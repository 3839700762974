import { orderBy } from 'lodash';
import SortOptions from '@enums/workpackage-sort-options';

/**
 * Sorts a list of items such as workpackages, templates and bundles.
 * The order is determined by the sortOrder option.
 * @param {Array<Object>} items - Array of items to be sorted
 * @param {String} sortOrder - Determines how the items are sorted
 * @returns {Array<Object>} - Sorted array of items
 */
const sortItemList = (items, sortOrder) => {
  let sortedList = [];
  switch (sortOrder) {
    case SortOptions.byNameAsc:
      sortedList = orderBy(items, [item => item.name.toLocaleLowerCase()], ['asc']);
      break;
    case SortOptions.byNameDesc:
      sortedList = orderBy(items, [item => item.name.toLocaleLowerCase()], ['desc']);
      break;
    case SortOptions.byCreatedDateAsc:
      sortedList = orderBy(items, [item => new Date(item.creationDate)], ['asc']);
      break;
    case SortOptions.byCreatedDateDesc:
      sortedList = orderBy(items, [item => new Date(item.creationDate)], ['desc']);
      break;
    case SortOptions.byLastModifiedDateAsc:
      sortedList = orderBy(items, [item => new Date(item.lastModifiedDate)], ['asc']);
      break;
    case SortOptions.byLastModifiedDateDesc:
      sortedList = orderBy(items, [item => new Date(item.lastModifiedDate)], ['desc']);
      break;
    default:
      sortedList = items;
  }
  return sortedList;
};

export default {
  sortItemList,
};
