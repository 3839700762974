var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "section h-100 d-flex flex-column",
      class: { "section-border": !_vm.shortView },
    },
    [
      _vm._t("header"),
      _vm._v(" "),
      _vm._t("main-section"),
      _vm._v(" "),
      _vm._t("footer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }