<template>
  <div class="reporting-menu d-flex flex-column h-100">
    <reporting-selection />

    <ag-grid-vue
      v-if="isSidebarShown"
      tree-data
      animate-rows
      dense
      style="height: 100%; width: 100%"
      class="ag-theme-custom-tree"
      :row-data="rowData"
      :column-defs="columns"
      :grid-options="gridOptions"
      @grid-ready="onGridReady"
    />
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
import {
  pick,
  values,
  reject,
  assign,
  get,
  size,
  reduce,
  map,
  flatMap,
  sortBy,
  uniqBy,
  concat,
  some,
  omit,
  includes,
  each,
} from 'lodash';
import menuLevel from '@enums/reporting-data-level';
import reportingFilters from '@enums/reporting-filters';
import agGridUtils from '@/js/utils/ag-grid-utils';

export default {
  localizationKey: 'reportingPage',

  components: {
    AgGridVue,
  },

  data() {
    return {
      rowData: [],
      columns: [
        {
          headerName: this.$tkey('menuHeaders.forecasted'),
          field: 'hasBeenForecast',
          cellClass: 'd-flex align-center justify-center',
          maxWidth: 35,
          cellRenderer: params =>
            agGridUtils.utils.customIconRenderer(params, {
              isDisplayed:
                get(params, 'data.menuLevel') === menuLevel.checkpoint ||
                (get(params, 'data.menuLevel') === menuLevel.canvas &&
                  get(params, 'data.live', false) &&
                  get(this.clientConfig, 'features.showLiveCanvasInReportingMenu', false)),
              icon: params.data.hasBeenForecast ? 'check' : 'close',
              classes: params.data.hasBeenForecast ? 'forecasted' : 'notForecasted',
            }),
          headerTooltip: this.$tkey('menuHeaders.forecasted'),
        },
        {
          headerName: this.$tkey('menuHeaders.baseShort'),
          cellClass: 'd-flex justify-center align-center',
          cellRendererParams: {
            field: reportingFilters.baseline,
          },
          cellRenderer: params =>
            get(params, 'data.menuLevel') === menuLevel.checkpoint ||
            (get(params, 'data.menuLevel') === menuLevel.canvas &&
              get(params, 'data.live', false) &&
              get(this.clientConfig, 'features.showLiveCanvasInReportingMenu', false))
              ? agGridUtils.utils.customCheckboxButtonRenderer(params, {
                  eventType: 'click',
                  handler: this.handleCheckboxChange,
                  isSelected: includes(this.baselineIds, params.data._id),
                })
              : '',
          maxWidth: 35,
          headerTooltip: this.$tkey('menuHeaders.base'),
        },
        {
          headerName: this.$tkey('menuHeaders.comparisonShort'),
          cellRenderer: params =>
            get(params, 'data.menuLevel') === menuLevel.checkpoint ||
            (get(params, 'data.menuLevel') === menuLevel.canvas &&
              get(params, 'data.live', false) &&
              get(this.clientConfig, 'features.showLiveCanvasInReportingMenu', false))
              ? agGridUtils.utils.customCheckboxButtonRenderer(params, {
                  eventType: 'click',
                  handler: this.handleCheckboxChange,
                  isSelected: includes(this.comparisonIds, params.data._id),
                  isComponentEnabled: this.isComparisonEnabled,
                })
              : '',
          maxWidth: 35,
          cellRendererParams: {
            field: reportingFilters.comparison,
          },
          cellClass: 'd-flex justify-center align-center',
          headerTooltip: this.$tkey('menuHeaders.comparison'),
        },
        {
          headerName: this.$tkey('menuHeaders.comparison2Short'),
          cellRenderer: params =>
            get(params, 'data.menuLevel') === menuLevel.checkpoint ||
            (get(params, 'data.menuLevel') === menuLevel.canvas &&
              get(params, 'data.live', false) &&
              get(this.clientConfig, 'features.showLiveCanvasInReportingMenu', false))
              ? agGridUtils.utils.customCheckboxButtonRenderer(params, {
                  eventType: 'click',
                  handler: this.handleCheckboxChange,
                  isSelected: includes(this.comparison2Ids, params.data._id),
                  isComponentEnabled: this.isComparisonEnabled,
                })
              : '',
          maxWidth: 35,
          cellRendererParams: {
            field: reportingFilters.comparison2,
          },
          cellClass: 'd-flex justify-center align-center',
          headerTooltip: this.$tkey('menuHeaders.comparison2'),
        },
      ],
      gridApi: null,
      columnApi: null,
      gridOptions: {
        animateRows: true,
        headerHeight: 30,
        rowHeight: 30,
        suppressContextMenu: true,
        defaultColDef: {
          filter: false,
          sortable: false,
          menuTabs: [],
          suppressMovable: true,
          tooltipShowDelay: 0,
        },
        onRowGroupOpened: params => {
          params.api.redrawRows({ rowNodes: [params.node] });
        },
        autoGroupColumnDef: {
          headerName: '',
          field: 'name',
          cellRendererParams: {
            suppressCount: true,
          },
          cellClassRules: {
            'ag-row-highlighted': this.isRowHighlighted,
          },
          hide: true,
          minWidth: 200,
          tooltipShowDelay: 0,
          tooltipValueGetter: params => get(params, 'data.name', ''),
        },
        getDataPath(data) {
          return data.hierarchy;
        },
        getRowId(row) {
          const { data } = row;
          return data._id;
        },
      },
      noBundle: { _id: null, name: this.$t('reportingPage.noBundle') },
    };
  },

  computed: {
    ...mapState('reporting', [
      'selectedBundles',
      'selectedWorkpackages',
      'canvases',
      'baselineIds',
      'comparisonIds',
      'comparison2Ids',
      'checkpoints',
      'metricValue',
      'isSidebarShown',
    ]),
    ...mapState('context', ['clientConfig']),
    ...mapGetters('reporting', [
      'scenariosByWorkpackage',
      'getSelectionFromId',
      'getCanvasName',
      'getFirstCommonAttribute',
    ]),
    ...mapGetters('workpackages', ['workpackagesByBundle']),
    ...mapGetters('bundles', ['bundlesById']),
  },

  created() {
    this.resetData();
  },

  methods: {
    ...mapMutations('reporting', ['setSelection', 'resetReportingFilters']),
    ...mapActions('reporting', ['resetFilters', 'fetchAttributeValuesForSelectedScenarios']),

    // build the next querystring based on tree selection & current location path
    getQueryFilters(params = {}) {
      const otherFiltersKeys = reject(values(reportingFilters), key => key === params.field);

      return otherFiltersKeys.reduce((acc, key) => {
        const propertyId = `${key}Ids`;
        return {
          ...acc,
          ...(size(this[propertyId]) && { [key]: this[propertyId] }),
        };
      }, {});
    },

    handleCheckboxChange(params) {
      /* On click we have to navigate the user to the "new url", update state and the information on which
      rows can be selected or not.
      */
      const { field, api } = params;
      const nextFilters = {
        ...this.getQueryFilters(params),
        ...this.getNextFilters(params),
        metricValue: this.metricValue,
      };
      // when the last baseline is unselected, also unselect comparison
      if (field === reportingFilters.baseline && !size(nextFilters[field])) {
        nextFilters.comparison = [];
        nextFilters.comparison2 = [];
      }
      // when the last comparison is unselected, also unselect comparison2
      if (field === reportingFilters.comparison && !size(nextFilters[field])) {
        nextFilters.comparison2 = [];
      }

      this.setSelection({ field: 'attributeValue', value: nextFilters.attributeValue });
      this.resetFilters(nextFilters);
      this.resetReportingFilters();
      api.redrawRows([]); // redraw all rows so the disabled/enabled flag is updated
      this.navigateToNewUrl(nextFilters);

      // after state is populated, we can get the values for all attributes in all scenarios
      this.fetchAttributeValuesForSelectedScenarios();
    },

    // Returns the tree row text
    getName(entry) {
      return entry.name || entry.description;
    },

    // Build tree rows for AG-grid respecting the hierarchy level
    getHierarchyData(entriesInLevel, hierarchyPick, nameFn = this.getName, hierarchyId = null) {
      return entriesInLevel.map(entry => {
        const hierarchy = values(pick(entry, hierarchyPick));

        return {
          hierarchy: hierarchy.concat(entry[hierarchyId] || entry._id),
          name: nameFn(entry),
          // helper properties
          ...pick(entry, [
            '_id',
            'menuLevel',
            'scenarioId',
            'storeClassId',
            'clusterId',
            'canvasIdCombined',
            'totalCanvases',
            'totalExisting',
            'totalForecasted',
            'isClustered',
            'hasBeenForecast',
            'live',
          ]),
        };
      });
    },

    resetData() {
      this.rowData = [];

      let scenarioData = [];
      const bundles = [];
      const bundleWorkpackages = flatMap(this.selectedBundles, b =>
        get(this.workpackagesByBundle, b._id, [])
      );
      const wps = uniqBy(
        [...this.selectedWorkpackages, ...(this.bundlesEnabled ? bundleWorkpackages : [])],
        '_id'
      );
      const workpackageData = reduce(
        sortBy(wps, 'creationDate'),
        (acc, w) => {
          // Ensures that all workpackages not assigned to a bundle have the bundleId key
          if (this.bundlesEnabled && !w.bundleId) w.bundleId = null;
          // bundleIds are required so that scenarios appear in the correct position in the menu
          const wpScenarios = map(this.scenariosByWorkpackage[w._id], s => ({
            ...s,
            bundleId: w.bundleId,
          }));
          // Workpackage not included if it does not have scenarios
          if (!size(wpScenarios)) return acc;

          scenarioData = scenarioData.concat(wpScenarios);
          if (this.bundlesEnabled) bundles.push(this.bundlesById[w.bundleId] || this.noBundle);
          w.menuLevel = menuLevel.workpackage;
          acc.push(w);
          return acc;
        },
        []
      );
      const bundleData = sortBy(uniqBy(bundles, '_id'), 'creationDate');

      // There is no data to display if there are no scenarios
      if (!size(scenarioData)) return;

      const bundlePick = this.bundlesEnabled ? ['bundleId'] : [];
      const bundleLevel = this.bundlesEnabled ? this.getHierarchyData(bundleData) : [];
      const workpackageLevel = this.getHierarchyData(workpackageData, bundlePick);
      const scenarioLevel = this.getHierarchyData(scenarioData, [...bundlePick, 'workpackageId']);
      const canvasesLevel = this.getHierarchyData(
        this.canvases,
        [...bundlePick, 'workpackageId', 'scenarioId'],
        this.getCanvasName,
        'canvasIdCombined'
      );
      const checkpointsLevel = this.getHierarchyData(
        this.checkpoints,
        [...bundlePick, 'workpackageId', 'scenarioId', 'canvasIdCombined'],
        this.getCheckpointName
      );
      this.rowData = this.rowData.concat(
        bundleLevel,
        workpackageLevel,
        scenarioLevel,
        canvasesLevel,
        checkpointsLevel
      );

      this.fetchAttributeValuesForSelectedScenarios();
    },

    // toggle which rows can be selected or not as baseline and comparisons change
    isComparisonEnabled(params) {
      return params.field === reportingFilters.comparison2
        ? size(this.baselineIds) && size(this.comparisonIds)
        : size(this.baselineIds);
    },

    // include new query to url param
    navigateToNewUrl(params) {
      const currentQueryValues = this.$route.query || {};
      let newQueryValues = assign({}, currentQueryValues, params);

      each(reportingFilters, filter => {
        // Ensure baseline and comparisons are always arrays
        newQueryValues[filter] = concat([], newQueryValues[filter] || []);
        if (!size(newQueryValues[filter])) delete newQueryValues[filter];
      });

      if (!newQueryValues.comparison || !newQueryValues.baseline) {
        newQueryValues = omit(newQueryValues, ['comparison', 'comparison2']);
      }
      if (!newQueryValues.comparison2) delete newQueryValues.comparison2;

      // Ensure workpackage and bundleIds are always arrays
      if (newQueryValues.workpackageIds) {
        newQueryValues.workpackageIds = concat([], newQueryValues.workpackageIds || []);
      }
      if (this.bundlesEnabled && newQueryValues.bundleIds) {
        newQueryValues.bundleIds = concat([], newQueryValues.bundleIds || []);
      }

      this.$router.push({
        path: this.$route.path, // keep on same path
        query: newQueryValues,
      });
    },

    getCheckpointName(entry) {
      return get(entry, 'checkpointMeta.name', entry.description);
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.gridApi.sizeColumnsToFit();
      this.gridApi.expandAll();
    },

    isRowHighlighted(params) {
      const allChildrenCount = get(params, 'node.allChildrenCount');
      const allLeafChildren = get(params, 'node.allLeafChildren', []);
      const allLeafChildrenIds = map(allLeafChildren, 'data._id');

      const baselineIds = new Set(this.baselineIds);
      const comparisonIds = new Set(this.comparisonIds);
      const comparison2Ids = new Set(this.comparison2Ids);

      if (size(allLeafChildrenIds) && allChildrenCount) {
        return some(
          allLeafChildrenIds,
          id => baselineIds.has(id) || comparisonIds.has(id) || comparison2Ids.has(id)
        );
      }
      const id = params.data._id;
      if (!id) return false;
      return baselineIds.has(id) || comparisonIds.has(id) || comparison2Ids.has(id);
    },

    getNextFilters({ data, field }) {
      const stateValue = data._id;
      const propertyId = `${field}Ids`;
      const fieldIds = new Set(this[propertyId]);
      if (fieldIds.has(stateValue)) fieldIds.delete(stateValue);
      else fieldIds.add(stateValue);
      const nextSelectionValues = [...fieldIds];

      const params = reduce(
        values(reportingFilters),
        (acc, filter) => {
          const key = `${filter}Ids`;
          return {
            ...acc,
            [key]: key === propertyId ? nextSelectionValues : this[key],
          };
        },
        {}
      );
      const attributeValue = this.getFirstCommonAttribute(params);

      return {
        attributeValue,
        [field]: nextSelectionValues,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.reporting-menu {
  background-color: $reporting-section-bg-color;
}

::v-deep {
  .round-selection {
    color: $baseline-colour;
    font-family: 'Material Design Icons';
    font-size: 15px;
    position: relative;

    &:before {
      content: '\F43D';
      height: 15px;
      width: 15px;
    }

    &[disabled]:before {
      color: $assortment-disabled-text-button-colour;
    }
  }

  .selected-baseline,
  .selected-comparison,
  .selected-comparison2 {
    &:before {
      content: '\F43E';
      height: 15px;
      width: 15px;
    }
  }

  .selected-baseline {
    color: $baseline-colour;
  }

  .selected-comparison {
    color: $comparison-colour;
  }

  .selected-comparison2 {
    color: $comparison2-colour;
  }

  .ag-popup > .ag-tooltip {
    color: $assortment-text-colour !important;
    background-color: $assortment-dialog-background !important;
    border: 1px solid $assortment-text-colour !important;
    min-width: fit-content !important;
  }

  .unforecasted-checkpoint {
    color: $assortment-disabled-text-button-colour;
  }

  .forecasted {
    color: $success-colour;
  }

  .notForecasted {
    color: $reporting-disabled-icon-color;
  }

  .ag-layout-normal > .ag-body-viewport.ag-layout-normal {
    scrollbar-gutter: stable;
  }
}
</style>
