<template>
  <v-dialog
    v-model="isPopupOpen"
    content-class="rtls-dialog rtls-dialog--legend"
    max-width="1120"
    max-height="740"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="close-button" color="primary" icon v-on="on">
        <v-icon size="20">$info</v-icon>
      </v-btn>
    </template>

    <dialog-card :title="$tkey('title')" @close="closePopup">
      <v-card>
        <div class="tooltip-section d-flex flex-wrap pt-5 pb-0">
          <div class="tile-item">
            <img
              src="../../../../src/img/legend/optimal-tile-product.png"
              width="80px"
              height="80px"
              alt="optimal-tile-product"
              class="tile-image"
            />
            <!-- using v-html because of keywords formatting in a translation string -->
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div class="tile-text" v-html="$tkey('optimal')" />
          </div>
          <div class="tile-item">
            <img
              src="../../../../src/img/legend/promoted-tile-product.png"
              width="80px"
              height="80px"
              alt="promoted-tile-product"
              class="tile-image"
            />
            <div class="tile-text">{{ $tkey('promoted') }}</div>
          </div>
          <div class="tile-item">
            <img
              src="../../../../src/img/legend/demoted-tile-product.png"
              width="80px"
              height="80px"
              alt="demoted-tile-product"
              class="tile-image"
            />
            <div class="tile-text">{{ $tkey('demoted') }}</div>
          </div>
          <div class="tile-item">
            <img
              src="../../../../src/img/legend/increased-distribution-tile-product.png"
              width="80px"
              height="80px"
              alt="increased-distribution-tile-product"
              class="tile-image"
            />
            <div class="tile-text">{{ $tkey('increasedDistribution') }}</div>
          </div>
          <div class="tile-item">
            <img
              src="../../../../src/img/legend/decreased-distribution-tile-product.png"
              width="80px"
              height="80px"
              alt="decreased-distribution-tile-product"
              class="tile-image"
            />
            <div class="tile-text">{{ $tkey('decreasedDistribution') }}</div>
          </div>
        </div>
        <div class="tooltip-section pt-5 pb-5">
          <h1 class="mb-5 section-header">{{ $tkey('productTileTagsAndColours') }}</h1>
          <div class="d-flex flex-column">
            <div class="d-flex align-center">
              <assortment-tag full-border :tag-text="'new'" position-relative />
              <div class="tile-text mb-2 pl-3">{{ $tkey('newProduct') }}</div>
            </div>
            <div class="d-flex align-center">
              <assortment-tag full-border :tag-text="'modelled'" position-relative />
              <div class="tile-text mb-2 pl-3">{{ $tkey('modelledProduct') }}</div>
            </div>
            <div class="d-flex align-center">
              <assortment-tag full-border :tag-text="'modelledInCluster'" position-relative />
              <div class="tile-text mb-2 pl-3">{{ $tkey('modelledInClusterProduct') }}</div>
            </div>
          </div>
        </div>
        <div class="tooltip-section pt-5 pb-5">
          <div class="d-flex flex-column">
            <h1 class="mb-5 section-header">{{ $tkey('tileLockStates') }}</h1>
            <div class="d-flex mb-5">
              <div class="d-flex align-center mr-4">
                <v-icon size="20">mdi-lock-open-variant-outline</v-icon>
                <div class="tile-text">{{ $tkey('unlocked') }}</div>
              </div>
              <div class="d-flex align-center mr-4">
                <v-icon size="20">mdi-lock-outline</v-icon>
                <div class="tile-text">{{ $tkey('locked') }}</div>
              </div>
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex flex-column mb-2">
                <div class="tile-text bold mb-1">{{ $tkey('unlocked') }}</div>
                <div class="tile-text">{{ $tkey('unlockedDescription') }}</div>
              </div>
              <div class="d-flex flex-column mb-2">
                <div class="tile-text bold mb-1">{{ $tkey('locked') }}</div>
                <div class="tile-text">{{ $tkey('lockedDescription') }}</div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </dialog-card>
  </v-dialog>
</template>

<script>
export default {
  localizationKey: 'assortmentCanvasPage.legend',
  data() {
    return {
      isPopupOpen: false,
    };
  },
  methods: {
    closePopup() {
      this.isPopupOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.action-panel {
  height: 45px;
}

.tile-item {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-right: 20px;
  width: 280px;
  margin-bottom: 20px;
}
.tile-text {
  font-size: 1.5rem;

  &.bold {
    font-weight: bold;
  }
}

.tile-image {
  margin-right: 20px;
}

.section-header {
  font-size: 1.5rem;
}
</style>
