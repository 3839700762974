<template>
  <section class="bundle-card d-flex flex-column">
    <bundle-panel :bundle="item" />

    <workpackages-active v-if="isPanelExpanded" class="mt-3" is-link :show-toolbar="false" />
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('bundles', ['isWorkpackagePanelExpanded', 'selectedBundle']),

    isPanelExpanded() {
      return this.isWorkpackagePanelExpanded && this.selectedBundle._id === this.item._id;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.bundle-card {
  border-top: 3px solid $assortment-bundle-border-colour;
}
</style>
