import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: {
        id: "mapFieldsModal",
        value: _vm.value,
        width: "750px",
        persistent: "",
      },
    },
    [
      _vm.value
        ? _c(
            "dialog-card",
            {
              attrs: { title: _vm.$tkey("title") },
              on: { close: _vm.closeModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("page-actions", {
                          attrs: {
                            "has-data-errors": _vm.hasDataErrors,
                            "has-data-changes": _vm.hasDataChanges,
                            "show-discard": false,
                            "save-btn-text": "confirm",
                          },
                          on: { save: _vm.confirmMappings },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "right-btns",
                                fn: function () {
                                  return [
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "text-outline",
                                        attrs: { text: "", link: "" },
                                        on: { click: _vm.closeModal },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.$t("actions.cancel")) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            808452761
                          ),
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2557486219
              ),
            },
            [
              _c(
                VContainer,
                { staticClass: "map-fields-list pa-1" },
                [
                  _c(
                    "p",
                    {
                      staticClass: "pl-3 mt-2 map-fields-list__subtitle",
                      class: [_vm.enableFieldCreation ? "mb-0" : "mb-1"],
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$tkey(
                              _vm.isResetFromTemplate
                                ? "resetFromTemplate.subtitle"
                                : "subtitle"
                            )
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.enableFieldCreation
                    ? _c(
                        "p",
                        { staticClass: "pl-3 mb-1 map-fields-list__subtitle" },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$tkey("createNewColumnsMessage")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-2 d-block" },
                    [
                      !_vm.isResetFromTemplate && _vm.downloadFileUrl
                        ? _c(
                            "download-link",
                            { attrs: { url: _vm.downloadFileUrl } },
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    small: "",
                                    text: "",
                                    color: "primary",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("mapFields.uploadedFile")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider),
                  _vm._v(" "),
                  _vm.hasUnmappedRequiredFields
                    ? _c(
                        VAlert,
                        {
                          staticClass: "ma-2",
                          attrs: { type: "error", text: "" },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$tkey("missingRequiredColumnsError")) +
                              ":\n        "
                          ),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.unmappedRequiredFields.join(", "))
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    VForm,
                    {
                      attrs: { autocomplete: "off" },
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        VList,
                        { staticClass: "mt-2", attrs: { dense: "" } },
                        [
                          _c(
                            VListItem,
                            { staticClass: "divider" },
                            [
                              _c(
                                VCol,
                                {
                                  staticClass: "pl-2 pb-1 font-weight-bold",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$tkey("uploadedColumns")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                VCol,
                                {
                                  staticClass: "pl-2 pb-1 font-weight-bold",
                                  attrs: { cols: "8" },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$tkey("actions")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.uploadedFields, function (field, index) {
                            return _c("map-fields-row", {
                              key: `${field.key}-${index}`,
                              attrs: {
                                field: field,
                                "upload-metadata": _vm.uploadMetadata,
                                "fields-options": _vm.fieldsOptions,
                                "fields-to-ignore": _vm.fieldsToIgnore,
                                mappings: _vm.mappings,
                              },
                              on: { "update-mapping": _vm.updateMapping },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }