<template>
  <div class="spacebreak-panel">
    <v-row>
      <v-col cols="5" class="pb-1">
        <rtls-text-field
          ref="spacebreakName"
          v-model.trim="spacebreak.name"
          :disabled="isEditingDisabled"
          run-validations-on-creation
          :rules="[required, isNotEmpty]"
          :server-error="spacebreakNameIsUnique({ spacebreak, storeClassId })"
          hide-details
          class="storeclass-text-field"
          @input="updateSpacebreak"
        />
      </v-col>
      <v-col cols="7" class="pl-1 pb-1 title-column">
        <v-row no-gutters>
          <v-col cols="10" class="associated-header">
            <b>{{ $tkey('associatedFA') }}</b>
            {{ $tkey('addFromList') }}
          </v-col>
          <v-col cols="2" class="d-flex justify-end">
            <v-icon :disabled="isRemoveDisabled || isEditingDisabled" @click="removeSpacebreak()">
              $trash
            </v-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-simple-table dense class="striped-table simple-assortments-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" />
                <th class="text-left">
                  {{ `${$tkey('spacebreakTable.suggested')} (${$t(`suffixes.${currentScope}`)})` }}
                </th>
                <th class="text-left">
                  {{ `${$tkey('spacebreakTable.override')} (${$t(`suffixes.${currentScope}`)})` }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ $tkey('spacebreakTable.total') }}</td>
                <td class="text-left">
                  {{ formatNumber({ number: spacebreak.size, format: 'float' }) }}
                </td>
                <td />
              </tr>
              <tr>
                <td>{{ $tkey('spacebreakTable.fill') }}</td>
                <td class="text-left">
                  {{ formatNumber({ number: getFillSuggested, format: 'float' }) }}
                </td>
                <td class="text-left override">
                  <rtls-text-field
                    run-validations-on-creation
                    :disabled="isEditingDisabled"
                    :value="formatNumber({ number: spacebreak.fillOverride, format: 'float' })"
                    :rules="inputRules"
                    :server-error="hasOverrideServerErrors"
                    class="table-input"
                    hide-details
                    width="100px"
                    @blur="event => updateSpacebreakField('fillOverride', event)"
                    @keyup.enter="event => updateSpacebreakField('fillOverride', event)"
                  />
                </td>
              </tr>
              <tr>
                <td>{{ $tkey('spacebreakTable.setAside') }}</td>
                <td class="text-left">
                  {{ formatNumber({ number: getSuggestedSetAside, format: 'float' }) }}
                </td>
                <td class="text-left">{{ getOverrideSetAside }}</td>
              </tr>
              <tr v-if="isPalletsSlotsEnabled">
                <td>{{ $t('general.pallets') }}</td>
                <td />
                <td class="text-left pallets">
                  <rtls-text-field
                    run-validations-on-creation
                    :disabled="isEditingDisabled"
                    :value="formatNumber({ number: spacebreak.palletsSlots, format: 'float' })"
                    :rules="palletInputRules"
                    :server-error="hasPalletsSlotsServerErrors"
                    class="table-input"
                    hide-details
                    width="100px"
                    @blur="event => updateSpacebreakField('palletsSlots', event)"
                    @keyup.enter="event => updateSpacebreakField('palletsSlots', event)"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="7" class="furniture-panel pl-1 pt-1 pb-1">
        <draggable
          :value="spacebreak.generatedFurnitureIds"
          group="spacebreakFurniture"
          ghost-class="ghost"
          draggable=".draggable"
          class="drag-container"
          :class="{ disabled: isEditingDisabled }"
          :disabled="isEditingDisabled"
          @change="handleDrop"
        >
          <v-card
            v-for="furnitureId in spacebreak.generatedFurnitureIds"
            :key="furnitureId"
            flat
            class="draggable ma-1"
          >
            <div class="list-item d-flex">
              <span class="furniture-archetype">
                {{ furnitureMapping[furnitureId] }}
              </span>
              <v-icon
                :disabled="isEditingDisabled"
                size="14"
                color="primary"
                class="list-icon"
                @click="$emit('removeOne', furnitureId)"
              >
                mdi-close-circle
              </v-icon>
            </div>
          </v-card>
        </draggable>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5" class="py-2" />
      <v-col cols="7" class="pl-1 py-2 action-btns">
        <v-btn
          small
          secondary
          depressed
          :disabled="isEditingDisabled"
          class="border-transparent-btn mx-2 add-all-btn"
          @click="$emit('addAll')"
        >
          {{ $tkey('addAllAvailable') }}
        </v-btn>
        <v-btn
          small
          text
          depressed
          :disabled="isEditingDisabled"
          class="transparent-btn mx-2 remove-btn"
          @click="$emit('removeAll')"
        >
          {{ $tkey('removeAll') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="5" class="pt-1 d-flex justify-start">
        {{ $tkey('totalStoreCount') }}
        <b class="ml-1">
          {{ totalStoreCount }}
        </b>
      </v-col>
      <v-col cols="7" class="pt-1 d-flex justify-end most-common-furniture">
        <div class="mr-1">
          {{ $tkey('mostCommonFA') }}
        </div>
        <b class="mr-2">{{ mostCommonFurniture.name }}</b>
        <div class="mr-2">|</div>

        <b class="mr-1">{{ mostCommonFurniture.storeCount }}</b>
        <div class="mr-2">
          {{ $tkey('stores') }}
        </div>

        <div class="mr-2">|</div>

        <b class="mr-1">{{ mostCommonFurniture.products }}</b> {{ $tkey('products') }}
      </v-col>
    </v-row>
    <main-dialog
      ref="confirmDeleteDialog"
      :message="$tkey('changingSpacebreaksWarning')"
      show-confirm-message
    >
      <template v-slot:actions="{ cancel: close }">
        <v-row class="mt-2">
          <v-btn
            :disabled="isEditingDisabled"
            primary
            class="mx-auto"
            @click="[confirmRemoveSpacebreak(), close()]"
          >
            {{ $tkey('removeSpacebreak') }}
          </v-btn>
        </v-row>
        <v-row class="my-1">
          <v-btn :disabled="isEditingDisabled" text class="mx-auto" @click="close">
            {{ $t('actions.cancel') }}
          </v-btn>
        </v-row>
      </template>
    </main-dialog>
  </div>
</template>

<script>
import { find, isEmpty, reject, isNaN, forEach, camelCase, get, findIndex } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import inputValidationMixin from '../../../../mixins/input-validations';
import numberUtils from '../../../../utils/number-format-utils';

export default {
  mixins: [inputValidationMixin],
  localizationKey: 'spacebreakCalculatorPage',
  props: {
    spacebreak: {
      type: Object,
      required: true,
      default: () => {},
    },
    furnitureMapping: {
      type: Object,
      required: true,
    },
    storeClassId: {
      type: String,
      required: true,
    },
    isRemoveDisabled: {
      type: Boolean,
      required: true,
    },
    spacebreaks: {
      type: Array,
      required: true,
    },
    spacebreakIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      inputRules: [this.isPositive, this.isNumber, this.isNotEmpty, this.required],
      palletInputRules: [
        v => this.isGreaterOrEqual(v, 0),
        this.isNumber,
        this.isNotEmpty,
        this.required,
      ],
      serverRulesOverride: [
        (v, size) => this.isLessThan(v, size),
        this.isUnique,
        v => this.isGreaterThan(v, 0),
      ],
      serverRulesPallets: [(v, index) => this.isLessThanPreviousSpaceBreak(v, index)],
    };
  },
  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('furniture', ['spacebreakNameIsUnique']),
    ...mapGetters('context', ['getPalletsSlotsEnabled']),

    isPalletsSlotsEnabled() {
      return this.getPalletsSlotsEnabled;
    },

    currentScope() {
      return camelCase(this.selectedWorkpackage.fillInSelection);
    },
    getSuggestedSetAside() {
      return this.spacebreak.size - this.spacebreak.fillSuggested;
    },
    getOverrideSetAside() {
      if (this.isNumber(this.spacebreak.fillOverride) !== true) {
        return '';
      }
      return numberUtils.formatNumber({
        number: this.spacebreak.size - this.spacebreak.fillOverride,
        format: 'float',
      });
    },
    totalStoreCount() {
      return this.spacebreak.storeCount;
    },
    getFillSuggested() {
      return this.spacebreak.fillSuggested;
    },
    mostCommonFurniture() {
      return this.spacebreak.mostCommonFurniture || {};
    },
    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditSpacebreakCalculatorPage);
    },
    hasOverrideServerErrors() {
      let errorOrValid = null;
      forEach(this.serverRulesOverride, rule => {
        errorOrValid = rule(this.spacebreak.fillOverride, this.spacebreak.size);
        if (errorOrValid !== true) {
          return false;
        }
      });
      return errorOrValid !== true ? errorOrValid : '';
    },

    hasPalletsSlotsServerErrors() {
      let errorOrValid = null;
      forEach(this.serverRulesPallets, rule => {
        errorOrValid = rule(
          this.spacebreak.palletsSlots,
          findIndex(this.spacebreaks, { _id: this.spacebreak._id })
        );
        if (errorOrValid !== true) {
          return false;
        }
      });
      return errorOrValid !== true ? errorOrValid : '';
    },
  },
  methods: {
    isLessThanPreviousSpaceBreak(value, spacebreakIndex) {
      // If the previous pallet hasn't been entered yet, it'll be undefined, and we don't want to compare to that, so instead we're using 0
      const palletsToCompare = get(this.spacebreaks[spacebreakIndex - 1], `palletsSlots`, 0);

      const sanitisedCurrentPalletValue = numberUtils.formatStringToNumber(value);
      const sanitisedPreviousPalletValue = numberUtils.formatStringToNumber(palletsToCompare);

      // Rules
      // We do not allow pallets to be smaller than the previous pallet's value (this is the main rule)
      // We allow pallets to be 0 if it's not smaller than the previous pallet's value
      // We allow pallets to be the same as the previous pallet's value
      if (
        sanitisedCurrentPalletValue >= 0 &&
        sanitisedPreviousPalletValue === sanitisedCurrentPalletValue
      ) {
        return true;
      }
      if (
        sanitisedCurrentPalletValue >= 0 &&
        sanitisedCurrentPalletValue > sanitisedPreviousPalletValue
      ) {
        return true;
      }

      return this.$t('validationErrors.palletsValueIsLessThanPrevious');
    },

    handleDrop({ added, removed }) {
      if (added) return this.$emit('addOne', added.element);
      if (removed) return this.$emit('removeOne', removed.element);
    },

    removeSpacebreak() {
      this.$refs.confirmDeleteDialog.open();
    },

    confirmRemoveSpacebreak() {
      this.$emit('removeSpacebreak');
    },

    updateSpacebreak() {
      const spacebreak = {
        ...this.spacebreak,
        ...{
          fillOverride: numberUtils.formatStringToNumber(this.spacebreak.fillOverride),
          palletsSlots: numberUtils.formatStringToNumber(this.spacebreak.palletsSlots),
        },
      };
      this.$emit('updateSpacebreak', spacebreak);
    },

    updateSpacebreakField(field, event) {
      this.spacebreak[field] = this.parseRawNumber(event.target.value);
      this.updateSpacebreak();
    },

    parseRawNumber(rawNumber) {
      const parsedNumber = numberUtils.formatStringToNumber(rawNumber);
      return this.isNotEmpty(rawNumber) !== true || isNaN(parsedNumber)
        ? rawNumber
        : numberUtils.formatNumber({ number: rawNumber, format: 'float' });
    },

    isUnique(newValue) {
      const otherSpacebreaks = reject(this.spacebreaks, { _id: this.spacebreak._id });
      const spacebreakMatchingSize = find(otherSpacebreaks, {
        fillOverride: newValue,
      });

      return (
        isEmpty(spacebreakMatchingSize) ||
        this.$tkey('fillNotUnique', [this.spacebreak.name, spacebreakMatchingSize.name])
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.disabled {
  pointer-events: none;
  cursor: default;
}
.list-item {
  cursor: move !important;
}
.drag-container {
  height: 150px;
  overflow: auto;
  border-radius: 3px;
  border: 1px solid $assortment-divider-colour;
}
.spacebreak-panel {
  background: $assortment-table-white-bg-colour;
  border-top: 1px solid $assortment-primary-colour;
  border-bottom: 1px solid $assortment-filter-bar-bg-colour;
  max-height: 500px;
  margin-top: 10px;

  .v-text-field__details {
    display: none;
  }

  .v-input__slot {
    margin: unset !important;
  }

  input {
    background-color: $assortment-background;
    padding: 1px;
  }

  .v-input {
    padding: 0px;
    margin: 0px;
  }

  .v-data-table {
    table {
      th {
        font-size: 12px;
      }
      tbody {
        td {
          font-size: 12px;
        }
      }
    }
  }

  .furniture-panel {
    box-sizing: border-box;
    overflow-y: auto;
  }

  .border-transparent-btn {
    border: 1px solid $assortment-primary-colour;
    color: $assortment-primary-colour;
    letter-spacing: 0;
    text-align: center;
  }
  .transparent-btn {
    color: $assortment-primary-colour;
    font-weight: 600;
    letter-spacing: 0;
  }
}

.storeclass-text-field {
  ::v-deep {
    .rtls-text-field {
      margin-right: -32px !important;
    }

    .v-text-field__slot {
      input {
        font-weight: bold;
      }
    }
  }
}

.associated-header {
  height: fit-content;
  align-self: flex-end;
}
.simple-assortments-table {
  ::v-deep {
    th {
      border-bottom: 1px solid $assortment-menu-border-colour !important;
    }
    td {
      border-bottom: none !important;
    }
    .text-left {
      padding-left: 10px !important;
    }
    .override,
    .pallets {
      padding-left: 5px !important;
      border-bottom: 1px solid $spacebreak-pallets-border-colour !important;
      input {
        background: transparent !important;
        border-bottom: none !important;
      }
    }
  }
}

.furniture-archetype {
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 14px;
}

.action-btns,
.most-common-furniture,
.title-column,
.furniture-panel {
  padding-right: 20px;
}

.add-all-btn,
.remove-btn {
  margin-left: 0px !important;
}
</style>
