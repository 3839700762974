import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "flex-list pl-0" },
    [
      _c(
        "li",
        [
          _vm.showNotImplemented
            ? _c(
                VBtn,
                {
                  staticClass: "action-button",
                  attrs: { disabled: "", icon: "", text: "" },
                },
                [_c(VIcon, [_vm._v("$favourite-star")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _vm.showNotImplemented
            ? _c(
                VBtn,
                {
                  staticClass: "action-button",
                  attrs: { disabled: "", icon: "", text: "" },
                },
                [_c(VIcon, [_vm._v("$notes")])],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "li",
        [
          _vm.hasProgress
            ? _c(
                VProgressCircular,
                {
                  attrs: { value: _vm.progressPercent, size: "25", width: "3" },
                },
                [_vm._v("\n      " + _vm._s(_vm.progressPercent) + "\n    ")]
              )
            : _c(
                VTooltip,
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            VBtn,
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "action-button",
                                  attrs: {
                                    icon: "",
                                    text: "",
                                    loading: _vm.copyInProgress,
                                    disabled:
                                      _vm.isCopyScenarioRunning ||
                                      !_vm.hasPermission(
                                        _vm.userPermissions.canCopyScenario
                                      ),
                                  },
                                  on: { click: _vm.onCopyScenarioClick },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                VIcon,
                                { staticStyle: { "font-size": "19px" } },
                                [_vm._v("mdi-content-copy")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("actions.copy")))]),
                ]
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("scenario-option-delete", { attrs: { scenario: _vm.scenario } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }