<template>
  <div
    class="d-flex justify-center w-100"
    :class="{
      relative: dashboard,
      'product-image--text-wrapper': imageTag === 'span' && !dashboard,
      'product-image--text-wrapper--large': imageTag === 'span' && !dashboard && isLargeTile,
    }"
    :style="getWrapperStyle"
  >
    <component
      :is="imageTag"
      loading="lazy"
      v-bind="$attrs"
      :src="src"
      :alt="alt"
      :title="tooltipText"
      class="fit-image d-flex justify-center align-center"
      :style="size"
      :class="{ 'longer-description': isShownOnProductDashboard }"
      @error="imageTag = 'span'"
    >
      {{ alt }}
    </component>
    <div v-if="dashboard" class="d-flex w-100 product-tags">
      <div class="w-100" />
      <assortment-tag :tag-text="tagText" />
      <div v-if="showNotInResetIcon" class="product-icon">
        <not-in-reset-icon class="not-in-reset-tag" color="white" />
      </div>
      <div v-else class="w-100" />
    </div>
    <div
      v-if="isLargeTile && imageTag === 'img' && !dashboard"
      class="d-flex w-100 product-image--text-wrapper product-tile-header product-image--text-wrapper--large"
      :style="{
        width: width,
      }"
    >
      <v-fade-transition hide-on-leave>
        <span class="fit-image product-description w-100">
          {{ alt }}
        </span>
      </v-fade-transition>
    </div>
  </div>
</template>

<script>
import destroy from '../utils/destroy';

export default {
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    tooltipText: {
      type: String,
      required: false,
      default: '',
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
    src: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
      default: '30px',
    },
    height: {
      type: String,
      required: false,
      default: '30px',
    },
    tagText: {
      type: String,
      required: false,
      default: '',
    },
    dashboard: {
      type: Boolean,
      required: false,
      default: false,
    },
    tileSize: {
      type: String,
      required: false,
      default: 'medium',
    },
    showNotInResetIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      imageTag: 'img',
    };
  },

  computed: {
    getWrapperStyle() {
      const shouldUseFullHeight = this.hasImage || this.dashboard;
      const shouldShowLargeSpan = !shouldUseFullHeight && this.isLargeTile;

      let height = '20px';
      if (shouldUseFullHeight) height = this.height;
      else if (shouldShowLargeSpan) height = '30px';

      return {
        height,
      };
    },

    size() {
      return {
        width: this.width,
      };
    },

    isLargeTile() {
      return this.tileSize === 'large';
    },

    hasImage() {
      return this.imageTag === 'img';
    },

    isShownOnProductDashboard() {
      return this.imageTag === 'span' && this.dashboard;
    },
  },

  beforeDestroy() {
    destroy.destroyReactiveVueProps(this);
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.fit-image {
  transition: all 1s ease;
  object-fit: contain;
}

span {
  &.fit-image {
    transition: all 1s ease;
    color: white;
    font-size: 1rem;
    text-transform: uppercase;
    transition: all 1s ease;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    word-break: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: center;

    align-self: stretch;
    font-weight: 400;
    font-style: normal;
    margin: 0;
    padding: 0;
    align-self: center;
    line-height: 10px;

    &.longer-description {
      -webkit-line-clamp: 6;
      color: $assortment-text-colour;
    }
  }
}

.product-image {
  &--text-wrapper {
    position: absolute;
    top: 40px; // Affected by product-tile
    background: $assortment-tag-bg-colour;
    overflow: hidden;

    &--large {
      height: 30px;
      top: 60px;
    }
  }
}

span.product-description {
  word-break: break-all;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  align-self: center;
  justify-self: center;
}

.product-tags {
  position: absolute;
  bottom: 0;
  height: 18px;
}
</style>
