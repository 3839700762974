import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        VRow,
        [
          _c(
            VCol,
            {
              staticClass: "pa-0 step-tab-panel__content",
              attrs: { justify: "start" },
            },
            [
              _c(
                "extract-report-generator",
                {
                  attrs: { disabled: _vm.isGenerateExtractDisabled },
                  on: { "generate-extract-requested": _vm.generateReport },
                },
                [
                  _c(
                    VContainer,
                    {
                      staticClass:
                        "report-product-store-extract d-flex flex-column pa-0 ma-0",
                      attrs: { fluid: "" },
                    },
                    [
                      _c(
                        VRow,
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            VCol,
                            {
                              staticClass: "margin-right-23",
                              attrs: { colspan: "12" },
                            },
                            [
                              _c(
                                "report-input-item",
                                {
                                  attrs: {
                                    title: _vm.$t(
                                      "extract.reports.furnitureExtract.reportType.title"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-end mb-1",
                                        },
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              staticClass:
                                                "control-button ml-auto",
                                              attrs: { small: "", primary: "" },
                                              on: { click: _vm.selectAll },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "extract.reports.furnitureExtract.reportType.buttons.selectAll"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            VBtn,
                                            {
                                              staticClass: "control-button",
                                              attrs: {
                                                small: "",
                                                secondary: "",
                                              },
                                              on: { click: _vm.clearAll },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "extract.reports.furnitureExtract.reportType.buttons.clearAll"
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.extractions,
                                        function (extract, name) {
                                          return _c(
                                            "div",
                                            {
                                              key: name,
                                              staticClass:
                                                "d-flex align-center justify-space-between checkbox-container mb-1",
                                              class: extract.selected
                                                ? "border-selected"
                                                : "border-unselected",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "checkbox-label",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "extract.reports.furnitureExtract.reportType.extractions." +
                                                            name
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(VCheckbox, {
                                                staticClass:
                                                  "rtls-checkbox mx-2",
                                                attrs: {
                                                  "input-value":
                                                    extract.selected,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    extract.selected = $event
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VRow,
                        { staticClass: "mt-6" },
                        [
                          _c("checkpoints-selection-table", {
                            attrs: {
                              "scenario-id": _vm.selectedScenario._id,
                              headers: _vm.headers,
                              canvases: _vm.items,
                              "checkpoints-by-canvas-id":
                                _vm.checkpointsByCanvasId,
                              "clustered-toggle": true,
                              title: _vm.$t(
                                "extract.reports.furnitureExtract.table.title"
                              ),
                            },
                            on: {
                              "update-selected-checkpoints":
                                _vm.setSelectedCheckpoints,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass: "pt-0 pr-0",
              attrs: { justify: "start", cols: "4" },
            },
            [
              _c("previous-extract-reports", {
                attrs: { reports: _vm.reports },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }