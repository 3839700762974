'use strict';

const kpis = {
  MARGIN: 'margin',
  MARGINWITHOUTFUNDING: 'marginWithoutFunding',
  VOLUME: 'volume',
  SELFSWITCHING: 'selfSwitching',
  UTILITY: 'utility',
  RANK: 'rank',
  SALES: 'sales',
};

module.exports = {
  kpis,
};
