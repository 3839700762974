<template>
  <div class="column-wrapper">
    <div class="column">
      <div v-if="isEditable" class="pb-1 header">
        <rtls-text-field
          v-model="name"
          run-validations-on-creation
          width="140px"
          class="text-field"
          :rules="rules"
          @input="updateName"
        />
        <v-icon class="mt-2" size="22" @click="deleteGroup">$trash</v-icon>
      </div>
      <div v-else class="pb-1 available-values-header header">
        {{ $tkey('availableValues') }}
      </div>
      <div v-if="attrGroup.values" class="list-group ma-0 pa-0">
        <draggable :list="attrGroup.values" group="attrs">
          <div
            v-for="attrValue in attrGroup.values"
            :key="attrValue"
            class="pl-2 d-flex align-center justify-start attribute-item w-100"
          >
            {{ attrValue }}
            <div class="icon mt-1">
              <v-icon
                v-if="isEditable"
                size="14"
                :title="$tkey('removeAttribute')"
                class="remove"
                @click="deleteAttr(attrValue)"
                >close</v-icon
              >
              <v-icon size="10">
                $cross-move
              </v-icon>
            </div>
          </div>
          <div
            slot="footer"
            class="pl-2 pr-2 d-flex align-center justify-start attribute-item drag-here"
          >
            {{ $tkey('dragValuesHere') }}
          </div>
          <v-alert v-if="!attrGroup.values.length && !isEditable" slot="footer" type="info" text>{{
            $tkey('noRemainingAttributes')
          }}</v-alert>
        </draggable>
      </div>
    </div>
    <div class="btn-wrapper">
      <v-btn
        v-if="isEditable"
        text
        class="ma-2"
        :disabled="disableAddAllRemaining"
        @click="addAllRemaining"
        >{{ $tkey('addAllRemainingValues') }}</v-btn
      >
      <v-btn
        v-if="!isEditable"
        :disabled="disableAddAllRemaining"
        text
        class="ma-2"
        @click="distributeRemaining"
        >{{ $tkey('distributeRemainingValues') }}</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttributeColumn',
  localizationKey: 'scenarioInputPage.attributeGroupingModal',
  props: {
    attrGroup: {
      type: Object,
      required: true,
    },
    isEditable: {
      type: Boolean,
      default: () => true,
    },
    disableAddAllRemaining: {
      type: Boolean,
      required: true,
    },
    reservedNames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      name: '',
      rules: [this.isUniqueGroupName],
    };
  },

  created() {
    this.name = this.attrGroup.name;
  },
  methods: {
    deleteAttr(attrValue) {
      this.$emit('delete-attr', { groupId: this.attrGroup.id, attrValue });
    },
    deleteGroup() {
      this.$emit('delete-group', this.attrGroup.id);
    },
    addAllRemaining() {
      this.$emit('add-all-remaining', this.attrGroup.id);
    },
    distributeRemaining() {
      this.$emit('distribute-remaining');
    },
    updateName() {
      this.attrGroup.name = this.name;
      this.$emit('update', this.attrGroup);
    },
    isUniqueGroupName() {
      this.$emit('validation', { id: this.attrGroup.id, isValid: false });
      if (!this.name) return this.$tkey('groupNameIsRequired');

      const names = this.reservedNames.filter(n => n.name === this.name);
      if (names.length > 1) {
        return this.$tkey('groupNameHasToBeUnique');
      }
      this.$emit('validation', { id: this.attrGroup.id, isValid: true });
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .v-alert {
    font-size: 1.2rem !important;
    padding: 7px 5px;

    .v-icon {
      margin-right: 5px !important;
    }
  }
  .icon {
    order: 2;
    margin: auto 0 0 auto;
  }
}

.v-btn {
  color: $assortment-primary-colour;
}

.available-values-header {
  font-weight: bold;
  font-size: 1.3rem;
}

.header {
  display: flex;
}

.column-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.btn-wrapper {
  flex-grow: 0;
}

.column {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.list-group {
  overflow: auto !important;
  padding: 0 !important;

  .attribute-item {
    height: 28px;
    width: 100%;
    padding-left: 0px;
    margin-bottom: 5px;
    border-radius: 3px;
    color: $assortment-text-colour;
    border: 1px solid transparent;
    background-color: $assortment-attribute-colour;
    font-weight: bold;
  }

  .drag-here {
    background-color: $assortment-background !important;
    color: $assortment-greyed-out-colour;
  }

  .remove {
    margin-right: -6px;
    font-weight: bold;
    color: $assortment-primary-colour;
  }
}
</style>
