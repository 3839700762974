import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-id-e2e": "activeWorkpackages" } },
    [
      _vm.showToolbar
        ? _c("workpackages-toolbar", {
            attrs: {
              "doc-path": "020-workpackage",
              entity: _vm.entities.workpackage,
            },
            on: { "on-search": _vm.onSearch, "on-sort": _vm.onSort },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.showToolbar && _vm.activeWorkpackages.length
        ? _c(
            "h2",
            { staticClass: "workpackages__title" },
            [
              _vm._v("\n    " + _vm._s(_vm.$t("workpackages.title")) + " "),
              _c("docs-link", {
                attrs: { link: "toolguide/020-workpackage.html" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.additionalCreateWorkpackageBtnsEnabled
        ? [
            _c("add-workpackage-buttons", { staticClass: "mt-4" }),
            _vm._v(" "),
            _c(
              VRow,
              [
                _c(VCol, { staticClass: "horizontal-divider-container" }, [
                  _c("div", { staticClass: "horizontal-divider" }),
                ]),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        VCard,
        { staticClass: "col-box" },
        [
          _vm.loading
            ? _c(
                VAlert,
                {
                  attrs: {
                    border: "left",
                    "colored-border": "",
                    color: "primary accent-4",
                  },
                },
                [
                  _c(VProgressCircular, {
                    attrs: { indeterminate: "", color: "primary", size: "20" },
                  }),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("workpackagePage.loadingActiveWorkpackages")
                      ) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            VRow,
            { attrs: { "no-gutters": "" } },
            [
              _c(
                VCol,
                {
                  staticClass: "workpackages__container",
                  attrs: { colspan: "12" },
                },
                [
                  _vm.sortedWorkpackagesList.length
                    ? _c("workpackages-list", {
                        attrs: {
                          entity: _vm.entities.workpackage,
                          "is-link": _vm.isLink,
                          "visible-workpackages": _vm.visibleWorkpackages,
                          workpackages: _vm.sortedWorkpackagesList,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.loading && !_vm.visibleWorkpackages.length
                    ? _c(VAlert, { attrs: { type: "info", text: "" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("workpackages.noActiveWps")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        [
          _c(VCol, { staticClass: "horizontal-divider-container" }, [
            _c("div", { staticClass: "horizontal-divider" }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("add-workpackage-buttons", { staticClass: "mb-6" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }