import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "mb-4", attrs: { fluid: "" } },
    [
      _c(
        VLayout,
        { attrs: { column: "" } },
        [_c("bundles-active"), _vm._v(" "), _c("bundles-archived")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }