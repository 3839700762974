import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: `product-${_vm.isSidebarShown}`,
      staticClass: "h-100 d-flex flex-column",
    },
    [
      _c("reporting-section", {
        attrs: { "short-view": _vm.shortView },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("reporting-header", {
                  attrs: { "short-view": _vm.shortView, section: _vm.section },
                  scopedSlots: _vm._u([
                    {
                      key: "toolbar",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row justify-end flex-grow-1",
                            },
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: { secondary: "", depressed: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.excelExport()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "reportingPage.exportCurrentView"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                  _c(
                                    VIcon,
                                    {
                                      staticClass:
                                        "material-icons-outlined ml-1 sidebar-toggle",
                                    },
                                    [
                                      _vm._v(
                                        "\n                file_download\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                VBtn,
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.shouldShowLayouts,
                                      expression: "shouldShowLayouts",
                                    },
                                  ],
                                  attrs: { secondary: "", depressed: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleSideBar("layouts")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$tkey(
                                          "sideBar.toolPanels.layouts.title"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                  _c(
                                    VIcon,
                                    {
                                      staticClass:
                                        "material-icons-outlined ml-1 sidebar-toggle",
                                    },
                                    [_vm._v("dashboard")]
                                  ),
                                  _vm._v(" "),
                                  _c("LayoutsComponent", {
                                    staticClass: "d-none",
                                  }),
                                ],
                                1
                              ),
                              _vm._v("\n             \n            "),
                              _c(
                                VBtn,
                                {
                                  attrs: { secondary: "", depressed: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleSideBar("columns")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$tkey("sideBar.toolPanels.columns")
                                      ) +
                                      "\n              "
                                  ),
                                  _c(
                                    VIcon,
                                    {
                                      staticClass:
                                        "material-icons-outlined ml-1 sidebar-toggle",
                                    },
                                    [_vm._v("view_column")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "main-section",
            fn: function () {
              return [
                _c("reporting-main-section", {
                  attrs: { section: _vm.section, "short-view": _vm.shortView },
                  scopedSlots: _vm._u([
                    {
                      key: "data-section",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "ag-grid-box d-flex w-100 h-100",
                              staticStyle: { height: "initial" },
                            },
                            [
                              _vm.isProductReportDisabled
                                ? _c(
                                    "div",
                                    { staticClass: "ma-auto no-data" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "reportingPage.noPermissions"
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm.isTableLoading
                                ? _c("progress-bar", {
                                    staticClass: "pt-5",
                                    staticStyle: { margin: "auto" },
                                    attrs: {
                                      message: _vm.$t(
                                        "reportingPage.loadingTable"
                                      ),
                                    },
                                  })
                                : _c("ag-grid-vue", {
                                    staticClass:
                                      "ag-theme-custom ag-theme-custom--attributes",
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: {
                                      "animate-rows": "",
                                      dense: "",
                                      "column-defs": _vm.columnDefs,
                                      "row-data": _vm.tableData,
                                      "grid-options": _vm.gridOptions,
                                      "side-bar": _vm.sideBar,
                                      "excel-styles": _vm.excelStyles,
                                    },
                                    on: { "grid-ready": _vm.onGridReady },
                                  }),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "confirm",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  VCardText,
                  { staticClass: "display-1 pa-0 text-center" },
                  [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$tkey("confirmationDialog.warning"))),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function ({ cancel, confirm }) {
              return [
                _c(
                  VCardActions,
                  { staticClass: "float-right" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "ma-2",
                        attrs: { primary: "" },
                        on: { click: confirm },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("continue")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      VBtn,
                      {
                        staticClass: "cancel ma-2",
                        attrs: { text: "", depressed: "" },
                        on: { click: cancel },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("cancel")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }