'use strict';

const metrics = {
  sales: 'sales',
  margin: 'margin',
  volume: 'volume',
  products: 'products',
  stockingPoints: 'stockingPoints',
  linearSpace: 'linearSpace',
  cubicSpace: 'cubicSpace',
  horizontalSpace: 'horizontalSpace',
  frontalSpace: 'frontalSpace',
};

const chartsLookup = {
  [metrics.sales]: {
    numberFormat: 'currency',
    field: 'totalSales',
  },
  [metrics.margin]: {
    numberFormat: 'currency',
    field: 'totalMargin',
  },
  [metrics.volume]: {
    numberFormat: 'float',
    field: 'totalVolume',
  },
  [metrics.products]: {
    numberFormat: 'integer',
    field: 'totalProducts',
  },
  [metrics.stockingPoints]: {
    numberFormat: 'float',
    field: 'totalPointOfDistribution',
  },
  [metrics.linearSpace]: {
    numberFormat: 'float',
    field: 'totalSize',
  },
  [metrics.cubicSpace]: {
    numberFormat: 'float',
    field: 'totalSize',
  },
  [metrics.frontalSpace]: {
    numberFormat: 'float',
    field: 'totalSize',
  },
  [metrics.horizontalSpace]: {
    numberFormat: 'float',
    field: 'totalSize',
  },
};

module.exports = {
  metrics,
  chartsLookup,
};