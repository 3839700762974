import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wp-scope" },
    [
      _c(
        VTabs,
        {
          attrs: { "hide-slider": true, "data-id-e2e": "workpackageScopeTabs" },
          model: {
            value: _vm.selectedTab,
            callback: function ($$v) {
              _vm.selectedTab = $$v
            },
            expression: "selectedTab",
          },
        },
        [
          _vm._l(_vm.tabs, function (item) {
            return [
              _c(
                VTab,
                { key: item.id, staticClass: "wp-tab justify-space-between" },
                [_c("span", [_vm._v(_vm._s(item.name))])]
              ),
              _vm._v(" "),
              _c("div", {
                key: `spacer-${item.id}`,
                staticClass: "wp-tab__spacer",
              }),
            ]
          }),
          _vm._v(" "),
          _c("div", { staticClass: "wp-tab__filler" }),
          _vm._v(" "),
          _c(
            VTabsItems,
            {
              model: {
                value: _vm.selectedTab,
                callback: function ($$v) {
                  _vm.selectedTab = $$v
                },
                expression: "selectedTab",
              },
            },
            _vm._l(_vm.tabs, function (item) {
              return _c(
                VTabItem,
                { key: item._id, staticClass: "wp-tab__item" },
                [
                  item.id === "periods"
                    ? _c("periods-tab", {
                        attrs: {
                          "date-selections": _vm.dateSelections,
                          "fill-in-selection": _vm.model.fillInSelection,
                          "maximum-trend": _vm.model.maximumTrend,
                          workpackage: _vm.workpackage,
                          "period-ids": _vm.model.periodIds,
                        },
                        on: {
                          "date-changed": _vm.populateDateSelections,
                          "dates-loaded": _vm.onDatesLoaded,
                          "model-changed": _vm.populateModel,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.id === "agNew" && _vm.dateSelections.snapshotDate
                    ? _c("assortment-groups-tab", {
                        attrs: {
                          "snapshot-date": _vm.dateSelections.snapshotDate,
                          workpackage: _vm.workpackage,
                          "custom-workpackage": _vm.dirtyWorkpackage(),
                          "dates-loaded": _vm.datesLoaded,
                        },
                        on: {
                          "update-assortment-groups":
                            _vm.populateSelectedAssortmentGroups,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  item.id === "agOld"
                    ? [
                        _c(
                          VRow,
                          {
                            staticClass: "wp-scope__form",
                            attrs: { "no-gutters": "" },
                          },
                          [
                            _c(
                              VCol,
                              { staticClass: "pr-3", attrs: { cols: 9 } },
                              [
                                _vm.dateSelections.snapshotDate
                                  ? _c("assortment-groups-selection", {
                                      attrs: {
                                        workpackage: _vm.workpackage,
                                        "snapshot-date":
                                          _vm.dateSelections.snapshotDate,
                                        "selected-assortment-group-settings":
                                          _vm.workpackage
                                            .selectedAssortmentGroupSettings ||
                                          [],
                                      },
                                      on: {
                                        "select-assortment-group":
                                          _vm.populateSelectedAssortmentGroups,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(VCol, { staticClass: "col-border-left" }, [
                              _c(
                                "div",
                                { staticClass: "col-padding" },
                                [
                                  _c(
                                    VCard,
                                    {
                                      staticClass: "pt-0 pb-2 pr-2 pl-2",
                                      staticStyle: {
                                        background: "none",
                                        "box-shadow": "none",
                                      },
                                    },
                                    [
                                      _c(
                                        "h3",
                                        {
                                          staticClass:
                                            "wp-scope__input-heading",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$tkey(
                                                  "productsAndStoresLabel"
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "wp-scope__input-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$tkey(
                                                "importProductDataLabel"
                                              )
                                            ) + ":"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.datesLoaded
                                        ? _c("import-from-planogram", {
                                            attrs: {
                                              "custom-workpackage":
                                                _vm.dirtyWorkpackage(),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("wp-scope-products"),
                                  _vm._v(" "),
                                  _c("wp-scope-stores"),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        VRow,
        { staticClass: "footer-panel" },
        [
          _c(
            VCol,
            {
              staticClass: "d-flex justify-end pa-3",
              attrs: { colspan: "12" },
            },
            [
              _c(
                VBtn,
                {
                  staticClass: "mr-0 font-weight-bold btn-text",
                  attrs: {
                    "data-id-e2e": "btnCloseWorkpackage",
                    ripple: false,
                    text: "",
                    small: "",
                  },
                  on: { click: _vm.closeScope },
                },
                [_vm._v(_vm._s(_vm.$t("actions.close")))]
              ),
              _vm._v(" "),
              _c(VDivider, {
                staticClass: "mr-0 divider",
                attrs: { vertical: "" },
              }),
              _vm._v(" "),
              _vm.showNotImplemented
                ? _c(
                    VBtn,
                    {
                      staticClass: "mr-2 font-weight-bold btn-text",
                      attrs: {
                        ripple: false,
                        text: "",
                        small: "",
                        disabled: "",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("actions.discardChanges")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  staticClass: "mr-2",
                  attrs: {
                    id: "scope-save",
                    "data-id-e2e": "btnSaveWorkpackage",
                    depressed: "",
                    "min-width": "65",
                    small: "",
                    action: "",
                    disabled:
                      _vm.isWorkpackageSetupRunning ||
                      !_vm.hasPermission(
                        _vm.userPermissions.canEditWorkpackageScope
                      ) ||
                      !_vm.validate(),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("save:workpackage")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("actions.save")) + "\n      ")]
              ),
              _vm._v(" "),
              _c(
                VTooltip,
                {
                  attrs: {
                    value: _vm.hasInvalidPeriodIds,
                    disabled: !_vm.hasInvalidPeriodIds,
                    top: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "div",
                            _vm._g({}, on),
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    "data-id-e2e": "btnRunWorkpackage",
                                    disabled:
                                      !_vm.hasPermission(
                                        _vm.userPermissions
                                          .canEditWorkpackageScope
                                      ) || _vm.hasInvalidPeriodIds,
                                    loading: _vm.isWorkpackageSetupRunning,
                                    depressed: "",
                                    primary: "",
                                    "min-width": "65",
                                    small: "",
                                  },
                                  on: { click: _vm.runWpSetup },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("workpackagePage.runWp")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _vm.hasInvalidPeriodIds
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$tkey("warnings.periodIdWarning"))),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }