<template>
  <div class="h-100">
    <progress-bar v-if="isProcessing" :message="$t('general.loading')" />

    <div class="compound-extracts-table__container h-100">
      <compound-extracts-toolbar
        :available-extracts="allowedItems.write"
        @update-selected-extract-filter="updateCreatorFilter"
      />

      <div class="flex-grow-1">
        <ag-grid-vue
          style="width: 100%; height: 100%;"
          class="ag-theme-custom"
          :row-data="rowData"
          :column-defs="columnDefs"
          :grid-options="gridOptions"
          :overlay-no-rows-template="overlayNoRowsTemplate"
          @grid-ready="onGridReady"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  get,
  map,
  isEqual,
  reduce,
  isEmpty,
  each,
  upperCase,
  includes,
  debounce,
  some,
} from 'lodash';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import {
  canCreateKasuExtracts,
  canViewKasuExtracts,
  canCreateAocExtracts,
  canViewAocExtracts,
  canCreateHasuExtracts,
  canViewHasuExtracts,
} from '@enums/user-permissions';
import compoundExtractFilters from '@enums/compound-extract-filters';
import { jobFinishedStatuses } from '@enums/jobapi';
import { planningHub, spaceTest } from '@enums/ftp-upload-types';
import agGridUtils from '@/js/utils/ag-grid-utils';
import downloadButtonRenderer from '@/js/components/ag-grid-cell-renderers/download-button-renderer.vue';
import duplicateButtonRenderer from '@/js/components/ag-grid-cell-renderers/duplicate-button-renderer.vue';
import uploadButtonRenderer from '@/js/components/ag-grid-cell-renderers/upload-button-renderer.vue';
import outboundStatusRenderer from '@/js/components/ag-grid-cell-renderers/outbound-status-renderer.vue';
import deleteButtonRenderer from '@/js/components/ag-grid-cell-renderers/delete-button-renderer.vue';

const fieldEnum = {
  bundle: 'bundle',
  workpackage: 'workpackage',
  created: 'extractDatetime',
  creator: 'createdBy.fullName',
  type: 'type',
  description: 'description',
  duplicate: 'duplicate',
  download: 'download',
  upload: 'upload',
  planningHub: 'planningHub',
  spaceTest: 'spaceTest',
  delete: 'delete',
};

const seperator = ' | ';

export default {
  localizationKey: 'compoundExtractsPage.table',

  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    duplicateButtonRenderer,
    uploadButtonRenderer,
    downloadButtonRenderer,
    outboundStatusRenderer,
    deleteButtonRenderer,
  },

  data() {
    return {
      items: {
        kasu: {
          name: 'kasu-extract',
          tkey: 'compoundExtractsPage.kasu',
          permissions: {
            read: canViewKasuExtracts,
            write: canCreateKasuExtracts,
          },
        },
        hasu: {
          name: 'hasu-extract',
          tkey: 'compoundExtractsPage.hasu',
          permissions: {
            read: canViewHasuExtracts,
            write: canCreateHasuExtracts,
          },
        },
        aoc: {
          name: 'aoc-extract',
          tkey: 'compoundExtractsPage.aoc',
          permissions: {
            read: canViewAocExtracts,
            write: canCreateAocExtracts,
          },
        },
      },
      gridOptions: {
        rowHeight: 50,
        headerHeight: 50,
        suppressRowClickSelection: true,
        suppressContextMenu: true,
        tooltipShowDelay: 0,
        defaultColDef: {
          filter: true,
          sortable: true,
          resizable: false,
          minWidth: 80,
          editable: false,
          suppressMovable: true,
          flex: 1,
          menuTabs: ['filterMenuTab'],
          cellClass: 'no-border',
        },
        onFilterChanged: this.onFilterChanged,
      },
      gridApi: null,
      columnApi: null,
      rowData: null,
      overlayNoRowsTemplate: null,
    };
  },

  computed: {
    ...mapState('compoundExtract', ['extracts', 'isProcessing', 'selectedExtractFilter']),
    ...mapState('context', ['clientConfig']),
    ...mapGetters('bundles', ['bundlesById']),
    ...mapGetters('compoundExtract', ['extractSeriesIdBySettingId', 'extractSeriesById']),
    ...mapGetters('context', ['getClientConfig', 'getDateFormats', 'getFullName']),
    ...mapGetters('workpackages', ['workpackagesById']),

    columnDefs() {
      return [
        {
          field: fieldEnum.bundle,
          colId: fieldEnum.bundle,
          headerName: this.$tkey(`columns.${fieldEnum.bundle}`),
          hide: !this.bundlesEnabled,
          filter: 'agTextColumnFilter',
          filterParams: agGridUtils.filters.standardStringFilter,
          maxWidth: 250,
          tooltipValueGetter: this.getTooltipValue,
        },
        {
          field: fieldEnum.workpackage,
          colId: fieldEnum.workpackage,
          headerName: this.$tkey(`columns.${fieldEnum.workpackage}`),
          filter: 'agTextColumnFilter',
          filterParams: agGridUtils.filters.standardStringFilter,
          maxWidth: 250,
          tooltipValueGetter: this.getTooltipValue,
        },
        {
          field: fieldEnum.created,
          colId: fieldEnum.created,
          headerName: this.$tkey('columns.created'),
          sort: 'desc',
          valueFormatter: params =>
            agGridUtils.formatters.datesFormatter(params, this.getDateFormats.mediumWithTime),
          filterParams: {
            valueFormatter: params =>
              agGridUtils.formatters.datesFormatter(params, this.getDateFormats.mediumWithTime),
          },
          maxWidth: 110,
        },
        {
          field: fieldEnum.creator,
          colId: fieldEnum.creator,
          headerName: this.$tkey('columns.creator'),
          maxWidth: 180,
          tooltipValueGetter: this.getTooltipValue,
        },
        {
          field: fieldEnum.type,
          colId: fieldEnum.type,
          headerName: this.$tkey(`columns.${fieldEnum.type}`),
          maxWidth: 85,
          // Show extract type in uppercase
          valueFormatter: params => upperCase(this.$t(`compoundExtractsPage.${params.value}`)),
          filterParams: {
            valueFormatter: params => upperCase(this.$t(`compoundExtractsPage.${params.value}`)),
          },
        },
        {
          field: fieldEnum.description,
          colId: fieldEnum.description,
          headerName: this.$tkey(`columns.${fieldEnum.description}`),
          filter: 'agTextColumnFilter',
          filterParams: agGridUtils.filters.standardStringFilter,
          tooltipValueGetter: this.getTooltipValue,
          maxWidth: 300,
        },
        {
          field: fieldEnum.delete,
          colId: fieldEnum.delete,
          headerName: '',
          hide: !this.betaIsExtractDeleteEnabled,
          maxWidth: 40,
          sortable: false,
          filter: false,
          menuTabs: [],
          cellRenderer: 'deleteButtonRenderer',
          cellRendererParams: params => {
            return {
              extractId: params.data._id,
              filenameOnDownload: params.data.filenameOnDownload,
              refresh: this.init,
            };
          },
        },
        {
          field: fieldEnum.duplicate,
          colId: fieldEnum.duplicate,
          headerName: this.$tkey(`columns.${fieldEnum.duplicate}`),
          maxWidth: 120,
          sortable: false,
          filter: false,
          menuTabs: [],
          cellRenderer: 'duplicateButtonRenderer',
          cellRendererParams: params => ({
            extractId: params.data._id,
            type: params.data.type,
            extractSettingsId: params.data.extractSettingsIds[0], // TODO AOV3-2337 - This should take the most up to date version, i.e. highest version.
            extractSeries: this.extractSeriesById[
              this.extractSeriesIdBySettingId[params.data.extractSettingsIds[0]] // TODO AOV3-2337 - This should take the most up to date settings version, i.e. highest version.
            ],
          }),
        },
        {
          field: fieldEnum.download,
          colId: fieldEnum.download,
          headerName: '',
          maxWidth: 120,
          sortable: false,
          filter: false,
          menuTabs: [],
          cellRenderer: 'downloadButtonRenderer',
          cellRendererParams: params => ({
            extractId: params.data._id,
            extractTypes: this.extractTypes,
          }),
        },
        {
          field: fieldEnum.upload,
          colId: fieldEnum.upload,
          headerName: '',
          maxWidth: 120,
          sortable: false,
          filter: false,
          menuTabs: [],
          cellRenderer: 'uploadButtonRenderer',
          cellRendererParams: params => {
            return {
              mostRecentOutbound: params.data.mostRecentOutbound,
              extractId: params.data._id,
              extractTypes: this.extractTypes,
              refresh: this.init,
            };
          },
        },
        {
          field: fieldEnum.planningHub,
          colId: fieldEnum.planningHub,
          headerName: this.$t('compoundExtractsPage.planningHub'),
          width: 145,
          sortable: false,
          filter: false,
          menuTabs: [],
          cellRenderer: 'outboundStatusRenderer',
          cellRendererParams: params => {
            return {
              mostRecentOutbound: params.data.mostRecentOutbound,
              outboundHistory: params.data.outboundHistory,
              outboundType: planningHub,
              extractId: params.data._id,
              extractTypes: this.extractTypes,
              refresh: this.init,
            };
          },
        },
        {
          field: fieldEnum.spaceTest,
          colId: fieldEnum.spaceTest,
          headerName: this.$t('compoundExtractsPage.spaceTest'),
          width: 145,
          sortable: false,
          filter: false,
          menuTabs: [],
          hide: !get(this, 'clientConfig.features.extracts.ftpSpaceTestEnabled'),
          cellRenderer: 'outboundStatusRenderer',
          cellRendererParams: params => {
            return {
              mostRecentOutbound: params.data.mostRecentOutbound,
              outboundHistory: params.data.outboundHistory,
              outboundType: spaceTest,
              extractId: params.data._id,
              extractTypes: this.extractTypes,
              refresh: this.init,
            };
          },
        },
      ];
    },

    allowedItems() {
      const clientExportTypes = get(this.getClientConfig, 'features.availableExtractsExports', []);
      return clientExportTypes.reduce(
        (enabledExtracts, exportType) => {
          const item = this.items[exportType];
          if (item) {
            item.reportType = exportType;
            each(item.permissions, (permission, type) => {
              if (this.hasPermission(permission)) enabledExtracts[type].push(item);
            });
          }
          return enabledExtracts;
        },
        { read: [], write: [] }
      );
    },

    extractTypes() {
      // Only load the extracts the user has permission to view
      return map(this.allowedItems.read, 'reportType');
    },

    shouldPollExtracts() {
      // checks if there are unfinished jobs that need to be refreshed
      return some(
        this.extracts,
        e => !includes(jobFinishedStatuses, get(e, 'jobs.compoundExtract.status'))
      );
    },

    betaIsExtractDeleteEnabled() {
      return get(this.getClientConfig, 'betaFeatures.extractDeleteEnabled', false);
    },
  },

  watch: {
    extracts() {
      if (this.shouldPollExtracts) {
        this.debouncedFetchExtracts();
      }
    },
  },

  created() {
    this.debouncedFetchExtracts = debounce(this.fetchCompoundExtracts, 30000);
    this.init();
    this.overlayNoRowsTemplate = `<span>${this.$tkey('noExtracts')}</span>`;
  },

  methods: {
    ...mapMutations('compoundExtract', ['setSelectedExtractFilter']),
    ...mapActions('bundles', ['fetchBundles']),
    ...mapActions('compoundExtract', ['fetchExtracts', 'fetchCompoundExtracts']),
    ...mapActions('workpackages', ['fetchWorkpackages']),

    async init() {
      const pick = ['_id', 'name', 'creationDate'];

      const promises = [
        this.fetchWorkpackages({ pick }),
        this.fetchExtracts({ extractTypes: this.extractTypes }),
        this.fetchCompoundExtracts({ extractTypes: this.extractTypes }),
      ];
      if (this.bundlesEnabled) {
        promises.push(this.fetchBundles({ pick }));
      }
      await Promise.all(promises);

      this.loadExtractData();
    },

    async loadExtractData() {
      this.rowData = reduce(
        this.extracts,
        (rows, e) => {
          const extractSeries = get(
            this.extractSeriesById,
            this.extractSeriesIdBySettingId[e.extractSettingsId]
          );
          if (extractSeries) {
            rows.push({
              ...extractSeries,
              _id: e._id,
              blobId: e.blobId,
              filenameOnDownload: e.filenameOnDownload,
              jobs: e.jobs,
              extractDatetime: e.extractDatetime,
              bundle: extractSeries.bundleIds
                .reduce((bundles, id) => {
                  const bundle = this.bundlesById[id];
                  if (bundle) bundles.push(bundle.name);
                  return bundles;
                }, [])
                .join(seperator),
              workpackage: extractSeries.workpackageIds
                .reduce((workpackages, id) => {
                  const wp = this.workpackagesById[id];
                  if (wp) workpackages.push(wp.name);
                  return workpackages;
                }, [])
                .join(seperator),
            });
          }
          return rows;
        },
        []
      );
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      // Applies correct filter to the creator column on load
      setTimeout(() => {
        this.updateCreatorFilter(this.selectedExtractFilter);
      }, 150);
    },

    onFilterChanged(params) {
      if (!params.api) return;
      const filterComponent = params.api.getFilterInstance(fieldEnum.creator);
      const allCreatorValues = get(filterComponent, 'valueModel.availableValues', new Set());
      const allExtractsCreatedByUser =
        allCreatorValues.size === 1 && allCreatorValues.has(this.getFullName);
      const userSelected = get(filterComponent, 'valueModel.selectedValues', new Set()).has(
        this.getFullName
      );
      const userFilterSelected = this.selectedExtractFilter === this.getFullName;
      const currentModelValues = get(filterComponent.getModel(), 'values', []);
      const noFilterValues = currentModelValues && isEmpty(currentModelValues);

      /*
        Updates the toolbar toggle when selections are made through ag-grid filter.
        Ensures that the current user filter toggle is selected when:
          1. All extracts in the table have been created by the user and the current user toggle is selected
          2. Extracts by multiple authors exist but only filtering by the current user
          3. The current user toggle is selected but there are no extracts created by the user
        */
      const selectedFilter =
        (allExtractsCreatedByUser && userSelected && userFilterSelected) ||
        isEqual(currentModelValues, [this.getFullName]) ||
        (noFilterValues && userFilterSelected)
          ? this.getFullName
          : compoundExtractFilters.all;
      this.setSelectedExtractFilter(selectedFilter);

      // If there are no rows in the table, show no rows overlay
      if (!this.gridApi.getDisplayedRowCount()) {
        this.gridApi.showNoRowsOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
    },

    updateCreatorFilter(filter) {
      if (!this.gridApi) return;
      const filterComponent = this.gridApi.getFilterInstance(fieldEnum.creator);
      const updatedModelValues = filter === compoundExtractFilters.all ? null : [filter];
      filterComponent.setModel({ values: updatedModelValues });
      this.setSelectedExtractFilter(filter);
      this.gridApi.onFilterChanged();
    },

    getTooltipValue(params) {
      return get(params.data, params.column.colId, '');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.compound-extracts-table {
  &__container {
    display: flex;
    flex-direction: column;
  }

  &__toolbar {
    border-bottom: 1px solid $assortment-panel-header-border;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__toggle {
    display: flex;
  }
}

::v-deep {
  .generate-extracts {
    &__button {
      height: 32px !important;
      padding: 0 12px !important;

      span {
        font-size: 1.6rem;
      }
    }

    &__icon {
      margin-left: 6px;
    }
  }

  .ag-theme-custom {
    .ag-header-cell {
      border-right: 8px solid transparent !important;
      border-left: 8px solid transparent !important;

      &-text {
        font-weight: bold;
      }
    }

    .ag-ltr .ag-cell {
      &.ag-cell-not-inline-editing.ag-cell-value {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 4.5rem;
      }

      &.ag-cell-focus,
      &.ag-cell-no-focus {
        border: 1px solid transparent !important;
        padding-left: 1px !important;
        border-right-width: 5px !important;
        border-left-width: 5px !important;
      }

      &.no-border:focus {
        border: 1px solid transparent !important;
        border-left: 0px solid transparent !important;
        border-right-width: 5px !important;
        border-left-width: 5px !important;
        outline: none;
      }
    }

    .ag-row {
      border-width: 0;
    }
  }
}
</style>
