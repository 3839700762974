import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "management-panel-container" },
    [
      _c(
        VRow,
        { staticClass: "management-panel" },
        [
          _c(
            VCol,
            {
              staticClass: "management-panel-block --first",
              attrs: { "data-id-e2e": "panelBlockFurniture" },
            },
            [
              _c(
                VContainer,
                [
                  _c(VRow, [
                    _c("h4", { staticClass: "title-text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "scenarioManagementPanel.furnitureAndSpaceTitle"
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    VRow,
                    { staticClass: "d-flex flex-column panel-item" },
                    [
                      _c("section-nav-button", {
                        staticClass: "white",
                        attrs: {
                          text: _vm.$t(
                            "scenarioManagementPanel.furnitureEditor"
                          ),
                          tab: _vm.tab.FURNITURE_EDITOR_TAB,
                          available:
                            _vm.tabPermissionsLookup[
                              _vm.tab.FURNITURE_EDITOR_TAB
                            ],
                        },
                      }),
                      _vm._v(" "),
                      _vm.getScenarioStatus.space.furniture.available
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.getScenarioStatus.space.furniture.status ===
                                _vm.sectionStatuses.complete
                                  ? "success-text"
                                  : "required-text",
                            },
                            [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "scenarioManagementPanel.status.furnitureCount",
                                      [
                                        _vm.getScenarioStatus.space.furniture
                                          .furnitureCount,
                                      ]
                                    )
                                  ) + "\n            "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("last-updated", {
                        attrs: {
                          "last-updated":
                            _vm.getScenarioStatus.space.furniture
                              .lastModifiedDate,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    { staticClass: "d-flex flex-column panel-item" },
                    [
                      _c("section-nav-button", {
                        staticClass: "white",
                        attrs: {
                          text: _vm.$t("scenarioManagementPanel.storeMapping"),
                          tab: _vm.tab.FURNITURE_MAPPING_TAB,
                          available:
                            _vm.getScenarioStatus.space.mapping.available &&
                            _vm.tabPermissionsLookup[
                              _vm.tab.FURNITURE_MAPPING_TAB
                            ],
                        },
                      }),
                      _vm._v(" "),
                      _vm.getScenarioStatus.space.mapping.available
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.getScenarioStatus.space.mapping.status ===
                                _vm.sectionStatuses.complete
                                  ? "success-text"
                                  : "required-text",
                            },
                            [
                              _c("strong", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "scenarioManagementPanel.status.storesMapped",
                                        [
                                          _vm.getScenarioStatus.space.mapping
                                            .storesMapped,
                                          _vm.getScenarioStatus.space.mapping
                                            .storeCount,
                                        ]
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("last-updated", {
                        attrs: {
                          "last-updated":
                            _vm.getScenarioStatus.space.mapping
                              .lastModifiedDate,
                        },
                      }),
                      _vm._v(" "),
                      !_vm.getScenarioStatus.space.mapping.available
                        ? _c("section-requires", {
                            attrs: {
                              requires: _vm.$t(
                                "scenarioManagementPanel.furniture"
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    { staticClass: "d-flex flex-column panel-item" },
                    [
                      _c("section-nav-button", {
                        staticClass: "white",
                        attrs: {
                          text: _vm.$t("scenarioManagementPanel.storeClass"),
                          tab: _vm.tab.STORECLASS_DEFINITION_TAB,
                          available:
                            _vm.getScenarioStatus.space.storeclass.available &&
                            _vm.tabPermissionsLookup[
                              _vm.tab.STORECLASS_DEFINITION_TAB
                            ],
                        },
                      }),
                      _vm._v(" "),
                      _vm.getScenarioStatus.space.storeclass.available &&
                      _vm.getScenarioStatus.space.storeclass.storeclassCount > 0
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.getScenarioStatus.space.storeclass
                                  .status === _vm.sectionStatuses.complete
                                  ? "success-text"
                                  : "required-text",
                            },
                            [
                              _c("strong", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "scenarioManagementPanel.status.storeclassesFurniture",
                                        [
                                          _vm.getScenarioStatus.space.storeclass
                                            .furnitureWithStoreClassCount,
                                          _vm.getScenarioStatus.space.furniture
                                            .furnitureCount,
                                        ]
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.getScenarioStatus.space.storeclass.available
                        ? _c(
                            "span",
                            {
                              class: {
                                "required-text":
                                  _vm.getScenarioStatus.space.storeclass
                                    .storeclassCount === 0,
                              },
                            },
                            [
                              _c("strong", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "scenarioManagementPanel.status.storeClassCount",
                                        [
                                          _vm.getScenarioStatus.space.storeclass
                                            .storeclassCount,
                                        ]
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("last-updated", {
                        attrs: {
                          "last-updated":
                            _vm.getScenarioStatus.space.storeclass
                              .lastModifiedDate,
                        },
                      }),
                      _vm._v(" "),
                      !_vm.getScenarioStatus.space.storeclass.available
                        ? _c("section-requires", {
                            attrs: {
                              requires: _vm.$t(
                                "scenarioManagementPanel.storeMapping"
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isFullResetWP
                    ? _c(
                        VRow,
                        { staticClass: "d-flex flex-column panel-item" },
                        [
                          _c("section-nav-button", {
                            staticClass: "white",
                            attrs: {
                              text: _vm.$t(
                                "scenarioManagementPanel.spacebreak"
                              ),
                              tab: _vm.tab.SPACEBREAK_CALCULATOR_TAB,
                              available:
                                _vm.getScenarioStatus.space.spacebreaks
                                  .available &&
                                _vm.tabPermissionsLookup[
                                  _vm.tab.SPACEBREAK_CALCULATOR_TAB
                                ],
                            },
                          }),
                          _vm._v(" "),
                          _vm.getScenarioStatus.space.spacebreaks.status ===
                          _vm.sectionStatuses.complete
                            ? _c("span", { staticClass: "success-text" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "scenarioManagementPanel.status.spacebreaksCompleted"
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.getScenarioStatus.space.spacebreaks.status !==
                            _vm.sectionStatuses.complete &&
                          _vm.getScenarioStatus.space.spacebreaks.available
                            ? _c("span", { staticClass: "required-text" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "scenarioManagementPanel.status.spacebreaksRequired"
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("job-status", {
                            attrs: { job: _vm.jobStatuses.spacebreakGenerator },
                          }),
                          _vm._v(" "),
                          _c("last-updated", {
                            attrs: {
                              "last-updated":
                                _vm.getScenarioStatus.space.spacebreaks
                                  .lastModifiedDate,
                            },
                          }),
                          _vm._v(" "),
                          !_vm.getScenarioStatus.space.spacebreaks.available
                            ? _c("section-requires", {
                                attrs: {
                                  requires: _vm.$t(
                                    "scenarioManagementPanel.storeClass"
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSimpleSwapsWP
                    ? _c(
                        VRow,
                        { staticClass: "d-flex flex-column panel-item" },
                        [
                          _c("section-nav-button", {
                            staticClass: "white",
                            attrs: {
                              text: _vm.$t(
                                "scenarioManagementPanel.planograms"
                              ),
                              tab: _vm.tab.PLANOGRAM_SELECTION_TAB,
                              available:
                                _vm.getScenarioStatus.space.planograms
                                  .available &&
                                _vm.tabPermissionsLookup[
                                  _vm.tab.PLANOGRAM_SELECTION_TAB
                                ],
                            },
                          }),
                          _vm._v(" "),
                          _vm.getScenarioStatus.space.planograms.available &&
                          _vm.getScenarioStatus.space.planograms
                            .planogramCount > 0
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.getScenarioStatus.space.planograms
                                      .status === _vm.sectionStatuses.complete
                                      ? "success-text"
                                      : "required-text",
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "scenarioManagementPanel.status.planogramsSelectedCount",
                                            [
                                              _vm.getScenarioStatus.space
                                                .planograms
                                                .planogramsSelectedCount,
                                              _vm.getScenarioStatus.space
                                                .planograms.planogramCount,
                                            ]
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.getScenarioStatus.space.planograms.available &&
                          _vm.getScenarioStatus.space.planograms
                            .planogramCount === 0
                            ? _c("span", { staticClass: "required-text" }, [
                                _c("strong", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "scenarioManagementPanel.status.planogramsRequired"
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("last-updated", {
                            attrs: {
                              "last-updated":
                                _vm.getScenarioStatus.space.planograms
                                  .lastModifiedDate,
                            },
                          }),
                          _vm._v(" "),
                          !_vm.getScenarioStatus.space.planograms.available
                            ? _c("section-requires", {
                                attrs: {
                                  requires: _vm.$t(
                                    "scenarioManagementPanel.storeClass"
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(VCard, {
            staticClass: "pa-2",
            attrs: { outlined: "", tile: "" },
          }),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass: "management-panel-block",
              attrs: { "data-id-e2e": "panelBlockInputs" },
            },
            [
              _c(
                VContainer,
                [
                  _c(VRow, [
                    _c("h4", { staticClass: "title-text" }, [
                      _vm._v(_vm._s(_vm.$t("scenarios.inputs"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    VRow,
                    { staticClass: "d-flex flex-column panel-item" },
                    [
                      _c("section-nav-button", {
                        attrs: {
                          text: _vm.$t("scenarioManagementPanel.attributes"),
                          tab: _vm.tab.ATTRIBUTES_TAB,
                          available:
                            _vm.tabPermissionsLookup[_vm.tab.ATTRIBUTES_TAB],
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          class:
                            _vm.getScenarioStatus.inputs.attributeEditor
                              .status === _vm.sectionStatuses.complete
                              ? "success-text"
                              : "required-text",
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "scenarioManagementPanel.status.attributePercent",
                                    [
                                      _vm.getScenarioStatus.inputs
                                        .attributeEditor.percentageComplete,
                                    ]
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [
                        _c("strong", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "scenarioManagementPanel.status.attributeCount",
                                  [
                                    _vm.getScenarioStatus.inputs.attributeEditor
                                      .productCount,
                                    _vm.getScenarioStatus.inputs.attributeEditor
                                      .attributeCount,
                                  ]
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("last-updated", {
                        attrs: {
                          "last-updated":
                            _vm.getScenarioStatus.inputs.attributeEditor
                              .lastModifiedDate,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    {
                      staticClass:
                        "d-flex flex-column panel-item panel-item--middle",
                    },
                    [
                      _c("section-nav-button", {
                        attrs: {
                          text: _vm.$t("scenarioManagementPanel.cann"),
                          tab: _vm.tab.CANN_GROUP_TAB,
                          available:
                            _vm.getScenarioStatus.inputs.cannGroups.available &&
                            _vm.tabPermissionsLookup[_vm.tab.CANN_GROUP_TAB],
                        },
                      }),
                      _vm._v(" "),
                      _vm.getScenarioStatus.inputs.cannGroups.available
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.getScenarioStatus.inputs.cannGroups
                                  .status === _vm.sectionStatuses.complete
                                  ? "success-text"
                                  : "required-text",
                            },
                            [
                              _c("strong", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "scenarioManagementPanel.status.cannGroupsCount",
                                        [
                                          _vm.getScenarioStatus.inputs
                                            .cannGroups.groupsCount,
                                        ]
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("last-updated", {
                        attrs: {
                          "last-updated":
                            _vm.getScenarioStatus.inputs.cannGroups
                              .lastModifiedDate,
                        },
                      }),
                      _vm._v(" "),
                      !_vm.getScenarioStatus.inputs.cannGroups.available
                        ? _c("section-requires", {
                            attrs: {
                              requires: _vm.$t(
                                "scenarioManagementPanel.attributes"
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    { staticClass: "d-flex flex-column panel-item" },
                    [
                      _c("section-nav-button", {
                        attrs: {
                          text: _vm.$t("scenarioManagementPanel.variety"),
                          tab: _vm.tab.VARIETY_TAB,
                          available:
                            _vm.getScenarioStatus.inputs.varietyGroups
                              .available &&
                            _vm.tabPermissionsLookup[_vm.tab.VARIETY_TAB],
                        },
                      }),
                      _vm._v(" "),
                      _vm.getScenarioStatus.inputs.varietyGroups.available
                        ? _c(
                            "span",
                            {
                              class:
                                _vm.getScenarioStatus.inputs.varietyGroups
                                  .status === _vm.sectionStatuses.complete
                                  ? "success-text"
                                  : "required-text",
                            },
                            [
                              _c("strong", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "scenarioManagementPanel.status.varietyGroupsSet",
                                        [
                                          _vm.getScenarioStatus.inputs
                                            .varietyGroups.numberSet,
                                          _vm.getScenarioStatus.inputs
                                            .cannGroups.groupsCount,
                                        ]
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("last-updated", {
                        attrs: {
                          "last-updated":
                            _vm.getScenarioStatus.inputs.varietyGroups
                              .lastModifiedDate,
                        },
                      }),
                      _vm._v(" "),
                      !_vm.getScenarioStatus.inputs.varietyGroups.available
                        ? _c("section-requires", {
                            attrs: {
                              requires: _vm.$t("scenarioManagementPanel.cann"),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isSimpleSwapsWP
                    ? _c(
                        VRow,
                        { staticClass: "d-flex flex-column panel-item" },
                        [
                          _c("section-nav-button", {
                            attrs: {
                              text: _vm.$t(
                                "scenarioManagementPanel.prepareSwaps"
                              ),
                              tab: _vm.tab.PREPARE_SWAPS_TAB,
                              available:
                                _vm.getScenarioStatus.inputs.prepareSwaps
                                  .available &&
                                _vm.tabPermissionsLookup[
                                  _vm.tab.PREPARE_SWAPS_TAB
                                ],
                            },
                          }),
                          _vm._v(" "),
                          _vm.getScenarioStatus.inputs.prepareSwaps.status ===
                          _vm.sectionStatuses.complete
                            ? _c("span", { staticClass: "success-text" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "scenarioManagementPanel.status.prepareSwapsCompleted"
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.getScenarioStatus.inputs.prepareSwaps.status !==
                            _vm.sectionStatuses.complete &&
                          _vm.getScenarioStatus.inputs.prepareSwaps.available
                            ? _c("span", { staticClass: "required-text" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "scenarioManagementPanel.status.prepareSwapsRequired"
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("job-status", {
                            attrs: { job: _vm.jobStatuses.prepareSwaps },
                          }),
                          _vm._v(" "),
                          !_vm.getScenarioStatus.inputs.prepareSwaps.available
                            ? _c("section-requires", {
                                attrs: {
                                  requires: _vm.$t(
                                    "scenarioManagementPanel.variety"
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(VDivider, { attrs: { vertical: "" } }),
          _vm._v(" "),
          _vm.isFullResetWP
            ? _c(
                VCol,
                {
                  staticClass: "management-panel-block",
                  attrs: { "data-id-e2e": "panelBlockMeasuring" },
                },
                [
                  _c(
                    VContainer,
                    [
                      _c(VRow, [
                        _c("h4", { staticClass: "title-text" }, [
                          _vm._v(_vm._s(_vm.$t("scenarios.measuring"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        VRow,
                        { staticClass: "d-flex flex-column panel-item" },
                        [
                          _c("section-nav-button", {
                            attrs: {
                              text: _vm.$t("scenarioManagementPanel.switching"),
                              tab: _vm.tab.SWITCHING_BEHAVIOUR_TAB,
                              available:
                                _vm.getScenarioStatus.measuring.switching
                                  .available &&
                                _vm.tabPermissionsLookup[
                                  _vm.tab.SWITCHING_BEHAVIOUR_TAB
                                ],
                            },
                          }),
                          _vm._v(" "),
                          _vm.getScenarioStatus.measuring.switching.status ===
                          _vm.sectionStatuses.complete
                            ? _c("span", { staticClass: "success-text" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "scenarioManagementPanel.status.switchingCompleted"
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.getScenarioStatus.measuring.switching.status !==
                            _vm.sectionStatuses.complete &&
                          _vm.getScenarioStatus.measuring.switching.available
                            ? _c("span", { staticClass: "required-text" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "scenarioManagementPanel.status.switchingRequired"
                                      )
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("job-status", {
                            attrs: { job: _vm.jobStatuses.switching },
                          }),
                          _vm._v(" "),
                          !_vm.getScenarioStatus.measuring.switching.available
                            ? _c("section-requires", {
                                attrs: {
                                  requires: _vm.$t(
                                    "scenarioManagementPanel.variety"
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VRow,
                        {
                          staticClass:
                            "d-flex flex-column panel-item panel-item--middle",
                        },
                        [
                          _c("section-nav-button", {
                            attrs: {
                              text: _vm.$t("scenarioManagementPanel.cdt"),
                              tab: _vm.tab.CDT_TAB,
                              available:
                                _vm.getScenarioStatus.measuring.cdt.available &&
                                _vm.tabPermissionsLookup[_vm.tab.CDT_TAB],
                            },
                          }),
                          _vm._v(" "),
                          _vm.getScenarioStatus.measuring.cdt.available
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.getScenarioStatus.measuring.cdt
                                      .status === _vm.sectionStatuses.complete
                                      ? "success-text"
                                      : "required-text",
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "scenarioManagementPanel.status.cannGroupsWithCdt",
                                            [
                                              _vm.getScenarioStatus.measuring
                                                .cdt.numberSelected,
                                              _vm.getScenarioStatus.inputs
                                                .cannGroups.groupsCount,
                                            ]
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("job-status", {
                            attrs: { job: _vm.jobStatuses.cdtGeneration },
                          }),
                          _vm._v(" "),
                          _c("last-updated", {
                            attrs: {
                              "last-updated":
                                _vm.getScenarioStatus.measuring.cdt
                                  .lastModifiedDate,
                            },
                          }),
                          _vm._v(" "),
                          !_vm.getScenarioStatus.measuring.cdt.available
                            ? _c("section-requires", {
                                attrs: {
                                  requires: _vm.$t(
                                    "scenarioManagementPanel.switching"
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.hasRegionsByAttributeEnabled
                        ? _c(
                            VRow,
                            { staticClass: "d-flex flex-column panel-item" },
                            [
                              _c("section-nav-button", {
                                attrs: {
                                  text: _vm.$t(
                                    "scenarioManagementPanel.region"
                                  ),
                                  tab: _vm.tab.REGION_TAB,
                                  available:
                                    _vm.getScenarioStatus.measuring.region
                                      .available &&
                                    _vm.tabPermissionsLookup[
                                      _vm.tab.REGION_TAB
                                    ],
                                },
                              }),
                              _vm._v(" "),
                              _c("job-status", {
                                attrs: {
                                  job: _vm.jobStatuses
                                    .regionsByAttributeGenerator,
                                },
                              }),
                              _vm._v(" "),
                              _c("last-updated", {
                                attrs: {
                                  "last-updated":
                                    _vm.getScenarioStatus.measuring.region
                                      .lastModifiedDate,
                                },
                              }),
                              _vm._v(" "),
                              !_vm.getScenarioStatus.measuring.region.available
                                ? _c("section-requires", {
                                    attrs: {
                                      requires: _vm.$t(
                                        "scenarioManagementPanel.cann"
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        VRow,
                        { staticClass: "d-flex flex-column panel-item" },
                        [
                          _c("section-nav-button", {
                            attrs: {
                              text: _vm.$t(
                                "scenarioManagementPanel.clustering"
                              ),
                              tab: _vm.tab.CLUSTERING_TAB,
                              available:
                                _vm.getScenarioStatus.measuring.clustering
                                  .available &&
                                _vm.tabPermissionsLookup[
                                  _vm.tab.CLUSTERING_TAB
                                ],
                              replace: "",
                            },
                          }),
                          _vm._v(" "),
                          _vm.getScenarioStatus.measuring.clustering.available
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.getScenarioStatus.measuring.clustering
                                      .status === _vm.sectionStatuses.complete
                                      ? "success-text"
                                      : "required-text",
                                },
                                [
                                  _vm.getScenarioStatus.measuring.clustering
                                    .schemeSelected
                                    ? _c("strong", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "scenarioManagementPanel.status.selectedClusterScheme",
                                                [
                                                  _vm.getScenarioStatus
                                                    .measuring.clustering
                                                    .schemeSelected,
                                                ]
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.getScenarioStatus.measuring.clustering
                                    .schemeSelected
                                    ? _c("strong", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "scenarioManagementPanel.status.noClusterSelectedScheme"
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("job-status", {
                            attrs: {
                              label: _vm.$t(
                                "scenarioManagementPanel.status.clusterGeneratorJob"
                              ),
                              job: _vm.jobStatuses.clusteringGenerator,
                            },
                          }),
                          _vm._v(" "),
                          _c("job-status", {
                            attrs: {
                              label: _vm.$t(
                                "scenarioManagementPanel.status.clusterSwitchingJob"
                              ),
                              job: _vm.jobStatuses.clusteredSwitching,
                            },
                          }),
                          _vm._v(" "),
                          _c("last-updated", {
                            attrs: {
                              "last-updated":
                                _vm.getScenarioStatus.measuring.clustering
                                  .lastModifiedDate,
                            },
                          }),
                          _vm._v(" "),
                          !_vm.getScenarioStatus.measuring.clustering.available
                            ? _c("section-requires", {
                                attrs: {
                                  requires: _vm.hasRegionsByAttributeEnabled
                                    ? _vm.$t("scenarioManagementPanel.region")
                                    : _vm.$t("scenarioManagementPanel.cann"),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isSimpleSwapsWP
            ? _c(
                VCol,
                {
                  staticClass: "management-panel-block",
                  attrs: { "data-id-e2e": "panelBlockSwaps" },
                },
                [
                  _c(
                    VContainer,
                    [
                      _c(VRow, [
                        _c("h4", { staticClass: "title-text" }, [
                          _vm._v(_vm._s(_vm.$t("scenarios.swaps"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        VRow,
                        { staticClass: "d-flex flex-column panel-item" },
                        [
                          _c("section-nav-button", {
                            attrs: {
                              text: _vm.$t(
                                "scenarioManagementPanel.productsToSwap"
                              ),
                              tab: _vm.tab.PRODUCTS_TO_SWAP_TAB,
                              available:
                                _vm.getScenarioStatus.swaps.productsToSwap
                                  .available &&
                                _vm.tabPermissionsLookup[
                                  _vm.tab.PRODUCTS_TO_SWAP_TAB
                                ],
                            },
                          }),
                          _vm._v(" "),
                          _vm.getScenarioStatus.swaps.productsToSwap.available
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.getScenarioStatus.swaps.productsToSwap
                                      .status === _vm.sectionStatuses.complete
                                      ? "success-text"
                                      : "required-text",
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "scenarioManagementPanel.status.productsCompletedCount",
                                            [
                                              _vm.getScenarioStatus.swaps
                                                .productsToSwap
                                                .productsCompletedCount,
                                              _vm.getScenarioStatus.swaps
                                                .productsToSwap.productCount,
                                            ]
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("last-updated", {
                            attrs: {
                              "last-updated":
                                _vm.getScenarioStatus.swaps.productsToSwap
                                  .lastModifiedDate,
                            },
                          }),
                          _vm._v(" "),
                          !_vm.getScenarioStatus.swaps.productsToSwap.available
                            ? _c("section-requires", {
                                attrs: {
                                  requires: _vm.$t(
                                    "scenarioManagementPanel.storeClass"
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(VDivider, { attrs: { vertical: "" } }),
          _vm._v(" "),
          _vm.isFullResetWP
            ? _c(
                VCol,
                { staticClass: "management-panel-block" },
                [
                  _c(
                    VContainer,
                    [
                      _c(VRow, [
                        _c("h4", { staticClass: "title-text" }, [
                          _vm._v(_vm._s(_vm.$t("scenarios.modelling"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        VRow,
                        { staticClass: "d-flex flex-column panel-item" },
                        [
                          _c("section-nav-button", {
                            attrs: {
                              text: _vm.$t(
                                "scenarioManagementPanel.productModelling"
                              ),
                              tab: _vm.tab.PRODUCT_MODELLING_TAB,
                              available:
                                _vm.productModellingStatus.available &&
                                _vm.tabPermissionsLookup[
                                  _vm.tab.PRODUCT_MODELLING_TAB
                                ],
                            },
                          }),
                          _vm._v(" "),
                          _vm.productModellingStatus.available
                            ? _c(
                                "span",
                                {
                                  class:
                                    _vm.productModellingStatus.status ===
                                    _vm.sectionStatuses.complete
                                      ? "success-text"
                                      : "required-text",
                                },
                                [
                                  _vm.productModellingStatus
                                    .newProductsModelled > 0
                                    ? _c("strong", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "scenarioManagementPanel.status.modelledFull",
                                                [
                                                  _vm.productModellingStatus
                                                    .newProductsModelled,
                                                  _vm.productModellingStatus
                                                    .productCount,
                                                  _vm.productModellingStatus
                                                    .establishedProductCount,
                                                  _vm.productModellingStatus
                                                    .childProductCount,
                                                ]
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.productModellingStatus
                                    .newProductsModelled === 0
                                    ? _c("strong", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.$t(
                                                "scenarioManagementPanel.status.modelled",
                                                [
                                                  _vm.productModellingStatus
                                                    .newProductsModelled,
                                                  _vm.productModellingStatus
                                                    .productCount,
                                                ]
                                              )
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("last-updated", {
                            attrs: {
                              "last-updated":
                                _vm.productModellingStatus.lastModifiedDate,
                            },
                          }),
                          _vm._v(" "),
                          !_vm.productModellingStatus.available
                            ? _c("section-requires", {
                                attrs: {
                                  requires: _vm.$t(
                                    "scenarioManagementPanel.cdt"
                                  ),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.hasSwitchingModellingAvailable
                        ? _c(
                            VRow,
                            { staticClass: "d-flex flex-column panel-item" },
                            [
                              _c("section-nav-button", {
                                attrs: {
                                  text: _vm.$t(
                                    "scenarioManagementPanel.switchingModelling"
                                  ),
                                  tab: _vm.tab.SWITCHING_MODELLING_TAB,
                                  available:
                                    _vm.switchingModellingStatus.available &&
                                    _vm.tabPermissionsLookup[
                                      _vm.tab.SWITCHING_MODELLING_TAB
                                    ],
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isSimpleSwapsWP
            ? _c(
                VCol,
                { staticClass: "management-panel-block" },
                [
                  _c(
                    VContainer,
                    [
                      _c(VRow, [
                        _c("h4", { staticClass: "title-text" }, [
                          _vm._v(_vm._s(_vm.$t("scenarios.extracts"))),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        VRow,
                        { staticClass: "d-flex flex-column panel-item" },
                        [
                          _c("section-nav-button", {
                            attrs: {
                              text: _vm.$t(
                                "scenarioManagementPanel.viewReports"
                              ),
                              tab: _vm.tab.EXTRACTS_TAB,
                              available:
                                _vm.getScenarioStatus.extracts.viewReports
                                  .available &&
                                _vm.tabPermissionsLookup[_vm.tab.EXTRACTS_TAB],
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(VDivider, { attrs: { vertical: "" } }),
          _vm._v(" "),
          _c(
            VCol,
            { staticClass: "management-panel-block --last" },
            [_c(VContainer)],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }