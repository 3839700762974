import axios from 'axios';
import to from 'await-to-js';
import i18n from '@/js/vue-i18n';
import { get, map, some, find, reduce } from 'lodash';
import ClusterSchemeTypes from '@enums/cluster-schemes-source';
import Classifications from '@enums/region-classifications';

const store = {
  namespaced: true,
  state: {
    regions: [],
    loading: false,
  },

  getters: {},

  mutations: {
    setRegions(state, regions) {
      state.regions = regions;
    },

    setLoading(state, loading) {
      state.loading = loading;
    },
  },

  actions: {
    async fetchRegions({ commit, rootState }) {
      commit('setLoading', true);
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const [err, res] = await to(axios.get(`/api/scenarios/${scenarioId}/product-regions`));
      if (err) throw new Error(err.message);
      commit('setRegions', res.data);
      commit('setLoading', false);
    },

    async generateDefaultRegionalClusterScheme({ dispatch, state, rootGetters, rootState }) {
      // Do not generate cluster scheme if there are no regional classifications
      if (!some(state.regions, r => r.classification === Classifications.regional)) return;

      const scenarioId = rootState.scenarios.selectedScenario._id;
      const selectedAttributes = rootGetters['clustering/getScenarioAttributes'];
      // Cluster schemes are always deleted after generating regions so this will always be the first scheme
      const defaultClusterScheme = {
        name: i18n.t('clusteringPage.dialog.regionalScheme'),
        clusterCount: 1, // Single cluster allowed here as it will be subclustered by region
        scenarioId,
        source: ClusterSchemeTypes.regional,
        clusters: [
          {
            clusterName: i18n.t('clusteringPage.unassignedStores'),
            storeKeys: map(rootGetters['scenarios/stores'], 'storeKey'),
          },
        ],
      };
      await dispatch(
        'clustering/saveClusteringSchemes',
        { clusterSchemes: [defaultClusterScheme], selectedAttributes },
        { root: true }
      );
    },

    async updateScenarioProducts({ dispatch, state, rootState }) {
      const { selectedScenario } = rootState.scenarios;
      const selectedAttribute = get(selectedScenario, 'regionSettings.attribute');
      const customAttributes = get(selectedScenario, 'customAttributes', []);

      // Need the custom attributeId to query scenario products
      const attributeId = get(find(customAttributes, a => a.name === selectedAttribute), 'id');
      // Find hyperlocal attribute values
      const attributeValues = reduce(
        state.regions,
        (acc, r) => {
          if (r.classification === Classifications.hyperlocal) acc.push(r.attributeValue);
          return acc;
        },
        []
      );

      dispatch(
        'scenarioProducts/updateScenarioProductRegionClassifications',
        { attributeId, attributeValues },
        {
          root: true,
        }
      );
    },

    async finishGenerateRegionsByAttributeJob(
      { dispatch, rootState },
      { message, translation, color }
    ) {
      const [scenarioErr, scenarioRes] = await to(
        axios.get(`/api/scenarios/${message.scenarioId}`)
      );
      if (scenarioErr) throw new Error(scenarioErr.message);

      const scenario = scenarioRes.data.data;
      const scenarioIdFromEvent = get(message, 'scenarioId', null);
      const scenarioId = rootState.scenarios.selectedScenario._id;

      const content = i18n.t(translation, { name: scenario.name });
      dispatch('snackbar/showSnackbar', { content, color }, { root: true });

      // do not refresh store in case user navigated away from current scenario
      if (scenarioIdFromEvent === scenarioId) {
        await dispatch('fetchRegions');
        dispatch('scenarios/loadScenario', scenarioId, { root: true });
      }
    },

    async generateRegionsByAttributeJobComplete({ dispatch }, { message }) {
      dispatch(
        'scenarios/setScenarioHasSkippedRegionsByAttribute',
        { hasSkippedRegionsByAttribute: false },
        { root: true }
      );
      await dispatch('finishGenerateRegionsByAttributeJob', {
        message,
        translation: 'notifications.region.finished',
        color: 'success',
      });
      // Update hyperlocal products. These products will not be included in the assortment
      dispatch('updateScenarioProducts');
      // Automatically create a cluster scheme if regional data has been generated
      await dispatch('generateDefaultRegionalClusterScheme');
    },

    generateRegionsByAttributeJobFailed({ dispatch }, { message }) {
      dispatch('finishGenerateRegionsByAttributeJob', {
        message,
        translation: 'notifications.region.failed',
        color: 'error',
      });
    },

    async updateRegions({ commit, dispatch, rootState }, updates) {
      commit('setLoading', true);
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const [err] = await to(
        axios.patch(`/api/scenarios/${scenarioId}/product-regions`, { updates })
      );
      if (err) throw new Error(err.message);

      await dispatch('fetchRegions');
      commit('setLoading', false);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });

      // Update hyperlocal products. These products will not be included in the assortment
      dispatch('updateScenarioProducts');
      // Automatically generate a new cluster scheme based on updated region data
      dispatch('generateDefaultRegionalClusterScheme');
    },
  },
};

export default store;
