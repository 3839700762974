var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "store-allocation-table__container d-flex flex-column grid-container",
      staticStyle: { width: "100%", height: "100%" },
    },
    [
      _c("ag-grid-vue", {
        staticClass: "ag-theme-custom",
        staticStyle: { width: "100%", height: "100%" },
        attrs: {
          "column-defs": _vm.columnDefs,
          "row-data": _vm.clusterData,
          "grid-options": _vm.gridOptions,
          components: _vm.components,
          "stop-editing-when-cells-loses-focus": true,
        },
        on: {
          "cell-value-changed": _vm.onChange,
          "grid-ready": _vm.onGridReady,
        },
      }),
      _vm._v(" "),
      _c("page-actions", {
        attrs: {
          "has-data-changes": _vm.hasDataChanges,
          "has-data-errors": _vm.hasDataErrors,
        },
        on: { discard: _vm.discard, save: _vm.onSave },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }