import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import to from 'await-to-js';
import axios from 'axios';
import { get } from 'lodash';

import workpackages from './modules/workpackages';
import workpackageProducts from './modules/workpackage-products';
import bundles from './modules/bundles';
import dependencyTree from './modules/dependency-tree';

import scenarios from './modules/scenarios';
import scenarioProducts from './modules/scenario-products';
import scenarioCdts from './modules/scenario-cdts';
import simpleSwapRuns from './modules/simple-swap-runs';
import furniture from './modules/furniture';

import assortmentGroups from './modules/assortment-groups';
import assortmentCanvas from './modules/assortment-canvas';
import switchingMatrices from './modules/switching-matrices';

import snapshotDates from './modules/snapshot-dates';
import files from './modules/files';
import extracts from './modules/extracts';
import storeExecutions from './modules/store-executions';

import jobs from './modules/jobs';
import userNotifications from './modules/user-notifications';
import context from './modules/context';
import filters from './modules/filters';
import snackbar from './modules/snackbar';
import events from './modules/events';
import alerts from './modules/alerts';
import clustering from './modules/clustering';
import referenceClustering from './modules/reference-clustering';
import reporting from './modules/reporting';
import reportingPowerBi from './modules/reporting-power-bi';
import toolData from './modules/tool-data';
import compoundExtract from './modules/compound-extract';
import productRegions from './modules/product-regions';

import notes from './modules/notes';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'store',
  storage: window.localStorage,
  reducer: state => ({
    context: state.context,
  }),
});

const store = new Vuex.Store({
  // we've agreed that unless there's a strong case for it, there should be no other actions here
  actions: {
    // this is where you can dispatch actions that need to happen as soon as the app loads
    async initialiseState({ dispatch }) {
      // TODO: better way to pass env var to FE
      const [, res] = await to(axios.get('/api/events/rabbit-enabled'));
      if (get(res, 'data') === true) {
        dispatch('events/changeEventstreamEnabled', { enabled: true });
        dispatch('events/resetEventStream');
      }
    },
  },

  modules: {
    context,
    workpackages,
    workpackageProducts,
    scenarios,
    scenarioProducts,
    scenarioCdts,
    assortmentGroups,
    assortmentCanvas,
    switchingMatrices,
    filters,
    snackbar,
    furniture,
    snapshotDates,
    files,
    clustering,
    referenceClustering,
    jobs,
    events,
    alerts,
    toolData,
    simpleSwapRuns,
    reporting,
    reportingPowerBi,
    extracts,
    dependencyTree,
    storeExecutions,
    userNotifications,
    notes,
    compoundExtract,
    bundles,
    productRegions,
  },

  plugins: [vuexLocalStorage.plugin],
});

export default store;
