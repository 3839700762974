<template>
  <v-card class="wp-scope-products col-box pa-2">
    <div class="wp-scope-products__container">
      <div class="mb-1">
        <h3 class="wp-scope-products__input-heading mt-3">
          {{ $t('workpackagePage.scope.products') }}

          <!-- Products scope tooltip -->
          <assortment-tooltip
            :title="$t('tooltips.productScope.title')"
            :tooltip-sections="productScopeTooltipSections"
          />
        </h3>
      </div>

      <div class="d-flex justify-end">
        <table class="item-details-table mb-2">
          <tr class="item-details-table__row">
            <td class="item-details-table__label">
              {{ $t('workpackagePage.scope.productsInScope') }}
            </td>
            <td class="item-details-table__count">
              {{ workpackage.productCount ? workpackage.productCount.total : 0 }}
            </td>
          </tr>
          <tr v-if="templatesEnabled && workpackage.templateId" class="item-details-table__row">
            <td class="item-details-table__label">
              {{ $t('workpackagePage.scope.fromTemplate') }}
            </td>
            <td class="item-details-table__count">
              {{ workpackage.productCount ? workpackage.productCount.includedFromTemplate : 0 }}
            </td>
          </tr>
          <tr v-if="hasProductsInResetEnabled" class="item-details-table__row">
            <td class="item-details-table__label">{{ $t('workpackagePage.scope.inReset') }}</td>
            <td class="item-details-table__count">
              {{ workpackage.productCount ? workpackage.productCount.inReset : 0 }}
            </td>
          </tr>
          <tr class="item-details-table__row">
            <td class="item-details-table__label">{{ $t('workpackagePage.scope.active') }}</td>
            <td class="item-details-table__count">
              {{ workpackage.productCount ? workpackage.productCount.active : 0 }}
            </td>
          </tr>
          <tr class="item-details-table__row">
            <td class="item-details-table__label">{{ $t('workpackagePage.scope.inactive') }}</td>
            <td class="item-details-table__count">
              {{ workpackage.productCount ? workpackage.productCount.inactive : 0 }}
            </td>
          </tr>
        </table>
      </div>

      <div class="text-right">
        <workpackage-products />
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { get } from 'lodash';
import { workpackageProductsTooltipOptionsMixin } from '@/js/mixins/tooltip-options';

export default {
  mixins: [workpackageProductsTooltipOptionsMixin],

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('context', ['getClientConfig']),

    workpackage() {
      return this.selectedWorkpackage;
    },

    hasProductsInResetEnabled() {
      return get(this.getClientConfig, 'features.productsInResetEnabled', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.wp-scope-products {
  font-size: 1.2rem;
  margin: 0 0 2px 0;

  &__container {
    border-top: 1px solid $assortment-divider-colour-darker;
    margin-top: 5px;
  }

  &__input-heading {
    font-size: 1.2rem;

    span {
      font-weight: normal;
    }
  }

  .v-btn {
    padding: 0;
  }
}
</style>
