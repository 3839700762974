<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :min-width="250"
      :max-width="500"
      :max-height="320"
      :nudge-bottom="35"
      left
      :z-index="menuZindex"
      @input="closeModal"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :ripple="false"
          depressed
          icon
          text
          :color="menuButtonColour"
          class="ml-2 control-menu-button"
          v-bind="attrs"
          :disabled="disabled"
          v-on="on"
        >
          <v-icon size="30">settings_applications</v-icon>
        </v-btn>
      </template>
      <v-card>
        <div class="d-flex flex-column">
          <div class="title-heading d-flex flex-column">
            <div>
              <h2>{{ $tkey('title') }}</h2>
            </div>
          </div>
          <div class="d-flex flex-column pa-3 control-container">
            <!-- Move to section -->
            <div>
              <div class="mb-1">
                <span class="font-weight-bold">{{ $tkey('moveToLabel') }}</span>
              </div>
              <div class="d-flex align-center">
                <rtls-select
                  v-model="spacebreakToMoveTo"
                  hide-details
                  width="200px"
                  max-width="200px"
                  :menu-props="{ maxWidth: 200 }"
                  :items="getAvailableSpacebreaks"
                  item-value="_id"
                  :placeholder="$t('general.select')"
                  :disabled="disabled"
                  grey
                />
                <v-btn
                  id="moveToBtn"
                  class="action-btn ml-5"
                  primary
                  depressed
                  :disabled="!spacebreakToMoveTo || disabled"
                  @click="openPopUp('moveTo')"
                >
                  {{ $tkey('moveToBtn') }}
                </v-btn>
              </div>
            </div>
          </div>
          <!-- Add attributes and extra KPIs control on list view section -->
          <div
            v-if="selectedView === 'list' && !columnSettingsEnabled"
            class="d-flex flex-column pa-3 control-container"
          >
            <div>
              <div class="mb-1">
                <span class="font-weight-bold">{{ $tkey('addColumnslabel') }}</span>
              </div>
              <div class="d-flex align-center">
                <v-select
                  class="rtls-select--multi-select"
                  :value="selectedCustomAttributes"
                  :items="customAttributes"
                  item-text="name"
                  item-value="id"
                  return-object
                  white
                  width="200px"
                  dense
                  :height="30"
                  small-chips
                  :placeholder="$tkey('selectAttributes')"
                  hide-details
                  multiple
                  :disabled="disabled"
                  :menu-props="{ dense: true, contentClass: 'rtls-multi-select-list' }"
                  @change="updateCustomAttributes"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <div class="overflow-container">
                        <span>{{ item.name }}</span>
                      </div>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ selectedCustomAttributes.length - 1 }})
                    </span>
                  </template>
                </v-select>
                <v-btn
                  id="addAttrCols"
                  :loading="setAttributeColsInProgress"
                  class="action-btn ml-5"
                  primary
                  depressed
                  :disabled="!hasAttributesChanges || setAttributeColsInProgress || disabled"
                  @click="saveAttributeSelection"
                >
                  {{ $tkey('addBtn') }}
                </v-btn>
              </div>
              <!-- Available extra KPIs can be added -->
              <template v-if="availableExtraKPIs.length">
                <div class="mt-2 mb-1">
                  <span class="font-weight-bold">{{ $tkey('addKPIs') }}</span>
                </div>
                <div class="d-flex align-center">
                  <v-select
                    class="rtls-select--multi-select"
                    :value="selectedExtraKPIs"
                    :items="availableExtraKPIs"
                    item-text="name"
                    item-value="id"
                    return-object
                    white
                    width="200px"
                    dense
                    :height="30"
                    small-chips
                    :placeholder="$tkey('selectKPIs')"
                    hide-details
                    multiple
                    :disabled="disabled"
                    :menu-props="{ dense: true, contentClass: 'rtls-multi-select-list' }"
                    @change="updateSelectedExtraKPIs"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <div class="overflow-container">
                          <span>{{ item.name }}</span>
                        </div>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text caption">
                        (+{{ selectedExtraKPIs.length - 1 }})
                      </span>
                    </template>
                  </v-select>
                  <v-btn
                    id="addExtraKPIs"
                    :loading="setExtraKPIsColsInProgress"
                    class="action-btn ml-5"
                    primary
                    depressed
                    :disabled="!hasKPIsChanges || setExtraKPIsColsInProgress || disabled"
                    @click="saveExtraKPIsSelection"
                  >
                    {{ $tkey('addBtn') }}
                  </v-btn>
                </div>
              </template>
            </div>
          </div>
          <div class="pa-3 d-flex flex-column control-container">
            <!-- Lock/unlock section -->
            <div class="d-flex justify-space-between">
              <v-btn
                class="mr-2 action-btn"
                primary
                depressed
                :disabled="!anyProductsVisible || disabled"
                @click="openPopUp('locked')"
              >
                {{ $tkey('lockVisible') }}
                <v-icon size="14" right>mdi-lock-outline</v-icon>
              </v-btn>
              <v-btn
                class="action-btn"
                primary
                depressed
                :disabled="!anyProductsVisible || disabled"
                @click="openPopUp('unlocked')"
              >
                {{ $tkey('unlockVisible') }}
                <v-icon size="14" right>mdi-lock-open-variant-outline</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="d-flex pt-3 pl-3 pr-3">
            <copy-canvas-decisions-modal :disabled="disabled" @close="menu = false" />
          </div>
          <div class="d-flex flex-column pl-3 pr-3 pt-2 pb-2">
            <div class="d-flex justify-end align-center close-icon-container">
              <v-btn
                class="mr-0 font-weight-bold btn-text"
                :ripple="false"
                text
                small
                @click="menu = false"
                >{{ $t('actions.close') }}
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-menu>
    <main-dialog
      ref="confirmDialog"
      :confirm-action="updateProducts"
      :title="popUpTitle"
      :message="popUpMessage"
      :border="true"
    >
      <template v-slot:actions="{ cancel, confirm }">
        <v-container class="actions-container assortment-table">
          <v-row>
            <v-col class="actions-col">
              <v-btn
                :disabled="isLoading"
                class="float-right button-space-5"
                primary
                @click="cancel"
                >{{ $t('actions.cancel') }}</v-btn
              >
              <v-btn
                :loading="isLoading || disabled"
                class="float-right button-space-5"
                primary
                :disabled="isLoading"
                @click="confirm"
                >{{ $t('actions.confirm') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </template>
    </main-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { cloneDeep, find, filter, isEqual, get, sortBy } from 'lodash';
import { lockTypes } from '@enums/assortment-product-lock-types';
import colors from '@/js/ow-colors';
import { zIndexes } from '@enums/menus';

const notListed = 'notListed';

export default {
  localizationKey: 'assortmentCanvasPage.controls',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      menu: false,
      chosenLockType: null,
      assortmentBlue: colors.assortmentBluePrimary,
      assortmentSecondaryBlue: colors.assortmentSecondaryBlue,
      menuZindex: zIndexes.menu,
      spacebreakToMoveTo: null,
      activeAction: null,
      selectedCustomAttributes: [],
      selectedExtraKPIs: [],
      customAttributes: null,
      setAttributeColsInProgress: false,
      setExtraKPIsColsInProgress: false,
      extraKPIsFormat: {
        wasteAndMarkdown: 'currency',
        wasteLossAndMarkdown: 'currency',
        wasteAndMarkdownPercentage: '0.00%',
        wasteLossAndMarkdownPercentage: '0.00%',
      },
    };
  },

  computed: {
    ...mapState('assortmentCanvas', [
      'spacebreaks',
      'additionalAttributeColumns',
      'selectedView',
      'additionalKPIsColumns',
      'assortmentCanvasProducts',
    ]),
    ...mapGetters('assortmentCanvas', [
      'getFilteredProducts',
      'selectedCanvas',
      'isSpacebreakLocked',
    ]),
    ...mapGetters('clustering', ['getScenarioAttributes']),
    ...mapState('context', ['clientConfig']),
    ...mapState('assortmentCanvas', ['spacebreaks', 'assortmentCanvasProducts']),
    ...mapGetters('assortmentCanvas', ['getFilteredProducts', 'isSpacebreakLocked']),

    columnSettingsEnabled() {
      return get(
        this.clientConfig,
        'features.scenario.assortmentCanvas.canvasListViewColumnSettings'
      );
    },

    menuButtonColour() {
      return this.menu ? this.assortmentSecondaryBlue : this.assortmentBlue;
    },

    popUpMessage() {
      if (!this.activeAction) return null; // avoids error when loading Vue devtools
      let spacebreakName = '';
      if (this.spacebreakToMoveTo) {
        spacebreakName = find(this.getAvailableSpacebreaks, { _id: this.spacebreakToMoveTo })
          .shortName;
      }
      let actionMessage = this.chosenLockType;
      let andMessage = '';
      if (!this.isALockTypeAction) {
        actionMessage = this.$tkey('moveToSpacebreak', [spacebreakName]);
        andMessage = this.$tkey('andUnlocked');
      }
      return this.$tkey('message', [this.updatableProducts.length, andMessage, actionMessage]);
    },

    popUpTitle() {
      if (this.isALockTypeAction) return this.$tkey(`${this.chosenLockType}Title`);
      return this.$tkey('moveToTitle');
    },

    anyProductsVisible() {
      return !!this.getFilteredProducts.visibleProductsSet.size;
    },

    getAvailableSpacebreaks() {
      return this.spacebreaks
        .map(sb => ({
          ...sb,
          disabled: this.isSpacebreakLocked(sb._id),
          text: `${sb.shortName} - ${sb.name}`,
        }))
        .concat([{ _id: notListed, text: this.$tkey('notListed') }]);
    },

    isALockTypeAction() {
      return !!lockTypes[this.activeAction];
    },

    // get a list of which products can be modified by the control
    updatableProducts() {
      // unlock/lock allows all products that are not disabled to be modified once the spacebreak they are in is unlocked.
      // otherwise, only the unlocked products can be modified once the spacebreak they are in is unlocked.
      if (this.isALockTypeAction)
        return filter(
          this.getFilteredProducts.products,
          prod =>
            !this.isSpacebreakLocked(prod.currentSpacebreakId) &&
            prod.assortment &&
            !prod.isLocal &&
            prod.isEligible
        );

      const inReset = filter(this.assortmentCanvasProducts, p => p.inReset).map(p => p._id);
      return filter(this.getFilteredProducts.products, prod => {
        return (
          prod.lockType === lockTypes.unlocked &&
          !this.isSpacebreakLocked(prod.currentSpacebreakId) &&
          inReset.includes(prod._id)
        );
      });
    },

    hasAttributesChanges() {
      if (!this.selectedCanvas) return [];
      return !isEqual(
        get(this.additionalAttributeColumns, [this.selectedCanvas._id], []),
        this.selectedCustomAttributes
      );
    },

    hasKPIsChanges() {
      if (!this.selectedCanvas) return [];
      return !isEqual(
        get(this.additionalKPIsColumns, [this.selectedCanvas._id], []),
        this.selectedExtraKPIs
      );
    },

    availableExtraKPIs() {
      return get(this.clientConfig, 'extraKPIs', []).map(kpi => ({
        id: kpi,
        name: this.$t(`assortmentCanvas.${kpi}`),
        format: this.extraKPIsFormat[kpi],
        type: 'kpi',
      }));
    },
  },

  created() {
    this.customAttributes = sortBy(this.getScenarioAttributes, item => item.name.toLowerCase());
    this.setAttributeSelection();
  },

  methods: {
    ...mapActions('assortmentCanvas', ['updateCanvasProducts', 'initializeListViewColumns']),
    ...mapMutations('assortmentCanvas', ['setAdditionalAttributeColumns', 'setExtraKPIsColumns']),

    getLocallyUpdatedProperties() {
      if (this.isALockTypeAction) return { lockType: this.chosenLockType };
      const nextSpacebreakId =
        this.spacebreakToMoveTo === notListed ? null : this.spacebreakToMoveTo;
      return { currentSpacebreakId: nextSpacebreakId };
    },

    getMongoUpdatedProperties() {
      if (this.isALockTypeAction) {
        return {
          fieldToUpdate: 'lockType',
          valueToUpdate: this.chosenLockType,
        };
      }

      const nextSpacebreakId =
        this.spacebreakToMoveTo === notListed ? null : this.spacebreakToMoveTo;
      return { fieldToUpdate: 'currentSpacebreakId', valueToUpdate: nextSpacebreakId };
    },

    async sleepDelay() {
      // Added timeout here due to assortment canvas frame dropping on large canvases, allows loading spinner to show before frame drops.
      // TO-DO Revisit this timeout after solving memory issues on canvas page https://owlabs.atlassian.net/browse/AOV3-954
      // We should be able to pass an array of handlers on click that execute in order, setting loading first then running the async action.
      // eslint-disable-next-line no-promise-executor-return
      const sleep = delay => new Promise(resolve => setTimeout(resolve, delay));
      await sleep(1000);
    },

    async updateProducts() {
      this.isLoading = true;

      await this.sleepDelay();

      const updateProductsPayload = {
        productsBeforeUpdates: cloneDeep(this.updatableProducts),
        locallyUpdatedProducts: this.updatableProducts.map(product => ({
          ...product,
          ...this.getLocallyUpdatedProperties(),
        })),
        updates: this.updatableProducts.map(product => ({
          _id: product._id,
          ...this.getMongoUpdatedProperties(),
        })),
      };
      if (this.activeAction === 'moveTo') {
        updateProductsPayload.successMessage = 'messages.movedToMessage';
        updateProductsPayload.failedMessage = 'messages.movedToFailedMessage';
      } else if (this.isALockTypeAction) {
        updateProductsPayload.failedMessage = `messages.${this.activeAction}VisibleFailedMessage`;
      }

      await this.updateCanvasProducts(updateProductsPayload);
    },

    async openPopUp(action) {
      this.activeAction = action;
      this.isLoading = false;
      // if is a valid lock type action, update model, otherwise assume it is a second control
      if (this.isALockTypeAction) {
        this.chosenLockType = lockTypes[action];
      }
      await this.$refs.confirmDialog.open();
    },

    closeModal() {
      const isModalOpen = this.menu;
      if (isModalOpen) {
        // only reset it if we're opening for the first time the modal
        this.spacebreakToMoveTo = null;
        this.setAttributeSelection();
      }
    },

    async saveAttributeSelection() {
      this.setAttributeColsInProgress = true;
      await this.sleepDelay();
      this.setAdditionalAttributeColumns({
        canvasId: this.selectedCanvas._id,
        selection: this.selectedCustomAttributes,
      });
      this.setAttributeColsInProgress = false;
      this.menu = false;
      this.initializeListViewColumns();
    },

    setAttributeSelection() {
      if (!this.selectedCanvas || !this.additionalAttributeColumns) return;
      this.selectedCustomAttributes = get(
        this.additionalAttributeColumns,
        [this.selectedCanvas._id],
        []
      );
    },

    updateCustomAttributes(newValue) {
      // maintain order of attributes in selected values
      this.selectedCustomAttributes = newValue.sort((a, b) => {
        return this.customAttributes.indexOf(a) - this.customAttributes.indexOf(b);
      });
    },

    updateSelectedExtraKPIs(newSelection) {
      this.selectedExtraKPIs = newSelection;
    },

    async saveExtraKPIsSelection() {
      this.setExtraKPIsColsInProgress = true;
      await this.sleepDelay();
      this.setExtraKPIsColumns({
        canvasId: this.selectedCanvas._id,
        selection: this.selectedExtraKPIs,
      });
      this.setExtraKPIsColsInProgress = false;
      this.menu = false;
      this.initializeListViewColumns();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.title-heading {
  padding: 10px 12px;
  border-bottom: 1px solid $assortment-divider-colour;
}

.control-container {
  border-bottom: 1px solid $assortment-divider-colour;
}

.btn-text {
  color: $assortment-primary-colour;
  padding-right: 0 !important;
}

.control-menu-button,
.btn-text {
  &::before {
    opacity: 0 !important;
  }
}
.action-btn {
  &.v-btn.v-btn--disabled .v-icon {
    color: $assortment-disabled-button-icon-colour !important;
  }
}

.close-icon-container {
  width: 100%;
}

.btn-text {
  color: $assortment-primary-colour;
  padding-right: 0 !important;

  &::before {
    opacity: 0 !important;
  }
}

.overflow-container {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.caption {
  font-size: 1rem !important;
}
</style>
