<template>
  <div
    id="product-distribution-grid"
    class="d-flex flex-column grid-container"
    style="width: 100%; height: 100%"
  >
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-custom ag-theme-custom--attributes"
      row-height="20"
      header-height="30"
      :column-defs="columnDefs"
      :row-data="productDistributionByCluster"
      :grid-options="gridOptions"
      @grid-ready="onGridReady"
      @column-visible="resizeClusters"
    />
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { partition, map, keys, keyBy, filter, get } from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import productMixin from '../product.mixin';

const MAX_VISIBLE_COLUMNS = 4;
const UNCLUSTERED_ID = 'unclustered';
export default {
  localizationKey: 'assortmentCanvasPage.productDashboard.productDistribution',
  components: {
    AgGridVue,
  },
  mixins: [productMixin],
  data() {
    return {
      gridApi: null,
      columnApi: null,
      productDistributionByCluster: [],
      columnDefs: [],
      gridOptions: {
        defaultColDef: {
          filter: false,
          sortable: false,
          editable: false,
          // menu to hide/show columns
          menuTabs: ['columnsMenuTab'],
          suppressMenuHide: true,
          suppressMovable: true,
          suppressToolPanel: false,
          minWidth: 100,
          flex: 1,
          // wraps value in a span that can be styled with ellipsis for overflow
          // normal cell container is a flex item which doesn't support ellipsis
          cellRenderer(params) {
            if (!params.value) return;
            return `<span title="${params.value}">${params.value}</span>`;
          },
        },
        getRowClass: params => {
          return params.node.rowIndex === 2 ? 'ag-header separator' : '';
        },
        suppressMenuHide: true,
        alwaysShowVerticalScroll: true,
        suppressHorizontalScroll: false,
      },
      rowsCategories: [
        {
          field: 'capturedInPercentageOfTransactions',
          text: 'capturedInPercentageOfTransactions',
          formatNumber: true,
          isPercentage: true,
        },
        {
          field: 'soldInPercentageOfROS',
          text: 'soldInPercentageOfROS',
          formatNumber: true,
          isPercentage: true,
        },
        {
          field: 'soldInPercentageOfStores',
          text: 'soldInPercentageOfStores',
          formatNumber: true,
          isPercentage: true,
        },
        { field: 'optimisedRank', text: 'optimisedRank' },
        { field: 'dataSource', text: 'dataSource' },
        { field: 'utility', text: 'utility' },
        { field: 'rateOfSale', formatNumber: true },
        {
          field: 'optimisedAccumulatedSalesWhenCut',
          text: 'optimisedAccumulatedSalesWhenCut',
          formatNumber: true,
        },
        { field: 'volume', text: 'volume', formatNumber: true },
        { field: 'margin', text: 'margin', formatMarginPercentage: true },
        { field: 'positionLatestEdit', text: 'positionLatestEdit' },
      ],
      distributionColumn: {
        headerName: '',
        field: 'rowCategory',
        cellClass: 'pl-5',
        pinned: 'left',
        lockVisible: true,
        // this column cannot be unselected
        suppressColumnsToolPanel: true,
        cellStyle: {
          'font-weight': 'bold',
        },
        suppressSizeToFit: true,
      },
      invisibleColumns: [],
      visibleColumns: [],
    };
  },
  computed: {
    ...mapState('clustering', ['selectedScheme']),
    ...mapState('assortmentCanvas', ['selectedPod']),
    ...mapGetters('clustering', ['selectedClusterScheme']),
    ...mapGetters('assortmentCanvas', [
      'getIndexedCanvasProducts',
      'getDashboardProduct',
      'canvasesById',
      'canvases',
    ]),
    ...mapGetters('furniture', ['getSpacebreaksIndexedById']),
    ...mapGetters('context', ['getCurrentNumericLocale']),

    currentNumericLocale() {
      return this.getCurrentNumericLocale;
    },

    requiredCurrency() {
      return this.$t(`currencies.${this.currentNumericLocale}`);
    },

    unclusteredHeader() {
      return {
        headerName: this.$tkey('tableHeaders.unclustered'),
        suppressSizeToFit: true,
        field: UNCLUSTERED_ID,
      };
    },
  },

  watch: {
    getDashboardProduct: {
      immediate: true,
      handler(newProduct, oldProduct) {
        this.resetColumnsVisibility();
        if (!newProduct || !!(oldProduct && newProduct.productKey === oldProduct.productKey))
          return;
        this.fetchProductInformation(newProduct.productKey);
      },
    },
  },

  created() {
    this.setTableColumns();
  },

  methods: {
    ...mapActions('assortmentCanvas', ['fetchProductDistribution']),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    setTableColumns() {
      // Get column info for each cluster. The currently selected cluster is the first column to be added
      const productCluster = this.canvasesById[this.getDashboardProduct.canvasId];
      const clusterColumns = get(this.selectedClusterScheme, 'clusters', []).map(
        (c, columnIndex) => {
          const hide = columnIndex + 1 > MAX_VISIBLE_COLUMNS - 1; // -1 considering unclustered
          (hide ? this.invisibleColumns : this.visibleColumns).push(c.clusterId);
          return {
            headerName: c.clusterName,
            field: c.clusterId,
            minWidth: 140,
            hide,
            suppressSizeToFit: false,
          };
        }
      );

      const allColumns = [this.unclusteredHeader, ...clusterColumns];
      const [[firstColumn], remainingColumns] = partition(allColumns, {
        field: productCluster.clusterId || UNCLUSTERED_ID,
      });

      const formattedColumns = [this.distributionColumn, firstColumn, ...remainingColumns].map(
        (c, columnIndex) => {
          c.cellStyle = c.cellStyle || {};
          c.menuTabs = [];

          // first cluster entry
          if (columnIndex === 1) {
            c.lockVisible = true;
            c.cellStyle['font-weight'] = 'bold';
            c.pinned = 'left';
            c.maxWidth = 150;
            // this column cannot be unselected
            c.suppressColumnsToolPanel = true;
          }

          return c;
        }
      );

      if (clusterColumns.length > MAX_VISIBLE_COLUMNS - 1) {
        formattedColumns.push({
          colId: 'menuIcon',
          headerName: '',
          width: 30,
          maxWidth: 30,
          menuTabs: ['columnsMenuTab'],
          headerClass: 'actions-column pr-2',
          pinned: 'right',
          icons: {
            menu: '...',
          },
          // this column cannot be unselected
          suppressColumnsToolPanel: true,
          lockVisible: true,
        });
      }

      this.columnDefs = formattedColumns;
    },

    getCellData(c, p, clusterId) {
      if (c.field === 'dataSource') {
        return this.$t(`assortmentCanvas.${p.isNewProduct ? 'modelled' : 'measured'}`);
      }
      if (c.field === 'positionLatestEdit') {
        const pod = this.podAndStoreCounts(
          p,
          clusterId,
          this.getSpacebreaksIndexedById[clusterId],
          {}
        ).currentSpacebreak;
        return this.$t('assortmentCanvas.productPositioning', [
          pod.shortName,
          pod.storeCount,
          pod.pod,
        ]);
      }

      let rowData = p[c.field];

      if (c.formatNumber) {
        rowData = this.formatNumber({ number: rowData || 0, format: 'float' });
      }

      if (c.isPercentage) {
        return `${rowData}%`;
      }

      if (c.formatMarginPercentage) {
        const formattedMargin = Number(rowData * 100)
          .toFixed(0)
          .toLocaleString(this.currentNumericLocale);
        return `${formattedMargin}%`;
      }

      return rowData;
    },

    buildTableContents(clusteredData) {
      const productByCanvas = keyBy(clusteredData, 'canvasId');

      this.productDistributionByCluster = this.rowsCategories.map(category => {
        let rowCategoryText = this.$t(`assortmentCanvas.${category.text}`);
        if (category.field === 'rateOfSale') {
          rowCategoryText = this.$t('assortmentCanvas.sales', { currency: this.requiredCurrency });
        }
        return {
          rowCategory: rowCategoryText,
          ...keys(productByCanvas).reduce((acc, canvasId) => {
            const product = productByCanvas[canvasId];
            const clusterId = this.canvasesById[canvasId].clusterId;
            return {
              ...acc,
              [clusterId || UNCLUSTERED_ID]: this.getCellData(category, product, clusterId),
            };
          }, {}),
        };
      });

      this.$nextTick(() => {
        if (this.columnApi) this.columnApi.autoSizeColumn('rowCategory');
      });
    },

    async fetchProductInformation(productKey) {
      this.productDistributionByCluster = [];
      const canvasesInStoreClass = map(
        filter(this.canvases, { storeClassId: this.getDashboardProduct.storeClassId }),
        '_id'
      );
      const clusteredData = await this.fetchProductDistribution({
        productKey,
        canvases: canvasesInStoreClass,
      });

      this.buildTableContents(clusteredData);
    },

    resetColumnsVisibility() {
      if (this.invisibleColumns.length && this.columnApi) {
        this.columnApi.setColumnsVisible(this.visibleColumns, true);
        this.columnApi.setColumnsVisible(this.invisibleColumns, false);
      }
    },
    resizeClusters() {
      this.gridApi.sizeColumnsToFit();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
::v-deep {
  // add border to third row
  .separator {
    border: 1px solid $assortment-ag-grid-header-separator-colour;
    border-width: 0 0 thin 0;
  }

  .actions-column {
    width: 30px !important;
    &span {
      color: $assortment-primary-colour;
      font-weight: bold;
      font-size: large;
    }
  }

  // force the pinned column to the right to not be so large
  .ag-pinned-right-header,
  .ag-pinned-right-header .ag-header-row,
  .ag-pinned-right-cols-container {
    width: 30px !important;
    max-width: 30px !important;
    min-width: 30px !important;
  }

  .ag-theme-custom--attributes .ag-cell {
    line-height: 20px !important;
    height: 20px !important;
  }

  .ag-theme-custom--attributes {
    .ag-body-viewport {
      border-bottom: none !important;
    }
  }

  .ag-cell-value {
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}
</style>
