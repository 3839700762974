<template>
  <v-row class="filter-bar mb-3 flex-grow-0">
    <v-col cols="8" class="py-2 px-0">
      <div class="d-flex assortment-filter">
        <span class="assortment-text mx-2">{{ title }}</span>
        <docs-link v-if="docPath" :link="`toolguide/${docPath}.html`" />
        <div v-if="showNotImplemented" class="d-flex filter-box">
          <span class="mx-2">{{ $t('general.filter') }}</span>
          <v-select
            item-value="_id"
            hide-details
            item-text=""
            white
            disabled
            :placeholder="$tkey('selectedFilterOption')"
            class="rtls-select rtls-select--white"
            :menu-props="{ 'z-index': 200 }"
          />
        </div>
        <div v-if="!archived" class="d-flex filter-box">
          <span class="mx-2">{{ $t('actions.sortBy') }}:</span>
          <rtls-select
            v-model="sortOrder"
            :items="sortOptions"
            item-value="key"
            hide-details
            item-text="value"
            :placeholder="$tkey('selectedFilterOption')"
            class="rtls-select rtls-select--white"
            :menu-props="{ 'z-index': 200 }"
            white
            width="200"
            @change="onSort"
          />
        </div>
      </div>
    </v-col>
    <v-col cols="4" class="d-flex py-2 px-0 justify-end">
      <div class="d-flex assortment-filter justify-end">
        <v-btn class="clear" :disabled="disableClear" primary @click="clearSearch">x</v-btn>

        <rtls-search
          v-model="searchText"
          :placeholder="placeholder"
          clear-on-selection
          @input="onSearchDebounced"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { debounce, capitalize } from 'lodash';
import SortOptions from '@enums/workpackage-sort-options';

export default {
  localizationKey: 'workpackages.toolbar',

  props: {
    archived: {
      type: Boolean,
      required: false,
      default: () => false,
    },

    docPath: {
      type: String,
      required: false,
      default: '',
    },

    entity: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    const sortKeys = [
      SortOptions.byNameAsc,
      SortOptions.byNameDesc,
      SortOptions.byCreatedDateAsc,
      SortOptions.byCreatedDateDesc,
      SortOptions.byLastModifiedDateAsc,
      SortOptions.byLastModifiedDateDesc,
    ];
    const sortOptions = sortKeys.map(option => {
      return { key: option, value: this.$tkey(`sortOptions.${option}`) };
    });
    return {
      sortOptions,
      searchText: '',
      sortOrder: null,
      onSearchDebounced: debounce(
        () => {
          this.$emit('on-search', this.searchText);
        },
        200,
        {
          maxWait: 500,
        }
      ),
    };
  },

  computed: {
    ...mapGetters('context', ['showNotImplemented']),

    title() {
      const localisationKey = this.archived ? 'archived' : 'active';
      return capitalize(
        this.$t(`workpackages.toolbar.${localisationKey}`, {
          entity: this.$t(`entities.${this.entity}`),
        })
      );
    },

    placeholder() {
      const entityTranslation = this.$t(`entities.${this.entity}`);
      return `${entityTranslation} ${this.$tkey('name')}`;
    },

    disableClear() {
      return this.searchText.length === 0;
    },
  },

  methods: {
    onSort() {
      this.$emit('on-sort', this.sortOrder);
    },

    clearSearch() {
      this.searchText = '';
      this.$emit('on-search', this.searchText);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.filter-bar {
  margin-top: 15px;
  border-top: 1px solid $assortment-primary-colour;
  background-color: $assortment-filter-bar-bg-colour;

  .assortment-text {
    font-size: 1.4rem;
    font-weight: 600;
    color: $assortment-text-colour;
  }

  .assortment-filter {
    width: 100%;
    align-items: baseline;
  }

  .date-filter {
    width: 160px;
  }

  .rtls-search {
    width: 250px;
    max-width: 250px;
    margin-right: 20px;
  }

  .rtls-select {
    width: 300px;
    max-width: 300px;
  }

  .filter-box {
    align-items: center;
    border-left: #6e8b9c 1px solid;
    border-right: #6e8b9c 1px solid;
    padding: 0 20px;
    margin: 0 20px;
  }

  .clear {
    width: 24px !important;
    min-width: 24px;
    border-radius: 0 !important;
    font-weight: bold;
    max-height: 27px;
    height: 27px !important;
  }
}
</style>
