import axios from 'axios';
import to from 'await-to-js';
import SIZE_TYPES from '@enums/size-types';
import { get, forEach, cloneDeep, omit, size, some, isNaN, merge, keyBy, groupBy } from 'lodash';
import moment from 'moment';
import Vue from 'vue';

import i18n from '@/js/vue-i18n';
import * as WORK_PACKAGE_TYPE from '@enums/workpackage-type';
import * as WORK_PACKAGE_BUSINESS_UNIT from '@enums/workpackage-business-unit';
import * as SORT_DIRECTION from '@enums/sort-direction';
import { jobInProgressStatuses } from '@enums/jobapi';
import baseStatus from '@sharedModules/data/baseStatus';

import handleErrorMessages from '../utils/validation';

export const name = 'workpackages';

export function createWorkpackage(isTemplate, withoutBundle = false) {
  // returns default workpackage data
  const utcNow = moment.utc();
  const dateTime = moment().format('YYYY-MM-DD HH:mm:ss');
  let wpName = i18n.t('entities.workpackage');
  if (isTemplate) wpName = `${i18n.t('entities.template')} ${wpName}`;

  return {
    // WP name is after user offset from utc
    name: `${wpName} - ${dateTime}`,
    description: '',
    businessUnit: WORK_PACKAGE_BUSINESS_UNIT.DAIRY,
    type: WORK_PACKAGE_TYPE.ASSORTMENT_FULL_RESET,
    targetLaunchDate: utcNow,
    isTemplate: !!isTemplate,
    withoutBundle,
    status: isTemplate ? merge({}, baseStatus.workpackageBaseStatusObject) : {},
  };
}

const workpackageDefaultFields = [
  'name',
  'type',
  'description',
  'targetLaunchDate',
  'productCount',
  'storeCount',
  'jobs',
  'lastModifiedDate',
  'creationDate',
  'createdBy',
  'templateId',
  'bundleId',
];

const store = {
  namespaced: true,

  state: {
    selectedWorkpackage: null,
    workpackages: [],
    archivedWorkpackages: [],
    workpackageTemplates: [],
    archivedWorkpackageTemplates: [],
    archivedWorkpackagesTotalCount: 0,
    archivedWorkpackageTemplatesTotalCount: 0,
    archivedWorkpackagesCurrentPage: 1,
    archivedWorkpackageTemplatessCurrentPage: 1,
    isScenarioPanelExpanded: false,
    isWPSetupExpanded: false,
    loading: false,
    loadingArchivedWorkpackages: false,
    loadingWorkpackageTemplates: false,
    wpValidation: false,
    productsHierarchy: null,
    hierarchyDepth: 0,
    isImportingFromPlano: false,
  },

  getters: {
    activeWorkpackages: state => state.workpackages,
    workpackageTemplates: state => state.workpackageTemplates,
    archivedWorkpackages: state => state.archivedWorkpackages,
    archivedWorkpackagesTotalCount: state => state.archivedWorkpackagesTotalCount,
    archivedWorkpackageTemplatesTotalCount: state => state.archivedWorkpackageTemplatesTotalCount,
    getWorkpackageById: state => id => state.workpackages.find(el => el._id === id),
    workpackagesById: state => keyBy(state.workpackages, '_id'),

    stores: state => get(state, 'selectedWorkpackage.stores', []),

    isFullResetWP: state =>
      get(state, 'selectedWorkpackage.type') === WORK_PACKAGE_TYPE.ASSORTMENT_FULL_RESET,

    isSimpleSwapsWP: state =>
      get(state, 'selectedWorkpackage.type') === WORK_PACKAGE_TYPE.ASSORTMENT_SIMPLE_SWAPS,

    // AOV3-1247 TODO: remove this once running multiple copy jobs is fixed
    isCopyWorkpackageRunning: state => {
      return some(
        state.workpackages.map(wp =>
          jobInProgressStatuses.includes(get(wp, 'jobs.copyWorkpackage.status'))
        )
      );
    },
    getProductsHierarchy: state => {
      return state.productsHierarchy;
    },
    hierarchyDepth: state => state.hierarchyDepth,

    getSelectedWorkpackageExcludedDates: state => forecastStartDate => {
      const dbExcludedDates = state.selectedWorkpackage.performanceExcludedDates;
      const weekStartDates = dbExcludedDates.filter(d => {
        return moment(d).weekday() === 1 && moment(d) > moment(forecastStartDate);
      });
      return weekStartDates;
    },

    getUnitOfMeasure: (state, getters, rootState, rootGetters) => workpackage => {
      const sizeTypesSuffixes = {
        [SIZE_TYPES.linearSpace]: `${rootGetters['context/getUnitOfMeasure']}`,
        [SIZE_TYPES.horizontalSpace]: `${rootGetters['context/getUnitOfMeasure']}^2`,
        [SIZE_TYPES.frontalSpace]: `${rootGetters['context/getUnitOfMeasure']}^2`,
        [SIZE_TYPES.cubicSpace]: `${rootGetters['context/getUnitOfMeasure']}^3`,
        [SIZE_TYPES.productCount]: '#',
      };
      const fillInSelection = get(workpackage, 'fillInSelection');
      return get(sizeTypesSuffixes, fillInSelection, '');
    },

    // Get the section status for the workpackage
    workpackageStatus: () => workpackage => {
      // Overwrite the actual status over the base
      const actualStatus = merge({}, baseStatus.workpackageBaseStatusObject, workpackage.status);

      // Calculate if general sections are available
      actualStatus.scope.available = true;
      actualStatus.inputs.available = true;

      // Calculate if general sub sections are available for editing
      actualStatus.scope.datesAssortmentGroups.available = true;
      actualStatus.scope.products.available = true;
      actualStatus.scope.stores.available = true;
      actualStatus.scope.additionalProducts.available = true;

      actualStatus.inputs.attributeEditor.available = true;
      actualStatus.inputs.cannGroups.available = true;

      return actualStatus;
    },

    // Get the section status for the selected workpackage
    selectedWorkpackageStatus: (state, getters) => {
      return getters.workpackageStatus(state.selectedWorkpackage);
    },

    isWorkpackageSetupRunning(state) {
      const workpackageStatus = get(state.selectedWorkpackage, 'jobs.workpackageSetup.status');
      return jobInProgressStatuses.includes(workpackageStatus);
    },

    templatesById(state) {
      return keyBy(state.workpackageTemplates, '_id');
    },

    workpackagesByBundle(state, getter, rootState) {
      const groupedWorkpackages = groupBy(state.workpackages, 'bundleId');
      // Allows workpackages that are not assigned to a bundle to be retrieved
      const noBundle = { _id: null };
      const bundles = [noBundle, ...rootState.bundles.bundles];

      return bundles.reduce(
        (acc, { _id }) => ({
          ...acc,
          [_id]: groupedWorkpackages[_id || undefined],
        }),
        {}
      );
    },
  },

  mutations: {
    addProductsHierarchy(state, products) {
      state.productsHierarchy = products;
    },

    addWorkpackage(state, workpackage) {
      if (workpackage.isTemplate) {
        state.workpackageTemplates.push(workpackage);
      }
      state.workpackages.push(workpackage);
    },

    setWorkpackages(state, workpackages) {
      state.workpackages = workpackages;
    },

    setWorkpackageTemplates(state, workpackageTemplates) {
      state.workpackageTemplates = workpackageTemplates;
    },

    setArchivedWorkpackages(state, data) {
      const { workpackages, totalCount } = data;
      state.archivedWorkpackages = workpackages;
      state.archivedWorkpackagesTotalCount = totalCount;
    },

    setArchivedWorkpackageTemplates(state, data) {
      const { workpackages, totalCount } = data;
      state.archivedWorkpackageTemplates = workpackages;
      state.archivedWorkpackageTemplatesTotalCount = totalCount;
    },

    setArchivedWorkpackagesCurrentPage(state, page) {
      state.archivedWorkpackagesCurrentPage = page;
    },

    setArchivedWorkpackageTemplatesCurrentPage(state, page) {
      state.archivedWorkpackageTemplatessCurrentPage = page;
    },

    setSelectedWorkpackage(state, workpackage) {
      state.selectedWorkpackage = workpackage;
    },

    deleteLastWorkpackage(state) {
      state.workpackages.pop();
    },

    deleteWorkpackageById(state, wpId) {
      const workpackages = [];
      state.workpackages.forEach(wp => {
        if (wp._id === wpId) return;
        workpackages.push(wp);
      });
      state.workpackages = workpackages;
    },

    deleteWorkpackageTemplateById(state, wpId) {
      const workpackageTemplates = [];
      state.workpackageTemplates.forEach(wp => {
        if (wp._id === wpId) return;
        workpackageTemplates.push(wp);
      });
      state.workpackageTemplates = workpackageTemplates;
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setLoadingWorkpackageTemplates(state, isLoading) {
      state.loadingWorkpackageTemplates = isLoading;
    },

    setLoadingArchivedWorkpackages(state, isLoading) {
      state.loadingArchivedWorkpackages = isLoading;
    },

    setExpanded(state, value) {
      state.expanded = value;
    },

    setScenarioPanelExpanded(state, value) {
      state.isScenarioPanelExpanded = value;
    },

    setWorkpackageSetupExpanded(state, value) {
      state.isWPSetupExpanded = value;
    },

    // Merge an update into a specific workpackage
    updateWorkpackage(state, { field, workpackageId, updates }) {
      const workpackageToUpdate = state.workpackages.find(wp => wp._id === workpackageId);

      if (workpackageToUpdate) Vue.set(workpackageToUpdate, field, updates);
      else {
        const templateToUpdate = state.workpackageTemplates.find(wp => wp._id === workpackageId);
        if (templateToUpdate) Vue.set(templateToUpdate, field, updates);
      }
    },

    updateNoteCount(state, { workpackageId, difference = 1 }) {
      const workpackageToUpdate = state.workpackages.find(wp => wp._id === workpackageId);

      if (workpackageToUpdate) {
        Vue.set(workpackageToUpdate, 'totalNotes', workpackageToUpdate.totalNotes + difference);
      }
    },

    setWorkpackageById(state, workpackage) {
      const indexOfWorkpackage = state.workpackages.findIndex(wp => wp._id === workpackage._id);
      Vue.set(state.workpackages, indexOfWorkpackage, workpackage);
    },

    // Merge an update into the selected workpackage
    updateSelectedWorkpackage(state, { field, updates }) {
      Vue.set(state.selectedWorkpackage, field, updates);
    },

    setHierarchyDepth(state, newDepth) {
      state.hierarchyDepth = newDepth;
    },

    setIsImportingFromPlano(state, isImporting) {
      state.isImportingFromPlano = isImporting;
    },
  },

  actions: {
    /**
     * Loads all of the state needed for a page based on the scenarioId and canvasId in it's params.
     * If loading state by workpackageId, the state will set based on the first scenario found for that
     * workpackage.
     */
    async loadState({ commit, dispatch }, { scenarioId, canvasId, workpackageId }) {
      commit('setLoading', true);

      if (workpackageId) {
        // If workpackageId exits, use it to fetch the scenarios
        const scenarioResult = await dispatch(
          'scenarios/fetchScenarios',
          { params: { where: { workpackageId } } },
          { root: true }
        );
        // Set the scenarioId to the id of the first scenario returned
        // A template will only have one scenario
        scenarioId = get(scenarioResult[0], '_id');

        const [workpackageErr, workpackage] = await to(
          axios.get(`/api/workpackages/${workpackageId}`)
        );
        if (workpackageErr) throw new Error(workpackageErr.message);
        await dispatch('setSelectedWorkpackage', workpackage.data);
        commit('setLoading', false);
      }

      if (!scenarioId) {
        // If there is no scenario to load, ensure scenario state is reset
        await commit('scenarios/setSelectedScenario', {}, { root: true });
        return;
      }

      const [err, result] = await to(
        axios.get(`/api/state/scenario/${scenarioId}`, { params: { canvasId } })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);

      if (!workpackageId) {
        // If workpackageId does not exits, use workpackageId from scenario
        // to fetch state for workpackage scenarios
        await dispatch(
          'scenarios/fetchScenarios',
          { params: { where: { workpackageId: result.data.workpackage._id } } },
          { root: true }
        );
        await dispatch('setSelectedWorkpackage', result.data.workpackage);
      }

      await commit('scenarios/setSelectedScenario', result.data.scenario, { root: true });
      // fetch canvases after current scenario was set
      await dispatch('assortmentCanvas/fetchCanvases', {}, { root: true });

      if (result.data.canvas) {
        commit('assortmentCanvas/setSelectedAssortmentCanvas', result.data.canvas, { root: true });
      }

      // Open event stream for events for this sceanrio, after we update the current selected scenario
      dispatch(
        'events/resetEventStreamWithScenarioId',
        { scenarioId: result.data.scenario._id },
        { root: true }
      );
    },

    async getProductHierarchy({ commit }, params = {}) {
      commit('setLoading', true);
      const [err, result] = await to(axios.get('/api/tool-data/product-hierarchy', { params }));
      commit('setLoading', false);
      if (err || (result.data && result.data.errors)) {
        throw new Error(i18n.t('errors.toolData.unableToGetProductsHierarchyTree'));
      }
      return result.data;
    },

    async getProductHierarchyDepth({ commit }, params = {}) {
      commit('setLoading', true);
      const [err, result] = await to(
        axios.get('/api/tool-data/product-hierarchy/depth', { params })
      );
      commit('setLoading', false);
      if (err || get(result, 'data.errors')) {
        throw new Error(i18n.t('errors.toolData.unableToGetProductsHierarchyDepth'));
      }
      commit('setHierarchyDepth', result.data.depth);
      return result.data;
    },

    async getProductsHierarchyProductsData({ commit, state }, { productCategoryKeys }) {
      commit('setLoading', true);
      const workpackageId = state.selectedWorkpackage._id;
      const [err, result] = await to(
        axios.post('/api/tool-data/products-hierarchy-data', { productCategoryKeys, workpackageId })
      );
      commit('setLoading', false);
      if (err || (result.data && result.data.errors)) {
        throw new Error(
          i18n.t('errors.toolData.unableToGetProductsForHierarchyTree', { productCategoryKeys })
        );
      }
      return result.data;
    },

    async addWorkpackage({ commit }, { isTemplate, templateId, bundleId, withoutBundle }) {
      commit('setLoading', true);
      const wpBody = { ...createWorkpackage(isTemplate, withoutBundle), templateId, bundleId };
      const [err, result] = await to(axios.post('/api/workpackages/', wpBody));
      commit('setLoading', false);
      if (err || (result.data && result.data.errors)) {
        throw new Error(`Unable to create workpackage: ${result.name}`);
      }
      if (result.data.insertedWorkpackage)
        commit('addWorkpackage', result.data.insertedWorkpackage);
    },

    async fetchSingleWorkpackage({ commit }, options = {}) {
      const { includeStores, includeAssortmentGroups, workpackageId } = options;
      commit('setLoading', true);
      const pickedFields = workpackageDefaultFields;
      if (includeStores) {
        pickedFields.push('stores');
      }
      if (includeAssortmentGroups) {
        pickedFields.push('selectedAssortmentGroupSettings');
      }
      const params = {
        sortField: 'creationDate',
        sortDirection: SORT_DIRECTION.ascending,
        pick: pickedFields,
      };
      const [err, result] = await to(axios.get(`/api/workpackages/${workpackageId}`, { params }));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      const workpackage = result.data;
      if (options.isStatelessFetch) return workpackage;
      commit('setWorkpackageById', workpackage);
    },

    async fetchWorkpackages({ commit, dispatch, state }, customParams = {}) {
      commit('setLoading', true);

      const pickedFields = workpackageDefaultFields.concat(
        get(customParams, 'pickExtra', ['bundleId'])
      );
      // Return workpackage templates if requested
      const shouldGetTemplates = get(customParams, 'shouldGetTemplates', false);
      // Only fetch workpackages for bundle if bundleId param is provided
      const bundleId = get(customParams, 'bundleId');

      const query = {
        archived: { $in: [null, false] },
        isTemplate: shouldGetTemplates ? true : { $ne: true },
        ...(bundleId && { bundleId }),
      };

      const params = {
        sortField: 'creationDate',
        sortDirection: SORT_DIRECTION.ascending,
        pick: pickedFields,
        where: query,
      };
      // override defaults
      if (customParams.pick) params.pick = customParams.pick;
      const [err, result] = await to(axios.get('/api/workpackages', { params }));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      const sortedWorkpackages = result.data.workpackages.sort((a, b) =>
        a.creationDate.localeCompare(b.creationDate)
      );
      commit(
        shouldGetTemplates ? 'setWorkpackageTemplates' : 'setWorkpackages',
        sortedWorkpackages
      );
      // when this action is called from template's section, we also update the currently select wp
      if (shouldGetTemplates && state.selectedWorkpackage) {
        dispatch('refreshWorkpackageInformation', { workpackageId: state.selectedWorkpackage._id });
      }
    },

    async fetchArchivedWorkpackages({ commit }, filterParams = {}) {
      const { limit, offset, regexSearch } = filterParams;
      commit('setLoadingArchivedWorkpackages', true);
      const pickedFields = [...workpackageDefaultFields, ...['archived', 'archiveDate']];
      // Return archived workpackage templates if requested
      const shouldGetTemplates = get(filterParams, 'shouldGetTemplates', false);

      const query = {
        archived: true,
        isTemplate: shouldGetTemplates ? true : { $ne: true },
      };

      let params = {
        sortField: 'creationDate',
        sortDirection: SORT_DIRECTION.descending,
        pick: pickedFields,
        where: query,
        regexSearch,
      };
      if (!isNaN(limit) && !isNaN(offset)) {
        params = { ...params, ...{ limit, offset } };
      }
      const [err, result] = await to(axios.get('/api/workpackages', { params }));
      commit('setLoadingArchivedWorkpackages', false);
      if (err) throw new Error(err.message);
      commit('setArchivedWorkpackages', {
        workpackages: result.data.workpackages,
        totalCount: result.data.totalCount,
      });
    },

    async updateWorkpackage(
      { commit, getters, state, dispatch },
      { workpackageId, updates, omitStoreUpdate }
    ) {
      const omitUpdate = omitStoreUpdate || false;
      commit('setLoading', true);
      if (updates._id && updates._id !== workpackageId) {
        throw new Error('mismatched workpackageId on update');
      }
      const [err, result] = await to(axios.patch(`/api/workpackages/${workpackageId}`, updates));
      commit('setLoading', false);
      if (err) {
        const message = get(err, ['response', 'data', 'message'], err.message);
        throw new Error(message);
      }

      if (result && !omitUpdate) {
        const { lastModifiedDate, storeCount, productCount } = result.data.updates;
        // AOV3-610 TODO: commit 1 mutation here
        forEach(
          { ...omit(updates, ['_id']), lastModifiedDate, storeCount, productCount },
          (value, key) => {
            commit('updateWorkpackage', { workpackageId, field: key, updates: value });
          }
        );
      }
      if (workpackageId === get(state.selectedWorkpackage, '_id')) {
        const workpackage = getters.getWorkpackageById(workpackageId);
        dispatch('setSelectedWorkpackage', workpackage);
      }
    },

    async deleteWorkpackage({ rootState, commit, dispatch }, workpackage) {
      if (!workpackage._id) {
        commit('deleteLastWorkpackage');
        return;
      }
      commit('setLoading', true);
      const [err, result] = await to(axios.delete(`/api/workpackages/${workpackage._id}`));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      if (result.data && result.data.errors) {
        throw new Error(`Unable to delete workpackage: ${workpackage.name}`);
      }
      const bundleId = get(rootState.bundles.selectedBundle, '_id');
      dispatch('fetchWorkpackages', { bundleId, shouldGetTemplates: workpackage.isTemplate });
    },

    async copyWorkpackage({ commit, dispatch }, workpackage) {
      commit('setLoading', true);
      dispatch('setSelectedWorkpackage', workpackage);
      const [err, response] = await to(axios.post(`/api/workpackages/copy/${workpackage._id}`));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      dispatch('updateWorkpackageJobStatus');
      return response;
    },

    // Checks the statuses of jobs given a list of workpackage ids. Updating the state for any that have completed
    async afterWorkpackageSetupComplete({ rootState, commit, dispatch }, { message }) {
      const { workpackageId } = message;
      const [err, res] = await to(axios.get(`/api/workpackages/${workpackageId}`));
      if (err) throw new Error(err.message);

      const { name: wpName, jobs, isTemplate } = res.data;
      commit('updateWorkpackage', { workpackageId, field: 'jobs', updates: jobs });
      const bundleId = get(rootState.bundles.selectedBundle, '_id');
      dispatch('fetchWorkpackages', { bundleId, shouldGetTemplates: isTemplate });

      const translationKey = isTemplate ? 'templateSetup' : 'workpackageSetup';
      const content = i18n.t(`notifications.${translationKey}.finished`, { workpackage: wpName });
      const color = 'success';
      dispatch('snackbar/showSnackbar', { content, color }, { root: true });

      if (isTemplate) dispatch('afterTemplateSetupComplete', res.data);
    },

    async afterWorkpackageSetupFailed({ rootState, commit, dispatch }, { message }) {
      const [err, res] = await to(axios.get(`/api/workpackages/${message.workpackageId}`));
      if (err) throw new Error(err.message);

      const wp = res.data;
      commit('updateWorkpackage', { workpackageId: wp._id, field: 'jobs', updates: wp.jobs });
      const bundleId = get(rootState.bundles.selectedBundle, '_id');
      dispatch('fetchWorkpackages', { bundleId, shouldGetTemplates: wp.isTemplate });

      const translationKey = wp.isTemplate ? 'templateSetup' : 'workpackageSetup';
      const content = i18n.t(`notifications.${translationKey}.failed`, { workpackage: wp.name });
      const color = 'error';
      dispatch('snackbar/showSnackbar', { content, color }, { root: true });
    },

    async afterTemplateSetupComplete({ dispatch }, workpackage) {
      const { _id: workpackageId, isTemplate } = workpackage;
      const scenario = {
        name: `${i18n.t('entities.template')} ${i18n.t('entities.scenario')}`,
        workpackageId,
        isTemplate,
      };
      const [scenarioErr, scenarioRes] = await to(
        dispatch('scenarios/createScenario', { scenario }, { root: true })
      );
      if (scenarioErr) throw new Error(scenarioErr.message);
      dispatch('scenarios/loadScenario', scenarioRes.data._id, { root: true });
    },

    async afterCopyWorkpackageComplete({ rootState, commit, dispatch }, { message }) {
      const [err, res] = await to(axios.get(`/api/workpackages/${message.workpackageId}`));
      if (err) throw new Error(err.message);

      const wp = res.data;
      commit('updateWorkpackage', { workpackageId: wp._id, field: 'jobs', updates: wp.jobs });
      const bundleId = get(rootState.bundles.selectedBundle, '_id');
      dispatch('fetchWorkpackages', { bundleId, shouldGetTemplates: wp.isTemplate });

      const content = i18n.t('notifications.copyWorkpackage.finished', { name: wp.name });
      const color = 'success';
      dispatch('snackbar/showSnackbar', { content, color }, { root: true });
    },

    async afterCopyWorkpackageFailed({ rootState, commit, dispatch }, { message }) {
      const [err, res] = await to(axios.get(`/api/workpackages/${message.workpackageId}`));
      if (err) throw new Error(err.message);

      const wp = res.data;
      commit('updateWorkpackage', { workpackageId: wp._id, field: 'jobs', updates: wp.jobs });
      const bundleId = get(rootState.bundles.selectedBundle, '_id');
      dispatch('fetchWorkpackages', { bundleId, shouldGetTemplates: wp.isTemplate });

      const content = i18n.t('notifications.copyWorkpackage.failed', { workpackage: wp.name });
      const color = 'error';
      dispatch('snackbar/showSnackbar', { content, color }, { root: true });
    },

    async toggleWorkpackageSetupExpanded({ commit, state, dispatch }, { workpackage }) {
      // Collapse scenario panel in case it's expanded
      commit('setScenarioPanelExpanded', false);

      if (
        !workpackage ||
        ((state.selectedWorkpackage || {})._id === workpackage._id && state.isWPSetupExpanded)
      ) {
        dispatch('setSelectedWorkpackage', null);
        commit('setWorkpackageSetupExpanded', false);
      } else {
        const { data } = await axios.get(`/api/workpackages/${workpackage._id}`);
        dispatch('setSelectedWorkpackage', data);
        commit('setWorkpackageSetupExpanded', true);
      }
    },

    toggleScenarioPanelExpanded({ commit, state, dispatch }, { workpackage } = {}) {
      // Collapse workpackage scope in case it's expanded
      commit('setWorkpackageSetupExpanded', false);

      if (
        !workpackage ||
        ((state.selectedWorkpackage || {})._id === workpackage._id && state.isScenarioPanelExpanded)
      ) {
        commit('scenarios/setScenarios', [], { root: true });
        dispatch('setSelectedWorkpackage', null);
        commit('setScenarioPanelExpanded', false);
      } else {
        commit('scenarios/setScenarios', [], { root: true });
        dispatch('setSelectedWorkpackage', workpackage);
        commit('setScenarioPanelExpanded', true);
      }
    },

    toggleExpanded({ commit, state }, workpackage) {
      const value = state.expanded === workpackage ? null : workpackage;
      commit('setExpanded', value);
    },

    async processCSV({ commit, state, dispatch }, { fileId, mappings, delimiter, service }) {
      const workpackage = cloneDeep(state.selectedWorkpackage);
      commit('setLoading', true);
      const [err, res] = await to(
        axios.post(`/api/csv/${service}/process/${fileId}`, {
          mappings,
          delimiter,
        })
      );
      commit('setLoading', false);
      // TODO: Remove this and use global interceptor on AOV3-159
      if (err) {
        const key = get(err, 'response.data.messageKey', err.message);
        const message = i18n.t(key, get(err, 'response.data.messageParams', []));
        dispatch('snackbar/showError', message, { root: true });

        return;
      }
      const { messages, tableRows } = res.data;
      if (size(tableRows)) workpackage.stores = tableRows;

      dispatch('setSelectedWorkpackage', workpackage);
      if (size(messages)) {
        dispatch('alerts/showMessages', messages, { root: true });
      }

      return tableRows;
    },

    /*
     * Action that sets the selected workpackage and opens a workpackage eventstream
     */
    setSelectedWorkpackage({ commit, dispatch }, workpackage) {
      commit('setSelectedWorkpackage', workpackage);

      if (workpackage) {
        // Open event stream for events of this workpackage
        dispatch(
          'events/resetEventStreamWithWorkpackageId',
          { workpackageId: workpackage._id },
          { root: true }
        );
      }
    },

    /*
     * This method is responsible for making a full refresh of the workpackage information on the screen instead of the
     * partial update above, which is used on the polling of the screen.
     */
    async refreshWorkpackageInformation(
      { commit, state, dispatch },
      { workpackageId, forceStateUpdate = false }
    ) {
      const { data } = await axios.get(`/api/workpackages/${workpackageId}`);
      commit('setWorkpackageById', data);
      if (forceStateUpdate) {
        commit('addWorkpackage', data);
      }
      if (workpackageId === get(state, 'selectedWorkpackage._id') || forceStateUpdate) {
        dispatch('setSelectedWorkpackage', data);
      }
    },

    async runWorkpackageSetupJob({ commit, state, dispatch }) {
      const workpackageId = state.selectedWorkpackage._id;
      commit('setLoading', true);
      const [err, response] = await to(axios.post(`/api/workpackages/${workpackageId}/run-setup`));
      commit('setLoading', false);
      dispatch('updateWorkpackageJobStatus');
      if (err) throw new Error(err.message);
      handleErrorMessages({ response, dispatch, options: { errorSuffix: 'invalidStores' } });
    },

    /*
     * This method partially updates selected workpackage with job status acquired from the events stream
     */
    async updateWorkpackageJobStatus({ commit, state }, { message } = {}) {
      if (!state.selectedWorkpackage) return;
      const workpackageId = get(message, 'workpackageId', state.selectedWorkpackage._id);
      const pick = ['_id', 'jobs'];
      const [err, res] = await to(
        axios.get(`/api/workpackages/${workpackageId}`, {
          params: { pick },
        })
      );
      if (err) throw new Error(err.message);

      if (res.data.jobs) {
        commit('updateWorkpackage', { workpackageId, field: 'jobs', updates: res.data.jobs });
      }
    },

    async deleteTemplate({ commit, dispatch }, template) {
      commit('setLoading', true);
      const [err, result] = await to(axios.delete(`/api/workpackages/${template._id}/template`));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      if (result.data && result.data.errors) {
        throw new Error(`Unable to delete template: ${template.name}`);
      }
      dispatch('fetchWorkpackages', { shouldGetTemplates: true, pickExtra: ['status'] });
    },
  },
};

export default store;
