import { render, staticRenderFns } from "./stats.vue?vue&type=template&id=0c89e5ea&scoped=true"
import script from "./stats.vue?vue&type=script&lang=js"
export * from "./stats.vue?vue&type=script&lang=js"
import style0 from "./stats.vue?vue&type=style&index=0&id=0c89e5ea&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c89e5ea",
  null
  
)

export default component.exports