import { reduce } from 'lodash';
import { jobStatuses, jobStatusesIconsMappings } from '@enums/jobapi';
import { STORE_EXECUTION, KASU, HASU, AOC, FURNITURE } from '@enums/extract-types';

function reports({ extracts, reportType }) {
  return reduce(
    extracts,
    (result, e) => {
      if (e.extractType === reportType) {
        let extractType = null;
        let additionalOutputParams = {};
        if ([KASU, HASU, AOC].includes(e.extractType)) {
          extractType = 'compoundExtract';

          additionalOutputParams = {
            blobId: e.blobId,
            extractSettingsId: e.extractSettingsId,
          };
        } else {
          switch (e.extractType) {
            case STORE_EXECUTION:
              extractType = 'storeExecutionExtracts';
              break;
            case FURNITURE:
              extractType = 'furnitureExtract';
              break;

            default:
              extractType = 'extractsGeneration';
              break;
          }
        }

        const status =
          e.jobs && e.jobs[extractType]
            ? jobStatusesIconsMappings[e.jobs[extractType].status]
            : jobStatusesIconsMappings[jobStatuses.failed];

        result.push({
          filenameOnDownload: e.filenameOnDownload,
          fileId: e.fileId,
          extractType: e.extractType,
          extractDatetime: e.extractDatetime,
          status,
          originalJobStatus: 'finished',
          ...additionalOutputParams,
        });
      }

      return result;
    },
    []
  );
}

export default {
  reports,
};
