var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "target-launch" },
    [
      _c("h4", { staticClass: "header" }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("rtls-calendar-picker", {
        staticClass: "dense-calendar",
        attrs: {
          value: _vm.dateValue,
          disabled: _vm.disabled,
          "display-date-format": _vm.getDateFormats.long,
          "offset-x": "",
          "bold-text": "",
        },
        on: { input: _vm.changeTargetLaunchDate },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }