import axios from 'axios';
import to from 'await-to-js';

const store = {
  namespaced: true,
  state: {
    loading: false,
    uploadedFile: null,
  },

  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setUploadedFile(state, file) {
      state.uploadedFile = file;
    },
  },

  actions: {
    async getDownloadToken({ commit }) {
      commit('setLoading', true);
      const [err, res] = await to(axios.post('/api/token/download'));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      return res.data.downloadToken;
    },

    async deleteFile({ commit }, { fileId }) {
      commit('setLoading', true);
      const [err] = await to(axios.delete(`/api/file/${fileId}`));
      commit('setLoading', false);
      commit('setUploadedFile', null);
      if (err) throw new Error(err.message);
    },

    async uploadCSV({ commit }, { formData = {}, service }) {
      commit('setLoading', true);
      const [err, res] = await to(
        axios.post(`/api/csv/${service}/upload`, formData, {
          headers: {
            // Now axios 1.x set the Content-Type to 'application/json' automatically so in order to have the value as 'FormData/HTMLForm' we need to set as undefined
            // You can read more about in the axios migration guide to v1.x on https://github.com/bmuenzenmeyer/axios-1.0.0-migration-guide?tab=readme-ov-file#multipart-form-data-is-no-longer-automatically-set
            'Content-Type': undefined,
          },
        })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      commit('setUploadedFile', res.data.uploadedFile);
      return res.data.uploadedFile;
    },

    async uploadFileToFtp({ commit }, file) {
      const { blobId } = file;
      commit('setLoading', true);
      const [err, res] = await to(axios.post(`/api/file/export-to-ftp/${blobId}`, { file }));
      if (err) throw new Error(err.message);
      commit('setLoading', false);
      return res.data.uploadedFile;
    },
  },
};

export default store;
