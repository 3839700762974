import reportTypes from '@enums/reporting-types';

const powerBITabs = [
  {
    key: reportTypes.market,
    translationKey: 'reportingPowerBIPage.tabs.market',
    position: 1,
  },
  {
    key: reportTypes.clickAndCollect,
    translationKey: 'reportingPowerBIPage.tabs.clickAndCollect',
    position: 2,
  },
  {
    key: reportTypes.segmentation,
    translationKey: 'reportingPowerBIPage.tabs.segmentation',
    position: 3,
  },
  {
    key: reportTypes.teardown,
    translationKey: 'reportingPowerBIPage.tabs.teardown',
    position: 4,
  },
];

export default {
  powerBITabs,
};
