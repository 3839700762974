import {
  isNaN,
  isNull,
  toNumber,
  isNumber,
  isFinite,
  isNil,
  get,
  size,
  last,
  isBoolean,
  isEmpty,
  map,
  forEach,
  isFunction,
  first,
  isEqual,
  isInteger,
  isSafeInteger,
  omit,
  toLower,
  isString,
  sortBy,
} from 'lodash';
import moment from 'moment';
import { KEY_DOWN, KEY_LEFT, KEY_RIGHT, KEY_UP, KEY_DELETE, KEY_BACKSPACE } from '@enums/key-codes';
import numberUtils from '@/js/utils/number-format-utils';
import i18n from '@/js/vue-i18n';

const formatCellValue = value =>
  value ? numberUtils.formatStringToNumber(value).toFixed(2) : value;

const filters = {
  standardStringFilter: {
    suppressAndOrCondition: true,
    filterOptions: ['contains', 'notContains'],
    textFormatter: r => {
      if (r == null) return null;
      return r
        .toLowerCase()
        .replace(/\s/g, '')
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y')
        .replace(/\W/g, '');
    },
  },

  // function so it gets the last translations
  getNumericStringFilter: () => {
    return {
      suppressAndOrCondition: true,
      filterOptions: [
        {
          displayKey: 'equals',
          displayName: i18n.t('general.filterOptions.equals'),
          test: (filterValue, cellValue) => {
            const formattedCellValue = formatCellValue(cellValue);
            return filterValue === cellValue || filterValue === formattedCellValue;
          },
        },
        {
          displayKey: 'notEqual',
          displayName: i18n.t('general.filterOptions.notEqual'),
          test: (filterValue, cellValue) => {
            const formattedCellValue = formatCellValue(cellValue);
            return filterValue !== cellValue && filterValue !== formattedCellValue;
          },
        },
        {
          displayKey: 'lessThan',
          displayName: i18n.t('general.filterOptions.lessThan'),
          test: (filterValue, cellValue) => {
            const formattedCellValue = formatCellValue(cellValue);
            return formattedCellValue < numberUtils.formatStringToNumber(filterValue);
          },
        },
        {
          displayKey: 'lessThanOrEqual',
          displayName: i18n.t('general.filterOptions.lessThanOrEqual'),
          test: (filterValue, cellValue) => {
            const formattedCellValue = formatCellValue(cellValue);
            return (
              formattedCellValue <= numberUtils.formatStringToNumber(filterValue) ||
              filterValue === cellValue
            );
          },
        },
        {
          displayKey: 'greaterThan',
          displayName: i18n.t('general.filterOptions.greaterThan'),
          test: (filterValue, cellValue) => {
            const formattedCellValue = formatCellValue(cellValue);
            return formattedCellValue > numberUtils.formatStringToNumber(filterValue);
          },
        },
        {
          displayKey: 'greaterThanOrEqual',
          displayName: i18n.t('general.filterOptions.greaterThanOrEqual'),
          test: (filterValue, cellValue) => {
            const formattedCellValue = formatCellValue(cellValue);
            return (
              formattedCellValue >= numberUtils.formatStringToNumber(filterValue) ||
              filterValue === cellValue
            );
          },
        },
      ],
    };
  },

  resetFilters(gridOptions) {
    gridOptions.api.setFilterModel(null);
    gridOptions.api.onFilterChanged();
  },
};

const colTypeEnum = {
  numericColumnCustom: 'numericColumnCustom',
  numericColumnPermissiveCustom: 'numericColumnPermissiveCustom',
  booleanColumnCustom: 'booleanColumnCustom',
};

const valueIsFalsy = ({ value }) => {
  return isNil(value) || value.toString().trim() === '';
};

/* All validations here return true in case value didn't pass validation,
 ** so that we don't need to negate checks everywhere. For example,
 ** validateValueExists will return false if value exists and true if not
 */

const validateValueExists = params => {
  const { value, colDef } = params;
  return colDef.required && colDef.editable && valueIsFalsy({ value });
};

const valueIsUnsafeInteger = ({ value }) => {
  return isInteger(value) && !isSafeInteger(value);
};

// AOV3-1423 TODO: ensure consistent with input-validations. if possible re-use / share functions
// Note validations return true to indicate the validation has not passed.
// e.g. valueIsNotNumeric returns true for non-numeric values, which in turn highlight the cell as errored.
// making negative validations are much clearer. e.g. valueIsNotNumeric vs valueIsNumeric.
const validations = {
  valueIsFalsy,
  validateValueExists,
  valueIsUnsafeInteger,

  // Returns true if value is negative
  valueIsNegative: ({ value }) => {
    return !isNull(value) && toNumber(value) < 0;
  },

  // Returns true if value is less or equal to allowed minimum
  valueIsLessOrEqual: (value, minimum) => {
    return isFinite(value) && !(toNumber(value) > minimum);
  },

  // Returns true if value is strictly less than allowed minimum
  valueIsLess: (value, minimum) => {
    const v = toNumber(value);
    return isFinite(v) && !(v >= minimum);
  },

  // Returns true if value is strictly greater than allowed maximum
  valueIsGreater: (value, maximum) => {
    return isFinite(value) && !(toNumber(value) <= maximum);
  },

  // Returns true if value is greater or equal to allowed maximum
  valueIsGreaterOrEqual: (value, maximum) => {
    return isFinite(value) && !(toNumber(value) < maximum);
  },

  // Returns true if value is not numeric
  valueIsNotNumeric(params) {
    const { value } = params;
    const parsedValue = numberUtils.stringToNumberIfNumeric(value);
    return !isFinite(parsedValue);
  },

  // Returns true if value is not finite or is unsafe integer
  valueIsNotNumericPermissive(params) {
    const { value } = params;
    // allow empty values to pass validation, different from valueIsNotNumeric
    if (['', undefined, null].includes(value)) return false;
    const parsedValue = numberUtils.stringToNumberIfNumeric(value);
    // only if the number is a safe integer.
    return !isFinite(parsedValue) || valueIsUnsafeInteger({ value: parsedValue });
  },

  // Returns true if value is not numeric or not finite or less than zero or a float
  valueIsNotPositiveInteger(params) {
    const { value } = params;
    const parsedValue = numberUtils.stringToNumberIfNumeric(value);
    const floorValue = Math.floor(parsedValue);
    return !isFinite(parsedValue) || parsedValue < 0 || floorValue !== parsedValue;
  },
};

/**
 * Default parser for ag-grid table cell values
 * @param params - ValueParserParams object from ag-grid that contains the new value of the cell
 * @returns {string} - Processed string
 */
function defaultParser(params) {
  if (isNil(params.newValue)) return;
  // trim the new value so that no odd spaces are saved
  const inputValue = params.newValue;

  return inputValue.toString().trim();
}

function booleanParser(params) {
  // calling the default parser first
  const inputValue = defaultParser(params);
  // ag-grid default empty value is empty string. return here to avoid coercing to 0.
  // newValue will be '' if value existed before, will be undefined if saved with empty value.
  return ![
    '',
    undefined,
    null,
    'false',
    false,
    'n',
    'N',
    i18n.t('general.no'),
    toLower(i18n.t('general.no')),
  ].includes(inputValue);
}

/**
 * Numeric parser for ag-grid table cell values
 * @param {object} params - Params object from ag-grid that contains the new value of the cell
 * @returns {(number | string)} - ag-grid returns strings by default,
 * if a value is castable to a number, return that value, otherwise, return whatever was passed
 */
function numericParser(params) {
  const inputValue = defaultParser(params);
  if (['', undefined].includes(inputValue)) return '';

  // needs to be symmetric with formatNumber
  const valueAfterCoercion = numberUtils.formatStringToNumber(inputValue);
  return !isNaN(valueAfterCoercion) ? valueAfterCoercion : inputValue;
}

/**
 * Numeric permissive parser for ag-grid table cell values
 * Used in attribute editor / places  where mixed types are allowed.
 * @param {object} params - Params object from ag-grid that contains the new value of the cell
 * @returns {(number | string)} - ag-grid returns strings by default,
 * if a value is castable to a number (without trimming any characters), return that value, otherwise, return string that was passed
 */
function numericPermissiveParser(params) {
  const inputValue = defaultParser(params);
  if (['', undefined].includes(inputValue)) return '';

  const valueAfterCoercion = numberUtils.stringToNumberIfNumeric(inputValue);
  return !isNaN(valueAfterCoercion) ? valueAfterCoercion : inputValue;
}

/**
 * Boolean formatter for ag-grid table cell values
 * @param {object} params - Params object from ag-grid
 * @returns {boolean} - Return value formatted to boolean. If value is already a boolean, return original value
 *
 * When parsing to a boolean value, it will return false if the value is omitted, 0, -0, null, false, NaN, undefined, or ''
 */
function booleanFormatter(params) {
  const parsedBoolean = booleanParser({ newValue: params.value });

  if (isBoolean(parsedBoolean)) {
    return parsedBoolean;
  }
  const value = get(params.data, params.colDef.field);
  return Boolean(value);
}

/**
 * Boolean string formatter for ag-grid table cell values
 * @param {object} params - Params object from ag-grid
 * @returns {boolean} - Return value formatted to a translated boolean string
 */
function booleanStringFormatter(params) {
  const value = booleanParser({ newValue: params.value });
  return toLower(i18n.t(`general.${value ? 'yes' : 'no'}`));
}

/**
 * Dates parser for ag-grid table cell values set to the begin of day
 * @param date - Date object or string
 * @returns {Date} - Processed date
 */
function beginOfTheDayDateParser(date) {
  return moment(date)
    .utc()
    .startOf('day')
    .toDate();
}

/**
 * Numeric formatter for ag-grid table cell values
 * @param {object} params - Params object from ag-grid
 * @param {string} format - Desired output format
 * @returns {string} - return number formatted to locale string if value is number, otherwise return original value
 *
 * assumes numbers.default.floatNonRounded in $translationFile.json keeps precision and ignores thousand separators.
 */
function numericFormatter(params, format = 'floatNonRounded') {
  // params.value is displayed value, get(params.data, params.colDef.field) is value as stored in db
  // e.g. params.value = "5,1", get(params.data, params.colDef.field) = 5.1
  // need get to handle nested fields e.g. x.y.z
  const value = get(params.data, params.colDef.field, params.value);
  return isFinite(value) ? numberUtils.formatNumber({ number: value, format }) : value;
}

/**
 * Round numeric formatter for ag-grid table cell values
 * @param {object} params - Params object from ag-grid
 * @returns {string} - return number if saved value is finite number, otherwise return original value
 *
 * assumes numbers.default.float in $translationFile.json rounds to nearest 2 decimals
 * note this works because we use valueGetter to parse the numeric value and this is purely a formatter.
 * this may cause problems if used directly or without a valueGetter.
 */
function roundFormatter(params) {
  return numericFormatter(params, 'float');
}

/**
 * Dates formatter for ag-grid table cell values
 * @param {object} params - Params object from ag-grid
 * @param {object} format - Moment format string
 * @returns {string} - return string formatted value
 */
function datesFormatter(params, format) {
  const { value: date } = params;
  return moment(date).format(format);
}

const disableEdit = params => {
  if (isFunction(params.colDef.editable)) {
    return !params.colDef.editable(params);
  }
  return !params.colDef.editable;
};

const utils = {
  checkboxRenderer: (params, vueClickHandlerMethod) => {
    const checkbox = document.createElement('input');
    checkbox.type = 'checkbox';
    checkbox.disabled = disableEdit(params);

    const pathForBox = params.colDef.field;
    const valueForBox = get(params.data, pathForBox);
    checkbox.checked = valueForBox;
    checkbox.addEventListener('change', () => vueClickHandlerMethod(params));
    return checkbox;
  },

  processCellFromClipboard(params, pasteFormatters) {
    const colName = params.column.colId;

    if (!isEmpty(pasteFormatters)) {
      const formatter = get(pasteFormatters, colName, v => v);

      if (get(pasteFormatters, colName)) {
        return formatter(params.value);
      }
    }

    const cellParser = get(params, 'column.colDef.valueParser', v => v);
    params.newValue = params.value; // paste and edit operations use different properties. This adds the correct value for paste operations.
    return cellParser(params);
  },

  processDataFromClipboard(params) {
    // Drop last row if it's an array with single empty string
    // This may appear because of new line at the end of clipboard data
    // Usually empty rows/columns are dropped out by ag-grid,
    // but in case of new line at the end we should drop it manually
    const lastRow = last(params.data);
    if (size(lastRow) === 1 && lastRow[0] === '') params.data.pop();
    return params.data;
  },

  processCellForExport(params) {
    if (params.column.colDef.type === colTypeEnum.numericColumnCustom && params.value) {
      return numberUtils.formatNumberIfNumeric({ value: params.value, format: 'export' });
    }
    if (params.column.colDef.type === colTypeEnum.booleanColumnCustom && isBoolean(params.value)) {
      return i18n.t(`csvExport.valueTranslations.${params.value}`);
    }
    return params.value;
  },

  customCheckboxButtonRenderer: (params, options = {}) => {
    const pathForBox = get(params.colDef, 'cellRendererParams.field', params.colDef.field);
    const button = document.createElement('button');
    const selectedClass = options.isSelected ? `selected-${pathForBox}` : '';
    button.type = 'button';
    button.className = `round-selection align-center ${pathForBox} ${selectedClass}`;
    if (options.isComponentEnabled) {
      button.disabled = !options.isComponentEnabled(params);
    }
    button.addEventListener(options.eventType || 'click', () =>
      options.handler(params, options.additionalArgs)
    );

    return button;
  },

  fullWidthHeadingRenderer: params => {
    const heading = document.createElement('span');
    heading.className = 'ag-header-group-text';
    heading.innerHTML = params.data.heading;
    return heading;
  },

  customIconRenderer: (_params, options = {}) => {
    const icon = document.createElement('i');
    const hiddenClass = options.isDisplayed ? '' : 'hidden';
    icon.innerHTML = `${options.icon}`;
    icon.className = `custom-icon material-icons icon ${options.classes} ${hiddenClass}`;
    return icon;
  },

  /**
   * Custom select and unselect all options for the ag-grid general menu tab.
   * @param {object} params - Params object from ag-grid
   * @param {function(string, boolean)} clickHandler - Function to handle option click
   * @param {Array<Object>} - Array of options to display in the menu
   */
  toggleAllMenuItems: (params, clickHandler) => {
    const { colId } = params.column;
    return [
      {
        name: i18n.t('actions.selectAll'),
        action: () => clickHandler(colId, true),
      },
      {
        name: i18n.t('actions.unselectAll'),
        action: () => clickHandler(colId, false),
      },
    ];
  },

  suppressKeyboardEvent: params => {
    function clearCells(start, end, columns, gridApi) {
      // TODO: improve remove performance because setDataValue is slow.
      gridApi.forEachNodeAfterFilterAndSort(node => {
        if (node.rowIndex >= start && node.rowIndex <= end) {
          forEach(columns, column => {
            const colDef = node.beans.columnApi.getColumn(column).colDef;

            if (isFunction(colDef.editable)) {
              if (colDef.editable(node)) {
                node.setDataValue(column, '');
              }
            } else if (colDef.editable) {
              node.setDataValue(column, '');
            }
          });
        }
      });
    }

    function isMacintosh() {
      return navigator.platform.indexOf('Mac') > -1;
    }

    const keyPress = params.event.keyCode;
    const cellRange = first(params.api.getCellRanges());
    const columns = params.columnApi.getColumns();
    const startRowIndex = Math.min(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex);
    const endRowIndex = Math.max(cellRange.startRow.rowIndex, cellRange.endRow.rowIndex);

    if (keyPress === KEY_DELETE || keyPress === KEY_BACKSPACE) {
      const colIds = map(cellRange.columns, col => col.colId);

      clearCells(startRowIndex, endRowIndex, colIds, params.api);
    }

    if (
      params.event.shiftKey &&
      ((isMacintosh() && params.event.metaKey) || params.event.ctrlKey)
    ) {
      switch (keyPress) {
        case KEY_RIGHT:
        case KEY_LEFT:
          params.api.clearRangeSelection();
          params.api.addCellRange({
            rowStartIndex: startRowIndex,
            rowEndIndex: endRowIndex,
            columnStart: isEqual(keyPress, KEY_RIGHT)
              ? first(cellRange.columns).colId
              : first(columns).colId,
            columnEnd: isEqual(keyPress, KEY_RIGHT)
              ? last(columns).colId
              : last(cellRange.columns).colId,
          });
          break;

        case KEY_UP:
        case KEY_DOWN:
          params.api.clearRangeSelection();
          params.api.addCellRange({
            rowStartIndex: startRowIndex,
            rowEndIndex: isEqual(keyPress, KEY_UP)
              ? 0
              : size(params.api.getModel().gridOptionsWrapper.gridOptions.rowData) - 1,
            columnStart: first(cellRange.columns).colId,
            columnEnd: last(cellRange.columns).colId,
          });
          break;

        default:
          break;
      }
    }
  },
  disableEdit,
  valueIsFalsy,
};

const sortings = {
  naturalSort: (valueA, valueB) => {
    valueA = isNil(valueA) ? '' : valueA.toString();
    valueB = isNil(valueB) ? '' : valueB.toString();

    const parsedValueA = numericPermissiveParser({ newValue: valueA });
    const parsedValueB = numericPermissiveParser({ newValue: valueB });

    if (!isNaN(toNumber(parsedValueA)) && !isNaN(toNumber(parsedValueB))) {
      return toNumber(parsedValueA) - toNumber(parsedValueB);
    }

    return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' });
  },
};

const comparators = {
  didValueChange: (newValue, oldValue) => {
    // Default comparator. If values are numbers, compares them as doubles with 10 digits precision.
    // Otherwise, compares using lodash isEqual.
    if (isNumber(newValue) && isNumber(oldValue)) {
      return Math.abs(newValue - oldValue) > 10 ** -10;
    }
    if ((isNumber(newValue) && isString(oldValue)) || (isNumber(oldValue) && isString(newValue))) {
      // When comparing number and string check if the value is the same once normalized
      return !isEqual(newValue.toString(), oldValue.toString());
    }

    return (
      !(valueIsFalsy({ value: newValue }) && valueIsFalsy({ value: oldValue })) &&
      !isEqual(newValue, oldValue)
    );
  },
};

// AOV3-1423 TODO: move all colDefs to columnTypes
const colDefs = {
  action: {
    headerName: '',
    editable: false,
    sortable: false,
    filter: false,
    suppressMenu: true,
    resizable: false,
    cellClass: 'justified-center action-column-cell',
    cellClassRules: {},
  },
  numeric: {
    valueParser: numericParser,
    // required to show localized number when editing cell
    valueGetter: numericFormatter,
    // show value rounded to 2 decimal places in view, but 10 decimal places when editing
    valueFormatter: roundFormatter,
    filterParams: () => filters.getNumericStringFilter(),
  },
  numericPermissive: {
    valueParser: numericPermissiveParser,
  },
  boolean: {
    valueParser: booleanParser,
    valueFormatter: booleanFormatter,
  },
};

const columnTypes = {
  numericColumnCustom: colDefs.numeric,
  numericColumnPermissiveCustom: {
    ...colDefs.numeric,
    ...colDefs.numericPermissive,
  },
  booleanColumnCustom: colDefs.boolean,
};

function mergeHeaders(basicHeaders, extraHeaders, options = {}) {
  let allHeaders = [...basicHeaders];
  if (isEmpty(extraHeaders)) return basicHeaders;

  const basicColumnDef = { width: 100 };
  forEach(extraHeaders, (columnDefinition, name) => {
    let newColumn = {
      ...basicColumnDef,
      ...columnDefinition,
      headerName: i18n.t(columnDefinition.translationKey),
      field: name,
    };
    if (!options.orderChildren) {
      newColumn = omit(newColumn, ['position']);
    }

    if (columnDefinition.isGroup) {
      newColumn = {
        headerName: i18n.t(columnDefinition.translationKey),
        children: mergeHeaders([], columnDefinition.childrenColumns, {
          ...options,
          orderChildren: true,
        }),
      };
    }
    const position =
      get(columnDefinition, `position.${options.componentName}`) ||
      get(columnDefinition, 'position.default') ||
      columnDefinition.position;
    if (position) allHeaders.splice(position, 0, newColumn);
    else allHeaders.push(newColumn);
  });

  // order children columns
  if (options.orderChildren) {
    allHeaders = sortBy(allHeaders, ['position']);
    allHeaders = allHeaders.map(header => omit(header, ['position']));
    if (options.firstGroupChildColumnVisible) {
      // make the first column visible at all times, everything else is collapsed by default
      allHeaders[0].columnGroupShow = null;
    }
  }

  return allHeaders;
}

export default {
  filters,
  colDefs,
  columnTypes,
  validations,
  utils,
  sortings,
  comparators,
  parsers: {
    booleanParser,
    defaultParser,
    numericParser,
    numericPermissiveParser,
    beginOfTheDayDateParser,
  },
  formatters: {
    numericFormatter,
    roundFormatter,
    booleanFormatter,
    booleanStringFormatter,
    datesFormatter,
  },
  builders: {
    mergeHeaders,
  },
};
