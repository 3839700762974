import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VRow,
    { staticClass: "d-flex h-100" },
    [
      _vm.storesWithNoCoordinates.length
        ? _c(
            VAlert,
            {
              staticClass: "ma-2 info-message w-100",
              attrs: { type: "info", text: "" },
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$tkey("infoMessage", {
                      stores: _vm.storesWithNoCoordinates.map(
                        (s) => s.storeKeyDisplay
                      ),
                    })
                  ) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "position-relative w-100 h-100 leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom",
          attrs: { tabindex: "0" },
        },
        [
          _c("div", {
            staticClass: "w-100",
            staticStyle: { height: "100%" },
            attrs: { id: "map" },
          }),
          _vm._v(" "),
          _vm.showSidepanel
            ? _c(
                "sidepanel",
                {
                  attrs: {
                    title: _vm.$tkey("legend"),
                    "toggle-btn-text": _vm.$tkey("legend"),
                  },
                },
                [
                  _c(
                    VCard,
                    { staticClass: "legend", attrs: { outlined: "" } },
                    [
                      _c(
                        VList,
                        [
                          _c(
                            VListItemGroup,
                            { attrs: { color: "primary" } },
                            _vm._l(_vm.schemeClusters, function (cluster) {
                              return _c(
                                VListItem,
                                {
                                  key: cluster.clusterId,
                                  attrs: { value: cluster.clusterId },
                                },
                                [
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(
                                        VListItemTitle,
                                        { staticClass: "cluster" },
                                        [
                                          _c(
                                            VRow,
                                            { staticClass: "cluster-name" },
                                            [
                                              _c(
                                                VCol,
                                                { attrs: { cols: 2 } },
                                                [
                                                  _c(VAvatar, {
                                                    attrs: {
                                                      color:
                                                        _vm.markerColourOptions[
                                                          cluster.colour
                                                        ],
                                                      size: "30",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                VCol,
                                                { attrs: { cols: 8 } },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        cluster.clusterName
                                                      )
                                                  ),
                                                  _c("br"),
                                                  _vm._v(" "),
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tkey("stores")
                                                      ) + ": "
                                                    ),
                                                  ]),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        cluster.storeKeys.length
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                VCol,
                                                { attrs: { cols: 2 } },
                                                [
                                                  _c(VCheckbox, {
                                                    attrs: {
                                                      value: cluster.clusterId,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.legendSelectedClusters,
                                                      callback: function ($$v) {
                                                        _vm.legendSelectedClusters =
                                                          $$v
                                                      },
                                                      expression:
                                                        "legendSelectedClusters",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }