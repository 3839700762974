import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c("scenario-measuring-header", {
        attrs: {
          "header-text": _vm.$tkey("reviewClusteringMessage"),
          columns: 4,
          "tooltip-link": "toolguide/100-clustering.html",
        },
      }),
      _vm._v(" "),
      _c(
        VContainer,
        {
          staticClass: "pa-0 flex-grow-1 d-flex flex-column",
          class: { "in-progress": _vm.hasJobInProgress },
          attrs: { fluid: "", disabled: _vm.isRunningClusteredSwitchingJob },
        },
        [
          _c(
            VRow,
            { staticClass: "flex-grow-0" },
            [
              _c(
                VCol,
                {
                  staticClass:
                    "rtls-border rtls-border--right created-cluster-schemes d-flex flex-column justify-end",
                  attrs: { cols: "2" },
                },
                [
                  _c("span", { staticClass: "pa-3 font-weight-bold d-block" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$tkey("clusteringSchemes")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        VRow,
                        { attrs: { id: "headers-row" } },
                        _vm._l(_vm.headers, function (header, index) {
                          return _c(
                            VCol,
                            {
                              key: `header-${index}`,
                              class: header.headerClass,
                              attrs: {
                                cols: header.cols,
                                disabled: _vm.isNavigationDisabled,
                              },
                            },
                            [_c("span", [_vm._v(_vm._s(header.text))])]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                VCol,
                { staticStyle: { padding: "0" }, attrs: { cols: "10" } },
                [
                  _c(
                    VTabs,
                    {
                      attrs: { value: _vm.currentTab, "hide-slider": "" },
                      on: { change: _vm.onTabChange },
                    },
                    _vm._l(_vm.tabs, function (tab, tabIndex) {
                      return _c(
                        "actionable-link",
                        {
                          key: tab.routeName,
                          class: {
                            "step-tab": true,
                            "first-tab": tabIndex === 0,
                          },
                          attrs: {
                            component: "v-tab",
                            disabled:
                              _vm.isNavigationDisabled ||
                              tab.disabled ||
                              !_vm.selectedScheme,
                            to: _vm.generateTabRoute(tab),
                            "on-click": _vm.handleTabSelect(tab),
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t(tab.title)) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm.userCanSeeSchemeInformation
                    ? _c(
                        "div",
                        { staticClass: "d-flex h-50" },
                        [
                          _c(VRow, { staticClass: "header ma-0" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center ml-3" },
                              [
                                _c("span", { staticClass: "info-note" }, [
                                  _vm._v(_vm._s(_vm.getInfoTextForTab)),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.showAttributeFilter
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex align-center ml-5" },
                                  [
                                    _c("span", { staticClass: "mr-2" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("general.attribute"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(VSelect, {
                                      attrs: {
                                        value: _vm.selectedAttributeId,
                                        flat: "",
                                        items: _vm.getClusterSchemeAttributes,
                                        placeholder: _vm.$tkey(
                                          "attributeFilterPlaceholder"
                                        ),
                                        "item-text": "name",
                                        "item-value": "id",
                                        disabled: _vm.hasJobInProgress,
                                        width: "240px",
                                      },
                                      on: {
                                        change: _vm.changeSelectedAttribute,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "append",
                                            fn: function () {
                                              return [
                                                _c(
                                                  VIcon,
                                                  { attrs: { size: "28" } },
                                                  [_vm._v("expand_more")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3122533595
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showCompareWithFilter
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex align-center ml-5" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "mr-2",
                                        staticStyle: {
                                          "white-space": "nowrap",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("general.compareWith")
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(VSelect, {
                                      attrs: {
                                        items: _vm.comparisonSchemas,
                                        placeholder: _vm.$t("general.select"),
                                        value: _vm.comparisonSchemeId,
                                        "item-text": "name",
                                        "item-value": "_id",
                                        width: "240px",
                                      },
                                      on: {
                                        change: _vm.setSelectedComparisonScheme,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "append",
                                            fn: function () {
                                              return [
                                                _c(
                                                  VIcon,
                                                  { attrs: { size: "28" } },
                                                  [_vm._v("expand_more")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3122533595
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.showListModeToggle
                              ? _c(
                                  "div",
                                  { staticClass: "d-flex align-center ml-2" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(/*
                    can't use v-btn-toggle because it immediately updates internal lazy value.
                    need to wait for unsaved data warning modal confirmation if hasDataChanges.
                    https://github.com/vuetifyjs/vuetify/issues/10622#issuecomment-656930871
              */) +
                                        "\n              "
                                    ),
                                    _c(
                                      VItemGroup,
                                      { staticClass: "v-btn-toggle" },
                                      [
                                        _c(
                                          VBtn,
                                          {
                                            class: {
                                              "v-btn--active":
                                                _vm.listMapToggle === 0,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.listMapToggle = 0
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(_vm.$tkey("list")) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          VBtn,
                                          {
                                            class: {
                                              "v-btn--active":
                                                _vm.listMapToggle === 1,
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.listMapToggle = 1
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(_vm.$tkey("map")) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(VSelect, {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.listMapToggle === 0,
                                          expression: "listMapToggle === 0",
                                        },
                                      ],
                                      staticClass: "ml-4 mt-2",
                                      attrs: {
                                        id: "vselectClusterName",
                                        height: 24,
                                        disabled: !_vm.hasSelectedStores,
                                        items: _vm.selectableClusterNames,
                                        placeholder: _vm.$tkey(
                                          "moveSelectedStoresToCluster"
                                        ),
                                        "hide-details": "",
                                        flat: "",
                                        dense: "",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.moveStores($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.selectedClusterToMoveTo,
                                        callback: function ($$v) {
                                          _vm.selectedClusterToMoveTo = $$v
                                        },
                                        expression: "selectedClusterToMoveTo",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(VDivider, { attrs: { horizontal: "" } }),
          _vm._v(" "),
          _c(
            VRow,
            {
              staticClass: "flex-grow-1",
              attrs: {
                id: "main-row",
                disabled: _vm.isRunningClusteredSwitchingJob,
              },
            },
            [
              _c(
                VCol,
                {
                  staticClass:
                    "rtls-border rtls-border--right created-cluster-schemes",
                  attrs: { cols: "2" },
                },
                [
                  _c(
                    VContainer,
                    {
                      class: {
                        "empty-table": !_vm.getScenarioClusterSchemes.length,
                      },
                      attrs: { id: "cluster-schemes-table" },
                    },
                    _vm._l(_vm.groupedSchemes, function (group) {
                      return _c(
                        "div",
                        { key: group.text },
                        [
                          group.text
                            ? _c(VRow, [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-bold pl-2" },
                                  [_vm._v(_vm._s(group.text))]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(group.schemes, function (row) {
                            return _c(
                              VRow,
                              {
                                key: row._id,
                                staticClass: "cluster-row",
                                class: { "in-scope": group.inScope },
                                attrs: {
                                  selected:
                                    _vm.selectedScheme &&
                                    _vm.selectedScheme._id === row._id,
                                },
                              },
                              _vm._l(_vm.headers, function (header) {
                                return _c(
                                  VCol,
                                  {
                                    key: `cell-${row._id}-${header.id}`,
                                    class: header.colClass,
                                    staticStyle: { height: "30px" },
                                    attrs: { cols: header.cols },
                                  },
                                  [
                                    header.ui === _vm.CELL_TYPE.icon
                                      ? [
                                          _c(
                                            VIcon,
                                            {
                                              staticClass: "v-icon-blue",
                                              attrs: {
                                                disabled:
                                                  _vm.isNavigationDisabled ||
                                                  row.disabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return header.onClick(row)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(header.value) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.getClusterVariance(
                                                  header.type,
                                                  row[header.value]
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                          _vm.showClusterWarning(
                                            header.value,
                                            row
                                          )
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "cluster-warning-container ml-2",
                                                  on: {
                                                    click: function ($event) {
                                                      return header.onClick(
                                                        row,
                                                        _vm.storeAllocationTab
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      staticClass:
                                                        "material-icons-round error-triangle-icon",
                                                    },
                                                    [_vm._v("warning_amber")]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                  ],
                                  2
                                )
                              }),
                              1
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(VDivider, { attrs: { horizontal: "" } }),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      staticClass: "mt-2 ml-2",
                      attrs: {
                        primary: "",
                        disabled: _vm.hasJobInProgress || _vm.isEditingDisabled,
                      },
                      on: { click: _vm.openClusterGenerationModal },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tkey("addClusteringSchemes")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.invalidClusteringSchemes.length,
                          expression: "invalidClusteringSchemes.length",
                        },
                      ],
                      staticClass: "mt-2 ml-2",
                      attrs: { id: "cluster-warning-section" },
                    },
                    [
                      _c(VDivider, { attrs: { horizontal: "" } }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "cluster-warning-container mt-2 mr-1" },
                        [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons-round error-triangle-icon",
                            },
                            [_vm._v("warning_amber")]
                          ),
                          _vm._v(" "),
                          _c("span", [
                            _c("b", { staticClass: "ml-2 mr-1" }, [
                              _vm._v(
                                _vm._s(_vm.$tkey("clusterWarningInfoTitle"))
                              ),
                            ]),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$tkey("clusterWarningInfoMessage")) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VCol,
                {
                  staticClass: "pb-0 pl-0 pr-0",
                  class: {
                    "pt-5": !_vm.isStoreAllocationMapView,
                    "pa-0": _vm.isStoreAllocationMapView,
                  },
                  attrs: { id: "clustering-tab-content", cols: "10" },
                },
                [
                  _vm.userCanSeeSchemeInformation
                    ? _c(_vm.componentName, {
                        ref: _vm.componentName,
                        tag: "component",
                        on: {
                          "data-changed": function ($event) {
                            _vm.hasDataChanges = $event
                          },
                          "has-selected-rows": function ($event) {
                            _vm.hasSelectedStores = $event
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("cluster-generation-modal", {
            on: {
              process: _vm.processNewClusterSchemes,
              close: _vm.closeClusterGenerationModal,
            },
            model: {
              value: _vm.isClusterGenerationModalOpen,
              callback: function ($$v) {
                _vm.isClusterGenerationModalOpen = $$v
              },
              expression: "isClusterGenerationModalOpen",
            },
          }),
          _vm._v(" "),
          _vm.getScenarioClusterSchemes.length
            ? _c("cluster-switching-modal", {
                on: {
                  process: _vm.processClusteredSwitching,
                  close: _vm.closeClusteredSwitchingModal,
                },
                model: {
                  value: _vm.isClusteredSwitchingModalOpen,
                  callback: function ($$v) {
                    _vm.isClusteredSwitchingModalOpen = $$v
                  },
                  expression: "isClusteredSwitchingModalOpen",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-actions-container" },
        [
          _c("page-actions", {
            attrs: {
              "show-export": !_vm.isStoreAllocationMapView,
              "include-scenario-in-export-name": "",
              "save-btn-text": "runClusteredSwitching",
              "export-service": "clustering",
              "save-disabled": _vm.isEditingDisabled,
              "is-discard-enabled": !_vm.isEditingDisabled,
              "has-data-changes": _vm.userCanRunClusteredSwitching,
              "has-data-errors": _vm.hasDataErrors,
              "show-discard": false,
              "live-data": _vm.getScenarioClusterSchemesForExport,
            },
            on: { save: _vm.openClusterSwitchingModal },
            scopedSlots: _vm._u([
              {
                key: "right-btns",
                fn: function () {
                  return [
                    _c(
                      VBtn,
                      {
                        staticClass: "text-outline",
                        attrs: {
                          secondary: "",
                          depressed: "",
                          disabled:
                            _vm.selectedScenario.hasSkippedClustering ===
                              false || _vm.isEditingDisabled,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setScenarioHasSkippedClustering({
                              hasSkippedClustering: true,
                            })
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("actions.skipClustering")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "center-top" },
        [
          _vm.isLoadingClusterInformation || _vm.hasJobInProgress
            ? _c("progress-bar", {
                attrs: {
                  message: _vm.$t("messages.inProgress"),
                  percentage: _vm.getProgressPercent,
                  status: _vm.getProgressStatus,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.closeUnsavedDataModal,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }