<template functional>
  <div class="enabled-product-tile" :class="{ 'h-100': props.parentIsDashboard }" @click.stop>
    <vue-draggable-resizable
      :w="!props.parentIsDashboard ? props.settings.width : 'auto'"
      :h="!props.parentIsDashboard ? props.settings.height : 'auto'"
      :parent="false"
      :z="props.settings.zIndex"
      :style="
        `${props.settings.left ? `left: ${props.settings.left}px;` : ''}${
          props.settings.right ? `right: ${props.settings.right}px;` : ''
        } top: ${props.settings.top}px; max-height: ${
          !props.parentIsDashboard ? props.settings.height + 'px' : 'auto'
        };`
      "
      :drag-cancel="'.drag-cancel'"
      :class="{
        'drag-cancel': props.parentIsDashboard,
        'draggable-resizable': !props.parentIsDashboard,
        'draggable-fit-content': !props.parentIsDashboard,
      }"
    >
      <div :class="{ 'd-flex flex-column product-content-container': !props.parentIsDashboard }">
        <div v-if="!props.parentIsDashboard" class="d-flex justify-space-between heading mb-1">
          <div class="product-title">
            {{ props.productInfo.itemDescription }}
          </div>
          <div class="close" @click.stop="listeners['close-popup'](props.productIndex)">
            <v-icon :size="18" color="#2f477c">mdi-close-circle</v-icon>
          </div>
        </div>
        <div>
          <p class="mb-1 copy-text">
            <img class="mr-1" :src="props.copyIcon" />
            {{ parent.$t('assortmentCanvas.copyText') }}
          </p>
        </div>
        <div
          :class="{
            'd-flex': true,
            'main-body': !props.parentIsDashboard,
            'pr-4': props.parentIsDashboard,
            'flex-column': !props.parentIsDashboard,
          }"
        >
          <div v-if="!props.parentIsDashboard" class="popup-left-side">
            <div class="d-flex product-tile position-relative flex-column justify-start">
              <div class="image-wrapper">
                <assortment-image
                  :src="props.imageUrl"
                  :alt="props.imageAlt"
                  :title="props.imageTitle"
                  :tooltip-text="`${props.imageTitle} - ${props.productInfo.itemDescription}`"
                  :tag-text="props.imageTagText"
                  :show-not-in-reset-icon="!props.productInfo.inReset"
                  v-bind="props.conditionalProductTileStyle"
                  dashboard
                />
              </div>
            </div>
            <div class="d-flex flex-column info-table justify-center flex-grow-3">
              <div class="d-flex justify-space-between product-tooltip-row">
                <div
                  class="font-weight-bold mr-4 copy-to-clipboard"
                  @click="
                    $options.copyToClipboard(`${props.productInfo.productKeyDisplay}`, $event)
                  "
                >
                  {{ parent.$t('assortmentCanvas.productKeyDisplay') }}
                </div>
                <div :title="props.productInfo.productKeyDisplay" class="overflow-ellipsis">
                  {{ props.productInfo.productKeyDisplay }}
                </div>
              </div>
              <div
                v-if="props.productInfo.futureProductIdString"
                class="d-flex justify-space-between product-tooltip-row"
              >
                <div
                  class="font-weight-bold mr-4 copy-to-clipboard"
                  @click="
                    $options.copyToClipboard(`${props.productInfo.futureProductIdString}`, $event)
                  "
                >
                  {{ parent.$t('assortmentCanvas.futureProductId') }}
                </div>
                <div :title="props.productInfo.futureProductIdString" class="overflow-ellipsis">
                  {{ props.productInfo.futureProductIdString }}
                </div>
              </div>
              <div class="d-flex justify-space-between product-tooltip-row">
                <div
                  class="font-weight-bold mr-4 copy-to-clipboard"
                  @click="$options.copyToClipboard(`${props.productInfo.itemDescription}`, $event)"
                >
                  {{ parent.$t('assortmentCanvas.productDescription') }}
                </div>
                <div :title="props.productInfo.itemDescription" class="overflow-ellipsis">
                  {{ props.productInfo.itemDescription }}
                </div>
              </div>
              <div class="d-flex justify-space-between product-tooltip-row">
                <div
                  class="font-weight-bold mr-4 copy-to-clipboard"
                  @click="
                    $options.copyToClipboard(
                      `${parent.formatNumber({
                        number: props.productInfo.contentValue,
                        format: 'float',
                      })}`,
                      $event
                    )
                  "
                >
                  {{ parent.$t('assortmentCanvas.contentUom') }}
                </div>
                <div
                  :title="
                    `${parent.formatNumber({
                      number: props.productInfo.contentValue,
                      format: 'float',
                    })} ${props.productInfo.contentUnitOfMeasure}`
                  "
                  class="overflow-ellipsis"
                >
                  {{
                    parent.formatNumber({ number: props.productInfo.contentValue, format: 'float' })
                  }}
                  {{ props.productInfo.contentUnitOfMeasure }}
                </div>
              </div>
              <div class="d-flex justify-space-between product-tooltip-row">
                <div
                  class="font-weight-bold mr-4 copy-to-clipboard"
                  @click="
                    $options.copyToClipboard(
                      `${parent.formatNumber({ number: props.productInfo.size, format: 'float' })}`,
                      $event
                    )
                  "
                >
                  {{ parent.$t('assortmentCanvas.dimensions') }}
                </div>
                <div
                  :title="parent.formatNumber({ number: props.productInfo.size, format: 'float' })"
                  class="overflow-ellipsis"
                >
                  {{ parent.formatNumber({ number: props.productInfo.size, format: 'float' }) }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-column"
            :class="{
              'popup-right-side': !props.parentIsDashboard,
              'popup-centre': props.parentIsDashboard,
            }"
          >
            <div class="d-flex flex-column">
              <div
                v-if="props.parentIsDashboard"
                class="d-flex product-dashboard position-relative"
              >
                <div>
                  <assortment-image
                    :src="props.imageUrl"
                    :alt="props.imageAlt"
                    :title="props.imageTitle"
                    :tag-text="props.imageTagText"
                    :tooltip-text="`${props.imageTitle} - ${props.productInfo.itemDescription}`"
                    :show-not-in-reset-icon="!props.productInfo.inReset"
                    v-bind="props.conditionalProductTileStyle"
                    dashboard
                  />
                </div>
              </div>
            </div>
            <div class="d-flex flex-column info-table ">
              <!-- Selected Kpis -->
              <div
                v-for="(kpi, i) in props.selectedKpis"
                :key="i"
                class="d-flex justify-space-between product-tooltip-row kpi-row"
              >
                <div
                  class="font-weight-bold mr-4 copy-to-clipboard"
                  @click="
                    $options.copyToClipboard(
                      $options.methods.getKPIValue(parent, props, kpi),
                      $event
                    )
                  "
                >
                  {{ $options.methods.getKpiTitle(parent, props, kpi) }}
                </div>
                <div
                  class="overflow-ellipsis"
                  :title="`${$options.methods.getKPIValue(parent, props, kpi)}`"
                >
                  <span>{{ $options.methods.getKPIValue(parent, props, kpi) }}</span>
                </div>
              </div>
              <div
                v-if="props.productInfo.averageTransactionWeight"
                class="d-flex justify-space-between product-tooltip-row"
              >
                <div
                  class="font-weight-bold mr-4 copy-to-clipboard"
                  @click="
                    $options.copyToClipboard(
                      `${parent.formatNumber({
                        number:
                          props.productInfo.averageTransactionWeight *
                          props.productInfo.contentValue,
                        format: 'float',
                      })}`,
                      $event
                    )
                  "
                >
                  {{ parent.$t('assortmentCanvas.volumeUom') }}
                </div>
                <div
                  :title="
                    parent.formatNumber({
                      number:
                        props.productInfo.averageTransactionWeight * props.productInfo.contentValue,
                      format: 'float',
                    })
                  "
                  class="overflow-ellipsis"
                >
                  {{
                    `${parent.formatNumber({
                      number:
                        props.productInfo.averageTransactionWeight * props.productInfo.contentValue,
                      format: 'float',
                    })} ${props.productInfo.contentUnitOfMeasure}`
                  }}
                </div>
              </div>
              <div class="d-flex justify-space-between product-tooltip-row divider-top">
                <div
                  class="font-weight-bold mr-4 copy-to-clipboard"
                  @click="
                    $options.copyToClipboard(
                      `${parent.$t('assortmentCanvas.productPositioning', [
                        props.podAndStoreCountInfo.originalSpacebreak.shortName,
                        props.podAndStoreCountInfo.originalSpacebreak.storeCount,
                        props.podAndStoreCountInfo.originalSpacebreak.pod,
                      ])}`,
                      $event
                    )
                  "
                >
                  {{ parent.$t('assortmentCanvas.startingPointPosition') }}
                </div>
                <div
                  :title="
                    parent.$t('assortmentCanvas.productPositioning', [
                      props.podAndStoreCountInfo.originalSpacebreak.shortName,
                      props.podAndStoreCountInfo.originalSpacebreak.storeCount,
                      props.podAndStoreCountInfo.originalSpacebreak.pod,
                    ])
                  "
                  class="overflow-ellipsis"
                >
                  {{
                    parent.$t('assortmentCanvas.productPositioning', [
                      props.podAndStoreCountInfo.originalSpacebreak.shortName,
                      props.podAndStoreCountInfo.originalSpacebreak.storeCount,
                      props.podAndStoreCountInfo.originalSpacebreak.pod,
                    ])
                  }}
                </div>
              </div>
              <div class="d-flex justify-space-between product-tooltip-row">
                <div
                  class="font-weight-bold mr-4 copy-to-clipboard"
                  @click="
                    $options.copyToClipboard(
                      `${parent.$t('assortmentCanvas.productPositioning', [
                        props.podAndStoreCountInfo.optimisedSpacebreak.shortName,
                        props.podAndStoreCountInfo.optimisedSpacebreak.storeCount,
                        props.podAndStoreCountInfo.optimisedSpacebreak.pod,
                      ])}`,
                      $event
                    )
                  "
                >
                  {{ parent.$t('assortmentCanvas.lastOptimisation') }}
                </div>
                <div
                  :title="
                    parent.$t('assortmentCanvas.productPositioning', [
                      props.podAndStoreCountInfo.optimisedSpacebreak.shortName,
                      props.podAndStoreCountInfo.optimisedSpacebreak.storeCount,
                      props.podAndStoreCountInfo.optimisedSpacebreak.pod,
                    ])
                  "
                  class="overflow-ellipsis"
                >
                  {{
                    parent.$t('assortmentCanvas.productPositioning', [
                      props.podAndStoreCountInfo.optimisedSpacebreak.shortName,
                      props.podAndStoreCountInfo.optimisedSpacebreak.storeCount,
                      props.podAndStoreCountInfo.optimisedSpacebreak.pod,
                    ])
                  }}
                </div>
              </div>
              <div class="d-flex justify-space-between product-tooltip-row">
                <div
                  class="font-weight-bold mr-4 copy-to-clipboard"
                  @click="
                    $options.copyToClipboard(
                      `${parent.$t('assortmentCanvas.productPositioning', [
                        props.podAndStoreCountInfo.currentSpacebreak.shortName,
                        props.podAndStoreCountInfo.currentSpacebreak.storeCount,
                        props.podAndStoreCountInfo.currentSpacebreak.pod,
                      ])}`,
                      $event
                    )
                  "
                >
                  {{ parent.$t('assortmentCanvas.latestEdit') }}
                </div>
                <div
                  :title="
                    parent.$t('assortmentCanvas.productPositioning', [
                      props.podAndStoreCountInfo.currentSpacebreak.shortName,
                      props.podAndStoreCountInfo.currentSpacebreak.storeCount,
                      props.podAndStoreCountInfo.currentSpacebreak.pod,
                    ])
                  "
                  class="overflow-ellipsis"
                >
                  {{
                    parent.$t('assortmentCanvas.productPositioning', [
                      props.podAndStoreCountInfo.currentSpacebreak.shortName,
                      props.podAndStoreCountInfo.currentSpacebreak.storeCount,
                      props.podAndStoreCountInfo.currentSpacebreak.pod,
                    ])
                  }}
                </div>
              </div>
            </div>
            <div class="d-flex flex-column mt-3">
              <div class="d-flex justify-space-between sub-info-row align-center">
                <div class="font-weight-bold mr-2">
                  {{ parent.$t('assortmentCanvas.spacebreak') }}
                </div>
                <div class="select-container select-container--spacebreak">
                  <!-- A copy of the select with the notEligible or notForAssortment option
                  The reason for that is that the products in the not listed and not eligible spacebreaks
                  have currentSpacebreakId === null. Plus, we don't want products to move to the not Eligible spacebreak
                  by changing the selection.
                  -->
                  <rtls-select
                    v-if="
                      !props.productInfo.isEligible ||
                        !props.productInfo.assortment ||
                        props.productInfo.isLocal
                    "
                    :value="props.selectedSpacebreak.value"
                    :disabled="true"
                    :items="[
                      {
                        value: null,
                        text: parent.$t(
                          `assortmentCanvas.${
                            !props.productInfo.isEligible
                              ? props.spacebreakTypes.notInStoreClass
                              : props.spacebreakTypes.notForAssortment
                          }`
                        ),
                        disabled: false,
                      },
                    ]"
                    class="spacebreak-select"
                  />

                  <rtls-select
                    v-if="
                      props.productInfo.isEligible &&
                        props.productInfo.assortment &&
                        !props.productInfo.isLocal
                    "
                    :value="props.selectedSpacebreak.value"
                    :items="props.spacebreaks"
                    :menu-props="{ top: true, offsetY: true }"
                    :disabled="
                      props.productInfo.lockType === props.lockTypes.locked ||
                        props.spacebreakDisabled ||
                        !props.productInfo.inReset
                    "
                    :attach="
                      /* Attach to select-container to position dropdown menu correctly on scroll.
                         Only apply if parent is dashboard to avoid issues opening the dropdown menu.  */
                      props.parentIsDashboard ? '.select-container' : ''
                    "
                    @input="
                      // need to change input event because this is a functional component with a select control
                      listeners.input && listeners.input($event)
                    "
                    @change="props.changeSpacebreak($event)"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex flex-column mt-3">
              <div class="d-flex justify-space-between sub-info-row">
                <div
                  class="font-weight-bold mr-4 copy-to-clipboard"
                  @click="$options.copyToClipboard(`${props.productInfo.optimisedNote}`, $event)"
                >
                  {{ parent.$t('assortmentCanvas.optimiserNotes') }}
                </div>
                <div :title="props.productInfo.optimisedNote" class="overflow-ellipsis">
                  {{ props.productInfo.optimisedNote }}
                </div>
              </div>
            </div>
            <div v-if="props.productPallets.length > 0" class="d-flex flex-column mt-3">
              <div class="d-flex flex-column sub-info-row ">
                <div class="font-weight-bold mr-4">
                  {{ parent.$t('general.pallets') }}
                </div>
                <div class="pallets">
                  <div v-for="pallet in props.productPallets" :key="pallet.productKeyDisplay">
                    <product-pallet
                      :product="pallet"
                      :pallet-content-spacebreak="props.selectedSpacebreak"
                      :all-pallets="props.productPallets"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex px-3 pt-3 pb-2">
        <button v-if="!props.parentIsDashboard" class="button" @click="props.popupButton.action()">
          {{ props.popupButton.label }}
        </button>
      </div>
      <div id="copy-tooltip" class="copy-tooltip-hidden">
        {{ parent.$t('assortmentCanvas.copyTooltip') }}
      </div>
    </vue-draggable-resizable>
  </div>
</template>

<script>
import CopyIcon from '@/img/copyToClipboard.svg';
import { kpis } from '@enums/product-kpis';
import { get } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductDraggableTooltip',

  props: {
    productPallets: {
      type: Array,
      required: false,
      default: () => [],
    },
    productInfo: {
      type: Object,
      required: true,
    },
    podAndStoreCountInfo: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: false,
      default: () => ({
        clientX: 0,
        clientY: 0,
      }),
    },
    conditionalProductTileStyle: {
      type: Object,
      required: true,
    },
    productIndex: {
      // required for parent components that hold products in an array instead of a single variable
      type: Number,
      required: false,
      default: () => 0,
    },
    cssZindex: {
      type: Number,
      required: false,
      default: () => 2,
    },
    productPositioningInfo: {
      type: Object,
      required: true,
    },
    // props for the pictures
    imageUrl: {
      type: String,
      required: false,
      default: '',
    },
    imageAlt: {
      type: String,
      required: false,
      default: '',
    },
    imageTitle: {
      type: String,
      required: false,
      default: '',
    },
    imageTagText: {
      type: String,
      required: false,
      default: '',
    },
    spacebreaks: {
      type: Array,
      required: true,
    },
    selectedSpacebreak: {
      type: Object,
      required: true,
    },
    changeSpacebreak: {
      type: Function,
      required: true,
    },
    lockTypes: {
      type: Object,
      required: true,
    },
    popupButton: {
      type: Object,
      required: true,
    },
    parentIsDashboard: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedKpis: {
      type: Array,
      required: true,
    },
    spacebreakDisabled: {
      type: Boolean,
      required: true,
    },
    spacebreakTypes: {
      type: Object,
      required: true,
    },
    copyIcon: {
      type: String,
      required: false,
      default: CopyIcon,
    },
    currentNumericLocale: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('context', ['getClientConfig']),

    usesMarginWithoutFunding() {
      return get(this.getClientConfig, 'features.marginWithoutSalesEnabled', false);
    },
  },

  methods: {
    getKPIValue(parent, props, kpi) {
      if (kpi === kpis.SELFSWITCHING) {
        return `${props.productInfo[kpi] ? (props.productInfo[kpi] * 100).toFixed(2) : 0}%`;
      }

      if (
        (!this.usesMarginWithoutFunding && kpi === kpis.MARGIN) ||
        (this.usesMarginWithoutFunding && kpi === kpis.MARGINWITHOUTFUNDING)
      ) {
        if (!props.productInfo[kpi]) return `0%`;

        const marginValueToShow =
          props.productInfo[kpi] <= 1 && props.productInfo[kpi] > 0
            ? (props.productInfo[kpi] * 100).toFixed(0)
            : Number(props.productInfo[kpi]).toFixed(0);

        return `${marginValueToShow}%`;
      }

      if (kpi === kpis.UTILITY || kpi === kpis.RANK) return props.productInfo[kpi];

      return parent.formatNumber({
        number: props.productInfo[kpi] || 0,
        format: 'float',
      });
    },

    getKpiTitle(parent, props, kpi) {
      if (kpi === kpis.SALES) {
        return parent.$t(`assortmentCanvas.${kpi}`, {
          currency: parent.$t(`currencies.${props.currentNumericLocale}`),
        });
      }

      return parent.$t(`assortmentCanvas.${kpi}`);
    },
  },

  copyToClipboard(str, event) {
    const contentsToCopy = str || '';

    // Show the little tooltip at the right position with good old vanilla JS
    const copyTooltip = document.getElementById('copy-tooltip');
    copyTooltip.classList.remove('copy-tooltip-hidden');
    copyTooltip.style.left = `${event.srcElement.offsetLeft}px`;
    copyTooltip.style.top = `${event.srcElement.offsetTop - 30}px`;

    navigator.clipboard.writeText(contentsToCopy);

    // Hide the tooltip
    setTimeout(function() {
      copyTooltip.classList.add('copy-tooltip-hidden');
    }, 1000);
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.close {
  cursor: pointer;
}
.heading {
  border-bottom: 1px solid $assortment-divider-colour;
}
.product-tooltip-row {
  font-size: 1.2rem;
  padding: 1px 6px;
  &:nth-child(odd) {
    background: $assortment-table-blue-bg-colour;
  }

  &.divider {
    border-bottom: 1px solid $assortment-menu-border-colour;
  }
  &.divider-top {
    border-top: 1px solid $assortment-menu-border-colour;
  }
}
.sub-info-row {
  font-size: 1.2rem;
  background: $assortment-table-white-bg-colour;
}

.popup-right-side {
  width: 100%;
}
.popup-left-side {
  width: 100%;
  display: flex;
  justify-content: center;
}
.popup-centre {
  width: 100%;
  display: flex;
  justify-content: center;
}
.draggable-resizable {
  position: absolute;
  background: white;
  bottom: 100px;
  box-shadow: $assortment-pop-out-box-shadow;

  &.active {
    // position the currently dragged tooltip on top of all the others
    z-index: 197 !important;
  }
}

.draggable-fit-content {
  height: fit-content !important;
}

.heading {
  padding: 7px 12px;
}

.main-body {
  padding: 16px;
}

.product-title {
  font-weight: bold;
  font-size: 1.3rem;
}

.product-tile {
  background-position: center center;
  background-size: contain;
  color: #000000;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  font-size: 14px;
}

.product-dashboard {
  background-position: center center;
  background-size: contain;
  color: #000000;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  padding-bottom: 10px;
}

.enabled-product-tile {
  pointer-events: visible;
}

.button {
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  color: white;
  border-radius: 3px;
  background-color: $assortment-primary-colour;
  font-size: 1.4rem;
  border: none;
  text-decoration: none;
}

.drag-cancel {
  cursor: default;
}

.spacebreak-select {
  padding-left: 5px;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid $assortment-primary-colour;
  outline: none;
  background: $assortment-control-secondary-bg-colour;
}

.select-container {
  width: 100%;
  position: relative;

  &--spacebreak {
    width: 75%;
  }

  .appended-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    &::before {
      font-size: 24px;
    }
  }
}

.info-table {
  // max-height and overflow set here for when additional client kpis are selected
  max-height: 300px;
  width: 100%;
  overflow: auto;
}

::v-deep {
  .select-container .v-input--is-disabled {
    padding: 0;

    .v-input {
      &__control {
        min-height: 27px;
      }

      &__slot {
        height: 27px !important;

        &:before {
          border: none;
        }
      }
    }

    .v-select__slot {
      height: 27px !important;
    }
  }
  .product-content-container {
    max-height: 560px;
    overflow-y: auto;
  }

  .image-wrapper {
    position: relative;
    padding: 3px 2px 2px 2px;
    margin: 5px;
    align-items: center;
    border: 1px solid #d9dadd;
    border-radius: 5px;
    transition: all 1s ease-out;

    .absolute-height {
      height: 100% !important;
    }
  }
}

.copy-to-clipboard {
  cursor: pointer;
  text-decoration: underline;
  color: $reporting-section-active-border;
}

.copy-text {
  font-size: 1.2rem;
  margin-left: 20px;
  margin-bottom: -15px !important;
}

#copy-tooltip {
  background: $assortment-canvas-tooltip-background;
  color: $assortment-input-background;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  padding: 5px 16px;
  position: absolute;
  text-transform: none;
  width: auto;
  opacity: 0.9;
  pointer-events: none;
  z-index: 199;
}
.copy-tooltip-hidden {
  display: none !important;
}
</style>
