<template>
  <v-icon large class="icon" :disabled="disabled" @click="toggleExpand">{{ name }}</v-icon>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    isExpanded: {
      type: Boolean,
      required: false,
    },

    isLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    name() {
      if (this.isLink) return 'mdi-chevron-right';
      return `expand_${this.isExpanded ? 'less' : 'more'}`;
    },
  },

  methods: {
    toggleExpand() {
      this.$emit('expand');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.icon {
  color: $assortment-primary-colour;
  cursor: pointer;
}
</style>
