'use strict';

const permissions = /** @type {const} */ ({
  // Server
  workpackageView: 'can-view-workpackages',
  workpackageDelete: 'can-delete-workpackages',
  workpackageAddProducts: 'can-add-woorkpackage-products',
  workpackageDeleteProducts: 'can-delete-workpackage-products',
  workpackageChangeProductSelection: 'can-change-workpackage-products-selection',
  workpackageEdit: 'can-edit-workpackages',
  workpackageAdd: 'can-add-workpackages',
  workpackageRunSetup: 'can-run-workpackage-setup',
  scenarioView: 'can-view-scenarios',
  scenarioWrite: 'can-update-scenarios',
  scenarioOptimiserSettingsWrite: 'can-update-scenario-optimiser-settings',
  scenarioProductsView: 'can-view-scenario-products',
  scenarioProductsWrite: 'can-update-scenario-products',
  homePageView: 'can-view-homepage',
  bundleView: 'can-view-bundles',
  bundleEdit: 'can-edit-bundles',
  apiDocsView: 'can-view-api-docs',
  assortmentCanvasView: 'can-read-assortment-canvas',
  assortmentCanvasWrite: 'can-update-assortment-canvas',
  assortmentCanvasProductsView: 'can-view-assortment-canvas-products',
  assortmentCanvasProductsWrite: 'can-update-assortment-canvas-products',
  assortmentGroupsView: 'can-view-assortment-groups',
  extractsView: 'can-view-extracts',
  extractsWrite: 'can-update-extracts',
  regionsView: 'can-view-regions',
  regionsWrite: 'can-update-regions',
  templateDelete: 'can-delete-templates',

  // compound
  compoundExtractsView: 'can-view-compound-extracts',
  compoundExtractsWrite: 'can-update-compound-extracts',
  storeExecutionsWrite: 'can-update-store-executions',
  reportingView: 'can-view-reporting',
  switchingMatricesView: 'can-view-switching-matrices',
  switchingMatricesWrite: 'can-update-switching-matrices',
  toolDataView: 'can-view-tool-data',
  fileView: 'can-view-files',
  fileWrite: 'can-update-files',
  stateView: 'can-view-state',
  csvWrite: 'can-update-csv',
  productsView: 'can-view-products',
  jobapiEngineRunFunction: 'can-run-job-engine-function',
  adminWrite: 'can-update-admin',
  analyticsJobsRun: 'can-run-analytics-jobs',
  snapshotDatesView: 'can-view-snapshot-dates',
  canTriggerDependencyTree: 'can-trigger-dependency-tree',
  userNotificationsView: 'can-view-notifications',
  userNotificationsWrite: 'can-update-notifications',
  prepareSwapsRun: 'can-run-prepare-swaps',
  optimiseSwapsRun: 'can-run-optimise-swaps',
  optimiseSwapsView: 'can-view-optimise-swaps',
  clientAdminView: 'can-view-client-admin',
  clientAdminWrite: 'can-update-client-admin',
  referenceClusterSchemesRead: 'can-view-reference-cluster-schemes',
  referenceClusterSchemesWrite: 'can-update-reference-cluster-schemes',

  // Client

  // Admin
  canViewAdminPage: 'can-view-admin-page',
  canViewClientAdminPage: 'can-view-client-admin-page',

  // Kit of parts
  canViewKitOfParts: 'can-view-kit-of-parts',

  // Status Page
  canViewStatusPage: 'can-view-status-page',

  // Space
  canViewFurnitureEditorPage: 'can-view-furniture-editor-page',
  canViewFurnitureMappingPage: 'can-view-furniture-mapping-page',
  canEditFurnitureMappingPage: 'can-edit-furniture-store-mapping',
  canViewSpacebreakCalculatorPage: 'can-view-spacebreak-calculator-page',
  canEditSpacebreakCalculatorPage: 'can-edit-spacebreak-calculator-page',
  canViewStoreclassDefinitionPage: 'can-view-storeclass-definition-page',
  canEditStoreclassDefinitionPage: 'can-edit-storeclass-definition-page',
  canViewPlanogramSelectionPage: 'can-view-planogram-selection-page',
  canEditFurnitureEditor: 'can-edit-furniture-editor',

  // Inputs
  canViewAttributeEditorPage: 'can-view-attribute-editor-page',
  canEditAttributeEditorPage: 'can-edit-attribute-editor-page',
  canViewCannGroupPage: 'can-view-cann-group-page',
  canEditCannGroupPage: 'can-edit-cann-group-page',
  canViewVarietyPage: 'can-view-variety-page',
  canEditVarietyPage: 'can-edit-variety-page',
  canViewPrepareSwapsPage: 'can-view-prepare-swaps-page',
  canTriggerPrepareSwaps: 'can-trigger-prepare-swaps',
  canEditClientID: 'can-edit-client-id',

  // Measuring
  canViewSwitchingBehaviourPage: 'can-view-switching-behaviour-page',
  canViewCDTPage: 'can-view-cdt-page',
  canEditCDTPage: 'can-edit-cdt-page',
  canViewRegionPage: 'can-view-region-page',
  canEditRegionPage: 'can-edit-region-page',
  canViewClusteringPage: 'can-view-clustering-page',
  canEditClusteringPage: 'can-edit-clustering-page',
  canEditSwitching: 'can-edit-switching',

  // Swaps
  canViewSwapsPage: 'can-view-swaps-page',
  canTriggerOptimiseSwaps: 'can-trigger-optimise-swaps',

  // Product Modelling
  canViewModellingPage: 'can-view-product-modelling-page',
  canEditModellingPage: 'can-edit-product-modelling-page',

  // Switching Modelling
  canViewSwitchingModellingPage: 'can-view-switching-modelling-page',
  canEditSwitchingModellingPage: 'can-edit-switching-modelling-page',

  // Assortments Panel
  canViewAssortmentsPanel: 'can-view-assortments-panel',

  // Assortment Canvas
  canViewAssortmentCanvas: 'can-view-assortment-canvas',
  canEditAssortmentCanvas: 'can-edit-assortment-canvas',

  // Finalisation
  canViewFinalisation: 'can-view-finalisation-page',
  canEditFinalisation: 'can-edit-finalisation',

  // Execution
  canViewExecutionPage: 'can-view-execution-page',
  canEditStoreExecutionPlanning: 'can-edit-store-execution-planning',

  // Extracts
  canViewExtractsPage: 'can-view-execution-page',
  canEditProductStoreExtracts: 'can-edit-product-store-extracts',

  // Reporting
  canViewReportingPage: 'can-view-reporting-page',
  canViewStatsReport: 'can-view-stats-report',
  canViewRelativeShareReport: 'can-view-relative-share-report',
  canViewPriceLadderReport: 'can-view-price-ladder-report',
  canViewComparisonWaterfallReport: 'can-view-comparison-waterfall-report',
  canViewProductReport: 'can-view-product-report',

  // Home Page - workpackages
  canCopyWorkpackage: 'can-copy-workpackage',
  canDeleteWorkpackage: 'can-delete-workpackage',
  canArchiveWorkpackage: 'can-archive-workpackage',
  canUnArchiveWorkpackage: 'can-unarchive-workpackage',
  canEditWorkpackageNameDescription: 'can-edit-workpackage-name-description',
  canCreateWorkpackage: 'can-create-workpackage',
  canChangeWorkpackageType: 'can-change-workpackage-type',

  // Home page - scenarios
  canCreateScenario: 'can-create-scenario',
  canDeleteScenario: 'can-delete-scenario',
  canCopyScenario: 'can-copy-scenario',
  canEditScenarioName: 'can-edit-scenario-name',

  // WP Scope
  canEditWorkpackageScope: 'can-edit-workpackage-scope',

  // Assortments Panel
  canChangeOptimiserSettings: 'can-change-optimiser-settings',
  canFetchCanvases: 'can-fetch-canvases',
  canOptimiseCanvases: 'can-optimise-canvases',
  canEditCanvasDescription: 'can-edit-canvas-description',

  // Docs
  canViewDocs: 'can-view-docs',

  // Notes
  canViewNotes: 'can-view-notes',
  canCreateNotes: 'can-create-notes',
  canDeleteNotes: 'can-delete-notes',
  canEditNotes: 'can-edit-notes',

  // Extracts
  canViewCompoundExtracts: 'can-view-compound-extracts',
  canEditCompoundExtracts: 'can-edit-compound-extracts',
  canDuplicateCompoundExtracts: 'can-duplicate-compound-extracts',
  canDeleteCompoundsExtract: 'can-delete-compound-extracts',

  // KASU extract
  canViewKasuExtracts: 'can-view-kasu-extracts',
  canCreateKasuExtracts: 'can-create-kasu-extracts',
  // HASU extract
  canViewHasuExtracts: 'can-view-hasu-extracts',
  canCreateHasuExtracts: 'can-create-hasu-extracts',
  // AOC extract
  canViewAocExtracts: 'can-view-aoc-extracts',
  canCreateAocExtracts: 'can-create-aoc-extracts',

  // Templates
  canViewTemplates: 'can-view-templates',
  canEditTemplates: 'can-edit-templates',

  // Bundles
  canViewBundles: 'can-view-bundles',
  canEditBundles: 'can-edit-bundles',
  canCreateLayouts: 'can-create-layouts',

  // Reports external API
  canGenerateReportsApiToken: 'can-generate-reports-api-token',
  canUseReportsApiProductLevelExtract: 'can-use-reports-api-product-level-extract',
});

module.exports = permissions;
