<template>
  <v-card class="step-tab-panel" flat>
    <div class="assortment-table attributes-table switching-modelling-page d-flex flex-column">
      <v-container
        class="actions-container flex-grow-0"
        data-id-e2e="switchingModellingActionsTopSection"
      >
        <v-row>
          <v-col
            class="actions-col d-flex justify-start align-center filter-select-header pr-6 br-1"
          >
            <span class="info-note mr-0">{{ $tkey('switchingModellingTitle') }}</span>
            <span class="pl-1 pr-1">{{ $tkey('switchingModellingTitleNote') }}</span>

            <!-- Switching modelling tooltip -->
            <!-- TODO add tool guide link -->
            <docs-link link="" />
          </v-col>
        </v-row>
      </v-container>

      <v-container
        class="actions-container flex-grow-0"
        data-id-e2e="switchingModellingActionsBottomSection"
      >
        <v-row>
          <v-col
            class="actions-col d-flex justify-start align-center filter-select-header pr-6 br-1"
          >
            <span class="info-note mr-0">{{ $tkey('switchingModellingSubTitle') }}</span>
            <span class="pl-1 pr-1">{{ $tkey('switchingModellingSubTitleNote') }}</span>
            <span class="rtls-border rtls-border--left ml-2 pl-2">
              {{ $tkey('switchingModellingDataQuality') }}
            </span>
            <div class="square-quality good ml-3" />
            <span class="pl-1 pr-1">{{ $t('general.good') }}</span>
            <div class="square-quality medium ml-3" />
            <span class="pl-1 pr-1">{{ $t('general.medium') }}</span>
            <div class="square-quality poor ml-3" />
            <span class="pl-1 pr-1">{{ $t('general.poor') }}</span>
          </v-col>
        </v-row>
      </v-container>

      <div class="ag-grid-box flex-grow-1">
        <ag-grid-vue
          data-id-e2e="switchingModellingGrid"
          style="width: 100%; height: 100%;"
          class="ag-theme-custom switching-modelling-grid"
          :row-data="switchingMetrics"
          :grid-options="gridOptions"
          @grid-ready="onGridReady"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import {
  get,
  map,
  each,
  last,
  size,
  set,
  groupBy,
  filter,
  keyBy,
  includes,
  partition,
} from 'lodash';
import { mapState, mapActions } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import cannGroupUtils from '@/js/utils/cann-groups';
import to from 'await-to-js';
import switchingModellingMetricsRenderer from '../renderers/switching-modelling-metrics-renderer.vue';
import similarityBiasRenderer from '../renderers/similarity-bias-renderer.vue';
import useUnclusteredRenderer from '../renderers/use-unclustered-renderer.vue';

const localizationKey = 'switchingModellingPage';

export default {
  name: 'SwitchingModelling',
  localizationKey,
  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    similarityBiasRenderer,
    useUnclusteredRenderer,
    switchingModellingMetricsRenderer,
  },

  data() {
    return {
      switchingMetrics: [],
      headers: {
        cannGroupName: {
          headerName: '',
          field: 'cannGroupName',
          editable: false,
          width: 170,
          maxWidth: 170,
          cellClass: 'info-note pa-5',
        },
        totalProductCount: {
          headerName: this.$tkey('productsCount'),
          field: 'totalProductCount',
          editable: false,
          width: 80,
          maxWidth: 80,
          cellClass: 'd-flex justify-end pr-4 ag-cell-border-right',
        },
        similarityBias: {
          headerName: this.$tkey('similarityBias'),
          field: 'similarityBias',
          editable: false,
          width: 220,
          maxWidth: 220,
          cellRenderer: 'similarityBiasRenderer',
          cellRendererParams: () => ({
            clusters: this.clusters,
            onSelectionChange: this.onUnclusteredSelectionChanged,
          }),
          cellClass: 'px-2 grey-cell ag-cell-border-right',
        },
        useUnclustered: {
          headerName: this.$tkey('useUnclustered'),
          field: 'useUnclustered',
          editable: false,
          width: 80,
          maxWidth: 80,
          menuTabs: [], // hides col menu
          cellStyle: { textAlign: 'right' },
          cellRenderer: 'useUnclusteredRenderer',
          cellRendererParams: params => ({
            cannGroupId: params.data.cannGroupId,
            clusters: this.clusters,
            onSelectionChange: this.onUnclusteredSelectionChanged,
          }),
          headerClass: 'unclustered-header',
          cellClass: 'grey-cell pr-0 justify-end',
        },
        metrics: {
          headerName: this.$tkey('unclustered'),
          field: 'unclusteredMetrics',
          editable: false,
          width: 250,
          maxWidth: 250,
          menuTabs: [],
          headerClass: 'pl-1 ag-cell-border-left-bold ag-cell-border-right-bold',
          cellClass: 'pr-1 ag-cell-border-left-bold ag-cell-border-right-bold',
          cellRenderer: 'switchingModellingMetricsRenderer',
          cellRendererParams: params => {
            // Products that appear in switching matrices are those with threshold linked transactions (baskets)
            // Undefined as the clusterId prop is optional, thus empty on unclustered matrices
            const productCountWithLinkedBaskets =
              params.data.cannGroupMatricesByCluster.undefined.original.productKeys;
            return {
              metrics: params.data.unclusteredMetrics,
              cannGroupId: params.data.cannGroupId,
              cannGroupName: params.data.cannGroupName,
              clusterName: this.$tkey('unclustered'),
              productCountWithLinkedBaskets: productCountWithLinkedBaskets.length,
              totalProductCount: params.data.totalProductCount,
              storeCount: params.data.unclusteredStoreCount,
              onSetToUnclusteredChange: this.onUnclusteredSelectionChanged,
            };
          },
        },
        emptyColumn: {
          // empty column to add space after the last actual column
          resizable: false,
        },
      },

      initialHeaders: [], // Set in created hook
      gridOptions: {
        getRowId(row) {
          return `${row.data.cannGroupId}`;
        },
        suppressContextMenu: true,
        // hides blue border
        suppressCellFocus: true,
        immutableData: true,
        tooltipShowDelay: 0,
        rowHeight: 50,
        headerHeight: 50,
        groupHeaderHeight: 60,
        overlayLoadingTemplate: `<span class="ag-overlay-loading-center">${this.$t(
          'general.inProgress'
        )}</span>`,
        defaultColDef: {
          filter: true,
          suppressMovable: true,
          menuTabs: ['filterMenuTab'],
          sortable: false,
          editable: false,
          minWidth: 80,
        },
      },

      gridApi: null,
      columnApi: null,

      isRunningCalculations: false,
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('clustering', ['selectedScheme']),
    ...mapState('scenarioProducts', ['scenarioProducts']),

    clusters() {
      return get(this.selectedScheme, 'clusters', []);
    },

    clustersById() {
      return groupBy(this.clusters, 'clusterId');
    },

    productsByCannGroup() {
      return groupBy(this.scenarioProducts, 'cannGroupId');
    },
  },

  async created() {
    await this.fetchScenarioClusters();
    await this.fetchScenarioProducts({ options: { shouldCommit: true } });
    await this.composeMetricsData();
    this.setAllInitialHeaders();

    if (this.gridApi) {
      this.gridApi.setColumnDefs(this.initialHeaders);
      this.gridApi.sizeColumnsToFit();
    }
  },

  methods: {
    ...mapActions('clustering', ['fetchScenarioClusters']),
    ...mapActions('switchingMatrices', [
      'fetchScenarioSwitchingMatrices',
      'runSimulationSetToUnclustered',
      'undoSwitchingModellingDecisions',
    ]),
    ...mapActions('scenarioProducts', ['fetchScenarioProducts']),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      if (!this.gridOptions.columnDefs) this.gridApi.setColumnDefs(this.initialHeaders);
    },

    async composeMetricsData() {
      // clear previous results
      this.switchingMetrics = [];
      // #1 - fetch and group all data
      const cannGroups = cannGroupUtils.getLeafNodes(this.selectedScenario.cannGroups);
      const validClusterIds = get(this.selectedScheme, 'clusters', []).map(c => c.clusterId);
      let switchingMatrices = await this.fetchScenarioSwitchingMatrices({
        where: {
          scenarioId: this.selectedScenario._id,
          // TODO: Simplify using NULL on $in AOV3-627
        },
      });
      // Ideally we'd pass NULL to query, however, repository utils sets NULLs to ObjectId
      // due to that, we remove unnecessary matrices here. TODO AOV3-627 - undo this hack
      switchingMatrices = switchingMatrices.filter(
        sm => !sm.clusterId || includes(validClusterIds, sm.clusterId)
      );

      const scenarioStoresInAnalysisInAssortment = filter(this.selectedScenario.stores, store => {
        return store.analysis || store.assortment;
      });

      const switchingMatricesByCannGroup = groupBy(switchingMatrices, 'cannGroupId');

      // #2 - for every cann group, build a row with metrics data based on switching matrices
      each(cannGroups, cannGroup => {
        const cannGroupMatrices = switchingMatricesByCannGroup[cannGroup.key];
        const cannGroupProducts = this.productsByCannGroup[cannGroup.key];
        const cannGroupMatricesByCluster = keyBy(cannGroupMatrices, 'clusterId');

        const row = {
          cannGroupId: cannGroup.key,
          cannGroupName: cannGroup.name,
          totalProductCount: size(cannGroupProducts), // total would be all products in a given cann group
          unclusteredStoreCount: size(scenarioStoresInAnalysisInAssortment),
          similarityBias: cannGroupMatrices[0].similarityBias, // all cann group matrices would have the same bias for now
          unclusteredMetrics: {},
          metricsByCluster: {},
          cannGroupMatricesByCluster,
          cannGroupMatrices,
        };

        // Fill in metrics data for every cann group
        each(cannGroupMatrices, matrix => {
          // Create unclustered metrics object for row first
          if (!matrix.clusterId) {
            row.unclusteredMetrics = matrix.metrics;
          } else {
            // Fill in metrics map for every cluster
            set(row.metricsByCluster, matrix.clusterId, matrix.metrics);
          }
        });

        this.switchingMetrics.push(row);
      });
    },

    createClusteredColumns() {
      // Use clusters in selected clustering scheme to initialise the cluster column group that renders on the table
      return map(this.clusters, ({ clusterName, clusterId, storeKeys, isNoDataCluster }) => {
        // Check if this is the final item in the clustered array
        // if so then add a right border class as it is the final column.
        const isLastCluster = last(this.clusters).clusterId === clusterId;

        return {
          ...this.headers.metrics,
          headerName: clusterName,
          headerClass: () => {
            return isLastCluster ? 'ag-cell-border-right' : '';
          },
          cellClass: () => {
            return isLastCluster ? 'ag-cell-border-right pl-0 pr-0' : 'pl-0 pr-0';
          },
          field: `metricsByCluster.${clusterId}`,
          cellRendererParams: params => {
            // Products that appear in switching matrices are those with threshold linked transactions (baskets)
            const productCountWithLinkedBaskets = get(
              params.data.cannGroupMatricesByCluster[clusterId],
              'original.productKeys',
              []
            );
            return {
              metrics: params.data.metricsByCluster[clusterId],
              cannGroupId: params.data.cannGroupId,
              cannGroupName: params.data.cannGroupName,
              clusterId,
              clusterName,
              isNoDataCluster,
              productCountWithLinkedBaskets: productCountWithLinkedBaskets.length,
              totalProductCount: params.data.totalProductCount,
              storeCount: size(storeKeys),
              onSetToUnclusteredChange: this.onUnclusteredSelectionChanged,
            };
          },
        };
      });
    },

    setAllInitialHeaders() {
      // Generate column groups for each cluster group, including unclustered
      const clusteredColumns = this.createClusteredColumns();

      this.initialHeaders = [
        {
          headerName: this.$tkey('cannGroups'),
          headerClass: 'pa-1',
          children: [
            this.headers.cannGroupName,
            this.headers.totalProductCount,
            this.headers.similarityBias,
            this.headers.useUnclustered,
          ],
        },
        {
          headerName: this.$tkey('clusters'),
          headerClass: 'pa-1 ag-cell-border-left-bold ag-cell-border-right-bold',
          // Column for unclustered metrics
          children: [this.headers.metrics],
        },
        // Generate column group for clusters
        {
          headerName: '',
          headerClass: 'border-left-data ag-cell-border-right',
          children: [...clusteredColumns],
        },
        {
          headerName: '',
          children: [this.headers.emptyColumn],
        },
      ];
    },

    async onUnclusteredSelectionChanged(payload) {
      this.gridApi.showLoadingOverlay();

      const [matricesToSetToUnclustered, itemsToUndo] = partition(payload, 'method');

      if (matricesToSetToUnclustered.length) {
        await this.setToUnclusterClustersCannGroup(matricesToSetToUnclustered);
      }
      if (itemsToUndo.length) {
        await this.revertChanges(itemsToUndo);
      }

      this.gridApi.hideOverlay();
    },

    async setToUnclusterClustersCannGroup(calculations) {
      this.isRunningCalculations = true;
      // Compose payload for jobapi function
      const payload = {
        scenario_id: this.selectedScenario._id,
        calculations,
      };

      await this.runSimulationSetToUnclustered({ payload });
      // update original value to currently set value
      this.originalValue = this.similarityBias;
      await this.composeMetricsData();
      this.isRunningCalculations = false;
    },

    async revertChanges(itemsToUndo) {
      this.isRunningCalculations = true;

      await to(this.undoSwitchingModellingDecisions(itemsToUndo));
      await this.composeMetricsData();
      this.isRunningCalculations = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

// Remove bottom border of the second actions container
.assortment-table {
  .actions-container:nth-child(2) {
    border-bottom: none !important;
  }
}

::v-deep {
  // Style rows to all be white and have appropriate borders
  .ag-row {
    background-color: $assortment-table-white-bg-colour !important;

    border-bottom-color: $assortment-divider-colour-darker !important;
    border-bottom-width: 1px !important;
  }

  .ag-cell {
    border-left-width: 0 !important;
    border-right-width: 0 !important;

    border-bottom-color: $assortment-divider-colour-darker !important;
    border-bottom-width: 1px !important;

    &.grey-cell {
      background-color: $assortment-background;
    }
  }
  .unclustered-header span {
    text-align: right;
  }
}

.square-quality {
  width: 15px;
  height: 15px;

  &.good {
    background-color: $assortment-chip-color;
  }

  &.medium {
    background: $assortment-sales-confidence-score-orange;
  }
  &.poor {
    background: $assortment-sales-confidence-score-red;
  }
}
</style>
