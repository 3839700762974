import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("main-dialog", {
        ref: "confirm",
        attrs: {
          title: _vm.$tkey("ftpFileUploadTitle"),
          message: _vm.dialogMessage,
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function ({ cancel: close }) {
              return [
                _c(
                  VRow,
                  [
                    _c(
                      VCol,
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          VBtn,
                          {
                            attrs: { "data-id-e2e": "btnCancelUploadFtp" },
                            on: { click: close },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.cancel")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            staticClass: "ml-2",
                            attrs: {
                              "data-id-e2e": "btnConfirmUploadFtp",
                              action: "",
                            },
                            on: {
                              click: function ($event) {
                                ;[_vm.sendFile(), close()]
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.upload")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _vm.isVisible && !_vm.clientConfig.features.extracts.ftpSpaceTestEnabled
        ? _c(
            "a",
            {
              attrs: { disabled: _vm.isDisabled },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.openPopup()
                },
              },
            },
            [
              _c(
                VBtn,
                {
                  staticClass: "upload-extracts__button",
                  attrs: {
                    "data-id-e2e": "btnUploadExtracts",
                    secondary: "",
                    depressed: "",
                    loading: _vm.loading,
                    disabled: _vm.isDisabled,
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("actions.upload")) + "\n      "
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "material-icons-outlined upload-extracts__icon",
                    },
                    [_vm._v("file_upload")]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm.isVisible &&
          _vm.clientConfig.features.extracts.ftpSpaceTestEnabled
        ? _c(
            VMenu,
            {
              attrs: { disabled: _vm.isDisabled, "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        VBtn,
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "upload-extracts__button",
                              attrs: {
                                "data-id-e2e": "btnUploadExtracts",
                                secondary: "",
                                depressed: "",
                                loading: _vm.loading,
                                disabled: _vm.isDisabled,
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("actions.upload")) +
                              "\n        "
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "material-icons-outlined upload-extracts__icon",
                            },
                            [_vm._v("file_upload")]
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c(
                VList,
                _vm._l(_vm.items, function (item, index) {
                  return _c(
                    VListItem,
                    { key: index, attrs: { link: "" } },
                    [
                      _c(
                        VListItemTitle,
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openFtpPopup(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.title))]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }