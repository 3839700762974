import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        "div",
        { staticClass: "assortment-table attributes-table d-flex flex-column" },
        [
          _c(
            VContainer,
            { staticClass: "actions-container flex-grow-0" },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    {
                      staticClass:
                        "actions-col actions-col__title justify-start flex-grow-0 align-self-center mr-3",
                    },
                    [
                      _c("span", { staticClass: "info-note" }, [
                        _vm._v(_vm._s(_vm.$tkey("attributes.header"))),
                      ]),
                      _vm._v(" "),
                      _c("docs-link", {
                        attrs: { link: "toolguide/050-attributes.html" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showProductsNotInTemplateWarning
                    ? _c(
                        VCol,
                        { staticClass: "actions-col flex-grow-0 ml-2" },
                        [
                          _c(
                            VAlert,
                            {
                              staticClass: "alert--small mr-5 mb-0",
                              attrs: { type: "info", text: "" },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$tkey(
                                      "attributes.notInTemplateWarning",
                                      [
                                        _vm.totalProductsNotInTemplate,
                                        (_vm.productsData || []).length,
                                      ]
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.productsWithoutPurchasePriceInformation.length
                    ? _c(
                        VCol,
                        { staticClass: "ml-2" },
                        [
                          _c(
                            VAlert,
                            {
                              staticClass: "ma-2 info-message w-100",
                              attrs: {
                                type: "warning",
                                dismissible: "",
                                text: "",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$tkey(
                                      "attributes.validation.notInAssortment",
                                      {
                                        products:
                                          _vm
                                            .productsWithoutPurchasePriceInformation
                                            .length,
                                      }
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showErrorControls
                    ? _c("error-controls", {
                        attrs: {
                          "invalid-rows-message": _vm.invalidRowsErrorMessage,
                          "is-showing-invalid-rows": _vm.filterInvalidRows,
                        },
                        on: { click: _vm.toggleInvalidRows },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { staticClass: "actions-col actions-col__search" },
                    [
                      _vm.gridApi
                        ? _c("rtls-search", {
                            attrs: {
                              grey: "",
                              width: "240px",
                              placeholder: _vm.$tkey("searchPlaceholder"),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.gridApi.onFilterChanged()
                              },
                            },
                            model: {
                              value: _vm.searchString,
                              callback: function ($$v) {
                                _vm.searchString = $$v
                              },
                              expression: "searchString",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VContainer,
            { staticClass: "actions-container flex-grow-0" },
            [
              _c(
                VRow,
                { staticClass: "products-table-actions" },
                [
                  _c(
                    VCol,
                    {
                      staticClass:
                        "actions-col d-flex align-center justify-start",
                    },
                    [
                      _c("rtls-text-field", {
                        staticClass: "text-field",
                        class: { "hidden-triangle": _vm.isTriangleHidden },
                        attrs: {
                          id: "number-of-products",
                          grey: "",
                          width: "150px",
                          "data-id-e2e": "txtNoOfNewProductsToAdd",
                          label: _vm.$tkey("numberOfProducts"),
                          rules: _vm.rules,
                          disabled: _vm.isAddNewProductInputDisabled,
                        },
                        on: {
                          change: function ($event) {
                            _vm.newProductRowsNumber = $event
                          },
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            _vm.isNewRowsNumberValid && _vm.addNewProducts()
                          },
                        },
                        model: {
                          value: _vm.newProductRowsNumber,
                          callback: function ($$v) {
                            _vm.newProductRowsNumber = _vm._n($$v)
                          },
                          expression: "newProductRowsNumber",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        VCol,
                        { staticClass: "actions-col add-products-btn-col" },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "float-left button-space-5",
                              attrs: {
                                id: "btnAddNewProducts",
                                primary: "",
                                "data-id-e2e": "btnAddNewProducts",
                                disabled: _vm.isAddButtonDisabled,
                              },
                              on: { click: _vm.addNewProducts },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("actions.add")) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.hasRestOfMarketEnabled
                            ? _c(
                                VBtn,
                                {
                                  staticClass: "float-left button-space-5",
                                  attrs: {
                                    "data-id-e2e": "btnAddRestOfMarketProducts",
                                    primary: "",
                                  },
                                  on: {
                                    click: _vm.openRestOfMarketProductsModal,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("actions.addROMProducts")) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isResetFromTemplate
                    ? _c("map-fields-modal", {
                        attrs: {
                          value: _vm.isResetFromTemplate,
                          "is-reset-from-template": _vm.isResetFromTemplate,
                          "download-file-url": "downloadFileUrl",
                          "upload-metadata": { uploadMethod: "scenario" },
                          "uploaded-data-details": _vm.uploadedDataDetails,
                          "current-custom-fields": _vm.customHeaderNames,
                          "fields-to-ignore": Array.from(_vm.fieldsToIgnore),
                          "enable-field-creation": "",
                        },
                        on: {
                          confirm: _vm.processData,
                          cancel: _vm.closeResetDataModal,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { staticClass: "actions-col d-flex justify-end" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "outlined-btn mr-2",
                          attrs: {
                            depressed: "",
                            secondary: "",
                            disabled: _vm.isClearFiltersDisabled,
                          },
                          on: { click: _vm.clearFilters },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions.clearAllFilters")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.hasTransferPriceEnabled
                        ? _c("product-attribute-options", {
                            on: {
                              "sync-financials": _vm.syncFinancials,
                              "update-maintain": _vm.bulkUpdateMaintain,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showResetFromTemplateButton
                        ? _c(
                            VBtn,
                            {
                              staticClass: "mr-2 outlined-btn reset-kpi-button",
                              attrs: {
                                disabled: _vm.isEditingDisabled,
                                depressed: "",
                                secondary: "",
                              },
                              on: { click: _vm.openResetFromTemplateModal },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$tkey(
                                      "attributes.resetProductsFromTemplateTitle"
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-2 outlined-btn reset-kpi-button",
                          attrs: {
                            disabled: _vm.isEditingDisabled,
                            depressed: "",
                            secondary: "",
                          },
                          on: { click: _vm.openResetDataModal },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$tkey("attributes.resetProductKPIsTitle")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "outlined-btn mr-2",
                          attrs: {
                            depressed: "",
                            secondary: "",
                            disabled:
                              !_vm.hasNewProducts || _vm.isEditingDisabled,
                          },
                          on: { click: _vm.deleteAllNewProducts },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions.removeNewProducts")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "outlined-btn mr-2",
                          attrs: {
                            disabled: _vm.isCleanAttributesDisabled,
                            depressed: "",
                            secondary: "",
                          },
                          on: { click: _vm.openCleanAttributesModal },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions.cleanAttributes")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.hasMinimumFacingsEnabled ||
                      _vm.hasMinimumDistributionEnabled ||
                      _vm.hasAdditionalFieldsEnabled
                        ? _c(
                            VBtn,
                            {
                              staticClass: "mr-2",
                              attrs: { depressed: "", primary: "" },
                              on: { click: _vm.openAddOptionalColumnsModal },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$tkey("attributes.addOptionalColumns")
                                  ) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { "data-id-e2e": "btnAddAttribute" } },
                        [
                          _c("data-upload", {
                            attrs: {
                              disabled: _vm.isEditingDisabled,
                              legends: _vm.csvUploadLegends,
                              "csv-upload-handler": _vm.onCSVUpload,
                              "source-upload-handler": _vm.onSourceDataUpload,
                              "scenario-upload-handler":
                                _vm.onScenarioDataUpload,
                              "custom-attr-handler": _vm.onCustomAttrAdd,
                              "furniture-upload-handler": _vm.onFurnitureUpload,
                              "custom-fields": [
                                ..._vm.customHeaderNames,
                                ..._vm.storeClassEligibilityHeaderNames,
                              ],
                              "fields-to-ignore": Array.from(
                                _vm.fieldsToIgnore
                              ),
                              "products-data": _vm.productsData,
                              "used-header-names": _vm.usedHeaders,
                              "show-modal": "",
                              "enable-field-creation": "",
                              "attributes-upload": "",
                            },
                            on: { process: _vm.process },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ag-grid-box flex-grow-1" },
            [
              _c("ag-grid-vue", {
                staticClass: "ag-theme-custom ag-theme-custom--attributes",
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  "row-data": _vm.productsData,
                  "grid-options": _vm.gridOptions,
                  "does-external-filter-pass": _vm.doesExternalFilterPass,
                  "stop-editing-when-cells-loses-focus": true,
                  "enable-range-selection": true,
                },
                on: {
                  "cell-value-changed": _vm.trackDiff,
                  "grid-ready": _vm.onGridReady,
                  "column-moved": _vm.onColumnMoved,
                  "drag-stopped": _vm.saveColumnState,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("page-actions", {
            staticClass: "page-actions",
            attrs: {
              "show-export": "",
              "is-custom-export": "",
              "has-data-changes": _vm.hasDataChanges,
              "has-data-errors": _vm.hasDataErrors,
              "save-disabled": _vm.isEditingDisabled,
              "is-discard-enabled": !_vm.isEditingDisabled,
            },
            on: {
              export: function ($event) {
                return _vm.exportCSV()
              },
              discard: function ($event) {
                return _vm.discard()
              },
              save: function ($event) {
                return _vm.saveChanges()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "deleteProduct",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  VCardText,
                  { staticClass: "display-1 pa-0 text-center" },
                  [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$tkey("attributes.deleteProductDialog.question")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$tkey("attributes.deleteProductDialog.warning")
                        )
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function ({ cancel, confirm }) {
              return [
                _c(
                  VCardActions,
                  { staticClass: "justify-center flex-column" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "ma-2",
                        attrs: { primary: "" },
                        on: { click: confirm },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$tkey("attributes.actions.deleteProduct")
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      VBtn,
                      {
                        staticClass: "cancel ma-2",
                        attrs: { text: "", depressed: "" },
                        on: { click: cancel },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("actions.cancel")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _vm.hasRestOfMarketEnabled && _vm.isRestOfMarketProductsModalOpen
        ? _c("rest-of-market-products-modal", {
            attrs: {
              "products-to-exclude": Array.from(_vm.lookupROMProductKeys),
              "product-category-key": _vm.commonProductCategoryLevelKey,
              value: _vm.isRestOfMarketProductsModalOpen,
            },
            on: {
              close: _vm.closeRestOfMarketProductsModal,
              "add-selected-products": _vm.addROMProducts,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("add-optional-columns-modal", {
        attrs: {
          value: _vm.isAddOptionalColumnsModalOpen,
          "optional-attrs": _vm.optionalAttributes,
        },
        on: {
          "add-optional-columns": _vm.addOptionalColumns,
          close: _vm.closeAddOptionalColumnsModal,
        },
      }),
      _vm._v(" "),
      _c("reset-product-data-modal", {
        attrs: {
          value: _vm.isResetDataModalOpen,
          "data-to-reset-enum": _vm.fieldEnum,
        },
        on: {
          "reset-product-data": _vm.resetProducts,
          close: _vm.closeResetDataModal,
        },
      }),
      _vm._v(" "),
      _c("clean-attributes-modal", {
        attrs: {
          value: _vm.isCleanAttributesModalOpen,
          "custom-attributes": _vm.currentCustomAttributes,
          "attributes-to-clean": _vm.attributesToClean,
        },
        on: {
          process: _vm.processCleanAttributesConfig,
          "clean-attributes": _vm.cleanCustomAttributesInEditor,
          close: _vm.closeCleanAttributesModal,
        },
      }),
      _vm._v(" "),
      _c("attribute-grouping-modal", {
        attrs: {
          value: _vm.groupingModalOpen,
          "attribute-values": _vm.groupingValues,
          "grouping-column": _vm.groupingColumn,
          "used-headers": _vm.usedHeaders,
        },
        on: {
          close: _vm.closeGroupingModal,
          "create-attribute-group": _vm.createGroupedAttr,
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
            )
              return null
            _vm.groupingModalOpen = false
          },
        },
      }),
      _vm._v(" "),
      _c("dependency-tree-feedback-modal", {
        attrs: {
          value: _vm.dependencyTreeModalOpen,
          results: _vm.dependencyTreeFeedback,
          "warning-message": _vm.dependencyTreeWarningMessage,
          page: "productAttributeEditor",
        },
        on: {
          close: _vm.closeDependencyTreeModal,
          commit: function ($event) {
            return _vm.saveChanges(true)
          },
        },
      }),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.closeUnsavedDataModal,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }