<template>
  <div class="d-flex flex-column">
    <!-- Title row -->
    <v-row class="mb-3 d-flex justify-space-between">
      <h2 class="mb-1">{{ $tkey('title') }}</h2>
      <v-btn
        class="close-button"
        data-id-e2e="btnCopyConstraintsModalGoBackToSettings"
        icon
        text
        @click="backToConstraintsTable"
      >
        <v-icon size="20" color="primary">mdi-close-circle</v-icon>
      </v-btn>
    </v-row>

    <!-- Search scenario section -->
    <v-row>
      <search-scenario
        inline
        data-id-e2e="searchScenarioSection"
        :rules="[]"
        @scenario-id-selected="updateSelectedScenario"
        @view-results="populateAssortmentCanvasesAndCheckpoints"
      />
    </v-row>

    <!-- Checkpoint selection table section -->
    <v-row class="d-block" data-id-e2e="checkpointsSelectionTableSection">
      <checkpoints-selection-table
        :scenario-id="selectedScenarioId"
        :headers="headers"
        :canvases="selectedScenarioCanvases"
        :checkpoints-by-canvas-id="scenarioCheckpointsByCanvasId"
        :should-validate-checkpoints="false"
        can-view-checkpoint-data
        max-height="40%"
        @view-checkpoint-data="populateCheckpointConstraints"
      />
    </v-row>
    <v-row>
      <h3 class="my-3">
        {{ $t('assortmentCanvasPage.spacebreakSettings.constraints.title') }}:
        <span class="green-text">{{
          constraintsCopyTarget
            ? `${copyConstraintsDescription.workpackageScenarioTitle} / ${
                copyConstraintsDescription.checkpointTitle
              }`
            : ''
        }}</span>
      </h3>
      <div v-if="isFetchingConstraintsForCheckpoint" class="w-100">
        <progress-bar :message="$t('general.loading')" />
      </div>
      <template v-else>
        <spacebreak-constraints-table
          v-for="currentSpacebreak in spacebreaksWithGlobal"
          :key="
            `${
              currentSpacebreak ? currentSpacebreak._id : 'globalSpacebreak'
            }-${selectedCheckpointId}`
          "
          is-copying-constraints
          :attributes-values-in-current-scenario="attributesInCurrentScenario"
          :attributes-values-in-source-scenario="constraintsCopyTarget.attributesValuesMap"
          :spacebreak="currentSpacebreak"
          :constraints="sanitiseConstraints(currentSpacebreak, constraintsCopyTarget.constraints)"
          :source-scenario-attributes="constraintsCopyTarget.customAttributes"
        />
      </template>
    </v-row>
    <v-row class="d-flex mt-3 justify-end">
      <div class="d-flex justify-end">
        <v-btn
          class="ml-2"
          secondary
          depressed
          data-id-e2e="btnGoBackToConstraintsTable"
          @click="backToConstraintsTable"
        >
          {{ $t('actions.cancel') }}
        </v-btn>
        <v-btn
          class="ml-2"
          primary
          depressed
          data-id-e2e="btnCopyConstraintsSelected"
          :disabled="!hasConstraintsToCopySelected"
          :loading="saveInProgress"
          @click="onCopyConstraintsSelected"
        >
          {{ $t('actions.copyConstraints') }}
          <v-icon size="14" right>mdi-plus</v-icon>
        </v-btn>
      </div>
    </v-row>
  </div>
</template>

<script>
import to from 'await-to-js';
import { cloneDeep, findKey, forEach, get, isEmpty, map, partition, reduce, pick } from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  localizationKey: 'assortmentCanvasPage.spacebreakSettings.copyConstraints',
  props: {
    spacebreak: {
      type: Object,
      default: null,
      required: false,
    },
  },

  data() {
    return {
      selectedScenarioId: null,
      selectedScenarioCanvases: [],
      checkpoints: [],
      scenarioCheckpointsByCanvasId: {},
      attributesInCurrentScenario: {},
      saveInProgress: false,
      headers: [
        {
          text: this.$t('checkpointSelectionTable.headers.assortments'),
          value: 'cluster',
        },
        {
          text: this.$t('checkpointSelectionTable.headers.storeClass'),
          value: 'storeClass',
        },
        {
          text: this.$t('checkpointSelectionTable.headers.switching'),
          value: 'switching',
        },
        {
          text: this.$t('checkpointSelectionTable.headers.description'),
          value: 'description',
        },
        {
          text: this.$t('checkpointSelectionTable.headers.checkpoint'),
          value: 'checkpoint',
        },
      ],
      copyConstraintsDescription: { workpackageScenarioTitle: '', checkpointTitle: '' },
      selectedCheckpointId: null,
      isFetchingConstraintsForCheckpoint: false,
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('assortmentCanvas', ['constraintsCopyTarget', 'selectedConstraintsToCopy']),
    ...mapGetters('assortmentCanvas', [
      'selectedCanvas',
      'hasConstraintsToCopySelected',
      'constraintsToCopyById',
    ]),

    spacebreaksWithGlobal() {
      if (!get(this.constraintsCopyTarget, 'spacebreaks')) return null;
      return [null, ...this.constraintsCopyTarget.spacebreaks];
    },
  },

  async created() {
    await this.getAttributesForConstraints();
  },

  methods: {
    ...mapActions('assortmentCanvas', [
      'fetchCanvases',
      'fetchScenarioCheckpoints',
      'fetchConstraintsCopyTarget',
      'updateAssortmentCanvasConstraint',
      'fetchCanvas',
    ]),
    ...mapMutations('assortmentCanvas', ['resetConstraintsCopyTarget']),
    ...mapActions('scenarioProducts', ['fetchScenarioUniqueAttributeValues']),

    backToConstraintsTable() {
      this.$emit('switch-copy-constraints-modal', false);
    },

    updateSelectedScenario(scenarioId) {
      this.selectedScenarioId = scenarioId;
      // if scenario has been cleared, clear canvases and checkpoints
      if (!scenarioId) {
        this.selectedScenarioCanvases = [];
        this.checkpoints = [];
        this.scenarioCheckpointsByCanvasId = {};
      }
    },

    async populateCheckpointConstraints({ checkpointId, name }) {
      this.isFetchingConstraintsForCheckpoint = true;
      this.selectedCheckpointId = checkpointId;
      this.copyConstraintsDescription.checkpointTitle = name;
      await this.fetchConstraintsCopyTarget({ checkpointId });
      this.isFetchingConstraintsForCheckpoint = false;
    },

    async populateAssortmentCanvasesAndCheckpoints(title) {
      this.resetConstraintsCopyTarget();
      this.copyConstraintsDescription.workpackageScenarioTitle = title;
      const canvases = await this.fetchCanvases({
        scenarioId: this.selectedScenarioId,
        isStatelessFetch: true,
      });

      await this.fetchScenarioCheckpoints({
        scenarioId: this.selectedScenarioId,
        isStatelessFetch: true,
      }).then(checkpointsForScenario => {
        this.checkpoints.push(...checkpointsForScenario);
      });

      this.selectedScenarioCanvases = map(canvases, c => ({
        _id: c._id,
        clusterId: c.clusterId,
        cluster: c.clusterName || this.$t('checkpointSelectionTable.unClustered'),
        storeClassId: c.storeClassId,
        storeClass: c.storeClassName,
        switching: c.clusterId
          ? this.$t('checkpointSelectionTable.clustered')
          : this.$t('checkpointSelectionTable.unClustered'),
        description: c.description || this.$t('checkpointSelectionTable.noDescription'),
        checkpoint: null,
      }));

      const canvasIdmap = reduce(
        this.selectedScenarioCanvases,
        (acc, canvas) => ({
          ...acc,
          [`${canvas.clusterId}-${canvas.storeClassId}`]: canvas._id,
        }),
        {}
      );
      if (isEmpty(canvasIdmap)) return {};

      const allCpsByCanvasId = reduce(
        this.checkpoints,
        (acc, checkpoint) => {
          const canvasIdForCheckpoint =
            canvasIdmap[`${checkpoint.clusterId}-${checkpoint.storeClassId}`];
          acc[canvasIdForCheckpoint] = [...(acc[canvasIdForCheckpoint] || []), checkpoint];
          return acc;
        },
        {}
      );

      // Create a map of canvas id to checkpoint options
      forEach(this.selectedScenarioCanvases, ({ _id: canvasId }) => {
        const canvasCheckpoints = get(allCpsByCanvasId, canvasId, []).map(
          ({ checkpointMeta, _id }) => {
            return {
              text: checkpointMeta.name,
              value: _id,
            };
          }
        );
        canvasCheckpoints.unshift({
          text: this.$t('checkpointSelectionTable.noSelection'),
          value: null,
        });
        this.scenarioCheckpointsByCanvasId[canvasId] = canvasCheckpoints;
      });
    },

    // move to global place (spacebreak-settings)
    async getAttributesForConstraints() {
      const data = await this.fetchScenarioUniqueAttributeValues({
        scenarioId: this.selectedScenario._id,
      });

      this.attributesInCurrentScenario = data;
    },

    /* Ensures global constraints only appears on global spacebreak */
    sanitiseConstraints(spacebreak, constraints) {
      const [spacebreakConstraints, globalConstraints] = partition(
        constraints.definitions,
        'spacebreakId'
      );
      const globalConstraintsIds = map(globalConstraints, 'id');

      const sanitisedDefinitions = spacebreak ? spacebreakConstraints : globalConstraints;
      const sanitisedPriorities = cloneDeep(constraints.priorities);
      // remove global constraint from spacebreak constraints
      if (spacebreak) {
        sanitisedPriorities[spacebreak._id] = constraints.priorities[spacebreak._id].filter(
          constraintId => !globalConstraintsIds.includes(constraintId)
        );
      }

      return {
        definitions: sanitisedDefinitions,
        priorities: sanitisedPriorities,
      };
    },

    buildConstraintsPayload() {
      // for each constraint flagged to be copied, get the mapped attribute ID to the current scenario
      return map(this.selectedConstraintsToCopy, (spacebreakId, constraintId) => {
        // ensures current values are recalculated
        const constraintBeingCopied = pick(cloneDeep(this.constraintsToCopyById[constraintId]), [
          'attributeFilters',
          'limits',
          'type',
        ]);
        // gets the attr name from original constraint definition
        const targetAttributeName = this.constraintsCopyTarget.attributesValuesMap[
          constraintBeingCopied.attributeFilters.attributeId
        ].attributeName.toLocaleLowerCase();

        // get the equivalent attr ID in current scenario by the attr name
        constraintBeingCopied.attributeFilters.attributeId = findKey(
          this.attributesInCurrentScenario,
          a => a.attributeName.toLocaleLowerCase() === targetAttributeName
        );

        return {
          ...constraintBeingCopied,
          spacebreakId,
        };
      });
    },

    async onCopyConstraintsSelected() {
      this.saveInProgress = true;
      const constraints = this.buildConstraintsPayload();
      await to(
        this.updateAssortmentCanvasConstraint({
          canvasId: this.selectedCanvas._id,
          constraints,
        })
      );
      // calculates the constraints stats
      await this.fetchCanvas({
        canvasId: this.selectedCanvas._id,
        forceSelectedCanvasUpdate: true,
      });
      this.backToConstraintsTable();
      this.saveInProgress = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.green-text {
  color: $assortment-positive-action-colour;
}
</style>
