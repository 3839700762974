import reportingPowerBIPage from './reporting-power-bi.vue';

const routes = [
  {
    path: '/reporting-power-bi',
    name: 'reporting-power-bi',
    id: 'reporting-power-bi',
    component: reportingPowerBIPage,
    linkText: 'navBar.reportingPowerBi',
    meta: {
      featureKey: 'reportingPowerBIEnabled',
      titleExtension: 'navBar.reportingPowerBi',
      requiresPermission: false,
    },
  },
];

export default routes;
