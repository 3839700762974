<template>
  <div class="ag-grid-box flex-grow-1 grid-size">
    <ag-grid-vue
      style="width: 100%; height: 100%;"
      class="ag-theme-custom ag-theme-custom--attributes"
      :column-defs="columnDefs"
      :row-data="products"
      :does-external-filter-pass="doesExternalFilterPass"
      auto-params-refresh
      :grid-options="gridOptions"
      row-drag-managed
      stop-editing-when-cells-loses-focus
      @cell-value-changed="trackDiff"
      @grid-ready="onGridReady"
      @selection-changed="onSelectionChanged"
    />
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapState, mapGetters } from 'vuex';
import {
  cloneDeep,
  get,
  isEmpty,
  isUndefined,
  keyBy,
  pickBy,
  union,
  toNumber,
  forEach,
  camelCase,
} from 'lodash';
import agGridUtils from '@/js/utils/ag-grid-utils';

export default {
  localizationKey: 'workpackagePage.products',
  components: {
    AgGridVue,
  },

  props: {
    products: {
      required: true,
      type: Array,
    },

    productsForDeletion: {
      required: true,
      type: Array,
    },

    searchString: {
      type: String,
      default: () => '',
    },

    isPopupOpen: {
      type: Boolean,
    },
  },

  data() {
    return {
      gridApi: null,
      currentStateDiff: {},
      savedState: {},
      gridOptions: {
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        suppressContextMenu: true,
        enableFillHandle: true,
        enableRangeSelection: true,
        isExternalFilterPresent: () => true,
        getMainMenuItems: this.getMainMenuItems,
        defaultColDef: {
          filter: true,
          sortable: true,
          resizable: true,
          minWidth: 80,
          comparator: agGridUtils.sortings.naturalSort,
          editable: false,
          suppressMovable: true,
          flex: 1,
          cellClass: 'aligned-start priority-cell',
          cellClassRules: {
            'disabled-text': params => this.isTemplate && !params.data.included,
          },
          menuTabs: ['filterMenuTab'],
          suppressKeyboardEvent: agGridUtils.utils.suppressKeyboardEvent,
        },
        processCellFromClipboard: agGridUtils.utils.processCellFromClipboard,
        rowHeight: 30,
        headerHeight: 40,
        getRowId: product => product.data.productKey,
        columnTypes: {
          booleanColumnCustom: agGridUtils.columnTypes.booleanColumnCustom,
        },
      },
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('workpackageProducts', ['loading']),
    ...mapGetters('context', ['getClientConfig']),

    isTemplate() {
      return !!this.selectedWorkpackage.isTemplate;
    },

    hasProductsInResetEnabled() {
      return (
        get(this.getClientConfig, 'features.productsInResetEnabled', false) && !this.isTemplate
      );
    },

    hasReferenceStoreCountEnabled() {
      return get(this.getClientConfig, 'features.referenceStoreCountEnabled', false);
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditWorkpackageScope);
    },

    hasDataChanges() {
      return !isEmpty(this.currentStateDiff) || !!this.productsForDeletion.length;
    },

    workpackageProducts() {
      const productKeysToDeleteSet = new Set(this.productsForDeletion);
      return this.products.filter(({ productKey }) => !productKeysToDeleteSet.has(productKey));
    },

    columnDefs() {
      const columns = [
        {
          colId: 'delete',
          checkboxSelection: true,
          headerName: '',
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          hide: this.templatesEnabled && this.isTemplate,
          resizable: false,
          maxWidth: 50,
          editable: !this.isEditingDisabled,
          suppressNavigable: true,
          menuTabs: [],
        },
        {
          headerName: this.$tkey('table.headers.productKeyDisplay'),
          colId: 'productKeyDisplay',
          field: 'productKeyDisplay',
          width: 50,
        },
        {
          headerName: this.$tkey('table.headers.productDescription'),
          colId: 'itemDescription',
          field: 'itemDescription',
          width: 200,
        },
        {
          headerName: this.$tkey('table.headers.fromTemplate'),
          colId: 'fromTemplate',
          field: 'fromTemplate',
          type: 'booleanColumnCustom',
          hide: !this.templatesEnabled || this.isTemplate,
          resizable: false,
          maxWidth: 120,
          valueFormatter: agGridUtils.formatters.booleanStringFormatter,
          filterParams: {
            valueFormatter: agGridUtils.formatters.booleanStringFormatter,
          },
        },
        // "included" column defines whether the product is included into a template
        // and will be passed to a regular WP. Should only be visible
        // for templates if feature is enabled
        {
          headerName: this.$tkey('table.headers.included'),
          colId: 'included',
          field: 'included',
          type: 'booleanColumnCustom',
          hide: !(this.templatesEnabled && this.isTemplate),
          resizable: false,
          maxWidth: 80,
          editable: !this.isEditingDisabled,
          valueParser: agGridUtils.parsers.booleanParser,
          cellRenderer: params =>
            agGridUtils.utils.checkboxRenderer(params, this.handleCheckboxChange),
          cellRendererParams: {
            field: 'included',
          },
          cellClassRules: {
            'disabled-text': params => this.isTemplate && !params.data.included,
          },
          menuTabs: ['generalMenuTab'],
          headerTooltip: this.$tkey('tooltips.included'),
        },
        {
          headerName: this.$tkey('table.headers.originSource'),
          colId: 'originSource',
          field: 'originSource',
          resizable: false,
          maxWidth: 120,
          valueFormatter: params => this.$tkey(`originSource.${params.value}`),
          filterParams: {
            valueFormatter: params => this.$tkey(`originSource.${params.value}`),
          },
        },
        {
          headerName: this.$tkey('table.headers.brand'),
          colId: 'brandDescription',
          field: 'brandDescription',
          width: 100,
        },
        {
          headerName: this.$tkey('table.headers.content'),
          valueGetter: params => `${params.data.contentValue}${params.data.contentUnitOfMeasure}`,
          width: 70,
          colId: 'contentAndUnitOfMeasure',
        },
        {
          headerName: this.$tkey('table.headers.supplier'),
          colId: 'supplierName',
          field: 'supplierName',
          width: 100,
        },
        {
          headerName: this.$tkey('table.headers.stores'),
          colId: 'referenceStoreCount',
          field: 'referenceStoreCount',
          hide: !this.hasReferenceStoreCountEnabled,
          width: 100,
          headerTooltip: this.$tkey('tooltips.inReset'),
        },
        {
          headerName: this.$tkey('table.headers.status'),
          colId: 'status',
          valueGetter: params =>
            params.data.productStatus === 'Current'
              ? this.$tkey('active')
              : this.$tkey('notActive'),
          width: 70,
        },
        // inReset column should only be visible for standard workpackages if feature is enabled
        {
          headerName: this.$tkey('table.headers.inReset'),
          colId: 'inReset',
          field: 'inReset',
          type: 'booleanColumnCustom',
          hide: !this.hasProductsInResetEnabled,
          resizable: false,
          maxWidth: 120,
          editable: !this.isEditingDisabled,
          valueParser: agGridUtils.parsers.booleanParser,
          cellRenderer: params =>
            agGridUtils.utils.checkboxRenderer(params, this.handleCheckboxChange),
          cellRendererParams: {
            field: 'inReset',
          },
          cellClassRules: {
            'diff-background': this.hasDiff,
          },
          menuTabs: ['generalMenuTab'],
          headerTooltip: this.$tkey('tooltips.inReset'),
        },
      ];

      const extraColumns = get(this.getClientConfig, 'productColumns', {});

      return agGridUtils.builders.mergeHeaders(columns, extraColumns, {
        componentName: camelCase(this.$options.name),
      });
    },
  },

  watch: {
    searchString: {
      deep: true,
      handler() {
        this.gridApi.onFilterChanged();
      },
    },

    isPopupOpen: {
      deep: true,
      handler() {
        this.init();
      },
    },

    products: {
      deep: true,
      handler() {
        const productKeys = new Set(this.products.map(v => v.productKey));
        const currentDiff = cloneDeep(this.currentStateDiff);
        pickBy(currentDiff, (v, k) => productKeys.has(toNumber(k)));
        this.currentStateDiff = currentDiff;
      },
    },

    currentStateDiff(value) {
      this.$emit('handle-data-changes', value);
    },

    loading(isLoading) {
      if (!this.gridApi) return; // AG-Grid may not be initialised yet
      if (isLoading) this.gridApi.showLoadingOverlay();
      else this.gridApi.hideOverlay();
    },
  },

  created() {
    this.init();
  },

  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.refreshCells();
      this.savedState = keyBy(cloneDeep(this.workpackageProducts), 'productKey');
    },

    getMainMenuItems(params) {
      const colId = params.column.getId();
      const filteredT = this.$t('general.filtered');

      return [
        ...params.defaultItems,
        {
          name: `${this.$t('actions.selectAll')} (${filteredT})`,
          action: () => {
            this.handleHeaderCheckboxChange(colId, true);
          },
        },
        {
          name: `${this.$t('actions.unselectAll')} (${filteredT})`,
          action: () => {
            this.handleHeaderCheckboxChange(colId, false);
          },
        },
      ];
    },

    refreshCells() {
      if (!this.gridApi) return;

      if (this.hasProductsInResetEnabled) {
        // Refresh "inReset" column to ensure correct diff highlighting
        this.gridApi.refreshCells({ columns: ['inReset'], force: true });
      }

      if (this.templatesEnabled && this.isTemplate) {
        this.gridApi.refreshCells({ columns: ['included'], force: true });
      }
    },

    init() {
      this.reset();
      this.$nextTick(() => {
        agGridUtils.filters.resetFilters(this.gridOptions);
      });
      this.$emit('refresh');
    },

    reset() {
      this.searchString = '';
      this.currentStateDiff = {};
      this.savedState = keyBy(cloneDeep(this.workpackageProducts), 'productKey');

      this.refreshCells();
    },

    trackDiff(params) {
      const { productKey } = params.data;
      const { field } = params.colDef;
      this.updateDiff(productKey, field, params.value);
    },

    updateDiff(productKey, field, currentValue) {
      const path = `${productKey}.${field}`;
      const originalValue = get(this.savedState, path);

      const existingValue = get(this.currentStateDiff, [productKey, field]);
      const valueHasChanged = agGridUtils.comparators.didValueChange(currentValue, originalValue);

      if (valueHasChanged) {
        if (!this.currentStateDiff[productKey]) this.$set(this.currentStateDiff, productKey, {});
        this.$set(this.currentStateDiff[productKey], field, currentValue);
      } else if (!isUndefined(existingValue)) {
        // existingValue can be false for boolean columns, check if defined instead.
        // Remove the field from the object if it's back to its old value.
        // Note: Entering values directly doesn't trigger cellValueChanged but pasting values does
        this.$delete(this.currentStateDiff[productKey], field);
        if (isEmpty(this.currentStateDiff[productKey])) {
          // If there's nothing left in the object, remove it.
          this.$delete(this.currentStateDiff, productKey);
        }
      }
    },

    hasDiff(params) {
      // Value can't be undefined
      if (isUndefined(params.value)) return false;
      const { productKey } = params.data;
      const { field } = params.colDef;
      const path = `${productKey}.${field}`;

      const currentValue = params.value;
      const originalValue = get(this.savedState, path);

      return agGridUtils.comparators.didValueChange(currentValue, originalValue);
    },

    deleteSelectedProducts(selectedRows) {
      const productKeys = selectedRows.map(n => n.productKey);
      // Remove update for deleted key if it exists
      forEach(productKeys, k => {
        if (this.currentStateDiff[k]) this.$delete(this.currentStateDiff, k);
      });
      this.gridApi.applyTransaction({ remove: selectedRows });
      this.$emit('update:productsForDeletion', union(this.productsForDeletion, productKeys));
      this.resetSearchFilter();
    },

    resetSearchFilter() {
      this.searchString = '';
      agGridUtils.filters.resetFilters(this.gridOptions);
    },

    doesExternalFilterPass(node) {
      let { itemDescription, productKeyDisplay } = node.data;
      if (!itemDescription || !productKeyDisplay) return false;

      itemDescription = agGridUtils.filters.standardStringFilter.textFormatter(itemDescription);
      productKeyDisplay = agGridUtils.filters.standardStringFilter.textFormatter(productKeyDisplay);
      const searchString = agGridUtils.filters.standardStringFilter.textFormatter(
        this.searchString
      );
      const inProductDescription = String(productKeyDisplay).indexOf(searchString) !== -1;
      const inProductKeyDisplay = itemDescription.indexOf(searchString) !== -1;
      return inProductKeyDisplay || inProductDescription;
    },

    handleCheckboxChange(params) {
      params.setValue(!params.value);
    },

    handleHeaderCheckboxChange(field, isSelected) {
      const updates = [];
      this.gridApi.forEachNodeAfterFilter(rowNode => {
        const newRowData = rowNode.data;
        newRowData[field] = isSelected;
        this.trackDiff({
          data: { productKey: newRowData.productKey },
          colDef: { field },
          value: isSelected,
        });

        updates.push(newRowData);
      });

      if (updates.length) {
        this.gridApi.applyTransaction({ update: updates });
      }
    },

    csvExport(params) {
      this.gridApi.exportDataAsCsv(params);
    },

    addRow(rowData) {
      this.gridApi.applyTransaction({ add: rowData });
    },

    onSelectionChanged() {
      this.$emit('selected-rows', this.gridApi.getSelectedRows());
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.grid-size {
  overflow: hidden;
}

.v-input--selection-controls .v-input__control {
  .v-messages {
    display: none;
  }
}

::v-deep {
  .ag-theme-custom {
    .ag-has-focus .ag-cell-focus.ag-cell {
      padding-left: 5px;
      outline: none;
    }

    .ag-ltr {
      .ag-cell {
        padding-left: 5px;
        border-left-width: 1px;
      }
    }
  }

  .ag-cell-focus,
  .ag-cell-no-focus {
    border: none !important;
  }

  .ag-row-selected {
    &.ag-row-even {
      outline: none;
    }

    &.ag-row-odd {
      outline: none;
    }

    &:before {
      content: none;
    }
  }
}
</style>
