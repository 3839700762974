import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.job.status
    ? _c(
        "span",
        { staticClass: "job-status-container" },
        [
          _c("strong", [_vm._v(_vm._s(_vm.label))]),
          _vm._v(" "),
          _vm.simpleJobStatus === _vm.jobSimpleStatuses.finished
            ? _c("span", [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("scenarioManagementPanel.jobs.ranSuccessfully")
                    ) + ": "
                  ),
                ]),
                _vm._v(
                  "\n    " +
                    _vm._s(
                      _vm._f("formatDate")(
                        _vm.job.dateTimeComplete,
                        _vm.getDateFormats.longWithTime
                      )
                    ) +
                    "\n  "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.simpleJobStatus === _vm.jobSimpleStatuses.inProgress
            ? _c("span", [
                _c("strong", [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("scenarioManagementPanel.jobs.inProgress", [
                          Math.round(_vm.job.progress),
                        ])
                      ) +
                      "\n    "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.simpleJobStatus === _vm.jobSimpleStatuses.inProgress
            ? _c(VProgressLinear, {
                attrs: { color: "primary", height: "5" },
                model: {
                  value: _vm.job.progress,
                  callback: function ($$v) {
                    _vm.$set(_vm.job, "progress", $$v)
                  },
                  expression: "job.progress",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.simpleJobStatus === _vm.jobSimpleStatuses.failed
            ? _c("span", { staticClass: "problem-text" }, [
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.$t("scenarioManagementPanel.jobs.failed")) + ": "
                  ),
                ]),
                _vm._v(
                  "\n    " +
                    _vm._s(
                      _vm._f("formatDate")(
                        _vm.job.dateTimeComplete,
                        _vm.getDateFormats.longWithTime
                      )
                    ) +
                    "\n  "
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }