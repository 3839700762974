import axios from 'axios';
import to from 'await-to-js';
import { omit, get, each } from 'lodash';

import { jobApi } from '@/js/helpers';
import handleErrorMessages from '../utils/validation';
import i18n from '../../vue-i18n';

const store = {
  namespaced: true,

  state: {
    switchingStatus: 'Stopped',
    switchingMatrixForCannGroup: null,
    scenarioSwitchingMatrices: [],
    selectedSwitchingMatrix: null,
    switchingMatricesUnclusteredCount: 0,
    loading: false,
  },
  getters: {
    switchingMatricesLoading: state => state.loading,

    switchingMatrixForCannGroup: state => state.switchingMatrixForCannGroup,

    scenarioSwitchingMatrices: state => state.scenarioSwitchingMatrices,

    switchingStatus: state => state.switchingStatus,
  },

  mutations: {
    setSwitchingMatrixForCannGroup(state, switchingMatrix) {
      state.switchingMatrixForCannGroup = switchingMatrix;
    },

    setScenarioSwitchingMatrices(state, switchingMatrices) {
      state.scenarioSwitchingMatrices = switchingMatrices;
    },

    setSelectedSwitchingMatrix(state, switchingMatrix) {
      state.selectedSwitchingMatrix = switchingMatrix;
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setSwitchingCounts(state, counts) {
      state.switchingMatricesUnclusteredCount = counts;
    },
  },

  actions: {
    async fetchCannGroupSwitchingMatrix({ commit }, { params = {} } = {}) {
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/switching-matrices/${params.scenarioId}/${params.cannGroupId}`, {
          params: omit(params, ['scenarioId', 'cannGroupId']),
        })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      commit('setSwitchingMatrixForCannGroup', response.data);
      commit('setLoading', false);
      return response.data;
    },

    async fetchScenarioSwitchingMatrices({ commit }, { where = {} } = {}) {
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/switching-matrices`, {
          params: {
            where,
          },
        })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      commit('setScenarioSwitchingMatrices', response.data);
      commit('setLoading', false);
      return response.data;
    },

    async fetchSwitchingMatricesCount({ commit, rootState }) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, response] = await to(axios.get(`/api/switching-matrices/${scenarioId}`));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      commit('setSwitchingCounts', response.data.data);
      commit('setLoading', false);
    },

    setSelectedScenario({ commit }, selectedSwitchingMatrix) {
      commit('setSelectedSwitchingMatrix', selectedSwitchingMatrix);
    },

    async runSwitchingJob({ commit, dispatch }, { params = {}, commit: commitChanges } = {}) {
      commit('setLoading', true);
      const [err, response] = await to(
        axios.post(`/api/scenarios/${params.scenarioId}/run-switching`, { commit: commitChanges })
      );
      commit('setLoading', false);
      if (err) throw new Error(err);
      handleErrorMessages({ response, dispatch });
      return response;
    },

    async exportSwitching({ rootState }, params) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const config = { params, responseType: 'arraybuffer' };
      const [err, res] = await to(
        axios.get(`/api/scenarios/${scenarioId}/switching-export`, config)
      );
      if (err) throw new Error(err.message);
      return {
        filename: res.headers['content-disposition'].split('filename=')[1],
        buffer: res.data,
      };
    },

    async exportSwitchingCannGroup({ rootState }, { params, cannGroupId }) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const config = { params, responseType: 'arraybuffer' };
      const [err, res] = await to(
        axios.get(`/api/scenarios/${scenarioId}/switching-export/${cannGroupId}`, config)
      );
      if (err) throw new Error(err.message);
      return {
        filename: res.headers['content-disposition'].split('filename=')[1],
        buffer: res.data,
      };
    },

    async updateSwitchingMatrixMetrics({ commit }, params) {
      commit('setLoading', true);
      try {
        await to(
          axios.patch(`/api/switching-matrices`, {
            updates: [
              {
                filter: {
                  scenarioId: params.scenarioId,
                  cannGroupId: params.cannGroupId,
                },
                operator: '$set',
                fields: params.fields,
              },
            ],
          })
        );
        commit('setLoading', false);
      } catch (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
    },

    async runSimulationWithBias({ dispatch }, params) {
      try {
        const scenarioId = params.payload.scenario_id;
        const cannGroupId = params.payload.calculations[0].cannGroupId; // every item in calculations list will have the same cann group id
        const similarityBias = params.payload.calculations[0].similarityBias;
        await jobApi.runFunction('run-switching-modelling', params.payload);

        // Save new simulation bias value to metrics (updating for all clusters at once)
        await dispatch('updateSwitchingMatrixMetrics', {
          scenarioId,
          cannGroupId,
          fields: {
            similarityBias,
          },
        });

        each(params.payload.calculations, ({ clusterId }) => {
          dispatch('fetchCannGroupSwitchingMatrix', {
            params: {
              scenarioId,
              cannGroupId,
              clusterId,
            },
          });
        });

        dispatch('snackbar/showSuccess', i18n.t('messages.switchingSimulationSuccess'), {
          root: true,
        });
      } catch (err) {
        const key = get(err, 'response.data.messageKey', err.message);
        const failedMessage = 'messages.switchingSimulationFailed';
        const message = i18n.t(key, get(err, 'response.data.messageParams', failedMessage));

        dispatch('snackbar/showError', i18n.t(failedMessage), {
          root: true,
        });
        throw new Error(message);
      }
    },

    async runSimulationSetToUnclustered({ dispatch }, params) {
      try {
        const scenarioId = params.payload.scenario_id;
        await jobApi.runFunction('run-switching-modelling', params.payload);

        each(params.payload.calculations, ({ clusterId, cannGroupId }) => {
          dispatch('fetchCannGroupSwitchingMatrix', {
            params: {
              scenarioId,
              cannGroupId,
              clusterId,
            },
          });
        });

        dispatch('snackbar/showSuccess', i18n.t('messages.switchingSimulationSuccess'), {
          root: true,
        });
      } catch (err) {
        const key = get(err, 'response.data.messageKey', err.message);
        const failedMessage = 'messages.switchingSimulationFailed';
        const message = i18n.t(key, get(err, 'response.data.messageParams', failedMessage));

        dispatch('snackbar/showError', i18n.t(failedMessage), {
          root: true,
        });
        throw new Error(message);
      }
    },

    async undoSwitchingModellingDecisions({ commit, dispatch }, payload) {
      commit('setLoading', true);
      const [err, response] = await to(
        axios.patch(`/api/switching-matrices/switching-modelling/undo`, payload)
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      dispatch('snackbar/showSuccess', i18n.t('messages.switchingSimulationSuccess'), {
        root: true,
      });
      return response.data;
    },
  },
};

export default store;
