<template>
  <v-container class="pb-50" fluid>
    <v-layout column>
      <v-flex>
        <v-row class="header-container d-flex flex-column mt-10">
          <div class="d-flex justify-space-between align-center mb-5">
            <h1 class="header">Assortment Tool UI / Kit</h1>
            <img class="ow-logo" src="../../../img/ow-logo-brand.png" alt="ow-logo" />
          </div>
          <v-divider class="mb-5" />
        </v-row>
        <v-row class="header-container intro-container">
          <h1 class="header mb-5">Introduction</h1>
        </v-row>
        <v-row>
          <v-col>
            <p class="paragraph">
              This UI kit is a work in progress and is constantly evolving. Strict rules are set for
              buttons, colours and text sizes, and these should be followed when possible. If there
              are any questions regarding this kit of parts, please contact
              josh.grazier@oliverwyman.com.
            </p>
          </v-col>
        </v-row>
        <v-row class="header-container colours-container mb-5">
          <h1 class="header">Colours</h1>
        </v-row>
        <v-row class="d-flex flex-row align-center">
          <v-col cols="1">
            <div class="colour-block-container d-flex flex-column align-start">
              <h4>Primary</h4>
              <div class="colour-block colour-block--primary" />
            </div>
          </v-col>
          <v-col cols="1">
            <div class="colour-block-container d-flex flex-column align-start">
              <h4>Secondary</h4>
              <div class="colour-block colour-block--secondary" />
            </div>
          </v-col>
          <v-col cols="1">
            <div class="colour-block-container d-flex flex-column align-start">
              <h4>Button Hover</h4>
              <div class="colour-block colour-block--button-hover" />
            </div>
          </v-col>
          <v-col cols="1">
            <div class="colour-block-container d-flex flex-column align-start">
              <h4>Text</h4>
              <div class="colour-block colour-block--text" />
            </div>
          </v-col>
          <v-col cols="1">
            <div class="colour-block-container d-flex flex-column align-start">
              <h4>List Item Background</h4>
              <div class="colour-block colour-block--list-item-bg" />
            </div>
          </v-col>
          <v-col cols="1">
            <div class="colour-block-container d-flex flex-column align-start">
              <h4>TextBox Background</h4>
              <div class="colour-block colour-block--text-box-bg" />
            </div>
          </v-col>
          <v-col cols="1">
            <div class="colour-block-container d-flex flex-column align-start">
              <h4>Positive Action</h4>
              <div class="colour-block colour-block--positive-action" />
            </div>
          </v-col>
          <v-col cols="1">
            <div class="colour-block-container d-flex flex-column align-start">
              <h4>Negative Action</h4>
              <div class="colour-block colour-block--negative-action" />
            </div>
          </v-col>
          <v-col cols="1">
            <div class="colour-block-container d-flex flex-column align-start">
              <h4>Filter Bar Background</h4>
              <div class="colour-block colour-block--filter-bar-bg" />
            </div>
          </v-col>
          <v-col cols="1">
            <div class="colour-block-container d-flex flex-column align-start">
              <h4>Divider Colour</h4>
              <div class="colour-block colour-block--divider-colour" />
            </div>
          </v-col>
          <v-col cols="1">
            <div class="colour-block-container d-flex flex-column align-start">
              <h4>Scenarios</h4>
              <div class="colour-block colour-block--scenarios" />
            </div>
          </v-col>
        </v-row>
        <v-row class="header-container row-container">
          <h1 class="header mb-5">Dropdowns</h1>
        </v-row>
        <v-row>
          <v-col>
            <h3 class="mb-3">Standard Dropdown</h3>
            <rtls-select
              v-model="standardDropdownModel"
              white
              width="240px"
              :items="standardDropdownItems"
              :placeholder="$t('general.select')"
            />
          </v-col>
          <v-col>
            <h3 class="mb-3">Standard Dropdown (grey)</h3>
            <rtls-select
              v-model="standardDropdownModel"
              :items="standardDropdownItems"
              :placeholder="$t('general.select')"
              width="240px"
            />
            <template slot="append">
              <v-icon size="28">expand_more</v-icon>
            </template>
          </v-col>
          <v-col>
            <h3 class="mb-3">Hover State</h3>
          </v-col>
          <v-col>
            <h3 class="mb-3">Clicked State</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3 class="mb-3">Disabled Dropdown</h3>
            <rtls-select
              v-model="standardDropdownModel"
              disabled
              :items="standardDropdownItems"
              :placeholder="$t('general.select')"
              width="240px"
            />
          </v-col>
          <v-col>
            <h3 class="mb-3">Calendar Dropdown</h3>
            <rtls-calendar-picker v-model="date" class="select" />
          </v-col>
          <v-col>
            <h3 class="mb-3">Calendar Hover</h3>
          </v-col>
          <v-col />
        </v-row>
        <v-row class="header-container row-container">
          <h1 class="header mb-5">Menu dropdowns</h1>
        </v-row>
        <v-row>
          <v-col>
            <h3 class="mb-3">Standard menu</h3>
            <div class="d-flex flex-column dropdown-btn-container">
              <main-dropdown-menu :actions="actionsArray" />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v class="col">
            <h3 class="mb-3">Menu with title</h3>
            <main-dropdown-menu title="Export" icon="import_export" :actions="actionsArray" />
          </v>
        </v-row>
        <v-row class="header-container">
          <h1 class="header mb-5 mt-5">Text fields</h1>
        </v-row>
        <v-row>
          <v-col>
            <h3 class="mb-3">Standard Text Field</h3>
            <rtls-text-field v-model="standardTextFieldModelWhite" />
          </v-col>
          <v-col>
            <h3 class="mb-3">Standard Text Field (grey)</h3>
            <rtls-text-field v-model="standardTextFieldModelGrey" grey />
          </v-col>
          <v-col>
            <h3 class="mb-3">Error Field (grey)</h3>
          </v-col>
          <v-col>
            <h3 class="mb-3">Clicked</h3>
          </v-col>
          <v-col>
            <h3 class="mb-3">Disabled</h3>
          </v-col>
          <v-col />
          <v-col />
        </v-row>
        <v-row class="header-container">
          <h1 class="header mb-5 mt-5">Search</h1>
        </v-row>
        <v-row>
          <v-col>
            <h3 class="mb-3">Standard Search</h3>
            <rtls-search
              v-model="standardAssortmentSearch"
              :placeholder="$t('kitOfParts.searchLabel')"
              width="240px"
            />
          </v-col>
          <v-col>
            <h3 class="mb-3">Clicked</h3>
          </v-col>
          <v-col />
          <v-col />
        </v-row>
        <v-row class="header-container">
          <h1 class="header mb-5 mt-5">Toggle</h1>
        </v-row>
        <v-col>
          <rtls-toggle
            v-model="standardToggleModel"
            :left-toggle="toggle.leftToggle"
            :right-toggle="toggle.rightToggle"
          />
        </v-col>
        <v-row class="header-container">
          <h1 class="header mb-5 mt-5">Buttons and Links</h1>
        </v-row>
        <v-col>
          <div class="buttons-container">
            <v-btn primary depressed>
              Primary Icon
              <v-icon right>$import</v-icon>
            </v-btn>
            <v-btn primary depressed>Primary</v-btn>
            <v-btn secondary depressed>Secondary</v-btn>
            <v-btn depressed action @click="save">Save</v-btn>
            <v-btn primary depressed disabled>Inactive</v-btn>
            <v-btn class="text-outline" text link>Remove all</v-btn>
          </div>
        </v-col>
        <v-row class="header-container">
          <h1 class="header mb-5 mt-5">Small buttons/Icons</h1>
        </v-row>
        <v-col>
          <h3 class="mb-3">Tickbox / Radio / Success</h3>
          <div class="d-flex align-center">
            <v-checkbox v-model="checkboxValue" hide-details class="rtls-checkbox" />
            <v-icon size="18">$success</v-icon>
          </div>
        </v-col>
        <v-row class="header-container">
          <h1 class="header mb-5 mt-5">User notes</h1>
        </v-row>
        <v-col cols="3">
          <notes :notes="[]" title="Notes" />
        </v-col>
        <v-row class="header-container">
          <h1 class="header mb-5 mt-5">Canvas components</h1>
        </v-row>
        <v-row>
          <v-col cols="3">
            <h2>Highlight</h2>
            <span>Used to highlight particular divs. Adds borders to sides:</span>
            <div class="highlight-demo">
              <highlighted-area outer-class="highlight-area-custom-class" />
              <span>FOO</span>
            </div>
            <span>You can also highlight only the sides, as seen on list view:</span>
            <div class="highlight-demo list-demo">
              <highlighted-area :outer-style="customHighlightStyle" list-view />
              <span>Bar</span>
            </div>
          </v-col>
          <v-col cols="2">
            <h2>Deltas tag</h2>
            <span>Used to display the optimiser/reference delta points of distribution (PoD)</span>
            <product-delta-tag :delta="-1" type="R" />
            <product-delta-tag :delta="21" type="O" />
            Use `show` to not display contents, eg when canvas get outdated but product has delta:
            <product-delta-tag :delta="-1" type="O" :show="false" />
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      standardToggleModel: 'attribute',
      toggle: {
        leftToggle: {
          value: 'attribute',
          translation: this.$t('switchingBehaviour.attributeBtn'),
        },
        rightToggle: {
          value: 'product',
          translation: this.$t('switchingBehaviour.productBtn'),
        },
      },
      standardDropdownModel: null,
      standardTextFieldModelWhite: 'Text input',
      standardTextFieldModelGrey: 'Text input',
      standardDropdownItems: [],
      standardAssortmentSearch: null,
      checkboxValue: false,
      date: new Date().toISOString(),
      actionsArray: [
        { name: 'Option 1', value: 'option1Value' },
        { name: 'Option 2', value: 'option2Value', disabled: true },
        { name: 'Option 3', value: 'option3Value' },
      ],
      customHighlightStyle: {
        height: '50px',
        width: '50px',
      },
    };
  },

  methods: {
    save() {
      return console.log('saved');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.container {
  background: #fafafa;
  padding: 0;

  &.main-page {
    padding: 0 100px 50px 100px;
    height: 100%;
  }

  .header-container {
    padding: 12px;
  }

  .header {
    margin-bottom: 0;
    font-size: 2.4rem;
  }
  .paragraph {
    font-size: 1.6rem;
  }
  .ow-logo {
    height: 20px;
  }

  .colour-block-container {
    h4 {
      height: 40px;
    }
  }

  .row-container {
    margin-top: 30px;
  }

  .colour-block {
    margin-top: 5px;
    width: 100%;
    height: 50px;

    &--primary {
      background: $assortment-primary-colour;
    }
    &--secondary {
      background: $assortment-secondary-colour;
    }
    &--button-hover {
      background: $assortment-primary-button-hover-colour;
    }
    &--text {
      background: $assortment-text-colour;
    }
    &--list-item-bg {
      background: $assortment-list-item-bg-colour;
    }
    &--text-box-bg {
      background: $assortment-background;
    }
    &--positive-action {
      background: $assortment-positive-action-colour;
    }
    &--negative-action {
      background: $assortment-negative-action-colour;
    }
    &--filter-bar-bg {
      background: $assortment-filter-bar-bg-colour;
    }
    &--divider-colour {
      background: $assortment-divider-colour;
    }
    &--scenarios {
      background: $assortment-scenarios-colour;
    }
  }

  .buttons-container {
    .v-btn {
      margin-right: 15px;
    }
  }
}

.highlight-demo {
  height: 50px;
  width: 50px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.list-demo {
  height: 25px;
}

::v-deep {
  .highlight-area-custom-class {
    height: 50px !important;
    width: 50px !important;
  }
}
</style>
