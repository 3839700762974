var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "w-100 d-flex justify-center align-center",
      class: {
        "pod-delta-increase": _vm.show && _vm.delta > 0,
        "pod-delta-decrease": _vm.show && _vm.delta < 0,
      },
    },
    [
      _vm.show
        ? _c("span", { staticClass: "delta" }, [
            _vm._v(_vm._s(_vm.getDeltaText)),
          ])
        : _c("div", { staticClass: "w-100 h-100" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }