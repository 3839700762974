<template>
  <component
    :is="componentName"
    v-model="isPopupOpen"
    content-class="rtls-dialog rtls-dialog--products"
    :class="{ 'template-screen': fromTemplate }"
    @keydown.esc="closeModal"
    @click:outside="closeModal"
  >
    <template v-slot:activator="{ on }">
      <v-btn :ripple="false" class="workpackage-products__link" text small v-on="on">
        {{ $tkey('scope.viewProductList') }}
        <v-icon size="20">mdi-chevron-right</v-icon>
      </v-btn>
    </template>

    <dialog-card
      :title="$tkey('products.popupHeading')"
      :is-popup="fromTemplate"
      :show-full-headers="!fromTemplate"
      @close="closeModal"
    >
      <v-card class="d-flex flex-column" style="height: 100%">
        <div class="action-panel">
          <div class="action-panel-container">
            <data-upload
              :legends="csvUploadLegends"
              :csv-upload-handler="onCSVUpload"
              :disabled="!hasPermission(userPermissions.canEditWorkpackageScope)"
              @process="onUploadProcess"
            />
            <import-from-planogram v-if="fromTemplate" class="ml-3" />
          </div>
          <div v-if="isOnDetailPage" class="action-panel-container">
            <rtls-search
              v-model="searchString"
              :placeholder="$tkey('stores.labels.search')"
              width="240px"
            />
          </div>
        </div>
        <div class="d-flex flex-column data-panel" style="height: 100%">
          <div class="input-tab-scope flex-grow-0">
            <v-tabs
              :value="currentTabId"
              class="input-tabs"
              :hide-slider="true"
              no-animation
              @change="onTabChange"
            >
              <v-tab
                v-for="item in tabs"
                :key="item.key"
                class="input-tab"
                :class="{ 'bl-0': fromTemplate }"
                @click="handleTabSelect(item.key)"
              >
                {{ item.label }}
              </v-tab>

              <v-row
                v-if="isOnDetailPage"
                no-gutters
                class="d-flex justify-start align-center"
                :class="{ 'ml-3': fromTemplate }"
              >
                <v-col class="add-product-btn-col ml-4">
                  <v-btn
                    v-if="!isAddingNewProduct"
                    :disabled="!hasPermission(userPermissions.canEditWorkpackageScope)"
                    primary
                    @click="showNewProductInput()"
                  >
                    {{ $t('actions.addProduct') }}
                  </v-btn>
                  <span v-if="isAddingNewProduct" class="mr-2">
                    {{ $t('actions.addProduct') }}:
                  </span>
                </v-col>
                <v-col class="d-flex align-center justify-start">
                  <!-- Manually searching products to add one by one -->
                  <assortment-search
                    v-if="isAddingNewProduct"
                    id="productSearch"
                    item-text="productKeyDisplay"
                    :item-label="getRowLabel"
                    :on-search="onProductSearch"
                    async-search
                    search-on-demand
                    :product-results-limit="100"
                    :placeholder="$tkey('products.selectIndividualProduct')"
                    :on-selection="onProductSelection"
                  />
                  <v-btn
                    v-if="isAddingNewProduct"
                    :disabled="!newProductToAddManually"
                    class="ml-2"
                    primary
                    @click="addProductManually()"
                  >
                    {{ $t('actions.add') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-tabs>
          </div>
          <div v-if="isOnDetailPage" class="input-tab-panel flex-grow-1">
            <workpackage-products-table
              ref="detail"
              :products="products"
              :products-for-deletion.sync="productsForDeletion"
              :search-string="searchString"
              :is-popup-open="isPopupOpen"
              @handle-data-changes="handleDataChanges"
              @refresh="init"
              @selected-rows="selectedRows = $event"
            />
          </div>
          <div
            v-if="currentTab === 'hierarchy'"
            class="input-tab-panel flex-grow-1 d-flex flex-column"
            flat
          >
            <workpackage-products-hierarchy
              ref="hierarchy"
              :products="products"
              :hierarchy-depth="hierarchyDepth"
              @handle-product-selection-updates="handleNewProductsFromHierarchy"
              @handle-category-selection-updates="handleCategorySelectionFromHierarchy"
              @refresh="init"
            />
          </div>
        </div>
      </v-card>
      <template v-slot:footer>
        <template v-if="missingProducts.length">
          <div id="failed-upload-notes">
            <span>
              <i18n path="workpackagePage.products.missingProductsInfo" tag="span">
                <template v-slot:button>
                  <a href="#" @click="openMissingProductsPopup">
                    {{ $t('general.here') }}
                  </a>
                </template>
                <template v-slot:totalMissing>{{ missingProducts.length }}</template>
              </i18n>
            </span>
            <popup :is-open="isMissingProductsPopupOpen" @close="closeMissingProductsPopup">
              <span>
                {{
                  $tkey('products.missingProductsList', {
                    totalMissing: missingProducts.length,
                    list: missingProducts.join(', '),
                  })
                }}
              </span>
            </popup>
          </div>
        </template>
        <page-actions
          :show-export="isOnDetailPage"
          :show-save-button="hasPermission(userPermissions.canEditWorkpackageScope)"
          :show-discard="isOnDetailPage"
          :export-options="exportOptions"
          :has-data-changes="hasDataChanges"
          :has-data-errors="hasDataErrors"
          :live-data="productsForDownload"
          :export-service="serviceName"
          @templateDataExport="
            dataExport({ columns: templateExportColumns, mode: 'template-export' })
          "
          @fullDataExport="dataExport({ columns: fullExportColumns, mode: 'full-export' })"
          @save="handleSave(false)"
          @discard="resetData"
        >
          <template v-slot:left-btns>
            <!-- key is used to overcome issue mentioned in the comments https://bitbucket.org/oliverwymantechssg/rtls-assortment/pull-requests/2607 -->
            <v-btn
              v-if="!fromTemplate && isOnDetailPage"
              :key="`delete-selected-products-${selectedRows.length}`"
              depressed
              secondary
              class="outlined-btn"
              :disabled="!selectedRows.length"
              @click="$refs.detail.deleteSelectedProducts(selectedRows)"
            >
              {{ $tkey('products.deleteSelectedProducts') }}
            </v-btn>
          </template>
          <template v-if="fromTemplate" v-slot:right-end-btns>
            <div class="action-btn-container d-flex rtls-border rtls-border--left-thin pl-2">
              <v-btn
                data-id-e2e="btnRunTemplate"
                :disabled="
                  !hasPermission(userPermissions.canEditWorkpackageScope) || hasDataChanges
                "
                :loading="isWorkpackageSetupRunning"
                depressed
                primary
                small
                @click="runTemplateSetup(false)"
              >
                {{ $t(fromTemplate ? 'templatesPage.runWp' : 'workpackagePage.runWp') }}
              </v-btn>
            </div>
          </template>
        </page-actions>
      </template>
    </dialog-card>
    <dependency-tree-feedback-modal
      class="dependency-modal"
      :value="dependencyTreeModalOpen"
      :results="dependencyTreeFeedback"
      page="workpackageSetup"
      @close="closeDependencyTreeModal"
      @commit="commitHandler"
    />
    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="closeUnsavedDataModal"
      @confirm="closeUnsavedDataModal"
    />
  </component>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import {
  cloneDeep,
  sortBy,
  assign,
  size,
  map,
  toNumber,
  get,
  merge,
  isEmpty,
  reduce,
  findKey,
  omit,
} from 'lodash';
import productOriginSourceTypes from '@enums/origin-source-types';
import agGridUtils from '@/js/utils/ag-grid-utils';
import ImportFromPlanogram from '@/js/components/import-from-planogram.vue';
import exportCsv from '@/js/mixins/export-csv';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';

export default {
  components: { ImportFromPlanogram },
  localizationKey: 'workpackagePage',
  mixins: [exportCsv, unsavedDataWarningMixin],

  props: {
    fromTemplate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dependencyTreeModalOpen: false,
      dependencyTreeFeedback: {},
      newProductsToAdd: [],
      productUpdates: {},
      searchString: '',
      currentTab: 'detail',
      currentTabId: 0,
      previousTabId: 0,
      clickedTabId: 0,
      isPopupOpen: false,
      isMissingProductsPopupOpen: false,
      select: null,
      selectItems: [this.$tkey('products.allStores')],
      tabs: [
        { key: 'detail', label: this.$t('scenarioInputPage.productsPopupDetailedTab') },
        { key: 'hierarchy', label: this.$t('scenarioInputPage.productsPopupHierarchyTab') },
      ],
      csvUploadLegends: {
        buttonName: this.$t('actions.manualImport'),
        title: this.$t('workpackagePage.products.addProductsTitle'),
      },
      productsForDeletion: [],
      exportOptions: [
        { name: this.$t('csvExport.exportOptions.templateData'), value: 'templateDataExport' },
        { name: this.$t('csvExport.exportOptions.fullData'), value: 'fullDataExport' },
      ],
      templateExportColumns: ['productKeyDisplay'],

      // Adding products one by one
      isAddingNewProduct: false,
      newProductToAddManually: null,
      productsFoundManually: [],
      hierarchyDepth: 0,
      productCategoriesSelected: [],
      productCategoriesDeSelected: [],
      productCategoriesSelectedProductsCount: 0,
      productCategoriesDeSelectedProductsCount: 0,
      selectedRows: [],
      calledFromSetup: false,
    };
  },

  computed: {
    ...mapState('context', ['allowTabChange']),
    ...mapState('files', ['uploadedFile']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('toolData', ['productHierarchy']),
    ...mapState('assortmentGroups', ['masterAssortmentGroups']),
    ...mapGetters('workpackageProducts', ['workpackageProducts', 'missingProducts']),
    ...mapGetters('assortmentGroups', ['assortmentGroups']),
    ...mapGetters('workpackages', ['isWorkpackageSetupRunning']),
    ...mapGetters('context', ['getCsvExport', 'getDateFormats', 'getClientConfig']),

    workpackage() {
      return this.selectedWorkpackage;
    },

    isTemplate() {
      return !!this.workpackage.isTemplate;
    },

    serviceName() {
      return this.hasProductsInResetEnabled
        ? 'workpackage-products-inReset'
        : 'workpackage-products';
    },

    fullExportColumns() {
      const columns = [
        'productKeyDisplay',
        'itemDescription',
        'originSource',
        'brandDescription',
        'contentAndUnitOfMeasure',
        'supplierName',
        'status',
      ];
      if (this.templatesEnabled && !this.isTemplate) columns.splice(2, 0, 'fromTemplate');
      if (this.templatesEnabled && this.isTemplate) columns.splice(2, 0, 'included');
      if (this.hasProductsInResetEnabled) columns.push('inReset');
      if (this.hasReferenceStoreCountEnabled) columns.push('referenceStoreCount');
      return columns;
    },

    hasProductsInResetEnabled() {
      return (
        get(this.getClientConfig, 'features.productsInResetEnabled', false) && !this.isTemplate
      );
    },

    hasReferenceStoreCountEnabled() {
      return get(this.getClientConfig, 'features.referenceStoreCountEnabled', false);
    },

    productsForDownload() {
      const productsForDeletion = new Set(this.productsForDeletion);
      return this.products.filter(({ productKey }) => !productsForDeletion.has(productKey));
    },

    hasDataChanges() {
      return !!size(this.productUpdates) || this.canSave;
    },

    // similar to hasDataChanges but doesn't check for dirty state on ag-grid,
    // required for data changes warning
    canSave() {
      // needed for <page-actions> component
      const numProductsImported = this.products.length;
      const numProductsDeleted = this.productsForDeletion.length;
      const numNewProducts = this.newProductsToAdd.length;
      const numSelectedCategories = this.productCategoriesSelected.length;
      const numDeSelectedCategories = this.productCategoriesDeSelected.length;

      return (
        (this.uploadedFile && numProductsImported > 0) ||
        numProductsDeleted > 0 ||
        numNewProducts > 0 ||
        numSelectedCategories > 0 ||
        numDeSelectedCategories > 0
      );
    },

    hasDataErrors() {
      // needed for <page-actions> component
      return false;
    },

    assortmentGroupFieldToInclude() {
      const extraColumns = get(this.getClientConfig, 'productColumns', {});
      return findKey(extraColumns, 'fromAssortmentGroup');
    },

    productsAssortmentGroups() {
      // no need to build when not required
      if (
        !size(this.selectedWorkpackage.selectedAssortmentGroupSettings) ||
        !this.assortmentGroupFieldToInclude
      ) {
        return {};
      }
      const workpackageAGs = new Set(
        this.selectedWorkpackage.selectedAssortmentGroupSettings.map(ag => ag.key)
      );
      return reduce(
        this.masterAssortmentGroups,
        (acc, ag) => {
          if (workpackageAGs.has(ag.key)) {
            ag.distinctProductKeys.forEach(productKey => {
              if (!acc[productKey]) acc[productKey] = new Set([]);
              acc[productKey].add(ag[this.assortmentGroupFieldToInclude]);
            });
          }

          return acc;
        },
        {}
      );
    },

    products: {
      get() {
        let products = cloneDeep(this.workpackageProducts);

        const hasCategoryHierarchyAvailable = !isEmpty(this.productHierarchy);
        const hasAssortmentGroupsInformationAvailable = !isEmpty(this.productsAssortmentGroups);

        if (hasCategoryHierarchyAvailable || hasAssortmentGroupsInformationAvailable) {
          products.forEach(p => {
            const updatedContents = {};
            if (hasCategoryHierarchyAvailable) {
              merge(updatedContents, this.productHierarchy[p.productCategoryKey]);
            }
            const productAssortmentGroups = this.productsAssortmentGroups[p.productKey];
            if (hasAssortmentGroupsInformationAvailable && !isEmpty(productAssortmentGroups))
              merge(updatedContents, {
                [this.assortmentGroupFieldToInclude]: Array.from(productAssortmentGroups).join('|'),
              });
            merge(p, updatedContents);
          });
        }
        products = sortBy(products, 'productKey');
        return products;
      },
      set(newProducts) {
        return newProducts;
      },
    },

    isOnDetailPage() {
      return this.currentTab === 'detail';
    },

    componentName() {
      return this.fromTemplate ? 'div' : 'v-dialog';
    },
  },

  watch: {
    isPopupOpen: {
      deep: true,
      handler() {
        this.searchString = '';
        if (!this.isPopupOpen) {
          this.selectTab('detail');
        }
      },
    },
  },

  async created() {
    await this.init();

    const vm = this;
    this.$root.$on('unsaved-data-confirm', () => {
      vm.onTabChange(vm.clickedTabId);
    });
  },

  methods: {
    ...mapMutations('scenarios', ['setSelectedScenario']),
    ...mapMutations('files', ['setUploadedFile']),
    ...mapActions('dependencyTree', ['triggerDependencyTree']),
    ...mapActions('workpackages', ['refreshWorkpackageInformation', 'getProductHierarchyDepth']),
    ...mapActions('workpackageProducts', [
      'fetchWorkpackageProducts',
      'createWorkpackageProducts',
      'processCSV',
      'saveWorkpackageProductSelection',
    ]),
    ...mapActions('assortmentGroups', ['fetchAssortmentGroups']),
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('files', ['uploadCSV']),
    ...mapActions('toolData', ['getProductsByQuery', 'fetchProductCategories']),

    async onTabChange(clickedTab) {
      this.clickedTabId = clickedTab;
      this.currentTabId = clickedTab;
      await this.$nextTick();
      if (!this.allowTabChange) this.currentTabId = this.previousTabId;
      else {
        this.previousTabId = this.currentTabId;
        // Clear data so action buttons are disabled initially after changing tabs
        this.resetHierarchyData();
        this.productUpdates = {};
      }
    },

    async initProducts() {
      await this.fetchWorkpackageProducts({ workpackageId: this.workpackage._id });
      await this.fetchProductsCategoriesInformation();
      const products = cloneDeep(this.workpackageProducts);
      this.products = sortBy(products, 'productKey');
    },

    async init() {
      const { depth } = await this.getProductHierarchyDepth();
      this.hierarchyDepth = depth;
      this.setUploadedFile(null);
      this.productsForDeletion = [];
      this.newProductsToAdd = [];
      this.selectedRows = [];
      // If there are no masterAssortmentGroups, let's get them,
      // otherwise we won't be able to show the assortmentgroups on the products themselves
      if (!size(this.masterAssortmentGroups)) {
        this.fetchAssortmentGroups(this.workpackage.snapshotDate);
      }

      await this.initProducts();
    },

    async resetData() {
      await this.init();
      if (this.$refs.detail) this.$refs.detail.reset();
      this.resetHierarchyData();
    },

    resetHierarchyData() {
      // Update initial selected state on hierarchy view
      this.productCategoriesSelected = [];
      this.productCategoriesDeSelected = [];
      this.productsForDeletion = [];
      this.newProductsToAdd = [];
      this.productCategoriesDeSelectedProductsCount = 0;
      this.productCategoriesSelectedProductsCount = 0;
      if (this.$refs.hierarchy) this.$refs.hierarchy.resetTrackers();
    },

    onProductSearch(query) {
      return this.getProductsByQuery({ query, existingProducts: this.products });
    },

    getRowLabel(product) {
      return `${product.productKeyDisplay} (${product.itemDescription})`;
    },

    onProductSelection(product) {
      this.newProductToAddManually = {
        ...product,
        originSource: productOriginSourceTypes.existingManually,
        fromTemplate: this.isTemplate,
        included: true,
      };
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
      this.calledFromSetup = false;
    },

    selectTab(tabKey) {
      this.currentTab = tabKey;
      this.currentTabId = this.tabs.findIndex(d => d.key === tabKey);
    },

    handleTabSelect(tabKey) {
      this.beforeNavWithUnsavedData(() => {
        this.selectTab(tabKey);
      });
    },

    handleDataChanges(updates) {
      this.productUpdates = updates;
    },

    closeModal() {
      this.beforeCloseModalWithUnsavedData('isPopupOpen');
      this.searchString = '';
    },

    closeMissingProductsPopup() {
      this.isMissingProductsPopupOpen = false;
    },

    openMissingProductsPopup() {
      this.isMissingProductsPopupOpen = true;
    },

    async onCSVUpload(formData) {
      await this.init();
      formData.append('workpackageId', this.workpackage._id);
      return this.uploadCSV({ formData, service: this.serviceName });
    },

    async commitHandler() {
      if (this.calledFromSetup) await this.runTemplateSetup(true);
      else this.handleSave(true);
    },

    async handleSave(commit = false) {
      const results = await this.triggerDependencyTree({
        params: {
          change: 'workpackageSetupModified',
          updates: {},
          commit,
          workpackageId: this.workpackage._id,
        },
      });

      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return false;
      }

      if (commit) this.setSelectedScenario({});

      // If there are no changes we don't need to do anything
      const numProductsImported = this.products.length;
      const numProductsDeleted = this.productsForDeletion.length;
      const numNewProducts = this.newProductsToAdd.length;
      const numSelectedCategories = this.productCategoriesSelected.length;
      const numDeSelectedCategories = this.productCategoriesDeSelected.length;

      // dependency tree
      // import new products from tooldata
      if (
        numNewProducts ||
        numProductsDeleted ||
        numSelectedCategories ||
        numDeSelectedCategories
      ) {
        const updates = map(this.productUpdates, (update, key) => ({
          productKey: toNumber(key),
          ...update,
        }));
        await this.saveWorkpackageProductSelection({
          workpackageId: this.selectedWorkpackage._id,
          productUpdates: updates,
          productKeysToAdd: this.newProductsToAdd,
          productKeysToDelete: this.productsForDeletion,
          categoryKeysToAdd: this.productCategoriesSelected,
          categoryKeysToDelete: this.productCategoriesDeSelected,
        });

        await this.refreshWorkpackageInformation({
          workpackageId: this.selectedWorkpackage._id,
        });
      }

      // Persist changes if products were manually added
      if (
        numProductsImported &&
        !numNewProducts &&
        !numProductsDeleted &&
        !numSelectedCategories &&
        !numDeSelectedCategories
      ) {
        await this.importProducts();
      }

      // Construct the toast message for the user.
      const importedMessage =
        this.uploadedFile && numProductsImported
          ? this.$t('workpackagePage.products.productsImported', [numProductsImported])
          : '';
      const addedMessage =
        !this.uploadedFile && numNewProducts
          ? this.$t('workpackagePage.products.productsAdded', [numNewProducts])
          : '';
      const deletedMessage = numProductsDeleted
        ? this.$t('workpackagePage.products.productsDeleted', [numProductsDeleted])
        : '';
      const addedCategoriesMessage = numSelectedCategories
        ? this.$t('workpackagePage.products.categoriesAdded', [
            this.productCategoriesSelectedProductsCount,
          ])
        : '';
      const deletedCategoriesMessage = numDeSelectedCategories
        ? this.$t('workpackagePage.products.categoriesDeleted', [
            this.productCategoriesDeSelectedProductsCount,
          ])
        : '';

      if (
        importedMessage ||
        addedMessage ||
        deletedMessage ||
        addedCategoriesMessage ||
        deletedCategoriesMessage
      ) {
        this.showSnackbar({
          color: 'green',
          content: `${importedMessage} ${addedMessage} ${deletedMessage} ${addedCategoriesMessage} ${deletedCategoriesMessage}`,
        });
      }
      this.resetData();
      return true;
    },

    handleNewProductsFromHierarchy(data) {
      const { selectedKeys, deselectedKeys } = data;
      // Override with selections from hierarchy view as we cannot stack changes there
      this.newProductsToAdd = selectedKeys || [];
      this.productsForDeletion = deselectedKeys || [];
    },

    handleCategorySelectionFromHierarchy(data) {
      const {
        selectedCategories,
        deSelectedCategories,
        selectedCategoriesProductsCount,
        deSelectedCategoriesProductsCount,
      } = data;
      this.productCategoriesSelected = [...selectedCategories];
      this.productCategoriesDeSelected = [...deSelectedCategories];
      this.productCategoriesSelectedProductsCount = selectedCategoriesProductsCount;
      this.productCategoriesDeSelectedProductsCount = deSelectedCategoriesProductsCount;
    },

    importProducts() {
      // Will save the products that were uploaded by the user
      const productsForDeletion = new Set(this.productsForDeletion);
      const productCategoriesDeSelected = new Set(this.productCategoriesDeSelected);

      const products = this.products.reduce((acc, p) => {
        if (
          !productsForDeletion.has(p.productKey) &&
          !productCategoriesDeSelected.has(p.productCategoryKey)
        ) {
          // Ensure product contains source fields
          const sourceData = {
            ...(!p.originSource && { originSource: productOriginSourceTypes.existingManually }),
            fromTemplate: this.isTemplate || !!p.fromTemplate,
          };
          // remove fields generated from temporary data
          p = omit(p, [
            `productCategory${this.hierarchyDepth}Description`,
            this.assortmentGroupFieldToInclude,
          ]);
          acc.push(assign(p, sourceData));
        }
        return acc;
      }, []);
      if (!products.length) return;
      return this.createWorkpackageProducts({
        workpackageId: this.workpackage._id,
        products,
        upsert: true,
      });
    },

    processCellCallback(params) {
      // Being called for each cell
      return agGridUtils.utils.processCellForExport(params);
    },

    dataExport(options) {
      const { columns, mode } = options;
      const params = {
        columnSeparator: this.getCsvExport.columnSeparator,
        suppressQuotes: this.getCsvExport.suppressQuotes,
        fileName: this.getFileName({
          serviceName: this.serviceName,
          workpackageName: `${this.workpackage.name}-${mode}`,
          fileNameDateFormat: this.getDateFormats.csvFileName,
        }),
        processCellCallback: this.processCellCallback,
      };
      if (columns) params.columnKeys = columns;
      this.$refs.detail.csvExport(params);
    },

    showNewProductInput() {
      this.isAddingNewProduct = true;
    },

    addProductManually() {
      this.products.push(this.newProductToAddManually);
      // add new row to products table
      this.$refs.detail.addRow([this.newProductToAddManually]);

      // Push new product key to be able to add several products manually before save
      this.newProductsToAdd.push(this.newProductToAddManually.productKey);

      this.isAddingNewProduct = false;
      this.newProductToAddManually = null;
    },

    async runTemplateSetup(commit = false) {
      this.calledFromSetup = true;
      const shouldProceed = await this.handleSave(commit);
      if (shouldProceed) this.globalEmit('run-template-setup');
    },

    async fetchProductsCategoriesInformation() {
      const pick = ['productCategoryKey', `productCategory${this.hierarchyDepth}Description`];
      await this.fetchProductCategories({ pick });
    },

    async onUploadProcess({ fileId, mappings, delimiter }) {
      await this.processCSV({ fileId, mappings, delimiter });
      await this.fetchProductsCategoriesInformation();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .actions-row .v-btn .v-icon {
    color: $sub-navigation-link !important;
  }

  .add-product-btn-col {
    max-width: fit-content;
  }

  .input-tab-scope {
    .v-tabs-bar {
      height: 40px;
      overflow: visible;
    }
  }
}

.workpackage-products__link {
  font-size: 1.2rem;
  font-weight: bold;
  color: $assortment-primary-colour;
  padding: 0 !important;
  &::before,
  &::after {
    display: none !important;
  }

  ::v-deep {
    .v-icon {
      color: $assortment-primary-colour;
      transform: translate(-2px, 2px);
    }
  }
}

.dialog-card__footer {
  .actions-row {
    flex: auto;
  }
}

.input-tab-scope {
  .v-tabs-bar__content :first-child {
    margin-left: 0px;
  }
  .input-tab {
    padding: 0 10px;
    justify-content: inherit;
    text-align: inherit;
    text-transform: none;
    min-width: 200px;
    border: 0.3px solid #d3dee6;
    border-left: 3px solid #d3dee7;
    border-bottom: none;
    font-size: 13px;
    font-weight: 900;
  }
  .input-tabs.theme--light.v-tabs > .v-tabs-bar {
    background-color: 1;
  }
  .v-tab {
    background-color: $assortment-unselected-tab-colour;
  }
  .v-tab--active {
    background-color: $assortment-active-tab-colour;
  }
  .v-tab.v-tab {
    color: $assortment-text-colour !important;
  }
  .v-tabs-slider {
    background-color: #ffffff;
  }

  .v-slide-group__wrapper {
    height: 40px;
  }
}

.input-tab-panel {
  border-left: 3px solid #d3dee7 !important;
  .v-card__text {
    font-size: 13px;
  }
  &.v-card:not(.v-sheet--tile) {
    border-radius: unset;
  }
}

#failed-upload-notes {
  padding: 5px;
}
</style>
