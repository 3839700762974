import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.fullScreenExpanded
    ? _c(
        VRow,
        { staticClass: "filter-bar mb-3 flex-grow-0" },
        [
          _c(
            VCol,
            {
              staticClass: "py-2 px-0",
              attrs: { cols: _vm.hasHighlightProductEnabled ? 4 : 6 },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex assortment-filter" },
                [
                  _c("span", { staticClass: "assortment-text mx-2" }, [
                    _vm._v(_vm._s(_vm.$tkey("assortment")) + ":"),
                  ]),
                  _vm._v(" "),
                  _c(VSelect, {
                    staticClass: "rtls-select rtls-select--white",
                    attrs: {
                      value: _vm.selectedCanvas,
                      items: _vm.canvases,
                      "item-text": _vm.getAssortmentOptionText,
                      "item-value": "_id",
                      "hide-details": "",
                      white: "",
                      "menu-props": { "z-index": 200 },
                    },
                    on: { change: _vm.changeCanvas },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass: "d-flex py-2 px-0 justify-end",
              attrs: { cols: _vm.hasHighlightProductEnabled ? 8 : 6 },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex assortment-filter justify-end" },
                [
                  _vm.hasCanvasFilterLegendEnabled
                    ? _c(
                        "div",
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "mr-2",
                              on: {
                                click: function ($event) {
                                  return _vm.setShowFilterLegend(
                                    !_vm.showFilterLegend
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s("mdi-information-outline") +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasHighlightProductEnabled
                    ? _c(
                        "span",
                        { key: "", staticClass: "assortment-text mx-2" },
                        [_vm._v(_vm._s(_vm.$tkey("highlight")) + ":")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasHighlightProductEnabled
                    ? _c(
                        "div",
                        [
                          _c("scenario-assortments-filter", {
                            attrs: { mode: "highlight" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "assortment-text mx-2" }, [
                    _vm._v(_vm._s(_vm.$tkey("filter")) + ":"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mr-2" },
                    [
                      _c("scenario-assortments-filter", {
                        attrs: { mode: "filter" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("assortment-search", {
                    attrs: {
                      id: "canvasSearch",
                      "item-text": "productKeyDisplay",
                      items: _vm.getFilteredProducts.products,
                      "item-label": _vm.getRowLabel,
                      "on-search": _vm.onSearch,
                      placeholder: _vm.$tkey("exampleSearch"),
                      "on-selection": _vm.onSelection,
                      "clear-on-selection": "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex align-center mr-2" },
                [
                  _c("scenario-assortments-controls", {
                    attrs: { disabled: _vm.isEditingDisabled },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }