<template>
  <div class="optimiser-settings">
    <v-form
      v-model="valid"
      class="optimiser-settings__form"
      @submit.prevent="saveOptimiserSettings"
    >
      <v-col
        class="d-flex flex-row align-center justify-start pl-0 pt-2 pb-2"
        :class="[hasCustomerSegmentsEnabled ? 'col-5' : 'col-6']"
      >
        <span class="optimiser-settings__label">{{ $tkey('optimiseAccording') }}</span>

        <!-- Optimize according to tooltip -->
        <assortment-tooltip
          :title="$t('tooltips.optimiseAccordingTo.title')"
          :tooltip-sections="optimiseAccordingToTooltipSections"
        />
        <rtls-select
          :disabled="validatingOptimiseOptions || readOnly"
          :value="optimiseAccordingTo"
          :items="sizeSelectItems"
          :error-messages="optimiseAccordingToErrorMessage"
          :placeholder="$t('sizeTypes.noOption')"
          :append-outer-icon="sizeTypeIconMap[optimiseAccordingTo] || '$empty-cube'"
          width="250px"
          @change="selectSetting"
        />
      </v-col>

      <v-col
        class="d-flex flex-row align-center justify-space-between pt-2 pb-2"
        :class="{ 'col-5': !hasCustomerSegmentsEnabled }"
      >
        <div class="d-flex align-center">
          <span class="optimiser-settings__label">{{ $tkey('categoryStrategy') }}</span>
          <!-- Category strategy tooltip -->
          <assortment-tooltip
            :title="$t('tooltips.categoryStrategy.title')"
            :tooltip-sections="categoryStrategyTooltipSections"
          />
          <span class="ml-5" />
        </div>

        <div class="d-flex" :class="[hasCustomerSegmentsEnabled ? 'flex-column' : 'align-center']">
          <span class="optimiser-settings__label" :class="{ 'mb-1': hasCustomerSegmentsEnabled }">
            {{ $tkey('unitVolume') }}
          </span>
          <rtls-text-field
            v-model.number="unitVolume"
            :disabled="readOnly"
            grey
            class="optimiser-settings__input"
            single-line
            :rules="rules"
          />
        </div>

        <div class="d-flex" :class="[hasCustomerSegmentsEnabled ? 'flex-column' : 'align-center']">
          <span class="optimiser-settings__label" :class="{ 'mb-1': hasCustomerSegmentsEnabled }">
            {{ $tkey('sales') }}
          </span>
          <rtls-text-field
            v-model.number="sales"
            :disabled="readOnly"
            grey
            class="optimiser-settings__input"
            single-line
            :rules="rules"
          />
        </div>

        <div class="d-flex" :class="[hasCustomerSegmentsEnabled ? 'flex-column' : 'align-center']">
          <span class="optimiser-settings__label" :class="{ 'mb-1': hasCustomerSegmentsEnabled }">
            {{ $tkey('margin') }}
          </span>
          <rtls-text-field
            v-model.number="margin"
            :disabled="readOnly"
            grey
            class="optimiser-settings__input"
            single-line
            :rules="rules"
          />

          <assortment-tooltip
            v-if="usesMarginWithoutFunding"
            :title="$t('general.warning')"
            :tooltip-sections="margingWithoutFundingSections"
            :width="500"
          />
        </div>

        <div v-if="hasCustomerSegmentsEnabled" class="d-flex align-center">
          <div class="d-flex flex-column mr-4">
            <span class="optimiser-settings__label mb-1">{{ $tkey('customerSegment') }}</span>
            <rtls-select
              :disabled="readOnly"
              :value="customerSegmentKey"
              :items="customerSegmentsOptions"
              width="200px"
              @change="selectCustomerSegment"
            />
          </div>

          <div class="d-flex flex-column">
            <span class="optimiser-settings__label mb-1">{{ $tkey('maximumCap') }}</span>
            <div class="d-flex">
              <v-text-field
                v-model.number="maxCap"
                :disabled="readOnly || !customerSegmentKey"
                hide-details
                class="optimiser-settings__input rtls-text-field rtls-text-field--grey align-center"
                single-line
              >
                <template slot="append-outer">
                  <span class="d-inline-block">%</span>
                </template>
              </v-text-field>
              <error-triangle class="ml-1 mr-1" :errors="errors" />
            </div>
          </div>
        </div>
      </v-col>
      <v-col class="d-flex flex-row align-center justify-end pr-0 pt-2 pb-2 col-1">
        <v-btn
          data-id-e2e="btnSaveOptimiserSetting"
          primary
          :disabled="isSaveDisabled"
          type="submit"
        >
          {{ $t('actions.save') }}
        </v-btn>
      </v-col>
    </v-form>
  </div>
</template>

<script>
import { isEqual, get, every, includes } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { OPTIMISER_SETTINGS } from '@enums/scenario-assortments';
import SIZE_OPTIONS from '@enums/size-types';
import { scenarioOptimiserTooltipOptionsMixin } from '@/js/mixins/tooltip-options';
import inputValidationMixin from '@/js/mixins/input-validations';
import customerSegmentationMixin from '@/js/mixins/customer-segmentation';

// Maximum value for the maxCap input field
const MAXIMUM_CAP = 100;

export default {
  localizationKey: 'scenarioAssortmentPanel.form',

  mixins: [inputValidationMixin, scenarioOptimiserTooltipOptionsMixin, customerSegmentationMixin],

  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      valid: true,
      originalValues: null,
      [OPTIMISER_SETTINGS.optimiseAccordingTo]: null,
      [OPTIMISER_SETTINGS.unitVolume]: null,
      [OPTIMISER_SETTINGS.bulkVolume]: null,
      [OPTIMISER_SETTINGS.sales]: null,
      [OPTIMISER_SETTINGS.margin]: null,
      [OPTIMISER_SETTINGS.customerSegmentKey]: null,
      [OPTIMISER_SETTINGS.maxCap]: null,
      rules: [this.isInteger, this.required],
      optimiseAccordingToErrorMessage: [],
      validatingOptimiseOptions: false,
      sizeTypeIconMap: {
        [SIZE_OPTIONS.linearSpace]: '$linear-space',
        [SIZE_OPTIONS.cubicSpace]: '$cubic-space',
        [SIZE_OPTIONS.horizontalSpace]: '$horizontal-space',
        [SIZE_OPTIONS.frontalSpace]: '$frontal-space',
        [SIZE_OPTIONS.productCount]: '$empty-cube',
      },
      customerSegments: [],
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
    ...mapGetters('context', ['getClientConfig']),

    sizeSelectItems() {
      return Object.keys(SIZE_OPTIONS).map(key => ({
        value: SIZE_OPTIONS[key],
        text: this.$t(`sizeTypes.${key}`),
      }));
    },

    customerSegmentsOptions() {
      const options = this.getCustomerSegmentsFlat().map(
        ({
          customerSegmentSchemeName,
          customerSegmentScheme,
          customerSegmentName,
          customerSegment,
        }) => ({
          text: `${customerSegmentSchemeName} - ${customerSegmentName}`,
          value: `${customerSegmentScheme}-${customerSegment}`,
        })
      );
      return [{ value: null, text: '' }, ...options];
    },

    hasDataChanges() {
      const currentValues = this.getValues();
      return !isEqual(this.originalValues, currentValues);
    },

    usesMarginWithoutFunding() {
      return get(this.getClientConfig, 'features.marginWithoutSalesEnabled', false);
    },

    hasCustomerSegmentsEnabled() {
      return get(this.getClientConfig, 'features.customerSegmentsEnabled', false);
    },

    errors() {
      const maxCap = this[OPTIMISER_SETTINGS.maxCap];
      const errors = {
        [this.$t('validationErrors.numberIsTooBig', [MAXIMUM_CAP])]: maxCap <= MAXIMUM_CAP,
        [this.$t('validationErrors.numberIsLessThan', [0])]: maxCap > 0,
        [this.$t('validationErrors.integer')]: Number.isInteger(maxCap),
      };
      // maxCap input is disabled if a customerSegmentKey is not selected
      // No error messgages are required when the input is disabled
      return this[OPTIMISER_SETTINGS.customerSegmentKey] ? errors : {};
    },

    isSaveDisabled() {
      return !this.valid || !every(this.errors) || !this.hasDataChanges || this.readOnly;
    },
  },

  watch: {
    // Have to validate the values like this, because we use async validation
    // and async rules are not supported for any v-input. https://github.com/vuetifyjs/vuetify/issues/4231
    optimiseAccordingTo(v) {
      this.isOptimiseAccordingToValid(v);
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions('scenarios', [
      'updateOptimiserSettings',
      'setSelectedScenario',
      'hasValidProductAttributes',
    ]),
    ...mapActions('toolData', ['getCustomerSegments']),

    async init() {
      const {
        sales: defaultSales,
        margin,
        unitVolume: defaultVolume,
      } = this.getClientConfig.scenario.assortmentCanvas.defaultOptimiserWeights;
      const defaultMargin = this.usesMarginWithoutFunding ? 0 : margin;
      this[OPTIMISER_SETTINGS.margin] = defaultMargin;
      this[OPTIMISER_SETTINGS.sales] = defaultSales;
      this[OPTIMISER_SETTINGS.unitVolume] = defaultVolume;

      this.populateFormValues(this.selectedScenario.optimiserSettings);

      this.originalValues = {
        ...this.getValues(),
        [OPTIMISER_SETTINGS.margin]: get(
          this.selectedScenario,
          'optimiserSettings.utilityWeights.margin',
          defaultMargin
        ),
        [OPTIMISER_SETTINGS.sales]: get(
          this.selectedScenario,
          'optimiserSettings.utilityWeights.sales',
          defaultSales
        ),
        [OPTIMISER_SETTINGS.unitVolume]: get(
          this.selectedScenario,
          'optimiserSettings.utilityWeights.unitVolume',
          defaultVolume
        ),
      };

      if (this.hasCustomerSegmentsEnabled) {
        this.customerSegments = await this.getCustomerSegments();
      }
    },

    getValues() {
      return {
        [OPTIMISER_SETTINGS.optimiseAccordingTo]: this[OPTIMISER_SETTINGS.optimiseAccordingTo],
        [OPTIMISER_SETTINGS.unitVolume]: this[OPTIMISER_SETTINGS.unitVolume],
        [OPTIMISER_SETTINGS.bulkVolume]: this[OPTIMISER_SETTINGS.bulkVolume],
        [OPTIMISER_SETTINGS.sales]: this[OPTIMISER_SETTINGS.sales],
        [OPTIMISER_SETTINGS.margin]: this[OPTIMISER_SETTINGS.margin],
        [OPTIMISER_SETTINGS.utilityWeights]: this[OPTIMISER_SETTINGS.utilityWeights],
        ...(this.hasCustomerSegmentsEnabled && {
          [OPTIMISER_SETTINGS.customerSegmentKey]: this[OPTIMISER_SETTINGS.customerSegmentKey],
          [OPTIMISER_SETTINGS.maxCap]: this[OPTIMISER_SETTINGS.maxCap],
        }),
      };
    },

    selectSetting(setting) {
      this.optimiseAccordingToErrorMessage = [];
      this[OPTIMISER_SETTINGS.optimiseAccordingTo] = setting;
    },

    selectCustomerSegment(segment) {
      this[OPTIMISER_SETTINGS.customerSegmentKey] = segment;

      // Populate maxCap if customerSegmentKey has a value and maxCap is null
      if (this[OPTIMISER_SETTINGS.customerSegmentKey] && !this[OPTIMISER_SETTINGS.maxCap]) {
        const defaultMaximumCap = get(this.getClientConfig, 'primaryCustomerFactor.maxCap');
        // Populated with the currently saved setting or fallback to the default from config
        this[OPTIMISER_SETTINGS.maxCap] =
          get(this.selectedScenario, 'optimiserSettings.primaryCustomerFactor.maxCap') ||
          defaultMaximumCap;
      }
      // If customerSegmentKey has been unset, revert maxCap to null
      if (!this[OPTIMISER_SETTINGS.customerSegmentKey]) {
        this[OPTIMISER_SETTINGS.maxCap] = null;
      }
    },

    populateFormValues(optimiserSettings = {}) {
      Object.keys(optimiserSettings).forEach(key => {
        const nestedSettings = [OPTIMISER_SETTINGS.utilityWeights];
        if (this.hasCustomerSegmentsEnabled) {
          nestedSettings.push(OPTIMISER_SETTINGS.primaryCustomerFactor);
        }
        this[OPTIMISER_SETTINGS[key]] = optimiserSettings[OPTIMISER_SETTINGS[key]];

        if (this[OPTIMISER_SETTINGS[key]] && includes(nestedSettings, key)) {
          this.populateFormValues(optimiserSettings[key]);
        }
      });
    },

    async saveOptimiserSettings() {
      const result = await this.updateOptimiserSettings({
        id: this.selectedScenario._id,
        optimiserSettings: {
          [OPTIMISER_SETTINGS.optimiseAccordingTo]: this[OPTIMISER_SETTINGS.optimiseAccordingTo],
          [OPTIMISER_SETTINGS.utilityWeights]: {
            [OPTIMISER_SETTINGS.unitVolume]: this[OPTIMISER_SETTINGS.unitVolume],
            [OPTIMISER_SETTINGS.bulkVolume]:
              this[OPTIMISER_SETTINGS.bulkVolume] === ''
                ? null
                : this[OPTIMISER_SETTINGS.bulkVolume],
            [OPTIMISER_SETTINGS.sales]: this[OPTIMISER_SETTINGS.sales],
            [OPTIMISER_SETTINGS.margin]: this[OPTIMISER_SETTINGS.margin],
          },
          [OPTIMISER_SETTINGS.primaryCustomerFactor]: this[OPTIMISER_SETTINGS.customerSegmentKey]
            ? {
                [OPTIMISER_SETTINGS.customerSegmentKey]: this[
                  OPTIMISER_SETTINGS.customerSegmentKey
                ],
                [OPTIMISER_SETTINGS.maxCap]: this[OPTIMISER_SETTINGS.maxCap],
              }
            : null,
        },
      });
      if (result.errors) return;
      if (result.data) {
        this.setSelectedScenario(result.data);
        this.init();
      }
    },

    async isOptimiseAccordingToValid(value) {
      if (!value) {
        this.optimiseAccordingToErrorMessage.push(this.$t('validationErrors.required'));
        return;
      }

      // only check if there's a value different from the saved one and not productCount
      if (
        (!this.selectedScenario.optimiserSettings && value !== SIZE_OPTIONS.productCount) ||
        (value !== this.selectedScenario.optimiserSettings.optimiseAccordingTo &&
          value !== SIZE_OPTIONS.productCount)
      ) {
        const key = Object.keys(SIZE_OPTIONS).find(k => SIZE_OPTIONS[k] === value);
        this.validatingOptimiseOptions = true;
        const { data } = await this.hasValidProductAttributes({
          scenarioId: this.selectedScenario._id,
          optimiseAccordingTo: key,
        });
        this.validatingOptimiseOptions = false;
        if (!data) {
          this.optimiseAccordingToErrorMessage.push(this.$t(`validationErrors.sizeType.${key}`));
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.optimiser-settings {
  width: 100%;
  padding: 0px;

  &__form {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__input {
    width: 80px;
  }

  &__select {
    width: 160px;
  }

  &__label {
    color: $assortment-font-colour;
    display: inline-block;
    margin-right: 5px;
  }

  .v-input__append-outer {
    margin-left: 5px;
  }
}
</style>
