import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "spacebreak-entry",
      class: _vm.spacebreakEntryClass,
      style: { height: _vm.height + "px" },
      on: { click: _vm.selectSpacebreak },
    },
    [
      _c(
        "div",
        { staticClass: "mb-2 mt-1" },
        [
          _c(
            VLayout,
            { staticClass: "d-flex justify-space-between align-center" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "spacebreak-entry__label spacebreak-entry__heading",
                  attrs: { title: _vm.spacebreak.name },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.spacebreak.name) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "actions__container d-flex align-center" },
                [
                  _c(
                    VAvatar,
                    {
                      class: {
                        "spacebreak-entry__count-badge": !_vm.cogWarning,
                        "spacebreak-entry__count-badge--warning":
                          _vm.cogWarning,
                      },
                      attrs: {
                        size: "14",
                        "data-dd-action-name":
                          "ToggleSpacebreakSettingsPanelBtn",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.toggleSettingsPanel.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "white--text headline" }, [
                        _vm._v(_vm._s(_vm.constraintsLength)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    VIcon,
                    {
                      attrs: {
                        "data-dd-action-name":
                          "ToggleSpacebreakSettingsPanelBtn",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.toggleSettingsPanel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("$settings")]
                  ),
                  _vm._v(" "),
                  _c(
                    VIcon,
                    {
                      staticClass: "actions__button--lock",
                      attrs: {
                        disabled:
                          _vm.isSpacebreakLockToggleDisabled(
                            _vm.spacebreak._id
                          ) || _vm.isEditingDisabled,
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.toggleLock.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.isSpacebreakLocked(_vm.spacebreak._id)
                              ? "mdi-lock-outline"
                              : "mdi-lock-open-variant-outline"
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.excessSpace > 0
        ? _c(VRow, [
            _c("div", {
              staticClass:
                "spacebreak-entry__bar spacebreak-entry__bar--excess-space",
              attrs: { id: "excess-space-bar" },
            }),
          ])
        : _c(VRow, [
            _c("div", {
              staticClass:
                "spacebreak-entry__bar spacebreak-entry__bar--set-aside",
              style: { width: _vm.setAsideWidth },
              attrs: { id: "set-aside-bar" },
            }),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "spacebreak-entry__bar spacebreak-entry__bar--space-used",
              style: { width: _vm.spaceUsedWidth },
              attrs: { id: "space-used-bar" },
            }),
            _vm._v(" "),
            _c("div", {
              staticClass:
                "spacebreak-entry__bar spacebreak-entry__bar--space-available",
              style: { width: _vm.spaceAvailableWidth },
              attrs: { id: "space-available-bar" },
            }),
          ]),
      _vm._v(" "),
      _c(VRow, { staticClass: "mt-1 mb-2 justify-space-between" }, [
        _c(
          "div",
          { staticClass: "spacebreak-entry__label d-flex flex-row mr-1" },
          [
            _c("span", { staticClass: "text" }, [
              _vm._v("\n        " + _vm._s(_vm.totalSpaceLabel) + "\n      "),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.excessSpace > 0
          ? _c(
              "div",
              {
                staticClass:
                  "spacebreak-entry__label d-flex flex-row text-right justify-end",
              },
              [
                _c("span", { staticClass: "text" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.excessSpaceLabel) + "\n      "
                  ),
                ]),
              ]
            )
          : _c(
              "div",
              {
                staticClass:
                  "spacebreak-entry__label d-flex flex-row text-right justify-end",
              },
              [
                _c("span", { staticClass: "text" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.freeSpaceLabel) + "\n      "
                  ),
                ]),
              ]
            ),
      ]),
      _vm._v(" "),
      _c(VRow, [
        _c(
          "table",
          { staticClass: "spacebreak-details" },
          _vm._l(_vm.spacebreakDetails, function (row) {
            return _c("tr", { staticClass: "spacebreak-details__row" }, [
              _c(
                "td",
                [
                  row.iconSrc
                    ? _c("img", {
                        staticClass: "spacebreak-details__icon",
                        attrs: { src: row.iconSrc },
                      })
                    : row.iconName
                    ? _c(
                        VIcon,
                        { staticClass: "spacebreak-details__icon" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(row.iconName) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("td", { staticClass: "spacebreak-details__label" }, [
                _vm._v("\n          " + _vm._s(`${row.label}:`) + "\n        "),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "spacebreak-details__count" }, [
                _c(
                  "span",
                  {
                    staticClass: "px-1",
                    class: {
                      valueError: row.highlightError && row.count > row.total,
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          `${_vm.formatNumber({
                            number: row.count,
                            format: row.countFormatter || "integer",
                          })}`
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "spacebreak-details__total" }, [
                row.total
                  ? _c("span", { staticClass: "px-1 d-inline-block" }, [
                      _c("span", { staticClass: "pr-1" }, [_vm._v("/")]),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.formatNumber({
                              number: row.total,
                              format: row.totalFormatter || "integer",
                            })
                          ) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ])
          }),
          0
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }