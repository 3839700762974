<template>
  <v-snackbar
    v-model="show"
    :timeout="-1"
    class="connection-snackbar"
    :class="snackbarOptions.theme"
    :color="snackbarOptions.colour"
    bottom
    left
    text
  >
    <div class="notification-wrapper d-flex">
      <v-icon class="mr-2">mdi-lan-disconnect</v-icon>
      {{ $t('general.liveStatusUpdatesDisconnect') }}
    </div>
  </v-snackbar>
</template>

<script>
import { get } from 'lodash';

// This component is intended to be used to display a warning to the user when the tool loses connection
// to the server and is not going to be receiving live events to auto update the screen
export default {
  data() {
    return {
      show: false,
    };
  },

  computed: {
    snackbarOptions() {
      return get(this.getClientConfig, 'snackbars.connection', {});
    },
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'events/setEventStreamConnectionProblemStatus') {
        this.show = state.events.eventStreamConnectionHasProblem;
      }
    });
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.notification-wrapper {
  word-wrap: break-word;
}

.connection-snackbar {
  &.dark {
    color: $assortment-snackbar-dark-colour;
  }

  &.light {
    color: $assortment-snackbar-light-colour;
  }
}

.main-snackbar {
  // must be higher than main-navbar z-index to be displayed
  z-index: 210 !important;
}
</style>
