<template>
  <v-container fluid class="view-reports__container pa-0">
    <v-row class="view-reports__row">
      <v-col cols="6" class="pa-0">
        <div v-if="selectedScenario" class="ag-grid-box d-flex flex-column flex-grow-1">
          <h2 class="view-reports__heading mb-3">{{ $tkey('header') }}</h2>
          <ag-grid-vue
            style="width: 75%; height: 100%;"
            class="ag-theme-custom"
            :column-defs="headers"
            :row-data="reports"
            :grid-options="gridOptions"
            @grid-ready="onGridReady"
          />
        </div>
        <section v-else>
          <h2>{{ $t('errors.scenarioNotFound') }}</h2>
        </section>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters, mapActions } from 'vuex';
import { map, isNil, get } from 'lodash';
import { AgGridVue } from 'ag-grid-vue';
import agGridUtils from '@/js/utils/ag-grid-utils';
import { StoreNamespaces } from '@/js/store/constants';
import { jobStatuses, jobStatusesIconsMappings } from '@enums/jobapi';
import agGridIcon from '@/js/components/ag-grid-cell-renderers/ag-grid-icon.vue';
import agGridCellRendererComponentWrapper from '@/js/components/ag-grid-cell-renderers/ag-grid-cell-renderer-component-wrapper.vue';

export default {
  name: 'ViewReports',
  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    agGridIcon,
    agGridCellRendererComponentWrapper,
  },

  localizationKey: 'viewReportsPage',
  data() {
    return {
      gridApi: null,
      columnApi: null,
      gridOptions: {
        suppressContextMenu: true,
        defaultColDef: {
          filter: true,
          sortable: true,
          comparator: agGridUtils.sortings.naturalSort,
          editable: false,
          menuTabs: ['filterMenuTab'],
          suppressMovable: true,
        },
        rowHeight: 41,
        headerHeight: 40,
      },
      actionColDef: {
        ...agGridUtils.colDefs.action,
        menuTabs: [],
      },
    };
  },

  computed: {
    ...mapState(StoreNamespaces.scenarios, ['selectedScenario']),
    ...mapGetters(StoreNamespaces.simpleSwapRuns, ['scenarioSimpleSwapRuns']),
    ...mapGetters(StoreNamespaces.context, ['getDateFormats']),

    headers() {
      return [
        {
          headerName: this.$tkey('tableHeaders.runDescription'),
          field: 'runDescription',
          cellClass: 'font-weight-bold',
          flex: 1,
        },
        {
          headerName: this.$tkey('tableHeaders.date'),
          field: 'extractDatetime',
          flex: 1,
        },
        {
          headerName: this.$tkey('tableHeaders.maxNumberSwaps'),
          field: 'maxSwaps',
          flex: 1,
        },
        {
          ...this.actionColDef,
          headerName: this.$tkey('tableHeaders.status'),
          field: 'extractStatus',
          width: 75,
          sortable: true,
          comparator: (valueA, valueB) => {
            return valueB.localeCompare(valueA, undefined, { sensitivity: 'base' });
          },
          cellRenderer: 'agGridIcon',
          // Send the name of the icon using valueGetter. This is used in the component as this.params.value
          valueGetter: params => jobStatusesIconsMappings[params.data.status],
        },
        {
          ...this.actionColDef,
          field: 'extractDownload',
          width: 40,
          cellClass: 'justify-center',
          cellClassRules: {
            'v-icon--disabled': params => isNil(params.data.fileId),
          },
          cellRenderer: 'agGridCellRendererComponentWrapper',
          // ValueGetter should return an object with the component name to use and the props it should receive.
          // The agGridCellRendererComponentWrapper will then handle rendering this component and passing these props to it
          valueGetter: params => ({
            component: 'download-link',
            props: {
              url: `/api/file/${params.data.fileId}?type=excel`,
              filename: params.data.fileName,
              visible: !isNil(params.data.fileId),
            },
          }),
        },
      ];
    },

    reports() {
      return map(this.scenarioSimpleSwapRuns, e => {
        const date = get(e, ['jobs', 'simpleSwaps', 'dateTimeComplete']);
        return {
          runDescription: e.runDescription,
          extractDatetime: date ? moment.utc(date).format(this.getDateFormats.longWithTime) : null,
          maxSwaps: e.maxSwaps,
          status: get(e, ['jobs', 'simpleSwaps', 'status'], jobStatuses.failed),
          fileId: e.gridFsExcelFileId,
          fileName: e.runDescription,
        };
      });
    },
  },

  created() {
    this.fetchSimpleSwapRuns();
  },

  methods: {
    ...mapActions(StoreNamespaces.simpleSwapRuns, ['fetchSimpleSwapRuns']),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.view-reports {
  &__container {
    flex: 1;
    height: 100%;
  }

  &__row {
    height: 100%;
  }

  &__heading {
    color: $assortment-table-header-colour;
    font-size: 1.4rem;
  }

  .ag-grid-box {
    height: 100%;
  }
}

::v-deep {
  .ag-theme-custom {
    .ag-root-wrapper {
      background-color: transparent;
    }

    .ag-row {
      background-color: $assortment-table-white-bg-colour;
      border-top: 5px solid $assortment-background;
    }

    .ag-header-row,
    .ag-row {
      color: $assortment-text-colour;
      line-height: 1.5rem;
    }

    .ag-header-cell,
    .ag-cell {
      padding: 5px;
    }
  }
}
</style>
