import { ASSORTMENT_FULL_RESET, ASSORTMENT_SIMPLE_SWAPS } from '@enums/workpackage-type';
import tabUtils from './tabs-utils';

const tabs = [
  // Space and furniture
  {
    routeName: 'furniture-editor',
    step: 'space',
    wpTypes: [ASSORTMENT_FULL_RESET, ASSORTMENT_SIMPLE_SWAPS],
    position: 0,
    translationKey: 'scenarioSpacePage.tab.furnitureEditor',
    statusSection: 'space',
    statusSubSection: 'furniture',
  },
  {
    routeName: 'furniture-mapping',
    step: 'space',
    wpTypes: [ASSORTMENT_FULL_RESET, ASSORTMENT_SIMPLE_SWAPS],
    position: 1,
    translationKey: 'scenarioSpacePage.tab.furnitureMapping',
    statusSection: 'space',
    statusSubSection: 'mapping',
  },
  {
    routeName: 'storeclass-definition',
    step: 'space',
    wpTypes: [ASSORTMENT_FULL_RESET, ASSORTMENT_SIMPLE_SWAPS],
    position: 2,
    translationKey: 'scenarioSpacePage.tab.storeclassDefinition',
    statusSection: 'space',
    statusSubSection: 'storeclass',
  },
  {
    routeName: 'spacebreak-calculator',
    step: 'space',
    wpTypes: [ASSORTMENT_FULL_RESET],
    position: 3,
    translationKey: 'scenarioSpacePage.tab.spacebreakCalculator',
    statusSection: 'space',
    statusSubSection: 'spacebreaks',
  },
  {
    routeName: 'planogram-selection',
    step: 'space',
    wpTypes: [ASSORTMENT_SIMPLE_SWAPS],
    position: 3,
    translationKey: 'scenarioSpacePage.tab.planogramSelection',
    statusSection: 'space',
    statusSubSection: 'planograms',
  },

  // Inputs
  {
    routeName: 'attributes',
    step: 'inputs',
    wpTypes: [ASSORTMENT_FULL_RESET, ASSORTMENT_SIMPLE_SWAPS],
    position: 0,
    translationKey: 'scenarioInputPage.tab.attr',
    statusSection: 'inputs',
    statusSubSection: 'attributeEditor',
  },
  {
    routeName: 'cann-group',
    step: 'inputs',
    wpTypes: [ASSORTMENT_FULL_RESET, ASSORTMENT_SIMPLE_SWAPS],
    position: 1,
    translationKey: 'scenarioInputPage.tab.cann-group',
    statusSection: 'inputs',
    statusSubSection: 'cannGroups',
  },
  {
    routeName: 'variety',
    step: 'inputs',
    wpTypes: [ASSORTMENT_FULL_RESET, ASSORTMENT_SIMPLE_SWAPS],
    position: 2,
    translationKey: 'scenarioInputPage.tab.variety',
    statusSection: 'inputs',
    statusSubSection: 'varietyGroups',
  },
  {
    routeName: 'prepare-swaps',
    step: 'inputs',
    wpTypes: [ASSORTMENT_SIMPLE_SWAPS],
    position: 2,
    translationKey: 'scenarioInputPage.tab.prepareSwaps',
    statusSection: 'inputs',
    statusSubSection: 'prepareSwaps',
  },

  // Measuring
  {
    routeName: 'switching-behaviour',
    step: 'measuring',
    wpTypes: [ASSORTMENT_FULL_RESET],
    position: 0,
    translationKey: 'scenarioMeasuringPage.tab.switchingBehaviour',
    statusSection: 'measuring',
    statusSubSection: 'switching',
  },
  {
    routeName: 'cdt',
    step: 'measuring',
    wpTypes: [ASSORTMENT_FULL_RESET],
    position: 1,
    translationKey: 'scenarioMeasuringPage.tab.cdt',
    statusSection: 'measuring',
    statusSubSection: 'cdt',
  },
  {
    routeName: 'region',
    step: 'measuring',
    wpTypes: [ASSORTMENT_FULL_RESET],
    position: 2,
    translationKey: 'scenarioMeasuringPage.tab.region',
    statusSection: 'measuring',
    statusSubSection: 'region',
  },
  {
    routeName: 'clustering',
    step: 'measuring',
    wpTypes: [ASSORTMENT_FULL_RESET],
    position: 3,
    translationKey: 'scenarioMeasuringPage.tab.clustering',
    statusSection: 'measuring',
    statusSubSection: 'clustering',
    replaceInHistory: true,
  },

  // Modelling
  {
    routeName: 'product-modelling',
    step: 'modelling',
    wpTypes: [ASSORTMENT_FULL_RESET],
    position: 0,
    translationKey: 'scenarioModellingPage.tab.productModelling',
    statusSection: 'modelling',
    statusSubSection: 'productModelling',
  },
  {
    routeName: 'switching-modelling',
    step: 'modelling',
    wpTypes: [ASSORTMENT_FULL_RESET],
    position: 1,
    translationKey: 'scenarioModellingPage.tab.switchingModelling',
    statusSection: 'modelling',
    statusSubSection: 'switchingModelling',
  },

  // Swaps
  {
    routeName: 'products-to-swap',
    step: 'swaps',
    wpTypes: [ASSORTMENT_SIMPLE_SWAPS],
    position: 0,
    translationKey: 'scenarioSwapsPage.tab.productsToSwap',
    statusSection: 'swaps',
    statusSubSection: 'productsToSwap',
  },

  // Extracts
  {
    routeName: 'extracts',
    step: 'extracts',
    wpTypes: [ASSORTMENT_SIMPLE_SWAPS],
    position: 0,
    translationKey: 'scenarioExtractsPage.tab.viewReports',
    statusSection: 'extracts',
    statusSubSection: 'viewReports',
  },

  // Execution
  {
    routeName: 'finalisation',
    step: 'execution',
    wpTypes: [ASSORTMENT_FULL_RESET],
    position: 0,
    translationKey: 'scenarioExecutionPage.tab.finalisation',
    statusSection: 'finalisation',
    hideStatus: true,
  },
  {
    routeName: 'product-store-extract',
    step: 'execution',
    wpTypes: [ASSORTMENT_FULL_RESET],
    position: 0,
    translationKey: 'scenarioExecutionPage.tab.productExtract',
    statusSection: 'extracts',
    statusSubSection: 'product-store-extract',
  },
  {
    routeName: 'jda',
    step: 'execution',
    wpTypes: [ASSORTMENT_FULL_RESET],
    position: 1,
    translationKey: 'scenarioExecutionPage.tab.storeExecutionPlanning',
    statusSection: 'extracts',
    statusSubSection: 'jda',
  },
  {
    routeName: 'furniture',
    step: 'execution',
    wpTypes: [ASSORTMENT_FULL_RESET],
    position: 1,
    translationKey: 'scenarioExecutionPage.tab.furnitureExtract',
    statusSection: 'extracts',
    statusSubSection: 'furniture',
  },
];

export default {
  ...tabUtils.generateTabRouteMap(tabs),
  ALL: tabs,
};
