import { VForm } from 'vuetify/lib/components/VForm';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VForm,
    {
      ref: "form",
      staticClass: "main-expandable-panel panel-row d-flex",
      attrs: { autocomplete: "off" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("div", {
        staticClass: "panel-cell drag-cell flex-grow-0 flex-shrink-0",
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "panel-cell name-description pr-0" },
        [
          _c(
            "div",
            { staticClass: "top-container d-flex" },
            [
              _c("rtls-text-field", {
                key: _vm.nameKey,
                attrs: {
                  disabled: _vm.isEditingDisabled,
                  "data-id-e2e": "workpackageName",
                  "data-value-e2e": _vm.model.name,
                  rules: [
                    _vm.required,
                    (v) =>
                      _vm.isNameUnique(
                        v,
                        _vm.workpackage.name,
                        _vm.wpNames,
                        _vm.$t("entities.workpackage")
                      ),
                  ],
                },
                on: {
                  blur: _vm.saveModel,
                  keyup: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.saveModel.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      return _vm.resetModel("name")
                    },
                  ],
                },
                model: {
                  value: _vm.model.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.model,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "model.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("rtls-text-field", {
            key: _vm.descriptionKey,
            attrs: { disabled: _vm.isEditingDisabled },
            on: {
              blur: _vm.saveModel,
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.saveModel.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _vm.resetModel("description")
                },
              ],
            },
            model: {
              value: _vm.model.description,
              callback: function ($$v) {
                _vm.$set(
                  _vm.model,
                  "description",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "model.description",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("assortment-groups", {
        staticClass: "panel-cell",
        attrs: { groups: _vm.assortmentGroups },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column align-start wp-source-info panel-cell",
        },
        [
          _vm.shouldShowTemplatesInfo
            ? _c("div", { staticClass: "d-flex w-100" }, [
                _c("img", {
                  attrs: {
                    width: "15px",
                    src: _vm.templateIcon,
                    alt: "Template",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "trimmed-text font-weight-bold ml-2" },
                  [_vm._v(_vm._s(_vm.templateName))]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("assortment-type", {
            attrs: {
              disabled: _vm.isTypeDisabled,
              types: _vm.types,
              type: _vm.model.type,
              rules: [_vm.required],
            },
            on: {
              update: function ($event) {
                return _vm.updateWorkpackageType($event, false)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.bundlesEnabled
        ? _c(
            "div",
            {
              staticClass:
                "panel-cell target-lunch d-flex flex-column justify-start",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "target-launch-info d-flex flex-column justify-center align-start",
                },
                [
                  _c("h4", { staticClass: "header" }, [
                    _vm._v(_vm._s(_vm.$t("bundlesPage.goLive"))),
                  ]),
                  _vm._v(" "),
                  _vm.workpackage.bundleId
                    ? _c("span", { staticClass: "date" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm._f("formatDate")(
                                _vm.getMomentObject(
                                  _vm.workpackage.targetLaunchDate
                                ),
                                _vm.getDateFormats.long
                              )
                            ) +
                            "\n      "
                        ),
                      ])
                    : [_vm._v("\n        -\n      ")],
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("status-scope", {
        staticClass: "panel-cell",
        attrs: {
          disabled: _vm.isScopeDisabled,
          date: _vm.model.lastModifiedDate,
          status: _vm.workpackageStatus,
          "is-loading": _vm.isLoadingWorkpackageDetails,
          "workpackage-id": _vm.workpackage._id,
          "has-notes": !!_vm.workpackage.totalNotes,
        },
        on: { toggleScope: _vm.handleScoped },
      }),
      _vm._v(" "),
      _vm.showNotImplemented
        ? _c("users-permissions", { staticClass: "panel-cell" })
        : _vm._e(),
      _vm._v(" "),
      _c("command-bar", { staticClass: "panel-cell workpackage__cell-icons" }, [
        _c("ul", { staticClass: "flex-list pl-0" }, [
          _c(
            "li",
            [
              _vm.showNotImplemented
                ? _c("command-item", {
                    attrs: { icon: "$new-workpackage", disabled: "" },
                    on: { execute: () => {} },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _vm.workpackage.archived
                ? _c("workpackage-option-unarchive", {
                    attrs: {
                      workpackage: _vm.workpackage,
                      entity: _vm.entities.workpackage,
                    },
                  })
                : _c("workpackage-option-archive", {
                    attrs: {
                      workpackage: _vm.workpackage,
                      entity: _vm.entities.workpackage,
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("workpackage-option-copy", {
                attrs: { workpackage: _vm.workpackage },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("workpackage-option-delete", {
                attrs: { workpackage: _vm.workpackage },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "panel-cell expand d-flex align-center justify-center flex-grow-0 flex-shrink-0 align-center",
        },
        [
          _c("main-expand-button", {
            class: { "float-right": "float-right" },
            attrs: {
              disabled: _vm.isExpandDisabled,
              "is-expanded": _vm.isExpanded,
              "is-link": _vm.isLink,
            },
            on: { expand: _vm.handleExpanded },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("dependency-tree-feedback-modal", {
        attrs: {
          value: _vm.dependencyTreeModalOpen,
          results: _vm.dependencyTreeFeedback,
          page: "workpackageSetup",
        },
        on: {
          close: _vm.closeDependencyTreeModal,
          commit: function ($event) {
            return _vm.updateWorkpackageType($event, true)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }