<template>
  <div class="d-flex justify-end">
    <v-btn :ripple="false" icon disabled @click="edit">
      <v-icon :size="24">expand_{{ editingMode ? 'less' : 'more' }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import { cloneDeep, isEqual } from 'lodash';
import { StoreNamespaces } from '../../../store/constants';

export default Vue.extend({
  name: 'cellExpandIconRenderer',
  computed: {
    ...mapState(StoreNamespaces.assortmentCanvas, ['constraintEditing', 'spacebreakSettings']),

    editingMode() {
      // Editing to be finished in AOV3-2216
      return (
        this.constraintEditing.constraint.open &&
        isEqual(
          `${this.params.node.data.spacebreakId}${this.params.data.constraintId}`,
          `${this.constraintEditing.constraint.rowNodeData.spacebreakId}${
            this.constraintEditing.constraint.rowNodeData.constraintId
          }`
        )
      );
    },
  },
  methods: {
    ...mapMutations(StoreNamespaces.assortmentCanvas, [
      'setConstraintEditing',
      'setConstraintRowNodeData',
    ]),
    edit() {
      const rowData = [];
      this.params.api.forEachNode(node => {
        rowData.push(node.data);
      });
      const unSavedRowData = rowData.filter(x => !x.constraintId);
      if (unSavedRowData.length) {
        this.params.api.applyTransaction({ remove: unSavedRowData });
      }
      this.setConstraintEditing({
        rowNodeId: this.params.node.id,
        editingBoolean: true,
      });
      this.params.node.setSelected(true);
      const cloned = cloneDeep(this.params.node.data);

      this.setConstraintRowNodeData(cloned);
      this.params.api.redrawRows({ rowNodes: [this.params.node.data] });
    },
  },
});
</script>

<style lang="scss" scoped>
.v-btn {
  padding-bottom: 0 !important;
}
</style>
