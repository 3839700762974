import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { attrs: { fluid: "" } },
    [
      _c(
        VLayout,
        { attrs: { column: "" } },
        [
          _c("workpackages-template"),
          _vm._v(" "),
          _c("workpackages-archived", {
            attrs: {
              "doc-path": "160-templates",
              entity: _vm.entities.template,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }