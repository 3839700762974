'use strict';

const DataTypes = require('../../../enums/data-types');

const fixedFields = [
  {
    mongoField: 'storeKeyDisplay',
    type: DataTypes.alphanumeric,
    aliases: 'csvAliases.furnitureToStoreMapping.storeKeyDisplay',
    translationKey: 'mongoFields.storeID',
    required: true,
  },
  {
    mongoField: 'furnitureArchetypeSet',
    type: DataTypes.str,
    aliases: 'csvAliases.furnitureToStoreMapping.furnitureArchetypeSet',
    translationKey: 'mongoFields.furnitureCategory1Key',
    required: false,
  },
  {
    mongoField: 'historicFurnitureName',
    type: DataTypes.str,
    aliases: 'csvAliases.furnitureToStoreMapping.historicFurnitureName',
    translationKey: 'mongoFields.historicFAname',
    required: false,
  },
  {
    mongoField: 'overrideFurnitureName',
    type: DataTypes.str,
    aliases: 'csvAliases.furnitureToStoreMapping.overrideFurnitureName',
    translationKey: 'mongoFields.futureFAname',
    required: false,
  },
];

module.exports = {
  fixedFields,
};
