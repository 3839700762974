import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex justify-start align-center reset__container" },
    [
      _c(
        VBtn,
        {
          staticClass: "reset__button",
          class: { "reset__button--hidden": _vm.hideReset },
          attrs: { ripple: false, icon: "", "data-id-e2e": "resetValueButton" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.reset.apply(null, arguments)
            },
          },
        },
        [_c("reset-icon")],
        1
      ),
      _vm._v(" "),
      _c(
        VTooltip,
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    VTextField,
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "reset__input",
                          class: { "reset__input--invalid": _vm.isInvalid },
                          attrs: {
                            disabled: _vm.isSimilarityBiasDisabled,
                            "data-id-e2e": "similarityBiasValueInput",
                            reverse: "",
                            "hide-details": "",
                          },
                          on: {
                            keydown: [
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "left",
                                    37,
                                    $event.key,
                                    ["Left", "ArrowLeft"]
                                  )
                                )
                                  return null
                                if ("button" in $event && $event.button !== 0)
                                  return null
                                $event.stopPropagation()
                              },
                              function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "right",
                                    39,
                                    $event.key,
                                    ["Right", "ArrowRight"]
                                  )
                                )
                                  return null
                                if ("button" in $event && $event.button !== 2)
                                  return null
                                $event.stopPropagation()
                              },
                            ],
                          },
                          model: {
                            value: _vm.similarityBiasFormatted,
                            callback: function ($$v) {
                              _vm.similarityBiasFormatted = $$v
                            },
                            expression: "similarityBiasFormatted",
                          },
                        },
                        "v-text-field",
                        attrs,
                        false
                      ),
                      _vm.isInvalid ? on : ""
                    )
                  ),
                ]
              },
            },
          ]),
        },
        [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.getTooltipText()))])]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "ml-2" }, [_vm._v("%")]),
      _vm._v(" "),
      _c(
        VBtn,
        {
          staticClass: "ml-4 outlined-btn",
          attrs: {
            depressed: "",
            disabled: _vm.cannotRunCalculations,
            loading: _vm.runningCalculationsWithBias,
            secondary: "",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.runCalculations.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("actions.apply")) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }