<template>
  <div class="search-scenario w-100">
    <div v-if="block" class="block-container" data-id-e2e="searchScenario">
      <div class="mb-1">{{ $tkey('scenarioImportDescription') }}</div>
      <v-autocomplete
        ref="autocomplete"
        v-model="selectedWorkpackageId"
        data-id-e2e="searchScenarioWpAutocompleteBlock"
        class="mb-1"
        item-text="name"
        item-value="_id"
        height="30px"
        :items="workpackages"
        :filter="filterItems"
        :placeholder="$tkey('selectWorkpackage')"
        :menu-props="{ auto: true }"
        :search-input.sync="wpSearchInput"
        background-color="white"
        @change="getWorkpackageScenarios"
      />
      <v-row>
        <v-autocomplete
          ref="autocomplete"
          v-model="selectedScenarioId"
          data-id-e2e="searchScenarioScenarioAutocompleteBlock"
          item-text="name"
          item-value="_id"
          height="30px"
          :items="wpScenarios"
          :filter="filterItems"
          :disabled="!selectedWorkpackageId"
          :placeholder="$tkey('selectScenario')"
          :menu-props="{ auto: true }"
          :search-input.sync="scenarioSearchInput"
          :rules="rules"
          background-color="white"
          @change="setSelectedScenarioId"
        />
        <v-btn icon text class="pl-1 pt-2" @click="clearSelections">
          <v-icon size="20" color="primary">mdi-close-circle</v-icon>
        </v-btn>
      </v-row>
    </div>
    <v-row v-if="inline" class="inline-container d-flex align-center justify-space-between">
      <v-col cols="9" class="px-0 py-0">
        <v-row class="d-flex align-center justify-space-between">
          <span class="ml-2">{{ $t('general.filter') }}</span>
          <v-col class="overflow-ellipsis py-1">
            <v-autocomplete
              ref="autocomplete"
              v-model="selectedWorkpackageId"
              data-id-e2e="searchScenarioWpAutocompleteInline"
              item-text="name"
              item-value="_id"
              height="25px"
              :items="workpackages"
              :filter="filterItems"
              :placeholder="$tkey('selectWorkpackage')"
              :menu-props="{ auto: true }"
              :search-input.sync="wpSearchInput"
              background-color="white"
              @change="getWorkpackageScenarios"
            />
          </v-col>
          <v-col class="d-flex align-center px-0 overflow-ellipsis py-1">
            <v-autocomplete
              ref="autocomplete"
              v-model="selectedScenarioId"
              data-id-e2e="searchScenarioScenarioAutocompleteInline"
              item-text="name"
              item-value="_id"
              height="25px"
              :items="wpScenarios"
              :filter="filterItems"
              :disabled="!selectedWorkpackageId"
              :placeholder="$tkey('selectScenario')"
              :menu-props="{ auto: true }"
              :search-input.sync="scenarioSearchInput"
              :rules="rules"
              background-color="white"
              @change="setSelectedScenarioId"
            />
          </v-col>
          <v-col cols="1" class="px-1">
            <v-btn icon text @click="clearSelections">
              <v-icon size="20" color="primary">mdi-close-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3" class="d-flex justify-end">
        <v-btn
          class="ml-2"
          data-id-e2e="searchScenarioViewBtn"
          secondary
          depressed
          :disabled="!selectedScenarioId"
          @click="viewResults"
        >
          {{ $tkey('view') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { cloneDeep, filter, isEmpty, get, merge } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  localizationKey: 'assortmentSearch',

  props: {
    inline: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    block: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    activeScenarioId: {
      type: String,
      required: false,
      default: '',
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    scenariosSearchQuery: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      loading: false,
      search: null,
      hint: '',
      selectedWorkpackageId: null,
      selectedScenarioId: null,
      workpackages: [],
      wpScenarios: [],
      wpSearchInput: null,
      scenarioSearchInput: null,
    };
  },

  computed: {
    ...mapGetters('workpackages', ['activeWorkpackages']),
  },

  async created() {
    await this.fetchWorkpackages();
    this.workpackages = cloneDeep(this.activeWorkpackages);
  },

  methods: {
    ...mapActions('workpackages', ['fetchWorkpackages']),
    ...mapActions('scenarios', ['fetchScenarios']),

    filterItems(item, queryText) {
      if (isEmpty(queryText)) return true;

      const itemName = get(item, 'name', '');
      const query = queryText.toLocaleLowerCase();
      return itemName.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) > -1;
    },

    async getWorkpackageScenarios() {
      this.wpScenarios = [];
      this.selectedScenarioId = null;
      this.$emit('scenario-id-selected', this.selectedScenarioId); // reset selected scenario

      const where = merge(
        {},
        {
          workpackageId: this.selectedWorkpackageId,
        },
        this.scenariosSearchQuery
      );

      const scenarios = await this.fetchScenarios({
        params: { where, pick: ['_id', 'name'] },
        options: { skipCommit: true },
      });
      this.wpScenarios = filter(scenarios, ({ _id }) => _id !== this.activeScenarioId);
    },

    setSelectedScenarioId() {
      this.$emit('scenario-id-selected', this.selectedScenarioId);
    },

    viewResults() {
      this.$emit('view-results', `${this.wpSearchInput} / ${this.scenarioSearchInput}`);
    },

    clearSelections() {
      this.selectedWorkpackageId = null;
      this.selectedScenarioId = null;
      this.wpSearchInput = null;
      this.scenarioSearchInput = null;
      this.setSelectedScenarioId();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.search-scenario {
  .inline-container {
    border-top: $assortment-primary-colour 1px solid;
    background-color: $assortment-filter-bar-bg-colour;
  }
}

::v-deep {
  .v-input__control {
    .v-select__slot {
      margin-left: 5px;
    }
    // hide messages box on autocomplete
    .v-text-field__details {
      display: none;
    }
  }
}
</style>
