<template>
  <div>
    <v-autocomplete
      v-if="params.data.addSisterRow"
      ref="autocomplete"
      v-model="value"
      :disabled="params.disabled()"
      item-text="itemDescription"
      item-value="productKey"
      :items="potentialSisterProducts"
      :filter="filterSisterProducts"
      :placeholder="$tkey('addSisterProductSelectPlaceholder')"
      :no-data-text="$tkey('addSisterProductNoMatchingRecords')"
      :menu-props="{ auto: true }"
      :search-input.sync="searchInput"
      dense
      allow-overflow
      @change="addSisterProduct"
    />

    <p v-else class="test pl-3 pt-4">{{ params.value }}</p>
  </div>
</template>

<script>
import Vue from 'vue';
import { find, merge, get, findLastIndex, isEmpty } from 'lodash';

export default Vue.extend({
  localizationKey: 'productModellingPage',

  data() {
    return {
      potentialSisterProducts: [],
      vm: null,
      value: null,
      searchInput: null,
    };
  },

  created() {
    if (this.params.data.addSisterRow) {
      // Getting a correct reference to the parent VM was getting very messy.
      // This is brittle but very simple and these components are very tightly coupled
      this.vm = this.$parent.$parent.$parent;

      this.potentialSisterProducts = this.getPotentialSisterProductsList();
    }
  },

  methods: {
    async addSisterProduct(productKey) {
      const { parentKey } = this.params.data;
      // rtls-select doesn't support return-object so we have to find the object here
      const newSisterProduct = find(this.vm.existingProducts, { productKey });
      const sisterProductFields = {
        productKey,
        isSelected: true,
        isToolGenerated: false,
      };
      const newSisterRow = merge({}, newSisterProduct, sisterProductFields, {
        parentKey,
        rowKey: `${parentKey}${this.vm.separator}${productKey}`,
      });

      const newSisterProductEntry = {
        [productKey]: sisterProductFields,
      };

      // Get existing diff so we can merge onto it
      const existingSisterProducts = get(
        this,
        `vm.currentStateDiff.${parentKey}.sisterProducts`,
        {}
      );

      // Merge so we can reassign entire sister products object
      const updatedSisterProducts = merge({}, existingSisterProducts, newSisterProductEntry);

      // Update current state in parent so changes persist
      this.vm.updateCurrentState({
        productKey: parentKey,
        field: `sisterProducts`,
        currentValue: updatedSisterProducts,
      });

      // Insert after the last sister product row
      const lastSisterProductRow = findLastIndex(this.vm.productsData, { parentKey });

      // add the rows manually to that array
      this.vm.productsData.splice(lastSisterProductRow, 0, newSisterRow);

      this.vm.gridApi.setRowData(this.vm.productsData);

      // Refresh the list of products so we can't re-select the same item.
      this.potentialSisterProducts = this.getPotentialSisterProductsList();
      const parentRowUpdated = {
        productKey: parentKey,
        sisterProducts: merge(
          {},
          this.vm.productsData[this.params.api.getRowNode(parentKey).rowIndex].sisterProducts,
          updatedSisterProducts
        ),
      };
      this.vm.checkRowForErrors(parentRowUpdated);

      // Clear the selection box
      // Note - the next tick is required due to quirk in vueitfy reseting the value back to the selected one
      this.$nextTick(() => {
        this.searchInput = null;
        this.value = null;
      });
    },

    filterSisterProducts(item, queryText) {
      if (isEmpty(queryText)) return true;

      const productDescription = get(item, 'itemDescription', '');
      const productDisplayKey = get(item, 'productKeyDisplay', '');
      const query = queryText.toLocaleLowerCase();
      return (
        productDisplayKey.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) > -1 ||
        productDescription.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) > -1
      );
    },

    getPotentialSisterProductsList() {
      return this.params.potentialSisterProducts(this.params.data.parent).allProductList;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .v-autocomplete {
    min-width: 220px;
  }
}
</style>
