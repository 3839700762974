<template>
  <div class="mt-2 d-flex flex-column align-center">
    <v-chip small class="copy-chip">{{ $tkey(chipTextTranslationKey) }}</v-chip>
    <div class="mt-3 mb-3 d-flex flex-column" :class="selectBorder">
      <rtls-select
        :value="scenarioId"
        hide-details
        width="220px"
        max-width="220px"
        :items="scenarios"
        item-text="name"
        item-value="_id"
        :placeholder="$tkey('selectScenario')"
        class="mb-3"
        :class="margin"
        grey
        @input="setScenarioId"
      />
      <rtls-select
        :value="clusterId"
        hide-details
        width="220px"
        max-width="220px"
        :items="clusters"
        item-text="clusterName"
        item-value="clusterId"
        :placeholder="$tkey('selectCluster')"
        class="mb-3"
        :class="margin"
        grey
        :disabled="!scenarioId"
        @input="setClusterId"
      />
      <rtls-select
        :value="storeClassId"
        hide-details
        width="220px"
        max-width="220px"
        :items="storeClasses"
        item-text="name"
        item-value="_id"
        :placeholder="$tkey('selectStoreClass')"
        class="mb-3"
        :class="margin"
        grey
        :disabled="!scenarioId || (!clusterId && clusterId !== null)"
        @input="setStoreClassId"
      />
      <rtls-select
        :value="canvasId"
        hide-details
        width="220px"
        max-width="220px"
        :items="canvases"
        :item-text="canvasItemTextKey"
        item-value="_id"
        :placeholder="$tkey(canvasTranslationKey)"
        :class="`${margin} ${canvasSelectStyle}`"
        grey
        :disabled="canvasSelectDisabled"
        @input="setCanvasId"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty, filter, keys, map } from 'lodash';

export default {
  name: 'CopyDecisionsSelects',
  localizationKey: 'assortmentCanvasPage.controls',

  props: {
    scenarios: {
      type: Array,
      required: true,
    },
    clustersMap: {
      type: Object,
      required: true,
    },
    furnitureMap: {
      type: Object,
      required: true,
    },
    canvasesMap: {
      type: Object,
      required: true,
    },
    canvasItemTextKey: {
      type: [String, Function],
      required: true,
    },
    chipTextTranslationKey: {
      type: String,
      required: true,
    },
    canvasTranslationKey: {
      type: String,
      required: true,
    },
    left: {
      type: Boolean,
      required: false,
    },
    right: {
      type: Boolean,
      required: false,
    },
    selectedCanvas: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      scenarioId: '',
      clusterId: '',
      clusters: [],
      storeClassId: '',
      storeClasses: [],
      canvasId: '',
      unclustered: { clusterName: this.$tkey('noCluster'), clusterId: null },
    };
  },

  computed: {
    canvases() {
      return !this.canvasSelectDisabled &&
        this.canvasesMap[this.scenarioId] &&
        this.canvasesMap[this.scenarioId][this.clusterId]
        ? filter(
            this.canvasesMap[this.scenarioId][this.clusterId],
            x => x.storeClassId === this.storeClassId
          )
        : [];
    },
    canvasSelectDisabled() {
      return !this.scenarioId || !this.storeClassId || (!this.clusterId && this.clusterId !== null);
    },
    selectBorder() {
      return this.left ? 'selects-container-left' : 'selects-container-right';
    },
    margin() {
      return this.left ? 'mr-2' : 'ml-2';
    },
    canvasSelectStyle() {
      return this.defaultValuesSet ? 'remove-select-ability' : '';
    },
    defaultValuesSet() {
      return !isEmpty(this.selectedCanvas);
    },
  },

  watch: {
    canvasId: {
      handler() {
        return this.canvasId ? this.$emit('selected', this.canvasId) : this.$emit('unselected', '');
      },
    },
  },

  created() {
    if (this.defaultValuesSet) {
      this.setDefaultValuesToSelectedCanvas();
    }
  },

  methods: {
    setScenarioId(value) {
      if (value === this.scenarioId) return;
      this.clusterId = '';
      this.storeClassId = '';
      this.resetCanvasSelection();
      this.scenarioId = value;
      const allClusters = this.clustersMap[value]
        ? [...this.clustersMap[value], this.unclustered]
        : [this.unclustered];
      // filter to only show clusters that have an associated canvas
      this.clusters = allClusters.filter(x =>
        // String used here to convert null to a string so it can be read from the object key values
        keys(this.canvasesMap[this.scenarioId]).includes(String(x.clusterId))
      );
    },
    setDefaultValuesToSelectedCanvas() {
      const { scenarioId, clusterId, storeClassId, _id } = this.selectedCanvas;
      this.setScenarioId(scenarioId);
      this.setClusterId(clusterId);
      this.storeClassId = storeClassId;
      this.canvasId = _id;
    },
    setStoreClassId(value) {
      if (value === this.storeClassId) return;
      this.resetCanvasSelection();
      this.storeClassId = value;
      if (this.defaultValuesSet) {
        this.canvasId = this.canvases[0]._id;
      } else {
        this.resetCanvasSelection();
      }
    },
    setClusterId(value) {
      if (value === this.clusterId) return;
      this.storeClassId = '';
      this.clusterId = value;
      this.resetCanvasSelection();
      const allStoreClasses = this.furnitureMap[this.scenarioId];
      this.storeClasses = allStoreClasses.filter(x =>
        map(this.canvasesMap[this.scenarioId][this.clusterId], 'storeClassId').includes(x._id)
      );
    },
    setCanvasId(value) {
      if (value === this.canvasId) return;
      this.canvasId = value;
    },
    resetCanvasSelection() {
      this.canvasId = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.selects-container-left {
  border-right: 1px solid $assortment-divider-colour;
}
.selects-container-right {
  border-left: 1px solid $assortment-divider-colour;
}

.copy-chip {
  &.theme--light.v-chip {
    background: $assortment-canvas-copy-chip-colour;

    ::v-deep {
      .v-chip__content {
        font-weight: bold;
      }
    }
  }
}

.remove-select-ability {
  pointer-events: none;
  ::v-deep {
    .v-icon {
      display: none;
    }
    .v-select__slot {
      background: white;
    }
  }
}
</style>
