import axios from 'axios';
import to from 'await-to-js';
import { get, differenceWith, map, size } from 'lodash';
import i18n from '@/js/vue-i18n';

export const name = 'toolData';

const store = {
  namespaced: true,

  state: {
    loading: false,
    productHierarchy: {}, // map of product hierarchy from the lowest level
    stores: [],
    customerSegments: [],
    periodIds: [],
  },

  getters: {
    stores: state => get(state, 'stores', []),
    customerSegments: state => get(state, 'customerSegments', []),
    periodIds: state => get(state, 'periodIds', []),
  },

  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setProductHierarchyNodes(state, productHierarchy) {
      state.productHierarchy = productHierarchy;
    },

    setStores(state, stores) {
      state.stores = stores;
    },

    setCustomerSegments(state, customerSegments) {
      state.customerSegments = customerSegments;
    },

    setPeriodIds(state, periodIds) {
      state.periodIds = periodIds;
    },
  },

  actions: {
    /**
     * Gets all customer segments from tooldata-customer-segments.
     */
    async getCustomerSegments({ commit }) {
      commit('setLoading', true);
      const [err, result] = await to(axios.get('/api/tool-data/customer-segments'));
      commit('setLoading', false);
      if (err || get(result, 'data.errors')) {
        throw new Error(i18n.t('errors.toolData.unableToGetCustomerSegments'));
      }
      return result.data;
    },

    /**
     * Gets all stores from tooldata-stores.
     */
    async getStoreDetails({ commit }, { selectedAssortmentGroupKeys }) {
      commit('setLoading', true);
      const [err, result] = await to(
        axios.post('/api/tool-data/stores', {
          assortmentGroupKeys: selectedAssortmentGroupKeys,
        })
      );
      commit('setLoading', false);
      if (err || get(result, 'data.errors')) {
        throw new Error(
          i18n.t('errors.toolData.unableToGetStores', { selectedAssortmentGroupKeys })
        );
      }
      return result.data;
    },

    async getStores({ commit }) {
      commit('setLoading', true);
      const [err, result] = await to(axios.get('/api/tool-data/stores-all'));
      commit('setLoading', false);
      if (err || get(result, 'data.errors')) {
        throw new Error(i18n.t('errors.toolData.unableToGetStores'));
      }
      commit('setStores', result.data);
    },

    /**
     * Fetches the relative performance metrics for a single product
     * from tooldata-product-metrics by the metric type
     */
    async fetchRelativeProductPerformanceMetrics({ commit }, { productKey, metricType }) {
      const params = { productKey, metricType };

      commit('setLoading', true);
      const [err, result] = await to(
        axios.get('/api/tool-data/products/relative-performance', {
          params,
        })
      );
      commit('setLoading', false);
      if (err || get(result, 'data.errors')) {
        throw new Error(i18n.t('errors.toolData.unableToGetProductMetrics', { data: productKey }));
      }
      return result.data;
    },

    async getProducts({ commit }, { params = {} }) {
      commit('setLoading', true);
      const [err, result] = await to(axios.post('/api/tool-data/products/', params));
      commit('setLoading', false);
      if (err || get(result, 'data.errors')) {
        throw new Error(
          i18n.t('errors.toolData.unableToGetProducts', {
            data: params.productKeys || params.productKeyDisplays,
          })
        );
      }
      return result.data;
    },

    /**
     * Gets all products from tooldata-products by assortment group keys and
     * switchingStartDate / switchingEndDate to retrieve timespan history
     */
    async getProductsByAssortmentGroup(
      { commit },
      { selectedAssortmentGroupKeys, switchingStartDate, switchingEndDate }
    ) {
      commit('setLoading', true);
      const [err, result] = await to(
        axios.post('/api/tool-data/products/assortment-groups', {
          assortmentGroupKeys: selectedAssortmentGroupKeys,
          switchingStartDate,
          switchingEndDate,
        })
      );
      commit('setLoading', false);
      if (err || get(result, 'data.errors')) {
        throw new Error(
          i18n.t('errors.toolData.unableToGetProducts', { data: selectedAssortmentGroupKeys })
        );
      }
      return result.data;
    },

    /**
     * Gets all products from tooldata-products by query (product display key or product name).
     */
    async getProductsByQuery({ commit }, { query, existingProducts }) {
      commit('setLoading', true);
      const [err, result] = await to(axios.get(`/api/tool-data/products/${query}`));
      commit('setLoading', false);
      if (err || get(result, 'data.errors')) {
        throw new Error(i18n.t('errors.toolData.unableToGetProducts', { data: query }));
      }

      // filter out products that are already present in scope
      const availableProducts = differenceWith(
        result.data,
        existingProducts,
        (p, ep) => p.productKey === ep.productKey
      );
      return availableProducts;
    },

    /**
     * Gets all product rows and sub groups from tooldata-rom-products by category.
     */
    async getROMProductDataByCategory({ commit }, { categoryKey, existingProducts = [] }) {
      commit('setLoading', true);
      const [err, romData] = await to(
        axios.post('/api/tool-data/products/rest-of-market', { categoryKey, existingProducts })
      );
      commit('setLoading', false);
      if (err || get(romData, 'data.errors')) {
        throw new Error(i18n.t('errors.toolData.unableToGetROMProducts', { categoryKey }));
      }
      return {
        rowData: get(romData, 'data.rowData', []),
        subGroups: get(romData, 'data.subGroups', {}),
      };
    },

    /**
     * Gets all product attributes' sources from tooldata-attributes.
     */
    async getProductAttributeSources({ commit }) {
      commit('setLoading', true);
      const [err, result] = await to(axios.get('/api/tool-data/product-attributes/sources'));
      commit('setLoading', false);
      if (err || get(result, 'data.errors')) {
        throw new Error(i18n.t('errors.toolData.unableToGetAttributesSources'));
      }
      return result.data;
    },

    /**
     * Gets all product attributes by selected attribute source from tooldata-attributes.
     */
    async getProductAttributesBySource({ commit }, { attributeSource, productKeys }) {
      commit('setLoading', true);
      const [err, result] = await to(
        axios.post('/api/tool-data/product-attributes', { attributeSource, productKeys })
      );
      commit('setLoading', false);
      if (err || get(result, 'data.errors')) {
        throw new Error(
          i18n.t('errors.toolData.unableToGetAttributes', { attributeSource, productKeys })
        );
      }
      return result.data;
    },

    /**
     * Gets lowest product hierarchy for selected category keys
     */
    async fetchProductCategories({ commit, rootState }, { pick, categoryKeys = [] }) {
      commit('setLoading', true);
      const productCategoryKeys = size(categoryKeys)
        ? categoryKeys
        : map(rootState.workpackageProducts.workpackageProducts, 'productCategoryKey');
      if (!size(productCategoryKeys)) return; // nothing to do here
      const [err, result] = await to(
        axios.post('/api/tool-data/products-categories', { productCategoryKeys, pick })
      );
      commit('setLoading', false);
      if (err || get(result, 'data.errors')) {
        throw new Error(i18n.t('errors.toolData.unableToGetProductsHierarchy'));
      }
      commit('setProductHierarchyNodes', result.data);
    },

    /**
     * Gets category data for Rest of Market products
     */
    async fetchROMCategories({ commit }) {
      commit('setLoading', true);
      const [err, result] = await to(
        axios.get('/api/tool-data/products/rest-of-market/categories')
      );
      commit('setLoading', false);
      if (err || get(result, 'data.errors')) {
        throw new Error(i18n.t('errors.toolData.unableToGetROMCategories'));
      }
      return result.data;
    },

    async getPeriodIds({ commit }) {
      commit('setLoading', true);
      const [err, result] = await to(axios.get('/api/tool-data/product-transfer-price-periodIds'));
      commit('setLoading', false);
      if (err || get(result, 'data.errors')) {
        throw new Error(i18n.t('errors.toolData.unableToGetCustomerSegments'));
      }
      commit('setPeriodIds', result.data);
    },
  },
};

export default store;
