import { VAlert } from 'vuetify/lib/components/VAlert';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "heatmap-panel" },
    [
      !_vm.showGrid
        ? _c(VAlert, { attrs: { type: "info", text: "" } }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$t("switchingBehaviour.pleaseSelectAttribute")) +
                "\n  "
            ),
          ])
        : _vm.isLoading
        ? _c("progress-bar")
        : _c("switching-heatmap-grid", {
            attrs: { "grid-data": _vm.gridData },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }