import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "pa-0 ma-0 cdt-col" },
    [
      _c(
        VRow,
        { staticClass: "border-bottom" },
        [
          _c(VCol, { staticClass: "pa-1" }, [
            _c("b", [
              _vm._v(_vm._s(_vm.getAttributeName(_vm.splitAttributeId))),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        _vm._l(_vm.splitValues, function (val) {
          return _c(
            VCol,
            {
              key: val.cdtId,
              staticClass:
                "pa-0 ma-0 border-right align-center justify-center cdt-col",
            },
            [
              _c(VIcon, { staticClass: "box-icon", attrs: { size: "14" } }, [
                _vm._v("add_box"),
              ]),
              _vm._v(" "),
              _c("b", [_vm._v(_vm._s(val.attributeValue))]),
              _vm._v(" "),
              _vm.showNotImplemented
                ? _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("assortmentCanvasesPanel.percentOfSpace", ["x"])
                      )
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                VRow,
                { staticClass: "headers" },
                [
                  _c(VCol, [_vm._v(_vm._s(_vm.$tkey("rank")))]),
                  _vm._v(" "),
                  _c(VCol, [_vm._v(_vm._s(_vm.$tkey("id")))]),
                  _vm._v(" "),
                  _c(VCol, [_vm._v(_vm._s(_vm.$tkey("description")))]),
                  _vm._v(" "),
                  _c(VCol, [
                    _vm._v(_vm._s(_vm.$tkey("combinedUnitOfMeasure"))),
                  ]),
                  _vm._v(" "),
                  _c(VCol, [_vm._v(_vm._s(_vm.$tkey("sales")))]),
                  _vm._v(" "),
                  _c(VCol, [_vm._v(_vm._s(_vm.$tkey("volume")))]),
                  _vm._v(" "),
                  _c(VCol, [_vm._v(_vm._s(_vm.$tkey("margin")))]),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }