import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      style: [_vm.zIndex ? { "z-index": _vm.zIndex } : ""],
      attrs: {
        "content-class": "rtls-dialog rtls-dialog--legend",
        "max-width": "1120",
        "max-height": "740",
        width: _vm.width,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                VIcon,
                _vm._g({ class: _vm.iconClass, attrs: { size: "20" } }, on),
                [_vm._v("$information")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isPopupOpen,
        callback: function ($$v) {
          _vm.isPopupOpen = $$v
        },
        expression: "isPopupOpen",
      },
    },
    [
      _vm._v(" "),
      _c(
        "dialog-card",
        {
          staticClass: "rtls-dialog--legend",
          attrs: { title: _vm.title },
          on: { close: _vm.closePopup },
        },
        [
          _vm._t("default", function () {
            return [
              _c(
                VCard,
                _vm._l(_vm.tooltipSections, function (section, sectionIdx) {
                  return _c(
                    "div",
                    {
                      key: `section-${sectionIdx}`,
                      staticClass:
                        "tooltip-section tooltip-content-text pt-5 pb-5 d-flex flex-column",
                    },
                    [
                      section.type === "icons"
                        ? _c(
                            "div",
                            { staticClass: "d-flex flex-column" },
                            _vm._l(section.tips, function (tip, labelIdx) {
                              return _c(
                                "div",
                                {
                                  key: `label-tip-${labelIdx}`,
                                  staticClass:
                                    "tip-icon-item-wrapper d-flex align-center mr-4 mb-2",
                                },
                                [
                                  _c(VIcon, { staticClass: "mr-2" }, [
                                    _vm._v(_vm._s(tip.icon)),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    domProps: { innerHTML: _vm._s(tip.text) },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _c(
                            "ul",
                            _vm._l(section.tips, function (tip, tipIdx) {
                              return _c(
                                "li",
                                {
                                  key: `tip-${tipIdx}`,
                                  staticClass: "tip-list-item pt-2",
                                },
                                [
                                  tip.nestedTips
                                    ? _c("div", [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(tip.text),
                                          },
                                        }),
                                        _vm._v(" "),
                                        tip.type === "icons"
                                          ? _c(
                                              "div",
                                              { staticClass: "pt-2" },
                                              _vm._l(
                                                tip.nestedTips,
                                                function (
                                                  nestedTip,
                                                  nestedTipIdx
                                                ) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: `tip-${nestedTipIdx}`,
                                                      staticClass:
                                                        "tip-icon-item-wrapper d-flex align-center mr-4 mb-2",
                                                    },
                                                    [
                                                      _c(
                                                        VIcon,
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              nestedTip.icon
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("div", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            nestedTip.text
                                                          ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _c(
                                              "ul",
                                              { staticClass: "ml-4" },
                                              _vm._l(
                                                tip.nestedTips,
                                                function (
                                                  nestedTip,
                                                  nestedTipIdx
                                                ) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: `tip-${nestedTipIdx}`,
                                                      staticClass:
                                                        "tip-list-item nested pt-2",
                                                    },
                                                    [
                                                      _c("div", {
                                                        domProps: {
                                                          innerHTML:
                                                            _vm._s(nestedTip),
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                      ])
                                    : _c("div", {
                                        domProps: { innerHTML: _vm._s(tip) },
                                      }),
                                ]
                              )
                            }),
                            0
                          ),
                    ]
                  )
                }),
                0
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }