<template functional>
  <div class="highlight-box" :class="props.outerClass" :style="props.outerStyle">
    <div
      class="highlight-item highlight-item--top highlight-item--left"
      :class="{ 'highlight-item--list': props.listView }"
    />
    <div
      v-if="!props.listView"
      class="highlight-item highlight-item--bottom highlight-item--left"
    />
    <div
      class="highlight-item highlight-item--top highlight-item--right"
      :class="{ 'highlight-item--list': props.listView }"
    />
    <div
      v-if="!props.listView"
      class="highlight-item highlight-item--bottom highlight-item--right"
    />
  </div>
</template>

<script>
export default {
  props: {
    listView: {
      type: Boolean,
      default: false,
    },
    outerStyle: {
      type: Object,
      default: null,
    },
    outerClass: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.highlight-box {
  left: 0px;
  top: 0px;
  position: absolute;
}

.highlight-item {
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 1px;
  border-color: $product-highlighted-border-colour;
  border-style: solid;
  border-width: 0;

  &--top {
    top: 0;
    border-top-width: 2px;
  }

  &--bottom {
    bottom: 0;
    border-bottom-width: 2px;
  }

  &--right {
    right: 0;
    border-right-width: 2px;
  }

  &--left {
    left: 0;
    border-left-width: 2px;
  }

  &--list {
    width: 3px;
    border-width: 3px;
    top: 0;
    height: 26px;
    border-radius: 0;
  }
}
</style>
