<template>
  <div style="width: 200px" class="assortment-groups d-flex flex-column align-start">
    <h4 style="width: 100%" class="title text-truncate" :title="firstGroup.name">
      {{ firstGroup.name }}
    </h4>
    <h4 v-if="secondGroup" class="title text-truncate" :title="secondGroup.name">
      {{ secondGroup.name }}
    </h4>
    <v-tooltip v-if="moreGroups.length" content-class="rtls-tooltip" bottom>
      <template v-slot:activator="{ on }">
        <span class="tooltip" v-on="on">{{ tooltip }}</span>
      </template>
      <span v-for="(group, index) in groups" :key="index">{{ group.name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      moreThreshold: 2,
    };
  },

  computed: {
    firstGroup() {
      return this.groups.length ? this.groups[0] : {};
    },
    secondGroup() {
      return this.groups.length > 1 ? this.groups[1] : false;
    },
    tooltip() {
      return this.groups.length > this.moreThreshold
        ? `+ ${this.groups.length - this.moreThreshold} ${this.$t(
            'workpackagePage.scope.moreLabel'
          )}`
        : '';
    },
    moreGroups() {
      return this.groups.length ? this.groups.slice(this.moreThreshold) : [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.assortment-groups {
  min-width: 180px;
  width: 180px;
}

.title {
  padding-bottom: 0px;
  font-weight: bold;
  width: 100%;
}

.tooltip {
  color: $assortment-primary-colour;
  font-weight: bold;
  cursor: pointer;
}
</style>
