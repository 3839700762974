import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panel pb-3" },
    [
      _c(
        "draggable",
        {
          staticClass: "drag-container",
          attrs: {
            list: _vm.availableFurnitureIds,
            group: "spacebreakFurniture",
            "ghost-class": "ghost",
            draggable: ".draggable",
            disabled: _vm.isEditingDisabled,
          },
        },
        _vm._l(_vm.availableFurnitureIds, function (furnitureId) {
          return _c(
            VCard,
            {
              key: furnitureId,
              staticClass: "draggable ma-1",
              attrs: { flat: "", primary: "" },
            },
            [
              _c(
                "div",
                { staticClass: "list-item d-flex" },
                [
                  _c("span", { staticClass: "furniture-archetype" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.furnitureMapping[furnitureId]) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    VIcon,
                    {
                      staticClass: "list-icon",
                      attrs: {
                        disabled: _vm.isEditingDisabled,
                        size: "14",
                        color: "primary",
                      },
                    },
                    [_vm._v("\n          $cross-move\n        ")]
                  ),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }