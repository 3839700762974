import permissions from '@enums/user-permissions';

import Bundles from '@/js/pages/bundles/bundles.vue';

const routes = [
  {
    path: '/bundles',
    id: 'bundles',
    name: 'bundles',
    component: Bundles,
    linkText: 'navBar.bundles',
    meta: {
      featureKey: 'bundlesEnabled',
      requiresPermission: permissions.canViewBundles,
      titleExtension: 'bundlesPage.title',
    },
    index: 0,
  },
];

export default routes;
