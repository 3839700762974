<template>
  <v-dialog :value="value" width="400px" @click:outside="closeModal">
    <dialog-card v-if="value" :title="$tkey('createNewClusterTitle')" @close="closeModal">
      <v-container fluid style="max-height: 500px; padding: 0">
        <!-- Clustering scheme creation -->
        <div data-id-e2e="ref-clustering-scheme-creation-wrapper">
          <v-row class="my-2">
            <v-container fluid class="mx-0 px-0">
              <v-row class="my-2">
                <v-checkbox
                  :input-value="isNewClusterSchemeNameSelected"
                  class="rtls-checkbox mx-2"
                  @change="onToggleNewClusterSchemeNameSelected"
                />
                <span class="mr-4">{{ $tkey('clusterSchemeName') }}</span>
                <rtls-text-field
                  v-if="isNewClusterSchemeNameSelected"
                  v-model="newClusterSchemeName"
                  grey
                  class="mr-2 ml-4 my-2"
                  style="width:80%;"
                />
                <span v-if="nameHasError" class="my-4">
                  <i class="material-icons-round error-triangle-icon">warning_amber</i>
                </span>
              </v-row>
              <v-row class="my-2">
                <v-checkbox
                  :input-value="isManualUploadSelected"
                  class="rtls-checkbox mx-2"
                  @change="onToggleManualUploadSelected"
                />
                <span class="mr-4">{{ $tkey('directImport') }}</span>
                <data-upload
                  v-if="isManualUploadSelected"
                  :legends="csvUploadLegends"
                  :csv-upload-handler="onCSVUpload"
                  @process="process"
                />
              </v-row>
              <v-row v-if="isManualUploadSelected">
                {{ /* need data-table for loading state */}}
                <v-data-table
                  v-if="hasCSVUploadedData"
                  id="upload-ref-schemes-table"
                  class="my-2"
                  :loading="loading"
                  :headers="tableHeaders"
                  max-height="500px"
                  dense
                  disable-pagination
                  disable-sort
                  hide-default-footer
                  hide-default-header
                  fixed-header
                >
                  <template v-slot:header>
                    <thead>
                      <tr>
                        <th
                          v-for="header in tableHeaders"
                          :key="`upload-${header.id}`"
                          :class="header.headerClass"
                        >
                          {{ header.text }}
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <template v-slot:body>
                    <tbody>
                      <tr v-for="(clusteringScheme, rowIndex) in getTableRows" :key="rowIndex">
                        <td
                          v-for="header in tableHeaders"
                          :key="`upload-${header.id}-row-${rowIndex}`"
                          :class="header.cellClass"
                        >
                          {{ clusteringScheme[header.value] }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-row>
            </v-container>
          </v-row>
          <v-divider horizontal />
        </div>
      </v-container>

      <!-- Page actions -->
      <template v-slot:footer>
        <page-actions
          is-custom-export
          :export-button-title="getCsvExportTitle"
          :has-data-errors="hasDataErrors"
          :has-data-changes="true"
          :show-discard="false"
          :show-export="true"
          @save="onSave"
          @export="exportCSV()"
        >
          <template v-slot:right-btns>
            <v-btn class="text-outline" text link @click="closeModal">
              {{ $t('actions.cancel') }}
            </v-btn>
          </template>
        </page-actions>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import to from 'await-to-js';
import { mapGetters, mapState, mapActions } from 'vuex';
import {
  filter,
  isEqual,
  merge,
  pick,
  some,
  times,
  forEach,
  isEmpty,
  toNumber,
  isNaN,
  trim,
} from 'lodash';
import { CELL_TYPE } from '@enums/table/index';
import exportCSV from '@/js/mixins/export-csv';
import downloadFile from '../../../mixins/download-file';
import inputValidationMixin from '../../../mixins/input-validations';
import TableUtils from '../../../utils/table-utils';

export default {
  name: 'ClusterCreationModal',
  mixins: [inputValidationMixin, downloadFile, exportCSV],
  localizationKey: 'clusteringPage.dialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      times,
      TableUtils,
      CELL_TYPE,
      loading: false,
      numberOfSchemes: null,
      isNewClusterSchemeNameSelected: false,
      isManualUploadSelected: false,
      newClusterSchemeName: '',
      schemeNameInput: {
        id: 'schemeName',
        value: 'name',
        headerClass: '.cluster-name',
        cellClass: 'pr-0',
        ui: CELL_TYPE.input,
        text: this.$tkey('clusteringSchemeLabel'),
        rules: [this.required],
        serverRule: this.hasUniqueName,
      },
      clusterCountInput: {
        id: 'schemeClusterCount',
        value: 'clusterCount',
        headerClass: '.cluster-count',
        ui: CELL_TYPE.input,
        text: this.$tkey('noOfClusterHeader'),
        rules: [this.required, this.isInteger, this.isClustered],
        serverRule: this.hasValidClusterCount,
        onInput: this.updateClusterCount,
      },
      csvUploadLegends: {
        buttonName: this.$t('actions.manualImport'),
        title: this.$tkey('importClusteringSchemesTitle'),
      },
      csvUploadedClusterSchemes: [],
    };
  },
  computed: {
    // clusterSchemes is saved data in mongo,
    ...mapState('referenceClustering', ['clusterSchemes']),
    ...mapGetters('referenceClustering', ['getTableRows']),
    ...mapGetters('context', ['getCsvExport', 'getDateFormats']),

    hasCSVUploadedData() {
      return !isEmpty(this.getTableRows) || this.loading;
    },

    getCsvExportTitle() {
      return this.$tkey('downloadTemplate');
    },

    hasDataErrors() {
      // user should have at least one methodology selected
      const hasNoNewSchemaName = this.newClusterSchemeName === '';
      const hasNoSchemesToBeCreated = isEmpty(this.getTableRows);

      const conditions = [];
      if (this.isNewClusterSchemeNameSelected) {
        conditions.push(hasNoNewSchemaName);
        if (this.nameHasError) {
          conditions.push(true);
        }
      }
      if (this.isManualUploadSelected) {
        conditions.push(hasNoSchemesToBeCreated);
      }
      return some(conditions);
    },

    nameHasError() {
      return (
        this.isNewClusterSchemeNameSelected &&
        this.newClusterSchemeName !== '' &&
        this.hasUniqueNewClusterSchemeName({ name: this.newClusterSchemeName }) !== ''
      );
    },

    allClusterSchemes() {
      return [
        ...this.clusterSchemes,
        ...this.getTableRows, // user uploaded cluster schemes
      ];
    },
  },

  created() {
    this.tableHeaders = [this.schemeNameInput, this.clusterCountInput];
    this.resetFormData();
  },

  methods: {
    ...mapActions('files', ['uploadCSV']),
    ...mapActions('referenceClustering', ['processCSV', 'setUploadedData']),

    onToggleManualUploadSelected(value) {
      this.isManualUploadSelected = value;
      // remove csv uploaded data
      if (value === false) this.resetManualUploadForm();
    },

    onToggleNewClusterSchemeNameSelected(value) {
      this.isNewClusterSchemeNameSelected = value;
    },

    toClusterSchemes(csvData) {
      const clusterSchemes = [];
      forEach(csvData, (v, k) => {
        const clusterCount = v.length;
        clusterSchemes.push({
          name: k,
          clusterCount,
          clusters: v,
        });
      });
      return clusterSchemes;
    },

    toTableRows(clusterData) {
      return clusterData.map(v => pick(v, ['name', 'clusterCount']));
    },

    closeModal() {
      this.resetFormData();
      this.$emit('close');
    },

    resetFormData() {
      this.isManualUploadSelected = false;
      this.loading = false;
      this.resetManualUploadForm();
    },

    resetManualUploadForm() {
      this.setUploadedData([]);
    },

    isClustered(numClusters) {
      // can't choose 1 cluster, since that is essentially unclustered (all stores in one cluster)
      const parsedNumClusters = toNumber(numClusters);
      const isClustered = !isNaN(parsedNumClusters) && parsedNumClusters > 1;
      return isClustered || this.$t('validationErrors.unclustered');
    },

    hasUniqueName({ name }) {
      const newName = name;

      // We check if the name is unique whithin the list to be created and the list of existing clusters
      const isUnique =
        filter(this.allClusterSchemes, ({ name: existingName }) => {
          return isEqual(trim(String(newName)), trim(existingName));
        }).length === 1;

      return isUnique ? '' : this.$t('validationErrors.unique', [this.$tkey('name')]);
    },

    hasUniqueNewClusterSchemeName({ name }) {
      const newName = name;

      // We check if the name is unique whithin the list to be created and the list of existing clusters
      const nameMatches = filter(this.allClusterSchemes, ({ name: existingName }) => {
        return isEqual(trim(String(newName)), trim(existingName));
      });
      const isUnique = nameMatches.length === 0;
      return isUnique ? '' : this.$t('validationErrors.unique', [this.$tkey('name')]);
    },

    hasValidClusterCount(clusteringScheme) {
      return clusteringScheme.clusterCount > 0;
    },

    async onSave() {
      const newScheme = [];
      if (this.isNewClusterSchemeNameSelected) {
        newScheme.push({ name: this.newClusterSchemeName }); // no cluster count or clusters, just a new name
      }
      const bodyData = merge(
        { clusterSchemes: newScheme },
        { csvUploadedClusterSchemes: this.csvUploadedClusterSchemes }
      );

      this.$emit('process', bodyData);
      this.closeModal();
    },

    async onCSVUpload(formData) {
      return this.uploadCSV({ formData, service: 'reference-clustering' });
    },

    async process({ fileId, mappings, delimiter }) {
      // note at this point rows are still object form.
      this.loading = true;
      const [err, processedCsvData] = await to(this.processCSV({ fileId, mappings, delimiter }));
      this.loading = false;
      if (err) return;

      this.csvUploadedClusterSchemes = this.toClusterSchemes(processedCsvData);
      this.setUploadedData(this.toTableRows(this.csvUploadedClusterSchemes));
    },

    exportCSV() {
      const filename = this.getFileName({
        serviceName: 'reference-cluster-schemes', // this is not the actual service name, just used for the file/table name
        fileNameDateFormat: this.getDateFormats.csvFileName,
      });
      const separator = this.getCsvExport.columnSeparator;

      const header = [
        this.$t('mongoFields.scenarioClusterSchemes.name'),
        this.$t('mongoFields.scenarioClusterSchemes.storeKey'),
        this.$t('mongoFields.scenarioClusterSchemes.clusterName'),
      ];
      const sample = ['Region', '1', 'North'];
      const csvStr = `${header.join(separator)}\n${sample.join(separator)}`;

      this.downloadFromBuffer(csvStr, filename);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.attributes-row {
  background-color: $assortment-attribute-colour;
  margin-top: 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  opacity: 1;
  border-color: $assortment-attribute-colour;
  padding: 3px;
  align-items: center;
  flex-wrap: nowrap;
  word-break: break-word;
}

#schemes-table,
#upload-ref-schemes-table {
  width: 100%;
  tbody > tr:nth-last-of-type(odd) {
    background-color: $assortment-table-blue-bg-colour;
  }
}

::v-deep .dialog-card__footer {
  border-top-width: 0px;
}

.rtls-border {
  border-right-width: 1px;
}

.cluster-name {
  width: 70%;
}

.cluster-count {
  white-space: nowrap;
}

.dialog-card__footer {
  .actions-row {
    flex: auto;
  }
}

.error-triangle-icon {
  color: $assortment-negative-action-colour;
  font-size: 2rem !important;
  cursor: pointer;
}
</style>
