// Filter translations by client and fallback languages
import _, { isNil } from 'lodash';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import numeral from 'numeral';
import i18nconfig from '@sharedModules/config/static-i18n-config';
import { translations } from '../lang';

Vue.use(VueI18n);
Vue.mixin({
  methods: {
    /*
     * This new helper method can be used to simplify translation calls.
     * Instead of calling multiple times in Vue component for translations as:
     * this.$t('averylongnameformysection.subsection.title'), it can be simplified as this.$tkey('subsection.title')
     * as long as localizationKey is passed as an option to the Vue component. See furniture-editor.vue for more examples.
     *
     * The last parameter passed in can be an option object that contains a fallback, in case the translations are not loaded in context. See router.js for example
     * */
    $tkey(key, ...args) {
      let translation;
      if (!_.get(this, '$options.localizationKey')) {
        translation = this.$t(key, ...args);
      } else {
        translation = this.$t(`${this.$options.localizationKey}.${key}`, ...args);
      }

      // If translation fails check if last argument is an option object with a fallback
      if (translation === key || translation === `${this.$options.localizationKey}.${key}`) {
        return _.get(args.pop(), 'fallback', translation);
      }

      return translation;
    },
  },
});

const i18n = new VueI18n({
  locale: i18nconfig.defaultLocale, // client-specific. Overwritten on context load
  fallbackLocale: i18nconfig.fallbackLocale, // fallback language
  availableLocales: i18nconfig.availableLocales, // client-specific
  availableDateLocales: i18nconfig.availableDateLocales, // client-specific
  messages: {}, // No translations loaded here. See context where translations are dynamically loaded
  numberFormats: translations.numberFormats, // number formats for all locales
  silentTranslationWarn: true,
  dateTimeFormats: translations.dateFormats,
});

// Default tranlsations available before the users logs in
i18n.setLocaleMessage('en', {
  footer: {
    manageCookiesButtonTitle: 'Manage Cookies',
    termsOfUse: 'Terms of Use',
    privacyNote: 'Privacy Notice',
    cookieNotice: 'Cookie Notice',
    solutionFrom: 'A solution from',
  },
});

i18n.n = function(
  numberFormatKey,
  value,
  { usePlaceholder = false, placeholder = '-', useAbsoluteValue = false } = {}
) {
  if (isNil(value) && usePlaceholder === true) return placeholder;

  // Use translations to get the format
  let format = i18n.t(numberFormatKey);

  // It is possible for the returned value to point to a key in our defaults
  // As we may want some clients to still have the default value whenever that changes
  // For docs on how this works see: https://owlabs.atlassian.net/wiki/spaces/RTLS/pages/1428127889/Number+Localisation
  if (
    i18n.te(`numbers.default.${format}`) ||
    i18n.te(`numbers.default.${format}`, i18nconfig.fallbackLocale)
  ) {
    format = i18n.t(`numbers.default.${format}`);
  }

  // Use numeralJS to convert value using locale and format and return it
  return numeral(useAbsoluteValue ? Math.abs(value) : value).format(format);
};

export default i18n;
