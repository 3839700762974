import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex filter-box",
      attrs: { "data-id-e2e": "cdtFilterSelect" },
    },
    [
      _c("span", { staticClass: "col-3" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("workpackagePage.splitWorkpackages.filterCdt")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(VSelect, {
        staticClass: "rtls-select rtls-select--white",
        attrs: {
          items: _vm.cdtItems,
          "hide-details": "",
          white: "",
          multiple: "",
          "item-value": "productCategoryKey",
          "item-text": "description",
          "data-id-e2e": "cdtSelect",
          disabled: !_vm.cdtItems.length,
          placeholder: _vm.$t("filterWorkpackagesOptions.noOption"),
        },
        on: {
          change: function ($event) {
            return _vm.updateMainFilterObject()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function ({ item, index }) {
              return [
                index < 1
                  ? _c("span", { staticClass: "selected-items" }, [
                      _vm._v(_vm._s(item.description) + "  "),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                index === 1
                  ? _c("span", { staticClass: "grey--text caption" }, [
                      _vm._v(
                        "\n        (+" +
                          _vm._s(_vm.selectedCdts.length - 1) +
                          " " +
                          _vm._s(
                            _vm.$t("workpackagePage.splitWorkpackages.moreCDTs")
                          ) +
                          ")\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "prepend-item",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "sticky" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-space-around align-center pt-2",
                      },
                      [
                        _c(
                          VBtn,
                          {
                            staticClass: "button-link",
                            attrs: { text: "" },
                            on: { click: _vm.selectAll },
                          },
                          [
                            _c(VIcon, { attrs: { size: "16" } }, [
                              _vm._v("mdi-check"),
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("actions.selectAll"))),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            staticClass: "button-link",
                            attrs: { text: "" },
                            on: { click: _vm.selectNone },
                          },
                          [
                            _c(VIcon, { attrs: { size: "16" } }, [
                              _vm._v("mdi-checkbox-blank-outline"),
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t("actions.selectNone"))),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(VDivider, { staticClass: "mt-2" }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.selectedCdts,
          callback: function ($$v) {
            _vm.selectedCdts = $$v
          },
          expression: "selectedCdts",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }