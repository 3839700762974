'use strict';

module.exports = {
  overview: 'overview',
  stats: 'stats',
  relativeShare: 'relative-share',
  priceLadder: 'price-ladder',
  comparisonWaterfall: 'comparison-waterfall',
  product: 'product',
};
