import axios from 'axios';
import to from 'await-to-js';
import { each, keyBy, isNull, find, mapValues, without } from 'lodash';
import i18n from '../../vue-i18n';
import handleErrorMessages from '../utils/validation';

const store = {
  namespaced: true,
  state: {
    cdtSelectedAttributes: {},
    scenarioCdts: [],
    loading: false,
  },

  getters: {
    scenarioCdts(state) {
      return state.scenarioCdts;
    },

    getCdtSelectedAttributes(state) {
      return state.cdtSelectedAttributes;
    },

    getCdtSelectedAttributesForCannGroup: state => cannGroupId => {
      return state.cdtSelectedAttributes[cannGroupId];
    },

    childrenLookup: state => cannGroupId => {
      // This returns us a list of all the children of each CDT
      // This is used to structure and aggregate the canvas page

      const { cdt } = find(state.scenarioCdts, { cannGroupId });

      // we have to traverse the tree up to the parents, this cuts down on finds
      const idLookup = keyBy(cdt, '_id');

      const childTree = {};
      each(cdt, node => {
        // include the current ID - this is useful on the canvas page for aggregations.
        // leads to output where, given A has a child B with a child C, we get { A: Set([A, B, C])}
        childTree[node._id] = new Set([node._id]);

        let currentParent = node.parentId;
        const currentId = node._id;

        while (!isNull(currentParent)) {
          childTree[currentParent].add(currentId);
          currentParent = idLookup[currentParent].parentId;
        }
      });

      return childTree;
    },

    // Returns nested keyby. First by CannGroupId, then by all the CDT _id in that cann group
    cdtById: state =>
      mapValues(keyBy(state.scenarioCdts, 'cannGroupId'), cannGroup => keyBy(cannGroup.cdt, '_id')),
  },

  mutations: {
    setCdtSelectedAttributes(state, attributes) {
      state.cdtSelectedAttributes[attributes.key] = attributes.value;
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setScenarioCdts(state, scenarioCdts) {
      state.scenarioCdts = scenarioCdts;
    },

    addUserModifiedCdt(state, scenarioCdt) {
      state.scenarioCdts.push(scenarioCdt);
    },
  },

  actions: {
    async fetchScenarioCdts({ commit, rootState }, { params } = {}) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, response] = await to(axios.get(`/api/scenarios/${scenarioId}/cdts`, { params }));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      commit('setScenarioCdts', response.data);
    },
    async afterCdtJobComplete({ dispatch }) {
      dispatch('fetchScenarioCdts');
      const content = i18n.t('notifications.cdt.finished');
      dispatch('snackbar/showSuccess', content, { root: true });
    },
    async afterCdtJobFailed({ dispatch }) {
      dispatch('fetchScenarioCdts');
      const content = i18n.t('notifications.cdt.failed');
      dispatch('snackbar/showError', content, { root: true });
    },
    async updateCdtById({ commit, dispatch, rootState }, payload) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const { cdtId, updates, cannGroupId } = payload;
      commit('setLoading', true);
      const [err] = await to(
        axios.patch(`/api/scenarios/${scenarioId}/cdts/${cdtId}`, {
          cannGroupId,
          updates,
        })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
    },
    async addCdt({ commit, dispatch, rootState }, payload) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const { cannGroupId } = payload;
      commit('setLoading', true);
      const [err] = await to(
        axios.post(`/api/scenarios/${scenarioId}/cdts/cann-groups/${cannGroupId}`, payload)
      );
      if (err) throw new Error(err.message);
      dispatch('fetchScenarioCdts', scenarioId);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
      commit('setLoading', false);
    },

    async runCdtGenerationJob({ commit, dispatch, rootState }, payload) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const { cannGroupIds } = payload;
      commit('setLoading', true);
      const [err, response] = await to(
        axios.post(`/api/scenarios/${scenarioId}/cdt-generation`, {
          cannGroupIds,
        })
      );
      commit('setLoading', false);
      if (err) throw new Error(err);
      handleErrorMessages({ response, dispatch });
    },

    async deleteModifiedCdt({ commit, dispatch, rootState }, payload) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const { cdtId } = payload;
      commit('setLoading', true);
      const [err] = await to(axios.delete(`/api/scenarios/${scenarioId}/cdts/${cdtId}`));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      dispatch('fetchScenarioCdts', scenarioId);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
    },
    deleteUnsavedModifiedCdt({ commit, dispatch, state }, modifiedCdt) {
      commit('setLoading', true);
      const newScenarioCdts = without(state.scenarioCdts, modifiedCdt);
      commit('setScenarioCdts', newScenarioCdts);
      commit('setLoading', false);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
    },
    async copyAttributeSelection(
      { rootState, commit, dispatch },
      { fromCannGroupId, toCannGroups }
    ) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err] = await to(
        axios.patch(`/api/scenarios/${scenarioId}/cdt/${fromCannGroupId}/attributes/copy`, {
          toCannGroups,
        })
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      dispatch('fetchScenarioCdts', scenarioId);
      commit('setLoading', false);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
    },
  },
};
export default store;
