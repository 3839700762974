'use strict';

const DataTypes = require('../../enums/data-types');
const commonWorkpackageProductSchema = require('./workpackage-products');

const fixedFields = commonWorkpackageProductSchema.fixedFields.concat([
  {
    mongoField: 'inReset',
    type: DataTypes.boolean,
    aliases: 'csvAliases.workpackageProducts.inReset',
    translationKey: 'mongoFields.inReset',
    required: false,
  },
]);

module.exports = {
  fixedFields,
};
