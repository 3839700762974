import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-scenario w-100" },
    [
      _vm.block
        ? _c(
            "div",
            {
              staticClass: "block-container",
              attrs: { "data-id-e2e": "searchScenario" },
            },
            [
              _c("div", { staticClass: "mb-1" }, [
                _vm._v(_vm._s(_vm.$tkey("scenarioImportDescription"))),
              ]),
              _vm._v(" "),
              _c(VAutocomplete, {
                ref: "autocomplete",
                staticClass: "mb-1",
                attrs: {
                  "data-id-e2e": "searchScenarioWpAutocompleteBlock",
                  "item-text": "name",
                  "item-value": "_id",
                  height: "30px",
                  items: _vm.workpackages,
                  filter: _vm.filterItems,
                  placeholder: _vm.$tkey("selectWorkpackage"),
                  "menu-props": { auto: true },
                  "search-input": _vm.wpSearchInput,
                  "background-color": "white",
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.wpSearchInput = $event
                  },
                  "update:search-input": function ($event) {
                    _vm.wpSearchInput = $event
                  },
                  change: _vm.getWorkpackageScenarios,
                },
                model: {
                  value: _vm.selectedWorkpackageId,
                  callback: function ($$v) {
                    _vm.selectedWorkpackageId = $$v
                  },
                  expression: "selectedWorkpackageId",
                },
              }),
              _vm._v(" "),
              _c(
                VRow,
                [
                  _c(VAutocomplete, {
                    ref: "autocomplete",
                    attrs: {
                      "data-id-e2e": "searchScenarioScenarioAutocompleteBlock",
                      "item-text": "name",
                      "item-value": "_id",
                      height: "30px",
                      items: _vm.wpScenarios,
                      filter: _vm.filterItems,
                      disabled: !_vm.selectedWorkpackageId,
                      placeholder: _vm.$tkey("selectScenario"),
                      "menu-props": { auto: true },
                      "search-input": _vm.scenarioSearchInput,
                      rules: _vm.rules,
                      "background-color": "white",
                    },
                    on: {
                      "update:searchInput": function ($event) {
                        _vm.scenarioSearchInput = $event
                      },
                      "update:search-input": function ($event) {
                        _vm.scenarioSearchInput = $event
                      },
                      change: _vm.setSelectedScenarioId,
                    },
                    model: {
                      value: _vm.selectedScenarioId,
                      callback: function ($$v) {
                        _vm.selectedScenarioId = $$v
                      },
                      expression: "selectedScenarioId",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      staticClass: "pl-1 pt-2",
                      attrs: { icon: "", text: "" },
                      on: { click: _vm.clearSelections },
                    },
                    [
                      _c(
                        VIcon,
                        { attrs: { size: "20", color: "primary" } },
                        [_vm._v("mdi-close-circle")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.inline
        ? _c(
            VRow,
            {
              staticClass:
                "inline-container d-flex align-center justify-space-between",
            },
            [
              _c(
                VCol,
                { staticClass: "px-0 py-0", attrs: { cols: "9" } },
                [
                  _c(
                    VRow,
                    {
                      staticClass: "d-flex align-center justify-space-between",
                    },
                    [
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.$t("general.filter"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        VCol,
                        { staticClass: "overflow-ellipsis py-1" },
                        [
                          _c(VAutocomplete, {
                            ref: "autocomplete",
                            attrs: {
                              "data-id-e2e":
                                "searchScenarioWpAutocompleteInline",
                              "item-text": "name",
                              "item-value": "_id",
                              height: "25px",
                              items: _vm.workpackages,
                              filter: _vm.filterItems,
                              placeholder: _vm.$tkey("selectWorkpackage"),
                              "menu-props": { auto: true },
                              "search-input": _vm.wpSearchInput,
                              "background-color": "white",
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.wpSearchInput = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.wpSearchInput = $event
                              },
                              change: _vm.getWorkpackageScenarios,
                            },
                            model: {
                              value: _vm.selectedWorkpackageId,
                              callback: function ($$v) {
                                _vm.selectedWorkpackageId = $$v
                              },
                              expression: "selectedWorkpackageId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VCol,
                        {
                          staticClass:
                            "d-flex align-center px-0 overflow-ellipsis py-1",
                        },
                        [
                          _c(VAutocomplete, {
                            ref: "autocomplete",
                            attrs: {
                              "data-id-e2e":
                                "searchScenarioScenarioAutocompleteInline",
                              "item-text": "name",
                              "item-value": "_id",
                              height: "25px",
                              items: _vm.wpScenarios,
                              filter: _vm.filterItems,
                              disabled: !_vm.selectedWorkpackageId,
                              placeholder: _vm.$tkey("selectScenario"),
                              "menu-props": { auto: true },
                              "search-input": _vm.scenarioSearchInput,
                              rules: _vm.rules,
                              "background-color": "white",
                            },
                            on: {
                              "update:searchInput": function ($event) {
                                _vm.scenarioSearchInput = $event
                              },
                              "update:search-input": function ($event) {
                                _vm.scenarioSearchInput = $event
                              },
                              change: _vm.setSelectedScenarioId,
                            },
                            model: {
                              value: _vm.selectedScenarioId,
                              callback: function ($$v) {
                                _vm.selectedScenarioId = $$v
                              },
                              expression: "selectedScenarioId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VCol,
                        { staticClass: "px-1", attrs: { cols: "1" } },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { icon: "", text: "" },
                              on: { click: _vm.clearSelections },
                            },
                            [
                              _c(
                                VIcon,
                                { attrs: { size: "20", color: "primary" } },
                                [_vm._v("mdi-close-circle")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VCol,
                { staticClass: "d-flex justify-end", attrs: { cols: "3" } },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "ml-2",
                      attrs: {
                        "data-id-e2e": "searchScenarioViewBtn",
                        secondary: "",
                        depressed: "",
                        disabled: !_vm.selectedScenarioId,
                      },
                      on: { click: _vm.viewResults },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$tkey("view")) + "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }