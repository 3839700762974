import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.mostRecentOutbound && _vm.isVisible && !_vm.isDisabled
      ? _c(
          "span",
          [
            _vm.mostRecentOutbound.status === _vm.finished
              ? _c(
                  VIcon,
                  {
                    staticClass: "icon finished",
                    attrs: {
                      title: _vm.$tkey("recentlyUploadedOn"),
                      size: "20",
                    },
                  },
                  [_vm._v("mdi-check-circle")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.mostRecentOutbound.status === _vm.error
              ? _c(
                  VIcon,
                  {
                    staticClass: "icon error-icon",
                    attrs: {
                      title: _vm.$tkey("errorDuringUpload"),
                      size: "20",
                    },
                  },
                  [_vm._v("mdi-alert")]
                )
              : _vm._e(),
            _vm._v(
              "\n    " +
                _vm._s(
                  _vm._f("formatDate")(
                    _vm.mostRecentOutboundTimestamp,
                    _vm.getDateFormats.longWithFullTime
                  )
                ) +
                "\n  "
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }