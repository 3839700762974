<template>
  <div style="width: 100%; height: 100%;">
    <v-divider />
    <store-allocation-table
      id="store-allocation-table"
      ref="store-allocation-table"
      mode="scenario-cluster"
      @has-selected-rows="$emit('has-selected-rows', $event)"
      @data-changed="$emit('data-changed', $event)"
      @on-save="onSave()"
      @open-cluster-generation-modal="openClusterGenerationModal($event)"
    />

    <dependency-tree-feedback-modal
      :value="isDependencyTreeModalOpen"
      :results="dependencyTreeFeedback"
      page="clustering"
      @close="closeDependencyTreeModal"
      @commit="onSave(true)"
    />

    <cluster-generation-modal
      v-if="isClusterGenerationModalOpen"
      v-model="isClusterGenerationModalOpen"
      :sub-cluster-from="clusterToSubCluster"
      @process="processNewSubClusteringSchemes"
      @close="closeClusterGenerationModal"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { isEqual, find } from 'lodash';

export default {
  name: 'StoreAllocationList',
  localizationKey: 'clusteringPage.tabs.storeAllocation',
  data() {
    return {
      dependencyTreeFeedback: {},
      isDependencyTreeModalOpen: false,
      isClusterGenerationModalOpen: false,
      clusterToSubCluster: null,
    };
  },

  computed: {
    ...mapState('clustering', ['selectedScheme']),
    ...mapGetters('scenarios', ['stores']),
    ...mapGetters('context', ['getDateFormats', 'getClientConfig']),
  },

  methods: {
    ...mapActions('clustering', ['fetchScenarioClusters', 'saveClusteringSchemes']),
    ...mapActions('dependencyTree', ['triggerDependencyTree']),
    ...mapActions('scenarios', ['refreshScenario']),

    moveRowsToGroup({ groupClusterName }) {
      this.$refs['store-allocation-table'].moveRowsToGroup({ groupClusterName });
    },

    closeDependencyTreeModal() {
      this.isDependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
    },

    openClusterGenerationModal(params) {
      this.clusterToSubCluster = find(
        this.selectedScheme.clusters,
        ({ clusterName }) => isEqual(clusterName, params.node.key) // set sub-clustering source
      );
      this.isClusterGenerationModalOpen = true;
    },

    closeClusterGenerationModal() {
      this.isClusterGenerationModalOpen = false;
      this.clusterToSubCluster = null; // reset sub-clustering source
    },

    async processNewSubClusteringSchemes(body) {
      await this.saveClusteringSchemes(body);
      await this.fetchScenarioClusters();
      await this.refreshScenario();
    },

    async onSave(commit = false) {
      // Perform dependency tree check if scheme has run switching
      if (this.selectedScheme.selected) {
        const results = await this.triggerDependencyTree({
          params: {
            change: 'clusteringModified',
            updates: {
              global: {
                scenarioClusterSchemeId: this.selectedScheme._id,
                clusters: this.$refs['store-allocation-table'].formatClusterData(),
              },
            },
            commit,
          },
        });

        if (results.needsFeedback) {
          this.dependencyTreeFeedback = results.output;
          this.isDependencyTreeModalOpen = true;
          return;
        }
      }
      // scheme is not selected or DT feedback is not needed -> proceed with save
      await this.$refs['store-allocation-table'].onSaveRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .ag-row-group {
    background: $assortment-table-white-bg-colour;
  }

  .ag-row-selected,
  .ag-row-selected .ag-row-group {
    background: $assortment-table-blue-bg-colour !important;

    &:before {
      content: none;
    }
  }

  .sub-clustering-icon {
    i {
      transform: rotate(90deg) scale(90%);
      color: $assortment-sub-clustering-button-background-color;
    }
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}
</style>
