<template>
  <v-dialog
    v-model="show"
    :width="width"
    content-class="rtls-dialog"
    @keydown.esc="cancel"
    @click:outside="clickOutside"
  >
    <v-container class="main-dialog" :class="{ 'main-dialog--border': border }">
      <slot name="header" :cancel="cancel">
        <template v-if="title">
          <v-row class="main-dialog__header">
            <v-col class="d-flex justify-space-between pa-0">
              <h3>
                {{ title }}
              </h3>
              <v-btn icon class="float-right" width="24" @click="cancel">
                <v-icon color="#2f477c">mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-icon />
          <v-divider />
        </template>
      </slot>
      <slot name="content" :confirm="confirm" :cancel="cancel">
        <v-row class="main-dialog__content">
          <v-col class="message">
            {{ message }}
          </v-col>
        </v-row>
      </slot>
      <v-row v-if="showConfirmMessage">
        <strong class="mx-auto mb-2 message">
          {{ $t('messages.areYouSureYouWantToDoThis') }}
        </strong>
      </v-row>
      <slot name="actions" :confirm="confirm" :cancel="cancel" />
    </v-container>
  </v-dialog>
</template>

<script>
import to from 'await-to-js';

export default {
  props: {
    width: {
      type: [String, Number],
      default: 300,
    },
    title: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: '',
    },
    border: Boolean,
    showConfirmMessage: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    confirmAction: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      resolve: null,
      reject: null,
    };
  },
  computed: {
    hasActionSlot() {
      return !!this.$scopedSlots.actions;
    },
  },
  methods: {
    open() {
      this.show = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    async confirm() {
      if (this.confirmAction) {
        await to(this.confirmAction());
      }
      this.resolve(true);
      this.show = false;
    },
    cancel() {
      this.resolve(false);
      this.$emit('cancel');
      this.show = false;
    },

    clickOutside() {
      this.resolve(false);
      this.$emit('click:outside');
      this.show = false;
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.main-dialog {
  padding: 16px;
  background-color: $assortment-modal-background-colour;

  &--border {
    border-left: 8px solid $assortment-primary-colour;
    max-width: none;
  }
  &__header {
    font-size: 16px;
  }
  &__content {
    font-size: 12px;
  }

  .container {
    max-width: none;
  }
  .message {
    font-size: 1.4rem;
    padding: 16px 0 0 0;
  }
}
</style>
