<template>
  <v-dialog
    id="clusterNameModal"
    :value="value"
    fullscreen
    content-class="cdt-tree-modal"
    attach=".cdt-page"
    @click:outside="closeModal"
    @keydown.esc="closeModal"
  >
    <dialog-card :title="customerDecisionTreeTitle" @close="closeModal">
      <div class="cdt-tree-container">
        <div class="container-block flex-grow-1">
          <div class="tree ma-0 pb-1">
            <progress-bar v-if="loading" class="pt-5" />

            <div v-else class="treeview-container">
              <div v-if="cdt" class="treeview-column-labels">
                <div
                  v-for="(val, index) in selectedAttributes"
                  :key="val.attributeId"
                  class="d-flex treeview-column-label justify-space-between"
                >
                  <div class="attribute-name row align-center">
                    <p class="col-8 pa-0 pr-1">
                      {{ getAttributeName(val.attributeId) }}
                    </p>

                    <div class="text-center col-2 pa-0">
                      <span>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">
                              {{ coefficientByLevel[index] }}
                            </span>
                          </template>
                          {{ $tkey('cdtList.coefficient') }}: {{ coefficientByLevel[index] }}
                        </v-tooltip>
                      </span>
                    </div>

                    <div class="text-center col-2 pa-0">
                      <span v-if="val.rank" class="rank-circle">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">
                              {{ val.rank }}
                            </span>
                          </template>
                          {{ $tkey('cdtList.rank') }}: {{ val.rank }}
                        </v-tooltip>
                      </span>
                    </div>
                  </div>

                  <div
                    v-if="getToggleButton(index)"
                    class="treeview-column-controls d-flex flex-column justify-center"
                  >
                    <v-btn class="treeview-column-button" @click="toggleNodes(index)">
                      <v-icon class="material-icons icon">
                        {{ toggleIcon(index) }}
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>

              <v-treeview
                v-if="treeData"
                dense
                class="rtls-treeview"
                item-key="_id"
                :items="treeData"
                :open.sync="openedNodes"
              >
                <template v-slot:label="{ item: node }">
                  <!-- Small horizontal line -->
                  <div class="node-line" />
                  <div class="attributes-box d-flex flex-column">
                    <div class="attribute-value d-flex align-center mb-1">
                      <p>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">
                              {{ node.attributeValue }}
                            </span>
                          </template>
                          {{ node.attributeValue }}
                        </v-tooltip>
                      </p>
                    </div>
                  </div>
                </template>
              </v-treeview>
            </div>
          </div>
        </div>
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { isEmpty, get, uniq, filter, intersection, size, reduce, isUndefined } from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import { userModified } from '@enums/scenario-cdt-types';

export default {
  localizationKey: 'customerDecisionTree',
  props: {
    value: {
      type: Boolean,
      required: true,
    },

    selectedCannGroup: {
      type: Object,
      required: true,
    },

    cdtRef: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      cdt: null,
      treeData: null,
      openedNodes: [],
      parentNodesByLevel: {},
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('context', ['clientConfig']),
    ...mapState('scenarioCdts', ['scenarioCdts', 'loading']),
    ...mapGetters('scenarios', ['attributesById']),

    selectedAttributes() {
      if (this.cdt.type === userModified) {
        return filter(this.cdt.attributes, at => at.isSelected);
      }
      return filter(this.cdt.attributes, at => at.rank).sort((a, b) => a.rank - b.rank);
    },

    customerDecisionTreeTitle() {
      const cannGroupKey = get(this.selectedCannGroup, 'key');
      return this.$tkey('treeView.title', {
        cannGroupName: get(this.selectedCannGroup, 'name', cannGroupKey),
      });
    },

    coefficientByLevel() {
      const cutOff = this.clientConfig.cdt.coefficientDisplayCutOff;
      return reduce(
        this.selectedAttributes,
        (acc, sa, level) => {
          if (sa.coefficient < cutOff) {
            const cutOffFormatted = this.formatNumber({ number: cutOff, format: 'float' });
            acc[level] = `< ${cutOffFormatted}`;
          } else {
            acc[level] = this.formatNumber({ number: sa.coefficient, format: 'float' });
          }
          return acc;
        },
        {}
      );
    },
  },

  watch: {
    cdtRef: {
      deep: true,
      handler() {
        this.init(this.selectedScenario.cannGroups);
      },
    },
  },

  async created() {
    this.init(this.selectedScenario.cannGroups);
  },

  methods: {
    ...mapActions('scenarioCdts', ['fetchScenarioCdts']),

    getAttributeName(attributeId) {
      return this.attributesById[attributeId].name;
    },

    async init() {
      this.openedNodes = [];
      await this.fetchScenarioCdts();
      this.cdt = this.scenarioCdts.find(cdt => cdt._id === this.cdtRef._id);
      this.treeData = this.unflatten(get(this.cdt, 'cdt', []));
      this.openedNodes = uniq(this.openedNodes);
    },

    getOpenNodes(level) {
      const levelParentNodes = [...(this.parentNodesByLevel[level] || [])];
      return size(intersection(this.openedNodes, levelParentNodes));
    },

    toggleNodes(level) {
      const nodesToOpen = [];
      const levelOpenNodes = this.getOpenNodes(level);

      // Expand parent nodes
      for (let i = 0; i < level; i += 1) {
        nodesToOpen.push(...this.parentNodesByLevel[i]);
      }
      // If at least one node is closed in the current level, expand all nodes
      if (levelOpenNodes < this.parentNodesByLevel[level].size) {
        nodesToOpen.push(...this.parentNodesByLevel[level]);
      }
      this.openedNodes = nodesToOpen;
    },

    toggleIcon(level) {
      return this.getOpenNodes(level) === this.parentNodesByLevel[level].size
        ? 'arrow_drop_down'
        : 'arrow_right';
    },

    getToggleButton(level) {
      // hide toggle button for levels that do not contain nodes or contain nodes without children
      return !isUndefined(this.parentNodesByLevel[level]);
    },

    unflatten(array, parent, tree, level = 0) {
      tree = typeof tree !== 'undefined' ? tree : [];
      parent = typeof parent !== 'undefined' ? parent : { _id: null };
      parent.level = level;

      if (parent._id && level < this.clientConfig.cdt.defaultExpandDepth) {
        this.openedNodes.push(parent._id);
      }
      const children = array.filter(function(child) {
        return child.parentId === parent._id;
      });

      if (!isEmpty(children)) {
        if (parent._id == null) {
          tree = children;
        } else {
          if (!this.parentNodesByLevel[level]) {
            // Use Set to ensure ids are unique
            this.parentNodesByLevel[level] = new Set();
          }
          this.parentNodesByLevel[level].add(parent._id);
          children.forEach(c => {
            if (c._id && level < this.clientConfig.cdt.defaultExpandDepth) {
              this.openedNodes.push(c._id);
            }
          });
          level += 1;
          parent.children = children;
        }
        children.forEach(child => {
          child.level = level;
          this.unflatten(array, child, undefined, level);
        });
      }
      return tree;
    },

    closeModal() {
      this.parentNodesByLevel = {};
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.cdt-tree-container {
  font-size: 12px;
  max-width: none !important;
  color: $assortment-text-colour;
  height: 100%;

  .header {
    border-bottom: 1px solid $assortment-divider-colour;
  }

  .container-block {
    overflow: auto;
    position: relative;
    height: 100%;

    .tree {
      display: flex;
      overflow-y: auto;
      height: 100%;
      width: fit-content;

      .rtls-treeview {
        clear: both;
      }

      .treeview-container {
        padding: 0 $assortment-sidepanel-toggle-height 0;
      }

      .treeview-select {
        .v-input__slot,
        .v-select__slot {
          background: $assortment-control-secondary-bg-colour;
          height: 28px !important;
        }

        .v-select__selections input {
          padding-left: 5px;
          height: 28px !important;
        }

        .v-input__slot {
          box-shadow: none !important;
          padding: 0 !important;
        }

        .v-icon {
          padding-right: 0 !important;
        }

        .v-label {
          position: unset !important;
          padding-left: 5px;
          font-size: 1.2rem;
        }

        .v-select__selection.v-select__selection--comma {
          padding-left: 5px !important;
        }
      }

      .attribute-name {
        width: 186px;

        p {
          font-weight: bold;
          font-size: 1.2rem;
          margin-bottom: 2px;
        }
      }

      .attribute-value {
        background: $assortment-scope-background-colour;
        width: 220px;
        height: 22px;
        border-radius: 3px;
        padding: 0 20px 0 10px;
        margin-right: 10px;

        p {
          font-size: 1.2rem;
          margin-bottom: 0px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &--all-products {
          margin-top: 20px;
          margin-right: 0;
        }

        &.hidden {
          display: none !important;
        }
      }
    }
  }

  .page-actions-container {
    border-top: 1px solid $assortment-panel-border-divider-colour;
  }
}

.rank-circle {
  background: $rank-background;
  padding: 1px 6px 2px;
  border-radius: 50%;
  color: white;
}

.treeview-column {
  /* Top column labels */
  &-labels {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
    position: relative;
    background-color: white;
    padding: 10px 10px 10px 0px;
  }

  &-label {
    background: $assortment-attribute-colour;
    border: 1px solid $assortment-scenarios-border-colour;
    border-radius: 3px;
    margin-right: 12px;
    padding: 4px 5px 4px 10px;
    width: 220px;

    p {
      line-height: 1.8rem;
    }
  }

  &-button {
    background-color: transparent !important;
    box-shadow: none;
    color: $assortment-tree-toggle-colour;
    height: 18px !important;
    min-width: unset !important;
    padding: 0 !important;
    width: 18px !important;
  }
}

/* Disable initial lines */
.v-treeview
  > .v-treeview-node
  > .v-treeview-node__root
  > .v-treeview-node__content
  > .v-treeview-node__label
  > .node-line {
  display: none;
}

::v-deep {
  .v-treeview-node {
    &__label {
      display: flow-root !important;
    }

    &__root {
      min-height: 26px !important;

      .v-treeview-node__label {
        margin: 0;
      }
    }

    &__toggle {
      align-self: baseline;
    }

    &__children {
      border-left: 1px solid $assortment-tree-line-colour;

      .v-treeview-node {
        &__root {
          border-left: 1px solid $assortment-tree-line-colour;

          i.v-treeview-node__toggle {
            left: 217px;
          }
        }

        &__label {
          margin-left: 21px;
        }
      }
    }

    // Overrides to stop trailing lines
    &:last-child {
      & > .v-treeview-node__children {
        border-left: 1px solid white;
      }

      &
        > .v-treeview-node__root
        > .v-treeview-node__content
        > .v-treeview-node__label
        > .node-line {
        border-left: 1px solid white;
      }
    }
  }

  .v-select__slot {
    .v-input__append-inner {
      display: none;
    }
    .v-input__control {
      font-size: 1.2 em;
    }
  }

  .node-line {
    width: 15px;
    height: 50%;
    top: 50%;
    left: -1px;
    border-top: 1px solid $assortment-tree-line-colour;
    position: absolute;
  }

  i.v-treeview-node__toggle {
    margin-bottom: 5px !important;
    position: absolute;
    top: 0px;
    left: 196px;
  }
  .v-treeview-node--leaf {
    .v-treeview-node__root {
      padding-left: 0px !important;
    }
  }

  .v-treeview.rtls-treeview {
    & > .v-treeview-node > .v-treeview-node__children {
      border-left: 1px solid white;
    }

    .v-treeview-node {
      &__root .v-treeview-node--leaf,
      &__root .v-treeview-node,
      &__children .v-treeview-node {
        margin-left: 229px !important;
      }
    }

    .v-treeview-node__children {
      margin-left: 0;
    }

    & > .v-treeview-node > .v-treeview-node__children > .v-treeview-node {
      margin-left: 208px !important;
    }
  }
}
</style>
