import { mapKeys, mapValues, kebabCase, bind, forIn } from 'lodash';

// Allows components to trigger global events using the global vue instance
// by adding a new method this.globalEmit(...), which is equivalent to this.$root.$emit(...)
// To facilitate listening to these events, allows components to declare a new events object as part of its options
// Given an component like:
//    computed: { ... }
//    events: {
//      onScrollToView(payload){
//        // do something with payload
//      },
//      myEvent(payload) { ... }
//      ['making-my-life-hard'](payload) { ... }
//    },
//    methods: { ... }
// This mixin will automatically wire those functions to the events 'scroll-to-view', 'my-event' and 'making-my-life-hard'
// It converts automatically event names to kebab case (so the component declaration is nicer) and removes the 'on' prefix if found
// When the component is destroyed this mixin will automatically clean references

// WARNING: do not explicitly import this mixin into your component,
// because this is a global mixin, if you include it explicitly, your component will throw an error.

// WARNING: be careful how you name your event so you don't include any special characters like ':',
// which will prevent it from being subscribed to.
// For instance if your client side event name in the enum is as follows {exportResults: 'workpackage:export-results'},
// you won't be able to subscribe to it on the client side.

// Include your client side events under client-events.js

const mixin = {
  methods: {
    globalEmit(eventName, payload) {
      return this.$root.$emit(eventName, payload);
    },
  },
  beforeCreate() {
    this.$options.events = this.$options.events || {};

    // Allow events to be declared as "onScrollToView", and have them listening to the "scroll-to-view" event
    this.$options.events = mapKeys(this.$options.events, (method, eventName) => {
      if (eventName.startsWith('on')) eventName = eventName.slice(2);
      return kebabCase(eventName);
    });

    // Ensure all the event handlers receive the component instance as "this"
    this.$options.events = mapValues(this.$options.events, method => bind(method, this));

    // Automatically wire all the events declared in the "events" section of the component
    // so they become listeners of events in the $root instance
    forIn(this.$options.events, (method, eventName) => this.$root.$on(eventName, method));
  },
  beforeDestroy() {
    // Automatically cleanup of event listeners
    forIn(this.$options.events, (method, eventName) => this.$root.$off(eventName, method));
  },
};

export default mixin;
