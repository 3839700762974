<template>
  <component :is="imgComponent" v-if="imgComponent" :class="classes" :title="title" />
  <img v-else :title="title" :alt="title" :src="imgSrc" />
</template>

<script>
import { get } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'imageRenderer',
  computed: {
    imgComponent() {
      return get(this.params, 'imgComponent', null);
    },

    classes() {
      return get(this.params, 'classes', '');
    },

    imgSrc() {
      return get(this.params, 'title', '');
    },

    title() {
      return get(this.params, 'title', '');
    },
  },
});
</script>
