<template>
  <div data-id-e2e="btnDuplicateRenderer">
    <v-btn
      data-id-e2e="btnDuplicateExtracts"
      class="duplicate-extracts__button"
      secondary
      depressed
      :disabled="isDisabled"
      @click="() => onRouteClick()"
    >
      {{ $t('actions.duplicate') }}
      <img
        class="duplicate-extracts__icon"
        :src="duplicateExtractIcon"
        alt="Duplicate extract icon"
      />
    </v-btn>
  </div>
</template>

<script>
import Vue from 'vue';
import { get } from 'lodash';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import duplicateExtractIcon from '@/img/duplicateExtract.svg';
import to from 'await-to-js';

export default Vue.extend({
  name: 'duplicateButtonRenderer',
  data() {
    return {
      duplicateExtractIcon,
    };
  },
  computed: {
    ...mapGetters('compoundExtract', ['getExtractById', 'getSettingsById']),

    extractId() {
      return get(this.params, 'extractId');
    },

    extract() {
      return this.getExtractById(this.extractId) || {};
    },

    isDisabled() {
      return !this.hasPermission(this.userPermissions.canDuplicateCompoundExtracts);
    },
  },

  methods: {
    ...mapActions('compoundExtract', ['duplicateExtract']),
    ...mapMutations('compoundExtract', ['setIsProcessing']),

    async onRouteClick() {
      this.setIsProcessing(true);
      const extractDuplication = {
        type: this.params.type,
        existingSeries: this.params.extractSeries,
        existingSettingsId: this.params.extractSettingsId,
        extractId: this.extractId,
      };

      const [err, newExtract] = await to(this.duplicateExtract(extractDuplication));
      this.setIsProcessing(false);
      if (err) return;

      this.$router.push({
        name: `${this.params.extractSeries.type}-extract`,
        params: {
          extractSeriesId: newExtract.series._id,
        },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

a {
  text-decoration: none;
}

::v-deep {
  .duplicate-extracts {
    &__button {
      height: 29px !important;
      padding: 0 12px !important;

      > span {
        font-size: 1.4rem;
      }
    }

    &__icon {
      font-size: 1.6rem;
      margin-left: 5px;
    }
  }

  .generating-row {
    .generating-text {
      margin: 0px 4px 0px 8px;
      font-size: 14px;
    }
  }
}
</style>
