import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isReportingEnabled && !_vm.shortView
    ? _c(
        "div",
        { staticClass: "section-footer d-flex justify-end pr-3 py-2" },
        [
          _vm.showNotImplemented
            ? _c(
                VBtn,
                { attrs: { primary: "", secondary: "", disabled: "" } },
                [
                  _vm._v(
                    "\n    " + _vm._s(_vm.$tkey("footer.downloadPdf")) + "\n  "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            VBtn,
            {
              attrs: {
                primary: "",
                depressed: "",
                disabled: _vm.isExportDisabled,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("export")
                },
              },
            },
            [
              _vm._v("\n    " + _vm._s(_vm.$t("actions.export")) + "\n    "),
              _c(VIcon, { attrs: { right: "" } }, [_vm._v("import_export")]),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }