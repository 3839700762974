'use strict';

const sizeTypes = require('./size-types');

module.exports = {
  [sizeTypes.linearSpace]: ['unitWidth'],
  [sizeTypes.cubicSpace]: ['unitWidth', 'unitLength', 'unitHeight'],
  [sizeTypes.horizontalSpace]: ['unitWidth', 'unitLength'],
  [sizeTypes.frontalSpace]: ['unitWidth', 'unitHeight'],
  [sizeTypes.productCount]: [],
};
