/*
  Check whether the user is idle, /login page if so. 
  We avoid logging the user out so that if they have multiple tabs open they are not logged out of all tabs
*/
import { get } from 'lodash';
import authConfig from '@sharedModules/config/staticAuth';
import store from './store';
import i18n from './vue-i18n';

let idleTimeout;
let messageIdleTimeout;

const clientConfig = store.state.context.clientConfig;
const durationAfterWarningSecs = get(clientConfig, 'idleTimeouts.durationAfterWarningSecs') || 5;
const idleDurationSecs = get(clientConfig, 'idleTimeouts.idleDurationSecs') || 43200;

// Force the user to the login page
const resetPage = () => {
  const loginPage = get(
    authConfig.authenticationPaths,
    `${store.state.context.currentLoginMethod}.login`,
    '/login'
  );
  window.location = loginPage;
};

// Show a message to the user, informing them that we'll log them out on inactivity.
const showIdleUserMessage = () => {
  // dont do anything if we are on the login page
  const loginPagePaths = Object.values(authConfig.authenticationPaths).map(v => v.login);
  if (loginPagePaths.includes(window.location.pathname)) return;

  messageIdleTimeout = setTimeout(resetPage, durationAfterWarningSecs * 1000);

  store.dispatch('snackbar/showWarning', i18n.t('notifications.idleTimeDetected'));
};

const resetIdleTimeout = () => {
  // Clears the existing timeouts
  if (idleTimeout) clearTimeout(idleTimeout);
  if (messageIdleTimeout) clearTimeout(messageIdleTimeout);

  // Set a new idle timeout to log out the user after idleDurationSecs
  idleTimeout = setTimeout(() => showIdleUserMessage(), idleDurationSecs * 1000);
};

// Init on page load
resetIdleTimeout();

// Reset the idle timeout on any of the events listed below (clicking on something, keyboard actions)
['click', 'touchstart', 'keydown'].forEach(evt =>
  document.addEventListener(evt, resetIdleTimeout, false)
);
