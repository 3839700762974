import Vue from 'vue';
import VueRouter from 'vue-router';
import authConfig from '@sharedModules/config/staticAuth';
import { forEach, get } from 'lodash';
import loadRoutes from './utils/vue-plugins/loadRoutes';
// import VueI18nMixin from './vue-i18n';
import store from './store/index';

const publicRoutes = ['/login', '/ow-auth/login', '/auth-saml/login'];

export const navigationGuards = {
  ensureAuthenticated: (to, from, next) => {
    // If not authenticated, navigate to the login page
    const { profile, expireAt } = store.state.context;
    const isTokenExpired = expireAt < Date.now();

    if (profile && isTokenExpired) {
      // if auth token is expired a refresh should be requested
      store.dispatch('context/refreshUserContext');
    }

    if (!profile && !publicRoutes.includes(to.path)) {
      console.log('No valid user session, client forced redirect to /login page.');
      const loginPage = get(
        authConfig.authenticationPaths,
        `${store.state.context.currentLoginMethod}.login`,
        '/login'
      );
      return next({ path: loginPage, query: { returnPath: to.path } });
    }

    return next();
  },
  ensureAuthorized: (to, from, next) => {
    // Here we ensure a user has the required permissions to access the "to" page
    if (
      to.meta.requiresPermission &&
      !store.getters['context/hasPermission'](to.meta.requiresPermission)
    ) {
      console.error('accessing page without required permissions');
      return next({ path: '/error/403' });
    }
    return next();
  },
  ensureEnabled: (to, from, next) => {
    // Here we ensure that the "to" page is enabled before allowing the user access
    const isEnabled = get(
      store.getters['context/getClientConfig'].features,
      to.meta.featureKey,
      true
    );
    if (!isEnabled) return next({ path: '/' });
    return next();
  },
  // Redirect to Vue 3 app if a part of Vue 3 app is enabled
  // This and all other navigation guards execute when the route changes
  redirectToVue3: (to, from, next) => {
    // if redirect is disabled, do not redirect
    if (!get(store.getters['context/getClientConfig'], 'vue3.redirectEnabled', false)) {
      return next();
    }

    const isVue3BundlesEnabled = get(
      store.getters['context/getClientConfig'],
      'vue3.bundles.enabled',
      false
    );
    const isVue3TemplatesEnabled = get(
      store.getters['context/getClientConfig'],
      'vue3.templates.enabled',
      false
    );
    const isVue3WorkpackagesEnabled = get(
      store.getters['context/getClientConfig'],
      'vue3.workpackages.enabled',
      false
    );
    const isVue3PowerBIReportingEnabled = get(
      store.getters['context/getClientConfig'],
      'vue3.powerbiReporting.enabled',
      false
    );
    const isVue3ReportingEnabled = get(
      store.getters['context/getClientConfig'],
      'vue3.reporting.enabled',
      false
    );
    const isVue3CompoundExtractsEnabled = get(
      store.getters['context/getClientConfig'],
      'vue3.compoundExtracts.enabled',
      false
    );
    const isVue3ClientAdminEnabled = get(
      store.getters['context/getClientConfig'],
      'vue3.clientAdmin.enabled',
      false
    );
    const isVue3OWAdminEnabled = get(
      store.getters['context/getClientConfig'],
      'vue3.OWAdmin.enabled',
      false
    );
    const vue3AppDomain = get(store.getters['context/getClientConfig'], 'vue3.domain');

    if (to.path === '/bundles' && isVue3BundlesEnabled) {
      window.location.href = `${vue3AppDomain}/bundles`;
    } else if (to.path === '/templates' && isVue3TemplatesEnabled) {
      window.location.href = `${vue3AppDomain}/templates`;
    } else if (to.path === '/home' && isVue3WorkpackagesEnabled) {
      window.location.href = `${vue3AppDomain}/home`;
    } else if (to.path === '/power-bi-reporting' && isVue3PowerBIReportingEnabled) {
      window.location.href = `${vue3AppDomain}/power-bi-reporting`;
    } else if (to.path === '/reporting-main' && isVue3ReportingEnabled) {
      window.location.href = `${vue3AppDomain}/reporting-main`;
    } else if (to.path === '/extracts' && isVue3CompoundExtractsEnabled) {
      window.location.href = `${vue3AppDomain}/extracts`;
    } else if (to.path === '/client-admin' && isVue3ClientAdminEnabled) {
      window.location.href = `${vue3AppDomain}/client-admin`;
    } else if (to.path === '/admin' && isVue3OWAdminEnabled) {
      window.location.href = `${vue3AppDomain}/admin`;
    } else {
      next();
    }
  },
};

// Import routes and build router
const routes = loadRoutes(Vue, require.context('./pages/', true, /\.router.js$/));
const router = new VueRouter({
  routes,
  linkActiveClass: 'active',
  mode: 'history',
});
forEach(navigationGuards, guard => router.beforeEach(guard));

// Update title to make history show properly
// https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
router.afterEach(to => {
  // Call the translation, binding the current context to the function
  let title = router.app.$options.methods.$tkey.bind(router.app)('general.appTitle', {
    fallback: 'Assortment',
  });

  // If the route has a title extension add it
  if (to.meta.titleExtension) {
    title += ` | ${router.app.$t(to.meta.titleExtension)}`;
  }

  // Add the scenario name if there is a scenario ID
  if (to.params.scenarioId) {
    const scenario = store.getters['scenarios/getScenarioById'](to.params.scenarioId);
    if (scenario) title += ` | ${scenario.name}`;
  }

  Vue.nextTick(() => {
    document.title = title;
  });
});

export default router;
