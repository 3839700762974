'use strict';

const DataTypes = require('../../../enums/data-types');

const fixedFields = [
  {
    mongoField: 'name',
    type: DataTypes.str,
    aliases: 'csvAliases.clustering.name',
    translationKey: 'mongoFields.scenarioClusterSchemes.name',
    required: true,
  },
  {
    mongoField: 'clusterName',
    type: DataTypes.str,
    aliases: 'csvAliases.clustering.clusterName',
    translationKey: 'mongoFields.scenarioClusterSchemes.clusterName',
    required: true,
  },
  {
    mongoField: 'storeKeyDisplay',
    type: DataTypes.str,
    aliases: 'csvAliases.clustering.storeKeyDisplay',
    translationKey: 'mongoFields.scenarioClusterSchemes.storeKey',
    required: true,
  },
];

module.exports = {
  fixedFields,
};
