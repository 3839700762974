<template>
  <v-container>
    <extract-report-generator
      :disabled="isGenerateExtractDisabled"
      @generate-extract-requested="generateReport"
    >
      <v-container class="report-jda d-flex flex-column pa-0 ma-0" fluid>
        <v-row no-gutters>
          <v-col colspan="6">
            <div
              v-if="isScenarioInputIncomplete && !missingAttributes.length"
              class="validation-message"
            >
              {{ $tkey('extractValidation.incompleteInputs') }}
            </div>
            <div v-if="missingAttributes.length" class="validation-message">
              {{ $tkey('extractValidation.missingAttributes') }}
              <ul>
                <li v-for="item in missingAttributes" :key="item">
                  {{ item }}
                </li>
              </ul>
              {{ $tkey('extractValidation.addAttributesInstruction') }}
            </div>
            <report-input-item class="mb-4" :title="$tkey('extractName')">
              <rtls-text-field
                v-model="extractName"
                :disabled="isEditingDisabled"
                grey
                :placeholder="$tkey('chooseAnExtractName')"
              />
            </report-input-item>
            <report-input-item v-if="requiredAttributesForExtract.length">
              <template v-if="usesPresentationCategoryId" v-slot:title>
                <div class="d-flex mb-2">
                  <span class="mr-1 font-weight-bold">{{ $tkey('category') }} </span>
                  <span> {{ $tkey('categoryHelperText') }}</span>
                </div>
              </template>
              <v-radio-group v-model="category" class="jda-radio-group">
                <v-radio v-for="item in categories" :key="item" :label="`${item}`" :value="item" />
              </v-radio-group>
            </report-input-item>
          </v-col>
          <v-col colspan="6" />
        </v-row>
      </v-container>
    </extract-report-generator>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex';
import { uniq, isEmpty, includes, difference, isEqual, size, get } from 'lodash';
import { extracts } from '@enums/client-events';
import sectionStatuses from '@enums/section-status';
import { STORE_EXECUTION } from '@enums/extract-types';

export default {
  localizationKey: 'extract.reports.storeExecutionPlanning.storeExecutionPlanningReport',
  data() {
    return {
      extractName: null,
      category: null,
      selectedAttribute: null,
      missingAttributes: [],
      categories: [],
      presentationCategoryAttrName: 'Presentation_Category',
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('scenarios', ['selectedScenario', 'selectedScenarioStatus']),
    ...mapState('context', ['clientConfig']),
    ...mapGetters('context', { dateFormats: 'getDateFormats' }),

    isGenerateExtractDisabled() {
      // Button "Generate Extract" enabled when PAE status is complete and all required attributes are specified
      return (
        this.isScenarioInputIncomplete ||
        !isEmpty(this.missingAttributes) ||
        !this.hasPermission(this.userPermissions.canEditProductStoreExtracts)
      );
    },

    isScenarioInputIncomplete() {
      return (
        this.selectedScenarioStatus(this.selectedWorkpackage.type).inputs.status !==
        sectionStatuses.complete
      );
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditStoreExecutionPlanning);
    },

    requiredAttributesForExtract() {
      return get(this.clientConfig, 'features.storeExecutions.requiredAttributes', []);
    },

    usesPresentationCategoryId() {
      return this.requiredAttributesForExtract.includes(this.presentationCategoryAttrName);
    },
  },

  created() {
    this.setSelectedStep(this.$options.name);
    this.validateRequiredAttributesForExtract();
  },

  events: {
    [extracts.generateExtractRequested]() {
      this.generateReport();
    },
  },

  methods: {
    ...mapMutations('extracts', ['setSelectedStep']),
    ...mapActions('scenarioProducts', ['fetchScenarioProducts']),
    ...mapActions('extracts', ['generateStoreExecutionsExtracts']),

    validateRequiredAttributesForExtract() {
      let presentationCategoryId;

      // Get active scenario required attribute list for extract
      const scenarioAttributesForExtract = this.selectedScenario.customAttributes
        .filter(attribute => {
          if (
            this.usesPresentationCategoryId &&
            attribute.name === this.presentationCategoryAttrName
          ) {
            presentationCategoryId = attribute.id;
          }
          return includes(this.requiredAttributesForExtract, attribute.name);
        })
        .map(attribute => attribute.name);

      // If active scenario has not all list of required attributes for extract, we searching for missing ones
      // Missing attribute list is used for validation message
      if (!isEqual(size(scenarioAttributesForExtract), size(this.requiredAttributesForExtract))) {
        this.missingAttributes = difference(
          this.requiredAttributesForExtract,
          scenarioAttributesForExtract
        );
      }

      if (isEmpty(this.missingAttributes)) {
        this.setCategories(presentationCategoryId);
      }
    },

    async setCategories(presentationCategoryId) {
      const scenarioProducts = await this.fetchScenarioProducts({
        params: { pick: ['customAttributes'] },
      });

      // Searching for all unique Presentation_Category attribute values
      scenarioProducts.forEach(product => {
        product.customAttributes.forEach(attribute => {
          if (attribute.id === presentationCategoryId && attribute.value) {
            this.categories.push(attribute.value);
          }
        });
      });

      this.categories = uniq(this.categories);

      if (this.categories.length) this.category = this.categories[0];
    },

    async generateReport() {
      await this.generateStoreExecutionsExtracts({
        filenameOnDownload:
          this.extractName ||
          this.$t('extract.reports.defaultExtractFileName', [
            moment().format(this.dateFormats.longWithTime),
          ]),
        extractType: STORE_EXECUTION,
        extractDatetime: moment.utc().toDate(),
        fileId: null,
        category: this.category,
        requiredAttributes: this.requiredAttributesForExtract,
      });
      this.globalEmit(extracts.extractGenerated);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.report-jda {
  .validation-message {
    color: $assortment-span-error-colour;
    font-weight: bold;
    font-size: 1.2rem;
  }

  .jda-radio-group {
    ::v-deep .v-input__control {
      width: 100%;
    }

    .v-radio {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      border: 1px solid $assortment-border-colour;
      border-radius: 3px;
      padding: 0 8px;
      margin-right: 0;

      &.v-item--active {
        border-color: $assortment-primary-colour;
      }

      ::v-deep {
        .v-label {
          width: 100%;
          color: black;
        }

        .v-input--selection-controls__input {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
