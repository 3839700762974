'use strict';

// Enums and helpers around jobs statuses (e.g. in progress / complete etc)

// Main lookup of job types. These are the job names as used in analytics
// Sync with analytics/analytics/constants.py
const scenarioJobTypes = {
  copyScenario: 'CopyScenario',
  spacebreakGenerator: 'SpacebreakGenerator',
  clusteredSwitching: 'ClusteredSwitching',
  cdtGeneration: 'CdtGeneration',
  prepareSwaps: 'PrepareSwaps',
  switching: 'Switching',
  canvasGeneration: 'CanvasGeneration',
  clScSwitchingMatricesCalculation: 'ClScSwitchingMatricesCalculation',
  clusteringGenerator: 'ClusteringGenerator',
  regionsByAttributeGenerator: 'RegionsByAttributeGenerator',
  simpleSwaps: 'SimpleSwaps',
  setupProductModelling: 'SetupProductModelling',
};

const workpackageJobTypes = {
  workpackageSetup: 'WorkpackageSetup',
  templateSetup: 'TemplateSetup',
  copyWorkpackage: 'CopyWorkpackage',
};

const jobTypes = {
  ...scenarioJobTypes,
  ...workpackageJobTypes,
  assortmentOptimisation: 'AssortmentOptimisation',
  extractsGeneration: 'ExtractsGeneration',
  storeExecutionExtracts: 'StoreExecutionExtracts',
  furnitureExtract: 'FurnitureExtract',
  compoundExtracts: 'CompoundExtract',
  forecaster: 'Forecaster',
};

// Basic job statuses -  as used by the job api
const jobStatuses = {
  // default status to which job is moved in the FE after is scheduled
  triaging: 'triaging',
  queued: 'queued',
  starting: 'starting',
  started: 'started',
  running: 'running',
  finishing: 'finishing',
  // means that job finished with success
  finished: 'finished',
  // job finished with errors
  failed: 'failed',
};

const argoJobStatusMappings = {
  Succeeded: jobStatuses.finished,
  Failed: jobStatuses.failed,
  Error: jobStatuses.failed,
};

const jobStatusesIconsMappings = {
  [jobStatuses.finished]: 'success',
  [jobStatuses.failed]: 'failed',
  [jobStatuses.running]: 'in-progress',
  [jobStatuses.queued]: 'in-progress',
  [jobStatuses.triaging]: 'in-progress',
};

// Job api uses multiple internal statuses - all of which can be considered in progress
const jobInProgressStatuses = [
  jobStatuses.queued,
  jobStatuses.starting,
  jobStatuses.started,
  jobStatuses.running,
  jobStatuses.finishing,
];

// Helper function to check a job satus against this mapping
const isJobInProgress = status => {
  return jobInProgressStatuses.includes(status);
};

// A simplified version of the job mapping statuses
const jobSimpleStatuses = {
  inProgress: 'in-progress',
  finished: 'finished',
  failed: 'failed',
};

// Helper function to check a job status against this simple mapping
const getJobStatusSimplified = status => {
  if (!status) return null;
  if (jobInProgressStatuses.includes(status)) return jobSimpleStatuses.inProgress;
  return status;
};

const jobFinishedStatuses = [jobStatuses.failed, jobStatuses.finished];

// See if job is complete without caring about status
const isJobFinishedOrFailed = status => {
  return jobFinishedStatuses.includes(status);
};

const jobStatusesNotificationColorMapping = {
  starting: 'info',
  finished: 'success',
  failed: 'error',
};

module.exports = {
  jobStatuses,
  jobFinishedStatuses,
  jobInProgressStatuses,
  allowedJobStatuses: Object.keys(jobStatuses),
  jobStatusesIconsMappings,
  jobStatusesNotificationColorMapping,
  jobTypes,
  scenarioJobTypes,
  workpackageJobTypes,
  isJobInProgress,
  jobSimpleStatuses,
  getJobStatusSimplified,
  isJobFinishedOrFailed,
  argoJobStatusMappings,
};
