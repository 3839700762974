const mixin = {
  methods: {
    prepareDataSet(data, headers) {
      const dataSet = [];
      for (let i = 0; i < data.length; i += 1) {
        const probabilities = [];
        const to = [];
        for (let j = 0; j < data[i].length; j += 1) {
          probabilities.push(data[i][j]);
          to.push(headers[j]);
        }
        dataSet.push({
          rowNumber: i,
          from: headers[i],
          to,
          probabilities,
        });
      }
      return dataSet;
    },
  },
};

export default mixin;
