<template>
  <v-btn class="icon-button" :disabled="!available" :to="tabRoute" replace>
    {{ text }}
    <v-icon>keyboard_arrow_right</v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    available: {
      required: false,
      type: Boolean,
      default: true,
    },
    tab: {
      required: true,
      type: String,
    },
    text: {
      required: true,
      type: String,
    },
    replace: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('scenarios', ['selectedScenario']),
    tabRoute() {
      const params = { scenarioId: this.selectedScenario._id };
      return { name: this.tab, params };
    },
  },
};
</script>
