<template>
  <v-row class="previous-extract-report-row">
    <v-col class="row-item font-weight-bold" cols="3">
      <div :title="report.filenameOnDownload" class="overflow-ellipsis">
        {{ report.filenameOnDownload }}
      </div>
    </v-col>
    <v-col class="row-item" cols="3">{{
      report.extractDatetime | formatDate(dateFormats.longWithTime)
    }}</v-col>
    <v-col class="row-item" cols="3">
      {{ $t(`extract.reports.reportTypes.${report.extractType}`) }}
    </v-col>
    <v-col class="row-item justify-center" cols="2">
      <v-icon size="18">{{ `$${report.status}` }}</v-icon>
    </v-col>
    <v-col class="row-item justify-center" cols="1">
      <download-link
        :filename="report.filenameOnDownload"
        :visible="isDownloadLinkVisible"
        :url="buildLink(report)"
        :disabled="isDownloadDisabled"
      >
        <v-btn :disabled="isDownloadDisabled" icon>
          <v-icon color="primary" size="18">
            mdi-download
          </v-icon>
        </v-btn>
      </download-link>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { jobStatuses } from '@enums/jobapi';
import { StoreNamespaces } from '../store/constants';

export default {
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statusFinished: jobStatuses.finished,
    };
  },
  computed: {
    ...mapGetters(StoreNamespaces.context, {
      dateFormats: 'getDateFormats',
    }),
    ...mapState('compoundExtract', ['selectedExtract']),
    isDownloadLinkVisible() {
      return this.statusFinished === this.report.originalJobStatus;
    },
    isDownloadDisabled() {
      return !this.hasPermission(this.userPermissions.canViewKasuExtracts);
    },
    extractSettingsId() {
      return this.selectedExtract.settings._id;
    },
  },
  methods: {
    buildLink(report) {
      return `/api/file/by-blob/${report.blobId}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.previous-extract-report-row {
  list-style: none;
  margin-bottom: 6px;
  background-color: $assortment-dialog-background;
}

::v-deep {
  a {
    text-decoration: none;
  }
}

.row-item {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding-bottom: 8px;
  padding-top: 8px;
}
</style>
