import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        VContainer,
        { staticClass: "pa-0 ma-0 switching-container d-flex flex-column" },
        [
          _c("scenario-measuring-header", {
            attrs: {
              "header-text": _vm.$tkey("infoNote"),
              "tooltip-link": _vm.tooltipLink,
              "show-job": _vm.jobType,
              disabled: _vm.isEditingDisabled,
            },
            on: { "trigger-job": _vm.triggerJob },
          }),
          _vm._v(" "),
          _vm.isJobComplete
            ? _c(
                VRow,
                {
                  staticClass: "cann-group-container flex-grow-1",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    VCol,
                    { attrs: { cols: 2 } },
                    [
                      _vm.isJobComplete
                        ? _c("cann-group-menu", {
                            attrs: {
                              "selected-cann-group": _vm.selectedCannGroup
                                ? _vm.selectedCannGroup.key
                                : null,
                            },
                            on: { onCannGroupSelected: _vm.selectCannGroup },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { attrs: { cols: 10 } },
                    [
                      _vm.isJobComplete
                        ? _c(
                            VContainer,
                            { staticClass: "ma-0 pa-0 controls" },
                            [
                              _c(
                                VRow,
                                {
                                  staticClass:
                                    "d-flex justify-start align-center",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "div",
                                    { attrs: { cols: 2 } },
                                    [
                                      _c("rtls-toggle", {
                                        attrs: {
                                          "left-toggle": _vm.toggle.leftToggle,
                                          "right-toggle":
                                            _vm.toggle.rightToggle,
                                        },
                                        model: {
                                          value: _vm.viewBySelection,
                                          callback: function ($$v) {
                                            _vm.viewBySelection = $$v
                                          },
                                          expression: "viewBySelection",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm.viewBySelection !== "product"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "attribute-level-title",
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$tkey(
                                                    "attributeLevelSwitching"
                                                  )
                                                ) + ":"
                                              ),
                                            ]),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    [
                                      _vm.viewBySelection !== "product"
                                        ? _c("rtls-select", {
                                            attrs: {
                                              items: _vm.scenarioAttributesList,
                                              width: "180px",
                                              placeholder:
                                                _vm.$tkey("selectAttribute"),
                                            },
                                            model: {
                                              value: _vm.selectedAttribute,
                                              callback: function ($$v) {
                                                _vm.selectedAttribute = $$v
                                              },
                                              expression: "selectedAttribute",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(VDivider),
                      _vm._v(" "),
                      _vm.isJobComplete
                        ? _c(
                            "div",
                            [
                              _vm.selectedCannGroup
                                ? _c("switching-matrix", {
                                    key: _vm.matrixKey,
                                    attrs: {
                                      "cann-group": _vm.selectedCannGroup,
                                      "matrix-options": _vm.matrixOptions,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isJobComplete && _vm.requiresAnalyticsText
            ? _c(
                VRow,
                [
                  !_vm.isAnyJobRunning
                    ? _c("div", { staticClass: "no-matrices" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.requiresAnalyticsText) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAnyJobRunning
                    ? _c("progress-bar", {
                        attrs: {
                          percentage: _vm.jobProgress,
                          message: _vm.$t("messages.inProgress"),
                          status: _vm.jobStatus,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-actions-container" },
            [
              _c("page-actions", {
                attrs: {
                  "show-export": "",
                  "export-options": _vm.exportOptions,
                  "save-disabled": _vm.isEditingDisabled,
                  "is-discard-enabled": !_vm.isEditingDisabled,
                  "is-export-enabled": _vm.isExportButtonEnabled,
                  "is-custom-export": _vm.viewBySelection === "attribute",
                  "show-save-button": false,
                  "show-discard": false,
                  "has-data-errors": true,
                  "has-data-changes": false,
                },
                on: {
                  productLevelExport: function ($event) {
                    return _vm.onExport(_vm.switchingReport.products)
                  },
                  topPartnersExport: function ($event) {
                    return _vm.onExport(_vm.switchingReport.topPartners)
                  },
                  export: function ($event) {
                    return _vm.onExport(_vm.switchingReport.attributes)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }