<template>
  <v-container class="management-panel-container">
    <v-row class="management-panel">
      <!-- furniture and space container -->
      <v-col data-id-e2e="panelBlockFurniture" class="management-panel-block --first">
        <v-container>
          <v-row>
            <h4 class="title-text">{{ $t('scenarioManagementPanel.furnitureAndSpaceTitle') }}</h4>
          </v-row>

          <!-- Furniture editor -->
          <v-row class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.furnitureEditor')"
              :tab="tab.FURNITURE_EDITOR_TAB"
              :available="tabPermissionsLookup[tab.FURNITURE_EDITOR_TAB]"
              class="white"
            />

            <span
              v-if="getScenarioStatus.space.furniture.available"
              :class="
                getScenarioStatus.space.furniture.status === sectionStatuses.complete
                  ? 'success-text'
                  : 'required-text'
              "
            >
              <strong
                >{{
                  $t('scenarioManagementPanel.status.furnitureCount', [
                    getScenarioStatus.space.furniture.furnitureCount,
                  ])
                }}
              </strong>
            </span>

            <last-updated :last-updated="getScenarioStatus.space.furniture.lastModifiedDate" />
          </v-row>

          <!-- Store mapping -->
          <v-row class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.storeMapping')"
              :tab="tab.FURNITURE_MAPPING_TAB"
              :available="
                getScenarioStatus.space.mapping.available &&
                  tabPermissionsLookup[tab.FURNITURE_MAPPING_TAB]
              "
              class="white"
            />

            <span
              v-if="getScenarioStatus.space.mapping.available"
              :class="
                getScenarioStatus.space.mapping.status === sectionStatuses.complete
                  ? 'success-text'
                  : 'required-text'
              "
            >
              <strong>
                {{
                  $t('scenarioManagementPanel.status.storesMapped', [
                    getScenarioStatus.space.mapping.storesMapped,
                    getScenarioStatus.space.mapping.storeCount,
                  ])
                }}
              </strong>
            </span>

            <last-updated :last-updated="getScenarioStatus.space.mapping.lastModifiedDate" />

            <section-requires
              v-if="!getScenarioStatus.space.mapping.available"
              :requires="$t('scenarioManagementPanel.furniture')"
            />
          </v-row>

          <!-- Store classes -->
          <v-row class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.storeClass')"
              :tab="tab.STORECLASS_DEFINITION_TAB"
              :available="
                getScenarioStatus.space.storeclass.available &&
                  tabPermissionsLookup[tab.STORECLASS_DEFINITION_TAB]
              "
              class="white"
            />

            <span
              v-if="
                getScenarioStatus.space.storeclass.available &&
                  getScenarioStatus.space.storeclass.storeclassCount > 0
              "
              :class="
                getScenarioStatus.space.storeclass.status === sectionStatuses.complete
                  ? 'success-text'
                  : 'required-text'
              "
            >
              <strong>
                {{
                  $t('scenarioManagementPanel.status.storeclassesFurniture', [
                    getScenarioStatus.space.storeclass.furnitureWithStoreClassCount,
                    getScenarioStatus.space.furniture.furnitureCount,
                  ])
                }}
              </strong>
            </span>
            <span
              v-if="getScenarioStatus.space.storeclass.available"
              :class="{ 'required-text': getScenarioStatus.space.storeclass.storeclassCount === 0 }"
            >
              <strong>
                {{
                  $t('scenarioManagementPanel.status.storeClassCount', [
                    getScenarioStatus.space.storeclass.storeclassCount,
                  ])
                }}
              </strong>
            </span>

            <last-updated :last-updated="getScenarioStatus.space.storeclass.lastModifiedDate" />

            <section-requires
              v-if="!getScenarioStatus.space.storeclass.available"
              :requires="$t('scenarioManagementPanel.storeMapping')"
            />
          </v-row>

          <!-- Spacebreaks -->
          <v-row v-if="isFullResetWP" class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.spacebreak')"
              :tab="tab.SPACEBREAK_CALCULATOR_TAB"
              :available="
                getScenarioStatus.space.spacebreaks.available &&
                  tabPermissionsLookup[tab.SPACEBREAK_CALCULATOR_TAB]
              "
              class="white"
            />

            <span
              v-if="getScenarioStatus.space.spacebreaks.status === sectionStatuses.complete"
              class="success-text"
            >
              <strong>{{ $t('scenarioManagementPanel.status.spacebreaksCompleted') }}</strong>
            </span>
            <span
              v-if="
                getScenarioStatus.space.spacebreaks.status !== sectionStatuses.complete &&
                  getScenarioStatus.space.spacebreaks.available
              "
              class="required-text"
            >
              <strong>{{ $t('scenarioManagementPanel.status.spacebreaksRequired') }}</strong>
            </span>

            <job-status :job="jobStatuses.spacebreakGenerator" />

            <last-updated :last-updated="getScenarioStatus.space.spacebreaks.lastModifiedDate" />

            <section-requires
              v-if="!getScenarioStatus.space.spacebreaks.available"
              :requires="$t('scenarioManagementPanel.storeClass')"
            />
          </v-row>

          <!-- Planograms -->
          <v-row v-if="isSimpleSwapsWP" class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.planograms')"
              :tab="tab.PLANOGRAM_SELECTION_TAB"
              :available="
                getScenarioStatus.space.planograms.available &&
                  tabPermissionsLookup[tab.PLANOGRAM_SELECTION_TAB]
              "
              class="white"
            />

            <span
              v-if="
                getScenarioStatus.space.planograms.available &&
                  getScenarioStatus.space.planograms.planogramCount > 0
              "
              :class="
                getScenarioStatus.space.planograms.status === sectionStatuses.complete
                  ? 'success-text'
                  : 'required-text'
              "
            >
              <strong>
                {{
                  $t('scenarioManagementPanel.status.planogramsSelectedCount', [
                    getScenarioStatus.space.planograms.planogramsSelectedCount,
                    getScenarioStatus.space.planograms.planogramCount,
                  ])
                }}
              </strong>
            </span>
            <span
              v-if="
                getScenarioStatus.space.planograms.available &&
                  getScenarioStatus.space.planograms.planogramCount === 0
              "
              class="required-text"
            >
              <strong>
                {{ $t('scenarioManagementPanel.status.planogramsRequired') }}
              </strong>
            </span>

            <last-updated :last-updated="getScenarioStatus.space.planograms.lastModifiedDate" />

            <section-requires
              v-if="!getScenarioStatus.space.planograms.available"
              :requires="$t('scenarioManagementPanel.storeClass')"
            />
          </v-row>
        </v-container>
      </v-col>
      <v-card class="pa-2" outlined tile />

      <!-- inputs container -->
      <v-col data-id-e2e="panelBlockInputs" class="management-panel-block">
        <v-container>
          <v-row>
            <h4 class="title-text">{{ $t('scenarios.inputs') }}</h4>
          </v-row>

          <!-- inputs - attributes -->
          <v-row class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.attributes')"
              :tab="tab.ATTRIBUTES_TAB"
              :available="tabPermissionsLookup[tab.ATTRIBUTES_TAB]"
            />

            <span
              :class="
                getScenarioStatus.inputs.attributeEditor.status === sectionStatuses.complete
                  ? 'success-text'
                  : 'required-text'
              "
            >
              <strong>
                {{
                  $t('scenarioManagementPanel.status.attributePercent', [
                    getScenarioStatus.inputs.attributeEditor.percentageComplete,
                  ])
                }}
              </strong>
            </span>
            <span>
              <strong>
                {{
                  $t('scenarioManagementPanel.status.attributeCount', [
                    getScenarioStatus.inputs.attributeEditor.productCount,
                    getScenarioStatus.inputs.attributeEditor.attributeCount,
                  ])
                }}
              </strong>
            </span>

            <last-updated
              :last-updated="getScenarioStatus.inputs.attributeEditor.lastModifiedDate"
            />
          </v-row>

          <!-- inputs - cann groups -->
          <v-row class="d-flex flex-column panel-item panel-item--middle">
            <section-nav-button
              :text="$t('scenarioManagementPanel.cann')"
              :tab="tab.CANN_GROUP_TAB"
              :available="
                getScenarioStatus.inputs.cannGroups.available &&
                  tabPermissionsLookup[tab.CANN_GROUP_TAB]
              "
            />

            <span
              v-if="getScenarioStatus.inputs.cannGroups.available"
              :class="
                getScenarioStatus.inputs.cannGroups.status === sectionStatuses.complete
                  ? 'success-text'
                  : 'required-text'
              "
            >
              <strong>
                {{
                  $t('scenarioManagementPanel.status.cannGroupsCount', [
                    getScenarioStatus.inputs.cannGroups.groupsCount,
                  ])
                }}
              </strong>
            </span>

            <last-updated :last-updated="getScenarioStatus.inputs.cannGroups.lastModifiedDate" />

            <section-requires
              v-if="!getScenarioStatus.inputs.cannGroups.available"
              :requires="$t('scenarioManagementPanel.attributes')"
            />
          </v-row>

          <!-- inputs - variety groups -->
          <v-row class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.variety')"
              :tab="tab.VARIETY_TAB"
              :available="
                getScenarioStatus.inputs.varietyGroups.available &&
                  tabPermissionsLookup[tab.VARIETY_TAB]
              "
            />

            <span
              v-if="getScenarioStatus.inputs.varietyGroups.available"
              :class="
                getScenarioStatus.inputs.varietyGroups.status === sectionStatuses.complete
                  ? 'success-text'
                  : 'required-text'
              "
            >
              <strong>
                {{
                  $t('scenarioManagementPanel.status.varietyGroupsSet', [
                    getScenarioStatus.inputs.varietyGroups.numberSet,
                    getScenarioStatus.inputs.cannGroups.groupsCount,
                  ])
                }}
              </strong>
            </span>

            <last-updated :last-updated="getScenarioStatus.inputs.varietyGroups.lastModifiedDate" />

            <section-requires
              v-if="!getScenarioStatus.inputs.varietyGroups.available"
              :requires="$t('scenarioManagementPanel.cann')"
            />
          </v-row>

          <!-- Prepare swaps -->
          <v-row v-if="isSimpleSwapsWP" class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.prepareSwaps')"
              :tab="tab.PREPARE_SWAPS_TAB"
              :available="
                getScenarioStatus.inputs.prepareSwaps.available &&
                  tabPermissionsLookup[tab.PREPARE_SWAPS_TAB]
              "
            />

            <span
              v-if="getScenarioStatus.inputs.prepareSwaps.status === sectionStatuses.complete"
              class="success-text"
            >
              <strong>{{ $t('scenarioManagementPanel.status.prepareSwapsCompleted') }}</strong>
            </span>
            <span
              v-if="
                getScenarioStatus.inputs.prepareSwaps.status !== sectionStatuses.complete &&
                  getScenarioStatus.inputs.prepareSwaps.available
              "
              class="required-text"
            >
              <strong>{{ $t('scenarioManagementPanel.status.prepareSwapsRequired') }}</strong>
            </span>

            <job-status :job="jobStatuses.prepareSwaps" />

            <section-requires
              v-if="!getScenarioStatus.inputs.prepareSwaps.available"
              :requires="$t('scenarioManagementPanel.variety')"
            />
          </v-row>
        </v-container>
      </v-col>

      <v-divider vertical />

      <!-- measuring container -->
      <v-col v-if="isFullResetWP" data-id-e2e="panelBlockMeasuring" class="management-panel-block">
        <v-container>
          <v-row>
            <h4 class="title-text">{{ $t('scenarios.measuring') }}</h4>
          </v-row>

          <!-- Switching -->
          <v-row class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.switching')"
              :tab="tab.SWITCHING_BEHAVIOUR_TAB"
              :available="
                getScenarioStatus.measuring.switching.available &&
                  tabPermissionsLookup[tab.SWITCHING_BEHAVIOUR_TAB]
              "
            />

            <span
              v-if="getScenarioStatus.measuring.switching.status === sectionStatuses.complete"
              class="success-text"
            >
              <strong>{{ $t('scenarioManagementPanel.status.switchingCompleted') }}</strong>
            </span>
            <span
              v-if="
                getScenarioStatus.measuring.switching.status !== sectionStatuses.complete &&
                  getScenarioStatus.measuring.switching.available
              "
              class="required-text"
            >
              <strong>{{ $t('scenarioManagementPanel.status.switchingRequired') }}</strong>
            </span>

            <job-status :job="jobStatuses.switching" />

            <section-requires
              v-if="!getScenarioStatus.measuring.switching.available"
              :requires="$t('scenarioManagementPanel.variety')"
            />
          </v-row>

          <!-- CDT -->
          <v-row class="d-flex flex-column panel-item panel-item--middle">
            <section-nav-button
              :text="$t('scenarioManagementPanel.cdt')"
              :tab="tab.CDT_TAB"
              :available="
                getScenarioStatus.measuring.cdt.available && tabPermissionsLookup[tab.CDT_TAB]
              "
            />

            <span
              v-if="getScenarioStatus.measuring.cdt.available"
              :class="
                getScenarioStatus.measuring.cdt.status === sectionStatuses.complete
                  ? 'success-text'
                  : 'required-text'
              "
            >
              <strong>
                {{
                  $t('scenarioManagementPanel.status.cannGroupsWithCdt', [
                    getScenarioStatus.measuring.cdt.numberSelected,
                    getScenarioStatus.inputs.cannGroups.groupsCount,
                  ])
                }}
              </strong>
            </span>

            <job-status :job="jobStatuses.cdtGeneration" />

            <last-updated :last-updated="getScenarioStatus.measuring.cdt.lastModifiedDate" />

            <section-requires
              v-if="!getScenarioStatus.measuring.cdt.available"
              :requires="$t('scenarioManagementPanel.switching')"
            />
          </v-row>

          <!-- Region -->
          <v-row v-if="hasRegionsByAttributeEnabled" class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.region')"
              :tab="tab.REGION_TAB"
              :available="
                getScenarioStatus.measuring.region.available && tabPermissionsLookup[tab.REGION_TAB]
              "
            />

            <job-status :job="jobStatuses.regionsByAttributeGenerator" />

            <last-updated :last-updated="getScenarioStatus.measuring.region.lastModifiedDate" />

            <section-requires
              v-if="!getScenarioStatus.measuring.region.available"
              :requires="$t('scenarioManagementPanel.cann')"
            />
          </v-row>

          <!-- Clustering -->
          <v-row class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.clustering')"
              :tab="tab.CLUSTERING_TAB"
              :available="
                getScenarioStatus.measuring.clustering.available &&
                  tabPermissionsLookup[tab.CLUSTERING_TAB]
              "
              replace
            />

            <span
              v-if="getScenarioStatus.measuring.clustering.available"
              :class="
                getScenarioStatus.measuring.clustering.status === sectionStatuses.complete
                  ? 'success-text'
                  : 'required-text'
              "
            >
              <strong v-if="getScenarioStatus.measuring.clustering.schemeSelected">
                {{
                  $t('scenarioManagementPanel.status.selectedClusterScheme', [
                    getScenarioStatus.measuring.clustering.schemeSelected,
                  ])
                }}
              </strong>
              <strong v-if="!getScenarioStatus.measuring.clustering.schemeSelected">
                {{ $t('scenarioManagementPanel.status.noClusterSelectedScheme') }}
              </strong>
            </span>

            <job-status
              :label="$t('scenarioManagementPanel.status.clusterGeneratorJob')"
              :job="jobStatuses.clusteringGenerator"
            />

            <job-status
              :label="$t('scenarioManagementPanel.status.clusterSwitchingJob')"
              :job="jobStatuses.clusteredSwitching"
            />

            <last-updated :last-updated="getScenarioStatus.measuring.clustering.lastModifiedDate" />

            <section-requires
              v-if="!getScenarioStatus.measuring.clustering.available"
              :requires="
                hasRegionsByAttributeEnabled
                  ? $t('scenarioManagementPanel.region')
                  : $t('scenarioManagementPanel.cann')
              "
            />
          </v-row>
        </v-container>
      </v-col>

      <!-- Swaps container -->
      <v-col v-if="isSimpleSwapsWP" data-id-e2e="panelBlockSwaps" class="management-panel-block">
        <v-container>
          <v-row>
            <h4 class="title-text">{{ $t('scenarios.swaps') }}</h4>
          </v-row>

          <!-- Products to swap -->
          <v-row class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.productsToSwap')"
              :tab="tab.PRODUCTS_TO_SWAP_TAB"
              :available="
                getScenarioStatus.swaps.productsToSwap.available &&
                  tabPermissionsLookup[tab.PRODUCTS_TO_SWAP_TAB]
              "
            />

            <span
              v-if="getScenarioStatus.swaps.productsToSwap.available"
              :class="
                getScenarioStatus.swaps.productsToSwap.status === sectionStatuses.complete
                  ? 'success-text'
                  : 'required-text'
              "
            >
              <strong>
                {{
                  $t('scenarioManagementPanel.status.productsCompletedCount', [
                    getScenarioStatus.swaps.productsToSwap.productsCompletedCount,
                    getScenarioStatus.swaps.productsToSwap.productCount,
                  ])
                }}
              </strong>
            </span>

            <last-updated :last-updated="getScenarioStatus.swaps.productsToSwap.lastModifiedDate" />

            <section-requires
              v-if="!getScenarioStatus.swaps.productsToSwap.available"
              :requires="$t('scenarioManagementPanel.storeClass')"
            />
          </v-row>
        </v-container>
      </v-col>

      <v-divider vertical />

      <!-- modelling container -->
      <v-col v-if="isFullResetWP" class="management-panel-block">
        <v-container>
          <v-row>
            <h4 class="title-text">{{ $t('scenarios.modelling') }}</h4>
          </v-row>

          <!-- Product modelling -->
          <v-row class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.productModelling')"
              :tab="tab.PRODUCT_MODELLING_TAB"
              :available="
                productModellingStatus.available && tabPermissionsLookup[tab.PRODUCT_MODELLING_TAB]
              "
            />

            <span
              v-if="productModellingStatus.available"
              :class="
                productModellingStatus.status === sectionStatuses.complete
                  ? 'success-text'
                  : 'required-text'
              "
            >
              <strong v-if="productModellingStatus.newProductsModelled > 0">
                {{
                  $t('scenarioManagementPanel.status.modelledFull', [
                    productModellingStatus.newProductsModelled,
                    productModellingStatus.productCount,
                    productModellingStatus.establishedProductCount,
                    productModellingStatus.childProductCount,
                  ])
                }}
              </strong>
              <strong v-if="productModellingStatus.newProductsModelled === 0">
                {{
                  $t('scenarioManagementPanel.status.modelled', [
                    productModellingStatus.newProductsModelled,
                    productModellingStatus.productCount,
                  ])
                }}
              </strong>
            </span>

            <last-updated :last-updated="productModellingStatus.lastModifiedDate" />

            <section-requires
              v-if="!productModellingStatus.available"
              :requires="$t('scenarioManagementPanel.cdt')"
            />
          </v-row>
          <v-row v-if="hasSwitchingModellingAvailable" class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.switchingModelling')"
              :tab="tab.SWITCHING_MODELLING_TAB"
              :available="
                switchingModellingStatus.available &&
                  tabPermissionsLookup[tab.SWITCHING_MODELLING_TAB]
              "
            />
          </v-row>
        </v-container>
      </v-col>

      <!-- extracts container -->
      <v-col v-if="isSimpleSwapsWP" class="management-panel-block">
        <v-container>
          <v-row>
            <h4 class="title-text">{{ $t('scenarios.extracts') }}</h4>
          </v-row>

          <!-- View reports -->
          <v-row class="d-flex flex-column panel-item">
            <section-nav-button
              :text="$t('scenarioManagementPanel.viewReports')"
              :tab="tab.EXTRACTS_TAB"
              :available="
                getScenarioStatus.extracts.viewReports.available &&
                  tabPermissionsLookup[tab.EXTRACTS_TAB]
              "
            />
          </v-row>
        </v-container>
      </v-col>
      <v-divider vertical />

      <!-- run container -->
      <v-col class="management-panel-block --last">
        <v-container>
          <!--
          <span>
            <b>{{ $t('scenarioManagementPanel.runSelectedItems') }}</b>
          </span>
          <span class="estimated-time">{{ $t('scenarioManagementPanel.estimatedTime') }}: ...</span>
          <v-btn action>{{ $t('actions.run') }}</v-btn>
          -->
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { get } from 'lodash';

import sectionStatuses from '@enums/section-status';
import userPermissions from '@enums/user-permissions';
import scenarioTab from '@/js/utils/tabs/scenario-tabs-enum';

export default {
  props: {
    scenario: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      tab: scenarioTab,
      sectionStatuses,
      userPermissions,
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('workpackages', ['isFullResetWP', 'isSimpleSwapsWP']),
    ...mapGetters('scenarios', ['selectedScenarioStatus', 'jobStatuses']),
    ...mapGetters('context', ['getDateFormats', 'getClientConfig', 'hasPermission']),

    getScenarioStatus() {
      return this.selectedScenarioStatus(this.selectedWorkpackage.type);
    },

    productModellingStatus() {
      return this.getScenarioStatus && get(this.getScenarioStatus, 'modelling.productModelling');
    },

    hasSwitchingModellingAvailable() {
      return get(this.getClientConfig, 'features.switchingModellingEnabled', false);
    },

    switchingModellingStatus() {
      return this.getScenarioStatus && get(this.getScenarioStatus, 'modelling.switchingModelling');
    },

    tabPermissionsLookup() {
      return {
        // Furniture
        [scenarioTab.FURNITURE_EDITOR_TAB]: this.hasPermission(
          userPermissions.canViewFurnitureEditorPage
        ),
        [scenarioTab.FURNITURE_MAPPING_TAB]: this.hasPermission(
          userPermissions.canViewFurnitureMappingPage
        ),
        [scenarioTab.STORECLASS_DEFINITION_TAB]: this.hasPermission(
          userPermissions.canViewStoreclassDefinitionPage
        ),
        [scenarioTab.SPACEBREAK_CALCULATOR_TAB]: this.hasPermission(
          userPermissions.canViewSpacebreakCalculatorPage
        ),
        [scenarioTab.PLANOGRAM_SELECTION_TAB]: this.hasPermission(
          userPermissions.canViewPlanogramSelectionPage
        ),

        // Inputs
        [scenarioTab.ATTRIBUTES_TAB]: this.hasPermission(
          userPermissions.canViewAttributeEditorPage
        ),
        [scenarioTab.CANN_GROUP_TAB]: this.hasPermission(userPermissions.canViewCannGroupPage),
        [scenarioTab.VARIETY_TAB]: this.hasPermission(userPermissions.canViewVarietyPage),
        [scenarioTab.PREPARE_SWAPS_TAB]: this.hasPermission(
          userPermissions.canViewPrepareSwapsPage
        ),

        // Measuuring
        [scenarioTab.SWITCHING_BEHAVIOUR_TAB]: this.hasPermission(
          userPermissions.canViewSwitchingBehaviourPage
        ),
        [scenarioTab.CDT_TAB]: this.hasPermission(userPermissions.canViewCDTPage),
        [scenarioTab.REGION_TAB]: this.hasPermission(userPermissions.canViewRegionPage),
        [scenarioTab.CLUSTERING_TAB]: this.hasPermission(userPermissions.canViewClusteringPage),

        // Swaps
        [scenarioTab.PRODUCTS_TO_SWAP_TAB]: this.hasPermission(userPermissions.canViewSwapsPage),

        // Modelling
        [scenarioTab.PRODUCT_MODELLING_TAB]: this.hasPermission(
          userPermissions.canViewModellingPage
        ),
        [scenarioTab.SWITCHING_MODELLING_TAB]: this.hasPermission(
          userPermissions.canViewSwitchingModellingPage
        ),

        // Simple swaps extracts
        [scenarioTab.EXTRACTS_TAB]: this.hasPermission(userPermissions.canViewExtractsPage),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.management-panel-container {
  margin: 0px !important;
  max-width: none;
  padding: 0px;
  .management-panel {
    background: white;
    margin: 0px;
    padding: 0px;
    .v-card {
      border: none;
      background: $assortment-background;
    }
    .v-divider--vertical {
      margin: 10px 0;
    }
    .management-panel-block {
      padding: 12px;
      margin: 0px;
      &.--first {
        background-color: #d9e8f4;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &.--last {
        // align items in the bottom of container
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding: 0px;
        max-width: 12%;

        .estimated-time {
          padding-bottom: 20px;
        }
      }
      span {
        padding-left: 7px;
        margin-top: 2px;
        font-size: 12px;
        display: block;
        white-space: nowrap;

        &.updated-text {
          font-size: 11px;
        }
        &.success-text {
          color: $assortment-positive-action-colour;
        }
        &.required-text {
          color: $assortment-negative-action-colour;
        }
        &.problem-text {
          color: $assortment-negative-action-colour;
        }
      }

      h4 {
        &.title-text {
          font-size: 13px;
          margin-left: 7px;
          margin-bottom: 2px;
        }
      }

      .icon-button {
        width: 100%;
        height: 30px !important;
        padding-right: 0;
        padding-left: 7px;
        margin: 10px auto;
        display: block;
        font-weight: bold;
        font-size: 1.2rem !important;
        box-shadow: none;
        border-radius: 0px;
        background-color: #d9e8f4;
        color: $assortment-primary-colour !important;
        justify-content: space-between;
        &.--white {
          background-color: white;
        }
        &.--grey {
          background-color: #dfe4e9;
          color: #4a4a4a !important;
        }
        &.v-btn:hover {
          background-color: #aecbe2;
        }
      }
      .v-btn__content {
        .v-icon {
          font-size: 30px;
        }
      }
    }
    .checkbox {
      margin-right: 5px;
      ::v-deep {
        .v-input--selection-controls__input {
          margin-right: 0px;
          height: auto !important;
          width: auto !important;
        }

        .v-input__slot {
          padding-bottom: 0;
        }
      }
    }

    .panel-item {
      margin-bottom: 5px;
    }
  }
}
</style>
