import { VForm } from 'vuetify/lib/components/VForm';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VForm,
    {
      ref: "form",
      staticClass: "main-expandable-panel panel-row d-flex incomplete",
      attrs: { autocomplete: "off" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("div", {
        staticClass: "panel-cell drag-cell flex-grow-0 flex-shrink-0",
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "panel-cell name-description pr-0" },
        [
          _c(
            "div",
            { staticClass: "top-container d-flex" },
            [
              _c("rtls-text-field", {
                key: _vm.nameKey,
                attrs: {
                  disabled: _vm.isEditingDisabled,
                  "data-id-e2e": "bundleName",
                  "data-value-e2e": _vm.model.name,
                  rules: [
                    _vm.required,
                    (v) =>
                      _vm.isNameUnique(
                        v,
                        _vm.bundle.name,
                        _vm.bundleNames,
                        _vm.$t("entities.bundle")
                      ),
                  ],
                },
                on: {
                  blur: _vm.saveModel,
                  keyup: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.saveModel.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      return _vm.resetModel("name")
                    },
                  ],
                },
                model: {
                  value: _vm.model.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.model,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "model.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("rtls-text-field", {
            key: _vm.descriptionKey,
            attrs: {
              "data-id-e2e": "bundleDescription",
              "data-value-e2e": _vm.model.description,
              disabled: _vm.isEditingDisabled,
            },
            on: {
              blur: _vm.saveModel,
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.saveModel.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _vm.resetModel("description")
                },
              ],
            },
            model: {
              value: _vm.model.description,
              callback: function ($$v) {
                _vm.$set(
                  _vm.model,
                  "description",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "model.description",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("section", {
        staticClass: "panel-cell align-start flex-column flex-grow-1",
      }),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "align-start go-live flex-column" },
        [
          _c("target-launch", {
            attrs: {
              "data-id-e2e": "bundleGoLive",
              disabled: _vm.isEditingDisabled,
              title: _vm.$t("bundlesPage.goLive"),
            },
            on: { input: _vm.saveModel },
            model: {
              value: _vm.model.targetLaunchDate,
              callback: function ($$v) {
                _vm.$set(_vm.model, "targetLaunchDate", $$v)
              },
              expression: "model.targetLaunchDate",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("section", { staticClass: "panel-cell align-start flex-column" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-center flex-end row justify-space-between",
          },
          [
            _c(
              "div",
              { staticClass: "icons" },
              [
                _c("notes", {
                  attrs: {
                    title: _vm.$t("notes.types.workpackage"),
                    disabled: !_vm.showNotImplemented,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.model.archived
              ? _c("bundle-option-unarchive", {
                  attrs: {
                    workpackage: _vm.model,
                    entity: _vm.entities.bundle,
                  },
                })
              : _c("bundle-option-archive", {
                  attrs: {
                    workpackage: _vm.model,
                    entity: _vm.entities.bundle,
                  },
                }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "panel-cell align-start flex-column" }, [
        _c("h4", { staticClass: "header" }, [
          _vm._v(_vm._s(_vm.$tkey("bundlesPage.creator"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex align-center flex-end row justify-end" },
          [_c("rtls-avatar", { attrs: { username: _vm.getName(_vm.bundle) } })],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "section",
        {
          staticClass:
            "panel-cell px-1 align-center justify-center flex-grow-0",
        },
        [_c("panel-menu-button", { attrs: { disabled: "" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "panel-cell px-1 expand align-center justify-center flex-grow-0 flex-shrink-0",
        },
        [
          _c("main-expand-button", {
            attrs: {
              disabled: _vm.isExpandDisabled,
              "is-expanded": _vm.isExpanded,
            },
            on: { expand: _vm.handleExpanded },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }