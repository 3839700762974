<template>
  <v-container>
    <v-row class="ma-0">
      <v-col class="d-flex justify-end pa-0">
        <v-btn
          data-id-e2e="btnCreateCheckpoint"
          :disabled="creatingCheckpoint || isEditingDisabled"
          primary
          @click="createCheckpoint"
        >
          {{ $t('actions.createCheckpoint') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="ma-0">
      <v-col cols="12" class="pa-0">
        <v-list class="checkpoints-list">
          <v-list-item
            v-for="checkpoint in checkpoints || []"
            :key="checkpoint._id"
            class="checkpoints-list__item"
          >
            <scenario-checkpoints-list-item
              :checkpoint-id="checkpoint._id"
              :remaining-checkpoints="getRemainingCheckpoints(checkpoint)"
              @close="$emit('close')"
            />
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('assortmentCanvas');

export default {
  data() {
    return {
      creatingCheckpoint: false,
    };
  },

  computed: {
    ...mapGetters(['checkpoints']),

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAssortmentCanvas);
    },
  },

  methods: {
    ...mapActions(['addCheckpoint', 'fetchCheckpoints']),

    async createCheckpoint() {
      this.creatingCheckpoint = true;
      await this.addCheckpoint();
      await this.fetchCheckpoints();
      this.creatingCheckpoint = false;
    },

    getRemainingCheckpoints(checkpoint) {
      return this.checkpoints.filter(cp => cp._id !== checkpoint._id);
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.checkpoints-list {
  &__item {
    font-size: 12px;
    margin: 10px auto;
    background: #e7edef;
    padding: 0px;
    border-top: 1px solid $assortment-secondary-colour;
  }
}
</style>
