<template>
  <aside data-id-e2e="transfer-price-settings">
    <h3>
      {{ $tkey('maintainOptionsLabel') }}
    </h3>
    <div class="d-flex mb-5 align-center">
      <rtls-select
        v-model="maintainValue"
        class="mr-2 d-flex flex-column"
        white
        :items="maintainOptions"
        :placeholder="$t('general.select')"
        hide-details
        width="200"
      />
      <v-btn
        class="d-flex flex-column"
        depressed
        secondary
        :disabled="!maintainValue"
        @click="emitUpdateMaintainValues"
        >{{ $tkey('applyPriceSetting') }}</v-btn
      >
    </div>
    <hr class="mb-2 separator" />
    <h3>
      {{ $tkey('transferPriceSettings') }}
    </h3>
    <div class="input-container">
      <div data-id-e2e="dateSwitching" class="date-selector">
        <span>{{ $tkey('dateRange') }}</span>
        <calender-range-picker
          :value="[...calendarDisplayDates]"
          :display-date-format="longDateFormat"
          :events="events"
          @input="changeTransferPriceDateRange"
        />
      </div>
      <span>
        <b>{{ syncTypeInfo }} </b>
        {{ selectedPeriodIds }}
      </span>
      <v-btn
        primary
        class="sync-btn"
        :loading="saveInProgress"
        max-width="100"
        @click="emitSyncFinancials"
        >{{ $t('general.sync') }}</v-btn
      >
      <span>
        <b>{{ $tkey('lastSynced') }}</b>
        {{ dateSelections.lastRefreshed | moment(getDateFormats.long) }}
      </span>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import { get, map, omit, size } from 'lodash';
import datesMixin from '@/js/mixins/date-utils';
import maintainOptionsEnum from '@enums/price-change-maintain-option';

export default {
  mixins: [datesMixin],
  props: {},
  localizationKey: 'scenarioInputPage.attributes.options',
  data() {
    return {
      updatedDateSelections: {
        transferPriceStartDate: null,
        transferPriceEndDate: null,
      },
      maintainValue: null,
    };
  },
  computed: {
    ...mapGetters('context', ['getDateFormats', 'getDefaultReverseFormat']),
    ...mapState('scenarios', ['selectedScenario', 'saveInProgress']),
    ...mapState('workpackages', ['selectedWorkpackage']),

    selectedPeriodIds() {
      const selectedPeriodIds = get(this.selectedWorkpackage, 'periodIds', []);
      return selectedPeriodIds.join(', ');
    },

    syncTypeInfo() {
      const selectedPeriodIds = get(this.selectedWorkpackage, 'periodIds');
      return size(selectedPeriodIds) && !selectedPeriodIds.includes(null)
        ? this.$tkey('selectedPeriodIds')
        : this.$tkey('noneSelected');
    },

    dateSelections() {
      /**
       * The dates displayed will be in this order depending on what is available:
       *    - The value selected by the user (Not saved in DB)
       *    - The value from the db
       *    - A year from the performance period start date
       */
      const transferPriceStartDate = this.formatDateForSave(
        this.updatedDateSelections.transferPriceStartDate ||
          get(this.selectedScenario, 'transferPriceRange.transferPriceStartDate', null) ||
          moment(this.selectedWorkpackage.performanceStartDate).add(1, 'y'),
        this.getDefaultReverseFormat
      );

      /**
       * The end dates displayed will be in this order depending on whats available:
       *    - The value selected by the user (Not saved in DB)
       *    - The value from the db
       *    - A year from the performance period end date
       */
      const transferPriceEndDate = this.formatDateForSave(
        this.updatedDateSelections.transferPriceEndDate ||
          get(
            this.selectedScenario,
            'transferPriceRange.transferPriceEndDate',
            moment(this.selectedWorkpackage.performanceEndDate).add(1, 'y')
          ),
        this.getDefaultReverseFormat
      );

      const lastRefreshed = get(
        this.selectedScenario,
        'transferPriceRange.lastRefreshed',
        this.selectedScenario.creationDate
      );
      // Add the defaults to the workpackage
      return {
        transferPriceStartDate,
        transferPriceEndDate,
        lastRefreshed,
      };
    },

    maintainOptions() {
      // Allow all maintain options except for the 'manuallySet' option, as this should be a one-by-one decision of a user.
      const validMaintainOptions = omit(maintainOptionsEnum, 'manuallySet');
      return map(validMaintainOptions, (_, enumKey) => {
        return {
          text: this.$t(`scenarioInputPage.attributes.maintainOptions.${enumKey}`),
          value: enumKey,
        };
      });
    },

    calendarDisplayDates() {
      let dates = [
        this.dateSelections.transferPriceStartDate,
        this.dateSelections.transferPriceEndDate,
      ];
      // When there is an update not all dates are always displayed - but the default needs to be shown on load still
      if (this.updatedDateSelections.transferPriceStartDate) {
        dates = this.updatedDateSelections.transferPriceEndDate
          ? [
              this.updatedDateSelections.transferPriceStartDate,
              this.updatedDateSelections.transferPriceEndDate,
            ]
          : [this.updatedDateSelections.transferPriceStartDate];
      }

      return this.formatDatesForPicker(dates, this.getDefaultReverseFormat);
    },

    longDateFormat() {
      return this.getDateFormats.long;
    },

    events() {
      return this.selectedWorkpackage.targetLaunchDate
        ? [
            this.formatDateForPicker(
              this.selectedWorkpackage.targetLaunchDate,
              this.getDefaultReverseFormat
            ),
          ]
        : null;
    },
  },
  methods: {
    changeTransferPriceDateRange(dates) {
      if (!dates) return;
      const [transferPriceStartDate, transferPriceEndDate] = dates;
      this.updatedDateSelections = {
        ...this.updatedDateSelections,
        transferPriceStartDate,
        transferPriceEndDate,
      };
    },

    emitSyncFinancials() {
      this.$emit('sync-financials', this.dateSelections);
    },

    emitUpdateMaintainValues() {
      this.$emit('update-maintain', maintainOptionsEnum[this.maintainValue]);
    },
  },
};
</script>

<style lang="scss" scoped>
.aside {
  display: flex;
  flex-direction: column;
}
.input-container {
  display: flex;
  flex-direction: column;
}

.date-selector {
  margin-bottom: 1.5rem;
  span {
    margin-bottom: 0.25rem;
  }
}

h3 {
  margin-bottom: 0.5rem;
}

.sync-btn {
  margin-bottom: 0.5rem;
}
</style>
