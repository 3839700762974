import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "assortment-groups d-flex flex-column align-start",
      staticStyle: { width: "200px" },
    },
    [
      _c(
        "h4",
        {
          staticClass: "title text-truncate",
          staticStyle: { width: "100%" },
          attrs: { title: _vm.firstGroup.name },
        },
        [_vm._v("\n    " + _vm._s(_vm.firstGroup.name) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.secondGroup
        ? _c(
            "h4",
            {
              staticClass: "title text-truncate",
              attrs: { title: _vm.secondGroup.name },
            },
            [_vm._v("\n    " + _vm._s(_vm.secondGroup.name) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.moreGroups.length
        ? _c(
            VTooltip,
            {
              attrs: { "content-class": "rtls-tooltip", bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c("span", _vm._g({ staticClass: "tooltip" }, on), [
                          _vm._v(_vm._s(_vm.tooltip)),
                        ]),
                      ]
                    },
                  },
                ],
                null,
                false,
                3780422547
              ),
            },
            [
              _vm._v(" "),
              _vm._l(_vm.groups, function (group, index) {
                return _c("span", { key: index }, [_vm._v(_vm._s(group.name))])
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }