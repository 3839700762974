'use strict';

/**
 * Import sources for product's attributes.
 * @enum {string}
 */
module.exports = {
  /** Add attribute from uploaded CSV file */
  CSV: 'csv',
  /** Add attribute from tooldata-attributes data */
  FEED: 'feed',
  /** Manually add and fill in a new attribute */
  CUSTOM: 'custom',
  /** Add attribute from another scenario custom attribute data */
  SCENARIO: 'scenario',
  /** Add attribute from planogram (furniture) data */
  PLANOGRAM: 'planogram',
};
