<template>
  <div v-if="hasTag" class="d-flex product-tag">
    <span>
      {{ tagName }}
    </span>
  </div>
  <div v-else class="w-100" />
</template>

<script>
import { isEmpty } from 'lodash';

export default {
  localizationKey: 'assortmentCanvasPage.productTag',

  props: {
    tagText: {
      type: String,
      required: true,
    },
    fullBorder: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasTag() {
      return !isEmpty(this.tagText);
    },

    tagName() {
      return this.$tkey(this.tagText).toLocaleUpperCase();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.product-tag {
  background-color: $assortment-tag-bg-colour;
  min-width: 25px;
  border-bottom: 0;
  height: 100%;

  & > span {
    width: 100%;
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
    color: $product-icons-colour;
    align-self: center;
  }
}
</style>
