import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "wp-scope-filters col-box" },
    [
      _c("div", { staticClass: "wp-scope-filters__header mb-1" }, [
        _c(
          "h3",
          { staticClass: "wp-scope-filters__input-heading" },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t(
                    "workpackagePage.splitWorkpackages.splitWorkpackagesTitle"
                  )
                ) +
                "\n\n      "
            ),
            _vm._v(" "),
            _c("assortment-tooltip", {
              attrs: {
                title: _vm.$t("tooltips.filterSplitWorkpackages.title"),
                "tooltip-sections": _vm.filterSplitWorkpackagesSections,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        VRow,
        [
          _c(
            VCol,
            { staticClass: "py-2 px-0 filter-cols", attrs: { cols: "12" } },
            [
              _c(
                "div",
                { staticClass: "filter-main" },
                [
                  _c("wp-scope-filters-chain", {
                    attrs: { "wp-furniture": _vm.wpFurniture },
                    on: { updateMainFilterObject: _vm.updateMainFilterObject },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            VCol,
            { staticClass: "py-2 px-0 filter-cols", attrs: { cols: "12" } },
            [
              _c(
                "div",
                { staticClass: "filter-main" },
                [
                  _c("wp-scope-filters-assortment-role", {
                    attrs: { "wp-furniture": _vm.wpFurniture },
                    on: { updateMainFilterObject: _vm.updateMainFilterObject },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            VCol,
            { staticClass: "py-2 px-0 filter-cols", attrs: { cols: "12" } },
            [
              _c(
                "div",
                { staticClass: "filter-main" },
                [
                  _c("wp-scope-filters-display-group", {
                    attrs: { products: _vm.products },
                    on: { updateMainFilterObject: _vm.updateMainFilterObject },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            VCol,
            { staticClass: "py-2 px-0 filter-cols", attrs: { cols: "12" } },
            [
              _c(
                "div",
                { staticClass: "filter-main" },
                [
                  _c("wp-scope-filters-cdt-select", {
                    attrs: { products: _vm.products },
                    on: { updateMainFilterObject: _vm.updateMainFilterObject },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        { staticClass: "footer-panel" },
        [
          _c(
            VCol,
            {
              staticClass: "d-flex justify-end filter-apply",
              attrs: { colspan: "12" },
            },
            [
              _c(
                VBtn,
                {
                  attrs: {
                    "data-id-e2e": "btnFilterSplitWorkpackages",
                    depressed: "",
                    primary: "",
                    "min-width": "65",
                    small: "",
                    loading: _vm.isSaving || _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.filterSplitWorkpackages()
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t(
                          "workpackagePage.splitWorkpackages.splitWorkpackagesSave"
                        )
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("dependency-tree-feedback-modal", {
        attrs: {
          value: _vm.dependencyTreeModalOpen,
          results: _vm.dependencyTreeFeedback,
          page: "workpackageSetup",
        },
        on: {
          close: _vm.closeDependencyTreeModal,
          commit: function ($event) {
            return _vm.filterSplitWorkpackages(true)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }