import axios from 'axios';
import to from 'await-to-js';

const store = {
  namespaced: true,

  state: {
    userNotifications: [],
    loading: false,
  },

  mutations: {
    setUserNotifications(state, userNotifications) {
      state.userNotifications = userNotifications;
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },

  actions: {
    async fetchUserNotifications({ commit, rootState }, { params = {} } = {}) {
      if (!rootState.context.profile) return;
      commit('setLoading', true);
      const [err, { data: userNotifications } = {}] = await to(
        axios.get('/api/user-notifications/user', { params })
      );
      if (err) throw new Error(err.message);
      commit('setUserNotifications', userNotifications);
      commit('setLoading', false);
    },

    async updateUserNotifications({ commit, dispatch }, { updates = {} } = {}) {
      commit('setLoading', true);
      const [err] = await to(axios.patch('/api/user-notifications', updates));
      if (err) throw new Error(err.message);
      dispatch('fetchUserNotifications');
    },
  },
};

export default store;
