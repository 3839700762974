import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "status-scope d-flex flex-column justify-start" },
    [
      _c(
        "div",
        {
          staticClass:
            "scope-info d-flex flex-column justify-center align-start",
        },
        [
          _c("h4", { staticClass: "header" }, [
            _vm._v(_vm._s(_vm.$t("workpackagePage.lastModified"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "setup-container d-flex align-center justify-space-between",
            },
            [
              _c("span", { staticClass: "date" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("formatDate")(
                      _vm.date,
                      _vm.getDateFormats.longWithTimeAndMeridiem
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "setup-icons d-flex align-center justify-space-between",
                },
                [
                  _c(VIcon, { attrs: { size: "20" } }, [
                    _vm._v(_vm._s(_vm.status ? `$${_vm.status}` : "")),
                  ]),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        icon: "",
                        text: "",
                        disabled: _vm.disabled,
                        loading: _vm.isLoading,
                      },
                      on: { click: _vm.handleScope },
                    },
                    [
                      _c(VIcon, { attrs: { size: "20" } }, [
                        _vm._v("$target"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("notes", {
                    attrs: {
                      notes: _vm.getAllNotes,
                      loading: _vm.isNotesLoading,
                      title: _vm.$t("notes.types.workpackage"),
                      "max-chars": _vm.getClientConfig.notesSettings.maxLength,
                      notifications: _vm.hasNotes,
                    },
                    on: {
                      post: _vm.postNewNote,
                      menuOpen: _vm.fetchAllNotes,
                      delete: _vm.deleteCurrentNote,
                      update: _vm.updateCurrentNote,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }