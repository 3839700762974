<template>
  <section class="workpackage d-flex flex-column">
    <div
      v-if="!visible"
      v-observe-visibility="{ callback: visibilityChanged, once: true }"
      class="loading-wrapper"
    >
      <div class="panel-cell drag-cell flex-grow-0 flex-shrink-0" />
      <div class="loading-box pl-3">
        <v-progress-circular indeterminate class="mr-3" size="20" />
        <span>{{ $t('general.loading') }}</span>
      </div>
    </div>
    <component
      :is="`${entity}-card`"
      v-else
      :id="`wp-panel-${workpackage._id}`"
      :item="workpackage"
      :is-link="isLink"
    />
  </section>
</template>

<script>
export default {
  props: {
    workpackage: {
      type: Object,
      required: true,
    },

    entity: {
      type: String,
      required: false,
      default: '',
    },

    isLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  methods: {
    visibilityChanged(isVisible) {
      this.visible = isVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.loading-wrapper {
  height: 60px;
  background: $assortment-panel-background;
  display: flex;
}

.loading-box {
  align-items: center;
  display: flex;
  font-size: 1.1rem;
  color: $assortment-greyed-out-colour;

  span {
    color: $assortment-text-colour;
  }
}
</style>
