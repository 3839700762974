import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        VRow,
        { staticClass: "region__header ma-0 flex-grow-0" },
        [
          _c(
            VCol,
            { staticClass: "d-flex align-center" },
            [
              _c("span", { staticClass: "info-note" }, [
                _vm._v(_vm._s(_vm.$t("region.useRegions"))),
              ]),
              _vm._v(" "),
              _c("docs-link", {
                attrs: { link: "toolguide/140-regionalbrands.html" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VContainer,
        {
          staticClass: "pa-0 flex-grow-1 d-flex flex-column",
          attrs: { fluid: "" },
        },
        [
          _c(
            VRow,
            { staticClass: "flex-grow-0" },
            [
              _c(
                VCol,
                { staticClass: "actions-col d-flex justify-end align-center" },
                [
                  _c("rtls-select", {
                    staticClass: "mr-2",
                    attrs: {
                      items: _vm.attributes,
                      placeholder: _vm.$tkey("attributeSelectionPlaceholder"),
                      width: "240px",
                      "item-text": "name",
                      "item-value": "name",
                    },
                    model: {
                      value: _vm.selectedAttribute,
                      callback: function ($$v) {
                        _vm.selectedAttribute = $$v
                      },
                      expression: "selectedAttribute",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      staticClass: "m-2",
                      attrs: {
                        id: "btnGenerateRegions",
                        primary: "",
                        "data-id-e2e": "btnGenerateRegions",
                        disabled: _vm.isGenerateRegionsDisabled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.generateRegions(false)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("region.generateRegions")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(VDivider),
          _vm._v(" "),
          _c(
            VRow,
            {
              staticClass: "flex-grow-1",
              class: {
                "align-content-center":
                  _vm.isGenerateRegionsByAttributeJobRunning,
              },
            },
            [
              _vm.isGenerateRegionsByAttributeJobRunning
                ? _c("progress-bar", {
                    staticClass: "mb-5",
                    attrs: {
                      message: _vm.$t("messages.inProgress"),
                      percentage:
                        _vm.jobStatuses.regionsByAttributeGenerator.progress,
                      status:
                        _vm.jobStatuses.regionsByAttributeGenerator.status,
                    },
                  })
                : _c("ag-grid-vue", {
                    staticClass: "ag-theme-custom",
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: {
                      "column-defs": _vm.columnDefs,
                      "row-data": _vm.regionData,
                      "grid-options": _vm.gridOptions,
                      "stop-editing-when-cells-loses-focus": "",
                    },
                    on: {
                      "cell-value-changed": _vm.trackDiff,
                      "grid-ready": _vm.onGridReady,
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-actions-container" },
        [
          _c("page-actions", {
            attrs: {
              "save-disabled": _vm.isEditingDisabled || _vm.loading,
              "is-discard-enabled": !_vm.isEditingDisabled && !_vm.loading,
              "has-data-errors": false,
              "has-data-changes": _vm.hasDataChanges,
            },
            on: {
              discard: _vm.discard,
              save: function ($event) {
                return _vm.onSave(false)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "right-btns",
                fn: function () {
                  return [
                    _c(
                      VBtn,
                      {
                        staticClass: "text-outline",
                        attrs: {
                          secondary: "",
                          depressed: "",
                          disabled:
                            _vm.hasSkippedRegion ||
                            _vm.isEditingDisabled ||
                            _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.skipRegions(false)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("actions.skipRegion")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("dependency-tree-feedback-modal", {
            attrs: {
              value: _vm.dependencyTreeModalOpen,
              results: _vm.dependencyTreeFeedback,
              page: "region",
            },
            on: {
              close: _vm.closeDependencyTreeModal,
              commit: _vm.commitHandler,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }