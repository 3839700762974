<template>
  <div v-if="isReportingEnabled && !shortView" class="section-footer d-flex justify-end pr-3 py-2">
    <v-btn v-if="showNotImplemented" primary secondary disabled>
      {{ $tkey('footer.downloadPdf') }}
    </v-btn>
    <v-btn primary depressed :disabled="isExportDisabled" @click="$emit('export')">
      {{ $t('actions.export') }}
      <v-icon right>import_export</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  localizationKey: 'reportingPage.sections',

  props: {
    // represents whether this report is open on (short) overview page
    shortView: {
      type: Boolean,
      required: false,
      default: false,
    },
    isExportDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('context', ['showNotImplemented']),

    isReportingEnabled() {
      return this.hasPermission(this.userPermissions.canViewReportingPage);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.section-footer {
  border-top: 1px solid $reporting-section-divider;
}
</style>
