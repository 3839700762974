<template>
  <switching-behaviour
    :requires-analytics-text="$tkey('requiresAnalyticsRun')"
    :job-type="prepareSwapsJob"
    @run-prepare-swaps="runPrepareSwapsJob"
  />
</template>

<script>
import { jobTypes } from '@enums/jobapi';
import { mapActions, mapState } from 'vuex';

export default {
  localizationKey: 'prepareSwaps',
  data() {
    return {
      prepareSwapsJob: jobTypes.prepareSwaps,
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
  },

  methods: {
    ...mapActions('scenarios', ['loadScenario', 'prepareSwaps']),

    async runPrepareSwapsJob() {
      await this.prepareSwaps();
      await this.refreshSelectedScenario();
    },

    async refreshSelectedScenario() {
      await this.loadScenario(this.selectedScenario._id);
    },
  },
};
</script>
