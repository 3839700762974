<template>
  <div v-if="datesLoaded" class="dates-selection pa-2">
    <v-row no-gutters class="pb-2">
      <v-col class="pr-4 d-flex">
        <div>
          <h3 class="dates-selection__input-heading">
            {{ $tkey('snapshotDateLabel') }}

            <!-- Snapshot date tooltip -->
            <assortment-tooltip
              :title="$t('tooltips.snapshotDate.title')"
              :tooltip-sections="snapshotDateTooltipSections"
            />
          </h3>
          <div data-id-e2e="dateSnapshot" class="dates-selection__input">
            <rtls-calendar-picker
              :key="nameKey"
              :value="dateSelections.snapshotDate"
              :allowed-dates="allowedSnapshotDates"
              :display-date-format="longDateFormat"
              @input="changeSnapshotDate"
            />
          </div>
        </div>
        <div v-if="hasPeriodIdsEnabled" class="ml-8">
          <h3 class="dates-selection__input-heading mb-2">
            {{ $tkey('assortmentPeriodNumbers') }}
          </h3>
          <v-autocomplete
            class="rtls-select--multi-select"
            small-chips
            multiple
            hide-details
            :value="periodIds"
            :items="parsedPeriodIdsOptions"
            item-text="text"
            item-value="value"
            :style="{ width: '280px' }"
            :placeholder="$t('general.select')"
            :menu-props="{ dense: true, contentClass: 'rtls-multi-select-list' }"
            @change="onPeriodIdsChange"
          >
            <template v-slot:selection="{ item, index, parent }">
              <v-chip v-if="index === 0 || index === 1">
                <div class="overflow-container">
                  <span>{{ item.text }}</span>
                  <!-- ToDo replace with deletable-chips/closable-chips once we update Vuetify -->
                  <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                </div>
              </v-chip>
              <span v-if="index === 2" class="grey--text caption">
                (+{{ periodIds.length - 2 }})
              </span>
            </template>
          </v-autocomplete>
        </div>
      </v-col>
      <v-col class="pr-4">
        <!-- fill items -->
        <div>
          <h3 class="dates-selection__input-heading">
            {{ $tkey('fillLabel') }}

            <!-- Fill according to tooltip -->
            <assortment-tooltip
              :title="$t('tooltips.fillAccordingTo.title')"
              :tooltip-sections="fillAccordingToTooltipSections"
            />
          </h3>
          <v-select
            :value="fillInSelection"
            :placeholder="$t('sizeTypes.noOption')"
            :items="sizeSelectItems"
            :append-outer-icon="sizeTypeIconMap[fillInSelection] || '$empty-cube'"
            :style="{ width: '280px', 'max-width': '280px' }"
            append-icon="expand_more"
            hide-details
            class="rtls-select"
            @change="changeFillInSelection"
          />
        </div>
        <!-- end fill items -->
      </v-col>
      <v-col>
        <div v-if="bundlesEnabled && workpackage.bundleId && workpackage.templateId">
          <h3 class="dates-selection__input-heading">
            {{ $t('bundlesPage.goLive') }}

            <!-- Go live date tooltip -->
            <assortment-tooltip
              :title="$t('tooltips.targetLaunchDate.title')"
              :tooltip-sections="targetLaunchDateTooltipSections"
            />
          </h3>
          <div data-id-e2e="targetLaunchDate" class="dates-selection__input">
            <rtls-calendar-picker
              :key="nameKey"
              :value="dateSelections.targetLaunchDate"
              :display-date-format="longDateFormat"
              @input="changeTargetLaunchDateDate"
            />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <v-row no-gutters>
      <v-col class="pr-4">
        <v-card class="col-box">
          <!-- performance date -->
          <h3 class="mt-3 dates-selection__input-heading">
            {{ $tkey('performancePeriodLabel') }}
            <span>{{ $tkey('performancePeriodLabelWeeks') }}</span>

            <!-- Performance period tooltip -->
            <assortment-tooltip
              :title="$t('tooltips.performancePeriod.title')"
              :tooltip-sections="performancePeriodTooltipSections"
            />
          </h3>
          <div class="dates-selection__input-container">
            <div
              data-id-e2e="datePerformance"
              class="dates-selection__input dates-selection__input--longer"
            >
              <span>{{ $tkey('startLabel') }}:</span>
              <rtls-calendar-picker
                :key="nameKey"
                :value="dateSelections.performanceStartDate"
                :allowed-dates="allowedStartAndExcludedDates"
                :display-date-format="longDateFormat"
                @input="changePerformanceStartDate"
              />
            </div>
            <span class="mr-0">
              {{ $tkey('endLabel') }}:
              <v-tooltip
                :value="performanceEndDateWasReduced"
                :disabled="!performanceEndDateWasReduced"
                right
              >
                <template v-slot:activator="{ on }">
                  <b v-on="on">
                    {{ calculatePerformanceEndDate | moment(getDateFormats.long) }}
                  </b>
                </template>
                <span v-if="performanceEndDateWasReduced">{{ $tkey('maxWeekWarning') }}</span>
              </v-tooltip>
            </span>
          </div>
          <!-- end performance date -->
          <v-row data-id-e2e="btnPerformanceWeeks" no-gutters>
            <v-col class="pb-1 col-9">
              <v-btn-toggle
                :value="dateSelections.performanceDateWeeks"
                mandatory
                @change="changePerformanceDateWeeks"
              >
                <v-btn
                  v-for="(el, index) in performanceWeekOptions"
                  :key="`options-${index}`"
                  class="dates-selection__group-button"
                  :value="el.value"
                >
                  {{ el.label }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col class="col-3">
              <v-text-field
                v-if="hasCustomPerformanceDateWeeks"
                :value="dateSelections.customPerformanceDateWeeks"
                single-line
                class="pa-0 ma-0 ml-4 dates-selection__input--custom"
                :placeholder="$tkey('custom')"
                @change="
                  [
                    () => {
                      performanceEndDateWasReduced = false;
                    },
                    changePerformanceDateWeeks($event),
                  ]
                "
              />
            </v-col>
          </v-row>
        </v-card>

        <!-- excluding date -->
        <v-card class="pa-2 pl-0 col-box no-border">
          <div data-id-e2e="dateExclude" class="dates-selection__exclude-container">
            <h3 class="dates-selection__input-heading">{{ $tkey('excludingLabel') }}:</h3>
            <multiple-dates-selector
              :value="dateSelections.performanceExcludedDates"
              :allowed-dates="allowedStartAndExcludedDates"
              @input="updatePerformanceExcludedDates"
            />
          </div>
        </v-card>
        <!-- end excluding date -->
      </v-col>
      <v-col v-if="hasSeasonalityEnabled && !isSimpleSwapsWP" class="pr-4">
        <!-- learning period-->
        <v-card class="col-box">
          <!-- learning date -->
          <h3 class="mt-3 dates-selection__input-heading">
            {{ $tkey('learningPeriodLabel') }}
            <span>{{ $tkey('learningPeriodLabelWeeks') }}</span>

            <!-- Learning period tooltip -->
            <assortment-tooltip
              :title="$t('tooltips.learningPeriod.title')"
              :tooltip-sections="learningPeriodTooltipSections"
            />
          </h3>
          <div class="dates-selection__input-container">
            <div
              data-id-e2e="dateLearning"
              class="dates-selection__input dates-selection__input--longer"
            >
              <span>{{ $tkey('startLabel') }}:</span>
              <rtls-calendar-picker
                :key="`learning-start-date-${nameKey}`"
                :value="dateSelections.learningStartDate"
                :allowed-dates="allowedStartAndExcludedDates"
                :display-date-format="longDateFormat"
                @input="changeLearningStartDate"
              />
            </div>
            <span class="mr-0">
              {{ $tkey('endLabel') }}:
              <v-tooltip
                :value="learningEndDateWasReduced"
                :disabled="!learningEndDateWasReduced"
                right
              >
                <template v-slot:activator="{ on }">
                  <b v-on="on">
                    {{ calculateLearningEndDate | moment(getDateFormats.long) }}
                  </b>
                </template>
                <span v-if="learningEndDateWasReduced">{{ $tkey('maxWeekWarning') }}</span>
              </v-tooltip>
            </span>
          </div>
          <!-- end learning date -->
          <v-row data-id-e2e="btnLearningWeeks" no-gutters>
            <v-col class="pb-1 col-9">
              <v-btn-toggle
                :value="dateSelections.learningDateWeeks"
                mandatory
                @change="changeLearningDateWeeks"
              >
                <v-btn
                  v-for="(el, index) in learningWeekOptions"
                  :key="`learning-options-${index}`"
                  class="dates-selection__group-button"
                  :value="el.value"
                >
                  {{ el.label }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col class="col-3">
              <v-text-field
                v-if="hasCustomLearningDateWeeks"
                :value="dateSelections.customLearningDateWeeks"
                single-line
                class="pa-0 ma-0 ml-4 dates-selection__input--custom"
                :placeholder="$tkey('custom')"
                @change="
                  [
                    () => {
                      learningEndDateWasReduced = false;
                    },
                    changeLearningDateWeeks($event),
                  ]
                "
              />
            </v-col>
          </v-row>
        </v-card>

        <!-- learning excluding date -->
        <v-card class="pa-2 pl-0 col-box no-border">
          <div data-id-e2e="dateExclude" class="dates-selection__exclude-container">
            <h3 class="dates-selection__input-heading">{{ $tkey('excludingLabel') }}:</h3>
            <multiple-dates-selector
              :value="dateSelections.learningExcludedDates"
              :allowed-dates="allowedStartAndExcludedDates"
              @input="updateLearningExcludedDates"
            />
          </div>
        </v-card>
        <!-- end excluding date -->
        <!-- end learning period -->
      </v-col>
      <v-col>
        <!-- switching period -->
        <v-card class="col-box">
          <h3 class="dates-selection__input-heading mt-3">
            {{ $tkey('switchingPeriodLabel') }}
            <span>{{ $tkey('switchingPeriodLabelWeeks') }}</span>

            <!-- Switching period tooltip -->
            <assortment-tooltip
              :title="$t('tooltips.switchingPeriod.title')"
              :tooltip-sections="switchingPeriodTooltipSections"
            />
          </h3>
          <v-row no-gutters>
            <div class="dates-selection__input-container">
              <div
                data-id-e2e="dateSwitching"
                class="dates-selection__input dates-selection__input--longer"
              >
                <span>{{ $tkey('startLabel') }}:</span>
                <rtls-calendar-picker
                  :key="nameKey"
                  :value="dateSelections.switchingStartDate"
                  :allowed-dates="allowedStartAndExcludedDates"
                  :display-date-format="longDateFormat"
                  @input="changeSwitchingStartDate"
                />
              </div>
              <span class="wp-scope__end-label">
                {{ $tkey('endLabel') }}:
                <v-tooltip
                  :value="switchingEndDateWasReduced"
                  :disabled="!switchingEndDateWasReduced"
                  right
                >
                  <template v-slot:activator="{ on }">
                    <b v-on="on">
                      {{ calculateSwitchingEndDate | moment(getDateFormats.long) }}
                    </b>
                  </template>
                  <span v-if="switchingEndDateWasReduced">{{ $tkey('maxWeekWarning') }}</span>
                </v-tooltip>
              </span>
            </div>
          </v-row>
          <v-row data-id-e2e="btnSwitchingWeeks" class="pb-3" no-gutters>
            <v-col class="col-9">
              <v-btn-toggle
                :value="dateSelections.switchingDateWeeks"
                mandatory
                @change="
                  [
                    () => {
                      switchingEndDateWasReduced = false;
                    },
                    changeSwitchingDateWeeks($event),
                  ]
                "
              >
                <v-btn
                  v-for="(el, index) in switchingWeekOptions"
                  :key="`options-${index}`"
                  class="dates-selection__group-button"
                  :value="el.value"
                >
                  {{ el.label }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col class="col-3">
              <v-text-field
                v-if="hasCustomSwitchingDateWeeks"
                :value="dateSelections.customSwitchingDateWeeks"
                single-line
                class="pa-0 ma-0 ml-4 dates-selection__input--custom"
                :placeholder="$tkey('custom')"
                @change="changeSwitchingDateWeeks"
              />
            </v-col>
          </v-row>
        </v-card>
        <!-- end switching period -->
      </v-col>
    </v-row>

    <!-- Max trend -->
    <template v-if="calculateTrendEnabled">
      <v-divider />

      <v-row no-gutters class="pt-3">
        <v-col cols="2" class="dates-selection__maximum-trend-container">
          <h3 class="dates-selection__input-heading">
            {{ $tkey('maximumTrendLabel') }} <span>({{ $tkey('maximumTrendUnit') }})</span>
          </h3>
          <div class="dates-selection__input">
            <rtls-text-field :value="maximumTrend" :rules="rules" @change="changeMaximumTrend" />
          </div>
        </v-col>
      </v-row>
    </template>
    <!-- End max trend -->
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters, mapActions } from 'vuex';
import { cloneDeep, get } from 'lodash';
import SIZE_TYPES from '@enums/size-types';
import numberUtils from '@/js/utils/number-format-utils';
import datesMixin from '@/js/mixins/date-utils';
import { workpackageScopeTooltipOptionsMixin } from '@/js/mixins/tooltip-options';
import inputValidationMixin from '@/js/mixins/input-validations';

const defaultPerformanceDateWeeks = 52;
const defaultLearningDateWeeks = 52;
const defaultSwitchingDateWeeks = 104;
const twentyYearsInWeeks = 1042;
const maximumTrendLimit = 100;

export default {
  mixins: [datesMixin, inputValidationMixin, workpackageScopeTooltipOptionsMixin],
  localizationKey: 'workpackagePage.scope',

  props: {
    dateSelections: {
      type: Object,
      required: true,
    },

    fillInSelection: {
      type: String,
      required: true,
    },

    maximumTrend: {
      type: [String, Number],
      required: false,
      default: null,
    },

    workpackage: {
      type: Object,
      required: true,
    },

    periodIds: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      nameKey: false,
      datesLoaded: false,
      sizeTypeIconMap: {
        [SIZE_TYPES.linearSpace]: '$linear-space',
        [SIZE_TYPES.cubicSpace]: '$cubic-space',
        [SIZE_TYPES.horizontalSpace]: '$horizontal-space',
        [SIZE_TYPES.frontalSpace]: '$frontal-space',
        [SIZE_TYPES.productCount]: '$empty-cube',
      },
      performanceEndDateWasReduced: false,
      switchingEndDateWasReduced: false,
      learningEndDateWasReduced: false,
      rules: [
        this.required,
        v => this.isGreaterOrEqual(v, 0),
        v => this.isLessThan(v, maximumTrendLimit),
        this.isNumber,
      ],
    };
  },

  computed: {
    ...mapState('snapshotDates', ['latestSnapshotDate', 'snapshotDates']),
    ...mapGetters('context', ['getDateFormats', 'getDefaultReverseFormat', 'getClientConfig']),
    ...mapGetters('workpackages', ['isSimpleSwapsWP']),
    ...mapGetters('toolData', { periodIdsOptions: 'periodIds' }),

    parsedPeriodIdsOptions() {
      const periodIds = this.periodIdsOptions.map(el => {
        return {
          text: el,
          value: el,
        };
      });

      if (this.hasMandatoryPeriodIdsEnabled) {
        periodIds.unshift({
          text: this.$tkey('noPeriodID'),
          value: null,
        });
      }

      return periodIds;
    },

    calculateTrendEnabled() {
      return get(this.getClientConfig, 'features.calculateTrendEnabled', false);
    },

    longDateFormat() {
      return this.getDateFormats.long;
    },

    latestSelectableDate() {
      return this.toNearestSunday(this.latestSnapshotDate);
    },

    sizeSelectItems() {
      return Object.keys(SIZE_TYPES).map(key => ({
        value: SIZE_TYPES[key],
        text: this.$t(`sizeTypes.${key}`),
      }));
    },

    hasCustomPerformanceDateWeeks() {
      return !this.basePerformanceWeekOptions
        .map(v => v.value)
        .includes(this.dateSelections.performanceDateWeeks);
    },

    hasCustomSwitchingDateWeeks() {
      return !this.baseSwitchingWeekOptions
        .map(v => v.value)
        .includes(this.dateSelections.switchingDateWeeks);
    },

    hasCustomLearningDateWeeks() {
      return !this.baseLearningWeekOptions
        .map(v => v.value)
        .includes(this.dateSelections.learningDateWeeks);
    },

    basePerformanceWeekOptions() {
      return this.weekOptions(defaultPerformanceDateWeeks);
    },

    baseSwitchingWeekOptions() {
      return this.weekOptions(defaultSwitchingDateWeeks);
    },

    baseLearningWeekOptions() {
      return this.weekOptions(defaultLearningDateWeeks);
    },

    performanceWeekOptions() {
      // to ensure users can't unselect weeks, and custom tab is selected when appropriate,
      // we update week options with the custom value
      const weekOptions = cloneDeep(this.basePerformanceWeekOptions);
      weekOptions.push({
        label: this.$tkey('custom'),
        value: this.dateSelections.customPerformanceDateWeeks,
      });
      return weekOptions;
    },

    switchingWeekOptions() {
      const weekOptions = cloneDeep(this.baseSwitchingWeekOptions);
      weekOptions.push({
        label: this.$tkey('custom'),
        value: this.dateSelections.customSwitchingDateWeeks,
      });
      return weekOptions;
    },

    learningWeekOptions() {
      const weekOptions = cloneDeep(this.baseLearningWeekOptions);
      weekOptions.push({
        label: this.$tkey('custom'),
        value: this.dateSelections.customLearningDateWeeks,
      });
      return weekOptions;
    },

    calculatePerformanceEndDate() {
      // performanceStartDate and performanceDateWeeks will be null until datesLoaded.
      if (!this.datesLoaded) return false;
      const { performanceStartDate, performanceDateWeeks } = this.dateSelections;

      return this.calculateEndDate({
        startDate: performanceStartDate,
        weeks: performanceDateWeeks,
        dateFormat: this.getDefaultReverseFormat,
      });
    },

    calculateSwitchingEndDate() {
      // switchingStartDate and switchingDateWeeks will be null until datesLoaded.
      if (!this.datesLoaded) return false;
      const { switchingStartDate, switchingDateWeeks } = this.dateSelections;

      return this.calculateEndDate({
        startDate: switchingStartDate,
        weeks: switchingDateWeeks,
        dateFormat: this.getDefaultReverseFormat,
      });
    },

    calculateLearningEndDate() {
      // learningStartDate and learningDateWeeks will be null until datesLoaded.
      if (!this.datesLoaded) return false;
      const { learningStartDate, learningDateWeeks } = this.dateSelections;

      return this.calculateEndDate({
        startDate: learningStartDate,
        weeks: learningDateWeeks,
        dateFormat: this.getDefaultReverseFormat,
      });
    },

    allowedSnapshotDates() {
      const allowedSnapshotDatesFormatted = this.snapshotDates.map(d =>
        moment.utc(d).format(this.getDefaultReverseFormat)
      );
      return date => allowedSnapshotDatesFormatted.includes(date);
    },

    allowedStartAndExcludedDates() {
      // must be Monday and <= latestSelectableDate
      return date =>
        moment.utc(date).isoWeekday() === 1 &&
        moment.utc(date).isSameOrBefore(this.latestSelectableDate, 'day');
    },

    hasPeriodIdsEnabled() {
      return get(this.getClientConfig, 'features.workpackagePeriodIdsEnabled', false);
    },

    hasMandatoryPeriodIdsEnabled() {
      return get(this.getClientConfig, 'betaFeatures.rcMandatoryPeriodIdsEnabled', false);
    },
  },

  watch: {
    longDateFormat() {
      // force rtls-calendar-picker to re-render with new date format
      this.nameKey = !this.nameKey;
    },
  },

  async created() {
    await this.fetchSnapshotDates();
    await this.setupDates();
    if (this.hasPeriodIdsEnabled) {
      this.getPeriodIds();
    }
  },

  methods: {
    ...mapActions('snapshotDates', ['fetchSnapshotDates']),
    ...mapActions('toolData', ['getPeriodIds']),

    getMaxWeeksAllowed(weeks) {
      return Math.min(parseInt(weeks, 10), twentyYearsInWeeks);
    },

    changePerformanceDateWeeks(weeks) {
      const { validWeeks, wasReducedToFit } = this.toValidWeek({
        startDate: this.dateSelections.performanceStartDate,
        weeks: this.getMaxWeeksAllowed(weeks),
      });

      const isCustomWeek = this.isCustomWeek(validWeeks, this.basePerformanceWeekOptions);
      this.performanceEndDateWasReduced = wasReducedToFit;

      const updates = {
        customPerformanceDateWeeks: isCustomWeek ? validWeeks : 0,
        performanceDateWeeks: validWeeks,
      };
      this.$emit('date-changed', updates);
    },

    changeSwitchingDateWeeks(weeks) {
      const { validWeeks, wasReducedToFit } = this.toValidWeek({
        startDate: this.dateSelections.switchingStartDate,
        weeks: this.getMaxWeeksAllowed(weeks),
      });

      const isCustomWeek = this.isCustomWeek(validWeeks, this.baseSwitchingWeekOptions);
      this.switchingEndDateWasReduced = wasReducedToFit;

      const updates = {
        customSwitchingDateWeeks: isCustomWeek ? validWeeks : 0,
        switchingDateWeeks: validWeeks,
      };
      this.$emit('date-changed', updates);
    },

    changeLearningDateWeeks(weeks) {
      const { validWeeks, wasReducedToFit } = this.toValidWeek({
        startDate: this.dateSelections.learningStartDate,
        weeks: this.getMaxWeeksAllowed(weeks),
      });

      const isCustomWeek = this.isCustomWeek(validWeeks, this.baseLearningWeekOptions);
      this.learningEndDateWasReduced = wasReducedToFit;

      const updates = {
        customLearningDateWeeks: isCustomWeek ? validWeeks : 0,
        learningDateWeeks: validWeeks,
      };
      this.$emit('date-changed', updates);
    },

    changeSwitchingStartDate(switchingStartDate) {
      this.$emit('date-changed', { switchingStartDate });
      this.changeSwitchingDateWeeks(this.dateSelections.switchingDateWeeks); // re-run weeks validations
    },

    changePerformanceStartDate(performanceStartDate) {
      this.$emit('date-changed', { performanceStartDate });
      this.changePerformanceDateWeeks(this.dateSelections.performanceDateWeeks); // re-run weeks validations
    },

    changeLearningStartDate(learningStartDate) {
      this.$emit('date-changed', { learningStartDate });
      this.changeLearningDateWeeks(this.dateSelections.learningDateWeeks); // re-run weeks validations
    },

    changeSnapshotDate(snapshotDate) {
      // When using future snapshot date, the ending dates for the switching and performance
      // cannot go beyond the existing latest data date
      // the latestSnapshotDate date is not from the future - compare with it
      const existingLatestDataDate = moment.min([
        moment.utc(this.latestSnapshotDate),
        moment.utc(snapshotDate),
      ]);
      // Whenever the user selects a new snapshot date, it should refresh the start and end dates for performance and switching
      const {
        defaultSwitchingStartDate,
        defaultPerformanceStartDate,
      } = this.calculateStartDatesFromSnapshotDate(existingLatestDataDate);
      const updates = {
        snapshotDate,
        switchingStartDate: defaultSwitchingStartDate,
        performanceStartDate: defaultPerformanceStartDate,
      };
      this.$emit('date-changed', updates);
    },

    changeTargetLaunchDateDate(targetLaunchDate) {
      this.$emit('date-changed', { targetLaunchDate });
    },

    toValidWeek({ startDate, weeks }) {
      weeks = parseInt(weeks, 10);
      const endDate = this.calculateEndDate({
        startDate,
        weeks,
        dateFormat: this.getDefaultReverseFormat,
      });
      if (!moment.utc(endDate).isAfter(this.latestSelectableDate, 'day')) {
        return { validWeeks: weeks };
      }

      const maxWeeks = Math.floor(
        moment.utc(this.latestSnapshotDate).diff(startDate, 'weeks', true)
      );

      return { validWeeks: maxWeeks, wasReducedToFit: true };
    },

    calculateStartDatesFromSnapshotDate(snapshotDate) {
      const latestSunday = this.toNearestSunday(snapshotDate);
      const defaultPerformanceStartDate = this.calculateStartDate({
        endDate: latestSunday,
        weeks: 52,
        dateFormat: this.getDefaultReverseFormat,
      });
      const defaultSwitchingStartDate = this.calculateStartDate({
        endDate: latestSunday,
        weeks: 104,
        dateFormat: this.getDefaultReverseFormat,
      });

      const defaultLearningStartDate = this.calculateStartDate({
        endDate: latestSunday,
        weeks: 52,
        dateFormat: this.getDefaultReverseFormat,
      });

      return { defaultPerformanceStartDate, defaultSwitchingStartDate, defaultLearningStartDate };
    },

    setupDates() {
      const defaultSnapshotDate = this.latestSnapshotDate;
      const {
        defaultSwitchingStartDate,
        defaultPerformanceStartDate,
        defaultLearningStartDate,
      } = this.calculateStartDatesFromSnapshotDate(defaultSnapshotDate);

      const updates = {
        snapshotDate: this.formatDateForPicker(
          this.workpackage.snapshotDate || defaultSnapshotDate,
          this.getDefaultReverseFormat
        ),
        targetLaunchDate: this.workpackage.targetLaunchDate
          ? this.formatDateForPicker(
              this.workpackage.targetLaunchDate,
              this.getDefaultReverseFormat
            )
          : null, // not available to wp
        // switching dates
        switchingDateWeeks: this.workpackage.switchingDateWeeks || defaultSwitchingDateWeeks,
        switchingStartDate: this.formatDateForPicker(
          this.workpackage.switchingStartDate || defaultSwitchingStartDate,
          this.getDefaultReverseFormat
        ),
        // performance dates
        performanceStartDate: this.formatDateForPicker(
          this.workpackage.performanceStartDate || defaultPerformanceStartDate,
          this.getDefaultReverseFormat
        ),
        performanceDateWeeks: this.workpackage.performanceDateWeeks || defaultPerformanceDateWeeks,
        performanceExcludedDates: (this.workpackage.performanceExcludedDates || []).map(date =>
          this.formatDateForPicker(date, this.getDefaultReverseFormat)
        ),
      };

      if (this.isCustomWeek(updates.performanceDateWeeks, this.basePerformanceWeekOptions)) {
        updates.customPerformanceDateWeeks = updates.performanceDateWeeks;
      }

      if (this.isCustomWeek(updates.switchingDateWeeks, this.baseSwitchingWeekOptions)) {
        updates.customSwitchingDateWeeks = updates.switchingDateWeeks;
      }

      const isNewlyCreatedWorkpackage =
        this.workpackage.creationDate === this.workpackage.lastModifiedDate;
      // unless the workpackage had a learning date setup or is just created, we don't assign its content by default
      // in the future, this will be controlled by a checkbox
      if (
        this.hasSeasonalityEnabled &&
        (this.workpackage.learningStartDate || isNewlyCreatedWorkpackage)
      ) {
        updates.learningStartDate = this.formatDateForPicker(
          this.workpackage.learningStartDate || defaultLearningStartDate,
          this.getDefaultReverseFormat
        );
        updates.learningDateWeeks = this.workpackage.learningDateWeeks || defaultLearningDateWeeks;
        updates.learningExcludedDates = (this.workpackage.learningExcludedDates || []).map(date =>
          this.formatDateForPicker(date, this.getDefaultReverseFormat)
        );

        if (this.isCustomWeek(updates.learningDateWeeks, this.baseLearningWeekOptions)) {
          updates.customLearningDateWeeks = updates.learningDateWeeks;
        }
      } else {
        updates.learningStartDate = null;
        updates.learningDateWeeks = 0;
        updates.learningExcludedDates = [];
      }

      this.datesLoaded = true;
      this.$emit('date-changed', updates);
      this.$emit('dates-loaded', this.datesLoaded);
    },

    weekOptions(maxWeekValue) {
      return [1, 2, 4].map(v => {
        const value = maxWeekValue / v;
        return {
          label: value,
          value,
        };
      });
    },

    isCustomWeek(weeks, originalList) {
      // A week is marked as custom if is not part of the original list entries
      return !originalList.map(v => v.value).includes(weeks);
    },

    updatePerformanceExcludedDates(newDates) {
      this.$emit('date-changed', { performanceExcludedDates: newDates });
    },

    updateLearningExcludedDates(newDates) {
      this.$emit('date-changed', { learningExcludedDates: newDates });
    },

    changeFillInSelection(fillInSelection) {
      this.$emit('model-changed', { fillInSelection });
    },

    changeMaximumTrend(maximumTrend) {
      this.$emit('model-changed', { maximumTrend: numberUtils.formatStringToNumber(maximumTrend) });
    },

    onPeriodIdsChange(periodIds) {
      this.$emit('model-changed', { periodIds });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.dates-selection {
  background-color: $assortment-workpackage-frame-bg-blue;

  &__input-heading {
    margin-bottom: 7px;
    font-size: 1.2rem;

    span {
      font-weight: normal;
    }
  }

  &__input {
    ::v-deep.v-input__slot {
      background: #fff;
      border-bottom: 1px solid $assortment-primary-colour !important;
      width: 100px;
    }

    .v-input,
    .v-select {
      margin: 0;
      padding: 0;
      width: 60%;
    }

    &--longer {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-right: 14px;

      .v-input {
        width: 90% !important;
      }
    }

    &--custom {
      height: 20px;
    }
  }

  &__input-container {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 14px;

    span {
      font-size: 1.2rem;
      margin-right: 23px;
    }
  }

  &__group-button {
    border-color: $assortment-primary-colour !important;
    height: 32px !important;
    flex: 1;

    &::v-deep:not(.v-btn--active) {
      background-color: transparent !important;
    }
  }

  &__exclude-container {
    align-items: flex-start;
    display: flex;
    font-weight: normal;
    justify-content: flex-start;

    h3 {
      margin-top: 5px;
      margin-right: 15px;
      font-weight: normal;
    }
  }

  &__maximum-trend-container {
    .rtls-text-field-container {
      width: 100px;
    }
  }
}

.border-card {
  border-top: 1px solid $assortment-divider-colour-darker;
}

.v-card {
  padding-bottom: 0 !important;
}

::v-deep {
  .v-text-field__slot {
    input {
      padding-left: 5px;
    }
  }

  .v-item-group {
    width: 100%;
  }

  .v-chip {
    background: $assortment-workpackage-chip-colour !important;
  }
}
</style>
