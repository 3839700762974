<template>
  <v-card class="wp-scope-filters col-box">
    <div class="wp-scope-filters__header mb-1">
      <h3 class="wp-scope-filters__input-heading">
        {{ $t('workpackagePage.splitWorkpackages.splitWorkpackagesTitle') }}

        <!-- Filters tooltip -->
        <assortment-tooltip
          :title="$t('tooltips.filterSplitWorkpackages.title')"
          :tooltip-sections="filterSplitWorkpackagesSections"
        />
      </h3>
    </div>

    <v-row>
      <v-col cols="12" class="py-2 px-0 filter-cols">
        <div class="filter-main">
          <wp-scope-filters-chain
            :wp-furniture="wpFurniture"
            @updateMainFilterObject="updateMainFilterObject"
          />
        </div>
      </v-col>
      <v-col cols="12" class="py-2 px-0 filter-cols">
        <div class="filter-main">
          <wp-scope-filters-assortment-role
            :wp-furniture="wpFurniture"
            @updateMainFilterObject="updateMainFilterObject"
          />
        </div>
      </v-col>
      <v-col cols="12" class="py-2 px-0 filter-cols">
        <div class="filter-main">
          <wp-scope-filters-display-group
            :products="products"
            @updateMainFilterObject="updateMainFilterObject"
          />
        </div>
      </v-col>
      <v-col cols="12" class="py-2 px-0 filter-cols">
        <div class="filter-main">
          <wp-scope-filters-cdt-select
            :products="products"
            @updateMainFilterObject="updateMainFilterObject"
          />
        </div>
      </v-col>
    </v-row>

    <v-row class="footer-panel">
      <v-col class="d-flex justify-end filter-apply" colspan="12">
        <v-btn
          data-id-e2e="btnFilterSplitWorkpackages"
          depressed
          primary
          min-width="65"
          small
          :loading="isSaving || loading"
          @click="filterSplitWorkpackages()"
        >
          {{ $t('workpackagePage.splitWorkpackages.splitWorkpackagesSave') }}
        </v-btn>
      </v-col>
    </v-row>
    <dependency-tree-feedback-modal
      :value="dependencyTreeModalOpen"
      :results="dependencyTreeFeedback"
      page="workpackageSetup"
      @close="closeDependencyTreeModal"
      @commit="filterSplitWorkpackages(true)"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { cloneDeep, sortBy, reduce, indexOf, get, groupBy, isEmpty } from 'lodash';
import Vue from 'vue';
import filterTypeEnum from '@enums/wp-filter-types';
import originSourceTypes from '@enums/origin-source-types';
import { filterSplitWorkpackagesMixin } from '@/js/mixins/tooltip-options';

const defaultFilters = () => ({
  haveDisplayGroupsChanged: false,
  haveCdtsChanged: false,
  haveChainsChanged: false,
  haveRolesChanged: false,
  [filterTypeEnum.displayGroup]: [],
  [filterTypeEnum.cdt]: [],
  [filterTypeEnum.chain]: [],
  [filterTypeEnum.role]: [],
});

export default {
  mixins: [filterSplitWorkpackagesMixin],
  data() {
    return {
      filters: defaultFilters(),
      dependencyTreeFeedback: {},
      dependencyTreeModalOpen: false,
      isSaving: false,
    };
  },
  computed: {
    ...mapState('workpackages', ['selectedWorkpackage', 'loading']),
    ...mapGetters('workpackageProducts', ['workpackageProducts', 'missingProducts']),
    ...mapGetters('furniture', ['getWorkpackageFurniture']),

    wpFurniture() {
      return this.getWorkpackageFurniture;
    },

    products() {
      const products = this.workpackageProducts;
      return sortBy(cloneDeep(products), 'productKey');
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions('workpackageProducts', [
      'fetchWorkpackageProducts',
      'saveWorkpackageProductSelection',
    ]),
    ...mapMutations('scenarios', ['setSelectedScenario']),
    ...mapActions('dependencyTree', ['triggerDependencyTree']),
    ...mapActions('workpackages', ['refreshWorkpackageInformation', 'updateWorkpackage']),
    ...mapActions('furniture', ['fetchWorkpackageFurniture', 'updateWorkpackageFurniture']),

    async init() {
      this.fetchWorkpackageFurniture();
    },

    /**
     *  Update the filter within the filter object
     *
     * @param {Object} RORO
     * @param {"cdt" | "displayGroup"} RORO.type Filter type to update - should come from filterType enum
     * @param {String[]} RORO.update Product keys to add to removal filter
     */
    updateMainFilterObject({ type, update, isInit = false }) {
      Vue.set(this.filters, type, update);
      if (isInit) {
        return;
      }

      // todo switch
      if (type === filterTypeEnum.displayGroup) {
        this.filters.haveDisplayGroupsChanged = true;
      } else if (type === filterTypeEnum.cdt) {
        this.filters.haveCdtsChanged = true;
      } else if (type === filterTypeEnum.chain) {
        this.filters.haveChainsChanged = true;
      } else if (type === filterTypeEnum.role) {
        this.filters.haveRolesChanged = true;
      }
    },

    async filterSplitWorkpackages(commit = false) {
      const results = await this.triggerDependencyTree({
        params: {
          change: 'workpackageSetupModified',
          updates: {},
          commit,
          workpackageId: this.selectedWorkpackage._id,
        },
      });
      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return false;
      }

      this.isSaving = true;

      if (this.filters.haveDisplayGroupsChanged || this.filters.haveCdtsChanged) {
        await this.saveProducts();
      }

      // Clicking the apply and save button when this filter is empty will not do any data cleanup.
      // ie everything is unselected
      if (
        (this.filters.haveChainsChanged && !isEmpty(this.filters[filterTypeEnum.chain])) ||
        (this.filters.haveRolesChanged && !isEmpty(this.filters[filterTypeEnum.role]))
      ) {
        await this.updateWorkpackageFurnitureAndStores();
      }

      // The following fetches have loading built in and can take over from here
      this.isSaving = false;

      await this.refreshWorkpackageInformation({
        workpackageId: this.selectedWorkpackage._id,
      });
      // Reset the filters to be empty arrays
      this.filters = defaultFilters();
    },

    async saveProducts() {
      // Get the product keys to be deleted
      const productsForDeletion = reduce(
        this.products,
        (result, { isDisplayable, productCategoryKey, productKey, originSource }) => {
          // It is possible for semi-new products to have missing isDisplayable and/or productCategoryKey values.
          // Whenever this is the case, we should NOT filter the product
          const isNewProduct = originSource === originSourceTypes.newInTemplate;

          // Remove products from unselected CDTs or DisplayGroups
          // On subsequent tickets, we will have furnitures and stores here as well
          const isDGSelectedForRemoval =
            isNewProduct && !isDisplayable
              ? false
              : this.filters.haveDisplayGroupsChanged &&
                indexOf(this.filters.displayGroup, isDisplayable) === -1;
          const isCDTSelectedForRemoval =
            isNewProduct && !productCategoryKey
              ? false
              : this.filters.haveCdtsChanged &&
                indexOf(this.filters.cdt, productCategoryKey) === -1;

          if (isDGSelectedForRemoval || isCDTSelectedForRemoval) {
            result.push(productKey);
          }

          return result;
        },
        []
      );

      await this.saveWorkpackageProductSelection({
        workpackageId: this.selectedWorkpackage._id,
        productUpdates: [],
        productKeysToAdd: [],
        productKeysToDelete: productsForDeletion,
        categoryKeysToAdd: [],
        categoryKeysToDelete: [],
      });

      await this.fetchWorkpackageProducts({
        workpackageId: this.selectedWorkpackage._id,
      });
    },

    async updateWorkpackageFurnitureAndStores() {
      const furnitureUpdates = groupBy(this.wpFurniture.furniture, f => {
        const furnitureChain = get(f, 'clientProvidedMetadata.furnitureChain', null);
        const furnitureRole = get(f, 'clientProvidedMetadata.furnitureAssortmentRole', null);

        if (!furnitureChain && !furnitureRole) {
          return 'keep';
        }
        if (!furnitureChain && furnitureRole) {
          return this.filters[filterTypeEnum.role].includes(furnitureRole) ? 'keep' : 'remove';
        }
        if (furnitureChain && !furnitureRole) {
          return this.filters[filterTypeEnum.chain].includes(furnitureChain) ? 'keep' : 'remove';
        }
        return this.filters[filterTypeEnum.chain].includes(furnitureChain) &&
          this.filters[filterTypeEnum.role].includes(furnitureRole)
          ? 'keep'
          : 'remove';
      });

      if (isEmpty(furnitureUpdates.remove)) {
        return;
      }

      const removedFurnitureIds = furnitureUpdates.remove.map(f => f._id);

      const productFurniture = this.wpFurniture.productFurniture.filter(
        pf => !removedFurnitureIds.includes(pf.furnitureId)
      );

      const storeFurnitureUpdates = groupBy(this.wpFurniture.storeFurniture, sf =>
        removedFurnitureIds.includes(sf.furnitureId) ? 'remove' : 'keep'
      );

      const wpFurnitureUpdates = {
        ...this.wpFurniture,
        furniture: furnitureUpdates.keep,
        productFurniture,
        storeFurniture: storeFurnitureUpdates.keep,
      };

      await this.updateWorkpackageFurniture({
        workpackageId: this.wpFurniture.workpackageId,
        updates: wpFurnitureUpdates,
      });

      if (!isEmpty(storeFurnitureUpdates.remove)) {
        let removedFurnitureStoreKeys = storeFurnitureUpdates.remove.map(sf => sf.storeKey);

        // filter storeKeys that are linked to any other furniture
        const storeKeysWithFurniture = (storeFurnitureUpdates.keep || []).map(sf => sf.storeKey);
        removedFurnitureStoreKeys = removedFurnitureStoreKeys.filter(
          key => !storeKeysWithFurniture.includes(key)
        );

        const { stores } = this.selectedWorkpackage;
        // Any store that is only linked to a (or all) furniture that has been removed will be automatically be set to analysis only
        stores.forEach(store => {
          if (removedFurnitureStoreKeys.includes(store.storeKey)) {
            store.assortment = false;
          }
        });

        await this.updateWorkpackage({
          workpackageId: this.selectedWorkpackage._id,
          updates: { stores },
        });
      }
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.wp-scope-filters {
  font-size: 1.2rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input-heading {
    font-size: 1.2rem;
    color: $assortment-workpackage-text;

    span {
      font-weight: normal;
    }
  }

  .filter-cols {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .filter-apply {
    padding-right: 0;
  }

  .filter-main {
    width: 100%;
    align-items: baseline;
  }

  .filter-box {
    align-items: center;

    span {
      padding-left: 0;
    }
  }
}
</style>
