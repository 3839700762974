<template>
  <v-form
    ref="form"
    v-model="valid"
    class="main-expandable-panel panel-row d-flex incomplete"
    autocomplete="off"
    @submit.prevent=""
  >
    <div class="panel-cell drag-cell flex-grow-0 flex-shrink-0" />

    <div class="panel-cell name-description pr-0">
      <div class="top-container d-flex">
        <rtls-text-field
          :key="nameKey"
          v-model.trim="model.name"
          :disabled="isEditingDisabled"
          data-id-e2e="bundleName"
          :data-value-e2e="model.name"
          :rules="[required, v => isNameUnique(v, bundle.name, bundleNames, $t('entities.bundle'))]"
          @blur="saveModel"
          @keyup.enter="saveModel"
          @keyup.esc="resetModel('name')"
        />
      </div>
      <rtls-text-field
        :key="descriptionKey"
        v-model.trim="model.description"
        data-id-e2e="bundleDescription"
        :data-value-e2e="model.description"
        :disabled="isEditingDisabled"
        @blur="saveModel"
        @keydown.enter="saveModel"
        @keydown.esc="resetModel('description')"
      />
    </div>

    <section class="panel-cell align-start flex-column flex-grow-1" />

    <section class="align-start go-live flex-column">
      <target-launch
        v-model="model.targetLaunchDate"
        data-id-e2e="bundleGoLive"
        :disabled="isEditingDisabled"
        :title="$t('bundlesPage.goLive')"
        @input="saveModel"
      />
    </section>

    <section class="panel-cell align-start flex-column">
      <div class="d-flex align-center flex-end row justify-space-between">
        <div class="icons">
          <notes :title="$t('notes.types.workpackage')" :disabled="!showNotImplemented" />
        </div>
        <bundle-option-unarchive
          v-if="model.archived"
          :workpackage="model"
          :entity="entities.bundle"
        />
        <bundle-option-archive v-else :workpackage="model" :entity="entities.bundle" />
      </div>
    </section>

    <section class="panel-cell align-start flex-column">
      <h4 class="header">{{ $tkey('bundlesPage.creator') }}</h4>

      <div class="d-flex align-center flex-end row justify-end">
        <rtls-avatar :username="getName(bundle)" />
      </div>
    </section>

    <section class="panel-cell px-1 align-center justify-center flex-grow-0">
      <panel-menu-button disabled />
    </section>

    <div class="panel-cell px-1 expand align-center justify-center flex-grow-0 flex-shrink-0">
      <main-expand-button
        :disabled="isExpandDisabled"
        :is-expanded="isExpanded"
        @expand="handleExpanded"
      />
    </div>
  </v-form>
</template>

<script>
import { map, get, assign } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import entities from '@enums/entities';
import inputValidationMixin from '@/js/mixins/input-validations';
import datesMixin from '@/js/mixins/date-utils';

export default {
  mixins: [inputValidationMixin, datesMixin],

  props: {
    bundle: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      model: {
        _id: this.bundle._id,
        name: this.bundle.name,
        description: this.bundle.description,
        targetLaunchDate: this.bundle.targetLaunchDate,
        archived: this.bundle.archived,
      },
      valid: true,
      nameKey: false,
      descriptionKey: false,
      entities,
    };
  },

  computed: {
    ...mapState('bundles', ['bundles', 'selectedBundle', 'isWorkpackagePanelExpanded']),
    ...mapGetters('bundles', ['getBundleById']),
    ...mapGetters('context', ['showNotImplemented', 'getDefaultReverseFormat']),

    bundleNames() {
      return map(this.bundles, 'name');
    },

    isExpanded() {
      if (!this.selectedBundle) return false;
      return this.isWorkpackagePanelExpanded && this.selectedBundle._id === this.bundle._id;
    },

    isExpandDisabled() {
      return !this.bundle._id;
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditBundles);
    },
  },

  methods: {
    ...mapActions('bundles', ['updateBundle', 'toggleWorkpackagePanelExpanded']),

    resetModel(field) {
      this[`${field}Key`] = !this[`${field}Key`]; // toggle the key value so the component is reset
      this.updateModel(field, this.getBundleById(this.bundle._id)[field]);
    },

    updateModel(field, value) {
      this.model[field] = value;
    },

    async saveModel() {
      if (!this.valid) return false;
      const targetLaunchDate = this.formatDateForSave(
        this.model.targetLaunchDate,
        this.getDefaultReverseFormat
      );

      await this.updateBundle({
        bundleId: this.bundle._id,
        updates: assign(this.bundle, this.model, { targetLaunchDate }),
      });

      this.nameKey = !this.nameKey;
    },

    handleExpanded() {
      this.toggleWorkpackagePanelExpanded({ bundle: this.bundle });
    },

    getName(bundle) {
      return get(bundle, 'createdBy.fullName');
    },

    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.panel-row {
  background: $assortment-bundle-bg-colour;
}

.panel-cell {
  border-right: 1px solid $assortment-bundle-panel-divider-colour;
  flex-basis: max-content;
  margin-top: 7px;

  &:first-child {
    border-right: none;
  }
}

.disabled {
  color: $assortment-disabled-text-button-colour;
  pointer-events: none;

  ::v-deep {
    .tooltip {
      color: $assortment-disabled-text-button-colour;
    }
  }
}

.drag-cell {
  background: $assortment-bundle-drag-cell-colour;
  flex-basis: auto;
}

.name-description {
  ::v-deep {
    & .v-input {
      &__control {
        background-color: $assortment-bundle-input-bg-colour;
        border: 1px dashed $assortment-bundle-edit-border-colour;
      }

      &__slot {
        background-color: transparent;
      }
    }
  }
}
</style>
