<template>
  <highcharts v-if="selectedAttributeId" :options="chartOptions" />
</template>

<script>
import { filter, groupBy, map, mapValues, size, sumBy, uniq } from 'lodash';
import { mapGetters, mapState } from 'vuex';
import colors from '../../../../ow-colors';

let globalVm;

export default {
  localizationKey: 'clusteringPage.tabs.deviation',
  name: 'Deviation',
  data() {
    const defaultX = 120;
    return {
      defaultChartOptions: {
        chart: {
          type: 'column',
          events: {
            load() {
              this.legend.title.translate(-defaultX / 2, defaultX / 5 + 1);
            },
          },
        },
        title: {
          text: null,
        },
        tooltip: {
          headerFormat: '<tr><td style="font-weight: bold">{series.name}</td></tr><br/>',
          formatter() {
            return `<tr><td>${globalVm.$tkey('chart.tooltip')} ${globalVm.formatNumber({
              number: this.y,
              format: 'float',
            })}%</td>`;
          },
        },
        yAxis: {
          title: {
            text: this.$tkey('chart.subtitle'),
          },
          gridLineColor: 'transparent',
          labels: {
            formatter() {
              return globalVm.formatNumber({ number: this.value, format: 'floatNonRounded' });
            },
          },
        },
        colors: colors.orderedChartColors,
        plotOptions: {
          series: {
            showCheckbox: true,
            events: {
              legendItemClick() {
                this.checkbox.checked = !this.visible;
              },
            },
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          align: 'left',
          symbolRadius: 0,
          symbolHeight: 0,
          symbolWidth: 0,
          squareSymbol: false,
          itemDistance: 0,
          verticalAlign: 'bottom',
          x: defaultX,
          useHTML: true,
          title: {
            text: this.$tkey('chart.legendTitle'),
            style: {
              fontWeight: 'normal',
            },
          },
          labelFormatter() {
            return `<div style="max-width: 120px;display: flex; flex-direction: row"><div style="margin-right:2px;height:19px;display:flex;justify-content:center;align-items:center;background:${
              this.color
            };"><span style="font-size:1rem;letter-spacing: -0.5px;margin-bottom: 1px; color: white;font-weight:normal;margin-right:2px;margin-left:2px; padding: 2px">${
              this.name
            }</span></div></div>`;
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters('clustering', ['getAttributeDeviations']),
    ...mapState('clustering', ['selectedScheme', 'selectedAttributeId']),
    getAxis() {
      const attributeValues = uniq(map(this.getAttributeDeviations, 'attributeValue'));

      return {
        categories: attributeValues,
        // lines between attribute values
        plotLines: attributeValues.map((label, i) => ({
          value: 0.5 + i,
          width: 1,
          color: '#D5D5D5',
          zIndex: 9999999,
        })),
        lineColor: 'transparent',
      };
    },
    getSeries() {
      if (!size(this.getAttributeDeviations)) return [];
      const groupedDeviations = groupBy(this.getAttributeDeviations, 'attributeValue');
      // get all kmeans deviations in attribute across all stores for the selected attribute
      const totalDeviationsPerAttributeValue = mapValues(
        groupedDeviations,
        attributeDeviations => sumBy(attributeDeviations, 'deviation') / size(attributeDeviations)
      );

      return this.selectedScheme.clusters.map(cluster => {
        const clusterDeviations = this.calculateClusterDeviation(
          totalDeviationsPerAttributeValue,
          cluster
        );
        return {
          name: cluster.clusterName,
          data: clusterDeviations,
          selected: true,
          events: {
            checkboxClick(event) {
              if (event.checked) {
                this.show();
              } else {
                this.hide();
              }
            },
          },
        };
      });
    },
    chartOptions() {
      return { ...this.defaultChartOptions, series: this.getSeries, xAxis: this.getAxis };
    },
  },
  created() {
    // required so we can use the vue instance in highcharts formatter function which has it's own `this` context
    globalVm = this;
  },
  methods: {
    calculateClusterDeviation(totalDeviationsPerAttributeValue, cluster) {
      // Only get the deviations for stores in cluster
      const deviationsOfStoresInCluster = filter(this.getAttributeDeviations, ({ storeKey }) =>
        cluster.storeKeys.includes(storeKey)
      );
      const attributeGroupInCluster = groupBy(deviationsOfStoresInCluster, 'attributeValue');

      // now calculate the deviations for the cluster
      return map(attributeGroupInCluster, (c, attributeKeyValue) => {
        const totalAttributeValueDeviationInCluster = sumBy(c, 'deviation');
        const clusterDeviationsSize = size(c);
        const totalAttributeValueDeviation = totalDeviationsPerAttributeValue[attributeKeyValue];

        return (
          totalAttributeValueDeviationInCluster / clusterDeviationsSize -
          totalAttributeValueDeviation
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .highcharts-container > input[type='checkbox'] {
    margin-top: 3px;
  }
}
</style>
