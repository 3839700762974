import { render, staticRenderFns } from "./cdt-header.vue?vue&type=template&id=35051915&scoped=true"
import script from "./cdt-header.vue?vue&type=script&lang=js"
export * from "./cdt-header.vue?vue&type=script&lang=js"
import style0 from "./cdt-header.vue?vue&type=style&index=0&id=35051915&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35051915",
  null
  
)

export default component.exports