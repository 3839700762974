<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
    <path
      d="M3.9 11.05C4.835 11.05 5.6 11.815 5.6 12.75C5.6 13.685 4.835 14.45 3.9 14.45C2.965 14.45 2.2 13.685 2.2 12.75C2.2 11.815 2.965 11.05 3.9 11.05ZM3.9 9.35C2.03 9.35 0.5 10.88 0.5 12.75C0.5 14.62 2.03 16.15 3.9 16.15C5.77 16.15 7.3 14.62 7.3 12.75C7.3 10.88 5.77 9.35 3.9 9.35ZM9 2.55C9.935 2.55 10.7 3.315 10.7 4.25C10.7 5.185 9.935 5.95 9 5.95C8.065 5.95 7.3 5.185 7.3 4.25C7.3 3.315 8.065 2.55 9 2.55ZM9 0.849998C7.13 0.849998 5.6 2.38 5.6 4.25C5.6 6.12 7.13 7.65 9 7.65C10.87 7.65 12.4 6.12 12.4 4.25C12.4 2.38 10.87 0.849998 9 0.849998ZM14.1 11.05C15.035 11.05 15.8 11.815 15.8 12.75C15.8 13.685 15.035 14.45 14.1 14.45C13.165 14.45 12.4 13.685 12.4 12.75C12.4 11.815 13.165 11.05 14.1 11.05ZM14.1 9.35C12.23 9.35 10.7 10.88 10.7 12.75C10.7 14.62 12.23 16.15 14.1 16.15C15.97 16.15 17.5 14.62 17.5 12.75C17.5 10.88 15.97 9.35 14.1 9.35Z"
      fill="#39477B"
    />
  </svg>
</template>

<script>
export default {
  name: 'SeriesPanelIcon',
};
</script>
