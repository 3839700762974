<template>
  <header>
    <!-- Standard navbar otherwise -->
    <v-app-bar
      app
      class="assortment-toolbar elevation-6"
      :class="{ warning: notUsingLocalDatabase }"
      :color="`#${backgroundColor}`"
    >
      <v-toolbar-items>
        <div v-if="hasBrandLogoEnabled" class="brand-logo__container">
          <v-btn exact text to="/" class="font-weight-bold home-link" :ripple="false">
            <v-img
              id="client-logo"
              contain
              class="brand-logo__img"
              src="/organisation-assets/brand-logo.png"
              :alt="$t('general.logo')"
              @load="logoLoaded"
            />
          </v-btn>
        </div>

        <v-btn exact text to="/" class="subheading font-weight-bold home-link">
          <home-button />
        </v-btn>
      </v-toolbar-items>
      <v-spacer />
      <v-toolbar-items v-for="(route, index) in routes" :key="index">
        <v-btn
          v-if="!route.children || route.omitChildrenFromNavbar"
          :key="index"
          slot="activator"
          :name="route.linkText"
          exact
          text
          :to="route.path"
          class="text-none subheading nav-link"
          :class="{ 'active-link': getActiveRoute(route) }"
        >
          {{ $t(route.linkText) }}
        </v-btn>
        <v-menu v-if="route.children && !route.omitChildrenFromNavbar" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              :key="index"
              :name="route.linkText"
              exact
              text
              class="text-none subheading nav-link"
              :class="$route.fullPath.includes(route.path) ? 'active-link' : ''"
              v-on="on"
              >{{ $t(route.linkText) }}</v-btn
            >
          </template>
          <v-list v-if="route.children">
            <v-list-item
              v-for="(item, idx) in route.children"
              :key="idx"
              :name="item.linkText"
              :to="route.path + '/' + item.path"
              dark
            >
              <v-list-item-title>{{ $t(item.linkText) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-spacer />
      <v-toolbar-items>
        <v-menu
          v-if="isCogAvailable"
          open-on-click
          :close-on-content-click="false"
          bottom
          offset-y
          max-width="170px"
        >
          <template v-slot:activator="{ on }">
            <img class="cog-icon" :src="cog" v-on="on" />
          </template>
          <v-list>
            <v-list-item v-if="hasLocaleSwitcherEnabled">
              <locale-switcher />
            </v-list-item>
            <v-list-item v-if="hasFormatsSwitcherEnabled">
              <date-locale-switcher />
            </v-list-item>
            <v-list-item v-if="hasFormatsSwitcherEnabled">
              <numeric-locale-switcher />
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-toolbar-items>
        <v-btn id="navbar-logout" text class="text-none subheading nav-link" @click="onLogout()">
          <span>{{ $t('actions.logout') }}</span>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-items>
        <user-notifications />
      </v-toolbar-items>
      <v-toolbar-items v-if="docsButtonVisible">
        <v-btn
          id="navbar-docs"
          class="text-none subheading nav-link"
          text
          large
          color="primary"
          href="/api/docs/"
          target="_blank"
        >
          <v-icon size="28">mdi-help-circle-outline</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
  </header>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import cog from '@/img/nav_cog.svg';

export default {
  data() {
    return {
      sidebar: false,
      workpackageTruncationLength: 53,
      cog,
    };
  },

  computed: {
    ...mapGetters('context', [
      'hasPermission',
      'getClientConfig',
      'getShowAdminControlsPermissions',
      'getShowClientAdminControlsPermissions',
      'getShowReportingControlsPermissions',
      'getShowCompoundExtractsPermissions',
      'getShowReportingPowerBIPermissions',
      'getShowTemplatesPermissions',
      'getShowBundlesPermissions',
    ]),
    ...mapState('context', ['notUsingLocalDatabase']),

    backgroundColor() {
      return get(this.getClientConfig, 'headerColorOverride', 'ffffff');
    },

    isCogAvailable() {
      return this.hasLocaleSwitcherEnabled || this.hasFormatsSwitcherEnabled;
    },

    hasLocaleSwitcherEnabled() {
      return get(this.getClientConfig, 'features.localeSwitcherEnabled', false);
    },

    hasFormatsSwitcherEnabled() {
      return get(this.getClientConfig, 'features.formatsSwitcherEnabled', false);
    },

    hasBrandLogoEnabled() {
      return get(this.getClientConfig, 'features.brandLogoEnabled', false);
    },

    routes() {
      return this.$router.options.routes
        .filter(route => {
          if (get(route.meta, 'requiresPermission'))
            return this.hasPermission(route.meta.requiresPermission) && !route.omitFromNav;
          return !route.omitFromNav;
        })
        .filter(route => {
          // Only show admin page on whitelisted environments
          // The admin BE is also excluded based on the environments.
          if (route.name === 'admin') return this.getShowAdminControlsPermissions;
          if (route.name === 'client-admin') return this.getShowClientAdminControlsPermissions;
          if (route.name === 'reporting-main') return this.getShowReportingControlsPermissions;
          if (route.name === 'reporting-power-bi') return this.getShowReportingPowerBIPermissions;
          if (route.name === 'compound-extracts') return this.getShowCompoundExtractsPermissions;
          if (route.name === 'templates') return this.getShowTemplatesPermissions;
          if (route.name === 'bundles') return this.getShowBundlesPermissions;
          return true;
        })
        .sort((a, b) => {
          if (Number.isFinite(a.index) && Number.isFinite(b.index)) return a.index - b.index;
          if (Number.isFinite(a.index)) return -1;
          if (Number.isFinite(b.index)) return 1;

          return a.name > b.name ? -1 : 0;
        });
    },

    fullToolGuideEnabled() {
      return get(this.getClientConfig, 'features.fullToolGuideEnabled', false);
    },

    docsButtonVisible() {
      return this.hasPermission(this.userPermissions.canViewDocs) && this.fullToolGuideEnabled;
    },
  },

  methods: {
    ...mapActions('context', ['logout', 'resetState']),

    onLogout() {
      return this.logout().then(
        redirectUrl => {
          this.$router.push(redirectUrl);
          this.resetState();
        },
        () => {
          // eslint-disable-next-line no-console
          console.log('Logout error!');
        }
      );
    },

    logoLoaded() {
      // if the client logo exists, make it visible and style it
      document.getElementById('client-logo').style.visibility = 'visible';
      document.getElementById('client-logo').style.width = '6rem';
    },

    getActiveRoute(route) {
      return this.$route.fullPath.includes(route.path);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.assortment-toolbar {
  z-index: 199 !important;
  background-color: $assortment-header-footer-colour;
  box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.05),
    0px 1px 9px 5px rgba(0, 0, 0, 0.05) !important;

  .v-toolbar__items {
    display: flex;
    align-items: center;
    .v-btn {
      font-size: 1.4rem !important;
    }
  }

  ::v-deep {
    .v-toolbar__content {
      padding: 4px 28px;
    }
  }
}

.home-link {
  color: $assortment-secondary-colour;
}
.home-link:hover {
  text-decoration: none;
}

.nav-link:hover {
  text-decoration: none;
}

.nav-link {
  color: $assortment-primary-colour;
  border-radius: 0%;
  padding-bottom: 0.5rem;

  ::v-deep {
    .v-btn__content {
      font-weight: normal !important;
    }
  }
}

.active-link {
  border-bottom: 0.5rem solid $assortment-primary-colour;
  padding-bottom: 0rem;
}

.v-btn {
  font-size: 1.5rem;
}

.workpackage-selection {
  position: fixed;
  background-color: white;
  width: 100%;
  padding: 3rem 3rem 1rem;
  z-index: 90;
  color: #4a4a4a;
  font-size: 1.4rem;

  .description {
    font-weight: 600;
  }

  .dates {
    color: #37424a;
    font-size: 1.2rem;
  }
}
</style>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.v-list-item {
  min-height: 3.5rem;
  padding-right: 0;
}

.assortment-toolbar.v-app-bar--fixed.warning {
  background-color: $assortment-orange-dark !important;
}

.theme--light.v-list {
  color: $sub-navigation-link;

  .v-list-item--link {
    color: $sub-navigation-link;
    height: 30px;
  }

  .v-list__tile {
    height: 3rem;
  }

  .v-list-item__title {
    font-size: 1.5rem;
  }

  .v-list-item--link:hover {
    background-color: $sub-navigation-hover;
    color: $sub-navigation-link;
  }
}

.v-menu__content {
  margin-top: 2.4rem;
}

.cog-icon {
  height: 1.6rem;
  margin-right: 3rem;

  &:hover {
    cursor: pointer;
  }
}

.brand-logo {
  &__container {
    max-width: 120px;

    .home-link {
      height: auto;
      min-width: auto;
      padding: 0;

      &:before {
        background: transparent;
      }
    }
  }

  &__img {
    height: auto;
    max-height: 40px;
    visibility: hidden;
    width: 0;
  }
}
</style>

<style lang="scss">
@import '@style/base/_variables.scss';

.options {
  min-width: 6rem !important;

  .v-list .v-list-item--active .v-list-item__content {
    color: $assortment-greyed-out-colour;
  }
}
</style>
