import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "a",
        {
          class: { disabled: _vm.disabled },
          attrs: { href: _vm.url, download: "" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.onDownload.apply(null, arguments)
            },
          },
        },
        [
          _vm._t("default", function () {
            return [
              _c(
                VBtn,
                { attrs: { icon: "" } },
                [
                  _c(VIcon, { attrs: { color: "primary", size: "18" } }, [
                    _vm._v(" mdi-download "),
                  ]),
                ],
                1
              ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }