import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.datesLoaded
    ? _c(
        "div",
        { staticClass: "dates-selection" },
        [
          _c(VCard, { staticClass: "d-flex pa-2 pb-0 col-box no-border" }, [
            _c("div", [
              _c(
                "h3",
                { staticClass: "dates-selection__input-heading" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$tkey("snapshotDateLabel")) +
                      "\n\n        "
                  ),
                  _vm._v(" "),
                  _c("assortment-tooltip", {
                    attrs: {
                      title: _vm.$t("tooltips.snapshotDate.title"),
                      "tooltip-sections": _vm.snapshotDateTooltipSections,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dates-selection__input",
                  attrs: { "data-id-e2e": "dateSnapshot" },
                },
                [
                  _c("rtls-calendar-picker", {
                    key: _vm.nameKey,
                    attrs: {
                      value: _vm.dateSelections.snapshotDate,
                      "allowed-dates": _vm.allowedSnapshotDates,
                      "display-date-format": _vm.longDateFormat,
                    },
                    on: { input: _vm.changeSnapshotDate },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.hasPeriodIdsEnabled
              ? _c(
                  "div",
                  { staticClass: "ml-8" },
                  [
                    _c(
                      "h3",
                      { staticClass: "dates-selection__input-heading" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$tkey("assortmentPeriodNumbers")) +
                            "\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(VAutocomplete, {
                      staticClass: "rtls-select--multi-select",
                      style: { "max-width": "280px" },
                      attrs: {
                        "small-chips": "",
                        multiple: "",
                        "hide-details": "",
                        value: _vm.periodIds,
                        items: _vm.parsedPeriodIdsOptions,
                        "item-text": "text",
                        "item-value": "value",
                        placeholder: _vm.$t("general.select"),
                        "menu-props": {
                          dense: true,
                          contentClass: "rtls-multi-select-list",
                        },
                      },
                      on: { change: _vm.onPeriodIdsChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function ({ item, index, parent }) {
                              return [
                                index === 0
                                  ? _c(VChip, [
                                      _c(
                                        "div",
                                        { staticClass: "overflow-container" },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.text)),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            VIcon,
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function ($event) {
                                                  return parent.selectItem(item)
                                                },
                                              },
                                            },
                                            [_vm._v(" $delete ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                index === 1
                                  ? _c(
                                      "span",
                                      { staticClass: "grey--text caption" },
                                      [
                                        _vm._v(
                                          "\n            (+" +
                                            _vm._s(_vm.periodIds.length - 1) +
                                            ")\n          "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1846200203
                      ),
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.bundlesEnabled &&
            _vm.workpackage.bundleId &&
            _vm.workpackage.templateId
              ? _c("div", { staticClass: "ml-4" }, [
                  _c(
                    "h3",
                    { staticClass: "dates-selection__input-heading" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("bundlesPage.goLive")) +
                          "\n\n        "
                      ),
                      _vm._v(" "),
                      _c("assortment-tooltip", {
                        attrs: {
                          title: _vm.$t("tooltips.targetLaunchDate.title"),
                          "tooltip-sections":
                            _vm.targetLaunchDateTooltipSections,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dates-selection__input",
                      attrs: { "data-id-e2e": "targetLaunchDate" },
                    },
                    [
                      _c("rtls-calendar-picker", {
                        key: _vm.nameKey,
                        attrs: {
                          value: _vm.dateSelections.targetLaunchDate,
                          "display-date-format": _vm.longDateFormat,
                        },
                        on: { input: _vm.changeTargetLaunchDateDate },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            VCard,
            { staticClass: "mt-2 mb-1 mx-2 col-box border-card" },
            [
              _c(
                "h3",
                { staticClass: "mt-3 dates-selection__input-heading" },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$tkey("performancePeriodLabel")) +
                      "\n      "
                  ),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$tkey("performancePeriodLabelWeeks"))),
                  ]),
                  _vm._v(" "),
                  _c("assortment-tooltip", {
                    attrs: {
                      title: _vm.$t("tooltips.performancePeriod.title"),
                      "tooltip-sections": _vm.performancePeriodTooltipSections,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "dates-selection__input-container" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "dates-selection__input dates-selection__input--longer",
                    attrs: { "data-id-e2e": "datePerformance" },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.$tkey("startLabel")) + ":")]),
                    _vm._v(" "),
                    _c("rtls-calendar-picker", {
                      key: _vm.nameKey,
                      attrs: {
                        value: _vm.dateSelections.performanceStartDate,
                        "allowed-dates": _vm.allowedStartAndExcludedDates,
                        "display-date-format": _vm.longDateFormat,
                      },
                      on: { input: _vm.changePerformanceStartDate },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "mr-0" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tkey("endLabel")) +
                        ":\n        "
                    ),
                    _c(
                      VTooltip,
                      {
                        attrs: {
                          value: _vm.performanceEndDateWasReduced,
                          disabled: !_vm.performanceEndDateWasReduced,
                          right: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c("b", _vm._g({}, on), [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            _vm.calculatePerformanceEndDate,
                                            _vm.getDateFormats.long
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3851548133
                        ),
                      },
                      [
                        _vm._v(" "),
                        _vm.performanceEndDateWasReduced
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$tkey("maxWeekWarning"))),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                VRow,
                {
                  attrs: {
                    "data-id-e2e": "btnPerformanceWeeks",
                    "no-gutters": "",
                  },
                },
                [
                  _c(
                    VCol,
                    { staticClass: "pb-1 col-9" },
                    [
                      _c(
                        VBtnToggle,
                        {
                          attrs: {
                            value: _vm.dateSelections.performanceDateWeeks,
                            mandatory: "",
                          },
                          on: { change: _vm.changePerformanceDateWeeks },
                        },
                        _vm._l(
                          _vm.performanceWeekOptions,
                          function (el, index) {
                            return _c(
                              VBtn,
                              {
                                key: `options-${index}`,
                                staticClass: "dates-selection__group-button",
                                attrs: { value: el.value },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(el.label) +
                                    "\n          "
                                ),
                              ]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { staticClass: "col-3" },
                    [
                      _vm.hasCustomPerformanceDateWeeks
                        ? _c(VTextField, {
                            staticClass:
                              "pa-0 ma-0 ml-4 dates-selection__input--custom",
                            attrs: {
                              value:
                                _vm.dateSelections.customPerformanceDateWeeks,
                              "single-line": "",
                              placeholder: _vm.$tkey("custom"),
                            },
                            on: {
                              change: function ($event) {
                                ;[
                                  () => {
                                    _vm.performanceEndDateWasReduced = false
                                  },
                                  _vm.changePerformanceDateWeeks($event),
                                ]
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(VCard, { staticClass: "pa-2 col-box no-border" }, [
            _c(
              "div",
              {
                staticClass: "dates-selection__exclude-container",
                attrs: { "data-id-e2e": "dateExclude" },
              },
              [
                _c("h3", { staticClass: "dates-selection__input-heading" }, [
                  _vm._v(_vm._s(_vm.$tkey("excludingLabel")) + ":"),
                ]),
                _vm._v(" "),
                _c("multiple-dates-selector", {
                  attrs: {
                    value: _vm.dateSelections.performanceExcludedDates,
                    "allowed-dates": _vm.allowedStartAndExcludedDates,
                  },
                  on: { input: _vm.updatePerformanceExcludedDates },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.hasSeasonalityEnabled && !_vm.isSimpleSwapsWP
            ? [
                _c(
                  VCard,
                  { staticClass: "mt-2 mb-1 mx-2 col-box border-card" },
                  [
                    _c(
                      "h3",
                      { staticClass: "mt-3 dates-selection__input-heading" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$tkey("learningPeriodLabel")) +
                            "\n        "
                        ),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$tkey("learningPeriodLabelWeeks"))),
                        ]),
                        _vm._v(" "),
                        _c("assortment-tooltip", {
                          attrs: {
                            title: _vm.$t("tooltips.learningPeriod.title"),
                            "tooltip-sections":
                              _vm.learningPeriodTooltipSections,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dates-selection__input-container" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "dates-selection__input dates-selection__input--longer",
                            attrs: { "data-id-e2e": "dateLearning" },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$tkey("startLabel")) + ":"),
                            ]),
                            _vm._v(" "),
                            _c("rtls-calendar-picker", {
                              key: `learning-start-date-${_vm.nameKey}`,
                              attrs: {
                                value: _vm.dateSelections.learningStartDate,
                                "allowed-dates":
                                  _vm.allowedStartAndExcludedDates,
                                "display-date-format": _vm.longDateFormat,
                              },
                              on: { input: _vm.changeLearningStartDate },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "mr-0" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$tkey("endLabel")) +
                                ":\n          "
                            ),
                            _c(
                              VTooltip,
                              {
                                attrs: {
                                  value: _vm.learningEndDateWasReduced,
                                  disabled: !_vm.learningEndDateWasReduced,
                                  right: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c("b", _vm._g({}, on), [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    _vm.calculateLearningEndDate,
                                                    _vm.getDateFormats.long
                                                  )
                                                ) +
                                                "\n              "
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3186163369
                                ),
                              },
                              [
                                _vm._v(" "),
                                _vm.learningEndDateWasReduced
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$tkey("maxWeekWarning"))
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      VRow,
                      {
                        attrs: {
                          "data-id-e2e": "btnLearningWeeks",
                          "no-gutters": "",
                        },
                      },
                      [
                        _c(
                          VCol,
                          { staticClass: "pb-1 col-9" },
                          [
                            _c(
                              VBtnToggle,
                              {
                                attrs: {
                                  value: _vm.dateSelections.learningDateWeeks,
                                  mandatory: "",
                                },
                                on: { change: _vm.changeLearningDateWeeks },
                              },
                              _vm._l(
                                _vm.learningWeekOptions,
                                function (el, index) {
                                  return _c(
                                    VBtn,
                                    {
                                      key: `learning-options-${index}`,
                                      staticClass:
                                        "dates-selection__group-button",
                                      attrs: { value: el.value },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(el.label) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          VCol,
                          { staticClass: "col-3" },
                          [
                            _vm.hasCustomLearningDateWeeks
                              ? _c(VTextField, {
                                  staticClass:
                                    "pa-0 ma-0 ml-4 dates-selection__input--custom",
                                  attrs: {
                                    value:
                                      _vm.dateSelections
                                        .customLearningDateWeeks,
                                    "single-line": "",
                                    placeholder: _vm.$tkey("custom"),
                                  },
                                  on: {
                                    change: function ($event) {
                                      ;[
                                        () => {
                                          _vm.learningEndDateWasReduced = false
                                        },
                                        _vm.changeLearningDateWeeks($event),
                                      ]
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(VCard, { staticClass: "pa-2 col-box no-border" }, [
                  _c(
                    "div",
                    {
                      staticClass: "dates-selection__exclude-container",
                      attrs: { "data-id-e2e": "dateExclude" },
                    },
                    [
                      _c(
                        "h3",
                        { staticClass: "dates-selection__input-heading" },
                        [_vm._v(_vm._s(_vm.$tkey("excludingLabel")) + ":")]
                      ),
                      _vm._v(" "),
                      _c("multiple-dates-selector", {
                        attrs: {
                          value: _vm.dateSelections.learningExcludedDates,
                          "allowed-dates": _vm.allowedStartAndExcludedDates,
                        },
                        on: { input: _vm.updateLearningExcludedDates },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c(
            VCard,
            { staticClass: "mt-5 mb-2 mx-2 col-box border-card" },
            [
              _c(
                "h3",
                { staticClass: "dates-selection__input-heading mt-3" },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$tkey("switchingPeriodLabel")) +
                      "\n      "
                  ),
                  _c("span", [
                    _vm._v(_vm._s(_vm.$tkey("switchingPeriodLabelWeeks"))),
                  ]),
                  _vm._v(" "),
                  _c("assortment-tooltip", {
                    attrs: {
                      title: _vm.$t("tooltips.switchingPeriod.title"),
                      "tooltip-sections": _vm.switchingPeriodTooltipSections,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(VRow, { attrs: { "no-gutters": "" } }, [
                _c("div", { staticClass: "dates-selection__input-container" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "dates-selection__input dates-selection__input--longer",
                      attrs: { "data-id-e2e": "dateSwitching" },
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$tkey("startLabel")) + ":"),
                      ]),
                      _vm._v(" "),
                      _c("rtls-calendar-picker", {
                        key: _vm.nameKey,
                        attrs: {
                          value: _vm.dateSelections.switchingStartDate,
                          "allowed-dates": _vm.allowedStartAndExcludedDates,
                          "display-date-format": _vm.longDateFormat,
                        },
                        on: { input: _vm.changeSwitchingStartDate },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "wp-scope__end-label" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tkey("endLabel")) +
                          ":\n          "
                      ),
                      _c(
                        VTooltip,
                        {
                          attrs: {
                            value: _vm.switchingEndDateWasReduced,
                            disabled: !_vm.switchingEndDateWasReduced,
                            right: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c("b", _vm._g({}, on), [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm._f("moment")(
                                              _vm.calculateSwitchingEndDate,
                                              _vm.getDateFormats.long
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3377999343
                          ),
                        },
                        [
                          _vm._v(" "),
                          _vm.switchingEndDateWasReduced
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$tkey("maxWeekWarning"))),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                VRow,
                {
                  staticClass: "pb-3",
                  attrs: {
                    "data-id-e2e": "btnSwitchingWeeks",
                    "no-gutters": "",
                  },
                },
                [
                  _c(
                    VCol,
                    { staticClass: "col-9" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(/*
          TODO: ensure custom switching and performance week is updated correctly in UI.
          Currently only working when using buttons. When entering week into text box, does not update weeks, but does show maxWeekWarning.
        */) +
                          "\n        "
                      ),
                      _c(
                        VBtnToggle,
                        {
                          attrs: {
                            value: _vm.dateSelections.switchingDateWeeks,
                            mandatory: "",
                          },
                          on: {
                            change: function ($event) {
                              ;[
                                () => {
                                  _vm.switchingEndDateWasReduced = false
                                },
                                _vm.changeSwitchingDateWeeks($event),
                              ]
                            },
                          },
                        },
                        _vm._l(_vm.switchingWeekOptions, function (el, index) {
                          return _c(
                            VBtn,
                            {
                              key: `options-${index}`,
                              staticClass: "dates-selection__group-button",
                              attrs: { value: el.value },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(el.label) +
                                  "\n          "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { staticClass: "col-3" },
                    [
                      _vm.hasCustomSwitchingDateWeeks
                        ? _c(VTextField, {
                            staticClass:
                              "pa-0 ma-0 ml-4 dates-selection__input--custom",
                            attrs: {
                              value:
                                _vm.dateSelections.customSwitchingDateWeeks,
                              "single-line": "",
                              placeholder: _vm.$tkey("custom"),
                            },
                            on: { change: _vm.changeSwitchingDateWeeks },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCard,
            { staticClass: "mt-2 mx-2 col-box border-card" },
            [
              _c(
                "h3",
                { staticClass: "dates-selection__input-heading mt-3" },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$tkey("fillLabel")) + "\n\n      "
                  ),
                  _vm._v(" "),
                  _c("assortment-tooltip", {
                    attrs: {
                      title: _vm.$t("tooltips.fillAccordingTo.title"),
                      "tooltip-sections": _vm.fillAccordingToTooltipSections,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(VSelect, {
                staticClass: "rtls-select",
                style: { width: "280px", "max-width": "280px" },
                attrs: {
                  value: _vm.fillInSelection,
                  placeholder: _vm.$t("sizeTypes.noOption"),
                  items: _vm.sizeSelectItems,
                  "append-outer-icon":
                    _vm.sizeTypeIconMap[_vm.fillInSelection] || "$empty-cube",
                  "append-icon": "expand_more",
                  "hide-details": "",
                },
                on: { change: _vm.changeFillInSelection },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.calculateTrendEnabled
            ? _c(
                VCard,
                {
                  staticClass:
                    "mt-2 mb-1 mx-2 col-box border-card dates-selection__maximum-trend-container",
                },
                [
                  _c(
                    "h3",
                    { staticClass: "dates-selection__input-heading mt-3" },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.$tkey("maximumTrendLabel")) +
                          " "
                      ),
                      _c("span", [
                        _vm._v(
                          "(" + _vm._s(_vm.$tkey("maximumTrendUnit")) + ")"
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "dates-selection__input" },
                    [
                      _c("rtls-text-field", {
                        attrs: {
                          value: _vm.maximumTrend,
                          rules: _vm.rules,
                          grey: "",
                        },
                        on: { change: _vm.changeMaximumTrend },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isSplitWorkpackagesEnabled && !_vm.isTemplate
            ? _c(
                VCard,
                {
                  staticClass:
                    "mt-5 mb-2 mx-2 col-box border-card filter-split-wp",
                },
                [_c("wp-scope-filters")],
                1
              )
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }