var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "numeric-locale-switcher" },
    [
      _c("div", { staticClass: "label" }, [
        _vm._v(_vm._s(_vm.$t("navBar.numbers"))),
      ]),
      _vm._v(" "),
      _c("rtls-select", {
        attrs: {
          items: _vm.availableFormats,
          "item-text": "format",
          "item-value": "locale",
          white: "",
          "menu-props": { right: true, offsetX: true, contentClass: "options" },
          solo: "",
          "hide-details": "",
        },
        on: { change: _vm.setLocale },
        model: {
          value: _vm.currentNumericLocale,
          callback: function ($$v) {
            _vm.currentNumericLocale = $$v
          },
          expression: "currentNumericLocale",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }