<template>
  <div
    class="spacebreak-entry"
    :style="{ height: height + 'px' }"
    :class="spacebreakEntryClass"
    @click="selectSpacebreak"
  >
    <div class="mb-2 mt-1">
      <v-layout class="d-flex justify-space-between align-center">
        <div :title="spacebreak.name" class="spacebreak-entry__label spacebreak-entry__heading">
          {{ spacebreak.name }}
        </div>

        <div class="actions__container d-flex align-center">
          <v-avatar
            :class="{
              'spacebreak-entry__count-badge': !cogWarning,
              'spacebreak-entry__count-badge--warning': cogWarning,
            }"
            size="14"
            data-dd-action-name="ToggleSpacebreakSettingsPanelBtn"
            @click.stop="toggleSettingsPanel"
          >
            <span class="white--text headline">{{ constraintsLength }}</span>
          </v-avatar>
          <v-icon
            data-dd-action-name="ToggleSpacebreakSettingsPanelBtn"
            @click.stop="toggleSettingsPanel"
            >$settings</v-icon
          >

          <v-icon
            class="actions__button--lock"
            :disabled="isSpacebreakLockToggleDisabled(spacebreak._id) || isEditingDisabled"
            small
            @click.stop="toggleLock"
          >
            {{
              isSpacebreakLocked(spacebreak._id)
                ? 'mdi-lock-outline'
                : 'mdi-lock-open-variant-outline'
            }}
          </v-icon>
        </div>
      </v-layout>
    </div>

    <v-row v-if="excessSpace > 0">
      <div
        id="excess-space-bar"
        class="spacebreak-entry__bar spacebreak-entry__bar--excess-space"
      />
    </v-row>

    <v-row v-else>
      <div
        id="set-aside-bar"
        class="spacebreak-entry__bar spacebreak-entry__bar--set-aside"
        :style="{ width: setAsideWidth }"
      />
      <div
        id="space-used-bar"
        class="spacebreak-entry__bar spacebreak-entry__bar--space-used"
        :style="{ width: spaceUsedWidth }"
      />
      <div
        id="space-available-bar"
        class="spacebreak-entry__bar spacebreak-entry__bar--space-available"
        :style="{ width: spaceAvailableWidth }"
      />
    </v-row>

    <v-row class="mt-1 mb-2 justify-space-between">
      <div class="spacebreak-entry__label d-flex flex-row mr-1">
        <span class="text">
          {{ totalSpaceLabel }}
        </span>
      </div>
      <div
        v-if="excessSpace > 0"
        class="spacebreak-entry__label d-flex flex-row text-right justify-end"
      >
        <span class="text">
          {{ excessSpaceLabel }}
        </span>
      </div>
      <div v-else class="spacebreak-entry__label d-flex flex-row text-right justify-end">
        <span class="text">
          {{ freeSpaceLabel }}
        </span>
      </div>
    </v-row>

    <v-row>
      <table class="spacebreak-details">
        <tr v-for="row in spacebreakDetails" class="spacebreak-details__row">
          <td>
            <img v-if="row.iconSrc" class="spacebreak-details__icon" :src="row.iconSrc" />
            <v-icon v-else-if="row.iconName" class="spacebreak-details__icon">
              {{ row.iconName }}
            </v-icon>
          </td>
          <td class="spacebreak-details__label">
            {{ `${row.label}:` }}
          </td>
          <td class="spacebreak-details__count">
            <span class="px-1" :class="{ valueError: row.highlightError && row.count > row.total }">
              {{
                `${formatNumber({ number: row.count, format: row.countFormatter || 'integer' })}`
              }}
            </span>
          </td>
          <td class="spacebreak-details__total">
            <span v-if="row.total" class="px-1 d-inline-block">
              <span class="pr-1">/</span>
              {{ formatNumber({ number: row.total, format: row.totalFormatter || 'integer' }) }}
            </span>
          </td>
        </tr>
      </table>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import { get, some, size, find, isNull, camelCase } from 'lodash';
import sizeTypes from '@enums/size-types';
import { Views } from '@enums/assortment-canvases';
import spacebreakUtils from '@/js/utils/spacebreak-utils';
import productIcon from '@/img/product.svg';
import palletIcon from '@/img/pallet.svg';

export default {
  localizationKey: 'assortmentCanvasPage.spacebreakEntry',

  props: {
    spacebreak: {
      type: Object,
      required: true,
      default: () => {},
    },

    space: {
      type: Number,
      required: true,
    },

    height: {
      type: Number,
      default: null,
    },

    storeTotal: {
      type: Number,
      required: true,
    },

    palletsTotal: {
      type: Number,
      required: true,
    },

    usedPalletsSlots: {
      type: Number,
      required: false,
      default: null,
    },

    productCount: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      countOfConstraints: null,
      views: Views,
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('furniture', ['countFurnitures', 'getSpacebreaksIndexedById']),
    ...mapState('assortmentCanvas', [
      'spacebreakSettings',
      'selectedPod',
      'selectedView',
      'activeSpacebreak',
    ]),
    ...mapGetters('context', ['showNotImplemented', 'getClientConfig']),
    ...mapGetters('assortmentCanvas', [
      'selectedCanvas',
      'isSpacebreakLocked',
      'isSpacebreakLockToggleDisabled',
    ]),

    spacebreakDetails() {
      const productCount = {
        iconSrc: productIcon,
        label: this.$tkey('products'),
        count: this.productCount,
        total: this.spacebreak.productCountSize,
      };
      const palletsSlot = {
        iconSrc: palletIcon,
        label: this.$tkey('pallets'),
        count: this.usedPalletsSlots,
        countFormatter: 'float',
        total: this.palletsTotal,
        totalFormatter: 'float',
        highlightError: true,
      };
      return [
        ...(this.showProductCount ? [productCount] : []),
        ...(this.palletsSlotsEnabled ? [palletsSlot] : []),
        {
          iconName: 'storefront',
          label: this.$tkey('storesPod'),
          count: this.numberOfStores,
          total: this.storeTotal,
        },
      ];
    },

    spacebreakProductCountEnabled() {
      return get(
        this.getClientConfig,
        'features.uiComponents.spacebreakProductCountEnabled',
        false
      );
    },

    showProductCount() {
      return this.spacebreakProductCountEnabled && !isNull(this.productCount);
    },

    palletsSlotsEnabled() {
      return get(this.getClientConfig, 'features.palletsSlotsEnabled', false);
    },

    currentScope() {
      return camelCase(this.selectedWorkpackage.fillInSelection);
    },

    totalSpaceLabel() {
      return this.getLabel(this.spacebreak.size);
    },

    excessSpaceLabel() {
      const suffix =
        this.selectedWorkpackage.fillInSelection === sizeTypes.productCount
          ? this.$tkey('excess')
          : this.$tkey('excessSpace');
      return this.getLabel(this.excessSpace, suffix);
    },

    freeSpaceLabel() {
      return this.getLabel(this.spaceAvailable, this.$tkey('free'));
    },

    setAside() {
      return this.spacebreak.size - this.spacebreak.fillOverride;
    },

    setAsideWidth() {
      return `${(this.setAside / this.spacebreak.size) * 100}%`;
    },

    spaceUsed() {
      return this.space;
    },

    spaceUsedWidth() {
      return `${(this.space / this.spacebreak.size) * 100}%`;
    },

    numberOfStores() {
      const spacebreakIdInfo = get(
        this.getSpacebreaksIndexedById[this.selectedCanvas.clusterId],
        this.spacebreak._id,
        {
          storeKeys: [],
        }
      );
      return size(spacebreakIdInfo.storeKeys);
    },

    spaceAvailable() {
      return this.spacebreak.size - this.setAside - this.space;
    },

    spaceAvailableWidth() {
      return `${(this.spaceAvailable / this.spacebreak.size) * 100}%`;
    },

    excessSpace() {
      return this.setAside + this.spaceUsed - this.spacebreak.size;
    },

    constraintsLength() {
      return this.selectedCanvas.constraints.priorities[this.spacebreak._id].length;
    },

    spacebreakEntryClass() {
      let classes = '';
      if (this.selectedView === this.views.List) {
        classes = 'selectable';
      }
      if (
        get(this.spacebreakSettings, 'open', false) &&
        get(this.spacebreakSettings.spacebreak, '_id') === this.spacebreak._id
      ) {
        classes += ' selected-border-right';
      }
      return classes.trim();
    },

    cogWarning() {
      const constraints = this.selectedCanvas.constraints.priorities[this.spacebreak._id].map(cId =>
        find(this.selectedCanvas.constraints.definitions, { id: cId })
      );
      return some(constraints, c => {
        if (isNull(c.spacebreakId)) {
          return !get(c.isCurrentValueWithinLimits, this.spacebreak._id, false);
        }
        return c.isCurrentValueWithinLimits === false;
      });
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAssortmentCanvas);
    },
  },

  methods: {
    ...mapActions('assortmentCanvas', ['toggleSpacebreakLockForSelectedCanvas']),
    ...mapMutations('assortmentCanvas', ['toggleSpaceBreakSettingsPanel', 'setConstraintEditing']),

    getLabel(value, suffix = '') {
      const spacebreakShortName = spacebreakUtils.getSpacebreakShortName({
        spacebreak: { size: value },
        fillInSelection: this.selectedWorkpackage.fillInSelection,
      });
      return `${spacebreakShortName} ${suffix}`.trim();
    },

    toggleSettingsPanel() {
      this.setConstraintEditing({
        editingBoolean: false,
      });
      this.toggleSpaceBreakSettingsPanel(this.spacebreak);
    },

    async toggleLock() {
      await this.toggleSpacebreakLockForSelectedCanvas({
        spacebreakId: this.spacebreak._id,
        isLocked: !this.isSpacebreakLocked(this.spacebreak._id),
      });
    },

    selectSpacebreak() {
      this.$emit('update-active-spacebreak', this.spacebreak._id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.actions {
  &__container {
    position: relative;
  }

  &__button {
    &--lock {
      color: $assortment-primary-colour;

      &::before {
        line-height: normal;
        font-size: 19px;
      }
    }
  }
}

.spacebreak-entry {
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 3px;

  &__heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &__bar {
    display: inline-flex;
    height: 10px;

    &--excess-space {
      background-color: $assortment-negative-action-colour;
      width: 100%;
    }

    &--set-aside {
      background-color: $assortment-primary-colour;
    }

    &--space-used {
      background-color: $assortment-secondary-colour;
    }

    &--space-available {
      background-color: $canvas-space-available-colour;
    }
  }

  &__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: fit-content;
    font-size: 1.2rem;
    color: $assortment-text-colour;
    font-weight: bold;
  }

  &__count-badge {
    z-index: 2;
    cursor: pointer;
    position: absolute;
    left: -2px;
    top: -4px;
    background-color: $assortment-primary-colour;

    &--warning {
      z-index: 2;
      cursor: pointer;
      position: absolute;
      left: -2px;
      top: -4px;
      background-color: $assortment-slightly-negative-action-colour;
    }
  }
}

.spacebreak-details {
  border-collapse: collapse;
  font-size: 1.2rem;
  table-layout: auto;
  width: 100%;

  &__row {
    padding-bottom: 4px;
    width: 100%;

    &:not(:last-child) {
      border-bottom: 1px solid $assortment-tab-divider-colour;
    }

    &:not(:first-of-type) td {
      padding-top: 4px;
    }

    td {
      padding-bottom: 4px;
    }
  }

  &__icon {
    color: $assortment-primary-colour;
    display: block;
    font-size: 1.6rem;
    padding-right: 3px;
  }

  &__label {
    text-transform: capitalize;
    width: 100%;
  }

  &__count,
  &__total {
    font-weight: 700;
    white-space: nowrap;
    .valueError {
      color: $assortment-span-error-colour;
    }
  }
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.selected-border-right {
  position: relative;

  &::after {
    content: '';
    width: 3px;
    background: $assortment-primary-colour;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
}
</style>
