<template>
  <div class="d-flex" :class="editingModeClass">
    <div v-if="!editingMode">{{ $tkey(params.value) }}</div>
    <rtls-select
      v-if="editingMode"
      :value="fieldValue"
      white
      width="80%"
      :items="items"
      :placeholder="$t('general.select')"
      :menu-props="{ 'z-index': 200 }"
      hide-details
      @input="setFieldValue"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import { isEqual } from 'lodash';
import { StoreNamespaces } from '../../../store/constants';

export default Vue.extend({
  name: 'cellDropdownRenderer',
  localizationKey: 'assortmentCanvasPage.spacebreakSettings.constraints',
  data() {
    return {
      fieldValue: null,
      items: null,
    };
  },
  computed: {
    ...mapState(StoreNamespaces.assortmentCanvas, ['constraintEditing']),
    editingMode() {
      return (
        this.constraintEditing.constraint.open &&
        isEqual(this.params.node.id, this.constraintEditing.constraint.rowNodeId)
      );
    },
    isSelected() {
      return this.params.node.isSelected();
    },
    editingModeClass() {
      return this.editingMode && this.isSelected ? 'editing-padding-top' : '';
    },
  },
  watch: {
    editingMode() {
      this.fieldValue = this.params.value;
    },
  },
  created() {
    this.items = this.params.selectItems;
    this.fieldValue = this.params.value;
  },
  methods: {
    ...mapMutations(StoreNamespaces.assortmentCanvas, [
      'setConstraintRowNodeData',
      'updateConstraintRowNodeData',
    ]),
    setFieldValue(value) {
      this.updateConstraintRowNodeData({ data: value, field: this.params.field });
      this.fieldValue = value;
    },
  },
});
</script>

<style lang="scss" scoped>
.v-btn {
  padding-bottom: 0 !important;
}
</style>
