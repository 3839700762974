<template>
  <v-container fluid>
    <v-layout column>
      <v-flex>
        <workpackages-active />
        <workpackages-archived :entity="entities.workpackage" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import entities from '@enums/entities';

export default {
  data() {
    return {
      entities,
    };
  },
};
</script>
