<template>
  <v-card class="step-tab-panel" flat>
    <div class="assortment-table attributes-table d-flex flex-column">
      <!-- Tab title and search -->
      <v-container class="actions-container flex-grow-0">
        <v-row>
          <v-col
            class="actions-col actions-col__title justify-start flex-grow-0 align-self-center mr-3"
          >
            <span class="info-note">{{ $tkey('attributes.header') }}</span>

            <!-- Attribute editor tooltip -->
            <docs-link link="toolguide/050-attributes.html" />
          </v-col>

          <v-col v-if="showProductsNotInTemplateWarning" class="actions-col flex-grow-0 ml-2">
            <v-alert class="alert--small mr-5 mb-0" type="info" text>
              {{
                $tkey('attributes.notInTemplateWarning', [
                  totalProductsNotInTemplate,
                  (productsData || []).length,
                ])
              }}
            </v-alert>
          </v-col>
          <v-col v-if="productsWithoutPurchasePriceInformation.length" class="ml-2">
            <v-alert type="warning" dismissible text class="ma-2 info-message w-100">
              {{
                $tkey('attributes.validation.notInAssortment', {
                  products: productsWithoutPurchasePriceInformation.length,
                })
              }}
            </v-alert>
          </v-col>

          <error-controls
            v-if="showErrorControls"
            :invalid-rows-message="invalidRowsErrorMessage"
            :is-showing-invalid-rows="filterInvalidRows"
            @click="toggleInvalidRows"
          />

          <v-col class="actions-col actions-col__search">
            <!-- This uses @input as @change wasn't wired for rtls-search yet -->
            <!-- Should switch to using @change after AOV3-777 -->
            <rtls-search
              v-if="gridApi"
              v-model="searchString"
              grey
              width="240px"
              :placeholder="$tkey('searchPlaceholder')"
              @input="gridApi.onFilterChanged()"
            />
          </v-col>
        </v-row>
      </v-container>

      <!-- Action panel -->
      <v-container class="actions-container flex-grow-0">
        <v-row class="products-table-actions">
          <v-col class="actions-col d-flex align-center justify-start">
            <rtls-text-field
              id="number-of-products"
              v-model.number="newProductRowsNumber"
              grey
              width="150px"
              data-id-e2e="txtNoOfNewProductsToAdd"
              :label="$tkey('numberOfProducts')"
              :rules="rules"
              class="text-field"
              :class="{ 'hidden-triangle': isTriangleHidden }"
              :disabled="isAddNewProductInputDisabled"
              @change="newProductRowsNumber = $event"
              @keyup.enter="isNewRowsNumberValid && addNewProducts()"
            />
            <v-col class="actions-col add-products-btn-col">
              <v-btn
                id="btnAddNewProducts"
                primary
                data-id-e2e="btnAddNewProducts"
                class="float-left button-space-5"
                :disabled="isAddButtonDisabled"
                @click="addNewProducts"
              >
                {{ $t('actions.add') }}
              </v-btn>

              <v-btn
                v-if="hasRestOfMarketEnabled"
                data-id-e2e="btnAddRestOfMarketProducts"
                primary
                class="float-left button-space-5"
                @click="openRestOfMarketProductsModal"
              >
                {{ $t('actions.addROMProducts') }}
              </v-btn>
            </v-col>
          </v-col>

          <!-- Map fields modal - gets displayed after uploaded data is received -->
          <map-fields-modal
            v-if="isResetFromTemplate"
            :value="isResetFromTemplate"
            :is-reset-from-template="isResetFromTemplate"
            :download-file-url="'downloadFileUrl'"
            :upload-metadata="{ uploadMethod: 'scenario' }"
            :uploaded-data-details="uploadedDataDetails"
            :current-custom-fields="customHeaderNames"
            :fields-to-ignore="Array.from(fieldsToIgnore)"
            enable-field-creation
            @confirm="processData"
            @cancel="closeResetDataModal"
          />

          <v-col class="actions-col d-flex justify-end">
            <v-btn
              class="outlined-btn mr-2"
              depressed
              secondary
              :disabled="isClearFiltersDisabled"
              @click="clearFilters"
            >
              {{ $t('actions.clearAllFilters') }}
            </v-btn>

            <product-attribute-options
              v-if="hasTransferPriceEnabled"
              @sync-financials="syncFinancials"
              @update-maintain="bulkUpdateMaintain"
            />
            <v-btn
              v-if="showResetFromTemplateButton"
              :disabled="isEditingDisabled"
              class="mr-2 outlined-btn reset-kpi-button"
              depressed
              secondary
              @click="openResetFromTemplateModal"
            >
              {{ $tkey('attributes.resetProductsFromTemplateTitle') }}
            </v-btn>
            <v-btn
              :disabled="isEditingDisabled"
              class="mr-2 outlined-btn reset-kpi-button"
              depressed
              secondary
              @click="openResetDataModal"
            >
              {{ $tkey('attributes.resetProductKPIsTitle') }}
            </v-btn>
            <v-btn
              class="outlined-btn mr-2"
              depressed
              secondary
              :disabled="!hasNewProducts || isEditingDisabled"
              @click="deleteAllNewProducts"
            >
              {{ $t('actions.removeNewProducts') }}
            </v-btn>
            <v-btn
              :disabled="isCleanAttributesDisabled"
              depressed
              secondary
              class="outlined-btn mr-2"
              @click="openCleanAttributesModal"
            >
              {{ $t('actions.cleanAttributes') }}
            </v-btn>
            <v-btn
              v-if="
                hasMinimumFacingsEnabled ||
                  hasMinimumDistributionEnabled ||
                  hasAdditionalFieldsEnabled
              "
              class="mr-2"
              depressed
              primary
              @click="openAddOptionalColumnsModal"
            >
              {{ $tkey('attributes.addOptionalColumns') }}
            </v-btn>
            <div data-id-e2e="btnAddAttribute">
              <data-upload
                :disabled="isEditingDisabled"
                :legends="csvUploadLegends"
                :csv-upload-handler="onCSVUpload"
                :source-upload-handler="onSourceDataUpload"
                :scenario-upload-handler="onScenarioDataUpload"
                :custom-attr-handler="onCustomAttrAdd"
                :furniture-upload-handler="onFurnitureUpload"
                :custom-fields="[...customHeaderNames, ...storeClassEligibilityHeaderNames]"
                :fields-to-ignore="Array.from(fieldsToIgnore)"
                :products-data="productsData"
                :used-header-names="usedHeaders"
                show-modal
                enable-field-creation
                attributes-upload
                @process="process"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>

      <!-- Products table -->
      <div class="ag-grid-box flex-grow-1">
        <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-custom ag-theme-custom--attributes"
          :row-data="productsData"
          :grid-options="gridOptions"
          :does-external-filter-pass="doesExternalFilterPass"
          :stop-editing-when-cells-loses-focus="true"
          :enable-range-selection="true"
          @cell-value-changed="trackDiff"
          @grid-ready="onGridReady"
          @column-moved="onColumnMoved"
          @drag-stopped="saveColumnState"
        />
      </div>

      <!-- Actions -->
      <page-actions
        class="page-actions"
        show-export
        is-custom-export
        :has-data-changes="hasDataChanges"
        :has-data-errors="hasDataErrors"
        :save-disabled="isEditingDisabled"
        :is-discard-enabled="!isEditingDisabled"
        @export="exportCSV()"
        @discard="discard()"
        @save="saveChanges()"
      />
    </div>

    <!-- Dialogs and pop-ups -->

    <!-- This dialog is only needed for new products that are not yet saved to db -->
    <main-dialog ref="deleteProduct">
      <template v-slot:header>
        <v-card-text class="display-1 pa-0 text-center">
          <p>{{ $tkey('attributes.deleteProductDialog.question') }}</p>
          <strong>{{ $tkey('attributes.deleteProductDialog.warning') }}</strong>
        </v-card-text>
      </template>
      <template v-slot:content="{ cancel, confirm }">
        <v-card-actions class="justify-center flex-column">
          <v-btn primary class="ma-2" @click="confirm">
            {{ $tkey('attributes.actions.deleteProduct') }}
          </v-btn>
          <v-btn text depressed class="cancel ma-2" @click="cancel">
            {{ $t('actions.cancel') }}
          </v-btn>
        </v-card-actions>
      </template>
    </main-dialog>

    <rest-of-market-products-modal
      v-if="hasRestOfMarketEnabled && isRestOfMarketProductsModalOpen"
      :products-to-exclude="Array.from(lookupROMProductKeys)"
      :product-category-key="commonProductCategoryLevelKey"
      :value="isRestOfMarketProductsModalOpen"
      @close="closeRestOfMarketProductsModal"
      @add-selected-products="addROMProducts"
    />

    <add-optional-columns-modal
      :value="isAddOptionalColumnsModalOpen"
      :optional-attrs="optionalAttributes"
      @add-optional-columns="addOptionalColumns"
      @close="closeAddOptionalColumnsModal"
    />

    <reset-product-data-modal
      :value="isResetDataModalOpen"
      :data-to-reset-enum="fieldEnum"
      @reset-product-data="resetProducts"
      @close="closeResetDataModal"
    />

    <clean-attributes-modal
      :value="isCleanAttributesModalOpen"
      :custom-attributes="currentCustomAttributes"
      :attributes-to-clean="attributesToClean"
      @process="processCleanAttributesConfig"
      @clean-attributes="cleanCustomAttributesInEditor"
      @close="closeCleanAttributesModal"
    />

    <attribute-grouping-modal
      :value="groupingModalOpen"
      :attribute-values="groupingValues"
      :grouping-column="groupingColumn"
      :used-headers="usedHeaders"
      @close="closeGroupingModal"
      @create-attribute-group="createGroupedAttr"
      @keydown.esc="groupingModalOpen = false"
    />

    <dependency-tree-feedback-modal
      :value="dependencyTreeModalOpen"
      :results="dependencyTreeFeedback"
      :warning-message="dependencyTreeWarningMessage"
      page="productAttributeEditor"
      @close="closeDependencyTreeModal"
      @commit="saveChanges(true)"
    />

    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="closeUnsavedDataModal"
      @confirm="closeUnsavedDataModal"
    />
  </v-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import {
  range,
  cloneDeep,
  find,
  isEmpty,
  keyBy,
  unset,
  keys,
  values,
  get,
  set,
  merge,
  pickBy,
  size,
  map,
  mapValues,
  min,
  isString,
  isNull,
  isNil,
  isUndefined,
  isInteger,
  each,
  pick,
  omit,
  omitBy,
  some,
  differenceWith,
  difference,
  differenceBy,
  flattenDeep,
  reduce,
  groupBy,
  union,
  uniq,
  filter,
  includes,
  toLower,
  toUpper,
  findIndex,
  has,
  head,
  upperFirst,
  orderBy,
  trim,
  isNumber,
  intersectionWith,
  isEqual,
  compact,
  every,
} from 'lodash';
import to from 'await-to-js';
import sizeAttributes from '@enums/size-attributes';
import maintainOptions from '@enums/price-change-maintain-option';
import { optionalAttributeEditorAttributes as optionalFieldEnum } from '@enums/productAttributes/optional-attributes';
import productOriginSourceTypes from '@enums/origin-source-types';
import uploadMethods from '@enums/productAttributes/attribute-upload-methods';
import { productTypes } from '@enums/product-types';
import pgMustFlagOptions from '@enums/pg-must-flag-option';
import productValidations from '@sharedModules/data/utils/product-validations';
import exportCSV from '@/js/mixins/export-csv';
import dateUtils from '@/js/mixins/date-utils';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import inputValidationMixin from '@/js/mixins/input-validations';
import agGridUtils from '@/js/utils/ag-grid-utils';
import formatUtils from '@/js/utils/number-format-utils';
import gridPreferences from '@/js/utils/grid-preferences';
import i18n from '@/js/vue-i18n';
import agGridIcon from '@/js/components/ag-grid-cell-renderers/ag-grid-icon.vue';
import imageRenderer from '@/js/components/ag-grid-cell-renderers/image-renderer.vue';
import CellMarginEditor from '@/js/components/ag-grid-cell-renderers/cell-margin-editor.vue';
import MaskedDateEditor from '@/js/components/ag-grid-cell-renderers/mask-date-editor.vue';
import comboboxRenderer from '@/js/components/ag-grid-cell-renderers/combobox-renderer.vue';
import unitSuffixRenderer from '../../../components/ag-grid-cell-renderers/cell-unit-suffix-renderer.vue';
import customAttributesHeaderGroup from './table-components/customAttributesHeaderGroup.vue';

// We need to do translations inside an ag-grid functions where this is not bound to Vue.
const localizationKey = 'scenarioInputPage';
const t = (key, locKey, args = []) => i18n.t(`${locKey}.${key}`, args);

const maximumRowsToAdd = 1000;

function getProductState(productType) {
  // Can't use this.$t inside a valueGetter
  // This is why we have a direct import of translations above
  // Think this is definitely solvable, just not sure how right now.
  const translationKey = `attributes.${productType}Product`;
  const translationValue = t(translationKey, localizationKey);
  if (translationValue.length > 5) return translationValue.slice(0, 5);
  return translationValue;
}

const fieldEnum = {
  isNewProduct: 'isNewProduct',
  inReset: 'inReset',
  productKey: 'productKey',
  productKeyDisplay: 'productKeyDisplay',
  itemDescription: 'itemDescription',
  contentValue: 'contentValue',
  contentUnitOfMeasure: 'contentUnitOfMeasure',
  futureId: 'futureId',
  margin: 'margin',
  originalMarginWithoutFunding: 'originalMarginWithoutFunding',
  marginWithoutFunding: 'marginWithoutFunding',
  price: 'price',
  unitWidth: 'unitWidth',
  unitLength: 'unitLength',
  unitHeight: 'unitHeight',
  productSeries: 'productSeries',
  recentlySold: 'recentlySold',
  assortment: 'assortment',
  parentProduct: 'parentProduct',
  deleteNewProduct: 'deleteNewProduct',
  fromTemplate: 'fromTemplate',
  purchasePriceInRange: 'purchasePrice',
  originalPurchasePrice: 'originalPurchasePrice',
  transferPriceInRange: 'transferPrice',
  originalTransferPrice: 'originalTransferPrice',
  maintainOption: 'maintainOption',
  originalMargin: 'originalMargin',
  originalPrice: 'originalPrice',
  palletContent: 'palletContent',
  averageTransactionWeight: 'averageTransactionWeight',
  kpku: 'kpku',
  storeClassEligibility: 'storeClassEligibility',
  packageTypeDescription: 'packageTypeDescription',
  periodID: 'periodID',
  clientProductKey: 'clientProductKey',
};

const validRange = {
  minimumFacings: { min: 1 }, // max value for facings comes from the client config
  minimumDistribution: { min: 0, max: 100 },
};

export default {
  name: 'AttributeEditor',
  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    agGridIcon,
    imageRenderer,
    comboboxRenderer,
    unitSuffixRenderer,
    customAttributesHeaderGroupComponent: customAttributesHeaderGroup,
    /* eslint-disable vue/no-unused-components */
    CellMarginEditor,
    MaskedDateEditor,
  },

  mixins: [inputValidationMixin, exportCSV, unsavedDataWarningMixin, dateUtils],

  localizationKey,

  data() {
    return {
      lookupDisplayKeys: new Set(),
      lookupNewProductKeys: new Set(),
      lookupROMProductKeys: new Set(),
      lookupChildKeys: new Set(),

      savedValuesPerCustomAttribute: {},

      dependencyTreeModalOpen: false,
      dependencyTreeFeedback: {},
      dependencyTreeWarningMessage: null,

      fieldEnum,
      optionalFieldEnum,

      commonProductCategoryLevelKey: null,

      // ag-grid
      groupingColumn: {},
      groupingValues: [],
      groupingModalOpen: false,
      isRestOfMarketProductsModalOpen: false,
      isAddOptionalColumnsModalOpen: false,
      isResetDataModalOpen: false,
      isCleanAttributesModalOpen: false,
      size,
      customAttributeName: null,
      usedAttributeHeaders: [],
      filterInvalidRows: false,
      collapsedColGroup: false,
      gridOptions: {
        suppressContextMenu: true,
        enableFillHandle: true,
        getMainMenuItems: this.getColumnMenuItems,
        stopEditingWhenCellsLoseFocus: true,
        defaultColGroupDef: {
          marryChildren: true,
        },
        suppressDragLeaveHidesColumns: true,
        defaultColDef: {
          resizable: true,
          filter: true,
          sortable: true,
          minWidth: 80,
          comparator: agGridUtils.sortings.naturalSort,
          menuTabs: ['filterMenuTab', 'generalMenuTab'],
          editable: () => !this.isEditingDisabled,
          suppressMovable: false,
          lockPinned: true,
          required: true,
          headerClass: params => {
            const col = params.column;
            const groupCols = get(col, 'parent.displayedChildren');
            if (!groupCols && groupCols.length === 0) return null;
            return col.colId === head(groupCols).colId ? 'divider-left' : null;
          },
          cellClassRules: {
            'diff-background': this.hasDiff,
            'invalid-nonempty': agGridUtils.validations.validateValueExists,
            'not-editable-cell': agGridUtils.utils.disableEdit,
            'divider-left': params => {
              const col = params.column;
              const groupCols = get(col, 'parent.displayedChildren');
              if (!groupCols && groupCols.length === 0) return false;
              return col.colId === head(groupCols).colId;
            },
          },
          valueParser: agGridUtils.parsers.defaultParser,
          tooltipValueGetter: params => {
            const errors = [];
            if (params.colDef.required && agGridUtils.validations.validateValueExists(params)) {
              errors.push(t('required', 'validationErrors'));
            }
            const value = params.data[params.colDef.field];
            if (
              params.colDef.type === 'numericColumnPermissiveCustom' &&
              agGridUtils.validations.valueIsNotNumericPermissive(params)
            ) {
              const maximumAllowedValue = this.formatNumber({
                number: Number.MAX_SAFE_INTEGER,
                format: 'float',
              });
              const errorKey = agGridUtils.validations.valueIsUnsafeInteger({ value })
                ? 'numberIsTooBig'
                : 'number';
              errors.push(t(errorKey, 'validationErrors', [maximumAllowedValue]));
            }

            // For price or margin, run the specific validations.
            // Push the specific tooltip message for those, since validation only checks for
            // numberMustBeBiggerThan if in assortment condition.
            if (
              [fieldEnum.price, this.marginField].includes(params.colDef.field) &&
              this.isMarginOrPriceInvalid(params)
            ) {
              errors.push(t('numberMustBeBiggerThan', 'validationErrors', [0]));
            }

            const isMinimumFacingValid =
              params.colDef.field === optionalFieldEnum.minimumFacings.field &&
              params.data.assortment;
            if (
              isMinimumFacingValid &&
              agGridUtils.validations.valueIsLess(value, validRange.minimumFacings.min)
            ) {
              errors.push(
                t('numberMustBeBiggerOrEqual', 'validationErrors', [validRange.minimumFacings.min])
              );
            }
            if (
              isMinimumFacingValid &&
              agGridUtils.validations.valueIsGreater(value, this.getProductMinimumFacings)
            ) {
              errors.push(
                t('numberMustBeLessOrEqual', 'validationErrors', [this.getProductMinimumFacings])
              );
            }
            if (
              isMinimumFacingValid &&
              agGridUtils.validations.valueIsNotPositiveInteger({ value })
            ) {
              errors.push(t('integer', 'validationErrors'));
            }

            const isMinimumDistributionValid =
              params.colDef.field === optionalFieldEnum.minimumDistribution.field &&
              (params.data.assortment || this.hasAdditionalFieldsEnabled);
            if (
              isMinimumDistributionValid &&
              agGridUtils.validations.valueIsLess(value, validRange.minimumDistribution.min) &&
              !this.hasAdditionalFieldsEnabled
            ) {
              errors.push(
                t('numberMustBeBiggerOrEqual', 'validationErrors', [
                  validRange.minimumDistribution.min,
                ])
              );
            }
            if (
              isMinimumDistributionValid &&
              agGridUtils.validations.valueIsGreater(value, validRange.minimumDistribution.max) &&
              !this.hasAdditionalFieldsEnabled
            ) {
              errors.push(
                t('numberMustBeLessOrEqual', 'validationErrors', [
                  validRange.minimumDistribution.max,
                ])
              );
            }

            const isMaximumDistributionField =
              params.colDef.field === optionalFieldEnum.maximumDistribution.field;
            const isSeasonalWeekNumbersField =
              params.colDef.field === optionalFieldEnum.seasonalWeekNumbers.field;

            if (
              (isMinimumDistributionValid ||
                isMaximumDistributionField ||
                isSeasonalWeekNumbersField) &&
              agGridUtils.validations.valueIsNotPositiveInteger({ value })
            ) {
              errors.push(t('integer', 'validationErrors'));
            }
            // AOV3-693 zero (0) numeric value needed to trick ag-grid to include tooltip component but not actually display tooltip on valid values
            // TooltipFeature added to cell if value "exists" (not null and not empty string) https://github.com/ag-grid/ag-grid/blob/v23.2.1/community-modules/core/src/ts/rendering/cellComp.ts#L221
            // TooltipFeature.showTooltip checks with !tooltip if value is showable https://github.com/ag-grid/ag-grid/blob/v23.2.1/community-modules/core/src/ts/widgets/tooltipFeature.ts#L171
            return errors.join('\n');
          },
          suppressKeyboardEvent: agGridUtils.utils.suppressKeyboardEvent,
        },
        processDataFromClipboard: agGridUtils.utils.processDataFromClipboard,
        processCellFromClipboard: agGridUtils.utils.processCellFromClipboard,
        tooltipShowDelay: 0,
        isExternalFilterPresent() {
          return true;
        },
        getRowId(row) {
          const { data } = row;
          return data.productKey;
        },
        onColumnResized: () => {
          // Make sure all columns are resized and registered in grid on resize
          const index = this.headers.findIndex(h => h.groupId === 'custom-attributes');
          const customAttrsGroup = this.headers[index];
          const usedSources = uniq(compact(map(customAttrsGroup.children, 'source')));
          const sources = [
            uploadMethods.CSV,
            uploadMethods.FEED,
            uploadMethods.SCENARIO,
            uploadMethods.CUSTOM,
          ];
          if (intersectionWith(usedSources, sources, isEqual).length) this.gridApi.redrawRows();
        },
        rowHeight: 30,
        headerHeight: 40,
        columnTypes: {
          numericColumnPermissiveCustom: {
            ...agGridUtils.columnTypes.numericColumnPermissiveCustom,
            // AOV3-1423 TODO: move cellClassRules to agGridUtils.columnTypes.numericColumnCustom ?
            cellClassRules: {
              'diff-background': this.hasDiff,
              'invalid-nonempty': agGridUtils.validations.validateValueExists,
              'invalid-border': agGridUtils.validations.valueIsNotNumericPermissive,
              'not-editable-cell': agGridUtils.utils.disableEdit,
            },
          },
          booleanColumnCustom: agGridUtils.columnTypes.booleanColumnCustom,
        },
        statusBar: {
          statusPanels: [
            { statusPanel: 'agTotalRowCountComponent', align: 'left' },
            { statusPanel: 'agFilteredRowCountComponent', align: 'left' },
            { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
            {
              statusPanel: 'agAggregationComponent',
              statusPanelParams: {
                aggFuncs: ['count'],
              },
              align: 'left',
            },
          ],
        },
        onFilterChanged: () => {
          this.filterModel = this.gridApi.getFilterModel();
        },
      },
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      searchString: '',
      errorData: {},
      headers: [],

      keyIdMap: {},
      rules: [
        value => this.isLessThan(value, maximumRowsToAdd),
        value => this.isGreaterThan(value, 0),
        value => isEmpty(value) || this.isInteger(value),
      ],

      filter: 'all_active',
      filters: [{ text: this.$tkey('filter_all_active'), value: 'all_active' }],
      productsData: null,
      newProductRowsNumber: '',

      currentStateDiff: {},
      currentScenarioStateDiff: {}, // diffs against scenario
      // new attributes which came from csv, used in computed props
      newAttributes: [],
      updatedAttributes: {},
      attributesToClean: new Set(),
      savedAttributes: [],
      storeClassEligibilityFields: [],
      storeClassEligibilityHeaders: [],

      csvUploadLegends: {
        buttonName: this.$t('actions.addAttr'),
        title: this.$tkey('attributes.addAttributesTitle'),
      },
      uploadedDataDetails: null,
      dataToUploadFromSource: {
        source: null,
        attributes: [],
      },
      optionalSchemaFields: new Set(),
      fieldsToIgnore: new Set(),

      deletedAttributes: [],
      deletedProducts: [],
      addedProducts: [],
      newProductCount: 0,

      productsWithoutEligibility: [],

      isResetFromTemplate: false,
      productsWithoutPurchasePriceInformation: [],

      commonProductCategoryKey: null,
      isSyncingTransferPrice: false,

      extraValidations: {},
      filterModel: null,

      backupAdditionalFields: {},
    };
  },

  computed: {
    ...mapState('context', ['weeksNotSoldForDiscontinued', 'clientConfig']),
    ...mapState('furniture', ['scenarioFurniture']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('scenarioProducts', ['loading']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('toolData', ['productHierarchy']),
    ...mapGetters('workpackages', ['isSimpleSwapsWP']),
    ...mapGetters('workpackageProducts', ['workpackageProducts']),
    ...mapGetters('context', [
      'getCsvExport',
      'getDateFormats',
      'getClientConfig',
      'getCurrentNumericLocale',
      'getCurrentLocale',
      'getProductMinimumFacings',
      'getExcelDateFormat',
    ]),

    // This is the definition of validations used for the standard product attribute fields.
    // This should be used as a source of truth for manual error checks.
    // AG-Grid column definitions do not refer to this enum, because there's specific css styling for different issues.
    // Also, tooltips are built differently for different errors.
    fieldValidations() {
      return {
        productKeyDisplay: [this.hasDuplicates],
        futureId: [this.hasDuplicates],
        contentValue: [agGridUtils.validations.valueIsNotNumericPermissive],
        unitLength: [agGridUtils.validations.valueIsNotNumericPermissive],
        unitWidth: [agGridUtils.validations.valueIsNotNumericPermissive],
        unitHeight: [agGridUtils.validations.valueIsNotNumericPermissive],
        [this.marginField]: [
          agGridUtils.validations.validateValueExists,
          agGridUtils.validations.valueIsNotNumericPermissive,
          this.isMarginOrPriceInvalid,
        ],
        price: [
          agGridUtils.validations.validateValueExists,
          agGridUtils.validations.valueIsNotNumericPermissive,
          this.isMarginOrPriceInvalid,
        ],
        parentProduct: [this.isParentProductInvalid],
        palletContent: [this.isPalletContentInvalid],
        averageTransactionWeight: [
          agGridUtils.validations.valueIsNotNumericPermissive,
          this.isTransactionWeightInvalid,
        ],
        minimumFacings: [
          agGridUtils.validations.validateValueExists,
          agGridUtils.validations.valueIsNotNumericPermissive,
          this.isMinimumFacingsInvalid,
        ],
        minimumDistribution: [
          agGridUtils.validations.validateValueExists,
          agGridUtils.validations.valueIsNotNumericPermissive,
          this.isMinimumDistributionInvalid,
        ],
        maximumDistribution: [
          agGridUtils.validations.validateValueExists,
          agGridUtils.validations.valueIsNotNumericPermissive,
        ],
        seasonalWeekNumbers: [
          agGridUtils.validations.validateValueExists,
          agGridUtils.validations.valueIsNotNumericPermissive,
        ],
        pgMustFlag: [this.hasInvalidPgMustFlagOption],
        periodID: [this.hasInvalidPeriodIds],
        clientProductKey: [this.hasInvalidClientProductKey],
        assortment: [this.hasInvalidPeriodIds],
        ...this.extraValidations,
      };
    },

    marginField() {
      return this.usesMarginWithoutFunding ? fieldEnum.marginWithoutFunding : fieldEnum.margin;
    },

    originalMarginField() {
      return this.usesMarginWithoutFunding
        ? fieldEnum.originalMarginWithoutFunding
        : fieldEnum.originalMargin;
    },

    hasCleanAttributesUpperCaseEnabled() {
      return get(this.getClientConfig, 'features.cleanAttributesUpperCaseEnabled', false);
    },

    requiredCurrency() {
      return this.$t(`currencies.${this.currentNumericLocale}`);
    },

    showResetFromTemplateButton() {
      return this.templatesEnabled && !!this.selectedWorkpackage.templateId;
    },

    currentNumericLocale() {
      return this.getCurrentNumericLocale;
    },

    currentLocale() {
      return this.getCurrentLocale;
    },

    optionalAttributes() {
      if (this.hasAdditionalFieldsEnabled) {
        return [
          {
            field: 'seasonalWeekNumbers',
            name: `${this.$tkey('attributes.seasonalWeekNumbers')}`,
            value: get(
              this.selectedScenario,
              'optionalAttributes.additionalFields.seasonalWeekNumbers'
            ),
          },
          {
            field: 'userEnteredSisterProduct',
            name: `${this.$tkey('attributes.userEnteredSisterProduct')}`,
            value: get(
              this.selectedScenario,
              'optionalAttributes.additionalFields.userEnteredSisterProduct'
            ),
          },
          {
            field: 'seasonalProduct',
            name: `${this.$tkey('attributes.seasonalProduct')}`,
            value: get(
              this.selectedScenario,
              'optionalAttributes.additionalFields.seasonalProduct'
            ),
          },
          {
            field: 'pgMustFlag',
            name: `${this.$tkey('attributes.pgMustFlag')}`,
            value: get(this.selectedScenario, 'optionalAttributes.additionalFields.pgMustFlag'),
          },
          {
            field: 'minimumDistribution',
            name: `${this.$tkey('attributes.minimumDistributionWoPercentage')}`,
            value: get(
              this.selectedScenario,
              'optionalAttributes.additionalFields.minimumDistribution'
            ),
          },
          {
            field: 'maximumDistribution',
            name: `${this.$tkey('attributes.maximumDistribution')}`,
            value: get(
              this.selectedScenario,
              'optionalAttributes.additionalFields.maximumDistribution'
            ),
          },
          {
            field: 'localProduct',
            name: `${this.$tkey('attributes.localProduct')}`,
            value: get(this.selectedScenario, 'optionalAttributes.additionalFields.localProduct'),
          },
        ];
      }

      return [
        {
          field: 'minimumFacings',
          name: `${this.$tkey('attributes.minimumFacings')}`,
          value: get(this.selectedScenario, 'optionalAttributes.useMinimumFacings'),
        },
        {
          field: 'minimumDistribution',
          name: `${this.$tkey('attributes.minimumDistribution')}`,
          value: get(this.selectedScenario, 'optionalAttributes.useMinimumDistribution'),
        },
      ];
    },

    transactionWeightDisabled() {
      const transactionWeightEnabled = get(
        this.clientConfig,
        'features.transactionWeightEnabled',
        false
      );
      return !transactionWeightEnabled || this.isTemplate;
    },

    shouldValidateDuplicateProductKeys() {
      return get(
        this.getClientConfig,
        'features.productAttributeEditorPage.duplicateKeyValidation',
        false
      );
    },

    initialHeaders() {
      const columns = {
        pinnedHeaders: {
          openByDefault: true,
          groupId: 'product-details',
          marryChildren: true,
          groupLockGroupColumns: -1,
          children: [
            {
              field: fieldEnum.inReset,
              colId: fieldEnum.inReset,
              headerName: '',
              menuTabs: ['filterMenuTab'],
              filter: 'agTextColumnFilter',
              suppressMenu: false,
              suppressMovable: true,
              lockPosition: 'left',
              filterParams: agGridUtils.filters.standardStringFilter,
              editable: false,
              maxWidth: 50,
              minWidth: 50,
              resizable: false,
              pinned: 'left',
              required: true,
              hide: !this.hasProductsInResetEnabled,
              cellRenderer: 'imageRenderer',
              cellRendererParams: params => {
                if (!params.data.inReset) {
                  return {
                    imgComponent: 'not-in-reset-icon',
                    classes: this.isEditingDisabled ? 'disabled' : '',
                    title: this.$t('general.productNotSelectedToBeInReset'),
                  };
                }
              },
            },
            {
              field: fieldEnum.isNewProduct,
              colId: fieldEnum.isNewProduct,
              headerName: '',
              editable: false,
              maxWidth: 60,
              minWidth: 60,
              resizable: false,
              suppressMovable: true,
              lockPosition: 'left',
              cellClass: 'justified-center border-left limit-focus-padding chip-cell',
              cellRenderer: params => {
                const productType = this.getProductType(params.data);
                return productType !== productTypes.existing
                  ? `
                  <span class="chip ${productType}">
                    <span class="chip__content">
                      ${getProductState(productType)}
                    </span>
                  </span>
                `
                  : '';
              },
              valueGetter: params => {
                const productType = this.getProductType(params.data);
                return getProductState(productType);
              },
              filterParams: {
                valueFormatter: params => params.value,
              },
              pinned: 'left',
            },
            {
              field: fieldEnum.productKey,
              colId: fieldEnum.productKey,
              headerName: '',
              editable: false,
              suppressMenu: true,
              suppressMovable: true,
              lockPosition: 'left',
              hide: true,
              required: true,
            },
            {
              field: fieldEnum.productKeyDisplay,
              colId: fieldEnum.productKeyDisplay,
              headerName: this.$tkey('attributes.productKeyDisplay'),
              filter: 'agTextColumnFilter',
              menuTabs: ['filterMenuTab'],
              suppressMenu: false,
              suppressMovable: true,
              lockPosition: 'left',
              editable: params => params.data.isNewProduct && !this.isEditingDisabled,
              width: 100,
              pinned: 'left',
              cellClass: 'bold-text',
              required: true,
              tooltipValueGetter: params => this.hasDuplicates(params),
              cellClassRules: {
                'invalid-border': this.hasDuplicates,
              },
            },
          ],
        },
        productKpis: {
          headerName: this.$tkey('attributes.tableHeaders.productKPIs'),
          headerClass: 'product-kpis',
          openByDefault: true,
          groupId: 'product-kpis',
          children: [
            // itemDescription is in the colGroup for the product-kpis as we need to retract them and leave one item in the group fixed
            // otherwise the colgroup will disappear entirely.
            {
              field: fieldEnum.itemDescription,
              colId: fieldEnum.itemDescription,
              headerName: this.$tkey('attributes.itemDescription'),
              menuTabs: ['filterMenuTab'],
              filter: 'agTextColumnFilter',
              suppressMenu: false,
              editable: params => params.data.isNewProduct && !this.isEditingDisabled,
              filterParams: agGridUtils.filters.standardStringFilter,
              minWidth: 250,
              flex: 1,
              pinned: 'left',
              required: true,
            },
            {
              hide: !this.hasKPKUEnabled,
              field: fieldEnum.kpku,
              colId: fieldEnum.kpku,
              headerName: this.$tkey('attributes.kpku'),
              suppressMenu: false,
              width: 100,
              required: false,
              pinned: 'left',
            },
            {
              hide: !this.hasClientProductKeyColumnEnabled,
              field: fieldEnum.clientProductKey,
              colId: fieldEnum.clientProductKey,
              headerName: this.$tkey('attributes.clientProductKey'),
              suppressMenu: false,
              width: 100,
              editable: this.canEditClientID,
              cellClassRules: {
                'invalid-nonempty': this.hasInvalidClientProductKey,
              },
              required: true,
              tooltipValueGetter: params => {
                if (this.hasInvalidClientProductKey(params)) {
                  return [this.$t('validationErrors.missingClientProductKey')];
                }
              },
              pinned: 'left',
            },
            {
              field: fieldEnum.contentValue,
              colId: fieldEnum.contentValue,
              headerName: this.$tkey('attributes.contentValue'),
              type: 'numericColumnPermissiveCustom',
              width: 105,
              required: true,
            },
            {
              field: fieldEnum.contentUnitOfMeasure,
              colId: fieldEnum.contentUnitOfMeasure,
              headerName: this.$tkey('attributes.contentUnitOfMeasure'),
              suppressMenu: false,
              filter: true,
              width: 110,
              required: true,
            },
            {
              field: fieldEnum.averageTransactionWeight,
              colId: fieldEnum.averageTransactionWeight,
              headerName: this.$tkey('attributes.averageTransactionWeight'),
              suppressMenu: false,
              width: 110,
              required: false,
              editable: true,
              type: 'numericColumnPermissiveCustom',
              hide: this.transactionWeightDisabled,
              filter: 'agTextColumnFilter',
              cellRenderer: 'unitSuffixRenderer',
              cellRendererParams: {
                valueGetter: ({ data }) => data.averageTransactionWeight,
                suffixGetter: ({ data }) => data.contentUnitOfMeasure,
              },
              valueFormatter: ({ data }) => {
                return formatUtils.formatNumberIfNumeric({
                  value: data.averageTransactionWeight,
                  format: 'numberWithThreeDecimals',
                });
              },
              tooltipValueGetter: this.isTransactionWeightInvalid,
              cellClassRules: {
                'diff-background': this.hasDiff,
                'invalid-nonempty': this.isTransactionWeightInvalid,
              },
            },
            {
              field: fieldEnum.packageTypeDescription,
              colId: fieldEnum.packageTypeDescription,
              headerName: this.$tkey('attributes.packageTypeDescription'),
              hide: !this.isPackageTypeDescriptionColumnVisible,
              suppressMenu: false,
              editable: this.canEditClientID,
              filter: true,
              width: 110,
            },
            {
              field: fieldEnum.futureId,
              colId: fieldEnum.futureId,
              headerName: this.$tkey('attributes.futureId'),
              width: 105,
              required: false,
              tooltipValueGetter: params => this.hasDuplicates(params),
              cellClassRules: {
                'invalid-border': this.hasDuplicates,
              },
            },
            {
              field: fieldEnum.unitWidth,
              colId: fieldEnum.unitWidth,
              headerName: `${this.$tkey('attributes.unitWidth')}`,
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              width: 95,
            },
            {
              field: fieldEnum.unitLength,
              colId: fieldEnum.unitLength,
              headerName: `${this.$tkey('attributes.unitLength')}`,
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              width: 95,
            },
            {
              field: fieldEnum.unitHeight,
              colId: fieldEnum.unitHeight,
              headerName: `${this.$tkey('attributes.unitHeight')}`,
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              width: 100,
            },
            {
              field: fieldEnum.palletContent,
              colId: fieldEnum.palletContent,
              hide: !this.hasPalletContentEnabled,
              headerName: this.$tkey('attributes.palletContent'),
              valueParser: params => get(params, 'newValue', ''),
              valueSetter: params => {
                params.data[fieldEnum.palletContent] =
                  isNil(params.newValue) || isEmpty(String(params.newValue).trim())
                    ? ''
                    : String(params.newValue);

                // If pallet content has been set, update assortment field too
                if (get(params, 'data.palletContent', false)) {
                  params.node.setDataValue(fieldEnum.assortment, false);
                } else if (!get(params, 'data.parentProduct', false)) {
                  params.node.setDataValue(fieldEnum.assortment, true);
                }

                return true;
              },
              tooltipValueGetter: params => {
                return this.isPalletContentInvalid(params);
              },
              cellClassRules: {
                'diff-background': this.hasDiff,
                'invalid-border': this.isPalletContentInvalid,
                'not-editable-cell': agGridUtils.utils.disableEdit,
              },
              width: 105,
              required: false,
            },
            {
              field: fieldEnum.parentProduct,
              colId: fieldEnum.parentProduct,
              headerName: this.$tkey('attributes.parentProduct'),
              cellClassRules: {
                'invalid-border': this.isParentProductInvalid,
                'diff-background': this.hasDiff,
                'not-editable-cell': agGridUtils.utils.disableEdit,
              },
              editable: params =>
                !get(params, 'data.isNewProduct', false) && !this.isEditingDisabled,
              cellClass: params => get(params, 'data.isNewProduct', false) && 'disabled-cell',
              tooltipValueGetter: params => {
                if (get(params, 'data.isNewProduct', false)) {
                  return [this.$tkey('attributes.validation.newProductCanNotHaveParentProduct')];
                }
                return this.isParentProductInvalid(params);
              },
              // handle case where saved value is empty string, then cell is clicked but no value is inputted.
              valueParser: params => get(params, 'newValue', ''),
              valueSetter: params => {
                params.data[fieldEnum.parentProduct] =
                  isNil(params.newValue) || isEmpty(String(params.newValue).trim())
                    ? ''
                    : String(params.newValue);
                this.trackParentProductKeys();
                if (params.oldValue !== '' && !params.newValue) {
                  const displayKey = get(params, 'data.productKeyDisplay');
                  this.lookupChildKeys.delete(displayKey);
                }

                // If parent product has been set, update assortment field too
                if (get(params, 'data.parentProduct', false)) {
                  params.node.setDataValue(fieldEnum.assortment, false);
                }

                return true;
              },
              width: 105,
              required: false,
            },
          ],
        },
        productFinancials: {
          headerName: this.$tkey('attributes.tableHeaders.financials'),
          headerClass: 'financials divider-left',
          openByDefault: false,
          groupId: 'financials',
          children: [
            {
              hide: !this.hasTransferPriceRefreshUpdatesPriceAndMarginEnabled,
              field: fieldEnum.originalPurchasePrice,
              colId: fieldEnum.originalPurchasePrice,
              headerName: this.$tkey('attributes.originalPurchasePrice'),
              filter: 'agTextColumnFilter',
              type: 'numericColumnPermissiveCustom',
              width: 100,
              editable: false,
              required: false,
            },
            {
              field: fieldEnum.purchasePriceInRange,
              colId: fieldEnum.purchasePriceInRange,
              filter: 'agTextColumnFilter',
              hide: !this.hasTransferPriceEnabled,
              type: 'numericColumnPermissiveCustom',
              width: 100,
              editable: false,
              required: false,
            },
            {
              hide: !this.hasTransferPriceRefreshUpdatesPriceAndMarginEnabled,
              field: fieldEnum.originalTransferPrice,
              colId: fieldEnum.originalTransferPrice,
              headerName: this.$tkey('attributes.originalTransferPrice'),
              filter: 'agTextColumnFilter',
              type: 'numericColumnPermissiveCustom',
              width: 100,
              editable: false,
              required: false,
            },
            {
              field: fieldEnum.transferPriceInRange,
              colId: fieldEnum.transferPriceInRange,
              filter: 'agTextColumnFilter',
              hide: !this.hasTransferPriceEnabled,
              type: 'numericColumnPermissiveCustom',
              width: 100,
              editable: false,
              required: false,
            },
            {
              field: fieldEnum.margin,
              colId: fieldEnum.margin,
              headerName: this.$tkey('attributes.margin'),
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              width: 100,
              required: true,
              valueFormatter: ({ value }) => this.formatMargin(value),
              cellEditor: 'CellMarginEditor',
              cellClassRules: {
                'diff-background': this.hasDiff,
                'underlined-overridden': params => {
                  const originalValue = params.data[this.originalMarginField];
                  const margin = get(params, `data.${this.marginField}`);

                  return agGridUtils.comparators.didValueChange(margin, originalValue);
                },
                'invalid-nonempty': agGridUtils.validations.validateValueExists,
                'invalid-border': this.isMarginOrPriceInvalid,
              },
            },
            {
              field: fieldEnum.price,
              colId: fieldEnum.price,
              headerName: `${this.$tkey('attributes.price')} ${this.$tkey('attributes.priceUnit', {
                currency: this.requiredCurrency,
              })}`,
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              width: 90,
              required: true,
              cellClassRules: {
                'diff-background': this.hasDiff,
                'invalid-nonempty': agGridUtils.validations.validateValueExists,
                'not-editable-cell': agGridUtils.utils.disableEdit,
                'invalid-border': this.isMarginOrPriceInvalid,
                'underlined-overridden': params =>
                  agGridUtils.comparators.didValueChange(
                    params.data.price,
                    params.data.originalPrice
                  ),
              },
            },
            {
              columnGroupShow: 'open',
              field: this.originalMarginField,
              colId: fieldEnum.originalMargin,
              headerName: this.$tkey('attributes.originalMargin'),
              headerTooltip: `${this.$tkey('attributes.tooltips.originallyCalculatedMargin')}`,
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              width: 100,
              editable: false,
              required: false,
              valueFormatter: params => this.formatMargin(params.value),
            },
            {
              columnGroupShow: 'open',
              field: fieldEnum.originalPrice,
              colId: fieldEnum.originalPrice,
              headerName: `${this.$tkey('attributes.originalPrice')} ${this.$tkey(
                'attributes.priceUnit',
                {
                  currency: this.requiredCurrency,
                }
              )}`,
              headerTooltip: `${this.$tkey('attributes.tooltips.originallyCalculatedPrice')}`,
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              width: 90,
              editable: false,
              required: false,
            },
            {
              hide: !this.hasTransferPriceRefreshUpdatesPriceAndMarginEnabled,
              field: fieldEnum.maintainOption,
              colId: fieldEnum.maintainOption,
              headerName: this.$tkey('attributes.maintainOption'),
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                values: Object.values(maintainOptions),
              },
              width: 100,
              valueFormatter: params =>
                this.$tkey(
                  `attributes.maintainOptions.${params.value ? params.value : 'doNothing'}`
                ),
              valueParser: params => {
                // When copying a 'null' value, it appears to come through as a whitespace character.
                // When pasting from Excel, it pastes as an empty string.
                // This is to force it back to the doNothing value.
                return isEmpty(trim(params.newValue)) ? maintainOptions.doNothing : params.newValue;
              },
              required: false,
            },
            {
              field: fieldEnum.periodID,
              colId: fieldEnum.periodID,
              headerName: this.$tkey('attributes.periodID'),
              hide: !this.hasPeriodIDColumnEnabled,
              filter: 'agTextColumnFilter',
              editable: false,
              filterParams: agGridUtils.filters.standardStringFilter,
              suppressMenu: false,
              minWidth: 150,
              flex: 1,
              cellRenderer: 'comboboxRenderer',
              cellRendererParams: () => ({
                options: [...this.availablePeriodIds],
              }),
              cellClassRules: {
                'invalid-nonempty': this.hasInvalidPeriodIds,
              },
              required: false,
              tooltipValueGetter: params => {
                const periodID = get(params, 'data.periodID', null);
                const includedForAssortment = get(params, 'data.assortment', false);

                if (!this.availablePeriodIds.length || this.availablePeriodIds.includes(null)) {
                  return;
                }

                if (includedForAssortment && (isEmpty(periodID) || isNil(periodID))) {
                  return [this.$tkey('attributes.validation.requiredPeriodID')];
                }

                if (!includedForAssortment && (isEmpty(periodID) || isNil(periodID))) {
                  return;
                }

                if (!this.availablePeriodIds.includes(periodID)) {
                  return [this.$tkey('attributes.validation.invalidPeriodID')];
                }
              },
            },
          ],
        },
        productInformation: {
          headerName: this.$tkey('attributes.tableHeaders.productInformation'),
          headerClass: 'product-information divider-left',
          openByDefault: false,
          groupId: 'product-information',
          children: [
            {
              field: fieldEnum.productSeries,
              colId: fieldEnum.productSeries,
              headerName: this.$tkey('attributes.productSeries'),
              hide: !this.productSeriesEnabled,
              menuTabs: ['filterMenuTab'],
              filter: 'agTextColumnFilter',
              editable: !this.isEditingDisabled,
              filterParams: agGridUtils.filters.standardStringFilter,
              suppressMenu: false,
              minWidth: 150,
              flex: 1,
              cellRenderer: 'comboboxRenderer',
              cellRendererParams: () => ({
                options: this.distinctProductSeries,
              }),
              required: false,
            },
            {
              field: fieldEnum.recentlySold,
              colId: fieldEnum.recentlySold,
              headerName: `${this.$tkey('attributes.recentlySold')}`,
              type: 'booleanColumnCustom',
              width: 90,
              boolean: true,
              editable: false,
              headerTooltip: `${this.$tkey('attributes.tooltips.recentlySold', {
                numberWeeks: this.weeksNotSoldForDiscontinued,
              })}`,
            },
            {
              field: fieldEnum.assortment,
              colId: fieldEnum.assortment,
              suppressMenu: true, // values are inverted and show up wrong here
              type: 'booleanColumnCustom',
              headerName: this.$tkey('attributes.assortment'),
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                values: [true, false],
              },
              width: 90,
              valueParser: agGridUtils.parsers.booleanParser,
              valueFormatter: agGridUtils.formatters.booleanFormatter,
              cellClassRules: {
                'diff-background': this.hasDiff,
                'not-editable-cell': params => get(params, 'data.parentProduct', false),
              },
              boolean: true,
              editable: params =>
                !this.isEditingDisabled &&
                !(get(params, 'data.parentProduct', false) && !this.isParentProductInvalid(params)),
              tooltipValueGetter: params => {
                if (get(params, 'data.parentProduct', false)) {
                  return [
                    this.$tkey('attributes.validation.parentProductRelationChildNotInAssortment'),
                  ];
                }
                return 0;
              },
            },
            {
              field: fieldEnum.fromTemplate,
              colId: fieldEnum.fromTemplate,
              boolean: true,
              type: 'booleanColumnCustom',
              editable: false,
              headerName: this.$tkey('attributes.fromTemplate'),
              hide: !this.templatesEnabled || this.isTemplate,
              width: 95,
              valueFormatter: agGridUtils.formatters.booleanStringFormatter,
              filterParams: {
                valueFormatter: agGridUtils.formatters.booleanStringFormatter,
              },
            },
            {
              field: optionalFieldEnum.minimumFacings.field,
              colId: optionalFieldEnum.minimumFacings.field,
              headerName: `${this.$tkey('attributes.minimumFacings')}`,
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              width: 90,
              required: this.showMinimumFacings,
              hide: !this.showMinimumFacings,
              cellClassRules: {
                'diff-background': this.hasDiff,
                'invalid-nonempty': agGridUtils.validations.validateValueExists,
                // range 1 to 10
                'invalid-border': this.isMinimumFacingsInvalid,
              },
            },
            {
              field: optionalFieldEnum.minimumDistribution.field,
              colId: optionalFieldEnum.minimumDistribution.field,
              headerName: `${this.$tkey('attributes.minimumDistribution')}`,
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              width: 90,
              required: this.showMinimumDistribution,
              hide: !this.showMinimumDistribution,
              cellClassRules: {
                'diff-background': this.hasDiff,
                'invalid-nonempty': agGridUtils.validations.validateValueExists,
                // range 0 to 100
                'invalid-border': this.isMinimumDistributionInvalid,
              },
            },
            {
              field: optionalFieldEnum.seasonalWeekNumbers.field,
              colId: optionalFieldEnum.seasonalWeekNumbers.field,
              headerName: this.$tkey(`attributes.${optionalFieldEnum.seasonalWeekNumbers.field}`),
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              width: 90,
              required: this.showAdditionalField(optionalFieldEnum.seasonalWeekNumbers.field),
              hide: !this.showAdditionalField(optionalFieldEnum.seasonalWeekNumbers.field),
              cellClassRules: {
                'diff-background': this.hasDiff,
                'invalid-nonempty': agGridUtils.validations.validateValueExists,
              },
            },
            {
              field: optionalFieldEnum.userEnteredSisterProduct.field,
              colId: optionalFieldEnum.userEnteredSisterProduct.field,
              headerName: this.$tkey(
                `attributes.${optionalFieldEnum.userEnteredSisterProduct.field}`
              ),
              required: false,
              hide: !this.showAdditionalField(optionalFieldEnum.userEnteredSisterProduct.field),
              suppressMenu: false,
              filter: true,
              width: 110,
              valueSetter: params => {
                params.data[
                  optionalFieldEnum.userEnteredSisterProduct.field
                ] = agGridUtils.validations.valueIsFalsy({ value: params.newValue })
                  ? ''
                  : String(params.newValue);
                return true;
              },
            },
            {
              field: optionalFieldEnum.seasonalProduct.field,
              colId: optionalFieldEnum.seasonalProduct.field,
              suppressMenu: true, // values are inverted and show up wrong here
              type: 'booleanColumnCustom',
              headerName: this.$tkey(`attributes.${optionalFieldEnum.seasonalProduct.field}`),
              required: this.showAdditionalField(optionalFieldEnum.seasonalProduct.field),
              hide: !this.showAdditionalField(optionalFieldEnum.seasonalProduct.field),
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                values: [true, false],
              },
              width: 90,
              valueParser: agGridUtils.parsers.booleanParser,
              valueFormatter: agGridUtils.formatters.booleanFormatter,
              cellClassRules: {
                'diff-background': this.hasDiff,
              },
              boolean: true,
              editable: true,
            },
            {
              field: optionalFieldEnum.pgMustFlag.field,
              colId: optionalFieldEnum.pgMustFlag.field,
              headerName: this.$tkey(`attributes.${optionalFieldEnum.pgMustFlag.field}`),
              hide: !this.showAdditionalField(optionalFieldEnum.pgMustFlag.field),
              filter: 'agTextColumnFilter',
              editable: false,
              filterParams: agGridUtils.filters.standardStringFilter,
              suppressMenu: false,
              minWidth: 150,
              flex: 1,
              cellRenderer: 'comboboxRenderer',
              cellRendererParams: () => ({
                options: [...Object.values(pgMustFlagOptions)],
                preventValueToLower: true,
              }),
              cellClassRules: {
                'invalid-nonempty': this.hasInvalidPgMustFlagOption,
              },
              required: false,
            },
            {
              field: optionalFieldEnum.additionalMinimumDistribution.field,
              colId: `${optionalFieldEnum.additionalMinimumDistribution.field}AdditionalAttribute`,
              headerName: this.$tkey(
                `attributes.${optionalFieldEnum.additionalMinimumDistribution.field}WoPercentage`
              ),
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              width: 90,
              required: this.showAdditionalField(
                optionalFieldEnum.additionalMinimumDistribution.field
              ),
              hide: !this.showAdditionalField(
                optionalFieldEnum.additionalMinimumDistribution.field
              ),
              cellClassRules: {
                'diff-background': this.hasDiff,
                'invalid-nonempty': agGridUtils.validations.validateValueExists,
              },
            },
            {
              field: optionalFieldEnum.maximumDistribution.field,
              colId: optionalFieldEnum.maximumDistribution.field,
              headerName: this.$tkey(`attributes.${optionalFieldEnum.maximumDistribution.field}`),
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              width: 90,
              required: this.showAdditionalField(optionalFieldEnum.maximumDistribution.field),
              hide: !this.showAdditionalField(optionalFieldEnum.maximumDistribution.field),
              cellClassRules: {
                'diff-background': this.hasDiff,
                'invalid-nonempty': agGridUtils.validations.validateValueExists,
              },
            },
            {
              field: optionalFieldEnum.localProduct.field,
              colId: optionalFieldEnum.localProduct.field,
              suppressMenu: true, // values are inverted and show up wrong here
              type: 'booleanColumnCustom',
              headerName: this.$tkey(`attributes.${optionalFieldEnum.localProduct.field}`),
              required: this.showAdditionalField(optionalFieldEnum.localProduct.field),
              hide: !this.showAdditionalField(optionalFieldEnum.localProduct.field),
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                values: [true, false],
              },
              width: 90,
              valueParser: agGridUtils.parsers.booleanParser,
              valueFormatter: agGridUtils.formatters.booleanFormatter,
              cellClassRules: {
                'diff-background': this.hasDiff,
              },
              boolean: true,
              editable: true,
            },
          ],
        },
        deleteColumn: {
          headerName: '',
          groupId: 'deleteGroup',
          children: [
            {
              ...agGridUtils.colDefs.action,
              colId: fieldEnum.deleteNewProduct,
              minWidth: 40,
              maxWidth: 40,
              borders: false,
              pinned: 'right',
              headerClass: 'delete-column',
              cellRenderer: 'agGridIcon',
              cellRendererParams: params => {
                if (params.data.isNewProduct) {
                  return {
                    classes: this.isEditingDisabled ? 'greyed-out' : 'selectable',
                    isDisabled: this.isEditingDisabled,
                    onClick: this.deleteRows,
                    clickParams: [params.data],
                    iconComponent: 'trash-icon',
                  };
                }
              },
              cellClassRules: {}, // override default cell classes so it stays white.
            },
          ],
        },
      };
      const columnGroups = keys(columns);
      // Additional column headers can be retrieved from config
      const extraColumns = get(this.getClientConfig, 'attributeEditorColumns', {});
      // Column headers are divided into groups
      // Retrieve and build the config headers for each group if they exist
      each(columnGroups, group => {
        const extraGroupColumns = extraColumns[group];
        if (extraGroupColumns) {
          // Use custom editor for masked date fields
          Object.keys(extraGroupColumns).forEach(colName => {
            if (extraGroupColumns[colName].useDateMask) {
              extraGroupColumns[colName].cellEditor = 'MaskedDateEditor';
              extraGroupColumns[colName].cellClassRules = {
                'invalid-border': this.isDateInvalid,
              };
              this.extraValidations = { ...this.extraValidations, [colName]: [this.isDateInvalid] };
            }
          });

          columns[group].children = agGridUtils.builders.mergeHeaders(
            columns[group].children,
            extraGroupColumns,
            { orderChildren: true }
          );
          each(keys(extraGroupColumns), col => {
            // Fields that should only be automatically mapped during CSV upload if they exist in the config
            this.optionalSchemaFields.add(col);
          });
        }
      });

      return columns;
    },

    isNewRowsNumberValid() {
      return (
        isInteger(this.newProductRowsNumber) &&
        this.newProductRowsNumber > 0 &&
        this.newProductRowsNumber <= maximumRowsToAdd
      );
    },

    isAddNewProductInputDisabled() {
      return this.isSimpleSwapsWP || this.isEditingDisabled;
    },

    isAddButtonDisabled() {
      return !this.isNewRowsNumberValid || this.isAddNewProductInputDisabled;
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAttributeEditorPage);
    },

    isCleanAttributesDisabled() {
      return this.isEditingDisabled || !size(keys(this.currentCustomAttributes));
    },

    hasNoDataChanges() {
      return isEmpty(this.currentStateDiff);
    },

    requiredSizeFields() {
      return sizeAttributes[this.selectedWorkpackage.fillInSelection];
    },

    addedProductKeys() {
      return new Set(map(this.addedProducts, 'productKey'));
    },

    distinctProductSeries() {
      // Need to trigger this on diff change - a hack
      if (!this.currentStateDiff) return;
      // Returns list of unique product series values based on grid data (case insensitive).
      const productSeriesValues = map(
        filter(this.productsData, row => !!row.productSeries),
        fieldEnum.productSeries
      );
      return uniq(productSeriesValues);
    },

    requiredFields() {
      const cols = [
        ...this.initialHeaders.pinnedHeaders.children.filter(c => c.required),
        ...this.initialHeaders.productKpis.children.filter(c => c.required),
        ...this.initialHeaders.productFinancials.children.filter(c => c.required),
        ...this.initialHeaders.productInformation.children.filter(c => c.required),
        ...this.customAttributeColumns,
      ];
      return cols.map(col => col.colId);
    },

    showErrorControls() {
      return size(this.errorData) || this.filterInvalidRows;
    },

    invalidRowsErrorMessage() {
      return this.$t('validationErrors.rowsHaveInvalidValues');
    },

    currentCustomAttributes() {
      const currentAttributes = filter(
        this.customAttributeColumns,
        ca => !includes(this.deletedAttributes, ca.colId)
      );
      return reduce(
        currentAttributes,
        (acc, attr) => {
          acc[attr.colId] = {
            id: attr.colId,
            name: attr.headerName,
          };
          return acc;
        },
        {}
      );
    },

    customAttributeColumns() {
      return [...this.savedAttributes, ...this.newAttributes];
    },

    customAttributeColumnsByField() {
      return keyBy(this.customAttributeColumns, 'field');
    },

    initialHeadersFields() {
      return [
        ...this.initialHeaders.pinnedHeaders.children.map(c => c.field),
        ...this.initialHeaders.productKpis.children.map(c => c.field),
        ...this.initialHeaders.productFinancials.children.map(c => c.field),
        ...this.initialHeaders.productInformation.children.map(c => c.field),
      ];
    },

    storeClassEligibilityColumnsByField() {
      return keyBy(this.storeClassEligibilityHeaders, 'field');
    },

    storeClassEligibilityHeaderNames() {
      return map(this.storeClassEligibilityHeaders, 'headerName');
    },

    customHeaderFields() {
      return map(this.customAttributeColumns, 'field');
    },

    customHeaderNames() {
      return map(this.customAttributeColumns, 'headerName');
    },

    hasDataChanges() {
      return !(
        this.hasNoDataChanges &&
        isEmpty(this.deletedAttributes) &&
        isEmpty(this.deletedProducts) &&
        isEmpty(this.addedProducts) &&
        isEmpty(this.productsWithoutEligibility)
      );
    },

    hasDataErrors() {
      // required for <page-actions> component
      return false;
    },

    hasNewProducts() {
      return !!this.newProductCount;
    },

    usedHeaders() {
      return flattenDeep(map(this.headers, h => this.getHeaderName(h)));
    },

    storeClasses() {
      if (isEmpty(this.scenarioFurniture)) return [];

      return (this.scenarioFurniture.storeClasses || []).map(({ _id, name }) => ({ _id, name }));
    },

    allSizeFields() {
      return union(...map(sizeAttributes, v => v));
    },

    usesMarginWithoutFunding() {
      return get(this.getClientConfig, 'features.marginWithoutSalesEnabled', false);
    },

    hasRestOfMarketEnabled() {
      return get(this.getClientConfig, 'features.restOfMarketEnabled', false);
    },

    hasProductsInResetEnabled() {
      return get(this.getClientConfig, 'features.productsInResetEnabled', false);
    },

    hasTransferPriceEnabled() {
      return get(this.getClientConfig, 'features.transferPriceEnabled', false);
    },

    hasTransferPriceRefreshUpdatesPriceAndMarginEnabled() {
      return get(this.getClientConfig, 'features.transferPriceRefreshUpdatesPriceAndMargin', false);
    },

    hasKPKUEnabled() {
      return get(this.getClientConfig, 'features.kpkuEnabled', false);
    },

    hasPalletContentEnabled() {
      return get(this.getClientConfig, 'features.palletContentEnabled', false);
    },

    productSeriesEnabled() {
      return get(this.getClientConfig, 'features.productSeries', false);
    },

    isPackageTypeDescriptionColumnVisible() {
      return get(this.getClientConfig, 'features.isPackageTypeDescriptionColumnVisible', false);
    },

    hasAdditionalFieldsEnabled() {
      return get(
        this.getClientConfig,
        'features.productAttributeEditorPage.additionalFieldsEnabled',
        false
      );
    },

    hasMinimumFacingsEnabled() {
      return get(this.getClientConfig, 'features.minimumFacingsEnabled', false);
    },

    showMinimumFacings() {
      return (
        this.hasMinimumFacingsEnabled &&
        get(this.selectedScenario, 'optionalAttributes.useMinimumFacings')
      );
    },

    hasMinimumDistributionEnabled() {
      return get(this.getClientConfig, 'features.minimumDistributionEnabled', false);
    },

    showMinimumDistribution() {
      return (
        this.hasMinimumDistributionEnabled &&
        get(this.selectedScenario, 'optionalAttributes.useMinimumDistribution')
      );
    },

    isTriangleHidden() {
      return this.isNewRowsNumberValid || this.newProductRowsNumber === '';
    },

    isTemplate() {
      return !!this.selectedWorkpackage.isTemplate;
    },

    totalProductsNotInTemplate() {
      return size(filter(this.productsData, p => !p.fromTemplate));
    },

    showProductsNotInTemplateWarning() {
      return this.totalProductsNotInTemplate && this.selectedWorkpackage.templateId;
    },

    isClearFiltersDisabled() {
      return isEmpty(this.filterModel) && !this.searchString;
    },

    hasPeriodIDColumnEnabled() {
      return get(
        this.getClientConfig,
        'features.productAttributeEditorPage.periodIDColumnEnabled',
        false
      );
    },
    hasClientProductKeyColumnEnabled() {
      return get(
        this.getClientConfig,
        'features.scenario.products.displayOriginalClientKey',
        false
      );
    },

    availablePeriodIds() {
      return get(this.selectedWorkpackage, 'periodIds', []);
    },

    canEditClientID() {
      return (
        this.hasPermission(this.userPermissions.canEditClientID) && this.betaClientIDEditEnabled
      );
    },

    betaClientIDEditEnabled() {
      return get(this.getClientConfig, 'betaFeatures.clientIDEditEnabled');
    },
  },

  watch: {
    currentNumericLocale() {
      this.refreshGrid();
    },

    currentLocale() {
      this.refreshGrid();
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    ...mapMutations('furniture', ['setScenarioFurniture']),

    ...mapActions('scenarioProducts', [
      'fetchScenarioProducts',
      'saveScenarioProducts',
      'resetProductData',
      'uploadAttributesFromSource',
      'uploadAttributesFromScenario',
      'processCSV',
      'processAttributes',
      'uploadAttributesFromFurniture',
    ]),

    ...mapActions('scenarios', [
      'loadScenario',
      'updateScenario',
      'editCustomAttributes',
      'syncTransferPrice',
      'setScenarioOptionalAttributes',
    ]),
    ...mapActions('snackbar', ['showWarning', 'showSuccess', 'showError']),
    ...mapActions('files', ['uploadCSV']),
    ...mapActions('furniture', ['fetchScenarioFurniture']),
    ...mapActions('toolData', ['fetchProductCategories']),

    onColumnMoved(params) {
      // On Col move the first cells in the group need to have a divider
      // Here the rows get refreshed when they are moved to the first position
      // and the second col in the group is refreshed also

      // Always refresh headers
      this.gridOptions.api.refreshHeader();

      const col = params.column;
      const groupCols = get(col, 'parent.displayedChildren');
      if (!groupCols || groupCols.length < 2) return;
      // if moved into the first position update it and the second col in the group
      if (col.colId === head(groupCols).colId && groupCols[1]) {
        return this.gridApi.refreshCells({ columns: [col, groupCols[1]] });
      }
      // Otherwise refresh the moved col and the new first col
      this.gridApi.refreshCells({ columns: [col, groupCols[0]] });
    },

    saveColumnState() {
      const columnDefs = this.gridApi.getColumnDefs();
      const scenarioKey = this.selectedScenario._id;
      // Save current headers to local storage
      gridPreferences.saveColumnState('attributeEditorColumnState', scenarioKey, columnDefs);
    },

    // A method to restore the initial column state
    restoreColumnState() {
      // Load saved columns preferences from local storage
      const scenarioKey = this.selectedScenario._id;
      const savedState = gridPreferences.loadColumnState(
        `attributeEditorColumnState_${scenarioKey}`
      );
      const currentColDefs = this.gridApi.getColumnDefs();

      if (savedState) {
        const mergedState = gridPreferences.mergeColumnStates(savedState, currentColDefs);
        this.gridApi.setColumnDefs(mergedState);
      }
    },

    // A method to merge current column preferences with the saved order from local storage.
    // Needed to resolve conflicts in case different users added/deleted columns on the page.
    mergeColumnStates(savedState) {
      const currentColDefs = this.gridApi.getColumnDefs();
      const mergedState = gridPreferences.mergeColumnStates(savedState, currentColDefs);
      return mergedState;
    },

    restoreOriginalColumnOrder() {
      // TODO
    },

    refreshGrid() {
      this.headers = this.setHeaders();
      this.gridApi.refreshCells({ force: true });
    },

    isFieldBoolean(field) {
      // returns true if field values should be treated as booleans
      const colDef = this.gridApi.getColumnDef(field);
      return colDef && colDef.boolean;
    },

    async onCustomAttrAdd(attributeName) {
      this.headers.forEach(h => this.getHeaderName(h));
      if (this.usedAttributeHeaders.includes(attributeName) || isEmpty(attributeName)) {
        return;
      }

      const productKeys = map(this.$options.productsData, 'productKey');
      this.headers = this.gridApi.getColumnDefs();
      // Get the index of current columns (after possible reordering) to define where to insert the new attribute
      const index = this.headers.findIndex(h => h.groupId === 'custom-attributes');
      const customAttrsGroup = this.headers[index];
      const newAttribute = this.createAttributeHeader(attributeName, attributeName);
      customAttrsGroup.children.push(newAttribute);
      this.headers.splice(index, 1, customAttrsGroup);

      const mappings = [
        {
          key: attributeName,
          selectedField: attributeName,
          mongoField: null,
          isNew: true,
        },
      ];

      const attributeNames = [attributeName];
      const uploadPromise = this.processAttributes({
        productKeys,
        source: uploadMethods.CUSTOM,
        attributeSource: '',
        mappings,
        attributeNames,
      });
      const [err, uploadData] = await to(uploadPromise);

      if (err) {
        this.gridApi.hideOverlay();
        return;
      }

      const {
        tableRows: scenarioProductsUpdates,
        additionalInformation: { customNewKeys },
      } = uploadData;
      // Check for existing / duplicate attributes and update headers
      this.updateAttributesAfterImport(customNewKeys);
      this.updateRows(scenarioProductsUpdates);

      this.gridApi.hideOverlay();
      const customAttributes = get(this.selectedScenario, 'customAttributes', []);
      if (this.customHeaderFields.length && !customAttributes.length) {
        // resize the columns if there are new custom attributes added via csv and there were no custom attributes on the scenario previously
        // this is needed as otherwise the new attributes will render off screen as the custom attrs header wasn't initially present
        this.resizeColumnGroup('product-kpis');
      }
      return customNewKeys;
    },

    getHeaderName(obj) {
      const headers = [];
      const headerName = get(obj, 'headerName');
      if (headerName) {
        headers.push(headerName);
      }
      const children = get(obj, 'children');
      if (children) {
        headers.push(children.map(ch => this.getHeaderName(ch)));
      }
      return headers;
    },

    checkMultipleRowsForErrors(rows) {
      each(rows, this.checkRowForErrors);
    },

    checkRowForErrors(row) {
      // We only check for errors if we have the grid instantiated
      if (!this.columnApi) return false;

      const columns = this.columnApi.getColumns();
      // Run all the standard validations first - run them on all the that aren't null.
      const hasInvalidFields = some(row, (v, key) => {
        if (size(this.fieldValidations[key])) {
          const colDef = get(find(columns, { colId: key }), 'colDef');
          if (isNull(v)) return false; // do not validate nulls first, this will be covered below.

          const isInvalid = !!find(this.fieldValidations[key], validator => {
            // If the validator returns true, it means the value is invalid
            return validator({
              data: row,
              value: v,
              colDef,
            });
          });
          return isInvalid;
        }
      });

      const fieldsToExclude = [];

      each(this.optionalFieldEnum, f => {
        if (
          (this[`has${upperFirst(f.field)}Enabled`] || this.showAdditionalField(f.field)) &&
          !get(this.selectedScenario, `optionalAttributes.${f.key}`)
        ) {
          fieldsToExclude.push(f.field);
        }
      });

      const fieldNamesToValidate = difference(
        [...this.requiredFields, ...this.allSizeFields],
        fieldsToExclude
      );
      const optionalSizeFields = difference(this.allSizeFields, this.requiredSizeFields);

      const valuesFound = pick(row, fieldNamesToValidate);

      const hasUnsafeOrFaultyFields = some(valuesFound, (v, key) => {
        const isUnsafeInteger = agGridUtils.validations.valueIsUnsafeInteger({ value: v });
        if (isUnsafeInteger) return isUnsafeInteger;

        // If the user provided value to an optional field, we only check its safety, not value presence
        if (optionalSizeFields.includes(key)) return false;

        // Finally, check that value is present - this already only runs against required fields
        return agGridUtils.validations.valueIsFalsy({ value: v, required: true, editable: true });
      });

      if (hasInvalidFields || hasUnsafeOrFaultyFields) {
        this.$set(this.errorData, row.productKey, true);
      } else {
        this.$delete(this.errorData, row.productKey);
      }

      // turn off filter if you've removed all invalid rows
      if (isEmpty(this.errorData) && this.filterInvalidRows) this.toggleInvalidRows();
    },

    getColumnMenuItems(params) {
      if (this.isEditingDisabled) return [];

      const deleteAttributeMenuItem = {
        name: this.$tkey('attributes.actions.deleteAttr'),
        action: () => this.deleteAttribute(params.column.colId),
      };

      const groupId = get(params, 'column.parent.groupId');

      return groupId === 'custom-attributes'
        ? [deleteAttributeMenuItem, this.attributeGroupingMenuEl(params)]
        : [this.attributeGroupingMenuEl(params)];
    },

    attributeGroupingMenuEl(params) {
      return {
        name: this.$tkey('attributes.actions.createAttrGrouping'),
        action: () => {
          const colDef = params.column.colDef;
          const groupVals = new Set();
          this.groupingColumn = colDef;
          this.productsData.forEach(node => {
            if (node[colDef.colId]) {
              groupVals.add(node[colDef.colId]);
            }
          });
          this.groupingValues = Array.from(groupVals);
          this.groupingModalOpen = true;
        },
      };
    },

    createAttributeHeader(name, id) {
      return {
        headerName: name,
        field: id,
        colId: id,
        minWidth: 100,
        flex: 1,
        menuTabs: ['filterMenuTab', 'generalMenuTab'], // menu tab shows the deletion option
        resizable: true,
      };
    },

    createStoreClassEligibilityHeaders() {
      this.storeClassEligibilityHeaders = [];
      this.storeClassEligibilityFields = [];

      this.storeClasses.forEach(sc => {
        this.storeClassEligibilityHeaders.push({
          headerName: sc.name,
          minWidth: 120,
          flex: 1,
          editable: !this.isEditingDisabled,
          resizable: true,
          field: `restructuredEligibility.${sc._id}.value`,
          colId: `restructuredEligibility.${sc._id}.value`,
          valueParser: agGridUtils.parsers.booleanParser,
          cellRenderer: params =>
            agGridUtils.utils.checkboxRenderer(params, this.handleCheckboxChange),
          cellRendererParams: {
            field: `restructuredEligibility.${sc._id}.value`,
          },
          boolean: true,
        });
        this.storeClassEligibilityFields.push(`restructuredEligibility.${sc._id}.value`);
      });

      return this.storeClassEligibilityHeaders;
    },

    closeGroupingModal() {
      this.groupingModalOpen = false;
      this.groupingColumn = {};
      this.groupingValues = [];
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
    },

    openAddOptionalColumnsModal() {
      this.isAddOptionalColumnsModalOpen = true;
    },

    closeAddOptionalColumnsModal() {
      this.isAddOptionalColumnsModalOpen = false;
    },

    openRestOfMarketProductsModal() {
      this.isRestOfMarketProductsModalOpen = true;
    },

    closeRestOfMarketProductsModal() {
      this.isRestOfMarketProductsModalOpen = false;
    },

    async openResetFromTemplateModal() {
      this.isResetFromTemplate = true;
      this.uploadedDataDetails = await this.onScenarioDataUpload();
    },

    openResetDataModal() {
      this.isResetDataModalOpen = true;
    },

    closeResetDataModal() {
      this.isResetFromTemplate = false;
      this.isResetDataModalOpen = false;
    },

    openCleanAttributesModal() {
      this.isCleanAttributesModalOpen = true;
    },

    closeCleanAttributesModal() {
      this.isCleanAttributesModalOpen = false;
    },

    setSavedAttributes() {
      const customAttributes = get(this.selectedScenario, 'customAttributes', []);
      const savedAttributes = customAttributes.map(attr =>
        this.createAttributeHeader(attr.name, attr.id)
      );

      this.savedAttributes = savedAttributes;
    },

    getInitialCustomAttributesValuesAsObj() {
      const scenarioCustomAttributes = get(this.selectedScenario, 'customAttributes', []);
      const attributes = [...scenarioCustomAttributes, ...this.newAttributes];

      const customAttributesObj = {};

      attributes.forEach(attribute => {
        customAttributesObj[attribute.id || attribute.colId] = '';
      });

      return customAttributesObj;
    },

    getInitialCustomAttributesValues() {
      // Get initial values for both existing scenario attributes and new attributes
      const scenarioCustomAttributes = get(this.selectedScenario, 'customAttributes', []);
      const attributes = [...scenarioCustomAttributes, ...this.newAttributes];

      return map(attributes, attribute => ({
        id: attribute.id || attribute.colId,
        value: '',
      }));
    },

    getInitialStoreClassEligibility() {
      const initialStoreClassEligibility = [];
      const restructuredEligibility = {};
      this.storeClasses.forEach(sc => {
        initialStoreClassEligibility.push({ id: sc._id, value: true });
        restructuredEligibility[sc._id] = { id: sc._id, value: true };
      });
      return { initialStoreClassEligibility, restructuredEligibility };
    },

    // Inspects the product for store class eligibility and creates restructured eligibility object
    // with appropriate values for each store class
    setRestructuredEligibilityFromData(scenarioProduct, targetObj, defaultValues) {
      if (scenarioProduct.storeClassEligibility) {
        targetObj.restructuredEligibility = {};
        each(scenarioProduct.storeClassEligibility, se => {
          targetObj.restructuredEligibility[se.id] = se;
        });
        scenarioProduct.restructuredEligibility = groupBy(
          scenarioProduct.storeClassEligibility,
          'id'
        );
      } else {
        this.productsWithoutEligibility.push(`${scenarioProduct.productKey}`);
        const combinedEligibility = merge({}, defaultValues);
        set(targetObj, 'restructuredEligibility', combinedEligibility);
      }
    },

    setKeyIdMap() {
      this.keyIdMap = this.$options.productsData.reduce((a, c) => set(a, c.productKey, c._id), {});
    },

    /**
     * Delete rows from ag grid if confirmed in modal.
     * @param products - Array of products. The productKeys for each product are used as node ids for ag-grid.
     */
    async deleteRows(products) {
      // only delete if confirmed in modal
      if (!(await this.$refs.deleteProduct.open())) return;

      const removedProductsKeys = [];

      products.forEach(product => {
        const productKey = product.productKey;
        const productKeyDisplay = product.productKeyDisplay;
        removedProductsKeys.push({ productKey });
        // need _id to delete from db
        const existingValues = this.keyIdMap[productKey] ? { _id: this.keyIdMap[productKey] } : {};
        this.deletedProducts.push({ productKey, ...existingValues });
        this.$delete(this.currentStateDiff, productKey); // remove any changes to product marked for deletion
        this.$delete(this.errorData, productKey); // remove any error data for this row
        // Delete from lookups
        this.lookupNewProductKeys.delete(productKeyDisplay);
        this.lookupROMProductKeys.delete(productKey);
      });

      this.productsData = differenceBy(this.productsData, removedProductsKeys, 'productKey');
      this.addedProducts = differenceBy(this.addedProducts, removedProductsKeys, 'productKey');

      this.newProductCount -= products.length;
      this.gridApi.applyTransaction({ remove: removedProductsKeys });
    },

    async deleteAttribute(attributeId) {
      const savedAttributeIds = map(this.savedAttributes, 'colId');
      if (savedAttributeIds.includes(attributeId)) this.deletedAttributes.push(attributeId);
      else this.newAttributes = this.newAttributes.filter(attr => attr.colId !== attributeId);

      // This attribute needs to be removed from any objects it exists on on the current state diff too
      // If we don't do this, we could save attributes which have never been saved.
      each(this.currentStateDiff, (diffObject, productKey) =>
        this.deletePropertyFromDiff(productKey, attributeId)
      );

      // Attributes are now accurate - reassign the headers to reflect this.
      this.headers = this.setHeaders();
      this.saveColumnState();

      this.attributesToClean.delete(attributeId);
    },

    // recursively search if attribute exists in cann groups
    findInCannGroups(cannGroups, attributeId) {
      return find(cannGroups, cannGroup => {
        if (cannGroup.attributeId === attributeId) {
          return cannGroup;
        }
        return cannGroup.children && this.findInCannGroups(cannGroup.children, attributeId);
      });
    },

    // don't need computed since this could get expensive and we only want to evaluate it when adding new products.
    getMinProductKey() {
      const minSavedProductKey = min(
        this.$options.productsData.map(p => parseInt(p.productKey, 10))
      );
      const minUnsavedProductKey = min(
        Object.keys(this.currentStateDiff).map(v => parseInt(v, 10))
      );
      // parseInt to handle cases where there is no productsData or currentStateDiff, which would return undefined.
      // In that case, parseInt would coerce the undefined to NaN, and min would still return the valid min. e.g. 0.
      return min([0, minSavedProductKey, minUnsavedProductKey].map(v => parseInt(v, 10)));
    },

    deleteAllNewProducts() {
      const newProducts = this.$options.productsData.filter(p => p.isNewProduct);

      // Filter out products that are already pending deletion
      const availableNewProducts = differenceWith(
        newProducts,
        this.deletedProducts,
        (p, dp) => p.productKey === dp.productKey
      );

      // Include any unsaved new products to ensure they are removed from table
      const additionalNewProducts = Object.values(this.currentStateDiff);
      const productsToDelete = availableNewProducts.concat(additionalNewProducts);
      this.addedProducts = [];
      this.deleteRows(productsToDelete);
    },

    getDefaultNewProduct() {
      const eligibility = this.getInitialStoreClassEligibility();

      const margin = this.usesMarginWithoutFunding ? 0 : '';
      const marginWithoutFunding = this.usesMarginWithoutFunding ? '' : 0;
      const { newInTemplate, newInScenario } = productOriginSourceTypes;

      return {
        scenarioId: this.selectedScenario._id,
        itemDescription: '',
        assortment: true,
        recentlySold: false,
        isNewProduct: true,
        customAttributes: this.getInitialCustomAttributesValues(),
        storeClassEligibility: eligibility.initialStoreClassEligibility,
        restructuredEligibility: eligibility.restructuredEligibility,
        rateOfSale: 0,
        rateOfSaleOverride: null,
        salesParticipationIndex: 0,
        storeCount: 0,
        trendApplied: null,
        sisterProducts: [],
        originalMargin: margin,
        margin,
        originalMarginWithoutFunding: marginWithoutFunding,
        marginWithoutFunding,
        fromTemplate: this.isTemplate,
        originSource: this.isTemplate ? newInTemplate : newInScenario,
        inReset: true,
        minimumFacings: this.showMinimumFacings ? 1 : null,
        minimumDistribution:
          this.showMinimumDistribution || this.showAdditionalField('minimumDistribution')
            ? 0
            : null,
        // additional fields
        seasonalWeekNumbers: this.showAdditionalField('seasonalWeekNumbers') ? 0 : null,
        userEnteredSisterProduct: this.showAdditionalField('userEnteredSisterProduct') ? '' : null,
        seasonalProduct: this.showAdditionalField('seasonalProduct') ? false : null,
        pgMustFlag: this.showAdditionalField('pgMustFlag') ? '' : null,
        maximumDistribution: this.showAdditionalField('maximumDistribution') ? 0 : null,
        localProduct: this.showAdditionalField('localProduct') ? false : null,
        palletContent: '',
        averageTransactionWeight: '',
        furnitureArchetypeProductExceptionalEffectiveDate: '',
        furnitureArchetypeProductExceptionalExpiryDate: '',
        maintainOption: this.hasTransferPriceRefreshUpdatesPriceAndMarginEnabled
          ? maintainOptions.marginRate
          : maintainOptions.doNothing,
        ...this.getInitialCustomAttributesValuesAsObj(),
      };
    },

    addROMProducts(newProducts) {
      // If a Brand custom attribute exists, populate the value
      const brandColumn = find(
        this.currentCustomAttributes,
        attr => attr.name === this.$tkey('attributes.brand')
      );

      const newData = map(newProducts, p => {
        const productKey = p.productKey;
        const productKeyDisplay = p.productKey.toString();

        // Add productKey value to ROM lookup. Once products have been added,
        // we want to exclude the products from the ROM modal so that they cannot
        // be reselected.
        this.lookupROMProductKeys.add(productKey);
        this.lookupNewProductKeys.add(productKeyDisplay);
        // Add this new product to list of products without eligibility
        this.productsWithoutEligibility.push(productKey);
        return {
          productKey,
          productKeyDisplay,
          ...this.getDefaultNewProduct(),
          itemDescription: p.itemDescription,
          ...(brandColumn && { [brandColumn.id]: p.brandName }),
          fromRestOfMarket: true,
        };
      });

      this.addRows(newData);
      this.checkMultipleRowsForErrors(newData);
    },

    addNewProducts() {
      const minProductKey = this.getMinProductKey();
      // rows are added top to bottom, so we first add the minimum (negative) product key index
      // e.g. range(-3, 0) -> [p-3, p-2, p-1]
      const newData = range(minProductKey - Math.abs(this.newProductRowsNumber), minProductKey).map(
        newProductKey => {
          // Add this new product to list of products without eligibility
          this.productsWithoutEligibility.push(newProductKey);

          return {
            productKey: newProductKey,
            productKeyDisplay: `p${newProductKey}`, // 'p-3', 'p-4' etc; user will be able to edit that value
            ...this.getDefaultNewProduct(),
          };
        }
      );
      this.addRows(newData);
      this.checkMultipleRowsForErrors(newData);
      this.newProductRowsNumber = '';
    },

    updateFieldImportData(field, isEnabled) {
      const columnName = this.$tkey(`attributes.${field}`);
      if (isEnabled) {
        this.optionalSchemaFields.add(field);
        this.fieldsToIgnore.delete(columnName);
      } else {
        this.optionalSchemaFields.delete(field);
        this.fieldsToIgnore.add(columnName);
      }
    },

    /** specific optional columns */
    async addOptionalColumns(dataToSet) {
      let useMinimumFacings = this.selectedScenario.optionalAttributes.useMinimumFacings;
      let useMinimumDistribution = this.selectedScenario.optionalAttributes.useMinimumDistribution;
      const additionalFields = this.selectedScenario.optionalAttributes.additionalFields;
      this.backupAdditionalFields = {
        ...this.selectedScenario.optionalAttributes.additionalFields,
      };
      each(dataToSet, d => {
        if (d.field === optionalFieldEnum.minimumFacings.field) {
          useMinimumFacings = d.setTo;
          this.updateFieldImportData(d.field, useMinimumFacings);
          return;
        }

        if (
          d.field === optionalFieldEnum.minimumDistribution.field &&
          !this.hasAdditionalFieldsEnabled
        ) {
          useMinimumDistribution = d.setTo;
          this.updateFieldImportData(d.field, useMinimumDistribution);
          return;
        }

        this.updateFieldImportData(d.field, d.setTo);
        additionalFields[d.field] = d.setTo;
      });

      if (
        !this.hasAdditionalFieldsEnabled &&
        (this.selectedScenario.optionalAttributes.useMinimumFacings === useMinimumFacings &&
          this.selectedScenario.optionalAttributes.useMinimumDistribution ===
            useMinimumDistribution)
      ) {
        // nothing to do if the settings didn't change
        return;
      }
      // make the changes
      this.gridApi.showLoadingOverlay();
      const productUpdates = this.productsData.map(product => {
        const changedProduct = { productKey: product.productKey };
        // if you are setting the minimumFacings, default it to 1 instead of displaying null
        changedProduct.minimumFacings = useMinimumFacings ? product.minimumFacings || 1 : null;
        // if you are setting the minimumDistribution, default is 0 instead of displaying null
        changedProduct.minimumDistribution =
          useMinimumDistribution || additionalFields.minimumDistribution
            ? product.minimumDistribution || 0
            : null;

        changedProduct.seasonalWeekNumbers = additionalFields.seasonalWeekNumbers
          ? product.seasonalWeekNumbers || 0
          : null;
        changedProduct.userEnteredSisterProduct = additionalFields.userEnteredSisterProduct
          ? product.userEnteredSisterProduct || ''
          : null;
        changedProduct.seasonalProduct = additionalFields.seasonalProduct
          ? !!product.seasonalProduct
          : null;
        changedProduct.pgMustFlag = additionalFields.pgMustFlag ? product.pgMustFlag || '' : null;
        changedProduct.maximumDistribution = additionalFields.maximumDistribution
          ? product.maximumDistribution || 0
          : null;
        changedProduct.localProduct = additionalFields.localProduct ? !!product.localProduct : null;
        return changedProduct;
      });
      // save the settings - to use or not use options back to the scenario
      await this.setScenarioOptionalAttributes({
        useMinimumFacings,
        useMinimumDistribution,
        additionalFields,
      });
      this.updateRows(productUpdates);
      this.columnApi.setColumnsVisible(['minimumFacings'], !!this.showMinimumFacings);
      this.columnApi.setColumnsVisible(['minimumDistribution'], !!this.showMinimumDistribution);

      each(keys(this.selectedScenario.optionalAttributes.additionalFields), field => {
        // Since we have two types of Minimum Distribution this way we can use two differents columns in AgGrid
        const columnId =
          field === 'minimumDistribution' ? 'minimumDistributionAdditionalAttribute' : field;
        this.columnApi.setColumnsVisible([columnId], !!this.showAdditionalField(field));
      });
      this.gridApi.hideOverlay();
      this.checkMultipleRowsForErrors(this.productsData);
    },

    async resetProducts(dataToReset) {
      this.gridApi.showLoadingOverlay();
      const resetData = await this.resetProductData(dataToReset);
      const scenarioProducts = values(resetData.data.scenarioProducts);

      this.updateRows(scenarioProducts);
      this.gridApi.hideOverlay();
      this.showSuccess(this.$tkey('attributes.successResetProductKPIsMessage'));
      this.checkMultipleRowsForErrors(this.productsData);
    },

    async onCSVUpload(formData) {
      const scenarioId = this.selectedScenario._id;
      formData.append('scenarioId', scenarioId);
      formData.append('optionalSchemaFields', Array.from(this.optionalSchemaFields));
      return this.uploadCSV({ formData, service: 'scenario-products' });
    },

    onSourceDataUpload(attributeNames) {
      return this.uploadAttributesFromSource({
        attributeNames,
        optionalSchemaFields: Array.from(this.optionalSchemaFields),
      });
    },

    async onScenarioDataUpload(scenarioId) {
      // When resetting only the template Id needs to be passed
      const uploadAttributeParams = this.isResetFromTemplate
        ? { templateId: this.selectedWorkpackage.templateId }
        : { sourceScenarioId: scenarioId };
      uploadAttributeParams.optionalSchemaFields = Array.from(this.optionalSchemaFields);

      const omitOptionalSchemaFields = [];

      // If the periodID column is enabled we should exclude from being imported
      // from other scenarios as described in the ACs of AOV3-6535
      if (this.hasPeriodIDColumnEnabled && !this.isResetFromTemplate) {
        omitOptionalSchemaFields.push('periodID');
      }

      uploadAttributeParams.optionalSchemaFields = uploadAttributeParams.optionalSchemaFields.filter(
        field => !omitOptionalSchemaFields.includes(field)
      );

      const result = await this.uploadAttributesFromScenario(uploadAttributeParams);
      // Resulting mappings need an additional translation for key and default selectedKey,
      // as those might have lookups in values.
      result.mappings = map(result.mappings, mapping => {
        const tKey = mapping.translationKey;
        if (tKey) {
          mapping.key = this.$t(tKey);
          mapping.selectedField = this.$t(tKey);
        }
        return mapping;
      });
      return result;
    },

    async onFurnitureUpload() {
      const result = await this.uploadAttributesFromFurniture();
      // Resulting mappings need an additional translation for key and default selectedKey,
      // as those might have lookups in values.
      result.mappings = map(result.mappings, mapping => {
        const tKey = mapping.translationKey;
        if (tKey) {
          mapping.key = this.$t(tKey);
          mapping.selectedField = this.$t(tKey);
        }
        return mapping;
      });
      return result;
    },

    async processData(mappings = []) {
      if (!this.isResetFromTemplate) {
        mappings = [...this.uploadedDataDetails.mappings, ...mappings];
      }
      // Send actual attribute names as well to perform lookup on server side
      const actualAttributeNames = map(this.dataToUploadFromSource.attributes, 'attributeName');
      await this.process({
        fileId: this.uploadedDataDetails._id,
        delimiter: this.delimiter,
        mappings,
        attributeSource: this.dataToUploadFromSource.source,
        attributeNames: actualAttributeNames,
        sourceScenarioId: null,
        templateId: this.selectedWorkpackage.templateId,
      });
      this.closeResetDataModal();
    },

    async process({
      fileId,
      mappings,
      delimiter,
      attributeSource,
      attributeNames,
      sourceScenarioId,
      templateId,
      uploadMethod,
    }) {
      this.gridApi.showLoadingOverlay();

      const productKeys = map(this.$options.productsData, 'productKey');
      const uploadPromise = fileId
        ? this.processCSV({ fileId, mappings, delimiter }) // Upload CSV and get feedback from server
        : this.processAttributes({
            // Upload attributes data and get feedback from server
            productKeys,
            source: uploadMethod,
            attributeSource,
            mappings,
            attributeNames,
            sourceScenarioId,
            templateId,
          });

      const [err, uploadData] = await to(uploadPromise);

      if (err) {
        this.gridApi.hideOverlay();
        return;
      }

      const {
        tableRows: scenarioProductsUpdates,
        additionalInformation: { customNewKeys, customKeyUpdates },
      } = uploadData;

      // ignore parent product values for new products on csv import
      const productUpdates = scenarioProductsUpdates.map(product => {
        if (product.productKey < 0) product.parentProduct = undefined;
        return product;
      });
      // Check for existing/ duplicates attributes and update headers
      this.updateAttributesAfterImport(customNewKeys, customKeyUpdates);
      this.updateRows(productUpdates);

      this.gridApi.hideOverlay();
      const customAttributes = get(this.selectedScenario, 'customAttributes', []);
      if (this.customHeaderFields.length && !customAttributes.length) {
        // resize the columns if there are new custom attributes added via csv and there were no custom attributes on the scenario previously
        // this is needed as otherwise the new attributes will render off screen as the custom attrs header wasn't initially present
        this.resizeColumnGroup('product-kpis');
      }
    },

    updateAttributesAfterImport(uploadedAttributes, customKeyUpdates) {
      const attributesFromUpload = uploadedAttributes.map(attr => {
        const header = this.createAttributeHeader(attr.name, attr.id);
        // enrich new attributes with source data
        return {
          ...header,
          source: attr.source,
          attributeSource: attr.attributeSource,
          attributeKey: attr.attributeKey,
        };
      });

      // Currently this always appends - if we want to prevent duplicate uploads before save
      // Then we can check the difference here by the headerName field
      // However, you also need to handle the products as they come back with a unique attributeId
      // and you would need to map this to ensure new headers values are repeatedly applied to the same
      // original column

      this.updatedAttributes = { ...this.updatedAttributes, ...keyBy(customKeyUpdates, 'id') };
      this.newAttributes = [...this.newAttributes, ...attributesFromUpload];

      this.headers = this.setHeaders();

      // Config setting determines whether the new attributes should be cleaned by default
      const defaultCleanSetting = get(this.getClientConfig, 'features.cleanAttributesEnabled');
      if (defaultCleanSetting) {
        each(this.newAttributes, na => this.attributesToClean.add(na.colId));
      }

      // Run row validations to highlight invalid rows
      this.checkMultipleRowsForErrors(this.productsData);
    },

    resizeColumnGroup(groupId) {
      const colGroup = this.columnApi.columnModel.getColumnGroup(groupId);
      const groupedCols = colGroup.children.map(x => x.colId);
      this.columnApi.autoSizeColumns(groupedCols);
    },

    mergeUnsavedUpdateHeaders(originalAttr) {
      if (this.updatedAttributes[originalAttr.colId]) {
        originalAttr.headerName = this.updatedAttributes[originalAttr.colId].name;
      }
      return originalAttr;
    },

    setHeaders() {
      // This method constructs the headers and sets them for the grid.

      // Always set saved attributes from the scenario, even on remount
      this.setSavedAttributes();

      // We can delete saved attributes - if they're marked for deletion, don't display them.
      const undeletedAttributes = this.savedAttributes.filter(
        x => !this.deletedAttributes.includes(x.colId)
      );

      // Use updated values returned from reset template
      const allAttributes = [...undeletedAttributes, ...this.newAttributes].map(
        this.mergeUnsavedUpdateHeaders
      );

      // Attribute headers are all saved attributes (unless marked for deletion) and any newly imported ones.
      const attributeHeaders = {
        headerName: this.$tkey('attributes.tableHeaders.customAttributes'),
        headerClass: 'custom-attributes divider-left custom-attribute-header',
        groupId: 'custom-attributes',
        // This will be used for tab styling in the future - disabled temporarily
        // headerGroupComponent: 'customAttributesHeaderGroupComponent',
        children: allAttributes,
      };

      if (this.usesMarginWithoutFunding) {
        const indexOfMarginCol = findIndex(this.initialHeaders.productFinancials.children, {
          field: fieldEnum.margin,
        });
        const marginWithoutFundingHeader = {
          ...cloneDeep(this.initialHeaders.productFinancials.children[indexOfMarginCol]),
          field: fieldEnum.marginWithoutFunding,
          colId: fieldEnum.marginWithoutFunding,
        };
        this.initialHeaders.productFinancials.children[
          indexOfMarginCol
        ] = marginWithoutFundingHeader;
      }

      // Each of these is its own column group.
      const headers = [
        this.initialHeaders.pinnedHeaders,
        this.initialHeaders.productKpis,
        this.initialHeaders.productFinancials,
        this.initialHeaders.productInformation,
        attributeHeaders,
        this.initialHeaders.deleteColumn,
      ];

      if (!this.isSimpleSwapsWP && this.storeClasses.length > 0) {
        // storeClass eligibility headers
        const storeClassEligibilityHeaders = {
          headerName: this.$tkey('attributes.tableHeaders.storeClassEligibility'),
          headerClass: 'storeClass-eligibility divider-left',
          groupId: 'storeClass-eligibility',
          // This will be used for tab styling in the future - disabled temporarily
          // headerGroupComponent: 'customAttributesHeaderGroupComponent',
          children: this.createStoreClassEligibilityHeaders(),
        };

        headers.splice(3, 0, storeClassEligibilityHeaders);
      }

      this.gridApi.setColumnDefs(headers);
      this.restoreColumnState();
      return headers;
    },

    addRows(newData) {
      // update the currentStateDiff as well
      this.currentStateDiff = merge({}, this.currentStateDiff, keyBy(newData, 'productKey'));
      this.newProductCount += newData.length;

      this.productsData.push(...newData);
      this.addedProducts.push(...newData);

      this.gridApi.applyTransaction({
        add: newData,
        addIndex: 0, // Currently not working due to ag-grid enterprise
      });

      // ag-grid enterprise breaks the addIndex, so we manually sort in order to ensure
      // all new products are added at the top.
      // Fixing this will be addressed by AOV3-791
      this.columnApi.applyColumnState({
        state: [
          {
            colId: fieldEnum.isNewProduct,
            sort: 'desc',
            sortIndex: 0,
          },
          {
            colId: fieldEnum.productKey,
            sort: 'asc',
            sortIndex: 1,
          },
        ],
        defaultState: { sort: null },
      });
    },

    updateRows(updates) {
      // Ensure assortment is not invalid
      updates = map(updates, update => {
        if (!isEmpty(update.palletContent) || !isEmpty(update.productParent)) {
          update.assortment = false;
        }
        return update;
      });

      // Transactions apply multiple add/ update/ delete operations to the table at once
      // input -> [{ productKey: 5, fieldToUpdate: 10 }]
      const productsToUpdate = keyBy(updates, 'productKey');

      // only apply updates if they're distinct from the current values
      // if we have { 7: {price: 100} } and our update is { 7: {price: 100} } , do nothing
      // if some are new, like { 7: { price: 100, margin: 20} } then only update with { 7: {margin: 20} }
      const newUpdates = mapValues(productsToUpdate, (updatedRow, productKey) => {
        return pickBy(updatedRow, (value, field) => {
          // if it one of the optional columns, then don't check with original state
          if (has(optionalFieldEnum, field)) {
            return true;
          }
          return get(this.$options.savedState, [productKey, field]) !== value;
        });
      });

      // updates with no new data will still return an empty object like { 6: {}, 7: {}}, remove these
      // If there's no differences, stop now
      const updatesWithData = pickBy(newUpdates, size);
      if (isEmpty(updatesWithData)) return;

      const rowsToUpdate = keys(updatesWithData); // which rows to update
      const rows = rowsToUpdate.map(id => this.gridApi.getRowNode(id)); // get current state of rows in grid
      const update = rows.map(({ data }) => {
        const updateData = updatesWithData[data.productKey];
        const combinedData = merge({}, data, updateData);
        // Dont overwrite the include for assortment value if there is a parent product
        if (!isEmpty(combinedData.parentProduct) || !isEmpty(combinedData.palletContent)) {
          combinedData.assortment = false;
        }

        // if a user changes a price or margin manually, then the ‘Maintain Option’ should be set to ‘Do nothing’,
        // if the price or margin was adjusted automatically, don't change maintain option
        if (
          this.hasTransferPriceRefreshUpdatesPriceAndMarginEnabled &&
          !this.isSyncingTransferPrice &&
          ((!isNil(updateData.price) && data.price !== updateData.price) ||
            (!isNil(updateData.margin) && data.margin !== updateData.margin))
        ) {
          this.$set(combinedData, 'maintainOption', maintainOptions.manuallySet);
          this.$set(updateData, 'maintainOption', maintainOptions.manuallySet);
        }

        // If store class eligibility has been updated, re-create restructured eligibility
        if (size(updateData.storeClassEligibility)) {
          this.setRestructuredEligibilityFromData(
            updateData,
            combinedData,
            combinedData.storeClassEligibility
          );
        }

        // If uploading an empty value from a CSV file, this needs to be mapped to the `maintainOptions.doNothing` enum.
        if (updateData.maintainOption === '' || updateData.maintainOption === 'doNothing') {
          updateData.maintainOption = maintainOptions.doNothing;
        }

        const margin = updateData[this.marginField];
        if (!isNil(margin) && isNumber(margin)) {
          // Format the number to lose the dots if the number is > 1000
          if (margin.indexOf && margin.indexOf('.') > -1 && Number(margin) > 1000) {
            margin.split('.').join('');
          }
          this.$set(combinedData, this.marginField, margin);
          this.$set(updateData, this.marginField, margin);
        }

        // make sure to unset this flag as it's only necessary for this check
        this.isSyncingTransferPrice = false;
        this.checkRowForErrors(combinedData); // check if new data has caused errors
        return combinedData;
      });

      // update the currentStateDiff as well
      this.currentStateDiff = merge({}, this.currentStateDiff, updatesWithData);

      // also update products data, traverse products once
      const updatesByProductKey = keyBy(update, 'productKey');
      each(this.productsData, product => merge(product, updatesByProductKey[product.productKey]));
      this.gridApi.applyTransaction({
        update,
      });

      return update;
    },

    resetChanges() {
      this.productsWithoutEligibility = [];
      this.savedAttributes = [];
      this.newAttributes = [];
      this.updatedAttributes = {};
      this.deletedAttributes = [];
      this.deletedProducts = [];
      this.addedProducts = [];
      this.currentStateDiff = {};
      this.attributesToClean.clear();
      this.currentScenarioStateDiff = {};
      this.productsWithoutPurchasePriceInformation = [];
      this.userEnteredProductMarginsWithoutFunding = [];
      this.backupAdditionalFields = {};
      this.resetAttributesToClean();
    },

    mapHeaderNames(params) {
      if (params.column.colId === fieldEnum.isNewProduct)
        return this.$tkey('attributes.productType');

      const field = params.column.colDef.field;
      const foundAttribute =
        get(this.customAttributeColumnsByField, field) ||
        get(this.storeClassEligibilityColumnsByField, field);

      let translationKey = field;

      // If the feature flag is enabled we need to display the translation without the percentage
      if (this.hasAdditionalFieldsEnabled && translationKey === 'minimumDistribution') {
        translationKey = `${translationKey}WoPercentage`;
      }
      // If this is a custom attribute, return header name, otherwise return translation key
      return foundAttribute
        ? foundAttribute.headerName
        : this.$tkey(`attributes.${translationKey}`);
    },

    processCellCallback(params) {
      // Being called for each cell

      // Map product type values
      if (params.column.colId === fieldEnum.isNewProduct) {
        const productType = this.getProductType(params.node.data);
        return this.$tkey(`attributes.${productType}Product`);
      }

      if (params.column.colId === this.marginField) {
        const margin = params.node.data[this.marginField];
        const marginNum = formatUtils.stringToNumberIfNumeric(margin);
        if (isString(marginNum)) return marginNum;
        return formatUtils.formatNumberIfNumeric({ value: marginNum * 100, format: 'export' });
      }

      if (
        params.column.colId === fieldEnum.originalMargin &&
        params.node.data[this.originalMarginField]
      ) {
        const marginNum = formatUtils.stringToNumberIfNumeric(
          params.node.data[this.originalMarginField]
        );
        if (isString(marginNum)) return marginNum;
        return formatUtils.formatNumberIfNumeric({ value: marginNum * 100, format: 'export' });
      }
      return agGridUtils.utils.processCellForExport(params);
    },

    exportCSV() {
      const exportParams = {
        fileName: this.getFileName({
          serviceName: 'scenario-attributes',
          workpackageName: this.selectedWorkpackage.name,
          scenarioName: this.selectedScenario.name,
          fileNameDateFormat: this.getDateFormats.csvFileName,
        }),
        suppressQuotes: this.getCsvExport.suppressQuotes,
        columnSeparator: this.getCsvExport.columnSeparator,
        columnKeys: this.getAllVisibleDataColumns(),
        processHeaderCallback: this.mapHeaderNames, // Being called for each column
        processCellCallback: this.processCellCallback,
        skipColumnGroupHeaders: true,
      };

      this.gridApi.exportDataAsCsv(exportParams);
    },

    handleCheckboxChange(params) {
      params.setValue(!params.value);
    },

    async discard() {
      this.gridApi.showLoadingOverlay();
      await this.resetAdditionalFields();
      this.resetChanges();
      // restore our previous saved state
      this.productsData = cloneDeep(this.$options.productsData);
      this.newProductCount = this.$options.newProductCount;
      this.lookupNewProductKeys = new Set(this.$options.lookupNewProductKeys);
      this.lookupROMProductKeys = new Set(this.$options.lookupROMProductKeys);

      // identify errors
      this.errorData = {};
      this.checkMultipleRowsForErrors(this.productsData);

      this.setHeaders();

      if (!this.customHeaderFields.length) {
        // resize the columns if there are no custom attributes and refit them to the screen - avoids empty space in the grid
        this.gridApi.sizeColumnsToFit();
      }
      this.usedAttributeHeaders = [];
      this.gridApi.hideOverlay();
    },

    async getScenarioProducts() {
      const scenarioProducts = await this.fetchScenarioProducts();

      // Create default eligibility data for each storeClass
      const defaultValues = this.storeClasses.reduce((defaultObj, sc) => {
        const storeClassId = sc._id;
        defaultObj[storeClassId] = {
          id: storeClassId,
          value: true,
        };

        return defaultObj;
      }, {});

      const valuesPerAttribute = {};

      const products = scenarioProducts.map(sp => {
        const obj = {};
        each(sp.customAttributes, attr => {
          // construct a lookup of custom attribute id to info about all values for it across products
          if (!valuesPerAttribute[attr.id]) {
            valuesPerAttribute[attr.id] = {
              forNewProducts: [],
              forExistingProducts: [],
            };
          }

          if (!sp._id || sp.isNewProduct) {
            // If product is new, push changed value to correct list
            valuesPerAttribute[attr.id].forNewProducts.push(attr.value);
          } else {
            valuesPerAttribute[attr.id].forExistingProducts.push(attr.value);
          }

          return set(obj, attr.id, attr.value);
        });

        // if there are no storeClasses defined, then ignore that field from the data
        if (this.storeClasses.length > 0) {
          this.setRestructuredEligibilityFromData(sp, obj, defaultValues);
        }

        each(keys(sp), key => {
          if (
            key === 'customAttributes' ||
            key === 'storeClassEligibility' ||
            key === 'restructuredEligibility'
          )
            return;
          set(obj, key, sp[key]);
        });
        return obj;
      });

      this.savedValuesPerCustomAttribute = mapValues(valuesPerAttribute, attrValues => {
        return {
          forNewProducts: uniq(attrValues.forNewProducts),
          forExistingProducts: uniq(attrValues.forExistingProducts),
        };
      });

      return products;
    },

    resetAttributesToClean() {
      const customAttributes = get(this.selectedScenario, 'customAttributes', []);
      const defaultCleanSetting = get(this.getClientConfig, 'features.cleanAttributesEnabled');

      // Set initial attributes to clean
      each(customAttributes, ca => {
        // Use default if clean flag has not been set
        const isClean = get(ca, 'clean', defaultCleanSetting);
        if (isClean) this.attributesToClean.add(ca.id);
      });
    },

    async init() {
      // Only load furniture for standard workpackages
      if (!this.isTemplate) await this.fetchScenarioFurniture();
      else this.setScenarioFurniture({});

      // Re-fetch the scenario to pick up current custom attributes
      await this.loadScenario(this.selectedScenario._id);
      this.productsData = await this.getScenarioProducts();

      this.newProductCount = this.productsData.reduce(
        (count, products) => (products.isNewProduct ? count + 1 : count),
        0
      );

      this.resetAttributesToClean();

      // identify errors on load
      this.checkMultipleRowsForErrors(this.productsData);

      // backup data
      this.currentStateDiff = {};
      this.$options.productsData = cloneDeep(this.productsData);
      this.$options.savedState = keyBy(this.$options.productsData, 'productKey');
      this.$options.newProductCount = this.newProductCount;
      this.setKeyIdMap();

      // refresh headers (it is needed to properly apply dynamic store class eligibility headers)
      this.headers = this.setHeaders();
      this.saveColumnState(); // This will update the saved state to include all columns

      const catKeyCounts = {};
      // get initial validation lookup tables from data loaded from the server
      this.productsData.forEach(p => {
        const productKey = get(p, 'productKey');
        const productKeyDisplay = get(p, 'productKeyDisplay');
        const isNewProduct = get(p, 'isNewProduct') === true;
        if (!isNewProduct) {
          this.lookupDisplayKeys.add(productKeyDisplay);
        } else {
          const fromRestOfMarket = get(p, 'fromRestOfMarket', false);
          this.lookupNewProductKeys.add(productKeyDisplay);
          if (fromRestOfMarket) this.lookupROMProductKeys.add(productKey);
        }
        // Generate a map of product category keys and the total products for each
        if (p.productCategoryKey) {
          if (!catKeyCounts[p.productCategoryKey]) {
            catKeyCounts[p.productCategoryKey] = {
              productCategoryKey: p.productCategoryKey,
              count: 0,
            };
          }
          catKeyCounts[p.productCategoryKey].count += 1;
        }
      });
      // Only required for ROM
      if (this.hasRestOfMarketEnabled && !isEmpty(catKeyCounts))
        await this.setCommonProductCategoryLevelKey(catKeyCounts);
      // Backup lookup data
      this.$options.lookupNewProductKeys = cloneDeep(Array.from(this.lookupNewProductKeys));
      this.$options.lookupROMProductKeys = cloneDeep(Array.from(this.lookupROMProductKeys));

      // Add optional fields for CSV import
      this.updateFieldImportData(optionalFieldEnum.minimumFacings.field, this.showMinimumFacings);
      this.updateFieldImportData(
        optionalFieldEnum.minimumDistribution.field,
        this.showMinimumDistribution
      );

      each(keys(this.selectedScenario.optionalAttributes.additionalFields), field => {
        this.updateFieldImportData(field, !!this.showAdditionalField(field));
      });

      // Automatically map pallet content if enabled
      if (this.hasPalletContentEnabled) this.optionalSchemaFields.add('palletContent');
      if (!this.transactionWeightDisabled)
        this.optionalSchemaFields.add('averageTransactionWeight');

      if (this.hasTransferPriceRefreshUpdatesPriceAndMarginEnabled) {
        this.optionalSchemaFields.add('maintainOption');
      }
      if (this.hasKPKUEnabled) {
        this.optionalSchemaFields.add('kpku');
      }
      if (this.productSeriesEnabled) {
        this.optionalSchemaFields.add('productSeries');
      }
      if (this.hasPeriodIDColumnEnabled) {
        this.optionalSchemaFields.add('periodID');
      }
    },

    async setCommonProductCategoryLevelKey(counts) {
      // Convert the product category key map to an array so that it can be sorted
      const orderedProductCategoryKeys = orderBy(mapValues(counts), ['count'], ['desc']);
      // Find the most common product category key
      const commonProductCategoryKey = get(head(orderedProductCategoryKeys), 'productCategoryKey');
      // Fetch the product hierarchy
      await this.fetchProductCategories({ categoryKeys: [commonProductCategoryKey] });
      const productCategoryLevelKey = get(
        this.getClientConfig,
        'assortmentGroups.productCategoryLevelKey'
      );
      this.commonProductCategoryLevelKey = get(
        this.productHierarchy,
        `${commonProductCategoryKey}.${productCategoryLevelKey}`,
        null
      );
    },

    trackParentProductKeys() {
      // track changes of the parent key based on the ag-grid data state
      if (!this.gridApi) return;
      this.gridApi.forEachNode(node => {
        const productKey = get(node, 'data.productKey');
        const productKeyDisplay = get(node, 'data.productKeyDisplay');
        const isNewProduct = get(node, 'data.isNewProduct') === true;
        const fromRestOfMarket = get(node, 'data.fromRestOfMarket', false);
        if (!isNewProduct) {
          this.lookupDisplayKeys.add(productKeyDisplay);
        } else {
          this.lookupNewProductKeys.add(productKeyDisplay);
          if (fromRestOfMarket) this.lookupROMProductKeys.add(productKey);
        }

        const parentKey = get(node, 'data.parentProduct');
        if (parentKey) {
          const displayKey = get(node, 'data.productKeyDisplay');
          if (this.lookupDisplayKeys.has(displayKey) && !this.lookupChildKeys.has(displayKey)) {
            this.lookupChildKeys.add(displayKey);
          }
        }
      });
    },

    trackDiff(params) {
      const { productKey } = params.data;
      const { field } = params.colDef;
      const currentValue = params.colDef.valueParser
        ? params.colDef.valueParser(params)
        : params.data[params.colDef.field];

      const path = `${productKey}.${field}`;
      const originalValue = get(this.$options.savedState, path);
      const hasValueChanged = currentValue !== originalValue;

      this.updateDiff(productKey, field, currentValue);

      // if the user has manually updated price or margin, set maintain option to "Do nothing",
      if (
        this.hasTransferPriceRefreshUpdatesPriceAndMarginEnabled &&
        hasValueChanged &&
        (field === fieldEnum.price || field === this.marginField)
      ) {
        params.data.maintainOption = maintainOptions.manuallySet;
        this.updateDiff(productKey, 'maintainOption', maintainOptions.manuallySet);
        this.gridApi.refreshCells({ columns: [fieldEnum.maintainOption] });
      }

      // check if this change has introduced an error
      this.checkRowForErrors({ ...params.data, [field]: currentValue });
    },

    updateDiff(productKey, field, currentValue) {
      const path = `${productKey}.${field}`;
      const originalValue = get(this.$options.savedState, path);

      // if it's a new product it should always have the changes added to the diff
      if (
        this.addedProductKeys.has(productKey) ||
        agGridUtils.comparators.didValueChange(currentValue, originalValue)
      ) {
        if (!this.currentStateDiff[productKey]) {
          this.$set(this.currentStateDiff, productKey, {});
        }
        this.$set(this.currentStateDiff[productKey], field, currentValue);
      } else if (
        get(this.currentStateDiff, path) ||
        !isNil(get(this.currentStateDiff, [productKey, field]))
      ) {
        // Remove the field from the object if it's back to its old value.
        // Note: Entering values directly doesn't trigger cellValueChanged but pasting values does
        this.$delete(this.currentStateDiff[productKey], field);
        if (isEmpty(this.currentStateDiff[productKey])) {
          // If there's nothing left in the object, remove it.
          this.$delete(this.currentStateDiff, productKey);
        }
      }
    },

    deletePropertyFromDiff(productKey, path) {
      // When we delete attributes, we need to remove that attribute from every object on the current state

      const diffObject = get(this.currentStateDiff, productKey, null);
      if (isNull(diffObject)) {
        // You've tried to delete an object that doesn't exist, do nothing.
        return;
      }

      // Remove the property at this path from the object
      unset(diffObject, path);

      // If it still has properties, use $set to track the new object
      // If not, delete it from the diff as its empty
      return size(diffObject)
        ? this.$set(this.currentStateDiff, productKey, diffObject)
        : this.$delete(this.currentStateDiff, productKey);
    },

    hasDiff(params) {
      const { productKey } = params.data;
      const { field } = params.colDef;
      const path = `${productKey}.${field}`;

      const currentValue = get(params.data, field);
      const originalValue = get(this.$options.savedState, path);

      const didValueChange = agGridUtils.comparators.didValueChange(currentValue, originalValue);
      const isNumericAndCurrentlyZero =
        params.colDef.type === 'numericColumnPermissiveCustom' && currentValue === 0;

      // return false if values are the same or if undefined or null is compared to an empty string or zero for numeric fields
      return (
        (didValueChange &&
          !(isNil(originalValue) && (currentValue === '' || isNumericAndCurrentlyZero))) ||
        (this.productsWithoutEligibility.includes(productKey.toString()) &&
          field.indexOf('restructuredEligibility') !== -1)
      );
    },

    cleanCustomAttributes() {
      for (let i = 0; i < this.productsData.length; i += 1) {
        this.attributesToClean.forEach(id => {
          const { productKey } = this.productsData[i];
          let currentValue = this.productsData[i][id];
          const attributeDiff = get(this.currentStateDiff, `${productKey}.${id}`, false);
          if (attributeDiff) {
            currentValue = attributeDiff;
          }
          const formattedValue = this.hasCleanAttributesUpperCaseEnabled
            ? toUpper(currentValue)
            : toLower(currentValue);
          const sanitisedValue = formattedValue.trim();
          this.productsData[i][id] = sanitisedValue;
          this.updateDiff(productKey, id, sanitisedValue);
        });
      }
    },

    cleanCustomAttributesInEditor() {
      this.cleanCustomAttributes();
      // Makes sure updates are visible in the grid
      this.gridApi.applyTransaction({
        update: this.productsData,
      });
    },

    splitAttributes(product) {
      // Get all attributes from the product
      const initialAttrs = pickBy(product, (v, k) => this.initialHeadersFields.includes(k));
      const customAttributes = pickBy(product, (v, k) => this.customHeaderFields.includes(k));
      const productWithoutCustomAttributes = pickBy(
        product,
        (v, k) =>
          !this.customHeaderFields.includes(k) && !this.storeClassEligibilityFields.includes(k)
      );

      const formattedInitialAttrs = {};
      Object.keys(initialAttrs).forEach(key => {
        formattedInitialAttrs[key] = initialAttrs[key] || null;
      });
      const formattedCustomAttributes = map(customAttributes, (value, id) => ({
        id,
        value: value || '',
      }));

      // Only set customAttributes if there are some
      if (size(formattedCustomAttributes)) {
        set(productWithoutCustomAttributes, 'customAttributes', formattedCustomAttributes);
      }

      // productWithoutCustomAttributes contains custom attributes now, if there are any,
      // would be good to rename this for readability
      return merge(
        omitBy(productWithoutCustomAttributes, (val, key) => {
          return key.startsWith('restructuredEligibility');
        }),
        formattedInitialAttrs
      );
    },

    splitStoreClassEligibility(product) {
      // helper function to split the {'restructuredEligibility.storeClassId.value': true/false}
      // to {storeClassEligibility: [id: a, value: true/false]}
      if (product.restructuredEligibility) {
        keys(product.restructuredEligibility).forEach(k => {
          // if store class eligibility is a string, set its value to an object key
          const flattenedValue = product[`restructuredEligibility.${k}.value`];
          if (!isUndefined(flattenedValue))
            product.restructuredEligibility[k].value = flattenedValue;
        });
      }
      // always make sure there is a temporary string property for restructured eligibility
      // so that it's possible to get unflattened eligibility
      keys(product.restructuredEligibility).forEach(k => {
        product[`restructuredEligibility.${k}.value`] = product.restructuredEligibility[k].value;
      });
      const storeClassEligibility = pickBy(product, (v, k) => {
        return this.storeClassEligibilityFields.includes(k);
      });
      const customAttributes = pickBy(product, (v, k) => this.customHeaderFields.includes(k));
      // need this for the mapValues to work
      const nestedEligibility = { p: storeClassEligibility };
      const formattedStoreClassEligibility = get(
        mapValues(nestedEligibility, eligibility => {
          const unflattenedEligibility = {};
          each(eligibility, (value, path) => set(unflattenedEligibility, path, value));
          return this.reStructureStoreClassEligibility(unflattenedEligibility);
        }),
        'p'
      );
      // Only set storeClass eligibility if there are some
      if (size(formattedStoreClassEligibility)) {
        set(product, 'storeClassEligibility', formattedStoreClassEligibility);
      }
      product = omitBy(product, (val, key) => {
        return key.startsWith('restructuredEligibility');
      });
      return omit(
        product,
        keys(storeClassEligibility).concat(keys(customAttributes), ['customAttributes'])
      );
    },

    async syncFinancials(transferPriceRange) {
      this.isSyncingTransferPrice = true;
      delete transferPriceRange.lastRefreshed;
      const maintainOptionByProductKey = mapValues(
        keyBy(
          map(this.productsData, p => {
            // get current state of rows in grid
            const row = this.gridApi.getRowNode(p.productKey);
            return {
              productKey: p.productKey,
              maintainOption: get(row.data, 'maintainOption', maintainOptions.doNothing),
            };
          }),
          'productKey'
        ),
        'maintainOption'
      );
      // Update the scenario
      const scenarioUpdates = {
        id: this.selectedScenario._id,
        transferPriceRange,
        maintainOptionByProductKey,
        periodIds: this.selectedWorkpackage.periodIds,
      };
      await this.syncTransferPriceAndRefresh(scenarioUpdates);
      this.isSyncingTransferPrice = false;
    },

    bulkUpdateMaintain(newMaintainValue) {
      const newProductsWithUpdatedMaintainValue = map(this.productsData, product => {
        product.maintainOption = newMaintainValue;
        return product;
      });
      this.updateRows(newProductsWithUpdatedMaintainValue);
    },

    getChangedAttributeValues(alteredCustomAttributes) {
      // Helper function to build a map of changed attribute values across products.
      // Traverses all products. For every altered attribute compares all current attribute values to initial list.
      // Returns attributes for which values were added or deleted, as well as all changed attributes.
      // Example: const changedAttributeValues = {
      //   valuesAddedToNewOnly: ["attrA", "attrC"],
      //   valuesDeletedFromNewOnly: ["attrB"],
      //   valuesAddedForProducts: ["attrB", "attrA", "attrC"],
      //   valuesDeletedFromProducts: ["attrC", "attrB"],
      //   valuesChangedForProducts: ["attrA", "attrB", "attrC"],
      // };
      const changedAttributeValues = {
        valuesAddedForNewProductsOnly: [],
        valuesDeletedFromNewProductsOnly: [],
        valuesAddedForProducts: [],
        valuesDeletedFromProducts: [],
        valuesChangedForProducts: alteredCustomAttributes,
      };

      // Collect all values for altered attributes with separation between new / existing products.
      let allAttributeValues = reduce(
        this.productsData,
        (acc, p) => {
          const alteredCustomAttrs = pick(p, alteredCustomAttributes);

          each(alteredCustomAttrs, (attrValue, attrId) => {
            if (!acc[attrId]) {
              acc[attrId] = {
                forNewProducts: [],
                forExistingProducts: [],
              };
            }

            if (!p._id || p.isNewProduct) {
              // If product is new, push changed value to correct list
              acc[attrId].forNewProducts.push(attrValue);
            } else {
              acc[attrId].forExistingProducts.push(attrValue);
            }
          });

          return acc;
        },
        {}
      );

      // Remove duplicates from values
      allAttributeValues = mapValues(allAttributeValues, attrValues => {
        return {
          forNewProducts: uniq(attrValues.forNewProducts),
          forExistingProducts: uniq(attrValues.forExistingProducts),
        };
      });

      each(alteredCustomAttributes, attrId => {
        const initialValues = get(this.savedValuesPerCustomAttribute, attrId, {});
        const initialExisting = initialValues.forExistingProducts || [];
        const initialNew = initialValues.forNewProducts || [];
        const currentExisting = get(allAttributeValues, [attrId, 'forExistingProducts'], []);
        const currentNew = get(allAttributeValues, [attrId, 'forNewProducts'], []);

        // If some of the current values are not present in saved values, those are added values.
        const valuesAddedForExistingProducts = difference(currentExisting, initialExisting);

        // If some of the initial values are not present in current values, those are deleted values.
        const valuesDeletedFromExistingProducts = difference(initialExisting, currentExisting);

        // New values added for new products are values that did not exist before
        // neither in new products, nor in existing
        const valuesAddedForNewProducts = difference(currentNew, [
          ...initialNew,
          ...initialExisting,
          '', // don't count empty value as new
        ]);

        const valuesDeletedFromNewProducts = difference(currentNew, initialNew);

        // Get the new values that vere added to new products only
        const valuesAddedForNewProductsOnly = difference(
          valuesAddedForNewProducts,
          valuesAddedForExistingProducts
        );

        // Get the values that vere deleted from new products only
        const valuesDeletedFromNewProductsOnly = difference(
          valuesDeletedFromNewProducts,
          valuesDeletedFromExistingProducts
        );

        // If some values were added / deleted for new products only, push attr id to correct list
        if (size(valuesAddedForNewProductsOnly)) {
          changedAttributeValues.valuesAddedForNewProductsOnly.push(attrId);
        }

        if (size(valuesDeletedFromNewProductsOnly))
          changedAttributeValues.valuesDeletedFromNewProductsOnly.push(attrId);

        if (size(valuesAddedForNewProductsOnly) || size(valuesAddedForExistingProducts))
          // Aggregate attribute ids where any new values were added for any products
          changedAttributeValues.valuesAddedForProducts.push(attrId);

        if (size(valuesDeletedFromNewProductsOnly) || size(valuesDeletedFromExistingProducts))
          // Aggregate attribute ids where values were deleted from existing products
          changedAttributeValues.valuesDeletedFromProducts.push(attrId);
      });

      return changedAttributeValues;
    },

    formatProductsForSave() {
      // We get scenario products as a flat array of values, including custom attributes
      // Before saving, we re-group them under customAttributes
      // This does a single traversal and uses two picks to find the appropriate keys
      // Then we collect info about altered custom attributes to pass it to dependency tree

      const alteredCustomAttributes = [];
      const updatedProducts = pickBy(this.currentStateDiff, size);
      const productKeysMap = {};
      const products = map(updatedProducts, (productUpdate, productKey) => {
        const existingValues = this.keyIdMap[productKey]
          ? { _id: this.keyIdMap[productKey], productKey: Number(productKey) }
          : {};
        if (existingValues._id) productKeysMap[existingValues._id] = productKey;

        const attributesAfterSplit = this.splitAttributes(productUpdate);
        if (attributesAfterSplit.customAttributes) {
          alteredCustomAttributes.push(...attributesAfterSplit.customAttributes);
        }

        return merge(
          {},
          attributesAfterSplit, // will return altered attributes as well
          this.splitStoreClassEligibility(productUpdate),
          existingValues
        );
      });

      // Get info about changed attribute values across products
      const changedAttributeValues = this.getChangedAttributeValues(
        uniq(map(alteredCustomAttributes, 'id'))
      );

      return { products, changedAttributeValues, productKeysMap };
    },

    async processCleanAttributesConfig(cleanOnSave) {
      const savedAttributes = get(this.selectedScenario, 'customAttributes', []);
      const defaultCleanSetting = get(this.getClientConfig, 'features.cleanAttributesEnabled');
      // Update 'clean' values for all saved custom attributes based on selections made in modal
      const customAttributes = map(savedAttributes, sa => {
        const isDeleted = includes(this.deletedAttributes, sa.id);
        // If the attribute has been deleted in the editor, save the original clean value
        // if it exists. Else, fallback to the config value
        return {
          ...sa,
          clean: !isDeleted
            ? this.attributesToClean.has(sa.id)
            : get(sa, 'clean', defaultCleanSetting),
        };
      });

      // Update the scenario
      const scenarioUpdates = {
        id: this.selectedScenario._id,
        customAttributes,
        cleanAttributesOnSave: cleanOnSave,
      };
      await this.updateScenario({ scenario: scenarioUpdates });

      // Re-fetch the scenario
      await this.loadScenario(this.selectedScenario._id);
      this.showSuccess(this.$tkey('attributes.actions.saveSuccess'));
    },

    async saveChanges(commit = false) {
      const payloadData = await this.getFormattedDataForSaving();
      try {
        this.gridApi.showLoadingOverlay();
        const result = await this.saveScenarioProducts({
          commit,
          ...payloadData,
          deletedCustomAttributes: this.deletedAttributes,
          deletedProducts: this.deletedProducts,
          addedProducts: this.addedProducts,
          scenarioId: this.selectedScenario._id,
        });
        const { needsFeedback, output } = result.data;
        let outputRequiringFeedback = result.data.output;
        const everyWarningCanBeIgnored =
          needsFeedback && every(output, o => o.bypassUserConfirmation);
        if (needsFeedback) {
          // Remove the ones that are not meant to be visible
          outputRequiringFeedback = pickBy(result.data.output, o => !o.bypassUserConfirmation);
        }
        if (needsFeedback && !everyWarningCanBeIgnored) {
          this.dependencyTreeFeedback = outputRequiringFeedback;
          if (result.data.output.outdateOptimisedCanvases) {
            this.dependencyTreeWarningMessage = this.$t('dependencyTree.outdatedCanvasesWarning');
          }
          this.dependencyTreeModalOpen = true;
        } else if (needsFeedback && everyWarningCanBeIgnored) {
          // re-process user request but this time around, set commit on
          return this.saveChanges(true);
        } else {
          await this.processAfterSave(payloadData.addedCustomAttributes);
        }
      } catch (e) {
        console.error(e);
        this.showWarning();
      }
      this.gridApi.hideOverlay();
    },

    async getFormattedDataForSaving() {
      // Check if custom attributes should be cleaned
      // Use the scenario cleanAttributesOnSave setting if it exists, else default to the config value
      const cleanOnSaveDefault = get(this.getClientConfig, 'features.cleanAttributesOnSaveEnabled');
      const cleanAttributesOnSave = get(
        this.selectedScenario,
        'cleanAttributesOnSave',
        cleanOnSaveDefault
      );
      if (cleanAttributesOnSave && this.attributesToClean.size) {
        this.cleanCustomAttributes();
      }

      const { products, changedAttributeValues, productKeysMap } = this.formatProductsForSave();
      // get altered custom attributes
      const addedCustomAttributes = this.formatNewAttributes();
      const addedStoreClassEligibility = this.formatNewStoreClassEligibility();

      return {
        addedCustomAttributes,
        addedStoreClassEligibility,
        products,
        changedAttributeValues,
        productKeysMap,
      };
    },

    async processAfterSave(addedCustomAttributes) {
      // No product should be left without storeClassEligibility defined
      this.productsWithoutEligibility = [];
      this.savedValuesPerCustomAttribute = {};

      this.productsData = await this.getScenarioProducts();

      // we add new columns to selectedScenario and one scenario in vuex array
      const updatedScenario = await this.editCustomAttributes({
        scenario: this.selectedScenario,
        addedCustomAttributes,
        deletedCustomAttributes: this.deletedAttributes,
      });

      // update scenario
      const scenarioUpdates = {
        id: updatedScenario._id,
        ...this.currentScenarioStateDiff,
      };
      await this.updateScenarioAndRefresh(scenarioUpdates);
      this.showSuccess(this.$tkey('attributes.actions.saveSuccess'));
      this.updatePostSave();
      this.checkMultipleRowsForErrors(this.productsData);
    },

    async updateScenarioAndRefresh(scenarioUpdates) {
      // Update the scenario
      await this.updateScenario({ scenario: scenarioUpdates });
      // All attributes should have been saved so they are no longer new
      this.newAttributes = [];
      // Re-fetch the scenario
      await this.loadScenario(this.selectedScenario._id);

      // Set your custom attributes as they may have changed
      this.setSavedAttributes();
    },

    async syncTransferPriceAndRefresh(scenarioUpdates) {
      // Update the scenario
      const [err, data] = await to(this.syncTransferPrice({ scenario: scenarioUpdates }));
      if (err) return;
      this.updateRows(data.productsUpdates);

      this.productsWithoutPurchasePriceInformation = data.productsWithoutPurchasePrice;
      this.currentScenarioStateDiff = { transferPriceRange: scenarioUpdates.transferPriceRange };
    },

    updatePostSave() {
      // This uses the current changes to update the saved state
      // We then only redraw the rows that have been altered or added.
      // Note that we do not pull back the new data after the save operation.
      // This is much faster than performing init again - moves it from a 5s operation to about 500-1000ms.
      this.$options.productsData = cloneDeep(this.productsData);
      // Retrieve the products keys that have been deleted
      const deletedProductKeys = map(this.deletedProducts, 'productKey');

      const restructuredState = {};
      keys(this.currentStateDiff).forEach(productKey => {
        restructuredState[productKey] = {};
        keys(this.currentStateDiff[productKey]).forEach(k => {
          const defaultValue = this.isFieldBoolean(k) ? false : null;
          set(
            restructuredState[productKey],
            k,
            this.currentStateDiff[productKey][k] || defaultValue
          );
        });
      });

      // Remove deleted products from the saved state
      this.$options.savedState = omit(
        merge({}, this.$options.savedState, restructuredState),
        deletedProductKeys
      );
      this.$options.newProductCount = this.newProductCount;
      const modifiedRows = Object.keys(this.currentStateDiff).map(key =>
        this.gridApi.getRowNode(key)
      ); // get the current objects in the table
      this.resetChanges();
      this.setKeyIdMap();
      this.headers = this.setHeaders();
      this.gridApi.redrawRows({ rowNodes: modifiedRows });
    },

    getAllVisibleDataColumns() {
      return this.columnApi
        .getColumns()
        .filter(c => c.colId !== fieldEnum.deleteNewProduct && !c.colDef.hide);
    },

    formatNewAttributes() {
      // This method would ideally just return a set of attribute ids for both arrays
      // I've left it here to not have to change the server side but it could
      // be removed once scenario-products.updateAll is refactored.
      return this.newAttributes.map(attr => ({
        id: attr.colId,
        value: attr.colId,
        text: attr.headerName,
        clean: this.attributesToClean.has(attr.colId),
        source: attr.source,
        attributeKey: attr.attributeKey,
        attributeSource: attr.attributeSource,
      }));
    },

    reStructureStoreClassEligibility(product) {
      return keys(get(product, 'restructuredEligibility')).map(k => ({
        id: k,
        value: product.restructuredEligibility[k].value,
      }));
    },

    formatNewStoreClassEligibility() {
      // here we track the changes in case there are products without any eligibility set at all
      const productsWithoutEligibilitySet = new Set(this.productsWithoutEligibility);
      let updatesWithReStructuredEligibilityMap = {};
      // proceed only if there are products without any eligibility set
      if (size(productsWithoutEligibilitySet)) {
        updatesWithReStructuredEligibilityMap = reduce(
          merge(cloneDeep(this.$options.savedState), cloneDeep(this.currentStateDiff)),
          (productsMapAcc, product, productKey) => {
            const productUpdatedOrMissingEligibilityData = productsWithoutEligibilitySet.has(
              productKey
            );

            if (productUpdatedOrMissingEligibilityData) {
              productsMapAcc[product._id] = {
                _id: product._id,
                storeClassEligibility: this.reStructureStoreClassEligibility(product),
              };
            }
            return productsMapAcc;
          },
          {}
        );
      }
      return values(updatesWithReStructuredEligibilityMap);
    },

    toggleInvalidRows() {
      this.filterInvalidRows = !this.filterInvalidRows;
      this.gridApi.onFilterChanged();
    },

    doesExternalFilterPass(node) {
      // ag-grid only allows a single external filter so need to decide which predicate to apply here
      if (this.filterInvalidRows) {
        return this.errorData[node.data.productKey];
      }
      const { productKeyDisplay, itemDescription } = node.data;

      const isInProductDisplayKey = String(productKeyDisplay).indexOf(this.searchString) !== -1;
      const isInDescription = (itemDescription || '').indexOf(this.searchString) !== -1;
      return isInProductDisplayKey || isInDescription;
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.headers = this.setHeaders();

      if (!this.customHeaderFields.length) {
        // resize the columns if there are no custom attributes and refit them to the screen - avoids empty space in the grid
        this.gridApi.sizeColumnsToFit();
      }
    },

    // If the packageTypeDescription is kg there should be a value
    isTransactionWeightInvalid(params) {
      if (this.transactionWeightDisabled) {
        return false;
      }
      // Get only returns default value for undefined, add safe guard in case of null
      const isProductSoldByWeight =
        (get(params, 'data.packageTypeDescription', '') || '').toLowerCase() === 'kg';

      const hasInvalidWeight =
        isProductSoldByWeight && agGridUtils.validations.valueIsNotNumeric(params);

      return hasInvalidWeight ? this.$tkey('attributes.validation.kgProdNeedTransaction') : '';
    },

    // We're checking that if a product is in assortment,
    // both margin and price are numeric and greater than 0.
    isMarginOrPriceInvalid(params) {
      return some([
        agGridUtils.validations.valueIsNotNumericPermissive(params),
        params.data.assortment &&
          agGridUtils.validations.valueIsLessOrEqual(params.data[params.colDef.colId], 0),
      ]);
    },

    // TODO refactor these checks into single computed
    isMinimumFacingsInvalid(params) {
      return some([
        agGridUtils.validations.valueIsNotPositiveInteger(params),
        params.data.assortment &&
          agGridUtils.validations.valueIsLess(
            params.data[params.colDef.colId],
            validRange.minimumFacings.min
          ),
        params.data.assortment &&
          agGridUtils.validations.valueIsGreater(
            params.data[params.colDef.colId],
            this.getProductMinimumFacings
          ),
      ]);
    },

    isDateInvalid(params) {
      const dateValue = params.data[params.colDef.field];
      const dateFormat = this.getExcelDateFormat;
      return !this.isDateValid({ dateValue, dateFormat });
    },

    isMinimumDistributionInvalid(params) {
      if (this.hasAdditionalFieldsEnabled) return;

      return some([
        agGridUtils.validations.valueIsNotPositiveInteger(params),
        params.data.assortment &&
          agGridUtils.validations.valueIsLess(
            params.data[params.colDef.colId],
            validRange.minimumDistribution.min
          ),
        params.data.assortment &&
          agGridUtils.validations.valueIsGreater(
            params.data[params.colDef.colId],
            validRange.minimumDistribution.max
          ),
      ]);
    },

    hasDuplicates(params) {
      if (!this.shouldValidateDuplicateProductKeys) {
        return false;
      }

      const {
        value,
        colDef: { field },
      } = params;
      const seen = new Set();

      if (
        !!value &&
        this.productsData.some(p => {
          if (p[field] === value) {
            return seen.size === seen.add(value).size;
          }
          return false;
        })
      ) {
        return this.$tkey('attributes.validation.duplicatedProductKey');
      }
    },

    isPalletContentInvalid(params) {
      const newValue = String(get(params, 'value', ''));
      if (get(params, 'data.productKeyDisplay') === newValue) {
        return this.$tkey('attributes.validation.palletedProductKeyCannotBeTheSameAsProductKey');
      }

      const isScenarioProduct = find(this.productsData, ['productKeyDisplay', newValue]);
      if (newValue && !isScenarioProduct) {
        return this.$tkey('attributes.validation.selectedProductKeyIsNotFromTheCurrentScenario');
      }
    },

    isParentProductInvalid(params) {
      // If display keys to look through have not yet been filled, skip this validation
      if (!this.lookupDisplayKeys.size) return;

      const newValue = String(get(params, 'value', ''));
      if (get(params, 'data.productKeyDisplay') === newValue) {
        return this.$tkey('attributes.validation.parentKeyCannotBeTheSameAsProductKey');
      }

      if (newValue && this.lookupChildKeys.has(newValue) && this.lookupDisplayKeys.has(newValue)) {
        return this.$tkey('attributes.validation.parentProductRelationTooDeep');
      }

      if (
        newValue &&
        !this.lookupDisplayKeys.has(newValue) &&
        this.lookupNewProductKeys.has(newValue)
      ) {
        return this.$tkey('attributes.validation.newProductCanNotBeParentProduct');
      }

      if (
        newValue &&
        !this.lookupDisplayKeys.has(newValue) &&
        !this.lookupNewProductKeys.has(newValue)
      ) {
        return this.$tkey('attributes.validation.invalidParentProductKey', { key: newValue });
      }
    },

    async createGroupedAttr(params) {
      const { attrName, grouping, sourceColumnId } = params;
      const addedKeys = await this.onCustomAttrAdd(attrName);

      const hashGrouping = {};
      grouping.forEach(group => {
        group.values.forEach(groupValue => {
          hashGrouping[groupValue] = group.name;
        });
      });
      const elId = addedKeys[0].id;
      this.gridApi.forEachNode(node => {
        node.data[elId] = hashGrouping[node.data[sourceColumnId]];
      });

      const productUpdates = this.productsData.map(product => {
        product[elId] = hashGrouping[product[sourceColumnId]];
        product = omit(product, ['transferredSalesFrom', 'transferredSalesTo']);
        return product;
      });
      this.updateRows(productUpdates);
    },

    getProductType(product) {
      if (this.hasRestOfMarketEnabled && product.fromRestOfMarket) return productTypes.rom;
      const isSemiNewProduct =
        !product.isNewProduct && product.originSource === productOriginSourceTypes.newInTemplate;
      if (this.hasProductsExistingFromNewInTemplateEnabled && isSemiNewProduct)
        return productTypes.semiNew;
      return product.isNewProduct ? productTypes.new : productTypes.existing;
    },

    clearFilters() {
      this.searchString = '';
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();
    },

    showAdditionalField(field) {
      return (
        this.hasAdditionalFieldsEnabled &&
        get(this.selectedScenario, `optionalAttributes.additionalFields.${field}`)
      );
    },

    hasInvalidPgMustFlagOption(params) {
      return !Object.values(pgMustFlagOptions).includes(params.value);
    },

    hasInvalidPeriodIds(params) {
      if (!this.hasPeriodIDColumnEnabled) return false;

      const periodID = get(params, 'data.periodID', null);
      const includedForAssortment = get(params, 'data.assortment', false);

      return productValidations.hasInvalidPeriodId({
        periodIds: this.availablePeriodIds,
        periodID,
        includedForAssortment,
      });
    },

    hasInvalidClientProductKey(params) {
      const clientProductKey = get(params, 'data.clientProductKey', null);
      const includedForAssortment = get(params, 'data.assortment', false);
      if (this.hasClientProductKeyColumnEnabled && includedForAssortment) return !clientProductKey;
      return false;
    },

    async resetAdditionalFields() {
      if (!this.hasAdditionalFieldsEnabled || !size(keys(this.backupAdditionalFields))) return;

      // rollback the optional attributes in case the user discard changes so we can hide the columns
      await this.setScenarioOptionalAttributes({
        useMinimumFacings: false,
        useMinimumDistribution: false,
        additionalFields: this.backupAdditionalFields,
      });
      each(keys(this.backupAdditionalFields), field => {
        // Since we have two types of Minimum Distribution this way we can use two differents columns in AgGrid
        const columnId =
          field === 'minimumDistribution' ? 'minimumDistributionAdditionalAttribute' : field;
        this.columnApi.setColumnsVisible([columnId], !!this.showAdditionalField(field));
      });
      this.backupAdditionalFields = {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

$height-of-row: 2.8rem;

.hidden-triangle {
  ::v-deep .error-triangle-container {
    display: none;
  }
}

.assortment-table {
  .changed-cell {
    background-color: #ffa50047 !important;
  }

  ::v-deep .error-triangle-container {
    justify-content: flex-end;
    height: $height-of-row;
  }

  .table-caption {
    font-weight: bold;
    font-size: 1.2rem;
    padding: 1rem;
  }
  .v-text-field__details {
    display: none;
  }
  .actions-col {
    padding: 0;

    &__title {
      white-space: nowrap;
    }

    &__search {
      display: flex;
      justify-content: flex-end;
    }
  }

  .products-table-actions {
    align-items: center;

    .add-products-btn-col {
      margin-left: 0.8rem;
      max-width: fit-content;
    }
  }

  ::v-deep.ag-header-label-icon {
    &.ag-sort-ascending-icon,
    &.ag-sort-descending-icon {
      margin-left: 0 !important;
    }

    &.ag-sort-order {
      display: none !important;
    }
  }

  .filter-select-header {
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
  .actions-container {
    padding: 0.8rem;
    max-width: none;
    border-bottom: 0.1rem solid $assortment-panel-border-divider-colour;

    .opaque {
      opacity: 1;
    }
  }
  overflow-x: auto;
  .fixed-column {
    position: sticky;
    z-index: 3;
    background: #fff;
  }
  .last-fixed-column,
  .last-kpi {
    border-right: 0.1rem solid $assortment-panel-border-divider-colour !important;
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-bottom: 0.1rem solid $assortment-panel-border-divider-colour !important;
  }
  .button-space-20 {
    margin-right: 2rem;
  }
  .button-space-5 {
    margin-right: 0.5rem;
  }

  .header-caption-container {
    flex: 1 !important;
  }

  &.attributes-table {
    height: 100%;
    .v-data-table {
      overflow-x: scroll;

      &__wrapper {
        overflow: visible;
        position: relative;
        // Temporarily hide customer-facing-tab, until functional work is complete on table. // styling work to be done in AOV3-585
        // margin-top: 30px;
      }

      table {
        position: relative;
      }
      thead th {
        position: sticky;
        top: 0;
        z-index: 4;

        &.fixed-column {
          z-index: 5;
          &::before {
            content: '';
            width: 100%;
            height: 3.1rem;
            background: white;
            position: absolute;
            left: 0;
            top: -3.1rem;
            z-index: 6;
          }
        }
      }
      tbody th {
        position: sticky;
        left: 0;
      }
      .v-input,
      .v-input__control,
      .v-input__slot {
        height: $height-of-row;
      }

      .v-input__slot::before {
        display: none;
      }

      .v-text-field__slot {
        input {
          padding: 0 !important;
        }
      }

      .v-list-item__title {
        line-height: normal !important;
      }

      th,
      td {
        padding: 0 0.5rem !important;
      }
    }

    .v-input {
      .v-label {
        padding-left: 0.5rem;
      }
    }
  }

  ::v-deep.chip {
    line-height: 2rem;
    position: relative;
    display: inline-flex;
    height: 1.6rem;
    font-size: 1rem;
    line-height: 2rem;
    max-width: 100%;
    overflow: hidden;
    border-radius: 0.4rem;
    padding: 0 0.5rem;
    background-color: $assortment-chip-color;

    .chip__content {
      border-spacing: 0;
      line-height: 2rem;
      padding: 0;
      margin: 0;
      align-items: center;
      justify-content: center;
      display: inline-flex;
      height: 100%;
      max-width: 100%;
      width: 2.4rem;
      font-size: 1rem;
      font-weight: bold;
      color: $assortment-primary-colour;
    }

    &.new {
      background: $assortment-new-card-bg-colour !important;
    }

    &.semiNew {
      background: $assortment-semi-new-card-bg-colour !important;
    }

    &.rom {
      background: $assortment-rom-card-bg-colour !important;
    }

    &.new,
    &.rom,
    &.semiNew {
      .chip__content {
        color: $assortment-input-background;
      }
    }
  }
  .theme--light.v-data-table thead tr th {
    color: $assortment-table-header-colour;
    font-weight: normal;
  }

  thead {
    tr {
      height: 4.1rem;
    }
  }

  ::v-deep {
    .mdi-checkbox-marked,
    .mdi-checkbox-blank-outline {
      margin-top: 0.2rem;
    }
  }

  .customer-facing-tab {
    min-width: fit-content !important;
    position: absolute;
    top: -3.1rem;
    left: -0.1rem;
  }

  .custom-header {
    border-top: 0.1rem solid $assortment-panel-border-divider-colour;
    z-index: 1;
  }

  td {
    font-size: 1.2rem !important;
    font-family: $assortment-regular-font;

    &:first-child {
      padding: 0 0.5rem !important;
    }

    &.fixed-column {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .v-input {
      margin-top: 0.2rem;
    }

    // Below cell-outer and inner containers are a workaround to hide scrollbars in the table cells
    // scrollbars on windows chrome affect cell height so need to be hidden

    .cell-outer-container {
      position: relative;
      overflow: hidden;
    }

    .cell-inner-container {
      overflow-x: scroll;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .data-table-tab-container {
    .v-tabs-bar__content :first-child {
      margin-left: 0 !important;
    }
    .data-table-tab {
      padding: 0 1rem;
      justify-content: inherit;
      text-align: inherit;
      text-transform: none;
      min-width: 20rem;
      border: 0.1rem solid $assortment-panel-border-divider-colour;
      border-bottom: none;
      margin-left: 0.4rem;
      font-size: 1.2rem;
      font-weight: bold;
      color: $assortment-font-colour !important;
    }
    .data-table-tabs.theme--light.v-tabs > .v-tabs-bar {
      background-color: inherit !important;
    }
    .v-tabs-bar {
      height: 3rem;
    }
    .v-tab--active {
      background-color: #ffffff;
    }
    .v-tab.v-tab {
      color: rgba(0, 0, 0, 0.54);
    }
    .v-tabs-slider {
      background-color: #ffffff;
    }
  }

  .pagination-container {
    padding: 1.5rem;
    .col {
      padding: 0;
    }
  }
}

#number-of-products {
  width: auto;
}

// get rid of vuetify table borders
.theme--light.v-data-table tbody tr:not(:last-child) {
  td:last-child {
    border: none;
  }
  td:not(.v-data-table__mobile-row) {
    border: none;
  }
}

// ag-grid specific styles overrides
.ag-grid-box {
  height: 100%;
}

.ag-theme-custom {
  .ag-cell {
    bottom: 0;

    &.ag-cell-inline-editing .ag-cell-editor .ag-text-field-input-wrapper .ag-text-field-input {
      background: $assortment-blue-light;
    }
  }
}

::v-deep {
  .ag-sort-indicator-container .ag-sort-order {
    color: transparent;
  }

  .error-cell {
    background-color: $assortment-table-error-cell-background !important;

    &:hover {
      background-color: $assortment-table-error-cell-background !important;
    }
  }

  .diff-background {
    background-color: $assortment-table-changed-cell-bg-colour;
  }

  .invalid-nonempty {
    background-color: $assortment-table-error-cell-background;
  }

  .invalid-border {
    border-bottom: 0.2rem solid $assortment-table-error-cell-border !important;
  }

  .ag-cell-not-inline-editing {
    &.underlined-overridden {
      border-bottom: 0.2rem solid $assortment-primary-colour;
      border-left-width: 0.1rem !important;
      border-right-width: 0.1rem !important;
      font-style: italic;
      padding-left: 0.5rem !important;

      &.ag-cell-focus {
        padding-bottom: 0.1rem !important;
      }
    }
  }
}

.ag-tooltip {
  background-color: $assortment-tooltip-background !important;
  color: $assortment-tooltip-text-colour !important;
  border-radius: $assortment-tooltip-border-radius !important;
  padding: $assortment-tooltip-padding !important;
}

.disabled-cell {
  background-color: $assortment-draggable-disabled-color;
}

.not-editable-cell {
  color: $assortment-table-not-editable-color;
}

// fit page actions into ag-grid status panel to save vertical space
.page-actions {
  position: absolute;
  bottom: 3px;
  right: 0;
}

::v-deep {
  .info-message button {
    background-color: transparent !important;
  }
}
</style>
