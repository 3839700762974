'use strict';

const Errors = {
  ScenarioNotUnique: 'NOT_UNIQUE_SCENARIO_NAME',
  ScenarioNotFound: 'SCENARIO_NOT_FOUND',
  ScenarioUpdateFailed: 'FAILED_TO_UPDATE_SCENARIO',
  OrphanProducts: 'ORPHAN_PRODUCTS_IN_MONGO_DB',
  UpdateCannGroups: 'CAN_NOT_UPDATE_CANN_GROUPS',
};

module.exports = {
  Errors,
};
