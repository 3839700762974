<template>
  <div>
    Please use <a :href="loginSamlUrl">the Login link</a> if you aren't forwarded to login page.
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import queryString from 'querystring';

export default {
  computed: {
    ...mapState('context', ['loginRedirectUrl']),

    returnPath() {
      let returnPath = this.$route.query.returnPath || '/';
      if (returnPath === '/auth-saml/login') returnPath = '/';
      return returnPath;
    },
    loginSamlUrl() {
      this.setCurrentLoginMethod('auth-saml');
      const query = queryString.stringify({ returnPath: this.returnPath });

      return `${this.loginRedirectUrl}/api/auth-saml/login?${query}`;
    },
  },

  created() {
    window.location.href = this.loginSamlUrl;
  },

  methods: {
    ...mapActions('context', ['setCurrentLoginMethod']),
  },
};
</script>
