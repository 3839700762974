import permissions from '@enums/user-permissions';

import scenarioWorkflow from '@/js/pages/scenario-workflow/scenario-workflow.vue';

import admin from '@/js/pages/admin/admin.vue';
import clientAdmin from '@/js/pages/client-admin/client-admin.vue';

// Furniture and space
import scenarioSpace from '@/js/pages/scenario-space/scenario-space.vue';
import furnitureEditor from '@/js/pages/scenario-space/furniture-editor/furniture-editor.vue';
import furnitureMapping from '@/js/pages/scenario-space/furniture-mapping/furniture-mapping.vue';
import spacebreakCalculator from '@/js/pages/scenario-space/spacebreak-calculator/spacebreak-calculator.vue';
import storeclassDefinition from '@/js/pages/scenario-space/storeclass-definition/storeclass-definition.vue';
import planogramSelection from '@/js/pages/scenario-space/planogram-selection/planogram-selection.vue';

// Inputs
import scenarioInput from '@/js/pages/scenario-input/scenario-input.vue';
import attributeEditor from '@/js/pages/scenario-input/attribute-editor/attribute-editor.vue';
import cannGroups from '@/js/pages/scenario-input/cann-groups/cann-groups.vue';
import varietyGroups from '@/js/pages/scenario-input/variety-groups/variety-groups.vue';

// Measuring
import scenarioMeasuring from '@/js/pages/scenario-measuring/scenario-measuring.vue';
import switchingWrapper from '@/js/pages/scenario-measuring/switching-behaviour/switching-wrapper.vue';
import customerDecisionTree from '@/js/pages/scenario-measuring/customer-decision-tree/customer-decision-tree.vue';
import region from '@/js/pages/scenario-measuring/region/region.vue';
import clustering from '@/js/pages/scenario-measuring/clustering/clustering.vue';

// Modelling
import scenarioModelling from '@/js/pages/scenario-modelling/scenario-modelling.vue';
import productModelling from '@/js/pages/scenario-modelling/product-modelling/product-modelling.vue';
import switchingModelling from '@/js/pages/scenario-modelling/switching-modelling/switching-modelling.vue';

// Swaps
import scenarioSwaps from '@/js/pages/scenario-swaps/scenario-swaps.vue';
import productsToSwap from '@/js/pages/scenario-swaps/products-to-swap.vue';
import prepareSwaps from '@/js/pages/scenario-swaps/prepare-swaps.vue';

// Extracts
import scenarioExtracts from '@/js/pages/scenario-extracts/scenario-extracts.vue';

// Assortments
import assortmentPanel from '@/js/pages/home/workpackage-scenarios/scenario-panel/scenario-assortments-panel.vue';
import assortmentCanvas from '@/js/pages/scenario-assortments/scenario-assortments.vue';

// Execution
import scenarioExecution from '@/js/pages/scenario-execution/scenario-execution.vue';
import jdaStepper from '@/js/pages/scenario-execution/extract/jda/jda-stepper.vue';
import furnitureExtract from '@/js/pages/scenario-execution/extract/furniture/furniture-extract.vue';
import reportProductStoreExtract from '@/js/pages/scenario-execution/extract/product-store-extract/report-product-store-extract.vue';
import finalisation from '@/js/pages/scenario-execution/extract/finalisation/finalisation.vue';

const executionRoute = {
  path: 'execution',
  name: 'execution',
  component: scenarioExecution,
  omitFromNav: true,
  meta: {
    requiresPermission: permissions.canViewExecutionPage,
    titleExtension: 'toolbar.executionTab',
  },
  children: [
    {
      path: 'product-store-extract',
      name: 'product-store-extract',
      component: reportProductStoreExtract,
      omitFromNav: true,
      meta: {
        requiresPermission: permissions.canViewExtractsPage,
        titleExtension: 'scenarioExecutionPage.tab.productExtract',
      },
    },
  ],
};

executionRoute.children.push({
  path: 'finalisation',
  name: 'finalisation',
  component: finalisation,
  omitFromNav: true,
  meta: {
    featureKey: 'finalisationEnabled',
    requiresPermission: permissions.canViewFinalisation,
    titleExtension: 'scenarioExecutionPage.tab.finalisation',
  },
});

executionRoute.children.push({
  path: 'jda',
  name: 'jda',
  component: jdaStepper,
  omitFromNav: true,
  meta: {
    featureKey: 'jdaExtractEnabled',
    requiresPermission: permissions.canViewExtractsPage,
    titleExtension: 'scenarioExecutionPage.tab.storeExecutionPlanning',
  },
});

executionRoute.children.push({
  path: 'furniture',
  name: 'furniture',
  component: furnitureExtract,
  omitFromNav: true,
  meta: {
    featureKey: 'furnitureExtractEnabled',
    requiresPermission: permissions.canViewExtractsPage,
    titleExtension: 'scenarioExecutionPage.tab.furnitureExtract',
  },
});

const baseRoutes = [
  {
    path: '/scenario/:scenarioId',
    name: 'scenario-workflow',
    id: 'scenario-workflow',
    component: scenarioWorkflow,
    linkText: 'navBar.scenarioWorkflow',
    meta: {
      titleExtension: 'navBar.scenarioWorkflow',
    },
    omitFromNav: true,
    children: [
      {
        path: 'space',
        name: 'space',
        component: scenarioSpace,
        omitFromNav: true,
        children: [
          {
            path: 'furniture-editor',
            name: 'furniture-editor',
            component: furnitureEditor,
            omitFromNav: true,
            meta: {
              requiresPermission: permissions.canViewFurnitureEditorPage,
              titleExtension: 'scenarioManagementPanel.furnitureEditor',
            },
          },
          {
            path: 'furniture-mapping',
            name: 'furniture-mapping',
            component: furnitureMapping,
            omitFromNav: true,
            meta: {
              requiresPermission: permissions.canViewFurnitureMappingPage,
              titleExtension: 'scenarioManagementPanel.storeMapping',
            },
          },
          {
            path: 'spacebreak-calculator',
            name: 'spacebreak-calculator',
            component: spacebreakCalculator,
            omitFromNav: true,
            meta: {
              requiresPermission: permissions.canViewSpacebreakCalculatorPage,
              titleExtension: 'scenarioManagementPanel.spacebreak',
            },
          },
          {
            path: 'storeclass-definition',
            name: 'storeclass-definition',
            component: storeclassDefinition,
            omitFromNav: true,
            meta: {
              requiresPermission: permissions.canViewStoreclassDefinitionPage,
              titleExtension: 'scenarioManagementPanel.storeClass',
            },
          },
          {
            path: 'planogram-selection',
            name: 'planogram-selection',
            component: planogramSelection,
            omitFromNav: true,
            meta: {
              requiresPermission: permissions.canViewPlanogramSelectionPage,
              titleExtension: 'scenarioManagementPanel.planograms',
            },
          },
        ],
      },
      {
        path: 'inputs',
        name: 'inputs',
        component: scenarioInput,
        omitFromNav: true,
        children: [
          {
            path: 'attributes',
            name: 'attributes',
            component: attributeEditor,
            omitFromNav: true,
            meta: {
              requiresPermission: permissions.canViewAttributeEditorPage,
              titleExtension: 'scenarioInputPage.tab.attr',
            },
          },
          {
            path: 'cann-group',
            name: 'cann-group',
            component: cannGroups,
            omitFromNav: true,
            meta: {
              requiresPermission: permissions.canViewCannGroupPage,
              titleExtension: 'scenarioInputPage.tab.cann-group',
            },
          },
          {
            path: 'variety',
            name: 'variety',
            component: varietyGroups,
            omitFromNav: true,
            meta: {
              requiresPermission: permissions.canViewVarietyPage,
              titleExtension: 'scenarioInputPage.tab.variety',
            },
          },
          {
            path: 'prepare-swaps',
            name: 'prepare-swaps',
            component: prepareSwaps,
            omitFromNav: true,
            meta: {
              requiresPermission: permissions.canViewPrepareSwapsPage,
              titleExtension: 'scenarioInputPage.tab.prepareSwaps',
            },
          },
        ],
      },
      {
        path: 'measuring',
        name: 'measuring',
        component: scenarioMeasuring,
        omitFromNav: true,
        children: [
          {
            path: 'switching-behaviour',
            name: 'switching-behaviour',
            component: switchingWrapper,
            omitFromNav: true,
            meta: {
              requiresPermission: permissions.canViewSwitchingBehaviourPage,
              titleExtension: 'scenarioInputPage.tab.switchingBehaviour',
            },
          },
          {
            path: 'cdt',
            name: 'cdt',
            component: customerDecisionTree,
            omitFromNav: true,
            meta: {
              requiresPermission: permissions.canViewCDTPage,
              titleExtension: 'scenarioInputPage.tab.cdt',
            },
          },
          {
            path: 'region',
            name: 'region',
            component: region,
            omitFromNav: true,
            meta: {
              featureKey: 'regionsByAttributeEnabled',
              requiresPermission: permissions.canViewClusteringPage, // TODO: fix
              titleExtension: 'scenarioInputPage.tab.region',
            },
          },
          {
            path: 'clustering',
            name: 'clustering',
            component: clustering,
            omitFromNav: true,
            omitChildrenFromNavbar: true,
            meta: {
              requiresPermission: permissions.canViewClusteringPage,
              titleExtension: 'scenarioInputPage.tab.clustering',
            },
            // clustering is a unique case, clustering.vue handles rending of tabs, but we want router to stay up to date
            children: [
              {
                path: ':clusterSchemeId/comparison',
                name: 'comparison',
                component: clustering,
                omitFromNav: true,
                meta: {
                  requiresPermission: permissions.canViewClusteringPage,
                  titleExtension: 'clusteringPage.tabs.comparison.title',
                },
              },
              {
                path: ':clusterSchemeId/deviation',
                name: 'deviation',
                component: clustering,
                omitFromNav: true,
                meta: {
                  requiresPermission: permissions.canViewClusteringPage,
                  titleExtension: 'clusteringPage.tabs.deviation.title',
                },
              },
              {
                path: ':clusterSchemeId/store-allocation-list',
                name: 'store-allocation-list',
                component: clustering,
                omitFromNav: true,
                meta: {
                  requiresPermission: permissions.canViewClusteringPage,
                  titleExtension: 'clusteringPage.tabs.storeAllocation.title',
                },
              },
              {
                path: ':clusterSchemeId/store-allocation-map',
                name: 'store-allocation-map',
                component: clustering,
                omitFromNav: true,
                meta: {
                  requiresPermission: permissions.canViewClusteringPage,
                  titleExtension: 'clusteringPage.tabs.storeAllocation.title',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'swaps',
        name: 'swaps',
        component: scenarioSwaps,
        children: [
          {
            path: 'products-to-swap',
            name: 'products-to-swap',
            component: productsToSwap,
            omitFromNav: true,
            meta: {
              requiresPermission: permissions.canViewSwapsPage,
              titleExtension: 'scenarioManagementPanel.productsToSwap',
            },
          },
        ],
        omitFromNav: true,
      },
      {
        path: 'modelling',
        name: 'modelling',
        component: scenarioModelling,
        omitFromNav: true,
        children: [
          {
            path: 'product-modelling',
            name: 'product-modelling',
            component: productModelling,
            omitFromNav: true,
            meta: {
              requiresPermission: permissions.canViewModellingPage,
              titleExtension: 'scenarioModellingPage.tab.productModelling',
            },
          },
          {
            path: 'switching-modelling',
            name: 'switching-modelling',
            component: switchingModelling,
            omitFromNav: true,
            meta: {
              featureKey: 'switchingModellingEnabled',
              requiresPermission: permissions.canViewSwitchingModellingPage,
              titleExtension: 'scenarioModellingPage.tab.switchingModelling',
            },
          },
        ],
      },
      {
        path: 'assortments-panel',
        name: 'assortments-panel',
        component: assortmentPanel,
        omitFromNav: true,
        meta: {
          requiresPermission: permissions.canViewAssortmentsPanel,
          titleExtension: 'scenarioAssortmentPanel.assortments',
        },
      },
      {
        path: 'assortments-panel/:canvasId',
        name: 'assortments-canvas',
        component: assortmentCanvas,
        omitFromNav: true,
        meta: {
          requiresPermission: permissions.canViewAssortmentCanvas,
          titleExtension: 'assortmentCanvasPage.assortments',
        },
      },
      {
        path: 'extracts',
        name: 'extracts',
        component: scenarioExtracts,
        omitFromNav: true,
        meta: {
          requiresPermission: permissions.canViewExtractsPage,
          titleExtension: 'toolbar.extractsTab',
        },
      },
      executionRoute,
    ],
  },
];

const adminRouteDefinition = {
  path: '/admin',
  name: 'admin',
  id: 'admin',
  component: admin,
  linkText: 'navBar.admin',
  omitFromNav: false,
  meta: {
    featureKey: 'adminControlsEnabled',
    requiresPermission: permissions.canViewAdminPage,
    titleExtension: 'navBar.admin',
  },
};

const clientAdminRouteDefinition = {
  path: '/client-admin',
  name: 'client-admin',
  id: 'client-admin',
  component: clientAdmin,
  linkText: 'navBar.clientAdmin',
  omitFromNav: false,
  meta: {
    featureKey: 'clientAdminControlsEnabled',
    requiresPermission: permissions.canViewClientAdminPage,
    titleExtension: 'navBar.clientAdmin',
  },
};

// We have to get admin controls permissions from user context as it cannot be stored in client config now,
// so just hiding the tab on UI for envs with no permissions
// as user-context is loaded after building the routes
const adminRoutes = [adminRouteDefinition, clientAdminRouteDefinition];

// If the exported routes contains an empty object, it breaks
// We handle combining as arrays instead to avoid this issue
const combinedRoutes = [...baseRoutes, ...adminRoutes];

export default combinedRoutes;
