import Vue from 'vue';
import to from 'await-to-js';
import axios from 'axios';
import {
  groupBy,
  keyBy,
  flatten,
  map,
  includes,
  get,
  isEmpty,
  upperFirst,
  merge,
  pick,
  size,
  intersectionWith,
  cloneDeep,
  find,
  camelCase,
  partition,
  sortBy,
  reduce,
  flatMap,
  concat,
  compact,
  some,
} from 'lodash';
import menuLevel from '@enums/reporting-data-level';
import reportingMetrics from '@enums/reporting-metrics';
import reportingSections from '@enums/reporting-sections';
import CheckpoinTypes from '@enums/checkpoint-types';
import { Filters } from '@enums/assortment-canvases';
import i18n from '@/js/vue-i18n';

const store = {
  namespaced: true,

  state: {
    selectedBundles: [],
    selectedWorkpackages: [],
    baselineIds: [],
    comparisonIds: [],
    comparison2Ids: [],
    isLoading: true,
    isSaving: false,
    scenarios: [],
    spacebreaks: [],
    canvases: [],
    checkpoints: [],
    storeClasses: [],
    clusters: [],
    loadingSubsection: {
      statsTable: false,
      statsChart: false,
      relativeShareChart: false,
      priceLadderChart: false,
      comparisonWaterfallTable: false,
      productsTable: false,
    },
    metricValue: reportingMetrics.metrics.sales,
    filters: {
      [reportingSections.stats]: [],
      [reportingSections.relativeShare]: [],
      [reportingSections.priceLadder]: [],
      [reportingSections.comparisonWaterfall]: [],
    },
    attributeValue: null,
    attributeValuesForSelectedScenarios: {},
    isSidebarShown: true,
    layouts: [],
  },

  getters: {
    scenariosById(state) {
      return keyBy(state.scenarios, '_id');
    },

    selectedWorkpackageIds(state, getters, rootState, rootGetters) {
      const workpackages = [
        ...state.selectedWorkpackages,
        ...flatMap(state.selectedBundles, b =>
          get(rootGetters['workpackages/workpackagesByBundle'], b._id, [])
        ),
      ];
      return Array.from(
        reduce(
          workpackages,
          (acc, wp) => {
            if (size(getters.scenariosByWorkpackage[wp._id])) acc.add(wp._id);
            return acc;
          },
          new Set()
        )
      );
    },

    parentHierachyIdsByScenarioId(state, getters, rootState, rootGetters) {
      return reduce(
        state.scenarios,
        (acc, s) => {
          const workpackageId = s.workpackageId;
          acc[s._id] = {
            bundleId: get(rootGetters['workpackages/workpackagesById'][workpackageId], 'bundleId'),
            workpackageId,
          };
          return acc;
        },
        {}
      );
    },

    scenariosByWorkpackage(state, getter, rootState) {
      const groupedScenarios = groupBy(state.scenarios, 'workpackageId');

      return rootState.workpackages.workpackages.reduce(
        (acc, { _id }) => ({
          ...acc,
          [_id]: groupedScenarios[_id],
        }),
        {}
      );
    },

    canvasById(state) {
      return keyBy(state.canvases, '_id');
    },

    getLayouts(state) {
      return state.layouts;
    },

    checkpointsById(state) {
      return keyBy(state.checkpoints, '_id');
    },

    canvasesByCanvasIdCombined(state) {
      return keyBy(state.canvases, 'canvasIdCombined');
    },

    checkpointsByCanvasIdCombined(state) {
      return keyBy(state.checkpoints, 'canvasIdCombined');
    },

    checkpointsByScenarioId(state) {
      return groupBy(state.checkpoints, 'scenarioId');
    },

    storeClassesById(state) {
      return keyBy(flatten(map(state.storeClasses, 'storeClasses')), '_id');
    },

    clustersById(state) {
      return keyBy(flatten(map(state.clusters, 'clusters')), 'clusterId');
    },

    getSelectionFromId: (state, getters, rootState) => _ids => {
      if (get(rootState.context.clientConfig, 'features.showLiveCanvasInReportingMenu', false)) {
        // Need to check if the id is within the checkpoints or the canvases array. As the user could have
        // selected the 'live' version.
        return compact(
          map(
            concat([], _ids || []),
            id => get(getters.checkpointsById, id) || get(getters.canvasById, id)
          )
        );
      }
      return compact(map(concat([], _ids || []), id => get(getters.checkpointsById, id)));
    },

    /*
     * Is any of Base C1 or C2 an observed checkpoint
     */
    isAnySelectionObserved: (state, getters) => {
      return some(
        flatten([state.baselineIds, state.comparisonIds, state.comparison2Ids]).map(id => {
          return (
            getters.checkpointsById[id].checkpointMeta.type === CheckpoinTypes.observedAssortment
          );
        })
      );
    },

    getCanvasName: (state, getters) => entry => {
      if (size(entry.description)) return entry.description;
      // Handle when the canvas storeclass was deleted but canvas is still available, this safety check on the 'name' should be deleted once storeclass uses dependency tree on AOV3-1191
      const storeClassName = get(
        getters.storeClassesById[entry.storeClassId],
        'name',
        i18n.t('general.notAvailable')
      );

      const clusterName =
        entry.clusterId === null
          ? i18n.t('reportingPage.unclustered')
          : getters.clustersById[entry.clusterId].clusterName;
      return `${storeClassName} - ${clusterName}`;
    },

    /*
    Returns common attributes by name of attributes across scenarios. When there's is no comparison selected,
    all baseline scenario attributes are returned instead
    */
    getAttributesIntersection: (state, getters) => (baselineIds, comparisonIds, comparison2Ids) => {
      const baseline = getters.getSelectionFromId(baselineIds || state.baselineIds);
      const comparison = getters.getSelectionFromId(comparisonIds || state.comparisonIds);
      const comparison2 = getters.getSelectionFromId(comparison2Ids || state.comparison2Ids);

      if (!size(baseline)) return [];
      const baselineCustomAttributes = flatMap(baseline, b =>
        get(getters.scenariosById, [b.scenarioId || b._id, 'customAttributes'])
      );
      if (!size(comparison)) return baselineCustomAttributes;
      const comparisonCustomAttributes = flatMap(comparison, c =>
        get(getters.scenariosById, [c.scenarioId || c._id, 'customAttributes'])
      );

      const customAttributesToCompare = [
        cloneDeep(baselineCustomAttributes),
        comparisonCustomAttributes,
      ];
      if (size(comparison2)) {
        const comparison2CustomAttributes = flatMap(comparison2, c2 =>
          get(getters.scenariosById, [c2.scenarioId || c2._id, 'customAttributes'])
        );
        customAttributesToCompare.push(comparison2CustomAttributes);
      }
      return intersectionWith(...customAttributesToCompare, (x, y) => {
        if (x.name !== y.name) return false;
        // Create new array of the possible attr ids as this is needed for applying attribute filters for products
        // when uusing multiple scenarios which may have the same attribute name but different ids.
        x.attributeIds = [x.id, y.id];
        return true;
      });
    },

    scenarioIdsForSelection: (state, getters) => {
      const baseline = getters.getSelectionFromId(state.baselineIds);
      const comparison = getters.getSelectionFromId(state.comparisonIds);
      const comparison2 = getters.getSelectionFromId(state.comparison2Ids);

      if (!size(baseline)) return [];
      const baselineScenarioIds = map(baseline, b =>
        get(getters.scenariosById, [b.scenarioId || b._id, '_id'])
      );
      if (!size(comparison)) return baselineScenarioIds;
      const comparisonScenarioIds = map(comparison, c =>
        get(getters.scenariosById, [c.scenarioId || c._id, '_id'])
      );
      if (!size(comparison2)) return [...baselineScenarioIds, ...comparisonScenarioIds];
      const comparison2ScenarioIds = map(comparison2, c2 =>
        get(getters.scenariosById, [c2.scenarioId || c2._id, '_id'])
      );
      return [...baselineScenarioIds, ...comparisonScenarioIds, ...comparison2ScenarioIds];
    },

    getFirstCommonAttribute: (state, getters) => ({
      baselineIds,
      comparisonIds,
      comparison2Ids,
    }) => {
      const allAttributes = getters.getAttributesIntersection(
        baselineIds,
        comparisonIds,
        comparison2Ids
      );

      return get(allAttributes, '0.id', null);
    },

    getAttributeFromId(state, getters) {
      const allAttributes = getters.getAttributesIntersection();

      return find(allAttributes, { id: state.attributeValue });
    },

    allowedMetrics() {
      // Use a getter so that when called i18n is initialized
      // TODO: Add based on client config
      return map(reportingMetrics.metrics, metric => ({
        type: metric,
        text: i18n.t(`reportingPage.metrics.${metric}`),
      }));
    },

    enabledMetrics(state, getters) {
      const workpackageScopes = map(state.selectedWorkpackages, w => camelCase(w.fillInSelection));
      const sizeFields = [
        reportingMetrics.metrics.linearSpace,
        reportingMetrics.metrics.cubicSpace,
        reportingMetrics.metrics.frontalSpace,
        reportingMetrics.metrics.horizontalSpace,
      ];

      return getters.allowedMetrics.map(metric => {
        return {
          ...metric,
          disabled: includes(sizeFields, metric.type)
            ? !includes(workpackageScopes, metric.type)
            : false,
        };
      });
    },

    getValuesForSelectedAttribute: state => ({ name }) => {
      if (name) {
        const selectableValues = state.attributeValuesForSelectedScenarios[name] || [];
        // map data to conform to {name: 'x' , id: 'x'} convention
        return selectableValues.map(x => ({ name: x, id: x }));
      }
      return [];
    },

    // These are all the options users will have to use in the filters component
    filterOptions(state, getters) {
      return [
        ...getters
          .getAttributesIntersection(state.baselineIds, state.comparisonIds, state.comparison2Ids)
          .map(attr => {
            return {
              // Anything passed in this object will be passed to the getValuesForSelectedAttribute function as a param
              ...attr,
              type: Filters.customAttributes,
              allowSelectMultiple: true,
              valuesGetter: getters.getValuesForSelectedAttribute,
            };
          }),
      ];
    },
  },

  mutations: {
    setSelectedBundles(state, bundles) {
      state.selectedBundles = bundles;
    },

    setSelectedWorkpackages(state, workpackages) {
      state.selectedWorkpackages = workpackages;
    },

    setScenarios(state, scenarios) {
      state.scenarios = scenarios;
    },

    setCanvases(state, canvases) {
      state.canvases = canvases;
    },

    setLayouts(state, layouts) {
      state.layouts = layouts;
    },

    deleteLayout(state, layoutId) {
      state.layouts = state.layouts.filter(layout => layout._id !== layoutId);
    },

    setCheckpoints(state, checkpoints) {
      state.checkpoints = checkpoints;
    },

    setSelection(state, { field, value }) {
      state[field] = value;
    },

    setStoreClasses(state, storeClasses) {
      state.storeClasses = storeClasses;
    },

    setClusters(state, clusters) {
      state.clusters = clusters;
    },

    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setIsSaving(state, isSaving) {
      state.isSaving = isSaving;
    },

    setLoadingSubSection(state, { field, loading }) {
      Vue.set(state.loadingSubsection, field, loading);
    },

    resetReportingFilters(state) {
      state.filters = {
        [reportingSections.stats]: [],
        [reportingSections.relativeShare]: [],
        [reportingSections.priceLadder]: [],
        [reportingSections.comparisonWaterfall]: [],
      };
    },

    setReportingFilters(state, { section, filters }) {
      Vue.set(state.filters, section, filters);
    },

    setAttributeValuesForSelectedScenarios(state, attributeValuesForSelectedScenarios) {
      state.attributeValuesForSelectedScenarios = attributeValuesForSelectedScenarios;
    },

    setIsSidebarShown(state, newValue) {
      state.isSidebarShown = newValue;
    },
  },

  actions: {
    // load all scenarios in wps
    async fetchWorkpackagesScenarios({ dispatch, commit, getters }) {
      const params = {
        pick: ['workpackageId', 'name', 'customAttributes'],
        sortField: null,
        sortDirection: null,
      };
      const options = { skipCommit: true };
      const response = await dispatch(
        'scenarios/fetchScenarios',
        { params, options },
        { root: true }
      );

      const scenarios = map(response, s => ({
        ...s,
        menuLevel: menuLevel.scenario,
        bundleId: get(getters.parentHierachyIdsByScenarioId, [s._id, 'bundleId'], null),
      }));
      commit('setScenarios', scenarios);
    },

    async fetchCanvases({ getters }, { live, excludeObserved }) {
      const workpackageIds = getters.selectedWorkpackageIds;
      if (!size(workpackageIds)) return [];

      const params = {
        workpackageIds,
        where: { live, excludeObserved },
        pick: [
          'description',
          'scenarioId',
          'clusterId',
          'storeClassId',
          'checkpointMeta',
          'hasBeenForecast',
          'live',
        ],
      };

      const [err, res] = await to(
        axios.get('/api/assortment-canvases/scenarios/checkpoints', { params })
      );
      if (err) throw new Error(err.message);

      // Assign additional hierarchy keys to ensure they are displayed correctly in the reporting menu
      return map(res.data, c => ({
        ...c,
        menuLevel: live ? menuLevel.canvas : menuLevel.checkpoint,
        canvasIdCombined: `${c.scenarioId}-${c.storeClassId}-${c.clusterId}`,
        bundleId: get(getters.parentHierachyIdsByScenarioId, [c.scenarioId, 'bundleId'], null),
        workpackageId: get(
          getters.parentHierachyIdsByScenarioId,
          [c.scenarioId, 'workpackageId'],
          null
        ),
      }));
    },

    async fetchStoreClasses({ commit, getters }) {
      const workpackageIds = getters.selectedWorkpackageIds;
      if (!size(workpackageIds)) return commit('setStoreClasses', []);
      const params = {
        workpackageIds,
        pick: ['storeClasses.name', 'storeClasses._id'],
      };
      const [err, res] = await to(axios.get('/api/workpackages/scenarios/furniture', { params }));
      if (err) throw new Error(err.message);
      commit('setStoreClasses', res.data);
    },

    async fetchClusters({ commit, getters }) {
      const workpackageIds = getters.selectedWorkpackageIds;
      if (!size(workpackageIds)) return commit('setClusters', []);
      const params = {
        workpackageIds,
        pick: ['description', 'scenarioId', 'clusterId', 'storeClassId', 'name', 'clusters'],
      };

      const [err, res] = await to(axios.get('/api/workpackages/scenarios/clusters', { params }));
      if (err) throw new Error(err.message);
      commit('setClusters', res.data);
    },

    async loadWorkpackagesData({ dispatch, commit }) {
      await dispatch('fetchStoreClasses');
      await dispatch('fetchClusters');

      const canvases = await dispatch('fetchCanvases', { live: true });
      const checkpoints = await dispatch('fetchCanvases', { live: false, excludeObserved: false });

      // use same sorting as canvas page
      const [unclusteredCanvases, otherCanvases] = partition(sortBy(canvases, ['clusterId']), {
        clusterId: null,
      });
      commit('setCanvases', unclusteredCanvases.concat(otherCanvases));
      commit('setCheckpoints', checkpoints);
    },

    async resetFilters({ commit, getters }, { baseline, comparison, comparison2 }) {
      const baselineData = getters.getSelectionFromId(baseline);
      const comparisonData = getters.getSelectionFromId(comparison);
      const comparison2Data = getters.getSelectionFromId(comparison2);

      commit('setSelection', {
        field: 'baselineIds',
        value: size(baselineData) ? baseline : [],
      });
      commit('setSelection', {
        field: 'comparisonIds',
        value: size(comparisonData) ? comparison : [],
      });
      commit('setSelection', {
        field: 'comparison2Ids',
        value: size(comparison2Data) ? comparison2 : [],
      });
    },

    async fetchAttributeValuesForSelectedScenarios({ commit, getters }) {
      if (!getters.scenarioIdsForSelection.length) return;
      const [err, result] = await to(
        axios.get('/api/scenario-products/uniqueAttributeValuesForScenarios', {
          params: { scenarioIds: getters.scenarioIdsForSelection },
        })
      );
      if (err) throw new Error(err.message);
      commit('setAttributeValuesForSelectedScenarios', result.data);
    },

    async fetchReportingData({ commit, state }, params) {
      if (isEmpty(params) || isEmpty(params.section) || isEmpty(state.baselineIds)) return [];
      const { section, reportArea, ...otherParams } = params;
      const subSectionName = `${camelCase(section)}${upperFirst(reportArea)}`;
      commit('setLoadingSubSection', { field: subSectionName, loading: true });
      const requestParams = merge(
        {},
        otherParams,
        pick(state, [
          'baselineIds',
          'comparisonIds',
          'comparison2Ids',
          'metricValue',
          'attributeValue',
        ]),
        { reportArea }
      );

      const [err, res] = await to(
        axios.get(`/api/reporting/${params.section}`, { params: requestParams })
      );
      commit('setLoadingSubSection', { field: subSectionName, loading: false });
      if (err) return [];

      return res.data;
    },

    async fetchLayoutsData({ commit }) {
      const [err, res] = await to(axios.get('/api/reporting/layouts', {}));
      if (err) throw new Error(err.message);
      commit('setLayouts', res.data);

      return res.data;
    },

    async createLayout({ dispatch, commit }, params) {
      commit('setIsSaving', true);
      const [err, res] = await to(axios.post('/api/reporting/layouts', params));
      if (err) {
        commit('setIsSaving', false);
        throw new Error(err.message);
      }
      await dispatch('fetchLayoutsData');
      commit('setIsSaving', false);

      return res.data;
    },

    async deleteLayout({ commit }, { layoutId }) {
      const [err] = await to(axios.delete(`/api/reporting/layouts/${layoutId}`));
      if (err) throw new Error(err.message);
      commit('deleteLayout', layoutId);
    },

    updateSidebarVisibility({ commit, state }, newValue) {
      if (newValue === state.isSidebarShown) return;
      commit('setIsSidebarShown', newValue);
    },
  },
};

export default store;
