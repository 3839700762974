<template>
  <v-dialog
    :value="value"
    width="500px"
    content-class="dependency-tree-feedback-modal"
    @click:outside="closeModal"
  >
    <dialog-card :title="$tkey('modalTitle')" @close="closeModal">
      <v-container fluid style="padding: 0">
        <v-row class="d-flex flex-column main-container">
          <v-col class="pl-0 pb-0 pt-0 pr-4 content">
            <p>
              {{ $tkey(`pageInfo.${page}`) }}
            </p>
            <p>{{ $tkey('proceedText') }}:</p>

            <hr class="mb-2" />

            <p v-for="result in results" :key="result.name">
              {{ result.count }} {{ $tkey(`types.${result.name}`) }} {{ $tkey(result.action) }}
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="warningMessage" class="pt-3 pa-2 ma-0">
        <v-alert text class="mb-1">
          {{ $t(warningMessage) }}
        </v-alert>
      </v-container>
      <template v-slot:footer>
        <page-actions
          :has-data-errors="hasDataErrors"
          :has-data-changes="true"
          :show-discard="false"
          :save-btn-text="saveBtnText"
          :proceed-btn-text="proceedBtnText"
          :show-proceed-button="ableToProceedWithoutDeleting"
          @proceed="onSaveWithoutDeleting"
          @save="onSave"
        >
          <template v-slot:right-btns>
            <v-btn class="text-outline" text link @click="closeModal">
              {{ $t('actions.cancel') }}
            </v-btn>
          </template>
        </page-actions>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DependencyTreeFeedbackModal',
  localizationKey: 'dependencyTree',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    results: {
      type: Object,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
    saveBtnText: {
      type: String,
      required: false,
      default: 'confirmDownstreamChanges',
    },
    proceedBtnText: {
      type: String,
      required: false,
      default: 'proceed',
    },
    ableToProceedWithoutDeleting: {
      type: Boolean,
      required: false,
      default: false,
    },
    warningMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    hasDataErrors() {
      return false;
    },
  },
  methods: {
    closeModal(params) {
      this.$emit('close', params);
    },
    onSaveWithoutDeleting() {
      this.$emit('proceed');
      this.closeModal();
    },
    onSave() {
      this.$emit('commit');
      this.closeModal('afterSave');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.sub-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.main-container {
  border-bottom: 1px solid $assortment-divider-colour;
  max-height: 350px;
  overflow-y: auto;
  flex-basis: auto;
  font-size: 1.3rem;
}

::v-deep {
  .dialog-card__footer {
    border-top-width: 0px;
  }

  .v-alert {
    background: $assortment-warning-alert-background !important;
    border: 1px solid $assortment-warning-alert-border !important;
    border-radius: 0 !important;
    padding: 14px;

    &:before {
      content: none;
    }

    &__content {
      color: $assortment-text-colour;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }
}
</style>
