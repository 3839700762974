var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "contents" } },
    [
      _c(
        "div",
        {
          staticClass:
            "grid-item-border-bottom flexed-white-bg-grid-item header",
          class: _vm.headerPositioning,
          style: {
            "grid-row": "1 / span 3",
            top: 0,
            height: `${_vm.headerHeight}px`,
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column align-center pa-2 justify-center full-width",
            },
            [
              _c("div", { staticClass: "inside-box-title" }, [
                _c("div", { staticClass: "title-text" }, [
                  _c(
                    "h2",
                    { staticClass: "column-title", attrs: { title: _vm.name } },
                    [
                      _vm._v(
                        "\n            " + _vm._s(_vm.name) + "\n          "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.selectedView === _vm.views.Tile
        ? _c("tile-view", {
            attrs: { products: _vm.products, "group-name": _vm.name },
          })
        : _c("list-view", {
            attrs: {
              products: _vm.products,
              "group-name": _vm.name,
              positioning: _vm.isStickyHeader,
              "previous-heights": _vm.headerHeight,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }