import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    {
      staticClass: "pa-0 flex-grow-1 d-flex flex-column",
      attrs: { fluid: "" },
    },
    [
      _c(
        VRow,
        { staticClass: "header ma-0" },
        [
          _c(
            VCol,
            {
              staticClass: "pa-3 font-weight-bold d-block",
              attrs: { cols: "2" },
            },
            [
              _c("span", { staticClass: "pa-3 font-weight-bold d-block" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$tkey("clusteringSchemes")) +
                    "\n      "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass: "pa-3 font-weight-bold d-block",
              attrs: { cols: "8" },
            },
            [
              _c("span", { staticClass: "pa-3 font-weight-bold d-block" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.selectedClusterSchemeName) +
                    "\n      "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass: "actions-col d-flex justify-end",
              attrs: { cols: "2" },
            },
            [
              _c(VSelect, {
                staticClass: "ml-4 mt-2 mr-2",
                attrs: {
                  id: "refSelectClusterName",
                  height: 24,
                  disabled: !_vm.hasSelectedStores,
                  items: _vm.selectableClusterNames,
                  placeholder: _vm.$tkey("moveSelectedStoresToCluster"),
                  "hide-details": "",
                  flat: "",
                  dense: "",
                },
                on: {
                  input: function ($event) {
                    return _vm.moveStores($event)
                  },
                },
                model: {
                  value: _vm.selectedClusterToMoveTo,
                  callback: function ($$v) {
                    _vm.selectedClusterToMoveTo = $$v
                  },
                  expression: "selectedClusterToMoveTo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(VDivider),
      _vm._v(" "),
      _c(
        VRow,
        { staticClass: "main-panel flex-grow-1" },
        [
          _c(
            VCol,
            {
              staticClass: "rtls-border rtls-border--right",
              attrs: { cols: "2" },
            },
            [
              _c(
                VContainer,
                {
                  class: { "empty-table": !_vm.getClusterSchemes.length },
                  attrs: { id: "ref-cluster-schemes-table" },
                },
                [
                  _c(
                    VRow,
                    { attrs: { id: "headers-row" } },
                    _vm._l(_vm.headers, function (header, index) {
                      return _c(
                        VCol,
                        {
                          key: `header-${index}`,
                          class: header.headerClass,
                          attrs: {
                            cols: header.cols,
                            disabled: _vm.isNavigationDisabled,
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(header.text))])]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.clusterSchemes, function (row) {
                    return _c(
                      VRow,
                      {
                        key: row._id,
                        staticClass: "cluster-row",
                        attrs: {
                          selected:
                            _vm.selectedScheme &&
                            _vm.selectedScheme._id === row._id,
                        },
                      },
                      _vm._l(_vm.headers, function (header) {
                        return _c(
                          VCol,
                          {
                            key: `cell-${row._id}-${header.id}`,
                            class: header.colClass,
                            staticStyle: { height: "30px" },
                            attrs: { cols: header.cols },
                          },
                          [
                            header.ui === _vm.CELL_TYPE.icon
                              ? [
                                  _vm.showClusterWarning(header.id, row)
                                    ? _c(
                                        VIcon,
                                        {
                                          class: header.iconClass,
                                          attrs: {
                                            disabled:
                                              _vm.isNavigationDisabled ||
                                              row.disabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return header.onClick(row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(header.value) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              : [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(row[header.value]) +
                                      "\n            "
                                  ),
                                ],
                          ],
                          2
                        )
                      }),
                      1
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(VDivider, { attrs: { horizontal: "" } }),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  staticClass: "mt-2 ml-2",
                  attrs: { primary: "" },
                  on: { click: _vm.openClusterCreationModal },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$tkey("addScheme")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(VCol, { attrs: { cols: "10" } }, [
            _c(
              "div",
              { staticStyle: { width: "100%", height: "100%" } },
              [
                _c(VDivider),
                _vm._v(" "),
                _vm.selectedScheme
                  ? _c("store-allocation-table", {
                      key: `ref-store-allocation-table-${_vm.selectedScheme._id}`,
                      ref: "ref-store-allocation-table",
                      attrs: { mode: "reference-cluster" },
                      on: {
                        "has-selected-rows": function ($event) {
                          _vm.hasSelectedStores = $event
                        },
                        "data-changed": function ($event) {
                          _vm.hasDataChanges = $event
                        },
                        "on-save": function ($event) {
                          return _vm.onSave()
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.closeUnsavedDataModal,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
      _vm._v(" "),
      _c("cluster-creation-modal", {
        on: {
          process: _vm.processNewClusterSchemes,
          close: _vm.closeClusterCreationModal,
        },
        model: {
          value: _vm.isClusterCreationModalOpen,
          callback: function ($$v) {
            _vm.isClusterCreationModalOpen = $$v
          },
          expression: "isClusterCreationModalOpen",
        },
      }),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "edit",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(VCardText, { staticClass: "pa-0" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$tkey("edit.question")))]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "float-left" },
                    [
                      _c("rtls-text-field", {
                        staticClass: "ml-2 my-2",
                        attrs: { grey: "" },
                        model: {
                          value: _vm.editClusterSchemeName,
                          callback: function ($$v) {
                            _vm.editClusterSchemeName = $$v
                          },
                          expression: "editClusterSchemeName",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function ({ cancel, confirm }) {
              return [
                _c(
                  VCardActions,
                  { staticClass: "float-right" },
                  [
                    _vm.nameHasError
                      ? _c("span", { staticClass: "my-4" }, [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons-round error-triangle-icon",
                            },
                            [_vm._v("warning_amber")]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      VBtn,
                      {
                        staticClass: "ma-2 mr-4",
                        attrs: { primary: "", disabled: _vm.nameHasError },
                        on: { click: confirm },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tkey("edit.action")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      VBtn,
                      {
                        staticClass: "cancel ma-2",
                        attrs: { text: "", depressed: "" },
                        on: { click: cancel },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("cancel")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "confirm",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  VCardText,
                  { staticClass: "display-1 pa-0 text-center" },
                  [
                    _c("p", [_vm._v(_vm._s(_vm.$tkey("delete.question")))]),
                    _vm._v(" "),
                    _c("strong", [_vm._v(_vm._s(_vm.$tkey("delete.warning")))]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function ({ cancel, confirm }) {
              return [
                _c(
                  VCardActions,
                  { staticClass: "float-right" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "ma-2",
                        attrs: { primary: "" },
                        on: { click: confirm },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tkey("delete.action")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      VBtn,
                      {
                        staticClass: "cancel ma-2",
                        attrs: { text: "", depressed: "" },
                        on: { click: cancel },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("cancel")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }