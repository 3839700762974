import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex flex-column align-start cell-container p-0" },
    [
      !_vm.params.isCopyingConstraints
        ? _c("div", { staticClass: "applied-to-description" }, [
            _vm._v(_vm._s(_vm.params.value)),
          ])
        : _c(VAutocomplete, {
            ref: "autocompleteSelect",
            staticClass: "applied-to-description",
            attrs: {
              "data-id-e2e": "copyToSelection",
              items: _vm.spacebreaksWithGlobal,
              "search-input": _vm.searchInput,
              disabled: !_vm.params.data.canConstraintBeCopied,
              "allow-overflow": false,
              placeholder: _vm.$tkey("actions.copyTo"),
              "item-text": "name",
              "background-color": "white",
              dense: "",
              width: "240px",
              height: "25px",
              light: "",
              "hide-selected": "",
              "return-object": "",
            },
            on: {
              "update:searchInput": function ($event) {
                _vm.searchInput = $event
              },
              "update:search-input": function ($event) {
                _vm.searchInput = $event
              },
              change: _vm.updateAppliedTo,
              "click:append": _vm.menuArrow,
            },
            model: {
              value: _vm.result,
              callback: function ($$v) {
                _vm.result = $$v
              },
              expression: "result",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }