<template>
  <div class="panel pb-3">
    <draggable
      :list="availableFurnitureIds"
      group="spacebreakFurniture"
      ghost-class="ghost"
      draggable=".draggable"
      :disabled="isEditingDisabled"
      class="drag-container"
    >
      <v-card
        v-for="furnitureId in availableFurnitureIds"
        :key="furnitureId"
        flat
        primary
        class="draggable ma-1"
      >
        <div class="list-item d-flex">
          <span class="furniture-archetype">
            {{ furnitureMapping[furnitureId] }}
          </span>
          <v-icon :disabled="isEditingDisabled" size="14" color="primary" class="list-icon">
            $cross-move
          </v-icon>
        </div>
      </v-card>
    </draggable>
  </div>
</template>

<script>
export default {
  props: {
    availableFurnitureIds: {
      type: Array,
      required: true,
      default: () => [],
    },
    furnitureMapping: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  computed: {
    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditSpacebreakCalculatorPage);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.list-item {
  cursor: move !important;
}
.panel {
  height: 100%;
}

.drag-container {
  height: 100%;
  border-top: 1px solid $assortment-grid-line-colour;
  border-bottom: 1px solid $assortment-grid-line-colour;
  overflow-y: auto;
  overflow-x: hidden;
}

.furniture-archetype {
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
}
</style>
