var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100" },
    [
      _vm.isProcessing
        ? _c("progress-bar", { attrs: { message: _vm.$t("general.loading") } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "compound-extracts-table__container h-100" },
        [
          _c("compound-extracts-toolbar", {
            attrs: { "available-extracts": _vm.allowedItems.write },
            on: { "update-selected-extract-filter": _vm.updateCreatorFilter },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-grow-1" },
            [
              _c("ag-grid-vue", {
                staticClass: "ag-theme-custom",
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  "row-data": _vm.rowData,
                  "column-defs": _vm.columnDefs,
                  "grid-options": _vm.gridOptions,
                  "overlay-no-rows-template": _vm.overlayNoRowsTemplate,
                },
                on: { "grid-ready": _vm.onGridReady },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }