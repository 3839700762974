<template>
  <v-dialog
    :value="value"
    fullscreen
    content-class="rest-of-market-modal"
    attach=".assortment-table"
    @keydown.esc="closeModal"
  >
    <dialog-card
      :title="$tkey('modalTitle')"
      class="w-100 modal-card--full-screen"
      @close="closeModal"
    >
      <v-container fluid class="h-100 pa-0 rest-of-market__container">
        <v-row class="flex-grow-0 rest-of-market__toolbar">
          <!-- Category -->
          <v-col cols="3">
            <div class="d-flex align-center mb-3">
              <h3 class="mr-2 rest-of-market__label">{{ $tkey('categoryLabel') }}:</h3>
              <rtls-select
                class="rest-of-market__select"
                :value="selectedProductCategory"
                :items="productCategoryOptions"
                item-text="categoryDescription"
                item-value="categoryKey"
                :placeholder="$t('general.select')"
                :disabled="loading"
                @change="onSelectedCategoryChange"
              />
            </div>

            <div class="d-flex align-center">
              <v-checkbox
                class="rtls-checkbox mx-2 rest-of-market__checkbox"
                :disabled="loading"
                :input-value="filterListedProducts"
                @change="toggleListedProducts"
              />
              <span class="mr-3">{{ $tkey('hideListedLabel') }}</span>

              <v-checkbox
                class="rtls-checkbox mx-2 rest-of-market__checkbox"
                :disabled="loading"
                :input-value="filterParentRows"
                @change="toggleParentRows"
              />
              <span>{{ $tkey('hideSubtotalsLabel') }}</span>
            </div>
          </v-col>
          <v-col cols="3">
            <div class="d-flex align-center mb-3">
              <h3 class="mr-2 rest-of-market__label">{{ $tkey('selectLabel') }}:</h3>
              <rtls-select
                class="rest-of-market__select"
                :value="selectedSubGroup"
                :items="definitionOptions"
                item-text="value"
                item-value="key"
                :disabled="loading"
                :placeholder="$t('general.select')"
                @change="onSelectedGroupChange($event)"
              />
            </div>
          </v-col>
          <!-- FOOD -->
          <v-col>
            <span class="font-weight-bold">{{ $tkey('marketShareFOODLabel') }}:</span>
            <span>{{ `${getCategoryMarketShare(romGroups.FOOD)}%` }}</span>
          </v-col>

          <!-- MULO -->
          <v-col>
            <span class="font-weight-bold">{{ $tkey('marketShareMULOLabel') }}:</span>
            <span>{{ `${getCategoryMarketShare(romGroups.MULO)}%` }}</span>
          </v-col>
        </v-row>

        <v-row class="flex-grow-1 mt-0">
          <ag-grid-vue
            style="width: 100%; height: 100%;"
            class="ag-theme-custom"
            tree-data
            animate-rows
            :does-external-filter-pass="doesExternalFilterPass"
            :row-data="rowData"
            :column-defs="columnDefs"
            :grid-options="gridOptions"
            :auto-group-column-def="autoGroupColumnDef"
            @grid-ready="onGridReady"
            @selection-changed="setSelectedProducts"
          />
        </v-row>
      </v-container>

      <template v-slot:footer>
        <page-actions
          :has-data-errors="hasDataErrors"
          :has-data-changes="hasDataChanges"
          :is-discard-enabled="!isEditingDisabled && !loading"
          save-btn-text="addSelected"
          @save="addSelectedProducts"
          @discard="discardProductSelections"
        />
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import {
  reduce,
  map,
  get,
  toLower,
  keys,
  upperFirst,
  each,
  size,
  round,
  pick,
  head,
  orderBy,
  debounce,
} from 'lodash';
import { mapState, mapActions } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import agGridUtils from '@/js/utils/ag-grid-utils';
import romMetrics from '@enums/rom-metric-groups';
import booleanOptions from '@enums/boolean-options';
import arrowIndicatorRenderer from '../../../components/ag-grid-cell-renderers/arrow-indicator-renderer.vue';

export default {
  name: 'RestOfMarketModal',
  localizationKey: 'scenarioInputPage.restOfMarketModal',

  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    arrowIndicatorRenderer,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    productsToExclude: {
      type: Array,
      required: false,
      default: () => [],
    },

    productCategoryKey: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      gridApi: null,
      columnApi: null,
      autoGroupColumnDef: null,
      gridOptions: {
        headerHeight: 50,
        rowHeight: 30,
        suppressContextMenu: true,
        // hides blue border
        suppressCellFocus: true,
        suppressRowClickSelection: true,
        isExternalFilterPresent: () => this.filterListedProducts,
        rowSelection: 'multiple',
        groupDefaultExpanded: -1,
        columnTypes: {
          numericColumnPermissiveCustom: {
            ...agGridUtils.columnTypes.numericColumnPermissiveCustom,
          },
        },
        defaultColDef: {
          filter: true,
          sortable: true,
          comparator: agGridUtils.sortings.naturalSort,
          editable: false,
          menuTabs: ['filterMenuTab'],
          minWidth: 100,
          flex: 1,
          suppressMovable: true,
        },
        autoGroupColumnDef: {
          field: 'group',
          colId: 'group',
          headerName: this.$tkey('columns.group'),
          pinned: 'left',
          minWidth: 400,
          resizable: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: params =>
            !this.isEditingDisabled && params.data && !!params.data.productKey,
          cellRendererParams: {
            suppressCount: true,
          },
        },
        getDataPath: data => data.hierarchy,
        getRowId(row) {
          const { data } = row;
          return data.hierarchy.toString();
        },
      },
      romGroups: romMetrics.groups,
      romSubGroups: {},
      selectedSubGroup: null,
      rowData: null,
      selectedProducts: [],
      filterParentRows: false,
      filterListedProducts: false,
      productCategoryOptions: [],
      selectedProductCategory: null,
    };
  },

  computed: {
    ...mapState('toolData', ['loading']),

    hasDataErrors() {
      return false;
    },

    hasDataChanges() {
      return !!size(this.selectedProducts);
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAttributeEditorPage);
    },

    columnDefs() {
      return map(this.romGroups, g => {
        const suffix = g + upperFirst(this.selectedSubGroup || '');
        const firstColumn =
          g === this.romGroups.RMA
            ? {
                field: 'package',
                colId: 'package',
                headerName: this.$tkey('columns.size'),
              }
            : {
                field: `marketShare${g}`,
                colId: `marketShare${g}`,
                headerName: this.$tkey('columns.marketShare'),
                headerClass: 'divider-left text-right',
                cellClass: 'divider-left justify-end',
                type: 'numericColumnPermissiveCustom',
                filter: 'agTextColumnFilter',
                valueGetter: params =>
                  this.getMarketShare(params, g, upperFirst(this.selectedSubGroup || '')),
                valueFormatter: params =>
                  params.value ? `${(params.value * 100).toFixed(1)}%` : '-',
                cellRenderer: 'arrowIndicatorRenderer',
                cellRendererParams: params => ({
                  classRules: {
                    'fa-caret-up': params.value >= this.categoryMarketShare[g],
                    'fa-caret-down': params.value < this.categoryMarketShare[g],
                  },
                }),
              };

        return {
          headerName: this.$tkey(`tableHeaders.${toLower(g)}`),
          headerClass: g === this.romGroups.RMA ? 'text-center' : 'text-center divider-left',
          children: [
            firstColumn,
            {
              colId: `distributionTY${g}`,
              headerName: this.$tkey('columns.acv'),
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              valueGetter: node =>
                node.data ? get(node.data, `distributionTY${suffix}`) / 100 : null,
              valueFormatter: params =>
                params.value ? `${(params.value * 100).toFixed(1)}%` : '-',
              minWidth: 70,
            },
            {
              field: `productContribution${g}`,
              colId: `productContribution${g}`,
              headerName: this.$tkey('columns.productContribution'),
              headerClass: 'text-right',
              cellClass: 'justify-end',
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              valueGetter: params => this.getProductContribution(params, g, suffix),
              valueFormatter: params =>
                params.value ? `${(params.value * 100).toFixed(1)}%` : '-',
              ...(g !== this.romGroups.RMA && {
                cellRenderer: 'arrowIndicatorRenderer',
                cellRendererParams: params => {
                  const rmaProductContribution = this.getProductContribution(
                    params,
                    this.romGroups.RMA,
                    suffix
                  );
                  return {
                    classRules: {
                      'fa-caret-up': params.value >= rmaProductContribution,
                      'fa-caret-down': params.value < rmaProductContribution,
                    },
                    reverse: true,
                  };
                },
              }),
            },
            {
              colId: `salesTY${g}`,
              headerName: this.$tkey('columns.sales'),
              headerClass: 'text-right',
              cellClass: 'justify-end',
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              valueGetter: node => (node.data ? get(node.data, `salesTY${suffix}`) : null),
              valueFormatter: params =>
                this.formatNumber({ number: params.value, format: 'currency', zeroAsDash: true }),
              minWidth: 120,
            },
            {
              field: `percentChangeLY${g}`,
              colId: `percentChangeLY${g}`,
              headerName: this.$tkey('columns.percentChangeLY'),
              headerClass: 'text-right',
              cellClass: 'justify-end',
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              valueGetter: params => this.getPercentageChange(params, suffix),
              valueFormatter: params => this.percentageFormatter(params.value),
            },
            {
              colId: `opportunityTY${g}`,
              headerName: this.$tkey('columns.opportunity'),
              type: 'numericColumnPermissiveCustom',
              filter: 'agTextColumnFilter',
              valueGetter: node => (node.data ? get(node.data, `opportunityTY${suffix}`) : null),
              valueFormatter: params =>
                this.formatNumber({ number: params.value, format: 'currency', zeroAsDash: true }),
              minWidth: 70,
              hide: g !== this.romGroups.MULO,
            },
          ],
        };
      });
    },

    definitionOptions() {
      return Object.entries(this.romSubGroups).map(([key, value]) => ({ key, value }));
    },

    categoryMarketShare() {
      const TotalSalesRMA = get(this.categoryAggregations, `${romMetrics.groups.RMA}-salesTY`, 0);
      const foodRMATotal =
        get(this.categoryAggregations, `${romMetrics.groups.FOOD}-salesTY`, 0) + TotalSalesRMA;
      const muloRMATotal =
        get(this.categoryAggregations, `${romMetrics.groups.MULO}-salesTY`, 0) + TotalSalesRMA;
      return {
        [romMetrics.groups.FOOD]: foodRMATotal ? TotalSalesRMA / foodRMATotal : 0,
        [romMetrics.groups.MULO]: muloRMATotal ? TotalSalesRMA / muloRMATotal : 0,
      };
    },

    categoryAggregations() {
      const aggFields = ['salesTY', 'salesLY'];

      return reduce(
        this.rowData,
        (acc, row) => {
          const rowLevel = size(row.hierarchy);
          // Sum subCategory rows when parent rows not filtered
          // Sums all product rows when parent filtered
          if (rowLevel === 1) {
            each(this.romGroups, g => {
              const suffix = upperFirst(g || '') + upperFirst(this.selectedSubGroup || '');
              each(aggFields, f => {
                const key = `${g}-${f}`;
                const geographyField = `${f}${suffix}`;
                const currentTotal = get(acc, key, 0);
                acc[key] = currentTotal + row[geographyField];
              });
            });
          }
          return acc;
        },
        {}
      );
    },

    availableCategoryKeys() {
      return new Set(map(this.productCategoryOptions, 'categoryKey'));
    },
  },

  beforeMount() {
    this.autoGroupColumnDef = {
      field: 'group',
      colId: 'group',
      headerName: this.$tkey('columns.group'),
      pinned: 'left',
      minWidth: 400,
      resizable: true,
      headerCheckboxSelection: !this.isEditingDisabled,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: params =>
        !this.isEditingDisabled && params.data && !!params.data.productKey,
      cellRendererParams: {
        suppressCount: true,
      },
    };
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions('toolData', ['getROMProductDataByCategory', 'fetchROMCategories']),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    refreshGrid() {
      // Ensure column defs are definitely up-to-date before refresh
      this.gridOptions.api.setColumnDefs(this.columnDefs);
      this.gridApi.refreshCells({ force: true });
    },

    async init() {
      const categories = await this.fetchROMCategories();
      this.productCategoryOptions = orderBy(categories, ['categoryDescription']);
      // If the product category key prop exists as a dropdown option, select it.
      // Alternatively, set the first dropdown option as the default selected
      this.selectedProductCategory = this.availableCategoryKeys.has(this.productCategoryKey)
        ? this.productCategoryKey
        : get(head(this.productCategoryOptions), 'categoryKey', null);
      await this.fetchProducts();
      this.refreshGrid();
    },

    async fetchProducts() {
      const { rowData, subGroups } =
        (await this.getROMProductDataByCategory({
          categoryKey: this.selectedProductCategory,
          existingProducts: this.productsToExclude,
        })) || {};
      this.rowData = rowData;
      this.romSubGroups = subGroups;

      // Set default sub group selections

      this.selectedSubGroup = keys(this.romSubGroups)[0];
    },

    onSelectedCategoryChange(categoryKey) {
      this.selectedProductCategory = categoryKey;
      const debounceFetchProducts = debounce(this.fetchProducts, 500);
      debounceFetchProducts();
    },

    onSelectedGroupChange(selection) {
      this.selectedSubGroup = selection;
      this.refreshGrid();
    },

    toggleParentRows(isFiltered) {
      this.filterParentRows = isFiltered;
      const rowData = this.filterParentRows
        ? reduce(
            this.rowData,
            (acc, row) => {
              if (row.productKey) acc.push({ ...row, hierarchy: [row.productKey] });
              return acc;
            },
            []
          )
        : this.rowData;
      this.gridApi.setRowData(rowData);
    },

    toggleListedProducts(isFiltered) {
      this.filterListedProducts = isFiltered;
      this.gridApi.onFilterChanged();
    },

    doesExternalFilterPass(node) {
      if (this.filterListedProducts) {
        const falseOptions = new Set(booleanOptions.falseOptions);
        return node.data ? falseOptions.has(node.data.isListed) : false;
      }
    },

    setSelectedProducts() {
      const selectedRows = this.gridApi.getSelectedRows();
      this.selectedProducts = reduce(
        selectedRows,
        (acc, row) => {
          if (row.productKey) {
            acc.push(row);
          }
          return acc;
        },
        []
      );
    },

    addSelectedProducts() {
      // Only send data we need to attribute editor
      const newProducts = map(this.selectedProducts, p =>
        pick(p, ['productKey', 'itemDescription', 'brandName'])
      );
      this.$emit('add-selected-products', newProducts);
      this.closeModal();
    },

    getProductContribution(params, group, suffix) {
      const totalSales = get(this.categoryAggregations, `${group}-salesTY`, 0);
      const salesTY = params.data ? params.data[`salesTY${suffix}`] : null;
      return round(totalSales ? salesTY / totalSales : 0, 2);
    },

    getPercentageChange(params, suffix) {
      const salesLY = params.data ? params.data[`salesLY${suffix}`] : null;
      const salesTY = params.data ? params.data[`salesTY${suffix}`] : null;

      return round(salesLY ? salesTY / salesLY - 1 : 0, 2);
    },

    getMarketShare(params, group, selectedSubGroup) {
      const salesTYRMA = params.data ? params.data[`salesTYRMA${selectedSubGroup}`] : null;
      const salesTY = params.data ? params.data[`salesTY${group}${selectedSubGroup}`] : null;
      const salesSum = salesTY + salesTYRMA;

      return round(salesSum ? salesTYRMA / salesSum : 0, 3);
    },

    getCategoryMarketShare(group) {
      return round(this.categoryMarketShare[group] * 100, 1);
    },

    percentageFormatter(value) {
      return value
        ? `${this.formatNumber({
            number: value * 100,
            format: 'percent',
          })}%`
        : '-';
    },

    discardProductSelections() {
      this.gridApi.deselectAll();
    },

    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.rest-of-market {
  &__container {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
  }

  &__toolbar {
    border-bottom: 1px solid $assortment-panel-border-divider-colour;
    padding: 0 8px;
  }

  &__label {
    white-space: nowrap;
  }

  &__select {
    max-width: 250px !important;
  }

  &__checkbox {
    margin: 0 !important;

    ::v-deep.v-input--selection-controls__input {
      margin-right: 3px;
    }
  }
}

.ag-theme-custom {
  ::v-deep {
    .ag-header-row.ag-header-row-column-group {
      border-bottom: 1px solid $assortment-ag-grid-header-separator-colour;

      .ag-header-group-cell-label {
        justify-content: center;
      }
    }

    .ag-header-cell.text-right {
      .ag-header-cell-label {
        text-align: right;
        justify-content: end;
      }
    }

    .ag-cell-wrapper {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .ag-cell.justify-end {
      padding-right: 15px;
    }

    .ag-ltr .ag-row-group-indent {
      &-1 {
        padding-left: 20px;
      }

      &-2 {
        margin-left: 0;
        padding-left: 42px;
      }
    }

    .ag-header-select-all {
      margin-right: 13px;
    }

    .ag-row-selected {
      &.ag-row-even {
        background: $assortment-table-blue-bg-colour !important;
      }

      &.ag-row-odd {
        background: $assortment-table-white-bg-colour !important;
      }

      &:before {
        content: none;
      }
    }

    .ag-selection-checkbox.ag-hidden + .ag-cell-value {
      padding-left: 28px;
    }

    .ag-group-expanded,
    .ag-group-contracted {
      margin-right: 5px;
    }
  }
}
</style>
