var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "workpackage-list" },
    _vm._l(_vm.list, function (workpackage) {
      return _c(
        "li",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show(workpackage),
              expression: "show(workpackage)",
            },
          ],
          key: workpackage._id,
          staticClass: "workpackage-list__element",
        },
        [
          _c("workpackage-wrapper", {
            attrs: {
              id: `wp-panel-${workpackage._id}`,
              entity: _vm.entity,
              "is-link": _vm.isLink,
              workpackage: workpackage,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }