var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex align-center cell-container",
      class: _vm.editingModeClass,
    },
    [
      !_vm.editingMode
        ? _c("div", { staticClass: "displayed-value" }, [
            _vm._v(_vm._s(_vm.displayedValue)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isSelected
        ? _c(
            "div",
            { staticClass: "d-flex" },
            _vm._l(_vm.limits, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "d-flex" },
                [
                  _c("rtls-text-field", {
                    key: index,
                    attrs: { rules: _vm.getRules(index), width: "10" },
                    on: {
                      "rtls-text-field-errors": (errors) =>
                        _vm.handleErrors(errors, index),
                    },
                    model: {
                      value: item.value,
                      callback: function ($$v) {
                        _vm.$set(item, "value", $$v)
                      },
                      expression: "item.value",
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }