var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container" }, [
    _c("span", { staticClass: "value" }, [_vm._v(_vm._s(_vm.value) + " ")]),
    _c("span", { staticClass: "suffix" }, [_vm._v(_vm._s(_vm.suffix))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }