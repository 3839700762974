<template>
  <v-row class="previous-extract-report-row">
    <v-col class="row-item font-weight-bold" cols="3">
      <div :title="report.filenameOnDownload" class="overflow-ellipsis">
        {{ report.filenameOnDownload }}
      </div>
    </v-col>
    <v-col class="row-item" cols="3">{{
      report.extractDatetime | formatDate(dateFormats.longWithTime)
    }}</v-col>
    <v-col class="row-item" cols="3">{{
      $t(`extract.reports.reportTypes.${reportTypeKey}`)
    }}</v-col>
    <v-col class="row-item justify-center" cols="2">
      <v-icon v-if="report.status" size="18">{{ `$${report.status}` }}</v-icon>
    </v-col>
    <v-col class="row-item justify-center" cols="1">
      <div v-if="isExternalExtract && qaControlsEnabled">
        <v-btn icon @click="devTestExtract">
          <v-icon color="primary" size="18">
            mdi-plus-box
          </v-icon>
        </v-btn>
      </div>
      <download-link
        v-else
        :filename="report.filenameOnDownload"
        :visible="isDownloadLinkVisible"
        :url="buildLink(report)"
        :disabled="isDownloadDisabled"
      >
        <v-btn :disabled="isDownloadDisabled" icon>
          <v-icon color="primary" size="18">
            mdi-download
          </v-icon>
        </v-btn>
      </download-link>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { EXTERNAL_OPTIMISATION_EXTRACT } from '@enums/extract-types';
import { jobStatuses } from '@enums/jobapi';
import { StoreNamespaces } from '../../../../store/constants';

export default {
  props: {
    report: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { statusFinished: jobStatuses.finished };
  },
  computed: {
    ...mapGetters(StoreNamespaces.context, {
      dateFormats: 'getDateFormats',
      qaControlsEnabled: 'getQaControlsEnabled',
    }),
    isDownloadLinkVisible() {
      return this.statusFinished === this.report.originalJobStatus;
    },
    isDownloadDisabled() {
      return (
        !this.hasPermission(this.userPermissions.canEditProductStoreExtracts) ||
        this.isExternalExtract
      );
    },
    isExternalExtract() {
      return this.report.extractType === EXTERNAL_OPTIMISATION_EXTRACT;
    },

    reportTypeKey() {
      // Convert from snake case to camel case for the translation key
      return this.report.extractType
        .toLowerCase()
        .replace(/([-_][a-z])/g, group => group.toUpperCase().replace('_', ''));
    },
  },
  methods: {
    buildLink(report) {
      const extractFileType =
        report.extractType === 'store_execution' || report.extractType === 'furniture'
          ? 'zip'
          : 'excel';

      return `/api/file/${report.fileId}?type=${extractFileType}`;
    },
    /**
     * This method is for testing the external extract, Its only available for admins and will print the result in the console
     * This allows for easier testing of the external api
     */
    async devTestExtract() {
      const extractName = this.report.filenameOnDownload;
      const jsonResponse = await axios.get(
        `/api/reports/external/product-level-extract/${extractName}`
      );
      console.log(jsonResponse.data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.previous-extract-report-row {
  list-style: none;
  margin-bottom: 6px;
  background-color: $assortment-dialog-background;
}

::v-deep {
  a {
    text-decoration: none;
  }
}

.row-item {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding-bottom: 8px;
  padding-top: 8px;
}
</style>
