<template>
  <v-dialog :value="isOpen" :width="width" content-class="rtls-dialog" @input="$emit('close')">
    <dialog-card is-popup :title="title" content-class="no-max-height" @close="$emit('close')">
      <slot />
      <template v-slot:footer>
        <v-row>
          <v-col :cols="12" align-self="center" class="d-flex justify-center">
            <v-btn primary @click="$emit('close')">{{ $t('actions.ok') }}</v-btn>
          </v-col>
        </v-row>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: String,
      required: false,
      default: '20%',
    },
  },
};
</script>

<style scoped></style>
