import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex h-100 w-100" },
    [
      _vm.isLoading
        ? _c("progress-bar", { attrs: { message: _vm.$t("general.loading") } })
        : _c(
            VContainer,
            { attrs: { fluid: "" } },
            [
              _vm.hasMultipleScenariosSelected
                ? _c(
                    VRow,
                    [
                      _c(VAlert, { attrs: { type: "info", text: "" } }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$tkey(
                                "warnings.workpackageWithMultipleScenariosSelected"
                              )
                            ) +
                            "\n      "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                VRow,
                [
                  _c(
                    VBtn,
                    {
                      attrs: {
                        primary: "",
                        depressed: "",
                        disabled: _vm.isAddNewRowDisabled,
                      },
                      on: { click: _vm.addNewRow },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("actions.addNewRow")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      staticClass: "ml-2",
                      attrs: {
                        depressed: "",
                        action: "",
                        disabled: _vm.isSaveDisabled,
                      },
                      on: { click: _vm.save },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("actions.save")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VRow,
                { staticClass: "w-100", staticStyle: { height: "95%" } },
                [
                  _c("ag-grid-vue", {
                    staticClass: "ag-theme-custom w-100 h-100",
                    attrs: {
                      "master-detail": true,
                      "column-defs": _vm.columnDefs,
                      "row-data": _vm.rowData,
                      "grid-options": _vm.gridOptions,
                      "stop-editing-when-cells-loses-focus": true,
                      "detail-cell-renderer-params":
                        _vm.detailCellRendererParams,
                    },
                    on: {
                      "cell-value-changed": _vm.trackDiff,
                      "grid-ready": _vm.onGridReady,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }