<template>
  <div class="spacebreak-title d-flex align-center">
    <h2>{{ title }}</h2>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  localizationKey: 'assortmentCanvasPage.spacebreakSettings.constraints',
  data() {
    return {
      title: null,
    };
  },
  mounted() {
    this.title = this.params.title;
  },
});
</script>

<style lang="scss" scoped>
.spacebreak-title {
  height: 45px;
}
</style>
