import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        VContainer,
        {
          staticClass: "actions-container pa-0 ma-0 flex-grow-0",
          style: [
            { height: _vm.showSpacebreakConfigurations ? "62px" : "52px" },
          ],
        },
        [
          _c(
            VRow,
            [
              _c(
                VRow,
                { staticClass: "actions-col" },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "d-flex align-center spacebreak-actions",
                      class: [
                        _vm.showSpacebreakConfigurations ? "px-3 py-2" : "pa-3",
                      ],
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "mr-3 font-weight-bold" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tkey("reviewSpacebreakMessage")) +
                              "\n\n            "
                          ),
                          _vm._v(" "),
                          _c("docs-link", {
                            attrs: { link: "toolguide/04f-spacebreaks.html" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.hasSpacebreaks
                        ? _c(
                            VBtn,
                            {
                              staticClass: "button-space-5",
                              attrs: {
                                disabled:
                                  _vm.isEditingDisabled || _vm.isSBJobRunning,
                                "data-id-e2e": "btnCalculateSpacebreaks",
                                primary: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onCalculateSpacebreakClick(false)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$tkey("calculateSpacebreaks")) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showSpacebreakConfigurations
                    ? _c(
                        VCol,
                        { staticClass: "spacebreak-configurations pa-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            _vm._l(
                              _vm.spacebreakConfigurations,
                              function (v, c) {
                                return _c(
                                  "div",
                                  {
                                    key: `${c}-configuration`,
                                    staticClass: "d-flex align-center",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "mr-2 spacebreak-configurations__label",
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$tkey(`configurations.${c}`)
                                            ) +
                                            ":\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("rtls-text-field", {
                                      staticClass:
                                        "spacebreak-configurations__input mr-2",
                                      attrs: {
                                        disabled: _vm.isEditingDisabled,
                                        grey: "",
                                        rules: _vm.rules[c],
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.updateDiff(c, $event)
                                        },
                                      },
                                      model: {
                                        value: _vm.spacebreakConfigurations[c],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.spacebreakConfigurations,
                                            c,
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "spacebreakConfigurations[c]",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass:
                                "mb-0 mt-1 spacebreak-configurations__message",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$tkey("configurations.infoMessage")
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VContainer,
        {
          staticClass: "pa-0 flex-grow-1 d-flex flex-column",
          attrs: { fluid: "" },
        },
        [
          !_vm.hasSpacebreaks || _vm.isSBJobRunning
            ? _c(
                VLayout,
                { staticClass: "empty-switching" },
                [
                  _c(
                    VRow,
                    { staticClass: "main-panel" },
                    [
                      _c(VCol, {
                        staticClass: "rtls-border rtls-border--right",
                        attrs: { cols: "2" },
                      }),
                      _vm._v(" "),
                      _c(
                        VCol,
                        {
                          staticClass: "pl-0 pr-0 pb-0",
                          attrs: { cols: "10" },
                        },
                        [
                          _vm.isSBJobRunning
                            ? _c("progress-bar", {
                                attrs: {
                                  message: _vm.$t("messages.inProgress"),
                                  percentage:
                                    _vm.jobStatuses.spacebreakGenerator
                                      .progress,
                                  status:
                                    _vm.jobStatuses.spacebreakGenerator.status,
                                },
                              })
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-center justify-center flex-column",
                                  staticStyle: { height: "100%" },
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "empty-switching-text" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tkey("spacebreaksNotGenerated")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "empty-switching-text" },
                                    [_vm._v(_vm._s(_vm.$tkey("runToView")))]
                                  ),
                                ]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasSpacebreaks && !_vm.isSBJobRunning
            ? [
                _c(
                  VRow,
                  { staticClass: "main-panel" },
                  [
                    _c(
                      VTabs,
                      {
                        staticClass: "store-class-tabs",
                        attrs: { "hide-slider": true },
                        model: {
                          value: _vm.selectedTab,
                          callback: function ($$v) {
                            _vm.selectedTab = $$v
                          },
                          expression: "selectedTab",
                        },
                      },
                      [
                        _vm._l(_vm.getStoreClassesInScenario, function (item) {
                          return _c(
                            VTab,
                            {
                              key: item._id,
                              staticClass: "store-class-tab text-none step-tab",
                            },
                            [
                              _c("span", { staticClass: "tab-title" }, [
                                _vm._v(" " + _vm._s(item.name)),
                              ]),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          VTabsItems,
                          {
                            model: {
                              value: _vm.selectedTab,
                              callback: function ($$v) {
                                _vm.selectedTab = $$v
                              },
                              expression: "selectedTab",
                            },
                          },
                          _vm._l(
                            _vm.getStoreClassesInScenario,
                            function (item) {
                              return _c(
                                VTabItem,
                                { key: item._id, staticClass: "tab-content" },
                                [
                                  _c("spacebreaks", {
                                    attrs: { "store-class-id": item._id },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "page-actions-container" },
                  [
                    _c("page-actions", {
                      attrs: {
                        "show-export": _vm.showNotImplemented,
                        "has-data-changes": _vm.hasDataChanges,
                        "has-data-errors": _vm.hasDataErrors,
                        "save-loading": _vm.isLoading || _vm.isSaving,
                        "save-disabled": _vm.isEditingDisabled || _vm.isSaving,
                        "is-discard-enabled":
                          !_vm.isEditingDisabled && !_vm.isSaving,
                      },
                      on: { discard: _vm.discard, save: _vm.save },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "right-btns",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  { staticClass: "calculate-btn-container" },
                                  [
                                    _c(
                                      VBtn,
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.isEditingDisabled ||
                                            _vm.isSBJobRunning,
                                          "data-id-e2e":
                                            "btnReCalculateSpacebreaks",
                                          primary: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.onCalculateSpacebreakClick(
                                              false
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$tkey(
                                                "reCalculateSpacebreaks"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2452215580
                      ),
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("dependency-tree-feedback-modal", {
            attrs: {
              value: _vm.dependencyTreeModalOpen,
              results: _vm.dependencyTreeFeedback,
              page: "spacebreakCalculator",
            },
            on: {
              close: _vm.closeDependencyTreeModal,
              commit: _vm.commitHandler,
            },
          }),
          _vm._v(" "),
          _c("unsaved-data-modal", {
            ref: "unsavedDataModal",
            attrs: { value: _vm.isUnsavedDataModalOpen },
            on: {
              cancel: _vm.closeUnsavedDataModal,
              confirm: _vm.closeUnsavedDataModal,
            },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }