var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex rtls-text-field-container" },
    [
      _c(
        "input",
        _vm._g(
          {
            style: _vm.widthStyle,
            attrs: { label: _vm.label },
            domProps: { value: _vm.value },
          },
          _vm.$listeners
        )
      ),
      _vm._v(
        "\n  " +
          _vm._s(/* use $listeners to pass all event listeners on rtls-text-field through to v-text-field */) +
          "\n  "
      ),
      _c("error-triangle", {
        class: _vm.errorTriangleClass,
        attrs: { errors: _vm.errors, small: _vm.small },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }