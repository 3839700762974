<template>
  <section class="template-card d-flex flex-column" :class="workpackageSetupJobStatus">
    <template-panel :workpackage="item" />
  </section>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    workpackageSetupJobStatus() {
      if (this.item.jobs && this.item.jobs.workpackageSetup) {
        return this.item.jobs.workpackageSetup.status.toLowerCase();
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.template-card {
  border-top: 2px solid $assortment-template-panel-border-colour;

  &.failed {
    border-top-color: $assortment-workpackage-drag-cell-colour-failed !important;
  }

  &.in-progress {
    border-top-color: $assortment-workpackage-drag-cell-colour-in-progress !important;
  }

  &.finished {
    border-top-color: $assortment-workpackage-drag-cell-colour-finished !important;
  }
}
</style>
