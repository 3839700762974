import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    [
      _c(
        "extract-report-generator",
        {
          attrs: { disabled: _vm.isGenerateExtractDisabled },
          on: { "generate-extract-requested": _vm.generateReport },
        },
        [
          _c(
            VContainer,
            {
              staticClass: "report-jda d-flex flex-column pa-0 ma-0",
              attrs: { fluid: "" },
            },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    VCol,
                    { attrs: { colspan: "6" } },
                    [
                      _vm.isScenarioInputIncomplete &&
                      !_vm.missingAttributes.length
                        ? _c("div", { staticClass: "validation-message" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$tkey(
                                    "extractValidation.incompleteInputs"
                                  )
                                ) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.missingAttributes.length
                        ? _c("div", { staticClass: "validation-message" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$tkey(
                                    "extractValidation.missingAttributes"
                                  )
                                ) +
                                "\n            "
                            ),
                            _c(
                              "ul",
                              _vm._l(_vm.missingAttributes, function (item) {
                                return _c("li", { key: item }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(item) +
                                      "\n              "
                                  ),
                                ])
                              }),
                              0
                            ),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$tkey(
                                    "extractValidation.addAttributesInstruction"
                                  )
                                ) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "report-input-item",
                        {
                          staticClass: "mb-4",
                          attrs: { title: _vm.$tkey("extractName") },
                        },
                        [
                          _c("rtls-text-field", {
                            attrs: {
                              disabled: _vm.isEditingDisabled,
                              grey: "",
                              placeholder: _vm.$tkey("chooseAnExtractName"),
                            },
                            model: {
                              value: _vm.extractName,
                              callback: function ($$v) {
                                _vm.extractName = $$v
                              },
                              expression: "extractName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.requiredAttributesForExtract.length
                        ? _c(
                            "report-input-item",
                            {
                              scopedSlots: _vm._u(
                                [
                                  _vm.usesPresentationCategoryId
                                    ? {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex mb-2" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "mr-1 font-weight-bold",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$tkey("category")
                                                      ) + " "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$tkey(
                                                          "categoryHelperText"
                                                        )
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      }
                                    : null,
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                VRadioGroup,
                                {
                                  staticClass: "jda-radio-group",
                                  model: {
                                    value: _vm.category,
                                    callback: function ($$v) {
                                      _vm.category = $$v
                                    },
                                    expression: "category",
                                  },
                                },
                                _vm._l(_vm.categories, function (item) {
                                  return _c(VRadio, {
                                    key: item,
                                    attrs: { label: `${item}`, value: item },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VCol, { attrs: { colspan: "6" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }