import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCol,
    {
      staticClass:
        "attributes-column pa-0 d-flex flex-column justify-space-between",
      staticStyle: { "vertical-align": "top" },
      attrs: { cols: 3 },
    },
    [
      _c(
        VContainer,
        { staticClass: "ma-0 pa-0 controls" },
        [
          _c(
            VRow,
            {
              staticClass:
                "d-flex justify-start align-center heading-row pt-4 pb-3",
              attrs: { "no-gutters": "" },
            },
            [
              _c(VCol, { staticClass: "pl-3", attrs: { cols: 12 } }, [
                _c("h4", [_vm._v(_vm._s(_vm.$tkey("selectAttributes")))]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(VDivider),
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedCannGroup
        ? _c("div", { staticClass: "attributes pa-2" }, [
            _vm.scenarioHasAttributes
              ? _c("h4", { staticClass: "attributes-column-header pb-2" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$tkey("customerFacingAndReporting")) +
                      ":\n    "
                  ),
                ])
              : _c(
                  "h4",
                  {
                    staticClass:
                      "attributes-column-header text-center pt-6 pb-2",
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$tkey("scenarioHasNoAttributes")) +
                        "\n    "
                    ),
                  ]
                ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "attribute-list-group" },
              _vm._l(_vm.currentSelections, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass:
                      "d-flex align-center justify-start attribute-item",
                  },
                  [
                    _c(VCheckbox, {
                      staticClass: "rtls-checkbox ml-2 mr-0",
                      attrs: {
                        disabled: _vm.disabled,
                        "input-value": item.selected,
                      },
                      on: {
                        change: function ($event) {
                          item.selected = $event
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("h4", { staticClass: "pb-0" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ],
                  1
                )
              }),
              0
            ),
          ])
        : _c("div", { staticClass: "select-cann-group pa-10" }, [
            _vm._v(_vm._s(_vm.$tkey("selectCannGroup"))),
          ]),
      _vm._v(" "),
      _vm.selectedCannGroup
        ? _c(
            "div",
            {
              staticClass:
                "attributes-actions-container pt-2 pb-0 d-flex justify-end flex-wrap",
            },
            [
              _vm.showNotImplemented
                ? _c(
                    VBtn,
                    {
                      staticClass: "mr-2 mb-2",
                      attrs: { disabled: "", icon: "", text: "" },
                    },
                    [
                      _c(VIcon, { attrs: { size: "24" } }, [
                        _vm._v("$reset"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("select-multiple-cann-groups-modal", {
                attrs: {
                  "include-options": "",
                  "exclude-self": "",
                  "enable-all-options": "",
                  "save-button-text": "copyAndSave",
                  "selected-cann-group": _vm.selectedCannGroup,
                  title: _vm.$tkey("copy.title"),
                  subtitle: _vm.$tkey("copy.subtitle"),
                },
                on: { confirm: _vm.copyCdtInformation },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            VBtn,
                            _vm._g(
                              {
                                staticClass: "mr-2 mb-2",
                                attrs: {
                                  disabled: _vm.disabled,
                                  secondary: "",
                                  depressed: "",
                                },
                              },
                              on
                            ),
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$tkey("copy.button")) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4185852192
                ),
              }),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    "data-id-e2e": "btnSaveCDT",
                    action: "",
                    disabled: _vm.disabled || !_vm.hasDataChanges,
                    depressed: "",
                  },
                  on: { click: _vm.saveSelection },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("actions.save")) + "\n    ")]
              ),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    "data-id-e2e": "btnGenerateCDT",
                    primary: "",
                    depressed: "",
                    disabled: _vm.disabled || !_vm.hasSelectedAttribute,
                  },
                  on: { click: _vm.generate },
                },
                [_vm._v("\n      " + _vm._s(_vm.$tkey("generate")) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }