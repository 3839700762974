import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VMenu,
    {
      attrs: {
        disabled: !_vm.userNotifications.length,
        "offset-y": "",
        "min-width": 300,
        "max-width": 300,
        "max-height": 360,
        "min-height": 360,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                VBtn,
                _vm._g(
                  {
                    staticClass: "text-none subheading nav-link",
                    attrs: {
                      id: "navbar-user-notifications",
                      disabled: !_vm.userNotifications.length,
                      text: "",
                      large: "",
                      color: "primary",
                    },
                  },
                  on
                ),
                [
                  _c(
                    "div",
                    { staticClass: "icons-container" },
                    [
                      _vm.numUnreadNotifications
                        ? _c(
                            VAvatar,
                            {
                              staticClass: "pa-2 spacebreak-count-badge",
                              attrs: { size: "20" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "white--text headline" },
                                [_vm._v(_vm._s(_vm.numUnreadNotifications))]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(VIcon, { attrs: { size: "28" } }, [
                        _vm._v("notifications_none"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpened,
        callback: function ($$v) {
          _vm.isOpened = $$v
        },
        expression: "isOpened",
      },
    },
    [
      _vm._v(" "),
      _c(
        VList,
        [
          _c(VSubheader, { staticClass: "sub-header" }, [
            _vm._v(_vm._s(_vm.$t("general.notifications"))),
          ]),
          _vm._v(" "),
          _vm._l(_vm.userNotifications, function (notification) {
            return [
              _c(
                VListItem,
                {
                  key: notification._id,
                  class: _vm.getNotificationClass(notification),
                  on: {
                    click: function ($event) {
                      return _vm.goToNotification(notification)
                    },
                  },
                },
                [
                  _c(
                    VListItemContent,
                    [
                      _c(
                        VListItemTitle,
                        { staticClass: "notification__title" },
                        [
                          _c(
                            "span",
                            [
                              !notification.isRead
                                ? _c(VAvatar, {
                                    staticClass: "notification-unread-badge",
                                    attrs: { size: "9" },
                                  })
                                : _vm._e(),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(`jobNames.${notification.eventType}`)
                                  ) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm._f("formatDate")(
                                    notification.date,
                                    _vm.getDateFormats.longWithTimeAndMeridiem
                                  )
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        VListItemSubtitle,
                        { staticClass: "notification__content" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.getDescription(notification)) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        VListItemSubtitle,
                        { staticClass: "notification__content" },
                        [
                          _c("b", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  notification.eventType ===
                                    _vm.workpackageJobTypes.templateSetup
                                    ? _vm.$t("entities.template")
                                    : _vm.$t("entities.workpackage")
                                ) +
                                ":\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                notification.details.entityNames.workpackageName
                              )
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      notification.details.entityIds.scenarioId
                        ? _c(
                            VListItemSubtitle,
                            { staticClass: "notification__content" },
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("entities.scenario")) + ": "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    notification.details.entityNames
                                      .scenarioName
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      notification.details.entityIds.canvasId
                        ? _c(
                            VListItemSubtitle,
                            { staticClass: "notification__content" },
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("entities.canvas")) + ": "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    notification.details.entityNames.canvasName
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      notification.details.entityIds.checkpointId
                        ? _c(
                            VListItemSubtitle,
                            { staticClass: "notification__content" },
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("entities.checkpoint")) + ": "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    notification.details.entityNames
                                      .checkpointName
                                  )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(VDivider, { key: `divider-${notification._id}` }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }