<template>
  <div class="date-locale-switcher">
    <div class="label">{{ $t('navBar.date') }}</div>
    <rtls-select
      v-model="currentDateLocale"
      :items="availableFormats"
      item-text="format"
      item-value="locale"
      white
      :menu-props="{ right: true, offsetX: true, contentClass: 'options' }"
      solo
      hide-details
      @change="setFormat"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DateLocaleSwitcher',

  data: () => ({
    currentDateLocale: null,
    availableFormats: null,
  }),

  computed: {
    ...mapGetters('context', [
      'getCurrentDateLocale',
      'getAvailableDateLocales',
      'getDateFormats',
      'getClientConfig',
    ]),
  },

  created() {
    this.setDateFormats();
  },

  methods: {
    ...mapActions('context', ['setDateLocale']),

    setFormat(dateLocale) {
      this.setDateLocale({ dateLocale });
    },

    setDateFormats() {
      this.currentDateLocale = {
        locale: this.getCurrentDateLocale,
        format: this.getDateFormats.long,
      };
      this.availableFormats = this.getAvailableDateLocales.map(el => {
        return { locale: el, format: this.getClientConfig.dateFormats[el].long };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input__append-inner {
    transform: rotate(-90deg);
  }
}

.label {
  font-size: 1.2rem;
  font-weight: normal;
  min-width: 4rem;
}

.date-locale-switcher {
  display: flex;
  align-items: center;
}
</style>
