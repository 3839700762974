<template>
  <v-dialog v-model="dialog" persistent retain-focus max-width="350" content-class="rtls-dialog">
    <template v-slot:activator="{ on: onDialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            class="delete-button"
            icon
            text
            :disabled="disabled"
            :loading="loading"
            v-on="{ ...onDialog, ...onTooltip }"
          >
            <v-icon>$trash</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('actions.delete') }}</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title class="title" primary-title>
        <v-btn class="close-button" icon @click="closeModal">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="display-1 text-center">
        <p>{{ $tkey('question', { extractName }) }}</p>
        <strong>{{ $tkey('warning') }}</strong>
      </v-card-text>

      <v-card-actions class="justify-center flex-column">
        <v-btn primary class="btn-delete ma-2" @click="_deleteExtract">
          {{ $tkey('action') }}
        </v-btn>
        <v-hover disabled>
          <v-btn text depressed class="cancel ma-2" @click="closeModal">{{
            $t('actions.cancel')
          }}</v-btn>
        </v-hover>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

export default {
  localizationKey: 'compoundExtractsPage.deleteButton',
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('compoundExtract', ['getExtractById']),

    extractId() {
      return get(this.params, 'extractId');
    },

    extract() {
      return this.getExtractById(this.extractId) || {};
    },

    extractName() {
      return get(this.params, 'filenameOnDownload');
    },
    disabled() {
      return (
        !this.hasPermission(this.userPermissions.canDeleteCompoundsExtract) ||
        !this.extract._id ||
        !this.extract.extractSettingsId
      );
    },
  },
  methods: {
    ...mapActions('extracts', ['deleteExtract']),
    async _deleteExtract() {
      this.loading = true;
      try {
        this.closeModal();

        await this.deleteExtract(this.extractId);
      } finally {
        this.loading = false;
        this.params.refresh();
      }
    },
    closeModal() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.title {
  position: relative;
}

.delete-button {
  .v-icon {
    color: $assortment-primary-colour;
  }
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
