// keep in sync with webtool/server/middleware/user-context/router.js

import numeral from 'numeral';
import { forOwn } from 'lodash';
import 'numeral/locales/nl-nl';
import 'numeral/locales/en-gb';
import locales from '@sharedModules/config/locales';

// TODO AOV3-1835 solve issue of client specific config available here and do:
// if(!config.useCustomLocale) require(`numeral/locales/${config.numericLocale}`);
// otherwise we cannot support other clients without code changes!
forOwn(locales, locale => numeral.register('locale', locale.name, locale));
