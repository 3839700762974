import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: `overview-${_vm.isSidebarShown}`,
      staticClass: "container h-100 px-0 mx-0",
    },
    [
      _c("div", { staticClass: "reporting-sections-wrapper" }, [
        _c(
          "div",
          { staticClass: "reporting-section box-shadow-top-left" },
          [
            _c(
              "div",
              { staticClass: "button-wrapper" },
              [
                _c(
                  VBtn,
                  {
                    attrs: {
                      primary: "",
                      disabled: _vm.isStatsReportDisabled,
                      to: _vm.goToDetailedView("stats"),
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$tkey("sections.view")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("stats", {
              staticClass: "flex-grow-1 shortview-report",
              attrs: { "short-view": "" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "reporting-section box-shadow-top-right" },
          [
            _c(
              "div",
              { staticClass: "button-wrapper" },
              [
                _c(
                  VBtn,
                  {
                    attrs: {
                      primary: "",
                      disabled: _vm.isRelativeShareReportDisabled,
                      to: _vm.goToDetailedView("relative-share"),
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$tkey("sections.view")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("relative-share", {
              staticClass: "flex-grow-1 shortview-report",
              attrs: { "short-view": "" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "reporting-section box-shadow-bottom-left" },
          [
            _c(
              "div",
              { staticClass: "button-wrapper" },
              [
                _c(
                  VBtn,
                  {
                    attrs: {
                      primary: "",
                      disabled: _vm.isPriceLadderReportDisabled,
                      to: _vm.goToDetailedView("price-ladder"),
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$tkey("sections.view")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("price-ladder", {
              staticClass: "flex-grow-1 shortview-report",
              attrs: { "short-view": "" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "reporting-section box-shadow-bottom-right" },
          [
            _c(
              "div",
              { staticClass: "button-wrapper" },
              [
                _c(
                  VBtn,
                  {
                    attrs: {
                      primary: "",
                      disabled: _vm.isComparisonWaterfallReportDisabled,
                      to: _vm.goToDetailedView("comparison-waterfall"),
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$tkey("sections.view")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("comparison-waterfall", {
              staticClass: "flex-grow-1 shortview-report",
              attrs: { "short-view": "" },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }