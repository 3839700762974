var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ag-grid-box flex-grow-1 grid-size" },
    [
      _c("ag-grid-vue", {
        staticClass: "ag-theme-custom ag-theme-custom--attributes",
        staticStyle: { width: "100%", height: "100%" },
        attrs: {
          "column-defs": _vm.columnDefs,
          "row-data": _vm.products,
          "does-external-filter-pass": _vm.doesExternalFilterPass,
          "auto-params-refresh": "",
          "grid-options": _vm.gridOptions,
          "row-drag-managed": "",
          "stop-editing-when-cells-loses-focus": "",
        },
        on: {
          "cell-value-changed": _vm.trackDiff,
          "grid-ready": _vm.onGridReady,
          "selection-changed": _vm.onSelectionChanged,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }