<template>
  <div class="cann-group">
    <notes
      :notes="getAllNotes"
      :loading="isLoading"
      :title="$t('notes.types.cannGroup')"
      :max-chars="getClientConfig.notesSettings.maxLength"
      :is-cann-group="true"
      :notifications="!!leafNode.totalNotes"
      @post="postNewCannGroupNote(leafNode.key, $event)"
      @menuOpen="fetchSingleCannGroupNotes(leafNode.key)"
      @delete="deleteCannGroupNote(leafNode.key, $event)"
      @update="updateCannGroupNote(leafNode.key, $event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import contextEnum from '@enums/note-contexts';
import { notesMixin } from '../../../mixins/notes';

export default {
  name: 'CannGroupNote',
  mixins: [notesMixin(contextEnum.cannGroup)],
  props: {
    leafNode: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapGetters('context', ['getClientConfig']),

    getNotesParam() {
      return {
        id: this.leafNode.key,
        scenarioId: this.selectedScenario._id,
        workpackageId: this.selectedWorkpackage._id,
        context: contextEnum.cannGroup,
      };
    },
  },
  methods: {
    ...mapActions('notes', ['fetchCannGroupNotes', 'postNote', 'updateNote', 'deleteNote']),

    async postNewCannGroupNote(id, { message }) {
      this.isLoading = true;
      await this.postNote({
        params: {
          message,
          id,
          context: contextEnum.cannGroup,
          scenarioId: this.selectedScenario._id,
          workpackageId: this.selectedWorkpackage._id,
        },
        options: { storeToUpdate: 'scenario', mutationName: 'updateCannGroupNoteCount' },
      });
      this.isLoading = false;
    },

    async fetchSingleCannGroupNotes(id) {
      this.isLoading = true;
      await this.fetchCannGroupNotes({
        params: {
          id,
          scenarioId: this.selectedScenario._id,
          context: contextEnum.cannGroup,
        },
      });
      this.isLoading = false;
    },

    async updateCannGroupNote(id, { message, note }) {
      this.isLoading = true;
      await this.updateNote({
        params: {
          id,
          context: contextEnum.cannGroup,
          message,
          noteId: note._id,
        },
      });
      this.isLoading = false;
    },

    async deleteCannGroupNote(id, { noteId }) {
      this.isLoading = true;
      await this.deleteNote({
        params: {
          id,
          context: contextEnum.cannGroup,
          noteId,
        },
        options: { storeToUpdate: 'scenario', mutationName: 'updateCannGroupNoteCount' },
      });
      this.isLoading = false;
    },
  },
};
</script>
