var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.lastUpdated
    ? _c("span", { staticClass: "updated-text" }, [
        _c("strong", [
          _vm._v(_vm._s(_vm.$t("scenarioManagementPanel.updated")) + ": "),
        ]),
        _vm._v(
          "\n  " +
            _vm._s(
              _vm._f("formatDate")(
                _vm.lastUpdated,
                _vm.getDateFormats.longWithTime
              )
            ) +
            "\n"
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }