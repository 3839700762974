<template>
  <div>
    <switching-behaviour
      :requires-analytics-text="$tkey('requiresAnalyticsRun')"
      tooltip-link="toolguide/080-switching.html"
      :job-type="switchingJob"
      @run-switching="runSwitching"
    />
    <dependency-tree-feedback-modal
      :value="dependencyTreeModalOpen"
      :results="dependencyTreeFeedback"
      page="switching"
      @close="closeDependencyTreeModal"
      @commit="runSwitching(true)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { jobTypes } from '@enums/jobapi';

export default {
  localizationKey: 'switchingWrapper',
  data() {
    return {
      switchingJob: jobTypes.switching,
      dependencyTreeFeedback: {},
      dependencyTreeModalOpen: false,
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
  },

  methods: {
    ...mapActions('switchingMatrices', ['runSwitchingJob']),
    ...mapActions('scenarios', ['loadScenario']),

    async runSwitching(commit = false) {
      const result = await this.runSwitchingJob({
        params: { scenarioId: this.$route.params.scenarioId },
        commit,
      });
      if (result.data.needsFeedback) {
        this.dependencyTreeFeedback = result.data.output;
        this.dependencyTreeModalOpen = true;
      } else {
        await this.refreshSelectedScenario();
      }
    },

    async refreshSelectedScenario() {
      await this.loadScenario(this.selectedScenario._id);
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
    },
  },
};
</script>
