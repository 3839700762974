'use strict';

const { isEmpty, isNil } = require('lodash');
const numberFormatUtils = require('./number-format-utils');

const hasInvalidPeriodId = ({ periodIds, periodID, includedForAssortment }) => {
  // In case the workpackage doesnt have periodIds attached to it
  if (!periodIds || !periodIds.length) {
    return false;
  }

  // In case the workpackage has the No period ID option selected then any periodID should be valid
  if (periodIds.includes(null)) {
    return false;
  }

  // The periodID shouldn't be null or empty if the product is included for assortment
  if (includedForAssortment && (isEmpty(periodID) || isNil(periodID))) {
    return true;
  }

  // If the product is not included for Assortment we allow to be null or empty
  if (!includedForAssortment && (isEmpty(periodID) || isNil(periodID))) {
    return false;
  }

  // If the product is not included for Assortment
  // but there's a value for periodID then this value should be checked
  return !periodIds.includes(periodID);
};

const isTransactionWeightInvalid = ({ averageTransactionWeight, packageTypeDescription }) => {
  const isProductSoldByWeight = (packageTypeDescription || '').toLowerCase() === 'kg';
  const hasInvalidWeight = isProductSoldByWeight && numberFormatUtils.valueIsNotNumeric(averageTransactionWeight);

  return hasInvalidWeight;
};

module.exports = {
  hasInvalidPeriodId,
  isTransactionWeightInvalid,
};

