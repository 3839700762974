<template>
  <v-card class="wp-scope-stores col-box">
    <div class="wp-scope-stores__header mb-1">
      <h3 class="wp-scope-stores__input-heading">
        {{ $t('workpackagePage.scope.storesLabel') }}

        <!-- Stores scope tooltip -->
        <assortment-tooltip
          :title="$t('tooltips.storeScope.title')"
          :tooltip-sections="storeScopeTooltipSections"
        />
      </h3>
      <div>
        <workpackage-stores />
      </div>
    </div>

    <div class="grid-wrapper">
      <div class="item">{{ $t('workpackagePage.scope.storesScopeForAnalysis') }}</div>
      <div class="item item__count">
        {{ workpackage.storeCount ? workpackage.storeCount.analysis : 0 }}
      </div>

      <div class="item">{{ $t('workpackagePage.scope.storesScopeForAssortment') }}</div>
      <div class="item item__count">
        {{ workpackage.storeCount ? workpackage.storeCount.assortment : 0 }}
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { workpackageStoresTooltipOptionsMixin } from '@/js/mixins/tooltip-options';

export default {
  mixins: [workpackageStoresTooltipOptionsMixin],

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),

    workpackage() {
      return this.selectedWorkpackage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.wp-scope-stores {
  font-size: 1.2rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input-heading {
    font-size: 1.2rem;
    color: $assortment-workpackage-text;

    span {
      font-weight: normal;
    }
  }

  .v-btn {
    padding: 0;
  }

  .grid-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 1px;
    background-color: $assortment-workpackage-frame-bg-blue;

    .item {
      padding: 4px 8px;
      background-color: $assortment-table-header-white-background;
      color: $assortment-workpackage-text;
      line-height: 16px;

      &__count {
        font-weight: bold;
      }
    }
  }
}
</style>
