var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: `priceladder-${_vm.isSidebarShown}`,
      staticClass: "h-100 d-flex flex-column",
    },
    [
      _c("reporting-section", {
        attrs: { "short-view": _vm.shortView },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("reporting-header", {
                  attrs: {
                    "short-view": _vm.shortView,
                    section: _vm.section,
                    title: _vm.getTitle,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "filter",
                      fn: function () {
                        return [
                          !_vm.shortView
                            ? _c("filters", {
                                staticClass: "ml-3",
                                attrs: {
                                  filters: _vm.filters[_vm.section],
                                  "filter-options": _vm.filterOptions,
                                  "btn-text": _vm.filterButtonText,
                                  disabled:
                                    _vm.isPriceLadderReportDisabled ||
                                    _vm.filtersDisabled,
                                },
                                on: {
                                  change: _vm.handleFilterChange,
                                  apply: _vm.fetchData,
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "main-section",
            fn: function () {
              return [
                _c("reporting-main-section", {
                  attrs: {
                    section: _vm.section,
                    "short-view": _vm.shortView,
                    subtitle: _vm.$tkey("subtitle"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "data-section",
                      fn: function () {
                        return [
                          _vm.isPriceLadderReportDisabled
                            ? _c("span", { staticClass: "not-available" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("reportingPage.noPermissions")
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _vm.hasInvalidComparisons
                            ? _c("span", { staticClass: "not-available" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "reportingPage.notAvailableForComparisons"
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isLoading || _vm.isProcessingData
                            ? _c("progress-bar")
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isPriceLadderReportDisabled &&
                          !_vm.isLoading &&
                          !_vm.hasInvalidComparisons
                            ? _c(
                                "div",
                                {
                                  class: {
                                    "chartarea-sidebar-shown":
                                      _vm.isSidebarShown,
                                    "chartarea-sidebar-hidden":
                                      !_vm.isSidebarShown,
                                  },
                                  attrs: { id: "chartarea" },
                                },
                                [
                                  _c("div", {
                                    staticClass: "tooltip",
                                    attrs: { id: "tooltip" },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }