<template>
  <v-dialog :value="value" width="500px" @keydown.esc="cancel" @click:outside="cancel">
    <dialog-card :title="$tkey('modalTitle')" @close="cancel">
      <p>{{ $tkey('warning') }}</p>

      <template v-slot:footer>
        <v-card-actions>
          <v-btn primary @click="cancel">
            {{ $tkey('cancel') }}
          </v-btn>
          <v-btn secondary depressed class="outlined-btn" @click="confirm">
            {{ $tkey('confirm') }}
          </v-btn>
        </v-card-actions>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'UnsavedDataModal',
  localizationKey: 'unsavedDataModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 1.3rem;
  padding-top: 20px;
}

::v-deep {
  .dialog-card__footer {
    justify-content: flex-end;

    .v-card__actions {
      padding: 0;
    }
  }
}
</style>
