import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "step-tab-scope d-flex flex-column" },
    [
      _c(
        VTabs,
        {
          staticClass: "step-tabs attribute-editor-step-tab",
          attrs: { value: _vm.tabs.name, "hide-slider": true },
        },
        _vm._l(_vm.tabs, function (tab, index) {
          return _c(VTab, { key: index, staticClass: "step-tab" }, [
            _vm._v("\n      " + _vm._s(tab.name) + "\n    "),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }