<template>
  <component
    :is="component"
    v-bind="$attrs"
    event
    :v-on="$listeners"
    :to="to"
    @click.native.prevent="wrappedClick"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    component: {
      type: String,
      required: false,
      default: 'router-link',
    },
    onClick: {
      type: Function,
      required: false,
      default: () => {},
    },
    to: {
      type: Object,
      required: true,
    },
    rightClickOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    async wrappedClick() {
      // Await function if async
      await this.onClick();
      if (!this.rightClickOnly) {
        // Change route after having been run
        this.$router.push(this.to);
      }
    },
  },
};
</script>
