<template>
  <div class="d-flex flex-column align-start cell-container p-0">
    <div v-if="!params.isCopyingConstraints" class="applied-to-description">{{ params.value }}</div>
    <v-autocomplete
      v-else
      ref="autocompleteSelect"
      v-model="result"
      data-id-e2e="copyToSelection"
      class="applied-to-description"
      :items="spacebreaksWithGlobal"
      :search-input.sync="searchInput"
      :disabled="!params.data.canConstraintBeCopied"
      :allow-overflow="false"
      :placeholder="$tkey('actions.copyTo')"
      item-text="name"
      background-color="white"
      dense
      width="240px"
      height="25px"
      light
      hide-selected
      return-object
      @change="updateAppliedTo"
      @click:append="menuArrow"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapGetters, mapMutations } from 'vuex';

export default Vue.extend({
  name: 'cellAppliedToRenderer',
  data() {
    return {
      valid: true,
      showMenu: false,
      searchInput: '',
      isMapFieldsModalOpen: false,
      uploadMethod: null,
      result: null,
    };
  },

  computed: {
    ...mapState('assortmentCanvas', ['spacebreaks']),
    ...mapGetters('assortmentCanvas', ['isConstraintSelectedToCopy']),

    spacebreaksWithGlobal() {
      return [
        {
          _id: null,
          name: this.$tkey('assortmentCanvasPage.spacebreakSettings.constraints.globalConstraints'),
        },
        ...this.spacebreaks,
      ];
    },
  },

  beforeMount() {
    this.result = this.params.value;
  },

  methods: {
    ...mapMutations('assortmentCanvas', ['addSelectedConstraintToCopy']),

    menuArrow() {
      // allow arrow icon to close autocomplete selection menu
      const autocompleteSelect = this.$refs.autocompleteSelect;
      if (autocompleteSelect.isMenuActive) {
        this.$refs.autocompleteSelect.isMenuActive = false;
        autocompleteSelect.blur();
      } else {
        this.$refs.autocompleteSelect.isMenuActive = true;
        autocompleteSelect.focus();
      }
    },

    updateAppliedTo(update) {
      this.searchInput = '';
      if (this.params.isCopyingConstraints) {
        const { constraintId } = this.params.data;

        this.params.node.setDataValue('appliedTo', update);
        this.addSelectedConstraintToCopy({
          spacebreakId: update._id,
          constraintId,
        });
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.cell-container {
  width: 100%;
}

.applied-to-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
</style>
