'use strict';

const DataTypes = require('../../enums/data-types');

const fixedFields = [
  {
    mongoField: 'productKeyDisplay',
    type: DataTypes.alphanumeric,
    // The comparison function uses Lodash _.lowerCase which converts string, as space separated words, to lower case.
    // 'Product Key' === 'product key'  |  'productKey' === 'product key'  |  '--Product-Key--' === 'product key'
    aliases: 'csvAliases.scenarioProducts.productKeyDisplay',
    translationKey: 'mongoFields.productKeyDisplay',
    required: true,
  },
  {
    mongoField: 'itemDescription',
    type: DataTypes.str,
    aliases: 'csvAliases.scenarioProducts.itemDescription',
    translationKey: 'mongoFields.itemDescription',
    required: false,
  },
  {
    mongoField: 'contentValue',
    type: DataTypes.number,
    aliases: 'csvAliases.scenarioProducts.contentValue',
    translationKey: 'mongoFields.contentValue',
    required: false,
  },
  {
    mongoField: 'contentUnitOfMeasure',
    type: DataTypes.str,
    aliases: 'csvAliases.scenarioProducts.contentUnitOfMeasure',
    translationKey: 'mongoFields.contentUnitOfMeasure',
    required: false,
  },
  {
    mongoField: 'futureId',
    type: DataTypes.number,
    aliases: 'csvAliases.scenarioProducts.futureId',
    translationKey: 'mongoFields.futureId',
    required: false,
  },
  {
    mongoField: 'margin',
    type: DataTypes.number,
    aliases: 'csvAliases.scenarioProducts.margin',
    translationKey: 'mongoFields.margin',
    required: false,
  },
  {
    mongoField: 'price',
    type: DataTypes.number,
    aliases: 'csvAliases.scenarioProducts.price',
    translationKey: 'mongoFields.price',
    required: false,
  },
  {
    mongoField: 'unitWidth',
    type: DataTypes.number,
    aliases: 'csvAliases.scenarioProducts.unitWidth',
    translationKey: 'mongoFields.unitWidth',
    required: false,
  },
  {
    mongoField: 'unitLength',
    type: DataTypes.number,
    aliases: 'csvAliases.scenarioProducts.unitLength',
    translationKey: 'mongoFields.unitLength',
    required: false,
  },
  {
    mongoField: 'unitHeight',
    type: DataTypes.number,
    aliases: 'csvAliases.scenarioProducts.unitHeight',
    translationKey: 'mongoFields.unitHeight',
    required: false,
  },
  {
    mongoField: 'assortment',
    type: DataTypes.boolean,
    aliases: 'csvAliases.scenarioProducts.assortment',
    translationKey: 'mongoFields.forAssortment',
    required: false,
  },
  {
    mongoField: 'parentProduct',
    type: DataTypes.str,
    aliases: 'csvAliases.scenarioProducts.parentProduct',
    translationKey: 'mongoFields.parentProduct',
    required: false,
  },
];

const optionalFields = [
  {
    mongoField: 'furnitureArchetypeProductExceptionalEffectiveDate',
    type: DataTypes.str,
    aliases: 'csvAliases.scenarioProducts.furnitureArchetypeProductExceptionalEffectiveDate',
    translationKey: 'mongoFields.furnitureArchetypeProductExceptionalEffectiveDate',
    required: false,
  },
  {
    mongoField: 'furnitureArchetypeProductExceptionalExpiryDate',
    type: DataTypes.str,
    aliases: 'csvAliases.scenarioProducts.furnitureArchetypeProductExceptionalExpiryDate',
    translationKey: 'mongoFields.furnitureArchetypeProductExceptionalExpiryDate',
    required: false,
  },
  {
    mongoField: 'minimumFacings',
    type: DataTypes.number,
    aliases: 'csvAliases.scenarioProducts.minimumFacings',
    translationKey: 'mongoFields.minimumFacings',
    required: false,
  },
  {
    mongoField: 'minimumDistribution',
    type: DataTypes.number,
    aliases: 'csvAliases.scenarioProducts.minimumDistribution',
    translationKey: 'mongoFields.minimumDistribution',
    required: false,
  },
  {
    mongoField: 'seasonalWeekNumbers',
    type: DataTypes.number,
    aliases: 'csvAliases.scenarioProducts.seasonalWeekNumbers',
    translationKey: 'mongoFields.seasonalWeekNumbers',
    required: false,
  },
  {
    mongoField: 'userEnteredSisterProduct',
    type: DataTypes.str,
    aliases: 'csvAliases.scenarioProducts.userEnteredSisterProduct',
    translationKey: 'mongoFields.userEnteredSisterProduct',
    required: false,
  },
  {
    mongoField: 'seasonalProduct',
    type: DataTypes.boolean,
    aliases: 'csvAliases.scenarioProducts.seasonalProduct',
    translationKey: 'mongoFields.seasonalProduct',
    required: false,
  },
  {
    mongoField: 'pgMustFlag',
    type: DataTypes.str,
    aliases: 'csvAliases.scenarioProducts.pgMustFlag',
    translationKey: 'mongoFields.pgMustFlag',
    required: false,
  },
  {
    mongoField: 'maximumDistribution',
    type: DataTypes.number,
    aliases: 'csvAliases.scenarioProducts.maximumDistribution',
    translationKey: 'mongoFields.maximumDistribution',
    required: false,
  },
  {
    mongoField: 'localProduct',
    type: DataTypes.boolean,
    aliases: 'csvAliases.scenarioProducts.localProduct',
    translationKey: 'mongoFields.localProduct',
    required: false,
  },
  {
    mongoField: 'palletContent',
    type: DataTypes.str,
    aliases: 'csvAliases.scenarioProducts.palletContent',
    translationKey: 'mongoFields.palletContent',
    required: false,
  },
  {
    mongoField: 'averageTransactionWeight',
    type: DataTypes.number,
    aliases: 'csvAliases.scenarioProducts.averageTransactionWeight',
    translationKey: 'mongoFields.averageTransactionWeight',
    required: false,
  },
  {
    mongoField: 'maintainOption',
    type: DataTypes.str,
    aliases: 'csvAliases.scenarioProducts.maintainOption',
    translationKey: 'mongoFields.maintainOption',
    required: false,
  },
  {
    mongoField: 'kpku',
    type: DataTypes.str,
    aliases: 'csvAliases.scenarioProducts.kpku',
    translationKey: 'mongoFields.kpku',
    required: false,
  },
  {
    mongoField: 'productSeries',
    type: DataTypes.str,
    aliases: 'csvAliases.scenarioProducts.productSeries',
    translationKey: 'mongoFields.productSeries',
    required: false,
  },
  {
    mongoField: 'periodID',
    type: DataTypes.str,
    aliases: 'csvAliases.scenarioProducts.periodID',
    translationKey: 'mongoFields.periodID',
    required: false,
  },
];

module.exports = {
  fixedFields,
  optionalFields,
};
