import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cluster-storeclass-checkpoint-selector h-100" },
    [
      _c(VDataTable, {
        staticClass: "checkpoints-table h-100",
        attrs: {
          headers: _vm.headers,
          items: _vm.canvasesByClusterStoreClass,
          dense: "",
          "disable-sort": "",
          "disable-pagination": "",
          "hide-default-footer": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item.status",
            fn: function ({ item }) {
              return [
                _c(VIcon, { attrs: { size: "18" } }, [
                  _vm._v(_vm._s(`$${item.status}`)),
                ]),
              ]
            },
          },
          {
            key: "item.checkpoint",
            fn: function ({ item }) {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("rtls-select", {
                      attrs: {
                        value: item.checkpoint,
                        white: "",
                        width: "280px",
                        "hide-messages": "",
                        "item-value": "_id",
                        "menu-props": { "z-index": 200 },
                        items: _vm.getCheckpoints(item),
                        label: _vm.$t(
                          "checkpointSelectionTable.placeholders.choose"
                        ),
                      },
                      on: {
                        change: (checkpointId) =>
                          _vm.onSelectionChange(checkpointId, item._id),
                      },
                    }),
                    _vm._v(" "),
                    _c("error-triangle", {
                      staticClass: "ml-1 mr-1",
                      attrs: { errors: _vm.errors },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }