import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: { value: _vm.value, width: "400px" },
      on: { "click:outside": _vm.closeModal },
    },
    [
      _vm.value
        ? _c(
            "dialog-card",
            {
              attrs: { title: _vm.$tkey("createNewClusterTitle") },
              on: { close: _vm.closeModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("page-actions", {
                          attrs: {
                            "is-custom-export": "",
                            "export-button-title": _vm.getCsvExportTitle,
                            "has-data-errors": _vm.hasDataErrors,
                            "has-data-changes": true,
                            "show-discard": false,
                            "show-export": true,
                          },
                          on: {
                            save: _vm.onSave,
                            export: function ($event) {
                              return _vm.exportCSV()
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "right-btns",
                                fn: function () {
                                  return [
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "text-outline",
                                        attrs: { text: "", link: "" },
                                        on: { click: _vm.closeModal },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.$t("actions.cancel")) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            808452761
                          ),
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3665888440
              ),
            },
            [
              _c(
                VContainer,
                {
                  staticStyle: { "max-height": "500px", padding: "0" },
                  attrs: { fluid: "" },
                },
                [
                  _c(
                    "div",
                    {
                      attrs: {
                        "data-id-e2e": "ref-clustering-scheme-creation-wrapper",
                      },
                    },
                    [
                      _c(
                        VRow,
                        { staticClass: "my-2" },
                        [
                          _c(
                            VContainer,
                            { staticClass: "mx-0 px-0", attrs: { fluid: "" } },
                            [
                              _c(
                                VRow,
                                { staticClass: "my-2" },
                                [
                                  _c(VCheckbox, {
                                    staticClass: "rtls-checkbox mx-2",
                                    attrs: {
                                      "input-value":
                                        _vm.isNewClusterSchemeNameSelected,
                                    },
                                    on: {
                                      change:
                                        _vm.onToggleNewClusterSchemeNameSelected,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "mr-4" }, [
                                    _vm._v(
                                      _vm._s(_vm.$tkey("clusterSchemeName"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.isNewClusterSchemeNameSelected
                                    ? _c("rtls-text-field", {
                                        staticClass: "mr-2 ml-4 my-2",
                                        staticStyle: { width: "80%" },
                                        attrs: { grey: "" },
                                        model: {
                                          value: _vm.newClusterSchemeName,
                                          callback: function ($$v) {
                                            _vm.newClusterSchemeName = $$v
                                          },
                                          expression: "newClusterSchemeName",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.nameHasError
                                    ? _c("span", { staticClass: "my-4" }, [
                                        _c(
                                          "i",
                                          {
                                            staticClass:
                                              "material-icons-round error-triangle-icon",
                                          },
                                          [_vm._v("warning_amber")]
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                VRow,
                                { staticClass: "my-2" },
                                [
                                  _c(VCheckbox, {
                                    staticClass: "rtls-checkbox mx-2",
                                    attrs: {
                                      "input-value": _vm.isManualUploadSelected,
                                    },
                                    on: {
                                      change: _vm.onToggleManualUploadSelected,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "mr-4" }, [
                                    _vm._v(_vm._s(_vm.$tkey("directImport"))),
                                  ]),
                                  _vm._v(" "),
                                  _vm.isManualUploadSelected
                                    ? _c("data-upload", {
                                        attrs: {
                                          legends: _vm.csvUploadLegends,
                                          "csv-upload-handler": _vm.onCSVUpload,
                                        },
                                        on: { process: _vm.process },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.isManualUploadSelected
                                ? _c(
                                    VRow,
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(/* need data-table for loading state */) +
                                          "\n              "
                                      ),
                                      _vm.hasCSVUploadedData
                                        ? _c(VDataTable, {
                                            staticClass: "my-2",
                                            attrs: {
                                              id: "upload-ref-schemes-table",
                                              loading: _vm.loading,
                                              headers: _vm.tableHeaders,
                                              "max-height": "500px",
                                              dense: "",
                                              "disable-pagination": "",
                                              "disable-sort": "",
                                              "hide-default-footer": "",
                                              "hide-default-header": "",
                                              "fixed-header": "",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "header",
                                                  fn: function () {
                                                    return [
                                                      _c("thead", [
                                                        _c(
                                                          "tr",
                                                          _vm._l(
                                                            _vm.tableHeaders,
                                                            function (header) {
                                                              return _c(
                                                                "th",
                                                                {
                                                                  key: `upload-${header.id}`,
                                                                  class:
                                                                    header.headerClass,
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        header.text
                                                                      ) +
                                                                      "\n                      "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "body",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "tbody",
                                                        _vm._l(
                                                          _vm.getTableRows,
                                                          function (
                                                            clusteringScheme,
                                                            rowIndex
                                                          ) {
                                                            return _c(
                                                              "tr",
                                                              { key: rowIndex },
                                                              _vm._l(
                                                                _vm.tableHeaders,
                                                                function (
                                                                  header
                                                                ) {
                                                                  return _c(
                                                                    "td",
                                                                    {
                                                                      key: `upload-${header.id}-row-${rowIndex}`,
                                                                      class:
                                                                        header.cellClass,
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            clusteringScheme[
                                                                              header
                                                                                .value
                                                                            ]
                                                                          ) +
                                                                          "\n                      "
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              2054819191
                                            ),
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(VDivider, { attrs: { horizontal: "" } }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }