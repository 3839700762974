<template>
  <imask-input
    v-if="dateFormat"
    ref="input"
    v-model="value"
    :mask="dateFormat"
    :blocks="blocks"
    :lazy="false"
    :placeholder="dateFormat"
    :unmask="false"
    class="flex-1 m-0 p-0 pr-1 w-100"
  />
</template>

<script>
import dateUtils from '@/js/mixins/date-utils';
import { mapGetters } from 'vuex';
import { IMaskComponent, IMask } from 'vue-imask';
import { get } from 'lodash';

export default {
  components: { 'imask-input': IMaskComponent },
  mixins: [dateUtils],
  data() {
    return {
      value: null,
      dateFormat: '',
      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1000,
          to: 9999,
          placeholderChar: 'y',
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          placeholderChar: 'm',
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
          placeholderChar: 'd',
        },
      },
      originalValue: null,
      showTooltip: true,
      date: null,
    };
  },
  computed: {
    ...mapGetters('context', ['getExcelDateFormat']),
  },
  mounted() {
    this.setInitalValue();
    // Focus the input
    this.$nextTick(() => {
      const input = get(this.$refs, 'input.$el');
      if (input) {
        input.focus();
      }
    });
  },
  methods: {
    setInitalValue() {
      const { value } = this.params;
      // Get the correct date format
      // Use slashes instead of dashes to work with excel
      this.dateFormat = this.getExcelDateFormat;
      // Evaluate if its an acceptable string
      if (this.isDateValid({ dateValue: value, dateFormat: this.dateFormat })) {
        // Set the value
        this.value = value;
        this.originalValue = value;
      }
    },
    getValue() {
      // If the date is valid we set it. otherwise return the og value
      if (this.isDateValid({ dateValue: this.value, dateFormat: this.dateFormat })) {
        return this.value;
      }
      return this.originalValue;
    },
  },
};
</script>
