<template>
  <ul class="flex-list pl-0">
    <li>
      <v-btn v-if="showNotImplemented" class="action-button" disabled icon text>
        <v-icon>$favourite-star</v-icon>
      </v-btn>
    </li>
    <li>
      <v-btn v-if="showNotImplemented" class="action-button" disabled icon text>
        <v-icon>$notes</v-icon>
      </v-btn>
    </li>
    <li>
      <v-progress-circular v-if="hasProgress" :value="progressPercent" size="25" width="3">
        {{ progressPercent }}
      </v-progress-circular>
      <v-tooltip v-else bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="action-button"
            icon
            text
            :loading="copyInProgress"
            :disabled="isCopyScenarioRunning || !hasPermission(userPermissions.canCopyScenario)"
            v-bind="attrs"
            v-on="on"
            @click="onCopyScenarioClick"
          >
            <v-icon style="font-size: 19px">mdi-content-copy</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('actions.copy') }}</span>
      </v-tooltip>
    </li>
    <scenario-option-delete :scenario="scenario" />
  </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { includes, get } from 'lodash';
import { jobInProgressStatuses } from '@enums/jobapi';

export default {
  props: {
    scenario: {
      required: true,
      type: Object,
    },
  },

  computed: {
    ...mapGetters('context', ['showNotImplemented']),
    ...mapGetters('scenarios', ['isCopyScenarioRunning']),

    copyScenarioJobStatus() {
      return get(this.scenario, 'jobs.copyScenario.status');
    },

    copyInProgress() {
      return includes(jobInProgressStatuses, this.copyScenarioJobStatus);
    },

    progressPercent() {
      return get(this.scenario, 'jobs.copyScenario.progress');
    },

    hasProgress() {
      return this.copyInProgress && this.progressPercent > 0 && this.progressPercent < 100;
    },
  },

  methods: {
    ...mapActions('scenarios', ['copyScenario', 'setSelectedScenario']),
    ...mapActions('snackbar', ['showSuccess', 'showError']),

    async onCopyScenarioClick() {
      this.setSelectedScenario(this.scenario);
      this.showSuccess(
        this.$t('notifications.copyScenario.starting', { name: this.scenario.name })
      );
      await this.copyScenario(this.scenario);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.action-button {
  .v-icon {
    color: $assortment-primary-colour;
  }
}
</style>
