<template>
  <div class="width-100 h-100">
    <report-input-item :title="title" data-id-e2e="checkpointsSelectionTable">
      <div v-if="clusteredToggle" class="d-flex align-left justify-left checkbox-container mb-1">
        <div class="checkbox-label">
          {{ $tkey('useUnclustered') }}
        </div>
        <v-checkbox
          class="rtls-checkbox mx-2"
          :input-value="useUnclustered"
          @change="useUnclustered = $event"
        />
      </div>

      <!-- Main table with canvases  -->
      <v-data-table
        class="striped-table simple-assortments-table simple-assortments-table--extracts"
        :headers="headers"
        :items="filteredCanvases"
        max-height="maxHeight"
        dense
        fixed-header
        disable-sort
        disable-pagination
        hide-default-footer
      >
        <template v-slot:item.status="{ item }">
          <v-icon size="18">{{ `$${item.status}` }}</v-icon>
        </template>

        <!-- Checkpoint selection -->
        <template v-slot:item.checkpoint="{ item }">
          <div class="d-flex">
            <v-autocomplete
              ref="autocomplete"
              v-model="selectedCheckpoints[item._id]"
              data-id-e2e="checkpointsSelectionAutocomplete"
              :items="filteredCheckpointsByCanvasId[item._id]"
              :filter="filterCheckpoints"
              :placeholder="$tkey('placeholders.choose')"
              :menu-props="{ auto: true }"
              :search-input.sync="searchText[item._id]"
              :rules="rules"
              background-color="white"
              width="240px"
              height="25px"
              hide-messages
              @change="updateSelectedCheckpoints()"
            />
            <error-triangle
              class="ml-2 mr-2"
              :style="{ visibility: isCheckpointError ? 'visible' : 'hidden' }"
              :errors="{
                [$tkey('checkpointErrorMsg')]: !isCheckpointError,
              }"
            />
            <!-- View icon -->
            <v-icon
              v-if="canViewCheckpointData"
              color="primary"
              class="material-icons icon mr-3"
              :disabled="!selectedCheckpoints[item._id]"
              @click="viewCheckpointData(item._id)"
              >visibility</v-icon
            >
          </div>
        </template>
      </v-data-table>
    </report-input-item>
  </div>
</template>

<script>
import { filter, get, isEmpty, map } from 'lodash';

export default {
  name: 'CheckpointsSelectionTable',
  localizationKey: 'checkpointSelectionTable',
  props: {
    scenarioId: {
      type: String,
      required: false,
      default: () => null,
    },
    headers: {
      type: Array,
      required: true,
    },
    canvases: {
      type: Array,
      required: true,
    },
    checkpointsByCanvasId: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    maxHeight: {
      type: String,
      required: false,
      default: () => '500px',
    },
    shouldValidateCheckpoints: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    canViewCheckpointData: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    clusteredToggle: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  data() {
    return {
      selectedCheckpoints: {},
      searchText: {},
      rules: this.shouldValidateCheckpoints ? [value => this.validateCheckpoints(value)] : [],
      isCheckpointError: false,
      useUnclustered: false,
    };
  },
  computed: {
    isSelectCheckpointDisabled() {
      return !this.hasPermission(this.userPermissions.canEditProductStoreExtracts);
    },
    filteredCanvases() {
      return this.clusteredToggle
        ? filter(
            this.canvases,
            c => (this.useUnclustered && !c.clusterName) || (!this.useUnclustered && c.clusterName)
          )
        : this.canvases;
    },
    filteredCheckpointsByCanvasId() {
      const filteredCanvasIds = map(this.filteredCanvases, c => c._id);
      const checkpointsByCanvasIdKeys = Object.keys(this.checkpointsByCanvasId);
      const filteredKeys = filter(checkpointsByCanvasIdKeys, k => filteredCanvasIds.includes(k));
      const result = {};

      filteredKeys.forEach(key => {
        result[key] = this.checkpointsByCanvasId[key];
      });
      return result;
    },
  },

  watch: {
    canvases() {
      this.resetData();
    },
    useUnclustered() {
      this.resetData();
    },
  },

  async created() {
    await this.resetData();
  },

  methods: {
    filterCheckpoints(item, queryText) {
      if (isEmpty(queryText)) return true;

      const itemName = get(item, 'text', '');
      const query = queryText.toLocaleLowerCase();
      return itemName.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) > -1;
    },

    validateCheckpoints(value) {
      this.isCheckpointError = Object.values(this.selectedCheckpoints).every(v => !v);
      return !!value;
    },

    updateSelectedCheckpoints() {
      this.$emit('update-selected-checkpoints', this.selectedCheckpoints);
    },

    viewCheckpointData(canvasId) {
      const checkpointId = this.selectedCheckpoints[canvasId];
      this.$emit('view-checkpoint-data', { checkpointId, name: this.searchText[canvasId] });
    },

    async resetData() {
      this.searchText = {};
      if (this.shouldValidateCheckpoints) this.validateCheckpoints();

      // Pre-fill initial selected checkpoints map with ids
      this.selectedCheckpoints = {};
      Object.keys(this.filteredCheckpointsByCanvasId).forEach(k => {
        this.selectedCheckpoints[k] = null;
      });

      this.updateSelectedCheckpoints();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .v-input__control {
    .v-select__slot {
      margin-left: 5px;
    }
    // hide messages box on autocomplete
    .v-text-field__details {
      display: none;
    }
  }

  .v-data-table__wrapper {
    overflow-y: hidden;
  }

  .checkbox-container {
    border-radius: 4px;
    padding: 2px 1px 2px 10px;
  }

  .checkbox-label {
    font-size: 1.3rem;
    color: $assortment-font-colour;
    padding-left: 10px;
  }
}
</style>
