import { get } from 'lodash';
import { mapGetters } from 'vuex';
import userPermissions from '@enums/user-permissions';

/**
 * Global mixin, so that we do not need to import the enums & getter in every component that needs them
 */
const mixin = {
  data() {
    return {
      userPermissions: Object.freeze(userPermissions),
    };
  },

  computed: {
    ...mapGetters('context', ['hasPermission', 'getClientConfig']),

    bundlesEnabled() {
      return get(this.getClientConfig, 'features.bundlesEnabled', false);
    },

    templatesEnabled() {
      return get(this.getClientConfig, 'features.templatesEnabled', false);
    },

    hasSeasonalityEnabled() {
      return get(this.getClientConfig, 'features.seasonalityEnabled', false);
    },

    hasClusteringFromDataEnabled() {
      return get(this.getClientConfig, 'features.clusteringFromDataEnabled', false);
    },

    hasSpacebreakFromDataEnabled() {
      return get(this.getClientConfig, 'features.spacebreaksFromDataEnabled', false);
    },

    hasRegionsByAttributeEnabled() {
      return get(this.getClientConfig, 'features.regionsByAttributeEnabled', false);
    },

    hasProductsExistingFromNewInTemplateEnabled() {
      return get(this.clientConfig, 'features.productsExistingFromNewInTemplateEnabled', false);
    },

    hasProductLineEnabled() {
      return get(this.clientConfig, 'features.scenario.products.isProductLineEnabled', false);
    },
  },
};

export default mixin;
