<template>
  <div class="d-flex flex-grow-1 cdt-page">
    <cdt-tree-view
      :value="showTreeViewModal"
      :selected-cann-group="selectedCannGroup || {}"
      :cdt-ref="treeViewCdt"
      @close="closeCdtTreeViewModal"
    />

    <v-card class="step-tab-panel" flat>
      <v-container class="pa-0 ma-0 cdt-container d-flex flex-column">
        <scenario-measuring-header
          :header-text="$t('customerDecisionTree.title')"
          :columns="4"
          tooltip-link="toolguide/090-cdt.html"
        />
        <div no-gutters class="no-gutters cdt-panel d-flex flex-grow-1">
          <!-- Cann group menu -->
          <v-col :cols="2">
            <cann-group-menu
              :mark-cann-group-with-selected-cdt="true"
              :preselect-cann-group="true"
              :selected-cann-group="dirtySelectedCannGroup"
              @onCannGroupSelected="selectCannGroup"
            >
              <template v-slot:tickbox>
                <v-icon color="#d3d3d3" class="float-right">mdi-checkbox-blank-circle</v-icon>
              </template>
            </cann-group-menu>
          </v-col>

          <!-- Main panel -->
          <v-col class="d-flex flex-column" :cols="10">
            <progress-bar
              v-if="isCdtGenerationJobRunning"
              :message="$t('messages.inProgress')"
              :percentage="progressBarInfo.progress"
              :status="progressBarInfo.status"
              class="progress-bar"
            />

            <div
              v-if="hasCtdGenerated && !isCdtGenerationJobRunning"
              class="d-flex flex-column flex-cdt-container"
            >
              <div class="d-flex flex-grow-1 inner-flex-container">
                <!-- Tool generated -->
                <div class="d-flex">
                  <div
                    v-for="toolGeneratedCdt in toolGeneratedCdts"
                    :key="toolGeneratedCdt._id"
                    class="d-flex"
                  >
                    <cdt-attributes-list
                      :disabled="isEditingDisabled"
                      :selected-cann-group="selectedCannGroup"
                      :cdt="toolGeneratedCdt"
                      @generate="startCdtGeneration"
                      @open-tree-view-modal="openTreeViewModal"
                      @data-changed="hasTooldataChanges = $event"
                    />
                  </div>
                </div>

                <!-- User modified -->
                <div class="d-flex flex-cdt-container">
                  <div
                    v-for="userModifiedCdt in userModifiedCdts"
                    :key="userModifiedCdt._id"
                    class="d-flex"
                  >
                    <cdt-attributes-list
                      :disabled="isEditingDisabled"
                      :selected-cann-group="selectedCannGroup"
                      :cdt="userModifiedCdt"
                      @generate="startCdtGeneration"
                      @open-tree-view-modal="openTreeViewModal"
                      @data-changed="hasUserChanges = $event"
                    />
                  </div>
                </div>

                <!-- Create modified CDT -->
                <v-col
                  class="attributes-column pa-0 d-flex flex-column justify-space-between"
                  style="vertical-align: top"
                >
                  <v-container class="ma-0 pa-0 controls flex-grow-0">
                    <v-row
                      no-gutters
                      class="d-flex justify-start align-center heading-row pt-4 pb-3"
                    >
                      <h4>
                        <v-btn
                          text
                          :disabled="disableCreateModifiedCdt"
                          class="addModifiedCdt"
                          @click="createModifiedCDT()"
                        >
                          <v-icon class="material-icons icon mr-1 ml-2">add_box</v-icon>
                          {{ $tkey('cdtList.createModifiedCdt') }}
                        </v-btn>
                      </h4>
                    </v-row>
                    <v-divider />
                  </v-container>
                </v-col>
              </div>
            </div>

            <!-- Initial generation selection -->
            <cdt-attribute-selection
              v-if="!isCdtGenerationJobRunning && !hasCtdGenerated && selectedCannGroup"
              :disabled="isEditingDisabled"
              :selected-cann-group="selectedCannGroup"
              @generate="startCdtGeneration"
              @data-changed="hasAttributeSelectionChanges = $event"
            />

            <!-- Page actions -->
            <div v-if="!isCdtGenerationJobRunning" class="page-actions-container">
              <page-actions
                :show-export="showNotImplemented"
                :show-discard="false"
                :show-save-button="showNotImplemented"
                :save-disabled="isEditingDisabled"
                :is-discard-enabled="!isEditingDisabled"
                :has-data-changes="false"
                :has-data-errors="hasDataErrors"
                save-btn-text="saveCompleteCdt"
              >
                <template v-slot:right-btns>
                  <div class="generate-multiple-btn-container">
                    <select-multiple-cann-groups-modal
                      :selected-cann-group="selectedCannGroup || {}"
                      :title="$tkey('dialog.title')"
                      :subtitle="$tkey('dialog.title')"
                      @confirm="startCdtGeneration"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          :disabled="isEditingDisabled"
                          data-id-e2e="btnGenerateCDTMultiple"
                          depressed
                          primary
                          v-on="on"
                        >
                          {{ $tkey('generateMultiple') }}
                        </v-btn>
                      </template>
                    </select-multiple-cann-groups-modal>
                  </div>
                </template>
              </page-actions>
            </div>
          </v-col>
        </div>
      </v-container>

      <!-- confirmation dialog -->
      <main-dialog ref="confirm">
        <template v-slot:header>
          <v-card-text class="display-1 pa-0 text-center">
            <p>{{ $tkey('confirmation.question') }}</p>
            <strong>{{ $tkey('confirmation.warning') }}</strong>
          </v-card-text>
        </template>
        <template v-slot:content="{ cancel, confirm }">
          <v-card-actions class="justify-center flex-column">
            <v-btn data-id-e2e="btnOKGenerateCDT" primary class="ma-2" @click="confirm">
              {{ $t('actions.ok') }}
            </v-btn>
            <v-btn text depressed class="cancel ma-2" @click="cancel">
              {{ $t('actions.cancel') }}
            </v-btn>
          </v-card-actions>
        </template>
      </main-dialog>
    </v-card>

    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="onUnsavedDataModalCancel"
      @confirm="closeUnsavedDataModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import {
  map,
  get,
  compact,
  find,
  keyBy,
  cloneDeep,
  difference,
  property,
  has,
  isEqual,
  isUndefined,
  pick,
  some,
} from 'lodash';
import { toolGenerated, userModified } from '@enums/scenario-cdt-types';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import cannGroupUtils from '@/js/utils/cann-groups';

export default {
  localizationKey: 'customerDecisionTree',
  mixins: [unsavedDataWarningMixin],

  data() {
    return {
      showTreeViewModal: false,
      selectedCannGroup: null,
      // used on cann-group-modal component to update the internal state of the currently selected tab
      // usually matches the `selectedCannGroup.key` object, but in case user has unsaved data and decided to stay
      // this value is reset back to current tab
      dirtySelectedCannGroup: null,
      customAttributes: null,
      treeViewCdt: {},
      hasTooldataChanges: false,
      hasUserChanges: false,
      hasAttributeSelectionChanges: false,
    };
  },

  computed: {
    ...mapState('scenarioCdts', ['scenarioCdts', 'cdtSelectedAttributes']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapGetters('clustering', ['getScenarioAttributes']),
    ...mapGetters('context', ['showNotImplemented']),
    ...mapGetters('scenarios', ['jobStatuses', 'isJobRunning']),
    ...mapGetters('scenarioCdts', ['getCdtSelectedAttributesForCannGroup']),

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditCDTPage);
    },

    hasDataChanges() {
      // refs won't work here since the components we want to check hasDataChanges for are inside a v-if
      // emit the value each time it changes and track here instead.
      return some([
        this.hasUnsavedCdts,
        this.hasTooldataChanges,
        this.hasUserChanges,
        this.hasAttributeSelectionChanges,
      ]);
    },

    hasDataErrors() {
      // required for page-actions component
      return false;
    },

    hasUnsavedCdts() {
      // unsaved cdts have no _id
      return this.scenarioCdts.some(v => isUndefined(v._id));
    },

    disableCreateModifiedCdt() {
      if (this.isEditingDisabled) return true;

      // user cannot create a second modified CDT (mCDT) if they are currently working on a non-saved version of the mCDT.
      const hasNonSavedMCDTInfo =
        this.userModifiedCdts.filter(cdt => !has(cdt, '_id') && cdt.type === userModified).length >
        0;
      return hasNonSavedMCDTInfo || this.hasToolGeneratedChanges;
    },

    hasCtdGenerated() {
      if (!this.selectedCannGroup) {
        return false;
      }
      const matchingCdts = this.scenarioCdts.filter(
        ctd => ctd.cannGroupId === this.selectedCannGroup.key
      );
      const hasGeneratedCdts = matchingCdts.filter(el => el.cdt && el.cdt.length > 0).length > 0;
      return hasGeneratedCdts;
    },

    isCdtGenerationJobRunning() {
      return this.isJobRunning('cdtGeneration', get(this.selectedCannGroup, 'key'));
    },

    toolGeneratedCdts() {
      if (!this.scenarioCdts || !this.selectedScenario || !this.selectedCannGroup) {
        return [];
      }
      return this.scenarioCdts.filter(
        s =>
          s.scenarioId === this.selectedScenario._id &&
          s.cannGroupId === this.selectedCannGroup.key &&
          s.type === toolGenerated
      );
    },

    userModifiedCdts() {
      if (!this.scenarioCdts || !this.selectedScenario || !this.selectedCannGroup) {
        return [];
      }
      return this.scenarioCdts.filter(
        s =>
          s.scenarioId === this.selectedScenario._id &&
          s.cannGroupId === this.selectedCannGroup.key &&
          s.type === userModified
      );
    },

    scenarioAttributes() {
      return this.getScenarioAttributes ? cloneDeep(this.getScenarioAttributes) : [];
    },

    hasToolGeneratedChanges() {
      return this.toolGeneratedCdts.some(cdt => {
        // if the CDT is already generated, it will contain extra properties from the FE
        const sanitisedAttributes = cdt.attributes.map(a =>
          pick(a, ['attributeId', 'rank', 'coefficient', 'standardError'])
        );
        return !isEqual(sanitisedAttributes, cdt.originalJobAttributesSelection);
      });
    },

    progressBarInfo() {
      const cannGroupKey = get(this.selectedCannGroup, 'key');
      if (!cannGroupKey) {
        return {};
      }
      return this.jobStatuses.cdtGeneration[cannGroupKey];
    },
  },

  watch: {
    async hasCtdGenerated(newValue, oldValue) {
      // skip if no change or if it has not been generated.
      if (newValue === oldValue || !newValue) return;
      await this.preloadInitialSelections();
    },
  },

  async created() {
    // if there is already running job
    await this.preloadInitialSelections();
    this.resetHasDataChanges();
  },

  methods: {
    ...mapActions('scenarioCdts', [
      'runCdtGenerationJob',
      'fetchScenarioCdts',
      'addCdt',
      'updateCdtById',
    ]),
    ...mapActions('scenarios', ['loadScenario', 'updateScenario']),
    ...mapMutations('scenarioCdts', [
      'setCdtSelectedAttributes',
      'addUserModifiedCdt',
      'setScenarioCdts',
    ]),

    resetHasDataChanges() {
      this.hasTooldataChanges = false;
      this.hasUserChanges = false;
      this.hasAttributeSelectionChanges = false;
    },

    openTreeViewModal(cdt) {
      this.treeViewCdt = cdt;
      this.showTreeViewModal = true;
    },

    async preloadInitialSelections() {
      await this.fetchScenarioCdts();
      if (!this.selectedScenario.cannGroups) return;
      const cannGroups = cannGroupUtils.getLeafNodes(this.selectedScenario.cannGroups);

      cannGroups.forEach(cg => {
        const { key } = cg;
        const scenarioAttributes = this.scenarioAttributes;

        const document = find(this.scenarioCdts, item => item.cannGroupId === key);
        // check if attribute selections for this cann group have previously been saved in the database
        if (document && document.attributes && scenarioAttributes.length) {
          const { attributes } = document;
          const attributesMap = keyBy(attributes, 'attributeId');
          // select the previously saved attributes as true
          const selections = scenarioAttributes.map(item => {
            item.selected = !!attributesMap[item.id];
            return item;
          });
          this.setCdtSelectedAttributes({ key, value: selections });
        } else if (scenarioAttributes.length) {
          this.setCdtSelectedAttributes({ key, value: null });
        }
      });
    },

    selectCannGroup(cg) {
      this.oldKey = this.dirtySelectedCannGroup;
      this.dirtySelectedCannGroup = cg.key;
      // page is re-constructing
      if (!this.$refs.unsavedDataModal) {
        this.selectedCannGroup = cg;
        return;
      }
      this.beforeNavWithUnsavedData(() => {
        this.selectedCannGroup = cg;
        this.onUnsavedDataModaConfirm();
      });
    },

    onUnsavedDataModalCancel() {
      this.closeUnsavedDataModal();
      this.dirtySelectedCannGroup = this.oldKey;
    },

    onUnsavedDataModaConfirm() {
      // reset any CDT data in page
      this.resetHasDataChanges();
      this.dirtySelectedCannGroup = this.selectedCannGroup.key;
      this.setScenarioCdts(this.scenarioCdts.filter(v => !isUndefined(v._id)));
    },

    async hasCdtRecordsForCannGroups(cannGroupIds) {
      if (!this.scenarioCdts.length) {
        await this.fetchScenarioCdts();
      }

      const results = compact(
        map(cannGroupIds, cg => {
          return this.scenarioCdts.filter(cdt => {
            return cdt.cannGroupId === cg;
          })[0];
        })
      );

      const cdts = results.filter(el => {
        return get(el, 'cdt.length', 0) > 0 ? el : null;
      });
      return cdts.length > 0;
    },

    async createMissingCdtDocuments(cannGroupIds) {
      const cannGroupsIdsWithoutCdt = difference(
        cannGroupIds,
        map(this.scenarioCdts, property('cannGroupId'))
      );
      const attributesList = map(this.scenarioAttributes, property('id'));

      return Promise.all(
        cannGroupsIdsWithoutCdt.map(cannGroupId => {
          const payload = {
            scenarioId: get(this.selectedScenario, '_id'),
            attributesList,
            cannGroupId,
          };

          return this.addCdt(payload);
        })
      );
    },

    async generateCdt(cannGroupIds) {
      await this.createMissingCdtDocuments(cannGroupIds);
      const payload = {
        cannGroupIds,
      };
      await this.runCdtGenerationJob(payload);
      await this.loadScenario(this.selectedScenario._id);
    },

    async startCdtGeneration(params) {
      const { cannGroupIds, updatePayload } = params;
      // if there are already cdt records generated for this cannGroup and scenarioId we show yes/no popup
      if (await this.hasCdtRecordsForCannGroups(cannGroupIds)) {
        if (await this.$refs.confirm.open()) {
          if (updatePayload) await this.updateCdtById(updatePayload);
          await this.generateCdt(cannGroupIds);
        }
      } else {
        // there is no cdt records generated yet for this cannGroup and scenarioId
        if (updatePayload) await this.updateCdtById(updatePayload);
        await this.generateCdt(cannGroupIds);
      }
    },

    createModifiedCDT() {
      // need to use cloneDeep here, because otherwise we'll be making changes to the original one,
      // since toolGeneratedCdt will have its reference otherwise.
      const toolGeneratedCdt = cloneDeep(this.toolGeneratedCdts[0]);
      toolGeneratedCdt.sourceId = toolGeneratedCdt._id;
      delete toolGeneratedCdt._id;
      const attributes = toolGeneratedCdt.attributes.filter(a => a.coefficient && a.rank);
      const newObj = {
        ...toolGeneratedCdt,
        ...{ attributes },
        ...{ type: userModified, selected: false },
      };
      this.addUserModifiedCdt(newObj);
    },

    closeCdtTreeViewModal() {
      this.showTreeViewModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.flex-cdt-container {
  height: 100%;

  .inner-flex-container {
    overflow: auto;
    height: $tab-panel-height;
  }
}

::v-deep {
  .dialog-card__header {
    padding: 0px 15px 0px 15px;
  }
  .dialog-card__footer {
    display: none !important;
  }
  .dialog-card__content {
    height: 100%;
    max-height: 100% !important;
    padding: 0px 20px 20px 20px;
  }
}

.cdt-container {
  height: 100%;
  max-width: none;
  position: relative;

  .header {
    border-bottom: 1px solid $assortment-divider-colour;
  }

  .heading-row {
    height: 48px;
  }

  .controls {
    max-width: none;
    min-width: 200px;
  }

  .page-actions-container {
    border-top: 1px solid $assortment-divider-colour;

    .generate-multiple-btn-container {
      display: inline-block;
      padding: 0 8px;
      border-right: 1px solid $assortment-horizontal-border-colour;
    }
  }

  .cdt-panel {
    height: $tab-panel-height;
  }

  .progress-bar {
    padding: 0;
    margin: 10% auto auto auto;
  }

  .icon {
    color: $assortment-action-icon-color;
  }

  .addModifiedCdt {
    color: $assortment-action-icon-color;
  }
}
</style>
