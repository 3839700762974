import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VAutocomplete,
    {
      staticClass: "autocomplete-search-input rtls-search rtls-search--white",
      attrs: {
        "return-object": "",
        "persistent-hint": "",
        "hide-no-data": "",
        "no-filter": "",
        loading: _vm.loading,
        items: _vm.filteredVisible,
        "search-input": _vm.search,
        "item-text": _vm.itemText,
        placeholder: _vm.placeholder,
        hint: _vm.hint,
        "hide-details": _vm.hint.length < 1,
        "menu-props": { "z-index": 300 },
      },
      on: {
        "update:searchInput": function ($event) {
          _vm.search = $event
        },
        "update:search-input": function ($event) {
          _vm.search = $event
        },
        change: _vm.onItemSelected,
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          return _vm.filterItems.apply(null, arguments)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "item",
          fn: function ({ item }) {
            return [
              _c(
                "div",
                { staticClass: "search-item d-flex" },
                [
                  _vm._l(_vm.getMaskedText(item), function (text, position) {
                    return [
                      position === "start" ? [_vm._v(_vm._s(text))] : _vm._e(),
                      _vm._v(" "),
                      position === "middle"
                        ? _c(
                            "span",
                            { staticClass: "search-item--highlighted" },
                            [_vm._v(_vm._s(text))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      position === "end" ? [_vm._v(_vm._s(text))] : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.selectedItem,
        callback: function ($$v) {
          _vm.selectedItem = $$v
        },
        expression: "selectedItem",
      },
    },
    [
      _vm.maximumProductsShown
        ? _c(
            VListItem,
            { attrs: { slot: "prepend-item" }, slot: "prepend-item" },
            [
              _c(
                VListItemTitle,
                { staticClass: "maximum-items-note mb-3 mt-3" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$tkey("maximumProductsShown", [
                        _vm.productResultsLimit,
                      ])
                    )
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.maximumProductsShown ? _c(VDivider) : _vm._e(),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        VIcon,
        {
          attrs: { slot: "append", small: "", color: "primary" },
          on: {
            click: function ($event) {
              _vm.searchOnDemand && _vm.filterItems()
            },
          },
          slot: "append",
        },
        [_vm._v("$search")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }