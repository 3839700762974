<template>
  <div data-id-e2e="activeWorkpackages">
    <workpackages-toolbar
      v-if="showToolbar"
      doc-path="020-workpackage"
      :entity="entities.workpackage"
      @on-search="onSearch"
      @on-sort="onSort"
    />

    <h2 v-if="!showToolbar && activeWorkpackages.length" class="workpackages__title">
      {{ $t('workpackages.title') }} <docs-link link="toolguide/020-workpackage.html" />
    </h2>

    <template v-if="additionalCreateWorkpackageBtnsEnabled">
      <add-workpackage-buttons class="mt-4" />

      <v-row>
        <v-col class="horizontal-divider-container">
          <div class="horizontal-divider" />
        </v-col>
      </v-row>
    </template>

    <v-card class="col-box">
      <v-alert v-if="loading" border="left" colored-border color="primary accent-4">
        <v-progress-circular indeterminate color="primary" size="20" />
        {{ $t('workpackagePage.loadingActiveWorkpackages') }}
      </v-alert>
      <v-row no-gutters>
        <v-col class="workpackages__container" colspan="12">
          <workpackages-list
            v-if="sortedWorkpackagesList.length"
            :entity="entities.workpackage"
            :is-link="isLink"
            :visible-workpackages="visibleWorkpackages"
            :workpackages="sortedWorkpackagesList"
          />
          <v-alert v-if="!loading && !visibleWorkpackages.length" type="info" text>
            {{ $t('workpackages.noActiveWps') }}
          </v-alert>
        </v-col>
      </v-row>
    </v-card>

    <v-row>
      <v-col class="horizontal-divider-container">
        <div class="horizontal-divider" />
      </v-col>
    </v-row>

    <add-workpackage-buttons class="mb-6" />
  </div>
</template>

<script>
import { filter, get, includes, map } from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { jobFinishedStatuses } from '@enums/jobapi';
import entities from '@enums/entities';
import sortUtils from '../../../utils/sort-utils';
import searchUtils from '../../../utils/search-utils';

export default {
  name: 'ActiveWorkpackages',
  props: {
    isLink: {
      type: Boolean,
      required: false,
      default: false,
    },

    showToolbar: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      sortOrder: null,
      searchText: '',
      entities,
    };
  },

  computed: {
    ...mapState('bundles', ['selectedBundle']),
    ...mapState('workpackages', ['loading']),
    ...mapGetters('workpackages', ['activeWorkpackages']),
    ...mapGetters('context', ['getClientConfig']),

    visibleWorkpackages() {
      return searchUtils.getResultsByNameDescription(
        this.searchText,
        this.activeWorkpackages,
        this.sortOrder
      );
    },

    sortedWorkpackagesList() {
      return this.sortOrder
        ? sortUtils.sortItemList(this.activeWorkpackages, this.sortOrder)
        : this.activeWorkpackages;
    },

    bundleId() {
      return get(this.selectedBundle, '_id');
    },

    additionalCreateWorkpackageBtnsEnabled() {
      return get(
        this.getClientConfig,
        'features.uiComponents.additionalCreateWorkpackageBtnsEnabled',
        false
      );
    },
  },

  async created() {
    // clear workpackage state to avoid displaying incorrect workpackages in list while new
    // workpackages are loading
    this.setWorkpackages([]);
    // initialise getting all workpackages
    await this.fetchWorkpackages({ bundleId: this.bundleId });
    await this.fetchWorkpackages({ shouldGetTemplates: true });
  },

  methods: {
    ...mapMutations('workpackages', ['setWorkpackageSetupExpanded', 'setWorkpackages']),
    ...mapActions('workpackages', ['fetchWorkpackages']),

    getWorkpackagesWithActiveJob(jobName) {
      return map(
        filter(this.activeWorkpackages, function(wp) {
          return (
            wp.jobs && wp.jobs[jobName] && !includes(jobFinishedStatuses, wp.jobs[jobName].status)
          );
        }),
        wp => wp._id
      );
    },

    onSearch(searchText) {
      this.loadingActiveWorkpackages = true;
      this.searchText = searchText;
      if (!searchText) {
        this.loadingActiveWorkpackages = false;
        return;
      }
      this.loadingActiveWorkpackages = false;
    },

    onSort(sortOrder) {
      this.sortOrder = sortOrder;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.workpackages {
  &__container {
    overflow-x: auto;
  }

  &__title {
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0 0 10px 38px;
  }
}

::v-deep {
  .v-application .headline {
    font-family: $assortment-regular-font;
  }
}

::v-deep {
  .progress-bar {
    padding-bottom: 10em;
  }
}
</style>
