import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: { value: _vm.value, width: "700px" },
      on: { "click:outside": _vm.closeModal },
    },
    [
      _vm.value
        ? _c(
            "dialog-card",
            {
              attrs: {
                title: _vm.shouldSubCluster
                  ? _vm.$tkey("subClusteringTitle")
                  : _vm.$tkey("generationTitle"),
              },
              on: { close: _vm.closeModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("page-actions", {
                          attrs: {
                            "has-data-errors": _vm.hasDataErrors,
                            "has-data-changes": true,
                            "show-discard": false,
                            "save-btn-text": _vm.saveBtnText,
                          },
                          on: { save: _vm.onSave },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "right-btns",
                                fn: function () {
                                  return [
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "text-outline",
                                        attrs: { text: "", link: "" },
                                        on: { click: _vm.closeModal },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.$t("actions.cancel")) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            808452761
                          ),
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                500952281
              ),
            },
            [
              _c(
                VContainer,
                {
                  staticStyle: { "max-height": "500px", padding: "0" },
                  attrs: { fluid: "" },
                },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        {
                          staticClass:
                            "rtls-border rtls-border--right pl-0 mt-2",
                          attrs: { cols: "6" },
                        },
                        [
                          _c(
                            VForm,
                            {
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              !_vm.shouldSubCluster
                                ? _c(
                                    "div",
                                    {
                                      attrs: {
                                        "data-id-e2e":
                                          "clustering-scheme-generation-wrapper",
                                      },
                                    },
                                    [
                                      _c(VRow, [
                                        _c(
                                          "span",
                                          { staticClass: "font-weight-bold" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$tkey("methodology"))
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        VRow,
                                        { staticClass: "mt-3 mb-2" },
                                        [
                                          _c(
                                            VContainer,
                                            {
                                              staticClass: "mx-0 pa-0 pt-2",
                                              attrs: { fluid: "" },
                                            },
                                            [
                                              _c(
                                                VRow,
                                                [
                                                  _c(VCheckbox, {
                                                    staticClass:
                                                      "rtls-checkbox mx-2",
                                                    attrs: {
                                                      "data-id-e2e":
                                                        "toggleClusterSchemeSourceUploadCheckbox",
                                                      "input-value":
                                                        _vm
                                                          .clusteringSchemeSourceSelections[
                                                          _vm.clusterSchemeTypes
                                                            .upload
                                                        ],
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.onToggleManualUploadSelected,
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "mr-4 align-self-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$tkey(
                                                            "manualUpload"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm
                                                    .clusteringSchemeSourceSelections[
                                                    _vm.clusterSchemeTypes
                                                      .upload
                                                  ]
                                                    ? _c("data-upload", {
                                                        attrs: {
                                                          legends:
                                                            _vm.csvUploadLegends,
                                                          "csv-upload-handler":
                                                            _vm.onCSVUpload,
                                                        },
                                                        on: {
                                                          process: _vm.process,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _vm
                                                .clusteringSchemeSourceSelections[
                                                _vm.clusterSchemeTypes.upload
                                              ]
                                                ? _c(
                                                    VRow,
                                                    [
                                                      _vm.hasCSVUploadedData
                                                        ? _c(VDataTable, {
                                                            staticClass:
                                                              "schemes-table my-2",
                                                            attrs: {
                                                              loading:
                                                                _vm.loading,
                                                              headers:
                                                                _vm.kmeansTableHeaders,
                                                              "max-height":
                                                                "500px",
                                                              dense: "",
                                                              "disable-pagination":
                                                                "",
                                                              "disable-sort":
                                                                "",
                                                              "hide-default-footer":
                                                                "",
                                                              "hide-default-header":
                                                                "",
                                                              "fixed-header":
                                                                "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "header",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "thead",
                                                                        [
                                                                          _c(
                                                                            "tr",
                                                                            _vm._l(
                                                                              _vm.kmeansTableHeaders,
                                                                              function (
                                                                                header
                                                                              ) {
                                                                                return _c(
                                                                                  "th",
                                                                                  {
                                                                                    key: `upload-${header.id}`,
                                                                                    class:
                                                                                      header.headerClass,
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                              " +
                                                                                        _vm._s(
                                                                                          header.text
                                                                                        ) +
                                                                                        "\n                            "
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              }
                                                                            ),
                                                                            0
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                                {
                                                                  key: "body",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "tbody",
                                                                        _vm._l(
                                                                          _vm.getTableRows,
                                                                          function (
                                                                            clusteringScheme,
                                                                            rowIndex
                                                                          ) {
                                                                            return _c(
                                                                              "tr",
                                                                              {
                                                                                key: rowIndex,
                                                                              },
                                                                              _vm._l(
                                                                                _vm.kmeansTableHeaders,
                                                                                function (
                                                                                  header
                                                                                ) {
                                                                                  return _c(
                                                                                    "td",
                                                                                    {
                                                                                      key: `upload-${header.id}-row-${rowIndex}`,
                                                                                      class:
                                                                                        header.cellClass,
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              " +
                                                                                          _vm._s(
                                                                                            clusteringScheme[
                                                                                              header
                                                                                                .value
                                                                                            ]
                                                                                          ) +
                                                                                          "\n                            "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              ),
                                                                              0
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              4244643575
                                                            ),
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(VDivider, {
                                        attrs: { horizontal: "" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        VRow,
                                        { staticClass: "my-2 py-2" },
                                        [
                                          _c(VCheckbox, {
                                            staticClass: "rtls-checkbox mx-2",
                                            attrs: {
                                              "data-id-e2e":
                                                "toggleClusterSchemeSourceKmeansCheckbox",
                                              "input-value":
                                                _vm
                                                  .clusteringSchemeSourceSelections[
                                                  _vm.clusterSchemeTypes.kmeans
                                                ],
                                              disabled: _vm.shouldSubCluster,
                                            },
                                            on: {
                                              change:
                                                _vm.onToggleKMeansSelected,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "align-self-center",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$tkey("kmeanOption"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.clusteringSchemeSourceSelections[
                                        _vm.clusterSchemeTypes.kmeans
                                      ]
                                        ? [
                                            _c(
                                              VRow,
                                              { staticClass: "my-2" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tkey(
                                                        "noOfClustersLabel"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("rtls-select", {
                                                  staticClass: "my-2",
                                                  attrs: {
                                                    items: _vm.availableKMeans,
                                                    placeholder:
                                                      _vm.$t("general.select"),
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.onSelectionOfSchemeNumber,
                                                  },
                                                  model: {
                                                    value: _vm.numberOfSchemes,
                                                    callback: function ($$v) {
                                                      _vm.numberOfSchemes = $$v
                                                    },
                                                    expression:
                                                      "numberOfSchemes",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.hasKmeansData
                                              ? _c(
                                                  VRow,
                                                  { staticClass: "my-2" },
                                                  [
                                                    _c(
                                                      VForm,
                                                      {
                                                        model: {
                                                          value:
                                                            _vm.validNewKmeansTable,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.validNewKmeansTable =
                                                              $$v
                                                          },
                                                          expression:
                                                            "validNewKmeansTable",
                                                        },
                                                      },
                                                      [
                                                        _c(VSimpleTable, {
                                                          staticClass:
                                                            "schemes-table",
                                                          attrs: {
                                                            "max-height":
                                                              "500px",
                                                            dense: "",
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function () {
                                                                  return [
                                                                    _c(
                                                                      "thead",
                                                                      [
                                                                        _c(
                                                                          "tr",
                                                                          _vm._l(
                                                                            _vm.kmeansTableHeaders,
                                                                            function (
                                                                              header
                                                                            ) {
                                                                              return _c(
                                                                                "th",
                                                                                {
                                                                                  key: header.id,
                                                                                  class:
                                                                                    header.headerClass,
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                              " +
                                                                                      _vm._s(
                                                                                        header.text
                                                                                      ) +
                                                                                      "\n                            "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            }
                                                                          ),
                                                                          0
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "tbody",
                                                                      _vm._l(
                                                                        _vm.dynamicClusterSchemes,
                                                                        function (
                                                                          clusteringScheme,
                                                                          rowIndex
                                                                        ) {
                                                                          return _c(
                                                                            "tr",
                                                                            {
                                                                              key: rowIndex,
                                                                            },
                                                                            _vm._l(
                                                                              _vm.kmeansTableHeaders,
                                                                              function (
                                                                                header
                                                                              ) {
                                                                                return _c(
                                                                                  "td",
                                                                                  {
                                                                                    key: `${header.id}-row-${rowIndex}`,
                                                                                    class:
                                                                                      header.cellClass,
                                                                                  },
                                                                                  [
                                                                                    header.ui ===
                                                                                    _vm
                                                                                      .CELL_TYPE
                                                                                      .input
                                                                                      ? _c(
                                                                                          "rtls-text-field",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                rules:
                                                                                                  header.rules,
                                                                                                "server-error":
                                                                                                  header.serverRule(
                                                                                                    clusteringScheme
                                                                                                  ),
                                                                                              },
                                                                                            on: {
                                                                                              input:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  header.onInput
                                                                                                    ? header.onInput(
                                                                                                        clusteringScheme,
                                                                                                        $event
                                                                                                      )
                                                                                                    : null
                                                                                                },
                                                                                            },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  clusteringScheme[
                                                                                                    header
                                                                                                      .value
                                                                                                  ],
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      clusteringScheme,
                                                                                                      header.value,
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "clusteringScheme[header.value]",
                                                                                              },
                                                                                          }
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              }
                                                                            ),
                                                                            0
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ]
                                                                },
                                                                proxy: true,
                                                              },
                                                            ],
                                                            null,
                                                            false,
                                                            4294283139
                                                          ),
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(VDivider, {
                                        attrs: { horizontal: "" },
                                      }),
                                      _vm._v(" "),
                                      _vm.hasClusteringFromDataEnabled
                                        ? _c(
                                            VRow,
                                            { staticClass: "my-2 py-2" },
                                            [
                                              _c(
                                                VContainer,
                                                {
                                                  staticClass: "mx-0 pa-0",
                                                  attrs: { fluid: "" },
                                                },
                                                [
                                                  _c(
                                                    VRow,
                                                    [
                                                      _c(VCheckbox, {
                                                        staticClass:
                                                          "rtls-checkbox mx-2",
                                                        attrs: {
                                                          "data-id-e2e":
                                                            "toggleClusterSchemeSourceFeedCheckbox",
                                                          "input-value":
                                                            _vm
                                                              .clusteringSchemeSourceSelections[
                                                              _vm
                                                                .clusterSchemeTypes
                                                                .feed
                                                            ],
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.onToggleFeedSelected,
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "align-self-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$tkey(
                                                                "feedOption"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm
                                                    .clusteringSchemeSourceSelections[
                                                    _vm.clusterSchemeTypes.feed
                                                  ]
                                                    ? _c(
                                                        VRow,
                                                        [
                                                          _vm.hasFeedData
                                                            ? _c(
                                                                VDataTable,
                                                                {
                                                                  staticClass:
                                                                    "schemes-table my-2",
                                                                  attrs: {
                                                                    loading:
                                                                      _vm.loadingFromFeed,
                                                                    headers:
                                                                      _vm.kmeansTableHeaders,
                                                                    "max-height":
                                                                      "500px",
                                                                    dense: "",
                                                                    "disable-pagination":
                                                                      "",
                                                                    "disable-sort":
                                                                      "",
                                                                    "hide-default-footer":
                                                                      "",
                                                                    "hide-default-header":
                                                                      "",
                                                                    "fixed-header":
                                                                      "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "header",
                                                                          fn: function () {
                                                                            return [
                                                                              _c(
                                                                                "thead",
                                                                                [
                                                                                  _c(
                                                                                    "tr",
                                                                                    _vm._l(
                                                                                      _vm.kmeansTableHeaders,
                                                                                      function (
                                                                                        header
                                                                                      ) {
                                                                                        return _c(
                                                                                          "th",
                                                                                          {
                                                                                            key: `feed-${header.id}`,
                                                                                            class:
                                                                                              header.headerClass,
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                              " +
                                                                                                _vm._s(
                                                                                                  header.text
                                                                                                ) +
                                                                                                "\n                            "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                    0
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                          proxy: true,
                                                                        },
                                                                        {
                                                                          key: "body",
                                                                          fn: function () {
                                                                            return [
                                                                              _c(
                                                                                "tbody",
                                                                                _vm._l(
                                                                                  _vm.clusterSchemesFromFeed,
                                                                                  function (
                                                                                    clusteringScheme,
                                                                                    rowIndex
                                                                                  ) {
                                                                                    return _c(
                                                                                      "tr",
                                                                                      {
                                                                                        key: rowIndex,
                                                                                      },
                                                                                      _vm._l(
                                                                                        _vm.kmeansTableHeaders,
                                                                                        function (
                                                                                          header
                                                                                        ) {
                                                                                          return _c(
                                                                                            "td",
                                                                                            {
                                                                                              key: `feed-${header.id}-row-${rowIndex}`,
                                                                                              class:
                                                                                                header.cellClass,
                                                                                            },
                                                                                            [
                                                                                              header.ui ===
                                                                                              _vm
                                                                                                .CELL_TYPE
                                                                                                .input
                                                                                                ? _c(
                                                                                                    "rtls-text-field",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          value:
                                                                                                            clusteringScheme[
                                                                                                              header
                                                                                                                .value
                                                                                                            ],
                                                                                                          "server-error":
                                                                                                            header.serverRule(
                                                                                                              clusteringScheme
                                                                                                            ),
                                                                                                          readonly:
                                                                                                            "",
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                : _vm._e(),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      0
                                                                                    )
                                                                                  }
                                                                                ),
                                                                                0
                                                                              ),
                                                                            ]
                                                                          },
                                                                          proxy: true,
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      2290249726
                                                                    ),
                                                                }
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasDefaultClustersEnabled
                                        ? _c(VDivider, {
                                            attrs: { horizontal: "" },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.hasDefaultClustersEnabled
                                        ? _c(
                                            VRow,
                                            { staticClass: "my-2" },
                                            [
                                              _c(
                                                VContainer,
                                                {
                                                  staticClass: "mx-0 px-0",
                                                  attrs: { fluid: "" },
                                                },
                                                [
                                                  _c(
                                                    VRow,
                                                    [
                                                      _c(VCheckbox, {
                                                        staticClass:
                                                          "rtls-checkbox mx-2",
                                                        attrs: {
                                                          "data-id-e2e":
                                                            "toggleClusterSchemeSourceDefaultCheckbox",
                                                          "input-value":
                                                            _vm
                                                              .clusteringSchemeSourceSelections[
                                                              _vm
                                                                .clusterSchemeTypes
                                                                .default
                                                            ],
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.onToggleDefaultClustersSelected,
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$tkey(
                                                              "defaultClusters"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _vm
                                                    .clusteringSchemeSourceSelections[
                                                    _vm.clusterSchemeTypes
                                                      .default
                                                  ]
                                                    ? _c(
                                                        VRow,
                                                        { staticClass: "my-2" },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "w-100",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$tkey(
                                                                    "selectDefaultScheme"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-grow-1",
                                                            },
                                                            [
                                                              _c(
                                                                "rtls-select",
                                                                {
                                                                  staticClass:
                                                                    "my-2",
                                                                  attrs: {
                                                                    items:
                                                                      _vm.referenceClusterSchemes,
                                                                    "item-text":
                                                                      "name",
                                                                    "item-value":
                                                                      "value",
                                                                    placeholder:
                                                                      _vm.$t(
                                                                        "general.select"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.setSelectedReferenceClusterScheme,
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "error-triangle",
                                                                {
                                                                  staticClass:
                                                                    "ml-1",
                                                                  attrs: {
                                                                    errors:
                                                                      _vm.referenceClusterSchemeErrors,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm
                                                    .clusteringSchemeSourceSelections[
                                                    _vm.clusterSchemeTypes
                                                      .default
                                                  ]
                                                    ? _c(VRow, [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$tkey(
                                                                "missingDefaultClustersMessage"
                                                              )
                                                            )
                                                          ),
                                                        ]),
                                                      ])
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _c(
                                    "div",
                                    {
                                      attrs: {
                                        "data-id-e2e": "sub-clustering-wrapper",
                                      },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            VCol,
                                            {
                                              staticClass:
                                                "col-4 pa-0 d-flex justify-end",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "text-right" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tkey(
                                                        "clusteringSchemeLabel"
                                                      )
                                                    ) + ":"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            VCol,
                                            { staticClass: "info-note" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.selectedScheme.name)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        VRow,
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            VCol,
                                            {
                                              staticClass:
                                                "col-4 pa-0 d-flex justify-end",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "text-right" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tkey("clusterLabel")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            VCol,
                                            { staticClass: "info-note" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.subClusterFrom.clusterName
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        VRow,
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(
                                            VCol,
                                            {
                                              staticClass:
                                                "col-4 pa-0 d-flex justify-end",
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "text-right" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tkey("methodology")
                                                    ) + ":"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            VCol,
                                            { staticClass: "info-note" },
                                            [
                                              _c(
                                                VRow,
                                                [
                                                  _vm.hasDefaultClustersEnabled
                                                    ? _c(
                                                        VRadioGroup,
                                                        {
                                                          attrs: {
                                                            disabled: false,
                                                            "hide-details": "",
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.onSubClusteringMethodologyChanged,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.selectedSubClusteringMethodology,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.selectedSubClusteringMethodology =
                                                                $$v
                                                            },
                                                            expression:
                                                              "selectedSubClusteringMethodology",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.subClusteringOptions,
                                                          function (
                                                            methodology
                                                          ) {
                                                            return _c(
                                                              VRadio,
                                                              {
                                                                key: methodology.value,
                                                                staticClass:
                                                                  "sub-clustering__label",
                                                                attrs: {
                                                                  ripple: false,
                                                                  label:
                                                                    methodology.text,
                                                                  value:
                                                                    methodology.value,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$tkey(
                                                              "kmeanOption"
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        VForm,
                                        {
                                          model: {
                                            value: _vm.validSubClusteringScheme,
                                            callback: function ($$v) {
                                              _vm.validSubClusteringScheme = $$v
                                            },
                                            expression:
                                              "validSubClusteringScheme",
                                          },
                                        },
                                        [
                                          _vm.selectedSubClusteringMethodology ===
                                          _vm.clusterSchemeTypes.kmeans
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    VRow,
                                                    {
                                                      staticClass:
                                                        "d-flex align-center",
                                                    },
                                                    [
                                                      _c(
                                                        VCol,
                                                        {
                                                          staticClass:
                                                            "col-4 pa-0 d-flex justify-end",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$tkey(
                                                                    "noOfSubClustersLabel"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        VCol,
                                                        [
                                                          _c(
                                                            "rtls-text-field",
                                                            {
                                                              attrs: {
                                                                rules:
                                                                  _vm
                                                                    .clusterCountInput
                                                                    .rules,
                                                                "server-error":
                                                                  _vm.clusterCountInput.serverRule(
                                                                    _vm.newSubClusteringScheme
                                                                  ),
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.clusterCountInput.onInput(
                                                                      _vm.newSubClusteringScheme,
                                                                      $event
                                                                    )
                                                                  },
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .newSubClusteringScheme[
                                                                    _vm
                                                                      .clusterCountInput
                                                                      .value
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.newSubClusteringScheme,
                                                                      _vm
                                                                        .clusterCountInput
                                                                        .value,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "newSubClusteringScheme[clusterCountInput.value]",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    VRow,
                                                    {
                                                      staticClass:
                                                        "d-flex align-center",
                                                    },
                                                    [
                                                      _c(
                                                        VCol,
                                                        {
                                                          staticClass:
                                                            "col-4 pa-0 d-flex justify-end",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$tkey(
                                                                    "newClusteringSchemeLabel"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        VCol,
                                                        [
                                                          _c(
                                                            "rtls-text-field",
                                                            {
                                                              attrs: {
                                                                rules:
                                                                  _vm
                                                                    .schemeNameInput
                                                                    .rules,
                                                                "server-error":
                                                                  _vm.schemeNameInput.serverRule(
                                                                    _vm.newSubClusteringScheme
                                                                  ),
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .newSubClusteringScheme[
                                                                    _vm
                                                                      .schemeNameInput
                                                                      .value
                                                                  ],
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.newSubClusteringScheme,
                                                                      _vm
                                                                        .schemeNameInput
                                                                        .value,
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "newSubClusteringScheme[schemeNameInput.value]",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.hasDefaultClustersEnabled &&
                                          _vm.selectedSubClusteringMethodology ===
                                            _vm.clusterSchemeTypes.default
                                            ? _c(
                                                VRow,
                                                [
                                                  _c(
                                                    VCol,
                                                    {
                                                      staticClass:
                                                        "col-4 pr-0 d-flex justify-end",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-right mt-3",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                      " +
                                                              _vm._s(
                                                                `${_vm.$t(
                                                                  "clusteringPage.defaultClusteringScheme"
                                                                )}:`
                                                              ) +
                                                              "\n                    "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(VCol, [
                                                    _c(
                                                      "div",
                                                      { staticClass: "d-flex" },
                                                      [
                                                        _c("rtls-select", {
                                                          staticClass: "my-2",
                                                          attrs: {
                                                            items:
                                                              _vm.referenceClusterSchemes,
                                                            "item-text": "name",
                                                            "item-value":
                                                              "value",
                                                            placeholder:
                                                              _vm.$t(
                                                                "general.select"
                                                              ),
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.setSelectedReferenceSubClusterScheme,
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("error-triangle", {
                                                          staticClass: "ml-1",
                                                          attrs: {
                                                            errors:
                                                              _vm.referenceClusterSchemeErrors,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "mr-3 d-block",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$tkey(
                                                              "missingDefaultClustersMessage"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VCol,
                        { staticClass: "pr-0 mt-2", attrs: { cols: "6" } },
                        [
                          _c(VRow, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$tkey("attributes")) +
                                  "\n            "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.selectedAttributes, function (attribute) {
                            return _c(
                              VRow,
                              {
                                key: attribute._id,
                                staticClass: "attributes-row",
                              },
                              [
                                _c(VCheckbox, {
                                  staticClass: "rtls-checkbox mx-2",
                                  attrs: { "input-value": attribute.selected },
                                  on: {
                                    change: function ($event) {
                                      attribute.selected = $event
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v(_vm._s(attribute.name))]),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }