<template functional>
  <div class="flex-column pt-2 default-box d-flex relative">
    <div v-if="props.showValues && props.isTopSection" class="flex-column stats-item">
      <span class="value">
        <b>{{
          parent.formatNumber({ number: props.product.originalSales, format: 'currency' })
        }}</b>
        {{ parent.$t('assortmentCanvasPage.productDashboard.transferredSpend.sales') }}
      </span>
    </div>
    <div v-if="props.showValues && props.isTopSection" class="flex-column stats-item">
      <span class="value">
        <b>{{ parent.formatNumber({ number: props.product.newSales, format: 'currency' }) }}</b>
        {{ parent.$t('assortmentCanvasPage.productDashboard.transferredSpend.inclTransSpend') }}
      </span>
    </div>
    <div v-if="props.showValues" class="flex-column pt-3 pb-3">
      <assortment-image
        dashboard
        :src="props.imageUrl"
        :alt="props.imageAlt"
        :title="props.imageTitle"
        :tooltip-text="`${props.product.productKeyDisplay} - ${props.product.itemDescription}`"
        v-bind="props.conditionalProductTileStyle"
      />
    </div>
    <div v-if="props.showValues && !props.isTopSection" class="flex-column stats-item">
      <span class="value"
        ><b>{{
          parent.formatNumber({ number: props.product.originalSales, format: 'currency' })
        }}</b>
        {{ parent.$t('assortmentCanvasPage.productDashboard.transferredSpend.sales') }}
      </span>
    </div>
    <!-- for the others box -->
    <div v-if="!props.showValues && props.showOthers" class="flex-column relative stats-item">
      <span
        class="small-title"
        :class="[props.isTopSection ? 'small-title-top' : 'small-title-bot']"
      >
        {{ parent.$t('assortmentCanvasPage.productDashboard.transferredSpend.others') }}
      </span>
    </div>
    <div v-if="!props.showValues && props.showOthers" class="flex-column icon-column">
      <span class="box-icon mdi mdi-buffer" />
    </div>
    <div v-if="!props.showValues && props.showOthers" class="relative counter-value-container">
      <div class="counter-value counter-value-bold">+{{ props.fromOthersCount }}</div>
    </div>
    <!-- for the sales lost box -->
    <div v-if="!props.showValues && !props.showOthers" class="flex-column relative stats-item">
      <span class="small-title small-title-top"
        >{{ parent.$t('assortmentCanvasPage.productDashboard.transferredSpend.salesLost') }}
      </span>
    </div>
    <div v-if="!props.showValues && !props.showOthers" class="flex-column icon-column">
      <span class="box-icon-red mdi mdi-trending-down" />
    </div>
    <!-- values avatar -->
    <div
      class="flex-column value-container"
      :class="[props.isTopSection ? 'value-container-top' : 'value-container-bot']"
    >
      <div class="flex-column stats-item">
        <span
          class="value counter-value-bold"
          :class="[!props.showValues && !props.showOthers ? 'counter-value-red' : 'counter-value']"
        >
          {{ parent.formatNumber({ number: props.amount, format: 'currency' }) }}
        </span>
      </div>
      <div class="flex-column stats-item">
        <span
          class="counter-value"
          :class="[!props.showValues && !props.showOthers ? 'counter-value-red' : 'counter-value']"
          >{{ parent.formatNumber({ number: props.amountPercentage, format: 'percentage' }) }}
          %
        </span>
      </div>
    </div>
    <!-- arrow icon -->
    <div
      class="flex-column arrow-container"
      :class="[props.isTopSection ? 'arrow-container-top' : 'arrow-container-bot']"
    >
      <span class="arrow-icon mdi mdi-arrow-up" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TransferredSpendBox',
  props: {
    product: {
      type: Object,
      required: true,
    },
    showValues: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOthers: {
      type: Boolean,
      required: false,
      default: false,
    },
    fromOthersCount: {
      type: Number,
      required: false,
      default: 0,
    },
    amount: {
      type: Number,
      required: true,
    },
    amountPercentage: {
      type: Number,
      required: true,
    },
    isTopSection: {
      type: Boolean,
      required: false,
      default: true,
    },
    // props for the pictures
    imageUrl: {
      type: String,
      required: false,
      default: '',
    },
    imageAlt: {
      type: String,
      required: false,
      default: '',
    },
    imageTitle: {
      type: String,
      required: false,
      default: '',
    },
    conditionalProductTileStyle: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.default-box {
  width: 120px;
  height: 100px;
  border: 1px solid $assortment-transferred-spend-border;
  background-color: $assortment-input-background;
  transition: all 1s ease-out;
  margin: 0 5px 5px 0;
  text-align: center;
  padding-top: 5px;
  &:first-child {
    border-radius: 10px 0 0 10px;
  }
  &:last-child {
    border-radius: 0 10px 10px 0;
  }
}
.top-container {
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 15px;
}
.value {
  font-size: 1rem;
}
.small-title {
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  background-color: $assortment-input-background;
  padding-left: 5px;
  padding-right: 5px;
  display: block;
  left: 50%;
  transform: translate(-50%, 50%);
}
.small-title-top {
  top: -18px;
}
.small-title-bot {
  top: 79px;
}
.counter-value-container {
  position: absolute;
  top: -72px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 74%;
  transform: translate(-50%, 50%);
  border: 1px solid $assortment-input-background;
  border-radius: 50%;
  background: $reporting-counter-bg-color;
  padding: 5px;
  min-width: 30px;
  width: fit-content;
}
.counter-value {
  font-size: 1rem;
}
.counter-value-bold {
  font-weight: bold;
}
.counter-value-red {
  color: $assortment-negative-action-colour;
}
.icon-column {
  margin-top: 5px;
}
.relative {
  position: relative;
}
.box-icon {
  font-size: 5.3rem !important;
  color: $assortment-action-icon-color !important;
}
.arrow-icon {
  font-size: 2.3rem !important;
  color: $assortment-transferred-spend-border;
}
.box-icon-red {
  font-size: 5.3rem !important;
  color: $assortment-negative-action-colour !important;
}
.value-container {
  position: absolute;
  top: 63px;
  left: 50%;
  display: block;
  transform: translate(-50%, 50%);
  border: 1px solid $assortment-transferred-spend-border;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  background-color: $assortment-input-background;
}
.value-container-top {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 63px;
}
.value-container-bot {
  display: flex;
  justify-content: center;
  align-items: center;
  top: -113px;
}
.stats-item {
  line-height: 10px;
}
.arrow-container {
  position: absolute;
  left: 50%;
  display: block;
  transform: translate(-50%, 50%);
}
.arrow-container-top {
  top: 133px;
}
.arrow-container-bot {
  top: -42px;
}
</style>
