<!-- Continas the options in the layout toolbar, usch as saving the current layout -->
<template>
  <div class="layout-options-main">
    <span class="mx-2">
      {{ $t('reportingPage.sections.product.sideBar.toolPanels.layouts.currentLayout') }}
      {{ layoutName }}
    </span>
    <v-btn class=" mx-2" primary depressed width="min-content" height="18" @click="save">{{
      $t('actions.save')
    }}</v-btn>
    <layout-modal :value="saveOpen" @close="closeModal" @save="saveLayout" />
  </div>
</template>

<script>
export default {
  props: {
    layoutName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      saveOpen: false,
    };
  },

  methods: {
    saveLayout(params) {
      this.$emit('save', { ...params, closeModal: this.closeModal });
    },

    closeModal() {
      this.saveOpen = false;
    },
    save() {
      this.saveOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.layout-options-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 5px;
  width: 100%;
  span {
    font-size: 12px;
    font-weight: 700;
  }
}
</style>
