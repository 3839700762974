<template>
  <v-container fluid>
    <v-layout column class="compound-extracts__wrapper h-100">
      <div v-if="isRoot" class="d-flex flex-column h-100">
        <compound-extracts-table />
      </div>
      <router-view v-else />
    </v-layout>
  </v-container>
</template>

<script>
export default {
  computed: {
    isRoot() {
      // Checks if the route is the root parent or a child
      return this.$route.name === 'compound-extracts';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.compound-extracts {
  &__wrapper {
    background: $assortment-panel-background;
  }
}
</style>
