import { VCard } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VCard, { staticClass: "wp-scope-stores col-box pa-2" }, [
    _c("div", { staticClass: "wp-scope-stores__container" }, [
      _c("div", { staticClass: "mb-1" }, [
        _c(
          "h3",
          { staticClass: "wp-scope-stores__input-heading mt-3" },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("workpackagePage.scope.storesLabel")) +
                "\n\n        "
            ),
            _vm._v(" "),
            _c("assortment-tooltip", {
              attrs: {
                title: _vm.$t("tooltips.storeScope.title"),
                "tooltip-sections": _vm.storeScopeTooltipSections,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-end" }, [
        _c("table", { staticClass: "item-details-table mb-2" }, [
          _c("tr", { staticClass: "item-details-table__row" }, [
            _c("td", { staticClass: "item-details-table__label" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.$t("workpackagePage.scope.storesScopeForAnalysis")
                  ) +
                  "\n          "
              ),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "item-details-table__count" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.workpackage.storeCount
                      ? _vm.workpackage.storeCount.analysis
                      : 0
                  ) +
                  "\n          "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", { staticClass: "item-details-table__row" }, [
            _c("td", { staticClass: "item-details-table__label" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.$t("workpackagePage.scope.storesScopeForAssortment")
                  ) +
                  "\n          "
              ),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "item-details-table__count" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.workpackage.storeCount
                      ? _vm.workpackage.storeCount.assortment
                      : 0
                  ) +
                  "\n          "
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" }, [_c("workpackage-stores")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }