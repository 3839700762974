import axios from 'axios';
import to from 'await-to-js';
import { get, includes } from 'lodash';
import numeral from 'numeral';
import authConfig from '@sharedModules/config/staticAuth';
import { datadogRum } from '@datadog/browser-rum';
import i18n from '@/js/vue-i18n';

const getInitialContextState = () => ({
  loginMethod: null, // primary authMethod from auth.js config file
  loginRedirectUrl: null,
  oktaClientId: null,
  versionTag: null,
  env: null,
  subBrand: null,
  datadog: {},
  clientUrl: null,
  clientConfig: {},
  powerBIreportIds: {},
});

const getInitialUserContextState = () => ({
  csrfToken: null,
  profile: null,
  loading: false,
  currentLoginMethod: null, // authMethod used on session scope, needed for correct logout
  expireAt: null,
  showAdminControls: false,
  showClientAdminControls: false,
  simpleSwapsEnabled: false,
  reportingEnabled: false,
  templatesEnabled: false,
  bundlesEnabled: false,
  storeExecutionEnabled: false,
  uom: null,
  weeksNotSoldForDiscontinued: null,
  notUsingLocalDatabase: false,
  i18n: null,
  availableLocales: null,
  availableNumericLocales: null,
  availableDateLocales: null,
  currentLocale: null,
  numericLocale: null,
  dateLocale: null,
  localeInfo: null,
  // used by unsaved-data-warning
  allowTabChange: true,
  compoundExtractsEnabled: false,
  reportingPowerBIEnabled: false,
  availableExtractsExports: [],
});

const store = {
  namespaced: true,

  state: { ...getInitialContextState(), ...getInitialUserContextState() },

  getters: {
    currentUserPermissions: state => new Set(get(state.profile, 'permissions', [])), // Use set for quicker lookup
    currentUserProfile: state => state.profile,
    getFullName: (state, getters) => {
      const firstName = get(getters.currentUserProfile, 'firstname', '');
      const surname = get(getters.currentUserProfile, 'surname', '');
      return `${firstName} ${surname}`.trim();
    },
    hasPermission: (state, getters) => permission => getters.currentUserPermissions.has(permission),
    getDateFormats: state => state.clientConfig.dateFormats[state.dateLocale],
    getDefaultReverseFormat: state => state.clientConfig.dateFormats.defaultReverse,
    getExcelDateFormat: state =>
      state.clientConfig.dateFormats[state.dateLocale].long.replace(/-/g, '/'),
    getTimeRemainingFormat: state => state.timeRemainingFormat,
    getCurrency: state => state.clientConfig.currency,
    getCsvDelimiter: state => state.clientConfig.csvDelimiter,
    getCsvExport: state => state.clientConfig.csvExport,
    getCannGroupsRecommendedSizes: state => state.clientConfig.cannGroupsRecommendedSizes,
    getShowAdminControlsPermissions: state => state.showAdminControls,
    getShowClientAdminControlsPermissions: state => state.showClientAdminControls,
    getShowReportingControlsPermissions: state => state.reportingEnabled,
    getShowTemplatesPermissions: state => state.templatesEnabled,
    getShowBundlesPermissions: state => state.bundlesEnabled,
    getSimpleSwapsPermissions: state => state.simpleSwapsEnabled,
    getStoreExecutionPermissions: state => state.storeExecutionEnabled,
    getUnitOfMeasure: state => state.uom,
    getClientConfig: state => state.clientConfig,
    getAvailableLocales: state => state.availableLocales,
    getCurrentNumericLocale: state => state.numericLocale,
    getAvailableNumericLocales: state => state.availableNumericLocales,
    getCurrentDateLocale: state => state.dateLocale,
    getAvailableDateLocales: state => state.availableDateLocales,
    getLocaleInfo: state => state.localeInfo,
    getCurrentLocale: state => state.currentLocale,
    showNotImplemented: state => get(state.clientConfig, 'features.showNotImplemented', false),
    getShowCompoundExtractsPermissions: state => state.compoundExtractsEnabled,
    getShowReportingPowerBIPermissions: state => state.reportingPowerBIEnabled,
    getAvailableExtractsExports: state => state.availableExtractsExports,
    getProductMinimumFacings: state => state.clientConfig.productMinimumFacings,
    getShowLayouts: state => get(state.clientConfig, 'features.showLayouts', false),
    getPalletsSlotsEnabled: state => get(state.clientConfig, 'features.palletsSlotsEnabled', false),
    getQaControlsEnabled: state => get(state.clientConfig, 'features.qaControlsEnabled', false),
  },

  mutations: {
    setContext(state, contextData) {
      Object.assign(state, contextData);
    },
    setUserContext(state, profile) {
      state.profile = profile;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setLoginMethod(state, method) {
      state.loginMethod = method;
    },
    setCurrentLoginMethod(state, method) {
      state.currentLoginMethod = method;
    },
    setExpireAt(state, expireAt) {
      state.expireAt = expireAt;
    },
    setCsrfToken(state, token) {
      state.csrfToken = token;
    },
    setShowAdminControlsPermissions(state, showAdminControls) {
      state.showAdminControls = showAdminControls;
    },
    setShowClientAdminControlsPermissions(state, showClientAdminControls) {
      state.showClientAdminControls = showClientAdminControls;
    },
    setSimpleSwapsPermissions(state, simpleSwapsEnabled) {
      state.simpleSwapsEnabled = simpleSwapsEnabled;
    },
    setReportingPermissions(state, reportingEnabled) {
      state.reportingEnabled = reportingEnabled;
    },
    setTemplatesPermissions(state, templatesEnabled) {
      state.templatesEnabled = templatesEnabled;
    },
    setBundlesPermissions(state, bundlesEnabled) {
      state.bundlesEnabled = bundlesEnabled;
    },
    setStoreExecutionPermissions(state, storeExecutionEnabled) {
      state.storeExecutionEnabled = storeExecutionEnabled;
    },
    setShowCompoundExtractsPermissions(state, compoundExtractsEnabled) {
      state.compoundExtractsEnabled = compoundExtractsEnabled;
    },
    setShowreportingPowerBIPermissions(state, reportingPowerBIEnabled) {
      state.reportingPowerBIEnabled = reportingPowerBIEnabled;
    },
    setAvailableExtractsExports(state, availableExtractsExports) {
      state.availableExtractsExports = availableExtractsExports;
    },
    setUom(state, uom) {
      state.uom = uom;
    },
    setWeeksNotSoldForDiscontinued(state, numWeeks) {
      state.weeksNotSoldForDiscontinued = numWeeks;
    },
    setNotUsingLocalDatabase(state, notUsingLocalDatabase) {
      state.notUsingLocalDatabase = notUsingLocalDatabase;
    },
    setClientConfig(state, clientConfig) {
      state.clientConfig = clientConfig;
    },
    setI18n(state, i18nConfig) {
      state.i18n = i18nConfig;
    },
    setNumericLocale(state, numericLocale) {
      state.numericLocale = numericLocale;
    },
    setDateLocale(state, dateLocale) {
      state.dateLocale = dateLocale;
    },
    setAvailableDateLocales(state, availableDateLocales) {
      state.availableDateLocales = availableDateLocales;
    },
    setAvailableLocales(state, availableLocales) {
      state.availableLocales = availableLocales;
    },
    setAvailableNumericLocales(state, availableLocales) {
      state.availableNumericLocales = availableLocales;
    },
    setLocaleInfo(state, localeInfo) {
      state.localeInfo = localeInfo;
    },
    setCurrentLocale(state, currentLocale) {
      state.currentLocale = currentLocale;
    },
    setAllowTabChange(state, allowTabChange) {
      state.allowTabChange = allowTabChange;
    },
    resetUserContextState(state) {
      Object.assign(state, { ...state, ...getInitialUserContextState() });
    },
  },

  actions: {
    loginHardcoded({ dispatch, commit }, credentials) {
      return axios.post('/api/login', credentials).then(
        () => {
          commit('setCurrentLoginMethod', 'ow-auth-hardcoded');
          return dispatch('loadUserContext');
        },
        err => {
          console.error(err.response.data.message);
          commit('setCurrentLoginMethod', 'ow-auth-hardcoded');
        }
      );
    },

    loginOkta({ dispatch, commit }, token) {
      return axios.post('/api/ow-auth/login', { token }).then(
        () => {
          commit('setCurrentLoginMethod', 'ow-auth-okta');
          return dispatch('loadUserContext');
        },
        err => {
          console.error(err.response.data.message);
          commit('setCurrentLoginMethod', 'ow-auth-okta');
        }
      );
    },

    setCurrentLoginMethod({ commit }, method) {
      commit('setCurrentLoginMethod', method);
    },

    async loadAppContext({ commit }) {
      const [err, res] = await to(axios.get('/api/app-context'));
      if (err) throw new Error(err.message);

      const { data } = res;
      if (!data) return;

      commit('setContext', data);
    },

    async loadUserContext({ commit, dispatch }) {
      const [err, res] = await to(axios.get('/api/user-context'));
      if (err) throw new Error(err.message);

      const { data } = res;
      if (!data) return;
      datadogRum.setUser({ id: data.profile._id });
      // save tokenLifeTime in milliseconds if valid login exists
      const tokenLifetime = (data.exp - data.iat) * 1000;
      const expirationDate = Date.now() + tokenLifetime;
      commit('setUserContext', data.profile);
      commit('setCsrfToken', data.csrfToken);
      commit('setExpireAt', expirationDate);
      commit('setShowAdminControlsPermissions', data.showAdminControls);
      commit('setShowClientAdminControlsPermissions', data.showClientAdminControls);
      commit('setSimpleSwapsPermissions', data.simpleSwapsEnabled);
      commit('setReportingPermissions', data.reportingEnabled);
      commit('setTemplatesPermissions', data.templatesEnabled);
      commit('setBundlesPermissions', data.bundlesEnabled);
      commit('setStoreExecutionPermissions', data.storeExecutionEnabled);
      commit('setShowCompoundExtractsPermissions', data.compoundExtractsEnabled);
      commit('setShowreportingPowerBIPermissions', data.reportingPowerBIEnabled);
      commit('setAvailableExtractsExports', data.availableExtractsExports);
      commit('setAvailableDateLocales', data.availableDateLocales);
      // We get translations here as they contain sensitive info
      commit('setI18n', data.i18nConfig);
      dispatch('setNumericLocale', {});
      dispatch('setDateLocale', {});
      await dispatch('loadAppContext');
      await dispatch('setLanguageLocale', {});

      // this is where we know the user has successfully authenticated so we can load state
      dispatch('initialiseState', null, { root: true });
    },

    // Set the language provided so that translations for that language can be extracted
    setLanguageLocale({ commit, state }, { locale }) {
      const availableLocales = Object.keys(state.i18n.translations);
      commit('setAvailableLocales', availableLocales);
      availableLocales.forEach(availableLocale =>
        i18n.setLocaleMessage(availableLocale, state.i18n.translations[availableLocale])
      );

      const { localeInfo } = state.i18n; // contains abbreviations and full names of available locales
      commit('setLocaleInfo', localeInfo);

      const isCurrentLocaleAvailable =
        state.currentLocale && includes(availableLocales, state.currentLocale);
      const isDefaultLocaleAvailable = includes(availableLocales, state.i18n.defaultLocale);
      // If current locale is not available, set new locale to default or fallback.
      if (!isCurrentLocaleAvailable) {
        i18n.locale = isDefaultLocaleAvailable
          ? state.i18n.defaultLocale
          : state.i18n.fallbackLocale;
      } else {
        // Otherwise, only update it to provided or current locale on change.
        i18n.locale = locale || state.currentLocale;
      }
      commit('setCurrentLocale', i18n.locale);
    },

    setNumericLocale({ commit, state }, { locale }) {
      const availableLocales = state.i18n.availableNumericLocales;
      commit('setAvailableNumericLocales', availableLocales);

      const isNumericLocaleAvailable =
        state.numericLocale && includes(availableLocales, state.numericLocale);
      const isDefaultNumericLocaleAvailable = includes(
        availableLocales,
        state.i18n.defaultNumericLocale
      );
      // If current current locale is not available, set new current locale to default or fallback.
      if (!isNumericLocaleAvailable) {
        i18n.numericLocale = isDefaultNumericLocaleAvailable
          ? state.i18n.defaultNumericLocale
          : state.i18n.fallbackNumericLocale;
      } else {
        // Otherwise, only update it to provided or current current locale on change.
        i18n.numericLocale = locale || state.numericLocale;
      }
      commit('setNumericLocale', i18n.numericLocale);
      numeral.locale(i18n.numericLocale);
      // throw error if locale is undefined
      numeral.localeData(i18n.numericLocale);
    },

    setDateLocale({ commit, state }, { dateLocale }) {
      const availableDateLocales = state.i18n.availableDateLocales;
      commit('setAvailableDateLocales', availableDateLocales);

      const isDateLocaleAvailable =
        state.dateLocale && includes(availableDateLocales, state.dateLocale);
      const isDefaultDateLocaleAvailable = includes(
        availableDateLocales,
        state.i18n.defaultDateLocale
      );
      // If current current locale is not available, set new current locale to default or fallback.
      if (!isDateLocaleAvailable) {
        i18n.dateLocale = isDefaultDateLocaleAvailable
          ? state.i18n.defaultDateLocale
          : state.i18n.fallbackDateLocale;
      } else {
        // Otherwise, only update it to provided or current current locale on change.
        i18n.dateLocale = dateLocale || state.dateLocale;
      }
      commit('setDateLocale', i18n.dateLocale);
    },

    refreshUserContext({ dispatch }) {
      return axios.post('/api/token/refresh').then(() => {
        return dispatch('loadUserContext');
      });
    },

    logout({ state, commit }) {
      const logoutUrl = get(
        authConfig.authenticationPaths,
        `${state.currentLoginMethod}.logout`,
        '/api/logout'
      );

      return axios.get(logoutUrl).then(res => {
        commit('setUserContext', null);
        commit('setCsrfToken', null);

        if (state.currentLoginMethod === 'auth-saml') {
          // The SAML strategy will redirect to SAML logout page, this one
          // to our logout/callback and finally back to our client app
          window.location.href = res.data.redirectUrl;
          return Promise.reject();
        }
        return Promise.resolve(
          state.currentLoginMethod === 'ow-auth-okta' ? '/ow-auth/login' : '/login'
        );
      });
    },

    resetState({ commit }) {
      commit('resetUserContextState');
    },
  },
};

export default store;
