<template>
  <div :key="`priceladder-${isSidebarShown}`" class="h-100 d-flex flex-column">
    <reporting-section :short-view="shortView">
      <template v-slot:header>
        <reporting-header :short-view="shortView" :section="section" :title="getTitle">
          <template v-slot:filter>
            <filters
              v-if="!shortView"
              :filters="filters[section]"
              :filter-options="filterOptions"
              :btn-text="filterButtonText"
              :disabled="isPriceLadderReportDisabled || filtersDisabled"
              class="ml-3"
              @change="handleFilterChange"
              @apply="fetchData"
            />
          </template>
        </reporting-header>
      </template>

      <template v-slot:main-section>
        <reporting-main-section
          :section="section"
          :short-view="shortView"
          :subtitle="$tkey('subtitle')"
        >
          <template v-slot:data-section>
            <span v-if="isPriceLadderReportDisabled" class="not-available">
              {{ $t('reportingPage.noPermissions') }}
            </span>

            <span v-else-if="hasInvalidComparisons" class="not-available">
              {{ $t('reportingPage.notAvailableForComparisons') }}
            </span>

            <progress-bar v-if="isLoading || isProcessingData" />

            <div
              v-if="!isPriceLadderReportDisabled && !isLoading && !hasInvalidComparisons"
              id="chartarea"
              :class="{
                'chartarea-sidebar-shown': isSidebarShown,
                'chartarea-sidebar-hidden': !isSidebarShown,
              }"
            >
              <div id="tooltip" class="tooltip" />
            </div>
          </template>
        </reporting-main-section>
      </template>
      <!-- TODO add live export here -->
    </reporting-section>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { sortBy, forOwn, countBy, map, groupBy, some, size, isEqual } from 'lodash';
import reportingSections from '@enums/reporting-sections';
import dataType from '@enums/reporting-filters';
import colors from '../../../ow-colors';

let d3 = null;

export default {
  localizationKey: 'reportingPage.sections.price-ladder',

  props: {
    // represents whether this report is open on (short) overview page
    shortView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      chartData: {
        spacebreaks: [],
        prices: {},
      },
      isProcessingData: true,
      section: reportingSections.priceLadder,
    };
  },

  computed: {
    ...mapState('reporting', [
      'loadingSubsection',
      'baselineIds',
      'comparisonIds',
      'comparison2Ids',
      'selectedWorkpackage',
      'filters',
      'isSidebarShown',
    ]),
    ...mapGetters('reporting', ['scenariosById', 'filterOptions', 'isAnySelectionObserved']),
    ...mapGetters('workpackages', ['getUnitOfMeasure']),

    isPriceLadderReportDisabled() {
      return !this.hasPermission(this.userPermissions.canViewPriceLadderReport);
    },

    getTitle() {
      return this.shortView ? this.$tkey('title') : this.$tkey('titleFullView');
    },

    isLoading() {
      return this.loadingSubsection.priceLadderChart;
    },

    hasInvalidComparisons() {
      return !!size(this.comparison2Ids) || this.isAnySelectionObserved;
    },

    filterButtonText() {
      return `${this.$tc(`reportingPage.filters`, this.filters[this.section].length)}`;
    },

    filtersDisabled() {
      return this.isLoading || this.isProcessingData;
    },
  },

  watch: {
    baselineIds(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchData();
    },

    comparisonIds(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchData();
    },

    comparison2Ids(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchData();
    },

    async isSidebarShown() {
      await this.fetchData();
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    ...mapActions('reporting', ['fetchReportingData']),
    ...mapMutations('reporting', ['setReportingFilters']),

    async fetchData() {
      // Get data
      if (this.hasInvalidComparisons || !size(this.baselineIds)) {
        this.isProcessingData = false;
        // Clear graph
        await this.drawGraph([], [], false);
        return;
      }
      this.isProcessingData = true;
      const responseData = await this.fetchReportingData({
        section: this.section,
        reportArea: 'chart',
        productFilters: this.filters[this.section],
      });

      // Process raw data into graph data
      const { priceDataArray, spacebreakArray } = this.processData(responseData);
      this.isProcessingData = false;

      // Check if there is a comparison dataset
      const hasComparison = size(responseData.spacebreaks.comparison) !== 0;

      await this.drawGraph(spacebreakArray, priceDataArray, hasComparison);
    },

    async loadD3Dependencies() {
      d3 = Object.assign(
        {},
        await import('d3-array'),
        await import('d3-axis'),
        await import('d3-brush'),
        await import('d3-dispatch'),
        await import('d3-drag'),
        await import('d3-fetch'),
        await import('d3-force'),
        await import('d3-format'),
        await import('d3-hierarchy'),
        await import('d3-interpolate'),
        await import('d3-path'),
        await import('d3-polygon'),
        await import('d3-scale'),
        await import('d3-selection'),
        await import('d3-shape'),
        await import('d3-transition')
      );
    },

    async drawGraph(spacebreakArray, priceDataArray, hasComparison) {
      await this.loadD3Dependencies();
      if (!size(spacebreakArray) || !d3) return;

      // -- Clean up old graph
      d3.select('#chartarea')
        .select('svg')
        .remove();
      // -- Graph Variables
      // Base width and height for calcs, but graph should auto scale to fit
      const width = 1100;
      const height = 600;

      // Define max points are allowed at each space break for each size
      const circleSizesMaxPoints = {
        3: 100,
        2: 200,
      };

      // Set an offset for the points vs the vertical spacebreak tick mark
      const comparisonPercentOffset = 0.15;

      // Amount to offset the baseline - 0 if no comparison, or offset if there is
      const baseLinePercentOffset = hasComparison ? -comparisonPercentOffset : 0;

      // -- Create chart holder
      const svg = d3
        .select('#chartarea')
        .append('svg')
        .attr('id', 'priceChart')

        // Responsive SVG needs these 2 attributes and no width and height attr
        // This will allow the graph to automatically scale, e.g. for the overview page
        .attr('preserveAspectRatio', 'none')
        .attr('viewBox', `0 0 ${width} ${height}`)
        // Class to make it responsive.
        .classed('svg-content-responsive', true);

      // Pre-create the holders for various parts of the chart, to ensure correct z-index
      svg.append('g').attr('id', 'axis');
      svg.append('g').attr('id', 'datapoints');

      // -- Tooltip setup
      const tooltip = d3.select('#tooltip');

      // Show or hide box as mouse moves over
      const mouseover = function() {
        tooltip.style('display', 'block');
        d3.select(this)
          .style('stroke', 'red')
          .attr('stroke-width', '3');
      };
      const mouseleave = function() {
        tooltip.style('display', 'none');
        d3.select(this).style('stroke', 'none');
      };

      const mousemove = function(e, d) {
        // Need to account for scaling
        const svgDim = svg.node().getBoundingClientRect();
        const leftMousePos = d3.pointer(e)[0] * (svgDim.width / width);
        const topMousePos = d3.pointer(e)[1] * (svgDim.height / height);

        // Move the tooltip and colour it
        // (offset from exact mouse position for ease of visibility)
        tooltip
          .html(`${d.productKeyDisplay} - ${d.price}`)
          .style('left', `${leftMousePos + 25}px`)
          .style('top', `${topMousePos}px`)
          .attr(
            'class',
            d.type === dataType.baseline ? 'tooltip tooltip-baseline' : 'tooltip tooltip-comparison'
          );
      };

      // -- X-axis graph data - spacebreaks
      // Mostly need to get a unique values array, then use d3 to automatically
      // create a point position mapping for these
      const spacebreaks = map(spacebreakArray, 'shortName');

      // Calc separation
      const xSeparation = width / (spacebreaks.length + 1);
      const xCoords = spacebreaks.map((d, i) => xSeparation + i * xSeparation);
      const xScale = d3
        .scaleOrdinal()
        .domain(spacebreaks)
        .range(xCoords);

      // -- Y-axis graph data - prices
      const yScale = d3
        .scaleLinear()
        .domain(d3.extent(priceDataArray.map(d => +d.price)))
        .range([height - 50, 50]); // using 50 just to provide some margin at the top and bottom

      // -- Calculate circle sizes
      // Get counts of points in each spacebreak
      const spacebreakProductsCount = countBy(priceDataArray, prod => {
        return prod.spacebreak;
      });

      // Calculate band that each spacebreak falls into, and create a map for the circle size
      const circleSizeMap = {};
      forOwn(spacebreakProductsCount, (sbpc, sb) => {
        circleSizeMap[sb] = 1;
        if (sbpc < circleSizesMaxPoints[2]) circleSizeMap[sb] = 2;
        if (sbpc < circleSizesMaxPoints[3]) circleSizeMap[sb] = 3;
      });

      // -- Create circles for data points
      svg
        .select('#datapoints')
        .selectAll('.circ')
        .data(priceDataArray)
        .enter()
        .append('circle')
        .attr('class', 'circ')
        .attr('fill', d =>
          d.type === dataType.baseline ? colors.reports.baseline : colors.reports.comparison
        )
        .attr('r', d => circleSizeMap[d.spacebreak])

        // x and y positions. x needs an adjustment to split the baseline and comparison
        .attr(
          'cx',
          d =>
            xScale(d.spacebreak) +
            (d.type === dataType.baseline
              ? xSeparation * baseLinePercentOffset
              : xSeparation * comparisonPercentOffset)
        )
        .attr('cy', d => yScale(d.price))

        // Tooltip
        .on('mouseover', mouseover)
        .on('mousemove', mousemove)
        .on('mouseleave', mouseleave);

      // -- Create axis etc
      // x-axis
      const xaxis = d3
        .scaleOrdinal()
        .domain(spacebreakArray.map(sb => sb.shortName))
        .range(xCoords);
      svg
        .select('#axis')
        .append('g')
        .attr('transform', `translate(0,${height - 40})`)
        .attr('class', 'graphAxis graphXAxis')
        .call(d3.axisBottom(xaxis));

      svg
        .select('#axis')
        .append('text')
        .attr('transform', `translate(${width / 2},${height - 10})`)
        .style('text-anchor', 'middle')
        .attr('class', 'graphAxisLabel graphXAxisLabel')
        .text(this.$tkey('chart.xTitle'));

      // y-axis
      svg
        .select('#axis')
        .append('g')
        .attr('transform', 'translate(60,0)')
        .attr('class', 'graphAxis graphYAxis')
        .call(d3.axisLeft(yScale));

      svg
        .select('#axis')
        .append('text')
        .attr('transform', `translate(10,${height / 2}) rotate(-180)`)
        .style('text-anchor', 'middle')
        .attr('class', 'graphAxisLabel graphYAxisLabel')
        .text(this.$tkey('chart.yTitle'));

      // grid
      d3.selectAll('g.graphXAxis g.tick')
        .append('line')
        .attr('class', 'gridline xgridline')
        .attr('x1', 0)
        .attr('y1', -height + 85)
        .attr('x2', 0)
        .attr('y2', 0);

      d3.selectAll('g.graphYAxis g.tick')
        .append('line')
        .attr('class', 'gridline ygridline')
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', width - 150)
        .attr('y2', 0);

      // -- Create force simulation to 'swarm' the points rather than put them on a linear vertical
      // Tick function to move points
      function tick() {
        d3.selectAll('.circ')
          .attr('cx', d => d.x)
          .attr('cy', d => d.y);
      }

      // Simulation code
      const simulation = d3
        .forceSimulation(priceDataArray)
        .force(
          'x',
          d3
            .forceX(d => {
              return (
                xScale(d.spacebreak) +
                (d.type === dataType.baseline
                  ? xSeparation * baseLinePercentOffset
                  : xSeparation * comparisonPercentOffset)
              );
            })
            .strength(0.2)
        )

        .force(
          'y',
          d3
            .forceY(d => {
              return yScale(d.price);
            })
            .strength(1)
        )

        .force(
          'collide',
          d3.forceCollide(d => {
            return circleSizeMap[d.spacebreak] - 0;
          })
        )

        .alphaDecay(0)
        .alpha(0.3)
        .on('tick', tick);

      // Start simulation
      setTimeout(function() {
        simulation.alphaDecay(0.01);
      }, 3000);
    },

    processData(rawData) {
      // -- Manipulate data into format for the graph
      let priceDataArray = [];
      let spacebreakArray = [];
      const spacebreakSizeArray = [];

      // Loop through the baseline results and add into final array
      forOwn(rawData.prices.baseline, prodArray => {
        prodArray.forEach(prod => {
          priceDataArray.push({
            productKey: prod.productKey,
            productKeyDisplay: prod.productKeyDisplay,
            price: prod.price,
            spacebreak: rawData.spacebreaks.all[prod.currentSpacebreakId].shortName,
            type: dataType.baseline,
          });
        });
      });

      // Loop comparison and add to same array
      forOwn(rawData.prices.comparison, prodArray => {
        prodArray.forEach(prod => {
          priceDataArray.push({
            productKey: prod.productKey,
            productKeyDisplay: prod.productKeyDisplay,
            price: prod.price,
            spacebreak: rawData.spacebreaks.all[prod.currentSpacebreakId].shortName,
            type: dataType.comparison,
          });
        });
      });

      // Create both a clean object for spacebreaks, and a quick reference id array by size
      const sortedSpaceBreaks = sortBy(rawData.spacebreaks.all, 'fillOverride');
      forOwn(sortedSpaceBreaks, sb => {
        // Don't duplicate spacebreaks if they are the same size
        if (!some(spacebreakArray, sba => sba.shortName === sb.shortName)) {
          spacebreakArray.push({
            shortName: sb.shortName,
            fillOverride: sb.fillOverride,
          });
          spacebreakSizeArray.push(sb.shortName);
        }
      });
      spacebreakArray = sortBy(spacebreakArray, 'fillOverride');

      // Group up the products by spacebreak
      const productsPerSpacebreak = groupBy(priceDataArray, 'spacebreak');

      // -- Russian doll the data
      let previousProducts = [];
      for (let i = 0; i < spacebreakSizeArray.length; i += 1) {
        const thisSpacebreak = spacebreakSizeArray[i];
        // Can't russian doll the first sb
        if (i > 0) {
          // Get products in previous spacebreak and add to all the previously added products too
          const previousSpacebreak = spacebreakSizeArray[i - 1];
          const productsInPreviousSpacebreak = productsPerSpacebreak[previousSpacebreak];
          if (productsInPreviousSpacebreak) {
            previousProducts = previousProducts.concat(productsInPreviousSpacebreak);
          }

          // Update previous sb products sb id with the new one
          const previousProductsForAdding = previousProducts.map(el => ({
            ...el,
            spacebreak: thisSpacebreak,
          }));
          // Then add back into main array with the new sb id
          priceDataArray = priceDataArray.concat(previousProductsForAdding);
        }
      }

      return { priceDataArray, spacebreakArray };
    },

    handleFilterChange(filters) {
      this.setReportingFilters({ section: this.section, filters });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.not-available {
  margin: auto;
}

#chartarea {
  display: inline-block;
  position: relative;
  margin: 0 auto;
  vertical-align: top;
  overflow: hidden;
}

.chartarea-sidebar-shown {
  width: 90%;
}

.chartarea-sidebar-hidden {
  width: 70%;
}

.tooltip {
  display: none;
  border: solid 1px white;
  position: absolute;
  padding: 5px;
}

.tooltip-baseline {
  background-color: $baseline-tooltip;
}
.tooltip-comparison {
  background-color: $comparison-tooltip;
}

// For auto rescaling
.svg-content-responsive {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 0;
}

::v-deep {
  .graphAxis {
    g line {
      stroke: $reporting-section-border;
    }

    path {
      stroke: $reporting-section-border;
    }
  }

  .graphAxisLabel {
    font-weight: bold;
    font-size: 13px;
  }

  .graphYAxisLabel {
    writing-mode: vertical-lr;
  }

  .xgridline {
    stroke: $graph-grid !important;
  }

  .ygridline {
    stroke: lighten($graph-grid, 10%) !important;
  }
}
</style>
