import { orderBy } from 'lodash';

function getLeafNodes(nodes = [], result = []) {
  for (let i = 0; i < nodes.length; i += 1) {
    if (!nodes[i].children.length) {
      result.push(nodes[i]);
    } else {
      result = getLeafNodes(nodes[i].children, result);
    }
  }
  return orderBy(result, ['order']);
}

export default {
  getLeafNodes,
};
