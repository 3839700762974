<template>
  <v-snackbar
    v-model="show"
    class="main-snackbar"
    :color="color"
    :timeout="clientConfig.popupTimeout"
    top
  >
    {{ /* AOV3-823 TODO: move snackbar to bottom to match pricing? */ }}
    <div class="notification-wrapper w-100">
      {{ message }}
    </div>
    <v-btn text @click="show = false">{{ $t('actions.close') }}</v-btn>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      show: false,
      message: '',
      color: '',
    };
  },

  computed: {
    ...mapState('context', ['clientConfig']),
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'snackbar/showSnackbar') {
        this.message = state.snackbar.content;
        this.color = state.snackbar.color;
        this.show = true;
      }
    });
  },
};
</script>

<style lang="scss">
.notification-wrapper {
  word-wrap: break-word;
  font-size: 1.2rem;
}
.main-snackbar {
  // must be higher than main-navbar z-index to be displayed
  z-index: 210 !important;
}
</style>
