import { mapGetters, mapActions } from 'vuex';

export const notesMixin = context => ({
  data() {
    return { isNotesLoading: false };
  },

  computed: {
    ...mapGetters('notes', ['getNotes']),

    getAllNotes() {
      const { id } = this.getNotesParam;
      return this.getNotes({ id, context });
    },
  },

  methods: {
    ...mapActions('notes', ['fetchNotes', 'postNote', 'deleteNote', 'updateNote']),

    async postNewNote({ message }) {
      this.isNotesLoading = true;
      await this.postNote({ params: { ...this.getNotesParam, message, context } });
      this.isNotesLoading = false;
    },

    async fetchAllNotes() {
      this.isNoteLoading = true;
      const { id } = this.getNotesParam;
      await this.fetchNotes({
        params: {
          id,
          context,
        },
      });
      this.isNoteLoading = false;
    },

    async deleteCurrentNote({ noteId }) {
      this.isNoteLoading = true;
      const { id } = this.getNotesParam;
      await this.deleteNote({
        params: {
          id,
          context,
          noteId,
        },
      });
      this.isNoteLoading = false;
    },

    async updateCurrentNote({ message, note }) {
      this.isNoteLoading = true;
      const { id } = this.getNotesParam;

      await this.updateNote({
        params: {
          id,
          context,
          message,
          noteId: note._id,
        },
      });
      this.isNoteLoading = false;
    },
  },
});

export default notesMixin;
