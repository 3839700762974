<template>
  <v-dialog id="mapFieldsModal" :value="value" width="750px" persistent>
    <dialog-card v-if="value" :title="$tkey('title')" @close="closeModal">
      <v-container class="map-fields-list pa-1">
        <p
          class="pl-3 mt-2 map-fields-list__subtitle"
          :class="[enableFieldCreation ? 'mb-0' : 'mb-1']"
        >
          {{ $tkey(isResetFromTemplate ? 'resetFromTemplate.subtitle' : 'subtitle') }}
        </p>
        <p v-if="enableFieldCreation" class="pl-3 mb-1 map-fields-list__subtitle">
          {{ $tkey('createNewColumnsMessage') }}
        </p>

        <div class="mb-2 d-block">
          <download-link v-if="!isResetFromTemplate && downloadFileUrl" :url="downloadFileUrl">
            <v-btn small text color="primary">
              {{ $t('mapFields.uploadedFile') }}
            </v-btn>
          </download-link>
        </div>

        <v-divider />

        <v-alert v-if="hasUnmappedRequiredFields" type="error" text class="ma-2">
          {{ $tkey('missingRequiredColumnsError') }}:
          <span>{{ unmappedRequiredFields.join(', ') }}</span>
        </v-alert>

        <v-form v-model="valid" autocomplete="off" @submit.prevent>
          <v-list dense class="mt-2">
            <v-list-item class="divider">
              <v-col cols="4" class="pl-2 pb-1 font-weight-bold">
                {{ $tkey('uploadedColumns') }}
              </v-col>
              <v-col cols="8" class="pl-2 pb-1 font-weight-bold">
                {{ $tkey('actions') }}
              </v-col>
            </v-list-item>
            <map-fields-row
              v-for="(field, index) in uploadedFields"
              :key="`${field.key}-${index}`"
              :field="field"
              :upload-metadata="uploadMetadata"
              :fields-options="fieldsOptions"
              :fields-to-ignore="fieldsToIgnore"
              :mappings="mappings"
              @update-mapping="updateMapping"
            />
          </v-list>
        </v-form>
      </v-container>
      <template v-slot:footer>
        <page-actions
          :has-data-errors="hasDataErrors"
          :has-data-changes="hasDataChanges"
          :show-discard="false"
          save-btn-text="confirm"
          @save="confirmMappings"
        >
          <template v-slot:right-btns>
            <v-btn class="text-outline" text link @click="closeModal">
              {{ $t('actions.cancel') }}
            </v-btn>
          </template>
        </page-actions>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { find, omit, partition, some } from 'lodash';

export default {
  localizationKey: 'mapFieldsModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },

    currentCustomFields: {
      type: Array,
      required: false,
      default: () => [],
    },

    fieldsToIgnore: {
      type: Array,
      required: false,
      default: () => [],
    },

    downloadFileUrl: {
      type: String,
      required: false,
      default: '',
    },

    uploadedDataDetails: {
      type: Object,
      required: false,
      default: () => null,
    },

    uploadMetadata: {
      type: Object,
      required: false,
      default: () => {},
    },

    enableFieldCreation: {
      type: Boolean,
      required: false,
      default: false,
    },

    isResetFromTemplate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mappings: [],
      valid: true,
    };
  },
  computed: {
    autoMappedFields() {
      if (!this.uploadedDataDetails) return [];

      // Uploaded fields that match an existing fixed column
      const autoMappedFields = this.uploadedDataDetails.mappings;

      // If there are no required fields, return mappings
      if (!this.requiredFields.length) return autoMappedFields;

      // Split the fields that are required columns into a separate array
      // so that they can be positioned before other fields in the table
      const [requiredFields, fields] = partition(autoMappedFields, field =>
        some(this.requiredFields, rf => rf.mongoField === field.mongoField)
      );
      return [...requiredFields, ...fields];
    },

    uploadedCustomFields() {
      // Uploaded fields that do not automatically map to a column could map to a custom attribute
      return this.uploadedDataDetails
        ? this.uploadedDataDetails.unmappedFields.filter(field =>
            this.currentCustomFields.includes(field.key)
          )
        : [];
    },

    unmappedFields() {
      if (!this.uploadedDataDetails) return [];
      // Unmapped fields that do not match custom attributes are new fields
      const newFields = this.uploadedDataDetails.unmappedFields.filter(
        field => !this.currentCustomFields.includes(field.key)
      );
      // When the creation of new fields is enabled, assign property so that new fields can be identified
      if (this.enableFieldCreation)
        newFields.forEach(field => Object.assign(field, { isNew: true }));

      return newFields;
    },

    uploadedFields() {
      // All uploaded fields
      return [...this.autoMappedFields, ...this.uploadedCustomFields, ...this.unmappedFields];
    },

    unmappedExpectedFields() {
      // Formats and returns the existing fixed columns that were not included in the CSV upload
      return this.uploadedDataDetails
        ? this.uploadedDataDetails.unmappedExpectedFields.map(field => {
            return {
              key: this.$t(field.translationKey),
              selectedField: this.$t(field.translationKey),
              ...field,
            };
          })
        : [];
    },

    unmappedCustomFields() {
      // Filters the uploaded custom fields from the total list of custom fields
      return this.currentCustomFields
        ? this.currentCustomFields
            .filter(
              field =>
                !this.uploadedCustomFields.some(({ selectedField }) => selectedField === field)
            )
            .map(field => {
              return { key: field, selectedField: field };
            })
        : [];
    },

    requiredFields() {
      return this.uploadedDataDetails ? this.uploadedDataDetails.requiredFields : [];
    },

    unmappedRequiredFields() {
      const unmappedRequiredFields = [];
      // If the mappings array does not contain mongoField for a required field,
      // it means that the field is unmapped and it's name is pushed to an array
      this.requiredFields.forEach(field => {
        if (!some(this.mappings, m => m.mongoField === field.mongoField)) {
          unmappedRequiredFields.push(this.$t(field.translationKey));
        }
      });
      return unmappedRequiredFields;
    },

    hasUnmappedRequiredFields() {
      return this.unmappedRequiredFields.length > 0;
    },

    actionOptions() {
      const createNewOption = { selectedField: this.$tkey('createNew') };
      const ignoreColumnOption = { selectedField: this.$tkey('ignoreColumn') };
      const divider = { divider: true };
      // Should always have the ability to ignore a column
      const options = [ignoreColumnOption, divider];
      // Only provide the create new option if field creation is enabled
      if (!this.enableFieldCreation) return options;
      return [createNewOption, ...options];
    },

    fieldsOptions() {
      // Ensures that the option text matches the column name displayed in the table
      const fixedColumns = this.autoMappedFields.map(field => {
        return {
          ...field,
          selectedField: this.$t(field.translationKey),
        };
      });

      return [
        ...this.actionOptions,
        ...fixedColumns,
        ...this.unmappedExpectedFields,
        ...this.uploadedCustomFields,
        ...this.unmappedCustomFields,
      ];
    },

    hasDataChanges() {
      return true;
    },

    hasDataErrors() {
      return !this.valid || this.hasUnmappedRequiredFields;
    },
  },

  created() {
    this.mappings = this.uploadedFields.map(field => ({
      ...omit(field, 'translationKey'),
    }));
  },

  methods: {
    updateMapping(newMapping) {
      const { key, selectedField, mongoField, isNew, isDeleting } = newMapping;
      const existingMapping = find(this.mappings, { key });

      if (existingMapping) {
        const mappingIndex = this.mappings.findIndex(m => m.key === key);
        // Delete mapping if it already exists, and it has been set to be ignored
        if (isDeleting) {
          this.$delete(this.mappings, mappingIndex);
          return;
        }
        // Otherwise, update existing mapping with new values
        Object.assign(existingMapping, { selectedField, mongoField, isNew });
        this.$set(this.mappings, mappingIndex, existingMapping);
      } else if (!isDeleting) {
        // If the mapping does not already exist, and it should not be ignored, add it to the
        // mappings array
        this.mappings.push(newMapping);
      }
    },

    confirmMappings() {
      this.$emit('confirm', this.mappings);
    },

    closeModal() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.container {
  font-size: 1.2rem;
}

.divider {
  border-bottom: 1px solid $assortment-menu-border-colour;
}

::v-deep {
  .dialog-card__content {
    max-height: 70vh;
  }

  .v-alert {
    padding: 10px;

    &__content {
      font-size: 1.3rem;
    }

    &__icon {
      align-self: center;
    }
  }
}

.map-fields-list {
  &__subtitle {
    max-width: 75%;
  }

  .v-list {
    padding: 0;
  }

  .v-list-item {
    padding-left: 6px;
    padding-right: 0;
    min-height: auto;

    &:nth-child(2n + 2) {
      background: $assortment-list-item-bg-colour !important;

      ::v-deep {
        .v-input {
          .v-input__slot,
          .v-select__slot {
            background: $assortment-input-background !important;
          }
        }
      }
    }
  }
}
</style>
