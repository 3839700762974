<template>
  <div class="comparison-body">
    <v-container v-if="loaded" fluid style="min-width: max-content">
      <v-row no-gutters>
        <v-col cols="auto" class="comparisons">
          <highcharts :options="chartOptionsClusterNames" />
        </v-col>
        <v-divider vertical />
        <v-col cols="auto">
          <highcharts :options="chartOptionsFormat" />
        </v-col>
        <v-divider vertical />
        <v-col cols="auto">
          <highcharts :options="chartOptionsProfile" />
        </v-col>
        <v-divider vertical />
      </v-row>
    </v-container>
    <v-container v-if="loaded && comparisonSchemeId" fluid style="min-width: max-content">
      <v-row no-gutters>
        <v-col cols="auto" class="comparisons">
          <highcharts :options="comparisonChartOptionsClusterNames" />
        </v-col>
        <v-divider vertical />
        <v-col cols="auto">
          <highcharts :options="comparisonChartOptions" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { merge } from 'lodash/fp';
import { intersection, sum, sumBy, sortBy } from 'lodash';
import Highcharts from 'highcharts';
import heatmap from 'highcharts/modules/heatmap';
import colors from '../../../../ow-colors';

let globalVm;
heatmap(Highcharts);

export default {
  localizationKey: 'clusteringPage',
  name: 'Comparison',
  data() {
    return {
      // These two values have been chosen emperically to roughly make the cell heights
      // always the same
      heightMultiplier: 60,
      heightExtra: -30,
      chartOptionsClusterNames: null,
      chartOptionsFormat: null,
      chartOptionsProfile: null,
      comparisonChartOptionsClusterNames: null,
      comparisonChartOptions: null,
      defaultChartOptions: {
        chart: {
          type: 'heatmap',
          marginTop: 90,
          marginBottom: 40,
          plotBorderWidth: 0,
        },
        credits: {
          enabled: false,
        },
        yAxis: {
          visible: false,
          reversed: true,
        },
      },
      chartOptions: {
        legend: {
          align: 'left',
          layout: 'horizontal',
          symbolWidth: 0,
          squareSymbol: false,
          padding: 0,
          itemDistance: 1,
          verticalAlign: 'bottom',
          symbolPadding: 0,
          useHTML: true,
        },
        dataClasses: [
          { from: 0, to: 1e-9, name: '0%' },
          { from: 1e-9, to: 25, name: '>0-25%' },
          { from: 25, to: 50, name: '25-50%' },
          { from: 50, to: 75, name: '50-75%' },
          { from: 75, to: 100, name: '75-100%' },
        ],
      },
      loaded: false,
    };
  },

  computed: {
    ...mapState('clustering', ['selectedScheme', 'comparisonSchemeId']),
    ...mapGetters('clustering', ['getScenarioClusterSchemes']),
    ...mapGetters('scenarios', ['stores']),
  },

  watch: {
    comparisonSchemeId() {
      this.setupComparisonCharts();
    },
  },

  created() {
    globalVm = this;
    this.$nextTick(() => {
      const schemeId = this.selectedScheme._id;
      this.chartOptionsClusterNames = this.getchartOptionsClusterNames(schemeId);
      this.chartOptionsFormat = this.getChartOptions('formatDescription', schemeId);
      this.chartOptionsProfile = this.getChartOptions('storeProfile', schemeId);

      if (this.comparisonSchemeId === this.selectedScheme._id) {
        this.setComparisonSchemeId(null);
      } else {
        this.setupComparisonCharts();
      }
      this.loaded = true;
    });
  },

  methods: {
    ...mapMutations('clustering', ['setComparisonSchemeId']),

    setupComparisonCharts() {
      if (!this.comparisonSchemeId) return;
      this.comparisonChartOptions = this.getChartOptions(
        'clustersComparison',
        this.comparisonSchemeId
      );
      this.comparisonChartOptionsClusterNames = this.getchartOptionsClusterNames(
        this.selectedScheme._id
      );
    },

    getClusterNames(schemeId) {
      const scheme = this.getScenarioClusterSchemes.find(sc => sc._id === schemeId);
      const uniqueClusterNames = scheme.clusters.reduce((set, cluster) => {
        set.add(cluster.clusterName);
        return set;
      }, new Set());
      const names = [...uniqueClusterNames].sort();
      return names.concat(this.$tkey('tabs.comparison.total'));
    },

    getClusterNamesIncludingTotal(schemeId) {
      return this.getClusterNames(schemeId);
    },

    getClusterNamesChartWidth(schemeId) {
      // Try and set a decent size for the width of the clustering names chart
      // The numbers of 50 and 5 were chosen emperically and are so that we get a good value
      // for any set of cluster names
      return (
        50 + 5 * Math.max(...this.getClusterNamesIncludingTotal(schemeId).map(name => name.length))
      );
    },

    getchartOptionsClusterNames(schemeId) {
      const clusterNamesIncludingTotal = this.getClusterNames(schemeId);
      const chartOptions = {
        ...this.defaultChartOptions,
        title: null,
        colorAxis: { showInLegend: false },
        tooltip: { enabled: false },
        series: [
          {
            showInLegend: false,
            borderWidth: 10,
            borderColor: colors.owWhite,
            data: clusterNamesIncludingTotal.map((clusterName, index) => {
              return { x: 0, y: index, va: clusterName, color: colors.owWhite };
            }),
            dataLabels: {
              enabled: true,
              style: {
                color: 'contrast',
                fontWeight: 'normal',
                textOutline: 'none',
              },
              format: '{point.va}',
            },
          },
        ],
      };
      const overrides = {
        chart: {
          width: this.getClusterNamesChartWidth(schemeId),
          height:
            // Try to set height so cell heights stay consistent
            this.heightMultiplier * clusterNamesIncludingTotal.length +
            this.defaultChartOptions.chart.marginTop +
            this.defaultChartOptions.chart.marginBottom +
            this.heightExtra,
        },
        xAxis: { visible: false },
      };
      return merge(chartOptions, overrides);
    },

    getSeries(data) {
      return {
        borderWidth: 3,
        borderColor: colors.owWhite,
        data,
        dataLabels: {
          enabled: true,
          formatter() {
            return String(this.point.count);
          },
          style: {
            color: 'contrast',
            fontWeight: 'normal',
            textOutline: 'none',
          },
        },
        cursor: 'pointer',
        states: {
          hover: {
            enabled: false,
          },
        },
      };
    },

    calculateComparisonData(comparisonSchemeId) {
      const originalScheme = this.selectedScheme;
      const comparisonScheme = this.getScenarioClusterSchemes.find(
        s => s._id === comparisonSchemeId
      );

      const resultData = [];
      // sort by name here must be aligned with the sort on getChartData
      const sortedCurrentSchemeClusters = sortBy(originalScheme.clusters, 'clusterName');
      const sortedComparisonSchemeClusters = sortBy(comparisonScheme.clusters, 'clusterName');
      sortedCurrentSchemeClusters.forEach((originalSchemeDataRow, originalRowIndex) => {
        const rowData = [];
        sortedComparisonSchemeClusters.forEach((comparisonSchemeDataRow, comparisonRowIndex) => {
          const interLength = intersection(
            originalSchemeDataRow.storeKeys,
            comparisonSchemeDataRow.storeKeys
          ).length;
          rowData.push({ y: originalRowIndex, x: comparisonRowIndex, count: interLength });
        });
        // calculate values based on the new counts
        const row = rowData.filter(r => r.y === originalRowIndex);
        const rowSum = sumBy(row, 'count');
        const results = row.map(v => {
          const value = (v.count / rowSum) * 100;
          return { ...v, ...{ value } };
        });
        results.forEach(r => {
          resultData.push(r);
        });
      });
      const returnValue = {
        data: resultData,
        clusterNames: sortedCurrentSchemeClusters.map(c => c.clusterName),
        fieldValues: sortedComparisonSchemeClusters.map(c => c.clusterName),
        totalsData: [],
      };

      // add sum rows
      let totalRowSum = 0;
      returnValue.clusterNames.forEach((name, index) => {
        const rowValues = returnValue.data.filter(row => row.y === index);
        const totalIndex = Math.max(...rowValues.map(r => r.x)) + 1;
        const rowSum = sumBy(rowValues, 'count');
        totalRowSum += rowSum;
        returnValue.totalsData.push({
          y: index,
          x: totalIndex,
          va: rowSum,
        });
      });

      returnValue.fieldValues.forEach((name, index) => {
        const rowValues = returnValue.data.filter(row => row.x === index);
        const totalIndex = Math.max(...rowValues.map(r => r.y)) + 1;
        const colSum = sumBy(rowValues, 'count');
        returnValue.totalsData.push({
          y: totalIndex,
          x: index,
          va: colSum,
        });
      });

      returnValue.totalsData.push({
        x: returnValue.fieldValues.length,
        y: returnValue.clusterNames.length,
        va: totalRowSum,
      });

      return returnValue;
    },

    getChartOptions(field, schemeId) {
      const chartData =
        field === 'clustersComparison'
          ? this.calculateComparisonData(schemeId)
          : this.getChartData(field, schemeId);
      const title = this.$tkey(`tabs.comparison.subTitles.${field}`);
      const chartOptions = {
        ...this.defaultChartOptions,
        title: {
          align: 'left',
          floating: false,
          text: `${title}:`,
        },
        legend: {
          floating: true,
          ...this.chartOptions.legend,
          labelFormatter() {
            return `<div style="width:48px;margin-right:2px;height:19px;display:flex;justify-content:center;align-items:center;background:${
              this.color
            };"><span style="font-size:1rem;letter-spacing: -0.5px;margin-bottom: 1px; color: black;font-weight:normal;">${
              this.name
            }</span></div>`;
          },
        },
        colorAxis: {
          min: 0,
          minColor: {
            storeProfile: colors.owWhite,
            formatDescription: colors.owWhite,
            clustersComparison: colors.owWhite,
          }[field],
          maxColor: {
            storeProfile: colors.chartColor6,
            formatDescription: colors.chartColor5,
            clustersComparison: colors.chartColor7,
          }[field],
          dataClasses: this.chartOptions.dataClasses,
          showInLegend: true,
        },
        tooltip: {
          formatter() {
            return globalVm.formatNumber({ number: this.point.value, format: 'float' });
          },
          backgroundColor: colors.owWhite,
          borderWidth: 0,
        },
        series: [
          this.getSeries(chartData.data),
          {
            borderColor: colors.owWhite,
            data: chartData.totalsData.map(point => ({ ...point, color: colors.owWhite })),
            dataLabels: {
              enabled: true,
              style: {
                color: 'contrast',
                fontWeight: 'normal',
                textOutline: 'none',
              },
              format: '{point.va}',
            },
          },
        ],
      };
      const elWidth = document.getElementById('clustering-tab-content').getBoundingClientRect()
        .width;
      const xAxisSizes = this.getChartXAxisSizes();
      let chartWidth;
      /* Calculate the width in a way that roughly attempts to have cell widths
       * that look good no matter how many number of xAxis items we have.
       * Check if one of the charts has just 1 column. Because if so we must set a
       * minimum size to make the legend render correctly
       */
      if (Math.min(...xAxisSizes) === 1) {
        chartWidth = 145 * (chartData.fieldValues.length + 1);
      } else {
        // Add + 2 because each chart has 1 extra 'Total' column
        // Add + 1 because of the total column
        // Take the width of the cluster names chart and 30 extra for vertical dividers away from
        // the containing element width
        chartWidth =
          ((elWidth - this.getClusterNamesChartWidth(schemeId) - 30) / (sum(xAxisSizes) + 2)) *
          (chartData.fieldValues.length + 1);
      }
      const overrides = {
        chart: {
          width: chartWidth,
          height:
            // Try to set height so cell heights stay consistent
            this.heightMultiplier * (chartData.clusterNames.length + 1) +
            this.defaultChartOptions.chart.marginTop +
            this.defaultChartOptions.chart.marginBottom +
            this.heightExtra,
        },
        xAxis: {
          max: chartData.fieldValues.length - 0.25,
          opposite: true,
          lineColor: colors.owWhite,
          tickLength: 0,
          labels: {
            style: {
              color: colors.owBlack,
            },
          },
          categories: chartData.fieldValues.concat(this.$tkey('tabs.comparison.total')),
        },
      };
      return merge(chartOptions, overrides);
    },
    getScenarioStoresInClusteringScheme() {
      const storeKeysInClusteringScheme = new Set(
        [].concat(...this.selectedScheme.clusters.map(cluster => cluster.storeKeys))
      );
      return this.stores.reduce((arr, store) => {
        // Scheme might not have all stores in workpackage due to not for analysis or
        // other reasons => filter these out
        if (storeKeysInClusteringScheme.has(store.storeKey)) {
          arr.push(store);
        }
        return arr;
      }, []);
    },
    getChartXAxisSizes() {
      const formatDescriptions = new Set();
      const storeProfiles = new Set();
      this.getScenarioStoresInClusteringScheme().forEach(store => {
        formatDescriptions.add(store.formatDescription);
        storeProfiles.add(store.storeProfile);
      });
      return [formatDescriptions.size, storeProfiles.size];
    },

    getChartData(storeField, schemeId) {
      const scheme = this.getScenarioClusterSchemes.find(sc => sc._id === schemeId);
      // storeField is one of storeProfile or formatDescription

      // Get the mapping of each storeField to the storeKeys it's associated with
      const fieldValueStoreKeysMap = this.getScenarioStoresInClusteringScheme().reduce(
        (obj, store) => {
          const value = store[storeField];
          if (!obj[value]) {
            obj[value] = [];
          }
          obj[value].push(store.storeKey);
          return obj;
        },
        {}
      );

      // Get the mapping of each clusterName to the storeKeys it's associated with
      const clusterNameStoreKeysMap = scheme.clusters.reduce((obj, cluster) => {
        obj[cluster.clusterName] = cluster.storeKeys;
        return obj;
      }, {});

      // Create some things we need
      const data = [];
      // This sort by name needs to be kept in sync with comparison chart sort
      const clusterNames = Object.keys(clusterNameStoreKeysMap).sort(); // Sort is important
      const fieldValues = Object.keys(fieldValueStoreKeysMap).sort(); // Sort is important
      const totalsData = [];
      const totalsRight = Array.from(Array(clusterNames.length), () => 0);
      fieldValues.forEach((field, x) => {
        let totalBottom = 0;
        clusterNames.forEach((clusterName, y) => {
          const count = intersection(
            clusterNameStoreKeysMap[clusterName],
            fieldValueStoreKeysMap[field]
          ).length;
          data.push({ x, y, count });
          totalBottom += count;
          totalsRight[y] += count;
        });
        totalsData.push({ x, y: clusterNames.length, va: totalBottom });
      });

      // We need create a density, i.e. the count divided by the total
      data.forEach(point => {
        point.value = Number(((point.count / totalsRight[point.y]) * 100).toFixed(4));
      });

      // Add the totals on the right to the set of totals data
      totalsRight.forEach((total, y) => {
        totalsData.push({ x: fieldValues.length, y, va: total });
      });
      const totalCountAllStores = sum(totalsRight);
      // Add the total total, i.e. the total count of all stores
      totalsData.push({
        x: fieldValues.length,
        y: clusterNames.length,
        va: totalCountAllStores,
      });

      // Create the totals density, i.e. the total divided by the total total
      totalsData.forEach(point => {
        point.value = Number(((point.va / totalCountAllStores) * 100).toFixed(4));
      });

      return { clusterNames, fieldValues, data, totalsData };
    },
  },
};
</script>

<style lang="scss" scoped>
.comparison-body {
  overflow-x: auto;
  height: 100%;
}
</style>
