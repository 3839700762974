import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCol,
    {
      staticClass:
        "attributes-column pa-0 d-flex flex-column justify-space-between",
    },
    [
      _c(
        VContainer,
        { staticClass: "ma-0 pa-0 controls" },
        [
          _c(
            VRow,
            {
              staticClass:
                "d-flex justify-start align-center heading-row pt-3 pb-3",
              attrs: { "no-gutters": "" },
            },
            [
              _c(VCol, { staticClass: "pl-3", attrs: { cols: 7 } }, [
                _c("h4", [_vm._v(_vm._s(_vm.cdtType))]),
              ]),
              _vm._v(" "),
              _c(
                VCol,
                { staticClass: "pa-0", attrs: { cols: 5 } },
                [
                  _c(
                    VRow,
                    { staticClass: "selectHeader flex-row flex-1" },
                    [
                      _c("h4", [_vm._v(_vm._s(_vm.$tkey("cdtList.select")))]),
                      _vm._v(" "),
                      _c(VCheckbox, {
                        staticClass: "ma-0 pa-0 pl-1 pr-1 rtls-checkbox",
                        attrs: {
                          disabled: _vm.selectedControlDisabled,
                          "input-value": _vm.selectedCdt,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.markAsSelectedCdt(_vm.cdt._id)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "pl-1 pr-1 ml-1 pa-0 ma-0 trash-wrapper",
                        },
                        [
                          _vm.isUserModified
                            ? _c(
                                VIcon,
                                {
                                  staticClass: "mt-0",
                                  attrs: {
                                    disabled: _vm.disabled || _vm.selectedCdt,
                                    size: "22",
                                  },
                                  on: { click: _vm.openDeleteCdtDialog },
                                },
                                [_vm._v("\n              $trash\n            ")]
                              )
                            : _c("div", { staticClass: "pl-1 pr-1" }, [
                                _c("hr", { staticClass: "separator" }),
                              ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(VDivider),
        ],
        1
      ),
      _vm._v(" "),
      _vm.selectedCannGroup
        ? _c("div", { staticClass: "attributes pl-2 pr-2" }, [
            _c(
              "div",
              { staticClass: "attribute-list-group mb-3 mt-3" },
              [
                _c(
                  "h4",
                  [
                    _c(
                      VRow,
                      { staticClass: "pa-0 ma-0 header" },
                      [
                        _c(
                          VCol,
                          {
                            staticClass: "text-left pl-1 pr-0",
                            attrs: { cols: 6 },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tkey("cdtList.relativeIndex")) +
                                ": " +
                                _vm._s(_vm.relativeIndex) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          VCol,
                          {
                            staticClass: "text-right pl-0 pr-0 align-right",
                            attrs: { cols: 3 },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tkey("cdtList.coefficient")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          VCol,
                          {
                            staticClass: "text-right pl-0 pr-1 align-right",
                            attrs: { cols: 3 },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tkey("cdtList.rank")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "attrs-with-exp-power" },
                  [
                    !_vm.cdtGeneratedAttributes.length
                      ? _c(VAlert, { attrs: { type: "info", text: "" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$tkey(
                                  "cdtList.noAttributesWithExplanatoryPower"
                                )
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "draggable",
                      {
                        attrs: {
                          options: { disabled: _vm.disabled },
                          group: _vm.group,
                          "ghost-class": "dragged",
                          draggable: ".draggable",
                        },
                        model: {
                          value: _vm.cdtGeneratedAttributes,
                          callback: function ($$v) {
                            _vm.cdtGeneratedAttributes = $$v
                          },
                          expression: "cdtGeneratedAttributes",
                        },
                      },
                      [
                        _vm._l(_vm.cdtGeneratedAttributes, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.attributeId,
                              staticClass:
                                "d-flex align-center justify-start attribute-item",
                              class: {
                                draggable: !_vm.disabled && _vm.isUserModified,
                                "drag-disabled": _vm.disabled,
                              },
                              attrs: { index: item.attributeId },
                            },
                            [
                              !_vm.isUserModified
                                ? _c(VCheckbox, {
                                    staticClass: "ml-2 mr-0 rtls-checkbox",
                                    attrs: {
                                      disabled: _vm.disabled,
                                      "input-value": item.selected,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateSelected(item, $event)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "h4",
                                { staticClass: "pb-0 full-row flex-1" },
                                [
                                  _c(
                                    VRow,
                                    { staticClass: "pa-0 ma-0 align-center" },
                                    [
                                      _c(
                                        VCol,
                                        {
                                          staticClass:
                                            "text-left pl-1 pr-0 attr-name",
                                          class: { "pl-2": _vm.isUserModified },
                                          attrs: { cols: 6 },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.name) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        VCol,
                                        {
                                          staticClass: "text-right pl-0",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.formatCoefficient(
                                                  item.coefficient
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        VCol,
                                        {
                                          staticClass:
                                            "text-right pl-0 pr-1 rank",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          item.rank
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "rank-circle mr-1",
                                                },
                                                [_vm._v(_vm._s(item.rank))]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.isUserModified
                                            ? _c(
                                                VIcon,
                                                { attrs: { size: "10" } },
                                                [
                                                  _vm._v(
                                                    "\n                    $cross-move\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _vm.isUserModified
                          ? _c("div", {
                              staticClass: "item list-group__drop",
                              attrs: { slot: "footer" },
                              slot: "footer",
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pt-2 pb-0 d-flex justify-end flex-wrap" },
                  [
                    _vm.isUserModified
                      ? _c(
                          VBtn,
                          {
                            staticClass: "mr-2 mb-2",
                            attrs: {
                              disabled:
                                !_vm.cdtGeneratedAttributes.length ||
                                _vm.loading,
                              secondary: "",
                              depressed: "",
                            },
                            on: { click: _vm.removeAllModifiedAttributes },
                          },
                          [_vm._v(_vm._s(_vm.$tkey("removeAll")))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      VBtn,
                      {
                        staticClass: "mr-2 mb-2",
                        attrs: {
                          disabled: _vm.loading,
                          secondary: "",
                          depressed: "",
                        },
                        on: { click: _vm.openTreeView },
                      },
                      [_vm._v(_vm._s(_vm.$tkey("cdtList.viewTree")))]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(VDivider),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "attribute-list-group mt-3 mb-3 attrs-without-exp-power",
              },
              [
                !_vm.selectedScenarioAttributes.length
                  ? _c(VAlert, { attrs: { type: "info", text: "" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$tkey(
                            "cdtList.noAttributesWithoutExplanatoryPower"
                          )
                        )
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mb-2" },
                  [
                    _c(
                      "draggable",
                      {
                        attrs: {
                          options: { disabled: _vm.disabled },
                          group: _vm.group,
                          draggable: ".draggable",
                          "ghost-class": "dragged",
                        },
                        model: {
                          value: _vm.selectedScenarioAttributes,
                          callback: function ($$v) {
                            _vm.selectedScenarioAttributes = $$v
                          },
                          expression: "selectedScenarioAttributes",
                        },
                      },
                      [
                        _vm._l(_vm.selectedScenarioAttributes, function (item) {
                          return _c(
                            "div",
                            {
                              key: item.attributeId,
                              staticClass:
                                "d-flex align-center justify-start attribute-item",
                              class: {
                                "attr-greyed-out": !_vm.isUserModified,
                                draggable: !_vm.disabled && _vm.isUserModified,
                                "drag-disabled": _vm.disabled,
                              },
                              attrs: { index: item.attributeId },
                            },
                            [
                              !_vm.isUserModified
                                ? _c(VCheckbox, {
                                    staticClass: "ml-2 mr-0 rtls-checkbox",
                                    attrs: {
                                      disabled: _vm.disabled,
                                      "input-value": item.selected,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateSelected(item, $event)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "h4",
                                {
                                  staticClass: "pb-0 full-row",
                                  class: { "pl-2": _vm.isUserModified },
                                },
                                [
                                  _c(
                                    VRow,
                                    { staticClass: "pa-0 ma-0" },
                                    [
                                      _c(
                                        VCol,
                                        {
                                          staticClass:
                                            "text-left pl-1 pr-0 attr-name",
                                          class: { "pl-2": _vm.isUserModified },
                                          attrs: { cols: 6 },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item.name) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        VCol,
                                        {
                                          staticClass: "text-right pl-0",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.getField(
                                                  item,
                                                  "coefficient"
                                                )
                                                  ? _vm.formatCoefficient(
                                                      item.coefficient
                                                    )
                                                  : ""
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        VCol,
                                        {
                                          staticClass:
                                            "text-right pl-0 pr-1 rank",
                                          attrs: { cols: 3 },
                                        },
                                        [
                                          _vm.getField(item, "rank")
                                            ? _c(
                                                "span",
                                                { staticClass: "rank-circle" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.getField(
                                                          item,
                                                          "rank"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.isUserModified
                                            ? _c(
                                                VIcon,
                                                {
                                                  staticClass:
                                                    "pull-right mt-1",
                                                  attrs: { size: "10" },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    $cross-move\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        _vm._v(" "),
                        _vm.isUserModified
                          ? _c("div", {
                              staticClass: "item list-group__drop",
                              attrs: { slot: "footer" },
                              slot: "footer",
                            })
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(VDivider),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.isUserModified
              ? _c(
                  "div",
                  {
                    staticClass:
                      "attribute-list-group mt-3 mb-3 not-selected-attrs",
                  },
                  [
                    _vm.notSelectedScenarioAttributes.length
                      ? _c(
                          "div",
                          _vm._l(
                            _vm.notSelectedScenarioAttributes,
                            function (item) {
                              return _c(
                                "div",
                                {
                                  key: item.id,
                                  staticClass:
                                    "d-flex align-center justify-start attribute-item attr-greyed-out",
                                },
                                [
                                  !_vm.isUserModified
                                    ? _c(VCheckbox, {
                                        staticClass: "ml-2 mr-0 rtls-checkbox",
                                        attrs: {
                                          disabled: _vm.disabled,
                                          "input-value": item.selected,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateSelected(
                                              item,
                                              $event
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "h4",
                                    {
                                      staticClass: "pb-0 full-row",
                                      class: { "pl-2": _vm.isUserModified },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticClass: "pa-0 ma-0 align-center",
                                        },
                                        [
                                          _c(
                                            VCol,
                                            {
                                              staticClass:
                                                "text-left pl-1 pr-0 attr-name",
                                              class: {
                                                "pl-2": _vm.isUserModified,
                                              },
                                              attrs: { cols: 6 },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(item.name) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            VCol,
                                            {
                                              staticClass: "text-right pl-0",
                                              attrs: { cols: 3 },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.getField(
                                                      item,
                                                      "coefficient"
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            VCol,
                                            {
                                              staticClass:
                                                "text-right pl-0 pr-1 rank",
                                              attrs: { cols: 3 },
                                            },
                                            [
                                              _vm.getField(item, "rank")
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "rank-circle",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(
                                                            _vm.getField(
                                                              item,
                                                              "rank"
                                                            )
                                                          ) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _c(VAlert, { attrs: { type: "info", text: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$tkey("cdtList.allAttributesAreSelected")
                            )
                          ),
                        ]),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _c("div", { staticClass: "select-cann-group pa-10 pull-right" }, [
            _vm._v(_vm._s(_vm.$tkey("selectCannGroup"))),
          ]),
      _vm._v(" "),
      !_vm.isUserModified && _vm.hasToolGeneratedChanges
        ? _c(
            VAlert,
            {
              staticClass: "ml-2 mr-2",
              attrs: { dense: "", outlined: "", type: "error" },
            },
            [
              _vm._v("\n    " + _vm._s(_vm.$tkey("regenerationWarning"))),
              _c("br"),
              _vm._v(
                "\n    " + _vm._s(_vm.$tkey("modificationWillBeLost")) + "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedCannGroup
        ? _c(
            "div",
            {
              staticClass:
                "attributes-actions-container pt-2 pb-0 d-flex justify-end flex-wrap",
            },
            [
              !_vm.isUserModified
                ? _c(
                    "div",
                    [
                      _c(
                        VTooltip,
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mr-2 mb-2",
                                            attrs: {
                                              id: "resetToDefault",
                                              disabled:
                                                _vm.resetToDefaultDisabled,
                                              icon: "",
                                              text: "",
                                            },
                                            on: {
                                              click:
                                                _vm.resetToDefaultSelections,
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          VIcon,
                                          { attrs: { size: "24" } },
                                          [_vm._v("$reset")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3036895155
                          ),
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$tkey("resetAttributesTooltip"))),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("select-multiple-cann-groups-modal", {
                        attrs: {
                          "include-options": "",
                          "exclude-self": "",
                          "enable-all-options": "",
                          "save-button-text": "copyAndSave",
                          "selected-cann-group": _vm.selectedCannGroup,
                          title: _vm.$tkey("copy.title"),
                          subtitle: _vm.$tkey("copy.subtitle"),
                        },
                        on: { confirm: _vm.copyCdtInformation },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    VBtn,
                                    _vm._g(
                                      {
                                        staticClass: "mr-2 mb-2",
                                        attrs: {
                                          disabled: _vm.disabled,
                                          secondary: "",
                                          depressed: "",
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.$tkey("copy.button")) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3713575968
                        ),
                      }),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-2 mb-2",
                          attrs: {
                            id: "saveToolGeneratedCDT",
                            action: "",
                            depressed: "",
                            disabled: _vm.isSaveTGCdtDisabled,
                          },
                          on: { click: _vm.onSaveClick },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("actions.save")) +
                              "\n      "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-2 mb-2",
                          attrs: {
                            disabled:
                              _vm.disabled ||
                              !_vm.enableGenerationButton ||
                              !_vm.hasToolGeneratedChanges,
                            primary: "",
                            depressed: "",
                          },
                          on: { click: _vm.generate },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$tkey("generate")) +
                              "\n      "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _c(
                    VBtn,
                    {
                      staticClass: "mr-2 mb-2",
                      attrs: {
                        disabled: !_vm.enableModifiedCdtSave,
                        loading: _vm.isSavingUserModified,
                        action: "",
                        depressed: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.saveModifiedCdt(_vm.cdt)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.$t("actions.save")) + "\n    "
                      ),
                    ]
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "deleteModifiedCdtDialog",
        attrs: {
          title: _vm.$tkey("cdtList.deleteCdtTitle"),
          message: _vm.$tkey("cdtList.deleteCdtConfirmationMessage"),
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function ({ cancel: close }) {
              return [
                _c(
                  VRow,
                  [
                    _c(
                      VCol,
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          VBtn,
                          {
                            attrs: {
                              "data-id-e2e": "btnAgreeCheckpoint",
                              action: "",
                            },
                            on: {
                              click: function ($event) {
                                ;[_vm.deleteCdt(true), close()]
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tkey("cdtList.confirmDelete")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            staticClass: "ml-2",
                            attrs: { "data-id-e2e": "btnDisagreeCheckpoint" },
                            on: {
                              click: function ($event) {
                                ;[_vm.deleteCdt(false), close()]
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tkey("cdtList.cancelDelete")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }