'use strict';

module.exports = {
  cookie: {
    tokenName: 'jwt',
  },
  // configures each login and logout path for each enabled authentication methods
  authenticationPaths: {
    'ow-auth-okta': {
      login: '/ow-auth/login',
      logout: '/api/ow-auth/logout',
    },
    'auth-saml': {
      login: '/login',
      logout: '/api/auth-saml/logout',
    },
    'ow-auth-hardcoded': {
      login: '/login',
      logout: '/api/logout',
    },
  },
};
