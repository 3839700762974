<template>
  <v-card flat class="step-tab-panel">
    <scenario-measuring-header
      :header-text="$tkey('reviewClusteringMessage')"
      :columns="4"
      tooltip-link="toolguide/100-clustering.html"
    />
    <v-container
      fluid
      class="pa-0 flex-grow-1 d-flex flex-column"
      :class="{ 'in-progress': hasJobInProgress }"
      :disabled="isRunningClusteredSwitchingJob"
    >
      <v-row class="flex-grow-0">
        <v-col
          cols="2"
          class="rtls-border rtls-border--right created-cluster-schemes d-flex flex-column justify-end"
        >
          <span class="pa-3 font-weight-bold d-block">
            {{ $tkey('clusteringSchemes') }}
          </span>
          <div>
            <!-- Headers -->
            <v-row id="headers-row">
              <v-col
                v-for="(header, index) in headers"
                :key="`header-${index}`"
                :cols="header.cols"
                :class="header.headerClass"
                :disabled="isNavigationDisabled"
              >
                <span>{{ header.text }}</span>
              </v-col>
            </v-row>
          </div>
        </v-col>

        <v-col cols="10" style="padding: 0">
          <v-tabs :value="currentTab" hide-slider @change="onTabChange">
            <actionable-link
              v-for="(tab, tabIndex) in tabs"
              :key="tab.routeName"
              component="v-tab"
              :class="{
                'step-tab': true,
                'first-tab': tabIndex === 0,
              }"
              :disabled="isNavigationDisabled || tab.disabled || !selectedScheme"
              :to="generateTabRoute(tab)"
              :on-click="handleTabSelect(tab)"
            >
              {{ $t(tab.title) }}
            </actionable-link>
          </v-tabs>

          <div v-if="userCanSeeSchemeInformation" class="d-flex h-50">
            <v-row class="header ma-0">
              <!-- Title -->
              <div class="d-flex align-center ml-3">
                <span class="info-note">{{ getInfoTextForTab }}</span>
              </div>

              <!-- Attribute dropdown on the deviation tab -->
              <div v-if="showAttributeFilter" class="d-flex align-center ml-5">
                <span class="mr-2">{{ $t('general.attribute') }}</span>
                <v-select
                  :value="selectedAttributeId"
                  flat
                  :items="getClusterSchemeAttributes"
                  :placeholder="$tkey('attributeFilterPlaceholder')"
                  item-text="name"
                  item-value="id"
                  :disabled="hasJobInProgress"
                  width="240px"
                  @change="changeSelectedAttribute"
                >
                  <template v-slot:append>
                    <v-icon size="28">expand_more</v-icon>
                  </template>
                </v-select>
              </div>

              <!-- Compare with drop down on the comparison tab  -->
              <div v-if="showCompareWithFilter" class="d-flex align-center ml-5">
                <span style="white-space: nowrap" class="mr-2">
                  {{ $t('general.compareWith') }}
                </span>
                <v-select
                  :items="comparisonSchemas"
                  :placeholder="$t('general.select')"
                  :value="comparisonSchemeId"
                  item-text="name"
                  item-value="_id"
                  width="240px"
                  @change="setSelectedComparisonScheme"
                >
                  <template v-slot:append>
                    <v-icon size="28">expand_more</v-icon>
                  </template>
                </v-select>
              </div>

              <!-- Toggle for list / map view when on store allocation tab -->
              <div v-if="showListModeToggle" class="d-flex align-center ml-2">
                {{ /*
                      can't use v-btn-toggle because it immediately updates internal lazy value.
                      need to wait for unsaved data warning modal confirmation if hasDataChanges.
                      https://github.com/vuetifyjs/vuetify/issues/10622#issuecomment-656930871
                */ }}
                <v-item-group class="v-btn-toggle">
                  <v-btn
                    :class="{ 'v-btn--active': listMapToggle === 0 }"
                    @click="listMapToggle = 0"
                  >
                    {{ $tkey('list') }}
                  </v-btn>
                  <v-btn
                    :class="{ 'v-btn--active': listMapToggle === 1 }"
                    @click="listMapToggle = 1"
                  >
                    {{ $tkey('map') }}
                  </v-btn>
                </v-item-group>
                <v-select
                  v-show="listMapToggle === 0"
                  id="vselectClusterName"
                  v-model="selectedClusterToMoveTo"
                  :height="24"
                  :disabled="!hasSelectedStores"
                  :items="selectableClusterNames"
                  :placeholder="$tkey('moveSelectedStoresToCluster')"
                  hide-details
                  flat
                  dense
                  class="ml-4 mt-2"
                  @input="moveStores($event)"
                />
              </div>
            </v-row>
          </div>
        </v-col>
      </v-row>

      <v-divider horizontal />

      <v-row id="main-row" class="flex-grow-1" :disabled="isRunningClusteredSwitchingJob">
        <v-col cols="2" class="rtls-border rtls-border--right created-cluster-schemes">
          <v-container
            id="cluster-schemes-table"
            :class="{ 'empty-table': !getScenarioClusterSchemes.length }"
          >
            <!-- Body -->
            <div v-for="group in groupedSchemes" :key="group.text">
              <v-row v-if="group.text">
                <span class="font-weight-bold pl-2">{{ group.text }}</span>
              </v-row>
              <v-row
                v-for="row in group.schemes"
                :key="row._id"
                :selected="selectedScheme && selectedScheme._id === row._id"
                class="cluster-row"
                :class="{ 'in-scope': group.inScope }"
              >
                <v-col
                  v-for="header in headers"
                  :key="`cell-${row._id}-${header.id}`"
                  style="height: 30px"
                  :cols="header.cols"
                  :class="header.colClass"
                >
                  <template v-if="header.ui === CELL_TYPE.icon">
                    <v-icon
                      class="v-icon-blue"
                      :disabled="isNavigationDisabled || row.disabled"
                      @click="header.onClick(row)"
                    >
                      {{ header.value }}
                    </v-icon>
                  </template>
                  <template v-else>
                    {{ getClusterVariance(header.type, row[header.value]) }}
                    <span
                      v-if="showClusterWarning(header.value, row)"
                      class="cluster-warning-container ml-2"
                      @click="header.onClick(row, storeAllocationTab)"
                    >
                      <i class="material-icons-round error-triangle-icon">warning_amber</i>
                    </span>
                  </template>
                </v-col>
              </v-row>
            </div>
          </v-container>

          <v-divider horizontal />

          <v-btn
            primary
            class="mt-2 ml-2"
            :disabled="hasJobInProgress || isEditingDisabled"
            @click="openClusterGenerationModal"
          >
            {{ $tkey('addClusteringSchemes') }}
          </v-btn>

          <div
            v-show="invalidClusteringSchemes.length"
            id="cluster-warning-section"
            class="mt-2 ml-2"
          >
            <v-divider horizontal />
            <div class="cluster-warning-container mt-2 mr-1">
              <i class="material-icons-round error-triangle-icon">warning_amber</i>
              <span>
                <b class="ml-2 mr-1">{{ $tkey('clusterWarningInfoTitle') }}</b>
                {{ $tkey('clusterWarningInfoMessage') }}
              </span>
            </div>
          </div>
        </v-col>
        <v-col
          id="clustering-tab-content"
          cols="10"
          :class="{ 'pt-5': !isStoreAllocationMapView, 'pa-0': isStoreAllocationMapView }"
          class="pb-0 pl-0 pr-0"
        >
          <component
            :is="componentName"
            v-if="userCanSeeSchemeInformation"
            :ref="componentName"
            @data-changed="hasDataChanges = $event"
            @has-selected-rows="hasSelectedStores = $event"
          />
        </v-col>
      </v-row>

      <cluster-generation-modal
        v-model="isClusterGenerationModalOpen"
        @process="processNewClusterSchemes"
        @close="closeClusterGenerationModal"
      />

      <cluster-switching-modal
        v-if="getScenarioClusterSchemes.length"
        v-model="isClusteredSwitchingModalOpen"
        @process="processClusteredSwitching"
        @close="closeClusteredSwitchingModal"
      />
    </v-container>

    <div class="page-actions-container">
      <page-actions
        :show-export="!isStoreAllocationMapView"
        include-scenario-in-export-name
        save-btn-text="runClusteredSwitching"
        export-service="clustering"
        :save-disabled="isEditingDisabled"
        :is-discard-enabled="!isEditingDisabled"
        :has-data-changes="userCanRunClusteredSwitching"
        :has-data-errors="hasDataErrors"
        :show-discard="false"
        :live-data="getScenarioClusterSchemesForExport"
        @save="openClusterSwitchingModal"
      >
        <template v-slot:right-btns>
          <v-btn
            class="text-outline"
            secondary
            depressed
            :disabled="selectedScenario.hasSkippedClustering === false || isEditingDisabled"
            @click="setScenarioHasSkippedClustering({ hasSkippedClustering: true })"
          >
            {{ $t('actions.skipClustering') }}
          </v-btn>
        </template>
      </page-actions>
    </div>

    <div class="center-top">
      <progress-bar
        v-if="isLoadingClusterInformation || hasJobInProgress"
        :message="$t('messages.inProgress')"
        :percentage="getProgressPercent"
        :status="getProgressStatus"
      />
    </div>

    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="closeUnsavedDataModal"
      @confirm="closeUnsavedDataModal"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { CELL_TYPE } from '@enums/table/index';
import { camelCase, partition, has, get, filter, some, includes } from 'lodash';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';

export default {
  localizationKey: 'clusteringPage',
  mixins: [unsavedDataWarningMixin],

  beforeRouteUpdate(to, from, next) {
    this.updateSelectedAttributeId();
    next();
  },

  data() {
    const storeAllocationTab = {
      routeName: 'store-allocation',
      componentName: 'store-allocation',
      title: this.$tkey('tabs.storeAllocation.title'),
      position: 2,
    };
    const tabs = [
      {
        routeName: 'comparison',
        componentName: 'comparison',
        title: this.$tkey('tabs.comparison.title'),
        position: 0,
      },
      {
        routeName: 'deviation',
        componentName: 'deviation',
        title: this.$tkey('tabs.deviation.title'),
        position: 1,
      },
      storeAllocationTab,
    ];
    return {
      CELL_TYPE,
      comparisonSchemaId: null,
      selectedClusterToMoveTo: null, // used for store-allocation-list edit
      hasDataChanges: false, // only used for unsaved-data warning. component uses v-if and :is, can't rely on refs
      hasSelectedStores: false, // used to disable cluster select when no stores selected
      listMapToggleData: 0, // 0 for list, 1 for map
      isClusterGenerationModalOpen: false,
      isClusteredSwitchingModalOpen: false,
      headers: [
        {
          value: 'name',
          ui: CELL_TYPE.readonly,
          id: 'schemeName',
          cols: 5,
          headerClass: 'pl-2 px-0 py-0 pr-0',
          colClass: 'pl-2 px-0 py-0 pr-0',
          onClick: this.handleExpandClusteringScheme,
        },
        {
          text: this.$tkey('clusterCount'),
          value: 'clusterCount',
          class: 'pl-0',
          cols: 2,
          headerClass: 'pa-0 header-text',
          colClass: 'pa-0 justify-end',
          ui: CELL_TYPE.readonly,
          id: 'clusterCount',
        },
        {
          text: this.$tkey('varianceExplained'),
          value: 'variance',
          class: 'px-0',
          headerClass: 'pa-0 header-text',
          colClass: 'pr-0 justify-end',
          cols: 3,
          ui: CELL_TYPE.readonly,
          type: 'percentage',
          id: 'clusterVariance',
        },
        {
          class: 'p-0',
          cols: 2,
          ui: CELL_TYPE.icon,
          colClass: 'justify-end pr-1 pl-0 px-0 py-0',
          onClick: this.handleExpandClusteringScheme,
          value: 'keyboard_arrow_right',
          id: 'expandButton',
        },
      ],
      tabs,
      storeAllocationTab,
      currentTab: null,
      previousTab: null,
      clickedTab: null,
    };
  },

  computed: {
    ...mapState('clustering', [
      'isLoadingClusterInformation',
      'selectedAttributeId',
      'selectedScheme',
      'selectedComparisonClusterScheme',
      'comparisonSchemeId',
      'selectableClusterNames',
      'isLoadingClusterSchemes',
    ]),
    ...mapState('context', ['clientConfig', 'allowTabChange']),

    ...mapGetters('clustering', [
      'getScenarioClusterSchemes',
      'getClusterSchemeAttributes',
      'getScenarioClusterSchemesForExport',
      'getScenarioClusterSchemeById',
    ]),
    ...mapGetters('scenarios', ['isJobRunning', 'selectedScenario', 'jobStatuses']),
    ...mapGetters('context', ['showNotImplemented', 'getClientConfig']),

    hasDefaultClustersEnabled() {
      return get(this.getClientConfig, 'features.defaultClustersEnabled', false);
    },

    listMapToggle: {
      get() {
        return this.allowTabChange ? this.listMapToggleData : 0;
      },
      set(newValue) {
        this.beforeNavWithUnsavedData(() => {
          this.listMapToggleData = newValue;
          this.goToTab(this.storeAllocationTab);
        });
      },
    },

    selectedTab() {
      return this.tabs.find(tab => tab && this.$route.path.includes(tab.routeName)) || this.tabs[0];
    },

    getInfoTextForTab() {
      if (this.isStoreAllocationMapView) return this.$tkey(`tabs.storeAllocation.infoTextMapView`);
      return this.$tkey(`tabs.${camelCase(this.selectedTab.componentName)}.infoText`);
    },

    selectedTabPosition() {
      return this.selectedTab ? this.selectedTab.position : 0;
    },

    comparisonSchemas() {
      const schemas = [
        {
          _id: null,
          name: this.$tkey('emptySelection'),
        },
      ];
      this.groupedSchemes.forEach(group => {
        group.schemes.forEach(schema => {
          if (has(schema, '_id') && this.selectedScheme._id !== schema._id) {
            schemas.push(schema);
          }
        });
      });
      return schemas;
    },

    componentName() {
      return this.getComponentName(this.selectedTab.componentName);
    },

    // disable switching button
    hasDataErrors() {
      return this.hasJobInProgress || this.getScenarioClusterSchemes.length < 1;
    },

    isNavigationDisabled() {
      return (
        !this.getScenarioClusterSchemes.length ||
        this.hasJobInProgress ||
        this.isLoadingClusterSchemes
      );
    },

    isRunningClusteringJob() {
      return this.isJobRunning('clusteringGenerator');
    },

    userCanSeeSchemeInformation() {
      return (
        !this.isRunningClusteringJob && this.selectedScheme && !this.isLoadingClusterInformation
      );
    },

    showAttributeFilter() {
      return ['deviation'].includes(this.selectedTab.componentName);
    },

    showCompareWithFilter() {
      return ['comparison'].includes(this.selectedTab.componentName);
    },

    showListModeToggle() {
      return this.selectedTab.componentName === 'store-allocation';
    },

    groupedSchemes() {
      const [inScope, outOfScope] = partition(this.getScenarioClusterSchemes, 'selected');
      if (!inScope.length) {
        // return ungrouped
        return [{ schemes: outOfScope }];
      }
      // just to inform the user unclustered is also in scope
      inScope.unshift({
        variance: 0,
        clusterCount: 1,
        name: this.$tkey('unclustered'),
        disabled: true,
      });

      return [
        { text: this.$tkey('inScope'), schemes: inScope, inScope: true },
        { text: this.$tkey('outOfScope'), schemes: outOfScope, inScope: false },
      ];
    },

    isRunningClusteredSwitchingJob() {
      return this.isJobRunning('clusteredSwitching');
    },

    userCanRunClusteredSwitching() {
      const hasJobInProgress = this.isRunningClusteredSwitchingJob || this.isRunningClusteringJob;

      return !hasJobInProgress || !!this.getScenarioClusterSchemes.length;
    },

    hasJobInProgress() {
      return this.isRunningClusteredSwitchingJob || this.isRunningClusteringJob;
    },

    getProgressPercent() {
      if (this.isRunningClusteredSwitchingJob) return this.jobStatuses.clusteredSwitching.progress;
      if (this.isRunningClusteringJob) return this.jobStatuses.clusteringGenerator.progress;
      return null;
    },

    getProgressStatus() {
      if (this.isRunningClusteredSwitchingJob) return this.jobStatuses.clusteredSwitching.status;
      if (this.isRunningClusteringJob) return this.jobStatuses.clusteringGenerator.status;
      return null;
    },

    isStoreAllocationMapView() {
      return this.selectedTab.componentName === 'store-allocation' && this.listMapToggle === 1;
    },

    isStoreAllocationListView() {
      return this.selectedTab.componentName === 'store-allocation' && this.listMapToggle === 0;
    },

    showClusterMapToggle() {
      return get(this.clientConfig, 'features.clusterMapEnabled');
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditClusteringPage);
    },

    invalidClusteringSchemes() {
      return filter(this.getScenarioClusterSchemes, clScheme => {
        // cluster is invalid if it's marked as no data cluster
        return some(clScheme.clusters, 'isNoDataCluster');
      });
    },
  },

  watch: {
    selectedScheme() {
      // keep url up to date, don't need handleTabSelect since url change triggers beforeRouteLeave
      this.goToTab(this.selectedTab);
      if (!this.selectedScheme) return;
      this.setSelectableClusterNames(this.selectedScheme.clusters.map(c => c.clusterName));
    },
  },

  async created() {
    await this.fetchScenarioClusters();
    await this.fetchSwitchingMatricesCount();
    if (this.hasDefaultClustersEnabled) {
      await this.fetchClusterSchemes();
      await this.getStores();
      await this.setValidClusterSchemes();
    }
    const routeScheme = this.getScenarioClusterSchemeById(this.$route.params.clusterSchemeId);
    if (routeScheme) {
      this.expandClusteringScheme(routeScheme);
    }

    this.setSelectableClusterNames(
      this.selectedScheme ? this.selectedScheme.clusters.map(c => c.clusterName) : []
    );

    this.currentTab = this.selectedTabPosition;
    this.previousTab = this.currentTab;

    // listMapToggle is 0 on created, but if url ends with map, we need to select it
    if (this.$route.path.endsWith('store-allocation-map')) {
      this.listMapToggle = 1;
    }

    const vm = this;
    this.$root.$on('unsaved-data-confirm', () => {
      vm.onTabChange(vm.clickedTab);
    });
    if (this.selectedScheme && this.selectedScheme.scenarioId !== this.selectedScenario._id) {
      // Deselect the previously selected scheme if the user has changed scenario
      this.setSelectedScheme(null);
    }
  },

  methods: {
    ...mapActions('clustering', [
      'fetchScenarioClusters',
      'saveClusteringSchemes',
      'getClusterSchemeData',
      'changeSelectedAttribute',
      'runClusteredSwitching',
    ]),
    ...mapActions('scenarios', [
      'refreshScenario',
      'updateScenario',
      'setScenarioHasSkippedClustering',
    ]),
    ...mapActions('snackbar', ['showSuccess', 'showError']),
    ...mapMutations('clustering', [
      'setSelectedScheme',
      'updateSelectedAttributeId',
      'setComparisonSchemeId',
      'setSelectableClusterNames',
    ]),
    ...mapActions('switchingMatrices', ['fetchSwitchingMatricesCount']),
    ...mapActions('referenceClustering', ['fetchClusterSchemes', 'setValidClusterSchemes']),
    ...mapActions('toolData', ['getStores']),

    moveStores(groupClusterName) {
      this.$refs['store-allocation-list'].moveRowsToGroup({ groupClusterName });
      // without $nextTick first reset will work but all subsequent ones won't work.
      // The vuetify internal lazy value gets out of sync with the actual value.
      // similar issue on promo page-navigator.vue cancelUpdatePageCount.
      this.$nextTick(() => {
        this.selectedClusterToMoveTo = 0;
      });
    },

    getComponentName(componentName) {
      if (componentName === 'store-allocation') {
        const layout = this.listMapToggle ? 'map' : 'list';
        return `store-allocation-${layout}`;
      }

      return componentName;
    },

    async onTabChange(clickedTab) {
      this.clickedTab = clickedTab;
      this.currentTab = clickedTab;
      await this.$nextTick();
      if (!this.allowTabChange) this.currentTab = this.previousTab;
      else this.previousTab = this.currentTab;
    },

    goToTab(tab) {
      this.currentTab = tab.position;
      if (!this.selectedScheme) return;
      const route = this.generateTabRoute(tab);
      // Prevent unnecessary updates in history
      this.$router.replace(route);
    },

    generateTabRoute(tab) {
      if (!this.selectedScheme) return {};
      return {
        name: this.getComponentName(tab.componentName),
        params: { clusterSchemeId: this.selectedScheme._id },
      };
    },

    handleTabSelect(tab) {
      const vm = this;
      return () =>
        vm.beforeNavWithUnsavedData(() => {
          vm.currentTab = tab.position;
        });
    },

    setSelectedComparisonScheme(schemeId) {
      this.setComparisonSchemeId(
        schemeId === this.selectedScheme._id || !schemeId ? null : schemeId
      );
    },
    closeClusterGenerationModal() {
      this.isClusterGenerationModalOpen = false;
    },
    closeClusteredSwitchingModal() {
      this.isClusteredSwitchingModalOpen = false;
    },
    openClusterGenerationModal() {
      this.isClusterGenerationModalOpen = true;
    },
    openClusterSwitchingModal() {
      this.isClusteredSwitchingModalOpen = true;
    },

    expandClusteringScheme(clusterScheme) {
      if (clusterScheme.disabled) return;
      this.getClusterSchemeData(clusterScheme);
      this.setSelectedScheme(clusterScheme);
      this.updateSelectedAttributeId();
      this.comparisonSchemaId = null;
    },

    handleExpandClusteringScheme(clusterScheme, tab) {
      this.beforeNavWithUnsavedData(() => {
        this.expandClusteringScheme(clusterScheme);
        if (tab) {
          this.goToTab(tab);
        }
      });
    },

    async processNewClusterSchemes(body) {
      await this.saveClusteringSchemes(body);
      await this.refreshScenario();
    },

    getClusterVariance(headerType, value) {
      if (headerType === 'percentage') {
        return value ? `${this.formatNumber({ number: value, format: 'percent' })}%` : 0;
      }
      return value;
    },

    showClusterWarning(headerValue, scheme) {
      return headerValue === 'name' && includes(this.invalidClusteringSchemes, scheme);
    },

    async processClusteredSwitching(payload) {
      await this.runClusteredSwitching(payload);
      await this.refreshScenario();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

$shadow-colour1: rgba(0, 0, 0, 0.2);
$shadow-colour2: rgba(0, 0, 0, 0.14);
$shadow-colour3: rgba(0, 0, 0, 0.12);

.page-actions-container {
  border-top: 1px solid $assortment-panel-border-divider-colour;
}

.first-tab {
  margin-left: 0 !important;
  border-left: 0 !important;
}

.created-cluster-schemes {
  overflow: auto;
  padding: 0;
  height: 100%;
  border-top-width: 1px;
}

#clustering-tab-content {
  overflow: hidden;
  height: 100%;
}

#cluster-schemes-table {
  padding: 0;

  .cluster-row {
    &:nth-last-of-type(odd) {
      background-color: $assortment-table-blue-bg-colour;
    }

    .col {
      display: flex;
      align-items: center;
    }
    &[selected] {
      position: relative;
      /* override existing border with this new one */
      border-left: 2px solid $assortment-action-icon-color;
      box-shadow: 0px 3px 1px -2px $shadow-colour1, 0px 2px 2px 0px $shadow-colour2,
        0px 1px 5px 0px $shadow-colour3;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        top: 0;
        pointer-events: none;
        bottom: 0;
      }
    }
    &.in-scope:last-child {
      margin-bottom: 15px;
    }
  }
}

.v-icon-blue {
  color: $assortment-action-icon-color !important;
  fill: $assortment-action-icon-color !important; // for SVG
}

span {
  color: $assortment-text-colour;
}

.empty-table {
  color: $assortment-disabled-action-green-button-colour;
}

#main-row {
  height: $tab-panel-height;
  overflow-y: auto;
  overflow-x: hidden;
}

.step-tab {
  background-color: $assortment-unselected-tab-colour;
}

.v-tab--active {
  &::after {
    content: '';
    width: 100%;
    position: absolute;
    bottom: -1px;
    background: $assortment-selected-tab-colour;
    height: 2px;
    left: 0px;
  }
}

::v-deep {
  .v-slide-group__content {
    border-bottom: 1px solid $assortment-border-colour;
  }
  .header-text[disabled] > span {
    color: $assortment-disabled-action-green-button-colour;
  }
  .v-input__slot {
    height: 25px;
    border-bottom: 1px solid $assortment-primary-colour;
  }
  #vselectClusterName {
    // ensure entire help text is displayed
    min-width: 160px;
  }
}

.header-text {
  text-align: right;
}

.info-note {
  font-weight: bold;
  font-size: 1.2rem;
  margin-right: 5px;
}

.in-progress {
  opacity: 0.3;
}
.center-top {
  position: absolute;
  left: 50%;
  bottom: 50%;

  & > .progress-bar {
    position: relative;
    left: -50%;
    width: 500px;
  }
}

.cluster-warning-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.error-triangle-icon {
  color: $assortment-negative-action-colour;
  font-size: 2rem !important;
  cursor: pointer;
}
</style>
