import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: { width: _vm.width, "content-class": "rtls-dialog" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.cancel.apply(null, arguments)
        },
        "click:outside": _vm.clickOutside,
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        VContainer,
        {
          staticClass: "main-dialog",
          class: { "main-dialog--border": _vm.border },
        },
        [
          _vm._t(
            "header",
            function () {
              return [
                _vm.title
                  ? [
                      _c(
                        VRow,
                        { staticClass: "main-dialog__header" },
                        [
                          _c(
                            VCol,
                            {
                              staticClass: "d-flex justify-space-between pa-0",
                            },
                            [
                              _c("h3", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.title) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                VBtn,
                                {
                                  staticClass: "float-right",
                                  attrs: { icon: "", width: "24" },
                                  on: { click: _vm.cancel },
                                },
                                [
                                  _c(
                                    VIcon,
                                    { attrs: { color: "#2f477c" } },
                                    [_vm._v("mdi-close-circle")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(VIcon),
                      _vm._v(" "),
                      _c(VDivider),
                    ]
                  : _vm._e(),
              ]
            },
            { cancel: _vm.cancel }
          ),
          _vm._v(" "),
          _vm._t(
            "content",
            function () {
              return [
                _c(
                  VRow,
                  { staticClass: "main-dialog__content" },
                  [
                    _c(VCol, { staticClass: "message" }, [
                      _vm._v(
                        "\n          " + _vm._s(_vm.message) + "\n        "
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
            { confirm: _vm.confirm, cancel: _vm.cancel }
          ),
          _vm._v(" "),
          _vm.showConfirmMessage
            ? _c(VRow, [
                _c("strong", { staticClass: "mx-auto mb-2 message" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("messages.areYouSureYouWantToDoThis")) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._t("actions", null, { confirm: _vm.confirm, cancel: _vm.cancel }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }