import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VForm,
    {
      ref: "form",
      staticClass: "main-expandable-panel panel-row d-flex",
      class: _vm.templateStatus,
      attrs: { autocomplete: "off" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "panel-cell drag-cell flex-grow-0 flex-shrink-0",
          class: _vm.workpackageSetupJobStatus,
        },
        [
          _c("img", {
            staticClass: "template-icon",
            attrs: {
              width: "15px",
              src: _vm.templateIcon,
              alt: _vm.$t("entities.template"),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "panel-cell name-description pr-0 mr-auto" },
        [
          _c(
            "div",
            { staticClass: "top-container d-flex" },
            [
              _c("rtls-text-field", {
                key: _vm.nameKey,
                attrs: {
                  disabled: !_vm.hasPermission(
                    _vm.userPermissions.canEditWorkpackageNameDescription
                  ),
                  "data-id-e2e": "workpackageTemplateName",
                  "data-value-e2e": _vm.model.name,
                  rules: [
                    _vm.required,
                    (v) =>
                      _vm.isNameUnique(
                        v,
                        _vm.workpackage.name,
                        _vm.templateNames,
                        _vm.$t("entities.template")
                      ),
                  ],
                },
                on: {
                  blur: _vm.saveModel,
                  keyup: [
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.saveModel.apply(null, arguments)
                    },
                    function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      )
                        return null
                      return _vm.resetModel("name")
                    },
                  ],
                },
                model: {
                  value: _vm.model.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.model,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "model.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("rtls-text-field", {
            key: _vm.descriptionKey,
            attrs: {
              "data-id-e2e": "workpackageTemplateDescription",
              disabled: !_vm.hasPermission(
                _vm.userPermissions.canEditWorkpackageNameDescription
              ),
            },
            on: {
              blur: _vm.saveModel,
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.saveModel.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  )
                    return null
                  return _vm.resetModel("description")
                },
              ],
            },
            model: {
              value: _vm.model.description,
              callback: function ($$v) {
                _vm.$set(
                  _vm.model,
                  "description",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "model.description",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.sections, function (section) {
        return _c(
          "section",
          {
            key: section.tkey,
            staticClass: "panel-cell align-end flex-column clickable",
          },
          [
            _c(
              _vm.getSectionRoute(section) ? "actionable-link" : "div",
              {
                tag: "component",
                attrs: {
                  to: _vm.getSectionRoute(section),
                  "on-click": () => _vm.onSectionClick(section),
                },
              },
              [
                section.name === "dates-assortment-groups" &&
                _vm.assortmentGroups.length
                  ? _c(
                      VMenu,
                      {
                        attrs: {
                          "data-id-e2e": "workpackageTemplateAgTooltip",
                          "open-on-hover": "",
                          "content-class": "ag-list",
                          "offset-y": "",
                          "close-delay": "100",
                          "max-height": 210,
                          "z-index": 1000,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "h4",
                                    _vm._g({ staticClass: "header" }, on),
                                    [_vm._v(_vm._s(_vm.$t(section.tkey)))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _vm._l(_vm.assortmentGroups, function (group) {
                          return _c(
                            "h4",
                            { key: group.name, staticClass: "full-size-title" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(group.name) +
                                  "\n        "
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    )
                  : _c("h4", { staticClass: "header" }, [
                      _vm._v(_vm._s(_vm.$t(section.tkey))),
                    ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-center flex-end row justify-end",
                  },
                  [
                    _c("p", { staticClass: "header ma-0 px-1" }, [
                      _vm._v(_vm._s(_vm.displayCount(section.count))),
                    ]),
                    _vm._v(" "),
                    _vm.showNotImplemented
                      ? _c(
                          "div",
                          { staticClass: "icons" },
                          [
                            _c(VIcon, { attrs: { size: "20" } }, [
                              _vm._v(_vm._s(`$${section.status}`)),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("section", { staticClass: "panel-cell align-start flex-column" }, [
        _c("h4", { staticClass: "header" }, [
          _vm._v(_vm._s(_vm.$t("workpackagePage.lastModified"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "d-flex align-center flex-end row justify-space-between",
          },
          [
            _c("p", { staticClass: "header date ma-0 px-1" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm._f("formatDate")(
                      _vm.model.lastModifiedDate,
                      _vm.getDateFormats.longWithTimeAndMeridiem
                    )
                  ) +
                  "\n      "
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "panel-cell align-start flex-column" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex align-center flex-end row justify-space-between",
          },
          [
            _c(
              "div",
              { staticClass: "icons" },
              [
                _c("notes", {
                  attrs: {
                    notes: _vm.getAllNotes,
                    loading: _vm.isNotesLoading,
                    title: _vm.$t("notes.types.workpackage"),
                    "max-chars": _vm.getClientConfig.notesSettings.maxLength,
                    notifications: !!_vm.workpackage.totalNotes,
                  },
                  on: {
                    post: _vm.postNewNote,
                    menuOpen: _vm.fetchAllNotes,
                    delete: _vm.deleteCurrentNote,
                    update: _vm.updateCurrentNote,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _vm.workpackage.archived
              ? _c("workpackage-option-unarchive", {
                  attrs: {
                    workpackage: _vm.workpackage,
                    entity: _vm.entities.template,
                  },
                })
              : _c("workpackage-option-archive", {
                  attrs: {
                    workpackage: _vm.workpackage,
                    entity: _vm.entities.template,
                  },
                }),
            _vm._v(" "),
            _vm.isTemplateDeleteEnabled
              ? _c("workpackage-option-delete", {
                  attrs: {
                    workpackage: _vm.workpackage,
                    entity: _vm.entities.template,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "panel-cell align-end flex-column header" },
        [
          _c("h4", { staticClass: "header" }, [
            _vm._v(_vm._s(_vm.$tkey("templatesPage.createdBy"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex align-center flex-end row justify-end" },
            [
              _c("rtls-avatar", {
                attrs: { username: _vm.getName(_vm.workpackage) },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showNotImplemented
        ? _c(
            "section",
            {
              staticClass:
                "panel-cell px-1 align-center justify-center flex-grow-0",
            },
            [_c("panel-menu-button", { attrs: { disabled: "" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showNotImplemented
        ? _c(
            "div",
            {
              staticClass:
                "panel-cell px-1 expand align-center justify-center flex-grow-0 flex-shrink-0",
            },
            [
              _c("main-expand-button", {
                attrs: { disabled: true, "is-expanded": false },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }