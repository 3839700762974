import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.params.data.addSisterRow
        ? _c(VAutocomplete, {
            ref: "autocomplete",
            attrs: {
              disabled: _vm.params.disabled(),
              "item-text": "itemDescription",
              "item-value": "productKey",
              items: _vm.potentialSisterProducts,
              filter: _vm.filterSisterProducts,
              placeholder: _vm.$tkey("addSisterProductSelectPlaceholder"),
              "no-data-text": _vm.$tkey("addSisterProductNoMatchingRecords"),
              "menu-props": { auto: true },
              "search-input": _vm.searchInput,
              dense: "",
              "allow-overflow": "",
            },
            on: {
              "update:searchInput": function ($event) {
                _vm.searchInput = $event
              },
              "update:search-input": function ($event) {
                _vm.searchInput = $event
              },
              change: _vm.addSisterProduct,
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _c("p", { staticClass: "test pl-3 pt-4" }, [
            _vm._v(_vm._s(_vm.params.value)),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }