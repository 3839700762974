import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "cann-groups-menu pa-0 ma-0 fill-height" },
    [
      _c(
        "div",
        { staticClass: "cg-container", staticStyle: { width: "100%" } },
        [
          _c(
            "div",
            { staticClass: "flex-grow-0" },
            [
              _c(VCol, { staticClass: "title-text" }, [
                _vm._v(_vm._s(_vm.$t("switchingBehaviour.cannGroupTitle"))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(VDivider, { staticClass: "flex-grow-0" }),
          _vm._v(" "),
          _vm.cannGroups
            ? _c(
                VList,
                { attrs: { dense: "" } },
                [
                  _c(
                    VListItemGroup,
                    {
                      attrs: { value: _vm.selectedCannGroup, color: "primary" },
                    },
                    _vm._l(_vm.cannGroups, function (cg) {
                      return _c(
                        VListItem,
                        {
                          key: cg.key,
                          attrs: { value: cg.key },
                          on: {
                            click: function ($event) {
                              return _vm.selectCannGroup(cg)
                            },
                          },
                        },
                        [
                          _c(
                            VListItemContent,
                            [
                              _c(
                                VListItemTitle,
                                { staticClass: "cannGroupName" },
                                [
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { top: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c("span", _vm._g({}, on), [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        cg.name || cg.key
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(cg.name || cg.key) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VListItemIcon,
                            { staticClass: "align-self-center" },
                            [
                              _vm.markCannGroupWithSelectedCdt
                                ? _c(
                                    "div",
                                    [
                                      _vm.hasSelectedCDT(cg.key)
                                        ? _c(
                                            VIcon,
                                            {
                                              staticClass: "float-right mt-1",
                                              attrs: { size: "20" },
                                            },
                                            [_vm._v("$success")]
                                          )
                                        : _vm._t("tickbox", null, {
                                            cgKey: cg.key,
                                          }),
                                    ],
                                    2
                                  )
                                : _vm._t("tickbox", null, { cgKey: cg.key }),
                              _vm._v(" "),
                              _c(
                                VIcon,
                                {
                                  staticClass: "pull-right",
                                  attrs: { size: "30" },
                                },
                                [_vm._v("navigate_next")]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _c(
                VAlert,
                {
                  staticClass: "ma-2 info-message",
                  attrs: { type: "info", text: "" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$tkey("switchingBehaviour.noCannGroups")) +
                      "\n    "
                  ),
                ]
              ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }