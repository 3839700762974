<template functional>
  <svg
    width="18px"
    height="18px"
    viewBox="-1 0 20 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>{{ props.title }}</title>
    <g id="modelling-d2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="modelling-products-–-01-X"
        transform="translate(-59.000000, -512.000000)"
        :fill="props.color"
      >
        <path
          id="Combined-Shape"
          d="M72.5714286,512 C75.3328523,512 77.5714286,514.238576 77.5714286,517 C77.5714286,519.761424 75.3328523,522 72.5714286,522 C70.752638,522 69.1606637,521.028884 68.2857331,519.576879 C67.4107649,521.028884 65.8187906,522 64,522 C61.2385763,522 59,519.761424 59,517 C59,514.238576 61.2385763,512 64,512 C65.8187906,512 67.4107649,512.971116 68.2856954,514.423121 C69.1606637,512.971116 70.752638,512 72.5714286,512 Z M72.5714286,513.428571 C70.598983,513.428571 69,515.027554 69,517 C69,518.972446 70.598983,520.571429 72.5714286,520.571429 C74.5438741,520.571429 76.1428571,518.972446 76.1428571,517 C76.1428571,515.027554 74.5438741,513.428571 72.5714286,513.428571 Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    color: {
      type: String,
      required: false,
      default: '#000000',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.icon {
  height: 18px;
  width: 18px;
  background: $assortment-canvas-icon-bg-colour;
}

.disabled .icon {
  opacity: 0.5;
}
</style>
