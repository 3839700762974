<template>
  <v-container class="pa-0 ma-0 planogram-definition-container">
    <div class="ag-grid-box flex-grow-1 pa-2 ma-0">
      <div class="action-panel">
        <div class="action-panel-container">
          <data-upload
            :legends="csvUploadLegends"
            :csv-upload-handler="onCSVUpload"
            show-modal
            :disabled="isEditingDisabled"
            @process="process"
          />
        </div>
      </div>
      <ag-grid-vue
        style="width: 100%; height: 90%;"
        class="ag-theme-custom"
        :column-defs="columnDefs"
        :row-data="rowData"
        :grid-options="gridOptions"
        @cell-value-changed="trackDiff"
        @grid-ready="onGridReady"
      />
    </div>
    <page-actions
      show-export
      is-custom-export
      :has-data-changes="isActionsEnabled"
      :has-data-errors="hasDataErrors"
      :is-discard-enabled="isDiscardEnabled && !isEditingDisabled"
      :save-disabled="isEditingDisabled"
      @export="exportCSV()"
      @discard="discardChanges"
      @save="saveChanges"
    />
  </v-container>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import {
  isEmpty,
  camelCase,
  get,
  map,
  each,
  cloneDeep,
  fromPairs,
  isUndefined,
  pick,
  some,
  size,
  isNull,
  values,
  keyBy,
} from 'lodash';
import agGridUtils from '@/js/utils/ag-grid-utils';
import exportCSV from '@/js/mixins/export-csv';

const getCSSBKey = pd => {
  return `${pd.clusterStoreClassSequentialKey}${pd.spacebreakSequentialKey}`;
};

export default {
  localizationKey: 'extract.reports.storeExecutionPlanning.planogramDefinition',
  components: {
    AgGridVue,
  },
  mixins: [exportCSV],

  data() {
    return {
      gridApi: null,
      columnApi: null,
      gridOptions: {
        headerHeight: 40,
        rowHeight: 40,
        stopEditingWhenCellsLoseFocus: true,
        enableRangeSelection: true,
        suppressContextMenu: true,
        enableFillHandle: true,
        tooltipShowDelay: 0,
        defaultColDef: {
          filter: true,
          resizable: true,
          minWidth: 80,
          sortable: true,
          comparator: agGridUtils.sortings.naturalSort,
          editable: false,
          suppressMovable: true,
          menuTabs: ['filterMenuTab'],
        },
        processCellFromClipboard: agGridUtils.utils.processCellFromClipboard,
        getMainMenuItems: params =>
          agGridUtils.utils.toggleAllMenuItems(params, this.checkboxToggleAll),
        onDragStopped: params =>
          params.api.refreshCells({ columns: ['alternativeAssortment'], force: true }),
        columnTypes: {
          numericColumnCustom: agGridUtils.columnTypes.numericColumnCustom,
        },
      },
      rowData: null,
      currentStateDiff: {},
      savedState: {},
      csvUploadLegends: {
        buttonName: this.$t('actions.manualImport'),
        title: this.$tkey('import'),
      },
    };
  },

  computed: {
    ...mapState('storeExecutions', ['isLoading']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('assortmentCanvas', ['scenarioCheckpoints']),
    ...mapGetters('assortmentCanvas', ['scenarioCheckpointsById']),
    ...mapGetters('context', ['getCsvExport', 'getDateFormats']),

    currentScope() {
      return camelCase(this.selectedWorkpackage.fillInSelection);
    },

    planogramsToBeBuilt() {
      const planograms = new Set();
      each(this.rowData, r => {
        const planogramKey = getCSSBKey(r);
        if (r.buildSuggestion) planograms.add(planogramKey);
      });
      return planograms;
    },

    columnDefs() {
      const spaceType = this.$t(`workpackagePage.scope.${this.currentScope}`);
      const uom = this.$t(`suffixes.${this.currentScope}`);

      return [
        {
          headerName: this.$tkey('tableHeaders.cluster'),
          field: 'clusterName',
          colId: 'clusterName',
          valueFormatter: this.formatClusterName,
          filterParams: {
            valueFormatter: this.formatClusterName,
          },
          comparator: (valueA, valueB) => {
            valueA =
              isNull(valueA) || isUndefined(valueA)
                ? this.formatClusterName({ value: valueA })
                : valueA.toString();
            valueB =
              isNull(valueB) || isUndefined(valueB)
                ? this.formatClusterName({ value: valueB })
                : valueB.toString();
            return valueA.localeCompare(valueB, undefined, {
              numeric: true,
              sensitivity: 'base',
            });
          },
        },
        {
          headerName: this.$tkey('tableHeaders.storeClass'),
          field: 'storeClassName',
          colId: 'storeClassName',
        },
        {
          headerName: this.$tkey('tableHeaders.spacebreak'),
          field: 'spacebreakName',
          colId: 'spacebreakName',
          flex: 1,
          minWidth: 150,
          cellClass: 'overflow-ellipsis',
          tooltipValueGetter: params => params.data.spacebreakName,
        },
        {
          headerName: this.$tkey('tableHeaders.clusterStoreclassSpacebreakKey'),
          colId: 'clusterStoreclassSpacebreakKey',
          field: 'clusterStoreclassSpacebreakKey',
          width: 220,
          valueGetter(params) {
            return getCSSBKey(params.data);
          },
        },
        {
          headerName: this.$tkey('tableHeaders.furnitureArchetype'),
          field: 'furnitureSequentialKey',
          colId: 'furnitureSequentialKey',
          width: 150,
        },
        {
          headerName: this.$tkey('tableHeaders.furnitureArchetypeDescription'),
          field: 'furnitureArchetypeName',
          colId: 'furnitureArchetypeName',
          minWidth: 150,
          flex: 1,
          cellClass: 'overflow-ellipsis',
          tooltipValueGetter: params => params.data.furnitureArchetypeName,
        },
        {
          headerName: `${this.$tkey('tableHeaders.totalSpace')} ${spaceType} (${uom})`,
          field: 'totalSpace',
          colId: 'totalSpace',
          width: 110,
          type: 'numericColumnCustom',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.$tkey('tableHeaders.numberOfStores'),
          field: 'numberOfStores',
          colId: 'numberOfStores',
          width: 105,
          type: 'numericColumnCustom',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.$tkey('tableHeaders.numberOfProducts'),
          field: 'numberOfProducts',
          colId: 'numberOfProducts',
          width: 115,
          type: 'numericColumnCustom',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: this.$tkey('tableHeaders.buildSuggestion'),
          field: 'buildSuggestion',
          colId: 'buildSuggestion',
          width: 130,
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          editable: () => !this.isEditingDisabled,
          valueParser: agGridUtils.parsers.booleanParser,
          cellRenderer: params =>
            agGridUtils.utils.checkboxRenderer(params, this.handleCheckboxChange),
          cellRendererParams: {
            field: 'buildSuggestion',
          },
          cellClassRules: {
            'diff-background': this.hasDiff,
            'invalid-nonempty': agGridUtils.validations.validateValueExists,
          },
        },
        {
          headerName: this.$tkey('tableHeaders.alternativeAssortment'),
          field: 'alternativeAssortment',
          colId: 'alternativeAssortment',
          cellEditor: 'agSelectCellEditor',
          singleClickEdit: true,
          cellEditorParams: params => {
            const clusterStoreclassSpacebreakKey = getCSSBKey(params.data);
            return {
              values: this.getAlternativeAssortments(clusterStoreclassSpacebreakKey),
              field: 'alternativeAssortment',
            };
          },
          valueFormatter: this.formatAlternativeAssortments,
          filterParams: {
            valueFormatter: this.formatAlternativeAssortments,
          },
          editable: params => !params.data.buildSuggestion && !this.isEditingDisabled,
          cellClass: params => {
            if (params.data.buildSuggestion) return 'disabled-cell';
          },
          cellClassRules: {
            'diff-background': this.hasDiff,
            'invalid-nonempty': agGridUtils.validations.validateValueExists,
          },
        },
        {
          headerName: this.$tkey('tableHeaders.notes'),
          field: 'notes',
          colId: 'notes',
          editable: () => !this.isEditingDisabled,
          flex: 1,
          cellClassRules: {
            'diff-background': this.hasDiff,
            'invalid-nonempty': agGridUtils.validations.validateValueExists,
          },
        },
      ];
    },

    hasMissingValues() {
      // Finds rows without suggested assortment and alternaive assortment data
      return some(this.rowData, r => !r.alternativeAssortment && !r.buildSuggestion);
    },

    canSaveDefaultData() {
      /* Allows default data to be saved as long as an alternative assortment selection
         has been made for rows with buildSuggestion unselected */
      return (
        some(this.rowData, r => !r.selectedAssortment && r.buildSuggestion) &&
        !this.hasMissingValues
      );
    },

    isDiscardEnabled() {
      // Disabled discard button when saving default data
      return !this.canSaveDefaultData || (this.canSaveDefaultData && this.hasDataChanges);
    },

    hasDataChanges() {
      return !isEmpty(this.currentStateDiff);
    },

    isActionsEnabled() {
      return this.canSaveDefaultData || this.hasDataChanges;
    },

    hasDataErrors() {
      return this.isLoading || (this.canSaveDefaultData ? false : this.hasMissingValues);
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditStoreExecutionPlanning);
    },
  },

  async created() {
    this.setSelectedStep(this.$options.name);
    this.rowData = await this.fetchPlanogramDefinition();

    // Backup data
    this.resetChanges();
  },

  methods: {
    ...mapMutations('extracts', ['setSelectedStep']),
    ...mapActions('storeExecutions', [
      'fetchPlanogramDefinition',
      'savePlanogramDefinition',
      'processCSV',
    ]),
    ...mapActions('files', ['uploadCSV']),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    updateAlternativeAssortments() {
      each(this.rowData, r => {
        // If alternative assortment is not in planograms to be built then value should be reset
        if (r.alternativeAssortment && !this.planogramsToBeBuilt.has(r.alternativeAssortment)) {
          const { clusterId, storeClassId, spacebreakId, furnitureArchetypeId } = r;
          const key = `${clusterId}-${storeClassId}-${spacebreakId}-${furnitureArchetypeId}`;
          // Unset alternative assortment value
          r.alternativeAssortment = null;
          this.updateDiff(key, 'alternativeAssortment', null);
        }
      });
      // Redraw rows to see changes
      this.gridApi.redrawRows();
    },

    handleCheckboxChange(params) {
      const { node, value } = params;
      const rowNode = this.gridOptions.api.getRowNode(node.id);

      params.setValue(!value);
      // Unset alternative assortment value for row
      rowNode.setDataValue('alternativeAssortment', null);
      // Update alternative assortment values in other table rows
      this.updateAlternativeAssortments();
      // Refresh alternativeAssortment cells to ensure text is updated correctly
      this.gridApi.refreshCells({ columns: ['alternativeAssortment'], force: true });
    },

    checkboxToggleAll(colId, value) {
      each(this.rowData, r => {
        const { clusterId, storeClassId, spacebreakId, furnitureArchetypeId } = r;
        const key = `${clusterId}-${storeClassId}-${spacebreakId}-${furnitureArchetypeId}`;
        // Unset alternative assortment value
        r.alternativeAssortment = null;
        // Update table data and current state with updated vales
        r[colId] = value;
        this.updateDiff(key, colId, value);
      });

      // Redraw rows to see changes
      this.gridApi.redrawRows();
    },

    trackDiff(params) {
      const { field } = params.colDef;
      const { clusterId, storeClassId, spacebreakId, furnitureArchetypeId } = params.data;
      const key = `${clusterId}-${storeClassId}-${spacebreakId}-${furnitureArchetypeId}`;

      this.updateDiff(key, field, params.value);
    },

    updateDiff(key, field, currentValue) {
      const originalValue = get(this.savedState, [key, field]);
      const existingValue = get(this.currentStateDiff, key);

      // If the current state does not match the original, track the state difference
      if (agGridUtils.comparators.didValueChange(currentValue, originalValue)) {
        if (!this.currentStateDiff[key]) {
          this.$set(this.currentStateDiff, key, {});
        }
        this.$set(this.currentStateDiff[key], field, currentValue);
      } else if (existingValue) {
        this.$delete(this.currentStateDiff[key], field);
        // If there is nothing left in the object, remove it
        if (isEmpty(this.currentStateDiff[key])) {
          this.$delete(this.currentStateDiff, key);
        }
      }
    },

    hasDiff(params) {
      const { field } = params.colDef;
      const { clusterId, storeClassId, spacebreakId, furnitureArchetypeId } = params.data;
      const key = `${clusterId}-${storeClassId}-${spacebreakId}-${furnitureArchetypeId}`;

      const currentValue = params.value;
      const originalValue = get(this.savedState, [key, field]);

      const isDifferent = agGridUtils.comparators.didValueChange(currentValue, originalValue);
      if (isDifferent) {
        this.trackDiff(params);
      }

      return isDifferent;
    },

    async saveChanges() {
      each(this.rowData, r => {
        // Update selectedAssortment for each row
        const { buildSuggestion, alternativeAssortment } = r;
        const clusterStoreclassSpacebreakKey = getCSSBKey(r);
        const selectedAssortment = buildSuggestion
          ? clusterStoreclassSpacebreakKey
          : alternativeAssortment;
        this.$set(r, 'selectedAssortment', selectedAssortment);
      });
      const updates = map(this.rowData, r =>
        pick(r, [
          'clusterId',
          'storeClassId',
          'spacebreakId',
          'furnitureArchetypeId',
          'selectedAssortment',
          'notes',
        ])
      );

      await this.savePlanogramDefinition(updates);
      this.resetChanges();
      this.gridApi.redrawRows();
    },

    discardChanges() {
      this.rowData = cloneDeep(this.$options.rowData);
      this.resetChanges();
    },

    resetChanges() {
      this.currentStateDiff = {};
      this.$options.rowData = cloneDeep(this.rowData);
      this.resetSavedState();
    },

    resetSavedState() {
      this.savedState = fromPairs(
        map(this.$options.rowData, row => [
          `${row.clusterId}-${row.storeClassId}-${row.spacebreakId}-${row.furnitureArchetypeId}`,
          row,
        ])
      );
    },

    getAlternativeAssortments(combinationKey) {
      const planograms = new Set(this.planogramsToBeBuilt);
      planograms.delete(combinationKey);
      return [...planograms];
    },

    formatClusterName(params) {
      if (!params.value) return this.$tkey('unclustered');
      return params.value;
    },

    formatAlternativeAssortments(params) {
      if (params.value) return params.value;

      // If value is null, check if alternative assortments exist
      const clusterStoreclassSpacebreakKey = getCSSBKey(params.data);
      if (!size(this.getAlternativeAssortments(clusterStoreclassSpacebreakKey)))
        return this.$tkey('noAlternativeAssortment');

      // Text to display when no selection has been made and alternative assortments exist
      return this.$tkey('selectAlternative');
    },

    processCellCallback(params) {
      // Being called for each cell
      if (params.column.colId === 'clusterName') {
        params.value = this.formatClusterName(params);
      }
      return agGridUtils.utils.processCellForExport(params);
    },

    exportCSV() {
      const exportParams = {
        fileName: this.getFileName({
          serviceName: 'planogram-definition',
          workpackageName: this.selectedWorkpackage.name,
          scenarioName: this.selectedScenario.name,
          fileNameDateFormat: this.getDateFormats.csvFileName,
        }),
        suppressQuotes: this.getCsvExport.suppressQuotes,
        columnSeparator: this.getCsvExport.columnSeparator,
        processCellCallback: this.processCellCallback,
      };

      this.gridApi.exportDataAsCsv(exportParams);
    },

    async onCSVUpload(formData) {
      this.setSelectedStep(this.$options.name);
      formData.append('scenarioId', this.selectedScenario._id);
      return this.uploadCSV({ formData, service: 'planogram-definition' });
    },

    async process({ fileId, mappings, delimiter }) {
      const rows = await this.processCSV({
        fileId,
        mappings,
        delimiter,
        storeExecutionsService: 'planogram-definition',
      });

      // do not perform any action if no rows are being imported
      // merge existing table data with imported rows
      if (size(rows)) {
        this.rowData = values(
          Object.assign(
            keyBy(this.rowData, a => `${getCSSBKey(a)}${a.furnitureSequentialKey}`),
            keyBy(rows, b => `${getCSSBKey(b)}${b.furnitureSequentialKey}`)
          )
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.planogram-definition-container {
  flex: 1;
  max-width: 100%;

  .action-panel {
    margin: 0;
  }
}

::v-deep {
  .ag-theme-custom {
    .ag-cell-not-inline-editing {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
