var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column grid-container",
      staticStyle: { width: "100%", height: "100%" },
      attrs: { id: "product-distribution-grid" },
    },
    [
      _c("ag-grid-vue", {
        staticClass: "ag-theme-custom ag-theme-custom--attributes",
        staticStyle: { width: "100%", height: "100%" },
        attrs: {
          "row-height": "20",
          "header-height": "30",
          "column-defs": _vm.columnDefs,
          "row-data": _vm.productDistributionByCluster,
          "grid-options": _vm.gridOptions,
        },
        on: {
          "grid-ready": _vm.onGridReady,
          "column-visible": _vm.resizeClusters,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }