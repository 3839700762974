var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.margin,
        expression: "margin",
      },
    ],
    ref: "input",
    domProps: { value: _vm.margin },
    on: {
      input: function ($event) {
        if ($event.target.composing) return
        _vm.margin = $event.target.value
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }