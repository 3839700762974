import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        VContainer,
        { staticClass: "actions-container pa-0 ma-0" },
        [
          _c(
            VRow,
            { staticClass: "actions-col" },
            [
              _c(
                VCol,
                {
                  staticClass: "d-flex align-center",
                  attrs: { "data-id-e2e": "btnFurnitureMapping" },
                },
                [
                  _c(
                    "span",
                    { staticClass: "mr-3 font-weight-bold" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$tkey("reviewUpdateSelectFurnitureMapping")
                          ) +
                          "\n\n          "
                      ),
                      _vm._v(" "),
                      _c("docs-link", {
                        attrs: { link: "toolguide/045-storemapping.html" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("data-upload", {
                    attrs: {
                      legends: _vm.csvUploadLegends,
                      "csv-upload-handler": _vm.onCSVUpload,
                      disabled: _vm.isEditingDisabled,
                    },
                    on: { process: _vm.processMappedCSVData },
                  }),
                  _vm._v(" "),
                  _vm.totalUnmappedStores
                    ? _c(
                        "div",
                        { staticClass: "ml-4" },
                        [
                          !_vm.filterUnmappedRows
                            ? _c(
                                VBtn,
                                {
                                  attrs: {
                                    small: "",
                                    outlined: "",
                                    depressed: "",
                                    color: "error",
                                  },
                                  on: { click: _vm.toggleUnmappedRows },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$tc(
                                          "furnitureMappingPage.showUnmappedRows",
                                          _vm.totalUnmappedStores
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _c(
                                VBtn,
                                {
                                  attrs: {
                                    small: "",
                                    outlined: "",
                                    depressed: "",
                                    color: "primary",
                                  },
                                  on: { click: _vm.toggleUnmappedRows },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$tc(
                                          "furnitureMappingPage.showAllRows",
                                          _vm.furnitureStoreMapData.length
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                          _vm._v(" "),
                          _c("assortment-tooltip", {
                            attrs: {
                              title: _vm.$tc(
                                "furnitureMappingPage.unmappedStores",
                                _vm.totalUnmappedStores
                              ),
                              "tooltip-sections":
                                _vm.unmappedStoresTooltipSections,
                              error: "",
                            },
                          }),
                          _vm._v(" "),
                          _vm.unmappedDuplicateStores.length
                            ? _c(
                                VBtn,
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    disabled: _vm.isEditingDisabled,
                                    depressed: "",
                                    secondary: "",
                                  },
                                  on: {
                                    click: _vm.handleUnmatchedDuplicateStores,
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tc(
                                          "furnitureMappingPage.dropUnmatchedStores"
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ag-grid-box flex-grow-1" },
        [
          _c("ag-grid-vue", {
            staticClass: "ag-theme-custom",
            staticStyle: { width: "100%", height: "100%" },
            attrs: {
              "column-defs": _vm.headers,
              "row-data": _vm.furnitureStoreMapData,
              "grid-options": _vm.gridOptions,
              "does-external-filter-pass": _vm.doesExternalFilterPass,
              "stop-editing-when-cells-loses-focus": true,
              "enable-range-selection": true,
            },
            on: {
              "cell-value-changed": _vm.onSelectionChanged,
              "grid-ready": _vm.onGridReady,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("page-actions", {
        attrs: {
          "show-export": "",
          "is-custom-export": "",
          "has-data-changes": _vm.hasDataChanges,
          "has-data-errors": _vm.hasDataErrors,
          "save-disabled": _vm.isEditingDisabled,
          "is-discard-enabled": !_vm.isEditingDisabled,
        },
        on: {
          export: _vm.exportCSV,
          discard: _vm.discardChanges,
          save: _vm.confirmSave,
        },
        scopedSlots: _vm._u([
          {
            key: "left-btns",
            fn: function () {
              return [
                _vm.showNotImplemented
                  ? _c(
                      VBtn,
                      {
                        staticClass: "outlined-btn",
                        attrs: { depressed: "", disabled: "", secondary: "" },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$tkey("copyHistoricMapping")) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showNotImplemented
                  ? _c(
                      VBtn,
                      {
                        staticClass: "outlined-btn",
                        attrs: { depressed: "", disabled: "", secondary: "" },
                      },
                      [_vm._v(_vm._s(_vm.$tkey("linkSelection")))]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "confirmSaveDialog",
        attrs: { title: _vm.$tkey("saveWithUnmatchedStores") },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "mt-5 mb-2 mx-5 text-justify" }, [
                  _c("h3", [
                    _vm._v(_vm._s(_vm.$tkey("saveUnmappedStoresWarning"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "mt-2" },
                    _vm._l(_vm.unmappedStores, function (store) {
                      return _c(
                        "li",
                        {
                          key: store.storeKey,
                          staticStyle: { "font-size": "1.3rem" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                `${store.storeKeyDisplay} - ${store.storeName}`
                              ) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function ({ cancel: close }) {
              return [
                _c(
                  VRow,
                  [
                    _c(
                      VCol,
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          VBtn,
                          {
                            attrs: { action: "" },
                            on: {
                              click: function ($event) {
                                ;[_vm.saveChanges(), close()]
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.confirm")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            staticClass: "ml-2",
                            on: {
                              click: function ($event) {
                                return close()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.cancel")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.closeUnsavedDataModal,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }