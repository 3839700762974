import { render, staticRenderFns } from "./add-optional-columns-modal.vue?vue&type=template&id=ff3e19e4&scoped=true"
import script from "./add-optional-columns-modal.vue?vue&type=script&lang=js"
export * from "./add-optional-columns-modal.vue?vue&type=script&lang=js"
import style0 from "./add-optional-columns-modal.vue?vue&type=style&index=0&id=ff3e19e4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff3e19e4",
  null
  
)

export default component.exports