<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <v-form ref="form" v-model="formIsValid">
          <v-select
            :value="selectedSize"
            :items="availableNFRs"
            :label="$tkey('NFRs')"
            @change="setSelectedSize"
          />
          <v-text-field v-model="workpackageName" required label="Workpackage name" />
          <template v-for="(value, key) in config">
            <v-text-field
              :key="key"
              v-model.number="config[key]"
              :rules="rules"
              required
              :label="$tkey(key)"
              type="number"
            />
          </template>
          <v-btn
            primary
            :disabled="!formIsValid || loading"
            :loading="loading"
            class="mr-4"
            @click="generate"
          >
            {{ $tkey('generateDataButton') }}
          </v-btn>

          <v-btn warning :disabled="loading" class="mr-4" @click="reset">
            {{ $tkey('resetDataButton') }}
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { keys } from 'lodash';
import axios from 'axios';
import to from 'await-to-js';
import moment from 'moment';

export default {
  localizationKey: 'adminPage',

  data() {
    return {
      dataGenerationNFRs: null,
      selectedSize: 'large',
      workpackageName: 'Your sample workpackage',
      config: {},
      formIsValid: true,
      loading: false,
      rules: [
        value => value !== '' || this.$t('validationErrors.required'),
        value => parseInt(value, 10) > 0 || 'Needs to be positive',
      ],
    };
  },

  computed: {
    ...mapState('context', ['clientConfig']),
    availableNFRs() {
      return keys(this.dataGenerationNFRs);
    },
  },

  created() {
    this.reset();
  },

  methods: {
    ...mapActions('snackbar', ['showSuccess', 'showError']),

    setSelectedSize(val) {
      this.selectedSize = val;
      this.config = { ...this.dataGenerationNFRs[this.selectedSize] };
    },

    reset() {
      this.dataGenerationNFRs = this.clientConfig.dataGenerationNFRs;
      this.config = { ...this.dataGenerationNFRs[this.selectedSize] };
    },

    async generate() {
      this.loading = true;
      const name = `${this.workpackageName} - ${moment().format('YYYY-MM-DD HH:mm:ss')}`;
      const [err] = await to(axios.post('/api/admin/generate', { name, config: this.config }));

      if (err) {
        this.loading = false;
        return this.showError('Workpackage creation failed');
      }

      this.showSuccess('Workpackage successfully generated');
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.generate-button {
  background-color: $assortment-primary-colour;
}
</style>
