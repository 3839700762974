import i18n from '@/js/vue-i18n';
import axios from 'axios';
import to from 'await-to-js';
import { get, groupBy, size } from 'lodash';
import handleErrorMessages from '@/js/store/utils/validation';

const store = {
  namespaced: true,
  state: {
    scenarioProducts: [],
    loading: false,
  },

  getters: {
    scenarioProducts(state) {
      return state.scenarioProducts;
    },

    hasNewProducts(state) {
      return state.scenarioProducts.some(p => p.isNewProduct);
    },

    scenarioProductPallets(state) {
      return groupBy(state.scenarioProducts, 'palletContent');
    },
  },

  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setScenarioProducts(state, scenarioProducts) {
      state.scenarioProducts = scenarioProducts;
    },
  },

  actions: {
    async fetchScenarioProducts({ commit, rootState }, { params, options = {} } = {}) {
      const scenarioId = get(params, 'scenarioId', rootState.scenarios.selectedScenario._id);
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/scenario-products/scenario/${scenarioId}`, { params })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      if (options.shouldCommit) {
        commit('setScenarioProducts', response.data);
      }
      return response.data;
    },

    async processCSV(
      { commit, rootState, dispatch },
      { fileId, mappings, delimiter, service = 'scenario-products' }
    ) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const body = { mappings, scenarioId, delimiter, scenarioProductsCsv: true };
      commit('setLoading', true);
      const [err, res] = await to(axios.post(`/api/csv/${service}/process/${fileId}`, body));
      commit('setLoading', false);
      // TODO: Remove this and use global interceptor on AOV3-159
      if (err) {
        const key = get(err, 'response.data.messageKey', err.message);
        const message = i18n.t(key, get(err, 'response.data.messageParams', []));
        dispatch('snackbar/showError', message, { root: true });

        return Promise.reject(message);
      }
      const { messages, tableRows, additionalInformation } = res.data;

      if (size(messages)) {
        dispatch('alerts/showMessages', messages, { root: true });
      }

      return { tableRows, additionalInformation };
    },

    async resetProductData({ commit, rootState }, dataToReset) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, data] = await to(
        axios.get(`/api/scenario-products/scenario/${scenarioId}/reset-attributes`, {
          params: { dataToReset },
        })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      return data;
    },

    async saveScenarioProducts({ commit, dispatch }, productsData) {
      commit('setLoading', true);
      const [err, data] = await to(axios.patch(`/api/scenario-products`, productsData));
      commit('setLoading', false);
      if (err) {
        handleErrorMessages({
          response: get(err, 'response'),
          dispatch,
          options: { catchJoi: true },
        });
        return Promise.reject(err.message);
      }

      return data;
    },

    async updateScenarioProductRegionClassifications(
      { commit, rootState },
      { attributeId, attributeValues }
    ) {
      commit('setLoading', true);
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const [err, data] = await to(
        axios.patch(`/api/scenario-products/scenario/${scenarioId}/region-classifications`, {
          attributeId,
          attributeValues,
        })
      );
      commit('setLoading', false);
      if (err) return false;
      return data;
    },

    async fetchScenarioUniqueAttributeValues({ commit }, { scenarioId }) {
      commit('setLoading', true);
      const [err, result] = await to(
        axios.get(`/api/scenario-products/scenario/${scenarioId}/uniqueAttributeValues`)
      );
      commit('setLoading', false);
      if (err) return false;
      return result.data;
    },

    async fetchScenarioProductsWithClusters({ commit, rootState }) {
      // This pulls the data for the product modelling page
      // This will eventually pull all products so gets own method to be performant
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, result] = await to(
        axios.get(`/api/scenario-products/scenario/${scenarioId}/clustering-products/modelling`)
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      return result.data;
    },

    // A method that gets the information about the matching and non-matching products between source and current scenario
    async getMatchingProductsInfo({ commit, rootState, dispatch }, sourceScenarioId) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/scenario-products/scenario/${scenarioId}/matching-products-info`, {
          params: {
            sourceScenarioId,
          },
        })
      );
      commit('setLoading', false);
      handleErrorMessages({ response, dispatch });
      if (err) throw new Error(get(err, 'message'));
      return response.data;
    },

    async updateClusteredScenarioProducts({ commit, rootState, rootGetters }, updates) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const clusterSchemeId = rootGetters['clustering/selectedClusterScheme']._id;

      commit('setLoading', true);
      const [err, result] = await to(
        axios.patch(
          `/api/scenario-products/scenario/${scenarioId}/cluster-scheme/${clusterSchemeId}`,
          {
            updates,
          }
        )
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      return result.data;
    },

    async updateScenarioSwapsAttributes({ commit, rootState }, updates) {
      const scenarioId = rootState.scenarios.selectedScenario._id;

      commit('setLoading', true);
      const [err, result] = await to(
        axios.patch(`/api/scenario-products/scenario/${scenarioId}/swaps-attributes`, {
          updates,
        })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      return result.data;
    },

    async uploadAttributesFromSource(
      { commit, rootState },
      { attributeNames, optionalSchemaFields = [] }
    ) {
      const scenarioId = rootState.scenarios.selectedScenario._id;

      commit('setLoading', true);
      const [err, result] = await to(
        axios.post(`/api/scenario-products/scenario/${scenarioId}/upload-attributes-source`, {
          attributeNames,
          optionalSchemaFields,
        })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      return result.data;
    },

    async uploadAttributesFromScenario(
      { commit, dispatch, rootState },
      { sourceScenarioId = null, templateId, optionalSchemaFields = [] }
    ) {
      const targetScenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);

      const [err, result] = await to(
        axios.post(
          `/api/scenario-products/scenario/${targetScenarioId}/upload-attributes-scenario`,
          {
            sourceScenarioId,
            templateId,
            optionalSchemaFields,
          }
        )
      );
      commit('setLoading', false);

      if (err) {
        const key = get(err, 'response.data.messageKey', err.message);
        const message = i18n.t(key, get(err, 'response.data.messageParams', []));
        dispatch('snackbar/showError', message, { root: true });

        return Promise.reject(message);
      }

      return result.data;
    },

    async uploadAttributesFromFurniture({ commit, dispatch, rootState }) {
      const targetScenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);

      const [err, result] = await to(
        axios.get(`/api/scenario-products/scenario/${targetScenarioId}/upload-attributes-furniture`)
      );
      commit('setLoading', false);

      if (err) {
        const key = get(err, 'response.data.messageKey', err.message);
        const message = i18n.t(key, get(err, 'response.data.messageParams', []));
        dispatch('snackbar/showError', message, { root: true });

        return Promise.reject(message);
      }

      return result.data;
    },

    async processAttributes(
      { commit, rootState, dispatch },
      {
        productKeys,
        source,
        attributeSource,
        mappings,
        attributeNames,
        sourceScenarioId,
        templateId,
      }
    ) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, res] = await to(
        axios.post(`/api/scenario-products/scenario/${scenarioId}/process-attributes`, {
          productKeys,
          source,
          attributeSource,
          mappings,
          attributeNames,
          sourceScenarioId,
          templateId,
        })
      );
      commit('setLoading', false);
      // TODO: Remove this and use global interceptor on AOV3-159
      if (err) {
        const key = get(err, 'response.data.messageKey', err.message);
        const message = i18n.t(key, get(err, 'response.data.messageParams', []));
        dispatch('snackbar/showError', message, { root: true });

        return Promise.reject(message);
      }
      const { messages, tableRows, additionalInformation } = res.data;

      if (size(messages)) {
        dispatch('alerts/showMessages', messages, { root: true });
      }

      return { tableRows, additionalInformation };
    },

    async copyModellingDecisions({ commit, rootState, dispatch }, payload) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, response] = await to(
        axios.post(`/api/scenario-products/scenario/${scenarioId}/copy-modelling`, payload)
      );
      commit('setLoading', false);
      handleErrorMessages({ response, dispatch });
      if (err) throw new Error(get(err, 'message'));
      return response;
    },

    async runSetupProductModelling({ commit, rootState, dispatch }, payload) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, response] = await to(
        axios.post(`/api/scenario-products/scenario/${scenarioId}/setup-modelling`, payload)
      );
      commit('setLoading', false);
      handleErrorMessages({ response, dispatch });
      if (get(response, 'data.messageType') === 'JobValidation') {
        return get(response, 'data.data');
      }
      if (err) throw new Error(get(err, 'message'));
    },

    getAssociatedPallets({ getters }, { productKeyDisplay }) {
      return get(getters.scenarioProductPallets, productKeyDisplay, []);
    },
  },
};

export default store;
