import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column main-container-canvas" },
    [
      !_vm.fullScreenExpanded
        ? _c(
            VRow,
            { staticClass: "flex-grow-0" },
            [
              _c(VCol, { staticClass: "px-0", attrs: { cols: "3" } }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-end" },
                  [
                    _c("rtls-toggle", {
                      staticClass: "mr-2",
                      attrs: {
                        mandatory: "",
                        value: _vm.selectedView,
                        "left-toggle": {
                          value: _vm.views.Tile,
                          translation: _vm.$tkey(_vm.views.Tile),
                        },
                        "right-toggle": {
                          value: _vm.views.List,
                          translation: _vm.$tkey(_vm.views.List),
                        },
                      },
                      on: { input: _vm.updateSelectedView },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "docs-link" },
                      [
                        _c("docs-link", {
                          attrs: { link: "toolguide/120-assortment.html" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.selectedCanvas.outdated
                      ? _c("h4", { staticClass: "ml-2 warning-banner" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "assortmentCanvasesPanel.errorMessages.outdatedWarning"
                                )
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(VCol, { staticClass: "px-0 text-center" }, [
                _vm.cannGroupColumnSettingsEnabled && _vm.isDisplayByCDT
                  ? _c(
                      "span",
                      { staticClass: "pr-2" },
                      [
                        _c("column-settings", {
                          attrs: {
                            "button-title": _vm.$tkey("cannGroups"),
                            columns: _vm.canvasCannGroupColumns,
                          },
                          on: {
                            "update:selected-columns":
                              _vm.toggleCanvasCannGroupVisibility,
                            "update:column-order":
                              _vm.reorderCanvasCannGroupColumns,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAlternativeCanvasDisplaysEnabled
                  ? _c(
                      "span",
                      [
                        _c("span", { staticClass: "pr-2" }, [
                          _vm._v(_vm._s(_vm.$tkey("displayBy.title")) + ":"),
                        ]),
                        _vm._v(" "),
                        _c(
                          VBtnToggle,
                          {
                            staticClass: "toggle rtls-toggle",
                            attrs: { mandatory: "" },
                            model: {
                              value: _vm.displayBy,
                              callback: function ($$v) {
                                _vm.displayBy = $$v
                              },
                              expression: "displayBy",
                            },
                          },
                          [
                            _c(
                              VBtn,
                              { attrs: { value: "cdt", outlined: "" } },
                              [_vm._v(_vm._s(_vm.$tkey("displayBy.cdt")))]
                            ),
                            _vm._v(" "),
                            _c(
                              VMenu,
                              {
                                attrs: { "offset-y": "", "max-height": "400" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _c(
                                            VBtn,
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass: "pr-1",
                                                  attrs: {
                                                    value: _vm.displayByMenu,
                                                    outlined: "",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t("general.filter")
                                                  ) +
                                                  "\n                " +
                                                  _vm._s(
                                                    _vm.$tkey(
                                                      `displayBy.${_vm.displayByMenu}`,
                                                      {
                                                        fallback:
                                                          _vm.getDisplayByMenuFallbackName,
                                                      }
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "pl-2" },
                                                [_vm._v("|")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                VIcon,
                                                { attrs: { color: "primary" } },
                                                [_vm._v("mdi-chevron-down")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3603715301
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  VList,
                                  [
                                    _vm._l(
                                      _vm.displayByMenuItems,
                                      function (item, index) {
                                        return _c(
                                          VListItem,
                                          {
                                            key: index,
                                            staticClass: "display-by-title",
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectDisplayByMenuItem(
                                                  item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(
                                                  _vm.$tkey(`displayBy.${item}`)
                                                ) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm._v(" "),
                                    _vm.showCustomAttributesInDisplayByMenu
                                      ? [
                                          _c(VDivider),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.getScenarioAttributes,
                                            function (item) {
                                              return _c(
                                                VListItem,
                                                {
                                                  key: item.id,
                                                  staticClass:
                                                    "display-by-title",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.selectDisplayByMenuItem(
                                                        item.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(item.name) +
                                                      "\n                "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.showNotImplemented
                ? _c(
                    VCol,
                    { staticClass: "px-0 row", attrs: { cols: "2" } },
                    [
                      _c(
                        VSlider,
                        {
                          staticClass: "w-25 mw-25",
                          attrs: {
                            value: 50,
                            min: "0",
                            max: "100",
                            "hide-details": "",
                            disabled: "",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    icon: "",
                                    color: "primary",
                                    disabled: "",
                                  },
                                },
                                [_c(VIcon, [_vm._v("zoom_out_map")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                VCol,
                {
                  staticClass: "d-flex justify-end pr-2",
                  attrs: { "data-id-e2e": "btnsCheckpointsOptimise" },
                },
                [
                  _vm.selectedView === _vm.views.Tile
                    ? _c(
                        "div",
                        { staticClass: "d-flex align-center justify-center" },
                        [
                          _c("h4", { staticClass: "mr-2" }, [
                            _vm._v(_vm._s(_vm.$tkey("selectTileSize")) + ":"),
                          ]),
                          _vm._v(" "),
                          _c("rtls-toggle", {
                            staticClass: "mr-6",
                            attrs: {
                              mandatory: "",
                              value: _vm.selectedTileSize,
                              "left-toggle": {
                                value: "medium",
                                translation: _vm.$tkey("medium"),
                              },
                              "right-toggle": {
                                value: "large",
                                translation: _vm.$tkey("large"),
                              },
                            },
                            on: { input: _vm.setTileSize },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      staticClass: "mr-2",
                      attrs: { color: "primary" },
                      on: { click: _vm.displayCheckpoints },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$tkey("checkpoints.title")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        action: "",
                        disabled:
                          _vm.isAnyJobRunning ||
                          _vm.isCanvasConfigInvalidForOptimisation ||
                          _vm.isEditingDisabled,
                      },
                      on: { click: _vm.isCheckpointCreationEligible },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("actions.optimise")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("error-triangle", {
                    style: { "margin-bottom": "12px", "margin-left": "5px" },
                    attrs: {
                      arrow: false,
                      errors: {
                        [_vm.$tkey("errorMessages.setOptimiserSettings")]:
                          !_vm.isCanvasConfigInvalidForOptimisation,
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("progress-bar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.areCanvasProductsDownloading ||
              _vm.isAnyJobRunning ||
              _vm.renderingCanvas,
            expression:
              "areCanvasProductsDownloading || isAnyJobRunning || renderingCanvas",
          },
        ],
        staticClass: "pt-5",
        staticStyle: { margin: "initial" },
        attrs: { message: _vm.loadingMessage },
      }),
      _vm._v(" "),
      !_vm.areCanvasProductsDownloading && !_vm.isAnyJobRunning
        ? [
            _c("canvas-toolbar"),
            _vm._v(" "),
            _vm.hasCanvasFilterLegendEnabled && _vm.showFilterLegend
              ? _c("legend-filter-highlight", {
                  staticClass: "grid-container-overflow",
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.spacebreakSettings.open && _vm.selectedCanvas.storeClassId
              ? _c("spacebreak-settings", {
                  attrs: {
                    spacebreak: _vm.selectedSpacebreak,
                    "store-class-id": _vm.selectedCanvas.storeClassId,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                class: [
                  _vm.selectedView === _vm.views.Tile
                    ? "d-contents"
                    : "canvas-wrapper",
                ],
              },
              [
                _vm.selectedView === _vm.views.List
                  ? _c("spacebreak-sidebar", {
                      attrs: {
                        "points-of-distribution": _vm.pointsOfDistribution,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    class: [
                      _vm.selectedView === _vm.views.Tile
                        ? "infinite-canvas flex-grow-1"
                        : "grid-container-wrapper",
                    ],
                    attrs: { "ma-0": "", "pa-0": "" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "grid-container-overflow",
                        style: _vm.gridContainerOverflow,
                      },
                      [
                        _vm.orderedScenarioCdts
                          ? _c("canvas-grid", {
                              key: _vm.orderedScenarioCdtsKey,
                              attrs: {
                                "ordered-scenario-cdts":
                                  _vm.orderedScenarioCdts,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "checkpointsDialog",
        attrs: {
          title: _vm.$tkey("checkpoints.title"),
          width: "76%",
          border: true,
        },
        on: {
          "click:outside": _vm.closeCheckpoints,
          cancel: _vm.closeCheckpoints,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("scenario-checkpoints-list", {
                  on: {
                    close: function ($event) {
                      return _vm.$refs.checkpointsDialog.cancel()
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "confirm",
        attrs: {
          title: _vm.$t("assortmentCanvasPage.checkpointModal.title"),
          message: _vm.$t("assortmentCanvasPage.checkpointModal.text"),
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function ({ cancel: close }) {
              return [
                _c(
                  VRow,
                  [
                    _c(
                      VCol,
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          VBtn,
                          {
                            attrs: {
                              "data-id-e2e": "btnAgreeCheckpoint",
                              action: "",
                            },
                            on: {
                              click: function ($event) {
                                ;[_vm.initOptimisation(true), close()]
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tkey("checkpointModal.agree")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            staticClass: "ml-2",
                            attrs: { "data-id-e2e": "btnDisagreeCheckpoint" },
                            on: {
                              click: function ($event) {
                                ;[_vm.initOptimisation(false), close()]
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tkey("checkpointModal.disagree")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("product-dashboard"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }