import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex justify-end" },
    [
      !_vm.editingMode
        ? _c(
            VBtn,
            {
              attrs: {
                disabled: _vm.isEditingDisabled,
                ripple: false,
                icon: "",
              },
            },
            [
              _c(VIcon, { attrs: { size: 24 }, on: { click: _vm.edit } }, [
                _vm._v("$edit"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.editingMode
        ? _c(
            VBtn,
            {
              staticClass: "ml-2 delete-button",
              attrs: {
                disabled: !_vm.canRemoveConstraint || _vm.isEditingDisabled,
                ripple: false,
                icon: "",
              },
            },
            [
              _c(VIcon, { on: { click: _vm.removeConstraint } }, [
                _vm._v("$trash"),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.editingMode && _vm.isSelected
        ? _c(
            VBtn,
            {
              staticClass: "text-outline cancel",
              attrs: { text: "", depressed: "", ripple: false },
              on: { click: _vm.cancel },
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("actions.cancel")) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.editingMode && _vm.isSelected
        ? _c(
            VBtn,
            {
              staticClass: "ml-2",
              attrs: {
                ripple: false,
                disabled: !_vm.editingMode || _vm.errorsShouldDisableSave,
                action: "",
                depressed: "",
              },
              on: { mouseup: _vm.save },
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("actions.save")) + "\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }