<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 500,
    }"
    class="list-entry"
  >
    <v-row class="list-entry-header d-flex pl-2" :class="{ 'list-entry-header--active': isActive }">
      <div
        class="title-container font-weight-bold d-flex align-center justify-space-between"
        :title="title"
      >
        <h4 class="title">
          {{ title }}
        </h4>
        <v-chip v-if="isSpacebreakLocked(spacebreakId)" small color="primary" class="locked">
          {{ $tkey('locked') }}
        </v-chip>
      </div>
    </v-row>

    <!-- 30ms delay to ensure draggable content is always clickable -->
    <draggable
      :list="products"
      :disabled="isDragDisabled"
      :group="groupName"
      :sort="false"
      :scroll-sensitivity="100"
      :invert-swap="true"
      :force-fallback="true"
      ghost-class="ghost"
      draggable=".draggable"
      delay="30"
      style="position: relative;"
      @change="onChange"
      @start="onDragStart"
      @end="onDragStop"
    >
      <!-- Full product list item -->
      <div
        v-for="(product, index) in renderedProducts[0]"
        :id="`tile-product-${product.productKey}`"
        :key="product._id"
        ref="parent-entry-item"
        class="entry-item"
        :class="{
          draggable: !isLocked(product) && !isDragDisabled,
          'pod-background-increase': get(product.deltas, 'reference') > 0 && product.isEligible,
          'pod-background-decrease': get(product.deltas, 'reference') < 0 && product.isEligible,
        }"
      >
        <!-- Static KPIs -->
        <list-view-product-entry
          v-if="parentEntryItemRef"
          :product="product"
          :spacebreak-id="spacebreakId"
          :group-name="groupName"
          :list-view-ref="listViewRef"
          :parent-entry-item-ref="parentEntryItemRef"
          :index="index"
          :open-popup-on-load-for-product-id="productIdForPopup"
        />
      </div>

      <!-- Placeholder for when the product tile is off screen -->
      <div
        v-for="product in renderedProducts[1]"
        :id="`tile-product-${product.productKey}`"
        :key="product._id"
        ref="parent-entry-item"
        class="entry-item skeleton"
      >
        <img
          :id="`product-tile-popup-${product.productKey}`"
          src="../../../img/spinner.gif"
          @click="delayPopup(product._id)"
        />
      </div>
    </draggable>
  </div>
</template>

<script>
// Detailed comments on the visibility and draggable
// functionailty in: product-tile-drag-container.vue
import { mapActions, mapGetters, mapState } from 'vuex';
import { get, includes } from 'lodash';
import { lockTypes } from '@enums/assortment-product-lock-types';
import { undraggableSpacebreaks } from '@enums/spacebreak-types';

import destroy from '../../utils/destroy';
import productUtils from '../../utils/product-utils';

export default {
  localizationKey: 'assortmentCanvasPage.listViewPage',

  props: {
    products: {
      type: Array,
      default: () => [],
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    spacebreakId: {
      type: String,
      default: '',
    },

    spacebreakType: {
      type: String,
      default: null,
    },

    groupName: {
      type: String,
      required: true,
    },

    listViewRef: {
      type: Object,
      required: true,
    },

    containerId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      get,
      parentEntryItemRef: null,
      isVisible: false,
      productIdForPopup: null,
      beingDragged: false,
    };
  },

  computed: {
    ...mapState('assortmentCanvas', ['activeSpacebreak']),
    ...mapGetters('assortmentCanvas', ['selectedCanvas', 'isSpacebreakLocked']),

    isOptimised() {
      return get(this.selectedCanvas, 'hasBeenOptimised', false);
    },

    isActive() {
      return this.activeSpacebreak === (this.spacebreakId || this.spacebreakType);
    },

    isDragDisabled() {
      return (
        this.isSpacebreakLocked(this.spacebreakId) ||
        includes(undraggableSpacebreaks, this.containerId) ||
        this.isEditingDisabled
      );
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAssortmentCanvas);
    },

    // Calculate the list of products that should be rendered (first position rendered)
    renderedProducts() {
      if (this.isVisible || this.beingDragged) {
        // Should be rendered
        return [this.products, []];
      }
      return [[], this.products];
    },
  },

  beforeDestroy() {
    destroy.destroyReactiveVueProps(this);
  },

  mounted() {
    this.parentEntryItemRef = this.$refs;
  },

  methods: {
    ...mapActions('assortmentCanvas', ['updateCanvasProduct']),

    // If the user searches, but only the placeholder spinner is available,
    // delay a 'click' on the actual box until the visibility has changed
    delayPopup(productId) {
      this.productIdForPopup = productId;
    },

    onDragStart() {
      this.beingDragged = true;
    },

    onDragStop() {
      this.beingDragged = false;
    },

    visibilityChanged(isVisible) {
      this.isVisible = isVisible;

      if (!isVisible) {
        this.productIdForPopup = null;
      }
    },

    onChange(changedData) {
      if (changedData.added) {
        const element = changedData.added.element;

        // - Update data
        // locallyUpdatedProduct is meant to be used before the changes are done on the server,
        // to avoid product dragging flickering
        const locallyUpdatedProduct = {
          ...element,
          currentSpacebreakId: this.spacebreakId,
        };
        this.updateCanvasProduct({
          _id: changedData.added.element._id,
          currentSpacebreakId: this.spacebreakId,
          locallyUpdatedProduct,
        });

        // - Flash product border to make clear what was moved
        setTimeout(() => {
          const productTileId = `tile-product-${element.productKey}`;
          // Setting the first child, `tile-product-{pkey}` is a wrapper over the main tile element.
          // This is important to draw the blue border around the product image after search correctly.
          const productElement = document.getElementById(productTileId).firstChild;

          productUtils.addActiveSearchBorderToProductElement(productElement);
        }, 200);
      }
    },

    inReset(product) {
      return get(product, 'inReset', false);
    },

    isLocked(product) {
      return product.lockType === lockTypes.locked || !this.inReset(product);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.title-container {
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px;
}

.title {
  overflow: hidden;
  padding: 0 9px 0 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.list-entry {
  width: 100%;
}

.list-entry-header {
  background-color: $canvas-list-entry-header-colour;
  border-radius: 4px;
  text-transform: uppercase;
  margin-bottom: 5px;
  height: 28px;

  &--active {
    background-color: $canvas-list-entry-header-active-bg-colour;
    color: $canvas-list-entry-header-active-colour;
  }
}

.neutral-border {
  border-color: black;
}

.entry-item {
  display: flex;
  height: 28px;
  align-items: center;
  position: relative;
  border-radius: 4px;
  border: 1px solid;
  margin-bottom: 5px;
  transition: all 1s ease-out;
}

.skeleton {
  img {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
}
.locked {
  border-radius: 4px;
  flex: none;
  height: 18px;
  margin-right: 4px;
  padding: 0 10px;
  text-transform: capitalize;
}

::v-deep {
  .sortable-fallback {
    display: none !important;
  }

  .v-chip.primary {
    background-color: $assortment-text-colour !important;
  }

  .list-entry-header--active {
    .v-chip.primary {
      background-color: $canvas-list-entry-header-active-colour !important;
      color: $assortment-text-colour;
    }
  }
}

.pod-background-increase {
  background-color: $canvas-pod-increase-background;
}
.pod-background-decrease {
  background-color: $canvas-pod-decrease-background;
}
</style>
