import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: {
        value: _vm.isOpen,
        width: _vm.width,
        "content-class": "rtls-dialog",
      },
      on: {
        input: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "dialog-card",
        {
          attrs: {
            "is-popup": "",
            title: _vm.title,
            "content-class": "no-max-height",
          },
          on: {
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        {
                          staticClass: "d-flex justify-center",
                          attrs: { cols: 12, "align-self": "center" },
                        },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { primary: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("close")
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("actions.ok")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }