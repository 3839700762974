/*
These are the default Oliver Wyman palette colours (taken from Powerpoint)
Please do not over-write these colours when skinning tools for clients.
Instead create new colour names and add them to the bottom.
You can use the client's logo and the following resources to derive colour values:
    - http://www.palettefx.com/ (image to colours)
    - http://www.colorhexa.com/ (colour variations)
*/

import { map } from 'lodash';

const darkSapphire = '#002C77';
const owSapphire = '#008AB3';
const lightSapphire = '#9DE0ED';
const owOnyx = '#5F5F5F';
const lightOnyx = '#BEBEBE';
const veryLightOnyx = '#DFDFDF';
const owTopaz = '#E29815';
const lightTopaz = '#FFCF89';
const owEmerald = '#41A441';
const lightEmerald = '#BDDDA3';
const owIolite = '#646EAC';
const lightIolite = '#C5CAE7';
const owCitrine = '#DD712C';
const lightCitrine = '#FDCFAC';
const owTurquoise = '#079B84';
const lightTurquoise = '#A8DAC9';
const owRuby = '#CB225B';
const lightRuby = '#F8B8BC';
const owWhite = '#FFFFFF';
const owBlack = '#000000';

// assortment specific colours
const assortmentGreyLight = '#F3F3F3';
const assortmentGreyDark = '#808080';
const assortmentBlueLight = '#DDF2FB';
const assortmentBluePrimary = '#2f477c';
const assortmentSecondaryBlue = '#00a0e2';
const assortmentRedPrimary = '#df7d8b';
const assortmentYellowPrimary = '#f3cc92';
const assortmentGreenPrimary = '#6da54d';
const assortmentTealPrimary = '#339087';

// matching colours in webtool/client/style/base/_variable.scss
const assortmentEconomyColour = '#BCC72F';
const assortmentCompetitionColour = '#2CB498';
const assortmentArchitectureColour = '#9C79C8';
const assortmentChartScatter = '#00A0E2';
const assortmentDisabledTextButtonColour = '#CBD1DF';

const assortmentGridLineColor = '#9B9B9B';
const tensionChartPositiveColor = '#6ECADB';
const tensionChartNegativeColor = '#6B8C9F';

// colours in settings pages
const competitorTrafficLightColor = '#00AC92';
const architectureTrafficLightColor = '#9E79C5';
const economicTrafficLightColor = '#BCC743';
const otherTrafficLightColor = '#C2C2C2';
const inactiveTrafficLightColor = '#F0F0F0';

const rulesetDarkBlue = '#29477A';
const scopeHighlightBlue = '#006aa7';
const scopeHighlightRed = '#bf4c4f';

const rulesetBackgroundGrey = '#d2dee6';
const rulesetLightBlue = '#00a0df';

const penaltiesLightGreen = '#68BE4F';
const penaltiesScatterTextColour = '#000';

// used on clustering page for deviation charts and map pins

const clusteringColours = {
  orange: '#df9445',
  green: '#79a43d',
  blue: '#619fcd',
  red: '#bd4633',
  pink: '#e690e3',
  beige: '#eec792',
  darkred: '#8d3736',
  darkgreen: '#707b32',
  lightblue: '#98d7fb',
  gray: '#565656',
  lightgreen: '#c4f081',
  white: '#ffffff',
  black: '#000000',
  lightred: '#e38e7e',
  darkblue: '#3a5e95',
  lightgray: '#a3a3a3',
  cadetblue: '#486370',
  darkpurple: '#b755ab',
};

// used on charts, matches invision design
const chartColor1 = '#E48D50';
const chartColor2 = '#75CBEF';
const chartColor3 = '#9490B7';
const chartColor4 = '#66CC9A';
const chartColor5 = '#575D6C';
const chartColor6 = '#297F98';
const chartColor7 = '#DA8725';
const chartColor8 = '#5AA585';

// used on scatter plot charts
const plotColour1 = '#B3CADF';
const plotColour2 = '#DF9F6F';

const plotLineColour = '#D5D5D5';

const baseline = '#2c5eA0';
const comparison = '#df9f6f';
const baselinePlotLine = '#9abcd3';
const comparisonPlotLine = '#e4cebc';
const nonMaintainedPlotBand = '#eaf6fc';
const maintainedPlotBand = '#e1e4eb';

// Fonts used on Regions page
const regionStoreCountFontLight = '#ffffff';
const regionStoreCountFontDark = '#181d1f';
// Backgrounds used on Regions page
const regionStoreCountColour1 = '#ffffff';
const regionStoreCountColour2 = '#fdd0a2';
const regionStoreCountColour3 = '#fdae6b';
const regionStoreCountColour4 = '#f16913';
const regionStoreCountColour5 = '#d94801';
const regionStoreCountColour6 = '#b27358';
const regionStoreCountColour7 = '#a96143';
const regionStoreCountColour8 = '#9f502e';
const regionStoreCountColour9 = '#963e19';
const regionStoreCountColour10 = '#8c2d04';

export default {
  darkSapphire,
  owSapphire,
  lightSapphire,
  owOnyx,
  lightOnyx,
  veryLightOnyx,
  owTopaz,
  lightTopaz,
  owEmerald,
  lightEmerald,
  owIolite,
  lightIolite,
  owCitrine,
  lightCitrine,
  owTurquoise,
  lightTurquoise,
  owRuby,
  lightRuby,
  owWhite,
  owBlack,
  assortmentGreyDark,
  assortmentGreyLight,
  assortmentBlueLight,
  assortmentBluePrimary,
  assortmentSecondaryBlue,
  assortmentRedPrimary,
  assortmentYellowPrimary,
  assortmentGreenPrimary,
  assortmentTealPrimary,

  assortmentEconomyColour,
  assortmentCompetitionColour,
  assortmentArchitectureColour,
  assortmentGridLineColor,
  assortmentChartScatter,
  assortmentDisabledTextButtonColour,

  tensionChartPositiveColor,
  tensionChartNegativeColor,

  competitorTrafficLightColor,
  architectureTrafficLightColor,
  economicTrafficLightColor,
  otherTrafficLightColor,
  inactiveTrafficLightColor,

  rulesetDarkBlue,
  scopeHighlightBlue,
  scopeHighlightRed,

  rulesetBackgroundGrey,
  rulesetLightBlue,

  penaltiesLightGreen,
  penaltiesScatterTextColour,

  chartColor1,
  chartColor2,
  chartColor3,
  chartColor4,
  chartColor5,
  chartColor6,
  chartColor7,
  chartColor8,

  orderedChartColors: [...map(clusteringColours, v => v)],

  scatterPlotColours: [plotColour1, plotColour2],

  reports: {
    baseline,
    comparison,
  },

  comparisonWaterfall: {
    distributionBar: plotColour1,
    plotLine: {
      baseline: baselinePlotLine,
      comparison: comparisonPlotLine,
    },
    plotBands: {
      nonMaintained: nonMaintainedPlotBand,
      maintained: maintainedPlotBand,
    },
  },
  plotLineColour,
  clusteringColours,
  regionStoreCountColours: {
    colour: [
      regionStoreCountFontDark,
      regionStoreCountFontDark,
      regionStoreCountFontDark,
      regionStoreCountFontLight,
      regionStoreCountFontLight,
      regionStoreCountFontLight,
      regionStoreCountFontLight,
      regionStoreCountFontLight,
      regionStoreCountFontLight,
      regionStoreCountFontLight,
    ],
    background: [
      regionStoreCountColour1,
      regionStoreCountColour2,
      regionStoreCountColour3,
      regionStoreCountColour4,
      regionStoreCountColour5,
      regionStoreCountColour6,
      regionStoreCountColour7,
      regionStoreCountColour8,
      regionStoreCountColour9,
      regionStoreCountColour10,
    ],
  },
};
