import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    [
      _c(VRow, { staticClass: "mb-3 d-flex flex-column" }, [
        _c("h2", { staticClass: "mb-1" }, [_vm._v(_vm._s(_vm.$tkey("title")))]),
        _vm._v(" "),
        _c("div", { staticClass: "editing-text" }, [
          _vm._v(_vm._s(_vm.$tkey("editing"))),
        ]),
      ]),
      _vm._v(" "),
      _c(
        VRow,
        { staticClass: "spacebreak-stats d-flex" },
        [
          _c(VCol, { staticClass: "d-flex align-center" }, [
            _c("h4", { staticClass: "mr-1" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$tkey("spaceAvailable")) +
                  " (" +
                  _vm._s(_vm.unitOfMeasure) +
                  "):\n        "
              ),
              _c("span", { staticClass: "unit-label" }, [
                _vm._v(
                  _vm._s(
                    _vm.formatNumber({
                      number: _vm.totalSpace,
                      format: "float",
                    })
                  )
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            VCol,
            { staticClass: "d-flex" },
            [
              _c("h4", { staticClass: "space-heading" }, [
                _vm._v(
                  _vm._s(_vm.$tkey("usableSpace")) +
                    " (" +
                    _vm._s(_vm.unitOfMeasure) +
                    "):"
                ),
              ]),
              _vm._v(" "),
              _c("rtls-text-field", {
                staticClass: "table-input",
                attrs: {
                  "run-validations-on-creation": "",
                  white: "",
                  "validate-on-blur": "",
                  value: _vm.formatNumber({
                    number: _vm.fillOverride,
                    format: "float",
                  }),
                  rules: _vm.fillOverrideRules,
                  "server-error": _vm.hasServerErrors,
                  disabled: _vm.isEditingDisabled,
                  "hide-details": "",
                  width: "50px",
                },
                on: {
                  blur: _vm.updateFillOverride,
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.updateFillOverride.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(VCol, { staticClass: "d-flex align-center" }, [
            _c("h4", { staticClass: "mr-1" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$tkey("setAside")) +
                  " (" +
                  _vm._s(_vm.unitOfMeasure) +
                  "):\n        "
              ),
              _c("span", { staticClass: "unit-label" }, [
                _vm._v(
                  _vm._s(
                    _vm.formatNumber({ number: _vm.setAside, format: "float" })
                  )
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(VCol, { staticClass: "d-flex align-center" }, [
            _c("h4", { staticClass: "mr-1" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$tkey("spaceUsed")) +
                  " (" +
                  _vm._s(_vm.unitOfMeasure) +
                  "):\n        "
              ),
              _c("span", { staticClass: "unit-label" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.formatNumber({
                        number: _vm.spaceUsed,
                        format: "float",
                      })
                    )
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(VCol, { staticClass: "d-flex align-center" }, [
            _c("h4", { staticClass: "mr-1" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$tkey("spaceFree")) +
                  " (" +
                  _vm._s(_vm.unitOfMeasure) +
                  "):\n        "
              ),
              _c("span", { staticClass: "unit-label" }, [
                _vm._v(
                  _vm._s(
                    _vm.formatNumber({ number: _vm.spaceFree, format: "float" })
                  )
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            VCol,
            { staticClass: "d-flex justify-end" },
            [
              _c(
                VBtn,
                {
                  attrs: {
                    disabled:
                      _vm.hasDataErrors ||
                      !_vm.hasDataChanges ||
                      _vm.isEditingDisabled,
                    primary: "",
                    action: "",
                    depressed: "",
                  },
                  on: { click: _vm.save },
                },
                [_vm._v(_vm._s(_vm.$t("actions.save")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }