var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "popup",
    {
      attrs: {
        id: "main-alert",
        title: _vm.$t("general.warning"),
        "is-open": _vm.hasMessagesToShow,
        width: "40%",
      },
      on: { close: _vm.dismiss },
    },
    [
      _c(
        "ol",
        _vm._l(_vm.messageContents, function (message, index) {
          return _c("li", { key: index }, [
            _vm._v("\n      " + _vm._s(message) + "\n    "),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }