<template>
  <v-container fluid>
    <v-layout column class="h-100">
      <v-flex class="d-flex flex-column">
        <v-row class="d-flex h-100 flex-column">
          <progress-bar v-if="isLoading" :message="$t('general.loading')" />
          <v-row v-else>
            <v-col class="px-0" :class="colClass">
              <reporting-menu />
            </v-col>
            <v-col>
              <div v-if="isValidSelection" id="reports-section" class="d-flex flex-column h-100">
                <reporting-tabs />
                <router-view />
              </div>
            </v-col>
          </v-row>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { get, size, some, map, isEqual, filter, concat, isEmpty, isNull } from 'lodash';
import reportingFilters from '@enums/reporting-filters';

export default {
  localizationKey: 'reporting',

  beforeRouteLeave(to, from, next) {
    const selections = { workpackageIds: [] };
    if (this.bundlesEnabled) selections.bundleIds = [];
    this.resetSelected(selections);

    return next();
  },

  async beforeRouteUpdate(to, from, next) {
    const selections = {};

    if (this.bundlesEnabled) {
      const bundleIds = get(to, 'query.bundleIds', []);
      const previousBundleIds = concat([], from.query.bundleIds || []);

      if (!isEqual(bundleIds, previousBundleIds)) {
        selections.bundleIds = bundleIds;
      }
    }
    const workpackageIds = get(to, 'query.workpackageIds', []);
    const previousWorkpackageIds = concat([], from.query.workpackageIds || []);

    if (!isEqual(workpackageIds, previousWorkpackageIds)) {
      selections.workpackageIds = workpackageIds;
    }
    // when selected bundles / workpackages are the same, we assume the user has changed filters,
    // which don't trigger more updates
    if (isEmpty(selections)) return next();

    // on selection change, reset all report section data
    this.resetSelected(selections, true);

    return next();
  },

  data() {
    return {
      noBundle: { _id: null, name: this.$t('reportingPage.noBundle') },
    };
  },

  computed: {
    ...mapState('bundles', ['bundles']),
    ...mapState('workpackages', ['workpackages']),
    ...mapState('reporting', [
      'selectedBundles',
      'selectedWorkpackages',
      'isLoading',
      'scenarios',
      'metricValue',
      'attributeValue',
      'isSidebarShown',
    ]),
    ...mapGetters('workpackages', ['workpackagesByBundle']),
    ...mapGetters('reporting', [
      'scenariosByWorkpackage',
      'getSelectionFromId',
      'getFirstCommonAttribute',
    ]),

    workpackageIds() {
      const workpackageIds = this.$route.query.workpackageIds || [];
      return concat([], workpackageIds);
    },

    bundleIds() {
      const bundleIds = this.$route.query.bundleIds;
      if (!bundleIds) return isNull(bundleIds) ? [null] : [];
      return concat([], bundleIds);
    },

    isValidSelection() {
      if (!this.bundlesEnabled) return this.isValidWorkpackage;
      return this.isValidBundles || this.isValidWorkpackage;
    },

    isValidBundles() {
      if (!size(this.selectedBundles)) return;
      const bundleIds = map(this.selectedBundles, '_id');
      const bundleHasWorkpackages = some(bundleIds, id => !!size(this.workpackagesByBundle[id]));
      return this.selectedBundles && bundleHasWorkpackages;
    },

    isValidWorkpackage() {
      if (!size(this.selectedWorkpackages)) return;
      const wpIds = map(this.selectedWorkpackages, '_id');
      const workpackagesHasScenarios = some(wpIds, id => !!size(this.scenariosByWorkpackage[id]));
      return this.selectedWorkpackages && workpackagesHasScenarios;
    },

    currentQueryValues() {
      return this.$route.query || {};
    },

    colClass() {
      return this.isSidebarShown ? 'col-3' : 'col-auto';
    },
  },

  created() {
    return this.loadAllWorkpackagesData();
  },

  methods: {
    ...mapMutations('reporting', [
      'setSelectedBundles',
      'setSelectedWorkpackages',
      'setIsLoading',
      'setSelection',
      'resetReportingFilters',
    ]),
    ...mapActions('bundles', ['fetchBundles']),
    ...mapActions('workpackages', ['fetchWorkpackages']),
    ...mapActions('reporting', [
      'loadWorkpackagesData',
      'fetchWorkpackagesScenarios',
      'resetFilters',
    ]),

    async loadAllWorkpackagesData() {
      // even if there are workpackages in state, we need to refetch it to pull this extra info
      await this.fetchWorkpackages({ pickExtra: ['fillInSelection'] });

      if (!this.scenarios.length) await this.fetchWorkpackagesScenarios();

      const selections = {};

      if (this.bundlesEnabled) {
        await this.fetchBundles({ pick: ['_id', 'name', 'creationDate'] });

        if (!size(this.selectedBundles) && size(this.bundleIds)) {
          selections.bundleIds = this.bundleIds;
        }
      }
      if (!size(this.selectedWorkpackages) && size(this.workpackageIds)) {
        // when loading for the first time (copy & paste full link), we take whatever is on the url is
        selections.workpackageIds = this.workpackageIds;
      }

      this.resetSelected(selections, true);
    },

    resetSelected(selections, loadWPData = false) {
      const { bundleIds, workpackageIds } = selections;

      if (bundleIds) this.resetBundlesSelected(bundleIds);
      if (workpackageIds) this.resetWorkpackagesSelected(workpackageIds);

      if (!loadWPData) return;
      return this.resetWPData();
    },

    resetBundlesSelected(bundleIds) {
      const bIds = new Set(bundleIds);
      const bundles = bIds.size
        ? filter([...this.bundles, this.noBundle], b => bIds.has(b._id))
        : [];
      this.setSelectedBundles(bundles);
    },

    resetWorkpackagesSelected(workpackageIds) {
      const wpIds = new Set(workpackageIds);
      const workpackages = wpIds.size ? filter(this.workpackages, w => wpIds.has(w._id)) : [];
      this.setSelectedWorkpackages(workpackages);
    },

    // this method resets all information loaded (scenarios, canvases, checkpoints, etc) with newly selected bundle / workpackage data
    async resetWPData() {
      this.setIsLoading(true);
      // manually pre-set the filters which directly interfere with the shown contents, otherwise, the fetch call
      // will return invalid aggregated data; then we can check if the new baseline & comparison are valid
      this.resetFilters({ baseline: [], comparison: [], comparison2: [] });
      // get last items first, then fetch scenarios
      await this.loadWorkpackagesData();

      // after reloading all data, check the query data
      const baseline = this.getValidSelections(reportingFilters.baseline);
      const comparison = this.getValidSelections(reportingFilters.comparison);
      const comparison2 = this.getValidSelections(reportingFilters.comparison2);

      // reset charts required filters so overview page loads with the correct info
      const nextAttributeValue = this.getFirstCommonAttribute({
        baselineIds: baseline,
        comparisonIds: comparison,
        comparison2Ids: comparison2,
      });
      this.setSelection({ field: 'attributeValue', value: nextAttributeValue });
      this.setSelection({ field: 'metricValue', value: this.getUrlQueryString('metricValue') });

      await this.resetFilters({ baseline, comparison, comparison2 });

      const wasBundlesChanged = this.bundlesEnabled
        ? !isEqual(this.selectedBundles, this.bundleIds)
        : false;
      const wasWorkpackagesChanged = !isEqual(this.selectedWorkpackages, this.workpackageIds);
      const wasBaselineModified = !isEqual(
        baseline,
        concat([], this.getUrlQueryString(reportingFilters.baseline))
      );
      const wasComparisonModified = !isEqual(
        comparison,
        concat([], this.getUrlQueryString(reportingFilters.comparison))
      );
      const wasComparison2Modified = !isEqual(
        comparison2,
        concat([], this.getUrlQueryString(reportingFilters.comparison2))
      );
      if (
        wasBaselineModified ||
        wasComparisonModified ||
        wasComparison2Modified ||
        wasBundlesChanged ||
        wasWorkpackagesChanged
      ) {
        const newParams = { ...this.currentQueryValues, baseline };
        if (wasComparisonModified) newParams.comparison = comparison;
        if (wasComparison2Modified) newParams.comparison2 = comparison2;
        if (wasBundlesChanged) newParams.bundleIds = this.bundleIds;
        if (wasWorkpackagesChanged) newParams.workpackageIds = this.workpackageIds;
        this.redirectUserToURL(newParams);
      }

      this.setIsLoading(false);
    },

    getUrlQueryString(field) {
      return this.$route.query[field] || null;
    },

    getValidSelections(dataLevel) {
      const selection = this.getSelectionFromId(this.getUrlQueryString(dataLevel));
      return map(selection, '_id');
    },

    redirectUserToURL(query, redirectToOverview = false) {
      // avoid adding unnecessary key to URL
      if (!query.baseline) delete query.baseline;
      if (!query.comparison) delete query.comparison;
      if (!query.comparison2) delete query.comparison2;
      if (!query.attributeValue) delete query.attributeValue;

      // updates URL to include new query params
      this.$router.push({
        path: redirectToOverview ? '/reporting-main' : this.$route.path, // keep on same path
        query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
</style>
