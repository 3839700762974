import i18n from '@/js/vue-i18n';
import axios from 'axios';
import to from 'await-to-js';
import { get, reduce, each, keyBy } from 'lodash';

export const name = 'compoundExtract';

const store = {
  namespaced: true,

  state: {
    extractSeries: [],
    extractSettings: [],
    extracts: [],
    previousExtracts: [],
    selectedExtract: null,
    selectedStep: null,
    selectedExtractFilter: null,
    loading: false,
    isProcessing: false,
  },

  getters: {
    getSelectedExtractStatus(state) {
      return get(state.selectedExtract, 'series.status', {});
    },

    previousExtracts(state) {
      return state.previousExtracts;
    },

    extractSeriesIdBySettingId(state) {
      return reduce(
        state.extractSeries,
        (acc, series) => {
          each(series.extractSettingsIds, id => {
            acc[id] = series._id;
          });
          return acc;
        },
        {}
      );
    },

    extractSeriesById(state) {
      return keyBy(state.extractSeries, '_id');
    },

    getExtractById: state => id => state.extracts.find(({ _id }) => _id === id),
  },

  mutations: {
    setExtractSeries(state, extracts) {
      state.extractSeries = extracts;
    },

    setExtracts(state, extracts) {
      state.extracts = extracts;
    },

    setPreviousExtracts(state, extracts) {
      state.previousExtracts = extracts;
    },

    addExtract(state, extract) {
      state.extractSeries.push(extract);
    },

    setSelectedExtract(state, extract) {
      state.selectedExtract = extract;
    },

    setSelectedExtractFilter(state, filter) {
      state.selectedExtractFilter = filter;
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setIsProcessing(state, isProcessing) {
      state.loading = isProcessing;
    },

    setSelectedStep(state, step) {
      state.selectedStep = step;
    },
  },

  actions: {
    async generateExtract({ commit }, type) {
      const [err, response] = await to(axios.post('/api/compound-extract/', { type }));
      if (err) throw new Error(err.message);

      commit('addExtract', response.data);
      commit('setSelectedExtract', response.data);
      return response.data;
    },

    async fetchExtracts({ commit }, { extractTypes } = {}) {
      const [err, response] = await to(
        axios.get('/api/compound-extract/', { params: { extractTypes } })
      );
      if (err) throw new Error(err.message);
      commit('setExtractSeries', response.data);
    },

    async fetchExtract({ commit }, extractSeriesId) {
      const [err, response] = await to(axios.get(`/api/compound-extract/${extractSeriesId}`));
      if (err) throw new Error(err.message);
      commit('setSelectedExtract', response.data);
    },

    async updateExtract({ state, dispatch }, extractData) {
      // TODO: ensure that extractData.settings are properly passed (sometimes they are not which is causing errors)
      let seriesId = null;
      // state.selectedExtract is expected to be set in all places apart of extract update on extract main page
      // when sending file to the ftp, in this case seriesId is passed in payload.
      if (state.selectedExtract) {
        extractData.settings._id = state.selectedExtract.settings._id;
        seriesId = state.selectedExtract.series._id;
      } else {
        seriesId = extractData.settings.series._id;
      }
      const [err] = await to(axios.patch(`/api/compound-extract/${seriesId}`, { extractData }));
      if (err) throw new Error(err.message);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
      if (state.selectedExtract) dispatch('fetchExtract', seriesId);
    },

    async fetchCompoundExtracts({ commit }, { extractTypes } = {}) {
      commit('setLoading', true);
      const [err, response] = await to(axios.get('/api/extracts', { params: { extractTypes } }));
      if (err) throw new Error(err.message);
      commit('setExtracts', response.data);
      commit('setLoading', false);
    },

    async fetchCompoundExtractsByType({ commit }, { extractType, extractSettingsId }) {
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/extracts/${extractType}?extractSettingsId=${extractSettingsId}`)
      );
      if (err) throw new Error(err.message);
      commit('setPreviousExtracts', response.data);
      commit('setLoading', false);
    },

    async generateCompoundExtract({ commit, state }, extract) {
      commit('setLoading', true);
      const [err] = await to(
        axios.post(`/api/extracts/compound-extract`, {
          ...extract,
          extractSettingsId: state.selectedExtract.settings._id,
        })
      );
      if (err) throw new Error(err.message);
      commit('setLoading', false);
    },

    async duplicateExtract({ commit }, extractData) {
      commit('setLoading', true);

      const [err, response] = await to(
        axios.post('/api/compound-extract/', {
          type: extractData.type,
          copyFromExisting: true,
          existingSeries: extractData.existingSeries,
          existingSettingsId: extractData.existingSettingsId,
        })
      );
      if (err) throw new Error(err.message);

      commit('addExtract', response.data);
      commit('setSelectedExtract', response.data);
      commit('setLoading', false);
      return response.data;
    },
  },
};

export default store;
