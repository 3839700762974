<template>
  <div data-id-e2e="activeBundles">
    <workpackages-toolbar
      doc-path="190-bundles"
      :entity="entities.bundle"
      @on-search="onSearch"
      @on-sort="onSort"
    />

    <v-card class="col-box">
      <v-alert v-if="loading" border="left" colored-border color="primary accent-4">
        <v-progress-circular indeterminate color="primary" size="20" />
        {{ $tkey('loadingActiveBundles') }}
      </v-alert>
      <v-row no-gutters>
        <v-col class="bundles__container">
          <workpackages-list
            v-if="sortedBundlesList.length"
            :entity="entities.bundle"
            :visible-workpackages="visibleBundles"
            :workpackages="sortedBundlesList"
          />
          <v-alert v-if="!loading && !visibleBundles.length" type="info" text>
            {{ $tkey('noActiveBundles') }}
          </v-alert>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import entities from '@enums/entities';
import sortUtils from '../../../utils/sort-utils';
import searchUtils from '../../../utils/search-utils';

export default {
  name: 'ActiveBundles',
  localizationKey: 'bundlesPage',

  data() {
    return {
      sortOrder: null,
      searchText: '',
      entities,
    };
  },

  computed: {
    ...mapState('bundles', ['bundles', 'loading']),

    visibleBundles() {
      return searchUtils.getResultsByNameDescription(this.searchText, this.bundles, this.sortOrder);
    },

    sortedBundlesList() {
      return this.sortOrder ? sortUtils.sortItemList(this.bundles, this.sortOrder) : this.bundles;
    },
  },

  async created() {
    // initialise getting all bundles
    await this.fetchBundles();
  },

  methods: {
    ...mapMutations('bundles', ['setSelectedBundle', 'setWorkpackagePanelExpanded']),
    ...mapActions('bundles', ['fetchBundles']),

    onSort(sortOrder) {
      this.sortOrder = sortOrder;
    },

    onSearch(searchText) {
      this.searchText = searchText;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.bundles {
  &__container {
    overflow-x: auto;
  }
}

::v-deep {
  .progress-bar {
    padding-bottom: 10em;
  }
}
</style>
