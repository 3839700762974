var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100 w-100 pl-3" },
    [
      _c("ag-grid-vue", {
        class: _vm.heatmapClass,
        attrs: {
          id: "switching-heatmap-grid",
          "grid-options": _vm.gridOptions,
          "column-defs": _vm.columnDefs,
          "row-data": _vm.gridData.data,
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
      _vm._v(" "),
      _vm.gridData
        ? _c(
            "div",
            { staticClass: "legend" },
            _vm._l(_vm.dataClasses, function (dataClass) {
              return _c(
                "div",
                {
                  key: `dataClass-${dataClass.value}`,
                  staticClass: "legend-item",
                  style: {
                    "background-color": _vm.getDataClassBackgroundColor(
                      dataClass.value
                    ),
                    color: _vm.getTextColor(dataClass.value),
                  },
                },
                [_vm._v("\n      " + _vm._s(dataClass.text) + "\n    ")]
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }