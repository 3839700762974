import OktaLoginPage from './okta-login.vue';

const routes = [
  {
    path: '/ow-auth/login',
    name: 'ow-auth/login',
    id: 'ow-auth/login',
    component: OktaLoginPage,
    meta: { hideNavbar: true },
    omitFromNav: true,
  },
];

export default routes;
