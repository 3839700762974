// Libraries
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuetify from 'vuetify';
import VueMoment from 'vue-moment';
import draggable from 'vuedraggable';
import HighchartsVue from 'highcharts-vue';
import { RecycleScroller } from 'vue-virtual-scroller';
import VueDraggableResizable from 'vue-draggable-resizable';
import VueObserveVisibility from 'vue-observe-visibility';
import { get } from 'lodash';

// eslint-disable-next-line import/no-duplicates
import 'ag-grid-enterprise';
// eslint-disable-next-line import/no-duplicates
import { LicenseManager } from 'ag-grid-enterprise';

import { datadogRum } from '@datadog/browser-rum';

// Importing UI Components from RTLS UI Components
import rtlsUiComponents from '@rtls/rtls-ui-components';

// Importing Icon Components
import icons from '@rtls/rtls-ui-components/src/icons';
import customIcons from '@/js/utils/custom-icons';
import vuePlugins from '@/js/utils/vue-plugins/index';
import App from '@/app.vue';
import store from '@/js/store';
import Axios from '@/js/axios';
import router from '@/js/router';
import eventsMixin from '@/js/mixins/events';
import formatNumberMixin from '@/js/mixins/format-number';
import hasPermissionMixin from '@/js/mixins/has-permission';
import '@/js/idle-user-timeout';
import i18n from '@/js/vue-i18n';

// Styles
// Importing Stylesheet from Vuetify
import 'vuetify/dist/vuetify.css';
// Importing Stylesheet from RTLS UI Components Library
import '@rtls/rtls-ui-components/dist/rtls-ui-components.css';

// Import stylesheet from leaflet
import 'leaflet/dist/leaflet.css';
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';

// Import styles from vue-treeselect
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

// Import Local Styles
import '@style/main.scss';

// ag-grid styles
// eslint-disable-next-line import/no-relative-packages, import/no-unresolved
import './node_modules/ag-grid-community/dist/styles/ag-grid.css';
// eslint-disable-next-line import/no-relative-packages, import/no-unresolved
import './node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css';

// Import numeralJS locales
import '@/lang/numeral';

const initialiseDatadog = context => {
  // Initialise Datadog RUM if not disabled for environment
  if (!get(context.datadog[context.env], 'disabled')) {
    datadogRum.init({
      applicationId: context.datadog.applicationId,
      clientToken: context.datadog.clientToken,
      site: 'datadoghq.eu',
      service: 'assortment-webtool',
      env: context.env,
      version: context.versionTag,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackFrustrations: true,
      trackLongTasks: true,
      trackResources: true,
      enableExperimentalFeatures: ['clickmap'],
      allowedTracingUrls: [context.clientUrl],
    });
    datadogRum.startSessionReplayRecording();
    datadogRum.setGlobalContextProperty('subBrand', context.subBrand);
  }
};

// Register components
Vue.component('draggable', draggable);
Vue.component('vue-draggable-resizable', VueDraggableResizable);

// Extend Vue with libraries
Vue.use(Vuetify);
Vue.use(vuePlugins);

Vue.use(VueMoment);
Vue.use(HighchartsVue);
Vue.use(VueObserveVisibility);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        // Setting primary app colours for material theme
        primary: '#2f477c',
        accent: '#2f477c',
      },
    },
  },
  icons: {
    values: {
      ...icons.values,
      ...customIcons.values,
    },
  },
});

// Use RTLS UI Components
Vue.use(rtlsUiComponents);

// Import all the available client components/filters/directives from this project
Vue.registerComponents(require.context('./src/js/components/', true, /(\.html|\.js|\.vue)$/));
Vue.registerComponents(require.context('./src/js/pages/', true, /(\.html|\.js|\.vue)$/));
Vue.registerDirectivesAndFilters(require.context('./src/js/directives/', true, /\.js$/));
Vue.registerDirectivesAndFilters(require.context('./src/js/filters/', true, /\.js$/));
Vue.component('recycle-scroller', RecycleScroller);

Vue.use(Axios, { router, store, i18n });
Vue.use(VueRouter);

// Global mixins
Vue.mixin(eventsMixin);
Vue.mixin(formatNumberMixin);
Vue.mixin(hasPermissionMixin);

// Set ag-grid license
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-054150}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Wyman_Oliver_Inc}_is_granted_a_{Multiple_Applications}_Developer_License_for_{6}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{14}_Production_Environments___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{24_February_2025}____[v3]_[01]_MTc0MDM1NTIwMDAwMA==e41778debb5bf8aae8a4f23395613f6c'
);

// Load app context+translations and try to load current profile (get 401 otherwise and we navigate to the login page)
Promise.all([
  store.dispatch('context/loadAppContext'),
  store.dispatch('context/loadUserContext'),
]).finally(() => {
  initialiseDatadog(store.state.context);

  // Start the Vue application
  return new Vue({
    vuetify,
    i18n,
    router,
    store,
    el: '#vueholder',
    render: h => h(App),
  });
});
