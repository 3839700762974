<template>
  <v-form ref="form" v-model="valid" autocomplete="off" @submit.prevent="createNewScenario">
    <v-container>
      <v-row v-if="isOpen" class="form-body">
        <v-col>
          <div class="form-container">
            <rtls-text-field
              :key="nameKey"
              v-model.trim="scenarioName"
              :rules="rules"
              :server-error="scenarioNameServerError"
              :label="$t('scenarios.scenarioName')"
              @change="scenarioNameServerError = ''"
            />
            <v-btn save class="float-right" :disabled="!valid" type="submit">
              {{ $t('actions.save') }}
            </v-btn>
          </div>
        </v-col>
        <v-spacer />
        <v-spacer />
      </v-row>
      <v-row>
        <v-btn
          id="button-add"
          :disabled="isOpen || !hasPermission(userPermissions.canCreateScenario)"
          primary
          @click="isOpen = true"
        >
          {{ $t('scenarios.newScenario') }}
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { isEmpty } from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import { Errors } from '@enums/scenario-errors';
import inputValidationMixin from '@/js/mixins/input-validations';

const { mapActions, mapGetters } = createNamespacedHelpers('scenarios');

export default {
  mixins: [inputValidationMixin],
  props: {
    workpackageId: { required: true, type: String },
  },

  data() {
    return {
      valid: true,
      isOpen: false,
      nameKey: false,
      scenarioName: '',
      scenarioNameServerError: '',
      rules: [this.required, this.isUniqueScenarioName],
    };
  },

  computed: {
    ...mapGetters(['scenarios']),
  },

  async created() {
    await this.fetchWpScenarios();
  },

  methods: {
    ...mapActions(['createScenario', 'fetchScenarios']),

    async fetchWpScenarios() {
      this.nameKey = !this.nameKey; // force rtls-text-field to re-render with new prop value
      await this.fetchScenarios({ params: { where: { workpackageId: this.workpackageId } } });
    },

    async createNewScenario() {
      const scenario = {
        name: this.scenarioName,
        workpackageId: this.workpackageId,
      };
      const { data, errors } = await this.createScenario({ scenario });
      this.$emit('save:scenario');

      if (!isEmpty(errors) && errors.filter(e => e.code === Errors.ScenarioNotUnique).length) {
        this.scenarioNameServerError = this.$t('validationErrors.unique', [
          this.$t('scenarios.scenarioName'),
        ]);
      }
      // if scenario was created (data object is not empty) reset data to defaults
      if (!isEmpty(data)) {
        this.isOpen = false;
        this.scenarioName = '';
      }

      await this.fetchWpScenarios();
    },

    isUniqueScenarioName(value) {
      if (this.scenarios.filter(sc => sc.name === value.trim()).length) {
        return this.$t('validationErrors.unique', [this.$t('scenarios.scenarioName')]);
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

form {
  background-color: $assortment-background;
}

.container {
  background-color: $assortment-background;
  padding: 5px 0 10px 0;
  margin: 0;

  .col {
    padding-left: 6px;
  }

  .form-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .v-btn {
      margin-left: 20px;
    }

    ::v-deep .error-triangle-container {
      padding-left: 5px;
    }
  }

  .form-body {
    border-top: 1px solid $assortment-secondary-colour;
    border-bottom: 1px solid $assortment-secondary-colour;
    margin-bottom: 10px;

    //deep selector to select deep nested elements within v-input class
    ::v-deep {
      .v-input {
        width: 248px;
        padding: 0px;
        margin: 0px;
        input,
        label {
          padding-left: 7px;
          font-size: 1.2rem;
        }
        input {
          max-height: 28px;
          padding-top: 9px;
        }
        label {
          margin-top: -1px;
          padding-top: 0px;
        }
        .v-input__slot {
          margin-bottom: 0px;
        }
        .v-text-field__slot {
          background-color: white;
        }
        .v-text-field__details {
          //if display none this will hide error messages but leaves red
          display: block;
        }
      }
    }
  }
}
</style>
