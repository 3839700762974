<template>
  <div
    :id="`product-tile-popup-${product.productKey}`"
    ref="main-product-tile"
    class="d-flex flex-column default-product-box"
    :class="{
      'medium-margin': selectedTileSize === 'medium',
      'selected-product': isProductSelected,
    }"
    :style="getContainerSizes"
    @click.stop.prevent="openPopup"
  >
    <!-- The draggable popup -->
    <product-draggable-tooltip
      v-if="show"
      :selected-kpis="clientConfig.selectedKpis"
      :product-info="product"
      :product-index="index"
      :pod-and-store-count-info="
        podAndStoreCounts(product, selectedCanvas.clusterId, spacebreaksIndexedByIdByCluster)
      "
      :product-positioning-info="
        extractProductPositioningInfo(
          product,
          selectedCanvas.clusterId,
          spacebreaksIndexedByIdByCluster,
          selectedPod
        )
      "
      :settings="tooltipSettings"
      :image-url="imageUrl"
      :image-title="displayText"
      :image-alt="displayText"
      :conditional-product-tile-style="dashboardProductTileSize"
      :spacebreaks="spacebreaksNames"
      :spacebreak-disabled="isSpacebreakLocked(spacebreakId) || isEditingDisabled"
      :spacebreak-types="spacebreakTypes"
      :selected-spacebreak="selectedSpacebreak"
      :change-spacebreak="changeSpacebreak"
      :lock-types="lockTypes"
      :popup-button="popupButton"
      :image-tag-text="getProductTag"
      :product-pallets="productPallets"
      :current-numeric-locale="currentNumericLocale"
      @close-popup="closePopup"
    />
    <div
      :class="{
        'spacebreak-disabled': isSpacebreakLocked(spacebreakId),
      }"
    >
      <highlighted-area v-if="product.isHighlighted" :outer-style="getHighlightContainerSize" />

      <!-- Actual products identifiers -->
      <!-- First icons row-->
      <div class="d-flex product-tile-header w-100 top-row" :style="productDetailsStyle">
        <div
          v-if="product.productSeries"
          class="d-flex product-icon"
          style="cursor:pointer"
          @click.stop="onProductSeriesIconClick"
        >
          <series-icon />
        </div>
        <div v-else class="h-100" style="width: 18px" />
        <div v-if="hasPallets" class="d-flex product-icon">
          <pallet-icon :fill="hasAssignedPallets ? iconPrimary : iconDisabled" />
        </div>
        <v-icon
          :disabled="
            isSpacebreakLocked(spacebreakId) || isEditingDisabled || !inReset || !canProductBeMoved
          "
          color="white"
          class="d-flex product-icon lock-icon"
          :class="{ 'lock-icon__locked': isLocked }"
          @click.stop="toggleLock"
        >
          {{ isLocked ? 'mdi-lock-outline' : 'mdi-lock-open-variant-outline' }}
        </v-icon>
      </div>
      <!-- Second icons row-->
      <div class="d-flex product-tile-header w-100 middle-row" :style="productDetailsStyle">
        <v-icon
          v-if="showMinimumDistribution && product.minimumDistribution > 0"
          color="white"
          size="18"
          width="18"
          class="d-flex product-icon"
        >
          {{ 'mdi-speedometer' }}
        </v-icon>
        <div v-else class="w-100 h-100" />
        <div v-if="!inReset" class="d-flex product-icon pull-right">
          <not-in-reset-icon color="white" />
        </div>
        <div v-else class="w-100 h-100" />
      </div>
      <!-- Bottom icons row -->
      <div class="d-flex product-tile-header w-100 bottom-row" :style="productDetailsStyle">
        <product-delta-tag
          :show="referenceDelta !== 0 && product.isEligible"
          type="R"
          :delta="referenceDelta"
        />
        <assortment-tag :tag-text="getProductTag" />
        <product-delta-tag :show="shouldShowOptimisedDelta" type="O" :delta="lastOptimisedDelta" />
      </div>

      <div class="d-flex product-tile" :style="baseStyle">
        <assortment-image
          :src="imageUrl"
          :alt="displayText"
          :title="displayText"
          :tile-size="selectedTileSize"
          :tooltip-text="tooltipText"
          :tag-text="getProductTag"
          :tag-position-relative="false"
          v-bind="baseStyle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { get, isEmpty } from 'lodash';
import { lockTypes } from '@enums/assortment-product-lock-types';
import { spacebreakTypes } from '@enums/spacebreak-types';
import productUtils from '../../utils/product-utils';
import productMixin from './product.mixin';
import destroy from '../../utils/destroy';

export default {
  mixins: [productMixin], // includes methods extractProductPositioningInfo
  localizationKey: 'assortmentCanvasPage.productTile',
  props: {
    product: {
      type: Object,
      required: true,
    },
    selectedTileSize: {
      type: String,
      required: true,
    },
    tileViewRef: {
      type: Object,
      required: true,
    },
    spacebreakId: {
      type: String,
      default: null,
    },
    openPopupOnLoadForProductId: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      show: false,
      tooltipSettings: null,
      zIndex: null,
      imageTag: 'img',
      lockTypes,
      spacebreakTypes,
      popupButton: {
        label: this.$t('assortmentCanvasPage.tooltip.productDashboard'),
        action: this.openProductDashboard,
      },
      productPallets: [],
      iconPrimary: 'white',
      iconDisabled: '#d9d9d9',
    };
  },

  computed: {
    ...mapState('assortmentCanvas', [
      'tileSize',
      'tileViewContainerHeight',
      'popUpZindex',
      'selectedPod',
      'selectedDashboardProduct',
    ]),
    ...mapState('context', ['clientConfig']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapGetters('context', ['getClientConfig']),
    ...mapGetters('furniture', ['getSpacebreaksIndexedById']),
    ...mapGetters('assortmentCanvas', [
      'selectedCanvas',
      'isSpacebreakLocked',
      'canvasProducts',
      'canvasProductsPallets',
    ]),
    ...mapGetters('context', ['getCurrentNumericLocale']),

    currentNumericLocale() {
      return this.getCurrentNumericLocale;
    },

    hasMinimumDistributionEnabled() {
      return get(this.getClientConfig, 'features.minimumDistributionEnabled', false);
    },

    showMinimumDistribution() {
      return (
        this.hasMinimumDistributionEnabled &&
        get(this.selectedScenario, 'optionalAttributes.useMinimumDistribution')
      );
    },

    isLocked() {
      return this.product.lockType === lockTypes.locked || !this.inReset;
    },

    inReset() {
      return get(this.product, 'inReset', false);
    },

    isOptimised() {
      return get(this.selectedCanvas, 'hasBeenOptimised', false);
    },

    lastOptimisedDelta() {
      return this.product.deltas ? this.product.deltas.lastOptimised : 0;
    },

    referenceDelta() {
      return this.product.deltas ? this.product.deltas.reference : 0;
    },

    displayText() {
      return this.product.itemDescription || this.product.productKeyDisplay;
    },

    tooltipText() {
      return `${this.product.productKeyDisplay} - ${this.product.itemDescription}`;
    },

    productDetailsStyle() {
      return {
        width: `${this.tileSize[this.selectedTileSize].productImage.width}px`,
      };
    },

    baseStyle() {
      return {
        ...this.productDetailsStyle,
        'margin-top': `${this.tileSize[this.selectedTileSize].productImage['margin-top']}px`,
        height: `${this.tileSize[this.selectedTileSize].productImage.height}px`,
      };
    },

    dashboardProductTileSize() {
      return {
        width: '80px',
        height: '80px',
      };
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAssortmentCanvas);
    },

    hasKPKUEnabled() {
      return get(this.getClientConfig, 'features.kpkuEnabled', false);
    },

    futureProductIdString() {
      return productUtils.futureProductIdString(this.product.kpku, this.product.futureId);
    },

    hasPallets() {
      return !isEmpty(this.productPallets);
    },

    hasAssignedPallets() {
      return this.hasPallets && this.productPallets.some(p => p.currentSpacebreakId);
    },

    isProductSelected() {
      // there might not be a selected product
      return this.product.productKey === get(this.selectedDashboardProduct, 'productKey', null);
    },

    getContainerSizes() {
      return {
        height: `${this.tileSize[this.selectedTileSize].tile.height}px`,
        width: `${this.tileSize[this.selectedTileSize].tile.width}px`,
      };
    },

    getHighlightContainerSize() {
      // Deduce existing border size
      return {
        height: `${this.tileSize[this.selectedTileSize].tile.height - 4}px`,
        width: `${this.tileSize[this.selectedTileSize].tile.width - 4}px`,
      };
    },

    shouldShowOptimisedDelta() {
      return this.isOptimised && this.lastOptimisedDelta !== 0 && this.product.isEligible;
    },
  },

  mounted() {
    // Determine if needs to open the popup on render
    if (this.openPopupOnLoadForProductId === this.product._id) {
      // Highlight the product
      const productTile = this.$refs['main-product-tile'];
      productUtils.addActiveSearchBorderToProductElement(productTile);

      // Need to map the position of the element
      const position = {
        clientX: productTile.top,
        clientY: productTile.left + 120,
      };
      this.openPopup(position);
    }
    this.product.futureProductIdString = this.hasKPKUEnabled ? this.futureProductIdString : null;
    this.getProductPallets(this.product.productKeyDisplay);
  },

  beforeDestroy() {
    destroy.destroyReactiveVueProps(this);
  },

  methods: {
    ...mapActions('assortmentCanvas', ['updateCanvasProduct', 'changeLockType']),
    ...mapMutations('assortmentCanvas', ['incrementPopUpZindex', 'setIsProductSidebarOpen']),

    toggleLock() {
      this.changeLockType({ product: this.product, isLocked: this.isLocked });
    },

    closePopup() {
      this.show = false;
    },

    getProductPallets(productKeyDisplay) {
      this.productPallets = this.canvasProductsPallets[productKeyDisplay];
    },

    openPopup(event) {
      const parentProductTile = this.$refs['main-product-tile'];

      // Calculate total height and width of tile

      const parentProductTileWidth = parentProductTile.getBoundingClientRect().width;
      const parentProductTileHeight = parentProductTile.getBoundingClientRect().height;

      this.incrementPopUpZindex();

      // get pallets for the product
      this.getProductPallets(this.product.productKeyDisplay);

      this.zIndex = this.popUpZindex;

      let adjustmentY = 0;
      let adjustmentX = 0;

      const width = this.clientConfig.canvasPopUpWidth;
      const height = this.clientConfig.canvasPopUpHeight;

      // calculate height of main tile-view container

      const parentContainerHeight = this.tileViewRef['tile-view-container'].getBoundingClientRect()
        .height;

      // adjust the x and y positioning of tooltip if the user has clicked on a tile which would render tooltip outside of visible screen.
      if (window.innerWidth - event.clientX < width + parentProductTileWidth / 2) {
        adjustmentX = width;
      }

      if (
        window.innerHeight - event.clientY < height + parentProductTileHeight / 2 ||
        parentContainerHeight - event.clientY < height + parentProductTileHeight / 2
      ) {
        adjustmentY = height;

        if (!this.hasPalletsEnabled) {
          // when the user clicks near the top of the container, adjust Y value to offset popup from top of container.
          if (event.clientY < 700) adjustmentY -= height / 2 + parentProductTileHeight / 2;
          // add adjustment for when popup is opened near very top of page
          if (event.clientY < 500) adjustmentY -= parentProductTileHeight / 2;
        } else {
          // when the user clicks near the top of the container, adjust Y value to offset popup from top of container.
          if (event.clientY < 800) adjustmentY -= height / 2 + parentProductTileHeight / 2;
          // add adjustment for when popup is opened near very top of page
          if (event.clientY < 550) adjustmentY -= parentProductTileHeight / 2;
          if (event.clientY < 450) adjustmentY -= parentProductTileHeight;
        }
      }

      // dividing the width and height by 2 points the corner of the popup to the center of the tile
      this.tooltipSettings = {
        left: parentProductTileWidth / 2 - adjustmentX,
        top: parentProductTileHeight / 2 - adjustmentY,
        width,
        height,
        zIndex: this.zIndex,
      };
      this.show = true;
    },

    onProductSeriesIconClick() {
      this.setIsProductSidebarOpen(true);
      this.setDashboardProduct(this.product);
      productUtils.scrollToProductInCanvas(this.product);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.medium-margin {
  margin: 5px;
}

.icon {
  color: $product-icons-colour;
  font-size: 16px !important;
  height: 16px;
  cursor: pointer;
  display: block;
}

.product-tile {
  transition: all 1s ease;
  background-position: center center;
  background-size: contain;
  color: #000000;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  font-size: 14px;
  text-overflow: ellipsis;
}

.spacebreak-disabled {
  opacity: 30%;
}

.product-tile-header {
  position: absolute;
  height: 18px; // affects middle row
  justify-content: space-between;

  &--description {
    top: 21px;
  }
}

.top-row {
  top: 2px; // affects .middle-row
}

.middle-row {
  // .product-tile-header[height] + .top-row[top]
  top: 20px; // affects assortment-image
  margin-top: 1px;
}

.bottom-row {
  bottom: 2px;
  top: unset;
  color: $product-icons-colour;
  height: 13px;

  & span {
    font-size: 10px;
    text-align: center;
    font-weight: 400;
    color: $product-icons-colour;
    align-self: center;
  }
}

.lock-icon {
  visibility: hidden;
  transition: 0s;

  &__locked {
    visibility: initial;
  }

  ::v-deep {
    div {
      height: 18px;
    }
  }
}

.default-product-box:hover {
  .lock-icon {
    visibility: initial;
  }
}
</style>
