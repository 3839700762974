<template>
  <div class="date-input date-input-exclude">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-combobox
          :value="formattedDates"
          multiple
          :placeholder="$t('workpackagePage.scope.datesLabel')"
          v-on="on"
          @input="selectWeeks"
        >
          <template slot="append">
            <v-icon small @click="menu = true">$date-picker</v-icon>
          </template>
        </v-combobox>
      </template>
      <v-date-picker
        :value="value"
        :first-day-of-week="1"
        :allowed-dates="allowedDates"
        multiple
        no-title
        scrollable
        @input="selectWeeks"
      >
        <v-spacer />
        <v-btn text color="primary" @click="clearSelectedDates">{{ $t('actions.unselect') }}</v-btn>
        <v-btn text color="primary" @click="menu = false">{{ $t('actions.cancel') }}</v-btn>
        <v-btn text color="primary" @click="updateSelectedDates">{{ $t('actions.ok') }}</v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    allowedDates: {
      type: Function,
      required: false,
      default: () => true,
    },
    value: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    ...mapGetters('context', ['getDateFormats', 'getDefaultReverseFormat']),

    formattedDates() {
      return this.value
        .filter(date => moment.utc(date).isoWeekday() === 1) // only show Mondays
        .map(date => moment.utc(date).format(this.getDateFormats.long));
    },
  },
  methods: {
    // hack to select weeks. should be removed if feature request is accepted. https://github.com/vuetifyjs/vuetify/issues/4566
    selectWeeks(dates) {
      if (dates.length > this.value.length) {
        const dateToAdd = dates.filter(d => !this.value.includes(d))[0];
        const newDates = [...this.value, ...this.dateToWeek(dateToAdd)];
        this.$emit('input', newDates);
      } else {
        const dateToRemove = this.value.filter(d => !dates.includes(d))[0];
        const weekToRemove = this.dateToWeek(dateToRemove);
        this.$emit('input', this.value.filter(d => !weekToRemove.includes(d)));
      }
    },
    dateToWeek(date) {
      const startDate = moment.utc(date).startOf('isoWeek');
      const endDate = moment.utc(date).endOf('isoWeek');
      return this.datesToRange({ startDate, endDate });
    },
    datesToRange({ startDate, endDate }) {
      const dates = [];
      while (moment.utc(endDate).diff(startDate, 'day', true) > 0) {
        dates.push(startDate.format(this.getDefaultReverseFormat));
        startDate = moment.utc(startDate).add(1, 'day');
      }
      return dates;
    },
    updateSelectedDates() {
      this.$refs.menu.save(this.value);
    },
    clearSelectedDates() {
      this.$emit('input', []);
      this.updateSelectedDates();
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select.v-select--chips .v-select__selections {
  min-height: auto !important;
}

.date-input {
  &.date-input-exclude {
    width: 100%;
    ::v-deep {
      .v-input,
      .v-select {
        max-width: 223px;
        margin: 0;
        padding: 0;
      }
      .v-input__slot {
        background: white;
        border-bottom: blue 1px solid !important;
        width: 100% !important;
        height: auto !important;

        .v-select__slot {
          width: 100% !important;
          height: auto !important;
        }
      }
      .v-select__selections {
        padding-left: 3px;
        max-height: 100px;
        overflow-y: scroll;

        input {
          max-height: 50px;
          text-overflow: ellipsis;
          padding-top: 3px;
          padding-bottom: 3px;
        }
      }
      .v-select__selection--comma {
        margin-bottom: 3px;
        margin-top: 3px;
        padding-left: 0px;
      }
    }
  }
}
</style>
