import { get, intersection, keys, isEmpty, uniq, groupBy } from 'lodash';
import i18n from '@/js/vue-i18n';

const createValidationMessages = function({ errors, options }) {
  if (!errors.length) return '';
  const uniqueErrorKeys = [];
  errors.forEach(e => {
    const key = get(e, 'error_code');
    if (!uniqueErrorKeys.includes(key)) {
      uniqueErrorKeys.push(key);
    }
  });

  return uniqueErrorKeys.map(key => {
    const errs = errors.filter(err => err.error_code === key);
    const data = errs.map(e => {
      const msgKeys = keys(get(e, 'params'));
      const value = get(e, 'params.value');
      const preferredKeys = ['productKeyDisplay', 'workpackage_name', 'name'];
      const selectedKey = intersection(preferredKeys, msgKeys);

      const msgKey = !isEmpty(selectedKey) ? selectedKey[0] : msgKeys[0];
      const emptyValues = ['', null, undefined, [], 'None', '[]'];
      let valueWarning;

      if (!emptyValues.includes(value) && !isEmpty(value)) {
        valueWarning = `(${i18n.t(
          `jobsValidation.${options.errorSuffix || 'invalidValue'}`
        )}: ${value})`;
      }

      const message = `${get(e, `params.${msgKey}`)}${valueWarning ? ` ${valueWarning}` : ''}`;

      if (options.displayEmpty) return message;

      return `${get(e, `params.${msgKey}`)}`;
    });
    return {
      messageKey: `jobsValidation.${key}`,
      data: uniq(data),
    };
  });
};

const showValidationMessages = function({ errors, dispatch, options }) {
  if (!errors) {
    return;
  }
  const messages = createValidationMessages({ errors, options });
  if (messages) {
    dispatch('alerts/showMessages', messages, { root: true });
  }
};

// only certain Joi exceptions are handled
const handleJoiExceptions = ({ errors, dispatch }) => {
  const types = groupBy(errors.details, 'type');
  const messages = [];

  if (types['number.unsafe']) {
    const allFieldsWithErrors = new Set(types['number.unsafe'].map(r => r.context.key));
    const translatedFields = Array.from(allFieldsWithErrors).map(key =>
      i18n.t(`mongoFields.${key}`)
    );

    messages.push({
      messageKey: 'errors.genericBigValuesForFields',
      data: translatedFields.join(', '),
    });
  }

  if (messages.length) {
    dispatch('alerts/showMessages', messages, { root: true });
  }
};

const handleErrorMessages = function({
  response,
  dispatch,
  options = { catchJoi: false, displayEmpty: true },
}) {
  if (get(response, 'data.messageType') !== 'JobValidation') {
    const responseData = get(response, 'data');
    if (!options.catchJoi || !get(responseData, 'isJoi', false)) return;
    return handleJoiExceptions({ errors: responseData, dispatch, options });
  }
  showValidationMessages({ errors: get(response, 'data.data'), dispatch, options });
};

export default handleErrorMessages;
