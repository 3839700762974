<template functional>
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SeriesIcon" transform="translate(2, 2)scale(1.2)">
      <path
        :fill="props.fill"
        d="M8.1571 3.99893C8.42275 4.26458 8.42275 4.69528 8.1571 4.96093L4.9192 8.19883C4.65355 8.46448 4.22285 8.46448 3.9572 8.19883C3.69155 7.93318 3.69155 7.50248 3.9572 7.23683L7.1951 3.99893C7.46075 3.73328 7.89145 3.73328 8.1571 3.99893Z"
      />
      <path
        :fill="props.fill"
        d="M11.0012 1.15485C11.3364 1.48463 11.5976 1.87235 11.7798 2.30594C11.9628 2.74168 12.0572 3.20957 12.0572 3.68221C12.0572 4.15485 11.9629 4.62275 11.7798 5.05848C11.5971 5.49308 11.3298 5.88698 10.9935 6.21723L10.5995 6.6112C10.2693 6.94756 9.73476 7.28636 9.44077 7.39746C9.14678 7.50856 8.49737 7.70578 8.28721 7.08342C8.07706 6.46106 8.67939 6.23476 8.91126 6.13733C9.17935 6.02468 9.42224 5.85963 9.62576 5.65192L10.0342 5.24346C10.2419 5.03995 10.407 4.79706 10.5196 4.52897C10.6323 4.26088 10.6903 3.97301 10.6903 3.68221C10.6903 3.39142 10.6323 3.10354 10.5196 2.83546C10.4079 2.56968 10.235 2.31884 10.0298 2.11641C9.82738 1.91123 9.58635 1.74809 9.32058 1.63642C9.05249 1.52376 8.76462 1.46574 8.47382 1.46574C8.18303 1.46574 7.89516 1.52376 7.62707 1.63642C7.35898 1.74907 7.11609 1.91411 6.91257 2.12182L6.50411 2.53028C6.2964 2.7338 6.13136 2.97669 6.01871 3.24477C5.92127 3.47665 5.69498 4.07898 5.07262 3.86882C4.45025 3.65867 4.64748 3.00925 4.75858 2.71526C4.86968 2.42127 5.20848 1.88677 5.54484 1.55651L5.93881 1.16255C6.26906 0.826188 6.66295 0.558905 7.09755 0.376284C7.53329 0.193186 8.00118 0.098877 8.47382 0.098877C8.94647 0.098877 9.41436 0.193186 9.85009 0.376284C10.2837 0.558482 10.6714 0.81959 11.0012 1.15485Z"
      />
      <path
        :fill="props.fill"
        d="M1.11314 11.0429C1.44292 11.3782 1.83063 11.6393 2.26423 11.8215C2.69996 12.0046 3.16785 12.0989 3.64049 12.0989C4.11314 12.0989 4.58103 12.0046 5.01676 11.8215C5.45136 11.6388 5.84526 11.3716 6.17551 11.0352L6.56948 10.6412C6.90584 10.311 7.24464 9.77648 7.35574 9.48249C7.46684 9.1885 7.66407 8.53909 7.0417 8.32893C6.41934 8.11878 6.19305 8.72111 6.09561 8.95298C5.98296 9.22107 5.81792 9.46396 5.61021 9.66747L5.20175 10.0759C4.99823 10.2836 4.75534 10.4487 4.48725 10.5613C4.21916 10.674 3.93129 10.732 3.64049 10.732C3.3497 10.732 3.06183 10.674 2.79374 10.5613C2.52797 10.4497 2.27712 10.2767 2.0747 10.0715C1.86951 9.8691 1.70638 9.62807 1.5947 9.3623C1.48205 9.09421 1.42402 8.80634 1.42402 8.51554C1.42402 8.22475 1.48205 7.93687 1.5947 7.66878C1.70735 7.4007 1.87239 7.1578 2.0801 6.95429L2.48856 6.54583C2.69208 6.33812 2.93497 6.17308 3.20306 6.06043C3.43493 5.96299 4.03726 5.7367 3.82711 5.11433C3.61695 4.49197 2.96753 4.68919 2.67355 4.80029C2.37956 4.91139 1.84505 5.2502 1.5148 5.58656L1.12083 5.98053C0.78447 6.31078 0.517188 6.70467 0.334567 7.13927C0.151469 7.57501 0.0571595 8.0429 0.0571594 8.51554C0.0571595 8.98818 0.15147 9.45608 0.334567 9.89181C0.516765 10.3254 0.777873 10.7131 1.11314 11.0429Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      required: false,
      default: 'white',
    },
  },
};
</script>
