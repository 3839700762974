<template>
  <v-card class="step-tab-panel" flat>
    <div class="assortment-table d-flex flex-column">
      <v-container class="actions-container pa-0 ma-0 flex-grow-0">
        <v-row>
          <v-row class="actions-col">
            <v-col data-id-e2e="btnsFurnitureEditor" class="d-flex align-center flex-grow-0">
              <span class="mr-3 font-weight-bold info-span">
                {{ $tkey('reviewArchetypesInfo') }}
                <!-- Furniture editor tooltip -->
                <docs-link link="toolguide/040-furniture.html" />
              </span>
              <div v-if="!isSimpleSwapWorkpackage" class="d-flex">
                <data-upload
                  :legends="csvUploadLegends"
                  :csv-upload-handler="onCSVUpload"
                  :disabled="isEditingDisabled"
                  @process="process"
                />
                <v-btn
                  class="button-space-5 ml-2"
                  primary
                  :disabled="isEditingDisabled"
                  @click="addArchetype"
                >
                  {{ $t('actions.addArchetype') }}
                </v-btn>
              </div>
            </v-col>
            <v-col v-if="showErrorControls" id="error-controls" class="d-flex align-center col">
              <span class="invalid-rows-error-box pr-2">{{ invalidRowsErrorMessage }}</span>
              <v-btn
                v-if="!filterInvalidRows"
                outlined
                depressed
                color="error"
                @click="toggleInvalidRows"
              >
                {{ $t('actions.showInvalidRows') }}
              </v-btn>
              <v-btn v-else outlined depressed color="primary" @click="toggleInvalidRows">
                {{ $t('actions.showAllRows') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-row>
      </v-container>

      <div class="ag-grid-box flex-grow-1">
        <ag-grid-vue
          style="width: 100%; height: 100%"
          class="ag-theme-custom"
          :column-defs="headers"
          :row-data="furniture"
          :grid-options="gridOptions"
          :stop-editing-when-cells-loses-focus="true"
          :enable-range-selection="true"
          :does-external-filter-pass="doesExternalFilterPass"
          @cell-value-changed="onSelectionChanged"
          @grid-ready="onGridReady"
        />
      </div>
      <page-actions
        show-export
        is-custom-export
        :has-data-changes="hasDataChanges"
        :has-data-errors="hasDataErrors"
        :save-disabled="isEditingDisabled"
        :is-discard-enabled="!isEditingDisabled"
        @export="exportCSV"
        @discard="discardChanges"
        @save="saveChanges"
      />
    </div>

    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="closeUnsavedDataModal"
      @confirm="closeUnsavedDataModal"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import {
  camelCase,
  some,
  omit,
  startsWith,
  isEmpty,
  get,
  cloneDeep,
  keyBy,
  values,
  merge,
  uniqueId,
  keys,
  pick,
  size,
  groupBy,
  find,
  omitBy,
} from 'lodash';
import { OUTPUT_FORMAT, CELL_TYPE } from '@enums/table/index';
import WorkpackageType from '@enums/workpackage-type';
import furnitureType from '@enums/furniture-types';
import { AgGridVue } from 'ag-grid-vue';
import inputValidationMixin from '@/js/mixins/input-validations';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import exportCSV from '@/js/mixins/export-csv';
import numberUtils from '@/js/utils/number-format-utils';
import agGridUtils from '@/js/utils/ag-grid-utils';
import agGridIcon from '@/js/components/ag-grid-cell-renderers/ag-grid-icon.vue';
import duplicateIcon from '@/img/duplicate.svg';
import cogIcon from '@/img/cog.svg';
import userIcon from '@/img/user.svg';

const fieldEnum = {
  nameHeader: 'name',
  sizeHeader: 'size',
  categoryHeader: 'furnitureCategory1Key', // TODO AOV3-1391: Make configurable
  descriptionHeader: 'description',
  typeHeader: 'type',
  copyHeader: 'copy',
  trashHeader: 'trash',
};
let validator;
const validatorsFieldNames = {
  validateValueExists: [fieldEnum.nameHeader, fieldEnum.sizeHeader, fieldEnum.categoryHeader],
  hasUniqueName: [fieldEnum.nameHeader],
  valueIsNotNumeric: [fieldEnum.sizeHeader],
};

export default {
  localizationKey: 'furnitureEditorPage',
  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    agGridIcon,
  },

  mixins: [inputValidationMixin, exportCSV, unsavedDataWarningMixin],

  data() {
    validator = {
      default: params => agGridUtils.validations.validateValueExists(params),
      [fieldEnum.nameHeader]: params =>
        agGridUtils.validations.validateValueExists(params) || this.hasUniqueName(params),
      [fieldEnum.sizeHeader]: params =>
        agGridUtils.validations.validateValueExists(params) ||
        agGridUtils.validations.valueIsNotNumeric(params),
    };

    return {
      OUTPUT_FORMAT,
      CELL_TYPE,
      csvUploadLegends: {
        buttonName: this.$t('actions.manualImport'),
        title: this.$t('actions.manualImport'),
      },
      filterInvalidRows: false,
      errorData: {},
      deletedRows: [],
      gridOptions: null,
      gridApi: null, // set onGridReady
      furniture: null, // set created
      actionColDef: {
        ...agGridUtils.colDefs.action,
        menuTabs: [],
      },
      currentStateDiff: {},
      validationErrors: {},
      uploadedFurniture: false,
      furnitureService: 'furniture-archetypes',
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('furniture', ['isLoading', 'newlyAddedIdPrefix', 'localTableRowContents']),
    ...mapGetters('furniture', [
      'getTableRows',
      'userMadeChangesToAnyCell',
      'hasInvalidValuesInAnyCell',
      'getStoreMapping',
      'getFurnitureArchetypeName',
    ]),
    ...mapGetters('context', ['showNotImplemented', 'getCsvExport', 'getDateFormats']),
    ...mapGetters('scenarios', ['selectedScenario']),

    showErrorControls() {
      return size(this.errorData) || this.filterInvalidRows;
    },

    invalidRowsErrorMessage() {
      return this.$t(`validationErrors.rowsHaveInvalidValues`);
    },

    requiredFields() {
      const cols = this.headers.filter(c => c.required);
      return cols.map(col => col.colId);
    },

    isSimpleSwapWorkpackage() {
      return this.selectedWorkpackage.type === WorkpackageType.ASSORTMENT_SIMPLE_SWAPS;
    },

    headers() {
      const currentScope = camelCase(this.selectedWorkpackage.fillInSelection);

      const columns = [
        {
          headerName: this.$tkey('attributes.archetype.category'),
          field: fieldEnum.categoryHeader,
          // Must also set the colId for column identification when exporting
          colId: fieldEnum.categoryHeader,
          headerClass: 'bold',
          cellClass: 'bold',
          editable: !this.isEditingDisabled,
          required: true,
        },
        {
          headerName: this.$tkey('attributes.archetype.name'),
          field: fieldEnum.nameHeader,
          colId: fieldEnum.nameHeader,
          headerClass: 'bold',
          cellClass: 'bold',
          editable: !this.isEditingDisabled,
          required: true,
          cellClassRules: {
            'diff-background': this.hasDiff,
            'invalid-generic': validator[fieldEnum.nameHeader],
          },
          valueGetter: ({ data }) =>
            this.getFurnitureArchetypeName({
              data,
              groupedFurnitureByName: this.groupedFurnitureByName,
            }),
        },
        {
          // If changing the structure of this headerName make sure to update the aliases for furniture-archetype csv upload,
          // as these are what are downloaded in the csv file template
          headerName: `${this.$t(`workpackagePage.scope.${currentScope}`)} (${this.$t(
            `suffixes.${currentScope}`
          )})`,
          field: fieldEnum.sizeHeader,
          colId: fieldEnum.sizeHeader,
          editable: !this.isEditingDisabled,
          type: 'numericColumnCustom',
          filter: 'agTextColumnFilter',
          required: true,
          cellClassRules: {
            'diff-background': this.hasDiff,
            'invalid-generic': validator[fieldEnum.sizeHeader],
          },
          valueParser: agGridUtils.parsers.numericParser,
        },
        {
          headerName: this.$t('general.description'),
          field: fieldEnum.descriptionHeader,
          colId: fieldEnum.descriptionHeader,
          cellClass: 'grey-border-right',
          editable: !this.isEditingDisabled,
        },
        {
          headerName: this.$t('general.status'),
          field: fieldEnum.typeHeader,
          colId: fieldEnum.typeHeader,
          editable: false,
          maxWidth: 60,
          minWidth: 60,
          resizable: false,
          pinned: 'right',
          cellClass: 'status-cell action-column-cell',
          cellRenderer: params => this.statusCellRenderer(params),
        },
        {
          ...this.actionColDef,
          cellClass: 'v-icon--disabled',
          maxWidth: 40,
          minWidth: 40,
          pinned: 'right',
          hide: !this.showNotImplemented,
          field: fieldEnum.copyHeader,
          colId: fieldEnum.copyHeader,
          cellRenderer: params => this.copyCellRenderer(params),
        },
        {
          ...this.actionColDef,
          maxWidth: 40,
          minWidth: 40,
          pinned: 'right',
          field: fieldEnum.trashHeader,
          colId: fieldEnum.trashHeader,
          cellRenderer: 'agGridIcon',
          cellRendererParams: params => ({
            classes: this.isEditingDisabled ? 'greyed-out' : 'selectable',
            iconComponent: 'trash-icon',
            isDisabled: this.isEditingDisabled,
            onClick: this.deleteRow,
            clickParams: params,
          }),
        },
      ];

      const extraColumns = cloneDeep(get(this.getClientConfig, 'furnitureEditorPage.columns', {}));

      if (size(get(extraColumns, 'allSizesGroup.childrenColumns', {}))) {
        // Hide the extra size info that matches the wp fill scope size so we don't show it twice
        extraColumns.allSizesGroup.childrenColumns = omitBy(
          extraColumns.allSizesGroup.childrenColumns,
          c => c.fill === this.selectedWorkpackage.fillInSelection
        );
      }

      return agGridUtils.builders.mergeHeaders(columns, extraColumns, {
        componentName: camelCase(this.$options.name),
        // make the first column visible at all times, everything else is collapsed by default
        firstGroupChildColumnVisible: true,
      });
    },

    hasDataChanges() {
      return !(
        isEmpty(this.currentStateDiff) &&
        isEmpty(this.deletedRows) &&
        !this.uploadedFurniture
      );
    },

    isEditingDisabled() {
      return (
        !this.hasPermission(this.userPermissions.canEditFurnitureEditor) ||
        !get(this.selectedScenario, 'status.space.canBeEdited', true)
      );
    },

    groupedFurnitureByName() {
      return groupBy(this.furniture, 'name');
    },

    hasAnyDuplicatedSetName() {
      const listOfExistingArchetypeSets = new Set([]);
      const rowData = this.getRowData();
      return some(rowData, row => {
        const archetypeName = row.name;
        if (listOfExistingArchetypeSets.has(archetypeName)) return true;

        listOfExistingArchetypeSets.add(archetypeName);
      });
    },

    hasDataErrors() {
      return (
        this.hasInvalidValuesInAnyCell ||
        this.hasAnyDuplicatedSetName ||
        !isEmpty(this.validationErrors)
      );
    },
  },

  watch: {
    localTableRowContents(newValue) {
      // update furniture list when csv upload happens
      this.furniture = newValue;
      const furnitureUploaded = some(this.furniture, f =>
        get(f, '_id', '').includes('newlyAddedIdPrefix-furniture-archetypes')
      );
      if (furnitureUploaded) {
        this.uploadedFurniture = true;
        this.furniture.forEach(f => {
          this.$set(this.currentStateDiff, f._id, f);
        });
      } else {
        this.uploadedFurniture = false;
      }
    },
  },

  beforeMount() {
    const vm = this;
    this.gridOptions = {
      getRowId(params) {
        const { data } = params;
        return data._id;
      },
      rowHeight: 30,
      headerHeight: 40,
      suppressContextMenu: true,
      suppressPropertyNamesCheck: true,
      isExternalFilterPresent() {
        return true;
      },
      defaultColDef: {
        editable: !this.isEditingDisabled,
        resizable: true,
        suppressMovable: true,
        filter: true,
        sortable: true,
        comparator: agGridUtils.sortings.naturalSort,
        menuTabs: ['filterMenuTab'],
        flex: 1,
        required: false,
        minWidth: 80,
        valueParser: agGridUtils.parsers.defaultParser,
        cellClassRules: {
          'diff-background': this.hasDiff,
          'invalid-generic': validator.default,
        },
        tooltipValueGetter(params) {
          const errors = [];
          if (
            validatorsFieldNames.validateValueExists.includes(params.colDef.field) &&
            agGridUtils.validations.validateValueExists(params)
          ) {
            errors.push(vm.$t('validationErrors.required'));
          }
          if (
            validatorsFieldNames.valueIsNotNumeric.includes(params.colDef.field) &&
            agGridUtils.validations.valueIsNotNumeric(params)
          ) {
            errors.push(vm.$t('validationErrors.number'));
          }
          if (
            validatorsFieldNames.hasUniqueName.includes(params.colDef.field) &&
            vm.hasUniqueName(params)
          ) {
            errors.push(vm.$t('validationErrors.unique', ['Name']));
          }
          return errors.join('\n');
        },
        suppressKeyboardEvent: agGridUtils.utils.suppressKeyboardEvent,
      },
      tooltipShowDelay: 0,
      columnTypes: {
        numericColumnCustom: agGridUtils.columnTypes.numericColumnCustom,
      },
      processDataFromClipboard: agGridUtils.utils.processDataFromClipboard,
      processCellFromClipboard: agGridUtils.utils.processCellFromClipboard,
      stopEditingWhenCellsLoseFocus: true,
    };
  },

  async created() {
    await this.refreshWorkpackageInformation({ workpackageId: this.selectedWorkpackage._id });
    this.init();
  },

  methods: {
    ...mapMutations('furniture', ['setInputValue']),
    ...mapActions('furniture', [
      'processCSV',
      'initialiseEditableTable',
      'onValueChanged',
      'saveScenarioFurniture',
      'setUploadedData',
    ]),
    ...mapActions('files', ['uploadCSV']),
    ...mapActions('workpackages', ['refreshWorkpackageInformation']),

    init() {
      this.resetChanges();
      this.furniture = cloneDeep(this.getTableRows);
      this.$options.savedState = keyBy(cloneDeep(this.furniture), '_id');
    },

    checkRowForErrors(row) {
      const valuesFound = pick(row, this.requiredFields);

      if (some(valuesFound, v => agGridUtils.validations.valueIsFalsy({ value: v }))) {
        this.$set(this.errorData, row._id, true);
      } else {
        this.$delete(this.errorData, row._id);
      }

      // turn off filter if you've removed all invalid rows
      if (isEmpty(this.errorData) && this.filterInvalidRows) this.toggleInvalidRows();
    },

    toggleInvalidRows() {
      this.filterInvalidRows = !this.filterInvalidRows;
      this.gridApi.onFilterChanged();
    },

    doesExternalFilterPass(node) {
      if (this.filterInvalidRows) {
        return this.errorData[node.data._id];
      }
      return true;
    },

    async saveChanges() {
      const rowData = this.getRowData();
      const idsToRemove = [];
      const rows = rowData.map(row => {
        if (startsWith(row._id, this.newlyAddedIdPrefix)) {
          idsToRemove.push(row._id);
          // remove temporary id for uploaded/added new entries as mongo will create an object id when id is null
          row._id = null;
        }
        row.size = numberUtils.formatStringToNumber(row.size);
        return omit(row, ['index']);
      });
      // TODO: Handle upstream changes after dependency tree AOV3-446
      const updates = { furnitureType: rows };

      // If the user has deleted some furnitures or uploaded completely new furnitures
      // we need to unlink these in the store-furniture mapping
      const uniqueFurnitureIds = rows.reduce((obj, row) => {
        if (row._id) {
          // If user has uplodaded furniture this won't be set
          obj[row._id] = true;
        }
        return obj;
      }, {});
      let hasStoreFurnitureChanges = false;
      const storeFurniture = this.getStoreMapping.map(sf => {
        if (sf.overrideFurnitureId && !uniqueFurnitureIds[sf.overrideFurnitureId]) {
          sf = omit(sf, ['overrideFurnitureId']);
          hasStoreFurnitureChanges = true;
        }
        if (sf.furnitureId && !uniqueFurnitureIds[sf.furnitureId]) {
          sf = omit(sf, ['furnitureId']);
          hasStoreFurnitureChanges = true;
        }
        return sf;
      });
      if (hasStoreFurnitureChanges) {
        updates.storeFurniture = storeFurniture;
      }
      // Done unlinking the store furniture mappings

      await this.saveScenarioFurniture(updates);
      // remove the rows from the grid
      this.gridApi.applyTransaction({ remove: idsToRemove.map(_id => ({ _id })) });
      this.init();
    },

    discardChanges() {
      this.gridApi.showLoadingOverlay();
      this.furniture = cloneDeep(values(this.$options.savedState));
      this.resetChanges();
      this.gridApi.hideOverlay();
    },

    resetChanges() {
      this.deletedRows = [];
      this.currentStateDiff = {};
      this.validationErrors = {};
      this.errorData = {};
      this.uploadedFurniture = false;
      this.filterInvalidRows = false;
      this.initialiseEditableTable('getFurnituresInScenario');
    },

    getAllExportableDataColumns() {
      // will fail validation when reimporting if csv headerName is empty string
      return this.columnApi
        .getAllDisplayedColumns()
        .filter(c => c.colId !== fieldEnum.typeHeader) // filter type
        .filter(c => c.colDef.headerName !== ''); // filter copy and trash;
    },

    exportCSV() {
      const exportParams = {
        fileName: this.getFileName({
          serviceName: 'furniture-archetypes',
          workpackageName: this.selectedWorkpackage.name,
          scenarioName: this.selectedScenario.name,
          fileNameDateFormat: this.getDateFormats.csvFileName,
        }),
        suppressQuotes: this.getCsvExport.suppressQuotes,
        columnSeparator: this.getCsvExport.columnSeparator,
        columnKeys: this.getAllExportableDataColumns(),
        processCellCallback: agGridUtils.utils.processCellForExport,
        skipColumnGroupHeaders: true,
      };

      this.gridApi.exportDataAsCsv(exportParams);
    },

    onCSVUpload(formData) {
      formData.append('scenarioId', this.selectedScenario._id);
      return this.uploadCSV({ formData, service: this.furnitureService });
    },

    async process({ fileId, mappings, delimiter }) {
      const rows = await this.processCSV({
        fileId,
        mappings,
        delimiter,
        furnitureService: this.furnitureService,
      });
      this.setUploadedData(rows);
    },

    // when name is unique returns false - this is the standard for ag-grid, hence the false being returned on a successful validation.
    hasUniqueName(params) {
      const { valueGetter } = find(this.headers, { field: params.colDef.field });
      const name = valueGetter(params);
      const rowData = this.getRowData();
      const isUnique = rowData.filter(data => valueGetter({ data }) === name).length <= 1;
      return !isUnique;
    },

    // This method is temporary and should be removed as part of AOV3-605
    getServerErrors(item, header) {
      if (!header.serverRules) return '';

      const rulesResults = header.serverRules.map(rule => rule(item));

      const failedRulesMessages = rulesResults.filter(
        ruleMessage => typeof ruleMessage === 'string'
      );

      return failedRulesMessages[0] || '';
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    deleteRow(params) {
      this.handleDelete(params.node.id);
      // need to redraw rows so they have the correct disabled style
      const rowData = this.getRowData();
      const rowNodes = rowData.map(f => this.gridApi.getRowNode(f._id));
      this.gridApi.redrawRows({ rowNodes });
    },

    copyCellRenderer(params) {
      const img = document.createElement('img');
      img.src = duplicateIcon;
      img.className = this.isEditingDisabled ? 'v-icon--disabled' : '';
      img.style = this.isEditingDisabled ? '' : 'cursor: pointer;';
      img.addEventListener('click', () => this.handleCopy([params.node.id]));
      return img;
    },

    async handleDelete(_id) {
      this.deletedRows.push(_id);
      // remove validation errors for newly added rows that have been deleted
      keys(this.validationErrors).forEach(key => {
        if (startsWith(key, _id)) {
          this.$delete(this.validationErrors, key);
        }
      });

      this.$delete(this.currentStateDiff, _id); // remove any changes to the mapping marked for deletion
      this.gridApi.applyTransaction({ remove: [{ _id }] }); // remove the row from the grid
    },

    statusCellRenderer(params) {
      const img = document.createElement('img');
      img.src = params.value === furnitureType.manual ? userIcon : cogIcon;
      return img;
    },

    hasDiff(params) {
      const { _id } = params.data;
      const { field } = params.colDef;
      const path = `${_id}.${field}`;
      const currentValue = params.data[params.colDef.field];
      const originalValue = get(this.$options.savedState, path);

      return agGridUtils.comparators.didValueChange(currentValue, originalValue);
    },

    onSelectionChanged(params) {
      this.updateCurrentState(params);
      this.updateValidationErrors(params);
    },

    updateCurrentState(params) {
      const { _id } = params.data;
      const { field } = params.colDef;
      // params.value is displayed value, params.data[params.colDef.field] is value as stored in db
      // e.g. params.value = "5,1", params.data[params.colDef.field] = 5.1
      const currentValue = params.data[params.colDef.field];

      const path = `${_id}.${field}`;
      const originalValue = get(this.$options.savedState, path);

      this.checkRowForErrors(params.data);
      if (agGridUtils.comparators.didValueChange(currentValue, originalValue)) {
        if (!this.currentStateDiff[_id]) this.$set(this.currentStateDiff, _id, {});
        this.$set(this.currentStateDiff[_id], field, currentValue);
        return;
      }

      if (currentValue === originalValue && get(this.currentStateDiff[_id], field)) {
        // Remove the field from the object if it's back to its old value.
        // Note: This is only triggered on a change so entering 1 in a cell that already contains 1 triggers nothing.
        this.$delete(this.currentStateDiff[_id], field);
        if (isEmpty(this.currentStateDiff[_id])) {
          // If there's nothing left in the object, remove it.
          this.$delete(this.currentStateDiff, _id);
        }
      }
    },

    updateValidationErrors(params) {
      const { id } = params.node;
      const validationFunc = get(validator, params.colDef.field, validator.default);
      const hasError = validationFunc(params);
      const propName = `${id}-${params.colDef.field}`;
      if (hasError) {
        this.$set(this.validationErrors, propName, true);
      } else {
        this.$delete(this.validationErrors, propName);
      }
    },

    addArchetype() {
      const newRow = {
        furnitureCategory1Key: '',
        name: '',
        size: '',
        description: '',
        type: furnitureType.manual,
        _id: uniqueId(`${this.newlyAddedIdPrefix}-${this.furnitureService}`),
        productCountSize: null,
        dimensionSize: null,
        linearSize: null,
        horizontalAreaSize: null,
        cubicVolumeSize: null,
        frontalAreaSize: null,
      };
      this.currentStateDiff = merge({}, this.currentStateDiff, { [newRow._id]: newRow });
      // add new row to the top, usually would use applyTransaction but addIndex is deprecated in this version of ag-grid-enterprise
      const rowData = this.getRowData();
      rowData.unshift(newRow);
      this.gridApi.setRowData(rowData);

      // validating new blank row, updates the validation errors
      const params = {
        colDef: {
          editable: true,
          field: '',
        },
        data: newRow,
        newValue: '',
        node: { id: newRow._id },
        oldValue: '',
        value: '',
      };
      this.headers.forEach(header => {
        if (!header.editable || !header.required) return;

        params.colDef.field = header.field;

        this.updateValidationErrors(params);
      });
    },

    getRowData() {
      const rowData = [];
      // Check if grid has loaded before loading rowData
      if (this.gridApi) {
        this.gridApi.forEachNode(node => rowData.push(node.data));
      }
      return rowData;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.assortment-table {
  height: 100%;
  td:first-child {
    padding: 0 16px !important;
  }

  .margin-y {
    margin: 6px 0;
  }

  .bold {
    ::v-deep {
      input {
        font-weight: bold;
      }
    }
  }

  .main-panel {
    height: 500px;
    overflow-y: scroll;

    .row {
      box-sizing: content-box;
    }
  }

  .empty-data {
    background: $assortment-table-empty-table-row;
    text-align: center;

    &span {
      color: $assortment-table-empty-table-span;
    }
  }
}

.info-icon {
  font-size: 20px;
  color: $assortment-primary-colour !important;
}

.filter-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;

  .filter-label {
    color: #37424a;
    font-family: 'Source Sans Pro';
    font-size: 1.4rem;
    margin-right: 7px;
    letter-spacing: 0;
    line-height: 1.8rem;
  }
}

.info-span {
  white-space: nowrap;
}

::v-deep {
  .text-right input {
    text-align: right;
  }

  #furniture-table {
    border-collapse: collapse;

    & input {
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;
    }

    & tbody > tr {
      border-bottom: $assortment-border-colour 1px solid;
    }

    & td.updated-cell {
      background-color: $assortment-table-changed-cell-bg-colour !important;
    }
    & td.invalid-cell {
      background-color: $assortment-table-error-cell-background !important;
    }
  }
}

.basic-header-th {
  color: $assortment-table-header-span-colour;
}
</style>
