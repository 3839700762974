<template>
  <section class="workpackage d-flex flex-column" :class="workpackageSetupJobStatus">
    <workpackage-panel
      ref="panel"
      :workpackage="item"
      :disable-expand="isWorkpackageSetupRunning"
      :is-link="isLink"
      @save:workpackage="validateAndSaveWorkpackage"
    />
    <workpackage-scope
      v-if="isScoped"
      ref="scope"
      :is-workpackage-setup-running="isWorkpackageSetupRunning"
      @close="closeScope"
      @save:workpackage="validateAndSaveWorkpackage"
      @run-workpackage-setup="runWorkpackageSetup"
    />
    <workpackage-scenarios v-if="isWPScenariosExpanded" :workpackage="item" class="pl-5" />

    <dependency-tree-feedback-modal
      :value="dependencyTreeModalOpen"
      :results="dependencyTreeFeedback"
      page="workpackageSetup"
      @close="closeDependencyTreeModal"
      @commit="commitHandler()"
    />
  </section>
</template>

<script>
import { includes, get } from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import to from 'await-to-js';
import { jobFinishedStatuses } from '@enums/jobapi';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },

    isLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      dependencyTreeModalOpen: false,
      dependencyTreeFeedback: {},
      calledFromSetup: false,
    };
  },

  computed: {
    ...mapState('bundles', ['selectedBundle']),
    ...mapState('workpackages', [
      'isWPSetupExpanded',
      'isScenarioPanelExpanded',
      'selectedWorkpackage',
    ]),
    ...mapGetters('workpackages', ['activeWorkpackages']),

    workpackageSetupJobStatus() {
      if (this.item.jobs && this.item.jobs.workpackageSetup) {
        return this.item.jobs.workpackageSetup.status.toLowerCase();
      }
      return '';
    },

    isWorkpackageSetupRunning() {
      return this.workpackageSetupJobStatus
        ? !includes(jobFinishedStatuses, this.workpackageSetupJobStatus)
        : false;
    },

    isWPScenariosExpanded() {
      return this.isScenarioPanelExpanded && this.selectedWorkpackage._id === this.item._id;
    },

    isScoped() {
      return this.isWPSetupExpanded && this.selectedWorkpackage._id === this.item._id;
    },
  },

  methods: {
    ...mapActions('workpackages', [
      'updateWorkpackage',
      'fetchSingleWorkpackage',
      'fetchWorkpackages',
      'deleteWorkpackageById',
      'toggleWorkpackageSetupExpanded',
      'updateWorkpackageJobDetails',
      'runWorkpackageSetupJob',
    ]),
    ...mapActions('dependencyTree', ['triggerDependencyTree']),

    async commitHandler() {
      const workpackage = this.getData();
      if (!this.validate(workpackage)) {
        return;
      }

      if (this.calledFromSetup) await this.runWorkpackageSetup(true);
      else this.saveWorkpackage(workpackage, true);

      this.closeDependencyTreeModal();
    },

    async runWorkpackageSetup(commit = false) {
      this.calledFromSetup = true;
      const workpackage = this.getData();
      if (!this.validate(workpackage)) {
        return;
      }

      const needsFeedback = await this.saveWorkpackage(workpackage, commit);
      if (needsFeedback) return;
      await this.runWorkpackageSetupJob();
      await this.fetchWorkpackages({ bundleId: get(this.selectedBundle, '_id') });
    },

    async validateAndSaveWorkpackage(commit) {
      const data = this.getData();

      if (!this.validate(data)) {
        return;
      }
      await this.saveWorkpackage(data, commit);
    },

    async saveWorkpackage(data, commit = false) {
      const results = await this.triggerDependencyTree({
        params: {
          change: 'workpackageSetupModified',
          updates: {},
          commit,
          workpackageId: this.item._id,
        },
      });

      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return true;
      }

      const [err] = await to(
        this.updateWorkpackage({ workpackageId: this.item._id, updates: data })
      );

      await this.fetchSingleWorkpackage({
        workpackageId: this.item._id,
        includeAssortmentGroups: true,
      });
      if (err) {
        const message = (err && err.message) || this.$t('errors.generalErrorMessage');
        this.showError(message);
      }
    },

    showError(content) {
      this.$store.dispatch('snackbar/showSnackbar', {
        content,
      });
    },

    getData() {
      return {
        _id: this.item._id,
        ...this.getPanelPayload(),
        ...this.getScopePayload(),
      };
    },

    getPanelPayload() {
      const model = this.$refs.panel.getData();
      const data = {};
      ['name', 'description', 'type', 'targetLaunchDate', 'businessUnit'].forEach(key => {
        data[key] = model[key];
      });
      return data;
    },

    getScopePayload() {
      return this.$refs.scope ? this.$refs.scope.getData() : {};
    },

    validateName(data) {
      if (data._id) {
        return true;
      }
      const exist = this.activeWorkpackages.some(workpackage => data.name === workpackage.name);
      if (!exist) {
        return true;
      }

      this.showError(this.$t('workpackagePage.validation.name.unique'));
      return false;
    },

    validateScope() {
      if (this.$refs.scope) {
        return this.$refs.scope.validate();
      }
      return true;
    },

    validate(data) {
      return this.$refs.panel.validate() && this.validateScope() && this.validateName(data);
    },

    closeScope() {
      this.$refs.scope.resetScopeModel();
      this.toggleWorkpackageSetupExpanded({ workpackage: this.item });
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
      this.calledFromSetup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.workpackage {
  border-top: 1px solid $workpackage-top-border;

  &.failed {
    border-top: $assortment-workpackage-drag-cell-colour-failed 1px solid !important;
  }

  &.finished {
    border-top: $assortment-workpackage-drag-cell-colour-finished 1px solid !important;
  }

  &__setup-date-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__modified-date {
    padding-right: 3px;
    padding-bottom: 2px;
    font-weight: bold;
    width: 68px;
  }

  &__setup-icons {
    display: flex;
    align-items: center;
  }

  &__success {
    margin-right: 7px;
  }

  &__cell-users {
    text-align: right;
  }

  &__cell-users-permissions-icons {
    display: flex;
    justify-content: flex-start;

    ::v-deep {
      .v-avatar,
      svg {
        margin-right: 4px;
      }
    }
  }

  &__cell-users-permissions-title {
    color: $assortment-primary-colour;
    text-align: left;
  }

  &__cell-icons {
    ::v-deep {
      .v-icon:not(:last-child) {
        margin-right: 7px;
        color: $assortment-primary-colour;
      }
    }
  }

  .unbolded-header {
    font-weight: normal;
  }

  ::v-deep {
    .v-select__selection.v-select__selection--comma {
      font-weight: bold;
    }
  }
}
</style>
