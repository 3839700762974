import { render, staticRenderFns } from "./spacebreak-entry.vue?vue&type=template&id=6a467016&scoped=true"
import script from "./spacebreak-entry.vue?vue&type=script&lang=js"
export * from "./spacebreak-entry.vue?vue&type=script&lang=js"
import style0 from "./spacebreak-entry.vue?vue&type=style&index=0&id=6a467016&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a467016",
  null
  
)

export default component.exports