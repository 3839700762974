<template>
  <v-btn icon text :disabled="disabled" @click="$emit('execute')">
    <v-icon size="24" class="new-workpackage">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
};
</script>
