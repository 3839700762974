<template>
  <v-autocomplete
    v-model="selectedItem"
    return-object
    persistent-hint
    hide-no-data
    no-filter
    class="autocomplete-search-input rtls-search rtls-search--white"
    :loading="loading"
    :items="filteredVisible"
    :search-input.sync="search"
    :item-text="itemText"
    :placeholder="placeholder"
    :hint="hint"
    :hide-details="hint.length < 1"
    :menu-props="{ 'z-index': 300 }"
    @change="onItemSelected"
    @keyup.enter="filterItems"
  >
    <v-list-item v-if="maximumProductsShown" slot="prepend-item">
      <v-list-item-title class="maximum-items-note mb-3 mt-3">{{
        $tkey('maximumProductsShown', [productResultsLimit])
      }}</v-list-item-title>
    </v-list-item>
    <v-divider v-if="maximumProductsShown" />
    <template v-slot:item="{ item }">
      <div class="search-item d-flex">
        <template v-for="(text, position) in getMaskedText(item)">
          <template v-if="position === 'start'">{{ text }}</template>
          <span v-if="position === 'middle'" class="search-item--highlighted">{{ text }}</span>
          <template v-if="position === 'end'">{{ text }}</template>
        </template>
      </div>
    </template>
    <v-icon slot="append" small color="primary" @click="searchOnDemand && filterItems()"
      >$search</v-icon
    >
  </v-autocomplete>
</template>

<script>
import { debounce, includes, size, toLower, isEmpty } from 'lodash';
import searchUtils from '@/js/utils/search-utils';

export default {
  localizationKey: 'assortmentSearch',
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    itemLabel: {
      type: [Function, String],
      required: true,
    },
    // the text to show on the search on click, required for Vuetify
    itemText: {
      type: String,
      required: true,
    },
    asyncSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    onSearch: {
      type: Function,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    onSelection: {
      type: Function,
      required: true,
    },
    clearOnSelection: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchOnDemand: {
      type: Boolean,
      required: false,
      default: false,
    },
    productResultsLimit: {
      type: Number,
      required: false,
      default: -1,
    },
  },

  data() {
    return {
      selectedItem: null,
      search: null,
      hint: '',
      itemsSearchDebounced: debounce(() => this.filterItems(), 200, { maxWait: 500 }),
      // which items the user will see after querying
      visibleItems: [],
      loading: false,
    };
  },

  computed: {
    maximumProductsShown() {
      return size(this.visibleItems) === this.productResultsLimit;
    },
    filteredVisible() {
      return this.visibleItems.filter(({ palletContent }) => isEmpty(palletContent));
    },
  },

  watch: {
    search(newValue) {
      const currentQuerySize = size(newValue);

      if (currentQuerySize > 0 && currentQuerySize < 3) {
        this.hint = this.$tkey('hintMessage', [3 - currentQuerySize]);
        this.visibleItems = [];
        return;
      }
      this.hint = '';
      if (!this.searchOnDemand) {
        this.itemsSearchDebounced(newValue);
      }
    },
  },

  methods: {
    async filterItems() {
      // user must type at least 3 chars
      if (size(this.search) < 3) return false;

      this.loading = true;

      if (this.asyncSearch) {
        this.visibleItems = await this.onAsyncSearchChange(this.search);
      } else {
        this.visibleItems = this.items.filter(p =>
          this.onSyncSearchChange(p, toLower(this.search))
        );
      }

      this.loading = false;
    },

    onSyncSearchChange(item, query) {
      if (this.onSearch) return this.onSearch(item, query);
      return includes(toLower(item[this.itemLabel]), query);
    },

    onAsyncSearchChange(query) {
      if (this.onSearch) return this.onSearch(query);
    },

    async onItemSelected() {
      this.onSelection(this.selectedItem);
      // Vuetify modifies the search after this tick, so on next one we clear the local info
      if (this.clearOnSelection) {
        await this.$nextTick();
        this.search = null;
        this.selectedItem = null;
      }
    },

    getMaskedText(item) {
      return searchUtils.getMaskedText(item, this.itemLabel, this.search);
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

/*Unfortunately, vuetify does not expose a hint class property, so we deeply modify it*/
/*Hint is outside the stacking context, so absolute positioning and z-index didn't work to position the hint above everything else on the page*/
::v-deep {
  .v-text-field__details {
    position: relative;
    min-height: 0px;
    height: 0px;
    overflow: visible;

    & > .v-messages {
      position: absolute;
      top: -1px;
      background-color: $assortment-input-background;
      width: 100%;
      border-top: 1px solid $assortment-border-colour;
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.autocomplete-search-input {
  width: 100%;
  max-width: 350px;
}
.search-item {
  font-size: 1.2rem;

  &--highlighted {
    text-decoration: underline;
    background: $assortmeent-search-highlighted-match;
  }
}
.maximum-items-note {
  color: $assortment-greyed-out-colour;
}

::v-deep {
  input {
    padding-left: 5px;
  }
}
</style>
