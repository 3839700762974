<template>
  <v-form
    ref="form"
    v-model="valid"
    class="main-expandable-panel panel-row d-flex"
    :class="templateStatus"
    autocomplete="off"
    @submit.prevent=""
  >
    <div class="panel-cell drag-cell flex-grow-0 flex-shrink-0" :class="workpackageSetupJobStatus">
      <img class="template-icon" width="15px" :src="templateIcon" :alt="$t('entities.template')" />
    </div>

    <div class="panel-cell name-description pr-0 mr-auto">
      <div class="top-container d-flex">
        <rtls-text-field
          :key="nameKey"
          v-model.trim="model.name"
          :disabled="!hasPermission(userPermissions.canEditWorkpackageNameDescription)"
          data-id-e2e="workpackageTemplateName"
          :data-value-e2e="model.name"
          :rules="[
            required,
            v => isNameUnique(v, workpackage.name, templateNames, $t('entities.template')),
          ]"
          @blur="saveModel"
          @keyup.enter="saveModel"
          @keyup.esc="resetModel('name')"
        />
      </div>
      <rtls-text-field
        :key="descriptionKey"
        v-model.trim="model.description"
        data-id-e2e="workpackageTemplateDescription"
        :disabled="!hasPermission(userPermissions.canEditWorkpackageNameDescription)"
        @blur="saveModel"
        @keydown.enter="saveModel"
        @keydown.esc="resetModel('description')"
      />
    </div>

    <section
      v-for="section in sections"
      :key="section.tkey"
      class="panel-cell align-end flex-column clickable"
    >
      <!-- If there is no route to the section (e.g. inputs section is n/a), do not wrap section in a link component -->
      <component
        :is="getSectionRoute(section) ? 'actionable-link' : 'div'"
        :to="getSectionRoute(section)"
        :on-click="() => onSectionClick(section)"
      >
        <v-menu
          v-if="section.name === 'dates-assortment-groups' && assortmentGroups.length"
          data-id-e2e="workpackageTemplateAgTooltip"
          open-on-hover
          content-class="ag-list"
          offset-y
          close-delay="100"
          :max-height="210"
          :z-index="1000"
        >
          <template v-slot:activator="{ on }">
            <h4 class="header" v-on="on">{{ $t(section.tkey) }}</h4>
          </template>
          <h4 v-for="group in assortmentGroups" :key="group.name" class="full-size-title">
            {{ group.name }}
          </h4>
        </v-menu>
        <h4 v-else class="header">{{ $t(section.tkey) }}</h4>

        <div class="d-flex align-center flex-end row justify-end">
          <p class="header ma-0 px-1">{{ displayCount(section.count) }}</p>
          <div v-if="showNotImplemented" class="icons">
            <v-icon size="20">{{ `$${section.status}` }}</v-icon>
          </div>
        </div>
      </component>
    </section>

    <section class="panel-cell align-start flex-column">
      <h4 class="header">{{ $t('workpackagePage.lastModified') }}</h4>

      <div class="d-flex align-center flex-end row justify-space-between">
        <p class="header date ma-0 px-1">
          {{ model.lastModifiedDate | formatDate(getDateFormats.longWithTimeAndMeridiem) }}
        </p>
      </div>
    </section>

    <section class="panel-cell align-start flex-column">
      <div class="d-flex align-center flex-end row justify-space-between">
        <div class="icons">
          <notes
            :notes="getAllNotes"
            :loading="isNotesLoading"
            :title="$t('notes.types.workpackage')"
            :max-chars="getClientConfig.notesSettings.maxLength"
            :notifications="!!workpackage.totalNotes"
            @post="postNewNote"
            @menuOpen="fetchAllNotes"
            @delete="deleteCurrentNote"
            @update="updateCurrentNote"
          />
        </div>
        <workpackage-option-unarchive
          v-if="workpackage.archived"
          :workpackage="workpackage"
          :entity="entities.template"
        />
        <workpackage-option-archive v-else :workpackage="workpackage" :entity="entities.template" />
        <workpackage-option-delete
          v-if="isTemplateDeleteEnabled"
          :workpackage="workpackage"
          :entity="entities.template"
        />
      </div>
    </section>

    <section class="panel-cell align-end flex-column header ">
      <h4 class="header ">{{ $tkey('templatesPage.createdBy') }}</h4>

      <div class="d-flex align-center flex-end row justify-end">
        <rtls-avatar :username="getName(workpackage)" />
      </div>
    </section>

    <section
      v-if="showNotImplemented"
      class="panel-cell px-1 align-center justify-center flex-grow-0"
    >
      <panel-menu-button disabled />
    </section>

    <div
      v-if="showNotImplemented"
      class="panel-cell px-1 expand align-center justify-center flex-grow-0 flex-shrink-0"
    >
      <main-expand-button :disabled="true" :is-expanded="false" />
    </div>
  </v-form>
</template>

<script>
import { get, map, isFunction, isEqual } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import contextEnum from '@enums/note-contexts';
import sectionStatuses from '@enums/section-status';
import entities from '@enums/entities';
import templateSections from '@/js/utils/tabs/template-sections-enum';
import inputValidationMixin from '@/js/mixins/input-validations';
import { notesMixin } from '@/js/mixins/notes';
import templateIcon from '@/img/template.svg';

export default {
  mixins: [inputValidationMixin, notesMixin(contextEnum.workpackage)],

  props: {
    workpackage: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      model: {
        name: this.workpackage.name,
        description: this.workpackage.description,
        type: this.workpackage.type,
        targetLaunchDate: this.workpackage.targetLaunchDate,
        lastModifiedDate: this.workpackage.lastModifiedDate,
      },
      valid: true,
      nameKey: false,
      descriptionKey: false,
      templateScenario: {},
      templateIcon,
      entities,
    };
  },

  computed: {
    ...mapGetters('workpackages', ['workpackageTemplates', 'workpackageStatus']),
    ...mapGetters('context', ['showNotImplemented', 'getDateFormats', 'getClientConfig']),
    ...mapGetters('scenarios', ['scenariosByWorkpackageId']),

    isTemplateDeleteEnabled() {
      return get(this.getClientConfig, 'betaFeatures.templateDeleteEnabled', false);
    },

    templateNames() {
      return map(this.workpackageTemplates, 'name');
    },

    workpackageSetupJobStatus() {
      if (this.workpackage.jobs && this.workpackage.jobs.workpackageSetup) {
        return this.workpackage.jobs.workpackageSetup.status.toLowerCase();
      }
      return '';
    },

    templateStatus() {
      const inputsStatus = this.workpackageStatus(this.workpackage).inputs.status;
      // If inputs status is complete, mark template white
      // Do not return actual status strings to not mess with default vuetify 'success' class.
      return isEqual(inputsStatus, sectionStatuses.complete) ? 'complete' : 'incomplete';
    },

    sections() {
      return map(templateSections.ALL, section => {
        const res = { ...section };
        if (isFunction(section.count)) {
          res.count = section.count(this.workpackage);
        }

        const statusObj = this.workpackageStatus(this.workpackage);
        res.status = get(
          statusObj,
          `${section.statusSection}.${section.statusSubSection}.status`,
          sectionStatuses.notStarted
        );

        return res;
      });
    },

    assortmentGroups() {
      return map(this.workpackage.assortmentGroups, ag => {
        return { name: ag.name };
      });
    },

    getNotesParam() {
      return {
        id: this.workpackage._id,
        context: contextEnum.workpackage,
      };
    },
  },

  async created() {
    // It is expected that there'll only be one scenario for the workpackage,
    // but we need to handle the case where there's more, so take the first one.
    this.templateScenario = get(this.scenariosByWorkpackageId, this.workpackage._id, [{}])[0];
  },

  methods: {
    ...mapActions('workpackages', ['updateWorkpackage']),
    ...mapActions('scenarios', ['fetchScenarios']),

    // TODO this set of methods is copied from <workpackage-panel /> component.
    // Should extract these into utility file to avoid code duplication.

    displayCount(count) {
      if (count) return count;
      return count === 0 ? this.$tkey('templatesPage.noCount') : '';
    },

    getName(workpackage) {
      return get(workpackage, 'createdBy.fullName');
    },

    resetModel(field) {
      this[`${field}Key`] = !this[`${field}Key`]; // toggle the key value so the component is reset
      this.updateModel(field, this.getWorkpackageById(this.workpackage._id)[field]);
    },

    updateModel(field, value) {
      this.model[field] = value;
    },

    async saveModel() {
      if (!this.valid) return false;

      await this.updateWorkpackage({
        workpackageId: this.workpackage._id,
        updates: { ...this.model },
      });

      this.nameKey = !this.nameKey;
    },

    validate() {
      return this.$refs.form.validate();
    },

    getSectionRoute(section) {
      if (
        isEqual(section.statusSection, templateSections.templateSectionNames.inputs) &&
        !this.templateScenario._id
      ) {
        return;
      }

      return {
        name: section.name,
        params: {
          templateId: this.workpackage._id,
          templateScenarioId: this.templateScenario._id,
        },
      };
    },

    onSectionClick(section) {
      this.$router.push(this.getSectionRoute(section));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

section {
  width: 100%;
  white-space: nowrap;
  flex-basis: min-content;
}

// Actionable-link component turns section names into link elements
a {
  text-decoration: none;
  color: $assortment-navigation-tab-text-colour !important;
}

.incomplete {
  background-color: $assortment-template-incomplete !important;

  ::v-deep.v-input__slot {
    background: $assortment-template-incomplete !important;
  }
}

.icons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-left: 0.4rem;
  margin-bottom: 0.2rem;
  max-width: 8rem;
}

.row {
  height: 100%;
  width: 100%;
}

.panel-cell {
  border-right: 1px solid $assortment-template-panel-border-colour;
  flex-basis: max-content;
  margin-top: 7px;
}

.panel-row {
  background: $assortment-workpackage-bg-colour;
  justify-content: flex-end;
}

.disabled {
  color: $assortment-disabled-text-button-colour;
  pointer-events: none;

  ::v-deep {
    .tooltip {
      color: $assortment-disabled-text-button-colour;
    }
  }
}

.drag-cell {
  background: inherit;
  border: 0.2rem solid $assortment-template-panel-border-colour;
  border-top: none;
  flex-basis: auto;

  &.finished {
    background-color: $assortment-workpackage-drag-cell-colour-finished;
    border-color: $assortment-workpackage-drag-cell-colour-finished;
  }

  &.in-progress {
    background-color: $assortment-workpackage-drag-cell-colour-in-progress;
    border-color: $assortment-workpackage-drag-cell-colour-in-progress;
  }

  &.failed {
    background-color: $assortment-workpackage-drag-cell-colour-failed;
    border-color: $assortment-workpackage-drag-cell-colour-failed;
  }
}

.name-description {
  ::v-deep {
    & .v-input {
      &__control {
        border: 1px dashed $assortment-workpackages-edit-border-colour;
      }
    }
  }
}

.template-icon {
  margin-left: 2px;
}

.full-size-title {
  font-weight: bold;
}

.ag-list {
  background-color: white;
  padding: 8px;
}
</style>
