<template>
  <div>
    <v-tooltip v-if="!isArchiving" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="disabled"
          class="action-button"
          icon
          text
          v-bind="attrs"
          v-on="on"
          @click="onArchiveWorkpackageClick"
        >
          <command-item icon="$archive-box" />
        </v-btn>
      </template>
      <span>{{ $t('actions.archive') }}</span>
    </v-tooltip>
    <v-progress-circular v-else indeterminate size="25" width="3" />
  </div>
</template>

<script>
import moment from 'moment';
import { capitalize } from 'lodash';
import { mapActions, mapMutations, mapState } from 'vuex';
import entities from '@enums/entities';

export default {
  props: {
    workpackage: {
      required: true,
      type: Object,
    },
    entity: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isArchiving: false,
      message: '',
      offset: null,
      pageSize: null,
    };
  },
  computed: {
    ...mapState('workpackages', ['archivedWorkpackagesCurrentPage', 'workpackages']),
    ...mapState('context', ['clientConfig']),
    disabled() {
      return !this.hasPermission(this.userPermissions.canArchiveWorkpackage);
    },
  },
  created() {
    this.message = this.$t(`notifications.archive${capitalize(this.entity)}`, {
      name: this.workpackage.name,
    });
    this.pageSize = this.clientConfig.archivedWorpackages.pageSize;
    this.offset = (this.archivedWorkpackagesCurrentPage - 1) * this.pageSize;
  },
  methods: {
    ...mapMutations('workpackages', [
      'setWorkpackageById',
      'deleteWorkpackageById',
      'deleteWorkpackageTemplateById',
    ]),
    ...mapActions('workpackages', ['updateWorkpackage', 'fetchArchivedWorkpackages']),
    ...mapActions('snackbar', ['showSuccess']),
    async onArchiveWorkpackageClick() {
      this.isArchiving = true;
      const updates = {
        archived: true,
        archiveDate: moment.utc().toDate(),
      };
      await this.updateWorkpackage({
        workpackageId: this.workpackage._id,
        updates,
        omitStoreUpdate: true,
      });
      await Promise.all([
        this.fetchArchivedWorkpackages({
          limit: this.pageSize,
          offset: this.offset,
          shouldGetTemplates: this.entity === entities.template,
        }),
      ]);
      if (this.entity === entities.template) {
        this.deleteWorkpackageTemplateById(this.workpackage._id);
      } else {
        this.deleteWorkpackageById(this.workpackage._id);
      }

      this.showSuccess(this.message);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.action-button {
  .v-icon {
    color: $assortment-primary-colour;
  }
}
</style>
