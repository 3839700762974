'use strict';

const optionalAttributeEditorAttributes = {
  minimumDistribution: {
    field: 'minimumDistribution',
    key: 'useMinimumDistribution',
  },
  minimumFacings: {
    field: 'minimumFacings',
    key: 'useMinimumFacings',
  },
  seasonalWeekNumbers: {
    field: 'seasonalWeekNumbers',
    key: 'additionalFields.seasonalWeekNumbers',
  },
  userEnteredSisterProduct: {
    field: 'userEnteredSisterProduct',
    key: 'additionalFields.userEnteredSisterProduct',
  },
  seasonalProduct: {
    field: 'seasonalProduct',
    key: 'additionalFields.seasonalProduct',
  },
  pgMustFlag: {
    field: 'pgMustFlag',
    key: 'additionalFields.pgMustFlag',
  },
  additionalMinimumDistribution: {
    field: 'minimumDistribution',
    key: 'additionalFields.minimumDistribution',
  },
  maximumDistribution: {
    field: 'maximumDistribution',
    key: 'additionalFields.maximumDistribution',
  },
  localProduct: {
    field: 'localProduct',
    key: 'additionalFields.localProduct',
  },
};

module.exports = {
  optionalAttributeEditorAttributes,
};
