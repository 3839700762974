'use strict';

const DataTypes = require('../../../enums/data-types');

const fixedFields = [
  {
    mongoField: 'productKeyDisplay',
    type: DataTypes.alphanumeric,
    // The comparison function uses Lodash _.lowerCase which converts string, as space separated words, to lower case.
    // 'Product Key' === 'product key'  |  'productKey' === 'product key'  |  '--Product-Key--' === 'product key'
    aliases: 'csvAliases.productsToSwap.productKeyDisplay',
    translationKey: 'mongoFields.productKeyDisplay',
    required: true,
  },
  {
    mongoField: 'itemDescription',
    type: DataTypes.str,
    aliases: 'csvAliases.productsToSwap.itemDescription',
    translationKey: 'mongoFields.itemDescription',
    required: false,
  },
  {
    mongoFieldPart: 'eligibleToAdd',
    type: DataTypes.boolean,
    aliases: 'csvAliases.productsToSwap.eligibleToAdd',
    translationKeyPart: 'mongoFields.add',
    required: false,
    hasCompoundName: true,
  },
  {
    mongoFieldPart: 'eligibleToRemove',
    type: DataTypes.boolean,
    aliases: 'csvAliases.productsToSwap.eligibleToRemove',
    translationKeyPart: 'mongoFields.remove',
    required: false,
    hasCompoundName: true,
  },
  {
    mongoFieldPart: 'swapGroup',
    type: DataTypes.str,
    aliases: 'csvAliases.productsToSwap.swapGroup',
    translationKeyPart: 'mongoFields.group',
    required: false,
    hasCompoundName: true,
  },
];

module.exports = {
  fixedFields,
};
