import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "main-product-tile",
      staticClass: "d-flex flex-column default-product-box",
      class: {
        "medium-margin": _vm.selectedTileSize === "medium",
        "selected-product": _vm.isProductSelected,
      },
      style: _vm.getContainerSizes,
      attrs: { id: `product-tile-popup-${_vm.product.productKey}` },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.openPopup.apply(null, arguments)
        },
      },
    },
    [
      _vm.show
        ? _c("product-draggable-tooltip", {
            attrs: {
              "selected-kpis": _vm.clientConfig.selectedKpis,
              "product-info": _vm.product,
              "product-index": _vm.index,
              "pod-and-store-count-info": _vm.podAndStoreCounts(
                _vm.product,
                _vm.selectedCanvas.clusterId,
                _vm.spacebreaksIndexedByIdByCluster
              ),
              "product-positioning-info": _vm.extractProductPositioningInfo(
                _vm.product,
                _vm.selectedCanvas.clusterId,
                _vm.spacebreaksIndexedByIdByCluster,
                _vm.selectedPod
              ),
              settings: _vm.tooltipSettings,
              "image-url": _vm.imageUrl,
              "image-title": _vm.displayText,
              "image-alt": _vm.displayText,
              "conditional-product-tile-style": _vm.dashboardProductTileSize,
              spacebreaks: _vm.spacebreaksNames,
              "spacebreak-disabled":
                _vm.isSpacebreakLocked(_vm.spacebreakId) ||
                _vm.isEditingDisabled,
              "spacebreak-types": _vm.spacebreakTypes,
              "selected-spacebreak": _vm.selectedSpacebreak,
              "change-spacebreak": _vm.changeSpacebreak,
              "lock-types": _vm.lockTypes,
              "popup-button": _vm.popupButton,
              "image-tag-text": _vm.getProductTag,
              "product-pallets": _vm.productPallets,
              "current-numeric-locale": _vm.currentNumericLocale,
            },
            on: { "close-popup": _vm.closePopup },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "spacebreak-disabled": _vm.isSpacebreakLocked(_vm.spacebreakId),
          },
        },
        [
          _vm.product.isHighlighted
            ? _c("highlighted-area", {
                attrs: { "outer-style": _vm.getHighlightContainerSize },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex product-tile-header w-100 top-row",
              style: _vm.productDetailsStyle,
            },
            [
              _vm.product.productSeries
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex product-icon",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.onProductSeriesIconClick.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_c("series-icon")],
                    1
                  )
                : _c("div", {
                    staticClass: "h-100",
                    staticStyle: { width: "18px" },
                  }),
              _vm._v(" "),
              _vm.hasPallets
                ? _c(
                    "div",
                    { staticClass: "d-flex product-icon" },
                    [
                      _c("pallet-icon", {
                        attrs: {
                          fill: _vm.hasAssignedPallets
                            ? _vm.iconPrimary
                            : _vm.iconDisabled,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                VIcon,
                {
                  staticClass: "d-flex product-icon lock-icon",
                  class: { "lock-icon__locked": _vm.isLocked },
                  attrs: {
                    disabled:
                      _vm.isSpacebreakLocked(_vm.spacebreakId) ||
                      _vm.isEditingDisabled ||
                      !_vm.inReset ||
                      !_vm.canProductBeMoved,
                    color: "white",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.toggleLock.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.isLocked
                          ? "mdi-lock-outline"
                          : "mdi-lock-open-variant-outline"
                      ) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex product-tile-header w-100 middle-row",
              style: _vm.productDetailsStyle,
            },
            [
              _vm.showMinimumDistribution && _vm.product.minimumDistribution > 0
                ? _c(
                    VIcon,
                    {
                      staticClass: "d-flex product-icon",
                      attrs: { color: "white", size: "18", width: "18" },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s("mdi-speedometer") + "\n      "
                      ),
                    ]
                  )
                : _c("div", { staticClass: "w-100 h-100" }),
              _vm._v(" "),
              !_vm.inReset
                ? _c(
                    "div",
                    { staticClass: "d-flex product-icon pull-right" },
                    [_c("not-in-reset-icon", { attrs: { color: "white" } })],
                    1
                  )
                : _c("div", { staticClass: "w-100 h-100" }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex product-tile-header w-100 bottom-row",
              style: _vm.productDetailsStyle,
            },
            [
              _c("product-delta-tag", {
                attrs: {
                  show: _vm.referenceDelta !== 0 && _vm.product.isEligible,
                  type: "R",
                  delta: _vm.referenceDelta,
                },
              }),
              _vm._v(" "),
              _c("assortment-tag", {
                attrs: { "tag-text": _vm.getProductTag },
              }),
              _vm._v(" "),
              _c("product-delta-tag", {
                attrs: {
                  show: _vm.shouldShowOptimisedDelta,
                  type: "O",
                  delta: _vm.lastOptimisedDelta,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex product-tile", style: _vm.baseStyle },
            [
              _c(
                "assortment-image",
                _vm._b(
                  {
                    attrs: {
                      src: _vm.imageUrl,
                      alt: _vm.displayText,
                      title: _vm.displayText,
                      "tile-size": _vm.selectedTileSize,
                      "tooltip-text": _vm.tooltipText,
                      "tag-text": _vm.getProductTag,
                      "tag-position-relative": false,
                    },
                  },
                  "assortment-image",
                  _vm.baseStyle,
                  false
                )
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }