<template>
  <div>
    <workpackages-toolbar
      doc-path="160-templates"
      :entity="entities.template"
      @on-search="onSearch"
      @on-sort="onSort"
    />
    <v-card class="col-box">
      <v-alert v-if="loading" border="left" colored-border color="primary accent-4">
        <v-progress-circular indeterminate color="primary" size="20" />
        {{ $t('templatesPage.loadingWorkpackageTemplates') }}
      </v-alert>
      <v-row no-gutters>
        <v-col class="workpackages-container" colspan="12">
          <workpackages-list
            v-if="sortedWorkpackagesList.length"
            :workpackages="sortedWorkpackagesList"
            :visible-workpackages="visibleTemplates"
            :entity="entities.template"
          />
          <v-alert v-if="!loading && !visibleTemplates.length" type="info" text>
            {{ $t('workpackages.noActiveWps') }}
          </v-alert>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col class="horizontal-divider-container" colspan="12">
        <div class="horizontal-divider" />
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col colspan="12">
        <v-btn
          class="mb-3"
          data-id-e2e="btnNewWorkpackageTemplate"
          primary
          :disabled="isNewButtonDisabled"
          @click="addWorkpackage({ isTemplate: true })"
          >{{ $t('templatesPage.addNewButton') }}</v-btn
        >
        <v-btn
          v-if="bundlesEnabled"
          class="mb-3"
          data-id-e2e="btnNewWorkpackageTemplateWithoutBundle"
          primary
          :disabled="isNewButtonDisabled"
          @click="addWorkpackage({ isTemplate: true, withoutBundle: true })"
        >
          {{ $t('templatesPage.addNewWithoutBundleButton') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { size, map } from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import entities from '@enums/entities';
import sortUtils from '../../../utils/sort-utils';
import searchUtils from '../../../utils/search-utils';

export default {
  data() {
    return {
      sortOrder: null,
      searchText: '',
      entities,
    };
  },

  computed: {
    ...mapGetters('workpackages', ['workpackageTemplates']),
    ...mapState('workpackages', ['loadingWorkpackageTemplates']),

    loading() {
      return this.loadingWorkpackageTemplates;
    },

    isNewButtonDisabled() {
      const templates = size(this.workpackageTemplates);
      return (
        !this.hasPermission(this.userPermissions.canCreateWorkpackage) ||
        !!(templates && !this.workpackageTemplates[templates - 1]._id)
      );
    },

    visibleTemplates() {
      return searchUtils.getResultsByNameDescription(
        this.searchText,
        this.workpackageTemplates,
        this.sortOrder
      );
    },

    sortedWorkpackagesList() {
      return this.sortOrder
        ? sortUtils.sortItemList(this.workpackageTemplates, this.sortOrder)
        : this.workpackageTemplates;
    },
  },
  // Initialize getting all workpackages
  async created() {
    await this.fetchWorkpackages({ shouldGetTemplates: true, pickExtra: ['status'] });

    // Collect all the workpackage scenarios that are likely to be required for the user.
    await this.fetchScenarios({
      params: {
        where: {
          workpackageId: { $in: map(this.workpackageTemplates, '_id') },
        },
      },
    });
  },

  methods: {
    ...mapMutations('workpackages', ['setLoadingWorkpackageTemplates']),
    ...mapActions('workpackages', ['fetchWorkpackages', 'addWorkpackage']),
    ...mapActions('scenarios', ['fetchScenarios']),

    onSearch(searchText) {
      this.setLoadingWorkpackageTemplates(true);
      this.searchText = searchText;
      if (!searchText) {
        this.setLoadingWorkpackageTemplates(false);
        return;
      }
      this.setLoadingWorkpackageTemplates(false);
    },

    onSort(sortOrder) {
      this.sortOrder = sortOrder;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .v-application .headline {
    font-family: $assortment-regular-font;
  }
}

::v-deep {
  .progress-bar {
    padding-bottom: 10em;
  }
}
</style>
