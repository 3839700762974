import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      staticStyle: { "background-color": "white" },
      attrs: { width: "900" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                VIcon,
                _vm._g(
                  {
                    attrs: { size: "20" },
                    on: { click: _vm.renderPartialDocs },
                  },
                  on
                ),
                [_vm._v("mdi-help-circle")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _vm._v(" "),
      _c(
        "dialog-card",
        {
          staticClass: "rtls-dialog--legend",
          on: { close: _vm.closePopup },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _vm.fullToolGuideEnabled
                    ? _c(
                        VBtn,
                        {
                          attrs: {
                            secondary: "",
                            depressed: "",
                            href: _vm.docslink,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("actions.openFullToolGuide")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._t("default", function () {
            return [
              _c("div", {
                staticClass: "docs-text",
                domProps: { innerHTML: _vm._s(_vm.docsText) },
              }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }