<template>
  <component :is="params.value.component" v-bind="params.value.props" />
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'agGridCellRendererComponentWrapper',
});
</script>
