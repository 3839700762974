<template>
  <div class="progress-bar">
    <p v-if="showProgress">
      <v-progress-circular
        :size="80"
        :width="9"
        :rotate="-90"
        color="primary"
        :value="progressValue"
      >
        {{ progressText }}
      </v-progress-circular>
    </p>
    <p>{{ message }}</p>
    <v-progress-linear indeterminate />
  </div>
</template>

<script>
// This component is intended for use when analytics is running and the screen is locked out
// The progress component can show queue status and percentage complete. Recommended to not use status without a percentage

import { jobStatuses, getJobStatusSimplified, jobSimpleStatuses } from '@enums/jobapi';

export default {
  props: {
    message: {
      type: String,
      required: false,
      default: () => '',
    },
    percentage: {
      type: Number,
      required: false,
      default: null,
    },
    status: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    showProgress() {
      return (
        this.percentage !== null ||
        getJobStatusSimplified(this.status) === jobSimpleStatuses.inProgress
      );
    },

    progressText() {
      if (this.status === jobStatuses.queued) return this.$t('general.jobStatuses.queued');
      if (this.status === jobStatuses.starting) return this.$t('general.jobStatuses.starting');
      if (this.status === jobStatuses.started && !this.percentage) {
        return this.$t('general.jobStatuses.started');
      }

      if (this.percentage !== null) return `${Math.floor(this.percentage)}%`;
      return null;
    },

    progressValue() {
      if (this.percentage !== null) return Math.floor(this.percentage);
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.progress-bar {
  width: 30%;
  align-self: center;
  padding-top: 10em;
  margin: auto;
  p {
    font-size: 14px;
    text-align: center;
    color: $assortment-text-colour;
  }
}
</style>
