import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    [
      _c(
        VRow,
        { staticClass: "mb-3 d-flex justify-space-between" },
        [
          _c("h2", { staticClass: "mb-1" }, [
            _vm._v(_vm._s(_vm.$tkey("title"))),
          ]),
          _vm._v(" "),
          _c(
            VBtn,
            {
              staticClass: "close-button",
              attrs: {
                "data-id-e2e": "btnCopyConstraintsModalGoBackToSettings",
                icon: "",
                text: "",
              },
              on: { click: _vm.backToConstraintsTable },
            },
            [
              _c(VIcon, { attrs: { size: "20", color: "primary" } }, [
                _vm._v("mdi-close-circle"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        [
          _c("search-scenario", {
            attrs: {
              inline: "",
              "data-id-e2e": "searchScenarioSection",
              rules: [],
            },
            on: {
              "scenario-id-selected": _vm.updateSelectedScenario,
              "view-results": _vm.populateAssortmentCanvasesAndCheckpoints,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        {
          staticClass: "d-block",
          attrs: { "data-id-e2e": "checkpointsSelectionTableSection" },
        },
        [
          _c("checkpoints-selection-table", {
            attrs: {
              "scenario-id": _vm.selectedScenarioId,
              headers: _vm.headers,
              canvases: _vm.selectedScenarioCanvases,
              "checkpoints-by-canvas-id": _vm.scenarioCheckpointsByCanvasId,
              "should-validate-checkpoints": false,
              "can-view-checkpoint-data": "",
              "max-height": "40%",
            },
            on: { "view-checkpoint-data": _vm.populateCheckpointConstraints },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        [
          _c("h3", { staticClass: "my-3" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$t(
                    "assortmentCanvasPage.spacebreakSettings.constraints.title"
                  )
                ) +
                ":\n      "
            ),
            _c("span", { staticClass: "green-text" }, [
              _vm._v(
                _vm._s(
                  _vm.constraintsCopyTarget
                    ? `${_vm.copyConstraintsDescription.workpackageScenarioTitle} / ${_vm.copyConstraintsDescription.checkpointTitle}`
                    : ""
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.isFetchingConstraintsForCheckpoint
            ? _c(
                "div",
                { staticClass: "w-100" },
                [
                  _c("progress-bar", {
                    attrs: { message: _vm.$t("general.loading") },
                  }),
                ],
                1
              )
            : _vm._l(_vm.spacebreaksWithGlobal, function (currentSpacebreak) {
                return _c("spacebreak-constraints-table", {
                  key: `${
                    currentSpacebreak
                      ? currentSpacebreak._id
                      : "globalSpacebreak"
                  }-${_vm.selectedCheckpointId}`,
                  attrs: {
                    "is-copying-constraints": "",
                    "attributes-values-in-current-scenario":
                      _vm.attributesInCurrentScenario,
                    "attributes-values-in-source-scenario":
                      _vm.constraintsCopyTarget.attributesValuesMap,
                    spacebreak: currentSpacebreak,
                    constraints: _vm.sanitiseConstraints(
                      currentSpacebreak,
                      _vm.constraintsCopyTarget.constraints
                    ),
                    "source-scenario-attributes":
                      _vm.constraintsCopyTarget.customAttributes,
                  },
                })
              }),
        ],
        2
      ),
      _vm._v(" "),
      _c(VRow, { staticClass: "d-flex mt-3 justify-end" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-end" },
          [
            _c(
              VBtn,
              {
                staticClass: "ml-2",
                attrs: {
                  secondary: "",
                  depressed: "",
                  "data-id-e2e": "btnGoBackToConstraintsTable",
                },
                on: { click: _vm.backToConstraintsTable },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("actions.cancel")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              VBtn,
              {
                staticClass: "ml-2",
                attrs: {
                  primary: "",
                  depressed: "",
                  "data-id-e2e": "btnCopyConstraintsSelected",
                  disabled: !_vm.hasConstraintsToCopySelected,
                  loading: _vm.saveInProgress,
                },
                on: { click: _vm.onCopyConstraintsSelected },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("actions.copyConstraints")) +
                    "\n        "
                ),
                _c(VIcon, { attrs: { size: "14", right: "" } }, [
                  _vm._v("mdi-plus"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }