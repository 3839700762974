<template>
  <v-card class="step-tab-panel" flat>
    <v-container v-if="noProductsHaveSwapsData" class="pt-3 pa-2 ma-0">
      <v-alert text class="mb-1">
        {{ $tkey('checkSuggestedSelectionsMessage') }}
      </v-alert>
    </v-container>

    <div class="assortment-table attributes-table d-flex flex-column">
      <v-container class="actions-container flex-grow-0">
        <v-row>
          <v-col v-if="showErrorControls" id="error-controls" class="d-flex align-center col">
            <span class="invalid-rows-error-box pr-2">{{ invalidRowsErrorMessage }}</span>
            <v-btn
              v-if="!filterInvalidRows"
              outlined
              depressed
              color="error"
              @click="toggleInvalidRows"
            >
              {{ $t('actions.showInvalidRows') }}
            </v-btn>
            <v-btn v-else outlined depressed color="primary" @click="toggleInvalidRows">
              {{ $t('actions.showAllRows') }}
            </v-btn>
          </v-col>
          <v-col class="actions-col actions-col--search">
            <!-- This uses @input as @change wasn't wired for rtls-search yet -->
            <!-- Should switch to using @change after AOV3-777 -->
            <rtls-search
              v-if="gridApi"
              v-model="searchString"
              grey
              width="240px"
              :placeholder="$tkey('searchPlaceholder')"
              @input="gridApi.onFilterChanged()"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-container class="actions-container flex-grow-0">
        <v-row class="products-table-actions">
          <v-col data-id-e2e="btnProductsSwapImport" class="actions-col">
            <data-upload
              :legends="csvUploadLegends"
              :disabled="isEditingDisabled"
              :csv-upload-handler="onCSVUpload"
              :products-data="productsData"
              :used-header-names="allHeaders"
              :custom-fields="[...swapsAttributeHeaderNames]"
              show-modal
              @process="process"
            />
          </v-col>
        </v-row>
      </v-container>

      <div class="ag-grid-box flex-grow-1">
        <ag-grid-vue
          style="width: 100%; height: 100%;"
          class="ag-theme-custom ag-theme-custom--attributes"
          :row-data="productsData"
          :grid-options="gridOptions"
          :does-external-filter-pass="doesExternalFilterPass"
          :stop-editing-when-cells-loses-focus="true"
          :enable-range-selection="true"
          @cell-value-changed="trackDiff"
          @grid-ready="onGridReady"
        />
      </div>

      <page-actions
        show-export
        is-custom-export
        :has-data-changes="!isActionsDisabled"
        :has-data-errors="hasDataErrors"
        :is-discard-enabled="!isDiscardDisabled"
        :save-disabled="isEditingDisabled"
        @export="exportCSV()"
        @discard="discard()"
        @save="saveChanges()"
      >
        <template v-slot:right-btns>
          <div class="optimise-btn-container">
            <v-btn primary :disabled="isEditingDisabled" @click="openSwapParametersModal">
              {{ $tkey('optimiseSwapsButton') }}
            </v-btn>
          </div>
        </template>
      </page-actions>

      <swap-parameters-modal :value="swapParametersModalOpen" @close="closeSwapParametersModal" />
    </div>
    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="closeUnsavedDataModal"
      @confirm="closeUnsavedDataModal"
    />
  </v-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  isUndefined,
  get,
  isEmpty,
  isEqual,
  isObject,
  cloneDeep,
  keyBy,
  merge,
  set,
  mapValues,
  keys,
  each,
  reduce,
  pick,
  size,
  some,
  isNull,
  map,
  pickBy,
  omit,
} from 'lodash';
import to from 'await-to-js';
import agGridUtils from '@/js/utils/ag-grid-utils';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import i18n from '@/js/vue-i18n';
import exportCSV from '@/js/mixins/export-csv';

// We need to do translations inside of ag-grid functions where this is not bound to Vue.
const localizationKey = 'productsToSwapPage';
const t = (key, locKey = localizationKey) => i18n.t(`${locKey}.${key}`);

function getProductState(newProductValue) {
  // Can't use this.$t inside of a valueGetter
  // This is why we have a direct import of translations above
  // Think this is definitely solvable, just not sure how right now.
  const translationValue = newProductValue
    ? t('attributes.newProduct', localizationKey)
    : t('attributes.existingProduct', localizationKey);
  if (translationValue.length > 5) {
    return translationValue.slice(0, 5);
  }
  return translationValue;
}

export default {
  name: 'ProductsToSwap',
  components: {
    AgGridVue,
  },
  mixins: [unsavedDataWarningMixin, exportCSV],

  localizationKey,
  data() {
    return {
      filterInvalidRows: false,
      errorData: {},
      allHeaders: [],
      headers: [],
      initialHeaders: {
        pinnedHeaders: {
          openByDefault: true,
          groupId: 'product-details',
          children: [
            {
              field: 'isNewProduct',
              colId: 'isNewProduct',
              headerName: '',
              editable: false,
              minWidth: 50,
              maxWidth: 50,
              resizable: false,
              suppressMenu: true, // Currently shows blank or true - would need to modify underlying data to fix
              cellClass: 'justified-center border-left limit-focus-padding chip-cell',
              cellRenderer(params) {
                return `
                  <span class="chip ${params.data.isNewProduct ? 'new' : 'current'}">
                    <span class="chip__content">
                      ${getProductState(params.data.isNewProduct)}
                    </span>
                  </span>
                `;
              },
              pinned: 'left',
            },
            {
              field: 'productKey',
              colId: 'productKey',
              headerName: '',
              editable: false,
              suppressMenu: true,
              hide: true,
            },
            {
              field: 'productKeyDisplay',
              colId: 'productKeyDisplay',
              headerName: this.$tkey('attributes.productKeyDisplay'),
              filter: 'agTextColumnFilter',
              suppressMenu: false,
              editable: params => {
                return !this.isEditingDisabled && params.data.isNewProduct;
              },
              width: 100,
              pinned: 'left',
              cellClass: 'bold-text',
            },
            {
              field: 'itemDescription',
              colId: 'itemDescription',
              headerName: this.$tkey('attributes.itemDescription'),
              filter: 'agTextColumnFilter',
              suppressMenu: false,
              editable: params => {
                return !this.isEditingDisabled && params.data.isNewProduct;
              },
              filterParams: agGridUtils.filters.standardStringFilter,
              minWidth: 250,
              flex: 1,
              pinned: 'left',
            },
          ],
        },
      },
      swapsAttributeHeaders: [],
      savedState: {},
      csvUploadLegends: {
        buttonName: this.$t('actions.manualImport'),
        title: this.$t('actions.manualImport'),
      },
      gridOptions: {
        suppressContextMenu: true,
        enableFillHandle: true,
        defaultColDef: {
          filter: true,
          sortable: true,
          resizable: true,
          minWidth: 80,
          comparator: agGridUtils.sortings.naturalSort,
          menuTabs: ['filterMenuTab'],
          editable: true,
          suppressMovable: true,
          valueParser: agGridUtils.parsers.defaultParser,
          required: true,
          cellClassRules: {
            'diff-background': this.hasDiff,
            'invalid-nonempty': agGridUtils.validations.validateValueExists,
          },
          suppressKeyboardEvent: agGridUtils.utils.suppressKeyboardEvent,
        },
        processDataFromClipboard: agGridUtils.utils.processDataFromClipboard,
        isExternalFilterPresent() {
          return true;
        },
        processCellFromClipboard: agGridUtils.utils.processCellFromClipboard,
        getRowId(params) {
          const { data } = params;
          return data.productKey;
        },
        rowHeight: 30,
        headerHeight: 30,
      },
      searchString: '',
      gridApi: null,
      columnApi: null,
      productsData: null,
      currentStateDiff: {},
      productsWithoutSwapsAttributes: [],
      swapParametersModalOpen: false,
    };
  },

  computed: {
    ...mapState('furniture', ['scenarioFurniture']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('context', ['getCsvExport', 'getDateFormats']),

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canTriggerOptimiseSwaps);
    },

    storeClasses() {
      if (isEmpty(this.scenarioFurniture)) return [];

      return this.scenarioFurniture.storeClasses.map(({ _id, name }) => ({ _id, name }));
    },

    hasDataChanges() {
      return !isEmpty(this.currentStateDiff);
    },

    noProductsHaveSwapsData() {
      // Stops flash of alert on initial load
      if (isNull(this.productsData) || isEmpty(this.productsWithoutSwapsAttributes)) return false;

      return size(this.productsData) === size(this.productsWithoutSwapsAttributes);
    },

    isDiscardDisabled() {
      return (
        this.isEditingDisabled || (isEmpty(this.currentStateDiff) && this.noProductsHaveSwapsData)
      );
    },

    isActionsDisabled() {
      // This lets you save the default data
      if (this.noProductsHaveSwapsData) return false;

      return !this.hasDataChanges;
    },

    hasDataErrors() {
      // required for <page-actions> component
      return false;
    },

    showErrorControls() {
      return size(this.errorData) || this.filterInvalidRows;
    },

    invalidRowsErrorMessage() {
      return this.$t(`validationErrors.rowsHaveInvalidValues`);
    },

    requiredFields() {
      if (!this.headers) return [];
      this.headers.forEach(h => this.getHeaderDefs(h));
      return this.allHeaders.filter(h => h.required).map(c => c.colId);
    },

    swapsAttributeColumnsByField() {
      return keyBy(this.swapsAttributeHeaders, 'field');
    },

    swapsAttributeHeaderNames() {
      return map(this.swapsAttributeHeaders, h => `${h.scName} - ${h.headerName}`);
    },
  },

  async created() {
    await this.init();
  },

  methods: {
    ...mapActions('scenarioProducts', [
      'fetchScenarioProducts',
      'updateScenarioSwapsAttributes',
      'processCSV',
    ]),
    ...mapActions('furniture', ['fetchScenarioFurniture']),
    ...mapActions('files', ['uploadCSV']),
    ...mapActions('snackbar', ['showWarning', 'showSuccess', 'showError']),

    getHeaderDefs(obj) {
      const children = get(obj, 'children');
      if (children) {
        children.forEach(n => this.getHeaderDefs(n));
      } else {
        this.allHeaders.push(obj);
      }
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.header = this.setHeaders();
    },

    setHeaders() {
      // This method constructs the headers and sets them for the grid.
      const createStoreClassHeaderGroup = sc => {
        const groupChildren = [
          {
            headerName: this.$tkey('addHeader'),
            colId: 'eligibleToAdd',
            width: 70,
            height: 30,
            headerHeight: 30,
            resizable: false,
            editable: !this.isEditingDisabled,
            field: `restructuredSwapsAttributes.${sc._id}.eligibleToAdd`,
            valueParser: agGridUtils.parsers.booleanParser,
            cellRenderer: params =>
              agGridUtils.utils.checkboxRenderer(params, this.handleCheckboxChange),
            cellRendererParams: {
              field: `restructuredSwapsAttributes.${sc._id}.eligibleToAdd`,
            },
            scName: sc.name,
          },
          {
            headerName: this.$tkey('removeHeader'),
            colId: 'eligibleToRemove',
            width: 90,
            height: 30,
            headerHeight: 30,
            resizable: false,
            editable: !this.isEditingDisabled,
            field: `restructuredSwapsAttributes.${sc._id}.eligibleToRemove`,
            valueParser: agGridUtils.parsers.booleanParser,
            cellRenderer: params =>
              agGridUtils.utils.checkboxRenderer(params, this.handleCheckboxChange),
            cellRendererParams: {
              field: `restructuredSwapsAttributes.${sc._id}.eligibleToRemove`,
            },
            scName: sc.name,
          },
          {
            headerName: this.$tkey('swapGroupHeader'),
            colId: 'swapGroup',
            width: 80,
            height: 30,
            headerHeight: 30,
            field: `restructuredSwapsAttributes.${sc._id}.swapGroup`,
            required: true,
            editable: !this.isEditingDisabled,
            scName: sc.name,
          },
        ];
        this.swapsAttributeHeaders.push(...groupChildren);
        return {
          headerName: sc.name,
          children: groupChildren,
        };
      };

      const storeClassHeaders = this.storeClasses.map(createStoreClassHeaderGroup);
      const headers = [this.initialHeaders.pinnedHeaders, ...storeClassHeaders];

      this.gridApi.setColumnDefs(headers);
      return headers;
    },

    handleCheckboxChange(params) {
      params.setValue(!params.value);
    },

    discard() {
      this.productsData = cloneDeep(this.$options.productsData);
      this.currentStateDiff = {};
      this.productsWithoutSwapsAttributes = [];
    },

    async init() {
      const [scenarioProducts] = await Promise.all([
        this.fetchScenarioProducts({
          params: {
            pick: [
              'isNewProduct',
              'productKey',
              'productKeyDisplay',
              'itemDescription',
              'simpleSwapsAttributes',
            ],
            where: {
              $or: [{ parentProduct: '' }, { parentProduct: { $exists: false } }],
            },
          },
        }),
        this.fetchScenarioFurniture(),
      ]);

      // Headers require storeclass data to be initialised correctly
      this.headers = this.setHeaders();

      // Create default data for each storeclass.
      const defaultValues = this.storeClasses.reduce((defaultObj, sc) => {
        const storeClassId = sc._id;
        defaultObj[storeClassId] = {
          storeClassId,
          eligibleToAdd: false,
          eligibleToRemove: true,
          swapGroup: '',
        };

        return defaultObj;
      }, {});

      // All rows should have default values. Ag-grid needs an object path to set the value.
      // We create a default object for the existing storeclasses and merge our existing data onto it.
      // This gets flattened out before saving.
      const filledUpProducts = scenarioProducts.map(sp => {
        const existingSwapsAttributes = get(sp, 'simpleSwapsAttributes', {});

        if (isEmpty(existingSwapsAttributes))
          this.productsWithoutSwapsAttributes.push(`${sp.productKey}`);

        const restructuredSwapsAttributes = keyBy(existingSwapsAttributes, 'storeClassId');
        const combinedSwapsAttributes = merge({}, defaultValues, restructuredSwapsAttributes);

        return set(sp, 'restructuredSwapsAttributes', combinedSwapsAttributes);
      });

      this.productsData = filledUpProducts;

      // backup data
      this.currentStateDiff = {};
      this.$options.productsData = cloneDeep(this.productsData);
      this.savedState = keyBy(this.$options.productsData, 'productKey');
    },

    trackDiff(params) {
      const { productKey } = params.data;
      const { field } = params.colDef;
      this.updateDiff(productKey, field, params.value);
    },

    updateDiff(productKey, field, currentValue) {
      // Note: The eligibility data is a nested field and get saved as { 12: 'a.b.c': 5}
      // The saved version is { 12: { a: { b: { c: 5 }}}}
      // This is why we have different parameters to the get for the saved data vs the current

      const path = `${productKey}.${field}`;
      const originalValue = get(this.savedState, path);

      const existingValue = get(this.currentStateDiff, [productKey, field]);
      const valueHasChanged = agGridUtils.comparators.didValueChange(currentValue, originalValue);

      if (valueHasChanged) {
        if (!this.currentStateDiff[productKey]) this.$set(this.currentStateDiff, productKey, {});
        this.$set(this.currentStateDiff[productKey], field, currentValue);
      } else if (existingValue) {
        // Remove the field from the object if it's back to its old value.
        // Note: Entering values directly doesn't trigger cellValueChanged but pasting values does
        this.$delete(this.currentStateDiff[productKey], field);
        if (isEmpty(this.currentStateDiff[productKey])) {
          // If there's nothing left in the object, remove it.
          this.$delete(this.currentStateDiff, productKey);
        }
      }
    },

    hasDiff(params) {
      const { productKey } = params.data;
      const { field } = params.colDef;
      const path = `${productKey}.${field}`;

      const currentValue = get(params.data, field);
      const originalValue = get(this.savedState, path);

      const data = [];
      keys(params.data.restructuredSwapsAttributes).forEach(key => {
        data.push(params.data.restructuredSwapsAttributes[key]);
      });
      this.checkRowForErrors(Object.assign({ _id: params.data._id }, ...data));

      // return false if values are the same or if undefined is compared to an empty string
      return (
        !(isUndefined(originalValue) && currentValue === '') &&
        agGridUtils.comparators.didValueChange(currentValue, originalValue)
      );
    },

    updateRowsAfterImport(updates) {
      const productsToUpdate = keyBy(updates, 'productKey');

      // only apply updates if they're distinct from the current values
      const newUpdates = mapValues(productsToUpdate, (updatedRow, productKey) => {
        return pickBy(updatedRow, (value, field) => {
          const savedValue = get(this.savedState, [productKey, field]);
          // if value is restructuredSwapAttributes object, compare values for all its mutable props with original object values
          if (isObject(value) && field === 'restructuredSwapsAttributes') {
            return some(keys(value), key => {
              return (
                size(keys(value[key])) &&
                !isEqual(
                  omit(value[key], ['storeClassId']),
                  omit(savedValue[key], ['storeClassId'])
                )
              );
            });
          }
          return !isEqual(savedValue, value);
        });
      });

      // If there are no differences, stop now
      const updatesWithData = pickBy(newUpdates, size);
      if (isEmpty(updatesWithData)) return;

      const rowsToUpdate = keys(updatesWithData); // which rows to update
      const rows = rowsToUpdate.map(id => this.gridApi.getRowNode(id)); // get current state of rows in grid
      const update = rows.map(({ data }) => {
        const updateData = updatesWithData[data.productKey];
        return merge({}, data, updateData);
      });

      // update the currentStateDiff
      this.currentStateDiff = merge({}, this.currentStateDiff, updatesWithData);

      // also update products data, traverse products once
      const updatesByProductKey = keyBy(update, 'productKey');
      each(this.productsData, product => merge(product, updatesByProductKey[product.productKey]));

      this.gridApi.applyTransaction({
        update,
      });

      return update;
    },

    async saveChanges() {
      const updatedProducts = new Set(keys(this.currentStateDiff));

      // We store our diff as an object like { 'a.b.c.d': 5}
      // This map expands that to be properly nested, like { a: { b: { c: { d: 5 } } } }
      // This makes it easy to merge onto our existing data
      const updates = mapValues(this.currentStateDiff, product => {
        const restructuredProduct = {};
        each(product, (value, path) => set(restructuredProduct, path, value));
        return restructuredProduct;
      });

      const setOfProductsWithoutSwapsAttributes = new Set(this.productsWithoutSwapsAttributes);
      // If a product has been modified, get its entire swaps data and merge the changes in
      // We update the entire `simpleSwapsAttributes` field when any part of it changes for a product
      // This is simpler from a DB perspective and completes in ~400ms for 10000 products and 12 storeclasses
      // This also handles default data. productsWithoutSwapsAttributes contains the keys of all rows without data.
      // These get the default values updated in the DB.
      const savedUpdatedProducts = reduce(
        cloneDeep(this.savedState),
        (combinedSwapsData, product, productKey) => {
          const productUpdatedOrMissingSwapsData =
            updatedProducts.has(productKey) || setOfProductsWithoutSwapsAttributes.has(productKey);

          if (productUpdatedOrMissingSwapsData) {
            combinedSwapsData[productKey] = pick(product, 'restructuredSwapsAttributes');
          }
          return combinedSwapsData;
        },
        {}
      );

      // merge our updates onto the existing data for product that is either modified or missing data
      const updatedSwapAttributes = merge({}, savedUpdatedProducts, updates);

      await this.updateScenarioSwapsAttributes(updatedSwapAttributes);

      // update local data and reset any fields that are no longer relevant
      this.$options.productsData = cloneDeep(this.productsData); // current data now becomes backup
      this.savedState = merge({}, this.savedState, updatedSwapAttributes); // savedState gets updated with current changes
      this.currentStateDiff = {}; // no changes from saved state now exist.
      this.productsWithoutSwapsAttributes = []; // no products should be missing swaps data.
      const modifiedRows = keys(updates).map(key => {
        return this.gridApi.getRowNode(key);
      }); // get the modified row nodes
      this.gridApi.redrawRows({ rowNodes: modifiedRows }); // this refreshes the diff colouring on the cells

      return true;
    },

    doesExternalFilterPass(node) {
      if (this.filterInvalidRows) {
        return this.errorData[node.data._id];
      }

      const { productKeyDisplay, itemDescription } = node.data;

      const isInProductDisplayKey = String(productKeyDisplay).indexOf(this.searchString) !== -1;
      const isInDescription = itemDescription.indexOf(this.searchString) !== -1;
      return isInProductDisplayKey || isInDescription;
    },

    closeSwapParametersModal() {
      this.swapParametersModalOpen = false;
    },

    openSwapParametersModal() {
      this.swapParametersModalOpen = true;
    },

    checkRowForErrors(row) {
      const valuesFound = pick(row, this.requiredFields);
      if (some(valuesFound, v => agGridUtils.validations.valueIsFalsy({ value: v }))) {
        this.$set(this.errorData, row._id, true);
      } else {
        this.$delete(this.errorData, row._id);
      }

      // turn off filter if you've removed all invalid rows
      if (isEmpty(this.errorData) && this.filterInvalidRows) this.toggleInvalidRows();
    },

    toggleInvalidRows() {
      this.filterInvalidRows = !this.filterInvalidRows;
      this.gridApi.onFilterChanged();
    },

    mapHeaderNames(params) {
      if (params.column.colId === 'isNewProduct') {
        return this.$tkey('attributes.productType');
      }

      const { field } = params.column.colDef;
      const eligibilityAttribute = get(this.swapsAttributeColumnsByField, field);

      // If this is a custom attribute, return header name, otherwise return translation key
      return eligibilityAttribute
        ? `${eligibilityAttribute.scName} - ${eligibilityAttribute.headerName}`
        : this.$tkey(`attributes.${field}`);
    },

    processCellCallback(params) {
      // Being called for each cell

      // Map product type values
      if (params.column.colId === 'isNewProduct')
        return params.value
          ? this.$tkey('attributes.newProduct')
          : this.$tkey('attributes.existingProduct');

      return agGridUtils.utils.processCellForExport(params);
    },

    exportCSV() {
      const exportParams = {
        fileName: this.getFileName({
          serviceName: 'products-to-swap',
          workpackageName: this.selectedWorkpackage.name,
          scenarioName: this.selectedScenario.name,
          fileNameDateFormat: this.getDateFormats.csvFileName,
        }),
        suppressQuotes: this.getCsvExport.suppressQuotes,
        columnSeparator: this.getCsvExport.columnSeparator,
        processHeaderCallback: this.mapHeaderNames, // Being called for each column
        processCellCallback: this.processCellCallback,
      };

      this.gridApi.exportDataAsCsv(exportParams);
    },

    async onCSVUpload(formData) {
      const scenarioId = this.selectedScenario._id;
      formData.append('scenarioId', scenarioId);
      formData.append('fieldsToIgnore', this.$tkey('attributes.productType')); // ignore product type column on import
      return this.uploadCSV({ formData, service: 'products-to-swap' });
    },

    async process({ fileId, mappings, delimiter }) {
      this.gridApi.showLoadingOverlay();

      const uploadPromise = this.processCSV({
        fileId,
        mappings,
        delimiter,
        service: 'products-to-swap',
      });
      const [err, uploadData] = await to(uploadPromise);

      if (err) {
        this.gridApi.hideOverlay();
        return;
      }

      this.updateRowsAfterImport(uploadData.tableRows);
      this.gridApi.hideOverlay();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .v-alert {
    background: $assortment-warning-alert-background !important;
    border: 1px solid $assortment-warning-alert-border !important;
    border-radius: 0 !important;
    padding: 14px;

    &:before {
      content: none;
    }

    &__content {
      color: $assortment-text-colour;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

  .eligibility-to {
    span.ag-header-group-text {
      align-self: flex-end;
    }
  }
}

.optimise-btn-container {
  display: inline-block;
  padding: 0 14px;
  border-right: 1px solid $assortment-horizontal-border-colour;
}
</style>
