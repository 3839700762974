<template>
  <div>
    <v-menu
      v-model="menu"
      :disabled="disabled"
      :close-on-content-click="false"
      :min-width="500"
      :max-width="500"
      :max-height="500"
      :nudge-bottom="8"
      :z-index="menuZindex"
      offset-y
      @input="closeModal"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :ripple="false"
          :secondary="!menu"
          :primary="menu"
          :min-width="100"
          :style="filterButtonStyle"
          :height="28"
          :disabled="disabled"
          depressed
          v-bind="attrs"
          v-on="on"
        >
          {{ btnText }}
        </v-btn>
      </template>
      <v-card>
        <div class="pa-4 d-flex flex-column">
          <div class="d-flex flex-column">
            <div class="mb-2">
              <rtls-select
                v-model="filterValue"
                class="mt-1"
                grey
                :items="filterOptions"
                item-text="name"
                :placeholder="$t('general.select')"
                hide-details
                return-object
                @change="selectedFilterValues = []"
              />
            </div>
            <div class="mb-1 d-flex align-center justify-start">
              <v-checkbox
                class="rtls-checkbox mx-2 "
                :input-value="notEqualTo"
                @change="notEqualTo = $event"
              />
              <h3>{{ $tkey('notEqual') }}</h3>
            </div>
            <div class="mb-4 d-flex align-center">
              <v-autocomplete
                ref="autocompleteSelect"
                v-model="selectedFilterValues"
                :items="selectedFilterValueOptions"
                chips
                item-text="name"
                :multiple="selectedFilterDefinition && selectedFilterDefinition.allowSelectMultiple"
                :search-input.sync="searchInput"
                :allow-overflow="false"
                background-color="white"
                dense
                small-chips
                hide-details
                light
                hide-selected
                return-object
                :menu-props="{ closeOnClick: true }"
                @change="searchInput = ''"
                @click:append="menuArrow"
              >
                <template #selection="{ item }">
                  <v-chip :title="item.name" small grey close @click:close="deleteChip(item)"
                    >{{ item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn
                      icon
                      text
                      :disabled="isAddFilterDisabled"
                      class="ml-3 add-filter-button"
                      v-on="on"
                      @click="addFilter"
                    >
                      <v-icon color="primary">mdi-plus-box</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>{{
                  isAddFilterDisabled ? $tkey('selectAValueToAddFilter') : $tkey('addFilter')
                }}</span>
              </v-tooltip>
            </div>
            <div class="mb-5 d-flex flex-column">
              <div v-if="filters.length" class="d-flex flex-column">
                <h2 class="title-filters">{{ $tkey('appliedFilters') }}</h2>
                <div class="d-flex">
                  <v-col class="headers pr-0 pb-1">
                    <h4 class="pb-0">{{ $tkey('filterName') }}</h4>
                  </v-col>
                  <v-col class="headers pr-0 pb-1">
                    <h4 class="pb-0">{{ $tkey('equalNotEqual') }}</h4>
                  </v-col>
                  <v-col class="headers pr-0 pb-1">
                    <h4 class="pb-0">{{ $tkey('filterValues') }}</h4>
                  </v-col>
                  <v-col :cols="1" class="headers pb-1 pr-1" />
                </div>
                <div v-for="(filter, index) in filters" :key="filter.id" class="d-flex filter-row">
                  <v-col class="filter-item pr-0 pt-1 pb-1 d-flex flex-column justify-center">
                    {{ filter.filterName }}
                  </v-col>
                  <v-col class="filter-item pr-0 pt-1 pb-1 d-flex flex-column justify-center">
                    {{ filter.notEqualTo ? $tkey('notEqual') : $tkey('equal') }}
                  </v-col>
                  <v-col class="filter-item pr-0 pt-1 pb-1 d-flex flex-column justify-center">
                    <div v-for="value in filter.filterValues" :key="value.value + value.index">
                      {{ value.name }}
                    </div>
                  </v-col>
                  <v-col
                    :cols="1"
                    class="filter-item pt-1 pb-1 pr-1 d-flex flex-column justify-center align-center"
                  >
                    <v-btn icon class="ml-1" @click="removeFilter(index)">
                      <v-icon small color="primary">mdi-close-circle</v-icon>
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <div v-else>
                <h3>{{ $tkey('noFiltersApplied') }}</h3>
              </div>
            </div>
          </div>
          <div class="d-flex justify-end align-center close-icon-container">
            <v-btn
              class="mr-0 font-weight-bold btn-text"
              :ripple="false"
              text
              small
              @click="[(menu = false), closeModal(false)]"
              >{{ $t('actions.close') }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { isArray, isPlainObject, isEqual } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { Filters } from '@enums/assortment-canvases';
import { zIndexes } from '@enums/menus';

const localizationKey = 'filters';

export default {
  localizationKey,
  props: {
    filters: {
      required: true,
      type: Array,
    },
    filterOptions: {
      required: true,
      type: Array,
    },
    btnText: {
      required: true,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notEqualTo: false,
      searchInput: '',
      menu: false,
      filterValue: { name: null, type: null },
      selectedFilterValues: [],
      menuZindex: zIndexes.menu,
      previouslyAppliedFilters: [],
    };
  },

  computed: {
    selectedFilterDefinition() {
      return this.filterOptions.find(fi => fi.type === this.filterValue.type);
    },
    selectedFilterValueOptions() {
      if (!this.selectedFilterDefinition) return [];
      if (this.selectedFilterDefinition.valuesGetter)
        return this.selectedFilterDefinition.valuesGetter(this.filterValue);
      return this.selectedFilterDefinition.values;
    },
    isAddFilterDisabled() {
      // disable add filter button if no values are selected.
      // accounts for when autocomplete doesn't allow multiple selections, in that case the property is an object
      const isSingleValue = isPlainObject(this.selectedFilterValues);
      // accounts for multiple selections when property is an array
      const areMultipleValues = isArray(this.selectedFilterValues);
      if (isSingleValue) {
        return false;
      }
      if (areMultipleValues) {
        return !this.selectedFilterValues.length;
      }
      return true;
    },
    filterButtonStyle() {
      // Add padding to the main button when the menu opens, as it changes from secondary --> primary button
      // Makes sure UI elements stay in consistent place
      return this.menu ? { 'padding-right': '18px' } : {};
    },
  },

  created() {
    this.reset();
  },

  methods: {
    reset() {
      this.$emit('reset');
    },
    menuArrow() {
      // allow arrow icon to close autocomplete selection menu
      const autocompleteSelect = this.$refs.autocompleteSelect;
      if (autocompleteSelect.isMenuActive) {
        this.$refs.autocompleteSelect.isMenuActive = false;
        autocompleteSelect.blur();
      } else {
        this.$refs.autocompleteSelect.isMenuActive = true;
        autocompleteSelect.focus();
      }
    },
    addFilter() {
      const newFilter = {
        id: uuidV4(),
        notEqualTo: this.notEqualTo,
        filterType: this.filterValue.type,
        path: this.filterValue.path,
        // Need to add the attributeIds for both attributes as there are scenarios where attributes
        // can have the same name but different ids when comparing across different scenarios.
        attributeIds:
          this.filterValue.type === Filters.customAttributes
            ? this.filterValue.attributeIds || [this.filterValue.id]
            : '',
        filterName: this.filterValue.name,
        filterValues: isArray(this.selectedFilterValues)
          ? this.selectedFilterValues
          : [this.selectedFilterValues],
      };
      this.selectedFilterValues = [];
      this.filterValue = {};
      this.notEqualTo = false;
      this.$emit('change', [...this.filters, newFilter]);
    },
    removeFilter(index) {
      this.$emit('change', [...this.filters.slice(0, index), ...this.filters.slice(index + 1)]);
    },
    deleteChip(item) {
      if (isArray(this.selectedFilterValues)) {
        const index = this.selectedFilterValues.indexOf(item);
        this.selectedFilterValues.splice(index, 1);
      } else {
        this.selectedFilterValues = null;
      }
    },
    differenceInFilters(previous, current) {
      const previousIds = previous.map(({ id }) => id);
      const currentIds = current.map(({ id }) => id);
      return !isEqual(previousIds, currentIds);
    },
    closeModal() {
      if (!this.menu && this.differenceInFilters(this.previouslyAppliedFilters, this.filters)) {
        this.previouslyAppliedFilters = this.filters;
        this.$emit('apply');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.close-icon-container {
  width: 100%;
}

.btn-text {
  color: $assortment-primary-colour;
  padding-right: 0 !important;

  &::before {
    opacity: 0 !important;
  }
}

::v-deep {
  .v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before {
    opacity: 0 !important;
  }
  .v-btn[primary]:hover {
    background-color: $assortment-primary-colour !important;
  }

  .rtls-checkbox {
    margin: 0 !important;

    .v-input--selection-controls__input {
      margin-right: 0 !important;
    }
  }

  .v-chip {
    padding-right: 30px;
  }

  .v-chip__content {
    // set max-width on the chip content so it doesn't overflow the container
    max-width: 300px;
    height: auto;
    display: inline-block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .v-chip__close {
    position: absolute;
    top: 3px;
    right: 12px;
    width: 24px;
  }
}

.title-filters {
  padding-left: 5px;
}

.filter-item {
  font-size: 1.2rem;
  padding-left: 5px;
}

.filter-row {
  border-bottom: 1px solid $assortment-divider-colour;
  &:nth-child(odd) {
    background: $assortment-table-blue-bg-colour;
  }
}

.add-filter-button {
  margin-right: 3px;
}

.headers {
  border-bottom: 1px solid $assortment-divider-colour-darker;
  padding-left: 5px;
}
</style>
