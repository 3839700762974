var render = function render(_c, _vm) {
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "12",
        height: "15",
        viewBox: "0 0 12 15",
        fill: "none",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M1.1875 0.5C1.30353 0.5 1.41481 0.546094 1.49686 0.628141C1.57891 0.710188 1.625 0.821468 1.625 0.9375V2.25H10.375V0.9375C10.375 0.821468 10.4211 0.710188 10.5031 0.628141C10.5852 0.546094 10.6965 0.5 10.8125 0.5C10.9285 0.5 11.0398 0.546094 11.1219 0.628141C11.2039 0.710188 11.25 0.821468 11.25 0.9375V14.0625C11.25 14.1785 11.2039 14.2898 11.1219 14.3719C11.0398 14.4539 10.9285 14.5 10.8125 14.5C10.6965 14.5 10.5852 14.4539 10.5031 14.3719C10.4211 14.2898 10.375 14.1785 10.375 14.0625V13.625H1.625V14.0625C1.625 14.1785 1.57891 14.2898 1.49686 14.3719C1.41481 14.4539 1.30353 14.5 1.1875 14.5C1.07147 14.5 0.960188 14.4539 0.878141 14.3719C0.796094 14.2898 0.75 14.1785 0.75 14.0625V0.9375C0.75 0.821468 0.796094 0.710188 0.878141 0.628141C0.960188 0.546094 1.07147 0.5 1.1875 0.5ZM1.625 12.75H10.375V10.125H1.625V12.75ZM1.625 9.25H10.375V6.625H1.625V9.25ZM1.625 5.75H10.375V3.125H1.625V5.75Z",
          fill: "#39477B",
          stroke: "#39477B",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }