var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.rowData
      ? _c(
          "div",
          { staticClass: "striped-table--read-only" },
          [
            _c(
              "div",
              { staticClass: "striped-table__row striped-table__header" },
              _vm._l(_vm.tableColumns, function (column) {
                return _c(
                  "div",
                  {
                    key: column.key,
                    staticClass: "striped-table__col",
                    class: column.headerClass || "",
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(column.headerName) + "\n      "
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _vm._l(_vm.rowData, function (row) {
              return _c(
                "div",
                { staticClass: "striped-table__row" },
                _vm._l(_vm.tableColumns, function (column) {
                  return _c(
                    "div",
                    {
                      staticClass: "striped-table__col",
                      class: column.classes || "",
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(row[column.key]) + "\n      "
                      ),
                    ]
                  )
                }),
                0
              )
            }),
          ],
          2
        )
      : _c("div", { staticClass: "my-3 text-center" }, [
          _c("p", { staticClass: "ma-0" }, [
            _vm._v(_vm._s(_vm.$t("errors.noDataAvailable"))),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }