<template>
  <div :key="`stats-${isSidebarShown}`" class="h-100 d-flex flex-column">
    <reporting-section :short-view="shortView">
      <template v-slot:header>
        <reporting-header :short-view="shortView" :section="section">
          <template v-slot:filter>
            <filters
              v-if="!shortView"
              :filters="filters[reportingSections.stats]"
              :filter-options="filterOptions"
              :btn-text="filterButtonText"
              :disabled="isStatsReportDisabled || filtersDisabled"
              class="ml-3"
              @change="handleFilterChange"
              @apply="applyFilters"
            />
          </template>
        </reporting-header>
      </template>
      <template v-slot:main-section>
        <reporting-main-section
          :section="section"
          :short-view="shortView"
          :subtitle="$tkey('subtitle')"
        >
          <template v-slot:data-section>
            <div class="ag-grid-box d-flex w-100 mr-5 h-100" style="height: initial">
              <div v-if="isStatsReportDisabled" class="ma-auto no-data">
                {{ $t('reportingPage.noPermissions') }}
              </div>
              <progress-bar
                v-else-if="isTableLoading"
                :message="$t('reportingPage.loadingTable')"
                style="margin: auto"
                class="pt-5"
              />
              <ag-grid-vue
                v-else
                tree-data
                animate-rows
                dense
                class="ag-theme-custom w-100"
                :column-defs="headers"
                :row-data="tableData"
                :grid-options="gridOptions"
                @grid-ready="onGridReady"
              />
            </div>
            <div
              v-if="!shortView"
              class="ag-grid-box d-flex w-100 mr-5 h-100"
              style="height: initial"
            >
              <progress-bar
                v-if="isChartLoading"
                :message="$t('reportingPage.loadingChart')"
                :style="{ margin: 'auto' }"
                class="pt-5"
              />
              <div v-else>
                <div class="header d-flex">
                  <span>{{ $tkey('absoluteChange') }}</span>
                  <rtls-select
                    :value="metricValue"
                    :items="enabledMetrics"
                    :placeholder="$t('general.select')"
                    :disabled="isStatsReportDisabled"
                    item-text="text"
                    item-value="type"
                    style="margin-left: 150px; margin-right: 10px"
                    @input="value => onSelectionChanged('metricValue', value)"
                  />
                  <rtls-select
                    :value="attributeValue"
                    :items="getAttributesIntersection(baselineIds, comparisonIds)"
                    :placeholder="$t('general.select')"
                    :disabled="isStatsReportDisabled"
                    item-text="name"
                    item-value="id"
                    class="mr-3"
                    @input="value => onSelectionChanged('attributeValue', value)"
                  />
                </div>
                <highcharts
                  v-if="!isStatsReportDisabled && hasAvailableChartData"
                  :key="highchartKey"
                  :options="chartOptions"
                />
              </div>
            </div>
          </template>
        </reporting-main-section>
      </template>
      <template v-slot:footer>
        <reporting-footer
          :short-view="shortView"
          :is-export-disabled="!tableData.length"
          @export="exportCSV"
        />
      </template>
    </reporting-section>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import {
  size,
  sortBy,
  union,
  forEach,
  sum,
  get,
  lowerCase,
  groupBy,
  map,
  reduce,
  includes,
  isEqual,
  keys,
  intersection,
} from 'lodash';
import reportingMetrics from '@enums/reporting-metrics';
import reportingSections from '@enums/reporting-sections';
import reportingFilters from '@enums/reporting-filters';
import reportingLevel from '@enums/reporting-data-level';
import exportCSV from '@/js/mixins/export-csv';
import agGridUtils from '@/js/utils/ag-grid-utils';

const CHARTS_LOOKUP = {
  [reportingMetrics.metrics.sales]: {
    numberFormat: 'currency',
    field: 'totalSales',
  },
  [reportingMetrics.metrics.margin]: {
    numberFormat: 'currency',
    field: 'totalMargin',
  },
  [reportingMetrics.metrics.volume]: {
    numberFormat: 'float',
    field: 'totalVolume',
  },
  [reportingMetrics.metrics.products]: {
    numberFormat: 'integer',
    field: 'totalProducts',
  },
  [reportingMetrics.metrics.stockingPoints]: {
    numberFormat: 'float',
    field: 'totalPointOfDistribution',
  },
  [reportingMetrics.metrics.linearSpace]: {
    numberFormat: 'float',
    field: 'totalSize',
  },
  [reportingMetrics.metrics.cubicSpace]: {
    numberFormat: 'float',
    field: 'totalSize',
  },
  [reportingMetrics.metrics.frontalSpace]: {
    numberFormat: 'float',
    field: 'totalSize',
  },
  [reportingMetrics.metrics.horizontalSpace]: {
    numberFormat: 'float',
    field: 'totalSize',
  },
};

export default {
  localizationKey: 'reportingPage.sections.stats',

  components: {
    AgGridVue,
  },

  mixins: [exportCSV],

  props: {
    // represents whether this report is open on (short) overview page
    shortView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      reportingSections,
      tableData: [],
      chartData: [],
      optionalColumnHeaders: {
        marginPercent: {
          headerName: this.$tkey('table.marginPercent'),
          headerClass: 'bold',
          valueGetter: this.getMarginPercent,
          valueFormatter: params => (params.value ? `${(params.value * 100).toFixed(2)}%` : '-'),
        },
      },
      gridApi: null,
      columnApi: null,
      gridOptions: {
        rowHeight: 30,
        headerHeight: 40,
        suppressContextMenu: true,
        suppressPropertyNamesCheck: true,
        rowClassRules: {
          'baseline-row': ({ data }) => data.checkpointType === reportingFilters.baseline,
          'comparison-row': ({ data }) => data.checkpointType === reportingFilters.comparison,
          'comparison2-row': ({ data }) => data.checkpointType === reportingFilters.comparison2,
        },
        defaultColDef: {
          editable: false,
          sortable: false,
          filter: false,
          menuTabs: [],
          suppressMovable: true, // do not make headers movable
          resizable: true,
        },
        autoGroupColumnDef: {
          headerName: '',
          field: 'name',
          resizable: true,
          minWidth: 150,
          cellClassRules: {
            'font-weight-bold': ({ data }) =>
              includes([reportingLevel.bundle, reportingLevel.workpackage], data.level),
          },
          cellRendererParams: {
            suppressCount: true,
          },
          tooltipShowDelay: 0,
          tooltipValueGetter: params => get(params, 'data.name', ''),
        },
        getDataPath(data) {
          return data.hierarchy;
        },
        getRowId(row) {
          const { data } = row;
          return `${data._id}-${data.checkpointType}`;
        },
      },
      section: reportingSections.stats,
      chartLoading: false,
    };
  },

  computed: {
    ...mapState('reporting', [
      'baselineIds',
      'comparisonIds',
      'comparison2Ids',
      'attributeValue',
      'metricValue',
      'loadingSubsection',
      'filters',
      'isSidebarShown',
    ]),
    ...mapGetters('reporting', [
      'getCanvasName',
      'getAttributesIntersection',
      'getAttributeFromId',
      'enabledMetrics',
      'scenarioIdsForSelection',
      'filterOptions',
    ]),
    ...mapGetters('context', ['getCsvExport', 'getDateFormats']),

    headers() {
      const headers = [
        {
          headerName: this.$tkey('table.products'),
          field: 'totalProducts',
          headerClass: 'bold',
          aggFunc: params => this.getAggValue(params),
        },
        {
          headerName: this.$tkey('table.stores'),
          field: 'totalStores',
          headerClass: 'bold',
          aggFunc: params => this.getAggValue(params),
        },
        {
          headerName: this.$tkey('table.sales'),
          field: 'totalSales',
          headerClass: 'bold',
          aggFunc: params => this.getAggValue(params),
          valueFormatter: params => {
            return this.formatNumber({ number: params.value, format: 'currency' });
          },
        },
        {
          headerName: this.$tkey('table.volume'),
          field: 'totalVolume',
          headerClass: 'bold',
          aggFunc: params => this.getAggValue(params),
          valueFormatter: params => {
            return this.formatNumber({ number: params.value, format: 'float' });
          },
        },
        {
          headerName: this.$tkey('table.margin'),
          field: 'totalMargin',
          headerClass: 'bold',
          aggFunc: params => this.getAggValue(params),
          valueFormatter: params => {
            return this.formatNumber({ number: params.value, format: 'currency' });
          },
        },
      ];

      // Additional column headers can be retrieved from config
      const extraColumns = get(this.getClientConfig, 'statsReportColumns', []);
      forEach(extraColumns, c => {
        const addOptionalColumnDef = this.optionalColumnHeaders[c];
        if (addOptionalColumnDef) headers.push(addOptionalColumnDef);
      });

      return headers;
    },

    isStatsReportDisabled() {
      return !this.hasPermission(this.userPermissions.canViewStatsReport);
    },

    hasValidChartSelection() {
      return !!this.attributeValue && !!this.metricValue;
    },

    isTableLoading() {
      return this.loadingSubsection.statsTable;
    },

    isChartLoading() {
      return this.loadingSubsection.statsChart || this.chartLoading;
    },

    hasAvailableChartData() {
      return this.hasValidChartSelection && !this.isChartLoading && size(this.chartData);
    },

    filtersDisabled() {
      return !this.hasAvailableChartData && this.isTableLoading;
    },

    getAllAttributesValues() {
      const currentAttributeSelected = this.getAttributeFromId;
      if (!currentAttributeSelected || !size(this.chartData)) return [];

      return this.attributeValuesByNameMap[lowerCase(currentAttributeSelected.name)] || [];
    },

    // Combine the baseline and comparison attribute values so that we have full lists for filters
    attributeValuesByNameMap() {
      return this.chartData.reduce((acc, checkpoint) => {
        forEach(checkpoint.attributesValues, (values, key) => {
          acc[key] = union(acc[key], values);
          return acc;
        });
        return acc;
      }, {});
    },

    filterButtonText() {
      return `${this.$tc(`reportingPage.filters`, this.filters[this.currentSection].length)}`;
    },

    getSeries() {
      if (!size(this.chartData)) return [];
      const checkpointsByType = groupBy(this.chartData, 'checkpointType');
      const selectedComparisons = intersection(keys(checkpointsByType), [
        reportingFilters.comparison,
        reportingFilters.comparison2,
      ]);

      return map(selectedComparisons, c => {
        const attributeData = this.getAllAttributesValues.map(attributeValue => {
          const totalInBaseline = this.sumAttributeTotal(
            checkpointsByType[reportingFilters.baseline],
            attributeValue
          );
          const totalInComparison = this.sumAttributeTotal(checkpointsByType[c], attributeValue);

          return {
            y: totalInComparison - totalInBaseline,
            baseline: totalInBaseline,
            comparison: totalInComparison,
            type: c,
          };
        });

        return {
          name: this.$tc(`reportingPage.menuHeaders.${c}`),
          data: attributeData,
        };
      });
    },

    chartOptions() {
      const $tkey = key => this.$t(`reportingPage.${key}`);
      const fn = number =>
        this.formatNumber({ number, format: CHARTS_LOOKUP[this.metricValue].numberFormat });

      return {
        chart: {
          type: 'column',
        },
        title: {
          text: null,
        },
        yAxis: {
          allowDecimals: false,
          title: {
            text: this.$tkey('chart.yTitle'),
          },
        },
        xAxis: {
          categories: [...this.getAllAttributesValues],
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          formatter() {
            return `${$tkey('baseline')}: ${fn(this.point.baseline)}<br/>${$tkey(
              this.point.type
            )}: ${fn(this.point.comparison)}<br/>${$tkey('delta')}: <b>${fn(this.point.y)}</b>`;
          },
        },
        series: this.getSeries,
        plotOptions: {
          series: {
            dataLabels: {
              // dont show total per attribute value
              enabled: false,
              formatter() {
                return fn(this.y);
              },
            },
          },
        },
      };
    },

    highchartKey() {
      const valuesKeys = this.getAllAttributesValues.reduce((acc, value) => `${acc}-${value}`, '');
      return `${this.metricValue}-${this.attributeValue}-${valuesKeys}`;
    },

    currentSection() {
      return reportingSections.stats;
    },
  },

  watch: {
    baselineIds(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchData();
    },

    comparisonIds(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchData();
    },

    comparison2Ids(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchData();
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    ...mapActions('reporting', ['fetchReportingData']),
    ...mapMutations('reporting', ['setSelection', 'setReportingFilters']),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      params.api.sizeColumnsToFit();
    },

    parseTableData(rawData) {
      return sortBy(rawData, ['checkpointType', 'spacebreakSize']).map(r => {
        // add canvas name when data isn't a checkpoint
        if (!r.name) r.name = this.getCanvasName(r);

        return r;
      });
    },

    handleFilterChange(filters) {
      this.setReportingFilters({ section: this.currentSection, filters });
    },

    applyFilters() {
      this.fetchData();
    },

    async onSelectionChanged(field, value) {
      let fetchData = false;
      // If previously we had an invalid selection then we should fetch data
      if (!this.hasValidChartSelection) {
        fetchData = true;
      }
      await this.setSelection({ field, value });

      this.navigateToNewUrl({ fetchData });
    },

    // include new query to url param
    navigateToNewUrl({ fetchData }) {
      const newQueryValues = {
        ...this.$route.query,
        metricValue: this.metricValue,
        attributeValue: this.attributeValue,
      };

      this.$router.push({
        path: this.$route.path, // keep on same path
        query: newQueryValues,
      });
      if (fetchData) this.fetchData();
    },

    getMarginPercent(params) {
      const data = params.node.aggData ? params.node.aggData : params.data;
      const { totalMargin, totalSales } = data;
      return totalSales ? totalMargin / totalSales : 0;
    },

    getAggValue(params) {
      if (params.colDef.field === 'totalProducts') return get(params, 'data.totalProducts', 0);
      if (params.colDef.field === 'totalStores') return get(params, 'data.totalStores', 0);

      return sum(get(params, 'values', []));
    },

    sumAttributeTotal(checkpoints, attributeValue) {
      return reduce(
        checkpoints,
        (acc, checkpoint) => {
          const checkpointMetricForValue = get(
            checkpoint.metricsData,
            `${lowerCase(this.getAttributeFromId.name)}.${lowerCase(attributeValue)}`
          );
          // although some attribute values may be present on a scenario, not every product is assigned it spacebreaks,
          // so we assume 0 for their totals
          return checkpointMetricForValue
            ? acc + checkpointMetricForValue[CHARTS_LOOKUP[this.metricValue].field]
            : acc;
        },
        0
      );
    },

    getAllExportableDataColumns() {
      return this.columnApi.getAllDisplayedColumns();
    },

    processCellCallback(params) {
      // Being called for each cell

      if (includes(['totalSales', 'totalMargin'], params.column.colId)) {
        return this.formatNumber({ number: params.value, format: 'currency' });
      }

      if (params.column.colId === 'totalVolume') {
        return this.formatNumber({ number: params.value, format: 'float' });
      }

      return agGridUtils.utils.processCellForExport(params);
    },

    exportCSV() {
      const exportParams = {
        fileName: this.getFileName({
          serviceName: 'stats-report',
          fileNameDateFormat: this.getDateFormats.csvFileName,
        }),
        suppressQuotes: this.getCsvExport.suppressQuotes,
        columnSeparator: this.getCsvExport.columnSeparator,
        columnKeys: this.getAllExportableDataColumns(),
        processCellCallback: this.processCellCallback,
      };

      this.gridApi.exportDataAsCsv(exportParams);
    },

    async fetchData() {
      const promises = [
        this.fetchReportingData({
          section: this.currentSection,
          reportArea: 'table',
          productFilters: this.filters[this.currentSection],
        }),
      ];
      if (!this.shortView && this.hasValidChartSelection) {
        this.chartLoading = true;
        promises.push(
          this.fetchReportingData({
            section: this.currentSection,
            reportArea: 'chart',
            productFilters: this.filters[this.currentSection],
          })
        );
      }
      const [tableData, chartData] = await Promise.all(promises);

      this.tableData = this.parseTableData(tableData);

      if (chartData) this.chartData = chartData;
      this.chartLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.chart-area {
  & .header {
    justify-content: space-between;
  }
}

::v-deep {
  .baseline-row {
    background-color: lighten($baseline-colour, 60%) !important;
  }

  .comparison-row {
    background-color: lighten($comparison-colour, 30%) !important;
  }

  .comparison2-row {
    background-color: lighten($comparison2-colour, 30%) !important;
  }

  .ag-theme-custom {
    .ag-cell-wrapper {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-left: 0 !important;
    }

    .ag-row-group-indent {
      &-1 {
        padding-left: 15px !important;
      }

      &-2 {
        padding-left: 25px !important;
      }

      &-3 {
        padding-left: 35px !important;
      }

      &-4 {
        padding-left: 45px !important;
      }
    }
  }
}

.no-data {
  font-size: 1.4rem;
}
</style>
