import axios from 'axios';
import to from 'await-to-js';
import { sortBy, each, get, isArray } from 'lodash';
import moment from 'moment';
import AssortmentGroupTypes from '@enums/assortment-group-types';

const store = {
  namespaced: true,
  state: {
    masterAssortmentGroups: [],
    categoryToAssortmentGroupKeysMap: {},
    loading: false,
  },

  getters: {},

  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setAssortmentGroupData(state, { assortmentGroups, options }) {
      // Add the text and sortKey fields
      assortmentGroups = assortmentGroups.map(ag => {
        const productCount = ag.distinctProductCount;
        const storeCount = ag.distinctStoreCount;
        let text;
        if (ag.type === AssortmentGroupTypes.furniture) {
          text = `${ag[options.furnitureCategoryLevelDisplay]}`;
          if (!options.shortAssortmentGroupTextEnabled)
            text += ` (${ag.furnitureCategory1Description})`;
          text += ` (${productCount}-${storeCount}-${ag.distinctFurnitureArchetypeCount})`;
        } else if (ag.type === AssortmentGroupTypes.nonFurniture) {
          text = `${ag.productCategoryDescription} (${ag.formatDescription})`;
          text = `${text} (${productCount}-${storeCount})`;
        }
        return {
          ...ag,
          text,
          sortKey: `${ag.furnitureCategory2Description} ${ag.furnitureCategory1Description}`,
        };
      });
      // Sort and set
      assortmentGroups = sortBy(assortmentGroups, 'sortKey');
      state.masterAssortmentGroups = assortmentGroups;

      // Set the category to assortment group keys lookup
      const categoryToAssortmentGroupKeysMap = {};
      each(assortmentGroups, assortmentGroup => {
        const category = assortmentGroup[options.filterType];

        each(isArray(category) ? category : [category], c => {
          if (!categoryToAssortmentGroupKeysMap[c]) {
            categoryToAssortmentGroupKeysMap[c] = [];
          }
          categoryToAssortmentGroupKeysMap[c].push(assortmentGroup.key);
        });
      });
      state.categoryToAssortmentGroupKeysMap = categoryToAssortmentGroupKeysMap;
    },
  },

  actions: {
    async fetchAssortmentGroups({ commit, rootState }, snapshotDate) {
      commit('setLoading', true);
      const params = {
        snapshotDate: moment.utc(snapshotDate).format('YYYY-MM-DD'),
        pick: [
          // Common fields
          'key',
          'type',
          'distinctStoreCount',
          'distinctProductCount',
          'distinctProductKeys',
          'staticAssortmentGroupKey',
          // Furniture-type fields
          'furnitureCategory1Description',
          'furnitureCategory2Description',
          'distinctFurnitureArchetypeCount',
          'associatedProductCategoryDescriptions',
          // Non-furniture-type fields
          'formatDescription',
          'productCategoryDescription',
        ],
      };
      const [err, response] = await to(axios.get(`/api/assortment-groups/`, { params }));
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      const payload = {
        assortmentGroups: response.data,
        options: {
          shortAssortmentGroupTextEnabled: get(
            rootState.context.clientConfig,
            'features.shortAssortmentGroupTextEnabled',
            false
          ),
          furnitureCategoryLevelDisplay: get(
            rootState.context.clientConfig,
            'assortmentGroups.furnitureCategoryLevelDisplay',
            'furnitureCategory2Description'
          ),
          filterType: get(
            rootState.context.clientConfig,
            'assortmentGroups.filterType',
            'associatedProductCategoryDescriptions'
          ),
        },
      };
      commit('setAssortmentGroupData', payload);
      commit('setLoading', false);
    },
  },
};

export default store;
