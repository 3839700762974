import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        VRow,
        [
          _c(
            VCol,
            {
              staticClass: "pa-0 step-tab-panel__content",
              attrs: { justify: "start" },
            },
            [
              _c(
                "extract-report-generator",
                {
                  attrs: { disabled: _vm.isGenerateExtractDisabled },
                  on: { "generate-extract-requested": _vm.generateReport },
                },
                [
                  _c(
                    VContainer,
                    {
                      staticClass:
                        "report-product-store-extract d-flex flex-column pa-0 ma-0",
                      attrs: { fluid: "" },
                    },
                    [
                      _c(
                        VRow,
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            VCol,
                            {
                              staticClass: "margin-right-23",
                              attrs: { colspan: "6" },
                            },
                            [
                              _c(
                                "report-input-item",
                                {
                                  attrs: {
                                    title: _vm.$t(
                                      "extract.reports.productStore.reportType.title"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(VSelect, {
                                        attrs: {
                                          items: _vm.extractions,
                                          "hide-details": "",
                                          dense: "",
                                          "append-icon": "expand_more",
                                        },
                                        model: {
                                          value: _vm.selectedExtract,
                                          callback: function ($$v) {
                                            _vm.selectedExtract = $$v
                                          },
                                          expression: "selectedExtract",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VCol,
                            { attrs: { colspan: "6" } },
                            [
                              _c(
                                "report-input-item",
                                {
                                  attrs: {
                                    title: _vm.$t(
                                      "extract.reports.productStore.extractName.name"
                                    ),
                                  },
                                },
                                [
                                  _c(VTextField, {
                                    staticClass: "rtls-text-field--grey",
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "extract.reports.productStore.extractName.placeholder"
                                      ),
                                      hint: _vm.uniqueNameHint,
                                      rules: _vm.nameRules,
                                    },
                                    model: {
                                      value: _vm.extractName,
                                      callback: function ($$v) {
                                        _vm.extractName = $$v
                                      },
                                      expression: "extractName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VRow,
                        { staticClass: "mt-6" },
                        [
                          _c("checkpoints-selection-table", {
                            attrs: {
                              "scenario-id": _vm.selectedScenario._id,
                              headers: _vm.headers,
                              canvases: _vm.items,
                              "checkpoints-by-canvas-id":
                                _vm.checkpointsByCanvasId,
                              title: _vm.$t(
                                "extract.reports.productStore.table.title"
                              ),
                            },
                            on: {
                              "update-selected-checkpoints":
                                _vm.setSelectedCheckpoints,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass: "pt-0 pr-0",
              attrs: { justify: "start", cols: "4" },
            },
            [
              _c("previous-extract-reports", {
                attrs: { reports: _vm.reports },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }