<template>
  <v-container class="pa-0 ma-0 store-allocation-container">
    <div class="ag-grid-box flex-grow-1 pa-2 ma-0">
      <div class="action-panel">
        <div class="action-panel-container">
          <data-upload
            :legends="csvUploadLegends"
            :csv-upload-handler="onCSVUpload"
            show-modal
            :disabled="isEditingDisabled"
            @process="process"
          />
          <filters
            class="ml-3"
            :filters="filters"
            :filter-options="filterOptions"
            :btn-text="filterButtonText"
            @change="handleFilterChange"
            @apply="filterRows"
          />
        </div>
      </div>
      <ag-grid-vue
        style="width: 100%; height: 90%"
        class="ag-theme-custom"
        :row-data="filteredRowData"
        :column-defs="columnDefs"
        :grid-options="gridOptions"
        @cell-value-changed="trackDiff"
        @grid-ready="onGridReady"
      />
      <v-overlay v-if="loading" absolute opacity="0.5" color="white">
        <v-progress-circular indeterminate color="primary" size="64" width="10" />
      </v-overlay>
    </div>

    <page-actions
      show-export
      is-custom-export
      :has-data-changes="hasDataChanges"
      :has-data-errors="hasDataErrors"
      :is-discard-enabled="!isEditingDisabled"
      :save-disabled="isEditingDisabled"
      @export="exportCSV()"
      @discard="discardChanges"
      @save="saveChanges"
    />

    <dependency-tree-feedback-modal
      :value="dependencyTreeModalOpen"
      :results="dependencyTreeFeedback"
      page="storeAllocation"
      @close="closeDependencyTreeModal"
      @commit="saveChanges(true)"
    />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import filterUtils from '@/js/utils/filter-utils';
import agGridUtils from '@/js/utils/ag-grid-utils';
import {
  get,
  isEmpty,
  cloneDeep,
  pick,
  map,
  size,
  uniqBy,
  forEach,
  every,
  some,
  fromPairs,
  values,
  merge,
  keyBy,
} from 'lodash';
import i18n from '@/js/vue-i18n';
import exportCSV from '@/js/mixins/export-csv';

// We need to do translations inside of ag-grid functions where this is not bound to Vue.
const storeAllocationLocalizationKey = 'extract.reports.storeExecutionPlanning.storeAllocation';
const t = (key, locKey, params) => i18n.t(`${locKey}.${key}`, params);

const typeSelections = {
  CLUSTERED: 'clustered',
  UNCLUSTERED: 'unclustered',
};

export default {
  localizationKey: 'lookup',
  components: {
    AgGridVue,
  },
  mixins: [exportCSV],
  data() {
    return {
      gridApi: null,
      columnApi: null,
      columnDefs: [
        {
          headerName: this.$tkey('storeFormat'),
          field: 'storeFormat',
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.$tkey('spacebreak'),
          field: 'spacebreakName',
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.$tkey('furnitureArchetype'),
          field: 'furnitureArchetypeName',
          rowGroup: true,
          hide: true,
        },
        {
          headerName: this.$tkey('storeKey'),
          field: 'storeKeyDisplay',
          cellRenderer(params) {
            return params.value || t('cellValues.all', storeAllocationLocalizationKey);
          },
        },
        {
          headerName: this.$tkey('storeName'),
          field: 'storeName',
          cellRenderer(params) {
            return params.value || t('cellValues.all', storeAllocationLocalizationKey);
          },
        },
        {
          headerName: '',
          groupId: 'clustered',
          children: [
            {
              ...agGridUtils.colDefs.action,
              headerName: t('tableHeaders.clustered', storeAllocationLocalizationKey),
              headerClass: 'indicator-cell-header',
              field: 'type',
              editable: false,
              width: 170,
              maxWidth: 170,
              cellClass: 'indicator-cell',
              cellClassRules: {
                'diff-background': this.hasDiff,
              },
              cellRenderer: params =>
                agGridUtils.utils.customCheckboxButtonRenderer(params, {
                  eventType: 'mousedown',
                  handler: this.setType,
                  additionalArgs: {
                    type: typeSelections.CLUSTERED,
                  },
                  isSelected: this.getTypeSelection(params.node, typeSelections.CLUSTERED),
                  isComponentEnabled: () => !this.isEditingDisabled,
                }),
            },
          ],
        },
        {
          headerName: '',
          groupId: 'unclustered',
          children: [
            {
              headerName: t('tableHeaders.unclustered', storeAllocationLocalizationKey),
              headerClass: 'indicator-cell-header',
              field: 'type',
              editable: false,
              width: 170,
              maxWidth: 170,
              cellClass: 'indicator-cell',
              cellClassRules: {
                'diff-background': this.hasDiff,
              },
              cellRenderer: params =>
                agGridUtils.utils.customCheckboxButtonRenderer(params, {
                  eventType: 'mousedown',
                  handler: this.setType,
                  additionalArgs: {
                    type: typeSelections.UNCLUSTERED,
                  },
                  isSelected: this.getTypeSelection(params.node, typeSelections.UNCLUSTERED),
                  isComponentEnabled: () => !this.isEditingDisabled,
                }),
            },
          ],
        },
        {
          headerName: '',
          groupId: 'hybrid',
          children: [
            {
              headerName: t('tableHeaders.hybrid', storeAllocationLocalizationKey),
              headerClass: 'indicator-cell-header',
              field: 'type',
              editable: false,
              width: 170,
              maxWidth: 170,
              cellClass: 'indicator-cell',
              cellRenderer: params =>
                agGridUtils.utils.customIconRenderer(params, {
                  icon: 'check',
                  isDisplayed: this.isHybridSelection(params),
                }),
            },
          ],
        },
      ],
      gridOptions: {
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          menuTabs: [],
        },
        onRowGroupOpened: params => {
          params.api.redrawRows({ rowNodes: [params.node] });
        },
        autoGroupColumnDef: {
          minWidth: 250,
          cellRenderer: 'agGroupCellRenderer',
          tooltipShowDelay: 0,
          tooltipValueGetter(params) {
            return params.node && params.node.key;
          },
        },
        rowSelection: 'multiple',
        onSelectionChanged: this.onSelectionChanged,
        groupHeaderHeight: 0,
        groupSelectsChildren: true,
        groupDisplayType: 'multipleColumns',
        suppressRowClickSelection: true,
        suppressAggFuncInHeader: true,
        suppressCellFocus: true,
        rowHeight: 30,
        headerHeight: 40,
      },
      rowData: [],
      filters: [],
      filteredRowData: [],
      currentStateDiff: {},
      expandedNodeKeys: [],
      isRenderInProgress: false,
      dependencyTreeModalOpen: false,
      dependencyTreeFeedback: {},
      csvUploadLegends: {
        buttonName: this.$t('actions.manualImport'),
        title: t('import', storeAllocationLocalizationKey),
      },
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('context', ['getCsvExport', 'getDateFormats']),

    loading() {
      return this.isRenderInProgress;
    },

    hasDataChanges() {
      return !isEmpty(this.currentStateDiff);
    },

    hasDataErrors() {
      // required for <page-actions> component
      return false;
    },

    // These are all the options users will have to use in the filters component
    filterOptions() {
      return [
        {
          type: 'storeFormat',
          path: 'storeFormat',
          allowSelectMultiple: true,
          name: this.$tkey('storeFormat'),
          values: this.rowData.map(row => ({ name: row.storeFormat, value: row.storeFormat })),
        },
        {
          type: 'spacebreak',
          path: 'spacebreakName',
          allowSelectMultiple: true,
          name: this.$tkey('spacebreak'),
          values: this.rowData.map(row => ({
            name: row.spacebreakName,
            value: row.spacebreakName,
          })),
        },
        {
          type: 'furnitureArchetype',
          path: 'furnitureArchetypeName',
          allowSelectMultiple: true,
          name: this.$tkey('furnitureArchetype'),
          values: this.rowData.map(row => ({
            name: row.furnitureArchetypeName,
            value: row.furnitureArchetypeName,
          })),
        },
        {
          type: 'storeKey',
          path: 'storeKeyDisplay',
          allowSelectMultiple: true,
          name: this.$tkey('storeKey'),
          values: this.rowData.map(row => ({
            name: row.storeKeyDisplay,
            value: row.storeKeyDisplay,
          })),
        },
        {
          type: 'storeName',
          path: 'storeName',
          allowSelectMultiple: true,
          name: this.$tkey('storeName'),
          values: this.rowData.map(row => ({
            name: row.storeName,
            value: row.storeName,
          })),
        },
        {
          type: 'clustered',
          path: 'type',
          allowSelectMultiple: true,
          name: t('filterOptions.clusteredUnclustered', storeAllocationLocalizationKey),
          values: [
            {
              name: t('filterOptions.clustered', storeAllocationLocalizationKey),
              value: typeSelections.CLUSTERED,
            },
            {
              name: t('filterOptions.unclustered', storeAllocationLocalizationKey),
              value: typeSelections.UNCLUSTERED,
            },
            {
              name: t('filterOptions.notSelected', storeAllocationLocalizationKey),
              value: null,
            },
          ],
        },
      ];
    },

    filterButtonText() {
      const originalRowsLength = size(this.rowData);
      const filteredRowsLength = size(this.filteredRowData);
      const filteredRatio = `${filteredRowsLength}/${originalRowsLength}`;
      const numFiltersString = this.$tc(
        `${storeAllocationLocalizationKey}.filters`,
        size(this.filters)
      );
      const filterName = t('filterName', storeAllocationLocalizationKey);

      return `${numFiltersString} - (${filteredRatio}) ${filterName}`;
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditStoreExecutionPlanning);
    },
  },

  async created() {
    this.setSelectedStep(this.$options.name);
    this.rowData = await this.fetchStoreAllocation();
    this.filterRows();

    // backup data
    this.resetChanges();
  },

  methods: {
    ...mapMutations('extracts', ['setSelectedStep']),
    ...mapActions('snackbar', ['showWarning']),
    ...mapActions('storeExecutions', ['fetchStoreAllocation', 'saveStoreAllocation', 'processCSV']),
    ...mapActions('dependencyTree', ['triggerDependencyTree']),
    ...mapActions('files', ['uploadCSV']),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    // set type to a specified value for given cell and all its child nodes
    setType(params, { type }) {
      if (this.isEditingDisabled) return;
      params.setValue(type);
      this.setTypeToChildren(params.node, type);
      this.gridApi.redrawRows(); // TODO think how to redraw modified rows only
    },

    // set given type for every child node downstream
    setTypeToChildren(childNode, value) {
      if (childNode.data) {
        childNode.data.type = value;
      } else {
        childNode.setData({ type: value });
      }

      forEach(childNode.childrenAfterGroup, child => this.setTypeToChildren(child, value));
    },

    // gets correct value for type indicator based on type values
    getTypeSelection(node, type) {
      if (!node.group) {
        return get(node, 'data.type') === type;
      }

      // if it's a group node, set to true if all leaf children have the same value selected
      return every(node.allLeafChildren, childNode => get(childNode, 'data.type') === type);
    },

    // checks whether hybrid indicator should be displayed for given node
    isHybridSelection(params) {
      // if node is a leaf, don't show the indicator
      if (!params.node.group) return false;

      // check that all leaf child node types are the same
      // if no selection was made for any leaf node in a group, this will also be true
      return size(uniqBy(params.node.allLeafChildren, 'data.type')) > 1;
    },

    trackDiff(params) {
      const { field } = params.colDef;

      if (!params.node.group) {
        const { storeKey, furnitureArchetypeId, spacebreakId } = params.data;

        this.updateDiff(`${storeKey}-${furnitureArchetypeId}-${spacebreakId}`, field, params.value);
      } else {
        // update diff for all leaf child nodes in a group
        forEach(params.node.allLeafChildren, node => {
          const { storeKey, furnitureArchetypeId, spacebreakId } = node.data;
          this.updateDiff(
            `${storeKey}-${furnitureArchetypeId}-${spacebreakId}`,
            field,
            params.value
          );
        });
      }
    },

    updateDiff(key, field, currentValue) {
      const path = `${key}.${field}`;
      const originalValue = get(this.$options.savedState, path);
      const existingValue = get(this.currentStateDiff, [key]);

      // If the current state does not match the original, track the state difference
      if (currentValue !== originalValue) {
        if (!this.currentStateDiff[key]) {
          this.$set(this.currentStateDiff, key, {});
        }
        this.$set(this.currentStateDiff[key], field, currentValue);
      } else if (existingValue) {
        this.$delete(this.currentStateDiff[key], field);

        // If there is nothing left in the object, remove it
        if (isEmpty(this.currentStateDiff[key])) {
          this.$delete(this.currentStateDiff, key);
        }
      }
    },

    getDiffStatus(key, field, currentValue) {
      const path = `${key}.${field}`;
      const originalValue = get(this.$options.savedState, path);

      return currentValue !== originalValue;
    },

    hasDiff(params) {
      const { field } = params.colDef;

      if (!params.node.group) {
        const { storeKey, furnitureArchetypeId, spacebreakId } = params.data;
        return this.getDiffStatus(
          `${storeKey}-${furnitureArchetypeId}-${spacebreakId}`,
          field,
          params.value
        );
      }

      // check diff for all leaf child nodes in a group (with their actual values)
      const isGroupSelectionDifferent = some(params.node.allLeafChildren, node => {
        const { storeKey, furnitureArchetypeId, spacebreakId } = node.data;
        return this.getDiffStatus(
          `${storeKey}-${furnitureArchetypeId}-${spacebreakId}`,
          field,
          node.data[field]
        );
      });

      if (isGroupSelectionDifferent) this.trackDiff(params);

      return isGroupSelectionDifferent;
    },

    async saveChanges(commit = false) {
      const dataToSave = map(this.rowData, item =>
        pick(item, ['storeKey', 'spacebreakId', 'furnitureArchetypeId', 'type'])
      );

      // Trigger dependency tree
      const results = await this.triggerDependencyTree({
        params: { change: 'storeAllocationModified', updates: {}, commit },
      });

      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return;
      }

      try {
        await this.saveStoreAllocation(dataToSave);

        this.resetChanges();
        this.gridApi.redrawRows(); // redraw to trigger hasDiff
      } catch (e) {
        this.showWarning();
      }
    },

    discardChanges() {
      this.rowData = cloneDeep(this.$options.rowData);
      this.filterRows();
      this.resetChanges();

      this.gridApi.forEachNode(node => {
        // if the node is a group and is open, save it to state
        if (node.group && node.expanded) {
          this.expandedNodeKeys.push(node.key);
        }
      });

      // we will reset initial state expanded rows after discard
      this.isRenderInProgress = true;
      this.$nextTick(() => this.setExpandedRows());
    },

    setExpandedRows() {
      if (!this.gridApi) return;

      this.gridApi.forEachNode(node => {
        if (this.expandedNodeKeys.includes(node.key)) {
          node.expanded = true;
        }
      });
      if (size(this.expandedNodeKeys)) {
        this.gridApi.onGroupExpandedOrCollapsed();
      }
      this.expandedNodeKeys = [];

      // setting expanded rows happens after row data gets refreshed
      this.isRenderInProgress = false;
    },

    resetSavedState() {
      this.$options.savedState = fromPairs(
        map(this.$options.rowData, row => [
          `${row.storeKey}-${row.furnitureArchetypeId}-${row.spacebreakId}`,
          row,
        ])
      );
    },

    resetChanges() {
      this.currentStateDiff = {};
      this.$options.rowData = cloneDeep(this.rowData);
      this.resetSavedState();
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
    },

    processCellCallback(params) {
      // Being called for each cell

      // Group nodes do not have values in cells, so set them manually for export
      const { colDef } = params.column;
      params.value = params.node.data[colDef.field || colDef.showRowGroup];

      // Map clustered type to boolean
      if (params.column.colId === 'type') return params.value === typeSelections.CLUSTERED;

      return agGridUtils.utils.processCellForExport(params);
    },

    getColumnKeysToExport() {
      // Do not export Unclustered and Hybrid columns
      const excludedCols = [
        t('tableHeaders.unclustered', storeAllocationLocalizationKey),
        t('tableHeaders.hybrid', storeAllocationLocalizationKey),
      ];

      return this.columnApi
        .getAllDisplayedColumns()
        .filter(c => !excludedCols.includes(c.colDef.headerName));
    },

    exportCSV() {
      const exportParams = {
        fileName: this.getFileName({
          serviceName: 'store-allocation',
          workpackageName: this.selectedWorkpackage.name,
          scenarioName: this.selectedScenario.name,
          fileNameDateFormat: this.getDateFormats.csvFileName,
        }),
        suppressQuotes: this.getCsvExport.suppressQuotes,
        columnSeparator: this.getCsvExport.columnSeparator,
        columnKeys: this.getColumnKeysToExport(),
        processCellCallback: this.processCellCallback,
        skipGroups: true, // Export leaf nodes only
      };

      this.gridApi.exportDataAsCsv(exportParams);
    },

    async onCSVUpload(formData) {
      this.setSelectedStep(this.$options.name);
      formData.append('scenarioId', this.selectedScenario._id);
      return this.uploadCSV({ formData, service: 'store-allocation' });
    },

    async process({ fileId, mappings, delimiter }) {
      const rows = await this.processCSV({
        fileId,
        mappings,
        delimiter,
        storeExecutionsService: 'store-allocation',
      });

      // do not perform any action if no rows are being imported
      if (!size(rows)) return;

      // merge existing table data with imported rows
      this.rowData = values(
        merge(
          keyBy(this.rowData, a => `${a.spacebreakId}${a.furnitureArchetypeId}${a.storeKey}`),
          keyBy(rows, b => `${b.spacebreakId}${b.furnitureArchetypeId}${b.storeKey}`)
        )
      );
      this.filterRows();
      this.gridApi.forEachNode(node => {
        // if the node is a group and is open, save it to state
        if (node.group && node.expanded) {
          this.expandedNodeKeys.push(node.key);
        }
      });

      // we will reset initial state expanded rows after discard
      this.isRenderInProgress = true;
    },

    handleFilterChange(filters) {
      this.filters = filters;
    },

    filterRows() {
      this.filteredRowData = filterUtils.applyFiltersToData({
        filters: this.filters,
        items: this.rowData,
      });

      this.gridApi.forEachNode(node => {
        // if the node is a group and is open, save it to state
        if (node.group && node.expanded) {
          this.expandedNodeKeys.push(node.key);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
$radio-check-colour: #2f477c;

.store-allocation-container {
  flex: 1;
  max-width: 100%;

  .action-panel {
    margin: 0;
  }
}

::v-deep {
  .round-selection {
    display: flex;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    background-color: $assortment-radio-button-colour;

    &[disabled] {
      border-color: $assortment-disabled-text-button-colour;
    }
  }

  .selected-type {
    background-color: $radio-check-colour;
  }

  .custom-icon {
    color: $assortment-action-icon-color;
  }

  .hidden {
    display: none;
  }

  .indicator-cell,
  .indicator-cell-header .ag-header-cell-label {
    display: flex;
    justify-content: center;
  }

  .indicator-cell {
    border-left: 2px solid $assortment-divider-colour !important;
  }

  .ag-cell-focus,
  .ag-cell-no-focus {
    border: none !important;
  }
}
</style>
