import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("workpackages-toolbar", {
        attrs: { "doc-path": "160-templates", entity: _vm.entities.template },
        on: { "on-search": _vm.onSearch, "on-sort": _vm.onSort },
      }),
      _vm._v(" "),
      _c(
        VCard,
        { staticClass: "col-box" },
        [
          _vm.loading
            ? _c(
                VAlert,
                {
                  attrs: {
                    border: "left",
                    "colored-border": "",
                    color: "primary accent-4",
                  },
                },
                [
                  _c(VProgressCircular, {
                    attrs: { indeterminate: "", color: "primary", size: "20" },
                  }),
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$t("templatesPage.loadingWorkpackageTemplates")
                      ) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            VRow,
            { attrs: { "no-gutters": "" } },
            [
              _c(
                VCol,
                {
                  staticClass: "workpackages-container",
                  attrs: { colspan: "12" },
                },
                [
                  _vm.sortedWorkpackagesList.length
                    ? _c("workpackages-list", {
                        attrs: {
                          workpackages: _vm.sortedWorkpackagesList,
                          "visible-workpackages": _vm.visibleTemplates,
                          entity: _vm.entities.template,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.loading && !_vm.visibleTemplates.length
                    ? _c(VAlert, { attrs: { type: "info", text: "" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("workpackages.noActiveWps")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        [
          _c(
            VCol,
            {
              staticClass: "horizontal-divider-container",
              attrs: { colspan: "12" },
            },
            [_c("div", { staticClass: "horizontal-divider" })]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        { attrs: { "no-gutters": "" } },
        [
          _c(
            VCol,
            { attrs: { colspan: "12" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "mb-3",
                  attrs: {
                    "data-id-e2e": "btnNewWorkpackageTemplate",
                    primary: "",
                    disabled: _vm.isNewButtonDisabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addWorkpackage({ isTemplate: true })
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("templatesPage.addNewButton")))]
              ),
              _vm._v(" "),
              _vm.bundlesEnabled
                ? _c(
                    VBtn,
                    {
                      staticClass: "mb-3",
                      attrs: {
                        "data-id-e2e": "btnNewWorkpackageTemplateWithoutBundle",
                        primary: "",
                        disabled: _vm.isNewButtonDisabled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addWorkpackage({
                            isTemplate: true,
                            withoutBundle: true,
                          })
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("templatesPage.addNewWithoutBundleButton")
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }