'use strict';

const lockTypes = {
  unlocked: 'unlocked',
  locked: 'locked',
};

module.exports = {
  lockTypes,
};
