import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VSnackbar,
    {
      staticClass: "main-snackbar",
      attrs: {
        color: _vm.color,
        timeout: _vm.clientConfig.popupTimeout,
        top: "",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(/* AOV3-823 TODO: move snackbar to bottom to match pricing? */) +
          "\n  "
      ),
      _c("div", { staticClass: "notification-wrapper w-100" }, [
        _vm._v("\n    " + _vm._s(_vm.message) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        VBtn,
        {
          attrs: { text: "" },
          on: {
            click: function ($event) {
              _vm.show = false
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("actions.close")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }