<template>
  <v-row class="header ma-0 flex-grow-0">
    <v-col :cols="columns" class="d-flex align-center">
      <span class="info-note">{{ headerText }}</span>

      <docs-link :link="tooltipLink" />
    </v-col>
    <div class="d-flex align-center">
      <div v-if="showSwitching" data-id-e2e="btnRunSwitching">
        <v-btn primary :disabled="disabled || isJobRunning('switching')" @click="onSwitchingClick">
          {{ $t('scenarioMeasuringPage.buttons.runSwitching') }}
        </v-btn>
      </div>
      <div v-if="showPrepareSwaps" data-id-e2e="btnRunPrepareSwaps">
        <v-btn
          primary
          :disabled="disabled || isJobRunning('prepareSwaps')"
          @click="$emit('trigger-job', prepareSwapsJob)"
        >
          {{ $t('scenarioMeasuringPage.buttons.runPrepareSwaps') }}
        </v-btn>
      </div>
    </div>
    <v-col />

    <!-- confirmation dialog -->
    <main-dialog ref="reRunConfirmation">
      <template v-slot:header>
        <v-card-text class="display-1 pa-0 text-center">
          <strong>{{ $tkey('switchingWrapper.reRunConfirmation.warning') }}</strong>
          <p>{{ $tkey('switchingWrapper.reRunConfirmation.question') }}</p>
        </v-card-text>
      </template>
      <template v-slot:actions="{ cancel: close }">
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn action @click="[$emit('trigger-job', switchingJob), close()]">
              {{ $tkey('switchingWrapper.reRunConfirmation.confirm') }}
            </v-btn>
            <v-btn class="ml-2" @click="close()">
              {{ $tkey('switchingWrapper.reRunConfirmation.cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </main-dialog>
  </v-row>
</template>

<script>
import { jobTypes, jobStatuses } from '@enums/jobapi';
import { mapGetters } from 'vuex';

export default {
  name: 'ScenarioMeasuringHeader',
  props: {
    headerText: {
      required: true,
      type: String,
    },
    tooltipLink: {
      required: false,
      type: String,
      default: '',
    },
    showJob: {
      required: false,
      type: String,
      default: null,
    },
    columns: {
      required: false,
      type: Number,
      default: 2,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      switchingJob: jobTypes.switching,
      prepareSwapsJob: jobTypes.prepareSwaps,
    };
  },

  computed: {
    ...mapGetters('scenarios', ['jobStatuses', 'isJobRunning']),

    showSwitching() {
      return this.showJob === this.switchingJob;
    },

    showPrepareSwaps() {
      return this.showJob === this.prepareSwapsJob;
    },

    isSwitchingComplete() {
      return this.jobStatuses.switching.status === jobStatuses.finished;
    },
  },

  methods: {
    onSwitchingClick() {
      if (this.isSwitchingComplete) {
        this.$refs.reRunConfirmation.open();
      } else {
        this.$emit('trigger-job', this.switchingJob);
      }
    },
  },
};
</script>
