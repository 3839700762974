import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: {
        "content-class": "rtls-dialog rtls-dialog--legend",
        "max-width": "1120",
        "max-height": "740",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                VBtn,
                _vm._g(
                  {
                    staticClass: "close-button",
                    attrs: { color: "primary", icon: "" },
                  },
                  on
                ),
                [_c(VIcon, { attrs: { size: "20" } }, [_vm._v("$info")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isPopupOpen,
        callback: function ($$v) {
          _vm.isPopupOpen = $$v
        },
        expression: "isPopupOpen",
      },
    },
    [
      _vm._v(" "),
      _c(
        "dialog-card",
        { attrs: { title: _vm.$tkey("title") }, on: { close: _vm.closePopup } },
        [
          _c(VCard, [
            _c(
              "div",
              { staticClass: "tooltip-section d-flex flex-wrap pt-5 pb-0" },
              [
                _c("div", { staticClass: "tile-item" }, [
                  _c("img", {
                    staticClass: "tile-image",
                    attrs: {
                      src: require("../../../../src/img/legend/optimal-tile-product.png"),
                      width: "80px",
                      height: "80px",
                      alt: "optimal-tile-product",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "tile-text",
                    domProps: { innerHTML: _vm._s(_vm.$tkey("optimal")) },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "tile-item" }, [
                  _c("img", {
                    staticClass: "tile-image",
                    attrs: {
                      src: require("../../../../src/img/legend/promoted-tile-product.png"),
                      width: "80px",
                      height: "80px",
                      alt: "promoted-tile-product",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tile-text" }, [
                    _vm._v(_vm._s(_vm.$tkey("promoted"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "tile-item" }, [
                  _c("img", {
                    staticClass: "tile-image",
                    attrs: {
                      src: require("../../../../src/img/legend/demoted-tile-product.png"),
                      width: "80px",
                      height: "80px",
                      alt: "demoted-tile-product",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tile-text" }, [
                    _vm._v(_vm._s(_vm.$tkey("demoted"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "tile-item" }, [
                  _c("img", {
                    staticClass: "tile-image",
                    attrs: {
                      src: require("../../../../src/img/legend/increased-distribution-tile-product.png"),
                      width: "80px",
                      height: "80px",
                      alt: "increased-distribution-tile-product",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tile-text" }, [
                    _vm._v(_vm._s(_vm.$tkey("increasedDistribution"))),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "tile-item" }, [
                  _c("img", {
                    staticClass: "tile-image",
                    attrs: {
                      src: require("../../../../src/img/legend/decreased-distribution-tile-product.png"),
                      width: "80px",
                      height: "80px",
                      alt: "decreased-distribution-tile-product",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "tile-text" }, [
                    _vm._v(_vm._s(_vm.$tkey("decreasedDistribution"))),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "tooltip-section pt-5 pb-5" }, [
              _c("h1", { staticClass: "mb-5 section-header" }, [
                _vm._v(_vm._s(_vm.$tkey("productTileTagsAndColours"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("assortment-tag", {
                      attrs: {
                        "full-border": "",
                        "tag-text": "new",
                        "position-relative": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tile-text mb-2 pl-3" }, [
                      _vm._v(_vm._s(_vm.$tkey("newProduct"))),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("assortment-tag", {
                      attrs: {
                        "full-border": "",
                        "tag-text": "modelled",
                        "position-relative": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tile-text mb-2 pl-3" }, [
                      _vm._v(_vm._s(_vm.$tkey("modelledProduct"))),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("assortment-tag", {
                      attrs: {
                        "full-border": "",
                        "tag-text": "modelledInCluster",
                        "position-relative": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tile-text mb-2 pl-3" }, [
                      _vm._v(_vm._s(_vm.$tkey("modelledInClusterProduct"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "tooltip-section pt-5 pb-5" }, [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c("h1", { staticClass: "mb-5 section-header" }, [
                  _vm._v(_vm._s(_vm.$tkey("tileLockStates"))),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex mb-5" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center mr-4" },
                    [
                      _c(VIcon, { attrs: { size: "20" } }, [
                        _vm._v("mdi-lock-open-variant-outline"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "tile-text" }, [
                        _vm._v(_vm._s(_vm.$tkey("unlocked"))),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center mr-4" },
                    [
                      _c(VIcon, { attrs: { size: "20" } }, [
                        _vm._v("mdi-lock-outline"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "tile-text" }, [
                        _vm._v(_vm._s(_vm.$tkey("locked"))),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex flex-column" }, [
                  _c("div", { staticClass: "d-flex flex-column mb-2" }, [
                    _c("div", { staticClass: "tile-text bold mb-1" }, [
                      _vm._v(_vm._s(_vm.$tkey("unlocked"))),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tile-text" }, [
                      _vm._v(_vm._s(_vm.$tkey("unlockedDescription"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-column mb-2" }, [
                    _c("div", { staticClass: "tile-text bold mb-1" }, [
                      _vm._v(_vm._s(_vm.$tkey("locked"))),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "tile-text" }, [
                      _vm._v(_vm._s(_vm.$tkey("lockedDescription"))),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }