import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VMenu,
    {
      ref: "optionsMenu",
      attrs: {
        "data-id-e2e": "product-attribute-options",
        "close-on-content-click": false,
        "offset-y": "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ attrs, on }) {
            return [
              _c(
                VBtn,
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mr-2 outlined-btn reset-kpi-button",
                      attrs: {
                        disabled: _vm.disabled,
                        depressed: "",
                        secondary: "",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("general.options")) + "\n    "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showOptions,
        callback: function ($$v) {
          _vm.showOptions = $$v
        },
        expression: "showOptions",
      },
    },
    [
      _vm._v(" "),
      _vm.hasTransferPriceEnabled
        ? _c("transfer-price-settings", {
            on: {
              "sync-financials": _vm.emitSyncFinancials,
              "update-maintain": _vm.emitBulkUpdateMaintain,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }