<template>
  <v-container>
    <compound-extract-report-generator @generate-extract-requested="generateReport">
      <v-container class="report-jda d-flex flex-column pa-0 ma-0" fluid>
        <v-row no-gutters>
          <v-col colspan="6">
            <report-input-item class="mb-4" :title="$tkey('extractName')">
              <rtls-text-field
                v-model="extractName"
                grey
                :placeholder="$tkey('chooseAnExtractName')"
              />
            </report-input-item>
          </v-col>
          <v-col colspan="6" />
        </v-row>
      </v-container>
    </compound-extract-report-generator>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { extracts } from '@enums/client-events';
import { AOC } from '@enums/extract-types';

export default {
  localizationKey: 'extract.reports.series',
  data() {
    return {
      isProcessing: false,
      extractName: null,
    };
  },

  computed: {
    ...mapGetters('context', {
      dateFormats: 'getDateFormats',
      getClientConfig: 'getClientConfig',
    }),
  },

  created() {
    this.setSelectedStep(this.$options.name);
  },

  methods: {
    ...mapActions('compoundExtract', ['generateCompoundExtract']),
    ...mapMutations('compoundExtract', ['setSelectedStep']),

    async generateReport() {
      await this.generateCompoundExtract({
        filenameOnDownload:
          this.extractName ||
          this.$t('extract.reports.aocExtractFileName', [
            moment().format(this.dateFormats.longWithTimeAndMilliseconds),
          ]),
        extractType: AOC,
        extractDatetime: moment.utc().toDate(),
        fileId: null,
      });
      this.globalEmit(extracts.compoundExtractGenerated);
    },
  },
};
</script>

<style lang="scss" scoped>
.extract-link {
  color: inherit;
  text-decoration: none;
}
</style>
