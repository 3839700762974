import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        VContainer,
        { staticClass: "cann-groups-container d-flex flex-column pa-0 ma-0" },
        [
          _c(
            VRow,
            { staticClass: "header ma-0 flex-grow-0" },
            [
              _c(
                VCol,
                { staticClass: "flex-grow-0 header__title" },
                [
                  _c("span", { staticClass: "info-note" }, [
                    _vm._v(
                      _vm._s(_vm.$t("scenarioCannGroups.cannGroupsSetup"))
                    ),
                  ]),
                  _vm._v(" "),
                  _c("docs-link", {
                    attrs: { link: "toolguide/060-canngroups.html" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showTreeStructureTrimmedWarning
                ? _c(
                    VCol,
                    {
                      staticClass:
                        "flex-grow-0 pa-0 ml-2 mr-3 align-self-center",
                    },
                    [
                      _c(
                        VAlert,
                        {
                          staticClass: "alert--small mb-0",
                          attrs: { type: "info", text: "" },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "scenarioCannGroups.treeStructureTrimmedWarning"
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "container-block flex-grow-1" },
            [
              _c(
                "div",
                { staticClass: "tree ma-0" },
                [
                  _c("div", { staticClass: "title-container" }, [
                    _c("div", { staticClass: "d-flex align-center" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "attribute-value attribute-value--all-products d-flex align-center float-left",
                        },
                        [
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("scenarioCannGroups.allProducts"))
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "treeview-container" },
                    [
                      _vm.treeData
                        ? _c(VTreeview, {
                            staticClass: "rtls-treeview",
                            attrs: {
                              dense: "",
                              "item-key": "key",
                              items: _vm.treeDataArray,
                              open: _vm.openedNodes,
                              "open-all": _vm.expandAll,
                            },
                            on: { "update:open": _vm.setOpenNodes },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function ({ item: node }) {
                                    return [
                                      _c("div", { staticClass: "node-line" }),
                                      _vm._v(" "),
                                      node.attributeValues.length > 1
                                        ? _c("div", {
                                            staticClass: "node-group-line",
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "attributes-box d-flex flex-column",
                                        },
                                        [
                                          _c(
                                            "draggable",
                                            {
                                              attrs: {
                                                options: {
                                                  disabled:
                                                    _vm.isEditingDisabled,
                                                },
                                                list: _vm.getList(node),
                                                "ghost-class": "ghost",
                                                group: node.attributeId,
                                                handle: ".drag-handle",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.mergeGroup(
                                                    $event,
                                                    node
                                                  )
                                                },
                                              },
                                            },
                                            _vm._l(
                                              node.attributeValues,
                                              function (val) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: val,
                                                    staticClass:
                                                      "attribute-value d-flex align-center justify-space-between mb-1",
                                                    class: {
                                                      hidden:
                                                        node.attributeValues
                                                          .length < 1,
                                                      draggable:
                                                        !_vm.isEditingDisabled,
                                                      "drag-disabled":
                                                        _vm.isEditingDisabled,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      [
                                                        _c(
                                                          VTooltip,
                                                          {
                                                            attrs: { top: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                val ||
                                                                                  _vm.$t(
                                                                                    "scenarioCannGroups.blankAttributeValue"
                                                                                  )
                                                                              ) +
                                                                              "\n                          "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  val ||
                                                                    _vm.$t(
                                                                      "scenarioCannGroups.blankAttributeValue"
                                                                    )
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      { staticClass: "mr-1" },
                                                      [
                                                        node.attributeValues
                                                          .length > 1
                                                          ? _c(
                                                              VIcon,
                                                              {
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.isEditingDisabled,
                                                                  size: "14",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.splitGroup(
                                                                        node,
                                                                        val
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        mdi-call-split\n                      "
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _c(
                                                          VIcon,
                                                          {
                                                            staticClass:
                                                              "drag-handle",
                                                            attrs: {
                                                              size: "10",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "$cross-move"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        VTooltip,
                                        {
                                          attrs: {
                                            disabled:
                                              !_vm.displayCannGroupHighlighting(
                                                node.products.length,
                                                node.children
                                              ),
                                            "content-class": "rtls-tooltip",
                                            "max-width": "160px",
                                            bottom: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "attribute-products align-self-start",
                                                          class: {
                                                            "attribute-products-highlighted":
                                                              _vm.displayCannGroupHighlighting(
                                                                node.products
                                                                  .length,
                                                                node.children
                                                              ),
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "b",
                                                          {
                                                            staticClass:
                                                              "mr-1 ml-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                node.products
                                                                  .length
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.formatSalesRatio(
                                                                node,
                                                                _vm.treeData
                                                              )
                                                            ) +
                                                            "%\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "scenarioCannGroups.productSizeWarning",
                                                    [
                                                      _vm
                                                        .getCannGroupsRecommendedSizes
                                                        .min,
                                                      _vm
                                                        .getCannGroupsRecommendedSizes
                                                        .max,
                                                    ]
                                                  )
                                                ) +
                                                "\n                "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        VSelect,
                                        {
                                          staticClass:
                                            "treeview-select select mb-1",
                                          class: _vm.getValue(node.children)
                                            ? "attribute-is-split-select"
                                            : "attribute-not-split-select",
                                          attrs: {
                                            disabled: _vm.isEditingDisabled,
                                            "item-text": "name",
                                            "item-value": "id",
                                            "hide-details": "",
                                            "single-line": "",
                                            items: _vm.getAttributesToSelect(
                                              node.key
                                            ),
                                            value: _vm.getValue(node.children),
                                            label: _vm.$t(
                                              "scenarioCannGroups.selectAttribute"
                                            ),
                                            "menu-props": {
                                              bottom: true,
                                              offsetY: true,
                                            },
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.modifyTree(
                                                $event,
                                                node
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      VTooltip,
                                                      {
                                                        attrs: { top: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function ({
                                                                on,
                                                              }) {
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    _vm._g(
                                                                      {},
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "\n                        " +
                                                                          _vm._s(
                                                                            item.name
                                                                          ) +
                                                                          "\n                      "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(item.name) +
                                                            "\n                  "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "template",
                                            { slot: "append" },
                                            [
                                              _c(
                                                VIcon,
                                                { attrs: { size: "28" } },
                                                [_vm._v("expand_more")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                      _vm._v(" "),
                                      _vm.hasNodes(node.children)
                                        ? _c(
                                            VBtn,
                                            {
                                              staticClass:
                                                "align-self-start mb-1",
                                              attrs: {
                                                disabled: _vm.isEditingDisabled,
                                                icon: "",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteNode(node)
                                                },
                                              },
                                            },
                                            [_c(VIcon, [_vm._v("$trash")])],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1213046822
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VOverlay,
                    {
                      attrs: {
                        value: _vm.isTreeLoading,
                        absolute: true,
                        opacity: 0.1,
                      },
                    },
                    [
                      _c(VProgressCircular, {
                        attrs: { indeterminate: "", size: "32" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showSidepanel
                ? _c(
                    "sidepanel",
                    {
                      attrs: {
                        title: _vm.$t("cannGroupsSidepanel.title"),
                        "toggle-btn-text": _vm.$t(
                          "cannGroupsSidepanel.toggleBtn"
                        ),
                      },
                    },
                    [
                      _c(
                        VForm,
                        {
                          ref: "leafNodeForm",
                          attrs: { autocomplete: "off" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c(
                            "draggable",
                            {
                              staticClass: "list-group",
                              attrs: {
                                disabled: _vm.isEditingDisabled,
                                "ghost-class": "ghost",
                                handle: ".draggable__icon",
                                list: _vm.orderedLeafNodes,
                              },
                              on: { change: _vm.setCustomLeafNodesOrder },
                            },
                            _vm._l(_vm.orderedLeafNodes, function (leafNode) {
                              return _c(
                                "div",
                                {
                                  key: leafNode.key,
                                  staticClass: "list-group__item",
                                  class: {
                                    "drag-disabled": _vm.isEditingDisabled,
                                  },
                                },
                                [
                                  _c(
                                    VIcon,
                                    {
                                      class: {
                                        draggable__icon: !_vm.isEditingDisabled,
                                      },
                                      attrs: { size: "12" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                $cross-move\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: {
                                        left: "",
                                        disabled:
                                          _vm.isEditing || !leafNode.pathName,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c(
                                                  VTextField,
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "list-group__input rtls-text-field",
                                                      attrs: {
                                                        "single-line": "",
                                                        rules: _vm.rules,
                                                        disabled:
                                                          !leafNode.pathName ||
                                                          _vm.isEditingDisabled,
                                                      },
                                                      on: {
                                                        input:
                                                          _vm.revalidateForm,
                                                        focus:
                                                          _vm.highlightInputText,
                                                        blur: _vm.toggleEditing,
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          )
                                                            return null
                                                          return $event.target.blur()
                                                        },
                                                      },
                                                      model: {
                                                        value: leafNode.name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            leafNode,
                                                            "name",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "leafNode.name",
                                                      },
                                                    },
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(leafNode.pathName)),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("cann-group-note", {
                                    attrs: { "leaf-node": leafNode },
                                  }),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page-actions-container d-flex flex-end flex-row",
              staticStyle: { "justify-content": "flex-end" },
              attrs: { "data-id-e2e": "btnsCannGroups" },
            },
            [
              _c("page-actions", {
                attrs: {
                  "has-data-changes":
                    _vm.hasDataChanges || _vm.hasSingleCannGroup,
                  "has-data-errors": _vm.hasDataErrors,
                  "save-disabled": _vm.isEditingDisabled,
                  "is-discard-enabled": !_vm.isEditingDisabled,
                },
                on: { discard: _vm.discard, save: _vm.initCanngroupSave },
              }),
              _vm._v(" "),
              _c("error-triangle", {
                staticStyle: { "padding-right": "2rem" },
                attrs: {
                  errors: {
                    [_vm.$t(
                      "scenarioCannGroups.errorMessages.cannGroupMinSize"
                    )]: _vm.cannGroupMinimumSizeNotMet,
                  },
                  arrow: false,
                  "tooltip-positioning": { top: true },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("dependency-tree-feedback-modal", {
            attrs: {
              value: _vm.dependencyTreeModalOpen,
              results: _vm.dependencyTreeFeedback,
              page: "cannGroups",
            },
            on: {
              close: _vm.closeDependencyTreeModal,
              commit: function ($event) {
                return _vm.saveCannGroups(true)
              },
            },
          }),
          _vm._v(" "),
          _c("unsaved-data-modal", {
            ref: "unsavedDataModal",
            attrs: { value: _vm.isUnsavedDataModalOpen },
            on: {
              cancel: _vm.closeUnsavedDataModal,
              confirm: _vm.closeUnsavedDataModal,
            },
          }),
          _vm._v(" "),
          _c("main-dialog", {
            ref: "confirm",
            attrs: {
              title: _vm.$t("scenarioCannGroups.dialogTitle"),
              message: _vm.$t("scenarioCannGroups.dialogMessage"),
            },
            scopedSlots: _vm._u([
              {
                key: "actions",
                fn: function ({ cancel: close }) {
                  return [
                    _c(
                      VRow,
                      [
                        _c(
                          VCol,
                          { staticClass: "d-flex justify-end" },
                          [
                            _c(
                              VBtn,
                              {
                                attrs: {
                                  disabled: _vm.isEditingDisabled,
                                  action: "",
                                },
                                on: {
                                  click: function ($event) {
                                    ;[_vm.saveCannGroups(), close()]
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("actions.ok")) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              VBtn,
                              {
                                staticClass: "ml-2",
                                attrs: { disabled: _vm.isEditingDisabled },
                                on: { click: close },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("actions.cancel")) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }