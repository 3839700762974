<template>
  <v-menu ref="optionsMenu" :close-on-content-click="closeOnClick" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" :disabled="disabled" icon text color="primary" v-on="on">
        <v-icon class="icon mx-n6">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>

    <slot name="default" />
  </v-menu>
</template>

<script>
export default {
  props: {
    closeOnClick: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
