<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 500,
    }"
    class="d-flex flex-drag-box"
  >
    <!-- 30ms delay to ensure draggable content is always clickable -->
    <draggable
      class="drag-container d-flex flex-wrap align-content-start"
      ghost-class="ghost"
      draggable=".draggable"
      delay="30"
      :disabled="isDragDisabled"
      :list="filteredProducts"
      :group="groupName"
      :sort="false"
      :scroll-sensitivity="150"
      :invert-swap="true"
      :force-fallback="true"
      @change="onChange"
      @start="onDragStart"
      @end="onDragStop"
    >
      <template v-if="showProducts">
        <!-- Full product tile when visible -->
        <div
          v-for="(product, index) in filteredProducts"
          :id="`tile-product-${product.productKey}`"
          :key="`${product._id}-${product.isHighlighted}`"
          :index="index"
          :class="{
            draggable:
              product.lockType === lockTypes.unlocked && !isDragDisabled && inReset(product),
          }"
          class="default-box"
        >
          <product-tile
            :index="index"
            :spacebreak-id="spacebreakId"
            :product="product"
            :selected-tile-size="selectedTileSize"
            :tile-view-ref="tileViewRef"
            :open-popup-on-load-for-product-id="productIdForPopup"
          />
        </div>
      </template>
      <div v-else class="d-flex flex-wrap skeleton-box">
        <!-- Placeholder for when the product tile is off screen -->
        <div
          v-for="product in filteredProducts"
          :id="`tile-product-${product.productKey}`"
          :key="product._id"
          class="d-flex flex-column default-box skeleton"
          :style="{
            height: `${tileSize[selectedTileSize].tile.height}px`,
            width: `${tileSize[selectedTileSize].tile.width}px`,
          }"
          :class="{
            'medium-margin': selectedTileSize === 'medium',
          }"
        >
          <img
            :id="`product-tile-popup-${product.productKey}`"
            src="../../../img/spinner.gif"
            @click="delayPopup(product._id)"
          />
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
// Notes:
// - This component uses a combination of vue draggable and vue observe visible
// These libraries will work together, but some care is needed in ensuring that
// things are removed and (more importantly) *not* removed appropriately.
// In this case, we ensure that while a drag is happening, the original tiles
// are not disposed of when they leave the screen, otherwise they'll be removed and
// the app will lose track of what was actaully being dragged
// - The placeholder is used to ensure the correct spacing remains on rows
// and columns as the user scrolls around
// - The search auto opens the popup, but needs to cope with that popup not
// yet being rendered on the DOM. It passes in the currently searched product to
// check against, when new things are rendered. Note that the search will
// find the placeholder (if the result is offscreen), so that can be used to
// position the users screen while the DOM loads.
import { get, includes } from 'lodash';
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { undraggableSpacebreaks } from '@enums/spacebreak-types';
import { lockTypes } from '@enums/assortment-product-lock-types';
import productUtils from '../../utils/product-utils';
import destroy from '../../utils/destroy';

export default {
  props: {
    filteredProducts: {
      type: Array,
      required: true,
    },

    selectedTileSize: {
      type: String,
      required: true,
    },

    groupName: {
      type: String,
      required: true,
    },

    spacebreakId: {
      type: String,
      default: null,
    },

    tileViewRef: {
      type: Object,
      required: true,
    },

    containerId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      lockTypes,
      get,
      isVisible: false,
      productIdForPopup: null,
      beingDragged: false,
    };
  },

  computed: {
    ...mapState('assortmentCanvas', [
      'referenceCheckpointProducts',
      'unifiedColumns',
      'tileSize',
      'spacebreaksOrderedObject',
    ]),
    ...mapGetters('assortmentCanvas', ['isSpacebreakLocked', 'getIndexedCanvasProducts']),

    isDragDisabled() {
      return (
        this.isSpacebreakLocked(this.spacebreakId) ||
        includes(undraggableSpacebreaks, this.containerId) ||
        this.isEditingDisabled
      );
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAssortmentCanvas);
    },

    showProducts() {
      return this.isVisible || this.beingDragged;
    },
  },

  beforeDestroy() {
    destroy.destroyReactiveVueProps(this);
  },

  methods: {
    ...mapMutations('assortmentCanvas', ['updateAssortmentCanvasProductById']),
    ...mapActions('assortmentCanvas', ['updateCanvasProduct']),
    ...mapActions('snackbar', ['showError']),
    ...mapActions('scenarioProducts', ['getAssociatedPallets']),

    // If the user searches, but only the placeholder spinner is available,
    // delay a 'click' on the actual box until the visibility has changed
    delayPopup(productId) {
      this.productIdForPopup = productId;
    },

    onDragStart() {
      this.beingDragged = true;
    },

    onDragStop() {
      this.beingDragged = false;
    },

    visibilityChanged(isVisible) {
      this.isVisible = isVisible;

      // If has gone from visible to hidden, reset the popup opener, to make sure it won't open again
      if (!isVisible) {
        this.productIdForPopup = null;
      }
    },

    onChange(changedData) {
      if (changedData.added) {
        const element = changedData.added.element;

        // Update data
        productUtils.moveProductToNewSpacebreak(
          element,
          this.spacebreakId,
          {
            updateAssortmentCanvasProductById: this.updateAssortmentCanvasProductById,
            updateCanvasProduct: this.updateCanvasProduct,
            showError: this.showError,
            spacebreaksOrderedObject: this.spacebreaksOrderedObject,
            getAssociatedPallets: this.getAssociatedPallets,
            getIndexedCanvasProducts: this.getIndexedCanvasProducts,
            $t: this.$t,
          },
          this.$t('assortmentCanvas.invalidPallets')
        );

        // Flash product border to make clear what was moved
        setTimeout(() => {
          const productTileId = `tile-product-${element.productKey}`;
          // Setting the first child, `tile-product-{pkey}` is a wrapper over the main tile element.
          // This is important to draw the blue border around the product image after search correctly.
          const productElement = document.getElementById(productTileId).firstChild;

          productUtils.addActiveSearchBorderToProductElement(productElement);
        }, 200);
      }
    },

    inReset(product) {
      return get(product, 'inReset', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.flex-drag-box {
  padding: 5px;
  width: 100%;
  height: 100%;
}

.skeleton-box {
  align-content: baseline;

  .skeleton {
    img {
      width: 40px;
      height: 40px;
      margin: auto;
    }
    position: relative;
  }
}

.medium-margin {
  margin: 5px;
}

::v-deep {
  .sortable-fallback {
    display: none !important;
  }
}
</style>
