<template>
  <section class="users d-flex flex-column align-start" :class="position">
    <h4 v-if="!hideTitle" class="title">{{ $t('workpackagePage.usersPermissionsLabel') }}</h4>
    <div class="d-flex disabled">
      <ul class="users-list d-flex justify-center">
        <li v-for="user in users" :key="user.name" class="users-element">
          <user-permission :user="user" />
        </li>
      </ul>
      <v-icon size="23" disabled>$add-people</v-icon>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    hideTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    justifyCenter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      users: [
        { name: 'RM', permissions: { access: true } },
        { name: 'MJ', permissions: { access: false } },
        { name: 'AT', permissions: { access: false } },
      ],
    };
  },
  computed: {
    position() {
      return this.justifyCenter ? 'justify-center' : 'justify-start';
    },
  },
};
</script>

<style lang="scss" scoped>
.users {
  .title {
    font-weight: normal;
  }
  .users-list {
    list-style: none;
    padding: 0;
    & > .users-element {
      margin-right: 4px;
    }
  }

  .title {
    margin-bottom: 3px;
  }
}
</style>
