import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { attrs: { fluid: "" } },
    [
      _c(
        VRow,
        [
          _c(
            VTabs,
            {
              model: {
                value: _vm.selectedTab,
                callback: function ($$v) {
                  _vm.selectedTab = $$v
                },
                expression: "selectedTab",
              },
            },
            [
              _vm._l(_vm.tabs, function (item) {
                return _c(VTab, { key: item._id }, [
                  _c("span", { staticClass: "tab-title" }, [
                    _vm._v(" " + _vm._s(item.name)),
                  ]),
                ])
              }),
              _vm._v(" "),
              _c(
                VTabsItems,
                {
                  model: {
                    value: _vm.selectedTab,
                    callback: function ($$v) {
                      _vm.selectedTab = $$v
                    },
                    expression: "selectedTab",
                  },
                },
                _vm._l(_vm.tabs, function (item) {
                  return _c(
                    VTabItem,
                    { key: item._id },
                    [
                      item._id === "defaultClusters" &&
                      _vm.hasDefaultClustersEnabled
                        ? _c("reference-cluster-scheme")
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }