<template>
  <ul class="workpackage-list">
    <li
      v-for="workpackage in list"
      v-show="show(workpackage)"
      :key="workpackage._id"
      class="workpackage-list__element"
    >
      <workpackage-wrapper
        :id="`wp-panel-${workpackage._id}`"
        :entity="entity"
        :is-link="isLink"
        :workpackage="workpackage"
      />
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex';
import { get } from 'lodash';

export default {
  props: {
    workpackages: {
      type: Array,
      required: true,
    },

    visibleWorkpackages: {
      type: Array,
      required: false,
      default: () => [],
    },

    entity: {
      type: String,
      required: false,
      default: '',
    },

    isLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      list: this.workpackages,
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
  },

  watch: {
    workpackages: {
      deep: true,
      handler() {
        this.list = this.workpackages;
      },
    },
  },

  mounted() {
    const workpackageId = get(this.selectedWorkpackage, '_id');

    if (workpackageId) this.scrollToSelectedWorkpackage(workpackageId);
  },

  methods: {
    show(workpackage) {
      if (get(workpackage, 'archived') === true) return true;
      return this.visibleWorkpackages.includes(workpackage._id);
    },

    scrollToSelectedWorkpackage(workpackageId) {
      const selectedScenarioId = `wp-panel-${workpackageId}`;
      const selectedScenario = document.getElementById(selectedScenarioId);
      if (selectedScenario) {
        selectedScenario.scrollIntoView();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.workpackage-list {
  list-style-type: none;
  padding: 0;
  min-width: 1350px;

  &__element {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__element:first-child {
    margin-top: 0;
  }

  &__element:last-child {
    margin-bottom: 0;
  }

  .no-data-text {
    width: 29rem;
    font-size: 1.2rem;
    padding: 1rem 0;
    white-space: normal;
  }
}
</style>
