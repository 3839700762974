<template>
  <v-container class="map-fields-list pa-1">
    <div class="map-fields-list__subtitle">
      <p class="mb-1">
        {{ $tkey('subtitle') }}
      </p>

      <download-link :url="downloadFileUrl">
        <v-btn small text color="primary">
          {{ $tkey('uploadedFile') }}
        </v-btn>
      </download-link>

      <p class="mt-1 mb-0">
        {{ $tkey('autoMappedFields', { numMappedFields: automaticMappings.length }) }}:
        {{ automaticMappings.join(', ') }}
      </p>
    </div>

    <v-divider />

    <v-list dense class="mt-2">
      <v-list-item class="divider">
        <v-col cols="6" class="pl-0 pb-1 font-weight-bold">
          {{ $tkey('expected') }}
        </v-col>
        <v-col cols="6" class="pl-0 pb-1 font-weight-bold">
          {{ $tkey('csv') }}
        </v-col>
      </v-list-item>

      <v-list-item v-for="header in unmappedExpectedFields" :key="header.mongoField" dense>
        <v-col cols="6" class="pa-1 pl-0">
          {{ $t(header.translationKey) }}
        </v-col>

        <v-col cols="6" class="pa-1 pl-0">
          <rtls-select
            :items="unmappedFields"
            :placeholder="$tkey('selectField')"
            clearable
            @input="updateMapping(header.mongoField, $event)"
          />
        </v-col>
      </v-list-item>
    </v-list>

    <v-card-actions>
      <v-spacer />
      <v-btn text @click="cancel">{{ $t('actions.cancel') }}</v-btn>
      <v-btn :disabled="confirmDisabled" small primary @click="confirmMappings">
        {{ $t('actions.confirm') }}
      </v-btn>
    </v-card-actions>
  </v-container>
</template>

<script>
import { find, sortBy } from 'lodash';

export default {
  localizationKey: 'mapFields',
  props: {
    uploadedDataDetails: {
      type: Object,
      required: false,
      default: () => null,
    },

    downloadFileUrl: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      mappings: [],
    };
  },

  computed: {
    automaticMappings() {
      return this.uploadedDataDetails.mappings.map(({ translationKey }) => this.$t(translationKey));
    },

    unmappedFields() {
      return this.uploadedDataDetails
        ? this.uploadedDataDetails.unmappedFields.map(({ selectedField }) => selectedField)
        : [];
    },

    unmappedExpectedFields() {
      return this.uploadedDataDetails
        ? sortBy(this.uploadedDataDetails.unmappedExpectedFields, 'required').reverse()
        : [];
    },

    expectedFieldsMapped() {
      return new Set(this.mappings.map(({ mongoField }) => mongoField));
    },

    availableExpectedFields() {
      return this.unmappedExpectedFields.filter(
        ({ mongoField, required }) => required && !this.expectedFieldsMapped.has(mongoField)
      );
    },

    confirmDisabled() {
      return this.availableExpectedFields.length > 0;
    },
  },

  methods: {
    updateMapping(mongoField, selectedField) {
      // If no fields selected it means it was cleared and we must remove mapping
      if (!selectedField) return this.removeMapping(mongoField);

      const existingMapping = find(this.mappings, { mongoField });
      if (existingMapping) existingMapping.selectedField = selectedField;
      else this.mappings.push({ mongoField, selectedField });
    },

    removeMapping(clearedField) {
      this.mappings = this.mappings.filter(({ mongoField }) => mongoField !== clearedField);
    },

    confirmMappings() {
      this.$emit('confirm', this.mappings);
    },

    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.container {
  font-size: 1.2rem;
}

.map-fields-list {
  &__subtitle {
    padding: 8px 12px 12px;
  }

  .v-list {
    padding: 0;
  }

  .v-list-item {
    padding-left: 12px;
    padding-right: 0;
    min-height: auto;

    &:nth-child(2n + 2) {
      background: $assortment-list-item-bg-colour !important;

      ::v-deep {
        .v-input {
          .v-input__slot,
          .v-select__slot {
            background: $assortment-input-background !important;
          }
        }
      }
    }

    &__title {
      font-size: 12px;
      line-height: 20px;
    }
  }

  .divider {
    border-bottom: 1px solid $assortment-menu-border-colour;
  }
}
</style>
