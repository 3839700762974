<template>
  <div class="d-flex filter-box" data-id-e2e="displayGroupFilterSelect">
    <span class="col-3">
      {{ $t('workpackagePage.splitWorkpackages.filterDisplayGroups') }}
    </span>
    <v-select
      v-model="selectedDisplayGroups"
      :items="displayGroups"
      item-value="_id"
      hide-details
      item-text=""
      white
      multiple
      :disabled="!displayGroups.length"
      :placeholder="$t('filterWorkpackagesOptions.noOption')"
      class="rtls-select rtls-select--white"
      data-id-e2e="displayGroupSelect"
      @change="updateMainFilterObject()"
    >
      <template v-slot:selection="{ item, index }">
        <span v-if="index < 1" class="selected-items">{{ item }} &nbsp;</span>
        <span v-if="index === 1" class="grey--text caption">
          (+{{ displayGroups.length - 1 }}
          {{ $t('workpackagePage.splitWorkpackages.moreDisplayGroups') }})
        </span>
      </template>
      <template v-slot:prepend-item>
        <div class="sticky">
          <div class="d-flex justify-space-around align-center pt-2">
            <v-btn class="button-link" text @click="selectAll">
              <v-icon size="16">mdi-check</v-icon>
              <span>{{ $t('actions.selectAll') }}</span>
            </v-btn>
            <v-btn class="button-link" text @click="selectNone">
              <v-icon size="16">mdi-checkbox-blank-outline</v-icon>
              <span>{{ $t('actions.selectNone') }}</span>
            </v-btn>
          </div>
          <v-divider class="mt-2" />
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { filterSplitWorkpackagesMixin } from '@/js/mixins/tooltip-options';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { map, compact, uniq } from 'lodash';
import filterTypeEnum from '@enums/wp-filter-types';

export default {
  mixins: [filterSplitWorkpackagesMixin],
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedDisplayGroups: [],
  }),

  computed: {
    ...mapGetters('workpackageProducts', ['workpackageProducts']),
    // Remove all undefined or falsy values, sort and just pluck the isDisplayable field
    displayGroups() {
      if (this.products.length > 0) {
        return uniq(compact(map(this.products, 'isDisplayable'))).sort();
      }
      return [];
    },
  },

  watch: {
    // When the products change, we should select all by default.
    products() {
      Vue.nextTick(() => {
        this.selectAll();
        this.$emit('updateMainFilterObject', {
          type: filterTypeEnum.displayGroup,
          update: this.selectedDisplayGroups,
          isInit: true,
        });
      });
    },
  },

  methods: {
    async updateMainFilterObject() {
      this.$emit('updateMainFilterObject', {
        type: filterTypeEnum.displayGroup,
        update: this.selectedDisplayGroups,
      });
    },

    selectAll() {
      this.selectedDisplayGroups = [...this.displayGroups];
    },

    selectNone() {
      this.selectedDisplayGroups = [];
      this.updateMainFilterObject();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .v-select__selections {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: none;
  }
  .v-list {
    position: relative;
  }
}
.filter-box {
  align-items: center;

  span {
    padding-left: 0;
  }
}

.selected-items {
  padding-left: 5px !important;
}

.sticky {
  position: sticky;
  background-color: $canvas-header-white-background;
  top: 0;
}

.button-link {
  color: $assortment-primary-colour;
  padding: 0 !important;
  &::before,
  &::after {
    display: none !important;
  }

  span {
    text-decoration: underline;
  }
}
</style>
