import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "panelHeader",
      class: _vm.getDrawerClass,
      style: _vm.getHeaderPanelStyle,
    },
    [
      _c("transition", { attrs: { name: "slide-fade" } }, [
        _vm.isProductSidebarOpen
          ? _c(
              "div",
              { staticClass: "sticky sticky--top" },
              [
                _c("div", { staticClass: "product-info" }, [
                  _c(
                    "div",
                    { staticClass: "product-info__icons-row" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _vm.showMinimumDistribution &&
                          _vm.product.minimumDistribution > 0
                            ? _c(
                                VIcon,
                                {
                                  staticClass: "product-icon",
                                  attrs: { color: "white" },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s("mdi-speedometer") +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.selectedDashboardProduct.productSeries
                            ? _c(
                                "div",
                                { staticClass: "product-icon" },
                                [_c("series-icon")],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPallets
                            ? _c(
                                "div",
                                { staticClass: "product-icon" },
                                [
                                  _c("pallet-icon", {
                                    attrs: {
                                      fill: _vm.hasAssignedPallets
                                        ? _vm.iconPrimary
                                        : _vm.iconDisabled,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.inReset
                            ? _c(
                                "div",
                                { staticClass: "product-icon" },
                                [
                                  _c("not-in-reset-icon", {
                                    attrs: { color: "white" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isLocked
                            ? _c(
                                VIcon,
                                {
                                  staticClass: "product-icon",
                                  attrs: { color: "white" },
                                },
                                [
                                  _vm._v(
                                    "\n              mdi-lock-outline\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          attrs: { icon: "", size: "small" },
                          on: { click: _vm.onContextClose },
                        },
                        [
                          _c(VIcon, { staticClass: "close-icon" }, [
                            _vm._v("mdi-close"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "product-info__main-row" }, [
                    _c("div", [
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.selectedDashboardProduct.productSeries)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("h3", { staticClass: "product-info__title" }, [
                        _vm._v(
                          _vm._s(_vm.selectedDashboardProduct.itemDescription)
                        ),
                      ]),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.selectedDashboardProduct.productKeyDisplay
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "product-info__image" },
                      [
                        _c("assortment-image", {
                          attrs: {
                            src: _vm.imageUrl,
                            alt: _vm.displayText,
                            title: _vm.displayText,
                            width: "108px",
                            height: "108px",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "product-info__tags-row mt-2" },
                    [
                      _c("product-delta-tag", {
                        attrs: {
                          show:
                            _vm.referenceDelta !== 0 &&
                            _vm.selectedDashboardProduct.isEligible,
                          type: "R",
                          delta: _vm.referenceDelta,
                        },
                      }),
                      _vm._v(" "),
                      _c("assortment-tag", {
                        attrs: { "tag-text": _vm.getProductTag },
                      }),
                      _vm._v(" "),
                      _c("product-delta-tag", {
                        attrs: {
                          show: _vm.shouldShowOptimisedDelta,
                          type: "O",
                          delta: _vm.lastOptimisedDelta,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  VExpansionPanels,
                  {
                    staticClass: "product-panel__expansion-panel",
                    attrs: { flat: true, multiple: true, accordion: "" },
                  },
                  [
                    _c(
                      VExpansionPanel,
                      [
                        _c(VExpansionPanelHeader, {
                          attrs: { "expand-icon": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c(
                                      VRow,
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          VCol,
                                          {
                                            staticClass: "d-flex justify-start",
                                            attrs: { cols: "4" },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "mr-3" },
                                              [_c("spacbreak-panel-icon")],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("h2", [
                                              _vm._v(
                                                _vm._s(_vm.$tkey("spacebreak"))
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            63325067
                          ),
                        }),
                        _vm._v(" "),
                        _c(VExpansionPanelContent, [
                          _c(
                            "div",
                            { staticClass: "pa-2 divider-top--dark" },
                            [
                              _c("div", { staticClass: "text-14 mb-2" }, [
                                _vm._v(
                                  _vm._s(_vm.$tkey("current")) +
                                    ": " +
                                    _vm._s(_vm.selectedSpacebreakName)
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                VRow,
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass:
                                        "d-flex justify-end align-center pr-3",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c("h3", [
                                        _vm._v(_vm._s(_vm.$t("actions.move"))),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VCol,
                                    [
                                      _c(VSelect, {
                                        attrs: {
                                          items: _vm.moveSelectionOptions,
                                          "hide-details": "",
                                          dense: "",
                                          "append-icon": "expand_more",
                                        },
                                        model: {
                                          value: _vm.moveSelection,
                                          callback: function ($$v) {
                                            _vm.moveSelection = $$v
                                          },
                                          expression: "moveSelection",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                VRow,
                                {
                                  staticClass: "mt-3 mb-3",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass:
                                        "d-flex justify-end align-center pr-3",
                                      attrs: { cols: "2" },
                                    },
                                    [
                                      _c("h3", [
                                        _vm._v(_vm._s(_vm.$t("actions.to"))),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VCol,
                                    [
                                      _c(VSelect, {
                                        attrs: {
                                          items: _vm.moveToOptions,
                                          "hide-details": "",
                                          dense: "",
                                          "append-icon": "expand_more",
                                        },
                                        model: {
                                          value: _vm.moveTo,
                                          callback: function ($$v) {
                                            _vm.moveTo = $$v
                                          },
                                          expression: "moveTo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "d-flex align-center ml-3",
                                      attrs: { cols: "auto" },
                                    },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          attrs: {
                                            outlined: "",
                                            color: "primary",
                                            disabled: _vm.isMoveDisabled,
                                          },
                                          on: { click: _vm.move },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t("actions.move")) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.selectedDashboardProduct.productSeries
                      ? _c(
                          VExpansionPanel,
                          [
                            _c(VExpansionPanelHeader, {
                              staticClass: "series-expansion-panel-header",
                              attrs: { "expand-icon": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function () {
                                      return [
                                        _c(
                                          VRow,
                                          { attrs: { "no-gutters": "" } },
                                          [
                                            _c(
                                              VCol,
                                              {
                                                staticClass:
                                                  "d-flex justify-start",
                                                attrs: { cols: "4" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "mr-3" },
                                                  [_c("series-panel-icon")],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("h2", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tkey("productSeries")
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1358549413
                              ),
                            }),
                            _vm._v(" "),
                            _c(VExpansionPanelContent, [
                              _c(
                                "div",
                                { staticClass: "pa-2" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-14" },
                                    [
                                      _c(
                                        VTooltip,
                                        {
                                          attrs: { "z-index": 150, top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "text-14",
                                                        },
                                                        _vm
                                                          .selectedDashboardProduct
                                                          .productSeries
                                                          ? on
                                                          : null
                                                      ),
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.seriesManagementInfoTxt
                                                            ) +
                                                            "\n                    "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3366441502
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tkey("seriesStatsTotal")
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "actionable-link",
                                        {
                                          style: { fontSize: "12px" },
                                          attrs: { to: _vm.goToPAEPage },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$tkey("manageSeries")
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VRow,
                                    {
                                      staticClass: "mb-2",
                                      attrs: { "no-gutters": "" },
                                    },
                                    [
                                      _c(
                                        VCol,
                                        {
                                          staticClass:
                                            "d-flex justify-end align-center pr-2",
                                        },
                                        [
                                          _c("span", { staticClass: "pr-2" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("general.all"))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            VIcon,
                                            {
                                              attrs: {
                                                size: "20",
                                                color: "primary",
                                                disabled: _vm.isEditingDisabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toggleAllLock()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.hasSeriesUnlockedProducts
                                                      ? "mdi-lock-outline"
                                                      : " mdi-lock-open-variant-outline"
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "series-products__container mb-2",
                                    },
                                    _vm._l(_vm.seriesProducts, function (p) {
                                      return _c(
                                        VRow,
                                        {
                                          key: p._id,
                                          staticClass:
                                            "series-products__item pa-2",
                                          attrs: { "no-gutters": "" },
                                        },
                                        [
                                          _c(
                                            VCol,
                                            { attrs: { cols: "10" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "w-100 overflow-ellipsis",
                                                  class: {
                                                    "font-weight-bold":
                                                      p._id ===
                                                      _vm
                                                        .selectedDashboardProduct
                                                        ._id,
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(p.itemDescription)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            VCol,
                                            {
                                              staticClass:
                                                "d-flex justify-end align-center",
                                            },
                                            [
                                              _c(
                                                VIcon,
                                                {
                                                  attrs: {
                                                    size: "20",
                                                    color: "primary",
                                                    disabled:
                                                      _vm.isEditingDisabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toggleLock(p)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.isProductLocked(p)
                                                          ? "mdi-lock-outline"
                                                          : "mdi-lock-open-variant-outline"
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      !_vm.isFilterSeriesApplied
                                        ? _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                outlined: "",
                                                depressed: "",
                                                color: "primary",
                                              },
                                              on: { click: _vm.filterSeries },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$tkey("displaySeries")
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                outlined: "",
                                                depressed: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: _vm.clearSeriesFilter,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$tkey(
                                                      "clearSeriesFilter"
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: {
                                                "z-index": 150,
                                                top: "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "text-14",
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .totalSeriesSpaceStats
                                                                  .roundedUnlockedSize
                                                              ) +
                                                                " /\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3906488149
                                              ),
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tkey(
                                                      "seriesStatsHoverUnlocked"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: {
                                                "z-index": 150,
                                                top: "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "text-14",
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                `${_vm.totalSeriesSpaceStats.roundedTotalSize} (${_vm.totalSeriesSpaceStats.uom})`
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3639847124
                                              ),
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tkey(
                                                      "seriesStatsTotal"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }