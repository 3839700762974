<template>
  <div class="select-renderer">
    <v-autocomplete
      v-model="model"
      hide-no-data
      hide-details
      class="autocomplete-search-input ma-0 pa-0"
      :items="items"
      item-text="text"
      item-value="value"
      :disabled="isDisabled"
      :placeholder="$t('general.select')"
      dense
      solo
      flat
      clearable
      :search-input.sync="searchInput"
      @input="searchInput = null"
      @change="params.onChange"
      @keydown.enter.prevent
      @keydown.delete.stop
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { get } from 'lodash';

export default Vue.extend({
  data() {
    return {
      model: null,
      searchInput: null,
    };
  },
  computed: {
    items() {
      return get(this.params, 'items', []);
    },

    isDisabled() {
      return get(this.params, 'isDisabled', false);
    },
  },
  created() {
    this.model = this.params.initialValue;
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.autocomplete-search-input {
  width: 100%;
  height: 100%;
}

.select-renderer {
  width: 100%;
}
</style>

<style lang="scss">
@import '@style/base/_variables.scss';

.select-renderer > .v-input > .v-input__control > .v-input__slot {
  background-color: inherit;
}

.select-renderer > .v-input.v-input--is-focused > .v-input__control > .v-input__slot {
  background-color: $assortment-table-white-bg-colour;
}
</style>
