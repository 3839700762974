'use strict';

const DataTypes = require('../../../enums/data-types');

const fixedFields = [
  {
    mongoField: 'storeFormat',
    type: DataTypes.str,
    aliases: 'csvAliases.storeAllocation.storeFormat',
    translationKey: 'mongoFields.storeFormat',
    required: false,
    immutable: true,
  },
  {
    mongoField: 'spacebreakName',
    type: DataTypes.str,
    aliases: 'csvAliases.storeAllocation.spacebreakName',
    translationKey: 'mongoFields.spacebreak',
    required: true,
  },
  {
    mongoField: 'furnitureArchetypeName',
    type: DataTypes.str,
    aliases: 'csvAliases.storeAllocation.furnitureArchetypeName',
    translationKey: 'mongoFields.furnitureArchetype',
    required: true,
  },
  {
    mongoField: 'storeKeyDisplay',
    type: DataTypes.alphanumeric,
    aliases: 'csvAliases.storeAllocation.storeKeyDisplay',
    translationKey: 'mongoFields.storeKeyDisplay',
    required: true,
  },
  {
    mongoField: 'storeName',
    type: DataTypes.str,
    aliases: 'csvAliases.storeAllocation.storeName',
    translationKey: 'mongoFields.storeName',
    required: false,
    immutable: true,
  },
  {
    mongoField: 'clustered',
    type: DataTypes.boolean,
    aliases: 'csvAliases.storeAllocation.clustered',
    translationKey: 'mongoFields.clustered',
    required: true,
  },
];

module.exports = {
  fixedFields,
};
