<template>
  <v-form
    ref="form"
    v-model="valid"
    class="main-expandable-panel panel-row d-flex"
    autocomplete="off"
    @submit.prevent=""
  >
    <div class="panel-cell drag-cell flex-grow-0 flex-shrink-0" />
    <div class="panel-cell name-description pr-0">
      <div class="top-container d-flex">
        <rtls-text-field
          :key="nameKey"
          v-model.trim="model.name"
          :disabled="isEditingDisabled"
          data-id-e2e="workpackageName"
          :data-value-e2e="model.name"
          :rules="[
            required,
            v => isNameUnique(v, workpackage.name, wpNames, $t('entities.workpackage')),
          ]"
          @blur="saveModel"
          @keyup.enter="saveModel"
          @keyup.esc="resetModel('name')"
        />
      </div>
      <rtls-text-field
        :key="descriptionKey"
        v-model.trim="model.description"
        :disabled="isEditingDisabled"
        @blur="saveModel"
        @keydown.enter="saveModel"
        @keydown.esc="resetModel('description')"
      />
    </div>

    <assortment-groups class="panel-cell" :groups="assortmentGroups" />

    <div class="d-flex flex-column align-start wp-source-info panel-cell">
      <div v-if="shouldShowTemplatesInfo" class="d-flex w-100">
        <img width="15px" :src="templateIcon" alt="Template" />
        <div class="trimmed-text font-weight-bold ml-2">{{ templateName }}</div>
      </div>
      <assortment-type
        :disabled="isTypeDisabled"
        :types="types"
        :type="model.type"
        :rules="[required]"
        @update="updateWorkpackageType($event, false)"
      />
    </div>

    <div v-if="bundlesEnabled" class="panel-cell target-lunch d-flex flex-column justify-start">
      <div class="target-launch-info d-flex flex-column justify-center align-start">
        <h4 class="header">{{ $t('bundlesPage.goLive') }}</h4>
        <span v-if="workpackage.bundleId" class="date">
          {{ getMomentObject(workpackage.targetLaunchDate) | formatDate(getDateFormats.long) }}
        </span>
        <template v-else>
          -
        </template>
      </div>
    </div>

    <status-scope
      class="panel-cell"
      :disabled="isScopeDisabled"
      :date="model.lastModifiedDate"
      :status="workpackageStatus"
      :is-loading="isLoadingWorkpackageDetails"
      :workpackage-id="workpackage._id"
      :has-notes="!!workpackage.totalNotes"
      @toggleScope="handleScoped"
    />

    <users-permissions v-if="showNotImplemented" class="panel-cell" />

    <command-bar class="panel-cell workpackage__cell-icons">
      <ul class="flex-list pl-0">
        <li>
          <command-item
            v-if="showNotImplemented"
            icon="$new-workpackage"
            disabled
            @execute="() => {}"
          />
        </li>
        <li>
          <workpackage-option-unarchive
            v-if="workpackage.archived"
            :workpackage="workpackage"
            :entity="entities.workpackage"
          />
          <workpackage-option-archive
            v-else
            :workpackage="workpackage"
            :entity="entities.workpackage"
          />
        </li>
        <li><workpackage-option-copy :workpackage="workpackage" /></li>
        <li><workpackage-option-delete :workpackage="workpackage" /></li>
      </ul>
    </command-bar>

    <div
      class="panel-cell expand d-flex align-center justify-center flex-grow-0 flex-shrink-0 align-center"
    >
      <main-expand-button
        :disabled="isExpandDisabled"
        :class="{ 'float-right': 'float-right' }"
        :is-expanded="isExpanded"
        :is-link="isLink"
        @expand="handleExpanded"
      />
    </div>

    <dependency-tree-feedback-modal
      :value="dependencyTreeModalOpen"
      :results="dependencyTreeFeedback"
      page="workpackageSetup"
      @close="closeDependencyTreeModal"
      @commit="updateWorkpackageType($event, true)"
    />
  </v-form>
</template>

<script>
import { includes, isEqual, map, get } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  jobStatuses,
  allowedJobStatuses,
  jobFinishedStatuses,
  jobInProgressStatuses,
} from '@enums/jobapi';
import WorkpackageType from '@enums/workpackage-type';
import entities from '@enums/entities';
import inputValidationMixin from '@/js/mixins/input-validations';
import datesMixin from '@/js/mixins/date-utils';
import templateIcon from '@/img/template.svg';

export default {
  mixins: [inputValidationMixin, datesMixin],

  props: {
    disableExpand: {
      type: Boolean,
      required: true,
    },

    isLink: {
      type: Boolean,
      required: false,
      default: false,
    },

    workpackage: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      model: {
        name: this.workpackage.name,
        description: this.workpackage.description,
        type: this.workpackage.type,
        targetLaunchDate: this.workpackage.targetLaunchDate,
        lastModifiedDate: this.workpackage.lastModifiedDate,
      },
      types: null,
      valid: true,
      nameKey: false,
      descriptionKey: false,
      isLoadingWorkpackageDetails: false,
      dependencyTreeModalOpen: false,
      dependencyTreeFeedback: {},
      originalTypeSelection: null,
      intendedTypeSelection: null,
      templateIcon,
      entities,
    };
  },

  computed: {
    ...mapState('workpackages', [
      'workpackages',
      'selectedWorkpackage',
      'isScenarioPanelExpanded',
      'isWPSetupExpanded',
    ]),
    ...mapGetters('workpackages', ['getWorkpackageById', 'templatesById']),
    ...mapGetters('context', ['showNotImplemented', 'getSimpleSwapsPermissions', 'getDateFormats']),

    wpNames() {
      return map(this.workpackages, 'name');
    },

    assortmentGroups() {
      return map(this.workpackage.assortmentGroups, ag => {
        return { name: ag.name };
      });
    },

    workpackageStatus() {
      // if workpackage doesn't have any jobs or doesn't have workpackageSetup return status as ''
      if (
        !this.workpackage._id ||
        !this.workpackage.jobs ||
        !this.workpackage.jobs.workpackageSetup
      ) {
        return '';
      }

      // if workpackage has workpackageSetup job
      const jobStatus = this.workpackage.jobs.workpackageSetup.status;
      if (jobStatus && !includes(allowedJobStatuses, jobStatus)) {
        console.error(
          `Unexpected job status: ${jobStatus}. WorkpackageId: ${this.workpackage._id}`
        );
      }
      if (includes(jobFinishedStatuses, jobStatus)) {
        return jobStatus;
      }
      if (includes(jobInProgressStatuses, jobStatus)) {
        return 'in-progress';
      }
      return '';
    },

    isExpanded() {
      if (!this.selectedWorkpackage) return false;
      return this.isScenarioPanelExpanded && this.selectedWorkpackage._id === this.workpackage._id;
    },

    isScoped() {
      if (!this.selectedWorkpackage) return false;
      return this.isWPSetupExpanded && this.selectedWorkpackage._id === this.workpackage._id;
    },

    isExpandDisabled() {
      return (
        !this.workpackage._id ||
        this.disableExpand ||
        ![jobStatuses.finished].includes(this.workpackageStatus)
      );
    },

    isTypeDisabled() {
      return this.workpackageStatus !== '';
    },

    isScopeDisabled() {
      // If a WP has a status, it means that the WP setup has a job for it
      const hasJobStatus = this.workpackageStatus !== '';

      return hasJobStatus && !includes(jobFinishedStatuses, this.workpackageStatus);
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditWorkpackageNameDescription);
    },

    templateName() {
      return get(this.templatesById[this.workpackage.templateId], 'name');
    },

    shouldShowTemplatesInfo() {
      return this.templatesEnabled && !this.workpackage.isTemplate && this.workpackage.templateId;
    },
  },

  created() {
    this.populateTypes();
  },

  methods: {
    ...mapActions('workpackages', [
      'toggleWorkpackageSetupExpanded',
      'toggleScenarioPanelExpanded',
      'deleteWorkpackage',
      'updateWorkpackage',
      'refreshWorkpackageInformation',
    ]),
    ...mapActions('dependencyTree', ['triggerDependencyTree']),

    resetModel(field) {
      this[`${field}Key`] = !this[`${field}Key`]; // toggle the key value so the component is reset
      this.updateModel(field, this.getWorkpackageById(this.workpackage._id)[field]);
    },

    updateModel(field, value) {
      this.model[field] = value;
    },

    async editAndSaveExisting(field, value) {
      this.updateModel(field, value);
      if (this.workpackage._id) {
        await this.saveModel();
      }
    },

    async saveModel() {
      if (!this.valid) return false;

      await this.updateWorkpackage({
        workpackageId: this.workpackage._id,
        updates: { ...this.model },
      });

      this.nameKey = !this.nameKey;
    },

    async handleScoped() {
      this.isLoadingWorkpackageDetails = true;
      await this.toggleWorkpackageSetupExpanded({ workpackage: this.workpackage });
      this.isLoadingWorkpackageDetails = false;
    },

    handleExpanded() {
      this.toggleScenarioPanelExpanded({ workpackage: this.workpackage });
      if (this.isLink) this.$router.push({ path: 'home' });
    },

    handleDelete() {
      this.deleteWorkpackage(this.workpackage);
    },

    getData() {
      return this.model;
    },

    validate() {
      return this.$refs.form.validate();
    },

    populateTypes() {
      const availableTypes = this.getSimpleSwapsPermissions
        ? WorkpackageType.ALL
        : WorkpackageType.ALL.filter(type => type !== WorkpackageType.ASSORTMENT_SIMPLE_SWAPS);

      this.types = availableTypes.map(type => {
        return {
          text: this.$t(`wpTypeEnum.${type}`),
          value: type,
        };
      });
    },

    async updateWorkpackageType(event, commit = false) {
      const { value } = event || {};

      if (value) {
        this.intendedTypeSelection = value;
        this.originalTypeSelection = this.model.type;
      }

      this.$set(this.model, 'type', this.intendedTypeSelection);

      const results = await this.triggerDependencyTree({
        params: {
          change: 'workpackageTypeChanged',
          updates: {},
          commit,
          workpackageId: this.workpackage._id,
        },
      });

      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return true;
      }

      await this.editAndSaveExisting('type', this.model.type);
      await this.refreshWorkpackageInformation({ workpackageId: this.workpackage._id });
    },

    closeDependencyTreeModal(params) {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
      if (!isEqual(params, 'afterSave')) {
        this.$set(this.model, 'type', this.originalTypeSelection);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.panel-cell {
  border-right: 1px solid $assortment-workpackages-edit-border-colour;
  justify-content: center;
  flex-grow: 1;

  &:first-child {
    border-right: none;
  }
  &.expand {
    border-right: none;
  }

  &.target-lunch {
    flex-grow: 0;
    width: 80px;
  }
}

.panel-row {
  height: 60px;
  background: $assortment-workpackage-bg-colour;
}

.failed {
  background-color: $assortment-workpackage-drag-cell-bg-colour !important;

  .drag-cell {
    background: $assortment-workpackage-drag-cell-colour-failed !important;
  }

  & .status-icon {
    color: $assortment-workpackage-drag-cell-colour-failed !important;
  }

  svg {
    filter: brightness(5);
  }
}

.success,
.finished {
  background-color: $assortment-workpackage-drag-cell-bg-colour !important;

  .drag-cell {
    background: $assortment-workpackage-drag-cell-colour-finished !important;
  }

  & .status-icon {
    color: $assortment-workpackage-drag-cell-colour-finished;
  }

  svg {
    filter: brightness(5);
  }
}

.disabled {
  color: $assortment-disabled-text-button-colour;
  pointer-events: none;

  ::v-deep {
    .tooltip {
      color: $assortment-disabled-text-button-colour;
    }
  }
}

.drag-cell {
  background: $assortment-workpackage-drag-cell-colour;
}

.name-description {
  ::v-deep {
    & .v-input {
      &__control {
        border: 1px dashed $assortment-workpackages-edit-border-colour;
      }
    }
  }
}

.wp-source-info {
  max-width: 250px;
}
</style>
