import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reporting-selection" },
    [
      _c(
        VBtn,
        {
          staticClass: "sidebar-toggle-button ml-2",
          attrs: { icon: "", tile: "", ripple: false },
          on: {
            click: function ($event) {
              return _vm.toggleSidebar(false)
            },
          },
        },
        [
          _c("img", {
            class: { "button-active": _vm.isSidebarShown },
            attrs: {
              src: _vm.isSidebarShown
                ? _vm.closeSidebarIcon
                : _vm.closeSidebarSelectedIcon,
              alt: "Close sidebar icon",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        VBtn,
        {
          staticClass: "sidebar-toggle-button mr-2",
          attrs: { icon: "", tile: "", ripple: false },
          on: {
            click: function ($event) {
              return _vm.toggleSidebar(true)
            },
          },
        },
        [
          _c("img", {
            class: { "button-active": !_vm.isSidebarShown },
            attrs: {
              src: _vm.isSidebarShown
                ? _vm.openSidebarSelectedIcon
                : _vm.openSidebarIcon,
              alt: "Open sidebar icon",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _vm.isSidebarShown
        ? _c(
            "div",
            { staticClass: "reporting-selection__row mx-2 justify-end" },
            [
              _c(
                "treeselect",
                {
                  staticClass:
                    "vue-treeselect-custom-tree mr-2 w-70 vue-treeselect--values-hidden",
                  attrs: {
                    value: _vm.selections,
                    "data-id-e2e": "reportingBundleWorkpackageSelect",
                    multiple: "",
                    options: _vm.validOptions,
                    placeholder: `${_vm.$t(
                      "reportingPage.totalItemsSelected"
                    )}: ${_vm.totalSelected}`,
                    "default-expand-level": 1,
                    "no-results-text": _vm.$t("reportingPage.noResults"),
                    "no-options-text": _vm.noOptionsMessage,
                    "search-nested": "",
                    "disable-fuzzy-matching": "",
                    clearable: false,
                    "value-consists-of": "LEAF_PRIORITY",
                  },
                  on: {
                    select: _vm.addSelection,
                    deselect: _vm.removeSelection,
                    close: _vm.changeSelections,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "option-label",
                        fn: function ({ node, labelClassName }) {
                          return _c(
                            "label",
                            { class: labelClassName },
                            [
                              _c(
                                VTooltip,
                                {
                                  attrs: {
                                    top: "",
                                    disabled:
                                      node.label.length < _vm.truncateThreshold,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c("span", _vm._g({}, on), [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(node.label) +
                                                  "\n            "
                                              ),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c("span", [_vm._v(_vm._s(node.label))]),
                                ]
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    895801223
                  ),
                },
                [
                  _c(
                    "header",
                    {
                      staticClass: "vue-treeselect__header d-flex",
                      attrs: { slot: "before-list" },
                      slot: "before-list",
                    },
                    [
                      _vm.bundlesEnabled
                        ? _c(
                            "span",
                            { staticClass: "vue-treeselect__heading" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "reportingPage.selectionHeaders.bundles"
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", { staticClass: "vue-treeselect__heading" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "reportingPage.selectionHeaders.workpackages"
                              )
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("docs-link", {
                attrs: { link: "toolguide/130-reporting.html" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showNotImplemented
        ? _c(
            "div",
            { staticClass: "reporting-selection__row mx-2" },
            [
              _c("span", { staticClass: "span-text mx-2" }, [
                _vm._v(_vm._s(_vm.$t("general.filter"))),
              ]),
              _vm._v(" "),
              _c("rtls-select", {
                attrs: {
                  disabled: "",
                  width: "240px",
                  items: [],
                  placeholder: _vm.$t("general.select"),
                },
                model: {
                  value: _vm.filters,
                  callback: function ($$v) {
                    _vm.filters = $$v
                  },
                  expression: "filters",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }