<template>
  <v-dialog
    v-if="product"
    v-model="showDashboard"
    content-class="rtls-dialog rtls-dialog--dashboard"
    max-width="1120"
  >
    <dialog-card :title="$tkey('title')" doc-path="200-productdashboard" @close="closeDashboard">
      <div class="flex-container dashboard-text">
        <div class="flex-sub-container1">
          <div class="summary">
            <product-draggable-tooltip
              :product-info="product"
              :selected-kpis="clientConfig.selectedKpis"
              :css-zindex="zIndex"
              :pod-and-store-count-info="
                podAndStoreCounts(
                  product,
                  selectedCanvas.clusterId,
                  spacebreaksIndexedByIdByCluster
                )
              "
              :product-positioning-info="
                extractProductPositioningInfo(
                  product,
                  selectedCanvas.clusterId,
                  spacebreaksIndexedByIdByCluster,
                  selectedPod
                )
              "
              :settings="tooltipSettings"
              :image-url="imageUrl"
              :image-title="displayText"
              :image-alt="displayText"
              :conditional-product-tile-style="baseStyle"
              :spacebreaks="spacebreaksNames"
              :selected-spacebreak="selectedSpacebreak"
              :spacebreak-disabled="
                isSpacebreakLocked(product.currentSpacebreakId) || isEditingDisabled
              "
              :spacebreak-types="spacebreakTypes"
              :change-spacebreak="changeSpacebreak"
              :lock-types="lockTypes"
              :popup-button="{}"
              :parent-is-dashboard="true"
              :current-numeric-locale="currentNumericLocale"
            />
          </div>
          <div class="top-switching-partners">
            <span class="section-label font-weight-bold">
              {{ $tkey('topSwitchingPartners') }}
            </span>
            <top-partners />
          </div>
        </div>
        <div ref="right-pane" class="flex-sub-container2">
          <div class="product-distribution divider-bottom">
            <span class="section-label font-weight-bold">
              {{ $tkey('productDistribution.title') }}
            </span>
            <product-distribution />
          </div>

          <div
            class="transferred-spend"
            :class="{
              'divider-bottom': hasRelativeProductPerformanceEnabled,
              'h-100': !hasRelativeProductPerformanceEnabled,
            }"
          >
            <span class="section-label font-weight-bold">
              {{ $tkey('transferredSpend.title') }}
            </span>
            <div v-if="!selectedCanvas.hasBeenOptimised">
              <span>{{ $tkey('transferredSpend.onlyForOptimised') }} </span>
            </div>
            <transferred-spend v-if="selectedCanvas.hasBeenOptimised" />
          </div>

          <div v-if="hasRelativeProductPerformanceEnabled" class="relative-product-performance">
            <span class="section-label font-weight-bold mb-2 d-block">
              {{ $tkey('relativeProductPerformance.title') }}
            </span>
            <relative-product-performance />
          </div>
        </div>
      </div>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { get } from 'lodash';
import { lockTypes } from '@enums/assortment-product-lock-types';
import { spacebreakTypes } from '@enums/spacebreak-types';
import productMixin from '../product.mixin';

export default {
  mixins: [productMixin], // includes methods extractProductPositioningInfo
  localizationKey: 'assortmentCanvasPage.productDashboard',

  data() {
    return {
      baseStyle: {
        width: '90px',
        height: '90px',
      },
      lockTypes,
      spacebreakTypes,
      showDashboard: false,
      zIndex: null,
    };
  },

  computed: {
    ...mapState('assortmentCanvas', ['tileViewContainerHeight', 'popUpZindex', 'selectedPod']),
    ...mapState('context', ['clientConfig']),
    ...mapGetters('assortmentCanvas', [
      'selectedCanvas',
      'getDashboardProduct',
      'getShowDashboard',
      'isSpacebreakLocked',
    ]),
    ...mapGetters('furniture', ['getSpacebreaksIndexedById', 'spacebreaksShortNamesByCluster']),
    ...mapGetters('context', ['getCurrentNumericLocale']),

    // ensures homogeneous interface in product.mixin.js
    product() {
      return this.getDashboardProduct;
    },

    currentNumericLocale() {
      return this.getCurrentNumericLocale;
    },

    displayText() {
      return this.product.itemDescription || this.product.productKeyDisplay;
    },

    tooltipSettings() {
      return {
        top: 75,
        width: this.clientConfig.canvasPopUpWidthInsideDashboard,
        height: this.clientConfig.canvasPopUpHeightInsideDashboard,
        zIndex: null,
      };
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAssortmentCanvas);
    },

    hasRelativeProductPerformanceEnabled() {
      return get(this.getClientConfig, 'features.relativeProductPerformanceEnabled', false);
    },
  },

  watch: {
    showDashboard(newValue) {
      if (!newValue) {
        // closes when escape key or click event outside the dashboard is triggered.
        this.closeDashboard();
        this.setDashboardProduct(null);
      }
      this.$nextTick(() => {
        // reset scroll position of right pane when opening new product dashboard
        this.$refs['right-pane'].scrollTop = 0;
      });
    },

    getShowDashboard(newValue) {
      this.showDashboard = newValue;
    },
  },

  mounted() {
    this.zIndex = this.popUpZindex;
  },

  methods: {
    ...mapMutations('assortmentCanvas', ['setShowDashboard', 'setDashboardProduct']),

    closeDashboard() {
      this.setShowDashboard(false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.dashboard-text {
  height: 100%;
  font-size: 1.5rem;

  &.bold {
    font-weight: bold;
  }
}

.dashboard-image {
  margin-right: 20px;
}

.flex-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.flex-sub-container1 {
  display: flex;
  flex-direction: column;
  width: 28%; // opposite of flex-sub-container2
  border-right: 1px solid $assortment-divider-colour;

  .summary {
    margin-top: 5px;
    border-bottom-color: $assortment-divider-colour;
    border-style: solid;
    border-width: 0 0 1px 0;
  }

  .top-switching-partners {
    margin-top: 10px;
    overflow: auto;
    margin-bottom: 10px;
    margin-right: 20px;
  }
}
.flex-sub-container2 {
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 72%; // opposite of flex-sub-container1

  .product-distribution {
    display: flex;
    height: 300px;
    min-height: 150px;
    flex-direction: column;
  }

  .product-distribution,
  .transferred-spend,
  .relative-product-performance {
    margin-top: 15px;
    padding-left: 20px;
  }
}
.section-label {
  font-size: 1.3rem;
}
</style>
