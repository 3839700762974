const reportingTabs = [
  {
    key: 'overview', // Unique key for the tabs.
    translationKey: {
      title: 'reportingPage.overview', // Translation key for the title.
    },
    routeName: '', // The route name to navigate to on each tab.
    permission: 'overview', // Must related to the keys within ReportingSections.
    position: 0, // Where in the list of tabs this should appear (should be unique), first tab is zero.
  },
  {
    key: 'stats',
    translationKey: {
      title: 'reportingPage.sections.stats.title',
      subtitle: 'reportingPage.sections.stats.subtitle',
    },
    routeName: 'stats',
    permission: 'stats',
    position: 1,
  },
  {
    key: 'relativeShare',
    translationKey: {
      title: 'reportingPage.sections.relative-share.title',
      subtitle: 'reportingPage.sections.relative-share.subtitle',
    },
    routeName: 'relative-share',
    permission: 'relative-share',
    position: 2,
  },
  {
    key: 'comparisonWaterfall',
    translationKey: {
      title: 'reportingPage.sections.comparison-waterfall.title',
      subtitle: 'reportingPage.sections.comparison-waterfall.subtitle',
    },
    routeName: 'comparison-waterfall',
    permission: 'comparison-waterfall',
    position: 3,
  },
  {
    key: 'priceLadder',
    translationKey: {
      title: 'reportingPage.sections.price-ladder.title',
      subtitle: 'reportingPage.sections.price-ladder.subtitle',
    },
    routeName: 'price-ladder',
    permission: 'price-ladder',
    position: 4,
  },
  {
    key: 'product',
    translationKey: {
      title: 'reportingPage.sections.product.title',
      subtitle: 'reportingPage.sections.product.subtitle',
    },
    routeName: 'product',
    permission: 'product',
    position: 5,
    featureFlag: 'isProductReportDisplayed', // this flag is only off for 1 banner, revise in the future if we still need it
  },
];

export default {
  reportingTabs,
};
