import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "spacebreak-panel" },
    [
      _c(
        VRow,
        [
          _c(
            VCol,
            { staticClass: "pb-1", attrs: { cols: "5" } },
            [
              _c("rtls-text-field", {
                ref: "spacebreakName",
                staticClass: "storeclass-text-field",
                attrs: {
                  disabled: _vm.isEditingDisabled,
                  "run-validations-on-creation": "",
                  rules: [_vm.required, _vm.isNotEmpty],
                  "server-error": _vm.spacebreakNameIsUnique({
                    spacebreak: _vm.spacebreak,
                    storeClassId: _vm.storeClassId,
                  }),
                  "hide-details": "",
                },
                on: { input: _vm.updateSpacebreak },
                model: {
                  value: _vm.spacebreak.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.spacebreak,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "spacebreak.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            { staticClass: "pl-1 pb-1 title-column", attrs: { cols: "7" } },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    VCol,
                    { staticClass: "associated-header", attrs: { cols: "10" } },
                    [
                      _c("b", [_vm._v(_vm._s(_vm.$tkey("associatedFA")))]),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tkey("addFromList")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { staticClass: "d-flex justify-end", attrs: { cols: "2" } },
                    [
                      _c(
                        VIcon,
                        {
                          attrs: {
                            disabled:
                              _vm.isRemoveDisabled || _vm.isEditingDisabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.removeSpacebreak()
                            },
                          },
                        },
                        [_vm._v("\n            $trash\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        [
          _c(
            VCol,
            { attrs: { cols: "5" } },
            [
              _c(VSimpleTable, {
                staticClass: "striped-table simple-assortments-table",
                attrs: { dense: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c("th", { staticClass: "text-left" }),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    `${_vm.$tkey(
                                      "spacebreakTable.suggested"
                                    )} (${_vm.$t(
                                      `suffixes.${_vm.currentScope}`
                                    )})`
                                  ) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("th", { staticClass: "text-left" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    `${_vm.$tkey(
                                      "spacebreakTable.override"
                                    )} (${_vm.$t(
                                      `suffixes.${_vm.currentScope}`
                                    )})`
                                  ) +
                                  "\n              "
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("tbody", [
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.$tkey("spacebreakTable.total"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-left" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.formatNumber({
                                      number: _vm.spacebreak.size,
                                      format: "float",
                                    })
                                  ) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td"),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(_vm._s(_vm.$tkey("spacebreakTable.fill"))),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-left" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.formatNumber({
                                      number: _vm.getFillSuggested,
                                      format: "float",
                                    })
                                  ) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-left override" },
                              [
                                _c("rtls-text-field", {
                                  staticClass: "table-input",
                                  attrs: {
                                    "run-validations-on-creation": "",
                                    disabled: _vm.isEditingDisabled,
                                    value: _vm.formatNumber({
                                      number: _vm.spacebreak.fillOverride,
                                      format: "float",
                                    }),
                                    rules: _vm.inputRules,
                                    "server-error": _vm.hasOverrideServerErrors,
                                    "hide-details": "",
                                    width: "100px",
                                  },
                                  on: {
                                    blur: (event) =>
                                      _vm.updateSpacebreakField(
                                        "fillOverride",
                                        event
                                      ),
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return ((event) =>
                                        _vm.updateSpacebreakField(
                                          "fillOverride",
                                          event
                                        )).apply(null, arguments)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.$tkey("spacebreakTable.setAside"))
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-left" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.formatNumber({
                                      number: _vm.getSuggestedSetAside,
                                      format: "float",
                                    })
                                  ) +
                                  "\n              "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-left" }, [
                              _vm._v(_vm._s(_vm.getOverrideSetAside)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _vm.isPalletsSlotsEnabled
                            ? _c("tr", [
                                _c("td", [
                                  _vm._v(_vm._s(_vm.$t("general.pallets"))),
                                ]),
                                _vm._v(" "),
                                _c("td"),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "text-left pallets" },
                                  [
                                    _c("rtls-text-field", {
                                      staticClass: "table-input",
                                      attrs: {
                                        "run-validations-on-creation": "",
                                        disabled: _vm.isEditingDisabled,
                                        value: _vm.formatNumber({
                                          number: _vm.spacebreak.palletsSlots,
                                          format: "float",
                                        }),
                                        rules: _vm.palletInputRules,
                                        "server-error":
                                          _vm.hasPalletsSlotsServerErrors,
                                        "hide-details": "",
                                        width: "100px",
                                      },
                                      on: {
                                        blur: (event) =>
                                          _vm.updateSpacebreakField(
                                            "palletsSlots",
                                            event
                                          ),
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return ((event) =>
                                            _vm.updateSpacebreakField(
                                              "palletsSlots",
                                              event
                                            )).apply(null, arguments)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass: "furniture-panel pl-1 pt-1 pb-1",
              attrs: { cols: "7" },
            },
            [
              _c(
                "draggable",
                {
                  staticClass: "drag-container",
                  class: { disabled: _vm.isEditingDisabled },
                  attrs: {
                    value: _vm.spacebreak.generatedFurnitureIds,
                    group: "spacebreakFurniture",
                    "ghost-class": "ghost",
                    draggable: ".draggable",
                    disabled: _vm.isEditingDisabled,
                  },
                  on: { change: _vm.handleDrop },
                },
                _vm._l(
                  _vm.spacebreak.generatedFurnitureIds,
                  function (furnitureId) {
                    return _c(
                      VCard,
                      {
                        key: furnitureId,
                        staticClass: "draggable ma-1",
                        attrs: { flat: "" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "list-item d-flex" },
                          [
                            _c("span", { staticClass: "furniture-archetype" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.furnitureMapping[furnitureId]) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              VIcon,
                              {
                                staticClass: "list-icon",
                                attrs: {
                                  disabled: _vm.isEditingDisabled,
                                  size: "14",
                                  color: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("removeOne", furnitureId)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              mdi-close-circle\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }
                ),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        [
          _c(VCol, { staticClass: "py-2", attrs: { cols: "5" } }),
          _vm._v(" "),
          _c(
            VCol,
            { staticClass: "pl-1 py-2 action-btns", attrs: { cols: "7" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "border-transparent-btn mx-2 add-all-btn",
                  attrs: {
                    small: "",
                    secondary: "",
                    depressed: "",
                    disabled: _vm.isEditingDisabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("addAll")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$tkey("addAllAvailable")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  staticClass: "transparent-btn mx-2 remove-btn",
                  attrs: {
                    small: "",
                    text: "",
                    depressed: "",
                    disabled: _vm.isEditingDisabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("removeAll")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$tkey("removeAll")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        [
          _c(
            VCol,
            { staticClass: "pt-1 d-flex justify-start", attrs: { cols: "5" } },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$tkey("totalStoreCount")) + "\n      "
              ),
              _c("b", { staticClass: "ml-1" }, [
                _vm._v("\n        " + _vm._s(_vm.totalStoreCount) + "\n      "),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass: "pt-1 d-flex justify-end most-common-furniture",
              attrs: { cols: "7" },
            },
            [
              _c("div", { staticClass: "mr-1" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.$tkey("mostCommonFA")) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("b", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.mostCommonFurniture.name)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mr-2" }, [_vm._v("|")]),
              _vm._v(" "),
              _c("b", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(_vm.mostCommonFurniture.storeCount)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mr-2" }, [
                _vm._v("\n        " + _vm._s(_vm.$tkey("stores")) + "\n      "),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mr-2" }, [_vm._v("|")]),
              _vm._v(" "),
              _c("b", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(_vm.mostCommonFurniture.products)),
              ]),
              _vm._v(" " + _vm._s(_vm.$tkey("products")) + "\n    "),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "confirmDeleteDialog",
        attrs: {
          message: _vm.$tkey("changingSpacebreaksWarning"),
          "show-confirm-message": "",
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function ({ cancel: close }) {
              return [
                _c(
                  VRow,
                  { staticClass: "mt-2" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "mx-auto",
                        attrs: { disabled: _vm.isEditingDisabled, primary: "" },
                        on: {
                          click: function ($event) {
                            ;[_vm.confirmRemoveSpacebreak(), close()]
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tkey("removeSpacebreak")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  VRow,
                  { staticClass: "my-1" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "mx-auto",
                        attrs: { disabled: _vm.isEditingDisabled, text: "" },
                        on: { click: close },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("actions.cancel")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }