<template>
  <div class="main-container-section h-100 d-flex flex-column">
    <div v-if="!shortView" class="subtitle-heading pb-1 my-1 d-flex align-center">
      <span v-if="subtitle">{{ getSubtitle }}</span>
      <slot name="subtitle-section" />
    </div>
    <div class="section-data h-100 w-100 d-flex justify-space-between">
      <slot name="data-section" />
    </div>
  </div>
</template>

<script>
export default {
  localizationKey: 'reportingPage.sections',

  props: {
    // represents whether this report is open on (short) overview page
    shortView: {
      type: Boolean,
      required: false,
      default: false,
    },
    section: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    getSubtitle() {
      return this.subtitle || this.$tkey(`${this.section}.subtitle`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.main-container-section {
  padding: 10px;
  padding-top: 0px;
}

.section-data {
  background: white;
  position: relative;
}

.subtitle-heading {
  border-bottom: 1px solid $reporting-section-divider;
  font-size: 1.2rem;
}
</style>
