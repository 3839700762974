import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        VRow,
        [
          _c(
            VCol,
            { staticClass: "pa-0", attrs: { justify: "start" } },
            [
              _c("stepper", {
                ref: "stepper",
                attrs: { "step-data": _vm.steps },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showPreviousExtracts
            ? _c(
                VCol,
                {
                  staticClass: "pt-0 pr-0",
                  attrs: { justify: "start", cols: "4" },
                },
                [
                  _c("previous-extract-reports", {
                    attrs: { reports: _vm.reports },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.closeUnsavedDataModal,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }