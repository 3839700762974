import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "optimiser-settings" },
    [
      _c(
        VForm,
        {
          staticClass: "optimiser-settings__form",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.saveOptimiserSettings.apply(null, arguments)
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            VCol,
            {
              staticClass:
                "d-flex flex-row align-center justify-start pl-0 pt-2 pb-2",
              class: [_vm.hasCustomerSegmentsEnabled ? "col-5" : "col-6"],
            },
            [
              _c("span", { staticClass: "optimiser-settings__label" }, [
                _vm._v(_vm._s(_vm.$tkey("optimiseAccording"))),
              ]),
              _vm._v(" "),
              _c("assortment-tooltip", {
                attrs: {
                  title: _vm.$t("tooltips.optimiseAccordingTo.title"),
                  "tooltip-sections": _vm.optimiseAccordingToTooltipSections,
                },
              }),
              _vm._v(" "),
              _c("rtls-select", {
                attrs: {
                  disabled: _vm.validatingOptimiseOptions || _vm.readOnly,
                  value: _vm.optimiseAccordingTo,
                  items: _vm.sizeSelectItems,
                  "error-messages": _vm.optimiseAccordingToErrorMessage,
                  placeholder: _vm.$t("sizeTypes.noOption"),
                  "append-outer-icon":
                    _vm.sizeTypeIconMap[_vm.optimiseAccordingTo] ||
                    "$empty-cube",
                  width: "250px",
                },
                on: { change: _vm.selectSetting },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass:
                "d-flex flex-row align-center justify-space-between pt-2 pb-2",
              class: { "col-5": !_vm.hasCustomerSegmentsEnabled },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("span", { staticClass: "optimiser-settings__label" }, [
                    _vm._v(_vm._s(_vm.$tkey("categoryStrategy"))),
                  ]),
                  _vm._v(" "),
                  _c("assortment-tooltip", {
                    attrs: {
                      title: _vm.$t("tooltips.categoryStrategy.title"),
                      "tooltip-sections": _vm.categoryStrategyTooltipSections,
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-5" }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex",
                  class: [
                    _vm.hasCustomerSegmentsEnabled
                      ? "flex-column"
                      : "align-center",
                  ],
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "optimiser-settings__label",
                      class: { "mb-1": _vm.hasCustomerSegmentsEnabled },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tkey("unitVolume")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("rtls-text-field", {
                    staticClass: "optimiser-settings__input",
                    attrs: {
                      disabled: _vm.readOnly,
                      grey: "",
                      "single-line": "",
                      rules: _vm.rules,
                    },
                    model: {
                      value: _vm.unitVolume,
                      callback: function ($$v) {
                        _vm.unitVolume = _vm._n($$v)
                      },
                      expression: "unitVolume",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex",
                  class: [
                    _vm.hasCustomerSegmentsEnabled
                      ? "flex-column"
                      : "align-center",
                  ],
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "optimiser-settings__label",
                      class: { "mb-1": _vm.hasCustomerSegmentsEnabled },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tkey("sales")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("rtls-text-field", {
                    staticClass: "optimiser-settings__input",
                    attrs: {
                      disabled: _vm.readOnly,
                      grey: "",
                      "single-line": "",
                      rules: _vm.rules,
                    },
                    model: {
                      value: _vm.sales,
                      callback: function ($$v) {
                        _vm.sales = _vm._n($$v)
                      },
                      expression: "sales",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex",
                  class: [
                    _vm.hasCustomerSegmentsEnabled
                      ? "flex-column"
                      : "align-center",
                  ],
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "optimiser-settings__label",
                      class: { "mb-1": _vm.hasCustomerSegmentsEnabled },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tkey("margin")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("rtls-text-field", {
                    staticClass: "optimiser-settings__input",
                    attrs: {
                      disabled: _vm.readOnly,
                      grey: "",
                      "single-line": "",
                      rules: _vm.rules,
                    },
                    model: {
                      value: _vm.margin,
                      callback: function ($$v) {
                        _vm.margin = _vm._n($$v)
                      },
                      expression: "margin",
                    },
                  }),
                  _vm._v(" "),
                  _vm.usesMarginWithoutFunding
                    ? _c("assortment-tooltip", {
                        attrs: {
                          title: _vm.$t("general.warning"),
                          "tooltip-sections": _vm.margingWithoutFundingSections,
                          width: 500,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.hasCustomerSegmentsEnabled
                ? _c("div", { staticClass: "d-flex align-center" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-column mr-4" },
                      [
                        _c(
                          "span",
                          { staticClass: "optimiser-settings__label mb-1" },
                          [_vm._v(_vm._s(_vm.$tkey("customerSegment")))]
                        ),
                        _vm._v(" "),
                        _c("rtls-select", {
                          attrs: {
                            disabled: _vm.readOnly,
                            value: _vm.customerSegmentKey,
                            items: _vm.customerSegmentsOptions,
                            width: "200px",
                          },
                          on: { change: _vm.selectCustomerSegment },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c(
                        "span",
                        { staticClass: "optimiser-settings__label mb-1" },
                        [_vm._v(_vm._s(_vm.$tkey("maximumCap")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            VTextField,
                            {
                              staticClass:
                                "optimiser-settings__input rtls-text-field rtls-text-field--grey align-center",
                              attrs: {
                                disabled:
                                  _vm.readOnly || !_vm.customerSegmentKey,
                                "hide-details": "",
                                "single-line": "",
                              },
                              model: {
                                value: _vm.maxCap,
                                callback: function ($$v) {
                                  _vm.maxCap = _vm._n($$v)
                                },
                                expression: "maxCap",
                              },
                            },
                            [
                              _c("template", { slot: "append-outer" }, [
                                _c("span", { staticClass: "d-inline-block" }, [
                                  _vm._v("%"),
                                ]),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("error-triangle", {
                            staticClass: "ml-1 mr-1",
                            attrs: { errors: _vm.errors },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass:
                "d-flex flex-row align-center justify-end pr-0 pt-2 pb-2 col-1",
            },
            [
              _c(
                VBtn,
                {
                  attrs: {
                    "data-id-e2e": "btnSaveOptimiserSetting",
                    primary: "",
                    disabled: _vm.isSaveDisabled,
                    type: "submit",
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("actions.save")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }