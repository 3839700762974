import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.selectedScenario._id && _vm.selectedWorkpackage
    ? _c(
        VContainer,
        { attrs: { fluid: "" } },
        [
          _c(
            VLayout,
            { staticClass: "main-layout", attrs: { column: "" } },
            [
              _c(
                VFlex,
                { staticClass: "d-flex flex-column" },
                [
                  _c("toolbar", {
                    attrs: {
                      "back-nav-route": "home",
                      "enable-notes": "",
                      "enable-scenario-selection": "",
                      "toolbar-tabs": _vm.toolbarTabs,
                      workpackage: _vm.selectedWorkpackage,
                    },
                  }),
                  _vm._v(" "),
                  _c("router-view", { key: _vm.routerKey }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }