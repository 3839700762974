import authConfig from '@sharedModules/config/staticAuth';

const buildExcelLink = fileId => `/api/file/${fileId}?type=excel`;

/**
 * Default parser for ag-grid table cell values
 * @param {string} downloadToken - Unique token required for download url
 * @param {Object} fileData - An object containing file data: fileId, fileUrl and fileName
 */
const handleDownload = async (downloadToken, fileData) => {
  const { fileId, fileName, fileUrl } = fileData;
  // Return if resource url is missing and no fileId has been provided
  if (!fileUrl && !fileId) return;

  const url = fileUrl || buildExcelLink(fileId);
  const separator = url.includes('?') ? '&' : '?';
  const finalDownloadUrl = `${url}${separator}${
    authConfig.cookie.tokenName
  }=${downloadToken}&filename=${fileName}`;
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.href = finalDownloadUrl;
  link.setAttribute('type', 'hidden');
  link.setAttribute('download', true);
  link.click();
  link.remove();
};

export default {
  handleDownload,
};
