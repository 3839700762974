<template>
  <v-card class="step-tab-panel" flat>
    <v-container class="pa-0 ma-0 switching-container d-flex flex-column">
      <scenario-measuring-header
        :header-text="$tkey('infoNote')"
        :tooltip-link="tooltipLink"
        :show-job="jobType"
        :disabled="isEditingDisabled"
        @trigger-job="triggerJob"
      />
      <v-row v-if="isJobComplete" no-gutters class="cann-group-container flex-grow-1">
        <v-col :cols="2">
          <cann-group-menu
            v-if="isJobComplete"
            :selected-cann-group="selectedCannGroup ? selectedCannGroup.key : null"
            @onCannGroupSelected="selectCannGroup"
          />
        </v-col>

        <v-col :cols="10">
          <v-container v-if="isJobComplete" class="ma-0 pa-0 controls">
            <v-row no-gutters class="d-flex justify-start align-center">
              <div :cols="2">
                <rtls-toggle
                  v-model="viewBySelection"
                  :left-toggle="toggle.leftToggle"
                  :right-toggle="toggle.rightToggle"
                />
              </div>
              <div>
                <div v-if="viewBySelection !== 'product'" class="attribute-level-title">
                  <p>{{ $tkey('attributeLevelSwitching') }}:</p>
                </div>
              </div>
              <div>
                <rtls-select
                  v-if="viewBySelection !== 'product'"
                  v-model="selectedAttribute"
                  :items="scenarioAttributesList"
                  width="180px"
                  :placeholder="$tkey('selectAttribute')"
                />
              </div>
            </v-row>
          </v-container>

          <v-divider />

          <div v-if="isJobComplete">
            <switching-matrix
              v-if="selectedCannGroup"
              :key="matrixKey"
              :cann-group="selectedCannGroup"
              :matrix-options="matrixOptions"
            />
          </div>
        </v-col>
      </v-row>

      <v-row v-if="!isJobComplete && requiresAnalyticsText">
        <div v-if="!isAnyJobRunning" class="no-matrices">
          {{ requiresAnalyticsText }}
        </div>

        <progress-bar
          v-if="isAnyJobRunning"
          :percentage="jobProgress"
          :message="$t('messages.inProgress')"
          :status="jobStatus"
        />
      </v-row>
      <div class="page-actions-container">
        <page-actions
          show-export
          :export-options="exportOptions"
          :save-disabled="isEditingDisabled"
          :is-discard-enabled="!isEditingDisabled"
          :is-export-enabled="isExportButtonEnabled"
          :is-custom-export="viewBySelection === 'attribute'"
          :show-save-button="false"
          :show-discard="false"
          :has-data-errors="true"
          :has-data-changes="false"
          @productLevelExport="onExport(switchingReport.products)"
          @topPartnersExport="onExport(switchingReport.topPartners)"
          @export="onExport(switchingReport.attributes)"
        />
      </div>
    </v-container>
  </v-card>
</template>

<script>
import { map, sortBy } from 'lodash';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import jobApiEnums from '@enums/jobapi';
import switchingReport from '@enums/switching-report';
import downloadFile from '../mixins/download-file';

export default {
  localizationKey: 'switchingBehaviour',
  mixins: [downloadFile],
  props: {
    requiresAnalyticsText: {
      type: String,
      required: false,
      default: '',
    },
    tooltipLink: {
      type: String,
      required: false,
      default: null,
    },
    jobType: {
      type: String,
      required: false,
      default: jobApiEnums.jobTypes.switching,
    },
  },
  data() {
    return {
      switchingReport,
      selectedAttribute: null,
      viewBySelection: 'attribute',
      selectedCannGroup: null,
      scenarioAttributes: [],
      scenarioAttributesList: [],
      jobApiEnums,
      toggle: {
        leftToggle: {
          value: 'attribute',
          translation: this.$tkey('attributeBtn'),
        },
        rightToggle: {
          value: 'product',
          translation: this.$tkey('productBtn'),
        },
      },
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('scenarioProducts', ['scenarioProducts']),
    ...mapGetters('scenarios', ['jobStatuses', 'isJobRunning']),
    ...mapGetters('switchingMatrices', ['switchingStatus']),
    ...mapGetters('workpackages', ['isSimpleSwapsWP']),

    matrixOptions() {
      let attribute = this.selectedAttribute;
      if (this.viewBySelection === 'product') {
        attribute = null;
      }
      return {
        selectedAttribute: attribute,
        displayOption: this.viewBySelection,
      };
    },

    matrixKey() {
      const options = this.matrixOptions;
      return `${this.selectedCannGroup.key}-${options.displayOption}-${options.selectedAttribute}`;
    },

    jobProgress() {
      if (this.isPrepareSwapsJobRunning) {
        return this.jobStatuses.prepareSwaps.progress;
      }
      return this.jobStatuses.switching.progress;
    },

    jobStatus() {
      if (this.isPrepareSwapsJobRunning) {
        return this.jobStatuses.prepareSwaps.status;
      }
      return this.jobStatuses.switching.status;
    },

    isSwitchingJobRunning() {
      return this.isJobRunning('switching');
    },

    isPrepareSwapsJobRunning() {
      return this.isJobRunning('prepareSwaps');
    },

    isAnyJobRunning() {
      return this.isSwitchingJobRunning || this.isPrepareSwapsJobRunning;
    },

    isSwitchingComplete() {
      return (
        !this.isSwitchingJobRunning &&
        this.jobStatuses.switching.status === jobApiEnums.jobStatuses.finished
      );
    },

    isPrepareSwapsComplete() {
      return (
        !this.isPrepareSwapsJobRunning &&
        this.jobStatuses.prepareSwaps.status === jobApiEnums.jobStatuses.finished
      );
    },

    isJobComplete() {
      return this.isSwitchingComplete || this.isPrepareSwapsComplete;
    },

    exportOptions() {
      if (this.viewBySelection === 'product') {
        return [
          { name: this.$tkey('productLevel'), value: 'productLevelExport' },
          { name: this.$tkey('topPartners'), value: 'topPartnersExport' },
        ];
      }

      return [];
    },

    isExportButtonEnabled() {
      if (this.isAnyJobRunning) return false;

      if (this.viewBySelection === 'attribute') {
        return !!this.selectedAttribute;
      }

      return true;
    },

    isEditingDisabled() {
      if (this.isSimpleSwapsWP)
        return !this.hasPermission(this.userPermissions.canTriggerPrepareSwaps);
      return !this.hasPermission(this.userPermissions.canEditSwitching);
    },
  },

  async created() {
    const scenarioProducts = await this.fetchScenarioProducts(this.selectedScenario);
    // check if it's needed here - previously was side effect of fetchScenarioProducts
    this.setScenarioProducts(scenarioProducts);

    this.scenarioAttributes = map(this.scenarioProducts, sp => ({
      customAttributes: sp.customAttributes,
      productKey: sp.productKey,
    }));
    this.scenarioAttributesList = sortBy(
      map(this.selectedScenario.customAttributes, 'name'),
      item => item.toLowerCase()
    );
  },

  methods: {
    ...mapActions('scenarioProducts', ['fetchScenarioProducts']),
    ...mapActions('switchingMatrices', ['exportSwitching']),
    ...mapActions('scenarios', ['loadScenario', 'updateScenario']),
    ...mapMutations('scenarioProducts', ['setScenarioProducts']),

    selectCannGroup(cg) {
      this.selectedCannGroup = cg;
    },

    async onExport(level) {
      const options = {
        type: level,
        selectedAttribute: this.selectedAttribute,
        translations: {
          metadata: this.$tkey('excel.metadata'),
          workpackageName: this.$tkey('excel.workpackageName'),
          scenarioName: this.$tkey('excel.scenarioName'),
          extractTime: this.$tkey('excel.extractTime'),
          user: this.$tkey('excel.user'),
          products: this.$tkey('excel.products'),
          cannGroup: this.$tkey('excel.cannGroup'),
          cannGroups: this.$tkey('excel.cannGroups'),
          productIdentifier: this.$tkey('excel.productIdentifier'),
          tab: this.$tkey('excel.tab'),
          overview: this.$tkey('excel.overview'),
          description: this.$tkey('excel.description'),
          attribute: this.$tkey('excel.attribute'),
          rank: this.$tkey('excel.rank'),
          probability: this.$tkey('excel.probability'),
          newProductsNotIncludedNote: this.$tkey('excel.newProductsNotIncludedNote'),
        },
      };

      const { buffer, filename } = await this.exportSwitching(options);
      this.downloadFromBuffer(buffer, filename);
    },

    triggerJob(jobType) {
      const { prepareSwaps, switching } = jobApiEnums.jobTypes;

      const events = {
        [switching]: 'run-switching',
        [prepareSwaps]: 'run-prepare-swaps',
      };

      this.$emit(events[jobType]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.switching-container {
  max-width: none;
  height: 100%;
  .header {
    border-bottom: 1px solid $assortment-divider-colour;
  }

  .controls {
    max-width: none;
  }

  .cann-group-container {
    overflow: auto;
    height: $tab-panel-height;
  }

  .attribute-level-title {
    p {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 0 !important;
      margin-right: 7px;
    }
  }

  .v-item-group {
    margin-right: 5px;
  }

  .info-note {
    font-weight: bold;
    font-size: 1.2rem;
    margin-right: 5px;
  }

  .v-input {
    margin-right: 5px;
  }

  .toggle {
    padding: 12px;
    height: 48px;
  }

  .no-matrices {
    margin-top: 60px;
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
  }
}
</style>
