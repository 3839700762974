<template>
  <popup
    id="main-alert"
    :title="$t('general.warning')"
    :is-open="hasMessagesToShow"
    width="40%"
    @close="dismiss"
  >
    <ol>
      <li v-for="(message, index) in messageContents" :key="index">
        {{ message }}
      </li>
    </ol>
  </popup>
</template>

<script>
import { isEmpty, map, isPlainObject, isArray } from 'lodash';
import { mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('alerts', ['messages']),
    hasMessagesToShow() {
      return !isEmpty(this.messages);
    },
    messageContents() {
      // assume this.messages is always array: webtool/client/js/store/modules/alerts.js
      return map(this.messages, message => {
        if (isArray(message.data)) {
          // attempt to translate message.data items first if those are translation keys
          const translatedData = map(message.data, m => this.$t(m));
          return this.$t(message.messageKey, [translatedData.join(', ')]);
        }

        if (isPlainObject(message.data)) return this.$t(message.messageKey, message.data);

        // case where message.data is string, null etc.
        return this.$t(message.messageKey, this.$t(message.data));
      });
    },
  },
  methods: {
    ...mapMutations('alerts', ['dismiss']),
  },
};
</script>

<style lang="scss" scoped>
li {
  font-size: 1.2rem;
}
</style>
