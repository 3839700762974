var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "template-card d-flex flex-column",
      class: _vm.workpackageSetupJobStatus,
    },
    [_c("template-panel", { attrs: { workpackage: _vm.item } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }