<template>
  <v-card class="step-tab-panel" flat>
    <workpackage-stores :from-template="true" />
  </v-card>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
