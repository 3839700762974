import numeral from 'numeral';
import { escapeRegExp, isFinite, get, trim } from 'lodash';
import i18nConfig from '@sharedModules/config/static-i18n-config';
import numberFormatUtils from '@sharedModules/data/utils/number-format-utils';
import i18n from '../vue-i18n';
import { encaseNegativesInParentheses } from '../../lang/customFormatters';

const { displayNegativesInParentheses } = i18nConfig;

const helpers = {
  escapedThousandsSeparator: () => {
    const thousandsSeparator = get(numeral.localeData(), 'delimiters.thousands', ',');
    return escapeRegExp(thousandsSeparator);
  },
  escapedDecimalSeparator: () => {
    const decimalPoint = get(numeral.localeData(), 'delimiters.decimal', '.');
    return escapeRegExp(decimalPoint);
  },
  thousandsSeparatorCaptureGroup: () => {
    return `(?:\\d{1,3}(?:${helpers.escapedThousandsSeparator()}\\d{3})*)`;
  },
  decimalPointCaptureGroup: () => {
    // must be at end of string
    return `(${helpers.escapedDecimalSeparator()}\\d+)?$`;
  },
};

const regex = {
  // only matches correctly thousand separated numbers e.g. 1,234 will pass. 1,2345 will not pass.
  localeNumericStrict: () => {
    const format = `^-?${helpers.thousandsSeparatorCaptureGroup()}${helpers.decimalPointCaptureGroup()}`;
    return new RegExp(format);
  },
  // matches unseparated numbers. e.g 1234 will pass. 1,234 will not.
  localeNumeric: () => {
    const format = `^-?[${helpers.escapedThousandsSeparator()}\\d]+${helpers.decimalPointCaptureGroup()}`;
    return new RegExp(format);
  },
};

const validNumericStringStrict = value => {
  const strValue = String(value).trim();
  if (['', undefined].includes(strValue)) return false;
  return regex.localeNumericStrict().test(strValue);
};

const validNumericString = value => {
  const strValue = String(value).trim();
  if (['', undefined].includes(strValue)) return false;
  return regex.localeNumeric().test(strValue);
};

/**
 * Cast number to localized string using i18n.$n (numeral monkey patch) with additional custom formatting
 * see webtool/client/js/vue-i18n.js
 * @param {Object} params - Options for formatting a number
 * @param {int|float} params.number - the numeric value to format
 * @param {string} params.format - name of the format to apply (from numberFormats.js)
 * @param {boolean} params.zeroAsDash - flag to control returning `-` for zero/null/NaN values
 * @param {boolean} params.nullAsDash - flag to control returning `-` for null/NaN values (0 will remain 0)
 * @returns {string} the formatted number
 */
const formatNumber = ({
  number,
  format = 'floatNonRounded',
  zeroAsDash = false,
  nullAsDash = false,
}) => {
  // AOV3-1423 TODO: move all formatting to numeral configs
  // when overrides to format are required, just use i18n.n with valid format
  // e.g. numeral.zeroFormat('-')
  if (zeroAsDash && !number) return '-';
  if (nullAsDash && !number && number !== 0) return '-';
  const baseTranslation = i18n.n(format, number);

  // Any manipulations of the base translation should be done afterwards and defined in customFormatters.js
  if (displayNegativesInParentheses && number < 0) {
    return encaseNegativesInParentheses(baseTranslation);
  }
  return baseTranslation;
};

/**
 * Parse a value to string if it's numeric
 * @param {*} value - the value to parse
 * @param {string} format - name of the format to apply (from numberFormats.js)
 * @returns {string|*} the formatted string if passed a number or the original value
 */
const formatNumberIfNumeric = ({ value, format = 'floatNonRounded' }) => {
  return isFinite(value) ? formatNumber({ number: value, format }) : trim(value);
};

// We need to send numeral as a param since the locale can't be configured until this moment in shared-modules
const formatStringToNumber = stringFormatNumber =>
  numberFormatUtils.formatStringToNumber(stringFormatNumber, numeral);

/**
 * Parse a string to number if it's numeric
 * @param {string} stringFormatNumber - the string value to parse
 * @returns {number|string} the parsed number or the original string
 * stricter than formatStringToNumber, only accepts properly formatted numbers
 */
const stringToNumberIfNumeric = stringFormatNumber => {
  return regex.localeNumeric().test(String(stringFormatNumber))
    ? formatStringToNumber(stringFormatNumber)
    : stringFormatNumber;
};

export default {
  formatNumber,
  formatNumberIfNumeric,
  formatStringToNumber,
  stringToNumberIfNumeric,
  validNumericStringStrict,
  validNumericString,
  regex,
};
