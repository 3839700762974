import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _vm.isLink && _vm.hasOnClick ? "actionable-link" : "div",
    {
      tag: "component",
      staticClass: "d-contents",
      class: _vm.iconComponent ? _vm.params.classes : "",
      attrs: { to: _vm.params.to, "on-click": _vm.linkClickHandler },
    },
    [
      _vm.iconComponent
        ? _c(
            "span",
            { staticClass: "d-contents", on: { click: _vm.iconClickHandler } },
            [
              _c(_vm.iconComponent, {
                tag: "component",
                class: _vm.params.classes,
              }),
            ],
            1
          )
        : [
            !_vm.params.isLoading
              ? _c(
                  VIcon,
                  {
                    class: _vm.params.classes,
                    attrs: {
                      disabled: _vm.params.isDisabled,
                      size: _vm.iconSize,
                    },
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(`$${_vm.params.value}`) + "\n    "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.params.isLoading
              ? _c(VProgressCircular, {
                  staticClass: "progress-circular",
                  attrs: {
                    color: "primary",
                    indeterminate: "",
                    size: 20,
                    width: 2,
                  },
                })
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }