<template>
  <span v-if="lastUpdated" class="updated-text">
    <strong>{{ $t('scenarioManagementPanel.updated') }}: </strong>
    {{ lastUpdated | formatDate(getDateFormats.longWithTime) }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    lastUpdated: {
      required: false,
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters('context', ['getDateFormats']),
  },
};
</script>
