import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "column-wrapper" }, [
    _c("div", { staticClass: "column" }, [
      _vm.isEditable
        ? _c(
            "div",
            { staticClass: "pb-1 header" },
            [
              _c("rtls-text-field", {
                staticClass: "text-field",
                attrs: {
                  "run-validations-on-creation": "",
                  width: "140px",
                  rules: _vm.rules,
                },
                on: { input: _vm.updateName },
                model: {
                  value: _vm.name,
                  callback: function ($$v) {
                    _vm.name = $$v
                  },
                  expression: "name",
                },
              }),
              _vm._v(" "),
              _c(
                VIcon,
                {
                  staticClass: "mt-2",
                  attrs: { size: "22" },
                  on: { click: _vm.deleteGroup },
                },
                [_vm._v("$trash")]
              ),
            ],
            1
          )
        : _c("div", { staticClass: "pb-1 available-values-header header" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$tkey("availableValues")) + "\n    "
            ),
          ]),
      _vm._v(" "),
      _vm.attrGroup.values
        ? _c(
            "div",
            { staticClass: "list-group ma-0 pa-0" },
            [
              _c(
                "draggable",
                { attrs: { list: _vm.attrGroup.values, group: "attrs" } },
                [
                  _vm._l(_vm.attrGroup.values, function (attrValue) {
                    return _c(
                      "div",
                      {
                        key: attrValue,
                        staticClass:
                          "pl-2 d-flex align-center justify-start attribute-item w-100",
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(attrValue) + "\n          "
                        ),
                        _c(
                          "div",
                          { staticClass: "icon mt-1" },
                          [
                            _vm.isEditable
                              ? _c(
                                  VIcon,
                                  {
                                    staticClass: "remove",
                                    attrs: {
                                      size: "14",
                                      title: _vm.$tkey("removeAttribute"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteAttr(attrValue)
                                      },
                                    },
                                  },
                                  [_vm._v("close")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(VIcon, { attrs: { size: "10" } }, [
                              _vm._v(
                                "\n              $cross-move\n            "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "pl-2 pr-2 d-flex align-center justify-start attribute-item drag-here",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tkey("dragValuesHere")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.attrGroup.values.length && !_vm.isEditable
                    ? _c(
                        VAlert,
                        {
                          attrs: { slot: "footer", type: "info", text: "" },
                          slot: "footer",
                        },
                        [_vm._v(_vm._s(_vm.$tkey("noRemainingAttributes")))]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "btn-wrapper" },
      [
        _vm.isEditable
          ? _c(
              VBtn,
              {
                staticClass: "ma-2",
                attrs: { text: "", disabled: _vm.disableAddAllRemaining },
                on: { click: _vm.addAllRemaining },
              },
              [_vm._v(_vm._s(_vm.$tkey("addAllRemainingValues")))]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isEditable
          ? _c(
              VBtn,
              {
                staticClass: "ma-2",
                attrs: { disabled: _vm.disableAddAllRemaining, text: "" },
                on: { click: _vm.distributeRemaining },
              },
              [_vm._v(_vm._s(_vm.$tkey("distributeRemainingValues")))]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }