import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sidepanel", class: { open: _vm.isPanelExpanded } },
    [
      _c("div", { staticClass: "sidepanel__container" }, [
        _c(
          "div",
          { staticClass: "sidepanel__toggle" },
          [
            _c(
              VBtn,
              { staticClass: "toggle-btn", on: { click: _vm.toggleExpand } },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.toggleBtnText) + "\n\n        "
                ),
                _c(VIcon, { attrs: { size: "24" } }, [
                  _vm._v(_vm._s(_vm.toggleIcon)),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "sidepanel__header" }, [
          _c("h4", [_vm._v(_vm._s(_vm.title))]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "sidepanel__content" },
          [_vm._t("default")],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }