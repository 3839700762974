import Vue from 'vue';
import axios from 'axios';
import to from 'await-to-js';
import { get, findIndex, keyBy, isNaN } from 'lodash';

import * as SORT_DIRECTION from '@enums/sort-direction';

const bundleDefaultFields = [
  'name',
  'description',
  'createdBy',
  'creationDate',
  'lastModifiedDate',
  'targetLaunchDate',
];

const store = {
  namespaced: true,
  state: {
    bundles: [],
    archivedBundles: [],
    bundlesTotalCount: 0,
    archivedBundlesTotalCount: 0,
    archivedBundlesCurrentPage: 1,
    selectedBundle: null,
    isWorkpackagePanelExpanded: false,
    loading: false,
    loadingArchivedBundles: false,
  },

  getters: {
    getBundleById: state => id =>
      [...state.bundles, ...state.archivedBundles].find(el => el._id === id),
    bundlesById: state => keyBy([...state.bundles, ...state.archivedBundles], '_id'),
    archivedBundles: state => state.archivedBundles,
    archivedBundlesTotalCount: state => state.archivedBundlesTotalCount,
  },

  mutations: {
    setBundles(state, bundles) {
      state.bundles = bundles;
    },

    setArchivedBundles(state, data) {
      const { bundles, totalCount } = data;
      state.archivedBundles = bundles;
      state.archivedBundlesTotalCount = totalCount;
    },

    setSelectedBundle(state, bundle) {
      state.selectedBundle = bundle;
    },

    setWorkpackagePanelExpanded(state, value) {
      state.isWorkpackagePanelExpanded = value;
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    updateBundle(state, { bundleId, updates }) {
      const bundleToUpdateIndex = findIndex(state.bundles, b => b._id === bundleId);
      Vue.set(state.bundles, bundleToUpdateIndex, updates);
    },

    setArchivedBundlesCurrentPage(state, page) {
      state.archivedBundlesCurrentPage = page;
    },

    setLoadingArchivedBundles(state, isLoading) {
      state.loadingArchivedBundles = isLoading;
    },

    deleteBundleById(state, bundleId) {
      const bundles = [];
      state.bundles.forEach(bundle => {
        if (bundle._id === bundleId) return;
        bundles.push(bundle);
      });
      state.bundles = bundles;
    },
  },

  actions: {
    async fetchBundles({ commit }, customParams = {}) {
      commit('setLoading', true);

      const pickedFields = bundleDefaultFields.concat(get(customParams, 'pickExtra', []));
      const { limit, offset, regexSearch } = customParams;
      const query = {
        archived: { $in: [null, false] },
      };

      let params = {
        sortField: 'creationDate',
        sortDirection: SORT_DIRECTION.ascending,
        pick: pickedFields,
        where: query,
        regexSearch,
      };

      if (!isNaN(limit) && !isNaN(offset)) {
        params = { ...params, ...{ limit, offset } };
      }

      // override defaults
      if (customParams.pick) params.pick = customParams.pick;
      const [err, result] = await to(axios.get('/api/bundles', { params }));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      const sortedBundles = result.data.bundles.sort((a, b) =>
        a.creationDate.localeCompare(b.creationDate)
      );

      commit('setBundles', sortedBundles);
    },

    async fetchArchivedBundles({ commit }, customParams = {}) {
      commit('setLoadingArchivedBundles', true);

      const pickedFields = [
        ...bundleDefaultFields.concat(get(customParams, 'pickExtra', [])),
        ...['archived', 'archiveDate'],
      ];
      const { limit, offset, regexSearch } = customParams;
      const query = {
        archived: true,
      };

      let params = {
        sortField: 'creationDate',
        sortDirection: SORT_DIRECTION.ascending,
        pick: pickedFields,
        where: query,
        regexSearch,
      };

      if (!isNaN(limit) && !isNaN(offset)) {
        params = { ...params, ...{ limit, offset } };
      }

      // override defaults
      if (customParams.pick) params.pick = customParams.pick;
      const [err, result] = await to(axios.get('/api/bundles', { params }));
      commit('setLoadingArchivedBundles', false);
      if (err) throw new Error(err.message);
      const sortedBundles = result.data.bundles.sort((a, b) =>
        a.creationDate.localeCompare(b.creationDate)
      );

      commit('setArchivedBundles', {
        bundles: sortedBundles,
        totalCount: result.data.totalCount,
      });
    },

    async updateBundle({ commit }, { bundleId, updates }) {
      if (updates._id !== bundleId) {
        throw new Error('mismatched bundleId on update');
      }
      commit('setLoading', true);
      const [err, result] = await to(axios.patch(`/api/bundles/${bundleId}`, updates));
      commit('setLoading', false);
      if (err) {
        const message = get(err, ['response', 'data', 'message'], err.message);
        throw new Error(message);
      }

      if (result) commit('updateBundle', { bundleId, updates: result.data.updates });
    },

    toggleWorkpackagePanelExpanded({ commit, state }, { bundle }) {
      if (
        !bundle ||
        ((state.selectedBundle || {})._id === bundle._id && state.isWorkpackagePanelExpanded)
      ) {
        commit('setSelectedBundle', null);
        commit('setWorkpackagePanelExpanded', false);
      } else {
        commit('setSelectedBundle', bundle);
        commit('setWorkpackagePanelExpanded', true);
      }
    },
  },
};

export default store;
