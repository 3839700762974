import { compact, find, get, isNil } from 'lodash';

const moveProductToNewSpacebreak = async function(
  changedDataElement,
  spacebreakId,
  {
    updateAssortmentCanvasProductById,
    updateCanvasProduct,
    showError,
    spacebreaksOrderedObject,
    getAssociatedPallets,
    getIndexedCanvasProducts,
  },
  message
) {
  if (changedDataElement.currentSpacebreakId === spacebreakId) {
    updateAssortmentCanvasProductById(changedDataElement);
    return;
  }

  // If using pallets make sure that russian doll is respected
  const newSpacebreak = spacebreaksOrderedObject[spacebreakId];
  const associatedPallets = await getAssociatedPallets({
    productKeyDisplay: changedDataElement.productKeyDisplay,
  });
  // Map the pallets to the needed update to clear spacebreaks
  const palletUpdates = associatedPallets.reduce((result, pallet) => {
    // Currently only the scenario product for pallet is used - need to grab the canvas product for pallet
    const canvasPallet = getIndexedCanvasProducts[pallet.productKey];
    const palletSpacebreakId = canvasPallet.currentSpacebreakId;
    // No need to clear if the currentSpacebreakId is already null
    if (palletSpacebreakId) {
      const palletSpacebreak = spacebreaksOrderedObject[palletSpacebreakId];
      // Clear the pallet sb if it is smaller than the updated SB to respect the russian doll model
      if (palletSpacebreak.size < newSpacebreak.size) {
        result.push(
          updateCanvasProduct({
            _id: canvasPallet._id,
            currentSpacebreakId: null,
            locallyUpdatedProduct: {
              ...canvasPallet,
              currentSpacebreakId: null,
            },
          })
        );
      }
    }
    return result;
  }, []);

  // If moving to a smaller spacebreak - then check if pallets need have sb cleared
  if (palletUpdates.length) {
    // Clear the pallets & notify the user
    await Promise.all(palletUpdates);
    // Show Snackbar with message that pallets sb have been cleared
    showError(message);
  }

  // locallyUpdatedProduct is meant to be used before the changes are done on the server,
  // to avoid product dragging flickering
  const locallyUpdatedProduct = {
    ...changedDataElement,
    currentSpacebreakId: spacebreakId,
  };
  await updateCanvasProduct({
    _id: changedDataElement._id,
    currentSpacebreakId: spacebreakId,
    locallyUpdatedProduct,
  });
};

const selectedSpacebreak = function(spacebreaksShortNames, currentSpacebreakId) {
  return find(spacebreaksShortNames, { value: currentSpacebreakId });
};

const futureProductIdString = function(kpku, futureId) {
  const identifiers = compact([kpku, futureId].filter(el => !isNil(el)));
  if (!identifiers.length) return '-';
  return identifiers.length > 1 ? identifiers.join('/') : identifiers.toString();
};

function getImageUrl(clientConfig, product) {
  const { extension, fieldIdentifier = 'productKeyDisplay' } = clientConfig.images;
  const internalSourceEnabled = get(clientConfig, 'images.internalSourceEnabled', true);

  const endpoint = internalSourceEnabled ? '/api/products/image' : clientConfig.images.cdnImagesUrl;

  const filename = product[fieldIdentifier] || product.producKeyDisplay;

  return `${endpoint}/${filename}${extension}`;
}

const defaultScrollOptions = {
  behaviour: 'smooth',
  inline: 'center',
  block: 'center',
};

const scrollToProductInCanvas = function(product, options = defaultScrollOptions) {
  const productTileId = `tile-product-${product.productKey}`;
  // Setting the first child, `tile-product-{pkey}` is a wrapper over the main tile element.
  // This is important to draw the blue border around the product image after search correctly.
  const productDocument = document.getElementById(productTileId).firstChild;

  // scroll user to where tile is
  productDocument.scrollIntoView(options);

  return productDocument;
};

const addActiveSearchBorderToProductElement = function(productElement) {
  // Sets an .active-search class to element and removes it after 1s.
  productElement.classList.add('active-search');
  setTimeout(() => {
    productElement.classList.remove('active-search');
  }, 1000);
};

export default {
  moveProductToNewSpacebreak,
  selectedSpacebreak,
  futureProductIdString,
  getImageUrl,
  scrollToProductInCanvas,
  addActiveSearchBorderToProductElement,
};
