<template>
  <v-menu
    ref="notesMenu"
    v-model="showNotes"
    :content-class="notesType"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs, value }">
      <v-btn
        v-bind="attrs"
        :disabled="disabled"
        icon
        text
        color="primary"
        v-on="wrapOpenEvents(on, value)"
      >
        <span v-if="notifications" class="has-notes-badge" />
        <v-icon>$notes</v-icon>
      </v-btn>
    </template>

    <div class="notes">
      <header>
        <h3>{{ title || $tkey('title') }}</h3>
        <v-btn icon color="primary" @click="close">
          <v-icon color="primary" size="19"> mdi-close-circle</v-icon>
        </v-btn>
      </header>

      <div class="notes__warning-container">
        <div class="notes__warning-container__scroller">
          <progress-bar v-if="loading" :message="$t('general.loading')" class="confirm" />

          <div v-else-if="showConfirmDelete" class="confirm">
            <p class="confirm__question">{{ $tkey('confirmDeleteQuestion') }}</p>
            <p class="confirm__warning">{{ $tkey('confirmDeleteWarning') }}</p>
            <v-btn primary depressed width="min-content" class="ma-1" @click="deleteNote">
              {{ $t('actions.delete') }}
            </v-btn>
            <v-btn text depressed width="min-content" class="ma-1" @click="cancel">
              {{ $t('actions.cancel') }}
            </v-btn>
          </div>

          <div v-for="(note, noteIndex) in notes" :key="note._id" class="notes__container">
            <rtls-avatar :username="note.user.username" />
            <div class="notes__bodyText">
              <p class="notes__date">
                {{ note.creationDate | formatDate(getDateFormats.mediumWithTime) }}
              </p>
              <v-menu
                v-if="showOptionsMenu(note)"
                :disabled="isCurrentlyEditing(noteIndex)"
                close-on-click
              >
                <template v-slot:activator="{ on }">
                  <v-icon :color="isCurrentlyEditing(noteIndex) ? 'grey' : 'black'" v-on="on"
                    >mdi-dots-horizontal</v-icon
                  >
                </template>
                <v-list class="edit-menu">
                  <v-list-item @click="enableEditMode(noteIndex)">
                    {{ $t('actions.edit') }}
                  </v-list-item>
                  <v-list-item @click="openDeleteConfirm(note._id)">
                    {{ $t('actions.delete') }}
                  </v-list-item>
                </v-list>
              </v-menu>
              <form v-if="isCurrentlyEditing(noteIndex)" class="notes__edit-form">
                <v-textarea
                  v-model="editText"
                  class="notes__text"
                  no-resize
                  solo
                  hide-details
                  flat
                  autocomplete="off"
                  rows="0"
                  :maxlength="maxCharSize"
                />
                <p>
                  {{ remainingEditCharsMessage }}
                </p>
                <v-btn small text width="min-content" height="18" @click="cancel">
                  {{ $t('actions.cancel') }}
                </v-btn>
                <v-btn
                  primary
                  small
                  depressed
                  width="min-content"
                  height="18"
                  :disabled="isSaveButtonDisabled(note.message)"
                  @click="update(note)"
                >
                  {{ $t('actions.save') }}
                </v-btn>
              </form>
              <p v-else class="notes__text">{{ note.message }}</p>
            </div>
          </div>
        </div>
        <form v-if="showPostForm" class="notes__post-form">
          <v-textarea
            v-model="text"
            :label="$tkey('addNewPrompt')"
            no-resize
            solo
            hide-details
            flat
            autocomplete="off"
            rows="0"
            :maxlength="maxCharSize"
          />
          <p>
            {{ remainingCharsMessage }}
          </p>
          <v-btn
            primary
            small
            depressed
            width="min-content"
            height="18"
            :disabled="isPostButtonDisabled"
            @click="post"
          >
            {{ $tkey('postButton') }}
          </v-btn>
        </form>
        <span v-else-if="!hasNotes">{{ $tkey('noNotes') }}</span>
      </div>
    </div>
  </v-menu>
</template>

<script>
import { size } from 'lodash';
import { mapGetters } from 'vuex';
import { canCreateNotes, canEditNotes, canDeleteNotes } from '@enums/user-permissions';

export default {
  localizationKey: 'notes',
  name: 'Notes',
  props: {
    notes: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxCharSize: {
      type: Number,
      default: 280,
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    notifications: {
      type: Boolean,
      default: false,
    },
    isCannGroup: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    showNotes: false,
    text: null,
    editIndex: null,
    editText: null,
    deleteId: null,
    showConfirmDelete: false,
  }),

  computed: {
    ...mapGetters('context', ['getDateFormats', 'hasPermission', 'currentUserProfile']),

    notesType() {
      return this.isCannGroup ? 'cann-group-note' : 'notes-wrapper';
    },

    remainingCharsMessage() {
      return this.generateCharsMessage(this.text);
    },

    remainingEditCharsMessage() {
      return this.generateCharsMessage(this.editText);
    },

    isPostButtonDisabled() {
      return !size(this.text);
    },

    showPostForm() {
      return this.hasPermission(canCreateNotes);
    },

    hasNotes() {
      return size(this.notes);
    },
  },
  watch: {
    notes() {
      this.$refs.notesMenu.updateDimensions();
    },
  },
  methods: {
    post() {
      this.$emit('post', { message: this.text });
      this.text = null;
    },

    generateCharsMessage(selectedText) {
      if (!size(selectedText)) return;
      return this.$t('notes.chars.format', [
        this.maxCharSize - selectedText.length || this.$t('notes.chars.limitPrefix'),
      ]);
    },

    enableEditMode(noteIndex) {
      this.editIndex = noteIndex;
      this.editText = this.notes[noteIndex].message;
    },

    isSaveButtonDisabled(message) {
      return !size(this.editText) || this.editText === message;
    },

    cancel() {
      this.editIndex = null;
      this.showConfirmDelete = false;
      this.editText = null;
      this.text = null;
    },

    update(note) {
      this.$emit('update', { message: this.editText, note });
      this.editText = null;
      this.editIndex = null;
      this.text = null;
    },

    deleteNote() {
      this.$emit('delete', { noteId: this.deleteId });
      this.cancel();
    },

    openDeleteConfirm(noteId) {
      this.deleteId = noteId;
      this.showConfirmDelete = true;
    },

    close() {
      this.cancel();
      this.showNotes = false;
    },

    wrapOpenEvents(on, value) {
      const currentClick = on.click;
      const wrappedClick = (...args) => {
        currentClick(...args);
        if (!value) {
          this.$emit('menuOpen');
        }
      };
      return { ...on, click: wrappedClick };
    },

    getInitials(username) {
      return username
        .split(' ')
        .map(part => part[0])
        .join('');
    },

    showOptionsMenu(note) {
      return (
        this.hasPermission(canEditNotes) &&
        this.hasPermission(canDeleteNotes) &&
        note.user._id === this.currentUserProfile._id
      );
    },

    isCurrentlyEditing(noteIndex) {
      return noteIndex === this.editIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.has-notes-badge {
  height: 0.7rem;
  width: 0.7rem;
  min-width: 0.7rem;
  top: 0;
  right: 0;
  position: absolute;
  border-radius: 1rem;
  background-color: $assortment-negative-action-colour;
}

.notes-wrapper {
  // Footer has a z-index of 199, this prevents the footer blocking the post button
  z-index: 200 !important;
}

.cann-group-note {
  transform: translateX(-77%) translateY(-3rem);
}

.edit-menu {
  padding: 0.5rem 0;
  & > * {
    min-height: 2.3rem;
    color: $assortment-navigation-tab-text-colour;
    font-size: 1.2rem;
    &:hover {
      background-color: $assortment-blue-light;
    }
  }
}

.notes {
  width: 32.2rem;
  background: $assortment-notes-panel-background;
  padding: 0.9rem;
  border-left: 0.4rem solid $assortment-primary-colour;

  .progress-bar {
    padding: 5rem 0;
  }

  header {
    font-size: 1.4rem;
    padding: 0.3rem 0rem 0.9rem 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__warning-container {
    position: relative;
    width: 100%;
    height: 100%;

    span {
      text-align: center;
      padding: 2rem;
      display: block;
      font-weight: 600;
    }

    &__scroller {
      overflow-y: auto;
      max-height: 45vh;
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      height: 100%;

      .confirm {
        position: absolute;
        top: 0;
        left: 0;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex-direction: column;
        text-align: center;
        // Stops some vuetify elements appearing over the confirm overlay
        z-index: 10;

        p {
          font-size: 1.2rem;
          line-height: 1.5rem;
          padding: 0;
          margin: 0;
        }
        &__warning {
          font-weight: 600;
          margin-bottom: 1.8rem !important;
          margin-top: 1.5rem !important;
        }
      }
    }
  }

  &__container {
    display: flex;
    background: $assortment-background;
    padding: 0.7rem 1rem;
    margin-bottom: 0.6rem;
  }

  &__author {
    height: 2.6rem;
    width: 2.6rem;
    text-transform: uppercase;
    color: $assortment-notes-initials-color;
  }

  &__bodyText {
    padding-left: 1rem;
    font-size: 1.2rem;
    align-self: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 1;

    .v-menu {
      align-self: end;
    }

    .v-list-item {
      max-height: 2.3rem;
    }
  }

  &__text {
    word-break: break-word;
    margin-bottom: 0;
    width: 100%;
  }

  &__date {
    font-weight: bold;
    margin-bottom: 0.4rem;
  }

  &__post-form {
    padding: 0.6rem 0.9rem;
    background: $assortment-scenarios-colour;
  }
  &__edit-form {
    p {
      width: 100%;
    }
  }

  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .v-btn:first-of-type {
      margin-left: auto;
    }

    .v-btn {
      margin-top: 0.6rem;
    }

    p {
      margin: 0;
    }

    ::v-deep {
      .v-textarea {
        border-radius: 0;
        box-shadow: 0;
        width: 100%;
      }

      .v-label {
        top: 0.4rem !important;
      }
      .v-input__control {
        .v-input__slot {
          padding: 0 0.8rem;
          .v-text-field__slot {
            textarea {
              padding: 0.4rem 0;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
</style>
