<template>
  <v-card class="step-tab-panel" flat>
    <v-container class="actions-container pa-0 ma-0">
      <v-row class="actions-col">
        <v-col data-id-e2e="btnFurnitureMapping" class="d-flex align-center">
          <span class="mr-3 font-weight-bold">
            {{ $tkey('reviewUpdateSelectFurnitureMapping') }}

            <!-- Furniture mapping tooltip -->
            <docs-link link="toolguide/045-storemapping.html" />
          </span>

          <data-upload
            :legends="csvUploadLegends"
            :csv-upload-handler="onCSVUpload"
            :disabled="isEditingDisabled"
            @process="processMappedCSVData"
          />

          <div v-if="totalUnmappedStores" class="ml-4">
            <v-btn
              v-if="!filterUnmappedRows"
              small
              outlined
              depressed
              color="error"
              @click="toggleUnmappedRows"
            >
              {{ $tc('furnitureMappingPage.showUnmappedRows', totalUnmappedStores) }}
            </v-btn>
            <v-btn v-else small outlined depressed color="primary" @click="toggleUnmappedRows">
              {{ $tc('furnitureMappingPage.showAllRows', furnitureStoreMapData.length) }}
            </v-btn>
            <assortment-tooltip
              :title="$tc('furnitureMappingPage.unmappedStores', totalUnmappedStores)"
              :tooltip-sections="unmappedStoresTooltipSections"
              error
            />
            <v-btn
              v-if="unmappedDuplicateStores.length"
              :disabled="isEditingDisabled"
              depressed
              secondary
              class="ml-2"
              @click="handleUnmatchedDuplicateStores"
            >
              <span>{{ $tc('furnitureMappingPage.dropUnmatchedStores') }}</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div class="ag-grid-box flex-grow-1">
      <ag-grid-vue
        style="width: 100%; height: 100%"
        class="ag-theme-custom"
        :column-defs="headers"
        :row-data="furnitureStoreMapData"
        :grid-options="gridOptions"
        :does-external-filter-pass="doesExternalFilterPass"
        :stop-editing-when-cells-loses-focus="true"
        :enable-range-selection="true"
        @cell-value-changed="onSelectionChanged"
        @grid-ready="onGridReady"
      />
    </div>

    <page-actions
      show-export
      is-custom-export
      :has-data-changes="hasDataChanges"
      :has-data-errors="hasDataErrors"
      :save-disabled="isEditingDisabled"
      :is-discard-enabled="!isEditingDisabled"
      @export="exportCSV"
      @discard="discardChanges"
      @save="confirmSave"
    >
      <template v-slot:left-btns>
        <v-btn v-if="showNotImplemented" depressed disabled secondary class="outlined-btn">
          {{ $tkey('copyHistoricMapping') }}
        </v-btn>
        <v-btn v-if="showNotImplemented" depressed disabled secondary class="outlined-btn">{{
          $tkey('linkSelection')
        }}</v-btn>
      </template>
    </page-actions>
    <main-dialog ref="confirmSaveDialog" :title="$tkey('saveWithUnmatchedStores')">
      <template v-slot:content>
        <div class="mt-5 mb-2 mx-5 text-justify">
          <h3>{{ $tkey('saveUnmappedStoresWarning') }}</h3>
          <ul class="mt-2">
            <li v-for="store in unmappedStores" :key="store.storeKey" style="font-size: 1.3rem">
              {{ `${store.storeKeyDisplay} - ${store.storeName}` }}
            </li>
          </ul>
        </div>
      </template>
      <template v-slot:actions="{ cancel: close }">
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn action @click="[saveChanges(), close()]">
              {{ $t('actions.confirm') }}
            </v-btn>
            <v-btn class="ml-2" @click="close()">
              {{ $t('actions.cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </main-dialog>
    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="closeUnsavedDataModal"
      @confirm="closeUnsavedDataModal"
    />
  </v-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import {
  camelCase,
  pick,
  keyBy,
  uniq,
  find,
  get,
  groupBy,
  isEmpty,
  cloneDeep,
  omitBy,
  isNil,
  values,
  merge,
  includes,
  isEqual,
  mapValues,
  extend,
  forEach,
  uniqBy,
  filter,
  findIndex,
  map,
  compact,
} from 'lodash';
import { v1 as uuidv1 } from 'uuid';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import agGridUtils from '@/js/utils/ag-grid-utils';
import exportCSV from '@/js/mixins/export-csv';
import duplicateIcon from '@/img/duplicate.svg';
import MappingUtils from '@/js/store/utils/furniture-to-store-mapping';
import i18n from '@/js/vue-i18n';
import agGridIcon from '@/js/components/ag-grid-cell-renderers/ag-grid-icon.vue';

const localizationKey = 'furnitureMappingPage';
const t = (key, locKey = localizationKey) => i18n.t(`${locKey}.${key}`);

export default {
  localizationKey,
  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    agGridIcon,
  },
  mixins: [exportCSV, unsavedDataWarningMixin],

  data() {
    return {
      csvUploadLegends: {
        buttonName: this.$t('actions.manualImport'),
        title: this.$t('actions.manualImport'),
      },
      sanitisedFields: ['id', 'storeKey', 'furnitureId', 'overrideFurnitureId'],
      serviceName: 'furniture-to-store',
      currentStateDiff: {},
      deletedMappings: [],
      furnitureStoreMapData: null, // set on created
      furnitureInFASets: [], // set on created
      gridApi: null, // set onGridReady
      actionColDef: {
        ...agGridUtils.colDefs.action,
        menuTabs: [],
      },
      gridOptions: {
        getRowId(params) {
          const { data } = params;
          return data.id;
        },
        rowHeight: 30,
        headerHeight: 40,
        suppressContextMenu: true,
        defaultColDef: {
          editable: true,
          resizable: true,
          suppressMovable: true,
          filter: true,
          sortable: true,
          comparator: agGridUtils.sortings.naturalSort,
          menuTabs: ['filterMenuTab'],
          flex: 1,
          minWidth: 80,
          cellClassRules: {
            'diff-background': this.hasDiff,
            'not-editable-cell': agGridUtils.utils.disableEdit,
            'invalid-generic': this.isInvalidEntry,
          },
          suppressKeyboardEvent: agGridUtils.utils.suppressKeyboardEvent,
        },
        suppressScrollOnNewData: true,
        processDataFromClipboard: agGridUtils.utils.processDataFromClipboard,
        processCellFromClipboard: this.processCellFromClipboard,
        isExternalFilterPresent: () => true,
        columnTypes: {
          numericColumnCustom: agGridUtils.columnTypes.numericColumnCustom,
        },
        processCellForClipboard: params => {
          if (params.column.colId === 'furnitureId') return params.node.data.furnitureName;
          return params.node.data[params.column.colId];
        },
      },
      filterUnmappedRows: false,
    };
  },

  computed: {
    ...mapState('furniture', ['scenarioFurniture', 'localMessage', 'isLoading']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('furniture', [
      'getTableRows',
      'getFurnituresInScenario',
      'getFurnitureToStoreMappingSorted',
      'getFurnitureArchetypeName',
    ]),
    ...mapGetters('scenarios', ['stores']),
    ...mapGetters('scenarios', ['selectedScenario']),
    ...mapGetters('context', ['showNotImplemented', 'getCsvExport', 'getDateFormats']),

    isEditingDisabled() {
      return (
        !this.hasPermission(this.userPermissions.canEditFurnitureMappingPage) ||
        !get(this.selectedScenario, 'status.space.canBeEdited', true)
      );
    },

    hasNoDataChanges() {
      return isEmpty(this.currentStateDiff);
    },

    hasDataChanges() {
      return !this.hasNoDataChanges || !isEmpty(this.deletedMappings);
    },

    hasDataErrors() {
      // Users should have to match duplicate store entries
      const hasUnmappedDuplicatedStores = this.unmappedDuplicateStores.length > 0;
      return hasUnmappedDuplicatedStores || this.duplicatedRows.length > 0;
    },

    duplicatedRows() {
      return filter(
        groupBy(
          this.getRowData(),
          r => `${r.storeKey}-${r.furnitureId || null}-${r.overrideFurnitureId || null}`
        ),
        group => group.length > 1
      );
    },

    furnituresByNameAndId() {
      return extend(
        mapValues(keyBy(this.getFurnituresInScenario, '_id'), '_id'),
        mapValues(keyBy(this.getFurnituresInScenario, 'name'), '_id')
      );
    },

    storesToFurnituresGroup() {
      return groupBy(this.furnitureStoreMapData, 'storeKey');
    },

    unmappedStores() {
      const unmappedStores = [];
      forEach(this.storesToFurnituresGroup, mappings => {
        const currentMappings = mappings.filter(
          mapping => !this.deletedMappings.includes(mapping.id)
        );
        if (currentMappings.length === 1 && !currentMappings[0].furnitureId)
          unmappedStores.push(currentMappings[0]);
      });
      return uniqBy(unmappedStores, 'storeKey');
    },

    unmappedDuplicateStores() {
      let unmappedStores = [];
      forEach(this.storesToFurnituresGroup, mappings => {
        const currentMappings = mappings.filter(
          mapping => !this.deletedMappings.includes(mapping.id)
        );
        if (currentMappings.length > 1 && currentMappings.some(({ furnitureId }) => !furnitureId))
          unmappedStores = unmappedStores.concat(
            currentMappings.filter(({ furnitureId }) => !furnitureId)
          );
      });
      return unmappedStores;
    },

    totalUnmappedStores() {
      return this.unmappedStores.length + this.unmappedDuplicateStores.length;
    },

    unmappedStoresTooltipSections() {
      const tips = [];
      if (this.unmappedStores.length)
        tips.push({
          tips: [
            this.$tkey('unmappedStoresInfo'),
            {
              text: this.$tkey('storesIgnoredForAssortment'),
              nestedTips: uniq(
                this.unmappedStores.map(
                  ({ storeKeyDisplay, storeName }) => `${storeKeyDisplay} - ${storeName}`
                )
              ),
            },
          ],
        });
      if (this.unmappedDuplicateStores.length > 0)
        tips.push({
          tips: [
            {
              text: this.$tkey('duplicateUnmappedStores'),
              nestedTips: uniq(
                this.unmappedDuplicateStores.map(
                  ({ storeKeyDisplay, storeName }) => `${storeKeyDisplay} - ${storeName}`
                )
              ),
            },
          ],
        });
      return tips;
    },

    headers() {
      const currentScope = camelCase(this.selectedWorkpackage.fillInSelection);
      return [
        {
          headerName: this.$tkey('tableHeaders.storeKeyDisplay'),
          field: 'storeKeyDisplay',
          colId: 'storeKeyDisplay',
          headerClass: 'bold',
          cellClass: 'bold',
          editable: false,
          pinned: 'left',
        },
        {
          headerName: this.$tkey('tableHeaders.storeName'),
          field: 'storeName',
          colId: 'storeName',
          headerClass: 'bold',
          cellClass: 'bold',
          editable: false,
          pinned: 'left',
        },
        {
          headerName: this.$tkey('tableHeaders.format'),
          field: 'format',
          colId: 'format',
          editable: false,
          pinned: 'left',
        },
        {
          headerName: this.$tkey('tableHeaders.furnitureArchetypeSet'),
          field: 'furnitureCategory1Key',
          colId: 'furnitureCategory1Key',
          cellClass: 'grey-border-right',
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: [null, ...this.getSetNames()],
            field: 'furnitureCategory1Key',
          },
          editable: !this.isEditingDisabled,
          valueFormatter: this.getSetNames,
        },
        {
          // TODO: custom header html? set history bold on own line?
          headerName: `${this.$tkey('historic')} ${this.$tkey(
            'tableHeaders.furnitureArchetypeName'
          )}`,
          field: 'furnitureId',
          colId: 'furnitureId',
          cellEditor: 'agSelectCellEditor',
          // nice to have: try richselectEditor?
          cellEditorParams: params => {
            const { furnitureCategory1Key } = params.node.data;
            const furnitureIds = get(this.furnitureInFASets, furnitureCategory1Key, []).map(
              v => v._id
            );
            return {
              values: [null, ...furnitureIds],
              filed: 'furnitureName',
            };
          },
          valueFormatter: this.formatFurnitureName,
          filterParams: {
            valueFormatter: this.formatFurnitureName,
          },
          editable: !this.isEditingDisabled,
        },
        {
          headerName: `${this.$t(`workpackagePage.scope.${currentScope}`)} (${this.$t(
            `suffixes.${currentScope}`
          )})`,
          field: 'furnitureSize',
          colId: 'furnitureSize',
          type: 'numericColumnCustom',
          filter: 'agTextColumnFilter',
          editable: false,
          cellClass: 'grey-border-right d-flex align-center justify-center size-container',
        },
        {
          headerName: `${this.$tkey('future')} ${this.$tkey(
            'tableHeaders.furnitureArchetypeName'
          )}`,
          field: 'overrideFurnitureId',
          colId: 'overrideFurnitureId',
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: [null, ...this.getFurnituresInScenario.map(v => v._id)],
          },
          valueFormatter: this.formatFurnitureName,
          filterParams: {
            valueFormatter: this.formatFurnitureName,
          },
          editable: !this.isEditingDisabled,
        },
        {
          headerName: `${this.$t(`workpackagePage.scope.${currentScope}`)} (${this.$t(
            `suffixes.${currentScope}`
          )})`,
          field: 'overrideSize',
          colId: 'overrideSize',
          type: 'numericColumnCustom',
          filter: 'agTextColumnFilter',
          editable: false,
          cellClass: 'grey-border-right d-flex align-center justify-center size-container',
        },
        {
          ...this.actionColDef,
          cellRenderer: params => this.copyCellRenderer(params),
          tooltipValueGetter: () => t('tooltip.duplicate'),
          maxWidth: 40,
          minWidth: 40,
          pinned: 'right',
        },
        {
          ...this.actionColDef,
          maxWidth: 40,
          minWidth: 40,
          pinned: 'right',
          cellRenderer: 'agGridIcon',
          cellRendererParams: params => {
            const canDelete = this.canDelete(params);
            return {
              classes: canDelete ? 'selectable' : 'greyed-out',
              iconComponent: 'trash-icon',
              isDisabled: !canDelete,
              ...(canDelete ? { onClick: this.deleteRow, clickParams: params } : {}),
            };
          },
        },
      ];
    },

    unmappedDuplicateStoresSet() {
      return new Set(map(this.unmappedDuplicateStores, 'storeKey'));
    },
  },

  async created() {
    // solve issue where getting to this page from /home fails because workpackge state is empty.
    await this.refreshWorkpackageInformation({ workpackageId: this.selectedWorkpackage._id });
    this.init();
  },

  methods: {
    ...mapMutations('furniture', ['setMessage']),
    ...mapActions('snackbar', ['showWarning']),
    ...mapActions('furniture', ['processCSV', 'saveScenarioFurniture']),
    ...mapActions('files', ['uploadCSV']),
    ...mapActions('workpackages', ['refreshWorkpackageInformation']),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    processCellFromClipboard(params) {
      const furnitureFormatter = value => {
        return get(this.furnituresByNameAndId, value);
      };

      const pasteFormatters = {
        furnitureCategory1Key: value => {
          if (includes(this.getSetNames(), value)) return value;
          return null;
        },
        furnitureId: furnitureFormatter,
        overrideFurnitureId: furnitureFormatter,
      };

      return agGridUtils.utils.processCellFromClipboard(params, pasteFormatters);
    },

    allDuplicatedSetsRows() {
      return this.duplicatedRows.reduce(
        (acc, group) => acc.concat(group.map(row => this.gridApi.getRowNode(row.id))),
        []
      );
    },

    onSelectionChanged(params) {
      if (!isEqual(params.oldValue, params.newValue)) {
        // get a list of current duplicated rows
        let allDuplicatedRows = this.allDuplicatedSetsRows();
        let rowUpdated = null;
        this.updateCurrentState(params);
        /*
          If we change the furnitureCategory1Key, we clear the furnitureId so we don't have mismatched data.
          We also need to keep size and furniture in sync.
          i.e. furnitureId changes, size updates. overrideFurnitureId changes, overrideSize updates.
        */
        if (params.colDef.field === 'furnitureCategory1Key') {
          rowUpdated = this.updateSelection(params, 'furnitureSize', 'size');
          rowUpdated = this.updateSelection(params, 'furnitureId', '_id');
        } else if (params.colDef.field === 'furnitureId') {
          rowUpdated = this.updateSelection(params, 'furnitureSize', 'size', {
            _id: params.data.furnitureId,
          });
        } else if (params.colDef.field === 'overrideFurnitureId') {
          rowUpdated = this.updateSelection(params, 'overrideSize', 'size', {
            _id: params.data.overrideFurnitureId,
          });
        }
        if (this.filterUnmappedRows && this.totalUnmappedStores === 0) this.toggleUnmappedRows();

        // get new duplicated rows
        allDuplicatedRows = compact(
          allDuplicatedRows.concat(this.allDuplicatedSetsRows(), rowUpdated)
        );
        // redraw any previously duplicated rows + newly duplicated rows + current row
        this.gridApi.redrawRows({ rowNodes: allDuplicatedRows });
      }
    },

    updateSelection(params, tableField, dbField, findFilter) {
      let furniture = {};
      if (findFilter) furniture = find(this.getFurnituresInScenario, findFilter) || {};
      // cloneDeep and ... on params fail with TypeError: Cannot convert a Symbol value to a string.
      // https://github.com/lodash/lodash/issues/4461
      const { id } = params.data;
      const value = get(furniture, dbField, null);
      const newParams = {
        value,
        data: { [tableField]: value, id },
        colDef: { field: tableField },
      };
      this.updateCurrentState(newParams);
      const rowNode = this.gridApi.getRowNode(id);
      rowNode.data[tableField] = value;

      return rowNode;
    },

    updateCurrentState(params) {
      const { id } = params.data;
      const { field } = params.colDef;
      const currentValue = params.value;

      const path = `${id}.${field}`;
      const originalValue = get(this.$options.savedState, path);

      if (agGridUtils.comparators.didValueChange(currentValue, originalValue)) {
        if (!this.currentStateDiff[id]) this.$set(this.currentStateDiff, id, {});
        this.$set(this.currentStateDiff[id], field, currentValue);
        return;
      }

      if (currentValue === originalValue && get(this.currentStateDiff[id], field)) {
        // Remove the field from the object if it's back to its old value.
        // Note: This is only triggered on a change so entering 1 in a cell that already contains 1 triggers nothing.
        this.$delete(this.currentStateDiff[id], field);
        if (isEmpty(this.currentStateDiff[id])) {
          // If there's nothing left in the object, remove it.
          this.$delete(this.currentStateDiff, id);
        }
      }
    },

    hasDiff(params) {
      const { id } = params.data;
      const { field } = params.colDef;
      const path = `${id}.${field}`;

      // current value out of sync after save, but in sync after next chage?
      const currentValue = params.data[params.colDef.field];
      const originalValue = get(this.$options.savedState, path);

      return agGridUtils.comparators.didValueChange(currentValue, originalValue);
    },

    isDuplicatedRow(params) {
      const paramsFurnitureId = params.data.furnitureId || null;
      const paramsOverrideFurnitureId = params.data.overrideFurnitureId || null;
      // get any other row but the current one with the same selection
      const rowsMatchingSelection = this.furnitureStoreMapData.filter(row => {
        const rowFurnitureId = row.furnitureId || null;
        const rowOverrideFurnitureId = row.overrideFurnitureId || null;
        return (
          row.id !== params.data.id &&
          row.storeKey === params.data.storeKey &&
          rowFurnitureId === paramsFurnitureId &&
          rowOverrideFurnitureId === paramsOverrideFurnitureId
        );
      });

      return rowsMatchingSelection.length > 0;
    },

    async handleUnmatchedDuplicateStores() {
      const droppedStoresCounter = {};
      this.gridApi.showLoadingOverlay();
      const idsToDelete = [];
      this.unmappedDuplicateStores.forEach(store => {
        // We can't remove all entries if all are unmapped. So we leave one store with an empty mapping.
        const numberOfMappingsRemaining = this.storesToFurnituresGroup[store.storeKey].filter(
          mapping => !this.deletedMappings.includes(mapping.id)
        ).length;
        if (numberOfMappingsRemaining > 1) {
          idsToDelete.push(store.id);
          droppedStoresCounter[store.storeKey] = get(droppedStoresCounter, store.storeKey, 0) + 1;
        }
      });
      await this.handleMultipleRowsDeletion(idsToDelete);
      if (this.filterUnmappedRows && this.totalUnmappedStores === 0) this.toggleUnmappedRows();
      this.gridApi.hideOverlay();
    },

    formatFurnitureName(params) {
      const furniture = find(this.getFurnituresInScenario, { _id: params.value });
      return this.getFurnitureArchetypeName({ data: furniture });
    },

    // return current (non-deleted) mappings
    currentStoreMappings(storeKey) {
      const rowData = this.getRowData();
      return rowData.filter(v => {
        return v.storeKey === storeKey && !this.deletedMappings.includes(v.id);
      });
    },

    canDelete(params) {
      const { storeKey } = params.data;
      // Can only delete if there is > 1 row for that given store
      return !this.isEditingDisabled && this.currentStoreMappings(storeKey).length > 1;
    },

    deleteRow(params) {
      if (!this.canDelete(params)) return;
      this.handleMultipleRowsDeletion([params.node.id]);
      // need to redraw rows so they have the correct disabled style
      const ids = this.currentStoreMappings(params.data.storeKey).map(v => v.id);
      const rowNodes = ids.map(id => this.gridApi.getRowNode(id));
      this.gridApi.redrawRows({ rowNodes });
    },

    copyCellRenderer(params) {
      const img = document.createElement('img');
      img.src = duplicateIcon;
      img.className = this.isEditingDisabled ? 'v-icon--disabled' : '';
      img.style = this.isEditingDisabled ? '' : 'cursor: pointer;';
      img.addEventListener('click', () => this.handleCopy(params));
      return img;
    },

    toggleUnmappedRows() {
      this.filterUnmappedRows = !this.filterUnmappedRows;
      this.gridApi.onFilterChanged();
    },

    doesExternalFilterPass(node) {
      if (this.filterUnmappedRows)
        return (
          this.unmappedStores.some(({ id }) => id === node.data.id) ||
          this.unmappedDuplicateStores.some(({ id }) => id === node.data.id)
        );
      return true;
    },

    async handleMultipleRowsDeletion(ids) {
      const deletedIds = ids.map(id => {
        this.handleDelete(id);
        return { id };
      });
      this.gridApi.applyTransaction({ remove: deletedIds }); // remove multiple rows from grid
    },

    handleDelete(id) {
      this.deletedMappings.push(id);
      this.$delete(this.currentStateDiff, id); // remove any changes to the mapping marked for deletion
      const indexToBeRemoved = findIndex(this.furnitureStoreMapData, { id });
      this.furnitureStoreMapData.splice(indexToBeRemoved, 1);
    },

    handleCopy(params) {
      const { rowIndex } = params;
      const { format, storeKey, storeKeyDisplay, storeName } = params.node.data;

      const newRowData = {
        format,
        furnitureCategory1Key: null,
        furnitureId: null,
        furnitureName: null,
        furnitureSize: null,
        id: uuidv1(),
        index: null,
        overrideFurnitureId: null,
        overrideName: null,
        overrideSize: null,
        storeKey,
        storeKeyDisplay,
        storeName,
      };

      this.currentStateDiff = merge({}, this.currentStateDiff, { [newRowData.id]: newRowData });
      const rowData = this.getRowData();

      // The below splice method will be replaced with applyTransaction method using addIndex in ticket https://owlabs.atlassian.net/browse/AOV3-791
      // https://www.ag-grid.com/documentation/javascript/data-update-transactions/
      rowData.splice(rowIndex + 1, 0, newRowData);
      this.furnitureStoreMapData.splice(rowIndex + 1, 0, newRowData);
      this.gridApi.setRowData(rowData);
    },

    confirmSave() {
      if (this.unmappedStores.length) this.$refs.confirmSaveDialog.open();
      else this.saveChanges();
    },

    async saveChanges() {
      try {
        this.gridApi.showLoadingOverlay();
        // the api expects the entire table to be sent to it, so we use furnitureStoreMapData which includes local changes.
        // need to omit nil values so they don't create new objectIds e.g. ObjectId(null) -> 5f43b4a91976944d343fe518.
        // furnitureId and overrideFurnitureId could be converted to a new objectIds if sent through to the api as null/undefined.
        const rowData = this.getRowData();
        const sanitisedArray = rowData
          .filter(mapping => {
            return !this.deletedMappings.includes(mapping.id);
          })
          .map(mapping => {
            return omitBy(pick(mapping, this.sanitisedFields), isNil);
          });
        await this.saveScenarioFurniture({ storeFurniture: sanitisedArray });
        this.init();
      } catch (e) {
        console.error(e);
        this.showWarning();
      }
      this.gridApi.hideOverlay();
    },

    discardChanges() {
      this.gridApi.showLoadingOverlay();
      this.furnitureStoreMapData = cloneDeep(values(this.$options.savedState));
      this.resetChanges();
      this.gridApi.hideOverlay();
    },

    processCellCallback(params) {
      if (
        (params.column.colId === 'overrideFurnitureId' || params.column.colId === 'furnitureId') &&
        params.value
      ) {
        return this.formatFurnitureName(params);
      }
      return params.value;
    },

    exportCSV() {
      const exportParams = {
        fileName: this.getFileName({
          serviceName: 'furniture-to-store-map',
          workpackageName: this.selectedWorkpackage.name,
          scenarioName: this.selectedScenario.name,
          fileNameDateFormat: this.getDateFormats.csvFileName,
        }),
        suppressQuotes: this.getCsvExport.suppressQuotes,
        columnSeparator: this.getCsvExport.columnSeparator,
        columnKeys: this.getAllVisibleDataColumns(),
        processCellCallback: this.processCellCallback,
      };

      this.gridApi.exportDataAsCsv(exportParams);
    },
    getAllVisibleDataColumns() {
      // filter copy and trash
      return this.columnApi.getAllDisplayedColumns().filter(c => c.colDef.headerName !== '');
    },
    resetChanges() {
      this.deletedMappings = [];
      this.currentStateDiff = {};
    },

    init() {
      this.resetChanges();
      this.furnitureStoreMapData = cloneDeep(this.getFurnitureToStoreMappingSorted);
      // backup data
      this.$options.savedState = keyBy(cloneDeep(this.furnitureStoreMapData), 'id');
      this.furnitureInFASets = groupBy(this.getFurnituresInScenario, 'furnitureCategory1Key');
    },

    async onCSVUpload(formData) {
      formData.append('scenarioId', this.selectedScenario._id);
      return this.uploadCSV({ formData, service: this.serviceName });
    },

    // used after user selected mappings
    async processMappedCSVData({ fileId, mappings, delimiter }) {
      const uploadedData = await this.processCSV({
        fileId,
        mappings,
        delimiter,
        furnitureService: this.serviceName,
      });
      const uploadedStores = uniq(uploadedData.map(v => v.storeKey));

      const mappingsForUnuploadedStores = this.furnitureStoreMapData.filter(({ storeKey }) => {
        return !uploadedStores.includes(storeKey);
      });

      // put the new valid mappings from the csv into the table
      this.furnitureStoreMapData = MappingUtils.buildTableContents(
        keyBy(this.getFurnituresInScenario, '_id'),
        keyBy(this.stores, 'storeKey'),
        [...mappingsForUnuploadedStores, ...uploadedData]
      );
      // mark the uploaded data as changes (so user can save or discard)
      uploadedData.forEach(v => {
        this.$set(this.currentStateDiff, v.id, v);
      });
    },

    getSetNames(params) {
      const names = uniq(this.getFurnituresInScenario.map(v => v.furnitureCategory1Key));
      if (params) return names.indexOf(params.value) > -1 ? params.value : ' ';
      return names;
    },

    getRowData() {
      const rowData = [];
      // Check if grid has loaded before loading rowData
      if (this.gridApi) {
        this.gridApi.forEachNode(node => rowData.push(node.data));
      }
      return rowData;
    },

    isEmptyDuplicateUnmappedRow(params) {
      if (!this.unmappedDuplicateStoresSet.has(params.data.storeKey)) return false;

      // if this store is flagged as duplicate but we have data in it, let the default duplicate row check deal with it
      return isEmpty(params.data.furnitureId) && isEmpty(params.data.overrideFurnitureId);
    },

    isInvalidEntry(params) {
      return this.isDuplicatedRow(params) || this.isEmptyDuplicateUnmappedRow(params);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.actions-container {
  border-bottom: 1px solid $assortment-panel-border-divider-colour;
  max-width: none !important;
}

.assortment-table {
  height: 500px;
  flex: auto;
  overflow-y: scroll;
  border-bottom: 1px solid $assortment-panel-border-divider-colour;
  ::v-deep {
    td:first-child {
      padding: 0 16px !important;
    }
  }

  .margin-y {
    margin: 6px 0;
  }

  .bold {
    font-weight: bold;
  }

  .checkbox-header-container {
    margin-left: 4px;
  }

  .checkbox {
    margin: 0px 5px 0px 3px !important;
    ::v-deep {
      .v-input--selection-controls__input {
        margin-right: 0px;
        height: auto !important;
        width: auto !important;

        input {
          height: 100% !important;
          width: 100% !important;
        }
      }

      .v-input__slot {
        padding-bottom: 0;
      }
    }
  }

  .size-container {
    background: $assortment-input-background;
    height: 28px;
  }

  .connecting-bar {
    background: $assortment-input-background;
    width: 16px;
    height: 4px;
  }

  tr {
    &:nth-child(even) {
      .connecting-bar {
        background: $assortment-table-blue-bg-colour !important;
      }
    }
  }

  .empty-data {
    background: $assortment-table-empty-table-row;
    text-align: center;

    &span {
      color: $assortment-table-empty-table-span;
    }
  }
}
::v-deep {
  .blue-border-right {
    &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      border-right: 1px $assortment-table-header-blue-divider-colour solid;
      bottom: 0px;
      height: 33px;
    }
  }

  .grey-border-right {
    &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      border-right: 1px $assortment-horizontal-border-colour solid;
      bottom: 0px;
      height: 33px;
    }
  }

  .black-text {
    color: black;
  }

  .filter-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;

    .filter-label {
      color: #37424a;
      font-family: 'Source Sans Pro';
      font-size: 1.4rem;
      margin-right: 7px;
      letter-spacing: 0;
      line-height: 1.8rem;
    }
  }

  // You can't apply styles to the parent element of the column cells in v-data-table
  // This is a positional hack to ensure they get the right styling
  // If the columns are changed, these will need to be updated

  tr {
    td:nth-child(3) {
      border-right: 1px $assortment-table-header-blue-divider-colour solid !important;
    }
    td:nth-child(4),
    td:nth-child(6),
    td:nth-child(8) {
      border-right: 1px $assortment-horizontal-border-colour solid !important;
    }
    td:nth-child(5),
    td:nth-child(7) {
      padding-right: 0px !important;
    }
    td:nth-child(6),
    th:nth-child(6),
    td:nth-child(8),
    th:nth-child(8) {
      padding-left: 0px !important;
    }
  }
}

.outlined-btn {
  margin-left: 13px !important;
}
.not-editable-cell {
  color: $assortment-table-not-editable-color;
}
.unmapped-stores-tooltip {
  color: $assortment-span-error-colour;
}
</style>
