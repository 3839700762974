<template>
  <v-card class="step-tab-panel" flat>
    <v-container
      class="actions-container pa-0 ma-0 flex-grow-0"
      :style="[{ height: showSpacebreakConfigurations ? '62px' : '52px' }]"
    >
      <v-row>
        <v-row class="actions-col">
          <v-col
            class="d-flex align-center spacebreak-actions"
            :class="[showSpacebreakConfigurations ? 'px-3 py-2' : 'pa-3']"
          >
            <span class="mr-3 font-weight-bold">
              {{ $tkey('reviewSpacebreakMessage') }}

              <!-- Spacebreak calculator tooltip -->
              <docs-link link="toolguide/04f-spacebreaks.html" />
            </span>
            <v-btn
              v-if="!hasSpacebreaks"
              :disabled="isEditingDisabled || isSBJobRunning"
              data-id-e2e="btnCalculateSpacebreaks"
              class="button-space-5"
              primary
              @click="onCalculateSpacebreakClick(false)"
            >
              {{ $tkey('calculateSpacebreaks') }}
            </v-btn>
          </v-col>

          <v-col v-if="showSpacebreakConfigurations" class="spacebreak-configurations pa-2">
            <div class="d-flex">
              <div
                v-for="(v, c) in spacebreakConfigurations"
                :key="`${c}-configuration`"
                class="d-flex align-center"
              >
                <span class="mr-2 spacebreak-configurations__label">
                  {{ $tkey(`configurations.${c}`) }}:
                </span>
                <rtls-text-field
                  v-model.number="spacebreakConfigurations[c]"
                  :disabled="isEditingDisabled"
                  grey
                  class="spacebreak-configurations__input mr-2"
                  :rules="rules[c]"
                  @change="updateDiff(c, $event)"
                />
              </div>
            </div>
            <p class="mb-0 mt-1 spacebreak-configurations__message">
              {{ $tkey('configurations.infoMessage') }}
            </p>
          </v-col>
        </v-row>
      </v-row>
    </v-container>

    <v-container fluid class="pa-0 flex-grow-1 d-flex flex-column">
      <v-layout v-if="!hasSpacebreaks || isSBJobRunning" class="empty-switching">
        <v-row class="main-panel">
          <v-col cols="2" class="rtls-border rtls-border--right" />
          <v-col cols="10" class="pl-0 pr-0 pb-0">
            <progress-bar
              v-if="isSBJobRunning"
              :message="$t('messages.inProgress')"
              :percentage="jobStatuses.spacebreakGenerator.progress"
              :status="jobStatuses.spacebreakGenerator.status"
            />

            <div v-else class="d-flex align-center justify-center flex-column" style="height: 100%">
              <p class="empty-switching-text">{{ $tkey('spacebreaksNotGenerated') }}</p>
              <p class="empty-switching-text">{{ $tkey('runToView') }}</p>
            </div>
          </v-col>
        </v-row>
      </v-layout>

      <template v-if="hasSpacebreaks && !isSBJobRunning">
        <v-row class="main-panel">
          <v-tabs v-model="selectedTab" class="store-class-tabs" :hide-slider="true">
            <v-tab
              v-for="item in getStoreClassesInScenario"
              :key="item._id"
              class="store-class-tab text-none step-tab"
            >
              <span class="tab-title"> {{ item.name }}</span>
            </v-tab>
            <v-tabs-items v-model="selectedTab">
              <v-tab-item
                v-for="item in getStoreClassesInScenario"
                :key="item._id"
                class="tab-content"
              >
                <spacebreaks :store-class-id="item._id" />
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-row>

        <div class="page-actions-container">
          <page-actions
            :show-export="showNotImplemented"
            :has-data-changes="hasDataChanges"
            :has-data-errors="hasDataErrors"
            :save-loading="isLoading || isSaving"
            :save-disabled="isEditingDisabled || isSaving"
            :is-discard-enabled="!isEditingDisabled && !isSaving"
            @discard="discard"
            @save="save"
          >
            <template v-slot:right-btns>
              <div class="calculate-btn-container">
                <v-btn
                  :disabled="isEditingDisabled || isSBJobRunning"
                  data-id-e2e="btnReCalculateSpacebreaks"
                  primary
                  @click="onCalculateSpacebreakClick(false)"
                >
                  {{ $tkey('reCalculateSpacebreaks') }}
                </v-btn>
              </div>
            </template>
          </page-actions>
        </div>
      </template>

      <dependency-tree-feedback-modal
        :value="dependencyTreeModalOpen"
        :results="dependencyTreeFeedback"
        page="spacebreakCalculator"
        @close="closeDependencyTreeModal"
        @commit="commitHandler"
      />

      <unsaved-data-modal
        ref="unsavedDataModal"
        :value="isUnsavedDataModalOpen"
        @cancel="closeUnsavedDataModal"
        @confirm="closeUnsavedDataModal"
      />
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import {
  every,
  isEmpty,
  uniqBy,
  orderBy,
  cloneDeep,
  omit,
  reject,
  filter,
  startsWith,
  round,
  isEqual,
  some,
  find,
  map,
  padStart,
  each,
  flatMap,
  sortBy,
  get,
  size,
  difference,
  isUndefined,
  findIndex,
} from 'lodash';
import furnitureType from '@enums/furniture-types';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import inputValidationMixin from '@/js/mixins/input-validations';
import numberUtils from '@/js/utils/number-format-utils';

const newlyAddedSpacebreakIdPrefix = 'newlyAddedSpacebreak';

export default {
  mixins: [inputValidationMixin, unsavedDataWarningMixin],
  localizationKey: 'spacebreakCalculatorPage',

  data() {
    return {
      selectedTab: 0,
      uiFields: ['fillSize', 'storeCount', 'mostCommonFurniture'],
      initialStoreClasses: [],
      dependencyTreeFeedback: {},
      dependencyTreeModalOpen: false,
      isUnsavedDataModalOpen: false,
      calledFromOnCalculateSpacebreakClick: false,
      currentStateDiff: {},
      spacebreakConfigurations: {
        groupingThreshold: null,
        minimumStores: null,
      },
      rules: {
        groupingThreshold: [this.isPositive, this.isNumber, this.required],
        minimumStores: [this.isPositive, this.isInteger, this.required],
      },
      isSaving: false,
    };
  },

  computed: {
    ...mapGetters('furniture', [
      'getStoreClassesInScenario',
      'getAllAvailableFurnitureIds',
      'hasFurnitureType',
    ]),
    ...mapGetters('scenarios', ['jobStatuses', 'isJobRunning']),
    ...mapGetters('workpackages', ['getUnitOfMeasure']),
    ...mapGetters('context', ['showNotImplemented', 'getPalletsSlotsEnabled']),
    ...mapState('furniture', ['isLoading']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('workpackages', ['selectedWorkpackage']),

    hasDataChanges() {
      // For each initial storeclass, check if there are any data changes in spacebreaks, return early if there are
      // Data changes mean removed spacebreak, or changed spacebreak data
      return (
        this.areSpacebreaksEqualInStoreClasses({ excludeNames: false, excludePallets: false }) ||
        !isEmpty(this.currentStateDiff)
      );
    },

    hasDataErrors() {
      return (
        !isEmpty(this.getAllAvailableFurnitureIds) ||
        !this.allSpacebreakNamesUnique ||
        !this.allSpacebreakOverrideAreValid ||
        !this.allPalletsAreValid ||
        !this.allSpacebreaksAreFilled ||
        (this.showSpacebreakConfigurations &&
          some(this.rules, (rules, key) =>
            some(rules, r => r(this.spacebreakConfigurations[key]) !== true)
          ))
      );
    },

    isSBJobRunning() {
      return this.isJobRunning('spacebreakGenerator');
    },

    allSpacebreakNamesUnique() {
      return this.getStoreClassesInScenario.every(
        sc => uniqBy(sc.spacebreaks, 'name').length === sc.spacebreaks.length
      );
    },

    hasSpacebreaks() {
      return (
        !this.isSBJobRunning &&
        !isEmpty(this.getStoreClassesInScenario) &&
        every(this.getStoreClassesInScenario, storeClass => !isEmpty(storeClass.spacebreaks))
      );
    },

    allSpacebreakOverrideAreValid() {
      const validations = [
        s => this.required(numberUtils.formatStringToNumber(s.fillOverride)),
        s => this.isPositive(numberUtils.formatStringToNumber(s.fillOverride)),
        s => this.isNumber(numberUtils.formatStringToNumber(s.fillOverride)),
        s => this.isLessThan(numberUtils.formatStringToNumber(s.fillOverride), s.size),
        s => this.isNotEmpty(numberUtils.formatStringToNumber(s.fillOverride)),
        s => this.isGreaterThan(numberUtils.formatStringToNumber(s.fillOverride), 0),
        (s, spacebreaks) => this.isOverrideFillUnique(s, reject(spacebreaks, { _id: s._id })),
      ];

      return this.getStoreClassesInScenario.every(({ spacebreaks }) =>
        spacebreaks.every(s => validations.every(f => f(s, spacebreaks) === true))
      );
    },

    /*
    Pallets should be:
    - positive numbers
    - required
    - not empty
    - all bigger than the previous spacebreak's pallets
     */
    allPalletsAreValid() {
      if (this.getPalletsSlotsEnabled) {
        const validations = [
          s => this.required(numberUtils.formatStringToNumber(s.palletsSlots)),
          s => this.isGreaterOrEqual(numberUtils.formatStringToNumber(s.palletsSlots), 0),
          s => this.isNumber(numberUtils.formatStringToNumber(s.palletsSlots)),
          s => this.isNotEmpty(numberUtils.formatStringToNumber(s.palletsSlots)),
          (s, spacebreaks) => this.isBiggerOrEqualToThanPreviousSpaceBreak(s, spacebreaks),
        ];

        return this.getStoreClassesInScenario.every(({ spacebreaks }) =>
          spacebreaks.every(s => {
            return validations.every(f => f(s, spacebreaks) === true);
          })
        );
      }
      return true;
    },

    allSpacebreaksAreFilled() {
      return this.getStoreClassesInScenario.every(({ spacebreaks }) => {
        return spacebreaks.every(s => {
          return s.generatedFurnitureIds.length > 0 && !isEmpty(s.name);
        });
      });
    },

    isEditingDisabled() {
      return (
        !this.hasPermission(this.userPermissions.canEditSpacebreakCalculatorPage) ||
        !get(this.selectedScenario, 'status.space.canBeEdited', true)
      );
    },

    showSpacebreakConfigurations() {
      // Spacebreak configuration inputs should only be visible if the feature flag is enabled
      // AND there are no manually added furnitures
      return (
        get(this.getClientConfig, 'features.spacebreakConfigurationsEnabled', false) &&
        !this.hasFurnitureType(furnitureType.manual)
      );
    },
  },

  created() {
    this.initialStoreClasses = cloneDeep(this.getStoreClassesInScenario);
    if (this.showSpacebreakConfigurations) this.initialiseSpacebreakConfigurations();
  },

  methods: {
    ...mapActions('furniture', [
      'saveScenarioFurniture',
      'fetchScenarioFurniture',
      'startSpacebreaksJob',
    ]),
    ...mapActions('scenarios', ['updateScenario', 'refreshScenario']),
    ...mapActions('dependencyTree', ['triggerDependencyTree']),

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
    },

    async commitHandler() {
      if (this.calledFromOnCalculateSpacebreakClick) await this.onCalculateSpacebreakClick(true);
      else this.save(true);
    },

    sanitiseStoreClasses(storeClasses, orderSpacebreaks = false) {
      // remove unwanted keys needed for the UI
      const unitOfMeasure = this.getUnitOfMeasure(this.selectedWorkpackage);

      return map(storeClasses, sc => {
        const spacebreaks = map(sc.spacebreaks, sb => {
          return {
            ...omit(sb, this.uiFields),
            shortName: `${round(sb.fillOverride)}${unitOfMeasure}`,
            // nedd to remove the temporary _ids as the server will create Mongo ObjectIds
            _id: startsWith(sb._id, newlyAddedSpacebreakIdPrefix) ? null : sb._id,
          };
        });

        sc.spacebreaks = orderSpacebreaks
          ? orderBy(spacebreaks, ['fillOverride'], ['asc'])
          : spacebreaks;
        return sc;
      });
    },

    initialiseSpacebreakConfigurations() {
      const defaultGroupingThreshold = get(this.getClientConfig, 'spacebreaks.groupingThreshold');
      const defaultMinStores = get(this.getClientConfig, 'spacebreaks.minimumStores');

      // Use values from the client config to set the default values for the spacebreak configurations
      this.spacebreakConfigurations.groupingThreshold = get(
        this.selectedScenario,
        'spacebreakSettings.groupingThreshold',
        defaultGroupingThreshold
      );
      this.spacebreakConfigurations.minimumStores = get(
        this.selectedScenario,
        'spacebreakSettings.minimumStores',
        defaultMinStores
      );

      // Backup configuration data
      this.$options.savedState = cloneDeep(this.spacebreakConfigurations);
    },

    discard() {
      this.fetchScenarioFurniture();
      this.reset();
    },

    reset() {
      this.initialStoreClasses = cloneDeep(this.getStoreClassesInScenario);
      this.currentStateDiff = {};

      // Reset configurations
      if (this.showSpacebreakConfigurations) this.initialiseSpacebreakConfigurations();
    },

    async updateSpacebreakConfigurations() {
      const updates = {
        id: this.selectedScenario._id,
        'spacebreakSettings.groupingThreshold': this.spacebreakConfigurations.groupingThreshold,
        'spacebreakSettings.minimumStores': this.spacebreakConfigurations.minimumStores,
      };

      this.updateScenario({ scenario: updates });
    },

    async save(commit = false) {
      this.isSaving = true;
      const storeClasses = map(this.getStoreClassesInScenario, sc => {
        sc.spacebreaks = orderBy(sc.spacebreaks, ['fillSuggested'], ['asc']);
        return sc;
      });

      // Spacebreaks need to be ordered globally. These are used in store executions.
      function addSequentialSpacebreakKeys(allStoreClasses) {
        const spacebreaks = flatMap(allStoreClasses, 'spacebreaks');
        const sortedSpacebreaks = sortBy(spacebreaks, 'fillOverride');

        each(sortedSpacebreaks, (sb, ix) => {
          sb.sequentialKey = `SB${padStart(ix + 1, 2, 0)}`;
        });
      }

      addSequentialSpacebreakKeys(storeClasses);

      const sanitisedArray = this.sanitiseStoreClasses(storeClasses, true);

      // Trigger dependency tree only in case spacebreaks data was changed
      if (this.areSpacebreaksEqualInStoreClasses({ excludeNames: true, excludePallets: true })) {
        // Perform dependency tree check
        const results = await this.triggerDependencyTree({
          params: { change: 'spacebreaksModified', updates: {}, commit },
        });

        if (results.needsFeedback) {
          this.dependencyTreeFeedback = results.output;
          this.dependencyTreeModalOpen = true;
          this.isSaving = false;
          return;
        }
      }

      const spacebreakPromises = [
        await this.saveScenarioFurniture({ storeClasses: sanitisedArray }),
      ];
      if (this.showSpacebreakConfigurations) {
        spacebreakPromises.push(await this.updateSpacebreakConfigurations());
      }
      await Promise.all(spacebreakPromises);
      await this.refreshScenario();
      this.reset();
      this.isSaving = false;
    },

    async onCalculateSpacebreakClick(commit = false) {
      this.calledFromOnCalculateSpacebreakClick = true; // for dependency tree modal
      // Perform dependency tree check
      const results = await this.triggerDependencyTree({
        params: { change: 'spacebreaksModified', updates: {}, commit },
      });

      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return;
      }
      // Make sure that the current spacebreak configurations are saved before the job is triggered
      if (this.showSpacebreakConfigurations) await this.updateSpacebreakConfigurations();
      // Clear currentStateDiff after saving configurations to ensure buttons have the correct disabled state
      this.currentStateDiff = {};
      await this.startSpacebreaksJob();
    },

    isOverrideFillUnique(spacebreak, otherSpacebreaks) {
      const spacebreaksWithSameSize = filter(otherSpacebreaks, {
        fillOverride: spacebreak.fillOverride,
      });

      return isEmpty(spacebreaksWithSameSize);
    },

    isBiggerOrEqualToThanPreviousSpaceBreak(currentSpacebreak, allSpacebreaks) {
      const currentSpacebreakIndex = findIndex(allSpacebreaks, { _id: currentSpacebreak._id });
      const palletsToCompare = get(allSpacebreaks[currentSpacebreakIndex - 1], `palletsSlots`, 0);

      const sanitisedCurrentPalletValue = numberUtils.formatStringToNumber(
        currentSpacebreak.palletsSlots
      );
      const sanitisedPreviousPalletValue = numberUtils.formatStringToNumber(palletsToCompare);

      return (
        sanitisedCurrentPalletValue >= 0 &&
        sanitisedCurrentPalletValue >= sanitisedPreviousPalletValue
      );
    },

    areSpacebreaksEqualInStoreClasses({ excludeNames = false, excludePallets = false } = {}) {
      const sanitisedInitialSC = this.sanitiseStoreClasses(cloneDeep(this.initialStoreClasses));
      const sanitisedCurrentSC = this.sanitiseStoreClasses(this.getStoreClassesInScenario);

      const initialSpacebreaks = sanitisedInitialSC.map(sc => get(sc, 'spacebreaks', []).length);
      const modifiedSpacebreaks = sanitisedCurrentSC.map(sc => get(sc, 'spacebreaks', []).length);
      const hasNewSpacebreaks =
        difference(initialSpacebreaks, modifiedSpacebreaks).length > 0 ||
        difference(modifiedSpacebreaks, initialSpacebreaks).length > 0;

      if (hasNewSpacebreaks) return true;
      // For each initial storeclass, check if there are any changes in spacebreaks, return early if there are
      return some(sanitisedInitialSC, initialSC => {
        const currentSC = find(sanitisedCurrentSC, { _id: initialSC._id });
        if (!currentSC) return false;

        return some(initialSC.spacebreaks, initialSB => {
          const currentSB = find(currentSC.spacebreaks, { _id: initialSB._id });

          if (!currentSB) return true; // return true if spacebreak was removed

          // First we check that spacebreaks are the same excluding generatedFurnitureIds
          // We also exclude names in case we check for dependency tree
          const fieldsToExclude = ['generatedFurnitureIds', 'storeKeys'];
          if (excludeNames) fieldsToExclude.push('name');
          if (excludePallets && this.getPalletsSlotsEnabled) fieldsToExclude.push('palletsSlots');

          const isPureEqual = isEqual(
            omit(initialSB, fieldsToExclude),
            omit(currentSB, fieldsToExclude)
          );

          const initialGfIds = initialSB.generatedFurnitureIds;
          const currentGfIds = currentSB.generatedFurnitureIds;

          // Then we check that all current ids are also in initial and that sizes are the same
          // This is because after drag and drop furniture id goes to the end of the list, so we can't compare using _.isEqual
          // We don't check store keys here because they depend on furniture ids
          const areGfIdsTheSame =
            isEqual(size(initialGfIds), size(currentGfIds)) &&
            every(currentGfIds, currentId => initialGfIds.includes(currentId));

          return !isPureEqual || !areGfIdsTheSame;
        });
      });
    },

    updateDiff(field, currentValue) {
      const originalValue = get(this.$options.savedState, field);
      const existingValue = get(this.currentStateDiff, field);
      const valueHasChanged = currentValue !== originalValue;

      if (valueHasChanged) {
        this.$set(this.currentStateDiff, field, currentValue);
      } else if (!isUndefined(existingValue)) {
        // Remove the field from the object if it's back to its old value.
        this.$delete(this.currentStateDiff, field);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.actions-container {
  max-width: none !important;
}

.calculate-btn-container {
  display: inline-block;
  padding-left: 14px;
  padding-right: 15px;
  border-right: 1px solid $assortment-horizontal-border-colour;
}

.page-actions-container {
  border-top: 1px solid $assortment-panel-border-divider-colour;
}

.main-panel {
  height: 500px;
  overflow-y: auto;
}

.store-class-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  ::v-deep {
    .v-tabs-bar {
      flex-grow: 0;
      height: 26px;
      margin-bottom: 5px;

      &__content {
        border-bottom: 1px solid $assortment-tile-border-colour;
      }
    }

    .v-tabs-items {
      flex-grow: 1;
      position: relative;
    }

    .v-window__container {
      height: 100%;

      .v-window-item {
        height: 100%;
      }
    }

    .v-slide-group__prev,
    .v-slide-group__next {
      position: relative;
      visibility: visible !important;
      &::after {
        content: '';
        width: 100%;
        position: absolute;
        border-bottom: 1px solid $assortment-tile-border-colour;
        height: 100%;
        bottom: 0px;
      }
    }
    .v-slide-group__next {
      &::after {
        border-left: 1px solid $assortment-tile-border-colour;
      }
    }
    .v-slide-group__prev {
      &::after {
        border-right: 1px solid $assortment-tile-border-colour;
      }
    }
  }

  .store-class-tab {
    box-sizing: border-box;
    height: 26px;
    width: auto;
    border-color: $assortment-tile-border-colour;
    background: $assortment-unselected-tab-colour;
    border-left: 1px solid $assortment-tile-border-colour;
    border-bottom: 1px solid $assortment-tile-border-colour;

    &:first-child {
      border-left: 0px;
    }
    &.v-tab--active {
      background: $assortment-selected-tab-colour;
      border-bottom: 0px;
    }
  }
}

.v-btn {
  margin: 6px;
}

.discard-btn {
  margin-left: 15px;
}

.empty-switching {
  background-color: $assortment-background;
  min-height: 500px;
}

.empty-switching-text {
  font-size: 1.4rem;
  color: $assortment-text-colour;
}

.spacebreak-configurations {
  flex: 0 0 auto;
  width: auto;

  &__input {
    width: 80px;
  }

  &__label {
    display: inline-block;
    font-weight: bold;
  }

  &__message {
    font-size: 1.2rem;
  }
}

.spacebreak-actions {
  flex: 1 1 auto;
  width: auto;
}
</style>
