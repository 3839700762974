'use strict';

// These events are client side only. Avoid special characters like ':' in the event's value.

module.exports = {
  extracts: {
    generateExtractRequested: 'generate-extract-requested',
    extractGenerated: 'extract-generated',
    compoundExtractGenerated: 'compound-extract-generated',
  },
  assortmentCanvas: {
    constraintsValidationOccurred: 'constraints-validation-occurred',
  },
  template: {
    runTemplateSetup: 'run-template-setup',
  },
};
