import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex justify-end" },
    [
      _c(
        VBtn,
        {
          attrs: { ripple: false, icon: "", disabled: "" },
          on: { click: _vm.edit },
        },
        [
          _c(VIcon, { attrs: { size: 24 } }, [
            _vm._v("expand_" + _vm._s(_vm.editingMode ? "less" : "more")),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }