<template>
  <v-card class="wp-scope-products col-box">
    <div class="wp-scope-products__header mb-1">
      <h3 class="wp-scope-products__input-heading">
        {{ $t('workpackagePage.scope.products') }}

        <!-- Products scope tooltip -->
        <assortment-tooltip
          :title="$t('tooltips.productScope.title')"
          :tooltip-sections="productScopeTooltipSections"
        />
      </h3>
      <div>
        <workpackage-products />
      </div>
    </div>

    <div class="grid-wrapper">
      <div class="item">{{ $t('workpackagePage.scope.productsInScope') }}</div>
      <div class="item item__count">
        {{ workpackage.productCount ? workpackage.productCount.total : 0 }}
      </div>

      <template v-if="templatesEnabled && workpackage.templateId">
        <div class="item">{{ $t('workpackagePage.scope.fromTemplate') }}</div>
        <div class="item item__count">
          {{ workpackage.productCount ? workpackage.productCount.fromTemplate : 0 }}
        </div>
      </template>

      <template v-if="hasProductsInResetEnabled">
        <div class="item">{{ $t('workpackagePage.scope.inReset') }}</div>
        <div class="item item__count">
          {{ workpackage.productCount ? workpackage.productCount.inReset : 0 }}
        </div>
      </template>

      <div class="item">{{ $t('workpackagePage.scope.active') }}</div>
      <div class="item item__count">
        {{ workpackage.productCount ? workpackage.productCount.active : 0 }}
      </div>

      <div class="item">{{ $t('workpackagePage.scope.inactive') }}</div>
      <div class="item item__count">
        {{ workpackage.productCount ? workpackage.productCount.inactive : 0 }}
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { get } from 'lodash';
import { workpackageProductsTooltipOptionsMixin } from '@/js/mixins/tooltip-options';

export default {
  mixins: [workpackageProductsTooltipOptionsMixin],

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('context', ['getClientConfig']),

    workpackage() {
      return this.selectedWorkpackage;
    },

    hasProductsInResetEnabled() {
      return get(this.getClientConfig, 'features.productsInResetEnabled', false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.wp-scope-products {
  font-size: 1.2rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input-heading {
    font-size: 1.2rem;
    color: $assortment-workpackage-text;

    span {
      font-weight: normal;
    }
  }

  .v-btn {
    padding: 0;
  }

  .grid-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 1px;
    background-color: $assortment-workpackage-frame-bg-blue;

    .item {
      padding: 4px 8px;
      background-color: $assortment-table-header-white-background;
      color: $assortment-workpackage-text;
      line-height: 16px;

      &__count {
        font-weight: bold;
      }
    }
  }
}
</style>
