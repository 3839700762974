<template>
  <div class="target-launch">
    <h4 class="header">{{ title }}</h4>

    <rtls-calendar-picker
      :value="dateValue"
      :disabled="disabled"
      :display-date-format="getDateFormats.long"
      class="dense-calendar"
      offset-x
      bold-text
      @input="changeTargetLaunchDate"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import datesMixin from '@/js/mixins/date-utils';

export default {
  mixins: [datesMixin],

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    title: {
      type: String,
      required: false,
      default: '',
    },

    value: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters('context', ['getDateFormats', 'getDefaultReverseFormat']),

    dateValue() {
      return this.formatDateForPicker(this.value, this.getDefaultReverseFormat);
    },
  },

  methods: {
    changeTargetLaunchDate(date) {
      this.$emit('input', date);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.target-launch {
  min-width: 90px;

  ::v-deep.v-input--is-disabled .v-input__append-inner .v-icon g {
    stroke: $assortment-disabled-icon-colour;
  }
}
</style>
