'use strict';

module.exports = {
  byNameAsc: 'byNameAsc',
  byNameDesc: 'byNameDesc',
  byCreatedDateAsc: 'byCreatedDateAsc',
  byCreatedDateDesc: 'byCreatedDateDesc',
  byLastModifiedDateAsc: 'byLastModifiedDateAsc',
  byLastModifiedDateDesc: 'byLastModifiedDateDesc',
};
