<template>
  <div class="d-flex flex-column">
    <v-row class="mb-3 d-flex flex-column">
      <h2 class="mb-1">{{ $tkey('title') }}</h2>
      <div class="editing-text">{{ $tkey('editing') }}</div>
    </v-row>
    <v-row class="spacebreak-stats d-flex">
      <v-col class="d-flex align-center">
        <h4 class="mr-1">
          {{ $tkey('spaceAvailable') }} ({{ unitOfMeasure }}):
          <span class="unit-label">{{
            formatNumber({ number: totalSpace, format: 'float' })
          }}</span>
        </h4>
      </v-col>
      <v-col class="d-flex">
        <h4 class="space-heading">{{ $tkey('usableSpace') }} ({{ unitOfMeasure }}):</h4>
        <rtls-text-field
          run-validations-on-creation
          white
          validate-on-blur
          :value="formatNumber({ number: fillOverride, format: 'float' })"
          :rules="fillOverrideRules"
          :server-error="hasServerErrors"
          class="table-input"
          :disabled="isEditingDisabled"
          hide-details
          width="50px"
          @blur="updateFillOverride"
          @keyup.enter="updateFillOverride"
        />
      </v-col>
      <v-col class="d-flex align-center">
        <h4 class="mr-1">
          {{ $tkey('setAside') }} ({{ unitOfMeasure }}):
          <span class="unit-label">{{ formatNumber({ number: setAside, format: 'float' }) }}</span>
        </h4>
      </v-col>
      <v-col class="d-flex align-center">
        <h4 class="mr-1">
          {{ $tkey('spaceUsed') }} ({{ unitOfMeasure }}):
          <span class="unit-label">
            {{ formatNumber({ number: spaceUsed, format: 'float' }) }}</span
          >
        </h4>
      </v-col>
      <v-col class="d-flex align-center">
        <h4 class="mr-1">
          {{ $tkey('spaceFree') }} ({{ unitOfMeasure }}):
          <span class="unit-label">{{ formatNumber({ number: spaceFree, format: 'float' }) }}</span>
        </h4>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
          :disabled="hasDataErrors || !hasDataChanges || isEditingDisabled"
          primary
          action
          depressed
          @click="save"
          >{{ $t('actions.save') }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import {
  camelCase,
  forEach,
  cloneDeep,
  find,
  reject,
  isEmpty,
  map,
  isNaN,
  filter,
  sortBy,
  isEqual,
  merge,
  trim,
} from 'lodash';
import inputValidationMixin from '../../mixins/input-validations';
import numberUtils from '../../utils/number-format-utils';

export default {
  localizationKey: 'assortmentCanvasPage.spacebreakSettings.stats',
  mixins: [inputValidationMixin],
  props: {
    spacebreak: {
      type: Object,
      required: true,
    },
    storeClassId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      fillOverrideRules: [
        this.isPositive,
        this.isNumber,
        this.isNotEmpty,
        this.required,
        this.maintainsSpacebreakOrder,
      ],
      serverRules: [
        (v, size) => this.isLessThan(v, size),
        this.isUnique,
        v => this.isGreaterThan(v, 0),
      ],
      fillOverride: null,
      storeClasses: null,
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('assortmentCanvas', ['spacebreaks']),
    ...mapGetters('furniture', ['getStoreClassesInScenario']),
    ...mapGetters('assortmentCanvas', ['spaceUsedPerSpacebreak']),

    unitOfMeasure() {
      return this.$t(`suffixes.${camelCase(this.selectedWorkpackage.fillInSelection)}`);
    },

    allSpacebreakOverrideAreValid() {
      if (!this.storeClasses) return;
      const validations = [
        s => this.required(numberUtils.formatStringToNumber(s.fillOverride)),
        s => this.isPositive(numberUtils.formatStringToNumber(s.fillOverride)),
        s => this.isNumber(numberUtils.formatStringToNumber(s.fillOverride)),
        s => this.isLessThan(numberUtils.formatStringToNumber(s.fillOverride), s.size),
        s => this.isNotEmpty(numberUtils.formatStringToNumber(s.fillOverride)),
        s => this.isGreaterThan(numberUtils.formatStringToNumber(s.fillOverride), 0),
        () => this.maintainsSpacebreakOrder(),
        (s, spacebreaks) => this.isOverrideFillUnique(s, reject(spacebreaks, { _id: s._id })),
      ];

      return this.storeClasses.every(({ spacebreaks }) =>
        spacebreaks.every(s => validations.every(f => f(s, spacebreaks) === true))
      );
    },

    hasDataErrors() {
      return !this.allSpacebreakOverrideAreValid;
    },

    hasDataChanges() {
      // data property for fillOverride does not match the prop fillOverride - indicates a change in data
      return this.fillOverride !== this.usableSpace;
    },

    hasServerErrors() {
      // errorOrValid is either a string of the error or boolean value: true which indicates a valid entry
      let errorOrValid = null;
      forEach(this.serverRules, rule => {
        // If errorOrValid is true, then the check has passed and the rule is valid
        errorOrValid = rule(this.fillOverride, this.spacebreak.size);
        // we need the !== true comparison as the rule either returns true (valid) or a string (for error).
        if (errorOrValid !== true) {
          // there is an error, the rule has not passed and we exit the loop, causing a return of the error
          return false;
        }
      });
      // if there is an error return the error, else return an empty string
      return errorOrValid !== true ? errorOrValid : '';
    },

    usableSpace() {
      return this.spacebreak.fillOverride;
    },

    space() {
      return this.spaceUsedPerSpacebreak[this.spacebreak._id];
    },

    totalSpace() {
      return this.spacebreak.size;
    },

    excessSpace() {
      return this.setAside + this.spaceUsed - this.spacebreak.size;
    },

    spaceFree() {
      return this.spacebreak.size - this.setAside - this.space;
    },

    setAside() {
      return this.spacebreak.size - this.spacebreak.fillOverride;
    },

    spaceUsed() {
      return this.space;
    },

    storeClass() {
      return this.storeClasses ? find(this.storeClasses, { _id: this.storeClassId }) : null;
    },

    savedStoreClasses() {
      return this.getStoreClassesInScenario;
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAssortmentCanvas);
    },
  },

  watch: {
    spacebreak() {
      this.init();
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapMutations('assortmentCanvas', ['setSpacebreaks', 'setSettingsPanelSpacebreak']),
    ...mapActions('furniture', ['saveScenarioFurniture']),

    updateFillOverride(event) {
      const newValue = event.target.value;
      this.fillOverride = this.parseFillOverride(newValue);
      // find matching spacebreak in the storeClasses object and update the spacebreak
      const spacebreak = find(this.storeClass.spacebreaks, { _id: this.spacebreak._id });
      merge(spacebreak, {
        fillOverride: numberUtils.formatStringToNumber(this.fillOverride),
      });
    },

    trimSpacebreakName(name) {
      // Truncate spacebreak names if too long, avoids large tooltip errors
      if (name.length < 30) return name;
      return `${trim(name).slice(0, 30)}...`;
    },

    isUnique(newValue) {
      const otherSpacebreaks = reject(this.spacebreaks, { _id: this.spacebreak._id });
      const spacebreakMatchingSize = find(otherSpacebreaks, {
        fillOverride: numberUtils.formatStringToNumber(newValue),
      });

      return (
        isEmpty(spacebreakMatchingSize) ||
        this.$t('spacebreakCalculatorPage.fillNotUnique', [
          this.trimSpacebreakName(this.spacebreak.name),
          this.trimSpacebreakName(spacebreakMatchingSize.name),
        ])
      );
    },

    parseFillOverride(rawNumber) {
      const parsedNumber = numberUtils.formatStringToNumber(rawNumber);
      return this.isNotEmpty(rawNumber) !== true || isNaN(parsedNumber)
        ? rawNumber
        : numberUtils.formatNumber({ number: rawNumber, format: 'float' });
    },

    isOverrideFillUnique(spacebreak, otherSpacebreaks) {
      const spacebreaksWithSameSize = filter(otherSpacebreaks, {
        fillOverride: spacebreak.fillOverride,
      });

      return isEmpty(spacebreaksWithSameSize);
    },

    setFillOverride() {
      this.fillOverride = this.spacebreak.fillOverride;
    },

    getSpacebreakOrderArray(spacebreaks) {
      return sortBy(spacebreaks, 'fillOverride').map(({ _id }) => _id);
    },

    maintainsSpacebreakOrder() {
      const previousStoreClass = find(this.savedStoreClasses, { _id: this.storeClassId });
      const previousSpacebreakOrder = this.getSpacebreakOrderArray(previousStoreClass.spacebreaks);
      const currentSpacebreakOrder = this.getSpacebreakOrderArray(this.storeClass.spacebreaks);
      return (
        isEqual(previousSpacebreakOrder, currentSpacebreakOrder) ||
        this.$t('validationErrors.spacebreakOrderChanged')
      );
    },

    resetStoreClasses() {
      this.storeClasses = cloneDeep(this.getStoreClassesInScenario);
    },

    init() {
      this.resetStoreClasses();
      this.setFillOverride();
    },

    async save() {
      const storeClasses = map(this.storeClasses, sc => {
        sc.spacebreaks = sortBy(sc.spacebreaks, 'fillOverride');
        return sc;
      });

      await this.saveScenarioFurniture({
        storeClasses,
      });

      const currentStoreClass = find(this.savedStoreClasses, { _id: this.storeClassId });
      // This sets the spacebreaks info on assortment canvas store
      this.setSpacebreaks(currentStoreClass.spacebreaks);

      const currentSpacebreak = find(currentStoreClass.spacebreaks, { _id: this.spacebreak._id });
      this.setSettingsPanelSpacebreak(currentSpacebreak);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.editing-text {
  font-size: 1rem;
}

.spacebreak-stats {
  border-bottom: 1px solid $assortment-divider-colour;
  .col {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 15px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  .space-heading {
    white-space: nowrap;
  }

  .unit-label {
    font-size: 1.2rem;
    font-weight: normal;
    margin-left: 2px;
  }
}
</style>
