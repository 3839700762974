<template>
  <v-container fluid class="mb-4">
    <v-layout column>
      <bundles-active />
      <bundles-archived />
    </v-layout>
  </v-container>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

export default {
  created() {
    // Ensure that scenario panel is not expanded in the bundles page
    this.toggleScenarioPanelExpanded();
  },

  beforeDestroy() {
    this.setSelectedBundle(null);
    this.setWorkpackagePanelExpanded(false);
  },

  methods: {
    ...mapMutations('bundles', ['setSelectedBundle', 'setWorkpackagePanelExpanded']),
    ...mapActions('workpackages', ['toggleScenarioPanelExpanded']),
  },
};
</script>
