<template>
  <v-avatar size="23" :color="color">
    <span class="white--text headline">{{ user.name }}</span>
  </v-avatar>
</template>

<script>
import colors from '../ow-colors';

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    color() {
      // Greying out until actual avatars are in place
      return colors.assortmentDisabledTextButtonColour;
      // return this.user.permissions.access ? 'black' : 'grey darken-1';
    },
  },
};
</script>
