<template>
  <v-dialog v-model="dialog" persistent retain-focus max-width="350" content-class="rtls-dialog">
    <template v-slot:activator="{ on: onDialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            class="delete-button"
            icon
            text
            :disabled="disabled"
            :loading="loading"
            v-on="{ ...onDialog, ...onTooltip }"
          >
            <v-icon>$trash</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('actions.delete') }}</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title class="title" primary-title>
        <v-btn class="close-button" icon @click="closeModal">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="display-1 text-center">
        <p>{{ questionMessage }}</p>
        <strong>{{ $tkey('warning') }}</strong>
      </v-card-text>

      <v-card-actions class="justify-center flex-column">
        <v-btn primary class="btn-delete ma-2" @click="_deleteWorkpackage">
          {{ actionMessage }}
        </v-btn>
        <v-hover disabled>
          <v-btn text depressed class="cancel ma-2" @click="closeModal">{{
            $t('actions.cancel')
          }}</v-btn>
        </v-hover>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import entities from '@enums/entities';

export default {
  localizationKey: 'workpackages.options.delete',
  props: {
    workpackage: {
      required: true,
      type: Object,
    },
    entity: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('context', ['getClientConfig']),

    workpackageName() {
      return this.workpackage.name;
    },
    disabled() {
      return !this.hasPermission(this.userPermissions.canDeleteWorkpackage);
    },
    isTemplate() {
      return this.entity === entities.template;
    },
    questionMessage() {
      if (this.isTemplate) {
        return this.$t('templatesPage.options.delete.question', {
          templateName: this.workpackageName,
        });
      }

      return this.$tkey('question', { workpackageName: this.workpackageName });
    },
    actionMessage() {
      if (this.isTemplate) {
        return this.$t('templatesPage.options.delete.action');
      }

      return this.$tkey('action');
    },
    isTemplateDeleteEnabled() {
      return get(this.getClientConfig, 'betaFeatures.templateDeleteEnabled', false);
    },
  },
  methods: {
    ...mapMutations('workpackages', [
      'setWorkpackageSetupExpanded',
      'setSelectedWorkpackage',
      'setScenarioPanelExpanded',
    ]),
    ...mapActions('workpackages', ['deleteWorkpackage', 'deleteTemplate']),
    async _deleteWorkpackage() {
      this.loading = true;
      try {
        this.closeModal();

        if (!this.isTemplateDeleteEnabled && this.isTemplate) return;

        if (this.isTemplate) {
          await this.deleteTemplate(this.workpackage);
          return;
        }

        await this.deleteWorkpackage(this.workpackage);
        this.setWorkpackageSetupExpanded(false);
        this.setSelectedWorkpackage(null);
        this.setScenarioPanelExpanded(false);
      } finally {
        this.loading = false;
      }
    },
    closeModal() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.title {
  position: relative;
}

.delete-button {
  .v-icon {
    color: $assortment-primary-colour;
  }
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
