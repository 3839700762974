<template>
  <v-container fluid class="pa-0 flex-grow-1 d-flex flex-column">
    <v-row class="header ma-0">
      <v-col cols="2" class="pa-3 font-weight-bold d-block">
        <span class="pa-3 font-weight-bold d-block">
          {{ $tkey('clusteringSchemes') }}
        </span>
      </v-col>
      <v-col cols="8" class="pa-3 font-weight-bold d-block">
        <span class="pa-3 font-weight-bold d-block">
          {{ selectedClusterSchemeName }}
        </span>
      </v-col>
      <v-col cols="2" class="actions-col d-flex justify-end">
        <v-select
          id="refSelectClusterName"
          v-model="selectedClusterToMoveTo"
          :height="24"
          :disabled="!hasSelectedStores"
          :items="selectableClusterNames"
          :placeholder="$tkey('moveSelectedStoresToCluster')"
          hide-details
          flat
          dense
          class="ml-4 mt-2 mr-2"
          @input="moveStores($event)"
        />
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="main-panel flex-grow-1">
      <v-col cols="2" class="rtls-border rtls-border--right">
        <v-container
          id="ref-cluster-schemes-table"
          :class="{ 'empty-table': !getClusterSchemes.length }"
        >
          <!-- Headers -->
          <v-row id="headers-row">
            <v-col
              v-for="(header, index) in headers"
              :key="`header-${index}`"
              :cols="header.cols"
              :class="header.headerClass"
              :disabled="isNavigationDisabled"
            >
              <span>{{ header.text }}</span>
            </v-col>
          </v-row>
          <v-row
            v-for="row in clusterSchemes"
            :key="row._id"
            :selected="selectedScheme && selectedScheme._id === row._id"
            class="cluster-row"
          >
            <v-col
              v-for="header in headers"
              :key="`cell-${row._id}-${header.id}`"
              style="height: 30px"
              :cols="header.cols"
              :class="header.colClass"
            >
              <template v-if="header.ui === CELL_TYPE.icon">
                <v-icon
                  v-if="showClusterWarning(header.id, row)"
                  :class="header.iconClass"
                  :disabled="isNavigationDisabled || row.disabled"
                  @click="header.onClick(row)"
                >
                  {{ header.value }}
                </v-icon>
              </template>
              <template v-else>
                {{ row[header.value] }}
              </template>
            </v-col>
          </v-row>
        </v-container>
        <v-divider horizontal />
        <v-btn class="mt-2 ml-2" primary @click="openClusterCreationModal">
          {{ $tkey('addScheme') }}
        </v-btn>
      </v-col>

      <v-col cols="10">
        <div style="width: 100%; height: 100%;">
          <v-divider />
          <store-allocation-table
            v-if="selectedScheme"
            :key="`ref-store-allocation-table-${selectedScheme._id}`"
            ref="ref-store-allocation-table"
            mode="reference-cluster"
            @has-selected-rows="hasSelectedStores = $event"
            @data-changed="hasDataChanges = $event"
            @on-save="onSave()"
          />
        </div>
      </v-col>
    </v-row>

    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="closeUnsavedDataModal"
      @confirm="closeUnsavedDataModal"
    />

    <cluster-creation-modal
      v-model="isClusterCreationModalOpen"
      @process="processNewClusterSchemes"
      @close="closeClusterCreationModal"
    />

    <!-- edit confirmation dialog -->
    <main-dialog ref="edit">
      <template v-slot:header>
        <v-card-text class="pa-0">
          <p>{{ $tkey('edit.question') }}</p>
          <div class="float-left">
            <rtls-text-field v-model="editClusterSchemeName" grey class="ml-2 my-2" />
          </div>
        </v-card-text>
      </template>
      <template v-slot:content="{ cancel, confirm }">
        <v-card-actions class="float-right">
          <span v-if="nameHasError" class="my-4">
            <i class="material-icons-round error-triangle-icon">warning_amber</i>
          </span>
          <v-btn primary class="ma-2 mr-4" :disabled="nameHasError" @click="confirm">
            {{ $tkey('edit.action') }}
          </v-btn>
          <v-btn text depressed class="cancel ma-2" @click="cancel">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </template>
    </main-dialog>

    <!-- delete confirmation dialog -->
    <main-dialog ref="confirm">
      <template v-slot:header>
        <v-card-text class="display-1 pa-0 text-center">
          <p>{{ $tkey('delete.question') }}</p>
          <strong>{{ $tkey('delete.warning') }}</strong>
        </v-card-text>
      </template>
      <template v-slot:content="{ cancel, confirm }">
        <v-card-actions class="float-right">
          <v-btn primary class="ma-2" @click="confirm">
            {{ $tkey('delete.action') }}
          </v-btn>
          <v-btn text depressed class="cancel ma-2" @click="cancel">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </template>
    </main-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { CELL_TYPE } from '@enums/table/index';
import { filter, get, isEqual, some, trim } from 'lodash';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';

export default {
  localizationKey: 'clientAdminPage',
  mixins: [unsavedDataWarningMixin],
  data() {
    return {
      CELL_TYPE,
      clusterSchemes: [],
      selectedClusterSchemeName: '',
      editClusterSchemeName: '',
      selectedClusterToMoveTo: null, // used for store-allocation-list edit
      hasDataChanges: false,
      hasSelectedStores: false, // used to disable cluster select when no stores selected
      isClusterCreationModalOpen: false,
      headers: [
        {
          value: 'name',
          ui: CELL_TYPE.readonly,
          id: 'schemeName',
          cols: 5,
          headerClass: 'pl-2 px-0 py-0 pr-0',
          colClass: 'pl-2 px-0 py-0 pr-0',
          onClick: this.handleExpandClusteringScheme,
          text: this.$tkey('clusterSchemeName'),
        },
        {
          text: this.$tkey('clusterCount'),
          value: 'clusterCount',
          class: 'pl-0',
          cols: 2,
          headerClass: 'pa-0 header-text',
          colClass: 'pa-0 justify-end',
          ui: CELL_TYPE.readonly,
          id: 'clusterCount',
        },
        {
          class: 'p-0',
          cols: 1,
          ui: CELL_TYPE.icon,
          colClass: 'justify-end pr-1 pl-0 px-0 py-0',
          iconClass: 'v-icon-blue',
          onClick: this.handleDeleteClusteringScheme,
          value: '$trash',
          id: 'deleteButton',
        },
        {
          class: 'p-0',
          cols: 1,
          ui: CELL_TYPE.icon,
          colClass: 'justify-end pr-1 pl-0 px-0 py-0',
          iconClass: 'v-icon-blue',
          onClick: this.handleEditClusteringScheme,
          value: '$edit',
          id: 'editButton',
        },
        {
          class: 'p-0',
          cols: 1,
          ui: CELL_TYPE.icon,
          colClass: 'justify-end pr-1 pl-0 px-0 py-0',
          iconClass: 'v-icon-red',
          // onClick: this.handleEditClusteringScheme,
          value: '$warning',
          id: 'warningButton',
        },
        {
          class: 'p-0',
          cols: 2,
          ui: CELL_TYPE.icon,
          colClass: 'justify-end pr-1 pl-0 px-0 py-0',
          iconClass: 'v-icon-blue',
          onClick: this.handleExpandClusteringScheme,
          value: 'keyboard_arrow_right',
          id: 'expandButton',
        },
      ],
    };
  },

  computed: {
    ...mapGetters('context', ['getClientConfig']),
    ...mapGetters('referenceClustering', ['getClusterSchemes']),
    ...mapState('referenceClustering', ['selectedScheme', 'selectableClusterNames']),
    ...mapGetters('toolData', ['stores']),

    hasDefaultClustersEnabled() {
      return get(this.getClientConfig, 'features.defaultClustersEnabled', false);
    },

    isNavigationDisabled() {
      return !this.getClusterSchemes.length;
    },

    invalidClusteringSchemes() {
      const isNoDataCluster = filter(this.getClusterSchemes, clScheme => {
        // cluster is invalid if it's marked as no data cluster
        return some(clScheme.clusters, 'isNoDataCluster');
      });

      const unassigned = filter(this.getClusterSchemes, clScheme => {
        // cluster is invalid if it's marked as no data cluster
        return some(clScheme.clusters, { clusterName: 'Unassigned' });
      });

      return [...isNoDataCluster, ...unassigned];
    },

    invalidClusteringScheme() {
      const isNoDataCluster = filter(this.getClusterSchemes, clScheme => {
        // cluster is invalid if it's marked as no data cluster
        return some(clScheme.clusters, 'isNoDataCluster');
      });

      const unassigned = filter(this.getClusterSchemes, clScheme => {
        // cluster is invalid if it's marked as no data cluster
        return some(clScheme.clusters, { clusterName: 'Unassigned' });
      });

      return [...isNoDataCluster, ...unassigned];
    },

    nameHasError() {
      return (
        this.editClusterSchemeName === '' ||
        !this.hasUniqueName({ name: this.editClusterSchemeName })
      );
    },

    showWarning() {
      return false;
    },
  },

  watch: {
    selectedScheme() {
      if (!this.selectedScheme) return;
      this.selectedClusterSchemeName = this.selectedScheme.name;
      this.setSelectableClusterNames(this.selectedScheme.clusters.map(c => c.clusterName));
    },
  },

  async created() {
    await this.reset();
  },

  methods: {
    ...mapActions('snackbar', ['showSuccess', 'showError']),
    ...mapActions('toolData', ['getStores']),
    ...mapActions('referenceClustering', [
      'deleteClusterScheme',
      'fetchClusterScheme',
      'fetchClusterSchemes',
      'updateClusterSchemeName',
      'saveClusteringSchemes',
    ]),
    ...mapMutations('referenceClustering', ['setSelectedScheme', 'setSelectableClusterNames']),

    async reset() {
      await this.getStores();
      this.storeKeys = new Set(this.stores.map(c => c.storeKey));
      this.clusterSchemes = await this.fetchClusterSchemes();
      // set the first one as the selected scheme
      if (this.clusterSchemes) {
        this.setSelectedScheme(this.clusterSchemes[0]);
      }
    },

    showClusterWarning(headerId, scheme) {
      if (headerId !== 'warningButton') {
        return true;
      }
      if (!scheme.clusters || !scheme.clusterCount || scheme.clusterCount < 2) {
        return true;
      }
      if (some(scheme.clusters, 'isNoDataCluster')) {
        return true;
      }
      // get the stores assigned, if not the same as all stores, then alert
      const assignedStoreKeys = [];
      scheme.clusters.forEach(c => {
        assignedStoreKeys.push(...c.storeKeys);
      });
      const currentStoreKeys = new Set(assignedStoreKeys);
      if (!isEqual(currentStoreKeys, this.storeKeys)) {
        return true;
      }
      return false;
    },

    expandClusteringScheme(clusterScheme) {
      // if (clusterScheme.disabled) return;
      this.setSelectedScheme(clusterScheme);
      this.fetchClusterScheme(clusterScheme); // reload from the database
    },

    handleExpandClusteringScheme(clusterScheme) {
      this.beforeNavWithUnsavedData(() => {
        this.expandClusteringScheme(clusterScheme);
      });
    },

    async handleDeleteClusteringScheme(clusterScheme) {
      if (await this.$refs.confirm.open()) {
        this.confirmDelete(clusterScheme._id);
      }
    },

    async confirmDelete(clusterSchemeId) {
      await this.deleteClusterScheme(clusterSchemeId);
      this.clusterSchemes = await this.fetchClusterSchemes();
      // set the first one as the selected scheme
      if (this.clusterSchemes) {
        this.setSelectedScheme(this.clusterSchemes[0]);
      }
    },

    async handleEditClusteringScheme(clusterScheme) {
      this.editClusterSchemeName = clusterScheme.name;
      if (await this.$refs.edit.open()) {
        this.confirmEdit(clusterScheme._id);
      }
    },

    async confirmEdit(clusterSchemeId) {
      await this.updateClusterSchemeName({ clusterSchemeId, name: this.editClusterSchemeName });
      this.clusterSchemes = await this.fetchClusterSchemes();
      // set the first one as the selected scheme
      if (this.clusterSchemes) {
        this.setSelectedScheme(this.clusterSchemes[0]);
      }
    },

    moveStores(groupClusterName) {
      this.$refs['ref-store-allocation-table'].moveRowsToGroup({ groupClusterName });
      // without $nextTick first reset will work but all subsequent ones won't work.
      // The vuetify internal lazy value gets out of sync with the actual value.
      // similar issue on promo page-navigator.vue cancelUpdatePageCount.
      this.$nextTick(() => {
        this.selectedClusterToMoveTo = 0;
      });
    },

    openClusterCreationModal() {
      this.isClusterCreationModalOpen = true;
    },

    closeClusterCreationModal() {
      this.isClusterCreationModalOpen = false;
    },

    async processNewClusterSchemes(body) {
      await this.saveClusteringSchemes(body);
      this.clusterSchemes = await this.fetchClusterSchemes();
      // set the first one as the selected scheme
      if (this.clusterSchemes) {
        this.setSelectedScheme(this.clusterSchemes[0]);
      }
    },

    hasUniqueName({ name }) {
      const newName = name;
      // We check if the name is unique whithin the list to be created and the list of existing clusters
      const isUnique =
        filter(this.clusterSchemes, ({ name: existingName }) => {
          return isEqual(trim(String(newName)), trim(existingName));
        }).length === 1;

      return isUnique ? '' : this.$t('validationErrors.unique', [this.$tkey('name')]);
    },

    async onSave() {
      // updating of clusters is done on the refresh for reference clusters
      await this.$refs['ref-store-allocation-table'].onSaveRefresh();
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

$shadow-colour1: rgba(0, 0, 0, 0.2);
$shadow-colour2: rgba(0, 0, 0, 0.14);
$shadow-colour3: rgba(0, 0, 0, 0.12);

.empty-table {
  color: $assortment-disabled-action-green-button-colour;
}

.v-icon-blue {
  color: $assortment-action-icon-color !important;
  fill: $assortment-action-icon-color !important; // for SVG
}

.v-icon-red {
  color: $assortment-negative-action-colour !important;
  fill: $assortment-negative-action-colour !important; // for SVG
}

#ref-cluster-schemes-table {
  padding: 0;

  .cluster-row {
    &:nth-last-of-type(odd) {
      background-color: $assortment-table-blue-bg-colour;
    }

    .col {
      display: flex;
      align-items: center;
    }
    &[selected] {
      position: relative;
      /* override existing border with this new one */
      border-left: 2px solid $assortment-action-icon-color;
      box-shadow: 0px 3px 1px -2px $shadow-colour1, 0px 2px 2px 0px $shadow-colour2,
        0px 1px 5px 0px $shadow-colour3;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        top: 0;
        pointer-events: none;
        bottom: 0;
      }
    }
    &.in-scope:last-child {
      margin-bottom: 15px;
    }
  }
}

.error-triangle-icon {
  color: $assortment-negative-action-colour;
  font-size: 2rem !important;
  cursor: pointer;
}
.main-panel {
  // min-height: 100%;
  // height: 100%;
  height: $tab-panel-height;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
