import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    [
      _c(
        VRow,
        [
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c(
                VForm,
                {
                  ref: "form",
                  model: {
                    value: _vm.formIsValid,
                    callback: function ($$v) {
                      _vm.formIsValid = $$v
                    },
                    expression: "formIsValid",
                  },
                },
                [
                  _c(VSelect, {
                    attrs: {
                      value: _vm.selectedSize,
                      items: _vm.availableNFRs,
                      label: _vm.$tkey("NFRs"),
                    },
                    on: { change: _vm.setSelectedSize },
                  }),
                  _vm._v(" "),
                  _c(VTextField, {
                    attrs: { required: "", label: "Workpackage name" },
                    model: {
                      value: _vm.workpackageName,
                      callback: function ($$v) {
                        _vm.workpackageName = $$v
                      },
                      expression: "workpackageName",
                    },
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.config, function (value, key) {
                    return [
                      _c(VTextField, {
                        key: key,
                        attrs: {
                          rules: _vm.rules,
                          required: "",
                          label: _vm.$tkey(key),
                          type: "number",
                        },
                        model: {
                          value: _vm.config[key],
                          callback: function ($$v) {
                            _vm.$set(_vm.config, key, _vm._n($$v))
                          },
                          expression: "config[key]",
                        },
                      }),
                    ]
                  }),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      staticClass: "mr-4",
                      attrs: {
                        primary: "",
                        disabled: !_vm.formIsValid || _vm.loading,
                        loading: _vm.loading,
                      },
                      on: { click: _vm.generate },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tkey("generateDataButton")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      staticClass: "mr-4",
                      attrs: { warning: "", disabled: _vm.loading },
                      on: { click: _vm.reset },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tkey("resetDataButton")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }