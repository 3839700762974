<template>
  <v-dialog
    :value="value"
    fullscreen
    content-class="grouping-modal"
    @click:outside="closeModal"
    @keydown.esc="closeModal"
  >
    <dialog-card
      :title="$tkey('modalTitle')"
      class="w-100 modal-card--full-screen"
      @close="closeModal"
    >
      <div class="pt-2">
        <rtls-text-field
          v-model="newAttrName"
          width="200px"
          class="text-field"
          :rules="rules"
          run-validations-on-creation
          :placeholder="$tkey('newAttrName')"
        />
      </div>
      <div class="d-flex w-100 grouping-content-wrapper">
        <div class="pl-0 pr-2 flex-grow-0 ma-0 d-flex flex-column grouping-content">
          <attribute-group-column
            :attr-group="groupAttrs"
            :is-editable="false"
            :disable-add-all-remaining="!hasRemainingAttributes"
            @distribute-remaining="distributeRemainingAttrs"
            @validation="columnValidation"
          />
        </div>
        <div class="pr-0 d-flex flex-grow-1 ma-0 groups-wrapper">
          <div v-for="attrGroup in attributeGroups" :key="attrGroup.id" class="attr-group">
            <attribute-group-column
              :attr-group="attrGroup"
              :disable-add-all-remaining="!hasRemainingAttributes"
              :reserved-names="groupNames"
              @delete-group="deleteGroup"
              @add-all-remaining="addAllRemainingAttrs"
              @update="updateGroup"
              @delete-attr="deleteGroupAttr"
              @validation="columnValidation"
            />
          </div>
        </div>
        <div class="flex-grow-0 ma-0 add-column">
          <v-btn text @click="addNewGroup()">
            <v-icon class="material-icons icon mr-1 ml-2">add_box</v-icon>
          </v-btn>
        </div>
      </div>
      <template v-slot:footer>
        <page-actions
          :has-data-errors="hasDataErrors"
          :has-data-changes="true"
          :show-discard="false"
          save-btn-text="createGroupedAttr"
          @save="onSave"
        >
          <template v-slot:right-btns>
            <v-btn class="text-outline" text link @click="closeModal">
              {{ $t('actions.cancel') }}
            </v-btn>
          </template>
        </page-actions>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { findIndex, get, uniqueId, compact } from 'lodash';

export default {
  name: 'AttributeGroupingModal',
  localizationKey: 'scenarioInputPage.attributeGroupingModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    groupingColumn: {
      type: Object,
      required: true,
    },
    attributeValues: {
      type: Array,
      required: true,
    },
    usedHeaders: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newAttrName: '',
      groupAttrs: {},
      attributeGroups: [],
      rules: [this.isUniqueAttr],
    };
  },
  computed: {
    hasDataErrors() {
      // check if all attribute values are alocated
      return (
        this.hasGroupsWithErrors ||
        !this.attributeGroups.length ||
        this.groupAttrs.values.length > 0 ||
        this.isUniqueAttr() !== true
      );
    },
    groupNames() {
      return compact(
        this.attributeGroups.map(group => {
          return group.name ? { name: group.name, id: group.id } : null;
        })
      );
    },
    hasRemainingAttributes() {
      return get(this.groupAttrs, 'values', []).length > 0;
    },
    hasGroupsWithErrors() {
      return this.attributeGroups.filter(g => g.isValid === false).length > 0;
    },
  },
  watch: {
    attributeValues: {
      deep: true,
      handler() {
        this.groupAttrs = {
          name: '',
          values: this.attributeValues,
        };
      },
    },
  },
  methods: {
    closeModal() {
      this.attributeGroups = [];
      this.newAttrName = '';
      this.$emit('close');
    },
    onSave() {
      const params = {
        attrName: this.newAttrName,
        grouping: this.attributeGroups,
        sourceColumnId: this.groupingColumn.colId,
      };
      this.$emit('create-attribute-group', params);
      this.closeModal();
    },
    deleteGroupAttr(params) {
      const { groupId, attrValue } = params;
      const currentGroup = this.attributeGroups.find(group => group.id === groupId);
      currentGroup.values = currentGroup.values.filter(v => v !== attrValue);
      this.updateGroup(currentGroup);
      this.groupAttrs.values.push(attrValue);
    },
    deleteGroup(id) {
      const currentGroup = this.attributeGroups.find(group => group.id === id);
      this.groupAttrs.values = [...this.groupAttrs.values, ...currentGroup.values];
      this.attributeGroups = this.attributeGroups.filter(group => group.id !== id);
    },
    updateGroup(group) {
      const index = findIndex(this.attributeGroups, { id: group.id });
      this.attributeGroups.splice(index, 1, group);
    },
    addAllRemainingAttrs(id) {
      const currentGroup = this.attributeGroups.find(group => group.id === id);
      currentGroup.values = [...currentGroup.values, ...this.groupAttrs.values];
      this.updateGroup(currentGroup);
      this.groupAttrs.values = [];
    },
    distributeRemainingAttrs() {
      this.groupAttrs.values.forEach(attrValue => {
        this.attributeGroups.push(this.createNewGroup(null, attrValue, [attrValue]));
      });
      this.groupAttrs.values = [];
    },

    createNewGroup(id = null, name = '', values = []) {
      if (!id) {
        id = uniqueId(Date.now());
      }
      return { id, name, values };
    },
    addNewGroup() {
      this.attributeGroups.push(this.createNewGroup());
    },
    columnValidation(params) {
      const { id, isValid } = params;
      const index = findIndex(this.attributeGroups, { id });
      const group = this.attributeGroups[index];
      group.isValid = isValid;
      this.attributeGroups.splice(index, 1, group);
    },
    isUniqueAttr() {
      if (!this.newAttrName) return this.$tkey('attrNameIsRequired');
      const headers = this.usedHeaders.map(h => h.toLocaleLowerCase());
      if (headers.includes(this.newAttrName.toLocaleLowerCase())) {
        return this.$tkey('attrNameHasToBeUnique');
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.sub-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.main-container {
  border-bottom: 1px solid $assortment-divider-colour;
  max-height: 350px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex-basis: auto;
  font-size: 1.3rem;
}

::v-deep {
  .dialog-card__footer {
    border-top-width: 0px;
  }
  .dialog-card__content {
    font-size: 1.1rem !important;
    padding: 10px 10px 10px 20px;
  }

  .dialog-card__footer {
    flex-direction: row-reverse;
  }
}

.groups-wrapper {
  overflow: auto;
}

.add-column {
  width: 50px;
  border-left: $assortment-divider-colour 1px solid;
  .v-btn {
    padding: 0 !important;
    min-width: 30px;
    width: 30px;
  }
}

.attr-group {
  width: 210px;
  max-width: 210px;
  min-width: 210px;
  margin: 0 5px;
  padding: 0 0 0 10px;
  border-left: $assortment-divider-colour 1px solid;
}

.grouping-content-wrapper {
  height: 10px;
  flex-grow: 1;
  padding-top: 20px;
}

.grouping-content {
  width: 210px !important;
  max-width: 210px !important;
  height: 100%;
}
</style>
