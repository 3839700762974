<template>
  <v-container fluid>
    <v-layout column class="reporting-power-bi__wrapper h-100">
      <div v-if="isRoot" class="d-flex flex-column h-100">
        <v-tabs
          v-model="selectedTab.position"
          hide-slider
          class="reporting-power-bi__tabs flex-grow-0"
        >
          <v-tab
            v-for="item in tabs"
            :key="item.key"
            class="reporting-power-bi__tab"
            :disabled="loading"
            @click="selectTab(item.key)"
          >
            {{ $t(item.translationKey) }}
          </v-tab>
        </v-tabs>

        <div id="powerbi-container" class="flex-grow-1" />
      </div>
      <router-view v-else />
    </v-layout>
  </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import * as pbi from 'powerbi-client'; // We need to import the pbi client like this
import { sortBy, get, filter } from 'lodash';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import reportTypes from '@enums/reporting-types';
import tabEnum from '../../utils/tabs/power-bi-tabs-enum';

const models = pbi.models;

export default {
  localizationKey: 'reportingPowerBIPage',

  data() {
    return {
      selectedTab: {
        key: reportTypes.market,
        position: 0,
      },
      report: null,
      reportContainer: null,
      // For a full list of settings available, see: https://docs.microsoft.com/en-us/javascript/api/overview/powerbi/configure-report-settings
      defaultReportConfig: {
        type: 'report',
        tokenType: models.TokenType.Embed,
      },
      tokenExpiration: {
        expirationTime: null,
        checkFrequency: 1 * 60 * 1000, // Check every 1 minute
        timeToUpdateBeforeExpiration: 30 * 60 * 1000, // Refresh embed token 30 minutes before expiry
      },
    };
  },

  computed: {
    ...mapState('reportingPowerBi', ['embedToken', 'loading']),
    ...mapGetters('reportingPowerBi', ['availableReports']),

    isRoot() {
      // Checks if the route is the root parent or a child
      return this.$route.name === 'reporting-power-bi';
    },

    tabs() {
      // Only display tabs for the reports that are available
      const tabs = filter(tabEnum.powerBITabs, t => this.availableReports.has(t.key));
      return sortBy(tabs, 'position');
    },

    embedUrl() {
      return get(this.embedToken, ['embedUrl', 0, 'embedUrl']);
    },
  },

  async mounted() {
    this.reportContainer = document.getElementById('powerbi-container');
    await this.updateEmbedToken();
    // eslint-disable-next-line no-undef
    powerbi.bootstrap(this.reportContainer, {
      type: 'report',
      embedUrl: this.embedUrl,
    });
    await this.refreshReport();
    // Use the token expiry to regenerate Embed token for seamless end user experience
    // Refer https://aka.ms/RefreshEmbedToken
    setInterval(() => this.checkTokenAndUpdate(), this.tokenExpiration.checkFrequency);
  },

  beforeDestroy() {
    // eslint-disable-next-line no-undef
    powerbi.reset(this.reportContainer);
  },

  methods: {
    ...mapMutations('reportingPowerBi', ['setEmbedToken', 'setLoading']),
    ...mapActions('reportingPowerBi', ['fetchEmbedToken']),

    async selectTab(clickedTab) {
      // Do not refresh report if the currently selected tab is clicked
      if (clickedTab === this.selectedTab.key) return;
      this.selectedTab.key = clickedTab;
      await this.updateEmbedToken();
      this.refreshReport();
    },

    async updateEmbedToken() {
      this.setLoading(true);
      // Generate a new embed token or refresh the user Azure AD access token
      const embedToken = await this.fetchEmbedToken(this.selectedTab.key);
      this.setEmbedToken(embedToken);
    },

    async refreshReport() {
      // Create a config object with type of the object, Embed details and Token Type
      const reportLoadConfig = {
        ...this.defaultReportConfig,
        accessToken: this.embedToken.accessToken,
        embedUrl: this.embedUrl,
      };
      // Embed Power BI report when Access token and Embed URL are available
      // eslint-disable-next-line no-undef
      this.report = powerbi.embed(this.reportContainer, reportLoadConfig);
    },

    /**
     * This function is likely to change as it is just a POC.
     * More info on it can be found here: https://owlabs.atlassian.net/wiki/spaces/RTLS/pages/2530115749/Events+Buttons
     */
    checkTokenAndUpdate() {
      // Time until token expiration in milliseconds
      const timeUntilExpiration = this.tokenExpiration.expirationTime - Date.now();
      // Update the token if it is about to expired
      if (timeUntilExpiration <= this.tokenExpiration.timeToUpdateBeforeExpiration) {
        this.updateToken();
      }
    },

    async updateToken() {
      if (this.report === null) return;
      // Update the new token expiration time
      await this.report.setAccessToken(this.embedToken.accessToken);
      this.tokenExpiration.expirationTime = Date.parse(this.embedToken.expiry);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.reporting-power-bi {
  &__wrapper {
    background: $assortment-panel-background;
  }

  &__tab {
    padding: 0 10px;
    justify-content: inherit;
    text-align: inherit;
    text-transform: none;
    min-width: 200px;
    border: 0.3px solid $assortment-border-colour;
    border-left: 3px solid $assortment-border-colour;
    border-bottom: none;
    font-size: 1.2rem;
    font-weight: 900;

    &:not(:first-of-type) {
      margin-left: 4px;
    }

    &.v-tab--active {
      background-color: $assortment-active-tab-colour;
      border-left: 3px solid $assortment-primary-colour;
    }
  }
}

::v-deep {
  .reporting-power-bi {
    &__tabs {
      &.theme--light.v-tabs > .v-tabs-bar {
        background-color: $assortment-background;
      }
    }
  }
}
</style>
