<template>
  <section class="previous-extract">
    <header class="header">
      <h2 class="header-title pt-0">{{ $t('extract.headers.previousExtract.title') }}</h2>
    </header>
    <v-row class="content-headers">
      <v-col class="header-item" cols="3">
        {{ $t('extract.headers.previousExtract.name') }}
      </v-col>
      <v-col class="header-item" cols="3">
        {{ $t('extract.headers.previousExtract.date') }}
      </v-col>
      <v-col class="header-item" cols="3">
        {{ $t('extract.headers.previousExtract.reportType') }}
      </v-col>
      <v-col class="header-item align-center" cols="2">
        {{ $t('extract.headers.previousExtract.status') }}
      </v-col>
      <v-col class="header-item" cols="1" />
    </v-row>
    <previous-extract-reports-row
      v-for="(report, index) in reports"
      :key="index"
      :report="report"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { debounce, every, includes } from 'lodash';
import { extracts } from '@enums/client-events';
import { jobFinishedStatuses } from '@enums/jobapi';
import { StoreNamespaces } from '../../../../store/constants';

export default {
  props: {
    reports: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  watch: {
    reports() {
      if (this.shouldPollExtracts()) {
        this.debouncedFetchExtracts();
      }
    },
  },
  created() {
    this.debouncedFetchExtracts = debounce(this.fetchExtracts, 5000);
    this.fetchExtracts();
  },
  methods: {
    ...mapActions(StoreNamespaces.extracts, ['fetchExtracts', 'generateExtract']),
    shouldPollExtracts() {
      // checks if there are unfinished jobs that need to be refreshed
      return every(this.reports, r => includes(jobFinishedStatuses, r.originalJobStatus)) === false;
    },
  },
  events: {
    [extracts.extractGenerated]() {
      this.fetchExtracts();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.previous-extract {
  .header {
    position: absolute;
    margin-top: -26px;
  }

  .header-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 2px;
    font-size: 1.2rem;
  }

  .header-title {
    font-weight: normal;
  }

  .content-headers {
    background-color: $assortment-dialog-background;
    margin-bottom: 5px;
    border-bottom: 1px solid $assortment-grid-line-colour;
  }
}
</style>
