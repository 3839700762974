import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "reporting-step-container pa-0", attrs: { fluid: "" } },
    [
      _c(
        VRow,
        { staticClass: "reporting-tab-row" },
        [
          _c(VFlex, [
            _c(
              "div",
              { staticClass: "reporting-tab-scope d-flex flex-column" },
              [
                _c(
                  VTabs,
                  {
                    staticClass: "reporting-tabs",
                    attrs: { "hide-slider": "" },
                  },
                  _vm._l(_vm.tabs, function (item) {
                    return _c(
                      VTab,
                      {
                        key: item.key,
                        staticClass: "reporting-tabs__tab",
                        attrs: {
                          ripple: false,
                          disabled: !_vm.viewsPermissions[item.permission],
                          to: _vm.createDetailedReportRoute(item.routeName),
                          replace: true,
                          exact: "",
                          title: _vm.$t(item.translationKey.subtitle),
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t(item.translationKey.title)) +
                            "\n          "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }