import moment from 'moment';

const mixin = {
  methods: {
    calculateEndDate({ startDate, weeks, dateFormat }) {
      if (!startDate || !weeks) return null; // moment returns current date / time when passed undefined, so exit early.
      if (weeks > 9999999) return null; // Will produce valid dates (to moment) but invalid dates (to us)
      return moment
        .utc(startDate)
        .add(weeks - 1, 'weeks') // go forward one week less to account for shift forward to sunday
        .endOf('isoWeek') // select Sunday
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) // end of isoWeek is sunday T23:59:59.999Z
        .format(dateFormat); // should return same format as picker since it's run through formatDateForSave
    },

    calculateStartDate({ endDate, weeks, dateFormat }) {
      if (!endDate || !weeks) return null; // moment returns current date / time when passed undefined, so exit early.

      return moment
        .utc(endDate)
        .subtract(weeks - 1, 'weeks') // go back one week less to account for shift back to monday
        .startOf('isoWeek') // select Monday
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .format(dateFormat);
    },

    toNearestSunday(date) {
      // Returns Sunday before date. if date is Sunday, return that Sunday.
      const parsedDate = moment.utc(date);
      // if latestSnapshotDate is not Sunday, go back 1 week.
      if (parsedDate.isoWeekday() !== 7) {
        parsedDate.subtract(1, 'week');
      }
      return parsedDate.endOf('isoWeek').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    },

    formatDateForPicker(date, format) {
      if (!date) return null;

      return moment.utc(date).format(format);
    },

    formatDateForSave(date, format) {
      if (!date) return null;

      // save date in specified format, with 0 utcOffset, 0 for time. e.g. 2018-01-01T00:00:00.000Z
      return moment
        .utc(date, format)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toISOString();
    },

    createDateForSave() {
      // save date in specified format, with 0 utcOffset, 0 for time. e.g. 2018-01-01T00:00:00.000Z
      return moment
        .utc()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toISOString();
    },

    /**
     * Formats an array of dates to be used by the vuetify date picker component
     *
     * @param {Date[]} dates The dates to be formatted
     * @param {string} format The date picker format
     * @returns {string[]} The formatted Dates
     */
    formatDatesForPicker(dates, format) {
      if (!dates) return null;
      return dates.map(date => this.formatDateForPicker(date, format));
    },

    sortDates(dates, format) {
      return dates.sort(
        (a, b) => moment.utc(a, format).valueOf() - moment.utc(b, format).valueOf()
      );
    },

    getMomentObject(date) {
      if (!date) return null;

      return moment.utc(date);
    },

    isDateValid({ dateValue, dateFormat, allowNull = true }) {
      if (allowNull && !dateValue) return true;
      return moment.utc(dateValue, dateFormat, true).isValid();
    },
  },
};

export default mixin;
