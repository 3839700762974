<template>
  <v-dialog :value="value" width="90%" @keydown.esc="cancel" @click:outside="closeModal">
    <dialog-card :title="$tkey('modalTitle')" @close="closeModal">
      <div class="ag-grid-box flex-grow-1 grid-size">
        <ag-grid-vue
          style="width: 100%; height: 400px"
          class="ag-theme-custom"
          :column-defs="columnDefs"
          :row-data="rowData"
          :grid-options="gridOptions"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
        />
      </div>
      <template v-slot:footer>
        <v-card-actions>
          <v-btn primary @click="closeModal">
            {{ $t('actions.cancel') }}
          </v-btn>
          <v-btn
            secondary
            depressed
            class="outlined-btn"
            :disabled="!selectedRows.length"
            :loading="isCreatingWorkpackage"
            @click="createWorkpackageFromTemplate"
          >
            {{ $t('general.select') }}
          </v-btn>
        </v-card-actions>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { get, isString } from 'lodash';
import { AgGridVue } from 'ag-grid-vue';
import { mapGetters, mapState, mapActions } from 'vuex';
import agGridUtils from '@/js/utils/ag-grid-utils';
import datesMixin from '@/js/mixins/date-utils';

export default {
  localizationKey: 'templatesPage.createWpFromTemplateModal',

  components: {
    AgGridVue,
  },
  mixins: [datesMixin],

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      rowData: [],
      selectedRows: [],
      gridApi: null,
      columnApi: null,
      isCreatingWorkpackage: false,
      gridOptions: {
        rowSelection: 'single',
        suppressRowClickSelection: true,
        suppressContextMenu: true,
        headerHeight: 40,
        rowHeight: 40,
        stopEditingWhenCellsLoseFocus: true,
        enableRangeSelection: true,
        enableFillHandle: true,
        tooltipShowDelay: 0,
        defaultColDef: {
          filter: true,
          resizable: true,
          minWidth: 80,
          sortable: true,
          comparator: agGridUtils.sortings.naturalSort,
          editable: false,
          suppressMovable: true,
        },
      },
    };
  },

  computed: {
    ...mapGetters('context', ['getDateFormats']),
    ...mapState('bundles', ['selectedBundle']),
    ...mapState('workpackages', ['loadingWorkpackageTemplates']),
    ...mapGetters('workpackages', ['workpackageTemplates']),
    ...mapGetters('bundles', ['bundlesById']),

    loading() {
      return this.loadingWorkpackageTemplates;
    },

    columnDefs() {
      return [
        {
          headerName: this.$tkey('gridHeaders.templateTitle'),
          field: 'name',
          colId: 'name',
          flex: 1,
        },
        {
          headerName: this.$tkey('gridHeaders.description'),
          field: 'description',
          colId: 'description',
          flex: 2,
        },
        {
          headerName: this.$tkey('gridHeaders.bundle'),
          field: 'bundleName',
          colId: 'bundleName',
          hide: !this.bundlesEnabled,
          valueGetter: node => {
            if (!node.data.bundleId) {
              return null;
            }

            return get(this.bundlesById[node.data.bundleId], 'name');
          },
        },
        {
          headerName: this.$tkey('gridHeaders.goLiveDate'),
          field: 'targetLaunchDate',
          colId: 'targetLaunchDate',
          filter: 'agDateColumnFilter',
          hide: !this.bundlesEnabled,
          maxWidth: 100,
          valueGetter: node => {
            if (!node.data.bundleId) {
              return null;
            }

            const bundle = this.bundlesById[node.data.bundleId];
            const targetLaunchDate = get(bundle, 'targetLaunchDate');
            return targetLaunchDate && isString(targetLaunchDate)
              ? this.getMomentObject(targetLaunchDate)
              : '-';
          },
          valueFormatter: params => {
            if (!params.data.bundleId) {
              return '-';
            }
            return agGridUtils.formatters.datesFormatter(params, this.getDateFormats.long);
          },
        },
        {
          headerName: this.$tkey('gridHeaders.numberOfAssortmentGroups'),
          field: 'assortmentGroups',
          colId: 'assortmentGroups',
          valueGetter: params => get(params, 'data.assortmentGroups', []).length,
        },
        {
          headerName: this.$tkey('gridHeaders.stores'),
          field: 'storeCount.total',
          colId: 'storeCount',
          maxWidth: 50,
          valueGetter: params => get(params, 'data.storeCount.total', 0),
        },
        {
          headerName: this.$tkey('gridHeaders.products'),
          // display count of products that are included on template level
          field: 'productCount.includedFromTemplate',
          colId: 'productCount',
          maxWidth: 50,
          valueGetter: params => get(params, 'data.productCount.includedFromTemplate', 0),
        },

        {
          headerName: this.$tkey('gridHeaders.creator'),
          field: 'createdBy',
          colId: 'createdBy',
          filter: 'agSetColumnFilter',
          filterParams: {
            valueFormatter: ({ value }) => value || '-',
          },
          valueGetter: params => get(params, 'data.createdBy.fullName', '-'),
        },
        {
          headerName: this.$tkey('gridHeaders.select'),
          field: 'selectTemplate',
          colId: 'selectTemplate',
          checkboxSelection: true,
          // TODO: add renderer for radio
          headerCheckboxSelectionFilteredOnly: true,
          resizable: false,
          maxWidth: 50,
          suppressNavigable: true,
        },
      ];
    },
  },

  async created() {
    await this.fetchBundles();
    await this.fetchWorkpackages({ shouldGetTemplates: true });
    this.rowData = this.workpackageTemplates;
    this.selectedRows = [];
  },

  methods: {
    ...mapActions('workpackages', ['fetchWorkpackages', 'addWorkpackage']),
    ...mapActions('bundles', ['fetchBundles']),

    async createWorkpackageFromTemplate() {
      this.isCreatingWorkpackage = true;

      const bundleId = get(this.selectedBundle, '_id');
      const template = this.selectedRows[0];
      await this.addWorkpackage({
        templateId: template._id,
        bundleId: bundleId || template.bundleId,
      });
      await this.fetchWorkpackages({ bundleId });
      this.isCreatingWorkpackage = false;
      this.scrollToNewWorkpackage();
      this.closeModal();
    },

    cancel() {
      this.$emit('cancel');
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
    },

    scrollToNewWorkpackage() {
      // New workpackages are always added to the bottom of the list
      const newWorkpackage = document.getElementsByClassName('workpackage-list')[0].lastChild;
      if (newWorkpackage) newWorkpackage.scrollIntoView();
    },

    closeModal() {
      this.gridApi.deselectAll();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 1.3rem;
  padding-top: 20px;
}

::v-deep {
  .dialog-card__footer {
    justify-content: flex-end;

    .v-card__actions {
      padding: 0;
    }
  }
}
</style>
