import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { attrs: { fluid: "" } },
    [
      _c(
        VLayout,
        { staticClass: "h-100", attrs: { column: "" } },
        [
          _c(
            VFlex,
            { staticClass: "d-flex flex-column" },
            [
              _c(
                VRow,
                { staticClass: "d-flex h-100 flex-column" },
                [
                  _vm.isLoading
                    ? _c("progress-bar", {
                        attrs: { message: _vm.$t("general.loading") },
                      })
                    : _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { staticClass: "px-0", class: _vm.colClass },
                            [_c("reporting-menu")],
                            1
                          ),
                          _vm._v(" "),
                          _c(VCol, [
                            _vm.isValidSelection
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "d-flex flex-column h-100",
                                    attrs: { id: "reports-section" },
                                  },
                                  [
                                    _c("reporting-tabs"),
                                    _vm._v(" "),
                                    _c("router-view"),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }