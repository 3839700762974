<template>
  <div class="master-container">
    <div class="header-container mt-2 mb-5">
      <span class="header-text">{{
        $tkey('header', [getDashboardProduct.productKeyDisplay])
      }}</span>
    </div>
    <!-- top section -->
    <div class="d-flex top-container">
      <!-- top products -->
      <transferred-spend-box
        v-for="(product, index) in topSection.top"
        :key="index"
        :product="product"
        :image-url="getImageUrl(product)"
        :image-alt="getDisplayText(product.productKeyDisplay)"
        :image-title="getDisplayText(product.productKeyDisplay)"
        :conditional-product-tile-style="getStyle('default')"
        :show-values="true"
        :amount="product.amount"
        :amount-percentage="product.percentage"
      />
      <transferred-spend-box
        :product="{ sales: 0, totalAmount: 0 }"
        :conditional-product-tile-style="getStyle('default')"
        :show-others="true"
        :from-others-count="fromOthersCount"
        :amount="fromOthersAmount"
        :amount-percentage="fromOthersPercentage"
      />
      <transferred-spend-box
        :product="{ sales: 0, totalAmount: 0 }"
        :conditional-product-tile-style="getStyle('default')"
        :amount="fromSalesLostAmount"
        :amount-percentage="fromSalesLostPercentage"
      />
    </div>
    <!-- mid section -->
    <div class="d-flex mid-container flex-column">
      <div class="flex-column pt-5">
        <assortment-image
          dashboard
          class="pb-1"
          :src="getImageUrl(getDashboardProduct)"
          :alt="getDisplayText(getDashboardProduct.productKeyDisplay)"
          :title="getDisplayText(getDashboardProduct.productKeyDisplay)"
          :tooltip-text="getTooltipText(getDashboardProduct)"
          v-bind="getStyle()"
        />
      </div>
      <div class="flex-column stats-item">
        <span class="value">
          <b>{{ formatNumber({ number: getDashboardProduct.sales, format: 'currency' }) }}</b>
          {{ $tkey('sales') }}
        </span>
      </div>
      <div class="flex-column stats-item">
        <span class="value">
          <b>{{ formatNumber({ number: productInQuestionTotalSales, format: 'currency' }) }}</b>
          {{ $tkey('inclTransSpend') }}
        </span>
      </div>
    </div>
    <!-- bot section -->
    <div class="d-flex top-container">
      <!-- top products -->
      <transferred-spend-box
        v-for="(product, index) in botSection.top"
        :key="index"
        :product="product"
        :image-url="getImageUrl(product)"
        :image-alt="getDisplayText(product.productKeyDisplay)"
        :image-title="getDisplayText(product.productKeyDisplay)"
        :conditional-product-tile-style="getStyle('default')"
        :show-values="true"
        :amount="product.amount"
        :amount-percentage="product.percentage"
        :is-top-section="false"
      />
      <transferred-spend-box
        :product="{ sales: 0, totalAmount: 0 }"
        :conditional-product-tile-style="getStyle('default')"
        :show-others="true"
        :from-others-count="toOthersCount"
        :amount="toOthersAmount"
        :amount-percentage="toOthersPercentage"
        :is-top-section="false"
      />
    </div>
    <div class="header-container mt-3 mb-4">
      <span class="header-text">{{
        $tkey('footer', [getDashboardProduct.productKeyDisplay])
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { pick, isEmpty, cloneDeep } from 'lodash';
import productUtils from '../../../utils/product-utils';

export default {
  localizationKey: 'assortmentCanvasPage.productDashboard.transferredSpend',

  computed: {
    ...mapState('context', ['clientConfig']),
    ...mapGetters('assortmentCanvas', [
      'getIndexedCanvasProducts',
      'getSelectedProductTransferredSpend',
      'getDashboardProduct',
    ]),

    imageFieldIdentifier() {
      const { fieldIdentifier = 'productKeyDisplay' } = this.clientConfig.images;
      return fieldIdentifier;
    },

    topSection() {
      if (isEmpty(this.getSelectedProductTransferredSpend)) return {};
      const ts = cloneDeep(this.getSelectedProductTransferredSpend);
      ts.transferredSalesFrom.top = ts.transferredSalesFrom.top.map(t => {
        return {
          ...t,
          ...pick(this.getIndexedCanvasProducts[t.productKeyTo], [
            'productKeyDisplay',
            'imageId',
            'itemDescription',
            this.imageFieldIdentifier,
          ]),
        };
      });
      return ts.transferredSalesFrom;
    },

    botSection() {
      if (isEmpty(this.getSelectedProductTransferredSpend)) return {};
      const ts = cloneDeep(this.getSelectedProductTransferredSpend);
      ts.transferredSalesTo.top = ts.transferredSalesTo.top.map(t => {
        const updated = {
          ...t,
          ...pick(this.getIndexedCanvasProducts[t.productKeyFrom], [
            'productKeyDisplay',
            'sales',
            'imageId',
            'itemDescription',
            this.imageFieldIdentifier,
          ]),
        };
        delete Object.assign(updated, { originalSales: updated.sales }).sales;
        return updated;
      });
      return ts.transferredSalesTo;
    },

    productInQuestionTotalSales() {
      if (isEmpty(this.getSelectedProductTransferredSpend)) return 0;
      return this.getSelectedProductTransferredSpend.transferredSalesTo.newSales;
    },

    fromOthersCount() {
      if (isEmpty(this.getSelectedProductTransferredSpend)) return 0;
      return this.getSelectedProductTransferredSpend.transferredSalesFrom.othersCount;
    },

    fromOthersAmount() {
      if (isEmpty(this.getSelectedProductTransferredSpend)) return 0;
      return this.getSelectedProductTransferredSpend.transferredSalesFrom.othersAmount;
    },

    fromOthersPercentage() {
      if (isEmpty(this.getSelectedProductTransferredSpend)) return 0;
      return this.getSelectedProductTransferredSpend.transferredSalesFrom.othersPercentage;
    },

    fromSalesLostAmount() {
      if (isEmpty(this.getSelectedProductTransferredSpend)) return 0;
      return this.getSelectedProductTransferredSpend.transferredSalesFrom.salesLost;
    },

    fromSalesLostPercentage() {
      if (isEmpty(this.getSelectedProductTransferredSpend)) return 0;
      return this.getSelectedProductTransferredSpend.transferredSalesFrom.salesLostPercentage;
    },

    toOthersCount() {
      if (isEmpty(this.getSelectedProductTransferredSpend)) return 0;
      return this.getSelectedProductTransferredSpend.transferredSalesTo.othersCount;
    },

    toOthersAmount() {
      if (isEmpty(this.getSelectedProductTransferredSpend)) return 0;
      return this.getSelectedProductTransferredSpend.transferredSalesTo.othersAmount;
    },

    toOthersPercentage() {
      if (isEmpty(this.getSelectedProductTransferredSpend)) return 0;
      return this.getSelectedProductTransferredSpend.transferredSalesTo.othersPercentage;
    },
  },

  watch: {
    getDashboardProduct: {
      immediate: true,
      async handler(newValue) {
        if (!newValue) return;
        await this.fetchProductTransferredSpend({
          productKey: newValue.productKey,
        });
      },
    },
  },

  methods: {
    ...mapActions('assortmentCanvas', ['fetchProductTransferredSpend']),

    getImageUrl(product) {
      return productUtils.getImageUrl(this.clientConfig, product);
    },

    getDisplayText(productKeyDisplay) {
      return productKeyDisplay;
    },

    getTooltipText(product) {
      return `${product.productKeyDisplay} - ${product.itemDescription}`;
    },

    getStyle(type) {
      if (type === 'default') {
        return {
          width: '50px',
          height: '40px',
        };
      }
      return {
        width: '60px',
        height: '50px',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.master-container {
  justify-content: center;
}

.header-container {
  text-align: center;
}

.header-text {
  background: $assortment-table-blue-bg-colour;
  padding: 5px 15px 5px 15px;
  border-radius: 30px;
  font-weight: bold;
  color: $assortment-text-colour;
}

.stats-item {
  line-height: 10px;
}

.top-container {
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 15px;
}

.value {
  font-size: 1rem;
}

.mid-container {
  flex-wrap: nowrap;
  margin-top: 70px;
  margin-bottom: 70px;
  border: 1px solid $assortment-transferred-spend-border;
  height: 120px;
  width: 623px;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
</style>
