import {
  sortBy,
  orderBy,
  get,
  size,
  keyBy,
  flatMap,
  difference,
  isEmpty,
  set,
  merge,
  flatten,
  map,
  uniq,
  reduce,
  reject,
  uniqueId,
  intersection,
  cloneDeep,
  values,
  groupBy,
  assign,
  forEach,
  filter,
  isNil,
  transform,
  some,
} from 'lodash';
import axios from 'axios';
import to from 'await-to-js';
import { jobApi } from '@/js/helpers';
import spacebreakUtils from '@/js/utils/spacebreak-utils';
import i18n from '../../vue-i18n';
import editableTableStore from '../utils/editable-table-store-utils';
import helpers from '../utils/furniture-utils';
import MappingUtils from '../utils/furniture-to-store-mapping';
import handleErrorMessages from '../utils/validation';

// So that is is accessible by mutations
function getMostCommonFurniture(allGeneratedFurniture) {
  const order = ['desc', 'desc', 'asc'];
  const fields = ['storeCount', 'size', 'furnitureId'];
  return orderBy(allGeneratedFurniture, fields, order)[0] || null;
}
const newlyAddedIdPrefix = 'newlyAddedIdPrefix';

const store = {
  namespaced: true,
  state: {
    ...editableTableStore.state,
    scenarioFurniture: {},
    workpackageFurniture: {}, // complete wp furniture as saved in db
    workpackageScenarioFurniture: [],
    workpackageProductFurniture: [],
    isLoading: false,
    isLoadingAllFurniture: true,
    availableFurnitureIds: {},
    newlyAddedIdPrefix,
  },

  getters: {
    ...editableTableStore.getters,

    getWorkpackageFurniture(state) {
      return state.workpackageFurniture;
    },

    getFurnituresInScenario(state) {
      return sortBy(state.scenarioFurniture.furnitureType, 'size');
    },

    getGeneratedFurnituresInScenario(state) {
      return sortBy(state.scenarioFurniture.generatedFurniture, 'size');
    },

    getStoreClassesInScenario(state) {
      return sortBy(state.scenarioFurniture.storeClasses, 'name');
    },

    getStoreMapping(state) {
      return state.scenarioFurniture.storeFurniture;
    },

    getAllAvailableFurnitureIds(state) {
      const furnitureIdsInSpacebreaks = flatMap(
        flatMap(state.scenarioFurniture.storeClasses, 'spacebreaks'),
        'generatedFurnitureIds'
      );
      const allFurnitureIds = flatMap(
        state.scenarioFurniture.storeClasses,
        'generatedFurnitureIds'
      );
      return difference(allFurnitureIds, furnitureIdsInSpacebreaks);
    },

    countFurnitures: state => furnitures =>
      state.scenarioFurniture.generatedFurniture.reduce(
        (count, generatedFurniture) =>
          furnitures.includes(generatedFurniture._id)
            ? count + size(generatedFurniture.storeKeys)
            : count,
        0
      ),

    getFurnitureToStoreMapping(state, getters) {
      const storeFurniture = groupBy(state.scenarioFurniture.storeFurniture, 'furnitureId');
      return reduce(
        getters.getFurnituresInScenario,
        (acc, sf) => {
          const storeKeys = flatMap(storeFurniture[sf._id], 'storeKey');
          acc.push({ ...sf, storeKeys });
          return acc;
        },
        []
      );
    },

    getWorkpackageProductFurniture(state) {
      return reduce(
        state.workpackageProductFurniture.productFurniture || [],
        (acc, { furnitureId, productKeys }) => {
          acc[furnitureId] = productKeys.length;
          return acc;
        },
        {}
      );
    },

    getSimpleSwapsClusteredFurnitureMapping(state, getters, rootState, rootGetters) {
      const scenarioClusteredStoreMap = rootGetters['scenarios/getSimpleSwapsClusteredStores'];
      const storeFurniture = groupBy(state.scenarioFurniture.storeFurniture, 'furnitureId') || [];
      const productFurniture = getters.getWorkpackageProductFurniture;
      const furnitureSelection = state.scenarioFurniture.simpleSwapsFurnitureSelection || [];

      // StoreClass furniture mapping
      const storeClassLookup = getters.getStoreClassesInScenario.reduce((mappings, storeClass) => {
        const storeClassFurnitureMappings = storeClass.furnitureIds.reduce((acc, id) => {
          acc[id] = storeClass.name;
          return acc;
        }, {});
        return assign(mappings, storeClassFurnitureMappings);
      }, {});

      // Furnitures in workpackage with store keys
      const scenarioFurniture = state.scenarioFurniture.furnitureType.reduce((acc, sf) => {
        const storeKeys = flatMap(storeFurniture[sf._id], 'storeKey');
        acc.push({ ...sf, storeKeys });
        return acc;
      }, []);

      // Returns furniture data keyed by cluster
      return reduce(
        scenarioClusteredStoreMap,
        (acc, storeKeys, index) => {
          forEach(scenarioFurniture, f => {
            const matchingKeys = intersection(f.storeKeys, storeKeys);
            const numberOfStores = size(matchingKeys);

            if (numberOfStores) {
              const storeClass = storeClassLookup[f._id];
              const numberOfProducts = productFurniture[f._id];
              const furnitureSelectionMap = furnitureSelection.find(
                fs => fs.furnitureId === f._id && fs.cluster === index
              );
              const inScope =
                size(furnitureSelectionMap) > 0 ? furnitureSelectionMap.inScope : null;

              acc.push({
                cluster: index,
                storeClass,
                furnitureId: f._id,
                furnitureName: f.name,
                storeKeys: matchingKeys,
                numberOfProducts,
                numberOfStores,
                inScope,
              });
            }
          });
          return acc;
        },
        []
      );
    },

    getFurnitureToStoreMappingSorted(state, getters, rootState, rootGetters) {
      const furnitureMap = keyBy(getters.getFurnituresInScenario, '_id');
      const wpStores = keyBy(rootState.workpackages.selectedWorkpackage.stores, 'storeKey');
      const storeMap = transform(
        rootGetters['scenarios/stores'],
        (acc, scStore) => {
          if (wpStores[scStore.storeKey].assortment) {
            acc[scStore.storeKey] = scStore;
          }
        },
        {}
      );

      return MappingUtils.buildTableContents(
        furnitureMap,
        storeMap,
        state.scenarioFurniture.storeFurniture
      );
    },

    spacebreakNameIsUnique: state => ({ spacebreak, storeClassId }) => {
      const otherSpacebreaks = state.scenarioFurniture.storeClasses
        .find(({ _id }) => _id === storeClassId)
        .spacebreaks.filter(sb => sb._id !== spacebreak._id);
      if (otherSpacebreaks.filter(sb => sb.name.trim() === spacebreak.name.trim()).length) {
        return i18n.t('validationErrors.unique', [i18n.t('spacebreakCalculatorPage.spacebreak')]);
      }
      return '';
    },

    getSpacebreaksIndexedById: (state, getters, rootState, rootGetters) => {
      // index space breaks by id.
      const selectedClusterScheme = cloneDeep(rootGetters['clustering/selectedClusterScheme']);
      const clusters = get(selectedClusterScheme, 'clusters', []);
      // need this for the unclustered points of distribution
      clusters.push({ clusterId: null, storeKeys: [] });
      // index space breaks by id.
      const fillInSelection = rootState.workpackages.selectedWorkpackage.fillInSelection;
      const spacebreaks = reduce(
        state.scenarioFurniture.storeClasses,
        (acc, sc) => {
          const productPositioningInfoPerSpacebreak = reduce(
            sc.spacebreaks,
            (sbAcc, sb) => {
              sb.shortName = spacebreakUtils.getSpacebreakShortName({
                spacebreak: sb,
                fillInSelection,
              });
              set(sbAcc, sb._id, sb);
              return sbAcc;
            },
            {}
          );
          return { ...acc, ...productPositioningInfoPerSpacebreak };
        },
        {}
      );

      const clusteredSpacebreaks = {};
      clusters.forEach(c => {
        clusteredSpacebreaks[c.clusterId] = {};
        Object.entries(spacebreaks).forEach(([sbKey, sb]) => {
          const cpSb = cloneDeep(sb);
          // only include stores that are available in cluster
          cpSb.storeKeys =
            get(c, 'storeKeys', []).length > 0
              ? intersection(cpSb.storeKeys, c.storeKeys)
              : cpSb.storeKeys;
          clusteredSpacebreaks[c.clusterId][sbKey] = cpSb;
        });
      });
      return clusteredSpacebreaks;
    },

    spacebreaksShortNamesByCluster: (state, getters) => {
      return reduce(
        getters.getSpacebreaksIndexedById,
        (acc, indexedSpacebreak, clusterId) => {
          acc[clusterId] = values(indexedSpacebreak).map(s => ({
            value: s._id,
            text: s.shortName,
          }));
          return acc;
        },
        {}
      );
    },

    wpScenarioFurnitureMap(state) {
      return reduce(
        state.workpackageScenarioFurniture,
        (acc, furniture) => {
          return {
            ...acc,
            [furniture.scenarioId]: furniture.storeClasses,
          };
        },
        {}
      );
    },

    hasFurnitureType: state => furnitureType => {
      return some(state.scenarioFurniture.furnitureType, f => f.type === furnitureType);
    },

    groupedFurnitureByName: (state, getters) => groupBy(getters.getFurnituresInScenario, 'name'),

    getFurnitureArchetypeName: (state, getters, rootState) => ({
      data,
      groupedFurnitureByName,
    }) => {
      const hasSpacebreakFromDataEnabled = get(
        rootState.context.clientConfig,
        'features.spacebreaksFromDataEnabled',
        false
      );

      const name = get(data, 'name', ' ');
      const furnitureByName = groupedFurnitureByName || getters.groupedFurnitureByName;

      // if generate spacebreak from data is not enabled or
      // if there is no duplication
      // return unmodified archetype name
      if (!hasSpacebreakFromDataEnabled || name === ' ' || size(furnitureByName[name]) === 1) {
        return name;
      }

      // otherwise add store class suffix
      const furnitureId = data._id;
      const storeClasses = getters.getStoreClassesInScenario;

      const suffix = get(
        storeClasses.find(sc => sc.furnitureIds.includes(furnitureId)),
        'name',
        ''
      );

      return `${name} (${suffix})`;
    },
  },

  mutations: {
    ...editableTableStore.mutations,
    setIsLoading(state, val) {
      state.isLoading = val;
    },

    setIsLoadingAllFurniture(state, val) {
      state.isLoadingAllFurniture = val;
    },

    setWorkpackageFurniture(state, val) {
      state.workpackageFurniture = val;
    },

    setScenarioFurniture(state, val) {
      state.scenarioFurniture = val;
    },

    setWorkpackageScenarioFurniture(state, val) {
      state.workpackageScenarioFurniture = val;
    },

    setWorkpackageProductFurniture(state, val) {
      state.workpackageProductFurniture = val;
    },

    setStoreClasses(state, val) {
      state.scenarioFurniture.storeClasses = val;
    },

    removeAllFurnitureFromSpacebreak(state, { storeClassId, spacebreakId }) {
      const spacebreak = helpers.getSpacebreak(state, { storeClassId, spacebreakId });
      spacebreak.generatedFurnitureIds = [];
      spacebreak.storeKeys = [];
      spacebreak.size = 0;
      spacebreak.fillOverride = 0;
    },

    updateSpacebreakForStoreClass(state, { storeClassId, updatedSpacebreak }) {
      const spacebreak = helpers.getSpacebreak(state, {
        storeClassId,
        spacebreakId: updatedSpacebreak._id,
      });
      merge(spacebreak, updatedSpacebreak);
    },

    removeSpacebreakFromStoreClass(state, { storeClassId, spacebreakId }) {
      const storeClass = state.scenarioFurniture.storeClasses.find(
        ({ _id }) => _id === storeClassId
      );
      storeClass.spacebreaks = storeClass.spacebreaks.filter(sb => sb._id !== spacebreakId);
    },

    addAllAvailableFurnitureToSpacebreak(
      state,
      { storeClassId, spacebreakId, availableFurnitureIds }
    ) {
      if (isEmpty(availableFurnitureIds)) return false;

      const spacebreak = helpers.getSpacebreak(state, { storeClassId, spacebreakId });
      spacebreak.generatedFurnitureIds = [
        ...spacebreak.generatedFurnitureIds,
        ...availableFurnitureIds,
      ];
      const allGF = map(
        filter(state.scenarioFurniture.generatedFurniture, gf =>
          availableFurnitureIds.includes(gf._id)
        ),
        agf => ({ ...agf, storeCount: size(agf.storeKeys) })
      );
      const missingStoreKeys = map(allGF, 'storeKeys');
      spacebreak.storeKeys.push(...flatten(missingStoreKeys));
      const mcf = getMostCommonFurniture(allGF);
      // as per V2, the spacebreak size is the MCF size
      spacebreak.size = get(mcf, 'size', 0);
      if (isEmpty(spacebreak.generatedFurnitureIds) && !get(mcf, 'size', 0)) {
        spacebreak.fillOverride = 0;
      }
    },

    removeOneFurnitureFromSpacebreak(state, { storeClassId, spacebreakId, furnitureId }) {
      const spacebreak = helpers.getSpacebreak(state, { storeClassId, spacebreakId });

      // remove stores from removed furniture, but only if they are not in the other furnitures
      const otherFurnitures = map(
        reject(helpers.getFurnituresInSpacebreak(state, { storeClassId, spacebreakId }), {
          _id: furnitureId,
        }),
        of => ({ ...of, storeCount: size(of.storeKeys) })
      );
      const storesInOtherFurnitures = flatten(map(otherFurnitures, 'storeKeys'));
      spacebreak.generatedFurnitureIds = spacebreak.generatedFurnitureIds.filter(
        fid => fid !== furnitureId
      );
      spacebreak.storeKeys = spacebreak.storeKeys.filter(sk =>
        storesInOtherFurnitures.includes(sk)
      );
      const mcf = getMostCommonFurniture(otherFurnitures);
      spacebreak.size = get(mcf, 'size', 0);
      if (isEmpty(spacebreak.generatedFurnitureIds) && !get(mcf, 'size', 0)) {
        spacebreak.fillOverride = 0;
      }
    },

    addOneFurnitureToSpacebreak(state, { storeClassId, spacebreakId, furnitureId }) {
      const spacebreak = helpers.getSpacebreak(state, { storeClassId, spacebreakId });
      const f = state.scenarioFurniture.generatedFurniture.find(gf => gf._id === furnitureId);
      spacebreak.generatedFurnitureIds = [...spacebreak.generatedFurnitureIds, furnitureId];
      spacebreak.storeKeys = uniq([...spacebreak.storeKeys, ...f.storeKeys]).sort();
      // Add storeCount to furniture - it's required to sort furnitures in getMostCommonFurniture
      const allGF = map(
        filter(state.scenarioFurniture.generatedFurniture, gf =>
          spacebreak.generatedFurnitureIds.includes(gf._id)
        ),
        agf => ({ ...agf, storeCount: size(agf.storeKeys) })
      );
      const mcf = getMostCommonFurniture(allGF);
      spacebreak.size = get(mcf, 'size', 0);
      if (isEmpty(spacebreak.generatedFurnitureIds) && !get(mcf, 'size', 0)) {
        spacebreak.fillOverride = 0;
      }
    },

    addSpacebreak(state, { storeClassId }) {
      const seed = Date.now();
      const storeClass = state.scenarioFurniture.storeClasses.find(
        ({ _id }) => _id === storeClassId
      );

      const newSpacebreak = {
        _id: uniqueId(seed),
        storeKeys: [],
        generatedFurnitureIds: [],
        size: 0,
        fillSuggested: 0,
        fillOverride: 0,
        name: '',
        shortName: null,
      };
      storeClass.spacebreaks.unshift(newSpacebreak);
    },
  },

  actions: {
    ...editableTableStore.actions,

    async fetchWorkpackageFurniture({ commit, rootState }, { workpackageId } = {}) {
      commit('setIsLoadingAllFurniture', true);

      let id = workpackageId;
      if (!workpackageId) {
        id = rootState.workpackages.selectedWorkpackage._id;
      }

      const [err, res] = await to(axios.get(`/api/workpackages/${id}/furniture`));
      const furnitureData = get(res, 'data');

      commit('setIsLoadingAllFurniture', false);
      if (err) throw new Error(err.message);
      // if no furnitureData or an empty string returned - set to empty object
      commit('setWorkpackageFurniture', furnitureData || {});
    },

    async updateWorkpackageFurniture({ commit, dispatch }, { workpackageId, updates }) {
      commit('setIsLoadingAllFurniture', true);

      const [err, result] = await to(
        axios.patch(`/api/workpackages/${workpackageId}/furniture`, updates)
      );
      commit('setIsLoadingAllFurniture', false);
      if (err) {
        const message = get(err, ['response', 'data', 'message'], err.message);
        throw new Error(message);
      }

      if (result) {
        dispatch('fetchWorkpackageFurniture', { workpackageId });
      }
    },

    async fetchScenarioFurniture({ commit, rootState }, { options = {} } = {}) {
      commit('setIsLoadingAllFurniture', true);
      const { _id: scenarioId } = rootState.scenarios.selectedScenario;

      const [err, res] = await to(
        axios.get(`/api/scenarios/${scenarioId}/furniture`, { params: options.params })
      );
      const furnitureData = get(res, 'data');

      commit('setIsLoadingAllFurniture', false);
      if (err) throw new Error(err.message);
      commit('setScenarioFurniture', furnitureData);
    },

    async fetchScenarioFurnitureByWorkPackage({ commit, rootState }, options = {}) {
      const workpackageId = options.workpackageId || rootState.workpackages.selectedWorkpackage._id;
      const [err, res] = await to(
        axios.get(`/api/workpackages/${workpackageId}/scenarios/furniture`)
      );
      if (err) throw new Error(err.message);
      const furnitureData = get(res, 'data');
      if (!options.shouldCommit) return furnitureData;
      commit('setWorkpackageScenarioFurniture', furnitureData);
    },

    async fetchFurnitureProductsByWorkpackage({ commit, rootState }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, res] = await to(
        axios.get(`/api/workpackages/${workpackageId}/furniture-products`)
      );
      if (err) throw new Error(err.message);
      const furnitureProductData = get(res, 'data');
      commit('setWorkpackageProductFurniture', furnitureProductData);
    },

    async saveScenarioFurniture(
      { commit, state, rootState, dispatch },
      { dispatchOptions, ...updates }
    ) {
      commit('setIsLoading', true);
      const { _id: scenarioId } = rootState.scenarios.selectedScenario;
      let deletedStoreClasses = [];
      let addedStoreClasses = [];

      if (updates.storeClasses) {
        deletedStoreClasses = difference(
          map(state.scenarioFurniture.storeClasses, '_id'),
          map(updates.storeClasses, '_id')
        );
        // new store class doesn't have id, that is why we should use name
        addedStoreClasses = map(filter(updates.storeClasses, sc => isNil(sc._id)), 'name');

        updates.storeClasses = updates.storeClasses.map(sc => {
          sc.spacebreaks = sc.spacebreaks.map(sb => {
            if (sb._id.length < 20) {
              sb._id = null;
            }
            return sb;
          });
          return sc;
        });
      }

      const [err, response] = await to(
        axios.patch(`/api/scenarios/${scenarioId}/furniture/${state.scenarioFurniture._id}`, {
          deletedStoreClasses,
          addedStoreClasses,
          updates,
        })
      );
      if (err) {
        commit('setIsLoading', false);
        throw new Error(err.message);
      }

      commit('setScenarioFurniture', response.data);

      if (get(dispatchOptions, 'showMessage', true)) {
        dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
      }

      commit('setIsLoading', false);
      return response;
    },

    async runWorkpackageFurnitureSetup({ rootState }) {
      const config = rootState.context.clientConfig;
      const excludeNonMappedFurniture = get(config, 'features.excludeFurnitureNotMapped', false);
      const furnitureToBePulledForAssortmentAGsOnly = get(
        config,
        'features.furnitureToBePulledForAssortmentAGsOnly',
        false
      );
      const removeUnsizedFurniture = get(config, 'features.removeUnsizedFurniture', false);

      const [err] = await to(
        jobApi.runFunction('run-furniture-setup', {
          workpackage_id: rootState.workpackages.selectedWorkpackage._id,
          // These params must be in sync with `analytics-job` getRequestParams for WP setup
          exclude_non_mapped_furniture: excludeNonMappedFurniture,
          furniture_for_assortment_ags_only: furnitureToBePulledForAssortmentAGsOnly,
          remove_unsized_furniture: removeUnsizedFurniture,
          generate_spacebreaks: false, // sbs must only be generated on WP setup
        })
      );
      if (err) throw new Error(err.message);
    },

    // furnitureService is one of: furnitureType, furniture to store, storeClasses, etc
    async processCSV(
      { commit, rootState, dispatch },
      { fileId, mappings, furnitureService, delimiter }
    ) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setIsLoading', true);
      const body = { mappings, scenarioId, delimiter };
      const [err, { data: responseData } = {}] = await to(
        axios.post(`/api/csv/${furnitureService}/process/${fileId}`, body)
      );
      commit('setIsLoading', false);
      // TODO: Remove this and use global interceptor on AOV3-159
      if (err) {
        const key = get(err, 'response.data.messageKey', err.message);
        const message = i18n.t(key, get(err, 'response.data.messageParams', []));
        dispatch('snackbar/showError', message, { root: true });
        return Promise.reject(message);
      }
      if (size(responseData.messages)) {
        dispatch('alerts/showMessages', responseData.messages, { root: true });
      }
      const csvRows = responseData.tableRows;
      csvRows.forEach(row => {
        // add temp ID to uploaded entries
        row._id = row._id || uniqueId(`${newlyAddedIdPrefix}-${furnitureService}`);
      });

      return responseData.tableRows;
    },

    async startSpacebreaksJob({ state, commit, dispatch, rootState }) {
      const { _id: scenarioFurnitureId } = state.scenarioFurniture;
      const { _id: scenarioId } = rootState.scenarios.selectedScenario;
      commit('setIsLoading', true);
      const [err, response] = await to(
        axios.post(
          `/api/scenarios/${scenarioId}/furniture/${scenarioFurnitureId}/spacebreak-generator`,
          {}
        )
      );
      commit('setIsLoading', false);
      if (err) throw new Error(err);
      await dispatch('scenarios/loadScenario', scenarioId, { root: true });
      handleErrorMessages({ response, dispatch });
    },

    async spacebreaksJobCompleted({ dispatch, rootState }, { message }) {
      const selectedScenario = rootState.scenarios.selectedScenario;
      if (message.scenarioId === selectedScenario._id) {
        dispatch('fetchScenarioFurniture');
      }
    },
  },
};

export default store;
