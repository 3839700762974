import i18nConfig from '@sharedModules/config/static-i18n-config';
import numberFormats from './numberFormats';
import dateFormats from './dateFormats';

const { defaultLocale } = i18nConfig;

const translations = {
  numberFormats,
  dateFormats,
};

export { defaultLocale, translations };
