var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-container-section h-100 d-flex flex-column" },
    [
      !_vm.shortView
        ? _c(
            "div",
            { staticClass: "subtitle-heading pb-1 my-1 d-flex align-center" },
            [
              _vm.subtitle
                ? _c("span", [_vm._v(_vm._s(_vm.getSubtitle))])
                : _vm._e(),
              _vm._v(" "),
              _vm._t("subtitle-section"),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "section-data h-100 w-100 d-flex justify-space-between",
        },
        [_vm._t("data-section")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }