<template>
  <v-dialog :value="value" width="870px" @click:outside="closeModal">
    <dialog-card v-if="value" :title="$tkey('title')" @close="closeModal">
      <v-container fluid class="cluster-switching__container mb-10">
        <v-row>
          <v-col id="cluster-switching-scheme-selection" class="pa-0 pr-6" cols="5">
            <h4 class="cluster-switching__heading mt-1 mb-4 pa-0 col-10">
              {{ $tkey('schemeSelectionTitle') }}
            </h4>
            <div>
              <v-row>
                <v-col
                  v-for="(header, index) in headers"
                  :key="`header-${index}`"
                  :cols="header.cols"
                  :class="header.headerClass"
                >
                  <span>{{ header.text }}</span>
                </v-col>
              </v-row>
              <v-row v-for="(row, rowIndex) in schemes" :key="row._id" class="cluster-row">
                <v-col
                  v-for="header in headers"
                  :key="`cell-${row._id}-${header.id}`"
                  :cols="header.cols"
                  :class="header.colClass"
                  class="d-flex align-center"
                  style="padding: 6px"
                >
                  <template v-if="isFirstClusterCount1Entry(rowIndex, header, row)">
                    <v-checkbox
                      v-model="row.selected"
                      class="scheme-checkbox rtls-checkbox mx-2"
                      :disabled="true"
                    />
                  </template>
                  <template
                    v-if="
                      !isFirstClusterCount1Entry(rowIndex, header, row) &&
                        header.ui === CELL_TYPE.radio
                    "
                  >
                    <v-radio-group
                      v-model="selectedScheme"
                      hide-details
                      @change="onSchemeSelectedChanged"
                    >
                      <v-radio :ripple="false" :value="row._id" class="scheme-radio" />
                    </v-radio-group>
                  </template>
                  <template v-if="header.ui === CELL_TYPE.readonly">
                    <span>
                      {{ getClusterVariance(header.outputFormat, row[header.value]) }}
                    </span>
                  </template>
                </v-col>
              </v-row>
            </div>
          </v-col>

          <v-col class="pa-0" cols="3">
            <div class="d-flex justify-space-between align-center mb-4">
              <h4 class="cluster-switching__heading mt-1 mb-0 pa-0 col-8">
                {{ $tkey('calculationsTitle') }}
              </h4>
            </div>
            <p class="cluster-switching__paragraph">
              {{ $tkey('calculationsSubtitle') }}
            </p>

            <v-radio-group
              v-model="selectedCalculationMethod"
              :disabled="!hasClustersUsingClusteredMatrix"
            >
              <v-radio
                v-for="calculation in calculationTypes"
                :key="calculation"
                class="cluster-switching__label"
                :ripple="false"
                :label="$tkey(`calculations.${calculation}`)"
                :value="calculation"
              />
            </v-radio-group>
          </v-col>

          <v-col id="cluster-switching-matrix-selection" class="pa-0 pl-6" cols="4">
            <h4 class="cluster-switching__heading mt-1 mb-4 pa-0 col-8">
              {{ $tkey('unclusterMatrixSelectionTitle') }}
            </h4>
            <p class="cluster-switching__paragraph">
              {{ $tkey('unclusterMatrixSelectionSubtitle') }}
            </p>
            <p
              v-if="!hasUnclusteredSwitchingMatrices"
              class="cluster-switching__paragraph font-italic d-inline-block invalid-rows-error-box"
            >
              {{ $tkey('noUnclusteredMatrixFound') }}
            </p>

            <div class="mt-3">
              <div
                v-for="cluster in selectedSchemeClusters"
                :key="cluster.clusterId"
                class="cluster-matrix-selection"
              >
                <v-checkbox
                  v-model="clustersUsingClusteredMatrix"
                  :disabled="!hasUnclusteredSwitchingMatrices"
                  :value="cluster.clusterId"
                  :label="cluster.clusterName"
                  :ripple="false"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:footer>
        <page-actions
          :has-data-errors="hasDataErrors"
          :has-data-changes="true"
          :show-discard="false"
          save-btn-text="run"
          @save="onSave"
        >
          <template v-slot:right-btns>
            <v-btn class="text-outline" text link @click="closeModal">
              {{ $t('actions.cancel') }}
            </v-btn>
          </template>
        </page-actions>

        <dependency-tree-feedback-modal
          :value="dependencyTreeModalOpen"
          :results="dependencyTreeFeedback"
          page="clustering"
          @close="closeDependencyTreeModal"
          @commit="onSave(true)"
        />
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { isNil, get, find, keyBy, reject, map, size } from 'lodash';
import { CELL_TYPE, OUTPUT_FORMAT } from '@enums/table/index';
import calculationTypes from '@enums/clustering-calculation-types';

export default {
  name: 'ClusterSwitchingModal',
  localizationKey: 'clusteringPage.switchingDialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      CELL_TYPE,
      OUTPUT_FORMAT,
      calculationTypes,
      headers: [
        {
          id: 'selected',
          value: 'selected',
          text: null,
          cols: 1,
          ui: CELL_TYPE.radio,
        },
        {
          id: 'schemeName',
          value: 'name',
          ui: CELL_TYPE.readonly,
        },
        {
          id: 'schemeClusterCount',
          value: 'clusterCount',
          text: this.$t('clusteringPage.clusterCount'),
          cols: 2,
          headerClass: 'px-0',
          ui: CELL_TYPE.readonly,
        },
        {
          id: 'clusterVariance',
          value: 'variance',
          text: this.$t('clusteringPage.varianceExplained'),
          outputFormat: OUTPUT_FORMAT.percentage,
          cols: 2,
          headerClass: 'px-0',
          ui: CELL_TYPE.readonly,
        },
      ],
      selectedScheme: null,
      selectedCalculationMethod: calculationTypes.hybrid,
      dependencyTreeFeedback: {},
      dependencyTreeModalOpen: false,
      clustersUsingClusteredMatrix: [],
    };
  },

  computed: {
    ...mapGetters('clustering', ['getScenarioClusterSchemes']),
    ...mapState('switchingMatrices', ['switchingMatricesUnclusteredCount']),

    hasDataErrors() {
      // check if nothing is selected
      return !this.selectedScheme;
    },

    firstUnclusteredScheme() {
      return this.getScenarioClusterSchemes
        .filter(s => !isNil(s.variance))
        .find((scheme, index) => {
          // all schemes are sorted by cluster count
          return this.isFirstClusterCount1Entry(index, { id: 'selected' }, scheme);
        });
    },

    schemes() {
      return this.getScenarioClusterSchemes
        .filter(s => !isNil(s.variance))
        .map(scheme => {
          return {
            ...scheme,
            selected: get(this.firstUnclusteredScheme, '_id') === scheme._id || scheme.selected,
          };
        });
    },

    selectedSchemeClusters() {
      if (!this.selectedScheme) return [];
      return get(
        find(this.getScenarioClusterSchemes, { _id: this.selectedScheme }),
        'clusters',
        []
      );
    },

    hasClustersUsingClusteredMatrix() {
      return size(this.clustersUsingClusteredMatrix) > 0;
    },

    scenarioClusterSchemesMap() {
      return keyBy(this.getScenarioClusterSchemes, '_id');
    },

    hasUnclusteredSwitchingMatrices() {
      return this.switchingMatricesUnclusteredCount > 0;
    },
  },

  watch: {
    value(isOpen, wasOpen) {
      if (!wasOpen && isOpen) {
        const selectedScheme = find(this.getScenarioClusterSchemes, { selected: true });
        this.selectedScheme = get(selectedScheme, '_id', null);
        this.onSchemeSelectedChanged(this.selectedScheme);
      }
    },

    hasClustersUsingClusteredMatrix(newValue) {
      this.resetCalculationMethod(newValue);
    },
  },

  methods: {
    ...mapActions('dependencyTree', ['triggerDependencyTree']),
    ...mapActions('scenarios', ['setScenarioHasSkippedClustering']),

    closeModal() {
      this.resetFormData();
      this.$emit('close');
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
    },

    async onSave(commit = false) {
      // Perform dependency tree check
      const results = await this.triggerDependencyTree({
        params: { change: 'clusteringModified', updates: {}, commit },
      });

      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return;
      }

      await this.setScenarioHasSkippedClustering({ hasSkippedClustering: false });
      const selectedSchemes = [this.selectedScheme];
      // TODO: Cleanup this on AOV3-1809
      if (this.firstUnclusteredScheme) {
        selectedSchemes.push(this.firstUnclusteredScheme._id);
      }

      const clustersUsingUnclusteredMatrices = map(
        reject(this.scenarioClusterSchemesMap[this.selectedScheme].clusters, c =>
          this.clustersUsingClusteredMatrix.includes(c.clusterId)
        ),
        'clusterId'
      );
      const payload = {
        clusterSchemes: selectedSchemes,
        clustersUsingUnclusteredMatrices,
        calculationMethod: this.selectedCalculationMethod,
      };
      this.$emit('process', payload);
      this.closeModal();
    },

    resetFormData() {
      this.selectedScheme = null;
    },

    getClusterVariance(headerType, value) {
      if (headerType === this.OUTPUT_FORMAT.percentage) {
        return value ? `${this.formatNumber({ number: value, format: 'percent' })}%` : 0;
      }
      return value;
    },

    isFirstClusterCount1Entry(rowIndex, header, row) {
      // schemes are sorted by cluster count, so if the first entry doesn't have cluster count of 1,
      // then there are no entries with cluster count as 1.
      return rowIndex === 0 && header.id === 'selected' && row.clusterCount === 1;
    },

    resetCalculationMethod(usingClusteredMatrix) {
      // If there are no clusters using clustered matrix, the calculation method will default to
      // 'static'. Otherwise, the calculation method will default to the last method used to run clustered
      // switching for that cluster scheme. If clustered switching has not yet ran for a scheme, the
      // default option will be 'hybrid'.
      const clusterSchemeCalculationMethod = get(
        this.scenarioClusterSchemesMap[this.selectedScheme],
        'selectedCalculationMethod',
        calculationTypes.hybrid
      );
      this.selectedCalculationMethod = usingClusteredMatrix
        ? clusterSchemeCalculationMethod
        : calculationTypes.static;
    },

    onSchemeSelectedChanged(schemeId) {
      const selectedScheme = this.scenarioClusterSchemesMap[schemeId];
      this.resetCalculationMethod(this.hasClustersUsingClusteredMatrix);
      this.clustersUsingClusteredMatrix = get(selectedScheme, 'clusters', []).reduce((acc, c) => {
        // ignore clusters using unclustered matrix
        if (!c.usesUnclusteredMatrix) acc.push(c.clusterId);

        return acc;
      }, []);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.cluster-switching {
  &__container {
    color: $assortment-text-colour;
    margin-bottom: 10px;
    margin-top: 10px;
    max-height: 500px;
    padding: 0;
  }

  &__heading {
    font-size: $panel-heading-size;
    line-height: $panel-heading-line-height;
  }

  &__paragraph {
    font-size: $panel-paragraph-size;
    line-height: $panel-paragraph-line-height;
  }
}

.cluster-row {
  background-color: $assortment-table-blue-bg-colour;
  margin-bottom: 5px;

  border-color: $assortment-table-blue-bg-colour;
  border-width: 10px;
  border-radius: 5px;
}

::v-deep {
  .scheme-checkbox {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .scheme-radio {
    .v-icon.v-icon {
      font-size: 18px;
    }
  }

  .v-dialog {
    & footer.dialog-card__footer {
      justify-content: space-between;
    }
  }

  .cluster-switching {
    &__label label {
      color: $assortment-text-colour;
      font-size: $panel-paragraph-size;
      line-height: $panel-paragraph-line-height;
    }
  }
}
</style>
