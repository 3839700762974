<template>
  <section class="section-container">
    <h2 v-if="isScenariosExists" class="scenarios-title">
      {{ $t('scenarios.title') }} <docs-link link="toolguide/030-scenario.html" />
    </h2>
    <v-data-table
      class="scenarios-table"
      item-key="_id"
      :single-expand="true"
      :body-append="true"
      :items="editableScenarios"
      :loading="loading"
      :expanded="initiallyExpanded"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item="{ item, expand, isExpanded, index }">
        <tr class="scenario">
          <td class="scenario__input-cell">
            <div class="scenario__input-container">
              <div class="scenario__input">
                {{ /* @submit.prevent needed to stop page reloading. see https://github.com/vuetifyjs/vuetify/issues/4302 */ }}
                <v-form v-model="valid" @submit.prevent="">
                  <rtls-text-field
                    :key="item._id"
                    v-model.trim="item.name"
                    :disabled="!hasPermission(userPermissions.canEditScenarioName)"
                    :label="$t('scenarios.scenarioName')"
                    :rules="rules"
                    style="padding-left: 7px;"
                    @blur="save(item)"
                    @keyup.enter="save(item)"
                    @keyup.esc="discard(item)"
                  />
                </v-form>
              </div>
            </div>
          </td>

          <td class="scenario__cell-container">
            <actionable-link
              class="scenario__cell clickable"
              :on-click="() => toggleExpand({ item, isExpanded: false, expand })"
              :to="item.generatedRoutes['space']"
              right-click-only
            >
              {{ $t('scenarios.space') }}
              <scenarios-list-icon :item-status="item.status" :subsection="'space'" />
            </actionable-link>
          </td>

          <td class="scenario__cell-container scenario__cell-container--main">
            <actionable-link
              :on-click="() => toggleExpand({ item, isExpanded: false, expand })"
              class="scenario__cell clickable"
              :to="item.generatedRoutes['inputs']"
              right-click-only
            >
              {{ $t('scenarios.inputs') }}
              <scenarios-list-icon :item-status="item.status" :subsection="'inputs'" />
            </actionable-link>
          </td>

          <td v-if="isFullResetWP" class="scenario__cell-container scenario__cell-container--sub">
            <actionable-link
              class="scenario__cell clickable"
              :on-click="() => toggleExpand({ item, isExpanded: false, expand })"
              :to="item.generatedRoutes['measuring']"
              right-click-only
            >
              {{ $t('scenarios.measuring') }}
              <scenarios-list-icon :item-status="item.status" :subsection="'measuring'" />
            </actionable-link>
          </td>

          <td v-if="isFullResetWP" class="scenario__cell-container scenario__cell-container--sub">
            <actionable-link
              class="scenario__cell clickable"
              :on-click="() => toggleExpand({ item, isExpanded: false, expand })"
              :to="item.generatedRoutes['modelling']"
              right-click-only
            >
              {{ $t('scenarios.modelling') }}
              <scenarios-list-icon :item-status="item.status" :subsection="'modelling'" />
            </actionable-link>
          </td>

          <td v-if="isFullResetWP" class="scenario__cell--container scenario__cell-container--main">
            <component
              :is="isAssortmentsPanelDisabled(item) ? 'div' : 'actionable-link'"
              class="scenario__cell scenario__cell--assortments"
              :class="{
                clickable: !isAssortmentsPanelDisabled(item),
              }"
              :on-click="
                () =>
                  toggleExpand({
                    item,
                    panel: 'assortment',
                    isDisabled: isAssortmentsPanelDisabled(item),
                    isExpanded: false,
                    expand,
                  })
              "
              :to="item.generatedRoutes['assortments-panel']"
              right-click-only
            >
              {{ $t('scenarios.assortments') }}
              <scenarios-list-icon :item-status="item.status" :subsection="'assortment'" />
            </component>
          </td>

          <td v-if="isFullResetWP" class="scenario__cell-container scenario__cell-container--main">
            <actionable-link
              class="scenario__cell scenario__cell--execution clickable"
              :to="item.generatedRoutes['execution']"
              :on-click="preRouteAction(item)"
            >
              {{ $t('scenarios.execution') }}
              <scenarios-list-icon :item-status="item.status" :subsection="'execution'" />
            </actionable-link>
          </td>

          <td
            v-if="isSimpleSwapsWP"
            class="scenario__cell-container scenario__cell-container--main"
          >
            <actionable-link
              class="scenario__cell clickable"
              :on-click="() => toggleExpand({ item, isExpanded: false, expand })"
              :to="item.generatedRoutes['swaps']"
              right-click-only
            >
              {{ $t('scenarios.swaps') }}
              <scenarios-list-icon :item-status="item.status" :subsection="'swaps'" />
            </actionable-link>
          </td>

          <td
            v-if="isSimpleSwapsWP"
            class="scenario__cell-container scenario__cell-container--main"
          >
            <actionable-link
              class="scenario__cell clickable"
              :on-click="() => toggleExpand({ item, isExpanded: false, expand })"
              :to="item.generatedRoutes['extracts']"
              right-click-only
            >
              {{ $t('scenarios.extracts') }}
              <scenarios-list-icon :item-status="item.status" :subsection="'extracts'" />
            </actionable-link>
          </td>

          <td class="scenario__cell-container scenario__cell-container--main">
            <div class="scenario__cell scenario__cell--last-edit">
              <span>
                {{ $t('scenarios.scenarioLastEdit') }}
                <b>{{ item.lastModifiedDate | formatDate(getDateFormats.longWithTime) }}</b>
              </span>
              <notes
                :notes="getNotes(item._id)"
                :loading="item.isLoading"
                :title="$t('notes.types.scenario')"
                :max-chars="getClientConfig.notesSettings.maxCharSize"
                :notifications="!!item.totalNotes"
                @post="postScenarioNote(item._id, index, $event)"
                @menuOpen="fetchScenarioNotes(item._id, index)"
                @delete="deleteScenarioNote(item._id, index, $event)"
                @update="updateScenarioNote(item._id, index, $event)"
              />
            </div>
          </td>

          <td class="expand-button-container">
            <div class="scenario__cell scenario__cell--icons">
              <!-- Option buttons -->
              <scenario-options :scenario="item" />
              <main-expand-button
                :class="{ 'float-right': 'float-right' }"
                :is-expanded="isExpanded"
                @expand="toggleExpand({ item, isExpanded, expand })"
              />
            </div>
          </td>
        </tr>
        <tr class="scenario spacer">
          <td class="spacer-line" colspan="12" />
        </tr>
      </template>
      <template v-slot:expanded-item="{ item }">
        <!--we need this empty tr so our expanded component will be created it's a vuetify bug-->
        <tr />
        <tr>
          <td class="scenario__mgmt-panel-container" colspan="12">
            <component :is="selectedPanel" :scenario="item" />
          </td>
        </tr>
        <tr class="scenario spacer">
          <td class="spacer-line" colspan="12" />
        </tr>
      </template>
      <template v-slot:footer>
        <scenarios-create-form
          :workpackage-id="workpackage._id"
          @save:scenario="fetchWpScenarios"
        />
      </template>
      <template v-slot:no-data>
        <div class="no-data-text">
          <span>{{ $t('scenarios.noScenariosToShow') }}</span>
          <docs-link link="toolguide/030-scenario.html" />
        </div>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { filter, cloneDeep, get, isFunction } from 'lodash';

import sectionStatuses from '@enums/section-status';
import { Errors } from '@enums/scenario-errors';
import panels from '@enums/scenario-panels';
import toolbarTabs from '@/js/utils/tabs/toolbar-tabs-enum';
import inputValidationMixin from '@/js/mixins/input-validations';
import contextEnum from '@enums/note-contexts';

export default {
  mixins: [inputValidationMixin],
  props: {
    workpackage: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      rules: [this.required, this.isUniqueScenarioName],
      scenarioNameErrors: {},
      scenarioNameServerError: '',
      selectedPanel: panels.management,
      valid: true,
      sectionStatuses,
      initiallyExpanded: [],
    };
  },
  computed: {
    ...mapState('scenarios', ['selectedScenario', 'loading']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('workpackages', ['isFullResetWP', 'isSimpleSwapsWP']),
    ...mapGetters('scenarios', ['scenarios', 'scenarioStatus']),
    ...mapGetters('context', ['getDateFormats', 'getClientConfig']),
    ...mapGetters('notes', ['getScenarioNotes']),

    isScenariosExists() {
      return this.scenarios.length;
    },

    editableScenarios() {
      // Create a copy of the scenarios for editing
      // Generate the routes for the scenario + attach
      // Add note functions to scenario
      return cloneDeep(this.scenarios).map(scenario => {
        const scenarioId = scenario._id;
        const currentScenarioStatus = this.scenarioStatus(this.selectedWorkpackage.type, scenario);

        scenario.generatedRoutes = toolbarTabs.ALL.reduce((result, tab) => {
          let tabRoute;
          if (isFunction(tab.firstChild)) {
            tabRoute = tab.firstChild(currentScenarioStatus, this.getClientConfig);
          } else {
            tabRoute = tab.firstChild || tab.routeName;
          }
          return {
            ...result,
            [tab.routeName]: {
              name: tabRoute,
              params: { scenarioId },
            },
          };
        }, {});
        scenario.isLoading = false;

        return scenario;
      });
    },

    scenarioIds() {
      return this.scenarios.map(scenario => scenario._id);
    },
  },

  async created() {
    await this.fetchWpScenarios();
    // If this wp contains the selected scenario, set that scenario to be open on load
    if (this.scenarioIds.includes(this.selectedScenario._id)) {
      this.initiallyExpanded = [this.selectedScenario];
    }
  },

  methods: {
    ...mapActions('scenarios', ['fetchScenarios', 'updateScenario', 'setSelectedScenario']),
    ...mapActions('notes', ['fetchNotes', 'postNote', 'updateNote', 'deleteNote']),

    getNotes(scenarioId) {
      return this.getScenarioNotes({ scenarioId });
    },

    async postScenarioNote(id, index, { message }) {
      this.editableScenarios[index].isLoading = true;
      await this.postNote({
        params: {
          message,
          id,
          context: contextEnum.scenario,
          workpackageId: this.workpackage._id,
        },
      });
      this.editableScenarios[index].isLoading = false;
    },

    async fetchScenarioNotes(id, index) {
      this.editableScenarios[index].isLoading = true;
      await this.fetchNotes({
        params: {
          id,
          context: contextEnum.scenario,
        },
      });
      this.editableScenarios[index].isLoading = false;
    },

    async deleteScenarioNote(id, index, { noteId }) {
      this.editableScenarios[index].isLoading = true;
      await this.deleteNote({
        params: {
          id,
          context: contextEnum.scenario,
          noteId,
        },
      });
      this.editableScenarios[index].isLoading = false;
    },

    async updateScenarioNote(id, index, { message, note }) {
      this.editableScenarios[index].isLoading = true;
      await this.updateNote({
        params: {
          id,
          context: contextEnum.scenario,
          message,
          noteId: note._id,
        },
      });
      this.editableScenarios[index].isLoading = false;
    },

    isUniqueScenarioName(value) {
      if (this.editableScenarios.filter(sc => sc.name === value.trim()).length > 1) {
        return this.$t('validationErrors.unique', [this.$t('scenarios.scenarioName')]);
      }
      return true;
    },

    isAssortmentsPanelDisabled(item) {
      const isProductModellingComplete =
        get(item, 'status.modelling.productModelling.status') === sectionStatuses.complete;
      const noCanvas = get(item, 'status.assortment.status') === sectionStatuses.notStarted;
      return (
        get(item, 'status.measuring.cdt.status', sectionStatuses.failed) !==
          sectionStatuses.complete ||
        (!isProductModellingComplete && noCanvas)
      );
    },

    selectPanel(panel) {
      this.selectedPanel = panels[panel] || panels.management;
    },

    async fetchWpScenarios() {
      const workpackageId = this.workpackage._id;
      await this.fetchScenarios({ params: { where: { workpackageId } } });
    },

    async save(item) {
      if (!this.valid) return false;

      const result = await this.updateScenario({
        scenario: {
          id: item._id,
          name: item.name,
        },
      });
      // This condition exists for the cases where the name was not repeated in the browser,
      // but when it was getting saved in the db, the same name had actually been used up, so an error got returned.
      // This condition allows server side validation errors to be displayed underneath the text box, just like the client side validations display errors.
      if (filter(result.errors, e => e.code === Errors.ScenarioNotUnique).length) {
        this.scenarioNameServerError = this.$t('validationErrors.unique', [
          this.$t('scenarios.scenarioName'),
        ]);
      }

      await this.fetchWpScenarios();
    },

    toggleExpand({ item, panel, isDisabled = false, isExpanded, expand }) {
      if (isDisabled) return;

      if (!isExpanded) {
        this.setSelectedScenario(item);
        this.selectPanel(panel);
      }
      expand(!isExpanded);
    },

    preRouteAction(scenario) {
      const vm = this;
      return () => {
        vm.setSelectedScenario(scenario);
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.section-container {
  position: relative;
  width: 100%;

  .scenarios-title {
    font-weight: bold;
    position: absolute;
    top: -30px;
    left: 2px;
    display: block;
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  .scenarios-table.v-data-table {
    .v-data-table__wrapper {
      table {
        border-collapse: separate; /* Don't collapse */
        tbody {
          .scenario {
            height: 41px;

            &.spacer {
              height: 5px;

              .spacer-line {
                height: 7px;
              }
              td {
                background-color: $assortment-background;
                height: 10px;
              }
            }
            td {
              padding-left: 10px;
              .col {
                padding: 0px;
                padding-left: 0px;
              }
              margin: 0px;
              padding: 0px;
              background-color: $assortment-scenarios-colour;
              height: 2.2rem;
              font-size: 1.2rem;
              &:not(.col-no-border) {
                //delete default border
                border-bottom-style: none;
              }
            }
            .row {
              height: 28px;
              .col {
                display: flex;
                align-items: center;
              }
            }

            .expand-button-container {
              padding-left: 5px;
            }

            td {
              position: relative;
            }

            &__input {
              background: $assortment-input-background;
              width: 100%;
              display: flex;
              align-items: center;
              height: 28px;

              form,
              span,
              .v-input {
                width: 100%;
              }

              ::v-deep {
                // styling deep nested elements that do not have a scoped data attribute
                .v-input__slot {
                  &:before,
                  &:after {
                    border: none !important;
                    bottom: -5px;
                  }
                }
                .v-text-field__slot {
                  input {
                    padding: 0 !important;
                  }
                }
                .v-label {
                  padding-top: 0px;
                  font-size: 1.2rem;
                  top: 0px;
                }
              }

              span {
                padding-bottom: 1px;
              }
            }

            &__input-container {
              width: 100%;
              display: flex;
              padding: 0 6px;
            }

            &__input-cell {
              min-width: 300px;
              background-color: $assortment-scenarios-colour;
            }

            &__cell-container {
              a {
                text-decoration: none;
                color: inherit;
              }

              &--main {
                &::after {
                  content: '';
                  background: $assortment-navbar-main-divider-colour;
                  position: absolute;
                  bottom: 15%;
                  left: 0;
                  height: 70%;
                  width: 2px;
                }
              }

              &--sub {
                &::after {
                  content: '';
                  background: $assortment-navbar-sub-divider-colour;
                  position: absolute;
                  bottom: 25%;
                  left: 0;
                  height: 50%;
                  width: 1px;
                }
              }
            }

            &__cell {
              display: flex;
              justify-content: space-evenly;
              align-items: center;
              padding: 0px 6px 0px 10px;
              background-color: $assortment-scenarios-colour;
              text-decoration: none;
              color: inherit;

              &--assortments,
              &--execution {
                padding: 0px 3px 0px 10px;
              }

              &--last-edit {
                padding: 0 10px;
                &::after {
                  content: '';
                  background: $assortment-navbar-sub-divider-colour;
                  position: absolute;
                  bottom: 20%;
                  right: 0;
                  height: 60%;
                  width: 0.1rem;
                }
              }

              &--icons {
                justify-content: flex-end;
                padding-right: 3px;

                .v-icon:not(:last-child) {
                  margin-right: 7px;
                  color: $assortment-primary-colour;
                }
              }
            }

            ::v-deep .error-triangle-container {
              padding-left: 5px;
            }

            &__mgmt-panel-container {
              padding: 0;
              border-bottom: none;
            }
          }
        }
      }
      .v-data-table__empty-wrapper .no-data-text {
        font-size: 1.2rem;
        width: 100%;
        height: 100%;
        white-space: normal;
        background: $assortment-background;
      }
    }
  }
}
</style>
