<template>
  <workflow-step :step="step" :tab-data="scenarioTabs" />
</template>

<script>
import toolbarTab from '@/js/utils/tabs/toolbar-tabs-enum';
import scenarioTabs from '@/js/utils/tabs/scenario-tabs-enum';

export default {
  data() {
    return {
      step: toolbarTab.MODELLING_TAB,
      scenarioTabs,
    };
  },
};
</script>

<style lang="scss"></style>
