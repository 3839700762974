<template>
  <v-card class="wp-scope-stores col-box pa-2">
    <div class="wp-scope-stores__container">
      <div class="mb-1">
        <h3 class="wp-scope-stores__input-heading mt-3">
          {{ $t('workpackagePage.scope.storesLabel') }}

          <!-- Stores scope tooltip -->
          <assortment-tooltip
            :title="$t('tooltips.storeScope.title')"
            :tooltip-sections="storeScopeTooltipSections"
          />
        </h3>
      </div>

      <div class="d-flex justify-end">
        <table class="item-details-table mb-2">
          <tr class="item-details-table__row">
            <td class="item-details-table__label">
              {{ $t('workpackagePage.scope.storesScopeForAnalysis') }}
            </td>
            <td class="item-details-table__count">
              {{ workpackage.storeCount ? workpackage.storeCount.analysis : 0 }}
            </td>
          </tr>
          <tr class="item-details-table__row">
            <td class="item-details-table__label">
              {{ $t('workpackagePage.scope.storesScopeForAssortment') }}
            </td>
            <td class="item-details-table__count">
              {{ workpackage.storeCount ? workpackage.storeCount.assortment : 0 }}
            </td>
          </tr>
        </table>
      </div>

      <div class="text-right">
        <workpackage-stores />
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { workpackageStoresTooltipOptionsMixin } from '@/js/mixins/tooltip-options';

export default {
  mixins: [workpackageStoresTooltipOptionsMixin],

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),

    workpackage() {
      return this.selectedWorkpackage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.wp-scope-stores {
  font-size: 1.2rem;
  margin-bottom: 10px;

  &__container {
    border-top: 1px solid $assortment-divider-colour-darker;
  }

  &__input-heading {
    font-size: 1.2rem;

    span {
      font-weight: normal;
    }
  }

  .v-btn {
    padding: 0;
  }
}
</style>
