import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: { value: _vm.value, width: "90%" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.cancel.apply(null, arguments)
        },
        "click:outside": _vm.closeModal,
      },
    },
    [
      _c(
        "dialog-card",
        {
          attrs: { title: _vm.$tkey("modalTitle") },
          on: { close: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    VCardActions,
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { primary: "" },
                          on: { click: _vm.closeModal },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("actions.cancel")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "outlined-btn",
                          attrs: {
                            secondary: "",
                            depressed: "",
                            disabled: !_vm.selectedRows.length,
                            loading: _vm.isCreatingWorkpackage,
                          },
                          on: { click: _vm.createWorkpackageFromTemplate },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.select")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "ag-grid-box flex-grow-1 grid-size" },
            [
              _c("ag-grid-vue", {
                staticClass: "ag-theme-custom",
                staticStyle: { width: "100%", height: "400px" },
                attrs: {
                  "column-defs": _vm.columnDefs,
                  "row-data": _vm.rowData,
                  "grid-options": _vm.gridOptions,
                },
                on: {
                  "grid-ready": _vm.onGridReady,
                  "selection-changed": _vm.onSelectionChanged,
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }