import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "list-view-grid-item",
      style: { "grid-row": `span ${_vm.rowCount}`, ..._vm.containerStyle },
    },
    [
      _c("div", [
        _c(
          "div",
          {
            ref: "list-view-container",
            staticClass: "list-view",
            style: _vm.containerStyle,
            attrs: { "ma-0": "", "pa-0": "" },
          },
          [
            _c(
              "div",
              {
                staticClass: "headers pl-2 d-flex",
                class: _vm.positioning,
                style: { top: `${_vm.previousHeights}px` },
              },
              [
                _vm._l(_vm.visibleListViewColumns, function (column, index) {
                  return _c(
                    VCol,
                    {
                      key: `${column.id}-${index}`,
                      class: column.headerClass,
                      style: { width: column.width + "px" },
                      attrs: { title: column.id },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getColumnTitle(column)) +
                          "\n        "
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _c(VCol, { staticClass: "header-col product-info-column" }),
              ],
              2
            ),
            _vm._v(" "),
            _vm._l(_vm.spacebreaks, function (spacebreak) {
              return _c(
                VRow,
                {
                  key: spacebreak._id,
                  staticClass: "spacebreak-row spacebreak-with-products",
                },
                [
                  _vm.listViewContainerRef
                    ? _c("list-view-entry", {
                        attrs: {
                          title: _vm.createTitle(spacebreak),
                          products: _vm.getProducts(spacebreak._id),
                          "spacebreak-id": spacebreak._id,
                          "group-name": _vm.groupName,
                          "list-view-ref": _vm.listViewContainerRef,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            _vm._v(" "),
            _vm._l(_vm.spacebreakTypes, function (spacebreak) {
              return _c(
                VRow,
                {
                  key: spacebreak,
                  staticClass: "spacebreak-row",
                  attrs: { id: `${spacebreak}-spacebreak-list` },
                },
                [
                  _vm.listViewContainerRef
                    ? _c("list-view-entry", {
                        attrs: {
                          title: _vm.$tkey(spacebreak),
                          products: _vm.productsWithoutSpacebreak[spacebreak],
                          "spacebreak-id": null,
                          "spacebreak-type": spacebreak,
                          "group-name": _vm.groupName,
                          "list-view-ref": _vm.listViewContainerRef,
                          "container-id": spacebreak,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }