<template>
  <div class="select-renderer" :style="{ maxWidth, width: maxWidth }">
    <rtls-select
      :value="params.value"
      hide-messages
      white
      :class="{ 'diff-background': isHighlighted }"
      :items="items"
      :disabled="isDisabled"
      @change="update"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { get } from 'lodash';

export default Vue.extend({
  name: 'selectBoxRenderer',
  computed: {
    items() {
      return get(this.params, 'items', []);
    },

    isDisabled() {
      return get(this.params, 'isDisabled', false);
    },

    isHighlighted() {
      return get(this.params, 'isHighlighted', false);
    },

    maxWidth() {
      return get(this.params, 'maxWidth', '100%');
    },
  },

  methods: {
    update(value) {
      this.params.setValue(value);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.select-renderer {
  ::v-deep {
    .v-select.rtls-select--white {
      .v-select__slot {
        background: $assortment-input-background;
      }

      &.diff-background .v-select__slot {
        background: $assortment-table-changed-cell-bg-colour;
      }
    }
  }
}
</style>
