<template>
  <v-row class="assortment-groups">
    <v-col class="col-border-left pt-0 pb-0" :cols="6">
      <div class="col-padding">
        <h3 class="assortment-groups__input-heading">
          {{ assortmentGroupsLabel }}
        </h3>
        <v-select
          v-model="selectedCategories"
          :items="categories"
          multiple
          :placeholder="$t('workpackagePage.scope.filterByCategoryLabel')"
          :menu-props="{ bottom: true, offsetY: true }"
        >
          <template slot="append">
            <v-icon size="28">expand_more</v-icon>
          </template>
        </v-select>
        <v-sheet class="margin-right">
          <div class="search">
            <rtls-search
              v-model="assortmentSearch"
              :placeholder="$t('workpackagePage.scope.searchLabel')"
            />
          </div>
          <div data-id-e2e="listAvailableAssortmentGroupsOld" class="list pa-3">
            <progress-bar v-if="loading" :message="$t('general.loading')" />
            <template v-else>
              <recycle-scroller
                v-if="templatesEnabled && !!workpackage"
                :key="uniqueId('selectedGroup')"
                :items="unselectedAgsSection.fromTemplate"
                :item-size="1"
                :buffer="200"
                :page-mode="true"
                key-field="key"
                class="scroller split-scroller"
              >
                <template #before>
                  <span v-if="hasTemplateAvailable">{{
                    `${$t('general.from')} ${$t('general.template')}`
                  }}</span>
                </template>
                <template v-slot="props">
                  <span
                    class="list-item"
                    :class="{ selectable: clickableAGSelectionsEnabled }"
                    @click="clickableAGSelectionsEnabled && selectAssortmentGroup(props.item)"
                  >
                    {{ props.item.text }}
                    <v-icon
                      size="14"
                      color="primary"
                      class="list-icon"
                      @click.stop="selectAssortmentGroup(props.item)"
                    >
                      mdi-plus-thick
                    </v-icon>
                  </span>
                </template>
              </recycle-scroller>
              <recycle-scroller
                :key="uniqueId('availableGroups')"
                :items="unselectedAgsSection.additional"
                :item-size="1"
                :page-mode="true"
                key-field="key"
                class="scroller"
              >
                <template #before>
                  <span v-if="hasTemplateAvailable" class="">{{ $t('general.additional') }}</span>
                </template>
                <template v-slot="props">
                  <span
                    class="list-item"
                    :class="{ selectable: clickableAGSelectionsEnabled }"
                    @click="clickableAGSelectionsEnabled && selectAssortmentGroup(props.item)"
                  >
                    {{ props.item.text }}
                    <v-icon
                      size="14"
                      color="primary"
                      class="list-icon"
                      @click.stop="selectAssortmentGroup(props.item)"
                    >
                      mdi-plus-thick
                    </v-icon>
                  </span>
                </template>
              </recycle-scroller>
            </template>
          </div>
        </v-sheet>
      </div>
    </v-col>
    <v-col class="margin-right col-border-left pt-0 pb-0" :cols="6">
      <div
        data-id-e2e="listSelectedAssortmentGroupsOld"
        class="col-padding
        d-flex flex-column
        justify-start"
      >
        <v-row no-gutters class="row-assortment-groups">
          <v-col :cols="7">
            <h3 class="assortment-groups__input-heading">
              {{ $t('workpackagePage.scope.selectedAssortmentGroupsLabel') }}
              - {{ selectedAssortmentGroups.length }}
            </h3>
          </v-col>
          <v-col :cols="5" align="right">
            <v-btn
              :disabled="
                !hasPermission(userPermissions.canEditWorkpackageScope) || isImportingFromPlano
              "
              color="primary"
              text
              plain
              @click="resetSelectedAssortmentGroups"
            >
              {{ $t('workpackagePage.scope.resetSelection') }}
            </v-btn>
          </v-col>
        </v-row>

        <v-sheet class="list selected-list pa-3">
          <progress-bar v-if="loading" :message="$t('general.loading')" />
          <template v-else>
            <template v-if="templatesEnabled && !!workpackage">
              <recycle-scroller
                :key="uniqueId('selectedGroup')"
                :items="selectedAGsSection.fromTemplate"
                :item-size="1"
                :buffer="200"
                :page-mode="true"
                key-field="key"
                class="scroller split-scroller"
              >
                <template v-if="hasTemplateAvailable" #before>
                  <span>{{ `${$t('general.from')} ${$t('general.template')}` }}</span>
                </template>
                <template v-slot="props">
                  <span
                    class="list-item"
                    :class="{ selectable: clickableAGSelectionsEnabled }"
                    @click="clickableAGSelectionsEnabled && deselectAssortmentGroup(props.item)"
                  >
                    {{ props.item.text }}
                    <v-icon
                      size="14"
                      color="primary"
                      class="list-icon"
                      @click.stop="deselectAssortmentGroup(props.item)"
                    >
                      mdi-close-circle
                    </v-icon>
                  </span>
                </template>
              </recycle-scroller>
            </template>
            <recycle-scroller
              :key="uniqueId('selectedGroup')"
              :items="selectedAGsSection.additional"
              :item-size="1"
              :buffer="200"
              :page-mode="true"
              key-field="key"
              class="scroller"
            >
              <template #before>
                <span v-if="hasTemplateAvailable">{{ $t('general.additional') }}</span>
              </template>
              <template v-slot="props">
                <span
                  class="list-item"
                  :class="{ selectable: clickableAGSelectionsEnabled }"
                  @click="clickableAGSelectionsEnabled && deselectAssortmentGroup(props.item)"
                >
                  {{ props.item.text }}
                  <v-icon
                    size="14"
                    color="primary"
                    class="list-icon"
                    @click.stop="deselectAssortmentGroup(props.item)"
                  >
                    mdi-close-circle
                  </v-icon>
                </span>
              </template>
            </recycle-scroller>
          </template>
        </v-sheet>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {
  includes,
  filter,
  indexOf,
  map,
  flatMap,
  uniq,
  uniqueId,
  sortedIndexBy,
  orderBy,
  get,
  keyBy,
} from 'lodash';

import { mapState, mapActions, mapGetters } from 'vuex';
import AssortmentGroupTypes from '@enums/assortment-group-types';

export default {
  props: {
    snapshotDate: {
      type: String,
      required: true,
    },

    resetAssortmentGroups: {
      type: Boolean,
      required: false,
      default: true,
    },

    selectedAssortmentGroupSettings: {
      type: Array,
      required: true,
    },

    workpackage: {
      type: Object,
      required: false, // not required for template
      default: null,
    },
  },

  data: () => ({
    selectedAssortmentGroups: [],
    unselectedAssortmentGroups: [],
    selectedCategories: [],
    assortmentSearch: null,
    uniqueId,
  }),

  computed: {
    ...mapState('context', ['clientConfig']),
    ...mapState('assortmentGroups', [
      'masterAssortmentGroups',
      'categoryToAssortmentGroupKeysMap',
      'loading',
    ]),
    ...mapState('workpackages', ['isImportingFromPlano']),
    ...mapGetters('workpackages', ['templatesById']),

    clickableAGSelectionsEnabled() {
      // The entire AG option should be clickable by default
      return get(this.getClientConfig, 'features.uiComponents.clickableAGSelections', true);
    },

    selectedAssortmentGroupSettingsByKey() {
      return keyBy(this.selectedAssortmentGroupSettings, 'key');
    },

    categories() {
      if (!this.unselectedAssortmentGroups) {
        return [];
      }

      const filterType = get(
        this.clientConfig,
        'assortmentGroups.filterType',
        'associatedProductCategoryDescriptions'
      );

      // Retrieve a unique list of categories
      const categories = flatMap(this.unselectedAssortmentGroups, ag => ag[filterType]);
      return orderBy(uniq(categories), [category => category.toLocaleLowerCase()], 'asc');
    },

    assortmentGroupsLabel() {
      const labelKey = {
        [AssortmentGroupTypes.furniture]: 'availableAssortmentGroupsLabelFurniture',
        [AssortmentGroupTypes.nonFurniture]: 'availableAssortmentGroupsLabelNonFurniture',
      }[this.clientConfig.assortmentGroups.mainType];
      return this.$t(`workpackagePage.scope.${labelKey}`, {
        num: this.availableAssortmentGroups.length,
      });
    },

    availableAssortmentGroups() {
      let filteredAssortmentGroups;
      // First check if there is a category filter
      if (this.selectedCategories.length === 0) {
        filteredAssortmentGroups = this.unselectedAssortmentGroups;
      } else {
        const uniqueKeys = uniq(
          flatMap(this.selectedCategories, c => this.categoryToAssortmentGroupKeysMap[c])
        );
        filteredAssortmentGroups = this.unselectedAssortmentGroups.filter(item => {
          return includes(uniqueKeys, item.key);
        });
      }

      // Now that we have filtered by category (or not), search by text
      if (this.assortmentSearch) {
        filteredAssortmentGroups = filteredAssortmentGroups.filter(item => {
          return includes(item.text.toLocaleLowerCase(), this.assortmentSearch.toLocaleLowerCase());
        });
      }
      return orderBy(
        filteredAssortmentGroups,
        [assortmentGroup => get(assortmentGroup, 'text', '').toLocaleLowerCase()],
        'asc'
      );
    },

    assortmentGroupsFromTemplate() {
      if (!this.workpackage)
        return { staticAssortmentGroupKeys: new Set([]), assortmentGroupsSelectedFromTemplate: [] };
      const assortmentGroupsSelectedFromTemplate = get(
        this.templatesById,
        [this.workpackage.templateId, 'assortmentGroups'],
        []
      );
      const staticAssortmentGroupKeys = new Set(
        assortmentGroupsSelectedFromTemplate.map(ag => ag.staticAssortmentGroupKey)
      );
      return {
        staticAssortmentGroupKeys,
        assortmentGroupsSelectedFromTemplate,
      };
    },

    unselectedAgsSection() {
      return this.splitFromTemplateVsAdditionalAssortmentGroups(this.availableAssortmentGroups);
    },

    selectedAGsSection() {
      return this.splitFromTemplateVsAdditionalAssortmentGroups(this.selectedAssortmentGroups);
    },

    hasTemplateAvailable() {
      return !!get(this.workpackage, 'templateId', null);
    },

    useOldVersionOfAGSelection() {
      return get(this.clientConfig, 'features.useOldVersionOfAGSelection');
    },

    deleteAGDataOnResetSelectionEnabled() {
      return get(
        this.getClientConfig,
        'features.uiComponents.deleteAGDataOnResetSelectionEnabled',
        false
      );
    },
  },

  watch: {
    async snapshotDate() {
      await this.fetchAssortmentGroups(this.snapshotDate);
      if (this.resetAssortmentGroups) this.resetSelectedAssortmentGroups();
      else this.populateAssortmentGroups();
    },
  },

  async created() {
    await this.fetchAssortmentGroups(this.snapshotDate);
    this.populateAssortmentGroups();
  },

  methods: {
    ...mapActions('assortmentGroups', ['fetchAssortmentGroups']),
    ...mapActions('workpackages', ['updateWorkpackage']),
    ...mapActions('workpackageProducts', ['deleteWorkpackageProducts']),

    populateAssortmentGroups() {
      this.unselectedAssortmentGroups = this.masterAssortmentGroups;

      // pre-populate already selected assortment groups and notify parent component above
      const assortmentsToSelect = this.unselectedAssortmentGroups
        .filter(ag => !!this.selectedAssortmentGroupSettingsByKey[ag.key])
        .map(ag => {
          const workpackageAssortmentGroup = this.selectedAssortmentGroupSettingsByKey[ag.key];
          return {
            ...ag,
            analysis: workpackageAssortmentGroup.analysis,
            assortment: workpackageAssortmentGroup.assortment,
          };
        });
      this.selectAssortmentGroup(assortmentsToSelect);
    },

    async resetSelectedAssortmentGroups() {
      // Unselect all assortment groups
      this.unselectedAssortmentGroups = this.masterAssortmentGroups;
      this.selectedAssortmentGroups = [];

      if (this.deleteAGDataOnResetSelectionEnabled) {
        // Remove imported stores and products
        const workpackageId = this.workpackage._id;
        await Promise.all([
          await this.deleteWorkpackageProducts({ workpackageId }),
          await this.updateWorkpackage({ workpackageId, updates: { stores: [] } }),
        ]);
      }

      this.$emit('select-assortment-group', this.selectedAssortmentGroups);
    },

    selectAssortmentGroup(assortmentGroup) {
      // if we're selecting from template or user is using old view
      // always assign AG to both analysis and assortment
      if (!this.workpackage || this.useOldVersionOfAGSelection) {
        assortmentGroup.analysis = true;
        assortmentGroup.assortment = true;
      }

      this.selectedAssortmentGroups = flatMap([this.selectedAssortmentGroups, assortmentGroup]);
      const selectedAssortmentGroupKeys = map(this.selectedAssortmentGroups, 'key');
      this.unselectedAssortmentGroups = filter(
        this.unselectedAssortmentGroups,
        o => indexOf(selectedAssortmentGroupKeys, o.key) === -1
      );
      this.$emit('select-assortment-group', this.selectedAssortmentGroups);
    },

    deselectAssortmentGroup(assortmentGroup) {
      this.selectedAssortmentGroups = filter(
        this.selectedAssortmentGroups,
        currentObject => currentObject.key !== assortmentGroup.key
      );
      // Insert assortmentGroup back into unselectedAssortmentGroups maintaining order
      const indexToInsert = sortedIndexBy(
        this.unselectedAssortmentGroups,
        assortmentGroup,
        'sortKey'
      );
      this.unselectedAssortmentGroups.splice(indexToInsert, 0, assortmentGroup);
      this.$emit('select-assortment-group', this.selectedAssortmentGroups);
    },

    splitFromTemplateVsAdditionalAssortmentGroups(fromList) {
      const fromTemplate = [];
      const additional = [];

      const { staticAssortmentGroupKeys } = this.assortmentGroupsFromTemplate;
      fromList.forEach(ag => {
        if (staticAssortmentGroupKeys.has(ag.staticAssortmentGroupKey)) {
          fromTemplate.push(ag);
        } else {
          additional.push(ag);
        }
      });

      return {
        fromTemplate,
        additional,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.assortment-groups {
  height: 100%;
  &__input-heading {
    margin-bottom: 7px;
    font-size: 1.2rem;
  }

  ::v-deep {
    .v-input__append-inner {
      margin-top: 0 !important;
    }
    &.pa-3 {
      overflow: auto !important;
    }

    .v-select__selections {
      flex-wrap: nowrap;
    }
  }

  a {
    font-size: 1.2rem;
    font-weight: normal;
  }

  .col-padding {
    height: 100%;
  }
}

.v-chip {
  width: 100%;
  height: auto;
  border-radius: 2px;
}
.v-chip__content {
  display: block !important;
}
.v-chip__content.v-icon {
  float: right;
}

.list {
  /* assortments list*/
  height: 430px;
  overflow-y: auto;
}
.selected-list {
  /* selected assortments list*/
  height: 500px;
}

.row-assortment-groups {
  flex: 0 1 auto;
}

.scroller {
  overflow-y: scroll;
  height: 100%;
}

::v-deep {
  // hack to remove component required by scroller
  .split-scroller {
    height: min-content !important;
    & > .resize-observer {
      display: none !important;
    }
  }
}

.list-icon {
  position: absolute;
  right: 7px;
  font-size: 20px;
}

.list-item:not(.selectable) {
  cursor: default;
}
</style>
