import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        VContainer,
        { staticClass: "pa-0 ma-0 variety-container d-flex flex-column" },
        [
          _c(
            VRow,
            { staticClass: "header-container flex-grow-0 ma-0" },
            [
              _c(
                VCol,
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("varietyGroups.infoNote"))),
                  ]),
                  _vm._v(" "),
                  _c("docs-link", {
                    attrs: { link: "toolguide/070-varietygroups.html" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "main-panel no-gutters d-flex flex-row flex-grow-1",
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                VCol,
                { staticClass: "d-flex flex-column", attrs: { cols: "2" } },
                [
                  _c("cann-group-menu", {
                    attrs: {
                      "selected-cann-group": _vm.selectedCannGroup
                        ? _vm.selectedCannGroup.key
                        : null,
                      "preselect-cann-group": false,
                    },
                    on: { onCannGroupSelected: _vm.selectCannGroup },
                    scopedSlots: _vm._u([
                      {
                        key: "tickbox",
                        fn: function (props) {
                          return [
                            _vm.existsInVarietyGroups(props.cgKey)
                              ? _c(
                                  VIcon,
                                  {
                                    staticClass: "float-right mt-1",
                                    attrs: { size: "20" },
                                  },
                                  [
                                    _vm._v(
                                      "\n              $success\n            "
                                    ),
                                  ]
                                )
                              : _c(
                                  VIcon,
                                  {
                                    staticClass: "float-right",
                                    attrs: { color: "#d3d3d3" },
                                  },
                                  [_vm._v("mdi-checkbox-blank-circle")]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VCol,
                {
                  staticClass: "variety-attributes d-flex flex-column",
                  attrs: { cols: "6" },
                },
                [
                  _c(
                    VRow,
                    { staticClass: "variety-attributes-title flex-grow-0" },
                    [
                      _c(VCol, [
                        _vm._v(_vm._s(_vm.$t("varietyGroups.settings"))),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "variety-attributes-container" },
                    [
                      _c("variety-attributes", {
                        ref: "varietyAttributes",
                        attrs: { "selected-cann-group": _vm.selectedCannGroup },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(VCol, { attrs: { cols: "4" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "page-actions-container",
              attrs: { "data-id-e2e": "btnConfirmVarietyGroups" },
            },
            [
              _c("page-actions", {
                attrs: {
                  "has-data-changes": true,
                  "has-data-errors": _vm.hasDataErrors,
                  "save-btn-text": "confirmAsSet",
                  "show-discard": false,
                  "save-disabled": _vm.isEditingDisabled,
                  "is-discard-enabled": !_vm.isEditingDisabled,
                },
                on: { save: _vm.saveAll },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.closeUnsavedDataModal,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }