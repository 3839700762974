<template>
  <v-app>
    <link
      href="https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Round"
      rel="stylesheet"
    />
    <link
      href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css"
      rel="stylesheet"
    />
    <main-navbar v-if="!hideNavbar" />
    <v-main>
      <v-container class="main-container" fluid>
        <transition name="fade" mode="out-in">
          <router-view class="main-page" />
        </transition>
      </v-container>
    </v-main>
    {{ /* AOV3-823 TODO: better handling for multiple notifications? possibly display stack of snackbars? */ }}
    <main-snackbar />
    <main-alert />
    <connection-snackbar />
    <main-footer />
    <div id="consent_blackbar" />
  </v-app>
</template>

<script>
import { find, get } from 'lodash';
import { mapState } from 'vuex';

export default {
  data() {
    return {
      notificationColours: {
        starting: 'info',
        finished: 'success',
        failed: 'error',
      },
    };
  },

  computed: {
    ...mapState('context', ['clientConfig']),
    topLevelRoute() {
      const topLevelNavPath = get(this, '$route.matched.0.path');
      return find(this.$router.options.routes, { path: topLevelNavPath }) || {};
    },

    hideNavbar() {
      if (!this.topLevelRoute.meta) return false;
      return this.topLevelRoute.meta.hideNavbar;
    },
  },

  mounted() {
    if (!this.clientConfig.features.showStrictlyNecessaryCookiesNotice) return;

    const cookieScript = document.createElement('script');
    cookieScript.setAttribute(
      'src',
      '//cmp.osano.com/16CFuWSuPfE35eNP/9e220dc6-b4e9-4b7d-8de4-9aa0e81a60b0/osano.js'
    );
    document.body.appendChild(cookieScript);
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';
html {
  position: relative;
  min-height: 100%;
}

.main-container {
  height: 100%;
  padding-bottom: 1px !important;
  padding-top: 20px !important;
}
.main-page {
  height: 100%;
  padding-bottom: $page-padding-bottom;
}
</style>
