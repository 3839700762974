import { render, staticRenderFns } from "./previous-compound-reports.vue?vue&type=template&id=1bf3e389&scoped=true"
import script from "./previous-compound-reports.vue?vue&type=script&lang=js"
export * from "./previous-compound-reports.vue?vue&type=script&lang=js"
import style0 from "./previous-compound-reports.vue?vue&type=style&index=0&id=1bf3e389&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bf3e389",
  null
  
)

export default component.exports