import to from 'await-to-js';
import axios from 'axios';
import { jobStatuses } from '@enums/jobapi';

async function runFunction(functionName, params = {}) {
  const [err, response] = await to(
    axios.post(`/api/jobapi-engine/functions/${functionName}`, params)
  );
  if (err) throw new Error(err.message);
  return response.data;
}

function isRunningStatus(status) {
  return ![jobStatuses.finished, jobStatuses.failed].includes(status);
}

export default {
  runFunction,
  isRunningStatus,
};
