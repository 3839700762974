var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "workpackage d-flex flex-column",
      class: _vm.workpackageSetupJobStatus,
    },
    [
      _c("workpackage-panel", {
        ref: "panel",
        attrs: {
          workpackage: _vm.item,
          "disable-expand": _vm.isWorkpackageSetupRunning,
          "is-link": _vm.isLink,
        },
        on: { "save:workpackage": _vm.validateAndSaveWorkpackage },
      }),
      _vm._v(" "),
      _vm.isScoped
        ? _c("workpackage-scope", {
            ref: "scope",
            attrs: {
              "is-workpackage-setup-running": _vm.isWorkpackageSetupRunning,
            },
            on: {
              close: _vm.closeScope,
              "save:workpackage": _vm.validateAndSaveWorkpackage,
              "run-workpackage-setup": _vm.runWorkpackageSetup,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isWPScenariosExpanded
        ? _c("workpackage-scenarios", {
            staticClass: "pl-5",
            attrs: { workpackage: _vm.item },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("dependency-tree-feedback-modal", {
        attrs: {
          value: _vm.dependencyTreeModalOpen,
          results: _vm.dependencyTreeFeedback,
          page: "workpackageSetup",
        },
        on: {
          close: _vm.closeDependencyTreeModal,
          commit: function ($event) {
            return _vm.commitHandler()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }