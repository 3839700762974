var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "158px",
        height: "33px",
        viewBox: "0 0 158 33",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("RA_logo")]),
      _vm._v(" "),
      _c("desc", [_vm._v("Created with Sketch.")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "RA_logo",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            "fill-opacity": "0.85",
            "font-family": "NotoSans, Noto Sans",
            "font-weight": "normal",
          },
        },
        [
          _c("g", { attrs: { id: "pen-header_1", fill: "#000000" } }, [
            _c("g", { attrs: { id: "Group-5" } }, [
              _c("g", { attrs: { id: "Group" } }, [
                _c("g", { attrs: { id: "Group-2" } }, [
                  _c("g", { attrs: { id: "Group-3" } }, [
                    _c(
                      "text",
                      {
                        attrs: {
                          id: "RetailAssortment",
                          "font-size": "20",
                          "line-spacing": "20",
                        },
                      },
                      [
                        _c(
                          "tspan",
                          {
                            attrs: {
                              "data-id-e2e": "hdrRetail",
                              x: "0",
                              y: "21.3430176",
                            },
                          },
                          [
                            _vm._v(
                              "\n                  Retail\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "tspan",
                          {
                            attrs: {
                              "data-id-e2e": "hdrAssortment",
                              x: "52.1219531",
                              y: "21",
                              "font-family": "NotoSans-Light, Noto Sans",
                              "font-weight": "300",
                            },
                          },
                          [
                            _vm._v(
                              "\n                  Assortment\n                "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "text",
                      {
                        attrs: {
                          id: "An-Oliver-Wyman-Solu",
                          "font-size": "8",
                          "line-spacing": "12",
                        },
                      },
                      [
                        _c("tspan", { attrs: { x: "1", y: "30" } }, [
                          _vm._v("An Oliver Wyman Solution"),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }