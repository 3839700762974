import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _vm.noProductsHaveSwapsData
        ? _c(
            VContainer,
            { staticClass: "pt-3 pa-2 ma-0" },
            [
              _c(VAlert, { staticClass: "mb-1", attrs: { text: "" } }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$tkey("checkSuggestedSelectionsMessage")) +
                    "\n    "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "assortment-table attributes-table d-flex flex-column" },
        [
          _c(
            VContainer,
            { staticClass: "actions-container flex-grow-0" },
            [
              _c(
                VRow,
                [
                  _vm.showErrorControls
                    ? _c(
                        VCol,
                        {
                          staticClass: "d-flex align-center col",
                          attrs: { id: "error-controls" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "invalid-rows-error-box pr-2" },
                            [_vm._v(_vm._s(_vm.invalidRowsErrorMessage))]
                          ),
                          _vm._v(" "),
                          !_vm.filterInvalidRows
                            ? _c(
                                VBtn,
                                {
                                  attrs: {
                                    outlined: "",
                                    depressed: "",
                                    color: "error",
                                  },
                                  on: { click: _vm.toggleInvalidRows },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("actions.showInvalidRows")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _c(
                                VBtn,
                                {
                                  attrs: {
                                    outlined: "",
                                    depressed: "",
                                    color: "primary",
                                  },
                                  on: { click: _vm.toggleInvalidRows },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("actions.showAllRows")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { staticClass: "actions-col actions-col--search" },
                    [
                      _vm.gridApi
                        ? _c("rtls-search", {
                            attrs: {
                              grey: "",
                              width: "240px",
                              placeholder: _vm.$tkey("searchPlaceholder"),
                            },
                            on: {
                              input: function ($event) {
                                return _vm.gridApi.onFilterChanged()
                              },
                            },
                            model: {
                              value: _vm.searchString,
                              callback: function ($$v) {
                                _vm.searchString = $$v
                              },
                              expression: "searchString",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VContainer,
            { staticClass: "actions-container flex-grow-0" },
            [
              _c(
                VRow,
                { staticClass: "products-table-actions" },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "actions-col",
                      attrs: { "data-id-e2e": "btnProductsSwapImport" },
                    },
                    [
                      _c("data-upload", {
                        attrs: {
                          legends: _vm.csvUploadLegends,
                          disabled: _vm.isEditingDisabled,
                          "csv-upload-handler": _vm.onCSVUpload,
                          "products-data": _vm.productsData,
                          "used-header-names": _vm.allHeaders,
                          "custom-fields": [..._vm.swapsAttributeHeaderNames],
                          "show-modal": "",
                        },
                        on: { process: _vm.process },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ag-grid-box flex-grow-1" },
            [
              _c("ag-grid-vue", {
                staticClass: "ag-theme-custom ag-theme-custom--attributes",
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  "row-data": _vm.productsData,
                  "grid-options": _vm.gridOptions,
                  "does-external-filter-pass": _vm.doesExternalFilterPass,
                  "stop-editing-when-cells-loses-focus": true,
                  "enable-range-selection": true,
                },
                on: {
                  "cell-value-changed": _vm.trackDiff,
                  "grid-ready": _vm.onGridReady,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("page-actions", {
            attrs: {
              "show-export": "",
              "is-custom-export": "",
              "has-data-changes": !_vm.isActionsDisabled,
              "has-data-errors": _vm.hasDataErrors,
              "is-discard-enabled": !_vm.isDiscardDisabled,
              "save-disabled": _vm.isEditingDisabled,
            },
            on: {
              export: function ($event) {
                return _vm.exportCSV()
              },
              discard: function ($event) {
                return _vm.discard()
              },
              save: function ($event) {
                return _vm.saveChanges()
              },
            },
            scopedSlots: _vm._u([
              {
                key: "right-btns",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "optimise-btn-container" },
                      [
                        _c(
                          VBtn,
                          {
                            attrs: {
                              primary: "",
                              disabled: _vm.isEditingDisabled,
                            },
                            on: { click: _vm.openSwapParametersModal },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tkey("optimiseSwapsButton")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("swap-parameters-modal", {
            attrs: { value: _vm.swapParametersModalOpen },
            on: { close: _vm.closeSwapParametersModal },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.closeUnsavedDataModal,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }