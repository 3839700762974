import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.selectedCannGroup
    ? _c(
        VContainer,
        {
          staticClass: "ma-0 pa-0 variety-attrs-container",
          attrs: { fluid: "" },
        },
        [
          _c(
            "div",
            { staticClass: "central-panel d-flex flex-column" },
            [
              _c(
                VRow,
                { staticClass: "align-end flex-grow-0" },
                [
                  _c(VCol, { staticClass: "pl-5 py-2" }, [
                    _vm._v(_vm._s(_vm.$t("varietyGroups.customerAttrs")) + ":"),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { staticClass: "pl-0 py-2" }, [
                    _c("span", { staticClass: "regular-text" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("varietyGroups.constantValuesAttrs")) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("varietyGroups.constantValues")) +
                        "\n      "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "panel-container full-height" },
                [
                  _c(
                    VCol,
                    { staticClass: "pt-0 first-panel" },
                    [
                      _c("variety-drag-container", {
                        attrs: {
                          disabled: _vm.isEditingDisabled,
                          items: _vm.unusedAttributes,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { staticClass: "pt-0 second-panel" },
                    [
                      _c("variety-drag-container", {
                        attrs: {
                          disabled: _vm.isEditingDisabled,
                          "show-footer": true,
                          items: _vm.remainConstantAttributes,
                        },
                      }),
                      _vm._v(" "),
                      _c(VDivider),
                      _vm._v(" "),
                      _c(VCol, { staticClass: "pl-2 py-2 sticky-title" }, [
                        _c("span", { staticClass: "regular-text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("varietyGroups.diffValuesAttrs")) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("varietyGroups.diffValues")) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("variety-drag-container", {
                        attrs: {
                          disabled: _vm.isEditingDisabled,
                          "show-footer": true,
                          items: _vm.canDifferAttributes,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { staticClass: "actions", attrs: { cols: "12" } },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "discard-btn",
                          attrs: {
                            disabled: _vm.isEditingDisabled,
                            outlined: "",
                          },
                          on: { click: _vm.discardToPrevious },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("actions.discardChanges")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          attrs: {
                            disabled: _vm.isEditingDisabled,
                            action: "",
                          },
                          on: { click: _vm.saveOne },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("actions.save")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }