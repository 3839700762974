import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VStepper,
    {
      staticClass: "stepper",
      model: {
        value: _vm.currentStepPosition,
        callback: function ($$v) {
          _vm.currentStepPosition = $$v
        },
        expression: "currentStepPosition",
      },
    },
    [
      _c(
        VStepperHeader,
        [
          _vm._l(_vm.stepperData.steps, function (step) {
            return [
              _c(
                VStepperStep,
                {
                  key: `${step.componentName}-step`,
                  class: { clickable: step.isClickable },
                  attrs: {
                    complete: _vm.isStepComplete(step),
                    step: step.order,
                    "complete-icon": "$success",
                  },
                  on: {
                    click: function ($event) {
                      step.isClickable && _vm.handleStepSelect(step.order)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(step.translationKey)) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              step.order < _vm.stepperData.steps.length
                ? _c(VDivider, { key: step.order })
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        VStepperItems,
        _vm._l(_vm.stepperData.steps, function (step) {
          return _c(
            VStepperContent,
            {
              key: `${step.componentName}-content`,
              attrs: { step: step.order },
            },
            [
              _vm.activeStep.order === step.order
                ? [
                    _c(_vm.activeStep.componentName, {
                      ref: _vm.activeStep.componentName,
                      refInFor: true,
                      tag: "component",
                      attrs: { "extract-type": _vm.extractType },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row stepper__actions" },
                      [
                        _c(
                          VBtn,
                          {
                            attrs: {
                              text: "",
                              disabled: _vm.isPreviousDisabled,
                            },
                            on: { click: _vm.handlePreviousStep },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.previous")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            staticClass: "stepper__button",
                            attrs: {
                              disabled: _vm.isNextDisabled,
                              color: "primary",
                            },
                            on: { click: _vm.handleNextStep },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.next")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.closeUnsavedDataModal,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }