<template>
  <div>
    <workpackages-toolbar
      doc-path="190-bundles"
      :entity="entities.bundle"
      archived
      @on-search="onSearch"
    />
    <v-alert
      v-if="loadingArchivedBundles && !archivedBundlesTotalCount"
      border="left"
      colored-border
      color="primary accent-4"
    >
      <v-progress-circular indeterminate color="primary" size="20" />
      {{ $t('bundlesPage.loadingArchivedBundles') }}
    </v-alert>
    <v-card v-else class="col-box">
      <v-row no-gutters>
        <v-col class="workpackages-container" colspan="12">
          <workpackages-list
            :entity="entities.bundle"
            :workpackages="archivedBundles"
            :visible-workpackages="visibleBundles"
          />
          <v-alert v-if="!loadingArchivedBundles && !visibleBundles.length" type="info" text>
            {{ $t(`bundlesPage.noArchived${capitalize(entities.bundle)}s`) }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="horizontal-divider-container" colspan="12">
          <div class="horizontal-divider" />
        </v-col>
      </v-row>

      <template v-if="archivedBundlesTotalCount">
        <div class="text-center">
          <v-pagination
            v-model="currentPage"
            :length="totalPageCount"
            :total-visible="totalVisiblePages"
          />
        </div>
      </template>
      <v-overlay v-if="loadingArchivedBundles" absolute opacity="0.5" color="white">
        <v-progress-circular indeterminate color="primary" size="64" width="10" />
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { ceil, capitalize } from 'lodash';
import entities from '@enums/entities';
import searchUtils from '../../../utils/search-utils';
import sortUtils from '../../../utils/sort-utils';

export default {
  name: 'ArchivedBundles',
  data() {
    return {
      regexSearch: '',
      currentPage: 1,
      pageSize: null,
      totalVisiblePages: null,
      sortOrder: null,
      entities,
      capitalize,
    };
  },
  computed: {
    ...mapGetters('bundles', ['archivedBundles', 'archivedBundlesTotalCount']),
    ...mapState('bundles', ['loadingArchivedBundles']),
    ...mapState('context', ['clientConfig']),

    totalPageCount() {
      return ceil(this.archivedBundlesTotalCount / this.pageSize);
    },

    offset() {
      return (this.currentPage - 1) * this.pageSize;
    },

    visibleBundles() {
      return searchUtils.getResultsByNameDescription(
        this.searchText,
        this.archivedBundles,
        this.sortOrder
      );
    },

    sortedBundlesList() {
      return this.sortOrder
        ? sortUtils.sortItemList(this.archivedBundles, this.sortOrder)
        : this.bundles;
    },
  },
  watch: {
    async currentPage(page) {
      this.setCurrentPage(page);
      await this.fetchArchivedBundlesPage({ limit: this.pageSize, page });
    },
  },
  async created() {
    const { pageSize, totalVisiblePages } = this.clientConfig.archivedWorpackages;
    this.pageSize = pageSize;
    this.totalVisiblePages = totalVisiblePages;
    this.setCurrentPage(this.currentPage);
    await this.fetchArchivedBundlesPage({ limit: this.pageSize, page: this.currentPage });
  },

  methods: {
    ...mapActions('bundles', ['fetchArchivedBundles']),
    ...mapMutations('bundles', ['setArchivedBundlesCurrentPage']),

    // fetches archived bundles page (records for single pagination page)
    async fetchArchivedBundlesPage(params) {
      const { limit, filter } = params;
      await this.fetchArchivedBundles({
        limit,
        offset: this.offset,
        filter,
        regexSearch: this.searchPhrase,
      });
    },

    async onSearch(searchText) {
      this.searchPhrase = searchText;
      await this.fetchArchivedBundles({
        limit: this.pageSize,
        offset: this.offset,
        regexSearch: this.searchPhrase,
      });

      const numberOfPages = Math.floor(this.archivedBundlesTotalCount / this.pageSize);
      // navigate to the first page if number of search results if less than expected for the current selected page
      if (numberOfPages <= this.currentPage && this.currentPage !== 1) {
        this.currentPage = 1;
      }
    },

    setCurrentPage(page) {
      this.setArchivedBundlesCurrentPage(page);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.col-box {
  min-height: 200px !important;
}

.workpackages-container {
  overflow-x: auto;
}

::v-deep {
  .v-application .headline {
    font-family: $assortment-regular-font;
  }
}

::v-deep {
  .progress-bar {
    padding-bottom: 10em;
  }
}
</style>
