<template>
  <span class="required-text">
    <strong>{{ $t('scenarioManagementPanel.requires') }} {{ requires }}</strong>
  </span>
</template>

<script>
export default {
  props: {
    requires: {
      required: true,
      type: String,
    },
  },
};
</script>
