<template>
  <v-card flat class="step-tab-panel">
    <v-row class="region__header ma-0 flex-grow-0">
      <v-col class="d-flex align-center">
        <span class="info-note">{{ $t('region.useRegions') }}</span>

        <docs-link link="toolguide/140-regionalbrands.html" />
      </v-col>
    </v-row>

    <v-container fluid class="pa-0 flex-grow-1 d-flex flex-column">
      <v-row class="flex-grow-0">
        <v-col class="actions-col d-flex justify-end align-center">
          <rtls-select
            v-model="selectedAttribute"
            :items="attributes"
            :placeholder="$tkey('attributeSelectionPlaceholder')"
            class="mr-2"
            width="240px"
            item-text="name"
            item-value="name"
          />
          <v-btn
            id="btnGenerateRegions"
            primary
            data-id-e2e="btnGenerateRegions"
            class="m-2"
            :disabled="isGenerateRegionsDisabled"
            @click="generateRegions(false)"
          >
            {{ $t('region.generateRegions') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-divider />

      <v-row
        class="flex-grow-1"
        :class="{ 'align-content-center': isGenerateRegionsByAttributeJobRunning }"
      >
        <progress-bar
          v-if="isGenerateRegionsByAttributeJobRunning"
          class="mb-5"
          :message="$t('messages.inProgress')"
          :percentage="jobStatuses.regionsByAttributeGenerator.progress"
          :status="jobStatuses.regionsByAttributeGenerator.status"
        />

        <ag-grid-vue
          v-else
          style="width: 100%; height: 100%;"
          class="ag-theme-custom"
          :column-defs="columnDefs"
          :row-data="regionData"
          :grid-options="gridOptions"
          stop-editing-when-cells-loses-focus
          @cell-value-changed="trackDiff"
          @grid-ready="onGridReady"
        />
      </v-row>
    </v-container>

    <div class="page-actions-container">
      <page-actions
        :save-disabled="isEditingDisabled || loading"
        :is-discard-enabled="!isEditingDisabled && !loading"
        :has-data-errors="false"
        :has-data-changes="hasDataChanges"
        @discard="discard"
        @save="onSave(false)"
      >
        <template v-slot:right-btns>
          <v-btn
            class="text-outline"
            secondary
            depressed
            :disabled="hasSkippedRegion || isEditingDisabled || loading"
            @click="skipRegions(false)"
          >
            {{ $t('actions.skipRegion') }}
          </v-btn>
        </template>
      </page-actions>

      <dependency-tree-feedback-modal
        :value="dependencyTreeModalOpen"
        :results="dependencyTreeFeedback"
        page="region"
        @close="closeDependencyTreeModal"
        @commit="commitHandler"
      />
    </div>
  </v-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import {
  get,
  isEmpty,
  cloneDeep,
  keyBy,
  isEqual,
  values,
  capitalize,
  isUndefined,
  min,
  max,
  map,
  floor,
  size,
  reduce,
} from 'lodash';
import agGridUtils from '@/js/utils/ag-grid-utils';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import colours from '@/js/ow-colors';
import regionClassifications from '@enums/region-classifications';
import selectBoxRenderer from '../../../components/ag-grid-cell-renderers/select-box-renderer.vue';

export default {
  name: 'Region',
  localizationKey: 'region',

  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    selectBoxRenderer,
  },

  mixins: [unsavedDataWarningMixin],

  props: {
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      gridApi: null,
      columnApi: null,
      gridOptions: {
        headerHeight: 40,
        rowHeight: 40,
        suppressContextMenu: true,
        enableFillHandle: false,
        defaultColDef: {
          filter: true,
          sortable: true,
          comparator: agGridUtils.sortings.naturalSort,
          editable: false,
          menuTabs: ['filterMenuTab'],
          minWidth: 100,
          flex: 1,
          suppressMovable: true,
        },
        // hides blue border
        suppressCellFocus: true,
        suppressRowClickSelection: true,
      },
      selectedAttribute: null,
      regionData: null,
      currentStateDiff: {},
      dependencyTreeFeedback: {},
      dependencyTreeModalOpen: false,
      calledFromGenerateRegionsClick: false,
      calledFromSkipRegionsClick: false,
    };
  },

  computed: {
    ...mapState('productRegions', ['regions', 'loading']),
    ...mapState('context', ['clientConfig']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapGetters('context', ['hasPermission']),
    ...mapGetters('scenarios', ['jobStatuses', 'isJobRunning']),

    hasSkippedRegion() {
      return !!get(this.selectedScenario, 'regionSettings.hasSkippedRegionsByAttribute');
    },

    hasDataChanges() {
      return !isEmpty(this.currentStateDiff);
    },

    isGenerateRegionsByAttributeJobRunning() {
      return this.isJobRunning('regionsByAttributeGenerator');
    },

    isEditingDisabled() {
      return (
        this.isGenerateRegionsByAttributeJobRunning ||
        !this.hasPermission(this.userPermissions.canEditRegionPage)
      );
    },

    isGenerateRegionsDisabled() {
      return this.isEditingDisabled || this.loading || !this.selectedAttribute;
    },

    columnDefs() {
      return [
        {
          field: 'attributeValue',
          colId: 'attributeValue',
          headerName: get(
            this.selectedScenario,
            'regionSettings.attribute',
            this.$tkey('columns.attribute')
          ),
        },
        {
          field: 'productCount',
          colId: 'productCount',
          headerName: this.$tkey('columns.products'),
          maxWidth: 250,
        },
        {
          field: 'storeCount',
          colId: 'storeCount',
          headerName: this.$tkey('columns.stores'),
          cellStyle: params => {
            const colourIndex = this.getColourIndex(params.value);
            return {
              color: colours.regionStoreCountColours.colour[colourIndex],
              backgroundColor: colours.regionStoreCountColours.background[colourIndex],
            };
          },
          maxWidth: 250,
        },
        {
          field: 'classification',
          colId: 'classification',
          headerName: this.$tkey('columns.classification'),
          filterParams: {
            valueFormatter: params => capitalize(params.value),
          },
          cellRenderer: 'selectBoxRenderer',
          cellRendererParams: params => ({
            items: reduce(
              regionClassifications,
              (acc, c) => {
                acc.push({ text: capitalize(c), value: c });
                return acc;
              },
              []
            ),
            isDisabled: this.isEditingDisabled,
            isHighlighted: this.hasDiff(params),
            maxWidth: '400px',
          }),
          maxWidth: 400,
        },
      ];
    },

    attributes() {
      return this.selectedScenario.customAttributes;
    },

    storeCountRanges() {
      const storeCounts = map(this.regions, 'storeCount');
      return { min: min(storeCounts) || 0, max: max(storeCounts) || 0 };
    },
  },

  watch: {
    regions: {
      deep: true,
      handler(newValue, oldValue) {
        if (!this.gridApi || isEqual(newValue, oldValue)) return;
        this.resetRegions();
      },
    },
  },

  async created() {
    this.selectedAttribute = get(this.selectedScenario, 'regionSettings.attribute', null);
    await this.fetchRegions();
    this.resetRegions();
  },

  methods: {
    ...mapMutations('productRegions', ['setLoading']),
    ...mapActions('dependencyTree', ['triggerDependencyTree']),
    ...mapActions('productRegions', [
      'fetchRegions',
      'updateRegions',
      'generateRegionsByAttributeJobComplete',
    ]),
    ...mapActions('scenarios', [
      'updateScenario',
      'refreshScenario',
      'setScenarioHasSkippedRegionsByAttribute',
      'runGenerateRegionsByAttribute',
    ]),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    async generateRegions(commit = false) {
      this.calledFromGenerateRegionsClick = true; // for dependency tree modal

      // Perform dependency tree check
      const results = await this.triggerDependencyTree({
        params: {
          change: 'regionsModified',
          updates: {
            global: { selectedAttribute: this.selectedAttribute },
          },
          commit,
        },
      });
      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return;
      }

      // Save the region settings before triggering the job
      const regionSettingsUpdates = {
        id: this.selectedScenario._id,
        'regionSettings.attribute': this.selectedAttribute,
        'regionSettings.regionRules': this.clientConfig.regionRules,
      };
      await this.updateRegionSettings(regionSettingsUpdates);

      await this.runGenerateRegionsByAttribute();
    },

    resetRegions() {
      this.gridOptions.api.hideOverlay();
      this.currentStateDiff = {};
      // Reset before setting table data to ensure that saved state is available
      this.$options.savedState = cloneDeep(keyBy(this.regions, '_id'));
      this.regionData = cloneDeep(this.regions);
      this.gridOptions.api.hideOverlay();
    },

    getColourIndex(count) {
      const totalColours = size(colours.regionStoreCountColours.background);
      if (count <= this.storeCountRanges.min) return 0;
      if (count >= this.storeCountRanges.max) return totalColours - 1;
      const percentage =
        (count - this.storeCountRanges.min) /
        (this.storeCountRanges.max - this.storeCountRanges.min);
      const colourIndex = floor(percentage * totalColours);
      // Ensure index is always less than the total colours
      return colourIndex < totalColours ? colourIndex : totalColours - 1;
    },

    trackDiff(params) {
      const id = params.data._id;
      const { field } = params.colDef;
      this.updateDiff(id, field, params.value);
    },

    updateDiff(id, field, currentValue) {
      const path = `${id}.${field}`;
      const originalValue = get(this.$options.savedState, path);
      const existingValue = get(this.currentStateDiff, path);
      const valueHasChanged = currentValue !== originalValue;

      if (valueHasChanged) {
        if (!this.currentStateDiff[id]) this.$set(this.currentStateDiff, id, {});
        this.$set(this.currentStateDiff[id], field, currentValue);
      } else if (!isUndefined(existingValue)) {
        // Remove the field from the object if it's back to its old value.
        this.$delete(this.currentStateDiff[id], field);
        if (isEmpty(this.currentStateDiff[id])) {
          // If there's nothing left in the object, remove it.
          this.$delete(this.currentStateDiff, id);
        }
      }
    },

    hasDiff(params) {
      const { _id } = params.data;
      const { field } = params.colDef;
      const path = `${_id}.${field}`;

      const currentValue = get(params.data, field);
      const originalValue = get(this.$options.savedState, path);
      return agGridUtils.comparators.didValueChange(currentValue, originalValue);
    },

    formatRegionData() {
      return map(this.currentStateDiff, (update, key) => ({ _id: key, ...update }));
    },

    async onSave(commit = false) {
      // Perform dependency tree check
      const results = await this.triggerDependencyTree({
        params: {
          change: 'regionsModified',
          updates: { rowLevel: this.formatRegionData() },
          commit,
        },
      });

      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return;
      }

      await this.setScenarioHasSkippedRegionsByAttribute({ hasSkippedRegionsByAttribute: false });
      this.saveChanges();
    },

    async saveChanges() {
      this.gridOptions.api.showLoadingOverlay();

      const regionUpdates = this.formatRegionData();
      await this.updateRegions(regionUpdates);

      this.gridOptions.api.hideOverlay();
    },

    async skipRegions(commit = false) {
      this.calledFromSkipRegionsClick = true;

      // Perform dependency tree check
      const results = await this.triggerDependencyTree({
        params: {
          change: 'regionsModified',
          updates: {
            // Unset selected attribute to trigger deletion of region data
            global: { selectedAttribute: null },
          },
          commit,
        },
      });
      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return;
      }
      this.selectedAttribute = null;
      // Reset region settings in scenario
      const regionSettingsUpdates = {
        id: this.selectedScenario._id,
        'regionSettings.hasSkippedRegionsByAttribute': true,
        'regionSettings.attribute': this.selectedAttribute,
        'regionSettings.regionRules': [],
      };
      await this.updateRegionSettings(regionSettingsUpdates);
      // Reset region table
      await this.fetchRegions();
      this.resetRegions();
    },

    async updateRegionSettings(updates) {
      this.setLoading(true);
      await this.updateScenario({ scenario: updates });
      await this.refreshScenario();
    },

    discard() {
      this.currentStateDiff = {};
      this.regionData = cloneDeep(values(this.$options.savedState));
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
    },

    async commitHandler() {
      if (this.calledFromSkipRegionsClick) await this.skipRegions(true);
      else if (this.calledFromGenerateRegionsClick) await this.generateRegions(true);
      else this.onSave(true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.region {
  &__header {
    border-bottom: 1px solid $assortment-panel-border-divider-colour;
  }
}

.page-actions-container {
  border-top: 1px solid $assortment-panel-border-divider-colour;
}

::v-deep .ag-theme-custom .ag-cell-range-right.ag-cell-inline-editing {
  border: none !important;
}
</style>
