var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasTag
    ? _c("div", { staticClass: "d-flex product-tag" }, [
        _c("span", [_vm._v("\n    " + _vm._s(_vm.tagName) + "\n  ")]),
      ])
    : _c("div", { staticClass: "w-100" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }