import { VAvatar } from 'vuetify/lib/components/VAvatar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VAvatar, { attrs: { size: "23", color: _vm.color } }, [
    _c("span", { staticClass: "white--text headline" }, [
      _vm._v(_vm._s(_vm.user.name)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }