import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "select-renderer" },
    [
      _c(VAutocomplete, {
        staticClass: "autocomplete-search-input ma-0 pa-0",
        attrs: {
          "hide-no-data": "",
          "hide-details": "",
          items: _vm.items,
          "item-text": "text",
          "item-value": "value",
          disabled: _vm.isDisabled,
          placeholder: _vm.$t("general.select"),
          dense: "",
          solo: "",
          flat: "",
          clearable: "",
          "search-input": _vm.searchInput,
        },
        on: {
          "update:searchInput": function ($event) {
            _vm.searchInput = $event
          },
          "update:search-input": function ($event) {
            _vm.searchInput = $event
          },
          input: function ($event) {
            _vm.searchInput = null
          },
          change: _vm.params.onChange,
          keydown: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              $event.preventDefault()
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                  "Backspace",
                  "Delete",
                  "Del",
                ])
              )
                return null
              $event.stopPropagation()
            },
          ],
        },
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }