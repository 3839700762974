import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "progress-bar" },
    [
      _vm.showProgress
        ? _c(
            "p",
            [
              _c(
                VProgressCircular,
                {
                  attrs: {
                    size: 80,
                    width: 9,
                    rotate: -90,
                    color: "primary",
                    value: _vm.progressValue,
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.progressText) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("p", [_vm._v(_vm._s(_vm.message))]),
      _vm._v(" "),
      _c(VProgressLinear, { attrs: { indeterminate: "" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }