import { ASSORTMENT_FULL_RESET, ASSORTMENT_SIMPLE_SWAPS } from '@enums/workpackage-type';
import tabUtils from './tabs-utils';

const tabs = [
  // Scope
  {
    routeName: 'dates-assortment-groups',
    step: 'scope',
    wpTypes: [ASSORTMENT_FULL_RESET, ASSORTMENT_SIMPLE_SWAPS],
    position: 0,
    translationKey: 'templateScopePage.tab.datesAssortmentGroups',
    statusSection: 'scope',
    statusSubSection: 'datesAssortmentGroups',
  },
  {
    routeName: 'products',
    step: 'scope',
    wpTypes: [ASSORTMENT_FULL_RESET, ASSORTMENT_SIMPLE_SWAPS],
    position: 1,
    translationKey: 'templateScopePage.tab.products',
    statusSection: 'scope',
    statusSubSection: 'products',
  },
  {
    routeName: 'stores',
    step: 'scope',
    wpTypes: [ASSORTMENT_FULL_RESET, ASSORTMENT_SIMPLE_SWAPS],
    position: 2,
    translationKey: 'templateScopePage.tab.stores',
    statusSection: 'scope',
    statusSubSection: 'stores',
  },

  // Inputs
  {
    routeName: 'template-attributes',
    step: 'template-inputs',
    wpTypes: [ASSORTMENT_FULL_RESET, ASSORTMENT_SIMPLE_SWAPS],
    position: 0,
    translationKey: 'scenarioInputPage.tab.attr',
    statusSection: 'inputs',
    statusSubSection: 'attributeEditor',
  },
  {
    routeName: 'template-cann-group',
    step: 'template-inputs',
    wpTypes: [ASSORTMENT_FULL_RESET, ASSORTMENT_SIMPLE_SWAPS],
    position: 1,
    translationKey: 'scenarioInputPage.tab.cann-group',
    statusSection: 'inputs',
    statusSubSection: 'cannGroups',
  },
];

export default {
  ...tabUtils.generateTabRouteMap(tabs),
  ALL: tabs,
};
