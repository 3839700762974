import permissions from '@enums/user-permissions';

import compoundExtractsPage from './compound-extracts.vue';
import KasuExtractsPage from './kasu-extract/kasu-extract.vue';
import HasuExtractsPage from './hasu-extract/hasu-extract.vue';
import AocExtractsPage from './aoc-extract/aoc-extract.vue';

const routes = [
  {
    path: '/extracts',
    name: 'compound-extracts',
    id: 'compound-extracts',
    component: compoundExtractsPage,
    linkText: 'navBar.extracts',
    meta: {
      featureKey: 'compoundExtractsEnabled',
      titleExtension: 'navBar.extracts',
      requiresPermission: permissions.canViewCompoundExtracts,
    },
    omitChildrenFromNavbar: true,
    children: [
      {
        path: 'kasu-extract/:extractSeriesId',
        name: 'kasu-extract',
        component: KasuExtractsPage,
        meta: {
          featureKey: 'compoundExtractsEnabled',
          requiresPermission: permissions.canViewKasuExtracts,
          titleExtension: 'compoundExtractsPage.kasu',
        },
      },
      {
        path: 'hasu-extract/:extractSeriesId',
        name: 'hasu-extract',
        component: HasuExtractsPage,
        meta: {
          featureKey: 'compoundExtractsEnabled',
          requiresPermission: permissions.canViewHasuExtracts,
          titleExtension: 'compoundExtractsPage.hasu',
        },
      },
      {
        path: 'aoc-extract/:extractSeriesId',
        name: 'aoc-extract',
        component: AocExtractsPage,
        meta: {
          featureKey: 'compoundExtractsEnabled',
          requiresPermission: permissions.canViewAocExtracts,
          titleExtension: 'compoundExtractsPage.aoc',
        },
      },
    ],
  },
];

export default routes;
