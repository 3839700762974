import { VCard } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VCard, { staticClass: "wp-scope-products col-box pa-2" }, [
    _c("div", { staticClass: "wp-scope-products__container" }, [
      _c("div", { staticClass: "mb-1" }, [
        _c(
          "h3",
          { staticClass: "wp-scope-products__input-heading mt-3" },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("workpackagePage.scope.products")) +
                "\n\n        "
            ),
            _vm._v(" "),
            _c("assortment-tooltip", {
              attrs: {
                title: _vm.$t("tooltips.productScope.title"),
                "tooltip-sections": _vm.productScopeTooltipSections,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-end" }, [
        _c("table", { staticClass: "item-details-table mb-2" }, [
          _c("tr", { staticClass: "item-details-table__row" }, [
            _c("td", { staticClass: "item-details-table__label" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("workpackagePage.scope.productsInScope")) +
                  "\n          "
              ),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "item-details-table__count" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.workpackage.productCount
                      ? _vm.workpackage.productCount.total
                      : 0
                  ) +
                  "\n          "
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.templatesEnabled && _vm.workpackage.templateId
            ? _c("tr", { staticClass: "item-details-table__row" }, [
                _c("td", { staticClass: "item-details-table__label" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("workpackagePage.scope.fromTemplate")) +
                      "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "item-details-table__count" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.workpackage.productCount
                          ? _vm.workpackage.productCount.includedFromTemplate
                          : 0
                      ) +
                      "\n          "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasProductsInResetEnabled
            ? _c("tr", { staticClass: "item-details-table__row" }, [
                _c("td", { staticClass: "item-details-table__label" }, [
                  _vm._v(_vm._s(_vm.$t("workpackagePage.scope.inReset"))),
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "item-details-table__count" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.workpackage.productCount
                          ? _vm.workpackage.productCount.inReset
                          : 0
                      ) +
                      "\n          "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", { staticClass: "item-details-table__row" }, [
            _c("td", { staticClass: "item-details-table__label" }, [
              _vm._v(_vm._s(_vm.$t("workpackagePage.scope.active"))),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "item-details-table__count" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.workpackage.productCount
                      ? _vm.workpackage.productCount.active
                      : 0
                  ) +
                  "\n          "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", { staticClass: "item-details-table__row" }, [
            _c("td", { staticClass: "item-details-table__label" }, [
              _vm._v(_vm._s(_vm.$t("workpackagePage.scope.inactive"))),
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "item-details-table__count" }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    _vm.workpackage.productCount
                      ? _vm.workpackage.productCount.inactive
                      : 0
                  ) +
                  "\n          "
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-right" }, [_c("workpackage-products")], 1),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }