import { camelCase } from 'lodash';
import sizeTypes from '@enums/size-types';
import i18n from '@/js/vue-i18n';
import formatUtils from '@/js/utils/number-format-utils';

function getSpacebreakShortName({ spacebreak, fillInSelection }) {
  const number = formatUtils.formatNumber({ number: spacebreak.size, format: 'integer' });
  const uom =
    fillInSelection === sizeTypes.productCount
      ? `(${i18n.t('assortmentCanvasPage.spacebreakEntry.products')})` // Need to use 'products' instead of the '#' symbol for it to make more sense in ui
      : `(${i18n.t(`suffixes.${camelCase(fillInSelection)}`)})`;
  return `${number} ${uom}`;
}

export default {
  getSpacebreakShortName,
};
