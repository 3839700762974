<template>
  <div class="d-flex">
    <error-triangle v-if="hasErrors" class="end-date-error-triangle" :errors="params.errors" />
    <v-checkbox v-else readonly :input-value="isSelected" />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { some } from 'lodash';

export default Vue.extend({
  name: 'cellCheckboxRenderer',
  localizationKey: 'assortmentCanvasPage.spacebreakSettings.constraints',

  computed: {
    ...mapGetters('assortmentCanvas', ['isConstraintSelectedToCopy']),

    isSelected() {
      const { appliedTo, constraintId } = this.params.data;
      return (
        appliedTo &&
        this.isConstraintSelectedToCopy({
          spacebreakId: appliedTo._id,
          constraintId,
        })
      );
    },

    hasErrors() {
      return some(this.params.errors, isValid => !isValid);
    },
  },
});
</script>

<style lang="scss" scoped>
.v-btn {
  padding-bottom: 0 !important;
}
::v-deep {
  .v-icon {
    font-size: 2.2rem;
  }
}
</style>
