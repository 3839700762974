<template>
  <v-menu
    v-model="isOpened"
    :disabled="!userNotifications.length"
    offset-y
    :min-width="300"
    :max-width="300"
    :max-height="360"
    :min-height="360"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        id="navbar-user-notifications"
        class="text-none subheading nav-link"
        :disabled="!userNotifications.length"
        text
        large
        color="primary"
        v-on="on"
      >
        <div class="icons-container">
          <v-avatar v-if="numUnreadNotifications" class="pa-2 spacebreak-count-badge" size="20">
            <span class="white--text headline">{{ numUnreadNotifications }}</span>
          </v-avatar>
          <v-icon size="28">notifications_none</v-icon>
        </div>
      </v-btn>
    </template>
    <v-list>
      <v-subheader class="sub-header">{{ $t('general.notifications') }}</v-subheader>
      <template v-for="notification in userNotifications">
        <v-list-item
          :key="notification._id"
          :class="getNotificationClass(notification)"
          @click="goToNotification(notification)"
        >
          <v-list-item-content>
            <v-list-item-title class="notification__title">
              <span>
                <v-avatar v-if="!notification.isRead" size="9" class="notification-unread-badge" />
                {{ $t(`jobNames.${notification.eventType}`) }}
              </span>
              <span>
                {{ notification.date | formatDate(getDateFormats.longWithTimeAndMeridiem) }}
              </span>
            </v-list-item-title>
            <v-list-item-subtitle class="notification__content">
              {{ getDescription(notification) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="notification__content">
              <b>
                {{
                  notification.eventType === workpackageJobTypes.templateSetup
                    ? $t('entities.template')
                    : $t('entities.workpackage')
                }}:
              </b>
              <span>{{ notification.details.entityNames.workpackageName }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="notification.details.entityIds.scenarioId"
              class="notification__content"
            >
              <b>{{ $t('entities.scenario') }}: </b>
              <span>{{ notification.details.entityNames.scenarioName }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="notification.details.entityIds.canvasId"
              class="notification__content"
            >
              <b>{{ $t('entities.canvas') }}: </b>
              <span>{{ notification.details.entityNames.canvasName }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="notification.details.entityIds.checkpointId"
              class="notification__content"
            >
              <b>{{ $t('entities.checkpoint') }}: </b>
              <span>{{ notification.details.entityNames.checkpointName }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="`divider-${notification._id}`" />
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { pick, get } from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import { jobStatuses, workpackageJobTypes } from '@enums/jobapi';
import asyncFunctions from '@enums/asynchronous-functions';

const functionsConfig = asyncFunctions.functions;

export default {
  props: {},

  data() {
    return {
      isOpened: false,
      workpackageJobTypes,
    };
  },

  computed: {
    ...mapState('userNotifications', ['userNotifications']),
    ...mapState('workpackages', ['workpackages']),
    ...mapGetters('context', ['getDateFormats']),

    unreadNotifications() {
      return this.userNotifications.filter(({ isRead }) => !isRead);
    },

    numUnreadNotifications() {
      // Don't put this greater than 99 without checking size of icon in frontend.
      const limitOfNewNotificationsForIcon = 99;
      return limitOfNewNotificationsForIcon > this.unreadNotifications.length
        ? this.unreadNotifications.length
        : `${limitOfNewNotificationsForIcon}+`;
    },
  },

  watch: {
    // There is no @change event for v-menu, so needed to user watcher to see when the menu is closed
    async isOpened() {
      if (this.isOpened) return;
      const updates = this.userNotifications
        .filter(({ isRead }) => !isRead)
        .map(notification => ({
          filter: { _id: notification._id, isRead: false },
          update: { isRead: true },
        }));
      if (updates.length) await this.updateUserNotifications({ updates });
    },
  },

  created() {
    this.fetchUserNotifications();
  },

  methods: {
    ...mapActions('userNotifications', ['fetchUserNotifications', 'updateUserNotifications']),

    goToNotification(notification) {
      const routeDetails = functionsConfig[notification.eventType].notifications.route;
      const params = pick(notification.details.entityIds, routeDetails.paramKeys);
      params.fromNotifications = true;
      this.$router.push({ name: routeDetails.name, params });
    },

    getNotificationClass(notification) {
      if (notification.status === jobStatuses.failed) return 'notification__failed';
      if (notification.status === jobStatuses.finished) return 'notification__success';
      return 'notification__standard';
    },

    getDescription(notification) {
      const eventConfig = functionsConfig[notification.eventType].notifications;
      if (notification.status === jobStatuses.failed) {
        const ifProblemPersistsMsg = this.$t('jobErrorNotifications.ifProblemPersists');
        const errorDetails = get(notification, 'error', {});

        const tkey = `jobErrorNotifications.${get(errorDetails, 'errorKey')}`;
        let msg = this.$t(tkey, errorDetails.params);
        // Check if we have a translation, if we do use it, otherwise this is an unknown error
        // show the generic faliure message
        if (tkey === msg) {
          msg = this.$t(eventConfig.failed);
        }
        return `${msg} ${ifProblemPersistsMsg}`;
      }
      return this.$t(eventConfig[notification.status]);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.sub-header {
  font-size: 1.5rem;
}

.icons-container {
  position: relative;
}

.spacebreak-count-badge {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  right: -2px;
  top: -6px;
  background-color: $assortment-negative-action-colour;
}

.notification-unread-badge {
  vertical-align: baseline;
  background-color: $assortment-primary-colour;
}

.notification {
  &__title {
    display: flex;
    justify-content: space-between;
    font-size: 1.3rem;
  }

  &__content {
    white-space: initial;
    font-size: 1.1rem;
  }

  &__success {
    border-left: 5px solid $assortment-positive-action-colour;
  }

  &__failed {
    border-left: 5px solid $assortment-negative-action-colour;
  }

  &__standard {
    border-left: 5px solid $assortment-navbar-colour;
  }
}
</style>
