'use strict';

const productTypes = {
  existing: 'existing',
  new: 'new',
  semiNew: 'semiNew',
  rom: 'rom',
};

module.exports = {
  productTypes,
};
