<template>
  <div>
    <!-- pre-optimisation checkpoint creation confirmation dialog -->
    <main-dialog ref="confirm" :title="$tkey('ftpFileUploadTitle')" :message="dialogMessage">
      <template v-slot:actions="{ cancel: close }">
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn data-id-e2e="btnCancelUploadFtp" @click="close">
              {{ $t('actions.cancel') }}
            </v-btn>
            <v-btn
              data-id-e2e="btnConfirmUploadFtp"
              class="ml-2"
              action
              @click="[sendFile(), close()]"
            >
              {{ $t('actions.upload') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </main-dialog>

    <a
      v-if="isVisible && !clientConfig.features.extracts.ftpSpaceTestEnabled"
      :disabled="isDisabled"
      @click.prevent="openPopup()"
    >
      <v-btn
        data-id-e2e="btnUploadExtracts"
        class="upload-extracts__button"
        secondary
        depressed
        :loading="loading"
        :disabled="isDisabled"
      >
        {{ $t('actions.upload') }}
        <span class="material-icons-outlined upload-extracts__icon">file_upload</span>
      </v-btn>
    </a>
    <v-menu
      v-else-if="isVisible && clientConfig.features.extracts.ftpSpaceTestEnabled"
      :disabled="isDisabled"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          data-id-e2e="btnUploadExtracts"
          class="upload-extracts__button"
          secondary
          depressed
          :loading="loading"
          :disabled="isDisabled"
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('actions.upload') }}
          <span class="material-icons-outlined upload-extracts__icon">file_upload</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index" link>
          <v-list-item-title @click.prevent="openFtpPopup(item)">{{
            item.title
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { finished, error } from '@enums/ftp-upload-statuses';
import { planningHub, spaceTest } from '@enums/ftp-upload-types';
import { get, size } from 'lodash';
import { AOC } from '@enums/extract-types';
import moment from 'moment';

export default Vue.extend({
  name: 'uploadButtonRenderer',
  localizationKey: 'compoundExtractsPage.uploadButton',

  data() {
    return {
      loading: false,
      items: [
        {
          title: this.$t('compoundExtractsPage.planningHub'),
          type: planningHub,
          message: this.$tkey('ftpFileUploadConfirmation'),
        },
        {
          title: this.$t('compoundExtractsPage.spaceTest'),
          type: spaceTest,
          message: this.$tkey('ftpSpaceTestFileUploadConfirmation'),
        },
      ],
      dialogMessage: this.$tkey('ftpFileUploadConfirmation'),
      seletedtedFtpType: planningHub,
      moment,
      finished,
      error,
    };
  },

  computed: {
    ...mapGetters('context', ['getDateFormats']),
    ...mapState('context', ['clientConfig']),
    ...mapGetters('compoundExtract', ['getExtractById', 'extractSeriesIdBySettingId']),

    extractId() {
      return get(this.params, 'extractId');
    },

    extract() {
      return this.getExtractById(this.extractId) || {};
    },

    mostRecentOutbound() {
      return get(this.params, 'mostRecentOutbound', null);
    },

    isVisible() {
      return this.extract.extractType === AOC;
    },

    isDisabled() {
      return (
        !this.hasPermission(this.userPermissions.canEditCompoundExtracts) ||
        !this.extract._id ||
        !this.extract.extractSettingsId ||
        !size(this.extract.blobId)
      );
    },
  },

  methods: {
    ...mapActions('files', ['uploadFileToFtp']),
    ...mapActions('snackbar', ['showSuccess', 'showError']),
    ...mapActions('compoundExtract', ['updateExtract']),

    openFtpPopup(item) {
      this.dialogMessage = item.message;
      this.seletedtedFtpType = item.type;
      this.openPopup();
    },

    async openPopup() {
      if (this.isDisabled) return;
      await this.$refs.confirm.open();
    },

    async sendFile() {
      if (this.isDisabled) return;
      this.loading = true;
      let status = finished;
      const { filenameOnDownload } = this.extract;
      let filename = filenameOnDownload;
      try {
        filename = await this.uploadFileToFtp({
          ftpType: this.seletedtedFtpType,
          ...this.extract,
        });
      } catch (err) {
        status = error;
        this.showError(this.$tkey('ftpUploadError'));
        this.loading = false;
      } finally {
        const extractSeriesId = this.extractSeriesIdBySettingId[this.extract.extractSettingsId];
        await this.updateExtract({
          status,
          filename,
          ftpType: this.seletedtedFtpType,
          settings: {
            _id: this.extract.extractSettingsId,
            series: {
              _id: extractSeriesId,
            },
          },
        });
        this.showSuccess(this.$tkey('ftpUploadSuccess'));
        this.loading = false;
        await this.params.refresh();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

a {
  text-decoration: none;
}

.icon {
  margin: 0 8px !important;
}

.finished {
  color: $assortment-positive-action-colour;
}

.error-icon {
  color: $assortment-negative-action-colour;
}

::v-deep {
  .upload-extracts {
    &__button {
      height: 29px !important;
      padding: 0 12px !important;

      > span {
        font-size: 1.4rem;
      }
    }

    &__icon {
      font-size: 1.6rem;
      margin-left: 2px;
    }
  }

  .v-list-item {
    min-height: unset;
    height: 28px;
  }
}
</style>
