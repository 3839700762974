import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCol,
    {
      staticClass: "actions-col align-self-center",
      attrs: { id: "warning-controls" },
    },
    [
      _c("span", { staticClass: "warning-rows-error-box pr-2" }, [
        _vm._v(_vm._s(_vm.invalidRowsMessage)),
      ]),
      _vm._v(" "),
      !_vm.isShowingInvalidRows
        ? _c(
            VBtn,
            {
              attrs: { outlined: "", depressed: "", color: "warning" },
              on: { click: _vm.onClick },
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.$t("actions.showInvalidRows")) + "\n  "
              ),
            ]
          )
        : _c(
            VBtn,
            {
              attrs: { outlined: "", depressed: "", color: "primary" },
              on: { click: _vm.onClick },
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("actions.showAllRows")) + "\n  ")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }