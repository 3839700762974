import { VCard } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [_c("workpackage-products", { attrs: { "from-template": true } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }