import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { attrs: { fluid: "" } },
    [
      _c(
        VLayout,
        {
          staticClass: "reporting-power-bi__wrapper h-100",
          attrs: { column: "" },
        },
        [
          _vm.isRoot
            ? _c(
                "div",
                { staticClass: "d-flex flex-column h-100" },
                [
                  _c(
                    VTabs,
                    {
                      staticClass: "reporting-power-bi__tabs flex-grow-0",
                      attrs: { "hide-slider": "" },
                      model: {
                        value: _vm.selectedTab.position,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedTab, "position", $$v)
                        },
                        expression: "selectedTab.position",
                      },
                    },
                    _vm._l(_vm.tabs, function (item) {
                      return _c(
                        VTab,
                        {
                          key: item.key,
                          staticClass: "reporting-power-bi__tab",
                          attrs: { disabled: _vm.loading },
                          on: {
                            click: function ($event) {
                              return _vm.selectTab(item.key)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t(item.translationKey)) +
                              "\n        "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "flex-grow-1",
                    attrs: { id: "powerbi-container" },
                  }),
                ],
                1
              )
            : _c("router-view"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }