import permissions from '@enums/user-permissions';

import Templates from '@/js/pages/templates/templates.vue';

const routes = [
  {
    path: '/templates',
    id: 'templates',
    name: 'templates',
    component: Templates,
    linkText: 'navBar.templates',
    meta: {
      featureKey: 'templatesEnabled',
      requiresPermission: permissions.canViewTemplates,
      titleExtension: 'templatesPage.title',
    },
    index: 0,
  },
];

export default routes;
