<template>
  <v-dialog
    v-model="isPopupOpen"
    content-class="rtls-dialog rtls-dialog--legend"
    max-width="600"
    max-height="400"
    @click:outside="closePopup"
  >
    <template v-slot:activator="{ on }">
      <v-btn :disabled="disabled" class="mr-2 action-btn" primary depressed v-on="on">
        {{ $tkey('copyDecisions') }}
        <v-icon size="14" right>mdi-content-copy</v-icon>
      </v-btn>
    </template>

    <dialog-card :title="$tkey('copyDecisions')" @close="closePopup">
      <v-card>
        <div
          v-if="loading"
          class="d-flex justify-center align-center fixed-height-loading-container"
        >
          <v-progress-circular indeterminate color="primary" />
        </div>
        <div v-if="!loading" class="my-2 d-flex justify-space-between align-center">
          <copy-decisions-selects
            :scenarios="scenarios"
            :clusters-map="wpScenarioClustersMap"
            :furniture-map="wpScenarioFurnitureMap"
            :canvases-map="wpCheckpointsMap"
            canvas-item-text-key="checkpointMeta.name"
            chip-text-translation-key="copyFrom"
            canvas-translation-key="selectCheckpoint"
            :left="true"
            @selected="copyFromCheckpointId = $event"
            @unselected="copyFromCheckpointId = $event"
          />
          <div v-if="selectionsComplete && !canvasMatchSuccess && !canvasMatchError">
            <v-progress-circular indeterminate color="primary" />
          </div>
          <div v-if="canvasMatchSuccess" class="ma-3">
            <h4 :class="spacebreaksMatch" class="match-heading mb-1">
              {{ $tkey(spacebreaksTranslation) }}
            </h4>
            <h4 :class="productsMatch" class="match-heading mb-1">
              {{ $tkey(productsTranslation) }}
            </h4>
            <h4 :class="activeJob" class="match-heading">{{ $tkey(activeJobTranslation) }}</h4>
          </div>
          <div v-if="canvasMatchError" class="ma-3">
            <h4 class="match-heading not-valid">{{ $tkey('comparisonError') }}</h4>
          </div>
          <copy-decisions-selects
            :scenarios="scenarios"
            :clusters-map="wpScenarioClustersMap"
            :furniture-map="wpScenarioFurnitureMap"
            :canvases-map="wpCanvasesMap"
            :canvas-item-text-key="getAssortmentOptionText"
            chip-text-translation-key="copyTo"
            canvas-translation-key="selectedCanvas"
            :selected-canvas="selectedCanvas"
            :right="true"
            @selected="copyToCanvasId = $event"
            @unselected="copyToCanvasId = $event"
          />
        </div>
        <div class="mt-2 mb-5 d-flex justify-end">
          <v-btn class="mr-2" text @click="closePopup">{{ $t('actions.cancel') }}</v-btn>
          <v-btn
            primary
            :loading="copyDecisionsInProgress"
            :disabled="copyDecisionsDisabled || copyDecisionsInProgress"
            @click="copyDecisions"
            >{{ $tkey('copyDecisions') }}</v-btn
          >
        </div>
      </v-card>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  localizationKey: 'assortmentCanvasPage.controls',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isPopupOpen: false,
      loading: true,
      copyToCanvasId: '',
      copyFromCheckpointId: '',
      canvasMatchSuccess: false,
      canvasMatchError: false,
      copyDecisionsInProgress: false,
      canvasMatch: {
        spacebreaks: false,
        products: false,
        activeJob: false,
      },
    };
  },

  computed: {
    ...mapGetters('scenarios', ['scenarios']),
    ...mapGetters('furniture', ['wpScenarioFurnitureMap']),
    ...mapGetters('clustering', ['wpScenarioClustersMap']),
    ...mapGetters('assortmentCanvas', ['wpCheckpointsMap', 'wpCanvasesMap', 'selectedCanvas']),

    selectionsComplete() {
      return !!this.copyFromCheckpointId && !!this.copyToCanvasId;
    },

    canvasIdSelections() {
      // compounded so we can watch both data properties at once
      return [this.copyFromCheckpointId, this.copyToCanvasId].join();
    },

    copyDecisionsDisabled() {
      return (
        !this.canvasMatch.products || !this.canvasMatch.spacebreaks || this.canvasMatch.activeJob
      );
    },

    spacebreaksTranslation() {
      return this.canvasMatch.spacebreaks ? 'spacebreaksMatch' : 'spacebreaksDontMatch';
    },

    productsTranslation() {
      return this.canvasMatch.products ? 'productsMatch' : 'productsDontMatch';
    },

    activeJobTranslation() {
      return this.canvasMatch.activeJob ? 'activeJob' : 'noActiveJob';
    },

    spacebreaksMatch() {
      return this.canvasMatch.spacebreaks ? 'valid' : 'not-valid';
    },

    productsMatch() {
      return this.canvasMatch.products ? 'valid' : 'not-valid';
    },

    activeJob() {
      return this.canvasMatch.activeJob ? 'not-valid' : 'valid';
    },
  },

  watch: {
    async isPopupOpen() {
      if (!this.isPopupOpen) return;
      // refresh data when pop-up is reopened
      this.resetCanvasMatch();
      await this.refreshData();
      this.loading = false;
    },
    async canvasIdSelections() {
      this.resetCanvasMatch();
      if (!this.selectionsComplete) return;
      try {
        const result = await this.compareCheckpointAndCanvas({
          checkpointId: this.copyFromCheckpointId,
          canvasId: this.copyToCanvasId,
        });
        this.canvasMatchSuccess = true;
        this.canvasMatch = result;
      } catch (e) {
        this.canvasMatchError = true;
      }
    },
  },

  async created() {
    await this.refreshData();
    this.loading = false;
  },

  methods: {
    ...mapActions('furniture', ['fetchScenarioFurnitureByWorkPackage']),
    ...mapActions('clustering', ['fetchScenarioClustersByWorkpackage']),
    ...mapActions('assortmentCanvas', [
      'fetchCanvasesForSelectedWorkpackage',
      'fetchCheckpointsForSelectedWorkpackage',
      'compareCheckpointAndCanvas',
      'copyCheckpointDecisionsToCanvas',
    ]),
    closePopup() {
      this.isPopupOpen = false;
      this.copyDecisionsInProgress = false;
      this.resetSelections();
      this.$emit('close');
      this.loading = true;
    },
    getAssortmentOptionText(option) {
      return (
        option.description ||
        `${option.clusterName || this.$tkey('noCluster')} - ${option.storeClassName || ''}`
      );
    },
    async refreshData() {
      await Promise.all([
        this.fetchCanvasesForSelectedWorkpackage(),
        this.fetchScenarioClustersByWorkpackage({ shouldCommit: true }),
        this.fetchScenarioFurnitureByWorkPackage({ shouldCommit: true }),
        this.fetchCheckpointsForSelectedWorkpackage({ shouldCommit: true }),
      ]);
    },
    resetSelections() {
      this.copyToCanvasId = '';
      this.copyFromCheckpointId = '';
    },
    resetCanvasMatch() {
      this.canvasMatchSuccess = false;
      this.canvasMatchError = false;
      this.canvasMatch = {
        spacebreaks: false,
        products: false,
        activeJob: false,
      };
    },
    async copyDecisions() {
      this.copyDecisionsInProgress = true;
      try {
        await this.copyCheckpointDecisionsToCanvas({
          checkpointId: this.copyFromCheckpointId,
          canvasId: this.copyToCanvasId,
        });
        this.closePopup();
      } catch (e) {
        this.closePopup();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.action-btn {
  &.v-btn {
    width: 100%;
    &.v-btn--disabled .v-icon {
      color: $assortment-disabled-button-icon-colour !important;
    }

    ::v-deep {
      .v-btn__content {
        justify-content: space-between;
      }
    }
  }
}

.match-heading {
  text-align: center;
}

.fixed-height-loading-container {
  height: 205px;
}

.valid {
  color: $assortment-positive-action-colour;
}

.not-valid {
  color: $assortment-negative-action-colour;
}
</style>
