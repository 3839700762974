<template>
  <div>
    <div v-if="rowData" class="striped-table--read-only">
      <!-- Column headers -->
      <div class="striped-table__row striped-table__header">
        <div class="striped-table__col font-weight-bold font-italic">
          {{ $tkey('columns.timePeriod') }}
        </div>
        <div
          v-for="column in tableColumns"
          :key="column.key"
          class="striped-table__col"
          :class="column.headerClass || ''"
        >
          {{ column.headerName }}
        </div>
      </div>

      <!-- Table rows -->
      <template v-for="category in productMetricCategories">
        <!-- Sub header -->
        <div class="striped-table__row">
          <div class="striped-table__col" :class="subHeaderRows[category].classes || ''">
            {{ subHeaderRows[category].headerName }}
          </div>
        </div>

        <!-- Metric rows -->
        <div v-for="row in metricRows[category]" class="striped-table__row">
          <div class="striped-table__col" :class="row.classes || ''">
            <span class="pl-3">{{ row.headerName }}</span>
          </div>

          <div
            v-for="column in tableColumns"
            class="striped-table__col"
            :class="column.classes || ''"
          >
            {{ rowData[`${category}-${column.key}-${row.key}`] }}
          </div>
        </div>
      </template>
    </div>
    <div v-else class="my-3 text-center">
      <p class="ma-0">{{ $t('errors.noDataAvailable') }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { reduce, get, keyBy, each, map, filter, includes, lowerFirst, size } from 'lodash';
import {
  metricTypes,
  metricCategories,
  metricGroups,
  productMetrics,
} from '@enums/product-metrics';

const metrics = productMetrics[metricTypes.marketShare];
const productMetricGroups = metricGroups[metricTypes.marketShare];
const productMetricCategories = metricCategories[metricTypes.marketShare];

const metricOptionsLookup = {
  [metrics.sales]: { format: 'float' },
  [metrics.marketShare]: {
    exclude: [productMetricCategories.RMA],
    format: 'percent',
  },
  [metrics.contribution]: {
    exclude: [productMetricCategories.RMA],
    format: 'percent',
  },
};

export default {
  localizationKey:
    'assortmentCanvasPage.productDashboard.relativeProductPerformance.tabs.marketShare',

  props: {
    productKey: {
      type: Number,
      required: true,
    },
  },

  data() {
    const { product, brand, subcategory, category } = productMetricGroups;
    return {
      tableColumns: [
        {
          headerName: this.$tkey(`columns.${lowerFirst(product)}`),
          key: product,
          headerClass: 'text-center',
          classes: 'text-center',
        },
        {
          headerName: this.$tkey(`columns.${lowerFirst(brand)}`),
          key: brand,
          headerClass: 'text-center',
          classes: 'text-center',
        },
        {
          headerName: this.$tkey(`columns.${lowerFirst(subcategory)}`),
          key: subcategory,
          headerClass: 'text-center',
          classes: 'text-center',
        },
        {
          headerName: this.$tkey(`columns.${lowerFirst(category)}`),
          key: category,
          headerClass: 'text-center',
          classes: 'text-center',
        },
      ],
      rowData: null,
      productMetricCategories,
    };
  },

  computed: {
    ...mapGetters('context', ['getCurrentNumericLocale']),

    subHeaderRows() {
      return reduce(
        this.productMetricCategories,
        (acc, group) => {
          const subHeaderRow = {
            headerName: group,
            key: group,
            classes: 'font-weight-bold',
          };
          if (group !== this.productMetricCategories.RMA) {
            subHeaderRow.headerName += ` ${this.$tkey('headerRowSuffix')}`;
            subHeaderRow.classes += ' divider-top';
          }
          return { ...acc, [group]: subHeaderRow };
        },
        {}
      );
    },

    metricRows() {
      const rows = map(productMetrics[metricTypes.marketShare], m => ({
        headerName: this.$tkey(`metricRows.${m}`, { currency: this.requiredCurrency }),
        key: m,
        classes: 'font-weight-bold',
        exclude: get(metricOptionsLookup, `${m}.exclude`, []),
      }));

      return reduce(
        this.productMetricCategories,
        (acc, group) => {
          return { ...acc, [group]: filter(rows, r => !includes(r.exclude, group)) };
        },
        {}
      );
    },

    requiredCurrency() {
      return this.$t(`currencies.${this.getCurrentNumericLocale}`);
    },
  },

  watch: {
    productKey: {
      handler(newProductKey, oldProductKey) {
        if (!newProductKey || newProductKey === oldProductKey) return;
        this.init(newProductKey);
      },
    },
  },

  async created() {
    await this.init();
  },

  methods: {
    ...mapActions('toolData', ['fetchRelativeProductPerformanceMetrics']),

    async init() {
      const metricsData = await this.fetchRelativeProductPerformanceMetrics({
        productKey: this.productKey,
        metricType: metricTypes.marketShare,
      });
      this.rowData = size(metricsData) ? this.generateRows(metricsData) : null;
    },

    generateRows(data) {
      const rowsByCategory = keyBy(data, 'categoryName');
      return reduce(
        rowsByCategory,
        (rows, category) => {
          each(category.categoryMetrics, cm => {
            each(cm.metrics, (m, k) => {
              const format = get(metricOptionsLookup, `${k}.format`, 'float');
              rows[`${category.categoryName}-${cm.metricGroup}-${k}`] = this.valueFormatter(
                m,
                format
              );
            });
          });
          return rows;
        },
        {}
      );
    },

    valueFormatter(value, format) {
      if (!value) value = 0;
      const isPercent = format === 'percent';
      return this.formatNumber({
        number: value && isPercent ? value * 100 : value,
        format,
      });
    },
  },
};
</script>
