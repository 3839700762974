import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    [
      _c(
        VAppBar,
        {
          staticClass: "assortment-toolbar elevation-6",
          class: { warning: _vm.notUsingLocalDatabase },
          attrs: { app: "", color: `#${_vm.backgroundColor}` },
        },
        [
          _c(
            VToolbarItems,
            [
              _vm.hasBrandLogoEnabled
                ? _c(
                    "div",
                    { staticClass: "brand-logo__container" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "font-weight-bold home-link",
                          attrs: {
                            exact: "",
                            text: "",
                            to: "/",
                            ripple: false,
                          },
                        },
                        [
                          _c(VImg, {
                            staticClass: "brand-logo__img",
                            attrs: {
                              id: "client-logo",
                              contain: "",
                              src: "/organisation-assets/brand-logo.png",
                              alt: _vm.$t("general.logo"),
                            },
                            on: { load: _vm.logoLoaded },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  staticClass: "subheading font-weight-bold home-link",
                  attrs: { exact: "", text: "", to: "/" },
                },
                [_c("home-button")],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(VSpacer),
          _vm._v(" "),
          _vm._l(_vm.routes, function (route, index) {
            return _c(
              VToolbarItems,
              { key: index },
              [
                !route.children || route.omitChildrenFromNavbar
                  ? _c(
                      VBtn,
                      {
                        key: index,
                        staticClass: "text-none subheading nav-link",
                        class: { "active-link": _vm.getActiveRoute(route) },
                        attrs: {
                          slot: "activator",
                          name: route.linkText,
                          exact: "",
                          text: "",
                          to: route.path,
                        },
                        slot: "activator",
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t(route.linkText)) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                route.children && !route.omitChildrenFromNavbar
                  ? _c(
                      VMenu,
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    VBtn,
                                    _vm._g(
                                      {
                                        key: index,
                                        staticClass:
                                          "text-none subheading nav-link",
                                        class: _vm.$route.fullPath.includes(
                                          route.path
                                        )
                                          ? "active-link"
                                          : "",
                                        attrs: {
                                          name: route.linkText,
                                          exact: "",
                                          text: "",
                                        },
                                      },
                                      on
                                    ),
                                    [_vm._v(_vm._s(_vm.$t(route.linkText)))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        route.children
                          ? _c(
                              VList,
                              _vm._l(route.children, function (item, idx) {
                                return _c(
                                  VListItem,
                                  {
                                    key: idx,
                                    attrs: {
                                      name: item.linkText,
                                      to: route.path + "/" + item.path,
                                      dark: "",
                                    },
                                  },
                                  [
                                    _c(VListItemTitle, [
                                      _vm._v(_vm._s(_vm.$t(item.linkText))),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(VSpacer),
          _vm._v(" "),
          _c(
            VToolbarItems,
            [
              _vm.isCogAvailable
                ? _c(
                    VMenu,
                    {
                      attrs: {
                        "open-on-click": "",
                        "close-on-content-click": false,
                        bottom: "",
                        "offset-y": "",
                        "max-width": "170px",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "img",
                                  _vm._g(
                                    {
                                      staticClass: "cog-icon",
                                      attrs: { src: _vm.cog },
                                    },
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2992668541
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c(
                        VList,
                        [
                          _vm.hasLocaleSwitcherEnabled
                            ? _c(VListItem, [_c("locale-switcher")], 1)
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasFormatsSwitcherEnabled
                            ? _c(VListItem, [_c("date-locale-switcher")], 1)
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasFormatsSwitcherEnabled
                            ? _c(
                                VListItem,
                                [_c("numeric-locale-switcher")],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VToolbarItems,
            [
              _c(
                VBtn,
                {
                  staticClass: "text-none subheading nav-link",
                  attrs: { id: "navbar-logout", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onLogout()
                    },
                  },
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("actions.logout")))])]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(VToolbarItems, [_c("user-notifications")], 1),
          _vm._v(" "),
          _vm.docsButtonVisible
            ? _c(
                VToolbarItems,
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "text-none subheading nav-link",
                      attrs: {
                        id: "navbar-docs",
                        text: "",
                        large: "",
                        color: "primary",
                        href: "/api/docs/",
                        target: "_blank",
                      },
                    },
                    [
                      _c(VIcon, { attrs: { size: "28" } }, [
                        _vm._v("mdi-help-circle-outline"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }