import { isFunction, get, map } from 'lodash';
import sortUtils from './sort-utils';

function getMaskedText(item, itemLabel, search) {
  /* This method is needed to mask the items when querying. It will split the text the user sees into three
      parts: start, middle and end. The middle is the part that gets highlighted on the row item.
      For more, check the test file for the final output.
    */
  const rowText = isFunction(itemLabel) ? itemLabel(item) : item[itemLabel];
  const searchInput = (search || '').toString().toLocaleLowerCase();
  const index = rowText.toLocaleLowerCase().indexOf(searchInput);

  if (index < 0) return { start: '', middle: rowText, end: '' };

  const start = rowText.slice(0, index);
  const middle = rowText.slice(index, index + searchInput.length);
  const end = rowText.slice(index + searchInput.length);

  return { start, middle, end };
}

/**
 * Filters a list of items such as workpackages, templates and bundles.
 * The name or description values must match/include the search criteria.
 * @param {String} searchText - Search criteria used to filter items
 * @param {Array<Object>} items - Array of items to be filtered
 * @param {String} sortOrder - Determines how the items are sorted
 * @returns {Array<Object>} - Array of items that match the search criteria
 */
function getResultsByNameDescription(searchText, items, sortOrder) {
  const resultList = searchText
    ? items.filter(item => {
        return (
          get(item, 'name', '').match(new RegExp(searchText, 'i')) ||
          get(item, 'description', '').match(new RegExp(searchText, 'i'))
        );
      })
    : items;

  return map(sortUtils.sortItemList(resultList, sortOrder), '_id');
}

export default {
  getMaskedText,
  getResultsByNameDescription,
};
