<template>
  <div class="d-flex rtls-text-field-container">
    <input :value="value" :label="label" :style="widthStyle" v-on="$listeners" />
    {{ /* use $listeners to pass all event listeners on rtls-text-field through to v-text-field */ }}
    <error-triangle :class="errorTriangleClass" :errors="errors" :small="small" />
  </div>
</template>

<script>
import { every, isEmpty, forEach } from 'lodash';

export default {
  name: 'AssortmentTextField',
  props: {
    // ---  error-triangle props ---
    small: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    // --- text-field props ---
    value: {
      required: true,
      type: [String, Number],
    },
    label: {
      required: false,
      type: String,
      default: () => '',
    },
    // set inline style width of v-text-field. see widthStyle computed.
    width: {
      required: false,
      type: String,
      default: '100%',
    },
    hideDetails: {
      required: false,
      type: Boolean,
      default: true,
    },
    // --- shared props ---
    // array of functions to be evaluated in updateAndValidate method.
    rules: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      errors: {},
    };
  },
  computed: {
    errorTriangleClass() {
      if (this.small) return '';

      return 'ml-1 mr-1';
    },
    error() {
      return !every(this.errors);
    },
    widthStyle() {
      return `width: ${this.width}; max-width: ${this.width}`;
    },
  },
  watch: {
    value(newValue, oldValue) {
      // skip validation if no change for performance
      if (newValue === oldValue) return;

      this.updateAndValidate(newValue);
    },
  },

  mounted() {
    this.updateAndValidate(this.value);
  },

  methods: {
    updateAndValidate(value) {
      const errs = {};
      // need lodash forEach to exit early with return false
      // https://lodash.com/docs/4.17.15#forEach "Iteratee functions may exit iteration early by explicitly returning false"
      forEach(this.rules, rule => {
        const valid = rule(value);
        if (valid !== true) {
          errs[valid] = false;
          return false;
        }
      });

      this.errors = errs;

      if (isEmpty(errs)) return this.$emit('valid');
      return this.$emit('invalid');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.col,
.row {
  max-height: 28px;
}

.rtls-text-field-container {
  width: 100%;
}
</style>
