<template>
  <v-container class="cann-groups-menu pa-0 ma-0 fill-height">
    <div class="cg-container" style="width: 100%">
      <div class="flex-grow-0">
        <v-col class="title-text">{{ $t('switchingBehaviour.cannGroupTitle') }}</v-col>
      </div>
      <v-divider class="flex-grow-0" />
      <v-list v-if="cannGroups" dense>
        <v-list-item-group :value="selectedCannGroup" color="primary">
          <v-list-item
            v-for="cg in cannGroups"
            :key="cg.key"
            :value="cg.key"
            @click="selectCannGroup(cg)"
          >
            <v-list-item-content>
              <v-list-item-title class="cannGroupName">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ cg.name || cg.key }}
                    </span>
                  </template>
                  {{ cg.name || cg.key }}
                </v-tooltip>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon class="align-self-center">
              <div v-if="markCannGroupWithSelectedCdt">
                <v-icon v-if="hasSelectedCDT(cg.key)" class="float-right mt-1" size="20"
                  >$success</v-icon
                >
                <slot v-else name="tickbox" :cg-key="cg.key" />
              </div>
              <slot v-else name="tickbox" :cg-key="cg.key" />
              <v-icon size="30" class="pull-right">navigate_next</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-alert v-else type="info" text class="ma-2 info-message">
        {{ $tkey('switchingBehaviour.noCannGroups') }}
      </v-alert>
    </div>
  </v-container>
</template>

<script>
import { first } from 'lodash';
import { mapState } from 'vuex';
import cannGroupUtils from '@/js/utils/cann-groups';

export default {
  props: {
    preselectCannGroup: {
      // If true will select the first cann group in the list
      default: true,
      type: Boolean,
    },

    markCannGroupWithSelectedCdt: {
      // displays green tick box beside canngroup name if canngroup has selected CDT
      default: false,
      type: Boolean,
    },

    selectedCannGroup: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      cannGroups: null,
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('scenarioCdts', ['scenarioCdts']),
  },

  created() {
    this.init();
  },

  methods: {
    hasSelectedCDT(cgKey) {
      const selectedCdts = this.scenarioCdts.filter(
        s =>
          s.cannGroupId === cgKey &&
          s.scenarioId === this.$route.params.scenarioId &&
          s.selected &&
          s.cdt &&
          s.cdt.length
      );
      return selectedCdts.length === 1;
    },

    selectCannGroup(cg) {
      this.$emit('onCannGroupSelected', cg);
    },

    init() {
      if (!this.selectedScenario.cannGroups) return;
      this.cannGroups = cannGroupUtils.getLeafNodes(this.selectedScenario.cannGroups);
      if (this.preselectCannGroup) {
        this.selectCannGroup(first(this.cannGroups));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

$shadow-colour1: rgba(0, 0, 0, 0.2);
$shadow-colour2: rgba(0, 0, 0, 0.14);
$shadow-colour3: rgba(0, 0, 0, 0.12);

.cann-groups-menu {
  color: $assortment-text-colour;
  .title-text {
    font-size: 12px;
    padding: 15px;
    font-weight: 600;
  }
  border-right: #d3dee7 2px solid !important;
  align-items: flex-start !important;

  .v-list {
    padding: 0;
    height: $tab-panel-height;
    flex-grow: 1;
    overflow: auto;
  }

  .cg-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .v-list-item {
    padding-left: 12px;
    padding-right: 0;
    height: 30px;
    min-height: auto;
    &__title {
      font-size: 12px;
      line-height: 20px;
    }

    &__icon {
      margin: 0 !important;
      display: flex;
      align-items: center;

      ::v-deep {
        svg {
          margin-top: 0px !important;
        }
      }
    }
    &[aria-selected='true'] {
      /* override existing border with this new one */
      border: none;
      z-index: 1;
      border-left: 2px solid $assortment-action-icon-color;
      box-shadow: 0px 3px 1px -2px $shadow-colour1, 0px 2px 2px 0px $shadow-colour2,
        0px 1px 5px 0px $shadow-colour3;
    }
  }
  .v-list-item:nth-child(2n + 1) {
    background: #eff5f6 !important;
  }

  .info-message {
    font-size: 1.2em !important;
  }
}
</style>
