'use strict';

const workpackageProductsSchema = require('./workpackage-products');
const workpackageProductsInResetSchema = require('./workpackage-products-inReset');
const workpackageStoresFRSchema = require('./workpackage-stores-a_full_reset');
const workpackageStoresSSSchema = require('./workpackage-stores-a_simple_swaps');
const scenarioProducts = require('./scenario-products');
const furnitureArchetypes = require('./furniture/furniture-archetype');
const furnitureToStore = require('./furniture/furniture-to-store-mapping');
const storeAllocation = require('./store-executions/store-allocation');
const planogramDefinition = require('./store-executions/planogram-definition');
const clustering = require('./clustering');
const referenceClustering = require('./reference-clustering');
const productsToSwap = require('./simple-swaps/products-to-swap');
const workpackageTypes = require('../../enums/workpackage-type');
const { trueOptions, falseOptions } = require('../../enums/boolean-options');

const schemas = {
  'workpackage-products': workpackageProductsSchema,
  'workpackage-products-inReset': workpackageProductsInResetSchema,
  [`workpackage-stores-${workpackageTypes.ASSORTMENT_SIMPLE_SWAPS}`]: workpackageStoresSSSchema,
  [`workpackage-stores-${workpackageTypes.ASSORTMENT_FULL_RESET}`]: workpackageStoresFRSchema,
  'scenario-products': scenarioProducts,
  'furniture-archetypes': furnitureArchetypes,
  'furniture-to-store': furnitureToStore,
  'store-allocation': storeAllocation,
  'planogram-definition': planogramDefinition,
  'products-to-swap': productsToSwap,
  clustering,
  'reference-clustering': referenceClustering,
};

// We can add in as many options as we like to these sets for boolean csv inputs
// All values are converted to lower case in the comparison function so no need
// to add upper case options to these sets.
const booleanCSVOptions = {
  trueOptions: new Set(trueOptions),
  falseOptions: new Set(falseOptions),
};

module.exports = {
  for: service => {
    return schemas[service];
  },
  booleanCSVOptions,
};
