var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "canvas-grid-container",
      style: _vm.canvasGridContainerStyle,
    },
    [
      _vm.selectedView === _vm.views.Tile
        ? _c("spacebreak-sidebar", {
            attrs: { "points-of-distribution": _vm.pointsOfDistribution },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isDisplayByCDT && !_vm.unifiedColumns[_vm.selectedView]
        ? [
            _vm._l(
              _vm.availableProductAttributesValues(_vm.getDisplayBy),
              function (attribute) {
                return _c("canvas-column", {
                  key: attribute.id,
                  attrs: {
                    name: attribute.name,
                    products: _vm.productsSplittedByAttribute[attribute.id],
                  },
                })
              }
            ),
            _vm._v(" "),
            _vm.hasProductsWithEmptyAttribute
              ? _c("canvas-column", {
                  attrs: {
                    name: _vm.productsWithEmptyAttributeTitle,
                    products: _vm.productsWithEmptyAttribute,
                  },
                })
              : _vm._e(),
          ]
        : !_vm.unifiedColumns[_vm.selectedView]
        ? _vm._l(_vm.orderedScenarioCdts, function (scenarioCdt) {
            return _c("cann-group-column", {
              key: scenarioCdt._id,
              attrs: { "scenario-cdt": scenarioCdt },
            })
          })
        : [
            _c("canvas-column", {
              attrs: {
                name: _vm.$t("assortmentCanvasPage.allCannGroups"),
                products: _vm.sortedUnifiedProducts,
              },
            }),
          ],
      _vm._v(" "),
      _c("product-panel"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }