import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VMenu,
    {
      attrs: {
        "offset-y": "",
        "max-height": "400",
        "close-on-content-click": false,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                VBtn,
                _vm._g(
                  _vm._b(
                    { attrs: { secondary: "", depressed: "" } },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v("\n      " + _vm._s(_vm.buttonTitle) + "\n      "),
                  _c(
                    VIcon,
                    {
                      staticClass: "material-icons-outlined ml-1 columns-icon",
                    },
                    [_vm._v("view_column")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        VList,
        [
          _c(
            "draggable",
            {
              attrs: { disabled: false, handle: ".drag-handle" },
              on: { end: _vm.onDragEnd },
              model: {
                value: _vm.allColumns,
                callback: function ($$v) {
                  _vm.allColumns = $$v
                },
                expression: "allColumns",
              },
            },
            [
              _vm._l(_vm.allColumns, function (column) {
                return [
                  _c(
                    VListItem,
                    {
                      key: _vm.getColumnIdentifier(column),
                      staticClass: "display-by-title",
                    },
                    [
                      _c(
                        VIcon,
                        {
                          staticClass: "drag-handle mr-2",
                          class: { invisible: _vm.isColumnDisabled(column) },
                        },
                        [_vm._v("\n            mdi-drag\n          ")]
                      ),
                      _vm._v(" "),
                      _c(VCheckbox, {
                        staticClass: "rtls-checkbox mx-2",
                        attrs: {
                          value: _vm.getColumnIdentifier(column),
                          label: _vm.getColumnLabel(column),
                          disabled: _vm.isColumnDisabled(column),
                          "hide-details": "",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.toggleColumn(column)
                          },
                        },
                        model: {
                          value: _vm.selectedColumns,
                          callback: function ($$v) {
                            _vm.selectedColumns = $$v
                          },
                          expression: "selectedColumns",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }