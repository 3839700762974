<template>
  <v-card class="step-tab-panel" flat>
    <v-container class="pa-0 ma-0 variety-container d-flex flex-column">
      <v-row class="header-container flex-grow-0 ma-0">
        <v-col>
          <span>{{ $t('varietyGroups.infoNote') }}</span>

          <!-- Variety groups tooltip -->
          <docs-link link="toolguide/070-varietygroups.html" />
        </v-col>
      </v-row>
      <div class="main-panel no-gutters d-flex flex-row flex-grow-1" no-gutters>
        <v-col class="d-flex flex-column" cols="2">
          <cann-group-menu
            :selected-cann-group="selectedCannGroup ? selectedCannGroup.key : null"
            :preselect-cann-group="false"
            @onCannGroupSelected="selectCannGroup"
          >
            <template v-slot:tickbox="props">
              <v-icon v-if="existsInVarietyGroups(props.cgKey)" class="float-right mt-1" size="20">
                $success
              </v-icon>
              <v-icon v-else color="#d3d3d3" class="float-right">mdi-checkbox-blank-circle</v-icon>
            </template>
          </cann-group-menu>
        </v-col>
        <v-col cols="6" class="variety-attributes d-flex flex-column">
          <v-row class="variety-attributes-title flex-grow-0">
            <v-col>{{ $t('varietyGroups.settings') }}</v-col>
          </v-row>
          <div class="variety-attributes-container">
            <variety-attributes ref="varietyAttributes" :selected-cann-group="selectedCannGroup" />
          </div>
        </v-col>
        <v-col cols="4" />
      </div>
      <div data-id-e2e="btnConfirmVarietyGroups" class="page-actions-container">
        <page-actions
          :has-data-changes="true"
          :has-data-errors="hasDataErrors"
          save-btn-text="confirmAsSet"
          :show-discard="false"
          :save-disabled="isEditingDisabled"
          :is-discard-enabled="!isEditingDisabled"
          @save="saveAll"
        />
      </div>
    </v-container>

    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="closeUnsavedDataModal"
      @confirm="closeUnsavedDataModal"
    />
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import { StoreNamespaces } from '../../../store/constants';

const { mapState } = createNamespacedHelpers(StoreNamespaces.scenarios);

export default {
  mixins: [unsavedDataWarningMixin],
  data() {
    return {
      selectedCannGroup: null,
    };
  },

  computed: {
    ...mapState(['selectedScenario']),

    hasDataChanges() {
      // AOV3-405 required for variety-groups unsaved data warning
      return this.$refs.varietyAttributes.hasDataChanges;
    },
    hasDataErrors() {
      // required for <page-actions> component
      return false;
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditVarietyPage);
    },
  },

  methods: {
    selectCannGroup({ key }) {
      this.selectedCannGroup = key;
    },

    existsInVarietyGroups(key) {
      return this.selectedScenario.varietyGroups.some(({ cannGroupId }) => key === cannGroupId);
    },

    saveAll() {
      this.$refs.varietyAttributes.saveAll();
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';
.variety-container {
  color: $assortment-text-colour;
  font-size: 12px;
  font-weight: 600;
  min-width: 100%;
  height: 100%;
  .header-container {
    span {
      font-size: 1.2rem;
    }
  }
  .variety-attributes {
    border-right: $assortment-border-colour 1px solid;
    &-title {
      border-bottom: $assortment-border-colour 1px solid;

      .col {
        padding: 15px;
      }
    }
  }
  .main-panel {
    border-top: $assortment-border-colour 1px solid;
    height: $tab-panel-height;
  }

  .variety-attributes-container {
    flex: 1;
    height: 300px;
    position: relative;
  }

  .page-actions-container {
    border-top: 1px solid $assortment-panel-border-divider-colour;
  }
}
</style>
