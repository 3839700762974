const jda = [
  {
    componentName: 'checkpoint-selector',
    order: 1,
    translationKey: 'extract.reports.storeExecutionPlanning.steps.checkpointSelector',
    statusSection: 'storeExecutions',
    statusSubSection: 'checkpointSelector',
  },
  {
    componentName: 'store-allocation',
    order: 2,
    translationKey: 'extract.reports.storeExecutionPlanning.steps.storeAllocation',
    statusSection: 'storeExecutions',
    statusSubSection: 'storeAllocation',
  },
  {
    componentName: 'planogram-definition',
    order: 3,
    translationKey: 'extract.reports.storeExecutionPlanning.steps.planogramDefinition',
    statusSection: 'storeExecutions',
    statusSubSection: 'planogramDefinition',
  },
  {
    componentName: 'report-jda',
    order: 4,
    translationKey: 'extract.reports.storeExecutionPlanning.steps.storeExecutionOutputs',
    statusSection: 'storeExecutions',
    statusSubSection: 'jda',
  },
];

export default {
  jda,
};
