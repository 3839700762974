import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "compound-extracts-toolbar pa-2" },
    [
      _c(
        "div",
        { staticClass: "compound-extracts-toolbar__toggle" },
        [
          _c(
            "span",
            { staticClass: "compound-extracts-toolbar__toggle-label mr-2" },
            [_vm._v(_vm._s(_vm.$tkey("filterLabel")) + ":")]
          ),
          _vm._v(" "),
          _c("rtls-toggle", {
            attrs: {
              mandatory: "",
              value: _vm.selectedExtractFilter,
              "left-toggle": _vm.toggle.leftToggle,
              "right-toggle": _vm.toggle.rightToggle,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("update-selected-extract-filter", $event)
              },
            },
          }),
          _vm._v(" "),
          _c("docs-link", { attrs: { link: `toolguide/140-extracts.html` } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VMenu,
        {
          attrs: {
            id: "myMenu",
            "min-width": "500",
            "max-width": "500",
            left: "",
            "offset-y": "",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    VBtn,
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "generate-extracts__button",
                          attrs: {
                            id: "btnGenerateExtracts",
                            "data-id-e2e": "btnGenerateExtracts",
                            action: "",
                            depressed: "",
                            disabled:
                              !_vm.availableExtracts.length || _vm.isProcessing,
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("actions.newExtract")) +
                          "\n        "
                      ),
                      _c(
                        VIcon,
                        {
                          staticClass: "generate-extracts__icon",
                          attrs: { right: "" },
                        },
                        [_vm._v("\n          mdi-plus\n        ")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            VList,
            _vm._l(
              _vm.availableExtracts,
              function ({ name, reportType, tkey }, i) {
                return _c(
                  "span",
                  {
                    key: i,
                    staticClass: "extract-link selectable",
                    attrs: {
                      id: `btnFetchCompound${reportType}`,
                      to: { name },
                    },
                    on: { click: () => _vm.onRouteClick(name, reportType) },
                  },
                  [
                    _c(
                      VListItem,
                      [_c(VListItemTitle, [_vm._v(_vm._s(_vm.$t(tkey)))])],
                      1
                    ),
                  ],
                  1
                )
              }
            ),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }