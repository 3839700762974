import { VCard } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VCard, { staticClass: "wp-scope-products col-box" }, [
    _c("div", { staticClass: "wp-scope-products__header mb-1" }, [
      _c(
        "h3",
        { staticClass: "wp-scope-products__input-heading" },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("workpackagePage.scope.products")) +
              "\n\n      "
          ),
          _vm._v(" "),
          _c("assortment-tooltip", {
            attrs: {
              title: _vm.$t("tooltips.productScope.title"),
              "tooltip-sections": _vm.productScopeTooltipSections,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", [_c("workpackage-products")], 1),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "grid-wrapper" },
      [
        _c("div", { staticClass: "item" }, [
          _vm._v(_vm._s(_vm.$t("workpackagePage.scope.productsInScope"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item item__count" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.workpackage.productCount
                  ? _vm.workpackage.productCount.total
                  : 0
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _vm.templatesEnabled && _vm.workpackage.templateId
          ? [
              _c("div", { staticClass: "item" }, [
                _vm._v(_vm._s(_vm.$t("workpackagePage.scope.fromTemplate"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item item__count" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.workpackage.productCount
                        ? _vm.workpackage.productCount.fromTemplate
                        : 0
                    ) +
                    "\n      "
                ),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _vm.hasProductsInResetEnabled
          ? [
              _c("div", { staticClass: "item" }, [
                _vm._v(_vm._s(_vm.$t("workpackagePage.scope.inReset"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "item item__count" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.workpackage.productCount
                        ? _vm.workpackage.productCount.inReset
                        : 0
                    ) +
                    "\n      "
                ),
              ]),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _vm._v(_vm._s(_vm.$t("workpackagePage.scope.active"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item item__count" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.workpackage.productCount
                  ? _vm.workpackage.productCount.active
                  : 0
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _vm._v(_vm._s(_vm.$t("workpackagePage.scope.inactive"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item item__count" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.workpackage.productCount
                  ? _vm.workpackage.productCount.inactive
                  : 0
              ) +
              "\n    "
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }