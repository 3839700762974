var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "switching-tooltip" }, [
    _c("div", { staticClass: "text" }, [
      _c("span", [
        _vm._v(
          "\n      " + _vm._s(_vm.$t("switchingBehaviour.tooltipFrom")) + " "
        ),
        _c("b", [_vm._v(_vm._s(_vm.valueInfo.from))]),
      ]),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          "\n      " + _vm._s(_vm.$t("switchingBehaviour.tooltipTo")) + " "
        ),
        _c("b", [_vm._v(_vm._s(_vm.valueInfo.to))]),
      ]),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("switchingBehaviour.tooltipSwitchAbility")) +
            " "
        ),
        _c("b", [_vm._v(_vm._s(_vm.valueInfo.value))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }