<template>
  <select
    :value="value"
    :disabled="disabled"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  >
    <option v-for="(item, index) in items" :key="index" :value="item">{{ item }}</option>
  </select>
</template>

<script>
import { isPlainObject } from 'lodash';

export default {
  name: 'AssortmentSelect',
  props: {
    value: {
      required: false,
      validator: prop =>
        typeof prop === 'string' ||
        typeof prop === 'number' ||
        prop === null ||
        isPlainObject(prop),
      default: '',
    },
    items: {
      required: true,
      type: Array,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    width: {
      required: false,
      type: String,
      default: '100%',
    },
  },
};
</script>
