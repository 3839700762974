<template>
  <v-container fluid class="ma-0 pa-0 w-100">
    <v-row class="mb-3">
      <!-- Filters and highlights -->
      <v-col cols="4" class="legend-filter-highlight__section ma-0">
        <h3 class="legend-filter-highlight__heading mb-2">{{ $tkey('filtersHighlights') }}</h3>

        <v-row v-if="filters.length" class="legend-filter-highlight__filters">
          <h4 class="legend-filter-highlight__label">{{ $tkey('filters') }}:</h4>

          <div class="flex-grow-1">
            <div v-for="(item, index) in filters" :key="index" class="mr-2 d-inline">
              <span class="filter-name mr-1">{{ item.filterName }}:</span>
              <span>
                {{ item.filterValues }}
                {{ item.notEqualTo ? ` (${$tkey('notEqualTo')})` : '' }}
              </span>
            </div>
          </div>
        </v-row>
        <v-row v-if="highlights.length" class="legend-filter-highlight__filters">
          <h4 class="legend-filter-highlight__label">{{ $tkey('highlights') }}:</h4>

          <div class="flex-grow-1">
            <div v-for="(item, index) in highlights" :key="index" class="mr-2 d-inline">
              <span class="filter-name mr-1">{{ item.filterName }}:</span>
              <span>
                {{ item.filterValues }}
                {{ item.notEqualTo ? ` (${$tkey('notEqualTo')})` : '' }}
              </span>
            </div>
          </div>
        </v-row>
      </v-col>

      <!-- Colors -->
      <v-col cols="4" class="ma-0 legend-filter-highlight__section">
        <h3 class="legend-filter-highlight__heading">{{ $tkey('colors') }}</h3>

        <v-row>
          <v-col cols="4" class="pl-0">
            <div class="d-flex mb-1">
              <i class="color-icon demoted pull-left mt-1" />
              <span class="ml-2">{{ $tkey('demotedByUser') }}</span>
            </div>
            <div class="d-flex">
              <i class="color-icon promoted pull-left mt-1" />
              <span class="ml-2">{{ $tkey('promotedByUser') }}</span>
            </div>
          </v-col>
          <v-col cols="4" class="pl-0">
            <div class="d-flex mb-1">
              <i class="color-icon demoted-by-opt pull-left mt-1" />
              <span class="ml-2">{{ $tkey('demotedByOptimizer') }}</span>
            </div>
            <div class="d-flex">
              <i class="color-icon promoted-by-opt pull-left mt-1" />
              <span class="ml-2">{{ $tkey('promotedByOptimizer') }}</span>
            </div>
          </v-col>
          <v-col cols="4" class="pl-0">
            <div class="d-flex">
              <i class="color-icon highlight pull-left mt-1" />
              <span class="ml-2">{{ $tkey('highlightedBasedOnSettings') }}</span>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <!-- Icons -->
      <v-col cols="4" class="ma-0 legend-filter-highlight__section">
        <h3 class="legend-filter-highlight__heading">{{ $tkey('icons') }}</h3>

        <v-row>
          <v-col cols="6" class="pl-0">
            <div class="d-flex mb-1">
              <v-icon color="primary" small class="pull-left lock-icon mr-2">
                {{ 'mdi-lock-open-variant-outline' }}
              </v-icon>
              <span>{{ $tkey('unlocked') }}</span>
            </div>
            <div class="d-flex">
              <v-icon color="primary" small class="pull-left lock-icon mr-2">
                {{ 'mdi-lock-outline' }}
              </v-icon>
              <span>{{ $tkey('locked') }}</span>
            </div>
          </v-col>
          <v-col cols="6" class="pl-0">
            <v-icon color="primary" small class="pull-left lock-icon mr-2">
              {{ 'mdi-speedometer' }}
            </v-icon>
            <span>{{ $tkey('minimumDistribution') }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import { map } from 'lodash';

export default {
  localizationKey: 'legendFilterHighlights',

  data() {
    return {};
  },

  computed: {
    ...mapState('assortmentCanvas', ['canvasProductsFilters', 'canvasProductsHighlights']),

    filters() {
      return this.getFilterValues(this.canvasProductsFilters);
    },

    highlights() {
      return this.getFilterValues(this.canvasProductsHighlights);
    },
  },

  methods: {
    getFilterValues(filters) {
      return map(filters, filter => {
        return {
          notEqualTo: filter.notEqualTo,
          filterName: filter.filterName,
          filterValues: map(filter.filterValues, 'name').join(', '),
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.legend-filter-highlight {
  &__section {
    border: 1px solid $assortment-tile-border-colour;
    padding: 7px;
  }

  &__heading {
    font-size: 1.2rem;
    line-height: 1;
  }

  &__label {
    min-width: 70px;
  }

  &__filters {
    flex-wrap: nowrap;
  }
}

.filter-name {
  font-weight: bold;
}

.lock-icon {
  color: $assortment-primary-colour;
}

.action-icon {
  font-size: 2rem;
}

.color-icon {
  width: 12px;
  height: 12px;
  border: 2px solid;
  display: inline;
}

.demoted {
  border-color: $canvas-pod-decrease-border;
}

.promoted {
  border-color: $canvas-pod-increase-border;
}

.demoted-by-opt {
  border-color: $canvas-pod-decrease-border;
  background-color: $canvas-pod-decrease-border;
  background-position: center center;
  background-size: contain;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  opacity: 30%;
}

.promoted-by-opt {
  border-color: $canvas-pod-increase-border;
  background-color: $canvas-pod-increase-border;
  background-position: center center;
  background-size: contain;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  opacity: 30%;
}
</style>
