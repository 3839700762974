import { VBtn } from 'vuetify/lib/components/VBtn';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isUnarchiving
        ? _c(
            VTooltip,
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          VBtn,
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "action-button",
                                attrs: {
                                  disabled: _vm.disabled,
                                  icon: "",
                                  text: "",
                                },
                                on: { click: _vm.onUnArchiveWorkpackageClick },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("span", { staticClass: "material-icons" }, [
                              _vm._v("unarchive"),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1409584248
              ),
            },
            [
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(_vm.$t("actions.unArchive")))]),
            ]
          )
        : _c(VProgressCircular, {
            attrs: { indeterminate: "", size: "25", width: "3" },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }