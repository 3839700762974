import { render, staticRenderFns } from "./deviation.vue?vue&type=template&id=aca3daa6&scoped=true"
import script from "./deviation.vue?vue&type=script&lang=js"
export * from "./deviation.vue?vue&type=script&lang=js"
import style0 from "./deviation.vue?vue&type=style&index=0&id=aca3daa6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aca3daa6",
  null
  
)

export default component.exports