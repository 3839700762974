<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :min-width="500"
      :max-width="500"
      :max-height="500"
      :nudge-bottom="8"
      offset-y
      :z-index="menuZindex"
      @input="closeModal"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :ripple="false"
          :secondary="!menu"
          :primary="menu"
          :min-width="170"
          :style="filterButtonStyle"
          depressed
          :height="28"
          v-bind="attrs"
          data-dd-action-name="OpenAssortmentCanvasProductFilterBtn"
          v-on="on"
        >
          {{ productsAndFiltersShown }}
        </v-btn>
      </template>
      <v-card>
        <div class="pa-4 d-flex flex-column">
          <div class="d-flex flex-column">
            <div class="mb-2">
              <rtls-select
                v-model="filterValue"
                class="mt-1"
                grey
                :items="filterItemsSorted"
                item-text="name"
                :placeholder="$t('general.select')"
                hide-details
                return-object
                @change="selectedFilterValues = []"
              />
            </div>
            <div class="mb-1 d-flex align-center justify-start">
              <v-checkbox
                class="rtls-checkbox mx-2"
                :input-value="notEqualTo"
                @change="notEqualTo = $event"
              />
              <h3>{{ $tkey('notEqual') }}</h3>
            </div>
            <div class="mb-4 d-flex align-center">
              <v-autocomplete
                ref="autocompleteSelect"
                v-model="selectedFilterValues"
                :items="selectedFilterItemsSorted"
                chips
                item-text="name"
                :multiple="allowSelectMultiple"
                :search-input.sync="searchInput"
                :allow-overflow="false"
                background-color="white"
                dense
                small-chips
                hide-details
                light
                hide-selected
                return-object
                :menu-props="{ closeOnClick: true }"
                @change="searchInput = ''"
                @click:append="menuArrow"
              >
                <template #selection="{ item }">
                  <v-chip :title="item.name" small grey close @click:close="deleteChip(item)"
                    >{{ item.name }}
                  </v-chip>
                </template>
              </v-autocomplete>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span v-on="on">
                    <v-btn
                      icon
                      text
                      :disabled="isAddFilterDisabled"
                      class="ml-3 add-filter-button"
                      v-on="on"
                      @click="addFilter"
                    >
                      <v-icon color="primary">mdi-plus-box</v-icon>
                    </v-btn>
                  </span>
                </template>
                <span>{{
                  isAddFilterDisabled ? $tkey('selectAValueToAddFilter') : $tkey('addFilter')
                }}</span>
              </v-tooltip>
            </div>
            <div class="mb-5 d-flex flex-column">
              <div v-if="filters.length" class="d-flex flex-column">
                <h2 class="title-filters">{{ labelAppliedFilters }}</h2>
                <div class="d-flex">
                  <v-col class="headers pr-0 pb-1">
                    <h4 class="pb-0">{{ labelFilterName }}</h4>
                  </v-col>
                  <v-col class="headers pr-0 pb-1">
                    <h4 class="pb-0">{{ $tkey('equalNotEqual') }}</h4>
                  </v-col>
                  <v-col class="headers pr-0 pb-1">
                    <h4 class="pb-0">{{ labelFilterValues }}</h4>
                  </v-col>
                  <v-col :cols="1" class="headers pb-1 pr-1" />
                </div>
                <div
                  v-for="(filter, index) in filters"
                  :key="filter.filterType + index"
                  class="d-flex filter-row"
                >
                  <v-col class="filter-item pr-0 pt-1 pb-1 d-flex flex-column justify-center">
                    {{ filter.filterName }}
                  </v-col>
                  <v-col class="filter-item pr-0 pt-1 pb-1 d-flex flex-column justify-center">
                    {{ filter.notEqualTo ? $tkey('notEqual') : $tkey('equal') }}
                  </v-col>
                  <v-col class="filter-item pr-0 pt-1 pb-1 d-flex flex-column justify-center">
                    <div
                      v-for="(value, valueIndex) in filter.filterValues"
                      :key="value.id + valueIndex"
                    >
                      {{ value.name }}
                    </div>
                  </v-col>
                  <v-col
                    :cols="1"
                    class="filter-item pt-1 pb-1 pr-1 d-flex flex-column justify-center align-center"
                  >
                    <v-btn icon text class="ml-1" @click="removeFilter(index)">
                      <v-icon small color="primary">mdi-close-circle</v-icon>
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <div v-else>
                <h3>{{ labelNoFiltersApplied }}</h3>
              </div>
            </div>
          </div>
          <div class="d-flex justify-end align-center close-icon-container">
            <v-btn
              class="mr-0 font-weight-bold btn-text"
              :ripple="false"
              text
              small
              @click="[(menu = false), closeModal(false)]"
              >{{ $t('actions.close') }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { cloneDeep, isArray, map, isPlainObject, size, upperFirst, get, sortBy } from 'lodash';
import { Filters, FilterValues, FilterMappings } from '@enums/assortment-canvases';
import { spacebreakTypes } from '@enums/spacebreak-types';
import { zIndexes } from '@enums/menus';
import customerSegmentationMixin from '@/js/mixins/customer-segmentation';

const localizationKey = 'filters';
const FILTER = 'filter';

export default {
  localizationKey,
  mixins: [customerSegmentationMixin],
  props: {
    mode: {
      type: String,
      required: true,
      default: 'filter', // filter or highlight
    },
  },
  data() {
    return {
      notEqualTo: false,
      searchInput: '',
      menu: false,
      attributesValuesMap: {},
      customAttributeItemsWithType: [],
      filterValue: { name: null, type: null },
      filterItems: [
        {
          name: this.$tkey(Filters.ProductType),
          type: FilterMappings.ProductType,
        },
        { name: this.$tkey(Filters.LockType), type: Filters.LockType },
        {
          name: this.$tkey(Filters.CurrentSpacebreak),
          type: FilterMappings.CurrentSpacebreak,
        },
        {
          name: this.$tkey(Filters.OptimisedSpacebreak),
          type: FilterMappings.OptimisedSpacebreak,
        },
        {
          name: this.$tkey(Filters.OriginalSpacebreak),
          type: FilterMappings.OriginalSpacebreak,
        },
        {
          name: this.$tkey(Filters.PromotedDemoted),
          type: Filters.PromotedDemoted,
        },
        {
          name: this.$tkey(Filters.ProductSeries),
          type: Filters.ProductSeries,
        },
      ],
      selectedFilterValues: [],
      menuZindex: zIndexes.menu,
    };
  },

  computed: {
    ...mapState('assortmentCanvas', [
      'spacebreaks',
      'selectedAssortmentCanvas',
      'canvasProductsFiltersSelection',
    ]),
    ...mapGetters('clustering', ['getScenarioAttributes']),
    ...mapGetters('assortmentCanvas', [
      'getFilteredProducts',
      'getHighlightedProducts',
      'canvasProducts',
      'availableProductSeries',
    ]),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapGetters('toolData', ['customerSegments']),

    filters() {
      return this.canvasProductsFiltersSelection || [];
    },

    selectedFilterItems() {
      switch (this.filterValue.type) {
        case Filters.LockType:
          return [
            { name: this.$tkey(`values.${FilterValues.Locked}`), id: FilterValues.Locked },
            { name: this.$tkey(`values.${FilterValues.Unlocked}`), id: FilterValues.Unlocked },
          ];
        case FilterMappings.ProductType:
          return [
            { name: this.$tkey(`values.${FilterValues.New}`), id: FilterMappings.New },
            { name: this.$tkey(`values.${FilterValues.Existing}`), id: FilterMappings.Existing },
          ];
        case FilterMappings.CurrentSpacebreak:
        case FilterMappings.OptimisedSpacebreak:
        case FilterMappings.OriginalSpacebreak:
          return [
            ...map(this.spacebreaks, s => ({ name: s.name, id: s._id })),
            ...map(spacebreakTypes, st => {
              const type = upperFirst(st);
              return {
                name: this.$tkey(`values.${FilterValues[type]}`),
                id: FilterMappings[type],
              };
            }),
          ];
        case Filters.PromotedDemoted:
          return [
            {
              name: this.$tkey(`values.${FilterValues.PromotedOptimisation}`),
              id: FilterValues.PromotedOptimisation,
            },
            {
              name: this.$tkey(`values.${FilterValues.DemotedOptimisation}`),
              id: FilterValues.DemotedOptimisation,
            },
            {
              name: this.$tkey(`values.${FilterValues.PromotedReferenceCheckpoint}`),
              id: FilterValues.PromotedReferenceCheckpoint,
            },
            {
              name: this.$tkey(`values.${FilterValues.DemotedReferenceCheckpoint}`),
              id: FilterValues.DemotedReferenceCheckpoint,
            },
          ];
        case Filters.customAttributes:
          return this.getValuesForSelectedAttribute(this.filterValue.id);
        case Filters.ExternalProductRanking: {
          let higherReferenceRankingName = this.$tkey(
            `values.${FilterValues.HigherReferenceRanking}`
          );
          const disabled = !this.selectedAssortmentCanvas.hasBeenOptimised;
          if (disabled) {
            const mustBeOptimised = this.$tkey('values.mustBeOptimised');
            higherReferenceRankingName = `${higherReferenceRankingName} (${mustBeOptimised})`;
          }
          return [
            {
              name: this.$tkey(`values.${FilterValues.TopXExternalRanking}`),
              id: FilterValues.TopXExternalRanking,
            },
            {
              name: higherReferenceRankingName,
              id: FilterValues.HigherReferenceRanking,
              disabled,
            },
          ];
        }
        case Filters.CustomerSegment:
          // Creates flat list of [{name, id, customerSegmentScheme, customerSegment}]
          return this.getCustomerSegmentsFlat().map(
            ({
              customerSegmentSchemeName,
              customerSegmentScheme,
              customerSegmentName,
              customerSegment,
            }) => ({
              name: `${customerSegmentSchemeName} - ${customerSegmentName}`,
              id: `${customerSegmentScheme}-${customerSegment}`,
              customerSegmentScheme,
              customerSegment,
            })
          );
        case Filters.ProductSeries:
          return this.availableProductSeries;
        default:
          return [];
      }
    },

    selectedFilterItemsSorted() {
      return sortBy(this.selectedFilterItems, item => item.name.toLowerCase());
    },

    allowSelectMultiple() {
      // Only allow selection of multiple values on certain filters.
      return !(
        this.filterValue.type === Filters.LockType ||
        this.filterValue.type === Filters.PromotedDemoted ||
        this.filterValue.type === FilterMappings.ProductType
      );
    },

    productsAndFiltersShown() {
      const res = this.mode === FILTER ? this.getFilteredProducts : this.getHighlightedProducts;
      return this.mode === FILTER
        ? `
        ${size(this.filters)} ${this.$tkey('filters')},
        ${size(res.products)} ${this.$tkey('productsShown')}`
        : `
        ${size(res.highlightedProducts)} ${this.$tkey('highlightedProductsShown')}`;
    },

    isAddFilterDisabled() {
      // disable add filter button if no values are selected.
      // accounts for when autocomplete doesn't allow multiple selections, in that case the property is an object
      const isSingleValue = isPlainObject(this.selectedFilterValues);
      // accounts for multiple selections when property is an array
      const areMultipleValues = isArray(this.selectedFilterValues);
      if (isSingleValue) {
        return false;
      }
      if (areMultipleValues) {
        return !this.selectedFilterValues.length;
      }
      return true;
    },

    filterButtonStyle() {
      // Add padding to the main button when the menu opens, as it changes from secondary --> primary button
      // Makes sure UI elements stay in consistent place
      return this.menu ? { 'padding-right': '18px' } : {};
    },

    labelAppliedFilters() {
      return this.mode === FILTER ? this.$tkey('appliedFilters') : this.$tkey('appliedHighlights');
    },

    labelNoFiltersApplied() {
      return this.mode === FILTER
        ? this.$tkey('noFiltersApplied')
        : this.$tkey('noHighlightsApplied');
    },

    labelFilterName() {
      return this.mode === FILTER ? this.$tkey('filterName') : this.$tkey('highlightName');
    },

    labelFilterValues() {
      return this.mode === FILTER ? this.$tkey('filterValues') : this.$tkey('highlightValues');
    },

    filterItemsSorted() {
      return sortBy(this.filterItems, item => item.name.toLowerCase());
    },
  },

  created() {
    this.reset();
  },

  methods: {
    ...mapActions('scenarioProducts', ['fetchScenarioUniqueAttributeValues']),
    ...mapMutations('assortmentCanvas', [
      'setCanvasProductsFilters',
      'setCanvasProductsHighlights',
    ]),

    async reset() {
      // reset product filters
      if (this.mode === FILTER) {
        this.setCanvasProductsFilters([]);
      } else {
        this.setCanvasProductsHighlights([]);
      }
      const data = await this.fetchScenarioUniqueAttributeValues({
        scenarioId: this.selectedScenario._id,
      });
      // add type to customAttributes for filter
      this.customAttributeItemsWithType = cloneDeep(this.getScenarioAttributes).map(x => {
        x.type = Filters.customAttributes;
        return x;
      });
      this.attributesValuesMap = data;
      this.filterItems = [...this.customAttributeItemsWithType, ...this.filterItems];
      if (
        get(this.getClientConfig, 'features.isExternalProductRankingHighlightingEnabled', false)
      ) {
        const externalRankingFilterItem = {
          name: this.$tkey(Filters.ExternalProductRanking),
          type: Filters.ExternalProductRanking,
        };
        this.filterItems = [...this.filterItems, externalRankingFilterItem];
      }
      if (get(this.getClientConfig, 'features.customerSegmentsEnabled', false)) {
        const customerSegmentFilterItem = {
          name: this.$tkey(Filters.CustomerSegment),
          type: Filters.CustomerSegment,
        };
        this.filterItems = [...this.filterItems, customerSegmentFilterItem];
      }
    },
    menuArrow() {
      // allow arrow icon to close autocomplete selection menu
      const autocompleteSelect = this.$refs.autocompleteSelect;
      if (autocompleteSelect.isMenuActive) {
        this.$refs.autocompleteSelect.isMenuActive = false;
        autocompleteSelect.blur();
      } else {
        this.$refs.autocompleteSelect.isMenuActive = true;
        autocompleteSelect.focus();
      }
    },
    getValuesForSelectedAttribute(id) {
      if (id) {
        const selectableValues = this.attributesValuesMap[id].values;
        // map data to conform to {name: 'x' , id: 'x'} convention
        return map(selectableValues, x => ({ name: x, id: x }));
      }
      return [];
    },
    addFilter() {
      this.filters.push({
        notEqualTo: this.notEqualTo,
        filterType: this.filterValue.type,
        attributeId: this.filterValue.type === Filters.customAttributes ? this.filterValue.id : '',
        filterName: this.filterValue.name,
        filterValues: isArray(this.selectedFilterValues)
          ? this.selectedFilterValues
          : [this.selectedFilterValues],
      });
      this.selectedFilterValues = [];
      this.filterValue = {};
      this.notEqualTo = false;
    },
    removeFilter(index) {
      this.filters.splice(index, 1);
    },
    deleteChip(item) {
      if (isArray(this.selectedFilterValues)) {
        const index = this.selectedFilterValues.indexOf(item);
        this.selectedFilterValues.splice(index, 1);
      } else {
        this.selectedFilterValues = null;
      }
    },
    closeModal() {
      if (!this.menu) {
        if (this.mode === FILTER) {
          this.setCanvasProductsFilters(cloneDeep(this.filters));
        } else {
          this.setCanvasProductsHighlights(cloneDeep(this.filters));
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.close-icon-container {
  width: 100%;
}

.btn-text {
  color: $assortment-primary-colour;
  padding-right: 0 !important;

  &::before {
    opacity: 0 !important;
  }
}

::v-deep {
  .v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before {
    opacity: 0 !important;
  }
  .v-btn[primary]:hover {
    background-color: $assortment-primary-colour !important;
  }

  .rtls-checkbox {
    margin: 0 !important;

    .v-input--selection-controls__input {
      margin-right: 0 !important;
    }
  }

  .v-chip {
    padding-right: 30px;
  }

  .v-chip__content {
    // set max-width on the chip content so it doesn't overflow the container
    max-width: 300px;
    height: auto;
    display: inline-block !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .v-chip__close {
    position: absolute;
    top: 3px;
    right: 12px;
    width: 24px;
  }
}

.title-filters {
  padding-left: 5px;
}

.filter-item {
  font-size: 1.2rem;
  padding-left: 5px;
}

.filter-row {
  border-bottom: 1px solid $assortment-divider-colour;
  &:nth-child(odd) {
    background: $assortment-table-blue-bg-colour;
  }
}

.add-filter-button {
  margin-right: 3px;
}

.headers {
  border-bottom: 1px solid $assortment-divider-colour-darker;
  padding-left: 5px;
}
</style>
