<template>
  <div
    class="w-100 d-flex justify-center align-center"
    :class="{
      'pod-delta-increase': show && delta > 0,
      'pod-delta-decrease': show && delta < 0,
    }"
  >
    <span v-if="show" class="delta">{{ getDeltaText }}</span>
    <div v-else class="w-100 h-100" />
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    delta: {
      type: Number,
      required: true,
    },
    // controls whether we show a blank space or the contents
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    getDeltaText() {
      // negative number has sign format
      const sign = this.delta < 0 ? '' : '+';
      return `${this.type}${sign}${this.delta}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.pod-delta-increase {
  background-color: $canvas-delta-tag-increase-background;
}

.pod-delta-decrease {
  background-color: $canvas-delta-tag-decrease-background;
}

span.delta {
  font-size: 0.9rem;
  color: $product-icons-colour;
}
</style>
