import permissions from '@enums/user-permissions';

import StatusPage from './status.vue';

const routes = [
  {
    path: '/status',
    name: 'status',
    id: 'status',
    component: StatusPage,
    omitFromNav: true,
    meta: { requiresPermission: permissions.canViewStatusPage, titleExtension: 'general.status' },
  },
];

export default routes;
