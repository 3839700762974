import { size, get } from 'lodash';

const templateSectionNames = {
  scope: 'scope',
  inputs: 'inputs',
};

// Main template sections
const sections = [
  {
    tkey: 'templatesPage.setupSections.assortmentGroup',
    name: 'dates-assortment-groups',
    count: wp => size(wp.selectedAssortmentGroupSettings),
    statusSection: 'scope',
    statusSubSection: 'datesAssortmentGroups',
  },
  {
    tkey: 'templatesPage.setupSections.stores',
    name: 'stores',
    count: wp => get(wp, 'storeCount.total', 0),
    statusSection: 'scope',
    statusSubSection: 'stores',
  },
  {
    tkey: 'templatesPage.setupSections.products',
    name: 'products',
    count: wp => get(wp, 'productCount.total', 0),
    statusSection: 'scope',
    statusSubSection: 'products',
  },
  // TODO AOV3-2312
  // {
  //   tkey: 'templatesPage.setupSections.additionalProducts',
  //   name: 'additionalProducts',
  //   statusSection: 'scope',
  //   statusSubSection: 'additionalProducts',
  // },
  {
    tkey: 'templatesPage.setupSections.productAttributes',
    name: 'template-attributes',
    statusSection: 'inputs',
    statusSubSection: 'attributeEditor',
  },
  {
    tkey: 'templatesPage.setupSections.cannGroups',
    name: 'template-cann-group',
    statusSection: 'inputs',
    statusSubSection: 'cannGroups',
  },
];

export default {
  ALL: sections,
  templateSectionNames,
};
