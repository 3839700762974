<template>
  <svg
    width="158px"
    height="33px"
    viewBox="0 0 158 33"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: Sketch 63.1 (92452) - https://sketch.com -->
    <title>RA_logo</title>
    <desc>Created with Sketch.</desc>
    <g
      id="RA_logo"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      fill-opacity="0.85"
      font-family="NotoSans, Noto Sans"
      font-weight="normal"
    >
      <g id="pen-header_1" fill="#000000">
        <g id="Group-5">
          <g id="Group">
            <g id="Group-2">
              <g id="Group-3">
                <text id="RetailAssortment" font-size="20" line-spacing="20">
                  <tspan data-id-e2e="hdrRetail" x="0" y="21.3430176">
                    Retail
                  </tspan>
                  <tspan
                    data-id-e2e="hdrAssortment"
                    x="52.1219531"
                    y="21"
                    font-family="NotoSans-Light, Noto Sans"
                    font-weight="300"
                  >
                    Assortment
                  </tspan>
                </text>
                <text id="An-Oliver-Wyman-Solu" font-size="8" line-spacing="12">
                  <tspan x="1" y="30">An Oliver Wyman Solution</tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'HomeButton',
};
</script>
