<template>
  <div>
    <workpackages-toolbar
      doc-path="020-workpackage"
      :entity="entity"
      archived
      @on-search="onSearch"
    />
    <v-alert
      v-if="loadingArchivedWorkpackages && !archivedWorkpackagesTotalCount"
      border="left"
      colored-border
      color="primary accent-4"
    >
      <v-progress-circular indeterminate color="primary" size="20" />
      {{ $t('workpackagePage.loadingArchivedWorkpackages') }}
    </v-alert>
    <v-card v-else class="col-box">
      <v-row no-gutters>
        <v-col class="workpackages-container" colspan="12">
          <workpackages-list
            v-if="archivedWorkpackagesTotalCount"
            :entity="entity"
            :workpackages="archivedWorkpackages"
          />
          <v-alert v-else type="info" text>
            {{ $t(`workpackages.noArchived${capitalize(entity)}s`) }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="horizontal-divider-container" colspan="12">
          <div class="horizontal-divider" />
        </v-col>
      </v-row>

      <template v-if="archivedWorkpackagesTotalCount">
        <div class="text-center">
          <v-pagination
            v-model="currentPage"
            :length="totalPageCount"
            :total-visible="totalVisiblePages"
          />
        </div>
      </template>
      <v-overlay v-if="loadingArchivedWorkpackages" absolute opacity="0.5" color="white">
        <v-progress-circular indeterminate color="primary" size="64" width="10" />
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { ceil, capitalize } from 'lodash';
import entities from '@enums/entities';

export default {
  name: 'ArchivedWorkpackages',
  props: {
    entity: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      regexSearch: '',
      currentPage: 1,
      pageSize: null,
      totalVisiblePages: null,
      entities,
      capitalize,
    };
  },
  computed: {
    ...mapGetters('workpackages', ['archivedWorkpackages', 'archivedWorkpackagesTotalCount']),
    ...mapState('workpackages', ['loadingArchivedWorkpackages']),
    ...mapState('context', ['clientConfig']),
    totalPageCount() {
      return ceil(this.archivedWorkpackagesTotalCount / this.pageSize);
    },
    offset() {
      const offset = (this.currentPage - 1) * this.pageSize;
      return offset;
    },
    getTemplates() {
      return this.entity === entities.template;
    },
  },
  watch: {
    async currentPage(page) {
      this.setCurrentPage(page);
      await this.fetchArchivedWorkpackagesPage({ limit: this.pageSize, page });
    },
  },
  async created() {
    const { pageSize, totalVisiblePages } = this.clientConfig.archivedWorpackages;
    this.pageSize = pageSize;
    this.totalVisiblePages = totalVisiblePages;
    this.setCurrentPage(this.currentPage);
    await this.fetchArchivedWorkpackagesPage({ limit: this.pageSize, page: this.currentPage });
  },

  methods: {
    ...mapMutations('workpackages', ['setWorkpackageSetupExpanded']),
    ...mapActions('workpackages', ['fetchArchivedWorkpackages']),
    ...mapMutations('workpackages', [
      'setArchivedWorkpackagesCurrentPage',
      'setArchivedWorkpackageTemplatesCurrentPage',
    ]),

    // fetches archived wp page (records for single pagination page)
    async fetchArchivedWorkpackagesPage(params) {
      const { limit, filter } = params;
      await this.fetchArchivedWorkpackages({
        limit,
        offset: this.offset,
        filter,
        regexSearch: this.searchPhrase,
        shouldGetTemplates: this.getTemplates,
      });
    },

    async onSearch(searchText) {
      this.searchPhrase = searchText;
      await this.fetchArchivedWorkpackagesPage({
        limit: this.pageSize,
        offset: this.offset,
        regexSearch: this.searchPhrase,
      });

      const numberOfPages = Math.floor(this.archivedWorkpackagesTotalCount / this.pageSize);
      // navigate to the first page if number of search results if less than expected for the current selected page
      if (numberOfPages <= this.currentPage && this.currentPage !== 1) {
        this.currentPage = 1;
      }
    },

    setCurrentPage(page) {
      if (!this.getTemplates) {
        this.setArchivedWorkpackagesCurrentPage(page);
      } else {
        this.setArchivedWorkpackageTemplatesCurrentPage(page);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.col-box {
  min-height: 200px !important;
}

.workpackages-container {
  overflow-x: auto;
}

::v-deep {
  .v-application .headline {
    font-family: $assortment-regular-font;
  }
}

::v-deep {
  .progress-bar {
    padding-bottom: 10em;
  }
}
</style>
