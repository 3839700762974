<template>
  <div class="command-bar d-flex justify-space-between align-center">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.command-bar {
  max-width: 140px;
  & > *:not(:last-child) {
    margin-right: 12px;
  }
}
</style>
