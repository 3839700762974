import { metricTypes, metricCategories, metricGroups } from '@enums/product-metrics';
import { lowerFirst, forEach } from 'lodash';

const mixin = {
  methods: {
    getCustomerSegmentsFlat() {
      return Object.values(metricCategories[metricTypes.customerSegmentation]).reduce(
        (customerSegmentsFlat, customerSegmentScheme) => {
          forEach(
            metricGroups[metricTypes.customerSegmentation][customerSegmentScheme],
            customerSegment => {
              const customerSegmentSchemeName = this.$t(
                `customerSegmentation.${lowerFirst(customerSegmentScheme)}`
              );
              const customerSegmentName = this.$t(
                `customerSegmentation.${lowerFirst(customerSegment)}`
              );
              customerSegmentsFlat.push({
                customerSegmentSchemeName,
                customerSegmentScheme,
                customerSegmentName,
                customerSegment,
              });
            }
          );
          return customerSegmentsFlat;
        },
        []
      );
    },
  },
};

export default mixin;
