import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "pb-50", attrs: { fluid: "" } },
    [
      _c(
        VLayout,
        { attrs: { column: "" } },
        [
          _c(
            VFlex,
            [
              _c(
                VRow,
                { staticClass: "header-container d-flex flex-column mt-10" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-space-between align-center mb-5",
                    },
                    [
                      _c("h1", { staticClass: "header" }, [
                        _vm._v("Assortment Tool UI / Kit"),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "ow-logo",
                        attrs: {
                          src: require("../../../img/ow-logo-brand.png"),
                          alt: "ow-logo",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(VDivider, { staticClass: "mb-5" }),
                ],
                1
              ),
              _vm._v(" "),
              _c(VRow, { staticClass: "header-container intro-container" }, [
                _c("h1", { staticClass: "header mb-5" }, [
                  _vm._v("Introduction"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                VRow,
                [
                  _c(VCol, [
                    _c("p", { staticClass: "paragraph" }, [
                      _vm._v(
                        "\n            This UI kit is a work in progress and is constantly evolving. Strict rules are set for\n            buttons, colours and text sizes, and these should be followed when possible. If there\n            are any questions regarding this kit of parts, please contact\n            josh.grazier@oliverwyman.com.\n          "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VRow,
                { staticClass: "header-container colours-container mb-5" },
                [_c("h1", { staticClass: "header" }, [_vm._v("Colours")])]
              ),
              _vm._v(" "),
              _c(
                VRow,
                { staticClass: "d-flex flex-row align-center" },
                [
                  _c(VCol, { attrs: { cols: "1" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "colour-block-container d-flex flex-column align-start",
                      },
                      [
                        _c("h4", [_vm._v("Primary")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "colour-block colour-block--primary",
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { attrs: { cols: "1" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "colour-block-container d-flex flex-column align-start",
                      },
                      [
                        _c("h4", [_vm._v("Secondary")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "colour-block colour-block--secondary",
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { attrs: { cols: "1" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "colour-block-container d-flex flex-column align-start",
                      },
                      [
                        _c("h4", [_vm._v("Button Hover")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass:
                            "colour-block colour-block--button-hover",
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { attrs: { cols: "1" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "colour-block-container d-flex flex-column align-start",
                      },
                      [
                        _c("h4", [_vm._v("Text")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "colour-block colour-block--text",
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { attrs: { cols: "1" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "colour-block-container d-flex flex-column align-start",
                      },
                      [
                        _c("h4", [_vm._v("List Item Background")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass:
                            "colour-block colour-block--list-item-bg",
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { attrs: { cols: "1" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "colour-block-container d-flex flex-column align-start",
                      },
                      [
                        _c("h4", [_vm._v("TextBox Background")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "colour-block colour-block--text-box-bg",
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { attrs: { cols: "1" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "colour-block-container d-flex flex-column align-start",
                      },
                      [
                        _c("h4", [_vm._v("Positive Action")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass:
                            "colour-block colour-block--positive-action",
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { attrs: { cols: "1" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "colour-block-container d-flex flex-column align-start",
                      },
                      [
                        _c("h4", [_vm._v("Negative Action")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass:
                            "colour-block colour-block--negative-action",
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { attrs: { cols: "1" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "colour-block-container d-flex flex-column align-start",
                      },
                      [
                        _c("h4", [_vm._v("Filter Bar Background")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass:
                            "colour-block colour-block--filter-bar-bg",
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { attrs: { cols: "1" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "colour-block-container d-flex flex-column align-start",
                      },
                      [
                        _c("h4", [_vm._v("Divider Colour")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass:
                            "colour-block colour-block--divider-colour",
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { attrs: { cols: "1" } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "colour-block-container d-flex flex-column align-start",
                      },
                      [
                        _c("h4", [_vm._v("Scenarios")]),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "colour-block colour-block--scenarios",
                        }),
                      ]
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(VRow, { staticClass: "header-container row-container" }, [
                _c("h1", { staticClass: "header mb-5" }, [_vm._v("Dropdowns")]),
              ]),
              _vm._v(" "),
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    [
                      _c("h3", { staticClass: "mb-3" }, [
                        _vm._v("Standard Dropdown"),
                      ]),
                      _vm._v(" "),
                      _c("rtls-select", {
                        attrs: {
                          white: "",
                          width: "240px",
                          items: _vm.standardDropdownItems,
                          placeholder: _vm.$t("general.select"),
                        },
                        model: {
                          value: _vm.standardDropdownModel,
                          callback: function ($$v) {
                            _vm.standardDropdownModel = $$v
                          },
                          expression: "standardDropdownModel",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCol,
                    [
                      _c("h3", { staticClass: "mb-3" }, [
                        _vm._v("Standard Dropdown (grey)"),
                      ]),
                      _vm._v(" "),
                      _c("rtls-select", {
                        attrs: {
                          items: _vm.standardDropdownItems,
                          placeholder: _vm.$t("general.select"),
                          width: "240px",
                        },
                        model: {
                          value: _vm.standardDropdownModel,
                          callback: function ($$v) {
                            _vm.standardDropdownModel = $$v
                          },
                          expression: "standardDropdownModel",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(VIcon, { attrs: { size: "28" } }, [
                            _vm._v("expand_more"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(VCol, [
                    _c("h3", { staticClass: "mb-3" }, [_vm._v("Hover State")]),
                  ]),
                  _vm._v(" "),
                  _c(VCol, [
                    _c("h3", { staticClass: "mb-3" }, [
                      _vm._v("Clicked State"),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    [
                      _c("h3", { staticClass: "mb-3" }, [
                        _vm._v("Disabled Dropdown"),
                      ]),
                      _vm._v(" "),
                      _c("rtls-select", {
                        attrs: {
                          disabled: "",
                          items: _vm.standardDropdownItems,
                          placeholder: _vm.$t("general.select"),
                          width: "240px",
                        },
                        model: {
                          value: _vm.standardDropdownModel,
                          callback: function ($$v) {
                            _vm.standardDropdownModel = $$v
                          },
                          expression: "standardDropdownModel",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCol,
                    [
                      _c("h3", { staticClass: "mb-3" }, [
                        _vm._v("Calendar Dropdown"),
                      ]),
                      _vm._v(" "),
                      _c("rtls-calendar-picker", {
                        staticClass: "select",
                        model: {
                          value: _vm.date,
                          callback: function ($$v) {
                            _vm.date = $$v
                          },
                          expression: "date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VCol, [
                    _c("h3", { staticClass: "mb-3" }, [
                      _vm._v("Calendar Hover"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(VCol),
                ],
                1
              ),
              _vm._v(" "),
              _c(VRow, { staticClass: "header-container row-container" }, [
                _c("h1", { staticClass: "header mb-5" }, [
                  _vm._v("Menu dropdowns"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                VRow,
                [
                  _c(VCol, [
                    _c("h3", { staticClass: "mb-3" }, [
                      _vm._v("Standard menu"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-column dropdown-btn-container",
                      },
                      [
                        _c("main-dropdown-menu", {
                          attrs: { actions: _vm.actionsArray },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VRow,
                [
                  _c(
                    "v",
                    { staticClass: "col" },
                    [
                      _c("h3", { staticClass: "mb-3" }, [
                        _vm._v("Menu with title"),
                      ]),
                      _vm._v(" "),
                      _c("main-dropdown-menu", {
                        attrs: {
                          title: "Export",
                          icon: "import_export",
                          actions: _vm.actionsArray,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(VRow, { staticClass: "header-container" }, [
                _c("h1", { staticClass: "header mb-5 mt-5" }, [
                  _vm._v("Text fields"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    [
                      _c("h3", { staticClass: "mb-3" }, [
                        _vm._v("Standard Text Field"),
                      ]),
                      _vm._v(" "),
                      _c("rtls-text-field", {
                        model: {
                          value: _vm.standardTextFieldModelWhite,
                          callback: function ($$v) {
                            _vm.standardTextFieldModelWhite = $$v
                          },
                          expression: "standardTextFieldModelWhite",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCol,
                    [
                      _c("h3", { staticClass: "mb-3" }, [
                        _vm._v("Standard Text Field (grey)"),
                      ]),
                      _vm._v(" "),
                      _c("rtls-text-field", {
                        attrs: { grey: "" },
                        model: {
                          value: _vm.standardTextFieldModelGrey,
                          callback: function ($$v) {
                            _vm.standardTextFieldModelGrey = $$v
                          },
                          expression: "standardTextFieldModelGrey",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VCol, [
                    _c("h3", { staticClass: "mb-3" }, [
                      _vm._v("Error Field (grey)"),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(VCol, [
                    _c("h3", { staticClass: "mb-3" }, [_vm._v("Clicked")]),
                  ]),
                  _vm._v(" "),
                  _c(VCol, [
                    _c("h3", { staticClass: "mb-3" }, [_vm._v("Disabled")]),
                  ]),
                  _vm._v(" "),
                  _c(VCol),
                  _vm._v(" "),
                  _c(VCol),
                ],
                1
              ),
              _vm._v(" "),
              _c(VRow, { staticClass: "header-container" }, [
                _c("h1", { staticClass: "header mb-5 mt-5" }, [
                  _vm._v("Search"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    [
                      _c("h3", { staticClass: "mb-3" }, [
                        _vm._v("Standard Search"),
                      ]),
                      _vm._v(" "),
                      _c("rtls-search", {
                        attrs: {
                          placeholder: _vm.$t("kitOfParts.searchLabel"),
                          width: "240px",
                        },
                        model: {
                          value: _vm.standardAssortmentSearch,
                          callback: function ($$v) {
                            _vm.standardAssortmentSearch = $$v
                          },
                          expression: "standardAssortmentSearch",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VCol, [
                    _c("h3", { staticClass: "mb-3" }, [_vm._v("Clicked")]),
                  ]),
                  _vm._v(" "),
                  _c(VCol),
                  _vm._v(" "),
                  _c(VCol),
                ],
                1
              ),
              _vm._v(" "),
              _c(VRow, { staticClass: "header-container" }, [
                _c("h1", { staticClass: "header mb-5 mt-5" }, [
                  _vm._v("Toggle"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                VCol,
                [
                  _c("rtls-toggle", {
                    attrs: {
                      "left-toggle": _vm.toggle.leftToggle,
                      "right-toggle": _vm.toggle.rightToggle,
                    },
                    model: {
                      value: _vm.standardToggleModel,
                      callback: function ($$v) {
                        _vm.standardToggleModel = $$v
                      },
                      expression: "standardToggleModel",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(VRow, { staticClass: "header-container" }, [
                _c("h1", { staticClass: "header mb-5 mt-5" }, [
                  _vm._v("Buttons and Links"),
                ]),
              ]),
              _vm._v(" "),
              _c(VCol, [
                _c(
                  "div",
                  { staticClass: "buttons-container" },
                  [
                    _c(
                      VBtn,
                      { attrs: { primary: "", depressed: "" } },
                      [
                        _vm._v("\n            Primary Icon\n            "),
                        _c(VIcon, { attrs: { right: "" } }, [
                          _vm._v("$import"),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(VBtn, { attrs: { primary: "", depressed: "" } }, [
                      _vm._v("Primary"),
                    ]),
                    _vm._v(" "),
                    _c(VBtn, { attrs: { secondary: "", depressed: "" } }, [
                      _vm._v("Secondary"),
                    ]),
                    _vm._v(" "),
                    _c(
                      VBtn,
                      {
                        attrs: { depressed: "", action: "" },
                        on: { click: _vm.save },
                      },
                      [_vm._v("Save")]
                    ),
                    _vm._v(" "),
                    _c(
                      VBtn,
                      { attrs: { primary: "", depressed: "", disabled: "" } },
                      [_vm._v("Inactive")]
                    ),
                    _vm._v(" "),
                    _c(
                      VBtn,
                      {
                        staticClass: "text-outline",
                        attrs: { text: "", link: "" },
                      },
                      [_vm._v("Remove all")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(VRow, { staticClass: "header-container" }, [
                _c("h1", { staticClass: "header mb-5 mt-5" }, [
                  _vm._v("Small buttons/Icons"),
                ]),
              ]),
              _vm._v(" "),
              _c(VCol, [
                _c("h3", { staticClass: "mb-3" }, [
                  _vm._v("Tickbox / Radio / Success"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c(VCheckbox, {
                      staticClass: "rtls-checkbox",
                      attrs: { "hide-details": "" },
                      model: {
                        value: _vm.checkboxValue,
                        callback: function ($$v) {
                          _vm.checkboxValue = $$v
                        },
                        expression: "checkboxValue",
                      },
                    }),
                    _vm._v(" "),
                    _c(VIcon, { attrs: { size: "18" } }, [
                      _vm._v("$success"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(VRow, { staticClass: "header-container" }, [
                _c("h1", { staticClass: "header mb-5 mt-5" }, [
                  _vm._v("User notes"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                VCol,
                { attrs: { cols: "3" } },
                [_c("notes", { attrs: { notes: [], title: "Notes" } })],
                1
              ),
              _vm._v(" "),
              _c(VRow, { staticClass: "header-container" }, [
                _c("h1", { staticClass: "header mb-5 mt-5" }, [
                  _vm._v("Canvas components"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                VRow,
                [
                  _c(VCol, { attrs: { cols: "3" } }, [
                    _c("h2", [_vm._v("Highlight")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "Used to highlight particular divs. Adds borders to sides:"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "highlight-demo" },
                      [
                        _c("highlighted-area", {
                          attrs: {
                            "outer-class": "highlight-area-custom-class",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("FOO")]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "You can also highlight only the sides, as seen on list view:"
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "highlight-demo list-demo" },
                      [
                        _c("highlighted-area", {
                          attrs: {
                            "outer-style": _vm.customHighlightStyle,
                            "list-view": "",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Bar")]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { attrs: { cols: "2" } },
                    [
                      _c("h2", [_vm._v("Deltas tag")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "Used to display the optimiser/reference delta points of distribution (PoD)"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("product-delta-tag", {
                        attrs: { delta: -1, type: "R" },
                      }),
                      _vm._v(" "),
                      _c("product-delta-tag", {
                        attrs: { delta: 21, type: "O" },
                      }),
                      _vm._v(
                        "\n          Use `show` to not display contents, eg when canvas get outdated but product has delta:\n          "
                      ),
                      _c("product-delta-tag", {
                        attrs: { delta: -1, type: "O", show: false },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }