import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VRow,
    {
      staticClass: "metrics-container clickable",
      class: _vm.getMetricsClass,
      attrs: { "data-id-e2e": "switchingModellingMetricsCell" },
    },
    [
      _c(
        VMenu,
        {
          attrs: {
            absolute: "",
            "offset-y": "",
            "z-index": 200,
            "close-on-content-click": false,
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    VRow,
                    _vm._g(_vm._b({}, "v-row", attrs, false), on),
                    [
                      !!_vm.params.clusterId && !_vm.params.isNoDataCluster
                        ? _c(
                            VCol,
                            { staticClass: "unclustered-cb" },
                            [
                              !_vm.isUsageOfUnclusteredDisabled
                                ? _c(VCheckbox, {
                                    staticClass: "rtls-checkbox mx-2",
                                    attrs: {
                                      "data-id-e2e":
                                        "toggleClusterUseUnclusteredCheckbox",
                                      "input-value":
                                        _vm.isClusterCannGroupSetToUnclustered,
                                    },
                                    on: {
                                      change:
                                        _vm.setToUnclusterSingleClusterCannGroup,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(VCol, { staticClass: "metric larger-font" }, [
                        _c("strong", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.confidenceScore) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(VCol, { staticClass: "metric" }, [
                        _vm._v(
                          _vm._s(_vm.numSubsequentBaskets) +
                            " / " +
                            _vm._s(_vm.numSubsequentBasketsPercentOfTotal)
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _vm._v(" "),
          _c(
            VCard,
            {
              attrs: {
                "data-id-e2e": "switchingModellingMetricsBreakdownModal",
              },
            },
            [
              _c(
                VContainer,
                { staticClass: "d-flex flex-column breakdown-modal" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "py-0 text-right", attrs: { cols: 4 } },
                        [_c("b", [_vm._v(_vm._s(_vm.$tkey("cannGroup")))])]
                      ),
                      _vm._v(" "),
                      _c(VCol, { staticClass: "py-0" }, [
                        _c("b", [_vm._v(_vm._s(_vm.params.cannGroupName))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "pt-0 text-right", attrs: { cols: 4 } },
                        [_c("b", [_vm._v(_vm._s(_vm.$tkey("cluster")))])]
                      ),
                      _vm._v(" "),
                      _c(VCol, { staticClass: "pt-0" }, [
                        _c("b", [_vm._v(_vm._s(_vm.params.clusterName))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider, { staticClass: "bold" }),
                  _vm._v(" "),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "text-right", attrs: { cols: 5 } },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(_vm.$tkey("switchingAnalysisMethodology"))
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(VCol, { attrs: { "align-self": "end" } }, [
                        _c("b", [
                          _vm._v(_vm._s(_vm.$t(_vm.methodologyInPlace))),
                        ]),
                        _vm._v(" " + _vm._s(_vm.$tkey("score")) + " "),
                        _c("b", [_vm._v(_vm._s(_vm.confidenceScore))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider),
                  _vm._v(" "),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "pb-1 text-right", attrs: { cols: 5 } },
                        [_c("b", [_vm._v(_vm._s(_vm.$tkey("transactions")))])]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "py-0 text-right", attrs: { cols: 5 } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tkey("total")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(VCol, { staticClass: "py-0" }, [
                        _c("b", [_vm._v(_vm._s(_vm.numTotalBaskets))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "py-0 text-right", attrs: { cols: 5 } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tkey("withPriorLinked")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(VCol, { attrs: { "align-self": "end" } }, [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.numSubsequentBaskets) +
                              " / " +
                              _vm._s(_vm.numSubsequentBasketsPercentOfTotal) +
                              "\n              " +
                              _vm._s(_vm.$tkey("ofTotal"))
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider),
                  _vm._v(" "),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "pb-1 text-right", attrs: { cols: 5 } },
                        [_c("b", [_vm._v(_vm._s(_vm.$tkey("products")))])]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "py-0 text-right", attrs: { cols: 5 } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tkey("count")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(VCol, { staticClass: "py-0" }, [
                        _c("b", [_vm._v(_vm._s(_vm.params.totalProductCount))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "py-0 text-right", attrs: { cols: 5 } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tkey("withSufficientSales")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(VCol, { attrs: { "align-self": "end" } }, [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.params.productCountWithLinkedBaskets)
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "py-0 text-right", attrs: { cols: 5 } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tkey("withThLinkedDataBasedScore")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(VCol, { attrs: { "align-self": "end" } }, [
                        _c("b", [_vm._v(_vm._s(_vm.confidenceScore))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider),
                  _vm._v(" "),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "pb-1 text-right", attrs: { cols: 5 } },
                        [_c("b", [_vm._v(_vm._s(_vm.$tkey("stores")))])]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "py-0 text-right", attrs: { cols: 5 } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tkey("numInCluster")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(VCol, { staticClass: "pt-0" }, [
                        _c("b", [_vm._v(_vm._s(_vm.params.storeCount))]),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider, { staticClass: "bold" }),
                  _vm._v(" "),
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "pb-0 text-right" },
                        [
                          _vm.params.isNoDataCluster
                            ? _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "switchingModellingPage.noClusterDataNoSwitching"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _c(
                                VBtn,
                                {
                                  staticClass: "view-switching__link",
                                  attrs: { ripple: false, text: "", small: "" },
                                  on: { click: _vm.viewSwitchingMatrices },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$tkey("viewSwitching")) +
                                      "\n              "
                                  ),
                                  _c(VIcon, { attrs: { size: "20" } }, [
                                    _vm._v("mdi-chevron-right"),
                                  ]),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showSwitchingMatricesModal
        ? _c("switching-modelling-matrices-modal", {
            attrs: {
              value: _vm.showSwitchingMatricesModal,
              "modal-data": _vm.switchingMatricesModalData,
            },
            on: {
              "close-modal": function ($event) {
                _vm.showSwitchingMatricesModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }