<template>
  <div class="locale-switcher">
    <div class="label">{{ $t('navBar.language') }}</div>
    <rtls-select
      v-model="currentLocale"
      :items="locales"
      item-text="fullName"
      item-value="locale"
      white
      :menu-props="{ right: true, offsetX: true, contentClass: 'options' }"
      solo
      hide-details
      @change="setLocale"
    />
  </div>
</template>

<script>
import { filter, includes } from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'LocaleSwitcher',
  props: {},

  data: () => ({
    currentLocale: null,
  }),

  computed: {
    ...mapGetters('context', ['getCurrentLocale', 'getAvailableLocales', 'getLocaleInfo']),

    locales() {
      return filter(this.getLocaleInfo, localeInfo =>
        includes(this.getAvailableLocales, localeInfo.locale)
      );
    },
  },

  created() {
    this.currentLocale = this.getCurrentLocale;
  },

  methods: {
    ...mapActions('context', ['setLanguageLocale']),

    setLocale(locale) {
      this.setLanguageLocale({ locale });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input__append-inner {
    transform: rotate(-90deg);
  }
}

.label {
  font-size: 1.2rem;
  font-weight: normal;
  min-width: 6.5rem;
}

.locale-switcher {
  display: flex;
  align-items: center;
}
</style>
