<template>
  <v-dialog :value="value" width="500px" @click:outside="closeModal">
    <dialog-card v-if="value" :title="$tkey('modalTitle')" @close="closeModal">
      <v-container fluid class="pa-0 reset-product-data__container">
        <v-row class="mt-4 border-bottom">
          <p class="mb-4">{{ $tkey('description') }}</p>
        </v-row>
        <!-- reset options list -->
        <div class="mt-4 pb-5">
          <v-row>
            <span class="font-weight-bold">
              {{ $tkey('kpis') }}
            </span>
          </v-row>
          <!-- specific fields to set -->
          <v-row v-for="attr in dataToReset" :key="attr.field" class="checkbox-selection-row">
            <v-checkbox
              :input-value="currentSelections[attr.field]"
              class="rtls-checkbox mx-2"
              @change="updateCurrentSelections(attr.field, $event)"
            />
            <span>{{ attr.name }}</span>
          </v-row>
        </div>
      </v-container>
      <template v-slot:footer>
        <page-actions
          :has-data-errors="false"
          :has-data-changes="true"
          :save-disabled="false"
          :show-discard="false"
          :save-btn-text="$t('confirm')"
          @save="addColumns"
        />
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { forEach, map, entries, size } from 'lodash';

export default {
  name: 'AddOptionColumnsModal',
  localizationKey: 'scenarioInputPage.addOptionalColumnsModal',

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    optionalAttrs: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      dataToAdd: [],
      currentSelections: {},
    };
  },

  computed: {
    hasDataErrors() {
      return !size(this.selectedDataToReset);
    },

    selectedDataToReset() {
      const ret = map(entries(this.currentSelections), ([k, v]) => {
        return { field: k, setTo: v };
      });
      return ret;
    },
  },

  watch: {
    optionalAttrs() {
      this.init();
    },
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.dataToReset = this.getDefaultDataToReset();
      this.setDefaultCurrentSelections();
    },

    updateCurrentSelections(field, shouldReset) {
      this.$set(this.currentSelections, field, shouldReset);
    },

    // This returns list of product object keys that can be reset based on business logic
    getDefaultDataToReset() {
      return this.optionalAttrs;
    },

    setDefaultCurrentSelections() {
      forEach(this.dataToReset, attr => {
        this.$set(this.currentSelections, attr.field, attr.value);
      });
    },

    addColumns() {
      this.$emit('add-optional-columns', this.selectedDataToReset);
      this.closeModal();
    },

    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.reset-product-data {
  &__container {
    font-size: 1.2rem;
    max-height: 500px;
  }
}

.border-bottom {
  border-bottom: $assortment-border-colour 1px solid;
}

::v-deep {
  .dialog-card {
    &__footer {
      justify-content: flex-end;
    }
  }
}
</style>
