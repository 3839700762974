import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        VRow,
        [
          _c(
            VCol,
            {
              staticClass: "h-100 pa-0",
              attrs: { justify: "start", "data-id-e2e": "aocExtractPage" },
            },
            [
              _c("stepper", {
                ref: "stepper",
                attrs: {
                  "from-series": "",
                  "step-data": _vm.steps,
                  "series-statuses": _vm.seriesStatus,
                  "extract-type": _vm.extractType,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showPreviousExtracts
            ? _c(
                VCol,
                {
                  staticClass: "pt-0 pr-0",
                  attrs: { justify: "start", cols: "4" },
                },
                [
                  _c("previous-compound-reports", {
                    attrs: {
                      reports: _vm.reports,
                      "extract-type": _vm.extractType,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }