import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: { value: _vm.value, width: "500px" },
      on: { "click:outside": _vm.closeModal },
    },
    [
      _vm.value
        ? _c(
            "dialog-card",
            {
              attrs: { title: _vm.$tkey("modalTitle") },
              on: { close: _vm.closeModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("page-actions", {
                          attrs: {
                            "has-data-errors": _vm.hasDataErrors,
                            "has-data-changes": true,
                            "show-discard": false,
                            "save-btn-text": "saveSettings",
                          },
                          on: { save: _vm.onSave },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "right-btns",
                                fn: function () {
                                  return [
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "primary",
                                        attrs: {
                                          disabled: _vm.isCleanNowDisabled,
                                        },
                                        on: { click: _vm.cleanAttributes },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.$t("actions.cleanNow")) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1001378693
                          ),
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                645118838
              ),
            },
            [
              _c(
                VContainer,
                {
                  staticClass: "pa-0 clean-attributes__container",
                  attrs: { fluid: "" },
                },
                [
                  _c(VRow, { staticClass: "mt-4 border-bottom" }, [
                    _c("p", { staticClass: "mb-4" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.cleanAttributesModalDescription) +
                          "\n        "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-4 pb-5 border-bottom" },
                    [
                      _c(VRow, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tkey("attributes")) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.customAttributes, function (attr) {
                        return _c(
                          VRow,
                          {
                            key: attr.id,
                            staticClass: "checkbox-selection-row",
                          },
                          [
                            _c(VCheckbox, {
                              staticClass: "rtls-checkbox mx-2",
                              attrs: {
                                "input-value": _vm.attributesToClean.has(
                                  attr.id
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.updateCurrentSelections(
                                    attr.id,
                                    $event
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(attr.name))]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    { staticClass: "mt-4 pb-4" },
                    [
                      _c(VCheckbox, {
                        staticClass: "rtls-checkbox mx-2 ml-3",
                        attrs: { "input-value": _vm.autoCleanOnSave },
                        on: {
                          change: function ($event) {
                            _vm.autoCleanOnSave = $event
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "mr-4" }, [
                        _vm._v(_vm._s(_vm.$tkey("autoClean"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }