import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VCard, { staticClass: "step-tab-panel", attrs: { flat: "" } }, [
    _c(
      "div",
      { staticClass: "assortment-table attributes-table d-flex flex-column" },
      [
        _c(
          VContainer,
          { staticClass: "actions-container flex-grow-0" },
          [
            _c(
              VRow,
              [
                _c(
                  VCol,
                  {
                    staticClass:
                      "actions-col d-flex align-center justify-start",
                  },
                  [
                    _c("div", { staticClass: "filter-select-header" }, [
                      _c(
                        "span",
                        { staticClass: "pr-6 br-1" },
                        [
                          _c("span", { staticClass: "info-note" }, [
                            _vm._v(_vm._s(_vm.$tkey("productModellingTitle"))),
                          ]),
                          _vm._v(" "),
                          _c("docs-link", {
                            attrs: { link: "toolguide/110-modelling.html" },
                          }),
                          _vm._v(" "),
                          _c(
                            VBtn,
                            {
                              staticClass: "ml-2",
                              attrs: {
                                primary: "",
                                loading: _vm.isProductModellingJobRunning,
                                disabled: !_vm.notModelledProductsCount,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.initSetupProductModelling()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.$tkey("runProductModelling")) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.copyDecisionsAvailable
                            ? _c(
                                VBtn,
                                {
                                  staticClass: "ml-2 mr-2",
                                  attrs: {
                                    "data-id-e2e": "btnCopyDecisions",
                                    primary: "",
                                    disabled:
                                      _vm.isEditingDisabled ||
                                      _vm.isProductModellingJobRunning ||
                                      !!_vm.notModelledProductsCount,
                                  },
                                  on: { click: _vm.copyDecisions },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$tkey("copyDecisions")) +
                                      "\n              "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  VCol,
                  { staticClass: "actions-col actions-col--search" },
                  [
                    _vm.gridApi
                      ? _c("rtls-search", {
                          attrs: {
                            disabled: _vm.isProductModellingJobRunning,
                            white: "",
                            width: "240px",
                            placeholder: _vm.$tkey("searchPlaceholder"),
                          },
                          on: { input: _vm.onFilterChanged },
                          model: {
                            value: _vm.searchString,
                            callback: function ($$v) {
                              _vm.searchString = $$v
                            },
                            expression: "searchString",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          VContainer,
          { staticClass: "actions-container flex-grow-0" },
          [
            _vm.notModelledProductsCount
              ? _c(VAlert, { attrs: { type: "error", text: "" } }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("validationErrors.modelProducts", [
                          _vm.notModelledProductsCount,
                          _vm.totalParentProductsCount,
                          _vm.establishedProductsCount,
                        ])
                      ) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              VRow,
              { staticClass: "products-table-actions" },
              [
                _c(
                  VCol,
                  { staticClass: "actions-col" },
                  [
                    _vm.showErrorControls
                      ? _c("error-controls", {
                          staticClass: "mt-2 ml-2 d-flex",
                          attrs: {
                            "invalid-rows-message": _vm.$tkey(
                              "mustHaveSisterProductsWarning"
                            ),
                            "is-showing-invalid-rows": _vm.filterInvalidRows,
                          },
                          on: { click: _vm.toggleInvalidRows },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showWarningControls
                      ? _c("warning-controls", {
                          staticClass: "mt-2 ml-2 d-flex",
                          attrs: {
                            "invalid-rows-message": _vm.$tkey(
                              "sisterProductsWarning"
                            ),
                            "is-showing-invalid-rows": _vm.filterWarningRows,
                          },
                          on: { click: _vm.toggleWarningRows },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.needsCDT || _vm.notModelledProductsCount
                      ? _c("div", { staticClass: "mt-2 ml-2 d-flex" }, [
                          _vm.needsCDT
                            ? _c("span", { staticClass: "no-cdt" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("validationErrors.runCDT")) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              VRow,
              { staticClass: "products-table-actions" },
              [
                _vm.isSelectedViewToggleVisible
                  ? _c(VCol, { staticClass: "d-flex justify-start" }, [
                      _c(
                        "div",
                        [
                          _c("rtls-toggle", {
                            attrs: {
                              "left-toggle": _vm.toggle.leftToggle,
                              "right-toggle": _vm.toggle.rightToggle,
                            },
                            on: { input: _vm.setProductsDataToDisplay },
                            model: {
                              value: _vm.selectedView,
                              callback: function ($$v) {
                                _vm.selectedView = $$v
                              },
                              expression: "selectedView",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  VCol,
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "div",
                      [
                        _c("rtls-text-field", {
                          attrs: {
                            disabled:
                              _vm.isEditingDisabled ||
                              _vm.isProductModellingJobRunning,
                            grey: "",
                            width: "140px",
                            placeholder: _vm.$tkey("enterTrend"),
                            rules: _vm.trendRules,
                          },
                          on: {
                            blur: _vm.updateTrendOverrideDisplay,
                            focus: _vm.trendOverrideDisplayValueFocus,
                          },
                          model: {
                            value: _vm.trendOverrideDisplayValue,
                            callback: function ($$v) {
                              _vm.trendOverrideDisplayValue = $$v
                            },
                            expression: "trendOverrideDisplayValue",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      VBtn,
                      {
                        attrs: {
                          primary: "",
                          disabled:
                            _vm.isTrendDisabled ||
                            _vm.isProductModellingJobRunning,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.applyTrend()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$tkey("actions.applyToVisible")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ag-grid-box flex-grow-1" },
          [
            _vm.isLoading
              ? _c("progress-bar")
              : _c("ag-grid-vue", {
                  staticClass: "ag-theme-custom",
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: {
                    "row-data": _vm.productsData,
                    "grid-options": _vm.gridOptions,
                    "does-external-filter-pass": _vm.doesExternalFilterPass,
                    "stop-editing-when-cells-loses-focus": true,
                    "single-click-edit": true,
                  },
                  on: {
                    "cell-value-changed": _vm.handleCellEdit,
                    "grid-ready": _vm.onGridReady,
                  },
                }),
          ],
          1
        ),
        _vm._v(" "),
        _c("page-actions", {
          attrs: {
            "has-data-changes": _vm.hasDataChanges,
            "has-data-errors": _vm.hasDataErrors,
            "save-disabled": _vm.isEditingDisabled,
            "is-discard-enabled": !_vm.isEditingDisabled,
            "save-btn-text": "calculateAndSave",
          },
          on: {
            discard: _vm.discardChanges,
            save: function ($event) {
              return _vm.onSave(false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "right-btns",
              fn: function () {
                return [
                  _c(
                    VBtn,
                    {
                      attrs: {
                        primary: "",
                        disabled: !_vm.canRunCalculations,
                        loading: _vm.runningProductCalculations,
                      },
                      on: { click: _vm.runCalculations },
                    },
                    [_vm._v(_vm._s(_vm.$tkey("calculate")))]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c("dependency-tree-feedback-modal", {
          attrs: {
            value: _vm.dependencyTreeModalOpen,
            results: _vm.dependencyTreeFeedback,
            page: "productModelling",
            "save-btn-text": "proceedWithDeleting",
            "proceed-btn-text": "proceedWithoutDeleting",
            "warning-message": "dependencyTree.productModellingWarning",
            "able-to-proceed-without-deleting": "",
          },
          on: {
            close: _vm.closeDependencyTreeModal,
            proceed: function ($event) {
              return _vm.proceedHandler()
            },
            commit: function ($event) {
              return _vm.commitHandler()
            },
          },
        }),
        _vm._v(" "),
        _c("copy-modelling-decisions-modal", {
          ref: "copyDecisionsModal",
          attrs: {
            value: _vm.copyDecisionsModalOpen,
            "is-processing": _vm.isProcessingCopyDecisions,
          },
          on: {
            cancel: _vm.closeCopyDecisionsModal,
            confirm: _vm.onCopyDecisionsConfirm,
            close: _vm.closeCopyDecisionsModal,
            "diff-change": _vm.updateDiffFromCopy,
          },
        }),
        _vm._v(" "),
        _c("unsaved-data-modal", {
          ref: "unsavedDataModal",
          attrs: { value: _vm.isUnsavedDataModalOpen },
          on: {
            cancel: _vm.closeUnsavedDataModal,
            confirm: _vm.closeUnsavedDataModal,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }