import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "rtls-calendar-picker" },
    [
      _c(
        VMenu,
        {
          attrs: {
            "close-on-content-click": false,
            transition: "scale-transition",
            "min-width": "290px",
            "offset-x": _vm.calculateOffsetX,
            "offset-y": _vm.calculateOffsetY,
            "nudge-bottom": "25",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    VTextField,
                    _vm._g(
                      {
                        class: _vm.boldText ? "bold-text" : "",
                        attrs: { "hide-details": "", disabled: _vm.disabled },
                        on: {
                          input: function ($event) {
                            _vm.$emit("input", _vm.parseDates($event))
                            _vm.setTypingActive()
                          },
                        },
                        model: {
                          value: _vm.datesFormatted,
                          callback: function ($$v) {
                            _vm.datesFormatted = $$v
                          },
                          expression: "datesFormatted",
                        },
                      },
                      on
                    ),
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            VIcon,
                            {
                              attrs: { small: "" },
                              on: {
                                click: function ($event) {
                                  _vm.menu = true
                                },
                              },
                            },
                            [_vm._v("$date-picker")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _vm._v(" "),
          _c(VDatePicker, {
            attrs: {
              "no-title": "",
              scrollable: "",
              range: "",
              "allowed-dates": _vm.allowedDates,
              "first-day-of-week": _vm.firstDayOfWeek,
              events: _vm.events,
            },
            model: {
              value: _vm.dates,
              callback: function ($$v) {
                _vm.dates = $$v
              },
              expression: "dates",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }