/*
 * This module deals with the destroy phase of each vue component.
 * */

import { each, has } from 'lodash';

function destroyReactiveVueProps(instance) {
  // iterate over each of the vue instances data reactive properties and destroy them.
  const props = Object.keys(instance.$data);
  each(props, prop => {
    if (has(instance.$data, 'destroy')) {
      instance.$data[prop].destroy();
    } else {
      instance.$data[prop] = null;
      delete instance.$data[prop];
    }
  });
}

export default {
  destroyReactiveVueProps,
};
