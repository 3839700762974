<template>
  <div ref="main-product-tile" style="display: contents">
    <div
      class="d-flex w-100 h-100 pl-2"
      :class="{
        'spacebreak-disabled': isSpacebreakLocked(spacebreakId),
        'selected-product-list-view': isProductSelected,
      }"
    >
      <highlighted-area v-if="product.isHighlighted" outer-class="w-100" list-view />

      <!-- Dynamic columns -->
      <v-col
        v-for="column in visibleListViewColumns"
        :key="column.id"
        :title="product[column.id]"
        :class="column.cellClass"
      >
        <!-- Display row icons in a separate container, they have more complex layout -->
        <template v-if="column.id === 'rowIcons'">
          <v-icon
            v-if="showMinimumDistribution && product.minimumDistribution > 0"
            color="white"
            size="18"
            height="26px"
            width="26px"
            class="d-flex product-icon"
          >
            {{ 'mdi-speedometer' }}
          </v-icon>
          <div v-else class="product-icon product-icon--hidden  h-100 distribution" />
          <div
            v-if="product.productSeries"
            class="d-flex product product-icon"
            style="cursor:pointer;"
            @click="onProductSeriesIconClick"
          >
            <series-icon />
          </div>
          <div v-else class="h-100 product-icon product-icon--hidden series" />
          <div v-if="hasPallets" class="product-icon">
            <pallet-icon :fill="hasAssignedPallets ? iconPrimary : iconDisabled" />
          </div>
          <div v-else class="product-icon product-icon--hidden h-100 pallet" />
          <div v-if="!inReset" class="d-flex product-icon">
            <not-in-reset-icon color="white" />
          </div>
          <div v-else class="product-icon product-icon--hidden  h-100 reset" />
          <v-icon
            :disabled="
              isSpacebreakLocked(spacebreakId) ||
                isEditingDisabled ||
                !inReset ||
                !canProductBeMoved
            "
            color="white"
            class="product-icon lock-icon"
            :class="{ 'lock-icon__locked': isLocked(product) }"
            @click="toggleLock(product)"
          >
            {{ isLocked(product) ? 'mdi-lock-outline' : 'mdi-lock-open-variant-outline' }}
          </v-icon>
        </template>

        <!-- Display tags in a separate container -->
        <template v-else-if="column.id === 'deltaTag'">
          <product-delta-tag
            :show="getReferenceDelta !== 0 && product.isEligible"
            type="R"
            :delta="getReferenceDelta"
            class="mr-1"
          />
          <assortment-tag :tag-text="getProductTag" class="mr-1" />
          <product-delta-tag
            :show="isOptimised && getOptimisedDelta !== 0 && product.isEligible"
            type="O"
            :delta="getOptimisedDelta"
            class="mr-1"
          />
        </template>

        <!-- Display regular values -->
        <span v-else>
          {{ getColumnValue(column) }}
        </span>
      </v-col>

      <!-- Static product info column -->
      <v-col class="pt-1 pb-1 d-flex justify-end product-info-column">
        <v-icon
          :id="`product-tile-popup-${product.productKey}`"
          class="info-icon mr-1"
          small
          @click="event => openPopup(event)"
        >
          info
        </v-icon>
      </v-col>
    </div>

    <product-draggable-tooltip
      v-if="show"
      :selected-kpis="clientConfig.selectedKpis"
      :product-info="product"
      :product-index="index"
      :pod-and-store-count-info="
        podAndStoreCounts(product, selectedCanvas.clusterId, spacebreaksIndexedByIdByCluster)
      "
      :product-positioning-info="
        extractProductPositioningInfo(
          product,
          selectedCanvas.clusterId,
          getSpacebreaksIndexedById,
          selectedPod
        )
      "
      :settings="tooltipSettings"
      :conditional-product-tile-style="dashboardProductTileSize"
      :image-url="imageUrl"
      :image-title="displayText"
      :image-alt="displayText"
      :spacebreaks="spacebreaksNames"
      :selected-spacebreak="selectedSpacebreak"
      :spacebreak-disabled="isSpacebreakLocked(spacebreakId)"
      :spacebreak-types="spacebreakTypes"
      :change-spacebreak="changeSpacebreak"
      :lock-types="lockTypes"
      :popup-button="popupButton"
      :image-tag-text="getProductTag"
      :product-pallets="productPallets"
      :current-numeric-locale="currentNumericLocale"
      @close-popup="closePopup"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { get, isString, isEmpty, isNil, isNull, isUndefined } from 'lodash';
import { kpis } from '@enums/product-kpis';
import { lockTypes } from '@enums/assortment-product-lock-types';
import { spacebreakTypes } from '@enums/spacebreak-types';
import destroy from '../../utils/destroy';
import productUtils from '../../utils/product-utils';
import productMixin from './product.mixin';

export default {
  localizationKey: 'assortmentCanvasPage.listViewPage',
  mixins: [productMixin], // includes methods extractProductPositioningInfo

  props: {
    product: {
      type: Object,
      required: true,
    },

    groupName: {
      type: String,
      required: true,
    },

    spacebreakId: {
      type: String,
      default: null,
    },

    listViewRef: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    parentEntryItemRef: {
      type: Object,
      required: true,
    },

    openPopupOnLoadForProductId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      tooltipSettings: null,
      show: false,
      zIndex: null,
      get,
      isString,
      lockTypes,
      spacebreakTypes,
      popupButton: {
        label: this.$t('assortmentCanvasPage.tooltip.productDashboard'),
        action: this.openProductDashboard,
      },
      productPallets: [],
      iconPrimary: 'white',
      iconDisabled: '#d9d9d9',
      extraKPIsFormat: {
        wasteAndMarkdown: 'currency',
        wasteLossAndMarkdown: 'currency',
        wasteAndMarkdownPercentage: '0.00%',
        wasteLossAndMarkdownPercentage: '0.00%',
      },
    };
  },

  computed: {
    ...mapState('assortmentCanvas', [
      'referenceCheckpointProducts',
      'popUpZindex',
      'selectedPod',
      'selectedDashboardProduct',
    ]),
    ...mapState('context', ['clientConfig']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapGetters('context', ['getClientConfig']),
    ...mapGetters('furniture', ['getSpacebreaksIndexedById']),
    ...mapGetters('assortmentCanvas', [
      'selectedCanvas',
      'isSpacebreakLocked',
      'canvasProductsPallets',
      'filteredListViewKpiColumns',
      'listViewExtraColumns',
      'visibleListViewColumns',
    ]),

    // helper to get the product display string
    productDisplayString() {
      const displayString = `${this.product.productKeyDisplay}`;
      const hasKPKUEnabled = get(this.getClientConfig, 'features.kpkuEnabled', false);

      if (hasKPKUEnabled) {
        const extraIdentifiers = [this.product.kpku, this.product.futureId]
          .filter(el => !isNil(el))
          .join('/');
        if (extraIdentifiers.length) {
          return `${displayString} (${extraIdentifiers})`;
        }
      }
      return displayString;
    },

    // helper to get the combined UOM display string
    combinedUom() {
      return (
        this.formatNumber({ number: this.product.contentValue, format: 'float' }) +
        this.product.contentUnitOfMeasure
      );
    },

    // helper to get the volume UOM display string
    volumeUom() {
      const volume = this.product.averageTransactionWeight * this.product.contentValue;
      if (!volume) return '';
      return `${this.formatNumber({
        number: volume,
        format: 'float',
      })}${this.product.contentUnitOfMeasure}`;
    },

    isOptimised() {
      return get(this.selectedCanvas, 'hasBeenOptimised', false);
    },

    spacebreaksIndexedByIdByCluster() {
      return this.getSpacebreaksIndexedById[this.selectedCanvas.clusterId];
    },

    displayText() {
      return this.product.itemDescription || this.product.productKeyDisplay;
    },

    dashboardProductTileSize() {
      return {
        width: '80px',
        height: '80px',
      };
    },

    selectedSpacebreak() {
      return productUtils.selectedSpacebreak(
        this.spacebreaksShortNames,
        this.product.currentSpacebreakId
      );
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAssortmentCanvas);
    },

    inReset() {
      return get(this.product, 'inReset', false);
    },

    futureProductIdString() {
      return productUtils.futureProductIdString(this.product.kpku, this.product.futureId);
    },

    hasPallets() {
      return !isEmpty(this.productPallets);
    },

    hasAssignedPallets() {
      return this.hasPallets && this.productPallets.some(p => p.currentSpacebreakId);
    },

    hasPalletContentEnabled() {
      return get(this.getClientConfig, 'features.palletContentEnabled', false);
    },

    getOptimisedDelta() {
      return get(this.product.deltas, 'lastOptimised', 0);
    },

    getReferenceDelta() {
      return get(this.product.deltas, 'reference', 0);
    },

    showMinimumDistribution() {
      return get(this.selectedScenario, 'optionalAttributes.useMinimumDistribution');
    },

    isProductSelected() {
      // there might not be a selected product
      return this.product.productKey === get(this.selectedDashboardProduct, 'productKey', null);
    },
  },

  mounted() {
    // Detrimine if needs to open the popup on render
    if (this.openPopupOnLoadForProductId === this.product._id) {
      // Highlight the product
      const productTile = this.$refs['main-product-tile'];
      productUtils.addActiveSearchBorderToProductElement(productTile);

      // Need to map the position of the element
      const position = {
        clientX: productTile.top,
        clientY: productTile.left + 120,
      };
      this.openPopup(position);
    }
    this.product.futureProductIdString = this.hasKPKUEnabled ? this.futureProductIdString : null;
    this.getProductPallets(this.product.productKeyDisplay);
  },

  beforeDestroy() {
    destroy.destroyReactiveVueProps(this);
  },

  methods: {
    ...mapActions('assortmentCanvas', ['updateCanvasProduct', 'changeLockType']),
    ...mapMutations('assortmentCanvas', ['incrementPopUpZindex', 'setIsProductSidebarOpen']),

    getColumnValue(column) {
      const extraColumnIds = this.listViewExtraColumns.map(col => col.id);

      if (column.id === 'productKeyDisplay') {
        // Return the product display string for the product key column
        return this.productDisplayString;
      }
      if (column.id === 'contentValue') {
        // Return the combined unit of measure for the content value column
        return this.combinedUom;
      }
      if (column.id === 'transactionWeight') {
        // Return the volume unit of measure for the transaction weight column
        return this.volumeUom;
      }
      if (this.filteredListViewKpiColumns.includes(column.id)) {
        // Return the KPI value for columns that are in the filtered list view KPI columns
        return this.getKpiValue(column.id);
      }
      if (extraColumnIds.includes(column.id) && column.type === 'kpi') {
        return this.getExtraKpiValue(column.id);
      }

      // For all other columns, return the corresponding value from the product object
      return this.product[column.id];
    },

    // helper to get KPI value to display in a cell
    getKpiValue(kpi) {
      if (!isNull(this.product[kpi]) && !isUndefined(this.product[kpi])) {
        if (kpi === kpis.MARGIN) {
          if (!this.product[kpi]) return '0%';

          const marginValueToShow =
            this.product[kpi] <= 1 && this.product[kpi] > 0
              ? (this.product[kpi] * 100).toFixed(0)
              : Number(this.product[kpi]).toFixed(0);

          return `${marginValueToShow}%`;
        }

        const val = this.formatNumber({ number: this.product[kpi], format: 'float' });
        const unit = kpi === kpis.VOLUME ? this.product.contentUnitOfMeasure : '';
        return `${val}${unit}`;
      }
    },

    getExtraKpiValue(kpi) {
      if (isNull(this.product[kpi]) || isUndefined(this.product[kpi])) {
        return '';
      }
      return this.formatNumber({ number: this.product[kpi], format: this.extraKPIsFormat[kpi] });
    },

    getProductPallets(productKeyDisplay) {
      this.productPallets = this.canvasProductsPallets[productKeyDisplay];
    },

    isLocked(product) {
      return product.lockType === lockTypes.locked || !this.inReset;
    },

    toggleLock(product) {
      this.changeLockType({ product, isLocked: this.isLocked(product) });
    },

    closePopup() {
      this.show = false;
    },

    openPopup(event) {
      const parentEntryItem = this.parentEntryItemRef['parent-entry-item'][0];

      // Calculate total height of parent entry item row

      const parentEntryItemHeight = parentEntryItem.getBoundingClientRect().height;
      const parentEntryItemWidth = parentEntryItem.getBoundingClientRect().width;

      // Calculate height of main-container
      const listViewContainerHeight = this.listViewRef['list-view-container'].clientHeight;
      // count of list-view containers
      const listViewContainers = document.querySelectorAll('.list-view').length;

      let adjustmentY = 0;
      let adjustmentX = 0;

      // get pallets for the product
      this.getProductPallets(this.product.productKeyDisplay);

      const width = this.clientConfig.canvasPopUpWidth;
      const height = this.clientConfig.canvasPopUpHeight;

      // adjust x and y positioning if tooltip would render outside of window
      // tooltip renders 1/10th width from the right of the entry row
      if (
        window.innerWidth - event.clientX < width + parentEntryItemWidth / 10 ||
        // render popups to left of icon if only one list view container
        listViewContainers < 2
      ) {
        // value below to avoid overlapping with other rows info buttons
        adjustmentX = 30;
      }

      if (
        window.innerHeight - event.clientY < height + parentEntryItemHeight ||
        listViewContainerHeight - event.clientY < height + parentEntryItemHeight
      ) {
        adjustmentY = height - parentEntryItemHeight;
        // if the user clicks near the top of the container and the above conditions are also correct, correct the height of the Y adjustment by adding half of the popup height.
        if (event.clientY < 600) {
          adjustmentY -= height / 2;
        }
        // if the container for list view is small (1 or 2 spacebreaks) move the popup upwards by a third of its width.
        if (listViewContainerHeight < 400) {
          adjustmentY += height / 3;
        }
      }

      this.incrementPopUpZindex();

      this.zIndex = this.popUpZindex;

      this.tooltipSettings = {
        right: 0 + adjustmentX,
        top: 0 - adjustmentY,
        width,
        height,
        zIndex: this.zIndex,
      };
      this.show = true;
    },

    onProductSeriesIconClick() {
      this.setIsProductSidebarOpen(true);
      this.setDashboardProduct(this.product);
      productUtils.scrollToProductInCanvas(this.product);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.list-entry {
  width: 100%;
}

.icons-container-list-view {
  padding: 0;
}

.entry-col {
  font-size: 1.2rem;
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 1rem;
}

.info-icon {
  color: $assortment-primary-colour;
}

.title {
  background-color: $canvas-list-entry-header-colour;
  border-radius: 4px;
  text-transform: uppercase;
}

.entry-item {
  display: flex;
  height: 28px; // affects the highlighted box
  align-items: center;
  position: relative;
  border-radius: 2px;
  border: 1px solid;
  margin-bottom: 5px;
  transition: all 1s ease-out;
}

.neutral-border {
  border-color: black;
}

.spacebreak-disabled {
  opacity: 30%;
}

.product-info-column {
  width: 50px;
}

.product-icon {
  color: $product-icons-colour;
  height: 26px;
  width: 26px;
  margin-right: 1px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--hidden {
    background-color: transparent;
  }
}

.list-view-tags-col {
  overflow: visible;

  ::v-deep {
    span {
      font-weight: 700;
    }
  }
}

.col-short {
  max-width: 70px;
  max-width: 70px;
}

.col-xs {
  max-width: 28px;
  min-width: 28px;
}

.description-col {
  width: 260px;
}

.id-col {
  max-width: 100px;
  min-width: 100px;
}

.lock-icon {
  visibility: hidden;
  transition: 0s;

  &__locked {
    visibility: initial;
  }

  ::v-deep {
    & div {
      height: 17px;
      width: 17px;
    }
  }
}

.entry-item:hover {
  .lock-icon {
    visibility: initial;
  }
}
</style>
