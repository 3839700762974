<template>
  <div class="d-flex justify-start align-center reset__container">
    <v-btn
      class="reset__button"
      :class="{ 'reset__button--hidden': hideReset }"
      :ripple="false"
      icon
      data-id-e2e="resetValueButton"
      @click.stop="reset"
    >
      <reset-icon />
    </v-btn>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="similarityBiasFormatted"
          class="reset__input"
          :class="{ 'reset__input--invalid': isInvalid }"
          :disabled="isSimilarityBiasDisabled"
          data-id-e2e="similarityBiasValueInput"
          reverse
          hide-details
          v-bind="attrs"
          v-on="isInvalid ? on : ''"
          @keydown.left.stop
          @keydown.right.stop
        />
      </template>
      <span>{{ getTooltipText() }}</span>
    </v-tooltip>
    <span class="ml-2">%</span>
    <v-btn
      depressed
      :disabled="cannotRunCalculations"
      :loading="runningCalculationsWithBias"
      secondary
      class="ml-4 outlined-btn"
      @click.stop="runCalculations"
    >
      {{ $t('actions.apply') }}
    </v-btn>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import agGridUtils from '@/js/utils/ag-grid-utils';
import { get, each, some, map } from 'lodash';
import { switchingModellingTypes } from '@enums/switching-modelling-types';

export default Vue.extend({
  name: 'similarityBiasRenderer',
  data() {
    return {
      numberInput: null,
      originalValue: null,
      runningCalculationsWithBias: false,
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),

    similarityBias() {
      return get(this.params, 'data.similarityBias');
    },

    isSimilarityBiasDisabled() {
      const clustersMatrices = this.params.data.cannGroupMatrices.filter(sm => sm.clusterId);
      return some(clustersMatrices, sm => sm.type === switchingModellingTypes.setToUnclustered);
    },

    isInvalid() {
      return some([
        agGridUtils.validations.valueIsNotNumericPermissive({ value: this.similarityBias }),
        agGridUtils.validations.valueIsLessOrEqual(this.similarityBias, 0),
        agGridUtils.validations.valueIsGreaterOrEqual(this.similarityBias, 1),
      ]);
    },

    colId() {
      return get(this.params, 'column.colId');
    },

    hideReset() {
      return !this.originalValue;
    },

    hasChanges() {
      return this.originalValue !== this.similarityBias;
    },

    cannotRunCalculations() {
      return !this.similarityBias || this.isInvalid || !this.hasChanges;
    },

    similarityBiasFormatted: {
      get() {
        const value = agGridUtils.formatters.numericFormatter(this.params);
        return value ? Math.floor(value * 100) : null; // transform to percentage if value is present
      },
      set(newValue) {
        // Setting float value between 0 and 1 from percentage
        this.params.data.similarityBias = Math.ceil(newValue) / 100;
      },
    },
  },

  created() {
    this.originalValue = this.similarityBias;
  },

  methods: {
    ...mapActions('switchingMatrices', ['runSimulationWithBias']),

    reset() {
      this.params.node.setDataValue(this.colId, null);
      const payload = map(this.params.data.cannGroupMatrices, ({ _id }) => ({
        _id,
        method: null,
      }));
      this.params.onSelectionChange(payload);
    },

    getTooltipText() {
      const errors = [];
      const bias = this.params.data.similarityBias;
      if (agGridUtils.validations.valueIsNotNumericPermissive({ value: bias }))
        errors.push(this.$t('validationErrors.number'));
      if (agGridUtils.validations.valueIsLessOrEqual(bias, 0))
        errors.push(this.$t('validationErrors.numberIsLessThan', [0]));
      if (agGridUtils.validations.valueIsGreaterOrEqual(bias, 1))
        errors.push(this.$t('validationErrors.numberMustBeLessThan', [100]));
      return errors.join('\n') || null;
    },

    async runCalculations() {
      this.runningCalculationsWithBias = true;
      // Compose payload for jobapi function
      const payload = {
        scenario_id: this.selectedScenario._id,
        calculations: [
          {
            clusterId: null, // Unclustered case calculations
            cannGroupId: this.params.data.cannGroupId,
            method: switchingModellingTypes.simulate,
            similarityBias: this.params.data.similarityBias,
          },
        ],
      };

      each(this.params.clusters, ({ clusterId, isNoDataCluster }) => {
        // Clusters without data do not have matrices
        if (!isNoDataCluster) {
          payload.calculations.push({
            clusterId,
            cannGroupId: this.params.data.cannGroupId,
            method: switchingModellingTypes.simulate, // TODO define dynamically when possible to choose unclustered method
            similarityBias: this.params.data.similarityBias,
          });
        }
      });

      await this.runSimulationWithBias({ payload });
      // update original value to currently set value
      this.originalValue = this.similarityBias;
      this.runningCalculationsWithBias = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.reset {
  &__container {
    height: 20px;
  }

  &__button {
    &--hidden {
      visibility: hidden;
    }

    &.v-btn.v-btn--icon {
      align-self: center;
      background-color: $assortment-primary-colour;
      border-radius: 0;
      color: $assortment-active-button-colour;
      height: 100%;
      width: 13px;

      svg {
        height: 12px;
        width: 12px;
      }
    }
  }

  &__input {
    height: 100%;
    width: 50px;

    &:not(.diff-background) {
      background: $assortment-input-background;
    }

    ::v-deep {
      .v-text-field__slot {
        height: 18px;

        input {
          padding: 0 5px;
        }
      }
    }

    &--invalid {
      ::v-deep {
        .v-input {
          &__slot:after,
          &__control > .v-input__slot:before {
            border: 1px solid $assortment-table-error-cell-border !important;
          }
        }
      }

      border-color: $assortment-table-error-cell-border;
    }
  }
}
</style>
