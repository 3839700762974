<template>
  <div class="d-flex align-center cell-container" :class="editingModeClass">
    <div v-if="!editingMode" class="displayed-value">{{ displayedValue }}</div>
    <div v-if="isSelected" class="d-flex">
      <div v-for="(item, index) in limits" :key="index" class="d-flex">
        <rtls-text-field
          :key="index"
          v-model="item.value"
          :rules="getRules(index)"
          width="10"
          @rtls-text-field-errors="errors => handleErrors(errors, index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import { isEqual, values, every, isNaN } from 'lodash';
import { assortmentCanvas } from '@enums/client-events';
import { Constraints } from '@enums/assortment-canvases';
import { StoreNamespaces } from '../../../store/constants';
import inputValidationMixin from '../../../mixins/input-validations';

export default Vue.extend({
  name: 'cellValueInputsRenderer',
  mixins: [inputValidationMixin],
  data() {
    return {
      limits: null,
      rules: [this.required, v => this.isGreaterOrEqual(v, 0)],
    };
  },
  computed: {
    ...mapState(StoreNamespaces.assortmentCanvas, ['constraintEditing']),

    editingMode() {
      return (
        this.constraintEditing.constraint.open &&
        isEqual(this.params.node.id, this.constraintEditing.constraint.rowNodeId)
      );
    },

    displayedValue() {
      if (this.params.value[0] !== null && this.params.value[1] !== null) {
        return `${this.params.value[0]} - ${this.params.value[1]}`;
      }
      if (this.params.value[0] !== null) {
        return this.params.value[0];
      }
      return this.params.value[1];
    },

    selectedParameter() {
      return this.constraintEditing.constraint.rowNodeData.parameter;
    },

    isSelected() {
      return this.editingMode && this.params.node.isSelected();
    },

    isGreaterThanFirstInput() {
      // AOV3-1423 TODO: handle localized numbers, add integer validation if always expecting integer
      // dynamically set the second argument of isGreaterThan to the value of the first input
      let firstInput = parseInt(this.limits[0].value || 0, 10);
      if (firstInput < 0 || isNaN(firstInput)) firstInput = 0;
      const secondInput = parseInt(this.limits[1].value || 1, 10);
      if (secondInput < 0 || isNaN(secondInput)) return () => this.isGreaterOrEqual(secondInput, 0);
      return () => this.isGreaterThan(secondInput, firstInput);
    },

    isLessThanSecondInput() {
      // dynamically set the second argument of isLessThan to the value of the second input
      const firstInput = parseInt(this.limits[0].value || 0, 10);
      if (firstInput < 0 || isNaN(firstInput)) return () => this.isGreaterOrEqual(firstInput, 0);
      let secondInput = parseInt(this.limits[1].value || 1, 10);
      if (secondInput < 0 || isNaN(secondInput)) secondInput = 1;
      return () => this.isLessThan(firstInput, secondInput);
    },

    editingModeClass() {
      return this.editingMode && this.isSelected ? 'editing-padding-top' : '';
    },
  },
  watch: {
    limits: {
      handler() {
        if (this.isSelected) {
          this.updateData();
        }
      },
      deep: true,
    },
    selectedParameter() {
      this.init();
    },
    editingMode() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations(StoreNamespaces.assortmentCanvas, [
      'setConstraintRowNodeData',
      'updateConstraintRowNodeData',
    ]),
    init() {
      let filteredResult;

      if (this.selectedParameter === Constraints.AtLeast) {
        filteredResult = this.params.value.slice(0, 1).map(x => {
          const value = x === null ? '' : x;
          return { value };
        });
      } else if (this.selectedParameter === Constraints.Between) {
        filteredResult = this.params.value.map(x => {
          const value = x === null ? 1 : x;
          return { value };
        });
      } else if (this.selectedParameter === Constraints.AtMost) {
        filteredResult = this.params.value.slice(1, 2).map(x => {
          const value = x === null ? '' : x;
          return { value };
        });
      }
      this.limits = filteredResult;
    },
    updateData() {
      let newData;
      if (this.selectedParameter === Constraints.AtLeast) {
        newData = [parseInt(this.limits[0].value, 10), null];
      } else if (this.selectedParameter === Constraints.Between) {
        newData = [parseInt(this.limits[0].value, 10), parseInt(this.limits[1].value, 10)];
      } else if (this.selectedParameter === Constraints.AtMost) {
        newData = [null, parseInt(this.limits[0].value, 10)];
      }
      this.updateConstraintRowNodeData({ data: newData, field: this.params.field });
    },
    getRules(index) {
      if (this.selectedParameter === Constraints.Between && index === 0) {
        return [...this.rules, this.isLessThanSecondInput];
      }
      if (this.selectedParameter === Constraints.Between && index === 1) {
        return [...this.rules, this.isGreaterThanFirstInput];
      }
      return this.rules;
    },
    handleErrors(errors, index) {
      const errorValues = values(errors);
      const valuesAreValid = every(errorValues, v => v === true);
      // when values are all true, the validation passes. If there is one false, the validation fails.
      this.globalEmit(assortmentCanvas.constraintsValidationOccurred, {
        // there can be 2 text fields, hence the property name including the index
        [`textFieldValid${index}`]: valuesAreValid || errorValues.length === 0,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.cell-container {
  width: 100%;
}

.displayed-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.v-btn {
  padding-bottom: 0 !important;
}
</style>
