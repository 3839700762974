<template>
  <div :key="`comparisonwaterfall-${isSidebarShown}`" class="h-100 d-flex flex-column">
    <reporting-section :short-view="shortView">
      <template v-slot:header>
        <reporting-header :short-view="shortView" :section="section" :title="$tkey('title')">
          <template v-slot:metric>
            <rtls-select
              :value="metricValue"
              :items="allowedMetrics"
              :placeholder="$t('general.select')"
              :disabled="isComparisonWaterfallReportDisabled"
              item-text="text"
              item-value="type"
              white
              width="240px"
              class="mr-3"
              @input="value => onSelectionChanged('metricValue', value)"
            />
          </template>
          <template v-slot:filter>
            <filters
              :filters="filters[section]"
              :filter-options="filterOptions"
              :disabled="isComparisonWaterfallReportDisabled || filtersDisabled"
              :btn-text="filterButtonText"
              class="ml-3"
              @change="handleFilterChange"
              @apply="fetchData"
            />
          </template>
        </reporting-header>
      </template>
      <template v-slot:main-section>
        <reporting-main-section
          :section="section"
          :short-view="shortView"
          :subtitle="$tkey('subtitle')"
        >
          <template v-slot:data-section>
            <span v-if="isComparisonWaterfallReportDisabled" class="not-available">
              {{ $t('reportingPage.noPermissions') }}
            </span>
            <progress-bar v-else-if="isLoading || isProcessingData" />
            <span v-else-if="hasInvalidComparison">
              {{ $tkey('invalidComparison') }}
            </span>
            <span v-else-if="hasInvalidComparisonSelections" class="not-available">
              {{ $t('reportingPage.notAvailableForComparisons') }}
            </span>
            <span v-else-if="hasInvalidMetric">
              {{ $tkey('invalidMetric') }}
            </span>
            <template v-else>
              <highcharts
                :options="chartOptions"
                class="chart-container"
                :style="{ width: shortView ? '100%' : '50%' }"
              />
              <div
                v-if="!shortView"
                class="ag-grid-box d-flex mx-5 pl-2 h-100 rtls-border rtls-border--left"
              >
                <ag-grid-vue
                  animate-rows
                  dense
                  class="ag-theme-custom w-100"
                  :column-defs="headers"
                  :row-data="tableData"
                  :grid-options="gridOptions"
                  @grid-ready="onGridReady"
                />
              </div>
            </template>
          </template>
        </reporting-main-section>
      </template>
      <template v-slot:footer>
        <reporting-footer
          :short-view="shortView"
          :is-export-disabled="!hasAvailableChartData"
          @export="exportCSV"
        />
      </template>
    </reporting-section>
  </div>
</template>

<script>
import i18n from '@/js/vue-i18n';
import reportingMetrics from '@enums/reporting-metrics';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import reportingSections from '@enums/reporting-sections';
import to from 'await-to-js';
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import { isEqual, pick, isEmpty, omit, startsWith, find, endsWith, size, map, every } from 'lodash';
import owColours from '@/js/ow-colors';
import exportCSV from '@/js/mixins/export-csv';
import agGridUtils from '@/js/utils/ag-grid-utils';

// initiliase extra (more) modules to enable waterfall chart
highchartsMore(Highcharts);
const localizationKey = 'reportingPage.sections.comparison-waterfall';
const t = key => i18n.t(`${localizationKey}.${key}`);

const CHARTS_LOOKUP = {
  [reportingMetrics.metrics.sales]: {
    numberFormat: 'currency',
    field: 'totalSales',
  },
  [reportingMetrics.metrics.margin]: {
    numberFormat: 'currency',
    field: 'totalMargin',
  },
  [reportingMetrics.metrics.volume]: {
    numberFormat: 'float',
    field: 'totalVolume',
  },
  [reportingMetrics.metrics.products]: {
    numberFormat: 'integer',
    field: 'totalProducts',
  },
  [reportingMetrics.metrics.stockingPoints]: {
    numberFormat: 'float',
    field: 'totalPointOfDistribution',
  },
  [reportingMetrics.metrics.linearSpace]: {
    numberFormat: 'float',
    field: 'totalSize',
  },
  [reportingMetrics.metrics.cubicSpace]: {
    numberFormat: 'float',
    field: 'totalSize',
  },
  [reportingMetrics.metrics.frontalSpace]: {
    numberFormat: 'float',
    field: 'totalSize',
  },
  [reportingMetrics.metrics.horizontalSpace]: {
    numberFormat: 'float',
    field: 'totalSize',
  },
};

export default {
  localizationKey,

  components: {
    AgGridVue,
  },

  mixins: [exportCSV],

  props: {
    // represents whether this report is open on (short) overview page
    shortView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      tableData: [],
      chartData: [],
      totalBaseline: 0,
      totalSumInComparison: 0,
      totalComparison: 0,
      isProcessingData: true,
      section: reportingSections.comparisonWaterfall,
      gridApi: null,
      columnApi: null,
      gridOptions: {
        rowHeight: 30,
        headerHeight: 40,
        suppressContextMenu: true,
        suppressPropertyNamesCheck: true,
        defaultColDef: {
          editable: false,
          sortable: false,
          filter: false,
          menuTabs: [],
          suppressMovable: true, // do not make headers movable
        },
      },
      fullChartAxis: [
        {
          color: owColours.reports.baseline,
          field: 'baseline',
          showInLegend: true,
        },
        {
          color: owColours.comparisonWaterfall.distributionBar,
          field: 'newProduct',
        },
        {
          color: owColours.comparisonWaterfall.distributionBar,
          field: 'newInCluster',
        },
        {
          color: owColours.comparisonWaterfall.distributionBar,
          field: 'increasedDistribution',
        },
        {
          color: owColours.comparisonWaterfall.distributionBar,
          field: 'maintainedDistributionUp',
        },
        {
          color: owColours.comparisonWaterfall.distributionBar,
          field: 'maintainedDistributionDown',
        },
        {
          color: owColours.comparisonWaterfall.distributionBar,
          field: 'decreasedDistribution',
        },

        {
          color: owColours.comparisonWaterfall.distributionBar,
          field: 'removedFromCluster',
        },
        {
          isSum: true,
          color: owColours.reports.comparison,
          showInLegend: true,
        },
      ],
      shortChartAxis: [
        {
          color: owColours.reports.baseline,
          field: 'baseline',
          showInLegend: true,
        },
        {
          color: owColours.comparisonWaterfall.distributionBar,
          fields: ['newProduct', 'newInCluster'],
        },
        {
          color: owColours.comparisonWaterfall.distributionBar,
          fields: ['increasedDistribution'],
        },
        {
          color: owColours.comparisonWaterfall.distributionBar,
          fields: ['maintainedDistributionUp', 'maintainedDistributionDown'],
        },
        {
          color: owColours.comparisonWaterfall.distributionBar,
          fields: ['decreasedDistribution', 'removedFromCluster'],
        },
        {
          isSum: true,
          color: owColours.reports.comparison,
          showInLegend: true,
        },
      ],
      notAllowedMetrics: [
        'products',
        'linearSpace',
        'cubicSpace',
        'horizontalSpace',
        'frontalSpace',
      ],
    };
  },

  computed: {
    ...mapState('reporting', [
      'loadingSubsection',
      'baselineIds',
      'comparisonIds',
      'comparison2Ids',
      'metricValue',
      'filters',
      'isSidebarShown',
    ]),
    ...mapGetters('reporting', [
      'getSelectionFromId',
      'enabledMetrics',
      'filterOptions',
      'isAnySelectionObserved',
    ]),
    ...mapGetters('context', ['getCsvExport', 'getDateFormats']),

    isComparisonWaterfallReportDisabled() {
      return !this.hasPermission(this.userPermissions.canViewComparisonWaterfallReport);
    },

    isLoading() {
      return this.loadingSubsection.comparisonWaterfallTable;
    },

    hasInvalidComparisonSelections() {
      return !!size(this.comparison2Ids) || this.isAnySelectionObserved;
    },

    hasAvailableChartData() {
      return !isEmpty(this.chartData) && !!size(this.baselineIds) && !!size(this.comparisonIds);
    },

    chartOptions() {
      const vm = this;
      const numberFormatter = number =>
        this.formatNumber({ number, format: CHARTS_LOOKUP[this.metricValue].numberFormat });

      return {
        chart: {
          type: 'waterfall',
        },

        title: {
          text: null,
        },

        xAxis: this.getxAxis(),
        legend: { enabled: false },
        credits: { enabled: false },
        yAxis: {
          title: {
            text: this.shortView ? this.metricText : this.$tkey('chart.yAxis'),
          },
          labels: {
            enabled: true,
          },

          plotLines: isEmpty(this.chartData)
            ? []
            : [
                {
                  value: this.totalBaseline,
                  width: 2,
                  color: owColours.comparisonWaterfall.plotLine.baseline,
                },
                {
                  value: this.totalComparison,
                  width: 2,
                  color: owColours.comparisonWaterfall.plotLine.comparison,
                },
              ],
        },

        tooltip: {
          formatter() {
            return `<b>${vm.metricText}</b>:${numberFormatter(this.y)}`;
          },
        },

        series: this.chartData,
      };
    },

    hasInvalidComparison() {
      const fieldsToCompare = ['scenarioId', 'storeClassId', 'clusterId'];
      const baseline = this.getSelectionFromId(this.baselineIds) || [];
      const comparison = this.getSelectionFromId(this.comparisonIds) || [];

      // All baseline selections should have the same fields to compare
      const baselineFields = map(baseline, b => pick(b, fieldsToCompare));
      const hasValidBaselines = every(baselineFields, bf => isEqual(bf, baselineFields[0]));
      if (!size(baselineFields) || !hasValidBaselines) return true;

      // All comparison selections should have the same fields to compare
      const comparisonFields = map(comparison, c => pick(c, fieldsToCompare));
      const hasValidComparisons = every(comparisonFields, cf => isEqual(cf, comparisonFields[0]));
      if (!size(comparisonFields) || !hasValidComparisons) return true;

      return !isEqual(baselineFields[0], comparisonFields[0]);
    },

    metricText() {
      return find(this.enabledMetrics, { type: this.metricValue }).text;
    },

    headers() {
      return [
        {
          headerName: this.$tkey('table.productKey'),
          minWidth: 80,
          resizable: true,
          field: 'productKeyDisplay',
          menuTabs: ['filterMenuTab'],
          filter: true,
        },
        {
          headerName: this.$tkey('table.description'),
          minWidth: 100,
          field: 'description',
          width: 400,
          resizable: true,
        },
        {
          headerName: this.$tkey('table.classification'),
          minWidth: 150,
          field: 'classification',
          resizable: true,
          menuTabs: ['filterMenuTab'],
          filter: true,
          valueFormatter: params => this.getClassification(params),
          filterParams: {
            valueFormatter: params => this.getClassification(params),
          },
        },
        {
          headerName: this.$tkey('table.storeCount'),
          minWidth: 50,
          resizable: true,
          field: 'storeCount',
          valueFormatter: params => {
            return this.formatNumber({ number: params.value, format: 'float' });
          },
        },
        {
          headerName: `Δ ${this.metricText}`,
          field: 'metricValue',
          minWidth: 100,
          resizable: true,
          valueFormatter: params => {
            return this.formatNumber({
              number: params.value,
              format: CHARTS_LOOKUP[this.metricValue].numberFormat,
            });
          },
        },
      ];
    },

    allowedMetrics() {
      return this.enabledMetrics.filter(m => !this.notAllowedMetrics.includes(m.type));
    },

    hasInvalidMetric() {
      return this.notAllowedMetrics.includes(this.metricValue);
    },

    filtersDisabled() {
      return !this.hasAvailableChartData && this.isLoading;
    },

    filterButtonText() {
      return `${this.$tc(`reportingPage.filters`, this.filters[this.section].length)}`;
    },

    visiblexAxis() {
      if (this.shortView) return this.shortChartAxis;

      return this.fullChartAxis;
    },
  },

  watch: {
    baselineIds(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchData();
    },

    comparisonIds(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchData();
    },

    comparison2Ids(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchData();
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    ...mapActions('reporting', ['fetchReportingData']),
    ...mapMutations('reporting', ['setSelection', 'setReportingFilters']),

    async fetchData() {
      // user must select both baseline and comparison in order to have a waterfall result
      if (
        this.hasInvalidComparisonSelections ||
        this.hasInvalidComparison ||
        this.hasInvalidMetric
      ) {
        this.isProcessingData = false;
        return;
      }

      this.isProcessingData = true;
      const [err, response] = await to(
        this.fetchReportingData({
          section: this.section,
          reportArea: 'table',
          productFilters: this.filters[this.section],
        })
      );

      this.tableData = err ? [] : response.tableData;
      this.chartData = err ? [] : this.getSeries(response.chartData);
      this.totalBaseline = err ? 0 : response.chartData.baseline;
      this.totalComparison = err ? 0 : response.chartData.comparison;
      this.isProcessingData = false;
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      params.api.sizeColumnsToFit();
    },

    getSeries(chartData) {
      const data = this.visiblexAxis.map(axis => {
        // is comparison area
        if (axis.isSum) return axis;
        let y = chartData[axis.field];
        if (Array.isArray(axis.fields)) {
          y = axis.fields.reduce((total, field) => total + chartData[field], 0);
        }

        return {
          ...omit(axis, ['field', 'fields']),
          y,
        };
      });

      const numberFormatter = number => this.formatNumber({ number, format: 'numberShorthand' });

      const dataLabels = {
        enabled: true,
        formatter() {
          return `${numberFormatter(this.y)}`;
        },
      };

      return [{ data, pointPadding: 0, showInLegend: true, dataLabels }];
    },

    async onSelectionChanged(field, value) {
      await this.setSelection({ field, value });
      // If previously we had an invalid selection then we should fetch data
      if (!this.hasInvalidComparison || !this.hasInvalidComparisonSelections) {
        await this.fetchData();
      }
      this.navigateToNewUrl();
    },

    // include new query to url param
    navigateToNewUrl() {
      const newQueryValues = {
        ...this.$route.query,
        metricValue: this.metricValue,
      };

      this.$router.push({
        path: this.$route.path, // keep on same path
        query: newQueryValues,
      });
    },

    getClassification(params) {
      const baseTranslation = this.$tkey(`table.${params.value}`);
      if (startsWith(params.value, 'maintained')) {
        return `${baseTranslation} ${this.metricText}`;
      }
      return baseTranslation;
    },

    handleFilterChange(filters) {
      this.setReportingFilters({ section: this.section, filters });
    },

    getxAxis() {
      const vm = this;
      if (!this.shortView) {
        return [
          {
            rotation: 90,
            categories: [
              this.$t('reportingPage.baseline'),
              this.$tkey('chart.newProduct'),
              this.$tkey('chart.newInCluster'),
              // the next four have added arrows, so we format them differently
              'distributionUp',
              'maintainedUp',
              'maintainedDown',
              'distributionDown',
              this.$tkey('chart.removedFromCluster'),
              this.$t('reportingPage.comparison'),
            ],
            plotBands: [
              {
                color: owColours.comparisonWaterfall.plotBands.nonMaintained,
                from: 0.6,
                to: 2.4,
              },

              {
                color: owColours.comparisonWaterfall.plotBands.nonMaintained,
                from: 2.6,
                to: 3.4,
              },

              {
                color: owColours.comparisonWaterfall.plotBands.maintained,
                from: 3.6,
                to: 5.4,
              },
              {
                color: owColours.comparisonWaterfall.plotBands.nonMaintained,
                from: 5.6,
                to: 7.4,
              },
            ],
            labels: {
              useHTML: true,
              formatter() {
                let icon = '';
                // add arrows to each axis when allowed
                if (endsWith(this.value, 'Down')) icon = 'arrow-down';
                if (endsWith(this.value, 'Up')) icon = 'arrow-up';
                if (isEmpty(icon)) return this.value;
                let text = t(`chart.${this.value}`);
                if (startsWith(this.value, 'maintained')) {
                  text = vm.metricText;
                }

                return `<div><i class="fa fa-${icon}"></i> ${text}</div>`;
              },
            },
          },
          {
            linkedTo: 0, // top axis
            categories: [
              '', // each empty space is a break in xAxis
              this.$tkey('chart.buckets.modelled'),
              '',
              this.$tkey('chart.buckets.increased'),
              '',
              this.$tkey('chart.buckets.maintained'),
              '',
              this.$tkey('chart.buckets.decreased'),
            ],
            tickPositions: [1, 3, 5, 7],
            tickInterval: 0.5,
            opposite: true, // show four buckets at the top
            rotation: 90,
          },
        ];
      }

      return {
        categories: [
          this.$t('reportingPage.baseline'),
          this.$tkey('chart.buckets.modelled'),
          this.$tkey('chart.buckets.increased'),
          this.$tkey('chart.buckets.maintained'),
          this.$tkey('chart.buckets.decreased'),
          this.$t('reportingPage.comparison'),
        ],
      };
    },

    getAllExportableDataColumns() {
      return this.columnApi.getAllDisplayedColumns();
    },

    processCellCallback(params) {
      // Being called for each cell

      if (params.column.colId === 'storeCount') {
        return this.formatNumber({ number: params.value, format: 'float' });
      }

      if (params.column.colId === 'metricValue') {
        return this.formatNumber({
          number: params.value,
          format: CHARTS_LOOKUP[this.metricValue].numberFormat,
        });
      }

      if (params.column.colId === 'classification') {
        return this.getClassification(params);
      }

      return agGridUtils.utils.processCellForExport(params);
    },

    exportCSV() {
      const exportParams = {
        fileName: this.getFileName({
          serviceName: 'comparison-waterfall-report',
          fileNameDateFormat: this.getDateFormats.csvFileName,
        }),
        suppressQuotes: this.getCsvExport.suppressQuotes,
        columnSeparator: this.getCsvExport.columnSeparator,
        columnKeys: this.getAllExportableDataColumns(),
        processCellCallback: this.processCellCallback,
      };

      this.gridApi.exportDataAsCsv(exportParams);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.chart-container {
  height: 100%;
}

.ag-grid-box {
  width: 50%;
}

::v-deep {
  .fa-arrow-up {
    color: $success-colour;
  }

  .fa-arrow-down {
    color: $error-colour;
  }
}

.not-available {
  margin: auto;
}
</style>
