const mixin = {
  methods: {
    downloadFromBuffer(buffer, fileName) {
      if (!buffer) return;
      const blob = new Blob([buffer]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      link.remove();
    },
  },
};

export default mixin;
