<template>
  <div ref="tile-view-container" :style="{ display: 'contents' }">
    <!-- Render a grid item for each spacebreak, then filter the products by that spacebreakId -->
    <div
      v-for="spacebreak in spacebreaks"
      :key="spacebreak._id"
      class="grid-item-border assigned-products-bay flexed-white-bg-grid-item"
    >
      <div :style="productsContainerStyle" class="inside-box">
        <product-tile-drag-container
          v-if="tileViewContainerRef"
          :spacebreak-id="spacebreak._id"
          :group-name="groupName"
          :filtered-products="productsPerSpacebreakInCdtNode[spacebreak._id] || []"
          class="d-flex"
          :selected-tile-size="selectedTileSize"
          :tile-view-ref="tileViewContainerRef"
        />
      </div>
    </div>
    <!-- Render one grid item for each spacebreak type at the bottom of the grid -->
    <div
      v-for="spacebreak in spacebreakTypes"
      :id="`${spacebreak}-spacebreak-tile`"
      :key="spacebreak"
      class="grid-item-border flexed-white-bg-grid-item"
    >
      <div :style="productsContainerStyle" class="inside-box">
        <product-tile-drag-container
          v-if="tileViewContainerRef"
          :container-id="spacebreak"
          :spacebreak-id="null"
          :group-name="groupName"
          :filtered-products="productsWithNoSpacebreak[spacebreak]"
          :selected-tile-size="selectedTileSize"
          :tile-view-ref="tileViewContainerRef"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { groupBy, filter, isEmpty } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { spacebreakTypes } from '@enums/spacebreak-types';
import destroy from '../../utils/destroy';

export default {
  props: {
    products: {
      required: true,
      type: Array,
    },
    groupName: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      tileViewContainerRef: null,
      spacebreakTypes,
    };
  },

  computed: {
    ...mapState('assortmentCanvas', [
      'selectedTileSize',
      'tileSize',
      'spacebreaks',
      'selectedView',
      'unifiedColumns',
    ]),
    ...mapGetters('assortmentCanvas', ['sortByProductsInCanvas', 'getProductsWithNoSpacebreak']),

    filteredProducts() {
      return filter(this.products, product => isEmpty(product.palletContent));
    },

    productsPerSpacebreakInCdtNode() {
      return groupBy(this.filteredProducts, 'currentSpacebreakId');
    },

    productsWithNoSpacebreak() {
      return this.getProductsWithNoSpacebreak(this.productsPerSpacebreakInCdtNode.null);
    },

    productsContainerStyle() {
      if (this.unifiedColumns[this.selectedView]) return {};
      return {
        'min-width': `${this.tileSize[this.selectedTileSize].tileContainer['min-width']}px`,
        'max-width': `${this.tileSize[this.selectedTileSize].tileContainer['max-width']}px`,
      };
    },
  },

  beforeDestroy() {
    destroy.destroyReactiveVueProps(this);
  },

  mounted() {
    this.tileViewContainerRef = this.$refs;
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.inside-box {
  transition: all 1s ease;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: flex-start;
  width: 100%;
}
</style>
