<template>
  <v-card class="step-tab-panel" flat>
    <v-container class="cann-groups-container d-flex flex-column pa-0 ma-0">
      <v-row class="header ma-0 flex-grow-0">
        <v-col class="flex-grow-0 header__title">
          <span class="info-note">{{ $t('scenarioCannGroups.cannGroupsSetup') }}</span>

          <!-- Cann groups tooltip -->
          <docs-link link="toolguide/060-canngroups.html" />
        </v-col>

        <v-col
          v-if="showTreeStructureTrimmedWarning"
          class="flex-grow-0 pa-0 ml-2 mr-3 align-self-center"
        >
          <v-alert class="alert--small mb-0" type="info" text>
            {{ $t('scenarioCannGroups.treeStructureTrimmedWarning') }}
          </v-alert>
        </v-col>
      </v-row>

      <div class="container-block flex-grow-1">
        <div class="tree ma-0">
          <div class="title-container">
            <div class="d-flex align-center">
              <div
                class="attribute-value attribute-value--all-products d-flex align-center float-left"
              >
                <p>{{ $t('scenarioCannGroups.allProducts') }}</p>
              </div>
            </div>
          </div>

          <div class="treeview-container">
            <v-treeview
              v-if="treeData"
              dense
              class="rtls-treeview"
              item-key="key"
              :items="treeDataArray"
              :open="openedNodes"
              :open-all="expandAll"
              @update:open="setOpenNodes"
            >
              <template v-slot:label="{ item: node }">
                <!-- Small horizontal line -->
                <div class="node-line" />

                <div v-if="node.attributeValues.length > 1" class="node-group-line" />

                <!-- Main holder for attribute value(s) -->
                <div class="attributes-box d-flex flex-column">
                  <draggable
                    :options="{ disabled: isEditingDisabled }"
                    :list="getList(node)"
                    ghost-class="ghost"
                    :group="node.attributeId"
                    handle=".drag-handle"
                    @change="mergeGroup($event, node)"
                  >
                    <!-- Loop here if this is a merge of several attributes -->
                    <div
                      v-for="val in node.attributeValues"
                      :key="val"
                      class="attribute-value d-flex align-center justify-space-between mb-1"
                      :class="{
                        hidden: node.attributeValues.length < 1,
                        draggable: !isEditingDisabled,
                        'drag-disabled': isEditingDisabled,
                      }"
                    >
                      <p>
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <span v-on="on">
                              {{ val || $t('scenarioCannGroups.blankAttributeValue') }}
                            </span>
                          </template>
                          {{ val || $t('scenarioCannGroups.blankAttributeValue') }}
                        </v-tooltip>
                      </p>
                      <!-- Div here to put icons together -->
                      <div class="mr-1">
                        <v-icon
                          v-if="node.attributeValues.length > 1"
                          :disabled="isEditingDisabled"
                          size="14"
                          @click="splitGroup(node, val)"
                        >
                          mdi-call-split
                        </v-icon>
                        <v-icon class="drag-handle" size="10">$cross-move</v-icon>
                      </div>
                    </div>
                  </draggable>
                </div>

                <!-- Product count and percentage box -->
                <v-tooltip
                  :disabled="!displayCannGroupHighlighting(node.products.length, node.children)"
                  content-class="rtls-tooltip"
                  max-width="160px"
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <span
                      class="attribute-products align-self-start"
                      :class="{
                        'attribute-products-highlighted': displayCannGroupHighlighting(
                          node.products.length,
                          node.children
                        ),
                      }"
                      v-on="on"
                    >
                      <b class="mr-1 ml-1">{{ node.products.length }}</b>
                      {{ formatSalesRatio(node, treeData) }}%
                    </span>
                  </template>
                  <span>
                    {{
                      $t('scenarioCannGroups.productSizeWarning', [
                        getCannGroupsRecommendedSizes.min,
                        getCannGroupsRecommendedSizes.max,
                      ])
                    }}
                  </span>
                </v-tooltip>

                <!-- Drop down for attribute splitter -->
                <v-select
                  :disabled="isEditingDisabled"
                  :class="
                    getValue(node.children)
                      ? 'attribute-is-split-select'
                      : 'attribute-not-split-select'
                  "
                  class="treeview-select select  mb-1"
                  item-text="name"
                  item-value="id"
                  hide-details
                  single-line
                  :items="getAttributesToSelect(node.key)"
                  :value="getValue(node.children)"
                  :label="$t('scenarioCannGroups.selectAttribute')"
                  :menu-props="{ bottom: true, offsetY: true }"
                  @change="modifyTree($event, node)"
                >
                  <template v-slot:selection="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">
                          {{ item.name }}
                        </span>
                      </template>
                      {{ item.name }}
                    </v-tooltip>
                  </template>
                  <template slot="append">
                    <v-icon size="28">expand_more</v-icon>
                  </template>
                </v-select>

                <!-- Delete button -->
                <v-btn
                  v-if="hasNodes(node.children)"
                  :disabled="isEditingDisabled"
                  class="align-self-start mb-1"
                  icon
                  text
                  @click="deleteNode(node)"
                >
                  <v-icon>$trash</v-icon>
                </v-btn>
              </template>
            </v-treeview>
          </div>

          <!-- Loading overlay -->
          <v-overlay :value="isTreeLoading" :absolute="true" :opacity="0.1">
            <v-progress-circular indeterminate size="32" />
          </v-overlay>
        </div>

        <sidepanel
          v-if="showSidepanel"
          :title="$t('cannGroupsSidepanel.title')"
          :toggle-btn-text="$t('cannGroupsSidepanel.toggleBtn')"
        >
          <v-form ref="leafNodeForm" v-model="valid" autocomplete="off" @submit.prevent>
            <draggable
              :disabled="isEditingDisabled"
              class="list-group"
              ghost-class="ghost"
              handle=".draggable__icon"
              :list="orderedLeafNodes"
              @change="setCustomLeafNodesOrder"
            >
              <div
                v-for="leafNode in orderedLeafNodes"
                :key="leafNode.key"
                class="list-group__item"
                :class="{ 'drag-disabled': isEditingDisabled }"
              >
                <v-icon :class="{ draggable__icon: !isEditingDisabled }" size="12">
                  $cross-move
                </v-icon>
                <v-tooltip left :disabled="isEditing || !leafNode.pathName">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model.trim="leafNode.name"
                      class="list-group__input rtls-text-field"
                      single-line
                      :rules="rules"
                      :disabled="!leafNode.pathName || isEditingDisabled"
                      v-on="on"
                      @input="revalidateForm"
                      @focus="highlightInputText"
                      @blur="toggleEditing"
                      @keyup.enter="$event.target.blur()"
                    />
                  </template>
                  <span>{{ leafNode.pathName }}</span>
                </v-tooltip>
                <cann-group-note :leaf-node="leafNode" />
              </div>
            </draggable>
          </v-form>
        </sidepanel>
      </div>

      <div
        data-id-e2e="btnsCannGroups"
        class="page-actions-container d-flex flex-end flex-row"
        style="justify-content: flex-end"
      >
        <page-actions
          :has-data-changes="hasDataChanges || hasSingleCannGroup"
          :has-data-errors="hasDataErrors"
          :save-disabled="isEditingDisabled"
          :is-discard-enabled="!isEditingDisabled"
          @discard="discard"
          @save="initCanngroupSave"
        />
        <error-triangle
          :errors="{
            [$t('scenarioCannGroups.errorMessages.cannGroupMinSize')]: cannGroupMinimumSizeNotMet,
          }"
          :arrow="false"
          :tooltip-positioning="{ top: true }"
          style="padding-right: 2rem;"
        />
      </div>

      <dependency-tree-feedback-modal
        :value="dependencyTreeModalOpen"
        :results="dependencyTreeFeedback"
        page="cannGroups"
        @close="closeDependencyTreeModal"
        @commit="saveCannGroups(true)"
      />

      <unsaved-data-modal
        ref="unsavedDataModal"
        :value="isUnsavedDataModalOpen"
        @cancel="closeUnsavedDataModal"
        @confirm="closeUnsavedDataModal"
      />

      <!-- confirmation dialog -->
      <main-dialog
        ref="confirm"
        :title="$t('scenarioCannGroups.dialogTitle')"
        :message="$t('scenarioCannGroups.dialogMessage')"
      >
        <template v-slot:actions="{ cancel: close }">
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn :disabled="isEditingDisabled" action @click="[saveCannGroups(), close()]">
                {{ $t('actions.ok') }}
              </v-btn>
              <v-btn :disabled="isEditingDisabled" class="ml-2" @click="close">
                {{ $t('actions.cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </main-dialog>
    </v-container>
  </v-card>
</template>

<script>
import {
  map,
  uniq,
  first,
  differenceWith,
  difference,
  isEqual,
  cloneDeep,
  isArray,
  isNull,
  size,
  get,
  isUndefined,
  isEmpty,
  pull,
  partition,
  set,
  sortedIndexBy,
  orderBy,
  sumBy,
  every,
  each,
  keyBy,
  sortBy,
} from 'lodash';
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex';
import { v1 as uuidv1 } from 'uuid';
import inputValidationMixin from '@/js/mixins/input-validations';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';

export default {
  mixins: [inputValidationMixin, unsavedDataWarningMixin],
  data() {
    return {
      treeData: null,
      dependencyTreeFeedback: {},
      dependencyTreeModalOpen: false,
      openedNodes: [],
      initialLeafNodes: [],
      rootKey: 'rootNode',
      orderedLeafNodes: [],
      customOrderedLeafNodes: [],
      rules: [this.isNotEmpty, this.isUniqueLeafNodeName],
      valid: true,
      isEditing: false,
      loadingCannGroups: false,
      savingCannGroups: false,
      showTreeStructureTrimmedWarning: false,
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario', 'scenarioCannGroupsWithNotes']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('context', ['getCannGroupsRecommendedSizes']),

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditCannGroupPage);
    },

    treeDataArray() {
      if (
        // in case of a single cann group tree we want to alter the structure
        // to avoid tree expansion
        isUndefined(this.selectedScenario.customAttributes) &&
        size(this.treeData.children) === 1 &&
        isNull(this.treeData.children[0].attributeId)
      ) {
        return [{ ...this.treeData, children: [] }];
      }
      return [this.treeData];
    },

    isTreeLoading() {
      return this.loadingCannGroups || this.savingCannGroups;
    },

    treeStructureHasChanged() {
      const initialLeafNodes = this.initialLeafNodes.map(leaf => leaf.key);
      const orderedLeafNodes = this.orderedLeafNodes.map(leaf => leaf.key);

      // Structure has changed if different lengths
      if (size(initialLeafNodes) !== size(orderedLeafNodes)) return true;

      const leafNodeDiff = difference(orderedLeafNodes, initialLeafNodes);
      return !!size(leafNodeDiff);
    },

    leafNodeHasChanged() {
      const customOrderedLeafNodes = this.customOrderedLeafNodes.map(({ name, order }) => ({
        name,
        order,
      }));
      const orderedLeafNodes = this.orderedLeafNodes.map(({ name, order }) => ({
        name,
        order,
      }));
      return !isEqual(customOrderedLeafNodes, orderedLeafNodes);
    },

    formattedProducts() {
      // Products need to have their attributes as root level properties
      // For each one, get their attributes, format them and add any other required keys
      return this.$options.scenarioProducts.map(p => {
        const product = p.customAttributes.reduce((obj, attr) => set(obj, attr.id, attr.value), {});
        product.product = p.productKey;
        product.rateOfSale = p.rateOfSale;
        return product;
      });
    },

    totalSales() {
      return (this.treeData && this.treeData.salesAmount) || 0;
    },

    expandAll() {
      // collapse in case a single cann group has been saved but custom attributes are available
      if (!this.treeData) return false;
      return !(
        this.selectedScenario.customAttributes &&
        size(this.treeData.children) === 1 &&
        isNull(this.treeData.children[0].attributeId)
      );
    },

    showSidepanel() {
      return size(this.orderedLeafNodes);
    },

    hasDataErrors() {
      // required for <page-actions> component
      return !this.valid || !this.cannGroupMinimumSizeNotMet;
    },

    hasDataChanges() {
      // required for <page-actions> component
      return this.treeStructureHasChanged || this.leafNodeHasChanged;
    },

    cannGroupMinimumSizeNotMet() {
      return every(this.orderedLeafNodes, oln => get(oln, 'products.length', 0) > 1);
    },

    // note this logic is outside the hasDataChanges function,
    // because we don't want to warn the user if they leave the page without saving the initial single cann group
    hasSingleCannGroup() {
      // AOV3-1495 user should ALWAYS be allowed to proceed with single cann group,
      // including when they first land on the cann group page before editing it at all.
      // need get with non-empty default since computed can run before all data is loaded,
      // enabling button incorrectly for a few frames on page load.
      return isEmpty(get(this, 'treeData.children', [1]));
    },
  },

  watch: {
    scenarioCannGroupsWithNotes: {
      deep: true,
      handler(newCannGroups) {
        const scenarioCannGroupsWithNotesByKey = keyBy(newCannGroups, 'key');
        each(this.orderedLeafNodes, n => {
          n.totalNotes = get(scenarioCannGroupsWithNotesByKey[n.key], 'totalNotes', 0);
        });
      },
    },
  },

  async created() {
    this.loadingCannGroups = true;
    const params = { pick: ['productKey', 'rateOfSale', 'customAttributes', 'cannGroupId'] };
    // using $options insted of data prop as Vue doesn't seem to put watcher on it, and read/write is much faster
    this.$options.scenarioProducts = await this.fetchScenarioProducts({ params });
    // have to fetch cann groups with totalNotes count every time user switches tabs or refreshes the page
    const cannGroups = await this.fetchScenarioCannGroups();
    this.loadCannGroups(cannGroups);
    this.loadingCannGroups = false;

    if (this.selectedWorkpackage.templateId) {
      const templateScenario = await this.fetchScenarios({
        params: {
          where: { workpackageId: this.selectedWorkpackage.templateId },
          pick: ['cannGroups'],
        },
        options: { skipCommit: true },
      });
      const scenarioCannGroupsAttributes = this.getAttributeValuesUsedInCannGroups(
        this.selectedScenario.cannGroups
      );
      const templateCannGroupsAttributes = this.getAttributeValuesUsedInCannGroups(
        get(first(templateScenario), 'cannGroups', [])
      );
      this.showTreeStructureTrimmedWarning = !!size(
        difference(templateCannGroupsAttributes, scenarioCannGroupsAttributes)
      );
    }
  },

  methods: {
    ...mapActions('scenarios', [
      'updateScenarioCannGroups',
      'fetchScenarioCannGroups',
      'setSelectedScenario',
      'fetchScenarios',
    ]),
    ...mapActions('scenarioProducts', ['fetchScenarioProducts']),
    ...mapMutations('scenarios', ['setScenarioCannGroupsWithNotes']),

    getAttributeValuesUsedInCannGroups(nodes = [], result = new Set()) {
      each(nodes, node => {
        const attrValues = node.attributeValues || [];
        result.add(`${node.attributeId}-${attrValues.join('-')}`);
        if (size(node.children)) this.getAttributeValuesUsedInCannGroups(node.children, result);
      });
      return [...result];
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
    },

    // function which defines cann groups node structure
    Node({
      attributeId,
      attributeValue,
      attributeValues,
      products,
      parentRef,
      children = [],
      name,
      salesAmount,
      path,
      pathName,
      key = this.createKey(),
    }) {
      return {
        key,
        attributeId,
        attributeValue,
        parentRef,
        attributeValues,
        products,
        children,
        name,
        salesAmount,
        path: path ? path.add(key) : null,
        pathName,
      };
    },

    splitGroup(mergedGroup, splitValue) {
      // Modify the previously merged group
      // Once you split, all children are invalidated
      mergedGroup.children = [];
      // Get the attributeId - this is the same for all groups within this level.
      const { attributeId } = mergedGroup;

      // Remove the value you're splitting on.
      const remainingAttributeValues = pull(mergedGroup.attributeValues, splitValue);
      mergedGroup.attributeValues = remainingAttributeValues;

      // Split the products based on whether they match the old or the new group
      const mergedNodeProducts = mergedGroup.products;
      const [remainingProducts, splitProducts] = partition(mergedNodeProducts, p => {
        return remainingAttributeValues.includes(p[attributeId]);
      });

      // the split group is a clone of the merged group in most respects - it's also shallow as its children are gone
      const splitGroup = { ...mergedGroup };
      splitGroup.key = this.createKey(); // Generate a new key - this will make diffing trickier.
      splitGroup.attributeValues = [splitValue]; // You split a single value so that's the only one in the new group.
      splitGroup.path = this.generatePath(splitGroup); // Assign split group the correct path

      // Update path names
      mergedGroup.pathName = this.generatePathName(mergedGroup);
      splitGroup.pathName = this.generatePathName(splitGroup);

      // Rename Groups
      mergedGroup.name = mergedGroup.pathName;
      splitGroup.name = splitGroup.pathName;

      // Assign products to their respective groups
      mergedGroup.products = remainingProducts;
      splitGroup.products = splitProducts;

      // Once subtree is assembled, recalculate sales
      this.setSalesForNode(mergedGroup);
      this.setSalesForNode(splitGroup);

      // Update current leaf nodes list using the merged group
      this.updateLeafNodes(mergedGroup);
      // Previous update did not included the new splitGroup. Add this seperately to leaf nodes
      this.addLeafNode(splitGroup);

      // Get reference to all groups for this attribute.
      const currentLevel = mergedGroup.parentRef.children;

      // Groups are ordered by sales - preserve this ordering.
      const index = sortedIndexBy(currentLevel, group => size(group.products));
      currentLevel.splice(index, 0, splitGroup);
    },

    mergeGroup(event, node) {
      // In a merge, this method is called for both the source and target node
      // Splits are handled by a separate mechanism - merges are the only operation to trigger this function
      // After a merge, children of both nodes are invalidated.
      node.children = [];
      const remainingAttributeValues = get(node, 'attributeValues', []); // There won't be attributeValues on the root node.
      const attributeId = get(node, 'attributeId'); // There won't be an attributeId on the root node.

      // Products need to be recalcaluated - only do this if you have some attributeValues to filter on
      // If it doesn't, do nothing - nodes with no values will be removed in a few lines
      if (size(remainingAttributeValues)) {
        const parentProducts = node.parentRef.products;
        const newProductsForNode = parentProducts.filter(p => {
          return remainingAttributeValues.includes(p[attributeId]);
        });
        node.products = newProductsForNode;
        node.pathName = this.generatePathName(node);
        node.name = node.pathName;
        this.setSalesForNode(node);
        this.updateLeafNodes(node);
      }
      // If the node now has no values, then it's been merged into another node
      // Remove that node by filtering the children of its parent (its own level)
      if (isEmpty(remainingAttributeValues)) {
        node.parentRef.children = node.parentRef.children.filter(c => c.key !== node.key);
        this.deleteLeafNodes(node.key);
        this.resetLeafNodeOrder();
      }
    },

    isUniqueLeafNodeName(name) {
      const newName = name.trim().toLowerCase();
      // Filter leaf nodes with the new name value.
      // If more than one leaf node is found, the name is not unique.
      const filteredLeaves = this.orderedLeafNodes.filter(
        leaf => leaf.name.toLowerCase() === newName
      );

      return (
        size(filteredLeaves) <= 1 ||
        this.$t('validationErrors.unique', [this.$t('cannGroupsSidepanel.name')])
      );
    },

    revalidateForm() {
      if (this.$refs.leafNodeForm) {
        this.$refs.leafNodeForm.validate();
      }
    },

    toggleEditing() {
      this.isEditing = !this.isEditing;
    },

    highlightInputText(event) {
      this.toggleEditing();
      event.target.select();
    },

    generateName(node) {
      if (node.attributeValues) {
        return node.parentRef.name
          ? `${node.parentRef.name}-${node.attributeValues.join('-')}`
          : node.attributeValues.join('-');
      }

      return '';
    },

    getList(node) {
      return get(node, 'attributeValues', []);
    },

    createKey() {
      return uuidv1();
    },

    hasNodes(list) {
      return size(list) && isArray(list);
    },

    setOpenNodes(nodes) {
      // This sets expanded false if you have a saved single cann group - otherwise it renders incorrectly
      this.openedNodes = !this.expandAll ? [] : nodes;
    },

    setSalesForNode(node) {
      if (node.children && node.children.length > 0) {
        node.salesAmount = sumBy(node.children, this.setSalesForNode);
      } else if (node.products) {
        node.salesAmount = sumBy(node.products, 'rateOfSale');
      }
      return node.salesAmount;
    },

    formatSalesRatio(node, tree) {
      return this.formatNumber({
        number: (100 * node.salesAmount) / tree.salesAmount || 0,
        format: 'integer',
      });
    },

    getValue(children) {
      return this.hasNodes(children) && first(children).attributeId;
    },

    // gives distinct values between all attribute and selected ones
    getAttributesToSelect(key) {
      const attributes = differenceWith(
        this.selectedScenario.customAttributes,
        this.generateParents(key),
        (x, y) => x.id === y
      );
      return sortBy(attributes, item => item.name.toLowerCase());
    },

    modifyTree(selectedAttributeId, node) {
      this.openedNodes.push(node.key);
      this.addChildrenNode(selectedAttributeId, node);
      this.setSalesForNode(node);
      this.updateLeafNodes(node);
    },

    addChildrenNode(selectedAttributeId, node) {
      node.children = this.createChildrens({
        list: uniq(map(node.products, selectedAttributeId)),
        nodeValues: {
          parentRef: node,
          products: node.products,
          attributeId: selectedAttributeId,
        },
      });
    },

    // creates array of nodes based on attribute values
    createChildrens({ list, nodeValues }) {
      const { products, attributeId, parentRef } = nodeValues;
      return list.map(attributeValue => {
        const nodeProducts = products.filter(product => product[attributeId] === attributeValue);
        const isRoot = parentRef.key === this.rootKey;

        return this.Node({
          attributeId,
          attributeValue,
          parentRef,
          attributeValues: [attributeValue],
          products: nodeProducts,
          name: isRoot ? attributeValue : `${parentRef.pathName}-${attributeValue}`,
          // salesAmount is set in setSalesForNode
          path: new Set(parentRef.path),
          pathName: isRoot ? attributeValue : `${parentRef.pathName}-${attributeValue}`,
        });
      });
    },

    updateLeafNodes(node) {
      this.deleteLeafNodes(node.key);
      // New child nodes are leaf nodes
      const leafNodes = size(node.children) ? node.children : node;
      this.addLeafNode(leafNodes);
    },

    deleteLeafNodes(nodeKey) {
      // Delete all leaf nodes if parent node is root
      if (nodeKey === this.rootKey) {
        this.orderedLeafNodes = [];
      } else {
        // Remove every leaf node that is a child of the modified node
        this.orderedLeafNodes = this.orderedLeafNodes.filter(node => !node.path.has(nodeKey));
      }
    },

    addLeafNode(nodes) {
      if (nodes.key === this.rootKey) {
        nodes.name = this.$t('scenarioCannGroups.allProducts');
      }
      this.orderedLeafNodes = this.orderedLeafNodes.concat(nodes);
      this.resetLeafNodeOrder();
    },

    discard() {
      this.loadCannGroups(this.selectedScenario.cannGroups);
      this.revalidateForm();
    },

    deleteNode(node) {
      this.$set(node, 'children', []);
      this.updateLeafNodes(node);
    },

    // iterates trough tree and gets path to node
    getPathToNode(key, node) {
      if (node.key === key) return [];
      if (isArray(node.children)) {
        for (let i = 0; i < node.children.length; i += 1) {
          const child = node.children[i];
          const childResult = this.getPathToNode(key, child);
          if (isArray(childResult))
            return [{ id: child.attributeId, value: child.attributeValue }].concat(childResult);
        }
      }
    },

    generateParents(nodeKey) {
      const path = this.getPathToNode(nodeKey, this.treeData);
      return path.map(({ id }) => id);
    },

    formatTreeOnSave(tree) {
      const { key, attributeId, attributeValues } = tree;
      const formattedTree = {
        key,
        attributeId,
        attributeValues,
        children: (tree.children || []).map(node => this.formatTreeOnSave(node)),
      };
      if (!size(tree.children)) {
        formattedTree.name = tree.name;
        formattedTree.order = isUndefined(tree.order)
          ? this.orderedLeafNodes.indexOf(tree)
          : tree.order;
      } else {
        formattedTree.name = tree.parentRef ? this.generateName(tree) : '';
      }
      return formattedTree;
    },

    resetLeafNodeOrder() {
      // Set the order value to the tree leaf nodes by their sales amounts
      this.orderedLeafNodes = orderBy(this.orderedLeafNodes, ['salesAmount', 'name'], ['desc']);
      // Custom order gets overridden
      this.customOrderedLeafNodes = cloneDeep(this.orderedLeafNodes);
    },

    setCustomLeafNodesOrder() {
      this.customOrderedLeafNodes = cloneDeep(this.orderedLeafNodes);
      this.customOrderedLeafNodes.forEach((leaf, idx) => {
        leaf.order = idx;
      });
    },

    setLeafNodeOrder() {
      if (this.leafNodeHasChanged) {
        // Make sure 'custom' leaf node order is up-to-date and contains new names
        this.setCustomLeafNodesOrder();

        const sortedLeaves = orderBy(this.orderedLeafNodes, ['key']);
        const sortedCustomLeaves = orderBy(this.customOrderedLeafNodes, ['key']);
        sortedLeaves.forEach((leaf, idx) => {
          leaf.order = sortedCustomLeaves[idx].order;
          leaf.name = sortedCustomLeaves[idx].name;
        });
      }
    },

    getProducts(node) {
      const parentProducts = !isNull(node.parentRef)
        ? node.parentRef.products
        : this.formattedProducts;

      const { attributeId, attributeValues } = node;

      // If it's a single cann group tree, values are null - return all products.
      if (isNull(attributeValues)) return parentProducts;

      const newProductsForNode = parentProducts.filter(p => {
        return attributeValues.includes(p[attributeId]);
      });
      return newProductsForNode;
    },

    generatePath(child) {
      const path =
        child.parentRef.key === this.rootKey || !child.parentRef.path
          ? [child.key]
          : [...child.parentRef.path, child.key];

      return new Set(path);
    },

    generatePathName(child) {
      if (child.attributeValues) {
        const attributeValuePath = map(
          child.attributeValues,
          v => v || this.$t('scenarioCannGroups.blankAttributeValue')
        ).join('-');
        return child.parentRef.key === this.rootKey || !child.parentRef.pathName
          ? attributeValuePath
          : `${child.parentRef.pathName}-${attributeValuePath}`;
      }
      return null;
    },

    formatTreeOnLoad(tree = [], parentRef = null) {
      tree.forEach(child => {
        this.openedNodes.push(child.key);
        child.parentRef = parentRef;
        child.products = this.getProducts(child);
        child.name = child.name || this.generateName(child);
        child.path = this.generatePath(child);
        child.pathName = this.generatePathName(child);

        if (size(child.children)) {
          child.children = this.formatTreeOnLoad(child.children, child);
        } else {
          this.orderedLeafNodes.push(child);
        }
      });
      return tree;
    },

    async initCanngroupSave() {
      if (!this.hasNodes(this.treeData.children)) {
        await this.$refs.confirm.open();
      } else {
        return this.saveCannGroups();
      }
    },

    async saveCannGroups(commit = false) {
      this.savingCannGroups = true;
      const tree = this.treeData;
      const isSingleCannGroupTree =
        size(tree.children) === 1 && isNull(tree.children[0].attributeId);
      if (isSingleCannGroupTree) return;

      // If we don't save, we need to keep all the current data like parentRef etc.
      // formatTreeOnSave does not mutate original presentational tree
      this.setLeafNodeOrder();

      // generated formatted tree data for save
      const treeDataFixed = size(tree.children)
        ? this.formatTreeOnSave(tree).children
        : this.getSingleCannGroupTree();

      const result = await this.updateScenarioCannGroups({
        id: this.selectedScenario._id,
        cannGroups: treeDataFixed,
        commitChanges: commit,
        cannGroupsChanged: this.treeStructureHasChanged,
      });

      this.savingCannGroups = false;

      if (result.data.needsFeedback) {
        this.dependencyTreeFeedback = result.data.output;
        this.dependencyTreeModalOpen = true;
      } else {
        this.loadingCannGroups = true;
        this.loadCannGroups(result.data.data.cannGroups);
        this.loadingCannGroups = false;
        return this.setSelectedScenario(result.data.data);
      }
    },

    getSingleCannGroupTree() {
      return [
        {
          key: this.createKey(),
          attributeId: null,
          attributeValues: null,
          order: 0,
          children: [],
          name: this.$t('scenarioCannGroups.allProducts'),
        },
      ];
    },

    loadCannGroups(data) {
      // Empty leafNodes before load
      this.orderedLeafNodes = [];

      const treePreFix = cloneDeep(data);
      const rootNode = this.Node({
        key: this.rootKey,
        children: [],
        attributeValues: [],
        products: this.formattedProducts,
      });
      const tree = this.formatTreeOnLoad(treePreFix, rootNode);

      rootNode.children = tree;
      // Calculate sales amount on loading tree so that we don't calculate it on render.
      // Also, adding sales amount will allow leaf nodes to be reordered when changes are made to the tree.
      this.setSalesForNode(rootNode);
      this.treeData = rootNode;

      this.orderedLeafNodes = orderBy(this.orderedLeafNodes, ['order']);
      if (this.scenarioCannGroupsWithNotes) {
        const scenarioCannGroupsWithNotesByKey = keyBy(this.scenarioCannGroupsWithNotes, 'key');
        each(this.orderedLeafNodes, n => {
          n.totalNotes = get(scenarioCannGroupsWithNotesByKey[n.key], 'totalNotes', 0);
        });
      }
      this.customOrderedLeafNodes = cloneDeep(this.orderedLeafNodes);
      // Store the cann groups available on load
      this.initialLeafNodes = cloneDeep(this.orderedLeafNodes);
      this.setScenarioCannGroupsWithNotes({ cannGroups: this.initialLeafNodes });
    },

    displayCannGroupHighlighting(productsNo, nodeChildren) {
      return (
        !(
          this.getCannGroupsRecommendedSizes.min <= productsNo &&
          this.getCannGroupsRecommendedSizes.max >= productsNo
        ) && !this.hasNodes(nodeChildren)
      );
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.cann-groups-container {
  font-size: 12px;
  max-width: none !important;
  color: $assortment-text-colour;
  height: 100%;

  .header {
    border-bottom: 1px solid $assortment-divider-colour;

    &__title {
      white-space: nowrap;
    }
  }

  .container-block {
    overflow: hidden;
    position: relative;
    height: $tab-panel-height;

    .drag-disabled {
      opacity: 0.5;
    }

    .tree {
      display: flex;
      overflow: auto;
      height: 100%;

      .title-container {
        display: flex;
        flex-direction: column;
        padding: 5px 3px 0px 10px;
      }

      .treeview-container {
        padding: 22px $assortment-sidepanel-toggle-height 0 0;
        width: auto;
        max-width: none;
      }

      .treeview-select {
        &.attribute-not-split-select {
          opacity: 0.5;
        }

        .v-input__slot,
        .v-select__slot {
          background: $assortment-control-secondary-bg-colour;
          height: 28px !important;
        }

        .v-select__selections {
          padding-left: 5px;
          height: 28px;
          font-weight: bold;

          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .v-input__slot {
          box-shadow: none !important;
          padding: 0 !important;
        }

        .v-icon {
          padding-right: 0 !important;
        }

        .v-label {
          position: unset !important;
          padding-left: 5px;
          font-size: 1.2rem;
        }

        .v-select__selection.v-select__selection--comma {
          padding-left: 5px !important;
        }
      }

      .title-text {
        font-weight: 600;
      }

      .node-line {
        width: 15px;
        height: 50%;
        top: 50%;
        left: -1px;
        border-top: 1px solid $assortment-tree-line-colour;
        position: absolute;
      }

      .node-group-line {
        width: 5px;
        height: calc(100% - 20px);
        left: 14px;
        top: 10px;
        border-top: 1px solid $assortment-tree-line-colour;
        border-left: 1px solid $assortment-tree-line-colour;
        border-bottom: 1px solid $assortment-tree-line-colour;
        position: absolute;
      }

      .v-treeview-node__label {
        display: flex;
      }

      .v-treeview-node__root {
        i.v-treeview-node__toggle {
          margin-bottom: 0px !important;
          visibility: hidden;
        }
      }

      .v-treeview-node__children {
        border-left: 1px solid $assortment-tree-line-colour;
        padding-left: 100px;
        .v-treeview-node__root {
          border-left: 1px solid $assortment-tree-line-colour;
          i.v-treeview-node__toggle {
            margin-bottom: 5px !important;
            visibility: hidden;
          }
        }

        /* Overrides to stop trailing lines */
        .v-treeview-node:last-child > .v-treeview-node__children {
          border-left: 1px solid white;
        }
        .v-treeview-node:last-child
          > .v-treeview-node__root
          > .v-treeview-node__content
          > .v-treeview-node__label
          > .node-line {
          border-left: 1px solid white;
        }
      }

      .attribute-products {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f4f4f4;
        height: 50px;
        min-width: 50px;
        height: 28px;
        border-radius: 3px;
        font-size: 1.2rem;
        padding-right: 3px;
        margin-left: 0px;
        margin-right: 5px;
      }

      .attribute-products-highlighted {
        border: 1px solid $assortment-negative-action-colour;
      }

      .attribute-value {
        background: $assortment-attribute-colour;
        width: 186px;
        height: 28px;
        border-radius: 3px;
        padding-left: 10px;
        padding-right: 3px;
        margin-right: 10px;

        p {
          font-weight: bold;
          font-size: 1.2rem;
          margin-bottom: 0px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &--all-products {
          margin-top: 20px;
          margin-right: 0;
        }

        &.hidden {
          display: none !important;
        }
      }

      .v-treeview {
        .v-treeview-node__label {
          .v-btn {
            margin-top: 2px;
            margin-left: 2px;
          }
        }
      }

      /* Specifically remove the first border, to stop the whole treeview having a left border */
      .v-treeview > .v-treeview-node > .v-treeview-node__children {
        border: none;
      }

      .v-treeview
        > .v-treeview-node
        > .v-treeview-node__root
        > .v-treeview-node__content
        > .v-treeview-node__label
        > .node-line {
        display: none;
      }

      .v-treeview > .v-treeview-node > .v-treeview-node__root > .v-treeview-node__toggle {
        display: none;
      }
    }
  }

  .page-actions-container {
    border-top: 1px solid $assortment-panel-border-divider-colour;
  }

  .draggable {
    cursor: default;
  }

  .drag-handle {
    cursor: move;
  }

  .list-group {
    position: relative;

    &__item {
      align-items: center;
      background-color: $assortment-cann-group-row-bg-white;
      border: 1px solid $assortment-cann-group-row-bg-white;
      color: $assortment-cann-group-row-colour;
      display: flex;
      font-size: 1.2rem;
      line-height: 1.5rem;
      padding: 4px 10px 4px 2px;

      &:nth-child(odd) {
        background-color: $assortment-cann-group-row-bg-blue;
        border-color: $assortment-cann-group-row-bg-blue;

        .v-input:not(.v-input--is-focused),
        .v-input--is-disabled {
          .v-input__slot {
            background-color: $assortment-cann-group-row-bg-blue;
          }
        }
      }
    }

    &__input {
      .v-text-field__details,
      .v-messages {
        min-height: 0;
      }

      .v-messages.error--text {
        color: $assortment-negative-action-colour !important;
        min-height: 14px;
      }

      &.v-input:not(.v-input--is-focused),
      &.v-input--is-disabled {
        .v-input__slot {
          background-color: $assortment-cann-group-row-bg-white;

          &:before,
          &:after {
            border-color: transparent !important;
            border-image: none !important;
          }

          .v-text-field__slot input {
            color: $assortment-cann-group-row-colour;
          }
        }
      }

      &.v-input.error--text {
        .v-input__slot {
          &:before,
          &:after {
            border-color: $assortment-negative-action-colour !important;
          }
        }
      }

      &.v-input--is-focused,
      &.error--text {
        .v-input__slot {
          background-color: $assortment-control-secondary-bg-colour !important;
        }
      }
    }

    .draggable__icon {
      cursor: move;
      margin: 3px;
    }
  }
}
</style>
