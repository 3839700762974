import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { "data-id-e2e": "btnDuplicateRenderer" } },
    [
      _c(
        VBtn,
        {
          staticClass: "duplicate-extracts__button",
          attrs: {
            "data-id-e2e": "btnDuplicateExtracts",
            secondary: "",
            depressed: "",
            disabled: _vm.isDisabled,
          },
          on: { click: () => _vm.onRouteClick() },
        },
        [
          _vm._v("\n    " + _vm._s(_vm.$t("actions.duplicate")) + "\n    "),
          _c("img", {
            staticClass: "duplicate-extracts__icon",
            attrs: {
              src: _vm.duplicateExtractIcon,
              alt: "Duplicate extract icon",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }