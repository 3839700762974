import { VAvatar } from 'vuetify/lib/components/VAvatar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.params.value !== undefined
    ? _c(VAvatar, { attrs: { size: "18", color: "#333" } }, [
        _c("span", { staticClass: "white--text headline" }, [
          _vm._v(_vm._s(_vm.params.value)),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }