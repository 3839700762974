import { find } from 'lodash';

const helpers = {
  getSpacebreak: (state, { storeClassId, spacebreakId }) => {
    const storeClass = find(state.scenarioFurniture.storeClasses, { _id: storeClassId });
    return find(storeClass.spacebreaks, { _id: spacebreakId });
  },
  getGeneratedFurniture(state, furnitureId) {
    return find(state.scenarioFurniture.generatedFurniture, { _id: furnitureId });
  },
  getFurnituresInSpacebreak(state, { storeClassId, spacebreakId }) {
    const spacebreak = helpers.getSpacebreak(state, { storeClassId, spacebreakId });

    return spacebreak.generatedFurnitureIds.map(id => helpers.getGeneratedFurniture(state, id));
  },
};

export default helpers;
