var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "tile-view-container", style: { display: "contents" } },
    [
      _vm._l(_vm.spacebreaks, function (spacebreak) {
        return _c(
          "div",
          {
            key: spacebreak._id,
            staticClass:
              "grid-item-border assigned-products-bay flexed-white-bg-grid-item",
          },
          [
            _c(
              "div",
              { staticClass: "inside-box", style: _vm.productsContainerStyle },
              [
                _vm.tileViewContainerRef
                  ? _c("product-tile-drag-container", {
                      staticClass: "d-flex",
                      attrs: {
                        "spacebreak-id": spacebreak._id,
                        "group-name": _vm.groupName,
                        "filtered-products":
                          _vm.productsPerSpacebreakInCdtNode[spacebreak._id] ||
                          [],
                        "selected-tile-size": _vm.selectedTileSize,
                        "tile-view-ref": _vm.tileViewContainerRef,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.spacebreakTypes, function (spacebreak) {
        return _c(
          "div",
          {
            key: spacebreak,
            staticClass: "grid-item-border flexed-white-bg-grid-item",
            attrs: { id: `${spacebreak}-spacebreak-tile` },
          },
          [
            _c(
              "div",
              { staticClass: "inside-box", style: _vm.productsContainerStyle },
              [
                _vm.tileViewContainerRef
                  ? _c("product-tile-drag-container", {
                      attrs: {
                        "container-id": spacebreak,
                        "spacebreak-id": null,
                        "group-name": _vm.groupName,
                        "filtered-products":
                          _vm.productsWithNoSpacebreak[spacebreak],
                        "selected-tile-size": _vm.selectedTileSize,
                        "tile-view-ref": _vm.tileViewContainerRef,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }