<template>
  <div class="container">
    <span class="value">{{ value }} </span><span class="suffix">{{ suffix }}</span>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'unitSuffixRenderer',
  data() {
    return {
      value: '-',
      suffix: null,
    };
  },
  mounted() {
    this.value = this.params.valueFormatted;
    this.suffix = this.value ? this.params.suffixGetter(this.params) : '';
  },
});
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: flex-end;
}

.suffix {
  align-self: flex-end;
  font-size: 10px;
  padding-left: 10.25px;
  padding-right: 10.25px;
}
</style>
