import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { attrs: { fluid: "" } },
    [
      _c(
        VLayout,
        { attrs: { column: "" } },
        [
          _c(
            VFlex,
            [
              _c("workpackages-active"),
              _vm._v(" "),
              _c("workpackages-archived", {
                attrs: { entity: _vm.entities.workpackage },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }