<template>
  <div :style="{ display: 'contents' }">
    <!-- The amount of rows and columns that each grid-item spans is set in the template, along with its top position for sticky headers functionality -->
    <div
      ref="current-attr-value"
      :style="{
        'grid-row': `span ${rowSpanning}`,
        'grid-column': `span ${childColumns}`,
        top: `${previousHeights}px`,
      }"
      class="grid-item-border-bottom flexed-white-bg-grid-item header"
      :class="positioning"
    >
      <div
        class="cdt-header pa-1 grey-background d-flex flex-column full-width flex-grow-1 bar-containers"
      >
        <div class="attr-value-title centred-header d-flex justify-center">
          <div class="inside-box-title">
            <div :title="currentAttrValue" class="title-text">
              {{ currentAttrValue }}
            </div>
          </div>

          <!-- If the current cdt node has not been expanded then show the plus icon so it can be expanded-->
          <span
            v-if="!expanded"
            size="14"
            class="d-flex box-icon mdi mdi-plus-box"
            primary
            :class="{ disabled: !hasChildren, active: hasChildren }"
            @click="toggleExpanded"
          />
          <!-- If it has been expanded then show the minus icon so that it can be collapsed -->
          <span
            v-else
            size="14"
            style="cursor: pointer"
            class="d-flex box-icon mdi mdi-minus-box"
            @click="toggleExpanded"
          />
        </div>
      </div>
    </div>
    <div
      v-if="expanded"
      ref="current-cdt-name"
      :style="{
        'grid-column': `span ${childColumns}`,
        top: `${attrValueHeight + previousHeights}px`,
      }"
      class="d-flex align-center grid-item-border-bottom flexed-white-bg-grid-item header"
      :class="positioning"
    >
      <div
        class="d-flex align-center flex-column full-width flex-grow-1 bar-containers grey-background pa-1"
      >
        <div class="centred-header d-flex justify-space-between">
          <div class="inside-box-title">
            <div class="title-text">
              <h2 :title="currentCdtName" class="header-large">{{ currentCdtName }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- If the node is expanded then we render this component again recursively for each childnode of the expanded one. -->
    <!-- We pass down a new childTree for each child of that specific node -->
    <!-- We pass down the accumulatedHeights of all parent nodes, this helps set the sticky header top positioning -->
    <!-- Each time a new component is rendered a level down in the tree we add a depth of + 1 so each component knows its depth in the headers -->
    <template v-if="expanded">
      <cdt-column
        v-for="cdtNode in children"
        :key="cdtNode._id"
        :cdt-node-id="cdtNode._id"
        :cann-group-id="cannGroupId"
        :depth="depth + 1"
        :cdts="cdts"
        :child-tree="childTree"
        :previous-heights="accumulatedHeights"
        @child-nodes-expanded="calculateChildrenNodes"
        @child-nodes-collapsed="removeChildNodes"
      />
    </template>
    <!-- We render the rows with the products in below each expanded header, in either tile or list view -->
    <template v-else>
      <tile-view
        v-if="selectedView === views.Tile"
        :key="`tile-view-${cdtNodeId}`"
        :products="getProductsForCurrentExpandedView"
        :group-name="cdtNodeId"
      />
      <list-view
        v-else
        :products="getProductsForCurrentExpandedView"
        :group-name="cdtNodeId"
        :previous-heights="accumulatedHeights"
        :positioning="positioning"
      />
    </template>
  </div>
</template>

<script>
import { get, first, filter, sum, values, omit } from 'lodash';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { Views } from '@enums/assortment-canvases';
import destroy from '../../utils/destroy';

export default {
  name: 'CdtColumn',
  props: {
    cannGroupId: {
      required: true,
      type: String,
    },
    cdts: {
      type: Array,
      required: true,
    },
    cdtNodeId: {
      required: true,
      type: String,
    },
    childTree: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
    previousHeights: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      tileViewContainerRef: null,
      expanded: false,
      expandedChildrenNodes: {},
      attrValueHeight: null,
      currentCdtHeight: null,
      views: Views,
    };
  },

  computed: {
    ...mapState('assortmentCanvas', ['selectedView', 'stickyHeaders', 'selectedAssortmentCanvas']),
    ...mapGetters('context', ['getClientConfig']),
    ...mapGetters('scenarios', ['attributesById']),
    ...mapGetters('scenarioCdts', ['cdtById']),
    ...mapGetters('assortmentCanvas', [
      'totalExpandedHeaderDepth',
      'productsByCdtGroup',
      'getFilteredProducts',
      'getHighlightedProducts',
      'sortByProductsInCanvas',
    ]),

    hasHighlightProductEnabled() {
      return get(this.getClientConfig, 'features.highlightProductEnabled', false);
    },

    rowSpanning() {
      const defaultRowSpan = 1;
      // Calculate how many rows the grid-item should span, this is set in the template above
      // This calculation is based off the totalExpandedHeaderDepth which returns the depth of the most expanded header.
      const lowestOpenAttribute = this.depth === this.totalExpandedHeaderDepth;
      if (lowestOpenAttribute) return defaultRowSpan;

      const someOtherHeaderIsExpanded = this.totalExpandedHeaderDepth > 1;
      const currentHeaderNotExpanded = !this.expanded;

      // additionalHeight
      // if the current header is not expanded and another header is then calculate the difference between them and times by 2 for the number of rows
      // we times by 2 as each time cdt-column is renderered we get 2 new rows.
      const additionalHeight =
        someOtherHeaderIsExpanded && currentHeaderNotExpanded
          ? (this.totalExpandedHeaderDepth - this.depth) * 2
          : 0;
      // return the normal row span of 1 plus any additional height
      return defaultRowSpan + additionalHeight;
    },

    childColumns() {
      const defaultColumnSpan = 1;
      // Calculate how many columns a grid item should span, if it has 3 leaf nodes at the bottom of its tree then this will be 3.
      if (this.expanded) {
        // We check if any of the children of this node have been expanded.
        // Everytime we expand a node we send an event up to the direct parent with the count of all its expanded children + its unexpanded children
        // See: 'child-nodes-expanded' event in toggleExpanded method
        // We filter the children to see which ones have been expanded, then sum together the count of unexpanded children + all expanded childrens's children.
        // This gives us the leaf nodes of this particular parent.
        const filteredChildren = this.children.filter(x => !this.expandedChildrenNodes[x._id]);
        // We sum together the count of all the expandedChildrenNodes sent
        return sum(values(this.expandedChildrenNodes)) + filteredChildren.length;
      }
      return defaultColumnSpan;
    },

    currentAttrValue() {
      return get(
        this.cdtById,
        `${this.cannGroupId}.${this.cdtNodeId}.attributeValue`,
        'Unmatched Attribute'
      );
    },

    hasChildren() {
      return this.childTree[this.cdtNodeId].size > 1;
    },

    children() {
      // Get the direct children of this node, where the parentId is the same as the current Node id.
      return filter(this.cdts, { parentId: this.cdtNodeId });
    },

    currentCdtName() {
      const { attributeId } = first(this.children);
      return this.attributesById[attributeId].name;
    },

    accumulatedHeights() {
      // Calculate all the heights of the headers accumulatively in order to set the top property on sticky headers
      return this.currentCdtHeight + this.attrValueHeight + this.previousHeights;
    },

    positioning() {
      return this.stickyHeaders ? 'sticky' : '';
    },

    getProductsForCurrentExpandedView() {
      // Filter the products checking if the cdtGroupId of the product belongs in any of the children of the current node.
      // Only returns products that belong in the current cdt node or are children of the current node.
      const allNodesUnderCurrentExpandedTree = Array.from(this.childTree[this.cdtNodeId] || []);
      return allNodesUnderCurrentExpandedTree
        .reduce((acc, tree) => {
          const products = this.productsByCdtGroup[tree] || [];
          const productsInView = this.hasHighlightProductEnabled
            ? this.getHighlightedProducts
            : this.getFilteredProducts;
          const visibleProducts = products.filter(p =>
            productsInView.visibleProductsSet.has(p.productKey)
          );
          // mark the products to be highlighted
          visibleProducts.forEach(p => {
            p.isHighlighted = this.hasHighlightProductEnabled
              ? productsInView.highlightedProductsSet.has(p.productKey)
              : false;
          });

          return acc.concat(visibleProducts);
        }, [])
        .sort(this.sortByProductsInCanvas);
    },
  },

  beforeDestroy() {
    destroy.destroyReactiveVueProps(this);
  },

  mounted() {
    this.tileViewContainerRef = this.$refs;
    this.getAttrValueHeight();
  },

  updated() {
    // When component is updated (headers expanded) we re-calculate the heights for the sticky headers.
    this.getAttrValueHeight();
    if (this.expanded) {
      this.getCdtHeight();
    }
    // Required only on list view to keep height of sidebar header consistent
    if (this.selectedView === this.views.List) {
      // Calculate the height of all column headers
      let height = this.attrValueHeight + this.previousHeights;
      // Multiply by 2 as each time cdt-column is renderered we get 2 new rows
      if (this.expanded) height += this.currentCdtHeight * 2;
      this.setSidebarHeaderHeight(height);
    }
  },

  methods: {
    ...mapMutations('assortmentCanvas', [
      'setExpandedCdts',
      'setCollapsedCdts',
      'setSidebarHeaderHeight',
    ]),

    toggleExpanded() {
      if (!this.expanded) {
        // If we are expanding the node, emit an event to the direct parent of this node
        // The direct parent will be cann-group-column for the root node, or this component (cdt-column) itself for any node below that
        // This event sends the amount of children the current node has to the parent
        this.$emit('child-nodes-expanded', { [this.cdtNodeId]: this.children.length });
        // set expandedCdts here so we can calculate totalExpandedHeaderDepth in the store
        this.setExpandedCdts({
          id: this.cdtNodeId,
          depth: this.depth + 1,
        });
      } else {
        // If we are collapsing the node, then send an event to the parent - in the parent we then omit this id from the expandedChildrenNodes data set.
        this.$emit('child-nodes-collapsed', this.cdtNodeId);
        this.expandedChildrenNodes = {};
        // setCollapsedCdts here so we can calculate totalExpandedHeaderDepth in the store
        this.setCollapsedCdts({ cannGroupId: this.cannGroupId, cdtId: this.cdtNodeId });
      }

      this.expanded = !this.expanded;
    },

    calculateChildrenNodes(nodeObject) {
      // Calculating the children nodes of this component when event is fired from direct child component
      this.expandedChildrenNodes = { ...this.expandedChildrenNodes, ...nodeObject };
      this.emitUpdatedChildrenCount();
    },

    removeChildNodes(nodeId) {
      // Omitting the nodeId of the collapsed child from the expandedChildrenNodes
      this.expandedChildrenNodes = omit(this.expandedChildrenNodes, nodeId);
      this.emitUpdatedChildrenCount();
    },

    emitUpdatedChildrenCount() {
      // Send updated count of expanded children and their expanded nodes + unexpanded children to parent component
      const filteredChildren = this.children.filter(x => !this.expandedChildrenNodes[x._id]);
      const count = sum(values(this.expandedChildrenNodes)) + filteredChildren.length;
      this.$emit('child-nodes-expanded', { [this.cdtNodeId]: count });
    },

    getAttrValueHeight() {
      this.attrValueHeight = this.$refs['current-attr-value'].offsetHeight;
    },

    getCdtHeight() {
      this.currentCdtHeight = this.$refs['current-cdt-name'].offsetHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.header-large {
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  height: fit-content;
  font-size: 1.2rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cdt-header {
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}

.box-icon {
  position: absolute;
  right: 0px;
  padding-top: 1px;
  margin-right: 10px;
  font-size: 2.3rem !important;
  color: $assortment-action-icon-color !important;
}

.attr-value-title {
  white-space: nowrap;
  padding-right: 30px;
  padding-left: 30px;
  font-size: 1.2rem;
  font-weight: bold;
}

.grey-background {
  background-color: $canvas-cdt-header-background;
}
</style>
