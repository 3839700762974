'use strict';

module.exports = {
  input: 'input',
  readonly: 'readonly',
  dropdown: 'dropdown',
  checkbox: 'checkbox',
  icon: 'icon',
  radio: 'radio',
};
