import axios from 'axios';
import to from 'await-to-js';
import { get, size, uniqueId } from 'lodash';
import i18n from '../../vue-i18n';

const newlyAddedIdPrefix = 'newlyAddedIdPrefix';

const store = {
  namespaced: true,

  state: {
    loading: false,
  },

  getters: {},

  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },

  actions: {
    async fetchCheckpointSelections({ commit, rootState }) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/scenarios/${scenarioId}/store-executions/checkpoint-selections`)
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      return response.data.checkpointSelections;
    },

    async saveCheckpointSelections({ commit, dispatch, rootState }, updates) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err] = await to(
        axios.post(`/api/scenarios/${scenarioId}/store-executions/checkpoint-selections`, {
          updates,
        })
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
      commit('setLoading', false);
    },

    async fetchStoreAllocation({ commit, rootState }) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/scenarios/${scenarioId}/store-executions/store-allocation`)
      );
      if (err) throw new Error(err.message);
      commit('setLoading', false);
      return response.data;
    },

    async saveStoreAllocation({ commit, rootState, dispatch }, payload) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err] = await to(
        axios.post(`/api/scenarios/${scenarioId}/store-executions/store-allocation`, payload)
      );
      if (err) throw new Error(err.message);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
      commit('setLoading', false);
    },

    async fetchPlanogramDefinition({ commit, rootState }) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/scenarios/${scenarioId}/store-executions/planogram-definition`)
      );
      if (err) throw new Error(err.message);
      commit('setLoading', false);
      return response.data;
    },

    async savePlanogramDefinition({ commit, rootState, dispatch }, payload) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err] = await to(
        axios.post(`/api/scenarios/${scenarioId}/store-executions/planogram-definition`, payload)
      );
      if (err) throw new Error(err.message);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
      commit('setLoading', false);
    },

    // storeExecutionsService is one of: store-allocation, planogram-definition
    async processCSV(
      { commit, rootState, dispatch },
      { fileId, mappings, storeExecutionsService, delimiter }
    ) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const body = { mappings, scenarioId, delimiter };
      commit('setLoading', true);
      const [err, response] = await to(
        axios.post(`/api/csv/${storeExecutionsService}/process/${fileId}`, body)
      );
      commit('setLoading', false);
      // TODO: Remove this and use global interceptor on AOV3-159
      if (err) {
        const key = get(err, 'response.data.messageKey', err.message);
        const message = i18n.t(key, get(err, 'response.data.messageParams', []));
        dispatch('snackbar/showError', message, { root: true });
        return Promise.reject(message);
      }
      if (size(response.data.messages)) {
        dispatch('alerts/showMessages', response.data.messages, { root: true });
      }
      const csvRows = response.data.tableRows;
      csvRows.forEach(row => {
        // add temp ID to uploaded entries
        row._id = row._id || uniqueId(`${newlyAddedIdPrefix}-${storeExecutionsService}`);
      });

      return response.data.tableRows;
    },
  },
};

export default store;
