<template>
  <div class="d-flex filter-box" data-id-e2e="cdtFilterSelect">
    <span class="col-3">
      {{ $t('workpackagePage.splitWorkpackages.filterCdt') }}
    </span>
    <v-select
      v-model="selectedCdts"
      :items="cdtItems"
      hide-details
      white
      multiple
      item-value="productCategoryKey"
      item-text="description"
      data-id-e2e="cdtSelect"
      class="rtls-select rtls-select--white"
      :disabled="!cdtItems.length"
      :placeholder="$t('filterWorkpackagesOptions.noOption')"
      @change="updateMainFilterObject()"
    >
      <template v-slot:selection="{ item, index }">
        <span v-if="index < 1" class="selected-items">{{ item.description }} &nbsp;</span>
        <span v-if="index === 1" class="grey--text caption">
          (+{{ selectedCdts.length - 1 }} {{ $t('workpackagePage.splitWorkpackages.moreCDTs') }})
        </span>
      </template>
      <template v-slot:prepend-item>
        <div class="sticky">
          <div class="d-flex justify-space-around align-center pt-2">
            <v-btn class="button-link" text @click="selectAll">
              <v-icon size="16">mdi-check</v-icon>
              <span>{{ $t('actions.selectAll') }}</span>
            </v-btn>
            <v-btn class="button-link" text @click="selectNone">
              <v-icon size="16">mdi-checkbox-blank-outline</v-icon>
              <span>{{ $t('actions.selectNone') }}</span>
            </v-btn>
          </div>
          <v-divider class="mt-2" />
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { map, uniq, compact, get, sortBy, cloneDeep } from 'lodash';
import Vue from 'vue';
import filterTypeEnum from '@enums/wp-filter-types';
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedCdts: [],
  }),

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage', 'getProductHierarchy', 'hierarchyDepth']),
    ...mapState('toolData', ['productHierarchy']),

    categoryProductMap() {
      const catProdMap = {};
      this.products.forEach(({ productKey, productCategoryKey }) => {
        if (catProdMap[productCategoryKey]) {
          catProdMap[productCategoryKey].push(productKey);
        } else {
          catProdMap[productCategoryKey] = [productKey];
        }
      });
      return catProdMap;
    },

    // Remove all undefined or falsy values, sort and just pluck the productCategoryKey field
    cdts() {
      if (this.products.length > 0) {
        return [...uniq(compact(map(this.products, 'productCategoryKey')))];
      }
      return [];
    },

    cdtItems() {
      const cdts = this.cdts;
      const hierarchy = this.productHierarchy;
      const items = compact(
        map(cdts, key => {
          // Attach product counts to the description
          const category = cloneDeep(get(hierarchy, key));

          if (category) {
            category.description = category[this.descriptionKey].concat(
              ` (${this.categoryProductMap[category.productCategoryKey].length} ${this.$t(
                'workpackagePage.splitWorkpackages.products'
              )})`
            );
          }
          return category;
        })
      );
      return [...sortBy(items, 'description')];
    },

    descriptionKey() {
      return `productCategory${this.hierarchyDepth}Description`;
    },
  },

  watch: {
    // When the CDTs array gets initialised, we should select all by default.
    products() {
      Vue.nextTick(() => {
        this.selectAll();
        this.$emit('updateMainFilterObject', {
          type: filterTypeEnum.cdt,
          update: this.selectedCdts,
          isInit: true,
        });
      });
    },
    selectedWorkpackage() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('workpackages', ['getProductHierarchyDepth']),
    ...mapActions('toolData', ['fetchProductCategories']),

    selectAll() {
      this.selectedCdts = [...this.cdts];
    },

    selectNone() {
      this.selectedCdts = [];
      this.updateMainFilterObject();
    },

    async updateMainFilterObject() {
      this.$emit('updateMainFilterObject', {
        type: filterTypeEnum.cdt,
        update: this.selectedCdts,
      });
    },

    async init() {
      await this.getProductHierarchyDepth();
      this.fetchProductsCategoriesInformation();
    },

    async fetchProductsCategoriesInformation() {
      const pick = ['productCategoryKey', this.descriptionKey];
      this.fetchProductCategories({ pick });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .v-list {
    position: relative;
  }
}

.sticky {
  position: sticky;
  background-color: $canvas-header-white-background;
  top: 0;
}

.filter-box {
  align-items: center;

  span {
    padding-left: 0;
  }
}

.selected-items {
  padding-left: 5px !important;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
