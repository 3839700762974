var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { display: "contents" } }, [
    _c(
      "div",
      { staticClass: "ag-grid-box flex-grow-1 grid-size" },
      [
        _c("ag-grid-vue", {
          staticClass: "ag-theme-custom ag-theme-custom--attributes",
          staticStyle: { width: "100%", height: "100%" },
          attrs: {
            "grid-options": _vm.gridOptions,
            "column-defs": _vm.columnDefs,
            "default-col-def": _vm.defaultColDef,
            "auto-group-column-def": _vm.autoGroupColumnDef,
            "animate-rows": true,
            "row-model-type": _vm.rowModelType,
            "server-side-store-type": _vm.serverSideStoreType,
            "master-detail": true,
            "keep-detail-rows": true,
            "detail-cell-renderer-params": _vm.detailCellRendererParams,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex justify-end align-center status-container" },
      [
        _c("div", { staticClass: "flex-column" }, [
          _c("span", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("workpackagePage.products.table.headers.selected")
                ) +
                ": "
            ),
          ]),
          _vm._v(" "),
          _c("span", { attrs: { id: "totalSelected" } }, [
            _vm._v(_vm._s(_vm.selectedProductsCount)),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }