import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VMenu,
    {
      attrs: {
        "close-on-content-click": false,
        "offset-y": "",
        "max-width": 400,
        "min-width": 400,
        "max-height": 600,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                VBtn,
                _vm._g(
                  {
                    staticClass: "float-right",
                    attrs: { disabled: _vm.disabled, primary: "" },
                  },
                  on
                ),
                [_vm._v("\n      " + _vm._s(_vm.legends.buttonName) + "\n    ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showMenu,
        callback: function ($$v) {
          _vm.showMenu = $$v
        },
        expression: "showMenu",
      },
    },
    [
      _vm._v(" "),
      _c(
        VCard,
        { staticClass: "assortment-table-add-attr" },
        [
          _c(VCardTitle, [
            _vm._v("\n      " + _vm._s(_vm.legends.title) + "\n    "),
          ]),
          _vm._v(" "),
          _c(VDivider),
          _vm._v(" "),
          _vm.uploadedDataDetails && _vm.showModal
            ? _c("map-fields-modal", {
                attrs: {
                  value: _vm.isMapFieldsModalOpen,
                  "download-file-url": _vm.downloadFileUrl,
                  "upload-metadata": _vm.uploadMetadata,
                  "uploaded-data-details": _vm.uploadedDataDetails,
                  "current-custom-fields": _vm.customFields,
                  "fields-to-ignore": _vm.fieldsToIgnore,
                  "enable-field-creation": _vm.enableFieldCreation,
                },
                on: { confirm: _vm.processData, cancel: _vm.cancelUpload },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.needToMapFields && !_vm.showModal
            ? _c("map-fields", {
                attrs: {
                  "download-file-url": _vm.downloadFileUrl,
                  "uploaded-data-details": _vm.uploadedDataDetails,
                },
                on: { confirm: _vm.processData, cancel: _vm.cancelUpload },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            VForm,
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.needToMapFields,
                  expression: "!needToMapFields",
                },
              ],
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                VRadioGroup,
                {
                  model: {
                    value: _vm.uploadMethod,
                    callback: function ($$v) {
                      _vm.uploadMethod = $$v
                    },
                    expression: "uploadMethod",
                  },
                },
                [
                  _c(
                    "div",
                    { attrs: { "data-id-e2e": "import-from-csv-option" } },
                    [
                      _vm.attributesUpload
                        ? _c(VRadio, {
                            staticClass: "mb-3 mt-3 ml-3",
                            attrs: {
                              label: _vm.$t("actions.selectCsvFile"),
                              ripple: false,
                              value: _vm.uploadMethods.CSV,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.uploadFromCsv
                        ? _c(
                            VList,
                            [
                              _c(
                                VListItem,
                                { staticClass: "mb-1" },
                                [
                                  _c(
                                    VListItemTitle,
                                    {
                                      staticClass:
                                        "attribute-upload d-flex flex-column mt-2",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex align-center" },
                                        [
                                          _c(VFileInput, {
                                            ref: "fileInput",
                                            staticClass: "mb-3",
                                            attrs: {
                                              "prepend-icon": "",
                                              accept: ".csv",
                                              rules: _vm.rules,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append-outer",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        VBtn,
                                                        {
                                                          staticClass:
                                                            "add-file-btn",
                                                          attrs: {
                                                            primary: "",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.triggerFindFile,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "actions.findFile"
                                                                )
                                                              ) +
                                                              "\n                      "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              4070222382
                                            ),
                                            model: {
                                              value: _vm.file,
                                              callback: function ($$v) {
                                                _vm.file = $$v
                                              },
                                              expression: "file",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(VDivider),
                              _vm._v(" "),
                              _c(
                                VListItem,
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    VListItemTitle,
                                    { staticClass: "delimiter" },
                                    [
                                      _c("p", { staticClass: "mb-1" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "actions.delimiterPlaceholder"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(VTextField, {
                                        attrs: { rules: _vm.rules, dense: "" },
                                        model: {
                                          value: _vm.delimiter,
                                          callback: function ($$v) {
                                            _vm.delimiter = $$v
                                          },
                                          expression: "delimiter",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { "data-id-e2e": "import-from-feed-option" } },
                    [
                      _c(VDivider),
                      _vm._v(" "),
                      _vm.attributesUpload
                        ? _c(VRadio, {
                            staticClass: "mb-3 mt-3 ml-3",
                            attrs: {
                              label: _vm.$t("actions.selectFeed"),
                              ripple: false,
                              value: _vm.uploadMethods.FEED,
                            },
                            on: { change: _vm.getAttributeSources },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.uploadFromFeed
                        ? _c(
                            VList,
                            [
                              _c(
                                VListItem,
                                { staticClass: "mb-1" },
                                [
                                  _c(
                                    VListItemTitle,
                                    {
                                      staticClass:
                                        "attribute-upload d-flex flex-column mt-2",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "upload-select-title mb-1",
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tkey("actions.sourceTitle")
                                              ) + ":"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("rtls-select", {
                                        attrs: {
                                          items: _vm.attributeSources,
                                          placeholder: _vm.$tkey(
                                            "actions.sourcePlaceholder"
                                          ),
                                        },
                                        on: { change: _vm.getAttributes },
                                        model: {
                                          value:
                                            _vm.dataToUploadFromSource.source,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataToUploadFromSource,
                                              "source",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataToUploadFromSource.source",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                VListItem,
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    VListItemTitle,
                                    {
                                      staticClass:
                                        "attribute-upload d-flex flex-column",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "upload-select-title mb-1",
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$tkey(
                                                  "actions.attributesTitle"
                                                )
                                              ) + ":"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        VAutocomplete,
                                        {
                                          ref: "autocompleteSelect",
                                          staticClass:
                                            "rtls-select--multi-select feed-attributes",
                                          attrs: {
                                            items: _vm.attributes,
                                            "search-input": _vm.searchInput,
                                            "allow-overflow": false,
                                            disabled:
                                              !_vm.dataToUploadFromSource
                                                .source,
                                            placeholder: _vm.$tkey(
                                              "actions.attributesPlaceholder"
                                            ),
                                            rules: _vm.rules,
                                            "item-text": "attributeName",
                                            chips: "",
                                            multiple: "",
                                            clearable: "",
                                            "background-color": "white",
                                            "small-chips": "",
                                            "hide-details": "",
                                            light: "",
                                            "hide-selected": "",
                                            "return-object": "",
                                          },
                                          on: {
                                            "update:searchInput": function (
                                              $event
                                            ) {
                                              _vm.searchInput = $event
                                            },
                                            "update:search-input": function (
                                              $event
                                            ) {
                                              _vm.searchInput = $event
                                            },
                                            change: function ($event) {
                                              _vm.searchInput = ""
                                            },
                                            "click:append": _vm.menuArrow,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      VChip,
                                                      {
                                                        attrs: {
                                                          small: "",
                                                          grey: "",
                                                          close: "",
                                                        },
                                                        on: {
                                                          "click:close":
                                                            function ($event) {
                                                              return _vm.deleteChip(
                                                                item
                                                              )
                                                            },
                                                        },
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.attributeName
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1138731351
                                          ),
                                          model: {
                                            value:
                                              _vm.dataToUploadFromSource
                                                .attributes,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dataToUploadFromSource,
                                                "attributes",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "dataToUploadFromSource.attributes",
                                          },
                                        },
                                        [
                                          !_vm.areAllAttributesSelected
                                            ? _c(
                                                VListItem,
                                                {
                                                  attrs: {
                                                    slot: "prepend-item",
                                                    ripple: "",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.selectAllAttributes,
                                                  },
                                                  slot: "prepend-item",
                                                },
                                                [
                                                  _c(
                                                    VListItemTitle,
                                                    {
                                                      staticClass: "mb-3 mt-3",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$tkey(
                                                            "actions.selectAll"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(VDivider),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { attrs: { "data-id-e2e": "import-custom-option" } },
                    [
                      _c(VDivider),
                      _vm._v(" "),
                      _vm.attributesUpload
                        ? _c(VRadio, {
                            staticClass: "mb-3 mt-3 ml-3",
                            attrs: {
                              label: _vm.$t("actions.selectCustomAttribute"),
                              ripple: false,
                              value: _vm.uploadMethods.CUSTOM,
                            },
                            on: { change: _vm.getAttributeSources },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.customAttributeSelection
                        ? _c(
                            VListItem,
                            { staticClass: "mt-3" },
                            [
                              _c(
                                VListItemTitle,
                                { staticClass: "delimiter" },
                                [
                                  _c("p", { staticClass: "mb-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "actions.customAttributePlaceholder"
                                        )
                                      )
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(VTextField, {
                                    attrs: { rules: _vm.rules, dense: "" },
                                    model: {
                                      value: _vm.customAttribute,
                                      callback: function ($$v) {
                                        _vm.customAttribute = $$v
                                      },
                                      expression: "customAttribute",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hasAttributeImportFromScenarioEnabled
                    ? _c(
                        "div",
                        {
                          attrs: {
                            "data-id-e2e": "import-from-scenario-option",
                          },
                        },
                        [
                          _c(VDivider),
                          _vm._v(" "),
                          _vm.attributesUpload
                            ? _c(VRadio, {
                                staticClass: "mb-3 mt-3 ml-3",
                                attrs: {
                                  label: _vm.$t("actions.selectOtherScenario"),
                                  ripple: false,
                                  value: _vm.uploadMethods.SCENARIO,
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.uploadFromScenario
                            ? _c(
                                VListItem,
                                { staticClass: "mt-3" },
                                [
                                  _c("search-scenario", {
                                    attrs: {
                                      block: "",
                                      "active-scenario-id":
                                        _vm.selectedScenario._id,
                                      rules: _vm.rules,
                                    },
                                    on: {
                                      "scenario-id-selected":
                                        _vm.updateScenarioIdToImportFrom,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasAttributeImportFromPlanogramEnabled
                    ? _c(
                        "div",
                        {
                          attrs: {
                            "data-id-e2e": "import-from-planogram-option",
                          },
                        },
                        [
                          _c(VDivider),
                          _vm._v(" "),
                          _vm.attributesUpload
                            ? _c(VRadio, {
                                staticClass: "mb-3 mt-3 ml-3",
                                attrs: {
                                  label: _vm.$t("actions.selectFromPlanogram"),
                                  ripple: false,
                                  value: _vm.uploadMethods.PLANOGRAM,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    { attrs: { text: "" }, on: { click: _vm.cancelUpload } },
                    [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        "data-id-e2e": "btnAdd",
                        primary: "",
                        disabled: !_vm.valid,
                      },
                      on: { click: _vm.addData },
                    },
                    [_vm._v(_vm._s(_vm.$t("actions.add")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }