import { each } from 'lodash';
import { scenario, architectureGroup } from '@enums/global-filters';

const store = {
  namespaced: true,
  // state should store only selected filters
  // resetAllFilters mutation will set all state values to null
  state: {
    [scenario]: null,
    [architectureGroup]: null,
  },

  getters: {
    getSelectedFilter: state => filterName => state[filterName],
  },

  mutations: {
    setSelectedFilter(state, { filterName, filterValue }) {
      state[filterName] = filterValue;
    },

    resetFilter(state, { filterName }) {
      state[filterName] = null;
    },

    resetAllFilters(state) {
      each(Object.keys(state), stateKey => {
        state[stateKey] = null;
      });
    },
  },

  actions: {
    setSelectedFilter({ commit }, { filterName, filterValue }) {
      commit('setSelectedFilter', { filterName, filterValue });
    },

    resetFilter({ commit }, { filterName }) {
      commit('resetFilter', { filterName });
    },

    resetAllFilters({ commit }) {
      commit('resetAllFilters');
    },
  },
};

export default store;
