var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { display: "contents" } },
    [
      _c(
        "div",
        {
          ref: "current-attr-value",
          staticClass:
            "grid-item-border-bottom flexed-white-bg-grid-item header",
          class: _vm.positioning,
          style: {
            "grid-row": `span ${_vm.rowSpanning}`,
            "grid-column": `span ${_vm.childColumns}`,
            top: `${_vm.previousHeights}px`,
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "cdt-header pa-1 grey-background d-flex flex-column full-width flex-grow-1 bar-containers",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "attr-value-title centred-header d-flex justify-center",
                },
                [
                  _c("div", { staticClass: "inside-box-title" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title-text",
                        attrs: { title: _vm.currentAttrValue },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.currentAttrValue) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  !_vm.expanded
                    ? _c("span", {
                        staticClass: "d-flex box-icon mdi mdi-plus-box",
                        class: {
                          disabled: !_vm.hasChildren,
                          active: _vm.hasChildren,
                        },
                        attrs: { size: "14", primary: "" },
                        on: { click: _vm.toggleExpanded },
                      })
                    : _c("span", {
                        staticClass: "d-flex box-icon mdi mdi-minus-box",
                        staticStyle: { cursor: "pointer" },
                        attrs: { size: "14" },
                        on: { click: _vm.toggleExpanded },
                      }),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.expanded
        ? _c(
            "div",
            {
              ref: "current-cdt-name",
              staticClass:
                "d-flex align-center grid-item-border-bottom flexed-white-bg-grid-item header",
              class: _vm.positioning,
              style: {
                "grid-column": `span ${_vm.childColumns}`,
                top: `${_vm.attrValueHeight + _vm.previousHeights}px`,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-center flex-column full-width flex-grow-1 bar-containers grey-background pa-1",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "centred-header d-flex justify-space-between",
                    },
                    [
                      _c("div", { staticClass: "inside-box-title" }, [
                        _c("div", { staticClass: "title-text" }, [
                          _c(
                            "h2",
                            {
                              staticClass: "header-large",
                              attrs: { title: _vm.currentCdtName },
                            },
                            [_vm._v(_vm._s(_vm.currentCdtName))]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.expanded
        ? _vm._l(_vm.children, function (cdtNode) {
            return _c("cdt-column", {
              key: cdtNode._id,
              attrs: {
                "cdt-node-id": cdtNode._id,
                "cann-group-id": _vm.cannGroupId,
                depth: _vm.depth + 1,
                cdts: _vm.cdts,
                "child-tree": _vm.childTree,
                "previous-heights": _vm.accumulatedHeights,
              },
              on: {
                "child-nodes-expanded": _vm.calculateChildrenNodes,
                "child-nodes-collapsed": _vm.removeChildNodes,
              },
            })
          })
        : [
            _vm.selectedView === _vm.views.Tile
              ? _c("tile-view", {
                  key: `tile-view-${_vm.cdtNodeId}`,
                  attrs: {
                    products: _vm.getProductsForCurrentExpandedView,
                    "group-name": _vm.cdtNodeId,
                  },
                })
              : _c("list-view", {
                  attrs: {
                    products: _vm.getProductsForCurrentExpandedView,
                    "group-name": _vm.cdtNodeId,
                    "previous-heights": _vm.accumulatedHeights,
                    positioning: _vm.positioning,
                  },
                }),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }