import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: {
        value: _vm.value,
        width: "500px",
        "content-class": "dependency-tree-feedback-modal",
      },
      on: { "click:outside": _vm.closeModal },
    },
    [
      _c(
        "dialog-card",
        {
          attrs: { title: _vm.$tkey("modalTitle") },
          on: { close: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c("page-actions", {
                    attrs: {
                      "has-data-errors": _vm.hasDataErrors,
                      "has-data-changes": true,
                      "show-discard": false,
                      "save-btn-text": _vm.saveBtnText,
                      "proceed-btn-text": _vm.proceedBtnText,
                      "show-proceed-button": _vm.ableToProceedWithoutDeleting,
                    },
                    on: {
                      proceed: _vm.onSaveWithoutDeleting,
                      save: _vm.onSave,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "right-btns",
                        fn: function () {
                          return [
                            _c(
                              VBtn,
                              {
                                staticClass: "text-outline",
                                attrs: { text: "", link: "" },
                                on: { click: _vm.closeModal },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("actions.cancel")) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VContainer,
            { staticStyle: { padding: "0" }, attrs: { fluid: "" } },
            [
              _c(
                VRow,
                { staticClass: "d-flex flex-column main-container" },
                [
                  _c(
                    VCol,
                    { staticClass: "pl-0 pb-0 pt-0 pr-4 content" },
                    [
                      _c("p", [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$tkey(`pageInfo.${_vm.page}`)) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.$tkey("proceedText")) + ":")]),
                      _vm._v(" "),
                      _c("hr", { staticClass: "mb-2" }),
                      _vm._v(" "),
                      _vm._l(_vm.results, function (result) {
                        return _c("p", { key: result.name }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(result.count) +
                              " " +
                              _vm._s(_vm.$tkey(`types.${result.name}`)) +
                              " " +
                              _vm._s(_vm.$tkey(result.action)) +
                              "\n          "
                          ),
                        ])
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.warningMessage
            ? _c(
                VContainer,
                { staticClass: "pt-3 pa-2 ma-0" },
                [
                  _c(VAlert, { staticClass: "mb-1", attrs: { text: "" } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t(_vm.warningMessage)) +
                        "\n      "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }