import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isVisible
    ? _c(
        "div",
        { staticClass: "d-flex justify-start reset__container" },
        [
          _c(
            VBtn,
            {
              staticClass: "reset__button",
              class: { "reset__button--hidden": _vm.hideReset },
              attrs: {
                ripple: false,
                icon: "",
                disabled: _vm.isDisabled,
                "data-id-e2e": "resetValueButton",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.reset.apply(null, arguments)
                },
              },
            },
            [_c("reset-icon")],
            1
          ),
          _vm._v(" "),
          _c(VTextField, {
            staticClass: "reset__input",
            class: {
              "reset__input--invalid": _vm.isInvalid,
              "diff-background": _vm.isHighlighted,
            },
            attrs: {
              disabled: _vm.isDisabled,
              "data-id-e2e": "resetValueInput",
              reverse: "",
              "hide-details": "",
            },
            on: {
              blur: _vm.update,
              focus: function ($event) {
                return _vm.setValue(_vm.params.value)
              },
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.update.apply(null, arguments)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "left", 37, $event.key, [
                      "Left",
                      "ArrowLeft",
                    ])
                  )
                    return null
                  if ("button" in $event && $event.button !== 0) return null
                  $event.stopPropagation()
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "right", 39, $event.key, [
                      "Right",
                      "ArrowRight",
                    ])
                  )
                    return null
                  if ("button" in $event && $event.button !== 2) return null
                  $event.stopPropagation()
                },
              ],
            },
            model: {
              value: _vm.formattedNumber,
              callback: function ($$v) {
                _vm.formattedNumber = $$v
              },
              expression: "formattedNumber",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }