import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VIcon,
    {
      staticClass: "icon",
      attrs: { large: "", disabled: _vm.disabled },
      on: { click: _vm.toggleExpand },
    },
    [_vm._v(_vm._s(_vm.name))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }