<template>
  <div>
    <div v-if="rowData" class="striped-table--read-only">
      <div class="striped-table__row striped-table__header">
        <div
          v-for="column in tableColumns"
          :key="column.key"
          class="striped-table__col"
          :class="column.headerClass || ''"
        >
          {{ column.headerName }}
        </div>
      </div>

      <div v-for="row in rowData" class="striped-table__row">
        <div
          v-for="column in tableColumns"
          class="striped-table__col"
          :class="column.classes || ''"
        >
          {{ row[column.key] }}
        </div>
      </div>
    </div>
    <div v-else class="my-3 text-center">
      <p class="ma-0">{{ $t('errors.noDataAvailable') }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { reduce, each, values, get, find, lowerFirst, size } from 'lodash';
import {
  metricTypes,
  metricCategories,
  metricGroups,
  productMetrics,
} from '@enums/product-metrics';

const metricType = metricTypes.distributionChannelsPerformance;
const metrics = productMetrics[metricType];
const productMetricGroups = metricGroups[metricType];

const metricOptionsLookup = {
  [metrics.rank]: { format: 'integer' },
  [metrics.totalSales]: { format: 'float' },
  [metrics.avgSalesWeekStore]: { format: 'float' },
  [metrics.totalVolume]: { format: 'float' },
  [metrics.avgVolumeWeekStore]: { format: 'float' },
  [metrics.selfSwitching]: { format: 'percent' },
};

export default {
  localizationKey:
    'assortmentCanvasPage.productDashboard.relativeProductPerformance.tabs.clickAndCollect',

  props: {
    productKey: {
      type: Number,
      required: true,
    },
  },

  data() {
    const { clickCollect, overall } = productMetricGroups;
    return {
      tableColumns: [
        {
          headerName: this.$tkey('columns.relativePerformanceMetrics'),
          key: 'relativePerformanceMetric',
          headerClass: 'font-italic',
          classes: 'font-weight-bold',
        },
        {
          headerName: this.$tkey(`columns.${lowerFirst(clickCollect)}`),
          key: clickCollect,
          headerClass: 'text-center',
          classes: 'text-center',
        },
        {
          headerName: this.$tkey(`columns.${lowerFirst(overall)}`),
          key: overall,
          headerClass: 'text-center',
          classes: 'text-center',
        },
      ],
      rowData: null,
    };
  },

  computed: {
    ...mapGetters('context', ['getCurrentNumericLocale']),

    requiredCurrency() {
      return this.$t(`currencies.${this.getCurrentNumericLocale}`);
    },
  },

  watch: {
    productKey: {
      handler(newProductKey, oldProductKey) {
        if (!newProductKey || newProductKey === oldProductKey) return;
        this.init(newProductKey);
      },
    },
  },

  async created() {
    await this.init();
  },

  methods: {
    ...mapActions('toolData', ['fetchRelativeProductPerformanceMetrics']),

    async init() {
      const metricsData = await this.fetchRelativeProductPerformanceMetrics({
        productKey: this.productKey,
        metricType,
      });
      const productMetricCategory = metricCategories[metricType].clickCollect;
      const clickCollectData = find(metricsData, m => m.categoryName === productMetricCategory);
      this.rowData = size(clickCollectData)
        ? this.generateRows(clickCollectData.categoryMetrics)
        : null;
    },

    generateRows(data) {
      const rowsByMetric = reduce(
        data,
        (rows, category) => {
          each(metricOptionsLookup, (settings, metric) => {
            // If a metric row does not exist, create one
            if (!rows[metric]) {
              rows[metric] = {
                // Currency will be ignored for translations that do not use this value
                relativePerformanceMetric: this.$tkey(`metrics.${metric}`, {
                  currency: this.requiredCurrency,
                }),
              };
            }
            rows[metric][category.metricGroup] = this.valueFormatter(
              get(category, `metrics.${metric}`, 0),
              get(settings, 'format', 'float')
            );
          });
          return rows;
        },
        {}
      );
      return values(rowsByMetric);
    },

    valueFormatter(value, format) {
      if (!value) value = 0;
      const isPercent = format === 'percent';
      const formattedValue = this.formatNumber({
        number: isPercent ? value * 100 : value,
        format,
      });
      return isPercent ? `${formattedValue}%` : formattedValue;
    },
  },
};
</script>

<style lang="scss" scoped>
.striped-table--read-only {
  .striped-table__col:first-child {
    flex: 45% 0 0;
  }
}
</style>
