import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VForm,
    {
      ref: "form",
      attrs: { autocomplete: "off" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.createNewScenario.apply(null, arguments)
        },
      },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        VContainer,
        [
          _vm.isOpen
            ? _c(
                VRow,
                { staticClass: "form-body" },
                [
                  _c(VCol, [
                    _c(
                      "div",
                      { staticClass: "form-container" },
                      [
                        _c("rtls-text-field", {
                          key: _vm.nameKey,
                          attrs: {
                            rules: _vm.rules,
                            "server-error": _vm.scenarioNameServerError,
                            label: _vm.$t("scenarios.scenarioName"),
                          },
                          on: {
                            change: function ($event) {
                              _vm.scenarioNameServerError = ""
                            },
                          },
                          model: {
                            value: _vm.scenarioName,
                            callback: function ($$v) {
                              _vm.scenarioName =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "scenarioName",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            staticClass: "float-right",
                            attrs: {
                              save: "",
                              disabled: !_vm.valid,
                              type: "submit",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.save")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VSpacer),
                  _vm._v(" "),
                  _c(VSpacer),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            VRow,
            [
              _c(
                VBtn,
                {
                  attrs: {
                    id: "button-add",
                    disabled:
                      _vm.isOpen ||
                      !_vm.hasPermission(_vm.userPermissions.canCreateScenario),
                    primary: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.isOpen = true
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("scenarios.newScenario")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }