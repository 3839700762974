<template>
  <div class="layout">
    <div class="layout__wrapper overflow-ellipsis">
      <input
        :value="params.layout._id"
        name="selected"
        type="radio"
        @input="params.selectLayoutFunction(params.layout._id)"
      />
      <span class="layout__name">{{ params.layout.name }}</span>
    </div>
    <div v-if="isDeleteVisible">
      <v-icon @click="params.onDeleteLayout(params.layout)">$trash</v-icon>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { get } from 'lodash';

export default Vue.extend({
  name: 'agGridLayoutRow',

  computed: {
    ...mapGetters('context', ['currentUserProfile']),

    isDeleteVisible() {
      return get(this.params, 'layout.createdBy._id', '') === this.currentUserProfile._id;
    },
  },
});
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__wrapper {
    max-width: 300px;
  }

  &__name {
    text-decoration: underline;
    font-size: 12px !important;
    padding-left: 10px;
    text-transform: capitalize;
  }
}
</style>
