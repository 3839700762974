<template>
  <v-dialog :value="value" width="400px" @click:outside="closeModal">
    <dialog-card :title="$tkey('modalTitle')" @close="closeModal">
      <v-container fluid class="swap-parameters__container">
        <v-row>
          <v-col class="pa-0">
            <v-form ref="swapParametersForm" v-model="valid" autocomplete="off" @submit.prevent>
              <v-row class="mt-5 mb-3">
                <v-col class="pa-0">
                  <span class="swap-parameters__label d-block">
                    {{ $tkey('form.runDescription') }}
                  </span>
                  <rtls-text-field
                    v-model="runDescription"
                    :rules="runDescriptionRules"
                    grey
                    single-line
                    data-id-e2e="swapDescriptionInput"
                    class="swap-parameters__input"
                  />
                </v-col>
              </v-row>
              <v-row class="border-bottom pb-4 mb-3">
                <v-col cols="4" class="pa-0">
                  <span class="swap-parameters__label d-block">
                    {{ $tkey('form.maxNumberOfSwaps') }}
                  </span>
                  <rtls-text-field
                    v-model.number="maxSwaps"
                    :rules="greaterThanZeroRules"
                    grey
                    class="swap-parameters__input swap-parameters__input--short"
                    single-line
                    run-validations-on-creation
                    data-id-e2e="swapMaxNoOfSwapsInput"
                  />
                </v-col>
              </v-row>
              <v-row class="border-bottom pb-4 mb-4">
                <v-col cols="7" class="pa-0 pr-5">
                  <span class="swap-parameters__label d-block">
                    {{ $tkey('form.forecastPeriod') }}
                  </span>
                  <rtls-calendar-picker
                    v-model="weekStartDate"
                    :allowed-dates="allowedStartDates"
                    :display-date-format="getDateFormats.long"
                    data-id-e2e="swapForecasterStartDateSelect"
                  />
                </v-col>
                <v-col cols="5" class="pa-0">
                  <span class="swap-parameters__label d-block">
                    {{ $tkey('form.numberOfSlices') }}
                  </span>
                  <rtls-text-field
                    v-model.number="numberOfSlices"
                    :rules="greaterThanZeroRules"
                    grey
                    class="swap-parameters__input swap-parameters__input--short"
                    single-line
                    data-id-e2e="swapNumberOfSlicesInput"
                  />
                </v-col>
              </v-row>
              <v-row class="border-bottom pb-4 mb-3">
                <v-col class="pa-0 d-flex align-center">
                  <span class="swap-parameters__label mb-0 pr-3">
                    {{ $tkey('form.forecastSliceLength') }}
                  </span>
                  <rtls-select
                    v-model="sliceLength"
                    hide-details
                    width="100%"
                    :items="forecastSliceLengthsOptions"
                    :placeholder="$t('general.select')"
                    grey
                    data-id-e2e="swapForecastSliceLengthSelect"
                  />
                </v-col>
              </v-row>
              <v-row class="border-bottom mb-3">
                <v-col class="pa-0 d-flex align-center swap-parameters__forecast-end">
                  <span class="swap-parameters__label mb-0 pr-1">
                    {{ $tkey('form.forecastEnd') }}:
                  </span>
                  <span>
                    {{ forecastEndDate | formatDate(getDateFormats.longWithTime) }}
                  </span>
                  <error-triangle class="end-date-error-triangle" :errors="errors" />
                </v-col>
              </v-row>
              <v-row class="border-bottom pb-7 mb-3">
                <v-col class="pa-0 d-flex align-center">
                  <span class="swap-parameters__label mb-0 pr-3">
                    {{ $tkey('form.optimiseAccordingTo') }}
                  </span>
                  <rtls-select
                    :disabled="validatingOptimiseOptions"
                    :value="optimiseAccordingTo"
                    :items="sizeSelectItems"
                    :error-messages="optimiseAccordingToErrorMessage"
                    :placeholder="$t('sizeTypes.noOption')"
                    :append-outer-icon="sizeTypeIconMap[optimiseAccordingTo] || '$empty-cube'"
                    width="250px"
                    data-id-e2e="swapOptimiseAccordingToSelect"
                    @change="selectSetting"
                  />
                </v-col>
              </v-row>
              <v-row class="border-bottom pb-4 mb-4">
                <v-col cols="4" class="pa-0 pr-5">
                  <span class="swap-parameters__label d-block">
                    {{ $tkey('form.unitVolume') }}
                  </span>
                  <rtls-text-field
                    v-model.number="volume"
                    :rules="rules"
                    grey
                    class="swap-parameters__input swap-parameters__input--short"
                    single-line
                    data-id-e2e="swapOptimiserVolumeInput"
                  />
                </v-col>
                <v-col cols="4" class="pa-0">
                  <span class="swap-parameters__label d-block">
                    {{ $tkey('form.sales') }}
                  </span>
                  <rtls-text-field
                    v-model.number="sales"
                    :rules="rules"
                    grey
                    class="swap-parameters__input swap-parameters__input--short"
                    single-line
                    data-id-e2e="swapOptimiserSalesInput"
                  />
                </v-col>
                <v-col cols="4" class="pa-0">
                  <div class="d-flex justify-space-between">
                    <span class="swap-parameters__label d-block">
                      {{ $tkey('form.margin') }}
                    </span>
                    <assortment-tooltip
                      v-if="usesMarginWithoutFunding"
                      :title="$t('general.warning')"
                      :tooltip-sections="margingWithoutFundingSections"
                      :width="500"
                    />
                  </div>
                  <rtls-text-field
                    v-model.number="margin"
                    :rules="rules"
                    grey
                    class="swap-parameters__input swap-parameters__input--short"
                    single-line
                    data-id-e2e="swapOptimiserMarginInput"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:footer>
        <page-actions
          :has-data-errors="hasDataErrors"
          :has-data-changes="true"
          :show-discard="false"
          save-btn-text="optimiseSwaps"
          @save="runOptimiseSwaps"
        >
          <template v-slot:right-btns>
            <v-row class="actions-row justify-space-between flex-grow-1">
              <v-btn class="text-outline" text link @click="closeModal">
                {{ $t('actions.cancel') }}
              </v-btn>
            </v-row>
          </template>
        </page-actions>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters, mapActions } from 'vuex';
import { map, every, get, isNull, size, filter, assign, without } from 'lodash';
import { forecastSliceLengths } from '@enums/forecast-slice-lengths';
import SIZE_OPTIONS from '@enums/size-types';
import inputValidationMixin from '@/js/mixins/input-validations';
import datesMixin from '@/js/mixins/date-utils';
import { scenarioOptimiserTooltipOptionsMixin } from '@/js/mixins/tooltip-options';

const defaultValues = {
  runDescription: null,
  maxSwaps: null,
  weekStartDate: null,
  numberOfSlices: 26,
  sliceLength: 2,
  optimiseAccordingTo: null,
  volume: 1,
  sales: 6,
  margin: 3,
};
// List of falsy entries, except zero, to be used on form validations
const falsyValues = [undefined, null, false, NaN, ''];

export default {
  name: 'SwapParametersModal',
  mixins: [inputValidationMixin, datesMixin, scenarioOptimiserTooltipOptionsMixin],
  localizationKey: 'swapParametersModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      ...defaultValues,
      forecastSliceLengthsOptions: map(forecastSliceLengths, n => ({
        text: `${n} ${this.$tkey('weeks')}`,
        value: n,
      })),
      valid: true,
      runDescriptionRules: [this.isNotEmpty, this.isUniqueRunDescription],
      rules: [this.isNotEmpty, this.isInteger],
      greaterThanZeroRules: [this.isNotEmpty, this.isInteger, v => this.isNumberGreaterThan(v, 0)],
      optimiseAccordingToErrorMessage: [],
      validatingOptimiseOptions: false,
      sizeTypeIconMap: {
        [SIZE_OPTIONS.linearSpace]: '$linear-space',
        [SIZE_OPTIONS.cubicSpace]: '$cubic-space',
        [SIZE_OPTIONS.horizontalSpace]: '$horizontal-space',
        [SIZE_OPTIONS.frontalSpace]: '$frontal-space',
        [SIZE_OPTIONS.productCount]: '$empty-cube',
      },
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('simpleSwapRuns', ['getSimpleSwapRunDescriptions']),
    ...mapGetters('context', ['getDateFormats', 'getDefaultReverseFormat', 'getClientConfig']),
    ...mapGetters('scenarios', ['selectedScenario']),

    allowedStartDates() {
      const {
        performanceStartDate,
        performanceEndDate,
        performanceExcludedDates,
      } = this.selectedWorkpackage;
      const parsedExcludedDates = map(
        performanceExcludedDates || [],
        ped => moment.utc(ped).format('YYYY-MM-DD') // no need to use config because this is for local comparison only
      );
      return date => {
        const parsedDate = moment.utc(date);
        // requires moment 2.13.0, currently on 2.19.2 https://momentjscom.readthedocs.io/en/latest/moment/05-query/06-is-between/
        // [ is inclusive. Forecaster start date can be >= performanceStartDate.
        // ) is exclusive. Forecaster start date must be < performanceEndDate
        // shouldn't matter about exclusive portion because end dates should always be Sundays and this must be Monday.
        return (
          parsedDate.isoWeekday() === 1 &&
          parsedDate.isBetween(performanceStartDate, performanceEndDate, 'day', '[)') &&
          parsedExcludedDates.includes(date) === false
        );
      };
    },

    forecastEndDate() {
      return this.calculateEndDate({
        startDate: this.weekStartDate,
        weeks: this.numberOfForecastWeeks,
        dateFormat: this.getDefaultReverseFormat,
      });
    },

    numberOfForecastWeeks() {
      const sliceValue = this.sliceLength;
      return this.numberOfSlices * sliceValue;
    },

    isEndDateValid() {
      return (
        moment.utc(this.selectedWorkpackage.performanceEndDate) >= moment.utc(this.forecastEndDate)
      );
    },

    isStartDateValid() {
      return (
        moment.utc(this.weekStartDate) >= moment.utc(this.selectedWorkpackage.performanceStartDate)
      );
    },

    hasEmptyFields() {
      // Disables optimise swaps button before form changes have been made to
      // trigger form validation rules
      const swapParameters = {
        runDescription: this.runDescription,
        maxSwaps: this.maxSwaps,
        weekStartDate: this.weekStartDate,
        numberOfSlices: this.numberOfSlices,
        sliceLength: this.sliceLength,
        optimiseAccordingTo: this.optimiseAccordingTo,
        volume: this.volume,
        sales: this.sales,
        margin: this.margin,
      };
      const parameterValues = Object.values(swapParameters);
      return size(parameterValues) !== size(without(parameterValues, ...falsyValues));
    },

    errors() {
      return {
        [this.$tkey('errorMessages.endDateOutOfBoundsError')]: this.isEndDateValid,
        [this.$tkey('errorMessages.periodOutOfBoundsError')]: this.isStartDateValid,
      };
    },

    error() {
      return !every(this.errors);
    },

    hasDataErrors() {
      return !this.valid || this.error || this.hasEmptyFields;
    },

    sizeSelectItems() {
      return Object.keys(SIZE_OPTIONS).map(key => ({
        value: SIZE_OPTIONS[key],
        text: this.$t(`sizeTypes.${key}`),
      }));
    },

    usesMarginWithoutFunding() {
      return get(this.getClientConfig, 'features.marginWithoutSalesEnabled', false);
    },
  },

  watch: {
    value() {
      if (!this.value) return;
      // Reset parameters when modal is reopened
      this.resetParameters();
    },

    optimiseAccordingTo: {
      immediate: true,
      handler(v) {
        this.isOptimiseAccordingToValid(v);
      },
    },
  },

  created() {
    this.setMargin();
    this.fetchSimpleSwapRuns();
  },

  methods: {
    ...mapActions('simpleSwapRuns', ['fetchSimpleSwapRuns', 'addSimpleSwapRun']),
    ...mapActions('scenarios', ['hasValidProductAttributes']),

    resetParameters() {
      assign(this, defaultValues);
      this.setMargin();
      this.setInitialForecastStartDate();
    },

    setMargin() {
      this.margin = this.usesMarginWithoutFunding ? 0 : this.margin;
    },

    isUniqueRunDescription(value) {
      if (!value) return true;

      const runDescription = value.toLowerCase().trim();
      const matchingDescriptions = filter(
        this.getSimpleSwapRunDescriptions,
        rd => rd.toLowerCase() === runDescription
      );
      if (size(matchingDescriptions) > 0) {
        return this.$t('validationErrors.unique', [this.$tkey('form.runDescription')]);
      }
      return true;
    },

    isNumberGreaterThan(value, minimum) {
      // Prevents validation error when form is reset
      if (isNull(value)) return true;
      return this.isGreaterThan(value, minimum);
    },

    setInitialForecastStartDate() {
      if (!this.selectedWorkpackage) return;

      this.weekStartDate = this.calculateStartDate({
        endDate: this.selectedWorkpackage.performanceEndDate,
        weeks: this.numberOfForecastWeeks,
        dateFormat: this.getDefaultReverseFormat,
      });

      let isWeekStartDateInvalid = true;

      // If weekStartDate is not valid we set weekStartDate as nearest valid date
      while (isWeekStartDateInvalid) {
        if (this.allowedStartDates(this.weekStartDate)) {
          isWeekStartDateInvalid = false;
        } else {
          this.weekStartDate = moment
            .utc(this.weekStartDate)
            .add(7, 'days')
            .format(this.getDefaultReverseFormat);
        }
      }

      if (!this.isEndDateValid) {
        this.numberOfSlices = Math.round(
          moment
            .utc(this.selectedWorkpackage.performanceEndDate)
            .diff(moment.utc(this.weekStartDate), 'week') / 2
        );
      }
    },

    async runOptimiseSwaps() {
      if (this.hasDataErrors) return false;

      const payload = {
        scenarioId: get(this.selectedScenario, '_id'),
        runDescription: this.runDescription,
        maxSwaps: parseInt(this.maxSwaps, 10),
        forecastParameters: {
          weekStartDate: this.formatDateForSave(this.weekStartDate, this.getDefaultReverseFormat),
          numberOfSlices: parseInt(this.numberOfSlices, 10),
          sliceLength: parseInt(this.sliceLength, 10),
        },
        optimiserSettings: {
          optimiseAccordingTo: this.optimiseAccordingTo,
          utilityWeights: {
            volume: parseInt(this.volume, 10),
            sales: parseInt(this.sales, 10),
            margin: parseInt(this.margin, 10),
          },
        },
      };

      await this.addSimpleSwapRun(payload);
      this.closeModal();
    },

    closeModal() {
      this.$emit('close');
    },

    async isOptimiseAccordingToValid(value) {
      if (!value) {
        this.optimiseAccordingToErrorMessage.push(this.$t('validationErrors.required'));
        return;
      }

      if (value === SIZE_OPTIONS.productCount) return;
      const key = Object.keys(SIZE_OPTIONS).find(k => SIZE_OPTIONS[k] === value);
      this.validatingOptimiseOptions = true;
      const { data } = await this.hasValidProductAttributes({
        scenarioId: this.selectedScenario._id,
        optimiseAccordingTo: key,
      });
      this.validatingOptimiseOptions = false;
      if (!data) {
        this.optimiseAccordingToErrorMessage.push(this.$t(`validationErrors.sizeType.${key}`));
      }
    },

    selectSetting(setting) {
      this.optimiseAccordingToErrorMessage = [];
      this.optimiseAccordingTo = setting;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.swap-parameters {
  &__container {
    border-bottom: 1px solid $assortment-divider-colour;
    font-size: 1.2rem;
    padding: 0;
  }

  &__label {
    color: $assortment-text-colour;
    font-weight: bold;
    margin-bottom: 8px;
    white-space: nowrap;
  }

  &__input {
    &--short {
      width: 117px;
    }
  }

  &__forecast-end {
    line-height: 2.4rem;
  }
}

.end-date-error-triangle {
  padding-left: 8px;
}

::v-deep {
  .dialog-card__footer {
    border-top-width: 0px;
  }

  .rtls-calendar-picker .v-input__slot {
    background-color: $assortment-control-secondary-bg-colour;
  }
}
</style>
