import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column store-class-container" },
    [
      _c(
        VContainer,
        { staticClass: "store-class-panel", attrs: { fluid: "" } },
        [
          _c(
            VRow,
            { staticClass: "pa-0" },
            [
              _c(
                VCol,
                {
                  staticClass: "py-0 d-flex align-center",
                  attrs: { cols: "3" },
                },
                [
                  _c("div", { staticClass: "d-flex flex-column mr-6" }, [
                    _c("b", [_vm._v(_vm._s(_vm.$tkey("spacebreaks")))]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.accordingToMessage) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        disabled: _vm.isAddSpacebreakDisabled,
                        small: "",
                        primary: "",
                      },
                      on: { click: _vm.createSpacebreak },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tkey("addSpacebreak")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.hasSpacebreakFromDataEnabled
                    ? _c(
                        VAlert,
                        {
                          staticClass: "alert--small ml-5 mb-0",
                          attrs: { type: "info", text: "" },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$tkey(
                                  _vm.storeClass.isEditingDisabled
                                    ? "storeClassDisabledForEditing"
                                    : "storeClassEnabledForEditing"
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(VCol, {
                staticClass: "py-0 d-flex align-center",
                attrs: { cols: "4" },
              }),
              _vm._v(" "),
              _c(VCol, { staticClass: "py-0", attrs: { cols: "1" } }, [
                _c("br"),
                _vm._v(_vm._s(_vm.$tkey("totalStoreCount")) + " "),
              ]),
              _vm._v(" "),
              _c(VCol, { staticClass: "py-0", attrs: { cols: "3" } }, [
                _c("br"),
                _vm._v(
                  "\n        " + _vm._s(_vm.$tkey("availableFA")) + "\n      "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VContainer,
        {
          staticClass: "fixed-height-container pl-0 pt-2 pb-0",
          attrs: { fluid: "" },
        },
        [
          _c(
            VRow,
            { staticClass: "tab-row-item" },
            [
              _c(
                VCol,
                { staticClass: "panel-container pa-0", attrs: { cols: "8" } },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "spacebreaks-panel pt-0",
                      attrs: { cols: "12" },
                    },
                    _vm._l(
                      _vm.spacebreaksList,
                      function (spacebreak, spacebreakIndex) {
                        return _c("spacebreak", {
                          key: spacebreak._id,
                          attrs: {
                            "spacebreak-index": spacebreakIndex,
                            spacebreak: spacebreak,
                            "furniture-mapping": _vm.furnitureIdNameMap,
                            "store-class-id": _vm.storeClassId,
                            "is-remove-disabled": _vm.isRemoveDisabled,
                            spacebreaks: _vm.storeClass.spacebreaks,
                          },
                          on: {
                            updateSpacebreak: function ($event) {
                              return _vm.updateSpacebreakForStoreClass({
                                storeClassId: _vm.storeClass._id,
                                updatedSpacebreak: $event,
                              })
                            },
                            removeSpacebreak: function ($event) {
                              return _vm.removeSpacebreak(spacebreak._id)
                            },
                            removeAll: function ($event) {
                              return _vm.removeAllFurnitureFromSpacebreak({
                                storeClassId: _vm.storeClass._id,
                                spacebreakId: spacebreak._id,
                              })
                            },
                            addAll: function ($event) {
                              return _vm.addAllAvailableFurnitureToSpacebreak({
                                storeClassId: _vm.storeClass._id,
                                spacebreakId: spacebreak._id,
                                availableFurnitureIds:
                                  _vm.availableFurnitureIds,
                              })
                            },
                            removeOne: function ($event) {
                              return _vm.removeOneFurnitureFromSpacebreak({
                                storeClassId: _vm.storeClass._id,
                                spacebreakId: spacebreak._id,
                                furnitureId: $event,
                              })
                            },
                            addOne: function ($event) {
                              return _vm.addOneFurnitureToSpacebreak({
                                storeClassId: _vm.storeClass._id,
                                spacebreakId: spacebreak._id,
                                furnitureId: $event,
                              })
                            },
                          },
                        })
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VCol,
                {
                  staticClass: "archetype-list-container py-0",
                  attrs: { cols: "3" },
                },
                [
                  _c("available-furniture-archetypes-list", {
                    attrs: {
                      "available-furniture-ids": _vm.availableFurnitureIds,
                      "furniture-mapping": _vm.furnitureIdNameMap,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }