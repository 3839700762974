<template>
  <v-dialog
    :value="value"
    width="500px"
    content-class="copy-decisions-modal"
    data-id-e2e="copyDecisionsModal"
    @click:outside="closeModal"
  >
    <dialog-card :title="$tkey('modalTitle')" @close="closeModal">
      <v-container fluid class="pa-0">
        <v-row class="d-flex flex-column main-container">
          <v-col class="pl-0 pb-0 pt-0 pr-4 content">
            <!-- Search scenario section -->
            <v-row>
              <search-scenario
                block
                data-id-e2e="copyDecisionsSearchScenarioSection"
                :rules="[]"
                :active-scenario-id="selectedScenario._id"
                :scenarios-search-query="scenariosSearchQuery"
                @scenario-id-selected="retrieveMatchingProductsInfo"
              />
            </v-row>

            <!-- Show progress bar if products data is loading -->
            <div v-if="isLoading" class="d-flex justify-center align-center pt-1">
              <v-progress-circular indeterminate color="primary" />
            </div>

            <div v-else>
              <!-- Matching products indication section -->
              <v-row v-if="matchingProductsInfo.matchingProductCount" class="pt-3">{{
                $tkey('matchingProducts', [matchingProductsInfo.matchingProductCount])
              }}</v-row>
              <v-row v-if="matchingProductsInfo.nonMatchingProductCount" class="pt-1">{{
                $tkey('nonMatchingProducts', [matchingProductsInfo.nonMatchingProductCount])
              }}</v-row>
            </div>

            <!-- Decisions selection section -->
            <v-row class="pt-3">
              {{ $tkey('decisionsToCopy') }}
            </v-row>
            <v-row class="pt-1">
              <v-checkbox
                id="select-sister-products-decisions"
                :input-value="selectedDecisions.sisterProducts"
                hide-details
                disabled
                class="rtls-checkbox ml-2 pl-2 mx-2"
              />
              <label for="select-sister-products-decisions">
                {{ $t('productModellingPage.sisterProducts') }}
              </label>
            </v-row>
            <v-row>
              <v-checkbox
                id="select-trend-applied-decisions"
                :input-value="selectedDecisions.trendApplied"
                hide-details
                class="rtls-checkbox ml-2 pl-2 mx-2"
                @change="selectedDecisions.trendApplied = $event"
              />
              <label for="select-trend-applied-decisions">
                {{ $t('productModellingPage.trendApplied') }}
              </label>
            </v-row>
            <v-row>
              <v-checkbox
                id="select-ros-override-decisions"
                :input-value="selectedDecisions.rateOfSaleOverride"
                hide-details
                class="rtls-checkbox ml-2 pl-2 mx-2"
                @change="selectedDecisions.rateOfSaleOverride = $event"
              />
              <label for="select-ros-override-decisions">
                {{ $t('productModellingPage.salesOverride') }}
              </label>
            </v-row>

            <!-- Info message -->
            <v-container class="pt-3 pa-2 ma-0">
              <v-alert text class="mb-1">
                {{ $tkey('info') }}
              </v-alert>
            </v-container>
          </v-col>
        </v-row>
      </v-container>

      <!-- Footer -->
      <template v-slot:footer>
        <page-actions
          :has-data-errors="hasDataErrors"
          :has-data-changes="true"
          :show-discard="false"
          :save-loading="isProcessing"
          save-btn-text="copyDecisions"
          @save="copyDecisions"
        >
          <template v-slot:right-btns>
            <v-btn class="text-outline" text link @click="closeModal">
              {{ $t('actions.cancel') }}
            </v-btn>
          </template>
        </page-actions>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { isEmpty, filter, keys } from 'lodash';
import { jobStatuses } from '@enums/jobapi';

export default {
  name: 'CopyDecisionsModal',
  localizationKey: 'productModellingPage.copyDecisionsModal',

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isLoading: false,
      sourceScenarioId: null,
      matchingProductsInfo: {},
      selectedDecisions: {
        sisterProducts: true,
        trendApplied: false,
        rateOfSaleOverride: false,
      },
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('scenarioProducts', ['loading']),

    hasDataErrors() {
      return this.isLoading || !this.isMatchingProductsDataLoaded;
    },

    isMatchingProductsDataLoaded() {
      return this.sourceScenarioId && !isEmpty(this.matchingProductsInfo);
    },

    scenariosSearchQuery() {
      return {
        'jobs.setupProductModelling.status': jobStatuses.finished,
      };
    },
  },

  watch: {
    isProcessing(isProcessing, wasProcessing) {
      if (!isProcessing && wasProcessing) this.closeModal();
    },
  },

  methods: {
    ...mapActions('scenarioProducts', ['getMatchingProductsInfo', 'copyModellingDecisions']),

    closeModal(params) {
      this.$emit('close', params);
      // Do not clear user selections on close, leave as they are
    },

    async copyDecisions() {
      this.$emit('confirm');
      const selectedDecisions = filter(
        keys(this.selectedDecisions),
        k => this.selectedDecisions[k]
      );
      const change = await this.copyModellingDecisions({
        sourceScenarioId: this.sourceScenarioId,
        selectedDecisions,
      });
      this.$emit('diff-change', change.data);
    },

    updateSelectedScenario(scenarioId) {
      this.sourceScenarioId = scenarioId;

      // If user is clearing the scenario, discard the changes
      if (!scenarioId) this.discard();
    },

    async retrieveMatchingProductsInfo(scenarioId) {
      this.updateSelectedScenario(scenarioId);
      if (!scenarioId) return;

      this.isLoading = true;

      try {
        this.matchingProductsInfo = await this.getMatchingProductsInfo(this.sourceScenarioId);
      } catch (err) {
        console.error(err);
      }
      this.isLoading = false;
    },

    // Reset all data to defaults
    discard() {
      this.isLoading = false;
      this.sourceScenarioId = null;
      this.matchingProductsInfo = {};
      this.selectedDecisions = {
        sisterProducts: true,
        trendApplied: false,
        rateOfSaleOverride: false,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.sub-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.main-container {
  border-bottom: 1px solid $assortment-divider-colour;
  max-height: 350px;
  overflow-y: auto;
  flex-basis: auto;
  font-size: 1.3rem;
}

::v-deep {
  .dialog-card__footer {
    border-top-width: 0px;
  }

  .v-alert {
    background: $assortment-warning-info-background !important;
    border: 1px solid $assortment-warning-info-border !important;
    border-radius: 0 !important;
    padding: 14px;

    &:before {
      content: none;
    }

    &__content {
      color: $assortment-text-colour;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }
}
</style>
