import { VContainer } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { attrs: { fluid: "" } },
    [
      _c(
        VLayout,
        {
          staticClass: "compound-extracts__wrapper h-100",
          attrs: { column: "" },
        },
        [
          _vm.isRoot
            ? _c(
                "div",
                { staticClass: "d-flex flex-column h-100" },
                [_c("compound-extracts-table")],
                1
              )
            : _c("router-view"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }