<template>
  <v-container>
    <v-row class="checkpoint-row d-flex align-center" cols="12">
      <v-col cols="1" class="pa-0">
        <v-switch
          v-model="referenceCheckpoint"
          :disabled="isObservedCheckpoint || isEditingDisabled"
          :label="$tkey('checkpoints.reference')"
        />
      </v-col>

      <v-col cols="3" class="pa-0">
        <v-form v-model="valid" class="d-flex" autocomplete="off" @submit.prevent="">
          <rtls-text-field
            :key="nameKey"
            v-model.trim="checkpointMeta.name"
            :disabled="isEditingDisabled"
            class="text-field"
            :rules="rules"
            @blur="save(checkpoint)"
            @keyup.enter="save(checkpoint)"
            @keyup.esc="discard(checkpoint)"
          />
        </v-form>
      </v-col>

      <v-col cols="5" class="pa-0 checkpoint-details-container">
        <div class="checkpoint-details-info">
          <div class="checkpoint-details-item">
            <p class="checkpoint-details-title">
              {{ $tkey('checkpoints.dateAndTime') }}:
              <span>{{
                checkpointMeta.createdDate | formatDate(getDateFormats.longWithTimeAndMeridiem)
              }}</span>
            </p>
          </div>
          <div class="checkpoint-details-item">
            <p class="checkpoint-details-title">
              {{ $tkey('checkpoints.forecastStart') }}:
              <span> {{ displayForecastParameter('start') }} </span>
            </p>
          </div>
        </div>
        <div class="checkpoint-details-info">
          <div class="checkpoint-details-item">
            <p class="checkpoint-details-title">
              {{ $tkey('checkpoints.sales') }}:
              <span>{{ displayForecastResult('forecastedSales') }}</span>
            </p>
          </div>
          <div class="checkpoint-details-item">
            <p class="checkpoint-details-title">
              {{ $tkey('checkpoints.forecastEnd') }}:
              <span> {{ displayForecastParameter('end') }} </span>
            </p>
          </div>
        </div>
        <div class="checkpoint-details-info">
          <div class="checkpoint-details-item">
            <p class="checkpoint-details-title">
              {{ $tkey('checkpoints.margin') }}:
              <span>{{ displayForecastResult('forecastedMargin') }}</span>
            </p>
          </div>
          <div class="checkpoint-details-item">
            <p class="checkpoint-details-title">
              {{ $tkey('checkpoints.forecastLength') }}:
              <span> {{ displayForecastParameter('length') }} </span>
            </p>
          </div>
        </div>
      </v-col>

      <v-col class="buttons-container d-flex justify-end pa-0" cols="2">
        <v-btn
          data-id-e2e="btnRevertCheckpoint"
          outlined
          :disabled="isObservedCheckpoint || checkpoint.isNotRevertable || isEditingDisabled"
          class="assortment-btn-clear"
          @click="initRevertCheckpointToCanvas(checkpoint)"
        >
          {{ $tkey('checkpoints.revert.action') }}
        </v-btn>
        <v-btn
          data-id-e2e="btnRunForecast"
          primary
          depressed
          :disabled="isEditingDisabled"
          :loading="hasForecasterJobRunning"
          @click="runForecaster(checkpoint)"
          >{{ $tkey('checkpoints.runForecast') }}</v-btn
        >
        <v-btn
          v-if="showDelete"
          data-id-e2e="btnDeleteCheckpoint"
          class="delete-button"
          :disabled="isEditingDisabled"
          icon
          text
          @click="initDelete(checkpoint._id)"
        >
          <v-icon size="24">$trash</v-icon>
        </v-btn>
        <span v-else style="width: 24px" />
      </v-col>
    </v-row>
    <v-divider horizontal />
    <v-row class="checkpoint-details-row" cols="12">
      <v-col class="pa-0">
        <p class="checkpoint-type">{{ checkpointGeneratedBy() }}</p>
      </v-col>
      <div v-if="showNotImplemented" class="show-details greyed-out">
        <span>{{ $tkey('checkpoints.showDetails') }}</span>
        <v-icon size="21" primary class="icon">expand_more</v-icon>
      </div>
    </v-row>

    <!-- dialog to display forecaster setup -->
    <main-dialog ref="forecasterSetup" :width="'25%'" :border="true">
      <template v-slot:content="">
        <div class="forecasterSetup">
          <v-row class="border-bottom">
            <v-col cols="6">
              <div class="date-input">
                <div class="wp-scope__date-input-container">
                  <div data-id-e2e="forecasterStartDateField" class="date-input">
                    <span>{{ $tkey('forecasterSetupModal.forecastStart') }}:</span>
                    <rtls-calendar-picker
                      v-model="forecasterSetupWeekStartDate"
                      :allowed-dates="allowedStartDates"
                      :display-date-format="getDateFormats.long"
                      :icon-title="$tkey('forecasterSetupModal.iconTitle')"
                    />
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <span>{{ $tkey('forecasterSetupModal.numberOfSlices') }}:</span>
              <rtls-text-field
                v-model="forecasterSetupNumberOfSlices"
                data-id-e2e="drpForecasterSetupNumberOfSlices"
                :rules="numberOfSlicesValidationRules"
                class="text-field"
                run-validations-on-creation
                @rtls-text-field-errors="textFieldErrorsHandler"
              />
            </v-col>
          </v-row>
          <v-row class="border-bottom">
            <v-col class="mt-2" :cols="4">
              <span>{{ $tkey('forecasterSetupModal.forecastSliceLength') }}:</span>
            </v-col>
            <v-col class="d-flex">
              <rtls-select
                v-model="forecasterSetupSliceLength"
                data-id-e2e="drpForecastSliceLength"
                hide-details
                width="100%"
                :items="forecasterSetupSliceLengths"
                :placeholder="$t('general.select')"
                white
              />
              <error-triangle
                class="ml-1 mr-1"
                :errors="{
                  [$tkey('errorMessages.selectForecastSliceLength')]: forecasterSetupSliceLength,
                }"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col data-id-e2e="fldForecastEndDate" :cols="6" class="d-flex performance-end-date">
              <span class="forecast-end">{{ $tkey('forecasterSetupModal.forecastEnd') }}:</span>
              {{ forecasterSetupEndDate | moment(getDateFormats.long) }}
              <error-triangle
                class="end-date-error-triangle"
                :errors="{
                  [$tkey('errorMessages.endDateBiggerThanWpPerfPeriodEndDate')]: endDateValidation,
                }"
              />
            </v-col>
            <v-col>
              <v-btn
                primary
                class="pull-right"
                :disabled="confirmDisabled"
                @click="startForecaster"
                >{{ $t('actions.confirm') }}</v-btn
              >
              <v-btn class="pull-right mr-1" text @click="cancelForecaster">{{
                $t('actions.cancel')
              }}</v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
    </main-dialog>

    <!-- revert checkpoint confirmation dialog -->
    <main-dialog ref="revertCheckpointModal">
      <template v-slot:header>
        <v-card-text class="display-1 pa-0 text-center">
          <p>{{ $tkey('checkpoints.revert.question') }}</p>
          <strong>{{ $tkey('checkpoints.revert.warning') }}</strong>
        </v-card-text>
      </template>
      <template v-slot:content="{ cancel, confirm }">
        <v-card-actions class="justify-center flex-column">
          <v-btn primary class="ma-2" @click="confirm">
            {{ $tkey('checkpoints.revert.action') }}
          </v-btn>
          <v-btn text depressed class="cancel ma-2" @click="cancel">
            {{ $t('actions.cancel') }}
          </v-btn>
        </v-card-actions>
      </template>
    </main-dialog>

    <!-- delete confirmation dialog -->
    <main-dialog ref="confirm">
      <template v-slot:header>
        <v-card-text class="display-1 pa-0 text-center">
          <p>{{ $tkey('checkpoints.delete.question') }}</p>
          <strong>{{ $tkey('checkpoints.delete.warning') }}</strong>
        </v-card-text>
      </template>
      <template v-slot:content="{ cancel, confirm }">
        <v-card-actions class="justify-center flex-column">
          <v-btn primary class="ma-2" @click="confirm">
            {{ $tkey('checkpoints.delete.action') }}
          </v-btn>
          <v-btn text depressed class="cancel ma-2" @click="cancel">
            {{ $t('actions.cancel') }}
          </v-btn>
        </v-card-actions>
      </template>
    </main-dialog>
  </v-container>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { get, upperFirst, map, includes, find, cloneDeep, isEmpty, omit, isNull } from 'lodash';
import { CHECKPOINT_TYPE, API_ERRORS } from '@enums/scenario-assortments';
import { forecastSliceLengths } from '@enums/forecast-slice-lengths';
import { jobFinishedStatuses } from '@enums/jobapi';
import checkpointTypes from '@enums/checkpoint-types';
import inputValidationMixin from '../../mixins/input-validations';
import datesMixin from '../../mixins/date-utils';

export default {
  mixins: [inputValidationMixin, datesMixin],
  localizationKey: 'assortmentCanvasPage',
  props: {
    checkpointId: {
      required: true,
      type: String,
    },
    remainingCheckpoints: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      forecasterSetupWeekStartDate: null,
      forecasterSetupSliceLength: null,
      forecasterSetupNumberOfSlices: null,
      rules: [this.required, this.isUniqueCheckpointName],
      nameKey: false,
      numberOfSlicesValidationRules: [
        this.isInteger,
        this.isPositive,
        // validation of presense
        () => {
          if (!this.forecasterSetupNumberOfSlices) {
            return this.$tkey('errorMessages.selectForecastNumberOfSlices');
          }

          return true;
        },
      ],
      forecasterSetupSliceLengths: map(forecastSliceLengths, n => ({
        text: `${n} ${this.$tkey('forecasterSetupModal.weeks')}`,
        value: n,
      })),
      valid: true,
      forecastParametersDisplayFunctions: {
        start: () =>
          moment.utc(this.forecastParameters.weekStartDate).format(this.getDateFormats.long),
        end: () => moment.utc(this.forecastEndDate).format(this.getDateFormats.long),
        length: () => this.$tkey('checkpoints.forecastLengthText', this.forecastParameters),
      },
      confirmDisabled: false,
    };
  },
  computed: {
    ...mapState('assortmentCanvas', ['selectedReferenceCheckpoint']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('context', [
      'showNotImplemented',
      'getDateFormats',
      'getDefaultReverseFormat',
      'getClientConfig',
    ]),
    ...mapGetters('assortmentCanvas', ['checkpoints', 'canvases']),
    ...mapGetters('workpackages', ['getSelectedWorkpackageExcludedDates']),

    checkpoint() {
      return cloneDeep(find(this.checkpoints, c => c._id === this.checkpointId));
    },

    checkpointMeta() {
      return this.checkpoint.checkpoint;
    },

    forecastParameters() {
      return this.checkpoint.forecastParameters;
    },

    forecastResults() {
      return this.checkpoint.forecastResults;
    },

    defaultForecasterSetupSliceLength() {
      return get(
        this.getClientConfig,
        'features.forecaster.defaultForecasterSetupSliceLength',
        null
      );
    },

    defaultForecasterSetupNumberOfSlices() {
      return get(
        this.getClientConfig,
        'features.forecaster.defaultForecasterSetupNumberOfSlices',
        null
      );
    },

    referenceCheckpoint: {
      get() {
        return this.isReferenceCheckpoint;
      },
      set() {
        const newValue = this.isReferenceCheckpoint ? null : this.checkpointId;

        this.setSelectedReferenceCheckpoint(newValue);
      },
    },

    isReferenceCheckpoint() {
      return this.selectedReferenceCheckpoint
        ? this.checkpointId === this.selectedReferenceCheckpoint
        : false;
    },

    isObservedCheckpoint() {
      return this.checkpointMeta.type === checkpointTypes.observedAssortment;
    },

    forecasterSetupEndDate() {
      const excludedDatesCount = this.getWorkpackageExcludedDatesCount(
        this.forecasterSetupWeekStartDate
      );
      // The end date to show in the run forecaster popup
      return this.calculateEndDate({
        startDate: this.forecasterSetupWeekStartDate,
        weeks: this.forecasterSetupNumberOfForecasterWeeks + excludedDatesCount,
        dateFormat: this.getDefaultReverseFormat,
      });
    },

    forecastEndDate() {
      const excludedDatesCount = this.getWorkpackageExcludedDatesCount(
        this.forecastParameters.weekStartDate
      );
      // The end date to show in the checkpoint
      return this.calculateEndDate({
        startDate: this.forecastParameters.weekStartDate,
        weeks: this.numberOfForecasterWeeks + excludedDatesCount,
        dateFormat: this.getDefaultReverseFormat,
      });
    },

    showDelete() {
      return ![checkpointTypes.observedAssortment, checkpointTypes.startingAssortment].includes(
        this.checkpointMeta.type
      );
    },

    forecasterJobStatus() {
      return this.checkpoint.jobs && this.checkpoint.jobs.forecaster
        ? this.checkpoint.jobs.forecaster.status
        : null;
    },

    hasForecasterJobRunning() {
      return this.forecasterJobStatus
        ? !includes(jobFinishedStatuses, this.forecasterJobStatus.toLowerCase())
        : false;
    },

    forecasterSetupNumberOfForecasterWeeks() {
      const sliceValue = this.forecasterSetupSliceLength;
      return this.forecasterSetupNumberOfSlices * sliceValue;
    },

    numberOfForecasterWeeks() {
      const sliceValue = this.forecastParameters.sliceLength;
      return this.forecastParameters.numberOfSlices * sliceValue;
    },

    allowedStartDates() {
      const {
        performanceStartDate,
        performanceEndDate,
        performanceExcludedDates,
      } = this.selectedWorkpackage;
      const parsedExcludedDates = map(
        performanceExcludedDates || [],
        ped => moment.utc(ped).format('YYYY-MM-DD') // no need to use config because this is for local comparison only
      );
      return date => {
        const parsedDate = moment.utc(date);
        // requires moment 2.13.0, currently on 2.19.2 https://momentjscom.readthedocs.io/en/latest/moment/05-query/06-is-between/
        // [ is inclusive. Forecastor start date can be >= performanceStartDate.
        // ) is exclusive. Forecastor start date must be < performanceEndDate
        // shouldn't matter about exclusive portion because end dates should always be Sundays and this must be Monday.
        return (
          parsedDate.isoWeekday() === 1 &&
          parsedDate.isBetween(performanceStartDate, performanceEndDate, 'day', '[)') &&
          parsedExcludedDates.includes(date) === false
        );
      };
    },

    endDateValidation() {
      if (isNull(this.forecasterSetupEndDate)) {
        return true;
      }
      return (
        moment.utc(this.selectedWorkpackage.performanceEndDate) >=
        moment.utc(this.forecasterSetupEndDate)
      );
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAssortmentCanvas);
    },
  },

  watch: {
    forecasterSetupNumberOfSlices(val) {
      this.updateConfirmDisabledFlag(val, this.endDateValidation, this.forecasterSetupSliceLength);
    },

    endDateValidation(val) {
      this.updateConfirmDisabledFlag(
        this.forecasterSetupNumberOfSlices,
        val,
        this.forecasterSetupSliceLength
      );
    },

    forecasterSetupSliceLength(value) {
      this.updateConfirmDisabledFlag(
        this.forecasterSetupNumberOfSlices,
        this.endDateValidation,
        value
      );
    },
  },

  async created() {
    this.forecasterSetupSliceLength =
      this.forecastParameters.sliceLength || this.defaultForecasterSetupSliceLength;
    this.forecasterSetupNumberOfSlices =
      this.forecastParameters.numberOfSlices || this.defaultForecasterSetupNumberOfSlices;
    this.setInitialForecasterStartDate();
  },

  methods: {
    ...mapActions('assortmentCanvas', [
      'updateCheckpoint',
      'deleteCheckpoint',
      'fetchCheckpoints',
      'runForecasterJob',
      'fetchCanvases',
      'updateAssortmentCanvas',
      'deleteAssortmentCanvas',
      'revertCheckpointToCanvas',
    ]),

    ...mapMutations('assortmentCanvas', ['setSelectedReferenceCheckpoint']),

    ...mapActions('snackbar', ['showSnackbar']),

    displayForecastResult(key) {
      if (this.hasForecasterJobRunning) {
        return this.$tkey('checkpoints.calculating');
      }
      return isEmpty(this.forecastResults)
        ? this.$t('general.notAvailable')
        : this.formatNumber({ number: this.forecastResults[key], format: 'currency' });
    },

    displayForecastParameter(key) {
      if (isEmpty(this.forecastParameters)) {
        return this.$t('general.notAvailable');
      }
      return this.forecastParametersDisplayFunctions[key]();
    },

    setInitialForecasterStartDate() {
      if (!this.selectedWorkpackage) return;
      // If there's no defined number of weeks to forecast for,
      // set performance start date as forecaster start date.
      if (!this.forecasterSetupNumberOfForecasterWeeks) {
        this.forecasterSetupWeekStartDate = moment
          .utc(this.selectedWorkpackage.performanceStartDate)
          .format(this.getDefaultReverseFormat);
      } else {
        this.forecasterSetupWeekStartDate = this.calculateStartDate({
          endDate: this.selectedWorkpackage.performanceEndDate,
          weeks: this.forecasterSetupNumberOfForecasterWeeks,
          dateFormat: this.getDefaultReverseFormat,
        });
      }

      let isForecasterSetupWeekStartDateInvalid = true;

      // If forecasterSetupWeekStartDate is not valid we set forecasterSetupWeekStartDate as nearest valid date
      while (isForecasterSetupWeekStartDateInvalid) {
        if (this.allowedStartDates(this.forecasterSetupWeekStartDate)) {
          isForecasterSetupWeekStartDateInvalid = false;
        } else {
          this.forecasterSetupWeekStartDate = moment
            .utc(this.forecasterSetupWeekStartDate)
            .add(7, 'days')
            .format(this.getDefaultReverseFormat);
        }
      }

      if (!this.endDateValidation) {
        this.forecasterSetupNumberOfSlices = Math.round(
          moment
            .utc(this.selectedWorkpackage.performanceEndDate)
            .diff(moment.utc(this.forecasterSetupWeekStartDate), 'week') / 2
        );
      }
    },

    isUniqueCheckpointName(value) {
      if (this.remainingCheckpoints.filter(cp => cp.checkpoint.name === value.trim()).length) {
        return this.$t('validationErrors.unique', [this.$tkey('checkpoint')]);
      }
      return true;
    },

    checkpointGeneratedBy() {
      const checkpointType = this.checkpointMeta.type;
      let text = `${upperFirst(
        CHECKPOINT_TYPE[checkpointType] !== CHECKPOINT_TYPE.user
          ? CHECKPOINT_TYPE.tool
          : CHECKPOINT_TYPE.user
      )}
      ${this.$tkey('checkpoints.generatedCheckpoint')}`;
      if (this.isObservedCheckpoint) {
        text = `${text} (${this.$tkey('checkpoints.observedCheckpoint')})`;
      }
      return text;
    },

    async save(item) {
      if (!this.valid) return false;
      const updates = { ...omit(item, 'checkpoint', 'id'), ...{ checkpointMeta: item.checkpoint } };
      await this.updateCheckpoint({ checkpointId: item._id, updates });
      await this.fetchCheckpoints();
      this.nameKey = !this.nameKey;
    },

    getErrorTranslation(errors) {
      return errors.map(error =>
        this.$tkey(API_ERRORS[error.code] || 'errors.generalErrorMessage')
      );
    },

    async initDelete(canvasId) {
      if (await this.$refs.confirm.open()) {
        this.confirmDelete(canvasId);
      }
    },

    async initRevertCheckpointToCanvas(checkpoint) {
      if (await this.$refs.revertCheckpointModal.open()) {
        const checkpointId = checkpoint._id;
        await this.revertCheckpointToCanvas({ checkpointId });
        this.$emit('close');
      }
    },

    async confirmDelete(canvasId) {
      const { errors } = await this.deleteCheckpoint({ canvasId });
      if (errors) {
        return this.getErrorTranslation(errors).forEach(err => this.showError(err));
      }
      await this.fetchCheckpoints();
    },

    showError(error) {
      this.showSnackbar({ content: error });
    },

    runForecaster() {
      this.$refs.forecasterSetup.open();
    },

    cancelForecaster() {
      this.$refs.forecasterSetup.cancel();
    },

    async startForecaster() {
      const { performanceStartDate, performanceEndDate } = this.selectedWorkpackage;
      if (
        moment.utc(this.forecasterSetupWeekStartDate) < moment.utc(performanceStartDate) ||
        moment.utc(performanceEndDate) < moment.utc(this.forecasterSetupEndDate)
      ) {
        this.showError(this.$tkey('forecasterSetupModal.periodOutOfBoundsError'));
        return;
      }

      await this.runForecasterJob({
        checkpointId: this.checkpoint._id,
        scenarioId: this.$route.params.scenarioId,
      });
      const updates = {
        forecastParameters: {
          numberOfSlices: parseInt(this.forecasterSetupNumberOfSlices, 10),
          sliceLength: this.forecasterSetupSliceLength,
          weekStartDate: this.formatDateForSave(
            this.forecasterSetupWeekStartDate,
            this.getDefaultReverseFormat
          ),
        },
      };
      await this.updateCheckpoint({ checkpointId: this.checkpoint._id, updates });
      await this.fetchCheckpoints();
      this.$refs.forecasterSetup.cancel();
    },

    textFieldErrorsHandler(val) {
      if (Object.keys(val).length > 0) {
        this.confirmDisabled = true;
      }
    },

    updateConfirmDisabledFlag(numberOfSlices, endDateValidation, forecasterSetupSliceLength) {
      if (numberOfSlices > 0 && endDateValidation && forecasterSetupSliceLength) {
        this.confirmDisabled = false;
        return;
      }
      this.confirmDisabled = true;
    },

    getWorkpackageExcludedDatesCount(forecasterDate) {
      return this.getSelectedWorkpackageExcludedDates(forecasterDate).length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.container {
  padding: 8px;

  .checkpoint-row {
    margin-bottom: 10px;
  }

  .checkpoint-details-row {
    margin-top: 5px;
  }

  .assortment-btn-clear {
    color: $assortment-primary-colour;
  }

  .buttons-container {
    .v-btn:first-child {
      margin-right: 10px;
    }

    .v-btn:nth-child(2) {
      margin-right: 5px;
    }
  }

  .error-box {
    margin-left: 10px;
  }

  .show-details {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    span {
      font-weight: bold;
      color: $assortment-primary-colour;
    }

    .v-icon {
      transform: translate(0px, 0.5px);
    }
  }

  .checkpoint-details-container {
    display: flex;
    justify-content: space-between;

    .checkpoint-details-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .checkpoint-details-title {
        font-weight: bold;
        font-size: 1.2rem;
        margin: 0;

        span {
          font-weight: normal;
        }
      }
      .checkpoint-details-item {
        display: flex;
        align-items: center;

        .checkpoint-details-content {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.checkpoint-type {
  margin: 0;
  font-weight: bold;
}

.forecasterSetup {
  span {
    font-weight: bold;
  }
  .border-bottom {
    border-bottom: $assortment-border-colour 1px solid;
    padding-bottom: 10px;
  }
  .container {
    padding: 0 !important;
  }
}

::v-deep {
  .v-label {
    color: black;
  }

  // An override to ignore invalid red color
  // and always have the default - for consistency on the form
  .v-text-field .v-input__control {
    color: rgba(0, 0, 0, 0.5) !important;
  }
}

.end-date-error-triangle {
  padding-left: 8px;
}

.performance-end-date {
  font-size: 12px;
}

.forecast-end {
  padding-right: 1px;
}
</style>
