<template>
  <workflow-step v-if="!isLoadingAllFurniture" :step="step" :tab-data="scenarioTabs" />
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import toolbarTab from '@/js/utils/tabs/toolbar-tabs-enum';
import scenarioTabs from '@/js/utils/tabs/scenario-tabs-enum';

export default {
  beforeRouteUpdate(to, from, next) {
    this.unloadData();

    next();
  },

  data() {
    return {
      step: toolbarTab.SPACE_TAB,
      scenarioTabs,
    };
  },

  computed: {
    ...mapState('furniture', ['isLoadingAllFurniture']),
  },

  async created() {
    await this.fetchScenarioFurniture();
  },

  methods: {
    ...mapActions('furniture', ['fetchScenarioFurniture']),
    ...mapMutations('furniture', ['unloadData']),
  },
};
</script>

<style lang="scss"></style>
