<template>
  <v-container v-if="selectedScenario._id && selectedWorkpackage" fluid>
    <v-layout column class="main-layout">
      <v-flex class="d-flex flex-column">
        <toolbar
          back-nav-route="home"
          enable-notes
          enable-scenario-selection
          :toolbar-tabs="toolbarTabs"
          :workpackage="selectedWorkpackage"
        />
        <router-view :key="routerKey" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import toolbarTabs from '@/js/utils/tabs/toolbar-tabs-enum';

export default {
  async beforeRouteUpdate(to, from, next) {
    if (this.requiresStateReload(to.params.canvasId, to.params.scenarioId))
      await this.loadState({
        scenarioId: to.params.scenarioId,
        canvasId: to.params.canvasId,
      });
    return next();
  },

  data() {
    return {
      toolbarTabs,
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapGetters('assortmentCanvas', ['selectedCanvas', 'canvases']),

    scenarioId() {
      return this.$route.params.scenarioId;
    },

    routerKey() {
      return `${this.selectedScenario._id}-${this.selectedCanvas._id}`;
    },
  },

  async created() {
    await this.loadState({ scenarioId: this.scenarioId, canvasId: this.$route.params.canvasId });
    this.handleSelectingCanvas();
  },

  methods: {
    ...mapActions('workpackages', ['loadState']),
    ...mapActions('assortmentCanvas', ['fetchCanvases', 'fetchCanvasesForSelectedWorkpackage']),

    requiresStateReload(canvasId, scenarioId) {
      // If changing routes and the canvasId in route is different to selectedCanvas we need to load state
      if (canvasId !== this.selectedCanvas._id) return true;
      // If we are changing routes inside the same scenario we just continue
      if (scenarioId === this.selectedScenario._id) return false;
      return true;
    },

    async handleSelectingCanvas() {
      // First load all available live canvases for the scenario
      await this.fetchCanvases({ params: { where: { live: true } } });
      // If in the canvas page - load all canvases for the workpackage
      // So we can disable the scenario selector in toolbar if no canvas is available for a scenario
      if (this.$route.name === toolbarTabs.ASSORTMENTS_PANEL_TAB) {
        await this.fetchCanvasesForSelectedWorkpackage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-layout {
  height: 100%;
}
</style>
