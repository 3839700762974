<template>
  <!-- Ignore div when component is rendered inside css grid layout (tile view) -->
  <div :class="{ 'd-contents': selectedView === views.Tile }" class="sidebar-wrapper">
    <!-- div at top to span the amount of rows in the headers, allows for overflow scrolling -->
    <div
      :style="sidebarHeadingStyle"
      class="grid-item-border left-column-sticky-grid-item top-left-heading d-flex flex-column justify-space-between"
      :class="isSticky"
    >
      <div class="d-flex">
        <v-menu v-model="isViewMenuOpen" close-on-content-click offset-x>
          <template v-slot:activator="{ on }">
            <v-btn x-small link color="primary" class="mb-2" v-on="on">
              <v-icon size="16" class="mr-1">visibility</v-icon> {{ $tkey('viewOptions') }}
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    :value="fullScreenExpanded"
                    small
                    dense
                    color="primary"
                    class="view-menu-switch"
                    @change="toggleFullScreen(!fullScreenExpanded)"
                  />
                </v-list-item-action>
                <v-list-item-title class="view-menu-option">
                  {{ $tkey('fullscreen') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    v-model="stickyHeadersModel"
                    small
                    dense
                    color="primary"
                    class="view-menu-switch"
                  />
                </v-list-item-action>
                <v-list-item-title class="view-menu-option">
                  {{ $tkey('stickyHeaders') }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    v-model="unifiedColumnsModel"
                    small
                    dense
                    color="primary"
                    class="view-menu-switch"
                  />
                </v-list-item-action>
                <v-list-item-title class="view-menu-option">
                  {{ $tkey('unifiedColumns') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-card-actions class="view-menu-actions">
              <v-spacer />
              <v-btn color="primary" text @click="isViewMenuOpen = false">
                {{ $t('actions.close') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>

      <!-- This column settings panel is only available on the List view -->
      <!-- It allows to select and reorder columns to display -->
      <div class="d-flex">
        <column-settings
          v-if="selectedView === views.List && columnSettingsEnabled"
          :button-title="$tkey('columns')"
          :columns="listViewColumnsWithTitle"
          :disabled-columns="listViewFixedColumns"
          @update:selected-columns="toggleListViewColumnVisibility"
          @update:column-order="reorderListViewColumns"
        />
      </div>

      <!-- Global Constraints -->
      <div class="spacebreak-entry global-constraints" :class="selectedSpacebreakClass">
        <div class="spacebreak-heading-row">
          <v-layout class="d-flex justify-space-between">
            <div class="spacebreak-name space-break-heading">{{ $tkey('globalConstraints') }}</div>
            <div
              class="icons-container"
              data-dd-action-name="ToggleGlobalSpacebreakSettingsPanelBtn"
            >
              <v-avatar
                :class="{
                  'spacebreak-count-badge': !cogWarning,
                  'spacebreak-count-badge-warning': cogWarning,
                }"
                size="14"
                @click="toggleGlobalSettingsPanel"
              >
                <span class="white--text headline">{{ globalConstraintsCount || 0 }}</span>
              </v-avatar>
              <v-icon class="settings-btn" @click="toggleGlobalSettingsPanel">$settings</v-icon>
            </div>
          </v-layout>
        </div>
      </div>
    </div>
    <!-- Spacebreaks with products -->
    <div
      v-for="spacebreak in spacebreaks"
      :key="spacebreak._id"
      :class="{
        'spacebreak-locked-border':
          isSpacebreakLocked(spacebreak._id) && selectedView === views.Tile,
      }"
      class="grid-item-border left-column-sticky-grid-item sidebar d-flex"
    >
      <div v-if="isSpacebreakLocked(spacebreak._id) && selectedView === views.Tile" class="locked">
        {{ $tkey('locked') }}
      </div>
      <spacebreak-entry
        :spacebreak="spacebreak"
        :space="spaceUsedPerSpacebreak[spacebreak._id]"
        :pallets-total="palletSlotsPerSpacebreak(spacebreak)"
        :used-pallets-slots="usedPalletsSlotsPerSpacebreak[spacebreak._id]"
        :store-total="pointsOfDistribution[spacebreak._id]"
        :product-count="productCountPerSpacebreak[spacebreak._id]"
        class="list-spacebreak-entry"
        :class="{
          'active-spacebreak': isSpacebreakActive(spacebreak._id),
        }"
        @update-active-spacebreak="updateActiveSpacebreak(spacebreak._id)"
      />
    </div>
    <!-- Entries for products with no assigned spacebreak -->
    <div
      v-for="spacebreak in spacebreakTypes"
      :id="`${spacebreak}-spacebreak-entry`"
      :key="spacebreak"
      class="pa-3 grid-item-border left-column-sticky-grid-item sidebar"
      :class="{
        'active-spacebreak': isSpacebreakActive(spacebreak),
        selectable: selectedView === views.List,
      }"
      @click="updateActiveSpacebreak(spacebreak)"
    >
      <p class="font-weight-bold">{{ $tkey(spacebreak) }}</p>
      <p v-if="productsWithoutSpacebreak[spacebreak]">
        {{
          `${productsWithoutSpacebreak[spacebreak].length} ${$tc(
            'assortmentCanvasPage.listViewPage.products',
            productsWithoutSpacebreak[spacebreak].length
          )}`
        }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import {
  filter,
  cloneDeep,
  every,
  reduce,
  values,
  isEmpty,
  map,
  size,
  set,
  sortBy,
  get,
} from 'lodash';
import { Views } from '@enums/assortment-canvases';
import { spacebreakTypes } from '@enums/spacebreak-types';

export default {
  localizationKey: 'assortmentCanvasPage.listViewPage',
  name: 'SpacebreakSidebar',
  props: {
    pointsOfDistribution: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isViewMenuOpen: false,
      views: Views,
      spacebreakTypes,
    };
  },

  computed: {
    ...mapState('context', ['clientConfig']),
    ...mapState('assortmentCanvas', [
      'spacebreakSettings',
      'fullScreenExpanded',
      'stickyHeaders',
      'unifiedColumns',
      'spacebreaks',
      'selectedView',
      'sidebarHeaderHeight',
      'activeSpacebreak',
      'listViewColumns',
      'listViewFixedColumns',
    ]),
    ...mapState('scenarioProducts', ['scenarioProducts']),
    ...mapGetters('assortmentCanvas', [
      'productsPerSpacebreak',
      'totalExpandedHeaderDepth',
      'selectedCanvas',
      'spaceUsedPerSpacebreak',
      'isSpacebreakLocked',
      'getFilteredProducts',
      'getProductsWithNoSpacebreak',
    ]),

    columnSettingsEnabled() {
      return get(
        this.clientConfig,
        'features.scenario.assortmentCanvas.canvasListViewColumnSettings'
      );
    },

    productCountPerSpacebreak() {
      const { productCounts } = reduce(
        sortBy(this.spacebreaks, 'fillOverride'),
        (acc, sb) => {
          // Ignore products in unassigned spacebreak
          if (!sb._id) return acc;
          const productsExcludingPallets = filter(
            this.productsPerSpacebreak[sb._id],
            ({ palletContent }) => isEmpty(palletContent)
          );
          const productCount = size(map(productsExcludingPallets, 'productKey'));
          const currentCount = productCount + acc.previousProductCount;
          acc.previousProductCount = currentCount;
          return set(acc, `productCounts.${sb._id}`, currentCount);
        },
        { productCounts: {}, previousProductCount: 0 }
      );
      return productCounts;
    },

    usedPalletsSlotsPerSpacebreak() {
      const productKeyDisplays = new Set(this.scenarioProducts.map(p => p.productKeyDisplay));
      const { usedSpacePerSpacebreaks } = reduce(
        sortBy(this.spacebreaks, 'fillOverride'),
        (acc, sb) => {
          if (!sb._id) return acc;
          const palletsProducts = filter(this.productsPerSpacebreak[sb._id], product => {
            if (productKeyDisplays.has(product.palletContent)) return product;
            return null;
          });

          let usedSpace = 0;
          palletsProducts.forEach(p => {
            const { palletContent, palletSize } = p;
            if (palletSize) {
              if (
                !acc.latestUsedSpace[palletContent] ||
                acc.latestUsedSpace[palletContent] < palletSize
              ) {
                acc.latestUsedSpace[palletContent] = palletSize;
              }
            }
          });
          Object.values(acc.latestUsedSpace).forEach(s => {
            usedSpace += s;
          });

          return set(acc, `usedSpacePerSpacebreaks.${sb._id}`, usedSpace);
        },
        { latestUsedSpace: {}, usedSpacePerSpacebreaks: {} }
      );
      return usedSpacePerSpacebreaks;
    },

    rowSpanning() {
      // Account for the Cann Group Name header at the very top of the grid
      const cannGroupHeaderCount = 1;
      // totalExpandedHeaderDepth starts at 1, each time we expand a header it increments depth by 1.
      // Each time we expand a header we add two new rows, the currentCdtName row and a row with all the direct children.
      return this.totalExpandedHeaderDepth * 2 + cannGroupHeaderCount;
    },

    selectedSpacebreakClass() {
      if (
        this.spacebreakSettings &&
        this.spacebreakSettings.open &&
        !this.spacebreakSettings.spacebreak
      ) {
        return 'selected-border-right';
      }
      return '';
    },

    sidebarHeadingStyle() {
      if (this.selectedView === this.views.Tile) {
        return { 'grid-row': `span ${this.rowSpanning}` };
      }
      // Set fixed height if unified columns is set, else set to same height as grid headers
      const height = this.unifiedColumns[this.selectedView] ? 85 : this.sidebarHeaderHeight;
      return { height: `${height}px` };
    },

    cogWarning() {
      const constraints = filter(this.selectedCanvas.constraints.definitions, c => !c.spacebreakId);
      if (constraints.length === 0) {
        return false;
      }
      const allConstraintsValuesWithinLimits = reduce(
        constraints,
        (acc, constraint) => {
          const sbConstraintsWithinLimits = values(constraint.isCurrentValueWithinLimits);
          return [...acc, ...sbConstraintsWithinLimits];
        },
        []
      );
      return !every(allConstraintsValuesWithinLimits);
    },

    globalConstraintsCount() {
      return cloneDeep(this.selectedCanvas.constraints.definitions).filter(x => !x.spacebreakId)
        .length;
    },

    stickyHeadersModel: {
      get() {
        return this.stickyHeaders;
      },
      set(value) {
        this.setStickyHeaders(value);
      },
    },

    isSticky() {
      return this.stickyHeaders ? 'sticky' : '';
    },

    unifiedColumnsModel: {
      get() {
        return this.unifiedColumns[this.selectedView];
      },
      set(value) {
        this.setUnifiedColumns({ value, view: this.selectedView });
      },
    },

    productsWithoutSpacebreak() {
      const filteredProducts = filter(
        this.getFilteredProducts.products,
        p => !p.currentSpacebreakId
      );
      return this.getProductsWithNoSpacebreak(filteredProducts);
    },

    listViewColumnsWithTitle() {
      return filter(this.listViewColumns, 'title');
    },
  },

  created() {
    this.initializeListViewColumns();
  },

  methods: {
    ...mapMutations('assortmentCanvas', [
      'toggleSpaceBreakSettingsPanel',
      'setConstraintEditing',
      'setFullScreen',
      'setStickyHeaders',
      'setUnifiedColumns',
      'setActiveSpacebreak',
    ]),
    ...mapActions('assortmentCanvas', [
      'initializeListViewColumns',
      'reorderListViewColumns',
      'toggleListViewColumnVisibility',
    ]),
    toggleGlobalSettingsPanel() {
      this.setConstraintEditing({
        editingBoolean: false,
      });
      this.toggleSpaceBreakSettingsPanel(null);
    },

    toggleFullScreen(boolean) {
      this.setFullScreen(boolean);
    },

    isSpacebreakActive(spacebreak) {
      return this.activeSpacebreak === spacebreak && this.selectedView === this.views.List;
    },

    updateActiveSpacebreak(spacebreak) {
      if (this.selectedView === this.views.Tile) return;
      // Unset active spacebreak if the spacebreak is already active
      if (this.isSpacebreakActive(spacebreak)) {
        this.setActiveSpacebreak(null);
      } else {
        this.setActiveSpacebreak(spacebreak);
      }
    },

    palletSlotsPerSpacebreak(spacebreak) {
      return get(spacebreak, 'palletsSlots', 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.sidebar {
  min-width: 190px;
  max-width: 190px;
  min-height: 100px;
  z-index: 198;
  flex-direction: column;

  &.left-column-sticky-grid-item {
    border-top: 1px solid $assortment-tile-border-colour;
    border-right: 1px solid $assortment-tile-border-colour;

    &:nth-child(2) {
      // remove the top border from the first spacebreak sidebar element to allow sticky headers to function properly
      border-top: none;
    }
  }
}

.sidebar-wrapper {
  min-width: fit-content;
  overflow-y: auto;
  overflow-x: hidden;

  .top-left-heading {
    border-right: 1px solid $assortment-tile-border-colour;
  }
}

.space-break-heading {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.spacebreak-entry {
  box-sizing: border-box;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 7px;
}

.list-spacebreak-entry {
  padding: 7px 17px 7px 7px;
}

.active-spacebreak {
  background-color: $assortment-active-spacebreak-entry-bg-colour;
}

.spacebreak-size {
  margin-right: 5px;
}

.spacebreak-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: fit-content;
  font-size: 1.2rem;
  color: $assortment-primary-colour;
  font-weight: bold;
}

.settings-btn {
  transform: translate(2px, -4px);
  cursor: pointer;
}

.view-menu-actions {
  justify-content: flex-end;
}

.view-menu-switch {
  transform: scale(0.75);
}

.view-menu-option {
  font-size: 1.2rem;
}

.spacebreak-count-badge {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  left: -2px;
  top: -6px;
  background-color: $assortment-primary-colour;
}

.spacebreak-count-badge-warning {
  z-index: 2;
  cursor: pointer;
  position: absolute;
  left: -2px;
  top: -6px;
  background-color: $assortment-slightly-negative-action-colour;
}

.icons-container {
  position: relative;
  padding-right: 17px;
}

.selected-border-right {
  position: relative;
  &::after {
    content: '';
    width: 3px;
    background: $assortment-primary-colour;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
}

.global-constraints {
  padding-top: 2px;
}

.fullscreen-btn {
  padding-left: 2px;
  font-size: 24px;
  color: $assortment-primary-colour;

  &::before {
    cursor: pointer;
  }
}

.sticky {
  position: sticky;
  top: 0;
}

.sticky-header-checkbox-title {
  padding-left: 7px;
}

.spacebreak-locked-border {
  box-shadow: 5px 0 0 $assortment-text-colour;
  z-index: 1;
}

.locked {
  background-color: $assortment-text-colour;
  position: absolute;
  top: 50%;
  // Needed to use px here as normal percentage positioning wouldn't work.
  right: -49px;
  transform: translateY(-50%) rotate(-90deg);
  font-size: 1.4rem;
  color: white;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.top-left-heading {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 199;
  border-top: none;
  border-left: none;
  border-bottom: 1px solid $assortment-tile-border-colour;
  background: $assortment-background;
}
</style>
