import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-grow-1 cdt-page" },
    [
      _c("cdt-tree-view", {
        attrs: {
          value: _vm.showTreeViewModal,
          "selected-cann-group": _vm.selectedCannGroup || {},
          "cdt-ref": _vm.treeViewCdt,
        },
        on: { close: _vm.closeCdtTreeViewModal },
      }),
      _vm._v(" "),
      _c(
        VCard,
        { staticClass: "step-tab-panel", attrs: { flat: "" } },
        [
          _c(
            VContainer,
            { staticClass: "pa-0 ma-0 cdt-container d-flex flex-column" },
            [
              _c("scenario-measuring-header", {
                attrs: {
                  "header-text": _vm.$t("customerDecisionTree.title"),
                  columns: 4,
                  "tooltip-link": "toolguide/090-cdt.html",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "no-gutters cdt-panel d-flex flex-grow-1",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    VCol,
                    { attrs: { cols: 2 } },
                    [
                      _c("cann-group-menu", {
                        attrs: {
                          "mark-cann-group-with-selected-cdt": true,
                          "preselect-cann-group": true,
                          "selected-cann-group": _vm.dirtySelectedCannGroup,
                        },
                        on: { onCannGroupSelected: _vm.selectCannGroup },
                        scopedSlots: _vm._u([
                          {
                            key: "tickbox",
                            fn: function () {
                              return [
                                _c(
                                  VIcon,
                                  {
                                    staticClass: "float-right",
                                    attrs: { color: "#d3d3d3" },
                                  },
                                  [_vm._v("mdi-checkbox-blank-circle")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { staticClass: "d-flex flex-column", attrs: { cols: 10 } },
                    [
                      _vm.isCdtGenerationJobRunning
                        ? _c("progress-bar", {
                            staticClass: "progress-bar",
                            attrs: {
                              message: _vm.$t("messages.inProgress"),
                              percentage: _vm.progressBarInfo.progress,
                              status: _vm.progressBarInfo.status,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasCtdGenerated && !_vm.isCdtGenerationJobRunning
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column flex-cdt-container",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-grow-1 inner-flex-container",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    _vm._l(
                                      _vm.toolGeneratedCdts,
                                      function (toolGeneratedCdt) {
                                        return _c(
                                          "div",
                                          {
                                            key: toolGeneratedCdt._id,
                                            staticClass: "d-flex",
                                          },
                                          [
                                            _c("cdt-attributes-list", {
                                              attrs: {
                                                disabled: _vm.isEditingDisabled,
                                                "selected-cann-group":
                                                  _vm.selectedCannGroup,
                                                cdt: toolGeneratedCdt,
                                              },
                                              on: {
                                                generate:
                                                  _vm.startCdtGeneration,
                                                "open-tree-view-modal":
                                                  _vm.openTreeViewModal,
                                                "data-changed": function (
                                                  $event
                                                ) {
                                                  _vm.hasTooldataChanges =
                                                    $event
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-cdt-container",
                                    },
                                    _vm._l(
                                      _vm.userModifiedCdts,
                                      function (userModifiedCdt) {
                                        return _c(
                                          "div",
                                          {
                                            key: userModifiedCdt._id,
                                            staticClass: "d-flex",
                                          },
                                          [
                                            _c("cdt-attributes-list", {
                                              attrs: {
                                                disabled: _vm.isEditingDisabled,
                                                "selected-cann-group":
                                                  _vm.selectedCannGroup,
                                                cdt: userModifiedCdt,
                                              },
                                              on: {
                                                generate:
                                                  _vm.startCdtGeneration,
                                                "open-tree-view-modal":
                                                  _vm.openTreeViewModal,
                                                "data-changed": function (
                                                  $event
                                                ) {
                                                  _vm.hasUserChanges = $event
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VCol,
                                    {
                                      staticClass:
                                        "attributes-column pa-0 d-flex flex-column justify-space-between",
                                      staticStyle: { "vertical-align": "top" },
                                    },
                                    [
                                      _c(
                                        VContainer,
                                        {
                                          staticClass:
                                            "ma-0 pa-0 controls flex-grow-0",
                                        },
                                        [
                                          _c(
                                            VRow,
                                            {
                                              staticClass:
                                                "d-flex justify-start align-center heading-row pt-4 pb-3",
                                              attrs: { "no-gutters": "" },
                                            },
                                            [
                                              _c(
                                                "h4",
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticClass:
                                                        "addModifiedCdt",
                                                      attrs: {
                                                        text: "",
                                                        disabled:
                                                          _vm.disableCreateModifiedCdt,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.createModifiedCDT()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          staticClass:
                                                            "material-icons icon mr-1 ml-2",
                                                        },
                                                        [_vm._v("add_box")]
                                                      ),
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$tkey(
                                                              "cdtList.createModifiedCdt"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(VDivider),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isCdtGenerationJobRunning &&
                      !_vm.hasCtdGenerated &&
                      _vm.selectedCannGroup
                        ? _c("cdt-attribute-selection", {
                            attrs: {
                              disabled: _vm.isEditingDisabled,
                              "selected-cann-group": _vm.selectedCannGroup,
                            },
                            on: {
                              generate: _vm.startCdtGeneration,
                              "data-changed": function ($event) {
                                _vm.hasAttributeSelectionChanges = $event
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isCdtGenerationJobRunning
                        ? _c(
                            "div",
                            { staticClass: "page-actions-container" },
                            [
                              _c("page-actions", {
                                attrs: {
                                  "show-export": _vm.showNotImplemented,
                                  "show-discard": false,
                                  "show-save-button": _vm.showNotImplemented,
                                  "save-disabled": _vm.isEditingDisabled,
                                  "is-discard-enabled": !_vm.isEditingDisabled,
                                  "has-data-changes": false,
                                  "has-data-errors": _vm.hasDataErrors,
                                  "save-btn-text": "saveCompleteCdt",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "right-btns",
                                      fn: function () {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "generate-multiple-btn-container",
                                            },
                                            [
                                              _c(
                                                "select-multiple-cann-groups-modal",
                                                {
                                                  attrs: {
                                                    "selected-cann-group":
                                                      _vm.selectedCannGroup ||
                                                      {},
                                                    title:
                                                      _vm.$tkey("dialog.title"),
                                                    subtitle:
                                                      _vm.$tkey("dialog.title"),
                                                  },
                                                  on: {
                                                    confirm:
                                                      _vm.startCdtGeneration,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              VBtn,
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.isEditingDisabled,
                                                                    "data-id-e2e":
                                                                      "btnGenerateCDTMultiple",
                                                                    depressed:
                                                                      "",
                                                                    primary: "",
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      _vm.$tkey(
                                                                        "generateMultiple"
                                                                      )
                                                                    ) +
                                                                    "\n                      "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    4133338095
                                                  ),
                                                }
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2526826956
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("main-dialog", {
            ref: "confirm",
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function () {
                  return [
                    _c(
                      VCardText,
                      { staticClass: "display-1 pa-0 text-center" },
                      [
                        _c("p", [
                          _vm._v(_vm._s(_vm.$tkey("confirmation.question"))),
                        ]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$tkey("confirmation.warning"))),
                        ]),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "content",
                fn: function ({ cancel, confirm }) {
                  return [
                    _c(
                      VCardActions,
                      { staticClass: "justify-center flex-column" },
                      [
                        _c(
                          VBtn,
                          {
                            staticClass: "ma-2",
                            attrs: {
                              "data-id-e2e": "btnOKGenerateCDT",
                              primary: "",
                            },
                            on: { click: confirm },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.ok")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            staticClass: "cancel ma-2",
                            attrs: { text: "", depressed: "" },
                            on: { click: cancel },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.cancel")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.onUnsavedDataModalCancel,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }