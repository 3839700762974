import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VBtn,
    {
      attrs: { icon: "", text: "", disabled: _vm.disabled },
      on: {
        click: function ($event) {
          return _vm.$emit("execute")
        },
      },
    },
    [
      _c(VIcon, { staticClass: "new-workpackage", attrs: { size: "24" } }, [
        _vm._v(_vm._s(_vm.icon)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }