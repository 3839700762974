import { name as workpackages } from './modules/workpackages';
import { name as workpackageProducts } from './modules/workpackage-products';
import { name as snackbar } from './modules/snackbar';
import { name as toolData } from './modules/tool-data';
import { name as compoundExtracts } from './modules/compound-extract';

export const StoreNamespaces = {
  workpackages,
  workpackageProducts,
  scenarios: 'scenarios',
  scenarioProducts: 'scenarioProducts',
  scenarioCdts: 'scenarioCdts',
  assortmentGroups: 'assortmentGroups',
  assortmentCanvas: 'assortmentCanvas',
  context: 'context',
  snackbar,
  furniture: 'furniture',
  files: 'files',
  clustering: 'clustering',
  referenceClustering: 'referenceClustering',
  toolData,
  simpleSwapRuns: 'simpleSwapRuns',
  extracts: 'extracts',
  compoundExtracts,
  storeExecutions: 'storeExecutions',
  bundles: 'bundles',
  productRegions: 'productRegions',
};

export default {
  StoreNamespaces,
};
