'use strict';

// Since we have different configs from server and client we can't set up here in shared-modules but we can still use the library
const localNumeral = require('numeral');
const { isFinite, escapeRegExp, get } = require('lodash');

const helpers = {
  escapedThousandsSeparator: (numeral = localNumeral) => {
    const thousandsSeparator = get(numeral.localeData(), 'delimiters.thousands', ',');
    return escapeRegExp(thousandsSeparator);
  },
  escapedDecimalSeparator: (numeral = localNumeral) => {
    const decimalPoint = get(numeral.localeData(), 'delimiters.decimal', '.');
    return escapeRegExp(decimalPoint);
  },
  thousandsSeparatorCaptureGroup: (numeral = localNumeral) => {
    return `(?:\\d{1,3}(?:${helpers.escapedThousandsSeparator(numeral)}\\d{3})*)`;
  },
  decimalPointCaptureGroup: (numeral = localNumeral) => {
    // must be at end of string
    return `(${helpers.escapedDecimalSeparator(numeral)}\\d+)?$`;
  },
};

const regex = {
  // only matches correctly thousand separated numbers e.g. 1,234 will pass. 1,2345 will not pass.
  localeNumericStrict: (numeral = localNumeral) => {
    const format = `^-?${helpers.thousandsSeparatorCaptureGroup(numeral)}${helpers.decimalPointCaptureGroup(numeral)}`;
    return new RegExp(format);
  },
  // matches unseparated numbers. e.g 1234 will pass. 1,234 will not.
  localeNumeric: (numeral = localNumeral) => {
    const format = `^-?[${helpers.escapedThousandsSeparator(numeral)}\\d]+${helpers.decimalPointCaptureGroup(numeral)}`;
    return new RegExp(format);
  },
};

/**
 * Cast localized string to number
 * @param {string} stringFormatNumber - the string value to parse
 * @returns {number|string} the parsed number or the original string
 */
const formatStringToNumber = (stringFormatNumber, numeral = localNumeral) => {
  const parsedValue = numeral(stringFormatNumber).value();
  return isFinite(parsedValue) ? parsedValue : stringFormatNumber;
};

/**
 * Parse a string to number if it's numeric
 * @param {string} stringFormatNumber - the string value to parse
 * @returns {number|string} the parsed number or the original string
 * stricter than formatStringToNumber, only accepts properly formatted numbers
 */
const stringToNumberIfNumeric = (stringFormatNumber, numeral = localNumeral) => {
  return regex.localeNumeric(numeral).test(String(stringFormatNumber))
    ? formatStringToNumber(stringFormatNumber)
    : stringFormatNumber;
};

// Returns true if value is not numeric
const valueIsNotNumeric = (value) => {
  const parsedValue = stringToNumberIfNumeric(value);
  return !isFinite(parsedValue);
};

module.exports = {
  formatStringToNumber,
  stringToNumberIfNumeric,
  valueIsNotNumeric,
};
