'use strict';

const Errors = {
  CanvasNotFound: 'CANVAS_NOT_FOUND',
  CanvasProductsNotFound: 'CANVAS_PRODUCTS_NOT_FOUND',
  UnableToCreateCanvas: 'UNABLE_TO_CREATE_CANVAS',
  UnableToCreateCanvasProducts: 'UNABLE_TO_CREATE_CANVAS_PRODUCTS',
  CanvasUpdateError: 'CANVAS_UPDATE_ERROR',
  ConstraintUpdateError: 'CONSTRAINT_UPDATE_ERROR',
  UnableToCreateConstraint: 'UNABLE_TO_CREATE_CANVAS',
  UnableToDeleteConstraint: 'UNABLE_TO_DELETE_CONSTRAINT',
  SpacebreakIdNotFoundInFurniture: 'SPACEBREAK_ID_NOT_FOUND_IN_FURNITURE',
  LimitsMustBeDefined: 'LIMITS_MUST_BE_DEFINED',
};

const Views = {
  Tile: 'tile',
  List: 'list',
};

const Constraints = {
  Between: 'between',
  AtLeast: 'atLeast',
  AtMost: 'atMost',
  Percentage: 'percentage',
  ProductCount: 'productCount',
  AbsoluteSpace: 'absolute',
};

const Filters = {
  ProductType: 'isNewProduct',
  LockType: 'lockType',
  CurrentSpacebreak: 'currentSpacebreak',
  OptimisedSpacebreak: 'optimisedSpacebreak',
  OriginalSpacebreak: 'originalSpacebreak',
  PromotedDemoted: 'promotedDemoted',
  customAttributes: 'customAttributes',
  ExternalProductRanking: 'externalProductRanking',
  CustomerSegment: 'customerSegment',
  ProductSeries: 'productSeries',
};

const FilterValues = {
  Locked: 'locked',
  Unlocked: 'unlocked',
  PromotedOptimisation: 'promotedOptimisation',
  DemotedOptimisation: 'demotedOptimisation',
  PromotedReferenceCheckpoint: 'promotedReferenceCheckpoint',
  DemotedReferenceCheckpoint: 'demotedReferenceCheckpoint',
  New: 'new',
  Existing: 'existing',
  NotListed: 'notListed',
  NotForAssortment: 'notForAssortment',
  NotInStoreClass: 'notInStoreClass',
  TopXExternalRanking: 'topXExternalRanking',
  HigherReferenceRanking: 'higherReferenceRanking',
};

const FilterMappings = {
  New: true,
  Existing: false,
  ProductType: 'isNewProduct',
  CurrentSpacebreak: 'currentSpacebreakId',
  OptimisedSpacebreak: 'optimisedSpacebreakId',
  OriginalSpacebreak: 'originalSpacebreakId',
  NotForAssortment: 'assortment',
  NotListed: true,
  NotInStoreClass: false,
};

const DisplayBy = {
  cdt: 'cdt',
  productSeries: 'productSeries',
  brandDescription: 'brandDescription',
  supplierName: 'supplierName'
};

module.exports = {
  Errors,
  Views,
  Constraints,
  Filters,
  FilterValues,
  FilterMappings,
  DisplayBy,
};
