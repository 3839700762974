<template>
  <v-btn
    data-id-e2e="btnExport"
    :disabled="downloadDisabled"
    depressed
    primary
    @click="downloadData"
  >
    {{ $t('actions.export') }}
    <v-icon right>import_export</v-icon>
  </v-btn>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CSVSchemas from '@sharedModules/data/csv/schemas';
import DataTypes from '@enums/data-types';
import exportCSV from '../mixins/export-csv';

export default {
  mixins: [exportCSV],
  props: {
    data: {
      type: Array,
      required: true,
    },
    serviceName: {
      type: String,
      required: true,
    },
    includeScenarioInExportName: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapGetters('context', ['getCsvDelimiter', 'getDateFormats']),
    downloadDisabled() {
      // If we don't have a serviceName then we cannot download.
      return !this.serviceName;
    },
    schema() {
      // TODO - over multiple tickets - currently not every export location is wired up, so it's possible we have not been passed a name
      if (!this.serviceName) return null;
      return CSVSchemas.for(this.serviceName);
    },
    booleanSchemaFields() {
      return this.schema.fixedFields.filter(({ type }) => type === DataTypes.boolean);
    },
    numericFields() {
      return this.schema.fixedFields.filter(({ type }) => type === DataTypes.number);
    },
    headersForExport() {
      return this.schema.fixedFields.map(({ translationKey }) => this.$t(translationKey));
    },
    dataKeysToExport() {
      return this.schema.fixedFields.map(({ mongoField }) => mongoField);
    },
  },
  methods: {
    downloadData() {
      const blob = new Blob([this.formatCSVData()], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = this.getFileName({
        serviceName: this.serviceName,
        workpackageName: this.selectedWorkpackage.name,
        scenarioName: this.includeScenarioInExportName ? this.selectedScenario.name : null,
        fileNameDateFormat: this.getDateFormats.csvFileName,
      });
      link.click();
      link.remove();
    },
    formatCSVData() {
      // Do this in method instead of computed so we are only formatting when user wants to download.
      const rowData = this.data
        .map(row =>
          this.extractAndTranslateValues({
            row,
            delimiter: this.getCsvDelimiter,
            keysToExport: this.dataKeysToExport,
            booleanFields: this.booleanSchemaFields,
            numericFields: this.numericFields,
          })
        )
        .join('\n');
      const headers = this.headersForExport.join(this.getCsvDelimiter);

      return `${headers}\n${rowData}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn > .v-btn__content .v-icon {
  color: white !important;
}
</style>
