import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VMenu,
    {
      ref: "notesMenu",
      attrs: {
        "content-class": _vm.notesType,
        "close-on-content-click": false,
        "offset-y": "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs, value }) {
            return [
              _c(
                VBtn,
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        disabled: _vm.disabled,
                        icon: "",
                        text: "",
                        color: "primary",
                      },
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  _vm.wrapOpenEvents(on, value)
                ),
                [
                  _vm.notifications
                    ? _c("span", { staticClass: "has-notes-badge" })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(VIcon, [_vm._v("$notes")]),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showNotes,
        callback: function ($$v) {
          _vm.showNotes = $$v
        },
        expression: "showNotes",
      },
    },
    [
      _vm._v(" "),
      _c("div", { staticClass: "notes" }, [
        _c(
          "header",
          [
            _c("h3", [_vm._v(_vm._s(_vm.title || _vm.$tkey("title")))]),
            _vm._v(" "),
            _c(
              VBtn,
              {
                attrs: { icon: "", color: "primary" },
                on: { click: _vm.close },
              },
              [
                _c(VIcon, { attrs: { color: "primary", size: "19" } }, [
                  _vm._v(" mdi-close-circle"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "notes__warning-container" }, [
          _c(
            "div",
            { staticClass: "notes__warning-container__scroller" },
            [
              _vm.loading
                ? _c("progress-bar", {
                    staticClass: "confirm",
                    attrs: { message: _vm.$t("general.loading") },
                  })
                : _vm.showConfirmDelete
                ? _c(
                    "div",
                    { staticClass: "confirm" },
                    [
                      _c("p", { staticClass: "confirm__question" }, [
                        _vm._v(_vm._s(_vm.$tkey("confirmDeleteQuestion"))),
                      ]),
                      _vm._v(" "),
                      _c("p", { staticClass: "confirm__warning" }, [
                        _vm._v(_vm._s(_vm.$tkey("confirmDeleteWarning"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "ma-1",
                          attrs: {
                            primary: "",
                            depressed: "",
                            width: "min-content",
                          },
                          on: { click: _vm.deleteNote },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions.delete")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "ma-1",
                          attrs: {
                            text: "",
                            depressed: "",
                            width: "min-content",
                          },
                          on: { click: _vm.cancel },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions.cancel")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.notes, function (note, noteIndex) {
                return _c(
                  "div",
                  { key: note._id, staticClass: "notes__container" },
                  [
                    _c("rtls-avatar", {
                      attrs: { username: note.user.username },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "notes__bodyText" },
                      [
                        _c("p", { staticClass: "notes__date" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("formatDate")(
                                  note.creationDate,
                                  _vm.getDateFormats.mediumWithTime
                                )
                              ) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.showOptionsMenu(note)
                          ? _c(
                              VMenu,
                              {
                                attrs: {
                                  disabled: _vm.isCurrentlyEditing(noteIndex),
                                  "close-on-click": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            VIcon,
                                            _vm._g(
                                              {
                                                attrs: {
                                                  color: _vm.isCurrentlyEditing(
                                                    noteIndex
                                                  )
                                                    ? "grey"
                                                    : "black",
                                                },
                                              },
                                              on
                                            ),
                                            [_vm._v("mdi-dots-horizontal")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  VList,
                                  { staticClass: "edit-menu" },
                                  [
                                    _c(
                                      VListItem,
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.enableEditMode(noteIndex)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.$t("actions.edit")) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      VListItem,
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.openDeleteConfirm(
                                              note._id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.$t("actions.delete")) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isCurrentlyEditing(noteIndex)
                          ? _c(
                              "form",
                              { staticClass: "notes__edit-form" },
                              [
                                _c(VTextarea, {
                                  staticClass: "notes__text",
                                  attrs: {
                                    "no-resize": "",
                                    solo: "",
                                    "hide-details": "",
                                    flat: "",
                                    autocomplete: "off",
                                    rows: "0",
                                    maxlength: _vm.maxCharSize,
                                  },
                                  model: {
                                    value: _vm.editText,
                                    callback: function ($$v) {
                                      _vm.editText = $$v
                                    },
                                    expression: "editText",
                                  },
                                }),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.remainingEditCharsMessage) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  VBtn,
                                  {
                                    attrs: {
                                      small: "",
                                      text: "",
                                      width: "min-content",
                                      height: "18",
                                    },
                                    on: { click: _vm.cancel },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("actions.cancel")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  VBtn,
                                  {
                                    attrs: {
                                      primary: "",
                                      small: "",
                                      depressed: "",
                                      width: "min-content",
                                      height: "18",
                                      disabled: _vm.isSaveButtonDisabled(
                                        note.message
                                      ),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.update(note)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("actions.save")) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("p", { staticClass: "notes__text" }, [
                              _vm._v(_vm._s(note.message)),
                            ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.showPostForm
            ? _c(
                "form",
                { staticClass: "notes__post-form" },
                [
                  _c(VTextarea, {
                    attrs: {
                      label: _vm.$tkey("addNewPrompt"),
                      "no-resize": "",
                      solo: "",
                      "hide-details": "",
                      flat: "",
                      autocomplete: "off",
                      rows: "0",
                      maxlength: _vm.maxCharSize,
                    },
                    model: {
                      value: _vm.text,
                      callback: function ($$v) {
                        _vm.text = $$v
                      },
                      expression: "text",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.remainingCharsMessage) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        primary: "",
                        small: "",
                        depressed: "",
                        width: "min-content",
                        height: "18",
                        disabled: _vm.isPostButtonDisabled,
                      },
                      on: { click: _vm.post },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tkey("postButton")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              )
            : !_vm.hasNotes
            ? _c("span", [_vm._v(_vm._s(_vm.$tkey("noNotes")))])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }