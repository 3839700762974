var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("switching-behaviour", {
    attrs: {
      "requires-analytics-text": _vm.$tkey("requiresAnalyticsRun"),
      "job-type": _vm.prepareSwapsJob,
    },
    on: { "run-prepare-swaps": _vm.runPrepareSwapsJob },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }