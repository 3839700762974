<template>
  <div class="reporting-selection">
    <v-btn
      class="sidebar-toggle-button ml-2"
      icon
      tile
      :ripple="false"
      @click="toggleSidebar(false)"
    >
      <img
        :class="{ 'button-active': isSidebarShown }"
        :src="isSidebarShown ? closeSidebarIcon : closeSidebarSelectedIcon"
        alt="Close sidebar icon"
      />
    </v-btn>
    <v-btn
      class="sidebar-toggle-button mr-2"
      icon
      tile
      :ripple="false"
      @click="toggleSidebar(true)"
    >
      <img
        :class="{ 'button-active': !isSidebarShown }"
        :src="isSidebarShown ? openSidebarSelectedIcon : openSidebarIcon"
        alt="Open sidebar icon"
      />
    </v-btn>

    <div v-if="isSidebarShown" class="reporting-selection__row mx-2 justify-end">
      <treeselect
        :value="selections"
        data-id-e2e="reportingBundleWorkpackageSelect"
        class="vue-treeselect-custom-tree mr-2 w-70 vue-treeselect--values-hidden"
        multiple
        :options="validOptions"
        :placeholder="`${$t('reportingPage.totalItemsSelected')}: ${totalSelected}`"
        :default-expand-level="1"
        :no-results-text="$t('reportingPage.noResults')"
        :no-options-text="noOptionsMessage"
        search-nested
        disable-fuzzy-matching
        :clearable="false"
        value-consists-of="LEAF_PRIORITY"
        @select="addSelection"
        @deselect="removeSelection"
        @close="changeSelections"
      >
        <header slot="before-list" class="vue-treeselect__header d-flex">
          <span v-if="bundlesEnabled" class="vue-treeselect__heading">
            {{ $t('reportingPage.selectionHeaders.bundles') }}
          </span>
          <span class="vue-treeselect__heading">
            {{ $t('reportingPage.selectionHeaders.workpackages') }}
          </span>
        </header>
        <label slot="option-label" slot-scope="{ node, labelClassName }" :class="labelClassName">
          <v-tooltip top :disabled="node.label.length < truncateThreshold">
            <template v-slot:activator="{ on }">
              <span v-on="on">
                {{ node.label }}
              </span>
            </template>
            <span>{{ node.label }}</span>
          </v-tooltip>
        </label>
      </treeselect>
      <docs-link link="toolguide/130-reporting.html" />
    </div>

    <div v-if="showNotImplemented" class="reporting-selection__row mx-2">
      <span class="span-text mx-2">{{ $t('general.filter') }}</span>
      <rtls-select
        v-model="filters"
        disabled
        width="240px"
        :items="[]"
        :placeholder="$t('general.select')"
      />
    </div>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import { size, get, some, map, groupBy, reduce, has, each } from 'lodash';
import reportingMetrics from '@enums/reporting-metrics';
import closeSidebarIcon from '@/img/closeSidebar.svg';
import closeSidebarSelectedIcon from '@/img/closeSidebarSelected.svg';
import openSidebarIcon from '@/img/openSidebar.svg';
import openSidebarSelectedIcon from '@/img/openSidebarSelected.svg';

export default {
  localizationKey: 'reportingPage',

  components: {
    Treeselect,
  },

  data() {
    return {
      filters: {},
      noBundle: { _id: 0, name: this.$t('reportingPage.noBundle') },
      selections: [],
      totalSelected: 0,
      bundleSelections: new Set(),
      workpackageSelections: new Set(),
      truncateThreshold: 25,
      closeSidebarIcon,
      closeSidebarSelectedIcon,
      openSidebarIcon,
      openSidebarSelectedIcon,
    };
  },

  computed: {
    ...mapState('bundles', ['bundles']),
    ...mapState('workpackages', ['workpackages']),
    ...mapState('reporting', ['selectedWorkpackages', 'selectedBundles', 'isSidebarShown']),
    ...mapGetters('bundles', ['bundlesById']),
    ...mapGetters('workpackages', ['workpackagesByBundle', 'workpackagesById']),
    ...mapGetters('reporting', ['scenariosByWorkpackage']),
    ...mapGetters('context', ['showNotImplemented']),

    noOptionsMessage() {
      const key = this.bundlesEnabled ? 'noBundlesAvaialable' : 'noWorkpackagesAvaialable';
      return this.$t(`reportingPage.${key}`);
    },

    validOptions() {
      if (!this.bundlesEnabled) return this.validWorkpackages;
      return map(this.validBundles, b => ({
        ...b,
        children: [...get(this.validWorkpackagesByBundleId, b._id, [])],
      }));
    },

    validWorkpackagesByBundleId() {
      return groupBy(this.validWorkpackages, 'bundleId');
    },

    validWorkpackages() {
      // treeselect id cannot be null so use 0 instead
      return reduce(
        this.workpackages,
        (acc, w) => {
          if (size(this.scenariosByWorkpackage[w._id])) {
            acc.push({
              ...w,
              id: w._id,
              label: w.name,
              bundleId: w.bundleId || 0,
            });
          }
          return acc;
        },
        []
      );
    },

    validBundles() {
      return reduce(
        [...this.bundles, this.noBundle],
        (acc, b) => {
          const workpackages = this.validWorkpackagesByBundleId[b._id];
          const hasValidWorkpackages = some(workpackages, w => !w.disabled);
          if (size(workpackages) && hasValidWorkpackages) {
            acc.push({
              ...b,
              id: b._id,
              label: b.name,
            });
          }
          return acc;
        },
        []
      );
    },
  },

  created() {
    const selections = new Set();
    if (this.bundlesEnabled) {
      // Populate bundle selections on load
      each(this.selectedBundles, b => {
        const bundleId = b._id || 0;
        this.bundleSelections.add(bundleId);
        selections.add(bundleId);
        each(this.validWorkpackagesByBundleId[bundleId], wp => {
          selections.add(wp._id);
        });
        this.totalSelected += size(this.validWorkpackagesByBundleId[bundleId]);
      });
    }
    // Populate workpackage selections
    each(this.selectedWorkpackages, wp => {
      this.workpackageSelections.add(wp._id);
      selections.add(wp._id);
    });
    this.selections = Array.from(selections);
    this.totalSelected += size(this.selectedWorkpackages);
  },

  methods: {
    ...mapMutations('reporting', ['setSelectedWorkpackages', 'setSelectedBundles']),
    ...mapActions('reporting', ['updateSidebarVisibility']),

    addSelection(selection) {
      // Only bundle options will have children
      if (this.bundlesEnabled && has(selection, 'children')) {
        // Adding bundle
        this.bundleSelections.add(selection._id);
      } else {
        // Adding workpackage
        this.workpackageSelections.add(selection._id);
      }
    },

    removeSelection(selection) {
      if (this.bundlesEnabled && has(selection, 'children')) {
        // Removing bundle
        this.bundleSelections.delete(selection._id);
        // Remove any related wpIds
        each(this.validWorkpackagesByBundleId[selection._id], wp => {
          this.workpackageSelections.delete(wp._id);
        });
      } else {
        this.workpackageSelections.delete(selection._id);
        if (this.bundlesEnabled && this.bundleSelections.has(selection.bundleId)) {
          // If the related bundle is selected, remove it
          this.bundleSelections.delete(selection.bundleId);
          // Add all workpackages excluding the workpackage being removed
          each(this.validWorkpackagesByBundleId[selection.bundleId], wp => {
            if (wp._id !== selection._id) this.workpackageSelections.add(wp._id);
          });
        }
      }
    },

    changeSelections() {
      if (this.bundlesEnabled) {
        const bundles = map(Array.from(this.bundleSelections), id =>
          get(this.bundlesById, id, { _id: null, name: this.noBundle.name })
        );
        this.setSelectedBundles(bundles);
      }
      const workpackages = map(Array.from(this.workpackageSelections), id =>
        get(this.workpackagesById, id)
      );
      this.setSelectedWorkpackages(workpackages);

      const query = {
        metricValue: reportingMetrics.metrics.sales,
        workpackageIds: map(this.selectedWorkpackages, '_id'),
      };
      if (this.bundlesEnabled) query.bundleIds = map(this.selectedBundles, '_id');

      this.$router.push({
        path: '/reporting-main',
        query,
      });
    },

    toggleSidebar(newValue) {
      this.updateSidebarVisibility(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.reporting-selection {
  box-sizing: border-box;
  display: flex;
  flex: 0;
  height: 36px;
  margin-bottom: 4px;
  padding: 8px 0;

  &__row {
    align-items: center;
    display: flex;
    height: 100%;
  }

  .v-toolbar__title {
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.span-text {
  font-size: 1.4rem;
  font-weight: 600;
  color: $assortment-text-colour;
}

.sidebar-toggle-button {
  width: 32px;
  height: 32px !important;
}

// Need to override menu width for treeselect
// defined around webtool/client-vue/style/base/_trees.scss:203,
// as the default 100% of input width is too narrow
::v-deep {
  .vue-treeselect-custom-tree {
    .vue-treeselect {
      &__menu {
        width: 600px;
      }
    }
  }
}
</style>
