<template>
  <v-menu offset-y max-height="400" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn secondary depressed v-bind="attrs" v-on="on">
        {{ buttonTitle }}
        <v-icon class="material-icons-outlined ml-1 columns-icon">view_column</v-icon>
      </v-btn>
    </template>

    <v-list>
      <draggable v-model="allColumns" :disabled="false" handle=".drag-handle" @end="onDragEnd">
        <template v-for="column in allColumns">
          <v-list-item :key="getColumnIdentifier(column)" class="display-by-title">
            <v-icon class="drag-handle mr-2" :class="{ invisible: isColumnDisabled(column) }">
              mdi-drag
            </v-icon>

            <v-checkbox
              v-model="selectedColumns"
              :value="getColumnIdentifier(column)"
              :label="getColumnLabel(column)"
              :disabled="isColumnDisabled(column)"
              hide-details
              class="rtls-checkbox mx-2"
              @change="toggleColumn(column)"
            />
          </v-list-item>
        </template>
      </draggable>
    </v-list>
  </v-menu>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
  name: 'ColumnSettings',
  components: {
    Draggable,
  },

  /**
   * The `<column-settings>` component is used to allow the user to select which columns they want to display in a table or grid view, as well as reorder the columns by dragging and dropping them.
   *
   * @prop {Array} columns - An array of column objects representing all available columns. Each column object should have an `id` property (unique identifier for the column) and a `title` property (display name for the column).
   * @prop {String} [buttonTitle='Columns'] - The text to display on the button that opens the column settings menu.
   * @prop {Array} [disabledColumns=[]] - An array of column IDs that should be disabled (not selectable or reorderable) in the settings menu.
   *
   * @event update:selected-columns - Emitted when the user changes the selected columns. Passes the updated array of selected column IDs.
   * @event update:column-order - Emitted when the user reorders the columns. Passes the updated array of column objects in the new order.
   */
  props: {
    columns: {
      type: Array,
      required: true,
    },

    buttonTitle: {
      type: String,
      required: false,
      default: 'Columns',
    },

    disabledColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      allColumns: [...this.columns], // Complete list of columns
      selectedColumns: [
        ...this.columns.filter(col => col.visible).map(col => this.getColumnIdentifier(col)),
      ], // Selected columns
    };
  },

  methods: {
    getColumnLabel(column) {
      return column.title || column.name;
    },

    // to be flexible to different column types
    getColumnIdentifier(column) {
      return column.id || column.key;
    },

    isColumnDisabled(column) {
      const colIdentifier = this.getColumnIdentifier(column);
      return this.disabledColumns.includes(colIdentifier);
    },

    onDragEnd() {
      this.$emit('update:column-order', this.allColumns);
    },

    toggleColumn(column) {
      this.$emit('update:selected-columns', column);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.drag-handle {
  cursor: move;
}

.invisible {
  visibility: hidden;
}

.columns-icon {
  color: $assortment-primary-colour !important;
  font-family: 'Material Icons Outlined';
  font-feature-settings: 'liga';
  font-size: 20px;
}
</style>
