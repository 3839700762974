<!-- Modal for saving product report layouts, contains a form for settig the name -->
<template>
  <v-dialog :value="value" width="500px" @click:outside="closeModal">
    <dialog-card
      v-if="value"
      :title="$t('reportingPage.sections.product.sideBar.toolPanels.layouts.saveLayout')"
      @close="closeModal"
    >
      <v-container fluid class="pa-0 reset-product-data__container">
        <!-- reset options list -->
        <div class="mt-4 pb-5">
          <v-row>
            <span class="font-weight-bold">
              {{ $t('reportingPage.sections.product.sideBar.toolPanels.layouts.layoutName') }}
            </span>
          </v-row>
          <v-row>
            <rtls-text-field v-model="layoutName" grey class="mr-2 ml-4 my-2" style="width:80%;" />
          </v-row>
        </div>
      </v-container>
      <template v-slot:footer>
        <page-actions
          :has-data-changes="!!layoutName.length"
          :has-data-errors="false"
          :show-discard="false"
          save-btn-text="save"
          :save-loading="isSaving"
          @save="saveLayout"
        />
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      layoutName: '',
    };
  },
  computed: {
    ...mapState('reporting', ['isSaving']),
  },
  methods: {
    saveLayout() {
      this.$emit('save', { name: this.layoutName });
      this.closeModal();
    },

    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.reset-product-data {
  &__container {
    font-size: 1.2rem;
    max-height: 500px;
  }
}

.border-bottom {
  border-bottom: $assortment-border-colour 1px solid;
}

::v-deep {
  .dialog-card {
    &__footer {
      justify-content: flex-end;
    }
  }
}
</style>
