<template>
  <v-icon size="20">${{ status }}</v-icon>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';

import sectionStatuses from '@enums/section-status';

export default {
  props: {
    itemStatus: {
      required: true,
      type: Object,
    },
    subsection: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapGetters('context', ['getDateFormats']),
    status() {
      return get(this.itemStatus, `${this.subsection}.status`, sectionStatuses.notStarted);
    },
  },
};
</script>
