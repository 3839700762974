<template>
  <v-container class="px-8 py-6" fluid>
    <v-row>
      <slot />
    </v-row>
    <v-row class="mt-4">
      <v-btn :disabled="disabled" small primary @click="generate">
        {{ $t('extract.buttons.generateExtract') }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { extracts } from '@enums/client-events';

export default {
  props: {
    disabled: {
      required: false,
      type: Boolean,
      default: () => false,
    },
  },

  methods: {
    generate() {
      this.$emit(extracts.generateExtractRequested);
    },
  },
};
</script>

<style lang="scss" scoped></style>
