<template>
  <div class="switching-tooltip">
    <div class="text">
      <span>
        {{ $t('switchingBehaviour.tooltipFrom') }} <b>{{ valueInfo.from }}</b>
      </span>
      <span>
        {{ $t('switchingBehaviour.tooltipTo') }} <b>{{ valueInfo.to }}</b>
      </span>
      <span>
        {{ $t('switchingBehaviour.tooltipSwitchAbility') }} <b>{{ valueInfo.value }}</b>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    valueInfo: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

$base-triangle-x-position: 5px;
.switching-tooltip {
  position: absolute;
  width: 125px;
  border: 1px solid $assortment-border-colour;
  pointer-events: none;
  background-color: $assortment-table-header-white-background;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.6);
}

.switching-tooltip .text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}
.switching-tooltip:before {
  top: 45px;
  left: -75px;
  position: absolute;
  width: 125px;
  padding: 5px;
  z-index: 100;
  pointer-events: none;
  border: 1px solid $assortment-border-colour;
  background: $assortment-table-header-white-background;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.6);
}
.switching-tooltip .text:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-color: transparent transparent $assortment-table-header-white-background transparent;
  color: $assortment-table-header-white-background;
  border-style: solid;
  border-width: 11px;
  left: $base-triangle-x-position;
  top: -21px;
  z-index: 1;
}

.switching-tooltip .text:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #dddddd;
  position: absolute;
  left: calc(#{$base-triangle-x-position} - 1px);
  top: -12px;
  z-index: 0;
}

.switching-tooltip:hover {
  cursor: default;

  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
}
</style>
