<template>
  <div>
    <span v-if="mostRecentOutbound && isVisible && !isDisabled">
      <v-icon
        v-if="mostRecentOutbound.status === finished"
        :title="$tkey('recentlyUploadedOn')"
        size="20"
        class="icon finished"
        >mdi-check-circle</v-icon
      >
      <v-icon
        v-if="mostRecentOutbound.status === error"
        :title="$tkey('errorDuringUpload')"
        size="20"
        class="icon error-icon"
        >mdi-alert</v-icon
      >
      {{ mostRecentOutboundTimestamp | formatDate(getDateFormats.longWithFullTime) }}
    </span>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { finished, error } from '@enums/ftp-upload-statuses';
import { get, size } from 'lodash';
import { AOC } from '@enums/extract-types';
import moment from 'moment';

export default Vue.extend({
  name: 'uploadButtonRenderer',
  localizationKey: 'compoundExtractsPage.uploadButton',

  data() {
    return {
      loading: false,
      moment,
      finished,
      error,
    };
  },

  computed: {
    ...mapGetters('context', ['getDateFormats']),
    ...mapState('context', ['clientConfig']),
    ...mapGetters('compoundExtract', ['getExtractById', 'extractSeriesIdBySettingId']),

    extractId() {
      return get(this.params, 'extractId');
    },

    extract() {
      return this.getExtractById(this.extractId) || {};
    },

    outboundType() {
      return get(this.params, 'outboundType');
    },

    outboundHistory() {
      return get(this.params, 'outboundHistory', null);
    },

    mostRecentOutbound() {
      const outboundHistoryByType = this.getOutboundHistoryByType();
      if (!outboundHistoryByType || !outboundHistoryByType.length) return null;

      const mostRecentOutboundByType = outboundHistoryByType.reduce((a, b) => {
        return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
      });

      return mostRecentOutboundByType;
    },

    mostRecentOutboundTimestamp() {
      return moment.utc(this.mostRecentOutbound.timestamp);
    },

    isVisible() {
      return this.extract.extractType === AOC;
    },

    isDisabled() {
      return (
        !this.hasPermission(this.userPermissions.canEditCompoundExtracts) ||
        !this.extract._id ||
        !this.extract.extractSettingsId ||
        !size(this.extract.blobId)
      );
    },
  },

  methods: {
    getOutboundHistoryByType() {
      if (!this.outboundHistory) return null;
      return this.outboundHistory.filter(element => element.type === this.outboundType);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

a {
  text-decoration: none;
}

.icon {
  margin: 0 8px !important;
}

.finished {
  color: $assortment-positive-action-colour;
}

.error-icon {
  color: $assortment-negative-action-colour;
}
</style>
