import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VListItem,
    [
      _c(
        VCol,
        { staticClass: "pa-2 d-flex align-center", attrs: { cols: "4" } },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.field.translationKey
                  ? _vm.$t(_vm.field.translationKey)
                  : _vm.field.selectedField
              ) +
              "\n  "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        VCol,
        { staticClass: "pa-2 pr-1 d-flex", attrs: { cols: "4" } },
        [
          _c(VAutocomplete, {
            staticClass: "rtls-select",
            class: {
              "font-weight-bold": _vm.isActionOption(_vm.selectedField),
            },
            attrs: {
              value: _vm.selectedField,
              items: _vm.fieldsOptions,
              error: _vm.error && !_vm.isCreatingNew,
              "item-text": "selectedField",
              "hide-details": "",
              "return-object": "",
              placeholder: _vm.$t("mapFieldsModal.selectOption"),
            },
            on: { change: _vm.updateSelectedField },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function (data) {
                  return [
                    _c(
                      VListItemContent,
                      [
                        _c(
                          VListItemTitle,
                          {
                            staticClass: "v-list-item__title",
                            class: {
                              "font-weight-bold": _vm.isActionOption(
                                data.item.selectedField
                              ),
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(data.item.selectedField) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("error-triangle", {
            staticClass: "ml-1",
            class: { hidden: _vm.isCreatingNew },
            attrs: { errors: _vm.errors },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isCreatingNew
        ? _c(
            VCol,
            { staticClass: "pa-2 pl-1 d-flex", attrs: { cols: "4" } },
            [
              _c(VTextField, {
                staticClass: "rtls-text-field rtls-text-field--grey",
                attrs: {
                  error: _vm.error,
                  "single-line": "",
                  "hide-details": "",
                  value: _vm.field.selectedField,
                },
                on: {
                  input: function ($event) {
                    return _vm.setNewMapping(_vm.field.mongoField, $event)
                  },
                },
              }),
              _vm._v(" "),
              _c("error-triangle", {
                staticClass: "ml-1 mr-1",
                attrs: { errors: _vm.errors },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }