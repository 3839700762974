import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        VContainer,
        {
          staticClass:
            "pa-0 ma-0 d-flex flex-column flex-grow-1 date-assortment-groups__container",
        },
        [
          _c(
            VRow,
            { staticClass: "date-assortment-groups__header flex-grow-0 ma-0" },
            [
              _c(VCol, [
                _c("span", { staticClass: "info-note" }, [
                  _vm._v(_vm._s(_vm.$tkey("infoNote"))),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VRow,
            {
              staticClass: "date-assortment-groups__form flex-grow-1",
              attrs: { "no-gutters": "" },
            },
            [
              _c(
                VCol,
                { staticClass: "pr-4 col-3" },
                [
                  _c("dates-selection", {
                    attrs: {
                      "date-selections": _vm.dateSelections,
                      "fill-in-selection": _vm.model.fillInSelection,
                      "maximum-trend": _vm.model.maximumTrend,
                      workpackage: _vm.workpackage,
                      "period-ids": _vm.model.periodIds,
                    },
                    on: {
                      "date-changed": _vm.populateDateSelections,
                      "model-changed": _vm.populateModel,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VCol,
                { staticClass: "pl-3 pr-3 col-7 col-border-left" },
                [
                  _vm.dateSelections.snapshotDate
                    ? _c("assortment-groups-selection", {
                        ref: "assortmentGroupSelection",
                        attrs: {
                          "snapshot-date": _vm.dateSelections.snapshotDate,
                          "reset-assortment-groups": _vm.resetAssortmentGroups,
                          "selected-assortment-group-settings":
                            _vm.workpackage.selectedAssortmentGroupSettings ||
                            [],
                        },
                        on: {
                          "select-assortment-group":
                            _vm.populateSelectedAssortmentGroups,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VRow,
            { staticClass: "date-assortment-groups__footer flex-grow-0" },
            [
              _c(
                VCol,
                { staticClass: "d-flex pa-0" },
                [
                  _c("page-actions", {
                    attrs: {
                      "has-data-changes": _vm.hasDataChanges,
                      "has-data-errors": _vm.hasDataErrors,
                      "is-discard-enabled": !_vm.isEditingDisabled,
                      "save-disabled":
                        _vm.isWorkpackageSetupRunning || _vm.isEditingDisabled,
                    },
                    on: {
                      discard: _vm.discardChanges,
                      save: function ($event) {
                        return _vm.saveTemplate(false)
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "left-btns",
                        fn: function () {
                          return [
                            _c(VDivider, {
                              staticClass: "mr-3 divider",
                              attrs: { vertical: "" },
                            }),
                            _vm._v(" "),
                            _c("import-from-planogram", {
                              staticClass: "import-from-planogram",
                              attrs: {
                                "should-be-disabled": _vm.hasDataChanges,
                                "custom-workpackage": _vm.dirtyWorkpackage,
                              },
                            }),
                            _vm._v(" "),
                            _c(VDivider, {
                              staticClass: "mr-3 divider",
                              attrs: { vertical: "" },
                            }),
                            _vm._v(" "),
                            _c(
                              VTooltip,
                              {
                                attrs: {
                                  value: _vm.hasInvalidPeriodIds,
                                  disabled: !_vm.hasInvalidPeriodIds,
                                  top: "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "div",
                                          _vm._g({}, on),
                                          [
                                            _c(
                                              VBtn,
                                              {
                                                staticClass: "mx-0",
                                                attrs: {
                                                  "data-id-e2e":
                                                    "btnRunTemplate",
                                                  disabled:
                                                    _vm.isEditingDisabled ||
                                                    _vm.hasDataChanges ||
                                                    _vm.hasInvalidPeriodIds,
                                                  loading:
                                                    _vm.isWorkpackageSetupRunning,
                                                  depressed: "",
                                                  primary: "",
                                                  "min-width": "65",
                                                  small: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.runTemplateSetup(
                                                      false
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "datesAssortmentGroupsPage.runWp"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              },
                              [
                                _vm._v(" "),
                                _vm.hasInvalidPeriodIds
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "workpackagePage.scope.warnings.periodIdWarning"
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("dependency-tree-feedback-modal", {
                    staticClass: "dependency-modal",
                    attrs: {
                      value: _vm.dependencyTreeModalOpen,
                      results: _vm.dependencyTreeFeedback,
                      page: "workpackageSetup",
                    },
                    on: {
                      close: _vm.closeDependencyTreeModal,
                      commit: _vm.commitHandler,
                    },
                  }),
                  _vm._v(" "),
                  _c("unsaved-data-modal", {
                    ref: "unsavedDataModal",
                    attrs: { value: _vm.isUnsavedDataModalOpen },
                    on: {
                      cancel: _vm.closeUnsavedDataModal,
                      confirm: _vm.closeUnsavedDataModal,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }