import axios from 'axios';
import to from 'await-to-js';
import _ from 'lodash';

const store = {
  namespaced: true,
  actions: {
    async triggerDependencyTree({ rootState }, args) {
      const workpackageId = _.get(rootState, 'workpackages.selectedWorkpackage._id');
      const workpackageType = _.get(rootState, 'workpackages.selectedWorkpackage.type');
      const scenarioId = _.get(rootState, 'scenarios.selectedScenario._id');

      function checkTypes(params) {
        // ensure that all required params have correct types
        const typeChecks = [
          _.isString(params.change),
          _.isObject(params.updates),
          _.isBoolean(params.commit),
        ];

        if (_.some(typeChecks, check => check === false))
          throw new Error('Invalid dependency parameters');
      }

      checkTypes(args.params);

      const params = {
        // workpackageId and scenarioId can be overwritten
        workpackageId,
        workpackageType,
        scenarioId,
        ...args.params,
      };

      const [err, response] = await to(axios.post(`/api/dependency-tree`, { params }));
      if (err) throw new Error(err.message);

      return response.data;
    },
  },
};

export default store;
