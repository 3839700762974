var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "top-partners" },
    [
      _c("div", { staticClass: "d-flex top-partners-header" }, [
        _c("div", { staticClass: "col-2 top-partners-id-header table-item" }, [
          _vm._v("\n      " + _vm._s(_vm.$tkey("productKey")) + "\n    "),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6 top-partners-description-header table-item" },
          [_vm._v("\n      " + _vm._s(_vm.$tkey("description")) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-4 top-partners-value-header table-item" },
          [
            _vm._v(
              "\n      " + _vm._s(_vm.$tkey("switchingProbability")) + "\n    "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.topPartners, function (tp) {
        return _c(
          "div",
          { key: tp.productKey, staticClass: "d-flex top-partners-content" },
          [
            _c(
              "div",
              {
                staticClass: "col-2 top-partners-id table-item",
                attrs: { title: tp.productKeyDisplay },
              },
              [_vm._v("\n      " + _vm._s(tp.productKeyDisplay) + "\n    ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "col-6 top-partners-description table-item",
                attrs: { title: tp.itemDescription },
              },
              [_vm._v("\n      " + _vm._s(tp.itemDescription) + "\n    ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-4 top-partners-value table-item" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.formatNumber({
                      number: tp.value * 100,
                      format: "float",
                    })
                  ) +
                  "%\n      " +
                  _vm._s(tp.tooSmall ? _vm.$tkey("valueTooSmall") : "") +
                  "\n    "
              ),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }