import { get } from 'lodash';
import * as WORK_PACKAGE_TYPE from '@enums/workpackage-type';
import scenarioTabs from './scenario-tabs-enum';
import tabUtils from './tabs-utils';

const tabs = [
  {
    id: 'space',
    routeName: 'space',
    position: 0,
    wpTypes: [WORK_PACKAGE_TYPE.ASSORTMENT_FULL_RESET, WORK_PACKAGE_TYPE.ASSORTMENT_SIMPLE_SWAPS],
    disabled: false,
    translationKey: 'toolbar.spaceTab',
    firstChild: scenarioTabs.FURNITURE_EDITOR_TAB,
    statusSection: 'space',
  },
  {
    id: 'inputs',
    routeName: 'inputs',
    position: 1,
    wpTypes: [WORK_PACKAGE_TYPE.ASSORTMENT_FULL_RESET, WORK_PACKAGE_TYPE.ASSORTMENT_SIMPLE_SWAPS],
    disabled: false,
    translationKey: 'toolbar.inputsTab',
    firstChild: scenarioTabs.ATTRIBUTES_TAB,
    statusSection: 'inputs',
  },
  {
    id: 'measuring',
    routeName: 'measuring',
    position: 2,
    wpTypes: [WORK_PACKAGE_TYPE.ASSORTMENT_FULL_RESET],
    disabled: false,
    translationKey: 'toolbar.measuringTab',
    firstChild: scenarioTabs.SWITCHING_BEHAVIOUR_TAB,
    statusSection: 'measuring',
  },
  {
    id: 'modelling',
    routeName: 'modelling',
    position: 3,
    wpTypes: [WORK_PACKAGE_TYPE.ASSORTMENT_FULL_RESET],
    disabled: false,
    translationKey: 'toolbar.modellingTab',
    firstChild: scenarioTabs.PRODUCT_MODELLING_TAB,
    statusSection: 'modelling',
  },
  {
    id: 'assortments-panel',
    routeName: 'assortments-panel',
    position: 4,
    wpTypes: [WORK_PACKAGE_TYPE.ASSORTMENT_FULL_RESET],
    disabled: false,
    translationKey: 'toolbar.assortmentsTab',
    firstChild: null,
    statusSection: 'assortment',
  },
  {
    id: 'execution',
    routeName: 'execution',
    position: 5,
    wpTypes: [WORK_PACKAGE_TYPE.ASSORTMENT_FULL_RESET],
    disabled: false,
    translationKey: 'toolbar.executionTab',
    // Finalisation is first child if it is enabled and accessible for the chosen scenario.
    // In other case first child is product store extract.
    firstChild(status, clientConfig) {
      // If status is not provided then this function is called for the route that would not exist for WP.
      if (!status) return null;
      if (get(clientConfig, 'features.finalisationEnabled')) {
        const finalisationTab = scenarioTabs.ALL.find(tab => tab.routeName === 'finalisation');
        const statusSection = get(status, finalisationTab.statusSection);
        if (
          statusSection &&
          statusSection[finalisationTab.statusSubSection] &&
          statusSection[finalisationTab.statusSubSection].available
        ) {
          return scenarioTabs.FINALISATION_TAB;
        }
      }
      return scenarioTabs.PRODUCT_STORE_EXTRACT_TAB;
    },
    statusSection: 'execution',
  },
  {
    id: 'swaps',
    routeName: 'swaps',
    position: 2,
    wpTypes: [WORK_PACKAGE_TYPE.ASSORTMENT_SIMPLE_SWAPS],
    disabled: false,
    translationKey: 'toolbar.swapsTab',
    firstChild: scenarioTabs.PRODUCTS_TO_SWAP_TAB,
    statusSection: 'swaps',
  },
  {
    id: 'extracts',
    routeName: 'extracts',
    position: 3,
    wpTypes: [WORK_PACKAGE_TYPE.ASSORTMENT_SIMPLE_SWAPS],
    disabled: false,
    translationKey: 'toolbar.extractsTab',
    firstChild: null,
    statusSection: 'extracts',
  },
];

export default {
  ...tabUtils.generateTabRouteMap(tabs),
  ALL: tabs,
};
