<template>
  <div class="compound-extracts-toolbar pa-2">
    <div class="compound-extracts-toolbar__toggle">
      <span class="compound-extracts-toolbar__toggle-label mr-2">{{ $tkey('filterLabel') }}:</span>
      <rtls-toggle
        mandatory
        :value="selectedExtractFilter"
        :left-toggle="toggle.leftToggle"
        :right-toggle="toggle.rightToggle"
        @input="$emit('update-selected-extract-filter', $event)"
      />
      <docs-link :link="`toolguide/140-extracts.html`" />
    </div>

    <v-menu id="myMenu" min-width="500" max-width="500" left offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          id="btnGenerateExtracts"
          data-id-e2e="btnGenerateExtracts"
          class="generate-extracts__button"
          action
          depressed
          :disabled="!availableExtracts.length || isProcessing"
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('actions.newExtract') }}
          <v-icon class="generate-extracts__icon" right>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>

      <v-list>
        <span
          v-for="({ name, reportType, tkey }, i) in availableExtracts"
          :id="`btnFetchCompound${reportType}`"
          :key="i"
          :to="{ name }"
          class="extract-link selectable"
          @click="() => onRouteClick(name, reportType)"
        >
          <v-list-item>
            <v-list-item-title>{{ $t(tkey) }}</v-list-item-title>
          </v-list-item>
        </span>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import to from 'await-to-js';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import compoundExtractFilters from '@enums/compound-extract-filters';

export default {
  localizationKey: 'compoundExtractsPage.toolbar',

  props: {
    availableExtracts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      toggle: {
        leftToggle: {
          value: compoundExtractFilters.all,
          translation: this.$tkey('toggle.allBtn'),
        },
        rightToggle: {
          value: null,
          translation: this.$tkey('toggle.myExtractsBtn'),
        },
      },
    };
  },

  computed: {
    ...mapState('compoundExtract', ['selectedExtractFilter', 'isProcessing']),
    ...mapGetters('context', ['getFullName']),
  },

  created() {
    // Set the value of the right toggle to the full name of the user
    this.toggle.rightToggle.value = this.getFullName;
  },

  methods: {
    ...mapMutations('compoundExtract', ['setIsProcessing']),
    ...mapActions('compoundExtract', ['generateExtract']),

    async onRouteClick(routeName, extractType) {
      this.setIsProcessing(true);
      const [err, newExtract] = await to(this.generateExtract(extractType));
      this.setIsProcessing(false);
      if (err) return;
      this.$router.push({
        name: routeName,
        params: {
          extractSeriesId: newExtract.series._id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.compound-extracts-toolbar {
  border-bottom: 1px solid #eff5f6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__toggle {
    align-items: center;
    display: flex;

    ::v-deep .v-btn-toggle .v-btn.v-size--default {
      height: 29px !important;
      min-width: 0 !important;
      padding: 0 17px;

      span {
        font-size: 1.4rem;
      }
    }
  }

  &__toggle-label {
    color: $assortment-font-colour;
    font-size: 1.4rem;
  }
}

::v-deep {
  .generate-extracts {
    &__button {
      height: 32px !important;
      padding: 0 12px !important;

      span {
        font-size: 1.6rem;
      }
    }

    &__icon {
      margin-left: 2px;
    }
  }
}

.extract-link {
  color: inherit;
  text-decoration: none;
}
</style>
