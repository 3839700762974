<template>
  <div class="mb-4">
    <v-tabs v-model="selectedTab" hide-slider grow>
      <v-tab v-for="item in tabs" :key="item.key" class="relative-product-performance__tab">
        {{ $t(item.translationKey) }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item
        v-for="item in tabs"
        :key="item.key"
        class="relative-product-performance__tab-item"
        :transition="false"
        :reverse-transition="false"
      >
        <component :is="item.componentName" :product-key="productKey" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sortBy, get } from 'lodash';
import tabEnum from '../../../../utils/tabs/relative-product-performance-tabs-enum';

export default {
  localizationKey: 'assortmentCanvasPage.productDashboard.relativeProductPerformance',

  data() {
    return {
      selectedTab: 0,
    };
  },

  computed: {
    ...mapGetters('assortmentCanvas', ['getDashboardProduct']),

    productKey() {
      return get(this.getDashboardProduct, 'productKey');
    },

    tabs() {
      return sortBy(tabEnum.relativeProductPerformanceTabs, 'position');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.relative-product-performance {
  &__tab {
    color: $assortment-text-colour !important;
    background: $assortment-unselected-tab-colour;
    border-bottom: 1px solid $assortment-divider-colour;
    border-right: 1px solid $assortment-divider-colour;
    border-top: 1px solid $assortment-unselected-tab-colour;
    font-size: 1.3rem;
    font-weight: bold;
    justify-content: flex-start;
    padding: 0 18px;
    text-transform: none;

    &.v-tab--active {
      background: $assortment-active-tab-colour;
      border-bottom: 1px solid $assortment-active-tab-colour;
      border-top: 1px solid $assortment-divider-colour;
    }
  }

  &__tab-item {
    padding: 20px 0;
  }
}
</style>
