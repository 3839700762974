<template>
  <div class="sidepanel" :class="{ open: isPanelExpanded }">
    <div class="sidepanel__container">
      <div class="sidepanel__toggle">
        <v-btn class="toggle-btn" @click="toggleExpand">
          {{ toggleBtnText }}

          <v-icon size="24">{{ toggleIcon }}</v-icon>
        </v-btn>
      </div>

      <div class="sidepanel__header">
        <h4>{{ title }}</h4>
      </div>

      <div class="sidepanel__content">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,

      required: true,
    },

    toggleBtnText: {
      type: String,

      required: true,
    },
  },

  data() {
    return {
      isPanelExpanded: false,
    };
  },

  computed: {
    toggleIcon() {
      return this.isPanelExpanded ? 'mdi-chevron-down' : 'mdi-chevron-up';
    },
  },

  methods: {
    toggleExpand() {
      this.isPanelExpanded = !this.isPanelExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.sidepanel {
  z-index: 198;
  background-color: $assortment-sidepanel-bg-colour;
  border-left: 2px solid $assortment-border-colour;
  box-shadow: none;
  height: 100%;
  max-width: 0;
  min-width: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 500ms ease;

  &__container {
    height: 100%;
    position: relative;
  }

  &__toggle {
    left: (-$assortment-sidepanel-toggle-height);
    position: absolute;
    top: 50%;
    transform: rotate(270deg) translateX(-50%);
    transform-origin: -4px 0;
  }

  &__header {
    border-bottom: 1px solid $assortment-menu-border-colour;
    padding: 22px 9px 4px;
    white-space: nowrap;
  }

  &__content {
    height: 100%;
    overflow: auto;
    white-space: nowrap;
  }

  &.open {
    box-shadow: -4px 0 4px 0 rgba(0, 0, 0, 0.12);
    max-width: 20%;
    min-width: 216px;
  }
}

.toggle-btn {
  background-color: $assortment-sidepanel-toggle-bg-colour !important;
  border-radius: 3px 3px 0 0;
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.06);
  color: $assortment-sidepanel-toggle-colour;
  padding: 0 5px 0 16px !important;
  z-index: 1;

  .v-icon {
    margin-left: 2px;
  }

  ::v-deep {
    span {
      font-size: 0.9rem;
      font-weight: bold;
      line-height: 1.1rem;
      text-transform: uppercase;
    }
  }
}

.title-text {
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
