<template>
  <component :is="loginComponent" />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('context');

export default {
  computed: {
    ...mapState(['loginMethod']),

    loginComponent() {
      let component = 'login-hardcoded';
      if (this.loginMethod === 'ow-auth-okta') component = 'okta-login';
      if (this.loginMethod === 'auth-saml') component = 'login-saml';
      return component;
    },
  },
};
</script>
