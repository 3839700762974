import { render, staticRenderFns } from "./trash-icon.vue?vue&type=template&id=6ab7109c&functional=true"
import script from "./trash-icon.vue?vue&type=script&lang=js"
export * from "./trash-icon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports