import loadRoutes from './loadRoutes';
import registerComponents from './registerComponents';
import registerDirectivesAndFilters from './registerDirectivesAndFilters';

function install(Vue) {
  if (install.installed) return;
  install.installed = true;

  Vue.loadRoutes = folder => loadRoutes(Vue, folder);

  Vue.registerComponents = folder => registerComponents(Vue, folder);

  Vue.registerDirectivesAndFilters = req => registerDirectivesAndFilters(Vue, req);
}

// Export individual components and utilities, so they can be imported like import {datepicker} from 'vue-plugins';
export { registerComponents, registerDirectivesAndFilters, loadRoutes };

// Use the installation function as default export to include everything
export default install;
