import { render, staticRenderFns } from "./available-furniture-archetypes-list.vue?vue&type=template&id=d72f014a&scoped=true"
import script from "./available-furniture-archetypes-list.vue?vue&type=script&lang=js"
export * from "./available-furniture-archetypes-list.vue?vue&type=script&lang=js"
import style0 from "./available-furniture-archetypes-list.vue?vue&type=style&index=0&id=d72f014a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d72f014a",
  null
  
)

export default component.exports