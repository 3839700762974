var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cann-group" },
    [
      _c("notes", {
        attrs: {
          notes: _vm.getAllNotes,
          loading: _vm.isLoading,
          title: _vm.$t("notes.types.cannGroup"),
          "max-chars": _vm.getClientConfig.notesSettings.maxLength,
          "is-cann-group": true,
          notifications: !!_vm.leafNode.totalNotes,
        },
        on: {
          post: function ($event) {
            return _vm.postNewCannGroupNote(_vm.leafNode.key, $event)
          },
          menuOpen: function ($event) {
            return _vm.fetchSingleCannGroupNotes(_vm.leafNode.key)
          },
          delete: function ($event) {
            return _vm.deleteCannGroupNote(_vm.leafNode.key, $event)
          },
          update: function ($event) {
            return _vm.updateCannGroupNote(_vm.leafNode.key, $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }