<template>
  <div
    class="store-allocation-table__container d-flex flex-column grid-container"
    style="width: 100%; height: 100%;"
  >
    <ag-grid-vue
      style="width: 100%; height: 100%"
      class="ag-theme-custom"
      :column-defs="columnDefs"
      :row-data="clusterData"
      :grid-options="gridOptions"
      :components="components"
      :stop-editing-when-cells-loses-focus="true"
      @cell-value-changed="onChange"
      @grid-ready="onGridReady"
    />
    <page-actions
      :has-data-changes="hasDataChanges"
      :has-data-errors="hasDataErrors"
      @discard="discard"
      @save="onSave"
    />
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import {
  cloneDeep,
  get,
  keyBy,
  pick,
  isEqual,
  isEmpty,
  toArray,
  has,
  each,
  groupBy,
  every,
  camelCase,
  isUndefined,
  find,
  findIndex,
  some,
  reduce,
  size,
} from 'lodash';
import agGridUtils from '@/js/utils/ag-grid-utils';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import agGridIcon from '@/js/components/ag-grid-cell-renderers/ag-grid-icon.vue';
import agGridCellRendererComponentWrapper from '@/js/components/ag-grid-cell-renderers/ag-grid-cell-renderer-component-wrapper.vue';
import iconTooltipRenderer from './icon-tooltip-renderer.vue';

const SCENARIO = 'scenario-cluster';
const REFERENCE = 'reference-cluster';

export default {
  name: 'StoreAllocationTable',
  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    agGridIcon,
    agGridCellRendererComponentWrapper,
    iconTooltipRenderer,
  },
  mixins: [unsavedDataWarningMixin],
  props: {
    mode: {
      type: String,
      required: true,
      default: 'scenario-cluster', // scenario-cluster or reference-cluster
    },
  },
  localizationKey: 'clusteringPage.tabs.storeAllocation',
  data() {
    return {
      gridApi: null,
      columnApi: null,
      clusterData: null,
      storeKeyMap: null,
      storeKeysWithoutSales: new Set(),
      clusterWithoutSalesDataMap: {},
      gridOptions: {
        onSelectionChanged: this.onSelectionChanged,
        statusBar: {
          statusPanels: [{ statusPanel: 'agSelectedRowCountComponent', align: 'right' }],
        },
        enableGroupEdit: true,
        rowHeight: 30,
        suppressContextMenu: true,
        enableFillHandle: false, // can't drag selectboxes / clustername, can copy paste instead
        rowSelection: 'multiple',
        // https://www.ag-grid.com/javascript-grid/row-selection/#group-selection
        groupSelectsChildren: true,
        groupSelectsFiltered: true,
        autoGroupColumnDef: {
          groupUseEntireRow: true,
          headerName: 'Cluster',
          field: 'clusterName',
          minWidth: 250,
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: {
            checkbox: true,
            innerRenderer: 'iconTooltipRenderer',
            innerRendererParams: params => {
              const attributes = get(this.clusterAttributesMap, params.value, []);
              return {
                icon: 'info',
                hide: this.hideInfoIcon(params, attributes),
                messages: [this.$tkey('regionalBrandsTooltipMessage'), attributes.join(', ')],
              };
            },
          },
          columnStyle: {
            width: '100%',
          },
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          sort: 'asc',
          editable: () => !this.isEditingDisabled,
        },
        tooltipShowDelay: 0,
        defaultColDef: {
          filter: true,
          sortable: true,
          comparator: agGridUtils.sortings.naturalSort,
          editable: false,
          menuTabs: ['filterMenuTab'],
          minWidth: 100,
          flex: 1,
          resizable: true,
          suppressMovable: true, // don't move columns, only rows
          tooltipValueGetter: () => {
            return this.hasDataErrors ? this.$tkey('unclusteredError') : 0;
          },
          cellClass: 'aligned-start priority-cell',
          cellClassRules: {
            'invalid-nonempty': () => this.hasDataErrors,
          },
        },
        // hides blue border
        suppressCellFocus: true,
        suppressRowClickSelection: true,
      },
      components: null,
      modifiedClusters: new Set(),
    };
  },

  computed: {
    ...mapState('clustering', { selectedScenarioScheme: 'selectedScheme' }),
    ...mapState('referenceClustering', { selectedReferenceScheme: 'selectedScheme' }),
    ...mapGetters('scenarios', {
      scenarioStores: 'stores',
    }),
    ...mapGetters('toolData', {
      referenceStores: 'stores',
    }),
    ...mapGetters('context', ['getDateFormats', 'getClientConfig']),

    clusterAttributesMap() {
      return reduce(
        this.selectedScheme.clusters,
        (acc, c) => ({
          ...acc,
          ...(size(c.attributes) && { [c.clusterName]: c.attributes }),
        }),
        {}
      );
    },

    selectedScheme() {
      return this.mode === SCENARIO ? this.selectedScenarioScheme : this.selectedReferenceScheme;
    },

    stores() {
      return this.mode === SCENARIO ? this.scenarioStores : this.referenceStores;
    },

    setSelectedSchemeClusters() {
      return this.mode === SCENARIO
        ? this.setScenarioSelectedSchemeClusters
        : this.setReferenceSelectedSchemeClusters;
    },

    setSelectableClusterNames() {
      return this.mode === SCENARIO
        ? this.setScenarioSelectableClusterNames
        : this.setReferenceSelectableClusterNames;
    },

    updateClusters() {
      return this.mode === SCENARIO ? this.updateScenarioClusters : this.updateReferenceClusters;
    },

    hasDataChanges() {
      const dataChanged = !isEqual(this.$options.initialClusterData, this.clusterData);
      this.$emit('data-changed', dataChanged);
      return dataChanged;
    },

    hasDataErrors() {
      return this.uniqueClusterNames.size < 2;
    },

    hasSubClusteringEnabled() {
      return get(this.getClientConfig, 'features.subClusteringEnabled', false);
    },

    hasNoDataClustersInScheme() {
      return this.selectedScheme
        ? some(this.selectedScheme.clusters, ({ clusterName }) => this.isNoDataCluster(clusterName))
        : false;
    },

    uniqueClusterNames() {
      return this.clusterData ? new Set(this.clusterData.map(c => c.clusterName)) : new Set();
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditClusteringPage);
    },

    columnDefs() {
      const columns = [
        {
          field: 'storeWarningIcon',
          headerName: '',
          maxWidth: 30,
          sortable: false,
          editable: false,
          menuTabs: [],
          cellClass: 'justify-center',
          cellRenderer: 'agGridCellRendererComponentWrapper',
          valueGetter: params => ({
            component: 'error-triangle',
            props: {
              errors: this.getRowWarning(params),
              iconType: 'warning_amber',
              small: true,
              round: true,
            },
          }),
        },
        {
          headerName: this.$tkey('tableHeaders.clusterName'),
          field: 'clusterName',
          sort: 'asc',
          rowGroup: true,
          hide: true,
          editable: false, // AOV3-1128 TODO: edit groups but not children
        },
        {
          headerName: this.$tkey('tableHeaders.storeKeyDisplay'),
          field: 'storeKeyDisplay',
        },
        {
          headerName: this.$tkey('tableHeaders.formatDescription'),
          field: 'formatDescription',
        },
        {
          headerName: this.$tkey('tableHeaders.storeDescription'),
          field: 'storeDescription',
        },
        {
          headerName: this.$tkey('tableHeaders.storeProfile'),
          field: 'storeProfile',
        },
        {
          headerName: this.$tkey('tableHeaders.country'),
          field: 'country',
        },
        {
          headerName: this.$tkey('tableHeaders.province'),
          field: 'province',
        },
        {
          headerName: this.$tkey('tableHeaders.city'),
          field: 'city',
        },
        {
          headerName: this.$tkey('tableHeaders.openDate'),
          field: 'openDate',
          valueFormatter: this.dateFormatter,
          filterParams: {
            valueFormatter: this.dateFormatter,
          }, // format values in filter same as in column
        },
        {
          headerName: this.$tkey('tableHeaders.closeDate'),
          field: 'closeDate',
          valueFormatter: this.dateFormatter,
          filterParams: {
            valueFormatter: this.dateFormatter,
          },
        },
      ];
      if (this.mode === SCENARIO) {
        columns.push(
          ...[
            {
              headerName: this.$tkey('tableHeaders.analysis'),
              field: 'analysis',
            },
            {
              headerName: this.$tkey('tableHeaders.assortment'),
              field: 'assortment',
            },
          ]
        );
      }
      if (this.mode === SCENARIO && this.hasSubClusteringEnabled) {
        // Add split icon column
        columns.unshift({
          field: 'subClusterIcon',
          headerName: '',
          maxWidth: 30,
          sortable: false,
          editable: false,
          resizable: false,
          menuTabs: [],
          cellClass: 'justify-center clickable sub-clustering-icon',
          cellRenderer: params =>
            agGridUtils.utils.customIconRenderer(params, {
              icon: 'alt_route',
              isDisplayed: !!params.node.group,
            }),
          onCellClicked: params => this.$emit('open-cluster-generation-modal', params),
        });
      }

      const extraColumns = get(this.getClientConfig, 'storeColumns', {});

      return agGridUtils.builders.mergeHeaders(columns, extraColumns, {
        componentName: camelCase(this.$options.name),
      });
    },
  },

  async created() {
    this.storeKeyMap = keyBy(this.stores, 'storeKey');
    this.init();
    this.updateNoDataClusters();
  },

  beforeDestroy() {
    this.$emit('data-changed', false);
  },

  methods: {
    ...mapMutations('clustering', {
      setScenarioSelectableClusterNames: 'setSelectableClusterNames',
      setScenarioSelectedSchemeClusters: 'setSelectedSchemeClusters',
    }),
    ...mapMutations('referenceClustering', {
      setReferenceSelectableClusterNames: 'setSelectableClusterNames',
      setReferenceSelectedSchemeClusters: 'setSelectedSchemeClusters',
    }),
    ...mapActions('clustering', { updateScenarioClusters: 'updateClusters' }),
    ...mapActions('referenceClustering', {
      fetchClusterScheme: 'fetchClusterScheme',
      updateReferenceClusters: 'updateClusters',
    }),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.columnApi.setColumnVisible('storeWarningIcon', this.hasNoDataClustersInScheme);

      this.$emit('has-selected-rows', !isEmpty(this.gridApi.getSelectedRows()));
    },

    init() {
      if (this.mode === SCENARIO) this.setStoresWithoutSalesData();
      this.$options.initialClusterData = cloneDeep(this.getStoresGroupedByScheme());
      this.clusterData = cloneDeep(this.$options.initialClusterData);
    },
    getRowId(row) {
      const { data } = row;
      return data.storeKey;
    },

    onChange(params) {
      // refresh grouping when clusterName changes, handles new group name or moving to existing group with edit
      if (params.node.group) this.moveChildrenToGroup(params);

      this.refresh();
    },

    refresh() {
      // update row grouping
      this.gridApi.refreshClientSideRowModel();

      // may have removed all rows from group, essentially deleting it,
      // so we need to refresh selectable cluster names in the dropdown
      this.setSelectableClusterNames(this.uniqueClusterNames);
    },

    isNoDataCluster(clusterName) {
      return this.clusterWithoutSalesDataMap[clusterName];
    },

    updateNoDataClusters() {
      const storesByCluster = groupBy(this.clusterData, 'clusterName');
      this.clusterWithoutSalesDataMap = {}; // refresh data before every update
      if (this.mode === SCENARIO) {
        // check if all stores in a cluster are missing sales data
        each(storesByCluster, (stores, cluster) => {
          // AOV3-1490 workaround for stores that don't have hasPerformancePeriodSalesData flag:
          // If stores are not flagged and cluster is already mapped as no data, add it to this map
          // It is sufficient to check only first store for the presence of the flag.
          if (!has(stores[0], 'hasPerformancePeriodSalesData')) {
            const clusterInfo = find(this.selectedScheme.clusters, ({ clusterName }) =>
              isEqual(clusterName, cluster)
            );
            if (clusterInfo) this.clusterWithoutSalesDataMap[cluster] = clusterInfo.isNoDataCluster;
          } else {
            this.clusterWithoutSalesDataMap[cluster] = every(stores, s =>
              this.storeKeysWithoutSales.has(s.storeKey)
            );
          }
        });

        // update isNoDataCluster key for each table row
        each(this.clusterData, cd => {
          cd.isNoDataCluster = this.clusterWithoutSalesDataMap[cd.clusterName];
        });
      }
      // Keep clusters state up to date
      this.setSelectedSchemeClusters(this.formatClusterData());
    },

    getRowWarning(params) {
      if (params.node.group) {
        return {
          [this.$tkey('noDataClusterWarningMessage')]: !this.isNoDataCluster(params.node.key), // negate as error state is when value is false
        };
      }

      const { hasPerformancePeriodSalesData, assortment } = params.data;

      return {
        [this.$tkey('noSalesStoreWarningMessage')]:
          isUndefined(hasPerformancePeriodSalesData) || hasPerformancePeriodSalesData, // can be undefined for old WPs, AOV3-1435 had no migration
        [this.$tkey('notInAssortmentWarningMessage')]: assortment,
      };
    },

    onSelectionChanged() {
      this.$emit('has-selected-rows', !isEmpty(this.gridApi.getSelectedRows()));
    },

    formatClusterData() {
      const clusterObj = {};
      // group each store row from ag-grid by their cluster
      this.clusterData.forEach(
        ({
          clusterName,
          clusterId,
          attributes,
          storeKey,
          isNoDataCluster,
          usesUnclusteredMatrix,
        }) => {
          // Exclude attributes from cluster data if cluster has been modified
          const includeAttributes = attributes && !this.modifiedClusters.has(clusterName);
          // can't use clusterId, will be undefined for new clusters
          if (get(clusterObj, clusterName)) {
            clusterObj[clusterName].storeKeys.push(storeKey);
          } else {
            clusterObj[clusterName] = {
              clusterName,
              clusterId,
              ...(includeAttributes && { attributes }),
              isNoDataCluster,
              usesUnclusteredMatrix,
              storeKeys: [storeKey],
            };
          }
        }
      );
      const newClusters = toArray(clusterObj);
      const hasDifferentClustersCount =
        this.selectedScheme.clusterCount !== toArray(clusterObj).length;

      // if user manually generates new clusters, we reset its ids to ensure they are unique
      if (hasDifferentClustersCount) {
        newClusters.forEach(c => {
          c.clusterId = null;
        });
      }

      return newClusters;
    },

    getStoresGroupedByScheme() {
      const tableRows = reduce(
        this.selectedScheme.clusters,
        (acc, c) => {
          const { clusterName, clusterId, attributes, isNoDataCluster, usesUnclusteredMatrix } = c;
          // for each store from each cluster in scheme, create an entry which will represent a table row
          each(c.storeKeys, storeKey => {
            if (this.storeKeyMap[storeKey] !== undefined) {
              const store = pick(this.storeKeyMap[storeKey], [
                'storeKey', // internal use, not displayed
                'storeKeyDisplay',
                'formatDescription',
                'storeDescription',
                'storeProfile',
                'country',
                'province',
                'city',
                'openDate',
                'closeDate',
                'analysis',
                'assortment',
                'storeStatus',
                'hasPerformancePeriodSalesData',
                'distributionChannel',
              ]);

              acc.push({
                clusterName,
                clusterId,
                attributes,
                isNoDataCluster,
                usesUnclusteredMatrix,
                ...store,
              });
            }
          });
          return acc;
        },
        []
      );
      if (this.mode === REFERENCE) {
        tableRows.push(...this.setUnassignedStores());
      }
      return tableRows;
    },

    setStoresWithoutSalesData() {
      each(this.stores, s => {
        const hasMissingSalesData =
          has(s, 'hasPerformancePeriodSalesData') && !s.hasPerformancePeriodSalesData;
        if (!s.analysis || !s.assortment || hasMissingSalesData) {
          this.storeKeysWithoutSales.add(s.storeKey);
        }
      });
    },

    setUnassignedStores() {
      const cluster = {
        clusterName: 'Unassigned',
        clusterId: -1,
        isNoDataCluster: false,
        usesUnclusteredMatrix: false,
      };
      // get all the storeKeys currently assigned
      const assignedStoreKeys = [];
      this.selectedScheme.clusters.forEach(c => {
        assignedStoreKeys.push(...c.storeKeys);
      });

      const unAssignedStores = [];
      Object.entries(this.storeKeyMap).forEach(([key, storeValue]) => {
        const storeKey = Number(key);
        const assignedIndex = findIndex(assignedStoreKeys, a => {
          return a === storeKey;
        });
        if (assignedIndex < 0) {
          const store = pick(storeValue, [
            'storeKey', // internal use, not displayed
            'storeKeyDisplay',
            'formatDescription',
            'storeDescription',
            'storeProfile',
            'country',
            'province',
            'city',
            'openDate',
            'closeDate',
            'analysis',
            'assortment',
            'storeStatus',
            'hasPerformancePeriodSalesData',
            'distributionChannel',
          ]);

          unAssignedStores.push({
            ...cluster,
            ...store,
          });
        }
      });
      return unAssignedStores;
    },

    moveChildrenToGroup(params) {
      const groupClusterName = params.data.clusterName;
      const movingRows = params.node.allLeafChildren.map(c => c.data);
      this.moveRowsToGroup({ groupClusterName, movingRows });
    },

    moveRowsToGroup({ groupClusterName, movingRows }) {
      // used from clustering.vue, when value in v-select changes
      if (isEmpty(movingRows)) {
        movingRows = this.gridApi.getSelectedRows();
      }
      const modifiedClusters = reduce(
        movingRows,
        (acc, row) => {
          if (row.clusterName !== groupClusterName) acc.add(row.clusterName);
          return acc;
        },
        new Set()
      );
      if (modifiedClusters.size) {
        // Add group cluster name to modified clusters as stores are being removed
        this.modifiedClusters.add(groupClusterName);
        movingRows.forEach(data => {
          // check if user is moving to an existing (saved) cluster, if so, we grab that cluster id
          // when renaming the cluster name, we keep the cluster id, otherwise when a new one, we reset it
          const clusterToMoveStoreTo = find(this.selectedScheme.clusters, {
            clusterName: groupClusterName,
          });
          const isMovingToNewCluster =
            groupClusterName === this.$t('clusteringPage.newCluster') && !clusterToMoveStoreTo;
          // Track all modified clusters
          this.modifiedClusters.add(data.clusterName);
          data.clusterName = groupClusterName;
          data.clusterId = isMovingToNewCluster
            ? null
            : get(clusterToMoveStoreTo, 'clusterId', data.clusterId);
        });
        this.gridApi.applyTransaction({ update: movingRows });
      }
      this.gridApi.clearFocusedCell();
      this.updateNoDataClusters();
      this.refresh();
    },

    dateFormatter(params) {
      if (params.node.group) return;
      return this.$options.filters.formatDate(params.value, this.getDateFormats.long);
    },

    hideInfoIcon(params, attributes) {
      return (
        !this.hasRegionsByAttributeEnabled ||
        params.node.level ||
        !size(attributes) ||
        this.modifiedClusters.has(params.value)
      );
    },

    async discard() {
      if (this.mode === REFERENCE) {
        await this.fetchClusterScheme(); // reloads from database
      }
      if (this.mode === SCENARIO) this.setStoresWithoutSalesData();
      this.clusterData = cloneDeep(this.$options.initialClusterData);
      // Reset overall list of modified clusters as they have been returned to original state
      this.modifiedClusters = new Set();
      this.gridApi.refreshCells();
      this.refresh();
      this.updateNoDataClusters();
    },

    async onSave() {
      await this.$emit('on-save');
    },

    async onSaveRefresh() {
      await this.updateClusters(this.formatClusterData());
      this.gridApi.deselectAll();
      this.$options.initialClusterData = cloneDeep(this.clusterData);
      // hacky way to force 'hasDataChanges' computed to recalculate,
      // since it is not reacting to this.$options.initialClusterData change
      this.clusterData = cloneDeep(this.$options.initialClusterData);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .ag-row-group {
    background: $assortment-table-white-bg-colour;
  }

  .ag-row-selected,
  .ag-row-selected .ag-row-group {
    background: $assortment-table-blue-bg-colour !important;

    &:before {
      content: none;
    }
  }

  .sub-clustering-icon {
    i {
      transform: rotate(90deg) scale(90%);
      color: $assortment-sub-clustering-button-background-color;
    }
  }
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}
</style>
