import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VRow,
    { staticClass: "mt-10 mb-3" },
    [
      _c(
        VCol,
        { staticClass: "pa-0 pl-1", attrs: { cols: "12" } },
        [_c("scenarios-list", { attrs: { workpackage: _vm.workpackage } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }