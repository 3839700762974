import { isFunction } from 'lodash';
import { mapMutations } from 'vuex';

const mixin = {
  // eslint-disable-next-line no-shadow
  beforeRouteLeave(to, from, next) {
    if (!this.hasDataChanges) {
      this.setAllowTabChange(true);
      return next();
    }

    this.setAllowTabChange(false);
    this.openUnsavedDataModal();
    this.$refs.unsavedDataModal.$once('cancel', () => {
      next(false);
    });
    this.$refs.unsavedDataModal.$once('confirm', () => {
      if (isFunction(this.discard)) this.discard();
      this.setAllowTabChange(true);
      this.$root.$emit('unsaved-data-confirm');
      next();
    });
  },

  // rely on mixin data merging https://vuejs.org/v2/guide/mixins.html#Option-Merging
  data() {
    return {
      isUnsavedDataModalOpen: false,
    };
  },
  methods: {
    ...mapMutations('context', ['setAllowTabChange']),
    closeUnsavedDataModal() {
      this.isUnsavedDataModalOpen = false;
    },

    openUnsavedDataModal() {
      this.isUnsavedDataModalOpen = true;
    },

    beforeCloseModalWithUnsavedData(propName) {
      if (!this.hasDataChanges) {
        this.$set(this, propName, false);
        return false;
      }

      this.openUnsavedDataModal();
      this.$refs.unsavedDataModal.$once('cancel', () => {
        this.$set(this, propName, true);
      });
      this.$refs.unsavedDataModal.$once('confirm', () => {
        if (isFunction(this.discard)) this.discard();
        this.$set(this, propName, false);
      });
    },

    beforeNavWithUnsavedData(navCallback) {
      if (!this.hasDataChanges) {
        this.setAllowTabChange(true);
        return navCallback();
      }

      this.setAllowTabChange(false);
      this.openUnsavedDataModal();
      this.$refs.unsavedDataModal.$once('confirm', () => {
        this.setAllowTabChange(true);
        this.$root.$emit('unsaved-data-confirm');
        return navCallback();
      });
    },
  },
};

export default mixin;
