'use strict';

const switchingModellingTypes = {
  setToUnclustered: 'setToUnclustered',
  simulate: 'simulate',
  unclustered: 'Unclustered',
};

module.exports = {
  switchingModellingTypes,
};
