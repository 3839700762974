<template>
  <v-container ma-0 pa-0>
    <v-row>
      <v-col cols="2" class="spacebreak-sidebar" />
      <v-col v-for="cdt in scenarioCdts" :key="cdt.cannGroupId" class="pa-0 ma-0 header">
        <v-row class="cann-group border-bottom pa-0">
          <v-col>
            <v-icon size="14" class="draggable-cross">$cross-move</v-icon>
            <v-icon size="14" class="box-icon pull-right">indeterminate_check_box</v-icon>
            <h2>{{ getCannGroupName(cdt.cannGroupId) }}</h2>
            <span v-if="showNotImplemented">{{
              $t('assortmentCanvasesPanel.percentOfSpace', ['x'])
            }}</span>
          </v-col>
        </v-row>
        <cdt-header-node :cdts="cdt.cdt" only-first-level="true" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';

import { StoreNamespaces } from '../../store/constants';
import cannGroupUtils from '../../utils/cann-groups';

export default {
  localizationKey: 'assortmentCanvasPage.tileViewPage',

  data() {
    return {
      canvasId: this.$route.params.canvasId,
      storeClassName: this.$route.params.storeClassName,
      cannGroups: [],
      cdtLeafs: [],
    };
  },

  computed: {
    ...mapState(StoreNamespaces.scenarios, ['selectedScenario']),
    ...mapState(StoreNamespaces.assortmentCanvas, ['assortmentCanvasProducts']),
    ...mapState(StoreNamespaces.furniture, ['scenarioFurniture']),
    ...mapState(StoreNamespaces.scenarioCdts, ['scenarioCdts']),
    ...mapGetters('context', ['showNotImplemented']),
  },
  async created() {
    this.cannGroups = cannGroupUtils.getLeafNodes(this.selectedScenario.cannGroups);
    await this.fetchScenarioCdts();
  },
  methods: {
    ...mapActions(StoreNamespaces.scenarioCdts, ['fetchScenarioCdts']),
    getCannGroupName(id) {
      const matchingCannGroup = this.cannGroups.find(cg => cg.key === id);
      return get(matchingCannGroup, 'name', '');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.header {
  text-align: center;
  border: $assortment-tile-border-colour 1px solid;
  font-size: 1rem;
  .draggable-cross {
    float: left;
    font-size: 2rem !important;
  }
  .box-icon {
    float: right;
    font-size: 2rem !important;
    color: $assortment-action-icon-color !important;
  }
}

.border-bottom {
  border-bottom: $assortment-tile-border-colour 1px solid;
}

.border-right {
  border-right: $assortment-tile-border-colour 1px solid;
}

.cann-group {
  background-color: white;
  h2 {
    font-size: 1.5rem;
  }
}
</style>
