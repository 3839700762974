import { get } from 'lodash';
import { functions } from '@enums/asynchronous-functions';

const store = {
  namespaced: true,

  actions: {
    handleJobEventMessage({ dispatch }, { message }) {
      const { status, jobType } = message;

      //  See if a function exists for this type of message
      const actions = get(functions, [jobType, 'actions', status]);
      if (actions) {
        //  Dispatch the action
        if (Array.isArray(actions)) {
          actions.forEach(action => dispatch(action, { message }, { root: true }));
        } else {
          dispatch(actions, { message }, { root: true });
        }
      }
    },
  },
};

export default store;
