<template>
  <v-row v-if="show" no-gutters class="nav-bar">
    <!-- Workpackage name and back nav -->
    <v-col class="white workpackage d-flex justify-start">
      <v-btn color="primary btn-back" fab small dark tile depressed :to="{ name: backNavRoute }">
        <v-icon size="28">mdi-chevron-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pa-2 pl-4">{{ workpackage.name }}</v-toolbar-title>
    </v-col>

    <!-- Scenario drop down selector -->
    <v-col v-if="enableScenarioSelection" class="scenarioSelect d-flex align-center">
      <v-select
        :value="selectedScenario"
        flat
        :items="scenarios"
        :label="$t('toolbar.scenarioTitleID')"
        :menu-props="{ bottom: true, offsetY: true }"
        item-text="name"
        item-value="scenarioId"
        solo
        hide-details
        dense
        return-object
        @change="changeScenarios"
      >
        <template v-slot:append>
          <v-icon size="28">expand_more</v-icon>
        </template>
        <template v-slot:item="{ item, attrs, on }">
          <actionable-link
            v-bind="attrs"
            component="v-list-item"
            :to="createScenarioLink(item)"
            :on-click="scenarioAction"
            :v-on="on"
          >
            <v-list-item-content>
              <v-list-item-title>
                <span>{{ item.name }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </actionable-link>
        </template>
      </v-select>
    </v-col>

    <!-- navigation tabs + users -->
    <v-col class="tabs__container align-center" data-id-e2e="tabsRoute">
      <p v-if="tabsTitle" class="tabs__title ml-4 mr-3 my-0">{{ tabsTitle }}</p>

      <v-tabs
        show-arrows
        height="34"
        :value="currentTab"
        class="nav-bar-tabs"
        @change="onTabChange"
      >
        <v-tab
          v-for="tab in tabData"
          :key="tab.routeName"
          :disabled="isTabDisabled(tab)"
          :ripple="false"
          :to="createRouteForTab(tab)"
        >
          {{ $t(tab.translationKey) }}
          <v-icon v-if="showStatuses" class="status-tab-icon" size="18">${{ tab.status }}</v-icon>
        </v-tab>
      </v-tabs>

      <users-permissions v-if="showNotImplemented" hide-title justify-center class="panel-cell" />
    </v-col>

    <!-- Expansion -->
    <v-col v-if="enableNotes" class="d-flex justify-end expand-btn-container align-center">
      <notes
        :notes="getAllNotes"
        :loading="isNotesLoading"
        :title="$t('notes.types.scenario')"
        :max-chars="getClientConfig.notesSettings.maxLength"
        :notifications="!!selectedScenario.totalNotes"
        @post="postNewNote"
        @menuOpen="fetchAllNotes"
        @delete="deleteCurrentNote"
        @update="updateCurrentNote"
      />
      <v-icon size="35" class="icon" primary>expand_more</v-icon>
    </v-col>
  </v-row>
</template>

<script>
// Main toolbar used on the non-homepage pages.
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { get, isFunction, has } from 'lodash';

import sectionStatuses from '@enums/section-status';
import contextEnum from '@enums/note-contexts';

import { notesMixin } from '../mixins/notes';

import tabUtils from '../utils/tabs/tabs-utils';

export default {
  name: 'Toolbar',
  mixins: [notesMixin(contextEnum.scenario)],
  props: {
    backNavRoute: {
      type: String,
      required: true,
    },

    enableScenarioSelection: {
      type: Boolean,
      required: false,
      default: false,
    },

    enableNotes: {
      type: Boolean,
      required: false,
      default: false,
    },

    tabsTitle: {
      type: String,
      required: false,
      default: '',
    },

    toolbarTabs: {
      type: Object,
      required: true,
    },

    workpackage: {
      type: Object,
      required: true,
    },

    showStatuses: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      currentTab: null,
      previousTab: null,
      clickedTab: null,
      isNoteLoading: false,
    };
  },
  computed: {
    ...mapState('assortmentCanvas', [
      'selectedAssortmentCanvas',
      'assortmentCanvases',
      'fullScreenExpanded',
    ]),
    ...mapState('context', ['allowTabChange']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('workpackages', ['selectedWorkpackage']),

    ...mapGetters('assortmentCanvas', ['workpackageAssortmentCanvasesScenarioMap']),
    ...mapGetters('scenarios', ['selectedScenarioStatus', 'scenarios']),
    ...mapGetters('context', ['showNotImplemented', 'getDateFormats', 'getClientConfig']),
    ...mapGetters('workpackages', ['selectedWorkpackageStatus']),

    getNotesParam() {
      return {
        id: this.$route.params.scenarioId,
        context: contextEnum.scenario,
        workpackageId: this.workpackage._id,
      };
    },

    getScenarioStatus() {
      return this.selectedScenarioStatus(this.selectedWorkpackage.type);
    },

    tabData() {
      const tabData = tabUtils.getByType(this.toolbarTabs.ALL, this.selectedWorkpackage.type);
      // Update tab data with status
      tabData.forEach(tab => {
        const statusSection =
          get(this.getScenarioStatus, tab.statusSection) ||
          get(this.selectedWorkpackageStatus, tab.statusSection);

        if (statusSection) {
          // Disable the assortment panel when there are no canvases till modelling page is completed
          if (
            tab.id === this.toolbarTabs.ASSORTMENTS_PANEL_TAB &&
            statusSection.status === sectionStatuses.notStarted &&
            get(this.getScenarioStatus, 'modelling.productModelling.status') !==
              sectionStatuses.complete
          ) {
            tab.deactivated = true;
          } else {
            tab.deactivated = false;
          }
          tab.status = statusSection.status;
          tab.available = statusSection.available;
        } else {
          tab.status = sectionStatuses.notStarted;
          tab.available = false;
        }
      });
      return tabData;
    },

    selectedTab() {
      return this.toolbarTabs.ALL.find(tab => tab && this.$route.path.includes(tab.id));
    },

    selectedTabPosition() {
      return this.selectedTab ? this.selectedTab.position : 0;
    },

    availableCanvasId() {
      if (!this.assortmentCanvases.length) return null;
      if (
        this.selectedAssortmentCanvas._id &&
        this.selectedAssortmentCanvas.scenarioId === this.$route.params.scenarioId
      )
        return this.selectedAssortmentCanvas._id;
      return this.assortmentCanvases[0]._id;
    },

    show() {
      return !(
        this.selectedAssortmentCanvas._id &&
        this.selectedAssortmentCanvas.scenarioId === this.$route.params.scenarioId &&
        this.fullScreenExpanded
      );
    },

    isAssortmentRoute() {
      return ['assortments-panel', 'assortments-canvas'].includes(this.$route.name);
    },
  },

  created() {
    if (this.enableScenarioSelection) this.getWpScenarios(this.workpackage._id);
    if (!this.selectedTab) return;

    let tabRoute;
    if (isFunction(this.selectedTab.firstChild)) {
      tabRoute = this.selectedTab.firstChild(this.getScenarioStatus, this.getClientConfig);
    } else {
      tabRoute = this.selectedTab.firstChild || this.selectedTab.routeName;
    }
    this.currentTab = this.$router.resolve({
      name: tabRoute,
      params: this.$route.params,
    }).href;
    this.previousTab = this.currentTab;
    const toolbar = this;
    this.$root.$on('unsaved-data-confirm', () => {
      toolbar.onTabChange(toolbar.clickedTab);
    });
  },

  methods: {
    ...mapActions('scenarios', ['fetchScenarios']),
    ...mapActions('assortmentCanvas', ['changeCanvasRenderingStatus']),
    ...mapMutations('assortmentCanvas', ['setIsProductSidebarOpen', 'setDashboardProduct']),

    async onTabChange(clickedTab) {
      this.clickedTab = clickedTab;
      this.currentTab = clickedTab;
      await this.$nextTick();
      if (!this.allowTabChange) this.currentTab = this.previousTab;
      else this.previousTab = this.currentTab;
    },

    async getWpScenarios(workpackageId) {
      await this.fetchScenarios({
        params: {
          where: {
            workpackageId,
          },
        },
      });
    },

    createScenarioLink(scenario) {
      // Return nothing if scenario is same as current scenario to avoid loading issues on assortment panel
      if (this.selectedScenario._id === scenario._id) return {};
      if (this.isAssortmentRoute) {
        return {
          name: 'assortments-panel',
          params: { scenarioId: scenario._id },
        };
      }
      return {
        name: this.$route.name,
        params: { scenarioId: scenario._id },
      };
    },

    scenarioAction() {
      if (this.isAssortmentRoute) {
        this.changeCanvasRenderingStatus(true);
      }
    },

    createRouteForTab(tab) {
      const { params } = this.$route;

      if (this.toolbarTabs.TEMPLATE_INPUTS_TAB === tab.routeName) {
        // Cannot create template inputs route if scenario does not exist
        if (!this.selectedScenario._id) return;
        // Otherwise, ensure templateScenarioId param is set when navigating to template inputs tab
        if (
          !has(params, 'templateScenarioId') ||
          params.templateScenarioId !== this.selectedScenario._id
        ) {
          params.templateScenarioId = this.selectedScenario._id;
        }
      }

      let tabRoute;
      if (isFunction(tab.firstChild)) {
        tabRoute = tab.firstChild(this.getScenarioStatus, this.getClientConfig);
      } else {
        tabRoute = tab.firstChild;
      }
      return { name: tabRoute || tab.routeName, params };
    },

    isTabDisabled(tab) {
      return !tab.available || !!tab.disabled || !!tab.deactivated;
    },

    changeScenarios() {
      this.setIsProductSidebarOpen(false);
      this.setDashboardProduct(null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.nav-bar {
  border-top: 1px solid $assortment-primary-colour;
  border-bottom: 1px solid $assortment-navbar-sub-divider-colour;
  background-color: $assortment-navbar-colour;
  margin-bottom: 20px;
  height: 36px;
  box-sizing: border-box;
  display: flex;
  flex: 0;

  .workpackage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: 0 0 24%;
    max-width: 24%;
  }

  .row {
    height: 100%;

    .v-btn {
      height: 100%;
      width: 28px;
    }
  }

  .v-toolbar__title {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .tabs {
    &__container {
      display: flex;
      flex: 0 0 39%;
      height: 100%;
      max-width: 39%;
    }

    &__title {
      color: $assortment-navigation-tab-text-colour;
      font-size: 1.2rem;
      font-weight: bold;
      white-space: nowrap;
    }
  }

  .v-tab {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    font-family: $assortment-regular-font;
    color: $assortment-navigation-tab-text-colour;
    padding: 0 7px 0 9px;
    align-items: center;
    padding-bottom: 1px;
    min-width: 15px;

    &--active {
      background-color: $assortment-navbar-colour !important;
    }

    &::before {
      opacity: 0 !important;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      border-right: 2px solid $assortment-navbar-main-divider-colour;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }

    &:nth-child(2) {
      &::after {
        bottom: 5px;
        height: 24px;
      }

      &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 5px;
        width: 1px;
        top: auto !important;
        height: 24px;
        border-right: 2px solid $assortment-navbar-main-divider-colour;
        opacity: 1 !important;
        display: block;
        visibility: visible !important;
      }
    }

    &:nth-child(3),
    &:nth-child(4) {
      &::after {
        bottom: 7px;
        height: 20px;
        border-right: 1px solid $assortment-navbar-sub-divider-colour;
      }
    }

    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      &::after {
        bottom: 5px;
        top: auto !important;
        height: 24px;
      }
    }
  }

  ::v-deep {
    .v-item-group {
      background-color: $assortment-navbar-colour;
    }

    .v-input {
      border-radius: 0;
    }

    .v-input__slot {
      height: 25px;
      border-bottom: 1px solid $assortment-primary-colour;
    }

    .v-text-field {
      .v-input__control {
        min-height: auto;
        border-radius: 0;
      }
    }
    .v-label {
      position: relative !important;
    }

    .v-ripple_container {
      display: none;
    }
  }

  .nav-bar-tabs {
    margin-right: 9px;
    background: $assortment-navbar-colour;

    .v-icon {
      margin-left: 1px;
    }

    ::v-deep {
      .v-slide-group__next,
      .v-slide-group__prev {
        .v-icon {
          color: $assortment-primary-colour;
        }
      }
    }
  }

  .icon {
    color: $assortment-primary-colour;
    cursor: pointer;
  }

  .expand-btn-container {
    flex: 0 0 16%;
    max-width: 16%;
    ::v-deep {
      button {
        margin-right: 1.2rem;
      }
    }

    .v-icon {
      position: relative;
      margin-right: 2px;
      &::before {
        content: '';
        position: absolute;
        left: -2px;
        bottom: 4px;
        width: 1px;
        height: 27px;
        border-right: 1px solid $assortment-navbar-main-divider-colour;
      }
    }
  }
}
.scenarioSelect {
  padding: 0 12px !important;
  flex: 0 0 21%;
  max-width: 21%;

  ::v-deep {
    .v-input__slot {
      padding-right: 0px !important;
      padding-left: 5px !important;
    }

    .v-select__selection.v-select__selection--comma {
      font-size: 1.2rem !important;
    }
  }
}
.v-tab {
  text-transform: none;
}

.btn-back {
  height: 100%;
  width: 28px;
}

.status-tab-icon {
  margin-left: 4px !important;
}
</style>
