import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VBtn,
    {
      staticClass: "icon-button",
      attrs: { disabled: !_vm.available, to: _vm.tabRoute, replace: "" },
    },
    [
      _vm._v("\n  " + _vm._s(_vm.text) + "\n  "),
      _c(VIcon, [_vm._v("keyboard_arrow_right")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }