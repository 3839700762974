import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout-main" },
    [
      _c(
        "button",
        {
          staticClass: "layout-button",
          attrs: { "data-id-e2e": "layoutExpandButton" },
          on: {
            click: function ($event) {
              return _vm.expandAll()
            },
          },
        },
        [
          _c(
            VIcon,
            { staticClass: "material-icons-outlined ml-1 layout-icon" },
            [_vm._v("open_in_full")]
          ),
          _vm._v("\n    " + _vm._s(_vm.$tkey("expandAll")) + "\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "layout-button",
          attrs: { "data-id-e2e": "layoutCollapseButton" },
          on: {
            click: function ($event) {
              return _vm.collapseAll()
            },
          },
        },
        [
          _c(
            VIcon,
            { staticClass: "material-icons-outlined ml-1 layout-icon" },
            [_vm._v("close_fullscreen")]
          ),
          _vm._v("\n    " + _vm._s(_vm.$tkey("collapseAll")) + "\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ag-grid-box fill-height grid-size",
          attrs: { "data-id-e2e": "layoutGrid" },
        },
        [
          _c("layout-options", {
            attrs: { "layout-name": _vm.selectedLayoutName },
            on: { save: _vm.saveLayout },
          }),
          _vm._v(" "),
          _c("ag-grid-vue", {
            staticClass: "ag-theme-custom ag-theme-custom--attributes",
            staticStyle: { width: "100%", height: "100%" },
            attrs: {
              "column-defs": _vm.columnDefs,
              "row-data": _vm.layoutData,
              "group-display-type": _vm.groupDisplayType,
              "group-default-expanded": -1,
              "grid-options": _vm.gridOptions,
            },
            on: { "grid-ready": _vm.onGridReady },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "deleteLayoutModal",
        attrs: { title: _vm.$tkey("deleteLayoutModalTitle"), width: 416 },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  VRow,
                  { staticClass: "main-dialog__content" },
                  [
                    _c(VCol, { staticClass: "message" }, [
                      _c("div", [_vm._v(_vm._s(_vm.deleteLayoutModalMessage))]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.$tkey("deleteLayoutModalSubMessage"))
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function ({ cancel: close, confirm }) {
              return [
                _c(
                  VRow,
                  [
                    _c(
                      VCol,
                      { staticClass: "d-flex justify-end pa-0" },
                      [
                        _c(
                          VBtn,
                          {
                            staticClass: "outlined-btn",
                            attrs: { secondary: "", depressed: "" },
                            on: { click: close },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.cancel")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            staticClass: "ml-4",
                            attrs: { primary: "" },
                            on: { click: confirm },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.delete")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }