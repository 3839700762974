<template>
  <div :key="`relativeshare-${isSidebarShown}`" class="h-100 d-flex flex-column">
    <reporting-section :short-view="shortView">
      <template v-slot:header>
        <reporting-header :short-view="shortView" :section="section">
          <template v-slot:metric>
            <rtls-select
              :value="metricValue"
              :items="enabledMetrics"
              :placeholder="$t('general.select')"
              :disabled="isRelativeShareReportDisabled"
              item-text="text"
              item-value="type"
              white
              width="240px"
              class="mr-3"
              @input="value => onSelectionChanged('metricValue', value)"
            />
          </template>
          <template v-slot:attribute>
            <rtls-select
              :value="attributeValue"
              :items="getAttributesIntersection(baselineIds, comparisonIds, comparison2Ids)"
              :placeholder="$t('general.select')"
              :disabled="isRelativeShareReportDisabled"
              item-text="name"
              item-value="id"
              white
              width="240px"
              @input="value => onSelectionChanged('attributeValue', value)"
            />
          </template>
          <template v-slot:filter>
            <filters
              v-if="!shortView"
              :filters="filters[section]"
              :filter-options="filterOptions"
              :btn-text="filterButtonText"
              :disabled="isRelativeShareReportDisabled || filtersDisabled"
              class="ml-3"
              @change="handleFilterChange"
              @apply="fetchData"
            />
          </template>
        </reporting-header>
      </template>
      <template v-slot:main-section>
        <reporting-main-section :section="section" :short-view="shortView">
          <template v-slot:data-section>
            <progress-bar
              v-if="!hasAvailableChartData && isChartLoading && baselineIds.length"
              :message="loadingMessage"
              style="margin: auto"
              class="pt-5"
            />
            <div v-if="isRelativeShareReportDisabled" class="ma-auto no-data">
              {{ $t('reportingPage.noPermissions') }}
            </div>
            <div v-if="!baselineIds.length" class="ma-auto no-data">
              {{ $tkey('messages.noData') }}
            </div>
            <div v-else-if="isAnySelectionObserved" class="ma-auto no-data">
              {{ $t('reportingPage.notAvailableForComparisons') }}
            </div>
            <div v-else-if="noFilteredDataAvailable" class="ma-auto no-data">
              {{ $tkey('messages.noFilteredDataAvailable') }}
            </div>
            <div v-else-if="noDataAvailable" class="ma-auto no-data">
              {{ $tkey('messages.noDataAvailable') }}
            </div>
            <highcharts
              v-if="
                !isRelativeShareReportDisabled && hasAvailableChartData && !isAnySelectionObserved
              "
              :key="highchartKey"
              class="chart"
              :options="chartOptions"
            />
          </template>
        </reporting-main-section>
      </template>
      <template v-slot:footer>
        <reporting-footer v-if="showNotImplemented" :short-view="shortView" is-export-disabled />
      </template>
    </reporting-section>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { size, forEach, keys, has, sortBy, trim, get, isEqual } from 'lodash';
import reportingSections from '@enums/reporting-sections';
import reportingFilters from '@enums/reporting-filters';

import Highcharts from 'highcharts';
import stockInit from 'highcharts/modules/stock';
import spacebreakUtils from '@/js/utils/spacebreak-utils';

stockInit(Highcharts);

const colourMap = {};
let colorNextIndex = 0;

// max categories before showign a scroll bar
const maxShortViewCategories = 10;
const maxDetailedViewCategories = 18;

export default {
  localizationKey: 'reportingPage.sections.relative-share',

  props: {
    // represents whether this report is open on (short) overview page
    shortView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      reportingSections,
      chartData: [],
      section: reportingSections.relativeShare,
    };
  },

  computed: {
    ...mapState('reporting', [
      'baselineIds',
      'comparisonIds',
      'comparison2Ids',
      'attributeValue',
      'metricValue',
      'filters',
      'loadingSubsection',
      'selectedWorkpackage',
      'isSidebarShown',
    ]),
    ...mapGetters('reporting', [
      'getCanvasName',
      'getAttributesIntersection',
      'enabledMetrics',
      'filterOptions',
      'isAnySelectionObserved',
    ]),
    ...mapGetters('context', ['showNotImplemented']),

    isRelativeShareReportDisabled() {
      return !this.hasPermission(this.userPermissions.canViewRelativeShareReport);
    },

    isChartLoading() {
      return this.loadingSubsection.relativeShareChart;
    },

    hasAvailableChartData() {
      return (
        this.hasValidChartSelection &&
        !this.isChartLoading &&
        this.getChartData.series.length &&
        this.getChartData.categories.length
      );
    },

    noFilteredDataAvailable() {
      return this.noDataAvailable && this.filters[this.section].length;
    },

    noDataAvailable() {
      return (
        !this.getChartData.series.length && this.hasValidChartSelection && !this.isChartLoading
      );
    },

    hasValidChartSelection() {
      return !!this.attributeValue && !!this.metricValue;
    },

    highchartKey() {
      return `${this.metricValue}-${this.attributeValue}`;
    },

    filtersDisabled() {
      return !this.hasAvailableChartData && this.isChartLoading;
    },

    filterButtonText() {
      return `${this.$tc(`reportingPage.filters`, this.filters[this.section].length)}`;
    },

    getChartData() {
      if (!size(this.chartData)) return { series: [], categories: [] };

      // sort collection to put baseline first, then comparison
      const sortedChartData = sortBy(this.chartData, ['checkpointType']);

      // initialize categories, series and overallData
      const categories = [];
      let combinedSeries = [];
      let overallDataShortView;

      const isComparisonSelected = this.chartData.length > 1;
      const isComparison2Selected = this.chartData.length > 2;
      const overviewYaxisLimit = 5;

      // iterate over the sorted chart data
      forEach(sortedChartData, collection => {
        let series = [];
        let chartDataSet = collection.chartData;
        // If you are in shortView mode, only show smallest, first quartile, median, third quartile and largest dataSets
        if (this.shortView) {
          // remove overall category from chartData array and set as a variable on canvas view
          overallDataShortView = collection.chartData.shift();

          // max items to show on shortview mode per collection is 5
          const dataCount = size(collection.chartData);
          if (dataCount > overviewYaxisLimit) {
            const dataIndexes = collection.chartData.map((x, i) => i);

            const medianIndex = this.quartile(dataIndexes, 0.5);
            const firstQuartileIndex = this.quartile(dataIndexes, 0.25);
            const thirdQuartileIndex = this.quartile(dataIndexes, 0.75);
            const smallestDataSet = chartDataSet[0];
            const firstQuartileDataSet = chartDataSet[firstQuartileIndex];
            const medianDataSet = chartDataSet[medianIndex];
            const thirdQuartileDataSet = chartDataSet[thirdQuartileIndex];
            const largestDataSet = chartDataSet[dataCount - 1];

            chartDataSet = [
              smallestDataSet,
              firstQuartileDataSet,
              medianDataSet,
              thirdQuartileDataSet,
              largestDataSet,
            ];
          }
        }

        forEach(chartDataSet, dataEntry => {
          /**
           * The data in the series should match the order of the categories added.
            Assuming that we have the following data:
            [
              { checkpointType: baseline, isOverall: true, data: { green: 1, yellow: 2, pink: 6 }}
              { checkpointType: baseline, data: { green: 1, yellow: 2, pink: 6 }}

              { checkpointType: cp1, isOverall: true, data: { green: 4, yellow: 1, pink: 14 }}
              { checkpointType: cp1, data: { green: 45, yellow: 34, pink: 67 }}
              { checkpointType: cp1, data: { green: 74, yellow: 23, pink: 98 }}

              { checkpointType: cp2, isOverall: true, data: { green: 5, yellow: 56, pink: 85 }}
              { checkpointType: cp2, data: { green: 5, yellow: 56, pink: 85 }}
            ]

            the final data series array has to be:
            [
              { name: green, data: [
                1,
                4 (overall cp1),
                5 (overall cp2),
                blankOrZero for baseline line break,
                1,
                blankOrZero for comparison line break,
                45,
                74,
                blankOrZero for comparison2 line break,
                5
              ]}
            ]
          */
          const dataEntryPercentages = get(dataEntry, 'metricData.percentages', {});
          const isBaselineAdded = categories.includes(this.$tkey('chart.overall-baseline'));
          // check if the current iteration dataEntry is the overall data or individual spacebreak data
          const isOverallData = dataEntry.overall;
          if (isOverallData && !isBaselineAdded && !this.shortView) {
            // if entry is overall data and the baseline has not been added then add the category to the front of the category array
            categories.unshift(this.$tkey('chart.overall-baseline'));
            // loop through the overall data object and add each key as a name in the series with the overall value
            forEach(dataEntryPercentages, (value, key) => {
              // data value added depends on if comparison selected also, if comparison selected add a 0 after the values to account for the c/p overall data set
              let data = [value];
              // we need to add zeroes for each comparison a user may add to build the correct array where each zero represents a comparison selected on the menu
              if (isComparisonSelected) {
                data = [value, 0];
              }
              if (isComparison2Selected) {
                data = [value, 0, 0];
              }
              // push the value name [key] into the series array with data value
              series.push({ name: collection.attributesValues[key], data });
            });
          } else if (isOverallData && isBaselineAdded && !this.shortView) {
            // this is the comparisons loops - it is called twice, once for comparison 1 and once for comparison 2
            // so by the type comparison 2 is called, there are more categories added than on comparison 1 was made aware of
            // if the baseline has already been added
            // find the amount of categories in the baseline and reduce it by 2 (overall baseline category + overall comparison 1 category)
            // -2 because we have cp2 selected, when we add cp3, it has to be -3
            const baseLineCategoriesLength =
              categories.length -
              (collection.checkpointType === reportingFilters.comparison ? 1 : 2);
            // create an array of 0's correlating to the amount of categories in the baseline
            const emptyDataArray = Array(baseLineCategoriesLength).fill(0);
            // add comparison overall data to second place in the categories array
            if (collection.checkpointType === reportingFilters.comparison) {
              categories.splice(1, 0, this.$tkey('chart.overall-comparison'));
            }
            // add comparison 2 overall data to third place in the categories array
            if (collection.checkpointType === reportingFilters.comparison2) {
              categories.splice(2, 0, this.$tkey('chart.overall-comparison2'));
            }
            forEach(dataEntryPercentages, (value, key) => {
              // add 0 before the value as the data before aligns to the overall b/l value so it needs to be 0 for all values
              // emptyDataArray aligns to amount of baselineCategories, adds 0s to end of series as that aligns to b/l data set
              let data = null;
              if (collection.checkpointType === reportingFilters.comparison) {
                // first zero for baseline, then value for c1
                data = [0, value, ...emptyDataArray];
                if (isComparison2Selected) {
                  data = [0, value, 0, ...emptyDataArray];
                }
              } else if (collection.checkpointType === reportingFilters.comparison2) {
                data = [0, 0, value, ...emptyDataArray]; // first zero for baseline, then zero for c1, then value for c2
              }
              series.push({
                name: collection.attributesValues[key],
                data,
              });
            });
          } else {
            // loop for the checkopint per se
            // if the entry is a normal entry (not overall entry) add it to the end of the array
            // loop over the attribute values and look in the series to find the value that matches and add the value to the data array
            // in shortview mode the overall data is shifted from the array earlier in this function, so we set it as a variable there
            const overallData = !this.shortView ? collection.chartData[0] : overallDataShortView;
            const percentages = get(overallData, 'metricData.percentages', {});
            // loop through the keys of the overallData object and check if each key is on the current dataValues object,
            // if it isnt then push a 0 into the series data array as it is an empty value
            keys(percentages).forEach(key => {
              const hasKey = has(dataEntryPercentages, key);
              const seriesValueName = collection.attributesValues[key];
              if (this.shortView) {
                // if in shortview mode add the series value if not found as we dont use overview data to push in all the potential values
                const found = series.find(seriesValue => seriesValue.name === seriesValueName);
                if (!found) {
                  const categoriesLength = categories.length;
                  // same principle as above adding 0s to data set where values do not apply
                  const emptyDataArray = Array(categoriesLength).fill(0);
                  // if the current collection being iterated over is a baseline collection then do not add zeros on to the data series
                  const data =
                    collection.checkpointType === reportingFilters.baseline
                      ? []
                      : [...emptyDataArray];
                  series.push({ name: seriesValueName, data });
                }
              }
              const item = series.find(seriesValue => seriesValue.name === seriesValueName);
              // if the current metricData value being looped over has a value then push that value into the data array, otherwise add a 0
              const dataToPush = hasKey ? dataEntryPercentages[key] : 0;
              item.data.push(dataToPush);
            });

            const name = this.shortView ? 'shortName' : 'name';

            // set y-axis category names based on if data is spacebreaks (canvas view) or checkpoints (scenario view)
            if (!dataEntry.name) {
              dataEntry.name = this.getCanvasName(dataEntry);
              if (dataEntry.name.length < 5) {
                dataEntry.shortName = dataEntry.name;
              } else {
                // truncate the canvas name and set as shortName
                const splitName = dataEntry.name.split(/-(.+)/);
                const truncated = `${trim(splitName[0]).slice(0, 3)}... `;
                dataEntry.shortName = truncated.concat(trim(splitName[1]));
              }
            }
            // set shortName to use spacebreakUtils shortName, if entry is a spacebreak and on overview mode
            if (dataEntry.spacebreakId && this.shortView) {
              const spacebreakShortName = spacebreakUtils.getSpacebreakShortName({
                spacebreak: { size: dataEntry.size },
                fillInSelection: this.selectedWorkpackage.fillInSelection,
              });
              dataEntry.shortName = spacebreakShortName;
            }
            categories.push(dataEntry[name]);
          }
        });
        if (collection.checkpointType === reportingFilters.baseline && isComparisonSelected) {
          // if the collection being iterated over is the baseline collection and there is a comparison also selected
          const comparisonSpacebreakCount =
            // minus 1 for overall category
            this.chartData.find(cp => cp.checkpointType === reportingFilters.comparison).chartData
              .length - 1;
          let comparison2SpacebreakCount = 0;
          if (isComparison2Selected && this.chartData) {
            comparison2SpacebreakCount =
              // minus 1 for overall category
              this.chartData.find(cp => cp.checkpointType === reportingFilters.comparison2)
                .chartData.length - 1;
          }
          let totalSpacebreakCount = comparisonSpacebreakCount + comparison2SpacebreakCount;
          if (this.shortView && totalSpacebreakCount > overviewYaxisLimit) {
            // the spacebreaks are limited to a set of 5 median values when over 5 on shortview
            totalSpacebreakCount = overviewYaxisLimit;
          }
          // map 0s onto the end of the data set to account for the comparison categories where values for baseline data are 0
          const emptyArray = Array(totalSpacebreakCount).fill(0);
          series = series.map(item => {
            item.data = [...item.data, ...emptyArray];
            return item;
          });
        }

        // Sort series (to ensure consistant ordering between base and comparison)
        series = sortBy(series, 'name');

        // Set colours and combine series
        series = this.setColorsForSeries(series);
        combinedSeries = [...combinedSeries, ...series];
      });

      // Add spacing between overalls and comparisons
      if (!this.shortView) {
        // Get positions etc for the categories.
        // Each category represents a row in the final chart, so to add better visibility to the report, we break the chart contents by the type
        // of the contents being shown, eg to clearly separate the baseline checkpoints, we hadd a "Baseline:" blank row, to clearly  separate the comparison checkpoints, we add a "Comparison:" blank row.
        // Because of this blank row, we have to move all data points by one position. So that the contents shows aligned on the final report.
        let overallSeparatorPosition = 1;
        let baselineSeparatorPosition = null;
        let baseLineSepratorLabel = '';
        let comparisonSeparatorPosition = null;
        const baselineLength = sortedChartData[0].chartData.length - 1;

        if (isComparisonSelected) {
          const comparisonLength = sortedChartData[1].chartData.length - 1;
          const overallSeparatorPositionPadding = isComparison2Selected ? 3 : 2;
          overallSeparatorPosition = overallSeparatorPositionPadding;
          baselineSeparatorPosition = overallSeparatorPosition + baselineLength + 1;
          baseLineSepratorLabel = this.$tkey('chart.baseline');
          if (isComparison2Selected) {
            comparisonSeparatorPosition = baselineSeparatorPosition + comparisonLength + 1;
          }
        }

        // Add spacing
        categories.splice(overallSeparatorPosition, 0, baseLineSepratorLabel);
        combinedSeries.forEach(seriesItem => {
          seriesItem.data.splice(overallSeparatorPosition, 0, 0);
        });

        if (isComparisonSelected) {
          categories.splice(baselineSeparatorPosition, 0, this.$tkey('chart.comparison'));
          // ensures datapoints are in the correct position, eg pushed by X amount of the baseline
          combinedSeries.forEach(seriesItem => {
            seriesItem.data.splice(baselineSeparatorPosition, 0, 0);
          });
          if (isComparison2Selected) {
            // ensures datapoints for the cp2 are in the correct position, eg pushing by X amount of data points from baseline + comparison 1
            categories.splice(comparisonSeparatorPosition, 0, this.$tkey('chart.comparison2'));
            combinedSeries.forEach(seriesItem => {
              seriesItem.data.splice(comparisonSeparatorPosition, 0, 0);
            });
          }
        }
      }

      // Remove dupe series labels
      const seriesLabels = [];
      combinedSeries.forEach(seriesItem => {
        if (seriesLabels.includes(seriesItem.name)) {
          seriesItem.showInLegend = false;
        } else {
          seriesLabels.push(seriesItem.name);
        }
      });
      return { series: combinedSeries, categories };
    },

    chartOptions() {
      const fn = number => this.formatNumber({ number, format: 'percent' });
      const truncate = value => this.truncateText(value);
      const maxCategories = this.shortView ? maxShortViewCategories : maxDetailedViewCategories;
      const needsScrollbar = this.getChartData.categories.length > maxCategories;
      return {
        chart: {
          type: 'bar',
        },
        title: {
          text: null,
        },
        tooltip: {
          formatter() {
            return `<span style="font-size: 1rem">${truncate(this.x)}</span><br/><b>${
              this.series.name
            }</b>: ${fn(this.y)}%`;
          },
        },
        yAxis: {
          allowDecimals: false,
          title: {
            text: this.$tkey('chart.yTitle'),
          },
        },
        xAxis: {
          categories: this.getChartData.categories,
          // needs the highstock library imported
          scrollbar: {
            enabled: needsScrollbar,
          },
          min: 0,
          max: this.getMaxChartValue,
          tickLength: 0,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            stacking: 'percent',
            pointWidth: 15,
            groupPadding: 0,
            pointPadding: 0,
            borderWidth: 0,
            // Disable clicking legend
            events: {
              legendItemClick() {
                return false;
              },
            },
          },
        },
        legend: {
          reversed: true,
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
          x: 0,
          y: 100,
          enabled: !this.shortView,
        },
        series: this.getChartData.series,
      };
    },
    loadingMessage() {
      return this.$tkey('messages.loadingChartData');
    },

    getMaxChartValue() {
      const categoriesLength = this.getChartData.categories.length - 1;
      if (!categoriesLength) return 0;
      // max amount of y-axis categories to display before introducing a scrollbar depends on shortview or detailed view
      const maxCategories = this.shortView ? maxShortViewCategories : maxDetailedViewCategories;
      return Math.min(categoriesLength, maxCategories);
    },
  },

  watch: {
    baselineIds(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchData();
    },

    comparisonIds(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchData();
    },

    comparison2Ids(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchData();
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    ...mapActions('reporting', ['fetchReportingData']),
    ...mapMutations('reporting', ['setSelection', 'setReportingFilters']),

    truncateText(value) {
      // truncate tooltip names if values longer than 100 characters
      if (value.length < 100) return value;
      return `${trim(value).slice(0, 100)}... `;
    },

    setColorsForSeries(series) {
      const colorArray = [
        '#080AAE',
        '#0A9DEC',
        '#A7E0FF',
        '#7E5D00',
        '#FFBE00',
        '#FCDA8E',
        '#000000',
        '#949494',
        '#CFCFCF',
        '#7D0204',
        '#E34043',
        '#FCA59C',
        '#5F34A4',
        '#8A75FF',
        '#C7BEFA',
        '#A34400',
        '#FF8C00',
        '#FCCD9C',
        '#005558',
        '#20C9A2',
        '#A4E2D2',
      ];

      // Map colours onto values, using a saved colour if that value name is known
      return series.map(value => {
        if (!(value.name in colourMap)) {
          colourMap[value.name] = colorArray[colorNextIndex];
          colorNextIndex += 1;
          if (colorNextIndex >= colorArray.length) colorNextIndex = 0;
        }
        value.color = colourMap[value.name];

        return value;
      });
    },

    async fetchData() {
      this.chartData = [];
      if (!this.hasValidChartSelection) return;
      const chartData = await this.fetchReportingData({
        section: this.section,
        reportArea: 'chart',
        productFilters: this.filters[this.section],
      });
      this.chartData = chartData;
    },

    async onSelectionChanged(field, value) {
      await this.setSelection({ field, value });
      await this.fetchData();
      this.navigateToNewUrl();
    },

    // include new query to url param
    navigateToNewUrl() {
      const newQueryValues = {
        ...this.$route.query,
        metricValue: this.metricValue,
        attributeValue: this.attributeValue,
      };

      this.$router.push({
        path: this.$route.path, // keep on same path
        query: newQueryValues,
      });
    },

    quartile(data, q) {
      const pos = Math.round((data.length - 1) * q);
      const base = pos;
      const rest = pos - base;
      if (data[base + 1] !== undefined) {
        return data[base] + rest * (data[base + 1] - data[base]);
      }
      return data[base];
    },

    handleFilterChange(filters) {
      this.setReportingFilters({ section: this.section, filters });
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  height: 100%;
  position: absolute;
  flex: 1;
}

.no-data {
  font-size: 1.4rem;
}
</style>
