import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { attrs: { "ma-0": "", "pa-0": "" } },
    [
      _c(
        VRow,
        [
          _c(VCol, {
            staticClass: "spacebreak-sidebar",
            attrs: { cols: "2" },
          }),
          _vm._v(" "),
          _vm._l(_vm.scenarioCdts, function (cdt) {
            return _c(
              VCol,
              { key: cdt.cannGroupId, staticClass: "pa-0 ma-0 header" },
              [
                _c(
                  VRow,
                  { staticClass: "cann-group border-bottom pa-0" },
                  [
                    _c(
                      VCol,
                      [
                        _c(
                          VIcon,
                          {
                            staticClass: "draggable-cross",
                            attrs: { size: "14" },
                          },
                          [_vm._v("$cross-move")]
                        ),
                        _vm._v(" "),
                        _c(
                          VIcon,
                          {
                            staticClass: "box-icon pull-right",
                            attrs: { size: "14" },
                          },
                          [_vm._v("indeterminate_check_box")]
                        ),
                        _vm._v(" "),
                        _c("h2", [
                          _vm._v(_vm._s(_vm.getCannGroupName(cdt.cannGroupId))),
                        ]),
                        _vm._v(" "),
                        _vm.showNotImplemented
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "assortmentCanvasesPanel.percentOfSpace",
                                    ["x"]
                                  )
                                )
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("cdt-header-node", {
                  attrs: { cdts: cdt.cdt, "only-first-level": "true" },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }