import i18n from '@/js/vue-i18n';
import { isPlainObject, sortBy, get, size } from 'lodash';
import axios from 'axios';
import to from 'await-to-js';

export const name = 'workpackageProducts';

const store = {
  namespaced: true,
  state: {
    workpackageProducts: [],
    missingWorkpackageProducts: [],
    loading: false,
  },

  getters: {
    workpackageProducts(state) {
      return state.workpackageProducts;
    },
    missingProducts(state) {
      return sortBy(state.missingWorkpackageProducts, 'productKey');
    },
  },

  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setWorkpackageProducts(state, workpackageProducts) {
      state.workpackageProducts = workpackageProducts;
    },
    setMissingWorkpackageProducts(state, missingProducts) {
      state.missingWorkpackageProducts = missingProducts;
    },
  },

  actions: {
    async fetchWorkpackageProducts({ commit }, { workpackageId, params = {}, options = {} }) {
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/workpackages/${workpackageId}/products`, params)
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      if (options.isStatelessFetch) return response.data;
      commit('setWorkpackageProducts', response.data);
      commit('setMissingWorkpackageProducts', []);
    },

    async createWorkpackageProducts({ commit, dispatch }, { workpackageId, products, upsert }) {
      commit('setLoading', true);
      if (isPlainObject(products)) {
        products = [products];
      }
      const payload = products.map(o => ({ workpackageId, ...o }));
      const method = upsert ? 'put' : 'post';

      const [err, result] = await to(
        axios[method](`/api/workpackages/${workpackageId}/products`, payload)
      );
      commit('setLoading', false);
      if (err) {
        const message = get(err, 'response.data.message', err.message);
        throw new Error(message);
      }
      if (result.data && result.data.errors) {
        throw new Error(`Unable to create workpackage product: ${workpackageId}`);
      }
      commit('setWorkpackageProducts', payload);
      dispatch('workpackages/refreshWorkpackageInformation', { workpackageId }, { root: true });
      return result;
    },

    async saveWorkpackageProductSelection(
      { commit },
      {
        workpackageId,
        productUpdates,
        productKeysToAdd,
        productKeysToDelete,
        categoryKeysToAdd,
        categoryKeysToDelete,
      }
    ) {
      // add new products to the workpackage products from tooldata-products
      commit('setLoading', true);
      const [err, result] = await to(
        axios.post(`/api/workpackages/${workpackageId}/products-selection`, {
          data: {
            productUpdates,
            productKeysToAdd,
            productKeysToDelete,
            categoryKeysToAdd,
            categoryKeysToDelete,
          },
        })
      );
      commit('setLoading', false);
      if (err) {
        const message =
          (err && err.response && err.response.data && err.response.data.message) || err.message;
        throw new Error(message);
      }
      if (result.data && result.data.errors) {
        throw new Error(`Unable to update products of workpackage (${workpackageId})`);
      }
      return result;
    },

    async deleteWorkpackageProducts({ commit, dispatch }, { workpackageId, productKeys }) {
      commit('setLoading', true);
      const [err, result] = await to(
        axios.delete(`/api/workpackages/${workpackageId}/products`, { data: { productKeys } })
      );
      commit('setLoading', false);
      if (err) {
        const message =
          (err && err.response && err.response.data && err.response.data.message) || err.message;
        throw new Error(message);
      }
      if (result.data && result.data.errors) {
        throw new Error(`Unable to delete products of workpackage (${workpackageId})`);
      }
      dispatch('fetchWorkpackageProducts', { workpackageId });
      return result;
    },

    async processCSV({ commit, dispatch, rootState }, { fileId, mappings, delimiter }) {
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const productInResetEnabled = get(
        rootState.context.clientConfig,
        'features.productsInResetEnabled',
        false
      );
      const service = productInResetEnabled
        ? 'workpackage-products-inReset'
        : 'workpackage-products';
      const [err, res] = await to(
        axios.post(`/api/csv/${service}/process/${fileId}`, { mappings, delimiter, workpackageId })
      );
      commit('setLoading', false);
      // TODO: Remove this and use global interceptor on AOV3-159
      if (err) {
        const key = get(err, 'response.data.messageKey', err.message);
        const message = i18n.t(key, get(err, 'response.data.messageParams', []));
        dispatch('snackbar/showError', message, { root: true });

        return;
      }
      const { messages, tableRows, additionalInformation } = res.data;
      if (size(tableRows)) commit('setWorkpackageProducts', tableRows);
      commit('setMissingWorkpackageProducts', additionalInformation.missingProducts);
      if (size(messages)) {
        dispatch('alerts/showMessages', messages, { root: true });
      }
    },
  },
};

export default store;
