var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c("div", [
        _vm._v(
          "\n    " +
            _vm._s(_vm.product.itemDescription) +
            " (" +
            _vm._s(
              _vm.formatNumber({
                number: _vm.product.palletSize,
                format: "float",
              })
            ) +
            ")\n  "
        ),
      ]),
      _vm._v(" "),
      _c("rtls-select", {
        attrs: {
          value: _vm.value,
          items: _vm.smallerSpacebreaks,
          "menu-props": { top: true, offsetY: true, maxWidth: 265 },
        },
        on: {
          change: function ($event) {
            return _vm.changeSpacebreak($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }