<template>
  <div data-id-e2e="btnImport">
    <v-btn
      small
      depressed
      color="primary"
      :disabled="
        !hasSelectedAssortmentGroups ||
          !hasPermission(userPermissions.canEditWorkpackageScope) ||
          shouldBeDisabled
      "
      :loading="isImportingFromPlano"
      @click="importFromPlanogram()"
    >
      {{ $t('actions.importAssortmentGroups') }}
      <v-icon right>mdi-download</v-icon>
    </v-btn>
    <assortment-tooltip
      :title="$t('tooltips.importFromAssortmentGroups.title')"
      :tooltip-sections="importFromAssortmentGroupsToolTipSections"
    />
    <dependency-tree-feedback-modal
      :value="dependencyTreeModalOpen"
      :results="dependencyTreeFeedback"
      page="workpackageSetup"
      @close="closeDependencyTreeModal"
      @commit="importFromPlanogram(true)"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { map, get, keyBy, filter, omit, uniqBy, reduce, concat, keys, size } from 'lodash';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import originSourceTypes from '@enums/origin-source-types';
import assortmentGroupTypes from '@enums/assortment-group-types';
import { workpackageScopeTooltipOptionsMixin } from '@/js/mixins/tooltip-options';
import datesMixin from '@/js/mixins/date-utils';

export default {
  mixins: [datesMixin, workpackageScopeTooltipOptionsMixin],
  props: {
    customWorkpackage: {
      type: Object,
      required: false,
      default: null,
    },

    shouldBeDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      dependencyTreeFeedback: {},
      dependencyTreeModalOpen: false,
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage', 'isImportingFromPlano']),
    ...mapState('context', ['clientConfig']),
    ...mapGetters('context', ['getDefaultReverseFormat']),
    ...mapGetters('workpackageProducts', ['workpackageProducts']),

    workpackage() {
      return this.customWorkpackage || this.selectedWorkpackage || {};
    },

    hasSelectedAssortmentGroups() {
      return get(this.workpackage, 'selectedAssortmentGroupSettings', []).length > 0;
    },

    isSplitWorkpackagesEnabled() {
      return get(this.clientConfig, 'features.splitWorkpackagesEnabled');
    },

    isMaxRangePerformanceLearningSwitchingPeriodEnabled() {
      return get(this.clientConfig, 'features.maxRangePerformanceLearningSwitchingPeriod');
    },

    selectedAssortmentGroupKeys() {
      return (
        (this.hasSelectedAssortmentGroups &&
          this.workpackage.selectedAssortmentGroupSettings.map(ag => ag.key)) ||
        []
      );
    },

    assortmentGroupKeysSelectedForAssortment() {
      return (
        (this.hasSelectedAssortmentGroups &&
          this.workpackage.selectedAssortmentGroupSettings
            .filter(ag => ag.assortment)
            .map(ag => ag.key)) ||
        []
      );
    },

    assortmentGroupKeysSelectedForAnalysis() {
      return (
        (this.hasSelectedAssortmentGroups &&
          this.workpackage.selectedAssortmentGroupSettings
            .filter(ag => ag.analysis)
            .map(ag => ag.key)) ||
        []
      );
    },

    useNewVersionOfAGSelection() {
      return get(this.clientConfig, 'features.useNewVersionOfAGSelection');
    },

    importFurnitureEnabled() {
      // Only import furniture for furniture assortment groups
      const assortmentGroupType = get(
        this.clientConfig,
        'assortmentGroups.mainType',
        assortmentGroupTypes.furniture
      );
      return (
        assortmentGroupType === assortmentGroupTypes.furniture &&
        get(this.clientConfig, 'features.importPlanogramFurnitureEnabled', false)
      );
    },
  },

  methods: {
    ...mapMutations('workpackages', ['setIsImportingFromPlano']),

    ...mapActions('dependencyTree', ['triggerDependencyTree']),
    ...mapActions('furniture', ['runWorkpackageFurnitureSetup']),
    ...mapActions('toolData', ['getStoreDetails', 'getProducts', 'getProductsByAssortmentGroup']),
    ...mapActions('workpackages', ['updateWorkpackage', 'fetchSingleWorkpackage']),
    ...mapActions('workpackageProducts', [
      'createWorkpackageProducts',
      'deleteWorkpackageProducts',
      'fetchWorkpackageProducts',
    ]),
    ...mapActions('snackbar', ['showSnackbar', 'showSuccess', 'showError']),
    ...mapActions('furniture', ['fetchWorkpackageFurniture']),
    ...mapActions('scenarios', ['fetchScenarios']),
    ...mapActions('scenarioProducts', ['fetchScenarioProducts']),

    async importFromPlanogram(commit = false) {
      const results = await this.triggerDependencyTree({
        params: {
          change: 'workpackageSetupModified',
          updates: {},
          commit,
          workpackageId: this.workpackage._id,
        },
      });

      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return;
      }

      this.setIsImportingFromPlano(true);
      try {
        // get stores & products from tool-data collections
        const storesFilter = {
          selectedAssortmentGroupKeys: this.selectedAssortmentGroupKeys,
        };

        const startDate = this.isMaxRangePerformanceLearningSwitchingPeriodEnabled
          ? moment.min(
              [
                moment.utc(this.workpackage.performanceStartDate),
                moment.utc(this.workpackage.learningStartDate),
              ].filter(d => d.isValid())
            )
          : this.workpackage.switchingStartDate;

        const endDate = this.isMaxRangePerformanceLearningSwitchingPeriodEnabled
          ? moment.max(
              [
                moment.utc(this.workpackage.performanceEndDate),
                moment.utc(this.workpackage.learningEndDate),
              ].filter(d => d.isValid())
            )
          : this.workpackage.switchingEndDate;

        const productsFilter = {
          selectedAssortmentGroupKeys: this.selectedAssortmentGroupKeys,
          switchingStartDate: this.formatDateForPicker(startDate, this.getDefaultReverseFormat),
          switchingEndDate: this.formatDateForPicker(endDate, this.getDefaultReverseFormat),
        };

        const importedStores = await this.getStoreDetails(storesFilter);
        // based on selected assortment groups create list of store keys that should be included in for analysis or/and for assortment selection
        const storeKeysSelectedForAssortment = new Set(
          map(
            importedStores.filter(s =>
              this.assortmentGroupKeysSelectedForAssortment.some(r =>
                s.associatedAssortmentGroups.includes(r)
              )
            ),
            'storeKey'
          )
        );
        const storeKeysSelectedForAnalysis = new Set(
          map(
            importedStores.filter(s =>
              this.assortmentGroupKeysSelectedForAnalysis.some(r =>
                s.associatedAssortmentGroups.includes(r)
              )
            ),
            'storeKey'
          )
        );

        // Keep the manual stores which are not being pulled in from AG
        const allStoreKeys = new Set(map(importedStores, 'storeKey'));
        const importedStoresForUpdate = uniqBy(
          importedStores.map(s => omit(s, 'associatedAssortmentGroups')),
          'storeKey'
        );

        const originalStores = filter(
          this.workpackage.stores,
          ({ originSource, storeKey }) =>
            originSource === originSourceTypes.existingManually && !allStoreKeys.has(storeKey)
        );
        const updatedStores = [
          ...importedStoresForUpdate,
          ...(this.isImportingFromPlano ? [] : originalStores),
        ];

        // Keep the manual products which are not being pulled in from AG
        const importedProducts = await this.getProductsByAssortmentGroup(productsFilter);
        const importedProductKeys = new Set(map(importedProducts, 'productKey'));

        const updatedProducts = [
          ...filter(
            this.workpackageProducts,
            ({ originSource, productKey }) =>
              originSource === originSourceTypes.existingManually &&
              !importedProductKeys.has(productKey)
          ),
          ...importedProducts,
        ];

        let productsFromTemplate = {};
        let storesFromTemplate = {};
        let semiNewProductsFromTemplate = [];

        // Get template data
        if (this.workpackage.templateId) {
          // Fetch all workpackage products from the template
          const templateWorkpackageProducts = await this.fetchWorkpackageProducts({
            workpackageId: this.workpackage.templateId,
            options: { isStatelessFetch: true },
          });
          const template = await this.fetchSingleWorkpackage({
            workpackageId: this.workpackage.templateId,
            includeStores: true,
            isStatelessFetch: true,
          });
          productsFromTemplate = keyBy(templateWorkpackageProducts, 'productKey');
          storesFromTemplate = keyBy(template.stores, 'storeKey');

          if (this.hasProductsExistingFromNewInTemplateEnabled) {
            const newProductsFromTemplate = await this.getNewProductsFromTemplate();
            // Filter out new products that have already been imported
            const existingProductKeyDisplays = new Set(map(updatedProducts, 'productKeyDisplay'));
            const updatedNewProducts = filter(
              newProductsFromTemplate,
              p => !existingProductKeyDisplays.has(p.productKeyDisplay)
            );
            const updatedNewProductsByKey = keyBy(updatedNewProducts, 'productKeyDisplay');
            // Get data for semi-new products from tooldata. They should be treated as existing products
            if (size(updatedNewProductsByKey)) {
              const semiNewProducts = await this.getProducts({
                params: { productKeyDisplays: keys(updatedNewProductsByKey) },
              });
              semiNewProductsFromTemplate = map(semiNewProducts, p => {
                const { fromTemplate, originSource } = updatedNewProductsByKey[p.productKeyDisplay];
                return { ...p, fromTemplate, originSource };
              });
            }
          }
        }

        // we should add stores to the workpackage setup by setting analysis: true, assortment: true
        // and replacing storeProfile with simpleSwapsCluster
        const workpackageStores = map(updatedStores, s => ({
          ...Object.assign(s, {
            analysis: this.useNewVersionOfAGSelection
              ? storeKeysSelectedForAnalysis.has(s.storeKey)
              : get(storesFromTemplate, [s.storeKey, 'analysis'], true),
            assortment: this.useNewVersionOfAGSelection
              ? storeKeysSelectedForAssortment.has(s.storeKey)
              : get(storesFromTemplate, [s.storeKey, 'assortment'], true),
            simpleSwapsCluster: s.storeProfile,
          }),
        }));

        // Track that products were added to workpackage/template from AGs
        const newWorkpackageProducts = reduce(
          updatedProducts,
          (acc, p) => {
            const isFromTemplateIntoNonTemplate =
              !!productsFromTemplate[p.productKey] && !this.workpackage.isTemplate;

            const itemToPush = {
              ...p,
              fromTemplate: !!productsFromTemplate[p.productKey] || !!this.workpackage.isTemplate,
              originSource: p.originSource || originSourceTypes.existingFromAG,
            };

            // If a product comes from template into non-template WP, only leave it if hasn't been excluded
            if (isFromTemplateIntoNonTemplate) {
              const isIncludedInTemplate = productsFromTemplate[p.productKey].included;
              if (isIncludedInTemplate) {
                itemToPush.included = true;
                acc.push(itemToPush);
              }
            } else if (this.workpackage.isTemplate) {
              // If a product comes into template, always include it
              itemToPush.included = true;
              acc.push(itemToPush);
            } else {
              acc.push(itemToPush);
            }

            return acc;
          },
          []
        );
        // If feature enabled, semi-new products should be treated as existing products and
        // will be added to workpackage products
        const workpackageProductsUpdates = concat(
          newWorkpackageProducts,
          semiNewProductsFromTemplate
        );
        const keysToOmit = [
          '_id',
          'customLearningDateWeeks',
          'customPerformanceDateWeeks',
          'customSwitchingDateWeeks',
        ];
        await Promise.all([
          await this.updateWorkpackageStores(workpackageStores),
          await this.updateWorkpackageProducts(workpackageProductsUpdates),
          // Workpackage scope data must be saved before furniture data can be generated
          ...(this.importFurnitureEnabled
            ? [
                this.updateWorkpackage({
                  workpackageId: this.workpackage._id,
                  updates: omit(this.workpackage, keysToOmit),
                }),
              ]
            : []),
        ]);
        // Generate furniture data
        if (this.importFurnitureEnabled) await this.runWorkpackageFurnitureSetup();

        this.showSuccess(this.$t('notifications.planograms.finished'));

        // fetch furniture for split filter
        if (this.isSplitWorkpackagesEnabled && !this.workpackage.isTemplate) {
          await this.fetchWorkpackageFurniture();
        }
      } catch (e) {
        this.showError(this.$t('notifications.planograms.failed'));
        console.error(e);
      } finally {
        this.setIsImportingFromPlano(false);
      }
    },

    async getNewProductsFromTemplate() {
      // New products are stored at scenario level. In order to retrieve the new
      // products from the template, we must first find the template scenarioId
      const templateScenarios = await this.fetchScenarios({
        params: {
          where: { workpackageId: this.workpackage.templateId },
          pick: ['_id'],
        },
        options: { skipCommit: true },
      });
      const templateScenarioId = get(templateScenarios[0], '_id');
      // Fetch new products from the template scenario
      const templateNewProducts = await this.fetchScenarioProducts({
        params: { scenarioId: templateScenarioId, where: { isNewProduct: true } },
        options: { shouldCommit: false },
      });
      return templateNewProducts;
    },

    async updateWorkpackageStores(stores) {
      await this.updateWorkpackage({
        workpackageId: this.workpackage._id,
        updates: {
          stores,
          selectedAssortmentGroupSettings: this.selectedAssortmentGroupKeys.map(key => ({
            key,
            analysis: true,
            assortment: true,
          })),
        },
      });
    },

    async updateWorkpackageProducts(products) {
      await this.deleteWorkpackageProducts({
        workpackageId: this.workpackage._id,
      });
      await this.createWorkpackageProducts({
        workpackageId: this.workpackage._id,
        products,
      });
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
    },
  },
};
</script>

<style scoped></style>
