<template>
  <v-card class="step-tab-panel" flat>
    <div class="assortment-table attributes-table d-flex flex-column">
      <v-container class="actions-container flex-grow-0">
        <v-row>
          <v-col class="actions-col d-flex align-center justify-start">
            <div class="filter-select-header">
              <span class="pr-6 br-1">
                <span class="info-note">{{ $tkey('productModellingTitle') }}</span>

                <!-- Product modelling tooltip -->
                <docs-link link="toolguide/110-modelling.html" />
                <v-btn
                  primary
                  :loading="isProductModellingJobRunning"
                  :disabled="!notModelledProductsCount"
                  class="ml-2"
                  @click="initSetupProductModelling()"
                >
                  {{ $tkey('runProductModelling') }}
                </v-btn>
                <v-btn
                  v-if="copyDecisionsAvailable"
                  data-id-e2e="btnCopyDecisions"
                  primary
                  :disabled="
                    isEditingDisabled || isProductModellingJobRunning || !!notModelledProductsCount
                  "
                  class="ml-2 mr-2"
                  @click="copyDecisions"
                >
                  {{ $tkey('copyDecisions') }}
                </v-btn>
              </span>
            </div>
          </v-col>
          <v-col class="actions-col actions-col--search">
            <!-- This uses @input as @change wasn't wired for rtls-search yet -->
            <!-- Should switch to using @change after AOV3-777 -->
            <rtls-search
              v-if="gridApi"
              v-model="searchString"
              :disabled="isProductModellingJobRunning"
              white
              width="240px"
              :placeholder="$tkey('searchPlaceholder')"
              @input="onFilterChanged"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container class="actions-container flex-grow-0">
        <v-alert v-if="notModelledProductsCount" type="error" text>
          {{
            $t('validationErrors.modelProducts', [
              notModelledProductsCount,
              totalParentProductsCount,
              establishedProductsCount,
            ])
          }}
        </v-alert>

        <!-- Warnings and errors -->
        <v-row class="products-table-actions">
          <v-col class="actions-col">
            <error-controls
              v-if="showErrorControls"
              :invalid-rows-message="$tkey('mustHaveSisterProductsWarning')"
              :is-showing-invalid-rows="filterInvalidRows"
              class="mt-2 ml-2 d-flex"
              @click="toggleInvalidRows"
            />
            <warning-controls
              v-if="showWarningControls"
              :invalid-rows-message="$tkey('sisterProductsWarning')"
              :is-showing-invalid-rows="filterWarningRows"
              class="mt-2 ml-2 d-flex"
              @click="toggleWarningRows"
            />
            <div v-if="needsCDT || notModelledProductsCount" class="mt-2 ml-2 d-flex">
              <span v-if="needsCDT" class="no-cdt">
                {{ $t('validationErrors.runCDT') }}
              </span>
            </div>
          </v-col>
        </v-row>

        <!-- Filters and actions -->
        <v-row class="products-table-actions">
          <v-col v-if="isSelectedViewToggleVisible" class="d-flex justify-start">
            <div>
              <rtls-toggle
                v-model="selectedView"
                :left-toggle="toggle.leftToggle"
                :right-toggle="toggle.rightToggle"
                @input="setProductsDataToDisplay"
              />
            </div>
          </v-col>
          <v-col class="d-flex justify-end">
            <div>
              <rtls-text-field
                v-model="trendOverrideDisplayValue"
                :disabled="isEditingDisabled || isProductModellingJobRunning"
                grey
                width="140px"
                :placeholder="$tkey('enterTrend')"
                :rules="trendRules"
                @blur="updateTrendOverrideDisplay"
                @focus="trendOverrideDisplayValueFocus"
              />
            </div>
            <v-btn
              primary
              :disabled="isTrendDisabled || isProductModellingJobRunning"
              @click="applyTrend()"
            >
              {{ $tkey('actions.applyToVisible') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <div class="ag-grid-box flex-grow-1">
        <progress-bar v-if="isLoading" />
        <ag-grid-vue
          v-else
          style="width: 100%; height: 100%;"
          class="ag-theme-custom"
          :row-data="productsData"
          :grid-options="gridOptions"
          :does-external-filter-pass="doesExternalFilterPass"
          :stop-editing-when-cells-loses-focus="true"
          :single-click-edit="true"
          @cell-value-changed="handleCellEdit"
          @grid-ready="onGridReady"
        />
      </div>

      <page-actions
        :has-data-changes="hasDataChanges"
        :has-data-errors="hasDataErrors"
        :save-disabled="isEditingDisabled"
        :is-discard-enabled="!isEditingDisabled"
        save-btn-text="calculateAndSave"
        @discard="discardChanges"
        @save="onSave(false)"
      >
        <template v-slot:right-btns>
          <v-btn
            primary
            :disabled="!canRunCalculations"
            :loading="runningProductCalculations"
            @click="runCalculations"
            >{{ $tkey('calculate') }}</v-btn
          >
        </template>
      </page-actions>

      <dependency-tree-feedback-modal
        :value="dependencyTreeModalOpen"
        :results="dependencyTreeFeedback"
        page="productModelling"
        save-btn-text="proceedWithDeleting"
        proceed-btn-text="proceedWithoutDeleting"
        warning-message="dependencyTree.productModellingWarning"
        able-to-proceed-without-deleting
        @close="closeDependencyTreeModal"
        @proceed="proceedHandler()"
        @commit="commitHandler()"
      />

      <copy-modelling-decisions-modal
        ref="copyDecisionsModal"
        :value="copyDecisionsModalOpen"
        :is-processing="isProcessingCopyDecisions"
        @cancel="closeCopyDecisionsModal"
        @confirm="onCopyDecisionsConfirm"
        @close="closeCopyDecisionsModal"
        @diff-change="updateDiffFromCopy"
      />

      <unsaved-data-modal
        ref="unsavedDataModal"
        :value="isUnsavedDataModalOpen"
        @cancel="closeUnsavedDataModal"
        @confirm="closeUnsavedDataModal"
      />
    </div>
  </v-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import modellingTypeOptions, { modellingTypes } from '@enums/product-modelling-types';
import originSourceTypes from '@enums/origin-source-types';
import sectionStatuses from '@enums/section-status';
import {
  cloneDeep,
  each,
  every,
  filter,
  findIndex,
  get,
  intersection,
  isEmpty,
  isEqual,
  isNull,
  isNil,
  isNumber,
  isUndefined,
  keyBy,
  last,
  map,
  merge,
  pick,
  reduce,
  set,
  size,
  some,
  toNumber,
  uniq,
  values,
  without,
  xor,
  inRange,
  keys,
  flatMap,
  lowerCase,
  omit,
} from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import inputValidationMixin from '@/js/mixins/input-validations';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import agGridUtils from '@/js/utils/ag-grid-utils';
import numberUtils from '@/js/utils/number-format-utils';
import i18n from '@/js/vue-i18n';
import { jobApi } from '@/js/helpers';
import imageRenderer from '@/js/components/ag-grid-cell-renderers/image-renderer.vue';
import * as rosUtils from '@sharedModules/data/utils/ros-utils';
import availabilityIcon from '../../../../img/availability.svg';
import addSisterProductsComponent from './add-sister-products-select.vue';
import resetRenderer from '../renderers/reset-renderer.vue';
import resetAllHeader from '../headers/reset-all-header.vue';

// We need to do translations inside of ag-grid functions where this is not bound to Vue.
const localizationKey = 'productModellingPage';
const t = (key, locKey = localizationKey) => i18n.t(`${locKey}.${key}`);

const minTrendValue = -100;
const iconWidth = 40;
const gridRowHeight = 30;
const scsColours = {
  RED: 'red',
  ORANGE: 'orange',
  GREEN: 'green',
  GREY: 'grey',
};

export default {
  name: 'ProductModelling',
  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    imageRenderer,
    resetAllHeader,
    resetRenderer,
    addSisterProductsComponent,
  },

  localizationKey,
  mixins: [inputValidationMixin, unsavedDataWarningMixin],
  data() {
    return {
      validationErrors: [],
      trendRules: [this.isNumber, v => this.isGreaterOrEqual(v, minTrendValue)],
      trendOverrideDisplayValue: null,
      existingProducts: null,
      runningProductCalculations: false,
      expandedSisterRows: [],
      savedState: {},
      separator: '.',
      addRowProductKey: 'addSisterRow',
      changedSinceCalculation: [],
      selectedView: '',
      toggle: {
        leftToggle: {
          value: 'allProducts',
          translation: this.$tkey('allProducts'),
        },
        rightToggle: {
          value: 'newProductsOnly',
          translation: this.$tkey('newProductsOnly'),
        },
      },
      // Headers are used in multiple places so we define them all here
      headers: {
        inReset: {
          field: 'inReset',
          colId: 'inReset',
          headerName: '',
          pinned: 'left',
          minWidth: 50,
          maxWidth: 50,
          resizable: false,
          suppressMenu: true,
          cellRenderer: 'imageRenderer',
          cellRendererParams: params => {
            // we should indicate with not in reset icon only products that are not in reset
            // we also don't want to display icon in the row that has select to choose sister products
            if (!params.node.data.inReset && !params.data.addSisterRow) {
              return {
                imgComponent: 'not-in-reset-icon',
                classes: this.isEditingDisabled ? 'disabled' : '',
                title: this.$t('general.productNotSelectedToBeInReset'),
              };
            }
          },
        },
        productType: {
          field: 'isNewProduct',
          colId: 'isNewProduct',
          headerName: '',
          pinned: 'left',
          minWidth: 50,
          maxWidth: 50,
          resizable: false,
          suppressMenu: true,
          cellRenderer: params => {
            const productType = this.getProductState(params.data);
            return params.data.addSisterRow ||
              productType.translation === modellingTypeOptions.existingProduct
              ? ''
              : `
              <span class="chip ${productType.style}">
                <span class="chip__content">
                  ${t(productType.translation, localizationKey)}
                </span>
              </span>
            `;
          },
          cellClassRules: {
            'greyed-out-row': this.isRowGreyedOut,
          },
        },
        productKey: {
          field: 'productKeyDisplay',
          colId: 'productKey',
          headerName: this.$tkey('productKeyDisplay'),
          width: 120,
          filter: 'agTextColumnFilter',
          filterParams: agGridUtils.filters.standardStringFilter,
          pinned: 'left',
          cellRenderer: 'addSisterProductsComponent',
          cellRendererParams: {
            disabled: () => this.isEditingDisabled,
            potentialSisterProducts: row => this.getPotentialSisterProductsList(row),
          },
          cellClassRules: {
            'ag-cell-border-right': params => params.data.addSisterRow,
            'greyed-out-row': this.isRowGreyedOut,
            'not-editable': () => this.notModelledProductsCount,
          },
          colSpan: params => (params.data.addSisterRow ? 4 : 1),
        },
        itemDescription: {
          field: 'itemDescription',
          colId: 'itemDescription',
          headerName: this.$tkey('productDescription'),
          filter: 'agTextColumnFilter',
          pinned: 'left',
          width: 300,
          flex: 1,
          filterParams: agGridUtils.filters.standardStringFilter,
          cellClassRules: {
            'greyed-out-row': this.isRowGreyedOut,
          },
        },
        combinedUnitAndMeasure: {
          colId: 'combinedUnitAndMeasure',
          field: 'combinedUnitAndMeasure',
          headerName: this.$tkey('combinedContentAndUnit'),
          type: 'numericColumnCustom',
          filter: 'agTextColumnFilter',
          filterParams: agGridUtils.filters.standardStringFilter,
          pinned: 'left',
          width: 70,
          flex: 1,
          valueGetter(params) {
            return (params.data.contentValue || '') + (params.data.contentUnitOfMeasure || '');
          },
          cellClassRules: {
            'greyed-out-row': this.isRowGreyedOut,
          },
        },
        salesConfidenceScorePerformancePeriod: {
          field: 'salesConfidenceScorePerformancePeriod',
          colId: 'salesConfidenceScorePerformancePeriod',
          headerName: '',
          headerClass: 'column-divider',
          minWidth: 50,
          maxWidth: 50,
          resizable: false,
          cellClass: 'centered column-divider',
          filter: false,
          menuTabs: [], // hide menu as no action can be performed
          cellRenderer: params =>
            params.data.parentKey ? '' : this.salesConfidenceScoreRenderer(params),
        },
        trendApplied: {
          headerValueGetter: () => this.$tkey('trendApplied'),
          field: 'trendApplied',
          colId: 'trendApplied',
          type: 'numericColumnCustom',
          width: 80,
          filter: 'agNumberColumnFilter',
          pinned: 'left',
          valueFormatter: params => {
            return ![null, undefined, ''].includes(params.value)
              ? `${agGridUtils.formatters.roundFormatter(params)}%`
              : params.value;
          },
          headerComponent: 'resetAllHeader',
          headerComponentParams: params => ({
            isDisabled: this.isResetAllDisabled(params),
            resetAll: this.resetAll,
          }),
          cellRenderer: 'resetRenderer',
          cellRendererParams: params => ({
            invalid: params.data.addSisterRow
              ? false
              : some([
                  agGridUtils.validations.valueIsNotNumericPermissive(params),
                  agGridUtils.validations.valueIsLess(params.value, minTrendValue),
                ]),
            isHighlighted: params.data.addSisterRow ? false : this.hasDiff(params),
            visible:
              (!params.data.parentKey && !this.notModelledProductsCount) ||
              !isNil(params.data.trendApplied),
            isDisabled: !this.isEditableRow(params) || this.isRowGreyedOut(params),
            savedState: this.$options.savedState,
            hideReset: isNil(params.data.trendApplied),
          }),
          cellClass: 'editable-input ag-cell-border-right',
          headerTooltip: this.$tkey('tooltips.resetAll'),
          tooltipValueGetter: this.trendAppliedTooltipValueGetter,
        },
        availability: {
          headerName: this.$tkey('availability'),
          headerClass: 'border-left-data',
          cellClass: 'border-left-data padding-left-avail',
          suppressMenu: true,
          width: 70,
          cellRenderer: params => this.availabilityCellRenderer(params),
          cellClassRules: {
            'greyed-out-row': this.isRowGreyedOut,
          },
          hide: !this.showNotImplemented,
        },
        transfer: {
          columnGroupShow: 'open',
          headerClass: 'greyed-out transfer',
          cellClass: 'greyed-out',
          suppressMenu: true,
          width: 50,
          headerName: this.$tkey('transfer'),
          valueGetter: params => (params.data.addSisterRow ? '' : 'TBD'),
          cellClassRules: {
            'greyed-out-row': this.isRowGreyedOut,
          },
          hide: !this.showNotImplemented,
        },
        stores: {
          columnGroupShow: 'open',
          headerClass: 'greyed-out',
          cellClass: 'greyed-out',
          width: 50,
          suppressMenu: true,
          headerName: this.$tkey('stores'),
          valueGetter: params => (params.data.addSisterRow ? '' : 'TBD'),
          cellClassRules: {
            'greyed-out-row': this.isRowGreyedOut,
          },
          hide: !this.showNotImplemented,
        },
        time: {
          columnGroupShow: 'open',
          headerClass: 'greyed-out',
          width: 50,
          cellClass: 'greyed-out',
          suppressMenu: true,
          headerName: this.$tkey('time'),
          valueGetter: params => (params.data.addSisterRow ? '' : 'TBD'),
          cellClassRules: {
            'greyed-out-row': this.isRowGreyedOut,
          },
          hide: !this.showNotImplemented,
        },
        toggleSisterProductIcon: {
          pinned: 'right',
          cellClass: 'centered no-focus-padding',
          maxWidth: iconWidth,
          minWidth: iconWidth,
          resizable: false,
          suppressMenu: true,
          headerName: '',
          field: 'isSelected',
          cellRenderer: params =>
            params.data.addSisterRow ? '' : this.toggleSisterProductSelectionRenderer(params),
        },
        rateOfSale: {
          headerValueGetter: () => {
            return `${this.$tkey('predicted')} ${this.$tkey('unitPerWkStore', {
              currency: this.requiredCurrency,
            })}`;
          },
          field: 'rateOfSale',
          width: 90,
          flex: 1,
          type: 'numericColumnCustom', // no need for filterParams.valueFormatter because we're using agNumberColumnFilter
          filter: 'agTextColumnFilter',
          cellClassRules: {
            'diff-background': this.hasDiff,
            'greyed-out-row': this.isROSGreyedOut || this.isRowGreyedOut,
          },
        },
        rateOfSaleTrendApplied: {
          field: 'rateOfSaleTrendApplied',
          colId: 'rateOfSaleTrendApplied',
          headerName: this.$tkey('trendApplied'),
          headerClass: 'font-italic ag-header-border-right--hidden',
          type: 'numericColumnCustom',
          filter: 'agNumberColumnFilter',
          width: 90,
          valueGetter: params => {
            return rosUtils.calculateRoSWithTrend(params.data.rateOfSale, params.data.trendApplied);
          },
          valueFormatter: this.overrideValueFormatter,
          editable: false,
          tooltipValueGetter: this.trendAppliedTooltipValueGetter,
          cellClass: 'column-divider',
          cellClassRules: {
            'invalid-numeric': params => {
              return params.data.addSisterRow
                ? false
                : some([
                    agGridUtils.validations.valueIsNotNumericPermissive(params),
                    agGridUtils.validations.valueIsLess(params.value, minTrendValue),
                  ]);
            },
            'greyed-out-row': this.isRowGreyedOut,
          },
        },
        rateOfSaleOverride: {
          headerValueGetter: () =>
            `${this.$tkey('overridden')} ${this.$tkey('unitPerWkStore', {
              currency: this.requiredCurrency,
            })}`,
          field: 'rateOfSaleOverride',
          type: 'numericColumnCustom',
          width: 90,
          filter: 'agTextColumnFilter',
          valueFormatter: this.overrideValueFormatter,
          headerClass: 'ag-header-border-right--hidden',
          headerComponent: 'resetAllHeader',
          headerComponentParams: params => ({
            isDisabled: this.isResetAllDisabled(params),
            resetAll: this.resetAll,
          }),
          cellRenderer: 'resetRenderer',
          cellRendererParams: params => ({
            invalid: params.data.addSisterRow
              ? false
              : some([
                  agGridUtils.validations.valueIsNotNumericPermissive(params),
                  agGridUtils.validations.valueIsNegative(params),
                ]),
            isHighlighted: params.data.addSisterRow ? false : this.hasDiff(params),
            visible: this.isRoSOverrideUnderlined(params),
            isDisabled:
              !this.isEditableRow(params) ||
              params.data.modellingType === modellingTypes.established,
            savedState: this.$options.savedState,
            hideReset: isNil(params.data.rateOfSaleOverride),
          }),
          cellClass: 'editable-input',
          headerTooltip: this.$tkey('tooltips.resetAll'),
          tooltipValueGetter: this.tooltipValueGetter,
        },
        emptyColumn: {
          // empty column to add space between unclustered and clustered columns
          minWidth: 20,
          maxWidth: 20,
          resizable: false,
          cellClass: 'unclustered-border-right',
          headerClass: 'unclustered-border-right no-ag-icon-menu',
        },
        expandSisterProductsIcon: {
          field: 'isSelected',
          pinned: 'left',
          resizable: false,
          minWidth: iconWidth,
          maxWidth: iconWidth,
          cellClass: 'centered no-focus-padding',
          suppressMenu: true,
          cellRenderer: params =>
            params.data.addSisterRow ? '' : this.toggleSisterProductSelectionRenderer(params),
          headerName: '',
          cellClassRules: {
            'diff-background': this.sisterHasDiff,
          },
        },
        // Used to render empty column in the detail grid.
        blankTrendApplied: {
          minWidth: 70,
          maxWidth: 70,
          resizable: false,
          pinned: 'right',
          suppressMenu: true,
          colId: 'blankTrendApplied',
          cellClassRules: {
            'greyed-out-row': this.isRowGreyedOut,
          },
        },
        // Used to render empty column in the detail grid.
        blankRateOfSaleOverride: {
          minWidth: 90,
          maxWidth: 90,
          resizable: false,
          suppressMenu: true,
          colId: 'blankRateOfSaleOverride',
          cellClassRules: {
            'greyed-out-row': this.isRowGreyedOut,
          },
        },
      },
      initialHeaders: [], // Set in created hook
      staticDataColumnChildren: [], // Set in created hook
      gridOptions: {
        suppressContextMenu: true,
        tooltipShowDelay: 0,
        rowHeight: gridRowHeight,
        groupHeaderHeight: 30,
        headerHeight: 50,
        getRowClass(params) {
          if (!params.data.parentKey && params.rowIndex !== 0) {
            // add top border to all main products rows except first row, avoids duplicating top border
            return 'border-top-selected';
          }
        },
        defaultColDef: {
          filter: true,
          suppressMovable: true,
          menuTabs: ['filterMenuTab'],
          sortable: false,
          editable: false,
          resizable: true,
          minWidth: 80,
        },
        isExternalFilterPresent() {
          return true;
        },
        getRowId: params => `${params.data.rowKey}`,
        overlayLoadingTemplate: `<span class="ag-overlay-loading-center">${this.$tkey(
          'messages.inProgress'
        )}</span>`,
        // define colType to prevent console warning
        // AOV3-1423 TODO: use agGridUtils.columnTypes
        columnTypes: {
          numericColumnCustom: agGridUtils.columnTypes.numericColumnCustom,
        },
      },
      searchString: '',
      gridApi: null,
      columnApi: null,
      keyIdMap: {},
      // This stores the original set of non-child products retrieved for the grid, but gets into products data as a subset
      // based on selectedView
      allProductsData: [],
      // This stores the data for the grid. As you modify the grid, this array is mutated. If modifying from outside the grid,
      // such as calculating values or adding products, you must mutate this array and then reset it. See immutableData in ag-grid.
      productsData: [],
      // This stores the changes from the saved data
      currentStateDiff: {},
      dependencyTreeFeedback: {},
      dependencyTreeModalOpen: false,
      copyDecisionsModalOpen: false,
      calledFromInitSetupProductModelling: false,
      // fields that holds which rows are either faulty or requires attention, used when use filters products
      errorData: {},
      warningData: {},
      // flags that controls products shown on page if user has toggled the filter button
      filterInvalidRows: false,
      filterWarningRows: false,
      // states if the tool is processing/cleaning up modelling decisions
      isProcessingCopyDecisions: false,
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('context', ['clientConfig']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('clustering', ['selectedScheme']),
    ...mapGetters('context', [
      'showNotImplemented',
      'getCurrentNumericLocale',
      'getCurrentLocale',
      'getClientConfig',
    ]),
    ...mapGetters('scenarios', ['selectedScenarioStatus', 'isJobRunning', 'jobStatuses']),

    modifiedColumns() {
      return new Set(flatMap(this.currentStateDiff, diff => keys(diff)));
    },

    columnsWithValidationErrors() {
      return new Set(map(this.validationErrors, 'field'));
    },

    requiredCurrency() {
      return this.$t(`currencies.${this.currentNumericLocale}`);
    },

    copyDecisionsAvailable() {
      return get(this.getClientConfig, 'features.copyModellingDecisionsEnabled', false);
    },

    currentNumericLocale() {
      return this.getCurrentNumericLocale;
    },

    currentLocale() {
      return this.getCurrentLocale;
    },

    isSelectedViewToggleVisible() {
      return get(this.clientConfig, 'features.isProductModellingToggleProductViewsEnabled');
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditModellingPage);
    },

    productModellingDisabled() {
      return (
        // Shouldn't be available if CDT hasn't been run yet.
        // Shouldn't be available if all products were already modelled
        this.isEditingDisabled || !this.hasCDTRun || !this.notModelledProductsCount
      );
    },

    isTrendDisabled() {
      if (this.isEditingDisabled) return true;

      if (!numberUtils.validNumericString(this.trendOverrideDisplayValue)) {
        return true;
      }

      return (
        !every(map(this.trendRules, r => r(this.trendOverride)), v => !!v) ||
        this.trendOverride < minTrendValue
      );
    },

    hasProducts() {
      return size(this.productsData);
    },

    totalParentProductsCount() {
      // returns size of all parent products
      return size(filter(this.productsData, product => !product.parentKey));
    },

    notModelledProductsCount() {
      // returns size of all parent products that weren't modelled
      const notModelled = size(filter(this.productsData, p => !p.parentKey && !p.hasBeenModelled));
      const totalNotModelled =
        notModelled - this.establishedProductsCount - this.similarProductsCount;
      return totalNotModelled > 0 ? totalNotModelled : 0;
    },

    establishedProductsCount() {
      return size(filter(this.productsData, p => p.modellingType === modellingTypes.established));
    },

    similarProductsCount() {
      return size(filter(this.productsData, p => !p.hasBeenModelled && p.parentProduct));
    },

    trendOverride() {
      return !isEmpty(this.trendOverrideDisplayValue)
        ? numberUtils.formatStringToNumber(this.trendOverrideDisplayValue)
        : this.trendOverrideDisplayValue;
    },

    hasCDTRun() {
      const cdtStatus = get(
        this.selectedScenarioStatus(this.selectedWorkpackage.type),
        'measuring.cdt.status',
        sectionStatuses.notRun
      );
      return cdtStatus === sectionStatuses.complete;
    },

    needsCDT() {
      return this.hasProducts && !this.hasCDTRun;
    },

    keyedProductsData() {
      return keyBy(this.productsData, 'productKey');
    },

    existingProductsByProductKey() {
      return keyBy(this.existingProducts, 'productKey');
    },

    canSave() {
      return !size(this.validationErrors) && !this.isEditingDisabled;
    },

    canRunCalculations() {
      return size(this.changedSinceCalculation) && this.hasDataChanges && this.canSave;
    },

    hasDataChanges() {
      return !isEmpty(this.currentStateDiff);
    },

    clusterIdsInScheme() {
      const clusters = get(this.selectedScheme, 'clusters', []);
      return map(clusters, 'clusterId');
    },

    hasDataErrors() {
      // required for <page-actions> component
      return !this.canSave;
    },

    showErrorControls() {
      return !this.notModelledProductsCount && (size(this.errorData) || this.filterInvalidRows);
    },

    showWarningControls() {
      return !this.notModelledProductsCount && (size(this.warningData) || this.filterWarningRows);
    },

    isProductModellingJobRunning() {
      if (!this.gridApi) return false;
      const isRunning = this.isJobRunning('setupProductModelling');
      if (isRunning) {
        this.gridApi.showLoadingOverlay();
      } else {
        this.gridApi.hideOverlay();
      }
      return isRunning;
    },

    hideProductsNotListedToAssortment() {
      return get(this.getClientConfig, 'features.rcHideProductsNotListedToAssortment', false);
    },
  },

  watch: {
    currentNumericLocale() {
      this.refreshGrid();
    },

    currentLocale() {
      this.refreshGrid();
    },

    isProductModellingJobRunning() {
      if (!this.gridApi) return;
      // Re-fetch the data for the table
      this.updateDataAndGrid();
    },

    modifiedColumns(newValue, oldValue) {
      if (!this.gridOptions.api || isEqual(newValue, oldValue)) return;
      this.gridOptions.api.refreshHeader();
    },

    columnsWithValidationErrors(newValue, oldValue) {
      if (!this.gridOptions.api || isEqual(newValue, oldValue)) return;
      this.gridOptions.api.refreshHeader();
    },
  },

  async created() {
    if (this.gridApi) this.gridApi.showLoadingOverlay();
    await this.init();

    // Create initial headers
    // All individual headers are defined in data and then used here to construct the actual header objects
    this.setAllInitialHeaders();
    if (this.gridApi) {
      this.gridApi.setColumnDefs(this.initialHeaders);
      this.gridApi.hideOverlay();
    }
  },

  methods: {
    ...mapActions('scenarioProducts', [
      'saveScenarioProducts',
      'fetchScenarioProductsWithClusters',
      'updateClusteredScenarioProducts',
      'runSetupProductModelling',
    ]),
    ...mapActions('clustering', ['fetchScenarioClusters']),
    ...mapActions('scenarios', ['loadScenario']),
    ...mapActions('snackbar', ['showWarning', 'showSuccess']),
    ...mapActions('dependencyTree', ['triggerDependencyTree']),

    onFilterChanged() {
      // collapse all expanded rows before applying the filter
      each(this.expandedSisterRows, expandedRow => {
        const node = this.gridApi.getRowNode(expandedRow);
        this.toggleSisterProductRows(node);
        this.toggleSisterProductSelectionRenderer(node);
        this.redrawRow(node.data.rowKey);
      });
      this.gridApi.onFilterChanged();
    },

    refreshGrid() {
      this.setAllInitialHeaders();
      this.gridApi.setColumnDefs(this.initialHeaders);
      this.gridApi.refreshCells({ force: true });
    },

    isEditableRow(params) {
      // can't edit in sister product rows
      return !this.isEditingDisabled && !this.notModelledProductsCount && !params.data.parentKey;
    },

    setProductsDataToDisplay() {
      /* Filter out new subset of data to display after toggling selected view
        between 'allProducts' and 'newProductsOnly'.
        1. Merge current product data (possibly modified) with all products data
        2. Set the background data for the grid itself:
          if selected view is set to new products only, set only new products without parents,
          otherwise set all products without parents
      */

      const mergedProductsData = merge(
        keyBy(this.allProductsData, 'productKey'),
        keyBy(this.productsData, 'productKey')
      );
      this.allProductsData = values(mergedProductsData);

      this.productsData =
        this.selectedView === 'newProductsOnly'
          ? filter(this.allProductsData, p => p.isNewProduct)
          : this.allProductsData;
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
      this.calledFromInitSetupProductModelling = false;
    },

    copyDecisions() {
      this.copyDecisionsModalOpen = true;
    },

    closeCopyDecisionsModal() {
      this.copyDecisionsModalOpen = false;
    },

    onCopyDecisionsConfirm() {
      this.isProcessingCopyDecisions = true;
    },

    updateDiffFromCopy({ updates: rawUpdates, notCopiedSPDecisions = [] }) {
      // run validations on top of updates.
      const { updates, removedDecisions } = this.removeInvalidSisterProductsCopiedFromDecisions(
        rawUpdates
      );
      const allRemovedDecisions = notCopiedSPDecisions.concat(removedDecisions);
      this.isProcessingCopyDecisions = false;
      // collapse all expanded rows before updating
      each(this.expandedSisterRows, expandedRow => {
        const node = this.gridApi.getRowNode(expandedRow);
        this.toggleSisterProductRows(node);
        this.toggleSisterProductSelectionRenderer(node);
        this.redrawRow(node.data.rowKey);
      });

      const modifiedRows = updates.map(({ productKey }) => {
        return this.gridApi.getRowNode(productKey);
      });
      // get the current objects in the table
      this.updateRows(updates, { appendSisterProducts: false });
      this.gridApi.redrawRows({ rowNodes: modifiedRows });

      // identify errors
      each(this.productsData, this.checkRowForErrors);

      if (!isEmpty(allRemovedDecisions)) {
        const content = this.$tkey('notCopiedSPDecisions', {
          notCopiedSPDecisions: allRemovedDecisions,
        });
        this.showWarning(content);
      }
    },

    async commitHandler() {
      if (this.calledFromInitSetupProductModelling) await this.initSetupProductModelling(true);
      else await this.onSave(true);

      this.closeDependencyTreeModal();
    },

    async proceedHandler() {
      if (this.calledFromInitSetupProductModelling) await this.modelProducts();
      else await this.saveChanges();

      this.closeDependencyTreeModal();
    },

    async runCalculations() {
      this.runningProductCalculations = true;
      try {
        // Get updates from any rows that have been changed since last calculation
        const updates = this.getCalculationUpdates();

        if (isNull(updates)) {
          this.runningProductCalculations = false;
          this.showWarning(this.$tkey('mustHaveSisterProductsWarning'));
          return false;
        }

        const updatedRows = await jobApi.runFunction('update-new-products-ros', {
          scenario_id: this.selectedScenario._id,
          updates,
        });

        const formattedUpdates = updatedRows.reduce((a, update) => {
          const { productKey, clusterId, rateOfSale } = update;
          // If first time encountering product, initialise obj with property product key
          if (!a[productKey]) a[productKey] = { productKey };
          if (!clusterId) {
            // The unclustered value has changed - set it so that it gets updated
            a[productKey].rateOfSale = rateOfSale;
            return a;
          }

          return set(a, [productKey, clusterId], { rateOfSale });
        }, {});

        this.updateRows(formattedUpdates);
        this.runningProductCalculations = false;
        this.changedSinceCalculation = [];
        return true;
      } catch (e) {
        console.error(e);
        this.showWarning();
        this.runningProductCalculations = false;
        return false;
      }
    },

    /**
     * Update the rows in AGgrid, if appendSisterProducts is on will merge sister products
     */
    updateRows(updates, { appendSisterProducts } = { appendSisterProducts: true }) {
      const updatedProducts = keyBy(updates, 'productKey');
      const updatedRowKeys = new Set(map(updates, ({ productKey }) => parseInt(productKey, 10)));

      // Apply updates to currentStateDiff
      each(updatedProducts, (update, productKey) => {
        // Mark old keys for removal when not deep merging
        if (!appendSisterProducts) {
          const oldSisterProducts = get(this.keyedProductsData, `${productKey}.sisterProducts`, {});
          Object.keys(oldSisterProducts).forEach(key => {
            oldSisterProducts[key].isSelected = false;
          });
          const updatedSisters = get(updatedProducts, `${productKey}.sisterProducts`, {});
          updatedProducts[productKey].sisterProducts = merge({}, oldSisterProducts, updatedSisters);
          update.sisterProducts = updatedProducts[productKey].sisterProducts;
        }
        each(update, (value, field) =>
          this.updateCurrentState({
            productKey,
            field,
            currentValue: value,
          })
        );
      });

      // Currently need to use immutableData to implement this page - need to map here to construct a new array
      // Could be made faster by maintaing sorted array, would remove need for array traversal
      this.productsData = map(this.productsData, product => {
        if (product.parentKey) return product; // only the sister grid rows have this property

        if (updatedRowKeys.has(product.productKey)) {
          return updatedRowKeys.has(product.productKey)
            ? merge({}, product, updatedProducts[product.productKey])
            : product;
        }

        return product;
      });

      this.gridApi.setRowData(this.productsData);
    },

    sanitizeTrendInputValue() {
      if (!numberUtils.validNumericString(this.trendOverrideDisplayValue)) {
        return this.trendOverrideDisplayValue;
      }
      return !isEmpty(this.trendOverrideDisplayValue)
        ? numberUtils.formatStringToNumber(this.trendOverrideDisplayValue)
        : this.trendOverrideDisplayValue;
    },

    trendOverrideDisplayValueFocus() {
      this.trendOverrideDisplayValue = this.sanitizeTrendInputValue();
    },

    updateTrendOverrideDisplay() {
      if (!numberUtils.validNumericString(this.trendOverrideDisplayValue)) {
        return this.trendOverrideDisplayValue;
      }
      const sanitizedValue = this.sanitizeTrendInputValue();
      this.trendOverrideDisplayValue = !isEmpty(String(sanitizedValue))
        ? this.formatNumber({
            number: sanitizedValue,
            format: 'float',
          })
        : '';
    },

    getCalculationUpdates() {
      let err = false;

      // Only get products that have been modified since the last time you calculated
      const changedProducts = merge(
        {},
        pick(this.$options.savedState, this.changedSinceCalculation),
        pick(this.currentStateDiff, this.changedSinceCalculation)
      );
      const res = map(changedProducts, (product, productKey) => {
        const update = {};

        // We don't want to send values unless they exist - no `rateOfSaleOverride: undefined` or `sisterProducts: []`
        const { rateOfSaleOverride } = product;

        // Get the selected sister products - if none then handled by the err flag
        const selectedSisterProducts = filter(product.sisterProducts, { isSelected: true });
        const sisterProductKeys = map(selectedSisterProducts, sisterProduct =>
          toNumber(sisterProduct.productKey)
        );
        if (product.modellingType !== modellingTypes.established && isEmpty(sisterProductKeys)) {
          // Product must have at least one sister product if it's non-established
          err = true;
        }

        if (size(sisterProductKeys)) update.sisterProductKeys = sisterProductKeys;
        if (rateOfSaleOverride) update.rateOfSaleOverride = rateOfSaleOverride;

        if (size(update)) update.productKey = toNumber(productKey);
        return update;
      });

      if (err) return null;

      return filter(res, size);
    },

    redrawRow(rowKey) {
      const changedRow = this.gridApi.getRowNode(rowKey);
      const rowNodes = [changedRow];
      // Redraw row - this removes and recreates the entire row in the DOM.
      // This was the only way I could find to consistently trigger the row styling.
      this.gridApi.redrawRows({ rowNodes });
      // Current styling indents and unidents the row as it re-renders
      // Clearing focus makes it look a lot nicer - can be removed once redrawRows is gone
      this.gridApi.clearFocusedCell();
    },

    toggleSisterProductSelectionRenderer(params) {
      // If its a master row, we want to show the expansion option for the sister products
      const { productKey, isSelected, parentKey, modellingType } = params.data;

      if (!parentKey) {
        const icon = this.expandedSisterRows.includes(productKey) ? 'up' : 'down';
        const iconElement = document.createElement('i');
        iconElement.classList.add('fa', `fa-chevron-${icon}`);
        iconElement.addEventListener('click', () => {
          this.toggleSisterProductRows(params);
          this.redrawRow(params.data.rowKey);
        });

        // don't render chevron icon for established products
        // as they are not allowed to have sister products selection in the UI
        return modellingType !== modellingTypes.established ? iconElement : null;
      }

      // Otherwise, we want to show the +/ - icon to toggle selection of that sister product

      const icon = isSelected ? 'minus-square-o' : 'plus-square-o';

      // Create actual element with the appropriate icon
      const iconElement = document.createElement('i');
      iconElement.setAttribute('id', `add-icon-${productKey}`);
      iconElement.classList.add('fa', `fa-${icon}`);

      if (this.notModelledProductsCount || this.isEditingDisabled) {
        iconElement.classList.add('not-editable');
      } else {
        // Initialise click handler
        iconElement.addEventListener('click', () => {
          const spUpdate = {
            [productKey]: {
              isSelected: !isSelected,
            },
          };

          // This is a bit of faff - we have to figure out whether the changes to the entire object
          // are different than the saved state. This can be refactored to be much more simple once
          // we add support for dynamic nested properties in currentStateDiff.
          const savedState = get(this.$options.savedState, [parentKey, 'sisterProducts'], {});
          const currentDiff = get(this.currentStateDiff, [parentKey, 'sisterProducts'], {});
          const currentDiffPlusChanges = merge({}, currentDiff, spUpdate);
          const allChanges = merge({}, savedState, currentDiff, spUpdate);

          if (isEqual(allChanges, savedState)) {
            // If all the changes are the same as the saved state, assign the saved state
            // This will result in an empty object in the diff
            this.updateCurrentState({
              productKey: parentKey,
              field: 'sisterProducts',
              currentValue: savedState,
            });
          } else {
            // If all the changes would result in a different value, merge on the latest changes to the exisitng changes
            // This means we only ever store the minimum changes.
            this.updateCurrentState({
              productKey: parentKey,
              field: 'sisterProducts',
              currentValue: currentDiffPlusChanges,
            });
          }
          // This sets the value in the grid data itself
          params.node.setDataValue('isSelected', !isSelected);
          const parentNode = this.keyedProductsData[parentKey];
          // update parent object based on saved state + latest changes
          parentNode.sisterProducts = allChanges;
          this.checkRowForErrors(parentNode);

          this.redrawRow(params.data.rowKey);
        });
      }
      return iconElement;
    },

    parseParams(params) {
      // Helper function - this can be used to get appropriate info from params for state updates
      const { productKey } = params.data;
      const { field } = params.colDef;
      const currentValue = get(params.data, params.colDef.field);

      return {
        productKey,
        field,
        currentValue,
      };
    },

    setAllInitialHeaders() {
      this.staticDataColumnChildren = [
        this.headers.availability,
        this.headers.transfer,
        this.headers.stores,
        this.headers.time,
      ];

      // Generate column groups for each cluster group, including unclustered
      const clusters = this.getClusterSchemes();
      const prodPerfChildren = [this.createUnclusteredColumn(), ...clusters];

      this.initialHeaders = [
        {
          headerName: '',
          children: [this.headers.expandSisterProductsIcon],
        },
        {
          headerName: this.$tkey('products'),
          children: [
            this.headers.inReset,
            this.headers.productType,
            this.headers.productKey,
            this.headers.itemDescription,
            this.headers.combinedUnitAndMeasure,
            this.headers.trendApplied,
          ],
        },
        {
          headerName: this.$tkey('productPerformance'),
          headerClass: 'border-left-data',
          children: prodPerfChildren,
        },
      ];
    },

    async modelProducts() {
      const payload = {
        scenarioId: this.selectedScenario._id,
      };
      // Just trigger the job here, update grid in watcher
      await this.runSetupProductModelling(payload);
    },

    getSisterProducts(data) {
      // We pull a single set of products from the db
      // Each product with sister products has an array of references to the productKey and fields like isSelected
      // We create the full dataset for the detail grid when you click expand on the master product

      const parentKey = data.productKey;
      // If we've previously added or modified products, we want to render the sister products with those changes
      // At this stage, we'll still just have isSelected, isToolGenerated and productKey
      const currentProductDiff = get(this.currentStateDiff, [parentKey, 'sisterProducts'], {});
      const sisterProductInfo = merge({}, data.sisterProducts, currentProductDiff);

      // Get all the sister product keys for this product
      const sisterProductKeys = map(sisterProductInfo, 'productKey');
      // Get all the other fields for this product - these are immutable in the detail grid
      const fullSisterProductData = pick(this.existingProductsByProductKey, sisterProductKeys);

      // Create full rows for those objects - merging on any existing changes and some helper properties
      // Return full rows of data plus any existing changes for that product's sister products
      return map(sisterProductInfo, sisterProduct => {
        const baseData = fullSisterProductData[sisterProduct.productKey];
        const currentDiff = get(
          this.currentStateDiff,
          [parentKey, 'sisterProducts', sisterProduct.productKey],
          {}
        );

        return merge({}, baseData, sisterProduct, currentDiff, {
          sisterProduct: true,
        });
      });
    },

    toggleSisterProductRows(params) {
      // Need to get current value - params can go stale and lead to weird errors
      const data = this.keyedProductsData[params.data.productKey];
      // If sister rows exist, remove them. Otherwise, add them.
      const sisterProducts = this.getSisterProducts(data);
      const { productKey: parentKey } = data;
      // add 1 as we want to insert after the parent row, not before.
      const indexOfParentRow =
        findIndex(this.productsData, p => p.productKey === parentKey && !p.parentKey) + 1;

      if (this.expandedSisterRows.includes(data.productKey))
        this.removeSisterProductRows(sisterProducts, data, indexOfParentRow);
      else this.addSisterProductRows(sisterProducts, data, indexOfParentRow);
      // -1 as we want to get the parent not the position where sisters are added
      this.checkRowForErrors(this.productsData[indexOfParentRow - 1]);

      // update state to reflect if it was removed or added
      this.expandedSisterRows = xor(this.expandedSisterRows, [data.productKey]);
    },

    addSisterProductRows(sisterProducts, parent, indexOfParentRow) {
      // We manually create and add rows to the grid.
      const { productKey: parentKey } = parent;

      // Add fields to identify the sister rows
      const fullSisterProducts = sisterProducts.map(p => {
        p.rowKey = `${parentKey}${this.separator}${p.productKey}`;
        p.parentKey = parentKey;
        return p;
      });

      // This creates the row that allows us to add sister products
      const addSisterProductRow = {
        parent,
        parentKey,
        productKey: this.addRowProductKey,
        itemDescription: ' ',
        rowKey: `${parentKey}${this.separator}${this.addRowProductKey}`,
        addSisterRow: true,
      };

      const add = [...fullSisterProducts, addSisterProductRow];

      // add the rows manually to that array
      this.productsData.splice(indexOfParentRow, 0, ...add);

      this.gridApi.setRowData(this.productsData);
    },

    removeSisterProductRows(sisterProducts, parent, indexOfParentRow) {
      // add 1 as we also remove the row for adding new sister products
      const rowsToRemove = size(sisterProducts) + 1;

      // remove the rows manually from that array
      this.productsData.splice(indexOfParentRow, rowsToRemove);

      this.gridApi.setRowData(this.productsData);
    },

    tooltipValueGetter(params) {
      const errors = [];
      if (agGridUtils.validations.valueIsNotNumericPermissive(params))
        errors.push(t('number', 'validationErrors'));
      if (agGridUtils.validations.valueIsLessOrEqual(params.value, 0))
        errors.push(this.$t('validationErrors.numberMustBeBiggerThan', [0]));

      if (agGridUtils.validations.valueIsNegative(params))
        errors.push(t('nonNegative', 'validationErrors'));
      // TooltipFeature added to cell if value "exists" (not null and not empty string) https://github.com/ag-grid/ag-grid/blob/v23.2.1/community-modules/core/src/ts/rendering/cellComp.ts#L221
      // TooltipFeature.showTooltip checks with !tooltip if value is showable https://github.com/ag-grid/ag-grid/blob/v23.2.1/community-modules/core/src/ts/widgets/tooltipFeature.ts#L171
      return errors.join('\n');
    },

    trendAppliedTooltipValueGetter(params) {
      const errors = [];
      if (agGridUtils.validations.valueIsNotNumericPermissive(params))
        errors.push(t('number', 'validationErrors'));
      if (agGridUtils.validations.valueIsLess(params.value, minTrendValue))
        errors.push(this.$t('validationErrors.numberMustBeBiggerOrEqual', [minTrendValue]));
      return errors.join('\n');
    },

    createUnclusteredColumn() {
      return {
        headerName: this.$tkey('unclustered'),
        headerClass: 'border-left-data unclustered-border-right',
        children: [
          {
            headerName: this.$tkey('data'),
            headerClass: 'unclustered-border-left column-divider',
            children: [
              ...this.staticDataColumnChildren,
              this.headers.salesConfidenceScorePerformancePeriod,
            ],
          },
          {
            headerName: this.$tkey('sales'),
            children: [this.headers.rateOfSale, this.headers.rateOfSaleTrendApplied],
          },
          {
            children: [this.headers.rateOfSaleOverride],
          },
          {
            children: [this.headers.emptyColumn],
            headerClass: 'unclustered-border-right',
          },
        ],
      };
    },

    createClusteredColumn(clusterName, clusterId) {
      const clusters = get(this.selectedScheme, 'clusters', []);
      // Check if this is the final item in the clustered array
      // if so then don't add a right border class as it is the final column - avoids duplicating borders.
      const isLastCluster = last(clusters).clusterId === clusterId;

      return {
        headerName: clusterName,
        headerClass: () => {
          return isLastCluster ? 'border-left-data' : 'border-left-data clustered-border-right';
        },
        children: [
          {
            headerName: this.$tkey('data'),
            headerClass: 'unclustered-border-left column-divider',
            children: [
              ...this.staticDataColumnChildren,
              {
                ...this.headers.salesConfidenceScorePerformancePeriod,
                field: `${clusterId}.salesConfidenceScorePerformancePeriod`,
                colId: `${clusterId}.salesConfidenceScorePerformancePeriod`,
                headerClass: 'column-divider',
                cellRenderer: params =>
                  params.data.parentKey ? '' : this.salesConfidenceScoreRenderer(params, clusterId),
              },
            ],
          },
          {
            headerName: this.$tkey('sales'),
            headerClass: 'clustered-border-right',
            cellClassRules: {
              'clustered-border-right': isLastCluster,
            },
            children: [
              // This is defined here as the field is dynamic based on the cluster
              {
                headerName: `${this.$tkey('predicted')} ${this.$tkey('unitPerWkStore', {
                  currency: this.requiredCurrency,
                })}`,
                colId: 'rateOfSale',
                field: `${clusterId}.rateOfSale`,
                filter: 'agTextColumnFilter',
                minWidth: 90,
                flex: 1,
                type: 'numericColumnCustom',
                cellClassRules: {
                  'diff-background': this.hasDiff,
                  'greyed-out-row': this.isROSGreyedOut || this.isRowGreyedOut,
                },
              },
              {
                ...this.headers.rateOfSaleTrendApplied,
                field: `${clusterId}.rateOfSaleTrendApplied`,
                colId: `${clusterId}.rateOfSaleTrendApplied`,
                headerClass: () => {
                  return isLastCluster ? 'font-italic' : 'font-italic clustered-border-right';
                },
                cellClass: () => {
                  return isLastCluster ? '' : 'clustered-border-right';
                },
                valueGetter: params => {
                  return rosUtils.calculateRoSWithTrend(
                    get(params.data, `${clusterId}.rateOfSale`),
                    params.data.trendApplied
                  );
                },
              },
            ],
          },
        ],
      };
    },

    getClusterSchemes() {
      // Get any clusters that exist for a selected cluster scheme
      const clusters = get(this.selectedScheme, 'clusters', []);

      // Use those clusters to initialise the cluster column group that renders on the table
      return clusters.map(({ clusterName, clusterId }) =>
        this.createClusteredColumn(clusterName, clusterId)
      );
    },

    setKeyIdMap() {
      // We use product keys to identify products in the grid but _id for updates to the DB
      // This lookup exists to help switch between those easily.
      this.keyIdMap = this.$options.productsData.reduce((a, c) => set(a, c.productKey, c._id), {});
    },

    discardChanges() {
      this.gridApi.showLoadingOverlay();

      // Reset to default selected view and revert products data to the originals
      this.selectedView = get(this.clientConfig, 'features.showProductModellingForNewProductsOnly')
        ? 'newProductsOnly'
        : 'allProducts';
      this.allProductsData = cloneDeep(this.$options.allProductsData);
      this.productsData = cloneDeep(this.$options.productsData);

      // Revert all modifications to sister products
      this.currentStateDiff = {};
      this.expandedSisterRows = [];
      this.changedSinceCalculation = [];
      this.errorData = {};
      this.gridApi.refreshCells({ columns: ['isSelected'], force: true });

      this.gridApi.hideOverlay();
      // identify errors
      each(this.productsData, this.checkRowForErrors);
    },

    async init() {
      this.isLoading = true;

      const [clusteredProductData] = await Promise.all([
        this.fetchScenarioProductsWithClusters(),
        this.fetchScenarioClusters(),
      ]);

      const dataWithKeys = clusteredProductData.map(p => set(p, 'rowKey', `${p.productKey}`));

      const { existingProducts, productsWithoutParents } = reduce(
        dataWithKeys,
        (acc, d) => {
          if (!d.isNewProduct) acc.existingProducts.push(d);
          if (!d.parentProduct) acc.productsWithoutParents.push(d);
          return acc;
        },
        { existingProducts: [], productsWithoutParents: [] }
      );

      // Existing products are accessed via a computed for getting sister product data
      this.existingProducts = existingProducts;
      this.allProductsData = cloneDeep(productsWithoutParents);

      // Set default selected view based on a feature flag from client config
      this.selectedView = get(this.clientConfig, 'features.showProductModellingForNewProductsOnly')
        ? 'newProductsOnly'
        : 'allProducts';

      // Based on the selected view, filter the products that should be displayed
      this.setProductsDataToDisplay();

      // identify errors on load
      each(this.productsData, this.checkRowForErrors);

      // currentStateDiff should always reset when you load new data as there can't be changes anymore
      this.currentStateDiff = {};
      // We keep a copy of the current dataset on $options so we can reset to it easily
      // $options isn't reactive so saves us on making large datasets reactive when we don't need to
      this.$options.productsData = cloneDeep(this.productsData);
      // We keep savedState as an object to help us diff changes quickly.
      this.$options.savedState = keyBy(this.$options.productsData, 'productKey');
      // New data means a new keyId map
      this.setKeyIdMap();

      this.isLoading = false;
    },

    handleCellEdit(params) {
      // Cell edits come as a specific param structure - we parse this out so we can
      // make updates that have other forms
      const parsedParams = this.parseParams(params);
      this.updateCurrentState(parsedParams);
      this.validateGrid();
    },

    updateCurrentState(params) {
      if (params.field === 'isSelected') {
        // sister product rows are the only ones that modify this field - their updates shouldn't be stored
        return;
      }
      const { productKey, field, currentValue } = params;
      const headerDef = get(this.headers, field);
      if (get(headerDef, 'type') === 'numericColumnCustom' && !isEmpty(currentValue)) {
        if (
          !isNumber(currentValue) ||
          (headerDef.field === 'trendApplied' && isNumber(currentValue) && currentValue <= 0)
        ) {
          return;
        }
      }

      // If the rows been updated, it needs a calculation
      this.changedSinceCalculation.push(productKey);
      this.changedSinceCalculation = uniq(this.changedSinceCalculation);

      const path = `${productKey}.${field}`;
      const originalValue = get(this.$options.savedState, path);

      const valuesAreEqual = isEqual(currentValue, originalValue);
      const existingUpdate = get(this.currentStateDiff[productKey], field);
      if (!valuesAreEqual) {
        if (!this.currentStateDiff[productKey]) this.$set(this.currentStateDiff, productKey, {});
        this.$set(this.currentStateDiff[productKey], field, currentValue);
        return;
      }

      // Check that the values are equal and that an existingUpdate exists. An existingUpdate of null is a valid case
      // due to a removed override being sent through as 'null'.
      if (valuesAreEqual && (existingUpdate || existingUpdate === null)) {
        // Remove the field from the object if it's back to its old value.
        // Note: This is only triggered on a change so entering 1 in a cell that already contains 1 triggers nothing.
        this.$delete(this.currentStateDiff[productKey], field);
        if (isEmpty(this.currentStateDiff[productKey])) {
          // No more changes on this object - can't need a calculation.
          this.changedSinceCalculation = without(this.changedSinceCalculation, productKey);
          // If there's nothing left in the object, remove it.
          this.$delete(this.currentStateDiff, productKey);
        }
      }
    },

    validateGrid() {
      if (!this.gridOptions.api) return;
      this.validationErrors = [];
      const validationRules = {};
      Object.keys(this.headers).forEach(key => {
        const colConfig = this.headers[key];
        const type = get(colConfig, 'type');
        if (type) {
          validationRules[colConfig.field || key] = type;
        }
      });
      this.gridOptions.api.forEachNode(node => {
        const { data } = node;
        const validationFields = intersection(Object.keys(data), Object.keys(validationRules));
        validationFields.forEach(key => {
          if (validationRules[key] === 'numericColumnCustom') {
            const currentValue = data[key];
            if ((key !== 'trendApplied' && !currentValue) || isNull(currentValue)) return;
            if (
              (key === 'trendApplied' && isNumber(currentValue) && currentValue < minTrendValue) ||
              (String(currentValue).length && !isNumber(currentValue))
            ) {
              this.validationErrors.push({
                field: key,
                value: currentValue,
                _id: data._id,
              });
            }
          }
        });
      });
    },

    hasDiff(params) {
      const { productKey } = params.data;
      const { field } = params.colDef;
      const path = `${productKey}.${field}`;

      const currentValue = get(params.data, params.colDef.field);
      const originalValue = get(this.$options.savedState, path);

      // If it has no saved value, it's not different
      if (isUndefined(originalValue)) return false;

      return agGridUtils.comparators.didValueChange(currentValue, originalValue);
    },

    sisterHasDiff(params) {
      const { productKey, parentKey } = params.data;
      return !parentKey && get(this.currentStateDiff, `${productKey}.sisterProducts`);
    },

    formatUpdatesForSave() {
      // We have to update two separate collections, scenario-products and cluster-scenario-products
      // Updates for each cluster are scoped on each product by their clusterId
      // Separate properties based on that

      const clusterIds = this.clusterIdsInScheme;
      const scenarioProperties = ['rateOfSaleOverride', 'trendApplied', 'rateOfSale'];

      return reduce(
        this.currentStateDiff,
        (acc, product, productKey) => {
          const clusterUpdates = pick(product, clusterIds);
          // Each cluster is a separate update, handle that here
          const separatedClusterUpdates = map(clusterUpdates, (update, clusterId) => ({
            clusterId,
            scenarioId: this.selectedScenario._id,
            clusterSchemeId: this.selectedScheme._id,
            productKey: toNumber(productKey),
            rateOfSale: update.rateOfSale,
          }));

          const scenarioProductUpdate = pick(product, scenarioProperties);
          // We need the id for saving on the server
          scenarioProductUpdate._id = this.keyIdMap[productKey];

          // We replace the entire sisterProducts object, so this is constructed from savedState + our changes
          const sisterProducts = values(
            merge({}, this.$options.savedState[productKey].sisterProducts, product.sisterProducts)
          );
          scenarioProductUpdate.sisterProducts = sisterProducts;

          acc.scenarioProductUpdates.push(scenarioProductUpdate);
          acc.clusteredProductUpdates.push(...separatedClusterUpdates);
          return acc;
        },
        {
          scenarioProductUpdates: [],
          clusteredProductUpdates: [],
        }
      );
    },

    async onSave(commit = false) {
      // Perform dependency tree check
      const results = await this.triggerDependencyTree({
        params: { change: 'productModellingModified', updates: {}, commit },
      });

      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return;
      }

      this.saveChanges();
    },

    async saveChanges() {
      // Always re-run calculations prior to saving
      const successfulCalculation = await this.runCalculations();
      if (!successfulCalculation) return; // Error will already have been displayed
      const { clusteredProductUpdates, scenarioProductUpdates } = this.formatUpdatesForSave();
      try {
        this.gridApi.showLoadingOverlay();

        // Save changes
        await Promise.all([
          this.saveScenarioProducts({
            products: scenarioProductUpdates,
            addedCustomAttributes: [],
            deletedCustomAttributes: [],
            deletedProducts: [],
            scenarioId: this.selectedScenario._id,
          }),
          this.updateClusteredScenarioProducts(clusteredProductUpdates),
        ]);

        // Re-fetch required products
        await this.updateDataAndGrid();

        this.showSuccess(this.$tkey('actions.saveSuccess'));
      } catch (e) {
        console.error(e);
        this.showWarning();
      }
      this.gridApi.hideOverlay();
    },

    async updateDataAndGrid() {
      // Once saved, we pull back the new data and update the grid
      // ag-grid will handle computing the differences between rows but we only pull back top-level products
      // All the expanded sister product state needs to be persisted.
      // To do this, we map over the current grid and replace any of the top-level data, leaving the expanded rows

      // Get all the new data for this page
      const newData = await this.fetchScenarioProductsWithClusters();
      const dataWithRowKey = newData.map(p => set(p, 'rowKey', `${p.productKey}`));

      const { existingProducts, productsWithoutParents } = reduce(
        dataWithRowKey,
        (acc, d) => {
          if (!d.parentProduct) acc.productsWithoutParents.push(d);
          if (!d.isNewProduct) acc.existingProducts.push(d);
          return acc;
        },
        { existingProducts: [], productsWithoutParents: [] }
      );

      // Existing products are accessed via a computed for getting sister product data
      this.existingProducts = existingProducts;

      // the new data from the DB is our saved state now - this is what we reset to
      this.$options.productsData = cloneDeep(productsWithoutParents);

      // merge any changes onto whatever the previous saved state was
      // Will need to pass flat array of changes here to update
      this.$options.savedState = keyBy(this.$options.productsData, 'productKey');

      // KeyIdMap should update now our background data has changed
      this.setKeyIdMap();

      const updatedRowKeys = new Set(map(dataWithRowKey, 'productKey'));

      this.productsData = map(this.productsData, product => {
        if (product.parentKey) return product; // only the actual products need to be updated

        return updatedRowKeys.has(product.productKey)
          ? merge({}, this.$options.savedState[product.productKey])
          : product;
      });

      // Identify errors on load
      each(this.productsData, this.checkRowForErrors);

      // Apply the new data to the grid
      this.gridApi.setRowData(this.productsData);

      const modifiedRows = Object.keys(this.currentStateDiff).map(key =>
        this.gridApi.getRowNode(key)
      ); // get the current objects in the table

      // Redraw rows with updated products to see changes
      this.gridApi.redrawRows({ rowNodes: modifiedRows });
      this.gridApi.refreshCells({ columns: ['trendApplied', 'rateOfSaleOverride'], force: true });

      // We don't have any differences from our saved state now so reset this
      this.currentStateDiff = {};
    },

    doesExternalFilterPass(node) {
      const productKeyDisplay = get(node.data, 'productKeyDisplay');
      const includedAssortment = get(node.data, 'assortment');
      const isToolGenerated = get(node.data, 'isToolGenerated', undefined);
      const isNewProduct = get(node.data, 'isNewProduct');

      // Hide Products that are not included to Assortment but show if it's a child sister product
      // Temporary feature flag to hide the products not included to assortment
      if (
        this.hideProductsNotListedToAssortment &&
        !includedAssortment &&
        !isNewProduct &&
        productKeyDisplay &&
        isUndefined(isToolGenerated)
      )
        return false;

      if (this.filterInvalidRows) {
        return !!this.errorData[node.data.productKey] || !!this.errorData[node.data.parentKey];
      }

      if (this.filterWarningRows) {
        return !!this.warningData[node.data.productKey] || !!this.warningData[node.data.parentKey];
      }

      const itemDescription = get(node.data, 'itemDescription');

      if (!productKeyDisplay || !itemDescription) return true;
      if (isEmpty(this.searchString)) return true;

      const isInProductKeyDisplay = String(productKeyDisplay).indexOf(this.searchString) !== -1;
      const isInDescription = itemDescription.indexOf(this.searchString) !== -1;
      if (isInProductKeyDisplay || isInDescription) return true;

      // also handle possible main products -> sister products relationship,
      // e.g. for all main rows also include all of its sister products into filter results
      const mainProduct = this.keyedProductsData[node.data.parentKey];
      const mainRowProductKeyDisplay = get(mainProduct, 'productKeyDisplay', '');
      const mainRowItemDescription = get(mainProduct, 'itemDescription', '');
      const isInMainRowProductKeyDisplay =
        String(mainRowProductKeyDisplay).indexOf(this.searchString) !== -1;
      const isInMainRowItemDescription = mainRowItemDescription.indexOf(this.searchString) !== -1;
      return isInMainRowProductKeyDisplay || isInMainRowItemDescription;
    },

    applyTrend() {
      const updates = [];
      const trendOverride = Number(this.trendOverride);
      this.gridOptions.api.forEachNodeAfterFilterAndSort(rowNode => {
        const { productKey } = rowNode.data;
        if (get(rowNode, 'data.parentKey')) return;

        const data = rowNode.data;
        data.trendApplied = trendOverride;
        this.updateCurrentState({
          productKey,
          field: 'trendApplied',
          currentValue: trendOverride,
        });
        updates.push(data);
      });
      this.validateGrid();
      this.gridApi.applyTransaction({ update: updates });
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      if (!this.gridOptions.columnDefs) this.gridApi.setColumnDefs(this.initialHeaders);
    },

    availabilityCellRenderer(params) {
      // don't add availability icon if row is only used for adding new sister products
      if (params.data.addSisterRow) return;

      const img = document.createElement('img');
      img.classList.add('availability-icon');
      img.src = availabilityIcon;
      return img;
    },

    isRoSOverrideUnderlined(params) {
      return (
        !this.notModelledProductsCount &&
        !params.data.parentKey &&
        params.data.modellingType !== modellingTypes.established
      );
    },

    isRowGreyedOut(params) {
      if (this.isEditingDisabled) return true;

      return params.data.parentKey && !params.data.isSelected && !params.data.addSisterRow;
    },

    isROSGreyedOut(params) {
      const { trendApplied } = params.data;
      // Grey out ROS and ROS override columns if trendApplied is is set and not zero
      return isNumber(trendApplied) && trendApplied !== 0;
    },

    toggleInvalidRows() {
      this.filterInvalidRows = !this.filterInvalidRows;
      this.onFilterChanged();
    },

    toggleWarningRows() {
      this.filterWarningRows = !this.filterWarningRows;
      this.onFilterChanged();
    },

    checkRowForErrors(row) {
      // early exit for add sister product selects (fake rows)
      if (row.addSisterRow) return true;

      // Temporary feature flag to hide the products not included to assortment
      if (
        this.hideProductsNotListedToAssortment &&
        !row.assortment &&
        !row.isNewProduct &&
        row.productKeyDisplay &&
        isUndefined(row.isToolGenerated)
      )
        return true;

      // sisterProductsTolerance - is expected to be in %
      const sisterProductsTolerance = get(this.clientConfig, 'sisterProductsTolerance') / 100;
      const nonEstablishedProductHasNoSisProds =
        row.modellingType !== modellingTypes.established &&
        !some(row.sisterProducts, sp => sp.isSelected);

      if (nonEstablishedProductHasNoSisProds) {
        this.$set(this.errorData, row.productKey, true);
      } else {
        this.$delete(this.errorData, row.productKey);
      }

      const selectedSisterProducts = filter(row.sisterProducts, sp => sp.isSelected);
      // If sister rows exist, remove them. Otherwise, add them.
      const sisterProducts = this.getSisterProducts(row);

      // Check if there are any sister products which KPIs are outside of tolerance.
      // If there are any that would trigger UI warning
      const differentSisterProducts = filter(sisterProducts, sp => {
        if (!row.contentValue) row.contentValue = 0;
        if (!sp.contentValue) sp.contentValue = 0;
        sp.contentUnitOfMeasureAdjusted = sp.contentUnitOfMeasure;

        // Check if products' UoMs are convertable between master and sister product.
        // If so, perform the conversion.
        // TODO we can also refine this in the future to check against list of possible strings, like ‘g', 'gr', 'gram’ etc.
        const canBeConvertedDown =
          (lowerCase(row.contentUnitOfMeasure) === 'kg' &&
            lowerCase(sp.contentUnitOfMeasure) === 'g') ||
          (lowerCase(row.contentUnitOfMeasure) === 'l' &&
            lowerCase(sp.contentUnitOfMeasure) === 'ml');

        const canBeConvertedUp =
          (lowerCase(row.contentUnitOfMeasure) === 'g' &&
            lowerCase(sp.contentUnitOfMeasure === 'kg')) ||
          (lowerCase(row.contentUnitOfMeasure) === 'ml' &&
            lowerCase(sp.contentUnitOfMeasure) === 'l');

        if (canBeConvertedDown) {
          sp.contentValue /= 1000;
          sp.contentUnitOfMeasureAdjusted = row.contentUnitOfMeasure;
        }
        if (canBeConvertedUp) {
          sp.contentValue *= 1000;
          sp.contentUnitOfMeasureAdjusted = row.contentUnitOfMeasure;
        }

        const contentValueTolerance = row.contentValue * sisterProductsTolerance;
        const rateOfSaleTolerance = row.rateOfSale * sisterProductsTolerance;

        // Check if sister product's content value and RoS are within the tolerance limits.
        // The tolerance multiplier comes from client config.
        // Low limit is difference between master row KPI and its KPI with tolerance applied (0.8 of original KPI by default).
        // High limit is sum of master row KPI and its KPI with tolerance applied (1.2 of original KPI by default).
        const isSisterContentValueWithinLimits =
          row.contentValue - contentValueTolerance <= sp.contentValue &&
          sp.contentValue <= row.contentValue + contentValueTolerance;

        const isSisterRateOfSaleWithinLimits =
          row.rateOfSale - rateOfSaleTolerance <= sp.rateOfSale &&
          sp.rateOfSale <= row.rateOfSale + rateOfSaleTolerance;

        return (
          sp.isSelected &&
          (sp.contentUnitOfMeasureAdjusted !== row.contentUnitOfMeasure ||
            !isSisterContentValueWithinLimits ||
            !isSisterRateOfSaleWithinLimits)
        );
      });

      if (selectedSisterProducts.length === 1) {
        if (differentSisterProducts.length) this.$set(this.warningData, row.productKey, true);
      } else {
        this.$delete(this.warningData, row.productKey);
      }
      // turn off filter if you've removed all invalid rows
      if (isEmpty(this.errorData) && this.filterInvalidRows) this.toggleInvalidRows();

      // turn off filter if warnings were removed
      if (isEmpty(this.warningData) && this.filterWarningRows) this.toggleWarningRows();
    },

    salesConfidenceScoreRenderer(params, clusterId) {
      const path = clusterId
        ? `data.${clusterId}.salesConfidenceScorePerformancePeriod`
        : 'data.salesConfidenceScorePerformancePeriod';
      const score = get(params, path, 0);
      const iconColour = this.calcSalesConfidenceScoreColour(score, clusterId);
      const span = document.createElement('span');
      span.classList.add('circle-icon', `circle-icon--${iconColour}`);
      return span;
    },

    calcSalesConfidenceScoreColour(score, clusterId) {
      if (inRange(score, 0.33, 0.66)) return scsColours.RED;

      if (clusterId) {
        if (inRange(score, 0.66, 0.9)) return scsColours.ORANGE;
        if (score >= 0.9) return scsColours.GREEN;
      } else {
        if (inRange(score, 0.66, 1)) return scsColours.ORANGE;
        if (score >= 1) return scsColours.GREEN;
      }
      return scsColours.GREY;
    },

    async initSetupProductModelling(commit = false) {
      this.calledFromInitSetupProductModelling = true; // for dependency tree modal
      this.gridApi.showLoadingOverlay();

      try {
        // Perform dependency tree check
        const results = await this.triggerDependencyTree({
          params: { change: 'productModellingModified', updates: {}, commit },
        });

        if (results.needsFeedback) {
          this.dependencyTreeFeedback = results.output;
          this.dependencyTreeModalOpen = true;
          this.gridApi.hideOverlay();
          return;
        }
        await this.modelProducts();
        await this.loadScenario(this.selectedScenario._id);
      } catch (e) {
        console.error(e);
      }

      this.gridApi.hideOverlay();
    },

    resetAll(params) {
      if (this.isEditingDisabled) return;
      const colId = params.column.colId;
      const updates = [];

      this.gridApi.forEachNodeAfterFilter(rowNode => {
        const { productKey, parentKey } = rowNode.data;
        // Only apply updates for parent rows
        if (parentKey) return;
        const originalValue = get(this.$options.savedState, [productKey, colId], null);
        const data = rowNode.data;
        data[colId] = originalValue;
        updates.push(data);
        // Remove from currentStateDiff as change will be reset to original state
        this.updateCurrentState({
          productKey,
          field: colId,
          currentValue: originalValue,
        });
      });

      this.validateGrid();
      this.gridApi.applyTransaction({ update: updates });
    },

    isResetAllDisabled(params) {
      return (
        this.isEditingDisabled ||
        (!this.modifiedColumns.has(params.column.colId) &&
          !this.columnsWithValidationErrors.has(params.column.colId))
      );
    },

    overrideValueFormatter(params) {
      return ![null, undefined, ''].includes(params.value)
        ? agGridUtils.formatters.roundFormatter(params)
        : params.value;
    },

    getPotentialSisterProductsList(product) {
      const eligibleSisterProductsSet = new Set([]);

      // Valid options for sister products are:
      // - Within the same cann group as the original product.
      // - Not a child product (value of parentArticle for the product is null)
      // - Not a new product
      // - Not discontinued
      const { sisterProducts, productKey: parentKey } = product;
      const { cannGroupId } = this.keyedProductsData[product.productKey];
      // all product keys currently in the diff - this includes newly added ones
      const diffSisterProducts = map(
        get(this, `currentStateDiff.${parentKey}.sisterProducts`, {}),
        'productKey'
      );

      const existingSisterProductKeys = map(sisterProducts, 'productKey');
      // sister products here will include the row's original sister products
      const existingSisterProducts = new Set([...existingSisterProductKeys, ...diffSisterProducts]);

      // Mark non eligible products (soft ineligible - so should be visible to the user)
      const productsList = cloneDeep(this.existingProducts);
      productsList.forEach(prod => {
        // Add the product key to the start of the description
        prod.itemDescription = `${prod.productKeyDisplay} - ${prod.itemDescription}`;

        // Must be in the same cann group
        if (prod.cannGroupId !== cannGroupId) {
          prod.disabled = true;
          prod.itemDescription = `${prod.itemDescription} (${this.$tkey(
            'notAddableProducts.notSameCannGroup'
          )})`;
        }

        // Must be an existing product
        else if (prod.isNewProduct === true) {
          prod.disabled = true;
          prod.itemDescription = `${prod.itemDescription} (${this.$tkey(
            'notAddableProducts.isNewProduct'
          )})`;
        }

        // Product can't be a child product
        else if (get(prod, 'parentProduct', null)) {
          prod.disabled = true;
          prod.itemDescription = `${prod.itemDescription} (${this.$tkey(
            'notAddableProducts.isChildProduct'
          )})`;
        }

        // Product must not already be a sister product
        else if (existingSisterProducts.has(prod.productKey)) {
          prod.disabled = true;
          prod.itemDescription = `${prod.itemDescription} (${this.$tkey(
            'notAddableProducts.existingSisterProduct'
          )})`;

          // such product should still be eligible, just cannot be re-added via dropdown
          eligibleSisterProductsSet.add(prod.productKey);
        }

        // Product must have been tagged as eligible to be sister product by the analytics
        else if (!prod.isEligibleToBeSister) {
          prod.disabled = true;
          prod.itemDescription = `${prod.itemDescription} (${this.$tkey(
            'notAddableProducts.notEligible'
          )})`;
        } else {
          eligibleSisterProductsSet.add(prod.productKey);
        }
      });

      // Sort the possible sisters, to show valid ones first, then invalid
      const productsEnabledToBeSisterProducts = productsList.sort((a, b) => {
        if (a.disabled === b.disabled) {
          return a.itemDescription.localeCompare(b.itemDescription);
        }
        if (a.disabled && !b.disabled) return 1;
        return -1;
      });

      return {
        eligibleProductSet: eligibleSisterProductsSet,
        allProductList: productsEnabledToBeSisterProducts,
      };
    },

    removeInvalidSisterProductsCopiedFromDecisions(rawUpdates) {
      const validUpdates = [];
      const removedDecisions = [];
      rawUpdates.forEach(update => {
        const isOnlyUpdatingSisterProducts = isEqual(Object.keys(update).sort(), [
          'productKey',
          'sisterProducts',
        ]);
        const { eligibleProductSet: allowedSisterProducts } = this.getPotentialSisterProductsList(
          this.keyedProductsData[update.productKey]
        );
        const validSisterProducts = filter(update.sisterProducts, sp =>
          allowedSisterProducts.has(sp.productKey)
        );
        const hasValidSisProds = validSisterProducts.length > 0;
        const newUpdate = omit(update, ['sisterProducts']);
        // only update the sister products if there are incoming changes for it
        if (hasValidSisProds) {
          newUpdate.sisterProducts = keyBy(validSisterProducts, 'productKey');
        } else if (isOnlyUpdatingSisterProducts) {
          removedDecisions.push(this.keyedProductsData[update.productKey].productKeyDisplay);
        }

        if (!isOnlyUpdatingSisterProducts || (isOnlyUpdatingSisterProducts && hasValidSisProds)) {
          validUpdates.push(newUpdate);
        }
      });

      return { updates: validUpdates, removedDecisions };
    },

    getProductState(productData) {
      const {
        toolGenerated,
        userGenerated,
        newProduct,
        semiNewProduct,
        existingProduct,
        romProduct,
      } = modellingTypeOptions;
      if (productData.isToolGenerated === true) return toolGenerated;
      if (productData.isToolGenerated === false) return userGenerated;
      if (productData.fromRestOfMarket) return romProduct;
      const isSemiNewProduct =
        !productData.isNewProduct && productData.originSource === originSourceTypes.newInTemplate;
      if (this.hasProductsExistingFromNewInTemplateEnabled && isSemiNewProduct)
        return semiNewProduct;
      return productData.isNewProduct ? newProduct : existingProduct;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .clustered-border-right:not(:last-child),
  .column-divider:not(:last-child) {
    border-right: $assortment-divider-colour-darker 1px solid !important;
  }
  .unclustered-border-right {
    border-right: $assortment-menu-border-colour 4px solid !important;
  }

  .unclustered-border-left:nth-of-type(2) {
    border-left: $assortment-menu-border-colour 1px solid !important;
  }

  .ag-row {
    border-bottom-width: 1px !important;
    border-bottom-color: $assortment-menu-border-colour !important;
    &.border-top-selected {
      border-top: 1px solid $assortment-menu-border-colour !important;
    }
  }

  .ag-cell {
    &.centered {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.no-focus-padding {
      &.ag-cell.ag-cell-not-inline-editing.ag-cell-value.ag-cell-focus {
        padding-left: 1px !important;
      }
    }
    &.not-editable,
    i.not-editable::before {
      color: $assortment-table-not-editable-color !important;
      pointer-events: none;
    }

    &.ag-cell-not-inline-editing {
      &.underlined-editable {
        border-bottom: 2px solid $assortment-primary-colour;
        border-left-width: 1px !important;
        border-right-width: 1px !important;
        padding-left: 5px !important;

        &.ag-cell-focus {
          padding-bottom: 1px !important;
        }
      }
    }

    &.ag-cell-inline-editing.ag-cell-value.ag-cell-focus {
      &.underlined-editable {
        padding-bottom: 1px !important;
      }
    }

    &.padding-left-avail {
      &.ag-cell-not-inline-editing.ag-cell-value {
        &.ag-cell-focus {
          padding-left: 20px !important;
        }
      }
    }
  }

  .ag-header-group-cell-with-group {
    &.border-left-data {
      .ag-header-group-cell-label {
        border-left-width: 5px;
      }
    }
  }

  .ag-theme-custom {
    .ag-header-cell-label {
      padding-bottom: 5px !important;
    }
    .ag-header-cell {
      &.border-left-data {
        border-left-width: 20px;
      }
    }
    .ag-ltr {
      .ag-cell {
        &.border-left-data {
          border-left-width: 20px;
        }
      }
    }

    .ag-header {
      border-bottom-color: $assortment-menu-border-colour !important;
    }
    .ag-pinned-left-header {
      border-right-color: $assortment-menu-border-colour !important;
    }
    .ag-pinned-right-header {
      border-left-color: $assortment-menu-border-colour !important;
    }
    .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
      border-right-color: $assortment-menu-border-colour !important;
    }
    .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
      opacity: 1 !important;
      border-left-color: $assortment-menu-border-colour !important;
    }

    .ag-row {
      .editable-input {
        border: none !important;
        border-left: 5px solid transparent !important;
        padding-top: 2px;

        &.ag-cell-border-right {
          border-right: 1px solid $assortment-menu-border-colour !important;
        }

        &.ag-cell-focus {
          border: none;
        }
      }
    }

    .ag-cell {
      padding-left: 0;

      &.ag-cell-not-inline-editing.ag-cell-value.ag-cell-focus {
        padding-left: 0 !important;
      }
    }
  }

  .ag-header-expand-icon {
    margin-top: 1px;
  }

  .greyed-out-row {
    opacity: 0.15 !important;
  }

  .greyed-out-row {
    &.ag-cell-last-left-pinned,
    &.unclustered-border-right,
    &.clustered-border-right,
    &.ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
      opacity: 1 !important;
      background: rgba(255, 255, 255, 0.15);
      color: rgba(0, 0, 0, 0.15);
    }
  }

  .unclustered-border-right {
    &.ag-cell-focus {
      border-top: 1px solid $assortment-ag-grid-focus-colour !important;
    }

    border-top: none !important;
    &.underlined-editable {
      border-top: 1px solid transparent !important;
    }
    &:not(.ag-cell-focus) {
      &:not(.underlined-editable) {
        border-bottom: none !important;
      }
    }
  }

  .fa-chevron-down,
  .fa-chevron-up,
  .fa-minus-square-o,
  .fa-plus-square-o {
    cursor: pointer;
    &::before {
      color: $assortment-primary-colour;
    }
    &.not-editable {
      cursor: default;
    }
  }

  .fa-chevron-down,
  .fa-chevron-up {
    &::before {
      font-size: 15px;
    }
  }

  .fa-minus-square-o,
  .fa-plus-square-o {
    &::before {
      font-size: 18px;
    }
  }

  .fa-chevron-down,
  .fa-chevron-up {
    margin-bottom: 1px;
  }

  .hide-value-inside-caret-class {
    display: flex;
    justify-content: center;

    .ag-group-value {
      display: none;
    }
  }

  .availability-icon {
    margin-left: -4px;
  }

  .no-ag-icon-menu {
    .ag-icon-menu {
      display: none;
    }
  }
}

.v-alert {
  font-size: 13px;
  padding: 10px;
}

.no-cdt,
.not-modelled {
  color: $assortment-span-error-colour;
  font-weight: bold;
}
</style>
