<template>
  <div class="report-input-item">
    <slot name="title">
      <h3 class="label-input mb-2">{{ title }}</h3>
    </slot>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: () => '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.label-input {
  font-size: 1.3rem;
}
</style>
