<template>
  <v-dialog
    id="switchingModellingMatricesModal"
    data-id-e2e="switchingModellingMatricesModalDialog"
    :value="value"
    fullscreen
    content-class="switching-modelling-matrices-modal"
    attach=".switching-modelling-page"
    @click:outside="closeModal"
    @keydown.esc="closeModal"
  >
    <dialog-card :title="$tkey('title')" @close="closeModal">
      <div class="switching-modelling-matrices-container">
        <div class="container-block flex-grow-1">
          <progress-bar v-if="isLoading" />
          <div v-else-if="gridData">
            <v-row class="d-flex align-center">
              <v-col cols="3">
                <div>
                  <b>{{ $t('switchingModellingPage.switchingMetricsBreakdownModal.cannGroup') }}</b>
                  <span>{{ gridData.cannGroupName }}</span>
                </div>
                <div>
                  <b>{{ $t('switchingModellingPage.switchingMetricsBreakdownModal.cluster') }}</b>
                  <span>{{ gridData.clusterName }}</span>
                </div>
                <div>
                  <b>{{
                    $t(
                      'switchingModellingPage.switchingMetricsBreakdownModal.switchingAnalysisMethodology'
                    )
                  }}</b>
                  <span>
                    {{ $t(modalData.methodologyInPlace) }} {{ modalData.similarityBias }}
                  </span>
                </div>
              </v-col>
              <v-col>
                <v-radio-group v-model="selectedSwitchingView" row @change="drawGraph">
                  <v-radio
                    :label="$tkey('asModelled')"
                    :ripple="false"
                    :value="switchingViews.modelled"
                    class="mb-3 mt-3"
                  />
                  <v-radio
                    :label="$tkey('originalSwitching')"
                    :ripple="false"
                    :value="switchingViews.original"
                    class="mb-3 mt-3"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <switching-heatmap-grid :key="selectedViewKey" :grid-data="gridData" />
          </div>
        </div>
      </div>

      <template v-slot:footer>
        <v-btn primary depressed @click="onExport">
          {{ $t('actions.export') }}
          <v-icon right>import_export</v-icon>
        </v-btn>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { keyBy, cloneDeep, isEqual } from 'lodash';
import { mapState, mapActions } from 'vuex';
import switchingReport from '@enums/switching-report';
import switchingMatrixMixin from '@/js/mixins/switching-matrix';
import downloadFileMixin from '@/js/mixins/download-file';

export default {
  localizationKey: 'switchingModellingPage.switchingModellingMatricesModal',
  mixins: [switchingMatrixMixin, downloadFileMixin],

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    modalData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      switchingReport,
      gridData: null,
      switchingMatrix: null,
      stepSize: 5,
      isLoading: false,
      switchingViews: {
        original: 'original',
        modelled: 'modelled',
      },
      selectedSwitchingView: 'modelled',
    };
  },

  computed: {
    ...mapState('switchingMatrices', ['switchingMatrixForCannGroup']),
    ...mapState('scenarioProducts', ['scenarioProducts']),
    ...mapState('scenarios', ['selectedScenario']),

    productsMap() {
      return keyBy(this.scenarioProducts, 'productKey');
    },

    // Swap switching heatmap component key to redraw the component completely based on view
    selectedViewKey() {
      return isEqual(this.selectedSwitchingView, this.switchingViews.original) ? 0 : 1;
    },
  },

  async created() {
    await this.getSwitchingMatrix();
    this.drawGraph();
  },

  methods: {
    ...mapActions('switchingMatrices', [
      'fetchCannGroupSwitchingMatrix',
      'exportSwitchingCannGroup',
    ]),
    ...mapActions('scenarioProducts', ['fetchScenarioProducts']),
    ...mapActions('snackbar', ['showWarning']),

    async getSwitchingMatrix() {
      await this.fetchCannGroupSwitchingMatrix({
        params: {
          scenarioId: this.selectedScenario._id,
          cannGroupId: this.modalData.cannGroupId,
          clusterId: this.modalData.clusterId,
        },
      });
    },

    async drawGraph() {
      this.isLoading = true;
      if (this.switchingMatrixForCannGroup.errors) {
        return this.showWarning(this.$t('switchingBehaviour.emptyMatrix'));
      }
      this.switchingMatrix = this.switchingMatrixForCannGroup.data;

      // If selected switching view is original, take the original matrix and product keys
      // Otherwise, take current matrix and product keys (currently modelled)
      const matrixData = isEqual(this.selectedSwitchingView, this.switchingViews.original)
        ? this.switchingMatrix.original.matrix
        : this.switchingMatrix.matrix;

      const productKeys = isEqual(this.selectedSwitchingView, this.switchingViews.original)
        ? this.switchingMatrix.original.productKeys
        : this.switchingMatrix.productKeys;

      this.gridData = {
        data: this.prepareDataSet(cloneDeep(matrixData), productKeys),
        labels: productKeys,
        ...this.getGridOptions(),
      };
      this.isLoading = false;
    },

    getGridOptions() {
      return {
        headersMap: this.productsMap,
        displayOption: 'product',
        class: 'full-height-heatmap',
        switchingModelling: true,
        cannGroupName: this.modalData.cannGroupName,
        clusterName: this.modalData.clusterName,
      };
    },

    closeModal() {
      this.$emit('close-modal');
    },

    async onExport() {
      const level = isEqual(this.selectedSwitchingView, this.switchingViews.original)
        ? switchingReport.originalProductSwitching
        : switchingReport.modelledProductSwitching;

      const options = {
        type: level,
        selectedAttribute: this.selectedAttribute,
        translations: {
          metadata: this.$t('switchingBehaviour.excel.metadata'),
          workpackageName: this.$t('switchingBehaviour.excel.workpackageName'),
          scenarioName: this.$t('switchingBehaviour.excel.scenarioName'),
          extractTime: this.$t('switchingBehaviour.excel.extractTime'),
          user: this.$t('switchingBehaviour.excel.user'),
          products: this.$t('switchingBehaviour.excel.products'),
          cannGroup: this.$t('switchingBehaviour.excel.cannGroup'),
          cannGroups: this.$t('switchingBehaviour.excel.cannGroups'),
          productIdentifier: this.$t('switchingBehaviour.excel.productIdentifier'),
          tab: this.$t('switchingBehaviour.excel.tab'),
          overview: this.$t('switchingBehaviour.excel.overview'),
          description: this.$t('switchingBehaviour.excel.description'),
          attribute: this.$t('switchingBehaviour.excel.attribute'),
          rank: this.$t('switchingBehaviour.excel.rank'),
          probability: this.$t('switchingBehaviour.excel.probability'),
          newProductsNotIncludedNote: this.$t(
            'switchingBehaviour.excel.newProductsNotIncludedNote'
          ),
          originalSwitching: this.$tkey('originalSwitching'),
          modelledSwitching: this.$tkey('asModelled'),
          unclustered: this.$t('reportingPage.unclustered'),
          cluster: this.$t('mongoFields.cluster'),
        },
        clusterId: this.modalData.clusterId,
      };

      const { buffer, filename } = await this.exportSwitchingCannGroup({
        params: options,
        cannGroupId: this.modalData.cannGroupId,
      });
      this.downloadFromBuffer(buffer, filename);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.switching-modelling-matrices-container {
  font-size: 12px;
  max-width: none !important;
  color: $assortment-text-colour;
  height: 100%;
}

::v-deep {
  .dialog-card__header {
    padding: 0px 15px 0px 15px;
  }
  .dialog-card__footer {
    display: flex;
    justify-content: flex-end;
    padding: 0px 15px 0px 15px;
  }
  .dialog-card__content {
    height: 100%;
    max-height: 100% !important;
    padding: 0px 20px;
  }

  .v-messages {
    display: none;
  }

  .legend {
    border: none;
  }
}
</style>
