import { VCard } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VCard, { staticClass: "wp-scope-stores col-box" }, [
    _c("div", { staticClass: "wp-scope-stores__header mb-1" }, [
      _c(
        "h3",
        { staticClass: "wp-scope-stores__input-heading" },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("workpackagePage.scope.storesLabel")) +
              "\n\n      "
          ),
          _vm._v(" "),
          _c("assortment-tooltip", {
            attrs: {
              title: _vm.$t("tooltips.storeScope.title"),
              "tooltip-sections": _vm.storeScopeTooltipSections,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", [_c("workpackage-stores")], 1),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "grid-wrapper" }, [
      _c("div", { staticClass: "item" }, [
        _vm._v(_vm._s(_vm.$t("workpackagePage.scope.storesScopeForAnalysis"))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item item__count" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.workpackage.storeCount
                ? _vm.workpackage.storeCount.analysis
                : 0
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item" }, [
        _vm._v(
          _vm._s(_vm.$t("workpackagePage.scope.storesScopeForAssortment"))
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "item item__count" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.workpackage.storeCount
                ? _vm.workpackage.storeCount.assortment
                : 0
            ) +
            "\n    "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }