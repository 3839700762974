var render = function render(_c, _vm) {
  return _c(
    "div",
    { staticClass: "flex-column pt-2 default-box d-flex relative" },
    [
      _vm.props.showValues && _vm.props.isTopSection
        ? _c("div", { staticClass: "flex-column stats-item" }, [
            _c("span", { staticClass: "value" }, [
              _c("b", [
                _vm._v(
                  _vm._s(
                    _vm.parent.formatNumber({
                      number: _vm.props.product.originalSales,
                      format: "currency",
                    })
                  )
                ),
              ]),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.parent.$t(
                      "assortmentCanvasPage.productDashboard.transferredSpend.sales"
                    )
                  ) +
                  "\n    "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.props.showValues && _vm.props.isTopSection
        ? _c("div", { staticClass: "flex-column stats-item" }, [
            _c("span", { staticClass: "value" }, [
              _c("b", [
                _vm._v(
                  _vm._s(
                    _vm.parent.formatNumber({
                      number: _vm.props.product.newSales,
                      format: "currency",
                    })
                  )
                ),
              ]),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.parent.$t(
                      "assortmentCanvasPage.productDashboard.transferredSpend.inclTransSpend"
                    )
                  ) +
                  "\n    "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.props.showValues
        ? _c(
            "div",
            { staticClass: "flex-column pt-3 pb-3" },
            [
              _c(
                "assortment-image",
                _vm._b(
                  {
                    attrs: {
                      dashboard: "",
                      src: _vm.props.imageUrl,
                      alt: _vm.props.imageAlt,
                      title: _vm.props.imageTitle,
                      "tooltip-text": `${_vm.props.product.productKeyDisplay} - ${_vm.props.product.itemDescription}`,
                    },
                  },
                  "assortment-image",
                  _vm.props.conditionalProductTileStyle,
                  false
                )
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.props.showValues && !_vm.props.isTopSection
        ? _c("div", { staticClass: "flex-column stats-item" }, [
            _c("span", { staticClass: "value" }, [
              _c("b", [
                _vm._v(
                  _vm._s(
                    _vm.parent.formatNumber({
                      number: _vm.props.product.originalSales,
                      format: "currency",
                    })
                  )
                ),
              ]),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.parent.$t(
                      "assortmentCanvasPage.productDashboard.transferredSpend.sales"
                    )
                  ) +
                  "\n    "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.props.showValues && _vm.props.showOthers
        ? _c("div", { staticClass: "flex-column relative stats-item" }, [
            _c(
              "span",
              {
                staticClass: "small-title",
                class: [
                  _vm.props.isTopSection
                    ? "small-title-top"
                    : "small-title-bot",
                ],
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.parent.$t(
                        "assortmentCanvasPage.productDashboard.transferredSpend.others"
                      )
                    ) +
                    "\n    "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.props.showValues && _vm.props.showOthers
        ? _c("div", { staticClass: "flex-column icon-column" }, [
            _c("span", { staticClass: "box-icon mdi mdi-buffer" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.props.showValues && _vm.props.showOthers
        ? _c("div", { staticClass: "relative counter-value-container" }, [
            _c("div", { staticClass: "counter-value counter-value-bold" }, [
              _vm._v("+" + _vm._s(_vm.props.fromOthersCount)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.props.showValues && !_vm.props.showOthers
        ? _c("div", { staticClass: "flex-column relative stats-item" }, [
            _c("span", { staticClass: "small-title small-title-top" }, [
              _vm._v(
                _vm._s(
                  _vm.parent.$t(
                    "assortmentCanvasPage.productDashboard.transferredSpend.salesLost"
                  )
                ) + "\n    "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.props.showValues && !_vm.props.showOthers
        ? _c("div", { staticClass: "flex-column icon-column" }, [
            _c("span", { staticClass: "box-icon-red mdi mdi-trending-down" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex-column value-container",
          class: [
            _vm.props.isTopSection
              ? "value-container-top"
              : "value-container-bot",
          ],
        },
        [
          _c("div", { staticClass: "flex-column stats-item" }, [
            _c(
              "span",
              {
                staticClass: "value counter-value-bold",
                class: [
                  !_vm.props.showValues && !_vm.props.showOthers
                    ? "counter-value-red"
                    : "counter-value",
                ],
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.parent.formatNumber({
                        number: _vm.props.amount,
                        format: "currency",
                      })
                    ) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-column stats-item" }, [
            _c(
              "span",
              {
                staticClass: "counter-value",
                class: [
                  !_vm.props.showValues && !_vm.props.showOthers
                    ? "counter-value-red"
                    : "counter-value",
                ],
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.parent.formatNumber({
                      number: _vm.props.amountPercentage,
                      format: "percentage",
                    })
                  ) + "\n        %\n      "
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex-column arrow-container",
          class: [
            _vm.props.isTopSection
              ? "arrow-container-top"
              : "arrow-container-bot",
          ],
        },
        [_c("span", { staticClass: "arrow-icon mdi mdi-arrow-up" })]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }