var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "highlight-box",
      class: _vm.props.outerClass,
      style: _vm.props.outerStyle,
    },
    [
      _c("div", {
        staticClass: "highlight-item highlight-item--top highlight-item--left",
        class: { "highlight-item--list": _vm.props.listView },
      }),
      _vm._v(" "),
      !_vm.props.listView
        ? _c("div", {
            staticClass:
              "highlight-item highlight-item--bottom highlight-item--left",
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "highlight-item highlight-item--top highlight-item--right",
        class: { "highlight-item--list": _vm.props.listView },
      }),
      _vm._v(" "),
      !_vm.props.listView
        ? _c("div", {
            staticClass:
              "highlight-item highlight-item--bottom highlight-item--right",
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }