import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2 d-flex flex-column align-center" },
    [
      _c(VChip, { staticClass: "copy-chip", attrs: { small: "" } }, [
        _vm._v(_vm._s(_vm.$tkey(_vm.chipTextTranslationKey))),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "mt-3 mb-3 d-flex flex-column",
          class: _vm.selectBorder,
        },
        [
          _c("rtls-select", {
            staticClass: "mb-3",
            class: _vm.margin,
            attrs: {
              value: _vm.scenarioId,
              "hide-details": "",
              width: "220px",
              "max-width": "220px",
              items: _vm.scenarios,
              "item-text": "name",
              "item-value": "_id",
              placeholder: _vm.$tkey("selectScenario"),
              grey: "",
            },
            on: { input: _vm.setScenarioId },
          }),
          _vm._v(" "),
          _c("rtls-select", {
            staticClass: "mb-3",
            class: _vm.margin,
            attrs: {
              value: _vm.clusterId,
              "hide-details": "",
              width: "220px",
              "max-width": "220px",
              items: _vm.clusters,
              "item-text": "clusterName",
              "item-value": "clusterId",
              placeholder: _vm.$tkey("selectCluster"),
              grey: "",
              disabled: !_vm.scenarioId,
            },
            on: { input: _vm.setClusterId },
          }),
          _vm._v(" "),
          _c("rtls-select", {
            staticClass: "mb-3",
            class: _vm.margin,
            attrs: {
              value: _vm.storeClassId,
              "hide-details": "",
              width: "220px",
              "max-width": "220px",
              items: _vm.storeClasses,
              "item-text": "name",
              "item-value": "_id",
              placeholder: _vm.$tkey("selectStoreClass"),
              grey: "",
              disabled:
                !_vm.scenarioId || (!_vm.clusterId && _vm.clusterId !== null),
            },
            on: { input: _vm.setStoreClassId },
          }),
          _vm._v(" "),
          _c("rtls-select", {
            class: `${_vm.margin} ${_vm.canvasSelectStyle}`,
            attrs: {
              value: _vm.canvasId,
              "hide-details": "",
              width: "220px",
              "max-width": "220px",
              items: _vm.canvases,
              "item-text": _vm.canvasItemTextKey,
              "item-value": "_id",
              placeholder: _vm.$tkey(_vm.canvasTranslationKey),
              grey: "",
              disabled: _vm.canvasSelectDisabled,
            },
            on: { input: _vm.setCanvasId },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }