import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    [
      _c(
        "compound-extract-report-generator",
        { on: { "generate-extract-requested": _vm.generateReport } },
        [
          _c(
            VContainer,
            {
              staticClass: "report-jda d-flex flex-column pa-0 ma-0",
              attrs: { fluid: "" },
            },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    VCol,
                    { attrs: { colspan: "6" } },
                    [
                      _c(
                        "report-input-item",
                        {
                          staticClass: "mb-4",
                          attrs: { title: _vm.$tkey("extractName") },
                        },
                        [
                          _c("rtls-text-field", {
                            attrs: {
                              grey: "",
                              placeholder: _vm.$tkey("chooseAnExtractName"),
                            },
                            model: {
                              value: _vm.extractName,
                              callback: function ($$v) {
                                _vm.extractName = $$v
                              },
                              expression: "extractName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VCol, { attrs: { colspan: "6" } }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }