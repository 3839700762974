import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: {
        value: _vm.value,
        fullscreen: "",
        "content-class": "rest-of-market-modal",
        attach: ".assortment-table",
      },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeModal.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "dialog-card",
        {
          staticClass: "w-100 modal-card--full-screen",
          attrs: { title: _vm.$tkey("modalTitle") },
          on: { close: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c("page-actions", {
                    attrs: {
                      "has-data-errors": _vm.hasDataErrors,
                      "has-data-changes": _vm.hasDataChanges,
                      "is-discard-enabled":
                        !_vm.isEditingDisabled && !_vm.loading,
                      "save-btn-text": "addSelected",
                    },
                    on: {
                      save: _vm.addSelectedProducts,
                      discard: _vm.discardProductSelections,
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VContainer,
            {
              staticClass: "h-100 pa-0 rest-of-market__container",
              attrs: { fluid: "" },
            },
            [
              _c(
                VRow,
                { staticClass: "flex-grow-0 rest-of-market__toolbar" },
                [
                  _c(VCol, { attrs: { cols: "3" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center mb-3" },
                      [
                        _c(
                          "h3",
                          { staticClass: "mr-2 rest-of-market__label" },
                          [_vm._v(_vm._s(_vm.$tkey("categoryLabel")) + ":")]
                        ),
                        _vm._v(" "),
                        _c("rtls-select", {
                          staticClass: "rest-of-market__select",
                          attrs: {
                            value: _vm.selectedProductCategory,
                            items: _vm.productCategoryOptions,
                            "item-text": "categoryDescription",
                            "item-value": "categoryKey",
                            placeholder: _vm.$t("general.select"),
                            disabled: _vm.loading,
                          },
                          on: { change: _vm.onSelectedCategoryChange },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c(VCheckbox, {
                          staticClass:
                            "rtls-checkbox mx-2 rest-of-market__checkbox",
                          attrs: {
                            disabled: _vm.loading,
                            "input-value": _vm.filterListedProducts,
                          },
                          on: { change: _vm.toggleListedProducts },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "mr-3" }, [
                          _vm._v(_vm._s(_vm.$tkey("hideListedLabel"))),
                        ]),
                        _vm._v(" "),
                        _c(VCheckbox, {
                          staticClass:
                            "rtls-checkbox mx-2 rest-of-market__checkbox",
                          attrs: {
                            disabled: _vm.loading,
                            "input-value": _vm.filterParentRows,
                          },
                          on: { change: _vm.toggleParentRows },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.$tkey("hideSubtotalsLabel"))),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { attrs: { cols: "3" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center mb-3" },
                      [
                        _c(
                          "h3",
                          { staticClass: "mr-2 rest-of-market__label" },
                          [_vm._v(_vm._s(_vm.$tkey("selectLabel")) + ":")]
                        ),
                        _vm._v(" "),
                        _c("rtls-select", {
                          staticClass: "rest-of-market__select",
                          attrs: {
                            value: _vm.selectedSubGroup,
                            items: _vm.definitionOptions,
                            "item-text": "value",
                            "item-value": "key",
                            disabled: _vm.loading,
                            placeholder: _vm.$t("general.select"),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onSelectedGroupChange($event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VCol, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.$tkey("marketShareFOODLabel")) + ":"),
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          `${_vm.getCategoryMarketShare(_vm.romGroups.FOOD)}%`
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(VCol, [
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(_vm._s(_vm.$tkey("marketShareMULOLabel")) + ":"),
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          `${_vm.getCategoryMarketShare(_vm.romGroups.MULO)}%`
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VRow,
                { staticClass: "flex-grow-1 mt-0" },
                [
                  _c("ag-grid-vue", {
                    staticClass: "ag-theme-custom",
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: {
                      "tree-data": "",
                      "animate-rows": "",
                      "does-external-filter-pass": _vm.doesExternalFilterPass,
                      "row-data": _vm.rowData,
                      "column-defs": _vm.columnDefs,
                      "grid-options": _vm.gridOptions,
                      "auto-group-column-def": _vm.autoGroupColumnDef,
                    },
                    on: {
                      "grid-ready": _vm.onGridReady,
                      "selection-changed": _vm.setSelectedProducts,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }