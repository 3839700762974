import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        VContainer,
        { staticClass: "pt-3 pa-0 ma-0" },
        [
          _vm.unscopedMappings.length
            ? _c(VAlert, { staticClass: "mb-1", attrs: { text: "" } }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$tkey("checkSuggestedSelectionsMessage")) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ag-grid-box flex-grow-1" },
        [
          _c("ag-grid-vue", {
            staticClass: "ag-theme-custom",
            staticStyle: { width: "100%", height: "100%" },
            attrs: {
              "column-defs": _vm.columnDefs,
              "row-data": _vm.clusteredFurnitureData,
              "grid-options": _vm.gridOptions,
              "stop-editing-when-grid-loses-focus": true,
              "enable-range-selection": true,
            },
            on: {
              "cell-value-changed": _vm.trackDiff,
              "grid-ready": _vm.onGridReady,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("page-actions", {
        attrs: {
          "has-data-changes": !_vm.isSaveDisabled,
          "has-data-errors": _vm.hasDataErrors,
          "is-discard-enabled": _vm.hasDataChanges,
        },
        on: { discard: _vm.discardChanges, save: _vm.saveChanges },
      }),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.closeUnsavedDataModal,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }