<template>
  <div :key="`product-${isSidebarShown}`" class="h-100 d-flex flex-column">
    <reporting-section :short-view="shortView">
      <template v-slot:header>
        <reporting-header :short-view="shortView" :section="section">
          <template v-slot:toolbar>
            <div class="d-flex flex-row justify-end flex-grow-1">
              <v-btn secondary depressed @click="excelExport()">
                {{ $t('reportingPage.exportCurrentView') }}
                <v-icon class="material-icons-outlined ml-1 sidebar-toggle">
                  file_download
                </v-icon>
              </v-btn>

              <v-btn
                v-show="shouldShowLayouts"
                secondary
                depressed
                @click="toggleSideBar('layouts')"
              >
                {{ $tkey('sideBar.toolPanels.layouts.title') }}
                <v-icon class="material-icons-outlined ml-1 sidebar-toggle">dashboard</v-icon>
                <LayoutsComponent class="d-none" />
              </v-btn>
              &nbsp;
              <v-btn secondary depressed @click="toggleSideBar('columns')">
                {{ $tkey('sideBar.toolPanels.columns') }}
                <v-icon class="material-icons-outlined ml-1 sidebar-toggle">view_column</v-icon>
              </v-btn>
            </div>
          </template>
        </reporting-header>
      </template>

      <template v-slot:main-section>
        <reporting-main-section :section="section" :short-view="shortView">
          <template v-slot:data-section>
            <div class="ag-grid-box d-flex w-100 h-100" style="height: initial">
              <div v-if="isProductReportDisabled" class="ma-auto no-data">
                {{ $t('reportingPage.noPermissions') }}
              </div>
              <progress-bar
                v-else-if="isTableLoading"
                :message="$t('reportingPage.loadingTable')"
                style="margin: auto"
                class="pt-5"
              />
              <ag-grid-vue
                v-else
                animate-rows
                dense
                style="width: 100%; height: 100%"
                class="ag-theme-custom ag-theme-custom--attributes"
                :column-defs="columnDefs"
                :row-data="tableData"
                :grid-options="gridOptions"
                :side-bar="sideBar"
                :excel-styles="excelStyles"
                @grid-ready="onGridReady"
              />
            </div>
          </template>
        </reporting-main-section>
      </template>
    </reporting-section>
    <!-- confirmation dialog -->
    <main-dialog ref="confirm">
      <template v-slot:header>
        <v-card-text class="display-1 pa-0 text-center">
          <p>{{ $tkey('confirmationDialog.warning') }}</p>
        </v-card-text>
      </template>
      <template v-slot:content="{ cancel, confirm }">
        <v-card-actions class="float-right">
          <v-btn primary class="ma-2" @click="confirm">
            {{ $t('continue') }}
          </v-btn>
          <v-btn text depressed class="cancel ma-2" @click="cancel">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </template>
    </main-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import {
  debounce,
  differenceBy,
  flatten,
  isEqual,
  isEmpty,
  groupBy,
  isArray,
  sortBy,
  forEach,
  has,
  get,
  keyBy,
} from 'lodash';
import reportingSections from '@enums/reporting-sections';
import moment from 'moment';
import agGridUtils from '@/js/utils/ag-grid-utils';
import LayoutsComponent from './layouts.vue';

const groupsOrderMap = {
  details: 1,
  financials: 2,
  other: 3,
  cluster: 4,
  chain: 5,
  inputRanking: 6,
  placement: 7,
  outputRanking: 8,
  intermediateOutputRanking: 9,
  settingUser: 10,
  customAttributes: 11,
};

export default {
  localizationKey: 'reportingPage.sections.product',

  components: {
    AgGridVue,
    LayoutsComponent,
  },

  props: {
    // represents whether this report is open on (short) overview page
    shortView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      reportingSections,
      tableData: [],
      excelStyles: null,
      overviewData: [],
      columnsConfig: [],
      sideBar: null,
      section: reportingSections.product,
      gridApi: null,
      columnApi: null,
      isExporting: false,
    };
  },

  computed: {
    ...mapGetters('context', ['getDateFormats', 'getCurrentNumericLocale', 'getShowLayouts']),
    ...mapState('reporting', [
      'loadingSubsection',
      'isSidebarShown',
      'baselineIds',
      'comparisonIds',
      'comparison2Ids',
      'isSaving',
    ]),

    gridOptions() {
      return {
        rowHeight: 30,
        floatingFiltersHeight: 45,
        headerHeight: 55,
        suppressContextMenu: true,
        enableRangeSelection: true,
        suppressPropertyNamesCheck: true,
        suppressDragLeaveHidesColumns: true,
        defaultColDef: {
          editable: false,
          sortable: true,
          filter: true,
          floatingFilter: true,
          resizable: true,
          menuTabs: ['generalMenuTab'],
        },
        statusBar: {
          statusPanels: [
            {
              statusPanel: 'agAggregationComponent',
            },
          ],
        },
        columnTypes: {
          maintainOption: {
            valueFormatter: params => {
              if (!params.value) {
                return this.$t(`scenarioInputPage.attributes.maintainOptions.doNothing`);
              }
              const options = params.value.split(' | ');
              return options
                .map(option =>
                  this.$t(`scenarioInputPage.attributes.maintainOptions.${option || 'doNothing'}`)
                )
                .join(' | ');
            },
          },
          float: {
            valueFormatter: params => this.formatFloatValue(params.colDef, params.value),
            filterValueGetter: params => {
              const colId = params.column.colId;
              const value = params.data[colId];
              return this.formatFloatValue(colId, value);
            },
          },
          floatThreeDecimals: {
            valueFormatter: params => {
              return this.formatFloatValue(params.colDef, params.value, 'numberWithThreeDecimals');
            },
            filterValueGetter: this.numberWithThreeDecimalsGetter,
            valueGetter: this.numberWithThreeDecimalsGetter,
          },
          marginPercentage: {
            valueFormatter: params => {
              const formattedMargin = Number(params.value * 100)
                .toFixed(0)
                .toLocaleString(this.currentNumericLocale);
              return `${formattedMargin}%`;
            },
            filterValueGetter: params => {
              const colId = params.column.colId;
              const value = params.data[colId];
              const formattedMargin = Number(value * 100)
                .toFixed(0)
                .toLocaleString(this.currentNumericLocale);
              return `${formattedMargin}%`;
            },
            valueGetter: params => {
              const colId = params.column.colId;
              const value = params.data[colId];
              return value;
            },
          },
        },
      };
    },

    currentNumericLocale() {
      return this.getCurrentNumericLocale;
    },

    shouldShowLayouts() {
      return this.getShowLayouts;
    },

    isProductReportDisabled() {
      return !this.hasPermission(this.userPermissions.canViewProductReport);
    },

    isTableLoading() {
      return this.loadingSubsection.productTable;
    },

    tableColumnState() {
      if (!this.columnApi) return [];
      return this.columnApi.getColumnState();
    },

    requiredCurrency() {
      return this.$t(`currencies.${this.getCurrentNumericLocale}`);
    },

    columnDefs() {
      if (isEmpty(this.columnsConfig)) {
        return [];
      }
      const sortedColumns = sortBy(
        flatten(
          this.columnsConfig.map(column => {
            // some columns have dynamic headerName generated on BE
            let columnName =
              column.headerName ||
              this.$tkey(`table.${column.field}`, {
                currency: this.requiredCurrency,
              });

            if (column.isChainCluster) {
              const headerSuffix = this.$tkey(`table.${column.headerSuffix}`);
              columnName += ` - ${headerSuffix}`;
            }

            // if no comparison is selected - do not add 'C' prefix to the column header name
            const baseHeaderName = isEmpty(this.comparisonIds)
              ? columnName
              : `${this.$t('reportingPage.menuHeaders.baseShort')} - ${columnName}`;

            const baselineColumn = {
              width: 150,
              ...column,
              headerName: baseHeaderName,
            };

            const columns = [baselineColumn];

            if (!column.skipComparisonSplit) {
              if (!isEmpty(this.comparisonIds)) {
                columns.push({
                  ...baselineColumn,
                  field: `${baselineColumn.field}-comparison`,
                  headerName: `${this.$t(
                    'reportingPage.menuHeaders.comparisonShort'
                  )} - ${columnName}`,
                });
              }
              if (!isEmpty(this.comparison2Ids)) {
                columns.push({
                  ...baselineColumn,
                  field: `${baselineColumn.field}-comparison2`,
                  headerName: `${this.$t(
                    'reportingPage.menuHeaders.comparison2Short'
                  )} - ${columnName}`,
                });
              }
            }

            // If this is a column that compares data between comparison and base checkpoints,
            // add it dynamically if applicable
            if (column.comparisonWithBase) {
              const columnNameEnding = column.comparisonWithBaseAsPercentage ? '%' : '';

              if (!isEmpty(this.comparisonIds)) {
                columns.push({
                  ...baselineColumn,
                  field: `${baselineColumn.field}-comparison-vs-base`,
                  headerName: `${this.$t(
                    'reportingPage.menuHeaders.comparisonVsBase'
                  )} - ${columnName} ${this.$t(
                    'reportingPage.menuHeaders.change'
                  )} ${columnNameEnding}`,
                });
              }
              if (!isEmpty(this.comparison2Ids)) {
                columns.push({
                  ...baselineColumn,
                  field: `${baselineColumn.field}-comparison2-vs-base`,
                  headerName: `${this.$t(
                    'reportingPage.menuHeaders.comparison2VsBase'
                  )} - ${columnName} ${columnNameEnding}`,
                });
              }
            }

            return columns;
          })
        ),
        ['order', 'isClientOverride']
      );
      let groups = [];
      const groupedColumns = groupBy(sortedColumns, 'parent');
      forEach(groupedColumns, (value, key, index) => {
        groupedColumns[key][0].headerClass = 'divider-left';
        groupedColumns[key][0].cellClass = 'divider-left';
        groups.push({
          headerName: this.$tkey(`table.${key}`),
          headerClass: index === 0 ? '' : 'divider-left',
          groupId: key,
          children: groupedColumns[key],
        });
      });
      groups = sortBy(groups, group => groupsOrderMap[group.groupId]);

      return groups;
    },

    fetchDataDebounced() {
      return debounce(this.fetchData, 1000);
    },
  },

  watch: {
    baselineIds(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchDataDebounced();
    },

    comparisonIds(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchDataDebounced();
    },

    comparison2Ids(oldValue, newValue) {
      if (!isEqual(oldValue, newValue)) this.fetchDataDebounced();
    },
  },

  created() {
    this.excelStyles = [
      {
        id: 'overviewHeaderText',
        font: {
          bold: true,
        },
      },
      {
        id: 'filtersWarning',
        font: {
          bold: true,
        },
        interior: {
          color: '#eeee66',
          pattern: 'Solid',
        },
      },
    ];
    this.fetchDataDebounced();

    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: '',
          labelKey: 'columns',
          iconKey: 'close', // Icon acts as close button
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressPivotMode: true,
            suppressPivots: true,
            suppressRowGroups: true,
            suppressValues: true,
          },
          width: 400,
        },
        {
          id: 'layouts',
          labelDefault: '',
          labelKey: 'layouts',
          iconKey: 'close', // Icon acts as close button
          toolPanel: 'LayoutsComponent',
          toolPanelParams: {
            suppressPivotMode: true,
            suppressPivots: true,
            suppressRowGroups: true,
            suppressValues: true,
            tableColumnState: this.tableColumnState,
            applyColumnLayout: layout => this.applyColumnLayout(layout),
            saveLayout: params => this.saveLayout(params),
          },
          width: 400,
        },
      ],
      hiddenByDefault: true,
    };
  },

  methods: {
    ...mapActions('reporting', ['fetchReportingData', 'createLayout']),
    ...mapActions('snackbar', ['showSuccess', 'showWarning']),

    // Get a map of the custom attribute names and ids
    // this allows the layouts to be used across workpackages with the same attribute names
    getCustomAttMap(isLoading = false) {
      const allColumns = this.columnApi.getColumns();
      const customAttributeMap = {};
      if (allColumns) {
        allColumns.forEach(attribute => {
          const attId = attribute.colId;
          const name = attribute.colDef.headerName;
          if (isLoading) {
            // When loading the name needs to be mapped to an id
            customAttributeMap[name] = attId;
          } else if (get(attribute, 'userProvidedColDef.parent') === 'customAttributes') {
            // When saving the id needs to be mapped to the name
            customAttributeMap[attId] = name;
          }
        });
      }
      return customAttributeMap;
    },

    // Convert custom attribute ids to names to be stored
    // This allows them to be used by other wps
    formatLayout(layout, isLoading = false) {
      const customAttributesMap = this.getCustomAttMap(isLoading);
      return layout.map(col => {
        if (
          customAttributesMap[col.colId] &&
          (!isLoading || (isLoading && col.isCustomAttribute))
        ) {
          col.colId = customAttributesMap[col.colId];
          // We store that it was originally a custom att so we can convert the id back later
          if (!isLoading) {
            col.isCustomAttribute = true;
          }
        }
        return col;
      });
    },
    saveLayout({ name, closeModal }) {
      // Get the current layout
      let layout = this.columnApi.getColumnState();
      layout = this.formatLayout(layout);

      this.createLayout({
        name,
        layout,
        table: 'ProductReport',
      }).then(() => closeModal());
    },

    async fetchData() {
      const response = await this.fetchReportingData({
        section: this.section,
        reportArea: 'table',
      });
      this.tableData = response.data;
      this.columnsConfig = response.columnsConfig;
      this.overviewData = response.overviewData;
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    toggleSideBar(selectedToolPanel) {
      const openToolPanel = this.gridApi.getOpenedToolPanel();
      // If the selected toolbar is not open, open it
      if (!openToolPanel || openToolPanel !== selectedToolPanel) {
        this.gridApi.setSideBarVisible(true);
        this.gridApi.openToolPanel(selectedToolPanel);
      } else if (openToolPanel === selectedToolPanel) {
        // If the selected tool panel is already open, close it
        this.gridApi.closeToolPanel();
        this.gridApi.setSideBarVisible(false);
      }
    },

    getOverviewTabContents(includeFiltersInfo = false) {
      const columns = [
        { key: 'workpackage', type: 'String', property: 'name' },
        { key: 'scenario', type: 'String', property: 'name' },
        { key: 'cluster', type: 'String', property: 'name' },
        { key: 'storeClass', type: 'String', property: 'name' },
        { key: 'description', type: 'String' },
        { key: 'checkpoint', type: 'String', property: 'name' },
        { key: 'spacebreak', type: 'String', property: 'name' },
        { key: 'storeCount', type: 'String' },
      ];

      const overviewColumns = keyBy(columns, 'key');
      const overviewKeys = Object.keys(overviewColumns);
      const headerCells = overviewKeys.map(c => ({
        styleId: 'overviewHeaderText',
        data: {
          value: this.$t(`reportingPage.overviewHeaders.${c}`),
          type: overviewColumns[c].type,
        },
      }));

      const overviewData = this.overviewData.map(o => {
        const cells = overviewKeys.map(c => {
          const value = has(overviewColumns[c], 'property')
            ? o[c][overviewColumns[c].property]
            : o[c];
          return { data: { value, type: overviewColumns[c].type } };
        });
        return { cells };
      });
      const filtersInfo = {
        cells: [
          {
            styleId: 'filtersWarning',
            mergeAcross: columns.length - 1,
            data: { value: this.$t('reportingPage.exportFilterMessage'), type: 'String' },
          },
        ],
      };
      const prependContent = [{ cells: headerCells }, ...overviewData];
      if (includeFiltersInfo) prependContent.splice(0, 0, filtersInfo);

      return {
        prependContent,
        sheetName: this.$t('reportingPage.overviewTab'),
        // skip regular column header and group header
        skipColumnGroupHeaders: true,
        processHeaderCallback: () => '',
        processGroupHeaderCallback: () => '',
      };
    },

    async exportToExcel() {
      const hasFiltersApplied = !isEmpty(this.gridApi.getFilterModel());
      const filterColumn = this.gridApi.columnModel.getColumnDefs()[0].children[0].field; // first column used as dummy filter
      const filterInstance = this.gridApi.getFilterInstance(filterColumn);

      // Filter out ag-grid data for the overview tab
      filterInstance.setModel({
        values: [],
      });
      this.gridApi.onFilterChanged();
      const spreadsheets = [
        this.gridApi.getSheetDataForExcel(this.getOverviewTabContents(hasFiltersApplied)),
      ];

      filterInstance.setModel(null);
      this.gridApi.onFilterChanged();
      spreadsheets.push(
        this.gridApi.getSheetDataForExcel({
          sheetName: this.$t('reportingPage.reportTab'),
        })
      );

      const fileName = `${
        hasFiltersApplied
          ? this.$t('reportingPage.exportFilteredFileName')
          : this.$t('reportingPage.exportFileName')
      } - ${moment().format(this.getDateFormats.longWithFullTime)}`;

      this.gridApi.exportMultipleSheetsAsExcel({
        data: spreadsheets,
        author: 'AO',
        fileName,
      });
    },

    async excelExport() {
      this.isExporting = true;
      const hasFiltersApplied = !isEmpty(this.gridApi.getFilterModel());
      if (hasFiltersApplied) {
        if (await this.$refs.confirm.open()) {
          await this.exportToExcel();
        }
      } else {
        await this.exportToExcel();
      }
      this.isExporting = false;
    },

    formatFloatValue(colDef, value, format = 'float') {
      if (colDef.isArray && isArray(value) && !isEmpty(value)) {
        return value
          .map(v =>
            agGridUtils.utils.valueIsFalsy({ value: v })
              ? colDef.noValue || colDef.noBaseComparisonValue || ''
              : this.formatNumber({ number: v, format })
          )
          .join(' | ');
      }
      return agGridUtils.utils.valueIsFalsy({ value })
        ? colDef.noValue || colDef.noBaseComparisonValue || ''
        : this.formatNumber({ number: value, format });
    },

    // Applies the received column state to the grid
    applyColumnLayout(layoutColumnsState) {
      const currentColumnsState = this.columnApi.getColumnState();
      layoutColumnsState = this.formatLayout(layoutColumnsState, true);

      // hide columns that are currently visible but not presented in the layout to apply
      const columnsToHide = differenceBy(currentColumnsState, layoutColumnsState, 'colId');
      columnsToHide.forEach(column => {
        column.hide = true;
      });

      this.columnApi.applyColumnState({
        state: [...layoutColumnsState, ...columnsToHide],
        applyOrder: true,
      });
      this.showSuccess(this.$tkey('layoutApplied'));

      // get columns in layout that are missing in the current columnsConfig
      const missingColumns = differenceBy(layoutColumnsState, currentColumnsState, 'colId');
      if (!isEmpty(missingColumns)) {
        const content =
          this.$tkey('layoutApplied') +
          this.$tkey('columnsMissing', { count: missingColumns.length });
        this.showWarning(content);
      }
    },

    numberWithThreeDecimalsGetter(params) {
      const colId = params.column.colId;
      const value = params.data[colId];

      return this.isExporting
        ? parseFloat(value)
        : this.formatFloatValue(colId, value, 'numberWithThreeDecimals');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.chart-area {
  & .header {
    justify-content: space-between;
  }
}

::v-deep {
  .v-btn:not(:last-of-type) {
    margin-right: 30px;
  }

  .baseline-row {
    background-color: lighten($baseline-colour, 60%) !important;
  }

  .comparison-row {
    background-color: lighten($comparison-colour, 30%) !important;
  }

  .comparison2-row {
    background-color: lighten($comparison2-colour, 30%) !important;
  }

  .sidebar-toggle.v-icon {
    color: $assortment-primary-colour !important;
    font-family: 'Material Icons Outlined';
    font-feature-settings: 'liga';
    font-size: 20px;
  }

  .ag-theme-custom {
    .ag-row {
      font-size: 11px !important;
    }

    .ag-cell-wrapper {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-left: 0 !important;
    }

    .ag-row-group-indent {
      &-1 {
        padding-left: 15px !important;
      }

      &-2 {
        padding-left: 25px !important;
      }

      &-3 {
        padding-left: 35px !important;
      }

      &-4 {
        padding-left: 45px !important;
      }
    }

    .ag-side-buttons {
      padding-top: 0;
      position: absolute;
      z-index: 1;

      .ag-side-button {
        &:not(.ag-selected) {
          display: none;
        }

        .ag-side-button-button {
          border-left: none;
          min-height: unset;
          padding: 8px 10px 8px 0;
        }

        .ag-side-button-icon-wrapper {
          color: $assortment-primary-colour;
        }
      }
    }

    .ag-column-select-header {
      padding-right: 30px !important;

      .ag-column-select-header-filter-wrapper:before {
        right: 30px !important;
      }
    }

    .ag-status-bar {
      .ag-status-name-value {
        line-height: 1;
        padding: 10px 6px 0;
      }
    }
  }
}

.no-data {
  font-size: 1.4rem;
}
</style>
