'use strict';

// Describes actions to be taken for job statuses
module.exports = {
  functions: {
    WorkpackageSetup: {
      actions: {
        finished: 'workpackages/afterWorkpackageSetupComplete',
        failed: 'workpackages/afterWorkpackageSetupFailed',
      },
      notifications: {
        starting: 'notificationsLong.workpackageSetup.starting',
        running: 'notificationsLong.workpackageSetup.running',
        finished: 'notificationsLong.workpackageSetup.finished',
        failed: 'notificationsLong.workpackageSetup.failed',
        route: {
          name: 'home',
          paramKeys: [],
        },
      },
    },
    TemplateSetup: {
      notifications: {
        starting: 'notificationsLong.templateSetup.starting',
        running: 'notificationsLong.templateSetup.running',
        finished: 'notificationsLong.templateSetup.finished',
        failed: 'notificationsLong.templateSetup.failed',
        route: {
          name: 'templates',
          paramKeys: [],
        },
      },
    },
    ClusteringGenerator: {
      actions: {
        finished: 'clustering/clusteringJobComplete',
        failed: 'clustering/clusteringJobFailed',
      },
      notifications: {
        starting: 'notificationsLong.clustering.starting',
        running: 'notificationsLong.clustering.running',
        finished: 'notificationsLong.clustering.finished',
        failed: 'notificationsLong.clustering.failed',
        route: {
          name: 'clustering',
          paramKeys: ['scenarioId'],
        },
      },
    },
    RegionsByAttributeGenerator: {
      actions: {
        finished: 'productRegions/generateRegionsByAttributeJobComplete',
        failed: 'productRegions/generateRegionsByAttributeJobFailed',
      },
      notifications: {
        starting: 'notificationsLong.region.starting',
        running: 'notificationsLong.region.running',
        finished: 'notificationsLong.region.finished',
        failed: 'notificationsLong.region.failed',
        route: {
          name: 'region',
          paramKeys: ['scenarioId'],
        },
      },
    },
    Switching: {
      notifications: {
        starting: 'notificationsLong.switching.starting',
        running: 'notificationsLong.switching.running',
        finished: 'notificationsLong.switching.finished',
        failed: 'notificationsLong.switching.failed',
        route: {
          name: 'switching-behaviour',
          paramKeys: ['scenarioId'],
        },
      },
    },
    ClusteredSwitching: {
      actions: {
        finished: 'clustering/clusteredSwitchingJobComplete',
        failed: 'clustering/clusteredSwitchingJobFailed',
      },
      notifications: {
        starting: 'notificationsLong.clusteredSwitching.starting',
        running: 'notificationsLong.clusteredSwitching.running',
        finished: 'notificationsLong.clusteredSwitching.finished',
        failed: 'notificationsLong.clusteredSwitching.failed',
        route: {
          name: 'clustering',
          paramKeys: ['scenarioId'],
        },
      },
    },
    SpacebreakGenerator: {
      actions: {
        finished: 'furniture/spacebreaksJobCompleted',
      },
      notifications: {
        starting: 'notificationsLong.spacebreakGeneration.starting',
        running: 'notificationsLong.spacebreakGeneration.running',
        finished: 'notificationsLong.spacebreakGeneration.finished',
        failed: 'notificationsLong.spacebreakGeneration.failed',
        route: {
          name: 'spacebreak-calculator',
          paramKeys: ['scenarioId'],
        },
      },
    },
    CanvasGeneration: {
      actions: {
        finished: 'assortmentCanvas/canvasGenerationComplete',
        failed: 'assortmentCanvas/canvasGenerationFailed',
      },
      notifications: {
        starting: 'notificationsLong.canvasGeneration.starting',
        running: 'notificationsLong.canvasGeneration.running',
        finished: 'notificationsLong.canvasGeneration.finished',
        failed: 'notificationsLong.canvasGeneration.failed',
        route: {
          name: 'assortments-panel',
          paramKeys: ['scenarioId'],
        },
      },
    },
    ClScSwitchingMatricesCalculation: {
      actions: {
        failed: 'assortmentCanvas/clScSwitchingMatricesCalculationFailed',
      },
      notifications: {
        starting: 'notificationsLong.clScSwitchingMatricesCalculation.starting',
        running: 'notificationsLong.clScSwitchingMatricesCalculation.running',
        finished: 'notificationsLong.clScSwitchingMatricesCalculation.finished',
        failed: 'notificationsLong.clScSwitchingMatricesCalculation.failed',
        route: {
          name: 'assortments-panel',
          paramKeys: ['scenarioId'],
        },
      },
    },
    CdtGeneration: {
      actions: {
        finished: 'scenarioCdts/afterCdtJobComplete',
        failed: 'scenarioCdts/afterCdtJobFailed',
      },
      notifications: {
        starting: 'notificationsLong.cdt.starting',
        running: 'notificationsLong.cdt.running',
        finished: 'notificationsLong.cdt.finished',
        failed: 'notificationsLong.cdt.failed',
        route: {
          name: 'cdt',
          paramKeys: ['scenarioId'],
        },
      },
    },
    AssortmentOptimisation: {
      actions: {
        finished: [
          'assortmentCanvas/fetchCanvases',
          'assortmentCanvas/fetchCurrentCanvasInformation',
        ],
        failed: [
          'assortmentCanvas/fetchCanvases',
          'assortmentCanvas/fetchCurrentCanvasInformation',
        ],
      },
      notifications: {
        starting: 'notificationsLong.assortmentOptimisation.starting',
        running: 'notificationsLong.assortmentOptimisation.running',
        finished: 'notificationsLong.assortmentOptimisation.finished',
        failed: 'notificationsLong.assortmentOptimisation.failed',
        route: {
          name: 'assortments-canvas',
          paramKeys: ['scenarioId', 'canvasId'],
        },
      },
    },
    Forecaster: {
      actions: {
        finished: 'assortmentCanvas/fetchCheckpoints',
        failed: 'assortmentCanvas/fetchCheckpoints',
      },
      notifications: {
        starting: 'notificationsLong.forecaster.starting',
        running: 'notificationsLong.forecaster.running',
        finished: 'notificationsLong.forecaster.finished',
        failed: 'notificationsLong.forecaster.failed',
        route: {
          name: 'assortments-canvas',
          paramKeys: ['scenarioId', 'canvasId'],
        },
      },
    },
    StoreExecutionExtracts: {
      notifications: {
        starting: 'notificationsLong.storeExecution.starting',
        running: 'notificationsLong.storeExecution.running',
        finished: 'notificationsLong.storeExecution.finished',
        failed: 'notificationsLong.storeExecution.failed',
        route: {
          name: 'jda',
          paramKeys: ['scenarioId'],
        },
      },
      actions: {
        finished: 'extracts/fetchExtracts',
        failed: 'extracts/fetchExtracts',
      },
    },
    CopyScenario: {
      actions: {
        finished: 'scenarios/afterCopyScenarioComplete',
        failed: 'scenarios/afterCopyScenarioFailed',
      },
      notifications: {
        starting: 'notificationsLong.copyScenario.starting',
        running: 'notificationsLong.copyScenario.running',
        finished: 'notificationsLong.copyScenario.finished',
        failed: 'notificationsLong.copyScenario.failed',
        route: {
          name: 'home',
          paramKeys: [],
        },
      },
    },
    CopyWorkpackage: {
      actions: {
        finished: 'workpackages/afterCopyWorkpackageComplete',
        failed: 'workpackages/afterCopyWorkpackageFailed',
      },
      notifications: {
        starting: 'notificationsLong.copyWorkpackage.starting',
        running: 'notificationsLong.copyWorkpackage.running',
        finished: 'notificationsLong.copyWorkpackage.finished',
        failed: 'notificationsLong.copyWorkpackage.failed',
        route: {
          name: 'home',
          paramKeys: [],
        },
      },
    },
    ExtractsGeneration: {
      notifications: {
        starting: 'notificationsLong.extractsGeneration.starting',
        running: 'notificationsLong.extractsGeneration.running',
        finished: 'notificationsLong.extractsGeneration.finished',
        failed: 'notificationsLong.extractsGeneration.failed',
        route: {
          name: 'product-store-extract',
          paramKeys: ['scenarioId'],
        },
      },
      actions: {
        finished: 'extracts/fetchExtracts',
        failed: 'extracts/fetchExtracts',
      },
    },
    FurnitureExtract: {
      notifications: {
        starting: 'notificationsLong.furnitureExtract.starting',
        running: 'notificationsLong.furnitureExtract.running',
        finished: 'notificationsLong.furnitureExtract.finished',
        failed: 'notificationsLong.furnitureExtract.failed',
        route: {
          name: 'furniture-extract',
          paramKeys: ['scenarioId'],
        },
      },
      actions: {
        finished: 'extracts/fetchExtracts',
        failed: 'extracts/fetchExtracts',
      },
    },
    PrepareSwaps: {
      actions: {
        finished: 'scenarios/afterPrepareSwapsComplete',
        failed: 'scenarios/afterPrepareSwapsFailed',
      },
      notifications: {
        starting: 'notificationsLong.prepareSwaps.starting',
        running: 'notificationsLong.prepareSwaps.running',
        finished: 'notificationsLong.prepareSwaps.finished',
        failed: 'notificationsLong.prepareSwaps.failed',
        route: {
          name: 'prepare-swaps',
          paramKeys: ['scenarioId'],
        },
      },
    },
    SimpleSwaps: {
      actions: {
        finished: 'simpleSwapRuns/afterSimpleSwapsComplete',
        failed: 'simpleSwapRuns/afterSimpleSwapsFailed',
      },
      notifications: {
        starting: 'notificationsLong.simpleSwaps.starting',
        running: 'notificationsLong.simpleSwaps.running',
        finished: 'notificationsLong.simpleSwaps.finished',
        failed: 'notificationsLong.simpleSwaps.failed',
        route: {
          name: 'extracts',
          paramKeys: ['scenarioId'],
        },
      },
    },
    CompoundExtract: {
      actions: {
        finished: 'compoundExtract/fetchCompoundExtracts',
        failed: 'compoundExtract/fetchCompoundExtracts',
      },
      notifications: {},
    },
    SetupProductModelling: {
      actions: {
        finished: 'scenarios/afterProductModelingSetupComplete',
      },
      notifications: {
        starting: 'notificationsLong.setupProductModelling.starting',
        running: 'notificationsLong.setupProductModelling.running',
        finished: 'notificationsLong.setupProductModelling.finished',
        failed: 'notificationsLong.setupProductModelling.failed',
        route: {
          name: 'product-modelling',
          paramKeys: ['scenarioId'],
        },
      },
    },
  },
};
