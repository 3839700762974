import axios from 'axios';
import to from 'await-to-js';
import { keys } from 'lodash';
import i18n from '../../vue-i18n';

const store = {
  namespaced: true,

  state: {
    embedToken: null,
    loading: false,
  },

  getters: {
    availableReports(state, getters, rootState) {
      return new Set(keys(rootState.context.powerBIreportIds));
    },
  },

  mutations: {
    setEmbedToken(state, embedToken) {
      state.embedToken = embedToken;
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },

  actions: {
    async fetchEmbedToken({ commit, dispatch }, reportName) {
      const [error, response] = await to(
        axios.get(`/api/reporting-power-bi/embedToken/${reportName}`)
      );

      commit('setLoading', false);

      if (error) {
        dispatch('handleResponseNotifications', {
          error,
          errorMessage: i18n.t('notifications.fetchError', {
            resource: i18n.t('entities.report'),
          }),
        });
      }
      return response.data;
    },
  },
};

export default store;
