import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "pa-0 ma-0 checkpoint-selector-container" },
    [
      !_vm.canvases.length
        ? _c(
            "div",
            {
              staticClass: "pb-4 pa-2 ma-0",
              class: { "h-100": !_vm.canvases.length },
            },
            [
              _c(VAlert, { staticClass: "mb-1", attrs: { text: "" } }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$tkey("assortmentsMissingMessage")) +
                    "\n    "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.canvases.length
        ? _c(
            "div",
            { staticClass: "ag-grid-box flex-grow-1 pa-2 ma-0" },
            [
              _c("ag-grid-vue", {
                staticClass: "ag-theme-custom",
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  "column-defs": _vm.columnDefs,
                  "row-data": _vm.rowData,
                  "grid-options": _vm.gridOptions,
                  "stop-editing-when-cells-loses-focus": true,
                },
                on: {
                  "cell-value-changed": _vm.trackDiff,
                  "grid-ready": _vm.onGridReady,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("page-actions", {
        attrs: {
          "has-data-changes": _vm.hasDataChanges,
          "has-data-errors": _vm.hasDataErrors,
          "is-discard-enabled": !_vm.isEditingDisabled,
          "save-disabled": _vm.isEditingDisabled,
        },
        on: { discard: _vm.discardChanges, save: _vm.saveChanges },
      }),
      _vm._v(" "),
      _c("dependency-tree-feedback-modal", {
        attrs: {
          value: _vm.dependencyTreeModalOpen,
          results: _vm.dependencyTreeFeedback,
          page: "checkpointSelector",
        },
        on: {
          close: _vm.closeDependencyTreeModal,
          commit: function ($event) {
            return _vm.saveChanges(true)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }