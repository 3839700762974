var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.component,
    _vm._b(
      {
        tag: "component",
        attrs: { event: "", "v-on": _vm.$listeners, to: _vm.to },
        nativeOn: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.wrappedClick.apply(null, arguments)
          },
        },
      },
      "component",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }