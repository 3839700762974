import axios from 'axios';
import to from 'await-to-js';

const store = {
  namespaced: true,

  state: {
    extracts: [],
    selectedStep: null,
    loading: false,
  },

  getters: {
    extracts(state) {
      return state.extracts;
    },
  },

  mutations: {
    setExtracts(state, extracts) {
      state.extracts = extracts;
    },

    setSelectedStep(state, value) {
      state.selectedStep = value;
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },

  actions: {
    async generateExtract({ commit, rootState }, extract) {
      commit('setLoading', true);
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const [err] = await to(
        axios.post(`/api/extracts/scenario/${scenarioId}`, { ...extract, scenarioId })
      );
      if (err) throw new Error(err.message);
      commit('setLoading', false);
    },

    async generateFurnitureExtract({ commit, rootState }, extract) {
      commit('setLoading', true);
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const [err] = await to(
        axios.post(`/api/extracts/scenario/${scenarioId}/furniture`, { ...extract, scenarioId })
      );
      if (err) throw new Error(err.message);
      commit('setLoading', false);
    },

    async generateStoreExecutionsExtracts({ commit, rootState }, extract) {
      commit('setLoading', true);
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const [err] = await to(
        axios.post(`/api/extracts/scenario/${scenarioId}/store-execution`, {
          ...extract,
          scenarioId,
        })
      );
      if (err) throw new Error(err.message);
      commit('setLoading', false);
    },

    async fetchExtracts({ commit, rootState }) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, response] = await to(axios.get(`/api/extracts/scenario/${scenarioId}`));
      if (err) throw new Error(err.message);
      commit('setExtracts', response.data);
      commit('setLoading', false);
    },

    async deleteExtract({ commit }, extractId) {
      commit('setLoading', true);
      const [err, result] = await to(axios.delete(`/api/extracts/${extractId}`));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      if (result.data && result.data.errors) {
        throw new Error(`Unable to delete extract: ${extractId}`);
      }
    },
  },
};

export default store;
