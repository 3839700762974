<template>
  <v-menu
    ref="optionsMenu"
    v-model="showOptions"
    data-id-e2e="product-attribute-options"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        v-bind="attrs"
        :disabled="disabled"
        class="mr-2 outlined-btn reset-kpi-button"
        depressed
        secondary
        v-on="on"
      >
        {{ $t('general.options') }}
      </v-btn>
    </template>
    <transfer-price-settings
      v-if="hasTransferPriceEnabled"
      @sync-financials="emitSyncFinancials"
      @update-maintain="emitBulkUpdateMaintain"
    />
  </v-menu>
</template>

<script>
import { get } from 'lodash';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    showOptions: false,
  }),

  computed: {
    hasTransferPriceEnabled() {
      return get(this.getClientConfig, 'features.productsInResetEnabled', false);
    },
  },

  methods: {
    emitSyncFinancials(transferPriceRange) {
      this.$emit('sync-financials', transferPriceRange);
    },

    emitBulkUpdateMaintain(newMaintainValue) {
      this.$emit('update-maintain', newMaintainValue);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
.v-menu__content {
  padding: 2.5rem 1.5rem;
  background-color: $assortment-background;
}
</style>
