import { VCombobox } from 'vuetify/lib/components/VCombobox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { "data-id-e2e": "combobox-renderer" } },
    [
      _c(VCombobox, {
        attrs: { value: _vm.params.value, items: _vm.options },
        on: { input: _vm.onChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }