import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VMenu,
        {
          attrs: {
            "close-on-content-click": false,
            "min-width": 250,
            "max-width": 500,
            "max-height": 320,
            "nudge-bottom": 35,
            left: "",
            "z-index": _vm.menuZindex,
          },
          on: { input: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    VBtn,
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "ml-2 control-menu-button",
                          attrs: {
                            ripple: false,
                            depressed: "",
                            icon: "",
                            text: "",
                            color: _vm.menuButtonColour,
                            disabled: _vm.disabled,
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(VIcon, { attrs: { size: "30" } }, [
                        _vm._v("settings_applications"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _vm._v(" "),
          _c(VCard, [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("div", { staticClass: "title-heading d-flex flex-column" }, [
                _c("div", [_c("h2", [_vm._v(_vm._s(_vm.$tkey("title")))])]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex flex-column pa-3 control-container" },
                [
                  _c("div", [
                    _c("div", { staticClass: "mb-1" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.$tkey("moveToLabel"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("rtls-select", {
                          attrs: {
                            "hide-details": "",
                            width: "200px",
                            "max-width": "200px",
                            "menu-props": { maxWidth: 200 },
                            items: _vm.getAvailableSpacebreaks,
                            "item-value": "_id",
                            placeholder: _vm.$t("general.select"),
                            disabled: _vm.disabled,
                            grey: "",
                          },
                          model: {
                            value: _vm.spacebreakToMoveTo,
                            callback: function ($$v) {
                              _vm.spacebreakToMoveTo = $$v
                            },
                            expression: "spacebreakToMoveTo",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            staticClass: "action-btn ml-5",
                            attrs: {
                              id: "moveToBtn",
                              primary: "",
                              depressed: "",
                              disabled: !_vm.spacebreakToMoveTo || _vm.disabled,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.openPopUp("moveTo")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$tkey("moveToBtn")) +
                                "\n              "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.selectedView === "list" && !_vm.columnSettingsEnabled
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column pa-3 control-container",
                    },
                    [
                      _c(
                        "div",
                        [
                          _c("div", { staticClass: "mb-1" }, [
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v(_vm._s(_vm.$tkey("addColumnslabel"))),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c(VSelect, {
                                staticClass: "rtls-select--multi-select",
                                attrs: {
                                  value: _vm.selectedCustomAttributes,
                                  items: _vm.customAttributes,
                                  "item-text": "name",
                                  "item-value": "id",
                                  "return-object": "",
                                  white: "",
                                  width: "200px",
                                  dense: "",
                                  height: 30,
                                  "small-chips": "",
                                  placeholder: _vm.$tkey("selectAttributes"),
                                  "hide-details": "",
                                  multiple: "",
                                  disabled: _vm.disabled,
                                  "menu-props": {
                                    dense: true,
                                    contentClass: "rtls-multi-select-list",
                                  },
                                },
                                on: { change: _vm.updateCustomAttributes },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function ({ item, index }) {
                                        return [
                                          index === 0
                                            ? _c(VChip, [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "overflow-container",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(_vm._s(item.name)),
                                                    ]),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          index === 1
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "grey--text caption",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    (+" +
                                                      _vm._s(
                                                        _vm
                                                          .selectedCustomAttributes
                                                          .length - 1
                                                      ) +
                                                      ")\n                  "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2001880642
                                ),
                              }),
                              _vm._v(" "),
                              _c(
                                VBtn,
                                {
                                  staticClass: "action-btn ml-5",
                                  attrs: {
                                    id: "addAttrCols",
                                    loading: _vm.setAttributeColsInProgress,
                                    primary: "",
                                    depressed: "",
                                    disabled:
                                      !_vm.hasAttributesChanges ||
                                      _vm.setAttributeColsInProgress ||
                                      _vm.disabled,
                                  },
                                  on: { click: _vm.saveAttributeSelection },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$tkey("addBtn")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.availableExtraKPIs.length
                            ? [
                                _c("div", { staticClass: "mt-2 mb-1" }, [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v(_vm._s(_vm.$tkey("addKPIs")))]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c(VSelect, {
                                      staticClass: "rtls-select--multi-select",
                                      attrs: {
                                        value: _vm.selectedExtraKPIs,
                                        items: _vm.availableExtraKPIs,
                                        "item-text": "name",
                                        "item-value": "id",
                                        "return-object": "",
                                        white: "",
                                        width: "200px",
                                        dense: "",
                                        height: 30,
                                        "small-chips": "",
                                        placeholder: _vm.$tkey("selectKPIs"),
                                        "hide-details": "",
                                        multiple: "",
                                        disabled: _vm.disabled,
                                        "menu-props": {
                                          dense: true,
                                          contentClass:
                                            "rtls-multi-select-list",
                                        },
                                      },
                                      on: {
                                        change: _vm.updateSelectedExtraKPIs,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "selection",
                                            fn: function ({ item, index }) {
                                              return [
                                                index === 0
                                                  ? _c(VChip, [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "overflow-container",
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(item.name)
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                index === 1
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "grey--text caption",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      (+" +
                                                            _vm._s(
                                                              _vm
                                                                .selectedExtraKPIs
                                                                .length - 1
                                                            ) +
                                                            ")\n                    "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1671620293
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "action-btn ml-5",
                                        attrs: {
                                          id: "addExtraKPIs",
                                          loading:
                                            _vm.setExtraKPIsColsInProgress,
                                          primary: "",
                                          depressed: "",
                                          disabled:
                                            !_vm.hasKPIsChanges ||
                                            _vm.setExtraKPIsColsInProgress ||
                                            _vm.disabled,
                                        },
                                        on: {
                                          click: _vm.saveExtraKPIsSelection,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.$tkey("addBtn")) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pa-3 d-flex flex-column control-container" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-space-between" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-2 action-btn",
                          attrs: {
                            primary: "",
                            depressed: "",
                            disabled: !_vm.anyProductsVisible || _vm.disabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openPopUp("locked")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$tkey("lockVisible")) +
                              "\n              "
                          ),
                          _c(VIcon, { attrs: { size: "14", right: "" } }, [
                            _vm._v("mdi-lock-outline"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "action-btn",
                          attrs: {
                            primary: "",
                            depressed: "",
                            disabled: !_vm.anyProductsVisible || _vm.disabled,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openPopUp("unlocked")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$tkey("unlockVisible")) +
                              "\n              "
                          ),
                          _c(VIcon, { attrs: { size: "14", right: "" } }, [
                            _vm._v("mdi-lock-open-variant-outline"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex pt-3 pl-3 pr-3" },
                [
                  _c("copy-canvas-decisions-modal", {
                    attrs: { disabled: _vm.disabled },
                    on: {
                      close: function ($event) {
                        _vm.menu = false
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex flex-column pl-3 pr-3 pt-2 pb-2" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-end align-center close-icon-container",
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-0 font-weight-bold btn-text",
                          attrs: { ripple: false, text: "", small: "" },
                          on: {
                            click: function ($event) {
                              _vm.menu = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("actions.close")) + "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "confirmDialog",
        attrs: {
          "confirm-action": _vm.updateProducts,
          title: _vm.popUpTitle,
          message: _vm.popUpMessage,
          border: true,
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function ({ cancel, confirm }) {
              return [
                _c(
                  VContainer,
                  { staticClass: "actions-container assortment-table" },
                  [
                    _c(
                      VRow,
                      [
                        _c(
                          VCol,
                          { staticClass: "actions-col" },
                          [
                            _c(
                              VBtn,
                              {
                                staticClass: "float-right button-space-5",
                                attrs: { disabled: _vm.isLoading, primary: "" },
                                on: { click: cancel },
                              },
                              [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                            ),
                            _vm._v(" "),
                            _c(
                              VBtn,
                              {
                                staticClass: "float-right button-space-5",
                                attrs: {
                                  loading: _vm.isLoading || _vm.disabled,
                                  primary: "",
                                  disabled: _vm.isLoading,
                                },
                                on: { click: confirm },
                              },
                              [_vm._v(_vm._s(_vm.$t("actions.confirm")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }