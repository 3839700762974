import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: { value: _vm.value, width: "870px" },
      on: { "click:outside": _vm.closeModal },
    },
    [
      _vm.value
        ? _c(
            "dialog-card",
            {
              attrs: { title: _vm.$tkey("title") },
              on: { close: _vm.closeModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("page-actions", {
                          attrs: {
                            "has-data-errors": _vm.hasDataErrors,
                            "has-data-changes": true,
                            "show-discard": false,
                            "save-btn-text": "run",
                          },
                          on: { save: _vm.onSave },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "right-btns",
                                fn: function () {
                                  return [
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "text-outline",
                                        attrs: { text: "", link: "" },
                                        on: { click: _vm.closeModal },
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(_vm.$t("actions.cancel")) +
                                            "\n          "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            808452761
                          ),
                        }),
                        _vm._v(" "),
                        _c("dependency-tree-feedback-modal", {
                          attrs: {
                            value: _vm.dependencyTreeModalOpen,
                            results: _vm.dependencyTreeFeedback,
                            page: "clustering",
                          },
                          on: {
                            close: _vm.closeDependencyTreeModal,
                            commit: function ($event) {
                              return _vm.onSave(true)
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1831289675
              ),
            },
            [
              _c(
                VContainer,
                {
                  staticClass: "cluster-switching__container mb-10",
                  attrs: { fluid: "" },
                },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        {
                          staticClass: "pa-0 pr-6",
                          attrs: {
                            id: "cluster-switching-scheme-selection",
                            cols: "5",
                          },
                        },
                        [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "cluster-switching__heading mt-1 mb-4 pa-0 col-10",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$tkey("schemeSelectionTitle")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c(
                                VRow,
                                _vm._l(_vm.headers, function (header, index) {
                                  return _c(
                                    VCol,
                                    {
                                      key: `header-${index}`,
                                      class: header.headerClass,
                                      attrs: { cols: header.cols },
                                    },
                                    [_c("span", [_vm._v(_vm._s(header.text))])]
                                  )
                                }),
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.schemes, function (row, rowIndex) {
                                return _c(
                                  VRow,
                                  { key: row._id, staticClass: "cluster-row" },
                                  _vm._l(_vm.headers, function (header) {
                                    return _c(
                                      VCol,
                                      {
                                        key: `cell-${row._id}-${header.id}`,
                                        staticClass: "d-flex align-center",
                                        class: header.colClass,
                                        staticStyle: { padding: "6px" },
                                        attrs: { cols: header.cols },
                                      },
                                      [
                                        _vm.isFirstClusterCount1Entry(
                                          rowIndex,
                                          header,
                                          row
                                        )
                                          ? [
                                              _c(VCheckbox, {
                                                staticClass:
                                                  "scheme-checkbox rtls-checkbox mx-2",
                                                attrs: { disabled: true },
                                                model: {
                                                  value: row.selected,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "selected",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "row.selected",
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.isFirstClusterCount1Entry(
                                          rowIndex,
                                          header,
                                          row
                                        ) && header.ui === _vm.CELL_TYPE.radio
                                          ? [
                                              _c(
                                                VRadioGroup,
                                                {
                                                  attrs: { "hide-details": "" },
                                                  on: {
                                                    change:
                                                      _vm.onSchemeSelectedChanged,
                                                  },
                                                  model: {
                                                    value: _vm.selectedScheme,
                                                    callback: function ($$v) {
                                                      _vm.selectedScheme = $$v
                                                    },
                                                    expression:
                                                      "selectedScheme",
                                                  },
                                                },
                                                [
                                                  _c(VRadio, {
                                                    staticClass: "scheme-radio",
                                                    attrs: {
                                                      ripple: false,
                                                      value: row._id,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        header.ui === _vm.CELL_TYPE.readonly
                                          ? [
                                              _c("span", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.getClusterVariance(
                                                        header.outputFormat,
                                                        row[header.value]
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  }),
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        VCol,
                        { staticClass: "pa-0", attrs: { cols: "3" } },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between align-center mb-4",
                            },
                            [
                              _c(
                                "h4",
                                {
                                  staticClass:
                                    "cluster-switching__heading mt-1 mb-0 pa-0 col-8",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$tkey("calculationsTitle")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "cluster-switching__paragraph" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$tkey("calculationsSubtitle")) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            VRadioGroup,
                            {
                              attrs: {
                                disabled: !_vm.hasClustersUsingClusteredMatrix,
                              },
                              model: {
                                value: _vm.selectedCalculationMethod,
                                callback: function ($$v) {
                                  _vm.selectedCalculationMethod = $$v
                                },
                                expression: "selectedCalculationMethod",
                              },
                            },
                            _vm._l(
                              _vm.calculationTypes,
                              function (calculation) {
                                return _c(VRadio, {
                                  key: calculation,
                                  staticClass: "cluster-switching__label",
                                  attrs: {
                                    ripple: false,
                                    label: _vm.$tkey(
                                      `calculations.${calculation}`
                                    ),
                                    value: calculation,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VCol,
                        {
                          staticClass: "pa-0 pl-6",
                          attrs: {
                            id: "cluster-switching-matrix-selection",
                            cols: "4",
                          },
                        },
                        [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "cluster-switching__heading mt-1 mb-4 pa-0 col-8",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$tkey("unclusterMatrixSelectionTitle")
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "cluster-switching__paragraph" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$tkey(
                                      "unclusterMatrixSelectionSubtitle"
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          !_vm.hasUnclusteredSwitchingMatrices
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "cluster-switching__paragraph font-italic d-inline-block invalid-rows-error-box",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$tkey("noUnclusteredMatrixFound")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "mt-3" },
                            _vm._l(
                              _vm.selectedSchemeClusters,
                              function (cluster) {
                                return _c(
                                  "div",
                                  {
                                    key: cluster.clusterId,
                                    staticClass: "cluster-matrix-selection",
                                  },
                                  [
                                    _c(VCheckbox, {
                                      attrs: {
                                        disabled:
                                          !_vm.hasUnclusteredSwitchingMatrices,
                                        value: cluster.clusterId,
                                        label: cluster.clusterName,
                                        ripple: false,
                                      },
                                      model: {
                                        value: _vm.clustersUsingClusteredMatrix,
                                        callback: function ($$v) {
                                          _vm.clustersUsingClusteredMatrix = $$v
                                        },
                                        expression:
                                          "clustersUsingClusteredMatrix",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }