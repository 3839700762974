/*
Tooltip content options mixins for <assortment-tooltip> component are being described here.
Exporting separate mixin for each page to prevent getting all the configs on every page.
Config structure for tooltip section is the following:
sections: Array<Object>.
Each section object has tips: Array property.
Tip can be:
  * String - then it will be rendered as a bullet point in a section
  * Object with type 'icons'. In this case, 'icon' and 'text' props are expected for each tip
  * Object with 'text' and 'nestedTips' props - 'text' will be rendered as a bullet point,
    nestedTips are rendered as a nested list and can have the same structure as 'tip' array item
Currently one level of nesting is supported, e.g.:
* tip 1
  ** tip 1.1
  ** tip 1.2
* tip 2
*/

export const workpackageScopeTooltipOptionsMixin = {
  data() {
    return {
      snapshotDateTooltipSections: [
        {
          tips: [this.$t('tooltips.snapshotDate.info'), this.$t('tooltips.snapshotDate.defines')],
        },
        {
          tips: [this.$t('tooltips.snapshotDate.changeSetting')],
        },
      ],

      targetLaunchDateTooltipSections: [
        {
          tips: [
            this.$t('tooltips.targetLaunchDate.info'),
            this.$t('tooltips.targetLaunchDate.indicate'),
          ],
        },
      ],

      performancePeriodTooltipSections: [
        {
          tips: [
            this.$t('tooltips.performancePeriod.indicate'),
            this.$t('tooltips.performancePeriod.weeksCanBeExcluded'),
          ],
        },
        {
          tips: [this.$t('tooltips.performancePeriod.implications')],
        },
        {
          tips: [this.$t('tooltips.performancePeriod.changeSetting')],
        },
      ],

      switchingPeriodTooltipSections: [
        {
          tips: [
            this.$t('tooltips.switchingPeriod.indicate'),
            this.$t('tooltips.switchingPeriod.switchingRequiresData'),
          ],
        },
        {
          tips: [this.$t('tooltips.switchingPeriod.implications')],
        },
        {
          tips: [this.$t('tooltips.switchingPeriod.changeSetting')],
        },
      ],

      fillAccordingToTooltipSections: [
        {
          tips: [
            this.$t('tooltips.fillAccordingTo.indicate'),
            {
              type: 'icons',
              text: this.$t('tooltips.fillAccordingTo.shouldMatchPlanograms'),
              nestedTips: [
                {
                  icon: '$linear-space',
                  text: this.$t('tooltips.fillAccordingTo.linearSpace'),
                },
                {
                  icon: '$cubic-space',
                  text: this.$t('tooltips.fillAccordingTo.cubicSpace'),
                },
                {
                  icon: '$frontal-space',
                  text: this.$t('tooltips.fillAccordingTo.frontalSpace'),
                },
                {
                  icon: '$horizontal-space',
                  text: this.$t('tooltips.fillAccordingTo.horizontalSpace'),
                },
                {
                  icon: '$empty-cube',
                  text: this.$t('tooltips.fillAccordingTo.productCount'),
                },
              ],
            },
          ],
        },
        {
          tips: [
            this.$t('tooltips.fillAccordingTo.settingYouChoose'),
            this.$t('tooltips.fillAccordingTo.note'),
            this.$t('tooltips.fillAccordingTo.ifWishToChange'),
          ],
        },
      ],

      learningPeriodTooltipSections: [
        {
          tips: [
            this.$t('tooltips.learningPeriod.indicate'),
            this.$t('tooltips.learningPeriod.weeksCanBeExcluded'),
          ],
        },
        {
          tips: [this.$t('tooltips.learningPeriod.implications')],
        },
        {
          tips: [this.$t('tooltips.learningPeriod.changeSetting')],
        },
      ],
    };
  },
  computed: {
    importFromAssortmentGroupsToolTipSections() {
      const extraText = this.isMaxRangePerformanceLearningSwitchingPeriodEnabled
        ? this.$t('tooltips.importFromAssortmentGroups.productsFromTimespanWithLearning')
        : '';
      return [
        {
          tips: [
            {
              text: this.$t('tooltips.importFromAssortmentGroups.thisFunctionWill'),
              nestedTips: [
                this.$t('tooltips.importFromAssortmentGroups.stores'),
                this.$t('tooltips.importFromAssortmentGroups.productsFromSnapshot'),
                this.$t('tooltips.importFromAssortmentGroups.productsFromTimespan').concat(
                  extraText
                ),
              ],
            },
          ],
        },
      ];
    },
  },
};

export const workpackageProductsTooltipOptionsMixin = {
  data() {
    return {
      productScopeTooltipSections: [
        {
          tips: [
            {
              text: this.$t('tooltips.productScope.defineProducts'),
              nestedTips: [
                this.$t('tooltips.productScope.soldProducts'),
                this.$t('tooltips.productScope.soldInactiveProducts'),
              ],
            },
            this.$t('tooltips.productScope.newProducts'),
          ],
        },
        {
          tips: [this.$t('tooltips.productScope.implications')],
        },
        {
          tips: [this.$t('tooltips.productScope.changeSetting')],
        },
      ],
    };
  },
};

export const workpackageStoresTooltipOptionsMixin = {
  data() {
    return {
      storeScopeTooltipSections: [
        {
          tips: [
            this.$t('tooltips.storeScope.indicate'),
            this.$t('tooltips.storeScope.forAnalysis'),
            this.$t('tooltips.storeScope.forAssortment'),
            this.$t('tooltips.storeScope.analysisNotAssortment'),
            this.$t('tooltips.storeScope.assortmentNotAnalysis'),
          ],
        },
        {
          tips: [
            {
              text: this.$t('tooltips.storeScope.mayBeIncluded'),
              nestedTips: [
                this.$t('tooltips.storeScope.includedAnalysis'),
                this.$t('tooltips.storeScope.includedAssortment'),
              ],
            },
          ],
        },
      ],
    };
  },
};

export const filterSplitWorkpackagesMixin = {
  data() {
    return {
      filterSplitWorkpackagesSections: [
        {
          tips: [this.$t('tooltips.filterSplitWorkpackages.helpContent')],
        },
      ],
    };
  },
};

export const scenarioOptimiserTooltipOptionsMixin = {
  data() {
    return {
      optimiseAccordingToTooltipSections: [
        {
          tips: [
            this.$t('tooltips.optimiseAccordingTo.indicate'),
            {
              type: 'icons',
              text: this.$t('tooltips.optimiseAccordingTo.shouldBeAligned'),
              nestedTips: [
                {
                  icon: '$linear-space',
                  text: this.$t('tooltips.optimiseAccordingTo.linearSpace'),
                },
                {
                  icon: '$cubic-space',
                  text: this.$t('tooltips.optimiseAccordingTo.cubicSpace'),
                },
                {
                  icon: '$frontal-space',
                  text: this.$t('tooltips.optimiseAccordingTo.frontalSpace'),
                },
                {
                  icon: '$horizontal-space',
                  text: this.$t('tooltips.optimiseAccordingTo.horizontalSpace'),
                },
                {
                  icon: '$empty-cube',
                  text: this.$t('tooltips.optimiseAccordingTo.productCount'),
                },
              ],
            },
            this.$t('tooltips.optimiseAccordingTo.used'),
          ],
        },
      ],
      categoryStrategyTooltipSections: [
        {
          tips: [
            this.$t('tooltips.categoryStrategy.indicate'),
            this.$t('tooltips.categoryStrategy.implications'),
          ],
        },
      ],
      margingWithoutFundingSections: [
        {
          tips: [this.$t('tooltips.optimiseAccordingTo.marginWithoutFundingWarning')],
        },
      ],
    };
  },
};
