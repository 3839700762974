<template>
  <component
    :is="componentName"
    v-model="isPopupOpen"
    content-class="rtls-dialog rtls-dialog--stores"
    :class="{ 'template-screen': fromTemplate }"
    @keydown.esc="closeModal"
    @click:outside="closeModal"
  >
    <template v-slot:activator="{ on }">
      <v-btn :ripple="false" class="workpackage-stores__link" text small v-on="on">
        {{ $t('workpackagePage.scope.viewStoreList') }}
        <v-icon size="20">mdi-chevron-right</v-icon>
      </v-btn>
    </template>

    <dialog-card
      :title="$tkey('popupHeading')"
      :is-popup="fromTemplate"
      :show-full-headers="!fromTemplate"
      @close="closeModal"
    >
      <v-card class="d-flex flex-column data-panel" style="height: 100%">
        <div class="action-panel">
          <div class="action-panel-container">
            <data-upload
              :legends="csvUploadLegends"
              :csv-upload-handler="onCSVUpload"
              :disabled="!hasPermission(userPermissions.canEditWorkpackageScope)"
              @process="process"
            />
            <import-from-planogram v-if="fromTemplate" class="ml-3" />
          </div>

          <div
            v-if="showErrorControls"
            id="stores-error-controls"
            class="action-panel-container align-self-center"
          >
            <span class="invalid-rows-error-box pr-2">
              {{ $tkey('storesPerClusterWarning', { minimumStoresPerCluster }) }}
            </span>
            <v-btn v-if="!filterInvalidClusters" primary @click="toggleInvalidClusters">
              {{ $tkey('showInvalidClusters') }}
            </v-btn>
            <v-btn v-else primary @click="toggleInvalidClusters">
              {{ $tkey('showAllClusters') }}
            </v-btn>
          </div>

          <div class="action-panel-container">
            <rtls-search
              v-model="searchString"
              :placeholder="$tkey('labels.search')"
              width="240px"
              @input="gridOptions.api.onFilterChanged()"
            />
          </div>
        </div>
        <div class="ag-grid-box flex-grow-1">
          <ag-grid-vue
            style="width: 100%; height: 100%;"
            class="ag-theme-custom ag-theme-custom--attributes"
            auto-params-refresh
            :column-defs="columnDefs"
            :row-data="stores"
            :does-external-filter-pass="doesExternalFilterPass"
            :grid-options="gridOptions"
            :row-drag-managed="true"
            :stop-editing-when-cells-loses-focus="true"
            :enable-range-selection="true"
            @cell-value-changed="trackDiff"
            @grid-ready="onGridReady"
          />
        </div>
      </v-card>

      <template v-slot:footer>
        <page-actions
          show-export
          :show-save-button="hasPermission(userPermissions.canEditWorkpackageScope)"
          :show-discard="hasPermission(userPermissions.canEditWorkpackageScope)"
          :export-options="exportOptions"
          :has-data-changes="hasDataChanges"
          :has-data-errors="hasDataErrors"
          :live-data="stores"
          export-service="workpackage-stores"
          @templateDataExport="
            dataExport({ columns: templateExportColumns, mode: 'template-export' })
          "
          @fullDataExport="dataExport({ mode: 'full-export' })"
          @discard="init()"
          @save="saveImportedStores(false)"
        >
          <template v-if="fromTemplate" v-slot:right-end-btns>
            <div class="action-btn-container d-flex rtls-border rtls-border--left-thin pl-2 ">
              <v-btn
                data-id-e2e="btnRunTemplate"
                :disabled="
                  !hasPermission(userPermissions.canEditWorkpackageScope) || hasDataChanges
                "
                :loading="isWorkpackageSetupRunning"
                depressed
                primary
                small
                @click="runTemplateSetup(false)"
              >
                {{ $t(fromTemplate ? 'templatesPage.runWp' : 'workpackagePage.runWp') }}
              </v-btn>
            </div>
          </template>
        </page-actions>
      </template>
    </dialog-card>
    <dependency-tree-feedback-modal
      :value="dependencyTreeModalOpen"
      :results="dependencyTreeFeedback"
      page="workpackageSetup"
      @close="closeDependencyTreeModal"
      @commit="commitHandler"
    />
    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="closeUnsavedDataModal"
      @confirm="closeUnsavedDataModal"
    />
  </component>
</template>

<script>
import ImportFromPlanogram from '@/js/components/import-from-planogram.vue';
import { AgGridVue } from 'ag-grid-vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
  get,
  cloneDeep,
  isEmpty,
  size,
  groupBy,
  keys,
  keyBy,
  isUndefined,
  forEach,
  camelCase,
} from 'lodash';
import agGridUtils from '@/js/utils/ag-grid-utils';
import exportCsv from '@/js/mixins/export-csv';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';

export default {
  localizationKey: 'workpackagePage.stores',

  components: {
    AgGridVue,
    ImportFromPlanogram,
  },
  mixins: [exportCsv, unsavedDataWarningMixin],

  props: {
    fromTemplate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dependencyTreeModalOpen: false,
      dependencyTreeFeedback: {},
      searchString: '',
      select: null,
      selectItems: [this.$tkey('allStores')],
      filterInvalidClusters: false,
      minimumStoresPerCluster: 20, // for simple swaps WP
      gridOptions: {
        suppressContextMenu: true,
        enableFillHandle: true,
        isExternalFilterPresent: () => true,
        getMainMenuItems: this.getMainMenuItems,
        defaultColDef: {
          filter: true,
          sortable: true,
          resizable: true,
          comparator: agGridUtils.sortings.naturalSort,
          editable: false,
          menuTabs: ['filterMenuTab'],
          suppressMovable: true,
          minWidth: 80,
          flex: 1,
          cellClass: 'aligned-start priority-cell',
          cellClassRules: {
            'diff-background': this.hasDiff,
            'invalid-nonempty': agGridUtils.validations.validateValueExists,
          },
          suppressKeyboardEvent: agGridUtils.utils.suppressKeyboardEvent,
        },
        rowHeight: 30,
        headerHeight: 40,
        processCellFromClipboard: agGridUtils.utils.processCellFromClipboard,
        getRowId: store => store.data.storeKey,
      },
      gridApi: null,
      errorData: {},
      currentStateDiff: {},
      savedState: {},
      columnApi: null,
      stores: [],
      actions: [],
      isPopupOpen: false,
      csvUploadLegends: {
        buttonName: this.$t('actions.manualImport'),
        title: this.$tkey('addStoresTitle'),
      },
      exportOptions: [
        { name: this.$t('csvExport.exportOptions.templateData'), value: 'templateDataExport' },
        { name: this.$t('csvExport.exportOptions.fullData'), value: 'fullDataExport' },
      ],
      calledFromSetup: false,
    };
  },

  computed: {
    ...mapGetters('context', ['getCsvExport', 'getDateFormats', 'getClientConfig']),
    ...mapGetters('workpackages', [
      'getWorkpackageById',
      'isSimpleSwapsWP',
      'isWorkpackageSetupRunning',
    ]),
    ...mapState('workpackages', ['selectedWorkpackage', 'loading']),

    workpackage() {
      return this.selectedWorkpackage;
    },
    oldStores() {
      return get(this.getWorkpackageById(this.workpackage._id), 'stores', []);
    },
    analysisCount() {
      return this.stores.filter(store => store.analysis).length;
    },
    assortmentCount() {
      return this.stores.filter(store => store.assortment).length;
    },
    hasMinAssortmentAnalysis() {
      return this.analysisCount > 0 && this.assortmentCount > 0;
    },
    hasDataChanges() {
      return !isEmpty(this.currentStateDiff);
    },
    hasDataErrors() {
      // needed for <page-actions> component
      return !this.hasMinAssortmentAnalysis;
    },

    showErrorControls() {
      return this.isSimpleSwapsWP && (size(this.errorData) || this.filterInvalidClusters);
    },

    templateExportColumns() {
      const basicColumns = ['storeKeyDisplay', 'analysis', 'assortment'];

      return this.isSimpleSwapsWP ? basicColumns.concat('simpleSwapsCluster') : basicColumns;
    },

    serviceName() {
      return `workpackage-stores-${this.workpackage.type}`;
    },

    columnDefs() {
      const columns = [
        {
          headerName: this.$tkey('table.headers.storeKeyDisplay'),
          field: 'storeKeyDisplay',
          width: 100,
        },
        {
          headerName: this.$tkey('table.headers.format'),
          field: 'formatDescription',
          width: 100,
        },
        {
          headerName: this.$tkey('table.headers.storeName'),
          field: 'storeDescription',
          width: 200,
        },
        {
          headerName: this.$tkey('table.headers.storeProfile'),
          field: 'storeProfile',
          width: 120,
        },
        {
          headerName: this.$tkey('table.headers.country'),
          field: 'country',
          width: 200,
        },
        {
          headerName: this.$tkey('table.headers.province'),
          field: 'province',
          width: 100,
        },
        {
          headerName: this.$tkey('table.headers.city'),
          field: 'city',
        },
        {
          headerName: this.$tkey('table.headers.openDate'),
          field: 'openDate',
          valueFormatter: params =>
            agGridUtils.formatters.datesFormatter(params, this.getDateFormats.long),
          filter: false,
          menuTabs: [],
        },
        {
          headerName: this.$tkey('table.headers.closeDate'),
          field: 'closeDate',
          valueFormatter: params =>
            agGridUtils.formatters.datesFormatter(params, this.getDateFormats.long),
          filter: false,
          menuTabs: [],
        },
        {
          headerName: this.$tkey('table.headers.cluster'),
          field: 'simpleSwapsCluster',
          filter: false,
          editable: true,
          valueParser: agGridUtils.parsers.defaultParser,
          menuTabs: [],
        },
        {
          headerName: this.$tkey('table.headers.analysis'),
          field: 'analysis',
          resizable: false,
          maxWidth: 100,
          editable: this.hasPermission(this.userPermissions.canEditWorkpackageScope),
          valueParser: agGridUtils.parsers.booleanParser,
          cellRenderer: params =>
            agGridUtils.utils.checkboxRenderer(params, this.handleCheckboxChange),
          cellRendererParams: {
            field: 'analysis',
          },
          menuTabs: ['generalMenuTab'],
        },
        {
          headerName: this.$tkey('table.headers.assortment'),
          field: 'assortment',
          resizable: false,
          maxWidth: 100,
          editable: this.hasPermission(this.userPermissions.canEditWorkpackageScope),
          valueParser: agGridUtils.parsers.booleanParser,
          cellRenderer: params =>
            agGridUtils.utils.checkboxRenderer(params, this.handleCheckboxChange),
          cellRendererParams: {
            field: 'assortment',
          },
          menuTabs: ['generalMenuTab'],
        },
      ];

      const extraColumns = get(this.getClientConfig, 'storeColumns', {});

      return agGridUtils.builders.mergeHeaders(columns, extraColumns, {
        componentName: camelCase(this.$options.name),
      });
    },

    componentName() {
      return this.fromTemplate ? 'div' : 'v-dialog';
    },
  },

  watch: {
    isPopupOpen: {
      deep: true,
      async handler() {
        await this.init();
        if (get(this.gridOptions, 'api')) {
          this.resetSearchFilter();
        }
      },
    },
  },

  async created() {
    if (!this.fromTemplate) return;
    await this.init();
    if (get(this.gridOptions, 'api')) {
      this.resetSearchFilter();
    }
  },

  methods: {
    ...mapMutations('scenarios', ['setSelectedScenario']),

    ...mapActions('workpackages', [
      'updateWorkpackage',
      'fetchSingleWorkpackage',
      'setSelectedWorkpackage',
      'processCSV',
    ]),
    ...mapActions('snackbar', ['showSnackbar']),
    ...mapActions('files', ['uploadCSV']),
    ...mapActions('dependencyTree', ['triggerDependencyTree']),

    updateDiff(storeKey, field, currentValue) {
      const path = `${storeKey}.${field}`;
      const originalValue = get(this.savedState, path);

      const existingValue = get(this.currentStateDiff, [storeKey, field]);
      const valueHasChanged = currentValue !== originalValue;

      if (valueHasChanged) {
        if (!this.currentStateDiff[storeKey]) this.$set(this.currentStateDiff, storeKey, {});
        this.$set(this.currentStateDiff[storeKey], field, currentValue);
      } else if (!isUndefined(existingValue)) {
        // existingValue can be false for boolean columns, check if defined instead.
        // Remove the field from the object if it's back to its old value.
        // Note: Entering values directly doesn't trigger cellValueChanged but pasting values does
        this.$delete(this.currentStateDiff[storeKey], field);
        if (isEmpty(this.currentStateDiff[storeKey])) {
          // If there's nothing left in the object, remove it.
          this.$delete(this.currentStateDiff, storeKey);
        }
      }
    },

    hasDiff(params) {
      const { storeKey } = params.data;
      const { field } = params.colDef;
      const path = `${storeKey}.${field}`;

      const currentValue = params.value;
      const originalValue = get(this.savedState, path);

      return agGridUtils.comparators.didValueChange(currentValue, originalValue);
    },

    handleCheckboxChange(params) {
      params.setValue(!params.value);
    },

    handleHeaderCheckboxChange(field, isSelected) {
      const updates = [];
      const diffsToTrack = [];
      this.gridApi.forEachNodeAfterFilter(rowNode => {
        const newRowData = rowNode.data;
        newRowData[field] = isSelected;
        diffsToTrack.push({
          data: { storeKey: newRowData.storeKey },
          colDef: { field },
          value: isSelected,
        });

        updates.push(newRowData);
      });

      if (updates.length) {
        this.gridApi.applyTransaction({ update: updates });
        diffsToTrack.forEach(diff => this.trackDiff(diff, false));
        this.warnMinAssortmentAnalysisStores();
      }
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
      this.calledFromSetup = false;
    },

    dataExport(options) {
      const { columns, mode } = options;
      const params = {
        columnSeparator: this.getCsvExport.columnSeparator,
        suppressQuotes: this.getCsvExport.suppressQuotes,
        fileName: this.getFileName({
          serviceName: 'workpackage-stores',
          workpackageName: `${this.workpackage.name}-${mode}`,
          fileNameDateFormat: this.getDateFormats.csvFileName,
        }),
        processCellCallback: agGridUtils.utils.processCellForExport,
      };
      if (columns) {
        params.columnKeys = columns;
      }
      this.gridOptions.api.exportDataAsCsv(params);
    },

    toggleInvalidClusters() {
      this.filterInvalidClusters = !this.filterInvalidClusters;
      this.gridApi.onFilterChanged();
    },

    // this method checks if there are any clusters that have insufficient amount of stores for Simple Swaps algorithm,
    // and if there are any, adds stores with these clusters to error data list
    checkClustersForStores() {
      const clusters = groupBy(this.stores, 'simpleSwapsCluster');

      forEach(keys(clusters), key => {
        if (size(clusters[key]) < this.minimumStoresPerCluster) {
          forEach(clusters[key], store => this.$set(this.errorData, store.storeKey, true));
        } else {
          forEach(clusters[key], store => this.$delete(this.errorData, store.storeKey));
        }
      });

      // turn off filter if you've removed all invalid rows
      if (isEmpty(this.errorData) && this.filterInvalidClusters) this.toggleInvalidClusters();
    },

    doesExternalFilterPass(node) {
      // if there are clusters with insufficient stores, show these clusters
      if (this.filterInvalidClusters) {
        return this.errorData[node.data.storeKey];
      }

      let { storeDescription, storeKeyDisplay } = node.data;
      storeKeyDisplay = agGridUtils.filters.standardStringFilter.textFormatter(storeKeyDisplay);
      storeDescription = agGridUtils.filters.standardStringFilter.textFormatter(storeDescription);
      const searchString = agGridUtils.filters.standardStringFilter.textFormatter(
        this.searchString
      );
      const inStoreDescription = storeDescription.indexOf(searchString) !== -1;
      const inStoreDisplayKey = storeKeyDisplay.indexOf(searchString) !== -1;
      return inStoreDisplayKey || inStoreDescription;
    },

    warnMinAssortmentAnalysisStores() {
      if (!this.hasMinAssortmentAnalysis) {
        this.showSnackbar({
          color: 'red',
          content: this.$tkey('minAssortmentAnalysisWarning'),
        });
      }
    },

    async updateWorkpackageStores() {
      await this.updateWorkpackage({
        workpackageId: this.workpackage._id,
        updates: { stores: cloneDeep(this.stores) },
      });
    },

    async process({ fileId, mappings, delimiter }) {
      this.gridOptions.api.showLoadingOverlay();
      const uploadedData = await this.processCSV({
        fileId,
        mappings,
        delimiter,
        service: this.serviceName,
      });
      this.stores = cloneDeep(this.workpackage.stores) || [];
      // mark the uploaded data as changes (so user can save or discard)
      uploadedData.forEach(v => {
        this.$set(this.currentStateDiff, v.storeKey, v);
      });

      if (this.isSimpleSwapsWP) this.checkClustersForStores();
      this.warnMinAssortmentAnalysisStores();

      this.gridOptions.api.hideOverlay();
    },

    onCSVUpload(formData) {
      formData.append('workpackageId', this.workpackage._id);
      return this.uploadCSV({ formData, service: this.serviceName });
    },

    async commitHandler() {
      if (this.calledFromSetup) await this.runTemplateSetup(true);
      else this.saveImportedStores(true);
    },

    async saveImportedStores(commit = false) {
      const results = await this.triggerDependencyTree({
        params: {
          change: 'workpackageSetupModified',
          updates: {},
          commit,
          workpackageId: this.workpackage._id,
        },
      });

      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return false;
      }

      this.gridOptions.api.showLoadingOverlay();
      const importedStoreKeys = this.stores.map(store => store.storeKey);
      const numStoresImported = this.stores.length;
      const numStoresDeleted = this.oldStores.filter(
        ({ storeKey }) => !importedStoreKeys.includes(storeKey)
      ).length;

      await this.updateWorkpackageStores();

      // Construct the toast message for the user.
      const importedMessage = numStoresImported
        ? this.$tkey('storesImported', [numStoresImported])
        : '';
      const deletedMessage = numStoresDeleted
        ? this.$tkey('storesDeleted', [numStoresDeleted])
        : '';

      if (importedMessage || deletedMessage) {
        this.showSnackbar({
          color: 'green',
          content: `${importedMessage} ${deletedMessage}`,
        });
      }
      await this.init();
      this.gridOptions.api.hideOverlay();

      if (commit) this.setSelectedScenario({});
      return true;
    },

    async init() {
      this.currentStateDiff = {};
      await this.initStores();
    },

    async initStores() {
      await this.fetchSingleWorkpackage({
        includeStores: true,
        workpackageId: this.selectedWorkpackage._id,
      });
      this.setSelectedWorkpackage(this.workpackage);
      this.stores = cloneDeep(this.workpackage.stores) || [];

      // identify errors on load
      if (this.isSimpleSwapsWP) this.checkClustersForStores();
      this.savedState = keyBy(cloneDeep(this.stores), 'storeKey');
    },

    resetSearchFilter() {
      this.searchString = '';
      agGridUtils.filters.resetFilters(this.gridOptions);
    },

    async closeModal() {
      this.beforeCloseModalWithUnsavedData('isPopupOpen');
      this.resetSearchFilter();
    },

    trackDiff(params, shouldWarn = true) {
      // check if this change has introduced an error
      if (this.isSimpleSwapsWP) this.checkClustersForStores();
      const { storeKey } = params.data;
      const { field } = params.colDef;
      this.updateDiff(storeKey, field, params.value);
      if (shouldWarn) this.warnMinAssortmentAnalysisStores();
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.columnApi.setColumnVisible('simpleSwapsCluster', this.isSimpleSwapsWP);
    },

    getMainMenuItems(params) {
      const colId = params.column.getId();
      const filteredT = this.$t('general.filtered');

      return [
        ...params.defaultItems,
        {
          name: `${this.$t('actions.selectAll')} (${filteredT})`,
          action: () => {
            this.handleHeaderCheckboxChange(colId, true);
          },
        },
        {
          name: `${this.$t('actions.unselectAll')} (${filteredT})`,
          action: () => {
            this.handleHeaderCheckboxChange(colId, false);
          },
        },
      ];
    },

    async runTemplateSetup(commit = false) {
      this.calledFromSetup = true;
      const shouldProceed = await this.saveImportedStores(commit);
      if (shouldProceed) this.globalEmit('run-template-setup');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .actions-row .v-btn .v-icon {
    color: $sub-navigation-link !important;
  }
}
.grid-size {
  overflow: hidden;
}

.workpackage-stores__link {
  font-size: 1.2rem;
  font-weight: bold;
  color: $assortment-primary-colour;
  padding: 0 !important;
  &::before,
  &::after {
    display: none !important;
  }

  ::v-deep {
    .v-icon {
      color: $assortment-primary-colour;
      transform: translate(-2px, 2px);
    }
  }
}

.dialog-card__footer {
  .actions-row {
    flex: auto;
  }
}

.action-panel {
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $assortment-filter-bar-bg-colour;
  border-top: 1px solid $assortment-primary-colour;
  padding: 0 12px;
  margin-top: 12px;
  margin-bottom: 17px;

  .action-panel-container {
    display: flex;
    align-items: center;
    justify-content: center;

    .panel-action-import {
      margin-right: 30px;
    }
  }

  .filter-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40px;

    .filter-label {
      color: #37424a;
      font-family: 'Source Sans Pro';
      font-size: 1.4rem;
      margin-right: 7px;
      letter-spacing: 0;
      line-height: 1.8rem;
    }
  }
}

.dropdown-btn-container {
  h4 {
    font-size: 1.2rem !important;
    color: $assortment-table-header-colour !important;
    font-weight: normal;
  }
}
</style>
