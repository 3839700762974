<template functional>
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>{{ parent.$tkey('actions.reset') }}</title>
    <g id="Icon-/-Reset" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Reset">
        <rect
          id="Rectangle"
          fill="#2F477C"
          transform="translate(9.000000, 9.000000) scale(-1, 1) translate(-9.000000, -9.000000) "
          x="0"
          y="0"
          width="18"
          height="18"
          rx="2"
        />
        <path
          id="Combined-Shape"
          d="M7.6993007,9.97674419 L3,6.48837209 L7.6993007,3 L7.699,5.558 L12.15,5.55813953 C13.7240115,5.55813953 15,6.834128 15,8.40813953 L15,10.15 C15,11.7240115 13.7240115,13 12.15,13 L7.04160839,13 C5.81552025,13 4.77026502,12.2257623 4.36807981,11.1395241 L11.6952797,11.1395349 C12.435991,11.1395349 13.044706,10.5743912 13.1137565,9.85177189 L13.1202797,9.71453488 L13.1202797,8.84360465 C13.1202797,8.10289334 12.555136,7.49417841 11.8325167,7.4251279 L11.6952797,7.41860465 L7.699,7.418 L7.6993007,9.97674419 Z"
          fill="#FFFFFF"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ResetIcon',
};
</script>
