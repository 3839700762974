<template>
  <v-row
    class="metrics-container clickable"
    data-id-e2e="switchingModellingMetricsCell"
    :class="getMetricsClass"
  >
    <v-menu v-model="showMenu" absolute offset-y :z-index="200" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-row v-bind="attrs" v-on="on">
          <!-- Cell content -->
          <v-col v-if="!!params.clusterId && !params.isNoDataCluster" class="unclustered-cb">
            <v-checkbox
              v-if="!isUsageOfUnclusteredDisabled"
              data-id-e2e="toggleClusterUseUnclusteredCheckbox"
              :input-value="isClusterCannGroupSetToUnclustered"
              class="rtls-checkbox mx-2"
              @change="setToUnclusterSingleClusterCannGroup"
            />
          </v-col>
          <v-col class="metric larger-font">
            <strong>
              {{ confidenceScore }}
            </strong>
          </v-col>
          <v-col class="metric"
            >{{ numSubsequentBaskets }} / {{ numSubsequentBasketsPercentOfTotal }}</v-col
          >
        </v-row>
      </template>

      <!-- Breakdown modal menu template -->
      <v-card data-id-e2e="switchingModellingMetricsBreakdownModal">
        <v-container class="d-flex flex-column breakdown-modal">
          <!-- Titles section -->
          <v-row>
            <v-col :cols="4" class="py-0 text-right">
              <b>{{ $tkey('cannGroup') }}</b>
            </v-col>
            <v-col class="py-0">
              <b>{{ params.cannGroupName }}</b>
            </v-col>
          </v-row>
          <v-row>
            <v-col :cols="4" class="pt-0 text-right">
              <b>{{ $tkey('cluster') }}</b>
            </v-col>
            <v-col class="pt-0">
              <b>{{ params.clusterName }}</b>
            </v-col>
          </v-row>

          <v-divider class="bold" />

          <!-- Methodology section -->
          <v-row>
            <v-col :cols="5" class="text-right">
              <b>{{ $tkey('switchingAnalysisMethodology') }}</b>
            </v-col>
            <v-col align-self="end">
              <b>{{ $t(methodologyInPlace) }}</b> {{ $tkey('score') }} <b>{{ confidenceScore }}</b>
            </v-col>
          </v-row>

          <v-divider />

          <!-- Transactions section -->
          <v-row>
            <v-col :cols="5" class="pb-1 text-right">
              <b>{{ $tkey('transactions') }}</b>
            </v-col>
          </v-row>

          <v-row>
            <v-col :cols="5" class="py-0 text-right">
              {{ $tkey('total') }}
            </v-col>
            <v-col class="py-0">
              <b>{{ numTotalBaskets }}</b>
            </v-col>
          </v-row>

          <v-row>
            <v-col :cols="5" class="py-0 text-right">
              {{ $tkey('withPriorLinked') }}
            </v-col>
            <v-col align-self="end">
              <b
                >{{ numSubsequentBaskets }} / {{ numSubsequentBasketsPercentOfTotal }}
                {{ $tkey('ofTotal') }}</b
              >
            </v-col>
          </v-row>

          <v-divider />

          <!-- Products section -->
          <v-row>
            <v-col :cols="5" class="pb-1 text-right">
              <b>{{ $tkey('products') }}</b>
            </v-col>
          </v-row>

          <v-row>
            <v-col :cols="5" class="py-0 text-right">
              {{ $tkey('count') }}
            </v-col>
            <v-col class="py-0">
              <b>{{ params.totalProductCount }}</b>
            </v-col>
          </v-row>

          <v-row>
            <v-col :cols="5" class="py-0 text-right">
              {{ $tkey('withSufficientSales') }}
            </v-col>
            <v-col align-self="end">
              <b>{{ params.productCountWithLinkedBaskets }}</b>
            </v-col>
          </v-row>

          <v-row>
            <v-col :cols="5" class="py-0 text-right">
              {{ $tkey('withThLinkedDataBasedScore') }}
            </v-col>
            <v-col align-self="end">
              <b>{{ confidenceScore }}</b>
            </v-col>
          </v-row>

          <v-divider />

          <!-- Stores section -->
          <v-row>
            <v-col :cols="5" class="pb-1 text-right">
              <b>{{ $tkey('stores') }}</b>
            </v-col>
          </v-row>

          <v-row>
            <v-col :cols="5" class="py-0 text-right">
              {{ $tkey('numInCluster') }}
            </v-col>
            <v-col class="pt-0">
              <b>{{ params.storeCount }}</b>
            </v-col>
          </v-row>

          <v-divider class="bold" />

          <!-- View switching link -->
          <v-row>
            <v-col class="pb-0 text-right">
              <span v-if="params.isNoDataCluster">
                {{ $t('switchingModellingPage.noClusterDataNoSwitching') }}
              </span>
              <v-btn
                v-else
                :ripple="false"
                text
                small
                class="view-switching__link"
                @click="viewSwitchingMatrices"
              >
                {{ $tkey('viewSwitching') }}
                <v-icon size="20">mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-menu>

    <switching-modelling-matrices-modal
      v-if="showSwitchingMatricesModal"
      :value="showSwitchingMatricesModal"
      :modal-data="switchingMatricesModalData"
      @close-modal="showSwitchingMatricesModal = false"
    />
  </v-row>
</template>

<script>
import Vue from 'vue';
import { find, get } from 'lodash';
import { mapGetters } from 'vuex';
import { switchingModellingTypes } from '@enums/switching-modelling-types';

export default Vue.extend({
  name: 'switchingModellingMetricsRenderer',
  localizationKey: 'switchingModellingPage.switchingMetricsBreakdownModal',
  data() {
    return {
      showMenu: false,
      showSwitchingMatricesModal: false,
    };
  },

  computed: {
    ...mapGetters('context', ['getClientConfig']),

    confidenceScore() {
      const score = get(this.params, 'metrics.confidenceScore', 0);

      // Return formatted percentage if score exists, otherwise return 0%
      return `${this.formatNumber({ number: score, format: 'percent' })}%`;
    },

    numTotalBaskets() {
      const numberOfBaskets = get(this.params, 'metrics.numBaskets', 0);

      return `${this.formatNumber({ number: numberOfBaskets, format: '000.000.000a' })}`;
    },

    numSubsequentBaskets() {
      const numberOfBaskets = get(this.params, 'metrics.numSubsequentBaskets', 0);

      // This should be formatted as number
      return `${this.formatNumber({ number: numberOfBaskets, format: '000.000.000a' })}`;
    },

    numSubsequentBasketsPercentOfTotal() {
      const basketsPercent = get(this.params, 'metrics.numSubsequentBasketsPercentOfTotal', 0);
      return basketsPercent
        ? `${this.formatNumber({ number: basketsPercent, format: 'percent' })}%`
        : 0;
    },

    similarityBias() {
      const similarityBias = get(this.params, 'data.similarityBias', '');
      return similarityBias
        ? `${this.formatNumber({ number: similarityBias * 100, format: 'percent' })}%`
        : '';
    },

    switchingMatricesModalData() {
      return {
        ...this.params,
        methodologyInPlace: this.methodologyInPlace,
        similarityBias: this.similarityBias,
      };
    },

    getMetricsClass() {
      const score = get(this.params, 'metrics.confidenceScore', '');
      const ranges = this.getClientConfig.switching.metricsRange;

      if (score < ranges.poor) return 'metrics-red';
      if (score < ranges.medium) return 'metrics-amber';
      return ''; // default gray for ok metrics
    },

    isUsageOfUnclusteredDisabled() {
      return !!this.params.data.similarityBias;
    },

    isClusterCannGroupSetToUnclustered() {
      return (
        this.params.data.cannGroupMatricesByCluster[this.params.clusterId].type ===
        switchingModellingTypes.setToUnclustered
      );
    },

    currentCannGroup() {
      return find(
        this.params.data.cannGroupMatrices,
        ({ clusterId }) => clusterId === this.params.clusterId
      );
    },

    methodologyInPlace() {
      if (this.params.isNoDataCluster) return 'general.notAvailable';
      if (
        this.params.clusterName === switchingModellingTypes.unclustered &&
        !this.currentCannGroup.type
      )
        return 'switchingModellingPage.unclustered';
      if (!this.currentCannGroup.type) return 'switchingModellingPage.cluster';
      return this.currentCannGroup.type === switchingModellingTypes.setToUnclustered
        ? 'switchingModellingPage.unclustered'
        : 'switchingModellingPage.similarityBias';
    },
  },

  created() {
    // We want to close the breakdown modal on escape event if it's open.
    // @keydown.esc won't work as the container is not an element in focus.
    // So adding an event listener here
    const onEscape = e => {
      if (this.showMenu && e.keyCode === 27) {
        this.showMenu = false;
      }
    };

    document.addEventListener('keydown', onEscape);
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', onEscape);
    });
  },

  methods: {
    viewSwitchingMatrices() {
      this.showSwitchingMatricesModal = true;
      this.showMenu = false;
    },

    setToUnclusterSingleClusterCannGroup(isChecked) {
      this.showSwitchingMatricesModal = false;
      this.showMenu = false;
      const payload = [
        {
          cannGroupId: this.params.cannGroupId,
          method: isChecked ? switchingModellingTypes.setToUnclustered : null,
          similarityBias: null,
          clusterId: this.params.clusterId,
          _id: this.params.data.cannGroupMatricesByCluster[this.params.clusterId]._id,
        },
      ];

      this.params.onSetToUnclusteredChange(payload);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.metrics-container {
  padding: 5px;
  height: 100%;
  width: 100%;
  align-items: center;

  background-color: $assortment-chip-color;
  background-clip: content-box;

  .metric {
    text-align: center;
    line-height: initial;

    &.larger-font {
      padding-top: 9px;
      font-size: larger;
      font-weight: 500;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  .unclustered-cb {
    padding-top: 9px;

    .rtls-checkbox {
      margin: 0 !important;
    }
  }

  ::v-deep {
    .v-input--checkbox i::before {
      color: white !important;
    }
  }
}

.breakdown-modal {
  width: 500px;
  font-size: 12px !important;

  .bold {
    border-top: 2px solid;
  }

  .view-switching__link {
    font-size: 1.2rem;
    font-weight: bold;
    color: $assortment-primary-colour;
    padding: 0 !important;
    &::before,
    &::after {
      display: none !important;
    }

    ::v-deep {
      .v-icon {
        color: $assortment-primary-colour;
      }
    }
  }
}

.metrics-red {
  background-color: $assortment-sales-confidence-score-red !important;
  color: white;
}
.metrics-amber {
  background-color: $assortment-sales-confidence-score-orange !important;
  color: white;
}
</style>
