<template>
  <div>
    <h2 v-if="shortView" class="ml-3 mt-2">{{ getTitle }}</h2>
    <div v-else class="header-container">
      <div class="d-flex">
        <h2 class="reporting-title-detailed d-flex align-center pr-5 mr-6 ml-1">
          {{ getTitle }}
        </h2>
        <slot name="metric" />
        <slot name="attribute" />
        <slot name="filter" />
        <slot name="toolbar" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  localizationKey: 'reportingPage.sections',

  props: {
    // represents whether this report is open on (short) overview page
    shortView: {
      type: Boolean,
      required: false,
      default: false,
    },
    section: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    getTitle() {
      return this.title || this.$tkey(`${this.section}.title`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.header-container {
  padding: 8px;
  padding-bottom: 0px;
}
</style>
