import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    [
      _c(
        VRow,
        { staticClass: "ma-0" },
        [
          _c(
            VCol,
            { staticClass: "d-flex justify-end pa-0" },
            [
              _c(
                VBtn,
                {
                  attrs: {
                    "data-id-e2e": "btnCreateCheckpoint",
                    disabled: _vm.creatingCheckpoint || _vm.isEditingDisabled,
                    primary: "",
                  },
                  on: { click: _vm.createCheckpoint },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("actions.createCheckpoint")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        { staticClass: "ma-0" },
        [
          _c(
            VCol,
            { staticClass: "pa-0", attrs: { cols: "12" } },
            [
              _c(
                VList,
                { staticClass: "checkpoints-list" },
                _vm._l(_vm.checkpoints || [], function (checkpoint) {
                  return _c(
                    VListItem,
                    {
                      key: checkpoint._id,
                      staticClass: "checkpoints-list__item",
                    },
                    [
                      _c("scenario-checkpoints-list-item", {
                        attrs: {
                          "checkpoint-id": checkpoint._id,
                          "remaining-checkpoints":
                            _vm.getRemainingCheckpoints(checkpoint),
                        },
                        on: {
                          close: function ($event) {
                            return _vm.$emit("close")
                          },
                        },
                      }),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }