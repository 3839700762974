import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.shouldDisplay
    ? _c(
        "div",
        {
          staticClass: "d-flex flex-column w-100",
          attrs: { "data-id-e2e": "spacebreakConstraintsTable" },
        },
        [
          _vm.isCopyingConstraints
            ? _c("div", { staticClass: "horizontal-rule" })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "spacebreak-title d-flex align-center" }, [
            _c("h2", [
              _vm._v(
                _vm._s(
                  _vm.isCopyingConstraints
                    ? _vm.currentSpacebreakName
                    : _vm.$tkey("title")
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-column grid-container h-100 w-100" },
            [
              _c("ag-grid-vue", {
                staticClass: "ag-theme-custom-yellow-text h-100 w-100",
                attrs: {
                  id: "spacebreak-constraints-grid",
                  "column-defs": _vm.columnDefs,
                  "row-data": _vm.rowData,
                  "auto-params-refresh": "",
                  "dom-layout": "autoHeight",
                  "grid-options": _vm.gridOptions,
                  "row-drag-managed": true,
                  "animate-rows": true,
                  icons: _vm.icons,
                  "header-height":
                    _vm.isCopyingConstraints && !_vm.isGlobalSpacebreak
                      ? 0
                      : 40,
                },
                on: {
                  "drag-stopped": _vm.updateRanking,
                  "grid-ready": _vm.onGridReady,
                  "row-selected": _vm.onRowSelected,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isCopyingConstraints
            ? _c(
                VRow,
                { staticClass: "d-flex mt-3 justify-space-between" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-start" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "ml-2",
                          attrs: {
                            disabled: _vm.editingMode || _vm.isEditingDisabled,
                            "data-id-e2e": "btnAddConstraint",
                            primary: "",
                            depressed: "",
                            "data-dd-action-name": "AddNewConstraintBtn",
                          },
                          on: { click: _vm.addConstraint },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$tkey("addConstraint")) +
                              "\n        "
                          ),
                          _c(VIcon, { attrs: { size: "14", right: "" } }, [
                            _vm._v("mdi-plus"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.hasCopyConstraintsEnabled
                        ? _c(
                            "div",
                            [
                              _c(
                                VBtn,
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    disabled:
                                      _vm.editingMode || _vm.isEditingDisabled,
                                    "data-id-e2e":
                                      "btnOpenCopyConstraintsModal",
                                    "data-dd-action-name": "CopyConstraintsBtn",
                                    secondary: "",
                                    depressed: "",
                                  },
                                  on: { click: _vm.openCopyConstraintsModal },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.$tkey("copyConstraints")) +
                                      "\n          "
                                  ),
                                  _c(
                                    VIcon,
                                    { attrs: { size: "14", right: "" } },
                                    [_vm._v("$duplicate")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "docs-link" },
                                [
                                  _c("docs-link", {
                                    attrs: {
                                      link: "toolguide/120-assortment.html#copy-constraints",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "font-weight-bold remove-btn",
                          attrs: {
                            text: "",
                            depressed: "",
                            "data-id-e2e": "btnRemoveAllConstraints",
                            "data-dd-action-name": "RemoveAllConstraintsBtn",
                            disabled:
                              _vm.constraintsCount === 0 ||
                              _vm.isEditingDisabled,
                          },
                          on: { click: _vm.showRemoveAllConfirmation },
                        },
                        [_vm._v(_vm._s(_vm.$tkey("removeAll")))]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.isCopyingConstraints
            ? _c("main-dialog", {
                ref: "confirm",
                attrs: {
                  title: _vm.$tkey("removeAllConfirmation.title"),
                  message: _vm.$tkey("removeAllConfirmation.message", {
                    spacebreakName: _vm.spacebreakName,
                  }),
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "actions",
                      fn: function ({ cancel: close }) {
                        return [
                          _c(
                            VRow,
                            [
                              _c(
                                VCol,
                                { staticClass: "d-flex justify-end" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { action: "" },
                                      on: {
                                        click: function ($event) {
                                          ;[_vm.removeAllConstraints(), close()]
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$tkey(
                                              "removeAllConfirmation.confirm"
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "ml-2",
                                      on: {
                                        click: function ($event) {
                                          return close()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$tkey(
                                              "removeAllConfirmation.cancel"
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  206745664
                ),
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }