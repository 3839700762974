'use strict';

const DataTypes = require('../../enums/data-types');

const fixedFields = [
  {
    mongoField: 'storeKeyDisplay',
    type: DataTypes.alphanumeric,
    // The comparison function uses Lodash _.lowerCase which converts string, as space separated words, to lower case.
    // 'Store Key' === 'store key'  |  'storeKey' === 'store key'  |  '--Store-Key--' === 'store key'
    aliases: 'csvAliases.workpackageStores.storeKeyDisplay',
    translationKey: 'mongoFields.storeKeyDisplay',
    required: true,
  },
  {
    mongoField: 'analysis',
    type: DataTypes.boolean,
    aliases: 'csvAliases.workpackageStores.analysis',
    translationKey: 'mongoFields.analysis',
    required: true,
  },
  {
    mongoField: 'assortment',
    type: DataTypes.boolean,
    aliases: 'csvAliases.workpackageStores.assortment',
    translationKey: 'mongoFields.assortment',
    required: true,
  },
];

module.exports = {
  fixedFields,
};
