import { VBtn } from 'vuetify/lib/components/VBtn';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { "data-id-e2e": "btnDownloadRenderer" } },
    [
      _vm.isExtractGenerationJobRunning
        ? _c(VRow, { staticClass: "generating-row" }, [
            _c("span", { staticClass: "generating-text" }, [
              _vm._v(_vm._s(_vm.$t("actions.generating"))),
            ]),
            _vm._v(" "),
            _c("img", {
              staticClass: "spinner-icon",
              attrs: { src: require("../../../img/spinner.gif") },
            }),
          ])
        : _c(
            "download-link",
            {
              attrs: {
                filename: _vm.extract.filenameOnDownload,
                url: _vm.buildLink(_vm.extract.blobId),
                disabled: _vm.isDisabled,
              },
            },
            [
              _c(
                VBtn,
                {
                  staticClass: "download-extracts__button",
                  attrs: {
                    "data-id-e2e": "btnDownloadExtracts",
                    secondary: "",
                    depressed: "",
                    disabled: _vm.isDisabled,
                  },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("actions.download")) + "\n      "
                  ),
                  _c("img", {
                    staticClass: "download-extracts__icon",
                    attrs: {
                      src: _vm.downloadExtractIcon,
                      alt: "Download extract icon",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }