import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "main-product-tile", staticStyle: { display: "contents" } },
    [
      _c(
        "div",
        {
          staticClass: "d-flex w-100 h-100 pl-2",
          class: {
            "spacebreak-disabled": _vm.isSpacebreakLocked(_vm.spacebreakId),
            "selected-product-list-view": _vm.isProductSelected,
          },
        },
        [
          _vm.product.isHighlighted
            ? _c("highlighted-area", {
                attrs: { "outer-class": "w-100", "list-view": "" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.visibleListViewColumns, function (column) {
            return _c(
              VCol,
              {
                key: column.id,
                class: column.cellClass,
                attrs: { title: _vm.product[column.id] },
              },
              [
                column.id === "rowIcons"
                  ? [
                      _vm.showMinimumDistribution &&
                      _vm.product.minimumDistribution > 0
                        ? _c(
                            VIcon,
                            {
                              staticClass: "d-flex product-icon",
                              attrs: {
                                color: "white",
                                size: "18",
                                height: "26px",
                                width: "26px",
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s("mdi-speedometer") +
                                  "\n        "
                              ),
                            ]
                          )
                        : _c("div", {
                            staticClass:
                              "product-icon product-icon--hidden h-100 distribution",
                          }),
                      _vm._v(" "),
                      _vm.product.productSeries
                        ? _c(
                            "div",
                            {
                              staticClass: "d-flex product product-icon",
                              staticStyle: { cursor: "pointer" },
                              on: { click: _vm.onProductSeriesIconClick },
                            },
                            [_c("series-icon")],
                            1
                          )
                        : _c("div", {
                            staticClass:
                              "h-100 product-icon product-icon--hidden series",
                          }),
                      _vm._v(" "),
                      _vm.hasPallets
                        ? _c(
                            "div",
                            { staticClass: "product-icon" },
                            [
                              _c("pallet-icon", {
                                attrs: {
                                  fill: _vm.hasAssignedPallets
                                    ? _vm.iconPrimary
                                    : _vm.iconDisabled,
                                },
                              }),
                            ],
                            1
                          )
                        : _c("div", {
                            staticClass:
                              "product-icon product-icon--hidden h-100 pallet",
                          }),
                      _vm._v(" "),
                      !_vm.inReset
                        ? _c(
                            "div",
                            { staticClass: "d-flex product-icon" },
                            [
                              _c("not-in-reset-icon", {
                                attrs: { color: "white" },
                              }),
                            ],
                            1
                          )
                        : _c("div", {
                            staticClass:
                              "product-icon product-icon--hidden h-100 reset",
                          }),
                      _vm._v(" "),
                      _c(
                        VIcon,
                        {
                          staticClass: "product-icon lock-icon",
                          class: {
                            "lock-icon__locked": _vm.isLocked(_vm.product),
                          },
                          attrs: {
                            disabled:
                              _vm.isSpacebreakLocked(_vm.spacebreakId) ||
                              _vm.isEditingDisabled ||
                              !_vm.inReset ||
                              !_vm.canProductBeMoved,
                            color: "white",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleLock(_vm.product)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.isLocked(_vm.product)
                                  ? "mdi-lock-outline"
                                  : "mdi-lock-open-variant-outline"
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  : column.id === "deltaTag"
                  ? [
                      _c("product-delta-tag", {
                        staticClass: "mr-1",
                        attrs: {
                          show:
                            _vm.getReferenceDelta !== 0 &&
                            _vm.product.isEligible,
                          type: "R",
                          delta: _vm.getReferenceDelta,
                        },
                      }),
                      _vm._v(" "),
                      _c("assortment-tag", {
                        staticClass: "mr-1",
                        attrs: { "tag-text": _vm.getProductTag },
                      }),
                      _vm._v(" "),
                      _c("product-delta-tag", {
                        staticClass: "mr-1",
                        attrs: {
                          show:
                            _vm.isOptimised &&
                            _vm.getOptimisedDelta !== 0 &&
                            _vm.product.isEligible,
                          type: "O",
                          delta: _vm.getOptimisedDelta,
                        },
                      }),
                    ]
                  : _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.getColumnValue(column)) +
                          "\n      "
                      ),
                    ]),
              ],
              2
            )
          }),
          _vm._v(" "),
          _c(
            VCol,
            { staticClass: "pt-1 pb-1 d-flex justify-end product-info-column" },
            [
              _c(
                VIcon,
                {
                  staticClass: "info-icon mr-1",
                  attrs: {
                    id: `product-tile-popup-${_vm.product.productKey}`,
                    small: "",
                  },
                  on: { click: (event) => _vm.openPopup(event) },
                },
                [_vm._v("\n        info\n      ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _vm.show
        ? _c("product-draggable-tooltip", {
            attrs: {
              "selected-kpis": _vm.clientConfig.selectedKpis,
              "product-info": _vm.product,
              "product-index": _vm.index,
              "pod-and-store-count-info": _vm.podAndStoreCounts(
                _vm.product,
                _vm.selectedCanvas.clusterId,
                _vm.spacebreaksIndexedByIdByCluster
              ),
              "product-positioning-info": _vm.extractProductPositioningInfo(
                _vm.product,
                _vm.selectedCanvas.clusterId,
                _vm.getSpacebreaksIndexedById,
                _vm.selectedPod
              ),
              settings: _vm.tooltipSettings,
              "conditional-product-tile-style": _vm.dashboardProductTileSize,
              "image-url": _vm.imageUrl,
              "image-title": _vm.displayText,
              "image-alt": _vm.displayText,
              spacebreaks: _vm.spacebreaksNames,
              "selected-spacebreak": _vm.selectedSpacebreak,
              "spacebreak-disabled": _vm.isSpacebreakLocked(_vm.spacebreakId),
              "spacebreak-types": _vm.spacebreakTypes,
              "change-spacebreak": _vm.changeSpacebreak,
              "lock-types": _vm.lockTypes,
              "popup-button": _vm.popupButton,
              "image-tag-text": _vm.getProductTag,
              "product-pallets": _vm.productPallets,
              "current-numeric-locale": _vm.currentNumericLocale,
            },
            on: { "close-popup": _vm.closePopup },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }