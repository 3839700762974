<template>
  <div class="mt-2">
    <div>
      {{ product.itemDescription }} ({{
        formatNumber({ number: product.palletSize, format: 'float' })
      }})
    </div>
    <rtls-select
      :value="value"
      :items="smallerSpacebreaks"
      :menu-props="{ top: true, offsetY: true, maxWidth: 265 }"
      @change="changeSpacebreak($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import productMixin from './product.mixin';

export default {
  name: 'ProductPallet',
  mixins: [productMixin], // includes methods extractProductPositioningInfo
  props: {
    product: {
      type: Object,
      required: true,
    },
    palletContentSpacebreak: {
      type: Object,
      required: true,
    },
    allPallets: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters('furniture', ['getSpacebreaksIndexedById']),
    ...mapGetters('assortmentCanvas', ['selectedCanvas']),
    value() {
      // This makes the spacebreak with null value default
      return get(this.selectedSpacebreak, 'value', null);
    },

    selectedSpacebreakSet() {
      return this.allPallets.reduce((acc, pallet) => {
        if (!pallet.currentSpacebreakId) return acc;
        acc.add(pallet.currentSpacebreakId);
        return acc;
      }, new Set([]));
    },

    smallerSpacebreaks() {
      return this.spacebreaksNames.filter(
        sb =>
          (sb.fillOverride >= this.palletContentSpacebreak.fillOverride || sb.fillOverride === 0) &&
          (!this.selectedSpacebreakSet.has(sb.value) || sb.value === this.value)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.select-container {
  width: 100%;
  position: relative;

  .appended-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);

    &::before {
      font-size: 24px;
    }
  }
}

.spacebreak-select {
  padding-left: 5px;
  width: 100%;
  height: 30px;
  border-bottom: 1px solid $assortment-primary-colour;
  outline: none;
  background: $assortment-control-secondary-bg-colour;
}
</style>
