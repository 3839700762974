<template functional>
  <svg
    width="18"
    height="18"
    viewBox="-1.5 -2 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon no hover">
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.666667 1.33333L0.666667 10.3333H0L0 14.6667H2.66667L2.66667 13L6.66667 13V14.6667H9.33333V13H13.3333V14.6667H16V10.3333H15.3333L15.3333 6L10.6667 6V1.33333L0.666667 1.33333ZM5 2.33333L1.66667 2.33333L1.66667 5.66667L5 5.66667L5 2.33333ZM15 11.3333V12L1 12L1 11.3333L15 11.3333ZM14.3333 7V10.3333H11V7L14.3333 7ZM6.33333 10.3333H9.66667V7L6.33333 7L6.33333 10.3333ZM1.66667 7L5 7L5 10.3333H1.66667L1.66667 7ZM1 13H1.66667L1.66667 13.6667H1L1 13ZM14.3333 13V13.6667H15V13H14.3333ZM7.66667 13H8.33333V13.6667H7.66667V13ZM6.33333 2.33333L9.66667 2.33333V5.66667L6.33333 5.66667L6.33333 2.33333Z"
        :fill="props.fill"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PalletIcon',

  props: {
    fill: {
      type: String,
      required: false,
      default: 'white',
    },
  },
};
</script>
