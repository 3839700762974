import { render, staticRenderFns } from "./assortment-tooltip.vue?vue&type=template&id=e2aaa446&scoped=true"
import script from "./assortment-tooltip.vue?vue&type=script&lang=js"
export * from "./assortment-tooltip.vue?vue&type=script&lang=js"
import style0 from "./assortment-tooltip.vue?vue&type=style&index=0&id=e2aaa446&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2aaa446",
  null
  
)

export default component.exports