import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: { value: _vm.value, width: "500px" },
      on: { "click:outside": _vm.closeModal },
    },
    [
      _vm.value
        ? _c(
            "dialog-card",
            {
              attrs: {
                title: _vm.$t(
                  "reportingPage.sections.product.sideBar.toolPanels.layouts.saveLayout"
                ),
              },
              on: { close: _vm.closeModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("page-actions", {
                          attrs: {
                            "has-data-changes": !!_vm.layoutName.length,
                            "has-data-errors": false,
                            "show-discard": false,
                            "save-btn-text": "save",
                            "save-loading": _vm.isSaving,
                          },
                          on: { save: _vm.saveLayout },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2029073031
              ),
            },
            [
              _c(
                VContainer,
                {
                  staticClass: "pa-0 reset-product-data__container",
                  attrs: { fluid: "" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "mt-4 pb-5" },
                    [
                      _c(VRow, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "reportingPage.sections.product.sideBar.toolPanels.layouts.layoutName"
                                )
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        VRow,
                        [
                          _c("rtls-text-field", {
                            staticClass: "mr-2 ml-4 my-2",
                            staticStyle: { width: "80%" },
                            attrs: { grey: "" },
                            model: {
                              value: _vm.layoutName,
                              callback: function ($$v) {
                                _vm.layoutName = $$v
                              },
                              expression: "layoutName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }