import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-row" },
    [
      _c("h4", { staticClass: "header align-self-center" }, [
        _vm._v(_vm._s(_vm.$t("workpackagePage.wpTypeLabel"))),
      ]),
      _vm._v(" "),
      _c(
        VSelect,
        {
          staticClass: "wp-select ml-2",
          attrs: {
            "hide-details": "",
            height: "23px",
            required: "",
            disabled: _vm.selectionDisabled,
            value: _vm.type,
            items: _vm.types,
            rules: _vm.rules,
            placeholder: _vm.$t("workpackagePage.scope.filterByCategoryLabel"),
            "menu-props": { bottom: true, offsetY: true },
          },
          on: {
            change: function ($event) {
              return _vm.onChange("type", $event)
            },
          },
        },
        [
          _c(
            "template",
            { slot: "append" },
            [_c(VIcon, { attrs: { size: "28" } }, [_vm._v("expand_more")])],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }