/**
 * Mixin that can be used in every component for input validations.
 * Steps to use:
 * - Import this into the component import inputValidationMixin from 'some/path/mixins/input-validations';
 * - Add it as a mixin: mixins: [inputValidationMixin],
 * - When using the vuetify text-field component:
 *    <v-text-field v-model="someModel" :rules="[this.required]" />
 * - This will render the text field in an error state if it is empty.
 * - More rules can be added to the array if needed.
 */
import numberUtils from '@/js/utils/number-format-utils';

const { isEmpty, isFinite, includes } = require('lodash');

const parseValue = value => {
  const stringValue = String(value);
  // handle case where () format is used for negative numbers
  if (stringValue.startsWith('(') && stringValue.endsWith(')')) {
    return stringValue.substring(1, stringValue.length - 1);
  }
  return stringValue;
};

const mixin = {
  methods: {
    required(value) {
      return value === 0 || !!value || this.$t('validationErrors.required');
    },

    isInteger(value) {
      // AOV3-609 TODO: investigate why value is string after edit.
      const stringValue = parseValue(value);
      if (!numberUtils.validNumericString(stringValue)) return this.$t('validationErrors.integer');
      const sanitizedValue = !isEmpty(stringValue)
        ? numberUtils.formatStringToNumber(value)
        : stringValue;
      return Number.isInteger(sanitizedValue) || this.$t('validationErrors.integer');
    },

    isNumber(value) {
      if (isEmpty(String(value))) return true;
      const stringValue = parseValue(value);

      if (!numberUtils.validNumericString(stringValue)) return this.$t('validationErrors.number');
      const sanitizedValue = numberUtils.formatStringToNumber(value);
      if (!isFinite(sanitizedValue)) {
        return this.$t('validationErrors.number');
      }
      return true;
    },

    isLessThan(value, maximum) {
      const sanitizedValue = numberUtils.formatStringToNumber(value);
      return (
        sanitizedValue <= maximum ||
        this.$t('validationErrors.numberIsTooBig', [
          this.formatNumber({ number: maximum, format: 'float' }),
        ])
      );
    },

    isAlphanumeric(value) {
      return /^[A-Za-z0-9]+$/.test(value) || this.$t('validationErrors.alphanumeric');
    },

    isPositive(value) {
      const strValue = String(value);
      const sanitizedValue = numberUtils.formatStringToNumber(strValue);
      return (
        (this.isNumber(sanitizedValue) === true && sanitizedValue > 0) ||
        this.$t('validationErrors.numberNotPositive')
      );
    },

    isNotEmpty(value) {
      return !isEmpty(String(value).trim()) || this.$t('validationErrors.required');
    },

    isGreaterThan(value, minimum) {
      if (isEmpty(String(value))) return true;
      const sanitizedValue = numberUtils.formatStringToNumber(value);
      return sanitizedValue > minimum || this.$t('validationErrors.numberIsLessThan', [minimum]);
    },

    isGreaterOrEqual(value, minimum) {
      if (isEmpty(String(value))) return true;
      const sanitizedValue = numberUtils.formatStringToNumber(value);
      return (
        sanitizedValue >= minimum ||
        this.$t('validationErrors.numberMustBeBiggerOrEqual', [minimum])
      );
    },

    isNameUnique(newName, currentName, nameList, entity) {
      // v-model.trim gotcha https://github.com/vuejs/vue/issues/5847
      const trimmedNewName = newName.trim();
      // It is allowed to be the same name, the filter would not handle this edge case.
      if (currentName === trimmedNewName) return true;
      if (includes(nameList, trimmedNewName)) {
        return this.$t('validationErrors.unique', [entity]);
      }
      return true;
    },
  },
};

export default mixin;
