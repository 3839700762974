'use strict';

const modellingTypes = {
  modelled: 'modelled',
  modelledInCluster: 'modelledInCluster',
  established: 'established',
  new: 'new',
  semiNew: 'semiNew',
  rom: 'rom',
};

const toolGenerated = {
  translation: 'toolGenerated',
  style: 'tool',
};

const userGenerated = {
  translation: 'userGenerated',
  style: 'tool',
};

const newProduct = {
  translation: 'newProduct',
  style: 'new',
};

const semiNewProduct = {
  translation: 'semiNewProduct',
  style: 'semiNew',
};

const existingProduct = {
  translation: 'existingProduct',
  style: 'current',
};

const romProduct = {
  translation: 'romProduct',
  style: 'rom',
};

module.exports = {
  modellingTypes,
  toolGenerated,
  userGenerated,
  newProduct,
  semiNewProduct,
  existingProduct,
  romProduct,
};
