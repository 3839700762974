import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VRow,
    { staticClass: "previous-extract-report-row" },
    [
      _c(
        VCol,
        { staticClass: "row-item font-weight-bold", attrs: { cols: "3" } },
        [
          _c(
            "div",
            {
              staticClass: "overflow-ellipsis",
              attrs: { title: _vm.report.filenameOnDownload },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.report.filenameOnDownload) + "\n    "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(VCol, { staticClass: "row-item", attrs: { cols: "3" } }, [
        _vm._v(
          _vm._s(
            _vm._f("formatDate")(
              _vm.report.extractDatetime,
              _vm.dateFormats.longWithTime
            )
          )
        ),
      ]),
      _vm._v(" "),
      _c(VCol, { staticClass: "row-item", attrs: { cols: "3" } }, [
        _vm._v(
          _vm._s(_vm.$t(`extract.reports.reportTypes.${_vm.reportTypeKey}`))
        ),
      ]),
      _vm._v(" "),
      _c(
        VCol,
        { staticClass: "row-item justify-center", attrs: { cols: "2" } },
        [
          _vm.report.status
            ? _c(VIcon, { attrs: { size: "18" } }, [
                _vm._v(_vm._s(`$${_vm.report.status}`)),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VCol,
        { staticClass: "row-item justify-center", attrs: { cols: "1" } },
        [
          _vm.isExternalExtract && _vm.qaControlsEnabled
            ? _c(
                "div",
                [
                  _c(
                    VBtn,
                    { attrs: { icon: "" }, on: { click: _vm.devTestExtract } },
                    [
                      _c(
                        VIcon,
                        { attrs: { color: "primary", size: "18" } },
                        [_vm._v("\n          mdi-plus-box\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "download-link",
                {
                  attrs: {
                    filename: _vm.report.filenameOnDownload,
                    visible: _vm.isDownloadLinkVisible,
                    url: _vm.buildLink(_vm.report),
                    disabled: _vm.isDownloadDisabled,
                  },
                },
                [
                  _c(
                    VBtn,
                    { attrs: { disabled: _vm.isDownloadDisabled, icon: "" } },
                    [
                      _c(
                        VIcon,
                        { attrs: { color: "primary", size: "18" } },
                        [_vm._v("\n          mdi-download\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }