import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "width-100 h-100" },
    [
      _c(
        "report-input-item",
        {
          attrs: {
            title: _vm.title,
            "data-id-e2e": "checkpointsSelectionTable",
          },
        },
        [
          _vm.clusteredToggle
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex align-left justify-left checkbox-container mb-1",
                },
                [
                  _c("div", { staticClass: "checkbox-label" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tkey("useUnclustered")) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VCheckbox, {
                    staticClass: "rtls-checkbox mx-2",
                    attrs: { "input-value": _vm.useUnclustered },
                    on: {
                      change: function ($event) {
                        _vm.useUnclustered = $event
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(VDataTable, {
            staticClass:
              "striped-table simple-assortments-table simple-assortments-table--extracts",
            attrs: {
              headers: _vm.headers,
              items: _vm.filteredCanvases,
              "max-height": "maxHeight",
              dense: "",
              "fixed-header": "",
              "disable-sort": "",
              "disable-pagination": "",
              "hide-default-footer": "",
            },
            scopedSlots: _vm._u([
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [
                    _c(VIcon, { attrs: { size: "18" } }, [
                      _vm._v(_vm._s(`$${item.status}`)),
                    ]),
                  ]
                },
              },
              {
                key: "item.checkpoint",
                fn: function ({ item }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c(VAutocomplete, {
                          ref: "autocomplete",
                          attrs: {
                            "data-id-e2e": "checkpointsSelectionAutocomplete",
                            items: _vm.filteredCheckpointsByCanvasId[item._id],
                            filter: _vm.filterCheckpoints,
                            placeholder: _vm.$tkey("placeholders.choose"),
                            "menu-props": { auto: true },
                            "search-input": _vm.searchText[item._id],
                            rules: _vm.rules,
                            "background-color": "white",
                            width: "240px",
                            height: "25px",
                            "hide-messages": "",
                          },
                          on: {
                            "update:searchInput": function ($event) {
                              return _vm.$set(_vm.searchText, item._id, $event)
                            },
                            "update:search-input": function ($event) {
                              return _vm.$set(_vm.searchText, item._id, $event)
                            },
                            change: function ($event) {
                              return _vm.updateSelectedCheckpoints()
                            },
                          },
                          model: {
                            value: _vm.selectedCheckpoints[item._id],
                            callback: function ($$v) {
                              _vm.$set(_vm.selectedCheckpoints, item._id, $$v)
                            },
                            expression: "selectedCheckpoints[item._id]",
                          },
                        }),
                        _vm._v(" "),
                        _c("error-triangle", {
                          staticClass: "ml-2 mr-2",
                          style: {
                            visibility: _vm.isCheckpointError
                              ? "visible"
                              : "hidden",
                          },
                          attrs: {
                            errors: {
                              [_vm.$tkey("checkpointErrorMsg")]:
                                !_vm.isCheckpointError,
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.canViewCheckpointData
                          ? _c(
                              VIcon,
                              {
                                staticClass: "material-icons icon mr-3",
                                attrs: {
                                  color: "primary",
                                  disabled: !_vm.selectedCheckpoints[item._id],
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewCheckpointData(item._id)
                                  },
                                },
                              },
                              [_vm._v("visibility")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }