<template>
  <v-row class="d-flex h-100">
    <v-alert v-if="storesWithNoCoordinates.length" type="info" text class="ma-2 info-message w-100">
      {{ $tkey('infoMessage', { stores: storesWithNoCoordinates.map(s => s.storeKeyDisplay) }) }}
    </v-alert>
    <div
      class="position-relative w-100 h-100 leaflet-container leaflet-touch leaflet-fade-anim leaflet-grab leaflet-touch-drag leaflet-touch-zoom"
      tabindex="0"
    >
      <div id="map" class="w-100" style="height:100%" />
      <sidepanel v-if="showSidepanel" :title="$tkey('legend')" :toggle-btn-text="$tkey('legend')">
        <v-card outlined class="legend">
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="cluster in schemeClusters"
                :key="cluster.clusterId"
                :value="cluster.clusterId"
              >
                <v-list-item-content>
                  <v-list-item-title class="cluster">
                    <v-row class="cluster-name">
                      <v-col :cols="2">
                        <v-avatar :color="markerColourOptions[cluster.colour]" size="30" />
                      </v-col>
                      <v-col :cols="8">
                        {{ cluster.clusterName }}<br />
                        <b>{{ $tkey('stores') }}: </b> {{ cluster.storeKeys.length }}
                      </v-col>
                      <v-col :cols="2">
                        <v-checkbox v-model="legendSelectedClusters" :value="cluster.clusterId" />
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </sidepanel>
    </div>
  </v-row>
</template>

<script>
import L from 'leaflet';
import 'leaflet.awesome-markers';
import 'leaflet.markercluster';
import '../../../../utils/titleLayer.grayscale';
import { get, pick, find, min, max, isEmpty } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import owColours from '@/js/ow-colors';

const markerColourOptions = owColours.clusteringColours;
export default {
  name: 'StoreAllocationMap',
  localizationKey: 'clusteringPage.tabs.storeAllocation.map',

  data() {
    return {
      markerColourOptions,
      markerColours: Object.keys(markerColourOptions), // sensible ordered colours
      selectedColours: [],
      mapLayers: {},
      showSidepanel: true,
      legendSelectedClusters: [],
      map: null,
      markers: [],
      storesWithNoCoordinates: [],
    };
  },

  computed: {
    ...mapState('context', ['clientConfig']),
    ...mapState('clustering', ['selectedScheme']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('scenarios', ['stores']),

    storesGroupedByScheme() {
      const stores = [];
      this.schemeClusters.forEach(c => {
        const { clusterName, clusterId } = c;
        c.storeKeys.forEach(storeKey => {
          const store = pick(find(this.stores, { storeKey }), [
            'storeKeyDisplay',
            'formatDescription',
            'storeDescription',
            'latitude',
            'longitude',
          ]);

          stores.push({ clusterName, clusterId, ...store });
        });
      });
      return stores;
    },

    schemeClusters() {
      return this.selectedScheme.clusters.map(cluster => {
        return { ...cluster, ...{ colour: `${this.pickColour()}` } };
      });
    },
  },

  watch: {
    legendSelectedClusters() {
      // remove markers
      Object.keys(this.mapLayers).forEach(sc => {
        this.mapLayers[sc].removeFrom(this.map);
      });

      // apply markers from selected layers
      this.legendSelectedClusters.forEach(sc => {
        this.mapLayers[sc].addTo(this.map);
      });
      this.applyMapBoundaries();
    },
  },

  mounted() {
    // Create map
    const { attributionControl, defaultCenter } = this.clientConfig.clusteringMap;
    let { defaultZoom } = this.clientConfig.clusteringMap;
    if (this.storesWithNoCoordinates.length === this.storesGroupedByScheme.length) {
      defaultZoom = 2;
    }

    this.map = L.map('map', {
      center: defaultCenter,
      zoom: defaultZoom,
      minZoom: 2, // min zoom of 2 defaults to the world view
      layers: [this.createGrayScaleLayer()],
      attributionControl,
    });

    // Create map marker cluster layers
    const layerGroups = {};
    this.schemeClusters.forEach(cluster => {
      layerGroups[cluster.clusterId] = this.registerGroupLayer(cluster);
    });

    // select all markers
    this.legendSelectedClusters = Object.keys(layerGroups);
    this.legendSelectedClusters.forEach(k => {
      const layer = get(layerGroups[k], 'markers');
      if (layer) {
        this.mapLayers[k] = layer;
      }
    });
  },

  methods: {
    createGrayScaleLayer() {
      const { uiId, defaultMapUI } = this.clientConfig.clusteringMap;

      return L.tileLayer.grayscale(defaultMapUI, { id: uiId });
    },

    registerGroupLayer(cluster) {
      const { clusterId, colour } = cluster;
      const markerClusterGroup = L.markerClusterGroup({
        iconCreateFunction: c =>
          L.divIcon({
            className: `awesome-marker-icon-${colour} awesome-marker`,
            html: `<span class="awesome-marker__count">${c.getChildCount()}</span>`,
            iconSize: [35, 45],
          }),
      });
      // add auto generated marker icon
      const stores = this.storesGroupedByScheme.filter(
        store => store.clusterId === cluster.clusterId
      );
      stores.forEach(store => {
        const latitude = get(store, 'latitude');
        const longitude = get(store, 'longitude');
        if (!latitude || !longitude) {
          this.storesWithNoCoordinates.push(store);
        } else {
          this.createMarker(store, colour).addTo(markerClusterGroup);
          this.markers.push({ latitude, longitude, clusterId });
        }
      });
      this.map.addLayer(markerClusterGroup);
      return { markers: markerClusterGroup };
    },

    createMarker(store, colour) {
      const { latitude, longitude } = store;
      const markerIcon = L.AwesomeMarkers.icon({
        markerColor: colour,
      });
      const markerTooltipText = this.getTooltipMessage(store);
      return L.marker([latitude, longitude], { icon: markerIcon }).bindPopup(markerTooltipText);
    },

    getTooltipMessage(store) {
      return `<b>${store.storeKeyDisplay}</b><br /><b>${store.storeDescription}</b><br />${
        store.formatDescription
      }`;
    },

    applyMapBoundaries() {
      const latitudes = [];
      const longitudes = [];

      this.legendSelectedClusters.forEach(sc => {
        const markers = this.markers.filter(m => m.clusterId === sc);
        markers.forEach(m => {
          latitudes.push(get(m, 'latitude'));
          longitudes.push(get(m, 'longitude'));
        });
      });

      // calculate map boundaries
      const southWest = L.latLng(min(latitudes), min(longitudes)) || 0;
      const northEast = L.latLng(max(latitudes), max(longitudes)) || 0;

      // create bounds
      const bounds = L.latLngBounds(southWest, northEast);
      if (!isEmpty(bounds)) {
        // if no layer is selected there is no bounds
        this.map.fitBounds(bounds);
      }
    },

    pickColour() {
      let colour;
      for (let i = 0; i < this.markerColours.length; i += 1) {
        const c = this.markerColours[i];
        if (!this.selectedColours.includes(c)) {
          this.selectedColours.push(c);
          colour = c;
          break;
        }
      }
      return colour;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .leaflet-container {
    z-index: 200;

    .sidepanel {
      z-index: 201;
    }
  }

  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    border: $assortment-ag-grid-focus-colour 1px solid !important;
    border-radius: 0 !important;

    b {
      font-size: 1.2em;
    }
  }

  .awesome-marker {
    padding-top: 10px;
    text-align: center;

    &__count {
      font-weight: bold;
    }
  }

  .sidepanel__header {
    display: none;
  }
}

.legend {
  border: none !important;
  .v-item-group {
    min-width: 190px;
  }
  .v-list {
    padding: 0;
    border: none !important;
  }
  .v-list-item {
    padding: 0;
  }
  .-list-item {
    padding: 0;
  }

  .v-list-item__content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background-color: $legend-bg;
    border-bottom: $legend-border 1px solid;
  }

  .cluster {
    ::v-deep {
      .cluster-name {
        width: 100%;
        align-items: center;

        .v-input--selection-controls__ripple {
          display: none !important;
        }

        .v-avatar {
          margin: 0;
        }

        div {
          display: inline-block;
        }
      }
    }
  }
}
</style>
