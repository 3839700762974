<template>
  <div class="layout-main">
    <button data-id-e2e="layoutExpandButton" class="layout-button" @click="expandAll()">
      <v-icon class="material-icons-outlined ml-1 layout-icon">open_in_full</v-icon>
      {{ $tkey('expandAll') }}
    </button>
    <button data-id-e2e="layoutCollapseButton" class="layout-button" @click="collapseAll()">
      <v-icon class="material-icons-outlined ml-1 layout-icon">close_fullscreen</v-icon>
      {{ $tkey('collapseAll') }}
    </button>

    <div data-id-e2e="layoutGrid" class="ag-grid-box fill-height grid-size">
      <layout-options :layout-name="selectedLayoutName" @save="saveLayout" />
      <ag-grid-vue
        style="width: 100%; height: 100%;"
        class="ag-theme-custom ag-theme-custom--attributes"
        :column-defs="columnDefs"
        :row-data="layoutData"
        :group-display-type="groupDisplayType"
        :group-default-expanded="-1"
        :grid-options="gridOptions"
        @grid-ready="onGridReady"
      />
    </div>

    <main-dialog ref="deleteLayoutModal" :title="$tkey('deleteLayoutModalTitle')" :width="416">
      <template v-slot:content>
        <v-row class="main-dialog__content">
          <v-col class="message">
            <div>{{ deleteLayoutModalMessage }}</div>
            <div>{{ $tkey('deleteLayoutModalSubMessage') }}</div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:actions="{ cancel: close, confirm }">
        <v-row>
          <v-col class="d-flex justify-end pa-0">
            <v-btn secondary depressed class="outlined-btn" @click="close">
              {{ $t('actions.cancel') }}
            </v-btn>
            <v-btn class="ml-4" primary @click="confirm">
              {{ $t('actions.delete') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </main-dialog>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapGetters, mapActions } from 'vuex';
import { find, get } from 'lodash';
import agGridLayoutRow from '@/js/pages/reporting/detailed/ag-grid-layout-row.vue';

export default {
  localizationKey: 'reportingPage.sections.product.sideBar.toolPanels.layouts',
  components: {
    'ag-grid-vue': AgGridVue,
    /* eslint-disable vue/no-unused-components */
    agGridLayoutRow,
  },

  data() {
    return {
      gridOptions: {
        rowSelection: 'single',
        suppressContextMenu: true,
      },
      columnDefs: [
        {
          field: 'layoutType',
          rowGroup: true,
          headerClass: 'layout-header',
          cellClass: 'layout-cell',
          valueFormatter: params => {
            const headerValue = this.$tkey(`types.${params.value}`);
            return headerValue;
          },
          cellRenderer: 'agGridLayoutRow',
          cellRendererParams: params => {
            return {
              layout: params.data,
              selectLayoutFunction: this.selectLayout,
              onDeleteLayout: this.onDeleteLayout,
            };
          },
        },
      ],

      gridApi: null,
      columnApi: null,
      groupDisplayType: 'groupRows',
      selectedLayout: null,
      layoutToDelete: null,
    };
  },

  computed: {
    ...mapGetters('reporting', ['getLayouts']),

    layoutData() {
      return this.getLayouts;
    },

    selectedLayoutName() {
      const layout = find(this.layoutData, { _id: this.selectedLayoutId });
      return get(layout, 'name', this.$t('general.default'));
    },

    selectedLayoutId: {
      get() {
        return this.selectedLayout;
      },
      set(newValue) {
        this.selectedLayout = newValue;
      },
    },

    deleteLayoutModalMessage() {
      return this.$tkey('deleteLayoutModalMessage', [this.layoutToDelete]);
    },
  },

  methods: {
    ...mapActions('reporting', ['fetchLayoutsData', 'deleteLayout']),

    async fetchData() {
      await this.fetchLayoutsData();
    },

    saveLayout(params) {
      this.params.saveLayout(params);
    },

    selectLayout(selectedLayoutId) {
      this.selectedLayout = selectedLayoutId;

      const { layout } = find(this.layoutData, { _id: selectedLayoutId });
      this.params.applyColumnLayout(layout);
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.fetchData();
    },

    expandAll() {
      this.gridApi.expandAll();
    },

    collapseAll() {
      this.gridApi.collapseAll();
    },

    async onDeleteLayout({ name, _id }) {
      this.layoutToDelete = name;
      if (!(await this.$refs.deleteLayoutModal.open())) return;

      this.deleteLayout({ layoutId: _id });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.layout-main {
  padding: 10px;
  width: 100%;

  .ag-grid-box {
    font-size: 12px !important;
  }

  .layout-button {
    text-decoration: underline;
    color: $assortment-primary-colour;
  }

  .layout-icon {
    color: $assortment-primary-colour !important;
    font-size: 12px !important;
  }

  .grid-size {
    overflow: hidden;
  }
}

::v-deep {
  .ag-theme-custom .ag-row {
    font-size: 12px;
  }
  .layout-header {
    display: none !important;
  }

  .ag-header {
    display: none !important;
  }

  .layout-cell {
    color: $assortment-primary-colour;
    width: 100% !important;
    outline: none !important;
  }

  .ag-icon-tree-open,
  .ag-icon-tree-closed {
    color: $reporting-tab-text-color;
    font-size: 28px !important;
  }

  .ag-center-cols-container .ag-row {
    border-bottom: 1px solid $layouts-row-border-colour;
  }

  div.ag-row-group {
    border-bottom: 1px solid $layouts-row-border-colour !important;
    font-weight: bold;
  }

  .ag-theme-custom .ag-center-cols-container {
    width: 100% !important;
  }

  .ag-row-group {
    background: $layouts-row-group-colour !important;
  }

  .ag-theme-custom--attributes .ag-cell.layout-cell {
    height: 100% !important;
  }

  .ag-theme-custom--attributes .ag-cell.layout-cell,
  .ag-theme-custom .ag-cell.layout-cell.ag-cell-not-inline-editing.ag-cell-value.ag-cell-focus {
    padding-left: 20px !important;
    left: 0 !important;
    border-left-width: 0px !important;
    border-color: $layouts-row-group-colour !important;
  }

  .ag-theme-custom .ag-row-selected::before {
    background: $layouts-row-group-colour !important;
  }
  .ag-theme-custom .ag-row-even {
    background-color: $layouts-row-group-colour;
  }
  .ag-theme-custom .ag-row-selected {
    background-color: $layouts-selected-row-colour !important;
  }

  .ag-theme-custom .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border-right: 5px solid;
  }
}
</style>
