var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "bundle-card d-flex flex-column" },
    [
      _c("bundle-panel", { attrs: { bundle: _vm.item } }),
      _vm._v(" "),
      _vm.isPanelExpanded
        ? _c("workpackages-active", {
            staticClass: "mt-3",
            attrs: { "is-link": "", "show-toolbar": false },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }