var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      attrs: { disabled: _vm.disabled },
      domProps: { value: _vm.value },
      on: {
        input: function ($event) {
          return _vm.$emit("input", $event)
        },
        change: function ($event) {
          return _vm.$emit("change", $event)
        },
      },
    },
    _vm._l(_vm.items, function (item, index) {
      return _c("option", { key: index, domProps: { value: item } }, [
        _vm._v(_vm._s(item)),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }