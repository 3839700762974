const relativeProductPerformanceTabs = [
  {
    key: 'marketShare',
    componentName: 'market-share',
    translationKey:
      'assortmentCanvasPage.productDashboard.relativeProductPerformance.tabs.marketShare.label',
    position: 1,
  },
  {
    key: 'clickAndCollect',
    componentName: 'click-and-collect',
    translationKey:
      'assortmentCanvasPage.productDashboard.relativeProductPerformance.tabs.clickAndCollect.label',
    position: 2,
  },
  {
    key: 'customerSegmentation',
    componentName: 'customer-segmentation',
    translationKey:
      'assortmentCanvasPage.productDashboard.relativeProductPerformance.tabs.customerSegmentation.label',
    position: 3,
  },
];

export default {
  relativeProductPerformanceTabs,
};
