<template>
  <v-container class="pa-0 ma-0 checkpoint-selector-container">
    <div v-if="!canvases.length" class="pb-4 pa-2 ma-0" :class="{ 'h-100': !canvases.length }">
      <v-alert text class="mb-1">
        {{ $tkey('assortmentsMissingMessage') }}
      </v-alert>
    </div>

    <div v-if="canvases.length" class="ag-grid-box flex-grow-1 pa-2 ma-0">
      <ag-grid-vue
        style="width: 100%; height: 100%"
        class="ag-theme-custom"
        :column-defs="columnDefs"
        :row-data="rowData"
        :grid-options="gridOptions"
        :stop-editing-when-cells-loses-focus="true"
        @cell-value-changed="trackDiff"
        @grid-ready="onGridReady"
      />
    </div>
    <page-actions
      :has-data-changes="hasDataChanges"
      :has-data-errors="hasDataErrors"
      :is-discard-enabled="!isEditingDisabled"
      :save-disabled="isEditingDisabled"
      @discard="discardChanges"
      @save="saveChanges"
    />

    <dependency-tree-feedback-modal
      :value="dependencyTreeModalOpen"
      :results="dependencyTreeFeedback"
      page="checkpointSelector"
      @close="closeDependencyTreeModal"
      @commit="saveChanges(true)"
    />
  </v-container>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import {
  get,
  reduce,
  map,
  keyBy,
  cloneDeep,
  groupBy,
  isEmpty,
  merge,
  pick,
  each,
  partition,
  concat,
  orderBy,
  size,
  filter,
  padStart,
  sortBy,
  isNull,
} from 'lodash';
import agGridUtils from '@/js/utils/ag-grid-utils';

export default {
  localizationKey: 'extract.reports.storeExecutionPlanning.checkpointSelector',
  components: {
    AgGridVue,
  },

  data() {
    return {
      gridApi: null,
      columnApi: null,
      columnDefs: [
        {
          headerName: this.$tkey('tableHeaders.cluster'),
          field: 'clusterName',
          colId: 'clusterName',
        },
        {
          headerName: this.$tkey('tableHeaders.storeClass'),
          field: 'storeClassName',
          colId: 'storeClassName',
        },
        {
          headerName: this.$tkey('tableHeaders.sequentialKey'),
          field: 'sequentialKey',
          colId: 'sequentialKey',
        },
        {
          headerName: this.$tkey('tableHeaders.checkpoint'),
          field: 'checkpointId',
          colId: 'checkpointId',
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: params => {
            const { clusterId, storeClassId } = params.node.data;
            return {
              values: this.getCheckpoints(clusterId, storeClassId),
              field: 'checkpointId',
            };
          },
          valueFormatter: this.formatCheckpointName,
          filterParams: {
            valueFormatter: this.formatCheckpointName,
          },
          editable: () => !this.isEditingDisabled,
        },
      ],
      gridOptions: {
        headerHeight: 40,
        rowHeight: 40,
        singleClickEdit: true,
        suppressContextMenu: true,
        defaultColDef: {
          filter: false,
          sortable: true,
          resizable: true,
          minWidth: 80,
          comparator: agGridUtils.sortings.naturalSort,
          editable: false,
          suppressMenu: true,
          suppressMovable: true,
          flex: 1,
          required: true,
          cellClassRules: {
            'diff-background': this.hasDiff,
            'invalid-nonempty': agGridUtils.validations.validateValueExists,
          },
        },
        getRowId(row) {
          const { data } = row;
          return data.sequentialKey;
        },
        isFullWidthRow: params => params.rowNode.data.heading,
        fullWidthCellRenderer: agGridUtils.utils.fullWidthHeadingRenderer,
        postSortRows: this.postSort,
      },
      rowData: null,
      currentStateDiff: {},
      savedState: {},
      dependencyTreeModalOpen: false,
      dependencyTreeFeedback: {},
    };
  },

  computed: {
    ...mapState('assortmentCanvas', ['scenarioCheckpoints']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapGetters('assortmentCanvas', ['scenarioCheckpointsById', 'canvases']),
    ...mapGetters('clustering', ['selectedClusterScheme', 'noSelectedClusterScheme']),

    hasDataChanges() {
      return !isEmpty(this.currentStateDiff);
    },

    hasDataErrors() {
      // Required for <page-actions> component
      return false;
    },

    forecastCheckpointsByKey() {
      // Only checkpoints which have been forecast are valid here
      // Otherwise the store executions fails as it uses the forecast data.
      const forecastCheckpoints = filter(this.scenarioCheckpoints, {
        hasBeenForecast: true,
      });

      return groupBy(forecastCheckpoints, sc => `${sc.clusterId}-${sc.storeClassId}`);
    },
    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditStoreExecutionPlanning);
    },
  },

  async created() {
    await this.init();
  },

  methods: {
    ...mapMutations('extracts', ['setSelectedStep']),
    ...mapActions('clustering', ['fetchScenarioClusters']),
    ...mapActions('storeExecutions', ['fetchCheckpointSelections', 'saveCheckpointSelections']),
    ...mapActions('dependencyTree', ['triggerDependencyTree']),
    ...mapActions('assortmentCanvas', ['fetchScenarioCheckpoints']),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    addSequentialKeys(array) {
      // Unclustered checkpoints have a null checkpointId
      // These should come first, so we separate them, sort individually then concat
      const [unclustered, clustered] = partition(array, cs => isNull(cs.clusterId));

      const sortedUnclustered = sortBy(unclustered, ['clusterId', 'storeClassId']);
      const sortedClustered = sortBy(clustered, ['clusterId', 'storeClassId']);

      const prefix = this.$t('general.keyPrefixes.checkpointSelection');
      const allSelections = [...sortedUnclustered, ...sortedClustered];
      each(allSelections, (val, ix) => {
        val.sequentialKey = `${prefix}${padStart(ix + 1, 2, 0)}`;
      });
    },

    async init() {
      this.setSelectedStep(this.$options.name);

      const [checkpointSelections] = await Promise.all([
        this.fetchCheckpointSelections(),
        this.fetchScenarioCheckpoints(),
        this.fetchScenarioClusters(),
      ]);
      const existingSelections = this.formatCheckpointSelections(checkpointSelections);

      // Generate a table row for each canvas found
      const rows = map(this.canvases, c => {
        const { clusterId, storeClassId, storeClassName } = c;
        const clusterName = c.clusterName || this.$tkey('unclustered');

        // Use existing checkpoint selections in table data when available
        const checkpoint = get(existingSelections, [clusterId, storeClassId]);

        return {
          id: c._id,
          clusterId,
          clusterName,
          storeClassId,
          storeClassName,
          sequentialKey: get(checkpoint, 'sequentialKey'),
          checkpointId: get(checkpoint, 'checkpointId', null),
        };
      });

      // If no data exists, no sequential keys exist. Calculate them.
      const selectionsWithKey = filter(existingSelections, 'sequentialKey');
      if (isEmpty(selectionsWithKey)) this.addSequentialKeys(rows);

      if (size(rows)) {
        // Insert headers before and after unclustered rows
        // Add sequentialKey for nodeId
        const unclusteredHeader = {
          heading: this.$tkey('unclusteredHeading'),
          sequentialKey: 1,
        };
        const clusteredHeader = {
          heading: get(this.selectedClusterScheme, 'name'),
          sequentialKey: 2,
        };

        if (this.noSelectedClusterScheme) {
          this.rowData = concat(unclusteredHeader, rows);
        } else {
          const [clustered, unclustered] = partition(rows, r => r.clusterId);
          this.rowData = concat(unclusteredHeader, unclustered, clusteredHeader, clustered);
        }
      }

      // Backup data
      this.currentStateDiff = {};
      this.$options.rowData = cloneDeep(this.rowData);
      this.savedState = this.formatCheckpointSelections(cloneDeep(rows));
    },

    trackDiff(params) {
      const { clusterId, storeClassId } = params.data;
      const { field } = params.colDef;

      this.updateDiff(clusterId, storeClassId, field, params.value);
    },

    updateDiff(clusterId, storeClassId, field, currentValue) {
      const originalValue = get(this.savedState, [clusterId, storeClassId, field]);
      const existingValue = get(this.currentStateDiff, [clusterId, storeClassId]);

      // If the current state does not match the original, track the state difference
      if (currentValue !== originalValue) {
        if (!this.currentStateDiff[clusterId]) {
          this.$set(this.currentStateDiff, clusterId, {});
        }
        if (!this.currentStateDiff[clusterId][storeClassId]) {
          this.$set(this.currentStateDiff[clusterId], storeClassId, {});
        }
        this.$set(this.currentStateDiff[clusterId][storeClassId], field, currentValue);
      } else if (existingValue) {
        this.$delete(this.currentStateDiff[clusterId][storeClassId], field);

        // If there is nothing left in the object, remove it
        if (isEmpty(this.currentStateDiff[clusterId][storeClassId])) {
          this.$delete(this.currentStateDiff[clusterId], storeClassId);
        }

        if (isEmpty(this.currentStateDiff[clusterId])) {
          this.$delete(this.currentStateDiff, clusterId);
        }
      }
    },

    hasDiff(params) {
      const { clusterId, storeClassId } = params.data;
      const { field } = params.colDef;

      const currentValue = params.value;
      const originalValue = get(this.savedState, [clusterId, storeClassId, field]);

      return agGridUtils.comparators.didValueChange(currentValue, originalValue);
    },

    async saveChanges(commit = false) {
      const updatedCheckpointSelections = merge({}, this.savedState, this.currentStateDiff);

      const updates = reduce(
        updatedCheckpointSelections,
        (acc, selection) => {
          each(Object.keys(selection), storeClassId => {
            acc.push({
              ...pick(selection[storeClassId], ['clusterId', 'sequentialKey', 'checkpointId']),
              storeClassId,
            });
          });
          return acc;
        },
        []
      );

      // Trigger dependency tree
      const results = await this.triggerDependencyTree({
        params: { change: 'checkpointSelectorModified', updates: {}, commit },
      });

      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return;
      }

      await this.saveCheckpointSelections(updates);

      // Update backup data with the saved changes
      this.$options.rowData = cloneDeep(this.rowData);
      this.savedState = updatedCheckpointSelections;

      // Get the modified row nodes
      const modifiedRows = reduce(
        this.currentStateDiff,
        (acc, selection, clusterId) => {
          each(Object.keys(selection), key => {
            const sequentialKey = get(this.savedState, [clusterId, key, 'sequentialKey']);
            acc.push(this.gridApi.getRowNode(sequentialKey));
          });
          return acc;
        },
        []
      );
      // Refresh diff cell colouring
      this.gridApi.redrawRows({ rowNodes: modifiedRows });
      // Reset diff
      this.currentStateDiff = {};
    },

    discardChanges() {
      this.rowData = cloneDeep(this.$options.rowData);
      this.currentStateDiff = {};
    },

    postSort({ rowNodes }) {
      // Runs before onGridReady, check gridApi
      if (isEmpty(rowNodes) || isEmpty(this.gridApi)) return;

      // Separate rows with clusterIds
      const [clustered, unclustered] = partition(rowNodes, s => s.data.clusterId);
      // Separate the headers from unclustered rows
      const [headings, unclusteredRows] = partition(unclustered, s => s.data.heading);
      const sortedHeadings = orderBy(headings, [n => n.data.sequentialKey]);

      const dataRows = [unclusteredRows, clustered];
      const orderedNodes = reduce(
        sortedHeadings,
        (acc, heading, index) => {
          return concat(acc, heading, dataRows[index]);
        },
        []
      );

      each(orderedNodes, (node, childIndex) => {
        node.childIndex = childIndex;
      });

      // Have to use splice to update reference to rowNodes. see https://www.ag-grid.com/javascript-grid-sorting/#post-sort
      rowNodes.splice(0, rowNodes.length, ...orderedNodes);

      // Need to redraw rows to get correct styling for groups
      this.gridApi.redrawRows({ rowNodes });
      return rowNodes;
    },

    getCheckpoints(clusterId, storeClassId) {
      const key = `${clusterId}-${storeClassId}`;
      const checkpointsForCanvas = this.forecastCheckpointsByKey[key];
      return map(checkpointsForCanvas, '_id');
    },

    formatCheckpointSelections(data) {
      const clusteredSelections = groupBy(data, 'clusterId');

      return reduce(
        clusteredSelections,
        (acc, selections, index) => {
          acc[index] = keyBy(selections, 'storeClassId');
          return acc;
        },
        {}
      );
    },

    formatCheckpointName(params) {
      // Text to display when no selection has been made
      if (!params.value) return this.$tkey('checkpointSelect');
      return get(this.scenarioCheckpointsById[params.value], 'checkpointMeta.name');
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.checkpoint-selector-container {
  flex: 1;
  max-width: 100%;
}

::v-deep {
  .v-alert {
    background: $assortment-warning-alert-background !important;
    border: 1px solid $assortment-warning-alert-border !important;
    border-radius: 0 !important;
    padding: 14px;

    &:before {
      content: none;
    }

    &__content {
      color: $assortment-text-colour;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

  .ag-theme-custom {
    .ag-row {
      color: $assortment-table-textfield-colour;
    }

    .ag-header-row {
      color: $assortment-text-colour;

      &:not(.ag-header-row-column-group) {
        border-top: 1px solid $assortment-divider-colour;
      }
    }

    .ag-header-group-text {
      color: $assortment-table-header-colour;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.6rem;
    }

    .ag-full-width-row {
      align-items: center;
      background-color: $assortment-table-white-bg-colour;
      border-bottom: 1px solid $assortment-ag-grid-header-separator-colour;
      display: flex;
      padding: 0 5px;
    }
  }
}
</style>
