<template>
  <div class="top-partners">
    <div class="d-flex top-partners-header">
      <div class="col-2 top-partners-id-header table-item">
        {{ $tkey('productKey') }}
      </div>
      <div class="col-6 top-partners-description-header table-item">
        {{ $tkey('description') }}
      </div>
      <div class="col-4 top-partners-value-header table-item">
        {{ $tkey('switchingProbability') }}
      </div>
    </div>

    <div v-for="tp in topPartners" :key="tp.productKey" class="d-flex top-partners-content">
      <div :title="tp.productKeyDisplay" class="col-2 top-partners-id table-item">
        {{ tp.productKeyDisplay }}
      </div>
      <div :title="tp.itemDescription" class="col-6 top-partners-description table-item">
        {{ tp.itemDescription }}
      </div>
      <div class="col-4 top-partners-value table-item">
        {{ formatNumber({ number: tp.value * 100, format: 'float' }) }}%
        {{ tp.tooSmall ? $tkey('valueTooSmall') : '' }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { pick } from 'lodash';
import { StoreNamespaces } from '../../../store/constants';

export default {
  localizationKey: 'assortmentCanvasPage.productDashboard',
  computed: {
    ...mapGetters(StoreNamespaces.assortmentCanvas, [
      'getIndexedCanvasProducts',
      'getSelectedProductTopPartners',
      'getDashboardProduct',
    ]),
    topPartners() {
      if (!this.getSelectedProductTopPartners.length) return [];
      return this.getSelectedProductTopPartners.map(tp => {
        if (this.formatNumber({ number: tp.value * 100, format: 'float' }) === '0') {
          tp.tooSmall = true;
        }
        return {
          ...tp,
          ...pick(this.getIndexedCanvasProducts[tp.to], [
            'itemDescription',
            'productKey',
            'productKeyDisplay',
          ]),
        };
      });
    },
  },
  watch: {
    getDashboardProduct: {
      immediate: true,
      async handler(newValue) {
        if (!newValue) return;
        await this.fetchProductTopPartners({
          productKey: newValue.productKey,
          cannGroupId: newValue.cannGroupId,
        });
      },
    },
  },
  methods: {
    ...mapActions(StoreNamespaces.assortmentCanvas, ['fetchProductTopPartners']),
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.top-partners {
  font-size: 1.2rem;
  font-weight: normal;
}
.top-partners-header {
  border-bottom: 1px solid $assortment-grid-line-colour;
}
.top-partners-id-header {
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.top-partners-description-header {
  height: 40px;
  padding: 0;
  text-align: left;
  vertical-align: text-bottom;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.top-partners-value-header {
  height: 40px;
  padding: 0;
  text-align: left;
  vertical-align: text-bottom;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.top-partners-content {
  font-weight: lighter;
  &:nth-child(even) {
    background: $assortment-table-blue-bg-colour;
  }
}
.top-partners-id {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 20px;
  padding: 0;
  justify-content: flex-start;
}
.top-partners-description {
  height: 20px;
  padding: 0;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.top-partners-value {
  height: 20px;
  padding: 0;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-item {
  margin-left: 5px;
  cursor: default;
}
</style>
