<template>
  <div>
    <v-tooltip v-if="!isUnarchiving" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="action-button"
          :disabled="disabled"
          icon
          text
          v-bind="attrs"
          v-on="on"
          @click="onUnArchiveWorkpackageClick"
        >
          <span class="material-icons">unarchive</span>
        </v-btn>
      </template>
      <span>{{ $t('actions.unArchive') }}</span>
    </v-tooltip>
    <v-progress-circular v-else indeterminate size="25" width="3" />
  </div>
</template>

<script>
import { capitalize } from 'lodash';
import { mapActions, mapState } from 'vuex';
import entities from '@enums/entities';

export default {
  props: {
    workpackage: {
      required: true,
      type: Object,
    },
    entity: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isUnarchiving: false,
      message: '',
      offset: null,
      pageSize: null,
    };
  },
  computed: {
    ...mapState('context', ['clientConfig']),
    ...mapState('workpackages', ['archivedWorkpackagesCurrentPage']),
    disabled() {
      return !this.hasPermission(this.userPermissions.canUnArchiveWorkpackage);
    },
  },
  created() {
    this.message = this.$t(`notifications.unArchive${capitalize(this.entity)}`, {
      name: this.workpackage.name,
    });
    this.pageSize = this.clientConfig.archivedWorpackages.pageSize;
    this.offset = (this.archivedWorkpackagesCurrentPage - 1) * this.pageSize;
  },
  methods: {
    ...mapActions('workpackages', [
      'updateWorkpackage',
      'fetchWorkpackages',
      'setSelectedWorkpackage',
      'fetchArchivedWorkpackages',
    ]),
    ...mapActions('snackbar', ['showSuccess']),
    async onUnArchiveWorkpackageClick() {
      const shouldGetTemplates = this.entity === entities.template;
      this.isUnarchiving = true;
      await this.updateWorkpackage({
        workpackageId: this.workpackage._id,
        updates: {
          archived: false,
          archiveDate: null,
        },
      });
      this.setSelectedWorkpackage(this.workpackage);
      await Promise.all([
        this.fetchArchivedWorkpackages({
          limit: this.pageSize,
          offset: this.offset,
          shouldGetTemplates,
        }),
        this.fetchWorkpackages(shouldGetTemplates ? { shouldGetTemplates: true } : {}),
      ]);
      this.showSuccess(this.message);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.action-button {
  .material-icons {
    color: $assortment-primary-colour;
  }
}
</style>
