<template>
  <v-container fluid>
    <v-row>
      <v-tabs v-model="selectedTab">
        <v-tab v-for="item in tabs" :key="item._id">
          <span class="tab-title"> {{ item.name }}</span>
        </v-tab>
        <v-tabs-items v-model="selectedTab">
          <v-tab-item v-for="item in tabs" :key="item._id">
            <reference-cluster-scheme
              v-if="item._id === 'defaultClusters' && hasDefaultClustersEnabled"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';

export default {
  localizationKey: 'clientAdminPage',

  data() {
    return {
      tabs: [],
      selectedTab: 0,
      clusterSchemes: [],
      selectedClusterSchemeName: '',
    };
  },

  computed: {
    ...mapGetters('context', ['getClientConfig']),

    hasDefaultClustersEnabled() {
      return get(this.getClientConfig, 'features.defaultClustersEnabled', false);
    },
  },

  async created() {
    await this.reset();
  },

  methods: {
    ...mapActions('snackbar', ['showSuccess', 'showError']),

    async reset() {
      if (this.hasDefaultClustersEnabled) {
        this.tabs.push({ _id: 'defaultClusters', name: this.$tkey('defaultClusters') });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';
</style>
