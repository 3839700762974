import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "sidebar-wrapper",
      class: { "d-contents": _vm.selectedView === _vm.views.Tile },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "grid-item-border left-column-sticky-grid-item top-left-heading d-flex flex-column justify-space-between",
          class: _vm.isSticky,
          style: _vm.sidebarHeadingStyle,
        },
        [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                VMenu,
                {
                  attrs: { "close-on-content-click": "", "offset-x": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            VBtn,
                            _vm._g(
                              {
                                staticClass: "mb-2",
                                attrs: {
                                  "x-small": "",
                                  link: "",
                                  color: "primary",
                                },
                              },
                              on
                            ),
                            [
                              _c(
                                VIcon,
                                { staticClass: "mr-1", attrs: { size: "16" } },
                                [_vm._v("visibility")]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$tkey("viewOptions")) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.isViewMenuOpen,
                    callback: function ($$v) {
                      _vm.isViewMenuOpen = $$v
                    },
                    expression: "isViewMenuOpen",
                  },
                },
                [
                  _vm._v(" "),
                  _c(
                    VCard,
                    [
                      _c(
                        VList,
                        [
                          _c(
                            VListItem,
                            [
                              _c(
                                VListItemAction,
                                [
                                  _c(VSwitch, {
                                    staticClass: "view-menu-switch",
                                    attrs: {
                                      value: _vm.fullScreenExpanded,
                                      small: "",
                                      dense: "",
                                      color: "primary",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.toggleFullScreen(
                                          !_vm.fullScreenExpanded
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                VListItemTitle,
                                { staticClass: "view-menu-option" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$tkey("fullscreen")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VListItem,
                            [
                              _c(
                                VListItemAction,
                                [
                                  _c(VSwitch, {
                                    staticClass: "view-menu-switch",
                                    attrs: {
                                      small: "",
                                      dense: "",
                                      color: "primary",
                                    },
                                    model: {
                                      value: _vm.stickyHeadersModel,
                                      callback: function ($$v) {
                                        _vm.stickyHeadersModel = $$v
                                      },
                                      expression: "stickyHeadersModel",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                VListItemTitle,
                                { staticClass: "view-menu-option" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$tkey("stickyHeaders")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VListItem,
                            [
                              _c(
                                VListItemAction,
                                [
                                  _c(VSwitch, {
                                    staticClass: "view-menu-switch",
                                    attrs: {
                                      small: "",
                                      dense: "",
                                      color: "primary",
                                    },
                                    model: {
                                      value: _vm.unifiedColumnsModel,
                                      callback: function ($$v) {
                                        _vm.unifiedColumnsModel = $$v
                                      },
                                      expression: "unifiedColumnsModel",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                VListItemTitle,
                                { staticClass: "view-menu-option" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$tkey("unifiedColumns")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VCardActions,
                        { staticClass: "view-menu-actions" },
                        [
                          _c(VSpacer),
                          _vm._v(" "),
                          _c(
                            VBtn,
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.isViewMenuOpen = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("actions.close")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _vm.selectedView === _vm.views.List && _vm.columnSettingsEnabled
                ? _c("column-settings", {
                    attrs: {
                      "button-title": _vm.$tkey("columns"),
                      columns: _vm.listViewColumnsWithTitle,
                      "disabled-columns": _vm.listViewFixedColumns,
                    },
                    on: {
                      "update:selected-columns":
                        _vm.toggleListViewColumnVisibility,
                      "update:column-order": _vm.reorderListViewColumns,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "spacebreak-entry global-constraints",
              class: _vm.selectedSpacebreakClass,
            },
            [
              _c(
                "div",
                { staticClass: "spacebreak-heading-row" },
                [
                  _c(
                    VLayout,
                    { staticClass: "d-flex justify-space-between" },
                    [
                      _c(
                        "div",
                        { staticClass: "spacebreak-name space-break-heading" },
                        [_vm._v(_vm._s(_vm.$tkey("globalConstraints")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "icons-container",
                          attrs: {
                            "data-dd-action-name":
                              "ToggleGlobalSpacebreakSettingsPanelBtn",
                          },
                        },
                        [
                          _c(
                            VAvatar,
                            {
                              class: {
                                "spacebreak-count-badge": !_vm.cogWarning,
                                "spacebreak-count-badge-warning":
                                  _vm.cogWarning,
                              },
                              attrs: { size: "14" },
                              on: { click: _vm.toggleGlobalSettingsPanel },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "white--text headline" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.globalConstraintsCount || 0)
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            VIcon,
                            {
                              staticClass: "settings-btn",
                              on: { click: _vm.toggleGlobalSettingsPanel },
                            },
                            [_vm._v("$settings")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.spacebreaks, function (spacebreak) {
        return _c(
          "div",
          {
            key: spacebreak._id,
            staticClass:
              "grid-item-border left-column-sticky-grid-item sidebar d-flex",
            class: {
              "spacebreak-locked-border":
                _vm.isSpacebreakLocked(spacebreak._id) &&
                _vm.selectedView === _vm.views.Tile,
            },
          },
          [
            _vm.isSpacebreakLocked(spacebreak._id) &&
            _vm.selectedView === _vm.views.Tile
              ? _c("div", { staticClass: "locked" }, [
                  _vm._v("\n      " + _vm._s(_vm.$tkey("locked")) + "\n    "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("spacebreak-entry", {
              staticClass: "list-spacebreak-entry",
              class: {
                "active-spacebreak": _vm.isSpacebreakActive(spacebreak._id),
              },
              attrs: {
                spacebreak: spacebreak,
                space: _vm.spaceUsedPerSpacebreak[spacebreak._id],
                "pallets-total": _vm.palletSlotsPerSpacebreak(spacebreak),
                "used-pallets-slots":
                  _vm.usedPalletsSlotsPerSpacebreak[spacebreak._id],
                "store-total": _vm.pointsOfDistribution[spacebreak._id],
                "product-count": _vm.productCountPerSpacebreak[spacebreak._id],
              },
              on: {
                "update-active-spacebreak": function ($event) {
                  return _vm.updateActiveSpacebreak(spacebreak._id)
                },
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.spacebreakTypes, function (spacebreak) {
        return _c(
          "div",
          {
            key: spacebreak,
            staticClass:
              "pa-3 grid-item-border left-column-sticky-grid-item sidebar",
            class: {
              "active-spacebreak": _vm.isSpacebreakActive(spacebreak),
              selectable: _vm.selectedView === _vm.views.List,
            },
            attrs: { id: `${spacebreak}-spacebreak-entry` },
            on: {
              click: function ($event) {
                return _vm.updateActiveSpacebreak(spacebreak)
              },
            },
          },
          [
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.$tkey(spacebreak))),
            ]),
            _vm._v(" "),
            _vm.productsWithoutSpacebreak[spacebreak]
              ? _c("p", [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        `${
                          _vm.productsWithoutSpacebreak[spacebreak].length
                        } ${_vm.$tc(
                          "assortmentCanvasPage.listViewPage.products",
                          _vm.productsWithoutSpacebreak[spacebreak].length
                        )}`
                      ) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }