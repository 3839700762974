'use strict';

const sectionStatuses = require('./enums/section-status');

const baseStatusObject = {
  space: {
    status: sectionStatuses.notStarted, // not-started / in-progress / success / failed

    furniture: {
      status: sectionStatuses.notStarted,
      furnitureCount: 0,
      lastModifiedDate: null,
    },
    mapping: {
      status: sectionStatuses.notStarted,
      storeCount: 0,
      storesMapped: 0,
      lastModifiedDate: null,
    },
    storeclass: {
      status: sectionStatuses.notStarted,
      storeclassCount: 0,
      furnitureWithStoreClassCount: 0,
      lastModifiedDate: null,
    },
  },
  inputs: {
    status: sectionStatuses.notStarted,
    attributeEditor: {
      status: sectionStatuses.notStarted,
      productCount: 0,
      attributeCount: 0,
      percentageComplete: 0,
      lastModifiedDate: null,
    },
    cannGroups: {
      status: sectionStatuses.notStarted,
      groupsCount: 0,
      lastModifiedDate: null,
    },
    varietyGroups: {
      status: sectionStatuses.notStarted,
      numberSet: 0,
      lastModifiedDate: null,
    },
  },
};

const fullResetStatusObject = {
  space: {
    spacebreaks: {
      status: sectionStatuses.notStarted,
      lastModifiedDate: null,
    },
  },
  measuring: {
    status: sectionStatuses.notStarted,
    switching: {
      status: sectionStatuses.notStarted,
      lastModifiedDate: null,
    },
    cdt: {
      status: sectionStatuses.notStarted,
      numberSelected: 0,
      lastModifiedDate: null,
    },
    region: {
      status: sectionStatuses.notStarted,
      lastModifiedDate: null,
    },
    clustering: {
      status: sectionStatuses.notStarted,
      schemeSelected: null,
      lastModifiedDate: null,
    },
  },
  modelling: {
    status: sectionStatuses.notStarted,
    productModelling: {
      status: sectionStatuses.notStarted,
      productCount: 0,
      newProductCount: 0,
      newProductsModelled: 0,
      establishedProductCount: 0,
      childProductCount: 0,
      lastModifiedDate: null,
    },
    switchingModelling: {
      status: sectionStatuses.notStarted,
      lastModifiedDate: null,
    },
  },
  assortment: {
    status: sectionStatuses.notStarted,
    canvasesGenerated: false,
    jobDate: null,
  },
  execution: {
    status: sectionStatuses.notStarted,
    jobDate: null,
  },
  finalisation: {
    status: sectionStatuses.notStarted,
    jobDate: null,
  },
  storeExecutions: {
    checkpointSelector: {
      status: sectionStatuses.notStarted,
    },

    storeAllocation: {
      status: sectionStatuses.notStarted,
    },

    planogramDefinition: {
      status: sectionStatuses.notStarted,
    },

    jda: {
      status: sectionStatuses.notStarted,
    },
  },
};

const simpleSwapsStatusObject = {
  space: {
    planograms: {
      status: sectionStatuses.notStarted,
      planogramCount: 0,
      planogramsSelectedCount: 0,
      lastModifiedDate: null,
    },
  },
  inputs: {
    prepareSwaps: {
      status: sectionStatuses.notStarted,
      lastModifiedDate: null,
    },
  },
  swaps: {
    status: sectionStatuses.notStarted,
    productsToSwap: {
      status: sectionStatuses.notStarted,
      productCount: 0,
      productsCompletedCount: 0,
      lastModifiedDate: null,
    },
  },
  extracts: {
    status: sectionStatuses.notStarted,
    viewReports: {},
  },
};

const workpackageBaseStatusObject = {
  scope: {
    status: sectionStatuses.notStarted,

    datesAssortmentGroups: {
      status: sectionStatuses.notStarted,
      lastModifiedDate: null,
    },
    products: {
      status: sectionStatuses.notStarted,
      lastModifiedDate: null,
    },
    stores: {
      status: sectionStatuses.notStarted,
      lastModifiedDate: null,
    },
    additionalProducts: {
      status: sectionStatuses.notStarted,
      lastModifiedDate: null,
    },
  },
  inputs: {
    status: sectionStatuses.notStarted,

    attributeEditor: {
      status: sectionStatuses.notStarted,
      lastModifiedDate: null,
    },
    cannGroups: {
      status: sectionStatuses.notStarted,
      lastModifiedDate: null,
    },
  },
};

module.exports = {
  baseStatusObject,
  fullResetStatusObject,
  simpleSwapsStatusObject,
  workpackageBaseStatusObject,
};
