<template>
  <span v-if="job.status" class="job-status-container">
    <strong>{{ label }}</strong>

    <span v-if="simpleJobStatus === jobSimpleStatuses.finished">
      <strong>{{ $t('scenarioManagementPanel.jobs.ranSuccessfully') }}: </strong>
      {{ job.dateTimeComplete | formatDate(getDateFormats.longWithTime) }}
    </span>

    <span v-if="simpleJobStatus === jobSimpleStatuses.inProgress">
      <strong>
        {{ $t('scenarioManagementPanel.jobs.inProgress', [Math.round(job.progress)]) }}
      </strong>
    </span>

    <v-progress-linear
      v-if="simpleJobStatus === jobSimpleStatuses.inProgress"
      v-model="job.progress"
      color="primary"
      height="5"
    />

    <span v-if="simpleJobStatus === jobSimpleStatuses.failed" class="problem-text">
      <strong>{{ $t('scenarioManagementPanel.jobs.failed') }}: </strong>
      {{ job.dateTimeComplete | formatDate(getDateFormats.longWithTime) }}
    </span>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import { getJobStatusSimplified, jobSimpleStatuses } from '@enums/jobapi';

export default {
  props: {
    job: {
      required: true,
      type: Object,
    },
    label: {
      required: false,
      type: String,
      default: '',
    },
  },

  data() {
    return {
      jobSimpleStatuses,
    };
  },

  computed: {
    ...mapGetters('context', ['getDateFormats']),
    simpleJobStatus() {
      return getJobStatusSimplified(this.job.status);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.job-status-container {
  span {
    &.problem-text {
      color: $assortment-negative-action-colour;
    }
  }
}
</style>
