var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("switching-behaviour", {
        attrs: {
          "requires-analytics-text": _vm.$tkey("requiresAnalyticsRun"),
          "tooltip-link": "toolguide/080-switching.html",
          "job-type": _vm.switchingJob,
        },
        on: { "run-switching": _vm.runSwitching },
      }),
      _vm._v(" "),
      _c("dependency-tree-feedback-modal", {
        attrs: {
          value: _vm.dependencyTreeModalOpen,
          results: _vm.dependencyTreeFeedback,
          page: "switching",
        },
        on: {
          close: _vm.closeDependencyTreeModal,
          commit: function ($event) {
            return _vm.runSwitching(true)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }