import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        VRow,
        { staticClass: "nav-bar", attrs: { "no-gutters": "" } },
        [
          _c(
            VCol,
            { staticClass: "white workpackage d-flex justify-start" },
            [
              _c(
                VBtn,
                {
                  attrs: {
                    color: "primary btn-back",
                    fab: "",
                    small: "",
                    dark: "",
                    tile: "",
                    depressed: "",
                    to: { name: _vm.backNavRoute },
                  },
                },
                [
                  _c(VIcon, { attrs: { size: "28" } }, [
                    _vm._v("mdi-chevron-left"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(VToolbarTitle, { staticClass: "pa-2 pl-4" }, [
                _vm._v(_vm._s(_vm.workpackage.name)),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.enableScenarioSelection
            ? _c(
                VCol,
                { staticClass: "scenarioSelect d-flex align-center" },
                [
                  _c(VSelect, {
                    attrs: {
                      value: _vm.selectedScenario,
                      flat: "",
                      items: _vm.scenarios,
                      label: _vm.$t("toolbar.scenarioTitleID"),
                      "menu-props": { bottom: true, offsetY: true },
                      "item-text": "name",
                      "item-value": "scenarioId",
                      solo: "",
                      "hide-details": "",
                      dense: "",
                      "return-object": "",
                    },
                    on: { change: _vm.changeScenarios },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "append",
                          fn: function () {
                            return [
                              _c(VIcon, { attrs: { size: "28" } }, [
                                _vm._v("expand_more"),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "item",
                          fn: function ({ item, attrs, on }) {
                            return [
                              _c(
                                "actionable-link",
                                _vm._b(
                                  {
                                    attrs: {
                                      component: "v-list-item",
                                      to: _vm.createScenarioLink(item),
                                      "on-click": _vm.scenarioAction,
                                      "v-on": on,
                                    },
                                  },
                                  "actionable-link",
                                  attrs,
                                  false
                                ),
                                [
                                  _c(
                                    VListItemContent,
                                    [
                                      _c(VListItemTitle, [
                                        _c("span", [_vm._v(_vm._s(item.name))]),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      744070629
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass: "tabs__container align-center",
              attrs: { "data-id-e2e": "tabsRoute" },
            },
            [
              _vm.tabsTitle
                ? _c("p", { staticClass: "tabs__title ml-4 mr-3 my-0" }, [
                    _vm._v(_vm._s(_vm.tabsTitle)),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                VTabs,
                {
                  staticClass: "nav-bar-tabs",
                  attrs: {
                    "show-arrows": "",
                    height: "34",
                    value: _vm.currentTab,
                  },
                  on: { change: _vm.onTabChange },
                },
                _vm._l(_vm.tabData, function (tab) {
                  return _c(
                    VTab,
                    {
                      key: tab.routeName,
                      attrs: {
                        disabled: _vm.isTabDisabled(tab),
                        ripple: false,
                        to: _vm.createRouteForTab(tab),
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(tab.translationKey)) +
                          "\n        "
                      ),
                      _vm.showStatuses
                        ? _c(
                            VIcon,
                            {
                              staticClass: "status-tab-icon",
                              attrs: { size: "18" },
                            },
                            [_vm._v("$" + _vm._s(tab.status))]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
              _vm._v(" "),
              _vm.showNotImplemented
                ? _c("users-permissions", {
                    staticClass: "panel-cell",
                    attrs: { "hide-title": "", "justify-center": "" },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.enableNotes
            ? _c(
                VCol,
                {
                  staticClass:
                    "d-flex justify-end expand-btn-container align-center",
                },
                [
                  _c("notes", {
                    attrs: {
                      notes: _vm.getAllNotes,
                      loading: _vm.isNotesLoading,
                      title: _vm.$t("notes.types.scenario"),
                      "max-chars": _vm.getClientConfig.notesSettings.maxLength,
                      notifications: !!_vm.selectedScenario.totalNotes,
                    },
                    on: {
                      post: _vm.postNewNote,
                      menuOpen: _vm.fetchAllNotes,
                      delete: _vm.deleteCurrentNote,
                      update: _vm.updateCurrentNote,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    VIcon,
                    { staticClass: "icon", attrs: { size: "35", primary: "" } },
                    [_vm._v("expand_more")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }