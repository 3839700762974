<template>
  <v-card class="step-tab-panel" flat>
    <v-row>
      <v-col justify="start" class="pa-0 step-tab-panel__content">
        <extract-report-generator
          :disabled="isGenerateExtractDisabled"
          @generate-extract-requested="generateReport"
        >
          <v-container class="report-product-store-extract d-flex flex-column pa-0 ma-0" fluid>
            <v-row no-gutters>
              <v-col class="margin-right-23" colspan="6">
                <report-input-item :title="$t('extract.reports.productStore.reportType.title')">
                  <div>
                    <v-select
                      v-model="selectedExtract"
                      :items="extractions"
                      hide-details
                      dense
                      append-icon="expand_more"
                    />
                  </div>
                </report-input-item>
              </v-col>
              <v-col colspan="6">
                <report-input-item :title="$t('extract.reports.productStore.extractName.name')">
                  <v-text-field
                    v-model="extractName"
                    class="rtls-text-field--grey"
                    :placeholder="$t('extract.reports.productStore.extractName.placeholder')"
                    :hint="uniqueNameHint"
                    :rules="nameRules"
                  />
                </report-input-item>
              </v-col>
            </v-row>

            <v-row class="mt-6">
              <checkpoints-selection-table
                :scenario-id="selectedScenario._id"
                :headers="headers"
                :canvases="items"
                :checkpoints-by-canvas-id="checkpointsByCanvasId"
                :title="$t('extract.reports.productStore.table.title')"
                @update-selected-checkpoints="setSelectedCheckpoints"
              />
            </v-row>
          </v-container>
        </extract-report-generator>
      </v-col>
      <v-col justify="start" cols="4" class="pt-0 pr-0">
        <previous-extract-reports :reports="reports" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { map, every, includes, debounce, isEmpty, forEach, get } from 'lodash';
import moment from 'moment';
import { extracts } from '@enums/client-events';
import { PRODUCT_INFO, EXTERNAL_OPTIMISATION_EXTRACT } from '@enums/extract-types';
import { PRODUCT_OUTBOUND_API_EXTRACT } from '@enums/extract-names';
import { jobStatuses, jobFinishedStatuses, jobStatusesIconsMappings } from '@enums/jobapi';
import extractsUtils from '@/js/utils/extracts-utils';
import datesMixin from '@/js/mixins/date-utils';
import inputValidationMixin from '@/js/mixins/input-validations';

const canvasesFilterParams = {
  where: { live: true },
  pick: ['clusterId', 'jobs', 'checkpointMeta', 'live'],
};

export default {
  mixins: [inputValidationMixin, datesMixin],

  localizationKey: 'extract.reports.productStore',

  data() {
    return {
      levels: {
        product: true,
        store: false,
      },
      selectedExtract: null,
      extractions: [],
      headers: [
        {
          text: this.$t('checkpointSelectionTable.headers.cluster'),
          value: 'cluster',
          width: 100,
        },
        {
          text: this.$t('checkpointSelectionTable.headers.storeClass'),
          value: 'storeClass',
          width: 100,
        },
        {
          text: this.$t('checkpointSelectionTable.headers.description'),
          value: 'description',
          width: 200,
        },
        {
          text: this.$t('checkpointSelectionTable.headers.optimised'),
          value: 'status',
          align: 'center',
          width: 100,
        },
        {
          text: this.$t('checkpointSelectionTable.headers.checkpoint'),
          value: 'checkpoint',
          width: 250,
        },
      ],
      extractName: null,
      selectedCheckpoints: {},
      isCheckpointError: false,
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
    ...mapGetters('extracts', ['extracts']),
    ...mapGetters('assortmentCanvas', ['scenarioCheckpointsByCanvasId', 'canvases']),
    ...mapGetters('context', { dateFormats: 'getDateFormats' }),

    items() {
      return map(this.canvases, c => ({
        _id: c._id,
        clusterId: c.clusterId,
        cluster: c.clusterName || this.$tkey('unClustered'),
        storeClassId: c.storeClassId,
        storeClass: c.storeClassName,
        description: c.description || this.$tkey('noDescription'),
        status: c.hasBeenOptimised
          ? jobStatusesIconsMappings[jobStatuses.finished]
          : jobStatusesIconsMappings[jobStatuses.failed],
        originalJobStatus:
          c.jobs && c.jobs.assortmentOptimisation
            ? c.jobs.assortmentOptimisation.status
            : jobStatuses.failed,
        checkpoint: null,
      }));
    },

    checkpointsByCanvasId() {
      // Create a map of canvas id to checkpoint options
      const checkpointsByCanvasId = {};
      forEach(this.items, ({ _id: canvasId }) => {
        const canvasCheckpoints = get(this.scenarioCheckpointsByCanvasId, canvasId, []).map(
          ({ checkpointMeta, _id }) => {
            return {
              text: checkpointMeta.name,
              value: _id,
            };
          }
        );
        canvasCheckpoints.unshift({ text: this.$tkey('noSelection'), value: null });
        checkpointsByCanvasId[canvasId] = canvasCheckpoints;
      });

      return checkpointsByCanvasId;
    },

    isGenerateExtractDisabled() {
      return (
        isEmpty(this.scenarioCheckpointsByCanvasId) ||
        !this.userCanEditProductStoreExtracts ||
        this.isCheckpointError ||
        !this.selectedExtract ||
        (this.selectedExtract === PRODUCT_OUTBOUND_API_EXTRACT && !this.extractName)
      );
    },

    userCanEditProductStoreExtracts() {
      return this.hasPermission(this.userPermissions.canEditProductStoreExtracts);
    },

    reports() {
      return extractsUtils.reports({
        extracts: this.extracts,
        reportType: this.selectedExtractType,
      });
    },

    uniqueNameHint() {
      return this.selectedExtract === PRODUCT_OUTBOUND_API_EXTRACT
        ? this.$tkey('reportType.uniqueNameWarning')
        : '';
    },

    nameRules() {
      return this.selectedExtract === PRODUCT_OUTBOUND_API_EXTRACT ? [this.required] : [];
    },

    selectedExtractType() {
      return this.selectedExtract === PRODUCT_OUTBOUND_API_EXTRACT
        ? EXTERNAL_OPTIMISATION_EXTRACT
        : PRODUCT_INFO;
    },
    extractSelection() {
      if (this.selectedExtract === PRODUCT_OUTBOUND_API_EXTRACT)
        return EXTERNAL_OPTIMISATION_EXTRACT;
      return this.selectedExtract;
    },
  },

  watch: {
    items() {
      if (this.shouldPollCanvases()) {
        this.debouncedFetchCanvases({ params: canvasesFilterParams });
      }
    },
  },

  async created() {
    const availableProductExtracts = get(this.getClientConfig, `extracts.${PRODUCT_INFO}`, []);
    this.extractions = map(availableProductExtracts, e => ({
      text: this.$tkey(`reportType.extractions.${e}`),
      value: e,
    }));
    this.selectedExtract = availableProductExtracts[0] || null;
    this.debouncedFetchCanvases = debounce(this.fetchCanvases, 5000);
    await Promise.all([
      this.fetchCanvases({ params: canvasesFilterParams }),
      this.fetchScenarioCheckpoints({ excludeObserved: false }),
    ]);
  },

  methods: {
    ...mapActions('assortmentCanvas', ['fetchCanvases', 'fetchScenarioCheckpoints']),
    ...mapActions('extracts', ['generateExtract']),

    shouldPollCanvases() {
      // checks if there are unfinished jobs that need to be refreshed
      return every(this.items, i => includes(jobFinishedStatuses, i.originalJobStatus)) === false;
    },
    reportGenerationOptions() {
      return {
        filenameOnDownload:
          this.extractName ||
          this.$t('extract.reports.defaultExtractFileName', [
            moment().format(this.dateFormats.longWithTime),
          ]),
        extractType: this.selectedExtractType,
        extractDatetime: this.createDateForSave(),
        fileId: null,
        canvasesIds: Object.values(this.selectedCheckpoints).filter(c => c),
        extractsSelection: [this.extractSelection],
      };
    },

    async generateReport() {
      if (!this.checkpointError) {
        await this.generateExtract(this.reportGenerationOptions());
        this.globalEmit(extracts.extractGenerated);
      }
    },

    setSelectedCheckpoints(cps) {
      this.selectedCheckpoints = cps;
      // validate the selected checkpoints
      this.isCheckpointError = Object.values(cps).every(v => !v);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.report-product-store-extract {
  .margin-right-23 {
    margin-right: 23px;
  }
}

.rtls-checkbox {
  margin: 0 !important;
  ::v-deep {
    .v-input--selection-controls__input {
      margin: 0 !important;
    }
  }
}

.width-100 {
  width: 100%;
}

.border-selected {
  border: 1px solid $assortment-primary-colour;
}

.border-unselected {
  border: 1px solid $assortment-divider-colour;
}

.checkbox-container {
  border-radius: 4px;
  padding: 2px 1px 2px 7px;
}

.checkbox-label {
  font-size: 1.2rem;
  color: $assortment-font-colour;
}

.step-tab-panel {
  background-color: $assortment-background !important;

  &__content {
    background: $assortment-panel-background;
  }
}
</style>
