import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "workpackage d-flex flex-column" },
    [
      !_vm.visible
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "observe-visibility",
                  rawName: "v-observe-visibility",
                  value: { callback: _vm.visibilityChanged, once: true },
                  expression: "{ callback: visibilityChanged, once: true }",
                },
              ],
              staticClass: "loading-wrapper",
            },
            [
              _c("div", {
                staticClass: "panel-cell drag-cell flex-grow-0 flex-shrink-0",
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "loading-box pl-3" },
                [
                  _c(VProgressCircular, {
                    staticClass: "mr-3",
                    attrs: { indeterminate: "", size: "20" },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("general.loading")))]),
                ],
                1
              ),
            ]
          )
        : _c(`${_vm.entity}-card`, {
            tag: "component",
            attrs: {
              id: `wp-panel-${_vm.workpackage._id}`,
              item: _vm.workpackage,
              "is-link": _vm.isLink,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }