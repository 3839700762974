<template>
  <div class="d-flex flex-column align-start cell-container" :class="editingModeClass">
    <div v-if="!editingMode" class="constraint-description">{{ constraintDescription }}</div>
    <div class="d-flex">
      <rtls-select
        v-if="isSelected"
        :value="attributeValue"
        white
        width="80%"
        :items="itemsSorted"
        item-text="name"
        item-value="id"
        :placeholder="$t('general.select')"
        :menu-props="{ 'z-index': 200 }"
        hide-details
        @input="setAttributeValue"
      />
      <error-triangle
        v-if="isSelected"
        :errors="{
          [$tkey('errorMessages.setAttributeName')]: attributeValue,
          [$tkey('errorMessages.setAttributeValue')]: allAttributeValuesAreAssigned,
          [$tkey('errorMessages.setAttributeValueAsUnique', [
            spacebreakId === null ? $tkey('errorMessages.uniqueInSpacebreak') : '',
          ])]: isUnique,
        }"
        class="attributes-filter-renderer-triangle"
      />
    </div>
    <div v-if="isSelected" class="d-flex flex-column">
      <div>
        <div
          v-for="(filter, index) in selectedFilters"
          :key="index"
          class="d-flex flex-row ml-2 mt-2 filter-value-row"
        >
          <rtls-select
            :key="index"
            v-model="filter.value"
            white
            width="80%"
            :items="getUnselectedValues(index)"
            :placeholder="$t('general.select')"
            :menu-props="{ 'z-index': 200 }"
            hide-details
          />
          <div class="d-flex align-center position-relative">
            <v-btn
              v-if="selectedFilters.length > 1"
              icon
              text
              class="pt-1 remove-attr-value-btn"
              @click="removeAttributeValue(filter.value)"
            >
              <v-icon size="20" color="#d7534e">mdi-alpha-x-circle-outline</v-icon>
            </v-btn>
            <span
              v-if="totalValidSelectedAttributeValues > 0 && index === selectedFilters.length - 1"
              class="applied-filters-count"
            >
              {{
                $tc(
                  'assortmentCanvasPage.spacebreakSettings.constraints.filtersApplied',
                  totalValidSelectedAttributeValues
                )
              }}
            </span>
          </div>
        </div>
      </div>
      <v-btn class="mt-1" icon text @click="addAttributeValue">
        <v-icon color="#2f477c">mdi-plus-box</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import {
  isEqual,
  cloneDeep,
  keyBy,
  isNull,
  every,
  map,
  slice,
  without,
  filter,
  get,
  reject,
  sortBy,
} from 'lodash';
import { assortmentCanvas } from '@enums/client-events';

export default Vue.extend({
  name: 'cellAttributeFiltersRenderer',
  localizationKey: 'assortmentCanvasPage.spacebreakSettings.constraints',
  data() {
    return {
      items: [],
      scenarioAttributesMap: {},
      constraintDescription: '',
      attributesValuesMap: {},
      availableAttributes: [],
      selectedFilters: [],
      filterValue: null,
      attributeValue: null,
      updatedAttributeId: null,
    };
  },
  computed: {
    ...mapState('assortmentCanvas', ['constraintEditing']),
    ...mapGetters('clustering', ['getScenarioAttributes']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('scenarioProducts', ['scenarioProducts']),
    ...mapGetters('assortmentCanvas', ['selectedCanvas']),

    editingMode() {
      return (
        this.constraintEditing.constraint.open &&
        isEqual(this.params.node.id, this.constraintEditing.constraint.rowNodeId)
      );
    },

    isSelected() {
      return this.editingMode && this.params.node.isSelected();
    },

    fieldValue() {
      return this.params.value;
    },

    allAttributeValuesAreAssigned() {
      return every(this.selectedFilters, sf => isNull(sf.value) === false);
    },

    isUnique() {
      const av = this.attributeValue;
      const afs = map(this.selectedFilters, 'value');
      const type = get(this.constraintEditing, 'constraint.rowNodeData.type');
      const limits = get(this.constraintEditing, 'constraint.rowNodeData.value', []);
      let constraints = [];
      if (this.spacebreakId !== null) {
        // in a spacebreak constraints, consider the spacebreak constraints + global ones
        constraints = filter(
          this.selectedCanvas.constraints.definitions,
          c => c.spacebreakId === this.params.node.data.spacebreakId || c.spacebreakId === null
        );
      } else {
        // global ones are compared with all constraints in all spacebreaks
        constraints = this.selectedCanvas.constraints.definitions;
      }
      // remove self constraint if the constraint was already stored into the db
      if (this.params.node.data.constraintId) {
        constraints = reject(constraints, { id: this.params.node.data.constraintId });
      }

      return every(constraints, c => {
        const af = c.attributeFilters;
        const attrValuesEqual = isEqual(af.attributeValues, afs);
        const areTypesEqual = type === c.type;
        const areLimitsEqual = isEqual(limits, c.limits);

        return !(af.attributeId === av && attrValuesEqual && areTypesEqual && areLimitsEqual);
      });
    },
    totalValidSelectedAttributeValues() {
      return filter(this.selectedFilters, sf => isNull(sf.value) === false).length;
    },
    editingModeClass() {
      return this.editingMode && this.isSelected ? 'editing-padding-top' : '';
    },
    spacebreakId() {
      return this.constraintEditing.constraint.rowNodeData.spacebreakId;
    },
    itemsSorted() {
      return sortBy(this.items, item => item.name.toLowerCase());
    },
  },
  watch: {
    selectedFilters: {
      deep: true,
      handler() {
        const attrId = this.updatedAttributeId
          ? this.updatedAttributeId
          : this.params.value.attributeId;
        if (this.isSelected) {
          this.updateConstraintRowNodeData({
            data: { attributeId: attrId, attributeValues: this.selectedFilters.map(x => x.value) },
            field: this.params.field,
          });
        }
      },
    },
    fieldValue: {
      deep: true,
      handler() {
        this.loadConstraintDescription();
      },
    },
    editingMode() {
      this.loadFilterSelections();
      this.getValuesForSelectedAttribute(this.params.value.attributeId);
      this.attributeValue = this.params.value.attributeId;
    },
    isUnique(newValue) {
      this.globalEmit(assortmentCanvas.constraintsValidationOccurred, {
        isUnique: newValue,
      });
    },
    allAttributeValuesAreAssigned(newValue) {
      this.globalEmit(assortmentCanvas.constraintsValidationOccurred, {
        allAttributeValuesAreAssigned: newValue,
      });
    },
    attributeValue(newValue) {
      this.globalEmit(assortmentCanvas.constraintsValidationOccurred, {
        attributeId: !!newValue,
      });
    },
  },
  async created() {
    const attributesValuesMap = this.params.isCopyingConstraints
      ? this.params.attributesValuesMap
      : await this.fetchScenarioUniqueAttributeValues({
          scenarioId: this.selectedScenario._id,
        });
    this.items = cloneDeep(this.params.sourceScenarioAttributes || this.getScenarioAttributes);

    this.scenarioAttributesMap = keyBy(this.items, 'id');
    this.attributesValuesMap = cloneDeep(attributesValuesMap);
    this.loadConstraintDescription();
    this.loadFilterSelections();
    this.getValuesForSelectedAttribute(this.params.value.attributeId);
    this.attributeValue = this.params.value.attributeId;
  },
  methods: {
    ...mapActions('scenarioProducts', ['fetchScenarioUniqueAttributeValues']),
    ...mapMutations('assortmentCanvas', [
      'setConstraintRowNodeData',
      'updateConstraintRowNodeData',
    ]),
    loadConstraintDescription() {
      const value = this.params.value;
      const attributeName = value.attributeId
        ? this.scenarioAttributesMap[value.attributeId].name
        : '';
      const filters = value.attributeValues.join('-');
      this.constraintDescription = `${attributeName}-${filters}`;
    },
    loadFilterSelections() {
      this.selectedFilters = this.params.value.attributeValues.map(x => ({ value: x }));
    },
    getValuesForSelectedAttribute(id) {
      const selectableValues =
        id && this.attributesValuesMap ? this.attributesValuesMap[id].values : [];
      this.availableAttributes = selectableValues;
    },
    getUnselectedValues(index) {
      // find values used by previous dropdowns
      const previousFilters = slice(this.selectedFilters, 0, index);
      const previousFiltersValues = map(previousFilters, pf => pf.value);
      // exclude values used by previous dropdowns from available attributes
      const values = without(this.availableAttributes, ...previousFiltersValues);
      return sortBy(values, item => item.toLowerCase());
    },
    resetFilterSelections() {
      this.selectedFilters = [{ value: null }];
    },
    addAttributeValue() {
      this.selectedFilters.push({ value: null });
    },
    setAttributeValue(value) {
      this.getValuesForSelectedAttribute(value);
      this.updatedAttributeId = value;
      this.resetFilterSelections();
      if (this.isSelected) {
        this.updateConstraintRowNodeData({
          data: { attributeId: value, attributeValues: [] },
          field: this.params.field,
        });
      }
      this.attributeValue = value;
    },
    removeAttributeValue(value) {
      this.selectedFilters = reject(this.selectedFilters, { value });
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.cell-container {
  width: 100%;
}

.constraint-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.v-btn {
  padding-bottom: 0 !important;
}

.attributes-filter-renderer-triangle {
  margin-left: 5px;
}

.applied-filters-count {
  position: absolute;
  left: 25px;
  color: $assortment-primary-colour;
}
.filter-value-row {
  line-height: initial;
}
</style>
