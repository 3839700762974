<template>
  <v-avatar v-if="params.value !== undefined" size="18" color="#333">
    <span class="white--text headline">{{ params.value }}</span>
  </v-avatar>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'cellPriorityBadgeRenderer',
});
</script>

<style lang="scss" scoped></style>
