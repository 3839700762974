<template>
  <div class="cluster-storeclass-checkpoint-selector h-100">
    <v-data-table
      class="checkpoints-table h-100"
      :headers="headers"
      :items="canvasesByClusterStoreClass"
      dense
      disable-sort
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.status="{ item }">
        <v-icon size="18">{{ `$${item.status}` }}</v-icon>
      </template>

      <template v-slot:item.checkpoint="{ item }">
        <div class="d-flex">
          <rtls-select
            :value="item.checkpoint"
            white
            width="280px"
            hide-messages
            item-value="_id"
            :menu-props="{ 'z-index': 200 }"
            :items="getCheckpoints(item)"
            :label="$t('checkpointSelectionTable.placeholders.choose')"
            @change="checkpointId => onSelectionChange(checkpointId, item._id)"
          />
          <error-triangle class="ml-1 mr-1" :errors="errors" />
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  forEach,
  get,
  groupBy,
  find,
  keyBy,
  map,
  partition,
  sortBy,
  transform,
  values,
  some,
} from 'lodash';

export default {
  localizationKey: 'extract.reports',

  data() {
    return {
      detailRowData: {},
      errors: {},
      selectedCheckpointIds: {},
      headers: [
        {
          text: this.$t('checkpointSelectionTable.headers.cluster'),
          value: 'cluster',
          width: 100,
        },
        {
          text: this.$t('checkpointSelectionTable.headers.storeClass'),
          value: 'storeClass',
          width: 100,
        },
        {
          text: this.$t('checkpointSelectionTable.headers.switching'),
          value: 'switchingType',
          align: 'center',
          width: 100,
        },
        {
          text: this.$t('checkpointSelectionTable.headers.description'),
          value: 'description',
          width: 200,
        },
        {
          text: this.$t('checkpointSelectionTable.headers.checkpoint'),
          value: 'checkpoint',
          width: 290,
        },
      ],
    };
  },

  computed: {
    checkpointsByClusterStoreClass() {
      return groupBy(
        this.detailRowData.checkpoints.allCheckpoints,
        cp => `${cp.clusterId}-${cp.storeClassId}`
      );
    },

    canvasesByClusterStoreClass() {
      const sortedCanvases = sortBy(
        get(this.detailRowData.checkpoints, 'uniqueCanvases', []),
        'clusterId'
      );
      const [clustered, unclustered] = partition(sortedCanvases, r => r.clusterId);
      return map(unclustered.concat(clustered), c => {
        const usesUnclusteredMatrix = get(
          this.detailRowData.clusterMap,
          [c.clusterId, 'usesUnclusteredMatrix'],
          false
        );
        const switchingType =
          !c.clusterId || usesUnclusteredMatrix
            ? this.$t('assortmentCanvasesPanel.unclusteredSwitching')
            : this.$t('assortmentCanvasesPanel.clusteredSwitching');

        const checkpointsInGroup = new Set(
          (this.checkpointsByClusterStoreClass[`${c.clusterId}-${c.storeClassId}`] || []).map(
            cp => cp._id
          )
        );
        // check if any cp belonging to the same storeclass/cluster has been selected
        const selectedCheckpoint = find(this.selectedCheckpointIds, cpId =>
          checkpointsInGroup.has(cpId)
        );
        return {
          _id: c._id,
          clusterId: c.clusterId,
          cluster: get(
            this.detailRowData.clusterMap,
            [c.clusterId, 'clusterName'],
            this.$t('extract.reports.productStore.unClustered')
          ),
          switchingType,
          storeClass: get(this.detailRowData.furnitureMap, [c.storeClassId, 'name'], ''),
          storeClassId: c.storeClassId,
          description: c.description || this.$t('extract.reports.productStore.noDescription'),
          checkpoint: selectedCheckpoint ? { _id: selectedCheckpoint } : null,
        };
      });
    },

    checkpointsById() {
      return keyBy(this.detailRowData.checkpoints.allCheckpoints, '_id');
    },
  },

  created() {
    this.detailRowData = this.params.getDetailRowData(this.params);
    this.initCheckpoints();
  },

  methods: {
    initCheckpoints() {
      // AG-Grid rebuilds detail rows on master redraw, so we need to pre-populate it
      this.selectedCheckpointIds = transform(
        this.params.data.selectedCheckpointIds,
        (acc, checkpointId) => {
          const { clusterId, storeClassId } = this.checkpointsById[checkpointId];

          const { _id: canvasId } = this.canvasesByClusterStoreClass.find(
            c => c.clusterId === clusterId && c.storeClassId === storeClassId
          );

          acc[canvasId] = checkpointId;
        },
        {}
      );
    },

    getCheckpoints(row) {
      const checkpoints = get(
        this.detailRowData.checkpoints.checkpointsByClusterStoreClass,
        `${row.storeClassId}-${row.clusterId}`,
        []
      ).map(({ checkpointMeta, _id }) => {
        return {
          text: checkpointMeta.name,
          _id,
        };
      });
      checkpoints.unshift({
        text: this.$t('extract.reports.productStore.noSelection'),
        _id: null,
      });
      return checkpoints;
    },

    onSelectionChange(checkpointId, rowId) {
      this.$set(this.selectedCheckpointIds, rowId, checkpointId);
      const newData = {
        ...this.params.data,
        selectedCheckpointIds: values(this.selectedCheckpointIds).filter(n => n),
      };
      this.params.node.setData(newData);
      this.params.detailGridOptions.onCellValueChanged(newData, this.params.node.parent);
      this.$nextTick(() => this.trackError());
    },

    trackError() {
      const selectionsPerChainStoreClass = {};

      forEach(this.selectedCheckpointIds, ckpId => {
        if (ckpId === null) return;
        const isUnclustered = !this.checkpointsById[ckpId].clusterId;
        const { storeClassId } = this.checkpointsById[ckpId];
        if (!selectionsPerChainStoreClass[storeClassId]) {
          selectionsPerChainStoreClass[storeClassId] = {};
        }
        if (isUnclustered)
          selectionsPerChainStoreClass[storeClassId].hasUnclusteredSelection = true;
        else selectionsPerChainStoreClass[storeClassId].hasClusteredSelection = true;
      });

      // negate for Vuetify
      const hasValidSelections = !some(
        selectionsPerChainStoreClass,
        ({ hasUnclusteredSelection, hasClusteredSelection }) =>
          hasClusteredSelection && hasUnclusteredSelection
      );
      this.errors = {
        [this.$t('general.cannotMixUnclusteredWithClustered')]: hasValidSelections,
      };
      this.params.detailGridOptions.onCellValidationChanged(this.params, hasValidSelections);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .checkpoints-table {
    & table {
      height: 100%;
    }
  }
}
</style>
