import { get, size as _size, map, toLower, camelCase, find } from 'lodash';
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import { modellingTypes } from '@enums/product-modelling-types';
import originSourceTypes from '@enums/origin-source-types';
import productUtils from '../../utils/product-utils';
import { StoreNamespaces } from '../../store/constants';

const mixin = {
  computed: {
    ...mapState('context', ['clientConfig']),
    ...mapState('assortmentCanvas', ['spacebreaks', 'spacebreaksOrderedObject']),
    ...mapGetters('assortmentCanvas', ['isSpacebreakLocked', 'getIndexedCanvasProducts']),

    imageUrl() {
      return productUtils.getImageUrl(this.clientConfig, this.product);
    },

    spacebreaksIndexedByIdByCluster() {
      return this.getSpacebreaksIndexedById[this.selectedCanvas.clusterId];
    },

    getProductTag() {
      const {
        modellingType,
        assortment,
        fromRestOfMarket,
        isNewProduct,
        originSource,
      } = this.product;
      if (
        !assortment || // We don't want to show flags for discontinued products
        (modellingType === modellingTypes.modelledInCluster && !this.selectedCanvas.clusterId) ||
        modellingType === modellingTypes.established
      ) {
        // Scenario products will be marked as modelledInCluster if it is modelled in one, some
        // or all clusters so that it can be shown in product modelling dashboard, however we
        // don't want to show the modelledInCluster tag on the assortment page in this case
        return '';
      }
      // ROM products are new products but we need to mark them with a different tag
      if (fromRestOfMarket) return toLower(modellingTypes.rom);
      const isSemiNewProduct = !isNewProduct && originSource === originSourceTypes.newInTemplate;
      if (this.hasProductsExistingFromNewInTemplateEnabled && isSemiNewProduct)
        return camelCase(modellingTypes.semiNew);
      return modellingType || '';
    },

    spacebreaksNames() {
      const names = map(this.spacebreaks, ({ _id, name, shortName, size, fillOverride }) => ({
        value: _id,
        text: `${shortName} - ${name}`,
        disabled: this.isSpacebreakLocked(_id),
        size,
        fillOverride,
      }));
      return [
        ...names,
        {
          value: null,
          text: this.$t('assortmentCanvasPage.tileViewPage.notListed'),
          disabled: false,
          size: 0,
          fillOverride: 0,
        },
      ];
    },

    spacebreaksShortNames() {
      const shortNames = map(this.spacebreaks, ({ _id, shortName, size, fillOverride }) => ({
        value: _id,
        text: shortName,
        disabled: this.isSpacebreakLocked(_id),
        size,
        fillOverride,
      }));
      return [
        ...shortNames,
        {
          value: null,
          text: this.$t('assortmentCanvasPage.tileViewPage.notListed'),
          disabled: false,
          size: 0,
          fillOverride: 0,
        },
      ];
    },

    spacebreaksShortNamesNone() {
      const shortNames = map(this.spacebreaks, ({ _id, shortName, size, fillOverride }) => ({
        value: _id,
        text: shortName,
        disabled: this.isSpacebreakLocked(_id),
        size,
        fillOverride,
      }));
      return [
        ...shortNames,
        {
          value: null,
          text: this.$t('general.none'),
          disabled: false,
          size: 0,
          fillOverride: 0,
        },
      ];
    },

    selectedSpacebreak() {
      return productUtils.selectedSpacebreak(
        this.spacebreaksShortNames,
        this.product.currentSpacebreakId
      );
    },

    selectedSpacebreakName() {
      const spacebreak = find(this.spacebreaks, { _id: this.product.currentSpacebreakId });
      if (spacebreak) {
        return spacebreak.name;
      }
      // currentSpacebreakId is null
      if (!this.product.assortment || this.product.isLocal) {
        return this.$t(`assortmentCanvas.notForAssortment`);
      }
      return !this.product.isEligible
        ? this.$t('assortmentCanvas.notInStoreClass')
        : this.$t('assortmentCanvasPage.tileViewPage.notListed');
    },

    canProductBeMoved() {
      return this.product.assortment && !this.product.isLocal && this.product.isEligible;
    },
  },

  methods: {
    ...mapMutations(StoreNamespaces.assortmentCanvas, [
      'updateAssortmentCanvasProductById',
      'setShowDashboard',
      'setDashboardProduct',
      'setShowDashboard',
    ]),
    ...mapActions(StoreNamespaces.assortmentCanvas, ['updateCanvasProduct']),
    ...mapActions(StoreNamespaces.snackbar, ['showError']),
    ...mapActions(StoreNamespaces.scenarioProducts, ['getAssociatedPallets']),

    buildProductPositioningInfo({ spacebreakId, spacebreaksIndexedById, selectedPod }) {
      const spacebreakIdInfo = get(spacebreaksIndexedById, spacebreakId, {
        shortName: null,
        storeKeys: [],
      });
      const spacebreakIdPod = get(selectedPod, spacebreakId, 0);
      const noOfStores = _size(spacebreakIdInfo.storeKeys);
      return this.$t('assortmentCanvas.productPositioning', [
        get(spacebreakIdInfo, 'shortName', ''),
        noOfStores,
        noOfStores ? spacebreakIdPod : 0,
      ]);
    },

    extractProductPositioningInfo(product, clusterId, spacebreaksIndexedById, selectedPod) {
      // made this function a method rather than a computed so we can reuse it between list view and tile view
      const currentSpacebreakId = this.buildProductPositioningInfo({
        spacebreakId: product.currentSpacebreakId,
        spacebreaksIndexedById: spacebreaksIndexedById[clusterId],
        selectedPod,
      });
      const optimisedSpacebreakId = this.buildProductPositioningInfo({
        spacebreakId: product.optimisedSpacebreakId,
        spacebreaksIndexedById,
        selectedPod,
      });
      const originalSpacebreakId = this.buildProductPositioningInfo({
        spacebreakId: product.originalSpacebreakId,
        spacebreaksIndexedById,
        selectedPod,
      });
      return {
        currentSpacebreakId,
        optimisedSpacebreakId,
        originalSpacebreakId,
      };
    },

    podAndStoreCounts(product, clusterId, spacebreaksIndexedByIdByCluster) {
      return {
        currentSpacebreak: {
          id: product.currentSpacebreakId,
          shortName: get(
            this.getSpacebreakInfo(
              product.currentSpacebreakId,
              clusterId,
              spacebreaksIndexedByIdByCluster
            ),
            'shortName',
            ''
          ),
          pod: this.selectedPod[product.currentSpacebreakId],
          storeCount: this.numberOfStores(
            product.currentSpacebreakId,
            spacebreaksIndexedByIdByCluster
          ),
        },
        originalSpacebreak: {
          id: product.originalSpacebreakId,
          shortName: get(
            this.getSpacebreakInfo(
              product.originalSpacebreakId,
              clusterId,
              spacebreaksIndexedByIdByCluster
            ),
            'shortName',
            ''
          ),
          pod: this.selectedPod[product.originalSpacebreakId],
          storeCount: this.numberOfStores(
            product.originalSpacebreakId,
            spacebreaksIndexedByIdByCluster
          ),
        },
        optimisedSpacebreak: {
          id: product.optimisedSpacebreakId,
          shortName: get(
            this.getSpacebreakInfo(
              product.optimisedSpacebreakId,
              clusterId,
              spacebreaksIndexedByIdByCluster
            ),
            'shortName',
            ''
          ),
          pod: this.selectedPod[product.optimisedSpacebreakId],
          storeCount: this.numberOfStores(
            product.optimisedSpacebreakId,
            spacebreaksIndexedByIdByCluster
          ),
        },
      };
    },

    getSpacebreakInfo(spacebreakId, clusterId, spacebreaksIndexedByIdByCluster) {
      return get(spacebreaksIndexedByIdByCluster, spacebreakId, {
        shortName: null,
        storeKeys: [],
      });
    },

    numberOfStores(spacebreakId, spacebreaksIndexedByIdByCluster) {
      const spacebreakIdInfo = get(spacebreaksIndexedByIdByCluster, spacebreakId, {
        storeKeys: [],
      });
      return spacebreakIdInfo.storeKeys.length;
    },

    async changeSpacebreak(spacebreakId) {
      this.setShowDashboard(false);
      await productUtils.moveProductToNewSpacebreak(
        this.product,
        spacebreakId || null,
        {
          updateAssortmentCanvasProductById: this.updateAssortmentCanvasProductById,
          updateCanvasProduct: this.updateCanvasProduct,
          showError: this.showError,
          spacebreaksOrderedObject: this.spacebreaksOrderedObject,
          getAssociatedPallets: this.getAssociatedPallets,
          getIndexedCanvasProducts: this.getIndexedCanvasProducts,
        },
        this.$t('assortmentCanvas.invalidPallets')
      );
    },

    openProductDashboard() {
      this.show = false;
      this.setDashboardProduct(this.product);
      this.setShowDashboard(true);
    },
  },
};

export default mixin;
