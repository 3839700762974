<template>
  <v-col
    :cols="3"
    class="attributes-column pa-0 d-flex flex-column justify-space-between"
    style="vertical-align: top"
  >
    <v-container class="ma-0 pa-0 controls">
      <v-row no-gutters class="d-flex justify-start align-center heading-row pt-4 pb-3">
        <v-col :cols="12" class="pl-3">
          <h4>{{ $tkey('selectAttributes') }}</h4>
        </v-col>
      </v-row>
      <v-divider />
    </v-container>

    <div v-if="selectedCannGroup" class="attributes pa-2">
      <h4 v-if="scenarioHasAttributes" class="attributes-column-header pb-2">
        {{ $tkey('customerFacingAndReporting') }}:
      </h4>
      <h4 v-else class="attributes-column-header text-center pt-6 pb-2">
        {{ $tkey('scenarioHasNoAttributes') }}
      </h4>
      <div class="attribute-list-group">
        <div
          v-for="item in currentSelections"
          :key="item.id"
          class="d-flex align-center justify-start attribute-item"
        >
          <v-checkbox
            :disabled="disabled"
            :input-value="item.selected"
            class="rtls-checkbox ml-2 mr-0"
            @change="item.selected = $event"
          />
          <h4 class="pb-0">{{ item.name }}</h4>
        </div>
      </div>
    </div>
    <div v-else class="select-cann-group pa-10">{{ $tkey('selectCannGroup') }}</div>
    <div
      v-if="selectedCannGroup"
      class="attributes-actions-container pt-2 pb-0 d-flex justify-end flex-wrap"
    >
      <v-btn v-if="showNotImplemented" disabled class="mr-2 mb-2" icon text>
        <v-icon size="24">$reset</v-icon>
      </v-btn>

      <!-- Copy selection modal -->
      <select-multiple-cann-groups-modal
        include-options
        exclude-self
        enable-all-options
        save-button-text="copyAndSave"
        :selected-cann-group="selectedCannGroup"
        :title="$tkey('copy.title')"
        :subtitle="$tkey('copy.subtitle')"
        @confirm="copyCdtInformation"
      >
        <template v-slot:activator="{ on }">
          <v-btn :disabled="disabled" secondary depressed class="mr-2 mb-2" v-on="on">
            {{ $tkey('copy.button') }}
          </v-btn>
        </template>
      </select-multiple-cann-groups-modal>

      <v-btn
        data-id-e2e="btnSaveCDT"
        action
        :disabled="disabled || !hasDataChanges"
        depressed
        class="mr-2 mb-2"
        @click="saveSelection"
      >
        {{ $t('actions.save') }}
      </v-btn>
      <v-btn
        data-id-e2e="btnGenerateCDT"
        primary
        depressed
        :disabled="disabled || !hasSelectedAttribute"
        class="mr-2 mb-2"
        @click="generate"
      >
        {{ $tkey('generate') }}
      </v-btn>
    </div>
  </v-col>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { cloneDeep, find, isEqual, keyBy, get } from 'lodash';
import cannGroupUtils from '@/js/utils/cann-groups';

export default {
  props: {
    selectedCannGroup: {
      required: true,
      type: Object,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  localizationKey: 'customerDecisionTree',

  data() {
    return {
      initialSelections: null,
      currentSelections: null,
      scenarioAttributes: [],
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('scenarioCdts', ['scenarioCdts', 'cdtSelectedAttributes']),
    ...mapGetters('clustering', ['getScenarioAttributes']),
    ...mapGetters('scenarioCdts', ['getCdtSelectedAttributes']),
    ...mapGetters('context', ['showNotImplemented']),

    hasDataChanges() {
      const dataChanged =
        !isEqual(this.currentSelections, this.initialSelections) && this.scenarioHasAttributes;
      this.$emit('data-changed', dataChanged);
      return dataChanged;
    },

    hasSelectedAttribute() {
      // checking whether the user has selected any attribute
      if (!this.cdtSelectedAttributes || !this.selectedCannGroup) {
        return false;
      }
      const { key } = this.selectedCannGroup;
      const attributeIsSelected = this.cdtSelectedAttributes[key]
        ? this.cdtSelectedAttributes[key].filter(attr => attr.selected).length > 0
        : false;
      return attributeIsSelected;
    },

    selectedScenarioCdt() {
      const { key } = this.selectedCannGroup;
      return this.scenarioCdts.find(o => o.cannGroupId === key);
    },

    scenarioHasAttributes() {
      return !!this.currentSelections;
    },
  },

  watch: {
    selectedCannGroup: {
      immediate: true,
      handler() {
        this.reset();
        this.setInitialSelections();
      },
    },
  },

  async created() {
    await this.fetchScenarioCdts();
    if (this.scenarioAttributes && !this.scenarioAttributes.length && this.selectedCannGroup) {
      this.scenarioAttributes = this.cdtSelectedAttributes[this.selectedCannGroup.key];
    }
    this.setInitialSelections();
    this.cannGroups = cannGroupUtils.getLeafNodes(this.selectedScenario.cannGroups);
  },
  methods: {
    ...mapMutations('scenarioCdts', ['setCdtSelectedAttributes']),
    ...mapActions('scenarioCdts', [
      'fetchScenarioCdts',
      'addCdt',
      'updateCdtById',
      'copyAttributeSelection',
    ]),

    setInitialSelections() {
      const scenarioAttributes = this.scenarioAttributes ? this.scenarioAttributes : [];
      if (!this.selectedCannGroup) {
        return;
      }
      const { key } = this.selectedCannGroup;
      const savedDocument = find(this.scenarioCdts, item => item.cannGroupId === key);
      // check if attribute selections for this cann group have previously been saved in the database
      if (savedDocument && savedDocument.attributes && scenarioAttributes.length) {
        const { attributes } = savedDocument;
        const attributesMap = keyBy(attributes, 'attributeId');
        // select the previously saved attributes as true
        this.currentSelections = scenarioAttributes.map(item => {
          item.selected = !!attributesMap[item.id];
          return item;
        });
        this.initialSelections = cloneDeep(this.currentSelections);
      } else if (scenarioAttributes.length) {
        // if no attribute selection previously saved - select all available custom attributes on the scenario as true by default
        this.currentSelections = cloneDeep(scenarioAttributes);
        this.initialSelections = cloneDeep(scenarioAttributes);
      }
      // current and initial selections remain null if no scenario attributes available
      this.setCdtSelectedAttributes({ key, value: this.currentSelections });
    },

    reset() {
      this.scenarioAttributes = cloneDeep(this.getScenarioAttributes);
      this.currentSelections = null;
      this.initialSelections = null;
    },

    async generate() {
      const { key } = this.selectedCannGroup;
      await this.saveSelection();
      this.$emit('generate', { cannGroupIds: [key] });
    },

    async saveSelection(refresh = true) {
      const { key } = this.selectedCannGroup;
      const filteredAttributes = this.cdtSelectedAttributes[key]
        .filter(attr => attr.selected)
        .map(attr => attr.id);
      const cdtId = get(this.selectedScenarioCdt, '_id');
      let payload;
      if (cdtId) {
        payload = {
          cdtId,
          updates: {
            attributes: filteredAttributes.map(a => {
              return { attributeId: a };
            }),
          },
        };
        await this.updateCdtById(payload);
      } else {
        payload = {
          scenarioId: get(this.selectedScenario, '_id'),
          cannGroupId: key,
          attributesList: filteredAttributes,
          originalJobAttributesSelection: filteredAttributes,
        };
        await this.addCdt(payload);
      }

      if (!refresh) return;

      await this.fetchScenarioCdts();
      this.setInitialSelections();
    },

    async copyCdtInformation({ cannGroupIds }) {
      if (!cannGroupIds.length) return;

      // if there is any modification on screen, save it first
      if (this.hasDataChanges) {
        await this.saveSelection(false);
      }

      this.copyAttributeSelection({
        fromCannGroupId: this.selectedCannGroup.key,
        toCannGroups: cannGroupIds,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.attributes-column {
  flex: 1;
  height: $tab-panel-height;
  border-right: 3px solid $assortment-divider-colour;

  .select-cann-group {
    text-align: center;
    color: $assortment-text-colour;
    font-size: 1.2rem;
    flex-grow: 1;
  }

  .attributes {
    height: 100%;
    overflow-y: auto;
  }

  .attributes-column-header {
    font-weight: normal;
  }

  .attributes-actions-container {
    margin-top: 5px;
    border-top: 1px solid $assortment-divider-colour;
  }

  .v-input--checkbox {
    height: 100%;
    margin-top: 1px;
    ::v-deep {
      * i.v-icon {
        font-size: 16px;
      }
      .v-input--selection-controls__input {
        margin-right: 4px;
      }
    }
  }

  .attribute-list-group {
    .attribute-item {
      min-height: 28px;
      width: 100%;
      padding-left: 0px;
      margin-bottom: 5px;
      border-radius: 3px;
      color: $assortment-text-colour;
      border: 1px solid transparent;
      background-color: $assortment-attribute-colour;

      h4 {
        font-size: 1.1rem;
        cursor: default;
        word-break: break-word;
      }
    }
  }
}
</style>
