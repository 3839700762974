import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.componentName,
    {
      tag: "component",
      class: { "template-screen": _vm.fromTemplate },
      attrs: { "content-class": "rtls-dialog rtls-dialog--stores" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeModal.apply(null, arguments)
        },
        "click:outside": _vm.closeModal,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                VBtn,
                _vm._g(
                  {
                    staticClass: "workpackage-stores__link",
                    attrs: { ripple: false, text: "", small: "" },
                  },
                  on
                ),
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("workpackagePage.scope.viewStoreList")) +
                      "\n      "
                  ),
                  _c(VIcon, { attrs: { size: "20" } }, [
                    _vm._v("mdi-chevron-right"),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isPopupOpen,
        callback: function ($$v) {
          _vm.isPopupOpen = $$v
        },
        expression: "isPopupOpen",
      },
    },
    [
      _vm._v(" "),
      _c(
        "dialog-card",
        {
          attrs: {
            title: _vm.$tkey("popupHeading"),
            "is-popup": _vm.fromTemplate,
            "show-full-headers": !_vm.fromTemplate,
          },
          on: { close: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c("page-actions", {
                    attrs: {
                      "show-export": "",
                      "show-save-button": _vm.hasPermission(
                        _vm.userPermissions.canEditWorkpackageScope
                      ),
                      "show-discard": _vm.hasPermission(
                        _vm.userPermissions.canEditWorkpackageScope
                      ),
                      "export-options": _vm.exportOptions,
                      "has-data-changes": _vm.hasDataChanges,
                      "has-data-errors": _vm.hasDataErrors,
                      "live-data": _vm.stores,
                      "export-service": "workpackage-stores",
                    },
                    on: {
                      templateDataExport: function ($event) {
                        return _vm.dataExport({
                          columns: _vm.templateExportColumns,
                          mode: "template-export",
                        })
                      },
                      fullDataExport: function ($event) {
                        return _vm.dataExport({ mode: "full-export" })
                      },
                      discard: function ($event) {
                        return _vm.init()
                      },
                      save: function ($event) {
                        return _vm.saveImportedStores(false)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.fromTemplate
                          ? {
                              key: "right-end-btns",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "action-btn-container d-flex rtls-border rtls-border--left-thin pl-2",
                                    },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          attrs: {
                                            "data-id-e2e": "btnRunTemplate",
                                            disabled:
                                              !_vm.hasPermission(
                                                _vm.userPermissions
                                                  .canEditWorkpackageScope
                                              ) || _vm.hasDataChanges,
                                            loading:
                                              _vm.isWorkpackageSetupRunning,
                                            depressed: "",
                                            primary: "",
                                            small: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.runTemplateSetup(false)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.fromTemplate
                                                    ? "templatesPage.runWp"
                                                    : "workpackagePage.runWp"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VCard,
            {
              staticClass: "d-flex flex-column data-panel",
              staticStyle: { height: "100%" },
            },
            [
              _c("div", { staticClass: "action-panel" }, [
                _c(
                  "div",
                  { staticClass: "action-panel-container" },
                  [
                    _c("data-upload", {
                      attrs: {
                        legends: _vm.csvUploadLegends,
                        "csv-upload-handler": _vm.onCSVUpload,
                        disabled: !_vm.hasPermission(
                          _vm.userPermissions.canEditWorkpackageScope
                        ),
                      },
                      on: { process: _vm.process },
                    }),
                    _vm._v(" "),
                    _vm.fromTemplate
                      ? _c("import-from-planogram", { staticClass: "ml-3" })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.showErrorControls
                  ? _c(
                      "div",
                      {
                        staticClass: "action-panel-container align-self-center",
                        attrs: { id: "stores-error-controls" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "invalid-rows-error-box pr-2" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$tkey("storesPerClusterWarning", {
                                    minimumStoresPerCluster:
                                      _vm.minimumStoresPerCluster,
                                  })
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.filterInvalidClusters
                          ? _c(
                              VBtn,
                              {
                                attrs: { primary: "" },
                                on: { click: _vm.toggleInvalidClusters },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$tkey("showInvalidClusters")) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              VBtn,
                              {
                                attrs: { primary: "" },
                                on: { click: _vm.toggleInvalidClusters },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$tkey("showAllClusters")) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "action-panel-container" },
                  [
                    _c("rtls-search", {
                      attrs: {
                        placeholder: _vm.$tkey("labels.search"),
                        width: "240px",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.gridOptions.api.onFilterChanged()
                        },
                      },
                      model: {
                        value: _vm.searchString,
                        callback: function ($$v) {
                          _vm.searchString = $$v
                        },
                        expression: "searchString",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "ag-grid-box flex-grow-1" },
                [
                  _c("ag-grid-vue", {
                    staticClass: "ag-theme-custom ag-theme-custom--attributes",
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: {
                      "auto-params-refresh": "",
                      "column-defs": _vm.columnDefs,
                      "row-data": _vm.stores,
                      "does-external-filter-pass": _vm.doesExternalFilterPass,
                      "grid-options": _vm.gridOptions,
                      "row-drag-managed": true,
                      "stop-editing-when-cells-loses-focus": true,
                      "enable-range-selection": true,
                    },
                    on: {
                      "cell-value-changed": _vm.trackDiff,
                      "grid-ready": _vm.onGridReady,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("dependency-tree-feedback-modal", {
        attrs: {
          value: _vm.dependencyTreeModalOpen,
          results: _vm.dependencyTreeFeedback,
          page: "workpackageSetup",
        },
        on: { close: _vm.closeDependencyTreeModal, commit: _vm.commitHandler },
      }),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.closeUnsavedDataModal,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }