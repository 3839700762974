'use strict';

// Any custom numeral locales are defined here
// See docs at http://numeraljs.com/
// ensure names are lowercase

// There was no existing locale that suited a paricular client's needs so adding it manually here
// This is essentially en-gb with € for currency
const rtlseu = {
  name: 'rtlseu',
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal(number) {
    const b = number % 10;
    if ((number % 100) / 10 === 1) return 'th';
    if (b === 1) return 'st';
    if (b === 2) return 'nd';
    if (b === 3) return 'rd';
    return 'th';
  },
  currency: {
    symbol: '€',
  },
};

// Custom eu locale with serbian RSD currency
const rtlsserb = {
  ...rtlseu,
  name: 'rtlsserb',
  currency: {
    symbol: 'RSD',
  },
};

// Custom eu locale with Romanian lei currency
const rtlsrom = {
  ...rtlseu,
  name: 'rtlsrom',
  currency: {
    symbol: 'Lei',
  },
};

const rtlscs = {
  ...rtlseu,
  ...{
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
  },
  name: 'rtlscs',
  currency: {
    symbol: 'Kč',
  },
};

module.exports = {
  rtlseu,
  rtlsserb,
  rtlsrom,
  rtlscs,
};
