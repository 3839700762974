<template>
  <div :key="`overview-${isSidebarShown}`" class="container h-100 px-0 mx-0">
    <div class="reporting-sections-wrapper">
      <div class="reporting-section box-shadow-top-left">
        <div class="button-wrapper">
          <v-btn primary :disabled="isStatsReportDisabled" :to="goToDetailedView('stats')">
            {{ $tkey('sections.view') }}
          </v-btn>
        </div>
        <stats short-view class="flex-grow-1 shortview-report" />
      </div>

      <div class="reporting-section box-shadow-top-right">
        <div class="button-wrapper">
          <v-btn
            primary
            :disabled="isRelativeShareReportDisabled"
            :to="goToDetailedView('relative-share')"
          >
            {{ $tkey('sections.view') }}
          </v-btn>
        </div>
        <relative-share short-view class="flex-grow-1 shortview-report" />
      </div>

      <div class="reporting-section box-shadow-bottom-left">
        <div class="button-wrapper">
          <v-btn
            primary
            :disabled="isPriceLadderReportDisabled"
            :to="goToDetailedView('price-ladder')"
          >
            {{ $tkey('sections.view') }}
          </v-btn>
        </div>
        <price-ladder short-view class="flex-grow-1 shortview-report" />
      </div>

      <div class="reporting-section box-shadow-bottom-right">
        <div class="button-wrapper">
          <v-btn
            primary
            :disabled="isComparisonWaterfallReportDisabled"
            :to="goToDetailedView('comparison-waterfall')"
          >
            {{ $tkey('sections.view') }}
          </v-btn>
        </div>
        <comparison-waterfall short-view class="flex-grow-1 shortview-report" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  localizationKey: 'reportingPage',
  computed: {
    ...mapState('context', ['clientConfig']),
    ...mapState('reporting', ['isSidebarShown']),

    isStatsReportDisabled() {
      return !this.hasPermission(this.userPermissions.canViewStatsReport);
    },

    isRelativeShareReportDisabled() {
      return !this.hasPermission(this.userPermissions.canViewRelativeShareReport);
    },

    isPriceLadderReportDisabled() {
      return !this.hasPermission(this.userPermissions.canViewPriceLadderReport);
    },

    isComparisonWaterfallReportDisabled() {
      return !this.hasPermission(this.userPermissions.canViewComparisonWaterfallReport);
    },
  },

  methods: {
    goToDetailedView(page) {
      return {
        path: `/reporting-main/${page}`,
        query: this.$route.query,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.container {
  background-color: $reporting-section-bg-color;
  border-right: 1px solid $assortment-tab-divider-colour;
  border-bottom: 1px solid $assortment-tab-divider-colour;
  border-left: 3px solid $reporting-section-active-border;
}

.reporting-sections-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin-bottom: 20px;
  background-color: $assortment-background;
  height: 100%;
}

.reporting-section {
  background-color: $reporting-section-bg-color;
  display: flex;
  flex-direction: column;
  width: 100%;
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;
  }

  .shortview-report {
    margin-top: -29px;
  }

  button {
    width: 60px;
    margin-top: 10px;
    margin-right: 10px;
  }
}
.font-size-medium {
  font-size: medium;
}
::v-deep {
  .section .subtitle {
    font-weight: bold;
    font-size: 1.4rem;
  }
}
</style>
