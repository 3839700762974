<template>
  <div v-if="isVisible" class="d-flex justify-start reset__container">
    <v-btn
      class="reset__button"
      :class="{ 'reset__button--hidden': hideReset }"
      :ripple="false"
      icon
      :disabled="isDisabled"
      data-id-e2e="resetValueButton"
      @click.stop="reset"
    >
      <reset-icon />
    </v-btn>
    <v-text-field
      v-model="formattedNumber"
      class="reset__input"
      :class="{ 'reset__input--invalid': isInvalid, 'diff-background': isHighlighted }"
      :disabled="isDisabled"
      data-id-e2e="resetValueInput"
      reverse
      hide-details
      @blur="update"
      @focus="setValue(params.value)"
      @keydown.enter="update"
      @keydown.left.stop
      @keydown.right.stop
    />
  </div>
</template>

<script>
import Vue from 'vue';
import agGridUtils from '@/js/utils/ag-grid-utils';
import { get } from 'lodash';

export default Vue.extend({
  data() {
    return {
      numberInput: null,
      isInputActive: false,
    };
  },

  computed: {
    isVisible() {
      return get(this.params, 'visible', true);
    },

    isDisabled() {
      return get(this.params, 'isDisabled', false);
    },

    isInvalid() {
      return get(this.params, 'invalid', false);
    },

    isHighlighted() {
      return get(this.params, 'isHighlighted', false);
    },

    savedState() {
      return get(this.params, 'savedState', {});
    },

    colId() {
      return get(this.params, 'column.colId');
    },

    hideReset() {
      return (
        get(this.params, 'hideReset', false) ||
        get(this.params, ['data', this.colId]) === this.originalValue
      );
    },

    originalValue() {
      return get(this.savedState, [get(this.params, 'data.productKey'), this.colId], null);
    },

    formattedNumber: {
      get() {
        return this.isInputActive
          ? agGridUtils.formatters.numericFormatter(this.params)
          : this.params.valueFormatted;
      },
      set(newValue) {
        this.setValue(newValue);
      },
    },
  },

  methods: {
    reset() {
      this.params.node.setDataValue(this.colId, this.originalValue);
    },

    setValue(newValue) {
      this.isInputActive = true;
      this.numberInput = agGridUtils.parsers.numericPermissiveParser({ newValue }) || null;
    },

    update() {
      this.isInputActive = false;
      this.params.node.setDataValue(this.colId, this.numberInput);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.reset {
  &__container {
    height: 20px;
  }

  &__button {
    &--hidden {
      visibility: hidden;
    }

    &.v-btn.v-btn--icon {
      align-self: center;
      background-color: $assortment-primary-colour;
      border-radius: 0;
      color: $assortment-active-button-colour;
      height: 100%;
      width: 13px;

      svg {
        height: 12px;
        width: 12px;
      }
    }
  }

  &__input {
    height: 100%;
    width: 50px;

    &:not(.diff-background) {
      background: $assortment-input-background;
    }

    ::v-deep {
      .v-text-field__slot {
        height: 18px;

        input {
          padding: 0 5px;
        }
      }
    }

    &--invalid {
      ::v-deep {
        .v-input {
          &__slot:after,
          &__control > .v-input__slot:before {
            border: 1px solid $assortment-table-error-cell-border !important;
          }
        }
      }

      border-color: $assortment-table-error-cell-border;
    }
  }
}
</style>
