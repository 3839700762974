var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: { display: "contents" } },
    [
      _c(
        "div",
        {
          ref: "cann-group-name",
          staticClass:
            "grid-item-border-bottom flexed-white-bg-grid-item header cann-group-heading-container",
          class: _vm.cgHeaderPositioning,
          style: { "grid-column": `span ${_vm.childColumns}`, top: 0 },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column align-center pa-2 justify-center full-width",
            },
            [
              _c("div", { staticClass: "inside-box-title" }, [
                _c("div", { staticClass: "title-text" }, [
                  _c(
                    "h2",
                    {
                      staticClass: "cann-group-name",
                      attrs: { title: _vm.getCannGroupName },
                    },
                    [_vm._v(_vm._s(_vm.getCannGroupName))]
                  ),
                  _vm._v(" "),
                  _vm.showNotImplemented
                    ? _c("div", { staticClass: "d-flex cann-group-detail" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t("assortmentCanvasesPanel.percentOfSpace", [
                                "x",
                              ])
                            ) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "d-flex align-center mr-3 icons-container compressed",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "cdt-name",
          staticClass:
            "grid-item-border-bottom flexed-white-bg-grid-item header",
          class: _vm.cdtHeaderPositioning,
          style: {
            "grid-column": `span ${_vm.childColumns}`,
            top: `${_vm.cannGroupHeaderHeight}px`,
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-row full-width flex-grow-1 justify-center grey-background pa-1",
            },
            [
              _c("div", { staticClass: "inside-box-title" }, [
                _c("div", { staticClass: "title-text" }, [
                  _c(
                    "h2",
                    {
                      staticClass: "header-large",
                      attrs: { title: _vm.getCdtName },
                    },
                    [_vm._v(_vm._s(_vm.getCdtName))]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.cdtRoots, function (cdtNode) {
        return _c("cdt-column", {
          key: cdtNode._id,
          attrs: {
            "cdt-node-id": cdtNode._id,
            "cann-group-id": _vm.scenarioCdt.cannGroupId,
            depth: 1,
            cdts: _vm.scenarioCdt.cdt,
            "child-tree": _vm.childTree,
            "previous-heights": _vm.accumulatedHeights,
          },
          on: {
            "child-nodes-expanded": _vm.sumChildNodes,
            "child-nodes-collapsed": _vm.removeChildNodes,
          },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }