import { render, staticRenderFns } from "./compound-extract-report-generator.vue?vue&type=template&id=15fd4722&scoped=true"
import script from "./compound-extract-report-generator.vue?vue&type=script&lang=js"
export * from "./compound-extract-report-generator.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15fd4722",
  null
  
)

export default component.exports