<template>
  <div ref="panelHeader" :class="getDrawerClass" :style="getHeaderPanelStyle">
    <transition name="slide-fade">
      <div v-if="isProductSidebarOpen" class="sticky sticky--top">
        <div class="product-info">
          <div class="product-info__icons-row">
            <div class="d-flex">
              <v-icon
                v-if="showMinimumDistribution && product.minimumDistribution > 0"
                color="white"
                class="product-icon"
              >
                {{ 'mdi-speedometer' }}
              </v-icon>
              <div v-if="selectedDashboardProduct.productSeries" class="product-icon">
                <series-icon />
              </div>
              <div v-if="hasPallets" class="product-icon">
                <pallet-icon :fill="hasAssignedPallets ? iconPrimary : iconDisabled" />
              </div>
              <div v-if="!inReset" class="product-icon">
                <not-in-reset-icon color="white" />
              </div>
              <v-icon v-if="isLocked" color="white" class="product-icon">
                mdi-lock-outline
              </v-icon>
            </div>
            <v-btn icon size="small" @click="onContextClose">
              <v-icon class="close-icon">mdi-close</v-icon>
            </v-btn>
          </div>

          <div class="product-info__main-row">
            <div>
              <div>{{ selectedDashboardProduct.productSeries }}</div>
              <h3 class="product-info__title">{{ selectedDashboardProduct.itemDescription }}</h3>
              {{ selectedDashboardProduct.productKeyDisplay }}
            </div>

            <div class="product-info__image">
              <assortment-image
                :src="imageUrl"
                :alt="displayText"
                :title="displayText"
                width="108px"
                height="108px"
              />
            </div>
          </div>

          <div class="product-info__tags-row mt-2">
            <product-delta-tag
              :show="referenceDelta !== 0 && selectedDashboardProduct.isEligible"
              type="R"
              :delta="referenceDelta"
            />
            <assortment-tag :tag-text="getProductTag" />
            <product-delta-tag
              :show="shouldShowOptimisedDelta"
              type="O"
              :delta="lastOptimisedDelta"
            />
          </div>
        </div>

        <v-expansion-panels
          :flat="true"
          :multiple="true"
          class="product-panel__expansion-panel"
          accordion
        >
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="">
              <template v-slot:default>
                <v-row no-gutters>
                  <v-col cols="4" class="d-flex justify-start">
                    <span class="mr-3">
                      <spacbreak-panel-icon />
                    </span>
                    <h2>{{ $tkey('spacebreak') }}</h2>
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="pa-2 divider-top--dark">
                <div class="text-14 mb-2">{{ $tkey('current') }}: {{ selectedSpacebreakName }}</div>
                <v-row no-gutters>
                  <v-col cols="2" class="d-flex justify-end align-center pr-3">
                    <h3>{{ $t('actions.move') }}</h3>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="moveSelection"
                      :items="moveSelectionOptions"
                      hide-details
                      dense
                      append-icon="expand_more"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-3 mb-3">
                  <v-col cols="2" class="d-flex justify-end align-center pr-3">
                    <h3>{{ $t('actions.to') }}</h3>
                  </v-col>
                  <v-col>
                    <v-select
                      v-model="moveTo"
                      :items="moveToOptions"
                      hide-details
                      dense
                      append-icon="expand_more"
                    />
                  </v-col>
                  <v-col cols="auto" class="d-flex align-center ml-3">
                    <v-btn outlined color="primary" :disabled="isMoveDisabled" @click="move">
                      {{ $t('actions.move') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="selectedDashboardProduct.productSeries">
            <v-expansion-panel-header expand-icon="" class="series-expansion-panel-header">
              <template v-slot:default>
                <v-row no-gutters>
                  <v-col cols="4" class="d-flex justify-start">
                    <span class="mr-3">
                      <series-panel-icon />
                    </span>
                    <h2>{{ $tkey('productSeries') }}</h2>
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="pa-2">
                <div class="text-14">
                  <v-tooltip :z-index="150" top>
                    <template v-slot:activator="{ on }">
                      <span
                        class="text-14"
                        v-on="selectedDashboardProduct.productSeries ? on : null"
                      >
                        {{ seriesManagementInfoTxt }}
                      </span>
                    </template>
                    <span>{{ $tkey('seriesStatsTotal') }}</span>
                  </v-tooltip>
                  <actionable-link :to="goToPAEPage" :style="{ fontSize: '12px' }">
                    {{ $tkey('manageSeries') }}
                  </actionable-link>
                </div>

                <v-row no-gutters class="mb-2">
                  <v-col class="d-flex justify-end align-center pr-2">
                    <span class="pr-2">{{ $t('general.all') }}</span>
                    <v-icon
                      size="20"
                      color="primary"
                      :disabled="isEditingDisabled"
                      @click="toggleAllLock()"
                    >
                      {{
                        hasSeriesUnlockedProducts
                          ? 'mdi-lock-outline'
                          : ' mdi-lock-open-variant-outline'
                      }}
                    </v-icon>
                  </v-col>
                </v-row>

                <div class="series-products__container mb-2">
                  <v-row
                    v-for="p in seriesProducts"
                    :key="p._id"
                    no-gutters
                    class="series-products__item pa-2"
                  >
                    <v-col cols="10">
                      <div
                        class="w-100 overflow-ellipsis"
                        :class="{ 'font-weight-bold': p._id === selectedDashboardProduct._id }"
                      >
                        <span>{{ p.itemDescription }}</span>
                      </div>
                    </v-col>
                    <v-col class="d-flex justify-end align-center">
                      <v-icon
                        size="20"
                        color="primary"
                        :disabled="isEditingDisabled"
                        @click="toggleLock(p)"
                      >
                        {{
                          isProductLocked(p) ? 'mdi-lock-outline' : 'mdi-lock-open-variant-outline'
                        }}
                      </v-icon>
                    </v-col>
                  </v-row>
                </div>
                <div class="d-flex justify-space-between">
                  <v-btn
                    v-if="!isFilterSeriesApplied"
                    outlined
                    depressed
                    color="primary"
                    @click="filterSeries"
                  >
                    {{ $tkey('displaySeries') }}
                  </v-btn>
                  <v-btn v-else outlined depressed color="primary" @click="clearSeriesFilter">
                    {{ $tkey('clearSeriesFilter') }}
                  </v-btn>
                  <div>
                    <v-tooltip :z-index="150" top>
                      <template v-slot:activator="{ on }">
                        <span class="text-14" v-on="on"
                          >{{ totalSeriesSpaceStats.roundedUnlockedSize }} /
                        </span>
                      </template>
                      <span>{{ $tkey('seriesStatsHoverUnlocked') }}</span>
                    </v-tooltip>
                    <v-tooltip :z-index="150" top>
                      <template v-slot:activator="{ on }">
                        <span class="text-14" v-on="on">{{
                          `${totalSeriesSpaceStats.roundedTotalSize} (${totalSeriesSpaceStats.uom})`
                        }}</span>
                      </template>
                      <span>{{ $tkey('seriesStatsTotal') }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { get, isEmpty, sortBy, cloneDeep, groupBy, camelCase } from 'lodash';
import { lockTypes } from '@enums/assortment-product-lock-types';
import { Filters } from '@enums/assortment-canvases';
import spacebreakMoveSelection from '@enums/product-spacebreak-move-selection';
import productMixin from './product.mixin';
import productUtils from '../../utils/product-utils';

export default {
  mixins: [productMixin],

  localizationKey: 'assortmentCanvas',

  data() {
    return {
      iconPrimary: '#ffffff',
      iconDisabled: '#d9d9d9',
      moveSelection: spacebreakMoveSelection.productOnly,
      moveTo: '',
    };
  },

  computed: {
    ...mapState('assortmentCanvas', [
      'isProductSidebarOpen',
      'expandedCdts',
      'accumulatedHeadersHeight',
      'numberOfRowsInGrid',
      'selectedDashboardProduct',
      'canvasProductsFilters',
      'spacebreaksOrderedObject',
    ]),
    ...mapGetters('assortmentCanvas', [
      'canvasProductsPallets',
      'selectedCanvas',
      'canvasProducts',
      'getIndexedCanvasProducts',
    ]),
    ...mapGetters('context', ['getClientConfig']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('workpackages', ['selectedWorkpackage']),

    product() {
      return this.selectedDashboardProduct;
    },

    getHeaderPanelStyle() {
      return {
        // allows full grid height span
        'grid-row': `span ${this.numberOfRowsInGrid}`,
        'z-index': 2, // above existing headers
        height: '100%',
        'min-width': '400px',
      };
    },

    getDrawerClass() {
      if (!this.isProductSidebarOpen) return;

      return 'd-flex flex-column shadow-right sticky sticky--right sticky--top product-panel-contents';
    },

    productPallets() {
      return this.canvasProductsPallets[this.selectedDashboardProduct.productKeyDisplay];
    },

    hasPallets() {
      return !isEmpty(this.productPallets);
    },

    hasAssignedPallets() {
      return this.hasPallets && this.productPallets.some(p => p.currentSpacebreakId);
    },

    displayText() {
      return (
        this.selectedDashboardProduct.itemDescription ||
        this.selectedDashboardProduct.productKeyDisplay
      );
    },

    referenceDelta() {
      return get(this.selectedDashboardProduct, 'deltas.reference', 0);
    },

    shouldShowOptimisedDelta() {
      return this.isOptimised && this.lastOptimisedDelta !== 0 && this.product.isEligible;
    },

    isOptimised() {
      return get(this.selectedCanvas, 'hasBeenOptimised', false);
    },

    lastOptimisedDelta() {
      return this.product.deltas ? this.product.deltas.lastOptimised : 0;
    },

    isLocked() {
      return this.product.lockType === lockTypes.locked || !this.inReset;
    },

    inReset() {
      return get(this.product, 'inReset', false);
    },

    hasMinimumDistributionEnabled() {
      return get(this.getClientConfig, 'features.minimumDistributionEnabled', false);
    },

    showMinimumDistribution() {
      return (
        this.hasMinimumDistributionEnabled &&
        get(this.selectedScenario, 'optionalAttributes.useMinimumDistribution')
      );
    },

    isFilterSeriesApplied() {
      return this.canvasProductsFilters.some(
        cpFilter =>
          cpFilter.filterType === Filters.ProductSeries &&
          cpFilter.filterValues.some(
            series => series.id === this.selectedDashboardProduct.productSeries
          )
      );
    },

    moveSelectionOptions() {
      const options = [
        { text: this.$tkey('productOnly'), value: spacebreakMoveSelection.productOnly },
      ];
      if (this.selectedDashboardProduct.productSeries) {
        options.push({
          text: this.$tkey('allUnlocked'),
          value: spacebreakMoveSelection.allUnlocked,
        });
      }
      return options;
    },

    moveToOptions() {
      return [
        {
          text: this.$tkey('selectSpacebreak'),
          value: '',
        },
        ...this.spacebreaksNames,
      ];
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAssortmentCanvas);
    },

    isMoveDisabled() {
      return (
        this.isEditingDisabled ||
        !this.moveSelection ||
        this.moveTo === '' ||
        (this.moveSelection === spacebreakMoveSelection.productOnly &&
          (!this.canProductBeMoved ||
            this.isLocked ||
            this.isSpacebreakLocked(this.product.currentSpacebreakId)))
      );
    },

    productsPerSeries() {
      return groupBy(this.canvasProducts, 'productSeries');
    },

    seriesProducts() {
      const seriesProducts = this.productsPerSeries[
        this.selectedDashboardProduct.productSeries
      ].filter(p => p.assortment && !p.isLocal && p.isEligible);

      return sortBy(seriesProducts, 'itemDescription');
    },

    hasSeriesUnlockedProducts() {
      return this.seriesProducts.some(p => !this.isProductLocked(p));
    },

    goToPAEPage() {
      return { name: 'attributes', params: { scenarioId: this.selectedScenario._id } };
    },

    totalSeriesSpaceStats() {
      const uom = this.$t(`suffixes.${camelCase(this.selectedWorkpackage.fillInSelection)}`);

      const { totalSize, totalUnlockedSize } = this.seriesProducts.reduce(
        (acc, sp) => {
          acc.totalSize += sp.size;
          if (sp.lockType === lockTypes.unlocked) acc.totalUnlockedSize += sp.size;
          return acc;
        },
        { totalSize: 0, totalUnlockedSize: 0 }
      );
      const roundedTotalSize = this.formatNumber({ number: totalSize, format: 'float' });
      const roundedUnlockedSize = this.formatNumber({ number: totalUnlockedSize, format: 'float' });

      return {
        roundedTotalSize,
        roundedUnlockedSize,
        uom,
      };
    },

    seriesManagementInfoTxt() {
      if (!this.selectedDashboardProduct.productSeries) return this.$tkey('productNotPartOfSeries');
      const { roundedTotalSize, uom } = this.totalSeriesSpaceStats;

      return `${this.selectedDashboardProduct.productSeries} (${roundedTotalSize} ${uom})`;
    },
  },

  watch: {
    product(newValue, oldValue) {
      // If the product is part of a series, “all unlocked series products” should be selected by default
      if (newValue !== oldValue && newValue && newValue.productSeries) {
        this.moveSelection = spacebreakMoveSelection.allUnlocked;
      }
    },
  },

  methods: {
    ...mapMutations('assortmentCanvas', [
      'setIsProductSidebarOpen',
      'setDashboardProduct',
      'setCanvasProductsFilters',
      'setCanvasProductsFiltersSelection',
      'updateAssortmentCanvasProductById',
    ]),
    ...mapActions('assortmentCanvas', [
      'updateCanvasProduct',
      'updateCanvasProducts',
      'changeLockType',
    ]),
    ...mapActions('snackbar', ['showError']),
    ...mapActions('scenarioProducts', ['getAssociatedPallets']),

    isProductLocked(product) {
      return product.lockType === lockTypes.locked || !product.inReset;
    },

    onContextClose() {
      this.setIsProductSidebarOpen(false);
      this.setDashboardProduct(null);
    },

    filterSeries() {
      const filters = [
        {
          attributeId: '',
          notEqualTo: false,
          filterType: Filters.ProductSeries,
          filterName: this.$t(`filters.${Filters.ProductSeries}`),
          filterValues: [
            {
              id: this.selectedDashboardProduct.productSeries,
              name: this.selectedDashboardProduct.productSeries,
            },
          ],
        },
      ];
      this.setCanvasProductsFilters(filters);
      this.setCanvasProductsFiltersSelection(filters);
    },

    clearSeriesFilter() {
      const filters = this.canvasProductsFilters.filter(
        cpFilter => cpFilter.filterType !== Filters.ProductSeries
      );

      this.setCanvasProductsFilters(filters);
      this.setCanvasProductsFiltersSelection(filters);
    },

    async move() {
      if (this.moveSelection === spacebreakMoveSelection.productOnly) {
        await this.changeSpacebreak(this.moveTo);
      } else {
        await this.changeSeriesSpacebreak();
      }

      productUtils.scrollToProductInCanvas(this.product);
    },

    changeSeriesSpacebreak() {
      const series = this.selectedDashboardProduct.productSeries;

      const seriesProductsThatCanBeMoved = this.canvasProducts.filter(p => {
        const canProductBeMoved = p.assortment && !p.isLocal && p.isEligible;

        return (
          p.productSeries === series &&
          canProductBeMoved &&
          !this.isProductLocked(p) &&
          !this.isSpacebreakLocked(p.currentSpacebreakId)
        );
      });

      const promises = seriesProductsThatCanBeMoved.map(p =>
        productUtils.moveProductToNewSpacebreak(
          p,
          this.moveTo,
          {
            updateAssortmentCanvasProductById: this.updateAssortmentCanvasProductById,
            updateCanvasProduct: this.updateCanvasProduct,
            showError: this.showError,
            spacebreaksOrderedObject: this.spacebreaksOrderedObject,
            getAssociatedPallets: this.getAssociatedPallets,
            getIndexedCanvasProducts: this.getIndexedCanvasProducts,
          },
          this.$t('assortmentCanvas.invalidPallets')
        )
      );
      return Promise.all(promises);
    },

    toggleLock(product) {
      this.changeLockType({ product, isLocked: this.isProductLocked(product) });
    },

    toggleAllLock() {
      const lockType = this.hasSeriesUnlockedProducts ? lockTypes.locked : lockTypes.unlocked;
      const productsBeforeUpdates = cloneDeep(this.seriesProducts);
      this.seriesProducts.forEach(product => {
        product.lockType = lockType;
      });

      this.updateCanvasProducts({
        productsBeforeUpdates,
        locallyUpdatedProducts: this.seriesProducts,
        updates: this.seriesProducts.map(product => ({
          _id: product._id,
          fieldToUpdate: 'lockType',
          valueToUpdate: lockType,
        })),
        showToastMessages: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.product-panel-contents {
  align-self: start;
  width: 400px;
  background-color: white;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
}

.product-info {
  padding: 8px;
  font-size: 12px;

  &__icons-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .product-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 28px;
      margin-right: 1px;
    }

    .close-icon {
      color: $assortment-primary-colour;
      background-color: $assortment-active-button-colour;
    }
  }

  &__main-row {
    display: flex;
    justify-content: space-between;
    padding-top: 4px;
  }

  &__title {
    max-width: 260px;
  }

  &__image {
    display: flex;
    justify-content: center;
    width: 108px;
    height: 108px;

    ::v-deep {
      // hide broken images
      .product-image--text-wrapper {
        display: none !important;
      }
    }
  }

  &__tags-row {
    display: flex;
    justify-content: space-between;

    div {
      max-width: fit-content;
      font-size: 12px;
      border-radius: 3px;
      padding: 2px;
      margin: 4px;
    }
  }
}

.product-panel__expansion-panel {
  border-radius: 0;

  ::v-deep {
    .v-expansion-panel {
      &:before {
        box-shadow: none;
      }

      &--active:not(:first-child) {
        margin-top: 0;
      }

      &-header {
        padding: 12px;
        background-color: $canvas-tile-border-default;
        color: $spacebreak-pallets-border-colour;
        border-top: 1px solid $assortment-side-panel-border;
        border-radius: 0;
      }

      &-content__wrap {
        padding: 0;
      }
    }
  }

  .series-expansion-panel-header {
    border-bottom: 1px solid $assortment-side-panel-border;
    border-radius: 0;
  }
  .series-products {
    &__container {
      max-height: 175px;
      overflow-y: auto;
    }

    &__item {
      background-color: $canvas-tile-border-default;
      margin-bottom: 1px;
    }
  }
}
</style>
