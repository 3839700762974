var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex", class: _vm.editingModeClass },
    [
      !_vm.editingMode
        ? _c("div", [_vm._v(_vm._s(_vm.$tkey(_vm.params.value)))])
        : _vm._e(),
      _vm._v(" "),
      _vm.editingMode
        ? _c("rtls-select", {
            attrs: {
              value: _vm.fieldValue,
              white: "",
              width: "80%",
              items: _vm.items,
              placeholder: _vm.$t("general.select"),
              "menu-props": { "z-index": 200 },
              "hide-details": "",
            },
            on: { input: _vm.setFieldValue },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }