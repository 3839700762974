var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.imgComponent
    ? _c(_vm.imgComponent, {
        tag: "component",
        class: _vm.classes,
        attrs: { title: _vm.title },
      })
    : _c("img", {
        attrs: { title: _vm.title, alt: _vm.title, src: _vm.imgSrc },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }