<template>
  <div class="list-view-grid-item" :style="{ 'grid-row': `span ${rowCount}`, ...containerStyle }">
    <div>
      <div ref="list-view-container" class="list-view" ma-0 pa-0 :style="containerStyle">
        <!-- List view section -->
        <div
          class="headers pl-2 d-flex"
          :class="positioning"
          :style="{ top: `${previousHeights}px` }"
        >
          <!-- Dynamic columns -->
          <v-col
            v-for="(column, index) in visibleListViewColumns"
            :key="`${column.id}-${index}`"
            :class="column.headerClass"
            :title="column.id"
            :style="{ width: column.width + 'px' }"
          >
            {{ getColumnTitle(column) }}
          </v-col>

          <!-- Header for `i` icon-->
          <v-col class="header-col product-info-column" />
        </div>

        <!-- List views for products with assigned spacebreaks -->
        <v-row
          v-for="spacebreak in spacebreaks"
          :key="spacebreak._id"
          class="spacebreak-row spacebreak-with-products"
        >
          <list-view-entry
            v-if="listViewContainerRef"
            :title="createTitle(spacebreak)"
            :products="getProducts(spacebreak._id)"
            :spacebreak-id="spacebreak._id"
            :group-name="groupName"
            :list-view-ref="listViewContainerRef"
          />
        </v-row>

        <!-- List view for products with no assigned spacebreaks -->
        <v-row
          v-for="spacebreak in spacebreakTypes"
          :id="`${spacebreak}-spacebreak-list`"
          :key="spacebreak"
          class="spacebreak-row"
        >
          <list-view-entry
            v-if="listViewContainerRef"
            :title="$tkey(spacebreak)"
            :products="productsWithoutSpacebreak[spacebreak]"
            :spacebreak-id="null"
            :spacebreak-type="spacebreak"
            :group-name="groupName"
            :list-view-ref="listViewContainerRef"
            :container-id="spacebreak"
          />
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { groupBy, get, fill, size } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { spacebreakTypes } from '@enums/spacebreak-types';
import destroy from '../../utils/destroy';

export default {
  localizationKey: 'assortmentCanvasPage.listViewPage',
  name: 'ListView',
  props: {
    products: {
      required: true,
      type: Array,
    },

    groupName: {
      required: true,
      type: String,
    },

    // Value is coming from the wrapper container, where we need to know what the previous headers' height was
    // so we could position the sticky header appropriately.
    previousHeights: {
      required: false,
      type: Number,
      default: 0,
    },

    positioning: {
      required: false,
      type: String,
      default: 'sticky',
    },
  },

  data() {
    return {
      mouseCoordinates: null,
      headerWidth: null,
      show: fill(Array(this.products.length), false),
      listViewContainerRef: null,
      spacebreakTypes,
      listColumns: {
        idCol: {
          maxWidth: 64,
          maxAligned: 30,
          currentWidth: 0,
        },
      },
    };
  },

  computed: {
    ...mapState('assortmentCanvas', [
      'spacebreaks',
      'listViewContainerWidth',
      'selectedTileSize',
      'unifiedColumns',
      'selectedView',
    ]),
    ...mapGetters('assortmentCanvas', [
      'getProductsWithNoSpacebreak',
      'listViewExtraColumns',
      'filteredListViewKpiColumns',
      'visibleListViewColumns',
    ]),

    rowCount() {
      // The grid-item / cell will span the amount of rows in the grid, this is set in the template above
      // allows us to have one large cell for each list view
      return this.spacebreaks.length + size(spacebreakTypes);
    },

    productsPerSpacebreak() {
      return groupBy(this.products, 'currentSpacebreakId');
    },

    containerStyle() {
      if (!this.listViewContainerWidth || !this.selectedTileSize) return;
      // Due to the sticky header breaking the flow we need to manually calculate its length to modify the surrounding container
      // The extra margin needed to account for the icon overflowing
      const iconMargin = 50;
      // Combine the actual current width with the extra space needed to account for cols
      // Set it to be in px
      const totalWidth = `${this.headerWidth + iconMargin}px`;

      if (this.unifiedColumns[this.selectedView]) return { 'max-width': '100%' };
      return {
        width: `${totalWidth}px`,
      };
    },

    productsWithoutSpacebreak() {
      const products = this.getProducts('null');
      return this.getProductsWithNoSpacebreak(products);
    },
  },

  watch: {
    listViewExtraColumns() {
      this.updateHeaderWidth();
    },
    visibleListViewColumns() {
      this.updateHeaderWidth();
    },
  },

  beforeDestroy() {
    destroy.destroyReactiveVueProps(this);
  },

  mounted() {
    this.listViewContainerRef = this.$refs;
    this.updateHeaderWidth();
  },

  methods: {
    updateHeaderWidth() {
      this.$nextTick(() => {
        // Get the total width of all visible columns
        const visibleColumnsWidth = this.visibleListViewColumns.reduce((total, column) => {
          return total + column.width; // The width is in px
        }, 0);

        // Calculate the total header width
        this.headerWidth = visibleColumnsWidth;
      });
    },

    createTitle({ name, fillRank, shortName }) {
      return `${fillRank} / ${shortName} / ${name}`;
    },

    getProducts(spacebreakId) {
      return get(this.productsPerSpacebreak, spacebreakId, []);
    },

    getColumnTitle(column) {
      if (column.id === 'productInfo') {
        return '';
      }
      return column.title || column.id || this.$t('general.notAvailable');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.list-view-grid-item {
  border-right: 1px solid $assortment-tile-border-colour;
  background: white;
}

.list-view {
  position: relative;
  padding: 10px !important;
}

.header-col {
  align-self: flex-end;
  font-size: 1.2rem;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.product-info-column {
  width: 50px;
}

.headers {
  border-bottom: 1px solid $assortment-tile-border-colour;
  margin-bottom: 10px;
  // space-between
}

.spacebreak-row {
  margin-bottom: 5px;
  width: 100%;
}

.spacebreak-row:last-of-type {
  border-bottom: none;
}

::v-deep {
  .col-short {
    max-width: 70px;
    min-width: 70px;
  }

  .col-xs {
    max-width: 28px;
    min-width: 28px;
  }

  .extra-col-short {
    max-width: 130px;
    min-width: 130px;
  }

  .description-col {
    min-width: 250px;
    max-width: 260px;
  }

  .icons-col {
    min-width: 135px;
    max-width: 135px;
  }

  .id-col {
    max-width: 100px;
    min-width: 100px;
  }
}

.sticky {
  position: sticky;
  z-index: 1;
  background-color: white;
}
</style>
