/**
 * A module for saving and loading grid column states to and from local storage.
 *
 * The column state can be represented as array of objects, where each object can either represent a column, or a column group:
 *
 * {
 *   colId: string, // The unique identifier for the column
 *   width: number, // The width of the column in pixels
 *   pinned: string, // The pinned state of the column ('left', 'right', or undefined)
 *   sort: string, // The sort direction of the column ('asc' or 'desc', or undefined)
 *   filter: string, // The filter value for the column (or undefined)
 *   // and some other fields
 * } for a column
 *
 * and {
 *  groupId: string, // The unique identifier for the col group
 *  children: Array<Column>[], // List of child columns, could have nested groups.
 *  // and some other fields
 * } for a column group
 *
 * Technically, we support any object in the settings, as long as no custom merge implementation is expected
 * for each object type.
 *
 * The grid column state is stored in local storage using keys of the form `${namePrefix}_${stateId}`, where `namePrefix` is a prefix
 * that identifies the type of object being edited (e.g. `attributeEditorColumnState`), and `stateId` is a unique identifier for
 * the column state (e.g. the scenario key).
 *
 * When the maximum number of stored entries per user per object type is exceeded (based on ±20 Kb object size), the oldest entry
 * is evicted (LRU).
 *
 * @module gridPreferences
 * @requires lodash
 */

import { filter, reduce, sortBy } from 'lodash';

/**
 * The maximum number of stored entries per user per object type, based on ±20 Kb object size.
 * @constant {number}
 */
const MAX_ENTRIES = 100;

/**
 * Saves the given grid column state to local storage.
 * @function saveColumnState
 * @param {string} namePrefix - The prefix to use for the local storage key, e.g. type of grid
 * @param {string} stateId - The identifier for the column state.
 * @param {Array} columnState - The column state to save.
 */
function saveColumnState(namePrefix, stateId, columnState) {
  localStorage.setItem(
    `${namePrefix}_${stateId}`,
    JSON.stringify({ data: columnState, timestamp: Date.now() })
  );

  const allKeys = Object.keys(localStorage);
  const stateKeys = filter(allKeys, key => key.startsWith(namePrefix));

  if (stateKeys.length > MAX_ENTRIES) {
    // Evict the oldest entry (LRU)
    const stateEntries = stateKeys.map(key => {
      const entryStr = localStorage.getItem(key);
      const entry = JSON.parse(entryStr);
      return { key, timestamp: entry.timestamp };
    });

    const sortedState = sortBy(stateEntries, 'timestamp');
    localStorage.removeItem(sortedState[0].key);
  }
}

/**
 * Loads the grid column state with the given name from local storage.
 * @function loadColumnState
 * @param {string} stateName - The name of the column state object to load.
 * @returns {Array|null} The loaded column state, or null if it doesn't exist.
 */
function loadColumnState(stateName) {
  const savedStateStr = localStorage.getItem(stateName);
  const stateObj = JSON.parse(savedStateStr);
  return stateObj ? stateObj.data : null;
}

/**
 * Recursively merges children nodes.
 * @param {Array} currentChildren - The current children nodes.
 * @param {Array} savedChildren - The saved children nodes.
 * @returns {Array} The merged children nodes.
 */
function mergeChildren(currentChildren, savedChildren) {
  return reduce(
    savedChildren,
    (acc, savedChild) => {
      const matchingCurrentChild = currentChildren.find(currentChild => {
        // If it's a group, compare groupIds, otherwise compare colIds
        if (currentChild.groupId) return currentChild.groupId === savedChild.groupId;
        return currentChild.colId === savedChild.colId;
      });

      if (matchingCurrentChild) {
        // eslint-disable-next-line no-use-before-define
        acc.push(findAndMerge(matchingCurrentChild, savedChild));
      }
      return acc;
    },
    []
  ).concat(
    currentChildren.filter(
      currentChild =>
        !savedChildren.some(savedChild => {
          if (savedChild.groupId) return savedChild.groupId === currentChild.groupId;
          return savedChild.colId === currentChild.colId;
        })
    )
  );
}

/**
 * Recursively finds and merges a current node with a saved node.
 * @param {Object} currentNode - The current node.
 * @param {Object} savedNode - The saved node.
 * @returns {Object} The merged node.
 */
function findAndMerge(currentNode, savedNode) {
  if (savedNode.colId && currentNode.colId === savedNode.colId) {
    // Only apply several fields from saved state to avoid overriding nested objects.
    // This is needed because we can't store function params in localStorage (such as cellRendererParams)
    // eslint-disable-next-line no-shadow
    const { width, pinned, sort, filter } = savedNode;
    return {
      ...currentNode,
      ...(width && { width }),
      ...(pinned && { pinned }),
      ...(sort && { sort }),
      ...(filter && { filter }),
    };
  }

  if (savedNode.groupId && currentNode.groupId === savedNode.groupId) {
    const mergedChildren = mergeChildren(currentNode.children, savedNode.children);
    return { ...currentNode, ...savedNode, children: mergedChildren };
  }

  return currentNode;
}

/**
 * Merges the saved column state from local storage with the current column state.
 * Now suitable for AgGrid state only.
 * @param {Array} savedState - The saved column state.
 * @param {Array} currentColDefs - The current column definitions (with grouping).
 * @returns {Array} The merged column state.
 */
function mergeColumnStates(savedState, currentColDefs) {
  return mergeChildren(currentColDefs, savedState);
}

/**
 * Merges the saved column state from local storage with the current column state.
 * Suitable for non-AG Grid column states.
 * @param {Array} savedState - The saved column state.
 * @param {Array} currentColumns - The current column definitions.
 * @param {string} field - The field to merge on.
 * @returns {Array} The merged column state.
 */
function mergeGenericColumnStates(savedState, currentColumns, field) {
  if (!field) {
    throw new Error('Field is required');
  }

  // Create a map of current columns by provided field
  const currentColumnsMap = currentColumns.reduce((map, column) => {
    map[column[field]] = column;
    return map;
  }, {});

  // Merge the saved columns with the current columns by provided field
  const updatedColumns = savedState.reduce((cols, savedColumn) => {
    const currentColumn = currentColumnsMap[savedColumn[field]];

    // If the current column exists, merge its properties with the saved column
    if (currentColumn) {
      cols.push({
        ...currentColumn,
        visible: savedColumn.visible,
      });
    }

    return cols;
  }, []);

  // Add any new columns from the current state that were not present in the saved state
  const newColumns = currentColumns.filter(
    currentColumn => !savedState.some(savedColumn => savedColumn[field] === currentColumn[field])
  );

  return [...updatedColumns, ...newColumns];
}

export default {
  saveColumnState,
  loadColumnState,
  mergeColumnStates,
  mergeGenericColumnStates,
};
