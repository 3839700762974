'use strict';

const tableHeaders = [
  { key: 'ranking', value: 'optimisedRank' },
  { key: 'productId', value: 'productKey' },
  { key: 'description', value: 'itemDescription' },
  { key: 'sales', value: 'sales' },
  { key: 'volume', value: 'volume' },
  { key: 'margin', value: 'margin' },
];

const valuesToRound = [
  tableHeaders[3].value, // rateOfSale
  tableHeaders[4].value, // volume
  tableHeaders[5].value, // margin
];

module.exports = {
  TABLE_HEADERS: tableHeaders,
  VALUES_TO_ROUND: valuesToRound,
  CHECKPOINT_TYPE: {
    user: 'user',
    tool: 'tool',
  },
  OPTIMISER_SETTINGS: {
    utilityWeights: 'utilityWeights',
    optimiseAccordingTo: 'optimiseAccordingTo',
    unitVolume: 'unitVolume',
    bulkVolume: 'bulkVolume',
    sales: 'sales',
    margin: 'margin',
    primaryCustomerFactor: 'primaryCustomerFactor',
    maxCap: 'maxCap',
    customerSegmentKey: 'customerSegmentKey',
  },
  API_ERRORS: {
    CANVAS_NOT_FOUND: 'errorMessages.canvasNotFound',
  },
};
