<template>
  <div style="display:contents">
    <div class="ag-grid-box flex-grow-1 grid-size">
      <ag-grid-vue
        style="width: 100%; height: 100%"
        class="ag-theme-custom ag-theme-custom--attributes"
        :grid-options="gridOptions"
        :column-defs="columnDefs"
        :default-col-def="defaultColDef"
        :auto-group-column-def="autoGroupColumnDef"
        :animate-rows="true"
        :row-model-type="rowModelType"
        :server-side-store-type="serverSideStoreType"
        :master-detail="true"
        :keep-detail-rows="true"
        :detail-cell-renderer-params="detailCellRendererParams"
      />
    </div>
    <div class="d-flex justify-end align-center status-container">
      <div class="flex-column">
        <span> {{ $t('workpackagePage.products.table.headers.selected') }}: </span>
        <span id="totalSelected">{{ selectedProductsCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapActions, mapState, mapMutations } from 'vuex';
import {
  get,
  difference,
  keyBy,
  merge,
  groupBy,
  range,
  set,
  isEmpty,
  keys,
  rangeRight,
  pull,
  intersection,
  reduce,
  times,
  camelCase,
} from 'lodash';
import destroy from '@/js/utils/destroy';
import agGridUtils from '@/js/utils/ag-grid-utils';

window.getProductCategoryRenderer = function getProductCategoryRenderer(
  clickHandler,
  selectedText
) {
  function ProductCategoryRenderer() {}
  ProductCategoryRenderer.prototype.init = function(params) {
    this.eGui = document.createElement('div');

    const total = get(params, 'data.totalProducts', 0);
    const totalSelected = get(params, 'data.totalSelected', 0);
    const text = params.node.group
      ? params.node.key
      : params.data[`productCategory${params.node.level + 1}Description`];

    let checkBoxIcon = '';
    if (total === totalSelected && total > 0) {
      checkBoxIcon = 'mdi-check-box-outline';
    } else if (totalSelected !== 0) {
      checkBoxIcon = 'mdi-checkbox-intermediate';
    } else {
      checkBoxIcon = 'mdi-checkbox-blank-outline';
    }

    const rowId = `${params.data.productCategoryKey}_${params.data.level}`;
    this.eGui.innerHTML = `<span id="${rowId}" class="checkbox-icon mdi ${checkBoxIcon}"></span>
                          <span>${text} - (${total}, ${totalSelected} ${selectedText}) </span>`;

    // get reference to the checkbox icon
    const virtualCheckbox = this.eGui.querySelector(`.checkbox-icon`);

    // add event to it
    virtualCheckbox.addEventListener('click', () => clickHandler(params));
  };
  ProductCategoryRenderer.prototype.getGui = function() {
    return this.eGui;
  };
  return ProductCategoryRenderer;
};

export default {
  localizationKey: 'workpackagePage.products',

  components: {
    AgGridVue,
  },

  props: {
    products: {
      required: true,
      type: Array,
    },

    isPopupOpen: {
      type: Boolean,
    },

    hierarchyDepth: {
      required: true,
      type: Number,
    },
  },

  data() {
    return {
      initialSelectedProductKeys: [],
      selectedProductKeys: [],
      deselectedProductKeys: [],
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      autoGroupColumnDef: null,
      rowModelType: null,
      serverSideStoreType: null,
      detailCellRendererParams: null,
      selectedProductsCount: 0,
      productCategoriesMap: {},
      selectedProductCategoryKeys: new Set(),
      deSelectedProductCategoryKeys: new Set(),
      hierarchyLookupMap: {},
      initialSelectedProductCategoryKeys: new Set(),
      categoriesByKey: {},
      selectedCategoriesProductsCount: 0,
      deSelectedCategoriesProductsCount: 0,
    };
  },

  computed: {
    ...mapState('workpackageProducts', ['loading', 'workpackageProducts']),
    ...mapState('workpackages', ['selectedWorkpackage', 'productsHierarchy']),

    hasDataChanges() {
      const newlySelectedKeys = difference(
        this.selectedProductKeys,
        this.initialSelectedProductKeys
      );
      const newlyDeSelectedKeys = intersection(
        this.deselectedProductKeys,
        this.initialSelectedProductKeys
      );
      const newlySelectedCategoryKeys = new Set(
        [...this.selectedProductCategoryKeys].filter(
          x => !this.initialSelectedProductCategoryKeys.has(x)
        )
      );
      const newlyDeSelectedCategoryKeys = new Set(
        [...this.deSelectedProductCategoryKeys].filter(x =>
          this.initialSelectedProductCategoryKeys.has(x)
        )
      );
      return (
        newlySelectedKeys.length > 0 ||
        newlyDeSelectedKeys.length > 0 ||
        newlySelectedCategoryKeys.size > 0 ||
        newlyDeSelectedCategoryKeys.size > 0
      );
    },

    productsByHierarchy() {
      return groupBy(this.productsWithCategory, p =>
        this.availableProductCategoryKeys.map(k => p[k]).join('-')
      );
    },

    productsWithCategory() {
      return this.products.map(p => {
        return merge(p, this.categoriesByKey[p.productCategoryKey]);
      });
    },

    availableProductCategoryKeys() {
      return times(this.hierarchyDepth, i => `productCategory${i + 1}Description`);
    },

    detailGridOptionsColDefs() {
      const columns = [
        {
          headerName: this.$tkey('table.headers.selected'),
          field: 'selected',
          editable: true,
          valueParser: agGridUtils.parsers.booleanParser,
          cellRenderer: params =>
            agGridUtils.utils.checkboxRenderer(params, this.handleProductSelectionChange),
          cellRendererParams: {
            field: 'selected',
          },
        },
        {
          headerName: this.$tkey('table.headers.productDescription'),
          field: 'itemDescription',
          minWidth: 200,
        },
        {
          headerName: this.$tkey('table.headers.brand'),
          field: 'brandDescription',
          minWidth: 100,
        },
        {
          headerName: this.$tkey('table.headers.content'),
          valueGetter: params => {
            if (get(params, 'data')) {
              return `${get(params, 'data.contentValue')}${get(
                params,
                'data.contentUnitOfMeasure'
              )}`;
            }
          },
          minWidth: 70,
          colId: 'contentAndUnitOfMeasure',
        },
        {
          headerName: this.$tkey('table.headers.supplier'),
          field: 'supplierName',
          minWidth: 100,
        },
      ];

      const extraColumns = get(this.getClientConfig, 'productHierarchyColumns', {});

      return agGridUtils.builders.mergeHeaders(columns, extraColumns, {
        componentName: camelCase(this.$options.name),
      });
    },
  },

  watch: {
    loading() {
      if (!this.gridOptions.api) return;
      if (this.loading) {
        this.gridOptions.api.showLoadingOverlay();
      } else {
        this.gridOptions.api.hideOverlay();
      }
    },
  },

  beforeMount() {
    this.gridOptions = {
      components: {
        productCategoryRenderer: window.getProductCategoryRenderer(
          this.handleProductCategorySelectionChange,
          this.$tkey('table.headers.selected')
        ),
      },
      cacheBlockSize: 8000,
      getRowId: row => {
        const { data } = row;
        let id = '';
        range(data.level, this.hierarchyDepth + 1).forEach(level => {
          id += `${data[`productCategory${level}Key`]}_`;
        });
        return id + data.productCategoryKey;
      },
      rowHeight: 30,
      // hides blue border
      suppressCellFocus: true,
    };
    this.columnDefs = range(1, this.hierarchyDepth).map(i => ({
      field: `productCategory${i}Description`,
      rowGroup: true,
      hide: true,
    }));
    this.defaultColDef = { flex: 1 };
    this.autoGroupColumnDef = {
      headerName: this.$tkey('table.headers.productCategory'),
      field: `productCategory${this.hierarchyDepth}Description`,
      minWidth: 250,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: {
        innerRenderer: 'productCategoryRenderer',
        vm: this,
      },
      comparator: agGridUtils.sortings.naturalSort,
      sort: 'asc',
    };
    this.rowModelType = 'serverSide';
    this.serverSideStoreType = 'full';
    this.detailCellRendererParams = {
      detailGridOptions: {
        columnDefs: this.detailGridOptionsColDefs,
        defaultColDef: { flex: 1 },
        rowHeight: 30,
        headerHeight: 40,
      },
      getDetailRowData: params => {
        const vm = this;
        this.getProductsHierarchyProductsData({
          productCategoryKeys: [params.data.productCategoryKey],
        }).then(products => {
          products.forEach(p => {
            if (vm.selectedProductCategoryKeys.has(p.productCategoryKey)) {
              p.selected = true;
            } else if (vm.deSelectedProductCategoryKeys.has(p.productCategoryKey)) {
              p.selected = false;
            }
          });
          params.successCallback(products, products.length);
        });
      },
    };
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },

  async created() {
    await this.init();
  },

  async beforeDestroy() {
    this.gridOptions.api.destroy();
    destroy.destroyReactiveVueProps(this);
  },

  methods: {
    ...mapMutations('workpackages', ['addProductsHierarchy']),
    ...mapActions('workpackages', ['getProductHierarchy', 'getProductsHierarchyProductsData']),

    async init() {
      this.$emit('refresh');
      await this.loadProductsCategories();
      this.initialSelectedProductKeys = this.workpackageProducts.map(p => p.productKey);
      this.selectedProductsCount = this.initialSelectedProductKeys.length;

      const vm = this;
      this.gridApi.setServerSideDatasource({
        getRows(params) {
          let reqParams = null;
          if (params.request.groupKeys.length) {
            const path = {};
            const level = params.request.groupKeys.length + 1;
            params.request.groupKeys.forEach((k, i) => {
              path[params.request.rowGroupCols[i].field] = k;
            });

            reqParams = { path, level };
          } else {
            reqParams = { level: 1 };
          }

          vm.getProductHierarchy(reqParams).then(({ hierarchy }) => {
            hierarchy.forEach(h => {
              const itemsToCheck = vm.availableProductCategoryKeys
                .slice(0, params.request.groupKeys.length ? params.request.groupKeys.length + 1 : 1)
                .map(k => h[k])
                .join('-');

              const isCurrentCategoryUnselected = vm.deSelectedProductCategoryKeys.has(
                h.productCategoryKey
              );
              const isCurrentCategorySelected = vm.selectedProductCategoryKeys.has(
                h.productCategoryKey
              );

              if (isCurrentCategorySelected) {
                h.totalSelected = h.totalProducts;
              } else if (isCurrentCategoryUnselected) {
                h.totalSelected = 0;
              } else {
                h.totalSelected = Object.keys(vm.productsByHierarchy)
                  // append final dash at the end to remove category starting with same name
                  .filter(k =>
                    k.startsWith(
                      `${itemsToCheck}${reqParams.level === vm.hierarchyDepth ? '' : '-'}`
                    )
                  )
                  .reduce((acc, k) => acc + vm.productsByHierarchy[k].length, 0);
              }
              h.level = reqParams.level;

              if (h.totalProducts > 0 && h.totalProducts === h.totalSelected) {
                vm.initialSelectedProductCategoryKeys.add(h.productCategoryKey);
              }
            });
            params.successCallback(hierarchy, hierarchy.length);
          });
        },
      });
    },

    openConfirmDialog() {
      this.showConfirmDialog = true;
      this.$refs.confirmDeleteProductsDialog.open();
    },

    async loadProductsCategories() {
      const { hierarchy } = await this.getProductHierarchy({});

      this.categoriesByKey = keyBy(hierarchy, 'productCategoryKey');
      // this is needed so we know how to select all sub categories, if
      // a parent category is checked
      hierarchy.forEach(h => {
        let thirdLevel = '';
        range(1, this.hierarchyDepth + 1).forEach(upperLevel => {
          let path = '';
          /* for each possible path in hierarchy, we will build a map for each possible combination in tree, e.g.:
          products =[
            { productCategoryKey: 1, productCategory1Key: 20, productCategory2Key: 30 },
            { productCategoryKey: 3, productCategory1Key: 20, productCategory2Key: 79 },
            { productCategoryKey: 2, productCategory1Key: 34, productCategory2Key: 7 },
          ]
          will combine all `productCategoryKey` depending on which level was selected as:
          hierarchyLookupMap = {
                20: [1, 3],
                20.30: [1],
                34: [2],
                34.7: [2]
          };
          So if the row whose level is 1 and cat1key is 20, all ids under this tree node (1 and 3) will be selected
          * */
          thirdLevel += `${h[`productCategory${upperLevel}Key`]}.`;
          const keysSoFarInMap = this.hierarchyLookupMap[thirdLevel] || new Set([]);
          keysSoFarInMap.add(h.productCategoryKey);
          this.hierarchyLookupMap[thirdLevel] = keysSoFarInMap;

          range(upperLevel, this.hierarchyDepth + 1).forEach(level => {
            path += `_${h[`productCategory${level}Key`]}.`;
          });
          path += `_${h.productCategoryKey}`;
          set(this.productCategoriesMap, path, {});
        });
      });
    },

    handleProductSelectionChange(params) {
      const value = !params.value;
      params.setValue(value);
      if (value) {
        this.selectedProductsCount += 1;
        pull(this.deselectedProductKeys, params.data.productKey);
        this.selectedProductKeys.push(params.data.productKey);
      } else {
        this.selectedProductsCount -= 1;
        pull(this.selectedProductKeys, params.data.productKey);
        this.deselectedProductKeys.push(params.data.productKey);
      }

      // update the selected count in the parent hierarchy rows
      const vm = this;
      const rowsToUpdate = [];
      const category = this.categoriesByKey[params.data.productCategoryKey];

      rangeRight(1, vm.hierarchyDepth + 1).forEach(parentLevel => {
        const parentRowIds = [];
        vm.getRowIds(`_${category[`productCategory${parentLevel}Key`]}`, parentRowIds);

        parentRowIds.forEach(rowId => {
          // have to split it in order to reconstruct the ids of the parent rows
          const row = vm.gridOptions.api.getRowNode(rowId.replaceAll('.', '_'));

          if (!row || row.data.level !== parentLevel) return;
          row.data.totalSelected += value ? 1 : -1;
          row.setData(row.data);
          rowsToUpdate.push(row);
        });
      });
      if (rowsToUpdate.length) vm.gridOptions.api.redrawRows(rowsToUpdate);

      // send selection to the parent component
      const newlySelectedKeys = difference(
        this.selectedProductKeys,
        this.initialSelectedProductKeys
      );
      const newlyDeSelectedKeys = intersection(
        this.deselectedProductKeys,
        this.initialSelectedProductKeys
      );
      this.$emit('handle-product-selection-updates', {
        selectedKeys: newlySelectedKeys,
        deselectedKeys: newlyDeSelectedKeys,
      });
    },

    getRowIds(initialKey, rowIds) {
      const mapToTraverse = get(this.productCategoriesMap, initialKey);
      if (!isEmpty(mapToTraverse)) {
        keys(mapToTraverse).forEach(k => {
          this.getRowIds(`${initialKey}.${k}`, rowIds);
        });
      } else {
        rowIds.push(initialKey.replaceAll('_', ''));
      }
    },

    handleProductCategorySelectionChange(params) {
      const childrenRowIds = [];
      const detailRowIds = [];

      const vm = this;
      const rowsToUpdate = [];
      const checked = params.data.totalSelected !== params.data.totalProducts;
      const totalOriginallySelectedItems = params.data.totalSelected;
      const itemsToUnselectAtLevel =
        params.data.totalProducts === totalOriginallySelectedItems
          ? params.data.totalProducts
          : totalOriginallySelectedItems;
      const newSelectionsAtLevel = params.data.totalProducts - totalOriginallySelectedItems;
      if (checked) {
        this.selectedCategoriesProductsCount += newSelectionsAtLevel;
        if (this.deSelectedCategoriesProductsCount > 0) {
          this.deSelectedCategoriesProductsCount -= newSelectionsAtLevel;
        }
      } else {
        this.deSelectedCategoriesProductsCount += itemsToUnselectAtLevel;
        if (this.selectedCategoriesProductsCount > 0) {
          this.selectedCategoriesProductsCount -= itemsToUnselectAtLevel;
        }
      }

      // check all the children categories
      vm.getRowIds(`_${params.data[`productCategory${params.data.level}Key`]}`, childrenRowIds);

      const idSoFar = reduce(
        range(1, params.data.level + 1),
        (acc, level) => `${acc}${params.data[`productCategory${level}Key`]}.`,
        ''
      );
      const categoriesKeysUnderCurrentNode = this.hierarchyLookupMap[idSoFar];
      categoriesKeysUnderCurrentNode.forEach(categoryKey => {
        if (checked) {
          this.selectedProductCategoryKeys.add(categoryKey);
          this.deSelectedProductCategoryKeys.delete(categoryKey);
        } else {
          this.selectedProductCategoryKeys.delete(categoryKey);
          this.deSelectedProductCategoryKeys.add(categoryKey);
        }
      });

      childrenRowIds.forEach(rowId => {
        // have to split it in order to reconstruct the ids of the child rows
        const rowIdParts = rowId.split('.');
        const rowIdLevel = this.hierarchyDepth + 2 - rowIdParts.length;

        // ignore any parent rows first
        if (rowIdLevel < params.data.level) return;

        // rowId is constracted by all the categoryKeys depending on the level
        // ex. for lvl1 it would be cat1Key_cat2Key_cat3Key_cat4Key_catKey
        // for lvl4 would be cat4Key_catKey. Here we deconstruct and reconstruct it
        // to correctly fetch from ag-grid the correct sub categories in order to update the selection count
        range(0, this.hierarchyDepth).forEach(partIndex => {
          if (partIndex + 1 < rowIdParts.length) {
            const constructedId = rowIdParts.slice(partIndex).join('_');
            const row = vm.gridApi.getRowNode(constructedId.replaceAll('.', '_'));

            // if tree has no child or somehow the level is smaller than the current one, don't update it.
            // Parents are updated on a next loop
            if (!row || row.data.level < params.data.level) return;
            // if any child row is already expanded, we should not sum again the total selected in vm.selectedProductsCount
            const shouldUpdateTotalCounts = params.data.level === row.data.level;
            const originallySelectedRowItems = row.data.totalSelected;
            if (checked) {
              row.data.totalSelected = row.data.totalProducts;
              vm.selectedProductsCount += shouldUpdateTotalCounts
                ? row.data.totalProducts - originallySelectedRowItems
                : 0;
            } else {
              row.data.totalSelected = 0;
              const itemsToUnselect =
                row.data.totalProducts === originallySelectedRowItems
                  ? row.data.totalProducts
                  : originallySelectedRowItems;
              vm.selectedProductsCount -= shouldUpdateTotalCounts ? itemsToUnselect : 0;
            }
            detailRowIds.push(constructedId);
            row.setData(row.data);
            rowsToUpdate.push(row);
          }
        });
      });

      // check all the parent categories
      if (params.data.level !== 1) {
        rangeRight(1, params.data.level).forEach(parentLevel => {
          const parentRowIds = [];
          vm.getRowIds(`_${params.data[`productCategory${parentLevel}Key`]}`, parentRowIds);

          parentRowIds.forEach(rowId => {
            // have to split it in order to reconstruct the ids of the parent rows
            const rowIdParts = rowId.split('.');
            const currentRowIdParts = params.node.id.split('_');

            if (rowIdParts.length > currentRowIdParts.length) {
              const constructedId = rowIdParts.join('_');
              const parentRow = vm.gridOptions.api.getRowNode(constructedId.replaceAll('.', '_'));

              if (!parentRow || parentRow.data.level !== parentLevel) return;
              if (checked) {
                parentRow.data.totalSelected += newSelectionsAtLevel;
              } else {
                parentRow.data.totalSelected -= itemsToUnselectAtLevel;
              }
              parentRow.setData(parentRow.data);
              rowsToUpdate.push(parentRow);
            }
          });
        });
      }

      vm.gridApi.redrawRows(rowsToUpdate);

      // if check/uncheck the already loaded products
      detailRowIds.forEach(detailRowId => {
        const detailGrid = vm.gridApi.getDetailGridInfo(`detail_${detailRowId}`);

        if (!detailGrid) return;
        const detailRowsToUpdate = [];

        detailGrid.api.forEachNode(row => {
          row.data.selected = checked;
          row.setData(row.data);
          detailRowsToUpdate.push(row);
        });
        detailGrid.api.redrawRows(detailRowsToUpdate);
      });

      this.$emit('handle-category-selection-updates', {
        selectedCategories: this.selectedProductCategoryKeys,
        deSelectedCategories: this.deSelectedProductCategoryKeys,
        selectedCategoriesProductsCount: this.selectedCategoriesProductsCount,
        deSelectedCategoriesProductsCount: this.deSelectedCategoriesProductsCount,
      });
    },

    resetTrackers() {
      const newlySelectedKeys = difference(
        this.selectedProductKeys,
        this.initialSelectedProductKeys
      );
      const newlyDeSelectedKeys = intersection(
        this.deselectedProductKeys,
        this.initialSelectedProductKeys
      );
      const selectedProductKeys = newlySelectedKeys.filter(
        x => newlyDeSelectedKeys.indexOf(x) === -1
      );
      const newlySelectedCategoryKeys = [...this.selectedProductCategoryKeys].filter(
        x => !this.initialSelectedProductCategoryKeys.has(x)
      );
      const newlyDeSelectedCategoryKeys = [...this.deSelectedProductCategoryKeys].filter(x =>
        this.initialSelectedProductCategoryKeys.has(x)
      );

      const selectedCategoryKeys = newlySelectedCategoryKeys.filter(
        x => newlyDeSelectedCategoryKeys.indexOf(x) === -1
      );

      this.initialSelectedProductCategoryKeys = new Set(selectedCategoryKeys);
      this.deSelectedProductCategoryKeys = new Set();
      this.selectedProductCategoryKeys = new Set();
      this.initialSelectedProductKeys = selectedProductKeys;
      this.selectedProductKeys = [];
      this.deselectedProductKeys = [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .ag-cell.ag-cell-not-inline-editing.ag-cell-value.ag-cell-focus.ag-cell-range-single-cell {
    padding-left: 9px !important;
  }
  .checkbox-icon {
    font-size: 1.7rem !important;
    color: $assortment-menu-border-colour;
  }
  .ag-row-hover {
    .checkbox-icon {
      &:hover {
        color: $assortment-cann-group-row-colour !important;
      }
    }
  }
}
.grid-size {
  overflow: hidden;
}

.v-input--selection-controls .v-input__control {
  .v-messages {
    display: none;
  }
}

.status-container {
  padding-right: 22px;
  #totalSelected {
    font-weight: bold;
  }
}
</style>
