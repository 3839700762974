<template>
  <workflow-step :step="step" :tab-data="templateTabs" :show-statuses="false" />
</template>

<script>
import toolbarTab from '@/js/utils/tabs/template-toolbar-tabs-enum';
import templateTabs from '@/js/utils/tabs/template-tabs-enum';

export default {
  data() {
    return {
      step: toolbarTab.SCOPE_TAB,
      templateTabs,
    };
  },
};
</script>

<style lang="scss"></style>
