import Vue from 'vue';
import { cloneDeep, forEach, get, isEmpty, merge, omit, findIndex, map } from 'lodash';

function getFirstError(header, row, newValue) {
  const allRulesInHeader = {
    valueRules: get(header, 'rules', []),
    serverRules: get(header, 'serverRules', []),
  };
  // only compute one error at a time as the user can only see one
  let firstError = null;
  forEach(allRulesInHeader, (rules, ruleType) => {
    forEach(rules, rule => {
      const params = ruleType === 'valueRules' ? newValue : { ...row, [header.value]: newValue };
      const isValidOrErrorMessage = rule(params);
      if (isValidOrErrorMessage !== true) {
        firstError = isValidOrErrorMessage;
        // no need to continue loop, so stop on first error
        return false;
      }
    });
  });

  return firstError;
}

/*
 * The idea behind this utility file is to create a generic store that can be used in any store module that uses editable tables on the FE component.
 * This "module" will then enhance existing stores to handle updates on cell contents, run local validations, etc.
 * Given that a FE table needs to fetch/update/discard based on a store data, the component should be using directly the content from the getter `getTableRows` to build the tablee.
 * To make it work as expected, the method `initialiseEditableTable` should be called from the Vue component before rendering.
 *
 * For details on integration on:
 *  - Vue component: see `furniture-editor.vue`,
 *  - Store module: see `store/modules/furniture.js`
 * */

const editableTableStore = {
  state: {
    /* represents a getter from the original store to retrieve the original data.
    This is required to compare if any changes were made.
    */
    localDataGetterName: '',
    /* Represents the changed value per cell. See setCellChanges for more. It would append the updates here as:
    localDataChangesPerCell: {
      // index of data in table, it will probably be replaced to use dynamic ids depending on the outcome of AOV3-632/661
      0: {
        [propertyThatChanged]: [newPropertyValue],
        size: 1000.7,
      },
    }
    */
    localDataChangesPerCell: {},
    // Represents the data that will feed the table inside the Vue template
    localTableRowContents: [],
    /* Similarly to the changes object above, but the value for each entry is an message that will feed the triangle error component.
      0: {
        [propertyThatChanged]: "Value should be alphanumeric",
        size: "Size must be a number",
      },
    */
    localInvalidErrors: {},
  },
  mutations: {
    setNameOfLocalGetter(state, path) {
      state.localDataGetterName = path;
    },
    setTableRows(state, newTableContents) {
      state.localTableRowContents = newTableContents;
    },
    setInputValue(state, { row, newValue, header, rowIndex }) {
      const updatedRow = { ...row, [header.value]: newValue };
      Vue.set(state.localTableRowContents, rowIndex, updatedRow);
    },
    setInputError(state, { header, error, rowIndex }) {
      /*
       * Compute or remove errors in cells.
       * A) If an error is passed to this mutation, then we append to the existing row entry for errors
       * B) If no error was passed, we remove this error from the existing row entry.
       * C) If after the error is removed from the row, there is no error for the current row, then remove any reference from this row.
       *
       * */
      const existingErrorsForRow = state.localInvalidErrors[rowIndex] || {};

      // Step A.
      if (error !== null) {
        existingErrorsForRow[header.value] = error;
        Vue.set(state.localInvalidErrors, rowIndex, existingErrorsForRow);
      } else {
        const otherErrorsInRow = omit(existingErrorsForRow, header.value);
        // Step C.
        if (isEmpty(otherErrorsInRow)) {
          Vue.delete(state.localInvalidErrors, rowIndex);
        } else {
          // Step B.
          Vue.set(state.localInvalidErrors, rowIndex, otherErrorsInRow);
        }
      }
    },
    setCellChanges(state, { rowIndex, newValue, header, originalData }) {
      /*
       * 1. First, we check if the new changes are the same as the original change,
       *   - 1A. if they are, then we unmark this cell as changed
       *   - 1B. if they are not, then we compute this cell as changed
       */
      const localChanges = state.localDataChangesPerCell[rowIndex] || {};
      const originalValue = get(originalData, `[${rowIndex}].[${header.value}]`);
      // Step 1.
      let updatedRowChanges = null;
      if (originalValue === newValue) {
        // Step 1A
        updatedRowChanges = omit(localChanges, header.value);
      } else {
        // Step 1B
        updatedRowChanges = merge({}, localChanges, { [header.value]: newValue });
      }
      if (isEmpty(updatedRowChanges)) {
        Vue.delete(state.localDataChangesPerCell, rowIndex);
      } else {
        Vue.set(state.localDataChangesPerCell, rowIndex, updatedRowChanges);
      }
    },
    /*
     * Resets the current state to its default
     * This has to be called on the `beforeRouteLeave` lifecycle hook
     * */
    unloadData(state) {
      state.localDataGetterName = '';
      state.localDataChangesPerCell = {};
      state.localTableRowContents = [];
      state.localInvalidErrors = {};
    },
    markAllRowsAsChanged(state) {
      // For each row, we compute the new change as changed
      const allRowsUpdated = state.localTableRowContents.reduce(
        (changes, item, index) => ({ ...changes, [index]: { ...item } }),
        {}
      );
      state.localDataChangesPerCell = allRowsUpdated;
      state.localInvalidErrors = {}; // will be populated when page re-renders
    },
  },
  getters: {
    getTableRows(state) {
      return map(state.localTableRowContents, (el, idx) => ({ ...el, index: idx }));
    },
    hasInvalidValuesInAnyCell(state) {
      return !isEmpty(state.localInvalidErrors);
    },
    userMadeChangesToAnyCell(state) {
      return !isEmpty(state.localDataChangesPerCell);
    },
    hasChangesInCell: (state, getters) => (header, rowIndex) => {
      return getters.hasDataIn('localDataChangesPerCell', header, rowIndex);
    },
    hasInvalidDataInCell: (state, getters) => (header, rowIndex) => {
      return getters.hasDataIn('localInvalidErrors', header, rowIndex);
    },
    hasDataIn: state => (field, header, rowIndex) => {
      const rowChanges = state[field][rowIndex];
      if (!rowChanges) return false;

      return !!rowChanges[header.value];
    },
  },
  actions: {
    initialiseEditableTable({ commit, getters }, getterNameOfOriginalData) {
      commit('unloadData');
      commit('setNameOfLocalGetter', getterNameOfOriginalData);
      commit('setTableRows', cloneDeep(getters[getterNameOfOriginalData]));
    },
    onValueChanged({ commit, state, getters }, { header, row, value, index }) {
      // TODO: use ids on AOV3-632/661
      const rowIndex = row._id ? findIndex(state.localTableRowContents, { _id: row._id }) : index; // for uploads without ID.
      const update = { row, newValue: value, header, rowIndex };
      const firstError = getFirstError(header, row, value);

      commit('setInputError', { ...update, error: firstError });
      commit('setInputValue', update);
      commit('setCellChanges', { ...update, originalData: getters[state.localDataGetterName] });
    },
    setUploadedData({ commit }, dataUploaded) {
      commit('setTableRows', dataUploaded);
      commit('markAllRowsAsChanged');
    },
  },
};

export default editableTableStore;
