import axios from 'axios';
import to from 'await-to-js';
import { filter } from 'lodash';

const store = {
  namespaced: true,

  state: {
    latestSnapshotDate: null,
    snapshotDates: [],
  },
  mutations: {
    setLatestSnapshotDate(state, latestSnapshotDate) {
      state.latestSnapshotDate = latestSnapshotDate;
    },
    setSnapshotDates(state, snapshotDates) {
      state.snapshotDates = snapshotDates.map(d => d.snapshotDate);
    },
  },
  actions: {
    async fetchSnapshotDates({ commit }) {
      const [err, res] = await to(axios.get('/api/snapshot-dates'));
      if (err) throw new Error(err.message);
      // Set the latest date to be the last actual date, not the future one
      const actualDates = filter(res.data, sd => !sd.isFuture);

      commit('setLatestSnapshotDate', actualDates[actualDates.length - 1].snapshotDate);
      commit('setSnapshotDates', res.data); // Put both actual and future dates there
    },
  },
};

export default store;
