import { render, staticRenderFns } from "./cdt-attribute-selection.vue?vue&type=template&id=08d57879&scoped=true"
import script from "./cdt-attribute-selection.vue?vue&type=script&lang=js"
export * from "./cdt-attribute-selection.vue?vue&type=script&lang=js"
import style0 from "./cdt-attribute-selection.vue?vue&type=style&index=0&id=08d57879&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08d57879",
  null
  
)

export default component.exports