<template>
  <div>
    <div v-if="rowData" class="customer-segmentation__container">
      <div v-for="(metric, key) in productMetricCategories" class="striped-table--read-only">
        <div class="striped-table__row striped-table__header">
          <div
            v-for="column in tableColumns[key]"
            :key="column.key"
            class="striped-table__col"
            :class="column.headerClass || ''"
          >
            {{ column.headerName }}
          </div>
        </div>
        <div v-for="row in rowData[key]" class="striped-table__row">
          <div
            v-for="column in tableColumns[key]"
            class="striped-table__col"
            :class="column.classes || ''"
          >
            {{ valueFormatter(row[column.key], column.format, column.suffix) }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="my-3 text-center">
      <p class="ma-0">{{ $t('errors.noDataAvailable') }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { reduce, keys, map, each, concat, lowerFirst, keyBy, get, size } from 'lodash';
import {
  metricTypes,
  metricCategories,
  metricGroups,
  productMetrics,
} from '@enums/product-metrics';

const metrics = productMetrics[metricTypes.customerSegmentation];
const productMetricGroups = metricGroups[metricTypes.customerSegmentation];

export default {
  localizationKey:
    'assortmentCanvasPage.productDashboard.relativeProductPerformance.tabs.customerSegmentation',

  props: {
    productKey: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      rowData: null,
    };
  },

  computed: {
    ...mapGetters('context', ['getCurrentNumericLocale']),

    productMetricCategories() {
      return reduce(
        metricCategories[metricTypes.customerSegmentation],
        (acc, category, key) => {
          return {
            ...acc,
            [category]: {
              headerName: this.$tkey(`columns.${key}`),
              key: 'metricDescription',
              classes: 'font-weight-bold',
            },
          };
        },
        {}
      );
    },

    metricColumns() {
      return [
        {
          headerName: this.$tkey(`columns.${metrics.sales}`, { currency: this.requiredCurrency }),
          headerClass: 'text-center',
          key: metrics.sales,
          classes: 'text-center',
          format: 'integer',
          suffix: this.$t('abbreviations.thousand'),
        },
        {
          headerName: this.$tkey(`columns.${metrics.share}`),
          headerClass: 'text-center',
          key: metrics.share,
          classes: 'text-center',
          format: 'percent',
          suffix: '%',
        },
        {
          headerName: this.$tkey(`columns.${metrics.rank}`),
          headerClass: 'text-center',
          key: metrics.rank,
          classes: 'text-center',
          additionalMetrics: [metrics.ofRank],
          separator: '/',
        },
      ];
    },

    tableColumns() {
      return reduce(
        keys(this.productMetricCategories),
        (acc, category) => {
          return {
            ...acc,
            [category]: [this.productMetricCategories[category], ...this.metricColumns],
          };
        },
        {}
      );
    },

    requiredCurrency() {
      return this.$t(`currencies.${this.getCurrentNumericLocale}`);
    },
  },

  watch: {
    productKey: {
      handler(newProductKey, oldProductKey) {
        if (!newProductKey || newProductKey === oldProductKey) return;
        this.init(newProductKey);
      },
    },
  },

  async created() {
    await this.init();
  },

  methods: {
    ...mapActions('toolData', ['fetchRelativeProductPerformanceMetrics']),

    async init() {
      const customerSegmentationData = await this.fetchRelativeProductPerformanceMetrics({
        productKey: this.productKey,
        metricType: metricTypes.customerSegmentation,
      });
      this.rowData = size(customerSegmentationData)
        ? this.generateRows(customerSegmentationData)
        : null;
    },

    generateRows(data) {
      return reduce(
        data,
        (rows, category) => {
          const metricsByGroup = keyBy(category.categoryMetrics, 'metricGroup');
          return {
            ...rows,
            // Create rows for all metric groups in each category.
            // Loop through enum to allow more control over row ordering.
            [category.categoryName]: map(productMetricGroups[category.categoryName], group => {
              const groupMetrics = get(metricsByGroup, `${group}.metrics`, {});
              const metricData = reduce(
                this.metricColumns,
                (acc, column) => {
                  let metricValue = groupMetrics[column.key];
                  // If additional keys have been defined, concatenate values
                  each(column.additionalMetrics || [], k => {
                    metricValue = concat(metricValue, column.separator, groupMetrics[k]).join(' ');
                  });
                  return { ...acc, [column.key]: metricValue };
                },
                {}
              );
              return {
                metricDescription: this.$t(`customerSegmentation.${lowerFirst(group)}`),
                ...metricData,
              };
            }),
          };
        },
        {}
      );
    },

    valueFormatter(value, format, suffix = '') {
      if (!value) value = 0;
      if (!format) return value;

      const isPercent = format === 'percent';
      const formattedValue = this.formatNumber({
        number: isPercent ? value * 100 : value,
        format,
      });
      return `${formattedValue}${suffix}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-segmentation {
  &__container {
    display: flex;
    justify-content: space-between;
  }
}

.striped-table--read-only {
  .striped-table__col:first-child {
    flex: 30% 0 0;
  }
}
</style>
