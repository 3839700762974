import { VFadeTransition } from 'vuetify/lib/components/transitions';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex justify-center w-100",
      class: {
        relative: _vm.dashboard,
        "product-image--text-wrapper":
          _vm.imageTag === "span" && !_vm.dashboard,
        "product-image--text-wrapper--large":
          _vm.imageTag === "span" && !_vm.dashboard && _vm.isLargeTile,
      },
      style: _vm.getWrapperStyle,
    },
    [
      _c(
        _vm.imageTag,
        _vm._b(
          {
            tag: "component",
            staticClass: "fit-image d-flex justify-center align-center",
            class: { "longer-description": _vm.isShownOnProductDashboard },
            style: _vm.size,
            attrs: {
              loading: "lazy",
              src: _vm.src,
              alt: _vm.alt,
              title: _vm.tooltipText,
            },
            on: {
              error: function ($event) {
                _vm.imageTag = "span"
              },
            },
          },
          "component",
          _vm.$attrs,
          false
        ),
        [_vm._v("\n    " + _vm._s(_vm.alt) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.dashboard
        ? _c(
            "div",
            { staticClass: "d-flex w-100 product-tags" },
            [
              _c("div", { staticClass: "w-100" }),
              _vm._v(" "),
              _c("assortment-tag", { attrs: { "tag-text": _vm.tagText } }),
              _vm._v(" "),
              _vm.showNotInResetIcon
                ? _c(
                    "div",
                    { staticClass: "product-icon" },
                    [
                      _c("not-in-reset-icon", {
                        staticClass: "not-in-reset-tag",
                        attrs: { color: "white" },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "w-100" }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLargeTile && _vm.imageTag === "img" && !_vm.dashboard
        ? _c(
            "div",
            {
              staticClass:
                "d-flex w-100 product-image--text-wrapper product-tile-header product-image--text-wrapper--large",
              style: {
                width: _vm.width,
              },
            },
            [
              _c(VFadeTransition, { attrs: { "hide-on-leave": "" } }, [
                _c(
                  "span",
                  { staticClass: "fit-image product-description w-100" },
                  [_vm._v("\n        " + _vm._s(_vm.alt) + "\n      ")]
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }