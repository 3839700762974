var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: `comparisonwaterfall-${_vm.isSidebarShown}`,
      staticClass: "h-100 d-flex flex-column",
    },
    [
      _c("reporting-section", {
        attrs: { "short-view": _vm.shortView },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("reporting-header", {
                  attrs: {
                    "short-view": _vm.shortView,
                    section: _vm.section,
                    title: _vm.$tkey("title"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "metric",
                      fn: function () {
                        return [
                          _c("rtls-select", {
                            staticClass: "mr-3",
                            attrs: {
                              value: _vm.metricValue,
                              items: _vm.allowedMetrics,
                              placeholder: _vm.$t("general.select"),
                              disabled: _vm.isComparisonWaterfallReportDisabled,
                              "item-text": "text",
                              "item-value": "type",
                              white: "",
                              width: "240px",
                            },
                            on: {
                              input: (value) =>
                                _vm.onSelectionChanged("metricValue", value),
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "filter",
                      fn: function () {
                        return [
                          _c("filters", {
                            staticClass: "ml-3",
                            attrs: {
                              filters: _vm.filters[_vm.section],
                              "filter-options": _vm.filterOptions,
                              disabled:
                                _vm.isComparisonWaterfallReportDisabled ||
                                _vm.filtersDisabled,
                              "btn-text": _vm.filterButtonText,
                            },
                            on: {
                              change: _vm.handleFilterChange,
                              apply: _vm.fetchData,
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "main-section",
            fn: function () {
              return [
                _c("reporting-main-section", {
                  attrs: {
                    section: _vm.section,
                    "short-view": _vm.shortView,
                    subtitle: _vm.$tkey("subtitle"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "data-section",
                      fn: function () {
                        return [
                          _vm.isComparisonWaterfallReportDisabled
                            ? _c("span", { staticClass: "not-available" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("reportingPage.noPermissions")
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _vm.isLoading || _vm.isProcessingData
                            ? _c("progress-bar")
                            : _vm.hasInvalidComparison
                            ? _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$tkey("invalidComparison")) +
                                    "\n          "
                                ),
                              ])
                            : _vm.hasInvalidComparisonSelections
                            ? _c("span", { staticClass: "not-available" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "reportingPage.notAvailableForComparisons"
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _vm.hasInvalidMetric
                            ? _c("span", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$tkey("invalidMetric")) +
                                    "\n          "
                                ),
                              ])
                            : [
                                _c("highcharts", {
                                  staticClass: "chart-container",
                                  style: {
                                    width: _vm.shortView ? "100%" : "50%",
                                  },
                                  attrs: { options: _vm.chartOptions },
                                }),
                                _vm._v(" "),
                                !_vm.shortView
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ag-grid-box d-flex mx-5 pl-2 h-100 rtls-border rtls-border--left",
                                      },
                                      [
                                        _c("ag-grid-vue", {
                                          staticClass: "ag-theme-custom w-100",
                                          attrs: {
                                            "animate-rows": "",
                                            dense: "",
                                            "column-defs": _vm.headers,
                                            "row-data": _vm.tableData,
                                            "grid-options": _vm.gridOptions,
                                          },
                                          on: { "grid-ready": _vm.onGridReady },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("reporting-footer", {
                  attrs: {
                    "short-view": _vm.shortView,
                    "is-export-disabled": !_vm.hasAvailableChartData,
                  },
                  on: { export: _vm.exportCSV },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }