import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-id-e2e": "activeBundles" } },
    [
      _c("workpackages-toolbar", {
        attrs: { "doc-path": "190-bundles", entity: _vm.entities.bundle },
        on: { "on-search": _vm.onSearch, "on-sort": _vm.onSort },
      }),
      _vm._v(" "),
      _c(
        VCard,
        { staticClass: "col-box" },
        [
          _vm.loading
            ? _c(
                VAlert,
                {
                  attrs: {
                    border: "left",
                    "colored-border": "",
                    color: "primary accent-4",
                  },
                },
                [
                  _c(VProgressCircular, {
                    attrs: { indeterminate: "", color: "primary", size: "20" },
                  }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$tkey("loadingActiveBundles")) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            VRow,
            { attrs: { "no-gutters": "" } },
            [
              _c(
                VCol,
                { staticClass: "bundles__container" },
                [
                  _vm.sortedBundlesList.length
                    ? _c("workpackages-list", {
                        attrs: {
                          entity: _vm.entities.bundle,
                          "visible-workpackages": _vm.visibleBundles,
                          workpackages: _vm.sortedBundlesList,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.loading && !_vm.visibleBundles.length
                    ? _c(VAlert, { attrs: { type: "info", text: "" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tkey("noActiveBundles")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }