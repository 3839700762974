import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        "div",
        { staticClass: "assortment-table d-flex flex-column" },
        [
          _c(
            VContainer,
            { staticClass: "actions-container pa-0 ma-0 flex-grow-0" },
            [
              _c(
                VRow,
                { staticClass: "actions-col" },
                [
                  _c(
                    VCol,
                    { staticClass: "d-flex align-center flex-grow-1" },
                    [
                      _c(
                        "span",
                        { staticClass: "mr-3 font-weight-bold" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tkey("setList")) +
                              "\n\n            "
                          ),
                          _vm._v(" "),
                          _c("docs-link", {
                            attrs: { link: "toolguide/04a-storeclasses.html" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.showNotImplemented
                        ? _c(
                            VBtn,
                            {
                              staticClass: "button-space-5",
                              attrs: {
                                "data-id-e2e": "btnStoreClassImport",
                                primary: "",
                                disabled: "",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("actions.manualImport")) +
                                  "\n            "
                              ),
                              _c(VIcon, { attrs: { right: "" } }, [
                                _vm._v("$import"),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("filters", {
                        attrs: {
                          filters: _vm.filters,
                          "filter-options": _vm.filterOptions,
                          "btn-text": _vm.filterButtonText,
                        },
                        on: {
                          change: _vm.handleFilterChange,
                          apply: _vm.filterFurnitureWithStoreClass,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showErrorControls
                    ? _c(
                        VCol,
                        {
                          staticClass: "d-flex align-center col flex-grow-2",
                          attrs: { id: "error-controls" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "invalid-rows-error-box pr-2" },
                            [_vm._v(_vm._s(_vm.invalidRowsErrorMessage))]
                          ),
                          _vm._v(" "),
                          !_vm.filterInvalidRows
                            ? _c(
                                VBtn,
                                {
                                  attrs: {
                                    outlined: "",
                                    depressed: "",
                                    color: "error",
                                  },
                                  on: { click: _vm.toggleInvalidRows },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t("actions.showInvalidRows")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _c(
                                VBtn,
                                {
                                  attrs: {
                                    outlined: "",
                                    depressed: "",
                                    color: "primary",
                                  },
                                  on: { click: _vm.toggleInvalidRows },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$t("actions.showAllRows")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VContainer,
            {
              staticClass:
                "actions-container dynamic-height-container storeclass-container d-flex flex-column pl-0 pr-0 pb-0 flex-grow-1",
            },
            [
              _c(
                VRow,
                {
                  staticClass: "mt-2 mb-1 flex-grow-0",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(
                    VCol,
                    { attrs: { cols: "2" } },
                    [
                      _c(VCardText, { staticClass: "list-title" }, [
                        _c("div", [
                          _vm._v(_vm._s(_vm.$tkey("storeClassList"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "storeclass-list-container" },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "pt-0 storeclass-list-column",
                      attrs: { cols: "2" },
                    },
                    [
                      _c(VDivider, {
                        staticClass: "storeclass-border mb-2",
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "padded-column" },
                        [
                          _c(
                            "div",
                            { staticClass: "storeclass-list-scrollable" },
                            [
                              _c(
                                VForm,
                                {
                                  ref: "storeClassNamesForm",
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                  model: {
                                    value: _vm.valid,
                                    callback: function ($$v) {
                                      _vm.valid = $$v
                                    },
                                    expression: "valid",
                                  },
                                },
                                _vm._l(_vm.storeClasses, function (storeClass) {
                                  return _c(
                                    VCard,
                                    {
                                      key: storeClass._id,
                                      staticClass: "mb-1 storeclass-card",
                                      attrs: { flat: "", primary: "" },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        { staticClass: "align-items-center" },
                                        [
                                          _c(
                                            VCol,
                                            { staticClass: "storeclass-col" },
                                            [
                                              _c("rtls-text-field", {
                                                key:
                                                  _vm.nameKey + storeClass._id,
                                                staticClass:
                                                  "storeclass-text-field",
                                                attrs: {
                                                  rules:
                                                    _vm.storeClassInputRules,
                                                  "hide-details": false,
                                                  disabled: _vm.editingDisabled,
                                                  "run-validations-on-creation":
                                                    "",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    ;[
                                                      _vm.revalidateForm(),
                                                      _vm.redrawRows(),
                                                    ]
                                                  },
                                                },
                                                model: {
                                                  value: storeClass.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      storeClass,
                                                      "name",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "storeClass.name",
                                                },
                                              }),
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(/* have to call redrawRows for all ag-grids so storeclass names stay in sync. */) +
                                                  "\n                      " +
                                                  _vm._s(/* note: this is not performant. AOV3-754 TODO: only refresh tables when storeclass has furniture. only refresh related rows. */) +
                                                  "\n                    "
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            VCol,
                                            {
                                              staticClass:
                                                "mx-0 my-auto pa-0 d-flex justify-end",
                                              attrs: { cols: "2" },
                                            },
                                            [
                                              _c(
                                                VIcon,
                                                {
                                                  staticClass:
                                                    "small-icon mr-1",
                                                  attrs: {
                                                    disabled:
                                                      _vm.editingDisabled,
                                                    size: "19",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeStoreClass(
                                                        storeClass._id
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        $trash\n                      "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VBtn,
                            {
                              staticClass: "ml-2 mb-2 mt-2 storeclass-btn",
                              attrs: {
                                disabled: _vm.editingDisabled,
                                "data-id-e2e": "btnCreateStoreClass",
                                primary: "",
                                depressed: "",
                              },
                              on: { click: () => _vm.addNewStoreClass(false) },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$tkey("newStoreClass")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(VDivider, { staticClass: "storeclass-border" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "padded-column" },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass:
                                "ml-2 mb-2 mt-2 single-storeclass-btn storeclass-btn outlined-btn",
                              attrs: {
                                disabled: _vm.editingDisabled,
                                depressed: "",
                                outlined: "",
                                secondary: "",
                              },
                              on: { click: _vm.onSingleStoreClassClick },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$tkey("singleStoreClass")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCol,
                    {
                      staticClass: "furniture-col-scrollable pt-0 pl-0",
                      attrs: { cols: "10" },
                    },
                    [
                      _c(
                        VCol,
                        { staticClass: "pl-2 pt-2", attrs: { cols: "7" } },
                        [
                          _vm.filters.length &&
                          !_vm.filteredFurnitureCategorySets.length
                            ? _c("h3", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t("errors.noDataAvailableForFilters")
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(
                            _vm.filteredFurnitureCategorySets,
                            function (furnitureSet, index) {
                              return _c(
                                "div",
                                {
                                  key: `${furnitureSet + index}-container`,
                                  staticClass:
                                    "d-flex flex-column grid-container storeclass-table",
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%",
                                  },
                                },
                                [
                                  _c("b", { staticClass: "section" }, [
                                    _vm._v(_vm._s(furnitureSet)),
                                  ]),
                                  _vm._v(" "),
                                  _c("ag-grid-vue", {
                                    staticClass: "ag-theme-custom",
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: {
                                      id: `ag-grid-${furnitureSet}`,
                                      "dom-layout": "autoHeight",
                                      "column-defs": _vm.columnDefs,
                                      "row-data":
                                        _vm.groupedFurniture[furnitureSet],
                                      "grid-options":
                                        _vm.gridOptions[furnitureSet],
                                      "enable-range-selection": true,
                                      "does-external-filter-pass":
                                        _vm.doesExternalFilterPass,
                                    },
                                    on: {
                                      "cell-value-changed":
                                        _vm.onSelectionChange,
                                      "grid-ready": function ($event) {
                                        return _vm.onGridReady(
                                          $event,
                                          furnitureSet
                                        )
                                      },
                                      "row-clicked": function ($event) {
                                        return _vm.modifySelectPosition(
                                          `#ag-grid-${furnitureSet}`
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("page-actions", {
            attrs: {
              "show-export": _vm.showNotImplemented,
              "has-data-changes": _vm.hasDataChanges,
              "has-data-errors": !_vm.valid,
              "save-disabled": _vm.editingDisabled,
            },
            on: { discard: _vm.discardChanges, save: _vm.saveChanges },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "confirmDeleteDialog",
        attrs: {
          message: _vm.$tkey(
            _vm.deleteAllStoreClasses
              ? "removeAllWarning"
              : "changingStoreClassesWarning"
          ),
          "show-confirm-message": "",
        },
        on: { cancel: _vm.closeConfirmDialog },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function ({ cancel: close }) {
              return [
                _c(
                  VRow,
                  { staticClass: "mt-2" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "mx-auto",
                        attrs: { primary: "" },
                        on: {
                          click: function ($event) {
                            ;[_vm.onDeleteConfirm(), close()]
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.deleteAllStoreClasses
                                ? _vm.$t("general.yes")
                                : _vm.$tkey("removeStoreClass")
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  VRow,
                  { staticClass: "my-1" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "mx-auto",
                        attrs: { text: "" },
                        on: { click: close },
                      },
                      [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("dependency-tree-feedback-modal", {
        attrs: {
          value: _vm.dependencyTreeModalOpen,
          results: _vm.dependencyTreeFeedback,
          page: "storeclassDefinition",
        },
        on: {
          close: _vm.closeDependencyTreeModal,
          commit: function ($event) {
            return _vm.saveChanges(true)
          },
        },
      }),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.closeUnsavedDataModal,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }