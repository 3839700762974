var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "master-container" }, [
    _c("div", { staticClass: "header-container mt-2 mb-5" }, [
      _c("span", { staticClass: "header-text" }, [
        _vm._v(
          _vm._s(
            _vm.$tkey("header", [_vm.getDashboardProduct.productKeyDisplay])
          )
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex top-container" },
      [
        _vm._l(_vm.topSection.top, function (product, index) {
          return _c("transferred-spend-box", {
            key: index,
            attrs: {
              product: product,
              "image-url": _vm.getImageUrl(product),
              "image-alt": _vm.getDisplayText(product.productKeyDisplay),
              "image-title": _vm.getDisplayText(product.productKeyDisplay),
              "conditional-product-tile-style": _vm.getStyle("default"),
              "show-values": true,
              amount: product.amount,
              "amount-percentage": product.percentage,
            },
          })
        }),
        _vm._v(" "),
        _c("transferred-spend-box", {
          attrs: {
            product: { sales: 0, totalAmount: 0 },
            "conditional-product-tile-style": _vm.getStyle("default"),
            "show-others": true,
            "from-others-count": _vm.fromOthersCount,
            amount: _vm.fromOthersAmount,
            "amount-percentage": _vm.fromOthersPercentage,
          },
        }),
        _vm._v(" "),
        _c("transferred-spend-box", {
          attrs: {
            product: { sales: 0, totalAmount: 0 },
            "conditional-product-tile-style": _vm.getStyle("default"),
            amount: _vm.fromSalesLostAmount,
            "amount-percentage": _vm.fromSalesLostPercentage,
          },
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex mid-container flex-column" }, [
      _c(
        "div",
        { staticClass: "flex-column pt-5" },
        [
          _c(
            "assortment-image",
            _vm._b(
              {
                staticClass: "pb-1",
                attrs: {
                  dashboard: "",
                  src: _vm.getImageUrl(_vm.getDashboardProduct),
                  alt: _vm.getDisplayText(
                    _vm.getDashboardProduct.productKeyDisplay
                  ),
                  title: _vm.getDisplayText(
                    _vm.getDashboardProduct.productKeyDisplay
                  ),
                  "tooltip-text": _vm.getTooltipText(_vm.getDashboardProduct),
                },
              },
              "assortment-image",
              _vm.getStyle(),
              false
            )
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "flex-column stats-item" }, [
        _c("span", { staticClass: "value" }, [
          _c("b", [
            _vm._v(
              _vm._s(
                _vm.formatNumber({
                  number: _vm.getDashboardProduct.sales,
                  format: "currency",
                })
              )
            ),
          ]),
          _vm._v("\n        " + _vm._s(_vm.$tkey("sales")) + "\n      "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-column stats-item" }, [
        _c("span", { staticClass: "value" }, [
          _c("b", [
            _vm._v(
              _vm._s(
                _vm.formatNumber({
                  number: _vm.productInQuestionTotalSales,
                  format: "currency",
                })
              )
            ),
          ]),
          _vm._v(
            "\n        " + _vm._s(_vm.$tkey("inclTransSpend")) + "\n      "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex top-container" },
      [
        _vm._l(_vm.botSection.top, function (product, index) {
          return _c("transferred-spend-box", {
            key: index,
            attrs: {
              product: product,
              "image-url": _vm.getImageUrl(product),
              "image-alt": _vm.getDisplayText(product.productKeyDisplay),
              "image-title": _vm.getDisplayText(product.productKeyDisplay),
              "conditional-product-tile-style": _vm.getStyle("default"),
              "show-values": true,
              amount: product.amount,
              "amount-percentage": product.percentage,
              "is-top-section": false,
            },
          })
        }),
        _vm._v(" "),
        _c("transferred-spend-box", {
          attrs: {
            product: { sales: 0, totalAmount: 0 },
            "conditional-product-tile-style": _vm.getStyle("default"),
            "show-others": true,
            "from-others-count": _vm.toOthersCount,
            amount: _vm.toOthersAmount,
            "amount-percentage": _vm.toOthersPercentage,
            "is-top-section": false,
          },
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "header-container mt-3 mb-4" }, [
      _c("span", { staticClass: "header-text" }, [
        _vm._v(
          _vm._s(
            _vm.$tkey("footer", [_vm.getDashboardProduct.productKeyDisplay])
          )
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }