import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: { value: _vm.value, width: "400px" },
      on: { "click:outside": _vm.closeModal },
    },
    [
      _c(
        "dialog-card",
        {
          attrs: { title: _vm.$tkey("modalTitle") },
          on: { close: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c("page-actions", {
                    attrs: {
                      "has-data-errors": _vm.hasDataErrors,
                      "has-data-changes": true,
                      "show-discard": false,
                      "save-btn-text": "optimiseSwaps",
                    },
                    on: { save: _vm.runOptimiseSwaps },
                    scopedSlots: _vm._u([
                      {
                        key: "right-btns",
                        fn: function () {
                          return [
                            _c(
                              VRow,
                              {
                                staticClass:
                                  "actions-row justify-space-between flex-grow-1",
                              },
                              [
                                _c(
                                  VBtn,
                                  {
                                    staticClass: "text-outline",
                                    attrs: { text: "", link: "" },
                                    on: { click: _vm.closeModal },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("actions.cancel")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VContainer,
            { staticClass: "swap-parameters__container", attrs: { fluid: "" } },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { staticClass: "pa-0" },
                    [
                      _c(
                        VForm,
                        {
                          ref: "swapParametersForm",
                          attrs: { autocomplete: "off" },
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c(
                            VRow,
                            { staticClass: "mt-5 mb-3" },
                            [
                              _c(
                                VCol,
                                { staticClass: "pa-0" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "swap-parameters__label d-block",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tkey("form.runDescription")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("rtls-text-field", {
                                    staticClass: "swap-parameters__input",
                                    attrs: {
                                      rules: _vm.runDescriptionRules,
                                      grey: "",
                                      "single-line": "",
                                      "data-id-e2e": "swapDescriptionInput",
                                    },
                                    model: {
                                      value: _vm.runDescription,
                                      callback: function ($$v) {
                                        _vm.runDescription = $$v
                                      },
                                      expression: "runDescription",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VRow,
                            { staticClass: "border-bottom pb-4 mb-3" },
                            [
                              _c(
                                VCol,
                                { staticClass: "pa-0", attrs: { cols: "4" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "swap-parameters__label d-block",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tkey("form.maxNumberOfSwaps")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("rtls-text-field", {
                                    staticClass:
                                      "swap-parameters__input swap-parameters__input--short",
                                    attrs: {
                                      rules: _vm.greaterThanZeroRules,
                                      grey: "",
                                      "single-line": "",
                                      "run-validations-on-creation": "",
                                      "data-id-e2e": "swapMaxNoOfSwapsInput",
                                    },
                                    model: {
                                      value: _vm.maxSwaps,
                                      callback: function ($$v) {
                                        _vm.maxSwaps = _vm._n($$v)
                                      },
                                      expression: "maxSwaps",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VRow,
                            { staticClass: "border-bottom pb-4 mb-4" },
                            [
                              _c(
                                VCol,
                                {
                                  staticClass: "pa-0 pr-5",
                                  attrs: { cols: "7" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "swap-parameters__label d-block",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tkey("form.forecastPeriod")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("rtls-calendar-picker", {
                                    attrs: {
                                      "allowed-dates": _vm.allowedStartDates,
                                      "display-date-format":
                                        _vm.getDateFormats.long,
                                      "data-id-e2e":
                                        "swapForecasterStartDateSelect",
                                    },
                                    model: {
                                      value: _vm.weekStartDate,
                                      callback: function ($$v) {
                                        _vm.weekStartDate = $$v
                                      },
                                      expression: "weekStartDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                VCol,
                                { staticClass: "pa-0", attrs: { cols: "5" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "swap-parameters__label d-block",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tkey("form.numberOfSlices")
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("rtls-text-field", {
                                    staticClass:
                                      "swap-parameters__input swap-parameters__input--short",
                                    attrs: {
                                      rules: _vm.greaterThanZeroRules,
                                      grey: "",
                                      "single-line": "",
                                      "data-id-e2e": "swapNumberOfSlicesInput",
                                    },
                                    model: {
                                      value: _vm.numberOfSlices,
                                      callback: function ($$v) {
                                        _vm.numberOfSlices = _vm._n($$v)
                                      },
                                      expression: "numberOfSlices",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VRow,
                            { staticClass: "border-bottom pb-4 mb-3" },
                            [
                              _c(
                                VCol,
                                { staticClass: "pa-0 d-flex align-center" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "swap-parameters__label mb-0 pr-3",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tkey(
                                              "form.forecastSliceLength"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("rtls-select", {
                                    attrs: {
                                      "hide-details": "",
                                      width: "100%",
                                      items: _vm.forecastSliceLengthsOptions,
                                      placeholder: _vm.$t("general.select"),
                                      grey: "",
                                      "data-id-e2e":
                                        "swapForecastSliceLengthSelect",
                                    },
                                    model: {
                                      value: _vm.sliceLength,
                                      callback: function ($$v) {
                                        _vm.sliceLength = $$v
                                      },
                                      expression: "sliceLength",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VRow,
                            { staticClass: "border-bottom mb-3" },
                            [
                              _c(
                                VCol,
                                {
                                  staticClass:
                                    "pa-0 d-flex align-center swap-parameters__forecast-end",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "swap-parameters__label mb-0 pr-1",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tkey("form.forecastEnd")
                                          ) +
                                          ":\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            _vm.forecastEndDate,
                                            _vm.getDateFormats.longWithTime
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("error-triangle", {
                                    staticClass: "end-date-error-triangle",
                                    attrs: { errors: _vm.errors },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VRow,
                            { staticClass: "border-bottom pb-7 mb-3" },
                            [
                              _c(
                                VCol,
                                { staticClass: "pa-0 d-flex align-center" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "swap-parameters__label mb-0 pr-3",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$tkey(
                                              "form.optimiseAccordingTo"
                                            )
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("rtls-select", {
                                    attrs: {
                                      disabled: _vm.validatingOptimiseOptions,
                                      value: _vm.optimiseAccordingTo,
                                      items: _vm.sizeSelectItems,
                                      "error-messages":
                                        _vm.optimiseAccordingToErrorMessage,
                                      placeholder: _vm.$t("sizeTypes.noOption"),
                                      "append-outer-icon":
                                        _vm.sizeTypeIconMap[
                                          _vm.optimiseAccordingTo
                                        ] || "$empty-cube",
                                      width: "250px",
                                      "data-id-e2e":
                                        "swapOptimiseAccordingToSelect",
                                    },
                                    on: { change: _vm.selectSetting },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VRow,
                            { staticClass: "border-bottom pb-4 mb-4" },
                            [
                              _c(
                                VCol,
                                {
                                  staticClass: "pa-0 pr-5",
                                  attrs: { cols: "4" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "swap-parameters__label d-block",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$tkey("form.unitVolume")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("rtls-text-field", {
                                    staticClass:
                                      "swap-parameters__input swap-parameters__input--short",
                                    attrs: {
                                      rules: _vm.rules,
                                      grey: "",
                                      "single-line": "",
                                      "data-id-e2e": "swapOptimiserVolumeInput",
                                    },
                                    model: {
                                      value: _vm.volume,
                                      callback: function ($$v) {
                                        _vm.volume = _vm._n($$v)
                                      },
                                      expression: "volume",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                VCol,
                                { staticClass: "pa-0", attrs: { cols: "4" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "swap-parameters__label d-block",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$tkey("form.sales")) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("rtls-text-field", {
                                    staticClass:
                                      "swap-parameters__input swap-parameters__input--short",
                                    attrs: {
                                      rules: _vm.rules,
                                      grey: "",
                                      "single-line": "",
                                      "data-id-e2e": "swapOptimiserSalesInput",
                                    },
                                    model: {
                                      value: _vm.sales,
                                      callback: function ($$v) {
                                        _vm.sales = _vm._n($$v)
                                      },
                                      expression: "sales",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                VCol,
                                { staticClass: "pa-0", attrs: { cols: "4" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-space-between",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "swap-parameters__label d-block",
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$tkey("form.margin")) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.usesMarginWithoutFunding
                                        ? _c("assortment-tooltip", {
                                            attrs: {
                                              title: _vm.$t("general.warning"),
                                              "tooltip-sections":
                                                _vm.margingWithoutFundingSections,
                                              width: 500,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("rtls-text-field", {
                                    staticClass:
                                      "swap-parameters__input swap-parameters__input--short",
                                    attrs: {
                                      rules: _vm.rules,
                                      grey: "",
                                      "single-line": "",
                                      "data-id-e2e": "swapOptimiserMarginInput",
                                    },
                                    model: {
                                      value: _vm.margin,
                                      callback: function ($$v) {
                                        _vm.margin = _vm._n($$v)
                                      },
                                      expression: "margin",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }