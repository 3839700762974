<template>
  <v-dialog
    v-model="isPopupOpen"
    width="367px"
    content-class="generate-cdt-modal"
    @click:outside="closeModal"
    @keydown.esc="closeModal"
  >
    {{ /* for unsaved-data-mixin to work we need the modal to handle it's own state.
    this creates some problems since we need to use the activator slot provided by vuetify from this components parent.
    however, this component is used in multiple places, so we want to pass in different buttons as the activator.
    here we bind every scoped slot passed to this component to v-dialog,
    so when we pass 'template v-slot:activator="{ on }"' to this component, the slot is passed to v-dialog */ }}
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
      <slot :name="slot" v-bind="props" />
    </template>
    <dialog-card :title="title" @close="closeModal">
      <v-container fluid style="max-height: 400px; padding: 0">
        <v-row class="d-flex flex-column">
          <v-col class="pl-0 pb-0 pr-4">
            <p class="mb-0 sub-title">{{ subtitle }} {{ selectedCannGroup.name }} ?</p>
          </v-col>
          <v-col v-if="includeOptions" class="d-flex py-0 pr-2 justify-end">
            <main-dropdown-menu
              :actions="actions"
              @selectAll="updatedSelectedCannGroups(true)"
              @unselectAll="updatedSelectedCannGroups(false)"
            />
          </v-col>
          <v-col class="pl-0 pr-0 pt-0 mt-4 pb-4 main-container">
            <v-row
              v-for="cannGroup in cannGroups"
              :key="cannGroup.key"
              class="cann-group-row d-flex justify-space-between"
            >
              <v-checkbox
                :id="cannGroup.key"
                :disabled="cannGroup.disabled || isCannGroupRunning(cannGroup.key)"
                class="mr-2"
                :input-value="cannGroup.selected"
                @change="cannGroup.selected = $event"
              />
              <span class="cann-group-name pb-0 full-row flex-1">{{ cannGroup.name }}</span>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <template v-slot:footer>
        <page-actions
          :has-data-errors="false"
          :has-data-changes="true"
          :save-btn-text="saveButtonText"
          @discard="discard"
          @save="onSave"
        >
          <template v-slot:right-btns>
            <v-btn class="text-outline" text link @click="closeModal">
              {{ $t('actions.cancel') }}
            </v-btn>
          </template>
        </page-actions>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { keyBy, map, filter, keys, get, reject, cloneDeep } from 'lodash';
import inputValidationMixin from '@/js/mixins/input-validations';
import cannGroupUtils from '@/js/utils/cann-groups';
import { StoreNamespaces } from '@/js/store/constants';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import { jobApi } from '@/js/helpers';

export default {
  mixins: [inputValidationMixin, unsavedDataWarningMixin],
  localizationKey: 'customerDecisionTree.dialog',
  props: {
    selectedCannGroup: {
      required: true,
      type: Object,
    },
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      required: true,
      type: String,
    },
    includeOptions: {
      required: false,
      type: Boolean,
      default: false,
    },
    excludeSelf: {
      required: false,
      type: Boolean,
      default: false,
    },
    saveButtonText: {
      required: false,
      type: String,
      default: 'generate',
    },
    // by default, this component performs a few checks to see if a cann group can be selected.
    enableAllOptions: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      initialCannGroups: null,
      cannGroups: null,
      isPopupOpen: false,
      actions: [
        {
          name: this.$tkey('selectAll'),
          value: 'selectAll',
        },
        {
          name: this.$tkey('unselectAll'),
          value: 'unselectAll',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(StoreNamespaces.clustering, ['getScenarioAttributes']),
    ...mapState(StoreNamespaces.scenarioCdts, ['scenarioCdts']),
    ...mapState(StoreNamespaces.scenarios, ['selectedScenario']),
    ...mapGetters(StoreNamespaces.scenarios, ['getJob']),
    runningCannGroups() {
      const job = this.getJob('cdtGeneration');
      return filter(keys(job), k => {
        const status = get(job, `${k}.status`);
        if (!status) return false;
        return jobApi.isRunningStatus(status);
      });
    },
  },
  watch: {
    selectedCannGroup: {
      immediate: true,
      handler() {
        this.cannGroups = this.getCannGroups().map(gc => {
          gc.selected = false;
          return gc;
        });
        const { key } = this.selectedCannGroup;
        const scenarioCdtsMap = keyBy(this.scenarioCdts, 'cannGroupId');
        if (!this.cannGroups) {
          return;
        }
        if (this.excludeSelf) {
          // for the copy selection, we do not show the active CDT.
          this.cannGroups = reject(this.cannGroups, { key });
        }
        if (this.enableAllOptions) {
          return;
        }
        // for each CG, check if they can be selected
        this.cannGroups = this.cannGroups.map(cg => {
          const savedDocument = scenarioCdtsMap[cg.key];
          // if the cann group has no attributes selected, it cannot have the CDT generated
          if (savedDocument && savedDocument.attributes) {
            cg.attributeSelection = savedDocument.attributes;
            cg.disabled = savedDocument.attributes.length < 1 || cg.key === key;
          } else if (!savedDocument) {
            // if the cann group was not stored into the DB, the user must first save it then generate CDTs.
            cg.disabled = cg.key === key;
          }
          cg.selected = cg.key === key;
          return cg;
        });
      },
    },
  },

  created() {
    this.initialCannGroups = cloneDeep(this.cannGroups);
  },

  methods: {
    closeModal() {
      this.isPopupOpen = false;
    },
    discard() {
      this.cannGroups = cloneDeep(this.initialCannGroups);
    },
    onSave() {
      const selectedCannGroupIds = map(this.cannGroups.filter(cg => cg.selected), 'key');
      this.$emit('confirm', { cannGroupIds: selectedCannGroupIds });
      this.discard();
      this.closeModal();
    },
    getCannGroups() {
      return cloneDeep(cannGroupUtils.getLeafNodes(this.selectedScenario.cannGroups));
    },
    isCannGroupRunning(key) {
      return this.runningCannGroups.includes(key);
    },
    updatedSelectedCannGroups(isSelected) {
      this.cannGroups = this.cannGroups.map(cg => {
        return {
          ...cg,
          selected: isSelected,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.sub-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.main-container {
  border-bottom: 1px solid $assortment-divider-colour;
  max-height: 180px;
  overflow-y: auto;
  flex-basis: auto;
}

.cann-group-row {
  border-style: solid;
  border-width: 1px;
  color: $assortment-cann-group-row-colour;
  opacity: 1;
  padding: 3px;
  align-items: center;
  flex-wrap: nowrap;

  &:nth-child(odd) {
    background-color: $assortment-cann-group-row-bg-blue;
    border-color: $assortment-cann-group-row-bg-blue;
  }
  &:nth-child(even) {
    background-color: $assortment-cann-group-row-bg-white;
    border-color: $assortment-cann-group-row-bg-white;
  }

  .cann-group-name {
    word-break: break-word;
    font-size: 1.1rem;
  }
}

.rtls-checkbox {
  ::v-deep {
    .v-input--selection-controls__input {
      margin-right: 0px !important;
    }
  }
}

::v-deep .dialog-card__footer {
  border-top-width: 0px;
}
</style>
