<template>
  <div :style="{ display: 'contents' }">
    <!-- Cann Group -->
    <div
      ref="cann-group-name"
      :style="{ 'grid-column': `span ${childColumns}`, top: 0 }"
      class="grid-item-border-bottom flexed-white-bg-grid-item header cann-group-heading-container"
      :class="cgHeaderPositioning"
    >
      <div class="d-flex flex-column align-center pa-2 justify-center full-width">
        <div class="inside-box-title">
          <div class="title-text">
            <h2 :title="getCannGroupName" class="cann-group-name">{{ getCannGroupName }}</h2>
            <div v-if="showNotImplemented" class="d-flex cann-group-detail">
              {{ $t('assortmentCanvasesPanel.percentOfSpace', ['x']) }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-center mr-3 icons-container compressed" />
    </div>
    <!-- CDT -->
    <div
      ref="cdt-name"
      :style="{ 'grid-column': `span ${childColumns}`, top: `${cannGroupHeaderHeight}px` }"
      class="grid-item-border-bottom flexed-white-bg-grid-item header"
      :class="cdtHeaderPositioning"
    >
      <div class="d-flex flex-row full-width flex-grow-1 justify-center grey-background pa-1">
        <div class="inside-box-title">
          <div class="title-text">
            <h2 :title="getCdtName" class="header-large">{{ getCdtName }}</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- Here we render the roots nodes (no parents) in the cdt node tree -->
    <cdt-column
      v-for="cdtNode in cdtRoots"
      :key="cdtNode._id"
      :cdt-node-id="cdtNode._id"
      :cann-group-id="scenarioCdt.cannGroupId"
      :depth="1"
      :cdts="scenarioCdt.cdt"
      :child-tree="childTree"
      :previous-heights="accumulatedHeights"
      @child-nodes-expanded="sumChildNodes"
      @child-nodes-collapsed="removeChildNodes"
    />
  </div>
</template>

<script>
import { get, filter, sum, values, omit, keyBy, mapValues } from 'lodash';
import { mapGetters, mapMutations, mapState } from 'vuex';
import destroy from '../../utils/destroy';

export default {
  name: 'CannGroupColumn',
  props: {
    scenarioCdt: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      openLeafNodes: {},
      cannGroupHeaderHeight: null,
      cdtHeaderHeight: null,
    };
  },

  computed: {
    ...mapState('assortmentCanvas', ['stickyHeaders']),
    ...mapGetters('scenarios', ['cannGroups', 'attributesById']),
    ...mapGetters('scenarioCdts', ['cdtById', 'childrenLookup']),
    ...mapGetters('context', ['showNotImplemented']),

    childColumns() {
      // This calculation works out how many columns each grid item needs to span. This is calculating the open leaf nodes at the bottom of its respective tree.
      // This is set in the template above for each column e.g 'grid-column: span {childColumns}'
      const unexpandedChildren = this.cdtRoots.filter(x => !this.openLeafNodes[x._id]);
      return sum(values(this.openLeafNodes)) + unexpandedChildren.length;
    },

    childTree() {
      // We create a childTree for each node to find out all the children of that node.
      return this.childrenLookup(this.scenarioCdt.cannGroupId);
    },

    getCannGroupName() {
      const matchingCannGroup = this.cannGroups.find(cg => cg.key === this.scenarioCdt.cannGroupId);
      return get(matchingCannGroup, 'name', '');
    },

    getCdtName() {
      const cdtName = this.scenarioCdt.cdt[0].attributeId;
      // hack to avoid undefined issues on testing env
      return get(this.attributesById, cdtName, { name: this.$t('general.notFound') }).name;
    },

    cdtRoots() {
      // Calculate all root nodes, i.e the children that have no parentId in our cdt tree.
      return filter(this.scenarioCdt.cdt, { parentId: null });
    },

    accumulatedHeights() {
      // We are calculating the heights of the cannGroupHeader and cdtNameHeader via refs. We need to do this to pass it down to the cdt-column component as a prop.
      // This helps to calculate the offsetHeights of each element so we can set the sticky headers programatically.
      return this.cannGroupHeaderHeight + this.cdtHeaderHeight;
    },

    cgHeaderPositioning() {
      // If user has stickyHeaders option enabled then set their position as sticky.
      return this.stickyHeaders ? 'sticky' : 'relative';
    },

    cdtHeaderPositioning() {
      return this.stickyHeaders ? 'sticky' : '';
    },
  },

  created() {
    this.setChildTrees();
    const keyedRoots = keyBy(this.cdtRoots, '_id');
    this.openLeafNodes = mapValues(keyedRoots, () => 1);
  },

  beforeDestroy() {
    destroy.destroyReactiveVueProps(this);
  },

  mounted() {
    this.getHeaderHeights();
  },

  methods: {
    ...mapMutations('assortmentCanvas', ['setChildTree']),

    setChildTrees() {
      // TODO: refactor this so it's on the ac store and returns a full tree that can cache
      this.setChildTree({ id: this.scenarioCdt.cannGroupId, tree: this.childTree });
    },

    sumChildNodes(nodeObject) {
      // When the 'child-nodes-expanded' event is sent from the new-cdt-column component, update the openLeafNodes data object.
      this.openLeafNodes = { ...this.openLeafNodes, ...nodeObject };
    },

    removeChildNodes(nodeId) {
      // Remove the child node count for a certain nodeId, this event is emitted in new-cdt-column when a node is collapsed.
      this.openLeafNodes = omit(this.openLeafNodes, nodeId);
    },

    getHeaderHeights() {
      this.cannGroupHeaderHeight = this.$refs['cann-group-name'].offsetHeight;
      this.cdtHeaderHeight = this.$refs['cdt-name'].offsetHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.cann-group-heading-container {
  border-top: 1px solid $assortment-tile-border-colour;
}

.icons-container {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;

  &.compressed {
    padding-right: 6px;
    flex-direction: row-reverse;
    padding-left: 5px;
    width: 100%;
    margin-right: 0 !important;
    justify-content: space-between;
  }
}

.header-large {
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  height: fit-content;
  font-size: 1.2rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.box-icon {
  font-size: 2.3rem !important;
  align-self: flex-start;
  font-weight: bold;
  color: $assortment-action-icon-color !important;
}

.cann-group-name {
  white-space: nowrap;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 1.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.cann-group-detail {
  justify-content: center;
  font-size: 1.2rem;
}

.draggable-cross {
  font-size: 1.9rem !important;
  font-weight: bold;
  color: $assortment-action-icon-color !important;
}

.grey-background {
  background-color: $canvas-cdt-header-background;
}
</style>
