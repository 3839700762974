import { get, includes } from 'lodash';

/**
 * Function that will filter an array of items for a provided array of filters.
 * It can check whether values are equal or not equal. This could be extended in future to handle extra operations
 *
 * Example structure of the filters:
 * [
 *    {
 *      filterName: 'Furniture Name'
 *      filterType: furnitureName // This is not used here but is in other places that use filters
 *      filterValues: [{ name: 'Furniture 1', value: 'Furniture 1' }, { name: 'Furniture 2', value: 'Furniture 2' }]
 *      notEqualTo: false, // Would be true if we want to find all furniture names that do not equal the selections above
 *      path: 'furnitureName' // The path to the value in the item object
 *    }
 * ]
 *
 * @param {Object} RORO - The RORO wrapper
 * @param {String} RORO.filters - The array of filters
 * @param {String} RORO.items - The items to be filtered
 */
function applyFiltersToData({ filters, items }) {
  if (!filters || !filters.length) return items;
  return items.filter(item =>
    filters.every(filter => {
      const currentValue = get(item, filter.path, '');
      const filterValues = filter.filterValues.map(({ value }) => value);
      return includes(filterValues, currentValue) !== filter.notEqualTo;
    })
  );
}

export default {
  applyFiltersToData,
};
