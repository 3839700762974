<template>
  <a v-if="visible" :class="{ disabled }" :href="url" download @click.prevent="onDownload">
    <slot name="default">
      <v-btn icon>
        <v-icon color="primary" size="18"> mdi-download </v-icon>
      </v-btn>
    </slot>
  </a>
</template>

<script>
import { mapActions } from 'vuex';
import downloadFileUtils from '@/js/utils/download-file-utils';

export default {
  props: {
    url: {
      type: String,
      required: false,
      default: null,
    },

    filename: {
      type: String,
      required: false,
      default: null,
    },

    visible: {
      type: Boolean,
      required: false,
      default: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    ...mapActions('files', ['getDownloadToken']),

    async onDownload() {
      const downloadToken = await this.getDownloadToken();
      const fileData = {
        fileUrl: this.url,
        fileName: this.filename,
      };
      downloadFileUtils.handleDownload(downloadToken, fileData);
    },
  },
};
</script>

<style scoped lang="scss">
.disabled {
  pointer-events: none;
  cursor: default;
}
</style>
