<template>
  <div class="d-flex justify-end align-end mr-0">
    <v-checkbox
      data-id-e2e="toggleClusterUseUnclusteredCheckboxAllClusters"
      :input-value="isEveryClusterSetToUnclustered"
      class="mx-2 mr-0 rtls-checkbox"
      :disabled="isUsageOfUnclusteredDisabled"
      @change="setAllClustersToUnclustered"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { every, isEmpty } from 'lodash';
import { switchingModellingTypes } from '@enums/switching-modelling-types';

export default Vue.extend({
  name: 'useUnclusteredRenderer',
  data() {
    return {
      numberInput: null,
      originalValue: null,
      runningCalculationsWithBias: false,
    };
  },

  computed: {
    hasClusters() {
      return !isEmpty(this.params.clusters);
    },

    isUsageOfUnclusteredDisabled() {
      // user cannot mix methodologies
      return !!this.params.data.similarityBias || !this.hasClusters;
    },

    cannotRunCalculations() {
      return !this.similarityBias || this.isInvalid || this.hideReset;
    },

    isEveryClusterSetToUnclustered() {
      const clusteredMatrices = this.params.data.cannGroupMatrices.filter(cg => !!cg.clusterId);
      return (
        this.hasClusters &&
        every(clusteredMatrices, c => c.type === switchingModellingTypes.setToUnclustered)
      );
    },
  },

  created() {
    this.originalValue = this.similarityBias;
  },

  methods: {
    setAllClustersToUnclustered(isChecked) {
      const payload = this.params.clusters.reduce((acc, { clusterId, isNoDataCluster }) => {
        if (isNoDataCluster) return acc;

        acc.push({
          clusterId,
          cannGroupId: this.params.data.cannGroupId,
          method: isChecked ? switchingModellingTypes.setToUnclustered : null,
          similarityBias: null,
          _id: this.params.data.cannGroupMatricesByCluster[clusterId]._id,
        });
        return acc;
      }, []);

      this.params.onSelectionChange(payload);
    },
  },
});
</script>

<style lang="scss" scoped></style>
