import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        VRow,
        [
          _c(VCol, {
            staticClass: "pa-0 step-tab-panel__content",
            attrs: { justify: "start" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }