var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.dateFormat
    ? _c("imask-input", {
        ref: "input",
        staticClass: "flex-1 m-0 p-0 pr-1 w-100",
        attrs: {
          mask: _vm.dateFormat,
          blocks: _vm.blocks,
          lazy: false,
          placeholder: _vm.dateFormat,
          unmask: false,
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }