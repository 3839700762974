<template>
  <v-col class="attributes-column pa-0 d-flex flex-column justify-space-between">
    <v-container class="ma-0 pa-0 controls">
      <!-- header -->
      <v-row no-gutters class="d-flex justify-start align-center heading-row pt-3 pb-3">
        <v-col :cols="7" class="pl-3">
          <h4>{{ cdtType }}</h4>
        </v-col>
        <v-col :cols="5" class="pa-0">
          <v-row class="selectHeader flex-row flex-1">
            <h4>{{ $tkey('cdtList.select') }}</h4>
            <v-checkbox
              :disabled="selectedControlDisabled"
              :input-value="selectedCdt"
              class="ma-0 pa-0 pl-1 pr-1 rtls-checkbox"
              @change="markAsSelectedCdt(cdt._id)"
            />
            <div class="pl-1 pr-1 ml-1 pa-0 ma-0 trash-wrapper">
              <v-icon
                v-if="isUserModified"
                :disabled="disabled || selectedCdt"
                class="mt-0"
                size="22"
                @click="openDeleteCdtDialog"
              >
                $trash
              </v-icon>
              <div v-else class="pl-1 pr-1">
                <hr class="separator" />
              </div>
            </div>
          </v-row>
        </v-col>
      </v-row>
      <v-divider />
    </v-container>

    <!-- Main content -->
    <div v-if="selectedCannGroup" class="attributes pl-2 pr-2">
      <div class="attribute-list-group mb-3 mt-3">
        <!-- Attribute list headers -->
        <h4>
          <v-row class="pa-0 ma-0 header">
            <v-col :cols="6" class="text-left pl-1 pr-0">
              {{ $tkey('cdtList.relativeIndex') }}: {{ relativeIndex }}
            </v-col>
            <v-col :cols="3" class="text-right pl-0 pr-0 align-right">
              {{ $tkey('cdtList.coefficient') }}
            </v-col>
            <v-col :cols="3" class="text-right pl-0 pr-1 align-right">
              {{ $tkey('cdtList.rank') }}
            </v-col>
          </v-row>
        </h4>

        <!-- Attributes - with explanatory power -->
        <div class="attrs-with-exp-power">
          <v-alert v-if="!cdtGeneratedAttributes.length" type="info" text>
            {{ $tkey('cdtList.noAttributesWithExplanatoryPower') }}
          </v-alert>
          <draggable
            v-model="cdtGeneratedAttributes"
            :options="{ disabled }"
            :group="group"
            ghost-class="dragged"
            draggable=".draggable"
          >
            <div
              v-for="item in cdtGeneratedAttributes"
              :key="item.attributeId"
              :index="item.attributeId"
              class="d-flex align-center justify-start attribute-item"
              :class="{ draggable: !disabled && isUserModified, 'drag-disabled': disabled }"
            >
              <v-checkbox
                v-if="!isUserModified"
                :disabled="disabled"
                :input-value="item.selected"
                class="ml-2 mr-0 rtls-checkbox"
                @change="updateSelected(item, $event)"
              />
              <h4 class="pb-0 full-row flex-1">
                <v-row class="pa-0 ma-0 align-center">
                  <v-col
                    :cols="6"
                    :class="{ 'pl-2': isUserModified }"
                    class="text-left pl-1 pr-0 attr-name"
                  >
                    {{ item.name }}
                  </v-col>
                  <v-col :cols="3" class="text-right pl-0">
                    {{ formatCoefficient(item.coefficient) }}
                  </v-col>
                  <v-col :cols="3" class="text-right pl-0 pr-1 rank">
                    <span v-if="item.rank" class="rank-circle mr-1">{{ item.rank }}</span>
                    <v-icon v-if="isUserModified" size="10">
                      $cross-move
                    </v-icon>
                  </v-col>
                </v-row>
              </h4>
            </div>
            <div v-if="isUserModified" slot="footer" class="item list-group__drop" />
          </draggable>
        </div>

        <div class="pt-2 pb-0 d-flex justify-end flex-wrap">
          <!-- Remove all button -->
          <v-btn
            v-if="isUserModified"
            :disabled="!cdtGeneratedAttributes.length || loading"
            secondary
            depressed
            class="mr-2 mb-2"
            @click="removeAllModifiedAttributes"
            >{{ $tkey('removeAll') }}</v-btn
          >

          <!-- View tree button -->
          <v-btn :disabled="loading" secondary depressed class="mr-2 mb-2" @click="openTreeView">{{
            $tkey('cdtList.viewTree')
          }}</v-btn>
        </div>

        <v-divider />
      </div>

      <!-- Attributes - without explanatory power -->
      <div class="attribute-list-group mt-3 mb-3 attrs-without-exp-power">
        <v-alert v-if="!selectedScenarioAttributes.length" type="info" text>{{
          $tkey('cdtList.noAttributesWithoutExplanatoryPower')
        }}</v-alert>
        <div class="mb-2">
          <draggable
            v-model="selectedScenarioAttributes"
            :options="{ disabled }"
            :group="group"
            draggable=".draggable"
            ghost-class="dragged"
          >
            <div
              v-for="item in selectedScenarioAttributes"
              :key="item.attributeId"
              :index="item.attributeId"
              :class="{
                'attr-greyed-out': !isUserModified,
                draggable: !disabled && isUserModified,
                'drag-disabled': disabled,
              }"
              class="d-flex align-center justify-start attribute-item"
            >
              <v-checkbox
                v-if="!isUserModified"
                :disabled="disabled"
                :input-value="item.selected"
                class="ml-2 mr-0 rtls-checkbox"
                @change="updateSelected(item, $event)"
              />
              <h4 :class="{ 'pl-2': isUserModified }" class="pb-0 full-row">
                <v-row class="pa-0 ma-0">
                  <v-col
                    :cols="6"
                    :class="{ 'pl-2': isUserModified }"
                    class="text-left pl-1 pr-0 attr-name"
                  >
                    {{ item.name }}
                  </v-col>
                  <v-col :cols="3" class="text-right pl-0">
                    {{ getField(item, 'coefficient') ? formatCoefficient(item.coefficient) : '' }}
                  </v-col>
                  <v-col :cols="3" class="text-right pl-0 pr-1 rank">
                    <span v-if="getField(item, 'rank')" class="rank-circle">
                      {{ getField(item, 'rank') }}
                    </span>
                    <v-icon v-if="isUserModified" size="10" class="pull-right mt-1">
                      $cross-move
                    </v-icon>
                  </v-col>
                </v-row>
              </h4>
            </div>
            <div v-if="isUserModified" slot="footer" class="item list-group__drop" />
          </draggable>
        </div>
        <v-divider />
      </div>

      <!-- For the tool generated, show the initially unselected attributes -->
      <div v-if="!isUserModified" class="attribute-list-group mt-3 mb-3 not-selected-attrs">
        <div v-if="notSelectedScenarioAttributes.length">
          <div
            v-for="item in notSelectedScenarioAttributes"
            :key="item.id"
            class="d-flex align-center justify-start attribute-item attr-greyed-out"
          >
            <v-checkbox
              v-if="!isUserModified"
              :disabled="disabled"
              :input-value="item.selected"
              class="ml-2 mr-0 rtls-checkbox"
              @change="updateSelected(item, $event)"
            />
            <h4 :class="{ 'pl-2': isUserModified }" class="pb-0 full-row">
              <v-row class="pa-0 ma-0 align-center">
                <v-col
                  :cols="6"
                  :class="{ 'pl-2': isUserModified }"
                  class="text-left pl-1 pr-0 attr-name"
                >
                  {{ item.name }}
                </v-col>
                <v-col :cols="3" class="text-right pl-0">
                  {{ getField(item, 'coefficient') }}
                </v-col>
                <v-col :cols="3" class="text-right pl-0 pr-1 rank">
                  <span v-if="getField(item, 'rank')" class="rank-circle">
                    {{ getField(item, 'rank') }}
                  </span>
                </v-col>
              </v-row>
            </h4>
          </div>
        </div>
        <v-alert v-else type="info" text>{{ $tkey('cdtList.allAttributesAreSelected') }}</v-alert>
      </div>
    </div>

    <!-- Warning for modified tool generated CDT -->
    <div v-else class="select-cann-group pa-10 pull-right">{{ $tkey('selectCannGroup') }}</div>
    <v-alert
      v-if="!isUserModified && hasToolGeneratedChanges"
      dense
      outlined
      type="error"
      class="ml-2 mr-2"
    >
      {{ $tkey('regenerationWarning') }}<br />
      {{ $tkey('modificationWillBeLost') }}
    </v-alert>

    <!-- Actions -->
    <div
      v-if="selectedCannGroup"
      class="attributes-actions-container pt-2 pb-0 d-flex justify-end flex-wrap"
    >
      <!-- If tool generated - allow regenerate -->
      <div v-if="!isUserModified">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="resetToDefault"
              v-bind="attrs"
              :disabled="resetToDefaultDisabled"
              icon
              text
              class="mr-2 mb-2"
              v-on="on"
              @click="resetToDefaultSelections"
            >
              <v-icon size="24">$reset</v-icon>
            </v-btn>
          </template>
          <span>{{ $tkey('resetAttributesTooltip') }}</span>
        </v-tooltip>

        <!-- Copy selection modal -->
        <select-multiple-cann-groups-modal
          include-options
          exclude-self
          enable-all-options
          save-button-text="copyAndSave"
          :selected-cann-group="selectedCannGroup"
          :title="$tkey('copy.title')"
          :subtitle="$tkey('copy.subtitle')"
          @confirm="copyCdtInformation"
        >
          <template v-slot:activator="{ on }">
            <v-btn :disabled="disabled" secondary depressed class="mr-2 mb-2" v-on="on">
              {{ $tkey('copy.button') }}
            </v-btn>
          </template>
        </select-multiple-cann-groups-modal>
        <v-btn
          id="saveToolGeneratedCDT"
          action
          depressed
          class="mr-2 mb-2"
          :disabled="isSaveTGCdtDisabled"
          @click="onSaveClick"
        >
          {{ $t('actions.save') }}
        </v-btn>
        <v-btn
          :disabled="disabled || !enableGenerationButton || !hasToolGeneratedChanges"
          primary
          depressed
          class="mr-2 mb-2"
          @click="generate"
        >
          {{ $tkey('generate') }}
        </v-btn>
      </div>
      <!-- If user modified, allow save -->
      <v-btn
        v-else
        :disabled="!enableModifiedCdtSave"
        :loading="isSavingUserModified"
        action
        depressed
        class="mr-2 mb-2"
        @click="saveModifiedCdt(cdt)"
      >
        {{ $t('actions.save') }}
      </v-btn>
    </div>

    <!-- Delete user modified confirm dialog -->
    <main-dialog
      ref="deleteModifiedCdtDialog"
      :title="$tkey('cdtList.deleteCdtTitle')"
      :message="$tkey('cdtList.deleteCdtConfirmationMessage')"
    >
      <template v-slot:actions="{ cancel: close }">
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn data-id-e2e="btnAgreeCheckpoint" action @click="[deleteCdt(true), close()]">
              {{ $tkey('cdtList.confirmDelete') }}
            </v-btn>
            <v-btn
              data-id-e2e="btnDisagreeCheckpoint"
              class="ml-2"
              @click="[deleteCdt(false), close()]"
            >
              {{ $tkey('cdtList.cancelDelete') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </main-dialog>
  </v-col>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { isEqual, map, cloneDeep, has, pick, find, sortBy } from 'lodash';
import { toolGenerated, userModified } from '@enums/scenario-cdt-types';

export default {
  props: {
    selectedCannGroup: {
      required: true,
      type: Object,
    },
    cdt: {
      required: true,
      type: Object,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  localizationKey: 'customerDecisionTree',

  data() {
    return {
      allScenarioAttributes: [],
      initialSelections: null,
      currentSelections: null,
      scenarioAttributes: [],
      enableGenerationButton: false,
      cdtGeneratedAttributes: [],
      selectedScenarioAttributes: [],
      notSelectedScenarioAttributes: [],
      group: { name: 'attributes' },
      initialAttributes: null,
      initialToolGeneratedAttrs: {},
      hasToolGeneratedChanges: false,
      isSavingUserModified: false,
      isSaveTGCdtDisabled: true,
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('scenarioCdts', ['scenarioCdts', 'cdtSelectedAttributes', 'loading']),
    ...mapGetters('scenarios', ['attributesById']),
    ...mapState('context', ['clientConfig']),

    hasDataChanges() {
      return !this.isSaveTGCdtDisabled || this.enableModifiedCdtSave;
    },

    scenarioHasAttributes() {
      return !!this.currentSelections;
    },

    selectedCdt() {
      return this.cdt.selected;
    },

    selectedControlDisabled() {
      return this.disabled || !this.cdt._id || this.selectedCdt || this.loading;
    },

    cdtType() {
      return this.cdt.type === toolGenerated
        ? this.$tkey('cdtList.toolGeneratedCdt')
        : this.$tkey('cdtList.userModifiedCdt');
    },

    isUserModified() {
      return this.cdt.type === userModified;
    },

    enableModifiedCdtSave() {
      if (this.disabled) return false;

      const generatedAttributeIds = this.cdtGeneratedAttributes.map(a => a.name).join('');
      return this.initialAttributes !== generatedAttributeIds;
    },

    relativeIndex() {
      const currentLevels = this.cdtGeneratedAttributes.filter(x => x.coefficient);
      const baseLevels = sortBy(currentLevels, ['coefficient']);
      const reverseBaseLevels = sortBy(currentLevels, ['coefficient']).reverse();

      const currentGap = this.calculateGap(reverseBaseLevels, currentLevels);
      const reverseBaseGap = this.calculateGap(reverseBaseLevels, baseLevels);
      const conformity = Math.max(1 - currentGap / reverseBaseGap || 0, 0);
      return `${Math.round(conformity * 100)}%`;
    },

    resetToDefaultDisabled() {
      if (this.disabled) return true;

      return (
        this.selectedScenarioAttributes.every(attr => attr.selected) &&
        this.cdtGeneratedAttributes.every(attr => attr.selected) &&
        this.notSelectedScenarioAttributes.every(attr => attr.selected)
      );
    },
  },

  watch: {
    selectedCannGroup: {
      immediate: true,
      handler() {
        this.splitAttributesBetweenGroups();
      },
    },
    cdt: {
      deep: true,
      handler() {
        this.splitAttributesBetweenGroups();
      },
    },
    isSaveTGCdtDisabled() {
      this.$emit('data-changed', this.hasDataChanges);
    },
    enableModifiedCdtSave() {
      this.$emit('data-changed', this.hasDataChanges);
    },
  },

  async created() {
    if (!this.cdt._id) {
      return;
    }
    this.splitAttributesBetweenGroups();
  },

  mounted() {
    this.setInitialAttributes();
  },

  methods: {
    ...mapActions('scenarioCdts', [
      'fetchScenarioCdts',
      'updateCdtById',
      'deleteModifiedCdt',
      'addCdt',
      'deleteUnsavedModifiedCdt',
      'copyAttributeSelection',
    ]),
    formatCoefficient(coefficient) {
      const cutOff = this.clientConfig.cdt.coefficientDisplayCutOff;
      if (coefficient < cutOff) {
        const cutOffFormatted = this.formatNumber({ number: cutOff, format: 'float' });
        return `< ${cutOffFormatted}`;
      }
      return this.formatNumber({ number: coefficient, format: 'float' });
    },
    resetToDefaultSelections() {
      // For now this just sets everything as selected
      this.selectedScenarioAttributes.forEach(item => {
        if (!item.selected) this.updateSelected(item, true);
      });
      this.cdtGeneratedAttributes.forEach(item => {
        if (!item.selected) this.updateSelected(item, true);
      });
      this.notSelectedScenarioAttributes.forEach(item => {
        if (!item.selected) this.updateSelected(item, true);
      });
    },

    openTreeView() {
      this.$emit('open-tree-view-modal', this.cdt);
    },

    getCurrentlySelectedAttributes() {
      // attributes selected by both the user & algorithm
      const generated = this.cdtGeneratedAttributes
        .filter(attr => attr.selected)
        .map(attr => attr.attributeId);
      // originally selected by the user, but dropped by algo, then re-selected by the user
      const selected = this.selectedScenarioAttributes
        .filter(attr => attr.selected)
        .map(attr => attr.attributeId);
      // attributes that were originally unselected before running the job for the first time
      const notSelected = this.notSelectedScenarioAttributes
        .filter(attr => attr.selected)
        .map(attr => attr.id);
      return { generated, selected, notSelected };
    },

    toolGeneratedInitialSelectionAttrs() {
      this.initialToolGeneratedAttrs = cloneDeep(this.getCurrentlySelectedAttributes());
    },

    checkForToolGeneratedChanges() {
      // if we don't have originalJobAttributesSelection, then it is an old scenario
      const attributeSelectionFromJob = (this.cdt.originalJobAttributesSelection
        ? map(this.cdt.originalJobAttributesSelection, 'attributeId')
        : this.initialToolGeneratedAttrs.generated.concat(this.initialToolGeneratedAttrs.selected)
      ).sort();
      //
      const { generated, selected, notSelected } = this.getCurrentlySelectedAttributes();
      const currentSelection = generated.concat(selected, notSelected).sort();

      // values that were stored into the database
      const savedSelection = map(this.cdt.attributes, 'attributeId').sort();
      // If the current state matches the saved selection and also what the job generated, then nothing to show.
      if (
        isEqual(savedSelection, currentSelection) &&
        isEqual(currentSelection, attributeSelectionFromJob)
      ) {
        this.hasToolGeneratedChanges = false;
        return;
      }

      // means that the user went back and forth with selection
      if (
        isEqual(currentSelection, attributeSelectionFromJob) &&
        !isEqual(savedSelection, currentSelection)
      ) {
        this.hasToolGeneratedChanges = true;
        return;
      }
      this.hasToolGeneratedChanges = !isEqual(attributeSelectionFromJob, currentSelection);
    },

    init() {
      const { key } = this.selectedCannGroup;
      this.allScenarioAttributes = cloneDeep(this.cdtSelectedAttributes[key]);
      this.shouldEnableGeneration();
      this.cdtGeneratedAttributes = this.getCdtGeneratedAttributes();
      this.selectedScenarioAttributes = this.getSelectedScenarioAttributes();
      this.notSelectedScenarioAttributes = this.getNotSelectedScenarioAttributes();
    },

    setInitialAttributes() {
      this.initialAttributes = this.cdtGeneratedAttributes.map(a => a.name).join('');
      this.toolGeneratedInitialSelectionAttrs();
      this.checkForToolGeneratedChanges();
      if (this.cdt.type === toolGenerated) {
        this.isSaveTGCdtDisabled = this.isSaveSelectionDisabled();
      }
    },

    async generate() {
      const { key } = this.selectedCannGroup;
      const { _id } = this.cdt;
      const attributesList = this.getSelectedAttributesList();
      const updatePayload = {
        cdtId: _id,
        updates: { attributes: attributesList },
      };

      this.$emit('generate', { cannGroupIds: [key], updatePayload });
    },

    getCdtGeneratedAttributes() {
      // used to split the DB entry between two: selected and not by the algo
      const attrs = map(this.cdt.attributes, attr => {
        const el = find(this.allScenarioAttributes, { id: attr.attributeId });
        if (el) {
          return {
            ...attr,
            name: el.name,
            selected: has(attr, 'isSelected') ? attr.isSelected : true,
          };
        }
        return attr;
      });

      // attributes that were selected by the user and the algo
      if (!this.isUserModified) {
        return attrs.filter(el => el.coefficient && el.rank && el.selected);
      }
      return attrs.filter(el => el.selected);
    },

    getNotSelectedScenarioAttributes() {
      // attributes that were originally unselected before running the job for the first time
      const usedAttributesIds = map(this.cdt.attributes, 'attributeId');
      return (this.allScenarioAttributes || [])
        .filter(attr => !usedAttributesIds.includes(attr.id))
        .map(el => {
          return { ...el, ...{ selected: false } };
        });
    },

    getSelectedScenarioAttributesForNewUserModifiedCdt() {
      const { key } = this.selectedCannGroup;
      const toolGeneratedCdt = this.scenarioCdts.find(
        c =>
          c.cannGroupId === key && c.scenarioId === this.cdt.scenarioId && c.type === toolGenerated
      );
      const notSelectedAttributesIds = toolGeneratedCdt.attributes
        .filter(attr => !attr.coefficient && !attr.rank)
        .map(attr => attr.attributeId);
      return this.allScenarioAttributes.filter(attr => {
        return notSelectedAttributesIds.includes(attr.id) ? attr : null;
      });
    },

    getAttributeName(attributeId) {
      return this.attributesById[attributeId].name;
    },

    getSelectedScenarioAttributes() {
      if (this.isUserModified) {
        return this.cdt._id
          ? this.cdt.attributes
              .filter(a => !a.isSelected)
              .map(attr => ({ ...attr, name: this.getAttributeName(attr.attributeId) }))
          : this.getSelectedScenarioAttributesForNewUserModifiedCdt();
      }

      // for the tool generated CDT, it represents attributes that were selected by the user but dropped by the algo
      return this.cdt.attributes
        .filter(attr => !attr.coefficient && !attr.rank)
        .map(a => {
          return {
            ...a,
            selected: true,
            name: this.getAttributeName(a.attributeId),
          };
        });
    },

    hasSelectedAttributes() {
      // checking whether the user has selected any attribute
      if (
        !this.notSelectedScenarioAttributes ||
        !this.cdtGeneratedAttributes ||
        !this.selectedScenarioAttributes
      ) {
        return false;
      }
      return this.getSelectedAttributesList().length > 0;
    },

    shouldEnableGeneration() {
      this.enableGenerationButton = this.hasSelectedAttributes();
    },

    getSelectedAttributesList() {
      return [
        ...this.selectedScenarioAttributes,
        ...this.notSelectedScenarioAttributes,
        ...this.cdtGeneratedAttributes,
      ]
        .filter(attr => attr.selected)
        .map(a => {
          a.attributeId = has(a, 'attributeId') ? a.attributeId : a.id;
          return a;
        });
    },

    updateSelected(item, isSelected) {
      // if the attribute was reselected, we copy the previous rank, coefficient and standard error
      const previousAttributeValue = find(
        this.cdt.originalJobAttributesSelection,
        // it will have an `id` when unselected and `attributeId` post save
        a => a.attributeId === item.id || a.attributeId === item.attributeId
      );
      if (isSelected && !!previousAttributeValue && !item.rank) {
        this.$set(item, 'rank', previousAttributeValue.rank);
        this.$set(item, 'coefficient', previousAttributeValue.coefficient);
        this.$set(item, 'standardError', previousAttributeValue.standardError || null);
      }
      this.$set(item, 'selected', isSelected);
      this.checkForToolGeneratedChanges();
      this.shouldEnableGeneration();
      if (this.cdt.type === toolGenerated) {
        this.isSaveTGCdtDisabled = this.isSaveSelectionDisabled();
      }
    },

    async markAsSelectedCdt(cdtId) {
      const { key } = this.selectedCannGroup;
      const payload = { cdtId, cannGroupId: key, updates: { selected: !this.selectedCdt } };
      await this.updateCdtById(payload);
      await this.fetchScenarioCdts();
    },

    async saveModifiedCdt(cdt) {
      this.isSavingUserModified = true;
      const attributesList = map(
        this.cdtGeneratedAttributes
          .map(el => {
            return has(el, 'id') ? { ...el, ...{ attributeId: el.id } } : el;
          })
          .filter(a => a.selected),
        'attributeId'
      );
      if (!cdt._id) {
        const payload = {
          ...pick(cdt, ['sourceId', 'scenarioId', 'cannGroupId']),
          attributesList,
        };
        await this.addCdt(payload);
      } else {
        const attributes = this.cdtGeneratedAttributes.map(el => {
          if (has(el, 'id')) {
            el.attributeId = el.id;
            el.rank = el.rank ? el.rank : null;
            el.coefficient = el.coefficient ? el.coefficient : null;
            el.selected = true;
            delete el.id;
          }
          return el;
        });
        const { key } = this.selectedCannGroup;
        const payload = {
          cdtId: cdt._id,
          cannGroupId: key,
          updates: { attributes },
        };
        await this.updateCdtById(payload);
        await this.fetchScenarioCdts();
      }
      this.isSavingUserModified = false;
      this.splitAttributesBetweenGroups();
    },

    // Unselect all attributes (with explanatory power) from Modified CDT
    removeAllModifiedAttributes() {
      this.selectedScenarioAttributes.push(...this.cdtGeneratedAttributes);
      this.cdtGeneratedAttributes = [];
    },

    openDeleteCdtDialog() {
      this.$refs.deleteModifiedCdtDialog.open();
    },

    async deleteCdt(shouldDelete) {
      if (!shouldDelete) return;
      if (!this.cdt._id) return this.deleteUnsavedModifiedCdt(this.cdt);
      await this.deleteModifiedCdt({ cdtId: this.cdt._id });
      this.$emit('data-changed', this.hasDataChanges);
    },

    async onSaveClick() {
      const payload = this.getSavePayloadForModifiedCDT();
      await this.updateCdtById(payload);
      await this.fetchScenarioCdts();
      this.splitAttributesBetweenGroups();
    },

    splitAttributesBetweenGroups() {
      // We need to manually call for reset of the local data on store changes.
      // This is required to split the attributes between the three existing groups: with/without explanatory power and unselected ones.
      this.init();
      this.setInitialAttributes();
    },

    isSaveSelectionDisabled() {
      const { generated, selected, notSelected } = this.getCurrentlySelectedAttributes();
      const currentSelection = generated.concat(selected, notSelected).sort();

      // values that were stored into the database
      const savedSelection = map(this.cdt.attributes, 'attributeId').sort();

      // If the current state matches the saved selection, then there is nothing to savee
      return isEqual(savedSelection, currentSelection);
    },

    getField(attribute, field) {
      // This is needed so that we can show for unselected attributes, their original rank and coefficient in case
      // they were dropped post job execution.
      // For user modified CDT, we do not show the original (if present) value but the value as they were
      if (attribute[field] || this.isUserModified) return attribute[field];
      const previousAttributeValue = find(
        this.cdt.originalJobAttributesSelection,
        // it will have an `id` when unselected and `attributeId` post save
        a => a.attributeId === attribute.id || a.attributeId === attribute.attributeId
      );

      if (previousAttributeValue) {
        return previousAttributeValue[field];
      }
      return null;
    },

    async copyCdtInformation({ cannGroupIds }) {
      if (!cannGroupIds.length) return;

      // if there is any modification on screen, save it first
      if (!this.isSaveSelectionDisabled()) {
        const payload = this.getSavePayloadForModifiedCDT();
        await this.updateCdtById(payload);
      }

      this.copyAttributeSelection({
        fromCannGroupId: this.selectedCannGroup.key,
        toCannGroups: cannGroupIds,
      });
    },

    getSavePayloadForModifiedCDT() {
      // only selected attributes need to be stored
      // attributes selected by both the user & algorithm
      const allSelectedAttributes = this.cdtGeneratedAttributes
        .concat(this.selectedScenarioAttributes, this.notSelectedScenarioAttributes)
        .filter(attr => attr.selected)
        .map(attr => pick(attr, ['rank', 'attributeId', 'coefficient', 'standardError']));
      const attributesSortedByRank = sortBy(allSelectedAttributes, 'rank');

      return {
        cdtId: this.cdt._id,
        cannGroupId: this.selectedCannGroup.key,
        updates: {
          attributes: attributesSortedByRank,
        },
      };
    },
    calculateGap(baseLevels, targetLevels) {
      return baseLevels.reduce((acc, baseLevel, i) => {
        const { coefficient: baseCoefficient, standardError: baseError } = baseLevel;
        const { coefficient: targetCoefficient, standardError: targetError } = targetLevels[i];

        return (
          acc + Math.abs(baseCoefficient - targetCoefficient) / Math.sqrt(baseError * targetError)
        );
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.attributes-column {
  border-right: 3px solid $assortment-divider-colour;
  width: 330px;

  .v-alert {
    font-size: 1.2em;
    padding: 5px;
  }

  .select-cann-group {
    text-align: center;
    color: $assortment-text-colour;
    font-size: 1.2rem;
    flex-grow: 1;
  }

  .attributes {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .attributes-column-header {
    font-weight: normal;
  }

  .attributes-actions-container {
    margin-top: 5px;
    border-top: 1px solid $assortment-divider-colour;
  }

  .v-input--checkbox {
    height: 100%;
    margin-top: 1px;
    ::v-deep {
      * i.v-icon {
        font-size: 16px;
      }
      .v-input--selection-controls__input {
        margin-right: 4px;
      }
    }
  }

  .attribute-list-group {
    .attribute-item {
      min-height: 28px;
      width: 100%;
      padding-left: 0px;
      margin-bottom: 5px;
      border-radius: 3px;
      color: $assortment-text-colour;
      border: 1px solid transparent;
      background-color: $assortment-attribute-colour;

      h4 {
        font-size: 1.1rem;
      }

      .full-row {
        width: 100%;
      }
    }

    .list-group__drop {
      position: relative;
      pointer-events: none;
      color: $assortment-greyed-out-colour;
      border: 1px dashed $assortment-greyed-out-colour;
      background: white;
      padding: 10px;
      margin-top: 10px;
    }

    .attr-greyed-out {
      color: $assortment-greyed-out-colour !important;
      ::v-deep {
        .v-input--checkbox.rtls-checkbox * i.v-icon {
          color: $assortment-greyed-out-colour !important;
        }
      }
    }
  }
  .selectHeader {
    display: flex;

    h4 {
      margin-top: 2px;
    }
    ::v-deep {
      .rtls-checkbox {
        padding: 0 !important;
        margin: 0 0 0 10px !important;
      }
      .v-input__control {
        padding: 0;
        margin: 0;
      }
    }
  }
  .rank-circle {
    background: $rank-background;
    padding: 0 5px;
    border-radius: 50%;
    color: white;
  }
  .header {
    font-size: 1em;
  }
  .heading-row {
    padding-top: 14px !important;
  }
  .separator {
    width: 15px;
    border: 1px solid $assortment-border-colour;
    border-width: thin 0 0 0;
  }
  .trash-wrapper {
    display: flex;
    align-items: center;
    border-left: 1px solid $assortment-border-colour;
  }
  .dragged {
    border: 1px solid $assortment-primary-colour;
  }
  .drag-disabled {
    opacity: 0.5;
  }
}
</style>
