<template>
  <div class="d-flex filter-box" data-id-e2e="roleFilterSelect">
    <span class="col-3">
      {{ $t('workpackagePage.splitWorkpackages.filterAssortmentRole') }}
    </span>
    <v-select
      v-model="selectedRoles"
      :items="assortmentRoles"
      hide-details
      white
      multiple
      :disabled="!assortmentRoles.length"
      :placeholder="$t('filterWorkpackagesOptions.noOption')"
      class="rtls-select rtls-select--white"
      data-id-e2e="roleSelect"
      @change="updateMainFilterObject()"
    >
      <template v-slot:selection="{ item, index }">
        <span v-if="index < 1" class="selected-items">{{ item }} </span>
        <span v-if="index === 1" class="grey--text caption">
          (+{{ selectedRoles.length - 1 }}{{ $t('workpackagePage.splitWorkpackages.moreRoles') }})
        </span>
      </template>
      <template v-slot:prepend-item>
        <div class="sticky">
          <div class="d-flex justify-space-around align-center pt-2">
            <v-btn class="button-link" text @click="selectAll">
              <v-icon size="16">mdi-check</v-icon>
              <span>{{ $t('actions.selectAll') }}</span>
            </v-btn>
            <v-btn class="button-link" text @click="selectNone">
              <v-icon size="16">mdi-checkbox-blank-outline</v-icon>
              <span>{{ $t('actions.selectNone') }}</span>
            </v-btn>
          </div>
          <v-divider class="mt-2" />
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import filterTypeEnum from '@enums/wp-filter-types';
import { map, compact, uniq, isEmpty, get } from 'lodash';
import { filterSplitWorkpackagesMixin } from '@/js/mixins/tooltip-options';

export default {
  mixins: [filterSplitWorkpackagesMixin],

  props: {
    wpFurniture: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    selectedRoles: [],
  }),

  computed: {
    assortmentRoles() {
      if (isEmpty(this.wpFurniture.furniture)) {
        return [];
      }

      return uniq(
        compact(
          map(this.wpFurniture.furniture, f =>
            get(f, 'clientProvidedMetadata.furnitureAssortmentRole', null)
          )
        )
      ).sort();
    },
  },

  watch: {
    // When the assortmentRoles array gets initialised, we should select all by default.
    wpFurniture() {
      this.selectAll();
      this.$emit('updateMainFilterObject', {
        type: filterTypeEnum.role,
        update: this.selectedRoles,
        isInit: true,
      });
    },
  },

  methods: {
    async updateMainFilterObject() {
      this.$emit('updateMainFilterObject', {
        type: filterTypeEnum.role,
        update: this.selectedRoles,
      });
    },

    selectAll() {
      this.selectedRoles = [...this.assortmentRoles];
    },

    selectNone() {
      this.selectedRoles = [];
      this.updateMainFilterObject();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .v-select__selections {
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: none;
  }
  .v-list {
    position: relative;
  }
}
.filter-box {
  align-items: center;

  span {
    padding-left: 0;
  }
}

.selected-items {
  padding-left: 5px !important;
}

.sticky {
  position: sticky;
  background-color: $canvas-header-white-background;
  top: 0;
}

.button-link {
  color: $assortment-primary-colour;
  padding: 0 !important;
  &::before,
  &::after {
    display: none !important;
  }

  span {
    text-decoration: underline;
  }
}
</style>
