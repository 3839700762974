<template>
  <v-container class="assortment__container py-1">
    <v-row class="assortment-header mt-1 mb-1">{{ $t('scenarioAssortmentPanel.settings') }}</v-row>
    <v-divider horizontal class="my-1" />
    <v-row class="my-1">
      <scenario-optimiser-form
        ref="optimiserForm"
        :read-only="!hasPermission(userPermissions.canChangeOptimiserSettings)"
      />
    </v-row>
    <v-row class="assortment-header mt-1 mb-1">
      {{ $t('scenarioAssortmentPanel.assortments') }}
    </v-row>
    <v-divider horizontal class="my-1" />

    <progress-bar
      v-if="isRenderingCanvases"
      :message="
        isClusteredSwitchingJobRunning
          ? $t('messages.clusteredSwitchingRunning')
          : $t('messages.canvasesRendering')
      "
      class="pt-5"
    />

    <ag-grid-vue
      v-if="!isLoadingCanvases"
      style="width: 100%;"
      class="ag-theme-custom"
      dom-layout="autoHeight"
      :row-data="assortments"
      :column-defs="headers"
      :grid-options="gridOptions"
      :stop-editing-when-cells-loses-focus="true"
      @grid-ready="onGridReady"
      @first-data-rendered="onFirstDataRendered"
      @selection-changed="setSelectedAssortments"
    />

    <progress-bar
      v-if="isCanvasGenerationJobRunning"
      :percentage="jobStatuses.canvasGeneration.progress"
      :message="$t('messages.inProgress')"
      :status="jobStatuses.canvasGeneration.status"
      class="pb-10"
    />

    <progress-bar
      v-if="isClScSwitchingJobRunning"
      :percentage="jobStatuses.clScSwitchingMatricesCalculation.progress"
      :message="$t('messages.inProgress')"
      :status="jobStatuses.clScSwitchingMatricesCalculation.status"
      class="pb-10"
    />

    <v-row v-if="!isLoadingCanvases">
      <v-col class="d-flex flex-row justify-end">
        <div v-if="hasClusteredAssortments" class="d-flex flex-grow-1">
          <div v-if="hasPermission(userPermissions.canFetchCanvases)" class="d-flex mr-3">
            <v-btn
              outlined
              :disabled="!isRowSelectable"
              @click="selectRows('clusterId', null, true)"
            >
              {{ $tkey('selectAllUnclustered') }}
            </v-btn>
          </div>
          <div v-if="hasPermission(userPermissions.canFetchCanvases)" class="d-flex">
            <v-btn outlined :disabled="!isRowSelectable" @click="selectRows('clusterId', null)">
              {{ $tkey('selectAllClustered') }}
            </v-btn>
          </div>
        </div>

        <div class="d-flex">
          <div class="d-flex mr-3">
            <v-btn
              data-id-e2e="btnOptimiseAll"
              data-dd-action-name="OptimiseAllCanvasesBtn"
              :disabled="isOptimiseAllDisabled"
              outlined
              @click="() => $refs.optimiseAllModal.open()"
            >
              {{ $tkey('optimiseAllButton') }}
            </v-btn>
            <error-triangle
              v-if="isCanvasConfigInvalidForOptimisation"
              data-id-e2e="errorTriangleOptimiseAll"
              :arrow="false"
              class="ml-2"
              :errors="{
                [$t(
                  'assortmentCanvasPage.errorMessages.setOptimiserSettings'
                )]: !isCanvasConfigInvalidForOptimisation,
              }"
            />
          </div>

          <div class="d-flex mr-3">
            <v-btn
              data-id-e2e="btnFetchCanvases"
              outlined
              :disabled="isFetchCanvasesDisabled"
              @click="runCanvasGenerationJob"
            >
              {{ $t('scenarioAssortmentPanel.fetchCanvases') }}
            </v-btn>
            <error-triangle
              v-if="cannGroupMissingCDT"
              data-id-e2e="errorTriangleFetchCanvases"
              :arrow="false"
              class="ml-2"
              :errors="{
                [$t(
                  'assortmentCanvasPage.errorMessages.cannGroupMissingCDT'
                )]: !cannGroupMissingCDT,
              }"
            />
          </div>

          <div v-if="showReporting" class="d-flexmr-3">
            <v-btn
              data-id-e2e="btnViewReport"
              outlined
              :disabled="isReportingDisabled || isClusteredSwitchingJobRunning"
              :to="reportPageRoute"
            >
              {{ $t('scenarioAssortmentPanel.viewReport') }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <main-dialog ref="optimiseAllModal" :title="$tkey('optimiseAllModal.title')">
      <template v-slot:content>
        <div class="mt-5 mb-2 mx-5 text-justify">
          <span>{{ $tkey('optimiseAllModal.description') }}</span>
        </div>
        <div class="d-flex align-center mb-5">
          <v-checkbox
            :input-value="createCheckpoint"
            class="rtls-checkbox mx-2"
            @change="createCheckpoint = $event"
          />
          <span class="mr-4">{{ $tkey('optimiseAllModal.createCheckpoint') }}</span>
        </div>
      </template>
      <template v-slot:actions="{ cancel: close }">
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn action @click="[initOptimisation(), close()]">
              {{ $t('actions.confirm') }}
            </v-btn>
            <v-btn class="ml-2" @click="close()">
              {{ $t('actions.cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </main-dialog>

    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="closeUnsavedDataModal"
      @confirm="closeUnsavedDataModal"
    />
  </v-container>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  get,
  includes,
  isEqual,
  isEmpty,
  map,
  size,
  reduce,
  keyBy,
  isBoolean,
  some,
  filter,
} from 'lodash';
import i18n from '@/js/vue-i18n';
import agGridUtils from '@/js/utils/ag-grid-utils';
import inputValidationMixin from '@/js/mixins/input-validations';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import {
  jobFinishedStatuses,
  isJobInProgress,
  jobStatuses,
  jobStatusesIconsMappings,
} from '@enums/jobapi';
import reportingMetrics from '@enums/reporting-metrics';
import agGridIcon from '@/js/components/ag-grid-cell-renderers/ag-grid-icon.vue';
import agGridCellRendererComponentWrapper from '@/js/components/ag-grid-cell-renderers/ag-grid-cell-renderer-component-wrapper.vue';

// We need to do translations inside of ag-grid functions where this is not bound to Vue.
const localizationKey = 'assortmentCanvasesPanel';
const t = (key, locKey) => i18n.t(`${locKey}.${key}`);

const iconEnum = {
  goToAssortmentCanvas: 'next',
  notStarted: 'delimiter',
};

const fieldEnum = {
  clusterName: 'clusterName',
  storeClassName: 'storeClassName',
  switchingType: 'switchingType',
  description: 'description',
  generated: 'generated',
  optimised: 'optimised',
  outdated: 'outdatedWarningIcon',
};

export default {
  name: 'AssortmentPanel',
  localizationKey,
  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    agGridIcon,
    agGridCellRendererComponentWrapper,
  },
  mixins: [inputValidationMixin, unsavedDataWarningMixin],
  props: {
    scenario: {
      required: false,
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      gridOptions: {
        suppressContextMenu: true,
        defaultColDef: {
          filter: true,
          sortable: true,
          resizable: true,
          minWidth: 80,
          comparator: agGridUtils.sortings.naturalSort,
          menuTabs: ['filterMenuTab'],
          editable: false,
          suppressMovable: true,
          valueParser: agGridUtils.parsers.defaultParser,
        },
        suppressRowClickSelection: true,
        rowSelection: 'multiple',
        singleClickEdit: true,
        processDataFromClipboard: agGridUtils.utils.processDataFromClipboard,
        processCellFromClipboard: agGridUtils.utils.processCellFromClipboard,
        getRowId: row => {
          const { data } = row;
          return data._id;
        },
        tooltipShowDelay: 0,
        rowHeight: 30,
        headerHeight: 30,
      },
      actionColDef: {
        ...agGridUtils.colDefs.action,
        menuTabs: [],
      },
      createCheckpoint: true,
      assortments: null,
      jobStatusEnums: jobStatuses,
      selectedAssortments: [],
    };
  },

  computed: {
    ...mapState('context', ['clientConfig']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapGetters('assortmentCanvas', [
      'canvases',
      'getCanvasRenderingStatus',
      'isCanvasConfigInvalidForOptimisation',
      'scenarioCheckpointsByCanvasId',
    ]),
    ...mapGetters('scenarios', ['jobStatuses', 'isJobRunning', 'cannGroups']),
    ...mapGetters('scenarioCdts', ['scenarioCdts']),
    ...mapGetters('clustering', ['selectedClusterScheme', 'noSelectedClusterScheme']),
    ...mapGetters('furniture', ['getStoreClassesInScenario']),

    headers() {
      return [
        {
          headerName: this.$tkey('cluster'),
          field: fieldEnum.clusterName,
          colId: fieldEnum.clusterName,
          cellClass: 'bold-text',
          width: 250,
          headerCheckboxSelection: this.isRowSelectable,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: () => this.isRowSelectable,
        },
        {
          headerName: this.$tkey('storeClass'),
          field: fieldEnum.storeClassName,
          colId: fieldEnum.storeClassName,
          width: 250,
        },
        {
          headerName: this.$tkey('switching'),
          field: fieldEnum.switchingType,
          colId: fieldEnum.switchingType,
          width: 200,
          valueGetter: params =>
            !params.data.clusterId || params.data.usesUnclusteredMatrix
              ? this.$tkey('unclusteredSwitching')
              : this.$tkey('clusteredSwitching'),
        },
        {
          headerName: this.$tkey('description'),
          field: fieldEnum.description,
          colId: fieldEnum.description,
          editable: this.isDescriptionEditable,
          flex: 1,
          width: 300,
          cellClassRules: {
            'invalid-not-unique': params => this.isUniqueValidation(params.data.description),
            'disabled-cell': params => params.data.generated !== jobStatuses.finished,
          },
          tooltipValueGetter: params => {
            const validationMessage = this.isUniqueValidation(params.value);
            return !isBoolean(validationMessage) ? validationMessage : null;
          },
          onCellValueChanged: params => {
            if (params.newValue !== params.oldValue) {
              const { _id } = params.data;
              const description = params.newValue || '';
              this.save({ _id, description });
            }
          },
        },
        {
          headerName: this.$tkey('generated'),
          field: fieldEnum.generated,
          colId: fieldEnum.generated,
          width: 100,
          resizable: false,
          comparator: (valueA, valueB) => {
            return valueB.localeCompare(valueA, undefined, { sensitivity: 'base' });
          },
          cellClass: 'justify-center',
          cellRenderer: 'agGridIcon',
          cellRendererParams: params => {
            if (!jobStatusesIconsMappings[params.data.generated]) {
              return {
                classes: 'v-icon-disabled',
                size: 23,
              };
            }
          },
          // Send the name of the icon using valueGetter. This is used in the component as this.params.value
          valueGetter: params =>
            jobStatusesIconsMappings[params.data.generated] || iconEnum.notStarted,
        },
        {
          headerName: this.$tkey('optimised'),
          field: fieldEnum.optimised,
          colId: fieldEnum.optimised,
          width: 100,
          resizable: false,
          comparator: (valueA, valueB) => {
            return valueB.localeCompare(valueA, undefined, { sensitivity: 'base' });
          },
          cellClass: 'justify-center',
          cellRenderer: 'agGridIcon',
          cellRendererParams: params => {
            const options = {
              isLoading: this.isCanvasBeingOptimised[params.data._id],
            };
            if (params.data.optimised) return options;
            return {
              ...options,
              size: 23,
              classes: 'v-icon-disabled',
            };
          },
          valueGetter: params => {
            return params.data.optimised
              ? jobStatusesIconsMappings[jobStatuses.finished]
              : iconEnum.notStarted;
          },
        },
        {
          field: fieldEnum.outdated,
          colId: fieldEnum.outdated,
          headerName: '',
          hide: !this.hasOutdatedCanvases,
          maxWidth: 30,
          sortable: false,
          editable: false,
          menuTabs: [],
          cellClass: 'justify-center warning-icon-wrapper',
          cellRenderer: 'agGridCellRendererComponentWrapper',
          valueGetter: params => ({
            component: 'error-triangle',
            props: {
              errors: this.getRowWarning(params),
              iconType: 'warning_amber',
              small: true,
              round: true,
            },
          }),
        },
        {
          ...this.actionColDef,
          maxWidth: 40,
          minWidth: 40,
          pinned: 'right',
          cellRenderer: 'agGridIcon',
          cellRendererParams: params => ({
            onClick: this.goToAssortmentCanvas,
            to: this.getAssortmentRoute(params.data),
            isDisabled:
              !this.canViewAssortmentCanvas || params.data.generated !== jobStatuses.finished,
            size: 28,
            classes: 'v-icon-blue',
          }),
          cellClass: 'action-column-cell',
          valueGetter: params => params.data.goToAssortmentCanvas,
        },
      ];
    },

    hasDataChanges() {
      return this.$refs.optimiserForm.hasDataChanges;
    },

    hasClusteredAssortments() {
      return some(this.assortments, a => !!a.clusterId);
    },

    isFetchCanvasesDisabled() {
      return (
        this.isAssortmentOptimisationJobRunning ||
        this.cannGroupMissingCDT ||
        !this.hasPermission(this.userPermissions.canFetchCanvases) ||
        !size(this.selectedAssortments)
      );
    },

    cannGroupMissingCDT() {
      const cdtCannGroupIds = this.scenarioCdts.filter(v => v.selected).map(v => v.cannGroupId);
      const cannGroupsMissingCdts = this.cannGroups.filter(cannGroup => {
        return !cdtCannGroupIds.includes(cannGroup.key);
      });
      return size(cannGroupsMissingCdts) > 0;
    },

    currentScenario() {
      return this.scenario || this.selectedScenario;
    },

    isCanvasGenerationJobRunning() {
      return this.isJobRunning('canvasGeneration');
    },

    isClusteredSwitchingJobRunning() {
      return this.isJobRunning('clusteredSwitching');
    },

    isAssortmentOptimisationJobRunning() {
      return this.canvases.some(canvas => {
        const canvasStatus = get(canvas, 'jobs.assortmentOptimisation.status', '').toLowerCase();

        return isEmpty(canvasStatus) ? false : !includes(jobFinishedStatuses, canvasStatus);
      });
    },

    isClScSwitchingJobRunning() {
      return this.isJobRunning('clScSwitchingMatricesCalculation');
    },

    isReportingDisabled() {
      return !this.hasPermission(this.userPermissions.canViewReportingPage);
    },

    canvasesByClusterStoreClass() {
      return keyBy(this.canvases, c => `${c.clusterId}-${c.storeClassId}`);
    },

    isCanvasBeingOptimised() {
      return this.canvases.reduce(
        (acc, c) => ({
          ...acc,
          [c._id]: isJobInProgress(get(c, 'jobs.assortmentOptimisation.status', '')),
        }),
        {}
      );
    },

    isOptimiseAllDisabled() {
      return (
        this.isClusteredSwitchingJobRunning ||
        this.isAssortmentOptimisationJobRunning ||
        this.isCanvasConfigInvalidForOptimisation ||
        !this.canvases.length ||
        !this.hasPermission(this.userPermissions.canOptimiseCanvases)
      );
    },

    isRowSelectable() {
      return (
        !this.isAssortmentOptimisationJobRunning &&
        this.hasPermission(this.userPermissions.canFetchCanvases)
      );
    },

    showReporting() {
      return get(this.clientConfig, 'features.reportingControlsEnabled');
    },

    isLoadingCanvases() {
      return (
        this.getCanvasRenderingStatus ||
        this.isCanvasGenerationJobRunning ||
        this.isClusteredSwitchingJobRunning ||
        this.isClScSwitchingJobRunning
      );
    },

    isRenderingCanvases() {
      return (
        !this.isCanvasGenerationJobRunning &&
        !this.isClScSwitchingJobRunning &&
        (this.getCanvasRenderingStatus || this.isClusteredSwitchingJobRunning)
      );
    },

    canViewAssortmentCanvas() {
      return this.hasPermission(this.userPermissions.canViewAssortmentCanvas);
    },

    reportPageRoute() {
      const firstCanvas = get(this.canvases[0], '_id', null);
      const firstCheckpoint = get(
        this.scenarioCheckpointsByCanvasId,
        [firstCanvas, 0, '_id'],
        null
      );
      return {
        path: '/reporting-main',
        query: {
          workpackageIds: [this.currentScenario.workpackageId],
          baseline: [firstCheckpoint],
          metricValue: reportingMetrics.metrics.sales,
        },
      };
    },

    hasOutdatedCanvases() {
      return some(this.canvases, 'outdated');
    },
  },

  watch: {
    isClusteredSwitchingJobRunning(newValue) {
      const clusters =
        newValue || this.noSelectedClusterScheme ? [] : this.selectedClusterScheme.clusters;
      this.assortments = this.generateAssortments(clusters);
    },

    isAssortmentOptimisationJobRunning() {
      if (!this.gridApi) return;
      this.refreshAssortments();
    },

    canvases(newValue, oldValue) {
      if (!this.gridApi || isEqual(newValue, oldValue)) return;
      this.refreshAssortments();
    },

    async isCanvasGenerationJobRunning(newValue) {
      // Make sure that the assortment data is correct if canvas generation job fails
      if (!newValue && this.jobStatuses.canvasGeneration.status === jobStatuses.failed) {
        await this.fetchCanvases();
        this.refreshAssortments();
      }
    },
  },

  async created() {
    await this.init();
  },

  methods: {
    ...mapActions('assortmentCanvas', [
      'fetchCanvas',
      'fetchCanvases',
      'fetchScenarioCheckpoints',
      'runCanvasGeneration',
      'updateAssortmentCanvas',
      'changeCanvasRenderingStatus',
      'runAssortmentOptimisation',
    ]),
    ...mapActions('workpackages', ['loadState']),
    ...mapActions('scenarios', ['loadScenario', 'updateScenario']),
    ...mapActions('scenarioCdts', ['fetchScenarioCdts']),
    ...mapActions('clustering', ['fetchScenarioClusters']),
    ...mapActions('furniture', ['fetchScenarioFurniture']),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    onFirstDataRendered() {
      if (size(this.selectedAssortments)) this.selectedAssortments = [];
      else this.selectRows(fieldEnum.generated, jobStatuses.finished);
    },

    isDescriptionEditable(params) {
      return (
        params.data.generated === jobStatuses.finished &&
        this.hasPermission(this.userPermissions.canEditCanvasDescription)
      );
    },

    isUniqueValidation(value) {
      const description = value;
      const descriptionIsDuplicated = size(filter(this.assortments, { description })) > 1;
      // Allow multiple empty values
      if (!isEmpty(value) && descriptionIsDuplicated) {
        return this.$t('validationErrors.unique', [this.$t('scenarioAssortmentPanel.description')]);
      }
      return false;
    },

    getRowWarning(params) {
      return {
        // need to negate, as evaluated false means it's outdated
        [this.$tkey('errorMessages.outdatedWarning')]: !get(params.data, 'outdated'),
      };
    },

    async init() {
      // cann groups seem to always be loaded, but if navigating to component using url cdts may be empty
      await Promise.all([
        this.fetchCanvases(),
        this.fetchScenarioCheckpoints(),
        this.fetchScenarioFurniture(),
        this.fetchScenarioClusters(),
        this.fetchScenarioCdts(),
      ]);
      // If clustered switching job is running, remove clustered assortment rows
      // If clustered switching job has finished, we must have a selected scheme to generate clustered rows
      const clusters =
        this.isClusteredSwitchingJobRunning || this.noSelectedClusterScheme
          ? []
          : this.selectedClusterScheme.clusters;
      this.assortments = this.generateAssortments(clusters);
      this.changeCanvasRenderingStatus(false);
    },

    generateAssortments(clusters) {
      const storeClasses = this.getStoreClassesInScenario;
      const unclustered = { clusterId: null, clusterName: t('unClustered', localizationKey) };

      return reduce(
        [unclustered, ...clusters],
        (acc, c) => {
          if (c.isNoDataCluster) return acc;
          const combinations = map(storeClasses, sc => {
            const key = `${c.clusterId}-${sc._id}`;
            const canvas = this.canvasesByClusterStoreClass[key];

            return {
              _id: get(canvas, '_id', key),
              clusterId: c.clusterId,
              clusterName: c.clusterName,
              storeClassId: sc._id,
              storeClassName: sc.name,
              usesUnclusteredMatrix: c.usesUnclusteredMatrix,
              description: get(canvas, 'description', ''),
              generated: canvas ? jobStatuses.finished : iconEnum.notStarted,
              optimised: get(canvas, 'hasBeenOptimised', false),
              outdated: get(canvas, 'outdated', false),
              goToAssortmentCanvas: iconEnum.goToAssortmentCanvas,
            };
          });
          acc.push(...combinations);
          return acc;
        },
        []
      );
    },

    setSelectedAssortments() {
      const selectedNodes = this.gridApi.getSelectedNodes();
      this.selectedAssortments = selectedNodes.map(node => ({
        clusterId: node.data.clusterId,
        storeClassId: node.data.storeClassId,
      }));
    },

    selectRows(column, value, equalTo = false) {
      // Select all rows by default if canvases have not been generated
      this.gridApi.forEachNode(node => {
        const selectNode = equalTo ? node.data[column] === value : node.data[column] !== value;
        node.setSelected(selectNode);
      });
    },

    async save(item) {
      if (!isBoolean(this.isUniqueValidation(item.description))) return false;

      await this.updateAssortmentCanvas({
        canvas: {
          _id: item._id,
          description: item.description,
        },
      });
    },

    async runCanvasGenerationJob() {
      const scenarioId = this.selectedScenario._id;
      await this.runCanvasGeneration({
        scenarioId,
        selectedCanvases: this.selectedAssortments,
      });
      await this.loadScenario(scenarioId);
    },

    getAssortmentRoute(row) {
      return {
        name: 'assortments-canvas',
        params: { scenarioId: this.currentScenario._id, canvasId: row._id },
      };
    },

    async goToAssortmentCanvas(row) {
      // Prevents error in ag-grid when loading state
      this.gridApi.clearFocusedCell();
      await this.loadState({ scenarioId: this.currentScenario._id, canvasId: row._id });
    },

    async initOptimisation() {
      const payload = {
        scenarioId: this.selectedScenario._id,
        canvasIds: map(this.canvases, '_id'),
        useFurniture: true,
        createCheckpoint: this.createCheckpoint,
      };

      await this.runAssortmentOptimisation(payload);
      await this.fetchCanvases();
    },

    refreshAssortments() {
      // Update assortment data with latest canvas data
      this.assortments = map(this.assortments, a => {
        const key = `${a.clusterId}-${a.storeClassId}`;
        const canvas = this.canvasesByClusterStoreClass[key];
        return {
          ...a,
          _id: get(canvas, '_id', key),
          description: get(canvas, 'description', ''),
          generated: canvas ? jobStatuses.finished : iconEnum.notStarted,
          optimised: get(canvas, 'hasBeenOptimised', false),
          outdated: get(canvas, 'outdated', false),
        };
      });

      this.gridApi.refreshCells({
        columns: [
          fieldEnum.generated,
          fieldEnum.optimised,
          fieldEnum.outdated,
          fieldEnum.description,
        ],
        force: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.assortment-header {
  font-weight: bold;
  font-size: 1.2rem;
}

.disabled-cell {
  background-color: $assortment-draggable-disabled-color;
}

.assortment {
  &__container {
    background: $assortment-input-background;
    padding: 0 auto;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: none;
  }
}

::v-deep {
  .invalid-not-unique {
    border-bottom: 2px solid $assortment-table-error-cell-border !important;
  }

  .ag-theme-custom {
    .ag-header-select-all {
      margin-right: 13px;
    }

    .ag-row-selected {
      &.ag-row-even {
        background: $assortment-table-blue-bg-colour !important;
      }

      &.ag-row-odd {
        background: $assortment-table-white-bg-colour !important;
      }

      &:before {
        content: none;
      }
    }

    .ag-cell-range-selected:not(.ag-cell-focus) {
      background-color: inherit !important;
    }

    .ag-has-focus .ag-cell-focus.ag-cell {
      padding-left: 5px;
    }
  }

  .v-icon {
    &.v-icon-blue {
      color: $assortment-action-icon-color;
    }

    &.v-icon-disabled {
      color: $assortment-radio-button-colour;
    }
  }

  .warning-icon-wrapper {
    i {
      color: $assortment-warning-alert-border;
    }
  }

  button[disabled] > span {
    color: $assortment-disabled-button-span-color;
  }
}
</style>
