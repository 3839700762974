<template>
  <v-container fluid class="scenario-step-container pa-0">
    <v-row class="tab-row">
      <v-flex>
        <div class="step-tab-scope d-flex flex-column">
          <v-tabs
            data-id-e2e="tabsStep"
            class="step-tabs"
            :value="selectedTabPosition"
            :hide-slider="true"
          >
            <v-tab
              v-for="tab in tabs"
              :key="tab.routeName"
              :disabled="tab.disabled"
              class="step-tab justify-space-between"
              :to="{
                name: tab.routeName,
                params: {
                  scenarioId: selectedScenario._id,
                },
              }"
              :replace="tab.replaceInHistory"
            >
              <span class="tab-title">{{ $t(tab.translationKey) }}</span>

              <div class="step-tab__status">
                <span v-if="tab.lastUpdated" class="tab-last-updated"
                  >{{ $t('general.updated') }}:
                  {{ tab.lastUpdated | formatDate(getDateFormats.medium) }}</span
                >
                <span v-if="tab.status && !tab.hideStatus" class="tab-icon"
                  ><v-icon v-if="showStatuses" small>${{ tab.status }}</v-icon></span
                >
              </div>
            </v-tab>
          </v-tabs>
          <router-view :key="selectedScenario._id" :data-id-e2e="`${$route.name}-tab-page`" />
        </div>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { get } from 'lodash';
import tabUtils from '../../utils/tabs/tabs-utils';

export default {
  props: {
    step: {
      required: true,
      type: String,
    },

    tabData: {
      required: true,
      type: Object,
    },

    showStatuses: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('scenarios', ['selectedScenarioStatus']),
    ...mapGetters('workpackages', ['selectedWorkpackageStatus']),
    ...mapGetters('context', ['getDateFormats', 'getClientConfig']),

    selectedTab() {
      return this.enabledTabs.find(item => item.routeName === this.$route.name);
    },

    enabledTabs() {
      return tabUtils.getAllEnabledTabs(this.tabData.ALL, this.getClientConfig);
    },

    selectedTabPosition() {
      return this.selectedTab ? this.selectedTab.position : 0;
    },

    tabs() {
      // Get the base tab data
      const tabLayoutData = tabUtils.getByStepAndType(
        this.tabData.ALL,
        this.step,
        this.selectedWorkpackage.type,
        this.getClientConfig
      );

      // Add the scenario status
      // TODO - Disabling tabs works, but not if you change scenario from the drop down.
      // Need to discuss strategy for this
      tabLayoutData.forEach(tab => {
        const statusSection =
          get(this.selectedScenarioStatus(this.selectedWorkpackage.type), tab.statusSection) ||
          get(this.selectedWorkpackageStatus, tab.statusSection);

        if (statusSection && statusSection[tab.statusSubSection]) {
          tab.lastUpdated = statusSection[tab.statusSubSection].lastModifiedDate;
          tab.status = statusSection[tab.statusSubSection].status;
          tab.disabled = !statusSection[tab.statusSubSection].available;
        } else {
          tab.lastUpdated = null;
          tab.status = null;
          tab.disabled = false;
        }
      });

      return tabLayoutData;
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.scenario-step-container {
  height: 100%;
  flex: 1;
}

.tab-row {
  height: 100%;
}

.toolbar-space {
  margin-bottom: 20px;
}
.step-tab-scope {
  height: 100%;

  .v-tabs-bar__content :first-child:not(.tab-last-updated) {
    margin-left: 0px !important;
  }

  .step-tab {
    padding: 0 10px;
    justify-content: inherit;
    text-align: inherit;
    text-transform: none;
    min-width: 200px;
    border: 0.3px solid $assortment-border-colour;
    border-left: 3px solid $assortment-border-colour;
    border-bottom: none;
    margin-left: 4px;
    font-size: 13px;
    font-weight: 900;

    .tab-last-updated {
      font-size: 11px;
      font-weight: 400;
      margin-left: 30px;
    }

    .tab-icon {
      float: right;
      margin-left: 4px;
    }
  }

  .step-tabs {
    flex: 0;
  }

  .step-tabs.theme--light.v-tabs > .v-tabs-bar {
    background-color: inherit !important;
  }

  .v-tabs-bar {
    height: 37px;
  }

  .v-tab--active {
    background-color: #ffffff;
  }

  .v-tab.v-tab {
    color: $assortment-text-colour;
  }

  .v-tabs-slider {
    background-color: #ffffff;
  }
}

.step-tab-panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  border-left: 3px solid #d3dee7 !important;

  .v-card__text {
    font-size: 1.2rem;
  }

  &.v-card:not(.v-sheet--tile) {
    border-radius: unset;
  }
}
</style>
