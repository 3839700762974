<template>
  <draggable
    class="list-group"
    ghost-class="dragged"
    draggable=".list-group__attribute"
    :group="group"
    :list="items"
    :disabled="disabled"
    :class="{ disabled }"
  >
    <div v-for="element in items" :key="element.id" class="item list-group__attribute pr-2">
      {{ element.name }}
      <v-icon size="10">$cross-move</v-icon>
    </div>
    <div v-if="showFooter" slot="footer" class="item list-group__drop">
      {{ $t('varietyGroups.dragAttrHere') }}
    </div>
  </draggable>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true },
    showFooter: { type: Boolean, default: false },
    group: {
      type: Object,
      default: () => ({
        name: 'attributes',
      }),
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.list-group {
  position: relative;
  padding-bottom: 62px;

  &.disabled {
    pointer-events: none;
    cursor: default;
  }
  .item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-height: 28px;
    padding-left: 8px;
    width: 100%;
    word-break: break-word;
  }
  &__attribute {
    cursor: move;
    margin-bottom: 5px;
    border-radius: 3px;
    color: $assortment-text-colour;
    border: 1px solid transparent;
    background-color: #e7edef;
  }
  &__drop {
    position: absolute;
    pointer-events: none;
    color: #9b9b9b;
    border: 1px dashed $assortment-primary-colour;
    background-color: rgba(231, 237, 239, 0.4);
  }
  .dragged {
    border: 1px solid $assortment-primary-colour;
  }
}
</style>
