import axios from 'axios';
import to from 'await-to-js';
// note there is no direct collision with find in this file, but there were issues at some point with collisions with vanilla js find.
import { find as _find, range, sortBy, get, size, keyBy, isEmpty, reduce, uniqBy } from 'lodash';
import i18n from '@/js/vue-i18n';
import editableTableStore from '@/js/store/utils/editable-table-store-utils';
import handleErrorMessages from '@/js/store/utils/validation';
import { jobApi } from '@/js/helpers';
import ClusterSchemeTypes from '@enums/cluster-schemes-source';

const store = {
  namespaced: true,
  state: {
    ...editableTableStore.state,
    loading: false,
    clusterSchemes: [],
    workpackageScenarioClusterSchemes: [],
    availableKMeans: range(1, 11),
    clusterSchemeInformation: {},
    isLoadingClusterInformation: false,
    isLoadingClusterSchemes: false,
    selectedScheme: null,
    comparisonSchemeId: null,
    selectedAttributeId: null,
    selectedComparisonClusterScheme: null,
    clusteringGeneratorValidation: false,
    selectableClusterNames: [], // shared state between store-allocation-list and clustering
  },

  getters: {
    ...editableTableStore.getters,

    noSelectedClusterScheme(state) {
      const selectedClusteringScheme = _find(state.clusterSchemes, { selected: true });
      return isEmpty(selectedClusteringScheme);
    },

    getScenarioAttributes(state, getters, rootState, rootGetters) {
      const attributes = rootGetters['scenarios/getCustomerFacingAndReportingAttributes'];

      return (attributes || []).map(attribute => ({ ...attribute, selected: true }));
    },

    getScenarioClusterSchemes(state) {
      return sortBy(state.clusterSchemes, ['clusterCount', '_id']);
    },

    getScenarioClusterSchemeById: state => id => state.clusterSchemes.find(el => el._id === id),

    getScenarioClusterSchemesForExport(state, getters, rootState, rootGetters) {
      // returns flat array of stores mapped to clusters and schemes, same format as would be uploaded
      // get workpackage stores to look up display keys
      const scenarioStoreMap = keyBy(rootGetters['scenarios/stores'], 'storeKey');

      // get cluster schemes sorted same as FE
      const clusterSchemes = getters.getScenarioClusterSchemes;
      const sources = [
        ClusterSchemeTypes.kmeans,
        ClusterSchemeTypes.kmeansEdited,
        ClusterSchemeTypes.upload,
        ClusterSchemeTypes.uploadEdited,
      ];

      const storeToClusterAndSchemeMappings = [];
      const badStoreKeys = [];
      clusterSchemes.forEach(scheme => {
        scheme.clusters.forEach(cluster => {
          cluster.storeKeys.forEach(storeKey => {
            const { clusterName } = cluster;
            const { name } = scheme;
            // default clusters from the reference clustering schemes could have a larger subset of stores
            if (scenarioStoreMap[storeKey] === undefined) {
              if (sources.includes(scheme.source)) {
                badStoreKeys.push(storeKey);
              }
            } else {
              const { storeKeyDisplay } = scenarioStoreMap[storeKey];
              storeToClusterAndSchemeMappings.push({ storeKeyDisplay, clusterName, name });
            }
          });
        });
      });
      if (badStoreKeys.length > 0) {
        console.log(`${i18n.t('errors.storesNotInScope')}, ${badStoreKeys.join()}`); // TODO: need to dispatch warning
      }
      return storeToClusterAndSchemeMappings;
    },

    getClusterSchemeAttributes(state, getters, rootState, rootGetters) {
      const allAttributes = rootGetters['scenarios/getCustomerFacingAndReportingAttributes'];
      const clusterSchemeAttributes = state.selectedScheme.selectedAttributeIds.map(id =>
        _find(allAttributes, { id })
      );
      return sortBy(clusterSchemeAttributes, item => item.name.toLowerCase());
    },

    getAttributeDeviations(state) {
      const attribute = _find(state.clusterSchemeInformation.deviations, {
        attributeId: state.selectedAttributeId,
      });

      return attribute ? attribute.attributeStoreDeviations : [];
    },

    selectedClusterScheme: state => {
      const selected = _find(state.clusterSchemes, scheme => {
        return scheme.selected === true && scheme.clusterCount > 1;
      });
      // if we don't have any selected scheme (i.e. clustered switching is not run), just pick the first scheme or return empty object
      return selected || get(state.clusterSchemes, 0, {});
    },

    wpScenarioClustersMap(state) {
      return reduce(
        state.workpackageScenarioClusterSchemes,
        (acc, scheme) => {
          return {
            ...acc,
            [scheme.scenarioId]: scheme.clusters,
          };
        },
        {}
      );
    },
  },

  mutations: {
    ...editableTableStore.mutations,

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setClusterSchemes(state, schemes) {
      state.clusterSchemes = schemes;
    },

    setClusterSchemeInformation(state, clusterSchemeInformation) {
      state.clusterSchemeInformation = clusterSchemeInformation;
    },

    setIsLoadingClusterInformation(state, isLoading) {
      state.isLoadingClusterInformation = isLoading;
    },

    setIsLoadingClusterSchemes(state, isLoading) {
      state.isLoadingClusterSchemes = isLoading;
    },

    setSelectedAttribute(state, selectedAttribute) {
      state.selectedAttributeId = selectedAttribute;
    },

    setSelectedScheme(state, selectedScheme) {
      state.selectedScheme = selectedScheme;
    },

    setComparisonSchemeId(state, comparisonSchemeId) {
      state.comparisonSchemeId = comparisonSchemeId;
    },

    updateSelectedAttributeId(state) {
      const selectedAttributes = get(state, 'selectedScheme.selectedAttributeIds', []);
      // if the selected scheme does not uses the currently selected attr, do not show it
      if (!selectedAttributes.includes(state.selectedAttributeId)) {
        state.selectedAttributeId = null;
      }
    },

    setWorkpackageScenarioClusterSchemes(state, wpClusterSchemes) {
      state.workpackageScenarioClusterSchemes = wpClusterSchemes;
    },

    setSelectableClusterNames(state, selectableClusterNames) {
      state.selectableClusterNames = [
        ...selectableClusterNames,
        i18n.t('clusteringPage.newCluster'),
      ];
    },

    setSelectedSchemeClusters(state, clusters) {
      state.selectedScheme.clusters = clusters;
    },
  },

  actions: {
    ...editableTableStore.actions,
    async updateClusters({ commit, dispatch, state, rootState }, updates) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const selectedClusterSchemeId = state.selectedScheme._id;
      const clusterStoreKeys = updates.map(c => c.storeKeys);
      const jobApiRes = await jobApi.runFunction('recalculate-cluster-variance-explained', {
        scenario_id: scenarioId,
        scheme_id: selectedClusterSchemeId,
        clusters: clusterStoreKeys,
      });
      const { variance } = jobApiRes;
      const clusterCount = uniqBy(updates, 'clusterName').length;
      commit('setLoading', true);
      const [err] = await to(
        axios.patch(
          `/api/scenarios/${scenarioId}/cluster-schemes/${selectedClusterSchemeId}/clusters`,
          { updates, variance, clusterCount }
        )
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      dispatch('fetchScenarioClusters');
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
    },

    // ensure selectedScheme stays in sync with clusterSchemes
    refreshSelectedScheme({ commit, state }) {
      const selectedScheme =
        _find(state.clusterSchemes, {
          selected: true,
        }) || null;

      commit('setSelectedScheme', selectedScheme);
    },

    async fetchScenarioClusters({ commit, dispatch, rootState }) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, response] = await to(axios.get(`/api/scenarios/${scenarioId}/cluster-schemes`));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      commit('setClusterSchemes', response.data);
      dispatch('refreshSelectedScheme');
      return response.data;
    },

    async fetchScenarioClustersByWorkpackage({ commit, rootState }, options = {}) {
      const workpackageId = options.workpackageId || rootState.workpackages.selectedWorkpackage._id;
      const pick = ['scenarioId', 'name', 'clusters'];
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/workpackages/${workpackageId}/clusters`, { params: { pick } })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      if (!options.shouldCommit) return response.data;
      commit('setWorkpackageScenarioClusterSchemes', response.data);
    },

    async fetchClusterSchemesFromFeed({ rootState, dispatch }) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, response] = await to(
        axios.get(`/api/scenarios/${scenarioId}/cluster-schemes/feed`, {
          params: { workpackageId },
        })
      );
      if (err) {
        const messageKey = get(err, 'response.data.messageKey', err.message);
        const messageData = get(err, 'response.data.messageParams', []);
        const errorMessage = [{ messageKey, data: messageData }];

        dispatch('snackbar/showError', i18n.t('errors.clustersFromFeed'), { root: true });
        if (size(messageData)) dispatch('alerts/showMessages', errorMessage, { root: true });

        return {};
      }
      return response.data;
    },

    async saveClusteringSchemes({ commit, dispatch, rootState }, bodyData) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      commit('setIsLoadingClusterSchemes', true);
      const [err, response] = await to(
        axios.post(`/api/scenarios/${scenarioId}/cluster-schemes`, bodyData)
      );
      const options = { displayEmpty: false };
      handleErrorMessages({ response, dispatch, options });
      // if the job failed to start due to a validation error, we need to remove failed clusters.
      // same as if clusteringJobFailed.
      if (get(response, 'data.messageType') === 'JobValidation') {
        await dispatch('removeFailedClusters', { scenarioId });
      }
      commit('setLoading', false);

      if (err) {
        throw new Error(get(err, 'message') || err);
      }
      dispatch('fetchScenarioClusters');
    },

    async getClusterSchemeData({ commit, rootState }, clusterScheme) {
      const { _id, selectedAttributeIds } = clusterScheme;
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const url = `/api/scenarios/${scenarioId}/cluster-scheme/${_id}`;
      const params = { attributeIds: selectedAttributeIds };

      commit('setIsLoadingClusterInformation', true);
      const [err, response] = await to(axios.get(url, { params }));
      commit('setIsLoadingClusterInformation', false);
      if (err) throw new Error(err.message);
      commit('setClusterSchemeInformation', response.data);
    },

    async finishClusteringJob({ commit, dispatch, rootState }, { message, translation, color }) {
      const [err, res] = await to(axios.get(`/api/scenarios/${message.scenarioId}`));
      if (err) throw new Error(err.message);
      const scenario = res.data.data;
      const scenarioIdFromEvent = get(message, 'scenarioId', null);
      const scenarioId = rootState.scenarios.selectedScenario._id;
      // do not refresh store in case user navigated away from current scenario
      if (scenarioIdFromEvent === scenarioId) {
        dispatch('fetchScenarioClusters');
      }

      const content = i18n.t(translation, { name: scenario.name });
      dispatch('snackbar/showSnackbar', { content, color }, { root: true });
      commit('setIsLoadingClusterSchemes', false);
    },

    clusteringJobComplete({ dispatch }, { message }) {
      dispatch('finishClusteringJob', {
        message,
        translation: 'notifications.clustering.finished',
        color: 'success',
      });
    },

    async clusteringJobFailed({ dispatch }, { message }) {
      // wait for removeFailedClusters before fetching clusters
      await dispatch('removeFailedClusters', message);
      dispatch('finishClusteringJob', {
        message,
        translation: 'notifications.clustering.failed',
        color: 'error',
      });
    },

    clusteredSwitchingJobComplete({ dispatch }, { message }) {
      dispatch('finishClusteringJob', {
        message,
        translation: 'notifications.clusteredSwitching.finished',
        color: 'success',
      });
    },

    clusteredSwitchingJobFailed({ dispatch }, { message }) {
      dispatch('finishClusteringJob', {
        message,
        translation: 'notifications.clusteredSwitching.failed',
        color: 'error',
      });
    },

    changeSelectedAttribute({ commit, getters }, selectedAttributeId) {
      const attributeWasSelectedInScheme = _find(getters.getClusterSchemeAttributes, {
        id: selectedAttributeId,
      });

      commit('setSelectedAttribute', attributeWasSelectedInScheme ? selectedAttributeId : null);
    },

    async removeFailedClusters({ commit }, { scenarioId }) {
      commit('setLoading', true);
      const url = `/api/scenarios/${scenarioId}/failed-cluster-schemes`;
      const [err] = await to(axios.delete(url));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
    },

    async runClusteredSwitching({ commit, rootState }, payload) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const url = `/api/scenarios/${scenarioId}/cluster-schemes/switching`;
      const [err] = await to(axios.post(url, payload));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
    },

    async processCSV({ commit, rootState, dispatch }, { fileId, mappings, delimiter }) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const body = { mappings, scenarioId, delimiter };
      const [err, { data: responseData } = {}] = await to(
        axios.post(`/api/csv/clustering/process/${fileId}`, body)
      );
      commit('setLoading', false);
      // TODO: Remove this and use global interceptor on AOV3-159
      if (err) {
        const key = get(err, 'response.data.messageKey', err.message);
        const message = i18n.t(key, get(err, 'response.data.messageParams', []));
        dispatch('snackbar/showError', message, { root: true });
        return Promise.reject(message);
      }

      if (size(responseData.messages)) {
        dispatch('alerts/showMessages', responseData.messages, { root: true });
        return {}; // AOV3-196 NOTE: should not upload anything if there are any errors.
      }

      return responseData.storeToClusterMappings;
    },
  },
};

export default store;
