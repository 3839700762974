import { render, staticRenderFns } from "./cdt-attributes-list.vue?vue&type=template&id=09cc32c2&scoped=true"
import script from "./cdt-attributes-list.vue?vue&type=script&lang=js"
export * from "./cdt-attributes-list.vue?vue&type=script&lang=js"
import style0 from "./cdt-attributes-list.vue?vue&type=style&index=0&id=09cc32c2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09cc32c2",
  null
  
)

export default component.exports