<template>
  <div class="section h-100 d-flex flex-column" :class="{ 'section-border': !shortView }">
    <slot name="header" />
    <slot name="main-section" />
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  localizationKey: 'reportingPage.sections',

  props: {
    // represents whether this report is open on (short) overview page
    shortView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.section {
  background: $reporting-section-bg-color;
}

.section-border {
  border-right: 1px solid $assortment-tab-divider-colour;
  border-bottom: 1px solid $assortment-tab-divider-colour;
  border-left: 3px solid $reporting-section-active-border;
}
</style>
