'use strict';

module.exports = {
  KEY_UP: 38,
  KEY_DOWN: 40,
  KEY_RIGHT: 39,
  KEY_LEFT: 37,
  KEY_DELETE: 46,
  KEY_BACKSPACE: 8,
};
