<template>
  <input ref="input" v-model="margin" />
</template>

<script>
import Vue from 'vue';
import { isNumber } from 'lodash';
import formatUtils from '@/js/utils/number-format-utils';
import agGridUtils from '@/js/utils/ag-grid-utils';

export default {
  data() {
    return {
      value: null,
    };
  },
  computed: {
    margin: {
      get() {
        return isNumber(this.value)
          ? formatUtils.formatNumberIfNumeric({ value: this.value * 100 })
          : this.value;
      },
      set(newValue) {
        const parsedMargin = agGridUtils.parsers.numericPermissiveParser({ newValue });
        this.value = isNumber(parsedMargin) ? parsedMargin / 100 : parsedMargin;
      },
    },
  },
  mounted() {
    let { value } = this.params;
    // Parse the string
    value = this.formatStringToNumber(value);
    this.value = value;
    Vue.nextTick(() => this.$refs.input.focus());
  },
  methods: {
    getValue() {
      return this.value;
    },
  },
};
</script>
