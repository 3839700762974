import { get, cloneDeep, camelCase } from 'lodash';
import axios from 'axios';
import to from 'await-to-js';
import contextEnum from '@enums/note-contexts';

const store = {
  namespaced: true,
  state: {
    notes: {},
  },
  getters: {
    getScenarioNotes: state => ({ scenarioId }) => {
      return state.notes[`${contextEnum.scenario}/${scenarioId}`];
    },

    getNotes: state => ({ id, context }) => state.notes[`${context}/${id}`],
  },
  mutations: {
    setNotes(state, { id, context, notes }) {
      const notesClone = { ...state.notes };
      notesClone[`${context}/${id}`] = notes;
      state.notes = notesClone;
    },
    addNote(state, { id, context, note }) {
      const notes = state.notes;
      notes[`${context}/${id}`] = [note, ...notes[`${context}/${id}`]];
      state.notes = { ...notes };
    },

    updateNote(state, { id, context, update, noteId }) {
      const notesClone = cloneDeep(state.notes);
      const updateNoteIndex = notesClone[`${context}/${id}`].findIndex(
        currentNote => noteId === currentNote._id
      );
      // No note found to update
      if (updateNoteIndex === -1) return;
      // update note
      notesClone[`${context}/${id}`][updateNoteIndex] = {
        ...notesClone[`${context}/${id}`][updateNoteIndex],
        ...update,
      };
      state.notes = notesClone;
    },

    deleteNote(state, { id, context, noteId }) {
      const notes = state.notes;
      notes[`${context}/${id}`] = notes[`${context}/${id}`].filter(
        currentNote => currentNote._id !== noteId
      );
      state.notes = { ...notes };
    },
  },
  actions: {
    async fetchNotes({ commit }, { params }) {
      const { id, context } = params;

      const [err, res] = await to(axios.get(`/api/notes/${context}/${id}`));
      if (err) throw new Error(err.message);

      // Sort by date
      const notes = get(res, 'data', []);

      commit('setNotes', { id, context, notes });
    },

    async fetchCannGroupNotes({ commit }, { params }) {
      const { id, scenarioId, context } = params;

      const [err, res] = await to(axios.get(`/api/notes/${context}/${scenarioId}/${id}`));
      if (err) throw new Error(err.message);

      const notes = get(res, 'data', []);

      commit('setNotes', { id, context, notes });
    },

    async postNote({ commit }, { params, options = {} }) {
      const { message, id, context, workpackageId, scenarioId } = params;
      const { storeToUpdate = context, mutationName = 'updateNoteCount' } = options;

      const body = { message };

      if (workpackageId) body.workpackageId = workpackageId;
      if (scenarioId) body.scenarioId = scenarioId;

      const [err, res] = await to(axios.post(`/api/notes/${context}/${id}`, body));
      const note = get(res, 'data');

      if (err) throw new Error(err.message);
      commit('addNote', { id, context, note });
      commit(
        `${storeToUpdate}s/${mutationName}`,
        { [`${camelCase(context)}Id`]: id },
        { root: true }
      );
    },

    async updateNote({ commit }, { params }) {
      const { message, context, id, noteId } = params;
      const body = { message };
      const [err] = await to(axios.patch(`/api/notes/${noteId}`, body));
      if (err) throw new Error(err.message);
      commit('updateNote', { id, context, update: body, noteId });
    },

    async deleteNote({ commit }, { params, options = {} }) {
      const { context, noteId, id } = params;
      const { storeToUpdate = context, mutationName = 'updateNoteCount' } = options;
      const [err] = await to(axios.delete(`/api/notes/${noteId}`));
      if (err) throw new Error(err.message);
      commit('deleteNote', { id, context, noteId });
      commit(
        `${storeToUpdate}s/${mutationName}`,
        { [`${camelCase(context)}Id`]: id, difference: -1 },
        { root: true }
      );
    },
  },
};

export default store;
