<template>
  <div class="assortment-groups-tab pa-2">
    <v-row no-gutters class="mb-3">
      <v-col :cols="8" class="available-ags pr-3 pb-2">
        <h3 class="pt-1 pb-3">{{ $tkey('availableAGs') }}</h3>
        <div class="available-ags__wrapper">
          <v-row no-gutters>
            <v-col :cols="8" class="available-ags__search">
              <div class="search">
                <rtls-search
                  v-model="searchString"
                  :placeholder="$t('workpackagePage.scope.searchLabel')"
                  @input="gridOptions.api.onFilterChanged()"
                />
              </div>
            </v-col>
            <v-col v-if="hasTemplateAvailable" class="available-ags__toggle">
              <v-btn-toggle
                v-model="isTemplateOnly"
                mandatory
                @change="gridOptions.api.onFilterChanged()"
              >
                <v-btn :value="false">
                  {{ $tkey('toggle.all') }}
                </v-btn>
                <v-btn :value="true">
                  {{ $tkey('toggle.templated') }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <div class="ag-grid-box flex-grow-1">
            <ag-grid-vue
              style="width: 100%; height: 360px;"
              class="ag-theme-custom ag-theme-custom--attributes"
              auto-params-refresh
              data-id-e2e="listAvailableAssortmentGroups"
              :column-defs="columnDefs"
              :row-data="assortmentGroups"
              :does-external-filter-pass="doesExternalFilterPass"
              :grid-options="gridOptions"
              :stop-editing-when-cells-loses-focus="true"
              :enable-range-selection="true"
              @grid-ready="onGridReady"
            />
          </div>
        </div>
      </v-col>
      <v-col class="pl-3 pb-2">
        <v-row no-gutters>
          <v-col>
            <h3>{{ $tkey('analysisOnly') }}</h3>
          </v-col>
          <v-col class="text-right">
            <v-btn
              class="assortment-groups-tab__link"
              text
              small
              :disabled="selectedAssortmentGroupsForAnalysisOnly.length === 0"
              @click="unselectAllAnalysis"
            >
              <v-icon size="16">mdi-close</v-icon>
              <span>{{ $t('actions.clearAll') }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <div class="grid-wrapper">
          <div class="grid" data-id-e2e="listSelectedAssortmentGroupsForAnalysisOnly">
            <div
              v-for="ag in selectedAssortmentGroupsForAnalysisOnly"
              :key="ag.key"
              class="display-contents"
            >
              <div class="item trimmed-text">{{ ag.text }}</div>
              <div class="item item__icon">
                <v-btn x-small icon @click="unselectAnalysis([ag.key])">
                  <v-icon size="20">$trash</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>

        <v-row no-gutters>
          <v-col>
            <h3>{{ $tkey('includeForAssortment') }}</h3>
          </v-col>
          <v-col class="text-right">
            <v-btn
              class="assortment-groups-tab__link"
              text
              small
              :disabled="selectedAssortmentGroupsForAssortment.length === 0"
              @click="unselectAllAssortment"
            >
              <v-icon size="16">mdi-close</v-icon>
              <span>{{ $t('actions.clearAll') }}</span>
            </v-btn>
          </v-col>
        </v-row>
        <div class="grid-wrapper">
          <div class="grid" data-id-e2e="listSelectedAssortmentGroupsForAssortment">
            <div
              v-for="ag in selectedAssortmentGroupsForAssortment"
              :key="ag.key"
              class="display-contents"
            >
              <div class="item trimmed-text">{{ ag.text }}</div>
              <div class="item item__icon">
                <v-btn x-small icon @click="unselectAssortment([ag.key])">
                  <v-icon size="20">$trash</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <v-row no-gutters>
          <v-col>
            <h3 class="mb-2">
              {{ $t('workpackagePage.scope.productsAndStoresLabel') }}
            </h3>
            <p class="assortment-groups-tab__text mb-2">
              {{ $t('workpackagePage.scope.importProductDataLabel') }}:
            </p>
            <import-from-planogram v-if="datesLoaded" :custom-workpackage="customWorkpackage" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-divider />

    <v-row class="mt-3" no-gutters>
      <v-col v-if="isSplitWorkpackagesEnabled && !isTemplate" class="split-column pr-2">
        <wp-scope-filters />
      </v-col>
      <v-col :class="{ 'pl-2': isSplitWorkpackagesEnabled }" class="products-column pr-2">
        <wp-scope-products-new />
      </v-col>
      <v-col class="pl-2">
        <wp-scope-stores-new />
      </v-col>
      <v-col>&nbsp;</v-col>
    </v-row>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { get, isArray, keyBy } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import agGridUtils from '@/js/utils/ag-grid-utils';

export default {
  localizationKey: 'workpackagePage.scope.assortmentGroups',

  components: {
    AgGridVue,
  },

  props: {
    snapshotDate: {
      type: String,
      required: true,
    },
    workpackage: {
      type: Object,
      required: true,
      default: null,
    },
    customWorkpackage: {
      type: Object,
      required: true,
    },
    datesLoaded: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      searchString: null,
      isTemplateOnly: false,
      assortmentGroups: [],
      gridApi: null,
      columnApi: null,
    };
  },

  computed: {
    ...mapState('context', ['clientConfig']),
    ...mapState('assortmentGroups', ['masterAssortmentGroups']),
    ...mapGetters('workpackages', ['templatesById']),

    categoriesField() {
      return get(
        this.clientConfig,
        'assortmentGroups.filterType',
        'associatedProductCategoryDescriptions'
      );
    },

    isTemplate() {
      return !!this.workpackage.isTemplate;
    },

    isSplitWorkpackagesEnabled() {
      return get(this.clientConfig, 'features.splitWorkpackagesEnabled');
    },

    gridOptions() {
      return {
        suppressContextMenu: true,
        enableFillHandle: true,
        isExternalFilterPresent: () => !!this.searchString || this.isTemplateOnly,
        defaultColDef: {
          filter: true,
          sortable: true,
          resizable: true,
          comparator: agGridUtils.sortings.naturalSort,
          editable: false,
          menuTabs: ['filterMenuTab'],
          suppressMovable: true,
          minWidth: 80,
          flex: 1,
          cellClass: 'aligned-start priority-cell',
          cellClassRules: {
            'diff-background': this.hasDiff,
            'invalid-nonempty': agGridUtils.validations.validateValueExists,
          },
          suppressKeyboardEvent: agGridUtils.utils.suppressKeyboardEvent,
        },
        rowHeight: 30,
        headerHeight: 40,
        processCellFromClipboard: agGridUtils.utils.processCellFromClipboard,
        getRowId: ag => ag.data._id,
      };
    },

    columnDefs() {
      return [
        {
          headerName: this.$tkey('table.furnitureCategory'),
          field: 'text',
          width: 100,
        },
        {
          headerName: this.$tkey('table.category'),
          field: this.categoriesField,
          width: 100,
        },
        {
          hide: !this.hasTemplateAvailable,
          headerName: this.$tkey('table.fromTemplate'),
          field: 'isFromTemplate',
          resizable: false,
          maxWidth: 100,
          cellClass: 'justified-center',
          headerClass: 'justified-center',
          menuTabs: [],
          cellRenderer: params =>
            agGridUtils.utils.customIconRenderer(params, {
              icon: params.data.isFromTemplate ? 'check' : 'close',
              classes: params.data.isFromTemplate ? 'from-template' : 'not-from-template',
              isDisplayed: true,
            }),
        },
        {
          headerName: this.$tkey('table.analysis'),
          field: 'analysis',
          resizable: false,
          maxWidth: 100,
          editable: this.hasPermission(this.userPermissions.canEditWorkpackageScope),
          valueParser: agGridUtils.parsers.booleanParser,
          cellRenderer: params =>
            agGridUtils.utils.checkboxRenderer(params, this.handleCheckboxChange),
          cellRendererParams: {
            field: 'analysis',
          },
          onCellValueChanged: params => this.onCheckboxValueChanged(params),
          cellClass: 'justified-center',
          headerClass: 'justified-center',
          menuTabs: [],
        },
        {
          headerName: this.$tkey('table.assortment'),
          field: 'assortment',
          resizable: false,
          maxWidth: 100,
          editable: this.hasPermission(this.userPermissions.canEditWorkpackageScope),
          valueParser: agGridUtils.parsers.booleanParser,
          cellRenderer: params =>
            agGridUtils.utils.checkboxRenderer(params, this.handleCheckboxChange),
          cellRendererParams: {
            field: 'assortment',
          },
          onCellValueChanged: params => this.onCheckboxValueChanged(params),
          cellClass: 'justified-center e2e-chk-ag-for-assortment',
          headerClass: 'justified-center',
          menuTabs: [],
        },
      ];
    },

    hasTemplateAvailable() {
      return this.templatesEnabled && !!get(this.workpackage, 'templateId', null);
    },

    assortmentGroupsFromTemplate() {
      if (!this.workpackage)
        return { staticAssortmentGroupKeys: new Set([]), assortmentGroupsSelectedFromTemplate: [] };
      const assortmentGroupsSelectedFromTemplate = get(
        this.templatesById,
        [this.workpackage.templateId, 'assortmentGroups'],
        []
      );
      const staticAssortmentGroupKeys = new Set(
        assortmentGroupsSelectedFromTemplate.map(ag => ag.staticAssortmentGroupKey)
      );
      return {
        staticAssortmentGroupKeys,
        assortmentGroupsSelectedFromTemplate,
      };
    },

    selectedAssortmentGroupsByKey() {
      return keyBy(this.workpackage.selectedAssortmentGroupSettings || [], 'key');
    },

    selectedAssortmentGroups() {
      return this.assortmentGroups.filter(ag => ag.analysis || ag.assortment);
    },

    selectedAssortmentGroupsForAnalysisOnly() {
      return this.assortmentGroups.filter(ag => ag.analysis && !ag.assortment);
    },

    selectedAssortmentGroupsForAssortment() {
      return this.assortmentGroups.filter(ag => ag.assortment);
    },
  },

  watch: {
    async snapshotDate() {
      await this.fetchAssortmentGroups(this.snapshotDate);
      this.populateAssortmentGroups();
    },
  },

  async created() {
    await this.fetchAssortmentGroups(this.snapshotDate);
    this.populateAssortmentGroups();
  },

  methods: {
    ...mapActions('assortmentGroups', ['fetchAssortmentGroups']),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },

    populateAssortmentGroups() {
      const { staticAssortmentGroupKeys } = this.assortmentGroupsFromTemplate;

      this.assortmentGroups = this.masterAssortmentGroups.map(ag => {
        let agSettings = { analysis: false, assortment: false };

        if (this.selectedAssortmentGroupsByKey[ag.key]) {
          const { analysis, assortment } = this.selectedAssortmentGroupsByKey[ag.key];
          agSettings = { analysis, assortment };
        }

        return {
          ...ag,
          ...agSettings,
          isFromTemplate: staticAssortmentGroupKeys.has(ag.staticAssortmentGroupKey),
        };
      });

      this.$emit('update-assortment-groups', this.selectedAssortmentGroups);
    },

    handleCheckboxChange(params) {
      params.setValue(!params.value);
    },

    doesExternalFilterPass(node) {
      let passTemplateFilter = true;
      if (this.hasTemplateAvailable && this.isTemplateOnly) {
        passTemplateFilter = node.data.isFromTemplate === true;
      }

      let passSearchFilter = true;
      if (this.searchString) {
        let { text } = node.data;
        let categories = node.data[this.categoriesField];

        text = agGridUtils.filters.standardStringFilter.textFormatter(text);
        if (isArray(categories)) {
          categories = categories.join(', ');
        }
        categories = agGridUtils.filters.standardStringFilter.textFormatter(categories);

        const searchString = agGridUtils.filters.standardStringFilter.textFormatter(
          this.searchString
        );
        passSearchFilter =
          text.indexOf(searchString) !== -1 || categories.indexOf(searchString) !== -1;
      }

      return passTemplateFilter && passSearchFilter;
    },

    onCheckboxValueChanged(params) {
      // Ticking the ‘assortment’ box should automatically tick the analysis
      if (params.colDef.field === 'assortment' && params.newValue) {
        params.data.analysis = true;
        this.gridApi.refreshCells({ columns: ['analysis'] });
      }
      this.$emit('update-assortment-groups', this.selectedAssortmentGroups);
    },

    unselectAnalysis(keys) {
      const updates = [];
      const agsToUpdate = this.assortmentGroups.filter(ag => keys.includes(ag.key));

      agsToUpdate.forEach(ag => {
        ag.analysis = false;
        updates.push(ag);
      });
      this.gridApi.applyTransaction({ update: updates });

      this.$emit('update-assortment-groups', this.selectedAssortmentGroups);
    },

    unselectAllAnalysis() {
      const keys = this.selectedAssortmentGroupsForAnalysisOnly.map(ag => ag.key);
      this.unselectAnalysis(keys);
    },

    unselectAssortment(keys) {
      const updates = [];
      const agsToUpdate = this.assortmentGroups.filter(ag => keys.includes(ag.key));

      agsToUpdate.forEach(ag => {
        ag.assortment = false;
        updates.push(ag);
      });
      this.gridApi.applyTransaction({ update: updates });

      this.$emit('update-assortment-groups', this.selectedAssortmentGroups);
    },

    unselectAllAssortment() {
      const keys = this.selectedAssortmentGroupsForAssortment.map(ag => ag.key);
      this.unselectAssortment(keys);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.assortment-groups-tab {
  background-color: $assortment-workpackage-frame-bg-blue;
  color: $assortment-workpackage-text;

  .available-ags {
    border-right: 1px solid $assortment-tab-divider-colour;

    &__wrapper {
      background-color: $assortment-workpackage-bg-colour;
    }

    &__search,
    &__toggle {
      padding: 4px 8px;
    }
  }

  .products-column,
  .split-column {
    border-right: 1px solid $assortment-tab-divider-colour;
  }

  &__text {
    font-size: 12px;
  }

  .assortment-groups-tab__link {
    color: $assortment-primary-colour;
    padding: 0 !important;
    &::before,
    &::after {
      display: none !important;
    }

    span {
      text-decoration: underline;
    }
  }

  .grid-wrapper {
    height: 140px;
    overflow: auto;
    margin: 8px 0;
    background-color: $assortment-table-header-white-background;

    .grid {
      display: grid;
      grid-template-columns: 1fr 32px;
      grid-gap: 1px;
      background-color: $assortment-workpackage-frame-bg-blue;
      border-bottom: 1px solid $assortment-workpackage-frame-bg-blue;

      .display-contents {
        display: contents;
      }

      .item {
        padding: 4px 8px;
        background-color: $assortment-table-header-white-background;
        line-height: 16px;
        font-size: 12px;

        &__icon {
          padding-top: 2px;
          padding-bottom: 0;
        }
      }
    }
  }
}

::v-deep {
  .from-template {
    color: $assortment-workpackage-green-mid;
  }
  .not-from-template {
    color: $assortment-workpackage-disabled;
  }

  .ag-theme-custom .ag-row-even {
    background: inherit;
  }

  .ag-theme-custom
    .ag-cell.ag-cell-not-inline-editing.ag-cell-value.ag-cell-focus.ag-cell-range-single-cell {
    padding-left: 0 !important;
  }
}
</style>
