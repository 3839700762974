<template>
  <v-dialog :value="value" width="700px" @click:outside="closeModal">
    <dialog-card
      v-if="value"
      :title="shouldSubCluster ? $tkey('subClusteringTitle') : $tkey('generationTitle')"
      @close="closeModal"
    >
      <v-container fluid style="max-height: 500px; padding: 0">
        <v-row>
          <!-- Left side section -->
          <v-col cols="6" class="rtls-border rtls-border--right pl-0 mt-2">
            <v-form v-model="valid">
              <!-- Clustering scheme generation -->
              <div v-if="!shouldSubCluster" data-id-e2e="clustering-scheme-generation-wrapper">
                <v-row>
                  <span class="font-weight-bold">{{ $tkey('methodology') }}</span>
                </v-row>

                <!-- Manual -->
                <v-row class="mt-3 mb-2">
                  <v-container fluid class="mx-0 pa-0 pt-2">
                    <v-row>
                      <v-checkbox
                        data-id-e2e="toggleClusterSchemeSourceUploadCheckbox"
                        :input-value="clusteringSchemeSourceSelections[clusterSchemeTypes.upload]"
                        class="rtls-checkbox mx-2"
                        @change="onToggleManualUploadSelected"
                      />
                      <span class="mr-4 align-self-center">{{ $tkey('manualUpload') }}</span>
                      <data-upload
                        v-if="clusteringSchemeSourceSelections[clusterSchemeTypes.upload]"
                        :legends="csvUploadLegends"
                        :csv-upload-handler="onCSVUpload"
                        @process="process"
                      />
                    </v-row>
                    <v-row v-if="clusteringSchemeSourceSelections[clusterSchemeTypes.upload]">
                      <!-- need data-table for loading state -->
                      <v-data-table
                        v-if="hasCSVUploadedData"
                        class="schemes-table my-2"
                        :loading="loading"
                        :headers="kmeansTableHeaders"
                        max-height="500px"
                        dense
                        disable-pagination
                        disable-sort
                        hide-default-footer
                        hide-default-header
                        fixed-header
                      >
                        <template v-slot:header>
                          <thead>
                            <tr>
                              <th
                                v-for="header in kmeansTableHeaders"
                                :key="`upload-${header.id}`"
                                :class="header.headerClass"
                              >
                                {{ header.text }}
                              </th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:body>
                          <tbody>
                            <tr
                              v-for="(clusteringScheme, rowIndex) in getTableRows"
                              :key="rowIndex"
                            >
                              <td
                                v-for="header in kmeansTableHeaders"
                                :key="`upload-${header.id}-row-${rowIndex}`"
                                :class="header.cellClass"
                              >
                                {{ clusteringScheme[header.value] }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-row>
                  </v-container>
                </v-row>

                <v-divider horizontal />

                <!-- K-Mean -->
                <v-row class="my-2 py-2">
                  <v-checkbox
                    data-id-e2e="toggleClusterSchemeSourceKmeansCheckbox"
                    :input-value="clusteringSchemeSourceSelections[clusterSchemeTypes.kmeans]"
                    :disabled="shouldSubCluster"
                    class="rtls-checkbox mx-2"
                    @change="onToggleKMeansSelected"
                  />
                  <span class="align-self-center">{{ $tkey('kmeanOption') }}</span>
                </v-row>
                <template v-if="clusteringSchemeSourceSelections[clusterSchemeTypes.kmeans]">
                  <v-row class="my-2">
                    <span>{{ $tkey('noOfClustersLabel') }}</span>
                    <rtls-select
                      v-model="numberOfSchemes"
                      class="my-2"
                      :items="availableKMeans"
                      :placeholder="$t('general.select')"
                      @change="onSelectionOfSchemeNumber"
                    />
                  </v-row>
                  <v-row v-if="hasKmeansData" class="my-2">
                    <v-form v-model="validNewKmeansTable">
                      <v-simple-table class="schemes-table" max-height="500px" dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th
                                v-for="header in kmeansTableHeaders"
                                :key="header.id"
                                :class="header.headerClass"
                              >
                                {{ header.text }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(clusteringScheme, rowIndex) in dynamicClusterSchemes"
                              :key="rowIndex"
                            >
                              <td
                                v-for="header in kmeansTableHeaders"
                                :key="`${header.id}-row-${rowIndex}`"
                                :class="header.cellClass"
                              >
                                <rtls-text-field
                                  v-if="header.ui === CELL_TYPE.input"
                                  v-model="clusteringScheme[header.value]"
                                  :rules="header.rules"
                                  :server-error="header.serverRule(clusteringScheme)"
                                  @input="
                                    header.onInput ? header.onInput(clusteringScheme, $event) : null
                                  "
                                />
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-form>
                  </v-row>
                </template>

                <v-divider horizontal />

                <!-- From feed -->
                <v-row v-if="hasClusteringFromDataEnabled" class="my-2 py-2">
                  <v-container fluid class="mx-0 pa-0">
                    <v-row>
                      <v-checkbox
                        data-id-e2e="toggleClusterSchemeSourceFeedCheckbox"
                        :input-value="clusteringSchemeSourceSelections[clusterSchemeTypes.feed]"
                        class="rtls-checkbox mx-2"
                        @change="onToggleFeedSelected"
                      />
                      <span class="align-self-center">{{ $tkey('feedOption') }}</span>
                    </v-row>
                    <v-row v-if="clusteringSchemeSourceSelections[clusterSchemeTypes.feed]">
                      <!-- need data-table for loading state -->
                      <v-data-table
                        v-if="hasFeedData"
                        class="schemes-table my-2"
                        :loading="loadingFromFeed"
                        :headers="kmeansTableHeaders"
                        max-height="500px"
                        dense
                        disable-pagination
                        disable-sort
                        hide-default-footer
                        hide-default-header
                        fixed-header
                      >
                        <template v-slot:header>
                          <thead>
                            <tr>
                              <th
                                v-for="header in kmeansTableHeaders"
                                :key="`feed-${header.id}`"
                                :class="header.headerClass"
                              >
                                {{ header.text }}
                              </th>
                            </tr>
                          </thead>
                        </template>
                        <template v-slot:body>
                          <tbody>
                            <tr
                              v-for="(clusteringScheme, rowIndex) in clusterSchemesFromFeed"
                              :key="rowIndex"
                            >
                              <td
                                v-for="header in kmeansTableHeaders"
                                :key="`feed-${header.id}-row-${rowIndex}`"
                                :class="header.cellClass"
                              >
                                <rtls-text-field
                                  v-if="header.ui === CELL_TYPE.input"
                                  :value="clusteringScheme[header.value]"
                                  :server-error="header.serverRule(clusteringScheme)"
                                  readonly
                                />
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-data-table>
                    </v-row>
                  </v-container>
                </v-row>

                <!-- Default clusters from the reference cluster schemes -->
                <v-divider v-if="hasDefaultClustersEnabled" horizontal />

                <v-row v-if="hasDefaultClustersEnabled" class="my-2">
                  <v-container fluid class="mx-0 px-0">
                    <v-row>
                      <v-checkbox
                        data-id-e2e="toggleClusterSchemeSourceDefaultCheckbox"
                        :input-value="clusteringSchemeSourceSelections[clusterSchemeTypes.default]"
                        class="rtls-checkbox mx-2"
                        @change="onToggleDefaultClustersSelected"
                      />
                      <span>{{ $tkey('defaultClusters') }}</span>
                    </v-row>
                    <v-row
                      v-if="clusteringSchemeSourceSelections[clusterSchemeTypes.default]"
                      class="my-2"
                    >
                      <span class="w-100">{{ $tkey('selectDefaultScheme') }}</span>
                      <div class="d-flex flex-grow-1">
                        <rtls-select
                          class="my-2"
                          :items="referenceClusterSchemes"
                          item-text="name"
                          item-value="value"
                          :placeholder="$t('general.select')"
                          @change="setSelectedReferenceClusterScheme"
                        />
                        <error-triangle class="ml-1" :errors="referenceClusterSchemeErrors" />
                      </div>
                    </v-row>
                    <v-row v-if="clusteringSchemeSourceSelections[clusterSchemeTypes.default]">
                      <span>{{ $tkey('missingDefaultClustersMessage') }}</span>
                    </v-row>
                  </v-container>
                </v-row>
              </div>

              <!-- Sub-clustering -->
              <div v-else data-id-e2e="sub-clustering-wrapper">
                <v-row class="d-flex align-center">
                  <v-col class="col-4 pa-0 d-flex justify-end">
                    <span class="text-right">{{ $tkey('clusteringSchemeLabel') }}:</span>
                  </v-col>
                  <v-col class="info-note">{{ selectedScheme.name }}</v-col>
                </v-row>
                <v-row class="d-flex align-center">
                  <v-col class="col-4 pa-0 d-flex justify-end">
                    <span class="text-right">{{ $tkey('clusterLabel') }}</span>
                  </v-col>
                  <v-col class="info-note">{{ subClusterFrom.clusterName }}</v-col>
                </v-row>
                <v-row class="d-flex align-center">
                  <v-col class="col-4 pa-0 d-flex justify-end">
                    <span class="text-right">{{ $tkey('methodology') }}:</span>
                  </v-col>
                  <v-col class="info-note">
                    <v-row>
                      <v-radio-group
                        v-if="hasDefaultClustersEnabled"
                        v-model="selectedSubClusteringMethodology"
                        :disabled="false"
                        hide-details
                        @change="onSubClusteringMethodologyChanged"
                      >
                        <v-radio
                          v-for="methodology in subClusteringOptions"
                          :key="methodology.value"
                          class="sub-clustering__label"
                          :ripple="false"
                          :label="methodology.text"
                          :value="methodology.value"
                        />
                      </v-radio-group>
                      <span v-else>{{ $tkey('kmeanOption') }}</span>
                    </v-row>
                  </v-col>
                </v-row>

                <v-form v-model="validSubClusteringScheme">
                  <!-- Sub clustering kmeans -->
                  <div v-if="selectedSubClusteringMethodology === clusterSchemeTypes.kmeans">
                    <v-row class="d-flex align-center">
                      <v-col class="col-4 pa-0 d-flex justify-end">
                        <span class="text-right">{{ $tkey('noOfSubClustersLabel') }}</span>
                      </v-col>
                      <v-col>
                        <rtls-text-field
                          v-model="newSubClusteringScheme[clusterCountInput.value]"
                          :rules="clusterCountInput.rules"
                          :server-error="clusterCountInput.serverRule(newSubClusteringScheme)"
                          @input="clusterCountInput.onInput(newSubClusteringScheme, $event)"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="d-flex align-center">
                      <v-col class="col-4 pa-0 d-flex justify-end">
                        <span class="text-right">{{ $tkey('newClusteringSchemeLabel') }}</span>
                      </v-col>
                      <v-col>
                        <rtls-text-field
                          v-model="newSubClusteringScheme[schemeNameInput.value]"
                          :rules="schemeNameInput.rules"
                          :server-error="schemeNameInput.serverRule(newSubClusteringScheme)"
                        />
                      </v-col>
                    </v-row>
                  </div>

                  <!-- Sub clustering default -->
                  <v-row
                    v-if="
                      hasDefaultClustersEnabled &&
                        selectedSubClusteringMethodology === clusterSchemeTypes.default
                    "
                  >
                    <v-col class="col-4 pr-0 d-flex justify-end">
                      <span class="text-right mt-3">
                        {{ `${$t('clusteringPage.defaultClusteringScheme')}:` }}
                      </span>
                    </v-col>
                    <v-col>
                      <div class="d-flex">
                        <rtls-select
                          class="my-2"
                          :items="referenceClusterSchemes"
                          item-text="name"
                          item-value="value"
                          :placeholder="$t('general.select')"
                          @change="setSelectedReferenceSubClusterScheme"
                        />
                        <error-triangle class="ml-1" :errors="referenceClusterSchemeErrors" />
                      </div>
                      <span class="mr-3 d-block">{{ $tkey('missingDefaultClustersMessage') }}</span>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-form>
          </v-col>

          <!-- Right side section -->
          <v-col cols="6" class="pr-0 mt-2">
            <v-row>
              <span class="font-weight-bold">
                {{ $tkey('attributes') }}
              </span>
            </v-row>
            <v-row
              v-for="attribute in selectedAttributes"
              :key="attribute._id"
              class="attributes-row"
            >
              <v-checkbox
                :input-value="attribute.selected"
                class="rtls-checkbox mx-2"
                @change="attribute.selected = $event"
              />
              <span>{{ attribute.name }}</span>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

      <!-- Page actions -->
      <template v-slot:footer>
        <page-actions
          :has-data-errors="hasDataErrors"
          :has-data-changes="true"
          :show-discard="false"
          :save-btn-text="saveBtnText"
          @save="onSave"
        >
          <template v-slot:right-btns>
            <v-btn class="text-outline" text link @click="closeModal">
              {{ $t('actions.cancel') }}
            </v-btn>
          </template>
        </page-actions>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import to from 'await-to-js';
import { mapGetters, mapState, mapActions } from 'vuex';
import {
  cloneDeep,
  find,
  filter,
  isEqual,
  merge,
  pick,
  some,
  sortBy,
  times,
  forEach,
  isEmpty,
  toNumber,
  isNaN,
  get,
  trim,
  map,
  every,
  keys,
  reduce,
  difference,
  includes,
  values,
} from 'lodash';
import { CELL_TYPE } from '@enums/table/index';
import ClusterSchemeTypes from '@enums/cluster-schemes-source';
import inputValidationMixin from '../../../mixins/input-validations';
import TableUtils from '../../../utils/table-utils';

export default {
  name: 'ClusterGenerationModal',
  mixins: [inputValidationMixin],
  localizationKey: 'clusteringPage.dialog',
  props: {
    value: {
      type: Boolean,
      required: true,
    },

    subClusterFrom: {
      type: Object,
      required: false,
      default: () => null,
    },
  },

  data() {
    return {
      times,
      TableUtils,
      CELL_TYPE,
      loading: false,
      loadingFromFeed: false,
      numberOfSchemes: null,
      newSubClusteringScheme: {},
      dynamicClusterSchemes: [],
      selectedReferenceClusterScheme: null,
      selectedAttributes: [],
      clusterSchemeTypes: {
        upload: ClusterSchemeTypes.upload,
        kmeans: ClusterSchemeTypes.kmeans,
        feed: ClusterSchemeTypes.feed,
        default: ClusterSchemeTypes.default,
      },
      clusteringSchemeSourceSelections: {
        [ClusterSchemeTypes.upload]: false,
        [ClusterSchemeTypes.kmeans]: false,
        [ClusterSchemeTypes.feed]: false,
        [ClusterSchemeTypes.default]: false,
      },
      subClusteringOptions: [
        { value: ClusterSchemeTypes.kmeans, text: this.$tkey('kmeanOption') },
        { value: ClusterSchemeTypes.default, text: this.$tkey('defaultClusters') },
      ],
      selectedSubClusteringMethodology: ClusterSchemeTypes.kmeans,
      schemeNameInput: {
        id: 'schemeName',
        value: 'name',
        headerClass: '.cluster-name',
        cellClass: 'pr-0',
        ui: CELL_TYPE.input,
        text: this.$tkey('clusteringSchemeLabel'),
        rules: [this.required],
        serverRule: this.hasUniqueName,
      },
      clusterCountInput: {
        id: 'schemeClusterCount',
        value: 'clusterCount',
        headerClass: '.cluster-count',
        ui: CELL_TYPE.input,
        text: this.$tkey('noOfClusterHeader'),
        rules: [this.required, this.isInteger, this.isClustered],
        serverRule: this.hasValidClusterCount,
        onInput: this.updateClusterCount,
      },
      validNewKmeansTable: false,
      validSubClusteringScheme: false,
      csvUploadLegends: {
        buttonName: this.$t('actions.manualImport'),
        title: this.$tkey('importClusteringSchemesTitle'),
      },
      csvUploadedClusterSchemes: [],
      clusterSchemesFromFeed: [],
      kmeansTableHeaders: [],
      valid: true,
    };
  },

  computed: {
    // clusterSchemes is saved data in mongo, dynamicClusterSchemes is data in the table
    ...mapState('clustering', ['availableKMeans', 'clusterSchemes', 'selectedScheme']),
    ...mapState('referenceClustering', {
      referenceClusterSchemes: 'validClusterSchemes',
    }),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapState('furniture', ['workpackageFurniture']),
    ...mapGetters('clustering', ['getScenarioAttributes', 'getTableRows']),
    ...mapGetters('context', ['getClientConfig']),

    hasDefaultClustersEnabled() {
      return get(this.getClientConfig, 'features.defaultClustersEnabled', false);
    },

    shouldSubCluster() {
      return !isEmpty(this.subClusterFrom);
    },

    hasCSVUploadedData() {
      return !isEmpty(this.getTableRows) || this.loading;
    },

    hasKmeansData() {
      return !isEmpty(this.dynamicClusterSchemes);
    },

    hasFeedData() {
      return !isEmpty(this.clusterSchemesFromFeed);
    },

    hasDefaultClusterData() {
      return !isEmpty(this.selectedReferenceClusterScheme);
    },

    saveBtnText() {
      return this.shouldSubCluster ? this.$t('create') : this.$t('fetchClusteringSchemes');
    },

    subClusterStoreKeys() {
      return this.shouldSubCluster ? new Set(get(this.subClusterFrom, 'storeKeys', [])) : new Set();
    },

    hasDataErrors() {
      // user should have at least one methodology selected
      const hasNoMethodologySelected = every(this.clusteringSchemeSourceSelections, cs => !cs);
      const hasSomeAttributesSelected = some(this.selectedAttributes, { selected: true });
      const hasNoSchemesToBeCreated =
        isEmpty(this.dynamicClusterSchemes) &&
        isEmpty(this.getTableRows) &&
        isEmpty(this.clusterSchemesFromFeed) &&
        isEmpty(this.selectedReferenceClusterScheme);

      const conditions = [
        !this.valid,
        hasNoMethodologySelected,
        !hasSomeAttributesSelected,
        hasNoSchemesToBeCreated,
      ];
      if (this.clusteringSchemeSourceSelections[ClusterSchemeTypes.kmeans]) {
        const kmeansCondition = this.shouldSubCluster
          ? !this.validSubClusteringScheme
          : !this.validNewKmeansTable;

        conditions.push(kmeansCondition);
      }
      if (this.clusteringSchemeSourceSelections[ClusterSchemeTypes.default]) {
        conditions.push(
          this.referenceClusterSchemeNameHasError,
          some(values(this.referenceClusterSchemeErrors), v => !v)
        );

        if (this.shouldSubCluster) conditions.push(isEmpty(this.selectedReferenceClusterScheme));
      }

      return some(conditions, c => !!c);
    },

    referenceClusterSchemeErrors() {
      return {
        [this.$tkey('duplicateClusterSchemeError')]:
          this.checkDuplicatedClusterScheme(this.selectedReferenceClusterScheme || {}) === '',
        [this.$t('errors.duplicateName')]: !this.referenceClusterSchemeNameHasError,
      };
    },

    referenceClusterSchemeNameHasError() {
      return (
        this.clusteringSchemeSourceSelections[ClusterSchemeTypes.default] &&
        this.selectedReferenceClusterScheme &&
        this.hasUniqueName({ name: get(this.selectedReferenceClusterScheme, 'name') }) !== ''
      );
    },

    allClusterSchemes() {
      const refClusterScheme = this.selectedReferenceClusterScheme
        ? [this.selectedReferenceClusterScheme]
        : [];
      return [
        ...this.dynamicClusterSchemes,
        ...this.clusterSchemes,
        ...this.getTableRows, // user uploaded cluster schemes
        ...refClusterScheme,
        ...this.clusterSchemesFromFeed,
      ];
    },
  },

  created() {
    this.kmeansTableHeaders = [this.schemeNameInput, this.clusterCountInput];
    this.resetFormData();
  },

  methods: {
    ...mapActions('files', ['uploadCSV']),
    ...mapActions('clustering', ['processCSV', 'setUploadedData', 'fetchClusterSchemesFromFeed']),

    onToggleManualUploadSelected(value) {
      this.clusteringSchemeSourceSelections[ClusterSchemeTypes.upload] = value;
      // remove csv uploaded data
      if (!value) this.resetManualUploadForm();
    },

    onToggleKMeansSelected(value) {
      this.clusteringSchemeSourceSelections[ClusterSchemeTypes.kmeans] = value;
      // remove kmeans generated data
      if (!value) this.resetKmeanForm();
    },

    async onToggleFeedSelected(value) {
      this.clusteringSchemeSourceSelections[ClusterSchemeTypes.feed] = value;

      if (value) {
        this.loadingFromFeed = true;
        this.clusterSchemesFromFeed = await this.fetchClusterSchemesFromFeed();
        this.loadingFromFeed = false;
      } else {
        // remove feed generated data
        this.resetFeedForm();
      }
    },

    onToggleDefaultClustersSelected(value) {
      this.clusteringSchemeSourceSelections[ClusterSchemeTypes.default] = value;
      // remove the default reference-cluster-schemes data
      if (!value) this.resetDefaultClusterForm();
    },

    setSelectedReferenceClusterScheme(value) {
      this.selectedReferenceClusterScheme = find(
        this.referenceClusterSchemes,
        s => s.name === value
      );
    },

    setSelectedReferenceSubClusterScheme(value) {
      // First set the selected reference cluster scheme
      this.setSelectedReferenceClusterScheme(value);

      // Merge reference cluster scheme with the cluster to subcluster
      const clusterName = get(this.subClusterFrom, 'clusterName', '');
      let clusterSchemeName = this.$tkey('defaultSubClusteringSchemeName', [
        this.selectedScheme.name,
      ]);

      // Make sure the cluster name is unique as it is possible to subcluster from the same
      // cluster scheme multiple times if the cluster count and/or attributes are different.
      const schemeSuffix = reduce(
        this.clusterSchemes,
        (acc, cs) => {
          if (includes(cs.name, clusterSchemeName)) acc += 1;
          return acc;
        },
        1 // Start at 1 as we do not apply suffix to first scheme
      );
      if (schemeSuffix > 1) clusterSchemeName += ` ${schemeSuffix}`;

      const { subClusters, storeKeysFromReferenceClusterScheme } = reduce(
        this.selectedReferenceClusterScheme.clusters,
        (acc, c) => {
          const storeKeys = filter(c.storeKeys, k => this.subClusterStoreKeys.has(k));
          if (storeKeys.length) {
            acc.storeKeysFromReferenceClusterScheme = new Set([
              ...acc.storeKeysFromReferenceClusterScheme,
              ...storeKeys,
            ]);
            acc.subClusters.push({
              clusterName: `${clusterName} - ${c.clusterName}`,
              storeKeys,
            });
          }
          return acc;
        },
        { subClusters: [], storeKeysFromReferenceClusterScheme: new Set() }
      );
      // Check if there are stores in the subcluster that are not included in the reference scheme
      if (this.subClusterStoreKeys.size !== storeKeysFromReferenceClusterScheme.size) {
        const missingStoreKeys = difference(
          [...this.subClusterStoreKeys],
          [...storeKeysFromReferenceClusterScheme]
        );
        subClusters.push({ clusterName, storeKeys: missingStoreKeys });
      }

      this.selectedReferenceClusterScheme = {
        clusterCount: subClusters.length,
        clusters: subClusters,
        name: clusterSchemeName,
      };
    },

    toClusterSchemes(csvData) {
      const clusterSchemes = [];
      const scenarioId = this.selectedScenario._id;
      forEach(csvData, (v, k) => {
        const clusterCount = v.length;
        clusterSchemes.push({
          name: k,
          source: ClusterSchemeTypes.upload,
          scenarioId,
          clusterCount,
          clusters: v,
        });
      });
      return clusterSchemes;
    },

    toTableRows(clusterData) {
      return clusterData.map(v => pick(v, ['name', 'clusterCount']));
    },

    closeModal() {
      this.resetFormData();
      this.$emit('close');
    },

    onSelectionOfSchemeNumber(noOfSchemes) {
      const createEntry = number => ({
        name: `${this.$tkey('scheme')} ${number + 1}`,
        clusterCount: number + 2, // Can't have 1 since that's unclustered, start at 2 for scheme 1
        numCentroids: number + 2,
        source: ClusterSchemeTypes.kmeans,
      });

      // Append new entries to end of array so we don't loose previous inputted texts
      const existingEntries = this.dynamicClusterSchemes.slice(0, noOfSchemes);
      const missingEntries = noOfSchemes - existingEntries.length;
      const newEntries = times(missingEntries, i => createEntry(i + existingEntries.length));

      this.dynamicClusterSchemes = [...existingEntries, ...newEntries];
    },

    resetFormData() {
      this.selectedAttributes = cloneDeep(this.getScenarioAttributes);
      this.clusteringSchemeSourceSelections[ClusterSchemeTypes.upload] = false;
      this.clusteringSchemeSourceSelections[ClusterSchemeTypes.kmeans] = this.shouldSubCluster;
      this.clusteringSchemeSourceSelections[ClusterSchemeTypes.feed] = false;
      this.clusteringSchemeSourceSelections[ClusterSchemeTypes.default] = false;
      this.loading = false;
      this.resetManualUploadForm();
      this.resetKmeanForm();
      this.resetSubClusteringForm(ClusterSchemeTypes.kmeans);
      this.resetFeedForm();
    },

    resetManualUploadForm() {
      this.setUploadedData([]);
    },

    resetKmeanForm() {
      this.numberOfSchemes = null;
      this.dynamicClusterSchemes = [];
    },

    resetFeedForm() {
      this.clusterSchemesFromFeed = [];
    },

    resetDefaultClusterForm() {
      this.selectedReferenceClusterScheme = null;
    },

    resetSubClusteringForm(source) {
      this.resetDefaultClusterForm();
      this.resetKmeanForm();

      if (source === ClusterSchemeTypes.kmeans) {
        const selectedSchemeName = get(this.selectedScheme, 'name', '');
        this.newSubClusteringScheme = {
          name: this.$tkey('defaultSubClusteringSchemeName', [selectedSchemeName]),
          clusterCount: 2, // default
          numCentroids: 2,
          source,
        };
        // Only add new scheme to the list if currently sub-clustering
        if (this.shouldSubCluster) this.dynamicClusterSchemes = [this.newSubClusteringScheme];
      } else {
        this.newSubClusteringScheme = {};
      }
    },

    isClustered(numClusters) {
      // can't choose 1 cluster, since that is essentially unclustered (all stores in one cluster)
      const parsedNumClusters = toNumber(numClusters);
      const isClustered = !isNaN(parsedNumClusters) && parsedNumClusters > 1;
      return isClustered || this.$t('validationErrors.unclustered');
    },

    hasUniqueName({ name }) {
      const newName = name;

      // We check if the name is unique whithin the list to be created and the list of existing clusters
      const isUnique =
        filter(this.allClusterSchemes, ({ name: existingName }) => {
          return isEqual(trim(String(newName)), trim(existingName));
        }).length === 1;

      return isUnique ? '' : this.$t('validationErrors.unique', [this.$tkey('name')]);
    },

    hasValidClusterCount(clusteringScheme) {
      /*
        A cluster count is considered as valid if the number was not already in the active popup dialog
        or if the user previously generated the same no. of Clusters count with the same attributes
        This does not apply for the cluster scheme from feed as only one scheme will appear in the table
      */
      const sourcesToExclude = [ClusterSchemeTypes.feed, ClusterSchemeTypes.default];
      if (!includes(sourcesToExclude, clusteringScheme.source)) {
        const isUniqueInTable =
          filter(this.dynamicClusterSchemes, { clusterCount: clusteringScheme.clusterCount })
            .length === 1;

        if (!isUniqueInTable)
          return this.$t('validationErrors.unique', [this.$tkey('clusterCount')]);
      }

      return this.checkDuplicatedClusterScheme(clusteringScheme);
    },

    checkDuplicatedClusterScheme(clusterScheme) {
      /*
        If the current setting of cluster scheme count number and attributes selected are already in the DB,
        then there is no need to recalculate this as the stores were already split by current configuration.
        So we throw warnings to the user, without locking the ability to fetch.
      */
      const selectedAttributeIds = filter(this.selectedAttributes, { selected: true }).map(
        ({ id }) => id
      );

      let totalClusterCount =
        clusterScheme.source === ClusterSchemeTypes.kmeans
          ? clusterScheme.numCentroids
          : clusterScheme.clusterCount;
      if (this.shouldSubCluster) {
        // Calculate total cluster count for the future scheme as N = X + Y - 1,
        // where X = number of clusters in source scheme, Y = new numCentroids
        totalClusterCount =
          clusterScheme.source === ClusterSchemeTypes.kmeans
            ? this.selectedScheme.numCentroids + clusterScheme.numCentroids - 1
            : this.selectedScheme.clusterCount + clusterScheme.clusterCount - 1;
      }

      const findDuplicateScheme = cs => {
        const duplicateAttributes = isEqual(
          sortBy(selectedAttributeIds),
          sortBy(cs.selectedAttributeIds)
        );
        if (clusterScheme.source === ClusterSchemeTypes.kmeans) {
          return cs.numCentroids === totalClusterCount && duplicateAttributes;
        }
        if (clusterScheme.source === ClusterSchemeTypes.feed) {
          return (
            cs.source === clusterScheme.source &&
            cs.clusterCount === totalClusterCount &&
            duplicateAttributes
          );
        }
        if (this.shouldSubCluster) {
          const existingSchemeClusters = new Set(map(clusterScheme.clusters, 'clusterName'));
          return (
            every(clusterScheme.clusters, c => existingSchemeClusters.has(c.clusterName)) &&
            duplicateAttributes &&
            cs.clusterCount === totalClusterCount
          );
        }
        return (
          isEqual(
            sortBy(cs.clusters, ['clusterName']),
            sortBy(clusterScheme.clusters, ['clusterName'])
          ) && duplicateAttributes
        );
      };

      const existingClusterScheme = find(this.clusterSchemes, findDuplicateScheme);

      return existingClusterScheme ? this.$tkey('duplicateClusterSchemeError') : '';
    },

    updateClusterCount(clusteringScheme, newValue) {
      const parsedValue = TableUtils.extractValue(newValue, {
        outputFormat: 'number',
      });
      clusteringScheme.clusterCount = parsedValue;
      clusteringScheme.numCentroids = parsedValue;
    },

    onSubClusteringMethodologyChanged(methodology) {
      this.resetSubClusteringForm(methodology);
      // Reset source selections - only one can be selected at a time when subclustering
      forEach(keys(this.clusteringSchemeSourceSelections), source => {
        this.clusteringSchemeSourceSelections[source] = false;
      });
      this.clusteringSchemeSourceSelections[methodology] = true;
    },

    async onSave() {
      const subClusterFrom = get(this.subClusterFrom, 'clusterId', null);
      const sourceSchemeId = subClusterFrom ? get(this.selectedScheme, '_id', null) : null;
      // setting the default reference type cluster scheme similar to a csv uploaded cluster scheme
      const defaultScheme = this.selectedReferenceClusterScheme
        ? [
            {
              ...this.selectedReferenceClusterScheme,
              source: ClusterSchemeTypes.default,
              scenarioId: this.selectedScenario._id,
            },
          ]
        : [];
      const bodyData = merge(
        { clusterSchemes: this.dynamicClusterSchemes },
        {
          csvUploadedClusterSchemes: [...this.csvUploadedClusterSchemes, ...defaultScheme],
        },
        { feedClusterSchemes: this.clusterSchemesFromFeed },

        { sourceSchemeId },
        { sourceClusterId: subClusterFrom },
        pick(this, [
          'selectedAttributes',
          ...map(this.clusterSchemeTypes, type => `clusteringSchemeSourceSelections.${type}`),
        ])
      );

      this.$emit('process', bodyData);
      this.closeModal();
    },

    async onCSVUpload(formData) {
      formData.append('scenarioId', this.selectedScenario._id);
      return this.uploadCSV({ formData, service: 'clustering' });
    },

    async process({ fileId, mappings, delimiter }) {
      // note at this point rows are still object form.
      this.loading = true;
      const [err, processedCsvData] = await to(this.processCSV({ fileId, mappings, delimiter }));
      this.loading = false;
      if (err) return;

      this.csvUploadedClusterSchemes = this.toClusterSchemes(processedCsvData);
      this.setUploadedData(this.toTableRows(this.csvUploadedClusterSchemes));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.attributes-row {
  background-color: $assortment-attribute-colour;
  margin-top: 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  opacity: 1;
  border-color: $assortment-attribute-colour;
  padding: 3px;
  align-items: center;
  flex-wrap: nowrap;
  word-break: break-word;
}

.schemes-table {
  width: 100%;
  tbody > tr:nth-last-of-type(odd) {
    background-color: $assortment-table-blue-bg-colour;
  }
}

::v-deep {
  .dialog-card__footer {
    border-top-width: 0px;
  }

  .v-input--is-readonly {
    pointer-events: none;
  }
}

.rtls-border {
  border-right-width: 1px;
}

.cluster-name {
  width: 70%;
}

.cluster-count {
  white-space: nowrap;
}

.dialog-card__footer {
  .actions-row {
    flex: auto;
  }
}

.v-input--radio-group--column {
  .sub-clustering {
    &__label {
      color: $assortment-text-colour;
      font-size: $panel-paragraph-size;
      line-height: $panel-paragraph-line-height;

      &:not(:last-child):not(:only-child) {
        margin-bottom: 4px;
      }
    }
  }
}
</style>
