import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VSnackbar,
    {
      staticClass: "connection-snackbar",
      class: _vm.snackbarOptions.theme,
      attrs: {
        timeout: -1,
        color: _vm.snackbarOptions.colour,
        bottom: "",
        left: "",
        text: "",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        { staticClass: "notification-wrapper d-flex" },
        [
          _c(VIcon, { staticClass: "mr-2" }, [_vm._v("mdi-lan-disconnect")]),
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("general.liveStatusUpdatesDisconnect")) +
              "\n  "
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }