<template>
  <v-menu v-model="value" offset-y :top="top">
    <template v-slot:activator="{ on }">
      <v-btn primary depressed :class="{ activator: !title }" v-on="on">
        {{ title }}
        <v-icon v-if="icon" right>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="action in actions"
        :key="action.name"
        :disabled="action.disabled"
        @click="$emit(action.value)"
      >
        <v-list-item-title>{{ action.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    actions: {
      required: true,
      type: Array,
    },
    title: {
      required: false,
      type: String,
      default: null,
    },
    top: {
      required: false,
      type: Boolean,
      default: false,
    },
    icon: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.activator {
  display: block;
  height: 12px !important;
  width: 25px !important;
  min-width: 25px !important;
  padding: 0 !important;
  position: relative;
  border-radius: 3px;
  background-color: $assortment-primary-colour;
}
.activator::after {
  content: '...';
  position: absolute;
  top: 50%;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  color: white;
  margin-top: -50%;
  font-weight: bold;
}
</style>
