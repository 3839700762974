<template>
  <div class="d-flex h-100 w-100">
    <progress-bar v-if="isLoading" :message="$t('general.loading')" />
    <v-container v-else fluid>
      <v-row v-if="hasMultipleScenariosSelected">
        <v-alert type="info" text>
          {{ $tkey('warnings.workpackageWithMultipleScenariosSelected') }}
        </v-alert>
      </v-row>
      <v-row>
        <v-btn primary depressed :disabled="isAddNewRowDisabled" @click="addNewRow">
          {{ $t('actions.addNewRow') }}
        </v-btn>
        <v-btn depressed action class="ml-2" :disabled="isSaveDisabled" @click="save">
          {{ $t('actions.save') }}
        </v-btn>
      </v-row>
      <v-row class="w-100" style="height: 95%">
        <ag-grid-vue
          class="ag-theme-custom w-100 h-100"
          :master-detail="true"
          :column-defs="columnDefs"
          :row-data="rowData"
          :grid-options="gridOptions"
          :stop-editing-when-cells-loses-focus="true"
          :detail-cell-renderer-params="detailCellRendererParams"
          @cell-value-changed="trackDiff"
          @grid-ready="onGridReady"
        />
      </v-row>
    </v-container>
  </div>
</template>

<script>
import agGridUtils from '@/js/utils/ag-grid-utils';
import Vue from 'vue';
import to from 'await-to-js';
import { AgGridVue } from 'ag-grid-vue';
import {
  every,
  get,
  groupBy,
  isEmpty,
  isUndefined,
  keyBy,
  keys,
  map,
  mapValues,
  some,
  uniqueId,
  flatten,
  forOwn,
} from 'lodash';
import { mapActions, mapMutations, mapState } from 'vuex';
import chainLevels from '@enums/chain-levels';
import MultipleCheckpointSelector from '@/js/components/ag-grid-cell-renderers/multiple-checkpoint-selector-renderer.vue';
import SearchableSelect from '@/js/components/ag-grid-cell-renderers/searchable-select.vue';

const CollapsableCellRenderer = {
  template: `<v-btn icon :disabled="isDisabled" @click="onClick"><v-icon>{{getIcon}}</v-icon></v-btn>`,

  computed: {
    isDisabled() {
      return !this.params.value.scenarioId || this.params.value.isLoading;
    },
    getIcon() {
      return this.params.node.expanded ? 'mdi-chevron-down' : 'mdi-chevron-right';
    },
  },

  methods: {
    onClick() {
      this.params.node.setExpanded(!this.params.node.expanded);
    },
  },
};

export default {
  localizationKey: 'extract.reports.storeExecutionPlanning.checkpointSelector',
  components: {
    AgGridVue,
  },
  props: {
    extractType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isLoading: true,
      isLoadingContents: {},
      clustersByWorkpackage: {},
      furnituresByWorkpackage: {},
      currentStateDiff: {},
      gridApi: null,
      columnApi: null,
      rowData: [],
      errorsPerRow: {},
      detailCellRendererParams: {
        selector: this,
        detailGridOptions: {
          onCellValueChanged: (childData, parentNode) => {
            parentNode.data.selectedCheckpointIds = childData.selectedCheckpointIds;
            parentNode.colDef = { field: 'selectedCheckpointIds' };
            this.trackDiff(parentNode);
          },
          onCellValidationChanged: (params, isValid) => {
            this.$set(this.errorsPerRow, params.node.parent.id, isValid);
          },
        },
        getDetailRowData(params) {
          return {
            checkpoints: params.selector.getUniqueCheckpointCanvases(params),
            clusterMap: get(
              params.selector.clustersByWorkpackage[params.data.workpackageId],
              params.data.scenarioId,
              {}
            ),
            furnitureMap: get(
              params.selector.furnituresByWorkpackage[params.data.workpackageId],
              params.data.scenarioId,
              {}
            ),
          };
        },
      },
      gridOptions: {
        // hides menu
        suppressContextMenu: true,
        detailCellRenderer: 'MultipleCheckpointSelector',
        frameworkComponents: {
          collapsableCellRenderer: Vue.extend(CollapsableCellRenderer),
          MultipleCheckpointSelector: Vue.extend(MultipleCheckpointSelector),
        },
        defaultColDef: {
          editable: true,
          flex: 1,
        },
        getRowHeight: params => {
          const isDetailRow = params.node.detail;

          // for all rows that are not detail rows, return nothing, ie automatic height
          if (!isDetailRow) {
            return undefined;
          }
          const minimumRowHeight = 35; // to display "no row data"
          const headersRowHeight = 32;
          const detailRowData = this.getUniqueCheckpointCanvases(params);
          // otherwise return height based on number of rows in detail grid
          return (detailRowData.uniqueCanvases.length * 30 || minimumRowHeight) + headersRowHeight;
        },
      },
      checkpoints: [],
      savedState: null,
    };
  },

  computed: {
    ...mapState('workpackages', ['workpackages']),
    ...mapState('scenarios', ['scenarios']),
    ...mapState('compoundExtract', ['selectedExtract']),

    bundleIds() {
      return [];
    },

    bundleById() {
      return {};
    },

    workpackagesById() {
      return keyBy(this.workpackages, '_id');
    },

    scenariosByWorkpackage() {
      return groupBy(this.scenarios, 'workpackageId');
    },

    scenariosById() {
      return keyBy(this.scenarios, '_id');
    },

    checkpointsByScenarioId() {
      return groupBy(this.checkpoints, 'scenarioId');
    },

    checkpointsById() {
      return keyBy(this.checkpoints, '_id');
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canCreateKasuExtracts);
    },

    isAddNewRowDisabled() {
      return this.isEditingDisabled || !this.gridApi || !this.workpackages.length;
    },

    isSaveDisabled() {
      const hasAllValidSelections = every(this.currentStateDiff, (acc, rowId, statusDiff) =>
        ['workpackageId', 'scenarioId', 'workpackageLevel', 'selectedCheckpointIds'].every(
          field => !isEmpty(statusDiff[rowId][field])
        )
      );
      const hasMixedCheckpointsSelected = some(this.errorsPerRow, isValid => !isValid);
      return (
        this.isEditingDisabled ||
        isEmpty(this.currentStateDiff) ||
        !hasAllValidSelections ||
        hasMixedCheckpointsSelected
      );
    },

    chainLevelOptions() {
      const options = chainLevels[`${this.extractType}ChainLevels`];
      return mapValues(options, name => ({ name }));
    },

    hasMultipleScenariosSelected() {
      const selectedWorkpackageIds = get(this.selectedExtract, 'series.workpackageIds', []);
      const selectedScenariosIds = get(this.selectedExtract, 'series.scenarioIds', []);
      const workpackagesWithMultipleScenarios = [];

      selectedWorkpackageIds.forEach(workpackageId => {
        const scenariosByWorkpackageId = map(this.scenariosByWorkpackage[workpackageId], '_id');
        const selectedScenariosByWorkpackageId = scenariosByWorkpackageId.filter(scenarioId =>
          selectedScenariosIds.includes(scenarioId)
        );
        if (selectedScenariosByWorkpackageId.length > 1) {
          workpackagesWithMultipleScenarios.push(workpackageId);
        }
      });
      return !!workpackagesWithMultipleScenarios.length;
    },

    columnDefs() {
      return [
        {
          field: 'id',
          cellRenderer: 'collapsableCellRenderer',
          colId: 'id',
          headerName: '',
          maxWidth: 40,
          valueGetter: params => {
            // defines the condition for enabled/disabled
            return {
              scenarioId: params.data.scenarioId,
              isLoading: this.isLoadingContents[params.data.workpackageId],
            };
          },
          editable: false,
        },
        {
          hide: true, // TODO: Enable this once bundle is implemented on AOV3-2391
          field: 'bundleId',
          colId: 'bundleId',
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: map(this.bundleIds, '_id'),
            field: 'bundleId',
          },
          valueFormatter: params => this.getFormattedName(params, this.bundleById),
          editable: !this.isEditingDisabled,
          tooltipValueGetter(params) {
            return params.valueFormatted;
          },
        },
        {
          headerName: this.$t('entities.workpackage'),
          field: 'workpackageId',
          colId: 'workpackageId',
          cellRendererFramework: SearchableSelect,
          cellRendererParams: params => {
            return {
              isDisabled: false,
              initialValue: params.data.workpackageId || null,
              onChange: async wpId => {
                const newData = {
                  ...params.data,
                  scenarioId: null,
                };

                newData.workpackageId = wpId;
                params.node.setDataValue('workpackageId', wpId);
                params.node.setDataValue('scenarioId', null);
                params.node.setExpanded(false);
                params.api.refreshCells();

                if (wpId) {
                  await this.fetchWorkpackageData(wpId);
                }

                this.trackDiff({ data: newData, colDef: { field: 'scenarioId' } });
                this.trackDiff({ data: newData, colDef: { field: 'selectedCheckpointIds' } });
              },
              items: map(this.workpackages, wp => {
                return {
                  value: wp._id,
                  text: wp.name,
                };
              }),
            };
          },
        },
        {
          headerName: `${this.$t('entities.workpackage')} ${this.$t('general.level')}`,
          field: 'workpackageLevel',
          colId: 'workpackageLevel',
          singleClickEdit: true,
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: keys(this.chainLevelOptions),
            field: 'workpackageLevel',
          },
          valueFormatter: params => this.getFormattedName(params, this.chainLevelOptions),
          editable: !this.isEditingDisabled,
          tooltipValueGetter(params) {
            return params.valueFormatted;
          },
        },
        {
          headerName: this.$t('entities.scenario'),
          field: 'scenarioId',
          colId: 'scenarioId',
          singleClickEdit: true,
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: params => {
            return {
              values: map(this.scenariosByWorkpackage[params.data.workpackageId], '_id'),
              field: 'scenarioId',
            };
          },
          valueFormatter: params => this.getFormattedName(params, this.scenariosById),
          onCellValueChanged: params => {
            params.node.setExpanded(false);
            this.trackDiff({ data: params.data, colDef: { field: 'selectedCheckpointIds' } });
          },
          editable: !this.isEditingDisabled,
          tooltipValueGetter(params) {
            return params.valueFormatted;
          },
        },
      ];
    },
  },

  watch: {
    selectedExtract() {
      this.buildRowData();
    },
  },

  async created() {
    try {
      this.buildRowData();
      this.setSelectedStep(this.$options.name);
      await this.fetchWorkpackages({
        pick: ['_id', 'name', 'creationDate', 'selectedAssortmentGroupSettings'],
      });
      await this.fetchScenarios({
        params: { pick: ['_id', 'name', 'workpackageId'], sortField: null, sortDirection: null },
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions('assortmentCanvas', ['fetchCheckpointsForSelectedWorkpackage']),
    ...mapActions('clustering', ['fetchScenarioClustersByWorkpackage']),
    ...mapActions('furniture', ['fetchScenarioFurnitureByWorkPackage']),
    ...mapActions('workpackages', ['fetchWorkpackages']),
    ...mapActions('compoundExtract', ['updateExtract']),
    ...mapActions('scenarios', ['fetchScenarios']),
    ...mapMutations('compoundExtract', ['setSelectedStep']),

    trackDiff(params) {
      const { id: _id } = params.data;
      const { field } = params.colDef;
      // params.value is displayed value, params.data[params.colDef.field] is value as stored in db
      // e.g. params.value = "5,1", params.data[params.colDef.field] = 5.1
      const currentValue = params.data[params.colDef.field];

      const path = `${_id}.${field}`;
      const originalValue = get(this.savedState, path);

      if (agGridUtils.comparators.didValueChange(currentValue, originalValue)) {
        if (!this.currentStateDiff[_id]) this.$set(this.currentStateDiff, _id, {});
        this.$set(this.currentStateDiff[_id], field, currentValue);
        return;
      }

      if (
        (currentValue === originalValue || isUndefined(originalValue)) &&
        get(this.currentStateDiff[_id], field)
      ) {
        // Remove the field from the object if it's back to its old value.
        // Note: This is only triggered on a change so entering 1 in a cell that already contains 1 triggers nothing.
        this.$delete(this.currentStateDiff[_id], field);
        if (isEmpty(this.currentStateDiff[_id])) {
          // If there's nothing left in the object, remove it.
          this.$delete(this.currentStateDiff, _id);
        }
      }
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      const idsToKeep = [];
      this.gridApi.forEachNode(n => idsToKeep.push(n.data.id));

      forOwn(this.currentStateDiff, (_, key) => {
        if (!idsToKeep.includes(key)) {
          delete this.currentStateDiff[key];
        }
      });
    },

    addNewRow() {
      // We need to give this row an ID to track properly the row changes from master-detail table
      const newRow = { id: uniqueId(), bundleId: null, workpackageId: null, scenarioId: null };
      this.gridApi.applyTransaction({ add: [newRow] });
      this.$set(this.currentStateDiff, newRow.id, {});
    },

    getFormattedName(params, fromSource, path = 'name') {
      const dbEntry = fromSource[params.value];

      return get(dbEntry, path, this.$t('general.select'));
    },

    /**
     * getUniqueCheckpointCanvases processes the checkpoints of a specified scenario (from params),
     * groups them by their storeClassId and clusterId, and identifies unique canvases.
     * Returns an object with allCheckpoints, grouped checkpoints, and unique canvases.
     */
    getUniqueCheckpointCanvases(params) {
      const allCheckpoints = this.checkpointsByScenarioId[params.data.scenarioId] || [];
      const uniqueCanvases = [];
      const checkpointsByClusterStoreClass = allCheckpoints.reduce((acc, c) => {
        const location = `${c.storeClassId}-${c.clusterId}`;
        if (!acc[location]) {
          acc[location] = [];
          uniqueCanvases.push(c);
        }
        acc[location].push(c);
        return acc;
      }, {});
      return {
        allCheckpoints,
        checkpointsByClusterStoreClass,
        uniqueCanvases,
      };
    },

    deepScenarioGroupBy(array, field, objectId) {
      // returns an object where the values are first grouped by scenario, then by the field desired, eg
      // { scenarioI1: { checkpointId1: cp1, checkpointId2: cp2 }}
      return array.reduce((acc, object) => {
        if (!acc[object.scenarioId]) acc[object.scenarioId] = {};
        acc[object.scenarioId] = keyBy(object[field], objectId);
        return acc;
      }, {});
    },

    async fetchWorkpackageData(workpackageId) {
      this.$set(this.isLoadingContents, workpackageId, true);

      const promises = [
        this.fetchCheckpointsForSelectedWorkpackage({
          workpackageId,
          shouldCommit: false,
          pickExtra: ['description', 'hasBeenOptimised'],
        }),
        this.fetchScenarioClustersByWorkpackage({
          workpackageId,
          shouldCommit: false,
        }),
        this.fetchScenarioFurnitureByWorkPackage({
          workpackageId,
          shouldCommit: false,
        }),
      ];

      const [err, [checkpointsForWorkpackage, clustersInfo, furnitureInfo]] = await to(
        Promise.all(promises)
      );

      if (err) {
        this.$set(this.isLoadingContents, workpackageId, false);
      }

      this.checkpoints.push(...checkpointsForWorkpackage);
      this.$set(
        this.clustersByWorkpackage,
        workpackageId,
        this.deepScenarioGroupBy(clustersInfo, 'clusters', 'clusterId')
      );
      this.$set(
        this.furnituresByWorkpackage,
        workpackageId,
        this.deepScenarioGroupBy(furnitureInfo, 'storeClasses', '_id')
      );

      this.$set(this.isLoadingContents, workpackageId, false);
    },

    formatDataForSave() {
      const seriesData = {
        bundleIds: [],
        workpackageIds: [],
        scenarioIds: [],
        checkpointIds: [],
        assortmentGroups: new Set([]),
      };
      const settingsData = {
        workpackageLevelMap: {
          ...mapValues(this.chainLevelOptions, () => []),
        },
      };
      this.gridApi.forEachNode(n => {
        // TODO: Enable this once bundle is implemented on AOV3-2391
        // seriesData.bundleIds.push(n.data.bundleId);
        seriesData.workpackageIds.push(n.data.workpackageId);
        seriesData.scenarioIds.push(n.data.scenarioId);
        seriesData.checkpointIds.push(...n.data.selectedCheckpointIds);
        const ags = get(
          this.workpackagesById[n.data.workpackageId],
          'selectedAssortmentGroupSettings',
          []
        );
        ags.forEach(ag => seriesData.assortmentGroups.add(ag.key));
        settingsData.workpackageLevelMap[n.data.workpackageLevel].push(n.data.workpackageId);
      });
      seriesData.assortmentGroups = Array.from(seriesData.assortmentGroups);

      return {
        series: seriesData,
        settings: settingsData,
      };
    },

    async buildRowData() {
      if (
        isEmpty(get(this.selectedExtract, 'series.workpackageIds', [])) ||
        !get(this.selectedExtract, 'settings.workpackageLevelMap', null)
      ) {
        this.rowData = [];
        return;
      }

      const wpLevelKeys = keys(this.selectedExtract.settings.workpackageLevelMap).filter(
        key => !isEmpty(this.selectedExtract.settings.workpackageLevelMap[key])
      );

      if (isEmpty(wpLevelKeys)) {
        this.rowData = [];
        return;
      }

      await Promise.all(
        this.selectedExtract.series.workpackageIds.map(wpId => this.fetchWorkpackageData(wpId))
      );

      this.rowData = flatten(
        wpLevelKeys.map(key => {
          return this.selectedExtract.settings.workpackageLevelMap[key].map((wpId, index) => {
            const scenarioId = this.selectedExtract.series.scenarioIds[index];

            const row = {
              id: uniqueId(),
              scenarioId,
              // filter selected checkpoints by scenarioId
              selectedCheckpointIds: this.selectedExtract.series.checkpointIds.filter(chId =>
                this.checkpointsByScenarioId[scenarioId].some(item => item._id === chId)
              ),
              workpackageId: wpId,
              workpackageLevel: key,
            };

            this.trackDiff({ data: row, colDef: { field: 'workpackageId' } });
            this.trackDiff({ data: row, colDef: { field: 'workpackageLevel' } });
            this.trackDiff({ data: row, colDef: { field: 'scenarioId' } });
            this.trackDiff({ data: row, colDef: { field: 'selectedCheckpointIds' } });

            return row;
          });
        })
      );
    },

    save() {
      this.updateExtract(this.formatDataForSave());
    },
  },
};
</script>

<style scoped></style>
