import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VIcon, { attrs: { size: "20" } }, [
    _vm._v("$" + _vm._s(_vm.status)),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }