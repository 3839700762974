<template>
  <div class="d-flex flex-column">
    <div class="ag-cell-label-container">
      <span
        ref="eMenu"
        class="ag-header-icon ag-header-cell-menu-button reset-all-header__menu"
        aria-hidden="true"
      >
        <span class="ag-icon ag-icon-menu" unselectable="on" @click="onMenuClicked" />
      </span>
      <div ref="eLabel" class="ag-header-cell-label reset-all-header__label" role="presentation">
        <span ref="eText" class="ag-header-cell-text">{{ headerName }}</span>
      </div>
    </div>

    <div
      class="reset-all-header__actions"
      :class="{ 'reset-all-header__actions--disabled': isDisabled }"
      @click="params.resetAll(params)"
    >
      <v-btn
        class="reset-all-header__button"
        :ripple="false"
        icon
        :disabled="isDisabled"
        data-id-e2e="resetAllButton"
      >
        <reset-icon />
      </v-btn>
      <span class="reset-all-header__title">{{ $t('general.all') }}</span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { get, has, isFunction } from 'lodash';

export default Vue.extend({
  data() {
    return {
      isDisabled: true,
    };
  },

  computed: {
    headerName() {
      return get(this.params, 'displayName', '');
    },
  },

  mounted() {
    if (has(this.params, 'isDisabled')) {
      this.isDisabled = isFunction(this.params.isDisabled)
        ? this.params.isDisabled()
        : this.params.isDisabled;
    }
  },

  methods: {
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.eMenu);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.reset-all-header {
  &__title {
    color: $assortment-primary-colour;
    font-size: 1.2rem;
    font-weight: normal;
  }

  &__actions {
    cursor: pointer;
    line-height: 1.4rem;
    padding: 1px 0 2px;

    &--disabled {
      cursor: default;

      .reset-all-header {
        &__button.v-btn.v-btn--icon {
          color: $assortment-disabled-icon-colour;
        }

        &__title {
          opacity: 0.15;
        }
      }
    }
  }

  &__button.v-btn.v-btn--icon {
    align-self: center;
    background-color: $assortment-primary-colour;
    border-radius: 2px;
    color: $assortment-active-button-colour;
    height: 12px;
    margin-right: 2px;
    width: 11px;

    svg {
      height: 11px;
      width: 11px;
    }
  }
}

.ag-theme-custom {
  .ag-header-cell-label.reset-all-header__label {
    padding-bottom: 0 !important;
  }

  .ag-cell-label-container {
    &:hover .reset-all-header__menu {
      opacity: 1;
    }

    .reset-all-header__menu {
      opacity: 0;
      transition: opacity 0.2s ease 0s, border 0.2s ease 0s;
    }
  }
}
</style>
