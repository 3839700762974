<template>
  <v-card class="step-tab-panel" flat>
    <v-container class="pt-3 pa-0 ma-0">
      <v-alert v-if="unscopedMappings.length" text class="mb-1">
        {{ $tkey('checkSuggestedSelectionsMessage') }}
      </v-alert>
    </v-container>

    <div class="ag-grid-box flex-grow-1">
      <ag-grid-vue
        style="width: 100%; height: 100%;"
        class="ag-theme-custom"
        :column-defs="columnDefs"
        :row-data="clusteredFurnitureData"
        :grid-options="gridOptions"
        :stop-editing-when-grid-loses-focus="true"
        :enable-range-selection="true"
        @cell-value-changed="trackDiff"
        @grid-ready="onGridReady"
      />
    </div>

    <page-actions
      :has-data-changes="!isSaveDisabled"
      :has-data-errors="hasDataErrors"
      :is-discard-enabled="hasDataChanges"
      @discard="discardChanges"
      @save="saveChanges"
    />

    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="closeUnsavedDataModal"
      @confirm="closeUnsavedDataModal"
    />
  </v-card>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { mapGetters, mapActions } from 'vuex';
import {
  get,
  orderBy,
  isEmpty,
  forEach,
  flatten,
  uniq,
  isNull,
  cloneDeep,
  reduce,
  groupBy,
  keyBy,
} from 'lodash';
import agGridUtils from '@/js/utils/ag-grid-utils';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';

export default {
  localizationKey: 'planogramSelectionPage',
  components: {
    AgGridVue,
  },
  mixins: [unsavedDataWarningMixin],
  data() {
    return {
      gridApi: null,
      columnApi: null,
      columnDefs: [
        {
          headerName: this.$tkey('tableHeaders.cluster'),
          field: 'cluster',
          pinned: 'left',
          sort: null,
          valueFormatter: params => {
            return this.isfirstRowOfCluster(params) ? params.value : '';
          },
        },
        {
          headerName: this.$tkey('tableHeaders.storeClass'),
          field: 'storeClass',
        },
        {
          headerName: this.$tkey('tableHeaders.furnitures'),
          field: 'furnitureName',
          colSpan: params => {
            return params.data.isFooter ? 2 : 1;
          },
          cellClass: params => {
            if (params.data.isFooter) return ['pr-4', 'justify-end'];
          },
          flex: 1,
        },
        {
          headerName: this.$tkey('tableHeaders.numberOfProducts'),
          headerClass: 'border-right',
          cellClass: 'border-right',
          field: 'numberOfProducts',
        },
        {
          headerName: this.$tkey('tableHeaders.numberOfStores'),
          headerClass: 'border-right',
          cellClass: 'border-right',
          field: 'numberOfStores',
        },
        {
          headerName: this.$tkey('tableHeaders.inScope'),
          headerClass: 'border-right justify-center',
          cellClass: 'border-right',
          field: 'inScope',
          menuTabs: ['generalMenuTab', 'filterMenuTab'],
          valueParser: agGridUtils.parsers.booleanParser,
          cellRenderer: params => {
            if (!params.data.isFooter) {
              return agGridUtils.utils.checkboxRenderer(params, this.handleCheckboxChange);
            }
          },
          cellRendererParams: {
            field: 'inScope',
          },
          editable: params => {
            return !params.data.isFooter;
          },
        },
      ],
      gridOptions: {
        suppressContextMenu: true,
        enableFillHandle: true,
        headerHeight: 40,
        defaultColDef: {
          filter: true,
          sortable: true,
          resizable: true,
          minWidth: 80,
          comparator: agGridUtils.sortings.naturalSort,
          editable: false,
          menuTabs: ['filterMenuTab'],
          suppressMovable: true,
          cellClassRules: {
            'diff-background': this.hasDiff,
          },
        },
        rowClassRules: {
          'cluster-start-row': params => this.isfirstRowOfCluster(params),
          'footer-row': params => params.data.isFooter,
        },
        postSort: this.postSort,
        processCellFromClipboard: agGridUtils.utils.processCellFromClipboard,
        suppressKeyboardEvent: agGridUtils.utils.suppressKeyboardEvent,
        getMainMenuItems: params =>
          agGridUtils.utils.toggleAllMenuItems(params, this.inScopeToggleAll),
      },
      currentStateDiff: {},
      clusteredFurnitureData: null,
      defaultScope: true,
      unscopedMappings: [],
      footerRowData: null,
    };
  },

  computed: {
    ...mapGetters('furniture', ['getSimpleSwapsClusteredFurnitureMapping']),

    clusterRows() {
      if (isEmpty(this.gridApi)) return [];

      const clusterRows = [];
      this.gridApi.forEachNodeAfterFilterAndSort((node, index) => {
        if (index === 0) {
          clusterRows.push(node);
        } else {
          const previousClusterRow = clusterRows.slice(-1)[0];
          if (previousClusterRow.data.cluster !== node.data.cluster) {
            clusterRows.push(node);
          }
        }
      });

      return clusterRows;
    },

    isSaveDisabled() {
      // This lets you save the default data
      if (this.unscopedMappings.length) return false;

      return !this.hasDataChanges;
    },

    hasDataChanges() {
      return !isEmpty(this.currentStateDiff);
    },

    hasDataErrors() {
      // required for <page-actions> component
      return false;
    },
  },

  async created() {
    await this.init();
  },

  methods: {
    ...mapActions('furniture', ['fetchFurnitureProductsByWorkpackage', 'saveScenarioFurniture']),
    ...mapActions('snackbar', ['showWarning']),
    ...mapActions('scenarios', ['refreshScenario']),

    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.gridApi.redrawRows(); // force clusterRows to evaluate after gridApi is set
      this.gridApi.setPinnedBottomRowData(this.footerRowData); // force set bottom row data in case onGridReady fired after init
    },

    async init() {
      await this.fetchFurnitureProductsByWorkpackage();
      this.clusteredFurnitureData = this.getClusteredFurnitureData();
      if (!isEmpty(this.gridApi)) this.gridApi.setPinnedBottomRowData(this.footerRowData); // if onGridReady already fired, set bottom row data

      // Sets default inScope value for clustered furniture if missing
      this.unscopedMappings = this.clusteredFurnitureData.filter(cf => isNull(cf.inScope));
      if (this.unscopedMappings.length) {
        this.unscopedMappings.forEach(f => {
          f.inScope = this.defaultScope;
        });
      }

      // backup data
      this.currentStateDiff = {};
      this.$options.clusteredFurnitureData = cloneDeep(this.clusteredFurnitureData);
      this.$options.savedState = this.formatSavedStateData(this.$options.clusteredFurnitureData);
    },

    getClusteredFurnitureData() {
      const tableRows = [];
      const furnitureStoreKeys = [];

      forEach(this.getSimpleSwapsClusteredFurnitureMapping, f => {
        const {
          cluster,
          storeClass,
          furnitureId,
          furnitureName,
          numberOfProducts,
          numberOfStores,
        } = f;
        tableRows.push({
          cluster,
          storeClass,
          furnitureId,
          furnitureName,
          numberOfProducts,
          numberOfStores,
          inScope: f.inScope,
        });

        furnitureStoreKeys.push(f.storeKeys);
      });

      // Define footer row data
      this.footerRowData = [
        {
          cluster: null,
          storeClass: null,
          furnitureName: this.$tkey('totalStores'),
          numberOfProducts: null,
          numberOfStores: uniq(flatten(furnitureStoreKeys)).length,
          inScope: null,
          isFooter: true,
        },
      ];

      return tableRows;
    },

    inScopeToggleAll(colId, value) {
      this.clusteredFurnitureData.forEach(f => {
        const { cluster, furnitureId } = f;
        if (!cluster) return;

        // Update table data and current state with updated vales
        f[colId] = value;
        this.updateCurrentState(cluster, furnitureId, colId, value);
      });

      // Refresh table cells to see changes
      const { columns } = colId;
      this.gridApi.refreshCells({ columns });
    },

    isfirstRowOfCluster(params) {
      return this.clusterRows.map(v => v.id).includes(get(params, 'node.id'));
    },

    postSort({ rowNodes }) {
      // runs before onGridReady, check gridApi
      if (isEmpty(rowNodes) || isEmpty(this.gridApi)) return;

      const sortDirection = this.columnApi.getColumn('cluster').sort;
      const orderedNodes = orderBy(rowNodes, [n => n.data.cluster], [sortDirection]);

      orderedNodes.forEach((node, childIndex) => {
        node.childIndex = childIndex;
      });

      // have to use splice to update reference to rowNodes. see https://www.ag-grid.com/javascript-grid-sorting/#post-sort
      rowNodes.splice(0, rowNodes.length, ...orderedNodes);

      // need to redraw rows to get correct styling for groups
      this.gridApi.redrawRows({ rowNodes });
      return rowNodes;
    },

    handleCheckboxChange(params) {
      params.setValue(!params.value);
    },

    trackDiff(params) {
      const { cluster, furnitureId } = params.data;
      const { field } = params.colDef;

      this.updateCurrentState(cluster, furnitureId, field, params.value);
    },

    hasDiff(params) {
      const { cluster, furnitureId, isFooter } = params.data;
      const { field } = params.colDef;
      const path = `${cluster}.${furnitureId}.${field}`;

      const currentValue = params.value;
      const originalValue = get(this.$options.savedState, path);

      // Will only check difference for editable cells
      return !isFooter && agGridUtils.comparators.didValueChange(currentValue, originalValue);
    },

    updateCurrentState(cluster, furnitureId, field, currentValue) {
      const path = `${cluster}.${furnitureId}.${field}`;
      const originalValue = get(this.$options.savedState, path);
      const existingValue = get(this.currentStateDiff, [cluster, furnitureId]);

      // If the current state does not match the original, track the state difference
      if (agGridUtils.comparators.didValueChange(currentValue, originalValue)) {
        if (!this.currentStateDiff[cluster]) {
          this.$set(this.currentStateDiff, cluster, {});
        }
        if (!this.currentStateDiff[cluster][furnitureId]) {
          this.$set(this.currentStateDiff[cluster], furnitureId, {});
        }
        this.$set(this.currentStateDiff[cluster][furnitureId], field, currentValue);
      } else if (existingValue) {
        this.$delete(this.currentStateDiff[cluster][furnitureId], field);

        // If there is nothing left in the object, remove it
        if (isEmpty(this.currentStateDiff[cluster][furnitureId])) {
          this.$delete(this.currentStateDiff[cluster], furnitureId);
        }

        if (isEmpty(this.currentStateDiff[cluster])) {
          this.$delete(this.currentStateDiff, cluster);
        }
      }
    },

    prepareFurnitureSelectionsForSave() {
      return this.clusteredFurnitureData
        .filter(furniture => furniture.cluster)
        .map(f => {
          const { cluster, furnitureId, inScope } = f;
          return {
            cluster,
            furnitureId,
            inScope,
          };
        });
    },

    formatSavedStateData(data) {
      const validClusters = data.filter(furniture => furniture.cluster);
      const groupedClusteredData = groupBy(validClusters, 'cluster');

      return reduce(
        groupedClusteredData,
        (acc, cluster, index) => {
          acc[index] = keyBy(cluster, 'furnitureId');
          return acc;
        },
        []
      );
    },

    async saveChanges() {
      try {
        await this.saveScenarioFurniture({
          simpleSwapsFurnitureSelection: this.prepareFurnitureSelectionsForSave(),
        });

        this.$options.clusteredFurnitureData = cloneDeep(this.clusteredFurnitureData);
        this.$options.savedState = this.formatSavedStateData(this.$options.clusteredFurnitureData);

        this.gridApi.refreshCells({ columns: ['inScope'] });
        this.unscopedMappings = [];
        this.resetChanges();
        await this.refreshScenario();
      } catch (e) {
        this.showWarning();
      }
    },

    discardChanges() {
      this.clusteredFurnitureData = cloneDeep(this.$options.clusteredFurnitureData);
      this.resetChanges();
    },

    resetChanges() {
      this.currentStateDiff = {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .v-alert {
    background: $assortment-warning-alert-background !important;
    border: 1px solid $assortment-warning-alert-border !important;
    border-radius: 0 !important;
    padding: 14px;

    &:before {
      content: none;
    }

    &__content {
      color: $assortment-text-colour;
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

  .diff-background {
    background-color: $assortment-table-changed-cell-bg-colour;
  }

  .ag-theme-custom {
    .ag-header {
      border-bottom: 1px solid $assortment-grid-line-colour !important;
    }

    .ag-row {
      &.cluster-start-row:not(:first-of-type) {
        border-top: 1px solid $assortment-grid-line-colour !important;
      }
    }

    .border-right {
      border-right: 1px solid $assortment-divider-colour !important;
    }

    .footer-row {
      background-color: $assortment-header-footer-colour;
      .border-right:not(:last-of-type) {
        border-right-color: $assortment-header-footer-colour !important;
      }
      .ag-cell-last-left-pinned {
        border-right: $assortment-header-footer-colour !important;
      }
    }
  }
}
</style>
