const store = {
  namespaced: true,

  state: {
    messages: [],
  },

  mutations: {
    show(state, messages) {
      state.messages = messages;
    },
    dismiss(state) {
      state.messages = [];
    },
  },

  actions: {
    showMessages({ commit }, messages) {
      commit('show', messages);
    },
  },
};

export default store;
