<template>
  <component
    :is="isLink && hasOnClick ? 'actionable-link' : 'div'"
    :class="iconComponent ? params.classes : ''"
    class="d-contents"
    :to="params.to"
    :on-click="linkClickHandler"
  >
    <span v-if="iconComponent" class="d-contents" @click="iconClickHandler">
      <component :is="iconComponent" :class="params.classes" />
    </span>
    <template v-else>
      <v-icon
        v-if="!params.isLoading"
        :class="params.classes"
        :disabled="params.isDisabled"
        :size="iconSize"
      >
        {{ `$${params.value}` }}
      </v-icon>

      <v-progress-circular
        v-if="params.isLoading"
        color="primary"
        class="progress-circular"
        indeterminate
        :size="20"
        :width="2"
      />
    </template>
  </component>
</template>

<script>
import { has, get } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'agGridIcon',
  computed: {
    iconComponent() {
      return get(this.params, 'iconComponent', null);
    },

    iconSize() {
      return get(this.params, 'size', 18);
    },

    isLink() {
      return has(this.params, 'to');
    },

    hasOnClick() {
      return has(this.params, 'onClick');
    },
  },

  methods: {
    linkClickHandler() {
      return !this.params.isDisabled && this.isLink && this.hasOnClick
        ? this.params.onClick(this.params.data)
        : () => {};
    },

    iconClickHandler() {
      return !this.params.isDisabled && !this.isLink && this.hasOnClick
        ? this.params.onClick(this.params.clickParams)
        : () => {};
    },
  },
});
</script>
