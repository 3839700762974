import { isNil, round, isString } from 'lodash';
import numberFormatUtils from '../utils/number-format-utils';

const { formatStringToNumber, formatNumber } = numberFormatUtils;

/**
 * Takes a number input and formats it to be a margin percent
 *
 * eg. 0.1462323 -> 0.15
 * @param {number} margin
 * @returns {string} Formatted Margin
 */
const formatMargin = margin => {
  // Remove null values
  if (isNil(margin)) return '';
  margin = isString(margin) ? formatStringToNumber(margin) : margin;
  if (isString(margin)) return margin;

  // Round to 2 decimal places
  // ie. 0.15232323 -> 0.15
  margin = round(margin, 2);

  // Format into a percentage
  // ie. 0.15 -> 15%
  let formattedMargin = formatNumber({ number: margin, format: 'percentages' });
  // To avoid displaying negative zero, we format it and compare against the value
  const negativeZero = formatNumber({ number: -0, format: 'percentages' });
  // If it is set we change it to be zero
  if (formattedMargin === negativeZero)
    formattedMargin = formatNumber({ number: 0, format: 'percentages' });
  return formattedMargin;
};

const mixin = {
  methods: {
    formatStringToNumber,
    formatNumber,
    formatMargin,
  },
};

export default mixin;
