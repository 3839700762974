import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "layout" }, [
    _c("div", { staticClass: "layout__wrapper overflow-ellipsis" }, [
      _c("input", {
        attrs: { name: "selected", type: "radio" },
        domProps: { value: _vm.params.layout._id },
        on: {
          input: function ($event) {
            return _vm.params.selectLayoutFunction(_vm.params.layout._id)
          },
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "layout__name" }, [
        _vm._v(_vm._s(_vm.params.layout.name)),
      ]),
    ]),
    _vm._v(" "),
    _vm.isDeleteVisible
      ? _c(
          "div",
          [
            _c(
              VIcon,
              {
                on: {
                  click: function ($event) {
                    return _vm.params.onDeleteLayout(_vm.params.layout)
                  },
                },
              },
              [_vm._v("$trash")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }