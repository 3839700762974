import * as WORK_PACKAGE_TYPE from '@enums/workpackage-type';
import templateTabs from './template-tabs-enum';
import tabUtils from './tabs-utils';

const tabs = [
  {
    id: 'scope',
    routeName: 'scope',
    position: 0,
    wpTypes: [WORK_PACKAGE_TYPE.ASSORTMENT_FULL_RESET, WORK_PACKAGE_TYPE.ASSORTMENT_SIMPLE_SWAPS],
    disabled: false,
    translationKey: 'templatesPage.toolbar.scopeTab',
    firstChild: templateTabs.DATES_ASSORTMENT_GROUPS_TAB,
    statusSection: 'scope',
  },
  {
    id: 'inputs',
    routeName: 'template-inputs',
    position: 1,
    wpTypes: [WORK_PACKAGE_TYPE.ASSORTMENT_FULL_RESET, WORK_PACKAGE_TYPE.ASSORTMENT_SIMPLE_SWAPS],
    disabled: false,
    translationKey: 'templatesPage.toolbar.inputsTab',
    firstChild: templateTabs.TEMPLATE_ATTRIBUTES_TAB,
    statusSection: 'inputs',
  },
];

export default {
  ...tabUtils.generateTabRouteMap(tabs),
  ALL: tabs,
};
