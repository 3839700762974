<template>
  <div class="d-flex align-center">
    <span>{{ value }}</span>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <span class="align-center ml-3 mr-1" :class="[hide ? 'd-none' : 'd-flex']">
          <i class="material-icons-round icon-tooltip__icon" v-on="on">{{ icon }}</i>
        </span>
      </template>
      <p v-for="(message, i) in messages" :key="i" class="icon-tooltip__message">
        {{ message }}
      </p>
    </v-tooltip>
  </div>
</template>

<script>
import Vue from 'vue';
import { get, isArray } from 'lodash';

export default Vue.extend({
  name: 'iconTooltipRenderer',
  computed: {
    icon() {
      return get(this.params, 'icon', '');
    },

    hide() {
      return get(this.params, 'hide', false);
    },

    value() {
      return get(this.params, 'value', '');
    },

    messages() {
      // Allows multiline tooltips
      const tooltipMessages = get(this.params, 'messages', []);
      return isArray(tooltipMessages) ? tooltipMessages : [tooltipMessages];
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.icon-tooltip {
  &__icon {
    color: $assortment-info-icon-colour;
    font-size: 2rem;
  }

  &__message {
    margin-bottom: 5px;

    &:last-child {
      margin: 0;
    }
  }
}

::v-deep.v-tooltip {
  &__content {
    color: $assortment-text-colour !important;
    background-color: $assortment-dialog-background !important;
    border: 1px solid $assortment-text-colour !important;
    width: auto;
    max-width: 600px;
  }
}
</style>
