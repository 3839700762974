'use strict';

module.exports = {
  PRODUCT_INFO: 'product_info',
  EXTERNAL_OPTIMISATION_EXTRACT: 'external_optimisation_extract',
  SIMPLE_SWAPS_OUTPUT: 'simple_swaps_output',
  STORE_EXECUTION: 'store_execution',
  FURNITURE: 'furniture',
  KASU: 'kasu',
  HASU: 'hasu',
  AOC: 'aoc',
};
