<template>
  <div
    v-if="shouldDisplay"
    class="d-flex flex-column  w-100"
    data-id-e2e="spacebreakConstraintsTable"
  >
    <div v-if="isCopyingConstraints" class="horizontal-rule" />
    <div class="spacebreak-title d-flex align-center">
      <h2>{{ isCopyingConstraints ? currentSpacebreakName : $tkey('title') }}</h2>
    </div>
    <div class="d-flex flex-column grid-container h-100 w-100">
      <ag-grid-vue
        id="spacebreak-constraints-grid"
        class="ag-theme-custom-yellow-text h-100 w-100"
        :column-defs="columnDefs"
        :row-data="rowData"
        auto-params-refresh
        dom-layout="autoHeight"
        :grid-options="gridOptions"
        :row-drag-managed="true"
        :animate-rows="true"
        :icons="icons"
        :header-height="isCopyingConstraints && !isGlobalSpacebreak ? 0 : 40"
        @drag-stopped="updateRanking"
        @grid-ready="onGridReady"
        @row-selected="onRowSelected"
      />
    </div>

    <!-- Table actions row -->
    <v-row v-if="!isCopyingConstraints" class="d-flex mt-3 justify-space-between">
      <div class="d-flex justify-start">
        <v-btn
          class="ml-2"
          :disabled="editingMode || isEditingDisabled"
          data-id-e2e="btnAddConstraint"
          primary
          depressed
          data-dd-action-name="AddNewConstraintBtn"
          @click="addConstraint"
        >
          {{ $tkey('addConstraint') }}
          <v-icon size="14" right>mdi-plus</v-icon>
        </v-btn>
        <div v-if="hasCopyConstraintsEnabled">
          <v-btn
            class="ml-2"
            :disabled="editingMode || isEditingDisabled"
            data-id-e2e="btnOpenCopyConstraintsModal"
            data-dd-action-name="CopyConstraintsBtn"
            secondary
            depressed
            @click="openCopyConstraintsModal"
          >
            {{ $tkey('copyConstraints') }}
            <v-icon size="14" right>$duplicate</v-icon>
          </v-btn>

          <span class="docs-link">
            <docs-link link="toolguide/120-assortment.html#copy-constraints" />
          </span>
        </div>
      </div>
      <div class="d-flex justify-end">
        <v-btn
          text
          depressed
          class="font-weight-bold remove-btn"
          data-id-e2e="btnRemoveAllConstraints"
          data-dd-action-name="RemoveAllConstraintsBtn"
          :disabled="constraintsCount === 0 || isEditingDisabled"
          @click="showRemoveAllConfirmation"
          >{{ $tkey('removeAll') }}</v-btn
        >
      </div>
    </v-row>

    <!-- Remove constraints confirmation -->
    <main-dialog
      v-if="!isCopyingConstraints"
      ref="confirm"
      :title="$tkey('removeAllConfirmation.title')"
      :message="$tkey('removeAllConfirmation.message', { spacebreakName })"
    >
      <template v-slot:actions="{ cancel: close }">
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn action @click="[removeAllConstraints(), close()]">
              {{ $tkey('removeAllConfirmation.confirm') }}
            </v-btn>
            <v-btn class="ml-2" @click="close()">
              {{ $tkey('removeAllConfirmation.cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </main-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { AgGridVue } from 'ag-grid-vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import {
  cloneDeep,
  get,
  map,
  orderBy,
  filter,
  isNull,
  find,
  isPlainObject,
  merge,
  sortBy,
  every,
  values,
  isObject,
  first,
  keyBy,
} from 'lodash';
import { Constraints } from '@enums/assortment-canvases';
import agGridUtils from '@/js/utils/ag-grid-utils';
import cellPriorityBadgeRenderer from './renderers/cell-priority-badge-renderer.vue';
import cellActionIconsRenderer from './renderers/cell-action-icons-renderer.vue';
import cellExpandIconRenderer from './renderers/cell-expand-icon-renderer.vue';
import cellCheckboxRenderer from './renderers/cell-checkbox-renderer.vue';
import cellDropdownRenderer from './renderers/cell-dropdown-renderer.vue';
import cellValueInputsRenderer from './renderers/cell-value-inputs-renderer.vue';
import cellAttributeFiltersRenderer from './renderers/cell-attribute-filters-renderer.vue';
import cellAppliedToRenderer from './renderers/cell-applied-to-description-renderer.vue';

export default {
  localizationKey: 'assortmentCanvasPage.spacebreakSettings.constraints',

  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    cellPriorityBadgeRenderer,
    cellActionIconsRenderer,
    cellExpandIconRenderer,
    cellCheckboxRenderer,
    cellDropdownRenderer,
    cellValueInputsRenderer,
    cellAttributeFiltersRenderer,
    cellAppliedToRenderer,
  },

  props: {
    spacebreak: {
      type: Object,
      default: null,
      required: false,
    },

    isCopyingConstraints: {
      type: Boolean,
      default: false,
    },

    constraints: {
      type: Object,
      default: () => ({}),
    },

    sourceScenarioAttributes: {
      type: Array,
      default: null,
      required: false,
    },

    attributesValuesInSourceScenario: {
      type: Object,
      default: () => ({}),
    },

    attributesValuesInCurrentScenario: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      rowData: [],
      columnDefs: [
        {
          headerName: this.$tkey('tableHeaders.priority'),
          field: 'priority',
          width: 100,
          cellRenderer: 'cellPriorityBadgeRenderer',
          headerClass: 'no-borders',
          cellClass: 'left-padding aligned-start no-borders priority-cell overflow-visible',
        },
        {
          headerName: '',
          field: 'dragIcon',
          width: 100,
          cellClass: 'aligned-start',
          rowDrag: () => !this.isEditingDisabled,
          rowDragText: () => '',
        },
        {
          headerName: this.$tkey('tableHeaders.constraintDescription'),
          field: 'constraintDescription',
          width: 500,
          cellRenderer: 'cellAttributeFiltersRenderer',
          cellRendererParams: {
            field: 'constraintDescription',
            sourceScenarioAttributes: this.sourceScenarioAttributes,
            attributesValuesMap: this.attributesValuesInSourceScenario,
            isCopyingConstraints: this.isCopyingConstraints,
          },
          cellClass: 'aligned-start overflow-visible',
        },
        {
          headerName: this.$tkey('tableHeaders.parameter'),
          field: 'parameter',
          width: 300,
          cellRenderer: 'cellDropdownRenderer',
          cellRendererParams: {
            selectItems: [
              { text: this.$tkey(Constraints.AtLeast), value: Constraints.AtLeast },
              { text: this.$tkey(Constraints.Between), value: Constraints.Between },
              { text: this.$tkey(Constraints.AtMost), value: Constraints.AtMost },
            ],
            field: 'parameter',
          },
          cellClass: 'aligned-start',
        },
        {
          headerName: this.$tkey('tableHeaders.value'),
          field: 'value',
          width: 350,
          cellRenderer: 'cellValueInputsRenderer',
          cellClass: 'aligned-start',
          cellRendererParams: {
            field: 'value',
          },
          valueFormatter: agGridUtils.formatters.numericFormatter,
          valueParser: agGridUtils.parsers.numericParser,
        },
        {
          headerName: this.$tkey('tableHeaders.type'),
          field: 'type',
          width: 300,
          cellRenderer: 'cellDropdownRenderer',
          cellRendererParams: {
            selectItems: [
              { text: this.$tkey(Constraints.Percentage), value: Constraints.Percentage },
              { text: this.$tkey(Constraints.ProductCount), value: Constraints.ProductCount },
              { text: this.$tkey(Constraints.AbsoluteSpace), value: Constraints.AbsoluteSpace },
            ],
            field: 'type',
          },
          cellClass: 'aligned-start',
        },
        {
          headerName: this.$tkey('tableHeaders.currentValue'),
          field: 'currentValue',
          width: 200,
          cellClass: 'aligned-start',
          valueFormatter: agGridUtils.formatters.numericFormatter,
        },
        {
          headerName: this.$tkey('tableHeaders.appliedTo'),
          field: 'appliedTo',
          width: this.isCopyingConstraints ? 400 : 600,
          cellRenderer: 'cellAppliedToRenderer',
          cellRendererParams: {
            isCopyingConstraints: this.isCopyingConstraints,
            definitions: get(this.constraints, 'definitions'),
          },
          cellClass: this.isCopyingConstraints ? 'd-flex justify-center editing-padding-top' : '',
        },
        {
          headerName: this.$t('actions.copy'),
          field: 'toCopy',
          width: 100,
          cellRenderer: 'cellCheckboxRenderer',
          cellClass: ' icon-cell d-flex justify-center editing-padding-top',

          onCellClicked: params => this.onCheckboxCellClicked(params),
          cellRendererParams: params => {
            return {
              errors: {
                [this.$t('assortmentCanvasPage.errorMessages.attributeValueInvalid')]: params.data
                  .isAttributeValueValid,
                [this.$t('assortmentCanvasPage.errorMessages.attributeNameInvalid')]: params.data
                  .isAttributeNameValid,
              },
            };
          },
        },
        {
          headerName: '',
          field: 'icons',
          width: this.isCopyingConstraints ? 100 : 400,
          cellRenderer: this.isCopyingConstraints
            ? 'cellExpandIconRenderer'
            : 'cellActionIconsRenderer',
          cellClass: 'aligned-start justified-right-cell right-padding icons-cell',
          // TODO: AOV3-2254 enable edition
          hide: this.isCopyingConstraints && !this.showNotImplemented,
        },
      ],
      icons: {
        rowDrag: '<i class="fa fa-arrows"></i>', // overriding default drag icon
      },
    };
  },

  computed: {
    ...mapState('assortmentCanvas', ['constraintEditing', 'spacebreaks']),
    ...mapGetters('assortmentCanvas', ['selectedCanvas', 'canvases', 'isConstraintSelectedToCopy']),
    ...mapGetters('clustering', ['getScenarioAttributes']),
    ...mapGetters('context', ['getClientConfig', 'showNotImplemented']),

    shouldDisplay() {
      return (
        !this.isCopyingConstraints || this.isGlobalSpacebreak || (this.rowData || []).length > 0
      );
    },

    hasCopyConstraintsEnabled() {
      return get(this.getClientConfig, 'features.copyConstraintsEnabled', false);
    },

    currentSpacebreakName() {
      return this.spacebreak ? this.spacebreak.name : this.$tkey('global');
    },

    currentSpacebreakId() {
      return this.spacebreak ? this.spacebreak._id : null;
    },

    editingMode() {
      return this.constraintEditing.constraint.open;
    },

    constraintFiltersLength() {
      return get(
        this.constraintEditing,
        'constraint.rowNodeData.constraintDescription.attributeValues.length',
        0
      );
    },

    currentConstraints() {
      // When copying use the constraints from props
      if (this.isCopyingConstraints) {
        return this.constraints;
      }
      return get(this.selectedCanvas, 'constraints');
    },

    constraintsCount() {
      return this.currentConstraints
        ? filter(
            this.currentConstraints.definitions,
            c => c.spacebreakId === this.currentSpacebreakId
          ).length
        : 0;
    },

    spacebreakName() {
      if (this.spacebreak) {
        return this.spacebreak.name;
      }
      return this.$tkey('globalConstraints');
    },

    isGlobalSpacebreak() {
      return isNull(this.spacebreak);
    },

    isEditingDisabled() {
      return (
        this.userPermissions &&
        (this.isCopyingConstraints ||
          !this.hasPermission(this.userPermissions.canEditAssortmentCanvas))
      );
    },

    sourceScenarioAttributesById() {
      return keyBy(this.sourceScenarioAttributes, 'id');
    },

    attributesInCurrentScenarioByName() {
      return keyBy(this.getScenarioAttributes, att => {
        return att.name.toLocaleLowerCase();
      });
    },
  },

  watch: {
    spacebreak() {
      this.loadRowData();
      if (this.gridColumnApi && this.gridApi && this.shouldDisplay) {
        this.updateColumnsVisibility();
      }
      this.gridOptions.rowClassRules = {
        'ag-row-current-value-outside-limits': this.updateConstraintsLimitsWarning,
      };
    },

    constraintFiltersLength() {
      if (this.constraintFiltersLength > 0) {
        const calculatedRowHeight = 41 + this.constraintFiltersLength * 41 + 20;
        const currentNode = this.gridApi.getRowNode(this.constraintEditing.constraint.rowNodeId);
        currentNode.setRowHeight(calculatedRowHeight);
        this.gridApi.onRowHeightChanged();
      }
    },

    selectedCanvas() {
      this.loadRowData();
    },
  },

  async created() {
    this.gridOptions = {
      rowHeight: 41,
      headerHeight: 40,
      suppressContextMenu: true,
      defaultColDef: {
        suppressMovable: true,
        suppressMenu: true,
      },
      rowClassRules: {
        'ag-row-current-value-outside-limits': this.updateConstraintsLimitsWarning,
      },
    };
  },

  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    this.loadRowData();
    if (this.gridColumnApi && this.gridApi && this.shouldDisplay) {
      this.updateColumnsVisibility();
    }
  },

  methods: {
    ...mapMutations('assortmentCanvas', [
      'setConstraintEditing',
      'setConstraintRowNodeData',
      'addSelectedConstraintToCopy',
      'removeSelectedConstraintsToCopyConstraint',
    ]),
    ...mapActions('assortmentCanvas', [
      'fetchCanvas',
      'updateAssortmentCanvasConstraint',
      'removeAssortmentCanvasConstraintsForSpacebreak',
      'updateConstraintsRanking',
    ]),

    getRowData() {
      const rowData = [];
      this.gridApi.forEachNode(node => {
        rowData.push(node.data);
      });
      return rowData;
    },

    getRowNodes() {
      const rowNodes = [];
      this.gridApi.forEachNode(node => {
        rowNodes.push(node);
      });
      return rowNodes;
    },

    async removeAllConstraints() {
      // If on a spacebreak and this button was clicked, it should not remove the global constraints,
      // but only delete the spacebreak ones. When on the global spacebreak panel, then we can remove all
      const allRows = this.gridApi
        .getModel()
        .rowsToDisplay.map(r => r.data)
        .filter(r => (this.isGlobalSpacebreak ? true : r.spacebreakId === this.spacebreak._id));

      // eslint-disable-next-line no-restricted-syntax,no-unused-vars
      for (const r of allRows) {
        this.gridApi.applyTransaction({ remove: [r] });
      }
      await this.removeAssortmentCanvasConstraintsForSpacebreak({
        canvasId: this.selectedCanvas._id,
        spacebreakId: this.spacebreak ? this.spacebreak._id : 'global',
      });

      await this.fetchCanvas({
        canvasId: this.selectedCanvas._id,
        forceSelectedCanvasUpdate: true,
      });
    },

    async addConstraint() {
      const newData = {
        priority: this.getRowData().length + 1,
        constraintDescription: { attributeId: '', attributeValues: [] },
        parameter: 'atLeast',
        value: [0, null],
        type: 'percentage',
        currentValue: null,
        appliedTo: this.currentSpacebreakName,
        spacebreakId: this.currentSpacebreakId,
      };
      const transaction = this.gridApi.applyTransaction({ add: [newData] });
      // We know that there will only be one entry in the transaction add array, therefore can use index 0.
      const currentNodeId = transaction.add[0].id;
      const currentNode = this.gridApi.getRowNode(currentNodeId);
      currentNode.setSelected(true);
      this.setConstraintEditing({
        rowNodeId: currentNodeId,
        editingBoolean: true,
      });
      this.setConstraintRowNodeData(newData);
      this.gridApi.redrawRows({ rowNodes: [newData] });
    },

    openCopyConstraintsModal() {
      this.$emit('switch-copy-constraints-modal', true);
    },

    onGridReady(params) {
      params.api.sizeColumnsToFit();
      window.addEventListener('resize', () => {
        setTimeout(() => {
          params.api.sizeColumnsToFit();
        });
      });
    },

    getConstraintsForSpacebreak() {
      if (this.isGlobalSpacebreak) {
        return sortBy(
          cloneDeep(filter(this.currentConstraints.definitions, c => c.spacebreakId === null)),
          constraint => moment(constraint.createdDate)
        );
      }
      const constraintPrioritiesForspacebreak = this.currentConstraints.priorities[
        this.spacebreak._id
      ];
      if (!constraintPrioritiesForspacebreak) return [];

      return constraintPrioritiesForspacebreak.map((constraintId, index) => {
        return merge(
          cloneDeep(find(this.currentConstraints.definitions, { id: constraintId })),
          // Priorities are based on the `priorities` array order when first loaded
          { priority: index + 1 }
        );
      });
    },

    getSpacebreakToApplyTo(constraint) {
      const appliedTo = this.spacebreak
        ? this.spacebreak.appliedTo
        : { name: this.$tkey('globalConstraints'), _id: null };

      // By default constraints are applied to the spacebreak with in the same position
      // Global constraints will apply to globals by default
      // When there is no default `appliedTo` value available, the first spacebreak will be used with the copy checkbox unset. Except in the case where the constraint is a global - this is left unset so that there are not multiple constraints with the same id being copied by default

      // Example:
      // Checkpoint 1 (current)                     | Checkpoint 2 (target)
      // + SB 1                                     | + SB X
      //   - constraint1 -> applies to SB X (copy)  | + SB Y
      // + SB 2                                     |
      //   - constraint2 -> applies to SB Y (copy)  |
      // + SB 3                                     |
      //   - constraint3 -> applies to SB X unless constraint 3 is global (copy unchecked)

      if (!appliedTo && constraint.spacebreakId) {
        return first(this.spacebreaks, copySpacebreak => copySpacebreak._id);
      }

      return appliedTo;
    },

    loadRowData() {
      const constraints = map(this.getConstraintsForSpacebreak(), constraint => {
        const rowDataObject = {};
        rowDataObject.value = constraint.limits;
        if (constraint.limits[0] !== null && constraint.limits[1] !== null) {
          rowDataObject.parameter = Constraints.Between;
        } else if (constraint.limits[0] === null) {
          rowDataObject.parameter = Constraints.AtMost;
        } else if (constraint.limits[1] === null) {
          rowDataObject.parameter = Constraints.AtLeast;
        }
        rowDataObject.type = constraint.type;
        if (this.spacebreak) {
          rowDataObject.currentValue =
            isPlainObject(constraint.currentValue) && !this.isGlobalSpacebreak
              ? constraint.currentValue[this.spacebreak._id]
              : constraint.currentValue;
        }
        rowDataObject.priority = constraint.priority;
        rowDataObject.constraintDescription = constraint.attributeFilters;

        if (this.isCopyingConstraints) {
          const {
            canConstraintBeCopied,
            isAttributeNameValid,
            isAttributeValueValid,
          } = this.isConstraintValid(rowDataObject);
          merge(rowDataObject, {
            canConstraintBeCopied,
            isAttributeNameValid,
            isAttributeValueValid,
            toCopy: canConstraintBeCopied,
            appliedTo: canConstraintBeCopied ? this.getSpacebreakToApplyTo(constraint) : null,
          });
        } else {
          rowDataObject.appliedTo = this.currentSpacebreakName;
        }
        // pre-selects checkbox
        if (this.isCopyingConstraints && rowDataObject.appliedTo) {
          this.addSelectedConstraintToCopy({
            spacebreakId: rowDataObject.appliedTo._id,
            constraintId: constraint.id,
          });
        }

        rowDataObject.spacebreakId = constraint.spacebreakId;
        rowDataObject.constraintId = constraint.id;
        rowDataObject.isCurrentValueWithinLimits = constraint.isCurrentValueWithinLimits;
        rowDataObject.createdDate = constraint.createdDate;
        return rowDataObject;
      });
      this.rowData = orderBy(constraints, 'priority', 'asc');

      // Add editing constraint row if editing was performed before data has changed
      // $nextTick because we want initial data be rendered by ag-grid before making temporary editable row
      this.$nextTick(() => {
        const newConstraintPending =
          get(this.constraintEditing, 'constraint.open') &&
          !get(this.constraintEditing, 'constraint.rowNodeData.constraintId');

        if (newConstraintPending) {
          const data = this.constraintEditing.constraint.rowNodeData;
          data.priority = this.rowData.length + 1;
          const transaction = this.gridApi.applyTransaction({ add: [data] });
          const currentNodeId = transaction.add[0].id;
          const currentNode = this.gridApi.getRowNode(currentNodeId);
          currentNode.setSelected(true);
          this.setConstraintEditing({
            ...this.constraintEditing,
            rowNodeId: currentNodeId,
            editingBoolean: true,
          });
          this.setConstraintRowNodeData(data);
          this.gridApi.redrawRows({ rowNodes: [data] });
        }
      });
    },

    onRowSelected(event) {
      if (event.node.isSelected()) {
        const lengthOfFiltersArray = event.node.data.constraintDescription.attributeValues.length;
        const calculatedRowHeight = 41 + (lengthOfFiltersArray || 1) * 41 + 20;
        this.expandRowNode(event.node, calculatedRowHeight);
      }
      if (!event.node.isSelected()) {
        this.collapseRowNode(event.node);
      }
    },

    expandRowNode(node, height) {
      node.setRowHeight(height);
      this.gridApi.onRowHeightChanged();
      this.gridApi.setSuppressRowDrag(true);
    },

    async collapseRowNode(node) {
      node.setRowHeight(41);
      this.gridApi.onRowHeightChanged();
      this.loadRowData();
      this.gridApi.setSuppressRowDrag(false);
    },

    async updateRanking() {
      // update server with new ranking
      const payload = map(this.getRowNodes(), rn => {
        const isGlobalPriority = rn.data.spacebreakId === null;

        return {
          canvasId: this.selectedCanvas._id,
          constraintId: rn.data.constraintId,
          // do not override ids for global constraints from spacebreak entry
          spacebreakId: isGlobalPriority ? null : this.spacebreak._id,
        };
      });

      await this.updateConstraintsRanking({
        canvasId: this.selectedCanvas._id,
        constraints: payload,
        spacebreakId: this.spacebreak._id,
      });
      // reload grid
      await this.fetchCanvas({
        canvasId: this.selectedCanvas._id,
        forceSelectedCanvasUpdate: true,
      });
    },

    showRemoveAllConfirmation() {
      this.$refs.confirm.open();
    },

    updateColumnsVisibility() {
      const shouldDisplayConstraintsStats = this.isCopyingConstraints || !this.isGlobalSpacebreak;
      this.gridColumnApi.setColumnsVisible(['currentValue'], shouldDisplayConstraintsStats);
      this.gridColumnApi.setColumnsVisible(['priority'], shouldDisplayConstraintsStats);
      this.gridColumnApi.setColumnsVisible(['toCopy'], this.isCopyingConstraints);
      this.gridApi.sizeColumnsToFit();
    },

    updateConstraintsLimitsWarning(params) {
      const currentSpacebreakId = get(this.spacebreak, '_id', null);
      if (isNull(currentSpacebreakId)) {
        return !every(values(params.data.isCurrentValueWithinLimits));
      }
      if (isObject(params.data.isCurrentValueWithinLimits)) {
        return !get(params.data.isCurrentValueWithinLimits, currentSpacebreakId, false);
      }
      return params.data.isCurrentValueWithinLimits === false;
    },

    isConstraintValid(row) {
      /* A constraint is only valid if:
      1. The selected scenario (destination) has an attribute whose name matches the source attribute name.
      2. Given validation no. 1 passes, that for all of the attributes values used by the source constraint,
        they exist on the source constraint definition.
      * */
      const attributeNameFromSource = this.sourceScenarioAttributesById[
        row.constraintDescription.attributeId
      ].name.toLocaleLowerCase();

      // 1
      const attributeInCurrentScenario = this.attributesInCurrentScenarioByName[
        attributeNameFromSource
      ];
      const isAttributeNameAvailableInCurrentScenario = !!attributeInCurrentScenario;

      const attributesValues = new Set(
        get(
          this.attributesValuesInCurrentScenario,
          [get(attributeInCurrentScenario, 'id'), 'values'],
          []
        ).map(attributeValue => attributeValue.toLocaleLowerCase())
      );

      // 2
      const isEveryAttributeValueAvailableInCurrentScenario = every(
        row.constraintDescription.attributeValues,
        attributeValueUsedInConstraint =>
          attributesValues.has(attributeValueUsedInConstraint.toLocaleLowerCase())
      );

      const canConstraintBeCopied =
        isAttributeNameAvailableInCurrentScenario &&
        isEveryAttributeValueAvailableInCurrentScenario;
      return {
        canConstraintBeCopied,
        isAttributeNameValid: isAttributeNameAvailableInCurrentScenario,
        isAttributeValueValid: isEveryAttributeValueAvailableInCurrentScenario,
      };
    },

    onCheckboxCellClicked(params) {
      const { appliedTo, constraintId, canConstraintBeCopied } = params.data;
      // TODO: AOV3-2216 ditch canConstraintBeCopied check, disabling for now constraints not 100% valid
      if (!canConstraintBeCopied || !appliedTo) return;
      const currentlySelected = this.isConstraintSelectedToCopy({
        spacebreakId: appliedTo._id,
        constraintId,
      });
      if (currentlySelected) {
        this.removeSelectedConstraintsToCopyConstraint({
          spacebreakId: appliedTo._id,
          constraintId,
        });
      } else {
        this.addSelectedConstraintToCopy({
          spacebreakId: appliedTo._id,
          constraintId,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .fa-arrows {
    &::before {
      font-size: 1.5rem;
      color: $assortment-primary-colour;
    }
  }
  .ag-center-cols-viewport {
    overflow-x: hidden;
  }
  .v-input__control {
    .v-select__slot {
      margin-left: 5px;
    }
    // hide messages box on autocomplete
    .v-text-field__details {
      display: none;
    }
  }
}

.remove-btn {
  color: $assortment-primary-colour;
}

.grid-container {
  overflow: hidden;
  flex-grow: 1;
}

.spacebreak-title {
  height: 45px;
}

.horizontal-rule {
  background-color: $assortment-ag-grid-header-separator-colour;
  height: 1px;
}
</style>
