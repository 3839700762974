import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb-4" },
    [
      _c(
        VTabs,
        {
          attrs: { "hide-slider": "", grow: "" },
          model: {
            value: _vm.selectedTab,
            callback: function ($$v) {
              _vm.selectedTab = $$v
            },
            expression: "selectedTab",
          },
        },
        _vm._l(_vm.tabs, function (item) {
          return _c(
            VTab,
            { key: item.key, staticClass: "relative-product-performance__tab" },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t(item.translationKey)) + "\n    "
              ),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        VTabsItems,
        {
          model: {
            value: _vm.selectedTab,
            callback: function ($$v) {
              _vm.selectedTab = $$v
            },
            expression: "selectedTab",
          },
        },
        _vm._l(_vm.tabs, function (item) {
          return _c(
            VTabItem,
            {
              key: item.key,
              staticClass: "relative-product-performance__tab-item",
              attrs: { transition: false, "reverse-transition": false },
            },
            [
              _c(item.componentName, {
                tag: "component",
                attrs: { "product-key": _vm.productKey },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }