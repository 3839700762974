import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticClass: "step-tab-panel", attrs: { flat: "" } },
    [
      _c(
        "div",
        { staticClass: "assortment-table d-flex flex-column" },
        [
          _c(
            VContainer,
            { staticClass: "actions-container pa-0 ma-0 flex-grow-0" },
            [
              _c(
                VRow,
                [
                  _c(
                    VRow,
                    { staticClass: "actions-col" },
                    [
                      _c(
                        VCol,
                        {
                          staticClass: "d-flex align-center flex-grow-0",
                          attrs: { "data-id-e2e": "btnsFurnitureEditor" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "mr-3 font-weight-bold info-span" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$tkey("reviewArchetypesInfo")) +
                                  "\n              "
                              ),
                              _vm._v(" "),
                              _c("docs-link", {
                                attrs: { link: "toolguide/040-furniture.html" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.isSimpleSwapWorkpackage
                            ? _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  _c("data-upload", {
                                    attrs: {
                                      legends: _vm.csvUploadLegends,
                                      "csv-upload-handler": _vm.onCSVUpload,
                                      disabled: _vm.isEditingDisabled,
                                    },
                                    on: { process: _vm.process },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "button-space-5 ml-2",
                                      attrs: {
                                        primary: "",
                                        disabled: _vm.isEditingDisabled,
                                      },
                                      on: { click: _vm.addArchetype },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("actions.addArchetype")
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.showErrorControls
                        ? _c(
                            VCol,
                            {
                              staticClass: "d-flex align-center col",
                              attrs: { id: "error-controls" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "invalid-rows-error-box pr-2" },
                                [_vm._v(_vm._s(_vm.invalidRowsErrorMessage))]
                              ),
                              _vm._v(" "),
                              !_vm.filterInvalidRows
                                ? _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        outlined: "",
                                        depressed: "",
                                        color: "error",
                                      },
                                      on: { click: _vm.toggleInvalidRows },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("actions.showInvalidRows")
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        outlined: "",
                                        depressed: "",
                                        color: "primary",
                                      },
                                      on: { click: _vm.toggleInvalidRows },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t("actions.showAllRows")
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ag-grid-box flex-grow-1" },
            [
              _c("ag-grid-vue", {
                staticClass: "ag-theme-custom",
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  "column-defs": _vm.headers,
                  "row-data": _vm.furniture,
                  "grid-options": _vm.gridOptions,
                  "stop-editing-when-cells-loses-focus": true,
                  "enable-range-selection": true,
                  "does-external-filter-pass": _vm.doesExternalFilterPass,
                },
                on: {
                  "cell-value-changed": _vm.onSelectionChanged,
                  "grid-ready": _vm.onGridReady,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("page-actions", {
            attrs: {
              "show-export": "",
              "is-custom-export": "",
              "has-data-changes": _vm.hasDataChanges,
              "has-data-errors": _vm.hasDataErrors,
              "save-disabled": _vm.isEditingDisabled,
              "is-discard-enabled": !_vm.isEditingDisabled,
            },
            on: {
              export: _vm.exportCSV,
              discard: _vm.discardChanges,
              save: _vm.saveChanges,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.closeUnsavedDataModal,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }