var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "reporting-menu d-flex flex-column h-100" },
    [
      _c("reporting-selection"),
      _vm._v(" "),
      _vm.isSidebarShown
        ? _c("ag-grid-vue", {
            staticClass: "ag-theme-custom-tree",
            staticStyle: { height: "100%", width: "100%" },
            attrs: {
              "tree-data": "",
              "animate-rows": "",
              dense: "",
              "row-data": _vm.rowData,
              "column-defs": _vm.columns,
              "grid-options": _vm.gridOptions,
            },
            on: { "grid-ready": _vm.onGridReady },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }