<template>
  <v-dialog :value="value" width="500px" @click:outside="closeModal">
    <dialog-card v-if="value" :title="$tkey('modalTitle')" @close="closeModal">
      <v-container fluid class="pa-0 reset-product-data__container">
        <v-row class="mt-4 border-bottom">
          <p class="mb-4">{{ $tkey('description') }}</p>
        </v-row>
        <!-- reset options list -->
        <div class="mt-4 pb-5">
          <v-row>
            <span class="font-weight-bold">
              {{ $tkey('kpis') }}
            </span>
          </v-row>
          <!-- specific fields to reset -->
          <v-row v-for="attr in dataToReset" :key="attr" class="checkbox-selection-row">
            <v-checkbox
              :input-value="currentSelections[attr]"
              class="rtls-checkbox mx-2"
              @change="updateCurrentSelections(attr, $event)"
            />
            <span>{{ $t(`scenarioInputPage.attributes.${attr}`) }}</span>
          </v-row>
        </div>
      </v-container>
      <template v-slot:footer>
        <page-actions
          :has-data-errors="hasDataErrors"
          :has-data-changes="true"
          :show-discard="false"
          save-btn-text="reset"
          @save="resetProducts"
        />
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { forEach, filter, omit, get, size } from 'lodash';

export default {
  name: 'ResetProductDataModal',
  localizationKey: 'scenarioInputPage.resetProductDataModal',

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    dataToResetEnum: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dataToReset: [],
      currentSelections: {},
    };
  },

  computed: {
    ...mapGetters('context', ['getClientConfig']),

    hasDataErrors() {
      return !size(this.selectedDataToReset);
    },

    hasDefaultParentChildData() {
      return get(this.getClientConfig, 'features.setDefaultParentChildData', false);
    },

    usesMarginWithoutFunding() {
      return get(this.getClientConfig, 'features.marginWithoutSalesEnabled', false);
    },

    hasProductsInResetEnabled() {
      return get(this.getClientConfig, 'features.productsInResetEnabled', false);
    },

    hasTransactionWeightEnabled() {
      return get(this.getClientConfig, 'features.transactionWeightEnabled', false);
    },

    hasKPKUEnabled() {
      return get(this.getClientConfig, 'features.kpkuEnabled', false);
    },

    spacebreaksFromDataEnabled() {
      return get(this.getClientConfig, 'features.spacebreaksFromDataEnabled', false);
    },

    selectedDataToReset() {
      return filter(Object.keys(this.currentSelections), attr => !!this.currentSelections[attr]);
    },

    hasPeriodIDColumnEnabled() {
      return get(
        this.getClientConfig,
        'features.productAttributeEditorPage.periodIDColumnEnabled',
        false
      );
    },
  },

  created() {
    this.dataToReset = this.getDefaultDataToReset();
    this.setDefaultCurrentSelections();
  },

  methods: {
    updateCurrentSelections(field, shouldReset) {
      this.$set(this.currentSelections, field, shouldReset);
    },

    // This returns list of product keys that can be reset to WP level values
    getDefaultDataToReset() {
      // don't include fields that can't be reset
      const keysToOmit = [
        'isNewProduct',
        'productKey',
        'productKeyDisplay',
        'itemDescription',
        'deleteNewProduct',
        'futureId',
        'fromTemplate',
        'palletContent',
        'packageTypeDescription',
        'productSeries',
        'inReset',
        'recentlySold',
        'assortment',
        'originalMargin',
        'originalMarginWithoutFunding',
        'originalPrice',
        'transferPriceInRange',
        'originalTransferPrice',
        'purchasePriceInRange',
        'originalPurchasePrice',
        'maintainOption',
      ];
      // if there is no parent-child data on WP level, also omit parentProduct
      if (!this.hasDefaultParentChildData) {
        keysToOmit.push('parentProduct');
      }
      // if marginWithoutFunding is used, exclude regular margin, and vice versa
      if (this.usesMarginWithoutFunding) {
        keysToOmit.push('margin');
      } else {
        keysToOmit.push('marginWithoutFunding');
      }

      // if transaction weight feature is not enabled, exclude transaction weight field
      if (!this.hasTransactionWeightEnabled) {
        keysToOmit.push('averageTransactionWeight');
      }

      // if spacebreaks from data feature is not enabled, omit sc eligibility
      if (!this.spacebreaksFromDataEnabled) {
        keysToOmit.push('storeClassEligibility');
      }

      if (!this.hasKPKUEnabled) {
        keysToOmit.push('kpku');
      }

      if (this.hasPeriodIDColumnEnabled) {
        keysToOmit.push('periodID');
      }

      // set default options to reset, omit unnecessary keys from enum
      const enumSubset = omit(this.dataToResetEnum, keysToOmit);
      return Object.values(enumSubset);
    },

    setDefaultCurrentSelections() {
      forEach(this.dataToReset, attr => this.$set(this.currentSelections, attr, true));
    },

    resetProducts() {
      this.$emit('reset-product-data', this.selectedDataToReset);
      this.closeModal();
    },

    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.reset-product-data {
  &__container {
    font-size: 1.2rem;
    max-height: 500px;
  }
}

.border-bottom {
  border-bottom: $assortment-border-colour 1px solid;
}

::v-deep {
  .dialog-card {
    &__footer {
      justify-content: flex-end;
    }
  }
}
</style>
