import axios from 'axios';
import to from 'await-to-js';
import { keyBy, get, includes, merge, map, reduce, flatMap, groupBy, isEmpty } from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import i18n from '@/js/vue-i18n';
import { jobStatuses } from '@enums/jobapi';
import sectionStatuses from '@enums/section-status';
import * as SORT_DIRECTION from '@enums/sort-direction';
import * as WORK_PACKAGE_TYPE from '@enums/workpackage-type';
import baseStatus from '@sharedModules/data/baseStatus';

import cannGroupUtils from '../../utils/cann-groups';

const store = {
  namespaced: true,

  state: {
    scenarios: [],
    selectedScenario: {},
    loading: false,
    saveInProgress: false,
    copyScenarioRunning: false,
    scenarioCannGroupsWithNotes: null,
  },

  getters: {
    saveInProgress: state => state.saveInProgress,

    scenariosLoading: state => state.loading,

    scenarios: state => state.scenarios,

    selectedScenario: state => state.selectedScenario,

    getScenarioById: state => id => state.scenarios.find(el => el._id === id),

    cannGroups: state => cannGroupUtils.getLeafNodes(state.selectedScenario.cannGroups),

    attributesById: state => keyBy(state.selectedScenario.customAttributes, 'id'),

    getCustomerFacingAndReportingAttributes: state => state.selectedScenario.customAttributes,

    getJobStatus: state => jobName => {
      return get(state.selectedScenario.jobs, `${jobName}.status`, null);
    },

    stores: state => get(state, 'selectedScenario.stores', []),

    getSimpleSwapsClusteredStores: (_state, getters, rootState, rootGetters) => {
      const stores = map(getters.stores, ({ simpleSwapsCluster, storeKey }) => ({
        simpleSwapsCluster,
        storeKey,
      }));
      const clusteredStores = rootGetters['workpackages/isSimpleSwapsWP']
        ? groupBy(stores, 'simpleSwapsCluster')
        : [];
      return reduce(
        clusteredStores,
        (acc, cs, key) => {
          acc[key] = flatMap(cs, 'storeKey');
          return acc;
        },
        {}
      );
    },

    // AOV3-1247 TODO: remove this once running multiple copy jobs is fixed
    isCopyScenarioRunning: state => state.copyScenarioRunning,

    // Get the job statuses - uses a base object to avoid errors.
    jobStatuses: state => {
      const baseJobObject = {
        jobId: null,
        status: null,
        dateTimeComplete: null,
        progress: null,
      };

      const baseJobStatus = {
        switching: {
          ...baseJobObject,
        },
        prepareSwaps: {
          ...baseJobObject,
        },
        canvasGeneration: {
          ...baseJobObject,
        },
        copyScenario: {
          ...baseJobObject,
        },
        cdtGeneration: {},
        clusteringGenerator: {
          ...baseJobObject,
        },
        regionsByAttributeGenerator: {
          ...baseJobObject,
        },
        clusteredSwitching: {
          ...baseJobObject,
        },
        spacebreakGenerator: {
          ...baseJobObject,
        },
        clScSwitchingMatricesCalculation: {
          ...baseJobObject,
        },
        setupProductModelling: {
          ...baseJobObject,
        },
      };

      // Map on real status
      return merge(baseJobStatus, state.selectedScenario.jobs);
    },

    // Get the section status for the scenario
    scenarioStatus: (state, getters, rootState) => (wpType, scenario) => {
      const regionsByAttributeEnabled = get(
        rootState.context.clientConfig,
        'features.regionsByAttributeEnabled',
        false
      );
      // Overwrite the actual status over the base based on WP type
      let actualStatus = baseStatus.baseStatusObject;

      if (wpType === WORK_PACKAGE_TYPE.ASSORTMENT_FULL_RESET) {
        actualStatus = merge(actualStatus, baseStatus.fullResetStatusObject, scenario.status);
      }

      if (wpType === WORK_PACKAGE_TYPE.ASSORTMENT_SIMPLE_SWAPS) {
        actualStatus = merge(actualStatus, baseStatus.simpleSwapsStatusObject, scenario.status);
      }

      // Calculate if general sections are available
      actualStatus.inputs.available = !isEmpty(state.selectedScenario);
      actualStatus.space.available = true;

      // Calculate if general sub sections are available for editing
      actualStatus.space.furniture.available = true;

      actualStatus.space.mapping.available =
        actualStatus.space.furniture.status === sectionStatuses.complete ||
        actualStatus.space.mapping.status !== sectionStatuses.notStarted;

      actualStatus.space.storeclass.available =
        actualStatus.space.mapping.status === sectionStatuses.complete ||
        actualStatus.space.storeclass.status !== sectionStatuses.notStarted;

      actualStatus.inputs.attributeEditor.available = true;

      actualStatus.inputs.cannGroups.available =
        actualStatus.inputs.attributeEditor.status === sectionStatuses.complete ||
        actualStatus.inputs.cannGroups.status !== sectionStatuses.notStarted;

      actualStatus.inputs.varietyGroups.available =
        actualStatus.inputs.cannGroups.status === sectionStatuses.complete ||
        actualStatus.inputs.varietyGroups.status !== sectionStatuses.notStarted;

      // Calculate availability for full reset specific sections
      if (wpType === WORK_PACKAGE_TYPE.ASSORTMENT_FULL_RESET) {
        actualStatus.measuring.available =
          actualStatus.inputs.cannGroups.status === sectionStatuses.complete ||
          actualStatus.measuring.status !== sectionStatuses.notStarted;

        actualStatus.modelling.available =
          actualStatus.measuring.cdt.status === sectionStatuses.complete ||
          actualStatus.modelling.status !== sectionStatuses.notStarted;

        actualStatus.assortment.available =
          actualStatus.measuring.cdt.status === sectionStatuses.complete;
        actualStatus.execution.available = true;

        actualStatus.finalisation.available =
          actualStatus.assortment.status === sectionStatuses.complete;

        actualStatus.space.spacebreaks.available =
          actualStatus.space.storeclass.status === sectionStatuses.complete ||
          actualStatus.space.spacebreaks.status !== sectionStatuses.notStarted;

        actualStatus.measuring.switching.available =
          actualStatus.inputs.varietyGroups.status === sectionStatuses.complete ||
          actualStatus.measuring.switching.status !== sectionStatuses.notStarted;

        // cdt depends on switching, see: analytics/analytics/tasks/cdt_generation/cdt_generation.py:95
        actualStatus.measuring.cdt.available =
          (actualStatus.inputs.cannGroups.status === sectionStatuses.complete &&
            actualStatus.measuring.switching.status === sectionStatuses.complete) ||
          actualStatus.measuring.cdt.status !== sectionStatuses.notStarted;

        actualStatus.measuring.region.available =
          actualStatus.inputs.cannGroups.status === sectionStatuses.complete ||
          actualStatus.measuring.region.status !== sectionStatuses.notStarted;

        // The Clustering page availability should only depend on Region if the feature is enabled
        actualStatus.measuring.clustering.available =
          (regionsByAttributeEnabled &&
            actualStatus.measuring.region.status === sectionStatuses.complete) ||
          (!regionsByAttributeEnabled &&
            actualStatus.inputs.cannGroups.status === sectionStatuses.complete) ||
          actualStatus.measuring.clustering.status !== sectionStatuses.notStarted;

        actualStatus.modelling.productModelling.available =
          actualStatus.measuring.cdt.status === sectionStatuses.complete ||
          actualStatus.modelling.productModelling.status !== sectionStatuses.notStarted;

        // The Switching Modelling page should be enabled if either switching
        // or clustered switching was run (the user may use unclustered only)
        actualStatus.modelling.switchingModelling.available =
          actualStatus.measuring.switching.status === sectionStatuses.complete ||
          actualStatus.measuring.clustering.status === sectionStatuses.complete ||
          actualStatus.modelling.switchingModelling.status !== sectionStatuses.notStarted;
      }

      // Calculate availability for simple swaps specific sections
      if (wpType === WORK_PACKAGE_TYPE.ASSORTMENT_SIMPLE_SWAPS) {
        actualStatus.space.planograms.available =
          actualStatus.space.storeclass.status === sectionStatuses.complete ||
          actualStatus.space.planograms.status !== sectionStatuses.notStarted;

        actualStatus.inputs.prepareSwaps.available =
          actualStatus.inputs.varietyGroups.status === sectionStatuses.complete ||
          actualStatus.inputs.prepareSwaps.status !== sectionStatuses.notStarted;

        actualStatus.swaps.productsToSwap.available =
          actualStatus.space.storeclass.status === sectionStatuses.complete ||
          actualStatus.swaps.productsToSwap.status !== sectionStatuses.notStarted;

        actualStatus.extracts.viewReports.available = true;

        // Calculate if general sub sections are available for editing
        actualStatus.swaps.available = actualStatus.swaps.productsToSwap.available;
        actualStatus.extracts.available = true;
      }
      return actualStatus;
    },

    // Get the section status for the selected scenario
    selectedScenarioStatus: (state, getters) => wpType => {
      return getters.scenarioStatus(wpType, state.selectedScenario);
    },

    // TODO? - Should live in the jobs module? It could then also allow for the assortment style jobs rather than just scenario
    // Can be used now only if selected scenario is set in a state
    isJobRunning: (state, getters) => (jobName, subTaskId) => {
      const job = get(getters.jobStatuses, jobName);
      // we maybe need to check sub tasks instead of simple tasks.
      const subTaskStatus = get(job, `${subTaskId}.status`);
      const status = subTaskId ? subTaskStatus : job.status;
      if (!job || !status) return false;
      return !includes([jobStatuses.finished, jobStatuses.failed], status);
    },

    getJob: (state, getters) => jobName => {
      return get(getters.jobStatuses, jobName);
    },

    scenariosByWorkpackageId: state => {
      return groupBy(state.scenarios, 'workpackageId');
    },
  },

  mutations: {
    setScenarioCannGroupsWithNotes(state, { cannGroups }) {
      state.scenarioCannGroupsWithNotes = cannGroups;
    },

    setScenarios(state, scenarios) {
      state.scenarios = scenarios;
    },

    setSelectedScenario(state, scenario) {
      state.selectedScenario = scenario;
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setCopyScenarioRunning(state, isRunning) {
      state.copyScenarioRunning = isRunning;
    },

    setSaveInProgress(state, isLoading) {
      state.saveInProgress = isLoading;
    },

    updateScenarios(state, scenario) {
      state.scenarios = state.scenarios.map(el => (el._id === scenario._id ? scenario : el));
    },

    deleteScenario(state, scenario) {
      state.scenarios = state.scenarios.filter(el => el._id !== scenario._id);
    },

    // Merge an update into the selected scenario
    updateSelectedScenario(state, { field, updates }) {
      // Vue.set was not correctly merging the state updates sometimes, status changes were not always being picked up
      // Replacing the whole scenario state will ensure the reactivity
      // https://v2.vuejs.org/v2/guide/reactivity.html#For-Objects
      state.selectedScenario = merge({}, state.selectedScenario, {
        [field]: updates,
      });
    },

    // Update a specific scenario
    updateScenario(state, { scenarioId, field, updates }) {
      const scenarioToUpdate = state.scenarios.find(sc => sc._id === scenarioId);

      if (scenarioToUpdate) {
        Vue.set(scenarioToUpdate, field, updates);
      }
    },

    updateNoteCount(state, { scenarioId, difference = 1 }) {
      const scenarioToUpdate = state.scenarios.find(sc => sc._id === scenarioId);

      if (scenarioToUpdate) {
        Vue.set(scenarioToUpdate, 'totalNotes', scenarioToUpdate.totalNotes + difference);
      }

      if (state.selectedScenario._id === scenarioToUpdate._id) {
        Vue.set(
          state.selectedScenario,
          'totalNotes',
          state.selectedScenario.totalNotes + difference
        );
      }
    },

    updateCannGroupNoteCount(state, { cannGroupId, difference = 1 }) {
      const cannGroupToUpdate = state.scenarioCannGroupsWithNotes.find(
        cg => cg.key === cannGroupId
      );
      if (cannGroupToUpdate) {
        Vue.set(cannGroupToUpdate, 'totalNotes', cannGroupToUpdate.totalNotes + difference);
      }
    },
  },

  actions: {
    async fetchScenarioCannGroups({ state }) {
      const scenarioId = state.selectedScenario._id;
      const [err, response] = await to(axios.get(`/api/scenarios/${scenarioId}/cann-groups`));
      if (err) throw new Error(err.message);
      return response.data;
    },
    async fetchScenarios({ commit }, { params = {}, options = {} } = {}) {
      commit('setLoading', true);
      const defaultSortParams = {
        sortField: 'creationDate',
        sortDirection: SORT_DIRECTION.ascending,
      };
      const [err, response] = await to(
        axios.get('/api/scenarios/', { params: { ...defaultSortParams, ...params } })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      if (options.skipCommit !== true) commit('setScenarios', response.data);
      return response.data;
    },

    async loadScenario({ commit, dispatch, state }, id) {
      commit('setLoading', true);
      const [err, { data: result }] = await to(axios.get(`/api/scenarios/${id}`));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      const hasChangedScenario = !(id === state.selectedScenario._id);
      commit('setSelectedScenario', result.data);
      dispatch('updateScenarioStatus');

      if (hasChangedScenario) {
        // Open event stream for events for this sceanrio
        dispatch('events/resetEventStreamWithScenarioId', { scenarioId: id }, { root: true });
      }

      return result.data;
    },

    async createScenario({ commit }, { scenario }) {
      commit('setSaveInProgress', true);
      const [err, response] = await to(axios.post('/api/scenarios', scenario));
      commit('setSaveInProgress', false);
      if (err) throw new Error(err.message);
      // TODO: [AOV3-325] Check how server specifies mongo errors we rely on {data.errors}, that are not presented in Mongo Error.
      // Instead we have {name: 'Error', message }
      if (response.data.name === 'Error') {
        throw new Error(response.data.message);
      }
      return response.data;
    },

    async syncTransferPrice({ commit, state }, { scenario }) {
      function sortTransferPrice(transferPriceRange) {
        const [transferPriceStartDate, transferPriceEndDate] = Object.values(
          transferPriceRange
        ).sort((a, b) => moment.utc(a).diff(moment.utc(b)));
        return { transferPriceStartDate, transferPriceEndDate };
      }
      scenario.transferPriceRange = sortTransferPrice(scenario.transferPriceRange);

      // note this does not update the scenario in vuex! updateScenarios mutation does
      commit('setSaveInProgress', true);
      const [err, response] = await to(
        axios.post(`/api/scenarios/${state.selectedScenario._id}/sync-transfer-price`, scenario)
      );
      commit('setSaveInProgress', false);
      if (err) throw new Error(err.message);
      return response.data;
    },

    async updateScenario({ commit }, { scenario = {} }) {
      // note this does not update the scenario in vuex! updateScenarios mutation does
      commit('setSaveInProgress', true);
      const [err, response] = await to(axios.patch('/api/scenarios', scenario));
      if (err) throw new Error(err.message);
      commit('setSaveInProgress', false);
      return response.data;
    },

    async deleteScenario({ commit }, scenario) {
      commit('setLoading', true);
      const [err, response] = await to(axios.delete(`/api/scenarios/${scenario._id}`));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      commit('deleteScenario', scenario);
      return response;
    },

    async copyScenario({ commit, dispatch }, scenario) {
      commit('setLoading', true);
      commit('setCopyScenarioRunning', true);
      dispatch('setSelectedScenario', scenario);
      const [err, response] = await to(axios.post(`/api/scenarios/copy/${scenario._id}`));
      commit('setLoading', false);
      dispatch('updateScenarioJobStatus');
      if (err) throw new Error(err.message);
      return response;
    },

    async prepareSwaps({ commit, dispatch, state }) {
      const { _id: scenarioId } = state.selectedScenario;
      commit('setLoading', true);
      const [err, response] = await to(axios.post(`/api/scenarios/${scenarioId}/prepare-swaps`));
      commit('setLoading', false);
      dispatch('updateScenarioJobStatus');
      if (err) throw new Error(err.message);
      return response;
    },

    async afterProductModelingSetupComplete({ dispatch }) {
      dispatch('refreshScenario');
    },

    async afterCopyScenarioFinishes({ commit, dispatch, rootState }, message) {
      const [err, res] = await to(axios.get(`/api/scenarios/${message.scenarioId}`));
      if (err) throw new Error(err.message);
      const scenario = res.data.data;

      const isMessageComingFromCurrentWp =
        message.workpackageId === rootState.workpackages.selectedWorkpackage._id;
      if (isMessageComingFromCurrentWp) {
        await dispatch('fetchScenarios', {
          params: { where: { workpackageId: message.workpackageId } },
        });
      }
      commit('setCopyScenarioRunning', false);

      return scenario;
    },

    async afterCopyScenarioComplete({ dispatch }, { message }) {
      const scenario = await dispatch('afterCopyScenarioFinishes', message);
      const content = i18n.t('notifications.copyScenario.finished', { name: scenario.name });
      const color = 'success';
      dispatch('snackbar/showSnackbar', { content, color }, { root: true });
    },

    async afterCopyScenarioFailed({ dispatch }, { message }) {
      const scenario = await dispatch('afterCopyScenarioFinishes', message);
      const content = i18n.t('notifications.copyScenario.failed', { name: scenario.name });
      const color = 'error';
      dispatch('snackbar/showSnackbar', { content, color }, { root: true });
    },

    async afterPrepareSwapsComplete({ dispatch }, { message }) {
      const [err, res] = await to(axios.get(`/api/scenarios/${message.scenarioId}`));
      if (err) throw new Error(err.message);

      const scenario = res.data.data;

      const content = i18n.t('notifications.prepareSwaps.finished', { name: scenario.name });
      const color = 'success';
      dispatch('snackbar/showSnackbar', { content, color }, { root: true });
      dispatch('refreshScenario');
    },

    async afterPrepareSwapsFailed({ dispatch }, { message }) {
      const [err, res] = await to(axios.get(`/api/scenarios/${message.scenarioId}`));
      if (err) throw new Error(err.message);

      const scenario = res.data.data;

      const content = i18n.t('notifications.prepareSwaps.failed', { name: scenario.name });
      const color = 'error';
      dispatch('snackbar/showSnackbar', { content, color }, { root: true });
      dispatch('refreshScenario');
    },

    async updateScenarioCannGroups(
      { commit },
      { id, cannGroups, commitChanges, cannGroupsChanged }
    ) {
      commit('setSaveInProgress', true);
      const [err, result] = await to(
        axios.patch(`/api/scenarios/${id}/update-property/cannGroups`, {
          cannGroups,
          commit: commitChanges,
          cannGroupsChanged,
        })
      );
      if (err) throw new Error(err.message);
      commit('setSaveInProgress', false);
      commit('updateScenarios', result.data);
      return result;
    },

    async updateOptimiserSettings({ commit }, { id, optimiserSettings }) {
      commit('setSaveInProgress', true);
      const [err, { data: result }] = await to(
        axios.patch(`/api/scenarios/${id}/optimiserSettings`, { optimiserSettings })
      );
      if (err) throw new Error(err.message);
      commit('setSaveInProgress', false);
      commit('updateScenarios', result.data);
      return result;
    },

    async updateVarietyGroups({ commit }, { id, varietyGroups }) {
      commit('setSaveInProgress', true);
      const [err, { data: result }] = await to(
        axios.patch(`/api/scenarios/${id}/update-property/varietyGroups`, { varietyGroups })
      );
      if (err) throw new Error(err.message);
      commit('setSaveInProgress', false);
      commit('updateScenarios', result.data);
      return result;
    },

    async hasValidProductAttributes({ commit }, { scenarioId, optimiseAccordingTo }) {
      commit('setLoading', true);
      const [err, result] = await to(
        axios.get(`/api/scenarios/${scenarioId}/hasValidProductAttributes/${optimiseAccordingTo}`)
      );
      commit('setLoading', false);
      if (err) return false;
      return result.data;
    },

    async editCustomAttributes(
      { commit },
      { scenario, addedCustomAttributes, deletedCustomAttributes }
    ) {
      // This is used in attribute-editor and takes over a second on the large NFR
      // Should be looked over for performance improvements
      // Also, actions should be asynchronous - this seems odd.
      const oldCustomAttributes = scenario.customAttributes || [];
      const newCustomAttributesRevised = (addedCustomAttributes || []).map(attr => {
        let additionalAttrs = {};
        const attributeKey = get(attr, 'attributeKey', null);
        const attributeSource = get(attr, 'attributeSource', null);
        if (attributeKey && attributeSource) {
          additionalAttrs = {
            attributeKey,
            attributeSource,
          };
        }
        return {
          name: attr.text,
          id: attr.value,
          clean: attr.clean,
          source: attr.source,
          ...additionalAttrs,
        };
      });
      const updatedScenario = {
        ...scenario,
        customAttributes: [...oldCustomAttributes, ...newCustomAttributesRevised].filter(
          ({ id: attribute }) => !deletedCustomAttributes.includes(attribute)
        ),
      };

      commit('setSelectedScenario', updatedScenario);
      commit('updateScenarios', updatedScenario);
      return updatedScenario;
    },

    setSelectedScenario({ commit, dispatch }, scenario) {
      commit('setSelectedScenario', scenario);

      // Open event stream for events for this scenario
      dispatch(
        'events/resetEventStreamWithScenarioId',
        { scenarioId: scenario._id },
        { root: true }
      );
    },

    async refreshScenario({ dispatch, state }) {
      const { _id } = state.selectedScenario;
      await dispatch('loadScenario', _id);
    },

    async setScenarioHasSkippedClustering({ dispatch, state }, { hasSkippedClustering }) {
      const scenarioId = state.selectedScenario._id;
      const res = await dispatch('updateScenario', {
        scenario: {
          id: scenarioId,
          hasSkippedClustering,
        },
      });
      await dispatch('refreshScenario');

      if (get(res, 'data.matchedCount') === 1) {
        dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
      } else {
        dispatch('snackbar/showError', i18n.t('errors.generalErrorMessage'), { root: true });
      }
    },

    async setScenarioHasSkippedRegionsByAttribute(
      { dispatch, state },
      { hasSkippedRegionsByAttribute }
    ) {
      const scenarioId = state.selectedScenario._id;
      const res = await dispatch('updateScenario', {
        scenario: {
          id: scenarioId,
          'regionSettings.hasSkippedRegionsByAttribute': hasSkippedRegionsByAttribute,
        },
      });
      await dispatch('refreshScenario');

      if (get(res, 'data.matchedCount') !== 1) {
        dispatch('snackbar/showError', i18n.t('errors.generalErrorMessage'), { root: true });
      }
    },

    async runGenerateRegionsByAttribute({ commit, state, dispatch }) {
      const scenarioId = state.selectedScenario._id;
      commit('setLoading', true);
      const url = `/api/scenarios/${scenarioId}/regions-by-attribute/generate`;
      const [err] = await to(axios.post(url));
      commit('setLoading', false);
      await dispatch('refreshScenario');
      if (err) throw new Error(err.message);
    },

    async setScenarioOptionalAttributes(
      { dispatch, state },
      { useMinimumFacings, useMinimumDistribution, additionalFields }
    ) {
      const scenarioId = state.selectedScenario._id;
      const res = await dispatch('updateScenario', {
        scenario: {
          id: scenarioId,
          'optionalAttributes.useMinimumFacings': useMinimumFacings,
          'optionalAttributes.useMinimumDistribution': useMinimumDistribution,
          'optionalAttributes.additionalFields': additionalFields,
        },
      });
      await dispatch('refreshScenario');

      if (get(res, 'data.matchedCount') === 1) {
        dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
      } else {
        dispatch('snackbar/showError', i18n.t('errors.generalErrorMessage'), { root: true });
      }
    },

    async updateScenarioStatus({ commit, state }) {
      const pick = ['_id', 'status'];
      const [err, res] = await to(
        axios.get(`/api/scenarios/${state.selectedScenario._id}`, {
          params: { pick },
        })
      );
      if (err) throw new Error(err.message);

      if (res.data.data.status) {
        // The selected scenario may have changed by the time status updates are retrieved. Only apply if
        // the data returned includes the ID of the selected scenario
        if (state.selectedScenario._id === res.data.data._id) {
          commit('updateSelectedScenario', { field: 'status', updates: res.data.data.status });
        }
      }
    },

    async updateScenarioJobStatus({ commit, state }, { message } = {}) {
      // if message has scenarioId use it so multiple scenarios get progress updates independently.
      // default to selectedScenario if no id is provided.
      const scenarioId = get(message, 'scenarioId', state.selectedScenario._id);
      if (!scenarioId) return;

      const pick = ['_id', 'jobs'];
      const [err, res] = await to(
        axios.get(`/api/scenarios/${scenarioId}`, {
          params: { pick },
        })
      );
      if (err) throw new Error(err.message);

      if (res.data.data.jobs) {
        const updates = res.data.data.jobs;
        // AOV3-1314 TODO: refactor to use single update action
        commit('updateScenario', { scenarioId, field: 'jobs', updates });
        if (scenarioId === state.selectedScenario._id) {
          commit('updateSelectedScenario', { field: 'jobs', updates });
        }
      }
    },
  },
};

export default store;
