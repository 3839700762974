<template>
  <v-container class="pa-0 ma-0 cdt-col">
    <v-row class="border-bottom">
      <v-col class="pa-1"
        ><b>{{ getAttributeName(splitAttributeId) }}</b></v-col
      >
    </v-row>
    <v-row>
      <v-col
        v-for="val in splitValues"
        :key="val.cdtId"
        class="pa-0 ma-0 border-right align-center justify-center cdt-col"
      >
        <v-icon size="14" class="box-icon">add_box</v-icon>
        <b>{{ val.attributeValue }}</b>
        <p v-if="showNotImplemented">{{ $t('assortmentCanvasesPanel.percentOfSpace', ['x']) }}</p>

        <!--
          Uncomment when we will want header to be drawn more than one level (for now we want to display just first cdt level)
          <assortment-canvas-header-node :cdts="cdts" :parent-id="val.cdtId" v-if="hasChildren(val.cdtId)" />
        -->

        <v-row class="headers">
          <v-col>{{ $tkey('rank') }}</v-col>
          <v-col>{{ $tkey('id') }}</v-col>
          <v-col>{{ $tkey('description') }}</v-col>
          <v-col>{{ $tkey('combinedUnitOfMeasure') }}</v-col>
          <v-col>{{ $tkey('sales') }}</v-col>
          <v-col>{{ $tkey('volume') }}</v-col>
          <v-col>{{ $tkey('margin') }}</v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { filter, map, uniq } from 'lodash';

export default {
  props: {
    ctdData: {
      type: Array,
      default: () => [],
    },
    cdts: {
      type: Array,
      required: true,
    },
    parentId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      canvasId: this.$route.params.canvasId,
      storeClassName: this.$route.params.storeClassName,
    };
  },

  computed: {
    ...mapState('scenarioCdts', ['scenarioCdts']),
    ...mapGetters('scenarios', ['attributesById']),
    ...mapGetters('context', ['showNotImplemented']),

    currentCdtData() {
      return filter(this.cdts, cdt => cdt.parentId === this.parentId);
    },

    splitAttributeId() {
      return String(uniq(map(this.currentCdtData, c => c.attributeId)));
    },

    splitValues() {
      return map(this.currentCdtData, c => ({
        attributeValue: String(c.attributeValues),
        cdtId: c._id,
      }));
    },
  },
  methods: {
    ...mapActions('scenarioCdts', ['fetchScenarioCdts']),

    getAttributeName(id) {
      const matchingAttr = this.attributesById[id];
      return matchingAttr ? matchingAttr.name : '';
    },

    hasChildren(id) {
      return filter(this.cdts, cdt => cdt.parentId === id).length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.cdt-col {
  min-width: fit-content;
}

.box-icon {
  float: right;
  font-size: 2rem !important;
  color: $assortment-action-icon-color !important;
}

.border-bottom {
  border-bottom: $assortment-tile-border-colour 1px solid;
}

.border-right {
  border-right: $assortment-tile-border-colour 1px solid;
}

div:last-child {
  border-right: none !important;
}
</style>
