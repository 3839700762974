<template>
  <v-container fluid class="reporting-step-container pa-0">
    <v-row class="reporting-tab-row">
      <v-flex>
        <div class="reporting-tab-scope d-flex flex-column">
          <v-tabs hide-slider class="reporting-tabs">
            <!-- The 'exact' prop is needed here to ensure that the correct active tab is selected
              This ensures that we can have both the parent and child routes in the same set of tabs. -->
            <v-tab
              v-for="item in tabs"
              :key="item.key"
              class="reporting-tabs__tab"
              :ripple="false"
              :disabled="!viewsPermissions[item.permission]"
              :to="createDetailedReportRoute(item.routeName)"
              :replace="true"
              exact
              :title="$t(item.translationKey.subtitle)"
            >
              {{ $t(item.translationKey.title) }}
            </v-tab>
          </v-tabs>
        </div>
      </v-flex>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { sortBy, filter, map, get } from 'lodash';
import ReportingSections from '@enums/reporting-sections';
import tabEnum from '../../utils/tabs/reporting-tabs-enum';

export default {
  localizationKey: 'reportingPage.tabs',

  data() {
    return {};
  },

  computed: {
    ...mapState('context', ['clientConfig']),
    ...mapGetters('context', ['hasPermission', 'getClientConfig']),

    tabs() {
      return sortBy(
        filter(
          map(tabEnum.reportingTabs, tab => {
            tab.isEnabled = tab.featureFlag
              ? get(this.getClientConfig, `features.${tab.featureFlag}`, true)
              : true;
            return tab;
          }),
          t => t.isEnabled
        ),
        'position'
      );
    },
    viewsPermissions() {
      return {
        [ReportingSections.overview]: this.hasPermission(this.userPermissions.canViewReportingPage),
        [ReportingSections.stats]: this.hasPermission(this.userPermissions.canViewStatsReport),
        [ReportingSections.relativeShare]: this.hasPermission(
          this.userPermissions.canViewRelativeShareReport
        ),
        [ReportingSections.comparisonWaterfall]: this.hasPermission(
          this.userPermissions.canViewPriceLadderReport
        ),
        [ReportingSections.priceLadder]: this.hasPermission(
          this.userPermissions.canViewComparisonWaterfallReport
        ),
        [ReportingSections.product]: this.hasPermission(this.userPermissions.canViewProductReport),
      };
    },
  },

  methods: {
    createDetailedReportRoute(view) {
      return {
        path: `/reporting-main/${view}`,
        query: this.$route.query,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.reporting-step-container {
  height: 100%;
  flex: 1;
}

.reporting-tab-row {
  height: 100%;
}

.reporting-tab-scope {
  height: 100%;

  .v-tabs-bar__content :first-child:not(.tab-last-updated) {
    margin-left: 0px !important;
  }

  .reporting-tabs {
    flex: 0;

    &__tab {
      color: $reporting-tab-text-color !important;
      background: $assortment-unselected-tab-colour;
      padding: 0 10px;
      justify-content: inherit;
      text-align: inherit;
      text-transform: none;
      border: 0.3px solid $assortment-tab-divider-colour;
      border-left: 3px solid $assortment-tab-divider-colour;
      border-bottom: 1px solid $assortment-tab-divider-colour;
      margin-bottom: -1px;
      margin-left: 4px;
      font-size: 13px;
      font-weight: 900;

      &.v-tab--active {
        background: $assortment-active-tab-colour;
        border-top: 1px solid $assortment-tab-divider-colour;
        border-left: 3px solid $reporting-section-active-border;
        border-bottom: none;
      }

      &:first-child:not(.tab-last-updated) {
        margin-left: 0px !important;
      }
    }
  }

  .v-tabs-bar {
    height: 37px;
  }

  .v-tab--active {
    background-color: $assortment-active-tab-colour;
  }

  .v-tab {
    color: $assortment-text-colour;
  }

  .v-tabs-slider {
    background-color: $assortment-active-tab-colour;
  }
}
</style>

<style lang="scss">
@import '@style/base/_variables.scss';

.reporting-tab-scope > .reporting-tabs.theme--light.v-tabs > .v-tabs-bar {
  background-color: inherit !important;
}

.reporting-tab-row
  > .flex
  > .reporting-tab-scope
  > .v-tabs
  > .v-tabs-bar
  > .v-slide-group__wrapper
  > .v-slide-group__content.v-tabs-bar__content {
  border-bottom: 1px solid $assortment-tab-divider-colour;
}
</style>
