import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "d-flex flex-column" }, [
    _c("div", { staticClass: "ag-cell-label-container" }, [
      _c(
        "span",
        {
          ref: "eMenu",
          staticClass:
            "ag-header-icon ag-header-cell-menu-button reset-all-header__menu",
          attrs: { "aria-hidden": "true" },
        },
        [
          _c("span", {
            staticClass: "ag-icon ag-icon-menu",
            attrs: { unselectable: "on" },
            on: { click: _vm.onMenuClicked },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "eLabel",
          staticClass: "ag-header-cell-label reset-all-header__label",
          attrs: { role: "presentation" },
        },
        [
          _c("span", { ref: "eText", staticClass: "ag-header-cell-text" }, [
            _vm._v(_vm._s(_vm.headerName)),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "reset-all-header__actions",
        class: { "reset-all-header__actions--disabled": _vm.isDisabled },
        on: {
          click: function ($event) {
            return _vm.params.resetAll(_vm.params)
          },
        },
      },
      [
        _c(
          VBtn,
          {
            staticClass: "reset-all-header__button",
            attrs: {
              ripple: false,
              icon: "",
              disabled: _vm.isDisabled,
              "data-id-e2e": "resetAllButton",
            },
          },
          [_c("reset-icon")],
          1
        ),
        _vm._v(" "),
        _c("span", { staticClass: "reset-all-header__title" }, [
          _vm._v(_vm._s(_vm.$t("general.all"))),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }