import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VApp,
    [
      _c("link", {
        attrs: {
          href: "https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap",
          rel: "stylesheet",
        },
      }),
      _vm._v(" "),
      _c("link", {
        attrs: {
          href: "https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Round",
          rel: "stylesheet",
        },
      }),
      _vm._v(" "),
      _c("link", {
        attrs: {
          href: "https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css",
          rel: "stylesheet",
        },
      }),
      _vm._v(" "),
      !_vm.hideNavbar ? _c("main-navbar") : _vm._e(),
      _vm._v(" "),
      _c(
        VMain,
        [
          _c(
            VContainer,
            { staticClass: "main-container", attrs: { fluid: "" } },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("router-view", { staticClass: "main-page" })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(
        "\n  " +
          _vm._s(/* AOV3-823 TODO: better handling for multiple notifications? possibly display stack of snackbars? */) +
          "\n  "
      ),
      _c("main-snackbar"),
      _vm._v(" "),
      _c("main-alert"),
      _vm._v(" "),
      _c("connection-snackbar"),
      _vm._v(" "),
      _c("main-footer"),
      _vm._v(" "),
      _c("div", { attrs: { id: "consent_blackbar" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }