'use strict';

const DataTypes = require('../../../enums/data-types');

const fixedFields = [
  {
    mongoField: 'clusterName',
    type: DataTypes.str,
    aliases: 'csvAliases.planogramDefinition.clusterName',
    translationKey: 'mongoFields.cluster',
    required: false,
    immutable: true,
  },
  {
    mongoField: 'storeClassName',
    type: DataTypes.str,
    aliases: 'csvAliases.planogramDefinition.storeClassName',
    translationKey: 'mongoFields.storeclass',
    required: true,
  },
  {
    mongoField: 'spacebreakName',
    type: DataTypes.str,
    aliases: 'csvAliases.planogramDefinition.spacebreakName',
    translationKey: 'mongoFields.spacebreak',
    required: true,
  },
  {
    mongoField: 'clusterStoreclassSpacebreakKey',
    type: DataTypes.alphanumeric,
    aliases: 'csvAliases.planogramDefinition.clusterStoreclassSpacebreakKey',
    translationKey: 'mongoFields.CSSBKey',
    required: true,
  },
  {
    mongoField: 'furnitureSequentialKey',
    type: DataTypes.alphanumeric,
    aliases: 'csvAliases.planogramDefinition.furnitureSequentialKey',
    translationKey: 'mongoFields.furnitureArchetype',
    required: true,
  },
  {
    mongoField: 'furnitureArchetypeName',
    type: DataTypes.str,
    aliases: 'csvAliases.planogramDefinition.furnitureArchetypeName',
    translationKey: 'mongoFields.furnitureArchetypeDescription',
    required: false,
    immutable: true,
  },
  {
    mongoField: 'totalSpace',
    type: DataTypes.number,
    aliases: 'csvAliases.planogramDefinition.totalSpace',
    translationKey: 'mongoFields.totalSpace',
    required: false,
    immutable: true,
  },
  {
    mongoField: 'numberOfStores',
    type: DataTypes.number,
    aliases: 'csvAliases.planogramDefinition.numberOfStores',
    translationKey: 'mongoFields.numberOfStores',
    required: false,
    immutable: true,
  },
  {
    mongoField: 'numberOfProducts',
    type: DataTypes.number,
    aliases: 'csvAliases.planogramDefinition.numberOfProducts',
    translationKey: 'mongoFields.numberOfProducts',
    required: false,
    immutable: true,
  },
  {
    mongoField: 'buildSuggestion',
    type: DataTypes.boolean,
    aliases: 'csvAliases.planogramDefinition.buildSuggestion',
    translationKey: 'mongoFields.buildSuggestion',
    required: false,
  },
  {
    mongoField: 'alternativeAssortment',
    type: DataTypes.str,
    aliases: 'csvAliases.planogramDefinition.alternativeAssortment',
    translationKey: 'mongoFields.alternativeAssortment',
    required: false,
  },
  {
    mongoField: 'notes',
    type: DataTypes.str,
    aliases: 'csvAliases.planogramDefinition.notes',
    translationKey: 'mongoFields.notes',
    required: false,
  },
];

module.exports = {
  fixedFields,
};
