import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VCard, { staticClass: "step-tab-panel", attrs: { flat: "" } }, [
    _c(
      "div",
      {
        staticClass:
          "assortment-table attributes-table switching-modelling-page d-flex flex-column",
      },
      [
        _c(
          VContainer,
          {
            staticClass: "actions-container flex-grow-0",
            attrs: { "data-id-e2e": "switchingModellingActionsTopSection" },
          },
          [
            _c(
              VRow,
              [
                _c(
                  VCol,
                  {
                    staticClass:
                      "actions-col d-flex justify-start align-center filter-select-header pr-6 br-1",
                  },
                  [
                    _c("span", { staticClass: "info-note mr-0" }, [
                      _vm._v(_vm._s(_vm.$tkey("switchingModellingTitle"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "pl-1 pr-1" }, [
                      _vm._v(_vm._s(_vm.$tkey("switchingModellingTitleNote"))),
                    ]),
                    _vm._v(" "),
                    _c("docs-link", { attrs: { link: "" } }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          VContainer,
          {
            staticClass: "actions-container flex-grow-0",
            attrs: { "data-id-e2e": "switchingModellingActionsBottomSection" },
          },
          [
            _c(
              VRow,
              [
                _c(
                  VCol,
                  {
                    staticClass:
                      "actions-col d-flex justify-start align-center filter-select-header pr-6 br-1",
                  },
                  [
                    _c("span", { staticClass: "info-note mr-0" }, [
                      _vm._v(_vm._s(_vm.$tkey("switchingModellingSubTitle"))),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "pl-1 pr-1" }, [
                      _vm._v(
                        _vm._s(_vm.$tkey("switchingModellingSubTitleNote"))
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "rtls-border rtls-border--left ml-2 pl-2",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$tkey("switchingModellingDataQuality")) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "square-quality good ml-3" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "pl-1 pr-1" }, [
                      _vm._v(_vm._s(_vm.$t("general.good"))),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "square-quality medium ml-3" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "pl-1 pr-1" }, [
                      _vm._v(_vm._s(_vm.$t("general.medium"))),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "square-quality poor ml-3" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "pl-1 pr-1" }, [
                      _vm._v(_vm._s(_vm.$t("general.poor"))),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ag-grid-box flex-grow-1" },
          [
            _c("ag-grid-vue", {
              staticClass: "ag-theme-custom switching-modelling-grid",
              staticStyle: { width: "100%", height: "100%" },
              attrs: {
                "data-id-e2e": "switchingModellingGrid",
                "row-data": _vm.switchingMetrics,
                "grid-options": _vm.gridOptions,
              },
              on: { "grid-ready": _vm.onGridReady },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }