var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "select-renderer",
      style: { maxWidth: _vm.maxWidth, width: _vm.maxWidth },
    },
    [
      _c("rtls-select", {
        class: { "diff-background": _vm.isHighlighted },
        attrs: {
          value: _vm.params.value,
          "hide-messages": "",
          white: "",
          items: _vm.items,
          disabled: _vm.isDisabled,
        },
        on: { change: _vm.update },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }