<template>
  <v-container v-if="selectedCannGroup" fluid class="ma-0 pa-0 variety-attrs-container">
    <div class="central-panel d-flex flex-column">
      <v-row class="align-end flex-grow-0">
        <v-col class="pl-5 py-2">{{ $t('varietyGroups.customerAttrs') }}:</v-col>
        <v-col class="pl-0 py-2">
          <span class="regular-text">
            {{ $t('varietyGroups.constantValuesAttrs') }}
          </span>
          {{ $t('varietyGroups.constantValues') }}
        </v-col>
      </v-row>
      <div class="panel-container full-height">
        <v-col class="pt-0 first-panel">
          <variety-drag-container :disabled="isEditingDisabled" :items="unusedAttributes" />
        </v-col>
        <v-col class="pt-0 second-panel">
          <variety-drag-container
            :disabled="isEditingDisabled"
            :show-footer="true"
            :items="remainConstantAttributes"
          />
          <v-divider />
          <v-col class="pl-2 py-2 sticky-title">
            <span class="regular-text">
              {{ $t('varietyGroups.diffValuesAttrs') }}
            </span>
            {{ $t('varietyGroups.diffValues') }}
          </v-col>
          <variety-drag-container
            :disabled="isEditingDisabled"
            :show-footer="true"
            :items="canDifferAttributes"
          />
        </v-col>
      </div>
      <v-row>
        <v-col cols="12" class="actions">
          <v-btn
            :disabled="isEditingDisabled"
            class="discard-btn"
            outlined
            @click="discardToPrevious"
          >
            {{ $t('actions.discardChanges') }}
          </v-btn>
          <v-btn :disabled="isEditingDisabled" action @click="saveOne">
            {{ $t('actions.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { differenceWith, cloneDeep, isEqual, sortBy } from 'lodash';
import { StoreNamespaces } from '../../../store/constants';

const { mapState, mapActions } = createNamespacedHelpers(StoreNamespaces.scenarios);

export default {
  props: {
    selectedCannGroup: { required: true, type: null },
  },
  data() {
    return {
      varietyGroups: null,
      initialVarietyGroups: null,
    };
  },
  computed: {
    ...mapState(['selectedScenario']),
    unusedAttributes() {
      const attributes = differenceWith(
        this.selectedScenario.customAttributes,
        [...this.remainConstantAttributes, ...this.canDifferAttributes],
        (x, y) => x.id === y.id
      );
      return sortBy(attributes, item => item.name.toLowerCase());
    },
    remainConstantAttributes() {
      return this.varietyGroups[this.selectedCannGroup].remainConstantAttributes;
    },
    canDifferAttributes() {
      return this.varietyGroups[this.selectedCannGroup].canDifferAttributes;
    },
    hasDataChanges() {
      // AOV3-405 required for variety-groups unsaved data warning
      return !isEqual(this.varietyGroups, this.initialVarietyGroups);
    },
    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditVarietyPage);
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions(['updateVarietyGroups', 'setSelectedScenario']),

    init() {
      this.varietyGroups = this.getInitialValues();
      this.initialVarietyGroups = cloneDeep(this.varietyGroups);
    },

    discardToPrevious() {
      const initialVarietyGroups = this.getInitialValues();
      this.varietyGroups[this.selectedCannGroup].remainConstantAttributes =
        initialVarietyGroups[this.selectedCannGroup].remainConstantAttributes;
      this.varietyGroups[this.selectedCannGroup].canDifferAttributes =
        initialVarietyGroups[this.selectedCannGroup].canDifferAttributes;
    },

    varietyGroup({ cannGroupId, remainConstantAttributes, canDifferAttributes }) {
      return {
        cannGroupId,
        remainConstantAttributes: this.formatOnSave(remainConstantAttributes),
        canDifferAttributes: this.formatOnSave(canDifferAttributes),
      };
    },

    formatOnSave(list) {
      return list.map(attribute => attribute.id || attribute);
    },

    async saveAll() {
      const groupsToSave = Object.keys(this.varietyGroups).map(key =>
        this.varietyGroup({
          cannGroupId: key,
          remainConstantAttributes: this.varietyGroups[key].remainConstantAttributes,
          canDifferAttributes: this.varietyGroups[key].canDifferAttributes,
        })
      );
      await this.saveVarietyGroups(groupsToSave);
      this.init();
    },

    saveOne() {
      const initialVarietyGroups = cloneDeep(this.selectedScenario.varietyGroups);
      const groupToSave = this.varietyGroup({
        cannGroupId: this.selectedCannGroup,
        remainConstantAttributes: this.varietyGroups[this.selectedCannGroup]
          .remainConstantAttributes,
        canDifferAttributes: this.varietyGroups[this.selectedCannGroup].canDifferAttributes,
      });
      const index = initialVarietyGroups.findIndex(
        ({ cannGroupId }) => cannGroupId === this.selectedCannGroup
      );
      if (index === -1) initialVarietyGroups.push(groupToSave);
      else initialVarietyGroups[index] = groupToSave;
      this.saveVarietyGroups(initialVarietyGroups);
    },

    async saveVarietyGroups(varietyGroups) {
      const { data, errors } = await this.updateVarietyGroups({
        id: this.selectedScenario._id,
        varietyGroups,
      });
      if (errors) {
        return this.$store.dispatch(`${StoreNamespaces.snackbar}/showSnackbar`, {
          content: this.$t('errors.generalErrorMessage'),
        });
      }
      if (data) {
        return this.setSelectedScenario(data);
      }
    },

    getInitialValues() {
      const varietyGroupsObject = {};
      const { varietyGroups, customAttributes, cannGroups } = this.selectedScenario;
      const scenarioCannGroups = this.getLeafNodes(cannGroups);
      scenarioCannGroups.forEach(({ key }) => {
        const found = varietyGroups.find(({ cannGroupId }) => cannGroupId === key);
        let remainConstantAttributes = [];
        let canDifferAttributes = [];
        if (found) {
          remainConstantAttributes = found.remainConstantAttributes.map(id => ({
            id,
            name: customAttributes.find(attribute => attribute.id === id).name,
          }));
          canDifferAttributes = found.canDifferAttributes.map(id => ({
            id,
            name: customAttributes.find(attribute => attribute.id === id).name,
          }));
        }
        varietyGroupsObject[key] = {
          remainConstantAttributes,
          canDifferAttributes,
        };
      });
      return varietyGroupsObject;
    },

    getLeafNodes(nodes = [], result = []) {
      for (let i = 0; i < nodes.length; i += 1) {
        if (!nodes[i].children.length) {
          result.push(nodes[i]);
        } else {
          result = this.getLeafNodes(nodes[i].children, result);
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.variety-attrs-container {
  height: 100%;
}

.central-panel {
  height: 100%;
}

.panel-container {
  display: flex;
  flex-grow: 1;
  overflow: auto;

  &.full-height {
    height: $tab-panel-height;
  }
}

.first-panel {
  height: 100%;
  overflow: auto;
  border-right: 1px solid $assortment-border-colour;
}

.second-panel {
  overflow: auto;
}

.actions {
  border-top: 1px solid $assortment-border-colour;
  display: flex;
  justify-content: flex-end;
  .discard-btn {
    color: $assortment-primary-colour;
    margin-right: 5px;
  }
}

.sticky-title {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: $assortment-header-footer-colour;
}

.regular-text {
  font-weight: 200;
  font-size: 12px;
}
</style>
