import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "100%", height: "100%" } },
    [
      _c(VDivider),
      _vm._v(" "),
      _c("store-allocation-table", {
        ref: "store-allocation-table",
        attrs: { id: "store-allocation-table", mode: "scenario-cluster" },
        on: {
          "has-selected-rows": function ($event) {
            return _vm.$emit("has-selected-rows", $event)
          },
          "data-changed": function ($event) {
            return _vm.$emit("data-changed", $event)
          },
          "on-save": function ($event) {
            return _vm.onSave()
          },
          "open-cluster-generation-modal": function ($event) {
            return _vm.openClusterGenerationModal($event)
          },
        },
      }),
      _vm._v(" "),
      _c("dependency-tree-feedback-modal", {
        attrs: {
          value: _vm.isDependencyTreeModalOpen,
          results: _vm.dependencyTreeFeedback,
          page: "clustering",
        },
        on: {
          close: _vm.closeDependencyTreeModal,
          commit: function ($event) {
            return _vm.onSave(true)
          },
        },
      }),
      _vm._v(" "),
      _vm.isClusterGenerationModalOpen
        ? _c("cluster-generation-modal", {
            attrs: { "sub-cluster-from": _vm.clusterToSubCluster },
            on: {
              process: _vm.processNewSubClusteringSchemes,
              close: _vm.closeClusterGenerationModal,
            },
            model: {
              value: _vm.isClusterGenerationModalOpen,
              callback: function ($$v) {
                _vm.isClusterGenerationModalOpen = $$v
              },
              expression: "isClusterGenerationModalOpen",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }