import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: {
        value: _vm.value,
        fullscreen: "",
        "content-class": "grouping-modal",
      },
      on: {
        "click:outside": _vm.closeModal,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeModal.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "dialog-card",
        {
          staticClass: "w-100 modal-card--full-screen",
          attrs: { title: _vm.$tkey("modalTitle") },
          on: { close: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c("page-actions", {
                    attrs: {
                      "has-data-errors": _vm.hasDataErrors,
                      "has-data-changes": true,
                      "show-discard": false,
                      "save-btn-text": "createGroupedAttr",
                    },
                    on: { save: _vm.onSave },
                    scopedSlots: _vm._u([
                      {
                        key: "right-btns",
                        fn: function () {
                          return [
                            _c(
                              VBtn,
                              {
                                staticClass: "text-outline",
                                attrs: { text: "", link: "" },
                                on: { click: _vm.closeModal },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("actions.cancel")) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "pt-2" },
            [
              _c("rtls-text-field", {
                staticClass: "text-field",
                attrs: {
                  width: "200px",
                  rules: _vm.rules,
                  "run-validations-on-creation": "",
                  placeholder: _vm.$tkey("newAttrName"),
                },
                model: {
                  value: _vm.newAttrName,
                  callback: function ($$v) {
                    _vm.newAttrName = $$v
                  },
                  expression: "newAttrName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex w-100 grouping-content-wrapper" }, [
            _c(
              "div",
              {
                staticClass:
                  "pl-0 pr-2 flex-grow-0 ma-0 d-flex flex-column grouping-content",
              },
              [
                _c("attribute-group-column", {
                  attrs: {
                    "attr-group": _vm.groupAttrs,
                    "is-editable": false,
                    "disable-add-all-remaining": !_vm.hasRemainingAttributes,
                  },
                  on: {
                    "distribute-remaining": _vm.distributeRemainingAttrs,
                    validation: _vm.columnValidation,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pr-0 d-flex flex-grow-1 ma-0 groups-wrapper" },
              _vm._l(_vm.attributeGroups, function (attrGroup) {
                return _c(
                  "div",
                  { key: attrGroup.id, staticClass: "attr-group" },
                  [
                    _c("attribute-group-column", {
                      attrs: {
                        "attr-group": attrGroup,
                        "disable-add-all-remaining":
                          !_vm.hasRemainingAttributes,
                        "reserved-names": _vm.groupNames,
                      },
                      on: {
                        "delete-group": _vm.deleteGroup,
                        "add-all-remaining": _vm.addAllRemainingAttrs,
                        update: _vm.updateGroup,
                        "delete-attr": _vm.deleteGroupAttr,
                        validation: _vm.columnValidation,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-grow-0 ma-0 add-column" },
              [
                _c(
                  VBtn,
                  {
                    attrs: { text: "" },
                    on: {
                      click: function ($event) {
                        return _vm.addNewGroup()
                      },
                    },
                  },
                  [
                    _c(
                      VIcon,
                      { staticClass: "material-icons icon mr-1 ml-2" },
                      [_vm._v("add_box")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }