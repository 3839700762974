<template>
  <div class="d-flex flex-column main-container-canvas">
    <!-- Top-nav and filtering section -->
    <!-- Hide if the user expands the canvas to fullscreen -->
    <v-row v-if="!fullScreenExpanded" class="flex-grow-0">
      <v-col cols="3" class="px-0">
        <div class="d-flex align-end">
          <rtls-toggle
            mandatory
            :value="selectedView"
            :left-toggle="{ value: views.Tile, translation: $tkey(views.Tile) }"
            :right-toggle="{ value: views.List, translation: $tkey(views.List) }"
            class="mr-2"
            @input="updateSelectedView"
          />
          <!-- <scenario-assortments-legend /> -->
          <span class="docs-link">
            <docs-link link="toolguide/120-assortment.html" />
          </span>

          <h4 v-if="selectedCanvas.outdated" class="ml-2 warning-banner">
            {{ $t('assortmentCanvasesPanel.errorMessages.outdatedWarning') }}
          </h4>
        </div>
      </v-col>

      <v-col class="px-0 text-center">
        <span v-if="cannGroupColumnSettingsEnabled && isDisplayByCDT" class="pr-2">
          <!-- This column settings panel allows to select and reorder cann groups to display -->
          <!-- It's only present if the feature flag is enabled and if the user is on CDT view -->
          <column-settings
            :button-title="$tkey('cannGroups')"
            :columns="canvasCannGroupColumns"
            @update:selected-columns="toggleCanvasCannGroupVisibility"
            @update:column-order="reorderCanvasCannGroupColumns"
          />
        </span>
        <span v-if="isAlternativeCanvasDisplaysEnabled">
          <span class="pr-2">{{ $tkey('displayBy.title') }}:</span>
          <v-btn-toggle v-model="displayBy" mandatory class="toggle rtls-toggle">
            <v-btn value="cdt" outlined>{{ $tkey('displayBy.cdt') }}</v-btn>
            <v-menu offset-y max-height="400">
              <template v-slot:activator="{ on, attrs }">
                <v-btn :value="displayByMenu" class="pr-1" outlined v-bind="attrs" v-on="on">
                  {{ $t('general.filter') }}
                  {{
                    $tkey(`displayBy.${displayByMenu}`, { fallback: getDisplayByMenuFallbackName })
                  }}
                  <span class="pl-2">|</span>
                  <v-icon color="primary">mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in displayByMenuItems"
                  :key="index"
                  class="display-by-title"
                  @click="selectDisplayByMenuItem(item)"
                >
                  {{ $tkey(`displayBy.${item}`) }}
                </v-list-item>

                <!-- custom attributes -->
                <template v-if="showCustomAttributesInDisplayByMenu">
                  <v-divider />
                  <v-list-item
                    v-for="item in getScenarioAttributes"
                    :key="item.id"
                    class="display-by-title"
                    @click="selectDisplayByMenuItem(item.id)"
                  >
                    {{ item.name }}
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </v-btn-toggle>
        </span>
      </v-col>

      <v-col v-if="showNotImplemented" cols="2" class="px-0 row">
        <!-- Disabled in scope of AOV3-829 -->
        <v-slider :value="50" min="0" max="100" hide-details class="w-25 mw-25" disabled>
          <template slot="append">
            <v-btn icon color="primary" disabled><v-icon>zoom_out_map</v-icon></v-btn>
          </template>
        </v-slider>
      </v-col>
      <v-col data-id-e2e="btnsCheckpointsOptimise" class="d-flex justify-end pr-2">
        <div v-if="selectedView === views.Tile" class="d-flex align-center justify-center">
          <h4 class="mr-2">{{ $tkey('selectTileSize') }}:</h4>
          <rtls-toggle
            mandatory
            :value="selectedTileSize"
            :left-toggle="{ value: 'medium', translation: $tkey('medium') }"
            :right-toggle="{ value: 'large', translation: $tkey('large') }"
            class="mr-6"
            @input="setTileSize"
          />
        </div>

        <v-btn color="primary" class="mr-2" @click="displayCheckpoints">
          {{ $tkey('checkpoints.title') }}
        </v-btn>
        <v-btn
          action
          :disabled="isAnyJobRunning || isCanvasConfigInvalidForOptimisation || isEditingDisabled"
          @click="isCheckpointCreationEligible"
        >
          {{ $t('actions.optimise') }}
        </v-btn>
        <error-triangle
          :arrow="false"
          :style="{ 'margin-bottom': '12px', 'margin-left': '5px' }"
          :errors="{
            [$tkey('errorMessages.setOptimiserSettings')]: !isCanvasConfigInvalidForOptimisation,
          }"
        />
      </v-col>
    </v-row>
    <progress-bar
      v-show="areCanvasProductsDownloading || isAnyJobRunning || renderingCanvas"
      :message="loadingMessage"
      style="margin: initial"
      class="pt-5"
    />
    <template v-if="!areCanvasProductsDownloading && !isAnyJobRunning">
      <canvas-toolbar />

      <legend-filter-highlight
        v-if="hasCanvasFilterLegendEnabled && showFilterLegend"
        class="grid-container-overflow"
      />

      <!-- This is static across both list and tile view -->
      <spacebreak-settings
        v-if="spacebreakSettings.open && selectedCanvas.storeClassId"
        :spacebreak="selectedSpacebreak"
        :store-class-id="selectedCanvas.storeClassId"
      />
      <!-- <product-panel style="display: flex;position:absolute;right:0/> -->
      <!-- Wrapper element to provide alternative layout for list view only  -->
      <div :class="[selectedView === views.Tile ? 'd-contents' : 'canvas-wrapper']">
        <!-- Fixed spacebreak column on left hand side -->
        <spacebreak-sidebar
          v-if="selectedView === views.List"
          :points-of-distribution="pointsOfDistribution"
        />
        <!-- Classes are applied conditionally to provide different scrolling behaviours for each view -->
        <!-- The canvas grid and sidebar should scroll independantly for list view only -->
        <div
          ma-0
          pa-0
          :class="[
            selectedView === views.Tile ? 'infinite-canvas flex-grow-1' : 'grid-container-wrapper',
          ]"
        >
          <div :style="gridContainerOverflow" class="grid-container-overflow">
            <!-- This renders a spacebreak sidebar and a column per cann group -->
            <!-- The distinction between list and tile views happens lower in the component tree, on cdt-column -->
            <canvas-grid
              v-if="orderedScenarioCdts"
              :key="orderedScenarioCdtsKey"
              :ordered-scenario-cdts="orderedScenarioCdts"
            />
          </div>
        </div>
      </div>
    </template>

    <!-- dialog to display list of checkpoints -->
    <main-dialog
      ref="checkpointsDialog"
      :title="$tkey('checkpoints.title')"
      :width="'76%'"
      :border="true"
      @click:outside="closeCheckpoints"
      @cancel="closeCheckpoints"
    >
      <template v-slot:content>
        <scenario-checkpoints-list @close="$refs.checkpointsDialog.cancel()" />
      </template>
    </main-dialog>

    <!-- pre-optimisation checkpoint creation confirmation dialog -->
    <main-dialog
      ref="confirm"
      :title="$t('assortmentCanvasPage.checkpointModal.title')"
      :message="$t('assortmentCanvasPage.checkpointModal.text')"
    >
      <template v-slot:actions="{ cancel: close }">
        <v-row>
          <v-col class="d-flex justify-end">
            <v-btn
              data-id-e2e="btnAgreeCheckpoint"
              action
              @click="[initOptimisation(true), close()]"
            >
              {{ $tkey('checkpointModal.agree') }}
            </v-btn>
            <v-btn
              data-id-e2e="btnDisagreeCheckpoint"
              class="ml-2"
              @click="[initOptimisation(false), close()]"
            >
              {{ $tkey('checkpointModal.disagree') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </main-dialog>
    <product-dashboard />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { each, filter, find, sortBy, keyBy, isEmpty, includes, get } from 'lodash';
import { TABLE_HEADERS } from '@enums/scenario-assortments';
import { DisplayBy, Views } from '@enums/assortment-canvases';
import { jobFinishedStatuses } from '@enums/jobapi';
import { jobApi } from '@/js/helpers';

export default {
  name: 'AssortmentCanvas',

  localizationKey: 'assortmentCanvasPage',

  data() {
    return {
      canvasId: this.$route.params.canvasId,
      cdtCols: [],
      cannGroupColumns: [],
      headers: TABLE_HEADERS.map(item => ({
        key: item.key,
        text: this.$tkey(`tableHeaders.${item.key}`),
        value: item.value,
      })),
      renderingCanvas: false,
      views: Views,
      showLegend: true,
      displayByMenu: '',
    };
  },

  computed: {
    ...mapState('assortmentCanvas', [
      'spacebreaks',
      'referenceCheckpoint',
      'selectedReferenceCheckpoint',
      'spacebreakSettings',
      'selectedTileSize',
      'selectedView',
      'fullScreenExpanded',
      'unifiedColumns',
      'showFilterLegend',
      'canvasCannGroupColumns',
    ]),
    ...mapState('furniture', ['scenarioFurniture']),
    ...mapState('scenarioCdts', ['scenarioCdts']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('context', ['clientConfig']),
    ...mapGetters('context', ['getClientConfig', 'showNotImplemented']),
    ...mapGetters('scenarios', ['isJobRunning', 'selectedScenario']),
    ...mapGetters('scenarioProducts', ['scenarioProducts']),
    ...mapGetters('assortmentCanvas', [
      'getPointsOfDistribution',
      'selectedCanvas',
      'canvases',
      'canvasProducts',
      'selectedPod',
      'isCanvasConfigInvalidForOptimisation',
      'getDisplayBy',
      'visibleCanvasCannGroups',
    ]),
    ...mapGetters('clustering', ['getScenarioAttributes']),

    cannGroupColumnSettingsEnabled() {
      return get(
        this.clientConfig,
        'features.scenario.assortmentCanvas.cannGroupColumnSettings',
        false
      );
    },

    pointsOfDistribution() {
      // Points of distribution are calculated across all storeclasses so we select the one for this canvas.
      return get(this, 'selectedPod', {});
    },

    isAssortmentOptimisationJobRunning() {
      return this.optimizerJobStatus
        ? !includes(jobFinishedStatuses, this.optimizerJobStatus)
        : false;
    },

    isClScSwitchingJobRunning() {
      return this.isJobRunning('clScSwitchingMatricesCalculation');
    },

    isAnyJobRunning() {
      return this.isAssortmentOptimisationJobRunning || this.isClScSwitchingJobRunning;
    },

    optimizerJobStatus() {
      const canvas = this.canvases.find(c => c._id === this.selectedCanvas._id);
      return get(canvas, 'jobs.assortmentOptimisation.status', '').toLowerCase();
    },

    formattedProductsData() {
      return this.canvasProducts.map(item =>
        Object.fromEntries(
          // convert to array, map, and then fromEntries gives back the object
          Object.entries(item).map(([key, value]) => {
            return [key, value];
          })
        )
      );
    },

    selectedSpacebreak() {
      return this.spacebreakSettings.spacebreak;
    },

    // Order visible CDTs by the order of their cann groups
    orderedScenarioCdts() {
      if (!this.visibleCanvasCannGroups) return this.scenarioCdts;

      // Create lookup object for faster access
      const visibleCannGroupsByKey = keyBy(this.visibleCanvasCannGroups, 'key');

      // Map CDTs with their cann group orders
      const cdtsWithCannGroups = this.scenarioCdts.reduce((acc, cdt) => {
        const cannGroup = visibleCannGroupsByKey[cdt.cannGroupId];

        if (cannGroup) {
          acc.push({
            ...cdt,
            order: cannGroup.order,
          });
        }
        return acc;
      }, []);

      // Sort by order
      return sortBy(cdtsWithCannGroups, 'order');
    },

    orderedScenarioCdtsKey() {
      return this.orderedScenarioCdts.map(cdt => cdt._id).join('-');
    },

    areCanvasProductsDownloading() {
      return this.formattedProductsData.length === 0;
    },

    loadingMessage() {
      if (this.isAssortmentOptimisationJobRunning) {
        return this.$t('messages.inProgress');
      }
      if (this.areCanvasProductsDownloading) {
        return this.$t('messages.canvasProductsLoadingInProgress');
      }
      return this.$t('messages.productsRendering');
    },

    selectedCanvasId() {
      return this.selectedCanvas._id;
    },

    gridContainerOverflow() {
      if (this.unifiedColumns[this.selectedView])
        return {
          width: '100%',
        };
      return { width: 'fit-content' };
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAssortmentCanvas);
    },

    hasCanvasFilterLegendEnabled() {
      return get(this.getClientConfig, 'features.canvasFilterLegendEnabled', false);
    },

    displayBy: {
      get() {
        return this.getDisplayBy;
      },
      set(value) {
        this.setDisplayBy(value);
      },
    },

    isDisplayByCDT() {
      return this.displayBy === DisplayBy.cdt;
    },

    isAlternativeCanvasDisplaysEnabled() {
      return get(
        this.getClientConfig,
        'features.scenario.assortmentCanvas.alternativeCanvasDisplaysEnabled',
        false
      );
    },

    displayByMenuItems() {
      return get(this.getClientConfig, 'assortmentCanvas.displayByMenuOptions', []);
    },

    showCustomAttributesInDisplayByMenu() {
      return get(
        this.getClientConfig,
        'features.scenario.assortmentCanvas.showCustomAttributesInDisplayByMenu',
        false
      );
    },

    scenarioCustomAttributesById() {
      return keyBy(this.getScenarioAttributes, 'id');
    },

    getDisplayByMenuFallbackName() {
      return this.scenarioCustomAttributesById[this.displayByMenu]
        ? this.scenarioCustomAttributesById[this.displayByMenu].name
        : this.displayByMenu;
    },
  },

  watch: {
    selectedCanvasId: {
      immediate: true,
      async handler() {
        await this.loadCanvas();
      },
    },
  },

  created() {
    // CDT should be selected by default
    this.setDisplayBy(DisplayBy.cdt);
    this.initializeCanvasCannGroupColumns();

    // init default second option
    if (this.isAlternativeCanvasDisplaysEnabled && !isEmpty(this.displayByMenuItems)) {
      this.displayByMenu = this.displayByMenuItems[0];
    }
  },

  destroyed() {
    // Reset the height counter as Vuex data is otherwise preserved and will
    // render the wrong heights on reload.
    // This may need to expand to cover other assortment canvas store data resets in the future.
    this.resetExpandedCdts();
    this.setAssortmentCanvasProducts([]);
    // Reset fullscreen and sticky header settings in vuex when component destroyed
    this.setFullScreen(false);
    this.setStickyHeaders(true);
    this.resetUnifiedColumns();
  },

  methods: {
    ...mapMutations('assortmentCanvas', [
      'setSpacebreaks',
      'setSelectedView',
      'setTileSize',
      'resetExpandedCdts',
      'setSelectedPod',
      'setSelectedCanvasAsOptimised',
      'setAssortmentCanvasProducts',
      'setFullScreen',
      'setStickyHeaders',
      'setUnifiedColumns',
      'closeSpacebreakSettingsPanel',
      'setActiveSpacebreak',
      'setIsProductSidebarOpen',
      'setDashboardProduct',
      'setDisplayBy',
    ]),
    ...mapMutations('scenarioCdts', ['setScenarioCdts']),
    ...mapMutations('scenarioProducts', ['setScenarioProducts']),
    ...mapActions('furniture', ['fetchScenarioFurniture']),
    ...mapActions('assortmentCanvas', [
      'fetchCanvasProducts',
      'fetchCanvases',
      'fetchCheckpoints',
      'updateAssortmentCanvas',
      'refreshReferenceCheckpointData',
      'setReferenceCheckpoint',
      'fetchCanvas',
      'changeCanvasRenderingStatus',
      'runAssortmentOptimisation',
      'refreshProductDeltas',
      'initializeCanvasCannGroupColumns',
      'reorderCanvasCannGroupColumns',
      'toggleCanvasCannGroupVisibility',
    ]),
    ...mapActions('scenarioCdts', ['fetchScenarioCdts']),
    ...mapActions('clustering', ['fetchScenarioClusters']),
    ...mapActions('scenarioProducts', ['fetchScenarioProducts']),

    async loadCanvas() {
      if (!this.selectedCanvas._id) return;
      const canvasId = this.selectedCanvas._id;
      this.setAssortmentCanvasProducts([]);
      // show the rendering spinner.
      this.changeCanvasRenderingStatus(true);
      this.resetUnifiedColumns();
      this.setActiveSpacebreak(null);

      await Promise.all([
        this.fetchCanvas({ canvasId }),
        this.fetchScenarioFurniture(),
        this.fetchScenarioClusters(),
        this.fetchScenarioCdts({ params: { selected: true } }),
      ]);
      await Promise.all([this.getCdtCols(), this.refreshReferenceCheckpointData()]);

      if (this.isAssortmentOptimisationJobRunning) {
        return;
      }

      this.setSpacebreaksForCanvas();
      this.setSelectedPod(
        this.getPointsOfDistribution[this.selectedCanvas.clusterId][
          this.selectedCanvas.storeClassId
        ]
      );
      // after pod was loaded, fetch products with deltas
      const [scenarioProducts] = await Promise.all([
        this.fetchScenarioProducts(),
        this.fetchCanvasProducts(canvasId),
      ]);
      // This needs to be run after fetch scenario products as it does not update state
      this.setScenarioProducts(scenarioProducts);

      this.closeSpacebreakSettingsPanel();
      this.stopRenderingScreen();
    },

    resetUnifiedColumns() {
      // Set unified columns state to values defined in config
      const unifiedColumnsSettings = get(this.clientConfig, 'canvasUnifiedColumns');
      each(unifiedColumnsSettings, (value, view) => {
        this.setUnifiedColumns({ value, view });
      });
    },

    // TODO: ensure user always has referenceCheckpoint. Otherwise error is thrown
    // webtool/client/js/store/modules/assortment-canvas.js:323
    // Cannot read property '_id' of undefined
    async closeCheckpoints() {
      // We re-fetch reference checkpoints on close only if the reference has changed from the current selection
      const oldReferenceCheckpointId = get(this.referenceCheckpoint, '_id', null);
      const newReferenceCheckpointId = this.selectedReferenceCheckpoint;
      const referenceCheckpointHasChanged = newReferenceCheckpointId !== oldReferenceCheckpointId;

      if (referenceCheckpointHasChanged) {
        await this.setReferenceCheckpoint({ oldReferenceCheckpointId, newReferenceCheckpointId });
        await this.refreshReferenceCheckpointData();
        this.refreshProductDeltas();
      }
    },

    setSpacebreaksForCanvas() {
      // This gives us the spacebreaks info on assortment canvas store
      // In the future, this will be returned in a single initial call so will read from that rather than the scen-furn store.
      const storeClass = find(this.scenarioFurniture.storeClasses, {
        _id: this.selectedCanvas.storeClassId,
      });
      const spacebreaks = storeClass ? storeClass.spacebreaks : [];
      // sorting is crucial here to ensure nesting and cumulative figures are correct
      this.setSpacebreaks(sortBy(spacebreaks, 'fillOverride'));
    },

    getCdtLeafs(cdts, cannGroupId, parentId = null) {
      const parents = filter(cdts, cdt => cdt.parentId === parentId);

      each(parents, p => {
        this.cdtCols.push({
          cdtId: p._id,
          cdtValue: [...(p.attributeValue || [])],
          cannGroupId,
        });
      });
    },

    getCdtCols() {
      each(this.scenarioCdts, scenarioCdt => {
        this.getCdtLeafs(scenarioCdt.cdt, scenarioCdt.cannGroupId);
      });
    },

    getCdtOrder(cannGroupId) {
      if (!this.visibleCanvasCannGroups) return null;

      const matchingCannGroup = find(this.visibleCanvasCannGroups, { key: cannGroupId });
      return matchingCannGroup ? matchingCannGroup.order : null;
    },

    async displayCheckpoints() {
      // if we navigate through toolbar we skip assortments panel component which fetches canvases
      // therefore we need to fetch it here in order to update checkpoints
      if (isEmpty(this.canvases)) await this.fetchCanvases();
      await this.fetchCheckpoints();
      await this.$refs.checkpointsDialog.open();
    },

    // Check if a checkpoint should be created before kicking off optimisation
    // First check if anything has changed vs the previous checkpoint (function returns true if there are changes in data)
    // If it has changes since last checkpoint, then ask the user if they want to create a checkpoint
    // If no changes, then just run the optimiser
    async isCheckpointCreationEligible() {
      const showCheckpointCreationModal = await jobApi.runFunction('checkpoint-creation-eligible', {
        canvas_id: this.selectedCanvas._id,
      });
      if (showCheckpointCreationModal) {
        await this.$refs.confirm.open();
      } else {
        await this.initOptimisation(false);
      }
    },

    async initOptimisation(createCheckpoint) {
      const payload = {
        scenarioId: this.selectedScenario._id,
        canvasIds: [this.selectedCanvas._id],
        useFurniture: true,
        createCheckpoint,
      };
      const err = await this.runAssortmentOptimisation(payload);
      await this.fetchCanvases();
      if (isEmpty(err)) {
        this.setAssortmentCanvasProducts([]);
      } else {
        this.stopRenderingScreen();
      }
    },

    stopRenderingScreen() {
      this.$nextTick(() => this.changeCanvasRenderingStatus(false));
    },

    async updateSelectedView(v) {
      // loading canvas products again here is more performant than replacing existing data in the grid
      // we replace data with [], set new view state, render progress bar,
      // then Vue destroys rendered grid component and starts rendering new one leaving the progress bar in place meanwhile
      this.setAssortmentCanvasProducts([]);
      this.setSelectedView(v);
      // reset any possible sidepanel selection
      this.setIsProductSidebarOpen(false);
      this.setDashboardProduct(null);
      // making this a local data flag, as updating the store state is too expensive
      this.renderingCanvas = true;
      await this.fetchCanvasProducts(this.selectedCanvas._id);
      this.renderingCanvas = false;
    },

    selectDisplayByMenuItem(item) {
      this.setDisplayBy(item);
      this.displayByMenu = item;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.main-container-canvas {
  height: 100%;
}

.infinite-canvas {
  width: 100%; // this gives a fixed width, we need this for scroll
  height: 100px; // this height is misleading, it is actually dynamic due to the flex-grow property, but we need this fixed height
  flex-direction: column;
  display: flex;
  overflow: auto;
}

.canvas-wrapper {
  height: 100px;
  display: flex;
  overflow: auto;
  flex-grow: 1;
}

.grid-container-wrapper {
  flex-grow: 1;
  overflow: auto;
}

.grid-container-overflow {
  position: relative;
}

.docs-link {
  margin-bottom: 2px;
}

.warning-banner {
  font-size: 1.5rem;
  color: $assortment-warning-alert-border;
}

.rtls-toggle .v-btn--active .v-icon {
  color: $canvas-header-white-background !important;
}

.display-by-title {
  font-size: 12px;
}
</style>
