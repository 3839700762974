/*
Event module to handle event type communication with the server.
For example messages for job updates and status updates
This module will handle the connection logic, and normally pass of the events to the appropriate other modules
*/

import { isEmpty } from 'lodash';
import eventEnums from '@enums/event-messages';
import { scenarioJobTypes, workpackageJobTypes } from '@enums/jobapi';

// --- Utility functions ---
// -- Utility function to create an event stream to the server for events
// Event handlers can be passed in for the various status changes
function setupEventStream({
  apiUrl,
  handler,
  connectHandler = null,
  disconnectHandler = null,
  dispatcher = null,
}) {
  let reconnectFrequencySeconds = 0.5; // first attempt to reconnect after .5 seconds
  let reconnectCount = 0;
  let source;
  const maxRetries = 120;

  // Util for reconnection handling from https://stackoverflow.com/a/54385402
  function throttle(func) {
    let timeout;
    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func();
      }, reconnectFrequencySeconds * 1000);
    };
  }

  // - Setup reconnection function with escalating frequency
  // attempt reconnection every `reconnectFrequencySeconds`
  const reconnectFunc = throttle(() => {
    reconnectCount += 1;

    // Force a token refresh, as this is the main reason why eventstream connections drops
    // Only force refresh every 5th connection attempt, to avoid refreshing the token just after another refresh
    if (reconnectCount > 1 && reconnectCount % 5 === 0 && dispatcher) {
      dispatcher('context/refreshUserContext', {}, { root: true });
    }

    if (reconnectCount < maxRetries) {
      // eslint-disable-next-line no-use-before-define
      setupEventSource();
    }

    // Double every attempt to avoid overwhelming server
    reconnectFrequencySeconds *= 2;
  });

  // - Function to create connection and setup handlers
  const setupEventSource = () => {
    source = new EventSource(apiUrl);
    source.addEventListener('open', () => {
      reconnectCount = 0;
      if (connectHandler) connectHandler();
    });

    source.addEventListener('error', () => {
      // close the source (e.g. on token timeout)
      source.close();

      // Use the disconnect handler - Only show this if the reconnect fails
      if (disconnectHandler && reconnectCount > 0) disconnectHandler();

      // Try and reconnect
      reconnectFunc();
    });

    source.addEventListener('message', e => {
      const message = JSON.parse(e.data);

      // blank messages are sent intermittently to keep the EventSource open
      if (isEmpty(message)) return;

      // Pass off to the actual message handler
      handler(message);
    });

    return source;
  };

  // - Initial connection attempt
  return setupEventSource();
}

// --- Standard vuex store code ---
const store = {
  namespaced: true,

  state: {
    eventstreamEnabled: false,
    eventStream: null,
    eventStreamWorkpackageId: 0,
    eventStreamScenarioId: 0,
    eventStreamConnectionHasProblem: false,
  },

  mutations: {
    setEventstreamEnabled(state, { enabled }) {
      state.eventstreamEnabled = enabled;
    },

    setEventStreamWorkpackageId(state, { workpackageId }) {
      state.eventStreamWorkpackageId = workpackageId;
    },

    setEventStreamScenarioId(state, { scenarioId }) {
      state.eventStreamScenarioId = scenarioId;
    },

    setEventStream(state, { eventStream }) {
      state.eventStream = eventStream;
    },

    setEventStreamConnectionProblemStatus(state, { hasProblem }) {
      state.eventStreamConnectionHasProblem = hasProblem;
    },
  },

  actions: {
    // Set if rabbit (eventstream) is enabled or not
    changeEventstreamEnabled({ commit }, { enabled }) {
      commit('setEventstreamEnabled', { enabled });
    },

    resetEventStreamWithWorkpackageId({ commit, state, dispatch }, { workpackageId }) {
      const hasChanged = workpackageId !== state.eventStreamWorkpackageId;
      if (hasChanged) {
        commit('setEventStreamWorkpackageId', { workpackageId });
        dispatch('resetEventStream');
      }
    },

    resetEventStreamWithScenarioId({ commit, state, dispatch }, { scenarioId }) {
      const hasChanged = scenarioId !== state.eventStreamScenarioId;
      if (hasChanged) {
        commit('setEventStreamScenarioId', { scenarioId });
        dispatch('resetEventStream');
      }
    },

    resetEventStream({ commit, state, dispatch }) {
      if (state.eventstreamEnabled) {
        if (state.eventStream) {
          state.eventStream.close();
          commit('setEventStream', { eventStream: null });
        }
        const apiUrl = `/api/events/workpackage/${state.eventStreamWorkpackageId}/scenario/${
          state.eventStreamScenarioId
        }`;

        // -- Setup handlers for different types of messages
        const handler = function(message) {
          // -- If job message
          if (message.msgType === eventEnums.assortmentMessageTypes.jobUpdate) {
            dispatch('jobs/handleJobEventMessage', { message }, { root: true });
            if (Object.values(workpackageJobTypes).includes(message.jobType)) {
              dispatch('workpackages/updateWorkpackageJobStatus', { message }, { root: true });
            }
            if (Object.values(scenarioJobTypes).includes(message.jobType)) {
              dispatch('scenarios/updateScenarioJobStatus', { message }, { root: true });
            }
            // When the status has changed it likely means there will be new notifications to be loaded
            if (message.statusHasChanged) {
              dispatch('userNotifications/fetchUserNotifications', {}, { root: true });
            }
          }
          if (message.msgType === eventEnums.assortmentMessageTypes.statusUpdate) {
            dispatch('scenarios/updateScenarioStatus', {}, { root: true });
          }
        };

        const connectHandler = function() {
          commit('setEventStreamConnectionProblemStatus', { hasProblem: false });

          // On connect, should fetch the scenario status and job status again
          // (because we might have missed things while disconnected, or before we selected a scenario)
          if (state.eventStreamScenarioId !== 0) {
            dispatch('scenarios/updateScenarioStatus', {}, { root: true });
            dispatch('scenarios/updateScenarioJobStatus', {}, { root: true });
          }
        };

        const disconnectHandler = function() {
          commit('setEventStreamConnectionProblemStatus', { hasProblem: true });
        };

        // -- Open event stream for messages
        const eventStream = setupEventStream({
          apiUrl,
          handler,
          connectHandler,
          disconnectHandler,
          dispatcher: dispatch,
        });
        commit('setEventStream', { eventStream });
      } else {
        // -- Eventstreaming is disabled, setup polling instead for job statuses.
        // Note, this should only be the case during development - on live servers event streams should be used
        // Create polling function
        const pollJobs = () => {
          dispatch('workpackages/updateWorkpackageJobStatus', {}, { root: true });
          setTimeout(pollJobs, 20000);
        };

        console.warn('Eventstreams disabled - using polling instead');
        pollJobs();
      }
    },
  },
};

export default store;
