<template>
  <div data-id-e2e="btnDownloadRenderer">
    <v-row v-if="isExtractGenerationJobRunning" class="generating-row">
      <span class="generating-text">{{ $t('actions.generating') }}</span>
      <img class="spinner-icon" src="../../../img/spinner.gif" />
    </v-row>
    <download-link
      v-else
      :filename="extract.filenameOnDownload"
      :url="buildLink(extract.blobId)"
      :disabled="isDisabled"
    >
      <v-btn
        data-id-e2e="btnDownloadExtracts"
        class="download-extracts__button"
        secondary
        depressed
        :disabled="isDisabled"
      >
        {{ $t('actions.download') }}
        <img
          class="download-extracts__icon"
          :src="downloadExtractIcon"
          alt="Download extract icon"
        />
      </v-btn>
    </download-link>
  </div>
</template>

<script>
import Vue from 'vue';
import { get, size } from 'lodash';
import { mapGetters } from 'vuex';
import { jobStatuses } from '@enums/jobapi';
import downloadExtractIcon from '@/img/downloadExtract.svg';

export default Vue.extend({
  name: 'downloadButtonRenderer',
  data() {
    return {
      downloadExtractIcon,
    };
  },
  computed: {
    ...mapGetters('compoundExtract', ['getExtractById']),

    extractId() {
      return get(this.params, 'extractId');
    },

    extractTypes() {
      return get(this.params, 'extractTypes', []);
    },

    extract() {
      return this.getExtractById(this.extractId) || {};
    },

    isDisabled() {
      return (
        !this.hasPermission(this.userPermissions.canEditCompoundExtracts) ||
        !size(this.extract.blobId)
      );
    },

    extractGenerationJobStatus() {
      return get(this.extract, 'jobs.compoundExtract.status');
    },

    isExtractGenerationJobRunning() {
      return (
        this.extractGenerationJobStatus &&
        ![jobStatuses.finished, jobStatuses.failed].includes(this.extractGenerationJobStatus)
      );
    },
  },

  methods: {
    buildLink(blobId) {
      return `/api/file/by-blob/${blobId}`;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

a {
  text-decoration: none;
}

::v-deep {
  .download-extracts {
    &__button {
      height: 29px !important;
      padding: 0 12px !important;

      > span {
        font-size: 1.4rem;
      }
    }

    &__icon {
      font-size: 1.6rem;
      margin-left: 5px;
    }
  }

  .generating-row {
    .generating-text {
      margin: 0px 4px 0px 8px;
      font-size: 14px;
    }

    .spinner-icon {
      height: 18px;
      width: 18px;

      align-self: center;
    }
  }
}
</style>
