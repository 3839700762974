const _ = require('lodash');

/**
 * Helper function to convert a product level switching matrix to an attribute
 * value level switching matrix
 *
 * @param {Array<int>}           productKeys       Product keys
 * @param {Array<Array<Number>>} matrix            Product level switching matrix
 * @param {Array<Object>}        products          Products, each object must have productKey
 *                                                 and customAttributes fields
 * @param {String}               selectedAttribute The name of the selected attribute
 */
const calculateSwitchingMatrixByAttributes = function(
  productKeys,
  matrix,
  products,
  selectedAttribute
) {
  // Create map of each product to it's attribute value matching @param selectedAttribute
  const productKeyAttrValueMap = products.reduce((obj, product) => {
    product.customAttributes.forEach(ca => {
      if (ca.name === selectedAttribute) {
        obj[product.productKey] = ca.value;
      }
    });
    return obj;
  }, {});

  const uniqueSortedAttributeValues = [...new Set(Object.values(productKeyAttrValueMap))].sort();

  // Map each attribute value to it's index in the final matrix
  const attrValueIndexMap = uniqueSortedAttributeValues.reduce((obj, attrValue, index) => {
    obj[attrValue] = index;
    return obj;
  }, {});

  const numAttributes = uniqueSortedAttributeValues.length;
  const attrMatrix = Array(numAttributes)
    .fill()
    .map(() => Array(numAttributes).fill(0));

  // Now convert each products from/to switching value to the attribute value from/to switching value
  for (let i = 0; i < productKeys.length; i += 1) {
    const productKeyFrom = productKeys[i];
    const attrValueIndexFrom = attrValueIndexMap[productKeyAttrValueMap[productKeyFrom]];
    for (let j = 0; j < productKeys.length; j += 1) {
      const productKeyTo = productKeys[j];
      const attrValueIndexTo = attrValueIndexMap[productKeyAttrValueMap[productKeyTo]];
      attrMatrix[attrValueIndexFrom][attrValueIndexTo] += matrix[i][j];
    }
  }

  // Re-normalise the new attribute matrix
  for (let i = 0; i < numAttributes; i += 1) {
    const rowTotal = _.sum(attrMatrix[i]);
    for (let j = 0; j < numAttributes; j += 1) {
      attrMatrix[i][j] /= rowTotal;
    }
  }

  return {
    labels: uniqueSortedAttributeValues,
    matrix: attrMatrix,
  };
};

export default {
  calculateSwitchingMatrixByAttributes,
};
