var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass:
        "arrow-indicator d-flex h-100 w-100 align-center justify-space-between",
      class: { "arrow-indicator--reverse": _vm.reverse },
    },
    [
      _c("i", {
        staticClass: "arrow-indicator__icon fa",
        class: _vm.classRules,
      }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.valueFormatted))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }