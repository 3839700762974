'use strict';

module.exports = {
  defaultLocale: 'en',
  fallbackLocale: 'en',
  availableLocales: ['en'],
  availableDateLocales: ['en', 'en-gb'],
  numericLocale: 'en-gb',
  displayNegativesInParentheses: false,
};
