import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      staticClass: "list-group",
      class: { disabled: _vm.disabled },
      attrs: {
        "ghost-class": "dragged",
        draggable: ".list-group__attribute",
        group: _vm.group,
        list: _vm.items,
        disabled: _vm.disabled,
      },
    },
    [
      _vm._l(_vm.items, function (element) {
        return _c(
          "div",
          { key: element.id, staticClass: "item list-group__attribute pr-2" },
          [
            _vm._v("\n    " + _vm._s(element.name) + "\n    "),
            _c(VIcon, { attrs: { size: "10" } }, [_vm._v("$cross-move")]),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.showFooter
        ? _c(
            "div",
            {
              staticClass: "item list-group__drop",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.$t("varietyGroups.dragAttrHere")) + "\n  "
              ),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }