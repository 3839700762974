const kasuExtractSteps = [
  {
    componentName: 'workpackage-checkpoint-selector',
    order: 1,
    translationKey: 'extract.reports.series.steps.selector',
    statusSection: 'resourcesSelector',
  },
  {
    componentName: 'kasu-output-report',
    order: 2,
    translationKey: 'extract.reports.series.steps.kasuExtracts',
    statusSection: 'output',
  },
];

const hasuExtractSteps = [
  {
    componentName: 'workpackage-checkpoint-selector',
    order: 1,
    translationKey: 'extract.reports.series.steps.selector',
    statusSection: 'resourcesSelector',
  },
  {
    componentName: 'hasu-output-report',
    order: 2,
    translationKey: 'extract.reports.series.steps.hasuExtracts',
    statusSection: 'output',
  },
];

const aocExtractSteps = [
  {
    componentName: 'workpackage-checkpoint-selector',
    order: 1,
    translationKey: 'extract.reports.series.steps.selector',
    statusSection: 'resourcesSelector',
  },
  {
    componentName: 'aoc-output-report',
    order: 2,
    translationKey: 'extract.reports.series.steps.aocExtracts',
    statusSection: 'output',
  },
];

export default {
  kasuExtractSteps,
  hasuExtractSteps,
  aocExtractSteps,
};
