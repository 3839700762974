import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _vm.hasErrors
        ? _c("error-triangle", {
            staticClass: "end-date-error-triangle",
            attrs: { errors: _vm.params.errors },
          })
        : _c(VCheckbox, {
            attrs: { readonly: "", "input-value": _vm.isSelected },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }