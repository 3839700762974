import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column align-start cell-container",
      class: _vm.editingModeClass,
    },
    [
      !_vm.editingMode
        ? _c("div", { staticClass: "constraint-description" }, [
            _vm._v(_vm._s(_vm.constraintDescription)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _vm.isSelected
            ? _c("rtls-select", {
                attrs: {
                  value: _vm.attributeValue,
                  white: "",
                  width: "80%",
                  items: _vm.itemsSorted,
                  "item-text": "name",
                  "item-value": "id",
                  placeholder: _vm.$t("general.select"),
                  "menu-props": { "z-index": 200 },
                  "hide-details": "",
                },
                on: { input: _vm.setAttributeValue },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.isSelected
            ? _c("error-triangle", {
                staticClass: "attributes-filter-renderer-triangle",
                attrs: {
                  errors: {
                    [_vm.$tkey("errorMessages.setAttributeName")]:
                      _vm.attributeValue,
                    [_vm.$tkey("errorMessages.setAttributeValue")]:
                      _vm.allAttributeValuesAreAssigned,
                    [_vm.$tkey("errorMessages.setAttributeValueAsUnique", [
                      _vm.spacebreakId === null
                        ? _vm.$tkey("errorMessages.uniqueInSpacebreak")
                        : "",
                    ])]: _vm.isUnique,
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isSelected
        ? _c(
            "div",
            { staticClass: "d-flex flex-column" },
            [
              _c(
                "div",
                _vm._l(_vm.selectedFilters, function (filter, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "d-flex flex-row ml-2 mt-2 filter-value-row",
                    },
                    [
                      _c("rtls-select", {
                        key: index,
                        attrs: {
                          white: "",
                          width: "80%",
                          items: _vm.getUnselectedValues(index),
                          placeholder: _vm.$t("general.select"),
                          "menu-props": { "z-index": 200 },
                          "hide-details": "",
                        },
                        model: {
                          value: filter.value,
                          callback: function ($$v) {
                            _vm.$set(filter, "value", $$v)
                          },
                          expression: "filter.value",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "d-flex align-center position-relative",
                        },
                        [
                          _vm.selectedFilters.length > 1
                            ? _c(
                                VBtn,
                                {
                                  staticClass: "pt-1 remove-attr-value-btn",
                                  attrs: { icon: "", text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeAttributeValue(
                                        filter.value
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    VIcon,
                                    { attrs: { size: "20", color: "#d7534e" } },
                                    [_vm._v("mdi-alpha-x-circle-outline")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.totalValidSelectedAttributeValues > 0 &&
                          index === _vm.selectedFilters.length - 1
                            ? _c(
                                "span",
                                { staticClass: "applied-filters-count" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$tc(
                                          "assortmentCanvasPage.spacebreakSettings.constraints.filtersApplied",
                                          _vm.totalValidSelectedAttributeValues
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  staticClass: "mt-1",
                  attrs: { icon: "", text: "" },
                  on: { click: _vm.addAttributeValue },
                },
                [
                  _c(VIcon, { attrs: { color: "#2f477c" } }, [
                    _vm._v("mdi-plus-box"),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }