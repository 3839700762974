<template>
  <div class="heatmap-panel">
    <v-alert v-if="!showGrid" type="info" text>
      {{ $t('switchingBehaviour.pleaseSelectAttribute') }}
    </v-alert>
    <progress-bar v-else-if="isLoading" />
    <switching-heatmap-grid v-else :grid-data="gridData" />
  </div>
</template>

<script>
import { map, filter, each, merge, keyBy, cloneDeep } from 'lodash';
import { mapState, mapActions, mapMutations } from 'vuex';
import matrixHelper from '../helpers/switchingBehaviour';
import switchingMatrixMixin from '../mixins/switching-matrix';

export default {
  mixins: [switchingMatrixMixin],

  props: {
    cannGroup: {
      required: true,
      type: Object,
    },
    matrixOptions: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      gridData: null,
      switchingMatrix: null,
      stepSize: 5,
      isLoading: false,
    };
  },

  computed: {
    ...mapState('switchingMatrices', ['switchingMatrixForCannGroup']),
    ...mapState('scenarioProducts', ['scenarioProducts']),
    ...mapState('scenarios', ['selectedScenario']),

    showGrid() {
      return (
        this.matrixOptions.displayOption === 'product' ||
        (this.matrixOptions.displayOption !== 'product' && this.matrixOptions.selectedAttribute)
      );
    },

    productsMap() {
      return keyBy(this.scenarioProducts, 'productKey');
    },

    displayOption() {
      return this.matrixOptions.displayOption;
    },
  },

  watch: {
    async cannGroup() {
      await this.drawGraph();
    },

    matrixOptions: {
      deep: true,
      handler() {
        this.drawGraph();
      },
    },
  },

  created() {
    this.getSwitchingMatricesAndProducts();
    this.drawGraph();
  },

  methods: {
    ...mapActions('switchingMatrices', ['fetchCannGroupSwitchingMatrix']),
    ...mapActions('scenarioProducts', ['fetchScenarioProducts']),
    ...mapActions('snackbar', ['showWarning']),
    ...mapMutations('scenarioProducts', ['setScenarioProducts']),

    async getSwitchingMatricesAndProducts() {
      if (this.params) {
        await this.fetchCannGroupSwitchingMatrix({
          params: {
            scenarioId: this.selectedScenario._id,
            cannGroupId: this.params.cannGroup.key,
          },
        });
      }
      const scenarioProducts = await this.fetchScenarioProducts(this.selectedScenario);
      this.setScenarioProducts(scenarioProducts);
    },

    async drawGraph() {
      this.isLoading = true;
      await this.fetchCannGroupSwitchingMatrix({
        params: {
          scenarioId: this.selectedScenario._id,
          cannGroupId: this.cannGroup.key,
        },
      });
      if (this.switchingMatrixForCannGroup.errors) {
        return this.showWarning(this.$t('switchingBehaviour.emptyMatrix'));
      }
      this.switchingMatrix = this.switchingMatrixForCannGroup.data;

      if (this.matrixOptions.displayOption === 'product') {
        this.gridData = {
          data: this.prepareDataSet(
            cloneDeep(this.switchingMatrix.matrix),
            this.switchingMatrix.productKeys
          ),
          labels: this.switchingMatrix.productKeys,
          ...this.getGridOptions(),
        };
      }
      if (this.matrixOptions.displayOption !== 'product' && this.matrixOptions.selectedAttribute) {
        this.scenarioAttributes = map(this.scenarioProducts, sp => ({
          customAttributes: sp.customAttributes,
          productKey: sp.productKey,
        }));

        const mergeAttributes = function(scenarioAttr, customAttributes) {
          const newCustomAttributes = [];
          each(scenarioAttr.customAttributes, function(customAttr) {
            const attrName = filter(customAttributes, o => o.id === customAttr.id)[0].name;
            newCustomAttributes.push(merge(customAttr, { name: attrName }));
          });
          return newCustomAttributes;
        };

        const customAttributes = this.selectedScenario.customAttributes;
        for (let i = 0; i < this.scenarioAttributes.length; i += 1) {
          this.scenarioAttributes[i].customAttributes = mergeAttributes(
            this.scenarioAttributes[i],
            customAttributes
          );
        }

        const results = matrixHelper.calculateSwitchingMatrixByAttributes(
          this.switchingMatrix.productKeys,
          this.switchingMatrix.matrix,
          this.scenarioAttributes.filter(x =>
            this.switchingMatrix.productKeys.includes(x.productKey)
          ),
          this.matrixOptions.selectedAttribute
        );

        this.gridData = {
          labels: results.labels,
          data: this.prepareDataSet(cloneDeep(results.matrix), results.labels),
          ...this.getGridOptions(results.labels),
        };
      }
      this.isLoading = false;
    },

    getGridOptions(attributesLabels) {
      return {
        headersMap: this.displayOption === 'product' ? this.productsMap : keyBy(attributesLabels),
        displayOption: this.displayOption,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
