import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comparison-body" },
    [
      _vm.loaded
        ? _c(
            VContainer,
            {
              staticStyle: { "min-width": "max-content" },
              attrs: { fluid: "" },
            },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    VCol,
                    { staticClass: "comparisons", attrs: { cols: "auto" } },
                    [
                      _c("highcharts", {
                        attrs: { options: _vm.chartOptionsClusterNames },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider, { attrs: { vertical: "" } }),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { attrs: { cols: "auto" } },
                    [
                      _c("highcharts", {
                        attrs: { options: _vm.chartOptionsFormat },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider, { attrs: { vertical: "" } }),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { attrs: { cols: "auto" } },
                    [
                      _c("highcharts", {
                        attrs: { options: _vm.chartOptionsProfile },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider, { attrs: { vertical: "" } }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded && _vm.comparisonSchemeId
        ? _c(
            VContainer,
            {
              staticStyle: { "min-width": "max-content" },
              attrs: { fluid: "" },
            },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    VCol,
                    { staticClass: "comparisons", attrs: { cols: "auto" } },
                    [
                      _c("highcharts", {
                        attrs: {
                          options: _vm.comparisonChartOptionsClusterNames,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider, { attrs: { vertical: "" } }),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { attrs: { cols: "auto" } },
                    [
                      _c("highcharts", {
                        attrs: { options: _vm.comparisonChartOptions },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }