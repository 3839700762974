<template>
  <div data-id-e2e="combobox-renderer">
    <v-combobox :value="params.value" :items="options" @input="onChange" />
  </div>
</template>

<script>
import { get, toLower } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'comboboxRenderer',
  localizationKey: 'scenarioInputPage.attributes',

  computed: {
    options() {
      return get(this.params, 'options');
    },
  },

  methods: {
    onChange(value) {
      const fieldId = this.params.colDef.field;
      const newValue = this.params.preventValueToLower ? value : toLower(value);
      this.params.node.setDataValue(fieldId, newValue.trim()); // trigger cell update
      // refresh all cells in the column for the new options
      this.params.api.refreshCells({ force: true });
    },
  },
});
</script>
