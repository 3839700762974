import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "map-fields-list pa-1" },
    [
      _c(
        "div",
        { staticClass: "map-fields-list__subtitle" },
        [
          _c("p", { staticClass: "mb-1" }, [
            _vm._v("\n      " + _vm._s(_vm.$tkey("subtitle")) + "\n    "),
          ]),
          _vm._v(" "),
          _c(
            "download-link",
            { attrs: { url: _vm.downloadFileUrl } },
            [
              _c(
                VBtn,
                { attrs: { small: "", text: "", color: "primary" } },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$tkey("uploadedFile")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "mt-1 mb-0" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$tkey("autoMappedFields", {
                    numMappedFields: _vm.automaticMappings.length,
                  })
                ) +
                ":\n      " +
                _vm._s(_vm.automaticMappings.join(", ")) +
                "\n    "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(VDivider),
      _vm._v(" "),
      _c(
        VList,
        { staticClass: "mt-2", attrs: { dense: "" } },
        [
          _c(
            VListItem,
            { staticClass: "divider" },
            [
              _c(
                VCol,
                {
                  staticClass: "pl-0 pb-1 font-weight-bold",
                  attrs: { cols: "6" },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$tkey("expected")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                VCol,
                {
                  staticClass: "pl-0 pb-1 font-weight-bold",
                  attrs: { cols: "6" },
                },
                [_vm._v("\n        " + _vm._s(_vm.$tkey("csv")) + "\n      ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.unmappedExpectedFields, function (header) {
            return _c(
              VListItem,
              { key: header.mongoField, attrs: { dense: "" } },
              [
                _c(
                  VCol,
                  { staticClass: "pa-1 pl-0", attrs: { cols: "6" } },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t(header.translationKey)) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  VCol,
                  { staticClass: "pa-1 pl-0", attrs: { cols: "6" } },
                  [
                    _c("rtls-select", {
                      attrs: {
                        items: _vm.unmappedFields,
                        placeholder: _vm.$tkey("selectField"),
                        clearable: "",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.updateMapping(header.mongoField, $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        VCardActions,
        [
          _c(VSpacer),
          _vm._v(" "),
          _c(VBtn, { attrs: { text: "" }, on: { click: _vm.cancel } }, [
            _vm._v(_vm._s(_vm.$t("actions.cancel"))),
          ]),
          _vm._v(" "),
          _c(
            VBtn,
            {
              attrs: { disabled: _vm.confirmDisabled, small: "", primary: "" },
              on: { click: _vm.confirmMappings },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("actions.confirm")) + "\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }