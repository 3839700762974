import permissions from '@enums/user-permissions';

import stats from '@/js/pages/reporting/detailed/stats.vue';
import overview from '@/js/pages/reporting/overview.vue';
import reporting from '@/js/pages/reporting/reporting.vue';
import relativeShare from '@/js/pages/reporting/detailed/relative-share.vue';
import priceLadder from '@/js/pages/reporting/detailed/price-ladder.vue';
import comparisonWaterfall from '@/js/pages/reporting/detailed/comparison-waterfall.vue';
import product from '@/js/pages/reporting/detailed/product.vue';

const reportingRoutes = [
  {
    path: '/reporting-main',
    component: reporting,
    linkText: 'navBar.reporting',
    omitFromNav: false,
    omitChildrenFromNavbar: true,
    meta: {
      featureKey: 'reportingControlsEnabled',
      requiresPermission: permissions.canViewReportingPage,
      titleExtension: 'reportingPage.title',
    },
    children: [
      {
        path: '',
        id: 'reporting',
        name: 'reporting',
        component: overview,
        meta: {
          featureKey: 'reportingControlsEnabled',
          requiresPermission: permissions.canViewReportingPage,
          titleExtension: 'reportingPage.overview',
        },
      },
      {
        path: 'stats',
        component: stats,
        meta: {
          featureKey: 'reportingControlsEnabled',
          requiresPermission: permissions.canViewStatsReport,
          titleExtension: 'reportingPage.sections.stats.title',
        },
      },
      {
        path: 'relative-share',
        component: relativeShare,
        meta: {
          featureKey: 'reportingControlsEnabled',
          requiresPermission: permissions.canViewRelativeShareReport,
          titleExtension: 'reportingPage.sections.relative-share.title',
        },
      },
      {
        path: 'price-ladder',
        component: priceLadder,
        meta: {
          featureKey: 'reportingControlsEnabled',
          requiresPermission: permissions.canViewPriceLadderReport,
          titleExtension: 'reportingPage.sections.price-ladder.title',
        },
      },
      {
        path: 'comparison-waterfall',
        component: comparisonWaterfall,
        meta: {
          featureKey: 'reportingControlsEnabled',
          requiresPermission: permissions.canViewComparisonWaterfallReport,
          titleExtension: 'reportingPage.sections.comparison-waterfall.title',
        },
      },
      {
        path: 'product',
        component: product,
        meta: {
          featureKey: 'reportingControlsEnabled',
          requiresPermission: permissions.canViewProductReport,
          titleExtension: 'reportingPage.sections.product.title',
        },
      },
    ],
  },
];

export default reportingRoutes;
