'use strict';

const DataTypes = require('../../enums/data-types');

const fixedFields = [
  {
    mongoField: 'productKeyDisplay',
    type: DataTypes.alphanumeric,
    // The comparison function uses Lodash _.lowerCase which converts string, as space separated words, to lower case.
    // 'Product Key' === 'product key'  |  'productKey' === 'product key'  |  '--Product-Key--' === 'product key'
    aliases: 'csvAliases.workpackageProducts.productKeyDisplay',
    translationKey: 'mongoFields.productKeyDisplay',
    required: true,
  },
];

module.exports = {
  fixedFields,
};
