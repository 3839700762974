import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "d-flex justify-end align-end mr-0" },
    [
      _c(VCheckbox, {
        staticClass: "mx-2 mr-0 rtls-checkbox",
        attrs: {
          "data-id-e2e": "toggleClusterUseUnclusteredCheckboxAllClusters",
          "input-value": _vm.isEveryClusterSetToUnclustered,
          disabled: _vm.isUsageOfUnclusteredDisabled,
        },
        on: { change: _vm.setAllClustersToUnclustered },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }