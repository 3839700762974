import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "enabled-product-tile",
      class: { "h-100": _vm.props.parentIsDashboard },
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "vue-draggable-resizable",
        {
          class: {
            "drag-cancel": _vm.props.parentIsDashboard,
            "draggable-resizable": !_vm.props.parentIsDashboard,
            "draggable-fit-content": !_vm.props.parentIsDashboard,
          },
          style: `${
            _vm.props.settings.left ? `left: ${_vm.props.settings.left}px;` : ""
          }${
            _vm.props.settings.right
              ? `right: ${_vm.props.settings.right}px;`
              : ""
          } top: ${_vm.props.settings.top}px; max-height: ${
            !_vm.props.parentIsDashboard
              ? _vm.props.settings.height + "px"
              : "auto"
          };`,
          attrs: {
            w: !_vm.props.parentIsDashboard ? _vm.props.settings.width : "auto",
            h: !_vm.props.parentIsDashboard
              ? _vm.props.settings.height
              : "auto",
            parent: false,
            z: _vm.props.settings.zIndex,
            "drag-cancel": ".drag-cancel",
          },
        },
        [
          _c(
            "div",
            {
              class: {
                "d-flex flex-column product-content-container":
                  !_vm.props.parentIsDashboard,
              },
            },
            [
              !_vm.props.parentIsDashboard
                ? _c(
                    "div",
                    {
                      staticClass: "d-flex justify-space-between heading mb-1",
                    },
                    [
                      _c("div", { staticClass: "product-title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.props.productInfo.itemDescription) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "close",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.listeners["close-popup"](
                                _vm.props.productIndex
                              )
                            },
                          },
                        },
                        [
                          _c(
                            VIcon,
                            { attrs: { size: 18, color: "#2f477c" } },
                            [_vm._v("mdi-close-circle")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c("p", { staticClass: "mb-1 copy-text" }, [
                  _c("img", {
                    staticClass: "mr-1",
                    attrs: { src: _vm.props.copyIcon },
                  }),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.parent.$t("assortmentCanvas.copyText")) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  class: {
                    "d-flex": true,
                    "main-body": !_vm.props.parentIsDashboard,
                    "pr-4": _vm.props.parentIsDashboard,
                    "flex-column": !_vm.props.parentIsDashboard,
                  },
                },
                [
                  !_vm.props.parentIsDashboard
                    ? _c("div", { staticClass: "popup-left-side" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex product-tile position-relative flex-column justify-start",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "image-wrapper" },
                              [
                                _c(
                                  "assortment-image",
                                  _vm._b(
                                    {
                                      attrs: {
                                        src: _vm.props.imageUrl,
                                        alt: _vm.props.imageAlt,
                                        title: _vm.props.imageTitle,
                                        "tooltip-text": `${_vm.props.imageTitle} - ${_vm.props.productInfo.itemDescription}`,
                                        "tag-text": _vm.props.imageTagText,
                                        "show-not-in-reset-icon":
                                          !_vm.props.productInfo.inReset,
                                        dashboard: "",
                                      },
                                    },
                                    "assortment-image",
                                    _vm.props.conditionalProductTileStyle,
                                    false
                                  )
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column info-table justify-center flex-grow-3",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-space-between product-tooltip-row",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold mr-4 copy-to-clipboard",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$options.copyToClipboard(
                                          `${_vm.props.productInfo.productKeyDisplay}`,
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.parent.$t(
                                            "assortmentCanvas.productKeyDisplay"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "overflow-ellipsis",
                                    attrs: {
                                      title:
                                        _vm.props.productInfo.productKeyDisplay,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.props.productInfo
                                            .productKeyDisplay
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.props.productInfo.futureProductIdString
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-space-between product-tooltip-row",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-bold mr-4 copy-to-clipboard",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$options.copyToClipboard(
                                              `${_vm.props.productInfo.futureProductIdString}`,
                                              $event
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.parent.$t(
                                                "assortmentCanvas.futureProductId"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "overflow-ellipsis",
                                        attrs: {
                                          title:
                                            _vm.props.productInfo
                                              .futureProductIdString,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.props.productInfo
                                                .futureProductIdString
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-space-between product-tooltip-row",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold mr-4 copy-to-clipboard",
                                    on: {
                                      click: function ($event) {
                                        return _vm.$options.copyToClipboard(
                                          `${_vm.props.productInfo.itemDescription}`,
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.parent.$t(
                                            "assortmentCanvas.productDescription"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "overflow-ellipsis",
                                    attrs: {
                                      title:
                                        _vm.props.productInfo.itemDescription,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.props.productInfo.itemDescription
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-space-between product-tooltip-row",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold mr-4 copy-to-clipboard",
                                    on: {
                                      click: function ($event) {
                                        _vm.$options.copyToClipboard(
                                          `${_vm.parent.formatNumber({
                                            number:
                                              _vm.props.productInfo
                                                .contentValue,
                                            format: "float",
                                          })}`,
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.parent.$t(
                                            "assortmentCanvas.contentUom"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "overflow-ellipsis",
                                    attrs: {
                                      title: `${_vm.parent.formatNumber({
                                        number:
                                          _vm.props.productInfo.contentValue,
                                        format: "float",
                                      })} ${
                                        _vm.props.productInfo
                                          .contentUnitOfMeasure
                                      }`,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.parent.formatNumber({
                                            number:
                                              _vm.props.productInfo
                                                .contentValue,
                                            format: "float",
                                          })
                                        ) +
                                        "\n                " +
                                        _vm._s(
                                          _vm.props.productInfo
                                            .contentUnitOfMeasure
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-space-between product-tooltip-row",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold mr-4 copy-to-clipboard",
                                    on: {
                                      click: function ($event) {
                                        _vm.$options.copyToClipboard(
                                          `${_vm.parent.formatNumber({
                                            number: _vm.props.productInfo.size,
                                            format: "float",
                                          })}`,
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.parent.$t(
                                            "assortmentCanvas.dimensions"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "overflow-ellipsis",
                                    attrs: {
                                      title: _vm.parent.formatNumber({
                                        number: _vm.props.productInfo.size,
                                        format: "float",
                                      }),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.parent.formatNumber({
                                            number: _vm.props.productInfo.size,
                                            format: "float",
                                          })
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column",
                      class: {
                        "popup-right-side": !_vm.props.parentIsDashboard,
                        "popup-centre": _vm.props.parentIsDashboard,
                      },
                    },
                    [
                      _c("div", { staticClass: "d-flex flex-column" }, [
                        _vm.props.parentIsDashboard
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex product-dashboard position-relative",
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "assortment-image",
                                      _vm._b(
                                        {
                                          attrs: {
                                            src: _vm.props.imageUrl,
                                            alt: _vm.props.imageAlt,
                                            title: _vm.props.imageTitle,
                                            "tag-text": _vm.props.imageTagText,
                                            "tooltip-text": `${_vm.props.imageTitle} - ${_vm.props.productInfo.itemDescription}`,
                                            "show-not-in-reset-icon":
                                              !_vm.props.productInfo.inReset,
                                            dashboard: "",
                                          },
                                        },
                                        "assortment-image",
                                        _vm.props.conditionalProductTileStyle,
                                        false
                                      )
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column info-table" },
                        [
                          _vm._l(_vm.props.selectedKpis, function (kpi, i) {
                            return _c(
                              "div",
                              {
                                key: i,
                                staticClass:
                                  "d-flex justify-space-between product-tooltip-row kpi-row",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "font-weight-bold mr-4 copy-to-clipboard",
                                    on: {
                                      click: function ($event) {
                                        _vm.$options.copyToClipboard(
                                          _vm.$options.methods.getKPIValue(
                                            _vm.parent,
                                            _vm.props,
                                            kpi
                                          ),
                                          $event
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$options.methods.getKpiTitle(
                                            _vm.parent,
                                            _vm.props,
                                            kpi
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "overflow-ellipsis",
                                    attrs: {
                                      title: `${_vm.$options.methods.getKPIValue(
                                        _vm.parent,
                                        _vm.props,
                                        kpi
                                      )}`,
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$options.methods.getKPIValue(
                                            _vm.parent,
                                            _vm.props,
                                            kpi
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _vm.props.productInfo.averageTransactionWeight
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-space-between product-tooltip-row",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "font-weight-bold mr-4 copy-to-clipboard",
                                      on: {
                                        click: function ($event) {
                                          _vm.$options.copyToClipboard(
                                            `${_vm.parent.formatNumber({
                                              number:
                                                _vm.props.productInfo
                                                  .averageTransactionWeight *
                                                _vm.props.productInfo
                                                  .contentValue,
                                              format: "float",
                                            })}`,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.parent.$t(
                                              "assortmentCanvas.volumeUom"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "overflow-ellipsis",
                                      attrs: {
                                        title: _vm.parent.formatNumber({
                                          number:
                                            _vm.props.productInfo
                                              .averageTransactionWeight *
                                            _vm.props.productInfo.contentValue,
                                          format: "float",
                                        }),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            `${_vm.parent.formatNumber({
                                              number:
                                                _vm.props.productInfo
                                                  .averageTransactionWeight *
                                                _vm.props.productInfo
                                                  .contentValue,
                                              format: "float",
                                            })} ${
                                              _vm.props.productInfo
                                                .contentUnitOfMeasure
                                            }`
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between product-tooltip-row divider-top",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-bold mr-4 copy-to-clipboard",
                                  on: {
                                    click: function ($event) {
                                      _vm.$options.copyToClipboard(
                                        `${_vm.parent.$t(
                                          "assortmentCanvas.productPositioning",
                                          [
                                            _vm.props.podAndStoreCountInfo
                                              .originalSpacebreak.shortName,
                                            _vm.props.podAndStoreCountInfo
                                              .originalSpacebreak.storeCount,
                                            _vm.props.podAndStoreCountInfo
                                              .originalSpacebreak.pod,
                                          ]
                                        )}`,
                                        $event
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.parent.$t(
                                          "assortmentCanvas.startingPointPosition"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "overflow-ellipsis",
                                  attrs: {
                                    title: _vm.parent.$t(
                                      "assortmentCanvas.productPositioning",
                                      [
                                        _vm.props.podAndStoreCountInfo
                                          .originalSpacebreak.shortName,
                                        _vm.props.podAndStoreCountInfo
                                          .originalSpacebreak.storeCount,
                                        _vm.props.podAndStoreCountInfo
                                          .originalSpacebreak.pod,
                                      ]
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.parent.$t(
                                          "assortmentCanvas.productPositioning",
                                          [
                                            _vm.props.podAndStoreCountInfo
                                              .originalSpacebreak.shortName,
                                            _vm.props.podAndStoreCountInfo
                                              .originalSpacebreak.storeCount,
                                            _vm.props.podAndStoreCountInfo
                                              .originalSpacebreak.pod,
                                          ]
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between product-tooltip-row",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-bold mr-4 copy-to-clipboard",
                                  on: {
                                    click: function ($event) {
                                      _vm.$options.copyToClipboard(
                                        `${_vm.parent.$t(
                                          "assortmentCanvas.productPositioning",
                                          [
                                            _vm.props.podAndStoreCountInfo
                                              .optimisedSpacebreak.shortName,
                                            _vm.props.podAndStoreCountInfo
                                              .optimisedSpacebreak.storeCount,
                                            _vm.props.podAndStoreCountInfo
                                              .optimisedSpacebreak.pod,
                                          ]
                                        )}`,
                                        $event
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.parent.$t(
                                          "assortmentCanvas.lastOptimisation"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "overflow-ellipsis",
                                  attrs: {
                                    title: _vm.parent.$t(
                                      "assortmentCanvas.productPositioning",
                                      [
                                        _vm.props.podAndStoreCountInfo
                                          .optimisedSpacebreak.shortName,
                                        _vm.props.podAndStoreCountInfo
                                          .optimisedSpacebreak.storeCount,
                                        _vm.props.podAndStoreCountInfo
                                          .optimisedSpacebreak.pod,
                                      ]
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.parent.$t(
                                          "assortmentCanvas.productPositioning",
                                          [
                                            _vm.props.podAndStoreCountInfo
                                              .optimisedSpacebreak.shortName,
                                            _vm.props.podAndStoreCountInfo
                                              .optimisedSpacebreak.storeCount,
                                            _vm.props.podAndStoreCountInfo
                                              .optimisedSpacebreak.pod,
                                          ]
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-space-between product-tooltip-row",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "font-weight-bold mr-4 copy-to-clipboard",
                                  on: {
                                    click: function ($event) {
                                      _vm.$options.copyToClipboard(
                                        `${_vm.parent.$t(
                                          "assortmentCanvas.productPositioning",
                                          [
                                            _vm.props.podAndStoreCountInfo
                                              .currentSpacebreak.shortName,
                                            _vm.props.podAndStoreCountInfo
                                              .currentSpacebreak.storeCount,
                                            _vm.props.podAndStoreCountInfo
                                              .currentSpacebreak.pod,
                                          ]
                                        )}`,
                                        $event
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.parent.$t(
                                          "assortmentCanvas.latestEdit"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "overflow-ellipsis",
                                  attrs: {
                                    title: _vm.parent.$t(
                                      "assortmentCanvas.productPositioning",
                                      [
                                        _vm.props.podAndStoreCountInfo
                                          .currentSpacebreak.shortName,
                                        _vm.props.podAndStoreCountInfo
                                          .currentSpacebreak.storeCount,
                                        _vm.props.podAndStoreCountInfo
                                          .currentSpacebreak.pod,
                                      ]
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.parent.$t(
                                          "assortmentCanvas.productPositioning",
                                          [
                                            _vm.props.podAndStoreCountInfo
                                              .currentSpacebreak.shortName,
                                            _vm.props.podAndStoreCountInfo
                                              .currentSpacebreak.storeCount,
                                            _vm.props.podAndStoreCountInfo
                                              .currentSpacebreak.pod,
                                          ]
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex flex-column mt-3" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-space-between sub-info-row align-center",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "font-weight-bold mr-2" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.parent.$t(
                                        "assortmentCanvas.spacebreak"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "select-container select-container--spacebreak",
                              },
                              [
                                !_vm.props.productInfo.isEligible ||
                                !_vm.props.productInfo.assortment ||
                                _vm.props.productInfo.isLocal
                                  ? _c("rtls-select", {
                                      staticClass: "spacebreak-select",
                                      attrs: {
                                        value:
                                          _vm.props.selectedSpacebreak.value,
                                        disabled: true,
                                        items: [
                                          {
                                            value: null,
                                            text: _vm.parent.$t(
                                              `assortmentCanvas.${
                                                !_vm.props.productInfo
                                                  .isEligible
                                                  ? _vm.props.spacebreakTypes
                                                      .notInStoreClass
                                                  : _vm.props.spacebreakTypes
                                                      .notForAssortment
                                              }`
                                            ),
                                            disabled: false,
                                          },
                                        ],
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.props.productInfo.isEligible &&
                                _vm.props.productInfo.assortment &&
                                !_vm.props.productInfo.isLocal
                                  ? _c("rtls-select", {
                                      attrs: {
                                        value:
                                          _vm.props.selectedSpacebreak.value,
                                        items: _vm.props.spacebreaks,
                                        "menu-props": {
                                          top: true,
                                          offsetY: true,
                                        },
                                        disabled:
                                          _vm.props.productInfo.lockType ===
                                            _vm.props.lockTypes.locked ||
                                          _vm.props.spacebreakDisabled ||
                                          !_vm.props.productInfo.inReset,
                                        /* Attach to select-container to position dropdown menu correctly on scroll.
                       Only apply if parent is dashboard to avoid issues opening the dropdown menu.  */
                                        attach: _vm.props.parentIsDashboard
                                          ? ".select-container"
                                          : "",
                                      },
                                      on: {
                                        input: function ($event) {
                                          // need to change input event because this is a functional component with a select control
                                          _vm.listeners.input &&
                                            _vm.listeners.input($event)
                                        },
                                        change: function ($event) {
                                          return _vm.props.changeSpacebreak(
                                            $event
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex flex-column mt-3" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-space-between sub-info-row",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "font-weight-bold mr-4 copy-to-clipboard",
                                on: {
                                  click: function ($event) {
                                    return _vm.$options.copyToClipboard(
                                      `${_vm.props.productInfo.optimisedNote}`,
                                      $event
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.parent.$t(
                                        "assortmentCanvas.optimiserNotes"
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "overflow-ellipsis",
                                attrs: {
                                  title: _vm.props.productInfo.optimisedNote,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.props.productInfo.optimisedNote
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.props.productPallets.length > 0
                        ? _c(
                            "div",
                            { staticClass: "d-flex flex-column mt-3" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column sub-info-row",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "font-weight-bold mr-4" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.parent.$t("general.pallets")
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "pallets" },
                                    _vm._l(
                                      _vm.props.productPallets,
                                      function (pallet) {
                                        return _c(
                                          "div",
                                          { key: pallet.productKeyDisplay },
                                          [
                                            _c("product-pallet", {
                                              attrs: {
                                                product: pallet,
                                                "pallet-content-spacebreak":
                                                  _vm.props.selectedSpacebreak,
                                                "all-pallets":
                                                  _vm.props.productPallets,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex px-3 pt-3 pb-2" }, [
            !_vm.props.parentIsDashboard
              ? _c(
                  "button",
                  {
                    staticClass: "button",
                    on: {
                      click: function ($event) {
                        return _vm.props.popupButton.action()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.props.popupButton.label) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "copy-tooltip-hidden",
              attrs: { id: "copy-tooltip" },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.parent.$t("assortmentCanvas.copyTooltip")) +
                  "\n    "
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }