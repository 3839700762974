import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: { value: _vm.value, width: "500px" },
      on: { "click:outside": _vm.closeModal },
    },
    [
      _vm.value
        ? _c(
            "dialog-card",
            {
              attrs: { title: _vm.$tkey("modalTitle") },
              on: { close: _vm.closeModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c("page-actions", {
                          attrs: {
                            "has-data-errors": false,
                            "has-data-changes": true,
                            "save-disabled": false,
                            "show-discard": false,
                            "save-btn-text": _vm.$t("confirm"),
                          },
                          on: { save: _vm.addColumns },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3606646742
              ),
            },
            [
              _c(
                VContainer,
                {
                  staticClass: "pa-0 reset-product-data__container",
                  attrs: { fluid: "" },
                },
                [
                  _c(VRow, { staticClass: "mt-4 border-bottom" }, [
                    _c("p", { staticClass: "mb-4" }, [
                      _vm._v(_vm._s(_vm.$tkey("description"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-4 pb-5" },
                    [
                      _c(VRow, [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tkey("kpis")) +
                              "\n          "
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.dataToReset, function (attr) {
                        return _c(
                          VRow,
                          {
                            key: attr.field,
                            staticClass: "checkbox-selection-row",
                          },
                          [
                            _c(VCheckbox, {
                              staticClass: "rtls-checkbox mx-2",
                              attrs: {
                                "input-value":
                                  _vm.currentSelections[attr.field],
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.updateCurrentSelections(
                                    attr.field,
                                    $event
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(attr.name))]),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }