'use strict';

const kasuChainLevels = {
  PR: 'Prisma',
  SM: 'S-Market',
  PM: 'Sale / Alepa',
};

const hasuChainLevels = {
  ...kasuChainLevels,
  ABC: 'ABC',
  HERKKU: 'Herkku',
};

const aocChainLevels = {
  ...kasuChainLevels,
  ...hasuChainLevels,
};

module.exports = {
  kasuChainLevels,
  hasuChainLevels,
  aocChainLevels,
};
