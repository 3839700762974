'use strict';

const workpackageTypes = {
  ASSORTMENT_FULL_RESET: 'a_full_reset',
  ASSORTMENT_SIMPLE_SWAPS: 'a_simple_swaps',
};

module.exports = {
  ...workpackageTypes,
  ALL: Object.values(workpackageTypes),
};
