<template>
  <div class="container-fluid pt-3 px-5">
    <h3>LabsKit</h3>
    <table class="table table-striped table-hover table-bordered">
      <tbody>
        <tr v-for="rc in labskitrc" :key="rc.key">
          <th>{{ rc.key }}</th>
          <td>{{ rc.value }}</td>
        </tr>
      </tbody>
    </table>

    <h3>Context</h3>
    <table class="table table-striped table-hover table-bordered">
      <tbody>
        <tr v-for="[key, val] in currentContextEntries" :key="key">
          <th>{{ key }}</th>
          <td>{{ val }}</td>
        </tr>
      </tbody>
    </table>

    <h3>
      Health checks: <b-badge pill>{{ statusChecks.length }}</b-badge>
    </h3>
    <table v-if="statusChecks.length" class="table table-striped table-hover table-bordered">
      <tbody>
        <tr v-for="check in statusChecks" :key="check.basename">
          <th>{{ check.basename }}</th>
          <td>{{ check.status }}</td>
          <td>{{ check.description }}</td>
        </tr>
      </tbody>
    </table>

    <h3>Build details</h3>
    <table class="table table-striped table-hover table-bordered">
      <tbody>
        <tr>
          <th>Git revision</th>
          <td>{{ revision }}</td>
        </tr>
        <tr>
          <th>Build time</th>
          <td>{{ buildTime }}</td>
        </tr>
        <tr>
          <th>Build number</th>
          <td>{{ buildNumber }}</td>
        </tr>
        <tr>
          <th>Startup time</th>
          <td>{{ startupTime }}</td>
        </tr>
        <tr>
          <th>CWD (Current Working Dir)</th>
          <td>{{ cwd }}</td>
        </tr>
      </tbody>
    </table>

    <h3>Active Configuration</h3>
    <h6>*Any relative path/dir is relative to CWD</h6>
    <table class="table table-striped table-hover table-bordered">
      <tbody>
        <tr v-for="conf in config" :key="conf.key">
          <th>{{ conf.key }}</th>
          <td>{{ conf.value }}</td>
        </tr>
      </tbody>
    </table>

    <h3>Dependencies</h3>
    <div class="row">
      <div class="col-sm-12">
        <table class="table table-striped table-hover table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Configured version</th>
              <th>Installed version</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dep in dependencies" :key="dep.depname">
              <th>{{ dep.depname }}</th>
              <td>{{ dep.version }}</td>
              <td>{{ dep.installedVersion }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { forEach } from 'lodash';

export default {
  data() {
    return {
      revision: undefined,
      buildTime: undefined,
      buildNumber: undefined,
      startupTime: undefined,
      cwd: undefined,
      statusChecks: [],
      dependencies: [],
      labskitrc: [],
      config: [],
    };
  },
  computed: {
    currentContextEntries() {
      return Object.entries(this.$store.state.context);
    },
  },
  created() {
    return this.$http.get('/api/status').then(res => {
      forEach(res.data, (value, prop) => {
        this[prop] = value;
      });
    });
  },
};
</script>
