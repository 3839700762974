import { VChip } from 'vuetify/lib/components/VChip';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: {
            callback: _vm.visibilityChanged,
            throttle: 500,
          },
          expression:
            "{\n    callback: visibilityChanged,\n    throttle: 500,\n  }",
        },
      ],
      staticClass: "list-entry",
    },
    [
      _c(
        VRow,
        {
          staticClass: "list-entry-header d-flex pl-2",
          class: { "list-entry-header--active": _vm.isActive },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "title-container font-weight-bold d-flex align-center justify-space-between",
              attrs: { title: _vm.title },
            },
            [
              _c("h4", { staticClass: "title" }, [
                _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
              ]),
              _vm._v(" "),
              _vm.isSpacebreakLocked(_vm.spacebreakId)
                ? _c(
                    VChip,
                    {
                      staticClass: "locked",
                      attrs: { small: "", color: "primary" },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$tkey("locked")) + "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "draggable",
        {
          staticStyle: { position: "relative" },
          attrs: {
            list: _vm.products,
            disabled: _vm.isDragDisabled,
            group: _vm.groupName,
            sort: false,
            "scroll-sensitivity": 100,
            "invert-swap": true,
            "force-fallback": true,
            "ghost-class": "ghost",
            draggable: ".draggable",
            delay: "30",
          },
          on: {
            change: _vm.onChange,
            start: _vm.onDragStart,
            end: _vm.onDragStop,
          },
        },
        [
          _vm._l(_vm.renderedProducts[0], function (product, index) {
            return _c(
              "div",
              {
                key: product._id,
                ref: "parent-entry-item",
                refInFor: true,
                staticClass: "entry-item",
                class: {
                  draggable: !_vm.isLocked(product) && !_vm.isDragDisabled,
                  "pod-background-increase":
                    _vm.get(product.deltas, "reference") > 0 &&
                    product.isEligible,
                  "pod-background-decrease":
                    _vm.get(product.deltas, "reference") < 0 &&
                    product.isEligible,
                },
                attrs: { id: `tile-product-${product.productKey}` },
              },
              [
                _vm.parentEntryItemRef
                  ? _c("list-view-product-entry", {
                      attrs: {
                        product: product,
                        "spacebreak-id": _vm.spacebreakId,
                        "group-name": _vm.groupName,
                        "list-view-ref": _vm.listViewRef,
                        "parent-entry-item-ref": _vm.parentEntryItemRef,
                        index: index,
                        "open-popup-on-load-for-product-id":
                          _vm.productIdForPopup,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm._l(_vm.renderedProducts[1], function (product) {
            return _c(
              "div",
              {
                key: product._id,
                ref: "parent-entry-item",
                refInFor: true,
                staticClass: "entry-item skeleton",
                attrs: { id: `tile-product-${product.productKey}` },
              },
              [
                _c("img", {
                  attrs: {
                    id: `product-tile-popup-${product.productKey}`,
                    src: require("../../../img/spinner.gif"),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.delayPopup(product._id)
                    },
                  },
                }),
              ]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }