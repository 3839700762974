import { toString as valueToString, isNumber } from 'lodash';

function extractValue(rawValue, header) {
  if (header.outputFormat === 'text') return valueToString(rawValue);
  if (header.outputFormat === 'number') {
    /* although fields are marked as numeric, there could be the case where the user types something like
    "John to fill this" instead of a number, so the input fields are not forcing the input types.
    That being, we could have the case where a numeric field is not really numeric, so we do not parse it to number
    but instead return the actual string.

    Note: Should there be a "Annotation" component for this case? This creates ugly work, having to match conversions on backend and frontend
    * */
    // user is typing
    if (typeof rawValue === 'string' && rawValue.endsWith('.')) return rawValue;
    // AOV3-1423 TODO: handle localized numbers
    const convertedNumber = Number(rawValue);
    const isValidNumber = isNumber(convertedNumber) ? convertedNumber : rawValue;

    if (isValidNumber) return convertedNumber;
    return rawValue || '';
  }
  if (header.outputFormat === 'boolean') return Boolean(rawValue);

  return rawValue;
}

export default {
  extractValue,
};
