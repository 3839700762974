import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.product
    ? _c(
        VDialog,
        {
          attrs: {
            "content-class": "rtls-dialog rtls-dialog--dashboard",
            "max-width": "1120",
          },
          model: {
            value: _vm.showDashboard,
            callback: function ($$v) {
              _vm.showDashboard = $$v
            },
            expression: "showDashboard",
          },
        },
        [
          _c(
            "dialog-card",
            {
              attrs: {
                title: _vm.$tkey("title"),
                "doc-path": "200-productdashboard",
              },
              on: { close: _vm.closeDashboard },
            },
            [
              _c("div", { staticClass: "flex-container dashboard-text" }, [
                _c("div", { staticClass: "flex-sub-container1" }, [
                  _c(
                    "div",
                    { staticClass: "summary" },
                    [
                      _c("product-draggable-tooltip", {
                        attrs: {
                          "product-info": _vm.product,
                          "selected-kpis": _vm.clientConfig.selectedKpis,
                          "css-zindex": _vm.zIndex,
                          "pod-and-store-count-info": _vm.podAndStoreCounts(
                            _vm.product,
                            _vm.selectedCanvas.clusterId,
                            _vm.spacebreaksIndexedByIdByCluster
                          ),
                          "product-positioning-info":
                            _vm.extractProductPositioningInfo(
                              _vm.product,
                              _vm.selectedCanvas.clusterId,
                              _vm.spacebreaksIndexedByIdByCluster,
                              _vm.selectedPod
                            ),
                          settings: _vm.tooltipSettings,
                          "image-url": _vm.imageUrl,
                          "image-title": _vm.displayText,
                          "image-alt": _vm.displayText,
                          "conditional-product-tile-style": _vm.baseStyle,
                          spacebreaks: _vm.spacebreaksNames,
                          "selected-spacebreak": _vm.selectedSpacebreak,
                          "spacebreak-disabled":
                            _vm.isSpacebreakLocked(
                              _vm.product.currentSpacebreakId
                            ) || _vm.isEditingDisabled,
                          "spacebreak-types": _vm.spacebreakTypes,
                          "change-spacebreak": _vm.changeSpacebreak,
                          "lock-types": _vm.lockTypes,
                          "popup-button": {},
                          "parent-is-dashboard": true,
                          "current-numeric-locale": _vm.currentNumericLocale,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "top-switching-partners" },
                    [
                      _c(
                        "span",
                        { staticClass: "section-label font-weight-bold" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tkey("topSwitchingPartners")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("top-partners"),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { ref: "right-pane", staticClass: "flex-sub-container2" },
                  [
                    _c(
                      "div",
                      { staticClass: "product-distribution divider-bottom" },
                      [
                        _c(
                          "span",
                          { staticClass: "section-label font-weight-bold" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tkey("productDistribution.title")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("product-distribution"),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "transferred-spend",
                        class: {
                          "divider-bottom":
                            _vm.hasRelativeProductPerformanceEnabled,
                          "h-100": !_vm.hasRelativeProductPerformanceEnabled,
                        },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "section-label font-weight-bold" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tkey("transferredSpend.title")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.selectedCanvas.hasBeenOptimised
                          ? _c("div", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$tkey(
                                      "transferredSpend.onlyForOptimised"
                                    )
                                  ) + " "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.selectedCanvas.hasBeenOptimised
                          ? _c("transferred-spend")
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.hasRelativeProductPerformanceEnabled
                      ? _c(
                          "div",
                          { staticClass: "relative-product-performance" },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "section-label font-weight-bold mb-2 d-block",
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$tkey(
                                        "relativeProductPerformance.title"
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("relative-product-performance"),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }