import moment from 'moment';
import { pick, kebabCase, cloneDeep } from 'lodash';
import numberUtils from '@/js/utils/number-format-utils';

const mixin = {
  methods: {
    // Method that returns file name for the future csv.
    getFileName({ serviceName, workpackageName, scenarioName, fileNameDateFormat }) {
      const date = moment().format(fileNameDateFormat);
      const fileServiceName = this.$t(`csvExport.services.${serviceName}`);

      let fileName = `${date}-${fileServiceName}`;

      if (workpackageName) {
        fileName += `-${kebabCase(workpackageName)}`;
      }
      if (scenarioName) {
        fileName += `-${kebabCase(scenarioName)}`;
      }

      fileName += '.csv';

      return fileName;
    },

    // Method that transforms ag-grid table row to list of translated fields with their values.
    extractAndTranslateValues({
      row,
      delimiter,
      keysToExport,
      booleanFields = [],
      numericFields = [],
    }) {
      // Only pick fields that are defined in the schema
      const requiredData = cloneDeep(pick(row, keysToExport));

      // Translate boolean values
      booleanFields.forEach(({ mongoField }) => {
        requiredData[mongoField] = this.$t(
          `csvExport.valueTranslations.${requiredData[mongoField]}`
        );
      });

      numericFields.forEach(({ mongoField }) => {
        requiredData[mongoField] = numberUtils.formatNumberIfNumeric({
          value: requiredData[mongoField],
          format: 'export',
        });
      });

      return Object.values(requiredData).join(delimiter);
    },
  },
};

export default mixin;
