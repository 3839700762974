var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("workflow-step", {
    attrs: {
      step: _vm.step,
      "tab-data": _vm.templateTabs,
      "show-statuses": false,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }