import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: { width: "367px", "content-class": "generate-cdt-modal" },
      on: {
        "click:outside": _vm.closeModal,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeModal.apply(null, arguments)
        },
      },
      scopedSlots: _vm._u(
        [
          _vm._l(_vm.$scopedSlots, function (_, slot) {
            return {
              key: slot,
              fn: function (props) {
                return [_vm._t(slot, null, null, props)]
              },
            }
          }),
        ],
        null,
        true
      ),
      model: {
        value: _vm.isPopupOpen,
        callback: function ($$v) {
          _vm.isPopupOpen = $$v
        },
        expression: "isPopupOpen",
      },
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(/* for unsaved-data-mixin to work we need the modal to handle it's own state.
  this creates some problems since we need to use the activator slot provided by vuetify from this components parent.
  however, this component is used in multiple places, so we want to pass in different buttons as the activator.
  here we bind every scoped slot passed to this component to v-dialog,
  so when we pass 'template v-slot:activator="{ on }"' to this component, the slot is passed to v-dialog */) +
          "\n  "
      ),
      _vm._v(" "),
      _c(
        "dialog-card",
        {
          attrs: { title: _vm.title },
          on: { close: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c("page-actions", {
                    attrs: {
                      "has-data-errors": false,
                      "has-data-changes": true,
                      "save-btn-text": _vm.saveButtonText,
                    },
                    on: { discard: _vm.discard, save: _vm.onSave },
                    scopedSlots: _vm._u([
                      {
                        key: "right-btns",
                        fn: function () {
                          return [
                            _c(
                              VBtn,
                              {
                                staticClass: "text-outline",
                                attrs: { text: "", link: "" },
                                on: { click: _vm.closeModal },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("actions.cancel")) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VContainer,
            {
              staticStyle: { "max-height": "400px", padding: "0" },
              attrs: { fluid: "" },
            },
            [
              _c(
                VRow,
                { staticClass: "d-flex flex-column" },
                [
                  _c(VCol, { staticClass: "pl-0 pb-0 pr-4" }, [
                    _c("p", { staticClass: "mb-0 sub-title" }, [
                      _vm._v(
                        _vm._s(_vm.subtitle) +
                          " " +
                          _vm._s(_vm.selectedCannGroup.name) +
                          " ?"
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.includeOptions
                    ? _c(
                        VCol,
                        { staticClass: "d-flex py-0 pr-2 justify-end" },
                        [
                          _c("main-dropdown-menu", {
                            attrs: { actions: _vm.actions },
                            on: {
                              selectAll: function ($event) {
                                return _vm.updatedSelectedCannGroups(true)
                              },
                              unselectAll: function ($event) {
                                return _vm.updatedSelectedCannGroups(false)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { staticClass: "pl-0 pr-0 pt-0 mt-4 pb-4 main-container" },
                    _vm._l(_vm.cannGroups, function (cannGroup) {
                      return _c(
                        VRow,
                        {
                          key: cannGroup.key,
                          staticClass:
                            "cann-group-row d-flex justify-space-between",
                        },
                        [
                          _c(VCheckbox, {
                            staticClass: "mr-2",
                            attrs: {
                              id: cannGroup.key,
                              disabled:
                                cannGroup.disabled ||
                                _vm.isCannGroupRunning(cannGroup.key),
                              "input-value": cannGroup.selected,
                            },
                            on: {
                              change: function ($event) {
                                cannGroup.selected = $event
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "cann-group-name pb-0 full-row flex-1",
                            },
                            [_vm._v(_vm._s(cannGroup.name))]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }