import axios from 'axios';
import to from 'await-to-js';
// note there is no direct collision with find in this file, but there were issues at some point with collisions with vanilla js find.
import { find as _find, filter, sortBy, get, forEach, size, isEqual, some, uniqBy } from 'lodash';
import i18n from '@/js/vue-i18n';
import editableTableStore from '@/js/store/utils/editable-table-store-utils';
import handleErrorMessages from '@/js/store/utils/validation';

const store = {
  namespaced: true,
  state: {
    ...editableTableStore.state,
    loading: false,
    clusterSchemes: [],
    validClusterSchemes: [],
    clusterSchemeInformation: {},
    isLoadingClusterInformation: false,
    selectedScheme: null,
    selectableClusterNames: [], // shared state between store-allocation-list and clustering
    stores: [], // from tool data stores
    storeKeys: null,
  },

  mutations: {
    ...editableTableStore.mutations,

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setClusterSchemes(state, schemes) {
      state.clusterSchemes = schemes;
    },

    setClusterSchemeInformation(state, clusterSchemeInformation) {
      state.clusterSchemeInformation = clusterSchemeInformation;
    },

    setIsLoadingClusterInformation(state, isLoading) {
      state.isLoadingClusterInformation = isLoading;
    },

    setSelectedScheme(state, selectedScheme) {
      state.selectedScheme = selectedScheme;
    },

    setSelectableClusterNames(state, selectableClusterNames) {
      state.selectableClusterNames = [
        ...selectableClusterNames,
        i18n.t('clusteringPage.newCluster'),
      ];
    },

    setSelectedSchemeClusters(state, clusters) {
      state.selectedScheme.clusters = clusters;
    },

    refreshScheme(state, scheme) {
      const index = state.clusterSchemes.findIndex(obj => obj._id === scheme._id);
      Object.assign(state.clusterSchemes[index], scheme);
    },
  },

  getters: {
    ...editableTableStore.getters,

    getClusterSchemes(state) {
      return sortBy(state.clusterSchemes, ['name']);
    },
  },

  actions: {
    ...editableTableStore.actions,
    async updateClusters({ commit, dispatch, state }, updates) {
      // remove the unassigned group before saving
      const cleanUpdates = filter(updates, c => {
        return c.clusterName !== 'Unassigned';
      });
      const selectedClusterSchemeId = state.selectedScheme._id;
      const clusterCount = uniqBy(cleanUpdates, 'clusterName').length;
      commit('setLoading', true);
      const [err] = await to(
        axios.patch(
          `/api/reference/reference-cluster-schemes/${selectedClusterSchemeId}/clusters`,
          { updates: cleanUpdates, clusterCount }
        )
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      dispatch('fetchClusterScheme');
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
    },

    async updateClusterSchemeName({ commit, dispatch }, update) {
      commit('setLoading', true);
      const [err] = await to(
        axios.patch(`/api/reference/reference-cluster-schemes/${update.clusterSchemeId}/name`, {
          name: update.name,
        })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
    },

    // ensure selectedScheme stays in sync with clusterSchemes
    refreshSelectedScheme({ commit, state }) {
      const selectedScheme =
        _find(state.clusterSchemes, {
          selected: true,
        }) || null;

      commit('setSelectedScheme', selectedScheme);
    },

    async fetchClusterSchemes({ commit }) {
      commit('setLoading', true);
      const [err, response] = await to(axios.get(`/api/reference/reference-cluster-schemes`));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      commit('setClusterSchemes', response.data);
      // dispatch('refreshSelectedScheme');
      return response.data;
    },

    async fetchClusterScheme({ commit, state }) {
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/reference/reference-cluster-schemes/${state.selectedScheme._id}`)
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      commit('setSelectedScheme', response.data);
      commit('refreshScheme', response.data);
    },

    async deleteClusterScheme({ commit, dispatch }, clusterSchemeId) {
      commit('setLoading', true);
      const [err] = await to(
        axios.delete(`/api/reference/reference-cluster-schemes/${clusterSchemeId}`)
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
    },

    async saveClusteringSchemes({ commit, dispatch }, bodyData) {
      commit('setLoading', true);
      const [err, response] = await to(
        axios.post(`/api/reference/reference-cluster-schemes`, bodyData)
      );
      const options = { displayEmpty: false };
      handleErrorMessages({ response, dispatch, options });
      commit('setLoading', false);

      if (err) {
        throw new Error(get(err, 'message') || err);
      }
      // dispatch('fetchClusterSchemes');
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
    },

    async getClusterSchemeData({ commit }, clusterScheme) {
      const { _id } = clusterScheme;
      const url = `/api/reference/reference-cluster-scheme/${_id}`;

      commit('setIsLoadingClusterInformation', true);
      const [err, response] = await to(axios.get(url));
      commit('setIsLoadingClusterInformation', false);
      if (err) throw new Error(err.message);
      commit('setClusterSchemeInformation', response.data);
    },

    async processCSV({ commit, dispatch }, { fileId, mappings, delimiter }) {
      commit('setLoading', true);
      const body = { mappings, delimiter };
      const [err, { data: responseData } = {}] = await to(
        axios.post(`/api/csv/reference-clustering/process/${fileId}`, body)
      );
      commit('setLoading', false);
      // TODO: Remove this and use global interceptor on AOV3-159
      if (err) {
        const key = get(err, 'response.data.messageKey', err.message);
        const message = i18n.t(key, get(err, 'response.data.messageParams', []));
        dispatch('snackbar/showError', message, { root: true });
        return Promise.reject(message);
      }

      if (size(responseData.messages)) {
        dispatch('alerts/showMessages', responseData.messages, { root: true });
        return {}; // AOV3-196 NOTE: should not upload anything if there are any errors.
      }

      return responseData.storeToClusterMappings;
    },

    async setValidClusterSchemes({ state, rootState }) {
      if (!state.storeKeys) {
        // await dispatch('toolData/getStores', { root: true });
        state.stores = rootState.toolData.stores;
        state.storeKeys = new Set(state.stores.map(c => c.storeKey));
      }

      // get the stores assigned, if not the same as all stores, then invalid
      forEach(state.clusterSchemes, scheme => {
        let valid = true;
        if (!scheme.clusters || !scheme.clusterCount || scheme.clusterCount < 2) {
          valid = false;
        }
        if (valid && some(scheme.clusters, 'isNoDataCluster')) {
          valid = false;
        }
        const assignedStoreKeys = [];
        scheme.clusters.forEach(c => {
          assignedStoreKeys.push(...c.storeKeys);
        });
        const currentStoreKeys = new Set(assignedStoreKeys);
        if (valid && !isEqual(currentStoreKeys, state.storeKeys)) {
          valid = false;
        }
        if (valid) {
          state.validClusterSchemes.push(scheme);
        }
      });
    },
  },
};

export default store;
