import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: {
        "content-class": "rtls-dialog rtls-dialog--legend",
        "max-width": "600",
        "max-height": "400",
      },
      on: { "click:outside": _vm.closePopup },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                VBtn,
                _vm._g(
                  {
                    staticClass: "mr-2 action-btn",
                    attrs: {
                      disabled: _vm.disabled,
                      primary: "",
                      depressed: "",
                    },
                  },
                  on
                ),
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$tkey("copyDecisions")) + "\n      "
                  ),
                  _c(VIcon, { attrs: { size: "14", right: "" } }, [
                    _vm._v("mdi-content-copy"),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isPopupOpen,
        callback: function ($$v) {
          _vm.isPopupOpen = $$v
        },
        expression: "isPopupOpen",
      },
    },
    [
      _vm._v(" "),
      _c(
        "dialog-card",
        {
          attrs: { title: _vm.$tkey("copyDecisions") },
          on: { close: _vm.closePopup },
        },
        [
          _c(VCard, [
            _vm.loading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-center align-center fixed-height-loading-container",
                  },
                  [
                    _c(VProgressCircular, {
                      attrs: { indeterminate: "", color: "primary" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.loading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "my-2 d-flex justify-space-between align-center",
                  },
                  [
                    _c("copy-decisions-selects", {
                      attrs: {
                        scenarios: _vm.scenarios,
                        "clusters-map": _vm.wpScenarioClustersMap,
                        "furniture-map": _vm.wpScenarioFurnitureMap,
                        "canvases-map": _vm.wpCheckpointsMap,
                        "canvas-item-text-key": "checkpointMeta.name",
                        "chip-text-translation-key": "copyFrom",
                        "canvas-translation-key": "selectCheckpoint",
                        left: true,
                      },
                      on: {
                        selected: function ($event) {
                          _vm.copyFromCheckpointId = $event
                        },
                        unselected: function ($event) {
                          _vm.copyFromCheckpointId = $event
                        },
                      },
                    }),
                    _vm._v(" "),
                    _vm.selectionsComplete &&
                    !_vm.canvasMatchSuccess &&
                    !_vm.canvasMatchError
                      ? _c(
                          "div",
                          [
                            _c(VProgressCircular, {
                              attrs: { indeterminate: "", color: "primary" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canvasMatchSuccess
                      ? _c("div", { staticClass: "ma-3" }, [
                          _c(
                            "h4",
                            {
                              staticClass: "match-heading mb-1",
                              class: _vm.spacebreaksMatch,
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$tkey(_vm.spacebreaksTranslation)
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h4",
                            {
                              staticClass: "match-heading mb-1",
                              class: _vm.productsMatch,
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$tkey(_vm.productsTranslation)) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "h4",
                            {
                              staticClass: "match-heading",
                              class: _vm.activeJob,
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$tkey(_vm.activeJobTranslation))
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.canvasMatchError
                      ? _c("div", { staticClass: "ma-3" }, [
                          _c("h4", { staticClass: "match-heading not-valid" }, [
                            _vm._v(_vm._s(_vm.$tkey("comparisonError"))),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("copy-decisions-selects", {
                      attrs: {
                        scenarios: _vm.scenarios,
                        "clusters-map": _vm.wpScenarioClustersMap,
                        "furniture-map": _vm.wpScenarioFurnitureMap,
                        "canvases-map": _vm.wpCanvasesMap,
                        "canvas-item-text-key": _vm.getAssortmentOptionText,
                        "chip-text-translation-key": "copyTo",
                        "canvas-translation-key": "selectedCanvas",
                        "selected-canvas": _vm.selectedCanvas,
                        right: true,
                      },
                      on: {
                        selected: function ($event) {
                          _vm.copyToCanvasId = $event
                        },
                        unselected: function ($event) {
                          _vm.copyToCanvasId = $event
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-2 mb-5 d-flex justify-end" },
              [
                _c(
                  VBtn,
                  {
                    staticClass: "mr-2",
                    attrs: { text: "" },
                    on: { click: _vm.closePopup },
                  },
                  [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                ),
                _vm._v(" "),
                _c(
                  VBtn,
                  {
                    attrs: {
                      primary: "",
                      loading: _vm.copyDecisionsInProgress,
                      disabled:
                        _vm.copyDecisionsDisabled ||
                        _vm.copyDecisionsInProgress,
                    },
                    on: { click: _vm.copyDecisions },
                  },
                  [_vm._v(_vm._s(_vm.$tkey("copyDecisions")))]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }