<template>
  <div class="d-flex flex-column store-class-container">
    <v-container fluid class="store-class-panel">
      <v-row class="pa-0">
        <v-col cols="3" class="py-0 d-flex align-center">
          <div class="d-flex flex-column mr-6">
            <b>{{ $tkey('spacebreaks') }}</b>
            <div>
              {{ accordingToMessage }}
            </div>
          </div>
          <v-btn :disabled="isAddSpacebreakDisabled" small primary @click="createSpacebreak">
            {{ $tkey('addSpacebreak') }}
          </v-btn>

          <v-alert
            v-if="hasSpacebreakFromDataEnabled"
            class="alert--small ml-5 mb-0"
            type="info"
            text
          >
            {{
              $tkey(
                storeClass.isEditingDisabled
                  ? 'storeClassDisabledForEditing'
                  : 'storeClassEnabledForEditing'
              )
            }}
          </v-alert>
        </v-col>
        <v-col cols="4" class="py-0 d-flex align-center" />
        <v-col cols="1" class="py-0"> <br />{{ $tkey('totalStoreCount') }} </v-col>
        <v-col cols="3" class="py-0">
          <br />
          {{ $tkey('availableFA') }}
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="fixed-height-container pl-0 pt-2 pb-0">
      <v-row class="tab-row-item">
        <v-col cols="8" class="panel-container pa-0">
          <v-col cols="12" class="spacebreaks-panel pt-0">
            <spacebreak
              v-for="(spacebreak, spacebreakIndex) in spacebreaksList"
              :key="spacebreak._id"
              :spacebreak-index="spacebreakIndex"
              :spacebreak="spacebreak"
              :furniture-mapping="furnitureIdNameMap"
              :store-class-id="storeClassId"
              :is-remove-disabled="isRemoveDisabled"
              :spacebreaks="storeClass.spacebreaks"
              @updateSpacebreak="
                updateSpacebreakForStoreClass({
                  storeClassId: storeClass._id,
                  updatedSpacebreak: $event,
                })
              "
              @removeSpacebreak="removeSpacebreak(spacebreak._id)"
              @removeAll="
                removeAllFurnitureFromSpacebreak({
                  storeClassId: storeClass._id,
                  spacebreakId: spacebreak._id,
                })
              "
              @addAll="
                addAllAvailableFurnitureToSpacebreak({
                  storeClassId: storeClass._id,
                  spacebreakId: spacebreak._id,
                  availableFurnitureIds,
                })
              "
              @removeOne="
                removeOneFurnitureFromSpacebreak({
                  storeClassId: storeClass._id,
                  spacebreakId: spacebreak._id,
                  furnitureId: $event,
                })
              "
              @addOne="
                addOneFurnitureToSpacebreak({
                  storeClassId: storeClass._id,
                  spacebreakId: spacebreak._id,
                  furnitureId: $event,
                })
              "
            />
          </v-col>
        </v-col>

        <v-col cols="3" class="archetype-list-container py-0">
          <available-furniture-archetypes-list
            :available-furniture-ids="availableFurnitureIds"
            :furniture-mapping="furnitureIdNameMap"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import {
  find,
  get,
  flatMap,
  difference,
  camelCase,
  cloneDeep,
  orderBy,
  sortBy,
  keyBy,
} from 'lodash';

export default {
  localizationKey: 'spacebreakCalculatorPage',
  props: {
    storeClassId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      furnitureIdNameMap: {},
    };
  },

  computed: {
    ...mapState('context', ['clientConfig']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('furniture', ['getStoreClassesInScenario', 'getGeneratedFurnituresInScenario']),

    accordingToMessage() {
      const fillInSelection = camelCase(this.selectedWorkpackage.fillInSelection);
      const suffix = this.$t(`suffixes.${fillInSelection}`);
      return `${this.$tkey('accordingTo')} ${this.$t(
        `workpackagePage.scope.${fillInSelection}`
      )} (${suffix})`;
    },

    availableFurnitureIds() {
      const furnituresInSpacebreaks = flatMap(this.storeClass.spacebreaks, 'generatedFurnitureIds');
      return difference(this.storeClass.generatedFurnitureIds, furnituresInSpacebreaks);
    },

    isRemoveDisabled() {
      return this.storeClass.spacebreaks.length <= 1;
    },

    storeClass: {
      get() {
        return cloneDeep(find(this.getStoreClassesInScenario, { _id: this.storeClassId }));
      },
      set(value) {
        const otherStoreClasses = this.getStoreClassesInScenario.filter(
          sc => sc._id !== this.storeClassId
        );
        otherStoreClasses.push(value);
        this.setStoreClasses(otherStoreClasses);
      },
    },

    generatedFurnitureById() {
      return keyBy(this.getGeneratedFurnituresInScenario, '_id');
    },

    spacebreaksList() {
      return this.parsedSpacebreaks(this.storeClass.spacebreaks);
    },

    isAddSpacebreakDisabled() {
      return (
        !this.hasPermission(this.userPermissions.canEditSpacebreakCalculatorPage) ||
        this.storeClass.canBeEdited === false
      );
    },
  },

  watch: {
    spacebreaksList() {
      this.$forceUpdate();
    },
  },

  async created() {
    this.getFurnitureIdNameMap();
  },

  methods: {
    ...mapMutations('furniture', [
      'setStoreClasses',
      'addAllAvailableFurnitureToSpacebreak',
      'addOneFurnitureToSpacebreak',
      'removeAllFurnitureFromSpacebreak',
      'removeOneFurnitureFromSpacebreak',
      'updateSpacebreakForStoreClass',
      'removeSpacebreakFromStoreClass',
      'addSpacebreak',
    ]),

    getFurnitureIdNameMap() {
      this.furnitureIdNameMap = this.storeClass.generatedFurnitureIds.reduce(
        (mapping, furnitureId) => {
          mapping[furnitureId] = get(
            find(this.getGeneratedFurnituresInScenario, { _id: furnitureId }),
            'name'
          );
          return mapping;
        },
        {}
      );
    },

    removeSpacebreak(spaceBreakId) {
      const options = {
        storeClassId: this.storeClass._id,
        spacebreakId: spaceBreakId,
      };
      this.removeAllFurnitureFromSpacebreak(options);
      this.removeSpacebreakFromStoreClass(options);
    },

    parsedSpacebreaks(spacebreaks) {
      // required to watch for fill size values
      const updatedSpacebreaks = spacebreaks.map(s => {
        // step 1, get the MCF fill size
        s.fillSize = get(
          this.generatedFurnitureById[s.modalFurnitureId],
          'fillSize',
          s.fillSuggested
        );
        s.storeCount = s.storeKeys.length;
        // step 2, identify most common furniture
        // sorting is used to give preference to furnitures with
        // bigger storeCount, then size and finally furnitureId
        s.mostCommonFurniture =
          orderBy(
            this.getFurnitureMappings(s),
            ['storeCount', 'size', 'furnitureId'],
            // sorting is used to give preference to furnitures with higher storeCount, then size and finally furnitureId
            ['desc', 'desc', 'desc']
          )[0] || null;
        s.size = get(s.mostCommonFurniture, 'size', 0);
        s.modalFurnitureId = get(s.mostCommonFurniture, 'furnitureId');
        s.palletsSlots = get(s, 'palletsSlots', 0);

        return s;
      });
      // step 3, sort modal furnitures by size before calculating the new suggested set aside
      const sortedSpacebreaks = sortBy(updatedSpacebreaks, 'size');

      // step 4, calculate new suggested fill size:
      // if spacebreaks are generated from data - skip this step
      if (!this.storeClass.isEditingDisabled) {
        this.calculateSpacebreakSuggestedFillSizes(sortedSpacebreaks);
      }

      return updatedSpacebreaks;
    },
    getFurnitureMappings(spacebreak) {
      // for all furniture in a spacebreak, bring the furniture entry for it
      return spacebreak.generatedFurnitureIds.map(_id => {
        const furniture = this.generatedFurnitureById[_id];

        return {
          furnitureId: _id,
          storeCount: furniture.storeKeys.length,
          size: furniture.size,
          name: furniture.name,
          products: furniture.productKeys.length,
        };
      });
    },
    calculateSpacebreakSuggestedFillSizes(spacebreaks) {
      /* Function ported directly from the retails analytics repo into Vue for reactive calculations
      as the furnitures are dragged/dropped from spacebreaks.

      The retailanalytics method can be found here: https://github.com/owg-digital/rtls-retail-analytics/blob/b9a64206143160b288207f02ebce9808f970f9e1/retailanalytics/algorithms/assortment_spacebreaks/spacebreaks.py#lines-14

      It creates a list of suggested fill sizes with the same length as spacebreaks list.

      For each spacebreak we take the products that are currently on planogram/ furniture.
      (The fillSize property of a spacebreak corresponds to a single product's dimension)
      We take 1 face of each product and calculate the used space.
      The difference between the theoretical size of the planogram/ furniture
      and the used space is the set aside space.
      For the set aside space we add the maxSetAside constraint.

      The algorithm uses last_value to keep overall consistency so that bigger spacebreaks
      have more space available than smaller spacebreaks.

      */
      const maxSetAside = this.clientConfig.spacebreaks.defaultSetAside;
      let lastValue = 0;
      let lastNewValue = 0;
      let a;
      let b;
      let diff;
      let newValue;
      spacebreaks.forEach(sb => {
        const furnitureSize = sb.size;
        const furnitureFillSize = sb.fillSize;

        if (furnitureSize === 0) newValue = 0;
        else {
          diff = furnitureSize - lastValue;
          a = 1 - (furnitureFillSize - lastNewValue) / diff;
          b = lastNewValue + (1 - maxSetAside) * diff;
          if (a > maxSetAside) {
            if (b - lastNewValue > diff) {
              newValue = lastNewValue + diff;
            } else {
              newValue = b;
            }
          } else if (furnitureFillSize - lastNewValue > diff) {
            newValue = lastNewValue + diff;
          } else {
            newValue = furnitureFillSize;
          }
        }
        sb.fillSuggested = newValue;
        lastNewValue = newValue;
        lastValue = furnitureSize;

        return sb;
      });
    },
    createSpacebreak() {
      const options = { storeClassId: this.storeClassId };
      this.addSpacebreak(options);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.store-class-panel {
  flex-grow: 0;
  font-size: 12px;
  padding: 0px;
  .bottom-text {
    vertical-align: bottom;
  }
}

.panel-container {
  width: 100%;
  position: relative;
  height: 100%;
}

.panel-container::before {
  // add pseudo element on top of container, allows inset box shadow to appear on top of scrolled elements.
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-box-shadow: $assortment-main-panel-box-shadow;
  -moz-box-shadow: $assortment-main-panel-box-shadow;
  box-shadow: $assortment-main-panel-box-shadow;
  // remove point events for pseudo element to allow overflow scroll in child element
  pointer-events: none;
  // Overlay box shadow on top of draggable items
  z-index: 99;
}

.store-class-container {
  height: 100%;
}

.fixed-height-container {
  flex-grow: 1;
  height: 100px;
}

.tab-row-item {
  position: relative;
  height: 100%;
}

.archetype-list-container {
  height: 100%;
}

.spacebreaks-panel {
  height: 100%;
  background: $assortment-background;
  border-left: 1px solid $assortment-border-colour;
  border-top: 1px solid $assortment-border-colour;
  overflow-y: auto;
  font-size: 12px;
  padding-left: 10px;
}
</style>
