import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VRow,
        { attrs: { "no-gutters": "" } },
        [
          _c(
            VCol,
            [
              _c(
                VBtn,
                {
                  attrs: {
                    "data-id-e2e": "btnNewWorkpackage",
                    "data-dd-action-name": "AddNewWorkpackageBtn",
                    primary: "",
                    disabled: _vm.isNewButtonDisabled,
                  },
                  on: { click: _vm.addNewWorkpackage },
                },
                [_vm._v(_vm._s(_vm.$t("workpackagePage.newWpButton")))]
              ),
              _vm._v(" "),
              _vm.templatesEnabled
                ? _c(
                    VBtn,
                    {
                      attrs: {
                        "data-id-e2e": "btnNewWorkpackageFromTemplate",
                        "data-dd-action-name":
                          "AddNewWorkpackageFromTemplateBtn",
                        primary: "",
                        disabled: _vm.isNewButtonDisabled,
                      },
                      on: { click: _vm.createNewWorkpackageFromTemplate },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("workpackagePage.newWpFromTemplateButton")
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("workpackage-templates-modal", {
        on: { close: _vm.closeWorkpackageTemplatesModal },
        model: {
          value: _vm.isWorkpackageTemplatesModalOpen,
          callback: function ($$v) {
            _vm.isWorkpackageTemplatesModalOpen = $$v
          },
          expression: "isWorkpackageTemplatesModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }