<template>
  <div class="wp-scope">
    <v-tabs v-model="selectedTab" :hide-slider="true" data-id-e2e="workpackageScopeTabs">
      <template v-for="item in tabs">
        <v-tab :key="item.id" class="wp-tab justify-space-between">
          <span>{{ item.name }}</span>
        </v-tab>
        <div :key="`spacer-${item.id}`" class="wp-tab__spacer" />
      </template>

      <div class="wp-tab__filler" />

      <v-tabs-items v-model="selectedTab">
        <v-tab-item v-for="item in tabs" :key="item._id" class="wp-tab__item">
          <periods-tab
            v-if="item.id === 'periods'"
            :date-selections="dateSelections"
            :fill-in-selection="model.fillInSelection"
            :maximum-trend="model.maximumTrend"
            :workpackage="workpackage"
            :period-ids="model.periodIds"
            @date-changed="populateDateSelections"
            @dates-loaded="onDatesLoaded"
            @model-changed="populateModel"
          />

          <assortment-groups-tab
            v-if="item.id === 'agNew' && dateSelections.snapshotDate"
            :snapshot-date="dateSelections.snapshotDate"
            :workpackage="workpackage"
            :custom-workpackage="dirtyWorkpackage()"
            :dates-loaded="datesLoaded"
            @update-assortment-groups="populateSelectedAssortmentGroups"
          />

          <template v-if="item.id === 'agOld'">
            <v-row class="wp-scope__form" no-gutters>
              <v-col :cols="9" class="pr-3">
                <assortment-groups-selection
                  v-if="dateSelections.snapshotDate"
                  :workpackage="workpackage"
                  :snapshot-date="dateSelections.snapshotDate"
                  :selected-assortment-group-settings="
                    workpackage.selectedAssortmentGroupSettings || []
                  "
                  @select-assortment-group="populateSelectedAssortmentGroups"
                />
              </v-col>

              <v-col class="col-border-left">
                <div class="col-padding">
                  <v-card class="pt-0 pb-2 pr-2 pl-2" style="background: none;box-shadow:none">
                    <h3 class="wp-scope__input-heading">
                      {{ $tkey('productsAndStoresLabel') }}
                    </h3>
                    <p class="wp-scope__input-text">{{ $tkey('importProductDataLabel') }}:</p>
                    <import-from-planogram
                      v-if="datesLoaded"
                      :custom-workpackage="dirtyWorkpackage()"
                    />
                  </v-card>

                  <wp-scope-products />
                  <wp-scope-stores />
                </div>
              </v-col>
            </v-row>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <v-row class="footer-panel">
      <v-col class="d-flex justify-end pa-3" colspan="12">
        <v-btn
          data-id-e2e="btnCloseWorkpackage"
          class="mr-0 font-weight-bold btn-text"
          :ripple="false"
          text
          small
          @click="closeScope"
          >{{ $t('actions.close') }}</v-btn
        >
        <v-divider class="mr-0 divider" vertical />
        <v-btn
          v-if="showNotImplemented"
          class="mr-2 font-weight-bold btn-text"
          :ripple="false"
          text
          small
          disabled
          >{{ $t('actions.discardChanges') }}</v-btn
        >
        <v-btn
          id="scope-save"
          data-id-e2e="btnSaveWorkpackage"
          class="mr-2"
          depressed
          min-width="65"
          small
          action
          :disabled="
            isWorkpackageSetupRunning ||
              !hasPermission(userPermissions.canEditWorkpackageScope) ||
              !validate()
          "
          @click="$emit('save:workpackage')"
          >{{ $t('actions.save') }}
        </v-btn>
        <v-tooltip :value="hasInvalidPeriodIds" :disabled="!hasInvalidPeriodIds" top>
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <v-btn
                data-id-e2e="btnRunWorkpackage"
                :disabled="
                  !hasPermission(userPermissions.canEditWorkpackageScope) || hasInvalidPeriodIds
                "
                :loading="isWorkpackageSetupRunning"
                depressed
                primary
                min-width="65"
                small
                @click="runWpSetup"
              >
                {{ $t('workpackagePage.runWp') }}
              </v-btn>
            </div>
          </template>
          <span v-if="hasInvalidPeriodIds">{{ $tkey('warnings.periodIdWarning') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { get, every, assign, pick } from 'lodash';
import SIZE_TYPES from '@enums/size-types';
import datesMixin from '@/js/mixins/date-utils';
import { workpackageScopeTooltipOptionsMixin } from '@/js/mixins/tooltip-options';
import inputValidationMixin from '@/js/mixins/input-validations';

export default {
  mixins: [datesMixin, inputValidationMixin, workpackageScopeTooltipOptionsMixin],
  localizationKey: 'workpackagePage.scope',

  props: {
    isWorkpackageSetupRunning: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  data() {
    return {
      model: {
        fillInSelection: null,
        maximumTrend: null,
        periodIds: [],
        _id: null,
      },
      datesLoaded: false,
      dateSelections: {
        snapshotDate: null,
        switchingStartDate: null,
        switchingDateWeeks: null,
        customSwitchingDateWeeks: 0,
        performanceExcludedDates: [],
        performanceStartDate: null,
        performanceDateWeeks: null,
        customPerformanceDateWeeks: 0,
        targetLaunchDate: null,
        learningStartDate: null,
        learningDateWeeks: null,
        learningExcludedDates: [],
        customLearningDateWeeks: 0,
      },
      selectedAssortmentGroups: [],
      isImportingFromPlano: false,
      distinctStoreCount: 0,
      distinctProductCount: 0,
      requiredWorkpackageFields: [
        'performanceStartDate',
        'switchingStartDate',
        'fillInSelection',
        'snapshotDate',
      ],
      selectedTab: 0,
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage', 'wpValidation']),
    ...mapState('context', ['clientConfig']),
    ...mapGetters('workpackageProducts', ['workpackageProducts']),
    ...mapGetters('context', ['showNotImplemented', 'getDefaultReverseFormat']),

    workpackage() {
      return this.selectedWorkpackage || {};
    },

    stores() {
      return this.workpackage.stores || [];
    },

    useNewVersionOfAGSelection() {
      return get(this.clientConfig, 'features.useNewVersionOfAGSelection');
    },

    useOldVersionOfAGSelection() {
      return get(this.clientConfig, 'features.useOldVersionOfAGSelection');
    },

    calculateTrendEnabled() {
      return get(this.clientConfig, 'features.calculateTrendEnabled', false);
    },

    defaultMaximumTrend() {
      return get(this.clientConfig, 'maximumTrend');
    },

    tabs() {
      const tabs = [{ id: 'periods', name: this.$tkey('tabs.periods') }];

      if (this.useNewVersionOfAGSelection) {
        tabs.push({ id: 'agNew', name: this.$tkey('tabs.ag') });
      }

      if (this.useOldVersionOfAGSelection) {
        tabs.push({ id: 'agOld', name: this.$tkey('tabs.agOld') });
      }

      return tabs;
    },

    calculatePerformanceEndDate() {
      const { performanceStartDate, performanceDateWeeks } = this.dateSelections;
      // performanceStartDate and performanceDateWeeks will be null initially.
      if (!performanceStartDate || !performanceDateWeeks) return false;

      return this.calculateEndDate({
        startDate: performanceStartDate,
        weeks: performanceDateWeeks,
        dateFormat: this.getDefaultReverseFormat,
      });
    },

    calculateSwitchingEndDate() {
      const { switchingStartDate, switchingDateWeeks } = this.dateSelections;
      // switchingStartDate and switchingDateWeeks will be null initially.
      if (!switchingStartDate || !switchingDateWeeks) return false;

      return this.calculateEndDate({
        startDate: switchingStartDate,
        weeks: switchingDateWeeks,
        dateFormat: this.getDefaultReverseFormat,
      });
    },

    calculateLearningEndDate() {
      const { learningStartDate, learningDateWeeks } = this.dateSelections;
      // learningStartDate and learningDateWeeks will be null initially.
      if (!learningStartDate || !learningDateWeeks) return false;

      return this.calculateEndDate({
        startDate: learningStartDate,
        weeks: learningDateWeeks,
        dateFormat: this.getDefaultReverseFormat,
      });
    },

    hasSelectedAssortmentGroups() {
      return this.selectedAssortmentGroups.length > 0;
    },

    canRunWPSetup() {
      if (this.isWorkpackageSetupRunning || !this.workpackage) {
        return false;
      }

      return (
        !!this.stores.length &&
        !!this.workpackageProducts.length &&
        this.hasSelectedAssortmentGroups && // TODO: Only force AG if the client is using furniture
        every(this.requiredWorkpackageFields, field => !!this.workpackage[field])
      );
    },

    hasPeriodIdsEnabled() {
      return get(this.clientConfig, 'features.workpackagePeriodIdsEnabled', false);
    },

    hasMandatoryPeriodIdsEnabled() {
      return get(this.clientConfig, 'betaFeatures.rcMandatoryPeriodIdsEnabled', false);
    },

    hasInvalidPeriodIds() {
      return (
        (!this.model.periodIds || this.model.periodIds.length === 0) &&
        this.hasPeriodIdsEnabled &&
        this.hasMandatoryPeriodIdsEnabled
      );
    },
  },

  async created() {
    this.initialiseWorkpackageData();
  },

  methods: {
    ...mapActions('workpackageProducts', ['fetchWorkpackageProducts']),

    runWpSetup() {
      this.$emit('run-workpackage-setup');
    },

    async initialiseWorkpackageData() {
      this.model = {
        _id: this.workpackage._id,
        periodIds: this.workpackage.periodIds,
        fillInSelection: this.workpackage.fillInSelection || SIZE_TYPES.linearSpace,
        ...(this.calculateTrendEnabled && {
          maximumTrend: this.workpackage.maximumTrend || this.defaultMaximumTrend,
        }),
      };
      this.populateSelectedAssortmentGroups(
        this.selectedWorkpackage.selectedAssortmentGroupSettings || []
      );
      await this.fetchWorkpackageProducts({ workpackageId: this.workpackage._id });
    },

    populateDateSelections(dates) {
      this.dateSelections = assign({}, this.dateSelections, dates);
    },

    populateModel(model) {
      this.model = assign({}, this.model, model);
    },

    populateSelectedAssortmentGroups(selectedAssortmentGroups) {
      this.selectedAssortmentGroups = selectedAssortmentGroups;
    },

    getData() {
      const {
        snapshotDate,
        performanceExcludedDates,
        switchingStartDate,
        switchingDateWeeks,
        performanceStartDate,
        performanceDateWeeks,
        targetLaunchDate,
        learningStartDate,
        learningDateWeeks,
        learningExcludedDates,
      } = this.dateSelections;

      const workpackageUpdates = {
        ...this.model,
        snapshotDate: this.formatDateForSave(snapshotDate, this.getDefaultReverseFormat),
        targetLaunchDate: this.formatDateForSave(targetLaunchDate, this.getDefaultReverseFormat),
        performanceExcludedDates: performanceExcludedDates.map(date =>
          this.formatDateForSave(date, this.getDefaultReverseFormat)
        ),
        performanceStartDate: this.formatDateForSave(
          performanceStartDate,
          this.getDefaultReverseFormat
        ),
        performanceEndDate: this.formatDateForSave(
          this.calculatePerformanceEndDate,
          this.getDefaultReverseFormat
        ),
        switchingStartDate: this.formatDateForSave(switchingStartDate),
        switchingEndDate: this.formatDateForSave(
          this.calculateSwitchingEndDate,
          this.getDefaultReverseFormat
        ),
        switchingDateWeeks,
        performanceDateWeeks,
        selectedAssortmentGroupSettings: this.selectedAssortmentGroups.map(ag => {
          return pick(ag, ['key', 'analysis', 'assortment']);
        }),
        stores: this.stores,
      };

      if (this.hasSeasonalityEnabled) {
        workpackageUpdates.learningDateWeeks = learningDateWeeks;
        workpackageUpdates.learningExcludedDates = learningExcludedDates.map(date =>
          this.formatDateForSave(date, this.getDefaultReverseFormat)
        );
        workpackageUpdates.learningStartDate = this.formatDateForSave(
          learningStartDate,
          this.getDefaultReverseFormat
        );
        workpackageUpdates.learningEndDate = this.formatDateForSave(
          this.calculateLearningEndDate,
          this.getDefaultReverseFormat
        );
      } else {
        workpackageUpdates.learningStartDate = null;
        workpackageUpdates.learningEndDate = null;
        workpackageUpdates.learningDateWeeks = 0;
        workpackageUpdates.learningExcludedDates = [];
      }

      return workpackageUpdates;
    },

    dirtyWorkpackage() {
      return {
        ...this.getData(),
        templateId: this.workpackage.templateId,
      };
    },

    validate() {
      return this.calculateTrendEnabled ? this.validateMaximumTrend() : true;
    },

    validateMaximumTrend() {
      const maximumTrend = this.model.maximumTrend;
      return (
        maximumTrend &&
        this.isNumber(maximumTrend) === true &&
        maximumTrend <= 100 &&
        this.isGreaterOrEqual(maximumTrend, 0) === true
      );
    },

    closeScope() {
      this.resetScopeModel();
      this.$emit('close');
    },

    resetScopeModel() {
      this.model = {
        fillInSelection: null,
        maximumTrend: null,
        _id: null,
      };
    },

    onDatesLoaded(datesLoaded) {
      this.datesLoaded = datesLoaded;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.wp-scope {
  background: $assortment-scope-background-colour;
  width: 100%;
  padding-top: 8px;

  &__form {
    padding: 30px 32px;
  }

  ::v-deep {
    .dates-selection__group-button {
      flex: 1;

      &:not(.v-btn--active) {
        background-color: $assortment-scope-background-colour !important;
      }
    }

    .v-select {
      &__slot {
        background: $assortment-input-background;
        height: 28px !important;
      }

      &__selection {
        padding-left: 5px;
      }

      &__selections {
        input {
          padding-left: 5px;
        }
      }
    }

    .v-input__slot {
      background: white;
      height: 30px !important;
    }

    .v-text-field__slot {
      input {
        padding-left: 5px;
      }
    }

    .v-tabs-bar__content :first-child {
      margin-left: 0px !important;
    }
    .wp-tab {
      padding: 0 10px;
      justify-content: inherit;
      text-align: inherit;
      text-transform: none;
      min-width: 200px;
      border: 1px solid $assortment-tab-divider-colour;
      border-left: 3px solid $assortment-tab-divider-colour;
      font-size: 13px;
      font-weight: 900;

      &__spacer {
        width: 4px;
        border-bottom: 1px solid $assortment-tab-divider-colour;
      }

      &__filler {
        flex-grow: 1;
        border-bottom: 1px solid $assortment-tab-divider-colour;
      }

      &__item {
        border-left: 1px solid $assortment-tab-divider-colour;
        border-right: 1px solid $assortment-tab-divider-colour;
      }
    }

    .theme--light.v-tabs > .v-tabs-bar {
      background-color: inherit !important;
    }

    .v-tabs-bar {
      height: 37px;
    }
    .v-tab {
      color: $assortment-primary-colour !important;
    }

    .v-tab--active {
      background-color: $assortment-workpackage-frame-bg-blue;
      color: $assortment-workpackage-text !important;
      border-left: 3px solid $assortment-primary-colour;
      border-bottom: none;
    }
  }

  &__input-heading {
    margin-bottom: 7px;
    font-size: 1.2rem;

    span {
      font-weight: normal;
    }
  }

  &__input-text {
    margin-bottom: 7px;
  }

  p,
  a {
    font-size: 1.2rem;
  }

  .col-padding {
    padding: 0 15px;
  }

  .col-border-left {
    border-left: #ccc 1px solid;
  }

  .search .v-input {
    width: 100% !important;
    padding: 0;
    margin: 0;

    &.div {
      padding: 0;
    }
  }

  .v-select.v-select--chips .v-select__selections {
    min-height: auto !important;
  }
}

.v-card {
  padding-bottom: 0 !important;
}

.footer-panel {
  background-color: $assortment-workpackage-frame-bg-blue;
  border: 1px solid $assortment-tab-divider-colour;
  border-width: 0 1px 1px 1px;

  .btn-text {
    color: $assortment-primary-colour;

    &::before {
      opacity: 0 !important;
    }
  }

  .divider {
    min-height: 20px;
    max-height: 20px;
    margin: auto 0;
  }
}
</style>
