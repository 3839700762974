import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VMenu,
        {
          attrs: {
            disabled: _vm.disabled,
            "close-on-content-click": false,
            "min-width": 500,
            "max-width": 500,
            "max-height": 500,
            "nudge-bottom": 8,
            "z-index": _vm.menuZindex,
            "offset-y": "",
          },
          on: { input: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    VBtn,
                    _vm._g(
                      _vm._b(
                        {
                          style: _vm.filterButtonStyle,
                          attrs: {
                            ripple: false,
                            secondary: !_vm.menu,
                            primary: _vm.menu,
                            "min-width": 100,
                            height: 28,
                            disabled: _vm.disabled,
                            depressed: "",
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v("\n        " + _vm._s(_vm.btnText) + "\n      ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _vm._v(" "),
          _c(VCard, [
            _c("div", { staticClass: "pa-4 d-flex flex-column" }, [
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c(
                  "div",
                  { staticClass: "mb-2" },
                  [
                    _c("rtls-select", {
                      staticClass: "mt-1",
                      attrs: {
                        grey: "",
                        items: _vm.filterOptions,
                        "item-text": "name",
                        placeholder: _vm.$t("general.select"),
                        "hide-details": "",
                        "return-object": "",
                      },
                      on: {
                        change: function ($event) {
                          _vm.selectedFilterValues = []
                        },
                      },
                      model: {
                        value: _vm.filterValue,
                        callback: function ($$v) {
                          _vm.filterValue = $$v
                        },
                        expression: "filterValue",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mb-1 d-flex align-center justify-start" },
                  [
                    _c(VCheckbox, {
                      staticClass: "rtls-checkbox mx-2",
                      attrs: { "input-value": _vm.notEqualTo },
                      on: {
                        change: function ($event) {
                          _vm.notEqualTo = $event
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("h3", [_vm._v(_vm._s(_vm.$tkey("notEqual")))]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mb-4 d-flex align-center" },
                  [
                    _c(VAutocomplete, {
                      ref: "autocompleteSelect",
                      attrs: {
                        items: _vm.selectedFilterValueOptions,
                        chips: "",
                        "item-text": "name",
                        multiple:
                          _vm.selectedFilterDefinition &&
                          _vm.selectedFilterDefinition.allowSelectMultiple,
                        "search-input": _vm.searchInput,
                        "allow-overflow": false,
                        "background-color": "white",
                        dense: "",
                        "small-chips": "",
                        "hide-details": "",
                        light: "",
                        "hide-selected": "",
                        "return-object": "",
                        "menu-props": { closeOnClick: true },
                      },
                      on: {
                        "update:searchInput": function ($event) {
                          _vm.searchInput = $event
                        },
                        "update:search-input": function ($event) {
                          _vm.searchInput = $event
                        },
                        change: function ($event) {
                          _vm.searchInput = ""
                        },
                        "click:append": _vm.menuArrow,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "selection",
                          fn: function ({ item }) {
                            return [
                              _c(
                                VChip,
                                {
                                  attrs: {
                                    title: item.name,
                                    small: "",
                                    grey: "",
                                    close: "",
                                  },
                                  on: {
                                    "click:close": function ($event) {
                                      return _vm.deleteChip(item)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(item.name) + "\n                "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.selectedFilterValues,
                        callback: function ($$v) {
                          _vm.selectedFilterValues = $$v
                        },
                        expression: "selectedFilterValues",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      VTooltip,
                      {
                        attrs: { top: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "span",
                                  _vm._g({}, on),
                                  [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        {
                                          staticClass: "ml-3 add-filter-button",
                                          attrs: {
                                            icon: "",
                                            text: "",
                                            disabled: _vm.isAddFilterDisabled,
                                          },
                                          on: { click: _vm.addFilter },
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          VIcon,
                                          { attrs: { color: "primary" } },
                                          [_vm._v("mdi-plus-box")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.isAddFilterDisabled
                                ? _vm.$tkey("selectAValueToAddFilter")
                                : _vm.$tkey("addFilter")
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "mb-5 d-flex flex-column" }, [
                  _vm.filters.length
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _c("h2", { staticClass: "title-filters" }, [
                            _vm._v(_vm._s(_vm.$tkey("appliedFilters"))),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                VCol,
                                { staticClass: "headers pr-0 pb-1" },
                                [
                                  _c("h4", { staticClass: "pb-0" }, [
                                    _vm._v(_vm._s(_vm.$tkey("filterName"))),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                VCol,
                                { staticClass: "headers pr-0 pb-1" },
                                [
                                  _c("h4", { staticClass: "pb-0" }, [
                                    _vm._v(_vm._s(_vm.$tkey("equalNotEqual"))),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                VCol,
                                { staticClass: "headers pr-0 pb-1" },
                                [
                                  _c("h4", { staticClass: "pb-0" }, [
                                    _vm._v(_vm._s(_vm.$tkey("filterValues"))),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(VCol, {
                                staticClass: "headers pb-1 pr-1",
                                attrs: { cols: 1 },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.filters, function (filter, index) {
                            return _c(
                              "div",
                              {
                                key: filter.id,
                                staticClass: "d-flex filter-row",
                              },
                              [
                                _c(
                                  VCol,
                                  {
                                    staticClass:
                                      "filter-item pr-0 pt-1 pb-1 d-flex flex-column justify-center",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(filter.filterName) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  VCol,
                                  {
                                    staticClass:
                                      "filter-item pr-0 pt-1 pb-1 d-flex flex-column justify-center",
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          filter.notEqualTo
                                            ? _vm.$tkey("notEqual")
                                            : _vm.$tkey("equal")
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  VCol,
                                  {
                                    staticClass:
                                      "filter-item pr-0 pt-1 pb-1 d-flex flex-column justify-center",
                                  },
                                  _vm._l(filter.filterValues, function (value) {
                                    return _c(
                                      "div",
                                      { key: value.value + value.index },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(value.name) +
                                            "\n                  "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                                _vm._v(" "),
                                _c(
                                  VCol,
                                  {
                                    staticClass:
                                      "filter-item pt-1 pb-1 pr-1 d-flex flex-column justify-center align-center",
                                    attrs: { cols: 1 },
                                  },
                                  [
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "ml-1",
                                        attrs: { icon: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeFilter(index)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          VIcon,
                                          {
                                            attrs: {
                                              small: "",
                                              color: "primary",
                                            },
                                          },
                                          [_vm._v("mdi-close-circle")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    : _c("div", [
                        _c("h3", [
                          _vm._v(_vm._s(_vm.$tkey("noFiltersApplied"))),
                        ]),
                      ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-end align-center close-icon-container",
                },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "mr-0 font-weight-bold btn-text",
                      attrs: { ripple: false, text: "", small: "" },
                      on: {
                        click: function ($event) {
                          ;[(_vm.menu = false), _vm.closeModal(false)]
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("actions.close")) + "\n          ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }