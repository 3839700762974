import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "users d-flex flex-column align-start",
      class: _vm.position,
    },
    [
      !_vm.hideTitle
        ? _c("h4", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.$t("workpackagePage.usersPermissionsLabel"))),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex disabled" },
        [
          _c(
            "ul",
            { staticClass: "users-list d-flex justify-center" },
            _vm._l(_vm.users, function (user) {
              return _c(
                "li",
                { key: user.name, staticClass: "users-element" },
                [_c("user-permission", { attrs: { user: user } })],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(VIcon, { attrs: { size: "23", disabled: "" } }, [
            _vm._v("$add-people"),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }