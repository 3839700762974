import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VRow,
    { staticClass: "actions-row justify-space-between flex-grow-1" },
    [
      _c("div", [
        _c("div", { staticClass: "btn-container" }, [_vm._t("left-btns")], 2),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _vm.showExport
            ? _c(
                "div",
                {
                  staticClass: "btn-container btn-container--action",
                  class: _vm.displayDiscard,
                },
                [
                  _vm.exportOptions.length
                    ? [
                        _c(
                          "main-dropdown-menu",
                          _vm._g(
                            {
                              attrs: {
                                actions: _vm.exportOptions,
                                top: "",
                                title: "Export",
                                icon: "import_export",
                              },
                            },
                            _vm.$listeners
                          )
                        ),
                      ]
                    : _vm.isCustomExport
                    ? [
                        _c(
                          VBtn,
                          {
                            attrs: {
                              primary: "",
                              depressed: "",
                              disabled: !_vm.isExportEnabled,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("export")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.exportButtonTitle !== ""
                                    ? _vm.exportButtonTitle
                                    : _vm.$t("actions.export")
                                ) +
                                "\n          "
                            ),
                            _c(VIcon, { attrs: { right: "" } }, [
                              _vm._v("import_export"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    : [
                        _c("live-data-export", {
                          attrs: {
                            data: _vm.liveData,
                            "service-name": _vm.exportService,
                            "include-scenario-in-export-name":
                              _vm.includeScenarioInExportName,
                          },
                        }),
                      ],
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "action-btn-container d-flex" },
            [
              _vm._t("right-btns"),
              _vm._v(" "),
              _vm.showDiscard
                ? _c(
                    "div",
                    { staticClass: "outlined-btn-container" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "outlined-btn",
                          attrs: {
                            depressed: "",
                            disabled:
                              !_vm.isDiscardEnabled || !_vm.hasDataChanges,
                            secondary: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("discard")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("actions.discardChanges")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showProceedButton
                ? _c(
                    "div",
                    { staticClass: "save-btn-container" },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            "data-id-e2e": "btnProceedSpace",
                            depressed: "",
                            disabled: !_vm.hasDataChanges || _vm.hasDataErrors,
                            action: "",
                            loading: _vm.proceedLoading,
                          },
                          on: { click: _vm.proceed },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t(`actions.${_vm.proceedBtnText}`)) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showSaveButton
                ? _c(
                    "div",
                    { staticClass: "save-btn-container" },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            "data-id-e2e": "btnSaveSpace",
                            depressed: "",
                            disabled:
                              !_vm.hasDataChanges ||
                              _vm.hasDataErrors ||
                              _vm.saveDisabled,
                            action: "",
                            loading: _vm.saveLoading,
                          },
                          on: { click: _vm.onSaveClickDebounced },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t(`actions.${_vm.saveBtnText}`)) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("right-end-btns"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }