<template>
  <div class="error-page py-4 text-center">
    <h1 class="title">
      {{ $tkey(`${status}.title`) }}
    </h1>
    <h2 class="my-5">
      <span class="error-code align-middle">
        {{ status }}
      </span>
    </h2>
    <p class="description">{{ $tkey(`${status}.description`) }}</p>
  </div>
</template>

<script>
const localizationKey = 'errorPage';

export default {
  localizationKey,

  data() {
    return {
      status: this.$route.params.status,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.error-page {
  margin-top: 10rem;
  .title {
    font-size: 3rem !important;
  }
  .error-code {
    font-size: 4rem !important;
    width: 20rem;
    height: 20rem;
    line-height: 20rem !important;
    display: inline-block;
    border: 0.5rem solid $assortment-scenarios-border-colour;
    border-radius: 50%;
  }
  .description {
    font-size: 2rem;
  }
}
</style>
