<template>
  <v-card class="step-tab-panel" flat>
    <v-container class="pa-0 ma-0 d-flex flex-column flex-grow-1 date-assortment-groups__container">
      <v-row class="date-assortment-groups__header flex-grow-0 ma-0">
        <v-col>
          <span class="info-note">{{ $tkey('infoNote') }}</span>
        </v-col>
      </v-row>

      <v-row class="date-assortment-groups__form flex-grow-1" no-gutters>
        <v-col class="pr-4 col-3">
          <dates-selection
            :date-selections="dateSelections"
            :fill-in-selection="model.fillInSelection"
            :maximum-trend="model.maximumTrend"
            :workpackage="workpackage"
            :period-ids="model.periodIds"
            @date-changed="populateDateSelections"
            @model-changed="populateModel"
          />
        </v-col>

        <v-col class="pl-3 pr-3 col-7 col-border-left">
          <assortment-groups-selection
            v-if="dateSelections.snapshotDate"
            ref="assortmentGroupSelection"
            :snapshot-date="dateSelections.snapshotDate"
            :reset-assortment-groups="resetAssortmentGroups"
            :selected-assortment-group-settings="workpackage.selectedAssortmentGroupSettings || []"
            @select-assortment-group="populateSelectedAssortmentGroups"
          />
        </v-col>
      </v-row>

      <v-row class="date-assortment-groups__footer flex-grow-0">
        <v-col class="d-flex pa-0">
          <page-actions
            :has-data-changes="hasDataChanges"
            :has-data-errors="hasDataErrors"
            :is-discard-enabled="!isEditingDisabled"
            :save-disabled="isWorkpackageSetupRunning || isEditingDisabled"
            @discard="discardChanges"
            @save="saveTemplate(false)"
          >
            <template v-slot:left-btns>
              <v-divider class="mr-3 divider" vertical />
              <import-from-planogram
                class="import-from-planogram"
                :should-be-disabled="hasDataChanges"
                :custom-workpackage="dirtyWorkpackage"
              />

              <v-divider class="mr-3 divider" vertical />

              <v-tooltip :value="hasInvalidPeriodIds" :disabled="!hasInvalidPeriodIds" top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      data-id-e2e="btnRunTemplate"
                      class="mx-0"
                      :disabled="isEditingDisabled || hasDataChanges || hasInvalidPeriodIds"
                      :loading="isWorkpackageSetupRunning"
                      depressed
                      primary
                      min-width="65"
                      small
                      @click="runTemplateSetup(false)"
                    >
                      {{ $t('datesAssortmentGroupsPage.runWp') }}
                    </v-btn>
                  </div>
                </template>
                <span v-if="hasInvalidPeriodIds">{{
                  $t('workpackagePage.scope.warnings.periodIdWarning')
                }}</span>
              </v-tooltip>
            </template>
          </page-actions>

          <dependency-tree-feedback-modal
            class="dependency-modal"
            :value="dependencyTreeModalOpen"
            :results="dependencyTreeFeedback"
            page="workpackageSetup"
            @close="closeDependencyTreeModal"
            @commit="commitHandler"
          />
          <unsaved-data-modal
            ref="unsavedDataModal"
            :value="isUnsavedDataModalOpen"
            @cancel="closeUnsavedDataModal"
            @confirm="closeUnsavedDataModal"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { isEmpty, cloneDeep, get, assign, isEqual, keyBy, values, pick, each, keys } from 'lodash';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import to from 'await-to-js';
import SIZE_TYPES from '@enums/size-types';
import datesMixin from '@/js/mixins/date-utils';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import inputValidationMixin from '@/js/mixins/input-validations';

export default {
  name: 'TemplateDateAssortmentGroups',
  mixins: [datesMixin, inputValidationMixin, unsavedDataWarningMixin],
  localizationKey: 'datesAssortmentGroupsPage',

  data() {
    return {
      dateSelections: {
        snapshotDate: null,
        switchingStartDate: null,
        switchingDateWeeks: null,
        customSwitchingDateWeeks: 0,
        performanceExcludedDates: [],
        performanceStartDate: null,
        performanceDateWeeks: null,
        customPerformanceDateWeeks: 0,
        learningStartDate: null,
        learningDateWeeks: null,
        learningExcludedDates: [],
        customLearningDateWeeks: 0,
      },
      model: {
        fillInSelection: null,
        maximumTrend: null,
        periodIds: [],
        _id: null,
      },
      selectedAssortmentGroups: [],
      savedState: {},
      currentStateDiff: {},
      resetAssortmentGroups: true,
      dependencyTreeModalOpen: false,
      dependencyTreeFeedback: {},
      calledFromSetup: false,
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('context', ['clientConfig']),
    ...mapGetters('workpackages', ['isWorkpackageSetupRunning']),
    ...mapGetters('context', ['getDefaultReverseFormat']),
    ...mapGetters('scenarios', ['selectedScenario']),

    workpackage() {
      return this.selectedWorkpackage || {};
    },

    hasDataChanges() {
      return !isEmpty(this.currentStateDiff);
    },

    hasDataErrors() {
      return this.calculateTrendEnabled && this.validateMaximumTrend();
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditWorkpackageScope);
    },

    switchingEndDate() {
      return this.calculateEndDate({
        startDate: this.dateSelections.switchingStartDate,
        weeks: this.dateSelections.switchingDateWeeks,
        dateFormat: this.getDefaultReverseFormat,
      });
    },

    // returns workpackage currently being modified.
    dirtyWorkpackage() {
      return {
        ...this.model,
        ...this.dateSelections,
        switchingEndDate: this.switchingEndDate,
        selectedAssortmentGroupSettings: this.selectedAssortmentGroups.map(ag => {
          return pick(ag, ['key', 'analysis', 'assortment']);
        }),
        isTemplate: this.workpackage.isTemplate,
      };
    },

    calculateTrendEnabled() {
      return get(this.clientConfig, 'features.calculateTrendEnabled', false);
    },

    defaultMaximumTrend() {
      return get(this.clientConfig, 'maximumTrend');
    },

    hasPeriodIdsEnabled() {
      return get(this.clientConfig, 'features.workpackagePeriodIdsEnabled', false);
    },

    hasMandatoryPeriodIdsEnabled() {
      return get(this.clientConfig, 'betaFeatures.rcMandatoryPeriodIdsEnabled', false);
    },

    hasInvalidPeriodIds() {
      return (
        (!this.model.periodIds || this.model.periodIds.length === 0) &&
        this.hasPeriodIdsEnabled &&
        this.hasMandatoryPeriodIdsEnabled
      );
    },
  },

  created() {
    this.initialiseWorkpackageData();
  },

  methods: {
    ...mapMutations('scenarios', ['setSelectedScenario']),
    ...mapActions('dependencyTree', ['triggerDependencyTree']),
    ...mapActions('snackbar', ['showError']),
    ...mapActions('workpackages', ['updateWorkpackage', 'refreshWorkpackageInformation']),

    initialiseWorkpackageData() {
      this.model = {
        _id: this.workpackage._id,
        periodIds: this.workpackage.periodIds,
        fillInSelection: this.workpackage.fillInSelection || SIZE_TYPES.linearSpace,
        ...(this.calculateTrendEnabled && {
          maximumTrend: this.workpackage.maximumTrend || this.defaultMaximumTrend,
        }),
      };
      // Backup original fillInSelection value
      this.setSavedState({ fillInSelection: cloneDeep(this.model.fillInSelection) });
      // Backup original maximumTrend value
      if (this.calculateTrendEnabled)
        this.setSavedState({ maximumTrend: cloneDeep(this.model.maximumTrend) });
    },

    populateSelectedAssortmentGroups(selectedAssortmentGroups) {
      this.selectedAssortmentGroups = selectedAssortmentGroups;
      const assortmentGroupsById = keyBy(cloneDeep(this.selectedAssortmentGroups), '_id');

      // Backup initial assortment group selections
      if (!this.savedState.selectedAssortmentGroups) {
        this.setSavedState({
          selectedAssortmentGroups: assortmentGroupsById,
        });
      } else {
        this.updateDiff('selectedAssortmentGroups', assortmentGroupsById);
      }
    },

    populateDateSelections(dates) {
      this.resetAssortmentGroups = true;
      this.dateSelections = assign({}, this.dateSelections, dates);

      // Backup initial date selections
      if (!this.savedState.dateSelections) {
        this.setSavedState({
          dateSelections: cloneDeep(this.dateSelections),
        });
      } else {
        this.updateDiff('dateSelections', this.dateSelections);
      }
    },

    populateModel(model) {
      this.model = assign({}, this.model, model);
      each(keys(model), key => this.updateDiff(key, model[key]));
    },

    formatDataForSave() {
      const {
        snapshotDate,
        switchingStartDate,
        switchingDateWeeks,
        performanceExcludedDates,
        performanceStartDate,
        performanceDateWeeks,
        learningExcludedDates,
        learningStartDate,
        learningDateWeeks,
      } = this.dateSelections;

      const performanceEndDate = this.calculateEndDate({
        startDate: performanceStartDate,
        weeks: performanceDateWeeks,
        dateFormat: this.getDefaultReverseFormat,
      });
      const learningEndDate = this.calculateEndDate({
        startDate: learningStartDate,
        weeks: learningDateWeeks,
        dateFormat: this.getDefaultReverseFormat,
      });

      return {
        ...this.model,
        snapshotDate: this.formatDateForSave(snapshotDate, this.getDefaultReverseFormat),
        performanceExcludedDates: performanceExcludedDates.map(date =>
          this.formatDateForSave(date, this.getDefaultReverseFormat)
        ),
        performanceStartDate: this.formatDateForSave(
          performanceStartDate,
          this.getDefaultReverseFormat
        ),
        performanceEndDate: this.formatDateForSave(
          performanceEndDate,
          this.getDefaultReverseFormat
        ),
        switchingStartDate: this.formatDateForSave(
          switchingStartDate,
          this.getDefaultReverseFormate
        ),
        switchingEndDate: this.formatDateForSave(
          this.switchingEndDate,
          this.getDefaultReverseFormat
        ),
        switchingDateWeeks,
        performanceDateWeeks,
        learningDateWeeks,
        learningExcludedDates: learningExcludedDates.map(date =>
          this.formatDateForSave(date, this.getDefaultReverseFormat)
        ),
        learningStartDate: this.formatDateForSave(learningStartDate, this.getDefaultReverseFormat),
        learningEndDate: this.formatDateForSave(learningEndDate, this.getDefaultReverseFormat),
        selectedAssortmentGroupSettings: this.selectedAssortmentGroups.map(ag => {
          return pick(ag, ['key', 'analysis', 'assortment']);
        }),
        stores: this.workpackage.stores,
      };
    },

    async saveTemplate(commit = false) {
      const results = await this.triggerDependencyTree({
        params: {
          change: 'workpackageSetupModified',
          updates: {},
          commit,
          workpackageId: this.workpackage._id,
        },
      });

      if (results.needsFeedback) {
        this.dependencyTreeFeedback = results.output;
        this.dependencyTreeModalOpen = true;
        return false;
      }

      // If the dependency tree is triggered, the template scenario will be removed
      // Clear the deleted scenario from state to ensure that toolbar tabs dependent
      // on the scenario are disabled
      if (commit) this.setSelectedScenario({});

      const workpackage = this.formatDataForSave();
      const workpackageId = this.workpackage._id;
      const [err] = await to(this.updateWorkpackage({ workpackageId, updates: workpackage }));

      await this.refreshWorkpackageInformation({
        workpackageId,
        forceStateUpdate: true,
      });
      if (err) {
        const message = (err && err.message) || this.$t('errors.generalErrorMessage');
        this.showError(message);
      }

      // Update backup
      this.setSavedState(this.currentStateDiff);
      this.currentStateDiff = {};
      return true;
    },

    async commitHandler() {
      if (this.calledFromSetup) await this.runTemplateSetup(true);
      else this.saveTemplate(true);
    },

    async runTemplateSetup(commit = false) {
      this.calledFromSetup = true;
      const shouldProceed = await this.saveTemplate(commit);
      if (shouldProceed) this.globalEmit('run-template-setup');
    },

    discardChanges() {
      const { fillInSelection, dateSelections, selectedAssortmentGroups } = cloneDeep(
        this.savedState
      );
      this.model.fillInSelection = fillInSelection;

      if (this.calculateTrendEnabled) {
        const { maximumTrend } = cloneDeep(this.savedState);
        this.model.maximumTrend = maximumTrend;
      }

      this.$refs.assortmentGroupSelection.resetSelectedAssortmentGroups();
      // Set flag to ensure that selected assortment groups are reverted to saved state
      this.resetAssortmentGroups = false;
      // Assortment groups will automatically be refreshed if the snapshot date changes
      if (dateSelections.snapshotDate === this.dateSelections.snapshotDate) {
        // Refresh assortment group selections in list
        this.$refs.assortmentGroupSelection.selectAssortmentGroup(values(selectedAssortmentGroups));
      }

      this.dateSelections = dateSelections;
      this.currentStateDiff = {};
    },

    updateDiff(key, currentValue) {
      const originalValue = get(this.savedState, key);
      const existingValue = get(this.currentStateDiff, key);
      const valueHasChanged = !isEqual(currentValue, originalValue);

      // If the current state does not match the original, track the state difference
      if (valueHasChanged) {
        this.$set(this.currentStateDiff, key, currentValue);
      } else if (existingValue) {
        this.$delete(this.currentStateDiff, key);
      }
    },

    setSavedState(data) {
      this.savedState = assign({}, this.savedState, data);
    },

    validateMaximumTrend() {
      const maximumTrend = this.model.maximumTrend;
      return (
        !maximumTrend ||
        this.isNumber(maximumTrend) !== true ||
        maximumTrend > 100 ||
        this.isGreaterOrEqual(maximumTrend, 0) !== true
      );
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
      this.calledFromSetup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.date-assortment-groups {
  &__container {
    max-width: 100%;
  }

  &__header {
    border-bottom: 1px solid $assortment-divider-colour;
  }

  &__form {
    padding: 10px 5px 25px;
  }

  &__footer {
    background-color: $assortment-workpackage-bg-colour;
    border-top: 1px solid $assortment-divider-colour;

    .actions-row {
      flex: auto;
      flex-direction: row-reverse;
      justify-content: flex-start !important;
    }
  }
}

.col-border-left {
  border-left: 1px solid #ccc;
}

.import-from-planogram {
  margin-top: 2px;
}

::v-deep {
  .container.rtls-calendar-picker,
  .date-input.date-input-exclude,
  .assortment-groups {
    .v-input__slot,
    .v-select__slot {
      background-color: $assortment-control-secondary-bg-colour !important;
      height: 30px !important;
    }
  }

  .v-select__selections {
    input {
      padding-left: 5px;
    }
  }

  .v-select__selection {
    padding-left: 5px !important;

    &.v-select__selection--comma {
      font-weight: bold;
    }
  }

  .assortment-groups {
    .list {
      border: 1px solid $assortment-horizontal-border-colour;
    }
  }

  .actions-row {
    .action-btn-container {
      margin-right: 6px !important;
    }

    .btn-container {
      display: flex;
      padding-left: 0 !important;
    }
  }
}
</style>
