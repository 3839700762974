import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.componentName,
    {
      tag: "component",
      class: { "template-screen": _vm.fromTemplate },
      attrs: { "content-class": "rtls-dialog rtls-dialog--products" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeModal.apply(null, arguments)
        },
        "click:outside": _vm.closeModal,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                VBtn,
                _vm._g(
                  {
                    staticClass: "workpackage-products__link",
                    attrs: { ripple: false, text: "", small: "" },
                  },
                  on
                ),
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$tkey("scope.viewProductList")) +
                      "\n      "
                  ),
                  _c(VIcon, { attrs: { size: "20" } }, [
                    _vm._v("mdi-chevron-right"),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isPopupOpen,
        callback: function ($$v) {
          _vm.isPopupOpen = $$v
        },
        expression: "isPopupOpen",
      },
    },
    [
      _vm._v(" "),
      _c(
        "dialog-card",
        {
          attrs: {
            title: _vm.$tkey("products.popupHeading"),
            "is-popup": _vm.fromTemplate,
            "show-full-headers": !_vm.fromTemplate,
          },
          on: { close: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _vm.missingProducts.length
                    ? [
                        _c(
                          "div",
                          { attrs: { id: "failed-upload-notes" } },
                          [
                            _c(
                              "span",
                              [
                                _c("i18n", {
                                  attrs: {
                                    path: "workpackagePage.products.missingProductsInfo",
                                    tag: "span",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button",
                                        fn: function () {
                                          return [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click:
                                                    _vm.openMissingProductsPopup,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t("general.here")
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "totalMissing",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              _vm._s(_vm.missingProducts.length)
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3761786051
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "popup",
                              {
                                attrs: {
                                  "is-open": _vm.isMissingProductsPopupOpen,
                                },
                                on: { close: _vm.closeMissingProductsPopup },
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$tkey(
                                          "products.missingProductsList",
                                          {
                                            totalMissing:
                                              _vm.missingProducts.length,
                                            list: _vm.missingProducts.join(
                                              ", "
                                            ),
                                          }
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c("page-actions", {
                    attrs: {
                      "show-export": _vm.isOnDetailPage,
                      "show-save-button": _vm.hasPermission(
                        _vm.userPermissions.canEditWorkpackageScope
                      ),
                      "show-discard": _vm.isOnDetailPage,
                      "export-options": _vm.exportOptions,
                      "has-data-changes": _vm.hasDataChanges,
                      "has-data-errors": _vm.hasDataErrors,
                      "live-data": _vm.productsForDownload,
                      "export-service": _vm.serviceName,
                    },
                    on: {
                      templateDataExport: function ($event) {
                        return _vm.dataExport({
                          columns: _vm.templateExportColumns,
                          mode: "template-export",
                        })
                      },
                      fullDataExport: function ($event) {
                        return _vm.dataExport({
                          columns: _vm.fullExportColumns,
                          mode: "full-export",
                        })
                      },
                      save: function ($event) {
                        return _vm.handleSave(false)
                      },
                      discard: _vm.resetData,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "left-btns",
                          fn: function () {
                            return [
                              !_vm.fromTemplate && _vm.isOnDetailPage
                                ? _c(
                                    VBtn,
                                    {
                                      key: `delete-selected-products-${_vm.selectedRows.length}`,
                                      staticClass: "outlined-btn",
                                      attrs: {
                                        depressed: "",
                                        secondary: "",
                                        disabled: !_vm.selectedRows.length,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.detail.deleteSelectedProducts(
                                            _vm.selectedRows
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$tkey(
                                              "products.deleteSelectedProducts"
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                        _vm.fromTemplate
                          ? {
                              key: "right-end-btns",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "action-btn-container d-flex rtls-border rtls-border--left-thin pl-2",
                                    },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          attrs: {
                                            "data-id-e2e": "btnRunTemplate",
                                            disabled:
                                              !_vm.hasPermission(
                                                _vm.userPermissions
                                                  .canEditWorkpackageScope
                                              ) || _vm.hasDataChanges,
                                            loading:
                                              _vm.isWorkpackageSetupRunning,
                                            depressed: "",
                                            primary: "",
                                            small: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.runTemplateSetup(false)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.fromTemplate
                                                    ? "templatesPage.runWp"
                                                    : "workpackagePage.runWp"
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                      ],
                      null,
                      true
                    ),
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VCard,
            {
              staticClass: "d-flex flex-column",
              staticStyle: { height: "100%" },
            },
            [
              _c("div", { staticClass: "action-panel" }, [
                _c(
                  "div",
                  { staticClass: "action-panel-container" },
                  [
                    _c("data-upload", {
                      attrs: {
                        legends: _vm.csvUploadLegends,
                        "csv-upload-handler": _vm.onCSVUpload,
                        disabled: !_vm.hasPermission(
                          _vm.userPermissions.canEditWorkpackageScope
                        ),
                      },
                      on: { process: _vm.onUploadProcess },
                    }),
                    _vm._v(" "),
                    _vm.fromTemplate
                      ? _c("import-from-planogram", { staticClass: "ml-3" })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.isOnDetailPage
                  ? _c(
                      "div",
                      { staticClass: "action-panel-container" },
                      [
                        _c("rtls-search", {
                          attrs: {
                            placeholder: _vm.$tkey("stores.labels.search"),
                            width: "240px",
                          },
                          model: {
                            value: _vm.searchString,
                            callback: function ($$v) {
                              _vm.searchString = $$v
                            },
                            expression: "searchString",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column data-panel",
                  staticStyle: { height: "100%" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "input-tab-scope flex-grow-0" },
                    [
                      _c(
                        VTabs,
                        {
                          staticClass: "input-tabs",
                          attrs: {
                            value: _vm.currentTabId,
                            "hide-slider": true,
                            "no-animation": "",
                          },
                          on: { change: _vm.onTabChange },
                        },
                        [
                          _vm._l(_vm.tabs, function (item) {
                            return _c(
                              VTab,
                              {
                                key: item.key,
                                staticClass: "input-tab",
                                class: { "bl-0": _vm.fromTemplate },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleTabSelect(item.key)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.label) +
                                    "\n            "
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _vm.isOnDetailPage
                            ? _c(
                                VRow,
                                {
                                  staticClass:
                                    "d-flex justify-start align-center",
                                  class: { "ml-3": _vm.fromTemplate },
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    VCol,
                                    { staticClass: "add-product-btn-col ml-4" },
                                    [
                                      !_vm.isAddingNewProduct
                                        ? _c(
                                            VBtn,
                                            {
                                              attrs: {
                                                disabled: !_vm.hasPermission(
                                                  _vm.userPermissions
                                                    .canEditWorkpackageScope
                                                ),
                                                primary: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showNewProductInput()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t("actions.addProduct")
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isAddingNewProduct
                                        ? _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t("actions.addProduct")
                                                ) +
                                                ":\n                "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VCol,
                                    {
                                      staticClass:
                                        "d-flex align-center justify-start",
                                    },
                                    [
                                      _vm.isAddingNewProduct
                                        ? _c("assortment-search", {
                                            attrs: {
                                              id: "productSearch",
                                              "item-text": "productKeyDisplay",
                                              "item-label": _vm.getRowLabel,
                                              "on-search": _vm.onProductSearch,
                                              "async-search": "",
                                              "search-on-demand": "",
                                              "product-results-limit": 100,
                                              placeholder: _vm.$tkey(
                                                "products.selectIndividualProduct"
                                              ),
                                              "on-selection":
                                                _vm.onProductSelection,
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.isAddingNewProduct
                                        ? _c(
                                            VBtn,
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                disabled:
                                                  !_vm.newProductToAddManually,
                                                primary: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addProductManually()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t("actions.add")
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isOnDetailPage
                    ? _c(
                        "div",
                        { staticClass: "input-tab-panel flex-grow-1" },
                        [
                          _c("workpackage-products-table", {
                            ref: "detail",
                            attrs: {
                              products: _vm.products,
                              "products-for-deletion": _vm.productsForDeletion,
                              "search-string": _vm.searchString,
                              "is-popup-open": _vm.isPopupOpen,
                            },
                            on: {
                              "update:productsForDeletion": function ($event) {
                                _vm.productsForDeletion = $event
                              },
                              "update:products-for-deletion": function (
                                $event
                              ) {
                                _vm.productsForDeletion = $event
                              },
                              "handle-data-changes": _vm.handleDataChanges,
                              refresh: _vm.init,
                              "selected-rows": function ($event) {
                                _vm.selectedRows = $event
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentTab === "hierarchy"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "input-tab-panel flex-grow-1 d-flex flex-column",
                          attrs: { flat: "" },
                        },
                        [
                          _c("workpackage-products-hierarchy", {
                            ref: "hierarchy",
                            attrs: {
                              products: _vm.products,
                              "hierarchy-depth": _vm.hierarchyDepth,
                            },
                            on: {
                              "handle-product-selection-updates":
                                _vm.handleNewProductsFromHierarchy,
                              "handle-category-selection-updates":
                                _vm.handleCategorySelectionFromHierarchy,
                              refresh: _vm.init,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("dependency-tree-feedback-modal", {
        staticClass: "dependency-modal",
        attrs: {
          value: _vm.dependencyTreeModalOpen,
          results: _vm.dependencyTreeFeedback,
          page: "workpackageSetup",
        },
        on: { close: _vm.closeDependencyTreeModal, commit: _vm.commitHandler },
      }),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.closeUnsavedDataModal,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }