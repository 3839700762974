import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "section-container" },
    [
      _vm.isScenariosExists
        ? _c(
            "h2",
            { staticClass: "scenarios-title" },
            [
              _vm._v("\n    " + _vm._s(_vm.$t("scenarios.title")) + " "),
              _c("docs-link", {
                attrs: { link: "toolguide/030-scenario.html" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(VDataTable, {
        staticClass: "scenarios-table",
        attrs: {
          "item-key": "_id",
          "single-expand": true,
          "body-append": true,
          items: _vm.editableScenarios,
          loading: _vm.loading,
          expanded: _vm.initiallyExpanded,
          "hide-default-footer": "",
          "disable-pagination": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item, expand, isExpanded, index }) {
              return [
                _c("tr", { staticClass: "scenario" }, [
                  _c("td", { staticClass: "scenario__input-cell" }, [
                    _c("div", { staticClass: "scenario__input-container" }, [
                      _c(
                        "div",
                        { staticClass: "scenario__input" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(/* @submit.prevent needed to stop page reloading. see https://github.com/vuetifyjs/vuetify/issues/4302 */) +
                              "\n              "
                          ),
                          _c(
                            VForm,
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c("rtls-text-field", {
                                key: item._id,
                                staticStyle: { "padding-left": "7px" },
                                attrs: {
                                  disabled: !_vm.hasPermission(
                                    _vm.userPermissions.canEditScenarioName
                                  ),
                                  label: _vm.$t("scenarios.scenarioName"),
                                  rules: _vm.rules,
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.save(item)
                                  },
                                  keyup: [
                                    function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.save(item)
                                    },
                                    function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "esc",
                                          27,
                                          $event.key,
                                          ["Esc", "Escape"]
                                        )
                                      )
                                        return null
                                      return _vm.discard(item)
                                    },
                                  ],
                                },
                                model: {
                                  value: item.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item,
                                      "name",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "item.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "scenario__cell-container" },
                    [
                      _c(
                        "actionable-link",
                        {
                          staticClass: "scenario__cell clickable",
                          attrs: {
                            "on-click": () =>
                              _vm.toggleExpand({
                                item,
                                isExpanded: false,
                                expand,
                              }),
                            to: item.generatedRoutes["space"],
                            "right-click-only": "",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("scenarios.space")) +
                              "\n            "
                          ),
                          _c("scenarios-list-icon", {
                            attrs: {
                              "item-status": item.status,
                              subsection: "space",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass:
                        "scenario__cell-container scenario__cell-container--main",
                    },
                    [
                      _c(
                        "actionable-link",
                        {
                          staticClass: "scenario__cell clickable",
                          attrs: {
                            "on-click": () =>
                              _vm.toggleExpand({
                                item,
                                isExpanded: false,
                                expand,
                              }),
                            to: item.generatedRoutes["inputs"],
                            "right-click-only": "",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("scenarios.inputs")) +
                              "\n            "
                          ),
                          _c("scenarios-list-icon", {
                            attrs: {
                              "item-status": item.status,
                              subsection: "inputs",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isFullResetWP
                    ? _c(
                        "td",
                        {
                          staticClass:
                            "scenario__cell-container scenario__cell-container--sub",
                        },
                        [
                          _c(
                            "actionable-link",
                            {
                              staticClass: "scenario__cell clickable",
                              attrs: {
                                "on-click": () =>
                                  _vm.toggleExpand({
                                    item,
                                    isExpanded: false,
                                    expand,
                                  }),
                                to: item.generatedRoutes["measuring"],
                                "right-click-only": "",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("scenarios.measuring")) +
                                  "\n            "
                              ),
                              _c("scenarios-list-icon", {
                                attrs: {
                                  "item-status": item.status,
                                  subsection: "measuring",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFullResetWP
                    ? _c(
                        "td",
                        {
                          staticClass:
                            "scenario__cell-container scenario__cell-container--sub",
                        },
                        [
                          _c(
                            "actionable-link",
                            {
                              staticClass: "scenario__cell clickable",
                              attrs: {
                                "on-click": () =>
                                  _vm.toggleExpand({
                                    item,
                                    isExpanded: false,
                                    expand,
                                  }),
                                to: item.generatedRoutes["modelling"],
                                "right-click-only": "",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("scenarios.modelling")) +
                                  "\n            "
                              ),
                              _c("scenarios-list-icon", {
                                attrs: {
                                  "item-status": item.status,
                                  subsection: "modelling",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFullResetWP
                    ? _c(
                        "td",
                        {
                          staticClass:
                            "scenario__cell--container scenario__cell-container--main",
                        },
                        [
                          _c(
                            _vm.isAssortmentsPanelDisabled(item)
                              ? "div"
                              : "actionable-link",
                            {
                              tag: "component",
                              staticClass:
                                "scenario__cell scenario__cell--assortments",
                              class: {
                                clickable:
                                  !_vm.isAssortmentsPanelDisabled(item),
                              },
                              attrs: {
                                "on-click": () =>
                                  _vm.toggleExpand({
                                    item,
                                    panel: "assortment",
                                    isDisabled:
                                      _vm.isAssortmentsPanelDisabled(item),
                                    isExpanded: false,
                                    expand,
                                  }),
                                to: item.generatedRoutes["assortments-panel"],
                                "right-click-only": "",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("scenarios.assortments")) +
                                  "\n            "
                              ),
                              _c("scenarios-list-icon", {
                                attrs: {
                                  "item-status": item.status,
                                  subsection: "assortment",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFullResetWP
                    ? _c(
                        "td",
                        {
                          staticClass:
                            "scenario__cell-container scenario__cell-container--main",
                        },
                        [
                          _c(
                            "actionable-link",
                            {
                              staticClass:
                                "scenario__cell scenario__cell--execution clickable",
                              attrs: {
                                to: item.generatedRoutes["execution"],
                                "on-click": _vm.preRouteAction(item),
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("scenarios.execution")) +
                                  "\n            "
                              ),
                              _c("scenarios-list-icon", {
                                attrs: {
                                  "item-status": item.status,
                                  subsection: "execution",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSimpleSwapsWP
                    ? _c(
                        "td",
                        {
                          staticClass:
                            "scenario__cell-container scenario__cell-container--main",
                        },
                        [
                          _c(
                            "actionable-link",
                            {
                              staticClass: "scenario__cell clickable",
                              attrs: {
                                "on-click": () =>
                                  _vm.toggleExpand({
                                    item,
                                    isExpanded: false,
                                    expand,
                                  }),
                                to: item.generatedRoutes["swaps"],
                                "right-click-only": "",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("scenarios.swaps")) +
                                  "\n            "
                              ),
                              _c("scenarios-list-icon", {
                                attrs: {
                                  "item-status": item.status,
                                  subsection: "swaps",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSimpleSwapsWP
                    ? _c(
                        "td",
                        {
                          staticClass:
                            "scenario__cell-container scenario__cell-container--main",
                        },
                        [
                          _c(
                            "actionable-link",
                            {
                              staticClass: "scenario__cell clickable",
                              attrs: {
                                "on-click": () =>
                                  _vm.toggleExpand({
                                    item,
                                    isExpanded: false,
                                    expand,
                                  }),
                                to: item.generatedRoutes["extracts"],
                                "right-click-only": "",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("scenarios.extracts")) +
                                  "\n            "
                              ),
                              _c("scenarios-list-icon", {
                                attrs: {
                                  "item-status": item.status,
                                  subsection: "extracts",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass:
                        "scenario__cell-container scenario__cell-container--main",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "scenario__cell scenario__cell--last-edit",
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("scenarios.scenarioLastEdit")) +
                                "\n              "
                            ),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDate")(
                                    item.lastModifiedDate,
                                    _vm.getDateFormats.longWithTime
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("notes", {
                            attrs: {
                              notes: _vm.getNotes(item._id),
                              loading: item.isLoading,
                              title: _vm.$t("notes.types.scenario"),
                              "max-chars":
                                _vm.getClientConfig.notesSettings.maxCharSize,
                              notifications: !!item.totalNotes,
                            },
                            on: {
                              post: function ($event) {
                                return _vm.postScenarioNote(
                                  item._id,
                                  index,
                                  $event
                                )
                              },
                              menuOpen: function ($event) {
                                return _vm.fetchScenarioNotes(item._id, index)
                              },
                              delete: function ($event) {
                                return _vm.deleteScenarioNote(
                                  item._id,
                                  index,
                                  $event
                                )
                              },
                              update: function ($event) {
                                return _vm.updateScenarioNote(
                                  item._id,
                                  index,
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "expand-button-container" }, [
                    _c(
                      "div",
                      { staticClass: "scenario__cell scenario__cell--icons" },
                      [
                        _c("scenario-options", { attrs: { scenario: item } }),
                        _vm._v(" "),
                        _c("main-expand-button", {
                          class: { "float-right": "float-right" },
                          attrs: { "is-expanded": isExpanded },
                          on: {
                            expand: function ($event) {
                              return _vm.toggleExpand({
                                item,
                                isExpanded,
                                expand,
                              })
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", { staticClass: "scenario spacer" }, [
                  _c("td", {
                    staticClass: "spacer-line",
                    attrs: { colspan: "12" },
                  }),
                ]),
              ]
            },
          },
          {
            key: "expanded-item",
            fn: function ({ item }) {
              return [
                _c("tr"),
                _vm._v(" "),
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass: "scenario__mgmt-panel-container",
                      attrs: { colspan: "12" },
                    },
                    [
                      _c(_vm.selectedPanel, {
                        tag: "component",
                        attrs: { scenario: item },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", { staticClass: "scenario spacer" }, [
                  _c("td", {
                    staticClass: "spacer-line",
                    attrs: { colspan: "12" },
                  }),
                ]),
              ]
            },
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("scenarios-create-form", {
                  attrs: { "workpackage-id": _vm.workpackage._id },
                  on: { "save:scenario": _vm.fetchWpScenarios },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "no-data",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "no-data-text" },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("scenarios.noScenariosToShow"))),
                    ]),
                    _vm._v(" "),
                    _c("docs-link", {
                      attrs: { link: "toolguide/030-scenario.html" },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }