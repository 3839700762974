<template>
  <v-list-item>
    <v-col cols="4" class="pa-2 d-flex align-center">
      {{ field.translationKey ? $t(field.translationKey) : field.selectedField }}
    </v-col>
    <v-col cols="4" class="pa-2 pr-1 d-flex">
      <v-autocomplete
        :value="selectedField"
        :items="fieldsOptions"
        :error="error && !isCreatingNew"
        :class="{ 'font-weight-bold': isActionOption(selectedField) }"
        class="rtls-select"
        item-text="selectedField"
        hide-details
        return-object
        :placeholder="$t('mapFieldsModal.selectOption')"
        @change="updateSelectedField"
      >
        <!-- Slot to customise dropdown options -->
        <template slot="item" slot-scope="data">
          <v-list-item-content>
            <v-list-item-title
              class="v-list-item__title"
              :class="{ 'font-weight-bold': isActionOption(data.item.selectedField) }"
            >
              {{ data.item.selectedField }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <error-triangle :class="{ hidden: isCreatingNew }" class="ml-1" :errors="errors" />
    </v-col>
    <v-col v-if="isCreatingNew" cols="4" class="pa-2 pl-1 d-flex">
      <v-text-field
        :error="error"
        class="rtls-text-field rtls-text-field--grey"
        single-line
        hide-details
        :value="field.selectedField"
        @input="setNewMapping(field.mongoField, $event)"
      />
      <error-triangle class="ml-1 mr-1" :errors="errors" />
    </v-col>
  </v-list-item>
</template>

<script>
import { every, some, isEqual, includes } from 'lodash';
import uploadMethods from '@enums/productAttributes/attribute-upload-methods';

export default {
  props: {
    field: {
      type: Object,
      required: true,
    },

    fieldsOptions: {
      type: Array,
      required: false,
      default: () => [],
    },

    mappings: {
      type: Array,
      required: true,
    },

    uploadMetadata: {
      type: Object,
      required: false,
      default: () => {},
    },

    fieldsToIgnore: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      selectedField: '',
      newColumn: {},
      isCreatingNew: false,
      isDeleting: false,
      isColumnUnique: true,
      isColumnNameUnique: true,
      isNameNotEmpty: true,
      actionOptions: {
        createNew: this.$t('mapFieldsModal.createNew'),
        ignoreColumn: this.$t('mapFieldsModal.ignoreColumn'),
      },
    };
  },

  computed: {
    errors() {
      return {
        [this.$t('validationErrors.unique', [this.$t('mapFieldsModal.columnName')])]: this
          .isColumnNameUnique,
        [this.$t('validationErrors.unique', [this.$t('mapFieldsModal.column')])]: this
          .isColumnUnique,
        [this.$t('validationErrors.required')]: this.isNameNotEmpty,
      };
    },

    error() {
      return !every(this.errors);
    },

    uploadFromFeed() {
      return this.uploadMetadata.uploadMethod === uploadMethods.FEED;
    },

    uploadFromScenario() {
      return this.uploadMetadata.uploadMethod === uploadMethods.SCENARIO;
    },

    forceDefaultIgnore() {
      return (
        includes(this.fieldsToIgnore, this.field.key) ||
        (this.uploadFromScenario && !isEqual(this.field.mongoField, 'productKeyDisplay'))
      );
    },
  },

  watch: {
    mappings: {
      deep: true,
      handler() {
        this.validate(this.newColumn);
      },
    },
  },

  created() {
    let defaultSelection = this.actionOptions.ignoreColumn;

    if (this.forceDefaultIgnore) {
      this.selectedField = defaultSelection;
      this.isCreatingNew = false;
    } else {
      defaultSelection = this.field.isNew
        ? this.actionOptions.createNew
        : this.actionOptions.ignoreColumn;

      // Sets the default selected dropdown option
      if (this.field.translationKey) {
        this.selectedField = this.$t(this.field.translationKey);
      } else {
        const canSelectFieldOption = some(
          this.fieldsOptions,
          fo => fo.selectedField === this.field.selectedField
        );
        this.selectedField = canSelectFieldOption ? this.field.selectedField : defaultSelection;
      }

      this.isCreatingNew = !!this.field.isNew;
    }

    this.isDeleting = this.selectedField === this.actionOptions.ignoreColumn;
    // TODO: Replace naming of mongoField with a more generic name. Should avoid using db specific names. Ideally we would be using business domain names.
    this.setNewMapping(
      this.field.mongoField,
      this.$t(this.field.translationKey) || this.field.selectedField
    );
    // Validate on load to ensure that error messages appear
    this.validate(this.newColumn);
  },

  methods: {
    updateSelectedField({ selectedField, mongoField }) {
      // Update field if create new option is selected from dropdown
      this.isCreatingNew = selectedField === this.actionOptions.createNew;
      this.isDeleting = selectedField === this.actionOptions.ignoreColumn;
      this.field.isNew = this.isCreatingNew;

      this.selectedField = selectedField;
      // The key is the original column header name that was uploaded in the CSV.
      // If the user selects the 'Create New' option, the name
      // will be set to the key by default. This can then be overriden.
      if (this.isCreatingNew) {
        selectedField = this.field.key;
        // If importing from other scenario, also set mongoField to get new data from
        if (this.uploadFromScenario) {
          mongoField = this.field.mongoField;
        }
      }
      let oldMongoField;
      if (this.uploadFromScenario) {
        oldMongoField = this.field.mongoField;
      }
      this.setNewMapping(mongoField, selectedField, oldMongoField);
    },

    setNewMapping(mongoField, selectedField, oldMongoField) {
      const isNew = this.field.isNew;
      // On import from other scenario, keep mongoField set for new columns to get data correctly.
      // Otherwise, when creating a new column, make sure mongoField is not set.
      // New fields should not be mapped to existing columns in general.
      if (isNew && !this.uploadFromScenario) {
        mongoField = null;
      }

      // Store new column name value so that it can be used for validation
      this.newColumn = {
        selectedField: selectedField.trim(),
        mongoField,
      };

      this.$emit('update-mapping', {
        key: this.field.key,
        selectedField: this.newColumn.selectedField,
        mongoField,
        oldMongoField,
        isNew,
        isDeleting: this.isDeleting,
      });
    },

    validate(field) {
      // Check if selected column already exists in mapping
      this.isColumnUnique = this.isUniqueColumn(field);
      // Check if column name exists in mapping
      this.isColumnNameUnique = this.isUniqueName(field);
      // Check if a column name has been entered
      this.isNameNotEmpty = field.selectedField.length > 0;
    },

    isUniqueColumn(value) {
      const mappedColumns = this.mappings.filter(
        m => m.mongoField && m.mongoField === value.mongoField
      );
      return mappedColumns.length <= 1;
    },

    getFieldDefaultName(field) {
      return this.uploadFromFeed && !field.translationKey
        ? `${this.uploadMetadata.source} - ${field.selectedField}`
        : this.$t(field.translationKey) || field.selectedField;
    },

    isUniqueName(value) {
      const mappedColumns = this.mappings.filter(m => {
        // If value is for a fixed field, use the translation
        const mappedHeader = m.mongoField
          ? this.$t(`mongoFields.${m.mongoField}`)
          : m.selectedField;

        return mappedHeader.toLowerCase() === value.selectedField.toLowerCase();
      });
      return mappedColumns.length <= 1;
    },

    isActionOption(selectedField) {
      return some(
        Object.keys(this.actionOptions),
        key => this.actionOptions[key] === selectedField
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

::v-deep {
  .error-triangle-container {
    width: 28px !important;
  }

  .v-select__slot {
    input {
      padding: 10px 2px 6px 5px !important;
    }
  }
}
</style>
