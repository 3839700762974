'use strict';

const DataTypes = require('../../enums/data-types');
const commonWorkpackageStoreSchema = require('./workpackage-stores-shared');

const fixedFields = commonWorkpackageStoreSchema.fixedFields.concat([
  {
    mongoField: 'simpleSwapsCluster',
    type: DataTypes.str,
    aliases: 'csvAliases.workpackageStores.simpleSwapsCluster',
    translationKey: 'mongoFields.cluster',
    required: true, // only used on Simple Swap WP
  },
]);

module.exports = {
  fixedFields,
};
