import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    [
      _c(
        VDialog,
        {
          attrs: {
            persistent: "",
            "retain-focus": "",
            "max-width": "350",
            "content-class": "rtls-dialog",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on: onDialog }) {
                return [
                  _c(
                    VTooltip,
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: onTooltip, attrs }) {
                              return [
                                _c(
                                  VBtn,
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "delete-button",
                                        attrs: {
                                          disabled: !_vm.hasPermission(
                                            _vm.userPermissions
                                              .canDeleteScenario
                                          ),
                                          icon: "",
                                          text: "",
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    { ...onDialog, ...onTooltip }
                                  ),
                                  [_c(VIcon, [_vm._v("$trash")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("actions.delete")))]),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _vm._v(" "),
          _c(
            VCard,
            [
              _c(
                VCardTitle,
                { staticClass: "title", attrs: { "primary-title": "" } },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "close-button",
                      attrs: { icon: "", text: "" },
                      on: { click: _vm.close },
                    },
                    [_c(VIcon, [_vm._v("mdi-close-circle")])],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(VCardText, { staticClass: "display-1 text-center" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$tkey("question", { name: _vm.scenario.name }))
                  ),
                ]),
                _vm._v(" "),
                _c("strong", [_vm._v(_vm._s(_vm.$tkey("warning")))]),
              ]),
              _vm._v(" "),
              _c(
                VCardActions,
                { staticClass: "justify-center flex-column" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "ma-2",
                      attrs: { primary: "" },
                      on: { click: _vm._deleteScenario },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$tkey("action")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    VHover,
                    { attrs: { disabled: "" } },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "cancel ma-2",
                          attrs: { text: "", depressed: "" },
                          on: { click: _vm.close },
                        },
                        [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }