var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: {
            callback: _vm.visibilityChanged,
            throttle: 500,
          },
          expression:
            "{\n    callback: visibilityChanged,\n    throttle: 500,\n  }",
        },
      ],
      staticClass: "d-flex flex-drag-box",
    },
    [
      _c(
        "draggable",
        {
          staticClass: "drag-container d-flex flex-wrap align-content-start",
          attrs: {
            "ghost-class": "ghost",
            draggable: ".draggable",
            delay: "30",
            disabled: _vm.isDragDisabled,
            list: _vm.filteredProducts,
            group: _vm.groupName,
            sort: false,
            "scroll-sensitivity": 150,
            "invert-swap": true,
            "force-fallback": true,
          },
          on: {
            change: _vm.onChange,
            start: _vm.onDragStart,
            end: _vm.onDragStop,
          },
        },
        [
          _vm.showProducts
            ? _vm._l(_vm.filteredProducts, function (product, index) {
                return _c(
                  "div",
                  {
                    key: `${product._id}-${product.isHighlighted}`,
                    staticClass: "default-box",
                    class: {
                      draggable:
                        product.lockType === _vm.lockTypes.unlocked &&
                        !_vm.isDragDisabled &&
                        _vm.inReset(product),
                    },
                    attrs: {
                      id: `tile-product-${product.productKey}`,
                      index: index,
                    },
                  },
                  [
                    _c("product-tile", {
                      attrs: {
                        index: index,
                        "spacebreak-id": _vm.spacebreakId,
                        product: product,
                        "selected-tile-size": _vm.selectedTileSize,
                        "tile-view-ref": _vm.tileViewRef,
                        "open-popup-on-load-for-product-id":
                          _vm.productIdForPopup,
                      },
                    }),
                  ],
                  1
                )
              })
            : _c(
                "div",
                { staticClass: "d-flex flex-wrap skeleton-box" },
                _vm._l(_vm.filteredProducts, function (product) {
                  return _c(
                    "div",
                    {
                      key: product._id,
                      staticClass: "d-flex flex-column default-box skeleton",
                      class: {
                        "medium-margin": _vm.selectedTileSize === "medium",
                      },
                      style: {
                        height: `${
                          _vm.tileSize[_vm.selectedTileSize].tile.height
                        }px`,
                        width: `${
                          _vm.tileSize[_vm.selectedTileSize].tile.width
                        }px`,
                      },
                      attrs: { id: `tile-product-${product.productKey}` },
                    },
                    [
                      _c("img", {
                        attrs: {
                          id: `product-tile-popup-${product.productKey}`,
                          src: require("../../../img/spinner.gif"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.delayPopup(product._id)
                          },
                        },
                      }),
                    ]
                  )
                }),
                0
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }