import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VRow,
    { staticClass: "header ma-0 flex-grow-0" },
    [
      _c(
        VCol,
        { staticClass: "d-flex align-center", attrs: { cols: _vm.columns } },
        [
          _c("span", { staticClass: "info-note" }, [
            _vm._v(_vm._s(_vm.headerText)),
          ]),
          _vm._v(" "),
          _c("docs-link", { attrs: { link: _vm.tooltipLink } }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex align-center" }, [
        _vm.showSwitching
          ? _c(
              "div",
              { attrs: { "data-id-e2e": "btnRunSwitching" } },
              [
                _c(
                  VBtn,
                  {
                    attrs: {
                      primary: "",
                      disabled: _vm.disabled || _vm.isJobRunning("switching"),
                    },
                    on: { click: _vm.onSwitchingClick },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("scenarioMeasuringPage.buttons.runSwitching")
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showPrepareSwaps
          ? _c(
              "div",
              { attrs: { "data-id-e2e": "btnRunPrepareSwaps" } },
              [
                _c(
                  VBtn,
                  {
                    attrs: {
                      primary: "",
                      disabled:
                        _vm.disabled || _vm.isJobRunning("prepareSwaps"),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("trigger-job", _vm.prepareSwapsJob)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "scenarioMeasuringPage.buttons.runPrepareSwaps"
                          )
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(VCol),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "reRunConfirmation",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  VCardText,
                  { staticClass: "display-1 pa-0 text-center" },
                  [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm.$tkey(
                            "switchingWrapper.reRunConfirmation.warning"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          _vm.$tkey(
                            "switchingWrapper.reRunConfirmation.question"
                          )
                        )
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function ({ cancel: close }) {
              return [
                _c(
                  VRow,
                  [
                    _c(
                      VCol,
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          VBtn,
                          {
                            attrs: { action: "" },
                            on: {
                              click: function ($event) {
                                ;[
                                  _vm.$emit("trigger-job", _vm.switchingJob),
                                  close(),
                                ]
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$tkey(
                                    "switchingWrapper.reRunConfirmation.confirm"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            staticClass: "ml-2",
                            on: {
                              click: function ($event) {
                                return close()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$tkey(
                                    "switchingWrapper.reRunConfirmation.cancel"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }