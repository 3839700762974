<template>
  <v-card class="step-tab-panel" flat>
    <v-row>
      <v-col justify="start" class="h-100 pa-0" data-id-e2e="aocExtractPage">
        <stepper
          ref="stepper"
          from-series
          :step-data="steps"
          :series-statuses="seriesStatus"
          :extract-type="extractType"
        />
      </v-col>
      <v-col v-if="showPreviousExtracts" justify="start" cols="4" class="pt-0 pr-0">
        <previous-compound-reports :reports="reports" :extract-type="extractType" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import stepperData from '@/js/utils/stepper-compound-steps';
import { sortBy } from 'lodash';
import { AOC } from '@enums/extract-types';
import extractsUtils from '@/js/utils/extracts-utils';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('compoundExtract', ['selectedExtract', 'selectedStep']),
    ...mapGetters('compoundExtract', ['getSelectedExtractStatus', 'previousExtracts']),

    extractSeriesId() {
      return this.$route.params.extractSeriesId;
    },

    steps() {
      return sortBy(stepperData.aocExtractSteps, 'order');
    },

    reports() {
      return extractsUtils.reports({ extracts: this.previousExtracts, reportType: AOC });
    },

    seriesStatus() {
      return this.getSelectedExtractStatus;
    },

    showPreviousExtracts() {
      return this.selectedStep === 'aoc-output-report';
    },

    extractType() {
      return AOC;
    },
  },

  async created() {
    await this.fetchExtract(this.extractSeriesId);
  },

  methods: {
    ...mapActions('compoundExtract', ['fetchExtract']),
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.step-tab-panel {
  background-color: $assortment-background !important;
}
</style>
