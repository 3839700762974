<template>
  <v-container v-if="selectedWorkpackage && showTemplateWorkflow && !isRefreshingState" fluid>
    <v-layout column class="main-layout">
      <v-flex class="d-flex flex-column">
        <toolbar
          back-nav-route="templates"
          :tabs-title="$t('templatesPage.toolbar.tabsTitle')"
          :toolbar-tabs="templateToolbarTabs"
          :workpackage="selectedWorkpackage"
          :show-statuses="false"
        />
        <router-view :key="routerKey" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { template } from '@enums/client-events';
import { get, includes, some, intersectionWith } from 'lodash';
import { mapState, mapActions, mapMutations } from 'vuex';
import templateToolbarTabs from '@/js/utils/tabs/template-toolbar-tabs-enum';
import toolbarTabs from '@/js/utils/tabs/toolbar-tabs-enum';

export default {
  async beforeRouteLeave(to, from, next) {
    /* Ensure to reset any data that can overlap between templates/home page  */
    await this.setSelectedWorkpackage(null);
    await this.setWorkpackageProducts([]);
    await this.setScenarioPanelExpanded(false);
    return next();
  },

  data() {
    return {
      templateToolbarTabs,
      isRefreshingState: false,
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('scenarios', ['selectedScenario']),

    scenarioTabs() {
      return intersectionWith(
        toolbarTabs.ALL,
        this.templateToolbarTabs.ALL,
        (toolbarTab, templateTab) => toolbarTab.id === templateTab.id
      );
    },

    workpackageId() {
      return this.$route.params.templateId || get(this.selectedWorkpackage, '_id');
    },

    scenarioId() {
      return this.$route.params.templateScenarioId || this.selectedScenario._id;
    },

    routerKey() {
      return `${this.workpackageId}-${this.scenarioId}`;
    },

    showTemplateWorkflow() {
      /* In scenario-workflow.vue, the scenarioId value must be set before displaying tab content.
         The template workflow will include some of the same tabs as scenario workflow. As a result,
         we need to ensure that the scenarioId value is set before showing content for those tabs */
      if (some(this.scenarioTabs, t => includes(this.$route.fullPath, t.id)))
        return this.selectedWorkpackage && this.selectedScenario._id;
      return this.selectedWorkpackage;
    },
  },

  async created() {
    await this.handleNavigationChanges();
  },

  events: {
    [template.runTemplateSetup]() {
      this.runWorkpackageSetup();
    },
  },

  methods: {
    ...mapActions('workpackages', [
      'loadState',
      'refreshWorkpackageInformation',
      'runWorkpackageSetupJob',
      'setSelectedWorkpackage',
    ]),
    ...mapMutations('workpackageProducts', ['setWorkpackageProducts']),
    ...mapMutations('workpackages', ['setScenarioPanelExpanded']),

    async runWorkpackageSetup() {
      await this.runWorkpackageSetupJob();
      await this.refreshWorkpackageInformation({
        workpackageId: this.workpackageId,
        forceStateUpdate: true,
      });
    },

    async handleNavigationChanges() {
      this.isRefreshingState = true;
      await this.refreshWorkpackageInformation({
        workpackageId: this.workpackageId,
        forceStateUpdate: true,
      });
      await this.loadState({ workpackageId: this.workpackageId });
      this.isRefreshingState = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-layout {
  height: 100%;
}
</style>
