import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    [
      _c(
        VRow,
        {
          staticClass: "checkpoint-row d-flex align-center",
          attrs: { cols: "12" },
        },
        [
          _c(
            VCol,
            { staticClass: "pa-0", attrs: { cols: "1" } },
            [
              _c(VSwitch, {
                attrs: {
                  disabled: _vm.isObservedCheckpoint || _vm.isEditingDisabled,
                  label: _vm.$tkey("checkpoints.reference"),
                },
                model: {
                  value: _vm.referenceCheckpoint,
                  callback: function ($$v) {
                    _vm.referenceCheckpoint = $$v
                  },
                  expression: "referenceCheckpoint",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            { staticClass: "pa-0", attrs: { cols: "3" } },
            [
              _c(
                VForm,
                {
                  staticClass: "d-flex",
                  attrs: { autocomplete: "off" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                    },
                  },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("rtls-text-field", {
                    key: _vm.nameKey,
                    staticClass: "text-field",
                    attrs: {
                      disabled: _vm.isEditingDisabled,
                      rules: _vm.rules,
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.save(_vm.checkpoint)
                      },
                      keyup: [
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.save(_vm.checkpoint)
                        },
                        function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k($event.keyCode, "esc", 27, $event.key, [
                              "Esc",
                              "Escape",
                            ])
                          )
                            return null
                          return _vm.discard(_vm.checkpoint)
                        },
                      ],
                    },
                    model: {
                      value: _vm.checkpointMeta.name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.checkpointMeta,
                          "name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "checkpointMeta.name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass: "pa-0 checkpoint-details-container",
              attrs: { cols: "5" },
            },
            [
              _c("div", { staticClass: "checkpoint-details-info" }, [
                _c("div", { staticClass: "checkpoint-details-item" }, [
                  _c("p", { staticClass: "checkpoint-details-title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$tkey("checkpoints.dateAndTime")) +
                        ":\n            "
                    ),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(
                            _vm.checkpointMeta.createdDate,
                            _vm.getDateFormats.longWithTimeAndMeridiem
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "checkpoint-details-item" }, [
                  _c("p", { staticClass: "checkpoint-details-title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$tkey("checkpoints.forecastStart")) +
                        ":\n            "
                    ),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.displayForecastParameter("start")) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "checkpoint-details-info" }, [
                _c("div", { staticClass: "checkpoint-details-item" }, [
                  _c("p", { staticClass: "checkpoint-details-title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$tkey("checkpoints.sales")) +
                        ":\n            "
                    ),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.displayForecastResult("forecastedSales"))
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "checkpoint-details-item" }, [
                  _c("p", { staticClass: "checkpoint-details-title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$tkey("checkpoints.forecastEnd")) +
                        ":\n            "
                    ),
                    _c("span", [
                      _vm._v(
                        " " + _vm._s(_vm.displayForecastParameter("end")) + " "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "checkpoint-details-info" }, [
                _c("div", { staticClass: "checkpoint-details-item" }, [
                  _c("p", { staticClass: "checkpoint-details-title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$tkey("checkpoints.margin")) +
                        ":\n            "
                    ),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.displayForecastResult("forecastedMargin"))
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "checkpoint-details-item" }, [
                  _c("p", { staticClass: "checkpoint-details-title" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$tkey("checkpoints.forecastLength")) +
                        ":\n            "
                    ),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.displayForecastParameter("length")) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass: "buttons-container d-flex justify-end pa-0",
              attrs: { cols: "2" },
            },
            [
              _c(
                VBtn,
                {
                  staticClass: "assortment-btn-clear",
                  attrs: {
                    "data-id-e2e": "btnRevertCheckpoint",
                    outlined: "",
                    disabled:
                      _vm.isObservedCheckpoint ||
                      _vm.checkpoint.isNotRevertable ||
                      _vm.isEditingDisabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.initRevertCheckpointToCanvas(_vm.checkpoint)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$tkey("checkpoints.revert.action")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  attrs: {
                    "data-id-e2e": "btnRunForecast",
                    primary: "",
                    depressed: "",
                    disabled: _vm.isEditingDisabled,
                    loading: _vm.hasForecasterJobRunning,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.runForecaster(_vm.checkpoint)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$tkey("checkpoints.runForecast")))]
              ),
              _vm._v(" "),
              _vm.showDelete
                ? _c(
                    VBtn,
                    {
                      staticClass: "delete-button",
                      attrs: {
                        "data-id-e2e": "btnDeleteCheckpoint",
                        disabled: _vm.isEditingDisabled,
                        icon: "",
                        text: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.initDelete(_vm.checkpoint._id)
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { size: "24" } }, [
                        _vm._v("$trash"),
                      ]),
                    ],
                    1
                  )
                : _c("span", { staticStyle: { width: "24px" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(VDivider, { attrs: { horizontal: "" } }),
      _vm._v(" "),
      _c(
        VRow,
        { staticClass: "checkpoint-details-row", attrs: { cols: "12" } },
        [
          _c(VCol, { staticClass: "pa-0" }, [
            _c("p", { staticClass: "checkpoint-type" }, [
              _vm._v(_vm._s(_vm.checkpointGeneratedBy())),
            ]),
          ]),
          _vm._v(" "),
          _vm.showNotImplemented
            ? _c(
                "div",
                { staticClass: "show-details greyed-out" },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$tkey("checkpoints.showDetails"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    VIcon,
                    { staticClass: "icon", attrs: { size: "21", primary: "" } },
                    [_vm._v("expand_more")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "forecasterSetup",
        attrs: { width: "25%", border: true },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "forecasterSetup" },
                  [
                    _c(
                      VRow,
                      { staticClass: "border-bottom" },
                      [
                        _c(VCol, { attrs: { cols: "6" } }, [
                          _c("div", { staticClass: "date-input" }, [
                            _c(
                              "div",
                              { staticClass: "wp-scope__date-input-container" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "date-input",
                                    attrs: {
                                      "data-id-e2e": "forecasterStartDateField",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$tkey(
                                            "forecasterSetupModal.forecastStart"
                                          )
                                        ) + ":"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("rtls-calendar-picker", {
                                      attrs: {
                                        "allowed-dates": _vm.allowedStartDates,
                                        "display-date-format":
                                          _vm.getDateFormats.long,
                                        "icon-title": _vm.$tkey(
                                          "forecasterSetupModal.iconTitle"
                                        ),
                                      },
                                      model: {
                                        value: _vm.forecasterSetupWeekStartDate,
                                        callback: function ($$v) {
                                          _vm.forecasterSetupWeekStartDate = $$v
                                        },
                                        expression:
                                          "forecasterSetupWeekStartDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          VCol,
                          { attrs: { cols: "6" } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tkey(
                                    "forecasterSetupModal.numberOfSlices"
                                  )
                                ) + ":"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("rtls-text-field", {
                              staticClass: "text-field",
                              attrs: {
                                "data-id-e2e":
                                  "drpForecasterSetupNumberOfSlices",
                                rules: _vm.numberOfSlicesValidationRules,
                                "run-validations-on-creation": "",
                              },
                              on: {
                                "rtls-text-field-errors":
                                  _vm.textFieldErrorsHandler,
                              },
                              model: {
                                value: _vm.forecasterSetupNumberOfSlices,
                                callback: function ($$v) {
                                  _vm.forecasterSetupNumberOfSlices = $$v
                                },
                                expression: "forecasterSetupNumberOfSlices",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      VRow,
                      { staticClass: "border-bottom" },
                      [
                        _c(
                          VCol,
                          { staticClass: "mt-2", attrs: { cols: 4 } },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$tkey(
                                    "forecasterSetupModal.forecastSliceLength"
                                  )
                                ) + ":"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          VCol,
                          { staticClass: "d-flex" },
                          [
                            _c("rtls-select", {
                              attrs: {
                                "data-id-e2e": "drpForecastSliceLength",
                                "hide-details": "",
                                width: "100%",
                                items: _vm.forecasterSetupSliceLengths,
                                placeholder: _vm.$t("general.select"),
                                white: "",
                              },
                              model: {
                                value: _vm.forecasterSetupSliceLength,
                                callback: function ($$v) {
                                  _vm.forecasterSetupSliceLength = $$v
                                },
                                expression: "forecasterSetupSliceLength",
                              },
                            }),
                            _vm._v(" "),
                            _c("error-triangle", {
                              staticClass: "ml-1 mr-1",
                              attrs: {
                                errors: {
                                  [_vm.$tkey(
                                    "errorMessages.selectForecastSliceLength"
                                  )]: _vm.forecasterSetupSliceLength,
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      VRow,
                      [
                        _c(
                          VCol,
                          {
                            staticClass: "d-flex performance-end-date",
                            attrs: {
                              "data-id-e2e": "fldForecastEndDate",
                              cols: 6,
                            },
                          },
                          [
                            _c("span", { staticClass: "forecast-end" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$tkey("forecasterSetupModal.forecastEnd")
                                ) + ":"
                              ),
                            ]),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("moment")(
                                    _vm.forecasterSetupEndDate,
                                    _vm.getDateFormats.long
                                  )
                                ) +
                                "\n            "
                            ),
                            _c("error-triangle", {
                              staticClass: "end-date-error-triangle",
                              attrs: {
                                errors: {
                                  [_vm.$tkey(
                                    "errorMessages.endDateBiggerThanWpPerfPeriodEndDate"
                                  )]: _vm.endDateValidation,
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          VCol,
                          [
                            _c(
                              VBtn,
                              {
                                staticClass: "pull-right",
                                attrs: {
                                  primary: "",
                                  disabled: _vm.confirmDisabled,
                                },
                                on: { click: _vm.startForecaster },
                              },
                              [_vm._v(_vm._s(_vm.$t("actions.confirm")))]
                            ),
                            _vm._v(" "),
                            _c(
                              VBtn,
                              {
                                staticClass: "pull-right mr-1",
                                attrs: { text: "" },
                                on: { click: _vm.cancelForecaster },
                              },
                              [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "revertCheckpointModal",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  VCardText,
                  { staticClass: "display-1 pa-0 text-center" },
                  [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$tkey("checkpoints.revert.question"))),
                    ]),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$tkey("checkpoints.revert.warning"))),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function ({ cancel, confirm }) {
              return [
                _c(
                  VCardActions,
                  { staticClass: "justify-center flex-column" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "ma-2",
                        attrs: { primary: "" },
                        on: { click: confirm },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tkey("checkpoints.revert.action")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      VBtn,
                      {
                        staticClass: "cancel ma-2",
                        attrs: { text: "", depressed: "" },
                        on: { click: cancel },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("actions.cancel")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "confirm",
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c(
                  VCardText,
                  { staticClass: "display-1 pa-0 text-center" },
                  [
                    _c("p", [
                      _vm._v(_vm._s(_vm.$tkey("checkpoints.delete.question"))),
                    ]),
                    _vm._v(" "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$tkey("checkpoints.delete.warning"))),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function ({ cancel, confirm }) {
              return [
                _c(
                  VCardActions,
                  { staticClass: "justify-center flex-column" },
                  [
                    _c(
                      VBtn,
                      {
                        staticClass: "ma-2",
                        attrs: { primary: "" },
                        on: { click: confirm },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tkey("checkpoints.delete.action")) +
                            "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      VBtn,
                      {
                        staticClass: "cancel ma-2",
                        attrs: { text: "", depressed: "" },
                        on: { click: cancel },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("actions.cancel")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }