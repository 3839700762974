<template>
  <div class="d-flex flex-row">
    <h4 class="header align-self-center">{{ $t('workpackagePage.wpTypeLabel') }}</h4>
    <v-select
      class="wp-select ml-2"
      hide-details
      height="23px"
      required
      :disabled="selectionDisabled"
      :value="type"
      :items="types"
      :rules="rules"
      :placeholder="$t('workpackagePage.scope.filterByCategoryLabel')"
      :menu-props="{ bottom: true, offsetY: true }"
      @change="onChange('type', $event)"
    >
      <template slot="append">
        <v-icon size="28">expand_more</v-icon>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: {
    types: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: () => '',
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    selectionDisabled() {
      return this.disabled || !this.hasPermission(this.userPermissions.canChangeWorkpackageType);
    },
  },
  methods: {
    onChange(field, value) {
      this.$emit('update', { field, value });
    },
  },
};
</script>

<style lang="scss" scoped>
.type {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 205px !important;
}

.header {
  align-self: flex-start;
  font-size: 1.2rem;
  line-height: normal;
  font-weight: normal;
}

.wp-select {
  align-self: stretch;
  margin-top: 2px;

  ::v-deep {
    & .v-input-control {
      height: 20px;
    }

    & .v-select__selections {
      .v-select__selection {
        max-width: 100%;
      }

      input {
        padding: 0;
      }
    }

    & .v-input__append-inner i {
      font-size: 19px;
    }
  }
}
</style>
