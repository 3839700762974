<template>
  <div style="display: contents;">
    <div
      :style="{ 'grid-row': '1 / span 3', top: 0, height: `${headerHeight}px` }"
      class="grid-item-border-bottom flexed-white-bg-grid-item header"
      :class="headerPositioning"
    >
      <div class="d-flex flex-column align-center pa-2 justify-center full-width">
        <div class="inside-box-title">
          <div class="title-text">
            <h2 :title="name" class="column-title">
              {{ name }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <tile-view v-if="selectedView === views.Tile" :products="products" :group-name="name" />
    <list-view
      v-else
      :products="products"
      :group-name="name"
      :positioning="isStickyHeader"
      :previous-heights="headerHeight"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Views } from '@enums/assortment-canvases';

export default {
  name: 'CanvasGrid',
  props: {
    name: {
      required: true,
      type: String,
    },
    products: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      views: Views,
      headerHeight: 85, // Same height as sidebar heading (unified columns)
    };
  },

  computed: {
    ...mapState('assortmentCanvas', ['stickyHeaders', 'selectedView']),

    headerPositioning() {
      // If user has stickyHeaders option enabled then set their position as sticky.
      return this.stickyHeaders ? 'sticky' : 'relative';
    },

    isStickyHeader() {
      return this.stickyHeaders ? 'sticky' : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.column-title {
  font-size: 2rem;
}
</style>
