import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "view-reports__container pa-0", attrs: { fluid: "" } },
    [
      _c(
        VRow,
        { staticClass: "view-reports__row" },
        [
          _c(VCol, { staticClass: "pa-0", attrs: { cols: "6" } }, [
            _vm.selectedScenario
              ? _c(
                  "div",
                  { staticClass: "ag-grid-box d-flex flex-column flex-grow-1" },
                  [
                    _c("h2", { staticClass: "view-reports__heading mb-3" }, [
                      _vm._v(_vm._s(_vm.$tkey("header"))),
                    ]),
                    _vm._v(" "),
                    _c("ag-grid-vue", {
                      staticClass: "ag-theme-custom",
                      staticStyle: { width: "75%", height: "100%" },
                      attrs: {
                        "column-defs": _vm.headers,
                        "row-data": _vm.reports,
                        "grid-options": _vm.gridOptions,
                      },
                      on: { "grid-ready": _vm.onGridReady },
                    }),
                  ],
                  1
                )
              : _c("section", [
                  _c("h2", [_vm._v(_vm._s(_vm.$t("errors.scenarioNotFound")))]),
                ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }