<template>
  <div class="numeric-locale-switcher">
    <div class="label">{{ $t('navBar.numbers') }}</div>
    <rtls-select
      v-model="currentNumericLocale"
      :items="availableFormats"
      item-text="format"
      item-value="locale"
      white
      :menu-props="{ right: true, offsetX: true, contentClass: 'options' }"
      solo
      hide-details
      @change="setLocale"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'NumericLocaleSwitcher',

  data: () => ({
    currentNumericLocale: null,
  }),

  computed: {
    ...mapGetters('context', [
      'getCurrentNumericLocale',
      'getAvailableNumericLocales',
      'getClientConfig',
    ]),

    availableFormats() {
      return this.getAvailableNumericLocales;
    },
  },

  created() {
    this.currentNumericLocale = this.getCurrentNumericLocale;
  },

  methods: {
    ...mapActions('context', ['setNumericLocale']),

    setLocale(locale) {
      this.setNumericLocale({ locale });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-input__append-inner {
    transform: rotate(-90deg);
  }
}

.label {
  font-size: 1.2rem;
  font-weight: normal;
  min-width: 6.1rem;
}

.numeric-locale-switcher {
  display: flex;
  align-items: center;
}
</style>
