<template>
  <div class="h-100 w-100 pl-3">
    <ag-grid-vue
      id="switching-heatmap-grid"
      :class="heatmapClass"
      :grid-options="gridOptions"
      :column-defs="columnDefs"
      :row-data="gridData.data"
      @grid-ready="onGridReady"
    />
    <!-- Legend -->
    <div v-if="gridData" class="legend">
      <div
        v-for="dataClass in dataClasses"
        :key="`dataClass-${dataClass.value}`"
        class="legend-item"
        :style="{
          'background-color': getDataClassBackgroundColor(dataClass.value),
          color: getTextColor(dataClass.value),
        }"
      >
        {{ dataClass.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { get, size, toNumber, round } from 'lodash';
import numberUtils from '@/js/utils/number-format-utils';

const CustomTooltip = {
  template: `
    <switching-tooltip :value-info="getValueInfo" />
  `,

  computed: {
    getValueInfo() {
      return this.params.getTooltipData({
        from: this.data.from,
        to: this.data.to[this.params.toPosition],
        value: this.params.colDef.valueFormatter({
          value: this.params.value[this.params.toPosition],
        }),
      });
    },
  },

  beforeMount() {
    this.data = this.params.api.getDisplayedRowAtIndex(this.params.rowIndex).data;
  },

  getGui() {
    return this.eGui;
  },
};

export default {
  components: {
    AgGridVue,
    // Required for custom tooltip
    CustomTooltip, // eslint-disable-line
  },

  props: {
    gridData: {
      required: false,
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      dataClasses: [
        { value: 0, text: '>0%' },
        { value: 0.24, text: '0-25%' },
        { value: 0.49, text: '25-50%' },
        { value: 0.74, text: '50-75%' },
        { value: 0.99, text: '75-100%' },
      ],
      colorList: [],
      numberOfSteps: 5,
      stepSize: 25,
      gridApi: null,
      gridOptions: {
        suppressContextMenu: true,
        tooltipShowDelay: 0,
        rowHeight: 45,
        rowClass: 'rtls-border rtls-border--bottom',
        defaultColDef: {
          menuTabs: [],
          maxWidth: 100,
          minWidth: 50,
        },

        onFirstDataRendered: this.resizeSideColumn,
      },
    };
  },

  computed: {
    columnDefs() {
      if (!this.gridData) return [];

      const vm = this;
      const mapLocation = this.gridData.displayOption === 'product' ? `.productKeyDisplay` : '';
      // main columns
      const headers = get(this.gridData, 'labels', []).map((l, i) => ({
        headerName: get(this.gridData.headersMap, `${l}${mapLocation}`),
        field: `probabilities.${i}`,
        colId: `probabilities.${i}`,
        tooltipField: `probabilities`,

        valueGetter: node => {
          const colId = node.column.colId.split('.')[1];
          return node.data.probabilities[Number(colId)];
        },
        valueFormatter: params => {
          return `${numberUtils.formatNumber({ number: params.value * 100, format: 'percent' })}%`;
        },
        headerClass: 'rtls-border rtls-border--bottom',
        cellClass: 'switching-grid-item',
        cellStyle: params => {
          return {
            backgroundColor: vm.getDataClassBackgroundColor(params.value),
            color: vm.getTextColor(params.value),
          };
        },

        tooltipComponentFramework: 'CustomTooltip',
        tooltipComponentParams: {
          getTooltipData: this.getTooltipData,
          toPosition: i,
        },
      }));

      // side column
      return [
        {
          headerName: '',
          field: 'from',
          colId: 'fromDesc',
          valueGetter: node => get(this.gridData.headersMap, `${node.data.from}${mapLocation}`),
          cellClass: 'side-label overflow-ellipsis',
          tooltipValueGetter: this.getSideColumnTooltipData,
          pinned: 'left',
        },
        ...headers,
      ];
    },

    heatmapClass() {
      return get(this.gridData, 'class', 'fixed-height-heatmap');
    },
  },

  created() {
    const minColor = [242, 248, 250];
    const maxColor = [1, 126, 150];
    // Get list of colors for heatmap labels
    this.colorList = this.interpolateColors(minColor, maxColor, this.numberOfSteps);
  },

  methods: {
    // Returns a single rgb color interpolation between two given rgb colors
    // based on the factor given; via https://codepen.io/njmcode/pen/axoyD?editors=0010
    interpolateColor(color1, color2, factor) {
      if (arguments.length < 3) {
        factor = 0.5;
      }
      const result = color1.slice();
      for (let i = 0; i < 3; i += 1) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
      }
      return result;
    },

    interpolateColors(color1, color2, steps) {
      const stepFactor = 1 / (steps - 1);
      const interpolatedColorArray = [];

      for (let i = 0; i < steps; i += 1) {
        interpolatedColorArray.push(this.interpolateColor(color1, color2, stepFactor * i));
      }

      return interpolatedColorArray;
    },

    getDataClassBackgroundColor(value) {
      // gets background color in rgb
      const indexForValue = Math.min(
        size(this.colorList) - 1,
        Math.ceil(round(value * 100) / this.stepSize)
      );
      const color = this.colorList[indexForValue];
      return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    },

    getTextColor(value) {
      return toNumber(round(value * 100)) >= 75 ? 'white' : 'black';
    },

    getTooltipData({ to, from, value }) {
      const productFrom = this.gridData.headersMap[from];
      const productTo = this.gridData.headersMap[to];
      const isProduct = this.gridData.displayOption === 'product';

      return {
        from: isProduct
          ? `${productFrom.itemDescription} (${productFrom.productKeyDisplay})`
          : from, // for attribute
        to: isProduct ? `${productTo.itemDescription} (${productTo.productKeyDisplay})` : to,
        value,
      };
    },

    getSideColumnTooltipData(params) {
      const { from } = params.node.data;
      const productFrom = this.gridData.headersMap[from];
      const isProduct = this.gridData.displayOption === 'product';

      return isProduct ? `${params.value} (${productFrom.itemDescription})` : params.value;
    },

    onGridReady(params) {
      params.columnApi.autoSizeColumns(['fromDesc']);
      this.gridApi = params.api;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.fixed-height-heatmap {
  width: 100%;
  height: 480px;
  padding-top: 5px;
}

.full-height-heatmap {
  width: 100%;
  height: 70vh;
  padding-top: 5px;
}

::v-deep {
  .switching-grid-item {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;

    padding: 5px;
    background-clip: content-box;
  }

  .ag-pinned-left-cols-container .ag-row {
    align-items: center;
    background-color: $assortment-table-header-white-background;
    border-color: $assortment-table-header-white-background;
    display: flex;
  }

  .side-label {
    display: inline-block;
    font-weight: bold;
    font-size: 1.2rem;
    height: auto;
  }

  .ag-header-cell-label {
    justify-content: center;
  }

  .ag-header,
  .ag-header-row {
    height: 30px !important;
    min-height: 30px !important;
  }
}

.legend {
  display: flex;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid $assortment-tile-border-colour;

  .legend-item {
    margin-right: 3px;
    padding: 3px;
  }
}
</style>
