<template>
  <div class="status-scope d-flex flex-column justify-start">
    <div class="scope-info d-flex flex-column justify-center align-start">
      <h4 class="header">{{ $t('workpackagePage.lastModified') }}</h4>
      <div class="setup-container d-flex align-center justify-space-between">
        <span class="date">{{ date | formatDate(getDateFormats.longWithTimeAndMeridiem) }}</span>
        <div class="setup-icons d-flex align-center justify-space-between">
          <v-icon size="20">{{ status ? `$${status}` : '' }}</v-icon>
          <v-btn icon text :disabled="disabled" :loading="isLoading" @click="handleScope">
            <v-icon size="20">$target</v-icon>
          </v-btn>
          <notes
            :notes="getAllNotes"
            :loading="isNotesLoading"
            :title="$t('notes.types.workpackage')"
            :max-chars="getClientConfig.notesSettings.maxLength"
            :notifications="hasNotes"
            @post="postNewNote"
            @menuOpen="fetchAllNotes"
            @delete="deleteCurrentNote"
            @update="updateCurrentNote"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import contextEnum from '@enums/note-contexts';
import { notesMixin } from '../../../../mixins/notes';

export default {
  mixins: [notesMixin(contextEnum.workpackage)],
  props: {
    date: {
      type: String,
      required: true,
      default: '',
    },
    status: {
      type: String,
      required: true,
      default: '$success',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    workpackageId: {
      type: String,
      required: true,
    },
    hasNotes: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isNoteLoading: false,
    };
  },
  computed: {
    ...mapGetters('context', ['getDateFormats', 'getClientConfig']),

    getNotesParam() {
      return {
        id: this.workpackageId,
        context: contextEnum.workpackage,
      };
    },
  },

  methods: {
    handleScope() {
      this.$emit('toggleScope');
    },
  },
};
</script>

<style lang="scss" scoped>
.status-scope {
  min-width: 220px;
  max-width: 220px;
}
.scope-info {
  .date {
    display: block;
    width: min-content;
    white-space: nowrap;
    font-weight: bold;
  }

  .header {
    font-size: 1.2rem;
    line-height: normal;
    font-weight: normal;
    white-space: nowrap;
  }

  .setup-container {
    margin-top: 1px;
    width: 100%;
  }
}

.setup-icons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-left: 4px;
  margin-bottom: 2px;
  width: 100%;
  max-width: 8rem;
}
</style>
