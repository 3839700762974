import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VRow,
    { staticClass: "assortment-groups" },
    [
      _c(
        VCol,
        { staticClass: "col-border-left pt-0 pb-0", attrs: { cols: 6 } },
        [
          _c(
            "div",
            { staticClass: "col-padding" },
            [
              _c("h3", { staticClass: "assortment-groups__input-heading" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.assortmentGroupsLabel) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                VSelect,
                {
                  attrs: {
                    items: _vm.categories,
                    multiple: "",
                    placeholder: _vm.$t(
                      "workpackagePage.scope.filterByCategoryLabel"
                    ),
                    "menu-props": { bottom: true, offsetY: true },
                  },
                  model: {
                    value: _vm.selectedCategories,
                    callback: function ($$v) {
                      _vm.selectedCategories = $$v
                    },
                    expression: "selectedCategories",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "append" },
                    [
                      _c(VIcon, { attrs: { size: "28" } }, [
                        _vm._v("expand_more"),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(VSheet, { staticClass: "margin-right" }, [
                _c(
                  "div",
                  { staticClass: "search" },
                  [
                    _c("rtls-search", {
                      attrs: {
                        placeholder: _vm.$t(
                          "workpackagePage.scope.searchLabel"
                        ),
                      },
                      model: {
                        value: _vm.assortmentSearch,
                        callback: function ($$v) {
                          _vm.assortmentSearch = $$v
                        },
                        expression: "assortmentSearch",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "list pa-3",
                    attrs: {
                      "data-id-e2e": "listAvailableAssortmentGroupsOld",
                    },
                  },
                  [
                    _vm.loading
                      ? _c("progress-bar", {
                          attrs: { message: _vm.$t("general.loading") },
                        })
                      : [
                          _vm.templatesEnabled && !!_vm.workpackage
                            ? _c("recycle-scroller", {
                                key: _vm.uniqueId("selectedGroup"),
                                staticClass: "scroller split-scroller",
                                attrs: {
                                  items: _vm.unselectedAgsSection.fromTemplate,
                                  "item-size": 1,
                                  buffer: 200,
                                  "page-mode": true,
                                  "key-field": "key",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "before",
                                      fn: function () {
                                        return [
                                          _vm.hasTemplateAvailable
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    `${_vm.$t(
                                                      "general.from"
                                                    )} ${_vm.$t(
                                                      "general.template"
                                                    )}`
                                                  )
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "default",
                                      fn: function (props) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "list-item",
                                              class: {
                                                selectable:
                                                  _vm.clickableAGSelectionsEnabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.clickableAGSelectionsEnabled &&
                                                    _vm.selectAssortmentGroup(
                                                      props.item
                                                    )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(props.item.text) +
                                                  "\n                  "
                                              ),
                                              _c(
                                                VIcon,
                                                {
                                                  staticClass: "list-icon",
                                                  attrs: {
                                                    size: "14",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.selectAssortmentGroup(
                                                        props.item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-plus-thick\n                  "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  629927665
                                ),
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("recycle-scroller", {
                            key: _vm.uniqueId("availableGroups"),
                            staticClass: "scroller",
                            attrs: {
                              items: _vm.unselectedAgsSection.additional,
                              "item-size": 1,
                              "page-mode": true,
                              "key-field": "key",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "before",
                                fn: function () {
                                  return [
                                    _vm.hasTemplateAvailable
                                      ? _c("span", {}, [
                                          _vm._v(
                                            _vm._s(_vm.$t("general.additional"))
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "default",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "list-item",
                                        class: {
                                          selectable:
                                            _vm.clickableAGSelectionsEnabled,
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.clickableAGSelectionsEnabled &&
                                              _vm.selectAssortmentGroup(
                                                props.item
                                              )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(props.item.text) +
                                            "\n                  "
                                        ),
                                        _c(
                                          VIcon,
                                          {
                                            staticClass: "list-icon",
                                            attrs: {
                                              size: "14",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.selectAssortmentGroup(
                                                  props.item
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    mdi-plus-thick\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        VCol,
        {
          staticClass: "margin-right col-border-left pt-0 pb-0",
          attrs: { cols: 6 },
        },
        [
          _c(
            "div",
            {
              staticClass: "col-padding d-flex flex-column justify-start",
              attrs: { "data-id-e2e": "listSelectedAssortmentGroupsOld" },
            },
            [
              _c(
                VRow,
                {
                  staticClass: "row-assortment-groups",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(VCol, { attrs: { cols: 7 } }, [
                    _c(
                      "h3",
                      { staticClass: "assortment-groups__input-heading" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "workpackagePage.scope.selectedAssortmentGroupsLabel"
                              )
                            ) +
                            "\n            - " +
                            _vm._s(_vm.selectedAssortmentGroups.length) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { attrs: { cols: 5, align: "right" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            disabled:
                              !_vm.hasPermission(
                                _vm.userPermissions.canEditWorkpackageScope
                              ) || _vm.isImportingFromPlano,
                            color: "primary",
                            text: "",
                            plain: "",
                          },
                          on: { click: _vm.resetSelectedAssortmentGroups },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("workpackagePage.scope.resetSelection")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VSheet,
                { staticClass: "list selected-list pa-3" },
                [
                  _vm.loading
                    ? _c("progress-bar", {
                        attrs: { message: _vm.$t("general.loading") },
                      })
                    : [
                        _vm.templatesEnabled && !!_vm.workpackage
                          ? [
                              _c("recycle-scroller", {
                                key: _vm.uniqueId("selectedGroup"),
                                staticClass: "scroller split-scroller",
                                attrs: {
                                  items: _vm.selectedAGsSection.fromTemplate,
                                  "item-size": 1,
                                  buffer: 200,
                                  "page-mode": true,
                                  "key-field": "key",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    _vm.hasTemplateAvailable
                                      ? {
                                          key: "before",
                                          fn: function () {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    `${_vm.$t(
                                                      "general.from"
                                                    )} ${_vm.$t(
                                                      "general.template"
                                                    )}`
                                                  )
                                                ),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        }
                                      : null,
                                    {
                                      key: "default",
                                      fn: function (props) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "list-item",
                                              class: {
                                                selectable:
                                                  _vm.clickableAGSelectionsEnabled,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.clickableAGSelectionsEnabled &&
                                                    _vm.deselectAssortmentGroup(
                                                      props.item
                                                    )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(props.item.text) +
                                                  "\n                  "
                                              ),
                                              _c(
                                                VIcon,
                                                {
                                                  staticClass: "list-icon",
                                                  attrs: {
                                                    size: "14",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.deselectAssortmentGroup(
                                                        props.item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    mdi-close-circle\n                  "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("recycle-scroller", {
                          key: _vm.uniqueId("selectedGroup"),
                          staticClass: "scroller",
                          attrs: {
                            items: _vm.selectedAGsSection.additional,
                            "item-size": 1,
                            buffer: 200,
                            "page-mode": true,
                            "key-field": "key",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "before",
                              fn: function () {
                                return [
                                  _vm.hasTemplateAvailable
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("general.additional"))
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "default",
                              fn: function (props) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "list-item",
                                      class: {
                                        selectable:
                                          _vm.clickableAGSelectionsEnabled,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.clickableAGSelectionsEnabled &&
                                            _vm.deselectAssortmentGroup(
                                              props.item
                                            )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(props.item.text) +
                                          "\n                "
                                      ),
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "list-icon",
                                          attrs: {
                                            size: "14",
                                            color: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.deselectAssortmentGroup(
                                                props.item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  mdi-close-circle\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }