import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { "data-id-e2e": "btnImport" } },
    [
      _c(
        VBtn,
        {
          attrs: {
            small: "",
            depressed: "",
            color: "primary",
            disabled:
              !_vm.hasSelectedAssortmentGroups ||
              !_vm.hasPermission(_vm.userPermissions.canEditWorkpackageScope) ||
              _vm.shouldBeDisabled,
            loading: _vm.isImportingFromPlano,
          },
          on: {
            click: function ($event) {
              return _vm.importFromPlanogram()
            },
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("actions.importAssortmentGroups")) +
              "\n    "
          ),
          _c(VIcon, { attrs: { right: "" } }, [_vm._v("mdi-download")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("assortment-tooltip", {
        attrs: {
          title: _vm.$t("tooltips.importFromAssortmentGroups.title"),
          "tooltip-sections": _vm.importFromAssortmentGroupsToolTipSections,
        },
      }),
      _vm._v(" "),
      _c("dependency-tree-feedback-modal", {
        attrs: {
          value: _vm.dependencyTreeModalOpen,
          results: _vm.dependencyTreeFeedback,
          page: "workpackageSetup",
        },
        on: {
          close: _vm.closeDependencyTreeModal,
          commit: function ($event) {
            return _vm.importFromPlanogram(true)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }