<template>
  <li>
    <v-dialog v-model="dialog" persistent retain-focus max-width="350" content-class="rtls-dialog">
      <template v-slot:activator="{ on: onDialog }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onTooltip, attrs }">
            <v-btn
              class="delete-button"
              :disabled="!hasPermission(userPermissions.canDeleteScenario)"
              icon
              text
              v-bind="attrs"
              v-on="{ ...onDialog, ...onTooltip }"
            >
              <v-icon>$trash</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('actions.delete') }}</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title class="title" primary-title>
          <v-btn class="close-button" icon text @click="close">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="display-1 text-center">
          <p>{{ $tkey('question', { name: scenario.name }) }}</p>
          <strong>{{ $tkey('warning') }}</strong>
        </v-card-text>

        <v-card-actions class="justify-center flex-column">
          <v-btn primary class="ma-2" @click="_deleteScenario">
            {{ $tkey('action') }}
          </v-btn>
          <v-hover disabled>
            <v-btn text depressed class="cancel ma-2" @click="close">{{
              $t('actions.cancel')
            }}</v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </li>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  localizationKey: 'scenarios.options.delete',
  props: {
    scenario: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions('scenarios', ['deleteScenario']),
    async _deleteScenario() {
      this.dialog = false;
      await this.deleteScenario(this.scenario);
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.title {
  position: relative;
}

.delete-button {
  .v-icon {
    color: $assortment-primary-colour;
  }
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
