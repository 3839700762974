var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: `relativeshare-${_vm.isSidebarShown}`,
      staticClass: "h-100 d-flex flex-column",
    },
    [
      _c("reporting-section", {
        attrs: { "short-view": _vm.shortView },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("reporting-header", {
                  attrs: { "short-view": _vm.shortView, section: _vm.section },
                  scopedSlots: _vm._u([
                    {
                      key: "metric",
                      fn: function () {
                        return [
                          _c("rtls-select", {
                            staticClass: "mr-3",
                            attrs: {
                              value: _vm.metricValue,
                              items: _vm.enabledMetrics,
                              placeholder: _vm.$t("general.select"),
                              disabled: _vm.isRelativeShareReportDisabled,
                              "item-text": "text",
                              "item-value": "type",
                              white: "",
                              width: "240px",
                            },
                            on: {
                              input: (value) =>
                                _vm.onSelectionChanged("metricValue", value),
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "attribute",
                      fn: function () {
                        return [
                          _c("rtls-select", {
                            attrs: {
                              value: _vm.attributeValue,
                              items: _vm.getAttributesIntersection(
                                _vm.baselineIds,
                                _vm.comparisonIds,
                                _vm.comparison2Ids
                              ),
                              placeholder: _vm.$t("general.select"),
                              disabled: _vm.isRelativeShareReportDisabled,
                              "item-text": "name",
                              "item-value": "id",
                              white: "",
                              width: "240px",
                            },
                            on: {
                              input: (value) =>
                                _vm.onSelectionChanged("attributeValue", value),
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "filter",
                      fn: function () {
                        return [
                          !_vm.shortView
                            ? _c("filters", {
                                staticClass: "ml-3",
                                attrs: {
                                  filters: _vm.filters[_vm.section],
                                  "filter-options": _vm.filterOptions,
                                  "btn-text": _vm.filterButtonText,
                                  disabled:
                                    _vm.isRelativeShareReportDisabled ||
                                    _vm.filtersDisabled,
                                },
                                on: {
                                  change: _vm.handleFilterChange,
                                  apply: _vm.fetchData,
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "main-section",
            fn: function () {
              return [
                _c("reporting-main-section", {
                  attrs: { section: _vm.section, "short-view": _vm.shortView },
                  scopedSlots: _vm._u([
                    {
                      key: "data-section",
                      fn: function () {
                        return [
                          !_vm.hasAvailableChartData &&
                          _vm.isChartLoading &&
                          _vm.baselineIds.length
                            ? _c("progress-bar", {
                                staticClass: "pt-5",
                                staticStyle: { margin: "auto" },
                                attrs: { message: _vm.loadingMessage },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isRelativeShareReportDisabled
                            ? _c("div", { staticClass: "ma-auto no-data" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("reportingPage.noPermissions")
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.baselineIds.length
                            ? _c("div", { staticClass: "ma-auto no-data" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$tkey("messages.noData")) +
                                    "\n          "
                                ),
                              ])
                            : _vm.isAnySelectionObserved
                            ? _c("div", { staticClass: "ma-auto no-data" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "reportingPage.notAvailableForComparisons"
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _vm.noFilteredDataAvailable
                            ? _c("div", { staticClass: "ma-auto no-data" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$tkey(
                                        "messages.noFilteredDataAvailable"
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _vm.noDataAvailable
                            ? _c("div", { staticClass: "ma-auto no-data" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$tkey("messages.noDataAvailable")
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isRelativeShareReportDisabled &&
                          _vm.hasAvailableChartData &&
                          !_vm.isAnySelectionObserved
                            ? _c("highcharts", {
                                key: _vm.highchartKey,
                                staticClass: "chart",
                                attrs: { options: _vm.chartOptions },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _vm.showNotImplemented
                  ? _c("reporting-footer", {
                      attrs: {
                        "short-view": _vm.shortView,
                        "is-export-disabled": "",
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }