var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      key: `stats-${_vm.isSidebarShown}`,
      staticClass: "h-100 d-flex flex-column",
    },
    [
      _c("reporting-section", {
        attrs: { "short-view": _vm.shortView },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _c("reporting-header", {
                  attrs: { "short-view": _vm.shortView, section: _vm.section },
                  scopedSlots: _vm._u([
                    {
                      key: "filter",
                      fn: function () {
                        return [
                          !_vm.shortView
                            ? _c("filters", {
                                staticClass: "ml-3",
                                attrs: {
                                  filters:
                                    _vm.filters[_vm.reportingSections.stats],
                                  "filter-options": _vm.filterOptions,
                                  "btn-text": _vm.filterButtonText,
                                  disabled:
                                    _vm.isStatsReportDisabled ||
                                    _vm.filtersDisabled,
                                },
                                on: {
                                  change: _vm.handleFilterChange,
                                  apply: _vm.applyFilters,
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "main-section",
            fn: function () {
              return [
                _c("reporting-main-section", {
                  attrs: {
                    section: _vm.section,
                    "short-view": _vm.shortView,
                    subtitle: _vm.$tkey("subtitle"),
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "data-section",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "ag-grid-box d-flex w-100 mr-5 h-100",
                              staticStyle: { height: "initial" },
                            },
                            [
                              _vm.isStatsReportDisabled
                                ? _c(
                                    "div",
                                    { staticClass: "ma-auto no-data" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.$t(
                                              "reportingPage.noPermissions"
                                            )
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm.isTableLoading
                                ? _c("progress-bar", {
                                    staticClass: "pt-5",
                                    staticStyle: { margin: "auto" },
                                    attrs: {
                                      message: _vm.$t(
                                        "reportingPage.loadingTable"
                                      ),
                                    },
                                  })
                                : _c("ag-grid-vue", {
                                    staticClass: "ag-theme-custom w-100",
                                    attrs: {
                                      "tree-data": "",
                                      "animate-rows": "",
                                      dense: "",
                                      "column-defs": _vm.headers,
                                      "row-data": _vm.tableData,
                                      "grid-options": _vm.gridOptions,
                                    },
                                    on: { "grid-ready": _vm.onGridReady },
                                  }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.shortView
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "ag-grid-box d-flex w-100 mr-5 h-100",
                                  staticStyle: { height: "initial" },
                                },
                                [
                                  _vm.isChartLoading
                                    ? _c("progress-bar", {
                                        staticClass: "pt-5",
                                        style: { margin: "auto" },
                                        attrs: {
                                          message: _vm.$t(
                                            "reportingPage.loadingChart"
                                          ),
                                        },
                                      })
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "header d-flex" },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$tkey("absoluteChange")
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("rtls-select", {
                                                staticStyle: {
                                                  "margin-left": "150px",
                                                  "margin-right": "10px",
                                                },
                                                attrs: {
                                                  value: _vm.metricValue,
                                                  items: _vm.enabledMetrics,
                                                  placeholder:
                                                    _vm.$t("general.select"),
                                                  disabled:
                                                    _vm.isStatsReportDisabled,
                                                  "item-text": "text",
                                                  "item-value": "type",
                                                },
                                                on: {
                                                  input: (value) =>
                                                    _vm.onSelectionChanged(
                                                      "metricValue",
                                                      value
                                                    ),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("rtls-select", {
                                                staticClass: "mr-3",
                                                attrs: {
                                                  value: _vm.attributeValue,
                                                  items:
                                                    _vm.getAttributesIntersection(
                                                      _vm.baselineIds,
                                                      _vm.comparisonIds
                                                    ),
                                                  placeholder:
                                                    _vm.$t("general.select"),
                                                  disabled:
                                                    _vm.isStatsReportDisabled,
                                                  "item-text": "name",
                                                  "item-value": "id",
                                                },
                                                on: {
                                                  input: (value) =>
                                                    _vm.onSelectionChanged(
                                                      "attributeValue",
                                                      value
                                                    ),
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          !_vm.isStatsReportDisabled &&
                                          _vm.hasAvailableChartData
                                            ? _c("highcharts", {
                                                key: _vm.highchartKey,
                                                attrs: {
                                                  options: _vm.chartOptions,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("reporting-footer", {
                  attrs: {
                    "short-view": _vm.shortView,
                    "is-export-disabled": !_vm.tableData.length,
                  },
                  on: { export: _vm.exportCSV },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }