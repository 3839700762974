<template>
  <v-row class="actions-row justify-space-between flex-grow-1">
    <div>
      <div class="btn-container">
        <slot name="left-btns" />
      </div>
    </div>
    <div class="d-flex">
      <div v-if="showExport" class="btn-container btn-container--action" :class="displayDiscard">
        <template v-if="exportOptions.length">
          <main-dropdown-menu
            :actions="exportOptions"
            top
            title="Export"
            icon="import_export"
            v-on="$listeners"
          />
        </template>
        <template v-else-if="isCustomExport">
          <v-btn primary depressed :disabled="!isExportEnabled" @click="$emit('export')">
            {{ exportButtonTitle !== '' ? exportButtonTitle : $t('actions.export') }}
            <v-icon right>import_export</v-icon>
          </v-btn>
        </template>
        <template v-else>
          <live-data-export
            :data="liveData"
            :service-name="exportService"
            :include-scenario-in-export-name="includeScenarioInExportName"
          />
        </template>
      </div>
      <div class="action-btn-container d-flex">
        <slot name="right-btns" />
        <div v-if="showDiscard" class="outlined-btn-container">
          <v-btn
            depressed
            :disabled="!isDiscardEnabled || !hasDataChanges"
            secondary
            class="outlined-btn"
            @click="$emit('discard')"
          >
            {{ $t('actions.discardChanges') }}
          </v-btn>
        </div>
        <div v-if="showProceedButton" class="save-btn-container">
          <v-btn
            data-id-e2e="btnProceedSpace"
            depressed
            :disabled="!hasDataChanges || hasDataErrors"
            action
            :loading="proceedLoading"
            @click="proceed"
          >
            {{ $t(`actions.${proceedBtnText}`) }}
          </v-btn>
        </div>
        <div v-if="showSaveButton" class="save-btn-container">
          <v-btn
            data-id-e2e="btnSaveSpace"
            depressed
            :disabled="!hasDataChanges || hasDataErrors || saveDisabled"
            action
            :loading="saveLoading"
            @click="onSaveClickDebounced"
          >
            {{ $t(`actions.${saveBtnText}`) }}
          </v-btn>
        </div>
      </div>
      <slot name="right-end-btns" />
    </div>
  </v-row>
</template>

<script>
import { debounce } from 'lodash';

export default {
  props: {
    hasDataChanges: {
      type: Boolean,
      required: true,
    },
    hasDataErrors: {
      type: Boolean,
      required: true,
    },
    saveDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showExport: {
      type: Boolean,
      required: false,
    },
    showDiscard: {
      type: Boolean,
      required: false,
      default: true,
    },
    saveLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    saveBtnText: {
      type: String,
      required: false,
      default: 'save',
    },
    proceedLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    proceedBtnText: {
      type: String,
      required: false,
      default: 'proceed',
    },
    liveData: {
      type: Array,
      required: false,
      default: () => [],
    },
    exportService: {
      type: String,
      required: false,
      default: () => '',
    },
    showSaveButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showProceedButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    exportOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    isCustomExport: {
      type: Boolean,
      required: false,
      default: false,
    },
    isExportEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    exportButtonTitle: {
      type: String,
      required: false,
      default: () => '',
    },
    isDiscardEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    includeScenarioInExportName: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showConfirmDialog: false,
      onSaveClickDebounced: debounce(() => this.save(), 200, { maxWait: 500 }),
    };
  },
  computed: {
    displayDiscard() {
      return !this.showDiscard ? 'hide-discard' : '';
    },
  },
  methods: {
    save() {
      this.$emit('save');
      this.closeConfirmDialog();
    },
    proceed() {
      this.$emit('proceed');
      this.closeConfirmDialog();
    },
    closeConfirmDialog() {
      this.showConfirmDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.actions-row {
  flex: 0;
  padding: 5px 0;
  .v-btn {
    margin: 2px 6px;
  }
  .btn-container {
    padding: 0px 14px;

    &.hide-discard {
      padding-left: 14px;
      padding-right: 8px;
    }

    &--action {
      border-right: 1px solid $assortment-horizontal-border-colour;
    }
  }
  .action-btn-container {
    margin-right: 8px;
  }

  .save-btn-container {
    padding-left: 8px;
  }

  .outlined-btn-container {
    display: inline-block;
    padding-left: 14px;

    &:not(:first-child) {
      padding-left: 8px;
    }
  }

  .v-btn > .v-btn__content .v-icon {
    color: white !important;
  }
}
</style>
