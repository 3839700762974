import { VContainer } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "pa-0 ma-0 store-allocation-container" },
    [
      _c(
        "div",
        { staticClass: "ag-grid-box flex-grow-1 pa-2 ma-0" },
        [
          _c("div", { staticClass: "action-panel" }, [
            _c(
              "div",
              { staticClass: "action-panel-container" },
              [
                _c("data-upload", {
                  attrs: {
                    legends: _vm.csvUploadLegends,
                    "csv-upload-handler": _vm.onCSVUpload,
                    "show-modal": "",
                    disabled: _vm.isEditingDisabled,
                  },
                  on: { process: _vm.process },
                }),
                _vm._v(" "),
                _c("filters", {
                  staticClass: "ml-3",
                  attrs: {
                    filters: _vm.filters,
                    "filter-options": _vm.filterOptions,
                    "btn-text": _vm.filterButtonText,
                  },
                  on: { change: _vm.handleFilterChange, apply: _vm.filterRows },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("ag-grid-vue", {
            staticClass: "ag-theme-custom",
            staticStyle: { width: "100%", height: "90%" },
            attrs: {
              "row-data": _vm.filteredRowData,
              "column-defs": _vm.columnDefs,
              "grid-options": _vm.gridOptions,
            },
            on: {
              "cell-value-changed": _vm.trackDiff,
              "grid-ready": _vm.onGridReady,
            },
          }),
          _vm._v(" "),
          _vm.loading
            ? _c(
                VOverlay,
                { attrs: { absolute: "", opacity: "0.5", color: "white" } },
                [
                  _c(VProgressCircular, {
                    attrs: {
                      indeterminate: "",
                      color: "primary",
                      size: "64",
                      width: "10",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("page-actions", {
        attrs: {
          "show-export": "",
          "is-custom-export": "",
          "has-data-changes": _vm.hasDataChanges,
          "has-data-errors": _vm.hasDataErrors,
          "is-discard-enabled": !_vm.isEditingDisabled,
          "save-disabled": _vm.isEditingDisabled,
        },
        on: {
          export: function ($event) {
            return _vm.exportCSV()
          },
          discard: _vm.discardChanges,
          save: _vm.saveChanges,
        },
      }),
      _vm._v(" "),
      _c("dependency-tree-feedback-modal", {
        attrs: {
          value: _vm.dependencyTreeModalOpen,
          results: _vm.dependencyTreeFeedback,
          page: "storeAllocation",
        },
        on: {
          close: _vm.closeDependencyTreeModal,
          commit: function ($event) {
            return _vm.saveChanges(true)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }