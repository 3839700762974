import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "ma-0 pa-0 w-100", attrs: { fluid: "" } },
    [
      _c(
        VRow,
        { staticClass: "mb-3" },
        [
          _c(
            VCol,
            {
              staticClass: "legend-filter-highlight__section ma-0",
              attrs: { cols: "4" },
            },
            [
              _c(
                "h3",
                { staticClass: "legend-filter-highlight__heading mb-2" },
                [_vm._v(_vm._s(_vm.$tkey("filtersHighlights")))]
              ),
              _vm._v(" "),
              _vm.filters.length
                ? _c(
                    VRow,
                    { staticClass: "legend-filter-highlight__filters" },
                    [
                      _c(
                        "h4",
                        { staticClass: "legend-filter-highlight__label" },
                        [_vm._v(_vm._s(_vm.$tkey("filters")) + ":")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-grow-1" },
                        _vm._l(_vm.filters, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "mr-2 d-inline" },
                            [
                              _c("span", { staticClass: "filter-name mr-1" }, [
                                _vm._v(_vm._s(item.filterName) + ":"),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.filterValues) +
                                    "\n              " +
                                    _vm._s(
                                      item.notEqualTo
                                        ? ` (${_vm.$tkey("notEqualTo")})`
                                        : ""
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.highlights.length
                ? _c(
                    VRow,
                    { staticClass: "legend-filter-highlight__filters" },
                    [
                      _c(
                        "h4",
                        { staticClass: "legend-filter-highlight__label" },
                        [_vm._v(_vm._s(_vm.$tkey("highlights")) + ":")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "flex-grow-1" },
                        _vm._l(_vm.highlights, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "mr-2 d-inline" },
                            [
                              _c("span", { staticClass: "filter-name mr-1" }, [
                                _vm._v(_vm._s(item.filterName) + ":"),
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.filterValues) +
                                    "\n              " +
                                    _vm._s(
                                      item.notEqualTo
                                        ? ` (${_vm.$tkey("notEqualTo")})`
                                        : ""
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass: "ma-0 legend-filter-highlight__section",
              attrs: { cols: "4" },
            },
            [
              _c("h3", { staticClass: "legend-filter-highlight__heading" }, [
                _vm._v(_vm._s(_vm.$tkey("colors"))),
              ]),
              _vm._v(" "),
              _c(
                VRow,
                [
                  _c(VCol, { staticClass: "pl-0", attrs: { cols: "4" } }, [
                    _c("div", { staticClass: "d-flex mb-1" }, [
                      _c("i", {
                        staticClass: "color-icon demoted pull-left mt-1",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.$tkey("demotedByUser"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("i", {
                        staticClass: "color-icon promoted pull-left mt-1",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.$tkey("promotedByUser"))),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { staticClass: "pl-0", attrs: { cols: "4" } }, [
                    _c("div", { staticClass: "d-flex mb-1" }, [
                      _c("i", {
                        staticClass: "color-icon demoted-by-opt pull-left mt-1",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.$tkey("demotedByOptimizer"))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex" }, [
                      _c("i", {
                        staticClass:
                          "color-icon promoted-by-opt pull-left mt-1",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.$tkey("promotedByOptimizer"))),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { staticClass: "pl-0", attrs: { cols: "4" } }, [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("i", {
                        staticClass: "color-icon highlight pull-left mt-1",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "ml-2" }, [
                        _vm._v(_vm._s(_vm.$tkey("highlightedBasedOnSettings"))),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass: "ma-0 legend-filter-highlight__section",
              attrs: { cols: "4" },
            },
            [
              _c("h3", { staticClass: "legend-filter-highlight__heading" }, [
                _vm._v(_vm._s(_vm.$tkey("icons"))),
              ]),
              _vm._v(" "),
              _c(
                VRow,
                [
                  _c(VCol, { staticClass: "pl-0", attrs: { cols: "6" } }, [
                    _c(
                      "div",
                      { staticClass: "d-flex mb-1" },
                      [
                        _c(
                          VIcon,
                          {
                            staticClass: "pull-left lock-icon mr-2",
                            attrs: { color: "primary", small: "" },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s("mdi-lock-open-variant-outline") +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$tkey("unlocked")))]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c(
                          VIcon,
                          {
                            staticClass: "pull-left lock-icon mr-2",
                            attrs: { color: "primary", small: "" },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s("mdi-lock-outline") +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.$tkey("locked")))]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { staticClass: "pl-0", attrs: { cols: "6" } },
                    [
                      _c(
                        VIcon,
                        {
                          staticClass: "pull-left lock-icon mr-2",
                          attrs: { color: "primary", small: "" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s("mdi-speedometer") +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$tkey("minimumDistribution"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }