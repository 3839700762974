'use strict';

const { isNumber } = require('lodash');

/**
 * Applies trend to RoS
 * @param {*} ros The product's RoS in the cluster
 * @param {*} trendApplied - Optional
 * @returns 
 */
function calculateRoSWithTrend(ros, trendApplied) {
    // Do not calculate new ros if there is no old ros or trend is NaN or zero
    if (!isNumber(ros) || !isNumber(trendApplied) || trendApplied === 0) return null;

    // Apply trend to rate of sale value as old value + old value * trend / 100%
    return ros + (ros * trendApplied) / 100;

}
module.exports = { calculateRoSWithTrend };