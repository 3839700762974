<template>
  <div class="step-tab-scope d-flex flex-column">
    <v-tabs class="step-tabs attribute-editor-step-tab" :value="tabs.name" :hide-slider="true">
      <v-tab v-for="(tab, index) in tabs" :key="index" class="step-tab">
        {{ tab.name }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'customAttributesHeaderGroup',
  data() {
    return {};
  },
  computed: {
    tabs() {
      // temporary until we use more than one tab in AOV3-845
      return [{ name: this.params.displayName }];
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.attribute-editor-step-tab {
  .step-tab {
    min-width: 100px;
    font-size: 1.2rem;
    padding-left: 7px;
    padding-right: 7px;
    color: $assortment-font-colour !important;
    height: 30px;

    &:first-child {
      border-left: none;
    }
  }
}
</style>
