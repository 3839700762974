<template>
  <v-card :class="{ 'dialog-card': true, 'no-border': isPopup }">
    <slot name="header">
      <header v-if="showFullHeaders" :class="{ [headerClass]: true, 'no-border': isPopup }">
        <v-row>
          <v-col cols="10" class="pl-0 pt-3">
            <h3 class="dialog-card__title">
              {{ title }}
              <docs-link v-if="docPath" :link="`toolguide/${docPath}.html`" />
            </h3>
            <slot name="title" />
          </v-col>
          <v-col cols="2 text-right" class="pr-0 pt-3">
            <v-btn icon @click="$emit('close')">
              <v-icon class="dialog-card__icon" size="2rem">$cancel</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </header>
      <header v-else>
        <v-row class="pl-3 pt-3">
          <span class="info-note">{{ title }}</span>

          <!-- Attribute editor tooltip -->
          <assortment-tooltip
            :title="$t('tooltips.productScope.title')"
            :tooltip-sections="productScopeTooltipSections"
          />
        </v-row>
      </header>
    </slot>

    <section :class="{ 'dialog-card__content': true, [contentClass]: true }">
      <slot />
    </section>

    <footer :class="{ [footerClass]: true, 'no-border': isPopup }">
      <slot name="footer" />
    </footer>
  </v-card>
</template>

<script>
import { workpackageProductsTooltipOptionsMixin } from '@/js/mixins/tooltip-options';

export default {
  mixins: [workpackageProductsTooltipOptionsMixin],
  props: {
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    headerClass: {
      type: String,
      required: false,
      default: 'dialog-card__header',
    },
    footerClass: {
      type: String,
      required: false,
      default: 'dialog-card__footer',
    },
    contentClass: {
      type: String,
      required: false,
      default: '',
    },
    isPopup: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFullHeaders: {
      type: Boolean,
      default: true,
    },
    headerTitleElement: {
      type: String,
      required: false,
      default: 'h3',
    },
    docPath: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.no-max-height {
  max-height: none !important ;
}

.dialog-card {
  border-left: 8px solid $assortment-primary-colour;
  padding-left: 20px;
  padding-right: 20px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0px !important;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    min-height: 60px;
    border-bottom: 1px solid $assortment-divider-colour;

    .v-btn {
      .v-icon {
        color: $assortment-primary-colour;
      }
    }
  }

  &__title {
    font-family: 'Source Sans Pro';
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 600;
    letter-spacing: 0;
    color: $assortment-text-colour;
  }

  &__icon {
    color: $assortment-primary-colour;
    fill: currentColor;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 80vh; // is this needed? caused double scrollbar on main-alert.vue
    overflow: auto;
  }

  &__footer {
    display: flex;
    align-items: center;
    height: 55px;
    min-height: 55px;
    border-top: 1px solid $assortment-divider-colour;
  }
}

.no-border {
  border: none;
}
</style>
