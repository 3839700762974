<template>
  <div class="spacebreak-settings-container">
    <div class="spacebreak-settings d-flex">
      <v-col class="main-pane-column pt-4 pl-6 pb-4 pr-6 position-relative">
        <div v-if="!showCopyConstraintsModal">
          <spacebreak-stats
            v-if="spacebreak"
            :spacebreak="spacebreak"
            :store-class-id="storeClassId"
          />
          <spacebreak-constraints-table
            class="mt-4"
            :spacebreak="spacebreak"
            @switch-copy-constraints-modal="switchCopyConstraintsModal"
          />
        </div>
        <copy-constraints-modal
          v-else
          data-id-e2e="btnCopyConstraintsModel"
          :spacebreak="spacebreak"
          @switch-copy-constraints-modal="switchCopyConstraintsModal"
        />
      </v-col>
      <v-col class="close-pane-column d-flex justify-center">
        <v-btn
          class="close-icon-btn"
          color="primary"
          small
          dark
          depressed
          @click="toggleSpaceBreakSettingsPanel(spacebreak)"
        >
          <v-icon size="24">mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  props: {
    spacebreak: {
      type: Object,
      default: null,
      required: false,
    },
    storeClassId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showCopyConstraintsModal: false,
    };
  },

  computed: {
    ...mapState('assortmentCanvas', ['spacebreaks']),
  },

  methods: {
    ...mapMutations('assortmentCanvas', [
      'toggleSpaceBreakSettingsPanel',
      'resetConstraintsCopyTarget',
    ]),

    switchCopyConstraintsModal(shouldDisplay) {
      this.resetConstraintsCopyTarget();
      this.showCopyConstraintsModal = shouldDisplay;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.spacebreak-settings-container {
  pointer-events: none;
  width: 70vw;
  // that's the width the spacebreaks take on the left of the canvas page
  left: 229px;
  top: 140px;
  bottom: $assortment-footer-height;
  position: fixed;
  z-index: 7;
  background: transparent;
}

.spacebreak-settings {
  box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.3);
  pointer-events: auto;
  background: white;
  height: 100%;
  border: 1px solid $assortment-divider-colour;
}

.close-icon-btn {
  min-width: 0 !important;
  height: 20px !important;
  width: 5px;
}

.close-pane-column {
  border-left: 1px solid $assortment-divider-colour;
  flex: 0 0 5%;
  max-width: 5%;
}

.main-pane-column {
  // TO DO - fix overflow for constraints table
  overflow: auto;
}
</style>
