import { get, sortBy, values, uniq } from 'lodash';
import { v1 as uuidv1 } from 'uuid';

function buildTableContents(furnitureMap, storeMap, activeTable) {
  const table = activeTable.map(f => {
    const store = storeMap[f.storeKey];

    const overrideSize = get(furnitureMap, `${f.overrideFurnitureId}.size`, null);
    const overrideName = get(furnitureMap, `${f.overrideFurnitureId}.name`, null);

    const historicSize = get(furnitureMap, `${f.furnitureId}.size`, null);
    const historicName = get(furnitureMap, `${f.furnitureId}.name`, null);
    const historicCategory1Key = get(
      furnitureMap,
      `${f.furnitureId}.furnitureCategory1Key`,
      f.furnitureCategory1Key
    );

    return {
      id: f.id,
      index: f.index,
      storeKey: f.storeKey,
      storeKeyDisplay: store.storeKeyDisplay,
      storeName: store.name || store.storeDescription,
      format: store.formatDescription,
      // historic
      furnitureId: f.furnitureId,
      furnitureName: historicName,
      furnitureSize: historicSize,
      furnitureCategory1Key: historicCategory1Key,
      // future
      overrideName,
      overrideSize,
      overrideFurnitureId: f.overrideFurnitureId,
    };
  });

  // handle case where store is in workpackage but not scenario-furniture mappings
  const storesInTable = uniq(table.map(v => v.storeKey));
  values(storeMap).forEach(store => {
    if (!storesInTable.includes(store.storeKey) && store.assortment) {
      table.push({
        id: uuidv1(),
        storeKey: store.storeKey,
        storeKeyDisplay: store.storeKeyDisplay,
        storeName: store.name || store.storeDescription,
        format: store.formatDescription,
      });
    }
  });

  return sortBy(table, ['storeKey', 'furnitureSize']);
}
export default {
  buildTableContents,
};
