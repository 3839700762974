<template>
  <!-- eslint-disable vue/no-v-html -->
  <!-- Disabling v-html rule to add formatted translation strings for tooltips -->
  <!-- They are static strings, so should be safe -->

  <!--
  This component is used to add info icon with tooltip modal to it.
  If using it without additional content inside, the default layout will be used.
  Also, any custom layout can be passed.
  Tooltip sections configs are being described in '@/js/mixins/tooltip-options.js'
  -->

  <v-dialog
    v-model="isPopupOpen"
    content-class="rtls-dialog rtls-dialog--legend"
    max-width="1120"
    max-height="740"
    :style="[zIndex ? { 'z-index': zIndex } : '']"
    :width="width"
  >
    <template v-slot:activator="{ on }">
      <v-icon size="20" :class="iconClass" v-on="on">$information</v-icon>
    </template>
    <dialog-card :title="title" class="rtls-dialog--legend" @close="closePopup">
      <slot>
        <!-- This is the default layout for the contents -->
        <v-card>
          <!-- Iterating over each section -->
          <div
            v-for="(section, sectionIdx) in tooltipSections"
            :key="`section-${sectionIdx}`"
            class="tooltip-section tooltip-content-text pt-5 pb-5 d-flex flex-column"
          >
            <!-- If section has 'icons' type - display its tips without bullet points -->
            <div v-if="section.type === 'icons'" class="d-flex flex-column">
              <div
                v-for="(tip, labelIdx) in section.tips"
                :key="`label-tip-${labelIdx}`"
                class="tip-icon-item-wrapper d-flex align-center mr-4 mb-2"
              >
                <v-icon class="mr-2">{{ tip.icon }}</v-icon>
                <div v-html="tip.text" />
              </div>
            </div>
            <!-- Otherwise, display bullet list of tips -->
            <ul v-else>
              <li
                v-for="(tip, tipIdx) in section.tips"
                :key="`tip-${tipIdx}`"
                class="tip-list-item pt-2"
              >
                <!-- Show nested list in case tip has nested tips -->
                <div v-if="tip.nestedTips">
                  <div v-html="tip.text" />
                  <!-- If subsection has 'icons' type - display its tips without bullet points -->
                  <div v-if="tip.type === 'icons'" class="pt-2">
                    <div
                      v-for="(nestedTip, nestedTipIdx) in tip.nestedTips"
                      :key="`tip-${nestedTipIdx}`"
                      class="tip-icon-item-wrapper d-flex align-center mr-4 mb-2"
                    >
                      <v-icon class="mr-2">{{ nestedTip.icon }}</v-icon>
                      <div v-html="nestedTip.text" />
                    </div>
                  </div>
                  <!-- Otherwise, display bullet nested list -->
                  <ul v-else class="ml-4">
                    <li
                      v-for="(nestedTip, nestedTipIdx) in tip.nestedTips"
                      :key="`tip-${nestedTipIdx}`"
                      class="tip-list-item nested pt-2"
                    >
                      <div v-html="nestedTip" />
                    </li>
                  </ul>
                </div>

                <!-- In case the list of strings is passed, just display them as simple bullet points -->
                <div v-else v-html="tip" />
              </li>
            </ul>
          </div>
        </v-card>
      </slot>
    </dialog-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AssortmentTooltip',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    tooltipSections: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    zIndex: {
      type: Number,
      required: false,
      default: () => null,
    },
    error: {
      type: Boolean,
      required: false,
      default: false,
    },
    width: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isPopupOpen: false,
    };
  },
  computed: {
    iconClass() {
      if (this.error) return 'info-icon-error';
      return 'info-icon';
    },
  },

  methods: {
    closePopup() {
      this.isPopupOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.tip-list-item:first-child:not(.nested) {
  padding-top: 0 !important;
}

.tip-icon-item-wrapper:last-child {
  margin-bottom: 0 !important;
}

.info-icon-error {
  fill: $assortment-span-error-colour;
}
</style>
