'use strict';

// Enums for types of messages used for communication via the server and front end client.
// e.g. A status update from the server will have a different type to a job update

const assortmentMessageTypes = {
  jobUpdate: 'jobUpdate',
  statusUpdate: 'statusUpdate',
};

module.exports = {
  assortmentMessageTypes,
};
