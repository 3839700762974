import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "spacebreak-settings-container" }, [
    _c(
      "div",
      { staticClass: "spacebreak-settings d-flex" },
      [
        _c(
          VCol,
          {
            staticClass:
              "main-pane-column pt-4 pl-6 pb-4 pr-6 position-relative",
          },
          [
            !_vm.showCopyConstraintsModal
              ? _c(
                  "div",
                  [
                    _vm.spacebreak
                      ? _c("spacebreak-stats", {
                          attrs: {
                            spacebreak: _vm.spacebreak,
                            "store-class-id": _vm.storeClassId,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("spacebreak-constraints-table", {
                      staticClass: "mt-4",
                      attrs: { spacebreak: _vm.spacebreak },
                      on: {
                        "switch-copy-constraints-modal":
                          _vm.switchCopyConstraintsModal,
                      },
                    }),
                  ],
                  1
                )
              : _c("copy-constraints-modal", {
                  attrs: {
                    "data-id-e2e": "btnCopyConstraintsModel",
                    spacebreak: _vm.spacebreak,
                  },
                  on: {
                    "switch-copy-constraints-modal":
                      _vm.switchCopyConstraintsModal,
                  },
                }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          VCol,
          { staticClass: "close-pane-column d-flex justify-center" },
          [
            _c(
              VBtn,
              {
                staticClass: "close-icon-btn",
                attrs: { color: "primary", small: "", dark: "", depressed: "" },
                on: {
                  click: function ($event) {
                    return _vm.toggleSpaceBreakSettingsPanel(_vm.spacebreak)
                  },
                },
              },
              [
                _c(VIcon, { attrs: { size: "24" } }, [
                  _vm._v("mdi-chevron-left"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }