<template>
  <v-col id="warning-controls" class="actions-col align-self-center">
    <span class="warning-rows-error-box pr-2">{{ invalidRowsMessage }}</span>
    <v-btn v-if="!isShowingInvalidRows" outlined depressed color="warning" @click="onClick">
      {{ $t('actions.showInvalidRows') }}
    </v-btn>
    <v-btn v-else outlined depressed color="primary" @click="onClick">
      {{ $t('actions.showAllRows') }}
    </v-btn>
  </v-col>
</template>

<script>
export default {
  name: 'WarningControls',

  props: {
    invalidRowsMessage: {
      required: true,
      type: String,
    },
    isShowingInvalidRows: {
      required: true,
      type: Boolean,
    },
  },

  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped></style>
