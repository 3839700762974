import { render, staticRenderFns } from "./workpackage-templates-modal.vue?vue&type=template&id=76b9a08d&scoped=true"
import script from "./workpackage-templates-modal.vue?vue&type=script&lang=js"
export * from "./workpackage-templates-modal.vue?vue&type=script&lang=js"
import style0 from "./workpackage-templates-modal.vue?vue&type=style&index=0&id=76b9a08d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76b9a08d",
  null
  
)

export default component.exports