import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("workpackages-toolbar", {
        attrs: {
          "doc-path": "020-workpackage",
          entity: _vm.entity,
          archived: "",
        },
        on: { "on-search": _vm.onSearch },
      }),
      _vm._v(" "),
      _vm.loadingArchivedWorkpackages && !_vm.archivedWorkpackagesTotalCount
        ? _c(
            VAlert,
            {
              attrs: {
                border: "left",
                "colored-border": "",
                color: "primary accent-4",
              },
            },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", color: "primary", size: "20" },
              }),
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.$t("workpackagePage.loadingArchivedWorkpackages")
                  ) +
                  "\n  "
              ),
            ],
            1
          )
        : _c(
            VCard,
            { staticClass: "col-box" },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    VCol,
                    {
                      staticClass: "workpackages-container",
                      attrs: { colspan: "12" },
                    },
                    [
                      _vm.archivedWorkpackagesTotalCount
                        ? _c("workpackages-list", {
                            attrs: {
                              entity: _vm.entity,
                              workpackages: _vm.archivedWorkpackages,
                            },
                          })
                        : _c(VAlert, { attrs: { type: "info", text: "" } }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t(
                                    `workpackages.noArchived${_vm.capitalize(
                                      _vm.entity
                                    )}s`
                                  )
                                ) +
                                "\n        "
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    {
                      staticClass: "horizontal-divider-container",
                      attrs: { colspan: "12" },
                    },
                    [_c("div", { staticClass: "horizontal-divider" })]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.archivedWorkpackagesTotalCount
                ? [
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(VPagination, {
                          attrs: {
                            length: _vm.totalPageCount,
                            "total-visible": _vm.totalVisiblePages,
                          },
                          model: {
                            value: _vm.currentPage,
                            callback: function ($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.loadingArchivedWorkpackages
                ? _c(
                    VOverlay,
                    { attrs: { absolute: "", opacity: "0.5", color: "white" } },
                    [
                      _c(VProgressCircular, {
                        attrs: {
                          indeterminate: "",
                          color: "primary",
                          size: "64",
                          width: "10",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }