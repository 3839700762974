<template>
  <v-progress-circular v-if="hasProgress" :value="progressPercent" size="25" width="3">
    {{ progressPercent }}
  </v-progress-circular>
  <v-tooltip v-else bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="action-button"
        icon
        text
        :loading="copyInProgress"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        @click="onCopyWorkpackageClick"
      >
        <v-icon style="font-size: 19px">mdi-content-copy</v-icon>
      </v-btn>
    </template>
    <span>{{ $t('actions.copy') }}</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { includes, get } from 'lodash';
import { jobInProgressStatuses } from '@enums/jobapi';

export default {
  props: {
    workpackage: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters('workpackages', ['jobStatuses', 'isCopyWorkpackageRunning']),
    disabled() {
      return (
        this.isCopyWorkpackageRunning ||
        !this.hasPermission(this.userPermissions.canCopyWorkpackage)
      );
    },
    copyWorkpackageJobStatus() {
      return get(this.workpackage, 'jobs.copyWorkpackage.status');
    },
    copyInProgress() {
      return includes(jobInProgressStatuses, this.copyWorkpackageJobStatus);
    },
    progressPercent() {
      return get(this.workpackage, 'jobs.copyWorkpackage.progress');
    },
    hasProgress() {
      return this.copyInProgress && this.progressPercent > 0 && this.progressPercent < 100;
    },
  },
  methods: {
    ...mapActions('workpackages', ['copyWorkpackage', 'setSelectedWorkpackage']),
    ...mapActions('snackbar', ['showSuccess', 'showError']),
    async onCopyWorkpackageClick() {
      this.setSelectedWorkpackage(this.workpackage);
      this.showSuccess(
        this.$t('notifications.copyWorkpackage.starting', { name: this.workpackage.name })
      );
      await this.copyWorkpackage(this.workpackage);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.action-button {
  .v-icon {
    color: $assortment-primary-colour;
  }
}
</style>
