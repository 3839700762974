import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: {
        value: _vm.value,
        width: "500px",
        "content-class": "copy-decisions-modal",
        "data-id-e2e": "copyDecisionsModal",
      },
      on: { "click:outside": _vm.closeModal },
    },
    [
      _c(
        "dialog-card",
        {
          attrs: { title: _vm.$tkey("modalTitle") },
          on: { close: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c("page-actions", {
                    attrs: {
                      "has-data-errors": _vm.hasDataErrors,
                      "has-data-changes": true,
                      "show-discard": false,
                      "save-loading": _vm.isProcessing,
                      "save-btn-text": "copyDecisions",
                    },
                    on: { save: _vm.copyDecisions },
                    scopedSlots: _vm._u([
                      {
                        key: "right-btns",
                        fn: function () {
                          return [
                            _c(
                              VBtn,
                              {
                                staticClass: "text-outline",
                                attrs: { text: "", link: "" },
                                on: { click: _vm.closeModal },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("actions.cancel")) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VContainer,
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c(
                VRow,
                { staticClass: "d-flex flex-column main-container" },
                [
                  _c(
                    VCol,
                    { staticClass: "pl-0 pb-0 pt-0 pr-4 content" },
                    [
                      _c(
                        VRow,
                        [
                          _c("search-scenario", {
                            attrs: {
                              block: "",
                              "data-id-e2e":
                                "copyDecisionsSearchScenarioSection",
                              rules: [],
                              "active-scenario-id": _vm.selectedScenario._id,
                              "scenarios-search-query":
                                _vm.scenariosSearchQuery,
                            },
                            on: {
                              "scenario-id-selected":
                                _vm.retrieveMatchingProductsInfo,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isLoading
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-center align-center pt-1",
                            },
                            [
                              _c(VProgressCircular, {
                                attrs: { indeterminate: "", color: "primary" },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _vm.matchingProductsInfo.matchingProductCount
                                ? _c(VRow, { staticClass: "pt-3" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tkey("matchingProducts", [
                                          _vm.matchingProductsInfo
                                            .matchingProductCount,
                                        ])
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.matchingProductsInfo.nonMatchingProductCount
                                ? _c(VRow, { staticClass: "pt-1" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tkey("nonMatchingProducts", [
                                          _vm.matchingProductsInfo
                                            .nonMatchingProductCount,
                                        ])
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                      _vm._v(" "),
                      _c(VRow, { staticClass: "pt-3" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$tkey("decisionsToCopy")) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        VRow,
                        { staticClass: "pt-1" },
                        [
                          _c(VCheckbox, {
                            staticClass: "rtls-checkbox ml-2 pl-2 mx-2",
                            attrs: {
                              id: "select-sister-products-decisions",
                              "input-value":
                                _vm.selectedDecisions.sisterProducts,
                              "hide-details": "",
                              disabled: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              attrs: {
                                for: "select-sister-products-decisions",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t(
                                      "productModellingPage.sisterProducts"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VRow,
                        [
                          _c(VCheckbox, {
                            staticClass: "rtls-checkbox ml-2 pl-2 mx-2",
                            attrs: {
                              id: "select-trend-applied-decisions",
                              "input-value": _vm.selectedDecisions.trendApplied,
                              "hide-details": "",
                            },
                            on: {
                              change: function ($event) {
                                _vm.selectedDecisions.trendApplied = $event
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              attrs: { for: "select-trend-applied-decisions" },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("productModellingPage.trendApplied")
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VRow,
                        [
                          _c(VCheckbox, {
                            staticClass: "rtls-checkbox ml-2 pl-2 mx-2",
                            attrs: {
                              id: "select-ros-override-decisions",
                              "input-value":
                                _vm.selectedDecisions.rateOfSaleOverride,
                              "hide-details": "",
                            },
                            on: {
                              change: function ($event) {
                                _vm.selectedDecisions.rateOfSaleOverride =
                                  $event
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "select-ros-override-decisions" } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("productModellingPage.salesOverride")
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VContainer,
                        { staticClass: "pt-3 pa-2 ma-0" },
                        [
                          _c(
                            VAlert,
                            { staticClass: "mb-1", attrs: { text: "" } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$tkey("info")) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }