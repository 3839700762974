import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "previous-extract" },
    [
      _c("header", { staticClass: "header" }, [
        _c("h2", { staticClass: "header-title pt-0" }, [
          _vm._v(_vm._s(_vm.$t("extract.headers.previousExtract.title"))),
        ]),
      ]),
      _vm._v(" "),
      _c(
        VRow,
        { staticClass: "content-headers" },
        [
          _c(VCol, { staticClass: "header-item", attrs: { cols: "3" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("extract.headers.previousExtract.name")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(VCol, { staticClass: "header-item", attrs: { cols: "3" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("extract.headers.previousExtract.date")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(VCol, { staticClass: "header-item", attrs: { cols: "3" } }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("extract.headers.previousExtract.reportType")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            VCol,
            { staticClass: "header-item align-center", attrs: { cols: "2" } },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("extract.headers.previousExtract.status")) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(VCol, { staticClass: "header-item", attrs: { cols: "1" } }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.reports, function (report, index) {
        return _c("previous-extract-reports-row", {
          key: index,
          attrs: { report: report },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }