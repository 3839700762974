<template>
  <v-row v-if="!fullScreenExpanded" class="filter-bar mb-3 flex-grow-0">
    <v-col :cols="hasHighlightProductEnabled ? 4 : 6" class="py-2 px-0">
      <div class="d-flex assortment-filter">
        <span class="assortment-text mx-2">{{ $tkey('assortment') }}:</span>
        <v-select
          :value="selectedCanvas"
          :items="canvases"
          :item-text="getAssortmentOptionText"
          item-value="_id"
          hide-details
          white
          class="rtls-select rtls-select--white"
          :menu-props="{ 'z-index': 200 }"
          @change="changeCanvas"
        />
      </div>
    </v-col>
    <v-col :cols="hasHighlightProductEnabled ? 8 : 6" class="d-flex py-2 px-0 justify-end">
      <div class="d-flex assortment-filter justify-end">
        <div v-if="hasCanvasFilterLegendEnabled">
          <v-icon class="mr-2" @click="setShowFilterLegend(!showFilterLegend)">
            {{ 'mdi-information-outline' }}
          </v-icon>
        </div>
        <span v-if="hasHighlightProductEnabled" key="" class="assortment-text mx-2"
          >{{ $tkey('highlight') }}:</span
        >
        <div v-if="hasHighlightProductEnabled">
          <scenario-assortments-filter mode="highlight" />
        </div>
        <span class="assortment-text mx-2">{{ $tkey('filter') }}:</span>
        <div class="mr-2">
          <scenario-assortments-filter mode="filter" />
        </div>
        <assortment-search
          id="canvasSearch"
          item-text="productKeyDisplay"
          :items="getFilteredProducts.products"
          :item-label="getRowLabel"
          :on-search="onSearch"
          :placeholder="$tkey('exampleSearch')"
          :on-selection="onSelection"
          clear-on-selection
        />
      </div>
      <div class="d-flex align-center mr-2">
        <scenario-assortments-controls :disabled="isEditingDisabled" />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { get, includes, toLower } from 'lodash';
import productUtils from '../../utils/product-utils';

export default {
  localizationKey: 'assortmentCanvasPage.toolbar',

  computed: {
    ...mapState('assortmentCanvas', ['fullScreenExpanded', 'showFilterLegend']),
    ...mapGetters('assortmentCanvas', ['selectedCanvas', 'canvases', 'getFilteredProducts']),
    ...mapGetters('context', ['getClientConfig']),

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAssortmentCanvas);
    },

    hasHighlightProductEnabled() {
      return get(this.getClientConfig, 'features.highlightProductEnabled', false);
    },

    hasCanvasFilterLegendEnabled() {
      return get(this.getClientConfig, 'features.canvasFilterLegendEnabled', false);
    },
  },

  watch: {
    '$route.params.scenarioId': function() {
      this.changeCanvasRenderingStatus(true);
      this.fetchCanvases();
      this.closeSpacebreakSettingsPanel();
    },
  },

  methods: {
    ...mapActions('assortmentCanvas', ['fetchCanvases', 'changeCanvasRenderingStatus']),
    ...mapMutations('assortmentCanvas', [
      'closeSpacebreakSettingsPanel',
      'setCanvasRenderingStatus',
      'setShowFilterLegend',
      'setIsProductSidebarOpen',
      'setDashboardProduct',
    ]),

    changeCanvas(canvasId) {
      this.setIsProductSidebarOpen(false);
      this.setDashboardProduct(null);
      if (canvasId !== this.$route.params.canvasId) {
        this.changeCanvasRenderingStatus(true);
      }
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params, canvasId },
      });
      this.closeSpacebreakSettingsPanel();
    },

    getAssortmentOptionText(option) {
      return (
        option.description ||
        `${option.clusterName || this.$tkey('noCluster')} - ${option.storeClassName || ''}`
      );
    },

    onSelection({ productKey }) {
      const productElement = productUtils.scrollToProductInCanvas({ productKey });
      // then add an active class and then remove it.
      productUtils.addActiveSearchBorderToProductElement(productElement);

      // find the popup component and click on it
      const popupElement = document.getElementById(`product-tile-popup-${productKey}`);
      const rect = popupElement.getBoundingClientRect();
      const event = new MouseEvent('click', {
        clientX: rect.left,
        clientY: rect.top,
      });
      popupElement.dispatchEvent(event);
    },

    getRowLabel(product) {
      return `${product.productKeyDisplay} (${product.itemDescription})`;
    },

    onSearch(product, searchValue) {
      const { productKey, productKeyDisplay, itemDescription } = product;

      return (
        includes(toLower(productKey), searchValue) ||
        includes(toLower(productKeyDisplay), searchValue) ||
        includes(toLower(itemDescription), searchValue)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.filter-bar {
  border-top: 1px solid $assortment-primary-colour;
  background-color: $assortment-filter-bar-bg-colour;

  .assortment-text {
    font-size: 1.4rem;
    font-weight: 600;
    color: $assortment-text-colour;
  }
  .assortment-filter {
    width: 100%;
    align-items: baseline;
  }
}
</style>
