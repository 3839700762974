import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "assortment-groups-tab pa-2" },
    [
      _c(
        VRow,
        { staticClass: "mb-3", attrs: { "no-gutters": "" } },
        [
          _c(
            VCol,
            { staticClass: "available-ags pr-3 pb-2", attrs: { cols: 8 } },
            [
              _c("h3", { staticClass: "pt-1 pb-3" }, [
                _vm._v(_vm._s(_vm.$tkey("availableAGs"))),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "available-ags__wrapper" },
                [
                  _c(
                    VRow,
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        VCol,
                        {
                          staticClass: "available-ags__search",
                          attrs: { cols: 8 },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "search" },
                            [
                              _c("rtls-search", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "workpackagePage.scope.searchLabel"
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.gridOptions.api.onFilterChanged()
                                  },
                                },
                                model: {
                                  value: _vm.searchString,
                                  callback: function ($$v) {
                                    _vm.searchString = $$v
                                  },
                                  expression: "searchString",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.hasTemplateAvailable
                        ? _c(
                            VCol,
                            { staticClass: "available-ags__toggle" },
                            [
                              _c(
                                VBtnToggle,
                                {
                                  attrs: { mandatory: "" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.gridOptions.api.onFilterChanged()
                                    },
                                  },
                                  model: {
                                    value: _vm.isTemplateOnly,
                                    callback: function ($$v) {
                                      _vm.isTemplateOnly = $$v
                                    },
                                    expression: "isTemplateOnly",
                                  },
                                },
                                [
                                  _c(VBtn, { attrs: { value: false } }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$tkey("toggle.all")) +
                                        "\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(VBtn, { attrs: { value: true } }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$tkey("toggle.templated")) +
                                        "\n              "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "ag-grid-box flex-grow-1" },
                    [
                      _c("ag-grid-vue", {
                        staticClass:
                          "ag-theme-custom ag-theme-custom--attributes",
                        staticStyle: { width: "100%", height: "360px" },
                        attrs: {
                          "auto-params-refresh": "",
                          "data-id-e2e": "listAvailableAssortmentGroups",
                          "column-defs": _vm.columnDefs,
                          "row-data": _vm.assortmentGroups,
                          "does-external-filter-pass":
                            _vm.doesExternalFilterPass,
                          "grid-options": _vm.gridOptions,
                          "stop-editing-when-cells-loses-focus": true,
                          "enable-range-selection": true,
                        },
                        on: { "grid-ready": _vm.onGridReady },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            VCol,
            { staticClass: "pl-3 pb-2" },
            [
              _c(
                VRow,
                { attrs: { "no-gutters": "" } },
                [
                  _c(VCol, [
                    _c("h3", [_vm._v(_vm._s(_vm.$tkey("analysisOnly")))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { staticClass: "text-right" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "assortment-groups-tab__link",
                          attrs: {
                            text: "",
                            small: "",
                            disabled:
                              _vm.selectedAssortmentGroupsForAnalysisOnly
                                .length === 0,
                          },
                          on: { click: _vm.unselectAllAnalysis },
                        },
                        [
                          _c(VIcon, { attrs: { size: "16" } }, [
                            _vm._v("mdi-close"),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("actions.clearAll"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "grid-wrapper" }, [
                _c(
                  "div",
                  {
                    staticClass: "grid",
                    attrs: {
                      "data-id-e2e":
                        "listSelectedAssortmentGroupsForAnalysisOnly",
                    },
                  },
                  _vm._l(
                    _vm.selectedAssortmentGroupsForAnalysisOnly,
                    function (ag) {
                      return _c(
                        "div",
                        { key: ag.key, staticClass: "display-contents" },
                        [
                          _c("div", { staticClass: "item trimmed-text" }, [
                            _vm._v(_vm._s(ag.text)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item item__icon" },
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: { "x-small": "", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.unselectAnalysis([ag.key])
                                    },
                                  },
                                },
                                [
                                  _c(VIcon, { attrs: { size: "20" } }, [
                                    _vm._v("$trash"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                VRow,
                { attrs: { "no-gutters": "" } },
                [
                  _c(VCol, [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.$tkey("includeForAssortment"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    VCol,
                    { staticClass: "text-right" },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "assortment-groups-tab__link",
                          attrs: {
                            text: "",
                            small: "",
                            disabled:
                              _vm.selectedAssortmentGroupsForAssortment
                                .length === 0,
                          },
                          on: { click: _vm.unselectAllAssortment },
                        },
                        [
                          _c(VIcon, { attrs: { size: "16" } }, [
                            _vm._v("mdi-close"),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("actions.clearAll"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "grid-wrapper" }, [
                _c(
                  "div",
                  {
                    staticClass: "grid",
                    attrs: {
                      "data-id-e2e":
                        "listSelectedAssortmentGroupsForAssortment",
                    },
                  },
                  _vm._l(
                    _vm.selectedAssortmentGroupsForAssortment,
                    function (ag) {
                      return _c(
                        "div",
                        { key: ag.key, staticClass: "display-contents" },
                        [
                          _c("div", { staticClass: "item trimmed-text" }, [
                            _vm._v(_vm._s(ag.text)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "item item__icon" },
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: { "x-small": "", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.unselectAssortment([ag.key])
                                    },
                                  },
                                },
                                [
                                  _c(VIcon, { attrs: { size: "20" } }, [
                                    _vm._v("$trash"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                VRow,
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    VCol,
                    [
                      _c("h3", { staticClass: "mb-2" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$t(
                                "workpackagePage.scope.productsAndStoresLabel"
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "assortment-groups-tab__text mb-2" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(
                                  "workpackagePage.scope.importProductDataLabel"
                                )
                              ) +
                              ":\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.datesLoaded
                        ? _c("import-from-planogram", {
                            attrs: {
                              "custom-workpackage": _vm.customWorkpackage,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(VDivider),
      _vm._v(" "),
      _c(
        VRow,
        { staticClass: "mt-3", attrs: { "no-gutters": "" } },
        [
          _vm.isSplitWorkpackagesEnabled && !_vm.isTemplate
            ? _c(
                VCol,
                { staticClass: "split-column pr-2" },
                [_c("wp-scope-filters")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            VCol,
            {
              staticClass: "products-column pr-2",
              class: { "pl-2": _vm.isSplitWorkpackagesEnabled },
            },
            [_c("wp-scope-products-new")],
            1
          ),
          _vm._v(" "),
          _c(VCol, { staticClass: "pl-2" }, [_c("wp-scope-stores-new")], 1),
          _vm._v(" "),
          _c(VCol, [_vm._v(" ")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }