import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VBtn,
    {
      attrs: {
        "data-id-e2e": "btnExport",
        disabled: _vm.downloadDisabled,
        depressed: "",
        primary: "",
      },
      on: { click: _vm.downloadData },
    },
    [
      _vm._v("\n  " + _vm._s(_vm.$t("actions.export")) + "\n  "),
      _c(VIcon, { attrs: { right: "" } }, [_vm._v("import_export")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }