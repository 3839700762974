import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "date-input date-input-exclude" },
    [
      _c(
        VMenu,
        {
          ref: "menu",
          attrs: {
            "close-on-content-click": false,
            transition: "scale-transition",
            "offset-y": "",
            "min-width": "290px",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    VCombobox,
                    _vm._g(
                      {
                        attrs: {
                          value: _vm.formattedDates,
                          multiple: "",
                          placeholder: _vm.$t(
                            "workpackagePage.scope.datesLabel"
                          ),
                        },
                        on: { input: _vm.selectWeeks },
                      },
                      on
                    ),
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            VIcon,
                            {
                              attrs: { small: "" },
                              on: {
                                click: function ($event) {
                                  _vm.menu = true
                                },
                              },
                            },
                            [_vm._v("$date-picker")]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v
            },
            expression: "menu",
          },
        },
        [
          _vm._v(" "),
          _c(
            VDatePicker,
            {
              attrs: {
                value: _vm.value,
                "first-day-of-week": 1,
                "allowed-dates": _vm.allowedDates,
                multiple: "",
                "no-title": "",
                scrollable: "",
              },
              on: { input: _vm.selectWeeks },
            },
            [
              _c(VSpacer),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  attrs: { text: "", color: "primary" },
                  on: { click: _vm.clearSelectedDates },
                },
                [_vm._v(_vm._s(_vm.$t("actions.unselect")))]
              ),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  attrs: { text: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.menu = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
              ),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  attrs: { text: "", color: "primary" },
                  on: { click: _vm.updateSelectedDates },
                },
                [_vm._v(_vm._s(_vm.$t("actions.ok")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }