'use strict';

module.exports = {
  kmeans: 'kmeans',
  upload: 'upload',
  feed: 'feed',
  kmeansEdited: 'kmeans-edited',
  uploadEdited: 'upload-edited',
  default: 'default',
  defaultEdited: 'default-edited',
  regional: 'regional',
  regionalEdited: 'regional-edited',
};
