<template>
  <v-container fluid>
    <v-layout column>
      <workpackages-template />
      <workpackages-archived doc-path="160-templates" :entity="entities.template" />
    </v-layout>
  </v-container>
</template>

<script>
import entities from '@enums/entities';

export default {
  data() {
    return {
      entities,
    };
  },
};
</script>

<style lang="scss" scoped></style>
