<template>
  <div>
    <v-row no-gutters>
      <v-col>
        <v-btn
          data-id-e2e="btnNewWorkpackage"
          data-dd-action-name="AddNewWorkpackageBtn"
          primary
          :disabled="isNewButtonDisabled"
          @click="addNewWorkpackage"
          >{{ $t('workpackagePage.newWpButton') }}</v-btn
        >
        <v-btn
          v-if="templatesEnabled"
          data-id-e2e="btnNewWorkpackageFromTemplate"
          data-dd-action-name="AddNewWorkpackageFromTemplateBtn"
          primary
          :disabled="isNewButtonDisabled"
          @click="createNewWorkpackageFromTemplate"
        >
          {{ $t('workpackagePage.newWpFromTemplateButton') }}
        </v-btn>
      </v-col>
    </v-row>
    <workpackage-templates-modal
      v-model="isWorkpackageTemplatesModalOpen"
      @close="closeWorkpackageTemplatesModal"
    />
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'AddWorkpackageButtons',

  data() {
    return {
      isWorkpackageTemplatesModalOpen: false,
    };
  },

  computed: {
    ...mapState('bundles', ['selectedBundle']),

    ...mapGetters('workpackages', ['activeWorkpackages']),

    bundleId() {
      return get(this.selectedBundle, '_id');
    },

    isNewButtonDisabled() {
      return (
        (this.activeWorkpackages.length &&
          !this.activeWorkpackages[this.activeWorkpackages.length - 1]._id) ||
        !this.hasPermission(this.userPermissions.canCreateWorkpackage)
      );
    },
  },

  methods: {
    ...mapActions('workpackages', ['addWorkpackage']),

    async addNewWorkpackage() {
      await this.addWorkpackage({ bundleId: this.bundleId });
      this.scrollToNewWorkpackage();
    },

    createNewWorkpackageFromTemplate() {
      this.isWorkpackageTemplatesModalOpen = true;
    },

    closeWorkpackageTemplatesModal() {
      this.isWorkpackageTemplatesModalOpen = false;
    },

    scrollToNewWorkpackage() {
      // New workpackages are always added to the bottom of the list
      const newWorkpackage = document.getElementsByClassName('workpackage-list')[0].lastChild;
      if (newWorkpackage) newWorkpackage.scrollIntoView();
    },
  },
};
</script>
