import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "scenario-step-container pa-0", attrs: { fluid: "" } },
    [
      _c(
        VRow,
        { staticClass: "tab-row" },
        [
          _c(VFlex, [
            _c(
              "div",
              { staticClass: "step-tab-scope d-flex flex-column" },
              [
                _c(
                  VTabs,
                  {
                    staticClass: "step-tabs",
                    attrs: {
                      "data-id-e2e": "tabsStep",
                      value: _vm.selectedTabPosition,
                      "hide-slider": true,
                    },
                  },
                  _vm._l(_vm.tabs, function (tab) {
                    return _c(
                      VTab,
                      {
                        key: tab.routeName,
                        staticClass: "step-tab justify-space-between",
                        attrs: {
                          disabled: tab.disabled,
                          to: {
                            name: tab.routeName,
                            params: {
                              scenarioId: _vm.selectedScenario._id,
                            },
                          },
                          replace: tab.replaceInHistory,
                        },
                      },
                      [
                        _c("span", { staticClass: "tab-title" }, [
                          _vm._v(_vm._s(_vm.$t(tab.translationKey))),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "step-tab__status" }, [
                          tab.lastUpdated
                            ? _c("span", { staticClass: "tab-last-updated" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("general.updated")) +
                                    ":\n                " +
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        tab.lastUpdated,
                                        _vm.getDateFormats.medium
                                      )
                                    )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          tab.status && !tab.hideStatus
                            ? _c(
                                "span",
                                { staticClass: "tab-icon" },
                                [
                                  _vm.showStatuses
                                    ? _c(VIcon, { attrs: { small: "" } }, [
                                        _vm._v("$" + _vm._s(tab.status)),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                  1
                ),
                _vm._v(" "),
                _c("router-view", {
                  key: _vm.selectedScenario._id,
                  attrs: { "data-id-e2e": `${_vm.$route.name}-tab-page` },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }