<template>
  <a class="link-arrow">{{ unitData.levelEntryDescription }}</a>
</template>

<script>
export default {
  props: {
    unitData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss"></style>
