<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    offset-y
    :max-width="400"
    :min-width="400"
    :max-height="600"
  >
    <template v-slot:activator="{ on }">
      <v-btn class="float-right" :disabled="disabled" primary v-on="on">
        {{ legends.buttonName }}
      </v-btn>
    </template>
    <v-card class="assortment-table-add-attr">
      <v-card-title>
        {{ legends.title }}
      </v-card-title>
      <v-divider />

      <!-- Map fields modal - gets displayed after uploaded data is received -->
      <map-fields-modal
        v-if="uploadedDataDetails && showModal"
        :value="isMapFieldsModalOpen"
        :download-file-url="downloadFileUrl"
        :upload-metadata="uploadMetadata"
        :uploaded-data-details="uploadedDataDetails"
        :current-custom-fields="customFields"
        :fields-to-ignore="fieldsToIgnore"
        :enable-field-creation="enableFieldCreation"
        @confirm="processData"
        @cancel="cancelUpload"
      />

      <!-- Automatic fields mapping component -->
      <map-fields
        v-if="needToMapFields && !showModal"
        :download-file-url="downloadFileUrl"
        :uploaded-data-details="uploadedDataDetails"
        @confirm="processData"
        @cancel="cancelUpload"
      />

      <!-- Main form -->
      <v-form v-show="!needToMapFields" v-model="valid" @submit.prevent="">
        <v-radio-group v-model="uploadMethod">
          <!-- CSV upload section -->
          <div data-id-e2e="import-from-csv-option">
            <v-radio
              v-if="attributesUpload"
              :label="$t('actions.selectCsvFile')"
              :ripple="false"
              :value="uploadMethods.CSV"
              class="mb-3 mt-3 ml-3"
            />
            <v-list v-if="uploadFromCsv">
              <v-list-item class="mb-1">
                <v-list-item-title class="attribute-upload d-flex flex-column mt-2">
                  <div class="d-flex align-center">
                    <v-file-input
                      ref="fileInput"
                      v-model="file"
                      prepend-icon=""
                      class="mb-3"
                      accept=".csv"
                      :rules="rules"
                    >
                      <template v-slot:append-outer>
                        <v-btn class="add-file-btn" primary @click="triggerFindFile">
                          {{ $t('actions.findFile') }}
                        </v-btn>
                      </template>
                    </v-file-input>
                  </div>
                </v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item class="mt-3">
                <v-list-item-title class="delimiter">
                  <p class="mb-1">{{ $t('actions.delimiterPlaceholder') }}</p>
                  <v-text-field v-model="delimiter" :rules="rules" dense />
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>

          <!-- Upload from source section -->
          <div data-id-e2e="import-from-feed-option">
            <v-divider />

            <v-radio
              v-if="attributesUpload"
              :label="$t('actions.selectFeed')"
              :ripple="false"
              :value="uploadMethods.FEED"
              class="mb-3 mt-3 ml-3"
              @change="getAttributeSources"
            />
            <v-list v-if="uploadFromFeed">
              <v-list-item class="mb-1">
                <v-list-item-title class="attribute-upload d-flex flex-column mt-2">
                  <div class="upload-select-title mb-1">
                    <p>{{ $tkey('actions.sourceTitle') }}:</p>
                  </div>
                  <rtls-select
                    v-model="dataToUploadFromSource.source"
                    :items="attributeSources"
                    :placeholder="$tkey('actions.sourcePlaceholder')"
                    @change="getAttributes"
                  />
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="mt-3">
                <v-list-item-title class="attribute-upload d-flex flex-column">
                  <div class="upload-select-title mb-1">
                    <p>{{ $tkey('actions.attributesTitle') }}:</p>
                  </div>
                  <v-autocomplete
                    ref="autocompleteSelect"
                    v-model="dataToUploadFromSource.attributes"
                    :items="attributes"
                    :search-input.sync="searchInput"
                    :allow-overflow="false"
                    :disabled="!dataToUploadFromSource.source"
                    :placeholder="$tkey('actions.attributesPlaceholder')"
                    :rules="rules"
                    item-text="attributeName"
                    chips
                    multiple
                    clearable
                    background-color="white"
                    small-chips
                    hide-details
                    light
                    hide-selected
                    return-object
                    class="rtls-select--multi-select feed-attributes"
                    @change="searchInput = ''"
                    @click:append="menuArrow"
                  >
                    <v-list-item
                      v-if="!areAllAttributesSelected"
                      slot="prepend-item"
                      ripple
                      @click="selectAllAttributes"
                    >
                      <v-list-item-title class="mb-3 mt-3">{{
                        $tkey('actions.selectAll')
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-divider />
                    <template v-slot:selection="{ item }">
                      <!-- showing three first chips only -->
                      <v-chip small grey close @click:close="deleteChip(item)">
                        <span>{{ item.attributeName }}</span>
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>

          <!-- New custom attribute upload section -->
          <div data-id-e2e="import-custom-option">
            <v-divider />

            <v-radio
              v-if="attributesUpload"
              :label="$t('actions.selectCustomAttribute')"
              :ripple="false"
              :value="uploadMethods.CUSTOM"
              class="mb-3 mt-3 ml-3"
              @change="getAttributeSources"
            />
            <v-list-item v-if="customAttributeSelection" class="mt-3">
              <v-list-item-title class="delimiter">
                <p class="mb-1">{{ $t('actions.customAttributePlaceholder') }}</p>
                <v-text-field v-model="customAttribute" :rules="rules" dense />
              </v-list-item-title>
            </v-list-item>
          </div>

          <!-- Upload from other scenario section -->
          <div
            v-if="hasAttributeImportFromScenarioEnabled"
            data-id-e2e="import-from-scenario-option"
          >
            <v-divider />

            <v-radio
              v-if="attributesUpload"
              :label="$t('actions.selectOtherScenario')"
              :ripple="false"
              :value="uploadMethods.SCENARIO"
              class="mb-3 mt-3 ml-3"
            />
            <v-list-item v-if="uploadFromScenario" class="mt-3">
              <search-scenario
                block
                :active-scenario-id="selectedScenario._id"
                :rules="rules"
                @scenario-id-selected="updateScenarioIdToImportFrom"
              />
            </v-list-item>
          </div>

          <!-- From planogram upload section -->
          <div
            v-if="hasAttributeImportFromPlanogramEnabled"
            data-id-e2e="import-from-planogram-option"
          >
            <v-divider />

            <v-radio
              v-if="attributesUpload"
              :label="$t('actions.selectFromPlanogram')"
              :ripple="false"
              :value="uploadMethods.PLANOGRAM"
              class="mb-3 mt-3 ml-3"
            />
          </div>
        </v-radio-group>

        <!-- Main upload modal actions -->
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="cancelUpload">{{ $t('actions.cancel') }}</v-btn>
          <v-btn data-id-e2e="btnAdd" primary :disabled="!valid" @click="addData">{{
            $t('actions.add')
          }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { map, size, get } from 'lodash';
import uploadMethods from '@enums/productAttributes/attribute-upload-methods';
import { StoreNamespaces } from '../store/constants';
import inputValidationMixin from '../mixins/input-validations';

export default {
  mixins: [inputValidationMixin],

  props: {
    legends: {
      type: Object,
      required: true,
    },
    customAttrHandler: {
      type: Function,
      required: false,
      default: () => null,
    },
    csvUploadHandler: {
      type: Function,
      required: true,
    },
    sourceUploadHandler: {
      type: Function,
      required: false,
      default: () => null,
    },
    scenarioUploadHandler: {
      type: Function,
      required: false,
      default: () => null,
    },
    furnitureUploadHandler: {
      type: Function,
      required: false,
      default: () => null,
    },
    customFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    fieldsToIgnore: {
      type: Array,
      required: false,
      default: () => [],
    },
    showModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    productsData: {
      type: Array,
      required: false,
      default: () => [],
    },
    attributesUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    usedHeaderNames: {
      type: Array,
      required: false,
      default: () => [],
    },
    enableFieldCreation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      valid: true,
      showMenu: false,
      searchInput: '',

      uploadMethods,

      isMapFieldsModalOpen: false,
      uploadMethod: null,

      // for CSV upload
      file: null,
      delimiter: null,
      uploadedDataDetails: null,

      // for Feed upload
      attributeSources: [],
      attributes: [],
      dataToUploadFromSource: {
        source: null,
        attributes: [],
      },
      customAttribute: null,
    };
  },

  computed: {
    ...mapGetters('context', ['getCsvDelimiter', 'getClientConfig']),
    ...mapGetters('scenarios', ['selectedScenario']),

    hasAttributeImportFromScenarioEnabled() {
      return get(this.getClientConfig, 'features.importAttributesFromScenarioEnabled', false);
    },

    hasAttributeImportFromPlanogramEnabled() {
      return get(
        this.getClientConfig,
        'features.scenario.products.isImportAttributesFromPlanogramEnabled',
        false
      );
    },

    downloadFileUrl() {
      const url =
        this.uploadFromCsv && this.uploadedDataDetails
          ? `/api/file/${this.uploadedDataDetails._id}`
          : '';

      return url;
    },

    productKeys() {
      return map(this.productsData, 'productKey');
    },

    uploadFromCsv() {
      return this.uploadMethod === this.uploadMethods.CSV;
    },

    uploadFromFeed() {
      return this.uploadMethod === this.uploadMethods.FEED;
    },

    customAttributeSelection() {
      return this.uploadMethod === this.uploadMethods.CUSTOM;
    },

    uploadFromScenario() {
      return this.uploadMethod === this.uploadMethods.SCENARIO;
    },

    uploadFromPlanogram() {
      return this.uploadMethod === this.uploadMethods.PLANOGRAM;
    },

    uploadMetadata() {
      return {
        uploadMethod: this.uploadMethod,
        source: this.dataToUploadFromSource.source,
      };
    },

    areAllAttributesSelected() {
      return this.dataToUploadFromSource.attributes.length === this.attributes.length;
    },

    uniqueName() {
      const v = this.customAttribute;
      if (
        v &&
        this.usedHeaderNames.map(h => h.toLocaleLowerCase()).includes(v.toLocaleLowerCase())
      ) {
        return this.$t('scenarioInputPage.customAttribute.validationErrors.needsToBeUnique');
      }
      return true;
    },

    rules() {
      if (this.uploadFromCsv) {
        return [this.required];
      }
      if (this.uploadFromFeed) {
        // Validators must return strings on errors. Fixed in 2.2.4 https://github.com/vuetifyjs/vuetify/issues/9976
        return [list => !!size(list) || 'Error'];
      }
      if (this.customAttributeSelection) {
        return [this.required, this.uniqueName];
      }

      if (this.uploadFromScenario) {
        return [this.required, this.isNotEmpty];
      }

      return [];
    },

    needToMapFields() {
      if ((this.uploadFromFeed || this.uploadFromScenario) && this.uploadedDataDetails) return true;
      if (!this.uploadedDataDetails) return false;
      return this.uploadedDataDetails.unmappedExpectedFields.length;
    },
  },

  created() {
    this.delimiter = this.getCsvDelimiter;
    this.uploadMethod = this.uploadMethods.CSV;
  },

  methods: {
    ...mapActions(StoreNamespaces.files, ['deleteFile']),
    ...mapActions(StoreNamespaces.toolData, [
      'getProductAttributeSources',
      'getProductAttributesBySource',
    ]),

    async addFile() {
      const formData = new FormData();
      formData.append('file', this.file, this.file.name);
      formData.append('delimiter', this.delimiter);
      this.uploadedDataDetails = await this.csvUploadHandler(formData);
      // If the file has successfully uploaded and showModal prop is set, open modal to map fields
      if (this.showModal && this.uploadedDataDetails) this.openMapFieldsModal();
      // If the modal should not be displayed and the fields do not need mapping, process the file
      if (!this.showModal && !this.needToMapFields) return this.processData();
    },

    async addFromSource() {
      const attributeNames = map(this.dataToUploadFromSource.attributes, attr => {
        return `${this.dataToUploadFromSource.source} - ${attr.attributeName}`;
      });
      this.uploadedDataDetails = await this.sourceUploadHandler(attributeNames);
      if (this.showModal && this.uploadedDataDetails) this.openMapFieldsModal();
    },

    addCustomAttribute() {
      this.customAttrHandler(this.customAttribute);
      this.reset();
    },

    async addFromScenario() {
      this.uploadedDataDetails = await this.scenarioUploadHandler(this.sourceScenarioId);
      if (this.showModal && this.uploadedDataDetails) this.openMapFieldsModal();
    },

    async addFromFurniture() {
      this.uploadedDataDetails = await this.furnitureUploadHandler();
      if (this.showModal && this.uploadedDataDetails) this.openMapFieldsModal();
    },

    async cancelUpload() {
      if (get(this.uploadedDataDetails, '_id'))
        await this.deleteFile({ fileId: this.uploadedDataDetails._id });
      this.reset();
    },

    // Retrieves all options for attribtue sources to be selected.
    async getAttributeSources() {
      this.attributeSources = await this.getProductAttributeSources();
    },

    // Get all values for products based on the selected attribute source.
    async getAttributes() {
      this.attributes = await this.getProductAttributesBySource({
        attributeSource: this.dataToUploadFromSource.source,
        productKeys: this.productKeys,
      });
      // Clear selected attributes from previous source to avoid collisions
      this.dataToUploadFromSource.attributes = [];
    },

    updateScenarioIdToImportFrom(scenarioId) {
      this.sourceScenarioId = scenarioId;
    },

    addData() {
      if (this.uploadFromCsv) {
        return this.addFile();
      }

      if (this.uploadFromFeed) {
        return this.addFromSource();
      }

      if (this.customAttributeSelection) {
        return this.addCustomAttribute();
      }

      if (this.uploadFromScenario) {
        return this.addFromScenario();
      }

      if (this.uploadFromPlanogram) {
        return this.addFromFurniture();
      }
    },

    triggerFindFile() {
      // Accessing nested vuetify nodes using ref on the parent node, need to simulate a click on this file upload node when button clicked
      // unable to append a functional button to vuetify input field in other ways
      const textInput = this.$refs.fileInput.$el.children[0].children[0].firstElementChild
        .firstElementChild;
      textInput.click();
    },

    reset() {
      this.closeMapFieldsModal();
      this.showMenu = false;
      this.uploadedDataDetails = null;
      this.file = null;
      this.customAttribute = null;
      this.sourceScenarioId = null;
      this.uploadMethod = this.uploadMethods.CSV;
      this.dataToUploadFromSource = {
        source: null,
        attributes: [],
      };
      // The vuetify file input component has issues when we set the file back to null when resetting
      // After much trial and error calling this internal function of the component was the only fix I could find
      if (this.$refs.fileInput) this.$refs.fileInput.clearableCallback();
    },

    processData(mappings = []) {
      if (!this.showModal) {
        mappings = [...this.uploadedDataDetails.mappings, ...mappings];
      }

      // Send actual attribute names as well to perform lookup on server side
      const actualAttributeNames = map(this.dataToUploadFromSource.attributes, 'attributeName');

      this.$emit('process', {
        fileId: this.uploadedDataDetails._id,
        delimiter: this.delimiter,
        mappings,
        attributeSource: this.dataToUploadFromSource.source,
        attributeNames: actualAttributeNames,
        sourceScenarioId: this.sourceScenarioId, // nullable
        uploadMethod: this.uploadMethod,
      });

      this.reset();
    },

    menuArrow() {
      // allow arrow icon to close autocomplete selection menu
      const autocompleteSelect = this.$refs.autocompleteSelect;
      if (autocompleteSelect.isMenuActive) {
        this.$refs.autocompleteSelect.isMenuActive = false;
        autocompleteSelect.blur();
      } else {
        this.$refs.autocompleteSelect.isMenuActive = true;
        autocompleteSelect.focus();
      }
    },

    deleteChip(item) {
      const index = this.dataToUploadFromSource.attributes.indexOf(item);
      this.dataToUploadFromSource.attributes.splice(index, 1);
    },

    openMapFieldsModal() {
      this.showMenu = false;
      this.isMapFieldsModalOpen = true;
    },

    closeMapFieldsModal() {
      this.isMapFieldsModalOpen = false;
    },

    selectAllAttributes() {
      const select = this.$refs.autocompleteSelect;

      // Copy all v-select's items into dataToUploadFromSource.attributes array
      this.dataToUploadFromSource.attributes = [...this.attributes];

      // Close autocomplete menu
      select.isMenuActive = false;
      select.blur();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.assortment-table-add-attr {
  .v-list-item__title {
    font-size: 1.2rem;

    &.attribute-upload {
      p {
        margin: 0;
      }
    }
  }

  .title-container {
    .v-list-item__title {
      font-size: 1.4rem;
      font-weight: bold;
    }
  }

  .add-file-btn {
    margin-top: 5px;
  }

  .v-list-item {
    padding: 0 15px;
  }

  .upload-select-title {
    font-weight: bold;
  }

  .delimiter {
    p {
      margin: 0;
    }
  }

  ::v-deep {
    .v-text-field__slot {
      input {
        padding-bottom: 4px !important;
      }
    }
    .v-file-input__text {
      font-size: 1.2rem;
    }

    .v-input__control {
      width: 100%;
    }

    .v-select__selections {
      max-height: 65px;
      overflow-y: auto;
    }
  }

  .v-card__actions {
    padding: 0px 15px 15px 15px;
  }

  .feed-attributes {
    height: unset !important;

    ::v-deep {
      .v-chip {
        max-width: unset !important;

        .v-chip__content span {
          max-width: 256px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .v-select__selections {
        // The maximum height is 10 rows
        max-height: 320px !important;
      }
    }
  }
}
</style>
