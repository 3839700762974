import axios from 'axios';
import to from 'await-to-js';
import Vue from 'vue';
import {
  groupBy,
  max,
  values,
  set,
  merge,
  findIndex,
  keyBy,
  isEmpty,
  size,
  map,
  isEqual,
  orderBy,
  intersection,
  cloneDeep,
  includes,
  get,
  isNumber,
  forEach,
  keys,
  sumBy,
  partition,
  every,
  some,
  filter,
  uniqBy,
  sortBy,
  toLower,
} from 'lodash';
import {
  Filters,
  FilterValues,
  FilterMappings,
  Views,
  DisplayBy,
} from '@enums/assortment-canvases';
import { lockTypes } from '@enums/assortment-product-lock-types';
import { kpis } from '@enums/product-kpis';
import AgGridUtils from '@/js/utils/ag-grid-utils';
import gridPreferences from '@/js/utils/grid-preferences';
import { jobApi } from '@/js/helpers';
import i18n from '../../vue-i18n';
import { StoreNamespaces } from '../constants';
import handleErrorMessages from '../utils/validation';

// storage prefixes for column state - if you change these keys, existing user local storage objects will be invalid
const LIST_VIEW_COLUMN_STORAGE_PREFIX = 'listViewColumnState';
const LIST_VIEW_LAST_STATE_SAVED_SCENARIO_PREFIX = 'listViewLastStateSavedScenario';
const CANN_GROUPS_COLUMN_STORAGE_PREFIX = 'canvasCannGroupsColumnState';

/*
 * Function to return a set of product keys matching customer segments
 * segment data is set on each product like so
 * mainCustomerSegments: [
 *  {scheme: 'Price-Sensitivity', segment: 'Price-Focused'},
 *  {scheme: 'Residence-Type', segment: 'Urban'},
 * ]
 * Which indicates that this product is purchased by 'Price-Focused' customers in the
 * 'Price-Sensitivity' customer segmentation scheme and 'Urban' customers in the 'Residence-Type'
 * customer segmentation scheme.
 */
function getProductKeysMatchingCustomerSegmentsFilter(toFilter, products) {
  const customerSegmentFilters = filter(toFilter, f => f.type === Filters.CustomerSegment);
  if (!customerSegmentFilters.length) return;

  // Convert mainCustomerSegments to object like {scheme: segment}
  const productKeyMainSegmentsMap = products.reduce((acc, { productKey, mainCustomerSegments }) => {
    if (mainCustomerSegments) {
      acc[productKey] = mainCustomerSegments.reduce((schemeMainSegmentMap, { scheme, segment }) => {
        schemeMainSegmentMap[scheme] = segment;
        return schemeMainSegmentMap;
      }, {});
    }
    return acc;
  }, {});

  // Create the set
  const productKeysMatchingFilter = customerSegmentFilters.reduce((acc, filterObj) => {
    filterObj.values.forEach(({ customerSegmentScheme, customerSegment }) => {
      Object.entries(productKeyMainSegmentsMap).forEach(([productKey, mainSegmentsMap]) => {
        if (mainSegmentsMap[customerSegmentScheme] === customerSegment) {
          acc.add(+productKey);
        }
      });
    });
    return acc;
  }, new Set());
  return productKeysMatchingFilter;
}

/*
 * Function to return a delegate function with curried state to return a set which determines
 * which product keys should be highlighted based on their external ranking.
 * See AOV3-3202 for detailed spec
 */
function getDetermineExternallyRankedProductsToHighlightDelegate(
  canvas,
  spacebreaks,
  pod,
  assortmentCanvasProducts,
  clientConfig
) {
  function delegate(toFilter, filteredProducts) {
    const config = clientConfig.externalProductRanking; // Alias for ease of access

    // This is what we return, for each filter index that is an externalProductRanking we
    // add a set of products to highlight
    const filterMap = {};
    forEach(toFilter, (filterObj, filterIndex) => {
      if (filterObj.type === Filters.ExternalProductRanking) {
        filterMap[filterIndex] = new Set();
        const filterValuesSet = new Set(filterObj.values);
        const filteredProductKeys = new Set(filteredProducts.map(p => p.productKey));
        const productsWithExteralRanking = assortmentCanvasProducts.filter(
          p => 'externalProductRanking' in p
        );
        const uniqueCategoryIds = new Set(
          productsWithExteralRanking.map(p => p.externalProductRanking.categoryId)
        );

        // We want to sort the products with external ranking from the best by external ranking
        // to the worst by external ranking
        // If we only have one category we can sort by rank, otherwise we have to do a combined
        // sort using the cutUtility, with ties going to rank. We use 'desc', 'asc', because
        // with rank, a lower number is better, but with cutUtility a higher number is better
        let productsWithExternalRankingSorted;
        if (uniqueCategoryIds.size === 1) {
          productsWithExternalRankingSorted = orderBy(
            productsWithExteralRanking,
            p => p.externalProductRanking.rank
          );
        } else {
          productsWithExternalRankingSorted = orderBy(
            productsWithExteralRanking,
            [p => p.externalProductRanking.cutUtility, p => p.externalProductRanking.rank],
            ['desc', 'asc']
          );
        }

        // Get the top X external ranking
        if (filterValuesSet.has(FilterValues.TopXExternalRanking)) {
          const numToHighLight = Math.min(
            Math.ceil((productsWithExternalRankingSorted.length * config.topXPercentage) / 100),
            config.topXAbsoluteLimit
          );
          forEach(productsWithExternalRankingSorted.slice(0, numToHighLight), p => {
            if (filteredProductKeys.has(p.productKey)) filterMap[filterIndex].add(p.productKey);
          });
        }

        // Get the higher reference ranking
        if (filterValuesSet.has(FilterValues.HigherReferenceRanking)) {
          const totalPod = pod[spacebreaks[0]._id];
          const spacebreakLookup = keyBy(spacebreaks, '_id');
          const condition4Limit = Math.floor(
            (assortmentCanvasProducts.length * config.condition4Percentage) / 100
          );

          let i = 0;
          let extRank = 0;
          let cumSum = 0.0;
          forEach(productsWithExternalRankingSorted, p => {
            cumSum += p.size;
            extRank += 1; // Since optimiser rank starts at 1 we can add one here
            while (i < spacebreaks.length && cumSum > spacebreaks[i].fillOverride) {
              // Keep advancing our i pointer until we're either in the unlisted bay or
              // until we land in the next spacebreak that fits
              i += 1;
            }

            // Skip to next if this is not in the set of filtered products anyway
            if (!filteredProductKeys.has(p.productKey)) return true;

            const extSpacebreak = spacebreaks[i];
            const curSpacebreak = spacebreakLookup[p.currentSpacebreakId];

            // Condition 1: Is the external spacebreak 'better' than the current spacebreak
            // If either spacebreak is undefined (not listed) then the fill rank is the number
            // of spacebreaks + 1
            const extSpacebreakFillRank = get(extSpacebreak, 'fillRank', spacebreaks.length + 1);
            const curSpacebreakFillRank = get(curSpacebreak, 'fillRank', spacebreaks.length + 1);
            const condition1 = extSpacebreakFillRank < curSpacebreakFillRank;
            if (!condition1) return true;

            // Condition 2, for some clients we don't want to use this at all
            if (config.respectCondition2) {
              // Is (A - B) * 100 / C >= X, where
              // A = external POD, B = current POD, C = Total POD, X is a percentange (default 20)
              // I.e. there would be a >= X% increase in the number of stores the product would be
              // included in if it were placed by its external ranking
              const extPod = pod[get(extSpacebreak, '_id')] || 0;
              const curPod = pod[get(curSpacebreak, '_id')] || 0;
              const podDeltaPercentage = ((extPod - curPod) * 100) / totalPod;
              const condition2 = podDeltaPercentage >= config.condition2Percentage;
              if (!condition2) return true;
            }

            // Condition 3: Is the external rank better than the current rank
            // If this canvas has not been optimised we simply say condition 3 is true
            const curRank = p.rank;
            const condition3 = canvas.hasBeenOptimised ? extRank < curRank : true;
            if (!condition3) return true;

            // Condition 4: Does the external ranking put them in the top 50% of products
            const condition4 = extRank < condition4Limit;
            // If condition 4 starts being false, it will remain false => quit this loop to avoid
            // needless computations
            if (!condition4) return false;

            // If we are here, all conditions must have been met => add to products to highlight
            filterMap[filterIndex].add(p.productKey);
          });
        }
      }
    });
    return filterMap;
  }
  return delegate;
}

function calculateProductsDeltas(productsOrSingleProduct, pod, referenceCheckpointProducts) {
  if (!isEmpty(pod)) {
    const noReferenceCheckpoint = isEmpty(referenceCheckpointProducts);
    // There will always be a current and last optimised
    // In the case that the optimiser hasn't run, they'll be the same and the delta will be 0.
    const products = Array.isArray(productsOrSingleProduct)
      ? productsOrSingleProduct
      : [productsOrSingleProduct];
    return products.map(product => {
      const podOfCurrent = pod[product.currentSpacebreakId];
      const podOfLastOptimised = pod[product.optimisedSpacebreakId];

      // If there's no reference checkpoint data, theproductsPerSpacebreak difference will always be 0
      // If there is one, subtract previous from current
      let referenceDelta = 0;
      if (!pod || !referenceCheckpointProducts) {
        referenceDelta = 0;
      } else {
        referenceDelta = noReferenceCheckpoint
          ? 0
          : podOfCurrent - pod[referenceCheckpointProducts[product.productKey]];
      }
      Vue.set(product, 'deltas', {
        reference: referenceDelta,
        lastOptimised: podOfCurrent - podOfLastOptimised,
      });

      return product;
    });
  }

  return Array.isArray(productsOrSingleProduct) ? productsOrSingleProduct : undefined;
}

function applyProductFilters(
  products,
  toFilter,
  spacebreakFilters,
  determineExternallyRankedProductsToHighlightDelegate,
  productsSet
) {
  // If we are not using externally ranked products to highlight, this will simply be null
  const externallyRankedProductsToHighlightMap = determineExternallyRankedProductsToHighlightDelegate(
    toFilter,
    products
  );
  // Will fast return null if no filters are customer segment filters
  const productKeysMatchingCustomerSegmentsFilter = getProductKeysMatchingCustomerSegmentsFilter(
    toFilter,
    products
  );

  return products.filter(p => {
    const isVisible = every(toFilter, (f, filterIndex) => {
      if (f.type === Filters.ExternalProductRanking) {
        return (
          externallyRankedProductsToHighlightMap[filterIndex].has(p.productKey) !== f.notEqualTo
        );
      }
      if (f.type === Filters.CustomerSegment) {
        return productKeysMatchingCustomerSegmentsFilter.has(p.productKey) !== f.notEqualTo;
      }

      if (f.type === Filters.PromotedDemoted) {
        const { lastOptimised, reference } = get(p, 'deltas', {});
        const option = f.values[0];

        switch (option) {
          case FilterValues.PromotedOptimisation:
            return lastOptimised > 0 !== f.notEqualTo;
          case FilterValues.DemotedOptimisation:
            return lastOptimised < 0 !== f.notEqualTo;
          case FilterValues.PromotedReferenceCheckpoint:
            return reference > 0 !== f.notEqualTo;
          case FilterValues.DemotedReferenceCheckpoint:
            return reference < 0 !== f.notEqualTo;
          default:
            return false;
        }
      }

      if (includes(spacebreakFilters, f.type)) {
        let hasMatch = includes(f.values, p[f.type]);

        if (!p[f.type]) {
          hasMatch = some(f.values, v =>
            v === FilterMappings.NotForAssortment
              ? !p.assortment || p.isLocal
              : v === !!p.isEligible && p.assortment && !p.isLocal
          );
        }
        return hasMatch !== f.notEqualTo;
      }

      // custom attributes are flattened to main object, so we can access their props directly
      if (f.type === Filters.customAttributes) f.type = f.attributeId;
      // isNewProduct is an optional field, so if not present, it is false
      if (f.type === Filters.ProductType) p[f.type] = p[f.type] || false;

      return includes(f.values, p[f.type]) !== f.notEqualTo;
    });
    if (isVisible) productsSet.add(p.productKey);

    return isVisible;
  });
}

// Function to get KPI title to display in header
function getKpiTitle(kpi, rootState) {
  if (kpi === kpis.SALES) {
    return i18n.t(`assortmentCanvas.${kpi}`, {
      currency: i18n.t(`currencies.${rootState.context.numericLocale}`),
    });
  }
  return i18n.t(`assortmentCanvas.${kpi}`);
}

const store = {
  namespaced: true,
  state: {
    assortmentCanvases: [],
    workpackageAssortmentCanvases: [],
    workpackageCheckpoints: [],
    assortmentCheckpoints: [],
    scenarioCheckpoints: [],
    selectedAssortmentCanvas: {},
    selectedPod: {},
    assortmentCanvasProducts: [],
    spacebreaks: [],
    activeSpacebreak: null,
    spacebreaksOrderedObject: {},
    loading: false,
    selectedView: Views.Tile,
    // contains the max depth of visible CDT per CG
    expandedCdts: {},
    childTrees: {},
    additionalAttributeColumns: [],
    additionalKPIsColumns: [],
    tileSize: {
      medium: {
        productImage: {
          height: 72,
          width: 72,
        },
        tile: {
          height: 80,
          width: 80,
        },
        tileContainer: {
          'min-width': 190,
          'max-width': 460,
        },
      },
      large: {
        productImage: {
          height: 102,
          width: 102,
        },
        tile: {
          height: 110,
          width: 110,
        },
        tileContainer: {
          'min-width': 250,
          'max-width': 610,
        },
      },
    },
    marginAroundTile: 9,
    referenceCheckpoint: null,
    selectedReferenceCheckpoint: null,
    referenceCheckpointProducts: [],
    storeClassId: null,
    popUpZindex: 7,
    borderWidth: 1,
    sidebarHeaderHeight: 85, // default height of sidebar header
    listViewContainerWidth: {
      medium: {
        // min width
        default: 1100,
        // width before kpis added
        base: 650,
      },
      // TO DO - change these values for the large list-view size in https://owlabs.atlassian.net/browse/AOV3-1422
      large: {
        // min width
        default: 1100,
        // width before kpis added
        base: 650,
      },
    },
    // selectedTileSize is the variable we should be updating when implementing tile size dragger feature
    selectedTileSize: 'medium',
    spacebreakSettings: {
      spacebreak: {
        _id: 'c00000000000000000000002',
      },
      open: false,
    },

    constraintEditing: {
      constraint: {
        rowNodeId: '',
        open: false,
        rowNodeData: {},
      },
    },
    canvasRenderingStatus: false,
    canvasProductsFilters: [],
    // current selection of filters, can be not applied yet
    canvasProductsFiltersSelection: [],
    canvasProductsHighlights: [],
    showFilterLegend: false,
    productImages: {},
    fullScreenExpanded: false,
    stickyHeaders: true,
    unifiedColumns: {
      tile: false,
      list: false,
    },
    selectedDashboardProduct: null,
    showDashboard: false,
    selectedProductTopPartners: [],
    selectedProductTransferredSpend: {},
    // contains all constraints that may be copied
    constraintsCopyTarget: {},
    // map of destination of constraints being copied
    selectedConstraintsToCopy: {},
    // controls whether the sidebar is open or note
    isProductSidebarOpen: false,
    // current size of rows in grid visible
    numberOfRowsInGrid: 0,
    displayBy: DisplayBy.cdt,

    listViewColumns: [],
    listViewFixedColumns: ['optimisedRank', 'productKeyDisplay', 'itemDescription'],
    // have a state for cann groups columns on canvas (not the same as scenario cann groups)
    // to manage what CDTs to display in CDT display mode
    canvasCannGroupColumns: [],
  },

  getters: {
    getPointsOfDistribution: (state, getters, rootState, rootGetters) => {
      // Points of distribution are the stores that utilise furniture contained in a spacebreak.
      // For each spacebreak, we get the furniture it contains and then sum them.
      // We get a cumulative sum from the largest spacebreak to the smallest.
      // This reflects the russian doll nesting of spacebreaks. If the smallest spacebreak contains
      // a piece of furniture, then all larger ones will as well.sorted
      const selectedClusterScheme = cloneDeep(rootGetters['clustering/selectedClusterScheme']);
      const clusters = get(selectedClusterScheme, 'clusters', []);
      // need this for the unclustered points of distribution
      clusters.push({ clusterId: null, storeKeys: [] });
      const getPointsOfDistributionPerStoreClass = (spacebreaks, clusterStoreKeys) => {
        const clonedSpacebreaks = cloneDeep(spacebreaks);
        // Sum from the right so you reflect russian doll nesting of spacebreaks
        const sortedSpacebreaks = orderBy(clonedSpacebreaks, 'fillOverride', 'desc');

        const { pod: pointsOfDistribution } = sortedSpacebreaks.reduce(
          (acc, sb) => {
            sb.storeKeys =
              clusterStoreKeys.length > 0
                ? intersection(sb.storeKeys, clusterStoreKeys)
                : sb.storeKeys;
            const storeCount = sb.storeKeys.length;
            const currentSum = storeCount + acc.previousStoreSum;
            acc.previousStoreSum = currentSum;
            return set(acc, `pod.${sb._id}`, currentSum);
          },
          // Null is initialised to 0 to handle the unassigned spacebreak
          { pod: { null: 0 }, previousStoreSum: 0 }
        );

        return pointsOfDistribution;
      };

      const podsPerClusterStoreClass = {};
      const storeClasses = rootState[StoreNamespaces.furniture].scenarioFurniture.storeClasses;
      clusters.forEach(c => {
        storeClasses.forEach(sc => {
          if (!podsPerClusterStoreClass[c.clusterId]) {
            podsPerClusterStoreClass[c.clusterId] = {
              [sc._id]: getPointsOfDistributionPerStoreClass(sc.spacebreaks, c.storeKeys),
            };
          } else {
            podsPerClusterStoreClass[c.clusterId][sc._id] = getPointsOfDistributionPerStoreClass(
              sc.spacebreaks,
              c.storeKeys
            );
          }
        });
      });
      return podsPerClusterStoreClass;
    },

    canvases(state) {
      return state.assortmentCanvases;
    },

    canvasProducts(state) {
      return state.assortmentCanvasProducts;
    },

    sortByProductsInCanvas: state => (a, b) => {
      const { hasBeenOptimised } = state.selectedAssortmentCanvas;
      const bothAreUnlisted = !a.currentSpacebreakId && !b.currentSpacebreakId;

      if (!hasBeenOptimised || bothAreUnlisted) {
        return AgGridUtils.sortings.naturalSort(a.productKeyDisplay, b.productKeyDisplay);
      }
      if (!a.currentSpacebreakId) return 1;
      if (!b.currentSpacebreakId) return -1;

      // sort ascending
      return a.optimisedRank - b.optimisedRank;
    },

    spaceUsedPerSpacebreak(state, getters) {
      const { spacebreakSizes } = state.spacebreaks.reduce(
        (acc, c) => {
          const products = filter(getters.productsPerSpacebreak[c._id], ({ palletContent }) =>
            isEmpty(palletContent)
          );
          const currentSpacebreakSum = sumBy(products, 'size');

          const currentSum = currentSpacebreakSum + acc.previousSum;
          acc.previousSum = currentSum;
          return set(acc, `spacebreakSizes.${c._id}`, currentSum);
        },
        { spacebreakSizes: {}, previousSum: 0 }
      );

      return spacebreakSizes;
    },

    productsPerSpacebreak: state => groupBy(state.assortmentCanvasProducts, 'currentSpacebreakId'),

    getProductsWithNoSpacebreak: (state, getters) => products => {
      // Sorts all products that are not in any spacebreak by type.
      const sortedProducts = (products || []).sort(getters.sortByProductsInCanvas);
      const [forAssortment, notForAssortment] = partition(
        sortedProducts,
        p => p.assortment && !p.isLocal
      );
      const [notListed, notInStoreClass] = partition(forAssortment, 'isEligible');
      return {
        notListed,
        notForAssortment,
        notInStoreClass,
      };
    },

    checkpoints(state) {
      return state.assortmentCheckpoints.map(
        ({
          checkpointMeta,
          _id,
          isObserved,
          forecastParameters,
          forecastResults,
          jobs,
          reference,
          isNotRevertable,
        }) => ({
          _id,
          isObserved,
          forecastParameters,
          forecastResults,
          checkpoint: checkpointMeta,
          jobs,
          reference,
          isNotRevertable,
        })
      );
    },

    selectedCanvas(state) {
      return state.selectedAssortmentCanvas;
    },

    selectedPod(state) {
      return state.selectedPod;
    },

    selectedCanvasLockedSpacebreaks(state) {
      return new Set(
        state.selectedAssortmentCanvas.spacebreaks.filter(sb => sb.isLocked).map(({ _id }) => _id)
      );
    },

    isSpacebreakLocked: (state, getters) => id => {
      return getters.selectedCanvasLockedSpacebreaks.has(id);
    },

    isSpacebreakLockToggleDisabled: (state, getters) => spacebreakId => {
      const lockedSpacebreaks = [...getters.selectedCanvasLockedSpacebreaks];
      const spacebreakRank = state.spacebreaksOrderedObject[spacebreakId].rank;
      return lockedSpacebreaks.some(id => state.spacebreaksOrderedObject[id].rank > spacebreakRank);
    },

    totalExpandedHeaderDepth: state => {
      return state.unifiedColumns[state.selectedView]
        ? 1
        : max([max(values(state.expandedCdts)), 1]);
    },

    workpackageAssortmentCanvasesScenarioMap(state) {
      return keyBy(state.workpackageAssortmentCanvases, 'scenarioId');
    },

    scenarioCheckpointsById(state) {
      return keyBy(state.scenarioCheckpoints, '_id');
    },

    scenarioCheckpointsByCanvasId(state) {
      /**
       * We need to create a map of canvasId to a list of all checkpoints for that canvas
       * for every canvas in the scenario.
       * {
       *  canvasAId: [...listOfCheckpoints],
       *  canvasBId: [...listOfCheckpoints],
       * }
       */
      const canvasIdmap = state.assortmentCanvases.reduce(
        (acc, canvas) => ({
          ...acc,
          [`${canvas.clusterId}-${canvas.storeClassId}`]: canvas._id,
        }),
        {}
      );
      if (isEmpty(canvasIdmap)) return {};
      return state.scenarioCheckpoints.reduce((acc, checkpoint) => {
        const canvasIdForCheckpoint =
          canvasIdmap[`${checkpoint.clusterId}-${checkpoint.storeClassId}`];
        acc[canvasIdForCheckpoint] = [...(acc[canvasIdForCheckpoint] || []), checkpoint];
        return acc;
      }, {});
    },

    getCanvasRenderingStatus(state) {
      return state.canvasRenderingStatus;
    },

    getCanvasProductsFiltersToFilter(state) {
      return state.canvasProductsFilters.map(pf => ({
        notEqualTo: pf.notEqualTo,
        type: pf.filterType,
        attributeId: pf.attributeId,
        values:
          pf.filterType === Filters.CustomerSegment ? pf.filterValues : map(pf.filterValues, 'id'),
      }));
    },

    getCanvasProductsHighlightsToFilter(state) {
      return state.canvasProductsHighlights.map(pf => ({
        notEqualTo: pf.notEqualTo,
        type: pf.filterType,
        attributeId: pf.attributeId,
        values:
          pf.filterType === Filters.CustomerSegment ? pf.filterValues : map(pf.filterValues, 'id'),
      }));
    },

    getFilteredProducts(state, getters, rootState) {
      let res;
      if (!size(state.canvasProductsFilters)) {
        res = {
          visibleProductsSet: new Set(map(state.assortmentCanvasProducts, 'productKey')),
          products: state.assortmentCanvasProducts,
        };
      } else {
        const spacebreakFilters = [
          FilterMappings.CurrentSpacebreak,
          FilterMappings.OptimisedSpacebreak,
          FilterMappings.OriginalSpacebreak,
        ];
        const visibleProductsSet = new Set([]);
        const products = applyProductFilters(
          state.assortmentCanvasProducts,
          getters.getCanvasProductsFiltersToFilter,
          spacebreakFilters,
          getDetermineExternallyRankedProductsToHighlightDelegate(
            state.selectedAssortmentCanvas,
            state.spacebreaks,
            state.selectedPod,
            state.assortmentCanvasProducts,
            rootState.context.clientConfig
          ),
          visibleProductsSet
        );
        res = {
          visibleProductsSet,
          products,
        };
      }

      // Remove pallets from view
      state.assortmentCanvasProducts.forEach(({ palletContent, productKey }) => {
        if (!isEmpty(palletContent)) {
          res.visibleProductsSet.delete(productKey);
        }
      });
      return res;
    },

    /** calls getFilteredProducts first and then figures out which of the filtered products to highlight */
    getHighlightedProducts(state, getters, rootState) {
      const spacebreakFilters = [
        FilterMappings.CurrentSpacebreak,
        FilterMappings.OptimisedSpacebreak,
        FilterMappings.OriginalSpacebreak,
      ];
      const filteredProducts = getters.getFilteredProducts;
      if (!size(state.canvasProductsHighlights)) {
        return {
          visibleProductsSet: filteredProducts.visibleProductsSet,
          products: filteredProducts.products,
          highlightedProductsSet: new Set([]),
          highlightedProducts: null,
        };
      }
      const highlightedProductsSet = new Set([]);
      const highlightedProducts = applyProductFilters(
        filteredProducts.products,
        getters.getCanvasProductsHighlightsToFilter,
        spacebreakFilters,
        getDetermineExternallyRankedProductsToHighlightDelegate(
          state.selectedAssortmentCanvas,
          state.spacebreaks,
          state.selectedPod,
          state.assortmentCanvasProducts,
          rootState.context.clientConfig
        ),
        highlightedProductsSet
      );

      return {
        visibleProductsSet: filteredProducts.visibleProductsSet,
        products: filteredProducts.products,
        highlightedProductsSet,
        highlightedProducts,
      };
    },

    getDashboardProduct(state) {
      return state.selectedDashboardProduct;
    },

    getShowDashboard(state) {
      return state.showDashboard;
    },

    getIndexedCanvasProducts(state) {
      return keyBy(state.assortmentCanvasProducts, 'productKey');
    },

    getSelectedProductTopPartners(state) {
      return cloneDeep(state.selectedProductTopPartners);
    },

    getSelectedProductTransferredSpend(state) {
      return cloneDeep(state.selectedProductTransferredSpend);
    },

    isCanvasConfigInvalidForOptimisation(state, getters, rootState) {
      const invalidAccordingTo = isEmpty(
        get(rootState.scenarios.selectedScenario, 'optimiserSettings.optimiseAccordingTo', '')
      );
      const validUnitVolume = isNumber(
        get(
          rootState.scenarios.selectedScenario,
          'optimiserSettings.utilityWeights.unitVolume',
          null
        )
      );
      const validSales = isNumber(
        get(rootState.scenarios.selectedScenario, 'optimiserSettings.utilityWeights.sales', null)
      );
      const validMargin = isNumber(
        get(rootState.scenarios.selectedScenario, 'optimiserSettings.utilityWeights.margin', null)
      );
      return invalidAccordingTo || !validUnitVolume || !validSales || !validMargin;
    },

    wpCheckpointsMap(state) {
      // create a mapping object of checkpoints {scenarioId: {clusterId: [checkpoints] }}
      const grouped = groupBy(state.workpackageCheckpoints, 'scenarioId');
      forEach(grouped, (value, key) => {
        grouped[key] = groupBy(grouped[key], 'clusterId');
      });
      return grouped;
    },

    wpCanvasesMap(state) {
      // create a mapping object of canvases {scenarioId: {clusterId: [canvases] }}
      const grouped = groupBy(state.workpackageAssortmentCanvases, 'scenarioId');
      forEach(grouped, (value, key) => {
        grouped[key] = groupBy(grouped[key], 'clusterId');
      });
      return grouped;
    },

    canvasesById(state) {
      return keyBy(state.assortmentCanvases, '_id');
    },

    getProductTag: () => product => {
      return product.modellingType;
    },

    productsByCdtGroup(state) {
      return groupBy(state.assortmentCanvasProducts, 'cdtGroupId');
    },

    hasConstraintsToCopySelected(state) {
      return size(state.selectedConstraintsToCopy) > 0;
    },

    isConstraintSelectedToCopy: state => ({ constraintId }) => {
      const spacebreakId = state.selectedConstraintsToCopy[constraintId];

      return spacebreakId === null || !isEmpty(spacebreakId);
    },

    constraintsToCopyById(state) {
      return keyBy(state.constraintsCopyTarget.constraints.definitions, 'id');
    },

    canvasProductsPallets(state) {
      return groupBy(state.assortmentCanvasProducts, 'palletContent');
    },

    getDisplayBy(state) {
      return state.displayBy;
    },

    availableProductAttributesValues: (state, getters) => attributeName => {
      const options = uniqBy(getters.canvasProducts, attributeName)
        .filter(p => p[attributeName])
        .map(p => ({
          name: p[attributeName],
          id: p[attributeName],
        }));

      return sortBy(options, option => toLower(option.id));
    },

    availableProductSeries(state, getters) {
      return getters.availableProductAttributesValues('productSeries');
    },

    /* List view columns logic */

    filteredListViewKpiColumns(state, getters, rootState) {
      // filter out rank and utility from selectedKpis
      // rank column is at start of list-view, utlity not used on list-view
      return rootState.context.clientConfig.selectedKpis.filter(
        kpi => kpi !== 'rank' && kpi !== 'utility'
      );
    },

    listViewExtraColumns(state, getters, rootState, rootGetters) {
      if (!getters.selectedCanvas) {
        return [];
      }

      const extraAttributeIds = get(
        state.additionalAttributeColumns,
        [getters.selectedCanvas._id],
        []
      ).map(attr => attr.id);
      const extraKPIIds = get(state.additionalKPIsColumns, [getters.selectedCanvas._id], []).map(
        kpi => kpi.id
      );

      // Add extraKPIs columns
      const extraKPICols = (rootState.context.clientConfig.extraKPIs || []).map(kpi => ({
        id: kpi,
        title: getKpiTitle(kpi, rootState),
        type: 'kpi',
        visible: extraKPIIds.includes(kpi), // Set visible flag based on extraKPIs
      }));

      const scenarioAttributes = sortBy(rootGetters['clustering/getScenarioAttributes'], item =>
        item.name.toLowerCase()
      );
      const extraAttributeCols = scenarioAttributes.map(attr => ({
        id: attr.id,
        title: attr.name,
        type: 'attr',
        visible: extraAttributeIds.includes(attr.id), // Set visible flag based on extraAttributes
      }));

      // make extra KPIs next to existing KPIs
      return extraKPICols.concat(extraAttributeCols);
    },

    // Building the initial columns for canvas list view header layout dynamically
    // This is placed in the store since 2 components need to use the same logic
    // TODO is to change list-view to use AG Grid - then this logic can be moved back down to component
    initialListViewColumns: (state, getters, rootState) => {
      const hasProductLineEnabled = get(
        rootState.context.clientConfig,
        'features.scenario.products.isProductLineEnabled',
        false
      );

      const transactionWeightEnabled = get(
        rootState.context.clientConfig,
        'features.transactionWeightEnabled',
        false
      );

      let baseColumns = [];
      const unifiedColumns = state.unifiedColumns[state.selectedView];

      if (rootState.context.clientConfig.selectedKpis.includes('rank')) {
        baseColumns.push({
          id: 'optimisedRank',
          visible: true,
          title: i18n.t('assortmentCanvasPage.listViewPage.rank'),
          headerClass: `header-col pr-1 ${!unifiedColumns ? 'col-xs' : ''}`,
          cellClass: `pt-1 pb-1 pr-1 font-weight-bold entry-col ${!unifiedColumns ? 'col-xs' : ''}`,
          width: 70,
        });
      }

      // Add display name and description columns
      baseColumns = baseColumns.concat([
        {
          id: 'productKeyDisplay',
          visible: true,
          title: i18n.t('assortmentCanvasPage.listViewPage.id'),
          headerClass: `header-col id-col ${!unifiedColumns ? 'col-short' : ''}`,
          cellClass: 'pt-1 pb-1 entry-col id-col',
          width: 100,
        },
        {
          id: 'itemDescription',
          visible: true,
          title: i18n.t('assortmentCanvasPage.listViewPage.description'),
          headerClass: 'header-col description-col',
          cellClass: 'pt-1 pb-1 entry-col description-col',
          width: 260,
        },
        // Column for lock icon and points of distribution deltas
        {
          id: 'rowIcons',
          visible: true,
          title: i18n.t('assortmentCanvasPage.listViewPage.tags'),
          headerClass: 'header-col icons-col',
          cellClass: 'd-flex icons-col icons-container-list-view p-0',
          width: 135,
        },
      ]);

      if (hasProductLineEnabled) {
        baseColumns.push({
          id: 'productLine',
          visible: true,
          title: i18n.t('general.productLine'),
          headerClass: `header-col ${!unifiedColumns ? 'col-short' : ''}`,
          cellClass: `entry-col pt-1 pb-1 ${!unifiedColumns ? 'col-short' : ''}`,
          width: 100,
        });
      }

      baseColumns.push({
        id: 'contentValue',
        visible: true,
        title: i18n.t('assortmentCanvasPage.listViewPage.combinedUnitOfMeasure'),
        headerClass: `header-col ${!unifiedColumns ? 'col-short' : ''}`,
        cellClass: `entry-col pt-1 pb-1 ${!unifiedColumns ? 'col-short' : ''}`,
        width: 100,
      });

      const filteredKpis = getters.filteredListViewKpiColumns.map(kpi => ({
        id: kpi,
        visible: true,
        title: getKpiTitle(kpi, rootState),
        headerClass: `header-col ${!unifiedColumns ? 'col-short' : ''}`,
        cellClass: `entry-col pt-1 pb-1 ${!unifiedColumns ? 'col-short' : ''}`,
        width: 70,
      }));

      const extraColumns = getters.listViewExtraColumns.map(column => {
        return {
          ...column,
          headerClass: `header-col extra-col ${!unifiedColumns ? 'extra-col-short' : ''} ${
            column.type !== 'kpi' ? 'attribute-col' : ''
          }`,
          cellClass: `entry-col pt-1 pb-1 extra-col attribute-col ${
            !unifiedColumns ? 'extra-col-short' : ''
          }`,
          width: 130,
        };
      });

      const transactionWeightColumn = transactionWeightEnabled
        ? [
            {
              id: 'transactionWeight',
              visible: true,
              title: i18n.t('assortmentCanvas.volumeUom'),
              headerClass: `header-col extra-col ${!unifiedColumns ? 'col-short' : ''}`,
              cellClass: `entry-col pt-1 pb-1 ${!unifiedColumns ? 'col-short' : ''}`,
              width: 70,
            },
          ]
        : [];

      const deltaTagColumn = [
        {
          id: 'deltaTag',
          visible: true,
          title: i18n.t('assortmentCanvasPage.listViewPage.optimisationDeltas'),
          headerClass: 'header-col list-view-tags-col',
          cellClass: 'entry-col pt-1 pb-1 d-flex justify-end list-view-tags-col',
          width: 135,
        },
      ];

      return baseColumns.concat(
        filteredKpis,
        extraColumns,
        transactionWeightColumn,
        deltaTagColumn
      );
    },

    // Initial state ofcanvas cann groups (CDT view) column headers
    // Need to keep in store since it defines what products to display, and that affects several other components
    // Not the same as scenarios store -> state.cannGroups, as this should only affect canvas
    initialCanvasCannGroupColumns: (state, getters, rootState, rootGetters) => {
      // This getter gives us leaf cann groups in a flat structure, which is what we need
      const scenarioCannGroups = rootGetters['scenarios/cannGroups'];
      return scenarioCannGroups.map(cannGroup => ({
        ...cannGroup,
        visible: true,
      }));
    },

    visibleListViewColumns(state) {
      return state.listViewColumns.filter(col => col.visible);
    },

    visibleCanvasCannGroups(state) {
      return state.canvasCannGroupColumns.filter(col => col.visible);
    },
  },

  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    addSelectedConstraintToCopy(state, { spacebreakId, constraintId }) {
      Vue.set(state.selectedConstraintsToCopy, constraintId, spacebreakId);
    },

    removeSelectedConstraintsToCopyConstraint(state, { constraintId }) {
      Vue.delete(state.selectedConstraintsToCopy, constraintId);
    },

    resetConstraintsCopyTarget(state) {
      state.constraintsCopyTarget = {};
      state.selectedConstraintsToCopy = {};
    },

    setSaveInProgress(state, isLoading) {
      state.saveInProgress = isLoading;
    },

    setAssortmentCanvases(state, assortmentCanvases) {
      state.assortmentCanvases = assortmentCanvases;
    },

    setWorkpackageAssortmentCanvases(state, workpackageAssortmentCanvases) {
      state.workpackageAssortmentCanvases = workpackageAssortmentCanvases;
    },

    setConstraintsCopyTarget(state, constraintsCopyTarget) {
      state.constraintsCopyTarget = constraintsCopyTarget;
    },

    setWorkpackageCheckpoints(state, checkpoints) {
      state.workpackageCheckpoints = checkpoints;
    },

    setAssortmentCheckpoints(state, checkpoints) {
      state.assortmentCheckpoints = checkpoints;
    },

    setScenarioCheckpoints(state, checkpoints) {
      state.scenarioCheckpoints = checkpoints;
    },

    setAssortmentCanvasProducts(state, assortmentCanvasProducts) {
      // Shows deltas in points of distribution for both the reference and recent optimised checkpoints
      // Returns a 0 in case of either checkpoint not existing
      state.assortmentCanvasProducts = calculateProductsDeltas(
        assortmentCanvasProducts,
        state.selectedPod,
        state.referenceCheckpointProducts
      );
    },

    updateAssortmentCanvasProductById(state, assortmentCanvasProduct) {
      const index = findIndex(state.assortmentCanvasProducts, { _id: assortmentCanvasProduct._id });
      const product = state.assortmentCanvasProducts[index];
      if (product) {
        // defensive programming is in order
        forEach(keys(assortmentCanvasProduct), acpKey =>
          // need to set the product individually instead of whole array to avoid unnecessary reactivity.
          Vue.set(product, acpKey, assortmentCanvasProduct[acpKey])
        );
        // need to calculate deltas after previous operation to ensure it does not get mistakenly overridden.
        calculateProductsDeltas(product, state.selectedPod, state.referenceCheckpointProducts);
      }
    },

    updateAssortmentCanvasProducts(state, assortmentCanvasProducts) {
      state.assortmentCanvasProducts = state.assortmentCanvasProducts.map(originalProduct => {
        const updatedProduct = assortmentCanvasProducts.find(
          product => product._id === originalProduct._id
        );
        return updatedProduct || originalProduct;
      });
      calculateProductsDeltas(
        state.assortmentCanvasProducts,
        state.selectedPod,
        state.referenceCheckpointProducts
      );
    },

    setSelectedAssortmentCanvas(state, selectedAssortmentCanvas) {
      state.selectedAssortmentCanvas = selectedAssortmentCanvas;
    },

    updateSelectedAssortmentCanvas(state, updates) {
      state.selectedAssortmentCanvas = {
        ...state.selectedAssortmentCanvas,
        ...updates,
      };
    },

    setSpacebreaks(state, spacebreaks) {
      state.spacebreaks = spacebreaks;
      const spacebreaksOrderedObject = {};
      spacebreaks.forEach((s, rank) => {
        spacebreaksOrderedObject[s._id] = { rank: rank + 1, ...s };
      });
      state.spacebreaksOrderedObject = spacebreaksOrderedObject;
    },

    setActiveSpacebreak(state, spacebreakId) {
      state.activeSpacebreak = spacebreakId;
    },

    setSelectedView(state, view) {
      state.selectedView = view;
      // reset popUpZindex on view change
      state.popUpZindex = 2;
    },

    setTileSize(state, tileSize) {
      state.selectedTileSize = tileSize;
    },

    setChildTree(state, { id, tree }) {
      state.childTrees[id] = tree;
    },

    setExpandedCdts(state, { id, depth }) {
      // This is triggered every time we expand a CDT.
      // We set its depth which is the number of CDTs deep for that component.
      // This is used to set the header height across all the components.
      state.expandedCdts = { ...state.expandedCdts, [id]: depth };
    },

    setCollapsedCdts(state, { cannGroupId, cdtId }) {
      // This is triggered whenever we collapse a CDT level.
      // This can happen at any point in the tree, from the direct parent to several levels up.
      // To handle this, we leverage our tree of children to set the depth of all collapsed elements to 0
      // Our height will then become the next highest value in the object.

      // convert Set to array, giving [cdt1, cdt2, cdt3, ...]
      const childrenOfCollapsedCdt = [...state.childTrees[cannGroupId][cdtId]];

      // Create obj like { cdt1: 0, cdt2: 0, cdt3: 0, ...}
      const zeroDepthCdts = childrenOfCollapsedCdt.reduce((a, c) => set(a, c, 0), {});
      // Merge onto existing structure, merge mutates but we're reassigning so that's fine here.
      state.expandedCdts = merge(state.expandedCdts, zeroDepthCdts);
    },

    resetExpandedCdts(state) {
      state.expandedCdts = {};
    },

    setReferenceCheckpointProducts(state, referenceCheckpointProducts) {
      state.referenceCheckpointProducts = referenceCheckpointProducts;
    },

    setReferenceCheckpoint(state, referenceCheckpoint) {
      state.referenceCheckpoint = referenceCheckpoint;
    },

    setSelectedReferenceCheckpoint(state, selectedReferenceCheckpoint) {
      state.selectedReferenceCheckpoint = selectedReferenceCheckpoint;
    },

    toggleSpaceBreakSettingsPanel(state, spacebreak) {
      if (isEqual(state.spacebreakSettings.spacebreak, spacebreak)) {
        Vue.set(state.spacebreakSettings, 'open', !state.spacebreakSettings.open);
      } else {
        Vue.set(state.spacebreakSettings, 'spacebreak', spacebreak);
        Vue.set(state.spacebreakSettings, 'open', true);
      }
    },

    setSettingsPanelSpacebreak(state, spacebreak) {
      Vue.set(state.spacebreakSettings, 'spacebreak', spacebreak);
    },

    closeSpacebreakSettingsPanel(state) {
      state.spacebreakSettings.open = false;
    },

    setConstraintEditing(state, { rowNodeId, editingBoolean }) {
      if (isEqual(state.constraintEditing.constraint.rowNodeId, rowNodeId)) {
        state.constraintEditing.constraint.open = editingBoolean;
      } else {
        state.constraintEditing.constraint.rowNodeId = rowNodeId;
        state.constraintEditing.constraint.open = editingBoolean;
      }
    },

    setConstraintRowNodeData(state, rowData) {
      state.constraintEditing.constraint.rowNodeData = rowData;
    },

    updateConstraintRowNodeData(state, { data, field }) {
      state.constraintEditing.constraint.rowNodeData = {
        ...state.constraintEditing.constraint.rowNodeData,
        [field]: data,
      };
    },

    setSelectedPod(state, selectedPod) {
      state.selectedPod = selectedPod;
    },

    incrementPopUpZindex(state) {
      state.popUpZindex += 1;
    },

    setCanvasRenderingStatus(state, status) {
      state.canvasRenderingStatus = status;
    },

    setSelectedCanvasAsOptimised(state) {
      Vue.set(state.selectedAssortmentCanvas, 'hasBeenOptimised', true);
    },

    setCanvasProductsFilters(state, canvasProductsFilters) {
      state.canvasProductsFilters = canvasProductsFilters;
    },

    setCanvasProductsHighlights(state, canvasProductsHighlights) {
      state.canvasProductsHighlights = canvasProductsHighlights;
    },

    setSelectedCanvasConstraints(state, constraints) {
      Vue.set(state.selectedAssortmentCanvas, 'constraints', constraints);
    },

    setFullScreen(state, boolean) {
      state.fullScreenExpanded = boolean;
    },

    setSidebarHeaderHeight(state, height) {
      state.sidebarHeaderHeight = height;
    },

    setStickyHeaders(state, value) {
      state.stickyHeaders = value;
    },

    setUnifiedColumns(state, { value, view }) {
      state.unifiedColumns[view] = value;
    },

    setDashboardProduct(state, product) {
      state.selectedDashboardProduct = product;
    },

    setShowDashboard(state, status) {
      state.showDashboard = status;
    },

    setProductTopPartners(state, topPartners) {
      state.selectedProductTopPartners = topPartners;
    },

    setProductTransferredSpend(state, transferredSpend) {
      state.selectedProductTransferredSpend = transferredSpend;
    },

    setShowFilterLegend(state, value) {
      state.showFilterLegend = value;
    },

    setIsProductSidebarOpen(state, value) {
      state.isProductSidebarOpen = value;
    },

    setNumberOfRowsInGrid(state, rowCount) {
      state.numberOfRowsInGrid = rowCount;
    },

    setCanvasProductsFiltersSelection(state, filters) {
      state.canvasProductsFiltersSelection = filters;
    },

    setDisplayBy(state, displayBy) {
      state.displayBy = displayBy;
    },

    setListViewColumns(state, columns) {
      state.listViewColumns = columns;
    },

    setCanvasCannGroupColumns(state, columns) {
      state.canvasCannGroupColumns = columns;
    },

    setAdditionalAttributeColumns(state, { canvasId, selection }) {
      state.additionalAttributeColumns = {
        ...state.additionalAttributeColumns,
        [canvasId]: selection,
      };
    },

    setExtraKPIsColumns(state, { canvasId, selection }) {
      state.additionalKPIsColumns = {
        ...state.additionalKPIsColumns,
        [canvasId]: selection,
      };
    },
  },

  actions: {
    async fetchCanvases({ commit, rootState }, payload = {}) {
      const defaultPayload = { where: { live: true } };
      const params = payload.params || defaultPayload;
      const scenarioId = payload.scenarioId || rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/assortment-canvases/scenario/${scenarioId}`, { params })
      );
      if (err) throw new Error(err.message);
      if (!payload.isStatelessFetch) commit('setAssortmentCanvases', response.data);
      commit('setLoading', false);
      return response.data;
    },

    async fetchCanvas({ commit, rootState, state }, { canvasId, forceSelectedCanvasUpdate } = {}) {
      if (
        !state.selectedAssortmentCanvas._id ||
        // forceSelectedCanvasUpdate - enforces selected canvas refresh when one of the nested properties of the canvas got updated but is not reflected in the state
        (canvasId === state.selectedAssortmentCanvas._id && !forceSelectedCanvasUpdate)
      ) {
        return;
      }
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/assortment-canvases/scenario/${scenarioId}/canvas/${canvasId}`)
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      commit('setSelectedAssortmentCanvas', response.data);
    },

    async fetchCanvasesForSelectedWorkpackage({ commit, rootState }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      commit('setLoading', true);
      const where = { live: true };
      const [err, res] = await to(
        axios.get(`/api/assortment-canvases/workpackage/${workpackageId}`, {
          params: { where },
        })
      );
      if (err) throw new Error(err.message);
      commit('setWorkpackageAssortmentCanvases', res.data);
      commit('setLoading', false);
    },

    async fetchConstraintsCopyTarget({ commit, state }, { checkpointId }) {
      const [err, response] = await to(
        axios.get(`/api/assortment-canvases/${checkpointId}/constraints`)
      );
      commit('resetConstraintsCopyTarget');
      if (err) throw new Error(err.message);
      state.spacebreaks.forEach((spacebreak, index) => {
        if (response.data.spacebreaks[index]) {
          response.data.spacebreaks[index].appliedTo = spacebreak;
        }
      });

      commit('setConstraintsCopyTarget', response.data);
    },
    async refreshReferenceCheckpointData({ commit, state, rootState }) {
      // Pull the current spacebreak and product key for the reference checkpoints so we can compare them.
      // Get the reference checkpoint

      const { _id: canvasId, storeClassId } = state.selectedAssortmentCanvas;
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const checkpointParams = {
        where: {
          storeClassId, // This should also take a clusterId after AOV3-622
          scenarioId,
          reference: true,
        },
        pick: [
          'checkpoint',
          'isObserved',
          'forecastParameters',
          'forecastResults',
          'jobs',
          'isNotRevertable',
        ],
        limit: 1,
      };
      commit('setLoading', true);
      const [checkpointErr, checkpointResponse] = await to(
        axios.get(`/api/assortment-canvases/${canvasId}/checkpoints`, { params: checkpointParams })
      );
      if (checkpointErr) throw new Error(checkpointErr.message);

      // If no reference checkpoint exists, set these to null.
      // This covers the case where we've unset the reference checkpoint and need to remove our prior state.
      const checkpoint = checkpointResponse.data;
      if (!checkpoint.length) {
        commit('setReferenceCheckpoint', null);
        commit('setSelectedReferenceCheckpoint', null);
        commit('setReferenceCheckpointProducts', {});
        return commit('setLoading', false);
      }

      // If a reference checkpoint exists, there will only be one. Set that as our reference and currently selected one.
      const referenceCheckpoint = checkpoint[0];
      commit('setReferenceCheckpoint', referenceCheckpoint);
      commit('setSelectedReferenceCheckpoint', referenceCheckpoint._id);

      const referenceProductParams = {
        pick: ['currentSpacebreakId', 'productKey'],
      };

      const [referenceProductsErr, referenceProductsResponse] = await to(
        axios.get(
          `/api/assortment-canvas-products/scenario/${scenarioId}/canvas/${
            referenceCheckpoint._id
          }`,
          {
            params: referenceProductParams,
          }
        )
      );
      if (referenceProductsErr) throw new Error(referenceProductsErr.message);

      // Make a map of { productKey: currentSpacebreak } so we can quickly lookup differences
      // between the current placement and that of the reference products
      const referenceProducts = referenceProductsResponse.data;
      const productKeyCurrentSpacebreakIdMap = referenceProducts.reduce(
        (a, c) => set(a, c.productKey, c.currentSpacebreakId),
        {}
      );
      commit('setReferenceCheckpointProducts', productKeyCurrentSpacebreakIdMap);
      commit('setLoading', false);
    },

    async updateAssortmentCanvas({ commit }, { canvas = {} }) {
      commit('setLoading', true);
      const canvasId = canvas._id;
      const [err, response] = await to(
        axios.patch(`/api/assortment-canvases/${canvasId}`, { canvas })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      return response.data;
    },

    async updateAssortmentCanvasConstraint(
      { commit, dispatch },
      { canvasId, constraintId, constraints }
    ) {
      commit('setLoading', true);
      const [err, response] = await to(
        axios.post(`/api/assortment-canvases/${canvasId}/constraints/${constraintId || ''}`, {
          constraints,
        })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
      return response.data;
    },

    async removeAssortmentCanvasConstraint({ commit, dispatch }, { canvasId, constraintId }) {
      commit('setLoading', true);
      const [err] = await to(
        axios.delete(`/api/assortment-canvases/${canvasId}/constraint/${constraintId}`)
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
    },

    async removeAssortmentCanvasConstraintsForSpacebreak(
      { commit, dispatch },
      { canvasId, spacebreakId }
    ) {
      commit('setLoading', true);
      const [err] = await to(
        axios.delete(`/api/assortment-canvases/${canvasId}/spacebreak/${spacebreakId}/constraint`)
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
    },

    async revertCheckpointToCanvas({ commit, dispatch }, { checkpointId }) {
      commit('setLoading', true);
      const [err, response] = await to(
        axios.post(`/api/assortment-canvases/checkpoints/${checkpointId}/revert-to`)
      );
      commit('setLoading', false);
      if (err || (response.data && response.data.errors)) {
        throw new Error(`Unable to revert Assortment Canvas: ${response.id}`);
      }

      await dispatch('fetchCurrentCanvasInformation');
      return response.data;
    },

    async deleteAssortmentCanvas({ commit }, { canvasId }) {
      commit('setLoading', true);
      const [err, { data: result }] = await to(
        axios.delete(`/api/assortment-canvases/${canvasId}`)
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      return result;
    },

    async fetchCheckpoints({ commit, state }) {
      const canvasId = state.selectedAssortmentCanvas._id;
      const params = {
        where: {
          live: false,
          scenarioId: state.selectedAssortmentCanvas.scenarioId,
        },
        sortField: 'checkpointMeta.createdDate',
        pick: [
          'checkpointMeta',
          'isObserved',
          'forecastParameters',
          'forecastResults',
          'jobs',
          'reference',
          'isNotRevertable',
        ],
      };
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/assortment-canvases/${canvasId}/checkpoints`, { params })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      commit('setAssortmentCheckpoints', response.data);
    },

    async setReferenceCheckpoint(
      { commit, state },
      { oldReferenceCheckpointId, newReferenceCheckpointId }
    ) {
      const canvasId = state.selectedAssortmentCanvas._id;
      commit('setLoading', true);
      const [err] = await to(
        axios.post(`/api/assortment-canvases/${canvasId}/checkpoints/set-reference/`, {
          params: {
            oldReferenceCheckpointId,
            newReferenceCheckpointId,
          },
        })
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
    },

    async fetchScenarioCheckpoints({ commit, rootState }, options = {}) {
      const scenarioId = options.scenarioId || rootState.scenarios.selectedScenario._id;
      const where = {
        live: false,
        excludeObserved: get(options, 'excludeObserved', true),
      };
      const params = {
        where,
        sortField: 'checkpointMeta.createdDate',
        // need 'clusterId', 'storeClassId' for scenarioCheckpointsByCanvasId
        pick: [
          '_id',
          'checkpointMeta',
          'isObserved',
          'clusterId',
          'storeClassId',
          'hasBeenForecast',
        ],
      };
      commit('setLoading', true);
      const [err, res] = await to(
        axios.get(`/api/scenarios/${scenarioId}/checkpoints`, { params })
      );
      if (err) throw new Error(err.message);
      if (!options.isStatelessFetch) commit('setScenarioCheckpoints', res.data);
      commit('setLoading', false);
      return res.data;
    },

    async fetchCheckpointsForSelectedWorkpackage({ commit, rootState }, options = {}) {
      const workpackageId = options.workpackageId || rootState.workpackages.selectedWorkpackage._id;
      commit('setLoading', true);
      const params = {
        where: { live: false, excludeObserved: true },
        pick: ['scenarioId', 'clusterId', 'storeClassId', 'checkpointMeta'].concat(
          options.pickExtra
        ),
      };
      const [err, res] = await to(
        axios.get(`/api/workpackages/${workpackageId}/checkpoints`, { params })
      );
      if (err) throw new Error(err.message);
      commit('setLoading', false);
      if (!options.shouldCommit) return res.data;
      commit('setWorkpackageCheckpoints', res.data);
    },

    async addCheckpoint({ commit, state }) {
      commit('setLoading', true);
      const canvasId = state.selectedAssortmentCanvas._id;
      const [err] = await to(axios.post(`/api/assortment-canvases/${canvasId}/checkpoint`));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
    },

    async updateCheckpoint({ commit }, { checkpointId, updates }) {
      commit('setLoading', true);
      const [err] = await to(axios.patch(`/api/assortment-canvases/${checkpointId}/meta`, updates));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
    },

    async deleteCheckpoint({ commit }, { canvasId }) {
      commit('setLoading', true);
      const [err, { data: result }] = await to(
        axios.delete(`/api/assortment-canvases/${canvasId}/checkpoint`)
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      return result;
    },

    async runForecasterJob({ commit, dispatch }, payload) {
      const { checkpointId, scenarioId } = payload;
      commit('setLoading', true);
      const [err, response] = await to(
        axios.post(`/api/assortment-canvases/scenario/${scenarioId}/${checkpointId}/forecaster`, {})
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      handleErrorMessages({ response, dispatch });
    },

    async runAssortmentOptimisation({ commit, dispatch }, payload) {
      commit('setLoading', true);
      const [err, response] = await to(
        axios.post('/api/assortment-canvases/canvas-optimisation', payload)
      );
      commit('setLoading', false);
      handleErrorMessages({ response, dispatch });
      if (get(response, 'data.messageType') === 'JobValidation') {
        return get(response, 'data.data');
      }
      if (err) throw new Error(get(err, 'message'));
    },

    async fetchCanvasProducts({ rootState, commit }, canvasId) {
      commit('setLoading', true);
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const [err, { data: assortmentCanvasProducts } = {}] = await to(
        axios.get(`/api/assortment-canvas-products/scenario/${scenarioId}/canvas/${canvasId}/all`)
      );
      if (err) throw new Error(err.message);
      commit('setAssortmentCanvasProducts', assortmentCanvasProducts);
      commit('setLoading', false);
    },

    changeLockType({ dispatch }, { product, isLocked }) {
      const lockType = isLocked ? lockTypes.unlocked : lockTypes.locked;
      dispatch('updateCanvasProduct', {
        // locallyUpdatedProduct is meant to be used before the changes are done on the server,
        // to avoid product dragging flickering
        locallyUpdatedProduct: { ...product, lockType },
        _id: product._id,
        lockType,
      });
    },

    async updateCanvasProduct({ commit }, updates = {}) {
      commit('setSaveInProgress', true);
      const { locallyUpdatedProduct, ...patchUpdates } = updates;
      // ensure timely update of product tile on the client side first
      commit('updateAssortmentCanvasProductById', locallyUpdatedProduct);

      // ensure update of product tile on the server side for persistence
      const [err, response] = await to(
        axios.patch(`/api/assortment-canvas-products/${patchUpdates._id}`, patchUpdates)
      );
      if (err) throw new Error(err.message);
      commit('setSaveInProgress', false);
      commit('updateAssortmentCanvasProductById', response.data.canvasProduct);
      commit('setSelectedCanvasConstraints', response.data.constraints);
    },

    async updateCanvasProducts(
      { commit, dispatch },
      {
        productsBeforeUpdates,
        locallyUpdatedProducts,
        updates,
        successMessage = 'actions.saveSuccess',
        failedMessage = null,
        showToastMessages = true,
      }
    ) {
      // show error if there is nothing to update
      if (!size(locallyUpdatedProducts) && !size(updates)) {
        dispatch('snackbar/showError', i18n.t(failedMessage), { root: true });
        return;
      }
      commit('setSaveInProgress', true);
      // ensure timely update of product tiles on the client side first
      commit('updateAssortmentCanvasProducts', locallyUpdatedProducts);

      // ensure update of product tiles on the server side for persistence
      const [err, response] = await to(axios.patch('/api/assortment-canvas-products', updates));
      if (err) {
        const key = get(err, 'response.data.messageKey', err.message);
        const message = i18n.t(key, get(err, 'response.data.messageParams', failedMessage));
        if (showToastMessages) {
          dispatch('snackbar/showError', message, { root: true });
        }
        // reset local state if error in updating products
        commit('updateAssortmentCanvasProducts', productsBeforeUpdates);
        commit('setSaveInProgress', false);
        return Promise.reject(message);
      }
      commit('setSaveInProgress', false);
      commit('setSelectedCanvasConstraints', response.data.constraints);
      if (showToastMessages) {
        dispatch('snackbar/showSuccess', i18n.t(successMessage), { root: true });
      }
    },

    async setSelectedAssortmentCanvas({ commit }, canvas) {
      commit('setSelectedAssortmentCanvas', canvas);
    },

    async runCanvasGeneration({ commit, dispatch }, payload) {
      const { scenarioId, selectedCanvases } = payload;
      commit('setLoading', true);
      const url = `/api/assortment-canvases/scenario/${scenarioId}/canvas-generation`;
      const [err, response] = await to(axios.post(url, selectedCanvases));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      handleErrorMessages({ response, dispatch });
    },

    canvasGenerationComplete({ dispatch, rootState }, { message }) {
      const selectedScenario = rootState.scenarios.selectedScenario;
      if (message.scenarioId === selectedScenario._id) {
        dispatch('fetchCanvases', { params: { where: { live: true } } });
      }
      const content = i18n.t('notifications.canvasGeneration.finished');
      dispatch('snackbar/showSuccess', content, { root: true });
    },

    canvasGenerationFailed({ dispatch }) {
      const content = i18n.t('notifications.canvasGeneration.failed');
      dispatch('snackbar/showError', content, { root: true });
    },

    changeCanvasRenderingStatus({ commit }, status) {
      commit('setCanvasRenderingStatus', status);
    },

    refreshProductDeltas({ commit, state }) {
      commit('setAssortmentCanvasProducts', state.assortmentCanvasProducts);
    },

    async fetchProductTopPartners({ commit, rootState, state }, { productKey, cannGroupId } = {}) {
      commit('setProductTopPartners', []);
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const clusterId = state.selectedAssortmentCanvas.clusterId;
      const storeClassId = state.selectedAssortmentCanvas.storeClassId;
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(
          `/api/assortment-canvas-products/scenario/${scenarioId}/cluster/${clusterId}/store-class/${storeClassId}/cann-group/${cannGroupId}/top-partners/${productKey}`
        )
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      commit('setProductTopPartners', response.data);
    },

    async fetchProductTransferredSpend({ commit, rootState, state }, { productKey } = {}) {
      commit('setProductTransferredSpend', {});
      const scenarioId = rootState.scenarios.selectedScenario._id;
      const canvasId = state.selectedAssortmentCanvas._id;
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(
          `/api/assortment-canvas-products/scenario/${scenarioId}/canvas/${canvasId}/transferred-spend/${productKey}`
        )
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      commit('setProductTransferredSpend', response.data);
    },

    async updateConstraintsRanking({ commit, dispatch }, { canvasId, constraints, spacebreakId }) {
      commit('setLoading', true);
      const [err] = await to(
        axios.patch(
          `/api/assortment-canvases/${canvasId}/spacebreak/${spacebreakId}/constraints/order`,
          {
            constraints,
          }
        )
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
    },

    async fetchProductDistribution({ commit, rootState }, { productKey, canvases } = {}) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(
          `/api/assortment-canvas-products/scenario/${scenarioId}/product-distribution/${productKey}`,
          { params: canvases }
        )
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      return response.data;
    },

    async fetchCurrentCanvasInformation({ dispatch, state, commit }) {
      if (isEmpty(state.selectedAssortmentCanvas)) return;
      await Promise.all([
        dispatch('refreshReferenceCheckpointData'),
        dispatch('fetchCanvas', {
          canvasId: state.selectedAssortmentCanvas._id,
          forceSelectedCanvasUpdate: true,
        }),
      ]);

      // after refreshing the checkpoints products, we pull the products and have their deltas calculated
      await dispatch('fetchCanvasProducts', state.selectedAssortmentCanvas._id);
      commit('setCanvasRenderingStatus', false);
    },

    async toggleSpacebreakLockForSelectedCanvas(
      { dispatch, state, getters, commit },
      { spacebreakId, isLocked }
    ) {
      const spacebreakRank = state.spacebreaksOrderedObject[spacebreakId].rank;
      const updatedCanvas = {
        _id: getters.selectedCanvas._id,
        spacebreaks: getters.selectedCanvas.spacebreaks.map(sb => {
          const currentSpacebreakRank = state.spacebreaksOrderedObject[sb._id].rank;
          if (currentSpacebreakRank > spacebreakRank) return sb;
          if (currentSpacebreakRank < spacebreakRank && !isLocked) return sb;
          // Otherwise we are locking a spacebreak.
          // This means the matching spacebreak and the spacebreaks with a rank smaller than it should be locked.
          return {
            ...sb,
            isLocked,
          };
        }),
      };
      const canvas = await dispatch('updateAssortmentCanvas', { canvas: updatedCanvas });
      commit('updateSelectedAssortmentCanvas', { spacebreaks: canvas.spacebreaks });
    },

    async compareCheckpointAndCanvas({ commit }, { checkpointId, canvasId }) {
      commit('setLoading', true);
      const [err, response] = await to(
        axios.get(`/api/assortment-canvases/from/${checkpointId}/to/${canvasId}/copy-eligible`)
      );
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      return response.data;
    },

    async copyCheckpointDecisionsToCanvas(
      { commit, dispatch, state, rootGetters },
      {
        checkpointId,
        canvasId,
        successMessage = 'messages.copyDecisionsSuccess',
        failedMessage = 'messages.copyDecisionsFailed',
      }
    ) {
      commit('setSaveInProgress', true);
      const dateFormats = rootGetters['context/getDateFormats'];
      try {
        await jobApi.runFunction('copy-checkpoint-decisions-to-canvas', {
          checkpoint_id: checkpointId,
          canvas_id: canvasId,
          localisations: {
            dateFormat: get(dateFormats, 'longAnalyticsWithTime'),
            translations: {
              preCopyDecisionsCheckpoint: i18n.t('assortmentCanvas.preCopyDecisionsCheckpoint'),
            },
          },
        });
        if (state.selectedAssortmentCanvas._id === canvasId) {
          commit('setCanvasRenderingStatus', true);
          await dispatch('fetchCurrentCanvasInformation');
          commit('setCanvasRenderingStatus', false);
        }
        commit('setSaveInProgress', false);
        dispatch('snackbar/showSuccess', i18n.t(successMessage), { root: true });
      } catch (err) {
        const key = get(err, 'response.data.messageKey', err.message);
        const message = i18n.t(key, get(err, 'response.data.messageParams', failedMessage));
        dispatch('snackbar/showError', i18n.t(failedMessage), { root: true });
        commit('setSaveInProgress', false);
        throw new Error(message);
      }
    },

    initializeListViewColumns({ commit, getters, rootState }) {
      // First, try to load the column state from local storage
      const scenarioKey = rootState.scenarios.selectedScenario._id;
      const savedColumnState = gridPreferences.loadColumnState(
        `${LIST_VIEW_COLUMN_STORAGE_PREFIX}_${scenarioKey}`
      );
      // Get the id of the scenario that got its canvas list view state saved most recently
      const lastStateSavedScenario = gridPreferences.loadColumnState(
        `${LIST_VIEW_LAST_STATE_SAVED_SCENARIO_PREFIX}_0`
      );

      // Get the initial columns from the getter
      const initialColumns = getters.initialListViewColumns;

      let updatedColumns;

      if (savedColumnState) {
        // If a saved column state exists, merge it with the initial columns
        updatedColumns = gridPreferences.mergeGenericColumnStates(
          savedColumnState,
          initialColumns,
          'title'
        );
      } else if (lastStateSavedScenario) {
        // If a saved column state doesn't exist, find latest saved state and merge it with the initial columns
        const lastSavedColumnState = gridPreferences.loadColumnState(
          `${LIST_VIEW_COLUMN_STORAGE_PREFIX}_${lastStateSavedScenario}`
        );
        updatedColumns = gridPreferences.mergeGenericColumnStates(
          lastSavedColumnState,
          initialColumns,
          'title'
        );
      } else {
        // Otherwise, use the initial columns from the getter
        updatedColumns = initialColumns;
      }

      commit('setListViewColumns', updatedColumns);
    },

    initializeCanvasCannGroupColumns({ commit, getters, rootState }) {
      // First, try to load the column state from local storage
      const scenarioKey = rootState.scenarios.selectedScenario._id;
      const savedColumnState = gridPreferences.loadColumnState(
        `${CANN_GROUPS_COLUMN_STORAGE_PREFIX}_${scenarioKey}`
      );

      // Get the initial columns from the getter
      const initialColumns = getters.initialCanvasCannGroupColumns;

      let updatedColumns;

      if (savedColumnState) {
        // If a saved column state exists, merge it with the initial columns on 'key' field
        updatedColumns = gridPreferences.mergeGenericColumnStates(
          savedColumnState,
          initialColumns,
          'key'
        );
      } else {
        // Otherwise, use the initial columns from the getter
        updatedColumns = initialColumns;
      }

      commit('setCanvasCannGroupColumns', updatedColumns);
    },

    reorderListViewColumns({ commit, state, rootState }, newOrder) {
      const scenarioKey = rootState.scenarios.selectedScenario._id;

      // Create a new array with all columns (visible and hidden) reordered
      const reorderedColumns = newOrder.map(newCol => {
        return state.listViewColumns.find(col => col.id === newCol.id);
      });

      // Commit the reordered columns to the state
      commit('setListViewColumns', reorderedColumns);

      // Save the new column state
      gridPreferences.saveColumnState(
        LIST_VIEW_COLUMN_STORAGE_PREFIX,
        scenarioKey,
        reorderedColumns
      );
      // update scenario which was modified the last,
      // it will be used to initialise the view for new scenarios (so we store scenarioKey as data)
      gridPreferences.saveColumnState(LIST_VIEW_LAST_STATE_SAVED_SCENARIO_PREFIX, 0, scenarioKey);
    },

    // TODO refactor into single method
    reorderCanvasCannGroupColumns({ commit, state, rootState }, newOrder) {
      const scenarioKey = rootState.scenarios.selectedScenario._id;

      // Create a new array with all columns (visible and hidden) reordered
      const reorderedColumns = newOrder.map((newCol, idx) => {
        const foundCol = state.canvasCannGroupColumns.find(col => col.key === newCol.key);
        return { ...foundCol, order: idx };
      });

      // Commit the reordered columns to the state
      commit('setCanvasCannGroupColumns', reorderedColumns);

      // Save the new column state
      gridPreferences.saveColumnState(
        CANN_GROUPS_COLUMN_STORAGE_PREFIX,
        scenarioKey,
        reorderedColumns
      );
    },

    toggleListViewColumnVisibility({ commit, state, rootState }, selectedColumn) {
      const scenarioKey = rootState.scenarios.selectedScenario._id;

      const updatedColumns = state.listViewColumns.map(col => {
        if (col.id === selectedColumn.id) {
          return { ...col, visible: !col.visible };
        }
        return col;
      });
      commit('setListViewColumns', updatedColumns);
      // Save the new column state
      gridPreferences.saveColumnState(LIST_VIEW_COLUMN_STORAGE_PREFIX, scenarioKey, updatedColumns);
      gridPreferences.saveColumnState(LIST_VIEW_LAST_STATE_SAVED_SCENARIO_PREFIX, 0, scenarioKey);
    },

    toggleCanvasCannGroupVisibility({ commit, state, rootState }, selectedCannGroup) {
      const scenarioKey = rootState.scenarios.selectedScenario._id;

      const updatedColumns = state.canvasCannGroupColumns.map(col => {
        if (col.key === selectedCannGroup.key) {
          return { ...col, visible: !col.visible };
        }
        return col;
      });

      commit('setCanvasCannGroupColumns', updatedColumns);
      // Save the new column state
      gridPreferences.saveColumnState(
        CANN_GROUPS_COLUMN_STORAGE_PREFIX,
        scenarioKey,
        updatedColumns
      );
    },
  },
};

export default store;
