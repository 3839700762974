import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { class: { "dialog-card": true, "no-border": _vm.isPopup } },
    [
      _vm._t("header", function () {
        return [
          _vm.showFullHeaders
            ? _c(
                "header",
                {
                  class: { [_vm.headerClass]: true, "no-border": _vm.isPopup },
                },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { staticClass: "pl-0 pt-3", attrs: { cols: "10" } },
                        [
                          _c(
                            "h3",
                            { staticClass: "dialog-card__title" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.title) +
                                  "\n            "
                              ),
                              _vm.docPath
                                ? _c("docs-link", {
                                    attrs: {
                                      link: `toolguide/${_vm.docPath}.html`,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._t("title"),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        VCol,
                        {
                          staticClass: "pr-0 pt-3",
                          attrs: { cols: "2 text-right" },
                        },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("close")
                                },
                              },
                            },
                            [
                              _c(
                                VIcon,
                                {
                                  staticClass: "dialog-card__icon",
                                  attrs: { size: "2rem" },
                                },
                                [_vm._v("$cancel")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "header",
                [
                  _c(
                    VRow,
                    { staticClass: "pl-3 pt-3" },
                    [
                      _c("span", { staticClass: "info-note" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                      _vm._v(" "),
                      _c("assortment-tooltip", {
                        attrs: {
                          title: _vm.$t("tooltips.productScope.title"),
                          "tooltip-sections": _vm.productScopeTooltipSections,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ]
      }),
      _vm._v(" "),
      _c(
        "section",
        { class: { "dialog-card__content": true, [_vm.contentClass]: true } },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c(
        "footer",
        { class: { [_vm.footerClass]: true, "no-border": _vm.isPopup } },
        [_vm._t("footer")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }