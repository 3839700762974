import i18n from '../../vue-i18n';

export const name = 'snackbar';

const defaultErrorMessageColour = '#d7534e';
const defaultWarningMessageColour = '#CA7C00';

const store = {
  namespaced: true,

  state: {
    content: '',
    color: '',
  },

  mutations: {
    showSnackbar(state, { color, content }) {
      state.content = content;
      state.color = color;
    },
  },

  actions: {
    showSnackbar({ commit }, { color = defaultErrorMessageColour, content }) {
      commit('showSnackbar', { color, content });
    },
    showSuccess({ commit }, content = '') {
      commit('showSnackbar', { color: 'success', content });
    },
    showWarning({ commit }, content) {
      commit('showSnackbar', {
        color: defaultWarningMessageColour,
        content: content || i18n.tc('errors.generalErrorMessage'),
      });
    },
    showError({ commit }, content = '') {
      commit('showSnackbar', { color: defaultErrorMessageColour, content });
    },
  },
};

export default store;
