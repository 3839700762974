import axios from 'axios';
import to from 'await-to-js';
import Vue from 'vue';
import { findIndex, map } from 'lodash';
import i18n from '../../vue-i18n';

const store = {
  namespaced: true,

  state: {
    scenarioSimpleSwapRuns: [],
    loading: false,
  },

  getters: {
    scenarioSimpleSwapRuns: state => state.scenarioSimpleSwapRuns,
    getSimpleSwapRunDescriptions(state) {
      return map(state.scenarioSimpleSwapRuns, sr => sr.runDescription);
    },
  },

  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setScenarioSimpleSwapRuns(state, val) {
      state.scenarioSimpleSwapRuns = val;
    },

    addScenarioSimpleSwapRun(state, run) {
      state.scenarioSimpleSwapRuns.push(run);
    },
    updateSimpleSwapRun(state, updatedRun) {
      const targetIx = findIndex(state.scenarioSimpleSwapRuns, run => run._id === updatedRun._id);
      Vue.set(state.scenarioSimpleSwapRuns, targetIx, updatedRun);
    },
  },

  actions: {
    async fetchSimpleSwapRuns({ commit, rootState }) {
      const scenarioId = rootState.scenarios.selectedScenario._id;
      commit('setLoading', true);
      const [err, response] = await to(axios.get(`/api/scenarios/${scenarioId}/simple-swap-runs`));
      commit('setLoading', false);
      if (err) throw new Error(err.message);
      commit('setScenarioSimpleSwapRuns', response.data);
    },

    async fetchSimpleSwapRun({ rootState }, { _id }) {
      const [err, response] = await to(
        axios.get(
          `/api/scenarios/${rootState.scenarios.selectedScenario._id}/simple-swap-runs/${_id}`
        )
      );
      if (err) throw new Error(err.message);
      return response.data;
    },

    async addSimpleSwapRun({ commit, dispatch }, payload) {
      const { scenarioId } = payload;
      commit('setLoading', true);
      const [err, response] = await to(
        axios.post(`/api/scenarios/${scenarioId}/simple-swap-runs`, payload)
      );
      if (err) throw new Error(err.message);
      dispatch('snackbar/showSuccess', i18n.t('actions.saveSuccess'), { root: true });
      commit('addScenarioSimpleSwapRun', response.data);
      commit('setLoading', false);
    },

    async afterSimpleSwapsComplete({ commit, dispatch }, { message }) {
      const updatedRun = await dispatch('fetchSimpleSwapRun', {
        _id: message.jobArgs.simple_swaps_run_id,
      });
      commit('updateSimpleSwapRun', updatedRun);

      const content = i18n.t('notifications.simpleSwaps.finished', {
        name: updatedRun.runDescription,
      });
      dispatch('snackbar/showSuccess', content, { root: true });
    },

    async afterSimpleSwapsFailed({ commit, dispatch }, { message }) {
      const updatedRun = await dispatch('fetchSimpleSwapRun', {
        _id: message.jobArgs.simple_swaps_run_id,
      });
      commit('updateSimpleSwapRun', updatedRun);

      const content = i18n.t('notifications.simpleSwaps.failed', {
        name: updatedRun.runDescription,
      });
      dispatch('snackbar/showError', content, { root: true });
    },
  },
};

export default store;
