import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "assortment__container py-1" },
    [
      _c(VRow, { staticClass: "assortment-header mt-1 mb-1" }, [
        _vm._v(_vm._s(_vm.$t("scenarioAssortmentPanel.settings"))),
      ]),
      _vm._v(" "),
      _c(VDivider, { staticClass: "my-1", attrs: { horizontal: "" } }),
      _vm._v(" "),
      _c(
        VRow,
        { staticClass: "my-1" },
        [
          _c("scenario-optimiser-form", {
            ref: "optimiserForm",
            attrs: {
              "read-only": !_vm.hasPermission(
                _vm.userPermissions.canChangeOptimiserSettings
              ),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(VRow, { staticClass: "assortment-header mt-1 mb-1" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("scenarioAssortmentPanel.assortments")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(VDivider, { staticClass: "my-1", attrs: { horizontal: "" } }),
      _vm._v(" "),
      _vm.isRenderingCanvases
        ? _c("progress-bar", {
            staticClass: "pt-5",
            attrs: {
              message: _vm.isClusteredSwitchingJobRunning
                ? _vm.$t("messages.clusteredSwitchingRunning")
                : _vm.$t("messages.canvasesRendering"),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoadingCanvases
        ? _c("ag-grid-vue", {
            staticClass: "ag-theme-custom",
            staticStyle: { width: "100%" },
            attrs: {
              "dom-layout": "autoHeight",
              "row-data": _vm.assortments,
              "column-defs": _vm.headers,
              "grid-options": _vm.gridOptions,
              "stop-editing-when-cells-loses-focus": true,
            },
            on: {
              "grid-ready": _vm.onGridReady,
              "first-data-rendered": _vm.onFirstDataRendered,
              "selection-changed": _vm.setSelectedAssortments,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isCanvasGenerationJobRunning
        ? _c("progress-bar", {
            staticClass: "pb-10",
            attrs: {
              percentage: _vm.jobStatuses.canvasGeneration.progress,
              message: _vm.$t("messages.inProgress"),
              status: _vm.jobStatuses.canvasGeneration.status,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isClScSwitchingJobRunning
        ? _c("progress-bar", {
            staticClass: "pb-10",
            attrs: {
              percentage:
                _vm.jobStatuses.clScSwitchingMatricesCalculation.progress,
              message: _vm.$t("messages.inProgress"),
              status: _vm.jobStatuses.clScSwitchingMatricesCalculation.status,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isLoadingCanvases
        ? _c(
            VRow,
            [
              _c(VCol, { staticClass: "d-flex flex-row justify-end" }, [
                _vm.hasClusteredAssortments
                  ? _c("div", { staticClass: "d-flex flex-grow-1" }, [
                      _vm.hasPermission(_vm.userPermissions.canFetchCanvases)
                        ? _c(
                            "div",
                            { staticClass: "d-flex mr-3" },
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    outlined: "",
                                    disabled: !_vm.isRowSelectable,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectRows(
                                        "clusterId",
                                        null,
                                        true
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$tkey("selectAllUnclustered")
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasPermission(_vm.userPermissions.canFetchCanvases)
                        ? _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    outlined: "",
                                    disabled: !_vm.isRowSelectable,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectRows("clusterId", null)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.$tkey("selectAllClustered")) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex mr-3" },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            "data-id-e2e": "btnOptimiseAll",
                            "data-dd-action-name": "OptimiseAllCanvasesBtn",
                            disabled: _vm.isOptimiseAllDisabled,
                            outlined: "",
                          },
                          on: {
                            click: () => _vm.$refs.optimiseAllModal.open(),
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tkey("optimiseAllButton")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isCanvasConfigInvalidForOptimisation
                        ? _c("error-triangle", {
                            staticClass: "ml-2",
                            attrs: {
                              "data-id-e2e": "errorTriangleOptimiseAll",
                              arrow: false,
                              errors: {
                                [_vm.$t(
                                  "assortmentCanvasPage.errorMessages.setOptimiserSettings"
                                )]: !_vm.isCanvasConfigInvalidForOptimisation,
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex mr-3" },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            "data-id-e2e": "btnFetchCanvases",
                            outlined: "",
                            disabled: _vm.isFetchCanvasesDisabled,
                          },
                          on: { click: _vm.runCanvasGenerationJob },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("scenarioAssortmentPanel.fetchCanvases")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.cannGroupMissingCDT
                        ? _c("error-triangle", {
                            staticClass: "ml-2",
                            attrs: {
                              "data-id-e2e": "errorTriangleFetchCanvases",
                              arrow: false,
                              errors: {
                                [_vm.$t(
                                  "assortmentCanvasPage.errorMessages.cannGroupMissingCDT"
                                )]: !_vm.cannGroupMissingCDT,
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showReporting
                    ? _c(
                        "div",
                        { staticClass: "d-flexmr-3" },
                        [
                          _c(
                            VBtn,
                            {
                              attrs: {
                                "data-id-e2e": "btnViewReport",
                                outlined: "",
                                disabled:
                                  _vm.isReportingDisabled ||
                                  _vm.isClusteredSwitchingJobRunning,
                                to: _vm.reportPageRoute,
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("scenarioAssortmentPanel.viewReport")
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("main-dialog", {
        ref: "optimiseAllModal",
        attrs: { title: _vm.$tkey("optimiseAllModal.title") },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "mt-5 mb-2 mx-5 text-justify" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$tkey("optimiseAllModal.description"))),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex align-center mb-5" },
                  [
                    _c(VCheckbox, {
                      staticClass: "rtls-checkbox mx-2",
                      attrs: { "input-value": _vm.createCheckpoint },
                      on: {
                        change: function ($event) {
                          _vm.createCheckpoint = $event
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "mr-4" }, [
                      _vm._v(
                        _vm._s(_vm.$tkey("optimiseAllModal.createCheckpoint"))
                      ),
                    ]),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "actions",
            fn: function ({ cancel: close }) {
              return [
                _c(
                  VRow,
                  [
                    _c(
                      VCol,
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          VBtn,
                          {
                            attrs: { action: "" },
                            on: {
                              click: function ($event) {
                                ;[_vm.initOptimisation(), close()]
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.confirm")) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          VBtn,
                          {
                            staticClass: "ml-2",
                            on: {
                              click: function ($event) {
                                return close()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.cancel")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("unsaved-data-modal", {
        ref: "unsavedDataModal",
        attrs: { value: _vm.isUnsavedDataModalOpen },
        on: {
          cancel: _vm.closeUnsavedDataModal,
          confirm: _vm.closeUnsavedDataModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }