<template>
  <div class="d-flex justify-end">
    <v-btn v-if="!editingMode" :disabled="isEditingDisabled" :ripple="false" icon>
      <v-icon :size="24" @click="edit">$edit</v-icon>
    </v-btn>
    <v-btn
      v-if="!editingMode"
      :disabled="!canRemoveConstraint || isEditingDisabled"
      :ripple="false"
      class="ml-2 delete-button"
      icon
    >
      <v-icon @click="removeConstraint">$trash</v-icon>
    </v-btn>
    <v-btn
      v-if="editingMode && isSelected"
      text
      depressed
      :ripple="false"
      class="text-outline cancel"
      @click="cancel"
    >
      {{ $t('actions.cancel') }}
    </v-btn>
    <v-btn
      v-if="editingMode && isSelected"
      :ripple="false"
      :disabled="!editingMode || errorsShouldDisableSave"
      action
      depressed
      class="ml-2"
      @mouseup="save"
    >
      {{ $t('actions.save') }}
    </v-btn>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { isEqual, cloneDeep, filter, values, get } from 'lodash';
import { assortmentCanvas } from '@enums/client-events';
import { StoreNamespaces } from '../../../store/constants';

export default Vue.extend({
  name: 'cellActionIconsRenderer',
  data() {
    return {
      rowHeight: null,
      errorsOccurred: {},
    };
  },
  computed: {
    ...mapState(StoreNamespaces.scenarios, ['selectedScenario']),
    ...mapState(StoreNamespaces.assortmentCanvas, ['constraintEditing', 'spacebreakSettings']),
    ...mapGetters(StoreNamespaces.assortmentCanvas, ['selectedCanvas']),

    editingMode() {
      return (
        this.constraintEditing.constraint.open &&
        isEqual(this.params.node.id, this.constraintEditing.constraint.rowNodeId)
      );
    },
    isSelected() {
      return this.params.node.isSelected();
    },
    errorsShouldDisableSave() {
      const errorValues = values(this.errorsOccurred);

      const totalErrors = filter(errorValues, v => v === false);
      // if the only error that happened is the isUnique one, and that was from the global panel, the save is enabled
      if (this.errorsOccurred.isUnique === false && totalErrors.length === 1) {
        return !this.isGlobalSpacebreak;
      }

      return totalErrors.length > 0;
    },
    canRemoveConstraint() {
      // User cannot delete global constraints from spacebreak constraint
      const constraintEntry = this.params.node.data;
      return constraintEntry.spacebreakId === get(this.spacebreakSettings, 'spacebreak._id', null);
    },
    isGlobalSpacebreak() {
      return get(this.spacebreakSettings, 'spacebreak._id', null) === null;
    },
    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditAssortmentCanvas);
    },
  },
  events: {
    [assortmentCanvas.constraintsValidationOccurred](payload) {
      this.errorsOccurred = { ...this.errorsOccurred, ...payload };
    },
  },
  methods: {
    ...mapMutations(StoreNamespaces.assortmentCanvas, [
      'setConstraintEditing',
      'setConstraintRowNodeData',
    ]),
    ...mapActions(StoreNamespaces.assortmentCanvas, [
      'updateAssortmentCanvasConstraint',
      'removeAssortmentCanvasConstraint',
      'fetchCanvas',
    ]),
    async removeConstraint() {
      await this.removeAssortmentCanvasConstraint({
        canvasId: this.selectedCanvas._id,
        constraintId: this.params.node.data.constraintId,
      });
      this.params.api.applyTransaction({ remove: [this.params.node.data] });
      await this.fetchCanvas({
        canvasId: this.selectedCanvas._id,
        forceSelectedCanvasUpdate: true,
      });
    },
    edit() {
      const rowData = [];
      this.params.api.forEachNode(node => {
        rowData.push(node.data);
      });
      const unSavedRowData = rowData.filter(x => !x.constraintId);
      if (unSavedRowData.length) {
        this.params.api.applyTransaction({ remove: unSavedRowData });
      }
      this.setConstraintEditing({
        rowNodeId: this.params.node.id,
        editingBoolean: true,
      });
      this.params.node.setSelected(true);
      const cloned = cloneDeep(this.params.node.data);

      this.setConstraintRowNodeData(cloned);
      this.params.api.redrawRows({ rowNodes: [this.params.node.data] });
    },
    cancel() {
      this.setConstraintEditing({
        rowNodeId: this.params.node.id,
        editingBoolean: false,
      });
      this.params.node.setSelected(false);
      this.params.api.redrawRows({ rowNodes: [this.params.node.data] });
    },
    async save() {
      const newRowData = this.params.node.data;
      newRowData.parameter = this.constraintEditing.constraint.rowNodeData.parameter;
      newRowData.type = this.constraintEditing.constraint.rowNodeData.type;
      newRowData.value = this.constraintEditing.constraint.rowNodeData.value;
      newRowData.constraintDescription = this.constraintEditing.constraint.rowNodeData.constraintDescription;

      const currentSpacebreakId = get(this.spacebreakSettings.spacebreak, '_id', null);
      const currentType = cloneDeep(newRowData.type);
      const isGlobalConstraint = newRowData.spacebreakId === null;

      const attributeFilters = newRowData.constraintDescription;
      const payload = {
        canvasId: this.selectedCanvas._id,
        constraintId: newRowData.constraintId,
        constraints: [
          {
            spacebreakId: isGlobalConstraint ? null : currentSpacebreakId,
            attributeFilters,
            priority: newRowData.priority,
            limits: newRowData.value,
            type: currentType,
            createdDate: newRowData.createdDate,
          },
        ],
      };
      const [result] = await this.updateAssortmentCanvasConstraint(payload);
      newRowData.constraintId = result.id;
      this.params.api.applyTransaction({ update: [newRowData] });
      this.params.node.setSelected(false);
      this.setConstraintEditing({
        editingBoolean: false,
      });
      await this.fetchCanvas({
        canvasId: this.selectedCanvas._id,
        forceSelectedCanvasUpdate: true,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.v-btn {
  padding-bottom: 0 !important;
}
</style>
