import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: {
        id: "switchingModellingMatricesModal",
        "data-id-e2e": "switchingModellingMatricesModalDialog",
        value: _vm.value,
        fullscreen: "",
        "content-class": "switching-modelling-matrices-modal",
        attach: ".switching-modelling-page",
      },
      on: {
        "click:outside": _vm.closeModal,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeModal.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "dialog-card",
        {
          attrs: { title: _vm.$tkey("title") },
          on: { close: _vm.closeModal },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    VBtn,
                    {
                      attrs: { primary: "", depressed: "" },
                      on: { click: _vm.onExport },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("actions.export")) +
                          "\n        "
                      ),
                      _c(VIcon, { attrs: { right: "" } }, [
                        _vm._v("import_export"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "switching-modelling-matrices-container" }, [
            _c(
              "div",
              { staticClass: "container-block flex-grow-1" },
              [
                _vm.isLoading
                  ? _c("progress-bar")
                  : _vm.gridData
                  ? _c(
                      "div",
                      [
                        _c(
                          VRow,
                          { staticClass: "d-flex align-center" },
                          [
                            _c(VCol, { attrs: { cols: "3" } }, [
                              _c("div", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "switchingModellingPage.switchingMetricsBreakdownModal.cannGroup"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.gridData.cannGroupName)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "switchingModellingPage.switchingMetricsBreakdownModal.cluster"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.gridData.clusterName)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "switchingModellingPage.switchingMetricsBreakdownModal.switchingAnalysisMethodology"
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(_vm.modalData.methodologyInPlace)
                                      ) +
                                      " " +
                                      _vm._s(_vm.modalData.similarityBias) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              VCol,
                              [
                                _c(
                                  VRadioGroup,
                                  {
                                    attrs: { row: "" },
                                    on: { change: _vm.drawGraph },
                                    model: {
                                      value: _vm.selectedSwitchingView,
                                      callback: function ($$v) {
                                        _vm.selectedSwitchingView = $$v
                                      },
                                      expression: "selectedSwitchingView",
                                    },
                                  },
                                  [
                                    _c(VRadio, {
                                      staticClass: "mb-3 mt-3",
                                      attrs: {
                                        label: _vm.$tkey("asModelled"),
                                        ripple: false,
                                        value: _vm.switchingViews.modelled,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(VRadio, {
                                      staticClass: "mb-3 mt-3",
                                      attrs: {
                                        label: _vm.$tkey("originalSwitching"),
                                        ripple: false,
                                        value: _vm.switchingViews.original,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("switching-heatmap-grid", {
                          key: _vm.selectedViewKey,
                          attrs: { "grid-data": _vm.gridData },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }