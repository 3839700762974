import { render, staticRenderFns } from "./transferred-spend-box.vue?vue&type=template&id=27b92150&scoped=true&functional=true"
import script from "./transferred-spend-box.vue?vue&type=script&lang=js"
export * from "./transferred-spend-box.vue?vue&type=script&lang=js"
import style0 from "./transferred-spend-box.vue?vue&type=style&index=0&id=27b92150&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "27b92150",
  null
  
)

export default component.exports