<template>
  <div class="canvas-grid-container" :style="canvasGridContainerStyle">
    <!-- Fixed spacebreak column on left hand side -->
    <spacebreak-sidebar
      v-if="selectedView === views.Tile"
      :points-of-distribution="pointsOfDistribution"
    />
    <template v-if="!isDisplayByCDT && !unifiedColumns[selectedView]">
      <canvas-column
        v-for="attribute in availableProductAttributesValues(getDisplayBy)"
        :key="attribute.id"
        :name="attribute.name"
        :products="productsSplittedByAttribute[attribute.id]"
      />

      <canvas-column
        v-if="hasProductsWithEmptyAttribute"
        :name="productsWithEmptyAttributeTitle"
        :products="productsWithEmptyAttribute"
      />
    </template>
    <template v-else-if="!unifiedColumns[selectedView]">
      <!-- Expandable column per cann group -->
      <cann-group-column
        v-for="scenarioCdt in orderedScenarioCdts"
        :key="scenarioCdt._id"
        :scenario-cdt="scenarioCdt"
      />
    </template>
    <template v-else>
      <canvas-column
        :name="$t('assortmentCanvasPage.allCannGroups')"
        :products="sortedUnifiedProducts"
      />
    </template>

    <product-panel />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { get, cloneDeep, size, groupBy, isEmpty } from 'lodash';
import { Views, DisplayBy } from '@enums/assortment-canvases';
import { spacebreakTypes } from '@enums/spacebreak-types';

export default {
  name: 'CanvasGrid',
  props: {
    orderedScenarioCdts: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      views: Views,
      isSidePanelOpen: false,
    };
  },

  computed: {
    ...mapState('assortmentCanvas', [
      'spacebreaks',
      'unifiedColumns',
      'selectedView',
      'stickyHeaders',
    ]),
    ...mapGetters('assortmentCanvas', [
      'selectedPod',
      'totalExpandedHeaderDepth',
      'canvasProducts',
      'getFilteredProducts',
      'getHighlightedProducts',
      'sortByProductsInCanvas',
      'getDisplayBy',
      'availableProductAttributesValues',
    ]),
    ...mapGetters('context', ['getClientConfig']),

    hasHighlightProductEnabled() {
      return get(this.getClientConfig, 'features.highlightProductEnabled', false);
    },

    canvasGridContainerStyle() {
      const defaultClass = {
        'grid-template-rows': `repeat(${this.numberOfRows}, max-content)`,
      };
      if (this.unifiedColumns[this.selectedView])
        return {
          ...defaultClass,
          'grid-template-columns': this.selectedView === this.views.Tile ? 'auto 1fr' : '1fr',
        };
      return {
        ...defaultClass,
        'grid-auto-columns': 'max-content',
      };
    },

    pointsOfDistribution() {
      // Points of distribution are calculated across all storeclasses so we select the one for this canvas.
      return get(this, 'selectedPod', {});
    },

    numberOfRows() {
      // Account for the additional spacebreak bays at the bottom of the canvas grid
      const additionalSpacebreaks = size(spacebreakTypes);
      // Account for the Cann Group Name header at the very top of the grid
      const cannGroupHeaderCount = 1;
      // totalExpandedHeaderDepth works out the maximum depth of the expanded headers so we can work out how many extra rows there are in the headers
      // We times by 2 as each time we expand a header it has 2 new rows.
      // Return the number of rows in the entire canvas grid, we set this is the template above to tell CSS Grid how many times to repeat the pattern.
      const newRowCount =
        this.spacebreaks.length +
        this.totalExpandedHeaderDepth * 2 +
        cannGroupHeaderCount +
        additionalSpacebreaks;

      this.setNumberOfRowsInGrid(newRowCount);
      return newRowCount;
    },

    sortedUnifiedProducts() {
      const productsInView = this.hasHighlightProductEnabled
        ? this.getHighlightedProducts
        : this.getFilteredProducts;
      const products = cloneDeep(productsInView.products);
      // mark the products to be highlighted
      if (this.hasHighlightProductEnabled) {
        products.forEach(p => {
          p.isHighlighted = productsInView.highlightedProductsSet.has(p.productKey);
        });
      }
      return products.sort(this.sortByProductsInCanvas);
    },

    productsSplittedByAttribute() {
      return groupBy(this.sortedUnifiedProducts, this.getDisplayBy);
    },

    isDisplayByCDT() {
      return this.getDisplayBy === DisplayBy.cdt;
    },

    productsWithEmptyAttribute() {
      return this.sortedUnifiedProducts.filter(product => !product[this.getDisplayBy]);
    },

    hasProductsWithEmptyAttribute() {
      return !isEmpty(this.productsWithEmptyAttribute);
    },

    productsWithEmptyAttributeTitle() {
      const attributeNameTranslation = this.$t(
        `assortmentCanvasPage.displayBy.${this.getDisplayBy}`
      );
      return `${this.$t('general.no')} ${attributeNameTranslation}`;
    },
  },

  methods: {
    ...mapMutations('assortmentCanvas', ['setNumberOfRowsInGrid']),

    openProductSidebar() {
      this.isSidePanelOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.column-title {
  font-size: 2rem;
}

.side-panel-drawer {
  min-width: fit-content;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
