import permissions from '@enums/user-permissions';

import KitOfParts from './kit-of-parts.vue';

const routes = [
  {
    path: '/kit-of-parts',
    name: 'kit-of-parts',
    id: 'kit-of-parts',
    component: KitOfParts,
    meta: { hideNavbar: true, requiresPermission: permissions.canViewKitOfParts },
    omitFromNav: true,
  },
];

export default routes;
