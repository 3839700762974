'use strict';

const metricTypes = {
  marketShare: 'MarketShare',
  customerSegmentation: 'CustomerSegmentation',
  distributionChannelsPerformance: 'DistributionChannelsPerformance',
};

const metricCategories = {
  [metricTypes.distributionChannelsPerformance]: {
    clickCollect: 'ClickCollect',
  },
  [metricTypes.marketShare]: {
    RMA: 'RMA',
    FOOD: 'FOOD',
    MULO: 'MULO',
  },
  [metricTypes.customerSegmentation]: {
    clover: 'Clover',
    priceSensitivity: 'PriceSensitivity',
  },
};

const metricGroups = {
  [metricTypes.distributionChannelsPerformance]: {
    clickCollect: 'ClickCollect',
    overall: 'Overall',
  },
  [metricTypes.marketShare]: {
    product: 'Product',
    brand: 'Brand',
    subcategory: 'Subcategory',
    category: 'Category',
  },
  [metricTypes.customerSegmentation]: {
    [metricCategories[metricTypes.customerSegmentation].clover]: {
      primary: 'Primary',
      secondary: 'Secondary',
      tertiary: 'Tertiary',
    },
    [metricCategories[metricTypes.customerSegmentation].priceSensitivity]: {
      qualityDriven: 'QualityDriven',
      balanced: 'Balanced',
      priceFocused: 'PriceFocused',
      priceDriven: 'PriceDriven',
    },
  },
};

const productMetrics = {
  [metricTypes.distributionChannelsPerformance]: {
    avgSalesWeekStore: 'avgSalesWeekStore',
    avgVolumeWeekStore: 'avgVolumeWeekStore',
    rank: 'rank',
    selfSwitching: 'selfSwitching',
    totalSales: 'totalSales',
    totalVolume: 'totalVolume',
  },
  [metricTypes.marketShare]: {
    sales: 'sales',
    marketShare: 'marketShare',
    contribution: 'contribution',
  },
  [metricTypes.customerSegmentation]: {
    sales: 'sales',
    rank: 'rank',
    ofRank: 'ofRank',
    share: 'share',
  },
};

module.exports = {
  metricTypes,
  metricCategories,
  metricGroups,
  productMetrics,
};
