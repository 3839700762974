var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.shortView
      ? _c("h2", { staticClass: "ml-3 mt-2" }, [_vm._v(_vm._s(_vm.getTitle))])
      : _c("div", { staticClass: "header-container" }, [
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "h2",
                {
                  staticClass:
                    "reporting-title-detailed d-flex align-center pr-5 mr-6 ml-1",
                },
                [_vm._v("\n        " + _vm._s(_vm.getTitle) + "\n      ")]
              ),
              _vm._v(" "),
              _vm._t("metric"),
              _vm._v(" "),
              _vm._t("attribute"),
              _vm._v(" "),
              _vm._t("filter"),
              _vm._v(" "),
              _vm._t("toolbar"),
            ],
            2
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }