var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.rowData
      ? _c(
          "div",
          { staticClass: "striped-table--read-only" },
          [
            _c(
              "div",
              { staticClass: "striped-table__row striped-table__header" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "striped-table__col font-weight-bold font-italic",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$tkey("columns.timePeriod")) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.tableColumns, function (column) {
                  return _c(
                    "div",
                    {
                      key: column.key,
                      staticClass: "striped-table__col",
                      class: column.headerClass || "",
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(column.headerName) + "\n      "
                      ),
                    ]
                  )
                }),
              ],
              2
            ),
            _vm._v(" "),
            _vm._l(_vm.productMetricCategories, function (category) {
              return [
                _c("div", { staticClass: "striped-table__row" }, [
                  _c(
                    "div",
                    {
                      staticClass: "striped-table__col",
                      class: _vm.subHeaderRows[category].classes || "",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.subHeaderRows[category].headerName) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.metricRows[category], function (row) {
                  return _c(
                    "div",
                    { staticClass: "striped-table__row" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "striped-table__col",
                          class: row.classes || "",
                        },
                        [
                          _c("span", { staticClass: "pl-3" }, [
                            _vm._v(_vm._s(row.headerName)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.tableColumns, function (column) {
                        return _c(
                          "div",
                          {
                            staticClass: "striped-table__col",
                            class: column.classes || "",
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.rowData[
                                    `${category}-${column.key}-${row.key}`
                                  ]
                                ) +
                                "\n        "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
              ]
            }),
          ],
          2
        )
      : _c("div", { staticClass: "my-3 text-center" }, [
          _c("p", { staticClass: "ma-0" }, [
            _vm._v(_vm._s(_vm.$t("errors.noDataAvailable"))),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }