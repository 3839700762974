<template>
  <v-dialog v-model="showDialog" width="900" style="background-color: white">
    <template v-slot:activator="{ on }">
      <v-icon size="20" @click="renderPartialDocs" v-on="on">mdi-help-circle</v-icon>
    </template>
    <dialog-card class="rtls-dialog--legend" @close="closePopup">
      <template v-slot:title>
        <v-btn v-if="fullToolGuideEnabled" secondary depressed :href="docslink" target="_blank">
          {{ $t('actions.openFullToolGuide') }}
        </v-btn>
      </template>
      <slot>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="docs-text" v-html="docsText" />
      </slot>
    </dialog-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import { get } from 'lodash';

export default {
  name: 'AssortmentTooltipDocs',
  props: {
    link: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showDialog: false,
      docsText: '',
    };
  },

  computed: {
    docslink() {
      return `/api/docs/${this.link}`;
    },

    anchorTag() {
      // splits URL into groups, last one is the value of the anchor tag
      // eg, my-page.html#foo-bar, the value for tag is `foo-bar`
      const groupsResult = new RegExp('(#)(.+)').exec(this.link);

      return get(groupsResult, [2], null);
    },

    fullToolGuideEnabled() {
      return get(this.getClientConfig, 'features.fullToolGuideEnabled', false);
    },
  },

  updated() {
    // After popup has loaded with HTML, scroll the user to the anchor from link if it is available
    const hasAnchorTagToScrollTo = this.showDialog && this.docsText && this.anchorTag;
    if (hasAnchorTagToScrollTo) {
      this.scrollToAnchor();
    }
  },

  methods: {
    closePopup() {
      this.showDialog = false;
    },

    async renderPartialDocs() {
      try {
        // - Get the <main> part of the docs for the provided link
        const response = await axios.get(`/api/docs/${this.link}`);

        // Load the docs file
        const parser = new DOMParser();
        const docAll = parser.parseFromString(response.data, 'text/html');

        // Make sure non same page links open a new window
        const hyperlinks = docAll.getElementsByClassName('nav-link');
        hyperlinks.forEach(hl => {
          if (hl.target === '') hl.target = `_blank`;
        });

        // Remove footer
        const footer = docAll.getElementsByClassName('page-nav');
        if (footer.length > 0) footer[0].parentNode.removeChild(footer[0]);

        // Get the <main> section
        const mainTextSearch = docAll.getElementsByTagName('main');
        const doc = mainTextSearch[0].innerHTML;

        // Set text and show dialog
        this.docsText = doc;
        this.showDialog = true;
      } catch {
        // If any issues, then just open (in a new window) the main docs
        this.showDialog = false;
        window.open(this.docslink, '_blank');
      }
    },

    scrollToAnchor() {
      const element = document.getElementById(this.anchorTag);
      // Ensures HTML is ready for scrolling
      setTimeout(() => {
        element.scrollIntoView({ inline: 'nearest', block: 'start' });
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.docs-text {
  ::v-deep {
    .content__default {
      max-width: 100%;
      width: 100%;
      font-size: 16px;
      padding-left: 30px;
      padding-right: 40px;
      padding-bottom: 60px;
    }

    a.header-anchor {
      display: none;
    }

    img {
      max-width: 100%;
    }

    ul {
      margin-block-start: 16px;
      margin-block-end: 16px;
    }

    a {
      text-decoration: none;
      font-size: 16px;
    }

    a:hover {
      text-decoration: underline;
    }

    .table-of-contents {
      * ul {
        margin-block-start: 0px;
        margin-block-end: 0px;
      }
    }

    h1 {
      padding-top: 15px !important;
    }

    h2 {
      font-size: 1.2em;
      border-bottom: 1px solid #eee;
      padding-bottom: 5px;
      line-height: 30px;
      margin-bottom: 10px;
      margin-top: 15px;
    }

    h3 {
      font-size: 1.1em;
      margin-top: 20px;
      padding-bottom: 5px;

      & + h4 {
        margin-top: 0;
      }
    }

    h4 {
      font-size: 1.05em;
      margin-top: 20px;
      padding-bottom: 5px;
    }

    .lightbulb {
      background-color: #f0f7fb;
      background-image: url(../../img/Light-Bulb-30.png);
      background-position: 9px 7px;
      background-repeat: no-repeat;
      border-left: solid 4px #3498db;
      line-height: 18px;
      overflow: hidden;
      padding: 15px 60px;
    }

    .numberCircle {
      border-radius: 50%;
      display: inline-block;
      padding: 0 5px;
      margin-top: 10px;
      background: #ffbb00;
      border: 2px solid #ffbb00;
      color: black;
      text-align: center;
      font-weight: bold;
      font: 14px Arial, sans-serif;
    }

    table {
      border-collapse: collapse;
    }

    td,
    th {
      border: 1px solid #dfe2e5;
      padding: 0.6em 1em;
    }

    th {
      display: table-cell;
      vertical-align: inherit;
    }

    table {
      border-collapse: collapse;
    }

    td,
    th {
      border: 1px solid #dfe2e5;
      padding: 0.6em 1em;
    }

    th {
      display: table-cell;
      vertical-align: inherit;
    }
  }
}
</style>
