import ErrorPage from './error-page.vue';

const routes = [
  {
    path: '/error/:status',
    name: 'error-page',
    component: ErrorPage,
    omitFromNav: true,
  },
];

export default routes;
