<template>
  <v-stepper v-model="currentStepPosition" class="stepper">
    <v-stepper-header>
      <template v-for="step in stepperData.steps">
        <v-stepper-step
          :key="`${step.componentName}-step`"
          :complete="isStepComplete(step)"
          :step="step.order"
          :class="{ clickable: step.isClickable }"
          complete-icon="$success"
          @click="step.isClickable && handleStepSelect(step.order)"
        >
          {{ $t(step.translationKey) }}
        </v-stepper-step>

        <v-divider v-if="step.order < stepperData.steps.length" :key="step.order" />
      </template>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content
        v-for="step in stepperData.steps"
        :key="`${step.componentName}-content`"
        :step="step.order"
      >
        <template v-if="activeStep.order === step.order">
          <component
            :is="activeStep.componentName"
            :ref="activeStep.componentName"
            :extract-type="extractType"
          />

          <div class="row stepper__actions">
            <v-btn text :disabled="isPreviousDisabled" @click="handlePreviousStep">
              {{ $t('actions.previous') }}
            </v-btn>
            <v-btn
              class="stepper__button"
              :disabled="isNextDisabled"
              color="primary"
              @click="handleNextStep"
            >
              {{ $t('actions.next') }}
            </v-btn>
          </div>
        </template>
      </v-stepper-content>
    </v-stepper-items>

    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="closeUnsavedDataModal"
      @confirm="closeUnsavedDataModal"
    />
  </v-stepper>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { size, get, reduce, find, cloneDeep, orderBy, last } from 'lodash';
import sectionStatuses from '@enums/section-status';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';

export default {
  mixins: [unsavedDataWarningMixin],
  props: {
    stepData: {
      type: Array,
      required: true,
    },
    // If using this stepper from compound series selector
    fromSeries: {
      type: Boolean,
      required: false,
      default: false,
    },
    // get current status from series instead of scenario workflow
    seriesStatuses: {
      type: Object,
      required: false,
      default: null,
    },
    defaultToLastStepIfComplete: {
      type: Boolean,
      required: false,
      default: false,
    },
    extractType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      currentStepPosition: null,
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('scenarios', ['selectedScenarioStatus']),

    statuses() {
      return this.selectedScenarioStatus(this.selectedWorkpackage.type);
    },

    hasDataChanges() {
      return this.$refs[this.activeStep.componentName][0].hasDataChanges;
    },

    stepperData() {
      return reduce(
        cloneDeep(this.stepData),
        (acc, step, index) => {
          if (this.fromSeries) {
            // Retrieve the current status for step from series workflow
            step.status = get(this.seriesStatuses, [step.statusSection, 'status'], null);
          } else {
            // Retrieve the current status for step from scenario workflow
            step.status = get(
              this.statuses,
              [step.statusSection, step.statusSubSection, 'status'],
              null
            );
          }

          /* Allow step to be clickable if previous step is complete. First step should always be clickable.
             If previous step is not complete, fall back to isClickable value in stepData  */
          const currentClickState = this.stepData[index].isClickable;
          step.isClickable =
            acc.previousStep.status === sectionStatuses.complete || !!currentClickState;

          acc.steps.push(step);
          acc.previousStep = step;
          return acc;
        },
        { steps: [], previousStep: { status: sectionStatuses.complete } }
      );
    },

    activeStep() {
      return this.stepperData.steps[this.currentStepPosition - 1];
    },

    isFirstStep() {
      return this.currentStepPosition === 1;
    },

    isLastStep() {
      return this.currentStepPosition === size(this.stepperData.steps);
    },

    isNextDisabled() {
      return this.isLastStep || this.activeStep.status !== sectionStatuses.complete;
    },

    isEditingDisabled() {
      return !this.hasPermission(this.userPermissions.canEditStoreExecutionPlanning);
    },

    isPreviousDisabled() {
      return (
        this.isFirstStep ||
        this.stepperData.steps[this.currentStepPosition - 2].status !== sectionStatuses.complete
      );
    },
  },

  created() {
    const orderedSteps = orderBy(this.stepperData.steps, 'order');
    const lastStep = last(orderedSteps);

    // If the last step is in-progress or complete, default to that step on page load
    if (this.defaultToLastStepIfComplete && lastStep.status !== sectionStatuses.notStarted) {
      this.currentStepPosition = lastStep.order;
    } else {
      // Set default to first incomplete step
      const stepInProgress = find(orderedSteps, s => s.status !== sectionStatuses.complete);
      this.currentStepPosition = stepInProgress.order;
    }
  },

  methods: {
    isStepComplete(step) {
      return step.status === sectionStatuses.complete && step !== this.activeStep;
    },

    goToStep(stepOrder) {
      this.currentStepPosition = stepOrder;
    },

    handleNextStep() {
      if (!this.isNextDisabled) {
        this.handleStepSelect(this.currentStepPosition + 1);
      }
    },

    handlePreviousStep() {
      if (!this.isFirstStep) {
        this.handleStepSelect(this.currentStepPosition - 1);
      }
    },

    handleStepSelect(stepOrder) {
      this.beforeNavWithUnsavedData(() => {
        this.goToStep(stepOrder);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.stepper {
  box-shadow: none;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__actions {
    flex: 0;
    padding: 5px 0;
  }

  &__button {
    margin: 0 10px;

    &:first-of-type {
      margin-left: 0;
    }
  }
}

::v-deep {
  .v-divider {
    border-color: $assortment-step-divider-colour;
  }

  .clickable {
    &:hover {
      cursor: pointer;
    }

    .v-stepper__step__step {
      background: $assortment-primary-colour !important;
    }
  }

  .v-stepper {
    &__content {
      height: 100%;
      transition: none !important;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__step {
      font-size: 1.2rem;

      &--active {
        .v-stepper__label {
          color: $assortment-active-step-colour !important;
        }
      }

      &__step {
        font-size: 1.2rem;
        height: 20px;
        min-width: 20px;
        width: 20px;
      }
    }

    &__items {
      flex: 1;
    }
  }
}
</style>
