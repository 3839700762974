import { snakeCase, get, includes } from 'lodash';

/**
 * Function that creates an object to map tabs to the corresponding route names
 * @param {Array} tabs - Array of objects containing tab data
 * @returns {Object} - Map of tabs and route names,
 */
const generateTabRouteMap = tabs => {
  return tabs.reduce(
    (acc, tab) => ({
      ...acc,
      [`${snakeCase(tab.routeName).toUpperCase()}_TAB`]: tab.routeName,
    }),
    {}
  );
};

/**
 * Retrieves tab data for the specified step and workpackage type
 * @param {Array} tabs - Array of objects containing tab data
 * @param {Object} clientConfig - Object of config settings required to determine which tabs are enabled
 * @returns {Array} - Enabled tabs,
 */
const getAllEnabledTabs = (tabs, clientConfig) => {
  const disabledTabs = [];

  if (!get(clientConfig, 'features.jdaExtractEnabled')) disabledTabs.push('jda');
  if (!get(clientConfig, 'features.finalisationEnabled')) disabledTabs.push('finalisation');
  if (!get(clientConfig, 'features.switchingModellingEnabled')) {
    disabledTabs.push('switching-modelling');
  }
  if (!get(clientConfig, 'features.furnitureExtractEnabled')) disabledTabs.push('furniture');
  if (!get(clientConfig, 'features.regionsByAttributeEnabled')) {
    disabledTabs.push('region');
  }
  return tabs.filter(tab => !includes(disabledTabs, tab.routeName));
};

/**
 * Retrieves tab data for the specified workpackage type
 * @param {Array} tabs - Array of objects containing tab data
 * @param {String} wpType - The workpackage type
 * @returns {Array} - Filtered tab data,
 */
const getByType = (tabs, wpType) => {
  return tabs.filter(tab => tab.wpTypes.includes(wpType));
};

/**
 * Retrieves tab data for the specified step and workpackage type
 * @param {Array} tabs - Array of objects containing tab data
 * @param {String} step - Name of the step to filter by
 * @param {String} wpType - The workpackage type
 * @param {Object} clientConfig - Object of config settings required to determine which tabs are enabled
 * @returns {Array} - Filtered tab data,
 */
const getByStepAndType = (tabs, step, wpType, clientConfig) => {
  return getAllEnabledTabs(tabs, clientConfig).filter(
    tab => tab.step === step && tab.wpTypes.includes(wpType)
  );
};

export default {
  generateTabRouteMap,
  getAllEnabledTabs,
  getByType,
  getByStepAndType,
};
