import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "px-8 py-6", attrs: { fluid: "" } },
    [
      _c(VRow, [_vm._t("default")], 2),
      _vm._v(" "),
      _c(
        VRow,
        { staticClass: "mt-4" },
        [
          _c(
            VBtn,
            {
              attrs: { disabled: _vm.disabled, small: "", primary: "" },
              on: { click: _vm.generate },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("extract.buttons.generateExtract")) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }