<template>
  <v-card class="step-tab-panel" flat>
    <v-row>
      <v-col justify="start" class="pa-0">
        <stepper ref="stepper" :step-data="steps" />
      </v-col>
      <v-col v-if="showPreviousExtracts" justify="start" cols="4" class="pt-0 pr-0">
        <previous-extract-reports :reports="reports" />
      </v-col>
    </v-row>

    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="closeUnsavedDataModal"
      @confirm="closeUnsavedDataModal"
    />
  </v-card>
</template>

<script>
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import extractsUtils from '@/js/utils/extracts-utils';
import stepperData from '@/js/utils/tabs/stepper-steps-enum';
import { STORE_EXECUTION } from '@enums/extract-types';
import { flatten, isEqual, map, size, some, sortBy, values, get } from 'lodash';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  mixins: [unsavedDataWarningMixin],

  computed: {
    ...mapState('extracts', ['selectedStep']),
    ...mapState('scenarios', ['selectedScenario']),
    ...mapGetters('extracts', ['extracts']),
    ...mapGetters('clustering', ['noSelectedClusterScheme']),

    showPreviousExtracts() {
      // Hide previous extracts for specific steps in JDA tab
      return this.selectedStep === 'report-jda';
    },

    steps() {
      const excludedSubSections = [];
      if (this.noSelectedClusterScheme) excludedSubSections.push('storeAllocation');

      const displayedSteps = stepperData.jda.filter(
        step => !excludedSubSections.includes(step.statusSubSection)
      );

      return map(sortBy(displayedSteps, 'order'), (tab, ix) => {
        if (isEqual(tab.statusSubSection, 'jda') && this.reports) {
          tab.isClickable = !!size(this.reports);
        }

        // Ensure order is still 1,2,3 after steps are potentially excluded
        tab.order = ix + 1;
        return tab;
      });
    },

    reports() {
      return extractsUtils.reports({ extracts: this.extracts, reportType: STORE_EXECUTION });
    },

    hasDataChanges() {
      const components = flatten(values(this.$refs.stepper.$refs));
      return some(components.map(v => v.hasDataChanges));
    },
  },

  async created() {
    this.preselectStep();
    await this.fetchScenarioClusters();
  },

  methods: {
    ...mapMutations('extracts', ['setSelectedStep']),
    ...mapActions('clustering', ['fetchScenarioClusters']),

    preselectStep() {
      const isFromNotification = get(this.$route, 'params.fromNotifications', false);
      // Reset selected step as stepper is not visible initially, if coming from notifications, set to last position
      const initialStep = isFromNotification ? this.steps.length : 1;
      this.setSelectedStep(initialStep);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.step-tab-panel {
  background-color: $assortment-background !important;
}
</style>
