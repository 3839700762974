<template>
  <v-row class="mt-10 mb-3">
    <v-col class="pa-0 pl-1" cols="12">
      <scenarios-list :workpackage="workpackage" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    workpackage: {
      type: Object,
      required: true,
    },
  },
};
</script>
