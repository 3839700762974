<template>
  <v-card class="step-tab-panel" flat>
    <v-row>
      <v-col justify="start" class="pa-0 step-tab-panel__content">
        <extract-report-generator
          :disabled="isGenerateExtractDisabled"
          @generate-extract-requested="generateReport"
        >
          <v-container class="report-product-store-extract d-flex flex-column pa-0 ma-0" fluid>
            <v-row no-gutters>
              <v-col class="margin-right-23" colspan="12">
                <report-input-item :title="$t('extract.reports.furnitureExtract.reportType.title')">
                  <div>
                    <div class="d-flex justify-content-end mb-1">
                      <v-btn small primary class="control-button ml-auto" @click="selectAll">
                        {{ $t('extract.reports.furnitureExtract.reportType.buttons.selectAll') }}
                      </v-btn>
                      <v-btn small secondary class="control-button" @click="clearAll">
                        {{ $t('extract.reports.furnitureExtract.reportType.buttons.clearAll') }}
                      </v-btn>
                    </div>

                    <div
                      v-for="(extract, name) in extractions"
                      :key="name"
                      class="d-flex align-center justify-space-between checkbox-container mb-1"
                      :class="extract.selected ? 'border-selected' : 'border-unselected'"
                    >
                      <div class="checkbox-label">
                        {{ $t('extract.reports.furnitureExtract.reportType.extractions.' + name) }}
                      </div>
                      <v-checkbox
                        :input-value="extract.selected"
                        class="rtls-checkbox mx-2"
                        @change="extract.selected = $event"
                      />
                    </div>
                  </div>
                </report-input-item>
              </v-col>
            </v-row>

            <v-row class="mt-6">
              <checkpoints-selection-table
                :scenario-id="selectedScenario._id"
                :headers="headers"
                :canvases="items"
                :checkpoints-by-canvas-id="checkpointsByCanvasId"
                :clustered-toggle="true"
                :title="$t('extract.reports.furnitureExtract.table.title')"
                @update-selected-checkpoints="setSelectedCheckpoints"
              />
            </v-row>
          </v-container>
        </extract-report-generator>
      </v-col>
      <v-col justify="start" cols="4" class="pt-0 pr-0">
        <previous-extract-reports :reports="reports" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { map, every, includes, debounce, isEmpty, forEach, get } from 'lodash';
import moment from 'moment';
import { extracts } from '@enums/client-events';
import { FURNITURE } from '@enums/extract-types';
import { jobStatuses, jobFinishedStatuses, jobStatusesIconsMappings } from '@enums/jobapi';
import extractsUtils from '@/js/utils/extracts-utils';

const canvasesFilterParams = {
  where: { live: true },
  pick: ['clusterId', 'jobs', 'checkpointMeta', 'live'],
};

export default {
  localizationKey: 'extract.reports.furnitureExtract',

  data() {
    return {
      extractions: {
        furnitureClusterStore: {
          selected: true,
          extractKey: 'furniture_cluster_store_extract',
        },
        furnitureClusterMetadata: {
          selected: true,
          extractKey: 'furniture_cluster_metadata_extract',
        },
        furnitureClusterUpcRank: {
          selected: true,
          extractKey: 'furniture_cluster_productkey_rank_extract',
        },
        furnitureClusterUpcAttributes: {
          selected: true,
          extractKey: 'furniture_cluster_productkey_attributes_extract',
        },
        furnitureAttributeMetadata: {
          selected: true,
          extractKey: 'furniture_attribute_metadata_extract',
        },
        furnitureClusterStoreFurnitureSize: {
          selected: true,
          extractKey: 'furniture_cluster_store_furniture_size_extract',
        },
        furnitureClusterProductKeyUtility: {
          selected: true,
          extractKey: 'furniture_cluster_productkey_utility_extract',
        },
        spaceApproachFlagReference: {
          selected: true,
          extractKey: 'space_approach_flag_reference_extract',
        },
        productKeyStaticSwitching: {
          selected: true,
          extractKey: 'productkey_static_switching_extract',
        },
        xChart: {
          selected: true,
          extractKey: 'x_chart_extract',
        },
      },
      headers: [
        {
          text: this.$t('checkpointSelectionTable.headers.cluster'),
          value: 'cluster',
          width: 100,
        },
        {
          text: this.$t('checkpointSelectionTable.headers.storeClass'),
          value: 'storeClass',
          width: 100,
        },
        {
          text: this.$t('checkpointSelectionTable.headers.description'),
          value: 'description',
          width: 200,
        },
        {
          text: this.$t('checkpointSelectionTable.headers.optimised'),
          value: 'status',
          align: 'center',
          width: 100,
        },
        {
          text: this.$t('checkpointSelectionTable.headers.checkpoint'),
          value: 'checkpoint',
          width: 250,
        },
      ],
      extractName: null,
      selectedCheckpoints: {},
      isCheckpointError: false,
    };
  },

  computed: {
    ...mapState('scenarios', ['selectedScenario']),
    ...mapGetters('extracts', ['extracts']),
    ...mapGetters('assortmentCanvas', ['scenarioCheckpointsByCanvasId', 'canvases']),
    ...mapGetters('context', {
      dateFormats: 'getDateFormats',
      showNotImplemented: 'showNotImplemented',
    }),

    items() {
      return map(this.canvases, c => ({
        _id: c._id,
        clusterId: c.clusterId,
        cluster: c.clusterName || this.$tkey('unClustered'),
        clusterName: c.clusterName,
        storeClassId: c.storeClassId,
        storeClass: c.storeClassName,
        description: c.description || this.$tkey('noDescription'),
        status: c.hasBeenOptimised
          ? jobStatusesIconsMappings[jobStatuses.finished]
          : jobStatusesIconsMappings[jobStatuses.failed],
        originalJobStatus:
          c.jobs && c.jobs.assortmentOptimisation
            ? c.jobs.assortmentOptimisation.status
            : jobStatuses.failed,
        checkpoint: null,
      }));
    },

    checkpointsByCanvasId() {
      // Create a map of canvas id to checkpoint options
      const checkpointsByCanvasId = {};
      forEach(this.items, ({ _id: canvasId }) => {
        const canvasCheckpoints = get(this.scenarioCheckpointsByCanvasId, canvasId, []).map(
          ({ checkpointMeta, _id }) => {
            return {
              text: checkpointMeta.name,
              value: _id,
            };
          }
        );
        canvasCheckpoints.unshift({ text: this.$tkey('noSelection'), value: null });
        checkpointsByCanvasId[canvasId] = canvasCheckpoints;
      });

      return checkpointsByCanvasId;
    },

    isGenerateExtractDisabled() {
      return (
        isEmpty(this.scenarioCheckpointsByCanvasId) ||
        !this.userCanEditProductStoreExtracts ||
        this.isCheckpointError ||
        every(this.extractions, e => !e.selected)
      );
    },

    userCanEditProductStoreExtracts() {
      return this.hasPermission(this.userPermissions.canEditProductStoreExtracts);
    },

    reports() {
      return extractsUtils.reports({ extracts: this.extracts, reportType: FURNITURE });
    },
  },

  watch: {
    items() {
      if (this.shouldPollCanvases()) {
        this.debouncedFetchCanvases({ params: canvasesFilterParams });
      }
    },
  },

  async created() {
    this.debouncedFetchCanvases = debounce(this.fetchCanvases, 5000);
    await Promise.all([
      this.fetchCanvases({ params: canvasesFilterParams }),
      this.fetchScenarioCheckpoints(),
    ]);
  },

  methods: {
    ...mapActions('assortmentCanvas', ['fetchCanvases', 'fetchScenarioCheckpoints']),
    ...mapActions('extracts', ['generateFurnitureExtract']),

    shouldPollCanvases() {
      // checks if there are unfinished jobs that need to be refreshed
      return every(this.items, i => includes(jobFinishedStatuses, i.originalJobStatus)) === false;
    },

    async generateReport() {
      if (!this.checkpointError) {
        await this.generateFurnitureExtract({
          filenameOnDownload:
            this.extractName ||
            this.$tkey('furnitureExtractsFileName', [
              moment().format(this.dateFormats.longWithTimeAndMilliseconds),
            ]),
          extractType: FURNITURE,
          extractDatetime: moment.utc().toDate(),
          jobs: {
            furnitureExtract: {
              status: jobStatuses.queued,
              dateTimeComplete: null,
            },
          },
          fileId: null,
          canvasesIds: Object.values(this.selectedCheckpoints).filter(c => c),
          extractsSelection: Object.values(this.extractions)
            .filter(e => e.selected)
            .map(e => e.extractKey),
        });
        this.globalEmit(extracts.extractGenerated);
      }
    },

    setSelectedCheckpoints(cps) {
      this.selectedCheckpoints = cps;
      // validate the selected checkpoints
      this.isCheckpointError = Object.values(cps).every(v => !v);
    },

    selectAll() {
      forEach(this.extractions, e => {
        e.selected = true;
      });
    },

    clearAll() {
      forEach(this.extractions, e => {
        e.selected = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.report-product-store-extract {
  .margin-right-23 {
    margin-right: 23px;
  }
}

.rtls-checkbox {
  margin: 0 !important;
  ::v-deep {
    .v-input--selection-controls__input {
      margin: 0 !important;
    }
  }
}

.width-100 {
  width: 100%;
}

.border-selected {
  border: 1px solid $assortment-primary-colour;
}

.border-unselected {
  border: 1px solid $assortment-divider-colour;
}

.checkbox-container {
  border-radius: 4px;
  padding: 2px 1px 2px 7px;
}

.checkbox-label {
  font-size: 1.2rem;
  color: $assortment-font-colour;
}

.step-tab-panel {
  background-color: $assortment-background !important;

  &__content {
    background: $assortment-panel-background;
  }
}

.control-button {
  margin-left: 5px;
  margin-bottom: 5px;
}
</style>
