<template>
  <v-container class="rtls-calendar-picker">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="290px"
      :offset-x="calculateOffsetX"
      :offset-y="calculateOffsetY"
      nudge-bottom="25"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="datesFormatted"
          hide-details
          :disabled="disabled"
          :class="boldText ? 'bold-text' : ''"
          @input="
            $emit('input', parseDates($event));
            setTypingActive();
          "
          v-on="on"
        >
          <template slot="append">
            <v-icon small @click="menu = true">$date-picker</v-icon>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="dates"
        no-title
        scrollable
        range
        :allowed-dates="allowedDates"
        :first-day-of-week="firstDayOfWeek"
        :events="events"
      />
    </v-menu>
  </v-container>
</template>

<script>
import moment from 'moment';
import { debounce } from 'lodash';
import datesMixin from '@/js/mixins/date-utils';
import { mapGetters } from 'vuex';

export default {
  name: 'RtlsCalendarRangePicker',
  mixins: [datesMixin],
  props: {
    value: {
      required: false,
      type: Array,
      default: () => [],
    },
    boldText: {
      required: false,
      type: Boolean,
      default: false,
    },
    offsetX: {
      required: false,
      type: Boolean,
      default: false,
    },
    offsetY: {
      required: false,
      type: Boolean,
      default: true,
    },
    allowedDates: {
      required: false,
      type: Function,
      default: null,
    },
    firstDayOfWeek: {
      required: false,
      type: Number,
      default: 1, // Monday
    },
    displayDateFormatOverwrite: {
      required: false,
      type: String,
      default: null,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    events: {
      required: false,
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      menu: null,
      datesFormatted: [],
      isTyping: false,
    };
  },

  computed: {
    ...mapGetters('context', ['getDateFormats', 'getDefaultReverseFormat']),
    displayDateFormat() {
      return this.displayDateFormatOverwrite || this.getDateFormats.long;
    },
    expectedFormats() {
      return ['YYYY-MM-DDTHH:mm:ss.msZ', this.displayDateFormat, this.getDefaultReverseFormat];
    },
    dates: {
      get() {
        return this.isTyping || !this.isValidDates(this.value)
          ? []
          : this.sortDates(this.value, this.getDefaultReverseFormat);
      },
      set(value) {
        this.$emit('input', this.sortDates(value, this.getDefaultReverseFormat));
      },
    },
    calculateOffsetY() {
      return !this.offsetX && this.offsetY;
    },
    calculateOffsetX() {
      return this.offsetX;
    },
  },
  watch: {
    dates() {
      if (!this.isTyping && this.isValidDates(this.dates)) {
        this.datesFormatted = this.formatDates(this.dates);
      }
    },
    value() {
      this.setTypingInactive();
    },
  },
  created() {
    if (this.value && this.isValidDates(this.value)) {
      this.datesFormatted = this.formatDates(this.value);
    }
    this.setTypingInactive = debounce(this.setTypingInactive, 2000);
  },
  methods: {
    formatDates(dates) {
      if (!dates) {
        return null;
      }

      return this.formatDatesForPicker(dates, this.getDateFormats.long).join(', ');
    },
    parseDates(dates) {
      if (!dates) {
        return null;
      }

      const parsedDates = dates
        .split(', ')
        .map(date => this.formatDateForSave(date, this.getDateFormats.long));

      return this.isValidDates(parsedDates)
        ? this.sortDates(parsedDates, this.getDateFormats.long)
        : null;
    },

    setTypingActive() {
      this.isTyping = true;
    },
    setTypingInactive() {
      this.isTyping = false;
    },
    isValidDates(dates) {
      if (!dates) return false;
      // last argument true means that it will strictly tested
      return dates.reduce(
        (result, date) => result && moment.utc(date, this.expectedFormats, true).isValid(),
        true
      );
    },
  },
};
</script>
