<template>
  <v-card class="step-tab-panel" flat>
    <div class="assortment-table d-flex flex-column">
      <v-container class="actions-container pa-0 ma-0 flex-grow-0">
        <v-row class="actions-col">
          <v-col class="d-flex align-center flex-grow-1">
            <span class="mr-3 font-weight-bold">
              {{ $tkey('setList') }}

              <!-- StoreClass definition tooltip -->
              <docs-link link="toolguide/04a-storeclasses.html" />
            </span>

            <v-btn
              v-if="showNotImplemented"
              data-id-e2e="btnStoreClassImport"
              class="button-space-5"
              primary
              disabled
            >
              {{ $t('actions.manualImport') }}
              <v-icon right>$import</v-icon>
            </v-btn>
            <filters
              :filters="filters"
              :filter-options="filterOptions"
              :btn-text="filterButtonText"
              @change="handleFilterChange"
              @apply="filterFurnitureWithStoreClass"
            />
          </v-col>
          <v-col
            v-if="showErrorControls"
            id="error-controls"
            class="d-flex align-center col flex-grow-2"
          >
            <span class="invalid-rows-error-box pr-2">{{ invalidRowsErrorMessage }}</span>
            <v-btn
              v-if="!filterInvalidRows"
              outlined
              depressed
              color="error"
              @click="toggleInvalidRows"
            >
              {{ $t('actions.showInvalidRows') }}
            </v-btn>
            <v-btn v-else outlined depressed color="primary" @click="toggleInvalidRows">
              {{ $t('actions.showAllRows') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        class="actions-container dynamic-height-container storeclass-container d-flex flex-column pl-0 pr-0 pb-0 flex-grow-1"
      >
        <v-row no-gutters class="mt-2 mb-1 flex-grow-0">
          <v-col cols="2">
            <v-card-text class="list-title">
              <div>{{ $tkey('storeClassList') }}</div>
            </v-card-text>
          </v-col>
        </v-row>
        <div class="storeclass-list-container">
          <v-col cols="2" class="pt-0 storeclass-list-column">
            <v-divider class="storeclass-border mb-2" />
            <div class="padded-column">
              <div class="storeclass-list-scrollable">
                <v-form ref="storeClassNamesForm" v-model="valid" @submit.prevent>
                  <v-card
                    v-for="storeClass in storeClasses"
                    :key="storeClass._id"
                    class="mb-1 storeclass-card"
                    flat
                    primary
                  >
                    <v-row class="align-items-center">
                      <v-col class="storeclass-col">
                        <rtls-text-field
                          :key="nameKey + storeClass._id"
                          v-model.trim="storeClass.name"
                          class="storeclass-text-field"
                          :rules="storeClassInputRules"
                          :hide-details="false"
                          :disabled="editingDisabled"
                          run-validations-on-creation
                          @change="[revalidateForm(), redrawRows()]"
                        />
                        {{ /* have to call redrawRows for all ag-grids so storeclass names stay in sync. */ }}
                        {{ /* note: this is not performant. AOV3-754 TODO: only refresh tables when storeclass has furniture. only refresh related rows. */ }}
                      </v-col>
                      <v-col cols="2" class="mx-0 my-auto pa-0 d-flex justify-end">
                        <v-icon
                          :disabled="editingDisabled"
                          size="19"
                          class="small-icon mr-1"
                          @click="removeStoreClass(storeClass._id)"
                        >
                          $trash
                        </v-icon>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-form>
              </div>
              <v-btn
                :disabled="editingDisabled"
                data-id-e2e="btnCreateStoreClass"
                primary
                depressed
                class="ml-2 mb-2 mt-2 storeclass-btn"
                @click="() => addNewStoreClass(false)"
              >
                {{ $tkey('newStoreClass') }}
              </v-btn>
            </div>
            <v-divider class="storeclass-border" />
            <div class="padded-column">
              <v-btn
                :disabled="editingDisabled"
                depressed
                outlined
                secondary
                class="ml-2 mb-2 mt-2 single-storeclass-btn storeclass-btn outlined-btn"
                @click="onSingleStoreClassClick"
              >
                {{ $tkey('singleStoreClass') }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="10" class="furniture-col-scrollable pt-0 pl-0">
            <v-col cols="7" class="pl-2 pt-2">
              <h3 v-if="filters.length && !filteredFurnitureCategorySets.length">
                {{ $t('errors.noDataAvailableForFilters') }}
              </h3>
              <div
                v-for="(furnitureSet, index) in filteredFurnitureCategorySets"
                :key="`${furnitureSet + index}-container`"
                class="d-flex flex-column grid-container storeclass-table"
                style="width: 100%; height: 100%"
              >
                <b class="section">{{ furnitureSet }}</b>
                <ag-grid-vue
                  :id="`ag-grid-${furnitureSet}`"
                  style="width: 100%; height: 100%"
                  dom-layout="autoHeight"
                  class="ag-theme-custom"
                  :column-defs="columnDefs"
                  :row-data="groupedFurniture[furnitureSet]"
                  :grid-options="gridOptions[furnitureSet]"
                  :enable-range-selection="true"
                  :does-external-filter-pass="doesExternalFilterPass"
                  @cell-value-changed="onSelectionChange"
                  @grid-ready="onGridReady($event, furnitureSet)"
                  @row-clicked="modifySelectPosition(`#ag-grid-${furnitureSet}`)"
                />
              </div>
            </v-col>
          </v-col>
        </div>
      </v-container>
      <page-actions
        :show-export="showNotImplemented"
        :has-data-changes="hasDataChanges"
        :has-data-errors="!valid"
        :save-disabled="editingDisabled"
        @discard="discardChanges"
        @save="saveChanges"
      />
    </div>
    <main-dialog
      ref="confirmDeleteDialog"
      :message="$tkey(deleteAllStoreClasses ? 'removeAllWarning' : 'changingStoreClassesWarning')"
      show-confirm-message
      @cancel="closeConfirmDialog"
    >
      <template v-slot:actions="{ cancel: close }">
        <v-row class="mt-2">
          <v-btn primary class="mx-auto" @click="[onDeleteConfirm(), close()]">
            {{ deleteAllStoreClasses ? $t('general.yes') : $tkey('removeStoreClass') }}
          </v-btn>
        </v-row>
        <v-row class="my-1">
          <v-btn text class="mx-auto" @click="close">{{ $t('actions.cancel') }}</v-btn>
        </v-row>
      </template>
    </main-dialog>

    <dependency-tree-feedback-modal
      :value="dependencyTreeModalOpen"
      :results="dependencyTreeFeedback"
      page="storeclassDefinition"
      @close="closeDependencyTreeModal"
      @commit="saveChanges(true)"
    />

    <unsaved-data-modal
      ref="unsavedDataModal"
      :value="isUnsavedDataModalOpen"
      @cancel="closeUnsavedDataModal"
      @confirm="closeUnsavedDataModal"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  groupBy,
  uniq,
  cloneDeep,
  differenceBy,
  uniqueId,
  startsWith,
  isEqual,
  map,
  mapValues,
  get,
  keyBy,
  reduce,
  keys,
  extend,
  isEmpty,
  each,
} from 'lodash';
import inputValidationMixin from '@/js/mixins/input-validations';
import unsavedDataWarningMixin from '@/js/mixins/unsaved-data-warning';
import filterUtils from '@/js/utils/filter-utils';
import { AgGridVue } from 'ag-grid-vue';
import agGridUtils from '@/js/utils/ag-grid-utils';

const newlyAddedStoreClassIdPrefix = 'newlyAddedStoreClass';

export default {
  components: {
    AgGridVue,
  },
  mixins: [inputValidationMixin, unsavedDataWarningMixin],
  localizationKey: 'storeClassDefinitionPage',
  data() {
    return {
      errorData: {},
      filterInvalidRows: false,
      gridApis: {},
      storeClasses: [],
      storeClassInputRules: [this.required, this.isUniqueStoreClassName, this.hasFurniture],
      nameKey: false,
      valid: true,
      confirmMessages: [],
      deleteAllStoreClasses: false, // used to toggle contents of deletion modal
      gridOptions: {},
      filters: [],
      furnituresWithStoreClass: [],
      filteredFurnituresWithStoreClass: [],
      dependencyTreeFeedback: {},
      dependencyTreeModalOpen: false,
    };
  },
  computed: {
    ...mapGetters('furniture', ['getFurnituresInScenario', 'getStoreClassesInScenario']),
    ...mapGetters('context', ['showNotImplemented']),
    ...mapGetters('scenarios', ['selectedScenario']),

    editingDisabled() {
      return (
        !this.hasPermission(this.userPermissions.canEditStoreclassDefinitionPage) ||
        !get(this.selectedScenario, 'status.space.canBeEdited', true)
      );
    },

    groupedFurniture() {
      return groupBy(this.filteredFurnituresWithStoreClass, 'furnitureCategory1Key');
    },

    // These are all the options users will have to use in the filters component
    filterOptions() {
      return [
        {
          type: 'furnitureArchetypeKey',
          path: 'furnitureCategory1Key',
          allowSelectMultiple: true,
          name: this.$tkey('furnitureArchetypeSet'),
          values: this.furnitureCategorySets.map(catSet => ({ name: catSet, value: catSet })),
        },
        {
          type: 'furnitureName',
          path: 'furnitureName',
          allowSelectMultiple: true,
          name: this.$tkey('furnitureName'),
          values: this.getFurnituresInScenario.map(furniture => ({
            name: furniture.name,
            value: furniture.name,
          })),
        },
        {
          type: 'storeClass',
          path: 'storeClassId',
          allowSelectMultiple: true,
          name: this.$tkey('storeClass'),
          values: this.storeClasses.map(storeClass => ({
            name: storeClass.name,
            value: storeClass._id,
          })),
        },
      ];
    },

    filterButtonText() {
      const originalFurnitureLength = this.furnituresWithStoreClass.length;
      const filteredFurnitureLength = this.filteredFurnituresWithStoreClass.length;
      return `${this.$tc(
        `storeClassDefinitionPage.filters`,
        this.filters.length
      )} - (${filteredFurnitureLength}/${originalFurnitureLength}) ${this.$tkey('furniture')}`;
    },

    furnitureCategorySets() {
      return uniq(this.getFurnituresInScenario.map(f => f.furnitureCategory1Key)).sort();
    },

    filteredFurnitureCategorySets() {
      return uniq(this.filteredFurnituresWithStoreClass.map(f => f.furnitureCategory1Key)).sort();
    },

    hasDataChanges() {
      return (
        this.getStoreClassesInScenario.length !== this.storeClasses.length ||
        differenceBy(this.storeClasses, this.getStoreClassesInScenario, 'name').length > 0 ||
        this.hasMappingChanges
      );
    },

    hasMappingChanges() {
      // Checks whether our mappings of furniture archetypes to storeclasses are different
      // from what our initial state was. It breaks once it finds a single difference.
      const originalFurnitureIdToStoreClassMap = this.getStoreClassesInScenario.reduce(
        (m, storeClass) => {
          return {
            ...m,
            ...storeClass.furnitureIds.reduce((acc, id) => ({ [id]: storeClass._id, ...acc }), {}),
          };
        },
        {}
      );
      return !isEqual(
        originalFurnitureIdToStoreClassMap,
        mapValues(this.furnitureIdToStoreClassMap, v => v._id)
      );
    },

    furnitureIdToStoreClassMap() {
      return this.storeClasses.reduce((m, storeClass) => {
        return {
          ...m,
          ...storeClass.furnitureIds.reduce((acc, id) => ({ [id]: storeClass, ...acc }), {}),
        };
      }, {});
    },

    storeClassById() {
      return keyBy(this.storeClasses, '_id');
    },

    storeClassByNameAndId() {
      return extend(
        mapValues(keyBy(this.storeClasses, 'name'), '_id'),
        mapValues(this.storeClassById, '_id')
      );
    },

    columnDefs() {
      return [
        {
          headerName: '',
          field: 'furnitureName',
          colId: 'furnitureName',
          editable: false,
        },
        {
          headerName: '',
          field: 'storeClassId',
          colId: 'storeClassId',
          width: 240,
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: {
            values: map(this.storeClasses, '_id'),
            field: 'storeClassId',
          },
          valueFormatter: this.formatStoreclassName,
          editable: !this.editingDisabled,
          filterParams: {
            valueFormatter: this.formatFurnitureName,
          },
          tooltipValueGetter(params) {
            return params.valueFormatted;
          },
        },
      ];
    },

    showErrorControls() {
      return !isEmpty(this.errorData) || this.filterInvalidRows;
    },

    invalidRowsErrorMessage() {
      return this.$t(`validationErrors.rowsHaveInvalidValues`);
    },
  },

  created() {
    this.storeClasses = cloneDeep(this.getStoreClassesInScenario);
    this.setupFurnituresWithStoreClasses();

    // each grid must have its own config, so generate one keyed by set key
    this.gridOptions = reduce(
      keys(this.groupedFurniture),
      (acc, archetypeSetKey) => {
        return {
          ...acc,
          [archetypeSetKey]: {
            suppressContextMenu: true,
            headerHeight: 0,
            stopEditingWhenCellsLoseFocus: true,
            tooltipShowDelay: 0,
            defaultColDef: {
              filter: false,
              sortable: false,
              editable: true,
              menuTabs: [],
              suppressMovable: true,
              minWidth: 100,
              flex: 1,
              cellClassRules: {
                'not-editable-cell': agGridUtils.utils.disableEdit,
              },
              suppressKeyboardEvent: agGridUtils.utils.suppressKeyboardEvent,
            },
            isExternalFilterPresent() {
              return true;
            },
            processDataFromClipboard: agGridUtils.utils.processDataFromClipboard,
            processCellFromClipboard: this.processCellFromClipboard,
          },
        };
      },
      {}
    );
    this.validateFurnitureCategories();
  },

  methods: {
    ...mapActions('furniture', ['saveScenarioFurniture']),
    ...mapActions('dependencyTree', ['triggerDependencyTree']),
    ...mapActions('scenarios', ['refreshScenario']),

    validateFurnitureCategories() {
      this.furnitureCategorySets.forEach(fs => {
        this.checkMultipleRowsForErrors(this.groupedFurniture[fs]);
      });
    },

    revalidateForm() {
      // need to wait for element to be added before revalidating
      this.$nextTick().then(this.$refs.storeClassNamesForm.validate);
      this.validateFurnitureCategories();
    },

    redrawRows() {
      mapValues(this.gridApis, v => v.gridApi.redrawRows());
    },

    hasFurniture(storeClassName) {
      const storeClass = this.storeClasses.find(sc => sc.name === storeClassName);
      if (!storeClass) return 'storeClassNotFound';
      if (storeClass.furnitureIds.length > 0) return true;

      return this.$t('validationErrors.needsFurniture', [this.$tkey('storeClass')]);
    },

    isUniqueStoreClassName(storeClassName) {
      // since this page has a save button instead of inline editing, check length > 1.
      if (this.storeClasses.filter(sc => sc.name.trim() === storeClassName.trim()).length > 1) {
        return this.$t('validationErrors.unique', [this.$tkey('storeClass')]);
      }
      return true;
    },

    addNewStoreClass(isSingleStoreClass = false) {
      this.storeClasses.push({
        name: isSingleStoreClass
          ? this.$tkey('allFurnitureArchetype')
          : `${this.$tkey('storeClass')} ${Math.floor(1000 + Math.random() * 9000)}`,
        // Give new storeClasses a temporary id so they can be used in the furniture arch mapping
        // server will give it a mongo ObjectId when persisted.
        _id: uniqueId(newlyAddedStoreClassIdPrefix),
        spacebreaks: [],
        furnitureIds: isSingleStoreClass ? map(this.getFurnituresInScenario, '_id') : [],
        generatedFurnitureIds: [],
        isEditingDisabled: false,
      });
      this.setupFurnituresWithStoreClasses();
      this.revalidateForm();
    },

    removeStoreClass(storeClassId) {
      this.storeClassToDelete = storeClassId;
      this.$refs.confirmDeleteDialog.open();
      this.revalidateForm();
    },

    processCellFromClipboard(params) {
      const pasteFormatters = {
        storeClassId: value => {
          return get(this.storeClassByNameAndId, value, null);
        },
      };

      return agGridUtils.utils.processCellFromClipboard(params, pasteFormatters);
    },

    closeConfirmDialog() {
      this.storeClassToDelete = null;
      this.deleteAllStoreClasses = false;
    },

    setupFurnituresWithStoreClasses() {
      this.furnituresWithStoreClass = this.getFurnitureWithStoreClass();
      this.filterFurnitureWithStoreClass();
    },

    discardChanges() {
      this.nameKey = !this.nameKey; // force rtls-text-field to re-render with new prop value
      this.storeClasses = cloneDeep(this.getStoreClassesInScenario);
      this.setupFurnituresWithStoreClasses();
      this.revalidateForm();
    },

    handleFilterChange(filters) {
      this.filters = filters;
    },

    getFurnitureWithStoreClass() {
      return map(this.getFurnituresInScenario, f => {
        return {
          ...f,
          furnitureId: f._id,
          furnitureName: f.name,
          storeClassId: get(this.furnitureIdToStoreClassMap[f._id], '_id'),
        };
      });
    },

    filterFurnitureWithStoreClass() {
      this.filteredFurnituresWithStoreClass = filterUtils.applyFiltersToData({
        filters: this.filters,
        items: this.furnituresWithStoreClass,
      });
    },

    addFurnitureToStoreClass(storeClass, furnitureId) {
      const previousStoreClass = this.storeClasses.find(sc =>
        sc.furnitureIds.includes(furnitureId)
      );
      if (previousStoreClass) {
        previousStoreClass.furnitureIds = previousStoreClass.furnitureIds.filter(
          id => id !== furnitureId
        );
      }
      const targetStoreClass = this.storeClasses.find(sc => sc._id === storeClass._id);
      targetStoreClass.furnitureIds.push(furnitureId);
    },

    prepareStoreClassesForSave() {
      // Need to remove the temporary _ids as the server will create Mongo ObjectIds
      return this.storeClasses.map(storeClass => ({
        ...storeClass,
        spacebreaks: this.hasMappingChanges ? [] : storeClass.spacebreaks,
        _id: startsWith(storeClass._id, newlyAddedStoreClassIdPrefix) ? null : storeClass._id,
      }));
    },

    async saveChanges(commit = false) {
      if (!this.valid) return false;

      if (this.hasMappingChanges) {
        const results = await this.triggerDependencyTree({
          params: { change: 'storeClassModified', updates: {}, commit },
        });

        if (results.needsFeedback) {
          this.dependencyTreeFeedback = results.output;
          this.dependencyTreeModalOpen = true;
          return;
        }
      }

      await this.saveScenarioFurniture({
        storeClasses: this.prepareStoreClassesForSave(),
      });
      this.storeClasses = cloneDeep(this.getStoreClassesInScenario);
      this.setupFurnituresWithStoreClasses();
      await this.refreshScenario();
    },

    onSingleStoreClassClick() {
      if (this.storeClasses.length) {
        this.deleteAllStoreClasses = true;
        return this.$refs.confirmDeleteDialog.open();
      }

      this.replaceStoreClassToSingle();
      this.closeConfirmDialog();
    },

    replaceStoreClassToSingle() {
      this.storeClasses = [];
      this.addNewStoreClass(true);
    },

    onDeleteConfirm() {
      if (this.deleteAllStoreClasses) {
        this.replaceStoreClassToSingle();
      } else {
        this.storeClasses = this.storeClasses.filter(({ _id }) => _id !== this.storeClassToDelete);
      }

      this.closeConfirmDialog();
      this.setupFurnituresWithStoreClasses();
      this.revalidateForm();
      this.validateFurnitureCategories();
    },

    onGridReady(params, furnitureSet) {
      // need to track all ag-grids on page
      this.gridApis[furnitureSet] = { gridApi: params.api, columnApi: params.columnApi };
    },

    formatStoreclassName(params) {
      const storeClass = this.storeClassById[params.value];

      return get(storeClass, 'name', this.$t('general.select'));
    },

    onSelectionChange(params) {
      const { furnitureId } = params.data;
      const storeClassId = params.value;

      this.addFurnitureToStoreClass({ _id: storeClassId }, furnitureId);
      this.setupFurnituresWithStoreClasses();
      this.checkRowForErrors();
      this.revalidateForm();
    },

    modifySelectPosition(idSelector) {
      const agSelectList = document.querySelector(`${idSelector} .ag-select-list`);
      if (agSelectList) {
        const height = agSelectList.getBoundingClientRect().height;
        const bottom = agSelectList.getBoundingClientRect().bottom;
        const spaceBelow = window.innerHeight - bottom;
        // Find the distance between the dropdown select list and the bottom of the page, if lower than 100px then
        // reposition the select so it opens upwards rather than downwards, prevents increasing size of container
        if (spaceBelow < 100) {
          agSelectList.style.transform = `translateY(-${height}px)`;
        }
      }
    },

    checkRowForErrors() {
      // turn off filter if you've removed all invalid rows
      if (isEmpty(this.errorData) && this.filterInvalidRows) {
        this.toggleInvalidRows();
      }
    },

    doesExternalFilterPass(node) {
      if (this.filterInvalidRows) {
        return this.errorData[node.data._id];
      }
      return true;
    },

    toggleInvalidRows() {
      this.filterInvalidRows = !this.filterInvalidRows;
      Object.keys(this.gridApis).forEach(sc => {
        this.gridApis[sc].gridApi.onFilterChanged();
      });
    },

    checkMultipleRowsForErrors(rows) {
      each(rows, this.checkRowForErrors);
    },

    closeDependencyTreeModal() {
      this.dependencyTreeModalOpen = false;
      this.dependencyTreeFeedback = {};
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.assortment-table {
  height: 100%;
}

.list-title {
  padding: 0;
  width: 90%;
  margin: auto;
  margin-bottom: 2px;
  font-size: 1.2rem;
  margin-left: 17px;
}
.single-storeclass-btn {
  background-color: $assortment-background;
  color: $assortment-primary-colour;
  border: 1px solid $assortment-primary-colour;
}

.storeclass-col {
  padding: 2px;
}

.padded-column {
  padding-left: 5px;
}

.storeclass-border {
  &.v-divider {
    border-color: $assortment-menu-border-colour;
  }
}
.furniture-col-scrollable {
  position: relative;
  border-left: 1px solid $assortment-border-colour;
  border-top: 1px solid $assortment-border-colour;
  border-bottom: 1px solid $assortment-panel-border-divider-colour;
  -webkit-box-shadow: $assortment-main-panel-box-shadow;
  -moz-box-shadow: $assortment-main-panel-box-shadow;
  box-shadow: $assortment-main-panel-box-shadow;
  overflow-y: auto;
}

.dynamic-height-container {
  &.storeclass-container {
    border-bottom: none;
    height: $tab-panel-height;
    overflow: auto;
  }
}

.storeclass-list-container {
  display: flex;
  flex-grow: 1;
  overflow: auto;
}

.storeclass-table {
  margin-bottom: 1em;
  border-top: 1px solid $assortment-primary-colour;

  ::v-deep {
    .ag-row:nth-child(odd) {
      .v-input__slot,
      .v-select__slot {
        background: $assortment-input-background;
      }
    }
  }
}

.storeclass-list-column {
  overflow-y: auto;
  border-bottom: 1px solid $assortment-panel-border-divider-colour;
  -webkit-box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.3);
}
.storeclass-list-scrollable {
  overflow-y: auto;
  .storeclass-card {
    background-color: $assortment-attribute-colour;
  }
}

.small-icon {
  height: 21px;
}

.info-icon {
  font-size: 20px;
  color: $assortment-primary-colour !important;
}

.filter-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 30px;

  .filter-label {
    color: #37424a;
    font-family: 'Source Sans Pro';
    font-size: 1.4rem;
    margin-right: 7px;
    letter-spacing: 0;
    line-height: 1.8rem;
  }
}

.storeclass-text-field {
  ::v-deep {
    .v-text-field__slot {
      input {
        font-weight: bold;
        background-color: $assortment-attribute-colour;
      }
    }
  }
}

.not-editable-cell {
  color: $assortment-table-not-editable-color;
}

::v-deep .tabled-select {
  .v-select__selections {
    color: $assortment-table-textfield-colour !important;
    padding-left: 5px !important;
  }
}

.storeclass-btn {
  &.v-btn {
    min-width: 150px !important;
  }
}

::v-deep {
  .section {
    font-weight: bolder;
    color: $assortment-table-header-colour;
    font-size: 1.4rem;
    background-color: white !important;
    padding: 5px 5px 5px 16px;
  }

  .ag-cell-value.ag-cell-not-inline-editing {
    display: initial;
    text-overflow: ellipsis;
    overflow: hidden !important;
    white-space: nowrap;
  }

  .ag-cell {
    overflow: visible;
  }

  .ag-row.ag-row-focus {
    z-index: 1;
  }
  .ag-row.ag-row-no-focus.ag-row-level-0.ag-row-position-absolute {
    z-index: 0;
  }

  .ag-root,
  .ag-root-wrapper,
  .ag-body-viewport,
  .ag-body-viewport-wrapper,
  .ag-center-cols-clipper,
  .ag-center-cols-viewport {
    overflow: visible !important;
  }

  .flex-grow-2 {
    flex-grow: 2;
  }
}
</style>
