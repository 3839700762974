<template>
  <div
    class="arrow-indicator d-flex h-100 w-100 align-center justify-space-between"
    :class="{ 'arrow-indicator--reverse': reverse }"
  >
    <i class="arrow-indicator__icon fa" :class="classRules" />
    <span>{{ valueFormatted }}</span>
  </div>
</template>

<script>
import { get } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'arrowIndicatorRenderer',
  computed: {
    classRules() {
      return get(this.params, 'classRules', {});
    },

    reverse() {
      return get(this.params, 'reverse', false);
    },

    valueFormatted() {
      return get(this.params, 'valueFormatted', 0);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.arrow-indicator {
  &__icon {
    font-size: 1.6rem;
    margin-left: 12px;
  }

  .fa-caret {
    &-up {
      color: $assortment-positive-action-colour;
    }

    &-down {
      color: $assortment-negative-action-colour;
    }
  }

  &--reverse {
    .fa-caret {
      &-up {
        color: $assortment-negative-action-colour;
      }

      &-down {
        color: $assortment-positive-action-colour;
      }
    }
  }
}
</style>
