import permissions from '@enums/user-permissions';

import TemplateWorkflow from '@/js/pages/templates/template-workflow/template-workflow.vue';

// Scope
import TemplateScope from '@/js/pages/templates/template-scope/template-scope.vue';
import TemplateDateAssortmentGroups from '@/js/pages/templates/template-scope/template-date-assortment-groups/template-date-assortment-groups.vue';
import TemplateProducts from '@/js/pages/templates/template-scope/template-products/template-products.vue';
import TemplateStores from '@/js/pages/templates/template-scope/template-stores/template-stores.vue';

// Inputs
import TemplateInputs from '@/js/pages/templates/template-inputs/template-inputs.vue';
import AttributeEditor from '@/js/pages/scenario-input/attribute-editor/attribute-editor.vue';
import CannGroups from '@/js/pages/scenario-input/cann-groups/cann-groups.vue';

const routes = [
  {
    path: '/template/:templateId',
    name: 'template',
    id: 'template',
    component: TemplateWorkflow,
    linkText: 'navBar.templateWorkflow',
    meta: {
      featureKey: 'templatesEnabled',
      requiresPermission: permissions.canViewTemplates,
      titleExtension: 'navBar.templateWorkflow',
    },
    omitFromNav: true,
    children: [
      {
        path: 'scope',
        name: 'scope',
        component: TemplateScope,
        children: [
          {
            path: 'dates-assortment-groups',
            name: 'dates-assortment-groups',
            component: TemplateDateAssortmentGroups,
            omitFromNav: true,
            meta: {
              featureKey: 'templatesEnabled',
              requiresPermission: permissions.canViewTemplates,
              titleExtension: 'datesAssortmentGroupsPage.tab.datesAssortmentGroups',
            },
          },
          {
            path: 'products',
            name: 'products',
            component: TemplateProducts,
            omitFromNav: true,
            meta: {
              featureKey: 'templatesEnabled',
              requiresPermission: permissions.canViewTemplates,
              titleExtension: 'templateProductsPage.tab.products',
            },
          },
          {
            path: 'stores',
            name: 'stores',
            component: TemplateStores,
            omitFromNav: true,
            meta: {
              featureKey: 'templatesEnabled',
              requiresPermission: permissions.canViewTemplates,
              titleExtension: 'templateStoresPage.tab.stores',
            },
          },
        ],
      },
      {
        // until scenario is available
        path: ':templateScenarioId/inputs',
        name: 'template-inputs',
        component: TemplateInputs,
        children: [
          {
            path: 'attributes',
            name: 'template-attributes',
            component: AttributeEditor,
            omitFromNav: true,
            meta: {
              featureKey: 'templatesEnabled',
              requiresPermission: permissions.canViewTemplates,
              titleExtension: 'scenarioInputPage.tab.attr',
            },
          },
          {
            path: 'cann-group',
            name: 'template-cann-group',
            component: CannGroups,
            omitFromNav: true,
            meta: {
              featureKey: 'templatesEnabled',
              requiresPermission: permissions.canViewTemplates,
              titleExtension: 'scenarioInputPage.tab.cann-group',
            },
          },
        ],
      },
    ],
  },
];

export default routes;
