var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "required-text" }, [
    _c("strong", [
      _vm._v(
        _vm._s(_vm.$t("scenarioManagementPanel.requires")) +
          " " +
          _vm._s(_vm.requires)
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }