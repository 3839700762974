<template>
  <v-dialog :value="value" width="500px" @click:outside="closeModal">
    <dialog-card v-if="value" :title="$tkey('modalTitle')" @close="closeModal">
      <v-container fluid class="pa-0 clean-attributes__container">
        <v-row class="mt-4 border-bottom">
          <p class="mb-4">
            {{ cleanAttributesModalDescription }}
          </p>
        </v-row>

        <div class="mt-4 pb-5 border-bottom">
          <v-row>
            <span class="font-weight-bold">
              {{ $tkey('attributes') }}
            </span>
          </v-row>
          <v-row v-for="attr in customAttributes" :key="attr.id" class="checkbox-selection-row">
            <v-checkbox
              :input-value="attributesToClean.has(attr.id)"
              class="rtls-checkbox mx-2"
              @change="updateCurrentSelections(attr.id, $event)"
            />
            <span>{{ attr.name }}</span>
          </v-row>
        </div>

        <v-row class="mt-4 pb-4">
          <v-checkbox
            class="rtls-checkbox mx-2 ml-3"
            :input-value="autoCleanOnSave"
            @change="autoCleanOnSave = $event"
          />
          <span class="mr-4">{{ $tkey('autoClean') }}</span>
        </v-row>
      </v-container>
      <template v-slot:footer>
        <page-actions
          :has-data-errors="hasDataErrors"
          :has-data-changes="true"
          :show-discard="false"
          save-btn-text="saveSettings"
          @save="onSave"
        >
          <template v-slot:right-btns>
            <v-btn class="primary" :disabled="isCleanNowDisabled" @click="cleanAttributes">
              {{ $t('actions.cleanNow') }}
            </v-btn>
          </template>
        </page-actions>
      </template>
    </dialog-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { each, get, some } from 'lodash';

export default {
  name: 'CleanAttributesModal',
  localizationKey: 'scenarioInputPage.cleanAttributesModal',

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    customAttributes: {
      type: Object,
      required: true,
    },

    attributesToClean: {
      type: Set,
      required: true,
    },
  },

  data() {
    return {
      autoCleanOnSave: false,
      currentSelections: {},
    };
  },

  computed: {
    ...mapState('context', ['clientConfig']),
    ...mapState('scenarios', ['selectedScenario']),

    cleanAttributesModalDescription() {
      return this.$tkey('description', {
        case: this.$tkey(
          `formattingOptions.${this.hasCleanAttributesUpperCaseEnabled ? 'upperCase' : 'lowerCase'}`
        ),
      });
    },

    hasDataErrors() {
      return false;
    },

    hasDiff() {
      return some(this.currentSelections, (isClean, key) => {
        return (
          (this.attributesToClean.has(key) && !isClean) ||
          (!this.attributesToClean.has(key) && isClean)
        );
      });
    },

    isCleanNowDisabled() {
      // Must save selections before clean
      return this.hasDiff || !this.attributesToClean.size;
    },

    hasCleanAttributesUpperCaseEnabled() {
      return get(this.getClientConfig, 'features.cleanAttributesUpperCaseEnabled', false);
    },
  },

  created() {
    this.reset();
  },

  methods: {
    updateCurrentSelections(id, isClean) {
      this.$set(this.currentSelections, id, isClean);
    },

    cleanAttributes() {
      this.$emit('clean-attributes');
    },

    reset() {
      this.currentSelections = {};
      // Set the default value for the auto clean on save checkbox
      // The user selection will be used if it exists, otherwise the config value will be used
      const cleanOnSaveDefault = get(this.clientConfig, 'features.cleanAttributesOnSaveEnabled');
      this.autoCleanOnSave = get(
        this.selectedScenario,
        'cleanAttributesOnSave',
        cleanOnSaveDefault
      );
    },

    onSave() {
      each(this.currentSelections, (isClean, key) => {
        if (this.attributesToClean.has(key) && !isClean) {
          this.attributesToClean.delete(key);
        } else if (!this.attributesToClean.has(key) && isClean) {
          this.attributesToClean.add(key);
        }
      });

      this.currentSelections = {};
      this.$emit('process', this.autoCleanOnSave);
    },

    closeModal() {
      this.reset();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.clean-attributes {
  &__container {
    font-size: 1.2rem;
    max-height: 500px;
  }
}

::v-deep {
  .dialog-card {
    &__footer {
      flex-direction: row-reverse;
    }
  }
}
</style>
