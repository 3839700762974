import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { staticClass: "pa-0 ma-0 planogram-definition-container" },
    [
      _c(
        "div",
        { staticClass: "ag-grid-box flex-grow-1 pa-2 ma-0" },
        [
          _c("div", { staticClass: "action-panel" }, [
            _c(
              "div",
              { staticClass: "action-panel-container" },
              [
                _c("data-upload", {
                  attrs: {
                    legends: _vm.csvUploadLegends,
                    "csv-upload-handler": _vm.onCSVUpload,
                    "show-modal": "",
                    disabled: _vm.isEditingDisabled,
                  },
                  on: { process: _vm.process },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("ag-grid-vue", {
            staticClass: "ag-theme-custom",
            staticStyle: { width: "100%", height: "90%" },
            attrs: {
              "column-defs": _vm.columnDefs,
              "row-data": _vm.rowData,
              "grid-options": _vm.gridOptions,
            },
            on: {
              "cell-value-changed": _vm.trackDiff,
              "grid-ready": _vm.onGridReady,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("page-actions", {
        attrs: {
          "show-export": "",
          "is-custom-export": "",
          "has-data-changes": _vm.isActionsEnabled,
          "has-data-errors": _vm.hasDataErrors,
          "is-discard-enabled": _vm.isDiscardEnabled && !_vm.isEditingDisabled,
          "save-disabled": _vm.isEditingDisabled,
        },
        on: {
          export: function ($event) {
            return _vm.exportCSV()
          },
          discard: _vm.discardChanges,
          save: _vm.saveChanges,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }