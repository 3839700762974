import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout-options-main" },
    [
      _c("span", { staticClass: "mx-2" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t(
                "reportingPage.sections.product.sideBar.toolPanels.layouts.currentLayout"
              )
            ) +
            "\n    " +
            _vm._s(_vm.layoutName) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        VBtn,
        {
          staticClass: "mx-2",
          attrs: {
            primary: "",
            depressed: "",
            width: "min-content",
            height: "18",
          },
          on: { click: _vm.save },
        },
        [_vm._v(_vm._s(_vm.$t("actions.save")))]
      ),
      _vm._v(" "),
      _c("layout-modal", {
        attrs: { value: _vm.saveOpen },
        on: { close: _vm.closeModal, save: _vm.saveLayout },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }