import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VMenu,
    {
      attrs: { "offset-y": "", top: _vm.top },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                VBtn,
                _vm._g(
                  {
                    class: { activator: !_vm.title },
                    attrs: { primary: "", depressed: "" },
                  },
                  on
                ),
                [
                  _vm._v("\n      " + _vm._s(_vm.title) + "\n      "),
                  _vm.icon
                    ? _c(VIcon, { attrs: { right: "" } }, [
                        _vm._v(_vm._s(_vm.icon)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _vm._v(" "),
      _c(
        VList,
        _vm._l(_vm.actions, function (action) {
          return _c(
            VListItem,
            {
              key: action.name,
              attrs: { disabled: action.disabled },
              on: {
                click: function ($event) {
                  return _vm.$emit(action.value)
                },
              },
            },
            [_c(VListItemTitle, [_vm._v(_vm._s(action.name))])],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }