'use strict';

const spacebreakTypes = {
  notListed: 'notListed',
  notForAssortment: 'notForAssortment',
  notInStoreClass: 'notInStoreClass',
};

const undraggableSpacebreaks = [spacebreakTypes.notForAssortment, spacebreakTypes.notInStoreClass];

module.exports = {
  spacebreakTypes,
  undraggableSpacebreaks,
};
