/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { get, isObject, isDate, isNil, isArray, size } from 'lodash';
import authConfig from '@sharedModules/config/staticAuth';
import moment from 'moment';
import { SERVER_ERROR_CODES } from '@enums/server-error-codes';

let currentRefreshTokenRequest = null;

const encodeAxiosParam = value => {
  let v = value;
  if (isDate(value)) {
    v = value.toISOString();
  } else if (isObject(value)) {
    v = JSON.stringify(value);
  }
  return encodeURIComponent(v);
};

const interceptors = {
  csrfToken: (store, request) => {
    request.headers['x-csrf-token'] = get(store.state, 'context.csrfToken');
    return request;
  },

  currentLocale: (store, request) => {
    request.headers['x-current-locale'] = get(store.state, 'context.currentLocale', 'en');
    return request;
  },

  currentDateLocale: (store, request) => {
    request.headers['x-current-date-locale'] = get(
      store.state,
      'context.dateLocale',
      'context.clientConfig.defaultDateLocale'
    );
    return request;
  },

  timezone: (store, request) => {
    const logging = get(store.state, 'context.clientConfig.dateFormats.logging', null);
    // collect minutes of difference between current time and UTC
    // different is UTC - now, so we flip to sum based on UTC later
    request.headers['x-timezone-offset'] = 0 - new Date().getTimezoneOffset();
    request.headers['x-usertime'] = moment().format(logging);
    return request;
  },

  scenarioDetails: (store, router, request) => {
    request.headers['x-scenario-id'] = get(store.state, 'scenarios.selectedScenario._id', null);
    request.headers['x-workpackage-id'] = get(
      store.state,
      'workpackages.selectedWorkpackage._id',
      null
    );
    request.headers['x-application-page'] = get(router, 'history.current.name', null);
    return request;
  },

  responseError: (store, router, i18n, error) => {
    // Was this cancelled by our code?
    if (axios.isCancel(error)) {
      // If so, pass through the error
      return Promise.reject(error);
    }

    // TODO: AOV3-159, on status 404 we should just redirect the user to an error page, similar to 403.
    // Show snackbar for certain error codes
    const errorStatus = get(error, 'response.status'); // some errors do not have response defined e.g. ERR_UPLOAD_FILE_CHANGED. see AOv3-277
    if (SERVER_ERROR_CODES.includes(errorStatus)) {
      const errorCode = get(error.response, 'data.error.code', 'generalServerError');
      store.dispatch('snackbar/showSnackbar', {
        content: i18n.t(`errors.${errorCode}`),
      });
      return Promise.reject(error);
    }

    // Unauthenticated?
    if (errorStatus === 401) {
      // ensure it is not because of a hardcoded login attempt with invalid credentials
      if (error.config.url.endsWith('/login')) return Promise.reject(error);

      // Try first refreshing the token
      const shouldAttemptRefresh = error.response.data && error.response.data.attemptTokenRefresh;
      if (
        shouldAttemptRefresh &&
        !error.config.url.endsWith('/api/token/refresh') &&
        !error.config._triedRefreshToken &&
        !error.config._refreshFailed
      ) {
        // Do not attempt multiple requests to refresh in parallel, or they would fail since the last ones will attempt to refresh outdated tokens
        error.config._triedRefreshToken = true;
        if (!currentRefreshTokenRequest) {
          currentRefreshTokenRequest = store
            .dispatch('context/refreshUserContext')
            .then(() => {
              currentRefreshTokenRequest = null;
            })
            .catch(() => {
              currentRefreshTokenRequest = null;
              // if the token refresh fails, let the error handler decide what to do with the original request, but mark the request as having failed to refresh
              error.config._refreshFailed = true;
              return interceptors.responseError(store, router, i18n, error);
            });
        }

        // Once the token has been successfully refreshed, retry the original request, otherwise just pass back the result of the error handler
        // Any errors with this request would go through the interceptor for the new request, as part of the axios.request() call
        return currentRefreshTokenRequest.then(res => {
          if (!error.config._refreshFailed) {
            const request = error.config;
            // Undo axios processing to prevent 400
            try {
              const object = JSON.parse(request.data);
              if (object && typeof object === 'object') {
                request.data = object;
              }
            } catch (err) {
              console.log(
                `Unexpected error parsing JSON during request retry: ${err}. This should be investigated.`
              );
            }
            return axios.request(request);
          }
          return Promise.resolve(res);
        });
      }

      // Were we loading the /user-context as part of application startup?
      // If we couldnt load it even after trying refreshing the auth tokens, return empty context and let the bootstrap process to finish
      // The router navigation guard will navigate to the login page (since there will be no profile in the context)
      if (error.config.url.endsWith('/user-context')) {
        return Promise.resolve({ data: null });
      }

      // User needs to authenticate, navigate to the login page
      const { currentLoginMethod } = store.state.context;
      const newLoginPath = get(
        authConfig.authenticationPaths,
        `${currentLoginMethod}.login`,
        '/login'
      );
      router.replace({ path: newLoginPath, query: { returnPath: router.currentRoute.path } });

      // Show snackbar if session expired
      store.dispatch('snackbar/showSnackbar', {
        content: 'Session has timed out',
      });

      return Promise.reject(error);
    }

    // 403: user is logged in and has no permission to access a resource
    // 477: modsecurity rules fail
    if ([403, 477].includes(errorStatus)) {
      router.replace({ path: `/error/${errorStatus}` });
    }

    return Promise.reject(error);
  },
  paramsSerializer: params => {
    return Object.entries(params)
      .reduce((currentParams, [key, value]) => {
        if (!isNil(value) && (isArray(value) ? !!size(value) : true)) {
          if (Array.isArray(value)) {
            const arrayValues = value.map(v => `${key}[]=${encodeAxiosParam(v)}`);
            return currentParams.concat(arrayValues);
          }
          currentParams.push(`${key}=${encodeAxiosParam(value)}`);
        }
        return currentParams;
      }, [])
      .join('&');
  },
};

const install = (Vue, options) => {
  axios.interceptors.request.use(interceptors.csrfToken.bind(null, options.store));
  axios.interceptors.request.use(interceptors.currentLocale.bind(null, options.store));
  axios.interceptors.request.use(interceptors.currentDateLocale.bind(null, options.store));
  axios.interceptors.request.use(interceptors.timezone.bind(null, options.store));
  axios.interceptors.request.use(
    interceptors.scenarioDetails.bind(null, options.store, options.router)
  );
  axios.interceptors.request.use(config => {
    config.paramsSerializer = {
      serialize: params => interceptors.paramsSerializer(params),
    };
    return config;
  });
  axios.interceptors.response.use(
    null,
    interceptors.responseError.bind(null, options.store, options.router, options.i18n)
  );

  axios.defaults.withCredentials = true; // make sure we store/send cookies in CORS requests between frontend and backend

  Vue.prototype.$http = axios;
};

export default {
  interceptors, // Technically not needed, but will help with testing
  install,
  encodeAxiosParam,
};
